import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { PaginationData } from './common';
import { request, deepCamelcaseKeys, removeEmptyKeys } from './api-shared';
import { ExternalSource } from '../models/external-source';
import { BulkSelection, TopicBulkActionFilters } from '../hooks/common';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  search?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  statuses?: Array<string>;
  types?: Array<string>;
  autoPublish?: string[];
  shareable?: string[];
};

export type ExternalSourceData = {
  attributes: ExternalSource;
};

export type ExternalSourcesCollectionData = {
  data: Array<ExternalSourceData>;
  meta: PaginationData;
};

export type RequestData = {
  identifier?: string;
  isShareable?: boolean;
  type?: string;
  active?: boolean;
  alias?: string;
  autoPublish?: boolean;
  appendedHashtagEnabled?: boolean;
  appendedHashtag?: string;
  contentChannels?: { contentChannelId: number }[];
  contentTemplateId?: number | null;
  displayInternalContent?: boolean;
};

function mapExternalSourceToRequestData(
  externalSource: Partial<ExternalSource>
): RequestData {
  return {
    active: externalSource.active,
    appendedHashtagEnabled: externalSource.appendedHashtagEnabled,
    appendedHashtag: externalSource.appendedHashtag,
    autoPublish: externalSource.autoPublish,
    contentChannels: externalSource.contentChannels?.map((c) => {
      return { contentChannelId: c.id };
    }),
    contentTemplateId: externalSource.contentTemplateId,
    displayInternalContent: externalSource.displayInternalContent,
    identifier: externalSource.identifier,
    isShareable: externalSource.isShareable,
    type: externalSource.type,
  };
}

export const fetchExternalSources = async (
  props: FetchProps
): Promise<ExternalSourcesCollectionData> => {
  const {
    programId,
    search,
    page,
    pageSize,
    sortBy,
    sortDirection,
    statuses = [],
    types = [],
    shareable,
    autoPublish,
  } = props;

  // TODO:
  // Build the query string to handle filtering and paging
  const query = qs.stringify(
    snakecaseKeys({
      search,
      page,
      pageSize,
      sortBy,
      sortDirection,
      statuses,
      types,
      autoPublish,
      shareable,
    }),
    { arrayFormat: 'brackets' }
  );
  const url = `${apiRoot}/programs/${programId}/external_content_sources?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching external sources: ${response.status}`);
};

export const fetchById = async (
  programId: number,
  externalSourceId: number
): Promise<ExternalSourceData> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources/${externalSourceId}`;
  const response = await request(url);
  if (response.status === 200) {
    const json = await response.json();
    return camelcaseKeys(json.data, { deep: true });
  }
  throw new Error(`Error fetching external source: ${response.status}`);
};

export const updateExternalSource = async (
  programId: number,
  externalSource: ExternalSource
): Promise<ExternalSourceData> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources/${externalSource.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(
      snakecaseKeys(mapExternalSourceToRequestData(externalSource), {
        deep: true,
      })
    ),
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output.data));
  }

  throw new Error(`Error updating feed: ${response.status}`);
};

export const createExternalSource = async (
  programId: number,
  externalSource: Partial<ExternalSource>
): Promise<ExternalSourceData> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys(mapExternalSourceToRequestData(externalSource), {
        deep: true,
      })
    ),
  });

  if (response.status === 201) {
    return response.json().then((output) => deepCamelcaseKeys(output.data));
  }

  throw new Error(`Error creating feed: ${response.status}`);
};

export const archiveExternalSource = async (
  programId: number,
  externalSourceId: number
): Promise<ExternalSourceData> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources/${externalSourceId}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output.data));
  }
  throw new Error(`Error archive feed: ${response.status}`);
};

export const unarchiveExternalSource = async (
  programId: number,
  externalSourceId: number
): Promise<ExternalSourceData> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources/${externalSourceId}/unarchive`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output.data));
  }
  throw new Error(`Error unarchive feed: ${response.status}`);
};

export const harvestExternalSource = async (
  programId: number,
  externalSourceId: number
): Promise<string> => {
  const url = `${apiRoot}/programs/${programId}/external_content_source/harvest?id=${externalSourceId}`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 202) {
    return 'Refresh done successfully';
  }
  throw new Error(`Error refresh feed: ${response.status}`);
};

export const bulkArchiveExternalSources = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: TopicBulkActionFilters
): Promise<undefined> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources/bulk_archive`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({ bulkSelection, ...removeEmptyKeys(filterConfig) })
    ),
  });

  if (response.status === 200) return;

  throw new Error(`Error archive external sources: ${response.status}`);
};

export const bulkUnarchiveExternalSources = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: TopicBulkActionFilters
): Promise<undefined> => {
  const url = `${apiRoot}/programs/${programId}/external_content_sources/bulk_unarchive`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({ bulkSelection, ...removeEmptyKeys(filterConfig) })
    ),
  });

  if (response.status === 200) return;

  throw new Error(`Error unarchive external sources: ${response.status}`);
};
