import React from 'react';
import { NewFilterButton } from 'DesignSystem/Table/NewFilterButton';
import { BooleanFilter, DateFilter } from 'contexts/content/filters';
import NestableMenu from 'shared/NestableMenu';
import { NestableMenuType } from 'shared/NestableMenu/types';

/*
 * This button will display a NestableMenu containing all available filters for a report
 * Currently it will display everything in a single flat list
 * Eventually we will display user attribute filters in a nested submenu
 * */
type PropsType = {
  filters: (BooleanFilter | DateFilter)[];
  onSelectFilter: (filter: BooleanFilter | DateFilter) => void;
  layout?: 'campaigns' | 'journeys';
};
export const NewFiltersButton: React.FC<PropsType> = ({
  filters,
  onSelectFilter,
  layout = 'campaigns',
}) => {
  const rootMenuData: NestableMenuType<
    BooleanFilter | DateFilter
  > = React.useMemo(() => {
    return {
      simpleMenu: {
        onSelect: onSelectFilter,
        items: filters.map((filter) => {
          return { label: filter.label, key: filter.name, value: filter };
        }),
      },
    };
  }, [filters, onSelectFilter]);

  const menuButton = React.useMemo(
    () => (
      <div>
        {/* the nestable menu insists on this for an undetermined reason */}
        <NewFilterButton layout={layout} />
      </div>
    ),
    [layout]
  );

  return <NestableMenu menuButton={menuButton} rootMenuData={rootMenuData} />;
};
