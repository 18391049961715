import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useProgram } from 'contexts/program';
import { WithPermission } from 'shared/WithPermission';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { useDebounce } from 'hooks/useDebounce';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { EmailAliasList } from './EmailAliasList';
import { FiltersBar } from './FiltersBar';

export const EmailAlias: React.FC<RouteComponentProps> = () => {
  const [status, setStatus] = useFilterDropdownQueryString('status', [
    'active',
  ]);

  const filterChangeCallbacks: { [key: string]: (values: string[]) => void } = {
    status: setStatus,
  };

  function onFilterChange(filterName: string, values: string[]) {
    filterChangeCallbacks[filterName](values);
  }

  const parentRef = React.useRef<HTMLDivElement>(null);
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
    meta,
  } = useEmailAliasesInfiniteQuery({
    programId,
    search: useDebounce(searchTerm),
    status: useDebounce(status),
  });

  const authorAliasesEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Configure.AuthorAliases').data
      ?.value as boolean) || false;
  const tabs = authorAliasesEnabled
    ? [
        { to: 'email-alias', label: 'Email Aliases' },
        { to: 'author-aliases', label: 'Author Aliases' },
      ]
    : [];
  const title = authorAliasesEnabled ? 'Aliases' : 'Email Alias';
  return (
    <WithPermission permissions={['confContentAccess']}>
      <ListPage
        title={title}
        breadcrumbs={[{ to: '..', label: 'Configure' }, { label: title }]}
        actions={[
          {
            icon: <Icon name="Plus" fill="currentColor" />,
            label: 'Email Alias',
            to: 'new',
          },
        ]}
        tabs={tabs}
        filterbar={
          <FiltersBar
            status={status}
            onFilterChange={onFilterChange}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        }
      >
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <EmailAliasList
              parentRef={parentRef}
              errorMessage={errorMessage}
              isLoading={isLoading}
              emailAliases={data}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              meta={meta}
              height={height}
              filterConfig={{
                status,
                search: searchTerm,
              }}
            />
          )}
        </InfiniteContainer>
      </ListPage>
    </WithPermission>
  );
};
