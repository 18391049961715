import React from 'react';
import cx from 'classnames';
import defaultAvatar from 'assets/images/User.png';
import { DateTime } from 'luxon';
import {
  ProcessedAuditEvent,
  concatChangesMessage,
} from 'components/publisher/theme/CampaignHistoryModal/auditEvents';
import { Subheading, Body, color } from 'DesignSystem/Typography';
import { capitalizeFirstLetter } from 'utility/strings';
import styles from './campaign-history-modal.module.css';

const buildMessage = (action: string, messages: string[]) => {
  const message = concatChangesMessage(
    messages?.length > 0 ? messages : [action]
  ).trimStart();
  const messageWords = message.split(' ');
  const boldText = capitalizeFirstLetter(messageWords.slice(0, 2).join(' '));
  const restText = messageWords.slice(2).join(' ');
  return { boldText, restText };
};

type AuditEventItemProps = {
  auditEvent: ProcessedAuditEvent;
};
export const AuditEventItem: React.FC<AuditEventItemProps> = (
  props: AuditEventItemProps
) => {
  const {
    auditEvent: { author_name, action, messages, occurred_at, avatar_url },
  } = props;
  const timestamp = DateTime.fromISO(occurred_at).toLocaleString(
    DateTime.DATETIME_MED
  );
  const { boldText, restText } = buildMessage(action, messages);
  return (
    <div className={cx(styles.itemWrapper)}>
      <div className={cx(styles.row)}>
        <div
          title="User's avatar"
          className={cx(styles.avatarImage, {
            [styles.DefaultAvatarImage]: !avatar_url,
          })}
          style={{
            backgroundImage: `url(${avatar_url}), linear-gradient(var(--color-brandFull) 80%, var(--color-brandShadeLight))`,
          }}
        >
          {avatar_url ? null : (
            <img
              src={defaultAvatar}
              alt="Default Avatar"
              className={cx(styles.avatarImg)}
            />
          )}
        </div>
        <Subheading bold color={color.brandTintDark}>
          {author_name}
        </Subheading>
        <Body color={color.gray50}>{timestamp}</Body>
      </div>
      <div className={cx(styles.messageRow)}>
        <div />
        <Body color={color.gray60}>
          <b>{boldText} </b>
          {restText}
        </Body>
      </div>
    </div>
  );
};
