import { useQuery } from 'react-query';
import { Program } from 'models/program';
import { QueryResponse } from './common';
import {
  DraftsCount,
  DraftsCountData,
  FeedPostsCount,
  FeedPostsCountData,
  fetchDraftsCount,
  fetchFeedPostsCount,
  fetchFlaggedCount,
  fetchSubmittedCount,
  FlaggedCount,
  FlaggedCommentsCountData,
  SubmittedCount,
  SubmittedCountData,
} from '../services/api-post-metadata';

const mapDataToSubmittedCount = (resp: SubmittedCountData): SubmittedCount => {
  return { ...resp.data };
};

export const useSubmittedCountQuery = (
  programId: number
): QueryResponse<SubmittedCount> => {
  const { isLoading, error, data } = useQuery<SubmittedCountData, Error>(
    ['submitted_count', programId],
    () => fetchSubmittedCount({ programId }),
    { retry: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToSubmittedCount(data),
  };
};

const mapDataToFlaggedCommentsCount = (
  resp: FlaggedCommentsCountData
): FlaggedCount => {
  return { ...resp.data };
};

export const flaggedCountQueryKey = (
  programId: Program['id']
): ['flagged_count', Program['id']] => ['flagged_count', programId];

export const useFlaggedCountQuery = (
  programId: number
): QueryResponse<FlaggedCount> => {
  const { isLoading, error, data } = useQuery<FlaggedCommentsCountData, Error>(
    flaggedCountQueryKey(programId),
    () => fetchFlaggedCount({ programId }),
    { retry: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToFlaggedCommentsCount(data),
  };
};

const mapDataToDraftsCount = (resp: DraftsCountData): DraftsCount => {
  return { ...resp.data };
};

export const useDraftsCountQuery = (
  programId: number,
  adminId: number
): QueryResponse<DraftsCount> => {
  const { isLoading, error, data } = useQuery<DraftsCountData, Error>(
    ['drafts_count', programId, adminId],
    () => fetchDraftsCount({ programId, adminId }),
    { retry: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToDraftsCount(data),
  };
};

const mapDataToFeedPostsCount = (resp: FeedPostsCountData): FeedPostsCount => {
  return { ...resp.data };
};

export const useFeedPostsCountQuery = (
  programId: number
): QueryResponse<FeedPostsCount> => {
  const { isLoading, error, data } = useQuery<FeedPostsCountData, Error>(
    ['feed_posts_count', programId],
    () => fetchFeedPostsCount({ programId }),
    { retry: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToFeedPostsCount(data),
  };
};
