import React from 'react';
import { useNavigate } from '@reach/router';
import { useProgram } from 'contexts/program';
import { Template } from 'models/library';
import { Fancy as PostPreview } from 'shared/publisher/PostPreview';
import { getScrollParent } from 'utility/dom';
import styles from './preview.module.css';

type PropsType = {
  template: Template;
};

export const MoreLikeThisPreview: React.FC<PropsType> = ({ template }) => {
  const { id: programId } = useProgram();
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const handleClick = React.useCallback(() => {
    const main = getScrollParent(ref.current);
    if (main) {
      main.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    navigate(`/${programId}/app/preview/template/${template.id}`);
  }, [navigate, programId, template.id]);

  return (
    <button
      ref={ref}
      type="button"
      key={template.id}
      onClick={handleClick}
      className={styles.moreLikeThis}
    >
      <div className={styles.moreLikePreview}>
        <PostPreview width={262} height={270} post={template.asset.template} />
      </div>
      <div className={styles.assetType}>Campaign template</div>
      <div className={styles.previewTitle}>{template.title}</div>
    </button>
  );
};
