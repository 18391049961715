import * as React from 'react';
import { DefinitionBlock, Styling, CustomFont } from 'models/publisher/block';
import { useBlocksEditor } from 'contexts/publisher/compose/blocks';
import { usePublisherStyleEditor } from 'hooks/publisher-style-editor';
import { usePreview } from 'contexts/publisher/compose/preview';
import { defaultPost } from 'models/publisher/post';
import { useNavigationBlocker } from 'contexts/publisher/compose/navigation-blocker';
import { FontOption } from 'models/publisher/style';
import { Design } from 'models/design';
import { PublisherType } from 'models/library';
import { useFontOptions } from 'hooks/useFontOptions';

export type DesignEditor = {
  blocksEditor: ReturnType<typeof useBlocksEditor>;
  styleEditor: ReturnType<typeof usePublisherStyleEditor>;
  preview: ReturnType<typeof usePreview>;
  navigationBlocker: ReturnType<typeof useNavigationBlocker>;
  ready: boolean;
  previewEnabled?: boolean;
  enablePreview?: (value: boolean) => void;
};

export function useDesignHooks({
  design,
  update,
  publisherType,
}: {
  design: Design;
  update: (changes: Design) => void;
  publisherType: PublisherType;
}): DesignEditor {
  const { fontOptions, isLoading: isLoadingFonts } = useFontOptions();
  const updateDesignBlocks = React.useCallback(
    (blocks: DefinitionBlock[]) => {
      return update({
        ...design,
        blocks,
        styles: design.styles
          ? {
              ...design.styles,
              fontStylesheets: buildFontStylesheets(
                fontOptions,
                design.blocks,
                design.styles
              ),
            }
          : undefined,
      });
    },
    [design, update, fontOptions]
  );
  const updateDesignStyling = React.useCallback(
    (styles: Styling) => {
      update({
        ...design,
        styles: {
          ...styles,
          fontStylesheets: buildFontStylesheets(
            fontOptions,
            design.blocks,
            styles
          ),
        },
      });
    },
    [design, update, fontOptions]
  );

  const blocksEditor = useBlocksEditor(
    design.blocks,
    updateDesignBlocks,
    publisherType
  );

  const styleEditor = usePublisherStyleEditor({
    styleFor: 'global',
    initialStyleData: design.styles || defaultPost.styles,
    onChange: updateDesignStyling,
    fontOptions,
  });

  const preview = usePreview({
    blocks: design.blocks,
    settings: defaultPost.settings,
    styles: design.styles || defaultPost.styles,
  });
  const navigationBlocker = useNavigationBlocker();
  const [previewEnabled, setPreviewEnabled] = React.useState(false);
  const enablePreview = (value: boolean) => setPreviewEnabled(value);

  return {
    blocksEditor,
    styleEditor,
    preview,
    navigationBlocker,
    ready: !isLoadingFonts,
    previewEnabled,
    enablePreview,
  };
}

function buildFontStylesheets(
  fontOptions: FontOption[],
  blocks: DefinitionBlock[],
  styling: Styling
): CustomFont[] {
  const stylingFonts = styling?.fonts ? Object.values(styling.fonts) : [];
  const activeFonts = [
    ...stylingFonts,
    ...blocks.flatMap((block) => Object.values(block.style_data?.fonts ?? {})),
  ];
  return fontOptions
    .filter((option) => activeFonts.includes(option.value) && option.url)
    .map((option) => ({
      name: option.value,
      url: option.url,
      fallbackFont: option.value,
      generic: option.generic,
    })) as CustomFont[];
}
