import * as React from 'react';
import cx from 'classnames';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { ChevronDown } from 'shared/icons';
import { FormatData, AspectRatio } from 'models/publisher/format';
import { DefinitionBlock } from 'models/publisher/block';
import buttonStyles from 'shared/styles/buttons.module.css';
import { deepMerge } from 'utility/deep-merge';
import { SidebarPopout } from '../SidebarPopout';
import styles from '../sidebar-dropdown.module.css';

type DefaultOptionsList = Array<{
  label: string;
  value: AspectRatio | undefined;
}>;
const defaultOptionsList: DefaultOptionsList = [
  { label: 'No cropping', value: AspectRatio.None },
  { label: 'Landscape (2:1)', value: AspectRatio.Landscape },
  { label: 'Portrait (3:4)', value: AspectRatio.Portrait },
  { label: 'Square (1:1)', value: AspectRatio.Square },
  { label: 'Wide (3:1)', value: AspectRatio.Wide },
];

export const ImageCropping: React.FC<{
  id: string;
  block: DefinitionBlock;
  onChange: (id: string, data: FormatData) => void;
}> = ({ id, block, onChange }) => {
  const options = React.useMemo(() => {
    const aspect_ratios = block?.format_options?.aspect_ratios || [];
    return defaultOptionsList.reduce((agg, defaultOption) => {
      if (aspect_ratios.some((opt) => opt === defaultOption.value)) {
        agg.push(defaultOption);
      }
      return agg;
    }, [] as DefaultOptionsList);
  }, [block?.format_options?.aspect_ratios]);
  const value = block.format_data?.aspect_ratio;
  const choosenOption = React.useMemo(
    () => options.find((opt) => opt.value === value) || options[0],
    [options, value]
  );
  const changeFormatting = React.useCallback(
    (data: FormatData) => {
      onChange(id, deepMerge(block.format_data ?? {}, data));
    },
    [id, block, onChange]
  );

  return (
    <div className="kai-flex-row">
      <strong>Image cropping</strong>
      <SidebarPopout
        dataTestButton="image-cropping-dropdown"
        buttonClassName={styles.menuButton}
        buttonChildren={
          <>
            <span className={styles.label}>{choosenOption?.label ?? ''}</span>
            <span className={styles.append}>
              <ChevronDown />
            </span>
          </>
        }
        menuChildren={
          <ol className={styles.dropdown}>
            {options.map((option) => (
              <li
                key={option.value}
                className={cx(styles.option, styles.label)}
              >
                <button
                  type="button"
                  onClick={() =>
                    changeFormatting({ aspect_ratio: option.value })
                  }
                  className={buttonStyles.buttonReset}
                >
                  {option.label}
                </button>
                {option.value === value && <SVGIcon name="Check" />}
              </li>
            ))}
          </ol>
        }
      />
    </div>
  );
};

export function canEditImageCroppingFromOptionsList(
  block: DefinitionBlock
): boolean {
  return (block.format_options?.aspect_ratios?.length ?? 0) > 1;
}
