import React from 'react';
import nlp from 'compromise';
import nlp_sentences from 'compromise-sentences';
import { VARIABLES_RE } from './variablesRegex';

// - Use it to pluralize a word unconditionally
// - Use it to pluralize a word, conditionally based on a quantity

const PLURAL_EXCEPTIONS: { [singular: string]: string } = {
  content: 'content',
  person: 'people',
  has: 'have',
};

const isVowel = (char: string): boolean =>
  ['a', 'e', 'i', 'o', 'u'].indexOf(char.toLowerCase()) !== -1;

const pluralizeWord = (quantity: number, singular: string, plural?: string) => {
  if (quantity === 1) return singular;
  if (plural) return plural;
  if (PLURAL_EXCEPTIONS[singular]) return PLURAL_EXCEPTIONS[singular];
  if (
    singular[singular.length - 1] === 'y' &&
    !isVowel(singular[singular.length - 2])
  )
    return `${singular.slice(0, singular.length - 1)}ies`;
  if (singular[singular.length - 1] === 's') return `${singular}es`;
  return `${singular}s`;
};

export function pluralize(
  quantityOrSingular: number | string,
  singularOrPlural?: string,
  irregularPlural?: string
): string {
  if (typeof quantityOrSingular === 'number')
    return pluralizeWord(
      quantityOrSingular,
      `${singularOrPlural}`,
      irregularPlural
    );
  if (!Number.isNaN(parseInt(quantityOrSingular, 10)))
    return pluralizeWord(
      parseInt(quantityOrSingular, 10),
      `${singularOrPlural}`,
      irregularPlural
    );
  return pluralizeWord(0, quantityOrSingular, singularOrPlural);
}

export function titleCase(text: string): string {
  return text
    .split(/\s/)
    .map((word) => {
      const chars = Array.from(word);
      if (chars[0] === undefined) return '';
      const first = chars[0].toLocaleUpperCase();
      const rest = chars.slice(1).join('').toLocaleLowerCase();
      return `${first}${rest}`;
    })
    .join(' ');
}

export function stripHtml(html: string): string {
  // A `null` has been spotted in the wild.
  // Defensively cast to an empty string.
  let source = html || '';
  // Add a space between </p> and anything else to separate content.
  source = source.replace(/<\/p>(.)/g, '</p> $1');
  if (!source) return ''; // don't bother firing up a DOMParser.

  const domParser = new DOMParser();
  const doc = domParser.parseFromString(source, 'text/html');
  return doc.documentElement?.textContent ?? '';
}

const initNlp = (() => {
  let initiated = false;
  return () => {
    if (!initiated) {
      nlp.extend(nlp_sentences);
      initiated = true;
    }
  };
})();

export function truncate(
  text: string,
  options?: {
    unit?: 'character' | 'sentence';
    size?: number;
    end?: string;
    fallback?: string;
  }
): string {
  const size = options?.size ?? 100;
  const unit = options?.unit ?? 'character';
  const end = options?.end ?? '...';
  const fallback = options?.fallback ?? '';

  if (!text) {
    return fallback;
  }

  let result = '';

  if (unit === 'character') {
    if (text.length <= size) {
      return text;
    }

    result = text.slice(0, size - end.length);
  } else if (unit === 'sentence') {
    initNlp();
    result = nlp(text)
      .sentences()
      .json()
      .slice(0, size)
      .map(({ text: txt }: { text: string }) => txt)
      .join(' ');
  }

  if (result.length < text.length) result += end;

  return result;
}

export function toCamelCase(str: string): string {
  return str.replace(/_(\w)?/g, (match) => match.substr(1).toUpperCase());
}

export function toSlug(str: string): string {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

export function splitWords(label: string): string {
  if (!label) return '';
  const [firstChar, ...remainingChars] = toCamelCase(label).split('');
  const str = `${firstChar.toUpperCase()}${remainingChars.join('')}`;
  return str.replace(/[A-Z]/g, ' $&').trim();
}

export function equalsCaseInsensitive(s1: string, s2: string): boolean {
  return s1.toLowerCase() === s2.toLowerCase();
}

export function convertSnakeCaseToSentenceCase(label: string): string {
  const str = label.replace(/[_\s]+/g, ' ');
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export function isUrlValid(text: string): boolean {
  const pattern = /^(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/g;
  return pattern.test(text);
}

export function containLiquidVars(text: string): boolean {
  const pattern = /(\{{2} *[a-zA-Z0-9_]+ *([| *]+default: *["']+[^\n]+["']+ *)?\}{2})/;

  return pattern.test(text);
}

export function extractSentences(
  text: string,
  config: Partial<{ excludeVars: boolean }> = {}
): Array<string> {
  initNlp();

  return nlp(text)
    .sentences()
    .json()
    .filter(
      ({ text: sentence }: { text: string }) =>
        !config.excludeVars || !containLiquidVars(sentence)
    )
    .map(({ text: sentence }: { text: string }) => sentence);
}

/**
 * Counts the number of sentences in a given text.
 * @param text - The input text.
 * @returns The number of sentences in the text.
 */
export function countSentences(text: string): number {
  initNlp();
  // types say length is a function, but it's a number.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return nlp(text).sentences().length;
}

// Stolen from https://github.com/moment/luxon/issues/118
export function getNumberSuffix(num: number): string {
  const th = 'th';
  const rd = 'rd';
  const nd = 'nd';
  const st = 'st';

  if (num === 11 || num === 12 || num === 13) return th;

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case '1':
      return st;
    case '2':
      return nd;
    case '3':
      return rd;
    default:
      return th;
  }
}

export function stripSymbols(text: string): string {
  return text.replace(/[^a-zA-Z0-9\s]/g, '');
}

export const useHasVariables = (): ((string: string) => boolean) => {
  return React.useCallback((string: string) => {
    const values = string.match(VARIABLES_RE);
    return !!(values && values.length > 0);
  }, []);
};
