import { MenuItemsType, ThumbnailType } from 'shared/BannerListItem';
import { WorkflowTemplate } from 'models/workflow-templates';
import { WorkflowTemplateActions } from './useActions';

const BACKGROUND_COLORS = ['#5D7E80', '#5D6480', '#6797AC'];

const filterActive = (items: MenuItemsType) =>
  items.filter((item) => !item.disabled);

export function workflowTemplateToThumbnail(
  template: Pick<WorkflowTemplate, 'name'>,
  index: number
): ThumbnailType {
  return {
    title: template.name[0] ?? '',
    color: 'var(--color-gray05)',
    backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
  };
}

export function workflowTemplateToMenuItems(
  template: WorkflowTemplate,
  actions: WorkflowTemplateActions
): MenuItemsType {
  const { id: workflowTemplateId, status, scope } = template;

  // TODO: move this to the WorkflowTemplate model
  const isArchived = status === 'archived';
  const isPublished = status === 'published';
  const isDraft = status === 'draft';
  const isLocal = scope === 'program';
  const isGlobal = scope === 'global';

  return filterActive([
    {
      title: 'Edit',
      disabled: isArchived,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        actions.edit(workflowTemplateId);
      },
    },
    {
      title: 'Draft',
      disabled: isDraft,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.draft(workflowTemplateId);
      },
    },
    {
      title: 'Activate',
      disabled: isPublished,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.activate(workflowTemplateId);
      },
    },
    {
      title: 'Promote',
      disabled: isGlobal,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.confirmPromote?.showModal();
      },
    },
    {
      title: 'Demote',
      danger: true,
      disabled: isLocal || isArchived,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.confirmDemote?.showModal();
      },
    },
    {
      title: 'Archive',
      danger: true,
      disabled: isArchived,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.confirmArchive?.showModal();
      },
    },
  ]);
}
