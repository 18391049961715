import * as React from 'react';
import { FieldInput, Toggle } from 'DesignSystem/Form';
import { FieldLegend } from './FieldLegend';

export const OpenInNewTabToggle: React.FC<{
  value: boolean;
  onChange: (openInNewTab?: boolean) => void;
  disabled?: boolean;
}> = ({ value = true, onChange, disabled }) => (
  <FieldInput
    legend={<FieldLegend>Open in new tab?</FieldLegend>}
    htmlFor="block-button-link-open-in-new-tab"
  >
    <Toggle
      disabled={disabled}
      htmlForId="block-button-link-open-in-new-tab"
      on={value}
      onChange={onChange}
    />
  </FieldInput>
);
