import React from 'react';
import { Subsection } from 'DesignSystem/Form';
import { Pills } from 'DesignSystem/Components/Pills';
import { Gray10PillClass } from 'DesignSystem/Components/Pill';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { pluralize } from 'utility/text';

export const ConfirmationModalShow: React.FC<{
  modalEnabled: boolean;
  threshold: string;
}> = ({ modalEnabled, threshold }) => {
  const icon = modalEnabled ? (
    <Icon name="CheckCircleOutline" />
  ) : (
    <Icon name="CloseCircleOutline" />
  );

  return (
    <Subsection
      title="Audience Size Confirmation"
      description="Users will be prompted to confirm their selection when the audience size threshold has been reached or exceeded"
    >
      <Pills
        values={[{ id: 1 }]}
        render={() => (
          <>
            Audience Size Confirmation:&nbsp;
            <strong>{(modalEnabled && 'ON') || 'OFF'}</strong>
          </>
        )}
        pillIcon={() => icon}
        pillClassName={Gray10PillClass}
        small
        padding={[0, 8]}
      />
      {modalEnabled && (
        <div style={{ fontSize: '14px', margin: '12px 0' }}>
          Confirmation Criteria:&nbsp;
          <strong>
            {threshold} {pluralize(threshold, 'user')}
          </strong>
        </div>
      )}
    </Subsection>
  );
};
