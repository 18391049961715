import React, { FC } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  Popover,
  Tooltip,
  Alert,
  AlertType,
} from 'DesignSystem/Components';
import { PeopleGroup } from 'shared/icons';
import { useCampaignTarget } from 'components/publisher/settings/SettingsEditor/useCampaignTarget';
import {
  computeMaxRetries,
  hasAudience,
  hasTopic,
} from 'models/publisher/settings';

import { pluralize } from 'utility/text';
import { usePublisher } from 'contexts/publisher';
import { useOrchestrationInsights } from 'hooks/orchestration-insights';
import { Pills } from 'DesignSystem/Components/Pills';
import { Topic } from 'models/topic';
import { Body, Span } from 'DesignSystem/Typography';
import { Audience } from 'models/audience';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useAudienceList } from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/useAudienceList';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { DefinitionBlock, FieldData, Targets } from 'models/publisher/block';
import { MAIcon, IconNames } from 'shared/MAIcon';
import { useContentUserCount } from 'hooks/useContentUserCount';
import { useCurrentUserQuery } from 'hooks/user';
import { StatusLabel } from './StatusLabel';
import styles from './status-section.module.css';
import { SectionCard } from '../SectionCard';
import { SuggestionsSelect } from './SuggestionsSection/SuggestionsSelect';

const Bold = Span(styles.Bold);

type UniqueUsersProps = {
  usersCount: number;
};

export const UniqueUsers: FC<UniqueUsersProps> = ({ usersCount }) => (
  <Box margin={[0, 0, 0, 'auto']}>
    <Box
      className={styles.infoBox}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      Unique Users:
      <span
        style={{ marginLeft: 'auto', alignItems: 'center', display: 'flex' }}
      >
        <PeopleGroup style={{ marginLeft: 24 }} width={15} height={15} />
        <span className={styles.usersCount}>{usersCount}</span>
      </span>
    </Box>
  </Box>
);

const renderTopicPill = (value: Topic) => (
  <>
    {value.name}&nbsp;<Bold>·</Bold>&nbsp;{value.followerCount}
  </>
);

const renderAudiencePill = (value: Audience) => (
  <>
    {value.title || value.name}&nbsp;<Bold>·</Bold>&nbsp;{value.totalUsers}
  </>
);

const renderPlatformPill = (value: { name: Targets }) => {
  const icons: Record<Targets, IconNames> = {
    ios: 'phone_iphone',
    android: 'phone_iphone',
    email: 'mail',
    microapp: 'dashboard',
    web: 'laptop_windows',
  };

  return (
    <div className={styles.platform}>
      <MAIcon className={styles.icon} name={icons[value.name]} />
      <span className={styles.platformText}>{value.name}</span>
    </div>
  );
};

interface PillsTooltipProps<T extends { id?: string | number }> {
  values: Array<T>;
  title: string;
  label: string;
  render: (pill: T) => React.ReactNode;
}

export function PillsTooltip<T extends { id?: string | number }>(
  props: React.PropsWithChildren<PillsTooltipProps<T>>
): JSX.Element {
  const { values, label, render, title } = props;

  return (
    <Tooltip
      type="blank"
      hideDelay={0}
      showDelay={0}
      maxWidth={600}
      content={
        <Popover centered className={styles.TooltipPopover}>
          <Box margin={[0, 0, 17, 0]}>
            <Body bold>{title}</Body>
          </Box>
          <Pills values={values} render={render} />
        </Popover>
      }
    >
      <span className={styles.tooltipText}>
        {values.length} {pluralize(values.length, label)}
      </span>
    </Tooltip>
  );
}

const schedulingPublishedAt = (publishedAt: DateTime | undefined): string => {
  return publishedAt
    ? publishedAt.toFormat("MM/dd/yy 'at' hh:mma")
    : 'Immediately';
};

const SchedulingStatus = ({
  publishedAt,
}: {
  publishedAt: DateTime | undefined;
}) => (
  <div>
    Your campaign is scheduled for{' '}
    <strong>{schedulingPublishedAt(publishedAt)}</strong>
  </div>
);

const VisibilityStatus = ({
  blocks,
}: {
  blocks: DefinitionBlock<FieldData>[];
}) => {
  let platforms: Targets[] = [];
  blocks.forEach((block) => {
    if (block.target && block.target.excluded.length > 0) {
      platforms = [...platforms, ...block.target.excluded];
    }
  });
  const uniquePlatform: { id: Targets; name: Targets }[] = [];
  new Set(platforms).forEach((value) => {
    uniquePlatform.push({ id: value, name: value });
  });

  if (uniquePlatform.length < 1) return null;
  return (
    <li>
      <div>
        This campaign has{' '}
        <PillsTooltip
          render={renderPlatformPill}
          values={uniquePlatform}
          label="channel"
          title="Channels"
        />{' '}
        in which one or more blocks are hidden.
      </div>
    </li>
  );
};

export type TopicUsersStatusProps = {
  usersCount: number;
  contentTopics: Topic[];
};

export const TopicUsersStatus: FC<TopicUsersStatusProps> = ({
  usersCount,
  contentTopics,
}) => (
  <div>
    This campaign will be viewable by{' '}
    <strong>
      {usersCount} {pluralize(usersCount, 'user')}
    </strong>{' '}
    across{' '}
    <PillsTooltip
      render={renderTopicPill}
      values={contentTopics}
      label="topic"
      title="Topics"
    />
  </div>
);

const AudienceUsersStatus = ({
  usersCount,
  audiences,
  publishedAt,
  isEngagementBoost,
  daysCount,
}: {
  usersCount: number;
  audiences: Audience[];
  publishedAt: DateTime;
  isEngagementBoost: boolean;
  daysCount: number;
}) => (
  <div>
    This campaign will be sent to approx.{' '}
    <strong>
      {usersCount} {pluralize(usersCount, 'user')}
    </strong>{' '}
    across{' '}
    <PillsTooltip
      render={renderAudiencePill}
      values={audiences}
      label="audience"
      title="Audiences"
    />{' '}
    {!isEngagementBoost ? (
      <>at {publishedAt.toFormat('MM/dd/yy hh:mma')}</>
    ) : (
      <>
        over the next {daysCount} {pluralize(daysCount, 'day')}{' '}
      </>
    )}
  </div>
);

const RatargetingAttempts = ({ count = 0 }: { count: number }) => (
  <div>
    A maximum of{' '}
    <strong>
      {count} retargeting {pluralize(count, 'attempt')}
    </strong>{' '}
    will be made
  </div>
);

export const StatusSection: React.FC<{
  isReadyToPublish: boolean;
}> = ({ isReadyToPublish }) => {
  const { audiences, all } = useCampaignTarget();

  const {
    post: { settings, content, blocks },
  } = usePublisher();

  const creatingUser = content.createdBy;
  const currentUser = useCurrentUserQuery().data;
  const { id: programId } = useProgram();
  const campaignCreatorPermission = Boolean(
    useFeatureFlagsQuery(
      programId,
      'Studio.Delivery.CampaignCreatorPermissions'
    ).data?.value
  );

  const {
    contentPermissions: { canEdit, canPublish },
    permissions: { audienceAccess },
  } = useSettings();
  const audiencesData = useAudienceList([], '', () => {}, []);

  const { maxRetries } = useOrchestrationInsights(
    settings,
    content?.id || undefined
  );
  const { topicSubscribersCount, uniqueUserCount } = useContentUserCount();

  return (
    <SectionCard padding={[31, 34]}>
      <>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <StatusLabel isReadyToPublish={isReadyToPublish} />
          {!all.isLoading && <UniqueUsers usersCount={uniqueUserCount || 0} />}
        </Box>
        <Box margin={[33, 0, 0, 66]}>
          <ul className={styles.statusItems}>
            <li>
              <SchedulingStatus publishedAt={settings.publishedAt} />
            </li>
            {hasTopic(settings) && (
              <li>
                <TopicUsersStatus
                  usersCount={topicSubscribersCount ?? 0}
                  contentTopics={settings.contentTopics}
                />
              </li>
            )}
            {hasAudience(settings) && (
              <>
                <li>
                  <AudienceUsersStatus
                    usersCount={audiences.users?.totalObjects || 0}
                    audiences={settings.audiences}
                    publishedAt={settings.publishedAt ?? DateTime.now()}
                    isEngagementBoost={
                      settings.retries !== 0 ||
                      !!settings.optimizedDeliveryEnabled
                    }
                    daysCount={settings.duration?.value || 0}
                  />
                </li>
                {settings.retries !== 0 && (
                  <li>
                    <RatargetingAttempts
                      count={computeMaxRetries(settings, maxRetries)}
                    />
                  </li>
                )}
              </>
            )}
            <VisibilityStatus blocks={blocks} />
          </ul>
        </Box>
        {campaignCreatorPermission &&
          hasAudience(settings) &&
          canPublish &&
          canEdit &&
          creatingUser &&
          creatingUser !== currentUser?.id && (
            <div style={{ paddingTop: '20px' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Alert
                  type={AlertType.warning}
                  message={
                    "You're about to publish a campaign that you didn't create. The above audience(s) reflect the campaign creator's restrictions. To apply your own restrictions, please recreate or duplicate the campaign."
                  }
                  bgColor="yellow"
                  enableIcon
                />
              </Box>
            </div>
          )}
      </>
      {audienceAccess && audiencesData.data.length > 0 && (
        <SuggestionsSelect disabled={!canEdit} />
      )}
    </SectionCard>
  );
};
