import React from 'react';
import { DateTime } from 'luxon';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useGenerateErrorReport } from 'hooks/bulk-update-permissions';
import { Icon } from 'shared/Icon';
import { download } from 'utility/download';
import { titleCase } from 'utility/text';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';
import {
  generateCompletionString,
  generateDetailReportString,
} from '../../shared/helper';
import styles from './sidebar.module.css';

export const EmptySidebar: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.col}>
        <div>
          <span>Status</span>
          <span>Not yet created</span>
        </div>
        <div>
          <span>Records</span>
          <span>NA</span>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        <div className={styles.Disabled}>
          <span>
            <Icon iconName="Download" iconType="SVG" />
          </span>
          <span>Download failed records</span>
        </div>
      </div>
    </div>
  );
};

export const Sidebar: React.FC<{
  job: BulkUpdatePermissionsJob;
}> = ({ job }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const [csvRows, setCsvRows] = React.useState<string>('');
  React.useEffect(() => {
    if (!csvRows) return;
    const trigger: HTMLButtonElement | null = document.querySelector(
      '.csv-trigger'
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    trigger!.click();
    setCsvRows('');
  }, [csvRows]);

  const { download: downloadErrorReport } = useGenerateErrorReport(programId, {
    onSuccess: (downloadData: Blob) => {
      download(downloadData, 'error-report.csv');
    },
    onError: () => {
      setFlashMessage({
        message: 'Unable to download error file.',
        severity: 'error',
      });
    },
  });

  const handleErrorReportDownload = (jobId: number) => {
    downloadErrorReport(jobId);
  };

  const date = (d: string | undefined) => {
    return d ? DateTime.fromJSDate(new Date(d)) : undefined;
  };

  const hasDownload = job.processedAt && job.failedUsers > 0;
  const createdAt = date(job.submittedDatetime)?.toFormat('MMMM dd, yyyy');
  const completionString = generateCompletionString(job);
  const reportString = generateDetailReportString(job);

  return (
    <div className={styles.wrapper}>
      <div className={styles.col}>
        <div>
          <span>Created</span>
          <span>{createdAt}</span>
        </div>
        <div>
          <span>Created by</span>
          <span
            className={styles.wordWrap}
            title={job.createdByData.displayName}
          >
            {job.createdByData.displayName}
          </span>
        </div>
        <div>
          <span>Status</span>
          <span>{titleCase(`${job.state}`)}</span>
        </div>
        <div>
          <span>Duration</span>
          <span>{completionString}</span>
        </div>
        <div>
          <span>Records</span>
          <span>{reportString}</span>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        <div
          className={hasDownload ? styles.Active : styles.Disabled}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => handleErrorReportDownload(Number(job.id))}
        >
          <span>
            <Icon iconName="Download" iconType="SVG" />
          </span>
          <span>Download failed records</span>
        </div>
      </div>
    </div>
  );
};
