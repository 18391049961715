import * as React from 'react';
import { useProgram } from 'contexts/program';
import { contrastColor } from 'utility/contrast-color';
import * as colors from 'models/colors';

type UseColorPreviewProps = {
  onChange: (value: string) => void;
  value?: string;
};

export function useColorPreview({
  value = '',
  onChange: upstreamOnChange,
}: UseColorPreviewProps): {
  isValid: boolean;
  contrast: string;
  error?: string;
  onChange: (value: string) => void;
  programColors: string[];
} {
  const [error, setError] = React.useState('');
  const onChange = React.useCallback(
    (hex: string) => {
      setError(colors.validateHex(hex));
      upstreamOnChange(hex);
    },
    [upstreamOnChange]
  );

  const { themeColor, secondaryColor } = useProgram();
  const programColors = React.useMemo(
    () =>
      [secondaryColor, themeColor].filter((c) => c).filter(colors.isValidHex),
    [themeColor, secondaryColor]
  );

  const isValid = React.useMemo(() => colors.isValidHex(value), [value]);
  const contrast = React.useMemo(() => (isValid ? contrastColor(value) : ''), [
    value,
    isValid,
  ]);

  return {
    isValid,
    contrast,
    error,
    onChange,
    programColors,
  };
}
