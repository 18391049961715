import React, { FC, RefObject } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

interface MainContainerProps {
  className?: string;
}

export const MainContainerCtx = React.createContext<RefObject<HTMLDivElement>>(
  {} as RefObject<HTMLDivElement>
);

const MainContainer: FC<MainContainerProps> = ({ children, className }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div
      id="main-container"
      className={cx(styles.Container, className)}
      ref={ref}
    >
      <MainContainerCtx.Provider value={ref}>
        {children}
      </MainContainerCtx.Provider>
    </div>
  );
};

export default MainContainer;
