import * as React from 'react';
import {
  LinkIcon,
  LibraryIcon,
} from 'components/publisher/blocks/forms/fields/shared/SourceMenu';
import { EXTERNAL, LIBRARY } from '../../shared/SourceMenuConst';

export const SOURCE_MENU_OPTIONS = [
  { name: 'URL', source: EXTERNAL, icon: <LinkIcon /> },
  {
    name: 'Content Library',
    source: LIBRARY,
    icon: <LibraryIcon />,
  },
];
