import React from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body, Caption } from 'DesignSystem/Typography';
import { useFlashMessage } from 'contexts/flasher';
import { useCopyDesignMutation } from 'hooks/persist-design';
import { CommunicationStep } from 'models/journeys/journey';
import { ValidationError } from 'services/Errors/ValidationError';
import { Thumbnail } from 'shared/Thumbnail';
import { CircledInfo } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useJourneyState } from 'contexts/journeys/journey';
import { JourneyListItem } from './useJourneyList';
import styles from './journey-content-list-drawer.module.css';

export const JourneyContentListItem: React.FC<{
  content: JourneyListItem;
  onSelectContent: (content: Partial<CommunicationStep>) => void;
}> = ({ content, onSelectContent }) => {
  const { setFlashMessage } = useFlashMessage();
  const { activeStep } = useJourneyState();
  const { isLoading, mutate: copyDesign } = useCopyDesignMutation();

  const hasDataset = content.hasPfFile;
  const isDisabled = hasDataset || isLoading;

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    copyDesign(
      {
        id: content.id,
        sourceType: content.type,
        parentType: 'CommunicationNode',
        parentSource: 'hermes',
        parentId: activeStep?.id,
      },
      {
        onSuccess(design) {
          onSelectContent({
            designId: Number(design.id),
            title: content.title,
            channels: content.channels,
            author: content.author,
          });
        },
        onError(error) {
          let { message } = error;
          if (error instanceof ValidationError) {
            const [validationError] = JSON.parse(message).errors;
            message = validationError?.detail ?? 'A validation error occurred.';
          }
          setFlashMessage({
            message,
            severity: 'error',
          });
        },
      }
    );
  };

  const className = cx(styles.journeyContentListItem, {
    [styles.journeyContentListItemDisabled]: isDisabled,
  });

  return (
    <div className={className}>
      <button type="button" onClick={handleClick}>
        <Flex spread className={styles.journeyContentListItemBody}>
          <div className={styles.journeyContentListItemThumbnail}>
            <Thumbnail
              backgroundColor="#b8a0b6"
              imageUrl={content.imageUrl}
              size="medium"
              title={content.title}
            />
          </div>
          <Flex column className={styles.journeyContentListItemTitle}>
            <Body>{content.title}</Body>
            <Caption>{content.summary}</Caption>
          </Flex>
          <Caption>
            <time dateTime={content.updatedAt}>
              {content.updatedAt
                ? DateTime.fromISO(content.updatedAt).toFormat('MMM d, y')
                : ''}
            </time>
          </Caption>
        </Flex>
        {hasDataset && (
          <div className={styles.journeyContentListItemInfoMessage}>
            <CircledInfo /> This campaign relies on a dataset file and cannot be
            used in a journey.
          </div>
        )}
        {isLoading && (
          <div className={styles.journeyContentListItemLoading}>
            <LoadingSpinner size="medium" />
          </div>
        )}
      </button>
    </div>
  );
};
