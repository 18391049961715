import React, { FC, useCallback } from 'react';
import {
  BigIconButton,
  Button,
  Icon,
} from '@socialchorus/shared-ui-components';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';
import { useTopicFormCtx } from '../../../../../../context';
import styles from './styles.module.css';
import { DEFAULT_SHORTCUT_ICON } from '../../../../../common/constants';
import { ModalType } from '../../../../../../context/modals';

function Placeholder({ onCreateShortcut }: { onCreateShortcut: () => void }) {
  return (
    <CardPlaceholder>
      There are no Shortcuts in this topic yet
      <Button
        variant="primary"
        label="Shortcuts"
        leftIcon={<Icon>add</Icon>}
        onClick={onCreateShortcut}
      />
    </CardPlaceholder>
  );
}

const ShortcutsPreview: FC = () => {
  const {
    state: {
      fields: { shortcuts },
    },
    toggleModal,
    selectTab,
  } = useTopicFormCtx();
  const handleViewAll = useCallback(() => {
    selectTab('shortcuts');
  }, [selectTab]);

  const handleCreate = useCallback(() => {
    toggleModal({ type: ModalType.EditShortcut });
  }, [toggleModal]);

  let content;
  if (!shortcuts.length) {
    content = <Placeholder onCreateShortcut={handleCreate} />;
  } else {
    content = (
      <div className={styles.shortcutList}>
        {shortcuts.slice(0, 6).map((shortcut) => (
          <BigIconButton
            key={shortcut.id}
            imgSrc={shortcut?.iconUrl ?? DEFAULT_SHORTCUT_ICON}
            label={shortcut.name}
          />
        ))}
      </div>
    );
  }

  return (
    <PreviewCard
      title="Shortcuts"
      link={<CardLink onClick={handleViewAll}>View all</CardLink>}
    >
      {content}
    </PreviewCard>
  );
};

export default ShortcutsPreview;
