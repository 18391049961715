import React from 'react';

import { useProgram } from 'contexts/program';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import { useInfiniteApiQuery } from 'hooks/common';
import { useDebounce } from 'hooks/useDebounce';
import { SelectFilter } from 'models/language-controls/filter';
import { fetchLanguageControlsCreators } from 'services/api-language-controls';
import { ClickDropdown, ClickDropdownHandle } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';

type PropsType = {
  filter: SelectFilter;
  onChange: (filter: SelectFilter) => void;
};

type Creator = {
  id: number;
  displayName: string;
};

export const CreatorFilter: React.FC<PropsType> = ({ filter, onChange }) => {
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const { id: programId } = useProgram();

  const [search, setSearch] = React.useState<string>();

  const {
    data: creators,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery(
    'language-control-creators',
    fetchLanguageControlsCreators,
    {
      programId,
      search: useDebounce(search),
    }
  );

  const creatorsByRowId: { [key: string]: Creator } = {};
  const rowIds: string[] = [];

  creators.forEach((creator) => {
    creatorsByRowId[`${creator.id}`] = creator;
    rowIds.push(`${creator.id}`);
  });

  const selectedValues = filter.value.map(
    (id) => creatorsByRowId[id].displayName
  );

  const onSelectedIdsChange = (ids: string[]) =>
    onChange({
      ...filter,
      value: ids,
    });

  const renderSelectRow = (id: string) => (
    <div>
      <span>{creatorsByRowId[id].displayName}</span>
    </div>
  );

  const dropdownRenderProp = (dismiss: () => void) => {
    onDismissRef.current = dismiss;
    return (
      <div className="filter-dropdown">
        <InfiniteSelect
          rowIds={rowIds}
          rowRenderProp={renderSelectRow}
          maxHeight={300}
          itemHeight={32}
          searchEnabled
          searchTerm={search}
          searchPlaceholder="Search creators"
          onSearchTermChange={setSearch}
          selectedIds={filter.value}
          onSelectedIdsChange={onSelectedIdsChange}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          hasClearSearchButton
          dismissButton="Done"
          onDismissRef={onDismissRef}
        />
      </div>
    );
  };

  const clickDropdownRef = React.useRef<ClickDropdownHandle>(null);

  // When a new dataset is fetched, the width of the dropdown may change causing it to
  // possibly overflow off of the screen. The use of setImmediate is necessary to wait
  // for the InfiniteSelect component to finish rendering before having the dropdown check
  // if its positioning needs to be corrected.
  React.useEffect(() => {
    setImmediate(() => clickDropdownRef.current?.correctDropdownOverflow());
  }, [creators]);

  return (
    <ClickDropdown
      dropdownRenderProp={dropdownRenderProp}
      ref={clickDropdownRef}
    >
      <TriggerButton name="Creator" values={selectedValues} />
    </ClickDropdown>
  );
};
