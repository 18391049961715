import React, { useState } from 'react';
import { Box, DismissType } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { border } from 'DesignSystem/Typography';
import { Plus } from 'shared/icons';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useNotifications } from '../../../Notifications/useNotifications';
import { ContentTitle, Divisor, SettingsCard } from '../../../SettingsCard';
import { EditNotifications } from './EditNotifications';
import { NotificationCard } from '../../../Notifications/NotificationCard';
import { InfoTooltip } from '../../../Shared/InfoTooltip';

export const ReTargeting: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const { reTargetingNotifications } = useNotifications();

  const {
    settings: { deliveryChannels },
  } = useSettings();
  const [editing, setEditing] = useState(false);

  const emailChannel = deliveryChannels?.email;
  const pushChannel = deliveryChannels?.push;
  if (!emailChannel && !pushChannel) return null;

  const addNotifications = (
    dismiss: DismissType,
    setDismissable: (dismissable: boolean) => void
  ) => (
    <EditNotifications
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
    />
  );

  return (
    <>
      <Divisor />
      <SettingsCard
        dataTest="retargeting-notifications-settings"
        showEditButton={reTargetingNotifications.length > 0}
        editRenderProp={addNotifications}
        onEditingToggle={(value: boolean) => {
          setEditing(value);
        }}
        disabled={disabled}
      >
        {(toggleEditing: () => void) => (
          <>
            <Box>
              <Box>
                <ContentTitle>
                  Retargeting Notifications
                  <InfoTooltip
                    message={
                      <>
                        <Box>
                          Retargeting notification text will be used in the
                          order configured. If the orchestration engine decides
                          to make additional deliveries of the campaign, the
                          last configured text will be used for those
                          retargeting attempts.
                        </Box>
                        <Box margin={['1em', 0, 0, 0]}>
                          Note: If no text configuration is made here, the
                          engine will leverage the initial subject line/push
                          text for future deliveries.
                        </Box>
                      </>
                    }
                  />
                </ContentTitle>
              </Box>
              {reTargetingNotifications.map((notification) => (
                <Box
                  border={[
                    border.solid,
                    border.width1,
                    border.radius8,
                    border.gray10,
                  ]}
                  padding={12}
                  margin={[0, 0, 12, 0]}
                >
                  <NotificationCard
                    notification={notification}
                    cardTitle={`Retargeting Notification #${
                      notification.order - 1
                    }`}
                    disableEmail={!emailChannel}
                    disablePush={!pushChannel}
                  />
                </Box>
              ))}
            </Box>

            {reTargetingNotifications.length === 0 && !editing && (
              <Box>
                <Button
                  disabled={disabled}
                  compact
                  onClick={toggleEditing}
                  label={
                    <>
                      <Plus />
                      <Box padding={[0, 12]}>Add retargeting notifications</Box>
                    </>
                  }
                  secondary
                />
              </Box>
            )}
          </>
        )}
      </SettingsCard>
    </>
  );
};
