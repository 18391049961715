import * as React from 'react';

export const Keys = {
  Esc: 'Escape',
  Enter: 'Enter',
  Tab: 'Tab',
  Space: ' ',
  Shift: 'Shift',
} as const;

type KeyCode = keyof typeof Keys;
type Key = typeof Keys[KeyCode] | string; // there are more than what we've defined

type PropsType<T extends HTMLElement = HTMLElement> = {
  ref: React.RefObject<T>;
  keys: Key[];
  onIncludedKey?: () => void;
  onAnyOtherKey?: () => void;
};

function useEventTriggerCallback({
  keys,
  onIncludedKey,
  onAnyOtherKey,
}: Omit<PropsType, 'ref'>): (key: Key) => void {
  return React.useCallback(
    (key: Key) => {
      if (keys.includes(key)) {
        if (onIncludedKey) {
          onIncludedKey();
        }
      } else if (onAnyOtherKey) {
        onAnyOtherKey();
      }
    },
    [keys, onIncludedKey, onAnyOtherKey]
  );
}

export function useKeyboardEventHandler<T extends HTMLElement = HTMLElement>(
  props: Omit<PropsType, 'ref'>
): {
  onKeyDown: React.KeyboardEventHandler<T>;
  onKeyUp: React.KeyboardEventHandler<T>;
} {
  const cb = useEventTriggerCallback(props);
  const { onAnyOtherKey } = props;
  const closeOnTabOut = React.useCallback(
    (key: Key) => {
      if (key === Keys.Shift && onAnyOtherKey) onAnyOtherKey();
    },
    [onAnyOtherKey]
  );
  return {
    onKeyDown: React.useCallback((e) => closeOnTabOut(e.key), [closeOnTabOut]),
    onKeyUp: React.useCallback((e) => cb(e.key), [cb]),
  };
}

export function useKeyboardTrigger<T extends HTMLElement = HTMLElement>(
  props: PropsType<T>
): void {
  const { ref, ...rest } = props;
  const { current } = ref;
  const cb = useEventTriggerCallback(rest);
  React.useEffect(() => {
    const cleanupRef = ref;
    const keydown = (e: KeyboardEvent) => cb(e.key);
    if (current) {
      current.addEventListener('keydown', keydown);
    }
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current.removeEventListener('keydown', keydown);
      }
    };
  }, [ref, current, cb]);
}
