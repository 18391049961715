import React from 'react';
import { defaultFontStylesheet, FontStylesheet } from 'models/library';
import { useFlashMessage } from 'contexts/flasher';
import { useLibraryMutation } from 'hooks/useLibrary';
import { defaultFontData, FontData } from 'models/font';
import { useFontStylesheetUploader } from 'hooks/useFontStylesheetUploader';
import { useProgram } from 'contexts/program';
import { deleteFont } from 'services/api-assets';
import { useBasicValidator, ValidatorsType } from 'hooks/useBasicValidator';
import { ValueType } from 'react-select';
import { useUser } from 'contexts/user';
import { navigate } from '@reach/router';
import { isWebSafe } from 'models/publisher/style';
import { uniqueId } from './useUniqueId';
import { usePrevious } from './usePrevious';

export type EditorPropsType = {
  existingData?: FontStylesheet;
  onSaveSuccess?: (t: FontStylesheet) => void;
  isLoading: boolean;
};

const validators: ValidatorsType<FontStylesheet> = {
  title: (stylesheet) => {
    return !(stylesheet && stylesheet.title.length > 0);
  },
  title_characters: (stylesheet) => {
    // semicolons end an inline css value prematurely
    // commas separate fallback fonts
    const disallowedCharacters = /[;,"]/;
    return disallowedCharacters.test(stylesheet.title);
  },
  asset: (stylesheet) => {
    return stylesheet.asset.fonts.filter((f) => f.url.length > 0).length === 0;
  },
  asset_variants: (stylesheet) => {
    const duplicates: Array<FontData> = [];
    stylesheet.asset.fonts.forEach((font, i) => {
      stylesheet.asset.fonts.forEach((element, index) => {
        if (
          i !== index &&
          element.weight === font.weight &&
          element.style === font.style &&
          !duplicates.find(
            (d) => d.weight === element.weight && d.style === element.style
          )
        ) {
          duplicates.push(element);
        }
      });
    });
    return duplicates.length > 0;
  },
};

const ERRORS = {
  TITLE: "Font name can't be empty.",
  TITLE_CHARACTERS:
    'Font name can\'t contain the following special characters: ;," .',
  ASSET: 'Please upload font files.',
  ASSET_VARIANTS:
    'Multiple variants have identical settings. Please remove duplicate variants.',
};

type FontValue = ValueType<{
  value: number | string;
  label: string;
}>;

export type Editor = (
  props: EditorPropsType
) => {
  handleClose: () => void;
  isSaving: boolean;
  handleSave: () => void;
  isValid: boolean;
  stylesheet: FontStylesheet;
  setName: (name: string) => void;
  addVariant: () => void;
  setFallbackFont: (f: FontValue) => void;
  updateFont: (index: number, field: string, value: FontValue) => void;
  deleteVariant: (index: number) => void;
  setFont: (f: FontData, index: number) => void;
  errors: Array<string>;
};

export const useFontEditor: Editor = ({ existingData }) => {
  const { id: programId } = useProgram();
  const [stylesheet, setStylesheet] = React.useState<FontStylesheet>(
    existingData || defaultFontStylesheet
  );
  const validator = useBasicValidator<FontStylesheet>(validators, ERRORS);
  const { setFlashMessage } = useFlashMessage();
  const user = useUser();

  const prevData = usePrevious(existingData);

  React.useEffect(() => {
    if (
      prevData &&
      prevData.id === 'new' &&
      existingData &&
      existingData.id !== 'new'
    ) {
      setStylesheet(existingData);
    }
  }, [existingData, prevData]);

  const onSuccess = React.useCallback(() => {
    navigate(`/${programId}/app/library/fonts`);
    setFlashMessage({
      message: 'Font saved successfully',
      severity: 'info',
    });
  }, [programId, setFlashMessage]);

  const onSaveError = React.useCallback(() => {
    setFlashMessage({
      message: 'Could not create font',
      severity: 'warning',
    });
  }, [setFlashMessage]);

  const { mutate: save, isSaving } = useLibraryMutation({
    onSuccess,
    onError: onSaveError,
  });

  const addVariant = React.useCallback(() => {
    const newFont = { ...defaultFontData, assetKey: uniqueId() };
    const fonts = [...stylesheet.asset.fonts, newFont];
    const asset = {
      ...stylesheet.asset,
      fonts,
    };
    setStylesheet({ ...stylesheet, asset });
  }, [stylesheet]);

  const deleteVariant = React.useCallback(
    (index: number) => {
      const fonts = [...stylesheet.asset.fonts];
      const fontToDelete = fonts[index];
      if (fontToDelete.assetKey.length > 0) {
        deleteFont({ assetKey: fontToDelete.assetKey, programId });
      }
      fonts.splice(index, 1);

      const fontFamily = fonts.find((font) => !!font.family)?.family;

      setStylesheet({
        ...stylesheet,
        asset: {
          ...stylesheet.asset,
          fonts,
          fontFamily,
        },
      });
    },
    [stylesheet, programId]
  );

  const setName = React.useCallback(
    (name: string) => {
      setStylesheet({ ...stylesheet, title: name });
    },
    [stylesheet]
  );

  const updateFont = React.useCallback(
    (index, field, value) => {
      const fonts = [...stylesheet.asset.fonts];
      const el = fonts[index];
      if (el) {
        fonts[index] = { ...el, [field]: value.value };
        setStylesheet({
          ...stylesheet,
          asset: {
            ...stylesheet.asset,
            fonts,
          },
        });
      }
    },
    [stylesheet]
  );

  const setFont = React.useCallback(
    (f: FontData, index) => {
      const fonts = [...stylesheet.asset.fonts];
      fonts[index] = { ...fonts[index], ...f };

      const fontFamily = fonts.find((font) => !!font.family)?.family;

      setStylesheet({
        ...stylesheet,
        asset: {
          ...stylesheet.asset,
          fonts: [...fonts],
          fontFamily,
        },
      });
    },
    [stylesheet]
  );

  const setFallbackFont = React.useCallback(
    (fallback) => {
      setStylesheet({
        ...stylesheet,
        asset: { ...stylesheet.asset, fallbackFont: fallback.value },
      });
    },
    [stylesheet]
  );

  const handleClose = React.useCallback(() => {
    stylesheet.asset.fonts.forEach((f) => {
      if (f.status === 'created') {
        deleteFont({ assetKey: f.assetKey, programId });
      }
    });
    navigate(`/${programId}/app/library/fonts`);
  }, [stylesheet.asset.fonts, programId]);

  const validation = React.useMemo(() => {
    return validator.validate(stylesheet);
  }, [validator, stylesheet]);

  const isValid = React.useMemo(() => {
    return validation.errors.length === 0;
  }, [validation.errors]);

  const onUpload = React.useCallback(
    (data) => {
      const newStylesheet = {
        ...stylesheet,
        asset: {
          ...stylesheet.asset,
          css: {
            assetKey: data.assetKey,
            url: data.url,
          },
          value: buildCSSValue(stylesheet),
        },
      };
      if (!newStylesheet.asset.createdBy) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const createdBy = { ...user } as any;
        // this is too much information
        createdBy.accessibleBrands = undefined;
        createdBy.accessiblePrograms = undefined;
        createdBy.superAdmin = undefined;
        createdBy.adminPreferences = undefined;
        newStylesheet.asset.createdBy = createdBy;
      }

      setStylesheet(newStylesheet);
      save({ programId, item: newStylesheet });
    },
    [programId, save, stylesheet, user]
  );

  const uploader = useFontStylesheetUploader({
    onUpload,
    programId,
  });

  const handleSave = React.useCallback(() => {
    if (isValid) uploader.update(buildFontCssFile(stylesheet));
  }, [isValid, stylesheet, uploader]);

  return {
    handleClose,
    isSaving: isSaving || uploader.isUploading,
    handleSave,
    isValid,
    stylesheet,
    setName,
    addVariant,
    setFallbackFont,
    updateFont,
    deleteVariant,
    setFont,
    errors: validation.errors,
  };
};

function buildFontCssFile(stylesheet: FontStylesheet) {
  const cssContent = stylesheet.asset.fonts.map((font) =>
    buildFontFaceDeclaration(font, stylesheet)
  );
  return new File(cssContent, 'stylesheet.css', {
    type: 'text/css',
  });
}

function buildFontFaceDeclaration(font: FontData, stylesheet: FontStylesheet) {
  const fallbackFonts = getFallbackFonts(stylesheet).map(
    (fallback: string) => `local(${fallback.trim()})`
  );

  if (!fallbackFonts.includes('Arial')) {
    fallbackFonts.push('local(Arial)');
  }
  const fontFamily = getFontFamily(stylesheet, font);
  const escapedTitle = JSON.stringify(fontFamily);
  const [fallbackMsoAlt] = (stylesheet.asset.fallbackFont || 'Arial').split(
    ','
  );
  const msoAlt = isWebSafe(fontFamily)
    ? escapedTitle
    : `'${fallbackMsoAlt.trim()}'`;
  return `@font-face {
          font-family: ${escapedTitle};
          font-style: ${font.style};
          font-weight: ${font.weight};
          mso-font-alt: ${msoAlt};
          src: local(${escapedTitle});
          src: url(${font.url}), ${fallbackFonts.join(', ')};
        }\n`.replace(/^\s+/, '  ');
}

function buildCSSValue(stylesheet: FontStylesheet) {
  const fontFamily = getFontFamily(stylesheet, ...stylesheet.asset.fonts);
  return [JSON.stringify(fontFamily)]
    .concat(getFallbackFonts(stylesheet))
    .join(', ');
}

function getFontFamily(
  stylesheet: FontStylesheet,
  ...fonts: FontData[]
): string {
  return fonts.find((font) => font.family)?.family ?? stylesheet.title;
}

function getFallbackFonts(stylesheet: FontStylesheet): string[] {
  return (
    stylesheet?.asset?.fallbackFont?.split(/ *, */)?.filter((v) => v) ?? []
  );
}
