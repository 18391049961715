import classNames from 'classnames';
import type { Either } from 'd.ts/types';
import { Tooltip } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import React, { ComponentProps } from 'react';
import { IconNames, MAIcon } from 'shared/MAIcon';
import editorStyles from '../../editor.module.css';

export const ToolbarButton: React.FC<
  {
    isActive?: boolean;
    disabled?: boolean;
    icon: IconNames | JSX.Element;
    className?: string;
    // Icon only buttons should have an aria-label or aria-labelledby to be accessible
  } & Either<{ 'aria-label': string }, { 'aria-labelledby': string }> &
    ComponentProps<typeof Button>
> = ({ isActive, disabled, className, icon, ...buttonProps }) => {
  return (
    <Tooltip
      content={buttonProps['aria-label']}
      disabled={!buttonProps['aria-label']}
      placement="bottom"
    >
      <Button
        minimal
        layoutOnly
        aria-disabled={disabled}
        aria-pressed={isActive}
        className={classNames(
          editorStyles.tiptapButton,
          isActive && 'isActive',
          className
        )}
        icon={
          typeof icon === 'string' ? <MAIcon name={icon as IconNames} /> : icon
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      />
    </Tooltip>
  );
};

ToolbarButton.defaultProps = {
  disabled: false,
  className: undefined,
};
