import React from 'react';
import { Button } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import { Collection } from 'models/insight/Collection';
import { ReportsList } from 'App/Program/Main/Insight/components/ReportsList/ReportsList';
import styles from './NonFeaturedCollection.module.css';

export const NonFeaturedCollection: React.FC<{ collection: Collection }> = ({
  collection: { title, reports },
}) => {
  const [additional, setAdditional] = React.useState(false);

  const initialReports = reports.slice(0, 3);
  const displayedReports = additional ? reports : initialReports;
  const toggleView = () => setAdditional(!additional);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text.Heading bold>{title}</Text.Heading>
        {reports.length > 3 && (
          <Button
            text
            compact
            onClick={toggleView}
            label={additional ? 'Hide' : `See all (${reports.length})`}
          />
        )}
      </div>

      <ReportsList reports={displayedReports} viewType="card" />
    </div>
  );
};
