import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useExternalSourceQuery } from 'hooks/external-source';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { ExternalSource, validateExternalSource } from 'models/external-source';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { useFlashMessage } from 'contexts/flasher';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { Sidebar } from './components/Sidebar';
import { useActions } from './useActions';

import styles from './external-sources.module.css';

export const EditExternalSource: React.FC<RouteComponentProps<{
  id: string | number;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const [externalSource, setExternalSource] = React.useState<ExternalSource>();
  const { update, archive, unarchive } = useActions(setExternalSource);
  const { data, isLoading, errorMessage } = useExternalSourceQuery(
    programId,
    id as number
  );

  function onChange(updatedData: Partial<ExternalSource>) {
    setExternalSource({
      ...(externalSource as ExternalSource),
      ...updatedData,
    });
  }

  React.useEffect(() => {
    if (data) setExternalSource(data);
  }, [data]);

  const header = (
    <>
      <h1 className="page-header">Feed</h1>
    </>
  );

  const sidebar = (
    <Sidebar
      externalSource={externalSource as ExternalSource}
      archive={archive}
      unarchive={unarchive}
    />
  );

  const main = (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && (
        <Form
          externalSource={externalSource as ExternalSource}
          onChange={onChange}
          update
        />
      )}
    </>
  );

  function onSave() {
    const validationError = validateExternalSource(
      externalSource as ExternalSource
    );

    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });
      return;
    }
    update(externalSource as ExternalSource);
  }

  return (
    <WithPermission permissions={['confContentAccess', 'feedAccess']}>
      <Helmet>
        <title>Edit Feed</title>
      </Helmet>
      {externalSource && (
        <MainEditor
          header={header}
          main={main}
          sidebar={sidebar}
          footer={<Footer externalSource={externalSource} action={onSave} />}
        />
      )}
    </WithPermission>
  );
};
