import React, { useEffect, useMemo, useRef, useState } from 'react';
import { arc, pie, select, interpolate, DefaultArcObject } from 'd3';
import { ChartDimensions, DEFAULT_ANIMATION_TIME } from './Util';
import { NoDataChart } from './NoDataChart';

interface IProgressDonut {
  percentage?: number;
  chartDimensions?: ProgressDonutChartDimensions;
}

type PieChartData = {
  label: string;
  value: number;
};

type ProgressDonutChartDimensions = ChartDimensions & {
  radiusRatio: number;
  innerFontSize: number;
};

export const ProgressDonut: React.FC<IProgressDonut> = ({
  percentage,
  chartDimensions = {
    height: 300,
    width: 300,
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    radiusRatio: 0.8,
    innerFontSize: 20,
  },
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [prevPercentage, setPrevPercentage] = useState(0);
  const { height, width, radiusRatio: ratio, innerFontSize } = chartDimensions;
  const { data, color } = useMemo(() => {
    return {
      data: [
        { label: 'total', value: percentage || 0 },
        { label: 'remainder', value: 1 - (percentage || 0) },
      ],
      color: ['url(#greenGradient)', 'rgba(232, 231, 231, 1)'],
    };
  }, [percentage]);
  const padding = {
    left: 5,
    right: 5,
    top: 5,
    bottom: 5,
  };

  const radius =
    Math.min(
      width - padding.left - padding.right,
      height - padding.top - padding.bottom
    ) / 2;

  const drawPie = pie<PieChartData>()
    .value((d: { label: string; value: number }) => d.value)
    .sort(null);
  const drawArc = arc<DefaultArcObject>()
    .innerRadius(radius * ratio)
    .outerRadius(radius);

  useEffect(() => {
    if (!svgRef.current || percentage === undefined) return;

    const svg = select(svgRef.current);
    const g = svg.select('g');

    const arcGenerator = arc<DefaultArcObject>()
      .innerRadius(radius * ratio)
      .outerRadius(radius);
    const pieData = drawPie(data);
    g.selectAll('total')
      .data([pieData[1]])
      .join('path')
      .attr('fill', 'rgba(232, 231, 231, 1)')
      .attr('d', (datum) => arcGenerator(datum as never));

    g.selectAll('remainder')
      .data([pieData[0]])
      .join('path')
      .attr('fill', (_, i) => color[i])
      .transition()
      .duration(DEFAULT_ANIMATION_TIME)
      .attrTween('d', (d) => {
        const interpolateEnd = interpolate(0, d.endAngle - d.startAngle);
        return (t) => {
          return arcGenerator({
            ...d,
            endAngle: d.startAngle + interpolateEnd(t),
          } as never) as never;
        };
      });

    const textElement = svg.select('text');
    const currentPercentage = parseFloat(textElement.text());
    const targetPercentage = percentage * 100;

    textElement
      .transition()
      .duration(DEFAULT_ANIMATION_TIME)
      .tween('text', () => {
        const interpolator = interpolate(currentPercentage, targetPercentage);
        return (t: number) => {
          textElement.text(`${Math.round(interpolator(t))}%`);
        };
      });

    setPrevPercentage(percentage);
  }, [
    percentage,
    data,
    radius,
    ratio,
    drawPie,
    drawArc,
    color,
    prevPercentage,
  ]);

  return percentage === undefined ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id="greenGradient" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="rgba(126, 202, 4, 1)" />
          <stop offset="100%" stopColor="rgba(160, 231, 45, 1)" />
        </linearGradient>
      </defs>
      <g transform={`translate(${width / 2}, ${height / 2})`} />
      <text
        x={width * 0.5}
        y={height * 0.5}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={innerFontSize}
      >
        {(percentage * 100).toFixed(0)}%
      </text>
    </svg>
  );
};
