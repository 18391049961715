import * as React from 'react';
import * as DesignSystem from 'DesignSystem/Components';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import * as Inputs from 'DesignSystem/Form';
import { usePanelContext } from '../../usePanelContext';

export const KeepOpen: React.FC<{
  keepOpenLabel: string;
  confirmCloseLabel: string;
}> = ({ children, keepOpenLabel, confirmCloseLabel }) => {
  const panel = usePanelContext();
  return (
    <DesignSystem.Modal name="block-panel-exit">
      {children}
      <Flex end>
        <Inputs.Button
          onClick={panel.continueOpened}
          secondary
          label={keepOpenLabel}
        />
        <Space row size={12} />
        <Inputs.Button
          onClick={panel.confirmClose}
          warning
          label={confirmCloseLabel}
        />
      </Flex>
    </DesignSystem.Modal>
  );
};

export function useKeepOpen(
  ModalContent: React.FC,
  isUploading: boolean
): void {
  const { setBlocker } = usePanelContext();
  React.useEffect(() => {
    if (isUploading) setBlocker(<ModalContent />);
    else setBlocker(null);
  }, [ModalContent, isUploading, setBlocker]);
}
