import React from 'react';

import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useDebounce } from 'hooks/useDebounce';
import { Filter } from 'models/language-controls/filter';
import { SearchBar } from 'shared/SearchBar';
import { Filters } from './Filters';

type PropsType = {
  filters: Filter[];
  searchTerm: string;
  onFiltersChange: (filters: Filter[]) => void;
  onSearchChange: (searchTerm: string) => void;
};

const SEARCH_BAR_WIDTH = 160;

export const FilterBar: React.FC<PropsType> = ({
  filters,
  searchTerm,
  onFiltersChange,
  onSearchChange,
}) => {
  const [search, setSearch] = React.useState(searchTerm);
  onSearchChange(useDebounce(search));

  return (
    <Flex start>
      <Box margin={[0, 16, 0, 0]}>
        <SearchBar
          value={search}
          onChange={setSearch}
          placeholder="Search rules"
          width={`${SEARCH_BAR_WIDTH}px`}
        />
      </Box>
      <Filters filters={filters} onChange={onFiltersChange} />
    </Flex>
  );
};
