import React from 'react';
import { Box } from 'DesignSystem/Components';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import {
  CommunicationStep,
  isNotifitcationCenterChannel,
  isEmailChannel,
  isPushChannel,
  EmailChannel,
  NotificationCenterChannel,
  PushChannel,
} from 'models/journeys/journey';
import { ChannelSettingsSection, Section } from './layout';
import { Tag, ChannelTag } from './Tag';
import { Setting } from './Setting';
import styles from './readonly-communication-config.module.css';
import { useAcknowledgementsEnabled } from '../../../../../../hooks/journeys/journeys';
import { useNotificationCenterEnabled } from '../../../../../../hooks/notification-center';
import { EmailReadOnlyCommunicationConfig } from './EmailReadOnlyCommunicationConfig';

export const ReadOnlyCommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  if (!useNotificationCenterEnabled()) {
    return <EmailReadOnlyCommunicationConfig step={step} />;
  }

  return <OmniChannelReadOnlyCommunicationConfig step={step} />;
};

const OmniChannelReadOnlyCommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const emailChannel = step.channels.find(isEmailChannel);
  const pushChannel = step.channels.find(isPushChannel);
  const notificationCenterChannel = step.channels.find(
    isNotifitcationCenterChannel
  );
  const acknowledgementsEnabled = useAcknowledgementsEnabled();

  const tagLabels = {
    delivery: `Delivery: ${
      step.channelDelivery === 'all' ? 'All Channels' : 'Optimize'
    }`,
    markAsImportant: `Mark as important: ${
      notificationCenterChannel?.markAsImportant ? 'ON' : 'OFF'
    }`,
    acknowledge: `Acknowledge: ${step.acknowledgement ? 'ON' : 'OFF'}`,
  };

  return (
    <Box data-testid="omnichannel-readonly-communication-config">
      <FlexLayout.Flex
        data-testid="readonly-communication-config-tags"
        start
        wrap
        className={styles.tagsSection}
      >
        <ChannelTag channel={emailChannel} />
        <ChannelTag channel={notificationCenterChannel} />
        <ChannelTag channel={pushChannel} />
        {step.channelDelivery && <Tag label={tagLabels.delivery} />}
        {notificationCenterChannel && (
          <Tag label={tagLabels.markAsImportant} iconName="check_circle" />
        )}
        {acknowledgementsEnabled && (
          <Tag label={tagLabels.acknowledge} iconName="check_circle" />
        )}
      </FlexLayout.Flex>
      <Box width="50%">
        <Section title="Delivery Options">
          <EmailChannelSettings channel={emailChannel} />
          <NotificationCenterChannelSettings
            channel={notificationCenterChannel}
          />
          <PushChannelSettings channel={pushChannel} />
        </Section>
        <Section title="Content Settings">
          {step.author?.displayName && (
            <Setting label="Author" value={step.author.displayName} />
          )}
          {acknowledgementsEnabled && step.acknowledgement?.customLabel && (
            <Setting
              label="Acknowledgement Label"
              value={step.acknowledgement.customLabel}
            />
          )}
        </Section>
      </Box>
    </Box>
  );
};

const EmailChannelSettings: React.VFC<{ channel?: EmailChannel }> = ({
  channel,
}) => {
  if (!channel) return null;

  return (
    <ChannelSettingsSection channel={channel}>
      {channel.emailSenderAlias?.senderEmail && (
        <Setting
          label="Email Alias"
          value={channel.emailSenderAlias.senderEmail}
        />
      )}
      <Setting label="Subject Line" value={channel.subject} />
      <Setting label="Preview" value={channel.previewText} />
    </ChannelSettingsSection>
  );
};

const NotificationCenterChannelSettings: React.VFC<{
  channel?: NotificationCenterChannel;
}> = ({ channel }) => {
  if (!channel) return null;

  return (
    <ChannelSettingsSection channel={channel}>
      <Setting label="Title" value={channel.text} />
    </ChannelSettingsSection>
  );
};

const PushChannelSettings: React.VFC<{ channel?: PushChannel }> = ({
  channel,
}) => {
  if (!channel) return null;

  return (
    <ChannelSettingsSection channel={channel}>
      <Setting label="Message" value={channel.text} />
    </ChannelSettingsSection>
  );
};
