import * as React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
  duplicateField,
  DefinitionBlock,
  FieldData,
  FieldDefinition,
  FieldType,
  isFieldType,
} from 'models/publisher/block';
import { usePermissions } from 'contexts/permissions';
import { RenderStatusContext } from 'contexts/publisher/compose/render-status';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  useLibraryBlockCategories,
  useLibraryMutation,
} from 'hooks/useLibrary';
import { uniqueId } from 'hooks/useUniqueId';
import { Category, CustomBlock, defaultCustomBlock } from 'models/library';
import { useControls } from './Controls';
import { useVariableExpander } from './useVariableExpander';

export const useEditableInstance: (props: {
  block: DefinitionBlock;
  blockId: string;
  isDragging: boolean;
  onChangeData: (blockId: string, data: FieldData) => void;
  removeByBlockId: (blockId: string) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}) => ReturnType<typeof useCodeView> &
  ReturnType<typeof useControls> &
  ReturnType<typeof useDeletion> &
  ReturnType<typeof useDropzone> &
  ReturnType<typeof useDuplication> &
  ReturnType<typeof useEditable> &
  ReturnType<typeof useBlockEditor> &
  ReturnType<typeof useBlockVariantsEditor> &
  ReturnType<typeof useRendering> &
  ReturnType<typeof useSelectableBlock> &
  ReturnType<typeof useValidation> &
  ReturnType<typeof useCustomBlocks> &
  ReturnType<typeof useEditorVersion> & {
    dragHandleProps?: DraggableProvidedDragHandleProps;
  } = ({
  isDragging,
  block,
  blockId,
  removeByBlockId,
  onChangeData,
  dragHandleProps,
}) => {
  // They all need the version...
  const version = useEditorVersion();
  const { useNewEditor } = version;

  // certain fields (such as rich-text) are not supposed to have an editor
  const modalField = React.useMemo(
    () =>
      block.fields.find((field) => {
        if (useNewEditor && field.type === 'html') return true;
        return [
          'attachment',
          'box-integration',
          'date',
          'image',
          'image[]',
          'link[]',
          'button-link',
          'social[]',
          'poll',
          'user[]',
          'video',
        ].includes(field.type);
      }),
    [block.fields, useNewEditor]
  );

  // certain fields are not supposed to have a sidebar whatsoever
  const fieldWithoutControls = React.useMemo(
    () =>
      block.fields.find((field) => {
        return ['custom-html'].includes(field.type);
      }),
    [block.fields]
  );

  // A couple more of these are used within the rest...
  const dropzone = useDropzone(isDragging);
  const editor = useBlockEditor(block, blockId, onChangeData, modalField);
  const validation = useValidation(blockId);
  const variantsEditor = useBlockVariantsEditor(block, blockId, onChangeData);
  // These depend on those, and need to be given them..
  const codeview = useCodeView(block.fields, useNewEditor);
  const controls = useControls({ modalField, fieldWithoutControls });
  const deletion = useDeletion(blockId, removeByBlockId);
  const duplication = useDuplication(block, blockId, validation.isValid);
  const editable = useEditable(
    block,
    blockId,
    onChangeData,
    editor,
    dropzone,
    validation.isValid,
    useNewEditor
  );
  const rendering = useRendering(!!editor.showingFieldModal, useNewEditor);
  const selection = useSelectableBlock(blockId);
  const customBlocks = useCustomBlocks(block);

  return {
    ...codeview,
    ...controls,
    ...deletion,
    ...dropzone,
    ...duplication,
    ...editable,
    ...editor,
    ...rendering,
    ...selection,
    ...variantsEditor,
    ...validation,
    ...version,
    ...customBlocks,
    dragHandleProps,
  };
};

// These helper hooks shouldn't reference each other.

function useEditable(
  block: DefinitionBlock,
  blockId: string,
  onChangeData: (blockId: string, data: FieldData) => void,
  editor: ReturnType<typeof useBlockEditor>,
  dropzone: ReturnType<typeof useDropzone>,
  isValid: boolean,
  useNewEditor: boolean
) {
  useVariableExpander({
    fields: block.fields,
    fieldData: block.field_data,
    onChange: editor.onChangeFieldData,
  });

  const onChangeDataByBlockId = React.useCallback(
    (byBlockId: string, data: FieldData) => {
      onChangeData(byBlockId, data);
    },
    [onChangeData]
  );

  const canEdit =
    (useNewEditor || (editor.usesModalEditor && isValid)) &&
    !dropzone.isDropzoneUploading;
  return { onChangeDataByBlockId, onChangeData, block, blockId, canEdit };
}

function useEditorVersion() {
  const useNewEditor = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  )?.data?.value;
  return { useNewEditor };
}
function useForceCodeEditing() {
  return !!useFeatureFlagsQuery(useProgram().id, 'Experimental.EnableCodeView')
    ?.data?.value;
}

function useBlockEditor(
  block: DefinitionBlock,
  blockId: string,
  onChangeData: (blockId: string, data: FieldData) => void,
  modalField?: FieldDefinition
) {
  const [showingFieldModal, setShowing] = React.useState<FieldDefinition>();
  const showModalEditor = React.useCallback(() => {
    setShowing(modalField);
  }, [setShowing, modalField]);
  const hideModalEditor = React.useCallback(() => {
    setShowing(undefined);
  }, [setShowing]);
  const usesModalEditor = !!modalField;
  const onChangeFieldData = React.useCallback(
    (name: keyof FieldData, data: FieldType) => {
      onChangeData(blockId, {
        ...block.field_data,
        [name]: data,
      });
    },
    [block, onChangeData, blockId]
  );
  return {
    showingFieldModal,
    onChangeFieldData,
    hideModalEditor,
    usesModalEditor,
    showModalEditor,
  };
}

// we want visibility tab for all blocks even those without field data.
const FALLBACK_FIELD_DEFINITION: FieldDefinition = {
  name: 'fallback',
  display_name: 'fallback',
  type: 'spacer',
};

function useBlockVariantsEditor(
  block: DefinitionBlock,
  blockId: string,
  onChangeData: (blockId: string, data: FieldData) => void,
  modalField: FieldDefinition = FALLBACK_FIELD_DEFINITION
) {
  const [showingVariantsModal, setShowing] = React.useState<FieldDefinition>();
  const showVariantsModal = React.useCallback(() => {
    setShowing(modalField);
  }, [setShowing, modalField]);
  const hideVariantsModal = React.useCallback(() => {
    setShowing(undefined);
  }, [setShowing]);
  const onChangeVariantsData = React.useCallback(
    (name: keyof FieldData, data: FieldType) => {
      onChangeData(blockId, {
        ...block.field_data,
        [name]: data,
      });
    },
    [block, onChangeData, blockId]
  );
  return {
    showingVariantsModal,
    onChangeVariantsData,
    hideVariantsModal,
    showVariantsModal,
  };
}

function useValidation(blockId: string) {
  const { get: getStatus } = React.useContext(RenderStatusContext);
  const isValid = React.useMemo(() => getStatus(blockId), [blockId, getStatus]);
  return { isValid };
}

function useSelectableBlock(blockId: string) {
  const { select } = React.useContext(BlocksEditorContext);
  const selectBlock = React.useCallback(() => {
    select(blockId);
  }, [select, blockId]);
  return { selectBlock };
}

function useDeletion(
  blockId: string,
  removeByBlockId: (blockId: string) => void
) {
  const { remove: removeStatus } = React.useContext(RenderStatusContext);
  const deleteSelf = React.useCallback(() => {
    removeStatus(blockId);
    removeByBlockId(blockId);
  }, [removeStatus, blockId, removeByBlockId]);
  return { deleteSelf };
}

type CodeViewToggle = () => void;

type CodeViewCallbacks = Record<string, CodeViewToggle>;

export type CodeViewCallback = (uuid: string, callback: CodeViewToggle) => void;

function useCodeView(fields: FieldDefinition[], useNewEditor: boolean) {
  const showCodeViewButton = useForceCodeEditing();
  const [codeViewCallbacks, setCallbacks] = React.useState<CodeViewCallbacks>(
    {}
  );
  const codeViewCallback = React.useCallback<CodeViewCallback>(
    (uuid: string, callback: CodeViewToggle) => {
      setCallbacks((prev) => ({ ...prev, [uuid]: callback }));
    },
    []
  );
  const toggleCodeView = React.useCallback(() => {
    Object.values(codeViewCallbacks).forEach((cb) => cb());
  }, [codeViewCallbacks]);
  const supportsCodeView = React.useMemo(
    () => !useNewEditor && fields.some(({ type }) => type === 'html'),
    [fields, useNewEditor]
  );
  return {
    codeViewCallback,
    supportsCodeView,
    showCodeViewButton,
    toggleCodeView,
  };
}

function useDuplication(
  block: DefinitionBlock,
  blockId: string,
  isValid: boolean
) {
  const { insert, instances } = React.useContext(BlocksEditorContext);
  const duplicateSelf = React.useCallback(() => {
    const dupFieldData = Object.entries(block.field_data).reduce(
      (dup, [name, field]) =>
        Object.assign(dup, {
          [name]: isFieldType(field) ? duplicateField(field) : field,
        }),
      {}
    );

    insert(
      [
        {
          ...block,
          field_data: dupFieldData,
        },
      ],
      instances.findIndex(({ id }) => id === blockId) + 1
    );
  }, [block, blockId, insert, instances]);
  return { duplicateSelf, supportsDuplication: isValid };
}

function useDropzone(isDragging: boolean) {
  const [isDropzoneUploading, setIsDropzoneUploading] = React.useState(false);
  const onDropzoneUploading = React.useCallback((isUploading: boolean) => {
    setIsDropzoneUploading(isUploading);
  }, []);
  return { isDropzoneUploading, onDropzoneUploading, isDragging };
}

function useRendering(showingFieldModal: boolean, useNewEditor: boolean) {
  const enableRender = useNewEditor || !showingFieldModal;
  const enableLiveEdits = !showingFieldModal;
  return { enableRender, enableLiveEdits };
}

export function useCustomBlocks(
  block: DefinitionBlock
): {
  canSaveCustomBlock: boolean;
  saveCustomBlock: (title: string) => void;
} {
  const { id: programId } = useProgram();

  const isCustomBlocksEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CustomBlocks'
  )?.data?.value;
  const { permissions } = usePermissions();

  const canSaveCustomBlock =
    isCustomBlocksEnabled && !!permissions?.libraryCustomBlocksAccess;

  const response = useLibraryBlockCategories();
  const customCategory = React.useRef<Category>();
  React.useEffect(() => {
    // If managing custom blocks is enabled and permitted,
    // find and set the custom block category
    if (!canSaveCustomBlock || customCategory.current) return;

    customCategory.current = response.data?.find(
      (category) => category.identifier === 'custom_block'
    );
  }, [canSaveCustomBlock, response.data]);

  const { setFlashMessage } = useFlashMessage();

  const { mutate } = useLibraryMutation({
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Custom block saved successfully',
      });
    },
  });

  const saveCustomBlock = React.useCallback(
    (title: string) => {
      if (!canSaveCustomBlock || !customCategory.current) return;

      const item: CustomBlock = {
        ...defaultCustomBlock,
        identifier: uniqueId(),
        title,
        categories: [customCategory.current],
        asset: {
          blocks: [block],
          preview_blocks: [block],
        },
      };
      mutate({ programId, item });
    },
    [block, canSaveCustomBlock, mutate, programId]
  );

  return {
    canSaveCustomBlock,
    saveCustomBlock,
  };
}
