import React from 'react';
import { Subsection } from 'DesignSystem/Form';
import { User } from 'models/user';
import { TextInput } from 'shared/TextInput';
import { NewAliasData } from 'services/api-author-alias';
import { UserSelect } from 'components/users/UserSelect';
import { DescriptionSection } from './components/DescriptionSection';
import { TeamAvatarSection } from './components/TeamAvatarSection';
import styles from './author-alias.module.css';

export const UserForm: React.FC<{
  onChange: (c: NewAliasData) => void;
  errorMessage?: string;
  setErrorMessage: (value: string) => void;
}> = ({ onChange, errorMessage, setErrorMessage }) => {
  const [description, setDescription] = React.useState<string>('');
  const [selectedUser, setSelectedUser] = React.useState<User>();

  const validate = () => {
    if (!selectedUser) setErrorMessage('Display Name is a required field');
  };

  React.useEffect(() => {
    if (!selectedUser) return;
    setErrorMessage('');
    onChange({
      userId: selectedUser?.id.toString(),
      displayName: selectedUser.displayName,
      avatarUrl: selectedUser.avatarUrl,
      description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, selectedUser]);

  const handleChange = (selections?: User[] | User) => {
    let user: User = {} as User;
    if (selections) {
      user = Array.isArray(selections) ? selections[0] : selections;
    }
    setSelectedUser(user);
  };

  return (
    <>
      <Subsection
        title="Display Name"
        description="This name will be displayed to community members when publishing a campaign"
        divider={false}
      >
        <div className={styles.textField}>
          <UserSelect
            initialValue={selectedUser}
            onChange={handleChange}
            placeholder="Select a user to be used as an alias"
            showOptionAvatar
            filters={{
              withoutAliasedUsers: true,
              withoutHiddenOnProgram: true,
            }}
            statuses={[
              'created',
              'invited',
              'registered',
              'registering',
              'active',
            ]}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onBlur={validate}
            allowEmailSearch
          />
        </div>
      </Subsection>
      <DescriptionSection
        aliasData={{ description }}
        onChange={setDescription}
      />
    </>
  );
};

export const TeamForm: React.FC<{
  onChange: (c: NewAliasData) => void;
  errorMessage?: string;
  setErrorMessage: (value: string) => void;
}> = ({ onChange, errorMessage, setErrorMessage }) => {
  const [avatarUrl, setAvatarUrl] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [displayName, setDisplayName] = React.useState<string>('');

  const validate = () => {
    if (!(displayName || '').trim())
      setErrorMessage('Display Name is a required field');
  };

  React.useEffect(() => {
    setErrorMessage('');
    onChange({
      displayName,
      avatarUrl,
      description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, avatarUrl, description]);

  return (
    <>
      <Subsection
        title="Display Name"
        description="This name will be displayed to community members when publishing a campaign"
        divider={false}
      >
        <TextInput
          className={styles.textField}
          value={displayName}
          onChange={setDisplayName}
          placeholder="Enter a display name"
          hasError={!!errorMessage}
          errorMessage={errorMessage}
          onBlur={validate}
        />
      </Subsection>
      <DescriptionSection
        aliasData={{ description }}
        onChange={setDescription}
      />
      <TeamAvatarSection
        aliasData={{ avatarUrl, displayName }}
        onChange={setAvatarUrl}
      />
    </>
  );
};
