import * as React from 'react';
import { FilterTriggerButton } from 'DesignSystem/Table/FilterTriggerButton';

export const TriggerButton: React.FC<{
  name: string;
  values: Array<string>;
  onClose?: () => void;
}> = ({ name, values, onClose }) => (
  <FilterTriggerButton name={name} values={values} onClose={onClose} />
);
