import React, { useEffect, useMemo, useRef } from 'react';
import {
  axisBottom,
  axisLeft,
  AxisScale,
  format,
  interpolateSpectral,
  line,
  quantize,
  scaleLinear,
  ScaleOrdinal,
  scaleOrdinal,
  select,
  transition,
} from 'd3';
import { ChartDimensions, DEFAULT_ANIMATION_TIME } from './Util';
import { NoDataChart } from './NoDataChart';

export type LineChartDimensions = ChartDimensions;

export type LineData = {
  label: string;
  data: Array<PointData>;
};

export type PointData = {
  label?: string;
  value: number;
};

export type CustomDrawingFunction = (opts: {
  lines?: Array<LineData>;
  chartDimensions: LineChartDimensions;
  scaleX?: AxisScale<string> | AxisScale<number>;
  scaleY?: AxisScale<string> | AxisScale<number>;
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>;
}) => React.ReactFragment;

interface ILineChart {
  lines: Array<LineData>;
  highlightedLine?: string;
  domain?: [number, number];
  chartDimensions?: LineChartDimensions;
  customDrawing?: CustomDrawingFunction;
  customColors?: ScaleOrdinal<string, unknown>;
  toolTipOverride?: (
    x: number,
    y: number,
    index: number,
    show: boolean
  ) => void;
  hideXAxis?: boolean;
  hideYAxis?: boolean;
}

export const LineChart: React.FC<ILineChart> = ({
  lines,
  highlightedLine,
  domain = [0, 1],
  chartDimensions = {
    height: 240,
    width: 960,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  customDrawing,
  customColors,
  toolTipOverride,
  hideXAxis = false,
  hideYAxis = false,
}: ILineChart) => {
  const { height, width, padding } = chartDimensions;
  const firstLineLength = useMemo(() => {
    const firstLine = lines?.[0];
    return firstLine?.data.length - 1 || 1;
  }, [lines]);

  const yAxisRef = useRef<SVGGElement>(null);
  const xAxisRef = useRef<SVGGElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const axisMargin = {
    top: 14,
    bottom: 24,
    left: 24,
    right: 14,
  };

  const xStart = padding.left + axisMargin.left;
  const xEnd = width - padding.left - padding.right;

  const xScale = useMemo(
    () => scaleLinear([0, firstLineLength], [xStart, xEnd]),
    [firstLineLength, xEnd, xStart]
  );
  const yScale = useMemo(
    () =>
      scaleLinear(domain, [
        height - padding.bottom - axisMargin.bottom,
        padding.top - axisMargin.top,
      ]),
    [
      axisMargin.bottom,
      axisMargin.top,
      domain,
      height,
      padding.bottom,
      padding.top,
    ]
  );

  const lineGenerator = useMemo(
    () =>
      line<PointData>()
        .x((_, i) => xScale(i))
        .y((d) => yScale(d.value)),
    [xScale, yScale]
  );

  const colorScale =
    customColors ??
    scaleOrdinal()
      .domain(lines?.map((d) => d.label) ?? [])
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          lines?.length || 0
        ).reverse()
      );

  useEffect(() => {
    if (yAxisRef.current === null) {
      return;
    }

    select(yAxisRef.current).call(
      axisLeft(yScale).ticks(5).tickFormat(format('.0%'))
    );
  }, [yAxisRef, yScale]);

  useEffect(() => {
    if (xAxisRef.current === null) {
      return;
    }

    select(xAxisRef.current).call(axisBottom(xScale).ticks(0));
  }, [xAxisRef, xScale]);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = select(svgRef.current);
    // Animate lines
    svg
      .selectAll('.line')
      .data(lines)
      .join((enter) =>
        enter
          .append('path')
          .attr('class', 'line')
          .attr('fill', 'none')
          .attr('stroke', (d) => colorScale(d.label) as string)
          .attr('d', (d) =>
            lineGenerator(
              d.data.map(() => {
                return { value: domain[0] };
              })
            )
          )
          .call((innerEnter) =>
            innerEnter
              .transition(transition().duration(DEFAULT_ANIMATION_TIME))
              .attr('d', (d) => lineGenerator(d.data) || '')
          )
      )
      .attr('stroke-width', (d) => (highlightedLine === d.label ? 6 : 3));

    // Animate markers
    svg
      .selectAll('.marker-group')
      .data(
        lines.flatMap((lineData) =>
          lineData.data.map((point, i) => ({
            ...point,
            lineLabel: lineData.label,
            index: i,
          }))
        )
      )
      .join((enter) =>
        enter
          .append('g')
          .attr('class', 'marker-group')
          .attr(
            'transform',
            (d) => `translate(${xScale(d.index)},${yScale(domain[0])})`
          )
          .call((innerEnter) =>
            innerEnter
              .transition(transition().duration(DEFAULT_ANIMATION_TIME))
              .attr(
                'transform',
                (d) => `translate(${xScale(d.index)},${yScale(d.value)})`
              )
          )
          .call((g) =>
            g
              .append('circle')
              .attr('r', 4)
              .attr('stroke', (d) => colorScale(d.lineLabel) as string)
              .attr('stroke-width', 3)
              .attr('fill', 'white')
          )
      )
      .on('mouseenter', (event, d) => {
        if (toolTipOverride) {
          const mouseX =
            event.currentTarget.getBoundingClientRect().x + 4 + 3 / 2;
          const mouseY = event.currentTarget.getBoundingClientRect().y - 4;
          toolTipOverride(mouseX, mouseY, d.index, true);
        }
      })
      .on('mouseleave', () => {
        if (toolTipOverride) {
          toolTipOverride(0, 0, 0, false);
        }
      });
  }, [
    lines,
    xScale,
    yScale,
    colorScale,
    domain,
    highlightedLine,
    lineGenerator,
    toolTipOverride,
    firstLineLength,
  ]);

  const drawCustom = useMemo(() => {
    return customDrawing
      ? customDrawing({
          lines,
          chartDimensions,
          scaleX: xScale,
          scaleY: yScale,
          colorScale,
        })
      : undefined;
  }, [chartDimensions, colorScale, customDrawing, lines, xScale, yScale]);

  return lines === undefined || lines.length === 0 ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
      {drawCustom}
      {!hideXAxis && (
        <g
          ref={xAxisRef}
          transform={`translate(0,${height - axisMargin.bottom})`}
        />
      )}
      {!hideYAxis && (
        <g ref={yAxisRef} transform={`translate(${axisMargin.left},0)`} />
      )}
    </svg>
  );
};
