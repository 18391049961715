export type EngagementBoost = {
  retries: number;
  optimizedDeliveryEnabled: boolean;
};

export const defaultEngagementBoost: EngagementBoost = {
  retries: -1,
  optimizedDeliveryEnabled: true,
};

export const enabledEngagementBoost: EngagementBoost = {
  retries: -1,
  optimizedDeliveryEnabled: true,
};

// Modifier concept is equal to channel.ts
type Modifier = (val: EngagementBoost) => EngagementBoost;

export const withModifiers = (
  modifiers: Modifier[],
  initialValue: EngagementBoost
): EngagementBoost => {
  return modifiers.reduce((acc, modifier) => {
    return modifier(acc);
  }, initialValue);
};

export const withEngagementBoostDefaults = (
  engagementBoostDefaults?: Partial<EngagementBoost>,
  setDefaultEnabled?: boolean
): Modifier => {
  return ({ retries, optimizedDeliveryEnabled }) => {
    if (!setDefaultEnabled) {
      return { retries, optimizedDeliveryEnabled };
    }
    return {
      retries: engagementBoostDefaults?.retries ?? retries,
      optimizedDeliveryEnabled:
        engagementBoostDefaults?.optimizedDeliveryEnabled ??
        optimizedDeliveryEnabled,
    };
  };
};
