import React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Insights } from 'components/publisher/settings/OrchestrationInsights/Insights';
import { Post } from 'models/publisher/post';

export const CampaignRules: React.FC<{ post: Post }> = ({ post }) => {
  return (
    <Section title="Audience Insights">
      <Row>
        <Block>
          <Insights settings={post.settings} contentId={post.content?.id} />
        </Block>
      </Row>
    </Section>
  );
};
