import React from 'react';
import { navigate, useLocation } from '@reach/router';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useContentDuplicator } from 'hooks/useContentDuplicator';
import { useStateMutation } from 'hooks/state-mutator';
import { Post } from 'models/publisher/post';

type LocationState = {
  from: string;
};

export type PageActions = {
  isDuplicating: boolean;
  canArchive: boolean;
  showingArchiveModal: boolean;
  showArchiveModal(): void;
  closeArchiveModal(): void;
  handleDuplicate(): void;
  handleConfirmArchive(): void;
};

export const useActions = (programId: number, post: Post): PageActions => {
  const [showingArchiveModal, setShowingArchiveModal] = React.useState(false);
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();
  const locationState = useLocation().state as LocationState;
  const canArchive = post.content.publicationState !== 'archived';

  const showArchiveModal = React.useCallback(
    () => canArchive && setShowingArchiveModal(true),
    [canArchive]
  );
  const closeArchiveModal = React.useCallback(
    () => setShowingArchiveModal(false),
    []
  );

  const { duplicate, isDuplicating } = useContentDuplicator();

  const { mutate: archive } = useStateMutation({
    action: 'archive',
    onSuccess: () => {
      queryClient.invalidateQueries(['paged-contents']);
      setFlashMessage({
        timeout: 5000,
        severity: 'info',
        message: 'Campaign archived.',
      });
    },
    onError: () => {
      setFlashMessage({
        timeout: 5000,
        severity: 'error',
        message: 'There was an error archiving.',
      });
    },
  });

  const handleDuplicate = React.useCallback(() => {
    duplicate({ post, programId });
  }, [duplicate, post, programId]);

  const handleConfirmArchive = React.useCallback(() => {
    archive({ post, programId });
    closeArchiveModal();
    navigate(locationState?.from ?? `/${programId}/app/content`);
  }, [archive, closeArchiveModal, post, programId, locationState?.from]);

  return {
    canArchive,
    isDuplicating,
    showingArchiveModal,
    showArchiveModal,
    closeArchiveModal,
    handleDuplicate,
    handleConfirmArchive,
  };
};
