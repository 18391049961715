import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components';
import { Modal } from 'shared/Modal';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';

export const ConfirmationModal: React.FC<{
  usersCount: number;
  submit: () => void;
  toggle: (value: boolean) => void;
  actionName: string;
}> = ({ usersCount, submit, toggle, actionName }) => {
  const onClose = () => toggle(false);
  const [published, setPublished] = useState(false);
  const handlePublish = () => {
    setPublished(true);
    submit();
  };

  return (
    <Modal
      modalBodyStyle={{ maxHeight: '99%', overflow: 'auto', maxWidth: '40%' }}
      onClose={onClose}
      title=""
      showTitle
      showModal
    >
      <Box padding={8}>
        <Flex center style={{ marginTop: '-40px' }}>
          <img
            src="/campaign-confirmation.png"
            width="156"
            height="136"
            alt=""
          />
        </Flex>
        <div style={{ marginBottom: '20px' }}>
          This campaign will be delivered to <strong>{usersCount} users</strong>
          . Are you ready to {actionName.toLowerCase()}?
        </div>
        <Flex end>
          <Button focused secondary onClick={onClose} label="Cancel" />
          <Space row size={15} />
          <Button
            disabled={published}
            onClick={handlePublish}
            label={`${actionName} Campaign`}
          />
        </Flex>
      </Box>
    </Modal>
  );
};
