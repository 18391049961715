import { useCallback, useState } from 'react';
import { PollFieldData } from 'models/publisher/block';
import { AssetsTable, PollType } from 'models/poll';

const TextPresets = [PollType.single, PollType.multiple];

export const usePollState: (
  initial: PollFieldData,
  onChange: (poll: PollFieldData) => void,
  assets?: AssetsTable
) => {
  poll: PollFieldData;
  updatePoll: (poll: Partial<PollFieldData>) => void;
} = (initialState, onChange, assets) => {
  const [poll, setPoll] = useState({
    ...initialState,
    options: TextPresets.includes(initialState.preset as PollType)
      ? initialState.options
      : ['Enter Answer 1', 'Enter Answer 2'],
  });
  const updatePoll = useCallback(
    (value: Omit<Partial<PollFieldData>, 'poll_id' | 'processed'>) => {
      const updated = { ...poll, ...value };
      const isEditingText = TextPresets.includes(updated.preset as PollType);
      const wasEditingText = TextPresets.includes(poll.preset as PollType);

      // When the user switches to "single" or "multiple" from another type,
      // we restore the answers they had already typed.
      // This allows them to explore the types without losing their work.
      /* eslint-disable no-nested-ternary -- yeah yeah. */
      const options =
        !wasEditingText && isEditingText
          ? poll.options
          : updated.preset === PollType.satisfaction
          ? [
              assets?.satisfaction.very_dissatisfied.url ?? '',
              assets?.satisfaction.dissatisfied.url ?? '',
              assets?.satisfaction.neutral.url ?? '',
              assets?.satisfaction.satisfied.url ?? '',
              assets?.satisfaction.very_satisfied.url ?? '',
            ]
          : updated.preset === PollType.stars
          ? [
              assets?.stars.star_selected.url ?? '',
              assets?.stars.star_selected.url ?? '',
              assets?.stars.star_selected.url ?? '',
              assets?.stars.star_selected.url ?? '',
              assets?.stars.star_unselected.url ?? '',
            ]
          : updated.preset === PollType.yes_no
          ? [
              assets?.yes_no.thumb_down.url ?? '',
              assets?.yes_no.thumb_up.url ?? '',
            ]
          : updated.options;
      /* eslint-enable no-nested-ternary -- you saw nothing. */

      // this one should maintain the user text inputs
      setPoll(updated);
      // this one should have the image urls
      onChange({ ...updated, options });
    },
    [onChange, poll, assets]
  );
  return {
    poll,
    updatePoll,
  };
};
