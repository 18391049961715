import { RouteComponentProps } from '@reach/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import {
  Checkbox,
  FormSection,
  FormSubsection,
  Textarea,
} from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { BOX_MANAGEMENT_INFINITE_QUERY_KEY } from 'hooks/box-knowledge-mangement';
import { updateBoxConfiguration } from 'services/api-box-mangement';
import { TextInput } from 'shared/TextInput';
import { WithPermission } from 'shared/WithPermission';
import { BOX_CONFIGURATION_PAGE_BREADCRUMBS } from '../shared/breadcrumbs';
import {
  BOX_CONFIGURATION_PAGE_DESCRIPTION,
  BOX_CONFIGURATION_PAGE_TITLE,
} from '../shared/header';
import { getBoxConfigurationPageTabs } from '../shared/tabs';
import styles from './styles.module.css';

export const BoxConfigurationPage: React.FC<RouteComponentProps> = () => {
  const { setFlashMessage } = useFlashMessage();
  const { permissions } = usePermissions();
  const { id: programId } = useProgram();
  const client = useQueryClient();

  const [isSaving, setIsSaving] = useState(false);

  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [publicKeyId, setPublicKeyId] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [passphrase, setPassphrase] = useState('');
  const [enterpriseId, setEnterpriseId] = useState('');
  const [shouldClearMappings, setShouldClearMappings] = useState<boolean>(
    false
  );
  const [shouldConfirmClearMappings, setShouldConfirmClearMappings] = useState(
    false
  );

  useEffect(() => {
    setShouldConfirmClearMappings(shouldClearMappings);
  }, [shouldClearMappings]);

  const hasError =
    !clientId ||
    !clientSecret ||
    !publicKeyId ||
    !privateKey ||
    !passphrase ||
    !enterpriseId;

  const clearForm = useCallback(() => {
    setClientId('');
    setClientSecret('');
    setPublicKeyId('');
    setPrivateKey('');
    setPassphrase('');
    setEnterpriseId('');
    setShouldClearMappings(false);
  }, []);

  const onSave = useCallback(async () => {
    setIsSaving(true);

    try {
      await updateBoxConfiguration(
        programId,
        {
          boxAppSettings: {
            clientId,
            clientSecret,
            appAuth: {
              publicKeyId,
              privateKey,
              passphrase,
            },
          },
          enterpriseId,
        },
        shouldClearMappings
      );

      client.invalidateQueries(BOX_MANAGEMENT_INFINITE_QUERY_KEY);
      clearForm();

      setFlashMessage({
        severity: 'info',
        message: 'Your Box configuration has been updated.',
      });
    } catch (err) {
      setFlashMessage({
        severity: 'error',
        message:
          'There was an error updating the Box configuration. Please try again.',
      });
      throw err;
    } finally {
      setIsSaving(false);
    }
  }, [
    clearForm,
    client,
    clientId,
    clientSecret,
    enterpriseId,
    passphrase,
    privateKey,
    programId,
    publicKeyId,
    setFlashMessage,
    shouldClearMappings,
  ]);

  return (
    <WithPermission
      permissions={[
        'configureBoxKnowledgeManagementAccess',
        'boxKnowledgeManagementConfigureAppAccess',
      ]}
      operation="all"
    >
      <FormPage
        title={BOX_CONFIGURATION_PAGE_TITLE}
        description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
        breadcrumbs={BOX_CONFIGURATION_PAGE_BREADCRUMBS}
        tabs={getBoxConfigurationPageTabs(permissions)}
        actions={[
          {
            label: 'Save',
            disabled: hasError,
            onClick: onSave,
            isLoading: isSaving,
          },
        ]}
      >
        <FormSection
          title="Configure Box App"
          description="Fill in the form below with the values from the config.json from Box to set the Box app this program uses."
        >
          <FormSubsection title="Client ID" divider={false}>
            <TextInput
              onChange={setClientId}
              value={clientId}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Client Secret" divider>
            <TextInput
              onChange={setClientSecret}
              value={clientSecret}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Public Key ID" divider={false}>
            <TextInput
              onChange={setPublicKeyId}
              value={publicKeyId}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Private Key" divider={false}>
            <Textarea
              onChange={setPrivateKey}
              value={privateKey}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Passphrase" divider>
            <TextInput
              onChange={setPassphrase}
              value={passphrase}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Enterprise ID" divider>
            <TextInput
              onChange={setEnterpriseId}
              value={enterpriseId}
              className={styles.textInput}
            />
          </FormSubsection>

          <Checkbox
            checked={shouldClearMappings}
            onChange={setShouldClearMappings}
            label="Clear existing Box entity mappings"
          />
          {!shouldClearMappings && (
            <span className={styles.error}>
              Warning: References to Box files and folder in existing content
              and mapped folders may be unreachable if you connect the
              integration to a different Box app.
            </span>
          )}
        </FormSection>
      </FormPage>

      {shouldConfirmClearMappings && (
        <ConfirmModal
          title="Are you sure?"
          confirmLabel="Yes"
          onCancel={() => setShouldClearMappings(false)}
          onConfirm={() => setShouldConfirmClearMappings(false)}
        >
          <p>
            You are about to clear all references to the Box files and folders
            in the current program. This action cannot be undone.
          </p>
          <br />
          <p>
            Unless you are switching to a new Box app, you shouldn&apos;t need
            to perform this action. Are you sure you want to continue with
            clearing the mappings?
          </p>
        </ConfirmModal>
      )}
    </WithPermission>
  );
};
