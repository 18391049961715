import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useProgramQuery } from 'hooks/program';
import { useProgram } from 'contexts/program';
import { TermsSection } from './TermsSection';
import { PolicySection } from './PolicySection';
import { GdprSection } from './GdprSection';
import styles from './legal.module.css';

export const Legal: React.FC<RouteComponentProps> = () => {
  // this only needed for returning to the page after updating in terms/policy/compliance
  // to keep everything in the page up to date without refresh
  useProgramQuery(useProgram().id);
  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        title="Legal"
        description="Manage legal pages, like terms of use and privacy policy"
        breadcrumbs={[{ label: 'Configure', to: '..' }, { label: 'Legal' }]}
      >
        <TermsSection />
        <div className={styles.SectionMarginTop}>
          <PolicySection />
        </div>
        <div className={styles.SectionMarginTop}>
          <GdprSection />
        </div>
      </FormPage>
    </WithPermission>
  );
};
