import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { SearchBar } from 'shared/SearchBar';
import { FilterDropdown } from 'shared/FilterDropdown';
import { OptionType } from 'hooks/common';

export enum FilterType {
  SEARCH = 'search',
  CREATOR = 'creator',
  CREATOR_SEARCH = 'creator_search',
}

export type FilterValues = {
  [FilterType.SEARCH]: string;
  [FilterType.CREATOR]: string[];
  [FilterType.CREATOR_SEARCH]: string;
};

type PropsType = {
  onChange: (filterName: FilterType, value: string | string[]) => void;
  onClose?: () => void;
  searchTerm?: string;
  creatorSearchTerm?: string;
  creators: OptionType[];
  selectedCreators?: string[];
  types?: string[];
};

export const BoxFiltersBar: React.FC<PropsType> = ({
  onChange,
  onClose,
  searchTerm,
  creatorSearchTerm,
  creators,
  selectedCreators,
}) => {
  let creatorsInUse = creators;
  if (creators.length === 0 && (selectedCreators?.length || 0) > 0) {
    creatorsInUse =
      creators?.map((t) => {
        return { value: t.value, label: t.label };
      }) || [];
  }
  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={(value) => onChange(FilterType.SEARCH, value)}
        placeholder="Search folders"
        panelClassName={styles.searchBarWrapper}
      />
      <FilterDropdown
        label="Creator"
        searchEnabled
        clearDisabled={false}
        searchPlaceholder="Search creators"
        hasClearSearchButton
        onChange={(value) => onChange(FilterType.CREATOR, value)}
        options={creators}
        onSearchTermChange={(value) => {
          onChange(FilterType.CREATOR_SEARCH, value);
        }}
        searchTerm={creatorSearchTerm}
        selectedOptions={creatorsInUse.filter((opt) =>
          selectedCreators?.includes(opt.value)
        )}
        onDropDownClose={onClose}
      />
    </div>
  );
};
