import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { TLinkItem } from 'models/shortcut';
import { FieldValidationError } from '../types';
import { validateLink } from '../validations/linkValidation';
import { LINK_COUNT_LIMIT } from '../constants';
import { HandleRemoveLinkFn, LinkOnChangeFn } from '../../create/LinksCard';

export const emptyLinkItem: TLinkItem = { label: '', url: '' };

type UseLinkFieldsResult = {
  links: TLinkItem[];
  setLinks: Dispatch<SetStateAction<TLinkItem[]>>;
  errors: FieldValidationError[];
  onAddLink: () => void;
  onLinkChange: LinkOnChangeFn;
  onLinkRemove: HandleRemoveLinkFn;
};

export function useLinkFields(): UseLinkFieldsResult {
  const [links, setLinks] = useState([{ ...emptyLinkItem }]);
  const [errors, setErrors] = useState<FieldValidationError[]>([]);

  function setLinkError(index: number, error: FieldValidationError) {
    setErrors((prev) => {
      const newErrors = [...(prev ?? [])];
      if (newErrors) {
        newErrors[index] = error;
      }
      return newErrors;
    });
  }

  function removeLinkError(index: number) {
    setErrors((prev) => {
      prev.splice(index, 1);
      return prev;
    });
  }

  function onAddLink() {
    setLinks((prev) => {
      if (prev.length >= LINK_COUNT_LIMIT) {
        return prev;
      }
      return [...prev, emptyLinkItem];
    });
  }

  const onLinkChange: LinkOnChangeFn = (value, index) => {
    setLinks((prev) => {
      const newLinks = [...prev];
      newLinks[index] = { ...newLinks[index], ...value };
      return newLinks;
    });

    const error = validateLink(value);
    if (Object.keys(error).length > 0) {
      setLinkError(index, error);
    } else {
      removeLinkError(index);
    }
  };

  function onLinkRemove(index: number) {
    setLinks((prev) => {
      if (prev.length === 1) {
        return prev;
      }
      const newLinks = [...prev];
      newLinks.splice(index, 1);
      return newLinks;
    });

    removeLinkError(index);
  }

  const onSetLinks: typeof setLinks = useCallback((val) => {
    setLinks(val);
    setErrors([]);
  }, []);

  return {
    links,
    setLinks: onSetLinks,
    errors,
    onAddLink,
    onLinkChange,
    onLinkRemove,
  };
}
