import React from 'react';
import { ConfirmModal } from 'DesignSystem/Components';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WarningTriangle } from 'shared/icons';
import { Journey } from 'models/journeys/journey';
import { useProgram } from 'contexts/program';
import { useNavigate } from '@reach/router';
import {
  useJourneyDeleteDraftAction,
  useJourneyStopAction,
  useJourneyAction,
} from 'hooks/journeys/useJourneyActions';

type IconType = 'warning' | 'none';

interface JourneyDangerActionModalProps {
  title: string;
  confirmLabel: string;
  description: string;
  isLoading: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  iconType?: IconType;
}

const iconsMap = {
  warning: <WarningTriangle color="#dd1d1d" />,
  none: undefined,
};

const JourneyDangerActionModal: React.FC<JourneyDangerActionModalProps> = ({
  title,
  confirmLabel,
  description,
  isLoading,
  onConfirm,
  onCancel = () => {},
  iconType = 'warning',
}) => {
  return (
    <ConfirmModal
      title={title}
      titleIcon={iconsMap[iconType]}
      confirmLabel={confirmLabel}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmIcon={isLoading ? <LoadingSpinner size="small" /> : undefined}
      disabled={isLoading}
      disableCancel={isLoading}
      hideCancel={onCancel === undefined}
    >
      {description}
    </ConfirmModal>
  );
};

export const journeyActionModalStates = [
  'stop',
  'pause',
  'pause-failed',
  'resume',
  'resume-failed',
  'delete-draft',
  null,
] as const;

export type JourneyActionModalOpened = typeof journeyActionModalStates[number];

type JourneyActionModalProperties = {
  title: string;
  confirmLabel: string;
  description: string;
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function useJourneyActionModal(
  journey: Pick<Journey, 'name'>
):
  | readonly [
      JourneyActionModalOpened,
      React.Dispatch<React.SetStateAction<JourneyActionModalOpened>>,
      JourneyActionModalProperties
    ]
  | readonly [
      null,
      React.Dispatch<React.SetStateAction<JourneyActionModalOpened>>,
      null
    ] {
  const navigate = useNavigate();

  const { id: programId } = useProgram();
  const [openedModal, setOpenedModal] = React.useState<
    JourneyActionModalOpened
  >(null);

  function modalProps(obj: JourneyActionModalProperties) {
    return [openedModal, setOpenedModal, obj] as const;
  }

  const dismissModal = () => {
    setOpenedModal(null);
  };

  const {
    call: stopJourney,
    isLoading: isStoppingJourney,
  } = useJourneyStopAction(
    journey,
    () => {
      dismissModal();
      navigate(`/${programId}/app/journeys`);
    },
    dismissModal
  );

  const onPauseSuccess = () => {
    dismissModal();
  };
  const onPauseFailed = () => {
    setOpenedModal('pause-failed');
  };
  const { call: pauseJourney, isLoading: isPausingJourney } = useJourneyAction(
    'pause',
    journey,
    onPauseSuccess,
    onPauseFailed
  );

  const onResumeSuccess = () => {
    dismissModal();
  };
  const onResumeFailed = () => {
    setOpenedModal('resume-failed');
  };
  const {
    call: resumeJourney,
    isLoading: isResumingJourney,
  } = useJourneyAction('resume', journey, onResumeSuccess, onResumeFailed);

  const {
    call: deleteJourneyDraft,
    isLoading: isDeletingJourneyDraft,
  } = useJourneyDeleteDraftAction(
    journey,
    () => {
      dismissModal();
      navigate(`/${programId}/app/journeys`);
    },
    dismissModal
  );

  switch (openedModal) {
    case 'stop':
      return modalProps({
        title: `Stop ${journey.name}`,
        confirmLabel: isStoppingJourney ? 'Stopping' : 'Yes, Stop Journey',
        description:
          'This will remove all members and turn the active journey into a draft. It cannot be undone.',
        isLoading: isStoppingJourney,
        onConfirm: stopJourney,
        onCancel: dismissModal,
      });
    case 'pause':
      return modalProps({
        title: `Pause ${journey.name}`,
        confirmLabel: isPausingJourney ? 'Pausing' : 'Yes, Pause Journey',
        description:
          'Pausing this journey will stop new members from entering the journey. All current members will remain in the journey, but will not move forward until the journey is resumed.',
        isLoading: isPausingJourney,
        onConfirm: pauseJourney,
        onCancel: dismissModal,
      });
    case 'pause-failed':
      return modalProps({
        title: `Pause Error`,
        confirmLabel: 'Ok',
        description:
          'There was an issue pausing this journey. Please try again.',
        isLoading: false,
        onConfirm: dismissModal,
        onCancel: dismissModal,
      });
    case 'resume':
      return modalProps({
        title: `Resume ${journey.name}`,
        confirmLabel: isResumingJourney ? 'Resuming' : 'Yes, Resume Journey',
        description:
          'All current journey members will resume the journey from their current step and new members will be added as they qualify.',
        isLoading: isResumingJourney,
        onConfirm: resumeJourney,
        onCancel: dismissModal,
      });
    case 'resume-failed':
      return modalProps({
        title: `Resume Error`,
        confirmLabel: 'Ok',
        description:
          'There was an issue resuming this journey. Please try again.',
        isLoading: false,
        onConfirm: dismissModal,
        onCancel: dismissModal,
      });
    case 'delete-draft':
      return modalProps({
        title: `Delete Draft of ${journey.name}`,
        confirmLabel: isDeletingJourneyDraft
          ? 'Deleting Draft'
          : 'Yes, Delete Draft',
        description: 'This will delete journey graph. It cannot be undone.',
        isLoading: isDeletingJourneyDraft,
        onConfirm: deleteJourneyDraft,
        onCancel: dismissModal,
      });
    case null:
      return [null, setOpenedModal, null] as const;
    default:
      exhaustiveModalCheck(openedModal);
      return [null, setOpenedModal, null] as const;
  }
}

function exhaustiveModalCheck(open: never) {
  throw new Error(`Unhandled modal: ${open}`);
}

export default JourneyDangerActionModal;
