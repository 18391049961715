/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MetabaseReport, ViewPort } from 'models/insight/Report';
import styles from '../insight.module.css';

type PropsType = {
  embedUrl?: string;
  metabaseReport?: MetabaseReport;
  enableLoadingIndicator?: boolean;
  loadingIndicatorHeight?: number;
  loadingIndicatorTimeout?: number;
  setViewPort?: React.Dispatch<React.SetStateAction<ViewPort>>;
  auditDownload: (token: string) => void;
};

export const MetabaseEmbedIframe: React.FC<PropsType> = ({
  metabaseReport,
  embedUrl,
  enableLoadingIndicator = true,
  loadingIndicatorHeight = 210,
  loadingIndicatorTimeout = 5000,
  setViewPort,
  auditDownload,
}) => {
  /**
   * This component renders an iframe via the `embedUrl` from a metabase report model
   * We use the iframe-resizer-react component to automatically handle height resizing
   * see https://github.com/davidjbradshaw/iframe-resizer-react
   * and https://www.metabase.com/docs/latest/administration-guide/13-embedding.html#resizing-dashboards-to-fit-their-content
   * Metabase also implements this plugin to allow for easy height resizing
   *
   * DETERMINING LOADING STATE
   * During the loading state we will display a fixed height loading indicator
   * the iframe resizer plugin does not explicitly tell us when the metabase views are loading
   * we have to infer that from the following events that we do have access to:
   * [LOADING STATE]
   * - "onResize" event, height 150    // the first message on the initial render of all metabase views
   * - "onInit" event                  // indicates the init event
   * - "onResize" event, height 196    // the metabase default "loading" view height
   * [ACTIVE STATE]
   * - "onResize" event, height != 196 // loading state is assumed finished if height !== 196
   *                                   // and "onInit" event already happened
   *
   * KNOWN ISSUE:
   * the metabase embedded view will responsively resize from desktop to mobile view
   * Mobile view naturally has a much larger height compared to desktop
   * If we go from mobile to desktop views, the original height of the mobile view will be preserved
   * This happens in old studio as well and seems to be an issue on the metabase side
   * since the iframe body height remains the same, not our kai body
   * Therefore, there doesn't seem to be a way to overcome this.
   */

  const fetch_kind = (url: string | undefined) => {
    if (url) {
      const contents: string[] = url.split('/');
      return contents[4];
    }
    return null;
  };

  /*
   * Kai handles authorization by token. Bossanova knows how to authenticate this token against
   * governor and sends an authenticated insights request to dojo.
   * When we audit a report download from kai, metabase doesn't know about this token and
   * the request fails. We have decided to delegate the calling of the endpoint to kai.
   * This works by listening to a message sent from metabase with the necessary token data
   */
  const audit = React.useCallback(
    (message: MessageEvent) => {
      if (message.data.type === 'audit.metabase.report.download') {
        const embedSrc = new URL(embedUrl || metabaseReport?.embedUrl || '');
        if (embedSrc.origin === message.origin) {
          auditDownload(message.data.embedToken);
        }
      }
    },
    [auditDownload, embedUrl, metabaseReport]
  );

  const METABASE_LOADING_IFRAME_HEIGHT = 196;
  const [didInit, setDidInit] = React.useState<boolean>(false);
  const [frameHeight, setHeight] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isTimedOut, setIsTimedOut] = React.useState<boolean>(false);
  const kind = fetch_kind(metabaseReport?.embedUrl || embedUrl);
  type IFRIFrame = HTMLIFrameElement & {
    iFrameResizer?: { removeListeners: () => void };
  };
  const iFrameRef = React.createRef<IFRIFrame>();

  React.useEffect(() => {
    const timer = setTimeout(
      () => setIsTimedOut(true),
      loadingIndicatorTimeout
    );
    return () => clearInterval(timer);
  }, [loadingIndicatorTimeout]);

  // update the loading state based on the init and height variables
  React.useEffect(() => {
    if (
      isTimedOut ||
      (frameHeight > METABASE_LOADING_IFRAME_HEIGHT && didInit)
    ) {
      setIsLoading(false);
    }
  }, [didInit, frameHeight, isTimedOut]);

  React.useEffect(() => {
    window.addEventListener('message', audit);
    return () => window.removeEventListener('message', audit);
  }, [audit]);

  const onResized = ({ height, width }: { height: number; width: number }) => {
    setHeight(height);

    if (setViewPort) {
      setViewPort({
        width,
        height,
        deviceScaleFactor: window.devicePixelRatio > 1 ? 2 : 1,
      });
    }
  };

  const syncParams = () => {
    iFrameRef.current!.style.height = '600px';
    iFrameRef.current!.style.width = '100%';
    iFrameRef.current!.style.border = 'none';
    iFrameRef.current!.style.visibility = 'visible';
    setHeight(600);
    setDidInit(true);
  };

  return (
    <div className={styles.metabaseEmbedContainer}>
      {enableLoadingIndicator && isLoading && (
        <div
          className={styles.iFrameLoadingIndicatorContainer}
          style={{ height: loadingIndicatorHeight }}
        >
          <LoadingSpinner />
        </div>
      )}
      {(kind === 'question' && (
        <iframe
          src={embedUrl || metabaseReport?.embedUrl}
          ref={iFrameRef}
          onLoad={syncParams}
          title="unique"
          style={{ visibility: 'hidden' }}
        />
      )) || (
        <IframeResizer
          src={embedUrl || metabaseReport?.embedUrl}
          checkOrigin={[
            new URL(embedUrl || metabaseReport?.embedUrl || '').origin,
          ]}
          frameBorder="0"
          // according to IframeResizer readme this `style` improves rendering on ios
          style={{
            width: '1px',
            minWidth: '100%',
            visibility:
              enableLoadingIndicator && isLoading ? 'hidden' : 'visible',
          }}
          onInit={() => setDidInit(true)}
          // gotcha: callbacks passed to onResized only maintain the initial state of the component
          // this is because internally this method is only bounded the first time it is rendered
          // https://github.com/davidjbradshaw/iframe-resizer-react/blob/0b76902217c03f5f0eee8773871d40046528d832/src/index.jsx#L23-L31
          onResized={onResized}
        />
      )}
    </div>
  );
};
