// solution to invert the actual color can be taken from this SO answer:
// https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
import { COLOR_REGEX } from '../models/colors';

export const hexToRGB = (input: string): number[] => {
  let hex = input;
  if (!hex.match(COLOR_REGEX)) {
    return [0, 0, 0];
  }

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  const rHex = hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2);
  const gHex = hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4);
  const bHex = hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6);

  // dark text for light background and vise-versa
  // taken from here: https://css-tricks.com/css-variables-calc-rgb-enforcing-high-contrast-colors/
  const r = parseInt(rHex, 16);
  const g = parseInt(gHex, 16);
  const b = parseInt(bHex, 16);
  return [r, g, b];
};

export const contrastColor = (input: string): string => {
  const [r, g, b] = hexToRGB(input);
  const sum = Math.round((r * 299 + g * 587 + b * 114) / 1000);

  return sum > 128 ? '#000000' : '#ffffff';
};
