/* eslint-disable max-classes-per-file */

export type Edit = {
  term: string;
  explanation: string;
  suggestion: string[];
};

export type Match = Edit & {
  offset: number;
};

export type ContentMarker = {
  selector: string;
  root: string;
  type: 'content';
};

export type InputMarker = {
  selector: string;
  offset: number;
  type: 'input';
};

export type SuggestionDetail = {
  edit: Edit;
  marker: ContentMarker | InputMarker;
  event: MouseEvent;
  position?: { top: number; left: number };
};

export const InclusiveLanguageEvents = {
  names: {
    show: 'ShowPopupSuggestion' as 'click',
    hide: 'HidePopupSuggestion' as 'click',
    applied: 'PopupSuggestionApplied' as 'click',
  },
  pendingDetail: [] as SuggestionDetail[],
  show(detail: SuggestionDetail, el: Document | Element = document): void {
    InclusiveLanguageEvents.pendingDetail.push(detail);
    el.dispatchEvent(new Event(InclusiveLanguageEvents.names.show));
  },
  hide(el: Document | Element = document): void {
    el.dispatchEvent(new Event(InclusiveLanguageEvents.names.hide));
  },
  applied(el: Document | Element = document): void {
    el.dispatchEvent(new Event(InclusiveLanguageEvents.names.applied));
  },
};
