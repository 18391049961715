import { useCallback } from 'react';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { Content } from 'models/content';
import { Poll } from 'models/poll';

type UrlsReporter = {
  content: Content | Poll;
  startedAt?: DateTime;
  version?: number;
};

export const useContentReportUrls = ({
  content,
  startedAt,
  version,
}: UrlsReporter): {
  getArticleUrl: () => string;
  getVideoUrl: (id: number) => string;
  getPollUrl: (id: number) => string;
  getDeliveryDetailsUrl: () => string;
} => {
  const { id: programId } = useProgram();
  const { id: contentId, publishedAt } = content;
  const startDate =
    startedAt?.toFormat('yyyy-MM-dd') ??
    (publishedAt ? DateTime.fromISO(publishedAt).toFormat('yyyy-MM-dd') : '');

  const currentDate = DateTime.now().toFormat('yyyy-MM-dd');

  const baseUrl = `/${programId}/app/insights/report`;
  const contentVersion = version ?? (content as Content)?.version ?? 1;

  const getArticleUrl = useCallback(() => {
    if (startDate === '') {
      return '';
    }

    let path = 'content-summary';
    if (contentVersion === 2) {
      path = 'campaign-performance-v2';
    }

    return encodeURI(
      `${baseUrl}/${path}?content_id[0]=${contentId}&date_range[0]=${startDate}~${currentDate}`
    );
  }, [startDate, contentVersion, baseUrl, contentId, currentDate]);

  const getVideoUrl = useCallback(
    (id: number) => {
      return startDate !== ''
        ? encodeURI(
            `${baseUrl}/video-performance?content[0]=${contentId}&video_id[0]=${id}&date_range[0]=${startDate}~${currentDate}`
          )
        : '';
    },
    [baseUrl, contentId, startDate, currentDate]
  );

  const getPollUrl = useCallback(
    (id: number) => {
      return publishedAt != null
        ? encodeURI(
            `${baseUrl}/poll-summary?poll[0]=${id}&date_range[0]=${startDate}~${currentDate}`
          )
        : '';
    },
    [baseUrl, publishedAt, startDate, currentDate]
  );

  const getDeliveryDetailsUrl = useCallback(() => {
    return contentVersion === 2
      ? encodeURI(
          `${baseUrl}/campaign-delivery-details-v2?content_id[0]=${contentId}&date_range[0]=${startDate}~${currentDate}`
        )
      : '';
  }, [baseUrl, contentId, contentVersion, currentDate, startDate]);

  return {
    getArticleUrl,
    getVideoUrl,
    getPollUrl,
    getDeliveryDetailsUrl,
  };
};
