import React from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import useObserver from 'use-resize-observer';

type Props = {
  ref: React.MutableRefObject<HTMLElement | null>;
  onResize: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.ResizeObserver) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.ResizeObserver = ResizeObserver;
}

// a simple wrapper that always provides the IE11 polyfill
export function useResizeObserver({ ref, onResize }: Props): void {
  useObserver({
    ref,
    onResize,
  });
}
