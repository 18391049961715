import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { useUserImportQuery } from 'hooks/user-imports';
import { WithPermission } from 'shared/WithPermission';
import { Main } from './Main';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';

export const UserImportView: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { isLoading, data } = useUserImportQuery(programId, Number(id));

  if (isLoading) return <div>Loading...</div>;

  const importData = data as BulkUploadJob;
  const header = (
    <>
      <h1 className="page-header">Upload Audience</h1>
    </>
  );

  const main = <Main data={importData} />;
  const sidebar = <Sidebar data={importData} />;
  const footer = <Footer title={importData.fileName} />;

  return (
    <WithPermission permissions={['confContentAccess']}>
      <MainEditor
        header={header}
        main={main}
        sidebar={sidebar}
        footer={footer}
      />
    </WithPermission>
  );
};
