import { useMemo } from 'react';
import { useLibraryFontsCollection } from './use-library-fonts-collection';

export type FontOption = {
  label: string;
  value: string;
  id: string;
  url?: string;
  isEnabledForProgram: boolean;
};
export const useFontOptions: () => {
  fontOptions: FontOption[];
  isLoading: boolean;
} = () => {
  const { data: fonts, isLoading: fontsLoading } = useLibraryFontsCollection();

  const fontOptions = useMemo(() => {
    return fonts
      .filter((i) => i.is_enabled_for_program)
      .map((i) => {
        return {
          label: i.title,
          value: i.asset.value,
          id: i.id,
          url: i.asset.css?.url,
          isEnabledForProgram: i.is_enabled_for_program,
        } as FontOption;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [fonts]);

  return { fontOptions, isLoading: fontsLoading };
};
