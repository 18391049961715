import React from 'react';
import cx from 'classnames';

import { OptionType } from 'hooks/common';
import { emptyExpression, SimpleExpression } from 'models/expression';
import { ValueSelect } from 'App/Program/Editors/AudienceBuilder/ValueSelect';
import { GenericSelect } from 'shared/Select';
import { editableTextToQuery } from '../shared/AudienceSelect';
import styles from './StartConfig.module.css';

type TriggerSelectProps = {
  expression?: SimpleExpression;
  onChange?: (value: SimpleExpression) => void;
  isDisabled?: boolean;
};

const criterionAttributes: OptionType[] = [
  {
    value: 'group',
    label: 'Audience',
  },
];

const operatorAttributes: OptionType[] = [
  {
    value: 'is',
    label: 'is',
  },
];

export const TriggerSelect = React.memo(
  ({ expression, onChange, isDisabled }: TriggerSelectProps) => {
    const triggerExpression =
      expression ??
      emptyExpression({
        criterion: criterionAttributes[0].value,
        operator: operatorAttributes[0].value,
      });

    const [criterion, setCriterion] = React.useState<string>(
      triggerExpression.criterion
    );
    const [operator, setOperator] = React.useState<string>(
      triggerExpression.operator
    );
    const [values, setValues] = React.useState<string[]>(
      triggerExpression.values
    );

    const onChangeValues = (newValues: string[]) => {
      setValues(newValues);

      onChange?.({
        ...triggerExpression,
        values: newValues,
      });
    };

    return (
      <div className={styles.simpleExpression}>
        <div
          className={cx(styles.criteriaSelectorLeft, {
            [styles.selectorDisabled]: isDisabled,
          })}
        >
          <GenericSelect
            value={criterion}
            onChange={setCriterion}
            options={criterionAttributes}
            openMenuOnFocus={false}
            openMenuOnClick={false}
            isSearchable={false}
            singleValueStyled={criterionAttributes.length === 1}
            isDisabled={isDisabled}
          />
        </div>

        <div
          className={cx(styles.criteriaSelectorMiddle, {
            [styles.selectorDisabled]: isDisabled,
          })}
        >
          <GenericSelect
            value={operator}
            onChange={setOperator}
            options={operatorAttributes}
            openMenuOnFocus={false}
            openMenuOnClick={false}
            isSearchable={false}
            singleValueStyled={operatorAttributes.length === 1}
            isDisabled={isDisabled}
          />
        </div>

        <div
          className={cx(styles.criteriaSelectorRight, {
            [styles.selectorDisabled]: isDisabled,
          })}
        >
          <ValueSelect
            criterion={criterion}
            operator={operator}
            onChange={onChangeValues}
            value={values}
            editableTextToQuery={editableTextToQuery}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    );
  }
);
