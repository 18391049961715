import React from 'react';
import { PillButtonDropdown } from 'App/Program/Main/Insight/components/Filters/shared/PillButtonDropdown';
import { FilterDropdownStructureProvider } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import { FilterDropdownProvider } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { WidgetType, DataSource } from 'models/insight/json/filterJson';
import { DateFilter, ContentFiltersContext } from 'contexts/content/filters';
import { Topic } from 'models/topic';
import { Author } from 'models/author';
import { DateRangeInputWrapper } from 'App/Program/Main/Insight/components/Filters/Dropdowns/DateRangeInputWrapper';
import { FilterState } from 'App/Program/Main/Insight/contexts/filtersStateReducer';
import { Filter } from 'models/insight/Filter';
import { DateRange } from 'shared/DateRangeInput/DateRange';

const defaultFilter = {
  widgetType: WidgetType.Date,
  dataType: DataSource.Attributes,
  label: '',
  slug: '',
};

type PropsType = {
  filter: DateFilter;
  setFilterValues?: (
    name: string,
    value: Array<string | Author | Topic>
  ) => void;
};
export const DateRangeFilter: React.FC<PropsType> = ({ filter }) => {
  const { setDateValue, setFilterSelected } = React.useContext(
    ContentFiltersContext
  );

  const [dateRangeFilter] = React.useState<Filter>({
    ...defaultFilter,
    label: filter.label,
    slug: filter.field,
  });

  const [filterState] = React.useState<FilterState>({
    filter: {
      ...defaultFilter,
      label: filter.label,
      slug: filter.field,
    },
    isLoading: false,
    selectedValues: undefined,
    availableListValues: undefined,
    errorMessage: undefined,
  });

  const onCloseButtonClick = React.useCallback(() => {
    setFilterSelected(filter.name, false);
    setDateValue(filter.name, '');
  }, [filter.name, setDateValue, setFilterSelected]);

  const updateFilter = React.useCallback(
    (_, value) => setDateValue(filter.name, value as string),
    [filter.name, setDateValue]
  );

  return (
    <FilterDropdownProvider
      key={dateRangeFilter.slug}
      filter={dateRangeFilter}
      filterState={filterState}
    >
      <FilterDropdownStructureProvider>
        <PillButtonDropdown onCloseButtonClick={onCloseButtonClick}>
          <DateRangeInputWrapper
            initialValue={DateRange.buildFromKey(filter.value)}
            updateFilter={updateFilter}
          />
        </PillButtonDropdown>
      </FilterDropdownStructureProvider>
    </FilterDropdownProvider>
  );
};
