import React from 'react';
import { Filter } from 'models/journeys/filter';
import { SearchBar } from 'shared/SearchBar';
import { Flex } from 'DesignSystem/Layout/Flex';
import { JourneyFilters } from './JourneyFilters';
import styles from './filters.module.css';

export const JourneyFilterBar: React.FC<{
  filters: Filter[];
  searchTerm: string;
  onFiltersChange: (filters: Filter[]) => void;
  onSearchChange: (searchTerm: string) => void;
}> = ({ filters, onFiltersChange, searchTerm, onSearchChange }) => {
  return (
    <Flex start className={styles.filterBar}>
      <SearchBar
        value={searchTerm}
        onChange={onSearchChange}
        placeholder="Search journeys"
      />
      <JourneyFilters filters={filters} onChange={onFiltersChange} />
    </Flex>
  );
};
