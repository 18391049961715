import * as React from 'react';
import { DateTime } from 'luxon';
import {
  AbsoluteRange,
  DateRange,
  Timezone,
} from 'shared/DateRangeInput/DateRange';
import { ComponentLayout } from './ComponentLayout';
import { useAdminPreferences } from '../../contexts/admin-preferences';

export interface DateRangeInputProps {
  initialValue?: DateRange;
  onClear?: () => void;
  onDone?: (dateRange: DateRange) => void;
  onStateChange?: (dateRange: DateRange) => void;
  onZoneChange?: (zone: Timezone) => void;
}

export const WeekPicker: React.FC<DateRangeInputProps> = ({
  initialValue,
  onStateChange,
  onZoneChange,
  onDone,
}) => {
  const [dateRange, setDateRange] = React.useState<DateRange>(
    DateRange.buildFromKey(initialValue, true)
  );
  const [calendarMonth, setVisibleMonth] = React.useState<DateTime>(
    DateTime.now()
  );
  const { adminPreferences, setHideWeekend } = useAdminPreferences();

  const showMonth = React.useCallback((m) => {
    setVisibleMonth(m);
  }, []);

  React.useEffect(() => {
    const newDateRange = DateRange.buildFromKey(initialValue, true);
    setDateRange(newDateRange);
    showMonth(newDateRange.toAbsolute().start);
  }, [initialValue, showMonth]);

  const handleDateRangeChange = (range: DateRange): void => {
    if (!range.isValid()) return;
    setDateRange(range.clone());

    if (onStateChange) {
      onStateChange(range);
    }

    if (onDone) {
      onDone(range);
    }

    setVisibleMonth(range.toAbsolute().end);
  };

  const handleTimezoneChange = (timezone: Timezone): void => {
    if (onZoneChange) {
      onZoneChange(timezone);
    }
  };

  const updateAbsoluteRange = (date: DateTime): void => {
    if (!(dateRange instanceof AbsoluteRange))
      throw Error('unexpected code path');

    let newStart;
    let newEnd;
    const start = date.startOf('week');
    const end = date.endOf('week');

    // force the week to start on Sunday
    if (parseInt(date.toFormat('c'), 10) === 7) {
      newStart = start.plus({ days: 6 }).startOf('day');
      newEnd = end.plus({ days: 6 }).startOf('day');
    } else {
      newStart = start.minus({ days: 1 }).startOf('day');
      newEnd = end.minus({ days: 1 }).startOf('day');
    }

    handleDateRangeChange(new AbsoluteRange(newStart, newEnd, true));
  };

  const previousCalendarYear = React.useCallback((): void => {
    setVisibleMonth(calendarMonth.minus({ years: 1 }));
  }, [calendarMonth]);

  const nextCalendarYear = React.useCallback((): void => {
    setVisibleMonth(calendarMonth.plus({ years: 1 }));
  }, [calendarMonth]);

  const toggleWeekend = React.useCallback(() => {
    setHideWeekend(!adminPreferences.hideWeekend);
  }, [adminPreferences.hideWeekend, setHideWeekend]);

  return (
    <ComponentLayout
      setDateRange={handleDateRangeChange}
      setTimezone={handleTimezoneChange}
      setCurrentRangeDate={updateAbsoluteRange}
      previousCalendarYear={previousCalendarYear}
      nextCalendarYear={nextCalendarYear}
      setVisibleMonth={setVisibleMonth}
      calendarMonth={calendarMonth}
      dateRange={dateRange}
      hideWeekend={adminPreferences.hideWeekend}
      toggleWeekend={toggleWeekend}
    />
  );
};
