import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionListBulleted: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    aria-label="Bulleted list"
    onClick={() => editor.chain().focus().toggleBulletList().run()}
    disabled={!editor.can().chain().focus().toggleBulletList().run()}
    isActive={editor.isActive('bulletList')}
    icon="format_list_bulleted"
  />
);
