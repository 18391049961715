import { Section, Subsection } from './Containers';
import styles from './forms.module.css';

const FormSection = Section;
const FormSubsection = Subsection;

export * from './InputElements';
export { Section, Subsection, FormSection, FormSubsection };

export { styles };
