import { BulkUploadJob } from 'models/bulk-upload-job';

export const toDuration = (
  milliseconds: number
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  const millisecondsInDay = 60 * 60 * 24 * 1000;
  const days = Math.floor(milliseconds / millisecondsInDay);
  const remHoursInMilliseconds = milliseconds % millisecondsInDay;
  const hours = Math.floor(remHoursInMilliseconds / 3600000);
  const remTime = milliseconds % 3600000;
  const minutes = Math.floor(remTime / 60000);

  return {
    days,
    hours,
    minutes,
  };
};

export const generateCompletionString = (data: BulkUploadJob): string => {
  if (!data.processedAt) return '';
  const timeDifference =
    Date.parse(data.processedAt) - Date.parse(data.createdAt);
  const { days, hours, minutes } = toDuration(timeDifference);
  return days
    ? `Processed in ${days}d ${hours}h ${minutes}m`
    : `Processed in ${hours}h ${minutes}m`;
};

export const generateReportString = (data: BulkUploadJob): string => {
  if (!data.data || !data.data.stats) return '';
  const { duplicate, imported, totalItems } = data.data.stats;
  return `${imported || 0} of ${totalItems} processed - ${
    duplicate || 0
  } duplicates`;
};

export const generateStatString = (
  data: BulkUploadJob
): {
  description: string;
  processed: string;
} => {
  const { errors } = data.data;
  if (!data.data.stats) {
    return {
      description: `Failed - ${errors ? errors[0] : ''}`,
      processed: '-',
    };
  }

  const { imported, totalItems } = data.data.stats;
  let description = '';
  let processed = '';
  switch (data.status) {
    case 'failed':
      description = `Failed - ${errors?.[0]}`;
      processed = `0 of ${totalItems}`;
      break;
    case 'partial':
      description = `Partially complete`;
      processed = `${imported || 0} of ${totalItems}`;
      break;
    case 'complete':
      description = 'Complete';
      processed = `${totalItems}`;
      break;
    default:
      description =
        imported && totalItems
          ? `Processing (${Math.round((imported / totalItems) * 100)}%)`
          : '';
      processed = `NA`;
  }

  return {
    description,
    processed,
  };
};
