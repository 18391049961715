import React, { createContext, FC, useCallback, useState } from 'react';

type BlockNavigationContextType = {
  shouldBlockNavigation: () => boolean;
  setShouldBlockNavigation: (
    shouldBlockNavigation: boolean | (() => boolean)
  ) => void;
};

export const BlockNavigationContext = createContext<BlockNavigationContextType>(
  {
    shouldBlockNavigation: () => false,
    setShouldBlockNavigation: () => {},
  }
);

export const BlockNavigationContextProvider: FC = ({ children }) => {
  const [blockNavigationState, setBlockNavigationState] = useState<
    boolean | (() => boolean)
  >(false);

  const shouldBlockNavigation = useCallback(() => {
    if (typeof blockNavigationState === 'function') {
      return blockNavigationState();
    }
    return blockNavigationState;
  }, [blockNavigationState]);

  const setShouldBlockNavigation = useCallback(
    (newVal: boolean | (() => boolean)) => {
      if (typeof newVal === 'function') {
        setBlockNavigationState(() => newVal);
        return;
      }
      setBlockNavigationState(newVal);
    },
    []
  );

  return (
    <BlockNavigationContext.Provider
      value={{
        shouldBlockNavigation,
        setShouldBlockNavigation,
      }}
    >
      {children}
    </BlockNavigationContext.Provider>
  );
};
