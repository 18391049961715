import * as React from 'react';
import cx from 'classnames';
import * as Type from 'DesignSystem/Typography';
import { Alert, AlertType } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { GlobalTab } from './GlobalTab';
import styles from './styles.module.css';
import { CanvasGlobalPanel } from './CanvasPanels';

export const GlobalSidebar: React.FC = () => {
  const designPermission = React.useContext(DesignPermissionContext);
  return (
    <CanvasGlobalPanel hasCloseButton side="left">
      <div id="publisher-global-styles-sidebar">
        <div className={cx(styles.sidebarPadding, styles.sidebarMargin)}>
          {!designPermission.isLoading && !designPermission.canEdit && (
            <div className={styles.sidebarMargin}>
              <Alert
                type={AlertType.error}
                title="Editing unavailable"
                message={designPermission.errors.map((error) => (
                  <div>{error}</div>
                ))}
                bgColor="red"
                enableIcon
                icon={<MAIcon name="warning" />}
              />
            </div>
          )}
          <div style={{ margin: '16px' }}>
            <Type.Heading bold block>
              Global Styles
            </Type.Heading>
          </div>
        </div>
        <GlobalTab disabled={!designPermission.canEdit} />
      </div>
    </CanvasGlobalPanel>
  );
};
