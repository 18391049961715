import {
  CommunicationStep,
  defaultDeliveryChannel,
  DeliveryChannel,
  DeliveryChannelName,
  isNotifitcationCenterChannel,
  Journey,
} from 'models/journeys/journey';
import type { JourneyActionFor } from './journey-reducer';
import { getDraftStep, updateStep } from './steps';

export function handleCommunicationChannelUpdated(
  state: Journey,
  action: JourneyActionFor<'step/communication/channel/updated'>
): Journey {
  const step = getDraftStep(state, 'communication', action.stepId);
  if (!step) return state;
  const updatedChannels = step.channels?.map((channel) => {
    const match = channel.name === action.channel.name;
    if (!match) return channel;
    return {
      ...channel,
      ...action.channel,
    };
  });

  return updateStep(state, Object.assign(step, { channels: updatedChannels }));
}

export function handleCommunicationChannelToggled(
  state: Journey,
  action: JourneyActionFor<'step/communication/channel/toggled'>
): Journey {
  const step = getDraftStep(state, 'communication', action.stepId);
  if (!step) return state;
  const { channel } = action;
  const togglingOn = !step.selectedChannels.includes(channel);
  const channelExists = step.channels.some((c) => c.name === channel);

  const selectedChannels = togglingOn
    ? [...step.selectedChannels, channel]
    : step.selectedChannels.filter(exclude(channel));

  const hasMultipleChannels = selectedChannels.length > 1;
  const updatedStep: CommunicationStep = {
    ...step,
    selectedChannels,
    channelDelivery: hasMultipleChannels
      ? step.channelDelivery ?? 'optimize'
      : undefined,
  };

  if (togglingOn && !channelExists) {
    updatedStep.channels = appendDefaultChannel(step.channels, channel);
  }

  return updateStep(state, updatedStep);
}

function exclude(channel: DeliveryChannelName) {
  const togglingNotificationCenter = channel === 'notification_center';
  return (chan: DeliveryChannelName) => {
    // Remove the channel from the array
    if (chan === channel) {
      return false;
    }

    // if unchecking notification center, also remove the push channel
    if (togglingNotificationCenter && chan === 'push') {
      return false;
    }

    return true;
  };
}

function appendDefaultChannel(
  stepChannels: DeliveryChannel[],
  channel: DeliveryChannelName
) {
  const defaultChannel = defaultDeliveryChannel(channel);
  const isPushChannel = channel === 'push' && defaultChannel.name === 'push';

  if (isPushChannel) {
    const notifCenterText = stepChannels.find(isNotifitcationCenterChannel)
      ?.text;

    const pushWithNotificationCenterText = {
      ...defaultChannel,
      text: notifCenterText ?? defaultChannel.text,
    };
    return [...stepChannels, pushWithNotificationCenterText];
  }

  return [...stepChannels, defaultChannel];
}
