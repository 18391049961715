import * as React from 'react';
import { FieldInput, Input } from 'DesignSystem/Form';
import { FormModal } from 'DesignSystem/Components';
import { useQuery } from 'react-query';
import { findOrCreateExternalVideo } from 'services/api-assets';
import { useDebounce } from 'hooks/useDebounce';
import { Video } from 'models/video';
import { useProgram } from 'contexts/program';
import { useDesign } from 'contexts/design';
import { isUrlValid as validateUrl } from 'utility/text';
import * as Text from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components/Box';

const ERROR_MESSAGE = 'URL is invalid';

export const UrlInput: React.FC<{
  onChange: (url: string) => void;
  onCancel: () => void;
  autoplay?: boolean;
}> = ({ onChange, onCancel, autoplay }) => {
  const [url, setUrl] = React.useState('');
  const [isUrlTouched, setIsUrlTouched] = React.useState(false);
  const [isUrlVerified, setIsUrlVerified] = React.useState(false);
  const [isUrlValid, setIsUrlValid] = React.useState(false);
  const debouncedUrl = useDebounce(url, 600);

  React.useEffect(() => {
    if (!isUrlTouched) {
      return;
    }

    setIsUrlValid(validateUrl(debouncedUrl));
    setIsUrlVerified(true);
  }, [debouncedUrl, isUrlTouched]);

  const { id: programId } = useProgram();
  const { active: isDesignAsset, parentType } = useDesign();

  const { error, isLoading } = useQuery<Video, Error>(
    [programId, debouncedUrl, autoplay, isDesignAsset],
    () =>
      findOrCreateExternalVideo(
        programId,
        debouncedUrl,
        autoplay,
        isDesignAsset,
        parentType
      ),
    {
      enabled: isUrlValid,
      refetchOnWindowFocus: false,
      retry: false, // unncessary retries delay error from being shown that may confuse users
    }
  );

  const onInputChange = React.useCallback(
    (value) => {
      if (!isUrlTouched) setIsUrlTouched(true);
      setIsUrlVerified(false);
      setUrl(value);
    },
    [isUrlTouched]
  );
  const apply = React.useCallback(() => {
    if (debouncedUrl) onChange(debouncedUrl);
  }, [debouncedUrl, onChange]);

  const hasError = !!(
    debouncedUrl &&
    isUrlVerified &&
    (!isUrlValid || error?.message)
  );
  return (
    <FormModal
      entityText="url"
      actionText="add"
      onCancel={onCancel}
      onSubmit={apply}
      submitLabel="Add"
      disabled={!isUrlVerified || hasError || isLoading}
    >
      <FieldInput htmlFor="block-field-image-url">
        <Input
          data-test="external-image"
          id="block-field-image-url"
          value={url}
          placeholder="https://"
          onChange={onInputChange}
          hasError={hasError}
          block
        />
        <Box minHeight={22}>
          <Text.Caption block color={Text.color.redFull}>
            {hasError ? error?.message || ERROR_MESSAGE : undefined}
          </Text.Caption>
        </Box>
      </FieldInput>
    </FormModal>
  );
};
