import React, { FC } from 'react';
import cx from 'classnames';
import { RouteComponentProps } from '@reach/router';
import MainContainer from '../common/MainContainer';
import PageHeading from '../common/PageHeading';
import styles from './styles.module.css';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ReviewTopicSettings } from './ReviewTopicSettings';
import { ReviewTopicLandingPageContent } from './ReviewTopicLandingPageContent';
import { ReviewTopicWarnings } from './ReviewTopicWarnings';
import { useTopicFormCtx } from '../../context';
import { DraftFooter } from './DraftFooter';
import { TopicErrorsAreAlwaysAppliable } from '../../../topicErrorHooks';

export const ReviewView: FC<RouteComponentProps> = () => {
  const { topic } = useTopicFormCtx();

  return (
    <MainContainer className={styles.Container}>
      <Header />
      <div className={styles.Content}>
        <PageHeading>Review</PageHeading>
        <div className={cx(styles.VerticallySpaced, styles.Gap32)}>
          <ReviewTopicSettings />
          <ReviewTopicWarnings />
          <ReviewTopicLandingPageContent />
        </div>
      </div>
      {topic.draft && (
        /* We want to publish or attempt to publish on what the errors *really* are, rather than those 
          displayed by to the user, so switch off only considering errors 'applicable' after a publish attempt
          as it isn't applicable to making the above decision */
        <TopicErrorsAreAlwaysAppliable>
          <DraftFooter />
        </TopicErrorsAreAlwaysAppliable>
      )}
      {!topic.draft && <Footer />}
    </MainContainer>
  );
};
