import React from 'react';
import { Modal } from 'DesignSystem/Components';
import { SectionCard } from 'App/Program/Editors/Publisher/NewReview/SectionCard';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { useUniqueId } from 'hooks/useUniqueId';
import { titleCase } from 'utility/text';

export const FormModalSectioned: React.FC<{
  children: React.ReactNode;
  entityText: string;
  actionText: string;
  onSubmit(): void;
  onCancel(): void;
  width?: string | number;
  submitLabel?: string;
  innerPadding?: number;
  disabled?: boolean;
}> = ({
  entityText,
  actionText,
  width,
  children,
  onCancel,
  disabled,
  onSubmit,
  submitLabel,
}) => {
  const containerId = useUniqueId();
  const cancelId = useUniqueId();
  const [promptForCancel, setPromptForCancel] = React.useState(false);
  const callToActionLabel = React.useMemo(
    () => submitLabel || titleCase(`${actionText} ${entityText}`),
    [actionText, entityText, submitLabel]
  );

  return (
    <Modal
      innerPadding={0}
      width={width}
      name={`${actionText}-${entityText}`}
      modalBoxOverflow="auto"
      modalFlexOverflow="visible"
      modalFlexHeight="none"
      onClick={(e) => {
        const contained = document
          .getElementById(containerId)
          ?.contains(e.target as HTMLElement);
        if (!contained && !promptForCancel) {
          setPromptForCancel(true);
          setTimeout(() => {
            document.getElementById(cancelId)?.focus();
          }, 10);
        } else if (contained && promptForCancel) {
          setPromptForCancel(false);
        }
      }}
    >
      <div id={containerId}>
        <SectionCard borderless padding={24}>
          <Flex spread>
            <Text.Heading bold>
              {titleCase(`${actionText} ${entityText}`)}
            </Text.Heading>
            <Button
              onClick={onCancel}
              clearText
              label={<Text.Heading>×</Text.Heading>}
            />
          </Flex>
          <>{children}</>
          <Flex end>
            <Button
              focused={promptForCancel}
              secondary
              id={cancelId}
              onClick={onCancel}
              label="Cancel"
            />
            <Space row size={15} />
            <Button
              disabled={disabled}
              onClick={onSubmit}
              label={callToActionLabel}
            />
          </Flex>
        </SectionCard>
      </div>
    </Modal>
  );
};
