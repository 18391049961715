import React, { useEffect, useMemo, useRef } from 'react';
import {
  arc,
  AxisScale,
  DefaultArcObject,
  interpolateSpectral,
  pie,
  quantize,
  ScaleOrdinal,
  scaleOrdinal,
  select,
  transition,
} from 'd3';
import { ChartDimensions, DEFAULT_ANIMATION_TIME } from './Util';
import { NoDataChart } from './NoDataChart';

export type PieChartData = {
  label: string;
  value: number;
};

export type PieChartDimensions = ChartDimensions & {
  pie: {
    height: number;
    width: number;
    padding: {
      top: number;
      bottom: number;
      left: number;
      right: number;
    };
    gap: number;
  };
};

export type CustomDrawingFunction = (
  data: Array<PieChartData>,
  chartDimensions: PieChartDimensions,
  scaleX?: AxisScale<string> | AxisScale<number>,
  scaleY?: AxisScale<string> | AxisScale<number>,
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>
) => React.ReactFragment;

interface IPieChart {
  showLabels?: boolean;
  data: Array<Partial<PieChartData>>;
  customColors?: ScaleOrdinal<string, unknown>;
  customDrawing?: CustomDrawingFunction;
  chartDimensions?: PieChartDimensions;
}

const isValidPieChartData = (d: unknown): d is PieChartData => {
  return (
    Object.prototype.hasOwnProperty.call(d, 'label') &&
    Object.prototype.hasOwnProperty.call(d, 'value') &&
    (d as PieChartData).value !== undefined &&
    (d as PieChartData).value !== 0
  );
};

export const PieChart: React.FC<IPieChart> = ({
  showLabels = true,
  data,
  customColors,
  customDrawing,
  chartDimensions = {
    height: 480,
    width: 640,
    padding: {
      left: 40,
      right: 40,
      top: 40,
      bottom: 40,
    },
    pie: {
      height: 480,
      width: 480,
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      gap: 0,
    },
  },
}) => {
  const { height, width, pie: pieDimensions } = chartDimensions;
  const svgRef = useRef<SVGSVGElement>(null);

  const filteredData: Array<PieChartData> = useMemo(
    (): Array<PieChartData> => data.filter(isValidPieChartData),
    [data]
  );
  const total = filteredData.reduce(
    (sum, chartData) => sum + chartData.value,
    0
  );

  const radius =
    Math.min(
      pieDimensions.width -
        pieDimensions.padding.left -
        pieDimensions.padding.right,
      pieDimensions.height -
        pieDimensions.padding.top -
        pieDimensions.padding.bottom
    ) / 2;

  const drawPie = pie<PieChartData>().value((d) => d.value);
  const drawArc = arc().padAngle(pieDimensions.gap);

  const colors =
    customColors ??
    scaleOrdinal()
      .domain(filteredData.map((d) => d.label))
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          filteredData.length
        ).reverse()
      );

  const drawCustom = useMemo(() => {
    return customDrawing?.(filteredData, chartDimensions) ?? <></>;
  }, [chartDimensions, customDrawing, filteredData]);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = select(svgRef.current);
    const pieGroup = svg.select('.pie-group');

    const arcTween = (d: DefaultArcObject) => {
      return (t: number) => {
        const sliceInfo = {
          innerRadius: radius * 0.7,
          outerRadius: radius,
          startAngle: d.startAngle,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) * t,
        };
        return drawArc(sliceInfo) || '';
      };
    };

    const pieData = drawPie(filteredData);

    // Animate pie slices
    pieGroup
      .selectAll('.slice')
      .data(pieData)
      .join((enter) =>
        enter
          .append('path')
          .attr('class', 'slice')
          .attr('fill', (d, i) => colors(i as never) as string)
          .attr(
            'd',
            (d) => drawArc({ ...d, endAngle: d.startAngle } as never) as string
          )
          .call((innerEnter) =>
            innerEnter
              .transition(transition().duration(DEFAULT_ANIMATION_TIME))
              .attrTween('d', (d) => arcTween(d as never))
          )
      );

    // Add and animate centroids
    pieGroup
      .selectAll('.centroid')
      .data(pieData)
      .join((enter) =>
        enter
          .append('circle')
          .attr('class', 'centroid')
          .attr('r', 2)
          .attr('fill', 'black')
          .attr('opacity', 0)
          .attr('transform', (d) => {
            const centroid = drawArc.centroid({
              ...d,
              innerRadius: radius * 0.7,
              outerRadius: radius,
            });
            return `translate(${centroid})`;
          })
          .call((innerEnter) =>
            innerEnter
              .transition(
                transition()
                  .duration(DEFAULT_ANIMATION_TIME)
                  .delay(DEFAULT_ANIMATION_TIME)
              )
              .attr('opacity', 1)
          )
      );

    // Animate labels
    if (showLabels) {
      const labels: Array<{ [key: string]: string | number }> = [];
      pieGroup
        .selectAll('.label-group')
        .data(pieData)
        .join((enter) =>
          enter
            .append('g')
            .attr('class', 'label-group')
            .attr('opacity', 0)
            .call((innerEnter) =>
              innerEnter
                .transition(
                  transition()
                    .duration(DEFAULT_ANIMATION_TIME)
                    .delay(DEFAULT_ANIMATION_TIME)
                )
                .attr('opacity', 1)
            )
            .call((g) => {
              g.append('line')
                .attr('class', 'label-line1')
                .attr('stroke', 'black');
              g.append('line')
                .attr('class', 'label-line2')
                .attr('stroke', 'black');
              g.append('text')
                .attr('class', 'label-text')
                .attr('fill', 'black')
                .attr('font-size', 12);
            })
        )
        // disable func-names to enable use of `this`
        // eslint-disable-next-line func-names
        .each(function (d) {
          const { label: stringLabel } = d.data;
          let label = [
            `${Number(d.value / total).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 2,
            })} ${stringLabel}`,
          ];
          const group = select(this);
          const sliceInfo = {
            innerRadius: radius * 0.7,
            outerRadius: radius,
            startAngle: d.startAngle,
            endAngle: d.endAngle,
          };
          const centroid = drawArc.centroid(sliceInfo);

          const inflexionInfo = {
            innerRadius: radius + 20,
            outerRadius: radius + 20,
            startAngle: d.startAngle,
            endAngle: d.endAngle,
          };

          // Point outside the circle
          const inflexionPoint = drawArc.centroid(inflexionInfo);
          let isRightLabel = inflexionPoint[0] > 0;
          const approximateWidth = label.join(' ').length * 7;
          const approximateHeight = 16;

          const LABEL_PADDING = 15;
          let labelPosX =
            inflexionPoint[0] + LABEL_PADDING * (isRightLabel ? 1 : -1);

          let newInflexionPoint = { ...inflexionPoint };
          if (labels.length !== 0) {
            // Collision detection
            const collisions: Array<{ [key: string]: number | string }> = [];
            labels.forEach((l) => {
              const x1 = isRightLabel
                ? labelPosX
                : labelPosX - approximateWidth;
              const x2 = isRightLabel
                ? labelPosX + approximateWidth
                : labelPosX;
              const y1 = inflexionPoint[1];
              const y2 = inflexionPoint[1] + approximateHeight;

              const checks = {
                x: l.x2 < x1 || l.x1 > x2,
                y: l.y1 > y2 || l.y2 < y1,
              };

              if (!(checks.x || checks.y)) {
                collisions.push(l);
              }
            });
            const DEFAULT_ROTATION = 0.0174533; // Degrees to radians
            let push = DEFAULT_ROTATION;
            let finished = collisions.length === 0;
            const newInflexionInfo = { ...inflexionInfo };

            while (!finished && push <= 6.28319) {
              newInflexionInfo.startAngle =
                (inflexionInfo.startAngle + push) % 6.28319;
              newInflexionInfo.endAngle =
                (inflexionInfo.endAngle + push) % 6.28319;
              if (newInflexionInfo.endAngle < newInflexionInfo.startAngle) {
                newInflexionInfo.startAngle = 0;
              }

              newInflexionPoint = drawArc.centroid(newInflexionInfo);

              isRightLabel = newInflexionPoint[0] > 0;
              labelPosX =
                newInflexionPoint[0] + LABEL_PADDING * (isRightLabel ? 1 : -1);

              // eslint-disable-next-line no-loop-func
              const stillColliding = collisions.find((l) => {
                const x1 = isRightLabel
                  ? labelPosX
                  : labelPosX - approximateWidth;
                const x2 = isRightLabel
                  ? labelPosX + approximateWidth
                  : labelPosX;
                const y1 = newInflexionPoint[1];
                const y2 = newInflexionPoint[1] + approximateHeight;

                const checks = {
                  x: l.x2 < x1 || l.x1 > x2,
                  y: l.y1 > y2 || l.y2 < y1,
                };
                return !(checks.x || checks.y);
              });
              if (stillColliding) {
                push += DEFAULT_ROTATION;
              } else {
                finished = true;
              }
            }
          }

          labels.push({
            x1: isRightLabel ? labelPosX : labelPosX - approximateWidth,
            y1: newInflexionPoint[1],
            x2: isRightLabel ? labelPosX + approximateWidth : labelPosX,
            y2: newInflexionPoint[1] + approximateHeight,
          });

          const measureX = labelPosX + width / 2;
          if (approximateWidth > (isRightLabel ? width - measureX : measureX)) {
            // Split or truncate the label

            const strings = label.join(' ').split(' ');
            let tmpString: Array<string> = [];
            const tmpLabel: Array<string> = [];
            let index = 0;
            while (index < strings.length) {
              tmpString.push(strings[index]);
              const tmpWidth = tmpString.join(' ').length * 7;

              if (tmpWidth > (isRightLabel ? width - measureX : measureX)) {
                const next = tmpString.pop();
                tmpLabel.push(tmpString.join(' '));
                tmpString = next === undefined ? [] : [next];
              }

              index += 1;
            }
            tmpLabel.push(tmpString.join(' '));

            label = tmpLabel;
          }

          const textAnchor = isRightLabel ? 'start' : 'end';

          group
            .select('.label-line1')
            .attr('x1', centroid[0])
            .attr('y1', centroid[1])
            .attr('x2', inflexionPoint[0])
            .attr('y2', inflexionPoint[1]);

          group
            .select('.label-line2')
            .attr('x1', inflexionPoint[0])
            .attr('y1', inflexionPoint[1])
            .attr('x2', labelPosX)
            .attr('y2', inflexionPoint[1]);

          group
            .select('.label-text')
            .attr('x', labelPosX + (isRightLabel ? 2 : -2))
            .attr('y', inflexionPoint[1])
            .attr('text-anchor', textAnchor)
            .attr('dominant-baseline', 'middle')
            .call((g) => {
              label.forEach((l, i) => {
                g.append('tspan')
                  .attr('x', labelPosX + (isRightLabel ? 2 : -2))
                  .attr('dy', `${i * 1.2}em`)
                  .text(l);
              });
            });
        });
    }
  }, [
    filteredData,
    radius,
    colors,
    drawArc,
    drawPie,
    total,
    showLabels,
    width,
    height,
  ]);

  return filteredData.length === 0 ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
      <g
        className="pie-group"
        transform={`translate(${width / 2}, ${height / 2})`}
      />
      <tspan />
      {drawCustom}
    </svg>
  );
};
