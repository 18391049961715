import { Section } from 'DesignSystem/Form';
import { pluralize } from 'utility/text';
import React, { FC } from 'react';
import { useTopicFormCtx } from '../../context';
import { AudienceCount } from './AudienceCount';
import styles from './review-topic-settings.module.css';

export const ReviewTopicSettings: FC = () => {
  const { baseUrl, topic, state } = useTopicFormCtx();

  const topicIsVisibleToAllAudiences = state.fields.targeted === false;
  const topicIsAutoFollowedByAllAudiences =
    state.fields.autoFollowCriterion === false;

  return (
    <Section title="Settings" href={`${baseUrl}/settings`}>
      <div className={styles.Container}>
        <ul className={styles.TopicSettingsList}>
          <li>
            Will <strong>{state.fields.published || 'not'} show</strong> in
            experience
          </li>
          <Visible when={state.fields.published}>
            <li>
              Will{' '}
              <strong>{state.fields.isPromoted || 'not'} be suggested</strong>{' '}
              as a recommended topic
            </li>
          </Visible>
          <Visible when={!topicIsVisibleToAllAudiences}>
            <li>
              Can be viewed by{' '}
              <strong>
                <AudienceCount
                  audiences={state.fields.audience}
                  programId={topic.programId}
                  suffix="user"
                />
              </strong>{' '}
              across {state.fields.audience.length}{' '}
              {pluralize(state.fields.audience.length, 'audience')}
            </li>
          </Visible>
          <Visible when={topicIsVisibleToAllAudiences}>
            <li>
              Can be viewed by <strong>all users</strong> across all audiences
            </li>
          </Visible>
          <li>
            Auto-follow is{' '}
            <strong>{state.fields.autoFollow ? 'enabled' : 'disabled'}</strong>
          </li>
          <Visible when={state.fields.autoFollow}>
            <Visible when={!topicIsAutoFollowedByAllAudiences}>
              <li>
                Will auto-follow{' '}
                <strong>
                  <AudienceCount
                    audiences={state.fields.autoFollowAudience}
                    programId={topic.programId}
                    suffix="user"
                  />
                </strong>{' '}
                across {state.fields.autoFollowAudience.length}{' '}
                {pluralize(state.fields.autoFollowAudience.length, 'audience')}
              </li>
            </Visible>
            <Visible when={topicIsAutoFollowedByAllAudiences}>
              <li>
                Will auto-follow <strong>entire</strong> selected audience
              </li>
            </Visible>
          </Visible>
          <li>
            Will{' '}
            <strong>
              {state.fields.isUserSubmittable || 'not'} allow members
            </strong>{' '}
            to publish
          </li>
          <Visible when={state.fields.isUserSubmittable}>
            <li>
              Will{' '}
              <strong>{state.fields.autoPublish || 'not'} auto-publish</strong>{' '}
              content from members
            </li>
          </Visible>
        </ul>
      </div>
    </Section>
  );
};

const Visible: FC<{
  when: boolean;
  children: React.ReactNode;
}> = ({ when, children }: { when: boolean; children: React.ReactNode }) => {
  return when ? <>{children}</> : null;
};
