import * as React from 'react';
import { CSSProperties } from 'react';
import cx from 'classnames';
import boxIconBlack from 'assets/images/box_black.png';
import boxIconBlue from 'assets/images/box_blue.png';

export const BoxIcon: React.FC<{
  color?: 'black' | 'blue';
  className?: string;
  style?: CSSProperties;
}> = ({ color, className, style }) => (
  <span
    className={cx(className)}
    style={{
      display: 'inline-block',
      height: '24px',
      width: '24px',
      backgroundImage: `url(${color === 'black' ? boxIconBlack : boxIconBlue})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      ...(style || {}),
    }}
  />
);
