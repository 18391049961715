import * as React from 'react';
import * as Dnd from 'react-beautiful-dnd';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useLibrary } from 'hooks/useLibrary';

export const DragDropContext: React.FC = ({ children }) => {
  const library = useLibrary();
  const editor = React.useContext(BlocksEditorContext);

  const handleInsert = React.useCallback(
    ({ source, destination, draggableId }: Dnd.DropResult) => {
      if (source && destination) {
        if (
          source.droppableId !== destination.droppableId &&
          destination.droppableId === editor.droppableId
        ) {
          const blockName = draggableId.replace(/^(library|basic)-block-/, '');
          if (library[blockName]) {
            editor.insert(library[blockName].asset.blocks, destination.index);
          }
        }
      }
    },
    [editor, library]
  );

  const handleMove = React.useCallback(
    ({ source, destination }: Dnd.DropResult) => {
      if (source && destination) {
        if (
          source.droppableId === editor.droppableId &&
          destination.droppableId === editor.droppableId
        ) {
          editor.move(source.index, destination.index);
        }
      }
    },
    [editor]
  );

  const onDragEnd = React.useCallback(
    (result: Dnd.DropResult) => {
      handleInsert(result);
      handleMove(result);
    },
    [handleInsert, handleMove]
  );

  return (
    <Dnd.DragDropContext onDragEnd={onDragEnd}>{children}</Dnd.DragDropContext>
  );
};
