import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import {
  retriesDisabled,
  hasAudience,
  hasRetargettingNotif,
  hasTopic,
} from 'models/publisher/settings';
import { useCallback } from 'react';
import { useHasChanged } from './useChanged';

export const useSettingsChanges = (): {
  topicOrAudienceChanged: () => boolean;
  retargettingNotifOrRetriesChanged: () => boolean;
} => {
  const { settings } = useSettings();

  const withAudience = hasAudience(settings);
  const withTopic = hasTopic(settings);
  const withRetargettingNotif = hasRetargettingNotif(settings);
  const noRetries = retriesDisabled(settings);

  const audiencesChanged = useHasChanged(withAudience);
  const topicsChanged = useHasChanged(withTopic);

  const retargettingNotifsChanged = useHasChanged(withRetargettingNotif);
  const noRetriesChanged = useHasChanged(noRetries);

  const topicOrAudienceChanged = useCallback(() => {
    return topicsChanged || audiencesChanged;
  }, [topicsChanged, audiencesChanged]);

  const retargettingNotifOrRetriesChanged = useCallback(() => {
    return retargettingNotifsChanged || noRetriesChanged;
  }, [retargettingNotifsChanged, noRetriesChanged]);

  return { topicOrAudienceChanged, retargettingNotifOrRetriesChanged };
};
