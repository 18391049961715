import React, { useEffect, useState, useRef } from 'react';
import { Keys, useKeyboardTrigger } from 'hooks/useKeyboardTrigger';

export type DismissType = () => void;
export type DropdownRenderPropType =
  | React.ReactNode
  | ((dismiss: DismissType) => React.ReactNode);

// This component is meant to be "subclassed", so to speak.  There are concrete
// derived components that wrap this one and supply specialized dropdown contents.
// In all those cases, it needs to expose these props and pass them through to this
// parent so the common focus behavior can be provided.
export type PassThroughPropsType = {
  dropdownClassName?: string;
  id?: string;
  disabled?: boolean;
  keyPressActivated?: boolean;
  isOpen?: boolean;
  isFocused?: boolean;
  onOpen?: (id?: string) => void;
  onClose?: (id?: string) => void;
  onDropdownClick?: () => void;
  upward?: boolean;
};

// This also takes a render prop for children so that the onFocus event handler can be
// targeted to just the part of the children that should trigger opening the dropdown.
// For example, the MultiValueTextInput is a complex input component that has clickable
// buttons inside it (the remove "x"'s) that should _not_ cause the dropdown to open when
// they gain the focus or are clicked.  By passing down onFocus to a render prop, the
// focus can be, er, focused.
type PropsType = {
  dropdownRenderProp: DropdownRenderPropType;
  children: (
    onFocus: () => void,
    ref: React.RefObject<HTMLInputElement>
  ) => React.ReactNode;
} & PassThroughPropsType;

export const FocusDropdown: React.FC<PropsType> = (props) => {
  const {
    dropdownClassName = 'dropdown-align-left',
    dropdownRenderProp,
    id,
    isOpen,
    // isFocused,
    disabled,
    keyPressActivated,
    onOpen,
    onClose,
    onDropdownClick,
    children,
    upward = false,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const focusRef = useRef<HTMLInputElement>(null);

  const [isDropdownOpen, setDropdownOpen] = useState(isOpen);
  const [previousIsOpenProp, setPreviousIsOpenProp] = useState(isOpen);
  const targetRef = useRef<HTMLDivElement>(null);

  function showDropdown() {
    if (disabled) {
      return;
    }
    if (!isDropdownOpen && onOpen) {
      onOpen(id);
    }
    setDropdownOpen(true);
  }

  function hideDropdown() {
    if (onClose) {
      onClose(id);
    }
    setDropdownOpen(false);
  }

  function hideAndMaintainFocus() {
    focusRef?.current?.focus();
    setDropdownOpen(false);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function catchOuterClick(e: any) {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      hideDropdown();
    }
  }

  useEffect(() => {
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    document.addEventListener('mousedown', catchOuterClick);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', catchOuterClick);
    };
  });

  function handleDropdownClick() {
    if (onDropdownClick) {
      onDropdownClick();
    }
  }

  function keyTriggeredShowDropdown() {
    if (keyPressActivated && !isDropdownOpen) {
      showDropdown();
    }
  }

  function keyTriggeredHideDropdown() {
    if (keyPressActivated && isDropdownOpen) {
      hideDropdown();
    }
  }

  useKeyboardTrigger({
    ref: focusRef,
    onIncludedKey: keyTriggeredHideDropdown,
    keys: [Keys.Esc],
    onAnyOtherKey: keyTriggeredShowDropdown,
  });

  if (isOpen !== previousIsOpenProp) {
    setPreviousIsOpenProp(isOpen);
    setDropdownOpen(isOpen);
  }

  const dropdownStyle: React.CSSProperties = {
    position: 'absolute',
    margin: 0,
    zIndex: 1000,
  };

  if (upward) {
    dropdownStyle.bottom = targetRef.current?.clientHeight;
  }
  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      ref={wrapperRef}
      id={id}
    >
      <div className="focus-dropdown-target" ref={targetRef}>
        {children(showDropdown, focusRef)}
      </div>
      {isDropdownOpen && (
        <div
          onClick={handleDropdownClick}
          style={dropdownStyle}
          className={dropdownClassName}
        >
          {typeof dropdownRenderProp === 'function'
            ? dropdownRenderProp(hideAndMaintainFocus)
            : dropdownRenderProp}
        </div>
      )}
    </div>
  );
};
