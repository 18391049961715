import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';

export type CampaignEvent = {
  verb: string;
  objectType: string;
  complementType?: string;
};

export type FetchProps = {
  programId: number;
  onError?: (error: Error) => void;
};

export async function fetchSupportedCampaignEvents(
  programId: number
): Promise<Array<CampaignEvent>> {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/events/supported`
  );
  if (response.status === 200) {
    return response.json().then((body) => deepCamelcaseKeys(body));
  }

  throw new Error(`Error fetching authors: ${response.status}`);
}
