import * as React from 'react';
import { Label } from 'shared/Label';
import { MeasureType } from 'shared/publisher/types';
import { Block, Row, Section } from 'shared/SectionBlocks';
import styles from './platform.module.css';

const Info: React.FC<MeasureType> = ({ name, value }) => (
  <Block className={styles.block}>
    <Label containerClassName={styles.label} name={name}>
      {value}
    </Label>
  </Block>
);

export const Platform: React.FC<{ platforms: Array<MeasureType> }> = ({
  platforms,
}) => (
  <Section title="Platform">
    <Row>
      {platforms.map((platform) => (
        <Info key={platform.name} name={platform.name} value={platform.value} />
      ))}
    </Row>
  </Section>
);
