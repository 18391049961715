import * as React from 'react';
import { Layout, Alignment } from 'models/publisher/format';
import { deepMerge } from 'utility/deep-merge';
import styles from './arrangement.module.css';

function Rect({
  width,
  height,
  className,
}: {
  width: string;
  height: string;
  className: string;
}) {
  return <div className={className} style={{ height, width }} />;
}

function Picture({ dim }: { dim: string }) {
  const [w, h] = `${dim}x${dim}`.split('x');
  return (
    <Rect
      className={styles['util-picture']}
      width={`${w}px`}
      height={`${h}px`}
    />
  );
}

function Blurb({ width }: { width: number }) {
  return (
    <div style={{ opacity: 0.7 }}>
      <Rect className={styles['util-text']} width={`${width}px`} height="6px" />
      <Rect className={styles['util-text']} width={`${width}px`} height="4px" />
    </div>
  );
}

function Offset({ width }: { width: number }) {
  return <div style={{ width }} />;
}

type Icon = React.FC | undefined;
type AlignmentIcons = Record<Alignment, Icon>;
type ArrangementIcons = Record<Layout, AlignmentIcons>;
type ArrangementIconsPartial = Partial<Record<Layout, Partial<AlignmentIcons>>>;

const emptySet: ArrangementIcons = {
  [Layout.FullWidth]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.OneByFive]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.TwoByFour]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.ThreeByThree]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.ThreeByThreeGrid]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.ThreeByThreeList]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.TwoByTwoByTwo]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.FourByTwo]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.Gallery]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
  [Layout.FullBleed]: {
    [Alignment.ImageLeft]: undefined,
    [Alignment.ImageRight]: undefined,
  },
};

const pictureBlurbs: ArrangementIconsPartial = {
  [Layout.FullWidth]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <Picture dim="150x100" />
        <Blurb width={150} />
      </div>
    ),
  },
  [Layout.OneByFive]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="25" />
          <Blurb width={110} />
        </div>
        <div className="kai-flex-row">
          <Picture dim="25" />
          <Blurb width={110} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={110} />
          <Picture dim="25" />
        </div>
        <div className="kai-flex-row">
          <Blurb width={110} />
          <Picture dim="25" />
        </div>
      </>
    ),
  },
  [Layout.TwoByFour]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="40" />
          <Blurb width={90} />
        </div>
        <div className="kai-flex-row">
          <Picture dim="40" />
          <Blurb width={90} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={90} />
          <Picture dim="40" />
        </div>
        <div className="kai-flex-row">
          <Blurb width={90} />
          <Picture dim="40" />
        </div>
      </>
    ),
  },
  [Layout.TwoByTwoByTwo]: {
    [Alignment.ImageLeft]: () => (
      <div className="kai-flex-row">
        <div>
          <Picture dim="40x30" />
          <Blurb width={40} />
        </div>
        <div>
          <Picture dim="40x30" />
          <Blurb width={40} />
        </div>
        <div>
          <Picture dim="40x30" />
          <Blurb width={40} />
        </div>
      </div>
    ),
  },
  [Layout.ThreeByThreeList]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="70x50" />
          <Blurb width={70} />
        </div>
        <div className="kai-flex-row">
          <Picture dim="70x50" />
          <Blurb width={70} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={70} />
          <Picture dim="70x50" />
        </div>
        <div className="kai-flex-row">
          <Blurb width={70} />
          <Picture dim="70x50" />
        </div>
      </>
    ),
  },
  [Layout.ThreeByThreeGrid]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <div className="kai-flex-row">
          <div>
            <Picture dim="70x45" />
            <Blurb width={70} />
          </div>
          <div>
            <Picture dim="70x45" />
            <Blurb width={70} />
          </div>
        </div>
        <div className="kai-flex-row">
          <div>
            <Picture dim="70x45" />
            <Blurb width={70} />
          </div>
          <div>
            <Picture dim="70x45" />
            <Blurb width={70} />
          </div>
        </div>
      </div>
    ),
  },
};

const singlePicture: ArrangementIconsPartial = {
  [Layout.FullWidth]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Offset width={20} />
          <Picture dim="120x70" />
        </div>
      </>
    ),
  },
  [Layout.ThreeByThree]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="70x50" />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Offset width={80} />
          <Picture dim="70x50" />
        </div>
      </>
    ),
  },
  [Layout.FullBleed]: {
    [Alignment.ImageLeft]: () => <Picture dim="150x70" />,
  },
};

const pictures: ArrangementIconsPartial = {
  [Layout.Gallery]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <Picture dim="150x100" />
        <div className="kai-flex-row">
          <Picture dim="25" />
          <Picture dim="25" />
          <Picture dim="25" />
          <Picture dim="25" />
        </div>
      </div>
    ),
  },
  [Layout.FullWidth]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <Picture dim="150x100" />
        <div className={styles['util-fade']} />
      </div>
    ),
  },
  [Layout.TwoByTwoByTwo]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <div className="kai-flex-row">
          <Picture dim="40x30" />
          <Picture dim="40x30" />
          <Picture dim="40x30" />
        </div>
        <div className="kai-flex-row">
          <Picture dim="40x30" />
          <Picture dim="40x30" />
          <Picture dim="40x30" />
        </div>
        <div className="kai-flex-row">
          <Picture dim="40x30" />
          <Picture dim="40x30" />
          <Picture dim="40x30" />
        </div>
      </div>
    ),
  },
  [Layout.ThreeByThree]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <div className="kai-flex-row">
          <Picture dim="70x50" />
          <Picture dim="70x50" />
        </div>
        <div className="kai-flex-row">
          <Picture dim="70x50" />
          <Picture dim="70x50" />
        </div>
      </div>
    ),
  },
};

const imageHeadlineBlurbs: ArrangementIconsPartial = {
  [Layout.FullWidth]: {
    [Alignment.ImageLeft]: () => (
      <div>
        <Picture dim="150x100" />
        <Blurb width={150} />
      </div>
    ),
  },
  [Layout.OneByFive]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="25" />
          <Blurb width={110} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={110} />
          <Picture dim="25" />
        </div>
      </>
    ),
  },
  [Layout.TwoByFour]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="40" />
          <Blurb width={90} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={90} />
          <Picture dim="40" />
        </div>
      </>
    ),
  },
  [Layout.FourByTwo]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="90" />
          <Blurb width={40} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={40} />
          <Picture dim="90" />
        </div>
      </>
    ),
  },
  [Layout.ThreeByThree]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="65" />
          <Blurb width={65} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={65} />
          <Picture dim="65" />
        </div>
      </>
    ),
  },
};

const videoBlurbs: ArrangementIconsPartial = {
  [Layout.FullWidth]: {
    [Alignment.ImageRight]: () => (
      <div>
        <Blurb width={150} />
        <Picture dim="150x100" />
      </div>
    ),
  },
  [Layout.TwoByFour]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="100x67" />
          <Blurb width={50} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={50} />
          <Picture dim="100x67" />
        </div>
      </>
    ),
  },
  [Layout.ThreeByThree]: {
    [Alignment.ImageLeft]: () => (
      <>
        <div className="kai-flex-row">
          <Picture dim="75x50" />
          <Blurb width={75} />
        </div>
      </>
    ),
    [Alignment.ImageRight]: () => (
      <>
        <div className="kai-flex-row">
          <Blurb width={75} />
          <Picture dim="75x50" />
        </div>
      </>
    ),
  },
};

export const BlockIcons: Record<string, typeof emptySet> = {
  single_image: deepMerge(emptySet, singlePicture as Partial<ArrangementIcons>),
  images: deepMerge(emptySet, pictures as Partial<ArrangementIcons>),
  image_headlines: deepMerge(
    emptySet,
    imageHeadlineBlurbs as Partial<ArrangementIcons>
  ),
  author_image_header: deepMerge(
    emptySet,
    imageHeadlineBlurbs as Partial<ArrangementIcons>
  ),
  links: deepMerge(emptySet, pictureBlurbs as Partial<ArrangementIcons>),
  video: deepMerge(emptySet, videoBlurbs as Partial<ArrangementIcons>),
};
