import React, { FC } from 'react';
import {
  Avatar,
  IconButton,
  Icon,
  Button,
} from '@socialchorus/shared-ui-components';
import { Sortable } from 'DesignSystem/Components';
import styles from './styles.module.css';

export type SortableItem = {
  id: string;
  name: string;
  imgSrc?: string;
  description?: string;
};

export type SortableListProps = {
  items: SortableItem[];
  onEdit?: (item: SortableItem) => void;
  onDelete: (item: SortableItem) => void;
  onSort: (items: SortableItem[]) => void;
};

export const SortableList: FC<SortableListProps> = ({
  items,
  onEdit,
  onDelete,
  onSort,
}) => {
  return (
    <Sortable
      items={items}
      idAttr="id"
      Item={({ item }) => (
        <div className={styles.SortableItem}>
          <Icon>drag_indicator</Icon>
          <Avatar shape="square" size="medium" imgSrc={item.imgSrc} />
          <div className={styles.Texts}>
            <span className={styles.Title}>{item.name}</span>
            <span className={styles.Description}>{item.description}</span>
          </div>
          {onEdit && (
            <IconButton
              className={styles.ActionButton}
              size="compact"
              aria-label={`Edit ${item.name}`}
              iconName="settings"
              onClick={() => onEdit(item)}
            />
          )}
          <IconButton
            className={styles.ActionButton}
            size="compact"
            aria-label={`Delete ${item.name}`}
            iconName="delete"
            onClick={() => onDelete(item)}
          />
        </div>
      )}
      onSort={onSort}
    />
  );
};

interface ItemAddButtonProps {
  label: string;
  onClick: () => void;
}

export const ItemAddButton: FC<ItemAddButtonProps> = ({ label, onClick }) => (
  <Button
    variant="primary"
    fullWidth
    className={styles.ItemAddButton}
    leftIcon={<Icon>add</Icon>}
    label={label}
    onClick={onClick}
  />
);
