import { useMemo } from 'react';
import { useCurrentUserQuery } from './user';

export const useAuthCheck = (
  refetchInterval: number | false = 300000 // default to 5 minutes
): {
  isAuthenticated: boolean;
} => {
  const { isLoading, error, data: authData } = useCurrentUserQuery({
    refetchInterval,
    soft: true,
  });

  const isAuthenticated = useMemo(() => {
    if (error) return false;
    if (!authData && !isLoading) return false;

    return true;
  }, [error, authData, isLoading]);

  return {
    isAuthenticated,
  };
};
