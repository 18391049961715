import React from 'react';
import { Button } from 'DesignSystem/Form';
import { GoTo2 } from 'shared/icons';

type PropsType = {
  href: string;
  label: string;
  dataPendo?: string;
};

export const MetricsButton: React.FC<PropsType> = React.memo(
  ({ href, label, dataPendo }) => (
    <Button
      compact
      secondary
      openInNewTab
      href={href}
      icon={<GoTo2 />}
      label={label}
      dataPendo={dataPendo}
    />
  )
);
