import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { ListPage } from 'DesignSystem/Layout/Pages';
import './index.css';

export const RegionDomains: React.FC<RouteComponentProps> = () => {
  const program = useProgram();

  return (
    <ListPage
      title="Domains"
      description="The Firstup platform is deployed across multiple regional environments to ensure high security and scalability along with regional production stability for our customers. Below is what is identified for this Program:"
      breadcrumbs={[{ to: '..', label: 'Configure' }, { label: 'Domains' }]}
    >
      <div className="domain-grid">
        <span>Firstup Developer API Domain: </span>
        <span>{program?.partnerApiDomain}</span>
        <span>Authentication Service Domain: </span>
        <span>{program?.authServiceDomain}</span>
      </div>
    </ListPage>
  );
};
