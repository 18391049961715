import { useFlashMessage } from 'contexts/flasher';

export function useFlashServerErrors(): (
  error: Error,
  fallbackMessage: string
) => void {
  const { setFlashMessage } = useFlashMessage();

  function validationErrorMessages(error: Error): string[] {
    try {
      const result: string[] = [];
      const errors = JSON.parse(error.message);

      if (Array.isArray(errors.errors)) return errors.errors;
      if (errors.errors) {
        Object.keys(errors.errors).forEach((key) => {
          if (Array.isArray(errors.errors[key])) {
            result.push(`${key}: ${errors.errors[key].join(' ')}`);
          } else {
            result.push(`${key}: ${errors.errors[key]}`);
          }
        });
      } else if (errors.error) {
        result.push(errors.error);
      } else {
        result.push(error.message);
      }

      return result;
    } catch (e) {
      return [];
    }
  }

  return (error: Error, fallbackMessage: string) => {
    if (error.name === 'ValidationError') {
      const messages = validationErrorMessages(error);
      setFlashMessage({
        severity: 'error',
        message: messages.length > 0 ? messages.join('\n') : fallbackMessage,
      });
      return;
    }

    setFlashMessage({
      severity: 'error',
      message: fallbackMessage,
    });
  };
}

export function raise(message: string): never {
  throw new Error(message);
}

/**
 * Ensures at a type level that all possible cases have been handled in a switch statement or similar construct.
 * This function should be used in the default case of a switch statement to enforce exhaustiveness.
 * If an unhandled case is encountered, the compiler will error
 *
 * @param defaultCase - The value that was not handled by the switch statement.
 * @throws Will always throw an error indicating the unhandled case.
 */
export function exhaustiveCheck(defaultCase: never, msg?: string): never {
  throw new Error(`
    ${
      msg ? `${msg} \n` : undefined
    } exhaustive check failed unknown case, case=${JSON.stringify(defaultCase)}
    `);
}
