import {
  BoxContentPicker,
  BoxItem,
  useResponseInterceptor,
} from '@socialchorus/box-components';
import axios, { AxiosResponse } from 'axios';
import React, { ComponentProps, useCallback } from 'react';
import { useProgram } from 'contexts/program';
import { BoxIntegrationData } from 'models/box-integration';
import { FormModal } from 'DesignSystem/Components/Modal';
import { filterBoxResponseByPermission } from 'services/api-box-mangement';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useBoxToken } from './useBoxToken';
import styles from './box.module.css';

type PickerModalProps = Pick<
  ComponentProps<typeof BoxContentPicker>,
  'type' | 'folderId'
> & {
  entityText?: string;
  description?: string;
  onSubmit: (resource: BoxIntegrationData) => void;
  onCancel: () => void;
  restrictByPermission?: boolean;
};

export const PickerModal: React.FC<PickerModalProps> = ({
  type = ['file', 'folder'],
  folderId = '0',
  entityText,
  description,
  onSubmit,
  onCancel,
  restrictByPermission,
}) => {
  const { id: programId } = useProgram();
  const { token, refetch } = useBoxToken({
    resourceType: 'folder',
    resourceId: folderId,
  });

  const permissionFilter = useCallback(
    (response: AxiosResponse<BoxItem>) =>
      filterBoxResponseByPermission(programId, response),
    [programId]
  );

  const refetchToken = useCallback(
    async (response: AxiosResponse) => {
      const tokenResponse = await refetch();

      if (!tokenResponse.data?.token) {
        return response;
      }

      return axios.request({
        ...response.config,
        headers: {
          ...response.config.headers,
          Authorization: `Bearer ${tokenResponse.data.token}`,
        },
      });
    },
    [refetch]
  );

  const responseInterceptor = useResponseInterceptor({
    permissionFilter: restrictByPermission ? permissionFilter : undefined,
    refetchToken,
  });

  return (
    <FormModal
      onSubmit={() => {}}
      onCancel={onCancel}
      actionText="select"
      entityText={entityText ?? 'box content'}
      description={description}
      contentPadding="0"
      hideFooter
    >
      <div className={styles.PickerWrapper}>
        {!token ? (
          <div className={styles.Loading}>
            <LoadingSpinner />
          </div>
        ) : (
          <BoxContentPicker
            type={type}
            token={token}
            folderId={folderId}
            logoUrl="box"
            onCancel={onCancel}
            canUpload={false}
            canSetShareAccess={false}
            canCreateNewFolder={false}
            onChoose={(choices) => {
              const choice = choices[0]; // right now box content picker only supports selecting one item
              onSubmit({
                file_url: choice.authenticated_download_url,
                name: choice.name,
                extension:
                  choice.type === 'file' ? choice.name.split('.').pop() : '',
                resource_id: choice.id,
                resource_type: choice.type,
                description: choice.description,
                call_to_action: choice.call_to_action,
                image: choice.image,
              });
            }}
            responseInterceptor={responseInterceptor}
          />
        )}
      </div>
    </FormModal>
  );
};
