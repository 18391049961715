import { useMemo, useCallback, useState } from 'react';
import {
  ImageFieldData,
  imageToField,
  fieldToImage,
} from 'models/publisher/block';
import { ImageData } from 'models/image';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { UpdateOpts, FieldFormProps } from '../../../useFieldForm';

export function useImageDataMutator({
  data,
  default_data,
  onChange,
}: FieldFormProps<ImageFieldData>): {
  image: typeof image;
  changeImage: OnChange;
} {
  const renderingVars = useFieldVariables();
  const defaultImage = useState(fieldToImage(default_data, renderingVars))[0];
  const image = useMemo(() => {
    const incoming = fieldToImage(data, renderingVars);
    if (incoming.url) return incoming;
    return defaultImage;
  }, [data, defaultImage, renderingVars]);

  const changeImage = useCallback<OnChange>(
    (updated, opts) => {
      if (updated)
        onChange(
          // TODO... The placeholder needs moves into an effect.
          imageToField({ ...image, ...updated, isPlaceholder: false }),
          opts
        );
      else onChange(imageToField(defaultImage), opts);
    },
    [image, onChange, defaultImage]
  );

  return { image, changeImage };
}

export type OnChange = (
  updated?: Partial<ImageData>,
  opts?: UpdateOpts
) => void;
