import { useCallback, useState } from 'react';
import { changeState } from '../../services/api-post';
import { MutationOptions } from '../common';

type UseContentArchiveProps = {
  contentId: number;
  programId: number;
};
type UseContentArchiveOptions = MutationOptions<void, Error>;
type UseContentArchiveResponse = {
  isLoading: boolean;
  archive: () => Promise<void>;
};

export const useContentArchiver = (
  { contentId, programId }: UseContentArchiveProps,
  { onSuccess, onError }: UseContentArchiveOptions
): UseContentArchiveResponse => {
  const [isLoading, setIsLoading] = useState(false);

  const archive = useCallback(async () => {
    setIsLoading(true);

    try {
      await changeState({
        id: contentId,
        programId,
        action: 'archive',
      });
      onSuccess?.();
    } catch (error) {
      if (error instanceof Error) {
        onError?.(error);
      } else {
        onError?.(new Error('unknown error'));
      }
    } finally {
      setIsLoading(false);
    }
  }, [contentId, onError, onSuccess, programId]);

  return {
    isLoading,
    archive,
  };
};
