import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { InclusiveRule } from 'models/inclusive-language';
import { useInclusiveRuleValidator } from 'hooks/useInclusiveRuleValidator';
import { Form } from './components/Form';
import { useActions } from './useActions';

export const NewInclusiveRule: React.FC<RouteComponentProps> = () => {
  const { create } = useActions();

  const [inclusiveRule, setInclusiveRule] = React.useState<InclusiveRule>({
    terms: [],
    suggestions: [],
    explanation: '',
  });

  const validator = useInclusiveRuleValidator();
  const { errors } = validator.validate({ rule: inclusiveRule });

  const onChange = React.useCallback(
    (updatedData: Partial<InclusiveRule>) => {
      setInclusiveRule({ ...inclusiveRule, ...updatedData });
    },
    [inclusiveRule]
  );

  const onSave = React.useCallback(() => {
    if (errors.length === 0) create(inclusiveRule);
  }, [inclusiveRule, create, errors.length]);

  return (
    <FormPage
      title="Create Rule"
      description={
        <>
          Language guide rules are a way to provide your content creators with
          writing suggestions. Use rules to keep content on-brand, increase the
          use of inclusive language, and create more consistency in your
          organization’s writing.
        </>
      }
      actions={[
        {
          label: 'Save',
          icon: <Icon name="SaveSuccess" />,
          onClick: onSave,
          disabled: errors.length > 0,
        },
      ]}
      breadcrumbs={[
        { to: '../..', label: 'Configure' },
        { to: '..', label: 'Language Guide' },
        { label: 'Create Rule' },
      ]}
    >
      <Form inclusiveRule={inclusiveRule} onChange={onChange} />
    </FormPage>
  );
};
