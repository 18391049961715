import * as React from 'react';
import { useProgram } from 'contexts/program';
import { IframeLifecycle, useIframeIntegration } from './iframe-integration';

// TODO - The page appears idle as the user works in the frame.
//        Do we need a keep-alive to maintain the kai "session"?
//        Will injecting the Kai header into Boss Ajax solve this?

const BOSS = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

// This components takes anything an iframe would normally take.
// In addition, the `src` is required. It makes no sense otherwise.
type IframeAttrs = React.IframeHTMLAttributes<HTMLIFrameElement> &
  Required<Pick<React.IframeHTMLAttributes<HTMLIFrameElement>, 'src'>>;

type BossApp = { app: 'samba' | 'programs' };

export const BossViewFrame: React.FC<
  BossApp & IframeAttrs & IframeLifecycle
> = ({ src: hash, onNavigate, newSlugs, app = 'samba', ...iframeAttrs }) => {
  // Use the program ID to generate a path to the bossanova endpoint.
  // If the parent didn't prefix the src with a hash, this will.
  const { id: programId } = useProgram();
  const src = React.useMemo(() => {
    const host = `${BOSS}/programs/${programId}/${app}/kaiframe`;
    const path = hash[0] === '#' ? hash : `#${hash}`;
    return `${host}${path}`;
  }, [hash, app, programId]);

  // Put together the iframe attributes in the right order:
  // 1) Base defaults
  // 2) Provided custom values
  // 3) Deal with icky merges
  // 4) Finally the fully-qualified source
  const attrs: React.IframeHTMLAttributes<HTMLIFrameElement> = React.useMemo(
    () => ({
      title: src,
      width: '100%',
      height: '100%',
      frameBorder: '0',
      ...iframeAttrs,
      style: {
        margin: 0,
        padding: 0,
        display: 'block',
        border: 'none',
        ...(iframeAttrs.style ?? {}),
      },
      src,
    }),
    [iframeAttrs, src]
  );

  useIframeIntegration({ onNavigate, newSlugs });

  // eslint-disable-next-line
  return <iframe {...attrs} />;
};
