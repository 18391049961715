import React, { useEffect } from 'react';
import { fetchSelectedAttributes } from 'services/api-searchable-attributes';
import { capitalizeWords } from 'utility/strings';
import { Section } from 'DesignSystem/Form';
import { useQuery } from 'react-query';
import styles from './styles.module.css';
import { Spinner } from '../../Shared/Spinner';

function useSearchableAttributes(programId: number) {
  const { isLoading, data } = useQuery(
    `searchable_attributes_${programId}`,
    () => fetchSelectedAttributes(programId)
  );
  const [selected, setSelected] = React.useState<string[]>([]);
  useEffect(() => {
    if (data) {
      setSelected(data.map((s) => capitalizeWords(s.replace(/_/g, ' '))));
    }
  }, [data]);
  return { selected, loading: isLoading };
}

interface Props {
  programId: number;
  baseUrl?: string;
}

const SearchArttributesView: React.FC<Props> = ({ programId, baseUrl }) => {
  const { selected, loading } = useSearchableAttributes(programId);
  let content;
  if (loading) {
    content = <Spinner />;
  } else if (selected.length > 0) {
    content = (
      <div className={styles.Attributes}>
        {selected.map((item) => (
          <div key={item} className={styles.Item}>
            {item}
          </div>
        ))}
      </div>
    );
  } else {
    content = <em>No attributes selected</em>;
  }
  return (
    <Section
      href={`${baseUrl}/search-attributes`}
      title="Search Attributes"
      description="Attributes used for user search and profile preview when tagging users in content"
    >
      {content}
    </Section>
  );
};

export default SearchArttributesView;
