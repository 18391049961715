import React from 'react';
import { Settings } from 'models/publisher/settings';
import { Block, Row } from 'shared/SectionBlocks';
import { CircleCheck } from 'shared/icons';
import { useContentUserCount } from 'hooks/useContentUserCount';
import { ReviewExplanatory } from '../ReviewExplanatory';
import styles from './successmessage.module.css';

export const SuccessMessage: React.FC<{
  settings: Settings;
}> = ({ settings }) => {
  const { uniqueUserCount } = useContentUserCount();

  return (
    <Row>
      <Block className={styles.mainSuccessBlock}>
        <div className={styles.successBlock}>
          <CircleCheck
            height="175"
            width="175"
            className={styles.successIcon}
          />
          <h1 className={styles.successMessage}>You are ready to publish</h1>
          <ReviewExplanatory
            settings={settings}
            usersCount={uniqueUserCount ?? 0}
            isNewOrchestratePage
          />
        </div>
      </Block>
    </Row>
  );
};
