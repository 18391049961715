import { InfiniteQueryResponse } from 'hooks/common';
import { useInfiniteQuery } from 'react-query';

import { JourneyTemplateListItem } from 'models/journeys/journey';
import { JourneyPaginationData } from 'services/serializers/journey';
import {
  fetchJourneyTemplates,
  QueryParameters,
} from 'services/api-journey-template';
import { JourneyTemplatePaginationData } from 'services/serializers/journey-template';
import { ParameterizedFilters } from 'models/journeys/filter';

export type JourneyTemplatePage = {
  data: JourneyTemplateListItem[];
  meta?: JourneyTemplatePaginationData;
};

export function nextJourneyTemplatePageToFetch(
  props: JourneyPaginationData
): number | undefined {
  const { total, page } = props;
  if (total > page.number * page.size) {
    return Number(page.number) + 1;
  }
  return undefined;
}

export function useJourneyTemplatesInfiniteQuery(
  queryParams: QueryParameters,
  parameterizedFilters: ParameterizedFilters,
  refetchInterval: number | false | undefined = false
): InfiniteQueryResponse<
  JourneyTemplateListItem,
  JourneyTemplatePaginationData
> {
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<JourneyTemplatePage, Error>(
    ['paged-journeys-templates', queryParams, parameterizedFilters],
    async ({ pageParam = 1 }) => {
      const resp = await fetchJourneyTemplates(
        { ...queryParams, after: pageParam },
        parameterizedFilters
      );
      if (!resp) return { data: [], cursor: undefined };
      return { data: resp.data, cursor: resp.after, meta: resp.meta };
    },
    {
      refetchOnMount: 'always',
      refetchInterval,
      getNextPageParam(lastGroup) {
        return (
          lastGroup &&
          lastGroup.meta &&
          nextJourneyTemplatePageToFetch(lastGroup.meta)
        );
      },
    }
  );

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    meta: data?.pages[0].meta,
    errorMessage: error?.message,
    isLoading: isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}
