import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import cx from 'classnames';
import { Button } from 'DesignSystem/Form';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { useFlashMessage } from 'contexts/flasher';
import { MAIcon } from 'shared/MAIcon';
import { useGenerateErrorReport } from 'hooks/personalized-fields';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import {
  PersonalizedFieldsProcessingError,
  PersonalizedFieldsProcessingErrors,
} from 'services/api-personalized-fields';
import { download } from 'utility/download';
import { Tag } from './Tag';
import styles from '../styles.module.css';

const TypeMap: Record<string, string> = {
  identity: 'Identifier',
  duplicate: 'Duplicate',
  characters: 'Formatting',
};
const MessageMap: Record<string, string> = {
  'User not found': 'Unique identifiers not linked to recognized users',
  'Duplicate user': 'We found duplicate users',
  'Column too long': 'Column formatting',
};

const ErrorTableRow: React.FC<{ error: PersonalizedFieldsProcessingError }> = ({
  error: { count, type, message },
}) => {
  return (
    <tr>
      <td>{count}</td>
      <td>
        <Tag state="failed" message={TypeMap[type]} />
      </td>
      <td>• {MessageMap[message]}</td>
    </tr>
  );
};

export const ProcessingErrors: React.FC<{
  file: PersonalizedFieldsFile;
  errors: PersonalizedFieldsProcessingErrors;
}> = ({ file, errors }) => {
  const { setFlashMessage } = useFlashMessage();
  const { downloadReport } = useGenerateErrorReport({
    onSuccess(blob) {
      download(blob, `error-report-${file.name}`);
    },
    onError(error) {
      setFlashMessage({ severity: 'error', message: (error as Error).message });
    },
  });

  return errors?.length ? (
    <Box className={cx(styles.UpdateForm, styles.Errors)}>
      <Alert
        type={AlertType.error}
        bgColor="red"
        compact
        enableIcon
        icon={<MAIcon name="warning" />}
        message={
          <>
            <Text.Body bold>Failed</Text.Body>
            <Box>
              To successfully import your dataset, please fix the issues listed
              below
            </Box>
          </>
        }
      />
      <Box>
        <table className={styles.FileInfoTable}>
          <thead>
            <tr>
              <th>Quantity</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {errors.map((error) => (
              <ErrorTableRow error={error} key={error.key} />
            ))}
          </tbody>
        </table>
        <Button
          compact
          secondary
          icon={<MAIcon name="warning" />}
          label="Download Report"
          className={styles.ReportButton}
          onClick={() => downloadReport(file.id)}
        />
      </Box>
    </Box>
  ) : (
    <></>
  );
};
