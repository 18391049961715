import lucene, { AST, Node, NodeRangedTerm, NodeTerm } from 'lucene';

export function quoteTerm(term: string): string {
  if (lucene.term.escape(term) !== term) return `"${term}"`;
  return term;
}

export type ReadNode<R> =
  | { op: '<implicit>'; value: ReadNode<R> }
  | { op: 'NOT'; value: ReadNode<R> }
  | { op: 'OR'; left: ReadNode<R>; right: ReadNode<R> }
  | { op: 'AND'; left: ReadNode<R>; right: ReadNode<R> }
  | { op: 'AND NOT'; left: ReadNode<R>; right: ReadNode<R> }
  | { op: 'OR NOT'; left: ReadNode<R>; right: ReadNode<R> }
  | NodeTerm
  | NodeRangedTerm
  | R;
type Read<R> = (node: ReadNode<R>) => R;

function readOperand<R>(termOrNode: AST | Node, read: Read<R>): R {
  if ('term' in termOrNode) return read(termOrNode);
  if ('inclusive' in termOrNode) return read(termOrNode);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return readAST(termOrNode, read);
}

export function readAST<R>(ast: AST, read: Read<R>): R {
  let left = readOperand(ast.left, read);

  if ('start' in ast) left = read({ op: 'NOT', value: left });

  if ('right' in ast) {
    const right = readOperand(ast.right, read);

    if (ast.operator === '<implicit>' || ast.operator === 'NOT')
      throw new Error(`Cannot read ${JSON.stringify(ast)} using ${read.name}`);

    return read({ op: ast.operator, left, right });
  }

  return left;
}
