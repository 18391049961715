import * as React from 'react';
import { isGlobalItem, Template, templateAuthor } from 'models/library';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import {
  useLibraryDisable,
  useLibraryEnable,
  useLibraryMutation,
} from 'hooks/useLibrary';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { usePermissions } from 'contexts/permissions';
import { useFlashMessage } from 'contexts/flasher';
import { ItemType as MenuItemType } from 'shared/hover-dropdown/HoverIconMenu';
import { MenuItemsType, EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { PostPreview } from 'shared/publisher/PostPreview';
import { useUser } from 'contexts/user';

import styles from './template-banner.module.css';

type PropsType = {
  template: Template;
  invalidateQuery?: () => void;
};

export const TemplateBanner: React.FC<PropsType> = ({
  template,
  invalidateQuery,
}) => {
  const { id: programId } = useProgram();
  const { data: permissionsService, isLoading } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Service'
  );
  const {
    permissions: { manageTemplateAccess },
  } = usePermissions();
  const user = useUser();
  const { setFlashMessage } = useFlashMessage();

  const onGlobalStatusSuccess = React.useCallback(
    (t) => {
      if (invalidateQuery) invalidateQuery();
      setFlashMessage({
        severity: 'info',
        message: `Template ${t === 'published' ? 'unarchived' : 'archived'}`,
      });
    },
    [invalidateQuery, setFlashMessage]
  );

  const onGlobalStatusError = React.useCallback(() => {
    setFlashMessage({
      severity: 'error',
      message: `Could not ${
        template.status === 'published' ? 'archive' : 'unarchive'
      } the template`,
    });
  }, [template.status, setFlashMessage]);

  const onSuccess = React.useCallback(
    (t) => {
      if (invalidateQuery) invalidateQuery();
      setFlashMessage({
        severity: 'info',
        message: `Template ${
          t.is_enabled_for_program === true ? 'unarchived' : 'archived'
        }`,
      });
    },
    [invalidateQuery, setFlashMessage]
  );

  const { mutate: save, isSaving } = useLibraryMutation({
    onSuccess: onGlobalStatusSuccess,
    onError: onGlobalStatusError,
  });

  const { mutate: enable, isWorking: isEnabling } = useLibraryEnable(onSuccess);

  const { mutate: disable, isWorking: isDisabling } = useLibraryDisable(
    onSuccess
  );

  const changeGlobalTemplateStatus = React.useCallback(() => {
    if (user.superAdmin) {
      const newTemplate = { ...template };
      if (template.status === 'published') {
        newTemplate.status = 'archived';
      }
      if (template.status === 'archived') {
        newTemplate.status = 'published';
      }
      save({ item: newTemplate, programId });
    }
  }, [user.superAdmin, template, save, programId]);

  const changeTemplateStatus = React.useCallback(() => {
    if (template.is_enabled_for_program) {
      disable(template);
    } else {
      enable(template);
    }
  }, [enable, disable, template]);

  const menuItems: MenuItemsType = React.useMemo(() => {
    // status: archived template is disabled globally by superadmin
    const items = [] as Array<MenuItemType>;

    items.push({
      title: 'Preview',
      href: `/${programId}/app/preview/template/${template.id}`,
    });

    const editableByPermission =
      (!isLoading && !permissionsService?.value) || manageTemplateAccess;
    const editableByType = !isGlobalItem(template);
    if (editableByPermission && editableByType) {
      items.push({
        title: 'Edit',
        href: `/${programId}/edit/template/${template.id}`,
      });
    }

    if (template.status === 'published') {
      items.push({
        title: `${
          template.is_enabled_for_program ? 'Archive' : 'Unarchive'
        } for ${user.superAdmin ? 'this' : 'my'} community`,
        onClick: changeTemplateStatus,
      });
    }
    if (user.superAdmin && template.asset.template.createdBy === undefined) {
      items.push({
        title: `${
          template.status === 'published' ? 'Archive' : 'Unarchive'
        } globally`,
        onClick: changeGlobalTemplateStatus,
      });
    }
    return items;
  }, [
    template,
    user.superAdmin,
    programId,
    changeTemplateStatus,
    changeGlobalTemplateStatus,
    manageTemplateAccess,
    isLoading,
    permissionsService?.value,
  ]);

  const templateStatus = React.useMemo(() => {
    if (template.status === 'archived') {
      return 'archived globally';
    }
    if (template.status === 'published' && !template.is_enabled_for_program) {
      return 'archived for program';
    }
    return 'published';
  }, [template.is_enabled_for_program, template.status]);

  const createdBy = templateAuthor(template);

  return (
    <EmptyBanner menuItems={menuItems}>
      <div className={styles.wrapper}>
        {(isSaving || isEnabling || isDisabling) && (
          <div className={styles.spinnerContainer}>
            <LoadingSpinner />
          </div>
        )}
        {template.thumbnail_images?.length ? (
          <img
            src={template.thumbnail_images[0]?.url}
            alt="Thumbnail"
            className={styles.templateThumbnail}
          />
        ) : (
          <PostPreview post={template.asset.template} width={80} height={80} />
        )}
        <div className={styles.templateData}>
          <div className={cx(styles.col, styles.title)}>{template.title}</div>
          <div className={cx(styles.col, styles.creator)}>
            <div className={styles.colHeader}>Creator</div>
            <div className={styles.colData}>{createdBy}</div>
          </div>
          <div className={cx(styles.col, styles.status)}>
            <div className={styles.colHeader}>Status</div>
            <div className={styles.colData}>{templateStatus}</div>
          </div>
        </div>
      </div>
    </EmptyBanner>
  );
};
