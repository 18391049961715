import React, { useCallback } from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { IdentityScope } from 'models/identity';
import { User } from 'models/user';
import { WorkflowTemplatesSelect } from 'components/templates/WorkflowTemplatesSelect';
import { WorkflowTemplate } from 'models/workflow-templates';
import { useHasNoRestrictions } from './hooks/roles';

type PropsType = {
  selectedIds: string[];
  onChange: (value: IdentityScope[]) => void;
  role: User['role'];
};

export const WorkflowTemplatesSelector: React.FC<PropsType> = ({
  selectedIds,
  onChange,
  role,
}) => {
  const onSelectedTemplatesChange = useCallback(
    (templates: WorkflowTemplate[]) => {
      const scopes: IdentityScope[] = templates.map((template) => {
        return {
          type: 'workflow_template',
          name: `workflow_template:${template.id}`,
        };
      });
      onChange(scopes);
    },
    [onChange]
  );

  const hasNoRestrictions = useHasNoRestrictions(`${role}`);

  return (
    <Fieldset>
      <div className={fieldsetStyles.fieldGroupContainer}>
        <Field label="Workflow Templates">
          {hasNoRestrictions ? (
            <div>Selected role has access to all workflow templates</div>
          ) : (
            <WorkflowTemplatesSelect
              selectedTemplateIds={selectedIds}
              onSelectedTemplatesChange={onSelectedTemplatesChange}
              placeholder="Search workflow templates"
            />
          )}
        </Field>
      </div>
    </Fieldset>
  );
};
