import React, { FC, useContext } from 'react';
import { pluralize } from 'utility/text';
import cx from 'classnames';
import { ChevronDown, ChevronUp } from 'shared/icons';
import { Button } from '@socialchorus/shared-ui-components';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { MAIcon } from 'shared/MAIcon';
import { useNavigate } from '@reach/router';
import styles from './styles.module.css';
import { useApplicableErrors } from '../../../../topicErrorHooks';
import {
  TopicErrorDefinition,
  TopicErrorGroup,
} from '../../../../topicErrorDefinitions';
import { useTopicFormCtx } from '../../../context';
import { TopicErrorsPillToggleContext } from './TopicErrorsPillToggleContext';

export const TopicErrorsPill: FC = () => {
  const navigate = useNavigate();
  const { baseUrl, selectTab, toggleSidebar } = useTopicFormCtx();
  const applicableErrors = useApplicableErrors();
  const {
    isTopicErrorsPillOpen,
    closeTopicErrorsPill,
    toggleTopicErrorsPill,
  } = useContext(TopicErrorsPillToggleContext);

  if (!applicableErrors.length) {
    return null;
  }

  const groupedErrors = applicableErrors.reduce(
    (errorDefinitionsByGroup, error) => {
      const partiallyPopulatedGroup =
        errorDefinitionsByGroup.get(error.group) ?? [];
      partiallyPopulatedGroup.push(error);
      errorDefinitionsByGroup.set(error.group, partiallyPopulatedGroup);

      return errorDefinitionsByGroup;
    },
    new Map<TopicErrorGroup, TopicErrorDefinition[]>()
  );

  const buttonLabel = `${applicableErrors.length} ${pluralize(
    applicableErrors.length,
    'Error'
  )}`;

  return (
    <ExpandContractPill
      buttonLabel={buttonLabel}
      isOpen={isTopicErrorsPillOpen}
      toggle={toggleTopicErrorsPill}
      close={closeTopicErrorsPill}
    >
      <ul aria-label="Topic Errors" className={styles.ErrorList}>
        {[...groupedErrors].map(([group, errors]) => (
          <li key={group.name}>
            <button
              onClick={() => {
                const firstSidebar =
                  errors.find((error) => error.sidebar)?.sidebar ?? null;

                toggleSidebar(firstSidebar);
                if (group.tab) selectTab(group.tab);
                navigate(`${baseUrl}/${group.screen}`);
              }}
              type="button"
              className={styles.ButtonReset}
            >
              <div>
                <MAIcon name="warning" className={styles.RedIcon} />
              </div>
              <div>
                {displayTextOrAggregatedErrors({
                  groupName: group.name,
                  errors,
                })}
              </div>
            </button>
          </li>
        ))}
      </ul>
    </ExpandContractPill>
  );
};

function displayTextOrAggregatedErrors(group: {
  groupName: string;
  errors: readonly TopicErrorDefinition[];
}): string {
  if (group.errors.length === 1) {
    return `${group.groupName}: ${group.errors[0].text}`;
  }

  return `${group.groupName}: ${group.errors.length} errors`;
}

const ExpandContractPill: FC<{
  buttonLabel: string;
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
}> = ({ children, buttonLabel, isOpen, toggle, close }) => {
  const clickAreaRef = React.useRef(null);
  useOnClickOutside(clickAreaRef, close);

  return (
    <div ref={clickAreaRef} className={styles.RelativeWrapper}>
      <Button
        className={cx(styles.Rounded, styles.PillButton)}
        onClick={toggle}
        label={buttonLabel}
        size="compact"
        leftIcon={<MAIcon name="warning" />}
        rightIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
        variant="danger"
      />
      <div className={styles.AbsoluteChild}>{isOpen && children}</div>
    </div>
  );
};
