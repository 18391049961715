import {
  ThumbnailType,
  MenuItemsType,
  IconStatType,
} from '../../../../shared/BannerListItem';

import { Initiative } from '../../../../models/initiative';

const BACKGROUND_COLORS = ['#5D7E80', '#5D6480', '#6797AC'];

export function initiativeToThumbnail(
  initiative: Pick<Initiative, 'name'>,
  index: number
): ThumbnailType {
  return {
    title: initiative.name[0],
    color: 'var(--color-gray05)',
    backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
  };
}

export function initiativeToMenuItems(
  programId: number,
  initiative: Initiative,
  onDestroy: () => void,
  onArchive: () => void,
  onUnarchive: () => void
): MenuItemsType {
  const { id } = initiative;

  const result: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${programId}/configure/initiatives/${id}/edit`,
    },
    {
      title: initiative.isActive ? 'Archive' : 'Unarchive',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (initiative.isActive) onArchive();
        else onUnarchive();
      },
    },
    {
      title: 'Delete',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDestroy();
      },
    },
  ];

  return result;
}

export function initiativeToMetaStats(
  initiative: Pick<Initiative, 'postsCount'>
): Array<IconStatType> {
  const { postsCount } = { ...initiative };

  const result: Array<IconStatType> = [
    {
      text: `${postsCount} posts`,
    },
  ];

  return result;
}
