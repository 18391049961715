import * as React from 'react';
import { DateTime } from 'luxon';
import { RouteComponentProps } from '@reach/router';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { ListPage as LayoutListPage } from 'DesignSystem/Layout/Pages';
import * as Text from 'DesignSystem/Typography';
import { useMicroappsInfiniteQuery } from 'hooks/microapp';
import { useProgram } from 'contexts/program';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { Microapp } from 'models/microapp';
import { Space } from 'DesignSystem/Layout/Flex';
import { microappToThumbnail } from './mappings';

const Banner: React.FC<{
  microapp: Microapp;
  index: number;
}> = ({ microapp, index }) => {
  const { id: programId } = useProgram();
  const thumbnail = microappToThumbnail(microapp, index);
  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={[
        {
          title: 'Edit',
          href: `/${programId}/configure/microapps/edit/${microapp.id}`,
        },
      ]}
      rowId={`${microapp.id}`}
    >
      <div style={{ flex: 1, padding: '0 20px' }}>
        <div>
          <Text.Subheading bold>{microapp.name}</Text.Subheading>
        </div>
        <Text.Caption>
          Created At:{' '}
          {microapp.createdAt &&
            DateTime.fromJSDate(new Date(microapp.createdAt)).toFormat(
              'MMM dd, yyyy'
            )}
        </Text.Caption>
      </div>
      <Space />
    </EmptyBanner>
  );
};

export const ListPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const query = useMicroappsInfiniteQuery({
    programId,
  });

  return (
    <LayoutListPage
      title="Microapps"
      description="Create and manage microapps to reach employees anywhere"
      breadcrumbs={[{ label: 'Configure', to: '..' }, { label: 'Microapps' }]}
      actions={[
        {
          icon: <Icon name="Plus" fill="currentColor" />,
          label: 'Create',
          to: './new',
        },
      ]}
      infiniteQuery={query}
      emptyList={
        <Text.Subheading block bold>
          No microapps match that criteria.
        </Text.Subheading>
      }
      loading={<Text.Body block>Loading...</Text.Body>}
      renderRow={(microapp: Microapp, index: number) => (
        <Banner key={microapp.id} index={index} microapp={microapp} />
      )}
    />
  );
};
