export const getScrollParent = (node?: Element | null): Element | null => {
  if (!node) return null;
  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent((node.parentNode as unknown) as Element);
};

export const getTotalHeight = (selOrEl: Element | string): number => {
  const element =
    typeof selOrEl === 'string' ? document.querySelector(selOrEl) : selOrEl;
  if (!element) return 0;

  const { height } = element.getBoundingClientRect();
  const { marginTop, marginBottom } = window.getComputedStyle(element);
  const margin = parseInt(marginTop, 10) + parseInt(marginBottom, 10);
  return height + margin;
};
