import { useInfiniteQuery, useQuery } from 'react-query';
import {
  InfiniteQueryResponse,
  nextPageToFetch,
  QueryResponse,
} from './common';
import { TopicTag } from '../models/topic-tag';
import {
  fetchTopicTags,
  FetchProps,
  TopicTagsCollectionData,
  TopicTagData,
} from '../services/api-topic-tags';

export const mapServerDataToTopicTags = (
  serverData: TopicTagsCollectionData
): Array<TopicTag> => {
  return serverData.data.map((entity: TopicTagData) => entity.attributes);
};

export const useTopicTagsQuery = (
  props: FetchProps
): QueryResponse<Array<TopicTag>> => {
  const { isLoading, error, data } = useQuery<TopicTagsCollectionData, Error>(
    ['topic-tags', { ...props }],
    () => fetchTopicTags(props),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapServerDataToTopicTags(data),
  };
};

export const useTopicTagsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<TopicTag> => {
  const { programId, query, pageSize = 20000, statuses } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<TopicTagsCollectionData, Error>(
    ['topic-tags-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchTopicTags({
        programId,
        query,
        page: pageParam as number,
        statuses,
        pageSize,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) =>
        batch ? batch.data.map((topicTagData) => topicTagData.attributes) : []
      )
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};
