import React from 'react';

import { EventTrigger } from 'models/journeys/journey';
import { ComplexExpression } from 'models/expression';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Text } from 'DesignSystem/Typography';
import { pluralize } from 'utility/text';
import { SUPPORTED_CAMPAIGN_EVENTS } from '../../../JourneyDrawer/StartConfig/EventSelect';
import { isEveryone as everyone, parseCriterion } from './utils';

export const EventBody: React.FC<{
  trigger: EventTrigger;
  criterion?: ComplexExpression;
}> = ({ trigger, criterion }) => {
  const startEvent =
    SUPPORTED_CAMPAIGN_EVENTS[
      trigger.event as keyof typeof SUPPORTED_CAMPAIGN_EVENTS
    ];
  const isEveryone = !criterion || everyone(criterion);
  const parsedCriterion = !isEveryone && criterion && parseCriterion(criterion);

  return (
    <Flex column alignStart>
      <Text className={{ Semibold: true }}>Start Type: Event</Text>
      <Text className={{ Semibold: true }}>Start Event: {startEvent}</Text>
      {isEveryone ? (
        <Text className={{ Semibold: true }}>
          Filter Members: None, everyone is eligible
        </Text>
      ) : (
        parsedCriterion && (
          <Text className={{ Semibold: true }}>
            Filter Members: {parsedCriterion.count}{' '}
            {pluralize(parsedCriterion.count, 'filter')} applied
          </Text>
        )
      )}
    </Flex>
  );
};
