import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { ActionData } from 'DesignSystem/Layout/Actions';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useFilters } from 'hooks/language-controls/useFilters';
import { defaultFilters } from 'models/language-controls/filter';
import { WithPermission } from 'shared/WithPermission';
import { LanguageControlsList } from './LanguageControlsList';
import { FilterBar } from './FilterBar/FilterBar';

export const LanguageControlsListPage: React.FC<RouteComponentProps> = () => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const {
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    queryParameters,
    parameterizedFilters,
  } = useFilters(defaultFilters);

  const actions: ActionData[] = [
    {
      label: '+ Rule',
      to: './new',
    },
  ];

  return (
    <WithPermission permissions={['languageControlsAccess']}>
      <ListPage
        title="Language Controls"
        filterbar={
          <FilterBar
            filters={filters}
            searchTerm={searchTerm}
            onFiltersChange={setFilters}
            onSearchChange={setSearchTerm}
          />
        }
        actions={actions}
      >
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <LanguageControlsList
              parentRef={parentRef}
              height={height}
              queryParameters={queryParameters}
              parameterizedFilters={parameterizedFilters}
            />
          )}
        </InfiniteContainer>
      </ListPage>
    </WithPermission>
  );
};
