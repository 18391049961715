import React from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from '@reach/router';
import { deepMerge } from 'utility/deep-merge';
import {
  EditingStates,
  VisibilityTypes,
  WORKFLOW_CHANNELS,
} from 'models/workflows';

export type Filter = {
  name: string;
  field: string;
  label: string;
  values: Array<string>;
  states?: Array<string>;
  isVisible: boolean;
};

export type BooleanFilter = {
  name: string;
  field?: string;
  label: string;
  value?: boolean;
};

export type WorkflowFiltersContextType = {
  filters: FiltersStateType;
  setBooleanValue: (name: string, value: boolean) => void;
  setValue: (name: string, values: Array<string>) => void;
  setVisibility: (name: string, visible: boolean) => void;
};

export const WorkflowFiltersContext = React.createContext<
  WorkflowFiltersContextType
>({
  setBooleanValue: () => {},
  setValue: () => {},
  setVisibility: () => {},
  filters: {
    standard: {},
    boolean: {},
  },
});

export type FiltersStateType = {
  standard: { [name: string]: Filter };
  boolean: { [name: string]: BooleanFilter };
};

// Available workflow filters
export const defaultState = (
  partialState?: Partial<FiltersStateType>
): FiltersStateType => {
  const initial = {
    standard: {
      workflowStates: {
        name: 'editingState',
        field: 'editingState',
        label: 'State',
        values: [],
        states: EditingStates,
        isVisible: false,
      },
      channels: {
        name: 'channels',
        field: 'channels',
        label: 'Type',
        values: [],
        states: WORKFLOW_CHANNELS.filter((ch) => ch !== 'feed'),
        isVisible: true,
      },
      visibility: {
        name: 'visibility',
        field: 'visibility',
        label: 'Visibility',
        values: [],
        states: VisibilityTypes,
        isVisible: true,
      },
      initiatives: {
        name: 'initiatives',
        field: 'initiativeTagIds',
        label: 'Initiatives',
        values: [],
        isVisible: true,
      },
    },
    boolean: {},
  };

  return deepMerge(initial, partialState, { arrays: 'replace' });
};

type FilterProps = {
  customDefaultState?: FiltersStateType;
  filterCallback?: (filters: FiltersStateType) => void;
};

export const useFilters = ({
  customDefaultState,
  filterCallback,
}: FilterProps): {
  filters: FiltersStateType;
  setBooleanValue: (name: string, value: boolean) => void;
  setValue: (name: string, values: Array<string>) => void;
  setVisibility: (name: string, value: boolean) => void;
} => {
  const [filters, setFiltersState] = React.useState<FiltersStateType>(
    customDefaultState ?? defaultState()
  );

  const setVisibility = React.useCallback(
    (name: string, visible: boolean) => {
      const filterToUpdate = filters.standard[name];
      if (filterToUpdate) {
        filterToUpdate.isVisible = visible;
        const newState = {
          ...filters.standard,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          standard: newState,
        });
      }
    },
    [filters]
  );

  const setBooleanValue = React.useCallback(
    (name: string, value: boolean) => {
      const filterToUpdate = filters.boolean[name];
      if (filterToUpdate) {
        filterToUpdate.value = value;
        const newState = {
          ...filters.boolean,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          boolean: newState,
        });
        if (filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  const setValue = React.useCallback(
    (name: string, values: Array<string>) => {
      const filterToUpdate = filters.standard[name];
      if (filterToUpdate) {
        filterToUpdate.values = values;
        const newState = { ...filters.standard, [name]: filterToUpdate };

        const state = {
          ...filters,
          standard: newState,
        };
        setFiltersState(state);
        if (filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  return {
    filters,
    setValue,
    setBooleanValue,
    setVisibility,
  };
};

export const WorkflowFiltersProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQueryParams = React.useCallback(
    (filters: FiltersStateType) => {
      const queryParams: { [name: string]: string } = {};

      Object.keys(filters.standard).forEach((k) => {
        if (filters.standard[k].values.length > 0)
          queryParams[k] = filters.standard[k].values.join(',');
      });

      Object.keys(filters.boolean).forEach((k) => {
        if (filters.boolean[k].value) queryParams[k] = 'true';
      });

      const query = qs.stringify(queryParams);
      navigate(`${location.pathname}?${query}`);
    },
    [location.pathname, navigate]
  );

  const { filters, setValue, setBooleanValue, setVisibility } = useFilters({
    filterCallback: setQueryParams,
  });

  const queryString = location.search || '?';

  React.useEffect(() => {
    // update the values from query params
    async function updateValuesFromQuery() {
      const params = qs.parse(queryString, { ignoreQueryPrefix: true });
      Object.keys(params).forEach(async (key) => {
        const k = params[key] as string;
        if (k) {
          if (filters.standard[key]) {
            const values = (params[key] as string).split(',');
            setValue(key, values);
          } else if (filters.boolean[key]) {
            setBooleanValue(key, true);
          }
        }
      });
    }
    updateValuesFromQuery();
    // queryString is a dependency because the queryString might change
    // when studio admin uses the notifications menu links
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  return (
    <WorkflowFiltersContext.Provider
      value={{
        setVisibility,
        setBooleanValue,
        setValue,
        filters,
      }}
    >
      {children}
    </WorkflowFiltersContext.Provider>
  );
};
