import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { FilterDropdown } from 'shared/FilterDropdown';
import { SearchBar } from 'shared/SearchBar';
import {
  CustomSlugFilter,
  CustomSlugFilterOptions,
} from 'services/api-custom-slugs';
import { useCustomSlugFiltersData } from 'hooks/custom-slugs';
import { useProgram } from 'contexts/program';

type FilterTermList = {
  // eslint-disable-next-line prettier/prettier -- prettier bug
  [K in keyof CustomSlugFilterOptions as `${K}Term`]: CustomSlugFilterOptions[K];
};

type PropsType = {
  filterTerms: FilterTermList;
  setSearchTerm: (searchTerm: string) => void;
  onFilterChange: (filterName: CustomSlugFilter, values: string[]) => void;
};

export const FiltersBar: React.FC<PropsType> = ({
  setSearchTerm,
  onFilterChange,
  filterTerms,
}) => {
  const { searchTerm, createdByTerm } = filterTerms;
  const { id: programId } = useProgram();
  const filterOptions = useCustomSlugFiltersData(programId);

  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search custom urls"
        panelClassName={styles.searchBarWrapper}
      />
      <FilterDropdown
        label="Created By"
        onChange={(value) => onFilterChange('createdBy', value)}
        options={filterOptions}
        selectedOptions={filterOptions.filter((opt) =>
          createdByTerm?.includes(opt.value)
        )}
      />
    </div>
  );
};
