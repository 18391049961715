import Color from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import debounce from 'debounce-promise';
import React, { useMemo } from 'react';
import styles from './editor.module.css';
import { EditorToolbar } from './EditorToolbar';
import { TextStyleExtended } from './TipTapExtensions/TextStyle';

type AboutEditorProps = {
  content: string | undefined;
  onChange: (content: string, previewContent: string) => void;
  addFontId: (id: number) => void;
};

const AboutEditor: React.FC<AboutEditorProps> = ({
  content,
  onChange,
  addFontId,
}) => {
  const debouncedOnChange = useMemo(
    () =>
      debounce((html: string, preview: string) => onChange(html, preview), 300),
    [onChange]
  );

  const editor = useEditor({
    extensions: [
      StarterKit,
      FontFamily,
      Superscript,
      Subscript,
      Underline,
      Highlight.configure({ multicolor: true }),
      Link.configure({
        openOnClick: false,
        autolink: false,
      }),
      Color,
      Image,
      TextStyleExtended,

      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    onUpdate: ({ editor: ed }) => {
      const {
        state: { doc },
      } = ed.view;
      const MAX_CHARACTER_LENGTH = 120;
      const previewText =
        doc.textContent.length < MAX_CHARACTER_LENGTH
          ? doc.textContent
          : doc.textContent.slice(0, MAX_CHARACTER_LENGTH);

      debouncedOnChange(ed.getHTML(), previewText);
    },
    content,
  });

  return (
    <div className={styles.editor}>
      <EditorToolbar editor={editor} addFontId={addFontId} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default AboutEditor;
