import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Icon } from 'shared/Icon';
import { LitmusRole } from 'models/role';
import { useRoleQueryById } from 'hooks/roles';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useProgram } from 'contexts/program';
import { ActionData } from 'DesignSystem/Layout/Actions';
import { WithPermission } from 'shared/WithPermission';
import { useActions } from '../useActions';
import { Spinner } from '../../Shared/Spinner';
import { General } from './General';
import { Permissions } from './Permissions';
import style from '../roles.module.css';

export const ViewRolePage: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const [role, setRole] = React.useState<LitmusRole>();
  const { id: programId } = useProgram();
  const { data, isLoading, errorMessage } = useRoleQueryById(
    programId,
    id as number
  );

  React.useEffect(() => {
    if (data) {
      setRole(data);
    }
  }, [data]);

  const { duplicate } = useActions();
  const handleDuplication = () => role && duplicate(role);

  const actions: ActionData[] = [
    {
      icon: (
        <span className={style.headerIcon}>
          <Icon iconName="DuplicateNew" iconType="SVG" />
        </span>
      ),
      label: '',
      layoutOnly: true,
      onClick: handleDuplication,
    },
  ];

  return (
    <WithPermission permissions={['configureRoleAccess']}>
      <FormPage
        title={role?.title || 'View role'}
        breadcrumbs={[
          { label: 'Configure', to: '../../..' },
          { label: 'Roles', to: '../..' },
          { label: role?.title || 'View role' },
        ]}
        actions={actions}
      >
        <>
          {isLoading && <Spinner />}
          {errorMessage && <>{errorMessage}</>}

          {!isLoading && !errorMessage && role && (
            <>
              <General role={role as LitmusRole} programId={programId} />
              <Permissions role={role as LitmusRole} programId={programId} />
            </>
          )}
        </>
      </FormPage>
    </WithPermission>
  );
};
