import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { ReportDownload } from 'models/report-download';
import { pluralize } from 'utility/text';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { PageHeader } from 'DesignSystem/Layout/Pages';
import { SearchBar } from 'shared/SearchBar';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MAIcon } from 'shared/MAIcon';
import { useReportDownloadsData } from '../hooks/useReportDownloadsData';
import styles from '../insight.module.css';

export const ReportDownloads: React.FC = () => {
  const {
    download,
    filter,
    isError,
    isLoading,
    noMatches,
    dateRanges,
    reportsByRange,
    tabs,
    unlock,
  } = useReportDownloadsData();
  return (
    <Box padding={[0, 32]}>
      <PageHeader title="Insights" tabs={tabs} />
      <Type.Heading block bold>
        Report Downloads
      </Type.Heading>
      <Type.Body block>
        Click to generate URLs for the reports that are available. Click again
        to download the reports.
      </Type.Body>
      <Box width={450}>
        <SearchBar
          placeholder="Filter by name/url"
          onChange={filter.setSearchTerm}
          value={filter.searchTerm}
        />
      </Box>
      {isLoading && (
        <Box padding={32}>
          <LoadingSpinner />
        </Box>
      )}
      {noMatches && (
        <Box padding={32}>
          no results{' '}
          {filter.searchTerm && (
            <>
              for <strong>{filter.searchTerm}</strong>
            </>
          )}
        </Box>
      )}
      {!isLoading &&
        dateRanges.map((range) => (
          <DateRangeSection
            download={download}
            unlock={unlock}
            key={range}
            range={range}
            reports={reportsByRange(range)}
          />
        ))}
      {isError && (
        <Box padding={32}>
          There was an unexpected error loading the downloads. Please refresh
          the page and try again.
        </Box>
      )}
    </Box>
  );
};

const DateRangeSection: React.FC<{
  range: string;
  reports: ReturnType<
    ReturnType<typeof useReportDownloadsData>['reportsByRange']
  >;
  download: ReturnType<typeof useReportDownloadsData>['download'];
  unlock: ReturnType<typeof useReportDownloadsData>['unlock'];
}> = ({ range, reports, download, unlock }) => {
  if (!reports.length) return null;
  const toFilename = (url: string, status: ReportDownload['status']) => {
    if (status === 'loading') return 'Loading signed url...';
    if (status === 'locked') return 'Click to generate a signed URL.';
    const { pathname } = new URL(url);
    const filename = pathname.split('/').pop() || '';
    return decodeURIComponent(filename);
  };
  const toIcon = (status: ReportDownload['status']) => {
    if (status === 'ready') return 'download';
    if (status === 'locked') return 'lock';
    return 'pending';
  };
  const lockedTotal = reports.reduce(
    (a, c) => a + (c.status === 'locked' ? 1 : 0),
    0
  );
  const readyTotal = reports.reduce(
    (a, c) => a + (c.status === 'ready' ? 1 : 0),
    0
  );
  return (
    <Box padding={[32, 0]}>
      <Type.Heading bold block color={Type.color.brandShadeLight}>
        {range.replace(/~/, ' to ')}
      </Type.Heading>
      <Box
        radius={8}
        padding={16}
        background={Type.background.gray05}
        style={{ display: 'inline-block' }}
      >
        {reports.map((report) => (
          <button
            type="button"
            key={report.url}
            data-reportdownload={report.status === 'ready' ? range : undefined}
            data-reportlock={report.status === 'locked' ? range : undefined}
            className={styles.ReportDownload}
            onClick={() => {
              if (report.status === 'ready') download(report);
              else if (report.status === 'locked') unlock(report);
            }}
          >
            <Box padding={[0, 8, 0, 0]}>
              <MAIcon name={toIcon(report.status)} />
            </Box>
            <Box>
              <Type.Body bold>{report.name}</Type.Body>
              <br />
              <Type.Caption>
                <div className="kai-line-clamp">
                  {toFilename(report.url, report.status)}
                </div>
              </Type.Caption>
            </Box>
          </button>
        ))}
        <FooterButtons
          lockedTotal={lockedTotal}
          readyTotal={readyTotal}
          lockedSelector={`[data-reportlock="${range}"]`}
          readySelector={`[data-reportdownload="${range}"]`}
        />
      </Box>
    </Box>
  );
};

const FooterButtons: React.FC<{
  lockedSelector: string;
  lockedTotal: number;
  readySelector: string;
  readyTotal: number;
}> = ({ lockedSelector, readySelector, readyTotal, lockedTotal }) => {
  return (
    <Box>
      <hr />
      <Flex start>
        <Button
          compact
          disabled={!readyTotal}
          onClick={() => {
            document.querySelectorAll(readySelector).forEach((n) => {
              // @ts-expect-error Don't tell me how to open windows.
              n.click();
            });
          }}
          icon={<MAIcon name="download" />}
          label={
            readyTotal
              ? `Download ${readyTotal} ${pluralize(readyTotal, 'report')}`
              : ''
          }
        />
        <span>&nbsp;</span>
        {lockedTotal > 0 && (
          <Button
            compact
            secondary
            onClick={() => {
              document.querySelectorAll(lockedSelector).forEach((n) => {
                // @ts-expect-error Don't tell me how to open windows.
                n.click();
              });
            }}
            icon={<MAIcon name="lock" />}
            label={`Generate ${pluralize(
              lockedTotal,
              'URL'
            )} for ${lockedTotal} locked ${pluralize(lockedTotal, 'report')}`}
          />
        )}
      </Flex>
    </Box>
  );
};
