import * as React from 'react';
import cx from 'classnames';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { Tooltip } from 'shared/Tooltip';
import styles from './content-channels.module.css';

const alignmentOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;

export type ContentTopicsAndAudiencesPropsType = {
  topicsAndAudiences: Array<{
    id: string;
    name: string;
    count: number;
  }>;
  align?: keyof typeof alignmentOptions;
};

export const ContentTopicsAndAudiences: React.FC<ContentTopicsAndAudiencesPropsType> = ({
  topicsAndAudiences,
  align = 'center',
}) => {
  const count = 3;
  const moreItems = topicsAndAudiences.slice(count);
  const alignment = alignmentOptions[align];
  return (
    <div className={styles.wrapper} style={{ justifyContent: alignment }}>
      {topicsAndAudiences.slice(0, count).map((topicOrAudience) => (
        <div
          className={cx('banner-pill-item', styles.topicOrAudience)}
          key={topicOrAudience.id}
        >
          {topicOrAudience.name}
        </div>
      ))}
      {moreItems.length > 0 && (
        <HoverDropdown
          dropdownClassName={styles.moreItemDropdown}
          dropdownRenderProp={() => {
            return (
              <Tooltip
                description={
                  <div className={styles.moreTopicOrAudienceItemItems}>
                    {moreItems.map((topicOrAudience) => (
                      <div
                        key={topicOrAudience.id.toString()}
                        className={styles.topicOrAudienceItem}
                      >
                        <div className={styles.topicOrAudienceName}>
                          {topicOrAudience.name}
                        </div>
                        <div className={styles.topicOrAudienceCount}>
                          {topicOrAudience.count || 0}
                        </div>
                      </div>
                    ))}
                  </div>
                }
              />
            );
          }}
        >
          <div className={cx('banner-pill-more', styles.topicOrAudience)}>
            +{moreItems.length} More
          </div>
        </HoverDropdown>
      )}
    </div>
  );
};
