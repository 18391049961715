import * as React from 'react';
import { InfiniteQueryResponse } from 'hooks/common';
import { InfiniteWrapper } from 'shared/InfiniteWrapper';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { throttle } from 'utility/throttle';
import styles from './layout.module.css';

export const InfiniteContainer: React.FC<React.ComponentProps<
  typeof InfiniteWrapper
>> = ({ children, style, parentRef, isFullWidth, isStandalone }) => {
  React.useEffect(() => {
    const el = parentRef.current;
    const handleScroll = throttle(() => {
      if (el) {
        if (el.scrollTop <= 20) {
          el.classList.remove(styles.InfiniteContainerShadow);
        } else {
          el.classList.add(styles.InfiniteContainerShadow);
        }
      }
    });
    if (el) {
      el.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (el) {
        el.removeEventListener('scroll', handleScroll);
        el.classList.remove(styles.InfiniteContainerShadow);
      }
    };
  }, [parentRef]);

  return (
    <div
      style={{
        margin: isStandalone ? 0 : '0 -32px',
        position: 'relative',
      }}
      className={styles.PageContentList}
    >
      <InfiniteWrapper
        style={{
          padding: isStandalone ? 0 : '0 32px',
          maxWidth: isFullWidth
            ? undefined
            : 'calc(100vw - var(--layout-main-nav-width))',
          ...style,
        }}
        parentRef={parentRef}
      >
        {children}
      </InfiniteWrapper>
    </div>
  );
};

export type InfiniteListProps<T> = {
  infiniteQuery: InfiniteQueryResponse<T>;
  emptyList: React.ReactElement;
  loading: React.ReactElement;
  renderRow: (item: T, index: number) => React.ReactElement;
  rowHeight?: number;
  listHeaderBar?: React.ReactElement;
};

export function InfiniteList<T>({
  infiniteQuery: {
    isLoading,
    errorMessage,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    data,
  },
  emptyList,
  loading,
  renderRow,
  rowHeight,
  listHeaderBar,
}: InfiniteListProps<T>): ReturnType<React.FC> {
  const parentRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(
    null
  );
  return (
    <InfiniteContainer parentRef={parentRef}>
      {(height: number) => (
        <>
          {isLoading && loading}
          {errorMessage && <>{errorMessage}</>}
          {listHeaderBar && (
            <div className={styles.ListHeader}>{listHeaderBar}</div>
          )}
          <InfiniteBannerList
            itemCount={data?.length || 0}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            height={height}
            parentRef={parentRef}
            noItemsComponent={emptyList}
            rowHeight={rowHeight}
          >
            {(index: number) => renderRow(data[index], index)}
          </InfiniteBannerList>
        </>
      )}
    </InfiniteContainer>
  );
}
