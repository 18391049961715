import * as React from 'react';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import {
  useContentInsightsQuery,
  useMetricsSummaryQuery,
} from 'hooks/content-insights';
import { usePermissions } from 'contexts/permissions';
import { useWorkflowSummaryQuery } from 'hooks/workflows/useQueries';
import { DateTime, Interval } from 'luxon';
import { Content, isContent } from 'models/content';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { ProgressBar } from 'shared/ProgressBar';
import { Workflow } from 'models/workflows';
import { splitWords } from 'utility/text';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import {
  ContentReportLinks,
  DeliveryReportLink,
  WorkFlowReportLinks,
} from './ReportLinks';
import styles from './content-metrics-tooltip.module.css';

const Metric: React.FC<{
  name: string;
  count: number | string;
  isLoading: boolean;
  minor?: boolean;
  testId?: string;
}> = ({ name, count, isLoading, minor, testId }) => (
  <Flex
    className={cx(styles.statRow, {
      [styles.statMajor]: !minor,
      [styles.statMinor]: minor,
    })}
  >
    <div className={styles.statRowLabel}>{name}</div>
    <div
      className={cx({
        [styles.statMajor]: !minor,
        [styles.statMinor]: minor,
      })}
      data-testid={testId}
    >
      <div>{isLoading ? '...' : count || 0}</div>
    </div>
  </Flex>
);

const Delivery: React.FC<{
  content: Content;
  className?: string;
}> = ({ content }) => {
  const { data, isLoading } = useMetricsSummaryQuery({ contentId: content.id });

  const now = DateTime.now().toUTC();
  const startDate =
    (data?.epochStart && DateTime.fromSeconds(data.epochStart)) || now;
  const endDate =
    (data?.epochEnd && DateTime.fromSeconds(data.epochEnd)) || now;

  let percentCompleted = 100;
  let daysLeft = 0;

  if (now < endDate) {
    const progress = Math.floor(
      Interval.fromDateTimes(startDate, now).length('days')
    );
    const totalDuration = Math.round(
      Interval.fromDateTimes(startDate, endDate).length('days')
    );

    percentCompleted = Math.floor((progress / totalDuration) * 100);
    daysLeft = Math.ceil(Interval.fromDateTimes(now, endDate).length('days'));
  }

  const isOverride = content.contentCommunication
    ? content.contentCommunication.deliveryType === 'override'
    : false;

  return (
    <div className={styles.deliverySection}>
      {!isOverride && (
        <div className={styles.durationSection}>
          <h2 data-testid="metrics-percent-completed">
            {isLoading ? '...' : percentCompleted}%
          </h2>
          <h5 data-testid="metrics-days-left">
            {isLoading ? '...' : daysLeft} Day{daysLeft === 1 ? '' : 's'} Left
          </h5>
          {!isLoading && (
            <ProgressBar
              className={styles.progressBar}
              size={15}
              orientation="horizontal"
              max={100}
              value={percentCompleted}
              color="#3B3652"
            />
          )}
        </div>
      )}
      <div className={isOverride ? styles.durationOverride : ''}>
        <Metric
          name="email"
          count={data?.email?.delivered || 0}
          isLoading={isLoading}
          minor
          testId="metrics-channel-email"
        />
        <Metric
          name="push n."
          count={data?.push?.delivered || 0}
          isLoading={isLoading}
          minor
          testId="metrics-channel-push"
        />
        <Metric
          name="assistant"
          count={data?.assistant?.delivered || 0}
          isLoading={isLoading}
          minor
          testId="metrics-channel-assistant"
        />
      </div>
    </div>
  );
};

const ContentEngagement: React.FC<{
  content: Content;
  startDate: DateTime;
  endDate: DateTime;
  className?: string;
}> = ({ content, startDate, endDate }) => {
  const timezone = DateTime.now().zone.name;
  const { data, isLoading } = useContentInsightsQuery({
    contentId: content.id,
    startDate,
    endDate,
    timezone,
  });

  const { id: programId } = useProgram();

  const { data: reportVersion } = useFeatureFlagsQuery(
    programId,
    'Planner.Metrics.ContentReportVersion'
  );

  const displayViewers = (reportVersion?.value as string) === 'non_migrated';

  return (
    <div className={styles.engagementSection}>
      <div className={styles.contentStatsSection}>
        <Metric
          name={displayViewers ? 'Viewers' : 'Reached Users'}
          count={(data?.viewers || 0).toLocaleString()}
          isLoading={isLoading}
        />
        <Metric
          name={displayViewers ? 'Engagement' : '% Opened Users'}
          count={`${data?.openRate || 0}%`}
          isLoading={isLoading}
        />
      </div>
      <Metric
        name="Users Opened"
        count={(data?.usersOpened || 0).toLocaleString()}
        isLoading={isLoading}
        minor
        testId="metrics-engagement-opened"
      />
      <Metric
        name="Users Liked"
        count={(data?.usersLiked || 0).toLocaleString()}
        isLoading={isLoading}
        minor
        testId="metrics-engagement-liked"
      />
      <Metric
        name="Users Commented"
        count={(data?.usersCommented || 0).toLocaleString()}
        isLoading={isLoading}
        testId="metrics-engagement-commented"
        minor
      />
      <Metric
        name="Users Shared"
        count={(data?.usersShared || 0).toLocaleString()}
        isLoading={isLoading}
        minor
        testId="metrics-engagement-shared"
      />
    </div>
  );
};

const WorkflowMetricsDefaults = {
  push: {
    target: 0,
    appUsers: 0,
    delivered: 0,
    notifDisabled: 0,
    unreachable: 0,
  },
  email: {
    sent: 0,
  },
  assistant: {
    sent: 0,
  },
};

const WorkflowSummary: React.FC<{
  workflow: Workflow;
  className?: string;
}> = ({ workflow }) => {
  const { data, isLoading } = useWorkflowSummaryQuery({
    workflowId: workflow.id,
  });

  const Stats = React.useCallback(() => {
    const metrics = [];
    if (data?.email) metrics.push({ name: 'email', data: data.email });
    if (data?.push) metrics.push({ name: 'push', data: data.push });
    if (data?.assistant)
      metrics.push({ name: 'assistant', data: data.assistant });
    if (metrics.length > 1) {
      return (
        <>
          {!!data?.targeted && (
            <Metric
              name="target"
              count={data.targeted}
              isLoading={isLoading}
              minor
            />
          )}
          {!!data?.email && (
            <Metric
              name="email"
              count={data.email.DELIVERED}
              isLoading={isLoading}
              minor
            />
          )}
          {!!data?.push && (
            <Metric
              name="push"
              count={data.push.DELIVERED}
              isLoading={isLoading}
              minor
            />
          )}
          {!!data?.assistant && (
            <Metric
              name="assistant"
              count={data.assistant.DELIVERED}
              isLoading={isLoading}
              minor
            />
          )}
        </>
      );
    }

    // there should always be at least one metric present.
    const metric =
      metrics.length === 1 ? metrics[0] : { name: 'email', data: {} };
    // if we were given metrics for the channel display those, other wise display the channel's default metrics
    const stats =
      Object.keys(metric.data).length > 0
        ? metrics[0].data
        : WorkflowMetricsDefaults[
            metric.name as keyof typeof WorkflowMetricsDefaults
          ];
    return (
      <>
        {!!data?.targeted && (
          <Metric
            name="target"
            count={data.targeted}
            isLoading={isLoading}
            minor
          />
        )}
        {Object.entries(stats)
          .filter(
            ([name, _]) =>
              ![
                'openbymachine',
                'initial',
                'dropped',
                'openemaillink',
                'click',
              ].includes(name.toLowerCase())
          )
          .map(([name, count]) => (
            <Metric
              key={name}
              name={isLoading ? 'Loading' : splitWords(name)}
              count={count}
              isLoading={isLoading}
              minor
            />
          ))}
      </>
    );
  }, [data, isLoading]);

  return (
    <Flex column>
      <div className={styles.workflowStatsSection}>
        <Stats />
      </div>
    </Flex>
  );
};

const Metrics: React.FC<{
  data: Content | Workflow;
  startDate?: DateTime;
  endDate?: DateTime;
  offset?: string;
  direction?: string;
  animate?: boolean;
  className?: string;
}> = ({ data, startDate, endDate, offset, direction, className, animate }) => {
  const hasAudience = (metricsContent: Content) =>
    metricsContent.contentCommunication
      ? metricsContent.contentCommunication.audiences.length > 0
      : false;

  const hasChannels = (metricsContent: Content) =>
    metricsContent.contentCommunication
      ? metricsContent.contentCommunication.channels.filter(
          (channel) => channel !== 'feed'
        ).length > 0
      : false;
  const {
    permissions: { insightsAccess },
  } = usePermissions();
  return (
    <div
      className={cx(styles.reportDropdown, className, {
        [styles.animate]: animate,
      })}
      style={
        offset && direction
          ? { position: 'relative', [direction]: offset }
          : undefined
      }
    >
      <div className={styles.metricsWrapper}>
        {isContent(data) ? (
          <>
            {hasAudience(data) && <Delivery content={data} />}
            <ContentEngagement
              content={data}
              startDate={startDate ?? DateTime.now()}
              endDate={endDate?.toUTC() ?? DateTime.now()}
              className={className}
            />
          </>
        ) : (
          <WorkflowSummary workflow={data} />
        )}
      </div>
      {isContent(data) ? (
        insightsAccess && (
          <>
            <div className={styles.reportSection}>
              <ContentReportLinks content={data} />
              {hasAudience(data) && hasChannels(data) && (
                <DeliveryReportLink content={data} />
              )}
            </div>
          </>
        )
      ) : (
        <div className={styles.reportSection}>
          <WorkFlowReportLinks workflow={data} />
        </div>
      )}
    </div>
  );
};

export const MetricsTooltip: React.FC<{
  data: Content | Workflow;
  startDate?: DateTime;
  endDate?: DateTime;
  className?: string;
  closeDelay?: number;
  openDelay?: number | 'click';
  animate?: boolean;
  offset?: string;
  direction?: 'left' | 'right';
  disableDropdown?: boolean;
}> = ({
  data,
  startDate,
  endDate,
  className,
  closeDelay,
  openDelay,
  animate,
  direction = 'left',
  offset,
  children,
  disableDropdown,
}) => {
  return (
    <HoverDropdown
      closeDelay={closeDelay ?? 300}
      openDelay={openDelay ?? 0}
      dropdownClassName={`dropdown-align-${direction}`}
      dropdownRenderProp={() =>
        !disableDropdown && (
          <Metrics
            data={data}
            startDate={startDate}
            endDate={endDate}
            offset={offset}
            direction={direction}
            className={className}
            animate={animate}
          />
        )
      }
    >
      {children}
    </HoverDropdown>
  );
};
