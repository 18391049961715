import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import cx from 'classnames';
import { Targets } from 'models/donkey';
import { FlipSwitch } from 'shared/FlipSwitch';
import styles from './editor.module.css';

export type Visibility = {
  ios: boolean;
  android: boolean;
  web: boolean;
  email: boolean;
  microapp: boolean;
};

export type PropsType = {
  onChange: (data: Partial<Visibility>) => void;
  visibility: Visibility;
  disabledTargets?: Targets[];
};

export const VisibilitySettings: React.FC<PropsType> = ({
  onChange,
  visibility,
  disabledTargets = [],
}) => {
  return (
    <div>
      <p className={styles.sectionTitle}>Visibility Settings</p>
      <Flex spread>
        <p
          className={cx(styles.label, {
            [styles.disabled]: disabledTargets.includes(Targets.ios),
          })}
        >
          Show on iOS
        </p>
        <FlipSwitch
          shape="rounded"
          size="xl"
          on={visibility.ios}
          disabled={disabledTargets.includes(Targets.ios)}
          onChange={(value) =>
            value
              ? onChange({
                  ios: true,
                })
              : onChange({
                  ios: false,
                })
          }
        />
      </Flex>
      <Flex spread>
        <p
          className={cx(styles.label, {
            [styles.disabled]: disabledTargets.includes(Targets.android),
          })}
        >
          Show on Android
        </p>
        <FlipSwitch
          shape="rounded"
          size="xl"
          on={visibility.android}
          disabled={disabledTargets.includes(Targets.android)}
          onChange={(value) =>
            value
              ? onChange({
                  android: true,
                })
              : onChange({
                  android: false,
                })
          }
        />
      </Flex>
      <Flex spread>
        <p
          className={cx(styles.label, {
            [styles.disabled]: disabledTargets.includes(Targets.web),
          })}
        >
          Show on Web
        </p>
        <FlipSwitch
          shape="rounded"
          size="xl"
          on={visibility.web}
          disabled={disabledTargets.includes(Targets.web)}
          onChange={(value) =>
            value
              ? onChange({
                  web: true,
                })
              : onChange({
                  web: false,
                })
          }
        />
      </Flex>
      <Flex spread>
        <p
          className={cx(styles.label, {
            [styles.disabled]: disabledTargets.includes(Targets.email),
          })}
        >
          Show in Email
        </p>
        <FlipSwitch
          shape="rounded"
          size="xl"
          on={visibility.email}
          disabled={disabledTargets.includes(Targets.email)}
          onChange={(value) =>
            value
              ? onChange({
                  email: true,
                })
              : onChange({
                  email: false,
                })
          }
        />
      </Flex>
      <Flex spread>
        <p
          className={cx(styles.label, {
            [styles.disabled]: disabledTargets.includes(Targets.microapp),
          })}
        >
          Show in Microapps
        </p>
        <FlipSwitch
          shape="rounded"
          size="xl"
          on={visibility.microapp}
          disabled={disabledTargets.includes(Targets.microapp)}
          onChange={(value) =>
            value
              ? onChange({
                  microapp: true,
                })
              : onChange({
                  microapp: false,
                })
          }
        />
      </Flex>
    </div>
  );
};
