import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  ContentFilterBar,
  ContentFilterFetchProps,
} from 'components/content/ContentFilterBar/ContentFilterBar';
import * as DesignSystem from 'DesignSystem/Components/Calendar';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import {
  AbsoluteRange,
  DateRange,
  RelativeRange,
} from 'shared/DateRangeInput/DateRange';
import { ContentFiltersContext } from 'contexts/content/filters';
import { useDebounce } from 'hooks/useDebounce';
import { useProgram } from 'contexts/program';
import { FetchProps } from 'services/api-content';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Week } from './Week';
import styles from './Calendar.module.css';

export const CalendarPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const [isDatePickerOpen, setDatePickerState] = React.useState<boolean>(false);
  const [dateRange, setDateRange] = React.useState<AbsoluteRange>(
    RelativeRange.build('thisweek', true).toAbsolute()
  );

  const dateRangeLabel = React.useMemo(() => {
    const start = dateRange.start.toFormat('LLLL dd');
    const end = dateRange.end.toFormat(
      dateRange.start.month === dateRange.end.month ? 'dd' : 'LLLL dd'
    );
    return `${start} - ${end}`;
  }, [dateRange]);

  const onDone = React.useCallback((dr: DateRange) => {
    setDateRange(dr.toAbsolute());
    setDatePickerState(false);
  }, []);

  const toggleDatePicker = React.useCallback(() => {
    setDatePickerState(!isDatePickerOpen);
  }, [isDatePickerOpen]);

  const hideDatePicker = React.useCallback(() => {
    setDatePickerState(false);
  }, []);

  const clickAreaRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(clickAreaRef, hideDatePicker);

  const goBack = React.useCallback(() => {
    const end = dateRange.start.minus({ days: 1 });
    const start = end.minus({ weeks: 1 }).plus({ days: 1 });
    const prevWeek = new AbsoluteRange(start, end, true);
    setDateRange(prevWeek);
  }, [dateRange.start]);

  const goForward = React.useCallback(() => {
    const start = dateRange.end.plus({ days: 1 });
    const end = start.plus({ days: 6 });
    const nextWeek = new AbsoluteRange(start, end, true);
    setDateRange(nextWeek);
  }, [dateRange.end]);

  const {
    filters,
    setValue,
    setBooleanValue,
    setVisibility,
  } = React.useContext(ContentFiltersContext);

  React.useEffect(() => {
    if (!filters.standard.publicationState.isVisible) {
      setVisibility('publicationState', true);
    }
  }, [filters.standard.publicationState, setVisibility]);

  const [filterFetchProps, setFilterFetchProps] = useState<
    ContentFilterFetchProps
  >({});

  const defaultFetchProps = {
    sort: 'published_at',
    sortDirection: 'desc',
    pageSize: 20,
  };

  const fetchProps = {
    ...defaultFetchProps,
    ...useDebounce(filterFetchProps),
    programId,
  } as FetchProps;

  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  return (
    <ListPage
      title="Calendar"
      filterbar={
        <ContentFilterBar
          fetchProps={filterFetchProps}
          onChange={setFilterFetchProps}
          filters={filters}
          setValue={setValue}
          setBooleanValue={setBooleanValue}
        />
      }
      actionsOverride={
        <DesignSystem.DateRangeButton
          goForward={goForward}
          goBack={goBack}
          onClick={toggleDatePicker}
          label={dateRangeLabel}
        />
      }
    >
      <div
        ref={clickAreaRef}
        className={styles.PickerPopup}
        style={{ display: isDatePickerOpen ? 'block' : 'none' }}
      >
        <DesignSystem.WeekPicker onDone={onDone} initialValue={dateRange} />
      </div>
      <InfiniteContainer parentRef={scrollRef}>
        {() => (
          <div className={styles.CalendarData}>
            <Week fetchProps={fetchProps} dateRange={dateRange} />
          </div>
        )}
      </InfiniteContainer>
    </ListPage>
  );
};
