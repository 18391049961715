import { useProgram } from 'contexts/program';
import {
  DefinitionBlock,
  RichTextFieldData,
  PlainTextFieldData,
  SimpleTextFieldData,
  LinkFieldData,
} from 'models/donkey';
import * as React from 'react';
import { QueryStatus, UseMutateFunction } from 'react-query';
import {
  createContentCoverAthenaTask,
  CreateTaskResponse,
  SupportedCommands,
} from 'services/api-ai-chat-completion';
import { Post } from 'models/publisher/post';
import { useAthenaTask } from './athena';
import { useFeatureFlagsQuery } from './feature-flags';

export function useAICoverGenerationFeatureFlag(): boolean {
  const programId = useProgram().id;
  const { data: AIGenerationFeatureFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AI.CoverGeneration'
  );
  return Boolean(AIGenerationFeatureFlag?.value);
}

type AiContentCoverProps = {
  onCompleted: (value: string) => void;
  blocks: DefinitionBlock[];
  command: SupportedCommands;
  context?: { [key: string]: string };
  post?: Post;
};

type AiContentCoverReturn = {
  eligibleBlocks: DefinitionBlock[];
  status: QueryStatus;
  generate: UseMutateFunction<CreateTaskResponse, unknown, void, unknown>;
  error: string | undefined;
};

export const useAIContentCover = (
  props: AiContentCoverProps
): AiContentCoverReturn => {
  const programId = useProgram().id;
  const { onCompleted, blocks, command, context, post } = props;

  const blocksWithText = React.useMemo(() => {
    return blocks.filter(
      (block) =>
        (block.fields
          .map((f) => f.type)
          .includes('rich-text' || 'simple-text') ||
          block.name.includes('links')) &&
        !(
          block.name.includes('video') &&
          (block.format_data?.flags as string[] | undefined)?.includes(
            'hidden-headline'
          )
        ) &&
        !(
          block.name.includes('image_headlines') &&
          (block.format_data?.flags as string[] | undefined)?.includes(
            'hidden-description'
          )
        )
    );
  }, [blocks]);

  const content = React.useMemo(() => {
    if (blocksWithText.length === 0) {
      return '';
    }
    const result: Array<string> = [];
    blocksWithText.forEach((b) => {
      //  Links are a special case with unique fields
      if (b.name === 'links') {
        (b.field_data.links as [LinkFieldData]).forEach((l) => {
          const title = l.title as PlainTextFieldData;
          if (title && title.value) {
            result.push(title.value);
          }
          const description = l.description as PlainTextFieldData;
          if (description && description.value) {
            result.push(description.value);
          }
        });
        return;
      }

      //  Default behavior for most blocks, grab all the data available
      const markup = b.field_data.markup as RichTextFieldData;
      if (markup && markup.value) {
        result.push(markup.value);
      }
      const title = b.field_data.title as SimpleTextFieldData;
      if (title && title.value) {
        result.push(title.value);
      }
      const description = b.field_data.description as RichTextFieldData;
      if (description && description.value) {
        result.push(description.value);
      }
      const text = b.field_data.text as RichTextFieldData;
      if (text && text.value) {
        result.push(text.value);
      }
    });
    return result.join('. ');
  }, [blocksWithText]);

  const {
    createTask: generate,
    status: athenaOutputQueryStatus,
    error,
  } = useAthenaTask({
    createTaskFn: () =>
      createContentCoverAthenaTask({
        programId,
        content,
        command,
        contentId: post?.content.id,
        ...context,
      }),
    outputIdSelector: (data) => data.outputId,
    onSuccess: (data) => {
      if (data.status === 'complete') {
        onCompleted(data.value);
      }
    },
  });

  return {
    eligibleBlocks: blocksWithText,
    status: athenaOutputQueryStatus,
    generate,
    error: error instanceof Error ? error.message : undefined,
  };
};
