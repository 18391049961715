import * as React from 'react';
import { PreviewablePost, usePreview } from 'hooks/content-blocks';
import { useUniqueId } from 'hooks/useUniqueId';
import { Targets } from 'models/publisher/block';
import styles from './post-previews.module.css';

export const AutoSized: React.FC<{
  post: PreviewablePost;
}> = ({ post }) => {
  const [width, setWidth] = React.useState(60); // kinda the min-width needed
  const uuid = useUniqueId();
  const scale = width / 800;
  const [height, setHeight] = React.useState(width);
  const { html } = usePreview(post, Targets.web);
  const wrapper = React.useRef<HTMLDivElement>(null);
  const iframe = React.useRef<HTMLIFrameElement>(null);
  const [written, setWritten] = React.useState(false);
  const ready = iframe.current;

  React.useEffect(() => {
    if (wrapper.current && wrapper.current.parentElement) {
      const next = wrapper.current.parentElement.getBoundingClientRect().width;
      setWidth(next);
    }
  }, [wrapper]);

  React.useEffect(() => {
    if (html.length && ready && iframe.current?.contentDocument && !written) {
      iframe.current.style.transform = `scale(${scale})`;
      iframe.current.style.transformOrigin = `top left`;
      iframe.current.contentDocument.write(html);
      iframe.current.contentDocument.body.style.overflow = 'hidden';
      iframe.current.style.visibility = `visible`;
      setWritten(true);
      const doc = iframe.current.contentDocument;
      setHeight(doc.body.getBoundingClientRect().height);
      for (let i = 0; i < doc.images.length; i += 1) {
        const image = iframe.current.contentDocument.images[i];
        image.onload = () => {
          if (doc) setHeight(doc.body.getBoundingClientRect().height);
        };
      }
    }
  }, [scale, html, written, ready]);

  return (
    <div
      ref={wrapper}
      className={styles.iframe}
      style={{ height: `${height * scale}px`, width: `${width}px` }}
    >
      {process.env.REACT_APP_CYPRESS_ENABLED ? (
        <>preview</>
      ) : (
        <iframe
          title={uuid}
          id={uuid}
          ref={iframe}
          width={800}
          height={height}
        />
      )}
    </div>
  );
};
