import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Audience } from 'models/audience';
import { PageTemplate } from 'DesignSystem/Layout/Pages';
import { AudienceBuilder } from '../../Editors/AudienceBuilder';

export const AudienceBuilderPage: React.FC<RouteComponentProps<{
  groupId?: string; // supply if editing an existing audience
  onSave?: (data: Audience) => void; // optional, called on successful save
  query?: string; // optional, initial query when creating a new audience
}>> = ({ groupId, onSave, query }) => (
  <PageTemplate noSiteHeader title="Audiences">
    <AudienceBuilder id={groupId} onSave={onSave} query={query} />
  </PageTemplate>
);
