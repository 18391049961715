import * as React from 'react';
import cx from 'classnames';
import { useQueryClient } from 'react-query';
import { Box, FormModal } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { fetchLiquidVariables } from 'services/api-liquid-variables';
import { PersonalizedFieldsProcessingErrors } from 'services/api-personalized-fields';
import {
  usePersonalizedFieldsFileQuery,
  useUpdatePersonalizedFieldsFile,
  useProcessPersonalizedFieldsFile,
} from 'hooks/personalized-fields';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import { MAIcon } from 'shared/MAIcon';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { ConfirmFile } from './ConfirmFile';
import { UpdateModalMode, FileSettings } from './FileSettings';
import { ProcessingErrors } from './ProcessingErrors';
import styles from '../styles.module.css';

export type UpdateModalResult = {
  result: PersonalizedFieldsFile;
};
export type UpdateModalConfig = {
  type: 'update';
  file: PersonalizedFieldsFile;
};

const ResetButton: React.FC<{ onRemove: () => void }> = ({ onRemove }) => {
  const [deleting, setDeleting] = React.useState(false);
  return (
    <Box className={cx(styles.ResetContainer, { [styles.Deleting]: deleting })}>
      {deleting && (
        <Button layoutOnly label="cancel" onClick={() => setDeleting(false)} />
      )}
      <Button
        secondary
        compact={deleting}
        icon={deleting ? <MAIcon name="delete" /> : null}
        label={deleting ? 'Confirm Reset' : 'Reset'}
        onClick={() => (deleting ? onRemove() : setDeleting(true))}
      />
    </Box>
  );
};

export const fileStateChanged = (
  old: PersonalizedFieldsFile | null | undefined,
  current: PersonalizedFieldsFile | null
): boolean =>
  current !== null &&
  (old == null ||
    old.state !== current.state ||
    old.idType !== current.idType ||
    old.expires !== current.expires ||
    old.attributes.some(
      ({ id, active, employeeId }) =>
        !current.attributes.some(
          ({ id: i, active: a, employeeId: e }) =>
            id === i && active === a && employeeId === e
        )
    ));

export const UpdateFileModal: React.FC<{
  file: PersonalizedFieldsFile;
  onCancel: (file?: PersonalizedFieldsFile) => void;
  onSubmit: (result: { result: PersonalizedFieldsFile }) => void;
}> = ({ file, onCancel, onSubmit }) => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();
  const { id: campaignId, setLiquidVariables } = usePublisher();
  const { setFlashMessage } = useFlashMessage();

  const err = React.useCallback(
    (message: string) => {
      setFlashMessage({ severity: 'error', message });
    },
    [setFlashMessage]
  );

  const [queryKey, setQueryKey] = React.useState<string>('temp');
  const [mode, setMode] = React.useState<UpdateModalMode>('new');
  const [settings, setSettings] = React.useState<PersonalizedFieldsFile>();
  const [errors, setErrors] = React.useState<
    PersonalizedFieldsProcessingErrors
  >();

  const [spinner, setSpinner] = React.useState(false);
  const { processFile, processingErrors } = useProcessPersonalizedFieldsFile();
  const { data } = usePersonalizedFieldsFileQuery({
    fileId: file.id,
    interval: 2000,
    enabled: mode === 'waiting',
    key: queryKey,
  });

  const fileState = React.useRef<PersonalizedFieldsFile>();
  React.useEffect(() => {
    if (fileStateChanged(fileState.current, file)) {
      if (file.state === 'created' && !file.idType) {
        setMode('new');
      } else if (file.state === 'in_progress' || file.state === 'created') {
        setMode('waiting');
        setSpinner(true);
      } else if (file.state === 'failed') {
        setMode('error');
        (async () => setErrors(await processingErrors(file.id)))();
      } else if (file.state === 'complete') {
        setMode(file.expires ? 'complete' : 'confirm');
      }
      setSettings(file);
      setQueryKey(`pffile-${file.id}-polling`);
      fileState.current = file;
    }
  }, [fileState, file, processingErrors]);

  const dataState = React.useRef('');
  const dataUpdatedAt = React.useRef('');
  React.useEffect(() => {
    if (mode === 'waiting') {
      dataState.current = dataState.current || file.state;
      dataUpdatedAt.current = dataUpdatedAt.current || file.updatedAt;
      if (
        data &&
        (data.state === 'complete' || data.state === 'failed') &&
        (dataState.current !== data.state ||
          dataUpdatedAt.current !== data.updatedAt)
      ) {
        if (data.state === 'failed') {
          setMode('error');
          (async () => setErrors(await processingErrors(file.id)))();
        } else {
          setMode('new');
        }
        setSpinner(false);
        onSubmit({ result: data });
        dataState.current = '';
        dataUpdatedAt.current = '';
      }
    }
  }, [
    mode,
    data,
    file.id,
    file.state,
    file.updatedAt,
    dataState,
    dataUpdatedAt,
    onSubmit,
    processingErrors,
  ]);

  const updateSettings = (updates: Partial<PersonalizedFieldsFile>) => {
    setSettings((current) => current && { ...current, ...updates });
  };

  const onUpdateComplete = React.useCallback(async () => {
    if (settings?.expires) {
      setFlashMessage({
        severity: 'info',
        message: file.expires
          ? 'File settings updated'
          : 'File upload complete',
      });
      onSubmit({ result: settings as PersonalizedFieldsFile });
      try {
        const liquidVarsData = await fetchLiquidVariables(
          programId,
          campaignId
        );
        if (setLiquidVariables) setLiquidVariables(liquidVarsData);
      } catch (error) {
        err('Could not get updated variables.');
      }
    } else {
      setSpinner(true);
      try {
        queryClient.invalidateQueries(queryKey);
        await processFile(file.id);
        setMode('waiting');
      } catch (e) {
        setSpinner(false);
        err('Could not process file. Please try again.');
      }
    }
  }, [
    err,
    file,
    onSubmit,
    processFile,
    queryClient,
    queryKey,
    setFlashMessage,
    settings,
    programId,
    campaignId,
    setLiquidVariables,
  ]);

  const { updateFile } = useUpdatePersonalizedFieldsFile({
    onSuccess: onUpdateComplete,
    onError: ({ message }) => err(message),
  });

  const onFormCancel = React.useCallback(() => {
    if (settings && spinner) onCancel(settings);
    else onCancel();
  }, [settings, spinner, onCancel]);

  const onFormSubmit = React.useCallback(() => {
    if (file && settings) {
      const messages = [];
      if (!settings.attributes.some(({ employeeId }) => employeeId))
        messages.push('Select an identifier column to continue.');
      if (!settings.idType)
        messages.push('Select an identifier type to continue.');
      if (
        !settings.attributes.some(
          ({ active, employeeId }) => active && !employeeId
        )
      )
        messages.push(
          'Set at least one column active, other than the identifier, to continue.'
        );
      if (messages.length) {
        err(messages.join('\n'));
      } else if (settings) {
        if (mode === 'confirm' && !settings.expires) {
          settings.expires = { indefinite: true };
        }
        setErrors([]);
        updateFile(settings);
      }
    }
  }, [file, settings, err, mode, updateFile]);

  const onRemove = React.useCallback(() => {
    onSubmit({ result: { ...file, state: 'discarded' } });
  }, [file, onSubmit]);

  const formProps = React.useMemo(() => {
    let entityText = 'Upload Dataset File';
    let submitLabel = 'Continue';
    let secondaryButton = (
      <Button secondary label="Close" onClick={onFormCancel} />
    );
    switch (mode) {
      case 'confirm':
        submitLabel = 'Confirm';
        break;
      case 'complete':
        entityText = 'Edit Dataset File';
        submitLabel = 'Save';
        break;
      case 'error':
        submitLabel = 'Try Again';
        secondaryButton = <ResetButton onRemove={onRemove} />;
        break;
      default:
    }
    return { entityText, submitLabel, secondaryButton };
  }, [mode, onFormCancel, onRemove]);

  return (
    <FormModal
      actionText=""
      entityText={formProps.entityText}
      submitLabel={formProps.submitLabel}
      secondaryButton={formProps.secondaryButton}
      contentPadding="0"
      disabled={mode === 'waiting'}
      onSubmit={onFormSubmit}
      onCancel={onFormCancel}
    >
      <FileSettings file={file} mode={mode} onChange={updateSettings}>
        {(spinner || mode === 'confirm') && (
          <Box className={styles.Overlay}>{spinner && <LoadingSpinner />}</Box>
        )}
      </FileSettings>
      {errors?.length ? <ProcessingErrors file={file} errors={errors} /> : null}
      {file.state === 'complete' && (
        <ConfirmFile
          mode={mode}
          file={file}
          onChange={(expires) => updateSettings({ expires })}
        />
      )}
    </FormModal>
  );
};
