import * as React from 'react';

export const OPENED = true as const;
export const CLOSED = false as const;

export type Visibility = typeof OPENED | typeof CLOSED;
export type State<T> = { source: T; visibility: Visibility };
export type Config<T> = Array<Pick<Item<T>, 'name'> & { source: T }>;
export type Item<T> = {
  name: string;
  onClick: () => void;
  source: T;
  component?: React.ReactNode;
};
export type Menu<T = string> = Item<T>[];
export type MenuIface<T> = {
  menu: Menu<T>;
  source: T;
  visibility: Visibility;
  open: (source?: T) => void;
  close: () => void;
};

export function useMenu<T>(
  items: Config<T>,
  fn: (source?: T) => void
): Menu<T> {
  return React.useMemo(
    () =>
      items.map(({ name, source }) => ({
        name,
        source,
        onClick: () => fn(source),
      })),
    [items, fn]
  );
}

export function useSourceMenu<T>(
  items: Config<T>,
  initial: State<T>
): MenuIface<T> {
  const [{ source, visibility }, setState] = React.useState(initial);
  const open = React.useCallback(
    (newValue?: T) =>
      setState({ source: newValue || source, visibility: OPENED }),
    [source]
  );
  const close = React.useCallback(() => {
    setState({ source, visibility: CLOSED });
  }, [source]);
  const menu = useMenu(items, open);
  return { source, visibility, open, close, menu };
}

export const EXTERNAL = 'external' as const;
export const LIBRARY = 'library' as const;
export type Source = typeof EXTERNAL | typeof LIBRARY;

export const MENU: Config<Source> = [
  { name: 'URL', source: EXTERNAL },
  { name: 'Content Library', source: LIBRARY },
];

export function useLinksSourceMenu(
  initial: State<Source> = { source: EXTERNAL, visibility: CLOSED }
): MenuIface<Source> {
  return useSourceMenu(MENU, initial);
}
