import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { FieldInput, Input } from 'DesignSystem/Form';
import { FieldLegend } from '../../shared/FieldLegend';
import { detectLanguageDirection } from '../../../../LangDetect';

export const AltText: React.FC<{
  value?: string;
  name?: string;
  onChange(value: string): void;
  tooltipText?: string;
}> = ({ value = '', onChange, name, tooltipText }) => {
  const label = name || 'Alt Text';
  const tooltip =
    tooltipText || 'Describe your image to improve accessibility.';
  return (
    <FieldInput
      legend={<FieldLegend>{label}</FieldLegend>}
      htmlFor="block-field-image-altText"
    >
      <Input
        block
        id="block-field-image-altText"
        value={value}
        placeholder={label}
        onChange={onChange}
        dir={detectLanguageDirection(value)}
      />
      <Type.Caption block>{tooltip}</Type.Caption>
    </FieldInput>
  );
};
