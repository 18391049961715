import React from 'react';
import { ContributorTopicType, Topic } from 'models/topic';
import { OptionType } from 'hooks/common';
import debouncePromise from 'debounce-promise';
import { fetchTopics } from 'services/api-topics';
import { CheckListSelect } from 'App/Program/Editors/AudienceBuilder/ValueSelect/CheckListSelect';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import { useProgram } from 'contexts/program';

export const ContributorTopicSelect: React.FC<{
  userTopics: ContributorTopicType[];
  onChange: (value: Topic[], topicsById: { [key: string]: Topic }) => void;
}> = ({ userTopics, onChange }) => {
  const { id: programId } = useProgram();
  const { data: allTopics } = useTopicsInfiniteQuery({
    programId,
    includeDefault: false,
    status: '',
  });
  const topics = allTopics.filter((topic) => !topic.default && !topic.archived);
  const topicsById: { [key: string]: Topic } = topics.reduce(
    (sac, topic) => ({
      ...sac,
      [topic.id]: topic,
    }),
    {}
  );

  const options: OptionType[] = topics.map((topic: Topic) => ({
    label: topic.name,
    value: topic.id.toString(),
  }));

  const selectedTopics: OptionType[] = userTopics.map(
    (topic: ContributorTopicType) => ({
      label: topic.name,
      value: topic.id.toString(),
    })
  );

  const handleChange = (topicIds: string[]) => {
    const selections: Topic[] = topicIds.map((topicId) => topicsById[topicId]);
    onChange(selections, topicsById);
  };

  const loadOptions = debouncePromise(async (searchTerm: string) => {
    return fetchTopics({
      programId,
      includeDefault: false,
      search: searchTerm,
      status: '',
    }).then(({ data: aTopics }) => {
      return aTopics
        .filter((topic) => !topic.default && !topic.archived)
        .map((topic: Topic) => ({
          label: topic.name,
          value: topic.id.toString(),
        }));
    });
  }, 300);

  return (
    <CheckListSelect
      defaultOptions={options}
      value={selectedTopics}
      loadOptions={loadOptions}
      onChange={handleChange}
    />
  );
};
