import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Checkbox } from 'shared/Checkbox';

export const AutoCropping: React.FC<{
  autoCropping: boolean;
  canEdit: boolean;
  enableAutoCropping: () => void;
  disableAutoCropping: () => void;
}> = ({ enableAutoCropping, disableAutoCropping, canEdit, autoCropping }) => (
  <Flex>
    <strong>Automatically crop images</strong>
    <Checkbox
      disabled={!canEdit}
      onSelect={enableAutoCropping}
      onDeselect={disableAutoCropping}
      checked={autoCropping}
    />
  </Flex>
);
