import { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { useAudiencesInfiniteQuery } from 'hooks/audience';
import { Audience, ChannelAudience } from 'models/audience';
import { useDebounce } from 'hooks/useDebounce';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { removeStandardRoleAudiences } from 'utility/audienceUtil';
import { ListReturnType } from './useTopicsList';
import { audienceKey, useRecentAudiences } from './useRecentAudiences';

export const useAudienceList = (
  value: Array<Audience>,
  search: string,
  onChange: (value: Array<Audience>) => void,
  channelAudienceGroups: Array<ChannelAudience>
): ListReturnType => {
  const { id: programId } = useProgram();
  const { data: recentAudiences, updateCache } = useRecentAudiences(3);
  const {
    data: featureFlagsData,
    isLoading: isFeatureFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Audience.List.HideUserCount');

  const {
    isLoading,
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAudiencesInfiniteQuery({
    programId,
    search: useDebounce(search),
    statuses: 'enabled',
    hideUserCount: !!featureFlagsData?.value,
    isFeatureFlagLoading,
  });
  const showStandardRoles = !!useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowStandardRoles'
  ).data?.value;
  let filteredAudiences = data;
  if (!showStandardRoles) {
    filteredAudiences = removeStandardRoleAudiences(filteredAudiences);
  }

  const audienceByRowId = useMemo(() => {
    const map: { [key: string]: Audience } = {};
    filteredAudiences
      .concat(channelAudienceGroups as Audience[])
      .forEach((audience) => {
        map[audienceKey(audience)] = audience;
      });
    // retain the currently selected topics
    value.forEach((audience) => {
      if (!map[audienceKey(audience)]) map[audienceKey(audience)] = audience;
    });
    // append any cached topics from recently used
    recentAudiences.forEach((audience) => {
      if (!map[audienceKey(audience)]) map[audienceKey(audience)] = audience;
    });
    return map;
  }, [recentAudiences, filteredAudiences, value, channelAudienceGroups]);

  const selectedIds = value.map((audience) => audienceKey(audience));

  const onSelectedIdsChange = useCallback(
    (ids: string[]) =>
      onChange(
        ids.map((rowId) => ({ ...audienceByRowId[rowId], hidden: false }))
      ),
    [onChange, audienceByRowId]
  );

  const handleRemove = useCallback(
    (index: number) => {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(index, 1);
      onSelectedIdsChange(newSelectedIds);
    },
    [onSelectedIdsChange, selectedIds]
  );

  const selectedValues = useCallback(
    () =>
      selectedIds.map((id) => {
        const audience = audienceByRowId[id];
        return `${audience.name} (${audience.totalUsers})`;
      }),
    [audienceByRowId, selectedIds]
  );

  const audienceRowIds = filteredAudiences
    .concat(channelAudienceGroups as Audience[])
    .map((audience) => audienceKey(audience));

  return {
    data: filteredAudiences,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    selectedValues,
    handleRemove,
    onSelectedIdsChange,
    selectedIds,
    dataByRowId: audienceByRowId,
    dataRowIds: audienceRowIds,
    type: 'audience',
    updateCache,

    search,
  };
};
