import * as React from 'react';
import styles from './channel.module.css';

export const Progress: React.FC<{ value: number; radius: number }> = ({
  value,
  radius,
}) => {
  const circumference = React.useMemo(() => radius * 2 * Math.PI, [radius]);
  const offset = React.useMemo(
    () => circumference - (value / 100) * circumference,
    [circumference, value]
  );

  return (
    <svg viewBox="0 0 100 100">
      <circle className={styles.chartBackground} r={radius} cx="50" cy="50" />
      <circle
        className={styles.chart}
        style={{ strokeDasharray: circumference, strokeDashoffset: offset }}
        r={radius}
        cx="50"
        cy="50"
      />
      <text
        className={styles.chartText}
        x="50%"
        y="50%"
        dy=".3em"
      >{`${value}%`}</text>
    </svg>
  );
};
