import React from 'react';
import { Section } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Checkbox } from 'shared/Checkbox';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { Subheading } from 'DesignSystem/Typography';
import { TextInput } from 'shared/TextInput';
import { useProgram } from 'contexts/program';
import { Program } from 'models/program';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useActions } from './useAction';
import { TextEditor } from './TextEditor';

import styles from './legal.module.css';

export const EditPolicyPage: React.FC<RouteComponentProps> = () => {
  const program = useProgram();
  const { update } = useActions();
  const [data, setData] = React.useState({
    ...program,
    privacyPolicyFormat: program.privacyPolicyFormat || 'link',
  });

  function onChange(updatedData: Partial<Program>) {
    setData({ ...data, ...updatedData });
  }

  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        title="Privacy Policy"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'Legal', to: '..' },
          { label: 'Privacy Policy' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <Icon name="SaveSuccess" />,
            onClick: () => {
              update(data);
            },
          },
        ]}
      >
        <Section>
          <Subheading bold>Privacy Policy</Subheading>
          <div className={styles.Checkbox}>
            <Checkbox
              checked={data.privacyPolicyFormat === 'link'}
              onSelect={() => {
                onChange({ privacyPolicyFormat: 'link' });
              }}
              type="radio"
              id="link-opt"
            />
            <label htmlFor="link-opt" className={styles.CheckboxLabel}>
              Link to URL
            </label>
          </div>
          <div className={styles.Checkbox}>
            <Checkbox
              checked={data.privacyPolicyFormat === 'text'}
              onSelect={() => {
                onChange({ privacyPolicyFormat: 'text' });
              }}
              type="radio"
              id="text-opt"
            />
            <label htmlFor="text-opt" className={styles.CheckboxLabel}>
              Paste as Text
            </label>
          </div>
          <div className={styles.Inputs}>
            {data.privacyPolicyFormat === 'link' && (
              <TextInput
                className={styles.textField}
                placeholder="Type URL"
                value={data.privacyPolicyLink}
                onChange={(value) => onChange({ privacyPolicyLink: value })}
              />
            )}
            {data.privacyPolicyFormat === 'text' && (
              <div>
                <TextEditor
                  text={data.privacyPolicy}
                  onChange={(value) => onChange({ privacyPolicy: value })}
                />
              </div>
            )}
          </div>
        </Section>
      </FormPage>
    </WithPermission>
  );
};
