import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Modal } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Close } from 'shared/icons';
import styles from './journey-list-page.module.css';
import { JourneyTemplatesInfiniteList } from './JourneyTemplatesInfiniteList';

type NewJourneyModalType = {
  onClose: () => void;
};

export const NewJourneyModal: React.FC<
  RouteComponentProps & NewJourneyModalType
> = ({ onClose }) => {
  return (
    <Modal innerPadding={0} width={1272} maxHeight={780} name="new-journey">
      <Flex column>
        <Flex className={styles.modalHeader} spread>
          <span>New Journey</span>
          <Button
            onClick={onClose}
            compact
            layoutOnly
            icon={<Close />}
            title="Close"
          />
        </Flex>
        <JourneyTemplatesInfiniteList />
      </Flex>
    </Modal>
  );
};
