import React from 'react';
import { MetabaseReport, PlaceHolderReport } from 'models/insight/Report';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useProgram } from 'contexts/program';
import { Filter } from 'models/insight/Filter';
import {
  useMetabaseReport,
  useRelatedReports,
  fetchEmbedUrl,
  auditMetabaseReportDownload,
} from 'hooks/insights/useInsightsApi';
import {
  FilterStateWithSingleValuesObj,
  ReportFetchProps,
} from 'services/api-insights';
import { FiltersStateProvider } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { useParsedQueryParams } from 'hooks/insights/useInsightsQueryParams';
import { DeepLinkedParamsType } from 'App/Program/Main/Insight/contexts/filtersStateReducer';
import { QueryResponse } from 'hooks/common';

// const METABASE_UPDATE_DEBOUNCE_DELAY_MS = 1000;
type FilterQueryParamType = { [key: string]: string[] | string };
export type ReportContextType = {
  reportId: number;
  userLiked: boolean;
  filters?: Filter[];
  title: string | null | undefined;
  description: string | null | undefined;
  metabaseEmbedUrl?: string;
  old_studio_url?: string;
  metabaseReportQuery?: QueryResponse<MetabaseReport>;
  relatedReports?: MetabaseReport[];
  deepLinkedParams: DeepLinkedParamsType;
  showReset: boolean;
  setShowReset: (arg0: boolean) => void;
  auditDownload: (token: string) => void;
};
export type ReportContextPropsType = {
  reportId: string;
};

export const ReportContext = React.createContext<ReportContextType>({
  title: undefined,
  description: undefined,
  reportId: 0,
  userLiked: false,
  deepLinkedParams: new Map(),
  showReset: false,
  setShowReset: () => ({}),
  auditDownload: () => {},
});

/**
 * This hook handles data processing for the reports page
 * data for this page can come from two sources:
 * - placeholder reports come from the collection view and have basic info about report
 * - metabase reports come from the api and have more data
 * we extract the info from both sources and return the first one available, preferring the latter
 */
export const ReportProvider: React.FC<RouteComponentProps<
  ReportContextPropsType
>> = ({ children, reportId }) => {
  const { id: programId } = useProgram();
  const reportApiParams: ReportFetchProps = {
    programId,
    reportId,
  };

  // The filter state is updated by the child `FilterStateContext`
  // the state is passed here as a regular object and will be used to update the
  // metabase embed url (which will render a new view based on the filter state)
  // Note that updating this will trigger a rerender of all child views on this context
  // be careful to test thoroughly new `React.useEffect()` calls such that they don't
  // trigger an infinite rerender
  // Note also that we use the debounced filter state from the filter context
  // this prevents unnecessary calls to update the embed url
  const [filterState, setFilterState] = React.useState<
    FilterStateWithSingleValuesObj | undefined
  >();

  const [showReset, setShowReset] = React.useState(false);

  // this function is invoked with a debounce on the filter context
  // this prevents unnecessary requests to update the metabase iframe
  const updateFilterState = React.useCallback(
    (newState?: FilterStateWithSingleValuesObj) => {
      if (newState === undefined) return;
      setFilterState(newState);
    },
    [setFilterState]
  );

  // get any query params and parse to an object
  // TODO: put this in a separate hook
  const { params } = useParsedQueryParams<FilterQueryParamType>();
  const deepLinkedParams: DeepLinkedParamsType = React.useMemo(() => {
    const deepLinkedParamsMap: DeepLinkedParamsType = new Map();
    // convert object of arrays to a map of sets
    Object.keys(params).forEach((key) => {
      const valuesSet: Set<string> = new Set();
      ((params[key] as string[] | undefined) || []).forEach((value) =>
        valuesSet.add(value)
      );
      deepLinkedParamsMap.set(key, valuesSet);
    });
    return deepLinkedParamsMap;
  }, [params]);

  // placeholder report received as a Reach Router state object from report card component
  // coercing type to Report. Here is a more elegant but tedious way to declare the state type
  // https://github.com/reach/router/issues/414#issuecomment-683827688
  const { state } = useLocation();

  // technically a Metabase report can also be passed in the related reports view
  // but for simplicity we will treat it as a placeholder report
  const placeholderReport = state as PlaceHolderReport;

  // fetch the metabase report details
  const metabaseReportQuery = useMetabaseReport(reportApiParams);

  // fetch the related reports
  const relatedReportsQuery = useRelatedReports(reportApiParams);

  // extract values from the report objects
  const title = metabaseReportQuery?.data?.title || placeholderReport?.title;

  const numericReportId =
    metabaseReportQuery?.data?.id || placeholderReport?.id;

  const description =
    metabaseReportQuery?.data?.description || placeholderReport?.description;

  const filters: Filter[] = React.useMemo(
    () => metabaseReportQuery?.data?.filters || [],
    [metabaseReportQuery]
  );

  const auditDownload = (token: string) => {
    auditMetabaseReportDownload(token);
  };

  const shouldUpdateUrl = React.useRef<boolean>(false);

  /*
   * the embed url for metabase iframe can come from 2 sources and be updated at different times
   * 1. it can come from the initial /report payload via metabaseReportQuery?.data?.embedUrl
   *   however, this value won't be set during the context load, we need it set in a useEffect()
   * 2. it can come from /embed payload after filters change. see second useEffect() below
   *  */
  const [metabaseEmbedUrl, setMetabaseEmbedUrl] = React.useState(
    metabaseReportQuery?.data?.embedUrl // this is often undefined initially
  );

  // 1. setting the embed url via the /report api data. Consider this the "default" report
  //    this is only done if the url has not been set yet
  //    this is only relevant in reports that DO NOT have any filters, including date range
  //    otherwise, the embed url won't be picked up and the report won't load
  //    see https://firstup-io.atlassian.net/browse/ME-3808
  //    ------- UPDATE nov 17, 2022 -----------
  //    We manually add a timeout of 8 seconds on this logic because settings the
  //    `metabaseEmbedUrl` state variable triggers a rerender of the metabase iframe
  //    We wait 8 seconds to allow the iframe update triggered by the filterstate
  //    to finish first. If a metabaseUrl is already set, then we do NOT want to run this
  React.useEffect(() => {
    const url = metabaseReportQuery?.data?.embedUrl;
    const initialEmbedUrlTimeoutHandler = setTimeout(() => {
      if (url && !metabaseEmbedUrl) setMetabaseEmbedUrl(url);
    }, 8000);

    return () => clearTimeout(initialEmbedUrlTimeoutHandler);
  }, [metabaseReportQuery, metabaseEmbedUrl, setMetabaseEmbedUrl]);

  // 2. setting the embed url via the /embed api as filters changes from...
  //      either deep linked params or manual user selection of filters
  //    This is the primary way to update the `metabaseEmbedUrl`, which triggers
  //      a rerender of the metabase iframe. As mentioned in part (1), once a
  //      value has been set, we will no longer use the initial default url
  //      that comes from the report API
  React.useEffect(() => {
    const updateUrl = async () => {
      const response = await fetchEmbedUrl({
        programId,
        reportId,
        filterState,
      });

      const filterStateTriggeredEmbedUrlTimeoutHandler = setTimeout(() => {
        setMetabaseEmbedUrl(response?.data?.embed_src);
      }, 500);

      return () => {
        clearTimeout(filterStateTriggeredEmbedUrlTimeoutHandler);
      };
    };
    if (shouldUpdateUrl.current) {
      updateUrl();
      shouldUpdateUrl.current = false;
    }
  }, [programId, reportId, filterState, setMetabaseEmbedUrl]);

  React.useEffect(() => {
    shouldUpdateUrl.current = true;
  }, [filterState]);

  const userLiked =
    metabaseReportQuery?.data?.userLiked !== undefined
      ? metabaseReportQuery.data.userLiked
      : placeholderReport?.userLiked;

  // Manually override filter params. Should keep these to a minimum
  const customizedFilters = React.useMemo(() => {
    return filters?.map((filter) => {
      // Channels is renamed to Topics. this will only be a front end change
      // because dojo/metabase refers to this as channels internally
      if (filter.slug.match(/^channel(s?)$/i)) {
        return Object.assign(filter, { label: 'Topics' });
      }

      return filter;
    });
  }, [filters]);

  return (
    <ReportContext.Provider
      value={{
        reportId: numericReportId,
        filters: customizedFilters,
        title,
        description,
        userLiked,
        metabaseEmbedUrl,
        metabaseReportQuery,
        relatedReports: relatedReportsQuery?.data,
        deepLinkedParams,
        showReset,
        setShowReset,
        auditDownload,
      }}
    >
      <FiltersStateProvider
        reportId={reportId}
        allFilters={customizedFilters}
        deepLinkedParams={deepLinkedParams}
        updateDebouncedFilterState={updateFilterState}
      >
        {children}
      </FiltersStateProvider>
    </ReportContext.Provider>
  );
};
