import camelcaseKeys from 'camelcase-keys';
import { bossanovaDomain, prepareQueryString, request } from './api-shared';
import {
  CloudbeesFeatureFlag,
  FeatureFlag,
  UpsertFeatureFlagParams,
} from '../models/feature-flag';

export const fetchProgramCustomizations = async (
  programId: number
): Promise<Array<FeatureFlag> | undefined> => {
  const response = await request(
    `${bossanovaDomain}/programs/${programId}/program_customizations`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((customizations) => camelcaseKeys(customizations, { deep: true }));
  }
  throw new Error(`Error fetching feature flags: ${response.status}`);
};

// basicaly the same as fetchProgramCustomizations, but uses different permissions (main reason this exists) and only returns a specific single flag.
export const fetchUserGeneratedContentTopicLimit = async (
  programId: number
): Promise<FeatureFlag | undefined> => {
  const response = await request(
    `${bossanovaDomain}/programs/${programId}/user_generated_content_topic_limits`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((customization) => camelcaseKeys(customization, { deep: true }));
  }
  throw new Error(
    `Error fetching user generated content topic limit: ${response.status}`
  );
};

export const updateCloudbeesFeatureFlag = async (
  programId: number,
  name: string,
  params: UpsertFeatureFlagParams
): Promise<boolean> => {
  const body = JSON.stringify({
    name,
    ...params,
  });

  const url = `${bossanovaDomain}/programs/${programId}/feature_flags`;
  const response = await request(url, {
    body,
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
    method: 'POST',
  });

  if (response.status === 200) {
    return true;
  }
  throw new Error(`Error posting feature flags: ${response.status}`);
};

export const updateCloudbeesFeatureFlagV2 = async (
  programId: number,
  name: string,
  value: boolean
): Promise<boolean> => {
  const body = JSON.stringify({
    name,
    value,
  });

  const url = `${bossanovaDomain}/v2/programs/${programId}/feature_flags`;
  const response = await request(url, {
    body,
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
    method: 'POST',
  });

  if (response.status === 200) {
    return true;
  }
  throw new Error(`Error posting feature flags: ${response.status}`);
};

export const updateProgramCustomizations = async (
  programId: number,
  featureFlag: FeatureFlag
): Promise<boolean> => {
  const body = JSON.stringify({
    type: featureFlag.type,
    value: featureFlag.value,
  });

  const url = `${bossanovaDomain}/programs/${programId}/program_customizations`;
  const response = await request(url, {
    body,
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
    method: 'POST',
  });

  if (response.status === 200) {
    return true;
  }
  throw new Error(`Error posting feature flags: ${response.status}`);
};

// basicaly the same as updateProgramCustomizations, but uses different permissions (main reason this exists) and only allows operation on a specific flag.
// personally conflicted on whether we should use the FeatureFlag type here for the params, or just accept the value. I marginally perfer this way.
export const updateUserGeneratedContentTopicLimit = async (
  programId: number,
  featureFlag: FeatureFlag
): Promise<boolean> => {
  const body = JSON.stringify({
    value: featureFlag.value,
  });

  const url = `${bossanovaDomain}/programs/${programId}/user_generated_content_topic_limits`;
  const response = await request(url, {
    body,
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
    method: 'POST',
  });

  if (response.status === 200) {
    return true;
  }
  throw new Error(
    `Error posting user generated content topic limit: ${response.status}`
  );
};

export const fetchFeatureFlags = async <T>(
  programId: number,
  flag: string,
  userId?: number
): Promise<FeatureFlag<T>> => {
  const url = `${bossanovaDomain}/programs/${programId}/feature_flags`;
  const queryParams = prepareQueryString({ flag, user_id: userId });

  const response = await request(`${url}?${queryParams}`);
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`Error fetching feature flags: ${response.status}`);
};

export const fetchAllFeatureFlags = async (
  programId: number
): Promise<Array<CloudbeesFeatureFlag>> => {
  const response = await request(
    `${bossanovaDomain}/programs/${programId}/feature_flags/show_all`
  );
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`Error fetching feature flags: ${response.status}`);
};

export const fetchFeatureFlagsV2 = async <T>(
  programId: number,
  flag: string,
  userId?: number,
  params?: Record<string, string | boolean>
): Promise<FeatureFlag<T>> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/feature_flags`;
  const queryParams = prepareQueryString({ flag, user_id: userId, ...params });

  const response = await request(`${url}?${queryParams}`);
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`Error fetching feature flags: ${response.status}`);
};
