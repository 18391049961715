import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { Program } from 'models/program';
import { SignInConfig } from 'models/signInConfig';
import { bossanovaDomain, request } from './api-shared';

export type ProgramData = {
  id: number;
  name?: string;
  iconImageUrl?: string;
  secondaryColor?: string;
  slug?: string;
  brand_slug?: string;
};

export const fetchProgram = async (programId: number): Promise<ProgramData> => {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((program) => camelcaseKeys(program, { deep: true }));
  }
  throw new Error(`Error fetching program: ${response.status}`);
};

export const updateBrand = async (
  programId: number,
  data: Program
): Promise<ProgramData> => {
  const response = await request(`${bossanovaDomain}/brands/${programId}`, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response
      .json()
      .then((program) => camelcaseKeys(program, { deep: true }));
  }
  throw new Error(`Error updating brand: ${response.status}`);
};

export const updateProgram = async (
  programId: number,
  data: Program
): Promise<ProgramData> => {
  const response = await request(`${bossanovaDomain}/programs/${programId}`, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response
      .json()
      .then((program) => camelcaseKeys(program, { deep: true }));
  }
  throw new Error(`Error updating program: ${response.status}`);
};

export const switchProgram = async (
  programId: number,
  targetProgramId: number
): Promise<ProgramData> => {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/switch/${targetProgramId}`,
    {
      method: 'PUT',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response
      .json()
      .then((program) => camelcaseKeys(program, { deep: true }));
  }
  throw new Error(`Error switching program: ${response.status}`);
};

export type Update = Record<string, unknown>;

export type ParsedAuditEvent = {
  id: string | number;
  type: string;
  attributes: {
    action: string;
    advocateId: number;
    authorName?: string;
    canEdit: boolean;
    component: string;
    kind: string;
    message: string; // a hmtl tag
    messages?: string[];
    occurredAt: string;
    recordId: number;
    recordType: string;
    updates: Update[]; // Object
    userAvatarUrl: string; // url
  };
};
export type ProgramActivitiesLinks = {
  next?: string;
  last?: string;
  self: string;
};
export type ProgramActivities = {
  data: Array<ParsedAuditEvent>;
  links: ProgramActivitiesLinks;
};

export type FetchProgramActivitiesProps = {
  programId: number;
  recordId?: number;
  page?: number;
  pageSize?: number;
  kind?: string[];
  includeSuperadminActivity: boolean;
};
export const fetchProgramActivities = async ({
  programId,
  recordId,
  page,
  pageSize,
  kind,
  includeSuperadminActivity,
}: FetchProgramActivitiesProps): Promise<ProgramActivities> => {
  const query = qs.stringify(
    snakecaseKeys({
      page,
      perPage: pageSize,
      record_id: recordId,
      kind,
      include_superadmin_activity: includeSuperadminActivity,
    }),
    { arrayFormat: 'brackets', encodeValuesOnly: false }
  );
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/activities?${query}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((activities) => camelcaseKeys(activities, { deep: true }));
  }

  throw new Error(`Error fetching program activities: ${response.status}`);
};

export const fetchSignInConfig = async (
  programId: number
): Promise<SignInConfig> => {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/sign_in_configuration`
  );
  if (response.status === 200) {
    return response.json().then((data) => camelcaseKeys(data, { deep: true }));
  }
  throw new Error(`Error fetching sign in config: ${response.status}`);
};

export const updateSignInConfig = async (
  programId: number,
  data: SignInConfig
): Promise<SignInConfig> => {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/sign_in_configuration`,
    {
      method: 'PUT',
      body: JSON.stringify(snakecaseKeys(data, { deep: true })),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response
      .json()
      .then((result) => camelcaseKeys(result.data, { deep: true }));
  }
  throw new Error(`Error updating program: ${response.status}`);
};
