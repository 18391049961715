import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Body, Caption } from 'DesignSystem/Typography';
import { FlipSwitch } from 'shared/FlipSwitch';
import { EngagementBoost } from 'models/publisher/engagement-boost';
import { Subsection } from 'DesignSystem/Form';

const ConfigRow: React.FC<{
  title: string;
  description: string;
  checked: boolean;
  dataTest?: string;
  onChange: (value: boolean | undefined) => void;
}> = ({ title, checked, description, dataTest, onChange }) => {
  return (
    <Box style={{ display: 'flex' }}>
      <Box>
        <Body block>
          {title}: <Body bold>{checked ? 'ON' : 'OFF'}</Body>
        </Body>
        <Caption block>{description}</Caption>
      </Box>
      <Box margin={['auto', 23, 'auto', 'auto']}>
        <FlipSwitch dataTest={dataTest} onChange={onChange} on={checked} />
      </Box>
    </Box>
  );
};

export const EngagementBoostDefaultsEdit: React.FC<{
  engBoostDefaults: EngagementBoost;
  setEngBoostDefaults: (val: EngagementBoost) => void;
}> = ({ engBoostDefaults, setEngBoostDefaults }) => {
  const isBoostEnabled = Object.values(engBoostDefaults).some((val) => val);
  const onOptimizedDeliveryChange = (value?: boolean) => {
    setEngBoostDefaults({
      ...engBoostDefaults,
      optimizedDeliveryEnabled: !!value,
    });
  };
  const onAutoRetargetingChange = (value?: boolean) => {
    setEngBoostDefaults({
      ...engBoostDefaults,
      retries: value ? -1 : 0,
    });
  };

  return (
    <Subsection
      title="Engagement Boost"
      description={
        isBoostEnabled
          ? 'Optimize campaign delivery based on audience behavior, preferences, and competing communications'
          : 'Campaign will be sent once at selected publish time'
      }
    >
      <Box margin={[0, 0, 0, 40]}>
        <ConfigRow
          dataTest="optimized-toggle"
          title="Optimized Delivery"
          description={
            engBoostDefaults.optimizedDeliveryEnabled
              ? 'Campaign will be sent to audiences at the best time based on individual viewing habits'
              : 'Campaign will be sent at selected publish time'
          }
          onChange={onOptimizedDeliveryChange}
          checked={engBoostDefaults.optimizedDeliveryEnabled}
        />
        <ConfigRow
          dataTest="retargeting-toggle"
          title="Automatic Re-targeting"
          description={
            engBoostDefaults.retries === -1
              ? 'Campaign will attempt to be re-sent to audiences that have not opened/acknowledged within selected duration'
              : 'Campaign will not have any retargeting attempts'
          }
          onChange={onAutoRetargetingChange}
          checked={engBoostDefaults.retries === -1}
        />
      </Box>
    </Subsection>
  );
};
