import { useProgram } from 'contexts/program';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchAvailableAttributes,
  fetchSelectedAttributes,
  updateSelectedAttributes,
} from 'services/api-searchable-attributes';
import { capitalizeWords } from 'utility/strings';
import { useNavigate } from '@reach/router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFlashMessage } from '../../../../../../contexts/flasher';

interface SearchAttribute {
  name: string;
  label: string;
}

interface State {
  available: SearchAttribute[];
  selected: SearchAttribute[];
  isChanged: boolean;
  loading: boolean;
}

const defaultState: State = {
  available: [],
  selected: [],
  isChanged: false,
  loading: false,
};

function mapAvailableAttributes(names: string[]): SearchAttribute[] {
  return names.map((name) => ({
    name,
    label: capitalizeWords(name.replace(/_/g, ' ')),
  }));
}

function mapSelectedAttributes(
  available: SearchAttribute[],
  selected: string[]
): SearchAttribute[] {
  return selected.reduce((acc, name) => {
    const attr = available.find((a) => a.name === name);
    if (attr) {
      acc.push(attr);
    }
    return acc;
  }, [] as SearchAttribute[]);
}

interface HookReturn extends State {
  handleChange: (selected: SearchAttribute[]) => void;
  handleSave: () => void;
}

function useSearchableAttributes(): HookReturn {
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const { id } = useProgram();
  const key = `searchable_attributes_${id}`;
  const [state, setState] = useState<State>(defaultState);
  const updateSelected = useCallback(
    (attributes: string[]) => updateSelectedAttributes(id, attributes),
    [id]
  );
  const availableResult = useQuery(`${key}_available`, () =>
    fetchAvailableAttributes(id)
  );
  const selectedResult = useQuery(key, () => fetchSelectedAttributes(id));
  const queryClient = useQueryClient();
  const mutation = useMutation(updateSelected, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
  useEffect(() => {
    const delta: Partial<State> = {
      loading: availableResult.isLoading || selectedResult.isLoading,
    };
    if (availableResult.isSuccess) {
      delta.available = mapAvailableAttributes(availableResult.data || []);
    }
    if (selectedResult.isSuccess) {
      delta.selected = mapSelectedAttributes(
        delta.available || [],
        selectedResult.data || []
      );
      delta.isChanged = false;
    }
    if (availableResult.isError || selectedResult.isError) {
      setFlashMessage({
        message:
          'Something went wrong. Please try again later or contact support.',
        severity: 'error',
      });
    }
    setState((prev) => ({ ...prev, ...delta }));
  }, [availableResult, selectedResult, setFlashMessage]);

  useEffect(() => {
    if (mutation.isSuccess) {
      setFlashMessage({
        message: 'Profile search attributes updated successfully.',
        severity: 'info',
      });
      navigate('./');
    } else if (mutation.isError) {
      setFlashMessage({
        message: 'Failed to update profile search attributes.',
        severity: 'error',
      });
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } else if (mutation.isLoading) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
    }
  }, [navigate, setFlashMessage, mutation]);

  const handleChange: HookReturn['handleChange'] = useCallback(
    (selected) => {
      if (selected.length > 3) {
        setFlashMessage({
          message: 'You can only select up to 3 attributes.',
          severity: 'error',
        });
        return;
      }
      setState((prev) => ({ ...prev, selected, isChanged: true }));
    },
    [setFlashMessage]
  );

  const handleSave: HookReturn['handleSave'] = useCallback(() => {
    mutation.mutate(state.selected.map((a) => a.name));
  }, [state.selected, mutation]);

  return {
    ...state,
    handleChange,
    handleSave,
  };
}

export default useSearchableAttributes;
