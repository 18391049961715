import { ImageData } from 'models/image';

export const LOGOS = {
  facebook: {
    domains: ['fb.me', 'facebook.com'],
    image_url:
      'https://assets.socialchorus.com/production/176/images/b9e413ea-8363-4beb-95e5-b76fc9105225.png',
  },
  instagram: {
    domains: ['instagram.com', 'instagr.am'],
    image_url:
      'https://assets.socialchorus.com/production/176/images/8523dec8-8e83-4ee6-8ec6-870f565e3f28.png',
  },
  linkedin: {
    domains: ['linkedin.com', 'lnkd.in'],
    image_url:
      'https://assets.socialchorus.com/production/176/images/99c60a4f-f3d9-47ce-93af-42b3ca772b0c.png',
  },
  twitter: {
    domains: ['t.co', 'twitter.com', 'x.com'],
    image_url:
      'https://lib.onfirstup.com/content_blocks/defaults/x_circle_logo.svg',
  },
  youtube: {
    domains: ['youtube.com', 'youtu.be'],
    image_url:
      'https://assets.socialchorus.com/production/176/images/718283a3-8427-448b-a862-8c00612c17d0.png',
  },
  generic: {
    domains: ['*'],
    image_url:
      'https://lib.onfirstup.com/content_blocks/defaults/img-placeholder.png',
  },
};

export const getSocialNetworkImage = (url = 'https://localhost'): ImageData => {
  const { hostname } = new URL(url);
  const { image_url } = Object.values(LOGOS).find(({ domains }) =>
    domains.find((domain) => domain === '*' || hostname.endsWith(domain))
  ) || { image_url: '' };
  return {
    url: image_url,
    processed: true,
  };
};
