import React from 'react';
import { User } from 'models/user';
import { Topic } from 'models/topic';
import { OptionType } from 'hooks/common';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { IdentityScope } from 'models/identity';
import { GeneralInfo } from './Sections/General';
import { Attributes } from './Sections/Attributes';
import { Topics } from './Sections/Topics';
import { Questions } from './Sections/Questions';
import { Permissions } from './Sections/Permissions';

export const UserMainView: React.FC<{
  data: User;
  identityScopes: IdentityScope[];
  initialIdentityScopes?: IdentityScope[];
  onDataChange: (field: string, value: string) => void;
  onTopicChange: (value: Topic[], topicsById: { [key: string]: Topic }) => void;
  onIdentityScopesChange: (value: IdentityScope[]) => void;
}> = ({
  data,
  identityScopes,
  initialIdentityScopes,
  onDataChange,
  onTopicChange,
  onIdentityScopesChange,
}) => {
  const { id: programId } = useProgram();
  const { permissions } = usePermissions();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );

  const userTopics: OptionType[] = (data.channels || []).map((channel) => ({
    label: channel.name,
    value: channel.channel_id.toString(),
  }));

  return (
    <>
      <div>
        <div>
          <GeneralInfo
            data={data}
            programId={programId}
            onDataChange={onDataChange}
            onTopicChange={onTopicChange}
          />
          {showPermissionsUI?.value &&
            permissions.configureRoleAccess &&
            permissions.confContentAccess && (
              <Permissions
                user={data}
                onDataChange={onDataChange}
                identityScopes={identityScopes}
                initialIdentityScopes={initialIdentityScopes}
                onIdentityScopesChange={onIdentityScopesChange}
              />
            )}
          <Attributes data={data} />
          <Topics data={userTopics} />
          <Questions data={data} />
        </div>
      </div>
    </>
  );
};
