import React, { useState } from 'react';
import { useNavigate } from '@reach/router';
import { DateTime } from 'luxon';
import { User } from 'models/user';
import { Icon } from 'shared/Icon';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import {
  useInviteUsers,
  useActivateUser,
  useDeactivateUser,
  useForgetUser,
  useExportUsers,
  useConfirmUser,
} from 'hooks/user';
import { useFetchContentSubmissionsByUser } from 'hooks/content';
import { ConfirmModal } from 'shared/BulkSelect/Bar/ConfirmModal';
import { ExportsDownload } from 'shared/Exports/User';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlags } from 'contexts/feature-flags';
import styles from './sidebar.module.css';
import ResetPassword from './ResetPassword';

export const Sidebar: React.FC<{
  data: User;
  onDataChange: (field: string, value: string) => void;
}> = ({ data, onDataChange }) => {
  const { id: programId, emailTokenAuthEnabled } = useProgram();
  const { gdprComplianceEnabled } = useFeatureFlags();
  const { setFlashMessage } = useFlashMessage();
  const [exportId, setExportId] = useState(0);
  const [userPostCount, setUserPostCount] = useState(0);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const navigate = useNavigate();
  const {
    permissions: { addImportUsersAccess },
  } = usePermissions();

  const showFlashMessage = (message: string, isError = false): void => {
    setFlashMessage({
      severity: isError ? 'error' : 'info',
      message,
    });
  };

  const { deactivate: deactivateUser } = useDeactivateUser(programId, {
    onSuccess: () => showFlashMessage('User deactivated.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { activate: activateUser } = useActivateUser(programId, {
    onSuccess: () => showFlashMessage('User activated.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { forget: forgetUser } = useForgetUser(programId, {
    onSuccess: () =>
      showFlashMessage('Forgetting user. This might take a while.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { invite: inviteUser } = useInviteUsers(programId, {
    onSuccess: () => showFlashMessage('User invited.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { doExport: exportUser } = useExportUsers(programId, {
    onSuccess: (id) => {
      setExportId(() => Number(id));
    },
    onError: (err) => showFlashMessage(err, true),
  });

  const { confirm: confirmUser } = useConfirmUser(programId, {
    onSuccess: () => showFlashMessage('User confirmed successfully.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { fetch: fetchSubmissions } = useFetchContentSubmissionsByUser(
    programId,
    {
      onSuccess: (response: string) => {
        setUserPostCount(() => Number(response));
      },
    }
  );

  React.useEffect(() => {
    if (!data) return;
    fetchSubmissions(data.id);
    // eslint-disable-next-line
  }, [data]);

  if (!data) return null;

  const date = (d: string | undefined) => {
    return d ? DateTime.fromJSDate(new Date(d)) : undefined;
  };

  const resetProfilePicture = () => {
    onDataChange('avatarUrl', '');
  };

  const hasEmail = (data?.email?.length || 0) > 0;
  const createdAt = date(data.membershipCreatedAt)?.toFormat('MMMM dd, yyyy');
  const lastActive = date(data.lastVisitedAt)?.toRelative() || undefined;

  const handleForgetConfirm = () => {
    forgetUser(Number(data.id));
    setIsConfirmOpen(false);
    navigate(`/${programId}/app/people/users`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div>
          <div>{data.activity90Day || 'NA'}</div>
          <div>90 day activity</div>
        </div>
        <div>
          <div>NA</div>
          <div>Avg session length</div>
        </div>
        <div>
          <div>{userPostCount || 'NA'}</div>
          <div>Posts</div>
        </div>
      </div>
      <div className={styles.col}>
        <div>
          <span>Created</span>
          <span>{createdAt}</span>
        </div>
        <div>
          <span>Last active</span>
          <span>{lastActive}</span>
        </div>
        <div>
          <span>Preferred channel</span>
          <span>{data.channel90Day || 'NA'}</span>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        {!emailTokenAuthEnabled && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => {
              inviteUser(
                [data.id.toString()],
                undefined,
                hasEmail.toString(),
                hasEmail.toString()
              );
            }}
          >
            <span>
              <Icon iconName="ArrowNext" iconType="SVG" />
            </span>
            <span>Send Invite</span>
          </div>
        )}
        <ResetPassword userToReset={data} />
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() =>
            data.status === 'blocked'
              ? activateUser(Number(data.id))
              : deactivateUser(Number(data.id))
          }
        >
          <span>
            <Icon
              iconName={
                data.status === 'blocked' ? 'CheckCircleOutline' : 'Deactivate'
              }
              iconType="SVG"
            />
          </span>
          <span>{data.status === 'blocked' ? 'Activate' : 'Deactivate'}</span>
        </div>

        {addImportUsersAccess && gdprComplianceEnabled?.value && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => setIsConfirmOpen(true)}
          >
            <span>
              <Icon iconName="Delete" iconType="SVG" />
            </span>
            <span>Forget User (GDPR)</span>
          </div>
        )}

        {gdprComplianceEnabled?.value && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => exportUser([Number(data.id)])}
          >
            <span>
              <Icon iconName="Export" iconType="SVG" />
            </span>
            <span>Export user data (GDPR)</span>
          </div>
        )}

        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => confirmUser(Number(data.id))}
        >
          <span>
            <Icon iconName="CheckCircleOutline" iconType="SVG" />
          </span>
          <span>Confirm User</span>
        </div>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={resetProfilePicture}
        >
          <span>
            <Icon iconName="Undo" iconType="SVG" />
          </span>
          <span>Reset profile picture</span>
        </div>
      </div>
      {exportId > 0 && (
        <ExportsDownload exportId={exportId} programId={programId} />
      )}
      {isConfirmOpen && (
        <ConfirmModal
          headerTitle="Forget User"
          actionTitle=""
          customTitle="This action will permanently remove all data and remove this user from ALL programs. You cannot undo this action."
          hideLabel
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={handleForgetConfirm}
        />
      )}
    </div>
  );
};
