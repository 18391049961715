import snakecaseKeys from 'snakecase-keys';
import { CustomSlug } from 'models/custom-slug';
import qs from 'qs';
import { Program } from 'models/program';
import { ContentData } from 'services/api-content';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';
import { EmptyablePage } from './common';

type CustomSlugData = {
  attributes: CustomSlug;
};

export type CustomSlugFilterOptions = {
  search: string;
  createdBy: string[];
  excludeIds?: number[];
};

export type CustomSlugFilter = keyof Omit<
  CustomSlugFilterOptions,
  'search' | 'excludeIds'
>;
export type CustomSlugFilterValue = CustomSlugFilterOptions[CustomSlugFilter];

const setRequestBody = (items: Partial<CustomSlug>): string => {
  const attributes = snakecaseKeys({
    ...items,
    contentId: items.contentId ? items.contentId : null,
  });

  return JSON.stringify({ data: { attributes } });
};

export type QueryParams = {
  page?: number;
  pageSize?: number;
  filters?: CustomSlugFilterOptions;
};

export type FetchParams = {
  programId: number;
} & QueryParams;

export type CustomSlugCollectionData = EmptyablePage<CustomSlugData>;

export const fetchCustomSlugs = async (
  props: FetchParams
): Promise<CustomSlugCollectionData> => {
  const { programId, filters } = props;

  const query = qs.stringify(snakecaseKeys(filters ?? {}), {
    arrayFormat: 'brackets',
  });

  const url = `${bossanovaDomain}/samba/programs/${programId}/custom_slugs?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching custom slugs: ${response.status}`);
};

export type CustomSlugMemberData = {
  data: CustomSlugData;
  included: ContentData[];
};

export type CustomSlugMemberDataWithoutIncluded = Omit<
  CustomSlugMemberData,
  'included'
>;

type FetchOneParams = {
  programId: number;
  id: number;
};

export const fetchCustomSlug = async (
  props: FetchOneParams
): Promise<CustomSlugMemberData> => {
  const { programId, id } = props;
  const url = `${bossanovaDomain}/samba/programs/${programId}/custom_slugs/${id}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching custom slug: ${response.status}`);
};

export const createCustomSlug = async (
  programId: number,
  customSlug: Partial<CustomSlug>
): Promise<CustomSlug> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/custom_slugs`;
  const response = await request(url, {
    method: 'POST',
    body: setRequestBody(customSlug),
  });

  if (response.status === 201) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error on create custom slug: ${response.status}`);
};

export const updateCustomSlug = async (
  customSlug: Partial<CustomSlug>
): Promise<CustomSlug> => {
  const url = `${bossanovaDomain}/samba/programs/${customSlug.programId}/custom_slugs/${customSlug.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: setRequestBody(customSlug),
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error on create custom slug: ${response.status}`);
};

export const deleteCustomSlug = async ({
  programId,
  customSlugId,
}: {
  programId: Program['id'];
  customSlugId: CustomSlug['id'];
}): Promise<void> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/custom_slugs/${customSlugId}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status === 204) {
    return;
  }

  throw new Error(`Error on delete custom slug: ${response.status}`);
};

export const duplicateCustomSlug = async ({
  programId,
  customSlugId,
}: {
  programId: Program['id'];
  customSlugId: CustomSlug['id'];
}): Promise<CustomSlugMemberDataWithoutIncluded> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/custom_slugs/${customSlugId}/duplicate`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 201) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error on duplicate custom slug: ${response.status}`);
};
