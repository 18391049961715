import QS from 'qs';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import {
  Status,
  WorkflowTemplate,
  ProcessActions,
} from 'models/workflow-templates';
import { request } from './api-shared';
import { fqApiUrl, Page } from './common';

export type FetchProps = {
  status?: Status;
  search?: string;
} & {
  programId: number;
  page?: number;
  pageSize?: number;
};

type WorkflowTemplateData = {
  id: string;
  type: string;
  attributes: WorkflowTemplate;
};

export type WorkflowTemplatesCollection = Page<WorkflowTemplateData>;

export async function fetchWorkflowTemplates({
  status = '',
  search = '',
  programId,
  page = 1,
  pageSize = 20,
}: FetchProps): Promise<WorkflowTemplatesCollection> {
  const qs = QS.stringify(
    snakecaseKeys({
      withData: true,
      search,
      status,
      page,
      pageSize,
    })
  );
  const url = fqApiUrl(`samba/programs/${programId}/galleries?${qs}`);
  const res = await request(url);
  if (res.status !== 200)
    throw new Error(`Error fetching workflow templates: ${res.status}`);

  const data = await res.json();
  return camelcaseKeys(data, { deep: true });
}

export async function processWorkflowTemplate(
  programId: number,
  templateId: number,
  action: ProcessActions
): Promise<WorkflowTemplate> {
  const url = fqApiUrl(
    `samba/programs/${programId}/galleries/${templateId}/${action}`
  );
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }
  throw new Error(`Error creating draft workflow template: ${response.status}`);
}
