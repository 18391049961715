import { MenuItemsType, ThumbnailType } from 'shared/BannerListItem';
import { Workflow } from 'models/workflows';
import { WorkflowActions } from './useActions';

const BACKGROUND_COLORS = ['#5D7E80', '#5D6480', '#6797AC'];

export function workflowToThumbnail(
  workflow: Pick<Workflow, 'name'>,
  index: number
): ThumbnailType {
  return {
    title: workflow.name,
    color: 'var(--color-gray05)',
    backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
  };
}

export function workflowToMenuItems(
  workflow: Workflow,
  actions: WorkflowActions
): MenuItemsType {
  const { id: workflowId, editingState } = workflow;

  const isArchived = ['archived', 'deleted'].includes(editingState);
  if (isArchived) return [];

  const isReadOnly = ['completed', 'all_sent'].includes(editingState);
  const isActive = ['active', 'running'].includes(editingState);

  return [
    {
      title: isReadOnly ? 'View' : 'Edit',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return isReadOnly ? actions.view(workflowId) : actions.edit(workflowId);
      },
    },
    {
      title: 'Copy',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.copy(workflowId);
      },
    },
    {
      title: isActive ? 'Deactivate & Archive' : 'Archive',
      danger: true,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        return actions.confirmArchive?.showModal();
      },
    },
  ];
}
