import * as React from 'react';

type PropsType = {
  ref: React.RefObject<HTMLDivElement>;
  index?: number;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  onSelect?: (index: number) => void;
};

export const useKeyboardNavigation = (props: PropsType): void => {
  const { ref, index, moveUp, moveDown, onSelect } = props;

  const moveCursor = React.useCallback(
    (e: KeyboardEvent) => {
      if (index == null) {
        return;
      }

      if (['ArrowLeft', 'ArrowUp'].includes(e.key)) {
        moveUp(index);
        e.preventDefault();
      } else if (['ArrowRight', 'ArrowDown'].includes(e.key)) {
        moveDown(index);
        e.preventDefault();
      } else if (onSelect && [' ', 'Enter'].includes(e.key)) {
        onSelect(index);
        e.preventDefault();
      }
    },
    [index, moveUp, moveDown, onSelect]
  );

  React.useEffect(() => {
    const cleanupRef = ref;
    if (ref.current) {
      ref.current.tabIndex = 0;
      ref.current.addEventListener('keydown', moveCursor);
    }
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current.removeEventListener('keydown', moveCursor);
      }
    };
  }, [ref, moveCursor]);
};
