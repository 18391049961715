import React from 'react';
import { MAIcon } from '../MAIcon';
import styles from './fieldset.module.css';

type FieldErrorProps = {
  error: string;
  ariaLabel?: string;
};

export function FieldError({
  error,
  ariaLabel,
}: FieldErrorProps): React.ReactElement {
  return (
    <div className={styles.error} role="alert" aria-label={ariaLabel}>
      <MAIcon name="warning" />
      <p>{error}</p>
    </div>
  );
}
