import { useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { Workflow } from 'models/workflows';

export const useWorkflowReportUrls = (
  workflow: Workflow
): {
  performanceReportUrl: string;
} => {
  const { id: programId } = useProgram();
  const { id: workflowId, firstActivationDate } = workflow;
  const workflowActivationDate = firstActivationDate
    ? DateTime.fromISO(firstActivationDate).toFormat('yyyy-MM-dd')
    : '';
  const currentDate = DateTime.now().toFormat('yyyy-MM-dd');

  const baseUrl = `/${programId}/app/insights/report`;

  const performanceReportUrl = useMemo(() => {
    if (workflowActivationDate === '') {
      return '';
    }

    return encodeURI(
      `${baseUrl}/campaign-performance?campaign[0]=${workflowId}&date_range[0]=${workflowActivationDate}~${currentDate}`
    );
  }, [workflowActivationDate, baseUrl, workflowId, currentDate]);

  return {
    performanceReportUrl,
  };
};
