import React from 'react';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { usePublisher } from 'contexts/publisher';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { HoverTooltip } from 'shared/HoverTooltip';
import { FacetData } from 'models/facet';
import { useContentUserCount } from 'hooks/useContentUserCount';
import { useCampaignTarget } from '../SettingsEditor/useCampaignTarget';
import styles from './orchestration-insights.module.css';

export const Target: React.FC = () => {
  const { post } = usePublisher();
  const { audiences, contentTopics } = post.settings;
  const emailOnly = useFeatureFlag('emailOnly');
  const {
    audiences: targetAudiences,
    topics: targetTopics,
    all,
    facets,
  } = useCampaignTarget();

  const {
    uniqueUserCount,
    topicSubscribersCount,
    isLoading: userCountLoading,
  } = useContentUserCount();

  const isLoading = React.useMemo(() => {
    return (
      targetAudiences.isLoading ||
      userCountLoading ||
      targetTopics.isLoading ||
      facets.isLoading ||
      all.isLoading
    );
  }, [targetAudiences, targetTopics, facets, userCountLoading, all]);

  const displayFacet = React.useCallback((values: Array<FacetData>) => {
    return (
      <div className={styles.facetWrapper}>
        {(values || []).map((d: FacetData) => (
          <div className={styles.facetRow} key={`facet-${d.value}`}>
            <div>{d.value}</div>
            <div>{d.count}</div>
          </div>
        ))}
      </div>
    );
  }, []);

  const dropdown = React.useCallback(() => {
    if (!facets.data) {
      return <></>;
    }
    const locations = facets.data.find((f) => f.facetName === 'work_location');
    const departments = facets.data.find((f) => f.facetName === 'department');
    const jobTitles = facets.data.find((f) => f.facetName === 'job_title');
    return (
      <div className={styles.facetsTooltip}>
        {locations && (
          <div className={styles.facet}>
            <div className={styles.facetName}>Locations</div>
            {displayFacet(locations.facetData)}
          </div>
        )}
        {departments && (
          <div className={styles.facet}>
            <div className={styles.facetName}>Departments</div>
            {displayFacet(departments.facetData)}
          </div>
        )}
        {jobTitles && (
          <div className={styles.facet}>
            <div className={styles.facetName}>Job titles</div>
            {displayFacet(jobTitles.facetData)}
          </div>
        )}
      </div>
    );
  }, [facets, displayFacet]);

  if (isLoading) return <LoadingSpinner />;

  const errorCountValue = '?';

  const audiencesValue = () => {
    if (audiences.length === 0) return 0;
    if (targetAudiences.errorMessage) return errorCountValue;

    return targetAudiences.users?.totalObjects ?? 0;
  };

  const data = [
    { name: 'Audience', value: audiencesValue(), isLoading: false },
  ];

  if (!emailOnly) {
    const topicsValue = () => {
      if (contentTopics.length === 0) return 0;
      if (targetTopics.errorMessage) return errorCountValue;
      return topicSubscribersCount ?? 0;
    };

    data.push({ name: 'Topic', value: topicsValue(), isLoading: false });
  }

  return (
    <>
      <div className={styles.header}>
        <h3 className="section-header">Target</h3>
        <HoverTooltip align="left" content={dropdown} />
      </div>
      <div className={styles.total} id="insight-total-reach">
        Total reach of <span>{uniqueUserCount}</span>
      </div>
      <div className={styles.insights}>
        {data.map((d) => (
          <div
            className={styles.targetRow}
            key={d.name}
            id={`insight-${d.name.toLowerCase()}`}
          >
            <div className={styles.day}>{d.name}</div>
            <div className={styles.value}>{d.value}</div>
          </div>
        ))}
      </div>
    </>
  );
};
