import React from 'react';
import {
  SOCIAL,
  toSocialFieldData,
  SocialData,
  fieldToSocial,
} from 'models/publisher/block';
import { SocialDataMutator } from './useSocialDataMutator';

export const useSocialDataSelector = (
  mutator: SocialDataMutator
): {
  item?: SocialData;
  change(data: SocialData): void;
  deselect(): void;
  select(index: number): void;
} => {
  const { items, changeItems } = mutator;
  const [index, select] = React.useState(-1);
  const deselect = React.useCallback(() => {
    select(-1);
  }, []);

  const item = React.useMemo(() => {
    if (index < 0) return undefined;

    if (items[index]) return items[index];
    const newItem = { ...SOCIAL };
    newItem.image.hosted_url =
      'https://lib.onfirstup.com/content_blocks/defaults/img-placeholder.png';
    return fieldToSocial(toSocialFieldData(newItem));
  }, [index, items]);

  const change = React.useCallback(
    (updated: SocialData | SocialData[]) => {
      if (index < 0) throw new Error(`failed to edit social[undefined]`);

      const collection = Array.isArray(updated) ? updated : [updated];
      const prior = items.slice(0, index);
      const after = items.slice(index + 1);
      const newItems = [...prior, ...collection, ...after];
      changeItems(newItems);
      select(-1); // deselect after changing data
    },
    [items, changeItems, index]
  );

  return { deselect, item, select, change };
};
