import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Author, hasAvatar } from 'models/author';
import { ProgramAuthor } from 'models/programAuthor';
import { cropImage } from 'models/publisher/post';
import { CallToAction } from 'models/publisher/call-to-action';
import { DefaultAvatar } from 'shared/icons';
import styles from './call-to-action-preview.module.css';

export const CallToActionPreview: React.FC<{
  contentAuthor: Author | ProgramAuthor;
  callToAction: CallToAction;
}> = ({ contentAuthor, callToAction }) => {
  const ctaImageUrl = callToAction.image?.url
    ? cropImage(callToAction.image?.url, 'mobile', 'article', true)
    : 'https://lib.onfirstup.com/patron/production/images/content_default0.png';

  return (
    <>
      <Box>
        <img
          className={styles.CtaImage}
          src={ctaImageUrl}
          alt="content-preview"
        />
      </Box>
      <Box margin={[20, 0, 0, 0]} className={styles.CtaDataContainer}>
        {hasAvatar(contentAuthor) ? (
          <img
            className={styles.AuthorAvatar}
            src={contentAuthor.avatarUrl}
            alt="author-avatar"
          />
        ) : (
          <DefaultAvatar className={styles.AuthorAvatar} />
        )}
        <div className={styles.AuthorName}>{contentAuthor.displayName}</div>
      </Box>
      <Box margin={[26, 0, 0, 0]}>
        <div dir="auto" className={styles.CtaTitle}>
          {callToAction.title}
        </div>
      </Box>
      <Box className={styles.CtaSummary} margin={[11, 0, 0, 0]}>
        <div dir="auto">{callToAction.summary}</div>
      </Box>
    </>
  );
};
