export const setTimeoutExtended = (
  callback: () => void,
  time: number
): ReturnType<typeof setTimeout> => {
  const max = 2 ** 31 - 1;
  let current = time;

  if (time > max) {
    current = max;
  }

  const remaining = time - max;

  return setTimeout(() => {
    if (remaining <= 0) {
      callback();
    } else {
      setTimeoutExtended(callback, remaining);
    }
  }, current);
};

export const inactivityTimeout = (
  fn: () => void,
  timeInMinutes: number
): void => {
  let time: ReturnType<typeof setTimeout>;
  const resetTimer = () => {
    clearTimeout(time);
    time = setTimeoutExtended(fn, timeInMinutes * 60 * 1000);
  };
  window.onload = resetTimer;
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
};
