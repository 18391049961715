import React from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { splitWords } from 'utility/text';
import { LitmusRole } from 'models/role';
import styles from '../imports.module.css';

type StatsDisplay = {
  experience: number;
  studio: { group: string; value: number | undefined }[];
};

const preparePermissionsStats = (
  stats: { [key: string]: number | undefined },
  roles: LitmusRole[]
) => {
  const studioDefault: { [key: string]: number } = {};
  roles.forEach((role) => {
    studioDefault[role.title] = 0;
  });
  const { member, ...rest } = stats;
  const studioStats = { ...studioDefault };
  Object.keys(rest).forEach((key) => {
    const transformedKey = splitWords(key);
    if (studioDefault[transformedKey] !== undefined) {
      studioStats[transformedKey] = rest[key] || studioDefault[transformedKey];
    }
  });

  const experience = member || 0;

  const studio = Object.keys(studioStats).map((field: string) => ({
    group: field,
    value: studioStats[field],
  }));

  return {
    experience,
    studio,
  };
};

const prepareStats = (stats: { [key: string]: number | undefined }) => {
  const studioDefault: { [key: string]: number } = {
    analyst: 0,
    publisher: 0,
    channelContributor: 0,
    programAdmin: 0,
    administrator: 0,
  };

  const { member, ...rest } = stats;
  const experience = member || 0;
  const studioStats = { ...studioDefault, ...rest };
  const relevantStatFields = Object.keys(studioStats).filter(
    (key) => studioDefault[key] === 0
  );

  let studio = relevantStatFields.map((field: string) => ({
    group: splitWords(field.replace('channel', 'topic')),
    value: studioStats[field],
  }));

  studio = studio.map((option) => {
    switch (option.group) {
      case 'Topic Contributor':
        return { ...option, group: 'Topic Manager' };
      case 'Program Admin':
        return { ...option, group: 'Community Manager' };
      default:
        return option;
    }
  });

  return {
    experience,
    studio,
  };
};

export const Stats: React.FC<{
  stats: { [key: string]: number | undefined };
  roles: LitmusRole[];
  permissionsEnabled: boolean;
}> = ({ stats, roles, permissionsEnabled }) => {
  let { experience, studio }: StatsDisplay = {
    experience: 0,
    studio: [],
  };

  if (!permissionsEnabled) {
    ({ experience, studio } = prepareStats(stats));
  }
  if (permissionsEnabled) {
    ({ experience, studio } = preparePermissionsStats(stats, roles));
  }

  return (
    <>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Experience">
            <div className={styles.pill}>
              <div className={styles.twoCols}>
                <span>Members</span>
                <span>{experience}</span>
              </div>
            </div>
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Studio">
            <div className={styles.pill}>
              {studio.map((item) => (
                <div key={item.group} className={styles.twoCols}>
                  <span>{item.group}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </Field>
        </div>
      </Fieldset>
    </>
  );
};
