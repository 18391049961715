import React from 'react';
import cx from 'classnames';
import styles from './journey-content-list-drawer.module.css';

export enum ContentListState {
  Open,
  Closed,
}

export const Drawer: React.FC<{
  state: ContentListState;
  onOverlayClick?: () => void;
}> = ({ state, children, onOverlayClick = () => {} }) => {
  return (
    <div
      className={cx(styles.journeyContentListDrawer, {
        [styles.active]: state === ContentListState.Open,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={styles.journeyContentListDrawerOverlay}
        onClick={onOverlayClick}
      />
      {children}
    </div>
  );
};
