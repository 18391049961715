import { usePersistanceStatus } from 'components/publisher/theme/PublisherFooter/PostPersistanceStatus';
import { usePublisher } from 'contexts/publisher';
import { Post } from 'models/publisher/post';
import { useMemo } from 'react';
import { blocksReadTime } from 'utility/read-time';

export const useReadTime = (
  post: Post
): {
  readTimeInSeconds: number;
  formattedReadTime: string;
} => {
  const { cloudStatus } = usePersistanceStatus();
  const { status: postSavingStatus } = usePublisher();
  let formattedReadTime = '-- min read';

  // Fallback for old contents that don't have the read time column set
  // by server
  let readTimeInSeconds = useMemo(() => blocksReadTime(post.blocks), [
    post.blocks,
  ]);

  if (post.blocks.length > 0) {
    if (cloudStatus !== 'Up to date') {
      if (postSavingStatus.isSaving) {
        formattedReadTime = 'Calculating read time...';
      } else {
        formattedReadTime = 'Save to calculate read time';
      }
    } else {
      if (post.content.readTimeInSeconds) {
        readTimeInSeconds = post.content.readTimeInSeconds;
      }
      formattedReadTime = `${Math.ceil(readTimeInSeconds / 60)}-minute read`;
    }
  }

  return {
    readTimeInSeconds,
    formattedReadTime,
  };
};
