import React from 'react';
import { AttachmentFieldData } from 'models/publisher/block';
import { FieldProps } from '../FieldProps';
import { Upload } from './Upload';

export const Attachment: React.FC<FieldProps<AttachmentFieldData>> = ({
  data,
  onChange,
}) => {
  return <Upload data={data} onChange={onChange} />;
};
