import React, { CSSProperties } from 'react';
import cx from 'classnames';
import filterStyles from 'App/Program/Main/Insight/components/Filters/Filters.module.css';

type SelectableListItemPropsType = {
  onClick: () => void;
  rowType: 'radio' | 'checkbox';
  isSelected: boolean;
  label: string;
  style?: CSSProperties;
  innerListItemStyle?: CSSProperties;
  isPartiallySelected?: boolean;
};
export const SelectableListItem: React.FC<SelectableListItemPropsType> = ({
  onClick,
  label,
  rowType,
  isSelected,
  style,
  innerListItemStyle,
  isPartiallySelected,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      style={style}
      className={filterStyles.filterDropdownBodyListItemContainer}
      onClick={onClick}
    >
      <li
        style={innerListItemStyle}
        className={cx(
          filterStyles.filterDropdownBodyListItem,
          rowType === 'checkbox'
            ? filterStyles.filterDropdownBodyListItemCheckbox
            : filterStyles.filterDropdownBodyListItemRadio,
          isSelected && filterStyles.filterDropdownBodyListItemSelected,
          isPartiallySelected && filterStyles.filterDropdownBodyListItemPartial
        )}
        title={label}
      >
        {label}
      </li>
    </div>
  );
};
