import { Tooltip } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { LandingPageTab } from 'models/topic';
import React, { PropsWithChildren, ReactElement } from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import { MAIcon } from 'shared/MAIcon';
import styles from './styles.module.css';

type PageControlProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  tab: LandingPageTab;
  onChange: (data: LandingPageTab) => void;
  hideToggle?: boolean;
}>;

export function PageControl({
  title,
  subtitle,
  tab,
  onChange,
  hideToggle,
  children,
}: PageControlProps): ReactElement {
  return (
    <Flex column className={styles.pageControlContent}>
      <Flex spread className={styles.pageControlHeaderContainer}>
        <Flex column className={styles.pageControlHeader}>
          <Flex className={styles.pageControlTitle}>
            <Text.Body>{title}</Text.Body>
            {tab.position === 0 && (
              <Tooltip content="This page will appear as the homepage on topic">
                {/* Needed the extra div so that tooltip can anchor to the icon's container */}
                <div style={{ height: 24 }}>
                  <MAIcon
                    name="home"
                    style={{
                      fontVariationSettings: "'FILL' 1",
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </Flex>
          {subtitle && <Text.Caption>{subtitle}</Text.Caption>}
        </Flex>

        {/* control actions */}
        <Flex style={{ columnGap: '16px' }}>
          {!hideToggle && (
            <FlipSwitch
              size="xl"
              shape="rounded"
              onChange={(isOn) =>
                onChange({
                  ...tab,
                  isHidden: !isOn,
                })
              }
              on={!tab.isHidden}
            />
          )}
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
}
