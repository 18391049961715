import * as React from 'react';
import { useLocation, useNavigate } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { getActor } from 'services/global-actor-storage';
import styles from 'shared/Flasher/flasher.module.css';

const BOSS = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type IframeLifecycle = Partial<{
  // We want to know when the user leaves the page,
  // so we can handle the navigation if needed.
  onNavigate: (to: string) => void;
  // We want to know when the user saves an entity
  // so we can updates the URL.
  newSlugs: string[];
}>;

type Action = { TYPE: string; event: MessageEvent } & (
  | { TYPE: 'AUTH' }
  | { TYPE: 'CREATED'; id: string }
  | { TYPE: 'NAVIGATE'; to: string }
  | {
      TYPE: 'FLASH';
      priority: 'success' | 'error';
      message: string;
      link?: { href: string; text?: string; target?: string };
    }
);

function isAction(data: unknown): data is Action {
  return typeof data === 'object' && data !== null;
}

// Creates a handler for that message type.
function intercept<T extends Action['TYPE']>(
  TYPE: T,
  handler: (action: { TYPE: T } & Action) => void
) {
  return (action: Action) => {
    if (action.TYPE === TYPE) handler(action as { TYPE: T } & Action);
  };
}

// Sets up the communication to receieve valid actions for the handlers
function useMessageReceiver(handlers: Array<ReturnType<typeof intercept>>) {
  React.useEffect(() => {
    const verify = (event: MessageEvent) => event.origin === BOSS;
    const decode = (event: MessageEvent) => {
      try {
        if (isAction(event.data)) return { ...event.data, event };
        // eslint-disable-next-line
      } catch {}
      return undefined;
    };

    function handler(event: MessageEvent) {
      if (verify(event)) {
        const action = decode(event);
        if (action) handlers.forEach((handle) => handle(action));
      }
    }

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
    // eslint-disable-next-line
  }, []);
}

export function useIframeIntegration(props: IframeLifecycle): void {
  const location = useLocation();
  const navigate = useNavigate();
  const flash = useFlashMessage();
  useMessageReceiver([
    intercept('AUTH', ({ event }) => {
      Promise.resolve(getActor()).then((actor) => {
        if (actor) {
          if (
            !(event.source instanceof ServiceWorker) &&
            !(event.source instanceof MessagePort) // needy TS :P
          ) {
            event.source?.postMessage(
              {
                TYPE: 'AUTH',
                headers: [
                  ...Object.entries(actor.headers),
                  ['Accept', 'application/json'],
                  ['Content-Type', 'application/json'],
                ],
              },
              event.origin
            );
          }
        }
      });
    }),
    intercept('CREATED', (action) => {
      const slug = (props.newSlugs || ['new']).find((s) =>
        location.pathname.endsWith(`/${s}`)
      );
      if (slug) {
        const newLocation = location.pathname.replace(
          `/${slug}`,
          `/${action.id}`
        );
        navigate(newLocation, { replace: true });
      }
    }),
    intercept('NAVIGATE', (action) => {
      if (props.onNavigate) props.onNavigate(action.to);
    }),
    intercept('FLASH', (action) => {
      const children = !action.link?.href
        ? undefined
        : React.createElement(
            'a',
            {
              href: action.link.href,
              target: action.link.target,
              className: styles.link,
            },
            action.link.text || action.link.href
          );
      flash.setFlashMessage({
        children,
        message: action.message,
        severity: action.priority === 'error' ? 'error' : 'info',
      });
    }),
  ]);
}
