import camelcaseKeys from 'camelcase-keys';
import { Program } from 'models/program';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type SupportedCommands =
  | 'summarize'
  | 'title'
  | 'modal_summary'
  | 'fix_spelling_grammar'
  | 'use_concise_language'
  | 'delivery_subject'
  | 'delivery_preview'
  | 'delivery_mobile'
  | 'change_tone_professional'
  | 'change_tone_casual'
  | 'change_tone_straightforward'
  | 'change_tone_confident'
  | 'change_tone_friendly';
export type CreateContentCoverGenerationTaskParams = {
  programId: Program['id'];
  content: string;
  command: SupportedCommands;
  contentId?: number;
};

export type CreateTaskResponse = {
  outputId: string;
};
export const createContentCoverAthenaTask = async (
  props: CreateContentCoverGenerationTaskParams
): Promise<CreateTaskResponse> => {
  const { programId, command } = props;

  const url = `${apiRoot}/samba/programs/${programId}/generate/${command}`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(props),
  });
  if (response.ok) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error fetching AI response: ${response.status}`);
};

export type AthenaOutputStatus = 'pending' | 'complete' | 'failed';

export type AthenaOutputResponse = {
  id: string;
  name: string;
  status: AthenaOutputStatus;
  taskId: string;
  createdAt: string;
  updatedAt: string;
  value: string;
};

export async function fetchAthenaOutput(
  programId: Program['id'],
  outputId: AthenaOutputResponse['id']
): Promise<AthenaOutputResponse> {
  const url = `${apiRoot}/samba/programs/${programId}/generate/${outputId}`;

  const response = await request(url, { method: 'GET' });
  if (!response.ok) {
    throw new Error(`Error fetching Athena output: ${response.status}`);
  }

  const json = camelcaseKeys(await response.json()) as AthenaOutputResponse;

  if (json.status === 'failed') {
    throw new Error('Athena task failed.');
  }

  return json;
}

export function pollAthenaOutput({
  programId,
  outputId,
  interval = 5000,
  timeout = 60000,
  maxRetries = 12,
}: {
  programId: number;
  outputId: string;
  interval?: number; // polling interval in milliseconds
  timeout?: number; // maximum time to poll before throwing an error in milliseconds
  maxRetries?: number; // maximum number of retries before giving up
}): Promise<AthenaOutputResponse> {
  let retryCount = 0;
  const startTime = Date.now();

  const poll = (): Promise<AthenaOutputResponse> => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line consistent-return
      const attempt = async () => {
        if (retryCount >= maxRetries) {
          return reject(new Error('Polling failed: exceeded max retries.'));
        }

        try {
          const url = new URL(
            `${apiRoot}/samba/programs/${programId}/generate/${outputId}`
          ).toString();

          const response = await request(url, { method: 'GET' });
          if (!response.ok) {
            throw new Error(`Error fetching Athena output: ${response.status}`);
          }

          const data = camelcaseKeys(
            (await response.json()) as AthenaOutputResponse
          );

          if (data.status === 'complete') {
            resolve(data);
          } else if (data.status === 'failed') {
            reject(new Error('Polling failed: task failed.'));
          } else if (Date.now() - startTime > timeout) {
            reject(new Error('Polling failed: timeout exceeded.'));
          } else {
            retryCount += 1;
            setTimeout(attempt, interval);
          }
        } catch (error) {
          reject(error);
        }
      };

      attempt();
    });
  };

  return poll();
}
