import * as React from 'react';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { FocusDropdown } from 'shared/FocusDropdown';
import { AliasData } from 'services/api-author-alias';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  useAuthorAliasesWithUsersInfiniteQuery,
  useAuthorAliasesInfiniteQuery,
} from 'hooks/author-alias';
import { Dropdown } from './Dropdown';
import styles from './author-alias.module.css';

export type PropsType = {
  selectedAliases: Array<AliasData>;
  onSelectionChange: (value: Array<AliasData>) => void;
  maxDropdownHeight?: number;
  placeholder?: string;
  upward?: boolean;
};

export const AuthorAliaseSelect: React.FC<PropsType> = (props) => {
  const {
    selectedAliases,
    onSelectionChange,
    maxDropdownHeight = 400,
    placeholder,
    upward = false,
  } = props;

  const [searchText, setSearchText] = React.useState('');

  const { id: programId } = useProgram();

  const { data: authorAliasesEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.AuthorAliases'
  );

  // this conditional should be removed as soon as Studio.Configure.AuthorAliases
  // has been turned on for all customers

  const {
    isLoading: withUsersLoading,
    data: withUsersValues,
    fetchNextPage: withUsersFetchNextPage,
    hasNextPage: withUsersHasNextPage,
    isFetchingNextPage: withUsersIsFetchingNextPage,
  } = useAuthorAliasesWithUsersInfiniteQuery({
    pageSize: 20,
    programId,
    search: useDebounce(searchText),
  });

  const {
    isLoading: withoutUsersLoading,
    data: withoutUsersValues,
    fetchNextPage: withoutUsersFetchNextPage,
    hasNextPage: withoutUsersHasNextPage,
    isFetchingNextPage: withoutUsersIsFetchingNextPage,
  } = useAuthorAliasesInfiniteQuery({
    pageSize: 20,
    programId,
    search: useDebounce(searchText),
    statuses: ['active'],
  });

  const isLoading = authorAliasesEnabled?.value
    ? withoutUsersLoading
    : withUsersLoading;
  const values = authorAliasesEnabled?.value
    ? withoutUsersValues
    : withUsersValues;
  const fetchNextPage = authorAliasesEnabled?.value
    ? withoutUsersFetchNextPage
    : withUsersFetchNextPage;
  const hasNextPage = authorAliasesEnabled?.value
    ? withoutUsersHasNextPage
    : withUsersHasNextPage;
  const isFetchingNextPage = authorAliasesEnabled?.value
    ? withoutUsersIsFetchingNextPage
    : withUsersIsFetchingNextPage;

  const selectedIds: string[] = [];
  const selectedAliasesByIds: { [key: string]: AliasData } = {};
  selectedAliases.forEach((alias: AliasData) => {
    selectedIds.push(alias.id?.toString() || '');
    selectedAliasesByIds[alias.userId || ''] = alias;
  });

  const selectedValues = selectedAliases.map(
    (alias: AliasData) => `${alias?.displayName}`
  );

  const aliases = values.map((v) => {
    if (selectedAliasesByIds[v.userId || '-'])
      return selectedAliasesByIds[v.userId || '-'];
    return v;
  });
  const aliasIds: Array<number> = values.map((item) => item.id || 0);
  const uniqueIds = new Set([...aliasIds]);
  selectedAliases.forEach((value: AliasData) => {
    if (value.id && !uniqueIds.has(value.id)) {
      aliases.push(value);
    }
  });

  function handleRemove(index: number) {
    const newSelectedAliases = [...selectedAliases];
    newSelectedAliases.splice(index, 1);
    onSelectionChange(newSelectedAliases);
  }

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedAliases = ids
      .map((id) => aliases.find((alias) => alias.id?.toString() === id))
      .filter((item) => !!item);
    onSelectionChange(newSelectedAliases as Array<AliasData>);
  }

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={aliases}
        selectedIds={selectedIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        maxHeight={maxDropdownHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        upward={upward}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={selectedValues}
            onRemoveSelectedValueAt={handleRemove}
            placeholder={placeholder}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};
