type TimeOutT = {
  predicate: string;
  value: number;
  interval: string;
  displayString: string;
};

const getTimeConstants = (isInSeconds?: boolean) => {
  return {
    DAY: isInSeconds ? 86400 : 1440,
    HOUR: isInSeconds ? 3600 : 60,
    MINUTE: isInSeconds ? 60 : 1,
  };
};

export const parseSessionTimeout = (
  timeout?: number | null,
  isInSeconds?: boolean
): TimeOutT => {
  const { DAY, HOUR, MINUTE } = getTimeConstants(isInSeconds);
  const result: TimeOutT = {
    predicate: 'never',
    value: timeout || 0,
    interval: 'minute',
    displayString: `Never`,
  };

  if (!timeout)
    return {
      ...result,
      predicate: 'never',
    };

  result.predicate = 'every';
  if (timeout >= DAY) {
    result.interval = 'day';
    result.value = Math.round(timeout / DAY);
    result.displayString = `Every ${result.value} day(s)`;
  } else if (timeout >= HOUR) {
    result.interval = 'hour';
    result.value = Math.round(timeout / HOUR);
    result.displayString = `Every ${result.value} hour(s)`;
  } else {
    result.interval = 'minute';
    result.value = Math.round(timeout / MINUTE);
    result.displayString = `Every ${result.value} minute(s)`;
  }

  return result;
};

export const parseTimeoutValueToNumber = (
  value: number,
  interval: string,
  isIntervalInSeconds?: boolean
): number => {
  const { DAY, HOUR, MINUTE } = getTimeConstants(isIntervalInSeconds);
  switch (interval) {
    case 'hour':
      return HOUR * value;
    case 'day':
      return DAY * value;
    default:
      return MINUTE * value;
  }
};
