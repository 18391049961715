import React, { FC, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import { MAIcon } from 'shared/MAIcon';
import { Button } from 'DesignSystem/Form';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import styles from './styles.module.css';

interface Props {
  title: string;
  /** Callback before the sidebar closes. If returns `false`, the closing process will be aborted. */
  onBeforeClose?: () => boolean;
  /** Called when user clicked close button, or outside of sidebar */
  onClose: () => void;
  /** Whether to show the close button */
  closeButton?: boolean;
  position?: 'left' | 'right';
}

const Sidebar: FC<Props> = ({
  title,
  position,
  onBeforeClose,
  onClose,
  closeButton,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const closeTimeout = useRef<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpen(true);
    return () => {
      // If the sidebar is unmounted, then we don't want to call the onClose function.
      // This important when current sidebar is replaced with another one.
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, []);

  const handleClose = useCallback(() => {
    if (onBeforeClose && !onBeforeClose()) return;
    setOpen(false);
    // Let the animation finish before calling onClose
    closeTimeout.current = window.setTimeout(onClose, 300);
  }, [setOpen, onBeforeClose, onClose]);
  useOnClickOutside(ref, handleClose);

  let button = null;
  if (closeButton) {
    button = (
      <Button
        icon={<MAIcon name="close" />}
        secondary
        borderless
        compact
        className={styles.SidebarClose}
        onClick={handleClose}
      />
    );
  }
  return (
    <div
      className={cx({
        [styles.SidebarLeft]: position !== 'right',
        [styles.SidebarRight]: position === 'right',
        [styles.SidebarOpen]: open,
      })}
      ref={ref}
    >
      <div className={styles.SidebarHeader}>
        {title}
        {button}
      </div>
      <div className={styles.SidebarBody}>{children}</div>
    </div>
  );
};

export default Sidebar;
