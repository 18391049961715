import * as React from 'react';
import { DefinitionBlock } from 'models/publisher/block';
import { Alignment, Layout } from 'models/publisher/format';

type MenuOption = { layout: Layout; alignment: Alignment };

export function useMapCombos<T extends React.ReactNode>(
  format_options: DefinitionBlock['format_options']
): (cb: (option: MenuOption) => T) => T[] {
  const options = React.useMemo(() => {
    const memo: MenuOption[] = [];
    format_options?.layouts?.forEach((layout) => {
      format_options?.alignments?.forEach((alignment) => {
        memo.push({ layout, alignment });
      });
    });
    return memo;
  }, [format_options]);
  return React.useCallback((cb) => options.map(cb), [options]);
}
