import { useRef, useEffect, useState } from 'react';

// Copied from:
// https://www.jayfreestone.com/writing/react-portals-with-hooks/
//
// The first time this is run, the target element is created but not
// yet inserted into the DOM. Normally this is OK, but if the code is
// doing anything that assumes it is in the DOM already, it will fail.
// For example, animations or using getBoundingClientRect().
//
// For those reasons, we've added the `ready` flag that is returned
// along with the target element. It can be ignored usually, but for
// DOM-y stuff, take the value of the flag into consideration before
// rendering the children inside the target.

/**
 * Creates DOM element to be used as React root.
 */
function createRootElement(id: string): Element {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

/**
 * Appends element as last child of body.
 */
function addRootElement(rootElem: Element): void {
  const lastChild = document.body.lastElementChild;
  if (lastChild) {
    document.body.insertBefore(rootElem, lastChild.nextElementSibling);
  } else {
    // eslint-disable-next-line no-console
    console.warn('failed to create portal element');
  }
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 * @returns {boolean} If the target is in the DOM yet (useful for animations and dimensions)
 */
export function usePortal(
  id: string
): {
  ready: boolean;
  target: Element;
} {
  const rootElemRef = useRef<Element>();
  const [ready, setReady] = useState(false);

  useEffect(
    function setupElement() {
      // Look for existing target dom element to append to
      const existingParent = document.querySelector(`#${id}`);
      // Parent is either a new root or the existing dom element
      const parentElem: Element = existingParent || createRootElement(id);

      // If there is no existing DOM element, add a new one.
      if (!existingParent) {
        addRootElement(parentElem);
      }

      // Add the detached element to the parent
      if (rootElemRef.current) parentElem.appendChild(rootElemRef.current);
      // Sometimes the DOM isn't quite as ready as it claims.
      setTimeout(() => setReady(true), 10);

      return function removeElement() {
        if (rootElemRef.current) rootElemRef.current.remove();
        if (!parentElem.childNodes.length) {
          setReady(false);
          parentElem.remove();
        }
      };
    },
    [id]
  );

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return {
    ready,
    target: getRootElem(),
  };
}
