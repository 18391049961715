import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { DismissType } from 'DesignSystem/Components/ClickDropdown';
import { WhitePillClass } from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import { Button } from 'DesignSystem/Form/InputElements';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Plus } from 'shared/icons';
import { ContentTitle, SettingsCard } from '../../SettingsCard';
import { InfoTooltip } from '../../Shared/InfoTooltip';
import { SelectCustomSlugs, renderSlugPill } from './SelectCustomSlugs';

interface CustomSlugsSectionProps {
  disabled?: boolean;
}

export const CustomSlugsSection: React.FC<CustomSlugsSectionProps> = ({
  disabled,
}) => {
  const { id: programId } = useProgram();
  const customSlugsEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Configure.CustomURLsEnabled'
  ).data?.value;

  const { settings, update } = useSettings();

  const [linkedCustomSlugs, setLinkedCustomSlugs] = useState(
    settings.customSlugs || []
  );
  const linkCustomSlugs = (
    dismiss: DismissType,
    setDismissable: (dismissable: boolean) => void
  ) => (
    <SelectCustomSlugs
      disabled={disabled}
      onCancel={() => {
        setLinkedCustomSlugs(settings.customSlugs || []);
        dismiss();
      }}
      onSave={() => {
        update({ customSlugs: linkedCustomSlugs });
        dismiss();
      }}
      setDismissable={setDismissable}
      linkedCustomSlugs={linkedCustomSlugs}
      setLinkedCustomSlugs={setLinkedCustomSlugs}
    />
  );

  // don't block the editor/publisher from editing campaign
  const {
    permissions: { linkCustomSlug },
  } = usePermissions();

  return (
    <>
      {customSlugsEnabled && linkCustomSlug && (
        <SettingsCard
          isEditing={false}
          editRenderProp={linkCustomSlugs}
          showEditButton={linkedCustomSlugs.length !== 0}
          disabled={disabled}
        >
          {(toggleEditing: () => void) => (
            <div>
              <ContentTitle>
                Custom URL
                <InfoTooltip message="A URL alias will show up in the employee experience" />
              </ContentTitle>
              <Pills
                values={linkedCustomSlugs}
                render={renderSlugPill}
                pillClassName={WhitePillClass}
              />
              {linkedCustomSlugs.length === 0 && (
                <Button
                  disabled={disabled}
                  compact
                  secondary
                  onClick={toggleEditing}
                  label={
                    <>
                      <Plus />
                      <Box padding={[0, 12]}>Add Custom URL</Box>
                    </>
                  }
                />
              )}
            </div>
          )}
        </SettingsCard>
      )}
    </>
  );
};
