import React from 'react';

import { ClickDropdown } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import {
  CONTROL_TYPE_DO_NOT_TRANSLATE,
  CONTROL_TYPE_REMOVE,
  CONTROL_TYPE_REPLACE,
  LanguageControl,
  LanguageControlTypes,
} from 'models/language-controls/language-control';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { capitalize } from 'utility/strings';
import { convertSnakeCaseToSentenceCase } from 'utility/text';
import styles from './language-control-edit-page.module.css';

type RuleTypeSelectProps = {
  value?: LanguageControlTypes;
  onChange: (data: Partial<LanguageControl>) => void;
};

const CONTROL_TYPES: LanguageControlTypes[] = [
  CONTROL_TYPE_DO_NOT_TRANSLATE,
  CONTROL_TYPE_REMOVE,
  CONTROL_TYPE_REPLACE,
];

export const RuleTypeSelect: React.FC<RuleTypeSelectProps> = ({
  value,
  onChange,
}) => {
  const [selectedControlType, setSelectedControlType] = React.useState<
    LanguageControlTypes | undefined
  >(value);

  const availableControlTypes: Record<string, string> = CONTROL_TYPES.reduce(
    (memo, controlType) => ({
      ...memo,
      [controlType]: capitalize(convertSnakeCaseToSentenceCase(controlType)),
    }),
    {}
  );

  const renderRow = (rowId: string) => (
    <Text.Body>{availableControlTypes[rowId]}</Text.Body>
  );

  const dropdownRenderProp = (dismiss: () => void) => (
    <InfiniteSelect
      rowIds={[...CONTROL_TYPES]}
      rowRenderProp={renderRow}
      maxHeight={240}
      itemHeight={40}
      selectedIds={selectedControlType ? [selectedControlType] : []}
      onSelectedIdsChange={() => {}}
      onHandleChange={(id) => {
        const controlType = id as LanguageControlTypes;
        setSelectedControlType(controlType);
        onChange({ controlType });
        dismiss();
      }}
      multiSelect={false}
      itemClassName={styles.dropdownItem}
      clearDisabled
    />
  );

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp} autoUpdate>
      <Button
        block
        input
        label={
          selectedControlType
            ? availableControlTypes[selectedControlType]
            : 'Select a rule type'
        }
        badge={<SVGIcon name="DownChevron" />}
      />
    </ClickDropdown>
  );
};
