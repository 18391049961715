import * as React from 'react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import * as Text from '../Typography';
import { Button } from '../Form';

export const ErrorBoundaryScreen: React.FC = ({ children }) => {
  return <ErrorBoundary ErrorComponent={ErrorScreen}>{children}</ErrorBoundary>;
};

function ErrorScreen() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Text.Heading color={Text.color.gray40}>Unexpected Error</Text.Heading>
      <div>
        <Text.Body block>Reload the page to try again</Text.Body>
        <Button onClick={() => window.location.reload()} label="Reload" />
      </div>
    </div>
  );
}
