import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { SearchBar } from 'shared/SearchBar';
import { FilterDropdown } from 'shared/FilterDropdown';
import { OptionType } from 'hooks/common';
import { FilterBoolean } from '../Shared/FilterBoolean';

const STATUSES = [
  { value: 'enabled', label: 'Active' },
  { value: 'disabled', label: 'Archived' },
];

const TYPES = [
  { value: 'custom', label: 'Custom' },
  { value: 'dynamic', label: 'Dynamic' },
  { value: 'generated', label: 'Generated' },
  { value: 'snapshot', label: 'Snapshot' },
  { value: 'static', label: 'Static' },
  { value: 'standard', label: 'Standard' },
  { value: 'scim', label: 'ID Provider' },
];

type PropsType = {
  // eslint-disable-next-line
  onChange: (filterName: string, value: any) => void;
  searchTerm?: string;
  tagSearchTerm?: string;
  tagOptions: OptionType[];
  isFavorites?: boolean;
  statuses?: string[];
  tags?: string[];
  types?: string[];
};

export const FiltersBar: React.FC<PropsType> = ({
  onChange,
  searchTerm,
  tagSearchTerm,
  isFavorites,
  tagOptions,
  statuses,
  tags,
  types,
}) => {
  let tagOptionsInUse = tagOptions;
  if (tagOptions.length === 0 && (tags?.length || 0) > 0) {
    tagOptionsInUse =
      tags?.map((t) => {
        return { value: t, label: t };
      }) || [];
  }
  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={(value) => onChange('search', value)}
        placeholder="Search audiences"
        panelClassName={styles.searchBarWrapper}
      />
      <FilterDropdown
        label="Status"
        onChange={(value) => onChange('statuses', value)}
        options={STATUSES}
        selectedOptions={STATUSES.filter((opt) =>
          statuses?.includes(opt.value)
        )}
      />
      <FilterDropdown
        label="Type"
        onChange={(value) => onChange('types', value)}
        options={TYPES}
        selectedOptions={TYPES.filter((opt) => types?.includes(opt.value))}
      />
      <FilterDropdown
        label="Tag"
        searchEnabled
        clearDisabled={false}
        searchPlaceholder="Search tags"
        hasClearSearchButton
        onChange={(value) => onChange('tags', value)}
        options={tagOptionsInUse}
        onSearchTermChange={(value) => onChange('tagSearch', value)}
        searchTerm={tagSearchTerm}
        selectedOptions={tagOptionsInUse.filter((opt) =>
          tags?.includes(opt.value)
        )}
      />
      <FilterBoolean
        title="Favorites"
        checked={isFavorites}
        onClick={() => onChange('isFavorites', !isFavorites)}
      />
    </div>
  );
};
