import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { Topic } from '../../../../../../../models/topic';
import styles from './styles.module.css';
import { Icon } from '../../../../../../../shared/Icon';
import { getTopicStatusText } from '../../../TopicStatusPill';
import { Section } from '../../../../../../../DesignSystem/Form';

interface Props extends RouteComponentProps {
  topic: Topic;
}

export function TopicStatus({ topic }: Props): JSX.Element {
  const { updatedAt } = topic;
  const title = (
    <span className={styles.topicStatus}>
      {!topic.archived ? (
        <Icon iconType="SVG" iconName="CheckCircleOutline" />
      ) : (
        <Icon iconType="SVG" iconName="CloseCircleOutline" />
      )}
      {getTopicStatusText(topic)}
    </span>
  );
  const description = (
    <>
      Initial Activation Date:{' '}
      <strong>
        {DateTime.fromISO(topic.createdAt).toFormat('MMM dd, yyyy, hh:mma')}
      </strong>
    </>
  );
  const button = (
    <span className={styles.topicUpdatedAt}>
      Last updated: {DateTime.fromISO(updatedAt).setLocale('en').toRelative()}
    </span>
  );
  return (
    <Section
      className={styles.section}
      title={title}
      button={button}
      description={description}
    />
  );
}
