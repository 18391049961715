import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';

export const EditPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  return (
    <BossViewPage
      app="samba"
      title="Microapps"
      fullScreen
      src={`editor/content-anywhere/${id}`}
      returnUrls={['/configure/microapps']}
    />
  );
};
