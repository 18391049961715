import React, { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Topic } from 'models/topic';
import { ClickDropdown, ClickDropdownHandle } from 'shared/ClickDropdown';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import cx from 'classnames';
import styles from './topic-select.module.css';

export const TopicsFilter: React.FC<{
  value: Array<Topic>;
  onChange: (value: Array<Topic>) => void;
  align?: 'left' | 'right' | 'center';
}> = ({ value, onChange, align = 'left' }) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');
  const clearSearch = React.useCallback(() => {
    setSearch('');
  }, []);

  const {
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useTopicsInfiniteQuery({ programId, search });

  const topicsByRowId = useMemo(() => {
    const map: { [key: string]: Topic } = {};
    data.forEach((topic) => {
      map[topic.id] = topic;
    });
    // retain the currently selected topics
    value.forEach((topic) => {
      if (!map[topic.id]) map[topic.id] = topic;
    });

    return map;
  }, [data, value]);

  const rowIds = Object.keys(topicsByRowId).sort((a, b) => {
    const nameA = topicsByRowId[a].name?.toLowerCase();
    const nameB = topicsByRowId[b].name?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const selectedIds = value.map(({ id }) => id?.toString());

  const renderRow = React.useCallback(
    (rowId: string) => {
      const item = topicsByRowId[rowId];
      return item ? (
        <div className={cx(styles.title, styles.filterTitle)}>
          <span>{item.name}</span>
        </div>
      ) : null;
    },
    [topicsByRowId]
  );

  const onSelectedIdsChange = useCallback(
    (ids: Array<string>) => onChange(ids.map((id) => topicsByRowId[id])),
    [onChange, topicsByRowId]
  );

  const selectedValues = React.useMemo(
    () => selectedIds.map((id) => topicsByRowId[id].name),
    [topicsByRowId, selectedIds]
  );

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={300}
        itemHeight={32}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        itemClassName="filter-item"
        searchEnabled
        searchTerm={search}
        searchPlaceholder="Search topics"
        onSearchTermChange={setSearch}
        checkboxClassName={styles.filterCheckbox}
        hasClearSearchButton
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [
    renderRow,
    rowIds,
    search,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    onSelectedIdsChange,
    selectedIds,
  ]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  const clickDropdownRef = React.useRef<ClickDropdownHandle>(null);

  // When a new dataset is fetched, the width of the dropdown may change causing it to
  // possibly overflow off of the screen. The use of setImmediate is necessary to wait
  // for the InfiniteSelect component to finish rendering before having the dropdown check
  // if its positioning needs to be corrected.
  React.useEffect(() => {
    setImmediate(() => clickDropdownRef.current?.correctDropdownOverflow());
  }, [data]);

  return (
    <ClickDropdown
      onClose={clearSearch}
      dropdownRenderProp={dropdownRenderProp}
      dropdownClassName={`dropdown-align-${align}`}
      ref={clickDropdownRef}
    >
      <TriggerButton name="Topics" values={selectedValues} />
    </ClickDropdown>
  );
};
