import { useAudienceUsersQuery } from 'hooks/audience';
import { Audience, combinedAudiencesQuery } from 'models/audience';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import React, { FC } from 'react';
import { pluralize } from 'utility/text';

export interface AudienceCountProps {
  audiences: Audience[];
  programId: number;
  suffix: string;
}

export const AudienceCount: FC<AudienceCountProps> = ({
  audiences,
  programId,
  suffix,
}: AudienceCountProps) => {
  const query = combinedAudiencesQuery(audiences);

  const {
    data: audienceUserCount,
    isLoading: audienceUserCountLoading,
  } = useAudienceUsersQuery(programId, query);

  return audienceUserCountLoading ? (
    <LoadingSpinner size="small" />
  ) : (
    <>
      {audienceUserCount?.totalObjects ?? '?'}{' '}
      {pluralize(audienceUserCount?.totalObjects ?? 0, suffix)}
    </>
  );
};
