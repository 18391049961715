import { ContextCommunication } from 'models/context-communication';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';

export type ContextCommunicationData = Array<ContextCommunication>;

export async function fetchContextCommunication({
  programId,
}: {
  programId: number;
}): Promise<ContextCommunicationData> {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/context`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching communication context: ${response.status}`);
}
