import React from 'react';
import cx from 'classnames';
import { BoxIntegrationFieldData } from 'models/publisher/block';
import { Button } from 'DesignSystem/Form/InputElements';
import * as Text from 'DesignSystem/Typography';
import { Field, Fieldset } from 'shared/Fieldset';
// import { PreviewModal } from 'shared/Box/PreviewModal';
import { FieldProps } from '../FieldProps';
import styles from './box.module.css';
import forms from '../form.module.css';

export const BoxIntegration: React.FC<FieldProps<BoxIntegrationFieldData>> = ({
  data: initial,
  default_data,
  onChange,
}) => {
  const [data, setData] = React.useState(initial || default_data);
  const [showModal, setShowModal] = React.useState(false);
  const [resourceName, setResourceName] = React.useState(data?.name);

  React.useEffect(() => {
    if (resourceName) setData((old) => old && { ...old, name: resourceName });
  }, [resourceName, setData]);

  React.useEffect(() => {
    if (data) onChange(data);
  }, [data, onChange]);

  // picker needs to call updateBoxIntegrationFile when changed
  return (
    <>
      {data && (
        <Fieldset className={forms.fieldset}>
          <Field
            label="Title"
            className={cx(styles.title, styles.field, forms.field)}
          >
            <textarea
              className={styles.input}
              dir="auto"
              value={data.name}
              onChange={(event) => setResourceName(event.target.value)}
            />
          </Field>
          <Field
            label="Title"
            className={cx(styles.title, styles.field, forms.field)}
          >
            <Text.Caption>Type: {data.type.toUpperCase()}</Text.Caption>
          </Field>
        </Fieldset>
      )}
      <Button onClick={() => setShowModal(true)} type="button">
        Preview
      </Button>
      {showModal && <Text.Body>Modal!</Text.Body>}
    </>
  );
};
