import React from 'react';
import { DeepLinkedParamsType } from 'App/Program/Main/Insight/contexts/filtersStateReducer';
import { Filter } from 'models/insight/Filter';
import { safeTransformSetToArray } from 'utility/ie11Utils';
import { WidgetType } from 'models/insight/json/filterJson';

type DefaultValuesOrValue = {
  defaultValues?: Set<string>;
  defaultValue?: string;
};

/**
 * Default values for a filter can come from two sources:
 * the `filter.default` attribute from the API, or
 * from url deep linked params (this takes priority)
 *
 * This hook will...
 * 1. return the proper default from either the api or the query param
 * 2. return the default(s) as either a set or a single value
 *    - depending on the filter type, the appropriate type should be used
 *    - eg, list dropdowns should know to use the values as a set
 *
 * For `initialRequired` filters, reasonable defaults will be selected
 *    - date range: past 30 days
 *    - text/number: empty
 *    - list filters: first value from list of options
 * */
export const useDefaultValuesOrDeepLinkedParams = (
  deepLinkedParams: DeepLinkedParamsType,
  filter?: Filter
): DefaultValuesOrValue => {
  // get default values either from the deep link params or the filter.default attr
  // TODO: there is similar logic in FiltersStateContext. we should consolidate in the future
  const deepLinkedValues: Set<string> | undefined = React.useMemo(() => {
    if (filter === undefined) return undefined;

    const defaultValueFromFilter = new Set<string>();

    const defaultFilterValue = filter.default;
    if (defaultFilterValue) {
      // category params may have string or number arrays as defaults
      if (Array.isArray(defaultFilterValue)) {
        defaultFilterValue.forEach((value: number | string) =>
          defaultValueFromFilter.add(String(value))
        );

        // all other values are strings or numbers
      } else if (
        typeof defaultFilterValue === 'string' ||
        typeof defaultFilterValue === 'number'
      ) {
        const stringifiedDefaultFilterValue = String(defaultFilterValue);
        if (stringifiedDefaultFilterValue.split(',').length === 1) {
          defaultValueFromFilter.add(stringifiedDefaultFilterValue);
        } else {
          stringifiedDefaultFilterValue.split(',').forEach((value) => {
            defaultValueFromFilter.add(value.trim());
          });
        }
      }
    }
    return deepLinkedParams?.get(filter.slug) || defaultValueFromFilter;
  }, [filter, deepLinkedParams]);

  // return a single value if `allowMultiple = false`
  const defaultValue: string | undefined = React.useMemo(() => {
    if (
      filter === undefined ||
      deepLinkedValues === undefined ||
      filter.allowMultiple
    )
      return undefined;

    // it is possible that an empty set is here (if no values were selected)
    // in this case, an undefined will be return from the operation below
    return safeTransformSetToArray(deepLinkedValues)[0] as string | undefined;
  }, [deepLinkedValues, filter]);

  // return a set of values if `allowMultiple = true` OR `widgetType = category`
  // this means that single-select category dropdowns
  // will receive a set even though it only accepts one value
  const defaultValues: Set<string> | undefined = React.useMemo(() => {
    if (filter === undefined) return undefined;
    if (
      deepLinkedValues !== undefined &&
      (filter.widgetType === WidgetType.Category || filter.allowMultiple)
    )
      return deepLinkedValues;
    return undefined;
  }, [deepLinkedValues, filter]);

  return { defaultValues, defaultValue };
};
