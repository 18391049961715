import React from 'react';
import { download } from 'utility/download';
import { DownloadDashboardButton } from './DownloadDashboardButton';

const DOWNLOAD_HEADER_NAMES: { [key: string]: string } = {
  EmployeeName: 'Employee Name',
  EmployeeType: 'Employee Type',
  Position: 'Position',
  Department: 'Department',
  TenureLength: 'Tenure Length',
  'MIN(prediction)': 'Risk Level',
};

const PAGINATION_PARAMETER = 'Pagination - Page Size';

export const DownloadWorksheetData: React.FC<{
  worksheetToDownload: Tableau.Sheet;
  workbook: Tableau.IWorkbook;
}> = ({ worksheetToDownload, workbook }) => {
  const [downloadError, setDownloadError] = React.useState<boolean>(false);

  const triggerDownload = (csvString: string, filename = 'data.csv'): void => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    download(blob, filename);
  };

  const convertToCSV = (
    headers: Tableau.Column[],
    data: Array<Array<Tableau.DataValue>>
  ): string => {
    // Pre-sort and filter headers based on DOWNLOAD_HEADER_NAMES,
    // and the index returned from the Tableau worksheet's columns
    const activeHeaders = headers
      .filter((h) => DOWNLOAD_HEADER_NAMES[h.fieldName.replace(' ', '')])
      .sort((a, b) => a.index - b.index);

    // Create the header row by mapping directly to the DOWNLOAD_HEADER_NAMES
    const headerRow = activeHeaders
      .map((h) => DOWNLOAD_HEADER_NAMES[h.fieldName.replace(' ', '')])
      .join(',');

    const headerPositions = new Set(activeHeaders.map((ah) => ah.index));

    // Convert data rows to CSV, mapping only values that correspond to active header positions
    const rows = data
      .map((row) =>
        row
          .filter((_, index) => headerPositions.has(index))
          .map((value) => value.formattedValue?.replace(',', '-') ?? '')
          .join(',')
      )
      .join('\n');
    return `${headerRow}\n${rows}`;
  };

  const downloadWorksheet = async (
    e: React.MouseEvent | React.KeyboardEvent
  ): Promise<void> => {
    e.preventDefault();
    try {
      const parameter = (await workbook.getParametersAsync())?.find(
        (p) => p.name === PAGINATION_PARAMETER
      );
      if (!parameter) {
        setDownloadError(true);
        return;
      }
      await workbook.changeParameterValueAsync(parameter.name, 10000);

      // Verify summaryDataReader has data
      const dataReader = await worksheetToDownload.getSummaryDataReaderAsync();
      if (dataReader.totalRowCount === 0) {
        setDownloadError(true);
        return;
      }

      const dataTable = await dataReader.getAllPagesAsync();
      triggerDownload(
        convertToCSV(dataTable.columns, dataTable.data),
        `${worksheetToDownload.name}.csv`
      );
      await workbook.changeParameterValueAsync(
        parameter.name,
        parameter.currentValue.value
      );
      await dataReader.releaseAsync();
    } catch (error) {
      setDownloadError(true);
    }
  };

  return (
    <div style={{ display: 'block', marginTop: '20px' }}>
      <div style={{ display: 'inline-flex' }}>
        {downloadError && (
          <div>
            There was an issue with your download. Please try again later or
            contact support
          </div>
        )}
        <DownloadDashboardButton
          title="Download CSV of this dashboard"
          onClick={downloadWorksheet}
        />
      </div>
    </div>
  );
};
