import React from 'react';
import { Button } from 'DesignSystem/Form';
import Modal from 'simple-react-modal';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import progressModalStyles from './progress-modal.module.css';

type ProcessCancellationConfirmationProps = {
  isOpen: boolean;
  title: string;
  description: string;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

export const ProcessCancellationConfirmation = ({
  isOpen,
  title,
  description,
  setIsOpen,
  onConfirm,
}: ProcessCancellationConfirmationProps): JSX.Element => {
  return (
    <>
      <Modal
        style={{ padding: '0' }}
        show={isOpen}
        containerStyle={{
          backgroundColor: 'var(--color-gray00)',
          borderRadius: '16px',
          padding: '0',
          width: '500px',
        }}
      >
        <section>
          <div className={progressModalStyles.bannerContent}>
            <Text.Heading semibold>{title}</Text.Heading>
          </div>
          <div className={progressModalStyles.body}>
            <Text.Body>{description}</Text.Body>
          </div>

          <div className={progressModalStyles.footer}>
            <div className={progressModalStyles.footerContent}>
              <Flex end>
                <Button
                  className={progressModalStyles.confirmButton}
                  onClick={() => setIsOpen(false)}
                  label="Cancel"
                  text
                />

                <Button
                  className={progressModalStyles.confirmButton}
                  onClick={() => {
                    onConfirm();
                    setIsOpen(false);
                  }}
                  label="Yes, Stop"
                  text
                />
              </Flex>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};
