import React from 'react';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { usePublisher } from 'contexts/publisher';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body } from 'DesignSystem/Typography';
import { hasUnsafeWebFonts } from 'models/publisher/style';
import { Checkbox } from 'shared/Checkbox';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import styles from '../../theme/compose.module.css';

export const FontPreviewOptions: React.FC = () => {
  const { webFontsEnabled, toggleWebFonts } = React.useContext(PreviewContext);

  const publisher = usePublisher();
  const showFallbackFontPreview =
    hasUnsafeWebFonts(publisher.post.styles.fonts) ||
    publisher.post.blocks.some(
      (block) =>
        block.style_data?.fonts &&
        hasUnsafeWebFonts(block.style_data.fonts as Record<string, string>)
    );

  if (!showFallbackFontPreview) return null;

  return (
    <>
      <Flex start>
        <Body uppercase bold block>
          Font preview
        </Body>
        <HoverTooltip
          align="left"
          content={
            <Tooltip
              title="What is a fallback font?"
              description="Fallback fonts are fonts that will reliably display
                on any device or email clients. This campaign includes fonts
                that may not render in all cases, for example if a user does
                not have the font downloaded on their device. In these cases,
                a safe fallback font will display."
            />
          }
        />
      </Flex>
      <label htmlFor="preview_fonts_selected">
        <Flex start style={{ marginBottom: '8px' }}>
          <Checkbox
            id="preview_fonts_selected"
            type="radio"
            style={{ marginRight: '12px' }}
            checked={webFontsEnabled}
            onChange={toggleWebFonts}
          />
          Preview with selected fonts
        </Flex>
      </label>
      <label htmlFor="preview_fonts_fallback">
        <Flex start style={{ marginBottom: '8px' }}>
          <Checkbox
            id="preview_fonts_fallback"
            type="radio"
            style={{ marginRight: '12px' }}
            checked={!webFontsEnabled}
            onChange={toggleWebFonts}
          />
          Preview with fallback fonts
        </Flex>
      </label>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <p className={styles.previewNote}>
        Note: This design includes fonts that may not display on all email
        clients.&nbsp;
        <span onClick={toggleWebFonts} role="button" tabIndex={0}>
          Preview with fallback fonts
        </span>
        &nbsp;in order to see what the design will look like when those fonts
        are not supported.
      </p>
    </>
  );
};
