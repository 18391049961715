import { RouteComponentProps } from '@reach/router';
import React, { createContext } from 'react';
import { useProgram } from '../../../../../contexts/program';
import {
  useCategoriesQuery,
  useWorkbooksQuery,
} from '../../../../../hooks/insights/useInsightsPlusApi';

type WorkbooksContextType = {
  isLoading: boolean;
  getWorkbook: (id: string) => Tableau.Workbook | undefined;
  workbooksQuery: {
    isLoading: boolean;
    workbooks: Tableau.Workbook[];
    error: unknown;
  };
  getWorkbookCategoryByTag: (
    tag: string
  ) => Tableau.WorkbookCategory | undefined;
  categoriesQuery: {
    isLoading: boolean;
    categories: Tableau.WorkbookCategory[];
    error: unknown;
  };
};

const defaultState: WorkbooksContextType = {
  isLoading: false,
  workbooksQuery: {
    isLoading: false,
    workbooks: [],
    error: undefined,
  },
  getWorkbook: () => undefined,
  categoriesQuery: {
    isLoading: false,
    categories: [],
    error: undefined,
  },
  getWorkbookCategoryByTag: () => undefined,
};

export const WorkbooksContext = createContext<WorkbooksContextType>(
  defaultState
);

export const WorkbooksProvider: React.FC<RouteComponentProps> = ({
  children,
}) => {
  const { id: programId } = useProgram();
  const workbooksQuery = useWorkbooksQuery(programId);
  const categoriesQuery = useCategoriesQuery(programId);
  const getWorkbook = (id: string) =>
    workbooksQuery.workbooks.find((w) => w.id === id) || undefined;
  const getWorkbookCategoryByTag = (tag: string) =>
    categoriesQuery.categories.find((c) => c.tag === tag) || undefined;

  return (
    <WorkbooksContext.Provider
      value={{
        ...defaultState,
        workbooksQuery,
        getWorkbook,
        categoriesQuery,
        getWorkbookCategoryByTag,
      }}
    >
      {children}
    </WorkbooksContext.Provider>
  );
};
