import React, { useCallback } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import styles from './filters.module.css';
import { TriggerButton } from './TriggerButton';
import { useFeatureFlagsQuery } from '../../../hooks/feature-flags';
import { useProgram } from '../../../contexts/program';

export const FlaggedFilter: React.FC<{
  value: Array<string>;
  onChange: (value: Array<string>) => void;
  align?: 'left' | 'right' | 'center';
}> = ({ value, onChange, align = 'left' }) => {
  const { id: programId } = useProgram();
  const flagPostEnabled = !!useFeatureFlagsQuery(
    programId,
    'EE.FlagPost.Enabled'
  ).data?.value;

  const renderRow = (row: string) => {
    return (
      <div>
        <span className={styles.filterTitle}>{row}</span>
      </div>
    );
  };

  const onSelectedIdsChange = useCallback((ids: string[]) => onChange(ids), [
    onChange,
  ]);

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    const types = ['comments'];
    if (flagPostEnabled) {
      types.push('campaigns');
    }

    return (
      <InfiniteSelect
        rowIds={types}
        rowRenderProp={renderRow}
        maxHeight={400}
        itemHeight={32}
        selectedIds={value}
        onSelectedIdsChange={onSelectedIdsChange}
        className="no-search"
        itemClassName="filter-item"
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [onSelectedIdsChange, value, flagPostEnabled]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  return (
    <ClickDropdown
      dropdownRenderProp={dropdownRenderProp}
      dropdownClassName={`dropdown-align-${align}`}
    >
      <TriggerButton name="Flagged" values={value} />
    </ClickDropdown>
  );
};
