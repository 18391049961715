import React from 'react';
import { Button } from 'DesignSystem/Form';
import Modal from 'simple-react-modal';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import progressModalStyles from './progress-modal.module.css';

type ProgressConfirmationModalProps = {
  title: string;
  description: string;
  body: JSX.Element | string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  hideAction: boolean;
};
export const ProgressConfirmationModal = ({
  title,
  description,
  body,
  isOpen,
  setIsOpen,
  hideAction,
}: ProgressConfirmationModalProps): JSX.Element => {
  return (
    <>
      <Modal
        style={{ padding: '0' }}
        show={isOpen}
        containerStyle={{
          backgroundColor: 'var(--color-gray00)',
          borderRadius: '16px',
          padding: '0',
          width: '500px',
        }}
      >
        <section>
          <div className={progressModalStyles.bannerContent}>
            <Text.Heading semibold>{title}</Text.Heading>
          </div>
          <div className={progressModalStyles.body}>
            <Flex center className={progressModalStyles.loadingContainer}>
              <LoadingSpinner size="medium" />
            </Flex>
            <div className={progressModalStyles.centerBody}>
              <Text.Subheading bold>{description}</Text.Subheading>
            </div>
            <Text.Body>{body}</Text.Body>
          </div>

          <div className={progressModalStyles.footer}>
            <div className={progressModalStyles.footerContent}>
              <Flex
                end
                className={
                  hideAction ? progressModalStyles.visibilityHidden : undefined
                }
              >
                <Button
                  className={progressModalStyles.confirmButton}
                  onClick={() => setIsOpen(false)}
                  label="Ok"
                  text
                />
              </Flex>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};
