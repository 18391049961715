import React, { useCallback } from 'react';
import { TextInput } from 'shared/TextInput';
import { FocusDropdown } from 'shared/FocusDropdown';
import { FAIcon } from 'shared/Icon/FAIcon';
import { PropsType } from './types';
import { Dropdown } from './Dropdown';
import styles from './styles.module.css';

function useOptions(options: PropsType['options'], searchTerm: string) {
  const [filtered, setFiltered] = React.useState(options);
  React.useEffect(() => {
    const term = searchTerm.trim().toLowerCase();
    if (term === '') {
      setFiltered(options);
    } else {
      const filteredOptions = options.map((option) => ({
        ...option,
        hidden: !option.label.toLowerCase().includes(term),
      }));
      setFiltered(filteredOptions);
    }
  }, [options, searchTerm]);
  return filtered;
}

export const AttributeSelect: React.FC<PropsType> = ({
  options,
  value,
  onChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const filtered = useOptions(options, searchTerm);
  const handleSearch = useCallback((e) => {
    setSearchTerm(e.currentTarget.value);
  }, []);

  const handleOpen = useCallback(() => {
    setDropdownOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  function dropdownRenderer() {
    return (
      <Dropdown
        options={filtered}
        value={value}
        onChange={onChange}
        onClose={handleClose}
      />
    );
  }

  return (
    <FocusDropdown
      dropdownClassName={styles.Dropdown}
      dropdownRenderProp={dropdownRenderer}
      isOpen={dropdownOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {(onFocus, ref) => (
        <div className={styles.InputContainer}>
          <TextInput
            ref={ref}
            className={styles.Input}
            onFocus={onFocus}
            onInput={handleSearch}
            value={searchTerm}
            placeholder="Type to search attributes"
          />
          <FAIcon name="search" className={styles.SearchIcon} />
          <FAIcon name="chevron-down" className={styles.ArrowIcon} />
        </div>
      )}
    </FocusDropdown>
  );
};
