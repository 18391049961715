import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useUserExportInfiniteQuery } from 'hooks/user-exports';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { SearchBar } from 'shared/SearchBar';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { Body, Subheading } from 'DesignSystem/Typography';
import filterStyles from 'components/content/ContentFilterBar/filters.module.css';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { WithPermission } from 'shared/WithPermission';
import { UserExportBanner } from './UserExportBanner';
import { Filters } from './components/Filters';
import styles from '../../../Topics/topics.module.css';

export const UserExport: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [status, setStatus] = useFilterDropdownQueryString('status');

  const parentRef = React.useRef<HTMLDivElement>(null);

  const breadcrumbs = [
    { to: '..', label: 'Configure' },
    { label: 'User Exports' },
  ];

  const filterChangeCallbacks: { [key: string]: (values: string[]) => void } = {
    status: setStatus,
  };

  function onFilterChange(filterName: string, values: string[]) {
    filterChangeCallbacks[filterName](values);
  }

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useUserExportInfiniteQuery({
    programId,
    search: useDebounce(searchTerm),
    status: useDebounce(status),
    order: 'created_at_desc',
  });

  const emptyList = <Subheading block>No feeds match the criteria.</Subheading>;

  const filterBar = (
    <div className={filterStyles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search exports"
        panelClassName={styles.searchBar}
      />
      <Filters onChange={onFilterChange} status={status} />
    </div>
  );

  const loading = <Body block> Loading... </Body>;
  const itemCount = data.length || 0;

  return (
    <WithPermission permissions={['confContentAccess']}>
      <ListPage
        breadcrumbs={breadcrumbs}
        emptyList={emptyList}
        filterbar={filterBar}
        loading={loading}
        title="User Exports"
      >
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <InfiniteBannerList
              itemCount={itemCount}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              height={height}
              parentRef={parentRef}
              noItemsComponent={<div>No Exports match this criteria.</div>}
            >
              {(index: number) => <UserExportBanner data={data[index]} />}
            </InfiniteBannerList>
          )}
        </InfiniteContainer>
      </ListPage>
    </WithPermission>
  );
};
