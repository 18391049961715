import React, { useEffect, useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import {
  Span,
  Subheading,
  styles,
  Caption,
  Body,
} from 'DesignSystem/Typography';
import { Pills } from 'DesignSystem/Components/Pills';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Audience } from 'models/audience';
import { Button } from 'DesignSystem/Form/InputElements';
import { useToggle } from 'hooks/useToggle';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { Checkbox } from 'shared/Checkbox';
import { Builder } from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/Builder';
import { SelectGroup } from 'DesignSystem/Form/SelectGroup';
import { PRIORITIES, PRIORITIES_BY_NAME, Priority } from 'models/priority';
import { ClickDropdownDismissableType } from 'DesignSystem/Components/ClickDropdown';
import { WhitePillClass } from 'DesignSystem/Components/Pill';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { datadogRum } from '@datadog/browser-rum';
import cstyles from './add-audiences.module.css';
import { SelectAudience } from './SelectAudience';
import { SaveModalButtons } from '../Shared/SaveModalButtons';
import { InfoTooltip } from '../Shared/InfoTooltip';
import { archivedAudiences } from '../Shared/common-utils';

type AddAudiencesProps = {
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  hideUserCount?: boolean;
} & ClickDropdownDismissableType;

const Bold = Span(styles.Bold);

export const AddAudiences: React.FC<AddAudiencesProps> = ({
  disabled,
  onCancel,
  onSave,
  setDismissable,
  hideUserCount,
}) => {
  const {
    settings,
    fields: {
      audiences: { set: setAudiences },
    },
    permissions: { updateAudienceAccess, setAsCriticalAccess },
  } = useSettings();

  const {
    value: builderEnabled,
    disable: disableBuilder,
    enable: enableBuilder,
  } = useToggle();

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [audienceToEdit, setAudienceToEdit] = useState<Audience>();

  const [selectedAudiences, setSelectedAudiences] = useState<Array<Audience>>(
    settings.audiences
  );

  const [selectedPriority, setSelectedPriority] = useState<
    Priority | undefined
  >(settings.priority);

  const [
    limitContentVisibilityToAudience,
    setLimitContentVisibilityToAudience,
  ] = useState<boolean>(settings.limitVisibilityToAudience);

  const { id: programId } = useProgram();

  const {
    data: limitVisibilityToAudienceData,
    isLoading: limitVisibilityToAudienceLoading,
  } = useFeatureFlagsQuery(
    programId,
    'Studio.Content.Targets.LimitVisibilityToAudience'
  );
  const limitVisibilityToAudienceSupported =
    limitVisibilityToAudienceData?.value;

  const renderPill = (value: Audience) => (
    <>
      {value.title || value.name}&nbsp;
      {hideUserCount ? (
        ''
      ) : (
        <>
          <Bold>·</Bold>&nbsp;{value.totalUsers}
        </>
      )}
      {value.type === 'dynamic' && (
        <span
          className={cstyles.Link}
          role="link"
          tabIndex={0}
          onClick={() => {
            setAudienceToEdit(value);
            enableBuilder();
          }}
          onKeyDown={() => {
            setAudienceToEdit(value);
            enableBuilder();
          }}
        >
          Edit
        </span>
      )}
    </>
  );

  // disable dismiss if the save button has been enabled
  useEffect(() => {
    if (setDismissable) setDismissable(!saveEnabled && !builderEnabled);
  }, [setDismissable, saveEnabled, builderEnabled]);

  const saveSettings = React.useCallback(() => {
    setAudiences(selectedAudiences, {
      priority: selectedPriority,
      limitVisibilityToAudience: limitContentVisibilityToAudience,
    });
  }, [
    limitContentVisibilityToAudience,
    selectedAudiences,
    selectedPriority,
    setAudiences,
  ]);

  const handleAudienceChange = (audiences: Audience[]) => {
    if (selectedAudiences.length === 0 && audiences.length > 0) {
      setSelectedPriority(PRIORITIES_BY_NAME.SHOULD);
    }
    setSelectedAudiences(audiences);
    setSaveEnabled(true);
  };

  const showLimitVisibilityToAudienceCheckbox =
    limitVisibilityToAudienceSupported && selectedAudiences.length > 0;

  useEffect(() => {
    if (
      !limitVisibilityToAudienceLoading &&
      !showLimitVisibilityToAudienceCheckbox
    ) {
      setLimitContentVisibilityToAudience(false);
    }
  }, [limitVisibilityToAudienceLoading, showLimitVisibilityToAudienceCheckbox]);

  return (
    <>
      <Box>
        <Box
          margin={[0, 0, 16, 0]}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Subheading bold>Audiences</Subheading>
          <Box style={{ alignSelf: 'flex-end' }}>
            {updateAudienceAccess && (
              <Button
                label="+ Create audience"
                clearText
                onClick={() => {
                  enableBuilder();
                  setDismissable(false);
                }}
              />
            )}
          </Box>
        </Box>

        <SelectAudience
          disabled={disabled}
          selectedAudiences={selectedAudiences}
          onAudiencesChange={(audiences: Audience[]) => {
            handleAudienceChange(audiences);
          }}
          hideUserCount={hideUserCount}
        />
        <Pills
          values={selectedAudiences}
          render={renderPill}
          pillClassName={WhitePillClass}
          valueTransformer={archivedAudiences}
          onClose={(audience: Audience) => {
            const audiences = selectedAudiences.filter(
              (selectedAudience: Audience) =>
                selectedAudience.id !== audience.id
            );
            setSelectedAudiences(audiences);
            setSaveEnabled(true);
          }}
        />
        {showLimitVisibilityToAudienceCheckbox && (
          <Box
            margin={[20, 0, 20, 0]}
            style={{ display: 'flex' }}
            dataTest="limit-visibility-checkbox"
          >
            <Checkbox
              checked={limitContentVisibilityToAudience}
              onChange={(checked: boolean) => {
                setLimitContentVisibilityToAudience(checked);
                setSaveEnabled(true);
              }}
              label={
                <Body>
                  Limit campaign to specified Audiences
                  <InfoTooltip message="This will limit the campaign to specified Audience members only, topic members who are not in the selected audiences will be ignored." />
                </Body>
              }
            />
          </Box>
        )}
        {selectedAudiences.length > 0 && (
          <>
            <Box margin={[0, 0, 16, 0]}>
              <Subheading bold>
                Priority
                <InfoTooltip message="The priority of your campaign helps determine the timing, channels, and frequency of deliveries for your campaign." />
              </Subheading>
            </Box>

            <SelectGroup
              values={PRIORITIES.filter(
                (priority) =>
                  priority.value !== 'CRITICAL' || setAsCriticalAccess
              )}
              selected={selectedPriority}
              render={(priority) => <Bold>{priority?.label}</Bold>}
              compare={(priority) => priority?.value ?? ''}
              disabled={selectedAudiences.length === 0}
              onChange={(priority) => {
                setSelectedPriority(priority);
                setSaveEnabled(true);
              }}
            />
            {selectedPriority && (
              <Box margin={[13, 0, 0, 0]}>
                <Caption>
                  <Bold>{selectedPriority.label}</Bold>
                  {`: ${selectedPriority.description}`}
                </Caption>
              </Box>
            )}
          </>
        )}
      </Box>

      <SaveModalButtons
        onCancel={() => {
          datadogRum.addAction('cancel_audiences');
          if (onCancel) {
            onCancel();
          }
        }}
        onSave={() => {
          datadogRum.addAction('save_audiences', {
            selectedAudiences,
            selectedPriority,
            limitContentVisibilityToAudience,
          });
          if (saveEnabled) {
            saveSettings();

            if (onSave) {
              onSave();
            }
          }
        }}
        disabled={!saveEnabled}
      />

      {builderEnabled && (
        <SlideIn name="audience-builder">
          {(slideOut) => (
            <Builder
              existingAudience={audienceToEdit}
              onCloseBuilder={() => {
                slideOut(disableBuilder);
                setDismissable(!saveEnabled);
                setAudienceToEdit(undefined);
              }}
              onUseBuiltAudience={(audience) => {
                if (audienceToEdit) {
                  handleAudienceChange([
                    ...selectedAudiences.filter(({ id }) => id !== audience.id),
                    audience,
                  ]);
                  setAudienceToEdit(undefined);
                } else {
                  handleAudienceChange([...selectedAudiences, audience]);
                }
              }}
            />
          )}
        </SlideIn>
      )}
    </>
  );
};
