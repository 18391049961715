import * as React from 'react';
import { Box } from 'DesignSystem/Components/Box';
import styles from './forms.module.css';
import { Button } from './InputElements';

interface SelectGroupProps<T> {
  selected: T;
  values: Array<T>;
  render: (value: T) => React.ReactNode;
  compare: (value: T) => string;
  onChange?: (value: T) => void;
  disabled?: boolean;
}

export function SelectGroup<T>(
  props: React.PropsWithChildren<SelectGroupProps<T>>
): JSX.Element {
  const {
    selected,
    values,
    render,
    compare,
    onChange,
    disabled = false,
  } = props;
  return (
    <Box className={styles.ComboButton}>
      {values.map((value) => (
        <span
          key={`select-group-${compare(value)}`}
          data-active={compare(value) === compare(selected)}
        >
          <Button
            compact
            layoutOnly
            label={render(value)}
            onClick={() => onChange && onChange(value)}
            disabled={disabled}
          />
        </span>
      ))}
    </Box>
  );
}
