import * as React from 'react';
import { Audience } from 'models/audience';
import { InfiniteSelect, InfiniteLoadProps } from 'shared/InfiniteSelect';
import { Icon } from 'shared/Icon';
import { pluralize } from 'utility/text';
import styles from './audience-select.module.css';

export type PropsType = {
  values: Array<Audience>;
  selectedIds: Array<string>;
  onSelectedIdsChange: (ids: Array<string>) => void;
  maxHeight?: number;
  /*
   * `default` variant will render audience title, description and user count.
   * `minimal` variant will render only title and user count.
   */
  variant?: 'default' | 'minimal';
} & InfiniteLoadProps;

export const Dropdown: React.FC<PropsType> = (props) => {
  const {
    values,
    selectedIds,
    onSelectedIdsChange,
    maxHeight = 400,
    // Infinite load props
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
    variant = 'default',
  } = props;

  const itemHeight = variant === 'minimal' ? 32 : 95;

  // These should never be null but the linter wants it to be null safe.
  const rowIds: Array<string> = values.map((item) => item.id || '');

  const rowRenderProp = (id: string) => {
    const audience = values.find((item) => item.id?.toString() === id);
    if (!audience) return null;

    const { title, description, totalUsers } = audience;
    if (variant === 'minimal') {
      return (
        <div key={id} className={styles.rowMinimal}>
          <div className={styles.title}>{title}</div>
          <div className={styles.userCount}>
            {totalUsers} {pluralize(totalUsers, 'user')}
          </div>
        </div>
      );
    }
    return (
      <div key={id} className={styles.row}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.userCount}>
          <Icon size={13} iconType="SVG" iconName="Account" />
          <span>{totalUsers}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.dropdown}>
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={rowRenderProp}
        selectedIds={selectedIds.map((id) => id.toString())}
        onSelectedIdsChange={onSelectedIdsChange}
        itemHeight={itemHeight}
        maxHeight={maxHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        threshold={threshold}
        overscan={overscan}
      />
    </div>
  );
};
