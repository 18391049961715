import { SupportedCommands } from 'services/api-ai-chat-completion';
import { sparklesSvg } from 'shared/icons/Sparkles';
import { spellCheckSvg } from 'shared/icons/SpellCheck';
import { summarizeSvg } from 'shared/icons/Summarize';
import { moodSvg } from 'shared/icons/Mood';

export type AiOption = {
  command: SupportedCommands | string;
  text: string;
  svgString: string;
  options?: string[];
};

export const AiOptions: AiOption[] = [
  {
    command: 'modal_summary',
    text: 'Improve my writing',
    svgString: sparklesSvg('#1A1A1A'),
  },
  {
    command: 'fix_spelling_grammar',
    text: 'Fix spelling and grammar',
    svgString: spellCheckSvg,
  },
  {
    command: 'use_concise_language',
    text: 'Use concise language',
    svgString: summarizeSvg,
  },
  {
    command: 'change_tone',
    text: 'Change tone',
    options: [
      'Professional',
      'Casual',
      'Straightforward',
      'Confident',
      'Friendly',
    ],
    svgString: moodSvg,
  },
];
