import React from 'react';
import cx from 'classnames';
import { scaleOrdinal } from 'd3';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import { PieChart } from '../../../../../../../shared/Charts/PieChart';
import styles from '../MetricsCards.module.css';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IEmailClickEngagementCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const dimensions = {
  width: 528,
  height: 260,
  padding: {
    top: 0,
    bottom: 0,
    right: 20,
    left: 20,
  },
  pie: {
    width: 200,
    height: 200,
    padding: {
      top: 20,
      bottom: 20,
      right: 20,
      left: 20,
    },
    gap: 0.02,
  },
};

export const EmailClickEngagementCard: React.FC<IEmailClickEngagementCard> = ({
  data,
  isLoading,
}) => {
  const acknowledged = data?.campaign.email_engagement?.acknowledged || 0;
  const commented = data?.campaign.email_engagement?.commented || 0;
  const bookmarked = data?.campaign.email_engagement?.bookmarked || 0;
  const downloaded =
    data?.campaign.email_engagement?.downloaded_attachment || 0;
  const liked = data?.campaign.email_engagement?.liked || 0;
  const playedVideo = data?.campaign.email_engagement?.played_video || 0;
  const responded = data?.campaign.email_engagement?.responded_to_poll || 0;
  const shared = data?.campaign.email_engagement?.shared || 0;
  const graphData = [
    {
      label: 'Commented',
      value: commented,
    },
    {
      label: 'Bookmarked',
      value: bookmarked,
    },
    {
      label: 'Liked',
      value: liked,
    },
    {
      label: 'Acknowledged',
      value: acknowledged,
    },
    {
      label: 'Downloaded Attachment',
      value: downloaded,
    },
    {
      label: 'Played Video',
      value: playedVideo,
    },
    {
      label: 'Responded To Poll',
      value: responded,
    },
    {
      label: 'Shared',
      value: shared,
    },
  ];

  const color = scaleOrdinal()
    .domain(graphData.map((d) => d.label))
    .range([
      'url(#orangeBarGradient)',
      'url(#greenBarGradient)',
      'url(#blueBarGradient)',
      'url(#yellowBarGradient)',
    ]);

  const drawCustom = () => {
    return (
      <defs>
        <linearGradient
          id="orangeBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(245, 158, 11, 1)" />
          <stop offset="100%" stopColor="rgba(255, 208, 130, 0.93)" />
        </linearGradient>
        <linearGradient
          id="yellowBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(254, 209, 94, 1)" />
          <stop offset="100%" stopColor="rgba(255, 232, 174, 1)" />
        </linearGradient>
        <linearGradient
          id="greenBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(182, 228, 109, 1)" />
          <stop offset="100%" stopColor="rgba(219, 252, 166, 1)" />
        </linearGradient>
        <linearGradient
          id="blueBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(112, 214, 236, 1)" />
          <stop offset="100%" stopColor="rgba(182, 242, 255, 1)" />
        </linearGradient>
      </defs>
    );
  };

  return (
    <div className={cx(styles.metricCard, styles.metricCardHalf)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>
          How did Users <strong>interact</strong> with content after receiving
          email
        </h1>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <PieChart
            data={graphData}
            customColors={color}
            customDrawing={drawCustom}
            chartDimensions={dimensions}
          />
        </div>
      )}
    </div>
  );
};
