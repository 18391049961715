import React from 'react';
import { Section } from 'shared/SectionBlocks';
import { User } from 'models/user';
import { IdentityScope } from 'models/identity';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { OptionType } from 'hooks/common';
import { useCurrentUserQuery } from 'hooks/user';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Topic } from 'models/topic';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useTopicsQuery } from 'hooks/topics';
import { AudiencesSelector } from './AudiencesSelector';
import { AuthorSelector } from './AuthorSelector';
import { TopicSelector } from './TopicSelector';
import {
  filterRolesPerFeatureFlag,
  groupRolesByType,
  rolesToSelectOptions,
} from './roles-helper';
import { TemplatesSelector } from './TemplatesSelector';
import { WorkflowTemplatesSelector } from './WorkflowTemplatesSelector';
import { EmailSendersSelector } from './EmailSendersSelector';
import { RoleSelect } from '../../../../Shared/ValueSelect/Role';
import styles from '../../../../user-profile.module.css';

type PropsType = {
  user: User;
  identityScopes: IdentityScope[];
  initialIdentityScopes?: IdentityScope[];
  onDataChange: (field: string, value: string) => void;
  onIdentityScopesChange: (value: IdentityScope[]) => void;
};

export const Permissions: React.FC<PropsType> = ({
  user: userToEdit,
  identityScopes,
  initialIdentityScopes = [],
  onDataChange,
  onIdentityScopesChange,
}) => {
  const {
    permissions: { configureRoleAccess, peopleAccess },
  } = usePermissions();
  const [selectedTopics, setSelectedTopics] = React.useState<Topic[]>([]);
  const [permittedAudienceIds, setPermittedAudienceIds] = React.useState<
    string[]
  >([]);
  const [permittedTemplateIds, setPermittedTemplateIds] = React.useState<
    string[]
  >([]);
  const [permittedEmailIds, setPermittedEmailIds] = React.useState<string[]>(
    []
  );
  const [permittedAuthorAliasIds, setPermittedAuthorAliasIds] = React.useState<
    string[]
  >([]);
  const [
    permittedWorkflowTemplatesIds,
    setPermittedWorkflowTemplatesIds,
  ] = React.useState<string[]>([]);
  const { id: programId } = useProgram();
  const { data: currentUser } = useCurrentUserQuery();
  const [roleOptions, setRoleOptions] = React.useState<OptionType[]>([]);

  const { isLoading: isFetchingRoles, data: roles } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
  });

  const showStandardRoles = !!useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowStandardRoles'
  ).data?.value;
  const showCustomRoles = !!useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowCustomRoles'
  ).data?.value;

  const { data: workflowTemplatesPermit } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.WorkflowTemplatesPermit'
  );

  const { data: permitAuthorAlias } = useFeatureFlagsQuery(
    programId,
    'Studio.AuthorAliases.UI'
  );

  const showAuthorAlias =
    permitAuthorAlias?.value && peopleAccess && configureRoleAccess;

  const topicScope = initialIdentityScopes.filter((s) => s.type === 'topic');

  const topicScopeIds = topicScope.filter((s) => typeof s.name === 'string');

  const { data: pt, isLoading: isTopicsQueryLoading } = useTopicsQuery({
    programId,
    ids: topicScopeIds
      .filter((s) => typeof s.name === 'string')
      .map((s) => {
        if (typeof s.name === 'string') {
          return Number(s.name.split(':')[1]);
        }
        return -1;
      }) || [-1],
  });

  React.useEffect(() => {
    if (isFetchingRoles) return;

    const allowedRoles = filterRolesPerFeatureFlag(
      roles,
      showStandardRoles,
      showCustomRoles
    );
    const userEditedCurrentRole = roles.find((r) => r.name === userToEdit.role);
    if (
      userEditedCurrentRole &&
      !allowedRoles.find((r) => r.name === userToEdit.role)
    ) {
      allowedRoles.push(userEditedCurrentRole);
    }
    const rolesByType = groupRolesByType(allowedRoles);
    setRoleOptions(() => rolesToSelectOptions(rolesByType));
    // eslint-disable-next-line
  }, [isFetchingRoles, showCustomRoles, showStandardRoles]);

  React.useEffect(() => {
    if (topicScopeIds.length > 0) setSelectedTopics(pt || []);
    // eslint-disable-next-line
  }, [isTopicsQueryLoading, initialIdentityScopes]);

  React.useEffect(() => {
    const getScopeIds = (type: string) => {
      return identityScopes
        .filter(
          (s: IdentityScope) => s.type === type && typeof s.name === 'string'
        )
        .map((s) => {
          if (typeof s.name !== 'string') {
            return '';
          }
          return s.name.split(':')[1] || '';
        });
    };

    setPermittedAudienceIds(getScopeIds('audience'));
    setPermittedTemplateIds(getScopeIds('publisher_template'));
    setPermittedEmailIds(getScopeIds('sender_account'));
    setPermittedAuthorAliasIds(getScopeIds('author_alias'));
    setPermittedWorkflowTemplatesIds(getScopeIds('workflow_template'));
  }, [identityScopes]);

  const mergeScopes = (type: string, value: IdentityScope[]) => {
    return identityScopes
      .filter((x: IdentityScope) => x.type !== type)
      .concat(value);
  };

  const handleAudiencesChange = (value: IdentityScope[]) => {
    const updatedScopes = mergeScopes('audience', value);
    onIdentityScopesChange(updatedScopes);
  };

  const handleTemplatesChange = (value: IdentityScope[]) => {
    const updatedScopes = mergeScopes('publisher_template', value);
    onIdentityScopesChange(updatedScopes);
  };

  const handlePermittedTopicsChange = (value: IdentityScope[]) => {
    const updatedScopes = mergeScopes('topic', value);
    onIdentityScopesChange(updatedScopes);
  };

  const handleEmailsChange = (value: IdentityScope[]) => {
    const updatedScopes = mergeScopes('sender_account', value);
    onIdentityScopesChange(updatedScopes);
  };

  const handlePermittedAuthorsChange = (value: IdentityScope[]) => {
    const updatedScopes = mergeScopes('author_alias', value);
    onIdentityScopesChange(updatedScopes);
  };

  const hanldeWorkflowTemplateChange = (value: IdentityScope[]) => {
    const updatedScopes = mergeScopes('workflow_template', value);
    onIdentityScopesChange(updatedScopes);
  };

  return (
    <Section title="Permissions" className={styles.userPage}>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Role">
            <RoleSelect
              value={userToEdit.role}
              options={roleOptions}
              onChange={onDataChange}
            />
          </Field>
        </div>
      </Fieldset>
      {userToEdit.role !== 'member' && (
        <>
          <TopicSelector
            scope={topicScope}
            permittedTopics={selectedTopics}
            setPermittedTopics={setSelectedTopics}
            onChange={handlePermittedTopicsChange}
            role={userToEdit.role}
          />
          <AudiencesSelector
            selectedIds={permittedAudienceIds}
            onChange={handleAudiencesChange}
            role={userToEdit.role}
          />
          <TemplatesSelector
            selectedIds={permittedTemplateIds}
            onChange={handleTemplatesChange}
            role={userToEdit.role}
          />
          <EmailSendersSelector
            selectedIds={permittedEmailIds}
            onChange={handleEmailsChange}
            role={userToEdit.role}
          />
          {showAuthorAlias && (
            <AuthorSelector
              selectedIds={permittedAuthorAliasIds}
              onChange={handlePermittedAuthorsChange}
              role={userToEdit.role}
              currentUser={currentUser}
            />
          )}
          {workflowTemplatesPermit?.value && (
            <WorkflowTemplatesSelector
              selectedIds={permittedWorkflowTemplatesIds}
              onChange={hanldeWorkflowTemplateChange}
              role={userToEdit.role}
            />
          )}
        </>
      )}
    </Section>
  );
};
