import { useProgram } from 'contexts/program';
import {
  useProgramCustomizationsQuery,
  useUpdateProgramCustomizations,
} from 'hooks/feature-flags';
import { FlagValueType, FeatureFlag } from 'models/feature-flag';

export const isActiveDeliveryThreshold = (
  deliveryThreshold: FlagValueType | undefined
): boolean => {
  return (
    typeof deliveryThreshold === 'string' &&
    isNumber(deliveryThreshold) &&
    isPositiveNumber(Number(deliveryThreshold))
  );
};

export const useProgramDeliveryThreshold = (
  onSaveSuccess?: () => void,
  onSaveError?: () => void
): {
  deliveryThreshold: string;
  deliveryThresholdEnabled: boolean;
  updateDeliveryThreshold: (newThreshold: string, enabled: boolean) => void;
  defaultNewDeliveryThreshold: string;
} => {
  const { id: programId } = useProgram();
  const { data: programCustomizations } = useProgramCustomizationsQuery(
    programId
  );
  const { update } = useUpdateProgramCustomizations(programId, {
    onSuccess: onSaveSuccess,
    onError: onSaveError,
  });
  const updateDeliveryThreshold = (newThreshold: string, enabled: boolean) => {
    const value = calculateNewThresholdValue(newThreshold, enabled);
    const featureFlag: FeatureFlag = {
      value,
      programId,
      type: 'delivery_threshold',
      label: 'delivery_threshold',
    };

    update(featureFlag);
  };

  const deliveryThreshold = programCustomizations?.deliveryThreshold?.value;
  if (isActiveDeliveryThreshold(deliveryThreshold)) {
    return {
      deliveryThreshold: stringFeatureFlagValueOrDefault(
        deliveryThreshold,
        minimalEnabledDeliveryThresholdValue
      ),
      deliveryThresholdEnabled: true,
      updateDeliveryThreshold,
      defaultNewDeliveryThreshold,
    };
  }

  return {
    deliveryThreshold: disabledDeliveryThresholdValue,
    deliveryThresholdEnabled: false,
    updateDeliveryThreshold,
    defaultNewDeliveryThreshold,
  };
};

const defaultNewDeliveryThreshold = '1000';
const minimalEnabledDeliveryThresholdValue = '0';
const disabledDeliveryThresholdValue = '-1';

const stringFeatureFlagValueOrDefault = (
  value: FlagValueType | undefined,
  defaultValue: string
): string => {
  if (typeof value === 'string') {
    return value;
  }

  return defaultValue;
};

const isNumber = (rawNumber: string): boolean => {
  const trimmedRawNumber = rawNumber.trim();
  return trimmedRawNumber.length > 0 && !Number.isNaN(Number(trimmedRawNumber));
};

const isPositiveNumber = (value: number): boolean => {
  return value > Number(disabledDeliveryThresholdValue);
};

const calculateNewThresholdValue = (
  newThreshold: string,
  enabled: boolean
): string => {
  if (enabled && isActiveDeliveryThreshold(newThreshold)) {
    return newThreshold;
  }

  return disabledDeliveryThresholdValue;
};
