import { useCallback } from 'react';
import { PollPreset } from 'models/publisher/block';
import { usePollDataMutator } from './usePollDataMutator';

export function usePollPreset({
  poll: { preset },
  updatePoll,
}: ReturnType<typeof usePollDataMutator>): {
  preset: PollPreset;
  presets: Array<{ label: string; value: PollPreset }>;
  changePreset: (preset: PollPreset) => void;
} {
  const presets: ReturnType<typeof usePollPreset>['presets'] = [
    { label: 'Single choice', value: 'single' },
    { label: 'Multiple choice', value: 'multiple' },
    { label: 'Satisfaction', value: 'satisfaction' },
    { label: 'Star Rating', value: 'stars' },
    { label: 'Yes/No', value: 'yes_no' },
  ];
  const changePreset = useCallback(
    (newPreset: PollPreset) => updatePoll({ preset: newPreset }),
    [updatePoll]
  );

  return { preset, presets, changePreset };
}
