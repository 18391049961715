import React, { createContext, useContext } from 'react';
import { FeatureFlags } from '../models/feature-flag';

const FeatureFlagsContext = createContext({} as FeatureFlags);

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error(
      'Feature flag context hooks require a containing FeatureFlagsProvider'
    );
  }
  return context;
};

export const FeatureFlagsProvider: React.FC<{
  value?: FeatureFlags;
}> = ({ children, value }) => (
  <FeatureFlagsContext.Provider value={value || ({} as FeatureFlags)}>
    {children}
  </FeatureFlagsContext.Provider>
);
