import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import styles from './permission-denied-screen.module.css';

export const PermissionDeniedScreen: React.FC<RouteComponentProps> = () => (
  <div className={styles.wrapper}>
    <div className={styles.logoWrapper}>
      <SVGIcon size={120} name="Logo" />
    </div>
    <h3>Permission Denied</h3>
    <p>You do not have the correct permissions to view this content</p>
    <Link to="/">back to home</Link>
  </div>
);
