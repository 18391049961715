import React from 'react';

import { RecurringTrigger } from 'models/journeys/journey';
import { ComplexExpression } from 'models/expression';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Text } from 'DesignSystem/Typography';
import { useProgram } from 'contexts/program';
import { useValueSuggestionsQuery } from 'hooks/audience';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { capitalizeWords } from 'utility/strings';
import { pluralize } from 'utility/text';
import { editableTextToQuery } from '../../../JourneyDrawer/shared/AudienceSelect';
import { isEveryone as everyone, parseCriterion } from './utils';

export const RecurringBody: React.FC<{
  trigger: RecurringTrigger;
  criterion?: ComplexExpression;
}> = ({ trigger, criterion }) => {
  const { id: programId } = useProgram();
  const { isLoading, data: options = [] } = useValueSuggestionsQuery(
    programId,
    'group',
    trigger.triggerCriterion?.values.join('|') ?? '*',
    editableTextToQuery('*'),
    undefined,
    true
  );
  const audienceNameById = options.reduce<Record<string, string>>(
    (acc, option) => ({
      ...acc,
      [option.value]: option.label,
    }),
    {}
  );

  const triggerCriterion =
    trigger.triggerCriterion &&
    `Audience is ${trigger.triggerCriterion.values
      .map(
        (value) =>
          audienceNameById[value] ??
          capitalizeWords(value.replace(/_/g, ' '), { ios: 'iOS' })
      )
      .join(', ')}`;
  const isEveryone = !criterion || everyone(criterion);
  const parsedCriterion = !isEveryone && criterion && parseCriterion(criterion);

  if (isLoading)
    return (
      <Flex>
        <LoadingSpinner />
      </Flex>
    );

  return (
    <Flex column alignStart>
      <Text className={{ Semibold: true }}>Start Type: Daily</Text>
      {triggerCriterion && (
        <Text className={{ Semibold: true }}>Trigger: {triggerCriterion}</Text>
      )}
      {isEveryone ? (
        <Text className={{ Semibold: true }}>
          Filter Members: None, everyone is eligible
        </Text>
      ) : (
        parsedCriterion && (
          <Text className={{ Semibold: true }}>
            Filter Members: {parsedCriterion.count}{' '}
            {pluralize(parsedCriterion.count, 'filter')} applied
          </Text>
        )
      )}
    </Flex>
  );
};
