import * as React from 'react';
import { IMAGE_ACCEPT_STRING, ImageData } from 'models/image';
import { ImageSlideIn as Library } from 'shared/Library/ImageSlideIn';
import { EXTERNAL, LIBRARY, Source, UPLOAD } from 'models/source';
import { External } from './External';
import { AutoUpload } from './AutoUpload';
import * as SourceMenu from '../../SourceMenu/useSourceMenu';

const { OPENED, CLOSED } = SourceMenu;
export { OPENED, CLOSED };

export const MENU: SourceMenu.Config<Source> = [
  { name: 'Upload', source: UPLOAD },
  { name: 'Image Library', source: LIBRARY },
  { name: 'URL', source: EXTERNAL },
];

export function useImagesSourceMenu(
  initial: SourceMenu.State<Source> = { source: UPLOAD, visibility: CLOSED },
  onCreate: (data: ImageData[] | File[] | DataTransferItemList) => void,
  maxSelections: number,
  menu?: SourceMenu.Config<Source>
): SourceMenu.MenuIface<Source> & {
  fileInput: React.ReactNode;
  libraryInput: React.ReactNode;
  externalInput: React.ReactNode;
  onUploadClick: () => void;
} {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onUploadClick = React.useCallback(() => inputRef.current?.click(), [
    inputRef,
  ]);
  const source = SourceMenu.useSourceMenu(menu || MENU, initial);
  source.menu = source.menu.map((item) =>
    item.source !== UPLOAD
      ? item
      : {
          ...item,
          component: React.createElement(AutoUpload, {
            onClick: onUploadClick,
          }),
        }
  );

  const fileInput = React.useMemo(
    () => (
      <span
        style={{
          display: 'block',
          position: 'absolute',
          height: '1px',
          width: '1px',
          overflow: 'hidden',
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept={IMAGE_ACCEPT_STRING}
          data-test="upload-image"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file =
              event.currentTarget.files && event.currentTarget.files[0];
            if (file) onCreate([file]);
          }}
        />
      </span>
    ),
    [inputRef, onCreate]
  );

  const sourceExternalOnChange = React.useCallback(
    (url: string) => {
      onCreate([{ url, altText: '', processed: false }]);
      source.close();
    },
    [onCreate, source]
  );
  const externalInput = React.useMemo(
    () =>
      source.source === EXTERNAL && source.visibility === OPENED ? (
        <External onChange={sourceExternalOnChange} />
      ) : null,
    [sourceExternalOnChange, source.source, source.visibility]
  );

  const libraryInput = React.useMemo(
    () =>
      source.visibility !== OPENED || source.source !== LIBRARY ? null : (
        <Library
          name="pub-modal-field-editor-image-library"
          maxSelections={maxSelections}
          onClose={source.close}
          onChange={onCreate}
        />
      ),
    [maxSelections, source.source, source.visibility, source.close, onCreate]
  );
  return {
    ...source,
    fileInput,
    libraryInput,
    externalInput,
    onUploadClick,
  };
}
