import * as React from 'react';
import { Icon, IconType } from 'shared/Icon';
import * as DesignSystem from 'DesignSystem/Form';

export type ButtonType = {
  onClick: () => void;
  disabled?: boolean;
  // tabIndex?: number;
  className?: string;
  type?: 'primary' | 'secondary' | 'tertiary' | 'blank';
  // iconLocation?: 'left' | 'right';
  iconSize?: number;
  compact?: boolean;
  block?: boolean;
  useCurrentColor?: boolean;
  dataTest?: string;
} & IconType;

export const Button: React.FC<ButtonType> = (props) => {
  const {
    onClick,
    disabled,
    dataTest,
    type = 'primary',
    iconSize,
    useCurrentColor,
    compact,
    block,
    children,
    // IconType
    iconType,
    iconName,
  } = props;
  const clickHandler = React.useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [onClick, disabled]);

  const icon = !iconName ? undefined : (
    <Icon
      iconName={iconName}
      iconType={iconType}
      size={iconSize || (compact ? 13 : 14)}
      useCurrentColor={useCurrentColor}
    />
  );

  return (
    <DesignSystem.Button
      disabled={disabled}
      dataTest={dataTest}
      secondary={type === 'secondary'}
      text={type === 'tertiary'}
      compact={compact}
      block={block}
      label={children}
      icon={icon}
      onClick={clickHandler}
    />
  );
};
