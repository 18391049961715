import React from 'react';
import { Box, ClickDropdown, Popover } from 'DesignSystem/Components';
import { Calendar } from 'shared/DateInput/Calendar';
import { Icon } from 'shared/Icon';
import { Button } from 'DesignSystem/Form';
import { DateTime } from 'luxon';

export type CalendarSelectProps = {
  date?: DateTime;
  placeholder?: string;
  onChange: (changedDate?: DateTime) => void;
};

export const CalendarSelect = React.memo((props: CalendarSelectProps) => {
  const { date, placeholder, onChange } = props;

  return (
    <ClickDropdown
      dropdownRenderProp={() => (
        <Box width={336} style={{ cursor: 'auto', position: 'relative' }}>
          <Popover centered padding={0}>
            <Calendar
              selectedDate={date || DateTime.now()}
              onChange={onChange}
              minDate={DateTime.now()}
            />
          </Popover>
        </Box>
      )}
    >
      <Button
        input
        block
        badge={<Icon iconName="calendar" />}
        label={date?.toFormat('MM/dd/yyyy') || placeholder || 'MM/DD/YYYY'}
      />
    </ClickDropdown>
  );
});
