import React from 'react';
import { useNavigate } from '@reach/router';
import cx from 'classnames';
import { FormState, useTopicFormCtx } from '../../context';
import styles from './styles.module.css';
import { WarningBanner } from './WarningBanner';

interface TopicWarning {
  readonly name: string;
  readonly text: string;
  readonly rule: (state: FormState['fields']) => boolean;
}

const topicImageMissing: TopicWarning = {
  name: 'Missing Topic Image',
  text: 'Topic Image is not uploaded. The Default Topic Image will be used.',
  rule: (state) => state.image?.url === undefined,
};

const coverImageEnabledButMissing: TopicWarning = {
  name: 'Missing Cover Image',
  text:
    'Cover Image is enabled but no image is uploaded. The Default Cover Image will be used.',
  rule: (state) => state.coverEnabled && state.cover?.url === undefined,
};

const allPossibleWarnings: TopicWarning[] = [
  topicImageMissing,
  coverImageEnabledButMissing,
];

export const ReviewTopicWarnings: React.FC = () => {
  const { state, baseUrl, toggleSidebar } = useTopicFormCtx();

  const navigate = useNavigate();

  const applicableWarnings = allPossibleWarnings.filter((warning) =>
    warning.rule(state.fields)
  );

  if (applicableWarnings.length === 0) return null;

  return (
    <div className={cx(styles.VerticallySpaced, styles.Gap8)}>
      {applicableWarnings.map((warning) => (
        <WarningBanner
          key={warning.name}
          name={warning.name}
          actionButton={
            <button
              type="button"
              onClick={() => {
                toggleSidebar('editHeader');
                navigate(`${baseUrl}/design`);
              }}
            >
              <strong>Edit Design</strong>
            </button>
          }
        >
          {warning.text}
        </WarningBanner>
      ))}
    </div>
  );
};
