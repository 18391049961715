import * as React from 'react';
import {
  CallToAction,
  defaultCallToAction,
} from 'models/publisher/call-to-action';

const contextPrototype: {
  cta: CallToAction;
  change: (callToAction: CallToAction) => void;
  handleAltTextChange: (altText: string) => void;
} = {
  cta: defaultCallToAction,
  change: () => {},
  handleAltTextChange: () => {},
};

export const CardEditorContext = React.createContext(contextPrototype);

export const useCardEditor = (): typeof contextPrototype => {
  const context = React.useContext(CardEditorContext);
  if (context === undefined) {
    throw new Error('Card Editor context hooks require a provider');
  }
  return context;
};
