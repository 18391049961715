/**
 * Helper functions for dealing with blob types
 */
const isBlob = (obj: Blob | BlobPart): obj is Blob => {
  return obj instanceof Blob;
};

const blob = (obj: Blob | BlobPart, type: string) => {
  if (isBlob(obj)) return obj;
  return new Blob([obj], { type });
};

/**
 * Helper functions to try the different download strategies
 * - IE
 * - Firefox
 * - Chrome/Safari/other
 */
const ieDownload = (obj: Blob, fileName: string) => {
  try {
    window.navigator.msSaveBlob(obj, fileName);
    return true;
  } catch {
    return false;
  }
};

const ffDownload = (obj: Blob, fileName: string) => {
  if (navigator.userAgent.search('Firefox') !== -1) {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = reader.result as string;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };
      reader.readAsDataURL(obj);
      return true;
    } catch {
      return false;
    }
  }
  return false;
};

const csDownload = (obj: Blob, fileName: string) => {
  try {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(obj);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
    }, 1000);
    return true;
  } catch {
    return false;
  }
};

/**
 * Download any blob by file name.
 * It will try three methods before giving up, return the success status
 */
export const download = (obj: Blob, fileName: string): boolean => {
  if (ieDownload(obj, fileName)) return true;
  if (ffDownload(obj, fileName)) return true;
  if (csDownload(obj, fileName)) return true;
  return false;
};

export const png = (content: Blob | BlobPart, fileName: string): boolean => {
  return download(blob(content, 'image/png'), fileName);
};

export const csv = (content: Blob | BlobPart, fileName: string): boolean => {
  return download(blob(content, 'text/csv;charset=utf-8'), fileName);
};

export const pdf = (content: Blob | BlobPart, fileName: string): boolean => {
  return download(blob(content, 'application/pdf'), fileName);
};
