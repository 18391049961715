import React from 'react';
import { OptionalTableauVizProps, TableauVizRef } from './TableauVizComponent';
import useTableauEmbed from './useTableau';

interface TableauEmbed extends OptionalTableauVizProps {
  token: string;
  src: string;
  ref: TableauVizRef;
}

const TableauEmbed = (
  { src, token, ...optionalProperties }: TableauEmbed,
  ref: TableauVizRef
) => {
  const { component } = useTableauEmbed({
    src,
    ref,
    token,
    optionalProperties,
  });

  return <>{component ?? <h3>Error loading dashboard component.</h3>}</>;
};

export default React.forwardRef(TableauEmbed);
