import React from 'react';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import styles from 'components/content/ContentFilterBar/filters.module.css';

type PropsType = {
  label: string;
  states: string[];
  value: string[];
  onChange: (value: string[]) => void;
  onClose?: () => void;
};

export const FilterDropdown: React.FC<PropsType> = ({
  label,
  value,
  states,
  onChange,
  onClose,
}) => {
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    const renderRow = (option: string) => (
      <div>
        <span className={styles.filterTitle}>{option}</span>
      </div>
    );

    const onSelectedIdsChange = (ids: string[]) => onChange(ids);

    return (
      <InfiniteSelect
        className={styles.filterItemsWrapper}
        rowIds={states}
        rowRenderProp={renderRow}
        maxHeight={200}
        itemHeight={32}
        selectedIds={value}
        onSelectedIdsChange={onSelectedIdsChange}
        itemClassName="filter-item"
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [states, value, onChange]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  return (
    <div className={styles.dropdownWrapper}>
      <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
        <TriggerButton name={label} values={value} onClose={onClose} />
      </ClickDropdown>
    </div>
  );
};
