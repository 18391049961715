import * as React from 'react';
import { DateTime } from 'luxon';
import { SaveSuccess, Clock, CircledPause } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { ProgressBar } from 'shared/ProgressBar';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { useResultsSummaryQuery } from 'hooks/content-insights';
import { useCampaignStatus, Status } from 'hooks/content/useCampaignStatus';
import { PersistanceStatus } from 'components/publisher/theme/PublisherFooter/PostPersistanceStatus';
import { useTimeDelta } from 'hooks/useTimeDelta';
import { Post } from 'models/publisher/post';
import styles from './campaign-status.module.css';

type StatusStyle = {
  icon?: React.ReactNode;
  color: string;
  publishDateLabel?: string;
  hideEndDate?: boolean;
  hidePersistanceStatus?: boolean;
};

const getStatusStyle = (status?: Status): StatusStyle => {
  switch (status) {
    case 'scheduled':
      return {
        icon: <Clock />,
        color: 'var(--color-gray10)',
      };
    case 'started':
      return {
        icon: <SaveSuccess />,
        color: 'var(--color-greenFull)',
      };
    case 'completed':
      return {
        icon: <SaveSuccess />,
        color: 'var(--color-greenFull)',
      };
    case 'archived':
      return {
        icon: <SaveSuccess />,
        color: 'var(--color-orangeFull)',
        hidePersistanceStatus: true,
      };
    case 'archivedAndIncomplete':
      return {
        icon: <CircledPause />,
        color: 'var(--color-orangeFull)',
        publishDateLabel: 'Original Publish Date:',
        hidePersistanceStatus: true,
        hideEndDate: true,
      };
    case 'suspended':
      return { color: 'var(--color-orangeFull)' };
    default:
      return {
        icon: <LoadingSpinner size="small" />,
        color: 'var(--color-gray10)',
      };
  }
};

export const CampaignStatus: React.FC<{
  post: Post;
  isLoadingSummary: ReturnType<typeof useResultsSummaryQuery>['isLoading'];
  summary: ReturnType<typeof useResultsSummaryQuery>['data'];
}> = ({ isLoadingSummary: isLoading, summary, post }) => {
  const {
    campaignStatus,
    statusLabel,
    percentCompleted,
    startDate,
    formattedTiming,
    endLabel,
    updatedAt,
  } = useCampaignStatus(post, summary);

  const updatedAtTimeDelta = useTimeDelta(updatedAt);

  const statusStyle = getStatusStyle(campaignStatus);

  const startDateLabel =
    startDate && startDate.toLocaleString(DateTime.DATETIME_MED);

  return (
    <div className={styles.campaignStatus}>
      <Flex spread className="top">
        <div className="status">
          {statusStyle.icon}{' '}
          <Text.Heading bold>{isLoading ? '...' : statusLabel}</Text.Heading>
        </div>
        {!statusStyle?.hidePersistanceStatus && updatedAtTimeDelta && (
          <div className="lastUpdate">
            <PersistanceStatus
              label="Results Last Updated"
              timeDelta={updatedAtTimeDelta}
            />
          </div>
        )}
      </Flex>

      <ProgressBar
        className={styles.progressBar}
        size={8}
        orientation="horizontal"
        max={100}
        value={isLoading ? 0 : percentCompleted || 0}
        color={statusStyle.color}
      />

      <Flex spread className="bottom">
        <div className="publishDate">
          {statusStyle?.publishDateLabel || 'Publish Date:'}{' '}
          <span>{isLoading ? '...' : startDateLabel ?? '-'}</span>
        </div>

        {formattedTiming && endLabel && !statusStyle?.hideEndDate && (
          <div className="endDate">
            {endLabel}: <span>{isLoading ? '...' : formattedTiming}</span>
          </div>
        )}
      </Flex>
    </div>
  );
};
