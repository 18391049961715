import * as React from 'react';
import { DefinitionBlock } from 'models/publisher/block';
import { FormatData } from 'models/publisher/format';
import { deepMerge } from 'utility/deep-merge';
import { Checkbox } from 'shared/Checkbox';
import styles from '../styles.module.css';

export function canEditHiddenSections(block: DefinitionBlock): boolean {
  return Object.keys(block.format_options?.flags ?? {}).length > 0;
}

export const HiddenSections: React.FC<{
  id: string;
  block: DefinitionBlock;
  onChange: (id: string, data: FormatData) => void;
}> = ({ id, block, onChange }) => {
  const changeHiddenData = React.useCallback(
    (flag: string, add: boolean) => {
      let flags = block.format_data?.flags || [];
      if (add) flags.push((flag as unknown) as undefined);
      else flags = flags.filter((f) => f !== flag);
      onChange(
        id,
        deepMerge(block.format_data ?? {}, { flags }, { arrays: 'replace' })
      );
    },
    [id, block, onChange]
  );
  // could be better...
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const options = ((block.format_options?.flags ?? {}) as unknown) as any;
  const tuples = Object.keys(options).map((flag: keyof typeof options) => ({
    flag: flag as string,
    label: (options[flag] ?? '') as string,
    checked: block.format_data?.flags?.includes((flag as unknown) as undefined),
  }));
  return (
    <>
      {tuples.map(({ flag, label, checked }) => (
        <label htmlFor={flag} key={flag}>
          <div className="kai-flex-row" style={{ marginBottom: '12px' }}>
            <small style={{ flex: 1 }}>{label}</small>
            <Checkbox
              checked={!!checked}
              onChange={() => changeHiddenData(flag, !checked)}
              checkedClassName={styles.checkboxChecked}
            />
          </div>
        </label>
      ))}
    </>
  );
};
