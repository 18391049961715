import * as React from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { Initiative } from 'models/initiative';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { useState } from 'react';
import {
  useInitiativeBulkActive,
  useInitiativeBulkArchive,
  useInitiativeBulkDelete,
} from 'hooks/initiatives';
import { useProgram } from 'contexts/program';
import { InitiativeBulkActionFilters } from 'hooks/common';
import { InitiativeBanner } from './InitiativeBanner';

type PropsType = {
  initiatives: Array<Initiative>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  errorMessage?: string;
  totalRecords?: number;
  parentRef: React.RefObject<HTMLDivElement>;
  filterConfig?: InitiativeBulkActionFilters;
  height: number;
};

export const InitiativesList: React.FC<PropsType> = (props) => {
  const {
    initiatives,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    parentRef,
    totalRecords,
    filterConfig,
    height,
  } = props;

  const itemCount = initiatives.length || 0;
  const setIsBulkSelectMode = useState(false)[1];
  const { id: programId } = useProgram();
  const { archive } = useInitiativeBulkArchive(programId);
  const { active } = useInitiativeBulkActive(programId);
  const { remove } = useInitiativeBulkDelete(programId);
  return (
    <BulkSelectWrapper
      itemName="initiative"
      totalRecords={totalRecords || 0}
      actions={[
        {
          type: 'button',
          actions: [
            {
              label: 'Active',
              onClick: (bulkSelection) => {
                active(bulkSelection, filterConfig);
                return true;
              },
            },
          ],
        },
        {
          type: 'button',
          actions: [
            {
              label: 'Archive',
              iconName: 'archive',
              onClick: (bulkSelection) => {
                archive(bulkSelection, filterConfig);
                return true;
              },
            },
          ],
        },
        {
          type: 'button',
          actions: [
            {
              label: 'Delete',
              onClick: (bulkSelection) => {
                remove(bulkSelection, filterConfig);
                return true;
              },
            },
          ],
        },
      ]}
      setIsBulkSelectMode={setIsBulkSelectMode}
    >
      <InfiniteBannerList
        itemCount={itemCount}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        height={height}
        parentRef={parentRef}
        noItemsComponent={<div>No Initiatives match that criteria.</div>}
      >
        {(index: number) => (
          <InitiativeBanner initiative={initiatives[index]} index={index} />
        )}
      </InfiniteBannerList>
    </BulkSelectWrapper>
  );
};
