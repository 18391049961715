import * as React from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { throttle } from 'utility/throttle';
import * as Text from 'DesignSystem/Typography';
import { usePermissions } from 'contexts/permissions';
import { LoadingSkeleton } from 'shared/LoadingSkeleton';
import { AIAssistedSearchModal } from 'App/Program/Main/Search/AIAssistedSearchModal';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { CollectFeedback } from 'App/Program/Main/Feedback/CollectFeedback';
import { HoverIconMenu } from 'shared/hover-dropdown/HoverIconMenu';
import { FeedbackStyle } from '@socialchorus/critique-ui';
import { MenuItemsType } from 'shared/BannerListItem';
import { Breadcrumbs } from './Breadcrumbs';
import { Tabs } from './Tabs';
import { ActionData, Actions } from './Actions';
import { MainNavigation } from './MainNavigation';
import { InfiniteList, InfiniteListProps } from './ListContainers';
import styles from './layout.module.css';
import { SiteHeader } from './SiteHeader';
import { ErrorBoundaryScreen } from './Error';

export const PageHeader: React.FC<{
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
  actions?: ActionData[];
  menuItems?: MenuItemsType;
  actionsOverride?: React.ReactElement;
  filterbar?: React.ReactElement;
  breadcrumbs?: { to?: string; label: string; isLoading?: boolean }[];
  tabs?: { to: string; label: string }[];
  preserveSearch?: boolean;
  isLoading?: boolean;
  alert?: React.ReactElement;
}> = ({
  filterbar,
  alert,
  title,
  description,
  actions = [],
  menuItems = [],
  actionsOverride,
  breadcrumbs = [],
  tabs = [],
  preserveSearch = false,
  isLoading = false,
}) => (
  <>
    <div className={styles.PageHeader}>
      {breadcrumbs.length > 0 && <Breadcrumbs links={breadcrumbs} />}
      <div className={styles.TitleActions}>
        <Text.PageHeading block={false}>
          {isLoading ? <LoadingSkeleton width={200} /> : title}
        </Text.PageHeading>
        <div className={styles.ActionsGroup}>
          {actionsOverride ?? null}
          {!actionsOverride && actions.length > 0 && (
            <Actions actions={actions} />
          )}
          {menuItems.length > 0 && displayMenuItems(menuItems)}
        </div>
      </div>
      {description && (
        <div className={styles.HeaderDescription}>
          <Text.Body block>{description}</Text.Body>
        </div>
      )}
      {tabs.length > 0 && <Tabs preserveSearch={preserveSearch} links={tabs} />}
    </div>
    {alert}
    {filterbar && <div className={styles.FilterBar}>{filterbar}</div>}
  </>
);

const displayMenuItems = (items: MenuItemsType) => {
  return (
    <div className={styles.menu}>
      <HoverIconMenu
        layout="classic"
        menuItems={items}
        dropdownClassName={cx('dropdown-align-right', styles.hoverDropdown)}
        openDelay="click"
      >
        <button type="button" className={styles.button}>
          <span>•</span>
          <span>•</span>
          <span>•</span>
        </button>
      </HoverIconMenu>
    </div>
  );
};

export const MainBody: React.FC<{
  noSiteHeader?: boolean;
  title: string;
}> = ({ children, noSiteHeader, title }) => {
  const siteHeaderRef = React.useRef<HTMLElement | null>(null);
  const mainRef = React.useRef<HTMLElement | null>(null);
  const {
    permissions: { campaignsAccess, calendarAccess },
  } = usePermissions();
  const [
    showAIAssistedSearchModal,
    setShowAIAssistedSearchModal,
  ] = React.useState(false);
  const { id: programId } = useProgram();

  const showNotification = campaignsAccess && calendarAccess;
  const { data: showAIAssistedSearchIcon } = useFeatureFlagsQuery(
    programId,
    'Studio.AI.Navigation'
  );

  React.useEffect(() => {
    const main = mainRef.current;
    const siteHeader = siteHeaderRef.current;
    const handleScroll = throttle(() => {
      if (siteHeader && main) {
        if (main.scrollTop <= 20) {
          siteHeader.classList.remove(styles.SiteHeaderShadow);
        } else {
          siteHeader.classList.add(styles.SiteHeaderShadow);
        }
      }
    });
    if (main) {
      main.addEventListener('scroll', handleScroll);
    }
    const handleKeydown = (e: KeyboardEvent) => {
      if (!showAIAssistedSearchIcon?.value) return;

      if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'k') {
        e.preventDefault();
        setShowAIAssistedSearchModal(true);
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      if (main) main.removeEventListener('scroll', handleScroll);
      if (siteHeader) siteHeader.classList.remove(styles.SiteHeaderShadow);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [showAIAssistedSearchIcon]);

  const body = React.useMemo(
    () =>
      noSiteHeader ? (
        children
      ) : (
        <>
          <SiteHeader
            innerRef={siteHeaderRef}
            showNotification={showNotification}
            onAIAssistedNavigationClick={() =>
              setShowAIAssistedSearchModal(true)
            }
          />
          <CollectFeedback type={FeedbackStyle.Banner} />
          <main ref={mainRef}>{children}</main>
          {showAIAssistedSearchModal && (
            <AIAssistedSearchModal
              close={() => setShowAIAssistedSearchModal(false)}
            />
          )}
        </>
      ),
    [noSiteHeader, children, showNotification, showAIAssistedSearchModal]
  );
  const pageCssClass = title ? styles[`${title}PageBody`] : '';

  return <div className={`${styles.MainBody} ${pageCssClass}`}>{body}</div>;
};

export const PageTemplate: React.FC<{
  title: React.ComponentProps<typeof PageHeader>['title'];
  noSiteHeader?: boolean;
  noNavigation?: boolean;
}> = ({ children, title, noSiteHeader, noNavigation }) => {
  return (
    <>
      <Helmet>
        <title>{title || ''}</title>
      </Helmet>
      <div className={styles.PageTemplate}>
        {!noNavigation && <MainNavigation />}
        <ErrorBoundaryScreen>
          <MainBody noSiteHeader={noSiteHeader} title={`${title}`}>
            {children}
          </MainBody>
        </ErrorBoundaryScreen>
      </div>
    </>
  );
};

export const FormPage: React.FC<React.ComponentProps<typeof PageHeader>> = ({
  children,
  title,
  ...props
}) => (
  <PageTemplate title={title}>
    <div className={styles.FormPageHeader}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PageHeader title={title} {...props} />
    </div>
    <div className={styles.FormPage}>{children}</div>
  </PageTemplate>
);

export function ListPage<T>(
  props: Partial<InfiniteListProps<T>> &
    React.ComponentProps<typeof PageHeader> &
    Pick<React.ComponentProps<typeof PageTemplate>, 'noNavigation'> & {
      listHeaderBar?: React.ReactElement;
    }
): ReturnType<React.FC> {
  const {
    children,
    title,
    emptyList,
    loading,
    renderRow,
    infiniteQuery,
    noNavigation,
    listHeaderBar,
    ...pageHeaderProps
  } = props;
  return (
    <PageTemplate title={title} noNavigation={noNavigation}>
      <div className={styles.ListPage}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PageHeader title={title} {...pageHeaderProps} />
        {infiniteQuery && emptyList && renderRow ? (
          <InfiniteList
            emptyList={emptyList}
            renderRow={renderRow}
            loading={loading || <>...</>}
            infiniteQuery={infiniteQuery}
            listHeaderBar={listHeaderBar}
          />
        ) : (
          children
        )}
      </div>
    </PageTemplate>
  );
}

ListPage.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  noNavigation: false,
  listHeaderBar: undefined,
};
