import * as React from 'react';
import cx from 'classnames';
import { DefinitionBlock } from 'models/publisher/block';
import {
  FormatData,
  VerticalAlignment as Alignments,
} from 'models/publisher/format';
import { MAIcon } from 'shared/MAIcon';
import { deepMerge } from 'utility/deep-merge';
import styles from './vertical-alignment.module.css';

export const VerticalAlignment: React.FC<{
  id: string;
  block: DefinitionBlock;
  onChange: (id: string, data: FormatData) => void;
}> = ({ id, block, onChange }) => {
  const changeFormatting = React.useCallback(
    (data: FormatData) => {
      onChange(id, deepMerge(block.format_data ?? {}, data));
    },
    [id, block, onChange]
  );

  const alignmentMap = [
    {
      icon: <MAIcon name="vertical_align_top" />,
      value: Alignments.Top,
    },
    {
      icon: <MAIcon name="vertical_align_center" />,
      value: Alignments.Middle,
    },
    {
      icon: <MAIcon name="vertical_align_bottom" />,
      value: Alignments.Bottom,
    },
  ];

  return (
    <div>
      <strong>Vertical Alignment</strong>
      <div className={cx(styles.iconsBox)}>
        {alignmentMap.map(({ icon, value }) => (
          <button
            key={value}
            type="button"
            className={cx(
              styles.alignmentButton,
              block.format_data?.vertical_alignment === value && styles.active,
              styles.iconButton
            )}
            onClick={() => changeFormatting({ vertical_alignment: value })}
          >
            {icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export function canEditVerticalAlignment(block: DefinitionBlock): boolean {
  return (
    !!block.format_options?.vertical_alignments?.length &&
    !['6', 'fullBleed', 'gallery'].includes(block.format_data?.layout || '')
  );
}
