import * as React from 'react';
import { RenderError } from 'models/publisher/block';
import styles from '../editing.module.css';

export const Errors: React.FC<{ errors: RenderError[] }> = ({ errors }) => (
  <div className={styles.errors}>
    <ul>
      {errors.map((error) => (
        <li key={`${error.field_name}-${error.message}`}>
          <strong>{error.field_label}</strong>
          {error.message}
        </li>
      ))}
    </ul>
  </div>
);
