import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useInitiativesInfiniteQuery } from 'hooks/initiatives';
import { useStickyNavBarContext } from 'contexts/sticky-navbar';
import { useProgram } from 'contexts/program';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useDebounce } from 'hooks/useDebounce';
import { Icon } from 'shared/Icon';
import { WithPermission } from 'shared/WithPermission';
import { FiltersBar } from './FiltersBar';
import { InitiativesList } from './InitiativesList';

export const InitiativesListPage: React.FC<RouteComponentProps> = () => {
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [statuses, setStatuses] = useFilterDropdownQueryString('statuses', [
    'active',
  ]);

  const { id: programId } = useProgram();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const { handlePageScroll } = useStickyNavBarContext();
  const query = useDebounce(searchTerm);
  const selectedStatuses = useDebounce(statuses);
  React.useEffect(() => {
    handlePageScroll(parentRef);
    // eslint-disable-next-line
  }, [parentRef]);

  const filterChangeCallbacks: { [key: string]: (values: string[]) => void } = {
    status: setStatuses,
  };

  function onFilterChange(filterName: string, values: string[]) {
    filterChangeCallbacks[filterName](values);
  }

  const {
    isLoading,
    data,
    meta,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
  } = useInitiativesInfiniteQuery({
    programId,
    query,
    statuses: selectedStatuses,
  });

  return (
    <WithPermission permissions={['initiativeAccess']}>
      <ListPage
        title="Initiatives"
        breadcrumbs={[
          { to: '..', label: 'Configure' },
          { label: 'Initiatives' },
        ]}
        filterbar={
          <FiltersBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statuses={statuses}
            onFilterChange={onFilterChange}
          />
        }
        actions={[
          {
            label: 'Initiative',
            to: './new',
            icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
          },
        ]}
      >
        {errorMessage && <>{errorMessage}</>}
        {data && !errorMessage && (
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <InitiativesList
                parentRef={parentRef}
                height={height}
                errorMessage={errorMessage}
                isLoading={isLoading}
                initiatives={data}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                totalRecords={meta?.totalRecords}
                filterConfig={{ search: query, statuses: selectedStatuses }}
              />
            )}
          </InfiniteContainer>
        )}
      </ListPage>
    </WithPermission>
  );
};
