import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Topic } from '../../../../../../../models/topic';
import { SectionContainer } from '../../TopicForm/components/SettingsView/common';
import { Visibility } from './Visibility';
import { Audience } from './Audience';
import { AutoFollow } from './AutoFollow';
import { MemberSubmissions } from './MemberSubmissions';

interface Props extends RouteComponentProps {
  topic: Topic;
}

function Settings({ topic }: Props): JSX.Element {
  return (
    <SectionContainer>
      <Visibility topic={topic} />
      <Audience topic={topic} />
      <AutoFollow topic={topic} />
      <MemberSubmissions topic={topic} />
    </SectionContainer>
  );
}

export default Settings;
