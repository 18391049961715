import React from 'react';
import { Color } from 'components/publisher/pages/Design/Color';
import { Size } from 'components/publisher/pages/Design/Size';
import { FieldInput } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { FieldLabel } from 'DesignSystem/Typography';
import { UpdateVideoCaptionStylesProps } from 'services/api-assets';
import { SelectGroup } from 'DesignSystem/Form/SelectGroup';
import { VideoJsColors } from 'models/colors';
import { ChevronDown, ChevronUp } from 'shared/icons';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './styles.module.css';

export const CaptionStyles: React.FC<
  UpdateVideoCaptionStylesProps & {
    disabled?: boolean;
    onChange: (value: UpdateVideoCaptionStylesProps) => void;
  }
> = ({
  captionsColor,
  captionsColorBackground,
  captionsFontSize,
  captionsPosition,
  disabled,
  onChange,
}) => {
  const CAPTION_POSITONS = {
    bottom: 'Bottom of video',
    top: 'Top of video',
  };

  const [expanded, setExpanded] = React.useState(false);

  const clampSizeValue = (value: number) => {
    if (value > 200 && value <= 250) {
      return 200;
    }
    if (value > 250 && value < 300) {
      return 300;
    }
    if (value > 300 && value <= 350) {
      return 300;
    }
    if (value > 350 && value < 400) {
      return 400;
    }
    return value;
  };

  return (
    <Box margin={[16, 0, 0, 0]}>
      <FieldInput>
        <Flex
          start
          onClick={() => setExpanded(!expanded)}
          className={styles.expandable}
        >
          <Flex>
            <FieldLabel>Captions Styling</FieldLabel>
          </Flex>
          <Flex>{expanded ? <ChevronUp /> : <ChevronDown />}</Flex>
        </Flex>

        {expanded && (
          <div className={styles.section}>
            <div className={styles.setting}>
              <strong>Font color</strong>
              <Color
                onChange={(value) => {
                  const updatedStyles = {
                    captionsColor: value,
                    captionsColorBackground,
                    captionsFontSize,
                    captionsPosition,
                  };
                  onChange(updatedStyles);
                }}
                colors={VideoJsColors}
                value={captionsColor}
                disabled={disabled}
                hideBrandColors
              />
            </div>
            <div className={styles.setting}>
              <strong>Background color</strong>
              <Color
                onChange={(value) => {
                  const updatedStyles = {
                    captionsColor,
                    captionsColorBackground: value,
                    captionsFontSize,
                    captionsPosition,
                  };
                  onChange(updatedStyles);
                }}
                colors={VideoJsColors}
                value={captionsColorBackground}
                disabled={disabled}
                hideBrandColors
              />
            </div>
            <div className={styles.setting}>
              <strong>Font size</strong>
              <Size
                value={captionsFontSize}
                onChange={(value) => {
                  const updatedStyles = {
                    captionsColor,
                    captionsColorBackground,
                    captionsFontSize: clampSizeValue(value),
                    captionsPosition,
                  };
                  onChange(updatedStyles);
                }}
                min={50}
                max={200}
                step={25}
                disabled={disabled}
                render={(value?: number) => `${value}%`}
              />
            </div>
            {false && (
              <>
                <div className={styles.setting}>
                  <strong>Caption location</strong>
                </div>

                <SelectGroup
                  values={['bottom', 'top']}
                  selected={captionsPosition ?? 'bottom'}
                  render={(position: 'bottom' | 'top') => (
                    <strong>{CAPTION_POSITONS[position]}</strong>
                  )}
                  compare={(position) => position}
                  disabled={disabled}
                  onChange={(position) => {
                    const updatedStyles = {
                      captionsColor,
                      captionsColorBackground,
                      captionsFontSize,
                      captionsPosition: position,
                    };
                    onChange(updatedStyles);
                  }}
                />
              </>
            )}
          </div>
        )}
      </FieldInput>
    </Box>
  );
};
