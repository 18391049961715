import React from 'react';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { AliasData } from 'services/api-author-alias';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { MenuItemsType } from 'shared/BannerListItem';
import { Thumbnail } from 'shared/Thumbnail';
import { useActions } from './useActions';
import styles from './styles.module.css';

type PropsType = {
  authorAlias: AliasData;
  index: number;
};

export const AuthorAliasBanner: React.FC<PropsType> = ({ authorAlias }) => {
  const [authorAliasState, setAuthorAliasState] = React.useState(authorAlias);
  const { archive, unarchive } = useActions(setAuthorAliasState);
  const menuItems: MenuItemsType = [{ title: 'View', href: '#' }];
  // putting this on a separate line so that it will merge.
  // I will fix this after it all goes onto the release branch
  menuItems[0].href = `/${authorAlias.programId}/configure/author-aliases/${authorAlias.id}/details`;
  if (authorAlias.aliasType !== 'program') {
    menuItems.push({
      title: authorAliasState.archivedAt ? 'Unarchive' : 'Archive',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (authorAliasState.archivedAt) {
          unarchive(authorAlias.id as number);
        } else {
          archive(authorAlias.id as number);
        }
      },
    });
  }

  const thumbnail = (alias: AliasData) => {
    const words = alias.displayName?.split(/\s+/);
    return {
      title: words
        ? (words.length === 1
            ? words[0].substr(0, 2)
            : words[0].charAt(0) + words[1].charAt(0)
          ).toUpperCase()
        : '',
      backgroundColor: alias.avatarUrl ? 'transparent' : 'var(--color-gray50)',
      color: 'var(--color-gray00)',
      imageUrl: alias.avatarUrl,
    };
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={styles.BannerWrapper}>
      <EmptyBanner
        isSelectable={authorAlias.aliasType !== 'program'}
        menuItems={menuItems}
        rowId={String(authorAlias.id)}
        notSelectableReason="Program alias can't be modified"
      >
        <Flex spread className={styles.Banner}>
          <Box className={styles.Thumbnail}>
            <Thumbnail {...thumbnail(authorAlias)} size="large" />
          </Box>
          <Flex spread className={styles.BannerContent}>
            <Box className={styles.NameCol}>
              <Text.Body bold block>
                <span title={authorAlias.displayName}>
                  {authorAlias.displayName}
                </span>
              </Text.Body>
              <Box className={styles.Description}>
                <Text.Caption>
                  <span title={authorAlias.description}>
                    {authorAlias.description}
                  </span>
                </Text.Caption>
              </Box>
            </Box>
            <Box className={styles.CreatorCol}>
              <div className={styles.ColHeader}>Created by</div>
              {authorAlias.creator && (
                <Text.Body>
                  <span
                    title={`${authorAlias.creator?.firstName} ${authorAlias.creator?.lastName}`}
                  >
                    {authorAlias.creator?.firstName}{' '}
                    {authorAlias.creator?.lastName}
                  </span>
                </Text.Body>
              )}
              {!authorAlias.creator && <Text.Body>System</Text.Body>}
            </Box>
            <Box className={styles.DateCol}>
              <div className={styles.ColHeader}>Date Created</div>
              <Text.Body>
                {DateTime.fromJSDate(
                  new Date(authorAlias.createdAt as string)
                ).toFormat('DD')}
              </Text.Body>
            </Box>
            <Box className={styles.TypeCol}>
              <div className={styles.ColHeader}>Type</div>
              {authorAlias.aliasType === 'user' && (
                <div className={cx(styles.TypePile, styles.UserTypePile)}>
                  User
                </div>
              )}
              {authorAlias.aliasType === 'program' && (
                <div className={cx(styles.TypePile, styles.ProgramTypePile)}>
                  Program
                </div>
              )}
              {authorAlias.aliasType === 'team' && (
                <div className={cx(styles.TypePile, styles.TeamTypePile)}>
                  Team
                </div>
              )}
            </Box>
            <Box className={styles.DateCol}>
              <div className={styles.ColHeader}>Status</div>
              <Text.Body>
                {authorAlias.archivedAt ? 'Archived' : 'Active'}
              </Text.Body>
            </Box>
          </Flex>
        </Flex>
      </EmptyBanner>
    </div>
  );
};
