import React from 'react';
import { ImageData } from 'models/image';
import { useFlashMessage } from 'contexts/flasher';
import { compactArray } from 'utility/objectUtils';
import { uniqueId } from './useUniqueId';

type PropsType = {
  onDrop: (image: Array<ImageData>) => void;
  saveDisabled: boolean;
  enableSave: () => void;
  disableSave: () => void;
  maxSize?: number;
  length: number;
  waitForImageProcessing?: boolean;
};

type Uploader = (
  props: PropsType
) => {
  uploadingFiles: { id: string; file: File }[];
  uploadingUrls: ImageData[];
  handleFileUpload: (items: DataTransferItemList | File[]) => void;
  handleUrlUpload: (items: ImageData[]) => void;
  appendImages: (items: ImageData[]) => void;
  onFileUpload: (image: ImageData) => void;
  onUrlUpload: (image: ImageData) => void;
  hasPendingUploads: boolean;
};

export const useBulkUploader: Uploader = ({
  length,
  onDrop,
  saveDisabled,
  enableSave,
  disableSave,
  waitForImageProcessing = false,
  maxSize = 1000,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const [uploadingFiles, setUploadingFiles] = React.useState<
    { id: string; file: File }[]
  >([]);
  const [uploadingUrls, setUploadingUrls] = React.useState<ImageData[]>([]);

  const uploadingFilesCountRef = React.useRef(0);
  const doneFilesCountRef = React.useRef(0);
  const uploadingUrlsCountRef = React.useRef(0);
  const doneUrlsCountRef = React.useRef(0);

  const [uploadingCount, doneCount] = [
    uploadingFilesCountRef.current + uploadingUrlsCountRef.current,
    doneFilesCountRef.current + doneUrlsCountRef.current,
  ];
  const hasPendingUploads = uploadingCount > doneCount;

  React.useEffect(() => {
    if (!hasPendingUploads && saveDisabled && !waitForImageProcessing)
      enableSave();
  }, [hasPendingUploads, saveDisabled, enableSave, waitForImageProcessing]);

  React.useEffect(() => {
    if (hasPendingUploads && !saveDisabled) disableSave();
  }, [hasPendingUploads, saveDisabled, disableSave]);

  const appendImages = React.useCallback(
    (more: ImageData[]) => {
      if (!more.length) return false;
      onDrop(more);
      return true;
    },
    [onDrop]
  );

  const onFileUpload = React.useCallback(
    (image: ImageData) => {
      if (appendImages([image])) {
        doneFilesCountRef.current += 1;
      }
    },
    [appendImages]
  );

  const getUploadLimit = React.useCallback(
    (newFilesCount: number) => {
      const limit = maxSize - length;
      if (newFilesCount > limit) {
        setFlashMessage({
          severity: 'error',
          message: 'Image limit reached',
        });
      }
      return Math.min(limit, newFilesCount);
    },
    [length, maxSize, setFlashMessage]
  );

  const handleFileUpload = React.useCallback(
    (items: DataTransferItemList | File[]) => {
      const limit = getUploadLimit(items.length);
      const files = toFilesWithId(items).slice(0, limit);

      uploadingFilesCountRef.current = uploadingFiles.length + files.length;
      setUploadingFiles([...uploadingFiles, ...files]);
    },
    [getUploadLimit, uploadingFiles]
  );

  const handleUrlUpload = React.useCallback(
    (items: ImageData[]) => {
      const images = items.slice(0, getUploadLimit(items.length));
      uploadingUrlsCountRef.current = uploadingUrls.length + images.length;
      setUploadingUrls([...uploadingUrls, ...images]);
    },
    [uploadingUrls, getUploadLimit]
  );

  const onUrlUpload = React.useCallback(
    (image: ImageData) => {
      if (appendImages([image])) {
        doneUrlsCountRef.current += 1;
      }
    },
    [appendImages]
  );

  return {
    uploadingFiles,
    uploadingUrls,
    handleUrlUpload,
    handleFileUpload,
    appendImages,
    onFileUpload,
    onUrlUpload,
    hasPendingUploads,
  };
};

function toFilesWithId(
  input: DataTransferItemList | File[]
): Array<{ id: string; file: File }> {
  const files: File[] = Array.isArray(input)
    ? input
    : compactArray(Array.from(input).map((item) => item.getAsFile()));

  return files.map((file) => ({ id: uniqueId(), file }));
}
