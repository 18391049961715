import React from 'react';
import { Audience } from 'models/audience';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { People } from './People';
import { AudienceList } from './AudienceList';
import styles from './audience.module.css';

export const AudienceSummary: React.FC<{ audiences: Array<Audience> }> = ({
  audiences,
}) => {
  return (
    <Section title="Target">
      <Row>
        <Block>
          {audiences.length > 0 ? (
            <div className={styles.block}>
              <People />
              <AudienceList audiences={audiences} />
            </div>
          ) : (
            <span className={styles.empty}>No audience selected.</span>
          )}
        </Block>
      </Row>
    </Section>
  );
};
