import React from 'react';
import { User } from 'models/user';
import { Topic } from 'models/topic';
import { IdentityScope } from 'models/identity';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { GeneralInfo } from '../../Edit/Main/Sections/General';
import { Permissions } from '../../Edit/Main/Sections/Permissions';

export const UserInfoForm: React.FC<{
  data: User;
  identityScopes: IdentityScope[];
  onDataChange: (field: string, value: string) => void;
  onTopicChange: (value: Topic[], topicsById: { [key: string]: Topic }) => void;
  onIdentityScopesChange: (value: IdentityScope[]) => void;
}> = ({
  data,
  identityScopes,
  onDataChange,
  onTopicChange,
  onIdentityScopesChange,
}) => {
  const { id: programId } = useProgram();
  const { permissions } = usePermissions();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );

  return (
    <>
      <div>
        <div>
          <GeneralInfo
            data={data}
            programId={programId}
            onDataChange={onDataChange}
            onTopicChange={onTopicChange}
          />
          {showPermissionsUI?.value &&
            permissions.configureRoleAccess &&
            permissions.confContentAccess && (
              <Permissions
                user={data}
                onDataChange={onDataChange}
                identityScopes={identityScopes}
                onIdentityScopesChange={onIdentityScopesChange}
              />
            )}
        </div>
      </div>
    </>
  );
};
