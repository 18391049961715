import * as React from 'react';

export const SubmittableForm: React.FC<{
  disabled: boolean;
  onSubmit(): void;
}> = ({ disabled, onSubmit, children }) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      if (!disabled) onSubmit();
    }}
  >
    <div style={{ height: 0, overflow: 'hidden' }}>
      <input tabIndex={-1} type="submit" />
    </div>
    {children}
  </form>
);
