import React from 'react';
import { ListPage } from 'DesignSystem/Layout/Pages';
import cx from 'classnames';
import { WithPermission } from 'shared/WithPermission';
import {
  useShortcutsLimit,
  useShortcutsQuery,
  useOrderShortcut,
} from 'hooks/shortcuts';
import { useProgram } from 'contexts/program';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { Shortcut } from 'models/shortcut';
import { useFlashServerErrors } from 'utility/errors';
import { Sortable } from 'DesignSystem/Components';
import { ShortcutBanner } from './ShortcutBanner';
import styles from './shortcuts.module.css';
import { Spinner } from '../Shared/Spinner';

export const ShortcutsPageList: React.FC<{ title: string }> = ({ title }) => {
  const { id: programId } = useProgram();
  const {
    data: shortcutsData,
    errorMessage,
    isLoading: isShortcutsLoading,
  } = useShortcutsQuery({
    programId,
  });
  const {
    data: shortcutLimit,
    isLoading: isShortcutsLimitLoading,
  } = useShortcutsLimit();

  const numberOfShortcutLinks =
    shortcutsData?.filter((shortcut) => shortcut.type === 'link').length ?? 0;

  const studioShortcutsLimitValue = Number(shortcutLimit?.value) ?? 25;

  const exceededShortcutLimit =
    numberOfShortcutLinks > studioShortcutsLimitValue;

  const createButtonDisabled =
    isShortcutsLimitLoading || isShortcutsLoading || exceededShortcutLimit;
  const { mutate: orderShortcutFn } = useOrderShortcut();

  const flashServerErrors = useFlashServerErrors();

  const onSort = (newOrder: Array<Shortcut>) => {
    const newOrderedIds: Array<number> = newOrder.map((value: Shortcut) => {
      return value.id;
    });
    orderShortcutFn(
      { ids: newOrderedIds },
      {
        onError(e) {
          flashServerErrors(e, 'Could not update the order of shortcuts');
        },
      }
    );
  };

  return (
    <WithPermission permissions={['configureExperienceSettingsAccess']}>
      <ListPage
        title={title}
        breadcrumbs={[{ to: '.. ', label: 'Configure' }, { label: title }]}
        actions={[
          {
            icon: <Icon name="Plus" fill="currentColor" />,
            label: 'Create',
            to: createButtonDisabled ? undefined : 'new',
            disabled: createButtonDisabled,
          },
        ]}
      >
        {isShortcutsLoading && (
          <section className={styles.loadingContainer}>
            <Spinner />
          </section>
        )}
        {errorMessage && <>{errorMessage}</>}
        {shortcutsData && !errorMessage && (
          // <table> isnt compatible with the Banner underlying element, and from my understanding, we dont use <table> in this codebase.
          // still, we have a use case which is semantically a table. using roles here to reflect that.
          <>
            <Sortable
              className={styles.list}
              items={shortcutsData}
              idAttr="id"
              handleClass=".handle"
              onSort={onSort}
              itemType="List"
              Item={({ item }) => (
                <div className={styles.listItem}>
                  <div role="cell">
                    <button
                      type="button"
                      className={cx('handle', styles.dragHandle)}
                    >
                      <span>••</span>
                      <span>••</span>
                      <span>••</span>
                    </button>
                  </div>
                  <div className={styles.bannerContainer}>
                    <ShortcutBanner shortcut={item} />
                  </div>
                </div>
              )}
            />
          </>
        )}
      </ListPage>
    </WithPermission>
  );
};
