import React, { ReactNode } from 'react';
import { Button, FormSection, FormSubsection } from 'DesignSystem/Form';
import { TextInput } from 'shared/TextInput';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { FAIcon } from 'shared/Icon/FAIcon';
import { Text } from 'DesignSystem/Typography';
import { TLinkItem } from 'models/shortcut';
import { ShortcutErrorsState } from '../utils/types';
import styles from './create.module.css';
import { LINK_COUNT_LIMIT } from '../utils/constants';

export type LinkOnChangeFn = (value: TLinkItem, index: number) => void;
export type HandleRemoveLinkFn = (index: number) => void;

type LinksProps = {
  links: TLinkItem[];
  addLink: () => void;
  onChange: LinkOnChangeFn;
  handleRemoveLinkItem: HandleRemoveLinkFn;
  errors: ShortcutErrorsState;
};

export const LinksCard: React.FC<LinksProps> = ({
  links,
  addLink,
  onChange,
  handleRemoveLinkItem,
  errors,
}) => {
  const linkLimitHit = links.length >= LINK_COUNT_LIMIT;

  return (
    <FormSection title="Links">
      <ul className={styles.linkList}>
        {links.map((link, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <LinkItem
                label={link.label}
                url={link.url}
                index={i}
                onChange={onChange}
                removeLinkItem={handleRemoveLinkItem}
                errors={errors}
                showRemove={links.length > 1}
              />
            </li>
          );
        })}
      </ul>

      <Button
        icon={<Icon name="Plus" fill="currentColor" size={12} />}
        label="Add Link"
        className={styles.addLinkButton}
        secondary
        disabled={linkLimitHit}
        title={linkLimitHit ? 'Maximum number of links reached' : undefined}
        onClick={() => addLink()}
      />
    </FormSection>
  );
};

type LinkItemProps = TLinkItem & {
  index: number;
  onChange: LinkOnChangeFn;
  removeLinkItem: HandleRemoveLinkFn;
  errors: ShortcutErrorsState;
  showRemove: boolean;
};

const LinkItem: React.FC<LinkItemProps> = ({
  label: name,
  url,
  index,
  onChange: onChangeProp,
  removeLinkItem,
  errors,
  showRemove,
}) => {
  const [urlTouched, setUrlTouched] = React.useState(false);
  const [nameTouched, setNameTouched] = React.useState(false);

  const errorState =
    errors.links && errors.links.length > index
      ? errors.links[index]
      : undefined;

  const nameError = nameTouched ? errorState?.name?.message : undefined;

  const urlError =
    urlTouched && url.length > 2 ? errorState?.url?.message : undefined;

  const onChange = (changed: Partial<TLinkItem>) => {
    onChangeProp(
      {
        label: name,
        url,
        ...changed,
      },
      index
    );
  };

  return (
    <>
      <FormSubsection title="Link Name" divider={false}>
        <TextInput
          value={name}
          onChange={(value) => onChange({ label: value })}
          onBlur={() => setNameTouched(true)}
          className={styles.textInput}
        />
        {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
      </FormSubsection>
      <FormSubsection title="Link URL" divider={false}>
        <TextInput
          value={url}
          onChange={(value) => onChange({ url: value })}
          onBlur={() => setUrlTouched(true)}
          className={styles.textInput}
          type="url"
        />
        {urlError && <ErrorMessage>{urlError}</ErrorMessage>}
      </FormSubsection>
      {showRemove && (
        <Button
          icon={<FAIcon name="trash" />}
          className={styles.removeLinkItem}
          onClick={() => removeLinkItem(index)}
        />
      )}
    </>
  );
};

type ErrorMessageProps = {
  children: ReactNode;
};

const ErrorMessage = ({ children }: ErrorMessageProps) => (
  <div className={styles.errorLabelContainer}>
    <Icon name="WarningTriangle" size={16} fill="currentColor" />
    <Text className={{ Body: true, redFull: true }} as="span">
      {children}
    </Text>
  </div>
);
