import React, { createContext, useContext } from 'react';
import { User } from 'models/user';

type UserContextValue = User | undefined;

const UserContext = createContext(undefined as UserContextValue);

export const useUser = (): User => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('User context hooks require a containing UserProvider');
  }
  return context;
};

export const UserProvider: React.FC<{ value: User }> = ({
  children,
  value,
}) => <UserContext.Provider value={value}>{children}</UserContext.Provider>;
