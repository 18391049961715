import { useRef, useEffect } from 'react';
import { useProgram } from 'contexts/program';
import { PollPreset } from 'models/publisher/block';
import { usePollAssetsQuery } from 'hooks/polls';
import { usePollPreset } from './usePollPreset';
import { usePollAnswers } from './usePollAnswers';

const TEXT_PRESETS: (undefined | PollPreset)[] = ['single', 'multiple'];

export function usePresetAnswersEffect({
  preset,
  setAnswers,
  answers,
}: ReturnType<typeof usePollPreset> & ReturnType<typeof usePollAnswers>): void {
  const { data: assets } = usePollAssetsQuery(useProgram().id);
  const previousPreset = useRef<PollPreset>();
  const wasEditingText = TEXT_PRESETS.includes(previousPreset.current);
  const isEditingText = TEXT_PRESETS.includes(preset);

  const update = useRef(false);
  let texts = answers.map(({ value }) => value);
  const previousTexts = useRef([] as string[]);

  // Back up their answers when they switch away,
  // so we can restore them when they switch back.
  if (wasEditingText && !isEditingText) {
    previousTexts.current = [...texts];
  }
  // If they switched back, restore their previous answers.
  if (previousPreset.current && !wasEditingText && isEditingText) {
    texts = previousTexts.current;
  }

  // If the assets are loaded, load up the preset icons.
  if (assets) {
    if (preset === 'satisfaction')
      texts = [
        assets.satisfaction.very_dissatisfied.url,
        assets.satisfaction.dissatisfied.url,
        assets.satisfaction.neutral.url,
        assets.satisfaction.satisfied.url,
        assets.satisfaction.very_satisfied.url,
      ];
    if (preset === 'stars')
      texts = [
        assets.stars.star_selected.url,
        assets.stars.star_selected.url,
        assets.stars.star_selected.url,
        assets.stars.star_selected.url,
        assets.stars.star_unselected.url,
      ];
    if (preset === 'yes_no')
      texts = [assets.yes_no.thumb_down.url, assets.yes_no.thumb_up.url];
  }

  // Automatically update when we need some placeholders answers.
  if (isEditingText && (!texts[0] || !texts[1])) {
    update.current = true;
    texts[0] = texts[0] || 'Enter Answer 1';
    texts[1] = texts[1] || 'Enter Answer 2';
  }

  // Automatically update when they switch types, and assets are ready.
  if (assets && preset !== previousPreset.current) {
    update.current = true;
  }

  useEffect(() => {
    if (update.current) {
      setAnswers(texts);
      previousPreset.current = preset;
      update.current = false;
    }
  }, [texts, setAnswers, preset]);
}
