import * as React from 'react';
import FroalaEditor, { FroalaEvents } from 'froala-editor';
import { useUniqueId } from 'hooks/useUniqueId';
import {
  useContentEditableHighlighter,
  Highlighter,
} from './useContentEditableHighlighter';
import { InclusiveLanguageEvents } from './types';

// This set of froala events know how to transition the highlighter states.
// It is also resposnible for updating froala when the suggestion is applied.
export function makeFroalaEvents(
  highlighter: Highlighter,
  id: string
): Partial<FroalaEvents> {
  // `self` is set when it is first ready
  let self: FroalaEditor;

  // we want a named function for event bindings
  function triggerContentChanged() {
    // Don't save annotated text. Turn it off, save, then back on.
    const $oel = self.$oel.get(0);
    if (!$oel) return;
    highlighter.clear($oel);
    self.opts.events.contentChanged?.apply(self);
    highlighter.show($oel);
  }

  // --- Note to Developer ---
  // Do not switch these from the object syntax to fat-arrows.
  // The scope of the function matters when used as an event
  // handler for Froala.
  return {
    // set up initial state and kick off the highlighter
    initializationDelayed(this: FroalaEditor) {
      self = this;
      if (!this.$oel) return;
      const $el = this.$oel.get(0);
      if (!$el) return;
      $el.dataset.inclusiveLanguageRoot = id;
      $el.addEventListener(
        InclusiveLanguageEvents.names.applied,
        triggerContentChanged
      );
      highlighter.clear($el);
      highlighter.show($el);
    },
    // turn off the suggestions while the user is editing...
    focus(this: FroalaEditor) {
      const $el = this.$oel.get(0);
      if (!$el) return;
      highlighter.clear($el);
      // table plugin swallows the event that should be hiding it.
      InclusiveLanguageEvents.hide();
    },
    // ...then turn it back on. This avoids cursor bugs.
    blur(this: FroalaEditor) {
      const $el = this.$oel.get(0);
      if (!$el) return;
      highlighter.show($el);
    },
    // shut it all down when done
    destroy(this: FroalaEditor) {
      const $el = this.$oel.get(0);
      if (!$el) return;
      highlighter.clear($el);
      $el.removeEventListener(
        InclusiveLanguageEvents.names.applied,
        triggerContentChanged
      );
      $el.dataset.inclusiveLanguageRoot = undefined;
    },
  };
}

// The main export joins a language highlighter with froala events
export function useFroalaPlugin(
  programId: number
): {
  events: Partial<FroalaEvents>;
} {
  const id = useUniqueId();
  const highlighter = useContentEditableHighlighter(
    programId,
    `[data-inclusive-language-root=${id}]`
  );
  const [events] = React.useState(makeFroalaEvents(highlighter, id));
  return { events };
}
