import * as React from 'react';
import { useEffect } from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import { Icon } from 'shared/Icon';
import { TextInput } from 'shared/TextInput';
import { Video } from 'models/video';
import { useProgram } from 'contexts/program';
import { useQuery } from 'react-query';
import { findOrCreateExternalVideo } from 'services/api-assets';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import cx from 'classnames';
import { isUrlValid } from 'utility/text';
import { useDebounce } from 'hooks/useDebounce';
import { EXTERNAL } from 'models/source';
import { SourceMenu } from '../../SourceMenu';
import { VideoSourceMenu } from '../useVideoSourceMenu';
import styles from './video.module.css';
import forms from '../../form.module.css';
import { PreviewUploader } from '../PreviewUploader';

type PropsType = {
  video?: Video;
  onChange: (video: Video) => void;
  className?: string;
  sourceMenu?: VideoSourceMenu;
  disableSave?: () => void;
  enableSave?: () => void;
};

export const External: React.FC<PropsType> = ({
  video,
  onChange,
  className,
  sourceMenu,
  disableSave,
  enableSave,
}) => {
  const [url, setUrl] = React.useState<string>(video?.url ?? '');
  const debouncedUrl = useDebounce(url);
  // keep local state of altText for UI Performance
  const [altText, setAltTextState] = React.useState<string>(
    video?.altText ?? ''
  );
  const { id: programId } = useProgram();

  const { data: videoDbQueryResult, error, isLoading } = useQuery<Video, Error>(
    [programId, debouncedUrl],
    () => findOrCreateExternalVideo(programId, debouncedUrl, false),
    {
      enabled: isUrlValid(debouncedUrl),
      refetchOnWindowFocus: false,
      retry: false, // unncessary retries delay error from being shown that may confuse users
    }
  );

  useEffect(() => setUrl(video?.url || ''), [video]);
  useEffect(() => {
    if (videoDbQueryResult && video?.id !== videoDbQueryResult?.id) {
      onChange({ ...videoDbQueryResult, sourceType: EXTERNAL });
    }
  }, [videoDbQueryResult, video, onChange]);

  useEffect(() => {
    if (disableSave && enableSave) {
      if (!!error || isLoading || !isUrlValid(debouncedUrl)) {
        disableSave();
      } else {
        enableSave();
      }
    }
  }, [debouncedUrl, disableSave, enableSave, error, isLoading]);

  const setImage = React.useCallback(
    (image) => {
      if (video) {
        const newVideo = {
          ...video,
          customPreviewImageUrl: image.url,
          sourceType: EXTERNAL,
        };
        onChange(newVideo);
      }
    },
    [onChange, video]
  );

  const setAltText = React.useCallback(
    (text) => {
      setAltTextState(text);
      if (video) {
        const newVideo = {
          ...video,
          altText: text,
          sourceType: EXTERNAL,
        };
        onChange(newVideo);
      }
    },
    [onChange, video]
  );

  const isCustomImage =
    videoDbQueryResult &&
    videoDbQueryResult.customPreviewImageUrl !== undefined;

  return (
    <div className={className}>
      <Fieldset className={forms.fieldset}>
        {sourceMenu && (
          <Field label="Source" className={cx(forms.field, styles.videoField)}>
            <div className={styles.sourceFieldContainer}>
              <p className={styles.currentSource}>URL</p>
              <SourceMenu menu={sourceMenu.menu} />
            </div>
          </Field>
        )}
        <Field label="URL" className={cx(forms.field, styles.videoField)}>
          <TextInput
            className="flex-fill"
            value={url}
            onChange={setUrl}
            hasError={!!error || (!!debouncedUrl && !isUrlValid(debouncedUrl))}
          />
          {error?.message && (
            <div className={styles.error}>{error.message}</div>
          )}
        </Field>
        <Field label="Video" className={cx(forms.field, styles.videoField)}>
          {videoDbQueryResult ? (
            <div className={styles.video}>
              <div
                /* This comes from our server and we trust it */
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: videoDbQueryResult.embedHtml,
                }}
              />
            </div>
          ) : (
            <div className={styles.placeholder}>
              {debouncedUrl && isUrlValid(debouncedUrl) && !error ? (
                <LoadingSpinner />
              ) : (
                <Icon iconType="SVG" iconName="Video" size={50} />
              )}
            </div>
          )}
        </Field>
        <Field
          label="Cover Image"
          className={cx(
            forms.field,
            styles.videoField,
            styles.videoPreviewUploader
          )}
        >
          <PreviewUploader
            video={video}
            setImage={setImage}
            shouldClear={!isCustomImage}
          />
          {isCustomImage && (
            <button
              type="button"
              className={styles.removeImage}
              onClick={() => setImage({ url: '' })}
            >
              <Icon iconName="Trash" iconType="SVG" size={20} />
            </button>
          )}
        </Field>
        <Field label="Alt Text" className={cx(forms.field, styles.videoField)}>
          <TextInput
            value={altText}
            onChange={setAltText}
            className={styles.input}
            autoComplete="off"
            disabled={!video?.previewImageUrl && !video?.customPreviewImageUrl}
          />
        </Field>
      </Fieldset>
    </div>
  );
};
