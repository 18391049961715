import * as React from 'react';
import { useNavigate } from '@reach/router';
import { PageTemplate } from 'DesignSystem/Layout/Pages';
import { useHistory } from 'contexts/history';
import { BossViewFrame } from 'shared/BossView/Frame';

const FullScreen: React.FC = ({ children }) => (
  <div style={{ height: '100vh', width: '100vw' }}>{children}</div>
);

export const BossViewPage: React.FC<{
  app: 'samba' | 'programs';
  title: string;
  src: string;
  returnUrls: string[];
  newSlugs?: string[];
  fullScreen?: boolean;
}> = ({ app, title, src, returnUrls, newSlugs, fullScreen }) => {
  const navigate = useNavigate();
  const history = useHistory();
  const backToKai = React.useCallback(() => {
    navigate(history.previous(returnUrls));
  }, [history, navigate, returnUrls]);
  const Comp = fullScreen ? FullScreen : PageTemplate;
  return (
    <Comp title={title}>
      <BossViewFrame
        app={app}
        src={src}
        newSlugs={newSlugs}
        onNavigate={backToKai}
      />
    </Comp>
  );
};
