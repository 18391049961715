import React from 'react';
import { useUserExportQuery } from 'hooks/audience';

export const ExportsDownload: React.FC<{
  exportId: number;
  programId: number;
}> = ({ exportId, programId }) => {
  const { isLoading, data } = useUserExportQuery(exportId, programId);
  React.useEffect(() => {
    if (isLoading || !data?.downloadUrl) return;

    const link = document.createElement('a');
    link.href = data?.downloadUrl as string;
    link.setAttribute('download', `${data?.identifier as string}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [data, isLoading]);

  return <div />;
};
