import React from 'react';
import { TextInput } from '@socialchorus/shared-ui-components';

import { useDebounce } from 'hooks/useDebounce';
import { LanguageControl } from 'models/language-controls/language-control';
import styles from './language-control-edit-page.module.css';

type TermInputProps = {
  value?: string;
  property: string;
  onChange: (data: Partial<LanguageControl>) => void;
  placeholder?: string;
};

export const TermInput: React.FC<TermInputProps> = ({
  value,
  property,
  onChange,
  placeholder,
}) => {
  const [text, setText] = React.useState<string | undefined>(value);
  const debouncedText = useDebounce(text);

  React.useEffect(() => {
    if (typeof debouncedText === 'undefined' || debouncedText === value) return;
    onChange({ [property]: debouncedText });
  });

  return (
    <TextInput
      value={text}
      onChange={setText}
      placeholder={placeholder ?? 'Enter a term'}
      fullWidth
      className={styles.textInput}
    />
  );
};
