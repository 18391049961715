import * as React from 'react';
import { FieldInput, Textarea } from 'DesignSystem/Form';
import { FieldLegend } from '../../shared/FieldLegend';

export const ThankYouText: React.FC<{
  value?: string;
  onChange(thank_you_text: string): void;
}> = ({ value, onChange }) => (
  <FieldInput
    legend={<FieldLegend>Confirmation message</FieldLegend>}
    htmlFor="block-poll-thank-you-text"
  >
    <Textarea
      maxLength={200}
      id="block-poll-question"
      placeholder="Thank you for voting!"
      value={value}
      onChange={onChange}
    />
  </FieldInput>
);
