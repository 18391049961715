import { useState } from 'react';
import {
  QueryFunctionContext,
  QueryKey,
  UseMutateFunction,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  FiltersStateType,
  deriveFilterValues,
  parameterizeFilters,
  ParameterizeFiltersProcessors,
  ParameterizedFilters,
} from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import {
  BulkSelection,
  InfiniteQueryResponse,
  MutationOptions,
  QueryResponse,
} from './common';
import { Content } from '../models/content';
import { Poll } from '../models/poll';
import {
  updateBulkStatus,
  ContentCollectionData,
  ContentFetchProps,
  ContentData,
  fetchContent,
  fetchUserContentSubmissions,
  FetchProps,
  bulkPublish,
  bulkComplete,
  BulkParams,
  EditableData,
  fetchEditable,
  fetchBulkActionActiveJob,
  fetchBulkActionActiveJobStatus,
  FetchBulkActionActiveJobData,
  FetchBulkActionActiveJobOptions,
  FetchBulkActionActiveJobStatusOptions,
  FetchBulkActionActiveJobStatusData,
  updateBulkArchive,
  cancelBulkProcess,
} from '../services/api-content';
import { ContentPage, ContentPageCursor } from '../models/content-page';
import { useFeatureFlagsQuery } from './feature-flags';
import { AbsoluteRange, DateRange } from '../shared/DateRangeInput/DateRange';

export function mapServerDataToContent(
  serverData: ContentCollectionData | undefined
): ContentPage {
  if (!serverData) return { data: [], cursor: undefined };
  const data: (Content | Poll)[] = [];
  serverData.data.forEach((entity: ContentData) => {
    if (entity.type === 'poll') {
      data.push({
        type: 'poll',
        ...entity.attributes,
      });
    }
    if (entity.type === 'content_planner') {
      data.push({
        type: 'content_planner',
        ...entity.attributes,
        title: entity.attributes.title ?? '',
        contentTopics: entity.attributes.contentChannels,
      });
    }
  });
  return { data, cursor: serverData.after, meta: serverData.meta };
}

export const useContentQuery = (
  props: ContentFetchProps
): QueryResponse<Array<Content | Poll>> => {
  const { isLoading, error, data } = useQuery<ContentCollectionData, Error>(
    ['content', { ...props }],
    () => fetchContent(props),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapServerDataToContent(data).data,
  };
};

export function useContentsInfiniteQuery(
  fetchProps: FetchProps,
  filters: FiltersStateType,
  refetchInterval: number | false | undefined = false
): InfiniteQueryResponse<ContentPage> {
  const { id: programId } = useProgram();
  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;
  const contentQuery = {
    ...fetchProps,
    ...parameterizeFilters(filters, {
      standard: ({ filter }) => {
        const allOptionEnabled =
          filter.allSource &&
          filters.standard.sourceTypes?.values.includes(filter.allSource);
        return filter.values.length > 0 && !allOptionEnabled
          ? {
              [filter.field]: deriveFilterValues(
                filter.values,
                filter.name === 'author_aliases' && useAuthorAliases
              ).join(','),
            }
          : undefined;
      },
      boolean: ({ filter }) => {
        const query: ParameterizedFilters = {};
        if (filter.value && filter.field) {
          query[filter.field] = true;
        }
        if (filter.value && filter.name === 'shareable') {
          query.visibility = 'public';
        }
        return query;
      },
      date: ({ filter }) => {
        if (filter.isSelected && filter.value && filter.value.length > 0) {
          const range = DateRange.buildFromKey(filter.value) as AbsoluteRange;

          if (range && range.start && range.end) {
            const rangeStart = range.start.toUnixInteger();
            const rangeEnd = range.end.toUnixInteger();
            const [fieldStart, fieldEnd] = filter.queryFields;
            return {
              [fieldStart]: rangeStart,
              [fieldEnd]: rangeEnd,
            };
          }

          return undefined;
        }
        return undefined;
      },
    }),
  };

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ContentPage, Error>(
    ['paged-contents', contentQuery],
    async (context: QueryFunctionContext<QueryKey, ContentPageCursor>) => {
      const collection = await fetchContent({
        ...contentQuery,
        after: context.pageParam,
      });
      return mapServerDataToContent(collection);
    },
    {
      refetchOnMount: 'always',
      refetchInterval,
      getNextPageParam(page: ContentPage) {
        return page.data.length && page.cursor ? page.cursor : null;
      },
    }
  );
  return {
    data: data?.pages || [],
    meta: data?.pages[0].meta,
    errorMessage: error?.message,
    isLoading: isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}

const filterIds = (bulkSelection: BulkSelection): BulkParams => {
  const ids = bulkSelection.type === 'none' ? bulkSelection.includedIds : [];
  const excludedIds =
    bulkSelection.type === 'all' ? bulkSelection.excludedIds : [];
  return {
    type: bulkSelection.type,
    includedIds: ids
      .filter((id: string) => id.startsWith('content-'))
      .map((id: string) => id.replace('content-', '')),
    pollIds: ids
      .filter((id) => id.startsWith('poll-'))
      .map((id) => id.replace('poll-', '')),
    excludedIds: excludedIds
      .filter((id) => id.startsWith('content-'))
      .map((id) => id.replace('content-', '')),
    excludedPollIds: excludedIds
      .filter((id) => id.startsWith('poll-'))
      .map((id) => id.replace('poll-', '')),
  };
};

export const useContentBulkStatus = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {},
  currentState?: string
): {
  updateStatus: (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType,
    status: string
  ) => void;
  publish: (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => void;
  bulkArchive: (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => void;
  complete: (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => void;
  isProcessing: boolean;
} => {
  const [isProcessing, setIsProcessing] = useState(false);
  const queryClient = useQueryClient();
  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;
  const filterProcessors: ParameterizeFiltersProcessors = {
    standard: ({ filter }) =>
      filter.values.length > 0
        ? {
            [filter.field]: deriveFilterValues(
              filter.values,
              filter.name === 'author_aliases' && useAuthorAliases
            ),
          }
        : undefined,
    boolean: ({ filter }) => {
      const query: ParameterizedFilters = {};
      if (filter.value && filter.field) {
        query[filter.field] = true;
      }
      if (filter.value && filter.name === 'shareable') {
        query.visibility = 'public';
      }
      return query;
    },
    date: ({ filter }) => {
      const query: ParameterizedFilters = {};
      if (filter.isSelected) {
        const [sinceValue, beforeValue] = filter.value?.split('~') ?? [];
        const [sinceField, beforeField] = filter.queryFields;
        if (sinceValue && sinceField) {
          query[sinceField] = sinceValue;
        }
        if (beforeValue && beforeField) {
          // current search evaluates created_before and published_before based on (epoch < date) so to include the date requested we must increment by one day
          query[beforeField] = DateTime.fromISO(beforeValue)
            .plus({ days: 1 })
            .toISODate();
        }
      }
      return query;
    },
  };

  const updateStatus = (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType,
    status: string
  ) => {
    const bulkParams = filterIds(bulkSelection);
    const bulkQuery = {
      ...fetchProps,
      ...parameterizeFilters(filters, filterProcessors),
      currentState,
    };

    const request = updateBulkStatus(programId, bulkParams, bulkQuery, status);

    request
      .then(() => {
        queryClient.invalidateQueries(['paged-contents']);
        if (onSuccess) onSuccess('');
      })
      .catch((e) => {
        if (onError) onError(e.message);
      });
  };

  const bulkArchive = (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => {
    setIsProcessing(true);
    const bulkParams = filterIds(bulkSelection);
    const bulkQuery = {
      ...fetchProps,
      ...parameterizeFilters(filters, filterProcessors),
      currentState,
    };

    const request = updateBulkArchive(programId, bulkParams, bulkQuery);

    request
      .then((jobId: string) => {
        if (onSuccess) onSuccess(jobId);
      })
      .catch((error) => {
        if (onError) onError(error.message);
      })
      .finally(() => setIsProcessing(false));
  };

  const publish = (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => {
    setIsProcessing(true);
    const bulkParams = filterIds(bulkSelection);
    const bulkQuery = {
      ...fetchProps,
      ...parameterizeFilters(filters, filterProcessors),
      currentState,
    };

    const request = bulkPublish(programId, bulkParams, bulkQuery);

    request
      .then((jobId: string) => {
        if (onSuccess) onSuccess(jobId);
      })
      .catch((error) => {
        if (onError) onError(error.message);
      })
      .finally(() => setIsProcessing(false));
  };

  const complete = (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => {
    const bulkParams = filterIds(bulkSelection);
    const bulkQuery = {
      ...fetchProps,
      ...parameterizeFilters(filters, filterProcessors),
    };

    const request = bulkComplete(programId, bulkParams, bulkQuery);

    request.then(() => {
      queryClient.invalidateQueries(['paged-contents']);
      if (onSuccess) onSuccess('');
    });
  };
  return {
    updateStatus,
    publish,
    complete,
    bulkArchive,
    isProcessing,
  };
};

export const useFetchContentSubmissionsByUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  fetch: (userId: number) => void;
} => {
  const fetch = (userId: number) => {
    const request = fetchUserContentSubmissions(programId, userId);
    request
      .then((response) => {
        const totalRecords = response.meta?.totalRecords || 0;
        if (onSuccess) onSuccess(totalRecords.toString());
      })
      .catch((e) => {
        if (onError) onError(e.message);
      });
  };

  return { fetch };
};

export const useEditable = (
  programId: number,
  content: Content
): QueryResponse<EditableData> => {
  const { isLoading, error, data } = useQuery<EditableData, Error>(
    ['editable', programId, content],
    () => fetchEditable(programId, content),
    { retry: false, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useCheckBulkActionActiveJob = (
  props: FetchBulkActionActiveJobOptions,
  onSuccess: (data: FetchBulkActionActiveJobData) => void,
  onError?: (err: Error) => void
): QueryResponse<FetchBulkActionActiveJobData> => {
  const { isLoading, error, data } = useQuery<
    FetchBulkActionActiveJobData,
    Error
  >(['bulkActionActiveJob', props], () => fetchBulkActionActiveJob(props), {
    retry: false,
    onSuccess,
    onError,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useCheckBulkActionActiveJobStatus = (
  props: FetchBulkActionActiveJobStatusOptions,
  onSuccess: (data: FetchBulkActionActiveJobStatusData) => void
): QueryResponse<FetchBulkActionActiveJobStatusData> => {
  const { isLoading, error, data } = useQuery<
    FetchBulkActionActiveJobStatusData,
    Error
  >(
    ['bulkActionActiveJobStatus', { ...props }],
    () => fetchBulkActionActiveJobStatus(props),
    { retry: false, enabled: !!props.jobId, refetchInterval: 3000, onSuccess }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export function useCancelBulkProcessMutation(
  onComplete: (error?: Error) => void
): {
  isLoading: boolean;
  mutateCancelBulkProcess: UseMutateFunction<
    void,
    unknown,
    { programId: number; jobId: string }
  >;
} {
  const { isLoading, mutate: mutateCancelBulkProcess } = useMutation(
    ['cancel_bulk_process'],
    (data: { programId: number; jobId: string }) =>
      cancelBulkProcess(data, onComplete)
  );
  return {
    isLoading,
    mutateCancelBulkProcess,
  };
}
