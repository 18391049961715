import React, { useEffect, useMemo } from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import {
  journeysKeys,
  useJourneysInfiniteQuery,
  useJourneys,
} from 'hooks/journeys/journeys';
import { navigate } from '@reach/router';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useProgram } from 'contexts/program';
import { useJourneyTabState } from 'contexts/journeys/journey-tab';
import { useUserByIdsQuery } from 'hooks/user';
import { useFlashMessage } from 'contexts/flasher';
import { useQueryClient } from 'react-query';
import { JourneyListItem } from 'models/journeys/journey';
import { ListItem } from './ListItem';
import { JourneyFilterBar } from '../JourneyFilterBar';
import { useFilters } from './useFilters';
import { useJourneyErrorHandler } from '../error-handler-context';
import { JourneyModal } from '../JourneyModal';
import { usePollProcessingJourneys } from '../use-poll-processing-journeys';
import { useInitialSearchQuery } from './useInitialSearchQuery';

function useAnnouncePublishedJourneys(journeys: JourneyListItem[]) {
  const { setFlashMessage } = useFlashMessage();
  // We need to escape from React's render paradigm with useEffect to avoid
  // calling setFlashMessage in a loop during render.
  useEffect(() => {
    if (journeys.length < 1) {
      return;
    }

    journeys.forEach((journey) => {
      setFlashMessage({
        message: `${journey.name} has been published`,
        severity: 'info',
      });
    });
  }, [journeys, setFlashMessage]);
}

export const JourneyList: React.FC = () => {
  const { id: programId } = useProgram();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const initialSearchQuery = React.useRef(window.location.search);
  const { errorMessage, setErrorMessage } = useJourneyErrorHandler();
  const { initialFiltersData, initialSearch } = useInitialSearchQuery(
    initialSearchQuery.current
  );
  const {
    filters,
    searchTerm,
    setFilters,
    setSearchTerm,
    queryParameters,
    parameterizedFilters,
    path,
    tabState,
  } = useFilters(initialFiltersData, initialSearch);

  const { setJourneyTabState } = useJourneyTabState();
  useEffect(() => {
    setJourneyTabState(tabState);
  }, [tabState, setJourneyTabState]);

  useEffect(() => {
    navigate(path);
  }, [path]);

  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data,
  } = useJourneysInfiniteQuery(queryParameters, parameterizedFilters);

  const queryClient = useQueryClient();
  const { data: processingIds = [] } = useJourneys('processing', {
    // When we have a lot of processing journeys, we want to make sure we get all of them
    // otherwise we might miss some when polling
    pageSize: 100,
    select: (d) => d.data.map((journey) => String(journey.id)),
  });
  const { publishedJourneys } = usePollProcessingJourneys(processingIds);
  useAnnouncePublishedJourneys(publishedJourneys);

  const refreshList = React.useCallback(() => {
    queryClient.invalidateQueries([...journeysKeys.page()]);
  }, [queryClient]);
  const dismissErrorModal = () => {
    setErrorMessage(undefined);
  };

  const journeyCreatorIds = React.useMemo(
    () =>
      data.length > 0
        ? [
            ...new Set(
              data
                .map((journeyListItem) => journeyListItem.createdBy)
                .filter((createdBy): createdBy is number => !!createdBy)
            ),
          ]
        : [],
    [data]
  );

  const {
    data: journeyAuthors,
    isLoading: isAuthorsLoading,
  } = useUserByIdsQuery(programId, journeyCreatorIds);

  const filtersData = useMemo(() => {
    return filters.filter(
      (filter) =>
        filter.name !== 'publicationState' ||
        (filter.name === 'publicationState' && tabState !== 'archive')
    );
  }, [filters, tabState]);
  return (
    <>
      <JourneyFilterBar
        filters={filtersData}
        onFiltersChange={setFilters}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
      />
      {errorMessage && (
        <JourneyModal
          name="Journey error"
          header="Journey error"
          body={errorMessage}
          action={dismissErrorModal}
          actionLabel="OK"
        />
      )}
      <InfiniteContainer parentRef={parentRef}>
        {(height: number) => (
          <InfiniteBannerList
            isLoading={isLoading}
            itemCount={data.length}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            height={height}
            parentRef={parentRef}
          >
            {(index: number) => (
              <ListItem
                key={data[index].id}
                journey={data[index]}
                authors={journeyAuthors}
                isAuthorsLoading={isAuthorsLoading}
                refreshList={refreshList}
              />
            )}
          </InfiniteBannerList>
        )}
      </InfiniteContainer>
    </>
  );
};
