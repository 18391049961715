export function BoxLogoSvg(): string {
  return `
    <svg 
      version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px" y="0px" viewBox="375 270 470 260" xml:space="preserve">
      <style type="text/css">
        .st0{fill:#0061D5;}
      </style>
      <g>
        <g>
          <path class="st0" d="M617.4,345.4c-32.9,0-61.4,18.5-75.8,45.6c-14.4-27.1-43-45.6-75.9-45.6c-19.3,0-37.1,6.4-51.5,17.2v-72.3c-0.2-9.3-7.8-16.8-17.2-16.8c-9.4,0-16.9,7.5-17.1,16.8v142.4h0c0.8,46.8,38.9,84.4,85.8,84.4c32.9,0,61.5-18.5,75.9-45.6c14.4,27.1,43,45.6,75.8,45.6c47.4,0,85.9-38.4,85.9-85.9C703.2,383.8,664.8,345.4,617.4,345.4 M465.7,482.7c-28.4,0-51.5-23-51.5-51.5c0-28.4,23.1-51.5,51.5-51.5c28.4,0,51.5,23,51.5,51.5C517.2,459.7,494.1,482.7,465.7,482.7 M617.4,482.7c-28.4,0-51.5-23-51.5-51.5c0-28.4,23.1-51.5,51.5-51.5c28.4,0,51.5,23,51.5,51.5C668.9,459.7,645.8,482.7,617.4,482.7"/>
          <path class="st0" d="M836.4,488.4l-46.7-57.2l46.7-57.4c5.9-7.6,4.2-18.1-3.9-23.7c-8.2-5.7-19.6-4.2-25.9,3.1v0l-40.2,49.3l-40.2-49.3v0c-6.3-7.3-17.8-8.8-25.9-3.1c-8.1,5.6-9.8,16.2-3.9,23.7h0l46.6,57.4l-46.6,57.2h0c-5.9,7.6-4.2,18.1,3.9,23.8c8.1,5.6,19.7,4.2,25.9-3.2l40.2-49.2l40.1,49.2c6.3,7.4,17.8,8.8,25.9,3.2C840.7,506.5,842.4,495.9,836.4,488.4"/>
        </g>
      </g>
    </svg>`;
}
