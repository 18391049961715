import React, { useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { OptionType } from 'hooks/common';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { selectComponents, selectStyles, selectTheme } from '../Select';

const options = [
  { value: 'is', label: 'is' },
  { value: 'NOT', label: 'is not' },
];
const optionsWithContains = [
  ...options,
  { value: 'contains', label: 'contains' },
];

export const EqualitySelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
  isDisabled?: boolean;
  showContains?: boolean;
}> = ({ onChange, value, isDisabled, showContains = true }) => {
  const { id: programId } = useProgram();
  const { data: allowContainsOperator } = useFeatureFlagsQuery(
    programId,
    'Audience.Builder.Contains'
  );

  const optionValue =
    optionsWithContains.find((option) => option.value === value) || options[0];
  const onOptionChange = (option: ValueType<OptionType>) => {
    const newOp = option as OptionType;
    onChange(newOp.value);
  };

  useEffect(() => {
    if (!value) {
      onChange(options[0].value);
    }
  }, [value, onChange]);

  return (
    <Select
      value={optionValue}
      options={
        showContains && allowContainsOperator?.value
          ? optionsWithContains
          : options
      }
      isDisabled={isDisabled}
      onChange={onOptionChange}
      components={selectComponents}
      styles={selectStyles}
      theme={selectTheme}
      menuPlacement="auto"
    />
  );
};
