import React from 'react';
import styles from './loading-skeleton.module.css';

export const LoadingSkeleton: React.FC<{
  width?: number;
}> = ({ width = 100 }) => (
  <div style={{ width }} className={styles.Skeleton}>
    <div className={styles.SkeletonItem} />
  </div>
);
