import React from 'react';
import { useProgramIdState } from 'contexts/program';
import { deleteFont } from 'services/api-assets';
import { useBasicValidator, ValidatorsType } from './useBasicValidator';
import { useFontUpload } from './font';
import { defaultFontData, FontData } from '../models/font';

const rules = {
  ALLOWED_EXTENSIONS: ['woff2', 'ttf', 'otf', 'woff'],
};

const ERRORS = {
  EXTENSION: `File has invalid extension. Use one of the following: ${rules.ALLOWED_EXTENSIONS}`,
  CANNOT_UPLOAD: 'Cannot upload file. Service is not available',
};

const validators: ValidatorsType<File> = {
  extension: (file) => {
    const parts = file.name.split('.');
    const extension = parts[parts.length - 1]?.toLowerCase();
    return !rules.ALLOWED_EXTENSIONS.includes(extension);
  },
};

type PropsType = {
  onChange: (font: FontData) => void;
  existingData?: FontData;
  allowedExtensions?: string[];
};

export type Uploader = (
  props: PropsType
) => {
  allowedExtensions: string[];
  isUploading: boolean;
  update: (file: File) => Promise<void>;
  error: string | undefined;
  file: File | undefined;
  hasFile: boolean;
  upload: (f: File) => void;
  fileName?: string;
  clearUpload: () => void;
};

export const useFontUploader: Uploader = ({ onChange, existingData }) => {
  const onlyName = (fullFileName: string) =>
    fullFileName.substring(0, fullFileName.lastIndexOf('.'));

  const extension = (fileName: string) =>
    fileName.substring(fileName.lastIndexOf('.'));

  const validator = useBasicValidator<File>(validators, ERRORS);
  const [error, setError] = React.useState<string>();
  const [file, setFile] = React.useState<File>();

  const [programId] = useProgramIdState();
  const fontDataRef = React.useRef<FontData>();

  React.useEffect(() => {
    fontDataRef.current = existingData;
  }, [existingData]);

  const [name, setName] = React.useState<string>(
    onlyName(existingData?.filename || '')
  );

  const fileWithName = React.useCallback(
    (fileToChange: File) => {
      const isNameUnchanged =
        !name || onlyName(name) === onlyName(fileToChange.name || '');
      if (fileToChange && isNameUnchanged) return fileToChange;

      return new File(
        [fileToChange],
        `${name}${extension(fileToChange.name)}`,
        { type: fileToChange.type }
      );
    },
    [name]
  );

  const onUpload = React.useCallback(
    (data: FontData) => {
      // we need to use the ref here
      // otherwise this callback always has a stale value of fontData
      if (fontDataRef.current) {
        const newData = {
          ...fontDataRef.current,
          assetKey: data.assetKey,
          status: data.status,
          url: data.url,
          family: data.family,
        };
        if (onChange) {
          onChange(newData);
        }
      }
    },
    [onChange]
  );

  const { mutate, isSaving: isUploading } = useFontUpload({
    onSuccess: (data) => {
      onUpload(data);
      setError(undefined);
    },
    onError: (queryError) => {
      setError(queryError.message);
    },
  });

  const update = React.useCallback(
    async (f: File) => {
      setFile(f);
      const { isValid, errors: messages } = validator.validate(f);

      if (!isValid) {
        setError(messages.join());
        return;
      }

      mutate({ file: f, programId });
    },
    [mutate, programId, validator]
  );

  const upload = React.useCallback(
    (fileToUpload: File) => {
      const setOnlyName = (newFileName: string) =>
        setName(onlyName(newFileName));
      const customizedFile = fileWithName(fileToUpload);
      setFile(fileToUpload);
      if (existingData && onChange) {
        onChange({
          ...existingData,
          filename: customizedFile.name,
          filesize: customizedFile.size,
          filetype: customizedFile.type,
        });
      }
      setOnlyName(customizedFile.name);
      update(customizedFile);
    },
    [existingData, fileWithName, onChange, update]
  );

  const clearUpload = React.useCallback(() => {
    const assetKey = existingData?.assetKey;
    if (assetKey && assetKey.length > 0) {
      deleteFont({ assetKey, programId });
    }
    setFile(undefined);
    setError(undefined);
    onChange(defaultFontData);
  }, [existingData?.assetKey, onChange, programId]);

  const hasFile = React.useMemo(() => {
    if (file) {
      return true;
    }
    if (existingData && existingData.filesize > 0) {
      return true;
    }
    return false;
  }, [existingData, file]);

  const fileName = React.useMemo(() => {
    return file?.name || existingData?.filename;
  }, [existingData?.filename, file?.name]);

  return {
    allowedExtensions: rules.ALLOWED_EXTENSIONS,
    isUploading,
    error,
    update,
    file,
    hasFile,
    upload,
    fileName,
    clearUpload,
  };
};
