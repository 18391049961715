import * as React from 'react';
import { FieldType } from 'models/publisher/block';
import { DevReveal, PrettyJson } from 'DevMode';
import { useEditor, EditorProps, UpdateOpts } from './useEditor';
import { Errors } from './Errors';
import { modalEditableFields } from './fields/modalFields';
import styles from '../editing.module.css';

export const Editor: React.FC<EditorProps> = (props) => {
  const { field } = props;
  const editor = useEditor(props);

  const fieldEditor = modalEditableFields[field.type]({
    field,
    apply: editor.apply,
    data: editor.working,
    default_data: editor.block.field_data,
    onChange: (data: FieldType, opts?: UpdateOpts) =>
      editor.update(field, data, opts),
    saveDisabled: editor.saveDisabled,
    disableSave: editor.disableSave,
    enableSave: editor.enableSave,
  });

  return (
    <div className={styles.layout}>
      <aside>
        <h2>{`Edit ${field.display_name}`}</h2>
        <DevReveal
          view={
            <PrettyJson
              value={{
                isValid: editor.valid,
                isValildating: editor.isValidating,
                isDirtyForValidation: editor.isDirtyForValidation,
              }}
            />
          }
        >
          <button
            data-test="block-modal-apply-button"
            className={styles.applyButton}
            type="button"
            onClick={editor.apply}
            disabled={!editor.valid || editor.saveDisabled}
          >
            Apply
          </button>
        </DevReveal>

        <button
          className={styles.cancelButton}
          type="button"
          onClick={editor.cancel}
        >
          Cancel
        </button>
        {!editor.valid && <Errors errors={editor.errors} />}
      </aside>
      <div className={styles.main}>{fieldEditor}</div>
    </div>
  );
};
