import FroalaEditor from 'froala-editor';
import { getActor } from 'services/global-actor-storage';
import { bossanovaDomain } from 'services/api-shared';

// Required for froala to do anything with the options configured below
import 'froala-editor/js/plugins/file.min';

import { injectPluginsAndToolbarButtons } from '../froalaCodeViewUtils';
import type { Options } from '../froala-configs';

const ONE_HUNDRED_MB = 100 * 1024 * 1024;
// This is a list of mime types that we support for file uploads.
// Taken directly from pony: ContentAttachment::FileTypes
// Despite being hard-coded, this list of FileTypes hasn't been updated since
// 2018, so it's probably not worth building an API endpoint just to
// check current file-types.
const SUPPORTED_CONTENT_ATTACHMENT_MIME_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'text/calendar',
];

// This is just a pure HTML copy of the LoadingSpinner component
const loader = `
<style>
.dualRing {
  display: inline-block;
  width: 71px;
  height: 71px;
  animation: dualRing 0.5s linear infinite;
  position: relative;
}

.dualRing > svg:last-child {
  position: absolute;
  top: 35px;
  left: 35px;
}

@keyframes dualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
<div role="status" aria-label="Loading" class="dualRing medium">
  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35.5" cy="35.5" r="31.5" stroke="var(--color-gray10)" stroke-width="7"/>
  </svg>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 0.5C32 17.897 17.897 32 0.5 32" stroke="var(--color-brandTintDark)" stroke-width="7"/>
  </svg>
</div>

`;

const UPLOADING_FILE_TEMPLATE = `<div class="custom-layer" style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 280px; height: 150px">
  <h4 style="margin-bottom: 10px">Uploading file...</h4>
  ${loader}
</div>`;

const ERROR_UPLOADING_FILE_TEMPLATE = (errorMessage: string) =>
  `<div class="custom-layer fr-error" style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 280px; height: 150px; padding: 0 10px;">
  <h4>File upload was unsuccessful</h4>
  <p>Received error: ${errorMessage}</p>
</div>`;

/**
 * Despite this using the Froala file upload plugin, we do not adhere to the "typical" usage.
 * We use the file upload plugin to upload files to S3, and then host them on our own servers.
 * Note also that this is selectively applied to the Froala editor, so we spread the existing options
 * before defining the file upload plugin.
 *
 * This workflow is intimately tied to the ContentAttachment model in pony.
 * In general, the flow of network requests is: plugin -> bossanova -> pony -> S3 -> pony -> bossanova -> plugin
 * Once the ContentAttachment is created and saved, it is served from advocato.
 * Note that donkey is responsible for the sanitization of any input into the froala editor.
 *
 * Due to this, there are some interesting settings:
 * - fileUploadURL is set to an empty string, as we don't use it.
 * - We limit uploads to one file at a time due to the complexity of handling multiple files with our current APIs.
 */
export async function addFileUploadPlugin(
  options: Options,
  programId: number | string
): Promise<Options> {
  return {
    ...options,
    ...injectPluginsAndToolbarButtons(options, ['file'], ['insertFile']),
    fileUploadURL: '',
    fileUploadMethod: 'POST',
    fileMaxSize: ONE_HUNDRED_MB,
    fileAllowedTypes: SUPPORTED_CONTENT_ATTACHMENT_MIME_TYPES,
    events: {
      ...options.events,
      'file.beforeUpload': function handleFileUpload(files) {
        // Froala's typing for the files argument is incorrect
        // This re-types files to what it actually is
        const file = ((files as unknown) as FileList)[0];
        const editor = this as FroalaEditor;
        const actor = getActor();
        if (!actor) throw new Error('Not logged in.');

        showPopup(editor, ($popup) => {
          $popup.html(UPLOADING_FILE_TEMPLATE);
        });

        const commonBossanovaHeaders = {
          ...actor.headers,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-requested-with': 'XMLHttpRequest',
        };
        const getS3UploadUrl = `${bossanovaDomain}/samba/programs/${programId}/content_attachment_uploads/upload_url`;
        fetch(getS3UploadUrl, {
          method: 'POST',
          headers: commonBossanovaHeaders,
          body: JSON.stringify({
            file_name: file.name,
            file_type: file.type,
          }),
        }).then(async (s3UrlResponse: Response) => {
          if (!s3UrlResponse.ok) {
            throw new Error(
              `Error while trying to get S3 upload URL from ${getS3UploadUrl}`
            );
          }

          const json = await s3UrlResponse.json();
          const s3UploadUrl: string | undefined = json?.upload_url;
          if (!s3UploadUrl) {
            throw new Error('Did not receive S3 upload URL');
          }

          const s3FileUploadHeaders = {
            'Content-Type':
              file.type || json?.suggested_file_type || 'multipart/form-data',
            'x-amz-acl': 'bucket-owner-full-control',
          };
          const s3Upload = await fetch(s3UploadUrl, {
            method: 'PUT',
            body: file,
            headers: s3FileUploadHeaders,
          });
          if (!s3Upload.ok) throw s3Upload.status;

          const sendAttachmentUrl = `${bossanovaDomain}/samba/programs/${programId}/content_attachment_uploads/host_attachment`;
          const hostedAttachment = await fetch(sendAttachmentUrl, {
            method: 'POST',
            headers: commonBossanovaHeaders,
            body: JSON.stringify({
              url: s3UploadUrl,
              program_id: programId,
              file: {
                original_filename: file.name,
                size: file.size,
                content_type: file.type,
              },
            }),
          });
          if (!hostedAttachment.ok) throw hostedAttachment.status;
          const hostedAttachmentJson = await hostedAttachment.json();
          const htmlToEmbed =
            hostedAttachmentJson?.data?.attributes?.embed_html;
          if (!htmlToEmbed) {
            showPopup(editor, ($popup) => {
              $popup.html(
                ERROR_UPLOADING_FILE_TEMPLATE(
                  'The hosted file could not be retrieved from Firstup.'
                )
              );
            });
          } else {
            embedHtmlInRichTextBlock(editor, htmlToEmbed);
            editor.popups.hideAll();
          }
        });

        // We immediately return false here (note the above block is a promise)
        // to cancel the default Froala upload behavior which is to upload to their server,
        // and to put an inline file link. We first upload the file to S3, and then
        // provide a pass-through link served from advocato.
        return false;
      },
      // This event is triggered by the file upload plugin, but can also be manually triggered (which we don't do).
      // It will _not_ be triggered if our internal Firstup hosting process fails (i.e. like if bossanova doesn't return a S3 upload URL).
      'file.error': function handleFileUploadError(
        this: FroalaEditor,

        // This error truly is an "any"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: any
      ) {
        const editor = this as FroalaEditor;
        // These error codes are from the Froala documentation: https://froala.com/wysiwyg-editor/docs/concepts/file/upload/
        const errorMessage: string | undefined = {
          1: 'Bad link',
          2: 'No link in upload response',
          3: 'Error during file upload',
          4: 'Parsing response failed',
          5: 'File too text-large',
          6: 'Invalid file type',
          7: 'File can be uploaded only to same domain in IE 8 and IE 9',
        }[error?.code as number];
        if (errorMessage) {
          showPopup(editor, ($popup) => {
            $popup.html(ERROR_UPLOADING_FILE_TEMPLATE(errorMessage));
          });
        } else {
          const alternateErrorMessage =
            error?.responseText || error?.message || 'Unknown error';
          showPopup(editor, ($popup) => {
            $popup.html(ERROR_UPLOADING_FILE_TEMPLATE(alternateErrorMessage));
          });
        }
        return false;
      },
    },
  };
}

function embedHtmlInRichTextBlock(editor: FroalaEditor, html: string) {
  editor.edit.on();
  editor.events.focus(); // Focus, and restore any existing user-selection
  editor.selection.restore();
  editor.html.insert(html);
  editor.undo.saveStep(); // Save our HTML embed changes to the undo stack.
}

function showPopup(
  editor: FroalaEditor,
  applyPopupContent: ($popup: JQuery) => void
) {
  const popupName = 'file_upload_popup';

  let $popup = editor.popups.get(popupName);
  if (!$popup) {
    editor.popups.create(popupName, {});
    $popup = editor.popups.get(popupName);
  }

  // Set the editor toolbar as the popup's container.
  editor.popups.setContainer(popupName, editor.$tb);

  // We calculate the popup position from the "Insert File" button.
  const $uploadFileButton = editor.$tb.find(
    '.fr-command[data-cmd="insertFile"]'
  );
  const offset = $uploadFileButton.offset() ?? { left: 0, top: 0 };
  const outerWidth = $uploadFileButton.outerWidth() ?? 0;
  const outerHeight = $uploadFileButton.outerHeight() ?? 0;
  const left = offset.left + outerWidth / 2;
  const top = offset.top + (editor.opts.toolbarBottom ? 10 : outerHeight - 10);

  // Allow callback to apply content to the popup
  applyPopupContent($popup);
  editor.popups.show(popupName, left, top, outerHeight); // Set the popup position

  // This feels like a hack, but I can't otherwise get it to work and froala is closed-source. Booo.
  // Basically the popup is "active", but still has the .fr-empty class, so we manually remove it here.
  editor.popups.get(popupName).removeClass('fr-empty');
}
