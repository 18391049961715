import { MutationOptions, useMutation, useQuery } from 'react-query';
import {
  ShowAttachmentProps,
  uploadFont,
  UploadAttachmentProps,
  showFont,
  uploadFontStylesheet,
} from 'services/api-assets';
import { FontData, FontStylesheetData } from 'models/font';
import { MutationResponse, QueryError, QueryResponse } from './common';

export const useFontUpload = ({
  onSuccess,
  onError,
}: MutationOptions<
  FontData,
  QueryError,
  UploadAttachmentProps
> = {}): MutationResponse<UploadAttachmentProps> => {
  const { mutate, isLoading, error } = useMutation<
    FontData,
    QueryError,
    UploadAttachmentProps
  >(uploadFont, { onSuccess, onError });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export const useFontStylesheetUpload = ({
  onSuccess,
}: MutationOptions<
  FontStylesheetData,
  QueryError,
  UploadAttachmentProps
> = {}): MutationResponse<UploadAttachmentProps> => {
  const { mutate, isLoading, error } = useMutation<
    FontStylesheetData,
    QueryError,
    UploadAttachmentProps
  >(uploadFontStylesheet, { onSuccess });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

type AttachmentQueryProps = ShowAttachmentProps & {
  enabled?: boolean;
};

export const useFontQuery = (
  props: AttachmentQueryProps
): QueryResponse<FontData> & {
  refetch: ReturnType<typeof useQuery>['refetch'];
} => {
  const { isLoading, data, error, refetch } = useQuery(
    ['font', props],
    () => showFont(props),
    { enabled: props.enabled }
  );

  return {
    refetch,
    isLoading,
    data,
    errorMessage: (error as QueryError)?.message,
  };
};
