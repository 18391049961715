import FroalaEditor, { FroalaOptions } from 'froala-editor';

export const froalaEditor = (
  // eslint-disable-next-line
  element: any,
  options: Partial<FroalaOptions>
): FroalaEditor => {
  return new FroalaEditor(element, {
    key:
      process.env.REACT_APP_FROALA_EDITOR_KEY_V3 ||
      'SDB17hD9C4A3E4C3A2gKTRe2CG1PGe1DESAe1Kg1EBC1Pe2TKoF4I4B3A9A3B5F4B2B3H4==',
    ...options,
  });
};
