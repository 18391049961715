import React from 'react';
import { ListDropdownItem } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { useProgram } from 'contexts/program';
import { PaginatedListDropdownProvider } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { PaginatedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/PaginatedListDropdown';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { useSemiPaginatedAttributes } from 'hooks/insights/useSemiPaginatedQuery';

export const AttributesPaginatedDropdown: React.FC = () => {
  const { id: programId } = useProgram();
  const { reportId } = React.useContext(ReportContext);
  const { dateRange } = React.useContext(FiltersStateContext);
  const { defaultValues, debouncedSearchString, filter } = React.useContext(
    FilterDropdownContext
  );
  const {
    fetchNext,
    isLoading,
    hasMoreToLoad,
    aggregatedData,
    errorMessage,
    isFetching,
  } = useSemiPaginatedAttributes({
    programId,
    reportId,
    dateRange,
    search: debouncedSearchString,
    attribute_type: filter.slug,
  });

  const allItems: ListDropdownItem[] = React.useMemo(() => {
    return aggregatedData.map((attributeOption) => ({
      label: String(attributeOption.label),
      value: String(attributeOption.value),
      uuid: String(attributeOption.value),
    }));
  }, [aggregatedData]);

  return (
    <PaginatedListDropdownProvider
      allItems={allItems}
      fetchNext={fetchNext}
      isLoadingMoreItems={isLoading || isFetching}
      hasMoreToLoad={hasMoreToLoad}
      defaultValues={defaultValues}
      errorMessage={errorMessage}
    >
      <PaginatedListDropdown />
    </PaginatedListDropdownProvider>
  );
};
