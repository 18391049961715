import React from 'react';
import { NewFilterButton as DesignSystemNewButton } from 'DesignSystem/Table/NewFilterButton';
import NestableMenu from 'shared/NestableMenu';
import {
  NestableMenuItemType,
  NestableMenuType,
  NestableSubmenuType,
} from 'shared/NestableMenu/types';
import { DataSource } from 'models/insight/json/filterJson';
import { FilterState } from 'App/Program/Main/Insight/contexts/filtersStateReducer';
import style from './Filters.module.css';

/*
 * This button will display a NestableMenu containing all available filters for a report
 * Currently it will display everything in a single flat list
 * Eventually we will display user attribute filters in a nested submenu
 * */
type PropsType = {
  filterStates: FilterState[];
  onSelectFilter: (filterState: FilterState) => void;
};
export const NewFiltersButton: React.FC<PropsType> = ({
  filterStates,
  onSelectFilter,
}) => {
  const rootMenuData: NestableMenuType<FilterState> = React.useMemo(() => {
    const attributeFilters: FilterState[] = [];
    const otherFilters: FilterState[] = [];

    filterStates.forEach((filterState) => {
      if (filterState.filter.dataType === DataSource.Attributes) {
        attributeFilters.push(filterState);
      } else {
        otherFilters.push(filterState);
      }
    });

    const sortedAttributeFilters = attributeFilters.sort((a, b) =>
      a.filter.label.localeCompare(b.filter.label)
    );
    const sortedOtherFilters = otherFilters.sort((a, b) =>
      a.filter.label.localeCompare(b.filter.label)
    );

    const userAttributeSubMenu: NestableSubmenuType<FilterState> = {
      key: 'userAttributeSubmenu',
      label: 'User Attributes',
      subMenu: {
        simpleMenu: {
          onSelect: onSelectFilter,
          items: sortedAttributeFilters.map((filterState) => ({
            label: filterState.filter.label,
            key: filterState.filter.slug,
            value: filterState,
          })),
        },
      },
    };

    const otherAttributeMenuItems: NestableMenuItemType<
      FilterState
    >[] = sortedOtherFilters.map((filterState) => {
      return {
        label: filterState.filter.label,
        key: filterState.filter.slug,
        value: filterState,
      };
    });

    return {
      simpleMenu: {
        onSelect: onSelectFilter,
        items:
          sortedAttributeFilters.length > 0
            ? otherAttributeMenuItems.concat(userAttributeSubMenu)
            : otherAttributeMenuItems,
      },
    };
  }, [filterStates, onSelectFilter]);

  const iconAndText = React.useMemo(
    () => (
      <div className={style.FilterLabel}>
        <DesignSystemNewButton />
      </div>
    ),
    []
  );

  return <NestableMenu menuButton={iconAndText} rootMenuData={rootMenuData} />;
};
