import React from 'react';
import { EmailAlias } from 'models/email-alias';
import { useProgram } from 'contexts/program';
import { NavigationFooter } from 'shared/NavigationFooter';

export const Footer: React.FC<{
  emailAlias: Partial<EmailAlias>;
  action: () => void;
  allowSaving: boolean;
}> = ({ emailAlias, action, allowSaving = false }) => {
  const { id: programId } = useProgram();

  const exitPath = `/${programId}/configure/email-alias`;

  return (
    <NavigationFooter
      canPerformAction={allowSaving}
      actionName="Save"
      action={action}
      exitPath={exitPath}
      title={emailAlias?.name}
      hideMenu
    />
  );
};
