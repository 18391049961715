import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';
import { LitmusRole } from 'models/role';
import { IdentityScope } from 'models/identity';

export type RolesFetchParams = {
  search?: string;
  page?: { size: number; number: number };
  pageSize?: number;
  type?: string | string[];
  programId: number;
  order: string;
  hidden?: boolean;
};

export type RolesCollectionData = {
  data: Array<LitmusRole>;
  meta?: {
    totalObjects: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  };
};

export type RoleData = {
  data: LitmusRole;
};

type BulkUpdateFilter = {
  ids: string[] | undefined;
  excludedIds: string[] | undefined;
  query?: string;
  statuses?: string | string[];
  roles?: string | string[];
  audiences?: string[];
  scopes?: string[];
};

export type BulkUpdateParams = {
  filter: BulkUpdateFilter;
  bulkAction: 'replace' | 'add';
  role?: string;
  scopes?: IdentityScope[];
};

export const fetchRoles = async (
  props: RolesFetchParams
): Promise<RolesCollectionData> => {
  const { programId, ...queryParams } = props;
  const query = qs.stringify(snakeCaseKeys({ ...queryParams }), {
    arrayFormat: 'brackets',
  });

  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/roles?${query}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching roles`);
};

export const fetchRoleByName = async (
  programId: number,
  name: string
): Promise<RoleData> => {
  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/roles/${name}`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching role: ${response.status}`);
};

export const fetchRoleById = async (
  programId: number,
  id: number
): Promise<RoleData> => {
  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/roles/fetch_by_id/${id}`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching role: ${response.status}`);
};

export const createRole = async (
  programId: number,
  role: Partial<LitmusRole>
): Promise<RoleData> => {
  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/roles`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(role),
  });

  if (response.status === 200 || response.status === 201) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error creating role: ${response.status}`);
};

export const updateRole = async (
  programId: number,
  role: LitmusRole
): Promise<RoleData> => {
  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/roles/${role.name}`;

  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(role),
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error editing role: ${response.status}`);
};

export const bulkUpdatePermission = async (
  programId: number,
  params: BulkUpdateParams
): Promise<{ id: string }> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/users/update_permissions/bulk`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakeCaseKeys(params)),
  });

  if (response.status === 201) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error submit update permission bulk: ${response.status}`);
};
