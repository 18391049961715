import React from 'react';
import { Button } from 'DesignSystem/Form';
import generateAndClickLink from 'utility/link';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';

type Props = {
  label: string;
  url: string;
  tooltipMessage: string;
};

export const NewTabButton: React.FC<Props> = ({
  url,
  label,
  tooltipMessage,
}) => {
  return (
    <HoverTooltip
      align="right"
      icon={
        <Button
          text
          label={<b>{label}</b>}
          onClick={() => generateAndClickLink(url)}
        />
      }
      content={<Tooltip description={tooltipMessage} />}
    />
  );
};
