import React from 'react';
import { ImageData } from 'models/image';
import { UrlInput as ImageUrlInput } from 'components/publisher/blocks/forms/fields/Image/components/UrlInput';
import { Library } from '../../Image/components/Library';
import { UrlInput as VideoUrlInput } from '../components/UrlInput';
import * as SourceMenu from '../../shared/SourceMenu';

export const UPLOAD = 'upload' as const;
export const LIBRARY = 'library' as const;
export const EXTERNAL = 'external' as const;
export enum ExternalSourceType {
  Video = 'video',
  Image = 'image',
}
type Source = typeof UPLOAD | typeof LIBRARY | typeof EXTERNAL;

export function useVideoSourceMenu({
  onChange,
  inputFileAccept,
  menuSources,
  externalSourceType,
}: {
  onChange: (data: File | string) => void;
  inputFileAccept: string;
  menuSources: Source[];
  externalSourceType: ExternalSourceType;
}): SourceMenu.MenuIface<Source> & {
  fileInput: React.ReactNode;
  libraryInput: React.ReactNode;
  externalInput: React.ReactNode;
} {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onUploadClick = React.useCallback(() => inputRef.current?.click(), [
    inputRef,
  ]);

  const menuOptions: Record<Source, SourceMenu.Config<Source>[number]> = {
    [UPLOAD]: {
      name: 'Upload File',
      source: UPLOAD,
      icon: <SourceMenu.UploadIcon />,
      onClick: onUploadClick,
    },
    [LIBRARY]: {
      name: 'Select from Library',
      source: LIBRARY,
      icon: <SourceMenu.LibraryIcon />,
    },
    [EXTERNAL]: {
      name: 'Add from Link',
      source: EXTERNAL,
      icon: <SourceMenu.LinkIcon />,
    },
  };

  const source = SourceMenu.useSourceMenu(
    menuSources.map((sourceItem) => menuOptions[sourceItem]),
    {
      source: menuSources[0],
      visibility: SourceMenu.CLOSED,
    }
  );

  const fileInput = React.useMemo(
    () => (
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept={inputFileAccept}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.currentTarget.files?.[0];

          if (file) onChange(file);
          if (inputRef.current) inputRef.current.value = '';
        }}
      />
    ),
    [inputFileAccept, onChange]
  );

  const onExternalChange = React.useCallback(
    (url: string) => {
      onChange(url);
      source.close();
    },
    [onChange, source]
  );

  const libraryInput = React.useMemo(
    () =>
      source.source === LIBRARY && source.visibility === SourceMenu.OPENED ? (
        <Library
          maxSelections={1}
          onClose={source.close}
          onChange={(imageData: ImageData[]) => onChange(imageData[0].url)}
        />
      ) : null,
    [source.source, source.visibility, source.close, onChange]
  );

  const externalInput = React.useMemo(() => {
    if (
      !(source.source === EXTERNAL && source.visibility === SourceMenu.OPENED)
    )
      return null;

    return externalSourceType === ExternalSourceType.Image ? (
      <ImageUrlInput onChange={onExternalChange} onCancel={source.close} />
    ) : (
      <VideoUrlInput
        onChange={onExternalChange}
        onCancel={source.close}
        autoplay={false}
      />
    );
  }, [
    source.source,
    source.visibility,
    source.close,
    externalSourceType,
    onExternalChange,
  ]);

  return {
    ...source,
    fileInput,
    libraryInput,
    externalInput,
  };
}
