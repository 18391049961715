import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Post } from 'models/publisher/post';
import { CampaignRules } from 'components/publisher/pages/Review/Info/CampaignRules';
import cx from 'classnames';
import { AudienceSummary } from './AudienceSummary';
import { Content } from './Content';
import { Orchestrate } from './Orchestrate';
import styles from './info.module.css';
import parentStyles from '../review.module.css';

export const Info: React.FC<
  {
    post: Post;
    isValid: boolean;
  } & RouteComponentProps
> = ({ post, isValid }) => {
  return (
    <div className={styles.container}>
      <div className={cx({ [parentStyles.disabled]: !isValid })}>
        <AudienceSummary audiences={post.settings.audiences} />
        <Content />
        <Orchestrate />
        <CampaignRules post={post} />
      </div>
    </div>
  );
};
