import * as React from 'react';
import { uniqueId } from '../useUniqueId';
import styles from './highlights.module.css';
import * as E from './types';

// TODO switch to a react portal
// A shared div that can be used across all instances of
// language suggestions. You'll only ever see one at a time,
// so this makes it easier to manage a collection of instances
// (by not having a collection of instances).
let singletonId: string | undefined;
export function useHighlightPopup(): void {
  React.useEffect(() => {
    // to play nicely with froala, the popup is initiated on
    // mousedown. normal clicks close it, so we keep a little
    // timer to debounce the close effect
    let openedAt = 0;
    let current: E.SuggestionDetail | undefined;
    const popup = document.createElement('div');
    popup.className = styles.popup;
    popup.style.display = 'none';

    function hideSuggestion() {
      current = undefined;
      popup.style.display = 'none';
      popup.dataset.id = undefined;
    }

    function showSuggestion() {
      const detail = E.InclusiveLanguageEvents.pendingDetail.pop();
      if (detail && current?.marker.selector !== detail.marker.selector) {
        const source = document.querySelector<HTMLSpanElement>(
          detail.marker.selector
        );
        if (!source) return;
        popup.innerHTML = `<div>
            <small>Language Guide</small>
            <span>
            ${detail.edit.suggestion
              .map((s) => {
                return `<button>${s}</button>`;
              })
              .join('')}
            </span>
            <p>Inclusive Language - ${detail.edit.explanation}</p>
          </div>`;
        const { top, left } = detail.position || {
          top: detail.event.pageY + 13,
          left: detail.event.pageX - 80,
        };
        popup.style.top = `${top}px`;
        popup.style.left = `${left}px`;
        popup.style.display = 'block';
        current = detail;
        detail.event.preventDefault();
        openedAt = new Date().getTime();
      }
    }

    function applyTextSuggestion() {
      if (!current || current.marker.type === 'content') return;
      const el = document.querySelector<HTMLTextAreaElement | HTMLInputElement>(
        current.marker.selector
      );
      if (!el) return;
      const newValue =
        el.value.substr(0, current.marker.offset) +
        current.edit.suggestion +
        el.value.substr(current.marker.offset + current.edit.term.length);
      el.value = newValue;
      E.InclusiveLanguageEvents.applied(el);
    }

    function applyContentEditableSuggestion(suggestion: string) {
      if (!current || current.marker.type !== 'content') return;
      const source = document.querySelector<HTMLElement>(
        current.marker.selector
      );
      const parent = source?.parentElement;
      source?.replaceWith(suggestion);
      parent?.normalize();
      E.InclusiveLanguageEvents.applied(
        document.querySelector(current.marker.root) || undefined
      );
    }

    function applySuggestion(e: MouseEvent) {
      const target = e.target as HTMLElement;
      if (!popup.contains(target) || target.nodeName !== 'BUTTON') return;
      if (current?.marker.type === 'content') {
        applyContentEditableSuggestion(target.innerText);
      } else {
        applyTextSuggestion();
      }
      hideSuggestion();
    }

    function onWindowClick(e: MouseEvent) {
      const target = e.target as HTMLElement;
      if (popup.style.display === 'none') return;
      if (popup.contains(target)) return;
      if (new Date().getTime() - openedAt < 300) {
        return;
      }
      hideSuggestion();
    }

    const instanceId = uniqueId();
    if (!singletonId) {
      singletonId = instanceId;
      document.body.appendChild(popup);
      document.addEventListener(
        E.InclusiveLanguageEvents.names.hide,
        hideSuggestion
      );
      document.addEventListener(
        E.InclusiveLanguageEvents.names.show,
        showSuggestion
      );
      document.addEventListener('click', applySuggestion);
      document.addEventListener('click', onWindowClick);
    }
    return () => {
      if (singletonId === instanceId) {
        singletonId = undefined;
        document.removeEventListener('click', applySuggestion);
        document.removeEventListener('click', onWindowClick);
        document.removeEventListener('click', hideSuggestion);
        document.removeEventListener(
          E.InclusiveLanguageEvents.names.show,
          showSuggestion
        );
        popup.remove();
      }
    };
  }, []);
}
