import { Editor } from '@tiptap/react';
import { useLibraryFontsCollection } from 'hooks/use-library-fonts-collection';
import React from 'react';
import { GenericSelect } from 'shared/Select';
import editorStyles from '../editor.module.css';

const DEFAULT_FONT_FAMILY = 'Inter';

// putting this here to get this out the door. need to figure out how get the rest of the global fonts supported on patron
const supportedFonts = [
  'Arial',
  'Arial Black',
  'Barlow',
  'Georgia',
  'Helvetica',
  'Raleway',
  'sans-serif',
  'Tahoma',
  'Trebuchet',
  'Verdana',
  DEFAULT_FONT_FAMILY,
];

export const EditorActionFontFamily: React.FC<{
  editor: Editor;
  addFontId: (param: number) => void;
}> = ({ editor, addFontId }) => {
  // Fetch custom fonts
  const { data, isLoading } = useLibraryFontsCollection();

  const getEditorFont = (ed: Editor) => {
    const editorFont = ed.getAttributes('textStyle').fontFamily;
    const selectedFont = availableOptions.find(
      (option) => option.css_value === editorFont
    );
    return selectedFont?.value ?? defaultOption?.value;
  };

  const availableOptions = React.useMemo(() => {
    return (
      data
        .map((i) => {
          return {
            label: i.title,
            value: i.id,
            id: i.id,
            is_enabled_for_program: i.is_enabled_for_program,
            scope: i.scope,
            fallbackFont: i.asset.fallbackFont,
            css_value: i.asset.value,
          };
        }, [])
        // Make sure they are available
        .filter((option) => option.is_enabled_for_program)
        .filter((option) => option.scope === 'global')
        // Remove following line for custom font support
        .filter((option) => supportedFonts.includes(option.label))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [data]);

  const defaultOption = React.useMemo(() => {
    return (
      availableOptions.find((option) => option.label === DEFAULT_FONT_FAMILY) ??
      availableOptions[0]
    );
  }, [availableOptions]);

  return (
    <div className={editorStyles.tiptapDropdownContainer}>
      <GenericSelect
        onChange={(value) => {
          const fontId = value;
          if (fontId) {
            const selectedFont = availableOptions.find(
              (option) => option.id === fontId
            );
            if (selectedFont) {
              // todo: this should track the fonts actually used in the editor https://firstup-io.atlassian.net/browse/EE-18406
              addFontId(parseInt(fontId, 10));
              const fontFamily = selectedFont.css_value ?? selectedFont.label;
              editor.chain().focus().setFontFamily(fontFamily).run();
            }
            return;
          }
          editor.chain().focus().unsetFontFamily().run();
        }}
        options={
          isLoading || availableOptions.length === 0
            ? [{ label: 'Select a Font', value: DEFAULT_FONT_FAMILY }]
            : availableOptions
        }
        value={getEditorFont(editor)}
      />
    </div>
  );
};
