import * as React from 'react';
import { Filter, Template } from 'models/library';
import { useLibraryTemplates } from 'hooks/useLibrary';
import { FocusDropdown } from 'shared/FocusDropdown';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { useDebounce } from 'hooks/useDebounce';
import { fetchItems } from 'services/api-library';
import { useProgram } from 'contexts/program';
import { Dropdown } from './Dropdown';
import styles from './templates-select.module.css';

export type PropsType = {
  selectedTemplateIds: string[];
  onSelectedTemplatesChange: (templates: Template[]) => void;
  maxDropdownHeight?: number;
  placeholder?: string;
  name?: string | string[];
  upward?: boolean;
};

export const TemplatesSelect: React.FC<PropsType> = (props) => {
  const {
    selectedTemplateIds,
    onSelectedTemplatesChange,
    maxDropdownHeight = 400,
    placeholder,
    upward = false,
  } = props;

  const { id: programId } = useProgram();

  const [searchText, setSearchText] = React.useState('');
  const [templatesLoaded, setTemplatesLoaded] = React.useState<Template[]>([]);
  const [
    areInitialTemplatesLoaded,
    setAreInitialTemplatesLoaded,
  ] = React.useState(false);

  const debouncedSearchText = useDebounce(searchText);

  const filter: Filter = React.useMemo(() => {
    if (!debouncedSearchText) {
      return { type: 'all' };
    }
    return { type: 'search', search: debouncedSearchText };
  }, [debouncedSearchText]);

  const {
    isLoading,
    data: dropdownTemplates,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useLibraryTemplates({ filter, restrictedOnly: true, pageSize: 50 });

  React.useEffect(() => {
    const idsOfTemplatesLoaded = new Set(
      templatesLoaded.map((template) => template.id)
    );
    if (!isLoading) {
      const newTemplatesLoaded = [...templatesLoaded];
      let isUpdated = false;
      dropdownTemplates.forEach((template) => {
        if (idsOfTemplatesLoaded.has(template.id)) {
          return;
        }
        newTemplatesLoaded.push(template);
        idsOfTemplatesLoaded.add(template.id);
        isUpdated = true;
      });

      if (isUpdated) {
        setTemplatesLoaded(newTemplatesLoaded);
      }
    }

    if (selectedTemplateIds.length > 0 && !areInitialTemplatesLoaded) {
      const missingTemplateIds: string[] = [];
      selectedTemplateIds.forEach((templateId) => {
        if (idsOfTemplatesLoaded.has(templateId)) {
          return;
        }
        missingTemplateIds.push(templateId);
      });

      if (missingTemplateIds.length > 0) {
        fetchSpecificTemplates(programId, missingTemplateIds).then(
          (templates) => {
            setTemplatesLoaded([...templatesLoaded, ...templates]);
          }
        );
      }

      setAreInitialTemplatesLoaded(true);
    }
  }, [
    areInitialTemplatesLoaded,
    dropdownTemplates,
    isLoading,
    programId,
    selectedTemplateIds,
    templatesLoaded,
  ]);

  const handleRemove = React.useCallback(
    (index: number) => {
      const newSelectedTemplates = templatesLoaded.filter((value) =>
        selectedTemplateIds.includes(value.id)
      );
      newSelectedTemplates.splice(index, 1);
      onSelectedTemplatesChange(newSelectedTemplates);
    },
    [templatesLoaded, onSelectedTemplatesChange, selectedTemplateIds]
  );

  const handleSelectedIdsChange = React.useCallback(
    (ids: string[]) => {
      const newSelectedTemplates = ids
        .map((id) => templatesLoaded.find((template) => template.id === id))
        .filter((item) => !!item);
      onSelectedTemplatesChange(newSelectedTemplates as Template[]);
    },
    [onSelectedTemplatesChange, templatesLoaded]
  );

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={dropdownTemplates}
        selectedIds={selectedTemplateIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        maxHeight={maxDropdownHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        upward={upward}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={templatesLoaded
              .filter((template) => selectedTemplateIds.includes(template.id))
              .map((template) => template.title)}
            onRemoveSelectedValueAt={handleRemove}
            placeholder={placeholder}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};

const fetchSpecificTemplates = async (
  programId: number,
  templateIds: string[]
): Promise<Template[]> => {
  const filter: Filter = { type: 'ids', ids: templateIds };
  const { data } = await fetchItems({ programId, type: 'template', filter });
  return data.map((d) => ({ id: d.id, ...d.attributes } as Template));
};
