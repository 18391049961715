import * as React from 'react';
import { IconMenuItem, ItemType as IconMenuItemType } from './IconMenuItem';

export type ItemType = IconMenuItemType;

type PropsType = {
  menuItems: Array<ItemType>;
  dismiss?: () => void;
  className?: string;
  layout?: 'classic' | 'journeys';
};

export const IconMenu: React.FC<PropsType> = (props) => {
  const { menuItems, dismiss, className, layout } = props;

  return (
    <ul className={className}>
      {menuItems.map((item) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <IconMenuItem
          layout={layout}
          key={item.title}
          dismiss={dismiss}
          {...item}
        />
      ))}
    </ul>
  );
};
