import React from 'react';
import { Post } from 'models/publisher/post';
import { useReadTime } from 'hooks/content/useReadTime';
import { ReactComponent as Warning } from '../Warning.svg';
import { ReactComponent as TimeCards } from './timeCards.svg';
import styles from './block.module.css';

export const BlockContent: React.FC<{
  post: Post;
}> = ({ post }) => {
  const { formattedReadTime } = useReadTime(post);

  if (post.blocks.length > 0) {
    return (
      <div className={styles.block}>
        <TimeCards height="75" width="75" className={styles.errorIcon} />
        <h3 className={styles.text}>{formattedReadTime}</h3>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Warning height="75" width="75" className={styles.errorIcon} />
      <h3 className={styles.text}>{formattedReadTime}</h3>
    </div>
  );
};
