import * as React from 'react';
import cx from 'classnames';
import { Close } from 'shared/icons';
import { FilterDropdown } from 'shared/FilterDropdown';
import { OptionType } from 'hooks/common';
import filtersStyles from 'DesignSystem/Table/filters.module.css';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { FiltersButton } from './FiltersButton';
import { UsersFilter } from '../Shared/filters/UsersFilter';

type FilterProps = {
  onChange: (filterName: string, value: string[] | undefined) => void;
  statuses?: string[];
  types?: string[];
  users?: string[];
};

type FilterState = {
  [name: string]: {
    name: string;
    label: string;
    values?: OptionType[];
    selected: boolean;
  };
};

export const Filters: React.FC<FilterProps> = ({
  children,
  onChange,
  statuses,
  types,
  users,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterState, setFilterState] = React.useState<FilterState>({
    statuses: {
      name: 'statuses',
      label: 'Status',
      values: [
        { value: 'active', label: 'Active' },
        { value: 'archived', label: 'Archived' },
      ],
      selected: false,
    },
    types: {
      name: 'types',
      label: 'Type',
      values: [
        { value: 'program', label: 'Program' },
        { value: 'user', label: 'User' },
        { value: 'team', label: 'Team' },
      ],
      selected: false,
    },
    users: {
      name: 'users',
      label: 'Created by',
      selected: false,
    },
  });

  React.useEffect(() => {
    let newFilterState = { ...filterState };
    if (statuses && statuses.length > 0 && !filterState.statuses.selected) {
      newFilterState = {
        ...newFilterState,
        statuses: { ...filterState.statuses, selected: true },
      };
    }

    if (types && types.length > 0 && !filterState.types.selected) {
      newFilterState = {
        ...newFilterState,
        types: { ...filterState.types, selected: true },
      };
    }

    if (users && users.length > 0 && !filterState.users.selected) {
      newFilterState = {
        ...newFilterState,
        users: { ...filterState.users, selected: true },
      };
    }

    setFilterState(newFilterState);
    // eslint-disable-next-line
  }, []);

  const unselectedFilters = Object.values(filterState).filter(
    (filter) => !filter.selected
  );

  const selectedFilters = Object.values(filterState).filter(
    (filter) => filter.selected
  );

  // eslint-disable-next-line consistent-return
  const getSelectedOptions = (filterName: string) => {
    if (filterName === 'statuses') {
      return filterState.statuses.values?.filter((opt) =>
        statuses?.includes(opt.value)
      );
    }

    if (filterName === 'types') {
      return filterState.types.values?.filter((opt) =>
        types?.includes(opt.value)
      );
    }
  };

  function onClose(filterName: string) {
    setFilterState({
      ...filterState,
      [filterName]: {
        ...filterState[filterName],
        selected: false,
      },
    });
    onChange(filterName, undefined);
  }

  const triggerClassNames = React.useMemo(
    () => ({
      customTrigger: styles.booleanTrigger,
      filterTrigger: filtersStyles.FilterTrigger,
      appliedTrigger: filtersStyles.FilterTriggerApplied,
      closeButton: filtersStyles.FilterTriggerCloseButton,
    }),
    []
  );

  return (
    <div className={styles.filtersWrapper}>
      <div>{children}</div>
      {unselectedFilters?.length > 0 && (
        <div className={styles.AllFilters}>
          <FiltersButton
            filters={unselectedFilters}
            onSelectFilter={(name) => {
              setFilterState({
                ...filterState,
                [name]: { ...filterState[name], selected: true },
              });
            }}
          />
        </div>
      )}
      {selectedFilters.map((filter) => {
        if (filter.name === 'users') {
          return (
            <UsersFilter
              onChange={(values) => onChange('users', values)}
              label="Created by"
              values={users}
              onClose={() => onClose(filter.name)}
            />
          );
        }
        if (filter.values && filter.values.length > 0) {
          return (
            <FilterDropdown
              key={filter.name}
              label={filter.label}
              onChange={(value) => onChange(filter.name, value)}
              options={filter.values}
              selectedOptions={getSelectedOptions(filter.name)}
              onClose={() => onClose(filter.name)}
            />
          );
        }

        return (
          <div
            key={filter.name}
            className={cx(
              triggerClassNames.filterTrigger,
              triggerClassNames.appliedTrigger,
              triggerClassNames.customTrigger
            )}
          >
            <div>{filter.label}</div>
            <button
              className={triggerClassNames.closeButton}
              onClick={() => {
                setFilterState({
                  ...filterState,
                  [filter.name]: {
                    ...filterState[filter.name],
                    selected: false,
                  },
                });
              }}
              type="button"
            >
              <Close />
            </button>
          </div>
        );
      })}
    </div>
  );
};
