import { DateTime } from 'luxon';
import React, { useState } from 'react';
import cx from 'classnames';
import { ClickDropdown } from 'shared/ClickDropdown';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { IconMenu, ItemType } from 'shared/IconMenu';
import { usePermissions } from 'contexts/permissions';
import { Audience, editableTextToQueryType } from 'models/audience';
import styles from './AudienceBuilder.module.css';

export const MoreButtonMenu: React.FC<{
  onArchive: () => void;
  onUnarchive: () => void;
  onCreateSnapshot: () => void;
  onAdvancedQueryBuilder: () => void;
  onOpenSaveAsModal: () => void;
  setAudience: (audience: Audience) => void;
  editableTextToQuery: editableTextToQueryType;
  audience: Audience;
  advancedBuilder: boolean;
  archived?: boolean;
  updatedAt?: Date;
  updatedBy?: string;
  canEdit: boolean;
}> = ({
  onArchive,
  onUnarchive,
  onCreateSnapshot,
  onAdvancedQueryBuilder,
  onOpenSaveAsModal,
  advancedBuilder,
  archived,
  updatedAt,
  updatedBy,
  setAudience,
  editableTextToQuery,
  audience,
  canEdit,
}) => {
  const {
    permissions: { manageAudienceAccess },
  } = usePermissions();
  const [isOpen, setIsOpen] = useState(false);

  const [includeDeactivatedUsers, setIncludeDeactivatedUsers] = useState(
    audience.includeDeactivatedUsers
  );

  const handleChangeIncludeDeactivatedUsers = () => {
    const { query } = audience;
    const toggledIncludeDeactivatedUsers = !includeDeactivatedUsers;
    const updatedQuery = `${editableTextToQuery(
      query || '',
      toggledIncludeDeactivatedUsers
    )}`;
    setIncludeDeactivatedUsers(toggledIncludeDeactivatedUsers);
    setAudience({
      ...audience,
      includeDeactivatedUsers: toggledIncludeDeactivatedUsers,
      query: updatedQuery,
    });
  };

  const normalMenuItems: Array<ItemType> = [
    {
      title: `${advancedBuilder ? 'Basic' : 'Advanced'} query builder`,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onAdvancedQueryBuilder();
      },
    },
  ];

  const dangerMenuItems: Array<ItemType> = [];

  if (manageAudienceAccess) {
    normalMenuItems.push(
      {
        title: 'Create a snapshot',
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          onCreateSnapshot();
        },
      },
      {
        title: 'Save as...',
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          onOpenSaveAsModal();
        },
      }
    );

    const shouldDisableForScim = !archived && audience.type === 'scim';
    dangerMenuItems.push({
      title: archived ? 'Unarchive' : 'Archive',
      disabled: shouldDisableForScim,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (archived) onUnarchive();
        else onArchive();
      },
      hoverTooltip: shouldDisableForScim
        ? 'ID Provider generated audiences cannot be archived'
        : undefined,
    });
  }

  const lastUpdateSection = (
    <p>
      <div>Last updated</div>
      <div>
        {updatedAt &&
          DateTime.fromJSDate(new Date(updatedAt)).toFormat('MMM dd, yyyy')}
        {updatedBy && (
          <>
            {' '}
            by <strong>{updatedBy}</strong>
          </>
        )}
      </div>
    </p>
  );

  const includeDeactivatedUsersSection = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      onClick={() => canEdit && handleChangeIncludeDeactivatedUsers()}
      className={cx(styles.toggleDeactivatedUsers, {
        [styles.disabledToggleDeactivatedUsers]: !canEdit,
      })}
    >
      <span>Include deactivated users</span>
      <input type="checkbox" defaultChecked={includeDeactivatedUsers} />
    </div>
  );

  const DropdownComponent = () => (
    <div className={styles.moreButtonMenu}>
      <IconMenu
        menuItems={normalMenuItems}
        className={styles.normalMenuItems}
      />
      {includeDeactivatedUsersSection}
      <IconMenu
        menuItems={dangerMenuItems}
        className={styles.dangerMenuItems}
      />
      {(updatedAt || updatedBy) && lastUpdateSection}
    </div>
  );

  const dropdownRenderProp = () => <DropdownComponent />;

  return (
    <ClickDropdown
      dropdownRenderProp={dropdownRenderProp}
      onOpen={() => setIsOpen(true)}
      onDropdownClick={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <button type="button" className={styles.btnMore}>
        More <SVGIcon name="DownChevron" />
      </button>
    </ClickDropdown>
  );
};
