import React from 'react';
import { Box } from '../../../../../../DesignSystem/Components';
import { Body, FieldLabel } from '../../../../../../DesignSystem/Typography';

export const Setting: React.VFC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <Box margin={[16, 0, 0, 32]}>
      <FieldLabel>{label}</FieldLabel>
      <Box>
        <Body>{value}</Body>
      </Box>
    </Box>
  );
};
