import * as React from 'react';
import { ImageData } from 'models/image';
import { ImagePicker, itemToImage } from 'shared/Library/ImagePicker';

export const Library: React.FC<{
  maxSelections: number;
  onClose(): void;
  onChange(data: ImageData[]): void;
}> = ({ maxSelections, onClose, onChange }) => (
  <ImagePicker
    useModal
    maxSelections={maxSelections}
    onChange={(images) => {
      onChange(images.map(itemToImage));
      onClose();
    }}
    onCancel={onClose}
  />
);
