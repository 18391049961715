import React from 'react';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { Option, Values } from 'models/insight/json/filterJson';
import { ClearSelectionButton } from 'App/Program/Main/Insight/components/Filters/shared/header/ClearSelectionButton';
import { FilterDropdownBody } from 'App/Program/Main/Insight/components/Filters/shared/body/FilterDropdownBody';
import { FilterDropdownFooter } from 'App/Program/Main/Insight/components/Filters/shared/footer/FilterDropdownFooter';
import { FilterDropdownContainer } from 'App/Program/Main/Insight/components/Filters/shared/FilterDropdownContainer';
import { DoneButton } from 'App/Program/Main/Insight/components/Filters/shared/footer/DoneButton';
import { SelectableListItem } from 'App/Program/Main/Insight/components/Filters/shared/body/SelectableListItem';
import { ListItemSubheader } from 'App/Program/Main/Insight/components/Filters/shared/body/ListItemSubheader';
import { InputNumber } from 'App/Program/Main/Insight/components/Filters/shared/body/InputNumber';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { useDefaultValuesOrDeepLinkedParams } from 'App/Program/Main/Insight/contexts/useDefaultValuesOrDeepLinkedParams';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';
import filterStyles from 'App/Program/Main/Insight/components/Filters/Filters.module.css';
import { FilterState } from '../../../contexts/filtersStateReducer';

type ViewByGroupedOptionsDataType = { [label: string]: Option[] };

/**
 * Note that view by dropdowns might have a "max segments" number subfilter
 * Currently we handle this explicitly as an optional extension of this component
 * Rather than as a "general" subfilter implementation
 * */
export const ViewByDropdown: React.FC = () => {
  const {
    filter,
    defaultValue,
    setPillButtonDescriptionWithDefault,
  } = React.useContext(FilterDropdownContext);
  const { filtersStateAction, updatedAttributeFilters } = React.useContext(
    FiltersStateContext
  );
  const { setShowReset } = React.useContext(ReportContext);

  const attributesWithValues: Values = React.useMemo(
    () =>
      updatedAttributeFilters?.map(
        (f: FilterState) => f.filter.internalId || ''
      ) || [],
    [updatedAttributeFilters]
  );
  // if a default option exists, find the option from the filter
  const defaultOption: Option | undefined = React.useMemo(() => {
    // there should only be one value returned in this
    // find the one that matches the `option.value` in the filter
    // if filter is required and no default probvided, choose the first option
    if (defaultValue === undefined) {
      if (filter.initialRequired && filter.values) return filter.values[0];
      return undefined;
    }
    return filter.values?.find((f) => defaultValue === f.value);
  }, [defaultValue, filter]);

  const [selectedValue, setSelectedValue] = React.useState<
    Option | undefined
  >();

  React.useEffect(() => setSelectedValue(defaultOption), [defaultOption]);

  React.useEffect(() => {
    setPillButtonDescriptionWithDefault(selectedValue?.label);
  }, [setPillButtonDescriptionWithDefault, selectedValue]);

  React.useEffect(() => {
    filtersStateAction({
      action: 'setSingleFilterValue',
      filter,
      value: selectedValue?.value,
    });
  }, [selectedValue, filter, filtersStateAction]);

  // transform the data from the filter prior to rendering
  const viewByOptionsMap: Map<string, Option> = React.useMemo(() => {
    const newMap: Map<string, Option> = new Map();

    filter.values?.forEach((option) => {
      newMap.set(option.value, option);
    });

    return newMap;
  }, [filter.values]);

  const viewByGroupedOptionsData: ViewByGroupedOptionsDataType = React.useMemo(() => {
    const newData = {} as ViewByGroupedOptionsDataType;
    const tempGroupedValues = filter.groupedValues || {};

    const processValueKey = (groupName: string, valueKey: string) => {
      const option = viewByOptionsMap.get(valueKey);
      if (
        !option ||
        (groupName === 'User Attributes' &&
          !attributesWithValues.includes(valueKey))
      )
        return;
      newData[groupName].push(option);
    };

    Object.keys(tempGroupedValues).forEach((groupName) => {
      newData[groupName] = [];
      tempGroupedValues[groupName].forEach((valueKey) => {
        processValueKey(groupName, valueKey);
      });
    });

    if (newData['User Attributes'] && newData['User Attributes'].length === 0) {
      delete newData['User Attributes'];
    }
    // sort alphabetically
    if (newData['User Attributes']) {
      newData['User Attributes'] = newData['User Attributes'].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    }

    return newData;
  }, [viewByOptionsMap, filter.groupedValues, attributesWithValues]);

  const viewBySectionHeaders = React.useMemo(() => {
    const keys = Object.keys(viewByGroupedOptionsData);
    return keys;
  }, [viewByGroupedOptionsData]);

  // a subfilter may exist for view by menus
  // below are the state variable and effects for updating this subfilter
  const { subFilter } = filter;
  const { deepLinkedParams } = React.useContext(ReportContext);
  const {
    defaultValue: subFilterDefaultValue,
  } = useDefaultValuesOrDeepLinkedParams(deepLinkedParams, subFilter);
  const [subFilterValue, setSubFilterValue] = React.useState<
    string | undefined
  >();

  // set the default subFilter value if any
  React.useEffect(() => setSubFilterValue(subFilterDefaultValue), [
    subFilterDefaultValue,
    setSubFilterValue,
  ]);

  // update the subFilter within the filter state map independently of the view by filter state
  React.useEffect(() => {
    if (!subFilter) return;
    filtersStateAction({
      action: 'setSingleFilterValue',
      filter: subFilter,
      value: subFilterValue,
    });
  }, [subFilterValue, subFilter, filtersStateAction]);

  return (
    <FilterDropdownContainer>
      {viewBySectionHeaders.length === 0 ? (
        <FilterDropdownBody>
          <div className={filterStyles.filterDropdownEmptyBody}>
            None Available
          </div>
        </FilterDropdownBody>
      ) : (
        <>
          <FilterDropdownBody isScrollable>
            {viewBySectionHeaders.map((sectionHeader, sectionIndex) => {
              const options = viewByGroupedOptionsData[sectionHeader];
              /* eslint-disable react/no-array-index-key */
              return (
                <React.Fragment key={`${sectionHeader}${sectionIndex}`}>
                  <ListItemSubheader
                    label={sectionHeader}
                    key={`${sectionHeader}${sectionIndex}`}
                  />
                  {options.map((option, optionIndex) => {
                    return (
                      <SelectableListItem
                        key={`${option.value}${option.label}${sectionIndex}${optionIndex}`}
                        onClick={() => {
                          setShowReset(true);
                          setSelectedValue(option);
                        }}
                        rowType="radio"
                        isSelected={selectedValue?.value === option.value}
                        label={option.label}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
            {/* Manually add the "Max Segments" at the end for all view by dropdowns */}
            {subFilter === undefined ? null : (
              <>
                <ListItemSubheader label="Max Segments" key="max segments" />
                <InputNumber
                  onChange={(value) => setSubFilterValue(String(value))}
                  value={
                    subFilterValue === undefined ? 0 : Number(subFilterValue)
                  }
                />
              </>
            )}
          </FilterDropdownBody>
          <FilterDropdownFooter>
            <ClearSelectionButton
              disabled={!selectedValue}
              onClick={() => setSelectedValue(undefined)}
            />
            <DoneButton />
          </FilterDropdownFooter>
        </>
      )}
    </FilterDropdownContainer>
  );
};
