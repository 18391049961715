import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { KeepOpen } from '../../shared/KeepOpen';

export const ExitBlocker: React.FC = () => (
  <KeepOpen keepOpenLabel="Continue Editing" confirmCloseLabel="Close Anyway">
    <Type.Heading block>The poll has errors</Type.Heading>
    <Type.Body block>
      You must fix your errors before saving your poll.
    </Type.Body>
  </KeepOpen>
);
