import { AliasData } from 'services/api-author-alias';
import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';

import {
  useArchiveAuthorAlias,
  useUnarchiveAuthorAlias,
} from 'hooks/author-alias';

export const useActions = (
  setAuthorAlias?: (alias: AliasData) => void
): {
  archive: (aliasId: number) => void;
  unarchive: (aliasId: number) => void;
} => {
  const flashServerErrors = useFlashServerErrors();
  const { setFlashMessage } = useFlashMessage();

  const { archive } = useArchiveAuthorAlias({
    onSuccess: (data) => {
      if (setAuthorAlias) setAuthorAlias(data);
      setFlashMessage({
        severity: 'info',
        message: 'Author alias archived successfully',
      });
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not archive author alias');
    },
  });

  const { unarchive } = useUnarchiveAuthorAlias({
    onSuccess: (data) => {
      if (setAuthorAlias) setAuthorAlias(data);
      setFlashMessage({
        severity: 'info',
        message: 'Author alias unarchived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not unarchive author alias',
      }),
  });

  return { archive, unarchive };
};
