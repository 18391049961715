import * as React from 'react';
import { PaginationState } from 'hooks/common';
import { throttle } from 'utility/throttle';

// If the sidebar does not start at the top of
// the page, set the offset here:
const SIDEBAR_TOP_OFFSET = 0;

export function useSidebarPositioning(
  /* eslint-disable no-param-reassign */
  paging: React.MutableRefObject<PaginationState>,
  wrapperElement: React.RefObject<HTMLDivElement>,
  offset = SIDEBAR_TOP_OFFSET
): {
  contentElement: React.RefObject<HTMLDivElement>;
  sidebarElement: React.RefObject<HTMLDivElement>;
  sidebarSize: React.MutableRefObject<number>;
} {
  const contentElement = React.useRef<HTMLDivElement>(null);
  const sidebarElement = React.useRef<HTMLDivElement>(null);
  const sidebarSize = React.useRef<number>(0);
  const { current: content } = contentElement;
  const { current: sidebar } = sidebarElement;
  const { current: wrapper } = wrapperElement;
  const { current: size } = sidebarSize;
  React.useEffect(() => {
    let scrollTop = 0;

    const onScroll = throttle(() => {
      if (wrapper && sidebar) {
        const availableHeight = wrapper.getBoundingClientRect().height;
        const neededHeight = offset + sidebar.getBoundingClientRect().height;
        if (availableHeight < neededHeight) {
          const currTop = parseInt(sidebar.style.top, 10) || SIDEBAR_TOP_OFFSET;
          const scrollDiff = wrapper.scrollTop - scrollTop;
          let nextTop = currTop - scrollDiff;
          nextTop = Math.max(
            nextTop,
            availableHeight + SIDEBAR_TOP_OFFSET - neededHeight
          );
          nextTop = Math.min(nextTop, SIDEBAR_TOP_OFFSET);
          sidebar.style.top = `${nextTop}px`;
        } else {
          sidebar.style.top = `${SIDEBAR_TOP_OFFSET}px`;
        }
        scrollTop = wrapper.scrollTop;
      }
    });
    if (wrapper) wrapper.addEventListener('scroll', onScroll);

    return () => {
      if (wrapper) wrapper.removeEventListener('scroll', onScroll);
    };
  }, [content, wrapper, paging, sidebar, offset]);

  React.useEffect(() => {
    // The css pointer-events on the sidebar are such that it allows the
    // scrolling events to pass through to the main content. This is the
    // happy-path, since it normally has more content, ie: taller height.
    // Without this, the main content area seems frozen if the user tries
    // to scroll from within the sidebar. This fails when the sidebar
    // height causes overflow, but the main content area does not. The
    // easiest way to replicate this is to make your window short, and
    // search for "asdfasdf". When this happens, the user cannot get to
    // the bottom of the sidebar. This is the fix...
    if (sidebar && content) {
      content.style.minHeight = `${sidebar.clientHeight}px`;
    }
    // the `size` is included as a signal to recalc the height
  }, [content, sidebar, size]);
  return {
    contentElement,
    sidebarElement,
    sidebarSize,
  };
}
