import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { DismissType } from 'DesignSystem/Components/ClickDropdown';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Pills } from 'DesignSystem/Components/Pills';
import { Button } from 'DesignSystem/Form';
import { Plus } from 'shared/icons';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { searchMetaTagSort, SelectSearchMetaTag } from './SelectSearchMetaTag';
import { ContentTitle, SettingsCard } from '../../SettingsCard';
import { InfoTooltip } from '../../Shared/InfoTooltip';

interface SearchMetaTagsSectionProps {
  disabled?: boolean;
}

export const SearchMetaTagsSection: React.FC<SearchMetaTagsSectionProps> = ({
  disabled,
}) => {
  const { id: programId } = useProgram();
  const searchMetaTagsEnabled = !!useFeatureFlagsQuery(
    programId,
    'EE.SearchImprovements.Enabled'
  ).data?.value;
  const { settings } = useSettings();
  const [editing, setEditing] = useState(false);

  const selectSearchMetaTag = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => (
    <SelectSearchMetaTag
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
    />
  );

  return (
    <>
      {searchMetaTagsEnabled && (
        <SettingsCard
          dataTest="search-meta-tags-settings"
          isEditing={false}
          editRenderProp={selectSearchMetaTag}
          disabled={disabled}
          onEditingToggle={(value: boolean) => {
            setEditing(value);
          }}
          showEditButton={
            settings.searchMetaTags && settings.searchMetaTags.length > 0
          }
        >
          {(toggleEditing: () => void) => (
            <>
              <Box margin={[0, 0, 0, 0]}>
                <ContentTitle>
                  Search Keywords
                  <InfoTooltip message="Add search keywords to improve campaign search accuracy." />
                </ContentTitle>
              </Box>
              <Box>
                <Pills
                  values={
                    settings.searchMetaTags
                      ? settings.searchMetaTags.sort(searchMetaTagSort)
                      : []
                  }
                  render={(searchMetaTag) => searchMetaTag.name}
                />

                {(!settings.searchMetaTags ||
                  settings.searchMetaTags.length === 0) &&
                  !editing && (
                    <Box>
                      <Button
                        disabled={disabled}
                        compact
                        onClick={toggleEditing}
                        label={
                          <>
                            <Plus />
                            <Box padding={[0, 12]}>Add search keywords</Box>
                          </>
                        }
                        secondary
                      />
                    </Box>
                  )}
              </Box>
            </>
          )}
        </SettingsCard>
      )}
    </>
  );
};
