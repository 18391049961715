import * as React from 'react';
import { useValidator } from 'hooks/content-blocks';
import {
  DefinitionBlock,
  FieldData,
  FieldDefinition,
  FieldType,
  RenderError,
} from 'models/publisher/block';
import { TabType } from '../panels/ContentDesignTabs';

export type EditorProps = {
  block: DefinitionBlock;
  blockId: string;
  field: FieldDefinition;
  onChangeData: (blockId: string, data: FieldData) => void;
  hideBlockEditor: () => void;
  defaultTab?: TabType;
};

export type UpdateOpts = {
  shouldValidate: boolean;
};

export function useEditor(
  props: EditorProps
): {
  apply: () => void;
  block: DefinitionBlock;
  cancel: () => void;
  errors: RenderError[];
  title: string;
  update: (field: FieldDefinition, data: FieldType, opts?: UpdateOpts) => void;
  valid: boolean;
  isChanged: boolean;
  working?: FieldData;
  isValidating?: boolean;
  isDirtyForValidation?: boolean;
  saveDisabled: boolean;
  enableSave: () => void;
  disableSave: () => void;
} {
  const { block, blockId, onChangeData, hideBlockEditor } = props;
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const [shouldValidate, setShouldValidate] = React.useState(true);
  const [isDirtyForValidation, setIsDirtyForValidation] = React.useState(false);
  const disableSave = React.useCallback(() => setSaveDisabled(true), []);
  const enableSave = React.useCallback(() => setSaveDisabled(false), []);
  const mergedData = React.useMemo(() => {
    const withDefaults: FieldData = {};
    block.fields.forEach((f) => {
      const data = block.field_data;
      if (data && data[f.name]) {
        withDefaults[f.name] = data[f.name];
      } else {
        withDefaults[f.name] = data[f.name]; // pull from default_data
      }
    });
    return withDefaults;
  }, [block.field_data, block.fields]);

  const [working, setWorking] = React.useState<FieldData>(mergedData);
  // "working" state is often stale in apply() callback
  const workingRef = React.useRef<FieldData>();

  const isChanged = React.useMemo(
    () => JSON.stringify(working) !== JSON.stringify(mergedData),
    [mergedData, working]
  );
  const validatable = React.useMemo(
    () => ({
      ...block,
      field_data: {
        ...block.field_data,
        ...working,
      },
    }),
    [block, working]
  );
  const { valid, errors, isLoading: isValidating } = useValidator({
    block: validatable,
    enabled: shouldValidate,
    onSettled: () => {
      setIsDirtyForValidation(false);
    },
  });

  const apply = React.useCallback(() => {
    if (valid) {
      onChangeData(blockId, workingRef.current || working);
      hideBlockEditor();
    }
  }, [valid, hideBlockEditor, onChangeData, working, blockId]);

  const cancel = React.useCallback(() => {
    // TODO: check for changes to `working`, and warn on pending changes
    hideBlockEditor();
  }, [hideBlockEditor]);

  const update = React.useCallback(
    (field: FieldDefinition, fieldData: FieldType, opts?: UpdateOpts) => {
      const shouldValidateOption = opts?.shouldValidate ?? true;
      setShouldValidate(shouldValidateOption);
      if (shouldValidateOption) {
        setIsDirtyForValidation(true);
      }
      const newWorking = {
        ...working,
        [`${field.name}`]: fieldData,
      };
      workingRef.current = newWorking;
      setWorking(newWorking);
    },
    [working]
  );

  const title = `Edit`;

  return {
    apply,
    block,
    cancel,
    errors,
    title,
    update,
    valid: valid && !isValidating && !isDirtyForValidation,
    isValidating,
    isDirtyForValidation,
    isChanged,
    working,
    saveDisabled,
    disableSave,
    enableSave,
  };
}
