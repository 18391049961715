import React from 'react';
import { ExternalSource } from 'models/external-source';
import { useProgram } from 'contexts/program';
import { NavigationFooter } from 'shared/NavigationFooter';

export const Footer: React.FC<{
  externalSource: Partial<ExternalSource>;
  action: () => void;
}> = ({ externalSource, action }) => {
  const { id: programId } = useProgram();

  const exitPath = `/${programId}/configure/feeds`;

  return (
    <NavigationFooter
      canPerformAction
      actionName="Save"
      action={action}
      exitPath={exitPath}
      title={externalSource?.identifier}
      hideMenu
    />
  );
};
