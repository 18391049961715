import * as React from 'react';
import { useInputHighlighter } from 'hooks/inclusive-language/useInputHighlighter';
import { ExpandableTextarea } from 'shared/forms/Textarea/Expandable';
import { useProgram } from 'contexts/program';
import cx from 'classnames';
import { usePublisher } from 'contexts/publisher';
import { useAIContentCover } from 'hooks/content-cover-generation';
import { InfoI } from 'shared/icons';
import styles from './sidebar.module.css';
import { AIControls } from './AIControls';
import { SidebarInputError } from './SidebarInputError';

export const SummaryInput: React.FC<{
  summary: string;
  handleChange: (obj: { summary: string }) => void;
  disabled: boolean;
  errorMessage?: string;
  showAIGenerationButton?: boolean;
}> = ({
  summary,
  disabled,
  handleChange,
  errorMessage,
  showAIGenerationButton,
}) => {
  const { id: programId } = useProgram();
  const { post } = usePublisher();
  const [previousSummary, setPreviousSummary] = React.useState<string | null>(
    null
  );
  const backgroundHover = true;

  const changeSummary = React.useCallback(
    (value: string) => {
      handleChange({ summary: value });
    },
    [handleChange]
  );

  const onGenerationCompleted = React.useCallback(
    (value: string) => {
      handleChange({ summary: value || '' });
    },
    [handleChange]
  );

  const {
    status,
    generate,
    error: completionError,
    eligibleBlocks,
  } = useAIContentCover({
    onCompleted: onGenerationCompleted,
    command: 'summarize',
    blocks: post.blocks,
    post,
  });

  const generateSummary = React.useCallback(() => {
    if (summary && summary.trim() !== '') {
      setPreviousSummary(summary);
    }

    generate();
  }, [generate, summary]);

  const undo = React.useCallback(() => {
    if (previousSummary && previousSummary.trim() !== '') {
      handleChange({ summary: previousSummary });
    }

    // Reset previous summary
    setPreviousSummary(null);
  }, [handleChange, previousSummary]);

  const { inputRef, legendRef, onChange } = useInputHighlighter<
    HTMLTextAreaElement
  >(programId, summary, changeSummary);

  return (
    <fieldset>
      <legend ref={legendRef}>Description</legend>
      <ExpandableTextarea
        inputRef={inputRef}
        value={summary}
        maximum={500}
        disabled={disabled}
        className={cx('full-width text-input', {
          [styles.ctaInvalidInput]: errorMessage,
        })}
        onChange={onChange}
        dir="auto"
      />
      {showAIGenerationButton && (
        <AIControls
          status={status}
          trigger={generateSummary}
          cancel={undo}
          canUndo={!!previousSummary}
          sparkleFillColor="#F74857"
          backgroundHover={backgroundHover}
          disabled={eligibleBlocks.length === 0}
        />
      )}
      {(errorMessage || completionError) && (
        <SidebarInputError disabled={disabled} message={errorMessage} />
      )}
      {showAIGenerationButton && eligibleBlocks.length === 0 && (
        <div className={styles.ctaAiWarning}>
          <InfoI className={styles.infoSign} />
          Add a block with text content to enable AI generation
        </div>
      )}
    </fieldset>
  );
};
