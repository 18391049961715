import * as React from 'react';
import { InfiniteQueryResponse, PaginationState } from 'hooks/common';
import { ContentFilterFetchProps } from 'components/content/ContentFilterBar/ContentFilterBar';
import { ProgramIdProp } from 'services/api-insights';
import { FiltersStateType } from 'contexts/content/filters';
import { Content } from 'models/content';
import { Content as LibraryContent } from 'models/library';
import { UseCollection, useCollection } from './useCollection';

export function usePicker({
  onChange,
  useLibraryItems,
}: {
  onChange: (items: LibraryContent[]) => void;
  useLibraryItems: (
    fetchProps: ContentFilterFetchProps & ProgramIdProp,
    filters: FiltersStateType
  ) => InfiniteQueryResponse<Content>;
}): {
  collection: UseCollection;
  paging: React.MutableRefObject<PaginationState>;
  onApply(): void;
} {
  const collection = useCollection(useLibraryItems);
  const paging = React.useRef(collection.pagination);
  paging.current = { ...collection.pagination };
  const onApply = React.useCallback(
    () => onChange(collection.libraryContents),
    [collection, onChange]
  );
  return {
    collection,
    paging,
    onApply,
  };
}
