import React from 'react';
import { titleCase, pluralize } from 'utility/text';
import styles from 'shared/Library/PickerScreen/sidebar.module.css';
import { UseCollection } from './useCollection';

type PropsType = {
  selection: UseCollection['selection'];
  sidebarRef: React.RefObject<HTMLDivElement>;
  label: string;
  onApply: () => void;
  onCancel: () => void;
  divider?: 'line' | 'group';
};

export const Sidebar: React.FC<PropsType> = ({
  selection,
  sidebarRef,
  label,
  onApply,
  onCancel,
}) => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarFixed} ref={sidebarRef}>
        <div className={styles.label}>{titleCase(pluralize(label))}</div>
        <aside className={styles.actionsAside}>
          <button
            type="button"
            className={styles.applyButton}
            disabled={selection.length === 0}
            onClick={() => {
              onApply();
            }}
            data-test="apply-selection"
          >
            Use{' '}
            {selection.length > 1
              ? `${selection.length} ${titleCase(pluralize(2, label))}`
              : `${titleCase(label)}`}
          </button>
          <button
            className={styles.cancelButton}
            type="button"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        </aside>
      </div>
    </div>
  );
};
