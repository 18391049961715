import cx from 'classnames';
import * as React from 'react';
import { PaginationState } from 'hooks/common';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import buttonStyles from 'shared/styles/buttons.module.css';
import { pluralize } from 'utility/text';

export function Pagination(props: {
  label: string;
  pagination: PaginationState;
}): React.ReactElement {
  const { label, pagination } = props;
  if (!pagination.hasNextPage)
    return <em>No more {pluralize(label)} available.</em>;

  if (pagination.isFetchingNextPage)
    return (
      <>
        Loading more {pluralize(label)}...
        <br />
        <br />
        <LoadingSpinner />
      </>
    );
  return (
    <button
      type="button"
      className={cx(buttonStyles.pill, buttonStyles.button)}
      onClick={pagination.fetchNextPage}
    >
      load more {pluralize(label)}
    </button>
  );
}
