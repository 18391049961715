import { navigate, RouteComponentProps } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useTopicAboutPageQuery, useUpdateTopicAboutPage } from 'hooks/topics';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { NavigationFooter } from 'shared/NavigationFooter';
import { WithPermission } from 'shared/WithPermission';
import { useFlashServerErrors } from 'utility/errors';
import { TopicContext } from '../../../../TopicContext';
import styles from '../../../../topics.module.css';
import AboutEditor from './HTMLEditor/AboutEditor';

export const EditTopicAboutPage: React.FC<RouteComponentProps> = () => {
  const { topic } = useContext(TopicContext);

  const [page, setPage] = React.useState<string>();
  const [pagePreview, setPagePreview] = React.useState<string>();
  const [fontIds, setFontIds] = React.useState<number[]>([]);
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();

  const { data: pageData, isLoading, errorMessage } = useTopicAboutPageQuery(
    programId,
    topic?.id as string
  );

  const returnUrl = topic
    ? `/${programId}/configure/topics/${topic?.id}/edit`
    : undefined;

  const { update } = useUpdateTopicAboutPage(programId, String(topic?.id), {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Topic updated successfully',
      });
      if (returnUrl) navigate(returnUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update topic');
    },
  });

  const addFontId = (id: number) => {
    setFontIds((prevIds) => Array.from(new Set([...prevIds, id])));
  };

  const onPageChange = React.useCallback(
    (content: string, previewContent: string) => {
      setPage(content);
      setPagePreview(previewContent);
    },
    []
  );

  React.useEffect(() => {
    if (pageData) {
      onPageChange(pageData.pageContent, pageData.previewContent);
      setFontIds(pageData.fontIds);
    }
  }, [onPageChange, pageData]);

  const onSave = () => {
    if (page && pagePreview !== undefined) {
      update({ pageContent: page, previewContent: pagePreview, fontIds });
    } else {
      flashServerErrors(Error(errorMessage), 'Could not update topic');
    }
  };

  const header = <h1 className="page-header">About</h1>;

  const main = (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && topic && (
        <AboutEditor
          content={pageData?.pageContent}
          onChange={onPageChange}
          addFontId={addFontId}
        />
      )}
    </>
  );

  const footer = topic && (
    <NavigationFooter
      canPerformAction
      actionName="Save"
      action={onSave}
      exitPath={returnUrl}
      title={topic?.name}
      hideMenu
    />
  );

  return (
    <WithPermission permissions={['confContentAccess', 'topicsAccess']}>
      <Helmet>
        <title>Edit About</title>
      </Helmet>
      {topic && <MainEditor header={header} main={main} footer={footer} />}
    </WithPermission>
  );
};
