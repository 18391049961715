import * as React from 'react';
import * as Blocks from 'models/publisher/block';
import { useValidator } from 'hooks/content-blocks';
import { useDebounce } from 'hooks/useDebounce';

export function useFieldForm({
  block,
  blockId,
  onChangeData,
  field,
}: BlockFieldFormProps): {
  field: BlockFieldFormProps['field']['type'];
  data?: Blocks.FieldData[string];
  default_data?: Blocks.FieldData[string];
  errors: Blocks.RenderError[];
  onChange: (data: Blocks.FieldType, opts?: UpdateOpts) => void;
  valid: boolean;
  isChanged: boolean;
} {
  const initial = React.useState(block.field_data)[0];
  const [shouldValidate, setShouldValidate] = React.useState(true);
  const [isDirtyForValidation, setIsDirtyForValidation] = React.useState(false);
  const mergedData = React.useMemo(() => {
    const withDefaults: Blocks.FieldData = {};
    block.fields.forEach((f) => {
      const data = block.field_data;
      if (data && data[f.name]) {
        withDefaults[f.name] = data[f.name];
      } else {
        withDefaults[f.name] = data[f.name]; // pull from default_data
      }
    });
    return withDefaults;
  }, [block.field_data, block.fields]);

  const [working, setWorking] = React.useState<Blocks.FieldData>(mergedData);

  const isChanged = React.useMemo(
    () => JSON.stringify(working) !== JSON.stringify(mergedData),
    [mergedData, working]
  );
  const validatable = React.useMemo(
    () =>
      shouldValidate
        ? { ...block, field_data: { ...block.field_data, ...working } }
        : block, // it won't be used, risky pass 😅
    [shouldValidate, block, working]
  );
  const { valid: notInvalid, errors, isLoading: isValidating } = useValidator({
    block: validatable,
    enabled: shouldValidate,
    onSettled: () => {
      setIsDirtyForValidation(false);
    },
  });
  const valid = notInvalid && !isValidating && !isDirtyForValidation;

  const onChange = React.useCallback(
    (fieldData: Blocks.FieldType, opts?: UpdateOpts) => {
      const shouldValidateOption = opts?.shouldValidate ?? true;
      setShouldValidate(shouldValidateOption);
      if (shouldValidateOption) {
        setIsDirtyForValidation(true);
      }
      const newWorking = {
        ...working,
        [`${field.name}`]: fieldData,
      };
      setWorking(newWorking);
    },
    [working, field.name]
  );

  useDebouncedSave(blockId, working, onChangeData);

  return {
    errors,
    onChange,
    valid,
    isChanged,
    field: field.type,
    data: working[field.name],
    default_data: initial[field.name],
  };
}

function useDebouncedSave(
  blockId: string,
  working: Blocks.FieldData,
  onChangeData: (blockId: string, data: Blocks.FieldData) => void
) {
  // putting the `onChangeData` as a dep to the effect causes a render loop
  const onChangeRef = React.useRef(onChangeData);
  onChangeRef.current = onChangeData;
  const debouncedChanges = useDebounce(working);
  React.useEffect(() => {
    onChangeRef.current(blockId, debouncedChanges);
  }, [blockId, debouncedChanges]);
}

export type BlockFieldFormProps = {
  block: Blocks.DefinitionBlock;
  blockId: string;
  field: Blocks.FieldDefinition;
  onChangeData: (blockId: string, data: Blocks.FieldData) => void;
};

export type FieldFormProps<T extends Blocks.FieldType> = {
  // field: Blocks.FieldDefinition;
  data: T;
  default_data: T;
  onChange: (value: T, opts?: UpdateOpts) => void;
  valid?: boolean;
  errors?: ReturnType<typeof useFieldForm>['errors'];
};

export type UpdateOpts = {
  shouldValidate: boolean;
};
