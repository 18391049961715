import { MutationOptions, useMutation, useQuery } from 'react-query';
import {
  UploadContentImageProps,
  fetchContentImage,
  uploadContentImageFile,
  uploadContentImageUrl,
  UploadFile,
  UploadUrl,
  hostImageFile,
  hostImageUrl,
  HostImageFileProps,
  HostImageUrlProps,
  UploadDesignImageProps,
  uploadDesignImageFile,
} from 'services/api-assets';
import { MutationResponse, QueryError, QueryResponse } from './common';
import { Image } from '../models/image';

// creates a content association
export const useContentImageFileMutation = ({
  onSuccess,
  onError,
}: MutationOptions<
  Image,
  QueryError,
  UploadContentImageProps<UploadFile>
> = {}): MutationResponse<UploadContentImageProps<UploadFile>> => {
  const { mutate, isLoading, error } = useMutation<
    Image,
    QueryError,
    UploadContentImageProps<UploadFile>
  >(uploadContentImageFile, { onSuccess, onError });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export const useDesignImageFileMutation = ({
  onSuccess,
  onError,
}: MutationOptions<
  Image,
  QueryError,
  UploadDesignImageProps<UploadFile>
> = {}): MutationResponse<UploadDesignImageProps<UploadFile>> => {
  const { mutate, isLoading, error } = useMutation<
    Image,
    QueryError,
    UploadDesignImageProps<UploadFile>
  >(uploadDesignImageFile, { onSuccess, onError });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

// does not create a content association (templates, library, etc.)
export const useHostImageFileMutation = ({
  onSuccess,
  onError,
}: MutationOptions<
  Image,
  QueryError,
  HostImageFileProps
> = {}): MutationResponse<HostImageFileProps> => {
  const { mutate, isLoading, error } = useMutation<
    Image,
    QueryError,
    HostImageFileProps
  >(hostImageFile, { onSuccess, onError });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

// creates a content association
export const useContentImageUrlMutation = ({
  onSuccess,
  onError,
}: MutationOptions<
  Image,
  QueryError,
  UploadContentImageProps<UploadUrl>
> = {}): MutationResponse<UploadContentImageProps<UploadUrl>> => {
  const { mutate, isLoading, error } = useMutation<
    Image,
    QueryError,
    UploadContentImageProps<UploadUrl>
  >(uploadContentImageUrl, { onSuccess, onError });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

// does not create a content association (templates, library, etc.)
export const useHostImageUrlMutation = ({
  onSuccess,
  onError,
}: MutationOptions<
  Image,
  QueryError,
  HostImageUrlProps
> = {}): MutationResponse<HostImageUrlProps> => {
  const { mutate, isLoading, error } = useMutation<
    Image,
    QueryError,
    HostImageUrlProps
  >(hostImageUrl, { onSuccess, onError });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export const useContentImageQuery = (props: {
  programId: number;
  contentId: number;
  imageId?: string;
  enabled?: boolean;
  refetchInterval?: number;
}): QueryResponse<Image | undefined> => {
  const { programId, contentId, imageId, enabled, refetchInterval } = props;
  const { isLoading, error, data } = useQuery<Image | undefined, Error>(
    ['image', programId, imageId],
    () => fetchContentImage({ programId, imageId, contentId }),
    { enabled, refetchInterval, retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: error && data ? { ...data, status: 'errored' } : data,
  };
};
