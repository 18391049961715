import React from 'react';
import { FetchedCaption } from 'hooks/video';
import { languageDisplayName } from 'utility/language';

const languageNames = new Intl.DisplayNames(undefined, {
  type: 'language',
  languageDisplay: 'standard',
});

export const useCaptionPreview: (
  caption: FetchedCaption
) => {
  isDeleting: boolean;
  setDeleting: (isDeleting: boolean) => void;
  language: string;
  showDialog: (event: React.MouseEvent<Element, MouseEvent>) => void;
} = (caption) => {
  const [isDeleting, setDeleting] = React.useState(false);

  const showDialog = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleting(true);
  }, []);

  return {
    isDeleting,
    setDeleting,
    language: languageDisplayName(caption.language, languageNames),
    showDialog,
  };
};
