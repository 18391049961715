import * as React from 'react';

// It would be nice to test this, but I'm not sure how tbh.  Mocking the document and window is not
// trivial.  We could pull out that windowHeight calculation as a separate helper function and mock
// that, but then still stubbing all the `getBoundingClientRect` calls would be tedious.  This math
// does work.  It's been blackbox tested so much that we pretty much trust it.  At least having it
// pulled out as a custom hook allows us to mock it if needed.
export function useDropdownTopOffset(props: {
  isDropdownOpen?: boolean;
  dropdownRef: React.RefObject<HTMLElement>;
  toggleRef: React.RefObject<HTMLElement>;
}): number {
  const { isDropdownOpen, dropdownRef, toggleRef } = props;

  const [dropdownTopOffset, setDropdownTopOffset] = React.useState(0);

  React.useEffect(() => {
    if (isDropdownOpen) {
      if (!toggleRef.current || !dropdownRef.current) {
        return;
      }

      const rect = toggleRef.current.getBoundingClientRect();
      const dropdownTop = rect.y + rect.height;
      const dropdownBottom = dropdownTop + dropdownRef.current.offsetHeight;
      const windowHeight = Math.max(
        document.body.clientHeight,
        window.innerHeight
      );
      const marginTop = Math.min(windowHeight - dropdownBottom, 0);

      setDropdownTopOffset(marginTop);
    }
  }, [isDropdownOpen, dropdownRef, toggleRef]);

  return dropdownTopOffset;
}
