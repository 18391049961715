import React from 'react';
import { ImageFieldData } from 'models/publisher/block';
import { useGalleryDataMutator } from 'components/publisher/blocks/forms/fields/Gallery/hooks/useGalleryDataMutator';
import { FieldProps } from '../../FieldProps';
import { Collection } from './Collection';
import { Current } from './Current';
import styles from './multi-image.module.css';

export const MultiImage: React.FC<FieldProps<ImageFieldData[]>> = ({
  data,
  onChange,
  saveDisabled,
  enableSave,
  disableSave,
}) => {
  const { current, collection } = useGalleryDataMutator(data, onChange);
  return (
    <div className={styles.uploader}>
      {current.action ? (
        <Current
          action={current.action}
          image={current.item.data}
          source={current.item.source}
          onUpdate={current.item.update}
          onRemove={current.item.remove}
          onApply={current.done}
          onReset={current.cancel}
        />
      ) : (
        <Collection
          images={collection.images}
          onClearAll={collection.clear}
          onRemove={collection.remove}
          onSetCurrent={collection.setCurrent}
          onDrop={collection.append}
          onReorder={collection.reorder}
          onCreate={collection.create}
          replaceItems={collection.replaceItems}
          saveDisabled={saveDisabled as boolean}
          enableSave={enableSave as () => void}
          disableSave={disableSave as () => void}
        />
      )}
    </div>
  );
};
