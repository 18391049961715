import * as React from 'react';
import styles from './microapp.module.css';

export const MicroApp: React.FC = ({ children }) => {
  return (
    <div className={styles.microapp}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
