import React from 'react';
import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';
import { useGenerateErrorReport } from 'hooks/bulk-update-permissions';
import { download } from 'utility/download';
import { bulkUpdateToMenuItems, bulkUpdateToThumbnail } from './mappings';
import { generateCompletionString, generateStatString } from './shared/helper';
import styles from './bulk-updates.module.css';

export type PropsType = {
  data: BulkUpdatePermissionsJob;
};

const formatDate = (d: string | undefined) => {
  return d ? DateTime.fromJSDate(new Date(d)) : undefined;
};

export const BulkUpdateBanner: React.FC<PropsType> = ({ data }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const thumbnail = bulkUpdateToThumbnail(data.id.toString());
  const { description, processed } = generateStatString(data);
  const showCompletionTime =
    data.state === 'completed' || data.state === 'partial';
  const createdAt = formatDate(data.submittedDatetime)?.toFormat(
    'MMM dd, yyyy hh:mma'
  );
  const completionTime = generateCompletionString(data);

  const { download: downloadErrorReport } = useGenerateErrorReport(programId, {
    onSuccess: (downloadData: Blob) => {
      download(downloadData, 'error-report.csv');
    },
    onError: () => {
      setFlashMessage({
        message: 'Unable to download error file.',
        severity: 'error',
      });
    },
  });

  const menuItems = bulkUpdateToMenuItems(data, programId, downloadErrorReport);

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`user-import-${data.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{data.id}</div>
          <p className={styles.description} title={data.state}>
            {description}
          </p>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>TIME</span>
          <span className={styles.stats}>{createdAt || 'NA'}</span>
          <span className={styles.subStats}>
            {showCompletionTime && completionTime}
          </span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>USER</span>
          <span className={styles.stats}>
            {data.createdByData?.displayName || 'NA'}
          </span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>RECORDS PROCESSED</span>
          <span className={styles.stats}>{processed || 'NA'}</span>
        </div>
      </div>
    </EmptyBanner>
  );
};
