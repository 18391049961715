import React from 'react';
import { DateTime } from 'luxon';
import styles from './manual-date-input.module.css';

type Props = {
  onChange: (e: React.ChangeEvent) => void;
  value: DateTime;
  onBlur: () => void;
};

export const ManualDateInput: React.FC<Props> = ({
  onBlur,
  onChange,
  value,
}) => {
  React.useEffect(() => {
    const container = document.querySelector(`div.${styles.dateInput}`);
    if (container && container.lastChild) {
      (container.lastChild as HTMLInputElement).focus();
    }
  }, []);

  const [dateValue] = (value.toISO() || '').split('T');

  return (
    <div className={styles.dateInput}>
      <input className={styles.disabled} type="date" value="" disabled />
      <input
        type="date"
        value={dateValue}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
