import type { Source } from 'components/publisher/blocks/forms/fields/shared/SourceMenu';

export type ImageData = {
  altText?: string;
  url: string;
  imageId?: string;
  processed: boolean;
  isPlaceholder?: boolean;
  originalFilename?: string;
  href?: string;
  openInNewTab?: boolean;
  source?: Source;
};

export type ImageFile = {
  name: string;
  size: number;
  type?: string;
  lastModified?: number;
  webkitRelativePath?: string;
};

export function emptyImageData(): ImageData {
  return { url: '', altText: '', processed: false };
}

export type Image = {
  url: string;
  status: 'errored' | 'completed' | 'processing';
  source?: Source;
  id?: string;
};

export function isImageData(data: unknown): data is ImageData {
  const img = data as ImageData;
  return img.url !== undefined && img.processed !== undefined;
}

export function isImageDataArray(data: unknown): data is ImageData[] {
  return Array.isArray(data) && !data.some((i) => !isImageData(i));
}

export function isImageTypeAllowed(imageFile: ImageFile): boolean {
  const { type, name } = imageFile;

  // Validates the presence of the MIME type, if it is present then validates if it is an allowed MIME type.
  if (type) {
    return !IMAGE_UPLOAD_RULES.ALLOWED_MIME_TYPES.includes(type);
  }

  // As fallback we might do a file extension validation since not all browsers supports all MIME types allowed.
  const fileExtension = name ? name.split('.').pop() || '' : '';

  return !IMAGE_UPLOAD_RULES.ALLOWED_EXTENSION_TYPES.includes(fileExtension);
}

export const MAX_GALLERY_SIZE = 10;

export const IMAGE_UPLOAD_RULES = {
  MAX_FILE_SIZE: 10 * 1024 * 1024,
  MAX_ALT_SIZE: 125,
  ALLOWED_MIME_TYPES: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/x-ms-bmp',
    'image/webp',
    'image/x-tiff',
    'image/tiff',
    'image/x-icon',
    'image/vnd.microsoft.icon',
    'image/heic',
    'image/heif',
  ],
  ALLOWED_EXTENSION_TYPES: [
    'jpg',
    'jpeg',
    'jpe',
    'jif',
    'jfif',
    'png',
    'gif',
    'bmp',
    'webp',
    'tif',
    'tiff',
    'ico',
    'cur',
    'heic',
    'heif',
  ],
};

export const IMAGE_UPLOAD_ERRORS = {
  MAX_SIZE: 'Image must be smaller than 10MB. Please upload a smaller image.',
  MAX_ALT_SIZE: 'Alt text should be less or equal to 125 characters',
  ALLOWED_EXTENSION_TYPE: 'Image has a not supported format.',
  CANNOT_UPLOAD: 'Cannot upload image. Service is not available',
};

const generateAcceptString = (mimeTypes: string[], extensions: string[]) => {
  const dotExtensions = extensions.map((ext) => `.${ext}`);
  return [...mimeTypes, ...dotExtensions].join(',');
};

export const IMAGE_ACCEPT_STRING = generateAcceptString(
  IMAGE_UPLOAD_RULES.ALLOWED_MIME_TYPES,
  IMAGE_UPLOAD_RULES.ALLOWED_EXTENSION_TYPES
);
