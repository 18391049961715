import * as React from 'react';
import { useQuery } from 'react-query';
import { Video as VideoModel } from 'models/video';
import { VideoFieldData } from 'models/publisher/block';
import { useProgram } from 'contexts/program';
import { fetchVideoForFeed } from 'services/api-assets';
import { UPLOAD } from 'models/source';
import { VideoUploader } from './Uploader';
import { FieldProps } from '../FieldProps';
import { isVideoSource } from './useVideoSourceMenu';

export const Video: React.FC<FieldProps<VideoFieldData>> = ({
  onChange,
  data: fieldData,
  disableSave,
  enableSave,
}) => {
  const { id: programId } = useProgram();

  const { data: video, refetch: refetchVideo } = useQuery(
    ['video_feed', programId, fieldData.video_id],
    () => fetchVideoForFeed({ programId, videoId: fieldData.video_id }),
    { enabled: !!fieldData.video_id, refetchOnWindowFocus: false }
  );

  const updateVideo = React.useCallback(
    (newVideo: VideoModel) => {
      onChange({
        type: 'video',
        video_id: newVideo.id,
        video_length_sec: newVideo.durationInSeconds,
        video_uuid: newVideo.uuid,
        custom_preview_image_url: newVideo.customPreviewImageUrl,
        preview_image_url: newVideo.previewImageUrl ?? '',
        image_alt_text: newVideo.altText,
        processed: true,
        sourceType: isVideoSource(newVideo.sourceType)
          ? newVideo.sourceType
          : undefined,
        captions_color: newVideo.captionsColor,
        captions_color_background: newVideo.captionsColorBackground,
        captions_font_size: newVideo.captionsFontSize,
        captions_position: newVideo.captionsPosition,
      });
      if (newVideo.id !== video?.id || newVideo.status !== video?.status) {
        refetchVideo();
      }
    },
    [onChange, refetchVideo, video?.id, video?.status]
  );

  React.useEffect(() => {
    if (video) {
      video.customPreviewImageUrl = fieldData.custom_preview_image_url;
      video.previewImageUrl = fieldData.preview_image_url;
      video.altText = fieldData.image_alt_text;
    }
  }, [
    video,
    fieldData.custom_preview_image_url,
    fieldData.preview_image_url,
    fieldData.image_alt_text,
  ]);

  return (
    <VideoUploader
      video={video}
      onChange={updateVideo}
      source={
        isVideoSource(fieldData.sourceType) ? fieldData.sourceType : UPLOAD
      }
      disableSave={disableSave}
      enableSave={enableSave}
    />
  );
};
