import React from 'react';
import { BossViewPage } from 'shared/BossView/Page';
import { WithPermission } from 'shared/WithPermission';

export const ShortcutsPageBossWrapper: React.FC<{ title: string }> = ({
  title,
}) => {
  return (
    <WithPermission permissions={['configureExperienceSettingsAccess']}>
      <BossViewPage
        title={title}
        src="#user-experience"
        app="programs"
        returnUrls={['/configure/shortcuts']}
      />
    </WithPermission>
  );
};
