import React from 'react';
import cx from 'classnames';
import { CampaignResultsData } from 'services/api-insights';
import styles from '../MetricsCards.module.css';
import { ProgressDonut } from '../../../../../../../shared/Charts/ProgressDonut';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IEmailErrorRateCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const chartDimensions = {
  height: 156,
  width: 225,
  padding: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  radiusRatio: 0.8,
  innerFontSize: 20,
};

export const EmailErrorRateCard: React.FC<IEmailErrorRateCard> = ({
  data,
  isLoading,
}) => {
  const delivered = data?.campaign.stats.delivered || 0;
  const errors = data?.campaign.stats.errors || 0;
  const total = delivered + errors;
  const ratio = delivered !== 0 ? errors / total : 0;

  return (
    <div className={cx(styles.metricCard, styles.metricCardQuarter)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>Email Error Rate</h1>
      </div>
      {isLoading ? (
        <>
          <div className={styles.metricCardBody}>
            <LoadingChart chartDimensions={chartDimensions} />
          </div>
          <div className={styles.metricCardBody}>
            <p className={cx(styles.centeredText, styles.metricCardSubtext)} />
            <p className={cx(styles.centeredText, styles.metricCardSubtext)} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.metricCardBody}>
            <ProgressDonut
              percentage={ratio}
              chartDimensions={chartDimensions}
            />
          </div>
          <div className={styles.metricCardBody}>
            <p className={cx(styles.centeredText, styles.metricCardSubtext)}>
              <span className={styles.metricsGreenText}>{errors}</span> out of{' '}
              {total} employees
            </p>
          </div>
        </>
      )}
    </div>
  );
};
