import * as React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Label } from 'shared/Label';
import { Source, SourceType } from './Source';
import styles from './channel.module.css';

export const Channel: React.FC<{
  channels: Array<SourceType>;
  unreachable: number;
}> = ({ channels, unreachable }) => (
  <Section title="Channel">
    <Row>
      {channels.map((channel) => (
        <Source
          key={channel.name}
          name={channel.name}
          count={channel.count}
          percent={channel.percent}
          progress={channel.progress}
          measures={channel.measures}
        />
      ))}
      <Block className={styles.unreachableContainer}>
        <Label containerClassName={styles.unreachable} name="Unreachable">
          {unreachable}
        </Label>
      </Block>
    </Row>
  </Section>
);
