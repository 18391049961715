import { useInfiniteQuery } from 'react-query';
import qs from 'qs';
import {
  fetchProgramActivities,
  FetchProgramActivitiesProps,
  ProgramActivities,
  ProgramActivitiesLinks,
  ParsedAuditEvent,
} from 'services/api-program';
import { InfiniteQueryResponse } from './common';

function nextPageToFetch(links: ProgramActivitiesLinks): number | undefined {
  const { next } = links;
  if (next) {
    const nextParams = qs.parse(next.split('?')[1]);
    return Number(nextParams.page);
  }
  return undefined;
}

export const useAuditEventsInfiniteQuery = (
  props: Omit<FetchProgramActivitiesProps, 'page'>
): InfiniteQueryResponse<ParsedAuditEvent> => {
  const {
    programId,
    pageSize = 50,
    recordId,
    kind = [],
    includeSuperadminActivity,
  } = props;
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ProgramActivities, Error>(
    ['audit-events-infinite', JSON.stringify(props)],
    async ({ pageParam = 1 }) =>
      fetchProgramActivities({
        programId,
        page: pageParam,
        pageSize,
        recordId,
        kind,
        includeSuperadminActivity,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.links),
    }
  );

  const batchData = data?.pages;
  const flatData = batchData && batchData.map((d) => d.data).flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};
