import { OptionType } from 'hooks/common';
import { LitmusRole, LitmusRoleByType } from 'models/role';

export const filterRolesPerFeatureFlag = (
  roles: LitmusRole[],
  showStandardRoles: boolean,
  showCustomRoles: boolean
): LitmusRole[] => {
  const allowedRoles: LitmusRole[] = roles.filter(
    (role) => role.name === 'member' || role.name === 'brand_admin'
  );

  if (showStandardRoles) {
    allowedRoles.push(
      ...roles.filter(
        (role) =>
          role.isSystemRole &&
          role.name !== 'member' &&
          role.name !== 'brand_admin'
      )
    );
  }

  if (showCustomRoles) {
    allowedRoles.push(...roles.filter((role) => !role.isSystemRole));
  }

  return allowedRoles;
};

export const groupRolesByType = (roles: LitmusRole[]): LitmusRoleByType => {
  const rolesByType: LitmusRoleByType = {
    primary: [],
    secondary: [],
  };

  roles.forEach((role: LitmusRole) => {
    if (!rolesByType[role.type]) return;
    rolesByType[role.type].push(role);
  });

  return rolesByType;
};

export const rolesToSelectOptions = (
  groupedRoles: LitmusRoleByType
): OptionType[] => {
  const sortedPrimaryRoles = groupedRoles.primary.sort((a, b) => {
    if (a.title > b.title) return 1;
    if (b.title > a.title) return -1;

    return 0;
  });

  const roleOptions = sortedPrimaryRoles.map((role) => ({
    value: role.name,
    label: role.title,
    description: role.description || 'N/A',
  }));

  return [
    {
      value: 'member',
      label: 'Member',
      description:
        'Members can access the Member Experience for this community and do not have administrative privileges for Studio.',
    },
    ...roleOptions,
  ];
};
