import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { PageHeader } from 'DesignSystem/Layout/Pages';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { SearchBar } from 'shared/SearchBar';
import { useProgram } from 'contexts/program';
import { CollectionsContext } from '../contexts/CollectionsContext';
import { useCollectionTabs } from '../hooks/useCollectionTabs';
import styles from '../insight.module.css';

export const CollectionsSidebarTheme: React.FC<
  RouteComponentProps & { collectionId?: string }
> = ({ children, navigate, collectionId }) => {
  const { customCollectionsQuery, isLoadingAny } = useContext(
    CollectionsContext
  );
  const { id: programId } = useProgram();

  const collections = useMemo(() => customCollectionsQuery.data || [], [
    customCollectionsQuery,
  ]);

  const location = useLocation();
  const [queryTerm, updateQuery] = useState('');
  const [isValidCollection, setIsValidCollection] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const path = location.pathname.split('/').pop();

  if (path && path !== 'search') {
    sessionStorage.setItem(`${programId}:activeCollectionId`, path);
  }

  useEffect(() => {
    if (
      collectionId &&
      ['search', 'overview', 'saved'].indexOf(collectionId?.toLowerCase()) ===
        -1
    ) {
      updateQuery('');

      // see if collection exists in the list
      setIsValidCollection(
        !!collections.find((c) => String(c.id) === collectionId)
      );
    }
    if (collectionId === 'search' && inputRef.current && location) {
      const search = new URLSearchParams(location.search);
      const searchTerm = search.get('query') as string;
      updateQuery(searchTerm);
      inputRef.current.focus();
    }
  }, [collectionId, inputRef, location, setIsValidCollection, collections]);

  const search = React.useCallback(
    (text: string) => {
      const validText = text.trim();
      if (validText.length) {
        updateQuery(text);
      }

      if (!navigate) return;

      navigate(
        validText.length
          ? `../search?query=${encodeURI(text)}`
          : `../${
              sessionStorage.getItem(`${programId}:activeCollectionId`) ||
              'overview'
            }`
      );
    },
    [navigate, programId]
  );

  const tabs = useCollectionTabs();

  return (
    <div>
      <div style={{ padding: '0 32px' }}>
        <PageHeader
          filterbar={
            <SearchBar
              onChange={search}
              value={queryTerm}
              inputRef={inputRef}
            />
          }
          title="Insights"
          tabs={tabs}
        />
        {!isLoadingAny ? (
          <div style={{ marginTop: '64px' }}>
            {isValidCollection || queryTerm ? (
              children
            ) : (
              <p>
                That is an invalid Collection. Please search for another
                collection or select from one of the collections above.
              </p>
            )}
          </div>
        ) : (
          <div className={styles.centeredItemsContainer}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};
