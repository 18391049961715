import React from 'react';
import { Box } from '../../../DesignSystem/Components';
import { ExclamationTriangleOutlined } from '../../../shared/icons';
import { Body, color } from '../../../DesignSystem/Typography';

export const ErrorBox: React.FC<{
  visible: boolean;
  marginTop: number;
}> = ({ children, visible, marginTop }) => {
  if (!visible) return null;

  return (
    <Box role="alert" style={{ display: 'flex' }} margin={[marginTop, 0, 0, 0]}>
      <Box margin={['auto', 9, 'auto', 0]}>
        <ExclamationTriangleOutlined />
      </Box>
      <Body color={color.redFull}>{children}</Body>
    </Box>
  );
};
