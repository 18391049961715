import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { SearchMetaTag } from 'models/search-meta-tag';
import { EmptyablePage, Page } from './common';
import { request, deepCamelcaseKeys } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  query?: string;
  page?: number;
  pageSize?: number;
  statuses?: string[];
};

export type SearchMetaTagsCollectionData = Page<SearchMetaTagData>;
export type SearchMetaTagsEmptyableCollectionData = EmptyablePage<
  SearchMetaTagData
>;

export type SearchMetaTagData = {
  id: string;
  type: string;
  attributes: SearchMetaTag;
};

export const fetchSearchMetaTags = async (
  props: FetchProps
): Promise<SearchMetaTagsCollectionData> => {
  const { programId, query, page, pageSize, statuses } = props;

  const q = qs.stringify(snakecaseKeys({ query, page, pageSize, statuses }), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/v2/programs/${programId}/search_meta_tags?${q}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching meta_tags: ${response.status}`);
};

export const createSearchMetaTag = async (
  programId: number,
  searchMetaTag: Partial<SearchMetaTag>
): Promise<SearchMetaTag> => {
  const url = `${apiRoot}/v2/programs/${programId}/search_meta_tags`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      data: { attributes: snakecaseKeys(searchMetaTag) },
    }),
  });

  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }

  throw new Error(`Error creating meta tag: ${response.status}`);
};
