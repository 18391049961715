import camelcaseKeys from 'camelcase-keys';
import { ParameterizedFilters } from 'models/journeys/filter';
import qs from 'qs';
import snakecaseKeys from 'snakecase-keys';
import { request } from './api-shared';
import {
  deserializeJourneyTemplateListItems,
  JourneyTemplateCollectionData,
} from './serializers/journey-template';

export type SortColumn = 'id' | 'name';

export type QueryParameters = {
  programId: number;
  search?: string;
  page?: number;
  pageSize?: number;
  sortBy?: SortColumn;
  sortDirection?: 'asc' | 'desc';
  after?: unknown;
};

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export const fetchJourneyTemplates = async (
  props: QueryParameters,
  parameterizedFilters: ParameterizedFilters
): Promise<JourneyTemplateCollectionData> => {
  const { programId, ...queryProps } = props;
  const query = qs.stringify(
    snakecaseKeys({ ...queryProps, ...parameterizedFilters }),
    {
      arrayFormat: 'brackets',
    }
  );

  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journey_templates?${query}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((json) => camelcaseKeys(json, { deep: true }))
      .then(deserializeJourneyTemplateListItems);
  }
  throw new Error(`Error fetching Journeys: ${response.status}`);
};
