import React from 'react';
import { Close } from 'shared/icons';
import cx from 'classnames';
import { BooleanFilter, Filter } from 'models/journeys/filter';
import filtersStyles from 'DesignSystem/Table/filters.module.css';
import styles from './filters.module.css';

export const BooleanFilterComponent: React.FC<{
  filter: BooleanFilter;
  onRemove: (filter: Filter) => void;
}> = ({ filter, onRemove }) => {
  return (
    <div
      key={filter.name}
      className={cx(
        filtersStyles.FilterTrigger,
        filtersStyles.FilterTriggerApplied,
        styles.booleanTrigger
      )}
    >
      <div>{filter.label}</div>
      <button
        className={filtersStyles.FilterTriggerCloseButton}
        onClick={() => onRemove(filter)}
        type="button"
      >
        <Close />
      </button>
    </div>
  );
};
