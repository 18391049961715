import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionUnderline: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    onClick={() => editor.chain().focus().toggleUnderline().run()}
    disabled={!editor.can().chain().focus().toggleUnderline().run()}
    isActive={editor.isActive('underline')}
    icon="format_underlined"
    aria-label="Underline"
  />
);
