import { useQuery } from 'react-query';
import { fetchLink, ServerLinkData } from 'services/api-assets';
import { QueryError, QueryResponse } from './common';

function fetch(url: string) {
  if (url.length) {
    return fetchLink(url);
  }

  return undefined;
}

export const useLinkFetchQuery = (
  url: string
): QueryResponse<ServerLinkData> => {
  const { isLoading, error, data } = useQuery<
    ServerLinkData | undefined,
    QueryError
  >(['link-fetch', url], () => fetch(url), {
    refetchOnWindowFocus: false,
    enabled: !!url,
    retry: false,
  });

  return {
    isLoading: isLoading && url !== '',
    errorMessage: isLoading && url !== '' ? undefined : error?.message,
    data: url === '' ? undefined : data,
  };
};
