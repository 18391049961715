import React from 'react';

export const FilterLayoutContext = React.createContext({ layout: 'campaigns' });
export const FilterLayoutProvider: React.FC<{
  layout: 'campaigns' | 'journeys';
}> = ({ layout, children }) => {
  return (
    <FilterLayoutContext.Provider value={{ layout }}>
      {children}
    </FilterLayoutContext.Provider>
  );
};
