import * as React from 'react';
import cx from 'classnames';
import { DefinitionBlock } from 'models/publisher/block';
import { FormatData, LinkTypes } from 'models/publisher/format';
import { deepMerge } from 'utility/deep-merge';
import styles from './link-type.module.css';

export const LinkType: React.FC<{
  id: string;
  block: DefinitionBlock;
  onChange: (id: string, data: FormatData) => void;
}> = ({ id, block, onChange }) => {
  const changeFormatting = React.useCallback(
    (data: FormatData) => {
      onChange(id, deepMerge(block.format_data ?? {}, data));
    },
    [id, block, onChange]
  );

  const options = [LinkTypes.Text, LinkTypes.Button].map((value) => {
    return (
      <button
        key={value}
        type="button"
        className={cx(
          styles.controlButton,
          block.format_data?.link_type === value && styles.active
        )}
        onClick={() => changeFormatting({ link_type: value })}
      >
        {value}
      </button>
    );
  });

  return (
    <div>
      <strong>Link style</strong>
      <div className={cx(styles.controlBox)}>{options}</div>
    </div>
  );
};

export function canEditLinkType(block: DefinitionBlock): boolean {
  return !!block.format_options?.link_types?.length;
}
