import React from 'react';
import { DefaultLoading } from 'shared/InfiniteList/DefaultLoading';
import styles from 'shared/InfiniteList/infinite-list.module.css';
import { useProgram } from '../../../../../contexts/program';
import { WorkbookBanner } from './WorkbookBanner';

export type WorkbookListPropsType = {
  workbooks: Tableau.Workbook[];
  categories: Tableau.WorkbookCategory[];
  isLoading: boolean;
};

export const WorkbookList: React.FC<WorkbookListPropsType> = ({
  workbooks,
  categories,
  isLoading,
}) => {
  const programId = useProgram().id;
  const workbookData = React.useMemo(() => {
    return workbooks?.sort((a: Tableau.Workbook, b: Tableau.Workbook) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }, [workbooks]);

  const renderCategory = (category: Tableau.WorkbookCategory) => {
    const categoryWorkbooks = workbookData.filter(({ tags }) =>
      tags.tag.some(({ label }) => label === category.tag)
    );

    return categoryWorkbooks.length === 0 ? null : (
      <div key={category.name}>
        <h2>{category.name}</h2>
        <div>
          {categoryWorkbooks.map((workbook) => (
            <WorkbookBanner
              key={workbook.id}
              workbook={workbook}
              programId={programId}
            />
          ))}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <DefaultLoading />;
  }
  if (!workbooks.length) {
    return <div className={styles.noItemsFound}>No items found.</div>;
  }

  return <div>{categories.map(renderCategory)}</div>;
};
