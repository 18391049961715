import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionBold: React.FC<{ editor: Editor }> = ({ editor }) => (
  <ToolbarButton
    onClick={() => editor.chain().focus().toggleBold().run()}
    disabled={!editor.can().chain().focus().toggleBold().run()}
    isActive={editor.isActive('bold')}
    icon="format_bold"
    aria-label="Bold"
  />
);
