import React from 'react';
import { ImageData, isImageDataArray, MAX_GALLERY_SIZE } from 'models/image';
import { FileDropZone } from 'shared/FileDropZone';
import cx from 'classnames';
import { Eraser } from 'shared/icons';
import { UpdateOpts } from 'components/publisher/blocks/modal-field-editor/useEditor';
import { useBulkUploader } from 'hooks/useBulkUploader';
import { EXTERNAL, Source, UPLOAD } from 'models/source';
import { SourceMenu } from '../../../SourceMenu';
import { useImagesSourceMenu, CLOSED } from '../../Editor/useImagesSourceMenu';
import { DnDContext } from './DnDContext';
import { Row } from './Row';
import styles from './collection.module.css';
import { FileUploader, UrlUploader } from './Uploader';
import { OPENED } from '../../../SourceMenu/useSourceMenu';

type PropsType = {
  images: Array<ImageData>;
  onRemove: (index: number) => void;
  onSetCurrent: (index: number) => void;
  replaceItems: (image: ImageData[], opts?: UpdateOpts) => void;
  onDrop: (image: Array<ImageData>) => void;
  onReorder: (image: Array<ImageData>) => void;
  onClearAll: () => void;
  onCreate: (source?: Source) => void;
  saveDisabled: boolean;
  enableSave: () => void;
  disableSave: () => void;
};

export const Collection: React.FC<PropsType> = ({
  images,
  onRemove,
  onReorder,
  onSetCurrent,
  onClearAll,
  onDrop,
  replaceItems,
  onCreate,
  enableSave,
  disableSave,
  saveDisabled,
}) => {
  const {
    handleFileUpload,
    handleUrlUpload,
    uploadingFiles,
    uploadingUrls,
    onFileUpload,
    onUrlUpload,
    hasPendingUploads,
  } = useBulkUploader({
    length: images.length,
    onDrop,
    saveDisabled,
    enableSave,
    disableSave,
    maxSize: MAX_GALLERY_SIZE,
  });
  const onProcessed = React.useCallback(
    (data: ImageData) => {
      replaceItems([data], { shouldValidate: false });
    },
    [replaceItems]
  );
  // do not wrap in useMemo
  const isProcessed = images.map((i) => i.processed);

  React.useEffect(() => {
    if (isProcessed.includes(false)) {
      disableSave();
    } else if (!hasPendingUploads) {
      enableSave();
    }
  }, [disableSave, enableSave, hasPendingUploads, isProcessed]);

  const onImageCreate = React.useCallback(
    (data: ImageData[] | File[] | DataTransferItemList) => {
      if (isImageDataArray(data)) {
        handleUrlUpload(data);
      } else if (data instanceof DataTransferItemList) {
        const files: File[] = [];
        for (let i = 0; i < data.length; i += 1) {
          const file = data[i].getAsFile();
          if (file) files.push(file);
        }
        handleFileUpload(files);
      } else {
        handleFileUpload(data);
      }
    },
    [handleFileUpload, handleUrlUpload]
  );

  const sourceField = useImagesSourceMenu(
    { source: UPLOAD, visibility: CLOSED },
    onImageCreate,
    MAX_GALLERY_SIZE - images.length
  );

  React.useEffect(() => {
    if (([EXTERNAL] as Source[]).includes(sourceField.source)) {
      if (sourceField.visibility === OPENED) onCreate(sourceField.source);
    }
  }, [sourceField.source, sourceField.visibility, onCreate]);

  const renderGrid = React.useCallback(() => {
    const grid = [];
    for (let i = 0; i <= images.length; i += 3) {
      grid.push(
        <Row
          key={i}
          startIndex={i}
          images={images.slice(i, i + 3)}
          onEdit={onSetCurrent}
          onRemove={onRemove}
          onProcessed={onProcessed}
        />
      );
    }
    return grid;
  }, [images, onSetCurrent, onRemove, onProcessed]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SourceMenu menu={sourceField.menu}>
          {images.length < MAX_GALLERY_SIZE && (
            <button
              className={styles.actionButton}
              type="button"
              data-test="change-source-button-top"
              onClick={() => {}}
            >
              + New image
            </button>
          )}
        </SourceMenu>
        {sourceField.fileInput}
        {sourceField.libraryInput}
        <button
          className={cx(styles.actionButton, {
            [styles.inactive]: images.length === 0,
          })}
          type="button"
          onClick={onClearAll}
        >
          <Eraser />
          <span data-test="clear-all-images-button">Clear all</span>
        </button>
      </div>
      <DnDContext images={images} onDrop={onReorder}>
        <FileDropZone
          withButton={false}
          accept="image/*"
          onFileSelect={(file: File) => handleFileUpload([file])}
          handleUpload={onImageCreate}
        >
          {renderGrid()}

          {uploadingFiles.map((file) => (
            <FileUploader onUpload={onFileUpload} data={file} key={file.id} />
          ))}

          {uploadingUrls.map((image) => (
            <UrlUploader onUpload={onUrlUpload} data={image.url} />
          ))}

          {images.length >= MAX_GALLERY_SIZE ? null : (
            <button
              data-test="change-source-button-bottom"
              type="button"
              onClick={() => {}}
              className={styles.plus}
            >
              <SourceMenu menu={sourceField.menu}>+</SourceMenu>
            </button>
          )}
        </FileDropZone>
      </DnDContext>
    </div>
  );
};
