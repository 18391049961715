import { useProgram } from 'contexts/program';
import { Topic } from 'models/topic';
import React from 'react';
import { NavigationFooter } from 'shared/NavigationFooter';
import { useTopicErrors } from '../useTopicErrors';

export const Footer: React.FC<{
  topic: Partial<Topic>;
  action: () => void;
  isWorking: boolean;
}> = ({ topic, action, isWorking }) => {
  const topicErrors = useTopicErrors(topic);
  const { id: programId } = useProgram();

  const exitPath = `/${programId}/configure/topics`;

  return (
    <NavigationFooter
      canPerformAction={!topicErrors && !isWorking}
      actionName="Save"
      action={action}
      exitPath={exitPath}
      title={topic?.name}
      hideMenu
    />
  );
};
