import TextStyle from '@tiptap/extension-text-style';
import { DEFAULT_EDITOR_FONT_SIZE } from '../constants';

// from https://stackoverflow.com/questions/70564092/tiptap-font-size-react
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the font size
       */
      setFontSize: (size: number) => ReturnType;
      /**
       * Unset the font size
       */
      unsetFontSize: () => ReturnType;
    };
  }
}

export const TextStyleExtended = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: DEFAULT_EDITOR_FONT_SIZE,
        parseHTML: (element) => element.style.fontSize.replace('pt', ''),
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {};
          }
          return {
            style: `font-size: ${attributes.fontSize}pt`,
          };
        },
      },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize: (fontSize) => ({ commands }) => {
        return commands.setMark(this.name, { fontSize });
      },
      unsetFontSize: () => ({ chain }) => {
        return chain()
          .setMark(this.name, { fontSize: null })
          .removeEmptyTextStyle()
          .run();
      },
    };
  },
});
