import * as React from 'react';
import { useUniqueId } from 'hooks/useUniqueId';

export enum FillRule {
  Default = 'nonzero',
  EvenOdd = 'evenodd',
  Inherit = 'inherit',
  NonZero = 'nonzero',
}

export type Path = React.SVGProps<SVGPathElement>;

export type Dimensions = {
  width: number;
  height: number;
};

export type DrawFunc = (props: {
  shape: Shape;
  fill?: string;
  id: string;
}) => JSX.Element | Array<JSX.Element> | null;

export type Shape =
  | {
      type: 'paths';
      paths: Path[];
    }
  | {
      type: 'draw';
      draw: JSX.Element | ((id: string) => JSX.Element);
    };

export type CustomSvgProps = {
  viewBox?: string;
  className?: string;
  defaultSize: number | Dimensions;
  size?: number;
  defaultFill?: string;
  fill?: string;
  fillRule?: FillRule;
  shape: Shape;
  style?: React.CSSProperties;
  title?: string;
  clipRule?: number | string;
};

function isDimensions(size: CustomSvgProps['defaultSize']): size is Dimensions {
  return !(typeof size === 'string' || typeof size === 'number');
}

function isElement(
  fn: JSX.Element | ((id: string) => JSX.Element)
): fn is JSX.Element {
  return typeof fn !== 'function';
}

const draw: DrawFunc = ({ shape, fill, id }) => {
  switch (shape.type) {
    case 'draw': {
      return isElement(shape.draw) ? shape.draw : shape.draw(id);
    }
    case 'paths':
      return shape.paths.map((path) => {
        const options: Path = {
          d: path.d,
          fill: fill || path.fill || 'currentColor',
          stroke: path.stroke,
          strokeLinejoin: path.strokeLinejoin,
          strokeWidth: path.strokeWidth,
        };
        if (path.fillRule) options.fillRule = path.fillRule;
        if (path.clipRule) options.clipRule = path.clipRule;
        if (path.opacity) options.opacity = path.opacity;
        /* eslint-disable react/jsx-props-no-spreading */
        return <path key={path.d} {...options} />;
      });
    default:
      return null;
  }
};

export const CustomSvg: React.FC<CustomSvgProps> = ({
  className,
  fillRule = FillRule.Default,
  fill,
  defaultFill,
  size,
  viewBox,
  style,
  title,
  shape,
  defaultSize,
}) => {
  const { width, height } = isDimensions(defaultSize)
    ? defaultSize
    : {
        width: defaultSize,
        height: defaultSize,
      };
  const { scaledWidth, scaledHeight } = {
    scaledWidth: size || width,
    scaledHeight: size ? size * (height / width) : height,
  };

  const id = useUniqueId();
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || `0 0 ${width} ${height}`}
      fillRule={fillRule}
      className={className}
      width={scaledWidth}
      height={scaledHeight}
      fill={fill || defaultFill || 'currentColor'}
      style={style}
    >
      {title && <title>{title}</title>}
      {draw({ shape, fill, id })}
    </svg>
  );
};
