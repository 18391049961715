import React from 'react';
import cx from 'classnames';

import {
  HoverDropdown,
  DropdownRenderPropType,
} from 'shared/hover-dropdown/HoverDropdown';
import { ChevronRight } from 'shared/icons';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import mdlStyles from '../people.module.css';

export type RestrictionsFilterItemProps = {
  name: string;
  dropdownRenderProp: DropdownRenderPropType;
  filtersCount?: number;
};

export const RestrictionsFilterItem: React.FC<RestrictionsFilterItemProps> = ({
  dropdownRenderProp,
  name,
  filtersCount = 0,
}) => {
  return (
    <HoverDropdown
      closeDelay={0}
      dropdownClassName={cx(
        'dropdown-align-left',
        mdlStyles.restrictionsExpandedDropdown
      )}
      dropdownRenderProp={dropdownRenderProp}
    >
      <div className={styles.sourcesDropdownRow}>
        <div>{name}</div>
        <div className={styles.filterItemIcons}>
          {filtersCount > 0 && (
            <div className={styles.filterItemBadge}>{filtersCount}</div>
          )}
          <ChevronRight />
        </div>
      </div>
    </HoverDropdown>
  );
};
