import React from 'react';
import cx from 'classnames';
import { FontStylesheet } from 'models/library';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './font-banner.module.css';

type PreviewProps = { font: FontStylesheet };

export const FontPreview: React.FC<PreviewProps> = ({ font }) => {
  const fontSizes = [styles.smallFont, styles.mediumFont, styles.bigFont];

  const fontWeights = [
    {
      name: 'Normal',
      style: styles.regularFont,
    },
    {
      name: 'Extra Bold',
      style: styles.boldFont,
    },
    {
      name: 'Thin',
      style: styles.thinFont,
    },
  ];

  const isStandard = React.useMemo(() => {
    return !font.asset || (font.asset && !font.asset.fonts);
  }, [font.asset]);

  return (
    <Flex start column className={styles.previewModal}>
      {!isStandard &&
        font.asset.fonts.map((f) => {
          return (
            <div
              key={`example-${font.title}-${f.weight}-${f.style}`}
              className={styles.fontExample}
            >
              <div className={styles.styleName}>
                {f.style} {f.weight}
              </div>
              {fontSizes.map((size) => (
                <div
                  key={`size-${font.title}-${size}`}
                  className={size}
                  style={{
                    fontFamily: `${JSON.stringify(f.family ?? font.title)}`,
                    fontWeight: f.weight,
                    fontStyle: f.style,
                  }}
                >
                  The quick brown fox jumps over the lazy dog
                </div>
              ))}
            </div>
          );
        })}
      {isStandard &&
        fontWeights.map((weight) => (
          <div key={`weight-${weight.name}`} className={styles.fontExample}>
            <div className={styles.styleName}>{weight.name}</div>
            {fontSizes.map((size) => (
              <div
                key={`size-${font.title}-${size}`}
                className={cx(weight.style, size)}
                style={{ fontFamily: font.title }}
              >
                The quick brown fox jumps over the lazy dog
              </div>
            ))}
          </div>
        ))}
    </Flex>
  );
};
