import * as React from 'react';
import cx from 'classnames';
import { Textarea } from '.';
import style from './textarea.module.css';

type PropsType = {
  value?: string;
  onChange?: (value: string) => void;
  maximum?: number;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
} & Omit<JSX.IntrinsicElements['textarea'], 'value' | 'onChange' | 'onBlur'>;

export const ExpandableTextarea: React.FC<PropsType> = ({
  value,
  onChange,
  maximum,
  disabled,
  inputRef,
  className,
  ...passThrough
}) => {
  const internalRef = React.useRef<HTMLTextAreaElement>(null);
  const [textareaRef] = React.useState(inputRef || internalRef);
  const height = React.useCallback((el: Element | null) => {
    if (!el) return 'inherit';
    return `${el.scrollHeight}px`;
  }, []);
  React.useEffect(() => {
    const ready = !!textareaRef.current;
    if (ready && textareaRef.current) {
      textareaRef.current.style.height = height(textareaRef.current);
    }
  }, [value, textareaRef, height]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Textarea
      inputRef={textareaRef}
      className={cx(style.expandableTextarea, className)}
      value={value}
      onChange={onChange}
      maximum={maximum}
      disabled={disabled}
      rows={1}
      onKeyUp={(e) => {
        // needs set twice, once to reset, and once to get the natural height
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = height(textareaRef.current);
      }}
      {...passThrough}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};
