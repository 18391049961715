import { SignInConfig } from 'models/signInConfig';
import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';
import { useUpdateSignInConfig } from 'hooks/program';

export const useActions = (
  callback?: (config: SignInConfig) => void
): {
  update: (config: SignInConfig) => void;
} => {
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const validationError = (error: Error) => {
    const errors = JSON.parse(error.message);

    const keys = Object.keys(errors.errors);
    if (keys.includes('enabled.username')) {
      setFlashMessage({
        severity: 'error',
        message:
          'User verification set-up is required before enabling usernames.',
      });
      return;
    }

    if (keys.some((key) => key.includes('enabled.'))) {
      setFlashMessage({
        severity: 'error',
        message:
          'User verification set-up is required before enabling for Firstup Sign-In.',
      });
      return;
    }

    flashServerErrors(error, 'Could update user access');
  };

  const { update } = useUpdateSignInConfig({
    onSuccess: (config) => {
      setFlashMessage({
        severity: 'info',
        message: 'User access updated successfully',
      });
      if (callback) {
        callback(config);
      }
    },
    onError: (error) => {
      if (error.name === 'ValidationError') {
        validationError(error);
      } else {
        flashServerErrors(error, 'Could update user access');
      }
    },
  });

  return { update };
};
