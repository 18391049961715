import * as React from 'react';
import { ButtonLinkFieldData } from 'models/publisher/block';
import * as Type from 'DesignSystem/Typography';
import { FieldInput, Input } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { FieldLegend } from '../shared/FieldLegend';
import { FieldFormProps } from '../../useFieldForm';
import { useButtonLinkMutator } from './hooks/useButtonLinkMutator';
import { OpenInNewTabToggle } from '../shared/OpenInNewTabToggle';

export const ButtonLink: React.FC<FieldFormProps<ButtonLinkFieldData>> = (
  props
) => {
  const { buttonLink, updateButtonLink: onChange } = useButtonLinkMutator(
    props
  );
  const showOpenInNewTab = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.ShowOpenLinksInNewTab'
  ).data?.value;
  return (
    <>
      <FieldInput
        legend={<FieldLegend>Text</FieldLegend>}
        htmlFor="block-button-link-field-title"
      >
        <Input
          maxLength={100}
          id="block-button-link-field-title"
          className="flex-fill"
          value={buttonLink?.title || ''}
          placeholder="Read More"
          onChange={(title) => onChange({ ...buttonLink, title })}
        />
        <Type.Caption block>The text for your button.</Type.Caption>
      </FieldInput>
      <FieldInput
        htmlFor="block-button-link-field-url"
        legend={<FieldLegend>Link</FieldLegend>}
      >
        <Input
          id="block-button-link-field-url"
          className="flex-fill"
          value={buttonLink?.url || ''}
          placeholder="https://example.com"
          onChange={(url) => onChange({ ...buttonLink, url })}
        />
        <Type.Caption block>The link for your button.</Type.Caption>
      </FieldInput>
      {showOpenInNewTab && (
        <OpenInNewTabToggle
          value={buttonLink?.openInNewTab}
          onChange={(openInNewTab) => onChange({ ...buttonLink, openInNewTab })}
        />
      )}
    </>
  );
};
