import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SearchBar } from 'shared/SearchBar';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { ListPage as LayoutListPage } from 'DesignSystem/Layout/Pages';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Status, Statuses, WorkflowTemplate } from 'models/workflow-templates';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useWorkflowTemplatesInfiniteQuery } from 'hooks/workflow-templates/useQueries';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { titleCase } from 'utility/text';
import { Banner } from './Banner';

export const FilterBar: React.FC<{
  search?: string;
  setSearch: (value: string) => void;
}> = ({ search, setSearch }) => (
  <Flex start>
    <SearchBar
      value={search}
      onChange={setSearch}
      placeholder="Search Workflow Templates"
    />
    <Space size={10} />
  </Flex>
);

export const ListPage: React.FC<RouteComponentProps & { status: Status }> = ({
  status,
}) => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();

  const search = useDebounce(searchTerm);
  const query = useWorkflowTemplatesInfiniteQuery({
    programId,
    status,
    search,
  });

  return (
    <LayoutListPage
      title="Workflow Templates"
      description="Editing Workflow Template affects ALL programs. With great power comes great responsibility."
      breadcrumbs={[
        { label: 'Configure', to: '../..' },
        { label: 'Workflow Templates' },
      ]}
      tabs={Statuses.map((statusTab) => ({
        to: statusTab,
        label: titleCase(statusTab),
      }))}
      actions={[
        {
          icon: <Icon name="Plus" fill="currentColor" />,
          label: 'Create',
          to: '../new',
        },
      ]}
      filterbar={<FilterBar search={searchTerm} setSearch={setSearchTerm} />}
      infiniteQuery={query}
      emptyList={
        <Text.Subheading block bold>
          No workflow template match that criteria.
        </Text.Subheading>
      }
      loading={<Text.Body block>Loading...</Text.Body>}
      renderRow={(template: WorkflowTemplate, index: number) => (
        <Banner key={template.id} index={index} template={template} />
      )}
    />
  );
};
