import React from 'react';
import { FieldLegend } from 'components/publisher/blocks/forms/fields/shared/FieldLegend';
import { Box } from 'DesignSystem/Components';
import { FieldInput, Input, Textarea } from 'DesignSystem/Form';
import { Caption } from 'DesignSystem/Typography';
import {
  SHAREABLE_MESSAGE_MAX_LENGTH,
  SLUG_MAX_LENGTH,
} from 'models/publisher/settings';

export const SharableDataForm: React.FC<{
  message: string;
  slug: string;
  contentWasPublished: boolean;
  setMessage: (message: string) => void;
  setSlug: (slug: string) => void;
}> = ({ message, setMessage, slug, setSlug, contentWasPublished }) => (
  <Box margin={[0, 0, 0, 60]}>
    <FieldInput
      legend={
        <FieldLegend block>
          <strong>Share Message</strong>
        </FieldLegend>
      }
    >
      <Textarea
        hasError={message.length > SHAREABLE_MESSAGE_MAX_LENGTH}
        value={message}
        placeholder="Type sharable message"
        onChange={setMessage}
        style={{ fontSize: 'var(--font-size-body)' }}
      />
      <Box style={{ display: 'flex' }}>
        <Box margin={[0, 20, 0, 'auto']}>
          <Caption>
            {message.length} / {SHAREABLE_MESSAGE_MAX_LENGTH} characters
          </Caption>
        </Box>
      </Box>
    </FieldInput>
    <FieldInput
      legend={
        <FieldLegend block>
          <strong>Public URL Slug</strong>
        </FieldLegend>
      }
    >
      <Box style={{ display: 'flex' }}>
        <Input
          hasError={slug.length > SLUG_MAX_LENGTH}
          block
          value={slug}
          placeholder="Type handle"
          onChange={setSlug}
        />
      </Box>
      {contentWasPublished && (
        <Box margin={[5, 0]}>
          <Caption>Links shared with previous url slug will not work</Caption>
        </Box>
      )}
    </FieldInput>
  </Box>
);
