import * as React from 'react';
import * as DesignSystem from 'DesignSystem/Components';
import { Layer } from 'shared/Overlay/Layer';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { EditorProps } from '../modal-field-editor/useEditor';
import { Field } from './fields/Field';
import { PanelProvider, usePanelContext } from './usePanelContext';
import { useFieldForm, BlockFieldFormProps } from './useFieldForm';
import { ContentDesignTabs, TabType } from '../panels/ContentDesignTabs';
import { VisibilityPanel } from '../instances/SectionContent';
import styles from './styles.module.css';

export const Form: React.FC<EditorProps> = ({
  block,
  blockId,
  field,
  onChangeData,
  hideBlockEditor,
  defaultTab,
}) => (
  <PanelProvider hideBlockEditor={hideBlockEditor}>
    {defaultTab === TabType.Content && (
      <FormPanel
        block={block}
        blockId={blockId}
        field={field}
        onChangeData={onChangeData}
        defaultTab={defaultTab}
      />
    )}
    {defaultTab === TabType.Section && (
      <VisibilityPanel
        hideBlockEditor={hideBlockEditor}
        defaultTab={defaultTab}
      />
    )}
  </PanelProvider>
);

const FormPanel: React.FC<BlockFieldFormProps & { defaultTab: TabType }> = ({
  defaultTab,
  ...props
}) => {
  const editor = useFieldForm(props);
  const panel = usePanelContext();
  const { selected } = React.useContext(BlocksEditorContext);
  const goToOtherTab = React.useCallback(
    (tabName: TabType) => {
      const mapAction = {
        content: 'open-editor',
        design: 'open-styles',
        section: 'open-section',
      };
      const selector = `[data-block-id="${selected?.id}"][data-block-action="${mapAction[tabName]}"]`;
      const element = document.querySelector(selector) as HTMLElement | null;
      element?.click();
    },
    [selected]
  );
  return (
    <>
      <Layer name="publisher-block-panel">
        <DesignSystem.FixedPanel side="right">
          <ContentDesignTabs active={defaultTab} activate={goToOtherTab} />
          <div className={styles.fieldWrapper}>
            <Field
              field={editor.field}
              data={editor.data}
              default_data={editor.default_data}
              onChange={editor.onChange}
              valid={editor.valid}
              errors={editor.errors}
            />
          </div>
        </DesignSystem.FixedPanel>
      </Layer>
      {panel.isBlocking && panel.blocker}
    </>
  );
};
