import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useBulkUpdatePermissionsJobQuery } from 'hooks/bulk-update-permissions';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { WithPermission } from 'shared/WithPermission';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';
import { Main } from './Main';

export const BulkUpdatesDetailsPage: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { isLoading, data } = useBulkUpdatePermissionsJobQuery(
    programId,
    Number(id)
  );
  const { data: showBulkUpdateUI } = useFeatureFlagsQuery(
    programId,
    'Studio.BulkUpdatePermissions.UI'
  );

  if (isLoading) return <div>Loading...</div>;

  const importData = data as BulkUpdatePermissionsJob;
  const header = (
    <>
      <h1 className="page-header">Bulk Update Permissions</h1>
    </>
  );

  const main = <Main job={importData} />;
  const sidebar = <Sidebar job={importData} />;
  const footer = <Footer title={importData.id} />;

  return (
    <WithPermission permissions={['configureRoleAccess']}>
      {showBulkUpdateUI?.value && (
        <MainEditor
          header={header}
          main={main}
          sidebar={sidebar}
          footer={footer}
        />
      )}
    </WithPermission>
  );
};
