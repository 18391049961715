import React from 'react';
import { Box, ConfirmModal } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Pills } from 'DesignSystem/Components/Pills';
import { Topic } from 'models/topic';
import { Audience } from 'models/audience';
import { Template } from 'models/library';
import { EmailAlias } from 'models/email-alias';
import { AliasData } from 'services/api-author-alias';
import styles from './styles.module.css';

type PropsType = {
  action: string;
  onConfirm: () => void;
  onCancel: () => void;
  permissions: string[];
  topics: Topic[];
  audiences: Audience[];
  templates: Template[];
  emailAliases: EmailAlias[];
  authorAliases: AliasData[];
  userCount: number;
};
export const EditPermissionsConfirmModal: React.FC<PropsType> = ({
  userCount,
  action,
  onCancel,
  onConfirm,
  permissions,
  topics,
  audiences,
  templates,
  emailAliases,
  authorAliases,
}) => {
  return (
    <ConfirmModal
      title={
        action === 'replace'
          ? 'Bulk Replace Permissions'
          : 'Bulk Add Permissions'
      }
      description={
        action === 'replace'
          ? 'The permissions listed below will replace any existing permissions for the selected users.'
          : 'The permissions listed below will be added for the selected users. Any existing permissions will not be replaced.'
      }
      confirmLabel={action === 'replace' ? 'Replace' : 'Add'}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Box padding={8}>
        The following permissions will be{' '}
        <Text.Body bold>
          {action === 'replace' ? 'replaced' : 'added'}
        </Text.Body>{' '}
        for <Text.Body bold>{userCount}</Text.Body> users:
        {permissions.includes('topics') && (
          <Box margin={[24, 0, 0, 0]}>
            <Text.Subheading bold>Topics</Text.Subheading>
            <Box margin={[8, 0, 0, 0]}>
              <Flex start wrap className={styles.PillsContainer}>
                {topics.map((topic, i) => (
                  <Pills
                    key={`confirm-topic-${topic.id}`}
                    values={[{ id: i }]}
                    render={() => <>{topic.name}</>}
                    padding={[0, 12, 0, 12]}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        )}
        {permissions.includes('audiences') && (
          <Box margin={[24, 0, 0, 0]}>
            <Text.Subheading bold>Audiences</Text.Subheading>
            <Box margin={[8, 0, 0, 0]}>
              <Flex start wrap className={styles.PillsContainer}>
                {audiences.map((audience, i) => (
                  <Pills
                    key={`confirm-audience-${audience.id}`}
                    values={[{ id: i }]}
                    render={() => <>{audience.title}</>}
                    padding={[0, 12, 0, 12]}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        )}
        {permissions.includes('templates') && (
          <Box margin={[24, 0, 0, 0]}>
            <Text.Subheading bold>Templates</Text.Subheading>
            <Box margin={[8, 0, 0, 0]}>
              <Flex start wrap className={styles.PillsContainer}>
                {templates.map((template, i) => (
                  <Pills
                    key={`confirm-template-${template.id}`}
                    values={[{ id: i }]}
                    render={() => <>{template.title}</>}
                    padding={[0, 12, 0, 12]}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        )}
        {permissions.includes('email-aliases') && (
          <Box margin={[24, 0, 0, 0]}>
            <Text.Subheading bold>Email Aliases</Text.Subheading>
            <Box margin={[8, 0, 0, 0]}>
              <Flex start wrap className={styles.PillsContainer}>
                {emailAliases.map((alias, i) => (
                  <Pills
                    key={`confirm-email-alias-${alias.id}`}
                    values={[{ id: i }]}
                    render={() => <>{alias.name}</>}
                    padding={[0, 12, 0, 12]}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        )}
        {permissions.includes('author-aliases') && (
          <Box margin={[24, 0, 0, 0]}>
            <Text.Subheading bold>Author Aliases</Text.Subheading>
            <Box margin={[8, 0, 0, 0]}>
              <Flex start wrap className={styles.PillsContainer}>
                {authorAliases.map((alias, i) => (
                  <Pills
                    key={`confirm-author-alias-${alias.id}`}
                    values={[{ id: i }]}
                    render={() => <>{alias.displayName}</>}
                    padding={[0, 12, 0, 12]}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
    </ConfirmModal>
  );
};
