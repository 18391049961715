import React from 'react';
import {
  Clock,
  Communication,
  Condition,
  EditFilled,
  PlayArrow,
  Check,
} from 'shared/icons';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './icons.module.css';

export const CommunicationIcon: React.FC = () => {
  return (
    <div className={cx([styles.icon, styles.communication])}>
      <Communication />
    </div>
  );
};

export const EmptyCommunicationIcon: React.FC = () => {
  return (
    <div className={cx([styles.icon, styles.emptyCommunication])}>
      <EditFilled />
    </div>
  );
};

export const StartIcon: React.FC = () => {
  return (
    <div className={cx([styles.icon, styles.start])}>
      <PlayArrow />
    </div>
  );
};

export const DecisionIcon: React.FC = () => {
  return (
    <div className={cx([styles.icon, styles.condition])}>
      <Condition />
    </div>
  );
};

export const DelayIcon: React.FC = () => {
  return (
    <Flex center className={cx([styles.icon, styles.delay])}>
      <Clock />
    </Flex>
  );
};

export const EndIcon: React.FC = () => {
  return (
    <Flex center className={cx([styles.icon, styles.end])}>
      <Check />
    </Flex>
  );
};
