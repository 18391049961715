import React from 'react';
import {
  Filter,
  isAttributeFilter,
  isListFilter,
  PaginatedFilter,
} from 'models/insight/Filter';
import {
  safeTransformMapValuesToArray,
  safeTransformSetToArray,
} from 'utility/ie11Utils';
import qs from 'qs';
import { useLocation } from '@reach/router';
import { RelativeRange } from 'shared/DateRangeInput/DateRange';
import { useProgram } from 'contexts/program';
import { useInsightsFiltersWithUpdatedDojoListValues } from 'App/Program/Main/Insight/hooks/useInsightsFiltersWithUpdatedDojoListValues';
import {
  DeepLinkedParamsType,
  FilterState,
  FiltersStateAction,
  FiltersStateMap,
  filtersStateReducer,
} from 'App/Program/Main/Insight/contexts/filtersStateReducer';
import { useInitialFilters } from 'App/Program/Main/Insight/hooks/useInitialFilters';
import {
  FilterStateObj,
  FilterStateWithSingleValuesObj,
  FilterValue,
} from 'services/api-insights';
import { usePrevious } from 'hooks/usePrevious';
import { useDebounce } from 'hooks/useDebounce';
import { WidgetType } from 'models/insight/json/filterJson';

/**
 * This context is responsible for tracking the state of selected and unselected filters for a report
 * The state object is a Map (keyed by filter slug) and all selected values (single or multiple)
 * are saved in the value as a set
 * Maps are faster than arrays (for lookups) and more "appropriate" than regular objects
 *
 * All interactions with the state object should be done through the reducer actions
 * DO NOT directly modify the state object. This will lead to subtle bugs
 * take care when cloning the state object, be sure to deep clone when necessary,
 *   otherwise you will inadvertently modifying the state object by reference
 *
 * Special note about subfilters:
 * - it is possible for subfilters to be "nested" within another filter
 * - currently there is only one subfilter: "max segments" number filter within a "view by" filter
 * - currently we implement this as an optional extension of the view_by filter
 * - rather than as a "general" subfilter that can be rendered for any filter
 * - if in the future the need for a "general" filter is needed we will need to revisit this implementation
 * - see <ViewByDropdown/> for implementation details
 * - see the FilterStateReducer for the special handling of subfilters in the "add/remove" actions
 * */

export type FiltersStateContextType = {
  filtersStateMap?: FiltersStateMap;
  debouncedFilterStateMap?: FiltersStateMap;
  filtersStateArray?: FilterState[];
  filtersStateAction: (action: FiltersStateAction) => void;
  unSelectedFilters?: FilterState[];
  updatedAttributeFilters?: FilterState[];
  requiredFiltersWithNoSelections?: FilterState[];
  dateRange: string | undefined | false; // string=has value, undefined=no value, false=no matter
  getShareableLink: () => string;
  resetFilters: () => void;
  getSelectedFiltersStateObj: () => FilterStateObj;
  getFilterStateObjArray: () => FilterStateObj[];
  metabaseFilterQuery: () => string;
  staticFilters: Filter[];
};

export const FiltersStateContext = React.createContext<FiltersStateContextType>(
  {
    filtersStateAction: () => {},
    resetFilters: () => {},
    getShareableLink: () => '',
    getSelectedFiltersStateObj: () => ({}),
    getFilterStateObjArray: () => [],
    metabaseFilterQuery: () => '',
    dateRange: undefined,
    staticFilters: [],
  }
);

type FiltersStateProviderProps = {
  reportId?: string;
  allFilters?: Filter[];
  deepLinkedParams: DeepLinkedParamsType;
  updateFilterState?: (filterStateObj?: FilterStateWithSingleValuesObj) => void;
  updateDebouncedFilterState?: (
    filterStateObj?: FilterStateWithSingleValuesObj
  ) => void;
};
export const FiltersStateProvider: React.FC<FiltersStateProviderProps> = ({
  children,
  reportId,
  allFilters,
  deepLinkedParams,
  updateFilterState,
  updateDebouncedFilterState,
}) => {
  const routerLocation = useLocation();
  const [queryParams, setQueryParms] = React.useState(window.location.href);

  // initial render will cause the queryParams to change a couple of times, because of the way filters are loaded on report page.
  // filter params are loaded first and there values are loaded after, this will create a flicker in url on initial loading.
  const debouncedQueryParams = useDebounce(queryParams, 5000);

  const { id: programId } = useProgram();
  const didSetInitialFilters = React.useRef(false);
  const STATIC_FILTERS_MAX = 4;

  // when the report changes, we want to reset the initial filters flag
  const prevReportId = usePrevious(reportId);
  React.useEffect(() => {
    if (prevReportId !== reportId) didSetInitialFilters.current = false;
  }, [prevReportId, reportId]);

  // this is the state and reducer used to track the selected filters
  // we use a React callback to prevent the reducer function from double invoking
  const [filtersStateMap, filtersStateAction] = React.useReducer(
    React.useCallback(filtersStateReducer, []),
    new Map()
  );

  const staticFilters: Filter[] = React.useMemo(() => {
    if (allFilters === undefined) return [];
    // pick the first 4 non-user-attribute filters
    return allFilters
      .filter((filter) => {
        return (
          !isAttributeFilter(filter) &&
          !(isListFilter(filter) && (filter.values?.length || 0) === 0)
        );
      })
      .slice(0, STATIC_FILTERS_MAX);
  }, [allFilters]);

  // debounced version of the filter state, when the state is changing too often
  const debouncedFilterState = useDebounce(filtersStateMap, 400);

  // list of all filters with subfilters appropriately set
  const allFiltersWithSubFilters: Filter[] | undefined = React.useMemo(() => {
    if (allFilters === undefined) return undefined;

    return allFilters.map((filter) => {
      if (!filter.subFilterSlug) return filter;

      // search for a matching subfilter
      const subFilter: Filter | undefined = allFilters.find(
        (subFilterToFind) => subFilterToFind.slug === filter.subFilterSlug
      );
      if (!subFilter) return filter;

      // set matching subFilter as the `subFilter` attribute
      return { ...filter, subFilter };
    });
  }, [allFilters]);

  // construct an object from the filter state
  // all filter selections are saved as sets and will be coerced to arrays
  // if a filter has no selections, then a `null` will be set and the
  // such resulting query string will have an empty string
  // child dropdown components should handle such use cases appropriately
  const getSelectedFiltersStateObj = (): FilterStateObj => {
    const queryObj: FilterStateObj = {};
    filtersStateMap.forEach((filterOption, filterSlug) => {
      const selectedValues = filterOption.selectedValues as Set<
        FilterValue
      > | null;

      queryObj[filterSlug] =
        selectedValues?.size && selectedValues.size > 0
          ? safeTransformSetToArray(selectedValues || new Set())
          : null;
    });
    return queryObj;
  };

  const metabaseFilterQuery = () => {
    const appliedFilters = { ...getSelectedFiltersStateObj() };

    Object.keys(appliedFilters).forEach(
      (filterKey) =>
        appliedFilters[filterKey] || delete appliedFilters[filterKey]
    );
    let query = '';
    Object.keys(appliedFilters).forEach((filter) => {
      if (Array.isArray(appliedFilters[filter])) {
        // eslint-disable-next-line
        // @ts-ignore
        appliedFilters[filter].forEach((value) => {
          query += `&${filter}=${value}`;
        });
      } else {
        query += `&${filter}=${appliedFilters[filter]}`;
      }
    });
    return `?${query.substring(1)}`;
  };

  const getFilterStateObjArray = (): FilterStateObj[] => {
    const queryObjArray: FilterStateObj[] = [];

    filtersStateMap.forEach((filterOption) => {
      const selectedValues = filterOption.selectedValues as Set<
        FilterValue
      > | null;
      const filterObj: FilterStateObj = {};

      filterObj[filterOption.filter.label] =
        selectedValues?.size && selectedValues.size > 0
          ? safeTransformSetToArray(selectedValues || new Set())
          : null;
      queryObjArray.push(filterObj);
    });
    return queryObjArray;
  };

  // WET version of the function above. used for updateFilterState()
  // combining them would cause too many issues. separating out for safety reasons
  const getSelectedFiltersStateWithSingleValuesObj = (
    filtersState?: FiltersStateMap
  ): FilterStateWithSingleValuesObj | undefined => {
    if (filtersState?.size === 0) return undefined;

    const queryObj: FilterStateWithSingleValuesObj = {};
    filtersState?.forEach((filterOption, filterSlug) => {
      const selectedValues = filterOption.selectedValues as Set<
        FilterValue
      > | null;

      const noSelectedValues = selectedValues === null || !selectedValues?.size;

      if (noSelectedValues) queryObj[filterSlug] = null;
      else {
        const filterValuesArray: FilterValue[] = safeTransformSetToArray(
          selectedValues || new Set()
        );
        let filterValue: FilterValue = filterValuesArray[0];

        // some filters may be empty strings. we wil coerce these to nulls
        // otherwise we might trigger a rerender loop with the filter state object
        if (filterValue === '') filterValue = null;
        queryObj[filterSlug] = filterOption.filter.allowMultiple
          ? filterValuesArray
          : filterValue;
      }
    });
    return queryObj;
  };

  const removeEmptyValueFilters = (
    queryObj: FilterStateObj
  ): FilterStateObj => {
    return Object.fromEntries(
      Object.entries(queryObj).filter(
        ([_, value]) => value !== null && value !== undefined
      )
    );
  };

  const getShareableLink = (): string => {
    const queryObj = removeEmptyValueFilters(getSelectedFiltersStateObj());
    const queryString = qs.stringify(queryObj);
    setQueryParms(
      `${routerLocation.protocol}//${routerLocation.host}${routerLocation.pathname}?${queryString}`
    );
    return `${routerLocation.protocol}//${routerLocation.host}${routerLocation.pathname}?${queryString}`;
  };

  // everytime the filters state changes, we want to update the metabase embed url
  React.useEffect(() => {
    const filterStateObj = getSelectedFiltersStateWithSingleValuesObj(
      filtersStateMap
    );
    if (updateFilterState !== undefined) updateFilterState(filterStateObj);
  }, [filtersStateMap, updateFilterState]);

  React.useEffect(() => {
    window.history.pushState(
      { path: debouncedQueryParams },
      '',
      debouncedQueryParams
    );
  }, [debouncedQueryParams]);

  // debounced filter state update
  React.useEffect(() => {
    const debouncedFilterStateObj = getSelectedFiltersStateWithSingleValuesObj(
      debouncedFilterState
    );
    if (updateDebouncedFilterState !== undefined)
      updateDebouncedFilterState(debouncedFilterStateObj);
  }, [updateDebouncedFilterState, debouncedFilterState]);

  // Get the initial filters for a report and update the filter state
  // we must guard against reinitializing the filters otherwise we will
  // trigger an infinite render whenever we call updateFilterState()
  const initialFiltersMap: FiltersStateMap = useInitialFilters(
    allFiltersWithSubFilters,
    deepLinkedParams,
    staticFilters
  );
  React.useEffect(() => {
    if (didSetInitialFilters.current || initialFiltersMap.size === 0) return;
    didSetInitialFilters.current = true;
    filtersStateAction({
      action: 'setAllFilters',
      filtersMap: initialFiltersMap,
    });
  }, [initialFiltersMap, filtersStateMap]);

  const resetFilters = () => {
    setQueryParms(
      `${routerLocation.protocol}//${routerLocation.host}${routerLocation.pathname}`
    );
    filtersStateAction({
      action: 'setAllFilters',
      filtersMap: new Map(),
    });
    didSetInitialFilters.current = false;
  };

  // date range params are needed for the dojo filter api query
  // NOTE: this triggers the filter refreshes via useInsightsFiltersWithUpdatedDojoListValues()
  const dateRange: string | undefined | false = React.useMemo(() => {
    const allowUnboundedDateRanges = !(allFilters || []).find(
      ({ slug }) => slug === 'date_range' || slug === 'date'
    );
    if (allowUnboundedDateRanges) return false;

    // try to find the date range filter
    const dateRangeFilter =
      debouncedFilterState?.get('date_range') ||
      debouncedFilterState?.get('date') ||
      debouncedFilterState?.get('date_input');

    // if date range filters are not ready, then return undefined.
    // downstream, the filters won't load
    if (dateRangeFilter === undefined) return undefined;

    // get the selected or defaulted date range string.
    // if empty string, that means we want to get data for all dates,
    //   which result in very heavy api requests.
    let dateRangeStr = safeTransformSetToArray(
      dateRangeFilter.selectedValues || new Set()
    )[0] as string;

    if (RelativeRange.isValidKey(dateRangeStr)) {
      dateRangeStr = RelativeRange.build(dateRangeStr).toAbsolute().key;
    }

    return dateRangeStr;
  }, [allFilters, debouncedFilterState]);

  // for dojo list filters that need api requests for `values` to be set,
  // make those api requests in a batched fashion
  // if `dateRange == undefined` then no requests will be made
  const [
    updatedFiltersWithDojoListValues,
    updatedFiltersMap,
  ] = useInsightsFiltersWithUpdatedDojoListValues(
    programId,
    reportId,
    allFiltersWithSubFilters,
    dateRange
  );

  // Whenever a filter is loaded, we will update the values for any selected filters
  // we will also set the loading and error attributes in the config object
  React.useEffect(() => {
    filtersStateMap.forEach((filterState, slug) => {
      const updatedFilter = updatedFiltersMap.get(slug);
      if (updatedFilter === undefined) return;

      const updatedFilterConfig: FilterState = Object.assign(filterState, {
        availableListValues: updatedFilter.filter.values,
        isLoading: updatedFilter.isLoading,
        errorMessage: updatedFilter.errorMessage,
      } as Omit<FilterState, 'filter'>);

      filtersStateAction({
        action: 'setFilterState',
        ...updatedFilterConfig,
      });
    });

    // eslint-disable-next-line
  }, [updatedFiltersMap]);

  // construct the array of selected filters.
  const filtersStateArray: FilterState[] = React.useMemo(() => {
    const filterStates = safeTransformMapValuesToArray(filtersStateMap).filter(
      (filterState) => !filterState.filter.isSubFilter
    );
    const dateFilter = filterStates.find(
      ({ filter: { slug } }) => slug === 'date_range' || slug === 'date'
    );

    // put the default date range filter first in the list
    return dateFilter
      ? [
          dateFilter,
          ...filterStates.filter((filterState) => filterState !== dateFilter),
        ]
      : filterStates;
  }, [filtersStateMap]);

  const updatedAttributeFilters: FilterState[] = React.useMemo(() => {
    return (
      updatedFiltersWithDojoListValues?.filter((f) =>
        f.filter.slug.includes('attribute_')
      ) || []
    );
  }, [updatedFiltersWithDojoListValues]);

  // construct the array of unselected filters that are NOT subfilters.
  const unSelectedFilters: FilterState[] = React.useMemo(() => {
    return (
      updatedFiltersWithDojoListValues?.filter((f) => {
        if (
          !PaginatedFilter(f.filter) &&
          f.filter.widgetType === WidgetType.Category &&
          (f.availableListValues?.length || f.filter.values?.length || 0) === 0
        ) {
          return false;
        }
        return !filtersStateMap.has(f.filter.slug) && !f.filter.isSubFilter;
      }) || []
    );
  }, [updatedFiltersWithDojoListValues, filtersStateMap]);

  // get an array of required filters that do not yet have selections made
  // the report page will apply special logic for these
  const requiredFiltersWithNoSelections: FilterState[] = React.useMemo(() => {
    const emptyRequiredFilters: FilterState[] = [];
    filtersStateMap.forEach((f) => {
      const isRequired = !!f.filter.initialRequired;
      const hasNoSelections = (f.selectedValues?.size || 0) === 0;
      if (isRequired && hasNoSelections) emptyRequiredFilters.push(f);
    });
    return emptyRequiredFilters;
  }, [filtersStateMap]);

  return (
    <FiltersStateContext.Provider
      value={{
        filtersStateMap,
        debouncedFilterStateMap: debouncedFilterState,
        filtersStateArray,
        unSelectedFilters,
        updatedAttributeFilters,
        staticFilters,
        requiredFiltersWithNoSelections,
        filtersStateAction,
        dateRange,
        resetFilters,
        getShareableLink,
        getSelectedFiltersStateObj,
        getFilterStateObjArray,
        metabaseFilterQuery,
      }}
    >
      {children}
    </FiltersStateContext.Provider>
  );
};
