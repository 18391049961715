import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionListNumbered: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    onClick={() => editor.chain().focus().toggleOrderedList().run()}
    disabled={!editor.can().chain().focus().toggleOrderedList().run()}
    isActive={editor.isActive('orderedList')}
    icon="format_list_numbered"
    aria-label="Numbered list"
  />
);
