export type FilterCriteria =
  | 'topicsSearch'
  | 'audiencesSearch'
  | 'templatesSearch'
  | 'emailsSearch'
  | 'authorsSearch';

export const initialFiltersSearchState: Record<FilterCriteria, string> = {
  audiencesSearch: '',
  authorsSearch: '',
  emailsSearch: '',
  templatesSearch: '',
  topicsSearch: '',
};
