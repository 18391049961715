import { useState } from 'react';
import { hasAudience, hasTopic, Settings } from 'models/publisher/settings';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useBasicValidator, ValidatorsType } from './useBasicValidator';

const ERRORS = {
  NO_TOPIC_AND_AUDIENCE: 'Select at least one Topic or Audience to continue',
  NOTIFS_AND_DISABLED_ENGAGEMENT_BOOST:
    'Retargeting is not enabled for this campaign. Please remove any configured retargeting notifications.',
};

type ValidatorType = {
  settings: Settings;
};

const validators: ValidatorsType<ValidatorType> = {
  no_topic_and_audience: ({ settings }) =>
    !hasAudience(settings) && !hasTopic(settings),

  notifs_and_disabled_engagement_boost: ({ settings }) => {
    const { notifications, retries, audiences } = settings;
    return notifications.length > 1 && retries === 0 && audiences.length > 0;
  },
};

export const useDeliveryValidator = (): {
  isValid: boolean;
  errors: Array<string>;
  validate: () => boolean;
} => {
  const { settings } = useSettings();
  const validator = useBasicValidator<ValidatorType>(validators, ERRORS);

  const [errors, setErrors] = useState<Array<string>>([]);

  const validate = () => {
    const { errors: errs } = validator.validate({ settings });
    setErrors(errs);
    return errs.length === 0;
  };

  return { isValid: errors.length === 0, errors, validate };
};
