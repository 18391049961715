import * as React from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from '@reach/router';

// There was a bug, TODO find bug and reference it here
// to explain why this exists.
//
// Joey knows the answer, but was too lazy to type it out.
// Feel free to call him out on it.
function parse(queryString: string, options?: qs.IParseOptions) {
  const params = qs.parse(queryString, options);
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const indices = Object.keys(value!);
      if (indices.every((idx) => `${parseInt(idx, 10)}` === idx)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        params[key] = Object.values(value!) as string[];
      }
    }
  });
  return params;
}

function useRawQueryString() {
  const location = useLocation();
  const queryString = location.search || '?';
  const params = parse(queryString, { ignoreQueryPrefix: true });
  const { stringify } = qs;
  return { queryString, params, stringify };
}

type PropsType<T> = {
  key: string;
  cast?: (value: unknown) => T;
  defaultValue?: T;
  boolean?: boolean;
};

type UpdateOptions = {
  replace?: boolean;
};

type QueryStringStateCallback<T> = (
  newValue: T,
  options?: UpdateOptions
) => void;

export function useQueryStringState<T>(
  props: PropsType<T>
): [T, QueryStringStateCallback<T>] {
  const {
    key,
    cast = (value: unknown) => value as T,
    defaultValue,
    boolean = false,
  } = props;

  const queryString = useRawQueryString();
  const navigate = useNavigate();
  const queryValue = queryString.params[key];
  const value = cast(queryValue);
  const update = React.useCallback<QueryStringStateCallback<T>>(
    (newValue, options?) => {
      const newParams = { ...queryString.params, [key]: newValue };
      if (boolean && !newValue) {
        delete newParams[key];
      }
      const newQueryString = `?${queryString.stringify(newParams)}`;

      navigate(newQueryString, options);
    },
    [queryString, key, boolean, navigate]
  );

  if (!queryValue && defaultValue) {
    update(defaultValue);
    return [defaultValue, update];
  }

  return [value, update];
}
