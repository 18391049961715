import React, { createContext, useContext } from 'react';
import { FlashMessageType, FlashMessageKeyType } from 'models/flash-message';
import { FlashMessage } from 'shared/Flasher';
import styles from './flasher.module.css';

type FlashMessageContextData = {
  flashMessages?: FlashMessageType[];
  setFlashMessage: (flashMessage: FlashMessageType) => void;
  dismiss: ({ message, messageKey }: FlashMessageKeyType) => void;
  update: (
    current: FlashMessageKeyType,
    replacement: Omit<FlashMessageType, 'messageKey'>
  ) => void;
  refs: {
    setFlashMessage: React.MutableRefObject<
      FlashMessageContextData['setFlashMessage']
    >;
    dismiss: React.MutableRefObject<FlashMessageContextData['dismiss']>;
    update: React.MutableRefObject<FlashMessageContextData['update']>;
  };
};

const FlashMessagePrototype: FlashMessageContextData = {
  flashMessages: {} as FlashMessageType[],
  setFlashMessage: () => {},
  dismiss: () => {},
  update: () => {},
  refs: {
    setFlashMessage: React.createRef() as FlashMessageContextData['refs']['setFlashMessage'],
    dismiss: React.createRef() as FlashMessageContextData['refs']['dismiss'],
    update: React.createRef() as FlashMessageContextData['refs']['update'],
  },
};

const FlashMessageContext = createContext(FlashMessagePrototype);

export const useFlashMessage = (): FlashMessageContextData => {
  const context = useContext(FlashMessageContext);
  if (context === undefined) {
    throw new Error(
      'Flasher context hooks require a containing FlashMessageContext'
    );
  }
  return context;
};

export const FlashMessageProvider: React.FC = ({ children }) => {
  const [flashMessages, setFlashMessages] = React.useState<FlashMessageType[]>(
    []
  );

  // This is a context provider, so the client code shouldn't need
  // to worry about references or capturing the latest state.
  // A context is effectively a singleton, so we can expect this to
  // deal with the bookkeeping.
  const latest = React.useRef(flashMessages);
  latest.current = flashMessages;

  const dismiss = React.useCallback(
    (message: FlashMessageKeyType) => {
      if (latest.current.find(byMessageKey(message)))
        setFlashMessages((messages) => messages.filter(notMessageKey(message)));
    },
    [latest]
  );
  const dismissRef: FlashMessageContextData['refs']['dismiss'] = React.useRef(
    dismiss
  );
  dismissRef.current = dismiss;

  const setMessage = React.useCallback(
    (message: FlashMessageType) => {
      if (!latest.current.find(byMessageKey(message))) {
        const messages = latest.current;
        if (messages.length > 2)
          messages.slice(0, messages.length - 2).forEach(dismissRef.current);
        setFlashMessages((latestMessages) => [...latestMessages, message]);
      }
    },
    [latest, dismissRef]
  );

  const update = React.useCallback(
    (
      current: FlashMessageKeyType,
      replacement: Omit<FlashMessageType, 'messageKey'>
    ) => {
      const isCurrent = byMessageKey(current);
      if (latest.current.find(isCurrent))
        setFlashMessages(
          latest.current.map((message) =>
            isCurrent(message)
              ? { ...replacement, messageKey: message.messageKey }
              : message
          )
        );
    },
    [latest]
  );

  const setFlashMessageRef: FlashMessageContextData['refs']['setFlashMessage'] = React.useRef(
    setMessage
  );
  const updateRef: FlashMessageContextData['refs']['update'] = React.useRef(
    update
  );
  setFlashMessageRef.current = setMessage;
  updateRef.current = update;

  const value = {
    flashMessages,
    setFlashMessage: setFlashMessageRef.current,
    dismiss: dismissRef.current,
    update: updateRef.current,
    refs: {
      setFlashMessage: setFlashMessageRef,
      dismiss: dismissRef,
      update: updateRef,
    },
  };

  return (
    <FlashMessageContext.Provider value={value}>
      {children}
      {flashMessages.length > 0 && (
        <div className={styles.messagesWrapper}>
          {flashMessages.map((message) => (
            <FlashMessage
              key={message.messageKey || message.message}
              dismissRef={dismissRef}
              updateRef={updateRef}
              message={message}
            />
          ))}
        </div>
      )}
    </FlashMessageContext.Provider>
  );
};

const byMessageKey = (
  { message, messageKey }: FlashMessageKeyType,
  invert?: boolean
): ((message: FlashMessageKeyType) => boolean) => {
  const key = messageKey || message;
  return ({ message: m, messageKey: mk }) => {
    const match = key === (mk || m);
    return invert ? !match : match;
  };
};

const notMessageKey = (message: FlashMessageKeyType) =>
  byMessageKey(message, true);
