import React from 'react';

import { useProgram } from 'contexts/program';
import {
  Filter,
  ParameterizedFilters,
  parameterizeFilters,
} from 'models/language-controls/filter';
import { QueryParameters } from 'services/api-language-controls';

type UseFilters = (
  initialFilters: Filter[]
) => {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  queryParameters: QueryParameters;
  parameterizedFilters: ParameterizedFilters;
};

export const useFilters: UseFilters = (initialFilters) => {
  const { id: programId } = useProgram();
  const [filters, setFilters] = React.useState<Filter[]>([...initialFilters]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const queryParameters: QueryParameters = { programId };

  const parameterizedFilters = parameterizeFilters(
    filters.filter((f) => f.active)
  );
  if (searchTerm) parameterizedFilters.search = searchTerm;

  return {
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    queryParameters,
    parameterizedFilters,
  };
};
