import React from 'react';

export const AltTextTooltip: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div className={className}>
    <p>
      <strong>&lsquo;Alternative text&rsquo;</strong> is an <em>optional</em>{' '}
      brief description of your image used when the image cannot be displayed.
    </p>
    <p>
      This text improves accessibility by allowing screen readers to describe
      images to users with low or no vision.
    </p>
  </div>
);
