import * as React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { ComposeComponent } from 'components/publisher/theme/Compose/Component';
import { Design } from 'components/publisher/pages/Design';
import { Preview } from 'components/publisher/pages/Preview';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import {
  DesignEditor,
  useCampaignHooks,
} from 'components/publisher/theme/Compose/useCampaignHooks';
import { DesignPermission } from 'components/publisher/theme/Compose/DesignPermission';
import { Card } from 'components/publisher/pages/Card';
import { useTemplateAsset } from 'contexts/template-asset';
import { useFontOptions } from 'hooks/useFontOptions';
import { TemplateFooter } from '../TemplateFooter';

const Theme: React.FC<
  RouteComponentProps & {
    designEditor: DesignEditor;
    designPermission: DesignPermission;
  }
> = ({ children, designPermission, designEditor }) => {
  const navigationComponent = <TemplateFooter nextPage="orchestrate" />;

  return (
    <ComposeComponent
      navigationComponent={navigationComponent}
      designPermission={designPermission}
      designEditor={designEditor}
    >
      {children}
    </ComposeComponent>
  );
};

export const Compose: React.FC<RouteComponentProps> = () => {
  const { post, update } = useTemplateAsset();
  const { fontOptions, isLoading: fontsLoading } = useFontOptions();
  const designEditor = useCampaignHooks({ post, update, fontOptions });

  const { data } = useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  );
  const implementation = !data?.value ? 'old' : 'new';
  const { contentPermissions } = useSettings();

  if (fontsLoading) {
    return null;
  }

  return (
    <Router>
      <Theme
        path="/"
        designEditor={designEditor}
        designPermission={contentPermissions}
      >
        {/* Compose sub-pages */}
        <Design path="design" implementation={implementation} />
        <Card path="card" />
        <Preview hideEmailPreview path="preview" />

        {/* The defaults for the directory */}
        <Redirect from="/" to="design" noThrow />
        <Redirect from="*" to="/" noThrow default />
      </Theme>
    </Router>
  );
};
