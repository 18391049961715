import React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Label } from 'shared/Label';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import cx from 'classnames';
import infoStyles from '../info.module.css';
import styles from './orchestrate.module.css';
import { Topics } from './Topics';

export const Orchestrate: React.FC = () => {
  const {
    settings,
    options,
    fields: { notifications },
  } = useSettings();

  const topics = settings.contentTopics;

  const {
    isCommentable,
    isTranslatable,
    isShareable,
    shareMessage,
    isFeatured,
    contentLabel,
    isResource,
    acknowledge,
    initiativeTags,
    contentAuthor,
    emailSenderAlias,
  } = settings;

  const nonEmptyNotifications = (notifications?.value || []).filter(
    (n) => n.text
  );

  const hasAdvancedSection = React.useCallback(() => {
    return (
      isCommentable ||
      isTranslatable ||
      isShareable ||
      isFeatured ||
      isResource ||
      acknowledge ||
      initiativeTags.length > 0 ||
      contentAuthor.displayName.length > 0 ||
      emailSenderAlias ||
      nonEmptyNotifications.length > 0
    );
  }, [
    isCommentable,
    isTranslatable,
    isShareable,
    isFeatured,
    isResource,
    acknowledge,
    initiativeTags,
    contentAuthor,
    emailSenderAlias,
    nonEmptyNotifications,
  ]);

  return (
    <Section title="Orchestrate">
      <Row>
        <Block
          className={cx({
            [styles.templatePriority]: !options.canChangePublicationDate,
          })}
        >
          <Label name="Priority" containerClassName={infoStyles.labelContainer}>
            {settings.priority?.label}
          </Label>
        </Block>
        {options.canChangePublicationDate && (
          <Block>
            <Label
              name="Publish on"
              containerClassName={infoStyles.labelContainer}
            >
              {settings.publishedAt?.toLocaleString() ?? 'Today'}
            </Label>
          </Block>
        )}
        <Block
          className={cx({
            [styles.templatePriority]: !options.canChangePublicationDate,
          })}
        >
          <Label name="Duration" containerClassName={infoStyles.labelContainer}>
            {settings.duration ? `Run for ${settings.duration.label}` : 'N/A'}
          </Label>
        </Block>
      </Row>
      {topics.length > 0 && (
        <Row>
          <Topics topics={topics} />
        </Row>
      )}
      {hasAdvancedSection() && (
        <Row>
          <Block>
            <Label
              name="Advanced"
              containerClassName={styles.advancedContainer}
            >
              {isCommentable && (
                <Label name="Comments" containerClassName={styles.advancedRow}>
                  Enabled
                </Label>
              )}
              {isTranslatable && (
                <Label
                  name="Translations"
                  containerClassName={styles.advancedRow}
                >
                  Enabled
                </Label>
              )}
              {isShareable && (
                <Label name="Shareable" containerClassName={styles.advancedRow}>
                  {shareMessage || 'the title of the content'}
                </Label>
              )}
              {isFeatured && (
                <Label name="Featured" containerClassName={styles.advancedRow}>
                  {contentLabel?.text}
                </Label>
              )}
              {isResource && (
                <Label name="Resources" containerClassName={styles.advancedRow}>
                  Enabled
                </Label>
              )}
              {acknowledge && (
                <Label
                  name="Acknowledge"
                  containerClassName={styles.advancedRow}
                >
                  Enabled
                </Label>
              )}
              {initiativeTags.length > 0 && (
                <Label
                  name="Initiatives"
                  containerClassName={styles.advancedRow}
                >
                  {initiativeTags.map((t) => t.name).join(', ')}
                </Label>
              )}
              {contentAuthor.displayName.length > 0 && (
                <Label name="Author" containerClassName={styles.advancedRow}>
                  {contentAuthor.displayName.length > 0
                    ? contentAuthor.displayName
                    : contentAuthor.defaultDisplayName}
                </Label>
              )}
              {emailSenderAlias && (
                <Label
                  name="Email sender alias"
                  containerClassName={styles.advancedRow}
                >
                  {emailSenderAlias.senderName}{' '}
                  {`<${emailSenderAlias.senderEmail}>`}
                </Label>
              )}
              {nonEmptyNotifications && (
                <Label
                  name="Notifications"
                  containerClassName={styles.advancedRow}
                >
                  {nonEmptyNotifications.map((n) => n.text).join(', ')}
                </Label>
              )}
            </Label>
          </Block>
        </Row>
      )}
    </Section>
  );
};
