import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import {
  NewPersonalizedFieldsFile,
  PersonalizedFieldsFile,
  PersonalizedFieldsAttr,
  IndefiniteDate,
} from 'models/personalized-fields';
import {
  PersonalizedFieldsFileData,
  PersonalizedFieldsAttrData,
  PersonalizedFieldsValidateResult,
  PersonalizedFieldsProcessingErrors,
  uploadPersonalizedFieldsFile,
  validatePersonalizedFieldsFile,
  fetchPersonalizedFieldsFiles,
  fetchPersonalizedFieldsFileById,
  createPersonalizedFieldsFile,
  updatePersonalizedFieldsFile,
  deletePersonalizedFieldsFile,
  processPersonalizedFieldsFile,
  fetchPersonalizedFieldsProcessingErrors,
  exportErrorReportCsv,
} from 'services/api-personalized-fields';
import { QueryResponse, MutationOptions } from './common';

// if this code is still running in 2999, I'm sure skynet will take care of it
const indefinite = '2999-12-31';

export function mapToServerData(
  data: PersonalizedFieldsFile
): PersonalizedFieldsFileData {
  return {
    id: data.id,
    programId: data.programId,
    campaignId: data.campaignId,
    fileName: data.name,
    state: data.state,
    employeeIdentifierType: data.idType,
    totalRows: data.totalRows,
    processedRows: data.processedRows,
    failedRows: data.failedRows,
    expireDate: data.expires?.indefinite
      ? indefinite
      : data.expires?.date.toFormat('yyyy-MM-dd'),
    fileAttributes: data.attributes.map(
      (attr: PersonalizedFieldsAttr): PersonalizedFieldsAttrData => ({
        id: attr.id,
        personalizedFieldFileId: attr.fileId,
        attributeName: attr.name,
        placeholderText: attr.placeholder,
        active: attr.active,
        employeeIdentifier: attr.employeeId,
      })
    ),
    creatorId: data.creatorId,
    updatedAt: data.updatedAt,
  };
}

export function mapFromServerData(
  serverData: PersonalizedFieldsFileData
): PersonalizedFieldsFile {
  let expires;
  const expireDate = serverData.expireDate?.substr(0, 10);
  if (expireDate)
    expires = (expireDate === indefinite
      ? { indefinite: true }
      : { date: DateTime.fromISO(expireDate) }) as IndefiniteDate;
  return {
    id: serverData.id,
    programId: serverData.programId,
    campaignId: serverData.campaignId,
    name: serverData.fileName,
    state: serverData.state,
    idType: serverData.employeeIdentifierType,
    totalRows: serverData.totalRows,
    processedRows: serverData.processedRows,
    failedRows: serverData.failedRows,
    expires,
    attributes: serverData.fileAttributes.map(
      (attr: PersonalizedFieldsAttrData): PersonalizedFieldsAttr => ({
        id: attr.id,
        fileId: attr.personalizedFieldFileId,
        name: attr.attributeName,
        placeholder: attr.placeholderText,
        active: attr.active,
        employeeId: attr.employeeIdentifier,
      })
    ),
    creatorId: serverData.creatorId,
    updatedAt: serverData.updatedAt,
  };
}

export const useUploadPersonalizedFieldsFile = (): {
  uploadFile: (file: File) => Promise<string>;
  validateFile: (name: string) => Promise<PersonalizedFieldsValidateResult>;
} => {
  const { id: programId } = useProgram();
  const { id: campaignId } = usePublisher();
  return {
    uploadFile: async (file: File) => {
      const { filename } = await uploadPersonalizedFieldsFile(
        programId,
        campaignId,
        file
      );
      return filename;
    },
    validateFile: async (name: string) => {
      return validatePersonalizedFieldsFile(programId, campaignId, name);
    },
  };
};

export const usePersonalizedFieldsFilesQuery = (
  campaignId: number | 'new',
  enabled = true
): QueryResponse<PersonalizedFieldsFile[]> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<
    PersonalizedFieldsFileData[],
    Error
  >({
    queryKey: `pffiles-${programId}-${campaignId}`,
    queryFn: () => fetchPersonalizedFieldsFiles(programId, campaignId),
    cacheTime: 0,
    enabled,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.map(mapFromServerData),
  };
};

export const usePersonalizedFieldsFileQuery = ({
  fileId,
  interval,
  enabled,
  key,
}: {
  fileId: number | 'none';
  interval?: number;
  enabled?: boolean;
  key?: string;
}): QueryResponse<PersonalizedFieldsFile> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<
    PersonalizedFieldsFileData | undefined,
    Error
  >({
    queryKey: key || `pffile-${fileId}`,
    queryFn: (): Promise<PersonalizedFieldsFileData | undefined> => {
      if (fileId === 'none') return Promise.resolve(undefined);
      return fetchPersonalizedFieldsFileById(programId, fileId);
    },
    cacheTime: 0,
    refetchInterval: interval,
    enabled,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapFromServerData(data),
  };
};

export const useCreatePersonalizedFieldsFile = ({
  onSuccess,
  onError,
}: MutationOptions<PersonalizedFieldsFile, Error> = {}): {
  create: (data: NewPersonalizedFieldsFile) => void;
} => {
  const { id: programId } = useProgram();
  return {
    create(data: NewPersonalizedFieldsFile) {
      createPersonalizedFieldsFile(programId, data)
        .then((response: PersonalizedFieldsFileData) => {
          onSuccess?.(response && mapFromServerData(response));
        })
        .catch((error) => onError?.(error));
    },
  };
};

export const useUpdatePersonalizedFieldsFile = ({
  onSuccess,
  onError,
}: MutationOptions<number, Error> = {}): {
  updateFile: (file: PersonalizedFieldsFile) => void;
} => {
  const { id: programId } = useProgram();
  return {
    updateFile(file: PersonalizedFieldsFile) {
      const data = mapToServerData(file);
      updatePersonalizedFieldsFile(programId, data)
        .then((result) => onSuccess?.(result))
        .catch((error) => onError?.(error));
    },
  };
};

export const useDeletePersonalizedFieldsFile = ({
  onSuccess,
  onError,
}: MutationOptions<number, Error> = {}): {
  deleteFile: (fileId: number) => void;
} => {
  const { id: programId } = useProgram();
  return {
    deleteFile(fileId: number) {
      deletePersonalizedFieldsFile(programId, fileId)
        .then((result) => onSuccess?.(result))
        .catch((error) => onError?.(error));
    },
  };
};

export const useProcessPersonalizedFieldsFile = (): {
  processFile: (fileId: number) => Promise<number>;
  processingErrors: (
    fileId: number
  ) => Promise<PersonalizedFieldsProcessingErrors>;
} => {
  const { id: programId } = useProgram();
  return {
    processFile: async (fileId: number) => {
      return processPersonalizedFieldsFile(programId, fileId);
    },
    processingErrors: async (fileId: number) => {
      return fetchPersonalizedFieldsProcessingErrors(programId, fileId);
    },
  };
};

export const useGenerateErrorReport = ({
  onSuccess,
  onError,
}: MutationOptions<Blob, Error> = {}): {
  downloadReport: (fileId: number) => void;
} => {
  const { id: programId } = useProgram();
  return {
    downloadReport(fileId: number) {
      exportErrorReportCsv(programId, fileId)
        .then((result) => onSuccess?.(result))
        .catch((error) => onError?.(error));
    },
  };
};
