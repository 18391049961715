import qs from 'qs';
import { useLocation } from '@reach/router';

// copied from bossanova codebase. original comment:
// The QS library has a limitation, put there as a defense against malicious input.
// If the user enters key[999999999]=something, the QS library tries to avoid
// creating a huge array and iterating over it. So anything that has a numerical
// key over 20 (the QS default) gets converted into an object.
//
// The problem is that we have legitimate reasons to allow for dozens and dozens
// of values. There is an option to bump up that `depthLimit` as an option, but
// that feels like it is punting on the problem. We'll can remove the arbitrary
// limit, and still keep the safeguard, by detecting an object that has all
// numerical-looking keys, then just flatten the values into an array.
function parse<T>(queryString: string, options?: qs.IParseOptions): T {
  const params = qs.parse(queryString, options);
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const indices = Object.keys(value!);
      if (indices.every((idx) => `${parseInt(idx, 10)}` === idx)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        params[key] = Object.values(value!) as string[];
      }
    }
  });
  return (params as unknown) as T;
}

// copied from hooks/useQueryStringState.ts
// unable to export `useRawQueryString` because it caused too many type errors
// duplicating for insights purposes only
export function useParsedQueryParams<T>(): {
  queryString: string;
  params: T;
} {
  const location = useLocation();
  const queryString = location.search || '?';
  const params = parse<T>(queryString, { ignoreQueryPrefix: true });
  return { queryString, params };
}
