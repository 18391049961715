import * as React from 'react';
import cx from 'classnames';
import { useUser } from 'contexts/user';
import { useProgram } from 'contexts/program';
import { BooleanFilter, FiltersStateType } from 'contexts/people/filters';
import { RolesFilter } from 'components/roles/RoleSelect/Filter';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Audience } from 'models/audience';
import { LitmusRole } from 'models/role';
import { Close } from 'shared/icons';
import { FilterDropdown } from 'shared/FilterDropdown';
import filtersStyles from 'DesignSystem/Table/filters.module.css';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { NewFiltersButton } from './NewFiltersButton';
import { AudiencesFilter } from './AudiencesFilter';
import { RestrictionsFilter } from './RestrictionsFilter';
import { Scope } from './scope';

export const useOnMount = (func: React.EffectCallback): void => {
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    if (isMounted) {
      func();
    }
    // eslint-disable-next-line
  }, []);
};

type FilterProps = {
  // eslint-disable-next-line
  onChange: (filterName: string, value: any) => void;
  filters: FiltersStateType;
  statuses?: string[];
  roles?: LitmusRole[];
  roleNames?: string[];
  audiences?: Audience[];
  scopes?: Scope;
  setBooleanValue: (name: string, value: boolean) => void;
};

export const Filters: React.FC<FilterProps> = ({
  children,
  onChange,
  filters,
  statuses,
  roles,
  roleNames,
  audiences,
  scopes,
  setBooleanValue,
}) => {
  const { id: programId } = useProgram();

  useOnMount(() => {
    if (statuses) {
      setBooleanValue('statuses', true);
    }

    if (roleNames) {
      setBooleanValue('roles', true);
    }
  });

  const {
    data: permissionsFlag,
    isLoading: permissionsFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Permissions.Service');
  const { superAdmin } = useUser();
  const ROLES = React.useMemo(() => {
    let options = [
      { value: 'member', label: 'Member' },
      { value: 'analyst', label: 'Analyst' },
      { value: 'channel_contributor', label: 'Topic Manager' },
      { value: 'publisher', label: 'Publisher' },
      { value: 'program_admin', label: 'Community Manager' },
      { value: 'administrator', label: 'Administrator' },
    ];

    if (superAdmin) {
      options = [...options, { value: 'super_admin', label: 'Super Admin' }];
    }
    return options;
  }, [superAdmin]);

  const STATUSES = React.useMemo(() => {
    let options = [
      { value: 'created', label: 'Created' },
      { value: 'blocked', label: 'Blocked' },
      { value: 'registering', label: 'Registering' },
      { value: 'registered', label: 'Registered' },
      { value: 'invited', label: 'Invited' },
    ];

    if (superAdmin) {
      options = [...options, { value: 'hidden', label: 'Hidden' }];
    }

    return options;
  }, [superAdmin]);

  const keys = Object.keys(filters.boolean);

  const unselectedFilters = keys
    .filter((k) => !filters.boolean[k].value)
    .map((k) => filters.boolean[k]);

  const handleBooleanFilter = React.useCallback(
    (pr, value) => {
      setBooleanValue(pr, value);
    },
    [setBooleanValue]
  );

  const selectedFilters = keys
    .filter((k) => filters.boolean[k].value)
    .map((k) => filters.boolean[k]);

  const closeSourcesFilter = React.useCallback(
    (filterName) => {
      onChange(filterName, []);
      handleBooleanFilter(filterName, false);
    },
    [onChange, handleBooleanFilter]
  );

  const triggerClassNames = React.useMemo(
    () => ({
      customTrigger: styles.booleanTrigger,
      filterTrigger: filtersStyles.FilterTrigger,
      appliedTrigger: filtersStyles.FilterTriggerApplied,
      closeButton: filtersStyles.FilterTriggerCloseButton,
    }),
    []
  );

  return (
    <div className={styles.filtersWrapper}>
      <div>{children}</div>
      {unselectedFilters?.length > 0 && (
        <div className={styles.AllFilters}>
          <NewFiltersButton
            filters={unselectedFilters}
            onSelectFilter={(filter) => handleBooleanFilter(filter.name, true)}
          />
        </div>
      )}
      {selectedFilters.map((f: BooleanFilter) => {
        if (f.name === 'statuses') {
          return (
            <FilterDropdown
              key={f.name}
              label="Status"
              onChange={(value) => onChange('statuses', value)}
              options={STATUSES}
              selectedOptions={STATUSES.filter((opt) =>
                statuses?.includes(opt.value)
              )}
              onClose={() => closeSourcesFilter(f.name)}
            />
          );
        }

        if (f.name === 'roles' && !permissionsFlagLoading) {
          if (permissionsFlag?.value) {
            return (
              <RolesFilter
                key={f.name}
                onChange={(value) => onChange('roles', value)}
                value={roles || []}
                onClose={() => closeSourcesFilter(f.name)}
              />
            );
          }

          return (
            <FilterDropdown
              key={f.name}
              label="Role"
              onChange={(value) => onChange('roles', value)}
              options={ROLES}
              selectedOptions={ROLES.filter((opt) =>
                roleNames?.includes(opt.value)
              )}
              onClose={() => closeSourcesFilter(f.name)}
            />
          );
        }

        if (f.name === 'audiences') {
          return (
            <AudiencesFilter
              key={f.name}
              onChange={(value) => onChange('audiences', value)}
              value={audiences || []}
              onClose={() => closeSourcesFilter(f.name)}
            />
          );
        }

        if (f.name === 'scopes') {
          return (
            <RestrictionsFilter
              key={f.name}
              onChange={(value) => onChange('scopes', value)}
              scopes={
                scopes || {
                  audiences: [],
                  authorAliases: [],
                  emailAliases: [],
                  templates: [],
                  topics: [],
                }
              }
              closeFilter={() => handleBooleanFilter(f.name, false)}
            />
          );
        }

        return (
          <div
            key={f.name}
            className={cx(
              triggerClassNames.filterTrigger,
              triggerClassNames.appliedTrigger,
              triggerClassNames.customTrigger
            )}
          >
            <div>{f.label}</div>
            <button
              className={triggerClassNames.closeButton}
              onClick={() => handleBooleanFilter(f.name, false)}
              type="button"
            >
              <Close />
            </button>
          </div>
        );
      })}
    </div>
  );
};
