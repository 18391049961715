import * as React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import styles from './month.module.css';
import { DateRange } from './DateRange';

type PropsType = {
  className?: string;
  dateRange?: DateRange;
  month: DateTime;
  onDateClick: (date: DateTime) => void;
  hideWeekend?: boolean;
};

/* Ignoring some accessibility concerns here in order to make a better experience.
   Dates can be changed via arrow buttons in AbsoluteControl, making every day of
   two months a button would make tab navigation a nightmare. */
/* eslint-disable  jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-static-element-interactions */
export const Weeks: React.FC<PropsType> = (props) => {
  const { className, dateRange, month, onDateClick, hideWeekend } = props;
  const splitIntoWeeks = React.useCallback((arr) => {
    const result = [];
    for (let i = 0; i < arr.length; i += 7) {
      const tempArray = arr.slice(i, i + 7);
      result.push(tempArray);
    }
    return result;
  }, []);

  return (
    <div className={cx(styles.month, className)}>
      <div>
        <span className={styles.dayHead}>S</span>
        <span className={styles.dayHead}>M</span>
        <span className={styles.dayHead}>T</span>
        <span className={styles.dayHead}>W</span>
        <span className={styles.dayHead}>T</span>
        <span className={styles.dayHead}>F</span>
        <span className={styles.dayHead}>S</span>
      </div>
      <div>
        {(() => {
          /* force luxon week to start on sunday instead of monday */
          const mo_first = month.startOf('month').startOf('day');
          const cal_first = mo_first
            .startOf('week')
            .minus({ days: 1 })
            .startOf('day');
          const mo_last = month.endOf('month').endOf('day');
          const cal_last =
            mo_last.weekday === 7
              ? mo_last
                  .endOf('week')
                  .plus({ days: 1 })
                  .endOf('week')
                  .endOf('day')
              : mo_last.endOf('week').endOf('day');
          const spans = [];
          let current = cal_first.set({ hour: 7 });

          while (!current.hasSame(cal_last, 'day')) {
            const classNames = [styles.daySpan, styles.weekDaySpan];

            if (current < mo_first || current > mo_last) {
              classNames.push(styles.outOfMonth);
            }

            if (dateRange) {
              const abs = dateRange.toAbsolute();
              if (current > abs.start && current < abs.end) {
                // hightlight the selected week
                if (
                  (hideWeekend &&
                    !['6', '7'].includes(current.toFormat('c'))) ||
                  !hideWeekend
                ) {
                  classNames.push(cx([styles.inRange, styles.weekInRange]));
                }
                // start of the week
                if (
                  (hideWeekend && current.toFormat('c') === '1') ||
                  (!hideWeekend && current.toFormat('c') === '7')
                ) {
                  classNames.push(cx([styles.startOfWeek]));
                }
                // end of the week
                if (
                  (hideWeekend && current.toFormat('c') === '5') ||
                  (!hideWeekend && current.toFormat('c') === '6')
                ) {
                  classNames.push(cx([styles.endOfWeek]));
                }
              }
            }
            spans.push(
              <span
                className={cx(classNames)}
                key={spans.length}
                onClick={((date) => () => onDateClick(date))(current)}
              >
                <span>{current.day}</span>
              </span>
            );
            current = current.plus({ day: 1 });
          }
          const spanGroups = splitIntoWeeks(spans);
          return spanGroups.map((g: Array<ReactNode>, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`week-group-${i}`} className={styles.week}>
              {g.map((s) => s)}
            </div>
          ));
        })()}
      </div>
    </div>
  );
};
