import React, { FC } from 'react';
import { DateTime } from 'luxon';
import { DateRangeValue, DateRange } from 'shared/DateRange';
import { defaultDateRangeInputFormat } from 'shared/DateRange/date-range-db-format';

type DateRangeFilterProps = {
  value: [string, string];
  onChange: (values: [string, string]) => void;
  filterRangeFormat?: string;
  maxDate?: DateTime;
  minDate?: DateTime;
};

export const DateRangeFilter: FC<DateRangeFilterProps> = ({
  value,
  onChange,
  filterRangeFormat = defaultDateRangeInputFormat,
  maxDate,
  minDate,
}) => {
  const dates: DateRangeValue = [
    DateTime.fromFormat(value[0], filterRangeFormat),
    DateTime.fromFormat(value[1], filterRangeFormat),
  ];

  const handleDateChange = (newDates: DateRangeValue) => {
    onChange(
      newDates.map((newDate) => newDate.toFormat(filterRangeFormat)) as [
        string,
        string
      ]
    );
  };

  return (
    <DateRange
      maxDate={maxDate}
      minDate={minDate}
      dates={dates}
      onDateChange={handleDateChange}
    />
  );
};
