import React, { ComponentProps } from 'react';

type CharacterCounterProps = {
  count: number;
  maxCharacters: number;
};

export const CharacterCounter: React.FC<
  ComponentProps<'span'> & CharacterCounterProps
> = ({ count, maxCharacters, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <span {...rest}>
    {count}/{maxCharacters} characters
  </span>
);
