import React from 'react';
import { useOrchestrationInsights } from 'hooks/orchestration-insights';
import { Settings } from 'models/publisher/settings';
import styles from './orchestration-insights.module.css';

export const Insights: React.FC<{ settings: Settings; contentId?: number }> = ({
  settings,
  contentId,
}) => {
  const { insights } = useOrchestrationInsights(settings, contentId);

  if (insights.length === 0) return <div>...</div>;

  return (
    <table className={styles.insights}>
      <tbody>
        {insights.map((insight) => (
          <tr key={insight.label}>
            <th>{insight.label}</th>
            <td>
              {insight.items.map((item) => {
                return (
                  <p key={item.channelDescriptor}>
                    <strong>{item.percentage}%</strong> of users will receive{' '}
                    {item.channelDescriptor}
                  </p>
                );
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
