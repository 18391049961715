import { TLinkItem } from 'models/shortcut';
import { FieldValidationError } from '../types';

// This regex is based on RFC1738 section 5. Namely the BNF for hostname.
const VALID_HOSTNAME_REGEX = /^[\w\d.-]*$/i;
function validateUrl(url: string): boolean {
  try {
    const urlObj = new URL(url);
    return Boolean(urlObj) && VALID_HOSTNAME_REGEX.test(urlObj.hostname);
  } catch (e) {
    return false;
  }
}

export function validateLink(value: TLinkItem): FieldValidationError {
  const validUrl = validateUrl(value.url ?? '');
  const validName = (value.label ?? '').length > 1;

  const error: FieldValidationError = {};

  if (!validUrl && value.url) {
    error.url = {
      message: 'Please provide valid URL including scheme',
    };
  }

  if (!validName && value.label) {
    error.name = {
      message: 'Please provide a name with at least 2 characters',
    };
  }

  return error;
}
