import React, { RefObject } from 'react';
import { InfiniteList } from './InfiniteList';
import { ROW_HEIGHT } from './BannerListItem';
import { AutoHeight } from './AutoHeight';

type PropsType = {
  itemCount: number;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  threshold?: number;
  noItemsComponent?: React.ReactNode;
  children: (index: number) => React.ReactNode;
  height?: number;
  parentRef?: RefObject<HTMLDivElement>;
  rowHeight?: number;
};

export const InfiniteBannerList: React.FC<PropsType> = (props) => {
  const {
    itemCount,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    noItemsComponent,
    children,
    parentRef,
    height,
    rowHeight = ROW_HEIGHT,
  } = props;

  return (
    <AutoHeight>
      {(autoHeight: number) => (
        <InfiniteList
          itemCount={itemCount}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          threshold={threshold}
          noItemsComponent={noItemsComponent}
          itemHeight={rowHeight}
          height={height || autoHeight}
          parentRef={parentRef}
        >
          {children}
        </InfiniteList>
      )}
    </AutoHeight>
  );
};
