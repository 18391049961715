import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';

export enum TabType {
  Content = 'content',
  Design = 'design',
  Section = 'section',
}

export const ContentDesignTabs: React.FC<{
  active: TabType;
  activate(tab: TabType): void;
  // hide certain tab in certain cases
  // such as hiding the "content" tab for blocks that don't have an editor
  visibleTabs?: Array<TabType>;
}> = ({ active, activate, visibleTabs }) => {
  const toOtherTab = React.useCallback(
    (toTab: TabType) => {
      activate(toTab);
    },
    [activate]
  );

  const showSectionTab = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.BlockTargets'
  ).data?.value;

  const showContentTab = React.useMemo(() => {
    if (!visibleTabs) {
      return true;
    }

    if (visibleTabs && visibleTabs.length > 0) {
      return visibleTabs.includes(TabType.Content);
    }

    return true;
  }, [visibleTabs]);

  const showDesignTab = React.useMemo(() => {
    if (!visibleTabs) {
      return true;
    }

    if (visibleTabs && visibleTabs.length > 0) {
      return visibleTabs.includes(TabType.Design);
    }

    return true;
  }, [visibleTabs]);
  return (
    <Flex start>
      {showContentTab && (
        <Tab
          label="Content"
          onChange={toOtherTab}
          active={active === TabType.Content}
        />
      )}
      {showDesignTab && (
        <Tab
          label="Design"
          onChange={toOtherTab}
          active={active === TabType.Design}
        />
      )}
      {showSectionTab && (
        <Tab
          label="Section"
          onChange={toOtherTab}
          active={active === TabType.Section}
        />
      )}
    </Flex>
  );
};

function Tab({
  active,
  label,
  onChange,
}: {
  active: boolean;
  label: string;
  onChange(toTab: TabType): void;
}) {
  if (active) return <ActiveTab label={label} />;
  return (
    <InActiveTab
      onChange={() => onChange(label.toLowerCase() as TabType)}
      label={label}
    />
  );
}

function ActiveTab({ label }: { label: string }) {
  return (
    <FlexItem
      widen
      style={{
        borderBottom: 'solid 2px var(--color-brandSolar)',
      }}
    >
      <Label>{label}</Label>
    </FlexItem>
  );
}

function InActiveTab({
  label,
  onChange,
}: {
  label: string;
  onChange(toTab: TabType): void;
}) {
  return (
    <FlexItem
      widen
      style={{
        borderBottom: 'solid 2px var(--color-gray10)',
      }}
    >
      <Label onChange={() => onChange(label.toLowerCase() as TabType)}>
        {label}
      </Label>
    </FlexItem>
  );
}

const Label: React.FC<{ onChange?: () => void }> = ({ children, onChange }) => (
  <div
    role="button"
    tabIndex={0}
    onKeyPress={onChange}
    onClick={onChange}
    style={{
      cursor: onChange ? 'pointer' : 'default',
      lineHeight: '60px',
      width: '100%',
      textAlign: 'center',
    }}
  >
    <Type.Body color={Type.color.gray90}>{children}</Type.Body>
  </div>
);
