import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Span, styles } from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components/Box';
import { Pills } from 'DesignSystem/Components/Pills';
import { Topic } from 'models/topic';
import { WhitePillClass } from 'DesignSystem/Components/Pill';
import { useSuggestedTopics } from 'hooks/useSuggestedTopics';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { usePublisher } from 'contexts/publisher';
import { InfoTooltip } from '../Shared/InfoTooltip';
import {
  AISparkles,
  ExclamationTriangleOutlined,
} from '../../../../../../shared/icons';
import moduleStyles from './suggest-topics.module.css';

interface SuggestTopicsProps {
  programId: number;
  onSelect: (topic: Topic) => void;
  selectedTopics: Array<Topic>;
}

const Bold = Span(styles.Bold);

const renderPill = (value: Topic) => (
  <>
    {value.name}&nbsp;<Bold>·</Bold>&nbsp;{value.followerCount}
  </>
);

export const SuggestTopics: React.FC<SuggestTopicsProps> = ({
  programId,
  onSelect,
  selectedTopics,
}) => {
  const [suggestedTopicChoices, setSuggestedTopicChoices] = useState<
    Array<Topic>
  >();
  const [message, setMessage] = useState<string | null>(null);
  const { post } = usePublisher();
  const contentId = post.content.id;
  const { suggestedTopics, isLoading, isError } = useSuggestedTopics(
    programId,
    contentId
  );

  useEffect(() => {
    if (suggestedTopics) {
      const remaining = suggestedTopics.filter(
        (topic: Topic) =>
          !selectedTopics?.some(
            (selectedTopic: Topic) =>
              selectedTopic.id.toString() === topic.id.toString()
          )
      );
      setSuggestedTopicChoices(remaining);
    }
  }, [suggestedTopics, selectedTopics]);

  useEffect(() => {
    if (!isLoading) {
      if (isError) {
        setMessage(`Cannot reach AI service at this time. Please try again.`);
      } else if (suggestedTopics?.length === 0) {
        setMessage(`There are no topic suggestions.`);
      } else if (!suggestedTopicChoices?.length) {
        setMessage(`That's all the suggestions for now!`);
      } else {
        setMessage(null);
      }
    }
  }, [suggestedTopics, suggestedTopicChoices, isError, isLoading]);

  return (
    <Box>
      <div className={moduleStyles.titleContainer}>
        <div className={moduleStyles.sparklesContainer}>
          <AISparkles />
        </div>
        <div className={moduleStyles.title}>Suggested</div>
        <div className={moduleStyles.infoContainer}>
          <InfoTooltip message="Suggested Topics compares campaign content to your community's topic names and descriptions, and then suggests the best options." />
        </div>
      </div>
      <Box className={moduleStyles.suggestedTopics}>
        {isLoading && (
          <div className={moduleStyles.spinner}>
            <LoadingSpinner size="large" />
          </div>
        )}
        {message && message.length > 0 && (
          <div className={moduleStyles.messageContainer}>
            {isError && (
              <ExclamationTriangleOutlined
                className={moduleStyles.warningSign}
              />
            )}
            <div
              className={classNames(moduleStyles.message, {
                [moduleStyles.error]: isError,
                [moduleStyles.info]: !isError,
              })}
            >
              {message}
            </div>
          </div>
        )}
        {suggestedTopicChoices && suggestedTopicChoices?.length > 0 && (
          <div>
            <Pills
              values={suggestedTopicChoices}
              render={renderPill}
              pillClassName={WhitePillClass}
              onClose={(selectedTopic: Topic) => {
                onSelect(selectedTopic);
              }}
              closeButtonContents="+"
              largeCloseButton
            />
          </div>
        )}
      </Box>
    </Box>
  );
};
