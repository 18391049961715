import React from 'react';
import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { BulkUploadJob } from 'models/bulk-upload-job';
import {
  useGenerateErrorReport,
  useGenerateJobReport,
} from 'hooks/user-imports';
import { CSVDownloader } from 'react-papaparse';
import { userImportToMenuItems, userImportToThumbnail } from './mappings';
import { generateCompletionString, generateStatString } from '../shared/helper';
import styles from '../shared/user-imports.module.css';

export type PropsType = {
  data: BulkUploadJob;
};

const formatDate = (d: string | undefined) => {
  return d ? DateTime.fromJSDate(new Date(d)) : undefined;
};

export const UserImportBanner: React.FC<PropsType> = ({ data }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const thumbnail = userImportToThumbnail(data);
  const { description, processed } = generateStatString(data);
  const showCompletionTime =
    data.status === 'complete' || data.status === 'partial';
  const createdAt = formatDate(data.createdAt)?.toFormat('MMM dd, yyyy hh:mma');
  const completionTime = generateCompletionString(
    data.createdAt || '',
    data.processedAt || ''
  );

  const triggerButton = React.useRef<HTMLButtonElement | null>(null);
  const bareFileName = data.fileName.replace('.csv', '');
  const [file, setFile] = React.useState<{
    title: string;
    rows: string;
  }>({
    title: '',
    rows: '',
  });

  const { download: downloadFile } = useGenerateJobReport(programId, {
    onSuccess: (rows: string) => {
      setFile({
        title: bareFileName,
        rows,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      triggerButton.current!.click();
    },
    onError: (errorMessage) => {
      setFlashMessage({ message: errorMessage, severity: 'error' });
    },
  });

  const { download: downloadErrorFile } = useGenerateErrorReport(programId, {
    onSuccess: (rows: string) => {
      setFile({
        title: `${bareFileName}-rejectedrows`,
        rows,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      triggerButton.current!.click();
    },
    onError: (errorMessage) => {
      setFlashMessage({ message: errorMessage, severity: 'error' });
    },
  });

  const menuItems = userImportToMenuItems(
    data,
    programId,
    downloadFile,
    downloadErrorFile
  );

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`user-import-${data.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{data.fileName}</div>
          <p className={styles.description} title={data.status}>
            {description}
          </p>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>TIME</span>
          <span className={styles.stats}>{createdAt || 'NA'}</span>
          <span className={styles.subStats}>
            {showCompletionTime && completionTime}
          </span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>USER</span>
          <span className={styles.stats}>{data.owner || 'NA'}</span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>RECORDS PROCESSED</span>
          <span className={styles.stats}>{processed || 'NA'}</span>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            style={{ visibility: 'hidden' }}
          >
            <CSVDownloader data={file.rows} filename={file.title} type="link">
              <button ref={triggerButton} type="button" className="csv-trigger">
                .
              </button>
            </CSVDownloader>
          </button>
        </div>
      </div>
    </EmptyBanner>
  );
};
