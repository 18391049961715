import camelcaseKeys from 'camelcase-keys';
import qs from 'qs';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  ids: Array<number>;
};

export type ReachCollectionData = {
  data: Array<ReachData>;
};

export type ReachData = {
  id: number;
  reach: number;
};

export const fetchReach = async (
  props: FetchProps
): Promise<ReachCollectionData> => {
  const { programId, ids } = props;

  if (ids.length === 0) return { data: [] };

  const query = qs.stringify({ ids }, { arrayFormat: 'comma' });

  const url = `${apiRoot}/samba/programs/${programId}/contents/planner/reach?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching content: ${response.status}`);
};
