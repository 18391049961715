import * as React from 'react';
import { Icon } from 'shared/Icon';
import { useCardEditor } from 'contexts/publisher/compose/card';
import { Mobile as Container } from 'components/publisher/pages/Preview/Mobile';
import { CardPreview } from '../CardPreview';
import styles from './mobile.module.css';

export const Mobile: React.FC = () => {
  const { cta } = useCardEditor();

  return (
    <Container>
      <div className={styles.mobile}>
        <div className={styles.data}>
          <CardPreview styles={styles} viewType="mobile" />
          <div>
            {cta.title && (
              <>
                <div
                  dir="auto"
                  className={styles.title}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: cta.title }}
                />
              </>
            )}
            {cta.summary && (
              <div
                dir="auto"
                className={styles.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: cta.summary }}
              />
            )}
          </div>
        </div>
        <div className={styles.meta}>
          <div>
            <Icon iconType="SVG" iconName="Heart" />
            <span>123 likes</span>
          </div>
          <div>
            <Icon iconType="SVG" iconName="Comment" />
            <span>10 comments</span>
          </div>
        </div>
      </div>
    </Container>
  );
};
