import React, { RefObject, useState, useEffect, useCallback } from 'react';
import { ContentFiltersContext } from 'contexts/content/filters';
import { usePermissions } from 'contexts/permissions';
import { ContentOrPollBanner } from 'components/content/ContentBanner';
import { BulkSelection } from 'hooks/common';
import { useContentsInfiniteQuery } from 'hooks/content';
import { useReachByContentQueries } from 'hooks/reach';
import {
  BulkProcessingState,
  isContent,
  isProcessing,
  PublicationState,
} from 'models/content';
import { ContentPage } from 'models/content-page';
import { FetchProps } from 'services/api-content';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { useBulkAction } from 'hooks/useBulkAction';
import { ProgressConfirmationModal } from 'shared/Modal/ProgressConfirmationModal';
import { ProcessCancellationConfirmation } from 'shared/Modal/ProgressCancellationConfirmation';

const FETCH_INTERVAL = 2000;

type PropsType = {
  fetchProps: FetchProps;
  height: number;
  parentRef: RefObject<HTMLDivElement>;
  bulkProcessingState: BulkProcessingState;
  setBulkProcessingState: (state: BulkProcessingState) => void;
  pageName: PublicationState;
};

export const ContentList: React.FC<PropsType> = ({
  fetchProps,
  height,
  parentRef,
  bulkProcessingState,
  setBulkProcessingState,
  pageName: currentState,
}) => {
  const [fetchInterval, setFetchInterval] = useState<number | false>(
    FETCH_INTERVAL
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelProcessModalIsOpen, setCancelProcessModalIsOpen] = useState(
    false
  );
  const setIsBulkSelectMode = useState(false)[1];

  const { filters } = React.useContext(ContentFiltersContext);
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data,
    meta,
  } = useContentsInfiniteQuery(fetchProps, filters, fetchInterval);

  const onBulkActionSuccess = useCallback(() => {
    setModalOpen(true);
  }, []);
  const onBeforeBulkActionCancellation = useCallback(() => {
    setCancelProcessModalIsOpen(true);
  }, []);
  const onJobCompletion = useCallback(() => {
    setModalOpen(false);
    setCancelProcessModalIsOpen(false);
  }, []);

  const {
    bulkPublish,
    bulkArchive,
    cancelBulkProcess,
    activeJob,
    isBulkActionApplied,
    processingContents,
    currentBulkAction,
    isProcessing: isActionProcessing,
  } = useBulkAction({
    currentState,
    bulkProcessingState,
    setBulkProcessingState,
    onBulkActionSuccess,
    onBeforeBulkActionCancellation,
    onJobCompletion,
  });

  const content = data.flatMap((page: ContentPage) => page.data);
  const reachByContent = useReachByContentQueries(fetchProps.programId, data);
  const { permissions } = usePermissions();

  const bulkActions = [];

  const isDraftsTab = React.useMemo(() => {
    return currentState === 'draft';
  }, [currentState]);

  if (permissions.publishCampaign && currentState !== 'published') {
    bulkActions.push({
      label: 'Publish',
      onClick: (bulkSelection: BulkSelection) =>
        bulkPublish(bulkSelection, fetchProps, filters),
    });
  }

  if (
    currentState !== 'archived' &&
    (permissions.publishCampaign || isDraftsTab)
  )
    bulkActions.push({
      label: 'Archive',
      onClick: (bulkSelection: BulkSelection) =>
        bulkArchive(bulkSelection, fetchProps, filters),
    });

  useEffect(() => {
    if (content.find((el) => isContent(el) && isProcessing(el))) {
      setFetchInterval(FETCH_INTERVAL);
    } else {
      setFetchInterval(false);
    }
  }, [content, setFetchInterval]);

  return (
    <BulkSelectWrapper
      disabled={!bulkActions.length}
      totalRecords={meta?.totalRecords || 0}
      itemName="post"
      actions={[
        {
          type: 'select',
          groupLabel: 'Actions',
          actions: bulkActions,
        },
      ]}
      setIsBulkSelectMode={setIsBulkSelectMode}
      allowSelection={!activeJob && !isBulkActionApplied}
      clearOnFilterUpdate
    >
      <InfiniteBannerList
        isLoading={isLoading}
        itemCount={content.length}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        height={height}
        parentRef={parentRef}
      >
        {(index: number) => {
          const processingState = isBulkActionApplied
            ? processingContents[`${content[index].id}`]?.status
            : undefined;
          return (
            <ContentOrPollBanner
              item={content[index]}
              reachByContent={reachByContent}
              processingState={processingState}
              isSelectable={!processingState}
              disableContentActions={!!processingState}
            />
          );
        }}
      </InfiniteBannerList>
      <ProgressConfirmationModal
        title="Bulk Change"
        body={
          isActionProcessing ? (
            <div style={{ textAlign: 'center' }}>
              Preparing campaigns for processing.
              <br />
              Please don&#39;t refresh the page.
            </div>
          ) : (
            'Your changes are in progress. You can close the dialog and they will continue in background'
          )
        }
        isOpen={isActionProcessing || modalOpen}
        setIsOpen={setModalOpen}
        description={
          isActionProcessing
            ? 'Preparing...'
            : `${currentBulkAction} campaigns...`
        }
        hideAction={isActionProcessing}
      />
      <ProcessCancellationConfirmation
        title="Stop Processing?"
        description="No more campaigns will be processed. Any campaigns that have
        already processed will not be undone."
        isOpen={cancelProcessModalIsOpen}
        setIsOpen={setCancelProcessModalIsOpen}
        onConfirm={cancelBulkProcess}
      />
    </BulkSelectWrapper>
  );
};
