import React from 'react';
import cx from 'classnames';
import { Icon } from 'shared/Icon';
import {
  Video,
  VideoProcessingStatus,
  VideoProcessingStep,
} from 'models/video';
import * as Type from 'DesignSystem/Typography';
import { WarningError } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { ProgressBar } from 'shared/ProgressBar';
import styles from '../video.module.css';
import { UploadedVideoPlayer } from './UploadedVideoPlayer';

export const UploadedVideoPreview: React.FC<{
  video?: Video;
  processingStatus: VideoProcessingStatus;
  showCaption?: boolean;
  showProcessingBorder?: boolean;
}> = ({
  video,
  processingStatus,
  showCaption = true,
  showProcessingBorder = true,
}) => {
  const processingStep = processingStatus.step;

  const uploadStatus = React.useMemo(
    () => (
      <>
        <div>
          <Type.Body>
            {processingStatus.uploadPercentComplete ?? 0}% uploaded
          </Type.Body>
          <ProgressBar
            color="var(--color-brandTintDark)"
            max={100}
            value={processingStatus.uploadPercentComplete ?? 0}
            orientation="horizontal"
            size={5}
          />
        </div>
        {showCaption && (
          <Type.Caption>
            Do not navigate away until upload is complete.
          </Type.Caption>
        )}
      </>
    ),
    [processingStatus.uploadPercentComplete, showCaption]
  );

  const transcodeStatus = React.useMemo(
    () => (
      <>
        <div style={{ marginTop: 10, marginBottom: 5 }}>
          <Type.Body>
            {processingStatus.transcodePercentComplete ?? 0}% processed
          </Type.Body>
          <ProgressBar
            color="var(--color-brandTintDark)"
            max={100}
            value={processingStatus.transcodePercentComplete ?? 0}
            orientation="horizontal"
            size={5}
          />
        </div>
        {showCaption && (
          <Type.Caption>
            You can close this editor. Video will continue processing.
          </Type.Caption>
        )}
      </>
    ),
    [processingStatus.transcodePercentComplete, showCaption]
  );

  const readyToShowVideoPlayer =
    processingStep === VideoProcessingStep.Complete &&
    video?.sourceType === 'admin_created' &&
    video?.secureQueryParams &&
    video.previewImageUrl;

  return (
    <>
      {video && readyToShowVideoPlayer ? (
        <UploadedVideoPlayer video={video} />
      ) : (
        <div
          className={cx(styles.processingVideoContainer, {
            [styles.processingBorder]: !!showProcessingBorder,
          })}
          style={{ height: '200px' }}
        >
          {processingStep === VideoProcessingStep.Idle && (
            <Icon iconType="SVG" iconName="Video" size={50} />
          )}
          {processingStep === VideoProcessingStep.Uploading && (
            <>
              <LoadingSpinner />
              {uploadStatus}
            </>
          )}
          {processingStep === VideoProcessingStep.Transcoding && (
            <>
              <LoadingSpinner />
              {processingStatus.transcodePercentComplete && transcodeStatus}
            </>
          )}
          {processingStep === VideoProcessingStep.Error && <WarningError />}
        </div>
      )}
    </>
  );
};
