import * as React from 'react';
import { RenderError } from 'models/publisher/block';

export function useRenderCache(props: {
  html: string;
  enableRender: boolean | undefined;
  errors: RenderError[];
}): string {
  const { html, errors, enableRender } = props;
  // Use a cached copy of the raw HTML to prevent rendering flickers
  const [cache, setCache] = React.useState(html || '');

  React.useEffect(() => {
    if (!enableRender) setCache('');
    else if (!errors.length) setCache((currentValue) => html || currentValue);
  }, [html, errors, enableRender]);

  return cache;
}
