import React, { useEffect, useMemo } from 'react';
import { Body, Subheading } from 'DesignSystem/Typography';
import { Button, FormSection, FormSubsection } from 'DesignSystem/Form';
import Select from 'react-select';
import { FontData, FontFamily } from 'models/font';
import { Check } from 'shared/icons';
import { TextInput } from 'shared/TextInput';
import { EditorPropsType, useFontEditor } from 'hooks/useFontEditor';
import cx from 'classnames';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Flex } from 'DesignSystem/Layout/Flex';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { findWebSafe, WEB_SAFE_FONTS } from 'models/publisher/style';
import { Box } from 'DesignSystem/Components';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { Font } from './Font';
import styles from './font-editor.module.css';

export const EditorComponent: React.FC<EditorPropsType> = ({
  existingData,
  onSaveSuccess,
  isLoading,
}) => {
  const {
    isSaving,
    handleSave,
    isValid,
    stylesheet,
    setName,
    addVariant,
    setFallbackFont,
    updateFont,
    deleteVariant,
    setFont,
    errors,
  } = useFontEditor({ existingData, onSaveSuccess, isLoading });

  const [fontsInProgress, setFontsInProgress] = React.useState<Array<string>>(
    []
  );

  const [fontsUnrelated, setFontsUnrelated] = React.useState<
    Array<FontData['assetKey']>
  >([]);

  useEffect(() => {
    let stylesheetFontFamily: FontFamily;
    const fontsUnrelatedToSet: FontData['assetKey'][] = [];
    stylesheet.asset.fonts.forEach((font) => {
      if (!font.family) return;

      stylesheetFontFamily ||= font.family;
      if (stylesheetFontFamily !== font.family) {
        fontsUnrelatedToSet.push(font.assetKey);
      }
    });
    setFontsUnrelated(fontsUnrelatedToSet);
  }, [stylesheet.asset.fonts]);

  const fallbackOption = React.useMemo(
    () => findWebSafe(stylesheet.asset.fallbackFont),
    [stylesheet.asset.fallbackFont]
  );

  const save = React.useCallback(() => {
    handleSave();
  }, [handleSave]);

  const handleFontUploadingState = React.useCallback(
    (identifier: string, value: boolean) => {
      let newValue: Array<string> = [...fontsInProgress];
      if (value && !newValue.includes(identifier)) {
        newValue.push(identifier);
      }
      if (!value) {
        newValue = newValue.filter((v) => v !== identifier);
      }
      setFontsInProgress(newValue);
    },
    [fontsInProgress]
  );

  const dropdownRenderProp = React.useCallback(() => {
    if (isLoading || isSaving || errors.length === 0) {
      return <></>;
    }

    return (
      <Flex column className={styles.errors}>
        {errors.map((e) => (
          <Body key={e}>{e}</Body>
        ))}
      </Flex>
    );
  }, [errors, isLoading, isSaving]);

  const saveButton = React.useMemo(() => {
    const isDisabled =
      !isValid ||
      fontsInProgress.length !== 0 ||
      isSaving ||
      fontsUnrelated.length !== 0;

    return (
      <HoverDropdown dropdownRenderProp={dropdownRenderProp}>
        <Button
          onClick={save}
          disabled={isDisabled}
          label={
            <Flex>
              {!isSaving ? <Check /> : <LoadingSpinner size="small" />}
              &nbsp;
              <div>Save</div>
            </Flex>
          }
        />
      </HoverDropdown>
    );
  }, [
    dropdownRenderProp,
    fontsInProgress.length,
    fontsUnrelated.length,
    isSaving,
    isValid,
    save,
  ]);

  const displayTitle = useMemo(() => {
    if (isLoading) {
      return 'Loading...';
    }
    if (stylesheet.title && stylesheet.title.length > 0) {
      return stylesheet.title;
    }
    return 'Untitled';
  }, [isLoading, stylesheet.title]);

  return (
    <FormPage
      breadcrumbs={[
        { label: 'Library', to: '../../../app/library/fonts' },
        { label: displayTitle },
      ]}
      title={displayTitle}
      actionsOverride={saveButton}
    >
      {isLoading && (
        <Flex>
          <LoadingSpinner />
        </Flex>
      )}
      {!isLoading && (
        <>
          <FormSection title="Font Details" className={styles.formSection}>
            <FormSubsection title="Font name">
              <TextInput
                value={stylesheet.title}
                onChange={setName}
                placeholder="Brand Font"
                className={styles.fontName}
              />
            </FormSubsection>
            <FormSubsection title="Fallback font">
              <Body>
                This font will be used in emails and other places where your
                custom font cannot be rendered.
              </Body>
              <Box margin="5px 0 0 0">
                <Select
                  onChange={setFallbackFont}
                  value={fallbackOption}
                  options={WEB_SAFE_FONTS}
                />
              </Box>
            </FormSubsection>
          </FormSection>
          <FormSection title="Font Variants" className={styles.formSection}>
            <Body>
              Upload one or more of the following formats for each font style:
              .woff (recommended for widest compatibility), .ttf, .otf. Please
              ensure that you have the rights to use any fonts you upload.
            </Body>
            <Box margin="10px 0 0 0">
              <div className={cx(styles.variants, styles.variantsWrapper)}>
                <Subheading bold>Weight</Subheading>
                <Subheading bold>Style</Subheading>
                <Subheading bold>File</Subheading>
              </div>
              <div>
                {(stylesheet.asset.fonts || []).map((f, index) => (
                  <Font
                    key={`font-${f.assetKey}`}
                    font={f}
                    onDelete={() => deleteVariant(index)}
                    onUpdate={(field, value) => updateFont(index, field, value)}
                    onUpload={(data) => setFont(data, index)}
                    setIsWorking={handleFontUploadingState}
                    stylesheetFontFamily={stylesheet.asset.fontFamily}
                  />
                ))}
              </div>
              {stylesheet.asset.fonts.length < 4 && (
                <div className={styles.addVariantButtonContainer}>
                  <Button
                    onClick={addVariant}
                    layoutOnly
                    icon="+ Add Variant"
                  />
                </div>
              )}
            </Box>
          </FormSection>
        </>
      )}
    </FormPage>
  );
};
