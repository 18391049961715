/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

export const Image: React.FC<
  ImageDataProps & ImageLayoutProps & ImageStyleProps
> = ({ src, alt, responsive, margin, width, height, style = {} }) => {
  const responsiveStyles = responsive
    ? {
        display: 'inline-block',
        maxWidth: '100%',
        maxHeight: '100%',
        width: toMinDim(width),
        height: toMinDim(height),
        minWidth: toMinDim(width),
        minHeight: toMinDim(height),
      }
    : {};
  return (
    <img
      src={src}
      alt={alt}
      style={{
        margin: toPx(margin),
        ...responsiveStyles,
        ...style,
      }}
    />
  );
};

type ImageDataProps = {
  src: string;
  alt: string;
};

type ImageStyleProps = {
  style?: React.CSSProperties;
};

type ImageLayoutProps = {
  responsive?: boolean;
  width?: number;
  height?: number;
  margin?: number | number[];
};

function toPx(n?: number | number[]) {
  if (!n && n !== 0) return undefined;
  const ns = Array.isArray(n) ? n : [n];
  return ns.map((m) => `${m}px`).join(' ');
}

function toMinDim(n?: number) {
  return toPx(n) || 'auto';
}
