/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import cx from 'classnames';
import styles from './layout.module.css';
import { Background } from '../Typography';

type FlexLayoutProps = {
  spread?: boolean;
  alignStart?: boolean;
  start?: boolean;
  end?: boolean;
  center?: boolean;
  wrap?: boolean;
  column?: boolean;
};

type FlexStyleProps = {
  color?: Background;
};

export const Flex: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    FlexLayoutProps &
    FlexStyleProps
> = ({
  children,
  className,
  alignStart,
  start,
  end,
  spread,
  center,
  wrap,
  column,
  color,
  ...divProps
}) => (
  <div
    className={cx(styles.Flex, {
      [styles.Center]: center,
      [styles.End]: end,
      [styles.Spread]: spread,
      [styles.AlignStart]: alignStart,
      [styles.Start]: start,
      [styles.Wrap]: wrap,
      [styles.Column]: column,
      [color ?? '']: color,
      [`${className}`]: !!className,
    })}
    {...divProps}
  >
    {children}
  </div>
);

export const Space: React.FC<{ size?: number; row?: boolean }> = ({
  row,
  size = 10,
}) => (
  <div
    style={{ width: `${size}px`, display: row ? 'inline-block' : 'block' }}
  />
);

type FlexItemProps = {
  widen?: boolean;
  column?: boolean;
} & Pick<FlexLayoutProps, 'start' | 'end' | 'alignStart'> &
  FlexStyleProps;

export const FlexItem: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    FlexItemProps
> = ({
  color,
  column,
  widen,
  start,
  end,
  alignStart,
  children,
  ...divProps
}) => (
  <div
    className={cx(styles.Flex, {
      [styles.Widen]: widen,
      [styles.Start]: start,
      [styles.Column]: column,
      [styles.End]: end,
      [styles.AlignStart]: alignStart,
      [color ?? '']: color,
    })}
    {...divProps}
  >
    {children}
  </div>
);
