import FroalaEditor, { BoxPlugin, FroalaEvents } from 'froala-editor';
import { useCallback, useRef, useState } from 'react';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { usePublisher } from 'contexts/publisher';
import { BoxIntegrationData } from 'models/box-integration';
import { BoxLogoSvg } from './BoxLogoSvg';

export const BOX_FROALA_PLUGIN_NAME = 'box';

type UseFroalaBoxPluginProps = {
  enabled: boolean;
};

type BoxPluginEvents = Partial<FroalaEvents>;

type UseFroalaBoxPluginResult = {
  isBoxPickerOpen: boolean;
  setIsBoxPickerOpen: (isOpen: boolean) => void;
  onSelectBoxResource: BoxPlugin['applyBoxResourceLink'];
  froalaEvents: BoxPluginEvents;
};

const BOX_POPUP_ID = 'box.popup';
const LINK_EDIT_ID = 'link.edit';

const isBoxIntegrationResource = (
  element: HTMLElement | undefined
): element is HTMLAnchorElement =>
  !!element &&
  element.tagName.toUpperCase() === 'A' &&
  element.getAttribute('rel') === 'box-integration-resource';

export function useFroalaBoxPlugin({
  enabled,
}: UseFroalaBoxPluginProps): UseFroalaBoxPluginResult {
  const editorRef = useRef<FroalaEditor>();
  const showPopupRef = useRef<() => void>();
  const hidePopupRef = useRef<() => void>();
  const selectedElementRef = useRef<HTMLElement>();
  const [isBoxPickerOpen, setIsBoxPickerOpen] = useState(false);
  const { post } = usePublisher();
  const renderingVars = useFieldVariables(post);

  const onSelectBoxResource = useCallback(
    (boxResource: BoxIntegrationData) => {
      setIsBoxPickerOpen(false);

      if (!editorRef.current) return;

      editorRef.current.selection.restore();

      const selectedElement = selectedElementRef.current;
      selectedElementRef.current = undefined;

      // simply replace the relevant attribute if the current element is already a box resource element
      if (isBoxIntegrationResource(selectedElement)) {
        if (
          selectedElement.getAttribute('data-box-resource-name') ===
          selectedElement.text
        ) {
          selectedElement.textContent = boxResource.name;
        }

        selectedElement.setAttribute(
          'data-box-integration-type',
          boxResource.resource_type
        );
        selectedElement.setAttribute(
          'data-box-integration-id',
          boxResource.resource_id
        );
        selectedElement.setAttribute(
          'data-box-resource-name',
          boxResource.name
        );
        return;
      }

      editorRef.current.link.remove();
      editorRef.current.link.insert(
        renderingVars.content_permalink || `#`,
        editorRef.current.selection.text() || boxResource.name,
        {
          rel: 'box-integration-resource',
          'data-box-integration-type': boxResource.resource_type,
          'data-box-integration-id': boxResource.resource_id,
          'data-box-resource-name': boxResource.name,
        }
      );
    },
    [renderingVars]
  );

  if (enabled) {
    FroalaEditor.DefineIconTemplate('box_logo_svg', BoxLogoSvg());

    Object.assign(FroalaEditor.POPUP_TEMPLATES, {
      [BOX_POPUP_ID]: '[_BUTTONS_]',
    });
    const SELECT_BOX_RESOURCE_COMMAND = 'selectBoxResource';

    // eslint-disable-next-line func-names
    FroalaEditor.PLUGINS.box = function (editorInstance: FroalaEditor) {
      editorRef.current = editorInstance;

      function initPopup() {
        const popupButtons = `<div class="fr-buttons">${editorInstance.button.buildList(
          [SELECT_BOX_RESOURCE_COMMAND]
        )}</div>`;

        return editorInstance.popups.create(BOX_POPUP_ID, {
          buttons: popupButtons,
        });
      }

      function showPopup() {
        if (!editorInstance.popups.get(BOX_POPUP_ID)) {
          initPopup();
        }

        editorInstance.popups.setContainer(BOX_POPUP_ID, editorInstance.$sc);
        editorInstance.popups.refresh(BOX_POPUP_ID);

        // Place popup relative to the selected element
        const anchor = editorInstance.selection.element();

        const anchorRect = anchor.getBoundingClientRect();

        // Set the popup's position.
        const { left } = anchorRect;
        const top = anchorRect.top + anchorRect.height;

        // Show the custom popup.
        // The selection's outerHeight is required in case the popup needs to be displayed above it.
        editorInstance.popups.show(BOX_POPUP_ID, left, top, anchorRect.height);
      }

      function hidePopup() {
        // Hide the custom popup.
        editorInstance.popups.hide(BOX_POPUP_ID);
      }

      showPopupRef.current = showPopup;
      hidePopupRef.current = hidePopup;

      function openBoxPicker() {
        selectedElementRef.current = editorInstance.selection.element();
        editorInstance.selection.save();
        setIsBoxPickerOpen(true);
      }

      const plugin: BoxPlugin = {
        _init: () => {},
        applyBoxResourceLink: onSelectBoxResource,
        openBoxPicker,
      };

      return plugin;
    };

    FroalaEditor.DefineIcon(BOX_FROALA_PLUGIN_NAME, {
      NAME: 'box_logo_svg',
      template: 'box_logo_svg',
    });
    FroalaEditor.RegisterCommand(BOX_FROALA_PLUGIN_NAME, {
      title: 'Insert Box Resource Link',
      plugin: BOX_FROALA_PLUGIN_NAME,
      undo: true,
      callback() {
        this.box?.openBoxPicker();
      },
    });

    FroalaEditor.DefineIcon(SELECT_BOX_RESOURCE_COMMAND, {
      NAME: 'select-box-resource',
      template: 'box_logo_svg',
    });
    FroalaEditor.RegisterCommand(SELECT_BOX_RESOURCE_COMMAND, {
      title: 'Replace Box Resource Link',
      undo: true,
      callback() {
        this.box?.openBoxPicker();
      },
    });
  }

  const froalaEvents: BoxPluginEvents = {
    click(this: FroalaEditor) {
      const selectedElement = this.selection.element();

      if (isBoxIntegrationResource(selectedElement)) {
        showPopupRef.current?.();
      } else {
        hidePopupRef.current?.();
      }
    },

    [`popups.show.${LINK_EDIT_ID}`](this: FroalaEditor) {
      const selectedElement = this.selection.element();

      if (isBoxIntegrationResource(selectedElement)) {
        this.popups.hide(LINK_EDIT_ID);
        showPopupRef.current?.();
      }
    },
  };

  return {
    isBoxPickerOpen,
    setIsBoxPickerOpen,
    onSelectBoxResource,
    froalaEvents,
  };
}
