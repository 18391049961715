import React from 'react';
import { EngagementTogglesAndAuthor } from '../EngagementToggles';
import { CustomSlugsSection } from '../CustomSlugsSection';
import { InitiativesSection } from '../Initiatives/InitiativesSection';
import { SectionContainer } from '../../SettingsCard/SectionContainer';
import { Divisor } from '../../SettingsCard';
import { SearchMetaTagsSection } from '../SearchMetaTagsSection';

interface EngagementSectionProps {
  disabled?: boolean;
}

export const EngagementSection: React.FC<EngagementSectionProps> = ({
  disabled,
}) => {
  return (
    <SectionContainer title="Content Settings" headerBottomBorder>
      <EngagementTogglesAndAuthor disabled={disabled} />
      <Divisor />
      <CustomSlugsSection disabled={disabled} />
      <InitiativesSection disabled={disabled} />
      <SearchMetaTagsSection disabled={disabled} />
    </SectionContainer>
  );
};
