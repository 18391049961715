import camelcaseKeys from 'camelcase-keys';
import { Poll } from '../models/poll';
import { fqApiUrl } from './common';
import { request } from './api-shared';

export type PollData = {
  data: {
    attributes: Poll;
  };
};

export type AssetData = {
  data: Array<{
    id: string;
    type: 'asset';
    attributes: {
      assetType: 'image';
      assetKey: string;
      url: string;
    };
  }>;
};

const goGet = async <T>(path: string): Promise<T> => {
  const response = await request(fqApiUrl(path));
  if (response.status !== 200)
    throw new Error(`Error fetching polls: ${response.status}`);
  return response
    .json()
    .then((json) => (camelcaseKeys(json, { deep: true }) as unknown) as T);
};

export const fetchPoll = async (
  pollId: number,
  programId: number
): Promise<PollData> =>
  goGet<PollData>(`/samba/programs/${programId}/polls/${pollId}`);

export const fetchAssets = async (programId: number): Promise<AssetData> =>
  goGet<AssetData>(`/samba/programs/${programId}/poll_assets`);
