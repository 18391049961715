import * as React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useLibraryAccess } from '../useLibraryAccess';
import { TemplatesListPage as Actual } from './TemplatesListPage';

export const TemplatesListPage: React.FC<
  RouteComponentProps & { isJourneyTemplates?: boolean }
> = ({ isJourneyTemplates }) => {
  const access = useLibraryAccess();
  const { id: programId } = useProgram();
  const { data: journeysEnabled, isLoading } = useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.Enabled'
  );

  if (
    isJourneyTemplates &&
    !isLoading &&
    (!journeysEnabled?.value || !access.has('journey_templates'))
  ) {
    navigate('..');
    return null;
  }

  if (!access.has('templates')) {
    navigate('..');
    return null;
  }
  return <Actual isJourneyTemplates={isJourneyTemplates} tabs={access.pages} />;
};
