import { useNavigate } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { BulkSelection, TopicBulkActionFilters } from 'hooks/common';
import {
  useArchiveTopic,
  useBulkArchiveTopics,
  useBulkUnarchiveTopics,
  useCreateTopic,
  useDeleteTopicDraft,
  useUnarchiveTopic,
  useUpdateTopic,
} from 'hooks/topics';
import { Topic } from 'models/topic';
import { useQueryClient } from 'react-query';
import { useFlashServerErrors } from 'utility/errors';

export const useActions = (
  setTopic?: (topic: Topic) => void,
  updateList = false
): {
  edit: (topicId: number) => void;
  archive: (topicId: number) => void;
  unarchive: (topicId: number) => void;
  update: (topic: Topic) => void;
  create: (topic: Partial<Topic>) => void;
  bulkArchive: (
    bulkSelection: BulkSelection,
    filterConfig: TopicBulkActionFilters
  ) => void;
  bulkUnarchive: (
    bulkSelection: BulkSelection,
    filterConfig: TopicBulkActionFilters
  ) => void;
  unarchiveAndEdit: (topicId: number) => void;
  deleteDraft: (topic: Topic) => void;
  isLoading: boolean;
} => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const queryClient = useQueryClient();
  const topicListUrl = `/${programId}/configure/topics`;
  const infiniteQueryKey = 'topics-infinite';

  const edit = (topicId: number) => {
    navigate(`${topicListUrl}/${topicId}/edit`);
  };

  const { update, isLoading: isUpdating } = useUpdateTopic(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Topic updated successfully',
      });

      navigate(topicListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update topic');
    },
  });

  const { archive } = useArchiveTopic(programId, {
    onSuccess: (data) => {
      setTopic?.(data);
      if (updateList) {
        queryClient.invalidateQueries(infiniteQueryKey);
      }
      setFlashMessage({
        severity: 'info',
        message: 'Topic archived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive topic',
      }),
  });

  const { unarchive } = useUnarchiveTopic(programId, {
    onSuccess: (data) => {
      setTopic?.(data);
      if (updateList) {
        queryClient.invalidateQueries(infiniteQueryKey);
      }
      setFlashMessage({
        severity: 'info',
        message: 'Topic unarchived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not unarchive topic',
      }),
  });

  const { unarchive: unarchiveAndEdit } = useUnarchiveTopic(programId, {
    onSuccess: (data) => {
      setTopic?.(data);
      if (updateList) {
        queryClient.invalidateQueries(infiniteQueryKey);
      }
      navigate(`${topicListUrl}/${data.id}/edit`);
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not unarchive topic',
      }),
  });

  const { create, isLoading: isCreating } = useCreateTopic(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Topic created successfully',
      });

      navigate(topicListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, error.message);
    },
  });

  const { bulkArchive } = useBulkArchiveTopics(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Topics archived successfully.',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive topics',
      }),
  });

  const { bulkUnarchive } = useBulkUnarchiveTopics(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Topics unarchived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive topics',
      }),
  });

  const { deleteDraft } = useDeleteTopicDraft(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Draft deleted successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Failed to delete draft',
      }),
  });

  const isLoading = isCreating || isUpdating;

  return {
    edit,
    archive,
    unarchive,
    update,
    create,
    bulkArchive,
    bulkUnarchive,
    unarchiveAndEdit,
    deleteDraft,
    isLoading,
  };
};
