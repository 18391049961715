import { InclusiveRule } from 'models/inclusive-language';
import qs from 'qs';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { deepCamelcaseKeys, request } from './api-shared';
import { Page } from './common';
import { ValidationError } from './Errors/ValidationError';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  query?: string;
  page?: number;
  pageSize?: number;
};

export type InclusiveRulesCollectionData = Page<{ attributes: InclusiveRule }>;

export async function fetchInclusiveLanguageRules(
  props: FetchProps
): Promise<InclusiveRulesCollectionData> {
  const { programId, query, page, pageSize } = props;

  const q = qs.stringify(snakecaseKeys({ query, page, pageSize }), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/samba/programs/${programId}/inclusive_rules?${q}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching inclusive language: ${response.status}`);
}

export const fetchById = async (
  programId: number,
  inclusiveRuleId: number
): Promise<InclusiveRule> => {
  const url = `${apiRoot}/samba/programs/${programId}/inclusive_rules/${inclusiveRuleId}`;
  const response = await request(url);
  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }
  throw new Error(`Error fetching flagged term: ${response.status}`);
};

export const updateInclusiveRule = async (
  programId: number,
  inclusiveRule: InclusiveRule
): Promise<InclusiveRule> => {
  const url = `${apiRoot}/samba/programs/${programId}/inclusive_rules/${inclusiveRule.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify({
      id: inclusiveRule.id,
      data: { attributes: snakecaseKeys(inclusiveRule) },
    }),
  });

  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }

  if (response.status === 422) {
    const errors = await response.json();
    throw new ValidationError(JSON.stringify(errors));
  }

  throw new Error(`Error updating rule: ${response.status}`);
};

export const createInclusiveRule = async (
  programId: number,
  inclusiveRule: Partial<InclusiveRule>
): Promise<InclusiveRule> => {
  const url = `${apiRoot}/samba/programs/${programId}/inclusive_rules`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      data: { attributes: snakecaseKeys(inclusiveRule) },
    }),
  });

  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }

  if (response.status === 422) {
    const errors = await response.json();
    throw new ValidationError(JSON.stringify(errors));
  }

  throw new Error(`Error creating rule: ${response.status}`);
};
