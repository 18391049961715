import { Journey } from 'models/journeys/journey';
import type { JourneyActionFor } from './journey-reducer';
import { getDraftStep, updateStep } from './steps';

export function handleStepUpdated(
  state: Journey,
  action: JourneyActionFor<'step/updated'>
): Journey {
  const step = getDraftStep(state, action.step.type, action.stepId);

  if (!step) {
    return state;
  }

  return updateStep(state, Object.assign(step, action.step));
}
