import React, { CSSProperties, RefObject } from 'react';
import { AutoHeight } from './AutoHeight';

type PropsType = {
  children: (height: number) => React.ReactNode;
  style?: CSSProperties;
  parentRef: RefObject<HTMLDivElement>;
  isFullWidth?: boolean;
  isStandalone?: boolean;
};

export const InfiniteWrapper: React.FC<PropsType> = (props) => {
  const { children, parentRef, style = {} } = props;

  const cssHeight = style.height;

  const FixedHeight = (height: number) => (
    <div
      ref={parentRef}
      style={{
        height,
        width: '100%',
        overflowY: 'auto',
        ...style,
        maxHeight: '100%',
      }}
    >
      {children(height)}
    </div>
  );

  return typeof cssHeight === 'number' ? (
    FixedHeight(cssHeight as number)
  ) : (
    <AutoHeight>
      {(height: number) => (
        <div
          ref={parentRef}
          style={{
            height,
            width: '100%',
            overflowY: 'auto',
            ...style,
            maxHeight: '100%',
          }}
        >
          {children(height)}
        </div>
      )}
    </AutoHeight>
  );
};
