import * as React from 'react';
import {
  CloudbeesFeatureFlag,
  FeatureFlag,
  FeatureFlags,
} from 'models/feature-flag';

import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useAllFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Heading } from 'DesignSystem/Typography';
import { useUser } from 'contexts/user';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './capabilities.module.css';
import { ListItem } from './ListItem';
import { ListItemCloudbees } from './ListItemCloudbees';

type PropsType = {
  featureFlags?: FeatureFlags;
  isLoading?: boolean;
  searchValue?: string;
};

export const MainPanel: React.FC<PropsType> = (props) => {
  const { isLoading, featureFlags } = props;
  const { id: programId } = useProgram();
  const allFeatureFlags = useAllFeatureFlagsQuery(programId);
  const { superAdmin } = useUser();

  // to be replaced with flags that have appropriate label set in cloudbees
  const prefixes = [
    'Studio',
    'Audience',
    'Auth',
    'Assistant',
    'Content',
    'EE',
    'Microapps',
  ];

  const flags = React.useMemo(() => {
    if (featureFlags) {
      return Object.values(featureFlags).filter(
        (f) =>
          f?.label.indexOf('taichi') === -1 &&
          f?.label.indexOf('new_nav') === -1 &&
          f?.label.indexOf('program_type') === -1 &&
          f?.label.indexOf('delivery_threshold') === -1
      );
    }

    return [];
  }, [featureFlags]);

  const userChangeable = React.useMemo(() => {
    return [
      'content_sharing_enabled',
      'commenting_enabled',
      'commenting_default_enabled',
      'measure_anonymize_users_enabled',
    ];
  }, []);

  const superadminFlags = React.useMemo(() => {
    return flags.filter(
      (f) => f && f.label && !userChangeable.includes(f.label)
    );
  }, [flags, userChangeable]);

  const userFlags = React.useMemo(() => {
    return flags.filter(
      (f) => f && f.label && userChangeable.includes(f.label)
    );
  }, [flags, userChangeable]);

  const superadminCloudbeesFlags = React.useMemo(() => {
    return (allFeatureFlags.data || []).filter(
      (f) => !f.labels.includes('user_editable')
    );
  }, [allFeatureFlags.data]);

  const userCloudbeesFlags = React.useMemo(() => {
    return (allFeatureFlags.data || []).filter((f) =>
      // an example of how user-editable cloudbees flag would be labeled
      f.labels.includes('user_editable')
    );
  }, [allFeatureFlags.data]);

  const renderList = React.useCallback((list) => {
    return list.map(
      (featureFlag: FeatureFlag) =>
        featureFlag && (
          <Flex
            key={`feature-flag-${featureFlag.label}`}
            className={styles.itemWrapper}
          >
            <ListItem programCustomization={featureFlag} />
          </Flex>
        )
    );
  }, []);

  const renderCloudbeesList = React.useCallback((list) => {
    return list.map((f: CloudbeesFeatureFlag) => {
      return (
        <Flex key={`feature-flag-${f.name}`} className={styles.itemWrapper}>
          <ListItemCloudbees programCustomization={f} />
        </Flex>
      );
    });
  }, []);

  if (isLoading || allFeatureFlags.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <Heading>Community Settings</Heading>
        {userCloudbeesFlags.length > 0 && (
          <div>{renderCloudbeesList(userCloudbeesFlags)}</div>
        )}
        {renderList(userFlags)}
      </div>
      {superAdmin && (
        <div className={styles.section}>
          <Heading>Superadmin settings</Heading>
          {renderList(superadminFlags)}
          {prefixes.map((p) => {
            return (
              <div className={styles.prefix} key={p}>
                <Heading>{p} flags</Heading>
                {renderCloudbeesList(
                  superadminCloudbeesFlags.filter((f) =>
                    f.name.includes(`${p}.`)
                  )
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
