import React from 'react';
import cx from 'classnames';
import { backspaceKey, deleteKey, leftKey, rightKey } from './event-lib';
import styles from './vti.module.css';

export const VariableInput: React.FC<{
  onDeleteVariable: () => void;
  onPreviousInput: () => void;
  onNextInput: () => void;
  disabled: boolean;
  className?: string;
}> = ({
  onDeleteVariable,
  onPreviousInput,
  onNextInput,
  disabled,
  children,
  className,
}) => (
  <div
    role="textbox"
    className={cx(`${styles.variableTextInput} -vti-clickable`, className)}
    tabIndex={0}
    onKeyDown={(event) => {
      if (disabled) return;
      if (backspaceKey(event) || deleteKey(event)) {
        // this causes some browsers to go back in history.
        event.preventDefault();
        onDeleteVariable();
      }
      if (leftKey(event)) {
        onPreviousInput();
      }
      if (rightKey(event)) {
        onNextInput();
      }
    }}
  >
    {children}
  </div>
);
