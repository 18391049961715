import React from 'react';
import { Link } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useNavigationBlockerContext } from 'contexts/publisher/compose/navigation-blocker';

export const NavLink: React.FC<
  {
    baseClassName?: string;
    normalClassName?: string;
    activeClassName?: string;
    partialClassName?: string;
    disabledClassName?: string;
    disabled?: boolean;
    shouldDisablePartialClass?: boolean;
    validateClick?: () => boolean;
    dataPendo?: string;
  } & React.ComponentProps<typeof Link>
> = ({
  baseClassName = '-NavLink-',
  normalClassName = '-Normal-',
  activeClassName = '-Active-',
  partialClassName = '-Partial-',
  disabledClassName = '-Disabled-',
  className,
  shouldDisablePartialClass = false,
  validateClick,
  disabled,
  onClick,
  dataPendo,
  ...props
}) => {
  const { blocker } = useNavigationBlockerContext({ noThrow: true });
  const { setFlashMessage } = useFlashMessage();

  const clickValid = () =>
    validateClick === undefined ? true : validateClick();

  return (
    /* eslint-disable */
    <Link
      {...props}
      data-pendo={dataPendo}
      onClick={(event) => {
        if (disabled || !clickValid() || blocker.enabled) {
          event.preventDefault();
        }
        if (blocker.enabled) {
          setFlashMessage({ message: blocker.message, severity: 'error' });
        }
        if (!disabled && onClick) {
          onClick(event);
        }
      }}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const classNames = [baseClassName];
        if (className) classNames.push(className);
        if (isCurrent) classNames.push(activeClassName);
        else if (isPartiallyCurrent && !shouldDisablePartialClass)
          classNames.push(partialClassName);
        else if (disabled) classNames.push(disabledClassName);
        else classNames.push(normalClassName);
        return { className: classNames.join(' ') };
      }}
    />
  );
};
