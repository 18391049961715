import * as React from 'react';
import { deepMerge } from 'utility/deep-merge';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useStyleEditor as useGlobalStyleEditor } from 'contexts/publisher/compose/style';
import { usePublisherStyleEditor as useCustomStyleEditor } from 'hooks/publisher-style-editor';
import { resolveVariableStyling } from 'models/donkey';
import {
  DefinitionBlock,
  StyleData,
  Styling,
  VariableStyleData,
} from 'models/publisher/block';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { usePublisher } from 'contexts/publisher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';

export const useResolveVariableStyling = (
  styleData: Partial<VariableStyleData>
): Styling => {
  const { post } = usePublisher();
  const vars = useFieldVariables(post);
  return resolveVariableStyling(styleData, vars);
};

export function useStyleData({
  id,
  block,
}: {
  id: string;
  block: DefinitionBlock;
}): {
  editor: ReturnType<typeof useGlobalStyleEditor>;
  isCustomStyled: boolean;
  autoCropping: boolean;
  enableCustomStyle: () => void;
  disableCustomStyle: () => void;
  enableAutoCropping: () => void;
  disableAutoCropping: () => void;
  revertToGlobalStyle: () => void;
} {
  const globalEditor = useGlobalStyleEditor();
  const resolvedCustomStyles = useResolveVariableStyling(
    block.style_data ?? globalEditor.blockStyle
  );
  const { updateStyleData } = React.useContext(BlocksEditorContext);
  const isCustomStyled = !!block.style_data?.isCustomStyled;
  const autoCropping = !!block.style_data?.autoCropping;

  // The difference between the old and the new editors is the following:
  // The old editors merge BASE_STYLING with the user's selection and write it into the style_data field
  // The new editors only write the user's changes into the style_data field.
  const usingNewPubEditors = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const onChangeBlockStyle = React.useCallback(
    (blockStyle: StyleData) => {
      updateStyleData(id, {
        ...blockStyle,
        isCustomStyled: true,
        autoCropping,
      });
    },
    [autoCropping, id, updateStyleData]
  );

  const customEditor = useCustomStyleEditor({
    styleFor: id,
    initialStyleData: resolvedCustomStyles,
    // for the old editor or the global styles - sets FULL style_data
    onChange: onChangeBlockStyle,
    // for block styles in the new editor - sets PARTIAL style_Data
    onChangeBlockStyle,
    initialStyle: globalEditor.style,
    fontOptions: globalEditor.fontOptions,
  });

  const enableCustomStyle = React.useCallback(() => {
    // this backfills block's own style_data with custom style,
    // which we don't need if we are only keeping the user's changes in style_data
    // in the new editor
    if (!usingNewPubEditors) {
      // this trickles through the hook and back through `onChangeBlockStyle` above
      customEditor.changeStyle(globalEditor.style);
    }
  }, [customEditor, globalEditor.style, usingNewPubEditors]);

  const disableCustomStyle = React.useCallback(() => {
    updateStyleData(
      id,
      deepMerge(customEditor.style, { isCustomStyled: false, autoCropping })
    );
  }, [updateStyleData, id, customEditor.style, autoCropping]);

  const enableAutoCropping = React.useCallback(() => {
    updateStyleData(
      id,
      deepMerge(
        usingNewPubEditors ? customEditor.blockStyle : customEditor.style,
        {
          autoCropping: true,
          isCustomStyled,
        }
      )
    );
  }, [
    customEditor.blockStyle,
    customEditor.style,
    id,
    isCustomStyled,
    updateStyleData,
    usingNewPubEditors,
  ]);

  const disableAutoCropping = React.useCallback(() => {
    updateStyleData(
      id,
      deepMerge(
        usingNewPubEditors ? customEditor.blockStyle : customEditor.style,
        {
          autoCropping: false,
          isCustomStyled,
        }
      )
    );
  }, [
    customEditor.blockStyle,
    customEditor.style,
    id,
    isCustomStyled,
    updateStyleData,
    usingNewPubEditors,
  ]);

  const editor = React.useMemo(
    () => (isCustomStyled || usingNewPubEditors ? customEditor : globalEditor),
    [isCustomStyled, usingNewPubEditors, customEditor, globalEditor]
  );

  return {
    editor,
    isCustomStyled,
    autoCropping,
    enableCustomStyle,
    disableCustomStyle,
    enableAutoCropping,
    disableAutoCropping,
    revertToGlobalStyle: customEditor.revertToGlobalStyle,
  };
}
