import * as React from 'react';
import { Filter } from 'models/journeys/filter';
import { AuthorFilter } from 'components/publisher/settings/SettingsEditor/sections/Administration/AuthorSelect/Filter';
import { Author } from 'models/author';
import { SelectFilterComponent } from './SelectFilterComponent';
import { DateFilterComponent } from './DateFilterComponent';
import { BooleanFilterComponent } from './BooleanFilterComponent';

const renderFilter = (
  filter: Filter,
  onChange: (filter: Filter) => void,
  onRemove: (filter: Filter) => void
) => {
  switch (filter.type) {
    case 'select':
      return <SelectFilterComponent filter={filter} onChange={onChange} />;
    case 'date':
      return <DateFilterComponent filter={filter} onChange={onChange} />;
    case 'boolean':
      return <BooleanFilterComponent filter={filter} onRemove={onRemove} />;
    case 'author':
      return (
        <AuthorFilter
          value={
            filter.value?.map((userId) => ({
              userId: Number(userId),
            })) as Author[]
          }
          onChange={(authors) => {
            onChange({
              ...filter,
              value: authors.map((author) => `${author.userId}`),
            });
          }}
          allowForgotten
          align="left"
        />
      );
    default:
      return null;
  }
};

export const JourneyFilter: React.FC<{
  filter: Filter;
  onChange: (filter: Filter) => void;
  onRemove: (filter: Filter) => void;
}> = ({ filter, onChange, onRemove }) => {
  return <>{renderFilter(filter, onChange, onRemove)}</>;
};
