import { FeedCard } from '@socialchorus/shared-ui-components';
import React, { ComponentProps, useMemo } from 'react';
import { Button } from 'DesignSystem/Form';
import { Content } from 'models/content';
import { Avatar } from 'shared/Avatar';
import { MAIcon } from 'shared/MAIcon';
import styles from './content-preview.module.css';

type ContentPreview = {
  content: Content;
};

// Same as the colors defined in patron
// submissionNoteColors - /src/models/content-submission/atoms.js
const NOTE_COLORS = [
  '#ba5858',
  '#c5be7b',
  '#646692',
  '#655163',
  '#c29b72',
  '#4a93c6',
  '#494949',
];

type FeedCardImageProp = ComponentProps<typeof FeedCard.Image>['image'];

export function ContentPreview({
  content,
}: ContentPreview): React.ReactElement {
  const image = useMemo((): FeedCardImageProp => {
    if (content.contentType !== 'note') {
      return {
        url: content.imageUrl ?? null,
        alt: '',
      };
    }

    const calculatedColor = NOTE_COLORS[content.id % NOTE_COLORS.length];
    return {
      type: 'note-icon',
      color: calculatedColor,
    };
  }, [content.contentType, content.id, content.imageUrl]);

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <FeedCard.Image image={image} />
      </div>
      <div className={styles.content}>
        <div className={styles.author}>
          <Avatar
            width={24}
            height={24}
            src={content.contentAuthor.avatarUrl}
            className={styles.avatar}
          />
          <span className={styles.authorName}>
            {content.contentAuthor.displayName}
          </span>
        </div>
        <div className={styles.title}>{content.title}</div>
        <div className={styles.subtitle}>{content.summary}</div>
      </div>

      <Button
        href={content.permalinkUrl}
        secondary
        label="View in Experience"
        icon={<MAIcon name="open_in_new" />}
        openInNewTab
      />
    </div>
  );
}
