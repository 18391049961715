import { Editor } from '@tiptap/react';
import {
  selectStyles,
  selectTheme,
} from 'App/Program/Main/People/User/Shared/Select';
import { Tooltip } from 'DesignSystem/Components';
import React from 'react';
import { NamedProps, OptionsType, OptionTypeBase } from 'react-select';
import { GenericCreatableSelect } from 'shared/Select';
import { DEFAULT_EDITOR_FONT_SIZE } from '../constants';
import { ToolbarButton } from './shared/ToolbarButton';

const defaultFontSizes = [8, 9, 10, 11, 12, 13, 14];
const fontSizesSelectOptions: OptionsType<OptionType> = defaultFontSizes.map(
  (size) => ({
    value: String(size),
    label: String(size),
  })
);

function useEditorFontSize(editor: Editor): [number, (size: number) => void] {
  const { fontSize = DEFAULT_EDITOR_FONT_SIZE } = editor.getAttributes(
    'textStyle'
  );

  function setFontSize(size: number) {
    editor
      .chain()
      .focus()
      .setFontSize(size > 1 ? size : 1)
      .run();
  }

  return [Number(fontSize), setFontSize];
}

const defaultIncrement = 1;

function valueTypeFromNumber(value: number) {
  return {
    value: String(value),
    label: String(value),
  };
}

type OptionType = {
  value: string;
  label: string;
};

export const EditorActionFontSize: React.FC<{ editor: Editor }> = ({
  editor,
}) => {
  const [fontSize, setFontSize] = useEditorFontSize(editor);
  const fontSizeSelectValue = React.useMemo(
    () => valueTypeFromNumber(fontSize),
    [fontSize]
  );
  const handleOnChange: NamedProps<OptionTypeBase>['onChange'] = (option) => {
    if (!option || !('value' in option)) {
      return;
    }
    const size = Number(option.value);
    if (size === fontSize) {
      return;
    }
    if (!size || size < 1) {
      setFontSize(fontSize);
      return;
    }
    setFontSize(size);
  };

  return (
    <>
      <ToolbarButton
        onClick={() => setFontSize(fontSize - defaultIncrement)}
        className={editor.isActive('fontSize') ? 'is-active' : ''}
        icon="horizontal_rule"
        aria-label="Decrease font size"
      />
      <Tooltip content="Font size">
        <GenericCreatableSelect
          inputType="number"
          value={fontSizeSelectValue}
          noOptionsMessage={() => null}
          formatCreateLabel={(inputValue) => String(inputValue)}
          options={fontSizesSelectOptions}
          onChange={handleOnChange}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          styles={{
            ...selectStyles,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            control: (provided: React.CSSProperties, state: any) => ({
              ...provided,
              backgroundColor: 'var(--color-gray00)',
              borderColor: 'var(--color-gray10)',
              borderRadius: '6px',
              fontSize: '14px',
              width: '45px',
              cursor: 'pointer',
              border:
                state.isFocused &&
                '1px solid var(--color-brandTintDark) !important',
              outline: state.isFocused && 'none !important',
              boxShadow: state.isFocused && '0 0 0 4px rgba(88, 55, 108, 0.1)',
            }),
            menu: (provided: React.CSSProperties) => ({
              ...provided,
              backgroundColor: 'var(--color-gray00)',
              padding: '5px',
              width: '100%',
              cursor: 'pointer',
            }),
          }}
          theme={selectTheme}
          menuPlacement="auto"
        />
      </Tooltip>
      <ToolbarButton
        onClick={() => setFontSize(fontSize + defaultIncrement)}
        className={editor.isActive('fontSize') ? 'is-active' : ''}
        icon="add"
        aria-label="Increase font size"
      />
    </>
  );
};
