import { useNavigate } from '@reach/router';
import {
  journeysKeys,
  useCopyLiveToDraftMutation,
} from 'hooks/journeys/journeys';
import {
  useUnArchiveJourneyMutation,
  useStopJourneyMutation,
  useDeleteJourneyDraftMutation,
  pauseJourney,
  resumeJourney,
  JourneyActionParams,
} from 'services/api-journey';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { Journey } from 'models/journeys/journey';
import { useMutation, useQueryClient } from 'react-query';

type JourneyAction = (
  journey: Pick<Journey, 'id' | 'name'> | undefined,
  onSuccess?: () => void,
  onError?: () => void
) => {
  call: () => void;
  isLoading: boolean;
};

type AbstractJourneyAction = (
  action: keyof typeof ActionsMap,
  journey: Pick<Journey, 'id' | 'name'> | undefined,
  onSuccess?: () => void,
  onError?: () => void
) => {
  call: () => void;
  isLoading: boolean;
};

const ActionsMap = {
  pause: {
    mutation: pauseJourney,
    mutation_key: 'journey_pause',
    successMessage: 'Journey paused',
  },
  resume: {
    mutation: resumeJourney,
    mutation_key: 'journey_resume',
    successMessage: 'Journey resumed',
  },
};

export const useJourneyAction: AbstractJourneyAction = (
  action,
  journey,
  onSuccess = () => {},
  onError = () => {}
) => {
  const { mutation, mutation_key, successMessage } = ActionsMap[action];

  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const localOnSuccess = () => {
    setFlashMessage({
      message: successMessage.replace('Journey', journey?.name || 'Journey'),
      severity: 'info',
    });
    queryClient.invalidateQueries([...journeysKeys.all]);
    onSuccess();
  };

  const { isLoading, mutate: mutateAction } = useMutation(
    [mutation_key],
    (data: JourneyActionParams) => mutation(data),
    {
      onSuccess: localOnSuccess,
      onError,
    }
  );

  const call = () => {
    if (journey?.id) {
      mutateAction({ programId, journeyId: journey.id });
    }
  };

  return {
    call,
    isLoading,
  };
};

export const useJourneyCreateDraftAction: JourneyAction = (
  journey,
  onSuccess = () => {},
  onError = () => {}
) => {
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const { id: programId } = useProgram();

  const { mutateCopyLiveToDraft, isLoading } = useCopyLiveToDraftMutation({
    onSuccess: () => {
      setFlashMessage({
        message: 'Draft created successfully',
        severity: 'info',
      });
      navigate(`/${programId}/app/journeys/${journey?.id}/edit`);
      onSuccess();
    },
    onError: () => {
      setFlashMessage({
        message: 'Could not create draft',
        severity: 'error',
      });
      onError();
    },
  });

  const createDraft = () => {
    if (journey?.id) {
      mutateCopyLiveToDraft({ programId, journeyId: journey.id });
    }
  };

  return {
    call: createDraft,
    isLoading,
  };
};

export const useJourneyStopAction: JourneyAction = (
  journey,
  onSuccess = () => {},
  onError = () => {}
) => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();

  const { mutateStop, isLoading } = useStopJourneyMutation(
    () => {
      setFlashMessage({
        message: `${journey?.name || 'Journey'} has been stopped.`,
        severity: 'info',
      });
      onSuccess();
    },
    () => {
      setFlashMessage({
        message: `There was an error stopping ${
          journey?.name || 'Journey'
        }. Please try again.`,
        severity: 'error',
      });
      onError();
    }
  );

  const stopJourney = () => {
    if (journey?.id) {
      mutateStop({ programId, journeyId: journey.id });
    }
  };

  return {
    call: stopJourney,
    isLoading,
  };
};

export const useJourneyDeleteDraftAction: JourneyAction = (
  journey,
  onSuccess = () => {},
  onError = () => {}
) => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();

  const { mutateDeleteDraft, isLoading } = useDeleteJourneyDraftMutation(
    () => {
      setFlashMessage({
        message: `${journey?.name || 'Journey'}'s draft has been deleted.`,
        severity: 'info',
      });
      onSuccess();
    },
    () => {
      setFlashMessage({
        message: `There was an error deleting Draft of ${
          journey?.name || 'Journey'
        }. Please try again.`,
        severity: 'error',
      });
      onError();
    }
  );

  const deleteJourneyDraft = () => {
    if (journey?.id) {
      mutateDeleteDraft({ programId, journeyId: journey.id });
    }
  };

  return {
    call: deleteJourneyDraft,
    isLoading,
  };
};

export const useJourneyUnArchiveAction: JourneyAction = (
  journey,
  onSuccess = () => {},
  onError = () => {}
) => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();

  const { mutateUnArchive, isLoading } = useUnArchiveJourneyMutation(
    () => {
      setFlashMessage({
        message: `${journey?.name || 'Journey'} has been unarchived.`,
        severity: 'info',
      });
      onSuccess();
    },
    () => {
      setFlashMessage({
        message: `There was an error unarchiving ${
          journey?.name || 'Journey'
        }. Please try again.`,
        severity: 'error',
      });
      onError();
    }
  );

  const archiveJourney = () => {
    if (journey?.id) {
      mutateUnArchive({ programId, journeyId: journey.id });
    }
  };

  return {
    call: archiveJourney,
    isLoading,
  };
};
