import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { Sortable } from 'DesignSystem/Components';
import { useTopicAboutPageQuery } from 'hooks/topics';
import {
  LandingPageTab,
  LandingPageTabType,
  Topic,
  TopicErrors,
} from 'models/topic';
import React, {
  useCallback,
  useEffect,
  useState,
  ReactElement,
  useMemo,
} from 'react';
import { Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Section } from 'shared/SectionBlocks';
import { Overlay } from 'shared/Overlay/Overlay';
import styles from './form.module.css';
import { AboutControl } from './PagesControl/About/AboutControl';
import { PostsControl } from './PagesControl/Posts/PostsControl';

const defaultTabs: Record<
  LandingPageTabType.Posts | LandingPageTabType.About,
  LandingPageTab
> = {
  [LandingPageTabType.Posts]: {
    position: 0,
    isHidden: false,
    tabType: LandingPageTabType.Posts,
    pinnedContents: [],
  },
  [LandingPageTabType.About]: {
    position: 1,
    isHidden: true,
    tabType: LandingPageTabType.About,
  },
};

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>) => void;
  disabled?: boolean;
  errors?: TopicErrors;
};

PagesSection.defaultProps = {
  disabled: false,
  errors: undefined,
};

export function PagesSection({
  topic,
  onChange,
  disabled = false,
  errors,
}: PropsType): ReactElement {
  const { id: programId } = useProgram();

  const { isLoading, isEmpty: isAboutPageEmpty } = useTopicAboutPageQuery(
    programId,
    String(topic.id)
  );

  const [tabs, setTabs] = useState<LandingPageTab[]>([]);

  useEffect(() => {
    const landingPageTabs = topic.landingPageTabs ?? [];
    const existingTypes = new Set(landingPageTabs.map((tab) => tab.tabType));

    if (landingPageTabs.length < Object.keys(defaultTabs).length) {
      Object.values(defaultTabs).forEach((tab) => {
        if (!existingTypes.has(tab.tabType)) {
          landingPageTabs.push({
            ...tab,
            position: landingPageTabs.length,
          });
        }
      });
    }

    setTabs(
      landingPageTabs.sort((a, b) => Number(a.position) - Number(b.position))
    );
  }, [topic.landingPageTabs]);

  const onTabChange = useCallback(
    (updatedTab: LandingPageTab) => {
      const prevTabs = topic.landingPageTabs || tabs;

      const tabIndex = prevTabs.findIndex(
        (tab) => tab.tabType === updatedTab.tabType
      );

      if (tabIndex === -1) return;

      // Move tab to bottom of the list if it's getting hidden
      if (updatedTab.isHidden && !prevTabs[tabIndex].isHidden) {
        const updatedTabs = [
          ...prevTabs.slice(0, tabIndex),
          ...prevTabs.slice(tabIndex + 1),
          updatedTab,
        ].map((tab, index) => ({
          ...tab,
          position: index,
        }));

        onChange({
          landingPageTabs: updatedTabs,
        });
        return;
      }

      onChange({
        landingPageTabs: [
          ...prevTabs.slice(0, tabIndex),
          updatedTab,
          ...prevTabs.slice(tabIndex + 1),
        ],
      });
    },
    [onChange, topic, tabs]
  );

  const [visibleTabs, hiddenTabs] = useMemo(
    () =>
      tabs.reduce(
        (acc, tab) => {
          if (tab.isHidden) {
            acc[1].push(tab);
          } else {
            acc[0].push(tab);
          }
          return acc;
        },
        [[], []] as [LandingPageTab[], LandingPageTab[]]
      ),
    [tabs]
  );

  const onSort = (items: LandingPageTab[]) => {
    onChange({
      landingPageTabs: items.map((tab, index) => ({
        ...tab,
        position: index,
      })),
    });
  };

  const TabComponent = (tab: LandingPageTab) => {
    switch (tab.tabType) {
      case LandingPageTabType.Posts:
        return (
          <PostsControl
            topic={topic}
            tab={tab}
            onChange={onTabChange}
            disabled={disabled}
          />
        );
      case LandingPageTabType.About:
        return (
          <AboutControl
            tab={tab}
            onChange={onTabChange}
            isLoading={isLoading}
            isAboutPageEmpty={isAboutPageEmpty}
            disabled={disabled}
            error={errors?.landing_page_tabs?.about}
          />
        );
      default:
        return null;
    }
  };

  const disabledMessage = 'Section not available until topic is saved';

  return (
    <Section title="Pages">
      <div aria-label={disabled ? disabledMessage : undefined}>
        <Overlay show={disabled} hoverTooltipContent={disabledMessage}>
          <div aria-hidden={disabled}>
            <Fieldset className={styles.sortableFieldset}>
              {/* Visible pages */}
              <span className={cx(fieldsetStyles.label, styles.fieldsetLabel)}>
                Select which pages you’d like to be seen within the topic
              </span>
              <Sortable
                items={visibleTabs}
                idAttr="tabType"
                Item={({ item }) => (
                  <div className={styles.sortableItem}>
                    <SVGIcon name="DragPan" />
                    <div>{TabComponent(item)}</div>
                  </div>
                )}
                onSort={onSort}
              />

              {/* Hidden pages */}
              {hiddenTabs.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  <span
                    className={cx(fieldsetStyles.label, styles.fieldsetLabel)}
                  >
                    Hidden
                  </span>
                  <Sortable
                    items={hiddenTabs}
                    idAttr="tabType"
                    Item={({ item }) => (
                      <div className={styles.sortableItem}>
                        <SVGIcon name="DragPan" />
                        <div>{TabComponent(item)}</div>
                      </div>
                    )}
                    onSort={onSort}
                  />
                </div>
              )}
            </Fieldset>
          </div>
        </Overlay>
      </div>
    </Section>
  );
}
