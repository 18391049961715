import React from 'react';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { SelectableListItem } from 'App/Program/Main/Insight/components/Filters/shared/body/SelectableListItem';
import { DoneButton } from 'App/Program/Main/Insight/components/Filters/shared/footer/DoneButton';
import { ClearSelectionButton } from 'App/Program/Main/Insight/components/Filters/shared/header/ClearSelectionButton';
import { FilterDropdownBody } from 'App/Program/Main/Insight/components/Filters/shared/body/FilterDropdownBody';
import { FilterDropdownFooter } from 'App/Program/Main/Insight/components/Filters/shared/footer/FilterDropdownFooter';
import { FilterDropdownContainer } from 'App/Program/Main/Insight/components/Filters/shared/FilterDropdownContainer';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { ReportContext } from '../../../contexts/ReportContext';

type PropsType = {
  trueLabel?: string;
  falseLabel?: string;
};
export const BooleanDropdown: React.FC<PropsType> = ({
  trueLabel = 'Yes',
  falseLabel = 'No',
}) => {
  const {
    filter,
    defaultValue,
    setPillButtonDescriptionWithDefault,
  } = React.useContext(FilterDropdownContext);
  const { filtersStateAction } = React.useContext(FiltersStateContext);
  const { setShowReset } = React.useContext(ReportContext);

  // values for boolean filters are saved in api as strings; 'true', 'yes', 'y', 'false', 'no', and 'n'
  // within the react component, we will save the values internally as actual booleans
  const [value, setValue] = React.useState<boolean | undefined>();

  const defaultBoolean = React.useMemo(() => {
    if (defaultValue === undefined) return undefined;
    const normalizedDefaultValue = defaultValue.trim().toLowerCase();
    if (['true', 'yes', 'y'].includes(normalizedDefaultValue)) return true;
    if (['false', 'no', 'n'].includes(normalizedDefaultValue)) return false;

    // unknown value. treat as unknown
    return undefined;
  }, [defaultValue]);

  React.useEffect(() => {
    setValue(defaultBoolean);
  }, [defaultBoolean]);

  React.useEffect(() => {
    /* eslint-disable no-nested-ternary */
    const text =
      value === true ? trueLabel : value === false ? falseLabel : undefined;
    setPillButtonDescriptionWithDefault(text);

    filtersStateAction({
      action: 'setSingleFilterValue',
      filter,
      value,
    });
  }, [
    value,
    setPillButtonDescriptionWithDefault,
    trueLabel,
    falseLabel,
    filter,
    filtersStateAction,
  ]);

  const handleSetValue = (val: boolean | undefined) => {
    setShowReset(true);
    setValue(val);
  };

  return (
    <FilterDropdownContainer>
      <FilterDropdownBody>
        <SelectableListItem
          onClick={() => handleSetValue(true)}
          rowType="radio"
          isSelected={value === true}
          label={trueLabel}
        />
        <SelectableListItem
          onClick={() => handleSetValue(false)}
          rowType="radio"
          isSelected={value === false}
          label={falseLabel}
        />
      </FilterDropdownBody>
      <FilterDropdownFooter>
        <ClearSelectionButton onClick={() => setValue(undefined)} />
        <DoneButton />
      </FilterDropdownFooter>
    </FilterDropdownContainer>
  );
};
