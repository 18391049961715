import React from 'react';
import cx from 'classnames';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { titleCase } from 'utility/text';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import { Post } from 'models/publisher/post';
import { NavLink } from 'shared/NavLink';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import styles from './review.module.css';
import { Info } from './Info';
import { Sidebar } from './Sidebar';
import { Summary } from './Summary';

export const ReviewComponent: React.FC<
  RouteComponentProps & {
    post: Post;
    isValid: boolean;
    errors: Array<string>;
    footer: React.ReactElement;
  }
> = ({ isValid, errors, post, footer }) => {
  const { PAGES } = useProductVocabulary();
  const header = (
    <>
      <h1 className="page-header">{titleCase(PAGES.PUBLISHER.REVIEW)}</h1>
      <nav className={styles.nav}>
        <NavLink to="summary">Summary</NavLink>
        <NavLink to="details">Details</NavLink>
      </nav>
    </>
  );

  const main = (
    <Router>
      <Info path="details" isValid={isValid} post={post} />
      <Summary path="summary" isValid={isValid} errors={errors} post={post} />
      <Redirect from="/" to="summary" noThrow />
      <Redirect from="*" to="/" noThrow default />
    </Router>
  );

  const sidebar = (
    <aside
      className={cx(styles.sidebar, {
        [styles.disabled]: !isValid,
      })}
    >
      <Sidebar isValid={isValid} />
    </aside>
  );

  const newEditorsFlag = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  return (
    <MainEditor
      splitBars={newEditorsFlag}
      header={header}
      main={main}
      sidebar={sidebar}
      footer={footer}
    />
  );
};
