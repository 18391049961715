import * as React from 'react';
import cx from 'classnames';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { usePublisher } from 'contexts/publisher';
import {
  CallToAction,
  extractImages,
  canSummaryHoldAllContentWithCustomCover,
} from 'models/publisher/call-to-action';
import { ImageData } from 'models/image';
import { FileDropZone } from 'shared/FileDropZone';
import { Button } from 'shared/Button';
import { Checkbox } from 'shared/Checkbox';
import { TextInput } from 'shared/TextInput';
import { ImageSlideIn } from 'shared/Library/ImageSlideIn';
import { uniqueBy } from 'utility/unique';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import {
  isImageBlock,
  isImagesBlock,
  isVideoBlock,
} from 'models/publisher/block';
import { customCoverLabel, isSimplePost } from 'models/publisher/post';
import { AltTextTooltip } from 'shared/AltTextTooltip';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import {
  useContentImageUploader,
  useProcessedContentImage,
} from 'hooks/useContentImage';
import { useCardEditor } from 'contexts/publisher/compose/card';
import { useFlashMessage } from 'contexts/flasher';
import { Alert, AlertType } from 'DesignSystem/Components';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { MAIcon } from 'shared/MAIcon';
import { SummaryInput } from './SummaryInput';
import { TitleInput } from './TitleInput';
import styles from './sidebar.module.css';

export const Sidebar: React.FC = () => {
  const publisher = usePublisher();
  const cardEditor = useCardEditor();
  const { setFlashMessage } = useFlashMessage();
  const { cta } = cardEditor;
  const { instances } = React.useContext(BlocksEditorContext);
  const variables = useFieldVariables();
  const images = React.useMemo(
    () =>
      uniqueBy(
        extractImages(
          instances.map((i) => i.block),
          variables
        ),
        (i) => i.url
      ),
    [instances, variables]
  );
  const { contentPermissions } = useSettings();
  const { id } = useProgram();
  const { data: newEditorFeatureFlag } = useFeatureFlagsQuery(
    id,
    'Studio.Publish.NewEditors'
  );

  const { data: AIGenerationFeatureFlag } = useFeatureFlagsQuery(
    id,
    'Studio.Publish.AI.CoverGeneration'
  );

  const { canEdit, errors, isLoading: isEditableLoading } = contentPermissions;

  const handleChange = React.useCallback(
    (data: Partial<CallToAction>) => {
      cardEditor.change({ ...cta, ...data });
    },
    [cardEditor, cta]
  );

  React.useEffect(() => {
    if (!cta.image && images.length > 0) {
      handleChange({ image: images[0] });
    }
  });

  const onUpload = React.useCallback(
    (data: ImageData) => {
      // The cover image must be the first image in the array, other components expect to access it there
      handleChange({
        image: data,
      });
    },
    [handleChange]
  );

  const uploader = useContentImageUploader({
    onUpload,
    onError: (message) => {
      setFlashMessage({
        severity: 'error',
        message,
      });
    },
  });
  useProcessedContentImage({ image: uploader.image, onProcessed: onUpload });

  const handleFileSelect = React.useCallback(
    (newFile: File) => {
      uploader.uploadFile(newFile);
    },
    [uploader]
  );

  const handleLibrarySelect = React.useCallback(
    (selections: ImageData[]) => uploader.uploadUrl(selections[0].url),
    [uploader]
  );

  const [isLibraryOpen, setIsLibraryOpen] = React.useState(false);

  const toggleLibrary = React.useCallback(
    () => setIsLibraryOpen(!isLibraryOpen),
    [setIsLibraryOpen, isLibraryOpen]
  );

  const firstBlock = instances.find(
    (instance) => instance.block.name !== 'email_link'
  )?.block;

  const showTitleFieldset =
    firstBlock &&
    (!cta.customEnabled ||
      !(isImageBlock(firstBlock) || isImagesBlock(firstBlock)));
  const showCustomImagesFieldset =
    firstBlock &&
    (!cta.customEnabled ||
      !(
        isImageBlock(firstBlock) ||
        isImagesBlock(firstBlock) ||
        isVideoBlock(firstBlock)
      ));
  const showCtaTextStrippedWarning =
    cta.preferCustom &&
    (!firstBlock || !isVideoBlock(firstBlock)) &&
    (!canSummaryHoldAllContentWithCustomCover(instances.map((i) => i.block)) ||
      !cta.useTextFromContent);

  if (!newEditorFeatureFlag) return null;
  const usingNewEditors = !!newEditorFeatureFlag.value;

  if (!AIGenerationFeatureFlag) return null;
  const usingAIGeneration = !!AIGenerationFeatureFlag.value;

  return (
    <div
      className={cx(styles.sidebar, {
        [styles.sidebarMarginOld]: !usingNewEditors,
        [styles.sidebarMargin]: usingNewEditors,
      })}
    >
      {!isEditableLoading && !canEdit && (
        <Alert
          type={AlertType.error}
          title="Editing unavailable"
          message={errors.map((error) => (
            <div>{error}</div>
          ))}
          bgColor="red"
          enableIcon
          icon={<MAIcon name="warning" />}
        />
      )}
      <strong className={styles.title}>
        {isSimplePost(publisher.post) ? 'Cover type in feed' : 'Cover'}
      </strong>
      {isSimplePost(publisher.post) && (
        <>
          <div>
            <fieldset>
              <div>
                <button
                  className={cx(styles['radio-button'], {
                    [styles.selected]: !cta.preferCustom,
                  })}
                  type="button"
                  onClick={() => handleChange({ preferCustom: false })}
                >
                  Standard
                </button>
                <button
                  className={cx(styles['radio-button'], {
                    [styles.selected]: cta.preferCustom,
                  })}
                  type="button"
                  onClick={() => handleChange({ preferCustom: true })}
                >
                  {customCoverLabel(publisher.post)}
                </button>
              </div>
            </fieldset>
          </div>
          {showCtaTextStrippedWarning && (
            <div className={styles.ctaTextStrippedWarning}>
              With the {customCoverLabel(publisher.post)} cover type, your
              single-block article is not visible to users. Instead, only the
              Description below will appear with your{' '}
              {customCoverLabel(publisher.post)?.toLowerCase()}.
            </div>
          )}
        </>
      )}
      <div className={styles.styles}>
        <fieldset>
          <label htmlFor="use-text-from-content" className="kai-flex-row">
            <Checkbox
              id="use-text-from-content"
              checked={cta.useTextFromContent}
              onChange={(useTextFromContent) =>
                handleChange({ useTextFromContent })
              }
              className={cx({
                [styles.disabledSetting]: !canEdit,
              })}
              disabled={!canEdit}
            />
            &nbsp;
            <span
              className={cx({
                [styles.disabledSetting]: !canEdit,
              })}
            >
              Use text {showCustomImagesFieldset && 'and images '}
              from content
            </span>
          </label>
        </fieldset>

        {showTitleFieldset && (
          <>
            <TitleInput
              errorMessage=""
              title={cta.title}
              disabled={cta.useTextFromContent || !canEdit}
              handleChange={handleChange}
              showAIGenerationButton={
                usingAIGeneration && !cta.useTextFromContent
              }
            />
          </>
        )}
        <>
          <SummaryInput
            errorMessage=""
            summary={cta.summary}
            disabled={cta.useTextFromContent || !canEdit}
            handleChange={handleChange}
            showAIGenerationButton={
              usingAIGeneration && !cta.useTextFromContent
            }
          />
        </>
        {showCustomImagesFieldset && (
          <>
            <fieldset>
              <legend>Image</legend>
              {!cta.useTextFromContent && (
                <div className={styles.ctaImageUploadWrapper}>
                  {uploader.isUploading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <FileDropZone
                        accept="image/*"
                        type="secondary"
                        dropLabel="Drop a cover image here"
                        onFileSelect={handleFileSelect}
                        iconName="Upload"
                        iconType="SVG"
                        disabled={!canEdit}
                        compact
                        shouldClear
                      >
                        Upload
                      </FileDropZone>
                      &nbsp;
                      <Button
                        type="primary"
                        onClick={toggleLibrary}
                        disabled={!canEdit}
                        compact
                      >
                        Library
                      </Button>
                      {isLibraryOpen && (
                        <ImageSlideIn
                          name="pub-card-sidebar-image-library"
                          maxSelections={1}
                          onClose={toggleLibrary}
                          onChange={handleLibrarySelect}
                          useModal={usingNewEditors}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </fieldset>
            {images.length > 0 && (
              <fieldset>
                {!cta.useTextFromContent && (
                  <legend>Select from content</legend>
                )}
                <div className={styles.cardImageWrapper}>
                  {images.map((i) => (
                    <button
                      key={i.url}
                      className={cx(styles.ctaImageOption, {
                        [styles.ctaImageOptionSelected]:
                          i.url === cta.image?.url,
                        [styles.ctaImageOptionDisabled]:
                          cta.useTextFromContent || !canEdit,
                      })}
                      type="button"
                      onClick={() =>
                        handleChange({
                          image: i,
                        })
                      }
                      disabled={!canEdit}
                    >
                      <div
                        className={styles.ctaImageOption}
                        style={{ backgroundImage: `url(${i.url})` }}
                      />
                    </button>
                  ))}
                </div>
              </fieldset>
            )}
            <fieldset className={styles.altTextFieldset}>
              <legend>
                Alt text
                <HoverTooltip
                  align="left"
                  content={
                    <Tooltip
                      description={
                        <AltTextTooltip className="tooltip-content" />
                      }
                    />
                  }
                />
              </legend>
              <TextInput
                disabled={cta.useTextFromContent || !canEdit}
                className="full-width text-input"
                value={cta.altText}
                maximum={150}
                onChange={cardEditor.handleAltTextChange}
              />
            </fieldset>
          </>
        )}
      </div>
    </div>
  );
};
