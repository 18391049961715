import * as React from 'react';
import { RelativeRange } from './DateRange';
import styles from './relative-control.module.css';

type PropsType = {
  dateRange: RelativeRange;
};

export const RelativeControl: React.FC<PropsType> = (props) => {
  const { dateRange } = props;

  return (
    <div className={styles.control}>
      <div>{dateRange.label}</div>
      <div className={styles.dateRange}>{dateRange.toAbsolute().format()}</div>
    </div>
  );
};
