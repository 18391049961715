import React from 'react';
import { useNavigate } from '@reach/router';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import {
  useActivateWorkflowTemplate,
  useArchiveWorkflowTemplate,
  useDemoteWorkflowTemplate,
  useDraftWorkflowTemplate,
  usePromoteWorkflowTemplate,
} from 'hooks/workflow-templates/actions';

type WorkflowTemplateCallback = (templateId: number) => void;
type WorkflowTemplateModalActions = {
  showingModal: boolean;
  showModal(): void;
  closeModal(): void;
};

export type WorkflowTemplateActions = {
  edit: WorkflowTemplateCallback;
  draft: WorkflowTemplateCallback;
  activate: WorkflowTemplateCallback;
  promote: WorkflowTemplateCallback;
  demote: WorkflowTemplateCallback;
  archive: WorkflowTemplateCallback;
} & {
  confirmArchive?: WorkflowTemplateModalActions;
  confirmPromote?: WorkflowTemplateModalActions;
  confirmDemote?: WorkflowTemplateModalActions;
};

export const useActions = (): WorkflowTemplateActions => {
  const [showingArchiveModal, setShowingArchiveModal] = React.useState(false);
  const [showingPromoteModal, setShowingPromoteModal] = React.useState(false);
  const [showingDemoteModal, setShowingDemoteModal] = React.useState(false);
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const confirmArchive = React.useMemo(
    () => ({
      showingModal: showingArchiveModal,
      showModal: () => setShowingArchiveModal(true),
      closeModal: () => setShowingArchiveModal(false),
    }),
    [showingArchiveModal, setShowingArchiveModal]
  );

  const confirmPromote = React.useMemo(
    () => ({
      showingModal: showingPromoteModal,
      showModal: () => setShowingPromoteModal(true),
      closeModal: () => setShowingPromoteModal(false),
    }),
    [showingPromoteModal, setShowingPromoteModal]
  );

  const confirmDemote = React.useMemo(
    () => ({
      showingModal: showingDemoteModal,
      showModal: () => setShowingDemoteModal(true),
      closeModal: () => setShowingDemoteModal(false),
    }),
    [showingDemoteModal, setShowingDemoteModal]
  );

  const handleSuccess = React.useCallback(
    (message: string) => () => {
      queryClient.invalidateQueries(['workflow-templates-infinite']);
      setFlashMessage({
        severity: 'info',
        message,
      });
    },
    [queryClient, setFlashMessage]
  );

  const handleError = React.useCallback(
    (message: string) => () => {
      setFlashMessage({
        severity: 'error',
        message,
      });
    },
    [setFlashMessage]
  );

  const edit = React.useCallback(
    (templateId: number) => {
      navigate(`/${programId}/configure/workflow-templates/edit/${templateId}`);
    },
    [programId, navigate]
  );

  const { draft } = useDraftWorkflowTemplate(programId, {
    onSuccess: handleSuccess('Put into draft successfully'),
    onError: handleError('Could not put template into draft'),
  });

  const { activate } = useActivateWorkflowTemplate(programId, {
    onSuccess: handleSuccess(
      'Template activated successfully. It is now ready to be used'
    ),
    onError: handleError('Could not activate template'),
  });

  const { promote } = usePromoteWorkflowTemplate(programId, {
    onSuccess: handleSuccess(
      'Template promoted successfully. It is now visible to other programs'
    ),
    onError: handleError('Could not promote template'),
  });

  const { demote } = useDemoteWorkflowTemplate(programId, {
    onSuccess: handleSuccess(
      'Template demoted successfully. It is now visible to this program only'
    ),
    onError: handleError('Could not demote template'),
  });

  const { archive } = useArchiveWorkflowTemplate(programId, {
    onSuccess: handleSuccess('Template archived successfully'),
    onError: handleError('Could not archive template'),
  });

  return {
    edit,
    draft,
    activate,
    promote,
    demote,
    archive,
    confirmArchive,
    confirmPromote,
    confirmDemote,
  };
};
