import * as React from 'react';
import cx from 'classnames';
import { pluralize } from 'utility/text';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Masonry } from 'shared/Masonry';
import { Item, Content, Blocks, Image } from 'models/library';
import { BASE_STYLING } from 'models/publisher/style';
import { usePublisher } from 'contexts/publisher';
import { AutoSized } from 'shared/publisher/PostPreview';
import { Trash } from 'shared/icons';
import { FormModal } from 'DesignSystem/Components';
import { UseCollection } from './useCollection';
import styles from './items.module.css';

// Most library items just show the thumbnail provided
function ThumbnailItem<T extends Item>(props: { item: T }) {
  const { item } = props;
  return (
    <img
      className={styles.thumbnail}
      alt={item.title}
      src={item.thumbnail_images[0]?.url}
    />
  );
}

// But content items show the title, summary, and maybe an image
function ContentItem(props: { item: Content }) {
  const { item } = props;

  return (
    <div>
      {item.asset.image_url ? (
        <img
          className={styles.thumbnail}
          alt={item.title}
          src={item.asset.image_url}
        />
      ) : (
        <span className={styles.letters}>{item.title.substr(0, 2)}</span>
      )}
      <div className={styles.contentData}>
        <strong>{item.title}</strong>
        <p>{item.description}</p>
      </div>
    </div>
  );
}

function BlocksItem(props: { item: Blocks }) {
  const { item } = props;
  const { post: publisher } = usePublisher();
  const blocks = item.asset.preview_blocks || item.asset.blocks;
  const post = { blocks, settings: publisher.settings, styles: BASE_STYLING };
  return (
    <div className={styles.block} title={item.title}>
      <AutoSized post={post} />
    </div>
  );
}

function renderItem(item: Item) {
  switch (item.type) {
    case 'content_blocks':
      return <BlocksItem item={item as Blocks} />;
    case 'content_image':
      return <ThumbnailItem item={item as Image} />;
    case 'content_post':
      return <ContentItem item={item as Content} />;
    default:
      return null;
  }
}

export function Items<T extends Item>(props: {
  libraryType: Image['type'] | Content['type'] | Blocks['type'];
  spinnersCount: number;
  filter: UseCollection<T>['filter'];
  isLoading: boolean;
  isSelected: UseCollection<T>['isSelected'];
  items: UseCollection<T>['items'];
  label: string;
  maxSelections: number;
  select: UseCollection<T>['select'];
  selectedCount: number;
  unselect: UseCollection<T>['unselect'];
  canModifyCollection: boolean;
  handleDeleteItem: (item: T) => void;
  searchText?: string;
}): React.ReactElement {
  const {
    filter,
    isLoading,
    isSelected,
    items = [],
    label,
    maxSelections,
    selectedCount,
    select,
    unselect,
    libraryType,
    canModifyCollection,
    spinnersCount,
    handleDeleteItem,
    searchText,
  } = props;

  const [itemToDelete, setItemToDelete] = React.useState<T>();

  const removeItem = React.useCallback(() => {
    if (!itemToDelete) return;

    unselect(itemToDelete);
    handleDeleteItem(itemToDelete);
    setItemToDelete(undefined);
  }, [handleDeleteItem, itemToDelete, unselect]);

  const renderItemHook = React.useCallback(
    (item) => {
      function selectItem(i: T) {
        if (isSelected(i)) unselect(i);
        else select(i);
      }

      if (
        item.type === 'content_image' ||
        item.type === 'content_blocks' ||
        item.type === 'content_template'
      ) {
        return (
          <div
            key={item.id}
            data-test="picker-item"
            className={cx(styles.item, {
              [styles.selectable]: maxSelections >= selectedCount,
            })}
          >
            {renderItem(item)}
            {filter.type !== 'selected' && isSelected(item) ? (
              <div className={styles.selection} />
            ) : null}
            {maxSelections !== selectedCount && (
              <button
                type="button"
                onClick={() => selectItem(item)}
                data-test={`use:${item.identifier}`}
              >
                {isSelected(item) ? 'Unselect' : 'Select'}
              </button>
            )}
            {canModifyCollection && (
              <>
                <button
                  className={styles.closeButton}
                  type="button"
                  onClick={() => setItemToDelete(item)}
                >
                  <Trash />
                </button>
              </>
            )}
            {maxSelections === selectedCount && isSelected(item) && (
              <button type="button" onClick={() => selectItem(item)}>
                Unselect
              </button>
            )}
          </div>
        );
      }
      return (
        <div className={styles.spinnerItem}>
          <LoadingSpinner />
        </div>
      );
    },
    [
      isSelected,
      unselect,
      select,
      maxSelections,
      selectedCount,
      filter.type,
      canModifyCollection,
      setItemToDelete,
    ]
  );

  const spinners = React.useMemo(() => {
    const spinnerItem = {
      type: 'spinner',
    };
    const arr = [];
    let counter = 0;
    while (arr.length < spinnersCount) {
      arr.push({
        ...spinnerItem,
        id: `spinner-${counter}`,
      });
      counter += 1;
    }
    return arr;
  }, [spinnersCount]);

  if (isLoading && !items.length) {
    return (
      <div className={styles.notReady}>
        Loading some {pluralize(label)} for you...
        <br />
        <br />
        <br />
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !items.length &&
    libraryType !== 'content_image' &&
    searchText?.length !== 0
  ) {
    return (
      <div className={styles.notReady}>
        No {pluralize(label)} found, try broadening your search.
      </div>
    );
  }

  return (
    <main className={styles.items}>
      <Masonry
        columns={3}
        items={[...spinners, ...items]}
        render={renderItemHook}
      />
      {itemToDelete && (
        <FormModal
          entityText={label}
          actionText="delete"
          onSubmit={removeItem}
          onCancel={() => setItemToDelete(undefined)}
          width={300}
        >
          {`Are you sure you want to delete this ${label}?`}
        </FormModal>
      )}
    </main>
  );
}
