import React, { useCallback } from 'react';
import { Content as LibraryContent } from 'models/library';
import { useLibraryContents } from 'hooks/useLibrary';
import { PickerScreen } from './ContentPickerScreen';

export const ContentPicker: React.FC<{
  onChange: (content: LibraryContent[]) => void;
  maxSelections: number;
}> = ({ onChange, maxSelections }) => {
  const change = useCallback(
    (items: Array<LibraryContent>) => onChange(items as Array<LibraryContent>),
    [onChange]
  );

  const onCancel = useCallback(() => onChange([]), [onChange]);

  return (
    <PickerScreen
      useLibraryItems={useLibraryContents}
      label="content"
      maxSelections={maxSelections}
      onChange={change}
      onCancel={onCancel}
    />
  );
};
