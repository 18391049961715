import * as React from 'react';
import { Button } from 'DesignSystem/Form';
import { Icon } from 'shared/Icon';
import styles from './journey-drawer.module.css';

export type DrawerType = {
  /**
   * Called when the drawer state changes via user interaction with the drawer buttons.
   * */
  onChange?: (newState: DrawerState) => void;

  /**
   * If state is provided, the Drawer will be considered controlled and
   * the parent component becomes responsible for maintaining the state of the drawer.
   * */
  state?: DrawerState;
};

export enum DrawerState {
  Closed,
  Partial,
  Maximized,
}

enum DrawerAction {
  Open,
  Close,
  Maximize,
  Shrink,
}

export const Drawer: React.FC<DrawerType> = ({
  children,
  onChange,
  state: providedState,
}) => {
  const [internalState, setInternalState] = React.useState<DrawerState>(
    DrawerState.Closed
  );
  // If state is provided from the parent, we consider the drawer to be controlled and
  // use the provided state instead of the internal state.
  const drawerState = providedState ?? internalState;

  const setDrawerState = (newState: DrawerState) => {
    if (providedState === undefined) {
      setInternalState(newState);
    }
    onChange?.(newState);
  };

  const { actions, className } = {
    [DrawerState.Closed]: {
      actions: [DrawerAction.Open],
      className: styles.closed,
    },
    [DrawerState.Partial]: {
      actions: [DrawerAction.Maximize, DrawerAction.Close],
      className: styles.partial,
    },
    [DrawerState.Maximized]: {
      actions: [DrawerAction.Shrink, DrawerAction.Close],
      className: styles.maximized,
    },
  }[drawerState];

  return (
    <div className={`${styles.journeyDrawer} ${className}`}>
      <div className={styles.journeyDrawerButtons}>
        {actions.map((action) => (
          <DrawerButton key={action} action={action} onClick={setDrawerState} />
        ))}
      </div>
      {children}
    </div>
  );
};

const DrawerButton: React.FC<{
  action: DrawerAction;
  onClick: (newState: DrawerState) => void;
}> = ({ action, onClick }) => {
  const buttonProps = {
    [DrawerAction.Open]: {
      id: 'DrawerOpenButton',
      iconName: 'BottomPanelOpen',
      title: 'Open Drawer',
      newState: DrawerState.Partial,
    },
    [DrawerAction.Close]: {
      id: 'DrawerCloseButton',
      iconName: 'Close',
      title: 'Close Drawer',
      newState: DrawerState.Closed,
      size: 14,
    },
    [DrawerAction.Maximize]: {
      id: 'DrawerMaximizeButton',
      iconName: 'ExpandList',
      title: 'Maximize Drawer',
      newState: DrawerState.Maximized,
    },
    [DrawerAction.Shrink]: {
      id: 'DrawerShrinkButton',
      iconName: 'ShrinkList',
      title: 'Shrink Drawer',
      newState: DrawerState.Partial,
    },
  }[action];

  return (
    <Button
      id={buttonProps.id}
      className={styles.journeyDrawerButton}
      icon={
        <Icon
          iconName={buttonProps.iconName}
          iconType="SVG"
          size={buttonProps.size}
        />
      }
      title={buttonProps.title}
      onClick={() => onClick(buttonProps.newState)}
      secondary
      compact
      borderless
    />
  );
};
