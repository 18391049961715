import {
  MutationOptions,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  BoxFolderData,
  FetchProps,
  fetchBoxFoldersPage,
  createBoxFolder,
  CreateBoxFolderData,
  createBoxFolderAudienceMapping,
  deleteBoxMangementFolder,
  fetchById,
  deleteBoxFolderAudienceMapping,
  UpdateFolderData,
  updateBoxFolder,
  BoxFolderDataPaginated,
  BoxFolderCreator,
  fetchBoxFolderCreators,
} from 'services/api-box-mangement';
import { useProgramIdState } from 'contexts/program';
import {
  InfiniteQueryResponse,
  nextPageToFetch,
  QueryResponse,
} from './common';

export const BOX_MANAGEMENT_INFINITE_QUERY_KEY = 'box-management-infinite';
export const BOX_MANAGEMENT_DELETE_FOLDER_MAPPING_QUERY_KEY =
  'box-delete-folder-maping';

export const useBoxFoldersInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<BoxFolderData> => {
  const { programId, pageSize = 20, q = '', creatorIds } = props;
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<BoxFolderDataPaginated, Error>(
    [BOX_MANAGEMENT_INFINITE_QUERY_KEY, JSON.stringify(props)],
    async ({ pageParam = 1 }) =>
      fetchBoxFoldersPage({
        programId,
        page: pageParam,
        pageSize,
        q,
        creatorIds,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const batchData = data?.pages.map((batch) => (batch ? batch.data : []));
  const flatData = batchData && batchData.flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

export const useBoxFolderByIdQuery = (
  programId: number,
  id: number
): QueryResponse<BoxFolderData> => {
  const { isLoading, error, data } = useQuery<BoxFolderData, Error>({
    queryKey: ['box-mapping-folder', programId, id],
    queryFn: () => fetchById(programId, id),
    cacheTime: 0,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
export const useBoxFolderCreatorsQuery = (
  programId: number
): QueryResponse<BoxFolderCreator[]> => {
  const { isLoading, error, data } = useQuery<BoxFolderCreator[], Error>({
    queryKey: ['box-mapping-folder-creators', programId],
    queryFn: () => fetchBoxFolderCreators(programId),
    cacheTime: 0,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

type CreateBoxFolderMutationResult = UseMutationResult<
  BoxFolderData,
  Error,
  CreateBoxFolderData
>;

export const useCreateBoxFolder = ({
  onSuccess,
  onError,
}: MutationOptions<BoxFolderData, Error, CreateBoxFolderData> = {}): Pick<
  CreateBoxFolderMutationResult,
  'isLoading'
> & {
  create: CreateBoxFolderMutationResult['mutate'];
  createAsync: CreateBoxFolderMutationResult['mutateAsync'];
} => {
  const mutation = useMutation<BoxFolderData, Error, CreateBoxFolderData>(
    createBoxFolder,
    {
      onSuccess,
      onError,
    }
  );

  return {
    create: mutation.mutate,
    createAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

type UpdateBoxFolderMutationResult = UseMutationResult<
  BoxFolderData,
  Error,
  UpdateFolderData
>;

export const useUpdateBoxFolder = ({
  onSuccess,
  onError,
}: MutationOptions<BoxFolderData, Error, UpdateFolderData> = {}): Pick<
  UpdateBoxFolderMutationResult,
  'isLoading'
> & {
  update: UpdateBoxFolderMutationResult['mutate'];
  updateAsync: UpdateBoxFolderMutationResult['mutateAsync'];
} => {
  const mutation = useMutation<BoxFolderData, Error, UpdateFolderData>(
    updateBoxFolder,
    {
      onSuccess,
      onError,
    }
  );

  return {
    update: mutation.mutate,
    updateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

type BulkCreateBoxFolderAudienceMappingData = {
  programId: number;
  folderId: number;
  audienceIds: string[];
};

type BulkCreateBoxFolderAudienceMappingMutationResult = UseMutationResult<
  void[],
  Error,
  BulkCreateBoxFolderAudienceMappingData
>;

export const useBulkCreateBoxFolderAudienceMapping = ({
  onSuccess,
  onError,
}: MutationOptions<
  void[],
  Error,
  BulkCreateBoxFolderAudienceMappingData
> = {}): Pick<BulkCreateBoxFolderAudienceMappingMutationResult, 'isLoading'> & {
  bulkCreate: BulkCreateBoxFolderAudienceMappingMutationResult['mutate'];
  bulkCreateAsync: BulkCreateBoxFolderAudienceMappingMutationResult['mutateAsync'];
} => {
  const mutation = useMutation<
    void[],
    Error,
    BulkCreateBoxFolderAudienceMappingData
  >(
    ({ programId, folderId, audienceIds }) => {
      return Promise.all(
        audienceIds.map((audienceId) =>
          createBoxFolderAudienceMapping(programId, folderId, audienceId)
        )
      );
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    bulkCreate: mutation.mutate,
    bulkCreateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

export const useBulkDeleteBoxFolderAudienceMapping = ({
  onSuccess,
  onError,
}: MutationOptions<
  void[],
  Error,
  BulkCreateBoxFolderAudienceMappingData
> = {}): Pick<BulkCreateBoxFolderAudienceMappingMutationResult, 'isLoading'> & {
  bulkDelete: BulkCreateBoxFolderAudienceMappingMutationResult['mutate'];
  bulkDeleteAsync: BulkCreateBoxFolderAudienceMappingMutationResult['mutateAsync'];
} => {
  const mutation = useMutation<
    void[],
    Error,
    BulkCreateBoxFolderAudienceMappingData
  >(
    ({ programId, folderId, audienceIds }) => {
      return Promise.all(
        audienceIds.map((audienceId) =>
          deleteBoxFolderAudienceMapping(programId, folderId, audienceId)
        )
      );
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    bulkDelete: mutation.mutate,
    bulkDeleteAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

export const useDeleteBoxFolderMapping = (
  options: UseMutationOptions<void, Error, BoxFolderData['boxFolderId']> = {}
): UseMutationResult<void, Error, BoxFolderData['boxFolderId']> => {
  const client = useQueryClient();
  const [programId] = useProgramIdState();
  const mutationFn = (boxFolderId: BoxFolderData['boxFolderId']) =>
    deleteBoxMangementFolder(programId, boxFolderId);

  return useMutation(mutationFn, {
    mutationKey: [BOX_MANAGEMENT_DELETE_FOLDER_MAPPING_QUERY_KEY],
    onSuccess: () => {
      client.invalidateQueries(BOX_MANAGEMENT_INFINITE_QUERY_KEY);
    },
    ...options,
  });
};
