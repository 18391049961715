import React from 'react';
import { Checkbox, CheckboxLabel } from '@socialchorus/shared-ui-components';
import { useUniqueId } from 'hooks/useUniqueId';
import { usePrevious } from 'hooks/use-previous';
import styles from './checkbox-group.module.css';

type CheckboxGroupProps = {
  label: string;
  icon?: React.ReactNode;
  isChecked: boolean;
  toggleChecked: (checked: boolean | 'indeterminate') => void;
  children: React.ReactNode;
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  isChecked,
  toggleChecked,
  children,
  icon,
}) => {
  const checkboxId = useUniqueId();
  const prevChecked = usePrevious(isChecked);
  // Scroll the group into view if it was just checked, but not if it was already checked
  // (i.e. it was checked by default)
  const userChecked = prevChecked === false && isChecked;

  // We don't want to scroll the group into view on the first render because it will
  // cause the page to jump around.
  const isFirstRender = usePrevious(prevChecked);
  const scrollRefIntoView = React.useCallback(
    (node: HTMLDivElement) => {
      if (!isFirstRender || !node || !userChecked) return;
      node?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    [isFirstRender, userChecked]
  );

  const labelId = `${checkboxId}__label`;
  return (
    <>
      <Checkbox
        id={checkboxId}
        checked={isChecked}
        aria-labelledby={labelId}
        onCheckedChange={(checked) => toggleChecked(checked)}
      >
        <CheckboxLabel htmlFor={checkboxId} id={labelId}>
          {icon}
          {label}
        </CheckboxLabel>
      </Checkbox>
      {isChecked && (
        <div ref={scrollRefIntoView} className={styles.checkboxGroupContainer}>
          {children}
        </div>
      )}
    </>
  );
};
