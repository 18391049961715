import React from 'react';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useUserByIdsQuery, useUsersInfiniteQuery } from 'hooks/user';
import { useProgram } from 'contexts/program';
import { OptionType } from 'hooks/common';
import { useDebounce } from 'hooks/useDebounce';

type PropsType = {
  values?: string[];
  onChange: (filterName: string, values: string[]) => void;
};

export const UsersFilter: React.FC<PropsType> = ({ values, onChange }) => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = React.useState<string>();

  const {
    data: selectedUsers,
    isLoading: selectedUsersLoading,
  } = useUserByIdsQuery(programId, values?.map((v) => Number(v)) || [-1]);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useUsersInfiniteQuery({ programId, search: useDebounce(searchTerm) });

  const selectedOptions = React.useMemo<OptionType[]>(() => {
    return (
      selectedUsers?.map(
        (user): OptionType => {
          return {
            value: String(user.id),
            label: `${user.firstName} ${user.lastName}`,
          };
        }
      ) || []
    );
  }, [selectedUsers]);

  const options = React.useMemo<OptionType[]>(() => {
    const notSelected = data
      .filter((u) => !values?.includes(String(u.id)))
      .map(
        (user): OptionType => {
          return {
            value: String(user.id),
            label: `${user.firstName} ${user.lastName}`,
          };
        }
      );
    return [...selectedOptions, ...notSelected].sort((a, b) => {
      const aLower = a.label.toLowerCase();
      const bLower = b.label.toLowerCase();
      if (aLower < bLower) return -1;
      if (aLower > bLower) return 1;
      return 0;
    });
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <FilterDropdown
      onChange={(value) => {
        onChange('users', value);
      }}
      options={options}
      selectedOptions={selectedOptions}
      label="User"
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      isLoading={isLoading || selectedUsersLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      maxHeight={400}
    />
  );
};
