import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAudienceQuery } from 'hooks/audience';
import { useProgram } from 'contexts/program';
import { useStickyNavBarContext } from 'contexts/sticky-navbar';
import { Audience, defaultAudience } from 'models/audience';
import { FacetsSidebar } from 'components/audience/FacetsSidebar';
import { useFlashMessage } from 'contexts/flasher';
import { EditAudience } from './EditAudience';
import styles from './AudienceBuilder.module.css';

const EditAudienceWithFacets: React.FC<{
  audience: Audience;
  onSave?: (data: Audience) => void;
}> = ({ audience, onSave }) => {
  const [audienceState, setAudienceState] = useState(audience);
  return (
    <div className={styles.fullPage}>
      <div className={styles.leftPanel}>
        <EditAudience
          audience={audience}
          onSave={onSave}
          onChange={setAudienceState}
        />
      </div>

      <div className={styles.sidebar}>
        <FacetsSidebar audience={audienceState} />
      </div>
    </div>
  );
};

const EditNewAudience: React.FC<{
  onSave?: (data: Audience) => void;
  query?: string;
}> = ({ onSave, query }) => {
  const { setFlashMessage } = useFlashMessage();

  const onCreateNewAudience = (audience: Audience) => {
    if (onSave) onSave(audience);
    setFlashMessage({
      severity: 'info',
      message: 'Audience created successfully',
    });
  };

  const { id: programId } = useProgram();
  const audience = defaultAudience({ programId, query });
  return (
    <EditAudienceWithFacets audience={audience} onSave={onCreateNewAudience} />
  );
};

const SnapshotBuilding: React.FC = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries(['audience']);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  });
  return <>Please wait...</>;
};

const EditExistingAudience: React.FC<{
  id: string;
  onSave?: (data: Audience) => void;
}> = ({ id, onSave }) => {
  const { setFlashMessage } = useFlashMessage();

  const onAudienceUpdated = (audience: Audience) => {
    if (onSave) onSave(audience);
    setFlashMessage({
      severity: 'info',
      message: 'Audience updated successfully',
    });
  };
  const { id: programId } = useProgram();
  const { isLoading, errorMessage, data } = useAudienceQuery(programId, id);
  if (isLoading) {
    return <>Loading...</>;
  }
  if (data && data.type === 'snapshot' && data.state === 'building') {
    return <SnapshotBuilding />;
  }
  if (data) {
    return (
      <EditAudienceWithFacets
        audience={data}
        key={id}
        onSave={onAudienceUpdated}
      />
    );
  }
  return <>Error: {errorMessage || 'An unexpected error occurred'}</>;
};

export const AudienceBuilder: React.FC<{
  id?: string; // supply if editing an existing audience
  onSave?: (data: Audience) => void; // optional, called on successful save
  query?: string; // optional, initial query when creating a new audience
}> = ({ id, onSave, query }) => {
  const { setIsShowStickyNavBar } = useStickyNavBarContext();
  setIsShowStickyNavBar(false);

  if (id) {
    return <EditExistingAudience id={id} onSave={onSave} />;
  }
  return <EditNewAudience onSave={onSave} query={query} />;
};
