import { useQuery } from 'react-query';
import {
  fetchImportConfigurationDobFormat,
  ImportConfigurationData,
} from 'services/api-import-configurations';
import { QueryResponse } from './common';

export const useImportConfigurationQuery = (
  programId: number
): QueryResponse<ImportConfigurationData> => {
  const { isLoading, error, data } = useQuery<ImportConfigurationData, Error>({
    queryKey: `import-configuration-${programId}`,
    queryFn: () => fetchImportConfigurationDobFormat(programId),
    cacheTime: 0,
    retry: false,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
