import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { MAIcon } from 'shared/MAIcon';
import { useClickDropdown } from 'shared/ClickDropdown';
import { MenuItemsType, DropdownMenu } from 'shared/NavigationFooter';
import { titleCase } from 'utility/text';
import { TextInput } from 'shared/TextInput';
import { Box } from 'DesignSystem/Components';
import { StatusTag, TitleBarStatus } from './utils';
import styles from './navigation-bars.module.css';

export const TitleBar: React.FC<{
  DropdownComponent: ReturnType<typeof useClickDropdown>['ClickDropdown'];
  canEdit: boolean;
  createType: string;
  hideMenu?: boolean;
  leftMenuIsOpen?: boolean;
  leftMenuItems?: MenuItemsType;
  title?: string;
  hasEditableTitle?: boolean;
  updateTitle?: (title: string) => void;
  status?: TitleBarStatus;
}> = ({
  DropdownComponent,
  canEdit,
  createType,
  hideMenu,
  leftMenuIsOpen,
  leftMenuItems,
  title,
  hasEditableTitle,
  updateTitle,
  status,
}) => (
  <Flex>
    <Type.Heading color={Type.color.gray40}>
      {titleCase(`create ${createType} `)}/
    </Type.Heading>
    <Box padding={[0, 8]} className={styles.titleBarControls}>
      {!hasEditableTitle ||
        (!canEdit && (
          <PlainTitle title={title ?? ''} visible={!!(hideMenu && title)} />
        ))}
      {hasEditableTitle && canEdit && updateTitle && (
        <EditableTitle title={title ?? ''} updateTitle={updateTitle} />
      )}
      <TitleMenu
        visible={!!(!hideMenu && leftMenuItems && canEdit)}
        DropdownComponent={DropdownComponent}
        leftMenuItems={leftMenuItems}
        title={title}
        leftMenuIsOpen={leftMenuIsOpen || false}
      />
      {status && <StatusTag status={status} />}
    </Box>
  </Flex>
);

const EditableTitle: React.FC<{
  title: string;
  updateTitle: (title: string) => void;
}> = ({ title, updateTitle }) => {
  return <TextInput value={title} onChange={updateTitle} />;
};

const PlainTitle: React.FC<{ visible: boolean; title: string }> = ({
  visible,
  title,
}) =>
  !visible ? null : (
    <Type.Heading bold color={Type.color.gray90}>
      {title}
    </Type.Heading>
  );

const TitleMenu: React.FC<{
  visible: boolean;
  DropdownComponent: ReturnType<typeof useClickDropdown>['ClickDropdown'];
  leftMenuItems?: MenuItemsType;
  title?: string;
  leftMenuIsOpen: boolean;
}> = ({
  visible,
  DropdownComponent,
  leftMenuItems = {},
  title = '',
  leftMenuIsOpen,
}) =>
  !visible ? null : (
    <DropdownComponent
      dropdownRenderProp={(hideDropdown) => (
        <DropdownMenu hideDropdown={hideDropdown} menuItems={leftMenuItems} />
      )}
    >
      <Flex>
        <Type.Heading bold color={Type.color.gray90}>
          {title}
        </Type.Heading>
        <MAIcon name={leftMenuIsOpen ? 'expand_less' : 'expand_more'} />
      </Flex>
    </DropdownComponent>
  );
