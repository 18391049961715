import React, { createContext, useContext } from 'react';
import { Permissions } from 'models/permissions';
import { defaultPermissions } from 'data/permission';

const PermissionsContext = createContext(defaultPermissions);

export const usePermissions = (): Permissions => {
  const context = useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(
      'Permissions context hooks require a containing PermissionsProvider'
    );
  }
  return context;
};

export const PermissionsProvider: React.FC<{ value?: Permissions }> = ({
  children,
  value,
}) => (
  <PermissionsContext.Provider value={value || defaultPermissions}>
    {children}
  </PermissionsContext.Provider>
);
