import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import cx from 'classnames';
import { Alert, AlertType, Box, FormModal } from 'DesignSystem/Components';
import { FieldInput, Button } from 'DesignSystem/Form';
import { useFlashMessage } from 'contexts/flasher';
import { useUploadPersonalizedFieldsFile } from 'hooks/personalized-fields';
import { PersonalizedFieldsUploadErrors } from 'services/api-personalized-fields';
import { MAIcon } from 'shared/MAIcon';
import { FileDropZone } from 'shared/FileDropZone';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from '../styles.module.css';

export type UploadModalResult = { id: number | null; name?: string };
export type UploadModalConfig = { type: 'upload' };

const example = `Employee ID,Reward Points,Manager's Name
8502753,100,Robert
8264583,100,Jennifer
8927443,150,Jennifer
8801749,100,Leah
8015288,200,Tom
8566702,100,Robert
8089912,150,Tom
8673344,200,Leah`;

const downloadExampleCSV = () => {
  const BOM = '\ufeff';
  if (navigator.msSaveBlob) {
    const blob = new Blob([BOM + example], { type: 'text/csv;charset=utf-8;' });
    navigator.msSaveBlob(blob, 'template_example.csv');
  } else {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
      BOM + example
    )}`;
    downloadLink.download = 'template_example.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const UploadFileModal: React.FC<{
  onCancel: () => void;
  onSubmit: (result: {
    result: UploadModalResult;
    errors?: PersonalizedFieldsUploadErrors;
  }) => void;
}> = ({ onCancel, onSubmit }) => {
  const { uploadFile, validateFile } = useUploadPersonalizedFieldsFile();
  const { setFlashMessage } = useFlashMessage();

  const [isDragging, setIsDragging] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onUploadRegionClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (target.className === styles.ExampleCSVLink) downloadExampleCSV();
      else if (target.closest('button')) inputRef.current?.click();
    },
    [inputRef]
  );

  const fileSelected = React.useCallback(
    async (file: File) => {
      setIsUploading(true);
      try {
        const data = await validateFile(await uploadFile(file));
        const result: UploadModalResult = {
          id: 'id' in data ? data.id : null,
          name: file.name,
        };
        if (result.id) onSubmit({ result });
        else
          onSubmit({ result, errors: data as PersonalizedFieldsUploadErrors });
      } catch ({ message }) {
        setFlashMessage({ severity: 'error', message: message as string });
      } finally {
        setIsUploading(false);
      }
    },
    [uploadFile, validateFile, onSubmit, setFlashMessage]
  );

  const onUpload = async ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const file = currentTarget.files?.[0];
    if (file) fileSelected(file);
  };

  return (
    <FormModal
      actionText="Upload Dataset File"
      entityText=""
      submitButton={<></>}
      secondaryButton={<></>}
      onCancel={onCancel}
    >
      <FieldInput>
        {isUploading ? (
          <Box className={styles.Placeholder}>
            <LoadingSpinner />
            <Text.Body block>Uploading file...</Text.Body>
          </Box>
        ) : (
          <>
            <Alert
              type={AlertType.info}
              compact
              enableIcon
              icon={<MAIcon name="info" />}
              message={
                <>
                  <Box>
                    UTF-8 encoded CSV files are required for accented or
                    non-English characters to appear correctly.
                  </Box>
                  <Box>
                    Columns will become Personalized Field variables, with an
                    Email or Employee ID column required.
                  </Box>
                </>
              }
            />
            <Box
              className={cx(styles.Placeholder, {
                [styles.Dragging]: isDragging,
              })}
              onClick={onUploadRegionClick}
            >
              <FileDropZone
                accept="image/*"
                shouldClear
                dropLabel=""
                withButton={false}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={() => setIsDragging(false)}
                onFileSelect={fileSelected}
                disabled={isUploading}
              >
                <Text.Subheading bold>Drop file to upload</Text.Subheading>
                <Text.Body>or</Text.Body>
                <Button label="Select File" />
                <div className={styles.ExampleCSVLink}>
                  Download example CSV file
                </div>
              </FileDropZone>
            </Box>
            <div className={styles.CSVInput}>
              <input
                ref={inputRef}
                type="file"
                accept="text/csv"
                onChange={onUpload}
              />
            </div>
          </>
        )}
      </FieldInput>
    </FormModal>
  );
};
