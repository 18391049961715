import { useJourneyState } from 'contexts/journeys/journey';
import { TimeDelta, useTimeDelta } from 'hooks/useTimeDelta';

export enum CloudStatus {
  UpToDate,
  OutOfDate,
  NotSaved,
}

export const useJourneyPersistenceStatus = (): {
  timeDelta: TimeDelta;
  cloudStatus: CloudStatus;
} => {
  const { journey, lastModified, lastSaveSuccess } = useJourneyState();

  const timeDelta = useTimeDelta(lastSaveSuccess);

  let status = CloudStatus.NotSaved;
  if (journey && journey.id && lastSaveSuccess) {
    status = CloudStatus.UpToDate;
  }

  if (journey && journey.id && lastModified && lastSaveSuccess) {
    if (lastModified.toMillis() <= lastSaveSuccess.toMillis()) {
      status = CloudStatus.UpToDate;
    } else {
      status = CloudStatus.OutOfDate;
    }
  }

  return { timeDelta, cloudStatus: status };
};
