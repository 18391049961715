import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Video } from 'models/video';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from '../video.module.css';

export const ExternalVideo: React.FC<{
  video?: Video;
  isLoading: boolean;
}> = ({ video, isLoading }) => {
  const fitEmbedHtmlIframeWidthHeight = React.useCallback((html: string) => {
    const regex = /width="[0-9]*" height="[0-9]*"/g;
    return html.replace(regex, 'width="340px" height="200px"');
  }, []);

  if (isLoading)
    return (
      <div
        className={styles.processingVideoContainer}
        style={{ height: '200px' }}
      >
        <LoadingSpinner />
      </div>
    );

  return (
    <Flex
      /* This comes from our server and we trust it */
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{
        __html: fitEmbedHtmlIframeWidthHeight(video?.embedHtml || ''),
      }}
    />
  );
};
