import React from 'react';
import cx from 'classnames';
import { ExternalSource } from 'models/external-source';
import { Field, Fieldset } from 'shared/Fieldset';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TextInput } from 'shared/TextInput';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from './form.module.css';

type PropsType = {
  externalSource: Partial<ExternalSource>;
  onChange: (data: Partial<ExternalSource>) => void;
};

export const SharingInput: React.FC<PropsType> = ({
  externalSource,
  onChange,
}) => {
  function handleHashtagChange(value: string) {
    if (value === '') {
      onChange({ appendedHashtag: '', appendedHashtagEnabled: false });
      return;
    }

    onChange({ appendedHashtag: value, appendedHashtagEnabled: true });
  }
  return (
    <>
      <Fieldset>
        <div
          className={cx(
            fieldsetStyles.fieldGroupContainer,
            styles.multipleFields
          )}
        >
          <Field label="Shareable" className={styles.firstField}>
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <FlipSwitch
                  size="xl"
                  shape="rounded"
                  on={externalSource.isShareable === true}
                  onChange={(value) =>
                    value
                      ? onChange({
                          isShareable: true,
                        })
                      : onChange({
                          isShareable: false,
                        })
                  }
                />
              </div>
              Enable sharing on feed posts
            </div>
          </Field>
          <Field label="Hashtag">
            <TextInput
              disabled={!externalSource.isShareable}
              value={externalSource.appendedHashtag}
              placeholder="#hashtag"
              onChange={handleHashtagChange}
            />
          </Field>
        </div>
      </Fieldset>
    </>
  );
};
