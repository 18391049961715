import React from 'react';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { LitmusRole } from 'models/role';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { useActions } from './useActions';
import { useRolePermissionsCount } from './useRolePermissionsCount';
import { roleToThumbnail, roleToMenuItems } from './mappings';
import styles from './roles.module.css';

export type PropsType = {
  data: LitmusRole;
};

export const RolesBanner: React.FC<PropsType> = ({ data }) => {
  const { id: programId } = useProgram();
  const { duplicate } = useActions();
  const thumbnail = roleToThumbnail(data);
  const menuItems = roleToMenuItems(programId, data, duplicate);
  const { count: permissionsCount } = useRolePermissionsCount(data);

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`role-${data.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{data.title || data.name}</div>
          <p className={styles.description}>{data.description}</p>
        </div>
        <div className={styles.statsWrapper}>
          <div className={styles.statCol}>
            <span className={styles.statHeader}>TYPE</span>
            <span className={styles.stats}>
              {data.isSystemRole ? 'System' : 'Custom'}
            </span>
          </div>
          <div className={styles.statCol}>
            <span className={styles.statHeader}>DATE CREATED</span>
            <span className={styles.stats}>
              {data.createdAt
                ? DateTime.fromJSDate(new Date(data.createdAt)).toFormat(
                    'MMM dd, yyyy'
                  )
                : 'NA'}
            </span>
          </div>
          <div className={styles.statCol}>
            <span className={styles.statHeader}>PERMISSIONS</span>
            <span className={styles.stats}>{permissionsCount || 'NA'}</span>
          </div>
        </div>
      </div>
    </EmptyBanner>
  );
};
