import { BulkSelection, TopicBulkActionFilters } from 'hooks/common';
import { Topic, TopicShortcut } from 'models/topic';
import qs from 'qs';
import {
  deepCamelcaseKeys,
  removeEmptyKeys,
  request,
} from 'services/api-shared';
import { formatUsersResponse, UserCollectionData } from 'services/api-user';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { AboutPage } from 'models/about-page';
import { PaginationData } from './common';
import { fromJsonApiObject, toJsonApiObject } from './helpers/json-api';
import { ValidationError } from './Errors/ValidationError';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type QueryParams = {
  search?: string;
  page?: number;
  pageSize?: number;
  includeDefault?: boolean;
  status?: string | string[];
  visibility?: string | string[];
  autoFollow?: string | string[];
  userSubmittable?: string | string[];
  autoPublish?: string | string[];
  topicTags?: string | string[];
  ids?: number[];
};

export type FetchParams = { programId: number } & QueryParams;

export type TopicsCollectionData = {
  data: Array<Topic>;
  meta: PaginationData;
};

export type TopicData = {
  data: Topic;
};

export type FetchTopicFollowersCountResponse = {
  totalObjects: number;
};

const emptyUsersCountResponse: FetchTopicFollowersCountResponse = {
  totalObjects: 0,
};

export const fetchTopics = async (
  props: FetchParams
): Promise<TopicsCollectionData> => {
  const { programId, ...queryParams } = props;
  const query = qs.stringify(snakecaseKeys(queryParams), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/programs/${programId}/content_channels?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching topics: ${response.status}`);
};

export const fetchTopicById = async (
  programId: number,
  topicId: string
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/show`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching topics: ${response.status}`);
};

export const updateTopic = async (
  programId: number,
  topic: Topic
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topic.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(topic)),
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error updating topic: ${response.status}`);
};

export const initializeTopic = async (programId: number): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/new`;
  const response = await request(url, {
    method: 'GET',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  throw new Error(`Error updating topic: ${response.status}`);
};

export const archiveTopic = async (
  programId: number,
  topicId: number
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/archive`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error archive topic: ${response.status}`);
};

export const unarchiveTopic = async (
  programId: number,
  topicId: number
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/unarchive`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error unarchive topic: ${response.status}`);
};

export const fetchTopContributors = async (
  programId: number,
  topicId: number
): Promise<UserCollectionData> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/top_contributors`;
  const response = await request(url, {
    method: 'GET',
  });

  if (response.status === 200) {
    return formatUsersResponse(response);
  }

  throw new Error(`Error fetching top contributors: ${response.status}`);
};

export interface Member {
  id: string;
  avatarColor: string;
  avatarInitials: string;
  avatarUrl: string;
  department: string | null;
  employeeId: string | null;
  name: string;
}

export type MembersPage = {
  data: Array<Member>;
  meta: {
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    hidden: number;
    blocked: number;
  };
};

export const fetchMembers = async (
  programId: number,
  topicId: number,
  page = 1,
  pageSize = 50
): Promise<MembersPage> => {
  const query = qs.stringify(
    snakecaseKeys({
      page,
      pageSize,
    }),
    {
      arrayFormat: 'brackets',
    }
  );
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/members?${query}`;
  const response = await request(url, {
    method: 'GET',
  });

  const result = camelcaseKeys(await response.json(), { deep: true });

  result.data = result.data.map(
    (user: { id: string; attributes: Omit<Member, 'id'> }) => ({
      id: parseInt(user.id, 10),
      ...user.attributes,
    })
  );
  return result;
};

// Note that in most places we now refer to these as "members", but are more or less functionally equivalent.
export const fetchFollowersCount = async (
  programId: number,
  topicId: number
): Promise<number> => {
  const query = qs.stringify(
    snakecaseKeys({
      channelIds: [topicId],
    }),
    {
      arrayFormat: 'brackets',
    }
  );
  const url = `${apiRoot}/programs/${programId}/content_channels/count_unique_followers?${query}`;
  const response = await request(url, {
    method: 'GET',
  });

  if (response.status === 200) {
    return response.json().then((output) => output.data);
  }

  throw new Error(`Error fetching followers count: ${response.status}`);
};

export const createTopic = async (
  programId: number,
  topic: Partial<Topic>
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(topic)),
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }

  if (response.status === 409 || response.status === 422) {
    const errors = await response.json();
    throw new ValidationError(JSON.stringify(errors));
  }

  throw new Error(`Error creating topic: ${response.status}`);
};

export const bulkArchiveTopics = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: TopicBulkActionFilters
): Promise<undefined> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/bulk_archive`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({ bulkSelection, ...removeEmptyKeys(filterConfig) })
    ),
  });

  if (response.status === 200) return;

  throw new Error(`Error archive topics: ${response.status}`);
};

export const bulkUnarchiveTopics = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: TopicBulkActionFilters
): Promise<undefined> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/bulk_unarchive`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({ bulkSelection, ...removeEmptyKeys(filterConfig) })
    ),
  });

  if (response.status === 200) return;

  throw new Error(`Error unarchive topics: ${response.status}`);
};

export const fetchTopicFollowersCount = async (
  programId: number,
  ids: (string | number)[]
): Promise<FetchTopicFollowersCountResponse> => {
  if (ids.length === 0) return Promise.resolve(emptyUsersCountResponse);

  const query = qs.stringify({ channel_ids: ids }, { arrayFormat: 'brackets' });

  const url = `${apiRoot}/programs/${programId}/content_channels/count_unique_followers?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((output) => ({
      totalObjects: output.data,
    }));
  }
  throw new Error(`Error topics followers count: ${response.status}`);
};

export const fetchTopicAboutPage = async (
  programId: number,
  topicId: Topic['id']
): Promise<AboutPage> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/about_page`;
  const response = await request(url, {
    method: 'GET',
  });

  if (response.status === 200) {
    return response.json().then((output) => ({
      id: output.id,
      topicId: output.content_channel_id,
      pageContent: output.page_content,
      previewContent: output.preview_content,
      fontIds: output.font_ids,
      designId: output.design_id,
    }));
  }

  throw new Error(`Error fetching about page: ${response.status}`);
};

export const updateTopicAboutPage = async (
  programId: number,
  topicId: Topic['id'],
  pageContent?: string,
  previewContent?: string,
  fontIds?: number[],
  createDesign?: boolean
): Promise<AboutPage> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/about_page`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({ pageContent, previewContent, fontIds, createDesign })
    ),
  });

  if (response.status === 200) {
    return response.json().then((output) => ({
      id: output.id,
      topicId: output.content_channel_id,
      pageContent: output.page_content,
      fontIds: output.font_ids,
      previewContent: output.preview_content,
      designId: output.design_id,
    }));
  }

  throw new Error(`Error saving about page: ${response.status}`);
};

export const fetchTopicShortcuts = async (
  programId: number,
  topicId: Topic['id']
): Promise<TopicShortcut[]> => {
  const url = `${apiRoot}/v2/programs/${programId}/channels/${topicId}/shortcuts`;
  const response = await request(url, {
    method: 'GET',
  });

  if (response.ok) {
    return response
      .json()
      .then((data) =>
        (fromJsonApiObject(data) as TopicShortcut[]).sort(
          (a, b) => a.position - b.position
        )
      );
  }
  throw new Error(`Error fetching topics shortcuts: ${response.status}`);
};

export const createTopicShortcut = async (
  programId: number,
  topicId: Topic['id'],
  shortcut: Omit<TopicShortcut, 'id'>
): Promise<TopicShortcut> => {
  const url = `${apiRoot}/v2/programs/${programId}/channels/${topicId}/shortcuts`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(toJsonApiObject(shortcut)),
  });

  if (response.ok) {
    return response.json().then((output) => fromJsonApiObject(output));
  }

  throw new Error(`Error creating topic shortcut: ${response.status}`);
};

export const updateTopicShortcut = async (
  programId: number,
  topicId: Topic['id'],
  shortcut: TopicShortcut
): Promise<TopicShortcut> => {
  const url = `${apiRoot}/v2/programs/${programId}/channels/${topicId}/shortcuts/${shortcut.id}`;
  const response = await request(url, {
    method: 'PATCH',
    body: JSON.stringify(toJsonApiObject(shortcut)),
  });

  if (response.ok) {
    return response.json().then((output) => fromJsonApiObject(output));
  }

  throw new Error(`Error updating topic shortcut: ${response.status}`);
};

export const deleteTopicShortcut = async (
  programId: number,
  topicId: Topic['id'],
  shortcutId: TopicShortcut['id']
): Promise<void> => {
  const url = `${apiRoot}/v2/programs/${programId}/channels/${topicId}/shortcuts/${shortcutId}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.ok) return;

  throw new Error(`Error deleting topic shortcut: ${response.status}`);
};

export const reorderTopicShortcuts = async (
  programId: number,
  topicId: Topic['id'],
  shortcuts: TopicShortcut[]
): Promise<void> => {
  const url = `${apiRoot}/v2/programs/${programId}/channels/${topicId}/shortcuts/reorder`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        positions: shortcuts.map((shortcut) => shortcut.id),
      },
    }),
  });

  if (response.ok) return;

  throw new Error(`Error updating topic shortcuts order: ${response.status}`);
};

export const editTopic = async (
  programId: number,
  topicId: number
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/edit`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error editing topic: ${response.status}`);
};

export const publishTopic = async (
  programId: number,
  topicId: number
): Promise<Topic> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}/publish`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error publishing topic: ${response.status}`);
};

export const deleteDraft = async (
  programId: number,
  topicId: number
): Promise<void> => {
  const url = `${apiRoot}/programs/${programId}/content_channels/${topicId}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status !== 204) {
    throw new Error(`Error deleting draft: ${response.status}`);
  }
};
