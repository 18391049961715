import React, { forwardRef } from 'react';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import styles from './styles.module.css';

type ChildrenProps = {
  children: React.ReactNode;
};

export const Form = forwardRef<HTMLFormElement, ChildrenProps>(
  ({ children }, ref) => (
    <form ref={ref} className={styles.Form}>
      {children}
    </form>
  )
);

type FieldsetProps = ChildrenProps & {
  legend: string;
  toggleComponent?: React.ReactElement;
};

export function Fieldset({
  children,
  legend,
  toggleComponent,
}: FieldsetProps): React.ReactElement {
  return (
    <fieldset className={styles.Fieldset}>
      <Flex className={styles.FieldsetLegend__Container} spread>
        <FlexItem widen start>
          <legend>{legend}</legend>
        </FlexItem>
        {toggleComponent && <FlexItem end>{toggleComponent}</FlexItem>}
      </Flex>
      {children}
    </fieldset>
  );
}

Fieldset.defaultProps = {
  toggleComponent: undefined,
};
