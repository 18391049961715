import React from 'react';
import { FeatureFlagsProvider } from 'contexts/feature-flags';
import { LoadingScreen } from 'shared/LoadingScreen';
import { ProgramProvider } from 'contexts/program';
import { PermissionsProvider } from 'contexts/permissions';
import {
  useCurrentUserQuery,
  useCurrentUserPermissionsQuery,
} from 'hooks/user';
import { useProgramCustomizationsQuery } from 'hooks/feature-flags';
import { useProgramQuery } from 'hooks/program';
import { usePendo } from 'hooks/pendo';
import { useSetUser as useDatadog } from 'hooks/datadog';
import { NotFoundScreen } from 'shared/NotFoundScreen';
import { clearAuthRetry } from 'hooks/authentication';
import { AuthenticationError } from '../Authentication/AuthenticationError';

// ProgramRoutes renders the Routes component, wrapping it in a Program context,
// populated with program data fetched from the server. Program id is determined
// by the provided programId path component.
export const ProgramAccess: React.FC<{
  programId: number;
  subPath: string;
  navigate?: (path: string) => void;
}> = ({ navigate, programId, subPath, children }) => {
  const { isLoading, error, data: program } = useProgramQuery(programId);
  const { data: user } = useCurrentUserQuery();
  const permissions = useCurrentUserPermissionsQuery(programId);
  const featureFlags = useProgramCustomizationsQuery(programId);
  usePendo(user, program, permissions.data);
  useDatadog(user, program);

  React.useEffect(() => {
    if (!isLoading && !error) {
      sessionStorage.setItem('currentProgram', `${programId}`);
      clearAuthRetry();
    }
    const styleId = 'program-styles';
    if (
      !document.getElementById(styleId) &&
      program &&
      program.secondaryColor
    ) {
      const newStyles = document.createElement('style');
      newStyles.setAttribute('id', styleId);
      document.head.appendChild(newStyles);
      newStyles.textContent = `
        :root {
          --program-secondary-color: ${program.secondaryColor}
        }
      `;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, programId]);

  // Store a "program id setter" function in the context which works by
  // navigating to a new route (constructed from the current route, with a
  // different program prefix).
  const setProgramId = React.useCallback(
    (id: number) => {
      if (navigate) navigate(`/${id}/${subPath}`);
    },
    [navigate, subPath]
  );

  if (isLoading || featureFlags.isLoading || permissions.isLoading)
    return <LoadingScreen />;
  if (error && error.name === 'NotFoundError') {
    return <NotFoundScreen />;
  }
  if (error) return <>Error fetching program: {error.message}</>;
  if (featureFlags.errorMessage) window.location.reload();
  if (permissions.errorMessage) return <>Error fetching user permissions</>;
  if (!program) {
    return (
      <AuthenticationError error={new Error('an unexpected error occurred')} />
    );
  }

  return (
    <ProgramProvider value={program} setProgramId={setProgramId}>
      <FeatureFlagsProvider value={featureFlags.data}>
        <PermissionsProvider value={permissions.data}>
          {children}
        </PermissionsProvider>
      </FeatureFlagsProvider>
    </ProgramProvider>
  );
};
