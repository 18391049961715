import React from 'react';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { FormModal } from 'DesignSystem/Components/Modal';
import { Button } from 'DesignSystem/Form';
import { useContentArchiver } from 'hooks/content/archive';
import { useClearContentReporters } from 'hooks/content/flags';
import { flaggedCountQueryKey } from 'hooks/posts-metadata';
import { Content } from 'models/content';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { FlaggedCampaignInspector } from './FlaggedCampaignInspector';

type FlagCampaignModalProps = {
  content: Content;
  programId: number;
  onClose: () => void;
};

export function FlaggedCampaignModal({
  content,
  programId,
  onClose,
}: FlagCampaignModalProps): React.ReactElement {
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();

  const isArchived = content.publicationState === 'archived';

  const {
    clear: onRemoveFlags,
    isLoading: isRemovingFlags,
  } = useClearContentReporters(
    {
      programId,
      contentId: content.id,
    },
    {
      onSuccess: () => {
        setFlashMessage({
          timeout: 5000,
          severity: 'info',
          message: 'Flags removed.',
        });
        onClose();
        queryClient.invalidateQueries(['paged-contents']);
        queryClient.invalidateQueries(flaggedCountQueryKey(programId));
      },
      onError: (err) =>
        setFlashMessage({
          timeout: 5000,
          severity: 'error',
          message: err.message,
        }),
    }
  );

  const {
    archive: onArchiveCampaign,
    isLoading: isArchivingCampaign,
  } = useContentArchiver(
    { contentId: content.id, programId },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['paged-contents']);
        queryClient.invalidateQueries(flaggedCountQueryKey(programId));
        setFlashMessage({
          timeout: 5000,
          severity: 'info',
          message: 'Campaign archived.',
        });
        onClose();
      },
      onError: (err) => {
        setFlashMessage({
          timeout: 5000,
          severity: 'error',
          message: err.message,
        });
      },
    }
  );

  const isActionsDisabled = isRemovingFlags || isArchivingCampaign;

  return (
    <FormModal
      entityText="flagged campaign"
      actionText="review"
      description="Review campaign and either archive campaign or remove flags"
      width="952px"
      contentPadding="0"
      onCancel={onClose}
      disableCloseButton={isActionsDisabled}
      submitButton={
        <Button
          className="pendo-archive-flagged-campaign"
          warning
          disabled={isActionsDisabled || isArchived}
          onClick={onArchiveCampaign}
          label={
            isArchivingCampaign ? (
              <LoadingSpinner size="small" />
            ) : (
              'Archive Campaign'
            )
          }
        />
      }
      secondaryButton={
        <Button
          className="pendo-remove-campaign-flag"
          secondary
          disabled={isActionsDisabled}
          onClick={onRemoveFlags}
          label={
            isRemovingFlags ? <LoadingSpinner size="small" /> : 'Remove Flags'
          }
        />
      }
    >
      <FlaggedCampaignInspector content={content} programId={programId} />
    </FormModal>
  );
}
