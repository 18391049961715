import { RefObject, useEffect, useState } from 'react';

/**
 * A React hook that tracks if an element is in view using the IntersectionObserver API.
 *
 * @returns {boolean} - Returns true if the element is currently in view, otherwise false.
 */
export function useInView(
  /**
   * A React ref object that points to the element to track.
   */
  ref: RefObject<Element>,
  {
    once,
    threshold = 0,
  }: {
    /**
     * If true, the element will only be tracked once.
     */
    once: boolean;
    /**
     * A number between 0 and 1 that indicates the percentage of the element that must be in view to trigger the callback
     * function. For example, a value of 0.5 means that half of the element must be in view.
     * @default 0
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#thresholds
     */
    threshold?: number;
  }
): boolean {
  const [isInView, setInView] = useState(false);
  useEffect(() => {
    if (!ref.current || (once && isInView)) {
      return;
    }

    const onEnter = (): void => {
      setInView(true);
    };

    const onIntersectionChange = (
      entries: IntersectionObserverEntry[]
    ): void => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onEnter();
        }
      });
    };
    const observer = new IntersectionObserver(onIntersectionChange, {
      threshold,
    });

    observer.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [ref, threshold, once, isInView]);

  return isInView;
}
