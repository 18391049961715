import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Sparkles } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { QueryStatus } from 'react-query';
import { Tooltip } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import cx from 'classnames';
import styles from './AIControls.module.css';

export const AIControls: React.FC<{
  status: QueryStatus;
  trigger: () => void;
  cancel: () => void;
  canUndo: boolean;
  sparkleFillColor?: string;
  backgroundHover?: boolean;
  disabled?: boolean;
}> = ({
  status,
  trigger,
  cancel,
  canUndo,
  sparkleFillColor,
  backgroundHover,
  disabled,
}) => {
  const disableUndo = !canUndo || status === 'loading';

  return (
    <Flex start>
      {status === 'loading' ? (
        <LoadingSpinner size="small" />
      ) : (
        <Tooltip offset={-5} placement="top-start" content="Enhance with AI">
          <Button
            minimal
            layoutOnly
            aria-label="Enhance with AI"
            className={cx(styles.button, {
              [styles.aiButton]: !disabled,
              [styles.aiButtonBackgroundHover]: !disabled && backgroundHover,
            })}
            onClick={disabled ? undefined : trigger}
            icon={<Sparkles fill={sparkleFillColor} />}
            aria-disabled={disabled}
          />
        </Tooltip>
      )}

      <Tooltip offset={-5} placement="top-start" content="Undo">
        <Button
          minimal
          layoutOnly
          aria-label="Undo"
          className={cx(styles.button, {
            [styles.aiButton]: !disableUndo,
          })}
          onClick={disableUndo ? undefined : cancel}
          icon={<MAIcon name="undo" />}
          aria-disabled={disableUndo}
        />
      </Tooltip>
    </Flex>
  );
};
