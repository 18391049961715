import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import { ChevronDown, ChevronLeft, ChevronRight } from 'shared/icons';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';

import cx from 'classnames';
import { Year } from '../Year';
import styles from './calendar.module.css';

// Luxon doesn't support abbreviations like we want:
// https://moment.github.io/luxon/#/formatting?id=table-of-tokens
const WEEKDAY_MAP = {
  Sun: 'Su',
  Mon: 'M',
  Tue: 'T',
  Wed: 'W',
  Thu: 'Th',
  Fri: 'F',
  Sat: 'S',
} as { [key: string]: string };

// Adopted calendar form here:
// https://dev.to/franciscomendes10866/how-to-build-a-custom-calendar-component-in-react-26kj
export const Calendar: React.FC<{
  selectedDate: DateTime;
  onChange: (date: DateTime | undefined) => void;
  sundayStart?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  disableAutoSelectOnCalendarChange?: boolean;
}> = ({
  selectedDate,
  onChange,
  sundayStart = true,
  maxDate,
  minDate,
  disableAutoSelectOnCalendarChange = false,
  children,
}) => {
  const [visibleDate, setVisibleDate] = React.useState(
    selectedDate.startOf('month')
  );

  const firstDayOfTheMonth = useMemo(() => visibleDate.startOf('month'), [
    visibleDate,
  ]);

  const firstDayOfFirstWeekOfMonth = useMemo(() => {
    const date = firstDayOfTheMonth.startOf('week');
    return sundayStart ? date.minus({ day: 1 }) : date;
  }, [firstDayOfTheMonth, sundayStart]);

  const generateFirstDaysOfEachWeek = useCallback((day: DateTime) => {
    const dates = [day];
    for (let i = 1; i < 6; i += 1) {
      dates.push(day.plus({ week: i }));
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day: DateTime) => {
    const dates = [];
    for (let i = 0; i < 7; i += 1) {
      dates.push(day.plus({ day: i }));
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDaysOfEachWeek = generateFirstDaysOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDaysOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDaysOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const isValidDate = useCallback(
    (date: DateTime) => {
      if (maxDate && date.startOf('day') > maxDate.startOf('day')) return false;
      if (minDate && date.startOf('day') < minDate.startOf('day')) return false;
      return true;
    },
    [maxDate, minDate]
  );

  const singleDate = useCallback(
    (date: DateTime) => {
      if (visibleDate.month !== date.month || !isValidDate(date)) {
        return styles.otherMonth;
      }
      return selectedDate.hasSame(date, 'day')
        ? styles.selected
        : styles.default;
    },
    [selectedDate, isValidDate, visibleDate]
  );

  const handleDateChange = React.useCallback(
    (newDate: DateTime) => {
      if (isValidDate(newDate)) onChange(newDate);
    },
    [onChange, isValidDate]
  );

  const handleControlClick = React.useCallback(
    (newDate: DateTime, toVisibleDate: DateTime) => {
      setVisibleDate(toVisibleDate);
      if (!disableAutoSelectOnCalendarChange) handleDateChange(newDate);
    },
    [handleDateChange, disableAutoSelectOnCalendarChange]
  );

  const yearSelectionDropdown = (dismiss: DismissType) => (
    <Box width={100} style={{ cursor: 'auto', position: 'relative' }}>
      <Popover centered padding={0}>
        <Year
          colsCount={1}
          rowsCount={3}
          yearsOffset={1}
          selectedDate={visibleDate}
          onChange={(date: DateTime | undefined) => {
            if (date) {
              const newDate = visibleDate.set({ year: date.year });
              handleControlClick(date, newDate);
            }
            dismiss();
          }}
        />
      </Popover>
    </Box>
  );

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.calendarHeaderWrapper}>
        <Box style={{ display: 'flex' }}>
          <Box margin={['auto']}>
            <h3>{visibleDate.toFormat('MMMM y')}</h3>
          </Box>
          <Box margin={[3, 0, 0, 5]} className={styles.calendarButton}>
            <ClickDropdown dropdownRenderProp={yearSelectionDropdown}>
              <ChevronDown />
            </ClickDropdown>
          </Box>
        </Box>
        <Box>
          <span style={{ marginRight: 30 }}>
            <Button
              borderless
              minimal
              secondary
              onClick={() =>
                handleControlClick(
                  selectedDate.minus({ month: 1 }),
                  visibleDate.minus({ month: 1 })
                )
              }
              label={<ChevronLeft />}
            />
          </span>
          <span>
            <Button
              borderless
              minimal
              secondary
              onClick={() =>
                handleControlClick(
                  selectedDate.plus({ month: 1 }),
                  visibleDate.plus({ month: 1 })
                )
              }
              label={<ChevronRight />}
            />
          </span>
        </Box>
      </div>
      <div className={styles.weekDaysWrapper}>
        {generateWeeksOfTheMonth[0].map((day) => (
          <div key={day.toMillis()} className={styles.weekDayCell}>
            {WEEKDAY_MAP[day.toFormat('ccc')]}
          </div>
        ))}
      </div>
      {generateWeeksOfTheMonth.map((week) => (
        <div key={week[0].toMillis()} className={styles.calendarContentWrapper}>
          {week.map((day) => (
            <Box
              key={day.toMillis()}
              onClick={() =>
                handleDateChange(
                  day.set({
                    hour: selectedDate.hour,
                    minute: selectedDate.minute,
                  })
                )
              }
              className={cx(styles.calendarDayCell, singleDate(day))}
            >
              {day.toFormat('d')}
            </Box>
          ))}
        </div>
      ))}
      {children}
    </div>
  );
};
