import React, { createContext, useState } from 'react';

interface TopicErrorsPillToggle {
  isTopicErrorsPillOpen: boolean;
  openTopicErrorsPill: () => void;
  toggleTopicErrorsPill: () => void;
  closeTopicErrorsPill: () => void;
}

export const TopicErrorsPillToggleContext = createContext<
  TopicErrorsPillToggle
>({
  isTopicErrorsPillOpen: false,
  openTopicErrorsPill: () => {},
  toggleTopicErrorsPill: () => {},
  closeTopicErrorsPill: () => {},
});

export interface TopicErrorsPillToggleProviderProps {
  initalValue: boolean;
}

export const TopicErrorsPillToggleProvider: React.FC<TopicErrorsPillToggleProviderProps> = ({
  children,
  initalValue,
}) => {
  const [isTopicErrorsPillOpen, setIsTopicErrorsPillOpen] = useState(
    initalValue
  );

  const contextValue: TopicErrorsPillToggle = {
    isTopicErrorsPillOpen,
    openTopicErrorsPill: () => setIsTopicErrorsPillOpen(true),
    toggleTopicErrorsPill: () =>
      setIsTopicErrorsPillOpen((previousOpen) => !previousOpen),
    closeTopicErrorsPill: () => setIsTopicErrorsPillOpen(false),
  };

  return (
    <TopicErrorsPillToggleContext.Provider value={contextValue}>
      {children}
    </TopicErrorsPillToggleContext.Provider>
  );
};
