import * as React from 'react';
import { Video } from 'models/video';
import { humanDuration } from 'utility/human';
import { Button } from 'shared/Button';
import styles from './video-player.module.css';

type PropsType = {
  video: Video;
};

export const Meta: React.FC<PropsType> = (props) => {
  const { video } = props;

  // TODO:
  // The mock shows "Size" and "Type" which I don't think we have stored in the db.
  // The flex-column div is there to be the home for those when we do have them, and
  // to make sure that the download button still right justifies in the off chance
  // duration is blank.
  return (
    <div className={styles.meta}>
      <div className="flex-column">
        {video.durationInSeconds && (
          <div>Length: {humanDuration(video.durationInSeconds)}</div>
        )}
      </div>
      <a
        href={video.uploadedVideoUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          iconName="Download"
          iconType="SVG"
          iconSize={18}
          type="secondary"
          onClick={() => {}}
          compact
        >
          Download
        </Button>
      </a>
    </div>
  );
};
