import * as React from 'react';
import { Icon } from 'shared/Icon';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Caption, background } from 'DesignSystem/Typography';
import { useImageSelector } from '../hooks/useImageSelector';

export const ImageSelector: React.FC<Parameters<typeof useImageSelector>[0]> = (
  props
) => {
  const options = useImageSelector(props);
  return (
    <Flex
      column
      center
      color={background.gray02}
      style={{ padding: '8px 0 4px' }}
    >
      <Flex center>
        <Button
          disabled={!options.hasPrev}
          onClick={options.prev}
          icon={<Icon iconType="SVG" iconName="ArrowPrev" />}
          compact
          secondary
        />
        <Button
          compact
          secondary
          disabled={!options.hasNext}
          onClick={options.next}
          icon={<Icon iconType="SVG" iconName="ArrowNext" />}
        />
      </Flex>
      <Flex center>
        <Caption>
          {options.index + 1} of {options.images.length}
        </Caption>
      </Flex>
    </Flex>
  );
};
