import { useQuery } from 'react-query';
import { QueryResponse } from './common';
import { Asset, AssetsTable, Poll, PollType } from '../models/poll';
import {
  fetchPoll,
  PollData,
  fetchAssets,
  AssetData,
} from '../services/api-polls';

const mapDataToPoll = (resp: PollData): Poll => {
  return { ...resp.data.attributes };
};

const mapDataToAssets = (resp: AssetData): AssetsTable => {
  const assetsTable: AssetsTable = {
    [PollType.multiple]: {},
    [PollType.single]: {},
    [PollType.satisfaction]: {},
    [PollType.yes_no]: {},
    [PollType.stars]: {},
  };
  resp.data.forEach((data) => {
    const { assetKey } = data.attributes;
    const asset: Asset = {
      type: data.attributes.assetType,
      key: data.attributes.assetKey,
      url: data.attributes.url,
    };
    // TODO this is not thorough, but it classifies the images _enough_
    // for the UI to present a preview of those poll presets.
    if (assetKey.substr(0, 4) === 'star')
      assetsTable[PollType.stars][asset.key] = asset;
    else if (assetKey.substr(0, 5) === 'thumb')
      assetsTable[PollType.yes_no][asset.key] = asset;
    else assetsTable[PollType.satisfaction][asset.key] = asset;
  });
  return assetsTable;
};

export const usePollQuery = (
  programId: number,
  pollId: number
): QueryResponse<Poll> => {
  const { isLoading, error, data } = useQuery<PollData, Error>(
    ['poll', programId, pollId], // Cache key, must be distinct for different query params
    () => fetchPoll(programId, pollId),
    { retry: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToPoll(data),
  };
};

export const usePollAssetsQuery = (
  programId: number
): QueryResponse<AssetsTable> => {
  const { isLoading, error, data } = useQuery<AssetData, Error>(
    ['poll_assets', programId],
    () => fetchAssets(programId),
    { retry: false }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToAssets(data),
  };
};
