import * as React from 'react';
import { DateTime } from 'luxon';
import { DateRangeInputActionsType, DateRangeInputStateType } from './types';
import { AbsoluteRange, DateRange, RelativeRange, Timezone } from './DateRange';
import { ComponentLayout } from './ComponentLayout';

export interface DateRangeInputProps {
  initialValue?: string | DateRange;
  onClear?: () => void;
  onDone?: (dateRange: DateRange) => void;
  onStateChange?: (dateRange: DateRange) => void;
  onZoneChange?: (zone: Timezone) => void;
  allowEmpty?: boolean;
  allowFutureSelection?: boolean;
  shouldUseTimezone?: boolean;
  forceIgnoreYear?: boolean;
}

export default class DateRangeInput
  extends React.PureComponent<DateRangeInputProps, DateRangeInputStateType>
  implements DateRangeInputActionsType {
  constructor(props: DateRangeInputProps) {
    super(props);
    this.state = {
      dateRange: DateRange.buildFromKey(
        props.initialValue,
        props.allowFutureSelection
      ),
      timezone: Timezone.build_from_offset(
        DateTime.local().toFormat('ZZZZZ'),
        true
      ),
      calendarMonth: DateTime.now(),
      selectedDate: 'start',
    };
  }

  componentDidMount(): void {
    const { onStateChange } = this.props;
    const { dateRange } = this.state;

    if (onStateChange && dateRange) {
      onStateChange(dateRange.clone());
    }
  }

  setTimezone = (range: Timezone): void => {
    const { onZoneChange } = this.props;
    const { timezone } = this.state;
    if (onZoneChange && range.key !== timezone.key) {
      onZoneChange(range);
      this.setState({ timezone: range });
    }
  };

  setDateRange = (range: DateRange): void => {
    if (!range.isValid()) return;

    const { allowFutureSelection, onStateChange } = this.props;

    if (!allowFutureSelection) {
      const today = DateTime.now().endOf('day').plus({ minutes: 1 });
      const { start, end } = range.toAbsolute();
      if (start > today || end > today) {
        return;
      }
    }
    this.setState({ dateRange: range.clone() });

    if (onStateChange) {
      onStateChange(range);
    }

    if (range instanceof RelativeRange) {
      this.scrollCalendarIntoView(range);
    } else {
      this.setState({
        calendarMonth: range.toAbsolute().end,
      });
    }
  };

  setCurrentRangeDate = (date: DateTime): void => {
    const { dateRange } = this.state;
    const { timezone } = this.state;

    if (dateRange instanceof RelativeRange) {
      this.switchToAbsoluteRange(date);
    } else {
      this.updateAbsoluteRange(date);
      this.setTimezone(timezone);
    }
  };

  switchToAbsoluteRange = (date: DateTime): void => {
    const { allowFutureSelection } = this.props;
    const { dateRange } = this.state;
    const { end } = dateRange.toAbsolute();

    if (date.endOf('day') > end.startOf('day')) {
      this.setDateRange(
        new AbsoluteRange(
          dateRange.toAbsolute().start,
          date,
          allowFutureSelection
        )
      );
    } else {
      this.setDateRange(
        new AbsoluteRange(
          date,
          dateRange.toAbsolute().end,
          allowFutureSelection
        )
      );
    }

    this.setSelectedDate('end');
  };

  updateAbsoluteRange = (date: DateTime): void => {
    const { allowFutureSelection } = this.props;
    const { dateRange, selectedDate } = this.state;

    if (!(dateRange instanceof AbsoluteRange))
      throw Error('unexpected code path');

    const start = dateRange.start.startOf('day');
    const end = dateRange.end.endOf('day');

    if (date.startOf('day') < start) {
      this.setDateRange(new AbsoluteRange(date, end, allowFutureSelection));
      this.setSelectedDate('start');
    } else if (date.endOf('day') > end && selectedDate === 'start') {
      this.setDateRange(new AbsoluteRange(date, date, allowFutureSelection));
      this.setSelectedDate('end');
    } else if (selectedDate === 'start') {
      this.setDateRange(new AbsoluteRange(date, end, allowFutureSelection));
    } else {
      this.setDateRange(new AbsoluteRange(start, date, allowFutureSelection));
    }
  };

  toggleSelectedDate = (): void => {
    const { selectedDate } = this.state;
    this.setSelectedDate(selectedDate === 'start' ? 'end' : 'start');
  };

  setSelectedDate = (selectedDate: 'start' | 'end'): void =>
    this.setState({ selectedDate });

  previousCalendarMonth = (): void => {
    const { calendarMonth } = this.state;
    this.setState({
      calendarMonth: calendarMonth.minus({ months: 1 }),
    });
  };

  nextCalendarMonth = (): void => {
    const { calendarMonth } = this.state;
    this.setState({
      calendarMonth: calendarMonth.plus({ months: 1 }),
    });
  };

  editStartDate = (): void => this.setState({ selectedDate: 'start' });

  editEndDate = (): void => this.setState({ selectedDate: 'end' });

  shiftStartDateLeft = (): void => {
    this.shiftDate('start', 'minus');
  };

  shiftStartDateRight = (): void => {
    this.shiftDate('start', 'plus');
  };

  shiftEndDateLeft = (): void => {
    this.shiftDate('end', 'minus');
  };

  shiftEndDateRight = (): void => {
    this.shiftDate('end', 'plus');
  };

  shiftDate = (which: 'start' | 'end', op: 'plus' | 'minus'): void => {
    const { dateRange } = this.state;
    if (!(dateRange instanceof AbsoluteRange))
      throw Error('unexpected code path');
    const range = dateRange;
    range[which] = range[which][op]({ days: 1 });
    this.setDateRange(range);
  };

  scrollCalendarIntoView = (range: RelativeRange): void => {
    const { end } = range.toAbsolute();
    const { calendarMonth } = this.state;
    const right_month = calendarMonth;
    const left_month = right_month.minus({ months: 1 });

    if (
      !left_month.hasSame(end, 'month') &&
      !right_month.hasSame(end, 'month')
    ) {
      this.setState({
        calendarMonth: range.toAbsolute().end,
      });
    }
  };

  onDone = (): void => {
    const { onDone } = this.props;
    const { dateRange } = this.state;
    if (onDone && dateRange) onDone(dateRange);
  };

  onClear = (): void => {
    const { onClear } = this.props;
    if (onClear) onClear();
  };

  render(): React.ReactNode {
    const {
      allowEmpty,
      allowFutureSelection,
      shouldUseTimezone,
      forceIgnoreYear,
    } = this.props;

    return (
      <ComponentLayout
        allowEmpty={!!allowEmpty}
        state={this.state}
        allowFutureSelection={allowFutureSelection || false}
        forceIgnoreYear={forceIgnoreYear}
        shouldUseTimezone={shouldUseTimezone}
        actions={{
          setDateRange: this.setDateRange,
          setTimezone: this.setTimezone,
          setCurrentRangeDate: this.setCurrentRangeDate,
          setSelectedDate: this.setSelectedDate,
          previousCalendarMonth: this.previousCalendarMonth,
          nextCalendarMonth: this.nextCalendarMonth,
          editStartDate: this.editStartDate,
          editEndDate: this.editEndDate,
          shiftStartDateLeft: this.shiftStartDateLeft,
          shiftStartDateRight: this.shiftStartDateRight,
          shiftEndDateLeft: this.shiftEndDateLeft,
          shiftEndDateRight: this.shiftEndDateRight,
          onDone: this.onDone,
          onClear: this.onClear,
        }}
      />
    );
  }
}
