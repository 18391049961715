import React from 'react';
import { PillButtonDropdown } from 'App/Program/Main/Insight/components/Filters/shared/PillButtonDropdown';
import {
  isAttributeFilter,
  isAuthorAliasFilter,
  isBooleanFilter,
  isCampaignFilter,
  isContentPostFilter,
  isDateRangeFilter,
  isInitiativeFilter,
  isListFilter,
  isNumberInputFilter,
  isPollFilter,
  isPublisherFilter,
  isStaticListFilter,
  isTextInputFilter,
  isTopicFilter,
  isViewByFilter,
} from 'models/insight/Filter';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { FilterDropdownStructureProvider } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import { DateRangeDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/DateRangeDropdown';
import { InputDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/InputDropdown';
import { BooleanDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/BooleanDropdown';
import { ViewByDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ViewByDropdown';
import { PublishersListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/PublishersListDropdown';
import { StaticListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/StaticListDropdown';
import { TopicsListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/TopicsListDropdown';
import { InitiativesListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/InitiativesListDropdown';
import { CampaignPaginatedDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/CampaignPaginatedDropdown';
import { PollPaginatedDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/PollPaginatedDropdown';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { AttributesPaginatedDropdown } from './Dropdowns/ListDropdown/AttributePaginatedDropdown';
import { ContentPostsListDropdown } from './Dropdowns/ListDropdown/ContentPostsListDropdown';
import { ReportContext } from '../../contexts/ReportContext';
import { AuthorAliasListDropdown } from './Dropdowns/ListDropdown/AuthorAliasListDropdown';

export const FilterDropdown: React.FC = () => {
  const { filter, filterState } = React.useContext(FilterDropdownContext);
  const { filtersStateAction } = React.useContext(FiltersStateContext);
  const { setShowReset } = React.useContext(ReportContext);
  const onCloseButtonClick = React.useCallback(
    (f) => {
      // when filter remove add Reset Filter button
      setShowReset(true);
      filtersStateAction({
        action: 'removeFilter',
        filter: f,
      });
    },
    [filtersStateAction, setShowReset]
  );

  const renderDropdownForFilter = (): React.ReactNode => {
    if (isDateRangeFilter(filter)) return <DateRangeDropdown />;
    if (isNumberInputFilter(filter))
      return <InputDropdown inputType="number" />;
    if (isTextInputFilter(filter)) return <InputDropdown inputType="text" />;
    if (isBooleanFilter(filter)) return <BooleanDropdown />;
    if (isListFilter(filter)) {
      if (isViewByFilter(filter)) return <ViewByDropdown />;
      if (isPublisherFilter(filter)) return <PublishersListDropdown />;
      if (isAuthorAliasFilter(filter)) return <AuthorAliasListDropdown />;
      if (isCampaignFilter(filter)) return <CampaignPaginatedDropdown />;
      if (isTopicFilter(filter)) return <TopicsListDropdown />;
      if (isInitiativeFilter(filter)) return <InitiativesListDropdown />;
      if (isContentPostFilter(filter)) return <ContentPostsListDropdown />;
      if (isAttributeFilter(filter)) return <AttributesPaginatedDropdown />;
      if (isPollFilter(filter)) return <PollPaginatedDropdown />;
      if (filterState.availableListValues !== undefined)
        return <StaticListDropdown values={filterState.availableListValues} />;
      if (isStaticListFilter(filter))
        return <StaticListDropdown values={filter.values} />;
    }

    // Unknown filter type. Shouldn't happen in production
    return <div />;
  };

  return (
    <FilterDropdownStructureProvider>
      <PillButtonDropdown onCloseButtonClick={onCloseButtonClick}>
        {renderDropdownForFilter()}
      </PillButtonDropdown>
    </FilterDropdownStructureProvider>
  );
};
