import * as React from 'react';
import { uniqueId } from 'hooks/useUniqueId';
import { empty } from 'hooks/useLinkFetcher';
import { LinkData } from 'models/publisher/block';
import { FormModal } from 'DesignSystem/Components';
import { useLibraryContents } from 'hooks/useLibrary';
import { Content } from 'models/library';
import { useAutoPaging } from 'shared/Library/PickerScreen/useAutoPaging';
import { Items } from 'shared/Library/ContentPickerScreen/Items';
import { ContentHeader } from 'shared/Library/ContentPickerScreen/ContentsHeader';
import { usePicker } from 'shared/Library/ContentPickerScreen/usePicker';

export const Library: React.FC<{
  onChange: (images: LinkData[]) => void;
  onClose: () => void;
  maxSelections: number;
}> = ({ onChange, onClose, maxSelections }) => {
  const handleChange = React.useCallback(
    (contents: Content[]) => {
      onChange(
        contents.map((content) => ({
          url: content.asset.post_url,
          title: { type: 'plain-text', value: content.title } || '',
          description: {
            type: 'plain-text',
            value: content.description,
          },
          image: {
            url: content.asset.image_url ?? '',
            processed: true,
          },
          callToAction: empty('link').callToAction,
          images: [],
          uuid: uniqueId(),
          source: 'library',
          type: 'link',
          openInNewTab: true,
          readTimeInSeconds: content.asset.read_time_in_seconds,
        }))
      );
      onClose();
    },
    [onChange, onClose]
  );

  const { onApply, collection, paging } = usePicker({
    onChange: handleChange,
    useLibraryItems: useLibraryContents,
  });
  const scrollRef = useAutoPaging(paging);
  return (
    <FormModal
      disabled={collection.selection.length < 1}
      entityText="campaigns"
      actionText="link to"
      onSubmit={onApply}
      onCancel={onClose}
    >
      <ContentHeader
        filterFetchProps={collection.search}
        setFilterFetchProps={collection.setSearch}
        filters={collection.filters}
        setValue={collection.setFilterValue}
        setBooleanValue={collection.setFilterBoolean}
      />
      <div ref={scrollRef} style={{ height: '100%', overflow: 'auto' }}>
        <Items
          isLoading={collection.isLoading}
          items={collection.items}
          label="Campaign"
          maxSelections={maxSelections}
          pagination={collection.pagination}
          select={collection.select}
          selection={collection.selection}
        />
      </div>
    </FormModal>
  );
};
