import { UserIdentity } from 'models/identity';
import { useQuery } from 'react-query';
import { fetchUserIdentity, upsertUserIdentity } from 'services/api-identities';
import { MutationOptions, QueryResponse } from './common';

export const useUserIdentityQuery = (
  programId: number,
  id: string
): QueryResponse<UserIdentity> => {
  const { isLoading, error, data } = useQuery<UserIdentity, Error>({
    queryKey: `identity-${programId}-${id}`,
    queryFn: () => fetchUserIdentity(id, programId),
    cacheTime: 0,
    retry: false,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useUpsertUserIdentity = ({
  onSuccess,
  onError,
}: MutationOptions<UserIdentity, Error> = {}): {
  upsert: (identity: UserIdentity) => void;
} => {
  const upsert = (identity: UserIdentity) => {
    upsertUserIdentity(identity)
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { upsert };
};
