import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { mapDataToAudience } from 'hooks/audience';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useUsersInfiniteQuery } from 'hooks/user';
import { useDebounce } from 'hooks/useDebounce';
import { Audience } from 'models/audience';
import { LitmusRole } from 'models/role';
import { Icon } from 'shared/Icon';
import { useProgram } from 'contexts/program';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useGenericFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { ActionData } from 'DesignSystem/Layout/Actions';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { fetchAudience } from 'services/api-audiences';
import { fetchRoleByName } from 'services/api-role';
import { UserFiltersContext } from 'contexts/people/filters';
import { UsersList } from './UsersList';
import { FiltersBar } from './FiltersBar';
import { UserFilterBar } from './UserFilterBar/UserFilterBar';
import { Scope } from './UserFilterBar/scope';

export const UsersListPage: React.FC<RouteComponentProps> = () => {
  const { filters, setBooleanValue } = React.useContext(UserFiltersContext);
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [
    selectedStatuses,
    setSelectedStatuses,
  ] = useGenericFilterDropdownQueryString<string[]>({
    key: 'statuses',
  });
  const [roleNames, setRoleNames] = useGenericFilterDropdownQueryString<
    string[]
  >({
    key: 'roles',
  });
  const [audienceIds, setAudienceIds] = useGenericFilterDropdownQueryString<
    string[]
  >({ key: 'audiences' });
  const [scopeNames, setScopeNames] = useGenericFilterDropdownQueryString<
    string[]
  >({ key: 'scopes' });
  const [selectedAudiences, setSelectedAudiences] = React.useState<Audience[]>(
    []
  );
  const [selectedScopes, setSelectedScopes] = React.useState<Scope>();
  const [selectedRoles, setSelectedRoles] = React.useState<LitmusRole[]>([]);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const {
    permissions: { addImportUsersAccess },
  } = usePermissions();

  const {
    data: permissionsFlag,
    isLoading: permissionsFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Permissions.Service');

  const {
    data: bulkUpdatePermissionsFlag,
    isLoading: bulkUpdatePermissionsFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.BulkUpdatePermissions.UI');

  React.useEffect(() => {
    if (permissionsFlagLoading) return;
    if (roleNames && roleNames.length > 0 && permissionsFlag?.value) {
      Promise.all(
        roleNames.map((name) => fetchRoleByName(programId, name))
      ).then((result) => {
        setSelectedRoles(result.map((roleData) => roleData.data));
      });
    }
    // eslint-disable-next-line
  }, [permissionsFlagLoading]);

  React.useEffect(() => {
    if (bulkUpdatePermissionsFlagLoading) return;
    if (
      audienceIds &&
      audienceIds.length > 0 &&
      bulkUpdatePermissionsFlag?.value
    ) {
      Promise.all(audienceIds.map((id) => fetchAudience(programId, id))).then(
        (result) => {
          const rawAudienceData = result.map((a) => mapDataToAudience(a));
          setSelectedAudiences(rawAudienceData);
        }
      );
      setBooleanValue('audiences', true);
    }
    // eslint-disable-next-line
  }, [bulkUpdatePermissionsFlagLoading]);

  React.useEffect(() => {
    if (bulkUpdatePermissionsFlagLoading) return;
    if (
      scopeNames &&
      scopeNames.length > 0 &&
      bulkUpdatePermissionsFlag?.value
    ) {
      const localTopics = scopeNames
        .filter((s) => s.includes('topic'))
        .map((s) => s.split(':')[1]);

      const localAudiences = scopeNames
        .filter((s) => s.includes('audience'))
        .map((s) => s.split(':')[1]);

      const localAuthorAliases = scopeNames
        .filter((s) => s.includes('author_alias'))
        .map((s) => s.split(':')[1]);

      const localEmailAliases = scopeNames
        .filter((s) => s.includes('sender_account'))
        .map((s) => s.split(':')[1]);

      const localTemplates = scopeNames
        .filter((s) => s.includes('publisher_template'))
        .map((s) => s.split(':')[1]);

      const localScope = {
        audiences: localAudiences,
        authorAliases: localAuthorAliases,
        emailAliases: localEmailAliases,
        templates: localTemplates,
        topics: localTopics,
      };

      setBooleanValue('scopes', true);
      setSelectedScopes(localScope);
    }

    // eslint-disable-next-line
  }, [bulkUpdatePermissionsFlagLoading]);

  const search = useDebounce(searchTerm);
  const statuses = useDebounce(selectedStatuses);
  const roles = useDebounce(roleNames);
  const audiences = useDebounce(audienceIds);
  const scopes = useDebounce(scopeNames);
  const {
    isLoading,
    data,
    meta,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
  } = useUsersInfiniteQuery({
    programId,
    search,
    statuses,
    roles,
    audiences,
    scopes,
  });
  const parentRef = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line
  const filterChangeCallbacks: { [key: string]: (values: any) => void } = {
    statuses: setSelectedStatuses,
    roles: (values) => {
      if (permissionsFlagLoading) return;
      if (permissionsFlag?.value) {
        setSelectedRoles(values as LitmusRole[]);
        setRoleNames((values as LitmusRole[]).map((r) => r.name));
        return;
      }
      setRoleNames(values);
    },
    audiences: (values) => {
      if (bulkUpdatePermissionsFlagLoading) return;
      if (bulkUpdatePermissionsFlag?.value) {
        setSelectedAudiences(values as Audience[]);
        setAudienceIds((values as Audience[]).map((a) => a.id as string));
      }
    },
    scopes: (values) => {
      if (bulkUpdatePermissionsFlagLoading) return;
      if (bulkUpdatePermissionsFlag?.value) {
        setSelectedScopes(values as Scope);

        const scopeAudiences = values.audiences
          ? (values.audiences as string[]).map((id) => `audience:${id}`)
          : [];

        const authorAliases = values.authorAliases
          ? (values.authorAliases as string[]).map((id) => `author_alias:${id}`)
          : [];

        const emailAliases = values.emailAliases
          ? (values.emailAliases as string[]).map(
              (id) => `sender_account:${id}`
            )
          : [];

        const templates = values.templates
          ? (values.templates as string[]).map(
              (id) => `publisher_template:${id}`
            )
          : [];

        const topics = values.topics
          ? (values.topics as string[]).map((id) => `topic:${id}`)
          : [];

        const valueScopes = [
          ...scopeAudiences,
          ...authorAliases,
          ...emailAliases,
          ...templates,
          ...topics,
        ];

        setScopeNames((valueScopes as string[]).map((a) => a));
      }
    },
    search: setSearchTerm,
  };

  // eslint-disable-next-line
  function onFilterChange(filterName: string, values: any) {
    filterChangeCallbacks[filterName](values);
  }

  const actions: ActionData[] = [];
  if (addImportUsersAccess) {
    actions.push({
      label: 'Add a user',
      to: `/${programId}/app/people/users/new`,
      icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
    });
    actions.push({
      label: 'Import users',
      onClick: () => setIsImportOpen(true),
      icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
    });
  }

  const renderFilterBar = () =>
    bulkUpdatePermissionsFlag?.value ? (
      <UserFilterBar
        onChange={onFilterChange}
        searchTerm={searchTerm}
        statuses={statuses}
        roles={selectedRoles}
        roleNames={roleNames}
        audiences={selectedAudiences}
        scopes={selectedScopes}
        filters={filters}
        setBooleanValue={setBooleanValue}
      />
    ) : (
      <FiltersBar
        onChange={onFilterChange}
        searchTerm={searchTerm}
        statuses={statuses}
        roles={selectedRoles}
        roleNames={roleNames}
      />
    );

  return (
    <ListPage
      title="People"
      breadcrumbs={[{ label: 'People' }]}
      tabs={[
        { to: 'audiences', label: 'Audiences' },
        { to: 'users', label: 'Users' },
      ]}
      actions={actions}
      filterbar={
        bulkUpdatePermissionsFlagLoading ? undefined : renderFilterBar()
      }
    >
      {errorMessage && <>{errorMessage}</>}
      {data && !errorMessage && (
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <UsersList
              users={data}
              query={search}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              showFull
              totalRecords={meta?.totalRecords}
              parentRef={parentRef}
              height={height}
              isImportOpen={isImportOpen}
              setIsImportOpen={setIsImportOpen}
              filterConfig={{
                search,
                roles,
                statuses,
                audiences,
                scopes,
              }}
            />
          )}
        </InfiniteContainer>
      )}
    </ListPage>
  );
};
