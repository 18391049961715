import * as React from 'react';
import { Link } from '@reach/router';
import { useProgram } from 'contexts/program';
import { LoadingSkeleton } from 'shared/LoadingSkeleton';
import styles from './layout.module.css';

export const Breadcrumbs: React.FC<{
  links: Array<{
    label: string;
    to?: string;
    isLoading?: boolean;
  }>;
}> = ({ links }) => {
  const { id: programId } = useProgram();
  const back = links[links.length - 2] || { to: `/${programId}/app` };
  if (links.length < 2) return null;
  return (
    <ol className={styles.Breadcrumbs}>
      <li>{back.to ? <Link to={back.to}>{'<-'}</Link> : '<-'}</li>
      {links.map((link) => (
        <li key={link.to || 'last'}>
          {link.isLoading && <LoadingSkeleton width={100} />}
          {!link.isLoading &&
            (link.to ? (
              <Link to={link.to} className={styles.BreadcrumbLink}>
                {link.label}
              </Link>
            ) : (
              link.label
            ))}
        </li>
      ))}
    </ol>
  );
};
