import { useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { deepMerge, clone } from 'utility/deep-merge';
import { useFeatureFlagsQuery } from './feature-flags';

// This hook is for choosing the correct names to use for our product and features.
// To add new terms,
//   - extend the `STANDARD_VOCAB` in a sensible way
//   - add any overrides to the `NEWEDITOR_VOCAB` if needed
//   - create a new set of overrides if needed, updating the hook as needed
//   - address the `ProductVocabulary` type if needed

const STANDARD_VOCAB = {
  PAGES: {
    PUBLISHER: {
      COMPOSE: 'compose', // deprecated
      DELIVER: 'deliver',
      DESIGN: 'design',
      ORCHESTRATE: 'orchestrate', // deprecated
      REVIEW: 'review',
      TEMPLATE: 'template',
    },
  },
} as const; // const is used for inferring the type below

const NEW_PUB_EDITOR_VOCAB: PartialProductVocabulary = {
  PAGES: {
    PUBLISHER: { COMPOSE: 'design' },
  },
}; // no const, this uses the inferred typed

const NEW_ORCH_EDITOR_VOCAB: PartialProductVocabulary = {
  PAGES: {
    PUBLISHER: { ORCHESTRATE: 'deliver', TEMPLATE: 'review' },
  },
}; // no const, this uses the inferred typed

export function useProductVocabulary(): ProductVocabulary {
  const usingNewPubEditors = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  return useMemo(() => {
    let vocab = clone(STANDARD_VOCAB as ProductVocabulary);
    if (usingNewPubEditors) vocab = deepMerge(vocab, NEW_PUB_EDITOR_VOCAB);
    vocab = deepMerge(vocab, NEW_ORCH_EDITOR_VOCAB); // Always merge NEW_ORCH_EDITOR_VOCAB
    return vocab;
  }, [usingNewPubEditors]);
}

// The types are mostly inferred by the value of `STANDARD_VOCAB`
type PublisherPage = keyof typeof STANDARD_VOCAB['PAGES']['PUBLISHER'];
type ProductVocabulary = {
  PAGES: {
    PUBLISHER: Record<PublisherPage, string>;
  };
};
type PartialProductVocabulary = {
  PAGES: {
    PUBLISHER: Partial<ProductVocabulary['PAGES']['PUBLISHER']>;
  };
};
