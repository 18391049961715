import React, { useEffect } from 'react';
import Select, {
  components,
  ValueType,
  MenuProps,
  OptionProps,
} from 'react-select';
import { OptionType } from 'hooks/common';
import { selectComponents, selectStyles, selectTheme } from '../Select';
import mainStyles from '../../New/Main/main.module.css';
import styles from '../../user-profile.module.css';

const menuStyle = (provided: React.CSSProperties) => ({
  ...provided,
  backgroundColor: '#fff',
  width: 'auto',
  cursor: 'pointer',
});

const Menu: React.FC<MenuProps<OptionType>> = (props) => {
  const {
    children,
    selectProps: { hoveredRole },
  } = props;

  return (
    // eslint-disable-next-line
    <components.Menu {...props}>
      <div className={mainStyles.dropdownWrapper}>
        <div className={styles.itemOptionsWrapper}>{children}</div>
        <div className={styles.itemDescriptionWrapper}>
          <h2>{hoveredRole?.label}</h2>
          <p>{hoveredRole?.description}</p>
        </div>
      </div>
    </components.Menu>
  );
};

const Option: React.FC<OptionProps<OptionType>> = (props) => {
  const {
    children,
    data,
    isFocused,
    selectProps: { setHoveredRole },
  } = props;
  if (isFocused) {
    setHoveredRole(data);
  }
  return (
    <div onMouseEnter={() => setHoveredRole(data)}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props}>{children}</components.Option>
    </div>
  );
};

export const RoleSelect: React.FC<{
  onChange: (key: string, value: string) => void;
  options: OptionType[];
  value?: string;
  isDisabled?: boolean;
}> = ({ onChange, value, isDisabled, options }) => {
  const optionValue =
    options.find((option: OptionType) => option.value === value) || options[0];

  const [hoveredRole, setHoveredRole] = React.useState<OptionType>(options[0]);

  const onOptionChange = (option: ValueType<OptionType>) => {
    const newOp = option as OptionType;
    onChange('role', newOp.value);
    setHoveredRole(newOp);
  };

  useEffect(() => {
    setHoveredRole(options[0]);
  }, [options]);

  useEffect(() => {
    if (!value) {
      onChange('role', options[0]?.value);
    }
    // eslint-disable-next-line
  }, [value, onChange]);

  return (
    <Select
      value={optionValue}
      options={options}
      isDisabled={isDisabled}
      onChange={onOptionChange}
      components={{ ...selectComponents, Menu, Option }}
      hoveredRole={hoveredRole}
      setHoveredRole={setHoveredRole}
      styles={{ ...selectStyles, menu: menuStyle }}
      theme={selectTheme}
      menuPlacement="auto"
    />
  );
};
