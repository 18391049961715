import * as React from 'react';
import { ImageData, MAX_GALLERY_SIZE } from 'models/image';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Sortable } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { UpdateOpts } from '../../../useFieldForm';
import { Source, SourceMenu } from '../../shared/SourceMenu';
import { UPLOAD } from '../../shared/SourceMenuConst';
import { useGallerySourceMenu, CLOSED } from '../hooks/useGallerySourceMenu';
import { FileUploader, UrlUploader } from './Uploader';
import { DropZone, useDropZone } from './DropZone';
import { Thumbnail } from './Thumbnail';
import { useKeepOpen } from '../../shared/KeepOpen';
import { ExitBlocker } from './ExitBlocker';

const idAttr = (item: ImageData, index: number) =>
  `${item.imageId}` || `${item.url}-${index}`;

export const ImageList: React.FC<{
  images: Array<ImageData>;
  onRemove: (index: number) => void;
  onSetCurrent: (index: number) => void;
  replaceItems: (image: ImageData[], opts?: UpdateOpts) => void;
  onDrop: (image: Array<ImageData>) => void;
  onReorder: (image: Array<ImageData>) => void;
  onClearAll: () => void;
  onCreate: (source?: Source) => void;
}> = (props) => {
  const { images, onReorder } = props;
  const dz = useDropZone(props);
  const sourceField = useGallerySourceMenu(
    { source: UPLOAD, visibility: CLOSED },
    dz.handleUpload,
    MAX_GALLERY_SIZE - images.length
  );

  const { replaceItems } = props;
  const onProcessed = React.useCallback(
    (data: ImageData) => replaceItems([data], { shouldValidate: false }),
    [replaceItems]
  );

  useKeepOpen(
    ExitBlocker,
    dz.hasPendingUploads || images.some((img) => !img.processed)
  );

  return (
    <>
      <DropZone onFileSelect={dz.onFileSelect} handleUpload={dz.handleUpload}>
        <Sortable
          items={images}
          idAttr={idAttr}
          onSort={onReorder}
          itemType="GridSm"
          Item={({ item, index }) => (
            <Thumbnail
              item={item}
              onEdit={() => props.onSetCurrent(index)}
              onRemove={() => props.onRemove(index)}
              canRemove={images.length > 1}
            />
          )}
        />

        {dz.uploadingFiles.map((file) => (
          <FileUploader
            onUpload={dz.onFileUpload}
            data={file}
            key={file.id}
            onProcessed={onProcessed}
          />
        ))}

        {dz.uploadingUrls.map((image) => (
          <UrlUploader
            key={image.url}
            onUpload={dz.onUrlUpload}
            data={image.url}
            onProcessed={onProcessed}
          />
        ))}
      </DropZone>
      {!sourceField.externalInput && images.length < MAX_GALLERY_SIZE && (
        <Flex center>
          <SourceMenu menu={sourceField.menu} top={1} left={1}>
            <Button
              data-test="change-source-button-top"
              onClick={() => {}}
              label="+ New Image"
            />
          </SourceMenu>
        </Flex>
      )}
      {sourceField.fileInput}
      {sourceField.libraryInput}
      {sourceField.externalInput}
    </>
  );
};
