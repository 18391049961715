import { useNavigate } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useFlashServerErrors } from 'utility/errors';
import { EmailAlias } from 'models/email-alias';
import { useQueryClient } from 'react-query';
import { BulkSelection, EmailAliasBulkActionFilters } from 'hooks/common';
import {
  useUpdateEmailAlias,
  useUnarchiveEmailAlias,
  useArchiveEmailAlias,
  useCreateEmailAlias,
  useBulkArchiveEmailAlias,
  useBulkUnarchiveEmailAlias,
} from 'hooks/email-alias';

export const useActions = (
  setEmailAlias?: (emailAlias: EmailAlias) => void
): {
  update: (emailAlias: EmailAlias) => void;
  create: (emailAlias: Partial<EmailAlias>) => void;
  archive: (emailAliasId: string) => void;
  unarchive: (emailAliasId: string) => void;
  bulkArchive: (
    bulkSelection: BulkSelection,
    filterConfig: EmailAliasBulkActionFilters
  ) => void;
  bulkUnarchive: (
    bulkSelection: BulkSelection,
    filterConfig: EmailAliasBulkActionFilters
  ) => void;
} => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const flashServerErrors = useFlashServerErrors();
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();
  const emailAliasListUrl = `/${programId}/configure/email-alias`;
  const infiniteQueryKey = 'email-aliases';

  const { update } = useUpdateEmailAlias({
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Email alias updated successfully',
      });
      navigate(emailAliasListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update email alias');
    },
  });

  const { create } = useCreateEmailAlias({
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Email alias created successfully',
      });
      navigate(emailAliasListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not create email alias');
    },
  });

  const { archiveEmail: archive } = useArchiveEmailAlias({
    onSuccess: (data) => {
      setEmailAlias?.(data);
      setFlashMessage({
        severity: 'info',
        message: 'Email alias archived successfully',
      });
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not archive email alias');
    },
  });

  const { unarchiveEmail: unarchive } = useUnarchiveEmailAlias({
    onSuccess: (data) => {
      setEmailAlias?.(data);
      setFlashMessage({
        severity: 'info',
        message: 'Email alias unarchived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not unarchive email alias',
      }),
  });

  const { bulkArchive } = useBulkArchiveEmailAlias(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Email aliases archived successfully.',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive Email aliases',
      }),
  });

  const { bulkUnarchive } = useBulkUnarchiveEmailAlias(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Email aliases unarchived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive Email aliases',
      }),
  });

  return { update, archive, unarchive, create, bulkArchive, bulkUnarchive };
};
