import React from 'react';
import { SignInConfig } from 'models/signInConfig';
import { Section, Button, Subsection } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Gray10PillClass } from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import { ConfirmModal } from 'DesignSystem/Components/Modal';
import styles from './styles.module.css';

export const SsoSection: React.FC<{
  signInConfig: SignInConfig;
  setDefault: (value: string) => void;
}> = ({ signInConfig, setDefault }) => {
  const { id: programId } = useProgram();
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  const handleConfirm = () => {
    setDefault('sso');
    setIsConfirmOpen(false);
  };

  const enabled = signInConfig.enabled.sso;
  const firstupEnabled =
    signInConfig.enabled.username ||
    signInConfig.enabled.knownEmail ||
    signInConfig.enabled.otherEmail;
  const defaultOpt =
    signInConfig.defaultSignInMethod === 'sso' || (enabled && !firstupEnabled);

  const onIcon = <SVGIcon name="CheckCircleOutline" />;
  const offIcon = <SVGIcon name="CloseCircleOutline" />;

  const title = (
    <div className={styles.Title}>
      <span>SSO via SAML</span>
      {enabled && defaultOpt && (
        <span className={styles.DefaultBill}>Default</span>
      )}
      {enabled && !defaultOpt && (
        <Button
          onClick={() => {
            setIsConfirmOpen(true);
          }}
          secondary
          className={styles.SetDefaultButton}
          label="Set as default"
        />
      )}
    </div>
  );

  return (
    <Section
      title={title}
      description="Allow users to access the community with their Single Sign-On (SSO) ID and password."
      href={enabled ? `/${programId}/configure/user-access/sso` : undefined}
      className={styles.MainSection}
    >
      {!enabled && (
        <div className={styles.DisabledSection}>
          SSO Sign-in method is currently disabled
          <Button
            label="Turn On SSO"
            href={`/${programId}/configure/user-access/sso`}
          />
        </div>
      )}
      {enabled && (
        <>
          <Subsection title="">
            <Pills
              values={[{ id: 'ssoEnabled' }]}
              render={() => (
                <>
                  SSO Method:&nbsp;
                  <strong>{(enabled && 'ON') || 'OFF'}</strong>
                </>
              )}
              pillIcon={() => (enabled && onIcon) || offIcon}
              pillClassName={Gray10PillClass}
              small
              padding={[0, 8]}
            />
          </Subsection>

          <Subsection
            title="Login Page Label"
            description={signInConfig.labels.sso || 'SSO'}
            divider={false}
          />

          <Subsection
            title="Entity ID"
            description={signInConfig.sso.entityId}
            divider={false}
          />
          <div className={styles.SsoUrls}>
            <Subsection
              title="SSO Target URL"
              description={signInConfig.sso.ssoTargetUrl}
              divider={false}
            />
            <Subsection
              title="SLO Target URL"
              description={signInConfig.sso.sloTargetUrl}
              divider={false}
            />
          </div>

          <Subsection
            title="Name ID Format"
            description={signInConfig.sso.format}
            divider={false}
          />
          <Subsection
            title="SAML Assertions/Request"
            description={signInConfig.sso.assertions}
            divider={false}
          />
        </>
      )}
      {isConfirmOpen && (
        <ConfirmModal
          title="Change default sign-in method"
          confirmLabel="Set as default"
          onConfirm={handleConfirm}
          onCancel={() => {
            setIsConfirmOpen(false);
          }}
        >
          This action will change the default sign-in method. The user will see
          this sign-in method first but the other sign-in method will still be
          available to tab into.
        </ConfirmModal>
      )}
    </Section>
  );
};
