import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionItalicize: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    aria-label="Italic"
    onClick={() => editor.chain().focus().toggleItalic().run()}
    disabled={!editor.can().chain().focus().toggleItalic().run()}
    isActive={editor.isActive('italic')}
    icon="format_italic"
  />
);
