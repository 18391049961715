import React, { FC } from 'react';
import { localizeDate, timeAgoOrDate } from 'utility/patron-date';
import { PreviewCard } from '../common';
import { useTopicFormCtx } from '../../../../../../context';
import styles from './styles.module.css';

const TopicStat: FC<{ label: string }> = ({ label, children }) => {
  if (!children) {
    return null;
  }
  return (
    <div className={styles.statItemContainer}>
      <dt className={styles.statItemTitle}>{label}</dt>
      <dd className={styles.statItemBody}>{children}</dd>
    </div>
  );
};

const StatsPreview = (): JSX.Element => {
  const { topic } = useTopicFormCtx();
  const { stats } = topic;
  return (
    <PreviewCard title="Topic Statistics">
      <div className={styles.statContent}>
        <dl className={styles.statList}>
          {stats?.created && (
            <TopicStat label="Created">
              <time dateTime={stats?.created}>
                {localizeDate(stats?.created)}
              </time>
            </TopicStat>
          )}

          {stats?.lastUpdated && (
            <TopicStat label="Last updated">
              <time dateTime={stats?.lastUpdated}>
                {timeAgoOrDate(stats?.lastUpdated)}
              </time>
            </TopicStat>
          )}

          <TopicStat label="Avg. per week">
            {stats?.postsPerWeek || 0} post
            {stats?.postsPerWeek === 1 ? '' : 's'}
          </TopicStat>
          <TopicStat label="Members">
            {stats?.followerCount || 0} member
            {stats?.followerCount === 1 ? '' : 's'}
          </TopicStat>
          <TopicStat label="Contributors">
            {stats?.contributorCount || 0} contributor
            {stats?.contributorCount === 1 ? '' : 's'}
          </TopicStat>
        </dl>
      </div>
    </PreviewCard>
  );
};
export default StatsPreview;
