import React, { useCallback, useState } from 'react';
import { Audience } from 'models/audience';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import { useAudienceList } from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/useAudienceList';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Body, Caption } from 'DesignSystem/Typography';
import { pluralize } from 'utility/text';
import { ListReturnType } from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/useTopicsList';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { SearchInput } from 'shared/SearchInput';

interface SelectAudienceProps {
  disabled?: boolean;
  selectedAudiences: Array<Audience>;
  onAudiencesChange: (value: Array<Audience>) => void;
  hideUserCount?: boolean;
}

export const SelectAudience: React.FC<SelectAudienceProps> = ({
  disabled,
  selectedAudiences,
  onAudiencesChange,
  hideUserCount = false,
}) => {
  const [searchText, setSearchText] = useState('');
  const audiencesData = useAudienceList(
    selectedAudiences,
    searchText,
    onAudiencesChange,
    []
  );

  const rowRenderer = useCallback(
    (dataByRowId: ListReturnType['dataByRowId']) => (rowId: string) => {
      const item: Audience = dataByRowId[rowId] as Audience;
      return item ? (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            margin={[0, 16, 0, 0]}
            maxWidth={375}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Body>{item.title || item.name}</Body>
          </Box>
          {!hideUserCount && (
            <Box style={{ marginLeft: 'auto' }}>
              <Caption>
                {`${item.totalUsers} ${pluralize(item.totalUsers, 'user')}`}
              </Caption>
            </Box>
          )}
        </Box>
      ) : null;
    },
    [hideUserCount]
  );

  const selectRow = (
    selectedIds: ListReturnType['selectedIds'],
    onSelectedIdsChange: ListReturnType['onSelectedIdsChange'],
    updateCache: (id: string) => void
  ) => (id: string) => {
    updateCache(id);
    const newSelectedIds = [...selectedIds];
    const index = selectedIds.indexOf(id);
    if (index >= 0) {
      newSelectedIds.splice(index, 1);
    } else {
      newSelectedIds.push(id);
    }
    onSelectedIdsChange(newSelectedIds);
  };

  const selectAudienceDropdown = (
    <Box minWidth={400} margin={[-8, 0, 0, 0]}>
      <Popover padding={4}>
        <InfiniteSelect
          existenceGranted={false}
          rowIds={audiencesData.dataRowIds}
          rowRenderProp={rowRenderer(audiencesData.dataByRowId)}
          maxHeight={400}
          itemHeight={40}
          selectedIds={audiencesData.selectedIds}
          onSelectedIdsChange={audiencesData.onSelectedIdsChange}
          fetchNextPage={audiencesData.fetchNextPage}
          hasNextPage={audiencesData.hasNextPage}
          isFetchingNextPage={audiencesData.isFetchingNextPage}
          isLoading={audiencesData.isLoading}
          onHandleChange={selectRow(
            audiencesData.selectedIds,
            audiencesData.onSelectedIdsChange,
            audiencesData.updateCache
          )}
          hoverFocus={false}
          clearDisabled
          searchTerm={searchText}
          onSearchTermChange={setSearchText}
          searchEnabled={false}
          noShadow
        />
      </Popover>
    </Box>
  );

  return (
    <>
      <ClickDropdown
        dropdownRenderProp={selectAudienceDropdown}
        matchReferenceWidth
        disabled={disabled}
        referencePress={false}
        ignoreKeys
        autoUpdate
        onClose={() => {
          setSearchText('');
        }}
      >
        <div>
          <SearchInput
            placeholder="Search audiences"
            value={searchText || ''}
            onChange={setSearchText}
          />
        </div>
      </ClickDropdown>
    </>
  );
};
