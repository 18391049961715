import { ThumbnailType } from 'shared/BannerListItem';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { ItemType } from 'shared/IconMenu/IconMenuItem';

export const userImportToThumbnail = (
  userImport: Pick<BulkUploadJob, 'fileName'>
): ThumbnailType => {
  return {
    title: userImport.fileName,
    imageUrl: '',
    backgroundColor: '#5d6480',
    color: '#fff',
  };
};

export const userImportToMenuItems = (
  userImport: BulkUploadJob,
  programId: number,
  downloadFile: (jobId: number) => void,
  downloadErrorFile: (jobId: number) => void
): MenuItemsType => {
  const items: ItemType[] = [
    {
      title: 'View report',
      href: `/${programId}/configure/imports/${userImport.id}`,
    },
    {
      title: 'Download my file',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        downloadFile(Number(userImport.id));
      },
      href: '#',
    },
  ];

  if (userImport.status === 'partial') {
    items.push({
      title: 'Download error file',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        downloadErrorFile(Number(userImport.id));
      },
      href: '#',
    });
  }
  return items;
};
