import React from 'react';
import { OptionType } from 'hooks/common';
import defaulltAvatar from 'assets/images/Avatars.png';
import { Row, Block, Section } from 'shared/SectionBlocks';
import { useTopicsByIdsQuery } from 'hooks/topics';
import { useProgram } from 'contexts/program';
import styles from '../../../user-profile.module.css';

type TopicType = {
  id?: number | string;
  name?: string;
  avatar?: string;
};

const Topic: React.FC<{
  data: TopicType;
}> = ({ data }) => (
  <Block className={styles.topicBlock}>
    <div className={styles.topicAvatarWrapper}>
      <span className={styles.topicAvatar}>
        <img alt={`${data.name} Avatar`} src={data.avatar || defaulltAvatar} />
      </span>
      {data.name}
    </div>
  </Block>
);

export const Topics: React.FC<{
  data: OptionType[];
}> = ({ data }) => {
  const { id: programId } = useProgram();
  const topicIds = data.map((topic: OptionType) => topic.value);
  const topics: Array<TopicType> = useTopicsByIdsQuery(programId, topicIds).map(
    (topic): TopicType => ({
      id: topic.data?.id,
      name: topic.data?.name,
      avatar: topic.data?.backgroundImageUrl,
    })
  );

  const groupTopics = (_topics: TopicType[]) => {
    const groupedTopicss = [];
    for (let i = 0; i < _topics.length; i += 2) {
      const group = [_topics[i]];
      if (_topics[i + 1]) group.push(_topics[i + 1]);
      groupedTopicss.push(group);
    }

    return groupedTopicss;
  };

  if (!groupTopics(topics).length)
    return (
      <Section title="Followed topics" className={styles.userPage}>
        <Row>
          <Block>
            <div className={styles.empty}>Not subscribed to any topics</div>
          </Block>
        </Row>
      </Section>
    );

  return (
    <Section title="Followed topics" className={styles.userPage}>
      {groupTopics(topics).map((group: TopicType[]) => (
        <Row key={group[0].name}>
          <Topic data={group[0]} />
          {group[1] && <Topic data={group[1]} />}
        </Row>
      ))}
    </Section>
  );
};
