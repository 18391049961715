import { MenuItemsType, ThumbnailType } from 'shared/BannerListItem';
import { EmailAlias } from 'models/email-alias';

const BACKGROUND_COLORS = ['#5D7E80', '#5D6480', '#6797AC'];

export function emailAliasToThumbnail(
  emailAlias: Pick<EmailAlias, 'name'>,
  index: number
): ThumbnailType {
  return {
    title: emailAlias.name[0],
    color: '#F3F5F7',
    backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
  };
}

export function emailAliasToMenuItems(
  programId: number,
  emailAlias: EmailAlias,
  onSetAsDefault: () => void,
  archiveEmail: (id: string) => void,
  unarchiveEmail: (id: string) => void
): MenuItemsType {
  const result: MenuItemsType = [];

  result.push({
    title: 'Edit',
    href: `/${programId}/configure/email-alias/${emailAlias.id}/edit`,
  });

  if (!emailAlias.default)
    result.push({
      title: 'Set as Default',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        onSetAsDefault();
      },
    });

  if (!emailAlias.default)
    result.push({
      title: emailAlias.status === 'active' ? 'Archive' : 'Unarchive',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (emailAlias.status === 'active') archiveEmail(emailAlias.id);
        else unarchiveEmail(emailAlias.id);
      },
    });

  return result;
}
