import React from 'react';
import { Router, Redirect, RouteComponentProps } from '@reach/router';
import { Template } from './Template';

export const Previews: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Template path="template/*" />
      <Redirect from="template" to=".." noThrow />
      <Redirect from="/" to="../library" noThrow />
      <Redirect from="*" to="/" noThrow />
    </Router>
  );
};
