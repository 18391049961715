import { DateTime, Duration, Interval } from 'luxon';
import {
  hasExecution,
  isArchived,
  isDraft,
  isPublished,
  isScheduled,
  isTopicOnly,
} from 'models/content';
import { isRetryEnabled, Post } from 'models/publisher/post';
import { ResultsSummary } from 'services/api-content-insights';
import { pluralize, titleCase } from 'utility/text';

export type Status =
  | 'scheduled'
  | 'sending'
  | 'started'
  | 'archived'
  | 'archivedAndIncomplete'
  | 'suspended'
  | 'completed';

// TODO: Move this logic to backend. Kai should not be inferring
export const useCampaignStatus = (
  post: Post,
  summary?: ResultsSummary
): {
  percentCompleted?: number;
  campaignStatus?: Status;
  timeLeft?: Duration;
  startDate?: DateTime;
  endDate?: DateTime;
  updatedAt?: DateTime;
  // TODO: API shouldn't be involved in presentational data. Should remove
  //  in future once we determine best way to represent matrix of boost x status
  //  See figma designs
  statusLabel?: string;
  formattedTiming?: string;
  endLabel?: string;
} => {
  // TODO: Make startDate and endDate more explicit using hasExecution
  const startDate =
    (summary?.epochStart && DateTime.fromSeconds(summary.epochStart)) ||
    (post.content.publishedAt && DateTime.fromISO(post.content.publishedAt)) ||
    undefined;

  // EndDate is optional because topicOnly and Overrides should not have endDates
  const endDate =
    (summary?.epochEnd && DateTime.fromSeconds(summary.epochEnd)) || undefined;

  const updatedAt =
    (summary?.metricsUpdatedAt && DateTime.fromISO(summary.metricsUpdatedAt)) ||
    (post?.content?.updatedAt && DateTime.fromISO(post.content.updatedAt)) ||
    undefined;

  const stateChangedDate =
    (summary?.executionStateUpdatedAt &&
      DateTime.fromISO(summary.executionStateUpdatedAt)) ||
    undefined;

  const now = DateTime.now();

  const campaignDuration =
    startDate && endDate && calcInterval(startDate, endDate);
  const timeRemaining = endDate && calcInterval(now, endDate);
  const timePassed = startDate && calcInterval(startDate, now);
  const timePassedTillArchived =
    stateChangedDate && startDate && calcInterval(startDate, stateChangedDate);

  // TODO: Will need to implement this. See Figma designs for "Engagement Boost: Off -- Sending..."
  //  Backend will have to send values for sendingComplete and estimatedTimeToSend
  const sendingComplete = true;
  const estimatedTimeToSend = timePassed?.plus(
    Duration.fromObject({ minutes: 5 })
  );

  const isCommArchived = isArchived(post.content);
  const isCommDraft = isDraft(post.content);
  const isCommPublished = isPublished(post.content);
  const isCommScheduled = isScheduled(post.content);
  const isOverride = !isRetryEnabled(post);

  const topicOnly = isTopicOnly(post.content);
  const hasExec = hasExecution(post.content);

  if (isCommDraft) {
    return {
      campaignStatus: 'suspended',
      statusLabel: 'Suspended',
      percentCompleted: 100,
      startDate,
    };
  }

  // Topic only, archived
  if (topicOnly && isCommArchived) {
    return {
      campaignStatus: 'archived',
      statusLabel: 'Archived',
      percentCompleted: 100,
      startDate,
    };
  }

  // Topic only, published/completed
  if (topicOnly && isCommPublished) {
    return {
      campaignStatus: 'completed',
      statusLabel: 'Complete',
      percentCompleted: 100,
      startDate,
      updatedAt,
    };
  }

  // Topic only, scheduled
  if (topicOnly && isCommScheduled) {
    return {
      campaignStatus: 'scheduled',
      statusLabel: 'Scheduled',
      percentCompleted: 0,
      startDate,
    };
  }

  // (Engagement Boost: Off) Is Override, started, and finished sending, archived
  if (isOverride && timePassed && sendingComplete && isCommArchived) {
    return {
      campaignStatus: 'archived',
      statusLabel: 'Complete (Archived)',
      percentCompleted: 100,
      startDate,
      updatedAt,
    };
  }

  // (Engagement Boost: Off) Is Override, started, and finished sending
  if (isOverride && timePassed && sendingComplete) {
    return {
      campaignStatus: 'completed',
      statusLabel: 'Complete',
      percentCompleted: 100,
      startDate,
      updatedAt,
    };
  }

  // (Engagement Boost: Off) Is Override, started, and not finished sending
  if (isOverride && timePassed && estimatedTimeToSend && !sendingComplete) {
    return {
      campaignStatus: 'started',
      statusLabel: 'Sending...',
      percentCompleted: calcPercentCompleted(timePassed, estimatedTimeToSend),
      startDate,
      formattedTiming: getTimeRemaingLabel(estimatedTimeToSend),
      endLabel: 'Estimated Time Remaining',
      updatedAt,
    };
  }

  // (Engagement Boost: Off) Is Override, not started, archived
  if (isOverride && !timePassed && isCommArchived) {
    return {
      campaignStatus: 'archived',
      statusLabel: 'Archived',
      percentCompleted: 100,
      startDate,
      updatedAt,
    };
  }

  // (Engagement Boost: Off) Is Override, not started
  if (isOverride && !timePassed) {
    return {
      campaignStatus: 'scheduled',
      statusLabel: 'Scheduled',
      percentCompleted: 0,
      startDate,
      updatedAt: hasExec ? updatedAt : undefined,
    };
  }

  // (Engagement Boost: On) Is not Override, started, finished, archived
  if (
    !isOverride &&
    endDate &&
    campaignDuration &&
    timePassed &&
    !timeRemaining &&
    isCommArchived
  ) {
    return {
      campaignStatus: 'archived',
      statusLabel: 'Complete (Archived)',
      percentCompleted: 100,
      startDate,
      endDate,
      formattedTiming: endDate.toLocaleString(DateTime.DATETIME_MED),
      endLabel: 'End Date',
      updatedAt,
    };
  }

  // (Engagement Boost: On) Is not Override, started, finished
  if (
    !isOverride &&
    endDate &&
    campaignDuration &&
    timePassed &&
    !timeRemaining
  ) {
    return {
      campaignStatus: 'completed',
      statusLabel: 'Complete',
      percentCompleted: 100,
      startDate,
      endDate,
      formattedTiming: endDate.toLocaleString(DateTime.DATETIME_MED),
      endLabel: 'End Date',
      updatedAt,
    };
  }

  // (Engagement Boost: On) Is not Override, started, not finished, archived
  if (
    !isOverride &&
    endDate &&
    campaignDuration &&
    timePassed &&
    timeRemaining &&
    isCommArchived &&
    timePassedTillArchived
  ) {
    return {
      campaignStatus: 'archivedAndIncomplete',
      statusLabel: `Archived at ${timePassedTillArchived.days + 1} ${pluralize(
        timePassedTillArchived.days + 1,
        'day'
      )}`,
      percentCompleted: calcPercentCompleted(
        timePassedTillArchived,
        campaignDuration
      ),
      timeLeft: timeRemaining,
      startDate,
      endDate,
      formattedTiming: endDate.toLocaleString(DateTime.DATETIME_MED),
      endLabel: 'Estimated End Date',
      updatedAt: hasExec ? updatedAt : undefined,
    };
  }

  // (Engagement Boost: On) Is not Override, started, not finished
  if (
    !isOverride &&
    endDate &&
    campaignDuration &&
    timePassed &&
    timeRemaining
  ) {
    return {
      campaignStatus: 'started',
      statusLabel: `${getDaysLabel(timeRemaining)} Left`,
      percentCompleted: calcPercentCompleted(timePassed, campaignDuration),
      timeLeft: timeRemaining,
      startDate,
      endDate,
      formattedTiming: endDate.toLocaleString(DateTime.DATETIME_MED),
      endLabel: 'Estimated End Date',
      updatedAt,
    };
  }

  // (Engagement Boost: On) Is not Override, not started, archived
  if (
    !isOverride &&
    endDate &&
    campaignDuration &&
    !timePassed &&
    isCommArchived
  ) {
    return {
      campaignStatus: 'archived',
      statusLabel: 'Archived',
      percentCompleted: 100,
      updatedAt,
    };
  }

  // (Engagement Boost: On) Is not Override, not started
  if (!isOverride && endDate && campaignDuration && !timePassed) {
    return {
      campaignStatus: 'scheduled',
      statusLabel: 'Scheduled',
      percentCompleted: 0,
      timeLeft: campaignDuration,
      startDate,
      endDate,
      formattedTiming: endDate.toLocaleString(DateTime.DATETIME_MED),
      endLabel: 'Estimated End Date',
      updatedAt: hasExec ? updatedAt : undefined,
    };
  }

  return {};
};

const calcInterval = (start: DateTime, end: DateTime): Duration | undefined => {
  const interval = Interval.fromDateTimes(start, end);
  // End is before start
  if (!interval.isValid) {
    return undefined;
  }

  return interval.toDuration(['days', 'hours', 'minutes', 'seconds']);
};

const calcPercentCompleted = (
  timePassed: Duration,
  totalDuration: Duration
): number => {
  const progress = Math.floor(timePassed.shiftTo('seconds').seconds);
  const duration = Math.round(totalDuration.shiftTo('seconds').seconds);
  const percent = Math.floor((progress / duration) * 100);
  return percent < 100 ? percent : 100;
};

const getDaysLabel = (dur: Duration): string => {
  // TODO: If upgrading to luxon 3.0.1 we can do this:
  //    dur.shiftTo('days').toHuman({floor: true})
  return titleCase(dur.shiftTo('days').mapUnits(Math.round).toHuman());
};

const getTimeRemaingLabel = (dur: Duration): string => {
  return dur
    .shiftTo('day', 'hour', 'minute')
    .normalize()
    .mapUnits(Math.round)
    .toHuman({ listStyle: 'long' });
};
