import React, { RefObject, useState } from 'react';
import { Link } from '@reach/router';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { User } from 'models/user';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  useUserBulkActivate,
  useUserBulkDeactivate,
  useInviteUsers,
  useBulkExportUsers,
  useUserBulkHide,
} from 'hooks/user';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { useFlashMessage } from 'contexts/flasher';
import { UserExportData } from 'services/api-audiences';
import { BulkSelection, UserBulkActionFilters } from 'hooks/common';

import { ActionButtonType } from 'shared/BulkSelect/Actions';
import { usePermissions } from 'contexts/permissions';
import { UsersListItem } from './UsersListItem';
import { UserFullListItem } from './UserFullListItem';
import { UserExportLoader } from './UserExportLoader';
import { ImportUsersSlideIn } from './ImportUsersSlideIn';
import { EditRoleModal } from './EditRole/EditRoleModal';
import { EditPermissionsModal } from './EditPermissions/EditPermissionsModal';

type PropsType = {
  users: Array<User>;
  query?: string;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  parentRef?: RefObject<HTMLDivElement>;
  height?: number;
  showFull?: boolean;
  totalRecords?: number;
  filterConfig?: UserBulkActionFilters;
  isImportOpen?: boolean;
  setIsImportOpen?: (value: boolean) => void;
};

export const UsersList: React.FC<PropsType> = (props) => {
  const {
    isLoading,
    users,
    query,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    showFull,
    totalRecords,
    parentRef,
    height,
    filterConfig,
    isImportOpen,
    setIsImportOpen,
  } = props;
  const setIsBulkSelectMode = useState(false)[1];
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [showUpdatePermissionsModal, setShowUpdatePermissionsModal] = useState(
    false
  );
  const { setFlashMessage } = useFlashMessage();
  const {
    role: currentUserRole,
    permissions: { configureRoleAccess },
  } = usePermissions();
  const showFlashMessage = (message: string, isError = false): void => {
    setFlashMessage({
      severity: isError ? 'error' : 'info',
      message,
    });
  };
  const itemCount = users.length || 0;
  const ListItem = showFull ? UserFullListItem : UsersListItem;
  const { id: programId, emailTokenAuthEnabled } = useProgram();
  const [userExportData, setUserExportData] = useState<
    UserExportData | undefined
  >(undefined);
  const { invite } = useInviteUsers(programId);
  const { activate } = useUserBulkActivate(programId);
  const { deactivate } = useUserBulkDeactivate(programId);
  const { bulkExport } = useBulkExportUsers(
    programId,
    (data, selectedCount) => {
      if (selectedCount <= 1000) {
        setUserExportData(data);
        showFlashMessage(
          'Your export is being prepared and will be downloaded shortly.'
        );
      } else {
        setFlashMessage({
          message:
            'The export is being prepared and will be downloadable soon on the exports page.',
          severity: 'info',
          children: (
            <Link to={`/${programId}/configure/exports`}>Exports Page</Link>
          ),
        });
      }
    },
    {
      onError: () => {
        showFlashMessage(
          'Oops! Something went wrong while preparing the export!',
          true
        );
      },
    }
  );

  const { hide } = useUserBulkHide(programId, {
    onSuccess: () => showFlashMessage('Users hidden.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const {
    data: litmusRoles,
    isLoading: isLitmusRolesLoading,
  } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });

  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;

  const bulkUpdatePermissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.BulkUpdatePermissions.UI').data
      ?.value as boolean) || false;

  const actions = [
    {
      type: 'button',
      actions: [
        {
          label: 'Export',
          hideLabel: true,
          customTitle: 'Export',
          onClick: (bulkSelection: BulkSelection) => {
            const selected_ids =
              bulkSelection.type === 'none'
                ? bulkSelection.includedIds
                : undefined;
            const excluded_ids =
              bulkSelection.type === 'all'
                ? bulkSelection.excludedIds
                : undefined;
            const searchQuery = query?.length ? query : undefined;
            bulkExport(
              selected_ids,
              excluded_ids,
              searchQuery,
              filterConfig?.statuses,
              filterConfig?.roles,
              totalRecords,
              filterConfig?.audiences,
              filterConfig?.scopes
            );
            return true;
          },
        },
      ],
    },
    {
      type: 'button',
      actions: [
        {
          label: 'Activate',
          onClick: (bulkSelection: BulkSelection) => {
            activate(bulkSelection, filterConfig);
            return true;
          },
        },
      ],
    },
    {
      type: 'button',
      actions: [
        {
          label: 'Deactivate',
          onClick: (bulkSelection: BulkSelection) => {
            deactivate(bulkSelection, filterConfig);
            return true;
          },
        },
      ],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as any[];

  if (
    permissionsEnabled &&
    bulkUpdatePermissionsEnabled &&
    configureRoleAccess
  ) {
    actions.push({
      type: 'button',
      actions: [
        {
          label: 'Update Role',
          hideLabel: true,
          customTitle: 'Update Role',
          onClick: () => {
            setShowUpdateRoleModal(true);
            return false;
          },
          confirm: false,
        },
      ],
    });

    actions.push({
      type: 'button',
      actions: [
        {
          label: 'Update Permissions',
          hideLabel: true,
          customTitle: 'Update Permissions',
          onClick: () => {
            setShowUpdatePermissionsModal(true);
            return false;
          },
          confirm: false,
        },
      ],
    });
  }

  const moreActions = [
    {
      label: 'Invite to Studio',
      onClick: (bulkSelection) => {
        const selected_ids =
          bulkSelection.type === 'none' ? bulkSelection.includedIds : undefined;
        let excluded_ids =
          bulkSelection.type === 'all' ? bulkSelection.excludedIds : undefined;
        const sendExperienceEmail = 'false';
        const sendStudioEmail = 'true';
        const searchQuery = query?.length ? query : undefined;
        if (excluded_ids?.length === 0) {
          excluded_ids = ['0'];
        }
        invite(
          selected_ids,
          excluded_ids,
          sendExperienceEmail,
          sendStudioEmail,
          searchQuery,
          filterConfig?.audiences,
          filterConfig?.scopes
        );
        return true;
      },
    },
  ] as ActionButtonType[];

  if (!emailTokenAuthEnabled) {
    moreActions.push({
      label: 'Invite to Experience',
      onClick: (bulkSelection) => {
        const ids =
          bulkSelection.type === 'none' ? bulkSelection.includedIds : undefined;
        let excluded_ids =
          bulkSelection.type === 'all' ? bulkSelection.excludedIds : undefined;
        const sendExperienceEmail = 'true';
        const sendStudioEmail = 'false';
        const searchQuery = query?.length ? query : undefined;
        if (excluded_ids?.length === 0) {
          excluded_ids = ['0'];
        }
        invite(
          ids,
          excluded_ids,
          sendExperienceEmail,
          sendStudioEmail,
          searchQuery,
          filterConfig?.audiences,
          filterConfig?.scopes
        );
        return true;
      },
    });
  }

  if (currentUserRole === 'super_admin' && !permissionsEnabled)
    moreActions.push({
      label: 'Hide',
      onClick: (bulkSelection) => {
        hide(bulkSelection, filterConfig);
        return true;
      },
    });
  return (
    <>
      {userExportData && (
        <UserExportLoader
          userExportData={userExportData}
          onDownloadStart={() => setUserExportData(undefined)}
        />
      )}
      <BulkSelectWrapper
        itemName="user"
        totalRecords={totalRecords || 0}
        disabled={!showFull}
        actions={actions}
        moreActions={moreActions}
        setIsBulkSelectMode={setIsBulkSelectMode}
      >
        <InfiniteBannerList
          itemCount={itemCount}
          isLoading={isLoading || isLitmusRolesLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          noItemsComponent={<div>No users found.</div>}
          height={height}
          parentRef={parentRef}
        >
          {(index: number) => (
            <ListItem
              user={users[index]}
              key={users[index].id}
              permissionsEnabled={permissionsEnabled}
              litmusRoles={litmusRoles}
            />
          )}
        </InfiniteBannerList>
        {isImportOpen && (
          <ImportUsersSlideIn
            name="importUsers"
            onClose={() => setIsImportOpen?.(false)}
          />
        )}
        {showUpdateRoleModal && (
          <EditRoleModal
            filter={filterConfig}
            close={() => setShowUpdateRoleModal(false)}
          />
        )}
        {showUpdatePermissionsModal && (
          <EditPermissionsModal
            filter={filterConfig}
            close={() => setShowUpdatePermissionsModal(false)}
          />
        )}
      </BulkSelectWrapper>
    </>
  );
};
