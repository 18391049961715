import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { ShortcutsPageList } from './ShortcutsPageList';
import { ShortcutsPageInit } from './ShortcutsPageInit';
import { ShortcutsPageBossWrapper } from './ShortcutsPageBossWrapper';

const PAGE_TITLE = 'Shortcuts';

export const Shortcuts: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();

  const {
    isLoading,
    data: { value: showShortcutsListPage } = { value: false },
    errorMessage: featureFlagErrorMessage,
  } = useFeatureFlagsQuery(programId, 'Studio.Shortcuts.Enabled');

  if (isLoading || featureFlagErrorMessage) {
    return (
      <ShortcutsPageInit title={PAGE_TITLE} message={featureFlagErrorMessage} />
    );
  }

  if (showShortcutsListPage) {
    return <ShortcutsPageList title={PAGE_TITLE} />;
  }

  return <ShortcutsPageBossWrapper title={PAGE_TITLE} />;
};
