import * as React from 'react';
import cx from 'classnames';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { ColorPalette, Trash, CodeAlt } from 'shared/icons';
import { DirectionalTooltip } from 'shared/DirectionalTooltip/DirectionalTooltip';
import styles from 'components/publisher/blocks/dnd.module.css';
import { useCanvasStylePanel } from 'contexts/publisher/compose/canvas-style-panel';
import { PublisherMode } from 'contexts/publisher';
import { FormModal } from 'DesignSystem/Components';
import { FieldInput, Input } from 'DesignSystem/Form';
import * as Type from 'DesignSystem/Typography';
import { FieldDefinition } from 'models/publisher/block';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';

type ControlsProps = {
  blockId: string;
  supportsCodeView: boolean;
  canEdit: boolean;
  showEditor: (() => void) | false;
  showVariantsModal: () => void;
  deleteBlock: () => void;
  canDuplicate: boolean;
  duplicateBlock: () => void;
  selectBlock: () => void;
  toggleCodeView: () => void;
  canSaveCustomBlock: boolean;
  saveCustomBlock: (title: string) => void;
};

const SaveBlockControl: React.FC<{
  saveCustomBlock: ControlsProps['saveCustomBlock'];
}> = ({ saveCustomBlock }) => {
  const [showTitlePrompt, setShowTitlePrompt] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [showLengthWarning, setShowLengthWarning] = React.useState<boolean>(
    false
  );
  const [showHtmlWarning, setShowHtmlWarning] = React.useState<boolean>(false);

  const onInputChange = React.useCallback((value: string) => {
    const titleLength = value.trim().length;
    const hasHtmlTags = /<\/?[a-z][\s\S]*>|<.*?>/i.test(value);
    setShowLengthWarning(titleLength > 50);
    setShowHtmlWarning(hasHtmlTags);
    setTitle(value);
  }, []);

  const onSubmit = () => {
    saveCustomBlock(title);
    setTitle('');
    setShowTitlePrompt(false);
  };
  const onCancel = () => {
    setTitle('');
    setShowHtmlWarning(false);
    setShowTitlePrompt(false);
  };

  return (
    <>
      <DirectionalTooltip
        title="Save Block"
        width="90px"
        height="30px"
        direction="right"
        className={styles.dropdown}
      >
        <button
          onClick={() => setShowTitlePrompt(true)}
          type="button"
          className={cx(styles.icon, styles.menuItem)}
          data-test="save-custom-block"
        >
          <SVGIcon name="Download" />
        </button>
      </DirectionalTooltip>
      {showTitlePrompt && (
        <FormModal
          entityText="block name"
          actionText="add"
          onSubmit={onSubmit}
          onCancel={onCancel}
          disabled={title.length === 0 || title.length > 50 || showHtmlWarning}
          width={330}
        >
          <FieldInput
            htmlFor="custom-block-name"
            fieldSetClass={styles.fieldInput}
          >
            <Input
              id="custom-block-name"
              placeholder="Custom block name..."
              value={title}
              onChange={onInputChange}
              block
            />
            {showLengthWarning && (
              <Type.Body color={Type.color.redFull}>
                The name is too long, the maximum length is 50 characters.
              </Type.Body>
            )}
            {showHtmlWarning && (
              <Type.Body color={Type.color.redFull}>
                You cannot have HTML tags in the name.
              </Type.Body>
            )}
          </FieldInput>
        </FormModal>
      )}
    </>
  );
};

const DeleteControl: React.FC<{
  isDeleting: boolean;
  setIsDeleting: (isDeleting: boolean) => void;
  deleteBlock: () => void;
  selectBlock: () => void;
}> = ({ deleteBlock, setIsDeleting, selectBlock, isDeleting }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const catchOuterClick = React.useCallback(
    (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        setIsDeleting(false);
      }
    },
    [ref, setIsDeleting]
  );

  React.useEffect(() => {
    window.addEventListener('mouseup', catchOuterClick);
    return () => {
      window.removeEventListener('mouseup', catchOuterClick);
    };
  });

  if (!isDeleting)
    return (
      <DirectionalTooltip
        title="Delete"
        width="55px"
        height="30px"
        direction="right"
        className={styles.dropdown}
        tooltipClass={styles.tooltip}
      >
        <button
          onClick={() => {
            setIsDeleting(true);
            selectBlock();
          }}
          type="button"
          className={cx(styles.icon, styles.menuItem)}
        >
          <Trash />
        </button>
      </DirectionalTooltip>
    );

  return (
    <div ref={ref}>
      <div className={styles.deleteControl}>
        <button
          onClick={deleteBlock}
          type="button"
          className={styles.deleteConfirmText}
        >
          Confirm delete
        </button>
        <div className={styles.deleteIconWrapper}>
          <button
            onClick={() => setIsDeleting(false)}
            type="button"
            className={styles.deleteIcon}
          >
            <SVGIcon name="Close" size={12} />
          </button>
        </div>
      </div>
    </div>
  );
};

const Controls: React.FC<
  {
    isDeleting: boolean;
    setIsDeleting: (isDeleting: boolean) => void;
    modalField?: FieldDefinition;
    fieldWithoutControls?: FieldDefinition;
  } & ControlsProps
> = ({
  blockId,
  supportsCodeView,
  isDeleting,
  canEdit,
  canDuplicate,
  canSaveCustomBlock,
  setIsDeleting,
  toggleCodeView,
  showEditor,
  showVariantsModal,
  deleteBlock,
  selectBlock,
  duplicateBlock,
  saveCustomBlock,
  modalField,
  fieldWithoutControls,
}) => {
  const stylePanel = useCanvasStylePanel();
  const showSectionTab = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.BlockTargets'
  ).data?.value;
  const usingNewEditor = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;
  return (
    <ul>
      {supportsCodeView && (
        <li>
          <DirectionalTooltip
            title="Code View"
            width="100px"
            height="30px"
            direction="right"
            className={styles.dropdown}
          >
            <button
              onClick={toggleCodeView}
              type="button"
              className={cx(styles.menuItem, styles.edit)}
              data-test="toggle-code-view"
            >
              <CodeAlt />
            </button>
          </DirectionalTooltip>
        </li>
      )}
      {canEdit && showEditor && modalField && (
        <li>
          <DirectionalTooltip
            title="Edit"
            width="50px"
            height="30px"
            direction="right"
            className={styles.dropdown}
          >
            <button
              onClick={() => {
                setImmediate(() => {
                  showEditor();
                  selectBlock();
                });
              }}
              type="button"
              className={cx(styles.menuItem, styles.edit)}
              data-test="edit-block"
              data-block-action="open-editor"
              data-block-id={blockId}
            >
              <SVGIcon name="Edit" size={20} />
            </button>
          </DirectionalTooltip>
        </li>
      )}
      {showSectionTab && usingNewEditor && (
        <li style={{ display: 'none' }}>
          <DirectionalTooltip
            title="Visibility"
            width="80px"
            height="30px"
            direction="right"
            className={styles.dropdown}
          >
            <button
              onClick={() => {
                setImmediate(() => {
                  showVariantsModal();
                  selectBlock();
                });
              }}
              type="button"
              className={cx(styles.icon, styles.menuItem)}
              data-test="edit-visibility"
              data-block-action="open-section"
              data-block-id={blockId}
            >
              <SVGIcon name="Mobile" size={20} />
            </button>
          </DirectionalTooltip>
        </li>
      )}
      {!fieldWithoutControls && (
        <li>
          <DirectionalTooltip
            title="Duplicate"
            width="80px"
            height="30px"
            direction="right"
            className={styles.dropdown}
          >
            <button
              onClick={duplicateBlock}
              disabled={!canDuplicate}
              type="button"
              className={cx(styles.icon, styles.menuItem)}
              data-test="duplicate-block"
            >
              <SVGIcon name="Duplicate" />
            </button>
          </DirectionalTooltip>
        </li>
      )}
      {!fieldWithoutControls && (
        <li>
          <DirectionalTooltip
            title="Stylize"
            width="60px"
            height="30px"
            direction="right"
            className={styles.dropdown}
          >
            <button
              onClick={() => {
                setImmediate(() => {
                  stylePanel.enable();
                  selectBlock();
                });
              }}
              type="button"
              className={cx(styles.icon, styles.menuItem)}
              data-test="show-design"
              data-block-action="open-styles"
              data-block-id={blockId}
            >
              <ColorPalette />
            </button>
          </DirectionalTooltip>
        </li>
      )}
      {canSaveCustomBlock && (
        <li>
          <SaveBlockControl saveCustomBlock={saveCustomBlock} />
        </li>
      )}
      <li>
        <DeleteControl
          deleteBlock={deleteBlock}
          setIsDeleting={setIsDeleting}
          isDeleting={isDeleting}
          selectBlock={selectBlock}
        />
      </li>
    </ul>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
export const useControls = (controlProps: {
  modalField?: FieldDefinition;
  fieldWithoutControls?: FieldDefinition;
}): {
  Controls: React.FC<ControlsProps>;
  isDeleting: boolean;
} => {
  const { modalField, fieldWithoutControls } = controlProps;
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { publisherMode } = React.useContext(BlocksEditorContext);
  const isPublisherModeStandard = publisherMode === PublisherMode.standard;

  const Component: React.FC<ControlsProps> = React.useCallback(
    (props) =>
      isPublisherModeStandard ? (
        <Controls
          isDeleting={isDeleting}
          modalField={modalField}
          setIsDeleting={setIsDeleting}
          fieldWithoutControls={fieldWithoutControls}
          {...props}
        />
      ) : null,
    [fieldWithoutControls, isDeleting, isPublisherModeStandard, modalField]
  );

  return { Controls: Component, isDeleting };
};
