import React, { createContext, useContext } from 'react';

type FeedbackContextData = {
  feature: string;
  task_id: string;
  requestFeedback: (feature: string, task_id: string) => void;
  resetFeedback: () => void;
};

const FeedbackPrototype: FeedbackContextData = {
  feature: '',
  task_id: '',
  requestFeedback: () => {},
  resetFeedback: () => {},
};

export const FeedbackContext = createContext(FeedbackPrototype);

export const useFeedback = (): FeedbackContextData => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error(
      'Feedback context hooks require a containing FeedbackContext'
    );
  }
  return context;
};

export const FeedbackProviderInMemory: React.FC = ({ children }) => {
  const [feature, setFeature] = React.useState('');
  const [task_id, setTaskId] = React.useState('');

  const resetFeedback = React.useCallback(() => {
    setFeature('');
    setTaskId('');
  }, []);

  const requestFeedback = React.useCallback((f: string, t: string) => {
    // eslint-disable-next-line no-console
    console.log('requestFeedback', f, t);
    setFeature(f);
    setTaskId(t);
  }, []);

  const value = {
    feature,
    task_id,
    requestFeedback,
    resetFeedback,
  };
  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
};
