import React from 'react';
import { usePublisher } from 'contexts/publisher';
import { useReadTime } from 'hooks/content/useReadTime';
import { ContextStats } from './ContentStats';
import { ActionButtons } from './ActionButtons';
import { CallToActionPreview } from './CallToActionPreview';
import { SectionCard } from '../SectionCard';

export const ContentPreviewSection: React.FC = () => {
  const { post } = usePublisher();
  const { readTimeInSeconds: fallbackReadTimeInSeconds } = useReadTime(post);
  const {
    content: { readTimeInSeconds, wordCount },
    settings,
    callToAction,
  } = post;

  return (
    <SectionCard padding={[16, 15]}>
      <>
        <CallToActionPreview
          callToAction={callToAction}
          contentAuthor={settings.contentAuthor}
        />
        <ContextStats
          readTimeInSeconds={readTimeInSeconds || fallbackReadTimeInSeconds}
          wordCount={wordCount}
        />
        <ActionButtons />
      </>
    </SectionCard>
  );
};
