import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { User } from 'models/user';
import { Program } from 'models/program';
import { userToRole } from 'App/Program/Main/People/mappings';

export function init(): void {
  if (
    process.env.REACT_APP_DATADOG_APP_ID &&
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
    process.env.REACT_APP_DATADOG_ENVIRONMENT &&
    process.env.REACT_APP_DATADOG_SITE
  )
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_SITE,
      env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
      trackInteractions: true,
      trackFrustrations: true,
      service: 'kai',
      allowedTracingOrigins: [
        process.env.REACT_APP_BOSSANOVA_DOMAIN as string,
        process.env.REACT_APP_IAM_DOMAIN as string,
      ],
    });
}

export function useSetUser(user?: User, program?: Program): void {
  const { id: program_id } = program || { id: null };
  React.useEffect(() => {
    if (!user || !program_id) return;
    datadogRum.setUser({
      program_id,
      id: `${user.id}`,
      role: userToRole(user),
      name: user.displayName ?? user.email,
      email: user.email,
    });
  }, [user, program_id]);
}

export function removeUser(): void {
  datadogRum.removeUser();
}

export function logError(
  error: Error,
  context?: Record<string, unknown>
): void {
  datadogRum.addError(error, context);
  // eslint-disable-next-line no-console
  console.error({ error });
}

// Provide some helpers for QA / Debugging
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.qa = {
  getSession() {
    return datadogRum.getInternalContext()?.session_id;
  },

  label(key: string, value: unknown) {
    return datadogRum.addRumGlobalContext(key, value);
  },

  labels() {
    return datadogRum.getRumGlobalContext();
  },

  record() {
    datadogRum.startSessionReplayRecording();
  },

  stop() {
    datadogRum.stopSessionReplayRecording();
  },
};
