import FroalaEditor from 'froala-editor';
import styles from './horizontal-rule.module.css';

export const useFroalaHorizontalRulePlugin: () => void = () => {
  FroalaEditor.DefineIcon('horizontalRule', {
    template: 'svg',
    PATH: 'M5,12h14 M19,11H5v2h14V11z',
  });

  Object.assign(FroalaEditor.POPUP_TEMPLATES, {
    'horizontalRule.popup': '[_HR_COLORS_][_CUSTOM_COLOR_]',
  });

  // eslint-disable-next-line func-names
  FroalaEditor.PLUGINS.horizontalRule = function (editor: FroalaEditor) {
    let selectedColor: string;

    function init() {
      selectedColor = '#7F7F7F';

      editor.events.on('toolbar.hide', hidePopup, true);

      // Adds color picker dropdown button
      editor.$tb
        .find('.fr-command[data-cmd="horizontalRule"]')
        .wrap('<div class="fr-btn-wrap"></div>')
        .after(
          `<button style="border-radius:0 4px 4px 0;" type="button" tabindex="-1" role="button" class="fr-command fr-btn fr-options ${styles.frOptions}" data-cmd="horizontalRuleOptions" data-title="Horizontal Line Color"></button>`
        );
    }

    // Initializes the popup with colors from text color option
    function initPopup() {
      // Uses 'colorsText' option to create color swatches.
      // The 'REMOVE' color is filtered out.
      const colors = editor.opts.colorsText.filter(
        (color) => color !== 'REMOVE'
      );

      const hr_colors = `<div class="fr-color-set fr-selected-set">${colors
        .map(
          (color, index) =>
            `${
              index !== 0 && index % editor.opts.colorsStep === 0 ? '<br>' : ''
            }<span class="fr-command fr-select-color" style="background:${color};" tabIndex="-1" aria-selected="false" role="button" data-cmd="applyHorizontalRuleColor" data-param1="${color}"><span class="fr-sr-only"> ${editor.language.translate(
              'Color'
            )}${color}&nbsp;&nbsp;&nbsp;</span></span>`
        )
        .join('')}</div>`;

      const custom_color = editor.opts.colorsHEXInput
        ? `<div class="fr-color-hex-layer fr-active fr-layer" id="fr-color-hex-layer-${
            editor.id
          }"><div class="fr-input-line"><input maxlength="7" id="fr-color-hex-layer-text-${
            editor.id
          }" type="text" placeholder="${editor.language.translate(
            'HEX Color'
          )}" tabIndex="1" aria-required="true" value="${selectedColor}"></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="customHorizontalRuleColor" tabIndex="2" role="button">${editor.language.translate(
            'OK'
          )}</button></div></div>`
        : '';

      editor.popups.onHide('horizontalRule.popup', () => {
        editor.$tb
          .find('.fr-command[data-cmd="horizontalRule"]')
          .removeClass('fr-expanded');

        editor.$tb
          .find('.fr-command[data-cmd="horizontalRuleOptions"]')
          .removeClass(`fr-selected ${styles.frSelected}`);
      });

      return editor.popups.create('horizontalRule.popup', {
        hr_colors,
        custom_color,
      });
    }

    // Updates the UI to indicate the selected color
    function setActiveColor() {
      const $popup = editor.popups.get('horizontalRule.popup');

      // Removes existing selected/active colors
      const $selectedColor = $popup.find(
        '.fr-horizontal-rule-color .fr-select-color'
      );
      $selectedColor.find('.fr-selected-color').remove();
      $selectedColor.removeClass('fr-active-item');
      $selectedColor.attr('aria-selected', 'false');

      // Sets the selected color active if found in the palette
      const $colorElement = $popup.find(
        `.fr-horizontal-rule-color .fr-select-color[data-param1="${selectedColor}"]`
      );
      $colorElement.append(
        '<span class="fr-selected-color" aria-hidden="true">\uf00c</span>'
      );
      $colorElement.addClass('fr-active-item').attr('aria-selected', 'true');

      // Sets the selected color on input
      const $input = $popup.find('.fr-color-hex-layer input');
      if ($input.length) {
        $input.val(selectedColor);
        $input.trigger('change');
      }
    }

    // Show the popup
    function showPopup() {
      // Get the popup object defined above.
      // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.
      const $popup = editor.popups.get('horizontalRule.popup') || initPopup();

      if (!$popup.hasClass('fr-active')) {
        setActiveColor();

        editor.$tb
          .find('.fr-command[data-cmd="horizontalRuleOptions"]')
          .addClass(`fr-selected ${styles.frSelected}`);

        // Set the editor toolbar as the popup's container.
        editor.popups.setContainer('horizontalRule.popup', editor.$tb);

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        const $btn = editor.$tb.find('.fr-command[data-cmd="horizontalRule"]');

        $btn.addClass('fr-expanded');

        // Compute the popup's position.
        const { left, top } = editor.button.getPosition($btn);

        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show(
          'horizontalRule.popup',
          left,
          top,
          $btn.outerHeight()
        );
      }
    }

    // Inserts an hr
    function insert() {
      editor.html.insert(
        `<hr style="background-color: ${selectedColor}; opacity: 0.2;" id="fr-just">`,
        true
      );
      const $hr = editor.$el.find('hr#fr-just').removeAttr('id');

      editor.selection.setAfter($hr.get(0) as Element);
      editor.selection.restore();
    }

    // Hide the popup
    function hidePopup() {
      editor.popups.hide('horizontalRule.popup');
    }

    // Toggles the visibility of the color picker
    function togglePopup() {
      if (editor.popups.isVisible('horizontalRule.popup')) {
        if (editor.$el.find('.fr-marker').length) {
          editor.events.disableBlur();
          editor.selection.restore();
        }
        hidePopup();
      } else {
        showPopup();
      }
    }

    // Sets the selected color and inserts an hr
    function applyColor(color: string) {
      selectedColor = color;
      editor.popups
        .get('horizontalRule.popup')
        .find('.fr-color-hex-layer input')
        .val(selectedColor);
      insert();
      hidePopup();
    }

    // Sets the custom color and inserts an hr
    function customColor() {
      const $input = editor.popups
        .get('horizontalRule.popup')
        .find('.fr-color-hex-layer input');
      if ($input.length) {
        applyColor($input.val() as string);
      }
    }

    return {
      _init: init,
      togglePopup,
      applyColor,
      customColor,
      insert,
    };
  };

  FroalaEditor.RegisterCommand('horizontalRule', {
    title: 'Insert Horizontal Line',
    icon: 'horizontalRule',
    undo: true,
    focus: true,
    plugin: 'horizontalRule',
    callback() {
      this.horizontalRule?.insert();
    },
  });

  FroalaEditor.RegisterCommand('horizontalRuleOptions', {
    undo: false,
    focus: true,
    popup: true,
    plugin: 'horizontalRule',
    callback() {
      this.horizontalRule?.togglePopup();
    },
  });

  FroalaEditor.RegisterCommand('applyHorizontalRuleColor', {
    undo: true,
    plugin: 'horizontalRule',
    callback(_name, color) {
      this.horizontalRule?.applyColor(color);
    },
  });

  FroalaEditor.RegisterCommand('customHorizontalRuleColor', {
    title: 'OK',
    undo: true,
    plugin: 'horizontalRule',
    callback() {
      this.horizontalRule?.customColor();
    },
  });
};
