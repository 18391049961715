import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SearchBar } from 'shared/SearchBar';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Subheading, Body } from 'DesignSystem/Typography';
import { InclusiveRule } from 'models/inclusive-language';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useInclusiveLanguageInfiniteQuery } from 'hooks/inclusive-language/useQueries';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { InclusiveRuleBanner } from './InclusiveRuleBanner';

export const StyleGuides: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const query = useDebounce(searchTerm);
  const infiniteQuery = useInclusiveLanguageInfiniteQuery({ programId, query });

  return (
    <ListPage
      title="Language Guide"
      description="Language guide rules are a way to provide your content creators with writing suggestions. Use rules to keep content on-brand, increase the use of inclusive language, and create more consistency in your organization’s writing."
      breadcrumbs={[
        { to: '..', label: 'Configure' },
        { label: 'Language Guide' },
      ]}
      actions={[
        {
          icon: <Icon name="Plus" fill="currentColor" />,
          label: 'Rule',
          to: 'new',
        },
      ]}
      filterbar={
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search Inclusive Rules"
        />
      }
      infiniteQuery={infiniteQuery}
      emptyList={
        <Subheading block bold>
          No rules match that criteria.
        </Subheading>
      }
      loading={<Body block>Loading...</Body>}
      renderRow={(rule: InclusiveRule, index: number) => (
        <InclusiveRuleBanner inclusiveRule={rule} index={index} />
      )}
    />
  );
};
