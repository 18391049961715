import * as React from 'react';
import { FieldInput, Textarea, Input } from 'DesignSystem/Form';
import { Image } from 'shared/Image';
import { FileDropZone } from 'shared/FileDropZone';
import { useToggle } from 'hooks/useToggle';
import { ImageData } from 'models/image';
import { useLinkFetcher } from 'hooks/useLinkFetcher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { ImageSelector } from './ImageSelector';
import { FieldLegend } from '../../shared/FieldLegend';
import { OpenInNewTabToggle } from '../../shared/OpenInNewTabToggle';
import { detectLanguageDirection } from '../../../../LangDetect';

type PropsType = {
  isUploading?: boolean;
  isCustomImage: boolean;
  setImage: (image: ImageData | File) => void;
  fetcher: ReturnType<typeof useLinkFetcher>;
};

export const FormBody: React.FC<PropsType> = ({
  isUploading,
  isCustomImage,
  setImage,
  fetcher,
}) => {
  const {
    setDescription,
    setAltText,
    setTitle,
    setCallToAction,
    setReadTime,
    setOpenInNewTab,
    imagesAvailability,
    link,
  } = fetcher;

  const isDragging = useToggle();
  const showOpenInNewTab = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.ShowOpenLinksInNewTab'
  ).data?.value;
  return (
    <>
      <FieldInput
        htmlFor="link-edit-title"
        legend={<FieldLegend>Title</FieldLegend>}
      >
        <Input
          dir={detectLanguageDirection(link.title.value)}
          data-test="link-edit-title"
          id="link-edit-title"
          value={link.title.value}
          onChange={setTitle}
          autoComplete="off"
          block
          disabled={fetcher.isLoading}
        />
      </FieldInput>
      <FieldInput
        htmlFor="link-edit-description"
        legend={<FieldLegend>Description</FieldLegend>}
      >
        <Textarea
          id="link-edit-description"
          value={link.description.value}
          onChange={setDescription}
          disabled={fetcher.isLoading}
        />
      </FieldInput>
      {showOpenInNewTab && (
        <OpenInNewTabToggle
          disabled={fetcher.isLoading}
          value={link.openInNewTab}
          onChange={(openInNewTab) => setOpenInNewTab(!!openInNewTab)}
        />
      )}
      <FieldInput
        htmlFor="link-edit-button"
        legend={<FieldLegend>Button</FieldLegend>}
      >
        <Input
          id="link-edit-button"
          value={link.callToAction?.value ?? 'Read more'}
          onChange={setCallToAction}
          maxLength={100}
          autoComplete="off"
          block
          disabled={fetcher.isLoading}
        />
      </FieldInput>
      <FieldInput
        htmlFor="link-edit-image"
        legend={<FieldLegend>Image</FieldLegend>}
      >
        <div style={{ position: 'relative' }}>
          <FileDropZone
            accept="image/*"
            onFileSelect={setImage}
            shouldClear={!link.image.url || !isCustomImage}
            onDragEnd={isDragging.disable}
            onDragStart={isDragging.enable}
            disabled={fetcher.isLoading || imagesAvailability.isLoading}
          >
            <div style={{ margin: ' -14px -20px' }}>
              <Image
                width="359px"
                height="237px"
                isUploading={isUploading || imagesAvailability.isLoading}
                url={link.image.url || link.images[0]?.url}
                placeholderIconName="ImagePlaceholder"
              />
            </div>
          </FileDropZone>
        </div>
        {!isDragging.value && !isCustomImage && link.images.length > 1 && (
          <ImageSelector
            current={link.image}
            setImage={setImage}
            images={link.images}
          />
        )}
      </FieldInput>
      <FieldInput
        htmlFor="link-edit-alt"
        legend={<FieldLegend>Alt Text</FieldLegend>}
      >
        <Input
          id="link-edit-alt"
          value={link.image?.isPlaceholder ? '' : link.image?.altText || ''}
          placeholder="Alt Text"
          onChange={setAltText}
          autoComplete="off"
          disabled={fetcher.isLoading}
          block
        />
      </FieldInput>
      <FieldInput
        htmlFor="link-edit-read-time"
        legend={<FieldLegend>Read time (in seconds)</FieldLegend>}
      >
        <Input
          id="link-edit-read-time"
          value={link.readTimeInSeconds?.toString()}
          onChange={setReadTime}
          autoComplete="off"
          block
          number
          disabled={fetcher.isLoading}
          maxLength={5}
        />
      </FieldInput>
    </>
  );
};
