import React from 'react';

import cx from 'classnames';
import { Tooltip } from 'DesignSystem/Components';
import styles from './InfoTooltip.module.css';

type InfoTooltipProps = {
  icon?: string;
  message: React.ReactNode;
  classNames?: string;
};
export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  icon = 'info',
  message,
  classNames,
}) => {
  return (
    <Tooltip content={message}>
      <span
        className={cx(
          'material-symbols-rounded',
          styles.infoTooltip,
          classNames
        )}
      >
        {icon}
      </span>
    </Tooltip>
  );
};
