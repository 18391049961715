import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { useContentReporters } from 'hooks/content/flags';
import { Content } from 'models/content';
import { ContentReporter } from 'models/content-reporter';
import { Avatar } from 'shared/Avatar';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './flagged-campaign-modal.module.css';
import { ContentPreview } from './ContentPreview';

type FlaggedCampaignInspectorProps = {
  content: Content;
  programId: number;
};

export function FlaggedCampaignInspector({
  content,
  programId,
}: FlaggedCampaignInspectorProps): React.ReactElement {
  const {
    data: contentReporters,
    isLoading: isLoadingReporters,
  } = useContentReporters({ programId, contentId: content.id });

  return (
    <div className={styles.container}>
      <div className={styles.reporterList}>
        {isLoadingReporters ? (
          <LoadingSpinner />
        ) : (
          <>
            <Text.Subheading semibold>
              Flags ({contentReporters?.length || 0})
            </Text.Subheading>
            {contentReporters?.map((reporter) => (
              <ReporterListItem reporter={reporter} key={reporter.advocateId} />
            ))}
          </>
        )}
      </div>
      <div className={styles.contentView}>
        <ContentPreview content={content} />
      </div>
    </div>
  );
}

type ReporterListItemProps = {
  reporter: ContentReporter;
};

function ReporterListItem({
  reporter,
}: ReporterListItemProps): React.ReactElement {
  return (
    <div className={styles.reporterListItem}>
      <Avatar
        width={40}
        height={40}
        src={reporter.avatarUrl}
        className={styles.reporterAvatar}
      />
      <span className={styles.reporterName}>{reporter.fullName}</span>
      <span className={styles.reporterTitle}>{reporter.jobTitle}</span>
    </div>
  );
}
