import React from 'react';
import styles from './measures.module.css';

type Measure = {
  id: number;
  name: string;
  percent: number | null;
  count: number;
};

export const measuresMock = [
  { id: 1, name: 'Audience', percent: null, count: 425 },
  { id: 2, name: 'Reach', percent: 40, count: 170 },
  { id: 3, name: 'Activity', percent: 10, count: 42 },
  { id: 4, name: 'Clicks', percent: 8, count: 34 },
  { id: 5, name: 'Acknowledgement', percent: 2, count: 9 },
];

export const Measures: React.FC<{ data: Array<Measure> }> = ({ data }) => {
  return (
    <>
      {data.map((record) => (
        <div key={record.id} className={styles.row}>
          <div className={styles.measure}>{record.name}</div>
          {record.percent ? (
            <div className={styles.percent}>{record.percent}%</div>
          ) : null}
          <div className={styles.count}>{record.count}</div>
        </div>
      ))}
    </>
  );
};
