import { Page } from 'services/common';
import { StudioContact } from 'models/author';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  prepareQueryString,
  request,
} from './api-shared';

// -- forked from ./api-authors.ts

type StudioContactData = {
  id: string; // it comes from studio as a string, let's not touch it to not break anything in studio
  attributes: {
    name: string | null;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    displayName: string | null;
    primaryEmail: string | null;
    avatarUrl: string | null;
    role: string | null;
  };
};

function studioContactNameForDisplay({ id, attributes }: StudioContactData) {
  if (attributes.name) {
    return attributes.name;
  }
  if (attributes.firstName || attributes.lastName) {
    return `${attributes.firstName} ${attributes.lastName}`;
  }
  if (attributes.preferredName) {
    return attributes.preferredName;
  }
  if (attributes.displayName) {
    return attributes.displayName;
  }
  if (attributes.primaryEmail) {
    return attributes.primaryEmail;
  }
  return `User ${id}`;
}

export function transformJsonToStudioContact(
  studioContact: StudioContactData
): StudioContact {
  return {
    defaultDisplayName: studioContactNameForDisplay(studioContact),
    userId: parseInt(studioContact.id, 10),
    displayName: studioContactNameForDisplay(studioContact),
    email: studioContact.attributes.primaryEmail ?? undefined,
    avatarUrl: studioContact.attributes.avatarUrl ?? undefined,
  };
}

type FetchParams = {
  programId: number;
  page?: number;
  query?: string;
  pageSize?: number;
};

export async function fetchStudioContactPage({
  programId,
  ...queryParams
}: FetchParams): Promise<Page<StudioContact>> {
  const queryString = prepareQueryString({ ...queryParams });
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/users/studio_contacts?${queryString}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data, meta, ...rest }) => ({
        data: data.map(transformJsonToStudioContact),
        meta: {
          currentPage: meta.pageNumber,
          totalRecords: meta.totalObjects,
          ...meta,
        },
        ...rest,
      }));
  }

  throw new Error(`Error fetching studio_contacts: ${response.status}`);
}
