import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Heading } from 'DesignSystem/Typography';
import { ImageUploader } from '../ImageUploader';
import { usePickerScreen } from './usePickerScreen';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { Items } from './Items';
import { Pagination } from './Pagination';
import styles from './picker-screen.module.css';

export const FullScreenPicker: React.FC<ReturnType<typeof usePickerScreen>> = ({
  addToAppendedItems,
  allItems,
  allowFiltering,
  canModifyCollection,
  canUploadImages,
  categories,
  collection,
  defaultCategoryLabel,
  handleDeleteItem,
  hasCustomCategories,
  isFilteringAllowed,
  label,
  libraryType,
  maxSelections,
  onCancel,
  onChange,
  refs,
  select,
  setSpinnersCount,
  showImageUploader,
  spinnersCount,
}) => {
  return (
    <div className={styles.wrapper} ref={refs.wrapperElement}>
      <Sidebar
        collection={collection}
        label={label}
        sidebarRef={refs.sidebarElement}
        onChange={onChange}
        onCancel={onCancel}
        categories={categories.data}
        invalidateQuery={categories.invalidateQuery}
        hasCustomCategories={hasCustomCategories}
        defaultCategoryLabel={defaultCategoryLabel}
        isFilteringAllowed={isFilteringAllowed}
      />
      <div className={styles.main} ref={refs.contentElement}>
        <Header
          collection={collection}
          label={label}
          maxSelections={maxSelections}
        />
        {canUploadImages && (
          <ImageUploader
            filterByCategory={collection.filterByCategory}
            setSpinnersCount={setSpinnersCount}
            length={allItems.length}
            filter={collection.filter}
            addToAppendedItems={addToAppendedItems}
            isButton
            allowFiltering={allowFiltering}
          />
        )}
        {!canUploadImages && collection.items.length === 0 && (
          <Flex center column className={styles.empty}>
            <Heading>No images yet</Heading>
          </Flex>
        )}
        {canUploadImages && (
          <ImageUploader
            filterByCategory={collection.filterByCategory}
            setSpinnersCount={setSpinnersCount}
            length={allItems.length}
            filter={collection.filter}
            addToAppendedItems={addToAppendedItems}
            allowFiltering={allowFiltering}
          >
            {!showImageUploader && (
              <Items
                handleDeleteItem={handleDeleteItem}
                spinnersCount={spinnersCount}
                filter={collection.filter}
                isLoading={collection.isLoading}
                isSelected={collection.isSelected}
                items={allItems}
                label={label}
                maxSelections={maxSelections}
                select={select}
                selectedCount={collection.selection.length}
                unselect={collection.unselect}
                libraryType={libraryType}
                canModifyCollection={canModifyCollection}
              />
            )}
            {collection.filter.type !== 'selected' &&
              !collection.isLoading &&
              collection.items.length > 0 && (
                <div className={styles.pagination}>
                  <Pagination
                    label={label}
                    pagination={collection.pagination}
                  />
                </div>
              )}
          </ImageUploader>
        )}
        {!canUploadImages && (
          <>
            {!showImageUploader && (
              <Items
                handleDeleteItem={handleDeleteItem}
                spinnersCount={spinnersCount}
                filter={collection.filter}
                isLoading={collection.isLoading}
                isSelected={collection.isSelected}
                items={allItems}
                label={label}
                maxSelections={maxSelections}
                select={select}
                selectedCount={collection.selection.length}
                unselect={collection.unselect}
                libraryType={libraryType}
                canModifyCollection={canModifyCollection}
              />
            )}
            {collection.filter.type !== 'selected' &&
              !collection.isLoading &&
              collection.items.length > 0 && (
                <div className={styles.pagination}>
                  <Pagination
                    label={label}
                    pagination={collection.pagination}
                  />
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};
