import React from 'react';
import {
  ContentTopicsAndAudiences,
  ContentTopicsAndAudiencesPropsType as ContentTopicsAndAudiencesPropType,
} from 'components/content/ContentBanner/ContentTopicsAndAudiences';
import { Text } from 'DesignSystem/Typography';

// this should probably just be ContentTopicsAndAudiences at this point. Just need to maybe clean up "NA" and make sure it works in all places its used.

export const ShortcutTopicsAndAudiencesDisplay = ({
  topicsAndAudiences,
}: ContentTopicsAndAudiencesPropType): JSX.Element => {
  const hasItems = topicsAndAudiences && topicsAndAudiences.length > 0;
  return (
    <>
      {hasItems && (
        <ContentTopicsAndAudiences
          align="left"
          topicsAndAudiences={topicsAndAudiences}
        />
      )}
      {!hasItems && <Text className={{ Body: true }}>None selected</Text>}
    </>
  );
};
