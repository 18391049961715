import React from 'react';
import { Button } from 'DesignSystem/Form';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { Shortcut } from 'models/shortcut';
import { useDeleteShortcut } from 'hooks/shortcuts';
import Modal from 'simple-react-modal';
import { useFlashMessage } from 'contexts/flasher';
import { Text } from 'DesignSystem/Typography';
import modalStyles from './modal.module.css';

type DeleteShortcutButtonProps = {
  id: Shortcut['id'];
  title: string;
  description?: string;
  body: string;
};
export const DeleteShortcutButton = ({
  id,
  title,
  description,
  body,
}: DeleteShortcutButtonProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { mutate } = useDeleteShortcut();
  const hideModal = () => setIsOpen(false);
  const { setFlashMessage } = useFlashMessage();
  const onMutationError = () => {
    setFlashMessage({
      message: 'Error deleting shortcut',
      severity: 'error',
    });
    hideModal();
  };
  const deleteShortcut = () =>
    mutate(id, {
      onError: onMutationError,
    });

  const modalContainerStyle = {
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: '0',
  };

  return (
    <>
      {isOpen && (
        <Modal
          style={{ padding: '0' }}
          show={isOpen}
          containerStyle={modalContainerStyle}
        >
          <section>
            <div className={modalStyles.banner}>
              <div className={modalStyles.bannerContent}>
                <Text
                  as="h1"
                  className={{ Heading: true }}
                  style={{ fontWeight: 600 }}
                >
                  {title}
                </Text>
                {description && (
                  <Text
                    as="p"
                    className={{ Text: true }}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {description}
                  </Text>
                )}
              </div>
            </div>
            <div className={modalStyles.body}>
              <Text as="p" className={{ Body: true }}>
                {body}
              </Text>
            </div>

            <div className={modalStyles.footer}>
              <div className={modalStyles.footerContent}>
                <div className={modalStyles.actions}>
                  <Button onClick={hideModal} label="Cancel" secondary />
                  <Button
                    icon={<Icon name="Delete" size={16} />}
                    onClick={deleteShortcut}
                    label="Delete"
                    warning
                  />
                </div>
              </div>
            </div>
          </section>
        </Modal>
      )}
      <Button
        icon={<Icon name="Delete" />}
        title="Delete"
        onClick={() => setIsOpen(true)}
        secondary
        compact
        borderless
      />
    </>
  );
};

DeleteShortcutButton.defaultProps = {
  description: undefined,
};
