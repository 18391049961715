import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosProgressEvent,
} from 'axios';

export type UploadProgress = { loaded: number; total: number };

export type UploadFileProps = {
  url: string;
  file: File;
  suggestedFileType?: string;
  onUploadProgress?: (progress: UploadProgress) => void;
};

export async function uploadToS3(props: UploadFileProps): Promise<void> {
  const { url, file, suggestedFileType, onUploadProgress } = props;

  const headers: Record<string, string> = {
    'Content-Type': suggestedFileType || 'multipart/form-data',
    'x-amz-acl': 'bucket-owner-full-control',
  };

  const config: AxiosRequestConfig = {
    headers,
    onUploadProgress: onUploadProgress
      ? createUploadProgressHandler(onUploadProgress)
      : undefined,
  };

  const response: AxiosResponse = await axios.put(url, file, config);

  if (response.status !== 200) {
    throw new Error(
      `An unexpected error occurred while uploading a file: ${response.status}`
    );
  }
}

function createUploadProgressHandler(
  onUploadProgress: (progress: UploadProgress) => void
) {
  return (progressEvent: AxiosProgressEvent) => {
    const progress: UploadProgress = {
      loaded: progressEvent.loaded,
      total: progressEvent.total || 0, // If total is undefined, set it to 0
    };
    onUploadProgress(progress);
  };
}
