import { OptionType } from 'hooks/common';

export const roles = [
  {
    label: 'Member',
    value: 'member',
    description:
      'Members can access the Member Experience for this community and do not have administrative privileges for Studio',
  },
  {
    label: 'Topic Manager',
    value: 'channel_contributor',
    description:
      'Topic Managers can view and edit any post that contains their topics for this program',
  },
  {
    label: 'Analyst',
    value: 'analyst',
    description:
      "Analysts can view and edit everything in the 'Measure' section for this program",
  },
  {
    label: 'Publisher',
    value: 'publisher',
    description:
      "Publishers can use everything in the 'Measure' and 'Publish' sections for this program",
  },
  {
    label: 'Community Manager',
    value: 'program_admin',
    description:
      'Community Managers can view and edit everything in Studio for this program',
  },
  {
    label: 'Administrator',
    value: 'administrator',
    description:
      'Administrators can view and edit everything in Studio for all programs in this organization',
  },
  {
    label: 'Super Admin',
    value: 'super_admin',
    description: 'Super Admins can view and edit everything in Studio',
  },
] as OptionType[];
