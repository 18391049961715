/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Select from 'react-select';
import { Body } from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { DelayStep, DelayStepOptions } from 'models/journeys/journey';
import styles from './delay-config.module.css';
import { selectStyles } from './select-styles';

export const ReadOnlyDelayConfig: React.FC<{ step: DelayStep }> = ({
  step,
}) => {
  const value = DelayStepOptions.find((option) => option.value === step.unit);
  const durationLabel =
    value?.label && step.quantity === 1
      ? value.label.slice(0, -1)
      : value?.label;
  return (
    <Box className={styles.wrapper}>
      <Body bold>Duration</Body>
      <Box margin={[8, 0, 0, 0]}>{`${step.quantity} ${durationLabel}`}</Box>
    </Box>
  );
};

export const DelayConfig: React.FC<{
  step: DelayStep;
  onUpdateStep: (step: DelayStep) => void;
}> = ({ step, onUpdateStep }) => {
  const inputId = 'quantity';
  const value = React.useMemo(() => {
    return DelayStepOptions.find((option) => option.value === step.unit);
  }, [step.unit]);
  const minDuration = 1;

  // using state here to allow an empty value in the input
  const [quantity, setQuantity] = React.useState<number | undefined>(
    step.quantity
  );

  const updateQuantity = React.useCallback(
    (val: number) => {
      onUpdateStep({
        ...step,
        quantity: val,
      });
    },
    [onUpdateStep, step]
  );

  const increaseQuantity = React.useCallback(() => {
    const newValue = step.quantity + 1;
    setQuantity(newValue);
    updateQuantity(newValue);
  }, [updateQuantity, step]);

  const decreaseQuantity = React.useCallback(() => {
    if (step.quantity > minDuration) {
      const newValue = step.quantity - 1;
      setQuantity(newValue);
      updateQuantity(newValue);
    }
  }, [updateQuantity, step]);

  React.useEffect(() => {
    setQuantity(step.quantity);
  }, [setQuantity, step]);

  const handleSetQuantity = React.useCallback(
    (event) => {
      const val = parseInt((event.target.value || '').replace(/\D/g, ''), 10);
      if (val && val >= minDuration) {
        setQuantity(Math.floor(val));
        updateQuantity(Math.floor(val));
      } else {
        const el = document.getElementById(inputId) as HTMLInputElement;
        if (el) {
          el.value = '';
        }
      }
      return true;
    },
    [updateQuantity]
  );

  const updateUnit = React.useCallback(
    (v) => {
      onUpdateStep({
        ...step,
        quantity: quantity || 1,
        unit: v.value,
      });
    },
    [onUpdateStep, step, quantity]
  );

  return (
    <Flex start column className={styles.wrapper}>
      <Body bold>Duration length</Body>
      <Flex>
        <Flex spread className={styles.quantity}>
          <Button
            onClick={decreaseQuantity}
            compact
            disabled={step.quantity <= minDuration}
            layoutOnly
            label="-"
          />
          <input
            type="text"
            id={inputId}
            onChange={handleSetQuantity}
            className={styles.input}
            value={quantity}
          />
          <Button onClick={increaseQuantity} compact layoutOnly label="+" />
        </Flex>
        <Flex className={styles.unit}>
          <Select
            styles={selectStyles}
            onChange={updateUnit}
            options={DelayStepOptions}
            value={value}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
