import * as React from 'react';
import cx from 'classnames';
import { Link, useLocation } from '@reach/router';
import styles from './layout.module.css';

export const Tabs: React.FC<{
  preserveSearch: boolean;
  links: Array<{
    label: string;
    to: string;
    disablePartial?: boolean;
  }>;
}> = ({ links, preserveSearch = false }) => {
  const { search } = useLocation();
  return (
    <ul className={styles.Tabs}>
      {links.map((link) => {
        const destination = preserveSearch ? link.to + search : link.to;
        return (
          <li key={link.to}>
            <Link
              to={`../${destination}`}
              getProps={({ isCurrent, isPartiallyCurrent, href, location }) => {
                const hrefWithoutSearch = href.split('?')[0];
                const isCurrentWithSearch =
                  location.pathname === hrefWithoutSearch;
                const isPartiallyCurrentWithSearch = location.pathname.startsWith(
                  hrefWithoutSearch
                );
                const isActive =
                  isCurrent || (isPartiallyCurrent && !link.disablePartial);
                const isActiveWithSearch =
                  isCurrentWithSearch ||
                  (isPartiallyCurrentWithSearch && !link.disablePartial);
                return {
                  className: cx(styles.TabButton, {
                    [styles.TabButtonActive]: preserveSearch
                      ? isActiveWithSearch
                      : isActive,
                  }),
                };
              }}
            >
              {link.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
