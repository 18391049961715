import React from 'react';
import FroalaEditor from 'froala-editor';
import { froalaEditor } from 'shared/instances/froalaEditor';

type PropTypes = {
  text?: string;
  onChange: (value: string) => void;
};

export const TextEditor: React.FC<PropTypes> = ({ text, onChange }) => {
  React.useEffect(() => {
    // eslint-disable-next-line no-new
    froalaEditor('div#froala-editor', {
      attribution: false,
      placeholderText: '',
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'insertLink',
        'textColor',
        'formatOLSimple',
        'formatUL',
      ],
      events: {
        contentChanged(this: FroalaEditor) {
          // eslint-disable-next-line react/no-this-in-sfc
          onChange(this.html.get());
        },
        initialized(this: FroalaEditor) {
          // eslint-disable-next-line react/no-this-in-sfc
          this.html.set(text || '');
        },
      },
    });
    // eslint-disable-next-line
  }, []);

  return <div id="froala-editor" />;
};
