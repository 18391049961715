import { useProgram } from 'contexts/program';
import { journeysKeys } from 'hooks/journeys/journeys';
import { usePollingQuery } from 'hooks/use-polling-query';
import { JourneyListItem } from 'models/journeys/journey';
import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import { fetchJourneys } from 'services/api-journey';
import { asserts } from 'utility/asserts';

type ProcessedJourneys = {
  publishedJourneys: JourneyListItem[];
  updatedJourneys: JourneyListItem[];
};

/**
 * Provides a hook for monitoring the processing state of Journeys using their IDs.
 *
 * This function implements dynamic polling based on the current state of journeys. It uses a refetch interval that adapts to the number of journeys still processing. Polling continues until all specified journeys are no longer in a processing state or when disabled.
 *
 * @param ids An array of journey IDs to monitor. Default is an empty array.
 * @param options Configuration object:
 *        - enabled: Flag to enable or disable polling. Default is true.
 *
 * @returns An array of Journeys that are in 'active' state after the polling cycle.
 */
export function usePollProcessingJourneys(
  ids: string[] = [],
  {
    enabled = true,
    invalidateQueries = true,
  }: {
    enabled?: boolean;
    invalidateQueries?: boolean;
  } = {}
): ProcessedJourneys {
  const queryClient = useQueryClient();
  const programId = useProgram().id;

  const prevIntervalMs = useRef(1000);
  const { data: pollingJourneys = [] } = usePollingQuery({
    queryFn: () => fetchJourneys({ programId }, { id: ids }),
    enabled: ids.length > 0 && enabled,
    queryKey: [...journeysKeys.details(), ids],
    cacheTime: 0,
    retry: false,
    select: (d) => d.data,
    refetchInterval: (latestData) => {
      const nothingFromServer = !latestData || latestData.length === 0;
      if (nothingFromServer || !enabled) {
        return false;
      }

      asserts(latestData.length > 0, 'There should be at least one journey');
      asserts(
        enabled === true,
        'Refetch evaluation should only be called when enabled'
      );
      const processingJourneys = latestData.filter(
        (journey) => journey.state === 'processing'
      );
      const processingCount = processingJourneys.length;
      const maybeProcessingCount = latestData.length;

      asserts(
        maybeProcessingCount >= processingCount,
        'The processing journeys should be a subset of the polled for journeys'
      );

      // Adjust the polling interval dynamically
      let newInterval = prevIntervalMs.current;
      if (processingCount === 0) {
        return false; // No processing journeys left, stop polling
      }
      if (processingCount === maybeProcessingCount) {
        newInterval += 1000; // No change detected, slow down polling
      } else if (processingCount < maybeProcessingCount / 2) {
        newInterval = 1500; // Significant change detected, speed up polling
      }
      prevIntervalMs.current = Math.min(newInterval, 10000); // Cap interval at 10s
      return newInterval;
    },
  });

  const publishedJourneys =
    pollingJourneys.filter(
      (journey) => journey.state === 'active' && journey.hasDraft === false
    ) ?? [];

  if (invalidateQueries && publishedJourneys.length > 0) {
    // Invalidate the page query to refetch the new list of journeys
    queryClient.invalidateQueries([...journeysKeys.page()]);
    // Invalidate the details query to refetch the new data, including the updated processingIds
    queryClient.invalidateQueries([...journeysKeys.details()]);
  }

  const updatedJourneys =
    pollingJourneys.filter(
      (journey) => journey.state === 'initial' || journey.state === 'active'
    ) ?? [];

  return {
    publishedJourneys,
    updatedJourneys,
  };
}
