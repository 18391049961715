import { OptionType } from 'hooks/common';

export const normalizeInRoles = (options: OptionType[] = []): OptionType[] => {
  if (!options) return [];

  return options.map((option: OptionType) => {
    if (option.value.includes('channel_contributor')) {
      return {
        ...option,
        value: option.value.replace('channel_contributor', 'topic_manager'),
      };
    }

    if (option.value.includes('program_manager')) {
      return {
        ...option,
        value: option.value.replace('program_manager', 'community_manager'),
      };
    }

    return option;
  });
};
