import { reassignStepEdge } from 'App/Program/Main/Journey/JourneyCanvas/utils/graph';
import {
  Journey,
  DEFAULT_EDGE_TARGET_ID,
  getDefaultStep,
  Step,
} from 'models/journeys/journey';
import type { JourneyActionFor } from './journey-reducer';
import { mustFindDraftStep } from './steps';

function insertStepBetween(
  state: Journey,
  step: Step,
  sourceId: string,
  targetId: string
): Step[] {
  const source = mustFindDraftStep(state, sourceId);
  const target = mustFindDraftStep(state, targetId);
  const currentGraph = state.draftGraph;
  if (!source || !currentGraph || !target) return []; // Invalid insert, source or target doesn't exist
  if (!source.next.some((edge) => edge.targetId === targetId)) return []; // Invalid insert, edge doesn't exist

  // create new step with edge pointing to target
  const newStep = reassignStepEdge(step, DEFAULT_EDGE_TARGET_ID, targetId);
  const updatedStep = reassignStepEdge(source, targetId, newStep.id);

  return [
    ...currentGraph.steps.map((s) =>
      s.id === updatedStep.id ? updatedStep : s
    ),
    newStep,
  ];
}

export function handleStepInserted(
  state: Journey,
  action: JourneyActionFor<'step/inserted'>
): Journey {
  const currentGraph = state.draftGraph;
  if (!currentGraph) return state;
  const { sourceId, targetId } = action;
  const [defaultStep, additionalSteps] = getDefaultStep(action.stepType);
  const newStep = insertStepBetween(state, defaultStep, sourceId, targetId);
  const newRootStepId =
    currentGraph.rootStepId === targetId
      ? defaultStep.id
      : currentGraph.rootStepId;

  const steps = [...newStep, ...additionalSteps];

  return {
    ...state,
    draftGraph: {
      ...currentGraph,
      steps,
      rootStepId: newRootStepId,
    },
  };
}
