import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { Trash } from 'shared/icons';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { useUniqueId } from 'hooks/useUniqueId';
// import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './confirm-delete.module.css';

export const ConfirmDelete: React.FC<{
  entity: string;
  onRemove: (event?: React.MouseEvent) => void;
  cancelRemove: (event?: React.MouseEvent) => void;
}> = ({ cancelRemove, onRemove, entity }) => {
  const id = useUniqueId();
  React.useEffect(() => {
    const div = document.getElementById(id);
    if (!div) return;
    const parent = div.parentElement;
    if (!parent) return;
    const { height } = parent.getBoundingClientRect();
    div.style.height = `${height}px`;
  }, [id]);

  return (
    <div id={id} className={styles.delete}>
      <strong>{`Confirm ${entity} Delete`}</strong>
      <div>
        <button type="button" onClick={onRemove} className={styles.confirm}>
          Remove <SVGIcon name="Trash" size={18} />
        </button>
        <button type="button" onClick={cancelRemove} className={styles.cancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

// A version of the one above, using design system components.
// Let's phase ^ that impl. out as we move to the new Editors

export const ConfirmDeleteV2: React.FC<{
  entity: string;
  onRemove: (event?: React.MouseEvent) => void;
  cancelRemove: (event?: React.MouseEvent) => void;
}> = ({ cancelRemove, onRemove }) => {
  const id = useUniqueId();
  React.useEffect(() => {
    const div = document.getElementById(id);
    if (!div) return;
    const parent = div.parentElement;
    if (!parent) return;
    const { height } = parent.getBoundingClientRect();
    div.style.height = `${height}px`;
    div.style.width = `100%`;
  }, [id]);

  return (
    <Box
      absolute
      id={id}
      style={{
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        layoutOnly
        label={
          <Text.Body bold color={Text.color.gray00}>
            cancel
          </Text.Body>
        }
        onClick={cancelRemove}
      />
      <Button
        compact
        secondary
        onClick={onRemove}
        icon={<Trash style={{ height: '18px', width: '18px' }} />}
        label="Confirm Remove"
      />
    </Box>
  );
};
