import React from 'react';
import { MAIcon } from 'shared/MAIcon';
import cx from 'classnames';
import styles from './styles.module.css';

export type WarningBannerProps = {
  name: string;
  actionButton: React.ReactNode;
};

export const WarningBanner: React.FC<React.PropsWithChildren<
  WarningBannerProps
>> = ({ name, children, actionButton }) => (
  <div
    key={name}
    role="alert"
    aria-label={name}
    className={cx(
      styles.HorizontallySpaced,
      styles.Gap16,
      styles.WarningBanner,
      styles.VerticallyCentered
    )}
  >
    <div
      className={cx(
        styles.HorizontallySpaced,
        styles.Gap16,
        styles.VerticallyCentered
      )}
    >
      <MAIcon name="warning" />
      <p className={styles.WarningBannerContent}>{children}</p>
    </div>
    {actionButton}
  </div>
);
