import React from 'react';
import { SignInConfig } from 'models/signInConfig';
import { Section, Subsection, Button } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Pills } from 'DesignSystem/Components/Pills';
import { Gray10PillClass } from 'DesignSystem/Components/Pill';
import { ConfirmModal } from 'DesignSystem/Components/Modal';
import styles from './styles.module.css';

export const FirstupSection: React.FC<{
  signInConfig: SignInConfig;
  setDefault: (value: string) => void;
}> = ({ signInConfig, setDefault }) => {
  const { id: programId } = useProgram();
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleConfirm = () => {
    setDefault('usernameOrEmail');
    setIsConfirmOpen(false);
  };

  const enabled =
    signInConfig.enabled.knownEmail ||
    signInConfig.enabled.otherEmail ||
    signInConfig.enabled.username;

  const defaultOpt =
    signInConfig.defaultSignInMethod === 'usernameOrEmail' ||
    (enabled && !signInConfig.enabled.sso);

  const title = (
    <div className={styles.Title}>
      <span>Firstup Sign-in and Registration</span>
      {enabled && defaultOpt && (
        <span className={styles.DefaultBill}>Default</span>
      )}
      {enabled && !defaultOpt && (
        <Button
          onClick={() => {
            setIsConfirmOpen(true);
          }}
          secondary
          className={styles.SetDefaultButton}
          label="Set as default"
        />
      )}
    </div>
  );

  const onIcon = <SVGIcon name="CheckCircleOutline" />;

  return (
    <Section
      title={title}
      description="Set up community standard community sign-in options. NOTE: Enabling or disabling Username or Email methods will only affect new registrations (existing users will still be able to sign-in with their chosen method)."
      href={enabled ? `/${programId}/configure/user-access/firstup` : undefined}
      className={styles.MainSection}
    >
      {!enabled && (
        <div className={styles.DisabledSection}>
          Firstup Sign-in method is currently disabled
          <Button
            label="Turn On Firstup Sign-in"
            href={`/${programId}/configure/user-access/firstup`}
          />
        </div>
      )}

      {enabled && (
        <>
          <Subsection title="">
            <Pills
              values={[{ id: 'firstupEnabled' }]}
              render={() => (
                <>
                  Firstup Sign-In Method:&nbsp;
                  <strong>ON</strong>
                </>
              )}
              pillIcon={() => onIcon}
              pillClassName={Gray10PillClass}
              small
              padding={[0, 8]}
            />
          </Subsection>
          <Subsection title="Access Settings" divider={false}>
            <div className={styles.AccessSettings}>
              {signInConfig.enabled.username && (
                <Pills
                  values={[{ id: 'username' }]}
                  render={() => (
                    <>
                      Username:&nbsp;
                      <strong>ON</strong>
                    </>
                  )}
                  pillIcon={() => onIcon}
                  pillClassName={Gray10PillClass}
                  small
                  padding={[0, 8]}
                />
              )}

              {signInConfig.enabled.knownEmail && (
                <Pills
                  values={[{ id: 'knownEmail' }]}
                  render={() => (
                    <>
                      Known Email Domains:&nbsp;
                      <strong>ON</strong>
                    </>
                  )}
                  pillIcon={() => onIcon}
                  pillClassName={Gray10PillClass}
                  small
                  padding={[0, 8]}
                />
              )}

              {signInConfig.enabled.otherEmail && (
                <Pills
                  values={[{ id: 'otherEmail' }]}
                  render={() => (
                    <>
                      Other Email Domains:&nbsp;
                      <strong>ON</strong>
                    </>
                  )}
                  pillIcon={() => onIcon}
                  pillClassName={Gray10PillClass}
                  small
                  padding={[0, 8]}
                />
              )}
            </div>
          </Subsection>
          <Subsection title="Email Domains">
            {signInConfig.domains.length === 0 && 'None'}
            <div className={styles.Domains}>
              {signInConfig.domains.map((domain) => (
                <Pills
                  key={domain}
                  values={[{ id: domain }]}
                  render={() => <>{domain}</>}
                  padding={[7, 8]}
                />
              ))}
            </div>
          </Subsection>
          <Subsection title="Login Page Label">
            {signInConfig.labels.local || 'Username/Email'}
          </Subsection>
          <Subsection title="Field Helper Text">
            {signInConfig.labels.localPlaceholder || 'Username/Email'}
          </Subsection>
        </>
      )}
      {isConfirmOpen && (
        <ConfirmModal
          title="Change default sign-in method"
          confirmLabel="Set as default"
          onConfirm={handleConfirm}
          onCancel={() => {
            setIsConfirmOpen(false);
          }}
        >
          This action will change the default sign-in method. The user will see
          this sign-in method first but the other sign-in method will still be
          available to tab into.
        </ConfirmModal>
      )}
    </Section>
  );
};
