import * as React from 'react';
import cx from 'classnames';
import { CircleGauge } from 'shared/statistics/CircleGauge';
import { Box } from 'DesignSystem/Components/Box';
import styles from './textarea.module.css';

type PropsType = {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  maximum?: number;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  className?: string;
  hasError?: boolean;
  showCharacterCount?: boolean;
} & Omit<JSX.IntrinsicElements['textarea'], 'value' | 'onChange' | 'onBlur'>;

export const Textarea: React.FC<PropsType> = ({
  value,
  onChange,
  onBlur,
  maximum,
  disabled,
  inputRef,
  className,
  hasError,
  showCharacterCount,
  ...passThrough
}) => {
  const onTextareaChange = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >((event) => onChange?.(event.target.value), [onChange]);

  const onTextareaBlur = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >((event) => onBlur?.(event.target.value), [onBlur]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={styles.container}>
      <textarea
        dir="auto"
        value={value ?? ''}
        onChange={onTextareaChange}
        onBlur={onTextareaBlur}
        maxLength={maximum}
        disabled={disabled}
        ref={inputRef}
        className={cx(styles.Textarea, className, { [styles.error]: hasError })}
        {...passThrough}
      />
      {maximum && (
        <div className={styles.count}>
          <CircleGauge
            radius={7}
            maximum={maximum}
            value={value?.length ?? 0}
          />
          <div
            className={styles.number}
            style={{ width: `${String(value?.length ?? 0).length + 1}ch` }}
          >
            {value?.length ?? 0}
          </div>
        </div>
      )}
      {showCharacterCount && (
        <Box className={styles.LetterCount}>
          {`${value?.length || 0} / ${passThrough.maxLength} characters`}
        </Box>
      )}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};
