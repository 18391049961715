import {
  createContext,
  createElement,
  useCallback,
  useContext,
  useState,
} from 'react';

const contextPrototype: {
  visible: boolean;
  toggle(): void;
  enable(): void;
  disable(): void;
} = {
  visible: false,
  toggle: () => {},
  enable: () => {},
  disable: () => {},
};

const CanvasFieldsPanelContext = createContext(contextPrototype);

export const CanvasFieldsPanelContextProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const disable = useCallback(() => setVisible(false), []);
  const enable = useCallback(() => setVisible(true), []);
  const toggle = useCallback(() => setVisible((val) => !val), []);
  return createElement(
    CanvasFieldsPanelContext.Provider,
    { value: { visible, toggle, enable, disable } },
    children
  );
};

export const useCanvasFieldsPanel = (): typeof contextPrototype => {
  const context = useContext(CanvasFieldsPanelContext);
  if (context === undefined) {
    throw new Error('Canvas Fields Panel Context hooks require a provider');
  }
  return context;
};
