import React from 'react';
import cx from 'classnames';
import { Link } from '@reach/router';
import { useProgramIdState } from 'contexts/program';
import { Fancy as PostPreview } from 'shared/publisher/PostPreview';
import { Template } from 'models/library';
import { Button } from 'DesignSystem/Form';
import { LoadingSpinner } from '../LoadingSpinner';
import styles from './TemplateSelector.module.css';
import { InfiniteList } from '../InfiniteList';
import { AutoHeight } from '../AutoHeight';

type TemplatesListType = {
  templates: Array<Template>;
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
  onSelect?: (template: Template) => void;
};

export const TemplatesList: React.FC<TemplatesListType> = ({
  templates,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  parentRef,
  onSelect,
}) => {
  const [programId] = useProgramIdState();

  const columnCount = document.body.offsetWidth <= 1750 ? 3 : 4;
  const rowCount = Math.ceil(templates.length / columnCount);
  const [columnWidth, setColumnWidth] = React.useState(0);

  const templatesRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleResize = () => {
      if (!templatesRef.current || isLoading) return;

      const templatesWidth = templatesRef.current.getBoundingClientRect().width;
      const widthFactor = columnCount === 3 ? 0.32 : 0.24;
      const totalPadding = 4;

      setColumnWidth(templatesWidth * widthFactor + totalPadding);
    };
    setImmediate(handleResize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [columnCount, isLoading]);

  const loadingComponent = React.useMemo(
    () => (
      <div className={styles.spinner}>
        <LoadingSpinner />
      </div>
    ),
    []
  );
  return (
    <div
      className={cx(styles.container, {
        [styles.empty]: templates.length === 0,
      })}
    >
      <div className={styles.templates} ref={templatesRef}>
        <AutoHeight>
          {(height) => (
            <InfiniteList
              itemCount={rowCount}
              itemHeight={296}
              height={height}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              loadingComponent={loadingComponent}
              parentRef={parentRef}
              columnCount={columnCount}
              columnWidth={columnWidth}
            >
              {(index) => {
                const template = templates[index];
                if (!template) return null;
                const preview = (
                  <PostPreview
                    title={template.title}
                    width={270}
                    height={292}
                    post={template.asset.template}
                  />
                );

                return (
                  <div style={{ margin: '2px' }}>
                    {onSelect ? (
                      <Button
                        layoutOnly
                        onClick={() => {
                          if (onSelect) onSelect(template);
                        }}
                        label={preview}
                        className={styles.previewButton}
                      />
                    ) : (
                      <Link
                        key={`template-preview-${template.title}`}
                        to={`/${programId}/edit/publisher?source=template&id=${template.id}`}
                        className={styles.preview}
                      >
                        {preview}
                      </Link>
                    )}
                  </div>
                );
              }}
            </InfiniteList>
          )}
        </AutoHeight>
      </div>
    </div>
  );
};
