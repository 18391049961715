import { DateTime } from 'luxon';

export const defaultDateRangeInputFormat = 'yyyy-MM-dd';

export const formatDateRangetoDB = (
  [start, end]: [string, string],
  inputFormat = defaultDateRangeInputFormat
): [string, string] => {
  const dbStart = DateTime.fromFormat(start, inputFormat)
    .startOf('day')
    .setZone('utc')
    .toSQL({ includeOffset: false });
  const dbEnd = DateTime.fromFormat(end, inputFormat)
    .endOf('day')
    .setZone('utc')
    .toSQL({ includeOffset: false });
  return [dbStart || '', dbEnd || ''];
};
