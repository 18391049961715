import { facetNames } from 'components/audience/FacetsSidebar/constants';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import {
  useTopicSubscribersAudience,
  useAudienceUsersQuery,
} from 'hooks/audience';
import { useStickyResponse } from 'hooks/common';
import { useAudienceFacetsQuery } from 'hooks/facet';
import { combinedAudiencesQuery } from 'models/audience';
import { Facet } from 'models/facet';
import { FetchUsersCountResponse } from 'services/api-audiences';
import { useTopicFollowersQuery } from 'hooks/topics';

export const useCampaignTarget = (): {
  audiences: {
    users?: FetchUsersCountResponse;
    isLoading: boolean;
    errorMessage?: string;
  };
  topics: {
    data?: FetchUsersCountResponse;
    isLoading: boolean;
    errorMessage?: string;
  };
  all: {
    data?: FetchUsersCountResponse;
    isLoading: boolean;
    errorMessage?: string;
  };
  facets: {
    data?: Facet[];
    isLoading: boolean;
    errorMessage?: string;
  };
} => {
  const { post } = usePublisher();
  const { audiences, contentTopics } = post.settings;
  const { id: programId } = useProgram();
  const topicIds = contentTopics.map((topic) => topic.id);
  const retryCount = 2;
  const audiencesQuery = combinedAudiencesQuery(audiences);
  const {
    isLoading: topicsLoading,
    errorMessage: topicsErrorMessage,
    data: topics,
  } = useStickyResponse(
    useTopicFollowersQuery(programId, topicIds, retryCount)
  );

  const {
    isLoading: usersLoading,
    errorMessage: audienceErrorMessage,
    data: audienceUsers,
  } = useStickyResponse(
    useAudienceUsersQuery(programId, audiencesQuery, { retry: retryCount })
  );

  const topicAudiences = useTopicSubscribersAudience(programId, contentTopics);
  const allAudiences = [...topicAudiences, ...audiences];
  const AllAudiencesQuery = combinedAudiencesQuery(allAudiences);

  const {
    isLoading: allAudiencesLoading,
    errorMessage: allErrorMessage,
    data: allUsers,
  } = useStickyResponse(
    useAudienceUsersQuery(programId, AllAudiencesQuery, { retry: retryCount })
  );

  const {
    isLoading: facetsLoading,
    errorMessage: facetsErrorMessage,
    data: facets,
  } = useStickyResponse(
    useAudienceFacetsQuery(programId, AllAudiencesQuery, facetNames)
  );

  return {
    audiences: {
      users: audienceUsers,
      isLoading: usersLoading,
      errorMessage: audienceErrorMessage,
    },
    topics: {
      data: topics,
      isLoading: topicsLoading,
      errorMessage: topicsErrorMessage,
    },
    all: {
      data: allUsers,
      isLoading: allAudiencesLoading,
      errorMessage: allErrorMessage,
    },
    facets: {
      data: facets,
      isLoading: facetsLoading,
      errorMessage: facetsErrorMessage,
    },
  };
};
