import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Link } from 'hooks/useFooter';
import { NavLink } from 'shared/NavLink';

export const SectionLinks: React.FC<{ links?: Link[] }> = ({ links }) =>
  !links || !links.length ? null : (
    <Flex>
      {links.map((link) => (
        <div key={link.href}>
          <NavLink disabled={link.disabled} to={link.href}>
            <Type.PageHeading block={false}>&bull;</Type.PageHeading>
            <Type.Subheading>{link.name}</Type.Subheading>
          </NavLink>
        </div>
      ))}
    </Flex>
  );
