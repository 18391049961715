import React from 'react';
import { isRedirect } from '@reach/router';
import { datadogRum } from '@datadog/browser-rum';

// This will catch any runtime errors and show an Error message
// instead of a blank screen. There is one instance wrapped around
// the root of the application, but it can also be used as-needed
// throughout the app.

const DefaultErrorComponent: React.FC = () => (
  <h1>There was an unexpected error. Please try again.</h1>
);

type ErrorBoundaryProps = {
  ErrorComponent?: typeof DefaultErrorComponent;
  onError?: () => void;
};
type ErrorBoundaryState = { hasError: boolean };

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  componentDidCatch(error: any, info: any): void {
    if (isRedirect(error)) throw error;
    const { onError } = { ...this.props };
    if (onError) {
      onError();
    }
    this.setState({ hasError: true });
    datadogRum.addError(error);
    // eslint-disable-next-line no-console
    console.error({ error, params: { info } });
  }

  render(): React.ReactNode {
    const { children, ErrorComponent } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      const Component = ErrorComponent || DefaultErrorComponent;
      return <Component />;
    }
    return children;
  }
}
