import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { FieldInput, Input, Toggle } from 'DesignSystem/Form';
import { FieldLegend } from '../../shared/FieldLegend';

export const LinkOptions: React.FC<{
  href?: string;
  openInNewTab?: boolean;
  onChangeHref(href?: string): void;
  onChangeOpenInNewTab(openInNewTab?: boolean): void;
}> = ({
  href = '',
  openInNewTab = false,
  onChangeHref,
  onChangeOpenInNewTab,
}) => {
  return (
    <>
      <FieldInput
        htmlFor="block-field-image-link"
        legend={<FieldLegend>Link</FieldLegend>}
      >
        <Input
          block
          value={href}
          onChange={onChangeHref}
          id="block-field-image-link"
          placeholder="https://example.com"
        />
        <Type.Caption block>
          Enter the url if you want this image to have a link.
        </Type.Caption>
      </FieldInput>

      <FieldInput
        htmlFor="block-field-image-open-tab"
        legend={<FieldLegend>Open Link In New Tab?</FieldLegend>}
      >
        <Toggle
          on={openInNewTab}
          onChange={onChangeOpenInNewTab}
          htmlForId="block-field-image-open-tab"
        />
      </FieldInput>
    </>
  );
};
