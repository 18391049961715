import * as React from 'react';
import { usePollAssetsQuery } from 'hooks/polls';
import { useProgram } from 'contexts/program';
import { AssetsTable, PollType } from 'models/poll';
import { PollFieldData } from 'models/publisher/block';
import { Field } from 'shared/Fieldset';
import { TextInput } from 'shared/TextInput';
import { Icon } from 'shared/Icon';
import styles from './poll-modern.module.css';

// Maintenance notes:
// - TODO Migrate from FA to SVGs

const MAX_ANSWERS = 5;

type Props = {
  poll: PollFieldData;
  updatePoll: (poll: Partial<PollFieldData>) => void;
  answersId: string;
};

const SatisfactionPreview: React.FC<{
  assets: AssetsTable['satisfaction'];
}> = ({ assets }) => (
  <>
    <img src={assets.very_dissatisfied?.url} alt="Very Dissatisfied" />
    <img src={assets.dissatisfied?.url} alt="Dissatisfied" />
    <img src={assets.neutral?.url} alt="Neutral" />
    <img src={assets.satisfied?.url} alt="Satisfied" />
    <img src={assets.very_satisfied?.url} alt="Very Satisfied" />
  </>
);

const StarsPreview: React.FC<{
  assets: AssetsTable['stars'];
}> = ({ assets }) => (
  <>
    <img src={assets.star_selected?.url} alt="1 Star" />
    <img src={assets.star_selected?.url} alt="2 Stars" />
    <img src={assets.star_selected?.url} alt="3 Stars" />
    <img src={assets.star_selected?.url} alt="4 Stars" />
    <img src={assets.star_unselected?.url} alt="5 Stars" />
  </>
);

const YesNoPreview: React.FC<{
  assets: AssetsTable['yes_no'];
}> = ({ assets }) => (
  <>
    <img src={assets.thumb_down?.url} alt="Thumb Down" />
    <img src={assets.thumb_up?.url} alt="Thumb Up" />
  </>
);

const AssetsPreview: React.FC<Props> = ({ poll }) => {
  const { id: programId } = useProgram();
  const assets = usePollAssetsQuery(programId);
  const preview = React.useMemo(() => {
    if (!assets.data) return null;
    if (poll.preset === PollType.satisfaction)
      return <SatisfactionPreview assets={assets.data.satisfaction} />;
    if (poll.preset === PollType.stars)
      return <StarsPreview assets={assets.data.stars} />;
    if (poll.preset === PollType.yes_no)
      return <YesNoPreview assets={assets.data.yes_no} />;
    return null;
  }, [assets.data, poll.preset]);
  return (
    <Field label="Answers Preview">
      <div className={styles.assets}>{preview}</div>
    </Field>
  );
};

const TextInputs: React.FC<Props> = ({ poll, updatePoll, answersId }) => (
  <Field
    labelFor={answersId}
    label={
      <div>
        <div>Answers</div>
        <div>
          {poll.options.length} of {MAX_ANSWERS}
        </div>
      </div>
    }
  >
    {poll.options.map((option, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={idx} className={styles.answer}>
        <TextInput
          id={idx === 0 ? answersId : undefined}
          value={option}
          placeholder={`Answer ${idx + 1}`}
          onChange={(value: string) => {
            updatePoll({
              options: poll.options.map((text, optIdx) => {
                if (optIdx === idx) return value;
                return text;
              }),
            });
          }}
        />
        <button
          type="button"
          onClick={() => {
            updatePoll({
              options: [
                ...poll.options.slice(0, idx),
                ...poll.options.slice(idx + 1),
              ],
            });
          }}
        >
          <Icon iconName="Times" size={12} iconType="SVG" />
        </button>
      </div>
    ))}
    {poll.options.length < MAX_ANSWERS && (
      <button
        type="button"
        className={styles.add}
        onClick={() => updatePoll({ options: [...poll.options, ''] })}
      >
        <Icon iconName="Plus" iconType="SVG" />
      </button>
    )}
  </Field>
);

/* eslint-disable react/jsx-props-no-spreading */
export const Answers: React.FC<Props> = ({ poll, ...props }) => {
  if (poll.preset === 'multiple' || poll.preset === 'single')
    return <TextInputs poll={poll} {...props} />;
  return <AssetsPreview poll={poll} {...props} />;
};
