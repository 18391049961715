import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { MAIcon } from 'shared/MAIcon';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { JourneyList } from '../JourneyList';
import { NewJourneyModal } from './NewJourneyModal';

export const JourneyListPage: React.FC<RouteComponentProps> = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);
  return (
    <>
      {isModalOpen && <NewJourneyModal onClose={closeModal} />}
      <ListPage
        title="Journeys"
        breadcrumbs={[{ label: 'Journeys' }]}
        preserveSearch
        tabs={[
          { id: 'current', label: 'Current' },
          { id: 'archive', label: 'Archived' },
        ].map(({ id, label }) => ({
          to: id,
          label,
        }))}
        actionsOverride={
          <Box margin={['auto', 0]}>
            <Button
              onClick={openModal}
              icon={<MAIcon name="add" />}
              label="Create Journey"
            />
          </Box>
        }
      >
        <JourneyList />
      </ListPage>
    </>
  );
};
