import { User } from 'models/user';
import { LitmusRole, Role } from 'models/role';
import {
  MenuItemsType,
  ThumbnailType,
} from 'shared/BannerListItem/EmptyBanner';
import { IconStatType } from 'shared/BannerListItem';
import { ItemType } from 'shared/IconMenu/IconMenuItem';
import { Program } from 'models/program';

const admin_permissions = [
  {
    name: 'member',
    presentableName: 'Member',
  },
  {
    name: 'analyst',
    presentableName: 'Analyst',
  },
  {
    name: 'publisher',
    presentableName: 'Publisher',
  },
  {
    name: 'program_admin',
    presentableName: 'Community Manager',
  },
  {
    name: 'administrator',
    presentableName: 'Administrator',
  },
  {
    name: 'channel_contributor',
    presentableName: 'Topic Manager',
  },
  {
    name: 'super_admin',
    presentableName: 'Super Admin',
  },
];

export function userToThumbnail(user: User): ThumbnailType {
  return {
    title: `${user.firstName?.substr(0, 1)}${user.lastName?.substr(
      0,
      1
    )}`.toUpperCase(),
    backgroundColor: '#5d6480',
    color: 'var(--color-gray00)',
    imageUrl: user.avatarUrl,
  };
}

export function userToName(user: User): string {
  const firstName = user.preferredName || user.firstName;
  const fullName =
    firstName && user.lastName ? `${firstName} ${user.lastName}` : '';
  return `${
    fullName || user.displayName || user.username || `User-${user.id}`
  }`;
}

export function userToDescription(user: User): string {
  const description = user.email || user.federatedIdentifier || user.employeeId;
  return `${description || ''}`;
}

export function userToRole(user: User): string | undefined {
  return admin_permissions.find((item) => item.name === user.role)
    ?.presentableName;
}

export function userToLitmusRole(
  user: User,
  litmusRoles: Array<LitmusRole>
): string {
  if (
    !user.roleNames ||
    user.roleNames.length === 0 ||
    user.roleNames.find((role) => role === 'member')
  )
    return 'Member';
  const roles = litmusRoles.filter((role) =>
    user.roleNames?.includes(role.name)
  );

  return roles.map((role) => role.title).join(', ');
}

export type SingleUserAction = (userId: number) => void;

export function userToMenuItems(
  permissionsEnabled: boolean,
  user: User,
  program: Program,
  programId: number,
  currentUserRole: Role,
  hideUserAccess: boolean,
  onInvite: (
    ids: string[] | undefined,
    excludedIds: string[] | undefined,
    sendStudioEmail: string,
    sendExperienceEmail: string,
    query?: string
  ) => void,
  onActivate: SingleUserAction,
  onDeactivate: SingleUserAction,
  onHideUser: SingleUserAction,
  onUnHideUser: SingleUserAction,
  onExportData: (ids: number[]) => void
): MenuItemsType {
  const actions: { [key: string]: ItemType } = {
    view: {
      title: 'View',
      href: `/${programId}/app/people/users/${user.id}/edit`,
    },
    activate_or_deactivate: {
      title: user.status === 'blocked' ? 'Activate User' : 'Deactivate User',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (user.status === 'blocked') onActivate(user.id);
        else onDeactivate(user.id);
      },
      href: '#',
    },
    hide_or_unhide: {
      title: user.hidden ? 'Unhide User' : 'Hide User',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (user.hidden) onUnHideUser(user.id);
        else onHideUser(user.id);
      },
      href: '#',
    },
    export: {
      title: 'Export Data',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        onExportData([user.id]);
      },
      href: '#',
    },
  };

  if (!program.emailTokenAuthEnabled) {
    actions.invite = {
      title: 'Invite User',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        onInvite([user.id.toString()], undefined, 'true', 'true');
      },
      href: '#',
    };
  }

  const actionsMap: { [key: string]: { [key in Role]: string[] } } = {
    super_admin: {
      member: Object.keys(actions),
      analyst: Object.keys(actions),
      channel_contributor: Object.keys(actions),
      publisher: Object.keys(actions),
      program_admin: Object.keys(actions),
      administrator: Object.keys(actions),
      super_admin: ['view', 'activate_or_deactivate', 'hide_or_unhide'],
    },
    administrator: {
      member: ['view', 'activate_or_deactivate', 'export'],
      analyst: ['view', 'activate_or_deactivate', 'export'],
      channel_contributor: ['view', 'activate_or_deactivate', 'export'],
      publisher: ['view', 'activate_or_deactivate', 'export'],
      program_admin: ['view', 'activate_or_deactivate', 'export'],
      administrator: ['view', 'activate_or_deactivate', 'export'],
      super_admin: [],
    },
    program_admin: {
      member: ['view', 'activate_or_deactivate', 'export'],
      analyst: ['view', 'activate_or_deactivate', 'export'],
      channel_contributor: ['view', 'activate_or_deactivate', 'export'],
      publisher: ['view', 'activate_or_deactivate', 'export'],
      program_admin: ['view', 'activate_or_deactivate', 'export'],
      administrator: [],
      super_admin: [],
    },
  };

  const items: ItemType[] = [];

  if (permissionsEnabled) {
    items.push(actions.view);
    if (!program.emailTokenAuthEnabled) items.push(actions.invite);
    if (hideUserAccess) items.push(actions.hide_or_unhide);
    items.push(actions.activate_or_deactivate);
    items.push(actions.export);

    return items;
  }

  if (user.role) {
    const allowedActions = actionsMap[currentUserRole][user.role];
    allowedActions.forEach((action) => items.push(actions[action]));
  }

  return items;
}

export function userToMetaStats(
  user: User,
  permissionsEnabled: boolean,
  litmusRoles?: Array<LitmusRole>
): Array<IconStatType> {
  const result: Array<IconStatType> = [];
  const roleName =
    permissionsEnabled && litmusRoles
      ? userToLitmusRole(user, litmusRoles)
      : userToRole(user);
  if (user.role)
    result.push({
      iconName: 'circle',
      iconType: 'FA',
      text: `${roleName}`,
    });
  if (user.department)
    result.push({
      iconName: 'circle',
      iconType: 'FA',
      text: user.department,
    });
  if (user.workLocation)
    result.push({
      iconName: 'circle',
      iconType: 'FA',
      text: user.workLocation,
    });
  if (user.jobTitle)
    result.push({
      iconName: 'circle',
      iconType: 'FA',
      text: user.jobTitle,
    });

  return result;
}

export function userToUsersCountStat(): IconStatType {
  return {
    iconName: 'users',
    iconType: 'FA',
    text: `20 posts, 198 likes`,
  };
}
