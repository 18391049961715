import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Program } from 'models/program';
import { useProgram } from 'contexts/program';
import { useProgramQuery, useUpdateProgram } from 'hooks/program';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';
import { WithPermission } from 'shared/WithPermission';
import { CommunitySettings } from './community';

export const Account: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const { isLoading, data: program } = useProgramQuery(programId);
  const [programData, setProgramData] = useState<Program>({} as Program);

  useEffect(() => {
    if (isLoading || !program) return;
    setProgramData(() => program);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, program?.name, program?.slug]);

  const { update } = useUpdateProgram(programId, {
    onSuccess: () =>
      setFlashMessage({
        message: 'Program updated successfully',
        severity: 'info',
      }),
    onError: (error) => {
      flashServerErrors(error, 'Could not update program');
    },
  });

  const onSave = () => {
    update(programData);
  };

  return (
    <WithPermission permissions={['configureAccountAccess']}>
      <FormPage
        breadcrumbs={[{ to: '..', label: 'Configure' }, { label: 'Account' }]}
        actions={[
          {
            label: 'Save',
            icon: <Icon name="SaveSuccess" />,
            onClick: onSave,
          },
        ]}
        title="Account"
      >
        <CommunitySettings program={programData} onChange={setProgramData} />
      </FormPage>
    </WithPermission>
  );
};
