import { DateRange, RelativeRange } from 'shared/DateRangeInput/DateRange';

export type SelectOption = {
  value: string;
  label: string;
};

export type BaseFilter<T extends keyof Filters> = {
  name: string;
  type: T;
  field: string;
  label: string;
  active: boolean;
};

export type DateFilter = BaseFilter<'date'> & {
  queryFields: string[];
  value?: DateRange;
};

export type TextFilter = BaseFilter<'text'> & {
  value?: string;
};

export type SelectFilter = BaseFilter<'select' | 'author'> & {
  options?: Array<SelectOption>;
  value: string[];
};

export type BooleanFilter = BaseFilter<'boolean'> & {
  value: boolean;
};

export type Filter = Filters[keyof Filters];

type Filters = {
  boolean: BooleanFilter;
  date: DateFilter;
  select: SelectFilter;
  text: TextFilter;
  author: SelectFilter;
};

export type ParameterizedFilters = {
  [Property in Filter['field']]: Filter['value'];
};

type FilterProcessors = {
  [Key in keyof Filters]: (filter: Filters[Key]) => ParameterizedFilters;
};

export const parameterizeFilters = (
  rawFilters: Filter[]
): ParameterizedFilters => {
  return rawFilters.reduce(
    (res, filter) => ({ ...res, ...parameterizeFilter(filter.type, filter) }),
    {}
  );
};

const parameterizeFilter = <T extends keyof Filters>(
  type: T,
  filter: Filters[T]
): ParameterizedFilters => {
  const processor = FILTER_PROCESSORS[type];
  return processor(filter);
};

const FILTER_PROCESSORS: FilterProcessors = {
  boolean: (filter) => {
    return { [filter.field]: filter.value };
  },
  date: (filter) => {
    const query: ParameterizedFilters = {};
    const sinceValue = filter.value?.toAbsolute().start.toFormat('yyyy-MM-dd');
    const beforeValue = filter.value?.toAbsolute().end.toFormat('yyyy-MM-dd');
    const [sinceField, beforeField] = filter.queryFields;
    if (sinceValue && sinceField) {
      query[sinceField] = sinceValue;
    }
    if (beforeValue && beforeField) {
      query[beforeField] = beforeValue;
    }
    return query;
  },
  select: (filter) => {
    return filter.value.length > 0 ? { [filter.field]: filter.value } : {};
  },
  text: (filter) => {
    return { [filter.field]: filter.value };
  },
  author: (filter) => {
    return filter.value.length > 0 ? { [filter.field]: filter.value } : {};
  },
};

export const filters: Filter[] = [
  {
    name: 'publicationState',
    type: 'select',
    field: 'editing_state',
    label: 'State',
    options: [
      { value: 'initial', label: 'Draft' },
      { value: 'active', label: 'Active' },
      { value: 'processing', label: 'Processing' },
      { value: 'paused', label: 'Paused' },
    ],
    value: [],
    active: true,
  },
  {
    name: 'author',
    type: 'author',
    field: 'created_by',
    label: 'Author',
    options: [{ value: 'initial', label: 'Initial' }],
    value: [],
    active: true,
  },
  {
    name: 'createdDate',
    type: 'date',
    label: 'Created Date',
    field: 'created_date',
    queryFields: ['created_since', 'created_before'],
    active: false,
    value: RelativeRange.build('past30days~'),
  },
];
