import * as React from 'react';
import { Label } from 'shared/Label';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Thumbnail } from 'shared/Thumbnail';
import contentStyles from '../content-summary.module.css';
import styles from './campaign-summary.module.css';

type CampaignSummaryType = {
  content: {
    header: string;
    summary: string;
  };
  settings: Array<{ name: string; description: string }>;
  rules: Array<{ name: string; description: string }>;
};

export const CampaignSummary: React.FC<CampaignSummaryType> = ({
  content,
  settings,
  rules,
}) => (
  <Section title="Campaign summary">
    <div className={styles.container}>
      <div className={styles.left}>
        <Row>
          <Block>
            <div className={styles.thumbnail}>
              <Thumbnail title="Mock" />
              <div className={styles.summary}>
                <div className={styles.header}>{content.header}</div>
                <div className={styles.text}>{content.summary}</div>
              </div>
            </div>
          </Block>
        </Row>
        <Row>
          <Block>
            {settings.map((setting) => (
              <Label
                key={setting.name}
                name={setting.name}
                containerClassName={styles.setting}
              >
                {setting.description}
              </Label>
            ))}
          </Block>
        </Row>
      </div>
      <div className={styles.right}>
        <Row className={contentStyles.fullHeight}>
          <Block>
            {rules.map((rule) => (
              <Label
                key={rule.name}
                name={rule.name}
                containerClassName={styles.rule}
              >
                {rule.description}
              </Label>
            ))}
          </Block>
        </Row>
      </div>
    </div>
  </Section>
);
