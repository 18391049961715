import React from 'react';
import cx from 'classnames';
import { Placeholder } from 'DesignSystem/Components/Placeholder';
import { Plus } from 'shared/icons';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import styles from '../video.module.css';

type CaptionFormProps = {
  onUploadClick: () => void;
  disabled?: boolean;
};

export const CaptionsDropzone: React.FC<CaptionFormProps> = ({
  onUploadClick,
  disabled,
}) => {
  return (
    <div className={cx({ [styles.clickable]: !disabled })}>
      <Placeholder onClick={() => !disabled && onUploadClick()}>
        <Text.Body
          block
          semibold
          color={disabled ? Text.color.gray50 : Text.color.gray90}
        >
          Add Captions File(s)
        </Text.Body>
        <Button circle compact icon={<Plus />} disabled={disabled} />
        <Text.Body block color={Text.color.gray50}>
          Use an existing subtitles file (eg. SRT, VTT).
        </Text.Body>
      </Placeholder>
    </div>
  );
};
