import React from 'react';
import styles from './progress_animation.module.css';

export const ProgressAnimation: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.slider} />
    </div>
  );
};
