import { useCallback } from 'react';
import { usePermissions } from 'contexts/permissions';
import { usePollDataMutator } from './usePollDataMutator';

export function usePollResults({
  poll,
  updatePoll,
}: ReturnType<typeof usePollDataMutator>): {
  resultsVisibility: boolean;
  toggleResultsVisibility(): void;
  canToggleResultsVisibility: boolean;
} {
  const { role } = usePermissions();
  const resultsVisibility = poll.show_results_to_user;
  const canToggleResultsVisibility = role !== 'channel_contributor';
  const toggleResultsVisibility = useCallback(() => {
    if (!canToggleResultsVisibility) return;
    updatePoll({ show_results_to_user: !resultsVisibility });
  }, [updatePoll, resultsVisibility, canToggleResultsVisibility]);
  return {
    resultsVisibility,
    toggleResultsVisibility,
    canToggleResultsVisibility,
  };
}
