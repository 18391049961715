import {
  fetchActivities,
  ActivitiesCollectionData,
  ActivityExportFilters,
  exportActivityCsv,
} from 'services/api-activities';
import { useProgram } from 'contexts/program';
import {
  useInfiniteQuery,
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
} from 'react-query';
import { Activity } from 'models/activity';
import { InfiniteQueryResponse } from './common';

export const useActivitiesInfiniteQuery = (
  props: {
    limit?: number;
    offset?: number;
    identity?: string[];
    start?: string;
    end?: string;
  } = { limit: 20 }
): InfiniteQueryResponse<Activity> => {
  const { limit, identity, ...rest } = props;
  const { id: programId } = useProgram();
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ActivitiesCollectionData, Error>(
    [`activities-infinite-${programId}`, JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchActivities({
        programId,
        limit,
        identity,
        offset: pageParam,
        ...rest,
      }),
    {
      getNextPageParam: (lastGroup) => {
        // if last group under limit, then we stop
        if (lastGroup.count < lastGroup.limit) {
          return undefined;
        }
        // audit service pagination follows sql OFFSET/LIMIT values
        // increment offset by limit to get the next page.
        return lastGroup.offset + lastGroup.limit;
      },
    }
  );
  const flatData =
    data && data.pages.map((batch) => (batch ? batch.items : [])).flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};

type UseActivityDownloadMutate = UseMutateFunction<
  Blob,
  Error,
  ActivityExportFilters
>;

export const useActivityDownload = (
  options?: UseMutationOptions<Blob, Error, ActivityExportFilters>
): {
  mutate: UseActivityDownloadMutate;
  isLoading: boolean;
  errorMessage?: string;
} => {
  const { id: programId } = useProgram();

  const { mutate, isLoading, error } = useMutation<
    Blob,
    Error,
    ActivityExportFilters
  >(exportActivityCsv(programId), options);

  return { mutate, isLoading, errorMessage: error?.message };
};
