import React, { useEffect, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';

import { Modal } from 'shared/Modal';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { InfiniteWrapper } from 'shared/InfiniteWrapper';
import { PageHeading } from 'DesignSystem/Typography';
import { useStickyNavBarContext } from 'contexts/sticky-navbar';
import { useAuditEventsInfiniteQuery } from 'hooks/auditEvents';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import {
  parseActivitiesResponse,
  ProcessedAuditEvent,
} from 'components/publisher/theme/CampaignHistoryModal/auditEvents';

import { AuditEventItem } from './AuditEventItem';
import styles from './campaign-history-modal.module.css';

const defaultParsedAuditEvents: Array<ProcessedAuditEvent> = [];

type CampaignHistoryModalProps = {
  onClose: () => void;
};
export const CampaignHistoryModal: React.FC<CampaignHistoryModalProps> = ({
  onClose,
}) => {
  const { id: programId } = useProgram();
  const {
    isLoading: isTopicsLoading,
    data: allTopics,
  } = useTopicsInfiniteQuery({
    programId,
  });
  const { id: publisherId } = usePublisher();
  const parentRef = React.useRef<HTMLDivElement>(null);
  const { handlePageScroll } = useStickyNavBarContext();

  useEffect(() => {
    handlePageScroll(parentRef);
  }, [handlePageScroll, parentRef]);

  const {
    isLoading: isAuditEventsLoading,
    data: auditEvents,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAuditEventsInfiniteQuery({
    recordId: publisherId as number,
    programId,
    kind: ['content', 'content_note'],
    includeSuperadminActivity: true,
  });

  const isLoading = isAuditEventsLoading || isTopicsLoading;

  const parsedAuditEvents = useMemo(
    () =>
      isLoading
        ? defaultParsedAuditEvents
        : parseActivitiesResponse({
            data: auditEvents,
            externalSources: allTopics,
          }),
    [allTopics, auditEvents, isLoading]
  );

  return (
    <Modal
      title=""
      showTitle={false}
      showModal
      onClose={onClose}
      modalStyle={{
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: '0',
        width: 'auto',
        display: '-webkit-box',
      }}
      modalBodyStyle={{
        padding: '20px 48px',
        width: '580px',
        alignSelf: 'self-start',
      }}
    >
      <form className={styles.form}>
        <PageHeading>Campaign history</PageHeading>
        <InfiniteWrapper
          parentRef={parentRef}
          style={{ height: 'calc(100vh - 155px)' }}
        >
          {(height: number) => (
            <InfiniteBannerList
              itemCount={parsedAuditEvents.length}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              noItemsComponent={<div>No audit events found.</div>}
              height={height}
              parentRef={parentRef}
            >
              {(index: number) => (
                <AuditEventItem
                  key={parsedAuditEvents[index].id}
                  auditEvent={parsedAuditEvents[index]}
                />
              )}
            </InfiniteBannerList>
          )}
        </InfiniteWrapper>
      </form>
    </Modal>
  );
};
