import camelcaseKeys from 'camelcase-keys';
import { LiquidVariable } from 'models/liquid-variable';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  query?: string;
  page?: number;
  pageSize?: number;
};

export type LiquidVariablesCollectionData = Array<LiquidVariable>;

export async function fetchLiquidVariables(
  programId: number,
  campaignId: number | 'new'
): Promise<LiquidVariablesCollectionData> {
  const url = `${apiRoot}/api/v1/programs/${programId}/liquid_variables?campaign_id=${campaignId}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => {
      return camelcaseKeys(json.data, { deep: true });
    });
  }
  throw new Error(`Error fetching liquid variables: ${response.status}`);
}
