import React from 'react';
import { Button } from 'DesignSystem/Form';
import { InfoI, Plus } from 'shared/icons';
import { defaultComplexExpression } from 'models/expression';
import { EndStep, DecisionEdge, DecisionStep } from 'models/journeys/journey';
import {
  useJourneyState,
  JOURNEY_ACTION_DISABLED_MESSAGE,
} from 'contexts/journeys/journey';
import { v4 as uuidv4 } from 'uuid';
import styles from './decision-config.module.css';
import { DecisionCondition } from './decision-config-condition';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';

const edgeOrderMap = (edge: DecisionEdge, index: number) => ({
  ...edge,
  order: index,
});

export const ReadOnlyDecisionConfig: React.FC<{ step: DecisionStep }> = ({
  step,
}) => {
  return (
    <div>
      <div className={styles.configInfo}>
        <InfoI width={16} height={16} />
        <span>
          Journeys looks for members eligible for a split in the order you have
          configured. If a member is included in the first split, they won’t be
          considered for the second.
        </span>
      </div>
      {step.next.map((edge, index) => (
        <DecisionCondition
          topologyEditable={false}
          canEdit={false}
          key={edge.targetId}
          condition={edge}
          isFirst={index === 0}
          isLast={index >= step.next.length - 2}
        />
      ))}
    </div>
  );
};

export const DecisionConfig: React.FC<{
  step: DecisionStep;
  onUpdateStep: (step: DecisionStep) => void;
}> = ({ step, onUpdateStep }) => {
  const {
    journey,
    updateDraftGraph,
    deleteDecisionOption,
    topologyEditable,
  } = useJourneyState();
  const { resolveErrors } = useJourneyValidationErrors();

  const setEdges = (next: DecisionEdge[]) => {
    onUpdateStep({ ...step, next });
  };

  // TODO: Duplicate code between this and journeys/journey.ts/getDefaultStep
  const addCondition = () => {
    const endStepId = uuidv4();

    const newStep: EndStep = {
      type: 'end',
      id: endStepId,
      next: [],
    };

    const updatedStep = {
      ...step,
      next: [
        ...step.next,
        {
          label: 'Split Description',
          order: step.next.length - 2,
          criterion: defaultComplexExpression(defaultComplexExpression()),
          targetId: endStepId,
        },
      ]
        .sort((a, b) => a.order - b.order)
        .map(edgeOrderMap),
    };

    if (journey?.draftGraph) {
      const updatedSteps = [
        ...journey.draftGraph.steps.map((s) =>
          s.id === updatedStep.id ? updatedStep : s
        ),
        newStep,
      ];
      updateDraftGraph({
        ...journey.draftGraph,
        steps: updatedSteps,
      });
    }
  };

  const edgesWithout = (edge: DecisionEdge) => {
    return step.next.filter((s) => s.targetId !== edge.targetId);
  };

  const removeEdge = (edge: DecisionEdge) => {
    deleteDecisionOption(step, edge);
  };

  const setEdgeIndex = (edge: DecisionEdge, order: number) => {
    const newOrder = Math.min(Math.max(order, 0), step.next.length - 2);
    const without = edgesWithout(edge);
    const newEdges = [
      ...without.slice(0, newOrder),
      { ...edge },
      ...without.slice(newOrder),
    ]
      .map(edgeOrderMap)
      .sort((a, b) => a.order - b.order);

    setEdges(newEdges);
  };

  const updateEdge = (edge: DecisionEdge) => {
    const index = step.next.findIndex((s) => s.targetId === edge.targetId);
    const newEdges = [
      ...step.next.slice(0, index),
      { ...edge },
      ...step.next.slice(index + 1),
    ];
    const unresolvedEdges = newEdges.filter(
      (e) => !e.criterion && !e.isDefault
    );
    if (unresolvedEdges.length === 0) {
      resolveErrors(step.id, ['criterion']);
    }
    setEdges(newEdges);
  };

  return (
    <div>
      <div className={styles.configInfo}>
        <InfoI width={16} height={16} />
        <span>
          Journeys looks for members eligible for a split in the order you have
          configured. If a member is included in the first split, they won’t be
          considered for the second.
        </span>
      </div>
      {step.next.map((edge, index) => (
        <DecisionCondition
          topologyEditable={topologyEditable}
          key={edge.targetId}
          condition={edge}
          isFirst={index === 0}
          isLast={index >= step.next.length - 2}
          onChange={updateEdge}
          onRemove={removeEdge}
          onReorder={setEdgeIndex}
        />
      ))}

      <Button
        onClick={addCondition}
        layoutOnly
        label="Add another split"
        icon={<Plus />}
        disabled={!topologyEditable}
        title={
          topologyEditable
            ? 'Add another split'
            : JOURNEY_ACTION_DISABLED_MESSAGE
        }
      />
    </div>
  );
};
