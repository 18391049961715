import * as React from 'react';
import cx from 'classnames';
import { navigate, RouteComponentProps } from '@reach/router';
import { useTemplate } from 'contexts/template';
import { Row, Section } from 'shared/SectionBlocks';
import { ImageData } from 'models/image';
import { Field } from 'shared/Fieldset';
import { TextInput } from 'shared/TextInput';
import { Textarea } from 'shared/forms/Textarea';
import { usePermissions } from 'contexts/permissions';
import { useProgramIdState } from 'contexts/program';
import { useImageUploader } from 'hooks/useImageUploader';
import { FileDropZone } from 'shared/FileDropZone';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { WithPermission } from 'shared/WithPermission';
import { TemplatePermissionsType } from 'models/template';
import { useOneOf } from 'hooks/useOneOf';
import { isGlobalItem } from 'models/library';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import styles from './templates-editor.module.css';
import { TemplateFooter } from '../TemplateFooter';

export const TemplateEditor: React.FC<RouteComponentProps> = () => {
  const { template, update } = useTemplate();
  const [programId] = useProgramIdState();
  const isPublishNewEditors = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  ).data?.value;
  const isPermissionServiceEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Service'
  ).data?.value;
  const permissionsTab = useOneOf({
    names: ['users', 'private', 'public'],
    initial: 'public',
  });

  if (isGlobalItem(template)) {
    navigate('/');
  }

  const { role, roleV2 } = usePermissions();
  const isPermissionsSectionVisible =
    role === 'super_admin' ||
    role === 'program_admin' ||
    role === 'administrator' ||
    (isPermissionServiceEnabled && roleV2.includes('brand_admin'));

  const { asset } = template;

  const permissions = React.useMemo(() => {
    return template.asset.template.permissions || [];
  }, [template.asset.template.permissions]);

  const updateIsRestricted = React.useCallback(
    (isRestricted: boolean) => {
      update({
        asset: {
          template: {
            ...asset.template,
            isRestricted,
          },
        },
      });
    },
    [asset.template, update]
  );

  React.useEffect(() => {
    if (isPermissionServiceEnabled) {
      if (asset.template.isRestricted) {
        permissionsTab.users.activate();
      }
    } else if (permissions && permissions.length > 0) {
      permissionsTab.private.activate();
    }
  }, [
    permissionsTab.private,
    permissions,
    isPermissionServiceEnabled,
    asset.template.isRestricted,
    permissionsTab.users,
  ]);

  const onUpload = React.useCallback(
    (data: ImageData) => {
      update({ thumbnail_images: [{ url: data.url }] });
    },
    [update]
  );

  const uploader = useImageUploader({ onUpload, programId });

  const onTitleChange = React.useCallback(
    (value: string) => {
      update({ title: value });
    },
    [update]
  );

  const onDescriptionChange = React.useCallback(
    (value: string) => {
      update({ description: value });
    },
    [update]
  );

  const handleFileSelect = React.useCallback(
    (newFile: File) => {
      uploader.update(newFile);
    },
    [uploader]
  );

  const setPublicPermissions = React.useCallback(() => {
    permissionsTab.public.activate();
    updateIsRestricted(false);
  }, [permissionsTab.public, updateIsRestricted]);

  const setPrivatePermissions = React.useCallback(() => {
    permissionsTab.private.activate();
    updateIsRestricted(false);
  }, [permissionsTab.private, updateIsRestricted]);

  const setUsersPermissions = React.useCallback(() => {
    permissionsTab.users.activate();
    updateIsRestricted(true);
  }, [permissionsTab.users, updateIsRestricted]);

  const updatePermissions = React.useCallback(
    (p: Array<string>) => {
      update({
        asset: {
          template: {
            ...asset.template,
            permissions: p as Array<TemplatePermissionsType>,
          },
        },
      });
    },
    [asset.template, update]
  );

  const thumbnail = template.thumbnail_images
    ? template.thumbnail_images[0]
    : undefined;

  const availablePermissions = React.useMemo(
    () =>
      [
        'Administrators',
        'Community Managers',
        'Publishers',
        'Topic Managers',
      ].filter((p) => !permissions.includes(p as TemplatePermissionsType)),
    [permissions]
  );

  const setSearch = React.useCallback(() => {}, []);

  const handleRemove = React.useCallback(
    (index: number) => {
      if (permissions) {
        const newPermissions = [...permissions];
        newPermissions.splice(index, 1);
        updatePermissions(newPermissions);
      }
    },
    [updatePermissions, permissions]
  );

  return (
    <WithPermission permissions={['manageTemplateAccess']}>
      <div
        className={cx(styles.container, {
          [styles.SplitBars]: isPublishNewEditors,
        })}
      >
        <aside>
          {!isPublishNewEditors && <h1 className="page-header">Template</h1>}
        </aside>
        <main className={styles.main}>
          <Section title="General" titleClassName={styles.sectionTitle}>
            <Row>
              <Field label="Name" className={styles.field}>
                <TextInput
                  className={styles.textInput}
                  onChange={onTitleChange}
                  value={template.title}
                  maximum={100}
                  dataTest="template-name-input"
                />
              </Field>
            </Row>
            <Row>
              <Field label="Description" className={styles.field}>
                <Textarea
                  className={styles.textarea}
                  maximum={200}
                  value={template.description}
                  onChange={onDescriptionChange}
                />
              </Field>
            </Row>
            <Row>
              <Field label="Image" className={styles.field}>
                <div className={styles.thumbnail}>
                  <div>
                    <FileDropZone
                      accept="image/*"
                      type="secondary"
                      onFileSelect={handleFileSelect}
                      iconName="Plus"
                      iconType="SVG"
                      aspectRatio={2}
                      compact
                      shouldClear
                    >
                      Upload image
                    </FileDropZone>
                    <div className={styles.uploaderTooltip}>
                      Supported formats: PNG, TIF, JPG, JPEG, HEIC, BMP, GIF.
                      Images should have a 2:1 aspect ratio.
                    </div>
                  </div>
                  {uploader.isUploading && (
                    <div>
                      <LoadingSpinner />
                    </div>
                  )}
                  {!uploader.isUploading && thumbnail && (
                    <div
                      className={styles.thumbnailPreview}
                      style={{ backgroundImage: `url(${thumbnail.url})` }}
                    />
                  )}
                </div>
              </Field>
            </Row>
          </Section>
          {isPermissionsSectionVisible && (
            <Section title="Permissions" titleClassName={styles.sectionTitle}>
              <Row>
                <Field
                  label="Permissions"
                  className={cx(styles.permissions, styles.field)}
                >
                  <div className={styles.buttonsContainer}>
                    {isPermissionServiceEnabled && (
                      <button
                        onClick={setUsersPermissions}
                        type="button"
                        className={cx(styles.permissionsOption, {
                          [styles.activePermission]:
                            permissionsTab.users.isActive,
                        })}
                      >
                        Specific Users
                      </button>
                    )}
                    {!isPermissionServiceEnabled && (
                      <button
                        onClick={setPrivatePermissions}
                        type="button"
                        className={cx(styles.permissionsOption, {
                          [styles.activePermission]:
                            permissionsTab.private.isActive,
                        })}
                      >
                        Specific Roles
                      </button>
                    )}
                    <button
                      onClick={setPublicPermissions}
                      type="button"
                      className={cx(styles.permissionsOption, {
                        [styles.activePermission]:
                          permissionsTab.public.isActive,
                      })}
                    >
                      Public
                    </button>
                  </div>
                </Field>
              </Row>
              {permissionsTab.users.isActive && (
                <Row>
                  <Field label="" className={cx(styles.field)}>
                    Assign this template to specific users from the Users page.
                    Find the user you want to assign it to and add it to the
                    selected templates in their profile.
                  </Field>
                </Row>
              )}
              {permissionsTab.private.isActive && (
                <Row>
                  <Field label="Enabled" className={cx(styles.field)}>
                    <div>
                      <MultiValueTextInput
                        textValue=""
                        onTextValueChange={setSearch}
                        selectedValues={permissions}
                        onRemoveSelectedValueAt={handleRemove}
                        callToAction="None"
                        onSelectedValuesChange={updatePermissions}
                      />
                      <div className={styles.permissionsOptions}>
                        {availablePermissions.map((p) => {
                          return (
                            <button
                              className={styles.permissionsButton}
                              type="button"
                              onClick={() => {
                                updatePermissions([...permissions, p]);
                              }}
                            >
                              {p}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </Field>
                </Row>
              )}
            </Section>
          )}
        </main>
        <div className={styles.sidebar} />
        <TemplateFooter />
      </div>
    </WithPermission>
  );
};
