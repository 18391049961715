export type BaseAuthor = {
  displayName: string;
  defaultDisplayName: string;
  avatarUrl?: string;
  authorAliasId?: number;
};

export type Author = BaseAuthor & {
  id?: number;
  userId: number;
  programId?: number;
  avatarColor?: string;
  email?: string;
  role?: string;
};

export type AuthorAlias = Author & {
  authorAliasId: number;
};

export type ProgramAuthor = BaseAuthor;

export type StudioContact = Author;

export const defaultAuthor: Author = {
  displayName: '',
  defaultDisplayName: '',
  userId: 0,
};

export function isAuthor(author: Author | unknown): author is Author {
  return (
    (author as Author)?.displayName !== undefined &&
    (author as Author)?.userId !== undefined
  );
}
export function isAuthorAlias(author: Author | unknown): author is AuthorAlias {
  return (
    (author as AuthorAlias).displayName !== undefined &&
    (author as AuthorAlias).authorAliasId !== undefined
  );
}
/* Ensure that we have an avatar_url and it is not pointing to a local resource */
export function hasAvatar(author: BaseAuthor): boolean {
  return !!author.avatarUrl && !author.avatarUrl.startsWith('/');
}

export function hasDefaultLocalAvatar(author: BaseAuthor): boolean {
  return !!author.avatarUrl && author.avatarUrl.includes('/default_avatar');
}
