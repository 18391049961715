import React from 'react';

/**
 * Returns an SVG string representing a sparkles icon.
 *
 * @param fill - Optional. The fill color of the sparkles. Defaults to 'var(--color-brandSolar)'.
 * @returns A string containing the SVG markup for the sparkles icon.
 */
export const sparklesSvg = (fill?: string): string => {
  return `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      
    >
      <g clipPath="url(#clip0_1_14816)">
        <path
          d="M10.1176 3.24562L11.9912 3.99462L12.7406 5.86709C12.8031 5.96071 12.8968 6.02313 12.9905 6.02313C13.0529 6.02313 13.1466 5.96071 13.209 5.86709L13.9897 3.99462L15.8321 3.24562C15.9258 3.18321 15.9883 3.08959 15.9883 2.99596C15.9883 2.93355 15.9258 2.83992 15.8321 2.77751L13.9897 1.99731L13.209 0.15604C13.1466 0.0624159 13.0529 0 12.9905 0C12.8968 0 12.8031 0.0624159 12.7406 0.15604L11.9912 1.99731L10.1176 2.77751C10.0239 2.83992 9.96143 2.93355 9.96143 2.99596C9.96143 3.08959 10.0239 3.18321 10.1176 3.24562ZM15.8321 12.764L13.9897 11.9838L13.209 10.1426C13.1466 10.049 13.0529 9.98654 12.9905 9.98654C12.8968 9.98654 12.8031 10.049 12.7406 10.1426L11.9912 11.9838L10.1176 12.764C10.0239 12.8265 9.96143 12.9201 9.96143 12.9825C9.96143 13.0761 10.0239 13.1697 10.1176 13.2322L11.9912 13.9812L12.7406 15.8536C12.8031 15.9473 12.8968 16.0097 12.9905 16.0097C13.0529 16.0097 13.1466 15.9473 13.209 15.8536L13.9897 13.9812L15.8321 13.2322C15.9258 13.1697 15.9883 13.0761 15.9883 12.9825C15.9883 12.9201 15.9258 12.8265 15.8321 12.764ZM11.9912 7.98923C11.96 7.83319 11.8663 7.61474 11.7101 7.55232L8.18148 5.77347L6.43276 2.27818C6.2454 1.93489 5.71454 1.93489 5.52718 2.27818L3.77846 5.77347L0.249794 7.55232C0.0936585 7.61474 -0.03125 7.83319 -0.03125 7.98923C-0.03125 8.17648 0.0936585 8.36373 0.249794 8.42614L3.77846 10.205L5.52718 13.7315C5.62086 13.8875 5.80822 13.9812 5.99558 13.9812C6.15172 13.9812 6.33908 13.8875 6.43276 13.7315L8.18148 10.205L11.7101 8.42614C11.8663 8.36373 11.9912 8.17648 11.9912 7.98923Z"
          style="fill: ${fill || 'var(--color-brandSolar)'} !important;" 
        />
      </g>
      <defs>
        <clipPath id="clip0_1_14816">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>`;
};

export function Sparkles(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  const { fill = '#1A1A1A', ...restProps } = props;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <g clipPath="url(#clip0_1_14816)">
        <path
          d="M10.1176 3.24562L11.9912 3.99462L12.7406 5.86709C12.8031 5.96071 12.8968 6.02313 12.9905 6.02313C13.0529 6.02313 13.1466 5.96071 13.209 5.86709L13.9897 3.99462L15.8321 3.24562C15.9258 3.18321 15.9883 3.08959 15.9883 2.99596C15.9883 2.93355 15.9258 2.83992 15.8321 2.77751L13.9897 1.99731L13.209 0.15604C13.1466 0.0624159 13.0529 0 12.9905 0C12.8968 0 12.8031 0.0624159 12.7406 0.15604L11.9912 1.99731L10.1176 2.77751C10.0239 2.83992 9.96143 2.93355 9.96143 2.99596C9.96143 3.08959 10.0239 3.18321 10.1176 3.24562ZM15.8321 12.764L13.9897 11.9838L13.209 10.1426C13.1466 10.049 13.0529 9.98654 12.9905 9.98654C12.8968 9.98654 12.8031 10.049 12.7406 10.1426L11.9912 11.9838L10.1176 12.764C10.0239 12.8265 9.96143 12.9201 9.96143 12.9825C9.96143 13.0761 10.0239 13.1697 10.1176 13.2322L11.9912 13.9812L12.7406 15.8536C12.8031 15.9473 12.8968 16.0097 12.9905 16.0097C13.0529 16.0097 13.1466 15.9473 13.209 15.8536L13.9897 13.9812L15.8321 13.2322C15.9258 13.1697 15.9883 13.0761 15.9883 12.9825C15.9883 12.9201 15.9258 12.8265 15.8321 12.764ZM11.9912 7.98923C11.96 7.83319 11.8663 7.61474 11.7101 7.55232L8.18148 5.77347L6.43276 2.27818C6.2454 1.93489 5.71454 1.93489 5.52718 2.27818L3.77846 5.77347L0.249794 7.55232C0.0936585 7.61474 -0.03125 7.83319 -0.03125 7.98923C-0.03125 8.17648 0.0936585 8.36373 0.249794 8.42614L3.77846 10.205L5.52718 13.7315C5.62086 13.8875 5.80822 13.9812 5.99558 13.9812C6.15172 13.9812 6.33908 13.8875 6.43276 13.7315L8.18148 10.205L11.7101 8.42614C11.8663 8.36373 11.9912 8.17648 11.9912 7.98923Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_14816">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
    /* eslint-enable react/jsx-props-no-spreading */
  );
}
