import React, { FC, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { TopicContext } from './context';
import TopicForm from './TopicForm';
import { useProgram } from '../../../../../contexts/program';
import { useEditTopicQuery } from '../../../../../hooks/topics';
import { useFlashServerErrors } from '../../../../../utility/errors';
import { LoadingScreen } from '../../../../../shared/LoadingScreen';

const TopicEdit: FC<RouteComponentProps<{ id: string }>> = ({ uri, id }) => {
  const { id: programId } = useProgram();
  const { data: topic, error } = useEditTopicQuery(programId, Number(id));
  const flashServerErrors = useFlashServerErrors();

  useEffect(() => {
    if (error) {
      flashServerErrors(error, 'Unable to edit topic');
      navigate(`/${programId}/configure/topics`);
    }
  }, [error, programId, flashServerErrors]);

  if (!topic) {
    return <LoadingScreen />;
  }

  return (
    <TopicContext.Provider value={topic}>
      <TopicForm topic={topic} baseUrl={uri || ''} />
    </TopicContext.Provider>
  );
};

export default TopicEdit;
