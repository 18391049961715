import React from 'react';
import {
  ReportForCollectionViewProps,
  ReportItemCard,
} from 'App/Program/Main/Insight/components/ReportsList/ReportItemCard';
import style from './ReportsListStyle.module.css';

// currently only `card` type is supported. Not sure when/if `list-*` will be supported
export type ReportsListViewType = 'card' | 'list-large' | 'list-small';
export type ReportsListPropsType = {
  reports: ReportForCollectionViewProps[];
  viewType?: ReportsListViewType;
  onClick?: (report: ReportForCollectionViewProps) => void;
};

export const ReportsList: React.FC<ReportsListPropsType> = ({
  reports,
  viewType = 'card',
  onClick,
}) => {
  return (
    <div className={style.flexWrapStartContainer}>
      {reports.map((report) => {
        return viewType === 'card' ? (
          <ReportItemCard
            report={report}
            key={report.bookmarkId || report.id}
            onClick={onClick}
          />
        ) : (
          <p>Nothing</p>
        );
      })}
    </div>
  );
};
