import React from 'react';
import { PageHeader, PageTemplate } from 'DesignSystem/Layout/Pages';
import layoutStyles from 'DesignSystem/Layout/layout.module.css';

export const ShortcutsPageInit: React.FC<{
  title: string;
  message?: string;
}> = ({ title, message }) => {
  return (
    <PageTemplate title={title}>
      <div className={layoutStyles.ListPage}>
        <PageHeader
          title={title}
          breadcrumbs={[{ to: '.. ', label: 'Configure' }, { label: title }]}
        />
      </div>
      {message && <>{message}</>}
    </PageTemplate>
  );
};
