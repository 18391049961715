import * as React from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from 'hooks/usePortal';
import styles from './layer.module.css';

// Regarding `jsx-a11y`, this is not an interactive component
// but it does try to manage the contained click events.
export const Layer: React.FC<{ name: string }> = ({ children, name }) => {
  const portal = usePortal(name);
  if (!portal.ready) return null;

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return createPortal(
    <div className={styles.layer} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>,
    portal.target
  );
};
