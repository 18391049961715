import React, { FC, useMemo } from 'react';
import {
  createHistory,
  createMemorySource,
  LocationProvider,
} from '@reach/router';
import {
  ContentFiltersProvider,
  defaultState as defaultFiltersState,
} from 'contexts/content/filters';
import { PostsFinderModal } from 'App/Program/Configuration/Topics/components/Form/PagesControl/Posts/PostsFinder/PostsFinderModal';
import { PinnableContent, Topic } from 'models/topic';
import { useTopicFormCtx } from '../../../context';

interface PinnedPostAddModalProps {
  topic: Partial<Topic>;
  onClose: () => void;
}

export const PinnedPostAddModal: FC<PinnedPostAddModalProps> = ({
  topic,
  onClose,
}) => {
  const topicFilter = useMemo(() => {
    const parentTopic = () => ({
      id: topic.parentId,
      // Required to pass the isTopic check for inclusion in the filters
      followerCount: 1,
    });

    return {
      // If the topic is a draft with a parent, use the parent topic
      // instead as it will have the content attached to it
      values: topic.draft && topic.parentId ? [parentTopic()] : [topic],
      isVisible: false,
    };
  }, [topic]);

  const filtersState = useMemo(
    () =>
      defaultFiltersState({
        standard: {
          topics: topicFilter,
        },
      }),
    [topicFilter]
  );

  const {
    state: {
      fields: { pinnedPosts },
    },
    handleChange,
  } = useTopicFormCtx();

  const onPinnedPostsChange = (newPinnedPosts: PinnableContent[]) => {
    handleChange('pinnedPosts', newPinnedPosts);
  };

  // Prevent browser URL rewriting from ContentFiltersProvider
  const modalHistory = createHistory(createMemorySource('/posts-finder'));
  return (
    <LocationProvider history={modalHistory}>
      <ContentFiltersProvider defaultFiltersState={filtersState}>
        <PostsFinderModal
          selected={pinnedPosts}
          onSubmit={(data) => {
            onPinnedPostsChange(data);
            onClose();
          }}
          onCancel={onClose}
        />
      </ContentFiltersProvider>
    </LocationProvider>
  );
};
