import React from 'react';
import { useProgramIdState } from 'contexts/program';
import { useAttachmentUploader } from 'hooks/useAttachmentUploader';
import { Fieldset, Field } from 'shared/Fieldset';
import {
  attachmentToField,
  AttachmentFieldData,
  attachmentFieldToData,
} from 'models/publisher/block';
import { TextInput } from 'shared/TextInput';
import { FileDropZone } from 'shared/FileDropZone';
import { AttachmentData } from 'models/attachment';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { ScanStatus } from './ScanStatus';
import styles from './upload.module.css';

const Error: React.FC<{ message: string }> = ({ message }) => (
  <div className={styles.error}>{message}</div>
);

type PropsType = {
  data?: AttachmentFieldData;
  onChange: (data: AttachmentFieldData) => void;
};

type InfoType = {
  type: string;
  size: number;
  name: string;
};

const Info: React.FC<InfoType> = ({ type, name, size }) => (
  <>
    <div>Type: {type}</div>
    <div>Size: {size}kb</div>
    <div>Name: {name}</div>
  </>
);

const onlyName = (fullFileName: string) =>
  fullFileName.substring(0, fullFileName.lastIndexOf('.'));

const extension = (fileName: string) =>
  fileName.substring(fileName.lastIndexOf('.'));

export const Upload: React.FC<PropsType> = ({
  data: existingData,
  onChange,
}) => {
  const [programId] = useProgramIdState();
  const [file, setFile] = React.useState<File>();
  const [attachmentData, setAttachmentData] = React.useState<
    AttachmentData | undefined
  >(attachmentFieldToData(programId, existingData));
  const [name, setName] = React.useState<string>(
    onlyName(attachmentData?.filename || '')
  );
  const [info, setInfo] = React.useState<InfoType | undefined>(
    attachmentData
      ? {
          name: attachmentData.filename,
          size: attachmentData.filesize,
          type: attachmentData.filetype.toUpperCase(),
        }
      : undefined
  );

  const setOnlyName = (newFileName: string) => setName(onlyName(newFileName));

  const fileWithName = React.useCallback(
    (fileToChange: File) => {
      const isNameUnchanged =
        !name || onlyName(name) === onlyName(info?.name || '');
      if (fileToChange && isNameUnchanged) return fileToChange;

      return new File(
        [fileToChange],
        `${name}${extension(fileToChange.name)}`,
        { type: fileToChange.type }
      );
    },
    [info, name]
  );

  const onUpload = React.useCallback(
    (data: AttachmentData) => {
      setAttachmentData(data);
      const attachment = attachmentToField(data);
      setOnlyName(attachment.name);
      onChange(attachment);
      setInfo({
        name: onlyName(attachment.name),
        size: attachment.size,
        type: attachment.extension.toUpperCase(),
      });
    },
    [onChange]
  );

  const uploader = useAttachmentUploader({
    onUpload,
    programId,
  });

  const upload = React.useCallback(
    (fileToUpload: File) => {
      const customizedFile = fileWithName(fileToUpload);
      setFile(fileToUpload);
      uploader.update(customizedFile);
    },
    [fileWithName, uploader]
  );

  const setNameFromInput = React.useCallback(
    (input: string) => {
      const oldName = attachmentData?.filename ?? '.';
      const newName = `${input}.${oldName.split('.').pop()}`;
      setName(newName);
      if (attachmentData) onUpload({ ...attachmentData, filename: newName });
    },
    [setName, onUpload, attachmentData]
  );

  return (
    <>
      <Fieldset className={styles.dropZone}>
        <Field label="File" className={styles.file}>
          <FileDropZone
            dropLabel="Drop to upload attachment"
            shouldClear
            onFileSelect={upload}
            type="secondary"
            iconName="Upload"
            iconType="SVG"
            compact
            accept={uploader.allowedExtensions
              .map((ext) => `.${ext}`)
              .join(',')}
          >
            Upload
          </FileDropZone>
          {(file || attachmentData) && (
            <span className={styles.filename}>
              {file?.name || attachmentData?.filename}
            </span>
          )}
        </Field>
        {uploader.error && <Error message={uploader.error} />}
      </Fieldset>
      <Fieldset>
        <Field label="Virus check" className={styles.virusCheckField}>
          {!uploader.isUploading && attachmentData && (
            <ScanStatus
              id={attachmentData.id}
              initialStatus={attachmentData.status}
            />
          )}
          {uploader.isUploading && <LoadingSpinner />}
        </Field>
        <Field label="Attachment">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {!uploader.isUploading && info && <Info {...info} />}
          {uploader.isUploading && <LoadingSpinner />}
        </Field>
        <Field label="Name">
          <TextInput
            value={name}
            onChange={setNameFromInput}
            placeholder="Optional"
            className={styles.name}
          />
        </Field>
      </Fieldset>
    </>
  );
};
