import { isAxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useProgram } from 'contexts/program';
import { BoxIntegrationData } from 'models/box-integration';
import { fetchById as fetchBoxMappingById } from 'services/api-box-mangement';
import { NotFoundError } from 'services/Errors/NotFoundError';

type UseCheckBoxFolderConflictResult = {
  check: () => Promise<void>;
  isLoading: boolean;
  errorMessage?: string;
};

export function useCheckBoxFolderConflict(
  boxFolder: BoxIntegrationData | undefined
): UseCheckBoxFolderConflictResult {
  const { id: programId } = useProgram();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const check = useCallback(async () => {
    if (!boxFolder) return;

    setErrorMessage(undefined);
    setIsLoading(true);

    try {
      const folderMapping = await fetchBoxMappingById(
        programId,
        Number(boxFolder.resource_id)
      );

      if (folderMapping) {
        setErrorMessage(
          'This Box folder has already been linked to a folder in Studio. Please select a different folder.'
        );
      }
    } catch (err) {
      if (
        !(
          err instanceof NotFoundError ||
          (isAxiosError(err) && err.response?.status === 404)
        )
      ) {
        setErrorMessage(
          'Unable to verify the Box folder mapping. Please try again.'
        );
        throw err;
      }
    } finally {
      setIsLoading(false);
    }
  }, [boxFolder, programId]);

  return { check, isLoading, errorMessage };
}
