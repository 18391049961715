import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Checkbox } from 'shared/Checkbox';
import cx from 'classnames';
import styles from '../styles.module.css';
import { EngagementFlag } from '../useEngagementToggles';

export const ToggleCheckbox: React.FC<{
  flag: EngagementFlag;
  onChange: () => void;
}> = ({ flag: { disabled, value, description }, onChange }) => (
  <Box
    margin={[0, 20]}
    padding={[0, 20, 20, 0]}
    style={{ display: 'flex', fontSize: 'var(--font-size-body)' }}
    className={cx({
      [styles.disabledCheckbox]: disabled,
    })}
  >
    <Checkbox
      checked={value}
      disabled={disabled}
      onChange={onChange}
      className={cx({
        [styles.disabledCheckbox]: disabled,
      })}
      label={description}
    />
  </Box>
);
