import * as React from 'react';
import cx from 'classnames';
import { Caption } from 'DesignSystem/Typography';
import { LooseAutocomplete } from 'utility/strings';
import styles from './pill.module.css';
import { Box } from '../Box';

interface PillProps<T> {
  value: T;
  render?: (value: T) => React.ReactNode;
  onClick?: (value: T) => void;
  onClose?: (value: T) => void;
  className?: string;
  singleLine?: boolean;
  icon?: React.ReactNode;
  padding?: number | number[];
  minHeight?: number;
  closeButtonContents?: string;
  largeCloseButton?: boolean;
  width?: LooseAutocomplete<'auto' | 'fit-content'>;
}

export function Pill<T>(
  props: React.PropsWithChildren<PillProps<T>>
): JSX.Element {
  const {
    onClick,
    onClose,
    render,
    value,
    className,
    singleLine,
    padding = [0, 16],
    icon,
    minHeight = 32,
    closeButtonContents = '⨉',
    largeCloseButton = false,
    width = 'auto',
  } = props;
  return (
    <Box
      relative
      padding={padding}
      minHeight={minHeight}
      className={cx(styles.Pill, {
        [styles.noAction]: !onClick,
        [styles.SingleLine]: singleLine,
        [`${className}`]: !!className,
      })}
      width={width}
      onClick={() => {
        if (onClick) onClick(value);
      }}
    >
      {icon && <span className={styles.Icon}>{icon}</span>}

      <Caption color="inherit">{render ? render(value) : value}</Caption>

      {onClose && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <span
          className={cx(styles.CloseButton, {
            [`${styles.LargeCloseButton}`]: largeCloseButton,
            [`${styles.RegularCloseButton}`]: !largeCloseButton,
          })}
          onClick={(event) => {
            event.stopPropagation();
            onClose(value);
          }}
        >
          {closeButtonContents}
        </span>
      )}
    </Box>
  );
}

export const { GreenTintDarkPillClass } = styles;
export const { Gray10PillClass } = styles;
export const { OrangeTintDarkPillClass } = styles;
export const { WhitePillClass } = styles;
