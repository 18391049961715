import { TopicShortcut } from 'models/topic';
import { ReactNode } from 'react';

export enum ModalType {
  AddPinnedPosts = 'addPinnedPosts',
  EditAboutPreview = 'editAboutPreview',
  EditShortcut = 'editShortcut',
  ErrorModal = 'errorModal',
  DeleteShortcut = 'deleteShortcut',
}

export interface NoModal {
  type: null;
}

export interface AddPinnedPostsModal {
  type: ModalType.AddPinnedPosts;
}

export interface EditAboutPreviewModal {
  type: ModalType.EditAboutPreview;
}

export interface EditShortcutModal {
  type: ModalType.EditShortcut;
  shortcut?: TopicShortcut;
}

export interface ErrorModal {
  type: ModalType.ErrorModal;
  content: ReactNode;
}

export interface DeleteShortcutModal {
  type: ModalType.DeleteShortcut;
  shortcut: TopicShortcut;
}

export type Modal =
  | NoModal
  | AddPinnedPostsModal
  | EditAboutPreviewModal
  | EditShortcutModal
  | ErrorModal
  | DeleteShortcutModal;

export type ToggleModal = (modal: Modal) => void;
