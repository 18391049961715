import React from 'react';
import { ColorField, FontField, SizeField } from 'models/publisher/style';
import { StyleOptions } from 'models/publisher/block';
import { PublisherStyleEditor } from 'hooks/publisher-style-editor';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import { Color } from './Color';
import { Size } from './Size';
import { Font } from './Font';

type EditorType = {
  name?: string;
  availableStyles?: StyleOptions;
  editor: PublisherStyleEditor;
  disabled?: boolean;
};

export const ColorPicker: React.FC<EditorType & { field: ColorField }> = ({
  availableStyles,
  field,
  name,
  editor,
  disabled,
}) =>
  !availableStyles || availableStyles.colors?.includes(field) ? (
    <div>
      <strong>{name || field}</strong>
      <Color
        onChange={editor.colorChanger(field)}
        value={editor.displayValue('colors', field)}
        disabled={disabled}
      />
    </div>
  ) : null;

export const SizeSlider: React.FC<
  EditorType & {
    field: SizeField;
    min?: number;
    max?: number;
    helperInfo?: { title?: string; description: React.ReactNode };
  }
> = ({
  availableStyles,
  field,
  name,
  min = 0,
  max = 50,
  editor,
  disabled,
  helperInfo,
}) =>
  !availableStyles || availableStyles.sizes?.includes(field) ? (
    <div>
      <div>
        <strong>{name || field}</strong>
        {helperInfo && (
          <HoverTooltip
            align="left"
            content={
              <Tooltip
                title={helperInfo.title}
                description={helperInfo.description}
              />
            }
          />
        )}
      </div>
      <Size
        value={editor.displayValue('sizes', field)}
        onChange={editor.sizeChanger(field)}
        min={min}
        max={max}
        disabled={disabled}
      />
    </div>
  ) : null;

export const FontPicker: React.FC<EditorType & { field: FontField }> = ({
  availableStyles,
  field,
  name,
  editor,
  disabled,
}) =>
  !availableStyles || availableStyles.fonts?.includes(field) ? (
    <div>
      <strong>{name || field}</strong>
      <Font
        value={editor.displayValue('fonts', field)}
        onChange={editor.fontChanger(field)}
        disabled={disabled}
        options={editor.fontOptions}
      />
    </div>
  ) : null;
