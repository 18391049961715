import * as React from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { ExternalSource } from 'models/external-source';
import { PaginationData } from 'services/common';
import { ExternalSourceActionFilters } from 'hooks/common';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { WithBulkSelect } from './components/WithBulkSelect';
import styles from './external-sources.module.css';
import { ExternalSourceBanner } from './ExternalSourcesBanner';

type PropsType = {
  externalSources: Array<ExternalSource>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  errorMessage?: string;
  parentRef: React.RefObject<HTMLDivElement>;
  meta?: PaginationData;
  filterConfig: ExternalSourceActionFilters;
};

export const FeedsList: React.FC<PropsType> = (props) => {
  const {
    isLoading,
    externalSources,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    parentRef,
    errorMessage,
    meta,
    filterConfig,
  } = props;

  const itemCount = externalSources.length || 0;

  function List(height: number) {
    return (
      <div>
        <InfiniteBannerList
          itemCount={itemCount}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          height={height}
          parentRef={parentRef}
          noItemsComponent={<div>No Feeds match that criteria.</div>}
        >
          {(index: number) => (
            <ExternalSourceBanner externalSource={externalSources[index]} />
          )}
        </InfiniteBannerList>
      </div>
    );
  }

  return (
    <InfiniteContainer parentRef={parentRef}>
      {(height: number) => (
        <>
          {isLoading && <div className={styles.loading}>Loading...</div>}
          {errorMessage && <>{errorMessage}</>}
          <WithBulkSelect
            totalRecords={meta?.totalRecords || 0}
            filterConfig={filterConfig}
          >
            {() => <>{List(height)}</>}
          </WithBulkSelect>
        </>
      )}
    </InfiniteContainer>
  );
};
