import React from 'react';
import { NodeProps } from 'reactflow';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import cx from 'classnames';
import { useJourneyStepMetrics } from 'hooks/journeys/journey-metrics';
import { useJourneyState } from 'contexts/journeys/journey';
import { EndStep, MetricsMode } from 'models/journeys/journey';
import { DrawerState } from '../../JourneyDrawer/drawer';
import { BaseNode, NodeContent } from './BaseNode';
import styles from './node.module.css';
import { EndIcon } from '../icons';
import { Metrics } from './Metrics';
import { Metric } from './Metrics/Metric';

export const EndNode = React.memo((props: NodeProps) => {
  const { id } = props;
  const {
    journey,
    currentGraph,
    metricsMode,
    getStep,
    setDrawerState,
  } = useJourneyState();
  const step = getStep('end', id);

  if (!step) {
    return <div />;
  }

  // Handle click event to close the drawer
  const handleClick = () => {
    setDrawerState(DrawerState.Closed);
  };

  return (
    <BaseNode
      key={id}
      hideMoveLeft
      hideMoveRight
      disableDelete
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <NodeContent>
        <Flex column start className={styles.nodeContainer}>
          <Flex start className={styles.bodyContainer}>
            <Box className={styles.icon}>
              <EndIcon />
            </Box>
            <Flex
              className={cx(styles.messageBody, styles.messageBodyCompact)}
              column
              start
            >
              <div>End Journey</div>
              <div>Last communication sent</div>
            </Flex>
          </Flex>
          {journey?.id && currentGraph?.id && currentGraph?.isLive && (
            <EndMetrics
              journeyId={journey.id}
              graphId={currentGraph.id}
              step={step}
              mode={metricsMode}
            />
          )}
        </Flex>
      </NodeContent>
    </BaseNode>
  );
});

const EndMetrics: React.FC<{
  journeyId: number;
  graphId: number;
  step: EndStep;
  mode: MetricsMode;
}> = ({ journeyId, graphId, step, mode }) => {
  const { data, isLoading } = useJourneyStepMetrics(
    journeyId,
    graphId,
    'end',
    step.id
  );
  return (
    <>
      {mode === MetricsMode.members && (
        <Metrics isLoading={isLoading}>
          {data && <Metric label="Completed" value={data.completed} />}
        </Metrics>
      )}
    </>
  );
};
