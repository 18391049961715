import React from 'react';

import { FilterTriggerButton } from 'DesignSystem/Table/FilterTriggerButton';
import {
  SelectFilter as SelectFilterType,
  SelectOption,
} from 'models/language-controls/filter';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';

type PropsType = {
  filter: SelectFilterType;
  onChange: (filter: SelectFilterType) => void;
  onRemove: (filter: SelectFilterType) => void;
};

export const SelectFilter: React.FC<PropsType> = ({
  filter,
  onChange,
  onRemove,
}) => {
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const optionsLabelMap: {
    [key: SelectOption['value']]: SelectOption['label'];
  } = {};
  const rowIds: SelectOption['value'][] = [];

  filter.options?.forEach((option) => {
    optionsLabelMap[option.value] = option.label;
    rowIds.push(option.value);
  });

  const rowRenderProp = (row: string) => (
    <div key={row}>{optionsLabelMap[row] ?? row}</div>
  );

  const onSelectedIdsChange = (ids: SelectOption['value'][]) =>
    onChange({ ...filter, value: ids });

  const dropdownRenderProp = (dismiss: () => void) => {
    onDismissRef.current = dismiss;
    return (
      <div className="filter-dropdown">
        <InfiniteSelect
          rowIds={rowIds}
          rowRenderProp={rowRenderProp}
          maxHeight={400}
          itemHeight={32}
          selectedIds={filter.value}
          onSelectedIdsChange={onSelectedIdsChange}
          dismissButton="Done"
          onDismissRef={onDismissRef}
        />
      </div>
    );
  };

  const selectedLabels = filter.value.map((value) => optionsLabelMap[value]);

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
      <FilterTriggerButton
        name={filter.label}
        values={selectedLabels}
        onClose={() => onRemove(filter)}
      />
    </ClickDropdown>
  );
};
