import * as React from 'react';
import { Box } from 'DesignSystem/Components';
import { border } from 'DesignSystem/Typography';

export const Placeholder: React.FC<{ onClick?: React.MouseEventHandler }> = ({
  children,
  onClick = () => {},
}) => (
  <Box
    center
    height={200}
    border={[border.gray10, border.dashed, border.width2, border.radius8]}
    onClick={onClick}
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
);
