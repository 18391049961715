import React from 'react';
import { AttachmentFieldData } from 'models/publisher/block';
import { FieldInput, Input } from 'DesignSystem/Form/InputElements';
import { FileUploadButton } from 'shared/FileUploadButton';
import { ProgressAnimation } from 'shared/ProgressAnimation';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { FieldFormProps } from '../../useFieldForm';
import { FieldLegend } from '../shared/FieldLegend';
import { useAttachmentDataMutator } from './hooks/useAttachmentDataMutator';
import { VirusScanStatus } from './components/VirusScanStatus';

const FileInfo: React.FC<{ type: string; size: number }> = (props) => {
  const { type, size } = props;
  return (
    <Flex column style={{ marginLeft: '5px', alignItems: 'flex-start' }}>
      <Text.Caption>Type: {type.toUpperCase()}</Text.Caption>
      <Text.Caption>Size: {size.toLocaleString()}kb</Text.Caption>
    </Flex>
  );
};

export const Attachment: React.FC<FieldFormProps<AttachmentFieldData>> = (
  props
) => {
  const {
    data: attachmentData,
    uploaderStatus,
    updateAttachmentFile,
    updateFileName,
  } = useAttachmentDataMutator(props);
  const [overrideFileName, setOverrideFileName] = React.useState<string>('');

  return (
    <>
      <FieldInput legend={<FieldLegend>File Upload</FieldLegend>}>
        {attachmentData && (
          <Flex start style={{ marginBottom: '8px' }}>
            <Text.Body>{attachmentData.filename}</Text.Body>
          </Flex>
        )}

        {uploaderStatus.isUploading && (
          <div style={{ marginBottom: '10px' }}>
            <ProgressAnimation />
          </div>
        )}
        <FileUploadButton
          shouldClear
          onFileSelect={updateAttachmentFile}
          accept={uploaderStatus.allowedExtensions
            .map((ext) => `.${ext}`)
            .join(',')}
          type="secondary"
          iconName="Upload"
          iconType="SVG"
          compact
        >
          {attachmentData ? 'Replace' : 'Upload'}
        </FileUploadButton>
        {uploaderStatus.error && (
          <Text.Body color={Text.color.redFull}>
            {uploaderStatus.error}
          </Text.Body>
        )}
      </FieldInput>
      {attachmentData && (
        <>
          <FieldInput legend={<FieldLegend>Virus Scan</FieldLegend>}>
            {!uploaderStatus.isUploading && attachmentData && (
              <VirusScanStatus
                id={attachmentData.id}
                initialStatus={attachmentData.status}
              />
            )}
            {uploaderStatus.isUploading && <ProgressAnimation />}
          </FieldInput>
          <FieldInput legend={<FieldLegend>File Info</FieldLegend>}>
            {!uploaderStatus.isUploading && attachmentData && (
              <FileInfo
                type={attachmentData.filetype}
                size={attachmentData.filesize}
              />
            )}
            {uploaderStatus.isUploading && <ProgressAnimation />}
          </FieldInput>
          <FieldInput
            legend={<FieldLegend>File Name Override</FieldLegend>}
            htmlFor="file-name"
          >
            <Input
              id="file-name"
              value={overrideFileName}
              onChange={(e) => {
                setOverrideFileName(e);
                updateFileName(e);
              }}
              placeholder="Optional"
            />
          </FieldInput>
        </>
      )}
    </>
  );
};
