import React from 'react';
import { pluralize } from 'utility/text';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { PaginationState } from 'hooks/common';
import { Content } from 'models/content';
import styles from 'shared/Library/PickerScreen/items.module.css';
import { UseCollection } from './useCollection';
import { ContentBanner } from './ContentBanner';

type ItemsProps = {
  isLoading: boolean;
  items: UseCollection['items'];
  label: string;
  maxSelections: number;
  pagination: PaginationState;
  select: UseCollection['select'];
  selection: UseCollection['selection'];
};

export const Items: React.FC<ItemsProps> = ({
  isLoading,
  items = [],
  label,
  pagination,
  select,
  selection,
}): React.ReactElement => {
  if (isLoading && !items.length) {
    return (
      <div className={styles.notReady}>
        Loading some {pluralize(label)} for you...
        <br />
        <br />
        <br />
        <LoadingSpinner />
      </div>
    );
  }

  if (!items.length) {
    return (
      <div className={styles.notReady}>
        No {pluralize(label)} found, try broadening your search.
      </div>
    );
  }

  return (
    <main className={styles.items}>
      <InfiniteBannerList
        isLoading={isLoading}
        itemCount={items.length}
        fetchNextPage={pagination.fetchNextPage}
        isFetchingNextPage={pagination.isFetchingNextPage}
        hasNextPage={pagination.hasNextPage}
      >
        {(index: number) => (
          <ContentBanner
            content={items[index] as Content}
            select={select}
            selection={selection}
          />
        )}
      </InfiniteBannerList>
    </main>
  );
};
