import * as React from 'react';
import { CustomSvg } from './CustomSvg';
import { definitions, SVGIconName } from './definitions';

type PropsType = {
  name: SVGIconName;
  className?: string;
  fill?: string;
  size?: number;
  title?: string;
};

export function isSvgIconName(name: string): name is SVGIconName {
  return Object.keys(definitions).includes(name);
}

// TODO:
// There was one one-of icon in linoleum:  "Featured".  At some point we need to bring it over and make
// it compatible with format of the definitions file.
export const SVGIcon: React.FC<PropsType> = ({ name, ...props }) => {
  const data = definitions[name];
  if (!data) {
    return null;
  }
  /* eslint-disable react/jsx-props-no-spreading */
  return <CustomSvg {...data} {...props} />;
};
