import React from 'react';
import { Video } from 'models/video';
import { useUniqueId } from 'hooks/useUniqueId';
import { Player } from './Player';
import { Meta } from './Meta';

type PropsType = {
  video: Video;
  className?: string;
};

export const VideoPlayer: React.FC<PropsType> = ({ video, className }) => {
  const id = useUniqueId();
  return (
    <div className={className}>
      {video && (
        <div className="flex-column">
          <Player video={video} id={id} />
          <Meta video={video} />
        </div>
      )}
    </div>
  );
};
