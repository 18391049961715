import {
  useInfiniteQuery,
  useQueryClient,
  useQuery,
  useQueries,
  UseQueryResult,
} from 'react-query';
import {
  archiveEmailAlias,
  EmailData,
  EmailsCollectionData,
  fetchEmailAliases,
  FetchProps,
  setEmailAliasAsDefault,
  fetchById,
  fetchDefaultEmailAlias,
  updateEmailAlias,
  unarchiveEmailAlias,
  createEmailAlias,
  bulkArchiveEmailAlias,
  bulkUnarchiveEmailAlias,
} from 'services/api-email-alias';
import { useProgram } from 'contexts/program';
import {
  InfiniteQueryResponse,
  nextPageToFetch,
  QueryResponse,
  MutationOptions,
  BulkSelection,
  EmailAliasBulkActionFilters,
} from './common';
import { EmailAlias } from '../models/email-alias';

function mapDataToEmailAlias(data: EmailData): EmailAlias {
  return { ...data.attributes, id: data.id };
}

export const useEmailAliasesInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<EmailAlias> => {
  const { programId, search, perPage = 100, status } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<EmailsCollectionData, Error>(
    ['email-aliases', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchEmailAliases({
        programId,
        search,
        page: pageParam as number,
        status,
        perPage,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, perPage),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) =>
        batch
          ? batch.data.map((emailData) => {
              return {
                ...emailData.attributes,
                id: emailData.id,
              };
            })
          : []
      )
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    meta: data?.pages[0].meta,
    data: flatData || [],
  };
};

export const useEmailAliasByIdQueries = (
  programId: number,
  ids: Array<string>
): Array<QueryResponse<EmailAlias>> => {
  const queries =
    ids.map((id) => {
      return {
        queryKey: [programId, `email-alias-${id}`],
        queryFn: () => fetchById(programId, id),
        options: { retry: false },
      };
    }) || [];

  const emails = useQueries(queries) as Array<UseQueryResult<EmailData>>;

  const result: Array<{ data?: EmailAlias; isLoading: boolean }> = [];

  emails.forEach((emailData) => {
    const { data, isLoading } = emailData;
    let email: EmailAlias | undefined;

    if (data) email = mapDataToEmailAlias(data);

    result.push({ data: email, isLoading });
  });

  return result;
};

export const useEmailAliasQuery = (
  programId: number,
  id: string
): QueryResponse<EmailAlias> => {
  const { isLoading, error, data } = useQuery({
    queryFn: () => fetchById(programId, id),
    cacheTime: 0,
    select: (d) => (d ? mapDataToEmailAlias(d) : undefined),
  });

  return {
    isLoading,
    errorMessage: error instanceof Error ? error.message : undefined,
    data,
  };
};

export const useSetEmailAliasAsDefault = (
  emailAlias: EmailAlias,
  onSuccess?: () => void
): { setAsDefault: () => void } => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();

  const setAsDefault = () => {
    const request = setEmailAliasAsDefault(programId, emailAlias.id);

    request.then(() => {
      queryClient.invalidateQueries(['email-aliases']);
      if (onSuccess) onSuccess();
    });
  };
  return {
    setAsDefault,
  };
};

export const useDefaultEmailAddress = (): QueryResponse<EmailAlias> => {
  const { id: programId } = useProgram();

  const { isLoading, error, data } = useQuery<EmailData, Error>({
    queryFn: () => fetchDefaultEmailAlias(programId),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: data ? { ...data.attributes, id: data.id } : undefined,
  };
};

export const useUpdateEmailAlias = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  update: (emailAlias: EmailAlias) => void;
} => {
  const { id: programId } = useProgram();
  const update = (emailAlias: EmailAlias) => {
    updateEmailAlias(programId, emailAlias)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useArchiveEmailAlias = ({
  onSuccess,
  onError,
}: MutationOptions<EmailAlias, Error> = {}): {
  archiveEmail: (emailAliasId: string) => void;
} => {
  const { id: programId } = useProgram();
  const archiveEmail = (emailAliasId: string) => {
    archiveEmailAlias(programId, emailAliasId)
      .then((data) => {
        if (onSuccess) onSuccess({ id: data.id, ...data.attributes });
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { archiveEmail };
};

export const useUnarchiveEmailAlias = ({
  onSuccess,
  onError,
}: MutationOptions<EmailAlias, Error> = {}): {
  unarchiveEmail: (emailAliasId: string) => void;
} => {
  const { id: programId } = useProgram();
  const unarchiveEmail = (emailAliasId: string) => {
    unarchiveEmailAlias(programId, emailAliasId)
      .then((data) => {
        if (onSuccess) onSuccess({ id: data.id, ...data.attributes });
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { unarchiveEmail };
};

export const useCreateEmailAlias = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  create: (emailAlias: Partial<EmailAlias>) => void;
} => {
  const { id: programId } = useProgram();
  const create = (emailAlias: Partial<EmailAlias>) => {
    createEmailAlias(programId, emailAlias)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { create };
};

export const useBulkArchiveEmailAlias = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  bulkArchive: (
    bulkSelection: BulkSelection,
    filterConfig: EmailAliasBulkActionFilters
  ) => void;
} => {
  const bulkArchive = (
    bulkSelection: BulkSelection,
    filterConfig: EmailAliasBulkActionFilters
  ) => {
    bulkArchiveEmailAlias(programId, bulkSelection, filterConfig)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { bulkArchive };
};

export const useBulkUnarchiveEmailAlias = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  bulkUnarchive: (
    bulkSelection: BulkSelection,
    filterConfig: EmailAliasBulkActionFilters
  ) => void;
} => {
  const bulkUnarchive = (
    bulkSelection: BulkSelection,
    filterConfig: EmailAliasBulkActionFilters
  ) => {
    bulkUnarchiveEmailAlias(programId, bulkSelection, filterConfig)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { bulkUnarchive };
};
