import {
  IconStatType,
  MenuItemsType,
  ThumbnailType,
} from 'shared/BannerListItem';

import { Topic } from '../../../../models/topic';

export function topicToThumbnail(
  topic: Pick<
    Topic,
    'name' | 'backgroundImageUrl' | 'uploadedImage' | 'backgroundColor'
  >
): ThumbnailType {
  const imageUrl = topic.uploadedImage?.url || topic.backgroundImageUrl;
  const color = topic.backgroundColor || '#5d6480';
  return {
    title: topic.name,
    imageUrl,
    backgroundColor: imageUrl ? undefined : color,
    color: '#ffffff',
  };
}

export function topicToMenuItems(
  programId: number,
  topic: Topic,
  actions: {
    edit: (topicId: number) => void;
    archive: (topicId: number) => void;
    unarchive: (topicId: number) => void;
    // No need for any id here as this just wants to open the delete modal.
    deleteDraft: () => void;
  },
  flags: {
    detailsEnabled?: boolean;
  } = {}
): MenuItemsType {
  const { id } = topic;

  const items: MenuItemsType = [
    {
      title: topic.draft || topic.draftId ? 'Edit Draft' : 'Edit',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        actions.edit(Number(id));
      },
    },
    {
      title: topic.archived ? 'Unarchive' : 'Archive',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (topic.archived) actions.unarchive(Number(id));
        else actions.archive(Number(id));
      },
    },
  ];

  if (topic.draftId || topic.draft) {
    items.push({
      title: 'Delete Draft',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        actions.deleteDraft();
      },
      danger: true,
    });
  }

  if (flags.detailsEnabled) {
    items.unshift({
      title: 'View Details',
      href: `/${programId}/configure/topics/${id}/details`,
    });
  }

  return items;
}

type MetaAttributes = 'stats' | 'followerCount';

export function topicToMetaStats(
  topic: Pick<Topic, MetaAttributes>
): Array<IconStatType> {
  const { stats, followerCount } = { ...topic };

  const result: Array<IconStatType> = [
    {
      text: `${followerCount} followers`,
    },
  ];

  if (stats) {
    result.push({
      text: `${stats.postsPerWeek} posts/week`,
    });
  }

  return result;
}
