/* eslint-disable react/jsx-props-no-spreading */

import { OptionType } from 'hooks/common';
import { useAudienceUsersQuery } from 'hooks/audience';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  components,
  MenuPlacement,
  MenuProps,
  MultiValueProps,
  OptionProps,
  SelectComponentsConfig,
  ValueType,
} from 'react-select';
import lucene from 'lucene';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import { useProgram } from 'contexts/program';
import { selectComponents, selectStyles, selectTheme } from '../Select';
import { normalizeInRoles } from './role-helper';
import styles from './CheckListSelect.module.css';

const capitalizeCriterionLabel = (v: string) => {
  return v
    .split('_')
    .map((p) => p[0].toUpperCase() + p.slice(1))
    .join(' ');
};

const MultiValueLabel: React.FC<MultiValueProps<OptionType>> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...otherProps } = props;
  const { id: programId } = useProgram();
  const {
    data: { value },
    selectProps: { criterion, capitalizeLabel, editableTextToQuery },
  } = otherProps;

  const query = `${criterion}:"${lucene.term.escape(value)}"`;
  const { data: countData } = useAudienceUsersQuery(
    programId,
    `${editableTextToQuery(query)}`
  );
  const queryResultCount = countData ? `(${countData.totalObjects})` : '';

  return (
    <components.MultiValueLabel {...otherProps}>
      <span className={styles.MultiValueLabel}>
        {capitalizeLabel
          ? capitalizeCriterionLabel(otherProps.data.value)
          : otherProps.data.value}
      </span>
      <span>{queryResultCount}</span>
    </components.MultiValueLabel>
  );
};

const ClearButton: React.FC<{ onClick: () => void; disabled: boolean }> = ({
  onClick,
  disabled,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!disabled) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`${disabled ? styles.disabled : styles.clear}`}
      onClick={handleClick}
    >
      Clear selected items
    </button>
  );
};

const MenuWithClearButton: React.FC<MenuProps<OptionType>> = (props) => {
  const {
    children,
    selectProps: { clearAll, value },
  } = props;
  const isValueArray = Array.isArray(value);
  const disabled = !isValueArray || value?.length === 0;
  return (
    <div>
      <div className={styles.offsetSpacer} />
      <components.Menu {...props}>
        <>
          <ClearButton onClick={clearAll} disabled={disabled} />
          {children}
        </>
      </components.Menu>
    </div>
  );
};

const MenuList: React.FC<MenuListComponentProps<OptionType>> = (props) => {
  const { children, options, selectProps } = props;
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <components.MenuList {...props}>
      {children}
      {selectProps.selectProps.hasMore && options.length >= 20 && (
        <div className={styles.moreResults}>
          More results available, use a more specific search term
        </div>
      )}
    </components.MenuList>
  );
};

const OptionWithCheckbox: React.FC<OptionProps<OptionType>> = (props) => {
  const {
    isSelected,
    label,
    selectProps: { capitalizeLabel },
  } = props;
  const className = isSelected ? 'fas fa-check-square' : 'far fa-square';
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <components.Option {...props}>
      <div className={styles.optionDiv}>
        <span className={styles.optionCheckbox}>
          <i className={className} />
        </span>
        <span className={styles.labelSpan}>
          {capitalizeLabel ? capitalizeCriterionLabel(label) : label}
        </span>
      </div>
    </components.Option>
  );
};

const NotShown = () => null;

export const CheckListSelect: React.FC<{
  defaultOptions: OptionType[];
  disabled?: boolean;
  hasMore?: boolean;
  criterion?: string;
  dynamicCount?: boolean;
  loadOptions: (input: string) => Promise<OptionType[] | undefined>;
  onChange: (value: string[]) => void;
  onLoadOptions?: (options: OptionType[] | undefined) => void;
  value?: OptionType[];
  capitalizeLabel?: boolean;
  editableTextToQuery?: (
    text: string,
    includeDeactivatedUsers?: boolean
  ) => void;
  audienceName?: string;
  menuPlacement?: MenuPlacement;
}> = ({
  defaultOptions,
  disabled,
  loadOptions,
  onChange,
  onLoadOptions,
  value,
  hasMore,
  criterion,
  dynamicCount,
  capitalizeLabel,
  editableTextToQuery,
  audienceName,
  menuPlacement = 'auto',
}) => {
  const [selectedValues, setSelectedValues] = useState<OptionType[]>(
    value || []
  );

  const [inputText, setInputText] = useState<string>('');

  const [optionsToLoad, setOptionsToLoad] = useState<OptionType[]>(
    defaultOptions
  );

  useEffect(() => {
    setOptionsToLoad(normalizeInRoles(defaultOptions));
    setSelectedValues(normalizeInRoles(value));
  }, [value, defaultOptions]);

  useEffect(() => {
    if (!inputText && !!defaultOptions.length && !optionsToLoad.length) {
      setOptionsToLoad(normalizeInRoles(defaultOptions));
    }
  }, [inputText, defaultOptions, optionsToLoad]);

  const handleValueSelect = (values: ValueType<OptionType>) => {
    const selected = (values as Array<OptionType>) || [];
    setSelectedValues(() => {
      if (criterion !== 'role') return selected;
      return normalizeInRoles(selected);
    });

    onChange(selected.map((item) => item.value));
  };

  const handleLoadOptions = async (queryString: string) => {
    const raw_result = await loadOptions(queryString);
    let result = raw_result;

    if (criterion === 'role') {
      result = normalizeInRoles(raw_result);
    }

    if (criterion === 'group') {
      result = raw_result?.filter((option) => {
        return !(option.label === audienceName);
      });
    }

    setOptionsToLoad(() => result || []);
    onLoadOptions?.(result);
    return result;
  };

  const handleInputChange = (
    inputValue: string,
    { action }: { action: string }
  ) => {
    if (action !== 'set-value') {
      setInputText(inputValue);

      return inputValue;
    }

    return inputText;
  };

  const clearAll = () => handleValueSelect([]);

  const customComponents: Partial<SelectComponentsConfig<OptionType>> = {
    ...selectComponents,
    MenuList,
    Option: OptionWithCheckbox,
    Menu: MenuWithClearButton,
    ClearIndicator: NotShown,
  };

  if (dynamicCount) {
    customComponents.MultiValueLabel = MultiValueLabel;
  }

  return (
    <>
      <AsyncSelect
        aria-label="Audience Builder Value"
        loadOptions={handleLoadOptions}
        placeholder="Value"
        isDisabled={disabled}
        noOptionsMessage={() => 'none available'}
        isMulti
        defaultOptions={optionsToLoad}
        selectProps={{ hasMore }}
        value={selectedValues}
        inputValue={inputText}
        onInputChange={handleInputChange}
        onChange={handleValueSelect}
        hideSelectedOptions={false}
        clearAll={clearAll}
        components={customComponents}
        styles={selectStyles}
        criterion={criterion}
        capitalizeLabel={capitalizeLabel}
        editableTextToQuery={editableTextToQuery}
        theme={selectTheme}
        closeMenuOnSelect={false}
        onSelectResetsInput={false}
        menuPlacement={menuPlacement}
      />
    </>
  );
};
