import * as React from 'react';
import * as DesignSystem from 'DesignSystem/Components';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import { Layer } from 'shared/Overlay/Layer';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useCallback } from 'react';
import { DefinitionBlock, Targets } from 'models/publisher/block';
import { Visibility, VisibilitySettings } from './VisibilitySettings';
import { ContentDesignTabs, TabType } from '../panels/ContentDesignTabs';
import { usePanelContext } from '../forms/usePanelContext';
import styles from '../forms/styles.module.css';

const defaultVisibility: Visibility = {
  ios: true,
  android: true,
  web: true,
  email: true,
  microapp: true,
};

enum Reach {
  hidden = 'hidden',
  low = 'low',
  normal = 'normal',
}

const getTargets = (
  visibility: Visibility
): { numberOfTargets: number; reach: Reach } => {
  const numberOfTargets = Object.values(visibility).reduce(
    (acc, current) => (current === true ? acc + 1 : acc),
    0
  );
  let reach = Reach.hidden;
  if (numberOfTargets === 0) {
    reach = Reach.hidden;
  }
  if (numberOfTargets === 1) {
    reach = Reach.low;
  }
  if (numberOfTargets > 1) {
    reach = Reach.normal;
  }
  return { numberOfTargets, reach };
};

function visibilityFromBlock(block: DefinitionBlock): Visibility {
  if (!block.target) return defaultVisibility;

  return {
    android: !block.target?.excluded.includes(Targets.android),
    web: !block.target?.excluded.includes(Targets.web),
    email: !block.target?.excluded.includes(Targets.email),
    ios: !block.target?.excluded.includes(Targets.ios),
    microapp: !block.target?.excluded.includes(Targets.microapp),
  };
}

function visibilityToBlock(visibility: Visibility): { excluded: Targets[] } {
  const excluded: Targets[] = [];
  if (!visibility.ios) excluded.push(Targets.ios);
  if (!visibility.android) excluded.push(Targets.android);
  if (!visibility.web) excluded.push(Targets.web);
  if (!visibility.microapp) excluded.push(Targets.microapp);
  if (!visibility.email) excluded.push(Targets.email);
  return { excluded };
}

export const SectionContent: React.FC<{
  block: DefinitionBlock;
  blockId: string;
  onTargetChange: (visibility: DefinitionBlock['target']) => void;
}> = ({ block, onTargetChange }) => {
  const visibility = visibilityFromBlock(block);
  const [reach, setReach] = React.useState(getTargets(visibility).reach);

  const onChange = (data: Partial<Visibility>) => {
    const nextVisibility = { ...visibility, ...data };
    onTargetChange({
      ...block.target,
      ...visibilityToBlock(nextVisibility),
    });
    const { reach: currentReach } = getTargets(nextVisibility);
    setReach(currentReach);
  };

  return (
    <div>
      <VisibilitySettings visibility={visibility} onChange={onChange} />
      {reach === Reach.hidden && (
        <Box margin={[30, 0]}>
          <Alert
            compact
            enableIcon
            bgColor="red"
            type={AlertType.error}
            title="Block is hidden"
            icon={<MAIcon name="warning" />}
            addon={<MAIcon name="close" />}
          />
        </Box>
      )}
      {reach === Reach.low && (
        <Box margin={[30, 0]}>
          <Alert
            compact
            enableIcon
            bgColor="yellow"
            type={AlertType.warning}
            title="Low block visibility"
            icon={<MAIcon name="warning" />}
            addon={<MAIcon name="close" />}
          />
        </Box>
      )}
    </div>
  );
};

export const VisibilityPanel: React.FC<{
  hideBlockEditor: () => void;
  defaultTab: TabType;
}> = ({ defaultTab }) => {
  const panel = usePanelContext();
  const { selected, updateTarget } = React.useContext(BlocksEditorContext);
  const goToOtherTab = React.useCallback(
    (tabName: TabType) => {
      const mapAction = {
        content: 'open-editor',
        design: 'open-styles',
        section: 'open-section',
      };
      const selector = `[data-block-id="${selected?.id}"][data-block-action="${mapAction[tabName]}"]`;
      const element = document.querySelector(selector) as HTMLElement | null;
      element?.click();
    },
    [selected]
  );

  const onTargetChange = useCallback(
    (target: DefinitionBlock['target']) => {
      if (selected) updateTarget(selected.id, target);
    },
    [selected, updateTarget]
  );

  const contentTabButton = React.useMemo(() => {
    if (!selected?.id) return null;
    const selector = `[data-block-id="${selected?.id}"][data-block-action="open-editor"]`;
    return document.querySelector(selector) as HTMLElement | null;
  }, [selected?.id]);

  const visibleTabs = React.useMemo(() => {
    const tabs = ['design'];
    if (contentTabButton) {
      tabs.push('content');
    }

    return tabs as Array<TabType>;
  }, [contentTabButton]);

  if (!selected) return null;

  return (
    <>
      <Layer name="publisher-block-panel">
        <DesignSystem.FixedPanel side="right">
          <ContentDesignTabs
            active={defaultTab}
            activate={goToOtherTab}
            visibleTabs={visibleTabs}
          />
          <div className={styles.fieldWrapper}>
            <SectionContent
              blockId={selected.id}
              block={selected.block}
              onTargetChange={onTargetChange}
            />
          </div>
        </DesignSystem.FixedPanel>
      </Layer>
      {panel.isBlocking && panel.blocker}
    </>
  );
};
