import React from 'react';
import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Post } from 'models/publisher/post';
import { useContentInsightsQuery } from 'hooks/content-insights';
import { useContentReportUrls } from 'hooks/content/useContentReportUrls';
import { useCommentsAnalysisQuery } from 'hooks/comments-analysis';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { CommentsAnalysis } from 'services/api-comments-analysis';
import { Sparkles as SparklesIcon } from 'shared/icons';
import { capitalize } from 'utility/strings';
import { EmbeddedMedia } from './EmbeddedMedia';
import { Metric, MetricsSection, MetricsSubsection } from './MetricsSection';

export const EngagementSection: React.FC<{
  post: Post;
}> = ({ post }) => {
  const { content } = post;

  const { id: programId } = useProgram();
  const sentimentAnalysisEnabled = useFeatureFlagsQuery(
    programId,
    'EE.AI.SentimentAnalysis.Enabled'
  ).data?.value;

  const now = DateTime.now();
  const startDate = content.createdAt
    ? DateTime.fromISO(content.createdAt)
    : now;
  const endDate = now;
  const timezone = now.zone.name;

  const {
    data: insights,
    isLoading: isLoadingInsights,
  } = useContentInsightsQuery({
    contentId: content.id,
    startDate,
    endDate,
    timezone,
  });

  const { getArticleUrl, getPollUrl, getVideoUrl } = useContentReportUrls({
    content,
    startedAt: startDate,
    version: 2,
  });

  const commentsAnalysisEnabled = Boolean(
    post.settings.isCommentable && sentimentAnalysisEnabled
  );

  const {
    data: commentsAnalysis,
    isLoading: isLoadingCommentsAnalysis,
  } = useCommentsAnalysisQuery({
    contentId: content.id,
    usePercentage: true,
    enabled: commentsAnalysisEnabled,
  });

  const { data: reportVersion } = useFeatureFlagsQuery(
    programId,
    'Planner.Metrics.ContentReportVersion'
  );

  const isNonMigrated = (reportVersion?.value as string) === 'non_migrated';

  const nonMigratedMetrics: Array<Metric> = [
    {
      isLoading: isLoadingInsights,
      name: 'Engagement',
      tooltip:
        'The % of users that opened, clicked, or engaged with your content at least once, divided by the number of users exposed to the content.',
      stat: { type: 'percent', value: insights?.openRate },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Viewers',
      tooltip: 'The number of users that have seen the content.',
      stat: { type: 'count', value: insights?.viewers },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Opened',
      tooltip: 'The number of users that have opened a piece of content.',
      stat: { type: 'count', value: insights?.usersOpened },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Click Through Rate',
      tooltip: 'The unique number of clicks divided by unique opens.',
      stat: { type: 'percent', value: insights?.averageCtr },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Liked',
      stat: { type: 'count', value: insights?.usersLiked },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Bookmarked',
      stat: { type: 'count', value: insights?.usersBookmarked },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Commented',
      stat: { type: 'count', value: insights?.usersCommented },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Shared',
      stat: { type: 'count', value: insights?.usersShared },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Acknowledged',
      tooltip:
        'The number of users who have clicked the acknowledge button on this campaign.',
      stat: { type: 'count', value: insights?.usersAcknowledged },
    },
  ];

  const migratedMetrics: Array<Metric> = [
    {
      isLoading: isLoadingInsights,
      name: 'Reached Users',
      tooltip: 'Unique users who viewed the campaign',
      stat: { type: 'count', value: insights?.viewers },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Opened Users',
      tooltip: 'Number of unique users who opened the campaign',
      stat: { type: 'count', value: insights?.usersOpened },
    },
    {
      isLoading: isLoadingInsights,
      name: '% Opened Users',
      tooltip: 'Percentage of users who opened the campaign',
      stat: { type: 'percent', value: insights?.openRate },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Users Clicked a Link',
      tooltip: 'Number of unique users who clicked an embedded link',
      stat: { type: 'count', value: insights?.usersClickedALink },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Liked Users',
      tooltip: 'Number of unique users who liked the campaign',
      stat: { type: 'count', value: insights?.usersLiked },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Bookmarked Users',
      tooltip: 'Number of unique users who bookmarked the campaign',
      stat: { type: 'count', value: insights?.usersBookmarked },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Commented Users',
      tooltip: 'Number of unique users who commented on the campaign',
      stat: { type: 'count', value: insights?.usersCommented },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Shared Users',
      tooltip: 'Number of unique users who shared the campaign',
      stat: { type: 'count', value: insights?.usersShared },
    },
    {
      isLoading: isLoadingInsights,
      name: 'Acknowledged Users',
      tooltip: 'Number of unique users who acknowledged the campaign',
      stat: { type: 'count', value: insights?.usersAcknowledged },
    },
  ];

  const subsections: Array<MetricsSubsection> = [
    {
      id: 'engagement--default',
      metrics: isNonMigrated ? nonMigratedMetrics : migratedMetrics,
      addon: (
        <EmbeddedMedia
          content={content}
          getPollUrl={getPollUrl}
          getVideoUrl={getVideoUrl}
        />
      ),
    },
  ];

  if (commentsAnalysisEnabled) {
    const polarities: Array<{
      name: keyof CommentsAnalysis['polarity'];
      tooltip?: string;
    }> = [
      { name: 'positive' },
      { name: 'negative' },
      { name: 'neutral', tooltip: 'Expressing no strong opinion or emotion.' },
      {
        name: 'mixed',
        tooltip: 'Contains elements of both positive and negative sentiment.',
      },
    ];

    subsections.push({
      id: 'engagement--sentiment-analysis',
      style: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      subtitle: (
        <Flex
          start
          style={{
            columnGap: '16px',
          }}
        >
          <SparklesIcon fill="#F74857" />
          <Text.Subheading semibold>Comment Sentiment</Text.Subheading>
        </Flex>
      ),
      metrics: [
        ...polarities.map(({ name, tooltip }) => ({
          isLoading: isLoadingCommentsAnalysis,
          name: capitalize(name),
          tooltip,
          stat: {
            type: 'percent',
            value: commentsAnalysis
              ? Math.round(commentsAnalysis.polarity[name])
              : 0,
          },
        })),
      ],
    });
  }

  return (
    <MetricsSection
      title="Engagement"
      description="The metrics displayed on this page provide a snapshot of the most recent engagement data available and may not be real-time. Please also note that the comprehensive metrics included in the 'Full Report' may differ slightly from the metrics presented here and are subject to a 2-hour delay."
      reportLink={{
        dataPendo: 'view-engagement-report',
        href: getArticleUrl(),
        label: 'View Full Report',
      }}
      subsections={subsections}
    />
  );
};
