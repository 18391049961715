import { Criterion } from 'models/publisher/criterion';
import { Author } from './author';
import { Meta } from './content';

export type PollOptionImage = {
  altKey?: string;
  assetKey: string;
  url: string;
};

export type PollOption = {
  text: string;
  image?: PollOptionImage;
  selected?: {
    image?: PollOptionImage;
  };
  result?: {
    image?: PollOptionImage;
  };
};

export type PollConfig = {
  options: Array<PollOption>;
};

export enum PollType {
  single = 'single',
  multiple = 'multiple',
  satisfaction = 'satisfaction',
  stars = 'stars',
  yes_no = 'yes_no',
}

export type Poll = {
  programId: number;
  archiveAt?: string;
  config: PollConfig;
  criterion: Criterion;
  descriptionText: string;
  id: number;
  maxSelections: number;
  preset: PollType;
  publishedAt?: string;
  questionText: string;
  selectionType: 'text' | 'slider' | 'image' | 'star';
  showResultsToUser: boolean;
  status: 'draft' | 'scheduled' | 'published' | 'archived';
  type: 'poll';
  contentAuthor: Author;
} & Meta;

export function isPoll(content: Poll | unknown): content is Poll {
  return (content as Poll) && (content as Poll).type === 'poll';
}

export type Asset = {
  type: 'image';
  key: string;
  url: string;
};

export type AssetsTable = Record<PollType, Record<Asset['key'], Asset>>;
