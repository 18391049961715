import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { removeUser as datadogReset } from 'hooks/datadog';
import { useActor } from 'hooks/authentication';
import { LoadingScreen } from 'shared/LoadingScreen';
import { Authenticated } from './Authenticated';
import { AuthenticationError } from '../Authentication/AuthenticationError';

export const AuthenticatedInProgram: React.FC<RouteComponentProps<{
  programId: string;
}>> = ({ children, programId }) => {
  const { actor, error } = useActor({
    // Remove the user/tenant pair from Datadog tracking whenever authentication state is changing.
    // TODO: consolidate setting and clearing of Datadog in one place (probably inside
    // hooks/authentication.ts).
    onChange: datadogReset,
    // Require a specific tenant (determined by current URL) when fetching the actor. useActor will
    // make sure the returned credential is for the correct tenant, even if that requires a token
    // exchange request, or sending the user back to the auth service to reauthenticate.
    tenant: `program:${programId}`,
  });
  if (actor) {
    return <Authenticated>{children}</Authenticated>;
  }
  if (error) {
    return <AuthenticationError error={error} />;
  }
  return <LoadingScreen />;
};
