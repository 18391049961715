import React from 'react';
import { Filter, isDojoListFilter } from 'models/insight/Filter';
import { useQueries, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetchAndTransformDojoFilters } from 'services/api-insights';
import {
  FiltersStateMap,
  FilterState,
} from 'App/Program/Main/Insight/contexts/filtersStateReducer';

/**
 * This hook will take a list of insight filters and for any that are
 * dojo list filters, it will make an api request to retrieve and set the
 * `values` attribute.
 *
 * the hook returns a tuple containing a boolean signifying when all data is loaded
 * it will also return the updated set of filters
 *
 * NOTE1: we do not return the list of response objects from `useQueries()`
 * NOTE2: we only update the list of filters when ALL requests have completed
 *        in the future if we want to allow individual filter load/error states
 *        then we will need to modify this to return all response objects
 * NOTE3: date_range is appended as a query param to all api requests
 *        this value can be any string, including empty string (meaning fetch for all dates)
 *        However, undefined dateRangeParam signifies that the implementing
 *        hook is NOT ready, and we will just return the unmodified list of `allFilters`
 * NOTE4: Date range can be a value, undefined, or false
 *        - string    -- Use this to make the request
 *        - undefined -- Will provide a value, but one is not ready yet. Wait..
 *        - false     -- Will NOT provide a value, make the request anyway.
 * */
export const useInsightsFiltersWithUpdatedDojoListValues = (
  programId: number,
  reportId: string | undefined,
  allFilters: Filter[] | undefined,
  dateRangeParam: string | undefined | false
): [FilterState[], FiltersStateMap] => {
  const didUpdateListFilterValues = React.useRef(false);

  // find only those filters that need to have their values requested
  // these are filters with datetype=category and values_pending: true
  // NOTE: you must use a type-check boolean function in the `filter()` callback
  // using any other logic (such as manually inspecting each filter)
  // will mysteriously yield an infinite loop.
  //   eg `allFilters?.filter((f) => !!f.valuesPending)` = infinite loop
  //   eg 'allFilters?.filter(isDojoListFilter)' = no loop
  const dojoFilters: Filter[] = React.useMemo(() => {
    return allFilters?.filter(isDojoListFilter) || [];
  }, [allFilters]);

  // construct list of query params
  const dojoFilterQueryParams: UseQueryOptions[] = React.useMemo(() => {
    // we assume that dateRangeParams == undefined signifies that we are not yet ready
    // to fetch api data. as such, we will immediately return an empty array of results
    if (dateRangeParam === undefined) return [];
    didUpdateListFilterValues.current = false;
    return dojoFilters.map((filter) => {
      const queryKey = `${filter.slug}:${reportId}:${programId}:${
        dateRangeParam || ''
      }`;
      return {
        queryKey: `insights:report:filter:${queryKey}`,
        queryFn: () =>
          fetchAndTransformDojoFilters({
            programId,
            reportId,
            filterSlug: filter.slug,
            ...(dateRangeParam ? { dateRangeParam } : {}),
          }),
        placeholderData: filter,
      };
    });
  }, [dojoFilters, reportId, programId, dateRangeParam]);

  // fire queries for all dojo filters
  const filterQueryResponses = useQueries(
    dojoFilterQueryParams
  ) as UseQueryResult<Filter, Error>[];

  // create a filter state map from the array of query responses
  // filter state map will contain the loading/error states of the response
  const updatedFiltersMap: FiltersStateMap = React.useMemo(() => {
    const newMap: FiltersStateMap = new Map();
    filterQueryResponses.forEach((response) => {
      if (!response.data) return;
      newMap.set(response.data.slug, {
        filter: response.data,
        // isFetching correctly tells us the loading state when placeholder data is present
        isLoading: response.isFetching,
        errorMessage: response.error?.message,
      });
    });

    return newMap;
  }, [filterQueryResponses]);

  // update any filters that were returned
  const updatedFiltersWithDojoListValues: FilterState[] = React.useMemo(() => {
    if (allFilters === undefined) return [];
    // there are no pending filter requests. return all filters with no loading state
    if (filterQueryResponses.length === 0)
      return allFilters.map((filter) => ({ filter, isLoading: false }));
    // there are pending filter requests. wait for every response to finish loading and show all filters as loading
    if (filterQueryResponses.some((response) => response.isFetching))
      return allFilters.map((filter) => ({ filter, isLoading: true }));

    // filters have all finished loading. update the new values in the old filters
    didUpdateListFilterValues.current = true;

    // this is a lookup map for FILTERS that have values from the response queries
    const filtersWithValuesMap: Map<string, Filter> = new Map();
    filterQueryResponses.forEach((response) => {
      if (!response.data) return;
      filtersWithValuesMap.set(response.data.slug, response.data);
    });

    // now update the old filters with updated values
    const updatedFilters: FilterState[] = allFilters
      .map((oldFilter) => {
        // find the new values via the map
        const filterWithValues = filtersWithValuesMap.get(oldFilter.slug);
        if (filterWithValues === undefined)
          return { filter: oldFilter, isLoading: false };

        // update the `availableListValues` param with the new filters
        return {
          filter: oldFilter,
          ...({
            availableListValues: filterWithValues.values,
            isLoading: false,
          } as Omit<FilterState, 'filter'>),
        };
      })

      // returns all of the filters...
      .filter((newFilterConfig) => {
        // only return those dojo list filters with values
        if (filtersWithValuesMap.has(newFilterConfig.filter.slug)) {
          return (newFilterConfig.availableListValues?.length || 0) > 0;
        }
        // all other filters are returned as is
        return newFilterConfig;
      });

    return updatedFilters;
  }, [filterQueryResponses, allFilters]);

  return [updatedFiltersWithDojoListValues, updatedFiltersMap];
};
