import React from 'react';
import {
  emptyImageData,
  ImageData,
  IMAGE_UPLOAD_ERRORS,
  Image,
} from 'models/image';
import {
  UPLOAD,
  EXTERNAL,
} from 'components/publisher/blocks/forms/fields/shared/SourceMenuConst';
import { useBasicValidator } from './useBasicValidator';
import { useHostImageFileMutation, useHostImageUrlMutation } from './image';
import { QueryError } from './common';
import {
  HostImageFileProps,
  HostImageUrlProps,
  isUploadFile,
  UploadFile,
  UploadUrl,
} from '../services/api-assets';
import {
  AltTextData,
  FileData,
  fileValidators,
  urlValidators,
} from './useContentImage';

type PropsType = {
  onUpload: (data: ImageData) => void;
  onError?: (message: string) => void;
  programId: number;
};

type Uploader<T> = (
  props: T
) => {
  isUploading: boolean;
  update: (image: File | string) => void;
  remove: () => void;
  setAlt: (value: string) => void;
  errors: Array<string>;
  image: ImageData;
};

export const useImageUploader: Uploader<PropsType> = ({
  onUpload,
  programId,
  onError,
}) => {
  const [image, setImage] = React.useState<ImageData>(emptyImageData());
  const [errors, setErrors] = React.useState<Array<string>>();
  const fileValidator = useBasicValidator<FileData & AltTextData>(
    fileValidators,
    IMAGE_UPLOAD_ERRORS
  );
  const urlValidator = useBasicValidator<AltTextData>(
    urlValidators,
    IMAGE_UPLOAD_ERRORS
  );
  const altTextRef = React.useRef(image.altText); // handle async alt text changes, while uploading image, kinda edge case

  const onSuccess = (
    data: Image,
    variables: HostImageFileProps | HostImageUrlProps
  ) => {
    let originalFilename;
    if (isUploadFile(variables.data)) {
      // Strip off the file extension
      originalFilename = variables.data.name.split('.').slice(0, -1).join('.');
    }

    const newState = {
      ...image,
      ...data,
      altText: altTextRef.current,
      isPlaceholder: false,
      processed: data.status === 'completed',
      originalFilename,
    };
    setImage(newState);
    onUpload(newState);
    setErrors(undefined);
  };

  const queryError = React.useCallback(
    (error: QueryError) => {
      const message = error.message || error.toString();
      setErrors([message]);
      if (onError) {
        let parsedError = message;
        try {
          parsedError = JSON.parse(message)?.error || message;
          const errorType = parsedError.split(':')[0].trim();
          if (errorType === 'Error creating asset')
            parsedError = 'Cannot upload the image. Please try again.';
        } catch (e) {
          // noop
        } finally {
          onError(parsedError);
        }
      }
    },
    [onError]
  );

  const hostImageFile = useHostImageFileMutation({
    onSuccess,
    onError: queryError,
  });

  const hostImageUrl = useHostImageUrlMutation({
    onSuccess,
    onError: queryError,
  });

  const uploadFile = React.useCallback(
    (file: UploadFile) => {
      const { isValid, errors: errorMessages } = fileValidator.validate({
        file,
        alt: `${image.altText}`,
      });

      if (!isValid) {
        if (onError) {
          onError(`${file.name}: ${errorMessages.join(', ')}`);
        }
        setErrors(errorMessages);
        return;
      }

      hostImageFile.mutate({ data: file, programId, source: UPLOAD });
    },
    [fileValidator, hostImageFile, image.altText, onError, programId]
  );

  const uploadUrl = React.useCallback(
    (url: UploadUrl) => {
      const { isValid, errors: errorMessages } = urlValidator.validate({
        alt: `${image.altText}`,
      });

      if (!isValid) {
        if (onError) {
          onError(`${url}: ${errorMessages.join(', ')}`);
        }
        setErrors(errorMessages);
        return;
      }

      hostImageUrl.mutate({ data: url, programId, source: EXTERNAL });
    },
    [hostImageUrl, image.altText, onError, programId, urlValidator]
  );

  const update = React.useCallback(
    (data: UploadFile | UploadUrl) => {
      if (!data) return;

      if (isUploadFile(data)) uploadFile(data);
      else uploadUrl(data);
    },
    [uploadFile, uploadUrl]
  );

  const remove = React.useCallback(() => {
    const newState = emptyImageData();
    setImage(newState);
    onUpload(newState);
    setErrors(undefined);
  }, [onUpload]);

  const allErrors = React.useMemo(() => {
    const memo = [];
    if (hostImageFile.errorMessage) memo.push(hostImageFile.errorMessage);
    if (hostImageUrl.errorMessage) memo.push(hostImageUrl.errorMessage);
    if (errors) return memo.concat(errors);

    return memo;
  }, [errors, hostImageFile.errorMessage, hostImageUrl.errorMessage]);

  const setAlt = React.useCallback(
    (altText: string) => {
      altTextRef.current = altText;
      setImage({ ...image, altText });
    },
    [image]
  );

  return {
    remove,
    update,
    setAlt,
    image,
    errors: allErrors,
    isUploading: hostImageFile.isSaving || hostImageUrl.isSaving,
  };
};
