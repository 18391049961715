import React from 'react';
import {
  ListDropdownItem,
  ListDropdownProvider,
} from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { WindowedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/WindowedListDropdown';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { Option } from 'models/insight/json/filterJson';

// This dropdown renders a generic list dropdown
// utilizing values in "filter.values"
// if a filter requires api data, fetch it from a higher context
// and then update the filter to in `FilterDropdownContext`
type StaticListDropdownPropsType = {
  values: Option[] | undefined;
};
export const StaticListDropdown: React.FC<StaticListDropdownPropsType> = ({
  values,
}) => {
  const { defaultValues } = React.useContext(FilterDropdownContext);

  // values for filter are defined in the `filter.values` param
  const allItems: ListDropdownItem[] = React.useMemo(() => {
    return (values || []).map(({ value, label }) => ({
      uuid: value,
      value,
      label,
    }));
  }, [values]);

  return (
    <ListDropdownProvider allItems={allItems} defaultValues={defaultValues}>
      <WindowedListDropdown />
    </ListDropdownProvider>
  );
};
