import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { EngagementSummary } from './EngagementSummary';
import { Channel } from './Channel';
import { Timezone } from './Timezone';
import { Platform } from './Platform';
import { Engagement } from './Engagement';
import { CampaignSummary } from './CampaignSummary';
import styles from './content-summary.module.css';

const channels = [
  {
    name: 'Feed',
    count: 205,
    percent: 14,
    progress: 20,
    measures: [
      { name: 'Impressions', count: 205 },
      { name: 'Activity', count: 29 },
      { name: 'Click', count: 35 },
    ],
  },
  {
    name: 'Assistant',
    count: 122,
    percent: 59,
    progress: 70,
    measures: [
      { name: 'Sent', count: 122 },
      { name: 'Impressions', count: 98 },
      { name: 'Opened', count: 56 },
    ],
  },
  {
    name: 'Email',
    count: 138,
    percent: 5,
    progress: 25,
    measures: [
      { name: 'Sent', count: 138 },
      { name: 'Delivered', count: 137 },
      { name: 'Opened', count: 25 },
      { name: 'Error', count: 1 },
    ],
  },
  {
    name: 'Notification',
    count: 19,
    percent: 97,
    progress: 25,
    measures: [
      { name: 'Sent', count: 19 },
      { name: 'Opened', count: 17 },
    ],
  },
];
const timezones = [
  {
    name: 'Now',
    count: 425,
    measures: [
      { name: 'Audience', value: 205 },
      { name: 'Opens', value: 90 },
    ],
    selected: true,
  },
  { name: 'PDT' },
  { name: 'CDT' },
  { name: 'EDT' },
];
const platforms = [
  { name: 'Web', value: 216 },
  { name: 'Ios', value: 121 },
  { name: 'Android', value: 107 },
  { name: 'Slack', value: 16 },
  { name: 'Sharepoint', value: 16 },
  { name: 'Homepage', value: 16 },
];
const engagements = [
  { name: 'Power Users', value: 216 },
  { name: 'Active', value: 121 },
  { name: 'Passove', value: 107 },
];
const campaign = {
  content: {
    header: 'The Greatest Thing Just Happened',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed\n' +
      '                  do eiusmod tempor incididunt ut labore et dolore magna aliqua',
  },
  settings: [
    { name: 'Priority', description: 'Nice To Know' },
    { name: 'Publish on', description: 'Today' },
    { name: 'Duration', description: 'Run for 7 days' },
  ],
  rules: [
    {
      name: '7 days',
      description:
        'Lorem ipsum 7 days dolor sit amet, consectetur adipiscing\n' +
        '              elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
        '              aliqua.',
    },
    {
      name: '100 users',
      description:
        'Lorem ipsum 7 days dolor sit amet, consectetur adipiscing\n' +
        '              elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
        '              aliqua.',
    },
    {
      name: '10 users',
      description:
        'Lorem ipsum 7 days dolor sit amet, consectetur adipiscing\n' +
        '              elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
        '              aliqua.',
    },
    {
      name: 'Results',
      description:
        'Lorem ipsum 7 days dolor sit amet, consectetur adipiscing\n' +
        '              elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
        '              aliqua.',
    },
  ],
};

export const ContentSummary: React.FC<RouteComponentProps> = () => (
  <div className={styles.container}>
    <EngagementSummary />
    <Channel channels={channels} unreachable={608} />
    <Timezone timezones={timezones} />
    <Platform platforms={platforms} />
    <Engagement engagements={engagements} />
    <CampaignSummary
      content={campaign.content}
      settings={campaign.settings}
      rules={campaign.rules}
    />
  </div>
);
