import React from 'react';
import { useTopicFormCtx } from '../../../context';
import { PinnedPostAddModal } from './PinnedPostsAddModal';
import { EditAboutPreviewModal } from './EditAboutPreviewModal';
import { EditShortcutModal } from './EditShortcutModal';
import { ErrorModal } from './ErrorModal';
import { DeleteShortcutModal } from './DeleteShortcutModal';

function Modals(): JSX.Element | null {
  const {
    topic,
    state: { modal },
    toggleModal,
  } = useTopicFormCtx();
  const handleModalClose = () => {
    toggleModal({ type: null });
  };
  switch (modal.type) {
    case 'addPinnedPosts':
      return <PinnedPostAddModal topic={topic} onClose={handleModalClose} />;

    case 'editAboutPreview':
      return <EditAboutPreviewModal onClose={handleModalClose} />;

    case 'editShortcut':
      return (
        <EditShortcutModal
          shortcut={modal.shortcut}
          onClose={handleModalClose}
        />
      );

    case 'errorModal':
      return (
        <ErrorModal onClose={handleModalClose}>{modal.content}</ErrorModal>
      );

    case 'deleteShortcut':
      return (
        <DeleteShortcutModal
          shortcut={modal.shortcut}
          onClose={handleModalClose}
        />
      );

    default:
      return null;
  }
}

export default Modals;
