import * as React from 'react';
import cx from 'classnames';
import styles from './navigation-bars.module.css';

export interface NavbarProps {
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string;
}

export const UpperBar: React.FC<NavbarProps> = ({
  children,
  ariaLabel,
  className,
}) => (
  <nav
    className={cx(styles.SplitFooter, styles.top, className)}
    aria-label={ariaLabel}
  >
    {children}
  </nav>
);

export const LowerBar: React.FC<NavbarProps> = ({ children, ariaLabel }) => (
  <nav className={cx(styles.SplitFooter)} aria-label={ariaLabel}>
    {children}
  </nav>
);
