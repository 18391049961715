import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Section, Button } from 'DesignSystem/Form';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { useFlashMessage } from 'contexts/flasher';
import { Checkbox } from 'shared/Checkbox';
import cx from 'classnames';
import * as Text from 'DesignSystem/Typography';
import {
  useCampaignVariableConfigurationsQuery,
  useUpdateCampaignVariableConfigurations,
} from 'hooks/campaign-variables';
import {
  CampaignVariable,
  populateDisplayNames,
} from 'models/campaign-variable';
import { Tooltip, FormModal } from 'DesignSystem/Components';
import { TextInput } from 'shared/TextInput';
import { MAIcon } from 'shared/MAIcon';
import { useProgram } from 'contexts/program';
import { useFlashServerErrors } from 'utility/errors';
import { CustomLabel } from './CustomLabel';

import styles from './styles.module.css';

export const EditAttributeManagement: React.FC<RouteComponentProps> = () => {
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const { id: programId } = useProgram();
  const navigate = useNavigate();

  const loadListError = React.useCallback(() => {
    setFlashMessage({
      message: 'Unable to fetch attributes list',
      severity: 'error',
    });
  }, [setFlashMessage]);

  const { data: campaignVariables } = useCampaignVariableConfigurationsQuery({
    onError: loadListError,
  });

  const {
    update: updateCampaignVariableConfigurations,
  } = useUpdateCampaignVariableConfigurations({
    onSuccess: (_) => {
      setFlashMessage({
        message: 'Settings updated successfully',
        severity: 'info',
      });
      navigate(`/${programId}/configure/attribute-management`);
    },
    onError: (error) => {
      flashServerErrors(error, 'Settings update unsuccessful');
    },
  });
  const [campaignVariablesState, setCampaignVariablesState] = React.useState<
    CampaignVariable[]
  >([]);
  const [initialActive, setInitialActive] = React.useState<CampaignVariable[]>(
    []
  );
  const [sort, setSort] = React.useState({ dir: 'asc' });
  const [modalActive, setModalActive] = React.useState(false);

  React.useEffect(() => {
    if (campaignVariables) {
      populateDisplayNames(campaignVariables);
      // upon initial load,  sort active first, then alpha by name
      const sortedCampaignVariables = campaignVariables.sort(
        (a: CampaignVariable, b: CampaignVariable) => {
          const aa = a.active;
          const ba = b.active;
          const an = a.display_name ? a.display_name : a.name;
          const bn = b.display_name ? b.display_name : a.name;
          if (aa > ba) return -1;
          if (ba > aa) return 1;
          if (an < bn) return -1;
          if (bn < an) return 1;
          return 0;
        }
      );

      setCampaignVariablesState(sortedCampaignVariables as CampaignVariable[]);
      setInitialActive(
        sortedCampaignVariables.filter((campaignVar) => campaignVar.active)
      );
    }
  }, [campaignVariables]);

  function onSortClick() {
    const dir = sort.dir === 'asc' ? 'desc' : 'asc';
    setSort({ dir });
    const sortedCampaignVariables = campaignVariablesState.sort(
      (a: CampaignVariable, b: CampaignVariable) => {
        if (dir === 'asc') {
          return a.name > b.name ? 1 : -1;
        }
        return a.name < b.name ? 1 : -1;
      }
    );

    setCampaignVariablesState(sortedCampaignVariables);
  }

  function toggleCheckbox(variable: CampaignVariable) {
    const updatedCampaignVariables = campaignVariablesState.map((item) => {
      if (item.name === variable.name) {
        return { ...item, active: !item.active };
      }
      return item;
    });
    setCampaignVariablesState(updatedCampaignVariables);
  }

  function handleFallbackEdit(value: string, variable: CampaignVariable) {
    const updatedCampaignVariables = campaignVariablesState.map((item) => {
      if (item.name === variable.name) {
        return { ...item, fallback_value: value };
      }
      return item;
    });
    setCampaignVariablesState(updatedCampaignVariables);
  }

  /**
   * @todo Implement update function
   */
  function save(confirmed: boolean) {
    const activeCampaignVariables = campaignVariablesState.filter(
      (campaignVar: CampaignVariable) => campaignVar.active
    );
    const missingVars = initialActive.filter(
      (initialVar: CampaignVariable) =>
        !activeCampaignVariables.find(
          (stateVar: CampaignVariable) => stateVar.name === initialVar.name
        )
    );
    if (missingVars.length > 0 && !confirmed) return setModalActive(true);
    setModalActive(false);
    setInitialActive(activeCampaignVariables);
    return updateCampaignVariableConfigurations(campaignVariablesState);
  }

  return (
    <ListPage
      title="Field Management"
      description="Manage user attributes and their default values for use within content"
      breadcrumbs={[
        { label: 'Configure', to: '../../' },
        { label: 'Field Management', to: '..' },
        { label: 'Personalized Fields' },
      ]}
      actions={[
        {
          label: 'Save',
          icon: <SVGIcon name="SaveSuccess" />,
          onClick: () => {
            save(false);
          },
        },
      ]}
    >
      {modalActive && (
        <FormModal
          entityText=""
          actionText="Removing personalized fields"
          width="625px"
          contentPadding="25px"
          onCancel={() => setModalActive(false)}
          submitButton={
            <Button disabled={false} onClick={() => save(true)} label="Save" />
          }
        >
          <Text.Body>
            You are about to remove personalized fields that may be in use with
            existing content. If you choose to save, any content using these
            fields will display a blank space instead.
          </Text.Body>
        </FormModal>
      )}
      <Section
        className={styles.EditAttributeSection}
        title="Personalized Fields"
        description="*User attributes can only be removed at the system level via user sync"
      >
        <table className={styles.EditTable}>
          <thead>
            <tr>
              <th>
                <div
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex={0}
                  className={styles.Sortable}
                  onClick={() => {
                    onSortClick();
                  }}
                >
                  <span className={styles.TableHeaderText}>
                    User attributes
                    <span className={cx(styles.Icon, styles.Sorted)}>
                      {sort.dir === 'desc' ? (
                        <SVGIcon name="ArrowUpward" />
                      ) : (
                        <SVGIcon name="ArrowDownward" />
                      )}
                    </span>
                  </span>
                </div>
              </th>
              <th>
                <span className={styles.TableHeaderText}>
                  Include attribute
                  <Tooltip content="Selected attributes will be included as user attribute options for campaign creation.">
                    <span className={styles.TooltipIcon}>
                      <MAIcon name="help" />
                    </span>
                  </Tooltip>
                </span>
              </th>
              <th>
                <span className={styles.TableHeaderText}>
                  Default value
                  <Tooltip content="Default values can be configured to display for any users who do not have data to support an attribute.">
                    <span className={styles.TooltipIcon}>
                      <MAIcon name="help" />
                    </span>
                  </Tooltip>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {campaignVariablesState.map((variable) => (
              <tr key={variable.name}>
                <td>
                  <Text.Subheading>{variable.display_name}</Text.Subheading>
                  <CustomLabel variable={variable} />
                </td>
                <td>
                  <Checkbox
                    className={styles.CheckboxCell}
                    checked={variable.active}
                    onChange={() => toggleCheckbox(variable)}
                  />
                </td>
                <td>
                  <TextInput
                    className={styles.TextEditCell}
                    value={variable.fallback_value}
                    onChange={(value) => handleFallbackEdit(value, variable)}
                    placeholder="Enter a default value"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
    </ListPage>
  );
};
