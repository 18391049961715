import React, { useMemo } from 'react';
import cx from 'classnames';
import { scaleOrdinal } from 'd3';
import { DateTime, Interval } from 'luxon';
import debounce from 'debounce-promise';
import {
  CustomDrawingFunction,
  LineChart,
  LineData,
  PointData,
} from '../../../../../../../shared/Charts/LineChart';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';
import styles from '../MetricsCards.module.css';

interface IPerformanceTrendCard {
  data: null | CampaignResultsData;
  startDate: DateTime;
  keyFilter: Array<string>;
  isLoading: boolean;
}

const dimensions = {
  height: 240,
  width: 1118,
  padding: {
    top: 24,
    bottom: 24,
    left: 24,
    right: 0,
  },
};

export const PerformanceTrendCard: React.FC<IPerformanceTrendCard> = ({
  data,
  startDate,
  keyFilter,
  isLoading,
}) => {
  const graphData = data?.campaign.performance_trend;
  const reached = data?.campaign.stats.reached || 1;
  const [highlighted, setHighlighted] = React.useState<string>();
  const filteredGraphData = useMemo(() => {
    return (
      graphData?.filter((line) => {
        return keyFilter.includes(line.label) && line.data.length > 0;
      }) || []
    );
  }, [graphData, keyFilter]);

  const currentDay = Math.min(
    Interval.fromDateTimes(startDate.toUTC(), DateTime.now().toUTC()).length(
      'day'
    ),
    30
  );

  const [toolTipCoordinates, setToolTipCoordinates] = React.useState({
    x: 0,
    y: 0,
    index: 0,
  });
  const [toolTipOpen, setToolTipOpen] = React.useState(false);
  const showToolTip = debounce(
    (x: number, y: number, index: number, show: boolean) => {
      setToolTipCoordinates({
        x,
        y,
        index,
      });
      setToolTipOpen(show);
    },
    50,
    { leading: true }
  );

  const verifiedData = useMemo(() => {
    return filteredGraphData.map((line) => {
      if (line.data.length === currentDay) {
        return line;
      }

      const fixedData: PointData[] = [];
      for (let i = 0; i <= currentDay; i += 1) {
        const date = startDate.plus({ hours: 24 * i }).toISODate();

        const foundDate = line.data.find(({ label }) => label === date);

        if (foundDate) {
          fixedData.push(foundDate);
        } else {
          fixedData.push({
            label: date ?? '',
            value: 0,
          });
        }
      }
      return {
        label: line.label,
        data: fixedData,
      };
    });
  }, [currentDay, filteredGraphData, startDate]);

  const drawToolTip = () => {
    if (!toolTipOpen) {
      return null;
    }
    const { x, y, index } = toolTipCoordinates;
    const openedUsers = verifiedData.find(
      (line) => line.label === 'Opened Users'
    );
    const engagedUsers = verifiedData.find(
      (line) => line.label === 'Engaged Users'
    );
    return (
      <div
        className={styles.toolTip}
        style={{ top: y, left: x }}
        onMouseEnter={() => setToolTipOpen(true)}
        onMouseLeave={() => setToolTipOpen(false)}
      >
        <div className={styles.metricCard}>
          <div className={styles.metricCardBody}>
            <p className={styles.title}>
              <b>Day {index} Summary</b>
            </p>
          </div>
          <div className={styles.metricCardBody}>
            {verifiedData.map((d) => (
              <span className={styles.item}>
                <b>
                  {d.label.replace(
                    'ed Users',
                    d.label.startsWith('Open') ? 's' : 'es'
                  )}
                  :
                </b>{' '}
                {d.data?.[index]?.value ?? 0}
              </span>
            ))}
            {openedUsers && (
              <span className={styles.item}>
                <b>Opened Users:</b>{' '}
                {Number(
                  (openedUsers.data[index]?.value ?? 0) / reached
                ).toLocaleString(undefined, {
                  style: 'percent',
                  minimumFractionDigits: 0,
                })}
              </span>
            )}
            {engagedUsers && (
              <span className={styles.item}>
                <b>Engaged Users:</b>{' '}
                {Number(
                  (engagedUsers.data[index]?.value ?? 0) / reached
                ).toLocaleString(undefined, {
                  style: 'percent',
                  minimumFractionDigits: 0,
                })}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const color = scaleOrdinal()
    .domain(filteredGraphData.map((d) => d.label))
    .range([
      'rgba(254, 209, 94, 1)',
      'rgba(182, 228, 109, 1)',
      'rgba(112, 214, 236, 1)',
    ]);

  const generateKeys = (lineData: LineData[]) => {
    return lineData.map((line) => {
      return (
        <div
          className={styles.metricCardLegendLabel}
          key={`key-${line.label}`}
          onMouseEnter={() => setHighlighted(line.label)}
          onMouseLeave={() => setHighlighted(undefined)}
        >
          <svg
            className={cx(styles.icon, styles.circle)}
            width={10}
            height={10}
            viewBox="0 0 100 100"
          >
            <circle cx="50" cy="50" r="50" fill={color(line.label) as string} />
          </svg>
          <span>{line.label}</span>
        </div>
      );
    });
  };

  const drawLedgerLine = (
    text: string,
    x: number,
    y: number,
    width: number
  ) => (
    <g>
      <text
        x={x - 16}
        y={y}
        textAnchor="end"
        dominantBaseline="middle"
        fill="rgba(3, 2, 41, 0.7)"
        fontSize={12}
      >
        {text}
      </text>
      <line x1={x} y1={y} x2={width} y2={y} stroke="rgba(3, 2, 41, 0.05)" />
    </g>
  );

  const drawLedgerLines: CustomDrawingFunction = ({
    chartDimensions,
    scaleY,
    scaleX,
  }) => {
    const {
      width,
      padding: { left, right },
    } = chartDimensions;
    if (scaleX === undefined || scaleY === undefined) {
      return <></>;
    }

    const startX = scaleX(0 as never) || 0;
    const endX = width - left - right;
    const scale = reached / 5;

    const lines = [];
    for (let i = 0; i <= 5; i += 1) {
      lines.push(
        drawLedgerLine(
          `${i * 20}%`,
          startX,
          scaleY((scale * i) as never) || 0,
          endX
        )
      );
    }

    return <>{lines}</>;
  };

  const drawDayMarkers: CustomDrawingFunction = ({
    chartDimensions,
    scaleX,
  }) => {
    const {
      height,
      padding: { bottom },
    } = chartDimensions;
    if (scaleX === undefined) {
      return <></>;
    }
    const fontSize = 12;
    const {
      width,
      padding: { left, right },
    } = chartDimensions;

    const startX = scaleX(0 as never) || 0;
    const endX = width - left - right;

    return (
      <>
        <text
          x={startX}
          y={height - bottom}
          dominantBaseline="hanging"
          fill="rgba(3, 2, 41, 0.7)"
          fontSize={fontSize}
        >
          Day 0
        </text>
        <text
          x={endX}
          y={height - bottom}
          textAnchor="end"
          dominantBaseline="hanging"
          fill="rgba(3, 2, 41, 0.7)"
          fontSize={fontSize}
        >
          Day {currentDay.toFixed(0)}
        </text>
      </>
    );
  };

  const drawCustomAxis: CustomDrawingFunction = ({
    chartDimensions,
    scaleX,
    scaleY,
  }) => {
    return (
      <>
        {drawLedgerLines({ chartDimensions, scaleY, scaleX })}
        {drawDayMarkers({ chartDimensions, scaleX })}
      </>
    );
  };

  const domain: [number, number] = [0, reached];

  return (
    <div className={cx(styles.metricCard, styles.metricCardFull)}>
      <div className={styles.metricCardHeader}>
        <div className={styles.row}>
          <h1 className={styles.metricCardText}>Performance Trend</h1>
          <div className={styles.metricCardLegend}>
            {generateKeys(filteredGraphData)}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <LineChart
            lines={verifiedData}
            highlightedLine={highlighted}
            domain={domain}
            customColors={color}
            customDrawing={drawCustomAxis}
            chartDimensions={dimensions}
            toolTipOverride={showToolTip}
            hideXAxis
            hideYAxis
          />
          {drawToolTip()}
        </div>
      )}
    </div>
  );
};
