import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { usePublisher } from 'contexts/publisher';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { useCurrentUserQuery } from 'hooks/user';
import { useInfiniteApiQuery } from 'hooks/common';
import { useContextCommunication } from 'hooks/context_communication';
import { Post } from 'models/publisher/post';
import { MAX_PREVIEW_TEXT_SIZE } from 'models/context-communication';
import { fetchStudioContactPage } from 'services/api-studio-contacts';
import { Button } from 'shared/Button';
import { FocusDropdown } from 'shared/FocusDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Modal } from 'shared/Modal';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { VariableTextInput } from 'shared/VariableTextInput';
import { fullName } from 'models/user';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useSendTestEmail } from 'hooks/useSendTestEmail';
import styles from './send-preview-email-modal.module.css';

type SendPreviewEmailModalProps = {
  post: Post;
  onClose: () => void;
};

const MAX_NUMBER_RECIPIENTS = 100;

export const SendPreviewEmailModal: React.FC<SendPreviewEmailModalProps> = ({
  post,
  onClose,
}) => {
  const { id: programId } = useProgram();
  const currentUserData = useCurrentUserQuery().data;
  const currentUser = {
    id: currentUserData?.id ?? 0,
    email: currentUserData?.email ?? '',
    firstName: currentUserData?.firstName ?? '',
    lastName: currentUserData?.lastName ?? '',
  };
  const { setFlashMessage } = useFlashMessage();
  const { data: communicationMentionsData } = useContextCommunication(
    programId,
    false
  );

  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<
    {
      id: number;
      email: string;
      firstName: string;
      lastName: string;
    }[]
  >(currentUser ? [currentUser] : []);
  const [subjectLine, setSubjectLine] = useState(
    post.settings.notifications[0].text || post.callToAction.title
  );
  const [previewText, setPreviewText] = useState(
    post.settings.notifications[0].previewText || ''
  );

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const debouncedSearch = useDebounce(search);

  const {
    data: studioContactsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery('studio_contacts', fetchStudioContactPage, {
    programId,
    query: debouncedSearch,
  });

  const userSearchResults: typeof selectedUsers = React.useMemo(
    () =>
      studioContactsData.map((contact) => ({
        id: contact.userId,
        email: contact.email ?? '',
        firstName: contact.displayName,
        lastName: '',
      })),
    [studioContactsData]
  );
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );
  const { liquidVariables } = usePublisher();
  const { sendEmail } = useSendTestEmail({
    subject: subjectLine,
    previewText,
    post,
    userIds: selectedUsers.map((u) => u.id),
    preferOutlook365,
    type: 'campaign',
    onSuccess: () => {
      onClose();

      if (!selectedUsers || selectedUsers.length === 0) {
        return;
      }
      let additionalEmailsMessage = '';
      if (selectedUsers.length > 1) {
        additionalEmailsMessage = ` and ${
          selectedUsers.length - 1
        } other recipient(s)`;
      }
      const message = `Test email sent to ${selectedUsers[0].email}${additionalEmailsMessage}.`;
      setFlashMessage({
        severity: 'info',
        message,
      });
    },
    liquidVariables,
  });

  useEffect(() => {
    const handleEscKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setSearch('');
      }
    };
    window.addEventListener('keydown', handleEscKeyDown);

    return () => {
      window.removeEventListener('keydown', handleEscKeyDown);
    };
  }, []);

  const maxUsersSelected = selectedUsers.length >= MAX_NUMBER_RECIPIENTS;

  const renderRow = useCallback(
    (id: string) => {
      const user = userSearchResults.find((u) => u.id.toString() === id);
      if (!user) {
        return <></>;
      }
      return (
        <div key={id} className={styles.row}>
          <div>{fullName(user)}</div>
          <div className={styles.userEmail}>{user.email}</div>
        </div>
      );
    },
    [userSearchResults]
  );

  const dropdown = useMemo(
    () =>
      debouncedSearch === '' || maxUsersSelected ? (
        <></>
      ) : (
        <InfiniteSelect
          className={styles.dropdown}
          rowIds={userSearchResults.map((d) => d.id.toString())}
          rowRenderProp={renderRow}
          maxHeight={200}
          itemHeight={50}
          selectedIds={selectedUsers.map((u) => u.id.toString())}
          onSelectedIdsChange={(ids) => {
            const newIdsInSet = new Set(ids.map((id) => +id));
            const newSelectedUsers: typeof userSearchResults = [];
            selectedUsers.forEach((u) => {
              if (!newIdsInSet.has(u.id)) {
                return;
              }
              newSelectedUsers.push(u);
              newIdsInSet.delete(u.id);
            });
            newIdsInSet.forEach((id) => {
              const user = userSearchResults.find((u) => u.id === id);
              if (!user) {
                return;
              }
              newSelectedUsers.push(user);
            });
            setSelectedUsers(newSelectedUsers);
          }}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          searchTerm={search}
          onSearchTermChange={setSearch}
        />
      ),
    [
      debouncedSearch,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      maxUsersSelected,
      renderRow,
      search,
      selectedUsers,
      userSearchResults,
    ]
  );

  return (
    <Modal title="Send Test Email" showTitle showModal onClose={onClose}>
      <form className={styles.form}>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Subject Line</legend>
          <div className={styles.subjectLineWrapper}>
            <span>[Test Email]</span>
            <div className={styles.subjectLineInput}>
              <VariableTextInput
                text={subjectLine}
                setText={(v) => {
                  setSubjectLine(v);
                }}
                variables={communicationMentionsData || []}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Preview</legend>
          <div className={styles.previewTextWrapper}>
            <div className={styles.previewTextInput}>
              <VariableTextInput
                text={previewText}
                setText={(v) => {
                  setPreviewText(v);
                }}
                variables={communicationMentionsData || []}
                maxSize={MAX_PREVIEW_TEXT_SIZE}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Recipients</legend>
          <FocusDropdown dropdownRenderProp={dropdown}>
            {(onFocus, ref) => (
              <MultiValueTextInput
                textValue={maxUsersSelected ? '' : search}
                onTextValueChange={setSearch}
                selectedValues={selectedUsers.map((u) => u.email ?? '')}
                onRemoveSelectedValueAt={(index) => {
                  const newSelectedUsers = [...selectedUsers];
                  newSelectedUsers.splice(index, 1);
                  setSelectedUsers(newSelectedUsers);
                }}
                onFocus={onFocus}
                inputRef={ref}
                disabled={maxUsersSelected}
              />
            )}
          </FocusDropdown>
        </fieldset>
        <div className={styles.buttonsWrapper}>
          <Button
            type="primary"
            disabled={
              isSendingEmail ||
              subjectLine.length <= 0 ||
              previewText.length > MAX_PREVIEW_TEXT_SIZE ||
              selectedUsers.length === 0
            }
            onClick={() => {
              setIsSendingEmail(true);
              sendEmail();
            }}
          >
            Send
          </Button>
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className={styles.statusWrapper}>
          {isSendingEmail && (
            <>Sending to {selectedUsers.length} recipient(s)...</>
          )}
        </div>
      </form>
    </Modal>
  );
};
