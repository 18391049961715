import React, { FC, useCallback, useMemo } from 'react';
import { Avatar, FeedPreviewCard } from '@socialchorus/shared-ui-components';
import { useFeedPreviewQuery } from 'hooks/feedPreview';
import { useTopicApiId } from 'App/Program/Configuration/Topics/TopicV2/useTopicApiId';
import { Topic } from 'models/topic';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';
import { useTopicFormCtx } from '../../../../../../context';
import styles from './styles.module.css';
import { formatPublishDate } from '../../../Posts/Post';
import usePinnedFeedPreview from '../../../Posts/usePinnedFeedPreview';

function Placeholder() {
  return (
    <CardPlaceholder>
      There are no posts in this topic yet. Posts can be added from the Campaign
      Page.
    </CardPlaceholder>
  );
}

function Preview({ topic }: { topic: Topic }) {
  const {
    state: { fields },
  } = useTopicFormCtx();

  const apiTopicId = useTopicApiId(topic);

  const { isLoading, data } = useFeedPreviewQuery(apiTopicId);
  const posts = data?.data;

  const {
    isLoading: isPinnedLoading,
    data: pinnedPosts,
  } = usePinnedFeedPreview(apiTopicId, fields.pinnedPosts);

  const unpinnedPreviews = useMemo(() => {
    const pinnedItems = (pinnedPosts || []).reduce((acc, curr) => {
      acc[curr.id] = true;
      return acc;
    }, {} as Record<number, boolean>);

    return (posts ?? []).filter((p) => !pinnedItems[p.id]);
  }, [posts, pinnedPosts]);

  if (isLoading || isPinnedLoading) {
    return null;
  }

  const previewData = [...pinnedPosts, ...unpinnedPreviews];

  if (!previewData?.length) {
    return <Placeholder />;
  }

  return (
    <div className={styles.postPreviewContainer}>
      {previewData.slice(0, 3).map((post) => {
        const { id, title, summary, contentAuthor, publishedAt, pinned } = post;
        const { avatarUrl, displayName } = contentAuthor;
        return (
          <FeedPreviewCard
            key={id}
            authorAvatarComponent={
              <Avatar imgSrc={avatarUrl} shape="circle" size="small" />
            }
            authorName={displayName}
            pinned={pinned}
            datetime={
              (publishedAt && formatPublishDate(new Date(publishedAt))) || ''
            }
            content={title || summary || 'No description'}
          />
        );
      })}
    </div>
  );
}

const PostsPreview: FC = () => {
  const { topic, selectTab } = useTopicFormCtx();
  let content;
  if (!topic) {
    content = <Placeholder />;
  } else {
    content = <Preview topic={topic} />;
  }

  const handleViewAll = useCallback(() => {
    selectTab('posts');
  }, [selectTab]);

  return (
    <PreviewCard
      title="Posts"
      link={<CardLink onClick={handleViewAll}>View all</CardLink>}
    >
      {content}
    </PreviewCard>
  );
};

export default PostsPreview;
