import React, { useCallback } from 'react';
import { DeliveryChannels } from 'models/channel';
import { Checkbox } from 'shared/Checkbox';
import { Box } from 'DesignSystem/Components';
import { Body } from 'DesignSystem/Typography';
import { Subsection } from 'DesignSystem/Form';
import { DeliveryChannelOption } from './useDefaultChannels';
import styles from './default-channels.module.css';

export const DefaultChannelsEdit: React.FC<{
  channelsValues: Array<DeliveryChannelOption>;
  channels: DeliveryChannels;
  setChannels: (channels: DeliveryChannels) => void;
}> = ({ channelsValues, channels, setChannels }) => {
  const onChannelChange = useCallback(
    ({ name, value }) => {
      setChannels({
        ...channels,
        [name]: value,
      });
    },
    [channels, setChannels]
  );

  return (
    <Subsection title="Channels">
      <Box margin={[30, 0, 0, 0]}>
        {channelsValues.map(({ name, label, icon }) => (
          <Box
            key={name}
            id={name}
            margin={[0, 0, 20, 10]}
            className={styles.checkboxLabel}
          >
            <span
              data-test={`channel-settings-${name}`}
              key={`channel-settings-${name}`}
            >
              <Checkbox
                name={name}
                onChange={(v) => onChannelChange({ name, value: v })}
                checked={channels[name]}
                label={
                  <Body>
                    <span className={styles.Label}>
                      <span className={styles.Icon}>{icon}</span>
                      <span>{label}</span>
                    </span>
                  </Body>
                }
              />
            </span>
          </Box>
        ))}
      </Box>
    </Subsection>
  );
};
