import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Select, { components, ValueType } from 'react-select';
import { MenuProps } from 'react-select/src/components/Menu';
import { DateTimeInput } from 'shared/DateTimeInput';
import { selectComponents, selectStyles, DisabledSelect } from '../Select';
import styles from './DateSelect.module.css';

function selectDateFormat(ignoreYear: boolean): string {
  return ignoreYear ? 'MM-dd' : 'yyyy-MM-dd';
}

export const DateTimeSelect: React.FC<{
  onChange: (value?: string) => void;
  operator: string;
  value?: string;
  criterion: string;
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
  isDisabled?: boolean;
}> = ({
  onChange,
  value,
  criterion,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
  isDisabled,
}) => {
  const [dateFormat, setDateFormat] = useState<string>(
    selectDateFormat(ignoreYear)
  );
  const [selectValue, setSelectValue] = useState<
    ValueType<{ label: string; value: string }>
  >();

  let initialSelectedDate;

  if (!value) {
    initialSelectedDate = DateTime.now();
  } else {
    let tmp1 = value || '';
    let tmp2 = dateFormat;
    const [tmpDatePart, tmpSpecialPart] = tmp1.split(':');
    if (tmpSpecialPart && tmpSpecialPart === 'ignore_year' && tmpDatePart) {
      tmp1 = `${new Date().getFullYear()}-${tmpDatePart}`;
      tmp2 = `yyyy-${tmp2}`;
      initialSelectedDate = DateTime.fromFormat(tmp1, tmp2);
    } else {
      initialSelectedDate = DateTime.now();
    }
  }

  const [selectedDate, setSelectedDate] = useState<DateTime>(
    initialSelectedDate
  );

  useEffect(() => {
    const todayStr = DateTime.now().toFormat(dateFormat);
    let dateSelected = DateTime.now();

    let tmpValue = value || '';
    let tmpDateFormat = dateFormat;
    const [dt, tmpIgnoreYear] = tmpValue.split(':');
    const ignoreYearCriterion =
      criterion === 'startday' || criterion === 'birthday';
    if (
      dt &&
      ((tmpIgnoreYear && tmpIgnoreYear === 'ignore_year') ||
        ignoreYearCriterion)
    ) {
      tmpValue = `${new Date().getFullYear()}-${dt}`;
      tmpDateFormat = `yyyy-${tmpDateFormat}`;
    }

    if (tmpValue && Date.parse(tmpValue)) {
      // TODO: `value ||` !helpful here.
      dateSelected = DateTime.fromFormat(
        tmpValue || todayStr,
        tmpDateFormat
      ) as DateTime<true>;
    }

    const formattedDate = dateSelected.toFormat(dateFormat);

    setSelectedDate(dateSelected);
    if (tmpValue) {
      setSelectValue({
        label: formattedDate,
        value: formattedDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const formattedDate = selectedDate.toFormat(dateFormat);

    setSelectValue({
      label: formattedDate,
      value: formattedDate,
    });

    // onChange(formattedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dateFormat]);

  useEffect(() => {
    setDateFormat(selectDateFormat(ignoreYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreYear, setDateFormat]);

  const handleDateChange = (e: DateTime) => {
    const formattedDate = e.toFormat(dateFormat);
    onChange(formattedDate);
    setSelectedDate(e);
    setSelectValue({ value: formattedDate, label: formattedDate });
  };

  const Menu: React.FC<MenuProps<{ value: string; label: string }>> = (
    props
  ) => {
    return (
      <div>
        <div className={styles.offsetSpacer} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <components.Menu {...props} className={styles.dateTimeMenuWrapper}>
          <DateTimeInput
            value={selectedDate}
            hideTime
            onChange={handleDateChange}
            ignoreYearFlag={ignoreYearFlag}
            ignoreYear={ignoreYear}
            toggleIgnoreYear={toggleIgnoreYear}
          />
        </components.Menu>
      </div>
    );
  };

  if (isDisabled) {
    return <DisabledSelect placeholder={value || 'Value'} />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select
      value={selectValue}
      options={[]}
      components={{ ...selectComponents, Menu }}
      styles={{
        ...selectStyles,
        menu: (provided: React.CSSProperties) => ({
          ...provided,
          width: 'auto',
          backgroundColor: 'var(--color-gray00)',
          cursor: 'pointer',
        }),
      }}
    />
  );
};
