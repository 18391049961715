import * as React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { Statuses } from 'models/workflow-templates';
import { EditPage } from './EditPage';
import { ListPage } from './ListPage';
import { CreatePage } from './CreatePage';

export const WorkflowTemplates: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['workflowTemplatesAccess']}>
    <Router>
      {Statuses.map((status) => (
        <ListPage key={status} path={status} status={status} />
      ))}
      <CreatePage path="new" />
      <EditPage path="edit/:id" />
      <Redirect from="/" to={Statuses[0]} noThrow />
      <Redirect from="*" to="/" noThrow default />
    </Router>
  </WithPermission>
);
