import { BulkUploadJob } from 'models/bulk-upload-job';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';

export const fetchBulkUploadById = async (
  programId: number,
  id: string
): Promise<BulkUploadJob> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs/${id}`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching bulk upload: ${response.status}`);
};
