import * as React from 'react';
import cx from 'classnames';
import { Icon } from 'shared/Icon';
import styles from './pills-input.module.css';

type PropsType = {
  values: string[];
  onSelectedValuesChange: (values: string[]) => void;
  onRemoveSelectedValueAt?: (index: number) => void;
  onBlur?: (value: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  className?: string;
  callToAction?: string;
  onFocus?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  maximum?: number;
  id?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'>;

export const PillsInput: React.FC<PropsType> = (props) => {
  const {
    className,
    disabled,
    hasError,
    id,
    onSelectedValuesChange,
    onRemoveSelectedValueAt,
    onFocus,
    placeholder,
    maximum,
    values,
    callToAction,
    inputRef: suppliedInputRef,
    onBlur: suppliedOnBlur,
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const [textValue, setTextValue] = React.useState('');
  const myRef = React.useRef<HTMLInputElement>(null);
  const inputRef = suppliedInputRef || myRef;

  const handleChange = React.useCallback(() => {
    const addedTextValue = textValue.replace(/\s+$/, '');
    if (addedTextValue.length > 0) {
      const newValues = [...(values as string[])];
      newValues.push(addedTextValue);
      onSelectedValuesChange(newValues);
      setTextValue('');
    }
  }, [textValue, onSelectedValuesChange, values]);

  const handleTextInputFocus = React.useCallback(() => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleTextInputBlur = React.useCallback(
    (_: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (suppliedOnBlur) {
        suppliedOnBlur(values);
      }
      handleChange();
    },
    [handleChange, suppliedOnBlur, values]
  );

  const showCallToAction = React.useMemo(
    () =>
      callToAction &&
      callToAction.length > 0 &&
      values.length === 0 &&
      !isFocused,
    [callToAction, values, isFocused]
  );

  const hideCallToAction = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      handleTextInputFocus();
    }
  }, [handleTextInputFocus, inputRef]);

  const placeholderText = React.useMemo(() => {
    if (callToAction && showCallToAction) {
      if (isFocused) {
        return placeholder;
      }
      return '';
    }
    return placeholder;
  }, [isFocused, callToAction, placeholder, showCallToAction]);

  const handleTextValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextValue(e.target.value);
    },
    []
  );

  function handleRemove(index: number) {
    hideCallToAction();
    if (onSelectedValuesChange) {
      onSelectedValuesChange(values.filter((_value, i) => index !== i));
    }
    if (onRemoveSelectedValueAt) {
      onRemoveSelectedValueAt(index);
    }
  }

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleChange();
      }
    },
    [handleChange]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <div className={cx(styles.multiValueTextInput, { focus: isFocused })}>
      {showCallToAction && (
        <button
          type="button"
          onClick={hideCallToAction}
          className={styles.callToAction}
        >
          {callToAction}
        </button>
      )}
      {values.map((val, index) => (
        <div className={styles.selected} key={`pill-button-${val}`}>
          <span>{val}</span>
          <button
            type="button"
            className={styles.remove}
            onClick={() => handleRemove(index)}
          >
            <Icon iconName="Times" size={12} iconType="SVG" />
          </button>
        </div>
      ))}
      <input
        id={id}
        type="text"
        ref={inputRef}
        className={cx(styles.input, className, { [styles.error]: hasError })}
        onChange={handleTextValueChange}
        onBlur={handleTextInputBlur}
        value={textValue}
        placeholder={placeholderText}
        disabled={disabled}
        onFocus={onFocus}
        maxLength={maximum}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...inputProps}
      />
    </div>
  );
};
