import * as React from 'react';
import cx from 'classnames';
import { AbsoluteRange, RelativeRange } from './DateRange';
import { DateRangeInputActionsType, DateRangeInputStateType } from './types';
import styles from './summary-control.module.css';
import { AbsoluteControl } from './AbsoluteControl';
import { RelativeControl } from './RelativeControl';

type PropsType = {
  actions: DateRangeInputActionsType;
  className?: string;
  dateRange: DateRangeInputStateType['dateRange'];
  selectedDate: DateRangeInputStateType['selectedDate'];
};

export const SummaryControl: React.FC<PropsType> = (props) => {
  const { actions, className, dateRange, selectedDate } = props;

  return (
    <div className={cx(styles.control, className)}>
      {dateRange instanceof RelativeRange ? (
        <RelativeControl dateRange={dateRange} />
      ) : (
        <AbsoluteControl
          dateRange={dateRange as AbsoluteRange}
          selectedDate={selectedDate}
          actions={actions}
        />
      )}
    </div>
  );
};
