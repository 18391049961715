import * as React from 'react';
import styles from './liquid-variables.module.css';

export function useDefaultValueForm(): void {
  let openedAt = 0;
  const popup = document.createElement('div');
  const POPUP_CLASS = 'liquid-variables-popup';
  const UNIQUE_ID = 'data-liquid-unique-id';
  popup.className = `${styles.popup} ${POPUP_CLASS}`;
  popup.id = POPUP_CLASS;
  popup.style.display = 'none';

  function showForm(event: MouseEvent) {
    const target = event.target as Element;
    if (target.classList.contains('variable')) {
      document.body.appendChild(popup);
      const rect = target.getBoundingClientRect();
      const defaultValue = target.getAttribute('data-default-value');
      const liquidId = target.getAttribute('data-liquid-unique-id');
      popup.innerHTML = `<div class='liquid-default-popup'>
        <span>Set Default Value</span>
        <span>
          <span>
            <input id='default-value-input' placeholder="Placeholder text" data-liquid-unique-id=${liquidId} value="${defaultValue}" />
          </span>
          <span class='liquid-default-popup-buttons'>
            <button id='liquid-default-cancel'>Cancel</button>
            <button id='liquid-default-confirm'>Save</button>
          </span>
        </span>
      </div>`;
      const { top, left } = {
        top: rect.top + 23,
        left: rect.left + 13,
      };
      popup.style.top = `${top}px`;
      popup.style.left = `${left}px`;
      popup.style.display = 'block';
      openedAt = new Date().getTime();
    }
  }

  const hideForm = React.useCallback(() => {
    popup.style.display = 'none';
  }, [popup.style]);

  const acceptAndClose = React.useCallback(() => {
    popup.style.display = 'none';
    const input = Array.from(popup.getElementsByTagName('input'))[0];
    const liquidId = input.getAttribute(UNIQUE_ID);
    const newValue = input.value;
    const target = document.querySelector(
      `[${UNIQUE_ID}='${liquidId}']`
    ) as Element | null;
    if (target) {
      const initialDefaultValue =
        target.getAttribute('data-default-value') || '';
      const initialContent = target.getAttribute('data-content') || '';
      const [name, defaultValue] = initialContent
        .replace(/[{()}]/g, '')
        .split('|')
        .map((s) => s.trim());
      const newDefaultValue =
        defaultValue && defaultValue !== ''
          ? defaultValue.replace(initialDefaultValue, newValue).trim()
          : newValue;
      target.setAttribute('data-default-value', newValue);
      target.setAttribute(
        'data-content',
        `{\u00a0${name}${newDefaultValue ? ` | ${newDefaultValue}` : ''}\u00a0}`
      );
      const innerSpan = target.firstElementChild;
      if (innerSpan)
        innerSpan.textContent = `{{${name} | default: "${newDefaultValue}"}}`;
      // dispatch triggerContentChanged to make Froala register the changes
      target
        .closest('.fr-box')
        ?.dispatchEvent(
          new CustomEvent('triggerFroalaContentChanged', { detail: liquidId })
        );
    }

    hideForm();
  }, [hideForm, popup]);

  const onWindowClick = React.useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (popup.style.display === 'none') return;
      if (popup.contains(target)) {
        if (target.id === 'liquid-default-confirm') {
          acceptAndClose();
        }
        if (target.id === 'liquid-default-cancel') {
          hideForm();
        }
        return;
      }
      if (new Date().getTime() - openedAt < 300) {
        return;
      }
      hideForm();
    },
    [acceptAndClose, hideForm, openedAt, popup]
  );
  React.useEffect(() => {
    document.addEventListener('click', showForm);
    document.addEventListener('click', onWindowClick);
    return () => {
      document.removeEventListener('click', showForm);
      document.removeEventListener('click', onWindowClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
