import React from 'react';
import Select from 'react-select';
import { Pills } from 'DesignSystem/Components/Pills';
import * as Text from 'DesignSystem/Typography';
import { OptionType } from 'hooks/common';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import styles from './styles.module.css';

type PropsType = {
  onChange: (permissions: string[]) => void;
  value: string[];
};

export const PermissionsSelector: React.FC<PropsType> = ({
  onChange,
  value,
}) => {
  const options = [
    { value: 'topics', label: 'Topics' },
    { value: 'audiences', label: 'Audiences' },
    { value: 'templates', label: 'Templates' },
    { value: 'email-aliases', label: 'Email Aliases' },
    { value: 'author-aliases', label: 'Author Aliases' },
  ];

  const selected = options.filter((opt) => value.includes(opt.value));

  return (
    <>
      <Text.Subheading block bold>
        Select Permissions
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select at least one permission
      </Text.Body>
      <Text.Body>
        <Select
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          styles={{
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '300px',
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              width: '300px',
              marginTop: '16px',
            }),

            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: 'var(--color-gray90)',
            }),

            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              color: 'var(--color-gray90)',
            }),
          }}
          placeholder="Select Permissions"
          options={options}
          value={selected}
          isMulti
          controlShouldRenderValue={false}
          onChange={(opts) => {
            const values = (opts as OptionType[]).map((opt) => opt.value);
            onChange(values);
          }}
        />
      </Text.Body>
      <Box margin={[16, 0, 0, 0]}>
        <Flex start className={styles.PillsContainer}>
          {selected.map((opt, i) => (
            <Pills
              key={opt.value}
              values={[{ id: i }]}
              render={() => <>{opt.label}</>}
              onClose={(p) => {
                const index = p.id;
                const values = [
                  ...value.slice(0, index),
                  ...value.slice(index + 1),
                ];

                onChange(values);
              }}
              padding={[0, 12, 0, 8]}
            />
          ))}
        </Flex>
      </Box>
    </>
  );
};
