import React, { useMemo } from 'react';
import qs from 'qs';
import { RouteComponentProps, useLocation } from '@reach/router';
import { JourneyProvider } from 'contexts/journeys/journey';
import { useProgram } from 'contexts/program';
import { useNewJourneyQuery } from 'hooks/journeys/journeys';
import { LoadingScreen } from 'shared/LoadingScreen';
import { JourneyMode } from 'models/journeys/journey';
import { clone } from 'utility/deep-merge';
import { JourneyCanvas } from '../JourneyCanvas';

export const JourneyNewPage: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const templateId = params.template_id
    ? Number(params.template_id)
    : undefined;
  const { id: programId } = useProgram();
  const { data } = useNewJourneyQuery({
    programId,
    templateId,
  });

  const journey = useMemo(() => (data ? clone(data) : data), [data]);
  if (!journey) {
    return <LoadingScreen />;
  }

  return (
    <JourneyProvider journey={journey} mode={JourneyMode.Edit}>
      <JourneyCanvas />
    </JourneyProvider>
  );
};
