import React from 'react';
import {
  VoteType,
  Feedback,
  Vote,
  FeedbackModal,
  FeedbackModalBody,
  FeedbackFactory,
  FeedbackStyle,
} from '@socialchorus/critique-ui';
import { postFeedback } from 'services/api-critique';
import { useProgram } from 'contexts/program';
import { Box, Modal } from 'DesignSystem/Components';
import { styles } from 'DesignSystem/Form';
import { useFeedback } from './FeedbackProviderInMemory';

export interface CollectFeedbackProps {
  type: FeedbackStyle;
}

export const CollectFeedbackRow: React.FC<CollectFeedbackProps> = ({
  type,
}) => {
  const { id: programId } = useProgram();
  const { feature, task_id } = useFeedback();

  const handleVote = async (vote: VoteType): Promise<boolean> => {
    try {
      await postFeedback({
        tenant: `program:${programId}`,
        vote,
        feature,
        reference_type: 'athena_task',
        reference: task_id,
      });
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleComment = async (comment_text: string): Promise<boolean> => {
    try {
      await postFeedback({
        tenant: `program:${programId}`,
        comment_text,
        feature,
        reference_type: 'athena_task',
        reference: task_id,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  // only show feedback if we have a page defined
  const show = task_id !== '';
  if (!show) return <></>;
  // Get the correct feedback style based on the type
  const Format = FeedbackFactory(type);

  return (
    <Feedback>
      <Format>
        <Vote
          request="Were the results what you expected?"
          response="Thank you for your vote!"
          link="Leave Feedback"
          finished="Thanks, your feedback has been recorded"
          onVote={handleVote}
        />
      </Format>
      <FeedbackModal>
        <Modal name="edit-role-modal" width={960} innerPadding={0}>
          <div className={styles.EditRoleModal}>
            <Box padding={20}>
              <FeedbackModalBody
                title="Firstup AI Feedback"
                message="Please leave any comments or suggestions abour your experience with our AI search tool. Your feedback is important to allow us to improve our features. Thank you."
                placeholder="Comment"
                onComment={handleComment}
              />
            </Box>
          </div>
        </Modal>
      </FeedbackModal>
    </Feedback>
  );
};
