import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  InfiniteQueryResponse,
  nextPageToFetch,
  QueryResponse,
  MutationOptions,
} from './common';
import { SearchMetaTag } from '../models/search-meta-tag';
import {
  fetchSearchMetaTags,
  FetchProps,
  SearchMetaTagsCollectionData,
  SearchMetaTagData,
  createSearchMetaTag,
} from '../services/api-search-meta-tags';

export function mapServerDataToSearchMetaTags(
  serverData: SearchMetaTagsCollectionData
): Array<SearchMetaTag> {
  return serverData.data.map((entity: SearchMetaTagData) => entity.attributes);
}

type QueryResponseData = {
  data: Array<SearchMetaTag>;
  meta?: SearchMetaTagsCollectionData['meta'];
};

const QUERY_KEY = 'meta-tags';
const INFINITE_QUERY_KEY = 'meta-tags-infinite';

export const useSearchMetaTagsQuery = (
  props: FetchProps
): QueryResponse<Array<SearchMetaTag>> => {
  const { isLoading, error, data } = useQuery<
    SearchMetaTagsCollectionData,
    Error,
    QueryResponseData
  >([QUERY_KEY, { ...props }], () => fetchSearchMetaTags(props), {
    retry: false,
    select: (d) => ({
      data: mapServerDataToSearchMetaTags(d),
      meta: d.meta,
    }),
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data || [],
  };
};

type InfiniteQueryResponseData = {
  tags: SearchMetaTag[];
  meta?: SearchMetaTagsCollectionData['meta'];
};

// infinite query hook for the searchSearchMetaTags api
// based off of useInitiativesInfiniteQuery()
export const useSearchMetaTagsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<SearchMetaTag> => {
  const { programId, query, pageSize = 10 } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<
    SearchMetaTagsCollectionData,
    Error,
    InfiniteQueryResponseData
  >(
    [INFINITE_QUERY_KEY, JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchSearchMetaTags({
        programId,
        query,
        page: pageParam as number,
        pageSize,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, pageSize),
      select: (d) => ({
        pages: d.pages.map((batch) => ({
          tags: batch.data.map(
            (searchSearchMetaTagData) => searchSearchMetaTagData.attributes
          ),
          meta: batch.meta,
        })),
        pageParams: d.pageParams,
        meta: d.pages[0].meta,
      }),
    }
  );

  const flatData =
    data && data.pages.map((batch) => (batch ? batch.tags : [])).flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

export const useCreateSearchMetaTag = (
  programId: number,
  {
    onSuccess = () => {},
    onError = () => {},
  }: MutationOptions<SearchMetaTag, Error> = {}
): {
  mutate: (searchSearchMetaTag: Partial<SearchMetaTag>) => void;
} => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation<SearchMetaTag, Error, Partial<SearchMetaTag>>(
    (searchSearchMetaTag: Partial<SearchMetaTag>) => {
      return createSearchMetaTag(programId, searchSearchMetaTag);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(INFINITE_QUERY_KEY);
        queryClient.invalidateQueries(QUERY_KEY);
        onSuccess(data);
      },
      onError,
    }
  );
  return { mutate };
};
