import React, { RefObject } from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { LitmusRole } from 'models/role';
import { PaginationData } from 'services/common';
import { RolesBanner } from './RoleBanner';

type PropsType = {
  roles: Array<LitmusRole>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  errorMessage?: string;
  parentRef: RefObject<HTMLDivElement>;
  meta?: PaginationData;
  height: number;
};

export const RolesList: React.FC<PropsType> = (props) => {
  const {
    isLoading,
    roles,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    parentRef,
    height,
  } = props;

  const itemCount = roles.length || 0;

  return (
    <>
      <InfiniteBannerList
        itemCount={itemCount}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        height={height}
        parentRef={parentRef}
        noItemsComponent={<div>No roles match that criteria.</div>}
      >
        {(index: number) => <RolesBanner data={roles[index]} />}
      </InfiniteBannerList>
    </>
  );
};
