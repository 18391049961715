import * as React from 'react';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { EditorProps } from './useEditor';
import { Editor } from './Editor';

export const Modal: React.FC<EditorProps> = ({
  block,
  blockId,
  field,
  hideBlockEditor,
  onChangeData,
}) => (
  <SlideIn name="block-editor">
    {(slideOut) => (
      <Editor
        hideBlockEditor={() => slideOut(hideBlockEditor)}
        block={block}
        blockId={blockId}
        field={field}
        onChangeData={onChangeData}
      />
    )}
  </SlideIn>
);
