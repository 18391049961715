import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Post } from 'models/publisher/post';
import { Block, Row, Section } from 'shared/SectionBlocks';
import cx from 'classnames';
import styles from './summary.module.css';
import { ErrorList } from './ErrorList';
import { People } from './People';
import { BlockContent } from './Blocks';
import { Publish } from './Publish';
import { SuccessMessage } from './SuccessMessage';

export const Summary: React.FC<
  {
    post: Post;
    isValid: boolean;
    errors: Array<string>;
  } & RouteComponentProps
> = ({ post, isValid, errors }) => {
  return (
    <div className={styles.container}>
      <Section className={cx(styles.errorWrapper, styles.errors)} title="">
        {isValid ? (
          <SuccessMessage settings={post.settings} />
        ) : (
          <ErrorList errors={errors} />
        )}
        <Row>
          <Block className={styles.blockspace}>
            <People />
          </Block>
          <Block className={styles.blockspace}>
            <Publish post={post} />
          </Block>
          <Block className={styles.blockspace}>
            <BlockContent post={post} />
          </Block>
        </Row>
      </Section>
    </div>
  );
};
