import React from 'react';
import { Box } from 'DesignSystem/Components';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Body, color } from 'DesignSystem/Typography';

export const Metrics: React.FC<{ isLoading: boolean }> = ({
  isLoading,
  children,
}) => {
  return (
    <Box width="100%">
      <Box margin={[16, 0]}>
        <hr />
      </Box>
      {isLoading && <LoadingSpinner size="medium" />}
      {!isLoading && <div>{children ?? <UndefinedMetrics />}</div>}
    </Box>
  );
};

const UndefinedMetrics = () => (
  <Body color={color.gray60}>Metrics are available 24 hours after publish</Body>
);
