import * as React from 'react';
import { Channel, WorkflowTemplate } from 'models/workflow-templates';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { ConfirmModal } from 'shared/BulkSelect/Bar/ConfirmModal';
import { useActions } from './useActions';
import {
  workflowTemplateToMenuItems,
  workflowTemplateToThumbnail,
} from './mappings';
import styles from './workflow-templates.module.css';

export const Banner: React.FC<{
  template: WorkflowTemplate;
  index: number;
}> = ({ template, index }) => {
  const actions = useActions();
  const thumbnail = workflowTemplateToThumbnail(template, index);
  const menuItems = workflowTemplateToMenuItems(template, actions);
  const channels = React.useMemo(
    () =>
      (template.multinode ? template.channel : [template.channel]) as Channel[],
    [template.multinode, template.channel]
  );
  const {
    archive,
    promote,
    demote,
    confirmArchive,
    confirmPromote,
    confirmDemote,
  } = actions;

  const handleConfirmArchive = React.useCallback(() => {
    archive(template.id);
    confirmArchive?.closeModal();
  }, [template.id, archive, confirmArchive]);

  const handleConfirmPromote = React.useCallback(() => {
    promote(template.id);
    confirmPromote?.closeModal();
  }, [template.id, promote, confirmPromote]);

  const handleConfirmDemote = React.useCallback(() => {
    demote(template.id);
    confirmDemote?.closeModal();
  }, [template.id, demote, confirmDemote]);

  return (
    <>
      <EmptyBanner
        rowId={`${template.id}`}
        thumbnail={thumbnail}
        menuItems={menuItems}
      >
        <div className={styles.bannerWrapper}>
          <div className={styles.basic}>
            <div className={styles.title} title={template.name}>
              {template.name}
            </div>
            <p className={styles.description} title={template.description}>
              {template.description}
            </p>
          </div>
          <div className={styles.additionalWrapper}>
            <div className={styles.additionalCol}>
              <span className={styles.additionalHeader}>SETTINGS</span>
              <span className={styles.additionalInfo}>{template.scope}</span>
              {template.multinode && (
                <span className={styles.additionalInfo}>Multistep</span>
              )}
              {template.enabledForContributors && (
                <span className={styles.additionalInfo}>
                  Enabled for Contributors
                </span>
              )}
            </div>
            <div className={styles.additionalCol}>
              <span className={styles.additionalHeader}>CHANNELS</span>
              <span className={styles.additionalInfo}>
                {channels.join(', ')}
              </span>
            </div>
          </div>
        </div>
      </EmptyBanner>
      {confirmArchive?.showingModal && (
        <ConfirmModal
          headerTitle="Archive Template"
          customTitle="Are you sure you want to archive this template?"
          hideLabel
          onCancel={confirmArchive.closeModal}
          onConfirm={handleConfirmArchive}
        />
      )}
      {confirmPromote?.showingModal && (
        <ConfirmModal
          headerTitle="Promote Template"
          customTitle="Are you sure you want to promote this template?"
          actionTitle="Promoting will make the template visible to other programs."
          hideLabel={false}
          onCancel={confirmPromote.closeModal}
          onConfirm={handleConfirmPromote}
        />
      )}
      {confirmDemote?.showingModal && (
        <ConfirmModal
          headerTitle="Demote Template"
          customTitle="Are you sure you want to demote this template?"
          actionTitle="Demoting will make the template visible to this program only."
          hideLabel={false}
          onCancel={confirmDemote.closeModal}
          onConfirm={handleConfirmDemote}
        />
      )}
    </>
  );
};
