export type FontWeight = 400 | 700;
export type FontStyle = 'normal' | 'italic';
export type FontFamily = string;

export type FontWeightOption = {
  value: FontWeight;
  label: string;
};

export type FontStyleOption = {
  value: FontStyle;
  label: string;
};

export const FontWeightOptions: FontWeightOption[] = [
  { value: 400, label: 'Normal (400)' },
  { value: 700, label: 'Bold (700)' },
];

export const FontStyleOptions: FontStyleOption[] = [
  { value: 'normal', label: 'Regular' },
  { value: 'italic', label: 'Italic' },
];

type FontMetadata = {
  fontFamily?: FontFamily;
  preferredFamily?: FontFamily;
};

export type FontServerData = {
  assetKey: string;
  output: {
    hostedUrl: string;
    metadata?: FontMetadata;
  };
  status: string;
};

export type FontStylesheetData = {
  assetKey: string;
  url: string;
  status: string;
};

export type FontData = {
  assetKey: string;
  url: string;
  filename: string;
  filetype: string;
  filesize: number;
  status: string;
  weight: FontWeight;
  style: FontStyle;
  family?: FontFamily;
};

export const defaultFontData: FontData = {
  assetKey: '',
  url: '',
  filename: '',
  filetype: '',
  filesize: 0,
  status: 'created',
  weight: 400,
  style: 'normal',
  family: undefined,
};

export const defaultFallbackFont = 'Arial';
