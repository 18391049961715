import React from 'react';
import cx from 'classnames';
import style from './SectionBlocks.module.css';

export const Section: React.FC<{
  title: React.ReactNode;
  titleClassName?: string;
  className?: string;
}> = ({ title, titleClassName, children, className }) => {
  return (
    <div
      className={cx(style.section, className)}
      data-test={`section-${title}`}
    >
      <div className={cx(style.title, titleClassName)}>{title}</div>
      <div className={style.body}>{children}</div>
    </div>
  );
};
export const Row: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return <div className={cx(style.row, className)}>{children}</div>;
};

export const Block: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return <div className={cx(style.block, className)}>{children}</div>;
};
