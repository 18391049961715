import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

type UsePollingQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
> = Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'refetchInterval'> & {
  refetchInterval?: number | ((data: TData | undefined) => number | false);
};

/**
 * Custom hook for performing a polling query using React Query.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePollingQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>({
  queryFn,
  refetchInterval,
  ...queryOptions
}: UsePollingQuery<TQueryFnData, TError, TData>) {
  // This is a hack to get around the fact that React-Query v3 doesn't support
  // passing a function to refetchInterval. This is supported in v4.
  const queryClient = useQueryClient();
  const { queryKey } = queryOptions;
  const queryData = queryKey
    ? queryClient.getQueryData<TData>(queryKey)
    : undefined;

  const selectedData =
    queryOptions.select && queryData
      ? queryOptions.select(queryData as TQueryFnData)
      : queryData;
  const evaluatedRefetchInterval =
    typeof refetchInterval === 'function'
      ? refetchInterval(selectedData)
      : refetchInterval;

  const queryResult = useQuery({
    queryFn,
    ...queryOptions,
    refetchInterval: evaluatedRefetchInterval,
  });

  return queryResult;
}
