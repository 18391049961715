import React from 'react';
import { LinkData, SocialData } from 'models/publisher/block';
import cx from 'classnames';
import { ConfirmDelete } from 'shared/ConfirmDelete';
import { ReactComponent as GrabberIcon } from './Grabber.svg';
import { ReactComponent as TrashIcon } from './Trash.svg';
import styles from './preview.module.css';

type PropsType = {
  isDragging?: boolean;
  onEdit: () => void;
  onRemove: () => void;
  link: LinkData | SocialData;
};

export const Preview: React.FC<PropsType> = ({
  link,
  onEdit,
  isDragging,
  onRemove,
}) => {
  const { url, title, image } = link;
  const [isDeleting, setDeleting] = React.useState(false);

  const showDialog = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleting(true);
  }, []);

  const remove = React.useCallback(
    (event?: React.MouseEvent) => {
      if (event) event.stopPropagation();
      onRemove();
      setDeleting(false); // in case there is only one link and it cannot be deleted
    },
    [onRemove]
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      data-test="block-link-editor-preview"
      className={cx(styles.container, {
        [styles.draggingContainer]: isDragging,
      })}
      onClick={() => {
        if (!isDeleting) onEdit();
      }}
    >
      {isDeleting && (
        <ConfirmDelete
          entity="Link"
          onRemove={remove}
          cancelRemove={() => setDeleting(false)}
        />
      )}
      <div className={cx(styles.grabber, { [styles.dragging]: isDragging })}>
        <GrabberIcon />
      </div>
      <div
        className={styles.icon}
        style={{ backgroundImage: `url(${image?.url})` }}
      />
      <div className={styles.body}>
        <div className={styles.title}>{title.value}</div>
        {'description' in link && (
          <div className={styles.description}>{link.description.value}</div>
        )}
        <div className={styles.link}>{url}</div>
      </div>
      <div className={styles.trash}>
        <button type="button" onClick={showDialog}>
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
