import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Section } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import {
  fetchFeatureFlagsV2,
  updateCloudbeesFeatureFlagV2,
} from 'services/api-feature-flags';
import { QueryResponse } from 'hooks/common';
import { FeatureFlag } from 'models/feature-flag';
import { useQuery } from 'react-query';
import { usePermissions } from 'contexts/permissions';
import { Row } from './Row';
import styles from './styles.module.css';

export const useFeatureFlagsQuery = (
  programId: number,
  flag: string,
  user_id?: number
): QueryResponse<FeatureFlag<boolean>> => {
  const { isLoading, error, data } = useQuery<FeatureFlag<boolean>, Error>(
    ['feature_flag', programId, flag, user_id],
    () => fetchFeatureFlagsV2(programId, flag, user_id, { cache: false }),
    { retry: false, refetchOnMount: false, refetchOnWindowFocus: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const FeatureSettings: React.FC<RouteComponentProps> = () => {
  const program = useProgram();

  const { permissions: userPermissions } = usePermissions();

  const canChangeFlags = userPermissions.featureEditAccess;

  const CommsAIEnabled = useFeatureFlagsQuery(
    program.id,
    'Studio.FeatureSettings.CommsAI.Enabled'
  );

  const TermsNeedsAccepted = useFeatureFlagsQuery(
    program.id,
    'Studio.FeatureSettings.CommsAI.Terms.NeedsAccepted'
  );

  const TermsAccepted = useFeatureFlagsQuery(
    program.id,
    'Studio.FeatureSettings.CommsAI.Terms.Accepted'
  );

  const CoverGeneration = useFeatureFlagsQuery(
    program.id,
    'Studio.Publish.AI.CoverGeneration'
  );
  const SuggestedTopics = useFeatureFlagsQuery(
    program.id,
    'Studio.Publish.SuggestedTopics'
  );
  const SentimentAnalysis = useFeatureFlagsQuery(
    program.id,
    'EE.AI.SentimentAnalysis.Enabled'
  );
  const CommentAssistance = useFeatureFlagsQuery(
    program.id,
    'EE.AI.CommentAssistance.Enabled'
  );

  const handleChange = async (
    name: string,
    value: boolean,
    undo: () => void
  ) => {
    try {
      await updateCloudbeesFeatureFlagV2(program.id, name, value);
    } catch (error) {
      undo();
    }
  };

  const headerProps: {
    headerButtonLabel: string;
    headerButtonClassName: string;
    href: string;
  } = {
    headerButtonLabel: '',
    headerButtonClassName: '',
    href: '',
  };
  let bodyEnabled = true;

  if (
    TermsNeedsAccepted.data?.value === true &&
    TermsAccepted.data?.value === false
  ) {
    headerProps.headerButtonLabel = 'Review Terms to Enable';
    headerProps.href = `/${program.id}/configure/feature-settings/review-terms`;
    headerProps.headerButtonClassName = styles.ReviewTermsButton;
    bodyEnabled = false;
  }

  return (
    <FormPage
      title="Feature Settings"
      breadcrumbs={[
        { label: 'Configure', to: '..' },
        { label: 'Feature Settings' },
      ]}
    >
      {/* eslint-disable react/jsx-props-no-spreading */}
      {CommsAIEnabled.data?.value === true && (
        <Section
          title="CommunicationAI"
          description="Firstup’s generative AI features in Creator Studio and the Employee Experience"
          {...headerProps}
        >
          <div className={bodyEnabled ? '' : styles.Disabled}>
            {CoverGeneration.data && (
              <Row
                flag="Studio.Publish.AI.CoverGeneration"
                icon="Content"
                title="Content Assist"
                description="Leverage generative AI to craft content, tiles, and descriptions, as well as draft high-quality email subject lines, previews, and push notifications"
                enabled={CoverGeneration.data?.value === true}
                onChange={handleChange}
                disabled={canChangeFlags === false}
              />
            )}

            {SuggestedTopics.data && (
              <Row
                flag="Studio.Publish.SuggestedTopics"
                icon="Content"
                title="Topic Suggestions"
                description="Utilize generative AI to suggest Topics for your campaigns"
                enabled={SuggestedTopics.data?.value === true}
                onChange={handleChange}
                disabled={canChangeFlags === false}
              />
            )}

            {SentimentAnalysis.data && (
              <Row
                flag="EE.AI.SentimentAnalysis.Enabled"
                icon="Insights"
                title="Sentiment Analysis"
                description="Analyze aggregate employee sentiment for each campaign through AI analysis of comments"
                enabled={SentimentAnalysis.data?.value === true}
                onChange={handleChange}
                disabled={canChangeFlags === false}
              />
            )}

            {CommentAssistance.data && (
              <Row
                flag="EE.AI.CommentAssistance.Enabled"
                icon="People"
                title="Comment Assist"
                description="Help Employee Experience users craft comments, check spelling and grammar, and change tones with generative AI"
                enabled={CommentAssistance.data?.value === true}
                onChange={handleChange}
                disabled={canChangeFlags === false}
              />
            )}
          </div>
        </Section>
      )}
    </FormPage>
  );
};
