import React, { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import { useDebounce } from 'hooks/useDebounce';
import { Topic } from 'models/topic';
import { Audience } from 'models/audience';
import { useRecentTopics } from './useRecentTopics';

export type ListReturnType = {
  data: Audience[] | Topic[];
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  selectedValues: () => string[];
  handleRemove: (index: number) => void;
  onSelectedIdsChange: (ids: string[]) => void;
  selectedIds: string[];
  dataByRowId: { [key: string]: Audience | Topic };
  dataRowIds: string[];
  type: 'audience' | 'topic';
  updateCache: (id: string) => void;
  search?: string;
};

export const useTopicsList = (
  value: Array<Topic>,
  search: string,
  onChange: (value: Array<Topic>) => void
): ListReturnType => {
  const { id: programId } = useProgram();
  const { data: recentTopics, updateCache } = useRecentTopics(3);
  const {
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useTopicsInfiniteQuery({ programId, search: useDebounce(search) });

  const topicsByRowId = useMemo(() => {
    const map: { [key: string]: Topic } = {};
    data.forEach((topic) => {
      map[topic.id] = topic;
    });
    // retain the currently selected topics
    value.forEach((topic) => {
      if (!map[topic.id]) map[topic.id] = topic;
    });
    // append any cached topics from recently used
    recentTopics.forEach((topic) => {
      if (!map[topic.id]) map[topic.id] = topic;
    });
    return map;
  }, [recentTopics, data, value]);

  const selectedIds = value.map(({ id }) => `${id}`);

  const onSelectedIdsChange = useCallback(
    (ids: string[]) =>
      onChange(
        ids.map((rowId) => ({ ...topicsByRowId[rowId], hidden: false }))
      ),
    [onChange, topicsByRowId]
  );

  const handleRemove = React.useCallback(
    (index: number) => {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(index, 1);
      onSelectedIdsChange(newSelectedIds);
    },
    [onSelectedIdsChange, selectedIds]
  );

  const selectedValues = React.useCallback(
    () =>
      selectedIds.map((id) => {
        const topic = topicsByRowId[id];
        return `${topic.name} (${topic.followerCount})`;
      }),
    [topicsByRowId, selectedIds]
  );

  const topicRowIds = data.map(({ id }) => id.toString());

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    selectedValues,
    handleRemove,
    onSelectedIdsChange,
    selectedIds,
    dataByRowId: topicsByRowId,
    dataRowIds: topicRowIds,
    type: 'topic',
    updateCache,
    search,
  };
};
