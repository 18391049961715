import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';
import { bossanovaDomain, request, deepCamelcaseKeys } from './api-shared';

export type FetchProps = {
  page?: { size: number; number: number };
  pageSize?: number;
  pageNumber?: number;
  programId: number;
  states?: string[];
};

export type BulkUpdatePermissionsJobCollectionData = {
  data: Array<BulkUpdatePermissionsJob>;
  meta: {
    totalObjects: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  };
};

export type BulkUpdatesErrorReportType = {
  csvRows: string;
};

export async function fetchBulkUpdateJobs({
  programId,
  ...queryParams
}: FetchProps): Promise<BulkUpdatePermissionsJobCollectionData> {
  const queryString = qs.stringify(snakeCaseKeys({ ...queryParams }), {
    arrayFormat: 'brackets',
  });

  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/bulk_update_permissions_jobs?${queryString}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data, meta, ...rest }) => {
        return {
          data,
          meta: {
            currentPage: meta.pageNumber,
            totalRecords: meta.totalObjects,
            ...meta,
          },
          ...rest,
        };
      });
  }

  throw new Error(`Error fetching bulk update jobs: ${response.status}`);
}

export const fetchBulkUpdatePermissionsJobById = async (
  programId: number,
  id: number
): Promise<BulkUpdatePermissionsJob> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/bulk_update_permissions_jobs/${id}`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(
    `Error fetching bulk update permissions job: ${response.status}`
  );
};

export const exportErrorReportCsv = async (
  programId: number,
  id: number
): Promise<Blob> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/bulk_update_permissions_jobs/${id}/error_report`;
  try {
    const response = await request(url);
    if (response.status === 200) {
      return response.blob();
    }

    throw new Error(`Error fetching error report: ${response.status}`);
  } catch (error) {
    throw new Error(`Error fetching error report: ${(error as Error).name}`);
  }
};
