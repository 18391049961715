import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';
import snakecaseKeys from 'snakecase-keys';
import {
  Channel,
  ChannelInsight,
  CompetitionInsight,
  InsightsParams,
  ScheduleInsight,
  HigherPriority,
  MaxRetries,
} from 'models/publisher/orchestration-insights';

export type AudienceInsight = {
  day: number;
  name: Channel;
  value: number;
  count: number;
};

export type InsightsResponse = {
  sampleSize: number;
  performance: {
    noise: number;
    resonance: number;
  };
  audienceInsights: Array<AudienceInsight>;
  competitionInsights: Array<CompetitionInsight>;
  channelsInsights: Array<ChannelInsight>;
  higherPriority: HigherPriority;
  scheduleInsights: Array<ScheduleInsight>;
  maxRetries: MaxRetries;
};

export async function fetchOrchestrationInsights({
  programId,
  ...body
}: InsightsParams): Promise<InsightsResponse> {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/contents/communications/insights`,
    {
      method: 'POST',
      body: JSON.stringify(snakecaseKeys(body)),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching orchestration insights: ${response.status}`);
}
