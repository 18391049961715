import React, { FC, ReactNode } from 'react';
import styles from './styles.module.css';

export const SectionContainer: FC = ({ children }) => {
  return <div className={styles.SectionContainer}>{children}</div>;
};

export const Section: FC<{ title: string }> = ({ children, title }) => {
  return (
    <section className={styles.Section}>
      <h2>{title}</h2>
      {children}
    </section>
  );
};

export const FieldSet: FC = ({ children }) => {
  return <div className={styles.FieldSet}>{children}</div>;
};

export const Field: FC = ({ children }) => {
  return <div className={styles.Field}>{children}</div>;
};

export const FieldWithError: FC<{
  error?: ReactNode;
}> = ({ children, error }) => {
  return (
    <div className={styles.FieldWithError}>
      {children}
      {error}
    </div>
  );
};

export const Control: FC = ({ children }) => {
  return <div className={styles.Control}>{children}</div>;
};

export const Label: FC<{ htmlFor?: string }> = ({ children, htmlFor }) => {
  return (
    <label className={styles.Label} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
