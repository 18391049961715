import * as React from 'react';
import { Block } from 'shared/SectionBlocks';
import { Label } from 'shared/Label';
import { Progress } from './Progress';
import styles from './channel.module.css';

export type SourceType = {
  name: string;
  count: number;
  percent: number;
  progress: number;
  measures: Array<{
    name: string;
    count: number;
  }>;
};

export const Source: React.FC<SourceType> = ({
  name,
  count,
  percent,
  progress,
  measures,
}) => (
  <Block className={styles.source}>
    <div className={styles.header}>
      <Label containerClassName={styles.label} name={name}>
        {count}
      </Label>
      <div className={styles.circle}>
        <Progress radius={42} value={percent} />
      </div>
    </div>
    <div className={styles.progress}>
      <div className={styles.progressFill} style={{ width: `${progress}%` }} />
    </div>
    <div className={styles.measures}>
      {measures.map((measure) => (
        <Label
          key={measure.name}
          containerClassName={styles.measure}
          name={measure.name}
        >
          {measure.count}
        </Label>
      ))}
    </div>
  </Block>
);
