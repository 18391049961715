import * as React from 'react';
import { PollFieldData } from 'models/publisher/block';
import { FieldFormProps } from '../../useFieldForm';
import { Answers } from './components/Answers';
import { Preset } from './components/Preset';
import { Question } from './components/Question';
import { Results } from './components/Results';
import { ExitBlocker } from './components/ExitBlocker';
import { usePollFields } from './hooks/usePollFields';
import { usePollDataMutator } from './hooks/usePollDataMutator';
import { useKeepOpen } from '../shared/KeepOpen';
import { ThankYouText } from './components/ThankYouText';

export const Poll: React.FC<FieldFormProps<PollFieldData>> = ({
  errors,
  ...props
}) => {
  const mutator = usePollDataMutator(props);
  const poll = usePollFields(mutator);
  useKeepOpen(ExitBlocker, !!errors && errors.length > 0);
  return (
    <>
      <Preset
        preset={poll.preset}
        presets={poll.presets}
        onChange={poll.changePreset}
      />
      <Question value={poll.question} onChange={poll.changeQuestion} />
      <ThankYouText
        value={poll.thank_you_text}
        onChange={poll.changeThankYouText}
      />
      <Answers
        preset={poll.preset}
        answers={poll.answers}
        onAddAnswer={poll.addAnswer}
        canAddAnswer={poll.canAddAnswer}
      />
      <Results
        visibility={poll.resultsVisibility}
        canToggleVisibility={poll.canToggleResultsVisibility}
        toggleVisibility={poll.toggleResultsVisibility}
      />
    </>
  );
};
