import React, { useEffect } from 'react';
import { OptionType } from 'hooks/common';
import { Icon } from 'shared/Icon';
import Select, {
  ValueType,
  components,
  StylesConfig,
  Theme,
  KeyboardEventHandler,
} from 'react-select';
import Creatable from 'react-select/creatable';
import { useUniqueId } from 'hooks/useUniqueId';
import { VisuallyHidden } from 'shared/VisuallyHidden';

const selectStyles = (disablePointer = false, controlBorder: ControlBorder = "gray10"): StylesConfig => ({
  control: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: 'var(--color-gray00)',
    borderColor: `var(--color-${controlBorder})`,
    borderRadius: '6px',
    minWidth: '100px',
    fontSize: '14px',
    cursor: disablePointer ? 'default' : 'pointer',
  }),
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: 'var(--color-gray00)',
    padding: '5px',
    width: '100%',
    cursor: disablePointer ? 'default' : 'pointer',
  }),
  multiValue: (provided: React.CSSProperties) => ({
    ...provided,
    background: 'var(--color-gray05)',
    borderRadius: '60px',
    fontSize: '12px',
    padding: '4px',
    cursor: disablePointer ? 'default' : 'pointer',
  }),
  multiValueRemove: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'var(--color-gray50)',
    cursor: disablePointer ? 'default' : 'pointer',
    '&:hover': { background: 'none', color: 'var(--color-gray50)' },
  }),
  option: (provided: React.CSSProperties) => ({
    ...provided,
    cursor: disablePointer ? 'default' : 'pointer',
  }),
});

const selectTheme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--color-gray05)',
    primary25: 'var(--color-gray05)',
    neutral0: 'var(--color-gray90)',
  },
});

const DropdownIndicator: React.FC = () => (
  <div style={{ padding: '3px 6px 0 0' }}>
    <Icon iconName="ChevronDown" iconType="SVG" />
  </div>
);

const MultiValueRemove: React.FC = (
  props: React.ComponentProps<typeof components.MultiValueRemove>
) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <components.MultiValueRemove {...props}>
    <Icon iconName="Close" iconType="SVG" size={9} />
  </components.MultiValueRemove>
);

const NotShown: React.FC = () => null;

const selectComponents = {
  DropdownIndicator,
  IndicatorSeparator: NotShown,
  MultiValueRemove,
};

// eslint-disable-next-line prettier/prettier
type ControlBorder = `gray${10 | 30}`;

type GenericSelectProps<
  TL extends string = string,
  TV extends string = string
> = {
  onChange: (value: TV) => void;
  value?: string;
  isDisabled?: boolean;
  options: OptionType<TL, TV>[];
  placeholder?: string;
  keepBlank?: boolean;
  openMenuOnFocus?: boolean;
  openMenuOnClick?: boolean;
  singleValueStyled?: boolean;
  isSearchable?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  controlBorder?: ControlBorder;
  label?: string;
};
export const GenericSelect = <TL extends string, TV extends string>({
  onChange,
  value,
  isDisabled,
  options,
  placeholder,
  keepBlank,
  openMenuOnFocus,
  openMenuOnClick,
  isSearchable,
  menuPlacement = 'auto',
  singleValueStyled = false,
  controlBorder = 'gray10',
  label,
}: GenericSelectProps<TL, TV>): JSX.Element => {
  const optionValue =
    options.find((option) => option.value === value) ||
    (!keepBlank ? options[0] : undefined);
  const onOptionChange = (option: ValueType<OptionType<TL, TV>>) => {
    if (!option || !('value' in option)) {
      return;
    }
    onChange(option.value);
  };
  const localSelectComponents = {
    ...selectComponents,
    DropdownIndicator: singleValueStyled
      ? null
      : selectComponents.DropdownIndicator,
  };

  useEffect(() => {
    if (!keepBlank && !value) {
      onChange(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onChange]);

  const labelId = `generic-select__label-${useUniqueId()}`;
  return (
    <>
    <VisuallyHidden id={labelId}>{label}</VisuallyHidden>
      <Select
      aria-labelledby={labelId}
        value={optionValue}
        options={options}
        isDisabled={isDisabled}
        onChange={onOptionChange}
        components={localSelectComponents}
        styles={selectStyles(singleValueStyled, controlBorder)}
        theme={selectTheme}
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        openMenuOnFocus={openMenuOnFocus}
        openMenuOnClick={openMenuOnClick}
        isSearchable={isSearchable}
      />
    </>
  );
};

const handleNumericKeysOnly: KeyboardEventHandler = (event) => {
  const { key } = event;

  // Allow backspace, enter, delete, and arrow keys
  if (
    key === 'Backspace' ||
    key === 'Delete' ||
    key === 'ArrowLeft' ||
    key === 'ArrowRight' ||
    key === 'ArrowUp' ||
    key === 'ArrowDown' ||
    key === 'Enter'
  ) {
    return true;
  }

  // Allow numbers
  if (/\d/.test(key)) {
    return true;
  }

  // Allow Ctrl+C, Ctrl+V, Ctrl+X, Ctrl+A
  if (
    (event.ctrlKey || event.metaKey) &&
    (key === 'c' || key === 'v' || key === 'x' || key === 'a')
  ) {
    return true;
  }

  // Prevent any other input
  event.preventDefault();
  return false;
};

type CreatableProps = { inputType?: 'text' | 'number' } & React.ComponentProps<
  typeof Creatable
>;

export function GenericCreatableSelect({
  inputType = 'text',
  onKeyDown,
  ...createableProps
}: CreatableProps): JSX.Element {
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (inputType === 'number') {
      handleNumericKeysOnly(event);
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };
  return (
    <Creatable
      components={selectComponents}
      styles={selectStyles()}
      theme={selectTheme}
      menuPlacement="auto"
      onKeyDown={handleKeyDown}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...createableProps}
    />
  );
}

GenericCreatableSelect.defaultProps = {
  inputType: 'text',
};
