import React from 'react';
import { FileDropZone } from 'shared/FileDropZone';
import { useFontUploader } from 'hooks/useFontUploader';
import { FontData } from 'models/font';
import { Button } from 'DesignSystem/Form';
import { FAIcon } from 'shared/Icon/FAIcon';
import { Close, Trash } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './font-editor.module.css';

const Error: React.FC<{ message: string }> = ({ message }) => (
  <Flex>
    <FAIcon name="exclamation-triangle" className="fa-sm" />
    <div className={styles.error}>{message}</div>
  </Flex>
);

type PropsType = {
  data?: FontData;
  onChange: (data: FontData) => void;
  deleteVariant: () => void;
  setIsWorking: (identifier: string, value: boolean) => void;
  errorMessage?: string;
};

export const FontUploader: React.FC<PropsType> = ({
  data: existingData,
  onChange,
  deleteVariant,
  setIsWorking,
  errorMessage,
}) => {
  const handleChange = React.useCallback(
    (font: FontData) => {
      onChange(font);
      setIsWorking(font.assetKey, false);
    },
    [onChange, setIsWorking]
  );
  const {
    hasFile,
    upload,
    allowedExtensions,
    fileName,
    isUploading,
    error: uploadError,
    clearUpload,
  } = useFontUploader({
    onChange: handleChange,
    existingData,
  });

  const handleUpload = React.useCallback(
    (f: File) => {
      upload(f);
      if (existingData?.assetKey) {
        setIsWorking(existingData?.assetKey, true);
      }
    },
    [existingData?.assetKey, setIsWorking, upload]
  );

  const error = errorMessage ?? uploadError;

  return (
    <Flex className={styles.dropZone}>
      <Flex className={styles.dropZoneFile}>
        {!hasFile && (
          <FileDropZone
            dropLabel="Drop to upload font"
            shouldClear
            onFileSelect={handleUpload}
            type="secondary"
            iconName="Upload"
            iconType="SVG"
            compact
            accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
          >
            Upload
          </FileDropZone>
        )}
        {hasFile && !error && <FAIcon name="paperclip" />}
        {hasFile && !error && <div className={styles.filename}>{fileName}</div>}
        {error && <Error message={error} />}
        {!isUploading && hasFile && (
          <Button onClick={clearUpload} compact layoutOnly icon={<Close />} />
        )}
      </Flex>
      {!isUploading && (
        <Button layoutOnly onClick={deleteVariant} icon={<Trash />} />
      )}
      {isUploading && (
        <div className={styles.spinnerContainer}>
          <LoadingSpinner size="small" />
        </div>
      )}
    </Flex>
  );
};
