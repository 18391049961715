import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import {
  NewPersonalizedFieldsFile,
  PersonalizedFieldsFile,
} from 'models/personalized-fields';
import { bossanovaDomain, request, deepCamelcaseKeys } from './api-shared';
import { getActor } from './global-actor-storage';

export type PersonalizedFieldsAttrData = {
  id: number;
  personalizedFieldFileId: number;
  attributeName: string;
  placeholderText: string;
  active: boolean;
  employeeIdentifier: boolean;
};

export type PersonalizedFieldsFileData = {
  id: number;
  programId: number;
  campaignId: number;
  fileName: string;
  state: PersonalizedFieldsFile['state'];
  employeeIdentifierType?: string;
  totalRows?: number;
  processedRows?: number;
  failedRows?: number;
  expireDate?: string;
  fileAttributes: PersonalizedFieldsAttrData[];
  creatorId: number;
  updatedAt: string;
};

export type PersonalizedFieldsUploadResult = { id: number };
export type PersonalizedFieldsUploadErrors = {
  filename: string[];
  fileContents: string[];
  columnCount: string[];
  columnHeaderSize: string[];
  duplicateColumnHeader: string[];
  emptyHeader: string[];
};
export type PersonalizedFieldsProcessingErrors = PersonalizedFieldsProcessingError[];
export type PersonalizedFieldsProcessingError = {
  count: number;
  type: string;
  message: string;
  key: string;
};
export type PersonalizedFieldsValidateResult =
  | PersonalizedFieldsUploadResult
  | PersonalizedFieldsUploadErrors;

// this method deliberately does its own version of `request` so that we can
// leave out the `Content-Type` header. The browser inserts the form boundary
// into the header, which rack requires and which we cannot set manually
export const uploadPersonalizedFieldsFile = async (
  programId: number,
  campaignId: number | 'new',
  file: File
): Promise<{ filename: string }> => {
  if (Number.isNaN(campaignId))
    throw new Error('Cannot add personalized fields to unsaved campaigns');

  const actor = getActor();
  if (!actor) throw new Error('Not logged in.');

  const url = `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/upload`;
  const headers = {
    ...actor.headers,
    Accept: 'application/json',
    'x-requested-with': 'XMLHttpRequest',
  };

  const body = new FormData();
  body.append('file', file);
  body.append('campaign_id', `${campaignId}`);

  const response = await fetch(url, { method: 'POST', body, headers });

  if (response.status === 200) return deepCamelcaseKeys(await response.json());
  throw new Error(
    `Error uploading personalized fields file: ${response.status}`
  );
};

export const validatePersonalizedFieldsFile = async (
  programId: number,
  campaignId: number | 'new',
  fileName: string
): Promise<PersonalizedFieldsValidateResult> => {
  if (Number.isNaN(campaignId))
    throw new Error('Cannot add personalized fields to unsaved campaigns');

  const actor = getActor();
  if (!actor) throw new Error('Not logged in.');

  const url = `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/validate`;
  const headers = {
    ...actor.headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-requested-with': 'XMLHttpRequest',
  };
  const body = JSON.stringify(snakeCaseKeys({ campaignId, fileName }));

  const response = await fetch(url, { method: 'PUT', body, headers });
  switch (response.status) {
    case 200:
      return deepCamelcaseKeys(await response.json());
    case 422:
      return deepCamelcaseKeys(await response.json()).error;
    default:
      throw new Error(
        `Error validating personalized fields file: ${response.status}`
      );
  }
};

export async function fetchPersonalizedFieldsFiles(
  programId: number,
  campaignId: number | 'new'
): Promise<PersonalizedFieldsFileData[]> {
  if (Number.isNaN(campaignId))
    throw new Error("Personalized fields don't exist on unsaved campaigns");

  const queryString = qs.stringify(snakeCaseKeys({ campaignId }));
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields?${queryString}`
  );

  if (response.status === 200) {
    const result: {
      data: PersonalizedFieldsFileData[];
    } = deepCamelcaseKeys(await response.json());
    return result.data;
  }

  throw new Error(
    `Error fetching personalized fields files: ${response.status}`
  );
}

export const fetchPersonalizedFieldsFileById = async (
  programId: number,
  fileId: number
): Promise<PersonalizedFieldsFileData> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/${fileId}/file_details`
  );

  if (response.status === 200) return deepCamelcaseKeys(await response.json());

  throw new Error(
    `Error fetching personalized fields file: ${response.status}`
  );
};

export const createPersonalizedFieldsFile = async (
  programId: number,
  data: NewPersonalizedFieldsFile
): Promise<PersonalizedFieldsFileData> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields`,
    {
      method: 'POST',
      body: JSON.stringify(snakeCaseKeys(data)),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status === 201) return deepCamelcaseKeys(await response.json());
  throw new Error(
    `Error creating personalized fields file: ${response.status}`
  );
};

export const updatePersonalizedFieldsFile = async (
  programId: number,
  data: PersonalizedFieldsFileData
): Promise<number> => {
  const { employeeIdentifierType, expireDate, fileAttributes } = data;
  const body = { file: { employeeIdentifierType, expireDate }, fileAttributes };
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/${data.id}/file_details`,
    {
      method: 'PUT',
      body: JSON.stringify(snakeCaseKeys(body)),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status !== 200)
    throw new Error(
      `Error updating personalized fields file: ${response.status}`
    );

  await response.text();
  return data.id;
};

export const deletePersonalizedFieldsFile = async (
  programId: number,
  fileId: number
): Promise<number> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/${fileId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status !== 200)
    throw new Error(
      `Error deleting personalized fields file: ${response.status}`
    );

  await response.text();
  return fileId;
};

export const processPersonalizedFieldsFile = async (
  programId: number,
  fileId: number
): Promise<number> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/${fileId}/perform`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status !== 201)
    throw new Error(
      `Error processing personalized fields file: ${response.status}`
    );

  await response.text();
  return fileId;
};

export const fetchPersonalizedFieldsProcessingErrors = async (
  programId: number,
  fileId: number
): Promise<PersonalizedFieldsProcessingErrors> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/${fileId}/error_summary`
  );

  if (response.status === 200)
    return deepCamelcaseKeys(await response.json()).data;

  throw new Error(
    `Error fetching personalized fields processing errors: ${response.status}`
  );
};

export const exportErrorReportCsv = async (
  programId: number,
  fileId: number
): Promise<Blob> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/personalized_fields/${fileId}/error_report`
  );

  if (response.status === 200) return response.blob();
  throw new Error(`Error fetching error report: ${response.status}`);
};
