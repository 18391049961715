import * as React from 'react';
import cx from 'classnames';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { PublisherMode } from 'contexts/publisher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Blocks } from 'models/library';
import { Search } from 'shared/icons';
import { useScrollbarWidth } from 'hooks/useScrollbarWidth';
import { useSimpleBlocks } from 'hooks/publisher/useSimpleBlocks';
import { useProgram } from 'contexts/program';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { BlockPicker } from './BlockPicker';
import { InsertBar } from './instances/InsertBar';
import { DroppableEditor } from './dnd/DroppableEditor';
import { EmptyRow } from './instances/EmptyRow';
import styles from './dnd.module.css';
import { SummarizeWithAiProvider } from './instances/SummarizeWithAi/summarize-with-ai';

export const Canvas: React.FC = () => {
  const {
    publisherMode,
    insert,
    instances,
    select,
    omitLibraryCategories,
  } = React.useContext(BlocksEditorContext);
  const showBasic = !instances.length;
  const [inserting, setInserting] = React.useState<{ at: number }>();
  const basicBlocks = useSimpleBlocks({
    omitNames: ['article', 'custom_html'],
    omitCategories: omitLibraryCategories,
    blockIdentifierSubstitutes: { video: 'video_plain' },
  });

  const { canEdit, errors, isLoading: isEditableLoading } = React.useContext(
    DesignPermissionContext
  );

  const isPublisherModeStandard = publisherMode === PublisherMode.standard;

  const newEditorFlag = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const insertAt = React.useCallback(
    (libraryBlock: Blocks[]) => {
      const blocks = libraryBlock.flatMap((b) => b.asset.blocks);
      insert(blocks, inserting?.at);
      setInserting(undefined);
    },
    [insert, inserting]
  );

  const [insertingFromLibrary, setInsertingFromLibrary] = React.useState<{
    at: number;
  }>();

  const selectFromLibrary = React.useCallback(
    (animate: (after: () => void) => void) => (items: Blocks[]) => {
      animate(() => {
        const flattened = items.flatMap((block) => block.asset.blocks);
        insert(flattened, insertingFromLibrary?.at);
        setInsertingFromLibrary(undefined);
      });
    },
    [insert, insertingFromLibrary?.at]
  );

  const insertBlockBar = React.useCallback(
    (index: number) => (
      <div>
        {basicBlocks.map(({ block, Icon }) => (
          <button
            key={block.identifier}
            type="button"
            onClick={() => insertAt([block])}
            data-test={`insert-block-${block.identifier}`}
          >
            <span>
              <Icon />
            </span>
            <strong>{block.title}</strong>
          </button>
        ))}
        <button
          type="button"
          onClick={() => setInsertingFromLibrary({ at: index })}
        >
          <span>
            <Search />
          </span>
          <strong>Library</strong>
        </button>
      </div>
    ),
    [insertAt, basicBlocks]
  );

  const unselect = React.useCallback<React.MouseEventHandler>(
    (event) => {
      if (event.target === event.currentTarget) select('');
    },
    [select]
  );

  const ref = React.useRef<HTMLDivElement>(null);
  const scrollBarWidth = useScrollbarWidth(ref);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      id="canvas-scroll-container"
      ref={ref}
      className={cx(styles.canvas, {
        [styles.inserting]: inserting,
      })}
      onClick={unselect}
      style={{
        paddingRight: `calc(var(--canvas-rl-padding) - ${scrollBarWidth}px`,
      }}
    >
      {!isEditableLoading && !canEdit && (
        <Box margin={[0, 'auto', 20]}>
          <Alert
            type={AlertType.error}
            title="Editing unavailable"
            message={errors.map((error) => (
              <div>{error}</div>
            ))}
            bgColor="red"
            enableIcon
            icon={<MAIcon name="warning" />}
          />
        </Box>
      )}
      {/* space for dragging to bottom */}
      <div style={{ marginBottom: '200px' }}>
        <SummarizeWithAiProvider>
          {instances.length > 0 && (
            <DroppableEditor>
              {(child, index) => (
                <>
                  {canEdit && isPublisherModeStandard && (
                    <InsertBar
                      index={index}
                      isOpen={index === inserting?.at}
                      onOpen={() => setInserting({ at: index })}
                      onClose={() => setInserting(undefined)}
                      onClick={() => setInsertingFromLibrary({ at: index })}
                    >
                      {insertBlockBar(index)}
                    </InsertBar>
                  )}

                  {child}
                  {canEdit &&
                    isPublisherModeStandard &&
                    index + 1 === instances.length && (
                      <InsertBar
                        index={index + 1}
                        isOpen={index + 1 === inserting?.at}
                        onOpen={() => setInserting({ at: index + 1 })}
                        onClose={() => setInserting(undefined)}
                        onClick={() =>
                          setInsertingFromLibrary({ at: index + 1 })
                        }
                      >
                        {insertBlockBar(index + 1)}
                      </InsertBar>
                    )}
                </>
              )}
            </DroppableEditor>
          )}
        </SummarizeWithAiProvider>

        {showBasic && (
          <EmptyRow onInsert={() => setInsertingFromLibrary({ at: 0 })} />
        )}
      </div>
      {insertingFromLibrary && (
        <BlockPicker
          useModal={newEditorFlag}
          selectFromLibrary={selectFromLibrary}
          onCancel={() => setInsertingFromLibrary(undefined)}
        />
      )}
    </div>
  );
};
