import * as React from 'react';
import { Icon } from 'shared/Icon';
import { TextInput } from 'shared/TextInput';
import { PollPreset } from 'models/publisher/block';
import { Button, FieldInput } from 'DesignSystem/Form';
import { Image, Box } from 'DesignSystem/Components';
import { FieldLegend } from '../../shared/FieldLegend';

type Props = {
  answers: Array<{
    value: string;
    onChange(value: string): void;
    onRemove(): void;
    placeholder: string;
  }>;
  onAddAnswer(): void;
  canAddAnswer: boolean;
  preset: PollPreset;
};

const AssetsPreview: React.FC<Props> = ({ preset, answers }) => {
  const alt: string[] = {
    satisfaction: [
      'Very Dissatisfied',
      'Dissatisfied',
      'Neutral',
      'Satisfied',
      'Very Satisfied',
    ],
    stars: ['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars'],
    yes_no: ['Thumb Down', 'Thumb Up'],
    single: [],
    multiple: [],
  }[preset];

  return (
    <FieldInput legend={<FieldLegend>Answers Preview</FieldLegend>}>
      <Box center>
        {answers.map(({ value }, i) => (
          <Image
            responsive
            margin={[0, 10, 0, 0]}
            height={24}
            key={alt[i]}
            src={value}
            alt={alt[i]}
          />
        ))}
      </Box>
    </FieldInput>
  );
};

const TextInputs: React.FC<Props> = ({
  answers,
  onAddAnswer,
  canAddAnswer,
}) => (
  <FieldInput
    htmlFor="blocks-poll-answers"
    legend={<FieldLegend>Answers</FieldLegend>}
  >
    {answers.map(({ value, onRemove, onChange, placeholder }, idx) => (
      <Box key={placeholder} margin={[5, 0]}>
        <TextInput
          id={idx === 0 ? 'blocks-poll-answers' : undefined}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          style={{
            display: 'inline-block',
            width: '100%',
            marginRight: '-50px',
          }}
        />
        <Button
          text
          clearText
          compact
          onClick={onRemove}
          label={<Icon iconName="Times" size={12} iconType="SVG" />}
        />
      </Box>
    ))}
    {canAddAnswer && (
      <Button
        block
        onClick={onAddAnswer}
        icon={<Icon iconName="Plus" iconType="SVG" useCurrentColor />}
        label="Option"
      />
    )}
  </FieldInput>
);

export const Answers: React.FC<Props & { preset: PollPreset }> = ({
  preset,
  ...props
}) => {
  /* eslint-disable react/jsx-props-no-spreading */
  if (preset === 'multiple' || preset === 'single')
    return <TextInputs preset={preset} {...props} />;
  return <AssetsPreview preset={preset} {...props} />;
};
