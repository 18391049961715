import React, { useState } from 'react';
import { ClickDropdown, DismissType } from 'DesignSystem/Components';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Button, Input } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import { InfiniteList } from 'shared/InfiniteList';
import cx from 'classnames';
import { CUSTOM_TOPIC_LABEL_MAX_LENGTH } from 'models/publisher/settings';
import { Label } from 'models/label';
import styles from './featured-label.module.css';

interface LabelDropdownProps {
  disabled?: boolean;
  defaultSelectedLabel?: Label;
  placeholder?: string;
  maxWidth?: number;
  onChange: (value?: Label) => void;
  labels: Label[];
}

export const LabelDropdown: React.FC<LabelDropdownProps> = ({
  disabled,
  defaultSelectedLabel,
  onChange,
  placeholder = 'Select Label',
  maxWidth = CUSTOM_TOPIC_LABEL_MAX_LENGTH,
  labels = [],
}) => {
  const isCustomDefaultLabel = !defaultSelectedLabel?.preset;
  const [inputText, setInputText] = useState(
    (isCustomDefaultLabel && defaultSelectedLabel?.text) || ''
  );
  const [selectedLabel, setSelectedLabel] = useState<Label | undefined>(
    defaultSelectedLabel
  );

  const isValid = inputText.length <= CUSTOM_TOPIC_LABEL_MAX_LENGTH;

  React.useEffect(() => {
    if (defaultSelectedLabel) onChange(defaultSelectedLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTopicDropdown = (dismiss: DismissType) => {
    const handleChange = (index: number) => {
      if (disabled) return;
      const label = labels[index];
      setInputText('');
      setSelectedLabel(label);
      onChange(label);
      dismiss();
    };

    const onInputChangeHandler = (value: string) => {
      setInputText(value);
      const label = { text: value };
      setSelectedLabel(label);
      onChange(label);
    };

    return (
      <Box minWidth={370}>
        <Popover>
          <InfiniteList
            itemCount={labels.length}
            isLoading={false}
            hasNextPage={false}
            isFetchingNextPage={false}
            itemHeight={30}
            height={100}
            highlightable
          >
            {(index) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <Box
                  height={30}
                  padding={[6, 0, 10, 13]}
                  className={cx(styles.Item)}
                  onClick={() => handleChange(index)}
                  key={index}
                  data-testid="labeldropdown-option"
                >
                  {labels[index].preset === selectedLabel?.preset && (
                    <SVGIcon name="Check" />
                  )}{' '}
                  {labels[index].text}
                </Box>
              );
            }}
          </InfiniteList>
          <Box margin={[10, 0, 0, 0]}>
            <Input
              disabled={disabled}
              placeholder="Custom Label"
              value={inputText}
              onChange={onInputChangeHandler}
              hasError={!isValid}
              block
            />
            <Box className={styles.LetterCount}>
              {`${inputText.length} / ${maxWidth} characters`}
            </Box>
          </Box>
        </Popover>
      </Box>
    );
  };

  return (
    <>
      <ClickDropdown
        disabled={disabled}
        dropdownRenderProp={selectTopicDropdown}
        autoUpdate
      >
        <Button
          compact
          block
          input
          label={selectedLabel?.text || placeholder}
          badge={<SVGIcon name="DownChevron" />}
          className={cx({
            [styles.InputPlaceholder]: !selectedLabel?.text,
            [styles.ErrorInput]: !isValid,
          })}
        />
      </ClickDropdown>
    </>
  );
};
