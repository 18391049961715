import React from 'react';
import { Label } from 'shared/Label';
import { Icon } from 'shared/Icon';
import { useContentUserCount } from 'hooks/useContentUserCount';
import infoStyles from '../info.module.css';
import styles from './audience.module.css';

export const People: React.FC = () => {
  const { uniqueUserCount } = useContentUserCount();

  return (
    <div>
      <Label name="People" containerClassName={infoStyles.labelContainer}>
        <div className={styles.totalUsers}>
          <Icon iconType="SVG" iconName="Account" size={12} />
          <span>{uniqueUserCount ?? 0}</span>
        </div>
      </Label>
    </div>
  );
};
