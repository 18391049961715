import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components';
import { Caption, FieldLabel } from 'DesignSystem/Typography';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import {
  CommunicationStep,
  DeliveryChannels,
  PushChannel,
  isNotifitcationCenterChannel,
  isEmailChannel,
  isPushChannel,
  defaultEmailChannel,
  defaultNotificationCenterChannel,
  defaultPushChannel,
} from 'models/journeys/journey';
import { isCommunicationStepError } from 'models/journeys/journey-errors';
import { ChannelSelection } from 'components/channel-selection/channel-selection';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { Label } from 'models/label';
import { Checkbox } from 'DesignSystem/Form';
import { useJourneyDispatch, useJourneyState } from 'contexts/journeys/journey';
import { Icon } from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import { ProgramOrAuthor } from 'hooks/useAuthorsList';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';
import { useJourneyContentDesigner } from '../../JourneyContentDesigner/JourneyContentDesignProvider';
import { EmailConfig } from './channel-configs';
import { ContentSettings } from './content-settings/ContentSettings';
import styles from './communication-config.module.css';
import { ChannelDelivery } from './channel-delivery/ChannelDelivery';
import { useAcknowledgementsEnabled } from '../../../../../../hooks/journeys/journeys';

export type DeliveryChannelOption = {
  id?: string;
  name: keyof DeliveryChannels;
  label: string;
};

export const CommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const { errorsForStep } = useJourneyValidationErrors();
  const dispatch = useJourneyDispatch();
  const { resolveErrors } = useJourneyValidationErrors();
  const { inlineEditingEnabled } = useJourneyContentDesigner();

  const stepErrors = errorsForStep(step.id);
  const errors = isCommunicationStepError(stepErrors) ? stepErrors : undefined;
  const {
    approveCommunicationStep,
    removeCommunicationStepApproval,
  } = useJourneyState();

  const emailChannelConfig = step.channels.find(isEmailChannel);
  const notificationCenterChannelConfig = step.channels.find(
    isNotifitcationCenterChannel
  );
  const pushChannelConfig = step.channels.find(isPushChannel);

  const approve = () => {
    approveCommunicationStep(step.id);
    resolveErrors(step.id, ['approved']);
  };

  const removeApproval = () => {
    removeCommunicationStepApproval(step.id);
  };

  const { data: defaultAddress } = useDefaultEmailAddress();

  const handleAcknowledgementLabelChange = (label: Label | undefined) => {
    const acknowledgement = label && {
      label: label.preset,
      customLabel: label.text,
    };

    dispatch({
      type: 'step/updated',
      stepId: step.id,
      step: {
        type: 'communication',
        acknowledgement,
      },
    });
    resolveErrors(step.id, ['acknowledgement']);
  };

  const emailChannel = emailChannelConfig ?? defaultEmailChannel();

  const notificationCenterIsEnabled = useNotificationCenterEnabled();

  const emailChannelSubject =
    emailChannel.subject === 'Default' || !emailChannel.subject
      ? ''
      : emailChannel.subject;
  const emailChannelPreviewText =
    emailChannel.previewText === 'Default' || !emailChannel.previewText
      ? ''
      : emailChannel.previewText;
  const emailChannelSenderAlias =
    emailChannel.emailSenderAlias || defaultAddress;

  const notificationCenterChannel =
    notificationCenterChannelConfig ?? defaultNotificationCenterChannel();

  const notificationCenterTitle = notificationCenterChannel?.text ?? '';

  const defaultAuthor = useDefaultAuthor(step.designId, step.author);

  const pushChannel: PushChannel = pushChannelConfig ?? defaultPushChannel();

  const acknowledgementLabel = step.acknowledgement
    ? {
        preset: step.acknowledgement?.label,
        text: step.acknowledgement?.customLabel,
      }
    : undefined;

  const acknowledgementsEnabled = useAcknowledgementsEnabled();

  const hasMultipleChannels = step.selectedChannels.length > 1;
  return (
    <FlexLayout.Flex start>
      <Box
        margin={[16, 0, 0, 0]}
        width="720px"
        style={{
          display: 'flex',
          gap: '16px',
          flexDirection: 'column',
        }}
      >
        {inlineEditingEnabled && (
          <Box>
            <FieldLabel>Content Approval</FieldLabel>
            <Box padding={[24, 0]}>
              <Checkbox
                labelClassName={styles.communicationApprovalLabel}
                onSelect={approve}
                onDeselect={removeApproval}
                label={<FieldLabel>Ready for Publish</FieldLabel>}
                checked={step.approved || false}
              />
              {errors?.approved && (
                <Flex start className={styles.communicationApprovalWarning}>
                  <Icon size={16}>warning</Icon>
                  <Caption>
                    This communication must be marked Ready to publish this
                    journey
                  </Caption>
                </Flex>
              )}
            </Box>
          </Box>
        )}
        {(() => {
          if (notificationCenterIsEnabled) {
            return (
              <>
                <ChannelSelection
                  checked={(channel) => step.selectedChannels.includes(channel)}
                  toggleChecked={(channel) => {
                    dispatch({
                      type: 'step/communication/channel/toggled',
                      stepId: step.id,
                      channel,
                    });
                  }}
                >
                  <ChannelSelection.Email
                    emailAlias={emailChannelSenderAlias}
                    onEmailAliasChange={(e) => {
                      dispatch({
                        type: 'step/communication/channel/updated',
                        stepId: step.id,
                        channel: { name: 'email', emailSenderAlias: e },
                      });
                      resolveErrors(step.id, ['emailChannelAddress']);
                    }}
                    subject={emailChannelSubject}
                    onSubjectChange={(s) => {
                      dispatch({
                        type: 'step/communication/channel/updated',
                        stepId: step.id,
                        channel: { name: 'email', subject: s },
                      });
                      resolveErrors(step.id, ['emailChannelSubject']);
                    }}
                    previewText={emailChannelPreviewText}
                    onPreviewTextChange={(p) => {
                      dispatch({
                        type: 'step/communication/channel/updated',
                        stepId: step.id,
                        channel: { name: 'email', previewText: p },
                      });
                      resolveErrors(step.id, ['emailChannelPreview']);
                    }}
                    errors={
                      errors && {
                        emailAlias: errors.emailChannelAddress || [],
                        subject: errors.emailChannelSubject || [],
                        previewText: errors.emailChannelPreview || [],
                      }
                    }
                  />
                  <ChannelSelection.NotificationCenter
                    author={step.author}
                    initialAuthor={defaultAuthor}
                    onAuthorChange={(author) =>
                      dispatch({
                        type: 'step/updated',
                        stepId: step.id,
                        step: { type: 'communication', author },
                      })
                    }
                    title={notificationCenterTitle}
                    onTitleChange={(title) => {
                      dispatch({
                        type: 'step/communication/channel/updated',
                        stepId: step.id,
                        channel: { name: 'notification_center', text: title },
                      });
                      resolveErrors(step.id, [
                        'notificationCenterChannelTitle',
                      ]);
                    }}
                    markAsImportant={notificationCenterChannel.markAsImportant}
                    onMarkAsImportantChange={(markAsImportant) => {
                      dispatch({
                        type: 'step/communication/channel/updated',
                        stepId: step.id,
                        channel: {
                          name: 'notification_center',
                          markAsImportant,
                        },
                      });
                    }}
                    errors={
                      errors && {
                        title: errors.notificationCenterChannelTitle || [],
                      }
                    }
                  >
                    <ChannelSelection.PushNotification
                      pushMessage={pushChannel.text}
                      onPushMessageChange={(pushMessage) => {
                        dispatch({
                          type: 'step/communication/channel/updated',
                          stepId: step.id,
                          channel: { name: 'push', text: pushMessage },
                        });
                        resolveErrors(step.id, ['pushChannelText']);
                      }}
                      errors={
                        errors && {
                          pushMessage: errors.pushChannelText || [],
                        }
                      }
                    />
                  </ChannelSelection.NotificationCenter>
                </ChannelSelection>

                <ChannelDelivery
                  onSelectionChange={(value) => {
                    dispatch({
                      type: 'step/updated',
                      stepId: step.id,
                      step: { type: 'communication', channelDelivery: value },
                    });
                  }}
                  value={step.channelDelivery}
                  configurable={hasMultipleChannels}
                />
              </>
            );
          }

          return (
            <EmailConfig
              errors={errors}
              disabled={false}
              onEmailAliasChange={(emailSenderAlias) => {
                dispatch({
                  type: 'step/communication/channel/updated',
                  stepId: step.id,
                  channel: { name: 'email', emailSenderAlias },
                });
                resolveErrors(step.id, ['emailChannelAddress']);
              }}
              emailSenderAlias={emailChannelSenderAlias}
              subject={emailChannelSubject}
              onSubjectChange={(subject) => {
                dispatch({
                  type: 'step/communication/channel/updated',
                  stepId: step.id,
                  channel: { name: 'email', subject },
                });
                resolveErrors(step.id, ['emailChannelSubject']);
              }}
              previewText={emailChannelPreviewText}
              onPreviewTextChange={(previewText) => {
                dispatch({
                  type: 'step/communication/channel/updated',
                  stepId: step.id,
                  channel: { name: 'email', previewText },
                });
                resolveErrors(step.id, ['emailChannelPreview']);
              }}
            />
          );
        })()}

        {acknowledgementsEnabled && (
          <div className={styles.configSection}>
            <ContentSettings
              acknowledgementLabel={acknowledgementLabel}
              onAcknowledgementLabelChange={handleAcknowledgementLabelChange}
              errors={errors}
            />
          </div>
        )}
      </Box>
    </FlexLayout.Flex>
  );
};

function useDefaultAuthor(
  designId: number | undefined,
  author: ProgramOrAuthor | undefined
) {
  const [prevDesignId, setPrevDesignId] = useState<number | undefined>(
    designId
  );
  const [defaultAuthor, setDefaultAuthor] = useState<
    ProgramOrAuthor | undefined
  >(undefined);

  if (prevDesignId !== designId) {
    setPrevDesignId(designId);
  }

  // Track if the designId has changed
  const hasDesignIdChanged =
    prevDesignId !== undefined && prevDesignId !== designId;

  if (author && !defaultAuthor) {
    // If this is the first author, set it as the default
    setDefaultAuthor(author);
  } else if (hasDesignIdChanged) {
    // Otherwise, if we already have a default author but
    // we've loaded new content, reset the default author
    setDefaultAuthor(undefined);
    setPrevDesignId(designId);
  }

  return defaultAuthor;
}
