import React, { forwardRef, useCallback } from 'react';
import { SearchBar } from 'shared/SearchBar';
import { FetchProps } from 'services/api-content';
import { FiltersStateType } from 'contexts/content/filters';
import { Filters } from './Filters';
import styles from './filters.module.css';

type PropTypes = {
  onChange: (fetchProps: ContentFilterFetchProps) => void;
  fetchProps: ContentFilterFetchProps;
  filters: FiltersStateType;
  setValue: (name: string, values: string[]) => void;
  setBooleanValue: (name: string, value: boolean) => void;
  searchBarWidth?: string;
  dropdownAlignment?: 'left' | 'right' | 'center';
  filtersLayout?: 'campaigns' | 'journeys';
};

export type ContentFilterFetchProps = Pick<FetchProps, 'search'>;

export const ContentFilterBar = forwardRef<HTMLDivElement, PropTypes>(
  (
    {
      fetchProps,
      onChange,
      filters,
      setValue,
      setBooleanValue,
      searchBarWidth,
      dropdownAlignment,
      filtersLayout = 'campaigns',
    },
    ref
  ) => {
    const updateProp = useCallback(
      (prop: keyof FetchProps, value: FetchProps[typeof prop]) => {
        return onChange({ ...fetchProps, [prop]: value });
      },
      [fetchProps, onChange]
    );

    const onSearchChange = useCallback((value) => updateProp('search', value), [
      updateProp,
    ]);

    return (
      <div ref={ref}>
        <Filters
          filters={filters}
          setValue={setValue}
          setBooleanValue={setBooleanValue}
          dropdownAlignment={dropdownAlignment}
          layout={filtersLayout}
        >
          <SearchBar
            value={fetchProps.search}
            onChange={onSearchChange}
            placeholder="Search campaigns"
            panelClassName={styles.searchBarWrapper}
            width={searchBarWidth}
          />
        </Filters>
      </div>
    );
  }
);
