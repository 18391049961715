import React from 'react';
import cx from 'classnames';
import { LinkData, SocialData } from 'models/publisher/block';
import { Field, Fieldset } from 'shared/Fieldset';
import { Icon } from 'shared/Icon';
import { TextInput } from 'shared/TextInput';
import { ImageData } from 'models/image';
import { useLinksSourceMenu } from '../useLinksSourceMenu';
import styles from './external.module.css';
import forms from '../../form.module.css';

type PropsType = {
  initial?: LinkData | SocialData;
  link: LinkData | SocialData;
  onChange: (link: LinkData | SocialData) => void;
  onUrlChange: (url: string) => void;
  hasChanges: boolean;
  setUrl: (url: string) => void;
  setImage: (image: ImageData | File) => void;
  error?: string;
  loading?: boolean;
  source: ReturnType<typeof useLinksSourceMenu>;
};

export const External: React.FC<PropsType> = ({
  initial,
  onChange,
  onUrlChange,
  link,
  hasChanges,
  setUrl,
  loading,
}) => {
  React.useEffect(() => {
    if (hasChanges)
      onChange({
        ...link,
        source: 'external',
      });
  }, [hasChanges, initial, link, onChange]);

  const handleUrlChange = (url: string) => {
    setUrl(url);
    onUrlChange(url);
  };

  const disabled = ![undefined, 'external'].includes(link.source);
  const label = disabled ? 'Campaign URL' : 'URL';

  return (
    <Fieldset className={cx(styles.urlContainer, forms.fieldset)}>
      <Field
        label={label}
        className={cx(styles.url, styles.field, forms.field, styles.linkField)}
      >
        <TextInput
          value={link ? link.requestedUrl || link.url : ''}
          onChange={handleUrlChange}
          autoComplete="off"
          id="link-url"
          disabled={disabled}
        />
        {loading && !disabled && (
          <button type="button">
            <i className="fas fa-spinner fa-spin" />
          </button>
        )}
        {link?.url && !loading && !disabled && (
          <button type="button" onClick={() => setUrl('')}>
            <Icon iconName="times" iconType="FA" />
          </button>
        )}
      </Field>
    </Fieldset>
  );
};
