import { RouteComponentProps } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { Topic, updateTopic, validateTopic } from 'models/topic';
import React from 'react';
import { Helmet } from 'react-helmet';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { WithPermission } from 'shared/WithPermission';
import { Footer } from './components/Footer';
import { Form } from './components/Form';
import { useActions } from './useActions';
import { WithPagesControl } from './WithPagesControl';

export const NewTopic: React.FC<RouteComponentProps> = () => {
  const [topic, setTopic] = React.useState<Partial<Topic>>({});
  const { setFlashMessage } = useFlashMessage();
  const { create, isLoading } = useActions();

  function onChange(updatedData: Partial<Topic>) {
    setTopic(updateTopic(topic as Topic, updatedData));
  }

  const header = (
    <>
      <h1 className="page-header">Topic</h1>
    </>
  );

  const main = (
    <Form
      topic={topic}
      onChange={onChange}
      disablePages // pages section not yet functional for new topics
    />
  );

  function onSave() {
    const validationError = validateTopic(topic);
    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });
      return;
    }

    create(topic);
  }

  return (
    <WithPermission permissions={['confContentAccess', 'topicsAccess']}>
      <Helmet>
        <title>New Topic</title>
      </Helmet>
      <WithPagesControl topicId={topic.id ?? -1}>
        <MainEditor
          header={header}
          main={main}
          sidebar={<></>}
          footer={
            <Footer topic={topic} action={onSave} isWorking={isLoading} />
          }
        />
      </WithPagesControl>
    </WithPermission>
  );
};
