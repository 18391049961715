import React from 'react';
import { pluralize } from 'utility/text';
import { useContentUserCount } from 'hooks/useContentUserCount';
import { ReactComponent as Warning } from '../Warning.svg';
import { ReactComponent as Users } from './Users.svg';
import styles from './people.module.css';

export const People: React.FC = () => {
  const { uniqueUserCount } = useContentUserCount();

  return (
    <div>
      <div className={styles.totalUsers}>
        {uniqueUserCount === 0 ? (
          <Warning height="75" width="75" className={styles.icons} />
        ) : (
          <Users height="75" width="75" className={styles.icons} />
        )}
        <h3 className={styles.middleText}>
          {uniqueUserCount ?? 0} {pluralize(uniqueUserCount ?? 0, 'person')}
        </h3>
      </div>
    </div>
  );
};
