import React, { useCallback } from 'react';
import {
  ImageData,
  Image,
  emptyImageData,
  IMAGE_UPLOAD_RULES,
  IMAGE_UPLOAD_ERRORS,
  isImageTypeAllowed,
} from 'models/image';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import {
  EXTERNAL,
  UPLOAD,
} from 'components/publisher/blocks/forms/fields/shared/SourceMenuConst';
import { useBasicValidator, ValidatorsType } from './useBasicValidator';
import {
  useContentImageFileMutation,
  useContentImageQuery,
  useContentImageUrlMutation,
  useHostImageFileMutation,
  useHostImageUrlMutation,
} from './image';

export type FileData = {
  file: File;
};

export type AltTextData = {
  alt: string;
};

export const fileValidators: ValidatorsType<AltTextData & FileData> = {
  max_size: (data) => data.file.size > IMAGE_UPLOAD_RULES.MAX_FILE_SIZE,
  max_alt_size: (data) => data.alt.length > IMAGE_UPLOAD_RULES.MAX_ALT_SIZE,
  allowed_extension_type: (data) => isImageTypeAllowed(data.file),
};

export const urlValidators: ValidatorsType<AltTextData> = {
  max_alt_size: (data) => data.alt.length > IMAGE_UPLOAD_RULES.MAX_ALT_SIZE,
};

type ContentImageProps = {
  onUpload: (data: ImageData) => void;
  onError?: (message: string, file?: File) => void;
};

type ContentImageProcessor = {
  image?: ImageData;
  onProcessed: (data: ImageData) => void;
  onError?: (message: string) => void;
  linkType?: string;
};

type ContentImageUploaderReturn = {
  isUploading: boolean;
  uploadFile: (image: File) => void;
  uploadUrl: (url: string) => void;
  remove: () => void;
  setAlt: (value: string) => void;
  errors: Array<string>;
  image: ImageData;
};

type ContentImageUploader<T> = (
  props: T
) => {
  isUploading: boolean;
  uploadFile: (image: File) => void;
  uploadUrl: (url: string) => void;
  remove: () => void;
  setAlt: (value: string) => void;
  errors: Array<string>;
  image: ImageData;
};

const GENERIC_IMAGE_PROCESSING_ERROR_MESSAGE =
  'Image processing failed. Please make sure the image is valid and the size is less than 10MB.';

export const useContentImageUploader: ContentImageUploader<ContentImageProps> = ({
  onUpload,
  onError,
}): ContentImageUploaderReturn => {
  const { post, isEditingTemplate, touch, disableFetch } = usePublisher();
  const { id: contentId } = post.content;
  const { id: programId } = useProgram();
  const [image, setImage] = React.useState<ImageData>(emptyImageData());
  const [errors, setErrors] = React.useState<Array<string>>();
  const fileValidator = useBasicValidator<FileData & AltTextData>(
    fileValidators,
    IMAGE_UPLOAD_ERRORS
  );
  const urlValidator = useBasicValidator<AltTextData>(
    urlValidators,
    IMAGE_UPLOAD_ERRORS
  );
  const altTextRef = React.useRef(image.altText); // handle async alt text changes, while uploading image, kinda edge case

  const onSuccess = (data: Image) => {
    const newState = {
      ...image,
      ...data,
      imageId: data.id,
      inputUrl: data.url,
      processed: data.status === 'completed',
    };
    setImage(newState);
    onUpload(newState);
    setErrors(undefined);
  };

  const queryError = useCallback(() => {
    setErrors([GENERIC_IMAGE_PROCESSING_ERROR_MESSAGE]);
    if (onError) {
      onError(GENERIC_IMAGE_PROCESSING_ERROR_MESSAGE);
    }
  }, [onError]);

  const contentImageFile = useContentImageFileMutation({
    onSuccess,
    onError: queryError,
  });
  const hostImageFile = useHostImageFileMutation({
    onSuccess,
    onError: queryError,
  });

  const {
    mutate: mutateFile,
    isSaving: fileIsUploading,
    errorMessage: fileError,
  } = isEditingTemplate ? hostImageFile : contentImageFile;

  const contentImageUrl = useContentImageUrlMutation({
    onSuccess,
    onError: queryError,
  });

  const hostImageUrl = useHostImageUrlMutation({
    onSuccess,
    onError: queryError,
  });

  const {
    mutate: mutateUrl,
    isSaving: urlIsUploading,
    errorMessage: urlError,
  } = isEditingTemplate ? hostImageUrl : contentImageUrl;

  // We only ever need a ref to this function when we have no
  // content id to use. When that changes, this callback becomes
  // useless. If we use a normal callback, it will render more than
  // it needs to, for a function we'll never call. Using a ref
  // will keep this very side-effecty, and hopefully with
  // minimal impact on rerendering>
  const newIdSaverRef = React.useRef(async () => 0);
  newIdSaverRef.current = () =>
    new Promise((yay, nay) => {
      // let's be a little paranoid..
      if (post.content.id > 0) {
        // eslint-disable-next-line no-console
        console.warn(`Expected to see 0, found ${post.content.id}`);
        yay(post.content.id);
        return;
      }
      if (post.content.publicationState !== 'draft') {
        // eslint-disable-next-line no-console
        console.warn(
          `Expected to see a draft, found ${post.content.publicationState}`
        );
        nay(new Error('No campaign ID found'));
        return;
      }

      // Disable the side-effect of fetching the content after it has
      // been created. This allows for the image upload to complete and
      // the autosave to persist the image data. Fetching is automatically
      // re-enabled after autosave completes.
      disableFetch();

      touch((savedPost) => {
        if (!savedPost) {
          nay(new Error('Could not create campaign'));
          return;
        }
        if (!savedPost.content.id) {
          nay(new Error('Could not find content id'));
          return;
        }
        yay(savedPost.content.id);
      });
    });

  const uploadFile = React.useCallback(
    (file: File) => {
      if (!file) return;

      const { isValid, errors: messages } = fileValidator.validate({
        file,
        alt: `${image.altText}`,
      });

      if (!isValid) {
        if (onError) {
          onError(messages.join(', '), file);
        }
        setErrors(messages);
        return;
      }

      const getNewContentId = newIdSaverRef.current;
      mutateFile({
        data: file,
        programId,
        contentId,
        getNewContentId,
        source: UPLOAD,
      });
    },
    [fileValidator, image, mutateFile, programId, contentId, onError]
  );

  const uploadUrl = React.useCallback(
    (url: string) => {
      if (!url) return;

      const { isValid, errors: messages } = urlValidator.validate({
        alt: `${image.altText}`,
      });

      if (!isValid) {
        setErrors(messages);
        return;
      }

      const getNewContentId = newIdSaverRef.current;
      mutateUrl({
        data: url,
        programId,
        contentId,
        getNewContentId,
        source: EXTERNAL,
      });
    },
    [mutateUrl, programId, contentId, urlValidator, image.altText]
  );

  const remove = React.useCallback(() => {
    const newState = emptyImageData();
    setImage(newState);
    onUpload(newState);
    setErrors(undefined);
  }, [onUpload]);

  const allErrors = React.useMemo(() => {
    const memo = [];
    if (fileError) memo.push(fileError);
    if (urlError) memo.push(urlError);
    if (errors) return memo.concat(errors);

    return memo;
  }, [fileError, urlError, errors]);

  const setAlt = React.useCallback(
    (altText: string) => {
      altTextRef.current = altText;
      setImage({ ...image, altText });
    },
    [image]
  );

  return {
    remove,
    uploadFile,
    uploadUrl,
    setAlt,
    image,
    errors: allErrors,
    isUploading: urlIsUploading || fileIsUploading,
  };
};

export const useProcessedContentImage = ({
  image,
  onProcessed,
  onError,
  linkType,
}: ContentImageProcessor): Image['status'] => {
  const { id: programId } = useProgram();
  const { id: contentId } = usePublisher().post.content;
  const [status, setStatus] = React.useState<Image['status']>('processing');

  const { data: contentImage, errorMessage } = useContentImageQuery({
    programId,
    contentId,
    imageId: image?.imageId,
    enabled:
      !!image &&
      !image.processed &&
      (!!image.imageId || !!image.url) &&
      status === 'processing',
    refetchInterval: 2000,
  });

  React.useEffect(() => {
    setStatus(
      image?.processed && linkType === 'social' ? 'completed' : 'processing'
    );
  }, [image?.processed, linkType]);

  React.useEffect(() => {
    // if a new image is supplied, reset the status
    if (
      (status === 'completed' || status === 'errored') &&
      linkType === 'social'
    ) {
      return;
    }

    if (status !== 'processing' && linkType !== 'social') {
      if (!contentImage || contentImage.status === 'processing') {
        setStatus('processing');
      }
      return;
    }

    if (contentImage?.status === 'completed') {
      const newState = {
        ...image,
        ...contentImage,
        imageId: contentImage.id,
        processed: true,
      };
      setStatus('completed');
      onProcessed(newState);
    } else if (errorMessage || contentImage?.status === 'errored') {
      if (onError) {
        onError(GENERIC_IMAGE_PROCESSING_ERROR_MESSAGE);
      }
      setStatus('errored');
    }
  }, [
    contentImage,
    setStatus,
    onProcessed,
    image,
    status,
    onError,
    errorMessage,
    linkType,
  ]);

  return status;
};
