import React, { useCallback, useMemo, useState } from 'react';
import { Topic } from '../../../../../../models/topic';
import { SVGIcon as Icon } from '../../../../../../shared/Icon/SVGIcon';
import { FormPage } from '../../../../../../DesignSystem/Layout/Pages';
import { useProgram } from '../../../../../../contexts/program';
import { useActions } from '../../useActions';
import { ConfirmModal } from '../../../../../../DesignSystem/Components';
import { HoverIconMenu } from '../../../../../../shared/hover-dropdown/HoverIconMenu';
import { Button } from '../../../../../../DesignSystem/Form';
import { MAIcon } from '../../../../../../shared/MAIcon';
import styles from './styles.module.css';

interface Props {
  children: React.ReactNode;
  topic: Topic;
}

const tabs = [
  { label: 'Details', to: `details`, disablePartial: true },
  { label: 'Settings', to: `details/settings`, disablePartial: true },
];

export function MainContainer({ topic, children }: Props): JSX.Element {
  const program = useProgram();
  const topicId = Number(topic.id);
  const [showDraftWarning, setShowDraftWarning] = useState(false);
  const [showEditArchivedWarning, setShowEditArchivedWarning] = useState(false);

  const actions = useActions();

  const breadcrumbs = useMemo(
    () => [
      { label: 'Configure', to: '../../..' },
      { label: 'Topics', to: '../..' },
      { label: topic.name },
    ],
    [topic.name]
  );

  const handleView = useCallback(() => {
    window.open(`${program.url}#channel/${topic.id}`, '_blank')?.focus();
  }, [program, topic]);

  const handleEdit = () => {
    if (topic.archived) {
      setShowEditArchivedWarning(true);
    } else if (topic.draft || topic.draftId) {
      actions.edit(topicId);
    } else {
      setShowDraftWarning(true);
    }
  };

  const handleArciveToggle = () => {
    if (topic.archived) {
      actions.unarchive(topicId);
    } else {
      actions.archive(topicId);
    }
  };

  const editConfirmations: JSX.Element[] = [];
  if (showDraftWarning) {
    editConfirmations.push(
      <ConfirmModal
        key="draft-warning"
        title="Creating a draft"
        confirmLabel="Continue"
        onConfirm={() => {
          actions.edit(topicId);
        }}
        onCancel={() => {
          setShowDraftWarning(false);
        }}
      >
        Editing this topic will create a draft. Any changes you make won’t
        affect the topic until you publish the changes.
      </ConfirmModal>
    );
  }
  if (showEditArchivedWarning) {
    editConfirmations.push(
      <ConfirmModal
        key="edit-archived-warning"
        title="Cannot edit topic"
        confirmLabel="Unarchive and edit"
        onConfirm={() => {
          actions.unarchiveAndEdit(topicId);
        }}
        onCancel={() => {
          setShowEditArchivedWarning(false);
        }}
      >
        Archived topics cannot be edited. To edit, first unarchive the topic.
        Note that when you unarchive a topic, &quot;Show in Experience&quot;
        will be set to Off. To turn visibility On, change the setting in the
        Settings tab.
      </ConfirmModal>
    );
  }

  const pageActions = (
    <div className={styles.pageActions}>
      <Button
        icon={<Icon name="Edit" size={20} fill="var(--color-gray00)" />}
        label="Edit"
        onClick={handleEdit}
      />
      <Button label="View in Experience" onClick={handleView} secondary />
      <HoverIconMenu
        menuItems={[
          {
            title: topic.archived ? 'Unarchive' : 'Archive',
            danger: !topic.archived,
            onClick: handleArciveToggle,
          },
        ]}
        openDelay="click"
        dropdownClassName={`dropdown-align-right ${styles.moreDropdown}`}
      >
        <Button
          type="button"
          className={styles.buttonMore}
          secondary
          borderless
          icon={<MAIcon name="more_vert" />}
        />
      </HoverIconMenu>
    </div>
  );

  return (
    <FormPage
      title={topic.name}
      description={topic.description}
      breadcrumbs={breadcrumbs}
      actionsOverride={pageActions}
      tabs={tabs}
    >
      {children}
      {editConfirmations}
    </FormPage>
  );
}
