import React from 'react';
import { Scope } from 'models/bulk-update-permissions-job';
import { LitmusRole } from 'models/role';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { titleCase } from 'utility/text';
import styles from '../bulk-updates.module.css';
import { ScopeData } from './get-scopes-data';

type Scopes = {
  [key: string]: string[];
};

const prepareScopes = (jobScopes: Scope[], definedScopes: ScopeData[]) => {
  const preparedScopes: Scopes = {};

  jobScopes.forEach((scope) => {
    const scopeName =
      definedScopes.find((s) => s.jobScope === scope.name)?.scopeTitle ||
      scope.name;

    if (!preparedScopes[scope.type]) {
      preparedScopes[scope.type] = [scopeName];
    } else {
      preparedScopes[scope.type].push(scopeName);
    }
  });
  return preparedScopes;
};

const displayType = (type: string) => {
  const formattedType = formatType(type);
  switch (formattedType) {
    case 'Topic':
    case 'Audience':
    case 'Template':
      return `${formattedType}s:`;
    case 'Email Alias':
    case 'Author Alias':
    default:
      return `${formattedType}es:`;
  }
};

const formatType = (type: string) => {
  switch (type) {
    case 'topic':
      return 'Topic';
    case 'audience':
      return 'Audience';
    case 'publisher_template':
      return 'Template';
    case 'sender_account':
      return 'Email Alias';
    case 'author_alias':
      return 'Author Alias';
    default:
      return 'Undefined';
  }
};

const prepareRole = (jobRole: string, roles: LitmusRole[]) => {
  if (jobRole === 'member') return 'Member';
  const role = roles.find((r) => r.name === jobRole);
  return role ? role.title : 'Undefined';
};

export const Details: React.FC<{
  action: string;
  jobRole: string;
  jobScopes: Scope[];
  definedRoles: LitmusRole[];
  definedScopes: ScopeData[];
}> = ({ action, jobRole, jobScopes, definedRoles, definedScopes }) => {
  const role = prepareRole(jobRole, definedRoles);
  const scopes = prepareScopes(jobScopes, definedScopes);

  return (
    <>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Bulk Update">
            {jobRole && (
              <div className={styles.pill}>
                <div className={styles.twoCols}>
                  <span>Role set to:</span>
                  <span className={styles.wordWrap} title={role}>
                    {role}
                  </span>
                </div>
              </div>
            )}
            {jobScopes && jobScopes.length > 0 && (
              <div className={styles.pill}>
                <div className={styles.twoCols}>
                  <span>Action:</span>
                  <span>{titleCase(action)}</span>
                </div>
                {Object.entries(scopes).map(([type, values]) => (
                  <div key={type} className={styles.twoCols}>
                    <span>{displayType(type)}</span>
                    <div>
                      {Object.entries(values).map(([scope, name]) => (
                        <div title={name} key={scope} className={styles.scope}>
                          {name}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Field>
        </div>
      </Fieldset>
    </>
  );
};
