import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { Microapp } from 'models/microapp';
import { EmptyablePage, Page } from './common';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  search?: string;
  page?: number;
  perPage?: number;
  status?: string[];
};

export type MicroappData = {
  id: string;
  type: string;
  attributes: Omit<Microapp, 'id'>;
};

export type MicroappsCollectionData = Page<Microapp>;
export type MicroappsEmptyableCollectionData = EmptyablePage<Microapp>;

export const fetchMicroapps = async (
  props: FetchProps
): Promise<MicroappsCollectionData> => {
  const { programId, search, page, perPage, status } = props;

  const q = qs.stringify(snakecaseKeys({ search, page, perPage, status }), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/samba/programs/${programId}/content_amplifiers?${q}&sortBy=id&sortDirection=asc`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching microapps: ${response.status}`);
};
