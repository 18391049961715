export const isWeekendDuration = (start: number, end: number): boolean => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 2) return false; // if diffDays is longer than 2 days, it must span more than one weekend

  return (
    (startDay === 5 && (endDay === 0 || endDay === 6)) || // starts on Friday and ends on Saturday or Sunday
    (startDay === 6 && endDay === 0) || // starts on Saturday and ends on Sunday
    (startDay === endDay &&
      (startDay === 6 || startDay === 0 || startDay === 5)) // starts and ends on the same weekend day
  );
};
