import {
  BaseReportJson,
  MetabaseReportJson,
  MetabaseReportType,
  PlaceholderReportJson,
} from './json/reportJson';
import {
  Filter,
  PlaceholderFilter,
  transformJsonToFilter,
  transformJsonToPlaceholderFilter,
} from './Filter';

export type BaseReport = {
  description: string | null;
  id: number;
  metabaseId: number;
  bookmarkCount: number;
  likeCount: number;
  slug: string;
  tags: string[];
  title: string | null;
  createdAt: Date;
  updatedAt: Date;
  userLiked: boolean;
};

export type PlaceHolderReport = BaseReport & {
  allowedProgramIds: string[];
  filters: PlaceholderFilter[];
};

export type MetabaseReport = BaseReport & {
  embedUrl: string;
  filters: Filter[];
  kind: MetabaseReportType;
  reportFlashMessage: string | null | undefined;
  dojoUrl: string | null | undefined;
  metabaseUrl: string | null | undefined;
  oldStudioUrl: string | null | undefined;
  programIdSlug: string;
};

export type ViewPort = {
  deviceScaleFactor: 1 | 2;
  height: number;
  width: number;
};

const transformJsonToBaseReport = (json: BaseReportJson): BaseReport => {
  return {
    description: json.description,
    id: json.id,
    metabaseId: json.mapped_id,
    bookmarkCount: json.program_bookmark_count,
    likeCount: json.program_like_count,
    slug: json.slug,
    tags: json.tags,
    title: json.title,
    createdAt: new Date(json.created_at),
    updatedAt: new Date(json.updated_at),
    userLiked: json.user_liked,
  };
};

export const transformJsonToPlaceholderReport = (
  json: PlaceholderReportJson
): PlaceHolderReport => {
  return {
    ...transformJsonToBaseReport(json ?? {}),
    allowedProgramIds: json.allowed_program_ids,
    filters: json.filter_params?.map(transformJsonToPlaceholderFilter) || [],
  };
};

export const transformJsonToMetabaseReport = (
  json: MetabaseReportJson
): MetabaseReport => {
  return {
    ...transformJsonToBaseReport(json ?? {}),
    embedUrl: json.embed_src,
    kind: json.kind,
    filters: json.filter_params?.map(transformJsonToFilter) || [],
    reportFlashMessage: json.report_flash_message,
    dojoUrl: json.dojo_url,
    oldStudioUrl: json.old_studio_url,
    metabaseUrl: json.metabase_url,
    programIdSlug: json.program_id_slug,
  };
};

export function isPlaceholderReport(
  report: BaseReport
): report is PlaceHolderReport {
  return (report as PlaceHolderReport).allowedProgramIds !== undefined;
}

export function isMetabaseReport(report: BaseReport): report is MetabaseReport {
  return (report as MetabaseReport).embedUrl !== undefined;
}
