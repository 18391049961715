import React, {
  ComponentProps,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ContentFilterBar,
  ContentFilterFetchProps,
} from 'components/content/ContentFilterBar/ContentFilterBar';
import { BulkSelectProvider } from 'contexts/bulk-select';
import { ContentFiltersContext } from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { useContentsInfiniteQuery } from 'hooks/content';
import { useReachByContentQueries } from 'hooks/reach';
import { useDebounce } from 'hooks/useDebounce';
import { ContentPage } from 'models/content-page';
import { PinnableContent } from 'models/topic';
import { FetchProps } from 'services/api-content';
import { PostsFinderView } from './PostsFinderView';
import styles from '../posts.module.css';

type PostsFinderProps = {
  selected: PinnableContent[];
  onSelected: ComponentProps<typeof PostsFinderView>['onSelected'];
};

export function PostsFinder({
  selected,
  onSelected,
}: PostsFinderProps): ReactElement {
  const {
    filters,
    setValue,
    searchParams,
    setBooleanValue,
    updateQueryString,
  } = React.useContext(ContentFiltersContext);

  const { id: programId } = useProgram();

  const selectedIds = useMemo(
    () => selected.map((post) => `content-${post.id}`),
    [selected]
  );

  const { hasValue, fieldValue } = searchParams;
  const defaultFilterFetchProps = hasValue ? { search: fieldValue } : {};

  const [filterFetchProps, setFilterFetchProps] = useState<
    ContentFilterFetchProps
  >(defaultFilterFetchProps);
  // the following variable is used to avoid extra updates
  const debouncedFilterFetchProps = useDebounce(filterFetchProps);

  useEffect(() => {
    updateQueryString('search', debouncedFilterFetchProps.search || '');
  }, [debouncedFilterFetchProps, updateQueryString]);

  // Only fetch "Sent" campaigns
  const fetchProps: FetchProps = useMemo(
    () => ({
      programId,
      sort: 'published_at',
      sortDirection: 'desc',
      publicationState: ['published'],
      processingState: ['completed', 'processing'],
      pageSize: 20,
      ...debouncedFilterFetchProps,
    }),
    [debouncedFilterFetchProps, programId]
  );

  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: contentsData,
    meta,
  } = useContentsInfiniteQuery(fetchProps, filters, false);

  const content = contentsData.flatMap((page: ContentPage) => page.data);
  const reachByContent = useReachByContentQueries(
    fetchProps.programId,
    contentsData
  );

  const [finderViewHeight, setFinderViewHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const filterBarRef = useRef<HTMLDivElement>(null);

  // Set the height of the finder view to the height of the container minus the height of the filter bar
  useLayoutEffect(() => {
    if (containerRef.current && filterBarRef.current) {
      const {
        height: containerHeight,
      } = containerRef.current.getBoundingClientRect();

      const {
        height: filterBarHeight,
      } = filterBarRef.current.getBoundingClientRect();

      setFinderViewHeight(containerHeight - filterBarHeight);
    }
  }, []);

  return (
    <div ref={containerRef} className={styles.postFinderContainer}>
      <ContentFilterBar
        ref={filterBarRef}
        fetchProps={filterFetchProps}
        onChange={setFilterFetchProps}
        filters={filters}
        setValue={setValue}
        setBooleanValue={setBooleanValue}
      />
      <BulkSelectProvider
        totalRecords={meta?.totalRecords ?? 0}
        selectedIds={selectedIds}
      >
        <PostsFinderView
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          content={content}
          reachByContent={reachByContent}
          onSelected={onSelected}
          height={finderViewHeight}
        />
      </BulkSelectProvider>
    </div>
  );
}
