import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Toast } from 'DesignSystem/Components/Toast';
import { SocialDataItemForm } from '../hooks/useSocialDataItemForm';
import { TitleInput } from './TitleInput';
import { ImageInput } from './ImageInput';
import { UrlInput } from '../../shared/UrlInput';

export const Editor: React.FC<{ form: SocialDataItemForm }> = ({ form }) => (
  <Flex column start>
    {form.fetcher?.error && (
      <Toast message={form.fetcher.error} severity="warning" />
    )}
    <UrlInput
      loading={form.isLoading}
      value={form.url.value}
      onChange={form.url.onChange}
      onReset={form.url.onReset}
    />
    <TitleInput value={form.title.value} onChange={form.title.onChange} />
    <ImageInput
      onReset={form.image.onReset}
      value={form.image.value}
      onChange={form.image.onChange}
      isUploading={form.image.isUploading}
    />
  </Flex>
);
