import qs from 'qs';
import { deepCamelcaseKeys, request } from 'services/api-shared';
import snakecaseKeys from 'snakecase-keys';
import { Activity } from 'models/activity';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type ActivityReadData = {
  id: number;
  type: 'audit-events';
  attributes: Omit<Activity, 'id'>;
};

export type ActivitiesCollectionData = {
  items: Array<Activity>;
  offset: number;
  limit: number;
  count: number;
};

export interface IActivityFilters {
  programId: number;
  identity?: string[];
  offset?: number;
  start?: string;
  end?: string;
}

export type ActivityExportFilters = Omit<IActivityFilters, 'programId'>;

interface IFetchActivities extends IActivityFilters {
  limit?: number;
}

const commonFetchFilters = ({
  identity,
  ...rest
}: ActivityExportFilters): Record<string, unknown> => {
  return snakecaseKeys({ identity, ...rest });
};

export const fetchActivities = async (
  filters: IFetchActivities
): Promise<ActivitiesCollectionData> => {
  const { programId, limit = 20, ...rest } = filters;
  const commonFilters = commonFetchFilters(rest);

  const query = qs.stringify(
    {
      ...commonFilters,
      limit,
      exclude_member_updates: true,
    },
    {
      arrayFormat: 'brackets',
    }
  );
  const url = `${apiRoot}/v2/tenants/program:${programId}/audit_logs?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((data) => {
      return {
        items: deepCamelcaseKeys(data.items),
        offset: filters.offset || 0,
        limit: filters.limit || 20,
        count: data.count,
      };
    });
  }
  throw new Error(`Error fetching activities: ${response.status}`);
};

export const exportActivityCsv = (programId: number) => async (
  filters: ActivityExportFilters
): Promise<Blob> => {
  const commonFilters = commonFetchFilters(filters);
  const url = `${apiRoot}/v2/tenants/program:${programId}/audit_logs/export`;

  try {
    const response = await request(url.toString(), {
      method: 'POST',
      body: JSON.stringify({
        ...commonFilters,
        exclude_member_updates: true,
      }),
    });
    if (response.ok) {
      return await response.blob();
    }
    throw new Error(`Error fetching activities: ${response.status}`);
  } catch (error) {
    throw new Error(`Error fetching activities: ${(error as Error).name}`);
  }
};
