import * as React from 'react';
import { usePublisher } from 'contexts/publisher';
import styles from './content-previews.module.css';

export const ReviewPreview: React.FC = () => {
  const publisher = usePublisher();
  return (
    <div className={styles.review}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${
            publisher.post.callToAction.image?.url ||
            'https://lib.onfirstup.com/patron/production/images/content_default0.png'
          })`,
        }}
      />
      <div className={styles.text}>
        <div className={styles.title}>
          {publisher.post.callToAction.title || 'No title'}
        </div>
        <div className={styles.description}>
          {publisher.post.callToAction.summary || 'No description'}
        </div>
      </div>
    </div>
  );
};
