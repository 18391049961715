import React, { ReactNode } from 'react';
import styles from './styles.module.css';

interface Props {
  title: ReactNode;
  description: ReactNode;
}

function NonEditablePlaceholder({ title, description }: Props): JSX.Element {
  return (
    <div className={styles.placeholder}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
  );
}

export default NonEditablePlaceholder;
