import type { Editor } from '@tiptap/react';
import { FormModal } from 'DesignSystem/Components';
import { Input } from 'DesignSystem/Form';
import React, { useState } from 'react';
import editorStyles from '../editor.module.css';
import { findLinkAtPosition, findWholeWordAtPosition } from '../utils/shared';
import { ToolbarButton } from './shared/ToolbarButton';

type LinkInput = { text: string; link: string };

const LinkMenu: React.FC<{
  value: LinkInput;
  onUpdate: (val: LinkInput) => void;
  onCancel: () => void;
}> = ({ value, onUpdate, onCancel }) => {
  const [text, setText] = useState(value.text);
  const [link, setLink] = useState(value.link);

  const actionText = value.link ? 'Edit' : 'Create';

  const handleUpdate = () => {
    onUpdate({
      text,
      link,
    });
  };

  return (
    <FormModal
      actionText={actionText}
      entityText="Link"
      onCancel={onCancel}
      onSubmit={handleUpdate}
    >
      <div className={editorStyles.linkModal}>
        <div className={editorStyles.linkModalField}>
          <label
            htmlFor="url-picker-text"
            className={editorStyles.linkModalHeading}
          >
            Text
          </label>
          <Input
            type="text"
            value={text}
            id="url-picker-text"
            onChange={setText}
            className={editorStyles.linkModalInput}
          />
        </div>
        <div className={editorStyles.linkModalField}>
          <label
            htmlFor="url-picker-href"
            className={editorStyles.linkModalHeading}
          >
            URL
          </label>
          <Input
            type="text"
            value={link}
            id="url-picker-href"
            onChange={setLink}
            className={editorStyles.linkModalInput}
          />
        </div>
      </div>
    </FormModal>
  );
};

export const EditorActionAddLink: React.FC<{ editor: Editor }> = ({
  editor,
}) => {
  const [editorText, setEditorText] = useState('');
  const [editorLink, setEditorLink] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleChange = (value: LinkInput) => {
    const previousEditorTextLength = editorText.length;
    setEditorText(value.text);
    const { selection } = editor.view.state;
    const { from, to } = selection;

    // Update value for text selection highlighting based on given link text length
    let updatedTo = to;
    const newTextLength = value.text.length;
    if (previousEditorTextLength !== newTextLength) {
      let textLengthDifference = 0;
      if (previousEditorTextLength < newTextLength) {
        textLengthDifference = newTextLength - previousEditorTextLength;
        updatedTo = to + textLengthDifference;
      } else {
        textLengthDifference = previousEditorTextLength - newTextLength;
        updatedTo = to - textLengthDifference;
      }
    }

    // Update text
    editor
      .chain()
      .focus()
      .setTextSelection({ from, to: updatedTo })
      .insertContentAt(
        {
          from,
          to,
        },
        `${value.text}`
      )
      .run();

    let url = value.link;
    if (url && url.length) {
      if (!url.startsWith('http')) {
        url = `https://${url}`;
      }
      editor
        .chain()
        .focus()
        .setTextSelection({ from, to: updatedTo })
        .setLink({ href: url, target: '_blank' })
        .run(); //
    } else {
      editor
        .chain()
        .focus()
        .setTextSelection({ from, to: updatedTo })
        .unsetLink()
        .run(); //
    }

    setModalOpen(false);
  };

  const handleClick = () => {
    const existingLink = editor.getAttributes('link');
    const { href } = existingLink;
    let text = '';

    const {
      state: {
        doc,
        selection: { from, to },
      },
    } = editor.view;
    let wordStart = from;
    let wordEnd = to;

    // Existing link in selection
    if (href) {
      const link = findLinkAtPosition(editor, from);

      // Get the text of the link
      wordStart = link.from;
      wordEnd = link.to;

      // If we haven't highlighted anything, select the nearest word
    } else if (from === to) {
      const word = findWholeWordAtPosition(editor, from);

      // Get the text of the word
      wordStart = word.from;
      wordEnd = word.to;
    }

    text = doc.textBetween(wordStart, wordEnd, ' ');

    editor
      .chain()
      .setTextSelection({
        from: wordStart,
        to: wordEnd,
      })
      .focus()
      .run();

    setEditorLink(existingLink?.href);
    setEditorText(text);
    setModalOpen(true);
  };

  return (
    <div>
      <ToolbarButton
        aria-label="Add link"
        onClick={handleClick}
        isActive={editor.isActive('link')}
        icon="add_link"
      />
      {modalOpen && (
        <LinkMenu
          value={{
            link: editorLink,
            text: editorText,
          }}
          onUpdate={handleChange}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};
