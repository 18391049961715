import * as React from 'react';
import { WeekPicker as LegacyWeekPicker } from 'shared/WeekPicker';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';

export const DateRangeButton: React.FC<{
  label: string;
  onClick: React.MouseEventHandler;
  goBack: () => void;
  goForward: () => void;
}> = ({ label, onClick, goBack, goForward }) => (
  <Flex>
    <Button
      text
      compact
      onClick={goBack}
      label={
        <svg
          width="8"
          height="16"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66667 0.666672L1.33334 8L6.66667 15.3333"
            stroke="var(--color-gray90)"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      }
    />
    <Button
      text
      compact
      onClick={onClick}
      label={<Text.Body>{label}</Text.Body>}
    />
    <Button
      text
      compact
      onClick={goForward}
      label={
        <svg
          width="8"
          height="16"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.33334 0.666672L6.66668 8L1.33334 15.3333"
            stroke="var(--color-gray90)"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      }
    />
  </Flex>
);

export const WeekPicker: typeof LegacyWeekPicker = LegacyWeekPicker;
