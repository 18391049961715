import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';

export const CreatePage: React.FC<RouteComponentProps<{ id: string }>> = () => (
  <BossViewPage
    app="samba"
    title="Microapps"
    fullScreen
    src="editor/content-anywhere"
    returnUrls={['/configure/microapps']}
  />
);
