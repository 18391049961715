import React from 'react';
import { FilterDropdownStructureContext } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import styles from '../../Filters.module.css';

export const FilterDropdownHeader: React.FC = ({ children }) => {
  const { setElemHeader } = React.useContext(FilterDropdownStructureContext);

  return (
    <div ref={setElemHeader} className={styles.filterDropdownHeader}>
      {children}
    </div>
  );
};
