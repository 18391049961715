import { Flex } from 'DesignSystem/Layout/Flex';
import React from 'react';
import { WarningTriangle } from 'shared/icons';
import { StepErrors } from 'models/journeys/journey-errors';
import styles from '../journey-drawer.module.css';

export const ErrorBanner: React.FC<{
  errors: StepErrors;
}> = ({ errors }) => {
  const errorMessages = Object.values(errors).flat();

  return (
    <Flex start className={styles.errorBanner}>
      <Flex>
        <WarningTriangle />
      </Flex>
      {errorMessages.length === 1 && <div>{errorMessages[0]}</div>}
      {errorMessages.length > 1 && (
        <Flex column className={styles.errorsList}>
          <div>There are {errorMessages.length} errors to correct</div>
          <ul>
            {errorMessages.map((message) => {
              return (
                <li key={`journey-validation-error-${message}`}>{message}</li>
              );
            })}
          </ul>
        </Flex>
      )}
    </Flex>
  );
};
