/* eslint-disable no-empty-pattern */

import React from 'react';
import cx from 'classnames';
import { Icon } from 'shared/Icon';
import { Link, MenuItem, useFooter } from 'hooks/useFooter';
import { NavLink } from 'shared/NavLink';
import { useNavigate } from '@reach/router';
import { useNavigationBlockerContext } from 'contexts/publisher/compose/navigation-blocker';
import { useClickDropdown } from 'shared/ClickDropdown';
import { ChevronDown, ChevronUp, Save } from 'shared/icons';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { DropdownMenu } from 'shared/NavigationFooter';
import { Button } from 'DesignSystem/Form/InputElements';
import { useFlashMessage } from 'contexts/flasher';
import {
  PostPersistanceStatus,
  usePersistanceStatus,
} from 'components/publisher/theme/PublisherFooter/PostPersistanceStatus';
import { Tooltip } from 'DesignSystem/Components/Tooltip';
import styles from './footer.module.css';
import { ScheduleContent } from './ScheduleContent';

type MenuItemsType = { [sectionName: string]: Array<MenuItem> };

type FooterProps = {
  leftMenuItems?: MenuItemsType;
  rightMenuAction?: <T>(arg: T) => void;
  links?: Array<Link>;
  action?: () => void;
  actionName?: string;
  actionClassName?: string;
  actionIconName?: string;
  nextPage?: string;
  title?: string;
  canPerformAction: boolean;
  status?: string;
  usePrevArrow?: boolean;
  hideSubmit?: boolean;
  hideMenu?: boolean;
  exitPath?: string;
  isWorking?: boolean;
  disableSchedule?: boolean;
  hideSchedule?: boolean;
  exitHandler?: () => void;
  saveDropdownDisabledMessage?: string;
  validateDelivery?: () => boolean;
};

export const Footer: React.FC<FooterProps> = (props) => {
  const {
    nextPage,
    leftMenuItems,
    rightMenuAction,
    links,
    exitPath,
    exitHandler,
    title,
    canPerformAction,
    status,
    hideSubmit,
    hideMenu,
    isWorking,
    disableSchedule = false,
    hideSchedule = false,
    saveDropdownDisabledMessage,
    validateDelivery,
  } = props;

  const {
    ClickDropdown: LeftDropdown,
    isOpen: leftMenuIsOpen,
  } = useClickDropdown();
  const { setFlashMessage } = useFlashMessage();
  const footer = useFooter(props);
  const navigate = useNavigate();
  const { blocker } = useNavigationBlockerContext({ noThrow: true });
  const { contentPermissions } = useSettings();
  const { canEdit } = contentPermissions;

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
    else if (exitPath) navigate(exitPath);
  }, [navigate, exitPath, exitHandler]);

  const isNextPageReview = nextPage === 'review';
  const nextPageLink = links?.find(
    (link) => link.name.toLowerCase() === nextPage
  );

  const validateNavClick = () => {
    if (!validateDelivery) {
      return true;
    }

    return isNextPageReview && validateDelivery();
  };

  const SaveButton: React.FC<{
    isWorking: boolean;
    status: string;
    action: <T>(arg: T) => void;
    disabledMessage?: string;
  }> = ({
    isWorking: saveIsWorking,
    status: saveStatus,
    action,
    disabledMessage,
  }) => {
    const { cloudStatus } = usePersistanceStatus();
    const isDisabled = !!disabledMessage;

    return (
      <>
        <Tooltip content={cloudStatus}>
          <div
            className={styles.statusCircle}
            style={{
              backgroundColor: cloudStatus === 'Up to date' ? 'green' : 'red',
            }}
          />
        </Tooltip>
        <div className={cx(styles.lastSave)}>
          <Tooltip content={<PostPersistanceStatus />}>
            <Button
              className={cx(styles.inlineSave, styles.saveButton, {
                [styles.disabled]: saveIsWorking || isDisabled,
              })}
              disabled={saveIsWorking || isDisabled}
              onClick={() =>
                action({
                  shouldValidate: saveStatus !== 'draft',
                })
              }
              label={<Save />}
            />
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <>
      <nav className={styles.navigation}>
        <div className={styles.subNav}>
          <div className={styles.controls}>
            <div className={styles.closeButtonContainer}>
              <button
                type="button"
                className={styles.close}
                onClick={handleClose}
              >
                <span className={styles.closeIcon}>
                  <Icon iconName="CloseLong" iconType="SVG" />
                </span>
                <div className={styles.exit}>Exit</div>
              </button>
            </div>

            {hideMenu && title && (
              <div className={styles.infoWrapper}>
                <div className={styles.info}>
                  <div className={cx(styles.title, styles.noMenu)}>{title}</div>
                </div>
              </div>
            )}

            {!hideMenu && leftMenuItems && canEdit && (
              <LeftDropdown
                upward
                dropdownClassName={styles.dropdown}
                dropdownRenderProp={(hideDropdown) => (
                  <DropdownMenu
                    hideDropdown={hideDropdown}
                    menuItems={leftMenuItems}
                  />
                )}
              >
                <div className={styles.infoWrapper}>
                  <div className={styles.info}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.title} dir="auto">
                        {title}
                      </div>
                      <div className={styles.iconContainer}>
                        {leftMenuIsOpen ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    </div>
                    <div className={styles.status}>{status}</div>
                  </div>
                  <div className={styles.hoverInfo}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.title} dir="auto">
                        {title}
                      </div>
                      <div className={styles.iconContainer}>
                        {leftMenuIsOpen ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    </div>
                    <div className={styles.status}>{status}</div>
                  </div>
                </div>
              </LeftDropdown>
            )}
          </div>
          {links && (
            <div className={styles.links}>
              {links.map((link: Link) => (
                <NavLink
                  validateClick={link.validateClick}
                  disabled={link.disabled}
                  key={link.name}
                  to={link.href}
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          )}
          <div className={styles.rightMenuItems}>
            {(canEdit || saveDropdownDisabledMessage) &&
              rightMenuAction &&
              status &&
              isWorking !== undefined && (
                <SaveButton
                  isWorking={isWorking}
                  status={status}
                  action={rightMenuAction}
                  disabledMessage={saveDropdownDisabledMessage}
                />
              )}
            {!hideSchedule && <ScheduleContent disabled={disableSchedule} />}
          </div>
        </div>

        <div className={styles.submitButton}>
          {!hideSubmit && (
            <>
              {nextPageLink ? (
                <Button
                  disabled={nextPageLink.disabled}
                  label={isNextPageReview ? 'Review' : 'Continue'}
                  className={styles.submit}
                  onClick={() => {
                    if (!validateNavClick()) {
                      return;
                    }

                    if (blocker && blocker.enabled) {
                      setFlashMessage({
                        message: blocker.message,
                        severity: 'error',
                      });
                      return;
                    }
                    footer.goForward(nextPage);
                  }}
                />
              ) : (
                <Button
                  label={footer.actionName || ''}
                  className={styles.submit}
                  onClick={footer.action || (() => {})}
                  disabled={!canPerformAction}
                />
              )}
            </>
          )}
        </div>
      </nav>
    </>
  );
};
