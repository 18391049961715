import * as React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Label } from 'shared/Label';
import { Thumbnail } from 'shared/Thumbnail';
import { Measures, measuresMock } from 'shared/publisher/results/Measures';
import styles from './engagement-summary.module.css';

export const EngagementSummary: React.FC = () => (
  <Section title="Engagement summary">
    <Row>
      <Block className={styles.summary}>
        <div className={styles.block}>
          <Label containerClassName={styles.expected} name="Expected Reach">
            425
          </Label>
        </div>
        <div className={styles.block}>
          <Thumbnail backgroundColor="var(--color-gray00)" title="Chart" />
        </div>
        <div className={styles.block}>
          <Label containerClassName={styles.actual} name="Actual Reach">
            471
          </Label>
        </div>
      </Block>
      <Block>
        <Measures data={measuresMock} />
      </Block>
    </Row>
  </Section>
);
