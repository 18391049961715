import { useProgram } from 'contexts/program';
import { useTopicQuery } from 'hooks/topics';
import { LandingPageTabType, Topic } from 'models/topic';
import React, { useEffect, useState } from 'react';
import { PagesControlContext } from './components/Form/PagesControl/PagesControlContext';

export const WithPagesControl: React.FC<{ topicId: Topic['id'] }> = ({
  children,
  topicId,
}) => {
  const { id: programId } = useProgram();
  const { data: topic } = useTopicQuery(programId, topicId as string);

  const [pinnedPostsIncluded, setPinnedPostsIncluded] = useState(false);

  useEffect(() => {
    setPinnedPostsIncluded(
      topic?.landingPageTabs !== undefined &&
        topic?.landingPageTabs !== null &&
        topic.landingPageTabs.some(
          (tab) =>
            tab.tabType === LandingPageTabType.Posts &&
            tab.pinnedContents?.length > 0
        )
    );
  }, [topic]);

  return (
    <PagesControlContext.Provider
      value={{
        pinnedPostsIncluded,
        setPinnedPostsIncluded,
      }}
    >
      {children}
    </PagesControlContext.Provider>
  );
};
