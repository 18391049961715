import React from 'react';
import { ListDropdownItem } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { useProgram } from 'contexts/program';
import { PaginatedListDropdownProvider } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { PaginatedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/PaginatedListDropdown';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { useInitiativesInfiniteQuery } from 'hooks/initiatives';

export const InitiativesListDropdown: React.FC = () => {
  const { id: programId } = useProgram();
  const { defaultValues } = React.useContext(FilterDropdownContext);
  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInitiativesInfiniteQuery({
    programId,
  });

  // note: useInitiativesInfiniteQuery() returns the aggregated list of all records
  const allItems: ListDropdownItem[] = React.useMemo(() => {
    if (data === undefined) return [];
    return data.map((initiative) => ({
      label: String(initiative.name),
      value: String(initiative.id),
      uuid: String(initiative.id),
    }));
  }, [data]);

  return (
    <PaginatedListDropdownProvider
      allItems={allItems}
      fetchNext={fetchNextPage}
      isLoadingMoreItems={isLoading || isFetchingNextPage}
      hasMoreToLoad={hasNextPage || false}
      defaultValues={defaultValues}
      onSearch={() => {}}
    >
      <PaginatedListDropdown />
    </PaginatedListDropdownProvider>
  );
};
