import { useState, useCallback, useMemo, useEffect } from 'react';
import { InfiniteQueryResponse, PaginationState } from 'hooks/common';
import { useDebounce } from 'hooks/useDebounce';
import { useProgram } from 'contexts/program';
import {
  defaultState,
  FiltersStateType,
  useFilters,
} from 'contexts/content/filters';
import { ContentFilterFetchProps } from 'components/content/ContentFilterBar/ContentFilterBar';
import { ProgramIdProp } from 'services/api-insights';
import { Author } from 'models/author';
import { Topic } from 'models/topic';
import { Content } from 'models/content';
import { Content as LibraryContent } from 'models/library';

export type UseCollection = {
  isLoading: boolean;
  filters: FiltersStateType;
  setFilterValue: (name: string, values: (string | Author | Topic)[]) => void;
  setFilterBoolean: (name: string, value: boolean) => void;
  setSearch: (value: ContentFilterFetchProps) => void;
  search: ContentFilterFetchProps;
  items: Content[];
  libraryContents: LibraryContent[];
  pagination: PaginationState;
  select: (item: Content, op: 'add' | 'remove') => void;
  selection: Array<Content>;
};

const libraryDefaultFilterState = {
  standard: {
    publicationState: {
      name: 'publication_state',
      field: 'publication_state',
      label: 'State',
      values: ['published'],
      states: ['published', 'scheduled'],
      isVisible: true,
    },
  },
};

export const useCollection = (
  useCollectionSource: (
    fetchProps: ContentFilterFetchProps & ProgramIdProp,
    filters: FiltersStateType
  ) => InfiniteQueryResponse<Content>
): UseCollection => {
  const { id: programId } = useProgram();
  const [selection, setSelection] = useState<Content[]>([]);
  const { filters, setValue, setBooleanValue } = useFilters({
    customDefaultState: defaultState(libraryDefaultFilterState),
  });

  // This prevents draft posts from appearing in query results
  useEffect(() => {
    if (filters.standard.publicationState.values.length === 0)
      setValue('publicationState', ['published']);
  }, [filters, setValue]);

  const defaultContentFilter = useMemo(() => {
    const { values } = filters.standard.publicationState;
    return {
      sort: 'published_at',
      sortDirection:
        values.length === 1 && values.includes('scheduled') ? 'asc' : 'desc',
      pageSize: 20,
    };
  }, [filters]);

  const [filterFetchProps, setFilterFetchProps] = useState<
    ContentFilterFetchProps
  >({});
  const fetchProps = {
    programId,
    ...defaultContentFilter,
    ...useDebounce(filterFetchProps),
  };
  const { isLoading, data: items, ...pagination } = useCollectionSource(
    fetchProps,
    filters
  );

  const libraryContents = useMemo(
    () =>
      (selection.map((post) => ({
        type: 'content_post',
        id: post.id.toString(),
        title: post.title,
        description: post.summary,
        categories: [],
        tags: [],
        thumbnail_images: [],
        asset: {
          post_url: post.permalinkUrl ?? '',
          image_url: post.imageUrl ?? '',
          read_time_in_seconds: post.readTimeInSeconds,
        },
      })) as unknown[]) as LibraryContent[],
    [selection]
  );

  const handleSelection = useCallback(
    (item: Content, op: 'add' | 'remove') => {
      if (op === 'remove')
        setSelection(selection.filter((selected) => selected.id !== item.id));
      else {
        const next: Content[] = [...selection];
        const ids = next.map((selected) => selected.id);
        if (!ids.includes(item.id)) next.push(item);
        setSelection(next);
      }
    },
    [selection, setSelection]
  );

  return {
    isLoading,
    filters,
    setFilterValue: setValue,
    setFilterBoolean: setBooleanValue,
    setSearch: setFilterFetchProps,
    search: filterFetchProps,
    items,
    libraryContents,
    pagination,
    select: handleSelection,
    selection,
  };
};
