import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Label } from 'models/label';
import { Body } from 'DesignSystem/Typography';
import { ContentTitle } from '../../SettingsCard';

export const AcknowledgeLabel: React.FC<{
  label: Label;
}> = ({ label: { text } }) => (
  <Box>
    <Box padding={[23, 0, 0, 0]}>
      <ContentTitle>Acknowledge Label</ContentTitle>
    </Box>
    <Box>
      <Body>{text}</Body>
    </Box>
  </Box>
);
