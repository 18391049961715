import React from 'react';
import { Helmet } from 'react-helmet';
import { useProgram } from 'contexts/program';
import { useEmailAliasQuery } from 'hooks/email-alias';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { EmailAlias } from 'models/email-alias';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { RouteComponentProps } from '@reach/router';
import { asserts } from 'utility/asserts';
import { Form } from './components/Form/EmailAliasForm';
import { Footer } from './components/Footer';
import { useActions } from './useActions';
import { Sidebar } from './components/Sidebar';
import styles from './email-alias.module.css';
import { useEmailAliasForm } from './NewEmailAlias';

export const EditEmailAlias = ({
  id,
}: RouteComponentProps<{ id: string }>): React.ReactElement => {
  asserts(id !== undefined, 'ID parameter cannot be undefined');

  const { id: programId } = useProgram();
  const { data: emailAliasData, isLoading, errorMessage } = useEmailAliasQuery(
    programId,
    id
  );
  const {
    emailAlias,
    handleEmailAliasChange,
    handleBlur,
    touched,
    errors,
    hasErrors,
  } = useEmailAliasForm();

  const { update, archive, unarchive } = useActions(handleEmailAliasChange);

  React.useEffect(() => {
    if (emailAliasData) handleEmailAliasChange(emailAliasData);
  }, [emailAliasData, handleEmailAliasChange]);

  const header = (
    <>
      <h1 className="page-header">Email Alias</h1>
    </>
  );
  const main = (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && (
        <Form
          emailAlias={emailAlias}
          onChange={handleEmailAliasChange}
          errors={errors}
          onFieldBlur={handleBlur}
          touched={touched}
        />
      )}
    </>
  );

  function onSave() {
    update(emailAlias as EmailAlias);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <Helmet>
        <title>Edit Email Alias</title>
      </Helmet>
      {emailAliasData && (
        <MainEditor
          header={header}
          main={main}
          sidebar={
            <Sidebar
              emailAlias={emailAliasData}
              archive={archive}
              unarchive={unarchive}
            />
          }
          footer={
            <Footer
              emailAlias={emailAlias}
              action={onSave}
              allowSaving={!hasErrors}
            />
          }
        />
      )}
    </WithPermission>
  );
};
