import * as React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { AbsoluteRange } from './DateRange';
import { DateRangeInputStateType } from './types';
import styles from './month.module.css';

type PropsType = {
  allowFutureSelection: boolean;
  className?: string;
  dateRange?: DateRangeInputStateType['dateRange'];
  month: DateTime;
  onDateClick: (date: DateTime) => void;
  selectedDate?: DateRangeInputStateType['selectedDate'];
  singleDate?: DateTime;
  upperLimit?: DateTime;
  lowerLimit?: DateTime;
};

/* Ignoring some accessibility concerns here in order to make a better experience.
   Dates can be changed via arrow buttons in AbsoluteControl, making every day of
   two months a button would make tab navigation a nightmare. */
/* eslint-disable  jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-static-element-interactions */
export const Month: React.FC<PropsType> = (props) => {
  const {
    allowFutureSelection,
    className,
    dateRange,
    month,
    onDateClick,
    selectedDate,
    singleDate,
    upperLimit,
    lowerLimit,
  } = props;

  return (
    <div className={cx(styles.month, className)}>
      <header>
        <strong>{month.toFormat('MMMM yyyy')}</strong>
      </header>
      <div>
        <span className={styles.dayHead}>S</span>
        <span className={styles.dayHead}>M</span>
        <span className={styles.dayHead}>T</span>
        <span className={styles.dayHead}>W</span>
        <span className={styles.dayHead}>T</span>
        <span className={styles.dayHead}>F</span>
        <span className={styles.dayHead}>S</span>
        {(() => {
          /* force luxon week to start on sunday instead of monday */
          const today = DateTime.now().endOf('day').plus({ minutes: 1 });
          const mo_first = month.startOf('month').startOf('day');
          const cal_first = mo_first
            .startOf('week')
            .minus({ days: 1 })
            .startOf('day');
          const mo_last = month.endOf('month').endOf('day');
          const cal_last =
            mo_last.weekday === 7
              ? mo_last
                  .endOf('week')
                  .plus({ days: 1 })
                  .endOf('week')
                  .endOf('day')
              : mo_last.endOf('week').endOf('day');
          const spans = [];
          let current = cal_first.set({ hour: 7 });

          while (!current.hasSame(cal_last, 'day')) {
            const classNames = [styles.daySpan];

            const notSelectable =
              (current > today && !allowFutureSelection) ||
              (upperLimit && current.ordinal > upperLimit.ordinal) ||
              (lowerLimit && current.ordinal < lowerLimit.ordinal);

            if (notSelectable) {
              classNames.push(styles.notSelectable);
            }

            if (current < mo_first || current > mo_last) {
              classNames.push(styles.outOfMonth);
            }

            if (dateRange) {
              const abs = dateRange.toAbsolute();

              if (current.hasSame(abs.start, 'day')) {
                classNames.push(styles.startOfRange);
                if (dateRange instanceof AbsoluteRange) {
                  classNames.push(styles.rangeCap);
                  if (selectedDate === 'start') {
                    classNames.push(styles.selectedDate);
                  }
                }
              }

              if (current > abs.start && current < abs.end) {
                classNames.push(styles.inRange);
              }

              if (current.hasSame(abs.end, 'day')) {
                classNames.push(styles.endOfRange);
                if (dateRange instanceof AbsoluteRange) {
                  classNames.push(styles.rangeCap);
                  if (selectedDate === 'end') {
                    classNames.push(styles.selectedDate);
                  }
                }
              }
            } else if (singleDate) {
              if (
                current.hasSame(singleDate, 'day') &&
                current.hasSame(singleDate, 'month')
              ) {
                classNames.push(styles.selectedDate);
              }
            }

            spans.push(
              <span
                className={cx(classNames)}
                key={spans.length}
                onClick={((date) => () =>
                  notSelectable ? null : onDateClick(date))(current)}
              >
                <span>{current.day}</span>
              </span>
            );
            current = current.plus({ day: 1 });
          }
          return spans;
        })()}
      </div>
    </div>
  );
};
