import React from 'react';

import { ConfirmModal } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { Body } from 'DesignSystem/Typography';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MAIcon } from 'shared/MAIcon';
import styles from './language-control-list-item.module.css';

const MODAL_BODY_COPY =
  'The language control will be deleted. This action cannot be undone.';

type LanguageControlsDeleteModalProps = {
  onCancel: () => void;
  onDelete: () => void;
  isLoading?: boolean;
};

export const LanguageControlsDeleteModal: React.FC<LanguageControlsDeleteModalProps> = ({
  onCancel,
  onDelete,
  isLoading,
}) => (
  <ConfirmModal
    title="Delete Language Control?"
    titleIcon={
      <div>
        <MAIcon name="warning" className={styles.deleteModalIcon} />
      </div>
    }
    onCancel={onCancel}
    confirmButton={
      <AsyncDeleteButton
        onDelete={onDelete}
        isDisabled={isLoading}
        isLoading={isLoading}
      />
    }
  >
    <Body semibold>{MODAL_BODY_COPY}</Body>
  </ConfirmModal>
);

type AsyncDeleteButtonProps = {
  onDelete: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const AsyncDeleteButton: React.FC<AsyncDeleteButtonProps> = ({
  onDelete,
  isDisabled,
  isLoading,
}) => {
  const label = (
    <>
      Delete
      {isLoading && (
        <>
          &nbsp;
          <LoadingSpinner size="xsmall" />
        </>
      )}
    </>
  );

  return <Button disabled={isDisabled} onClick={onDelete} label={label} />;
};
