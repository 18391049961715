import React, { useMemo } from 'react';
import { Shortcut } from 'models/shortcut';
import { ThumbnailType } from 'shared/BannerListItem';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { Link } from '@reach/router';
import { useShortcutActions } from './useShortcutActions';
import styles from './shortcuts.module.css';
import { ShortcutTopicsAndAudiencesDisplay } from './components/ShortcutTopicsAndAudiencesDisplay';

const shortcutToThumbnail = (shortcut: Shortcut): ThumbnailType => {
  return {
    title: shortcut?.name,
    imageUrl: shortcut.imageUrl || shortcut?.service?.iconUrl,
  };
};

export type PropsType = {
  shortcut: Shortcut;
};

export const ShortcutBanner = ({ shortcut }: PropsType): JSX.Element => {
  const { name, status, updatedAt, audienceList } = shortcut;

  const thumbnail = shortcutToThumbnail(shortcut);

  const formattedDate = updatedAt ? new Date(updatedAt).toDateString() : '-';

  const { menuItems, deleteItem } = useShortcutActions({
    shortcut,
  });

  const selectedAudiences = useMemo(
    () =>
      audienceList?.map((audience) => ({
        id: String(audience.id),
        name: audience.title,
        count: audience.totalUsers,
      })) ?? [],
    [audienceList]
  );

  return (
    // for completeness, probably should also have 'cell' roles in the EmptyBanner,
    // but accessibility isnt the highest priority and not going to mess with the shared component yet.
    // (and none of them would map to a header at time of writing)
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      deleteItem={deleteItem}
    >
      <Link to={`./${shortcut.id.toString()}`} className={styles.listLink}>
        <div role="cell" className={styles.title}>
          <span className={styles.shortcutHeader}>Link Name</span>
          <span className={styles.shortcutText}>{name}</span>
        </div>
        <div role="cell" className={styles.status}>
          <span className={styles.shortcutHeader}>Status</span>
          <span className={styles.shortcutText}>{status}</span>
        </div>
        <div role="cell" className={styles.audience}>
          <span className={styles.shortcutHeader}>Target Audience</span>
          <ShortcutTopicsAndAudiencesDisplay
            topicsAndAudiences={selectedAudiences}
          />
        </div>
        <div role="cell">
          <span className={styles.shortcutHeader}>Last Updated</span>
          <span className={styles.shortcutText}>{formattedDate}</span>
        </div>
      </Link>
    </EmptyBanner>
  );
};
