import camelcaseKeys from 'camelcase-keys';
import { Facet } from 'models/facet';
import { bossanovaDomain, request } from './api-shared';

function programGroupsRoot(programId: number): string {
  return `${bossanovaDomain}/samba/programs/${programId}/groups`;
}

const headers = {
  'Content-Type': 'application/json',
  'x-requested-with': 'XMLHttpRequest',
};

export type FetchFacetsResponse = Facet[] | undefined;

export const fetchUsersFacets = async (
  q: string,
  programId: number,
  facetNames: string[]
): Promise<FetchFacetsResponse> => {
  if (q === '') {
    return undefined;
  }

  const url = `${programGroupsRoot(programId)}/query/facets`;
  const data = {
    q,
    facet_names: facetNames,
    include_other: true,
    size: 3,
    visibility: 'visible',
  };

  const response = await request(url, {
    headers,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    return response.json().then((json) => {
      return camelcaseKeys(json);
    });
  }

  throw new Error(`Error fetching facets: ${response.status}`);
};

// Gov endpoint returns object with { group_name: 'Group Title' }
// instead of an array of name/title pairs. This maintains that
// structure, which also makes looking up titles by name easier.
export type GroupQueryGroupTitles = { [name: string]: string };

export async function fetchGroupQueryGroupTitles(
  programId: number,
  groupName: string
): Promise<GroupQueryGroupTitles> {
  if (groupName === '') {
    return {};
  }

  const url = `${programGroupsRoot(programId)}/${groupName}/group_names`;
  const response = await request(url, {
    headers,
    method: 'GET',
  });

  if (response.status !== 200) {
    throw new Error(
      `Error ${response.status} fetching group names for Program ID ${programId} Group name ${groupName}`
    );
  }

  return response.json().then((json) => json || {});
}
