import * as React from 'react';
import { Template } from 'models/library';
import { InfiniteSelect, InfiniteLoadProps } from 'shared/InfiniteSelect';
import styles from './templates-select.module.css';

export type PropsType = {
  values: Template[];
  selectedIds: string[];
  onSelectedIdsChange: (ids: string[]) => void;
  maxHeight?: number;
} & InfiniteLoadProps;

export const Dropdown: React.FC<PropsType> = (props) => {
  const {
    values,
    selectedIds,
    onSelectedIdsChange,
    maxHeight = 400,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
  } = props;

  const rowIds: string[] = values.map((item) => item.id || '');

  const rowRenderProp = (id: string) => {
    const audience = values.find((item) => item.id === id);
    if (audience) {
      const { title, description } = audience;
      return (
        <div key={id} className={styles.row}>
          <div className={styles.title}>{title}</div>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.dropdown}>
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={rowRenderProp}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        itemHeight={60}
        maxHeight={maxHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        threshold={threshold}
        overscan={overscan}
      />
    </div>
  );
};
