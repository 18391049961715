import { Filter } from 'models/insight/Filter';
import { safeCopyMap } from 'utility/ie11Utils';
import { Option } from 'models/insight/json/filterJson';
import { FilterValue } from 'services/api-insights';

/**
 * This is the state machine for the selected filters.
 * all interactions with the state object should be done via actions
 * otherwise you risk modifying the object which can cause subtle bugs
 *
 * also the reducer function should be implemented within a React.useCallback()
 * otherwise you risk the reducer function executing twice, which leads to bugs
 * */

export type DeepLinkedParamsType = Map<string, Set<string>>;
export type FilterState = {
  filter: Filter;
  isLoading: boolean;
  selectedValues?: Set<FilterValue>;
  shouldAutoOpen?: boolean;
  availableListValues?: Option[];
  errorMessage?: string;
};
export type FiltersStateMap = Map<string, FilterState>;
export type FiltersStateAction =
  | {
      action: 'addFilter';
      filterState: FilterState;
    }
  | {
      action: 'removeFilter';
      filter: Filter;
    }
  | {
      action: 'setAllFilters';
      filtersMap: FiltersStateMap;
    }
  | {
      action: 'setSingleFilterValue';
      filter: Filter;
      value: FilterValue;
      isToggleable?: boolean;
    }
  | {
      action: 'setAllFilterValues';
      filter: Filter;
      valuesSet: Set<FilterValue>;
    }
  | {
      action: 'setFilterState';
      filter: Filter;
      isLoading: boolean;
      availableListValues?: Option[];
      errorMessage?: string;
    }
  | { action: 'clearAllFilters' };

export const filtersStateReducer = (
  state: FiltersStateMap,
  action: FiltersStateAction
): FiltersStateMap => {
  const newMap = safeCopyMap(state);
  switch (action.action) {
    case 'addFilter': {
      newMap.set(action.filterState.filter.slug, {
        filter: { ...action.filterState.filter },
        shouldAutoOpen: true,
        availableListValues: action.filterState.availableListValues,
        isLoading: action.filterState.isLoading,
        errorMessage: action.filterState.errorMessage,
      });

      // automatically set any subFilters that might exist for the filter
      const { subFilter } = action.filterState.filter;
      if (subFilter)
        newMap.set(subFilter.slug, {
          filter: subFilter,
          isLoading: false,
        });

      return newMap;
    }
    case 'removeFilter': {
      newMap.delete(action.filter.slug);

      // remove any subFilters that the filter might have
      const { subFilter } = action.filter;
      if (subFilter) newMap.delete(subFilter.slug);

      return newMap;
    }
    case 'setAllFilters':
      // NOTE: this will completely override the filter state variable
      // ensure that the filtersMap passed in contains the desired states for each filter
      // otherwise very subtle bugs may occur
      return safeCopyMap(action.filtersMap);
    case 'setSingleFilterValue': {
      // for setting one filter value at a time
      // list of selected filter values are tracked in a set
      // duplicate value selections will be interpreted as a deletion if `isToggleable=true`
      // set can have one or multiple values, depending on `filter.allowMultiple`
      const { value, isToggleable } = action;
      const updatedFilter = newMap.get(action.filter.slug);
      const allowMultipleSelections = action.filter.allowMultiple;

      // do nothing if the filter is not found.
      if (!updatedFilter) return newMap;

      // the new set of values for the returned map
      let valuesSet: Set<FilterValue>;

      // if value is undefined or null, it means we deselected the option and nothing remains.
      // in such cases, return an empty set
      // IMPORTANT! be sure to always guard against adding undefined or null to a set
      // it was cause BUGZZZZZZZ
      if (value === undefined || value === null) {
        valuesSet = new Set<FilterValue>();
      }

      // we de/selected something. take the existing set of values or create a new one
      else {
        valuesSet = updatedFilter.selectedValues || new Set<FilterValue>();
        // remove the value from the set
        if (valuesSet.has(value) && isToggleable) {
          valuesSet.delete(value);
        }
        // add the value to the set
        else {
          if (!allowMultipleSelections) valuesSet.clear();
          valuesSet.add(value);
        }
      }

      updatedFilter.selectedValues = valuesSet;
      newMap.set(updatedFilter.filter.slug, updatedFilter);
      return safeCopyMap(newMap);
    }
    case 'setAllFilterValues': {
      // this is for infinite list dropdowns
      // receive a set of values and set it directly on the filter
      const updatedFilter = newMap.get(action.filter.slug);

      // do nothing if the filter is not found. this really shouldn't ever happen
      // since a value can only be set on a filter that is already "selected"
      if (!updatedFilter) return newMap;
      updatedFilter.selectedValues = action.valuesSet;

      newMap.set(updatedFilter.filter.slug, updatedFilter);
      return newMap;
    }
    case 'setFilterState': {
      const updatedFilter = newMap.get(action.filter.slug);
      // do nothing if the filter is not found. this really shouldn't ever happen
      // since a value can only be set on a filter that is already "selected"
      if (!updatedFilter) return newMap;
      const { availableListValues, isLoading, errorMessage } = action;

      // deep copy of filter and its config options
      newMap.set(updatedFilter.filter.slug, {
        filter: { ...updatedFilter.filter },
        isLoading,
        errorMessage,
        availableListValues,
      });
      return newMap;
    }
    case 'clearAllFilters':
      return new Map();
    default:
      return newMap;
  }
};
