import React from 'react';
import { TemplateSelector } from 'shared/TemplateSelector';
import { Sidebar } from 'shared/TemplateSelector/Sidebar';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { Template } from 'models/library';
import { SplitFooter } from 'publish/designs/SplitFooter';
import { useCreateTemplateFromLibrary } from 'hooks/useLibrary';
import { LoadingScreen } from 'shared/LoadingScreen';
import styles from './templates-modal.module.css';

export const TemplatesModal: React.FC<{
  exitHandler: () => void;
  onTemplateSelect: (template: Template) => void;
  close: () => void;
}> = ({ exitHandler, onTemplateSelect, close }) => {
  const createTemplateFromLibrary = useCreateTemplateFromLibrary();
  const [
    isResolvingLibraryTemplate,
    setIsResolvingLibraryTemplate,
  ] = React.useState(false);
  const footer = (
    <SplitFooter
      canEditTitle={false}
      showTitle={false}
      title="Content"
      canPerformAction={false}
      exitHandler={exitHandler}
      hideMenu
      actionName="Save and close"
    />
  );

  // the reason for this being handled inside the modal
  // is because we want to await () for the template creation
  // before initializing the blocks editor
  const handleLibraryTemplateSelect = React.useCallback(
    (t?: string) => {
      setIsResolvingLibraryTemplate(true);
      createTemplateFromLibrary(t).then((libraryTemplate) => {
        onTemplateSelect(libraryTemplate);
        setIsResolvingLibraryTemplate(false);
        close();
      });
    },
    [close, createTemplateFromLibrary, onTemplateSelect]
  );

  const mainWrapperRef = React.useRef<HTMLDivElement>(null);

  if (isResolvingLibraryTemplate) return <LoadingScreen />;
  return (
    <div className={styles.modal}>
      <MainEditor
        splitBars
        header={<></>}
        main={
          <TemplateSelector
            parentRef={mainWrapperRef}
            onSelect={(template: Template) => {
              onTemplateSelect(template);
              close();
            }}
          />
        }
        sidebar={<Sidebar onSelect={handleLibraryTemplateSelect} />}
        footer={footer}
        mainWrapperRef={mainWrapperRef}
      />
    </div>
  );
};
