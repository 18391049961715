import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Topic } from 'models/topic';
import React from 'react';
import { TargetSection } from './TargetSection';
import { GeneralSection } from './GeneralSection';
import { ContributorSection } from './ContributorSection';
import { UserSubmissionsSection } from './UserSubmissionsSection';
import { VisibilitySection } from './VisibilitySection';
import { PagesSection } from './PagesSection';
import styles from './form.module.css';
import { useTopicErrors } from '../../useTopicErrors';

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>, error?: true) => void;
  disablePages?: boolean;
};

export const Form: React.FC<PropsType> = ({
  topic,
  onChange,
  disablePages = false,
}) => {
  const { id: programId } = useProgram();

  const errors = useTopicErrors(topic);

  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );
  const { data: topicLandingEnabled } = useFeatureFlagsQuery(
    programId,
    'EE.TopicLanding.EnabledOnExperience'
  );
  const { data: topicLandingConfigEnabled } = useFeatureFlagsQuery(
    programId,
    'EE.TopicLanding.ConfigEnabled'
  );

  const showPagesSection = Boolean(
    topicLandingEnabled?.value && topicLandingConfigEnabled?.value
  );

  return (
    <div className={styles.topicForm}>
      {topic?.default && (
        <div className={styles.imageWrapper}>
          <div className={styles.imageSection}>&nbsp;</div>
          <div className={styles.imageContainer}>
            <i className="fa-regular fa-lightbulb" />
            <span className={styles.bannerText}>
              This topic cannot be edited because it is the default topic
            </span>
          </div>
        </div>
      )}
      <GeneralSection topic={topic} onChange={onChange} />
      <TargetSection topic={topic} onChange={onChange} errors={errors} />
      {!showPermissionsUI?.value && (
        <ContributorSection topic={topic} onChange={onChange} />
      )}
      <UserSubmissionsSection topic={topic} onChange={onChange} />
      <VisibilitySection topic={topic} onChange={onChange} errors={errors} />
      {showPagesSection && (
        <PagesSection
          topic={topic}
          onChange={onChange}
          disabled={disablePages}
          errors={errors}
        />
      )}
    </div>
  );
};
