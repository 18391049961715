import React from 'react';
import { NewFilterButton } from 'DesignSystem/Table/NewFilterButton';
import NestableMenu from 'shared/NestableMenu';
import { NestableMenuType } from 'shared/NestableMenu/types';

type Filter = {
  name: string;
  label: string;
};

type PropsType = {
  filters: Filter[];
  onSelectFilter: (filter: string) => void;
};
export const FiltersButton: React.FC<PropsType> = ({
  filters,
  onSelectFilter,
}) => {
  const rootMenuData: NestableMenuType<Filter> = React.useMemo(() => {
    return {
      simpleMenu: {
        onSelect: (filter) => {
          onSelectFilter(filter.name);
        },
        items: filters.map((filter: Filter) => {
          return {
            label: filter.label,
            key: filter.name,
            value: filter,
          };
        }),
      },
    };
  }, [filters, onSelectFilter]);

  const menuButton = React.useMemo(
    () => (
      <div>
        <NewFilterButton />
      </div>
    ),
    []
  );

  return <NestableMenu menuButton={menuButton} rootMenuData={rootMenuData} />;
};
