import { ImageFieldData } from './donkey';

export enum IframeMode {
  url = 'url',
  rawHtml = 'rawHtml',
}

export type IframeData = {
  mode: IframeMode;
  url?: string;
  rawHtml?: string;
  image: ImageFieldData;
};
