import React, { createContext, useState } from 'react';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'shared/BulkSelect/Bar/ConfirmModal';

type ShowConfirmation = Omit<ConfirmModalProps, 'onCancel'>;

interface IConfirmationContext {
  showConfirmation: (data: ShowConfirmation) => void;
}

export const ConfirmationContext = createContext<IConfirmationContext>({
  showConfirmation: () => ({}),
});

export const ConfirmationProvider: React.FC = ({ children }) => {
  const [
    confirmationState,
    setConfirmationState,
  ] = useState<ConfirmModalProps | null>();

  const handleCancel = () => {
    setConfirmationState(null);
  };

  const handleConfirm = React.useCallback(() => {
    confirmationState?.onConfirm();
    setConfirmationState(null);
  }, [confirmationState]);

  const showConfirmation = React.useCallback((state: ShowConfirmation) => {
    setConfirmationState({ ...state, onCancel: handleCancel });
  }, []);

  return (
    <ConfirmationContext.Provider value={{ showConfirmation }}>
      <>
        {children}
        {confirmationState && (
          <ConfirmModal
            onCancel={confirmationState.onCancel}
            onConfirm={handleConfirm}
            actionTitle={confirmationState.actionTitle}
            count={confirmationState.count}
            customTitle={confirmationState.customTitle}
            headerTitle={confirmationState.headerTitle}
            hideLabel={confirmationState.hideLabel}
            itemName={confirmationState.itemName}
          />
        )}
      </>
    </ConfirmationContext.Provider>
  );
};
