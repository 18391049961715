import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { MetabaseEmbedIframe } from 'App/Program/Main/Insight/components/MetabaseEmbedIframe';
import { CollectionsContext } from 'App/Program/Main/Insight/contexts/CollectionsContext';
import { NonFeaturedCollection } from 'App/Program/Main/Insight/pages/NonFeaturedCollection';

/*
 * The overview page is rendered within the CollectionsProvider
 * and the CollectionsSidebarTheme
 * the overview report is accessed via the CollectionsContext
 * */
export const OverviewPage: React.FC<RouteComponentProps> = () => {
  const {
    customCollectionsQuery,
    overviewCollectionQuery,
    auditDownload,
  } = React.useContext(CollectionsContext);

  const { data: overviewMetabaseReport } = overviewCollectionQuery;

  const nonFeaturedCollections = React.useMemo(() => {
    const collections = customCollectionsQuery.data || [];

    return collections.filter(
      ({ tags }) =>
        !tags.some((tag) => tag.indexOf('legacy') === 0) &&
        tags.filter((tag) => tag.indexOf('featured') === 0).length === 0
    );
  }, [customCollectionsQuery.data]);

  const hasNoReports =
    !overviewMetabaseReport && nonFeaturedCollections.length === 0;

  return (
    <>
      {hasNoReports ? (
        <h3>You don&apos;t have access to Insights Reports</h3>
      ) : (
        <>
          {overviewMetabaseReport && (
            <MetabaseEmbedIframe
              metabaseReport={overviewMetabaseReport}
              auditDownload={auditDownload}
            />
          )}
          {nonFeaturedCollections.map((collection) => (
            <NonFeaturedCollection
              key={collection.id}
              collection={collection}
            />
          ))}
        </>
      )}
    </>
  );
};
