import qs from 'qs';
import { bossanovaDomain, request } from 'services/api-shared';
import { ViewPort } from 'models/insight/Report';
import {
  fetchDashboardPdf,
  FilterStateObj,
  FilterValue,
} from 'services/api-insights';
import { pdf, png } from 'utility/download';

type URLWithQueryParams = {
  programId: number;
  reportId: number;
  title: string | null | undefined;
  viewPort: ViewPort;
  params: FilterStateObj;
  labels: FilterStateObj[];
};

const filterLabel = (value: FilterValue[] | null) => {
  if (!value) {
    return 'Any';
  }
  if (value[0] === true) {
    return 'Yes';
  }
  if (value[0] === false) {
    return 'No';
  }

  return value.length === 1 ? value[0] : `${value[0]}, +${value.length - 1}`;
};

export const downloadUrlGenerator = ({
  programId,
  reportId,
  title,
  params: nested,
  viewPort,
  labels,
}: URLWithQueryParams): string => {
  const params = {} as { [slug: string]: FilterValue | FilterValue[] | null };
  Object.keys(nested)
    .filter((key) => nested[key])
    .forEach((key) => {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      if (nested[key]![0] === true) params[key] = 'Y';
      else if (nested[key]![0] === false) params[key] = 'N';
      else {
        params[key] =
          Array.isArray(nested[key]) && nested[key]!.length <= 1
            ? nested[key]![0]
            : nested[key];
      }
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    });
  const dateSlug = Object.keys(params).find((slug) => slug.match(/^date/i));
  const datePart = dateSlug && nested[dateSlug!] && nested[dateSlug!]![0]; // eslint-disable-line

  // // All the underscores are so Dojo can tell the report params from the options
  /* eslint-disable no-underscore-dangle */
  const __fileName__ = `${title}${datePart ? `-${datePart}` : ''}.png`;
  const __labels__ = labels.flatMap((filterObj: FilterStateObj) =>
    Object.keys(filterObj)
      .filter((key) => filterObj[key])
      .map((label) => {
        if (filterObj[label] instanceof Array) {
          const value = filterObj[label]![0] as { value: string } & string; // eslint-disable-line
          if (value && value.value) {
            return `${label} is ${value.value}`;
          }
        }
        return `${label} is ${filterLabel(filterObj[label])}`;
      })
  );

  const __viewport__ = {
    __width__: viewPort?.width,
    __height__: viewPort?.height,
    __scale__: viewPort?.deviceScaleFactor,
  };
  /* eslint-enable no-underscore-dangle */

  const query = qs.stringify(
    { ...params, __fileName__, __labels__, ...__viewport__ },
    { arrayFormat: 'brackets' }
  );

  return `${bossanovaDomain}/api/v1/programs/${programId}/insights/reports/${reportId}/screenshot.png?${query}`;
};

export const downloadScreenshot = async ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}): Promise<boolean> => {
  const resp: Response = await request(url);
  const blob: Blob = await resp.blob();
  return png(blob, fileName);
};

export const downloadPdf = async ({
  programId,
  dashboardId,
  dashboardName,
}: {
  programId: number;
  dashboardId: string;
  dashboardName: string;
}): Promise<boolean> => {
  const blob: Blob = await fetchDashboardPdf(programId, dashboardId);
  return pdf(blob, dashboardName);
};
