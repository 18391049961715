import {
  DefinitionBlock,
  isImageFieldData,
  isPollFieldData,
  isTextFieldData,
  isVideoFieldData,
} from 'models/publisher/block';
import { attrExtractionFn, digFieldData } from './data-extractor';
import { stripHtml } from './text';

function isImageWithUrl(data: unknown) {
  return (
    isImageFieldData(data) &&
    !!(data.processed ? data.hosted_url : data.input_url)
  );
}

/**
 * @deprecated Read time is now calculated on the server. Use content.readTimeInSeconds instead.
 */
export function blocksReadTime(blocks: Array<DefinitionBlock>): number {
  const words = blocks.flatMap((block) => {
    const textList = digFieldData(block, {
      match: isTextFieldData,
      extract: attrExtractionFn('value'),
    });
    if (textList) return stripHtml(textList.join(' ')).split(' ');
    return [];
  });

  const images = blocks
    .map((block) =>
      digFieldData(block, {
        match: isImageWithUrl,
      })
    )
    .flat(Infinity)
    .filter(Boolean);

  const videosTotalLength = blocks
    .map((block) =>
      digFieldData(block, {
        match: isVideoFieldData,
        extract: attrExtractionFn('video_length_sec'),
      })
    )
    .reduce((acc, curr) => acc + Number(curr), 0);

  const polls = blocks.flatMap((block) =>
    digFieldData(block, {
      match: isPollFieldData,
    })
  );

  // calculate readTime in seconds
  // text: 200wpm == 3.3wps
  // 10s per image
  // 60s per poll
  return (
    words.length / 3.3 +
    images.length * 10 +
    videosTotalLength +
    polls.length * 60
  );
}
