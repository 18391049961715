import React, { FC } from 'react';
import { IconNames, MAIcon } from 'shared/MAIcon';
import { Button } from 'DesignSystem/Form';
import styles from './styles.module.css';

interface ToolbarActionProps {
  icon: IconNames;
  onClick: () => void;
}
export const ToolbarAction: FC<ToolbarActionProps> = ({ icon, onClick }) => {
  return (
    <Button
      icon={<MAIcon name={icon} />}
      secondary
      borderless
      compact
      onClick={onClick}
      className={styles.ToolbarAction}
    />
  );
};

const Toolbar: FC = ({ children }) => {
  return <div className={styles.Toolbar}>{children}</div>;
};

export default Toolbar;
