import * as React from 'react';
import { User } from 'models/user';
import { LitmusRole } from 'models/role';
import { BannerListItem } from 'shared/BannerListItem';
import {
  userToMetaStats,
  userToName,
  userToDescription,
  userToThumbnail,
} from './mappings';

type PropsType = {
  user: User;
  permissionsEnabled: boolean;
  litmusRoles?: Array<LitmusRole>;
};

export const UsersListItem: React.FC<PropsType> = (props) => {
  const { user, permissionsEnabled, litmusRoles } = props;

  const centerSectionStats = userToMetaStats(
    user,
    permissionsEnabled,
    litmusRoles
  );
  const thumbnail = userToThumbnail(user);
  const name = userToName(user);
  const description = userToDescription(user);

  return (
    <BannerListItem
      thumbnail={thumbnail}
      menuItems={[]}
      headline={name}
      description={description}
      centerSectionStats={centerSectionStats}
      rowId={user.id.toString()}
    />
  );
};
