import React, { FC } from 'react';
import { Container } from '@socialchorus/shared-ui-components';
import { Link } from '@reach/router';
import styles from './styles.module.css';

interface CardProps {
  title: string;
  link?: JSX.Element;
}

export const PreviewCard: FC<CardProps> = ({ title, link, children }) => (
  <Container className={styles.previewCard} shadow="extra-light" fullWidth>
    <div className={styles.cardHeading}>
      <h3 className={styles.cardTitle}>{title}</h3>
      {link}
    </div>
    {children}
  </Container>
);

export const CardPlaceholder: FC = ({ children }) => (
  <div className={styles.cardPlaceholder}>{children}</div>
);

export const CardLink: FC<{ link?: string; onClick?: () => void }> = ({
  children,
  link,
  onClick,
}) => {
  if (link) {
    return (
      <Link to={link} className={styles.cardLink}>
        {children}
      </Link>
    );
  }
  return (
    <button type="button" onClick={onClick} className={styles.cardLink}>
      {children}
    </button>
  );
};
