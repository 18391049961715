import { DateTime } from 'luxon';
import { Audience } from 'models/audience';
import {
  Channel,
  CHANNELS,
  DeliveryChannels,
  isChannelsArray,
} from 'models/channel';
import { DeliveryType, isDeliveryType } from 'models/delivery-type';
import { Duration, DURATIONS_BY_UNIT } from 'models/duration';
import { Notification } from 'models/notification';
import {
  isPriorityName,
  PRIORITIES_BY_NAME,
  Priority,
  PriorityName,
} from 'models/priority';
import { Post } from 'models/publisher/post';
import { defaultSettings } from 'models/publisher/settings';
import { Notification as ServerNotification } from '../server-post-type';

export const serializeDeliveryType = (
  audiences: Audience[],
  deliveryType?: DeliveryType
): DeliveryType | null => {
  return audiences.length === 0 || !deliveryType ? null : deliveryType;
};

export const deserializeDeliveryType = (
  deliveryType?: string
): DeliveryType | undefined => {
  return (isDeliveryType(deliveryType) && deliveryType) || undefined;
};

export const serializeNotifications = (
  notifications: Notification[],
  retries: number,
  notifDeliveryTimesEnabled: boolean,
  publishedAt?: DateTime
): Notification[] => {
  // TODO: This logic is duplicated in settings-fields.notifiations. Consolidate.
  const notifs = retries !== 0 ? notifications : notifications.slice(0, 1);

  return notifs.map((notif, i) => {
    if (!notifDeliveryTimesEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dateTime, isPublishDateTime, ...withoutDateData } = notif;
      return withoutDateData;
    }

    if (i === 0 && notif.isPublishDateTime) {
      return {
        ...notif,
        dateTime: publishedAt?.toISO(),
      } as Notification;
    }
    return {
      ...notif,
      dateTime: notif.dateTime?.toISO(),
    } as Notification;
  });
};

// TODO: Should this be more defensive?
//    take Partial<ServerNotification>[]>
export const deserializeNotifications = (
  notifications?: ServerNotification[]
): Notification[] => {
  if (!notifications || notifications.length === 0) {
    return defaultSettings.notifications;
  }
  return notifications.map(deserializeNotificationDate);
};

// TODO: Where is the best place for normalize and serialize functions?
//      This file could get big if we have these for every attribute that needs it
export const deserializeNotificationDate = (
  notification: ServerNotification
): Notification => {
  if (notification.dateTime && typeof notification.dateTime === 'string') {
    return {
      ...notification,
      dateTime: DateTime.fromISO(notification.dateTime),
    };
  }
  return notification as Notification;
};

// Note: defaulting to 1 because Pony/Hermes can't handle non-durations
//  need to address. Non-retry campaigns and topic only should have nil for durations
export const serializeDuration = (duration?: Duration): number => {
  if (duration) {
    return duration.value * duration.unit.multiplier;
  }

  return 1;
};

// TODO: either parse this to convert the amount of days as the amount of weeks/quarters/years
// or change format so it's stored on backend not as days but in a format like { value: 1 unit: 'months' }
// Note: returning undefined to represent that topic only and non-retry campaigns
//  should have null for duration. Need to address in Pony/Hermes to support null
//  duration.
export const deserializeDuration = (
  duration?: number
): Duration | undefined => {
  if (duration && duration > 0) {
    return {
      label: `${duration} d`,
      value: duration,
      unit: DURATIONS_BY_UNIT.day,
    };
  }
  return undefined;
};

export const deserializeChannels = (
  channels: (Channel | unknown)[]
): DeliveryChannels => {
  if (!isChannelsArray(channels)) {
    return defaultSettings.deliveryChannels;
  }

  return {
    feed: channels.includes('feed'),
    push: channels.includes('push'),
    assistant: channels.includes('assistant'),
    email: channels.includes('email'),
  };
};

export const serializeChannels = (
  deliveryChannels: DeliveryChannels
): Channel[] => {
  return CHANNELS.filter((k) => deliveryChannels[k]);
};

export const deserializePriority = (
  priorityName?: string
): Priority | undefined => {
  return (
    (isPriorityName(priorityName) && PRIORITIES_BY_NAME[priorityName]) ||
    undefined
  );
};

export const serializePriority = (priority?: Priority): PriorityName | null => {
  return priority?.value || null;
};

export const serializeIncludeInForYou = (
  settings: Post['settings']
): boolean => {
  if (settings.audiences.length > 0) {
    return settings.includeInForYou ?? true;
  }
  return false;
};
