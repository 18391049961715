import { useState } from 'react';
import { useMutation } from 'react-query';
import { EditResponse } from './common';

export function useEdit<T>(
  object: T,
  persist: (object: T) => void,
  isSaved?: boolean
): EditResponse<T> {
  const [data, setData] = useState<T>({ ...object });

  const { mutate, isLoading, error } = useMutation<void, Error, T>(
    '',
    async (obj: T) => persist(obj),
    {
      onSuccess: (_, newData) => {
        setData(newData);
      },
    }
  );

  return {
    data,
    isSaved: isSaved || false,
    isSaving: isLoading,
    errorMessage: error?.message,
    revert: () => setData({ ...data }),
    save: () => mutate(data),
    setData,
  };
}
