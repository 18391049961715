import React from 'react';
import cx from 'classnames';
import {
  DropdownRenderPropType,
  HoverDropdown,
  PassThroughPropsType,
} from '../HoverDropdown';
import styles from './hover-menu-dropdown.module.css';

export type ItemType = {
  title: string;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
};

type HoverMenuDropdownProps = {
  menuItems: ItemType[];
} & PassThroughPropsType;

export const HoverMenuDropdown: React.FC<HoverMenuDropdownProps> = ({
  menuItems,
  ...passThroughProps
}) => {
  const dropdownRenderProp: DropdownRenderPropType = (dismiss) => (
    <div className={styles.dropdown}>
      {menuItems.map((item) => (
        <div
          key={item.title}
          className={cx(styles.menuItem, {
            [styles.active]: item.active,
            [styles.disabled]: item.disabled,
          })}
        >
          <button
            disabled={item.disabled}
            type="button"
            onClick={() => {
              if (item.disabled) return;
              item.onClick();
              dismiss();
            }}
          >
            {item.title}
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <HoverDropdown
      dropdownRenderProp={dropdownRenderProp}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...passThroughProps}
    />
  );
};
