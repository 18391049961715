import React from 'react';
import { ImageData } from 'models/image';
import { Draggable } from 'react-beautiful-dnd';
import { Trash } from 'shared/icons';
import { useUniqueId } from 'hooks/useUniqueId';
import { useProcessedContentImage } from 'hooks/useContentImage';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './collection.module.css';

type PropsType = {
  index: number;
  image: ImageData;
  onRemove: (image: ImageData) => void;
  onClick: () => void;
  onProcessed: (data: ImageData) => void;
};

export const DraggableImage: React.FC<PropsType> = ({
  image,
  index,
  onRemove,
  onClick,
  onProcessed,
}) => {
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();
  const uniqueId = useUniqueId();

  // Prevent image from flickering when updated with the processed image
  const onProcessedWrapper = React.useCallback(
    (data: ImageData) => {
      const img = new Image();
      img.onload = () => {
        setImageUrl(img.src);
      };
      img.src = data.url;
      onProcessed(data);
    },
    [setImageUrl, onProcessed]
  );

  useProcessedContentImage({
    onProcessed: onProcessedWrapper,
    image,
  });

  const remove = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onRemove(image);
    },
    [image, onRemove]
  );

  return (
    <Draggable draggableId={uniqueId} index={index}>
      {(dragProvided) => (
        /* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
        <div
          onClick={onClick}
          className={styles.image}
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          {!image.processed && (
            <Flex className={styles.spinnerOverlay}>
              <LoadingSpinner />
            </Flex>
          )}
          <button type="button" onClick={remove}>
            <Trash />
          </button>
          <img src={imageUrl ?? image.url} alt={image.altText} />
        </div>
      )}
    </Draggable>
  );
};
