import React from 'react';
import { SearchBar } from 'shared/SearchBar';
import { Audience } from 'models/audience';
import { LitmusRole } from 'models/role';
import { FiltersStateType } from 'contexts/people/filters';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { Filters } from './Filters';
import { Scope } from './scope';

type PropsType = {
  // eslint-disable-next-line
  onChange: (filterName: string, value: any) => void;
  searchTerm?: string;
  statuses?: string[];
  roles?: LitmusRole[];
  roleNames?: string[];
  audiences?: Audience[];
  scopes?: Scope;
  filters: FiltersStateType;
  setBooleanValue: (name: string, value: boolean) => void;
};

export const UserFilterBar: React.FC<PropsType> = ({
  onChange,
  searchTerm,
  statuses,
  roles,
  roleNames,
  audiences,
  scopes,
  filters,
  setBooleanValue,
}) => {
  return (
    <div className={styles.filtersWrapper}>
      <Filters
        onChange={onChange}
        filters={filters}
        roles={roles}
        statuses={statuses}
        roleNames={roleNames}
        audiences={audiences}
        scopes={scopes}
        setBooleanValue={setBooleanValue}
      >
        <SearchBar
          value={searchTerm}
          onChange={(value) => onChange('search', value)}
          placeholder="Search users"
          panelClassName={styles.searchBarWrapper}
        />
      </Filters>
    </div>
  );
};
