import { useEffect, useRef } from 'react';

export function useDelayedAction(
  callback: () => void | Promise<void>,
  delay: number
): {
  schedule: (afterCallbackFn?: () => void) => void;
  cancel: () => void;
} {
  const action = useRef<() => void>(() => {});
  const timer = useRef<ReturnType<typeof window.setTimeout>>();

  action.current = callback;

  // Cleans up a delayed action if we leave the screen
  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    []
  );

  return {
    cancel() {
      if (timer.current) clearTimeout(timer.current);
    },
    schedule(afterCallbackFn) {
      timer.current = setTimeout(async () => {
        await action.current();
        if (afterCallbackFn) {
          afterCallbackFn();
        }
      }, delay);
    },
  };
}
