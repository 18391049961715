import { useProgram } from 'contexts/program';
import { useImageUploader } from 'hooks/useImageUploader';
import { useToggle } from 'hooks/useToggle';
import * as React from 'react';
import { FileDropZone } from 'shared/FileDropZone';
import { ImageData } from 'models/image';
import { useEffect } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { usePrevious } from 'hooks/usePrevious';
import { uniqueId } from 'hooks/useUniqueId';
import cx from 'classnames';
import { Filter, LibraryImage } from 'models/library';
import {
  useLibraryImageCategories,
  useLibraryMutation,
} from 'hooks/useLibrary';
import { useBulkUploader } from 'hooks/useBulkUploader';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Heading } from 'DesignSystem/Typography';
import { pluralize } from 'utility/text';
import { MultiFileUploadButton } from '../FileUploadButton';
import styles from './library.module.css';
import { LoadingSpinner } from '../LoadingSpinner';

type PropsType = {
  length: number;
  filter: Filter;
  addToAppendedItems: (item: LibraryImage) => void;
  isButton?: boolean;
  setSpinnersCount: (spinners: number) => void;
  filterByCategory: (id: number) => void;
  allowFiltering: (value: boolean) => void;
};

export const ImageUploader: React.FC<PropsType> = ({
  children,
  length,
  filter,
  addToAppendedItems,
  setSpinnersCount,
  isButton,
  filterByCategory,
  allowFiltering,
}) => {
  const { id: programId } = useProgram();
  const categories = useLibraryImageCategories();
  const [totalFilesCount, setTotalFilesCount] = React.useState(0);
  const erroredFilesRef = React.useRef<number>(0);
  const completedFilesRef = React.useRef<number>(0);
  const { setFlashMessage } = useFlashMessage();
  const currentCategory =
    filter.type === 'category'
      ? (categories.data || []).find((c) => c.id === filter.id)
      : undefined;

  const completedLength = completedFilesRef.current;

  const updateCounters = React.useCallback(() => {
    const processedFilesCount =
      completedFilesRef.current + erroredFilesRef.current;
    if (processedFilesCount === totalFilesCount && processedFilesCount > 0) {
      completedFilesRef.current = 0;
      setTotalFilesCount(0);
      setSpinnersCount(0);
      erroredFilesRef.current = 0;
      allowFiltering(true);
    } else {
      setSpinnersCount(totalFilesCount - processedFilesCount);
    }
  }, [totalFilesCount, setSpinnersCount, allowFiltering]);

  const onUploadSuccess = React.useCallback(
    (data) => {
      completedFilesRef.current += 1;
      addToAppendedItems(data);
      updateCounters();
      if (currentCategory) {
        filterByCategory(currentCategory.id);
      }
    },
    [addToAppendedItems, currentCategory, filterByCategory, updateCounters]
  );

  const onUploadError = React.useCallback(
    (message) => {
      erroredFilesRef.current += 1;
      updateCounters();
      setFlashMessage({
        severity: 'error',
        message,
      });
    },
    [updateCounters, setFlashMessage]
  );

  const { mutate: save } = useLibraryMutation({
    onSuccess: onUploadSuccess,
    onError: onUploadError,
  });

  const isEmpty = React.useMemo(() => {
    return length === 0;
  }, [length]);

  const setImage: (data: ImageData) => void = React.useCallback(
    ({ url, originalFilename }: ImageData) => {
      save({
        programId,
        item: {
          type: 'content_image',
          source: 'kai',
          asset: { url },
          id: 'new',
          title: originalFilename ?? 'User uploaded image',
          identifier: uniqueId(),
          description: 'User uploaded image',
          categories: currentCategory ? [currentCategory] : [],
          tags: [],
          thumbnail_images: [{ url }],
        },
      });
    },
    [currentCategory, programId, save]
  );

  const uploader = useImageUploader({
    onUpload: setImage,
    programId,
    onError: onUploadError,
  });

  const { uploadingFiles, handleFileUpload } = useBulkUploader({
    length,
    onDrop: () => {},
    saveDisabled: false,
    enableSave: () => {},
    disableSave: () => {},
  });

  const isDragging = useToggle();
  const prevUploadingFiles = usePrevious(uploadingFiles);

  const initiateUpload = React.useCallback(
    (files: DataTransferItemList | File[]) => {
      handleFileUpload(files);
      completedFilesRef.current = 0;
      setTotalFilesCount(files.length);
      allowFiltering(false);
    },
    [allowFiltering, handleFileUpload]
  );

  const handleUploadCounter = React.useCallback(
    (list: DataTransferItemList) => {
      const files: File[] = [];
      for (let i = 0; i < list.length; i += 1) {
        const file = list[i].getAsFile();
        if (file) files.push(file);
      }
      initiateUpload(files);
    },
    [initiateUpload]
  );

  const handleButtonUpload = React.useCallback(
    (files: File[]) => {
      initiateUpload(files);
    },
    [initiateUpload]
  );

  useEffect(() => {
    const newFiles = uploadingFiles.filter((obj) => {
      return !prevUploadingFiles.some((obj2) => {
        return obj.id === obj2.id;
      });
    });
    newFiles.map((f) => uploader.update(f.file));
  }, [uploadingFiles, prevUploadingFiles, uploader]);

  const currentLength = completedLength + erroredFilesRef.current;
  const hasSpinners = totalFilesCount > 0 && currentLength < totalFilesCount;
  const isPreparing = hasSpinners && completedLength === 0;

  return (
    <>
      <div
        className={cx(styles.uploaderWrapper, {
          [styles.dropZoneWrapper]: !isButton,
        })}
      >
        <div className={styles.fileUploadButtonContainer}>
          <div>
            {hasSpinners && completedLength > 0 && (
              <Flex className="loadingTextContainer">
                <LoadingSpinner size="small" />
                <div className={styles.loadingText}>
                  Uploading image {completedLength + 1} of{' '}
                  {totalFilesCount - erroredFilesRef.current}
                </div>
              </Flex>
            )}
          </div>
          {isButton && (
            <MultiFileUploadButton
              onFileSelect={handleButtonUpload}
              accept="image/*"
            >
              + Add Images
            </MultiFileUploadButton>
          )}
        </div>
        {!isButton && (
          <div
            className={cx(styles.dropZoneContainer, {
              [styles.bigDropZone]: isEmpty,
            })}
          >
            <FileDropZone
              withButton={false}
              accept="image/*"
              handleUpload={handleUploadCounter}
              onFileSelect={uploader.update}
              onDragEnd={isDragging.disable}
              onDragStart={isDragging.enable}
            >
              {isEmpty && !isPreparing && (
                <>
                  <h2>No images yet</h2>
                  <div className={styles.topRow}>
                    <span>Drag and drop images here or click</span>
                    <MultiFileUploadButton
                      onFileSelect={handleButtonUpload}
                      accept="image/*"
                    >
                      + Add images
                    </MultiFileUploadButton>
                    <span>to</span>
                  </div>
                  <div> upload from your computer.</div>
                </>
              )}
              {isPreparing && (
                <Flex className={styles.loadingTextContainer}>
                  <LoadingSpinner />
                  <Heading>
                    Preparing {totalFilesCount}{' '}
                    {pluralize(totalFilesCount, 'image')} for uploading...
                  </Heading>
                </Flex>
              )}
              {children}
            </FileDropZone>
          </div>
        )}
      </div>
    </>
  );
};
