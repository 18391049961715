import React from 'react';
import cx from 'classnames';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { MAIcon } from 'shared/MAIcon';
import { JOURNEY_ACTION_DISABLED_MESSAGE } from 'contexts/journeys/journey';
import { AddStepOption, StepOption } from './AddStepOption';
import style from '../edges.module.css';

type AddStepProps = {
  options: Array<StepOption>;
  menuOpen: boolean;
  onToggleMenu: () => void;
  disabled?: boolean;
};

export const AddStep: React.FC<AddStepProps> = ({
  options,
  menuOpen,
  onToggleMenu,
  disabled,
}) => (
  <>
    <Button
      onClick={onToggleMenu}
      icon={<MAIcon name="add_box" />}
      className={style.AddStepEdgeButton}
      borderless
      clearText
      minimal
      disabled={disabled}
      title={disabled ? JOURNEY_ACTION_DISABLED_MESSAGE : 'Add a step'}
    />
    {menuOpen && (
      <Flex column alignStart className={cx(style.AddStep, 'nopan')}>
        {options.map(({ icon, title, onClick }) => (
          <AddStepOption
            key={title}
            icon={icon}
            title={title}
            onClick={onClick}
          />
        ))}
      </Flex>
    )}
  </>
);
