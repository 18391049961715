import { useCallback } from 'react';
import { ButtonLinkFieldData } from 'models/publisher/block';
import { FieldFormProps } from '../../../useFieldForm';

export const useButtonLinkMutator: (
  props: FieldFormProps<ButtonLinkFieldData>
) => {
  buttonLink: ButtonLinkFieldData;
  updateButtonLink: (buttonLink: Partial<ButtonLinkFieldData>) => void;
} = ({ data, onChange }) => {
  const updateButtonLink = useCallback(
    (
      value: Omit<Partial<ButtonLinkFieldData>, 'button_link_id' | 'processed'>
    ) => {
      const updated = { ...data, ...value };
      if (data.openInNewTab !== value.openInNewTab) {
        updated.openInNewTab = !data.openInNewTab;
      }
      onChange(updated);
    },
    [onChange, data]
  );
  return {
    buttonLink: data,
    updateButtonLink,
  };
};
