import React, { useState } from 'react';
import { useProgram } from 'contexts/program';
import { useAudiencesInfiniteQuery } from 'hooks/audience';
import { useAuthorAliasesInfiniteQuery } from 'hooks/author-alias';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import { useLibraryTemplates } from 'hooks/useLibrary';
import { Audience } from 'models/audience';
import { EmailAlias } from 'models/email-alias';
import { Template } from 'models/library';
import { Topic } from 'models/topic';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import cx from 'classnames';
import { AliasData } from 'services/api-author-alias';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { Scope } from './scope';
import mdlStyles from '../people.module.css';
import {
  RestrictionsFilterItem,
  RestrictionsFilterItemProps,
} from './RestrictionFilterItem';
import {
  FilterCriteria,
  initialFiltersSearchState,
} from './filter-criteria-state';
import { useSelectionsData } from './use-selections-data';

export const RestrictionsFilter: React.FC<{
  // eslint-disable-next-line
  onChange: (value: Scope) => void;
  scopes: Scope;
  closeFilter: () => void;
}> = ({ onChange, scopes, closeFilter }) => {
  const { id: programId } = useProgram();
  const [filtersSearch, setFiltersSearch] = useState(initialFiltersSearchState);
  const {
    topicsSearch,
    audiencesSearch,
    authorsSearch,
    templatesSearch,
    emailsSearch,
  } = filtersSearch;

  const {
    data: authorAliasPermissionsFlag,
    isLoading: authorAliasPermissionsFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.AuthorAliases.UI');

  // data section
  const topicsData = useTopicsInfiniteQuery({
    programId,
    search: topicsSearch,
  });
  const audiencesData = useAudiencesInfiniteQuery({
    programId,
    search: audiencesSearch,
  });
  const templatesData = useLibraryTemplates({
    filter: { type: 'search', search: templatesSearch },
    sortBy: 'title',
    restrictedOnly: true,
  });
  const emailData = useEmailAliasesInfiniteQuery({
    programId,
    search: emailsSearch,
  });
  const authorData = useAuthorAliasesInfiniteQuery({
    programId,
    search: authorsSearch,
  });

  const {
    topics,
    audiences,
    templates,
    emailAliases,
    authorAliases,
  } = useSelectionsData(scopes, {
    audiences: audiencesData.data,
    authorAliases: authorData.data,
    emailAliases: emailData.data,
    templates: templatesData.data,
    topics: topicsData.data,
  });

  const updateFilterSearchTerm = React.useCallback(
    (filterId: FilterCriteria) => {
      return (value: string) => {
        setFiltersSearch({ ...filtersSearch, [filterId]: value });
      };
    },
    [filtersSearch]
  );

  // renders
  const renderFilterLabel = (label: string) => {
    return (
      <div className={mdlStyles.restrictionsTitle}>
        <p title={label}>{label}</p>
      </div>
    );
  };

  // topics section
  const topicsByRowId = React.useMemo(() => {
    return (topics || []).reduce((acc, topic) => {
      return { ...acc, [topic.id]: topic };
    }, {} as { [key: string]: Topic });
  }, [topics]);

  const topicRowIds = Object.keys(topicsByRowId);
  const selectedTopicIds = scopes.topics;

  const renderTopicRow = React.useCallback(
    (rowId: string) => {
      const item = topicsByRowId[rowId];
      return renderFilterLabel(item.name);
    },
    [topicsByRowId]
  );

  const handleTopicsChange = React.useCallback(
    (ids: string[]) => {
      onChange({ ...scopes, topics: ids });
      updateFilterSearchTerm('topicsSearch')('');
    },
    [onChange, updateFilterSearchTerm, scopes]
  );

  const topicsDropdown = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return (
        <div
          className={cx(
            mdlStyles.restrictionsDropdownContainer,
            styles.submittersContainer
          )}
        >
          <InfiniteSelect
            searchEnabled
            searchTerm={topicsSearch}
            onSearchTermChange={updateFilterSearchTerm('topicsSearch')}
            className={styles.sourcesDropdown}
            rowIds={topicRowIds}
            rowRenderProp={renderTopicRow}
            maxHeight={400}
            itemHeight={30}
            selectedIds={selectedTopicIds}
            onSelectedIdsChange={handleTopicsChange}
            isLoading={topicsData.isLoading}
            fetchNextPage={topicsData.fetchNextPage}
            hasNextPage={topicsData.hasNextPage}
            isFetchingNextPage={topicsData.isFetchingNextPage}
            hasClearSearchButton
            dismissButton="Done"
            onDismissRef={onDismissRef}
          />
        </div>
      );
    },
    [
      topicRowIds,
      topicsSearch,
      updateFilterSearchTerm,
      renderTopicRow,
      selectedTopicIds,
      handleTopicsChange,
      topicsData.isLoading,
      topicsData.fetchNextPage,
      topicsData.hasNextPage,
      topicsData.isFetchingNextPage,
    ]
  );

  // audiences section
  const audiencesByRowId = React.useMemo(() => {
    return (audiences || []).reduce((acc, audience) => {
      return { ...acc, [String(audience.id)]: audience };
    }, {} as { [key: string]: Audience });
  }, [audiences]);

  const audienceRowIds = Object.keys(audiencesByRowId);
  const selectedAudienceIds = scopes.audiences;

  const renderAudienceRow = React.useCallback(
    (rowId: string) => {
      const item = audiencesByRowId[rowId];
      return renderFilterLabel(item.title);
    },
    [audiencesByRowId]
  );

  const handleAudiencesChange = React.useCallback(
    (ids: string[]) => {
      onChange({ ...scopes, audiences: ids });
      updateFilterSearchTerm('audiencesSearch')('');
    },
    [onChange, updateFilterSearchTerm, scopes]
  );

  const audiencesDropdown = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return (
        <div
          className={cx(
            mdlStyles.restrictionsDropdownContainer,
            styles.submittersContainer
          )}
        >
          <InfiniteSelect
            searchEnabled
            searchTerm={audiencesSearch}
            onSearchTermChange={updateFilterSearchTerm('audiencesSearch')}
            className={styles.sourcesDropdown}
            rowIds={audienceRowIds}
            rowRenderProp={renderAudienceRow}
            maxHeight={400}
            itemHeight={30}
            selectedIds={selectedAudienceIds}
            onSelectedIdsChange={handleAudiencesChange}
            isLoading={audiencesData.isLoading}
            fetchNextPage={audiencesData.fetchNextPage}
            hasNextPage={audiencesData.hasNextPage}
            isFetchingNextPage={audiencesData.isFetchingNextPage}
            hasClearSearchButton
            dismissButton="Done"
            onDismissRef={onDismissRef}
          />
        </div>
      );
    },
    [
      audienceRowIds,
      audiencesSearch,
      updateFilterSearchTerm,
      renderAudienceRow,
      selectedAudienceIds,
      handleAudiencesChange,
      audiencesData.isLoading,
      audiencesData.fetchNextPage,
      audiencesData.hasNextPage,
      audiencesData.isFetchingNextPage,
    ]
  );

  // templates section
  const templatesByRowId = React.useMemo(() => {
    return (templates || []).reduce((acc, template) => {
      return { ...acc, [template.id]: template };
    }, {} as { [key: string]: Template });
  }, [templates]);

  const templateRowIds = Object.keys(templatesByRowId);
  const selectedTemplateIds = scopes.templates;

  const renderTemplateRow = React.useCallback(
    (rowId: string) => {
      const item = templatesByRowId[rowId];
      return renderFilterLabel(item.title);
    },
    [templatesByRowId]
  );

  const handleTemplatesChange = React.useCallback(
    (ids: string[]) => {
      onChange({ ...scopes, templates: ids });
      updateFilterSearchTerm('templatesSearch')('');
    },
    [onChange, updateFilterSearchTerm, scopes]
  );

  const templatesDropdown = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return (
        <div
          className={cx(
            mdlStyles.restrictionsDropdownContainer,
            styles.submittersContainer
          )}
        >
          <InfiniteSelect
            searchEnabled
            searchTerm={templatesSearch}
            onSearchTermChange={updateFilterSearchTerm('templatesSearch')}
            className={styles.sourcesDropdown}
            rowIds={templateRowIds}
            rowRenderProp={renderTemplateRow}
            maxHeight={400}
            itemHeight={30}
            selectedIds={selectedTemplateIds}
            onSelectedIdsChange={handleTemplatesChange}
            isLoading={templatesData.isLoading}
            fetchNextPage={templatesData.fetchNextPage}
            hasNextPage={templatesData.hasNextPage}
            isFetchingNextPage={templatesData.isFetchingNextPage}
            hasClearSearchButton
            dismissButton="Done"
            onDismissRef={onDismissRef}
          />
        </div>
      );
    },
    [
      updateFilterSearchTerm,
      templatesSearch,
      templateRowIds,
      renderTemplateRow,
      selectedTemplateIds,
      handleTemplatesChange,
      templatesData.isLoading,
      templatesData.fetchNextPage,
      templatesData.hasNextPage,
      templatesData.isFetchingNextPage,
    ]
  );

  // email aliases section
  const emailsByRowId = React.useMemo(() => {
    return (emailAliases || []).reduce((acc, email) => {
      return { ...acc, [email.id]: email };
    }, {} as { [key: string]: EmailAlias });
  }, [emailAliases]);

  const emailRowIds = Object.keys(emailsByRowId);
  const selectedEmailIds = scopes.emailAliases;

  const renderEmailRow = React.useCallback(
    (rowId: string) => {
      const item = emailsByRowId[rowId];
      return renderFilterLabel(item.name);
    },
    [emailsByRowId]
  );

  const handleEmailChanges = React.useCallback(
    (ids: string[]) => {
      onChange({ ...scopes, emailAliases: ids });
      updateFilterSearchTerm('emailsSearch')('');
    },
    [onChange, updateFilterSearchTerm, scopes]
  );

  const emailDropdown = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return (
        <div
          className={cx(
            mdlStyles.restrictionsDropdownContainer,
            styles.submittersContainer
          )}
        >
          <InfiniteSelect
            searchEnabled
            searchTerm={emailsSearch}
            onSearchTermChange={updateFilterSearchTerm('emailsSearch')}
            className={styles.sourcesDropdown}
            rowIds={emailRowIds}
            rowRenderProp={renderEmailRow}
            maxHeight={400}
            itemHeight={30}
            selectedIds={selectedEmailIds}
            onSelectedIdsChange={handleEmailChanges}
            isLoading={emailData.isLoading}
            fetchNextPage={emailData.fetchNextPage}
            hasNextPage={emailData.hasNextPage}
            isFetchingNextPage={emailData.isFetchingNextPage}
            hasClearSearchButton
            dismissButton="Done"
            onDismissRef={onDismissRef}
          />
        </div>
      );
    },
    [
      emailRowIds,
      emailsSearch,
      updateFilterSearchTerm,
      renderEmailRow,
      selectedEmailIds,
      handleEmailChanges,
      emailData.isLoading,
      emailData.fetchNextPage,
      emailData.hasNextPage,
      emailData.isFetchingNextPage,
    ]
  );

  // author alias section
  const authorByRowId = React.useMemo(() => {
    return (authorAliases || []).reduce((acc, author) => {
      return { ...acc, [`${author.id}`]: author };
    }, {} as { [key: string]: AliasData });
  }, [authorAliases]);

  const authorRowIds = Object.keys(authorByRowId);
  const selectedAuthorIds = scopes.authorAliases;

  const renderAuthorRow = React.useCallback(
    (rowId: string) => {
      const item = authorByRowId[rowId];
      return renderFilterLabel((item.displayName || String(item.id)) ?? '');
    },
    [authorByRowId]
  );

  const handleAuthorChange = React.useCallback(
    (ids: string[]) => {
      onChange({ ...scopes, authorAliases: ids });
      updateFilterSearchTerm('authorsSearch')('');
    },
    [onChange, updateFilterSearchTerm, scopes]
  );

  const authorDropdown = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return (
        <div
          className={cx(
            mdlStyles.restrictionsDropdownContainer,
            styles.submittersContainer
          )}
        >
          <InfiniteSelect
            searchEnabled
            searchTerm={authorsSearch}
            onSearchTermChange={updateFilterSearchTerm('authorsSearch')}
            className={styles.sourcesDropdown}
            rowIds={authorRowIds}
            rowRenderProp={renderAuthorRow}
            maxHeight={400}
            itemHeight={30}
            selectedIds={selectedAuthorIds}
            onSelectedIdsChange={handleAuthorChange}
            isLoading={authorData.isLoading}
            fetchNextPage={authorData.fetchNextPage}
            hasNextPage={authorData.hasNextPage}
            isFetchingNextPage={authorData.isFetchingNextPage}
            hasClearSearchButton
            dismissButton="Done"
            onDismissRef={onDismissRef}
          />
        </div>
      );
    },
    [
      authorRowIds,
      authorsSearch,
      updateFilterSearchTerm,
      renderAuthorRow,
      selectedAuthorIds,
      handleAuthorChange,
      authorData.isLoading,
      authorData.fetchNextPage,
      authorData.hasNextPage,
      authorData.isFetchingNextPage,
    ]
  );

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    let dropdownItems: Array<RestrictionsFilterItemProps> = [
      {
        name: 'Topics',
        filtersCount: scopes.topics.length,
        dropdownRenderProp: topicsDropdown,
      },
      {
        name: 'Audiences',
        filtersCount: scopes.audiences.length,
        dropdownRenderProp: audiencesDropdown,
      },
      {
        name: 'Templates',
        filtersCount: scopes.templates.length,
        dropdownRenderProp: templatesDropdown,
      },
      {
        name: 'Email Aliases',
        filtersCount: scopes.emailAliases.length,
        dropdownRenderProp: emailDropdown,
      },
    ];

    if (
      !authorAliasPermissionsFlagLoading &&
      authorAliasPermissionsFlag?.value
    ) {
      dropdownItems = [
        ...dropdownItems,
        {
          name: 'Author Aliases',
          filtersCount: scopes.authorAliases.length,
          dropdownRenderProp: authorDropdown,
        },
      ];
    }

    return (
      <div className={mdlStyles.restrictionsDropdownContainer}>
        {dropdownItems.map(({ dropdownRenderProp, name, filtersCount }) => (
          <RestrictionsFilterItem
            key={name}
            name={name}
            dropdownRenderProp={dropdownRenderProp}
            filtersCount={filtersCount}
          />
        ))}
      </div>
    );
  }, [
    topicsDropdown,
    audiencesDropdown,
    templatesDropdown,
    emailDropdown,
    authorDropdown,
    scopes,
    authorAliasPermissionsFlag,
    authorAliasPermissionsFlagLoading,
  ]);

  const resetResourcesFilter = React.useCallback(() => {
    onChange({
      audiences: [],
      authorAliases: [],
      emailAliases: [],
      templates: [],
      topics: [],
    } as Scope);
    closeFilter();
  }, [closeFilter, onChange]);

  const restrictionNamesv2 = React.useMemo(() => {
    const memo = [
      ...selectedTopicIds.map((id) => topicsByRowId[id]?.name ?? id),
      ...selectedAudienceIds.map((id) => audiencesByRowId[id]?.title ?? id),
      ...selectedTemplateIds.map((id) => templatesByRowId[id]?.title ?? id),
      ...selectedEmailIds.map((id) => emailsByRowId[id]?.name ?? id),
      ...selectedAuthorIds.map((id) => authorByRowId[id]?.displayName ?? id),
    ];

    return memo;
  }, [
    selectedTopicIds,
    topicsByRowId,
    selectedAudienceIds,
    audiencesByRowId,
    selectedTemplateIds,
    templatesByRowId,
    selectedEmailIds,
    emailsByRowId,
    selectedAuthorIds,
    authorByRowId,
  ]);

  return (
    <ClickDropdown dropdownRenderProp={dropdown}>
      <div>
        <TriggerButton
          onClose={resetResourcesFilter}
          name="Permissions"
          values={restrictionNamesv2}
        />
      </div>
    </ClickDropdown>
  );
};
