import * as React from 'react';
import cx from 'classnames';
import * as Type from 'DesignSystem/Typography';
import { Tooltip } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import {
  PostPersistanceStatus,
  usePersistanceStatus,
} from 'components/publisher/theme/PublisherFooter/PostPersistanceStatus';
import { useFooter } from 'hooks/useFooter';
import { FlashMessageType } from 'models/flash-message';
import { MAIcon } from 'shared/MAIcon';
import ContentSizeLimitWarning from 'shared/ContentSizeLimitWarning';
import { usePublisher } from 'contexts/publisher';
import { WeekendDeliveryModal } from './WeekendDeliveryModal';
import style from './split-footer.module.css';

export const ContinueButton: React.FC<{
  visible: boolean;
  nextPage?: string;
  canPerformAction?: boolean;
  blocker?: { message: string; enabled: boolean };
  footer: ReturnType<typeof useFooter>;
  setFlashMessage(data: FlashMessageType): void;
  isWeekend?: boolean;
  optimizedDeliveryEnabled?: boolean;
  usingGuaranteedDelivery?: boolean;
  retries?: number;
  topicOnly?: boolean;
}> = ({
  visible,
  nextPage,
  blocker,
  footer,
  canPerformAction,
  setFlashMessage,
  isWeekend,
  optimizedDeliveryEnabled,
  usingGuaranteedDelivery,
  retries,
  topicOnly,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  if (!visible) return null;
  return nextPage ? (
    <Button
      label={nextPage === 'review' ? 'Next: Review' : 'Next: Deliver'}
      onClick={() => {
        if (blocker && blocker.enabled) {
          setFlashMessage({
            message: blocker.message,
            severity: 'error',
          });
          return;
        }
        footer.goForward(nextPage);
      }}
    />
  ) : (
    <>
      {showModal && (
        <WeekendDeliveryModal
          onCancel={() => setShowModal(false)}
          onSubmit={footer.action || (() => {})}
          optimizedDeliveryEnabled={optimizedDeliveryEnabled}
          retries={retries}
        />
      )}
      <Button
        label={footer.actionName}
        onClick={
          usingGuaranteedDelivery && isWeekend && !topicOnly
            ? () => setShowModal(true)
            : footer.action || (() => {})
        }
        disabled={!canPerformAction}
      />
    </>
  );
};

export const SaveButton: React.FC<{
  visible: boolean;
  isWorking: boolean;
  status: string;
  action: <T>(arg: T) => void;
  disabledMessage?: string;
  tooltipContent?: React.ReactNode;
  showStatusCircle?: boolean;
}> = ({
  visible,
  isWorking: saveIsWorking,
  status: saveStatus,
  action,
  status,
  disabledMessage,
  tooltipContent,
  showStatusCircle = true,
}) => {
  const [isWarningVisible, setIsWarningVisible] = React.useState(false);
  const {
    status: { isSizeLimitExceeded, currentSize },
  } = usePublisher();
  const { cloudStatus } = usePersistanceStatus();
  const isDisabled = !!disabledMessage;
  const doAction = React.useCallback(() => {
    if (saveIsWorking || isDisabled) return;
    setIsWarningVisible(true);
    action({
      shouldValidate: saveStatus !== 'draft',
    });
  }, [saveIsWorking, saveStatus, action, isDisabled]);
  if (!visible) return null;
  let color = Type.color.greenFull;
  if (cloudStatus !== 'Up to date') color = Type.color.redFull;
  if (saveIsWorking) color = Type.color.orangeFull;

  return (
    <>
      <ContentSizeLimitWarning
        isSizeLimitExceeded={isSizeLimitExceeded}
        currentSize={currentSize}
        isVisible={isWarningVisible}
        setIsVisible={setIsWarningVisible}
      />

      {showStatusCircle && (
        <span data-test="statusCircle">
          <Type.PageHeading block={false} color={color}>
            &bull;
          </Type.PageHeading>
        </span>
      )}
      <Type.Caption>
        &nbsp;{` ${saveIsWorking ? 'saving' : status} `}&nbsp;
      </Type.Caption>
      <Tooltip content={tooltipContent || <PostPersistanceStatus />}>
        <div
          className={cx(style.Button, {
            [style.Disabled]: isDisabled || saveIsWorking,
          })}
          role="button"
          onKeyPress={(e) => {
            if (['Enter', ' '].includes(e.key)) doAction();
          }}
          tabIndex={0}
          onClick={() => {
            doAction();
          }}
        >
          <MAIcon name="save_as" />
        </div>
      </Tooltip>
    </>
  );
};
