import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import { Designer } from 'publish/designs/Designer';
import { useDesignContext } from 'hooks/design';
import { useProgram } from 'contexts/program';
import { LoadingScreen } from 'shared/LoadingScreen';
import { DesignList } from 'publish/designs/DesignList';
import { DesignContext } from 'contexts/design';

export const Designs: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <DesignList path="/" />
      <DesignEdit path="edit/:id/*" />
      <Redirect from="*" to="/" noThrow default />
    </Router>
  );
};

export const DesignProvider: React.FC<RouteComponentProps<{
  id: number | 'new';
}>> = ({ children, id = 'new' }) => {
  const program = useProgram();

  const designContext = useDesignContext({ programId: program.id, id });
  const { status, error } = { ...designContext };
  if (status.isLoading) return <LoadingScreen />;

  if (error && error.length > 0) return <p>Error: {error}</p>;

  return (
    <DesignContext.Provider value={designContext}>
      {children}
    </DesignContext.Provider>
  );
};

export const DesignEdit: React.FC<RouteComponentProps<{ id: number }>> = ({
  id,
}) => {
  return (
    <DesignProvider id={id}>
      <Designer />
    </DesignProvider>
  );
};
