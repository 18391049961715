import React from 'react';
import { Icon } from 'shared/Icon';
import { BulkSelection } from 'hooks/common';
import { Button } from 'DesignSystem/Form';
import { Tooltip } from 'shared/Tooltip';
import { HoverTooltip } from 'shared/HoverTooltip';
import { HoverDropdown } from '../../hover-dropdown/HoverDropdown';
import styles from './actions.module.css';

type HandleActionType = (action: ActionButtonType) => void;

export type ActionButtonType = {
  label: string;
  iconName?: string;
  hideLabel?: boolean;
  actionLabel?: string;
  customTitle?: string;
  toStatusLabel?: string;
  onClick: (bulkSelection: BulkSelection) => boolean;
  handleAction?: HandleActionType;
  beforeAction?: (bulkSelection: BulkSelection) => Promise<void>;
  disabled?: boolean;
  isLoading?: boolean;
  designSystem?: boolean;
  confirm?: boolean;
  hoverTooltip?: string;
};

export type HoverActionType = {
  actions: ActionButtonType[];
  groupLabel?: string;
  handleAction?: HandleActionType;
  disabled?: boolean;
  designSystem?: boolean;
};

export type BulkSelectActionType = {
  type: 'button' | 'select';
  actions: ActionButtonType[];
  groupLabel?: string;
  handleAction?: HandleActionType;
  beforeAction?: (bulkSelection: BulkSelection) => Promise<void>;
  toStatusLabel?: string;
  disabled?: boolean;
  designSystem?: boolean;
  isLoading?: boolean;
  confirm?: boolean;
  scimIds?: string[];
  hoverButtonTooltip?: string;
};

const ButtonAction: React.FC<ActionButtonType> = (props) => {
  const {
    label,
    iconName,
    handleAction,
    beforeAction,
    onClick,
    toStatusLabel,
    hideLabel,
    customTitle,
    isLoading,
    disabled = false,
    designSystem = false,
    confirm,
    hoverTooltip,
  } = props;
  const handleClick = React.useCallback(async () => {
    const action: ActionButtonType = {
      label,
      iconName,
      hideLabel,
      customTitle,
      onClick,
      toStatusLabel,
      confirm,
      beforeAction,
    };
    if (!disabled && handleAction) handleAction(action);
  }, [
    label,
    iconName,
    hideLabel,
    toStatusLabel,
    customTitle,
    onClick,
    confirm,
    disabled,
    handleAction,
    beforeAction,
  ]);

  let button = (
    <button
      key={`index-${label}`}
      onClick={handleClick}
      type="button"
      className={styles.singleAction}
    >
      {label}
    </button>
  );

  if (designSystem) {
    button = (
      <Button
        disabled={disabled}
        compact
        isLoading={isLoading}
        label={label}
        key={label}
        onClick={handleClick}
      />
    );
  }

  return hoverTooltip ? (
    <HoverTooltip
      align="left"
      content={<Tooltip description={hoverTooltip} />}
      icon={button}
    />
  ) : (
    button
  );
};

const HoverAction: React.FC<HoverActionType> = (props) => {
  const { actions, disabled, designSystem, groupLabel, handleAction } = props;
  const handleClick = React.useCallback(
    (action: ActionButtonType) => {
      if (handleAction) handleAction(action);
    },
    [handleAction]
  );
  const button = React.useMemo(() => {
    if (!designSystem)
      return (
        <div className={styles.hoverAction}>
          <span>{groupLabel || 'more'}</span> <Icon iconName="sort" />
        </div>
      );
    return (
      <Button
        disabled={disabled}
        label={groupLabel || 'more'}
        icon={<Icon iconName="sort" />}
        secondary
      />
    );
  }, [designSystem, disabled, groupLabel]);
  return (
    <HoverDropdown
      disabled={disabled}
      dropdownClassName="dropdown-align-right"
      dropdownRenderProp={() => (
        <div className={styles.dropdown}>
          {actions?.map((action: ActionButtonType) => (
            <button
              key={action.label}
              type="button"
              onClick={() => handleClick(action)}
              className={styles.dropdownItem}
            >
              {action.label}
            </button>
          ))}
        </div>
      )}
    >
      {button}
    </HoverDropdown>
  );
};

export const BulkSelectAction: React.FC<BulkSelectActionType> = (props) => {
  const {
    type,
    actions,
    groupLabel,
    handleAction,
    disabled,
    beforeAction,
    isLoading,
    designSystem,
    toStatusLabel,
    hoverButtonTooltip,
  } = props;

  if (actions.length === 0) {
    return null;
  }

  const {
    label,
    iconName,
    onClick,
    hideLabel,
    customTitle,
    confirm,
  } = actions[0];

  switch (type) {
    case 'button':
      return (
        <ButtonAction
          designSystem={designSystem}
          label={label}
          hideLabel={hideLabel}
          iconName={iconName}
          customTitle={customTitle}
          beforeAction={beforeAction}
          onClick={onClick}
          toStatusLabel={toStatusLabel}
          isLoading={isLoading}
          handleAction={handleAction}
          disabled={disabled}
          confirm={confirm}
          hoverTooltip={hoverButtonTooltip}
        />
      );
    case 'select':
      return (
        <HoverAction
          designSystem={designSystem}
          disabled={disabled}
          actions={actions}
          groupLabel={groupLabel}
          handleAction={handleAction}
        />
      );
    default:
  }

  return null;
};
