import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import dashify from 'dashify';
import qs from 'qs';
import { getActor } from 'services/global-actor-storage';
import { NotFoundError } from './Errors/NotFoundError';
import { ValidationError } from './Errors/ValidationError';
import { ForbiddenError } from './Errors/ForbiddenError';
import { NetworkError } from './Errors/NetworkError';
import { ConflictError } from './Errors/ConflictError';

export const bossanovaDomain = process.env.REACT_APP_BOSSANOVA_DOMAIN;

export function deepCamelcaseKeys<T extends { [key: string]: unknown }>(
  input: T
): T {
  return camelcaseKeys(input, { deep: true });
}

export function prepareQueryString(params: unknown): string {
  return qs.stringify(snakecaseKeys(params as { [key: string]: unknown }), {
    arrayFormat: 'brackets',
  });
}

type RequestOptions = {
  authenticate?: boolean;
  body?: string | FormData;
  headers?: HeadersInit;
  method?: string;
  credentials?: RequestCredentials;
};

export async function request(
  url: string,
  options: RequestOptions = {}
): Promise<Response> {
  const { authenticate = true, body, method, credentials } = options;
  let { headers = {} } = options;
  if (authenticate) {
    const actor = getActor();
    if (!actor) {
      throw new Error('Not logged in.');
    }
    headers = {
      ...actor.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    };
  }

  let response: Response;

  try {
    response = await fetch(url, { body, headers, method, credentials });
  } catch (err) {
    throw new NetworkError('network error', { originalError: err });
  }

  if (response.status === 403) {
    throw new ForbiddenError('forbidden');
  }

  if (response.status === 404) {
    throw new NotFoundError('not found');
  }

  if (response.status === 409) {
    const errors = await response.json();
    throw new ConflictError(JSON.stringify(errors));
  }

  if (response.status === 422) {
    const errors = await response.json();
    throw new ValidationError(JSON.stringify(errors));
  }

  return response;
}

export function kebabCaseKeys(input: {
  [key: string]: unknown;
}): {
  [key: string]: unknown;
} {
  const result: { [key: string]: unknown } = {};
  Object.keys(input).forEach((key) => {
    result[dashify(key)] = input[key];
  });

  return result;
}

export function removeEmptyKeys(input: {
  [key: string]: unknown;
}): {
  [key: string]: unknown;
} {
  const result: { [key: string]: unknown } = {};
  Object.keys(input).forEach((key) => {
    const value = input[key];
    if (typeof value === 'string' || Array.isArray(value)) {
      if (value.length > 0) result[key] = value;
    } else if (typeof value === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (Object.keys(value).length > 0) result[key] = value;
    } else {
      result[key] = value;
    }
  });

  return result;
}
