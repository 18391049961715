import {
  createContext,
  createElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import { contextPrototype } from './canvas-style-panel';

const CanvasGlobalPanelContext = createContext(contextPrototype);

export const CanvasGlobalPanelContextProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const disable = useCallback(() => setVisible(false), []);
  const enable = useCallback(() => setVisible(true), []);
  const toggle = useCallback(() => setVisible((val) => !val), []);
  return createElement(
    CanvasGlobalPanelContext.Provider,
    { value: { visible, toggle, enable, disable } },
    children
  );
};

export const useCanvasGlobalPanel = (): typeof contextPrototype => {
  const context = useContext(CanvasGlobalPanelContext);
  if (context === undefined) {
    throw new Error('Canvas Global Panel Context hooks require a provider');
  }
  return context;
};
