import React from 'react';
import Select, { ValueType } from 'react-select';
import { useUser } from '../../contexts/user';

export const RegionPicker: React.FC = () => {
  const user = useUser();
  const { accessibleRegions } = user;

  type OptionType = { label: string; value: string };
  const options = accessibleRegions.map(({ name, newStudioUrl }) => ({
    value: newStudioUrl,
    label: name,
  }));

  if (options.length <= 1) {
    return null;
  }

  const currentRegion = accessibleRegions.find((opt) => opt.current)?.name;
  const currentOption = options.find((opt) => opt.label === currentRegion);
  const handleChange = (option: ValueType<OptionType>): void => {
    if (option) window.location.href = (option as OptionType).value;
  };

  return (
    <Select
      classNamePrefix="white"
      menuPlacement="top"
      onChange={handleChange}
      options={options}
      value={currentOption}
    />
  );
};
