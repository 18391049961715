import * as React from 'react';
import { Modal } from 'DesignSystem/Components/Modal';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import styles from './modal.module.css';

export type JourneyModalProps = {
  name: string;
  action: () => void;
  cancel?: () => void;
  close?: () => void;
  header: React.ReactNode;
  body: React.ReactNode;
  actionLabel?: string;
  cancelLabel?: string;
  actionsDisabled?: boolean;
};

export const JourneyModal: React.FC<JourneyModalProps> = ({
  name,
  header,
  body,
  actionLabel,
  action,
  cancelLabel,
  cancel,
  actionsDisabled,
}) => {
  return (
    <Modal innerPadding={0} name={name}>
      <Flex start column className={styles.modalWrapper}>
        <Flex start className={styles.modalHeader}>
          {header}
        </Flex>
        <div className={styles.modalBody}>{body}</div>
        <Flex end className={styles.modalFooter}>
          {cancel && cancelLabel && (
            <Button
              disabled={actionsDisabled}
              borderless
              secondary
              label={cancelLabel}
              onClick={cancel}
            />
          )}
          {action && actionLabel && (
            <Button
              disabled={actionsDisabled}
              label={actionLabel}
              onClick={action}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
