import React from 'react';
import { ListDropdownItem } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { useProgram } from 'contexts/program';
import { useSemiPaginatedContents } from 'hooks/insights/useSemiPaginatedQuery';
import { PaginatedListDropdownProvider } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { PaginatedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/PaginatedListDropdown';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { FlipSwitch } from 'shared/FlipSwitch';
import { Option } from 'models/insight/json/filterJson';
import { useQuery } from 'react-query';
import { fetchAndTransformContentFilters } from '../../../../../../../../services/api-insights';

export const ContentPostsListDropdown: React.FC = () => {
  const { id: programId } = useProgram();
  const { reportId } = React.useContext(ReportContext);
  const { dateRange } = React.useContext(FiltersStateContext);
  const {
    defaultValues,
    debouncedSearchString,
    filter,
    setCustomHeaderComponent,
  } = React.useContext(FilterDropdownContext);

  const [
    shouldShowActivePostsOnly,
    setShouldShowActivePostsOnly,
  ] = React.useState(false);

  const defaultIds = defaultValues
    ? Array.from(defaultValues.values()).map((i) => parseInt(i, 10))
    : [];

  const { isLoading: isInitialDataLoading, data: initialData } = useQuery<
    Option[]
  >({
    queryKey: [
      'insights-content-filter',
      { programId, reportId, dateRange, defaultIds },
    ],
    queryFn: () =>
      fetchAndTransformContentFilters({
        page: 1,
        programId,
        reportId,
        filterSlug: filter.slug,
        dateRange: false,
        ids: defaultIds,
      }),
  });

  const {
    fetchNext,
    isLoading,
    hasMoreToLoad,
    aggregatedData,
    errorMessage,
    isFetching,
  } = useSemiPaginatedContents({
    programId,
    reportId,
    filterSlug: filter.slug,
    dateRange,
    search: debouncedSearchString,
  });

  const customHeaderComponent = React.useMemo<React.ReactNode>(() => {
    return (
      <div
        style={{
          padding: '0 10px 10px 10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <FlipSwitch
            onChange={(val) => setShouldShowActivePostsOnly(val || false)}
            on={shouldShowActivePostsOnly}
            shape="rounded"
          />
        </div>
        <div style={{ marginLeft: 10 }}>Active Posts Only</div>
      </div>
    );
  }, [setShouldShowActivePostsOnly, shouldShowActivePostsOnly]);

  React.useEffect(() => {
    setCustomHeaderComponent(customHeaderComponent);
  }, [customHeaderComponent, setCustomHeaderComponent, filter]);

  const contentPostsOptions = React.useMemo<Option[]>(() => {
    if (!aggregatedData && !initialData) return [];

    const mergedDataHash: { [key: string]: Option } = {};

    if (!debouncedSearchString) {
      initialData?.forEach((t) => {
        mergedDataHash[`${t.value}`] = t;
      });
    }

    aggregatedData?.forEach((t) => {
      mergedDataHash[`${t.value}`] = t;
    });

    // Back to an array
    const mergedData: Option[] = Object.values(mergedDataHash);

    if (shouldShowActivePostsOnly) {
      return mergedData.filter((value) => !value.archived);
    }
    return mergedData;
  }, [
    shouldShowActivePostsOnly,
    aggregatedData,
    initialData,
    debouncedSearchString,
  ]);

  const allItems: ListDropdownItem[] = React.useMemo(() => {
    return contentPostsOptions.map((contentOption: Option) => ({
      label: String(contentOption.label),
      value: String(contentOption.value),
      uuid: String(contentOption.value),
    }));
  }, [contentPostsOptions]);

  return (
    <PaginatedListDropdownProvider
      allItems={allItems}
      fetchNext={fetchNext}
      isLoadingMoreItems={isLoading || isFetching || isInitialDataLoading}
      hasMoreToLoad={hasMoreToLoad}
      defaultValues={defaultValues}
      errorMessage={errorMessage}
    >
      <PaginatedListDropdown />
    </PaginatedListDropdownProvider>
  );
};
