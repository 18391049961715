import React from 'react';
import { deepMerge } from 'utility/deep-merge';

export type Filter = {
  name: string;
  field: string;
  label: string;
  values: Array<string>;
  states?: Array<string>;
  isVisible: boolean;
};

export type BooleanFilter = {
  name: string;
  field?: string;
  label: string;
  value?: boolean;
};

export type FiltersStateType = {
  standard: { [name: string]: Filter };
  boolean: { [name: string]: BooleanFilter };
};

export type UserFiltersContextType = {
  filters: FiltersStateType;
  setBooleanValue: (
    name: string,
    value: boolean,
    shouldInvokeCallback?: boolean
  ) => void;
  setValue: (
    name: string,
    values: Array<string>,
    shouldInvokeCallback?: boolean
  ) => void;
  setVisibility: (name: string, visible: boolean) => void;
};

export const UserFiltersContext = React.createContext<UserFiltersContextType>({
  setBooleanValue: () => {},
  setValue: () => {},
  setVisibility: () => {},
  filters: {
    standard: {},
    boolean: {},
  },
});

/*
Put all possible user filters here.

⚠️Any added filters *must* be compatible with bulk operations, such as bulk archiving.⚠️
Ensure params are being passed in the request, and are parsed correctly on the receiving end.
 */
export const defaultState = (
  partialState?: Partial<FiltersStateType>
): FiltersStateType => {
  const initial = {
    standard: {},
    boolean: {
      scopes: {
        name: 'scopes',
        field: 'scopes',
        label: 'Permissions',
      },
      statuses: {
        name: 'statuses',
        field: 'statuses',
        label: 'Status',
      },
      roles: {
        name: 'roles',
        field: 'roles',
        label: 'Role',
      },
      audiences: {
        name: 'audiences',
        field: 'audiences',
        label: 'Audiences',
      },
    },
    date: {},
  };

  return deepMerge(initial, partialState, { arrays: 'replace' });
};

type FilterProps = {
  customDefaultState?: FiltersStateType;
  filterCallback?: (filters: FiltersStateType) => void;
};

export const useFilters = ({
  customDefaultState,
  filterCallback,
}: FilterProps): {
  filters: FiltersStateType;
  setBooleanValue: (name: string, value: boolean) => void;
  setValue: (name: string, values: Array<string>) => void;
  setVisibility: (name: string, value: boolean) => void;
} => {
  const [filters, setFiltersState] = React.useState<FiltersStateType>(
    customDefaultState ?? defaultState()
  );
  const setVisibility = React.useCallback(
    (name: string, visible: boolean) => {
      const filterToUpdate = filters.standard[name];
      if (filterToUpdate) {
        filterToUpdate.isVisible = visible;
        const newState = {
          ...filters.standard,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          standard: newState,
        });
      }
    },
    [filters]
  );

  const setBooleanValue = React.useCallback(
    (name: string, value: boolean) => {
      const filterToUpdate = filters.boolean[name];
      if (filterToUpdate) {
        filterToUpdate.value = value;
        const newState = {
          ...filters.boolean,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          boolean: newState,
        });
        if (filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  const setValue = React.useCallback(
    (name: string, values: Array<string>) => {
      const filterToUpdate = filters.standard[name];
      if (filterToUpdate) {
        filterToUpdate.values = values;
        const newState = { ...filters.standard, [name]: filterToUpdate };

        const state = {
          ...filters,
          standard: newState,
        };
        setFiltersState(state);
        if (filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  return {
    filters,
    setValue,
    setBooleanValue,
    setVisibility,
  };
};

export const UserFiltersProvider: React.FC = ({ children }) => {
  const { filters, setValue, setBooleanValue, setVisibility } = useFilters({});

  return (
    <UserFiltersContext.Provider
      value={{
        setVisibility,
        setBooleanValue,
        setValue,
        filters,
      }}
    >
      {children}
    </UserFiltersContext.Provider>
  );
};
