import React from 'react';
import { useInfiniteApiQuery } from 'hooks/common';
import {
  useDeferFlagsMutation,
  useDeleteCommentMutation,
} from 'hooks/content/comment-actions';
import { Flex } from 'DesignSystem/Layout/Flex';
import { fetchPostComments } from 'services/api-content';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { Modal } from 'shared/Modal';
import { useQueryClient } from 'react-query';
import { flaggedCountQueryKey } from 'hooks/posts-metadata';
import { useProgram } from 'contexts/program';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import styles from './comments-modal.module.css';
import { Comments } from './Comments';

type CommentsModalProps = {
  postId: number;
  onClose: () => void;
  programId: number;
};

function useInvalidateContentMetadata() {
  const queryClient = useQueryClient();
  const programId = useProgram().id;
  const invalidateFlaggedCount = () =>
    queryClient.invalidateQueries({
      queryKey: flaggedCountQueryKey(programId),
    });

  return { invalidateFlaggedCount };
}

export const CommentsModal: React.FC<CommentsModalProps> = ({
  postId,
  onClose,
  programId,
}) => {
  const { invalidateFlaggedCount } = useInvalidateContentMetadata();
  const [selectedFilters, setSelectedFilters] = useFilterDropdownQueryString(
    'filters'
  );
  const {
    data,
    errorMessage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteApiQuery('comment_posts', fetchPostComments, {
    postId,
    programId,
    filters: selectedFilters,
  });

  const onSuccessfulMutation = React.useCallback(() => {
    invalidateFlaggedCount();
    refetch?.();
  }, [invalidateFlaggedCount, refetch]);

  const {
    mutate: deleteComment,
    isLoading: isDeleting,
    activeId: deleteId,
  } = useDeleteCommentMutation({
    onSuccess: onSuccessfulMutation,
  });
  const {
    mutate: deferFlags,
    isLoading: isDeferring,
    activeId: deferId,
  } = useDeferFlagsMutation({
    onSuccess: onSuccessfulMutation,
  });

  const ref = React.useRef<HTMLDivElement>(null);

  const NoComments = (
    <Flex>
      <p>No comments found.</p>
    </Flex>
  );

  const filtersEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Comments.Filters.Enabled'
  ).data?.value;

  const possibleFilters = [
    { value: 'flagged', label: 'Flagged' },
    { value: 'deleted', label: 'Deleted' },
  ];
  const onFilterChange = (values: string[]) => {
    setSelectedFilters(values);
  };

  return (
    <Modal
      title="Comments & Replies"
      showTitle
      showModal
      onClose={onClose}
      modalBodyStyle={{
        width: '700px',
      }}
    >
      <section className={styles.content} ref={ref}>
        {filtersEnabled && (
          <div className={styles.filter}>
            <FilterDropdown
              label="Type"
              options={possibleFilters}
              onChange={(values) => onFilterChange(values)}
              selectedOptions={possibleFilters.filter((opt) =>
                selectedFilters?.includes(opt.value)
              )}
            />
          </div>
        )}
        {errorMessage && <>{errorMessage}</>}
        <InfiniteBannerList
          itemCount={data?.length || 0}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          noItemsComponent={NoComments}
          parentRef={ref}
          rowHeight={130}
        >
          {(index: number) => {
            const comment = data[index];
            return (
              <Comments
                key={comment.id}
                postId={postId}
                programId={programId}
                comment={comment.attributes}
                deleteComment={deleteComment}
                deferFlags={deferFlags}
                isDeleting={isDeleting}
                deleteId={deleteId}
                isDeferring={isDeferring}
                deferId={deferId}
              />
            );
          }}
        </InfiniteBannerList>
      </section>
    </Modal>
  );
};
