import * as React from 'react';

export function attemptInterval(
  attempt: () => boolean,
  config: {
    maxAttempts?: number;
    intervalWait?: number;
  } = {}
): NodeJS.Timeout {
  const { maxAttempts, intervalWait } = {
    maxAttempts: 3,
    intervalWait: 1000,
    ...config,
  };
  let attemptNumber = 1;
  const interval = setInterval(() => {
    // console.debug(`attempt #${attemptNumber}`);
    try {
      if (attempt()) {
        // console.debug('success');
        clearInterval(interval);
      } else if (attemptNumber >= maxAttempts) {
        // console.debug('quitting');
        clearInterval(interval);
      } else {
        // console.debug('retrying');
        attemptNumber += 1;
      }
    } catch {
      // console.debug('error');
      clearInterval(interval);
    }
  }, intervalWait);
  return interval;
}

export function useAttempts(
  ...[attempt, config]: Parameters<typeof attemptInterval>
): void {
  React.useEffect(() => {
    const interval = attemptInterval(attempt, config);
    return () => clearInterval(interval);
  }, [attempt, config]);
}
