import React, { useEffect } from 'react';
import { Modal, Box } from 'DesignSystem/Components';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { useUniqueId } from 'hooks/useUniqueId';
import * as Text from 'DesignSystem/Typography';

import { SearchBar } from 'shared/SearchBar';
import { fetchAINavigationIntent } from 'services/api-ai-navigation';
import { useProgramIdState } from 'contexts/program';
import { navigate } from '@reach/router';

import styles from './styles.module.css';
import { useFeedback } from '../Feedback/FeedbackProvider';

type PropsType = {
  close: () => void;
};

export const AIAssistedSearchModal: React.FC<PropsType> = ({ close }) => {
  const [searchText, setSearchText] = React.useState('');

  const [programId] = useProgramIdState();
  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [searchError, setSearchError] = React.useState('');

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const showSearchError = React.useCallback(() => {
    setSearchError('No results');
  }, []);

  const cancelButton = React.useRef(false);
  const containerId = useUniqueId();

  // force reload if the url except the query is the same
  const navigateIfUrlDiffers = React.useCallback((newUrl) => {
    const currentUrl = window.location.href;
    const parsedCurrentUrl = new URL(currentUrl);
    const parsedNewUrl = new URL(newUrl, window.location.origin);
    if (parsedCurrentUrl.pathname !== parsedNewUrl.pathname) {
      navigate(newUrl);
    } else {
      window.location.href = newUrl;
    }
  }, []);

  const { requestFeedback } = useFeedback();
  const handleRedirect = React.useCallback(
    async (response: string, task_id: string) => {
      if (!response) {
        showSearchError();
        setIsSearchLoading(false);
        return;
      }

      let parsed;
      try {
        parsed = JSON.parse(response);
      } catch (e) {
        showSearchError();
        setIsSearchLoading(false);
        return;
      }

      if (!parsed?.intent) {
        showSearchError();
        setIsSearchLoading(false);
        return;
      }

      const topicMapping: { [key: string]: string } = {
        account: 'account',
        activityFeed: 'activities',
        advocacy: 'advocacy',
        roles: 'roles',
        campaignSettings: 'campaign-settings',
        emailAliases: 'email-aliases',
        feeds: 'feeds',
        initiatives: 'initiatives',
        orchestrationSettings: 'orchestration-settings',
        topic: 'topic',
        userExports: 'exports',
        userImports: 'imports',
        assistantMascot: 'assistant-mascot',
        branding: 'branding',
        customFeed: 'custom-homepage',
        shortcuts: 'shortcuts',
        legal: 'legal',
        sessionOptions: 'session-options',
        userAccess: 'user-access',
      };

      const audienceStatusMapping: { [key: string]: string } = {
        active: 'enabled',
        archived: 'disabled',
        deactivated: 'disabled',
        disabled: 'disabled',
      };

      const handleContentCase = (parameters: { [key: string]: string }) => {
        const baseUrl = `/${programId}/app/content`;
        const copiedParameters = { ...parameters };
        const status = copiedParameters?.status ?? 'sent';
        delete copiedParameters.status;
        const urlParams = new URLSearchParams(copiedParameters);
        const newUrl = `${baseUrl}/${status}?${urlParams.toString()}`;
        if (window.location.pathname.startsWith(baseUrl)) {
          window.location.href = newUrl;
        } else {
          navigateIfUrlDiffers(newUrl);
        }
      };

      const handleConfigCase = (parameters: { topic?: string }) => {
        const baseUrl = `/${programId}/configure`;
        const topic = topicMapping[parameters?.topic || ''] || '';
        navigateIfUrlDiffers(`${baseUrl}/${topic}`);
      };

      const handleAudienceCase = (parameters: { [key: string]: string }) => {
        const baseUrl = `/${programId}/app/people/audiences`;
        const updatedParameters = { ...parameters };

        if (parameters?.status) {
          updatedParameters.statuses = updatedParameters.status
            .split(',')
            .map((status) => audienceStatusMapping[status.trim()] || null)
            .filter((status) => status !== null)
            .join(',');

          delete updatedParameters.status;
        }

        const urlParams = new URLSearchParams(updatedParameters);
        navigateIfUrlDiffers(`${baseUrl}?${urlParams.toString()}`);
      };

      const handleUsersCase = (parameters: { [key: string]: string }) => {
        const baseUrl = `/${programId}/app/people/users`;
        const urlParams = new URLSearchParams(parameters);
        navigateIfUrlDiffers(`${baseUrl}?${urlParams.toString()}`);
      };

      const handleLibraryCase = (parameters: { type?: string }) => {
        const type = parameters?.type ?? 'templates';
        navigateIfUrlDiffers(`/${programId}/app/library/${type}`);
      };

      const intent = parsed.intent.toLowerCase();

      switch (intent) {
        case 'content':
          handleContentCase(parsed.parameters);
          break;
        case 'config':
          handleConfigCase(parsed.parameters);
          break;
        case 'audience':
          handleAudienceCase(parsed.parameters);
          break;
        case 'users':
          handleUsersCase(parsed.parameters);
          break;
        case 'calendar':
          navigateIfUrlDiffers(`/${programId}/app/calendar`);
          break;
        case 'insights':
          navigateIfUrlDiffers(`/${programId}/app/insights`);
          break;
        case 'library':
          handleLibraryCase(parsed.parameters);
          break;
        default:
          showSearchError();
          break;
      }

      const valid = [
        'content',
        'config',
        'audience',
        'users',
        'calendar',
        'insights',
        'library',
      ];
      if (valid.includes(intent)) {
        requestFeedback('Studio.AI.Navigation', task_id);
      }

      setIsSearchLoading(false);
    },
    [navigateIfUrlDiffers, programId, showSearchError, requestFeedback]
  );

  const handleSearch = React.useCallback(async () => {
    setIsSearchLoading(true);
    const response = await fetchAINavigationIntent({
      programId,
      search: searchText,
    });
    handleRedirect(response.outputs[0], response.task_ids[0]);
  }, [handleRedirect, programId, searchText]);

  useEffect(() => {
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        handleSearch();
      }
    };

    // Attach the event listener
    document.addEventListener('keydown', handleKeydown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleSearch]);

  return (
    <>
      <Modal
        name="edit-role-modal"
        width={960}
        innerPadding={0}
        onClick={(e) => {
          const contained = document
            .getElementById(containerId)
            ?.contains(e.target as HTMLElement);
          if (!contained || cancelButton.current) {
            close();
          }
        }}
      >
        <div id={containerId} className={styles.EditRoleModal}>
          <Box padding={20}>
            <Flex spread>
              <Text.Heading bold block>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1343_24750)">
                    <g clipPath="url(#clip1_1343_24750)">
                      <path
                        d="M10.1176 3.24562L11.9912 3.99462L12.7407 5.86709C12.8031 5.96071 12.8968 6.02313 12.9905 6.02313C13.0529 6.02313 13.1466 5.96071 13.2091 5.86709L13.9897 3.99462L15.8321 3.24562C15.9258 3.18321 15.9883 3.08959 15.9883 2.99596C15.9883 2.93355 15.9258 2.83992 15.8321 2.77751L13.9897 1.99731L13.2091 0.15604C13.1466 0.0624159 13.0529 0 12.9905 0C12.8968 0 12.8031 0.0624159 12.7407 0.15604L11.9912 1.99731L10.1176 2.77751C10.0239 2.83992 9.96144 2.93355 9.96144 2.99596C9.96144 3.08959 10.0239 3.18321 10.1176 3.24562ZM15.8321 12.764L13.9897 11.9838L13.2091 10.1426C13.1466 10.049 13.0529 9.98654 12.9905 9.98654C12.8968 9.98654 12.8031 10.049 12.7407 10.1426L11.9912 11.9838L10.1176 12.764C10.0239 12.8265 9.96144 12.9201 9.96144 12.9825C9.96144 13.0761 10.0239 13.1697 10.1176 13.2322L11.9912 13.9812L12.7407 15.8536C12.8031 15.9473 12.8968 16.0097 12.9905 16.0097C13.0529 16.0097 13.1466 15.9473 13.2091 15.8536L13.9897 13.9812L15.8321 13.2322C15.9258 13.1697 15.9883 13.0761 15.9883 12.9825C15.9883 12.9201 15.9258 12.8265 15.8321 12.764ZM11.9912 7.98923C11.96 7.83319 11.8663 7.61474 11.7102 7.55232L8.1815 5.77347L6.43278 2.27818C6.24542 1.93489 5.71456 1.93489 5.52719 2.27818L3.77847 5.77347L0.249809 7.55232C0.0936737 7.61474 -0.0312347 7.83319 -0.0312347 7.98923C-0.0312347 8.17648 0.0936737 8.36373 0.249809 8.42614L3.77847 10.205L5.52719 13.7315C5.62087 13.8875 5.80824 13.9812 5.9956 13.9812C6.15173 13.9812 6.3391 13.8875 6.43278 13.7315L8.1815 10.205L11.7102 8.42614C11.8663 8.36373 11.9912 8.17648 11.9912 7.98923Z"
                        fill="#F74857"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_1343_24750">
                      <rect width="16" height="16" rx="4" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1343_24750">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={styles.title}>AI Assisted Search</span>
              </Text.Heading>
              <Button
                onClick={() => {
                  cancelButton.current = true;
                }}
                clearText
                label={<Text.Heading>×</Text.Heading>}
              />
            </Flex>

            <Text.Body color={Text.color.gray60}>
              Use the power of Firstup AI to search for campaigns, people,
              audiences, settings, insights or library assets. Enter keywords
              and filter information to bring you directly where you need to go.
            </Text.Body>
          </Box>
          <Box padding={[0, 20]}>
            <SearchBar
              inputRef={searchInputRef}
              value={searchText}
              onChange={(v) => {
                setSearchText(v);
                setSearchError('');
              }}
              placeholder="Search campaigns, people, audiences, settings, insights or library assets..."
              showSpinner={isSearchLoading}
              hasClearButton={!isSearchLoading && searchText.length > 0}
            />
          </Box>
          {searchError && (
            <Box margin={[16, 24]}>
              <Text.Body color={Text.color.redFull}>No results</Text.Body>
            </Box>
          )}
          <Box margin={[16, 24]}>
            <Flex spread>
              <div>
                <span className={styles.center}>
                  <svg
                    width="11"
                    height="14"
                    viewBox="0 0 11 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5 13.375C5.21667 13.375 4.96592 13.3001 4.74775 13.1504C4.52911 13.0007 4.37847 12.8106 4.29583 12.5803H4.08333C3.78819 12.5803 3.53744 12.4766 3.33108 12.2693C3.12425 12.062 3.02083 11.8201 3.02083 11.5437V9.26321C2.30069 8.84858 1.7371 8.28421 1.33004 7.57012C0.922514 6.85603 0.71875 6.09587 0.71875 5.28963C0.71875 3.98814 1.18224 2.88545 2.10921 1.98154C3.03571 1.07718 4.16597 0.625 5.5 0.625C6.83403 0.625 7.96453 1.07718 8.8915 1.98154C9.818 2.88545 10.2812 3.98814 10.2812 5.28963C10.2812 6.1189 10.0777 6.88482 9.67067 7.5874C9.26314 8.28997 8.69931 8.84858 7.97917 9.26321V11.5437C7.97917 11.8201 7.87575 12.062 7.66892 12.2693C7.46256 12.4766 7.21181 12.5803 6.91667 12.5803H6.70417C6.62153 12.8106 6.47089 13.0007 6.25225 13.1504C6.03408 13.3001 5.78333 13.375 5.5 13.375ZM4.08333 11.5437H6.91667V10.8872H4.08333V11.5437ZM4.08333 10.2825H6.91667V9.60874H4.08333V10.2825ZM3.94167 8.57215H5.075V6.56809L3.55208 5.08232L4.15417 4.49492L5.5 5.80793L6.84583 4.49492L7.44792 5.08232L5.925 6.56809V8.57215H7.05833C7.69583 8.2727 8.21528 7.83203 8.61667 7.25016C9.01805 6.66875 9.21875 6.01524 9.21875 5.28963C9.21875 4.27608 8.85868 3.41802 8.13854 2.71545C7.4184 2.01287 6.53889 1.66159 5.5 1.66159C4.46111 1.66159 3.5816 2.01287 2.86146 2.71545C2.14132 3.41802 1.78125 4.27608 1.78125 5.28963C1.78125 6.01524 1.98194 6.66875 2.38333 7.25016C2.78472 7.83203 3.30417 8.2727 3.94167 8.57215Z"
                      fill="#666666"
                    />
                  </svg>
                </span>
                <span className={styles.title}>
                  <Text.Caption>Use Ctrl+k as a shortcut</Text.Caption>
                </span>
              </div>
              <Flex end>
                <Button
                  secondary
                  label="Cancel"
                  className="cancel-button"
                  onClick={() => {
                    cancelButton.current = true;
                  }}
                />
                <Space row size={16} />
                <Button
                  id="search-button"
                  label="Search"
                  onClick={async () => {
                    await handleSearch();
                  }}
                  disabled={
                    !searchText || isSearchLoading || searchError !== ''
                  }
                />
              </Flex>
            </Flex>
          </Box>
        </div>
      </Modal>
    </>
  );
};
