import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Placeholder } from 'DesignSystem/Components/Placeholder';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Type from 'DesignSystem/Typography';
import { Close, Plus, Trash } from 'shared/icons';
import { useVideoSourceMenu } from '../hooks/useVideoSourceMenu';
import { SourceMenu } from '../../shared/SourceMenu';

type MediaUploaderProps = {
  menu: ReturnType<typeof useVideoSourceMenu>['menu'];
  placeholderTitle: string;
  placeholderBody: string;
  onRemove: () => void;
  removeTitle?: string;
  disableRemove?: boolean;
  disableSourceMenu?: boolean;

  // When true will wrap with placeholder
  isPreparing?: boolean; // and hide the controls bar
  isUploading?: boolean; // and show loading controls bar
  isProcessing?: boolean; // and show processing controls bar

  lockVideoPreview?: boolean; // it will maintain a "live" preview of the video
};

export const MediaUploader: React.FC<MediaUploaderProps> = ({
  menu,
  placeholderTitle,
  placeholderBody,
  onRemove,
  removeTitle,
  disableRemove = false,
  disableSourceMenu = false,
  isPreparing = false,
  isUploading = false,
  isProcessing = false,
  lockVideoPreview = false,
  children,
}) => {
  const replaceButtonRef = React.useRef<HTMLButtonElement>(null);

  const isLoading = isUploading || isProcessing;
  const showPlaceholder =
    !isLoading && React.Children.toArray(children).length < 1;

  const ControlsBar = React.useCallback<React.FC>(() => {
    if (showPlaceholder || isPreparing || lockVideoPreview) return null;

    const onClick = (event: React.MouseEvent) => {
      // Only permits clicks from the Replace button to
      // trigger the source menu.  All other clicks on
      // the controls bar will be ignored.
      if (
        !(event.target instanceof Element) ||
        !replaceButtonRef.current?.contains(event.target)
      ) {
        event.stopPropagation();
      }
    };

    return (
      <Flex
        style={{ cursor: 'auto', width: '100%', height: '40px' }}
        spread
        color={Type.background.gray05}
        onClick={onClick}
      >
        {isLoading ? (
          <>
            <div style={{ padding: '0 20px' }}>
              <Type.Body>
                {isUploading ? 'Uploading...' : 'Processing...'}
              </Type.Body>
            </div>
            {!disableRemove && (
              <Button
                clearText
                title={removeTitle}
                icon={<Close style={{ width: '18px' }} />}
                onClick={onRemove}
              />
            )}
          </>
        ) : (
          <>
            <Button
              clearText
              icon={<i className="fa fa-upload" aria-hidden="true" />}
              ref={replaceButtonRef}
            />
            {!disableRemove && (
              <Button
                clearText
                title={removeTitle}
                icon={<Trash style={{ width: '18px' }} />}
                onClick={onRemove}
              />
            )}
          </>
        )}
      </Flex>
    );
  }, [
    disableRemove,
    isLoading,
    isPreparing,
    isUploading,
    onRemove,
    removeTitle,
    showPlaceholder,
    lockVideoPreview,
  ]);

  return (
    <SourceMenuWrapper wrap={!disableSourceMenu} menu={menu}>
      <PlaceholderWrapper wrap={isPreparing || isLoading || showPlaceholder}>
        {showPlaceholder ? (
          <>
            <Type.Subheading
              color={disableSourceMenu ? Type.color.gray50 : undefined}
              block
              bold
            >
              {placeholderTitle}
            </Type.Subheading>
            <Button
              disabled={disableSourceMenu}
              circle
              compact
              icon={<Plus />}
            />
            <Type.Body block color={Type.color.gray50}>
              {placeholderBody}
            </Type.Body>
          </>
        ) : (
          children
        )}
        <ControlsBar />
      </PlaceholderWrapper>
    </SourceMenuWrapper>
  );
};

// Conditionally wraps the children with a source menu.
// Pass false to the wrap property to disable the source menu.
const SourceMenuWrapper: React.FC<{
  wrap: boolean;
  menu: ReturnType<typeof useVideoSourceMenu>['menu'];
}> = ({ wrap, children, menu }) => (
  <Wrapper
    wrap={wrap}
    wrapper={(contents) => (
      <SourceMenu menu={menu}>
        <Box radius={4} style={{ overflow: 'hidden' }}>
          {contents}
        </Box>
      </SourceMenu>
    )}
  >
    {children}
  </Wrapper>
);

// Conditionally wraps the children with a placeholder.
// Pass true to the wrap property to apply its styling around
// the elements.
const PlaceholderWrapper: React.FC<{
  wrap: boolean;
}> = ({ wrap, children }) => (
  <Wrapper
    wrap={wrap}
    wrapper={(contents) => (
      <Placeholder>
        <Flex column style={{ width: '100%', height: '100%' }}>
          {contents}
        </Flex>
      </Placeholder>
    )}
  >
    {children}
  </Wrapper>
);

const Wrapper: React.FC<{
  wrap: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}> = ({ wrap, wrapper, children }) => (
  <>{wrap ? wrapper(children) : children}</>
);
