import * as React from 'react';
import { InfiniteQueryResponse } from 'hooks/common';
import { ContentFilterFetchProps } from 'components/content/ContentFilterBar/ContentFilterBar';
import { ProgramIdProp } from 'services/api-insights';
import { FiltersStateType } from 'contexts/content/filters';
import { Content } from 'models/content';
import { Content as LibraryContent } from 'models/library';
import { useScrollEffect } from '../PickerScreen/useScrollEffect';
import styles from '../PickerScreen/picker-screen.module.css';
import { Sidebar } from './Sidebar';
import { Items } from './Items';
import { ContentHeader } from './ContentsHeader';
import { usePicker } from './usePicker';

type PropsType = {
  label: string;
  topOffset?: number;
  onChange: (items: Array<LibraryContent>) => void;
  onCancel: () => void;
  maxSelections: number;
  useLibraryItems: (
    fetchProps: ContentFilterFetchProps & ProgramIdProp,
    filters: FiltersStateType
  ) => InfiniteQueryResponse<Content>;
};

export const PickerScreen: React.FC<PropsType> = ({
  onChange,
  onCancel,
  maxSelections,
  useLibraryItems,
  label,
}) => {
  const { onApply, collection, paging } = usePicker({
    onChange,
    useLibraryItems,
  });
  const { refs } = useScrollEffect(paging);
  refs.sidebarSize.current = (collection.selection || []).length;

  return (
    <div className={styles.wrapper} ref={refs.wrapperElement}>
      <Sidebar
        selection={collection.selection}
        label={label}
        sidebarRef={refs.sidebarElement}
        onApply={onApply}
        onCancel={onCancel}
      />
      <div className={styles.main} ref={refs.contentElement}>
        <ContentHeader
          filterFetchProps={collection.search}
          setFilterFetchProps={collection.setSearch}
          filters={collection.filters}
          setValue={collection.setFilterValue}
          setBooleanValue={collection.setFilterBoolean}
        />
        <Items
          isLoading={collection.isLoading}
          items={collection.items}
          label={label}
          maxSelections={maxSelections}
          pagination={collection.pagination}
          select={collection.select}
          selection={collection.selection}
        />
      </div>
    </div>
  );
};
