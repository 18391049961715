import React, { createContext, useContext, useState, useEffect } from 'react';
import { defaultPost, Post } from 'models/publisher/post';
import { LoadingScreen } from 'shared/LoadingScreen';
import { NotFoundScreen } from 'shared/NotFoundScreen';
import { PermissionDeniedScreen } from 'shared/PermissionDeniedScreen';
import { UsePost, usePost } from 'hooks/publisher';
import { usePermissions } from 'contexts/permissions';
import { defaultPostStatus } from 'hooks/persist-post';
import { useLiquidVariablesQuery } from 'hooks/liquid-variables/useQueries';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { datadogRum } from '@datadog/browser-rum';
import { resolveVariables } from 'components/publisher/blocks/instances/variableResolver';
import { LiquidVariable } from 'models/liquid-variable';
import { CloudbeesFlags } from 'models/feature-flag';
import {
  OrchestrateSettingsEditorContext,
  SettingsEditor,
} from './publisher/orchestrate/use-settings';
import { useFeatureFlags } from './feature-flags';

export type PublisherContextData = {
  id: number | 'new';
  post: Post;
  update: UsePost['update'];
  updateContent: UsePost['updateContent'];
  pauseAutosave: UsePost['pauseAutosave'];
  unpauseAutosave: UsePost['unpauseAutosave'];
  cancelAutosave: UsePost['cancelAutosave'];
  save: UsePost['save']; // persists the updated value
  touch: UsePost['touch']; // persists the updated value
  status: UsePost['status'];
  isEditingTemplate: UsePost['isEditingTemplate'];
  disableFetch: UsePost['disableFetch'];
  isProcessing: UsePost['isProcessing'];
  liquidVariables?: LiquidVariable[];
  setLiquidVariables?: React.Dispatch<
    React.SetStateAction<LiquidVariable[] | undefined>
  >;
};

export enum PublisherMode {
  standard,
  htmlFull,
}

const contextPrototype: PublisherContextData = {
  id: 'new',
  post: { ...defaultPost },
  update: () => {},
  save: () => {},
  touch: () => {},
  pauseAutosave: () => {},
  unpauseAutosave: () => {},
  cancelAutosave: () => {},
  updateContent: () => {},
  status: defaultPostStatus,
  isEditingTemplate: true,
  disableFetch: () => {},
  isProcessing: false,
};

const PublisherContext = createContext(contextPrototype);

export const usePublisher = (): PublisherContextData => {
  const context = useContext(PublisherContext);

  if (context === undefined) {
    throw new Error(
      'Publisher context hooks require a containing PublisherProvider'
    );
  }

  return context;
};

export const PublisherProvider: React.FC<{
  programId: number;
  id: number | 'new';
}> = ({ children, programId, id }) => {
  const { post, status, ...context } = usePost(programId, id);

  // populate blocks' field_data when settings are changed from orchestrate page
  // this is necessary to make it so that the changes to author_name (and other fields in the future)
  // make it to the blocks' field_data without opening the design tab.
  const { fromPost } = useFieldVariables();
  const updateSettings = React.useCallback<SettingsEditor['update']>(
    (settings) => {
      if (!post) return;

      const postWithSettings = {
        ...post,
        settings: { ...post.settings, ...settings },
      };
      const vals = fromPost(postWithSettings);
      const blocks = post.blocks.map((currentBlock) => {
        const block = { ...currentBlock };
        const newData = resolveVariables({
          vals,
          fields: block.fields,
          fieldData: block.field_data,
        });

        Object.keys(newData).forEach((k) => {
          block.field_data[k] = newData[k];
        });

        return block;
      });

      context.update({ settings: { ...post.settings, ...settings }, blocks });
    },
    [context, fromPost, post]
  );

  const { data } = useLiquidVariablesQuery(programId, id);

  const [liquidVariables, setLiquidVariables] = useState<
    LiquidVariable[] | undefined
  >();

  useEffect(() => {
    if (data) {
      setLiquidVariables(data);
    }
  }, [data]);

  const { permissions } = usePermissions();

  const featureFlags = useFeatureFlags();

  // TODO: If we get an error loading contentEditable, probably need to display here

  if (status.error === 'not-found')
    return (
      <NotFoundScreen message="This campaign doesn't exist, or you don't have permission to see it." />
    );
  if (status.error === 'unauthorized') return <PermissionDeniedScreen />;
  if (
    !post ||
    Object.keys(featureFlags).length === 0 ||
    Object.keys(permissions).length === 0
  )
    return <LoadingScreen />;

  const value: PublisherContextData = {
    id,
    post,
    status,
    liquidVariables,
    setLiquidVariables,
    ...context,
    save: (saveOptions) => {
      datadogRum.addAction('save_post', post.settings);
      context.save(saveOptions);
    },
  };

  return (
    <PublisherContext.Provider value={value}>
      <OrchestrateSettingsEditorContext.Provider
        value={{
          permissions,
          options: {
            canChangePublicationDate: true,
            canChangeArchiveDate: true,
            defaultNotificationText: post.callToAction.title.slice(0, 120),
            defaultNotificationPreviewText: post.callToAction.summary.slice(
              0,
              120
            ),
          },
          featureFlags,
          cloudbeesFlags: {} as CloudbeesFlags,
          settings: {
            ...post.settings,
            deliveryPageVersion: 2,
            isSizeLimitExceeded: status.isSizeLimitExceeded,
          },
          update: updateSettings,
          liquidVariables: post.liquidVariables,
        }}
      >
        {children}
      </OrchestrateSettingsEditorContext.Provider>
    </PublisherContext.Provider>
  );
};
