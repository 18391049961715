import React from 'react';
import { PublisherStyleEditor } from 'hooks/publisher-style-editor';
import { ColorField, FontField } from 'models/publisher/style';
import styles from '../styles.module.css';
import { Color } from '../Color';
import { Size } from '../Size';
import { Font } from '../Font';

export const FontSection: React.FC<{
  editor: PublisherStyleEditor;
  setting: FontField;
  color?: boolean;
  size?: boolean;
  font?: boolean;
  disabled?: boolean;
}> = ({
  setting,
  editor,
  color = true,
  size = true,
  font = true,
  disabled,
}) => {
  const colorStyling = React.useMemo(() => {
    return editor.displayValue('colors', setting) as string;
  }, [editor, setting]);

  const sizeStyling = React.useMemo(() => {
    return editor.displayValue('sizes', setting) as number;
  }, [editor, setting]);

  const fontStyling = React.useMemo(() => {
    return editor.displayValue('fonts', setting) as string;
  }, [editor, setting]);

  return (
    <fieldset>
      <legend className={styles.fieldsetLegend}>{setting} Font</legend>
      {color && (
        <div className={styles.style}>
          <strong>Font color</strong>
          <Color
            onChange={editor.colorChanger(setting as ColorField)}
            value={colorStyling}
            disabled={disabled}
          />
        </div>
      )}
      {size && (
        <div className={styles.style}>
          <strong>Font size</strong>
          <Size
            value={sizeStyling as number}
            onChange={editor.sizeChanger(setting)}
            min={6}
            max={50}
            disabled={disabled}
          />
        </div>
      )}
      {font && (
        <div className={styles.style}>
          <strong>Font face</strong>
          <Font
            value={fontStyling}
            onChange={editor.fontChanger(setting)}
            disabled={disabled}
            options={editor.fontOptions}
          />
        </div>
      )}
    </fieldset>
  );
};
