import { ThumbnailType } from 'shared/BannerListItem';
import { MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { ItemType } from 'shared/IconMenu/IconMenuItem';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';

export const bulkUpdateToThumbnail = (bulkUpdate: string): ThumbnailType => {
  return {
    title: bulkUpdate,
    imageUrl: '',
    backgroundColor: '#5d6480',
    color: '#fff',
  };
};

export const bulkUpdateToMenuItems = (
  bulkUpdate: BulkUpdatePermissionsJob,
  programId: number,
  downloadErrorFile: (jobId: number) => void
): MenuItemsType => {
  const items: ItemType[] = [
    {
      title: 'View report',
      href: `/${programId}/configure/bulk-updates/${bulkUpdate.id}/details`,
    },
    {
      title: 'Download failed records',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        downloadErrorFile(Number(bulkUpdate.id));
      },
      href: '#',
      disabled: !(
        (bulkUpdate.state === 'partial' || bulkUpdate.state === 'failed') &&
        bulkUpdate.failedUsers > 0
      ),
    },
  ];

  return items;
};
