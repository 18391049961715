import React from 'react';
import { Section } from '../../TopicForm/components/SettingsView/common';
import { Option } from './Option';
import { useAudiencesQuery } from '../../../../../../../hooks/audience';
import { Topic } from '../../../../../../../models/topic';
import { WhitePillClass } from '../../../../../../../DesignSystem/Components/Pill';
import { Pills } from '../../../../../../../DesignSystem/Components/Pills';

interface Props {
  topic: Topic;
}

export function AutoFollow({ topic }: Props): JSX.Element {
  const autoFollowScoped = !!topic.autoFollowGroupIds?.length;
  const { data: audience } = useAudiencesQuery(
    {
      name: autoFollowScoped ? topic.autoFollowGroupIds : [''],
      programId: topic.programId || 0, // If topic is new, query would be disabled anyway
    },
    { enabled: autoFollowScoped }
  );
  return (
    <Section title="Auto-Follow">
      <Option label="Auto-follow" value={topic.autoFollow ? 'ON' : 'OFF'} />
      {topic.autoFollow && (
        <>
          <Option
            label="Audience auto-following"
            value={
              autoFollowScoped
                ? 'Subset of the selected audience'
                : 'Entire selected audience'
            }
          />
          <Pills
            values={audience || []}
            render={(value) => (
              <>
                {value.title || value.name}&nbsp;·&nbsp;{value.totalUsers}
              </>
            )}
            pillClassName={WhitePillClass}
          />
        </>
      )}
    </Section>
  );
}
