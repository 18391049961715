/* eslint-disable no-param-reassign */
import React from 'react';
import { PaginationState } from 'hooks/common';
import { useAutoPaging } from './useAutoPaging';
import { useSidebarPositioning } from './useSidebarPositioning';

export function useScrollEffect(
  paging: React.MutableRefObject<PaginationState>
): {
  refs: {
    contentElement: React.RefObject<HTMLDivElement>;
    sidebarElement: React.RefObject<HTMLDivElement>;
    sidebarSize: React.MutableRefObject<number>;
    wrapperElement: React.RefObject<HTMLDivElement>;
  };
} {
  const wrapperElement = useAutoPaging(paging);
  const refs = useSidebarPositioning(paging, wrapperElement);
  return { refs: { ...refs, wrapperElement } };
}
