import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { LitmusRole, validateRole } from 'models/role';
import { useRoleQueryById } from 'hooks/roles';
import { FormPage } from 'DesignSystem/Layout/Pages';
import equal from 'fast-deep-equal/react';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { Form } from './Form';
import { useActions } from './useActions';
import styles from './roles.module.css';

function determinePageLabel(s: string) {
  switch (s) {
    case 'permissions':
      return 'Permissions';
    case 'general':
    default:
      return 'General';
  }
}

export const EditRolePage: React.FC<RouteComponentProps<{
  id: number;
  section: string;
}>> = ({ id, section }) => {
  const { setFlashMessage } = useFlashMessage();
  const [role, setRole] = React.useState<LitmusRole>();
  const { id: programId } = useProgram();
  const { update } = useActions();
  const { data, isLoading, errorMessage } = useRoleQueryById(
    programId,
    id as number
  );

  React.useEffect(() => {
    if (data) {
      setRole(data);
    }
  }, [data]);

  const handleUpdate = React.useCallback(() => {
    if (!role) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDataSame(data, role)) return;

    const validationError = validateRole(role);
    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });

      return;
    }

    update(role);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, update, setFlashMessage]);

  const isDataSame = (oldData: LitmusRole, newData: LitmusRole) => {
    if (!(oldData && newData)) return;

    oldData.aliases.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    newData.aliases.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    // eslint-disable-next-line consistent-return
    return equal(oldData, newData);
  };

  const onChange = React.useCallback(
    (updatedData: Partial<LitmusRole>) => {
      if (!role) return;

      setRole({ ...role, ...updatedData });
    },
    [role]
  );

  return (
    <WithPermission permissions={['configureRoleAccess']}>
      <FormPage
        title={role?.title || 'Edit role'}
        breadcrumbs={[
          { label: 'Configure', to: '../../../..' },
          { label: 'Roles', to: '../../..' },
          { label: role?.title || 'Edit role', to: `../../details` },
          { label: determinePageLabel(section ?? '') },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <Icon name="SaveSuccess" />,
            onClick: handleUpdate,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled: isDataSame(data, role),
          },
        ]}
      >
        <>
          {isLoading && (
            <div className={styles.Loading}>
              <LoadingSpinner />
            </div>
          )}
          {errorMessage && <>{errorMessage}</>}

          {!isLoading && !errorMessage && role && (
            <Form role={role} section={section} onChange={onChange} />
          )}
        </>
      </FormPage>
    </WithPermission>
  );
};
