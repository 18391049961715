import * as React from 'react';
import cx from 'classnames';
import { FilterLayoutContext } from 'contexts/content/filter-layout';
import { Close } from 'shared/icons';
import { MAIcon } from 'shared/MAIcon';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './filters.module.css';

export const FilterTriggerButton: React.FC<{
  name: string;
  values: Array<string>;
  onClose?: () => void;
}> = ({ name, values, onClose }) => {
  const { layout } = React.useContext(FilterLayoutContext);

  const value = React.useMemo(() => {
    const valuesLabel =
      values.length >= 1
        ? [
            <strong key="selectedLabel" className={styles.SelectedLabel}>
              {values[0]}
            </strong>,
          ]
        : [];
    if (values.length > 1) {
      valuesLabel.push(
        <strong key="additionalCount">&nbsp;+ {values.length - 1}</strong>
      );
    }
    return valuesLabel;
  }, [values]);

  const classNames = React.useMemo(
    () => ({
      FilterTrigger: styles.FilterTrigger,
      FilterTriggerCloseable: styles.FilterTriggerCloseable,
      FilterTriggerApplied: styles.FilterTriggerApplied,
      FilterTriggerCloseButton: styles.FilterTriggerCloseButton,
      FontWeight400: styles.FontWeight400,
    }),
    []
  );

  const icon =
    layout === 'journeys' ? (
      <Flex className={styles.IconBox}>
        <MAIcon className={styles.SmallIcon} name="expand_more" />
      </Flex>
    ) : (
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    );

  return (
    <div
      className={cx(classNames.FilterTrigger, {
        [classNames.FilterTriggerCloseable]: !!onClose,
        [classNames.FilterTriggerApplied]: !!onClose || value?.length > 0,
        [classNames.FontWeight400]: layout === 'journeys',
      })}
    >
      <span className={styles.FlexView}>
        {name}
        {value?.length > 0 && (
          <>
            <strong>&nbsp;is&nbsp;</strong> {value}
          </>
        )}
      </span>
      {onClose ? (
        <div>
          <button
            className={classNames.FilterTriggerCloseButton}
            onClick={onClose}
            type="button"
          >
            <Close />
          </button>
        </div>
      ) : (
        icon
      )}
    </div>
  );
};
