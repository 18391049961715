import { Post } from 'models/publisher/post';
import { Styling, DataBlock } from './publisher/block';
import { CallToAction } from './publisher/call-to-action';
import { Settings } from './publisher/settings';
import { User } from './user';

export enum TemplateContainerType {
  Template = 'template',
  Publisher = 'publisher',
}

export type TemplatePermissionsType =
  | 'Administrators'
  | 'Community Managers'
  | 'Publishers'
  | 'Topic Managers';

export type TemplateSettings = Omit<Settings, 'publishedAt' | 'archiveAt'>;

export function extractTemplateSettings(settings: Settings): TemplateSettings {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { publishedAt, archiveAt, ...keep } = settings;
  return keep;
}

export type TemplateType = {
  permissions?: Array<TemplatePermissionsType>;
  isRestricted?: boolean;
  settings: TemplateSettings;
  blocks: DataBlock[];
  callToAction: CallToAction;
  styles: Styling;
  createdBy?: User;
};

export function templateFromPost(post: Post): TemplateType {
  return {
    callToAction: scrubCta(post.callToAction),
    blocks: post.blocks,
    settings: extractTemplateSettings(post.settings),
    styles: post.styles,
  };
}

function scrubCtaImage(image?: CallToAction['image']): CallToAction['image'] {
  if (!image) return undefined;

  return {
    ...image,
    imageId: undefined,
    processed: false,
  };
}

function scrubCta(cta: CallToAction): CallToAction {
  return { ...cta, image: scrubCtaImage(cta.image) };
}
