import { Post } from 'models/publisher/post';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  changeState,
  mapServerDataToPost,
  StateAction,
  UpsertType,
  upsert,
} from 'services/api-post';
import { MutationResponse } from './common';
import { useFieldVariables } from './publisher/useFieldVariables';

function stateMutator(action: StateAction, shouldUpsert = false) {
  return async (props: UpsertType) => {
    let post: Post;

    if (shouldUpsert) {
      post = await upsert(props);
    } else {
      post = props.post;
    }

    const result = await changeState({
      id: post.content.id,
      programId: props.programId,
      action,
    });

    const mutatedPost = await mapServerDataToPost(props.programId, result);

    if (!mutatedPost) throw new Error('Error while changing state');
    return mutatedPost;
  };
}

type StateMutation = {
  action: StateAction;
  shouldUpsert?: boolean;
  onSuccess: (post?: Post) => void;
  onError?: (error: Error) => void;
};

export const useStateMutation = ({
  action,
  shouldUpsert,
  onSuccess,
  onError,
}: StateMutation): MutationResponse<Pick<UpsertType, 'post' | 'programId'>> => {
  const { fromPost } = useFieldVariables();
  const queryClient = useQueryClient();
  const onSuccessWrapper = useCallback<typeof onSuccess>(
    (post) => {
      if (post?.content.id) {
        queryClient.invalidateQueries(['publisher/content', post.content.id]);
      }
      onSuccess(post);
    },
    [queryClient, onSuccess]
  );
  const { mutate, isLoading } = useMutation<Post, Error, UpsertType>(
    stateMutator(action, shouldUpsert),
    { onSuccess: onSuccessWrapper, onError }
  );

  const mutateWithVars = (props: Pick<UpsertType, 'post' | 'programId'>) =>
    mutate({ ...props, variables: fromPost(props.post) });

  return { mutate: mutateWithVars, isSaving: isLoading };
};
