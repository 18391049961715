import { useFlashMessage } from 'contexts/flasher';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Box } from 'DesignSystem/Components/Box';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';
import { Pill } from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import { Popover } from 'DesignSystem/Components/Popover';
import { Button, Input } from 'DesignSystem/Form';
import { Body, border, Subheading } from 'DesignSystem/Typography';
import {
  useCreateSearchMetaTag,
  useSearchMetaTagsInfiniteQuery,
} from 'hooks/search-meta-tags';
import { SearchMetaTag } from 'models/search-meta-tag';
import React, { useMemo, useState } from 'react';
import { InfiniteList } from 'shared/InfiniteList';
import { useFlashServerErrors } from 'utility/errors';
import cx from 'classnames';
import { SaveModalButtons } from '../../../Shared/SaveModalButtons';
import styles from './select-search-meta-tag.module.css';
import { InfoTooltip } from '../../../Shared/InfoTooltip';

interface SelectSearchMetaTagProps {
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  setDismissable?: (dismissable: boolean) => void;
}

export const searchMetaTagSort = (a: SearchMetaTag, b: SearchMetaTag): number =>
  a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;

export const SelectSearchMetaTag: React.FC<SelectSearchMetaTagProps> = ({
  disabled,
  onCancel,
  onSave,
  setDismissable = () => {},
}) => {
  const {
    update,
    settings: { searchMetaTags },
  } = useSettings();

  const {
    permissions: { campaignsAccess },
  } = usePermissions();

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const { id: programId } = useProgram();
  const [searchText, setSearchText] = React.useState('');
  const [selectedSearchMetaTags, setSelectedSearchMetaTags] = React.useState<
    SearchMetaTag[]
  >(searchMetaTags || []);

  const infiniteSearchMetaTagsQuery = useSearchMetaTagsInfiniteQuery({
    programId,
    pageSize: 20,
    query: searchText,
    statuses: ['active'],
  });

  const filteredSearchMetaTags = useMemo(() => {
    return infiniteSearchMetaTagsQuery.data
      .filter(
        (searchMetaTag) =>
          selectedSearchMetaTags.find(
            (selected) => selected.id === searchMetaTag.id
          ) === undefined
      )
      .sort(searchMetaTagSort);
  }, [infiniteSearchMetaTagsQuery.data, selectedSearchMetaTags]);

  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();

  // disable dismiss if the save button has been enabled
  setDismissable(!saveEnabled);

  const { mutate: createSearchMetaTag } = useCreateSearchMetaTag(programId, {
    onSuccess: (newSearchMetaTag) => {
      setFlashMessage({
        severity: 'info',
        message: 'Search keyword created successfully',
      });
      setSelectedSearchMetaTags([...selectedSearchMetaTags, newSearchMetaTag]);
      setSaveEnabled(true);
      setDismissable(false);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not create search keyword');
    },
  });

  const selectSearchMetaTagDropdown = (dismiss: DismissType) => (
    <Box width={480}>
      <Popover centered>
        <InfiniteList
          itemCount={filteredSearchMetaTags.length}
          itemHeight={40}
          height={200}
          highlightable
          isLoading={infiniteSearchMetaTagsQuery.isLoading}
          hasNextPage={infiniteSearchMetaTagsQuery.hasNextPage}
          fetchNextPage={infiniteSearchMetaTagsQuery.fetchNextPage}
          isFetchingNextPage={infiniteSearchMetaTagsQuery.isFetchingNextPage}
          overscan={10}
        >
          {(index: number) => {
            const searchMetaTag: SearchMetaTag = filteredSearchMetaTags[index];
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                padding={[0, 8]}
                height={40}
                onClick={() => {
                  setSelectedSearchMetaTags([
                    ...selectedSearchMetaTags,
                    searchMetaTag,
                  ]);
                  setSaveEnabled(true);
                  setDismissable(false);
                  dismiss();
                }}
              >
                <Pill
                  value={searchMetaTag}
                  render={(i) => i.name}
                  className={cx(styles.SearchMetaTagPill)}
                  singleLine
                />
              </Box>
            );
          }}
        </InfiniteList>
        {campaignsAccess &&
          searchText.length > 0 &&
          !filteredSearchMetaTags.some(
            (searchMetaTag) =>
              searchMetaTag.name.toLowerCase() === searchText.toLowerCase()
          ) && (
            <Box
              border={[border.solid, border.width1, border.gray10]}
              style={{
                display: 'flex',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: 'none',
              }}
            >
              <Button
                text
                block
                alignContent="left"
                className={styles.CreateButton}
                label={
                  <>
                    <Body>Create</Body>
                    <Pill
                      value={searchText}
                      render={(t) => t}
                      className={styles.CreatePill}
                    />
                  </>
                }
                onClick={() => {
                  createSearchMetaTag({
                    name: searchText,
                  });
                  dismiss();
                }}
              />
            </Box>
          )}
      </Popover>
    </Box>
  );

  return (
    <>
      <Box padding={[0, 16, 16, 0]}>
        <Subheading bold>
          Search Keywords
          <InfoTooltip message="Add search keywords to improve campaign search accuracy." />
        </Subheading>
      </Box>
      <Box padding={[0, 16, 8, 0]}>
        <ClickDropdown
          dropdownRenderProp={selectSearchMetaTagDropdown}
          disabled={disabled}
          placement="top"
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearchText('');
          }}
          ignoreKeys
        >
          <Input
            placeholder={
              campaignsAccess
                ? `Select search keywords or create your own`
                : `Select search keywords`
            }
            block
            value={searchText}
            onChange={setSearchText}
            maxCharacters={30}
            showCharacterCount
          />
        </ClickDropdown>
      </Box>
      <Box maxWidth={512} padding={[0, 16]}>
        <Pills
          values={selectedSearchMetaTags.sort(searchMetaTagSort)}
          render={(searchMetaTag) => searchMetaTag.name}
          onClose={(searchMetaTag: SearchMetaTag) => {
            const newSearchMetaTags = selectedSearchMetaTags.filter(
              (selectedSearchMetaTag: SearchMetaTag) =>
                selectedSearchMetaTag.id !== searchMetaTag.id
            );
            setSelectedSearchMetaTags(newSearchMetaTags);
            setSaveEnabled(true);
            setDismissable(false);
          }}
        />
      </Box>
      <SaveModalButtons
        onCancel={onCancel}
        onSave={() => {
          if (saveEnabled) {
            update({
              searchMetaTags: selectedSearchMetaTags,
            });

            if (onSave) {
              onSave();
            }
          }
        }}
        disabled={!saveEnabled}
      />
    </>
  );
};
