import { useInfiniteQuery, useQuery } from 'react-query';
import { Workflow, WorkflowSummary } from 'models/workflows';
import { useProgram } from 'contexts/program';
import {
  fetchWorkflows,
  FetchProps,
  WorkflowCollection,
  WorkflowSummaryFetchProps,
  fetchWorkflowSummary,
} from 'services/api-workflows';
import {
  InfiniteQueryResponse,
  nextPageToFetch,
  QueryResponse,
} from '../common';

export const useWorkflowsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<Workflow> => {
  const key = ['workflows-infinite', JSON.stringify(props)];
  const res = useInfiniteQuery<WorkflowCollection, Error>(
    key,
    async ({ pageParam }: { pageParam?: number }) =>
      fetchWorkflows({ ...props, page: pageParam }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta &&
        nextPageToFetch(lastGroup.meta, props.pageSize ?? 20),
    }
  );
  const pages = res.data?.pages ?? [];
  const workflows: Workflow[] =
    pages.map((page) => page.data ?? []).flat(1) || [];
  return {
    isLoading: res.isFetching,
    errorMessage: res.error?.message,
    isFetchingNextPage: res.isFetchingNextPage,
    fetchNextPage: res.fetchNextPage,
    hasNextPage: res.hasNextPage,
    data: workflows,
    meta: pages[0]?.meta,
  };
};

export const useWorkflowSummaryQuery = (
  props: Omit<WorkflowSummaryFetchProps, 'programId'>
): QueryResponse<WorkflowSummary> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<WorkflowSummary, Error>(
    ['workflow', props.workflowId],
    () => fetchWorkflowSummary({ ...props, programId }),
    {}
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
