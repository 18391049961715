import { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { Topic } from 'models/topic';
import { useUser } from 'contexts/user';
import { Resource, Usage } from 'utility/usage-records';
import { useTopicsByIdsQuery } from 'hooks/topics';

type ReturnType = {
  data: Topic[];
  isLoading: boolean;
  updateCache: (resourceId: string) => void;
  dataByRowId: { [key: string]: Topic };
  dataRowIds: string[];
};

export function updateRecent(
  resourceId: string,
  programId: number,
  userId: number,
  kind: Resource
): void {
  const usage = new Usage(programId, userId);
  usage.recordUse(Usage.Kind[kind], resourceId);
}

export function getRecent(
  programId: number,
  userId: number,
  kind: Resource,
  limit: number
): string[] {
  const usage = new Usage(programId, userId);
  return usage.recentlyUsed(kind, limit);
}

export const useRecentTopics = (limit = 5): ReturnType => {
  const { id: programId } = useProgram();
  const { id: userId } = useUser();

  const recentTopics = getRecent(programId, userId, Resource.topic, limit);
  const topics = useTopicsByIdsQuery(programId, recentTopics);

  const topicsLoading = useMemo(
    () => topics.some(({ isLoading }) => isLoading),
    [topics]
  );

  const dataByRowId = useMemo(() => {
    const map: { [key: string]: Topic } = {};
    if (topicsLoading) return map;
    topics.forEach(({ data }) => {
      if (!data) return;
      map[data?.id] = data;
    });
    return map;
  }, [topics, topicsLoading]);

  const dataRowIds = useMemo(() => {
    if (topicsLoading) return [];
    return topics
      .filter(({ data }) => !!data)
      .map(({ data }) => (data as Topic).id.toString());
  }, [topics, topicsLoading]);

  const updateCache = useCallback(
    (resourceId: string) =>
      updateRecent(resourceId, programId, userId, Resource.topic),
    [programId, userId]
  );

  return {
    data: topicsLoading
      ? []
      : topics
          .filter(({ data }) => data !== undefined)
          .map(({ data }) => data as Topic),
    isLoading: topicsLoading,
    updateCache,
    dataByRowId,
    dataRowIds,
  };
};
