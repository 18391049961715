import * as React from 'react';
import { FieldInput, Textarea } from 'DesignSystem/Form';
import { FieldLegend } from '../../shared/FieldLegend';

export const Question: React.FC<{
  value: string;
  onChange(question: string): void;
}> = ({ value, onChange }) => (
  <FieldInput
    legend={<FieldLegend>Question</FieldLegend>}
    htmlFor="block-poll-question"
  >
    <Textarea
      id="block-poll-question"
      placeholder="Type question here"
      value={value}
      onChange={onChange}
    />
  </FieldInput>
);
