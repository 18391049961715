import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionTextAlignCenter: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    onClick={() => {
      editor.chain().focus().setTextAlign('center').run();
    }}
    disabled={!editor.can().chain().focus().setTextAlign('center').run()}
    isActive={editor.isActive({ textAlign: 'center' })}
    icon="format_align_center"
    aria-label="Center align"
  />
);
