import { useDesignStatusQuery } from 'hooks/design';
import { useTopicAboutPageQuery } from 'hooks/topics';
import { AboutPage } from 'models/about-page';
import { Topic } from 'models/topic';
import React, { createContext, FC, useEffect, useMemo } from 'react';

export type AboutDesignContextType = {
  aboutPage?: AboutPage & { isEmpty: boolean };
  designProcessingState?: string;
};

export const AboutDesignContext = createContext<AboutDesignContextType>({});

type AboutDesignContextProviderProps = {
  topic: Topic;
  programId: number;
};

export const AboutDesignContextProvider: FC<AboutDesignContextProviderProps> = ({
  topic,
  programId,
  children,
}) => {
  const [shouldContinuePolling, setShouldContinuePolling] = React.useState(
    true
  );
  const { data: aboutPage, isEmpty } = useTopicAboutPageQuery(
    programId,
    topic.id
  );

  const { data: designProcessingState } = useDesignStatusQuery(
    programId,
    aboutPage?.designId,
    shouldContinuePolling
  );

  useEffect(() => {
    if (designProcessingState === 'completed') {
      setShouldContinuePolling(false);
    }
  }, [designProcessingState]);

  const ctx = useMemo(() => {
    return {
      aboutPage: aboutPage ? { ...aboutPage, isEmpty } : undefined,
      designProcessingState,
    };
  }, [aboutPage, isEmpty, designProcessingState]);

  return (
    <AboutDesignContext.Provider value={ctx}>
      {children}
    </AboutDesignContext.Provider>
  );
};
