import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Alert, AlertType, Box, FormModal } from 'DesignSystem/Components';
import { usePersonalizedFieldsFileQuery } from 'hooks/personalized-fields';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import { PersonalizedFieldsUploadErrors } from 'services/api-personalized-fields';
import { MAIcon } from 'shared/MAIcon';
import styles from '../styles.module.css';

export type ErrorModalResult = { id: number | null };
export type ErrorModalConfig = {
  type: 'error';
  fileId: number | null;
  name?: string;
  errors?: PersonalizedFieldsUploadErrors;
};

export const ErrorLine: React.FC<{
  messages?: string[];
  message?: string;
}> = ({ messages, message }) => {
  return messages?.length ? <Box>{message || messages[0]}</Box> : <></>;
};

export const FileErrorsModal: React.FC<{
  fileId: number | null;
  name?: string;
  errors?: PersonalizedFieldsUploadErrors;
  onCancel: () => void;
  onSubmit: (result: { result: ErrorModalResult }) => void;
}> = ({ fileId, name, errors, onCancel, onSubmit }) => {
  const { data, isLoading } = usePersonalizedFieldsFileQuery({
    fileId: fileId ?? 'none',
  });

  const [file, setFile] = React.useState<PersonalizedFieldsFile>();
  const [fileName, setFileName] = React.useState<string | undefined>(name);
  const errorType =
    fileId === null || fileId === undefined ? 'upload' : 'process';

  const shouldLoad = React.useRef(errorType === 'process');
  React.useEffect(() => {
    if (!shouldLoad.current && !isLoading && data) {
      setFile(data);
      shouldLoad.current = false;
    }
  }, [shouldLoad, isLoading, data]);

  React.useEffect(() => {
    if (file) setFileName(file.name);
  }, [file]);

  // eslint-disable-next-line no-console
  console.log(
    `FileErrorsModal({\n  fileId: ${fileId},\n  errors: ${JSON.stringify(
      errors,
      null,
      2
    )},\n  onCancel: ${onCancel.toString()},\n  onSubmit: ${{}.toString.call(
      onSubmit
    )}\n})`
  );

  const prop = React.useCallback(
    (key: string) => {
      const k = key as keyof PersonalizedFieldsUploadErrors;
      return errors && k in errors ? errors[k] : undefined;
    },
    [errors]
  );

  return (
    <FormModal
      actionText="File Errors"
      entityText=""
      submitLabel="Try Again"
      contentPadding="0"
      secondaryButton={<></>}
      onCancel={onCancel}
      onSubmit={() => onSubmit({ result: { id: fileId } })}
    >
      <Box className={styles.ErrorsForm}>
        <Text.Body bold>{fileName}</Text.Body>
        <Alert
          type={AlertType.error}
          bgColor="red"
          compact
          enableIcon
          icon={<MAIcon name="warning" />}
          message={
            <>
              <Text.Body bold>Failed</Text.Body>
              <ErrorLine messages={prop('filename')} />
              <ErrorLine messages={prop('fileContents')} />
              <ErrorLine messages={prop('columnCount')} />
              <ErrorLine messages={prop('columnHeaderSize')} />
              <ErrorLine messages={prop('duplicateColumnHeader')} />
              <ErrorLine messages={prop('emptyHeader')} />
            </>
          }
        />
      </Box>
    </FormModal>
  );
};
