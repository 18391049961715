import React from 'react';
import { HoverTooltip } from 'shared/HoverTooltip';
import { ProgressBar } from 'shared/ProgressBar/';
import {
  CompetitionInsight,
  ScheduleInsight,
  HigherPriority,
} from 'models/publisher/orchestration-insights';

import { PRIORITIES_BY_NAME } from 'models/priority';
import styles from './orchestration-insights.module.css';

function clearValue(v: string) {
  return v.replace('Day ', '');
}

export const Schedule: React.FC<{
  higherPriority: HigherPriority;
  competitions: Array<CompetitionInsight>;
  scheduleInsights: Array<ScheduleInsight>;
}> = ({ higherPriority, competitions, scheduleInsights }) => {
  const items: { [key: string]: ScheduleInsight } = {};
  scheduleInsights.forEach((s) => {
    items[`Day ${s.day}`] = s;
  });
  const keys = Object.keys(items);
  const { length } = keys;

  const groups: { [key: string]: number } = {};

  if (keys.length) {
    const day1 = Object.keys(items)[0];
    groups[day1] = items[day1].value;
    const half = Math.ceil((length - 1) / 2);
    keys.shift();
    const key2 = keys.splice(0, half);
    const key3 = keys.splice(-half);

    const block2 = key2.reduce((x, y) => {
      return x + items[y].value;
    }, 0);
    const block3 = key3.reduce((x, y) => {
      return x + items[y].value;
    }, 0);
    if (key2.length === 1) {
      groups[`Day ${clearValue(key2[0])}`] = block2;
    } else if (key2.length > 1) {
      groups[
        `Day ${clearValue(key2[0])}-${clearValue(key2.splice(-1)[0])}`
      ] = block2;
    }

    if (key3.length === 1) {
      groups[`Day ${clearValue(key3[0])}`] = block3;
    } else if (key3.length > 1) {
      groups[
        `Day ${clearValue(key3[0])}-${clearValue(key3.splice(-1)[0])}`
      ] = block3;
    }
  } else {
    // default empty value
    groups['Day 1'] = 0;
  }

  const maxBarHeight =
    competitions && competitions.length > 0
      ? competitions.reduce((a, b) => {
          return a.count > b.count ? a : b;
        }).count
      : 0;

  const percent = higherPriority.percent.toFixed(0);
  const count = higherPriority.count.toFixed(0);

  const dropdown = React.useCallback(() => {
    return (
      <div className={styles.tooltip}>
        <div>
          <div className={styles.tooltipTitle}>
            Other posts during the campaign duration
          </div>
          <div className={styles.tooltipDescription}>
            {percent}% of the audience has {count} higher priority comms
          </div>
        </div>
        <div className={styles.barsContainer}>
          {competitions.map((c) => (
            <div className={styles.bar} key={`completion-${c.name}`}>
              <ProgressBar
                size={11}
                orientation="vertical"
                max={maxBarHeight}
                value={c.count}
                color="var(--color-gray00)"
              />
              <div className={styles.barCount}>{c.count}</div>
              <div className={styles.barName}>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {PRIORITIES_BY_NAME[c.name].label}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [percent, count, competitions, maxBarHeight]);

  return (
    <>
      <div className={styles.header}>
        <h3 className="section-header">Schedule</h3>
        <HoverTooltip align="left" content={dropdown} />
      </div>
      <div className={styles.insights}>
        {Object.keys(groups).map((key) => (
          <div key={key} className={styles.row}>
            <div className={styles.day}>{key}</div>
            <ProgressBar
              className={styles.progressBar}
              size={15}
              orientation="horizontal"
              max={100}
              value={groups[key]}
              color="var(--color-gray90)"
            />
            <div className={styles.value}>{+groups[key].toFixed(2)}%</div>
          </div>
        ))}
      </div>
    </>
  );
};
