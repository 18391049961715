import React, { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Topic } from 'models/topic';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { FocusDropdown } from 'shared/FocusDropdown';
import cx from 'classnames';
import styles from './topic-select.module.css';

export const TopicSelect: React.FC<{
  value: Array<Topic>;
  onChange: (value: Array<Topic>) => void;
  upward?: boolean;
  maxHeight?: number;
  allOption?: {
    selected: boolean;
    onChange: (selected: boolean) => void;
  };
  placeholder?: string;
}> = ({
  value,
  onChange,
  upward = false,
  maxHeight = 400,
  allOption,
  placeholder = 'Search topics',
}) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');

  const {
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useTopicsInfiniteQuery({ programId, search });

  const topicsByRowId = useMemo(() => {
    const map: { [key: string]: Topic } = {};
    data.forEach((topic) => {
      map[topic.id] = topic;
    });
    // retain the currently selected topics
    value.forEach((topic) => {
      if (!map[topic.id]) map[topic.id] = topic;
    });
    return map;
  }, [data, value]);

  const rowIds = allOption
    ? ['all', ...Object.keys(topicsByRowId)]
    : Object.keys(topicsByRowId);

  const selectedIds = React.useMemo(() => {
    if (allOption?.selected) {
      return ['all'];
    }
    return value.map(({ id }) => `${id}`);
  }, [allOption?.selected, value]);

  const renderRow = (rowId: string) => {
    if (rowId === 'all') {
      return (
        <div className={styles.title}>
          <strong>All Topics</strong>
        </div>
      );
    }
    const item = topicsByRowId[rowId];
    return item ? (
      <div className={styles.title}>
        <strong>
          {item.name} ({item.followerCount})
        </strong>
        <div className={styles.description}>{item.description}</div>
      </div>
    ) : null;
  };

  const onSelectedIdsChange = useCallback(
    (ids: string[]) => {
      if (allOption && ids.includes('all') && !allOption.selected) {
        allOption.onChange(true);
        return;
      }
      if (allOption && !ids.includes('all') && allOption.selected) {
        allOption.onChange(false);
        return;
      }
      onChange(
        ids
          .filter((id) => id !== 'all')
          .map((rowId) => ({ ...topicsByRowId[rowId], hidden: false }))
      );
    },
    [allOption, onChange, topicsByRowId]
  );

  const handleRemove = React.useCallback(
    (index: number) => {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(index, 1);
      onSelectedIdsChange(newSelectedIds);
    },
    [onSelectedIdsChange, selectedIds]
  );

  const dropdown = (
    <div data-test="topics-select">
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={maxHeight}
        itemHeight={60}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        itemClassName={styles.row}
        allOptionId="all"
      />
    </div>
  );

  const selectedValues = React.useCallback(() => {
    return selectedIds.map((id) => {
      if (id === 'all') return 'All Topics';
      const topic = topicsByRowId[id];
      return `${topic.name} (${topic.followerCount})`;
    });
  }, [topicsByRowId, selectedIds]);

  const clearSearchOnClose = React.useCallback(() => setSearch(''), []);

  return (
    <FocusDropdown
      dropdownRenderProp={dropdown}
      dropdownClassName={cx('dropdown-align-left', styles.dropdown)}
      onClose={clearSearchOnClose}
      keyPressActivated
      upward={upward}
    >
      {(onFocus, ref) => (
        <MultiValueTextInput
          textValue={search}
          onTextValueChange={setSearch}
          selectedValues={selectedValues()}
          onRemoveSelectedValueAt={handleRemove}
          placeholder={placeholder}
          onFocus={onFocus}
          inputRef={ref}
        />
      )}
    </FocusDropdown>
  );
};
