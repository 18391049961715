import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Toggle } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body } from 'DesignSystem/Typography';

type CaptionDefaultToggleProps = {
  enabled: boolean;
  onToggle: () => void;
  disabled: boolean;
};
export const CaptionDefaultToggle: React.FC<CaptionDefaultToggleProps> = ({
  enabled,
  onToggle,
  disabled,
}) => {
  return (
    <Box margin={[0, 0, 8, 0]}>
      <Flex start>
        <Body>Display captions by default</Body>
        <Box margin={[0, 0, 0, 10]}>
          <Toggle
            on={enabled}
            onChange={onToggle}
            disabled={disabled}
            theme={disabled ? 'inactive' : 'default'}
            size="lg"
            shape="rounded"
          />
        </Box>
      </Flex>
    </Box>
  );
};
