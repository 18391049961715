import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { SearchBar } from 'shared/SearchBar';
import { useLibraryFonts } from 'hooks/useLibrary';
import { ActionData } from 'DesignSystem/Layout/Actions';
import { Icon } from 'shared/Icon';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { appendStylesheets } from 'utility/appendStylesheets';
import { useDebounce } from 'hooks/useDebounce';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { FontsList } from './FontsList';
import styles from './font-banner.module.css';

export const FontsListPage: React.FC<{
  tabs?: { to: string; label: string }[];
}> = ({ tabs }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const { permissions } = usePermissions();
  const { id: programId } = useProgram();
  const statusOptions = React.useMemo(() => {
    return [
      { value: 'active', label: 'Active' },
      { value: 'archived', label: 'Archived' },
    ];
  }, []);
  const [filterStatus, setFilterStatus] = React.useState<Array<string>>([
    'active',
  ]);
  const fontsPageForProgram = useFeatureFlagsQuery(
    programId,
    'Studio.Library.FontUploads'
  ).data?.value;

  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const statusFilteringProps = React.useMemo(() => {
    if (filterStatus.length === 1) {
      if (filterStatus[0] === 'active') {
        return {
          includeDisabled: false,
        };
      }
      if (filterStatus[0] === 'archived') {
        return { onlyDisabled: true };
      }
    }

    return {
      includeDisabled: true,
    };
  }, [filterStatus]);

  const handleFilter = React.useCallback((values: string[]) => {
    if (values.length > 0) {
      setFilterStatus(values);
    } else {
      setFilterStatus(['active', 'archived']);
    }
  }, []);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
    invalidateQuery,
  } = useLibraryFonts({
    filter: {
      type: 'search',
      search: debouncedSearchTerm?.length >= 2 ? debouncedSearchTerm : '',
      status: ['published', 'archived'],
      scope: fontsPageForProgram ? undefined : 'global',
    },
    ...statusFilteringProps,
  });

  React.useEffect(() => {
    appendStylesheets(data);
  }, [data]);

  const actions: ActionData[] = [];
  if (permissions.libraryFontsAccess && fontsPageForProgram) {
    actions.push({
      label: 'Add a new font',
      to: `/${programId}/edit/font/new`,
      icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
    });
  }

  const selectedOptions = React.useMemo(() => {
    return statusOptions.filter((f) => filterStatus.includes(f.value));
  }, [filterStatus, statusOptions]);

  return (
    <ListPage
      actions={actions}
      title="Library"
      breadcrumbs={[{ label: 'Library' }]}
      tabs={tabs}
      filterbar={
        <Flex start className={styles.filters}>
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search fonts"
          />
          <div className={styles.filtersWrapper}>
            <FilterDropdown
              onChange={handleFilter}
              label="Status"
              options={statusOptions}
              selectedOptions={selectedOptions}
            />
          </div>
        </Flex>
      }
    >
      {errorMessage && <>{errorMessage}</>}
      {data && !errorMessage && (
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <FontsList
              customFontsEnabled={fontsPageForProgram === true}
              invalidateQuery={invalidateQuery}
              parentRef={parentRef}
              fonts={data}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              height={height}
            />
          )}
        </InfiniteContainer>
      )}
    </ListPage>
  );
};
