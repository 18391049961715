import { CustomFont, Styling } from 'models/donkey';
import { FontStylesheet } from 'models/library';
import { BASE_STYLING } from 'models/publisher/style';
import { unique } from 'utility/unique';
import { fetchFeatureFlags } from 'services/api-feature-flags';

const ARIAL_VALUE = 'Arial, Helvetica, sans-serif';

/*
  This function's logic will eventually be moved server side
 */
export async function resolveStyling(
  styles: Styling,
  stylesheets: FontStylesheet[],
  programId: number
): Promise<Styling> {
  const emailMaxWidthFlag = await fetchFeatureFlags<string>(
    programId,
    'Studio.Publish.DefaultEmailMaxWidth'
  );
  const emailMaxWidth = Number(emailMaxWidthFlag.value) || undefined;

  const fonts = { ...(styles.fonts ?? BASE_STYLING.fonts) };
  const fontStylesheets: CustomFont[] = [];

  const availableFonts = Object.fromEntries<FontStylesheet>(
    stylesheets.map((stylesheet) => [
      stylesheet.asset.value ?? stylesheet.title,
      stylesheet,
    ])
  );

  // If Arial is in the program's fonts, the default is Arial
  // If it is not present and there is at least one, then the default is the first font
  // If there are no fonts (which we don't expect to happen, but could) then the default is Arial again.
  const defaultFontValue = findFont(stylesheets, ARIAL_VALUE)
    ? ARIAL_VALUE
    : stylesheets[0]?.asset?.value ?? ARIAL_VALUE;

  Object.entries(fonts).forEach(([key, value]) => {
    // if the font is in the fonts from the library, use it
    if (availableFonts[value]) return;

    // if it's not in the library, use the default
    fonts[key as keyof Styling['fonts']] = defaultFontValue;
  });

  // Make sure CustomFonts are included for each referenced font
  unique(Object.values(fonts)).forEach((fontTitle) => {
    const font = availableFonts[fontTitle];
    if (font?.asset?.css?.url)
      fontStylesheets.push({
        url: font.asset?.css?.url,
        name: font.title,
        fallbackFont: font.asset?.fallbackFont || 'Arial, Helvetica',
        generic: 'sans-serif',
      });
  });

  return {
    ...styles,
    fontStylesheets,
    fonts,
    emailWidth: emailMaxWidth,
  };
}

function findFont(
  stylesheets: FontStylesheet[],
  valueOrTitle?: string
): FontStylesheet | undefined {
  return stylesheets.find((stylesheet: FontStylesheet) => {
    return (
      stylesheet.asset.value === valueOrTitle ||
      stylesheet.title === valueOrTitle
    );
  });
}
