import React from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import { AudienceSelect } from 'components/audience/AudienceSelect';
import { useProgram } from 'contexts/program';
import { Audience } from 'models/audience';
import { User } from 'models/user';
import { useAudienceByIdQueries } from 'hooks/audience';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { IdentityScope } from 'models/identity';
import { useHasNoRestrictions } from './hooks/roles';

type PropsType = {
  selectedIds: string[];
  onChange: (value: IdentityScope[]) => void;
  role: User['role'];
};

export const AudiencesSelector: React.FC<PropsType> = ({
  selectedIds,
  onChange,
  role,
}) => {
  const { id: programId } = useProgram();
  const hasNoRestrictions = useHasNoRestrictions(`${role}`);

  const audienceQueries = useAudienceByIdQueries(programId, selectedIds);

  const [selectedAudiences, setSelectedAudiences] = React.useState<Audience[]>(
    []
  );

  React.useEffect(() => {
    if (audienceQueries.every((q) => !!q.data)) {
      // eslint-disable-next-line
      // @ts-ignore
      setSelectedAudiences(audienceQueries.map((q) => q.data));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(audienceQueries)]);

  function onSelectedAudiencesChange(audiences: Audience[]) {
    setSelectedAudiences(audiences);
    const scopes: IdentityScope[] = audiences.map((audience) => {
      return { type: 'audience', name: `audience:${audience.id}` };
    });
    onChange(scopes);
  }

  return (
    <Fieldset>
      <div className={fieldsetStyles.fieldGroupContainer}>
        <Field label="Audiences">
          {hasNoRestrictions ? (
            <div>Selected role has access to all audiences</div>
          ) : (
            <AudienceSelect
              selectedAudiences={selectedAudiences as Audience[]}
              onSelectedAudiencesChange={onSelectedAudiencesChange}
              placeholder="Search audiences"
            />
          )}
        </Field>
      </div>
    </Fieldset>
  );
};
