import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionTextAlignLeft: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    onClick={() => {
      editor.chain().focus().setTextAlign('left').run();
    }}
    disabled={!editor.can().chain().focus().setTextAlign('left').run()}
    isActive={editor.isActive({ textAlign: 'left' })}
    icon="format_align_left"
    aria-label="Left align"
  />
);
