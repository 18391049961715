import React from 'react';
import { CollectionsContext } from 'App/Program/Main/Insight/contexts/CollectionsContext';
import { RouteComponentProps, useLocation } from '@reach/router';
import { PlaceHolderReport } from 'models/insight/Report';
import { useReportFinder } from 'hooks/insights/report-finder';
import { Collection } from 'models/insight/Collection';
import { ReportsList } from 'App/Program/Main/Insight/components/ReportsList/ReportsList';
import styles from './Collection.module.css';
import { NonFeaturedCollection } from './NonFeaturedCollection';

/*
 * The collections page is rendered within the CollectionsProvider
 * and the CollectionsSidebarTheme
 * the collections are accessed via the CollectionsContext
 * */
type PropsType = {
  collectionId: string;
};
export const CollectionPage: React.FC<RouteComponentProps<PropsType>> = ({
  collectionId,
}) => {
  const { customCollectionsQuery, getCollection } = React.useContext(
    CollectionsContext
  );

  const location = useLocation();

  const findReports = useReportFinder(customCollectionsQuery.data || []);
  const collections = React.useMemo(() => customCollectionsQuery.data || [], [
    customCollectionsQuery,
  ]);

  const search = new URLSearchParams(location.search);
  const queryTerm = search.get('query') ?? '';
  const currentCollection = React.useMemo(
    () =>
      collectionId === 'search'
        ? { reports: findReports(queryTerm) }
        : getCollection(collectionId),
    [queryTerm, collectionId, findReports, getCollection]
  );

  const reports: PlaceHolderReport[] = React.useMemo(
    () => currentCollection?.reports || [],
    [currentCollection]
  );

  const reportIds = React.useMemo(
    () =>
      reports.reduce((result: Record<number, boolean>, r) => {
        return { ...result, [r.id]: true };
      }, {}),
    [reports]
  );

  const searchCollections = React.useMemo(() => {
    const searchResults = collections.map((coll: Collection) => {
      const result = { ...coll, reports: [...coll.reports] };
      result.reports = result.reports.filter((r: PlaceHolderReport) =>
        Object.prototype.hasOwnProperty.call(reportIds, r.id)
      );
      return result;
    });
    return searchResults;
  }, [collections, reportIds]);

  const searchReports = React.useMemo(() => {
    const filterReports: { [id: string]: PlaceHolderReport } = {};
    searchCollections.forEach((coll: Collection) => {
      coll.reports.forEach((report) => {
        filterReports[report.id] = report;
      });
    });
    return Object.values(filterReports);
  }, [searchCollections]);

  return (
    <>
      {queryTerm.length > 0 ? (
        <>
          <h1 className={styles.searchTitle}>
            Results for &quot;{queryTerm}&quot;
          </h1>
          {searchReports.length > 0 ? (
            <ReportsList reports={searchReports} viewType="card" />
          ) : (
            <div className={styles.noItemsFound}>No results found.</div>
          )}
        </>
      ) : (
        searchCollections.map(
          (collection) =>
            !!collection.reports.length && (
              <NonFeaturedCollection
                key={collection.id}
                collection={collection}
              />
            )
        )
      )}
    </>
  );
};
