import type { Editor } from '@tiptap/react';
import type { Node } from 'prosemirror-model';

const findBottomNode = (
  editor: Editor,
  pos: number
): {
  node: Node;
  from: number;
  to: number;
} => {
  const {
    state: { doc },
  } = editor.view;

  const search = doc.resolve(pos);

  let depth = 0;
  let result = search.node(depth);
  let found = false;

  // Drill down to the bottom leaf
  while (!found) {
    if (result) {
      depth += 1;
      result = search.node(depth);
    } else {
      // Back up we overshot
      depth -= 1;
      result = search.node(depth);
      found = true;
    }
  }

  return {
    node: result,
    from: result ? search.start(depth) : pos,
    to: result ? search.end(depth) : pos,
  };
};

// Prime for refactoring to search for any mark
export const findLinkAtPosition = (
  editor: Editor,
  pos: number
): { from: number; to: number } => {
  const { node: result, from: foundNodeStart } = findBottomNode(editor, pos);

  let offset = -1;
  let length = 0;
  let hit = false;
  let finish = false;

  result?.forEach((n, nodeOffset) => {
    if (
      !finish &&
      n.isLeaf &&
      n.marks.some((m) => {
        return m.type.name === 'link';
      })
    ) {
      if (offset === -1 || offset > nodeOffset) {
        offset = nodeOffset;
      }
      length += n.textContent.length;

      const wordStart = foundNodeStart + offset;
      const wordEnd = foundNodeStart + offset + length;

      // If the current "word" contains our search position, we found the link
      if (wordStart <= pos && wordEnd >= pos) {
        hit = true;
      }
    } else if (hit) {
      finish = true; // Fin
    } else {
      offset = -1;
      length = 0;
    }
  });

  if (offset === -1) {
    offset = 0;
  }

  return {
    from: foundNodeStart + offset,
    to: foundNodeStart + offset + length,
  };
};

export const findWholeWordAtPosition = (
  editor: Editor,
  pos: number
): { from: number; to: number } => {
  const {
    state: { doc },
  } = editor.view;

  const { from: foundNodeStart, to: foundNodeEnd } = findBottomNode(
    editor,
    pos
  );

  let textStart = pos;
  let textEnd = textStart;

  try {
    while (
      textStart > 0 &&
      textStart >= foundNodeStart &&
      !/[\s\n]+/.test(doc.textBetween(textStart - 1, textStart))
    ) {
      textStart -= 1;
    }
  } catch (e) {
    // We went too far back
  }

  try {
    while (
      textEnd >= textStart &&
      textEnd <= foundNodeEnd &&
      !/[\s\n]+/.test(doc.textBetween(textStart, textEnd + 1))
    ) {
      textEnd += 1;
    }
  } catch (e) {
    // We went too far forward
  }

  return {
    from: textStart,
    to: textEnd,
  };
};
