import React, { useRef, useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { DismissType } from 'shared/ClickDropdown';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Settings } from 'models/publisher/settings';
import {
  GreenTintDarkPillClass,
  OrangeTintDarkPillClass,
} from 'DesignSystem/Components/Pill';
import { ReactComponent as CircleCheckIcon } from 'shared/icons/CheckCircle.svg';
import { ReactComponent as CancelIcon } from 'shared/icons/Cancel.svg';
import { Pills } from 'DesignSystem/Components/Pills';
import { EngagementEditHandle, EngagementEdit } from './EngagementEdit';
import { SharableDataPreview } from './SharableDataPreview';
import { useEngagementToggles } from './useEngagementToggles';
import { AcknowledgeLabel } from './AcknowledgeLabel';
import { ContentTitle, SettingsCard } from '../../SettingsCard';
import { SelectAuthor, SelectAuthorHandle } from '../Author/SelectAuthor';
import { SaveModalButtons } from '../../Shared/SaveModalButtons';
import { AuthorName } from '../Author/AuthorName';

interface EngagementTogglesProps {
  disabled?: boolean;
}

interface SummaryPill {
  id: number;
  enabled: boolean;
  text: React.ReactNode;
}

export const EngagementTogglesAndAuthor: React.FC<EngagementTogglesProps> = ({
  disabled,
}) => {
  const {
    engagementFlags,
    showSharableData,
    onEngagementTogglesChange,
  } = useEngagementToggles();
  const { settings, update } = useSettings();

  const [engagementDisabled, setEngagementDisabled] = useState(false);
  const [authorDisabled, setAuthorDisabled] = useState(false);

  const {
    isShareable,
    isCommentable,
    isTranslatable,
    acknowledge,
  } = engagementFlags;

  // create list of pills as a summary
  const summaryPills: SummaryPill[] = [
    isShareable,
    isCommentable,
    isTranslatable,
    acknowledge,
  ]
    .filter((flag) => flag.available)
    .map((item, index) => ({
      id: index,
      enabled: item.value,
      text: (
        <>
          {item.title}:&nbsp;<b>{item.value ? 'ON' : 'OFF'}</b>
        </>
      ),
    }))
    .sort((a, b) => Number(b.enabled) - Number(a.enabled));

  const renderSummaryPill = ({ text }: SummaryPill) => <>{text}</>;
  const renderSummaryPillClass = ({ enabled }: SummaryPill) =>
    enabled ? GreenTintDarkPillClass : OrangeTintDarkPillClass;
  const renderSummaryPillIcon = ({ enabled }: SummaryPill) =>
    enabled ? <CircleCheckIcon /> : <CancelIcon />;

  // keeping a reference to them, and having a function to query their current selection
  // allows us to have their edit form logic separate in code, but together in the ui.
  const engagementEdit = useRef<EngagementEditHandle>(null);
  const selectAuthor = useRef<SelectAuthorHandle>(null);

  const editEngagement = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => {
    return (
      <>
        <SelectAuthor
          ref={selectAuthor}
          author={settings.contentAuthor}
          disabled={disabled}
          onSaveDisabledChange={setAuthorDisabled}
          setDismissable={setDismissable}
        />
        <EngagementEdit
          ref={engagementEdit}
          settings={settings}
          onSaveDisabledChange={setEngagementDisabled}
          engagementFlags={engagementFlags}
          setDismissable={setDismissable}
        />

        <SaveModalButtons
          onCancel={dismiss}
          onSave={() => {
            // get the settings from each section
            const engagementSettings = engagementEdit.current?.getSettings();
            const authorSettings = !authorDisabled
              ? selectAuthor.current?.getSettings()
              : undefined;
            // format engagement settings
            const settingChanges =
              engagementSettings && !engagementDisabled
                ? onEngagementTogglesChange(
                    engagementSettings.flags,
                    engagementSettings.slug,
                    engagementSettings.shareMessage,
                    engagementSettings.acknowledgementLabel
                  )
                : undefined;

            // update both settings
            update({ ...(settingChanges as Settings), ...authorSettings });

            dismiss();
          }}
          disabled={engagementDisabled || authorDisabled}
        />
      </>
    );
  };

  return (
    <SettingsCard
      dataTest="author-settings"
      isEditing={false}
      editRenderProp={editEngagement}
      disabled={disabled}
    >
      <Box margin={[0, 0, 0, 0]}>
        <Box>
          <ContentTitle>Author</ContentTitle>
        </Box>
        <Box>
          <AuthorName author={settings.contentAuthor} />
        </Box>

        <Box margin={[32, 0, 0, 0]}>
          <ContentTitle>Engagement</ContentTitle>
        </Box>

        <Box margin={[24, 0, 0, 0]}>
          <Pills
            small
            values={summaryPills}
            render={renderSummaryPill}
            pillIcon={renderSummaryPillIcon}
            pillClassName={renderSummaryPillClass}
            padding={[0, 8]}
            dataTest="summary-pills"
          />
        </Box>

        {showSharableData && <SharableDataPreview data={settings} />}
        {acknowledge.value && settings.acknowledgementLabel && (
          <AcknowledgeLabel label={settings.acknowledgementLabel} />
        )}
      </Box>
    </SettingsCard>
  );
};
