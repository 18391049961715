import React from 'react';
import { SearchBar } from 'shared/SearchBar';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useUser } from 'contexts/user';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { RolesFilter } from 'components/roles/RoleSelect/Filter';
import { LitmusRole } from 'models/role';
import styles from 'components/content/ContentFilterBar/filters.module.css';

type PropsType = {
  // eslint-disable-next-line
  onChange: (filterName: string, value: any) => void;
  searchTerm?: string;
  statuses?: string[];
  roles?: LitmusRole[];
  roleNames?: string[];
};

export const FiltersBar: React.FC<PropsType> = ({
  onChange,
  searchTerm,
  statuses,
  roles,
  roleNames,
}) => {
  const { id: programId } = useProgram();

  const {
    data: permissionsFlag,
    isLoading: permissionsFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Permissions.Service');
  const { superAdmin } = useUser();
  const ROLES = React.useMemo(() => {
    let options = [
      { value: 'member', label: 'Member' },
      { value: 'analyst', label: 'Analyst' },
      { value: 'channel_contributor', label: 'Topic Manager' },
      { value: 'publisher', label: 'Publisher' },
      { value: 'program_admin', label: 'Community Manager' },
      { value: 'administrator', label: 'Administrator' },
    ];

    if (superAdmin) {
      options = [...options, { value: 'super_admin', label: 'Super Admin' }];
    }
    return options;
  }, [superAdmin]);

  const STATUSES = React.useMemo(() => {
    let options = [
      { value: 'created', label: 'Created' },
      { value: 'blocked', label: 'Blocked' },
      { value: 'registering', label: 'Registering' },
      { value: 'registered', label: 'Registered' },
      { value: 'invited', label: 'Invited' },
    ];

    if (superAdmin) {
      options = [...options, { value: 'hidden', label: 'Hidden' }];
    }

    return options;
  }, [superAdmin]);

  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={(value) => onChange('search', value)}
        placeholder="Search users"
        panelClassName={styles.searchBarWrapper}
      />
      <FilterDropdown
        label="Experience Status"
        onChange={(value) => onChange('statuses', value)}
        options={STATUSES}
        selectedOptions={STATUSES.filter((opt) =>
          statuses?.includes(opt.value)
        )}
      />
      {!permissionsFlagLoading && permissionsFlag?.value && (
        <RolesFilter
          onChange={(value) => onChange('roles', value)}
          value={roles || []}
        />
      )}
      {!permissionsFlagLoading && !permissionsFlag?.value && (
        <FilterDropdown
          label="Role"
          onChange={(value) => onChange('roles', value)}
          options={ROLES}
          selectedOptions={ROLES.filter((opt) =>
            roleNames?.includes(opt.value)
          )}
        />
      )}
    </div>
  );
};
