import React from 'react';
import { LinkData } from 'models/publisher/block';
import { FormModal } from 'DesignSystem/Components/Modal';
import { Editor } from './Editor';
import { Library } from './Library';
import { useLinksSourceMenu, OPENED, LIBRARY } from '../hooks/useSourceMenu';
import { SubmittableForm } from '../../shared/SubmittableForm';

export const Current: React.FC<{
  initial?: LinkData;
  onSave: (link: LinkData | LinkData[]) => void;
  onCancel: () => void;
  source: ReturnType<typeof useLinksSourceMenu>;
}> = ({ initial, onSave, onCancel, source }) => {
  const [link, setLink] = React.useState<LinkData | undefined>(initial);
  const [onLoading, setOnLoading] = React.useState(false);
  const [urlTouched, setUrlTouched] = React.useState(false);
  const [applyDisabled, setApplyDisabled] = React.useState(link?.uuid === '');
  const [isWaitingForDebounce, setIsWaitingForDebounce] = React.useState(false);

  const handleDone = React.useCallback(() => {
    if (link) {
      onSave({
        ...link,
        url: link.requestedUrl || link.url,
      });
    }
  }, [link, onSave]);

  const saveCollection = React.useCallback(
    (links: LinkData[]) => {
      onSave(links);
    },
    [onSave]
  );

  const onUrlChange = () => {
    setApplyDisabled(true);
    setUrlTouched(true);
  };

  React.useEffect(() => {
    if (urlTouched) {
      setApplyDisabled(!link?.url || onLoading || isWaitingForDebounce);
    }
  }, [isWaitingForDebounce, link?.url, onLoading, urlTouched]);

  return source.visibility === OPENED && source.source === LIBRARY ? (
    <Library
      onChange={(images: LinkData[]) => {
        source.close();
        if (!images.length) onCancel();
        if (images.length) saveCollection(images);
      }}
      onClose={() => {
        source.close();
        onCancel();
      }}
      maxSelections={50}
    />
  ) : (
    <FormModal
      onSubmit={handleDone}
      onCancel={onCancel}
      disabled={applyDisabled}
      actionText="add"
      entityText="link"
    >
      <SubmittableForm onSubmit={handleDone} disabled={applyDisabled}>
        <Editor
          source={source}
          initial={initial}
          onChangeLink={setLink}
          onApplyCollection={saveCollection}
          onCancel={onCancel}
          onLoading={setOnLoading}
          onUrlChange={onUrlChange}
          setIsWaitingForDebounce={setIsWaitingForDebounce}
        />
      </SubmittableForm>
    </FormModal>
  );
};
