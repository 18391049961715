import * as React from 'react';
import { FieldInput, Input } from 'DesignSystem/Form';
import { SocialData } from 'models/publisher/block';
import { FieldLegend } from '../../shared/FieldLegend';

export const TitleInput: React.FC<{
  value: SocialData['title']['value'];
  onChange: (value: string) => void;
}> = ({ value, onChange }) => (
  <FieldInput
    htmlFor="social-edit-title"
    legend={<FieldLegend>Title</FieldLegend>}
  >
    <Input
      data-test="social-edit-title"
      id="social-edit-title"
      value={value}
      onChange={onChange}
      autoComplete="off"
      block
    />
  </FieldInput>
);
