import cx from 'classnames';
import { DurationInput } from 'shared/DurationInput';
import { Block } from 'shared/SectionBlocks';
import React from 'react';
import { DateTime } from 'luxon';
import { Tooltip } from 'shared/Tooltip';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { HoverTooltip } from 'shared/HoverTooltip';
import { useDuration } from 'App/Program/Editors/Publisher/Deliver/DirectDelivery/DirectDeliverySection/EngagementeBoostSection/useDuration';
import {
  addDuration,
  Duration,
  DURATION_DAYS,
  isSameDuration,
} from 'models/duration';
import { Body, color } from 'DesignSystem/Typography';
import { DeliveryType } from 'models/delivery-type';
import { Audience } from 'models/audience';
import { pluralize } from 'utility/text';
import styles from '../settings-editor.module.css';

interface CampaignDurationProps {
  disable?: boolean;
}

export const shouldDisplaySuggestedDuration = (
  publishedAt: DateTime,
  audiences: Audience[],
  suggested: number | undefined,
  duration?: Duration,
  deliveryType?: DeliveryType
): boolean => {
  if (
    suggested === null ||
    duration === undefined ||
    deliveryType === undefined
  )
    return false;
  // end date as it's configured
  const endDate = addDuration(publishedAt, duration);
  // end date with suggested duration
  const suggestedEndDate = publishedAt.plus({ days: suggested });
  const isEndDateValid: boolean = endDate >= suggestedEndDate;

  return (
    duration !== undefined &&
    audiences.length !== 0 &&
    (deliveryType === 'optimize' || deliveryType === 'hybrid') &&
    !isEndDateValid
  );
};

export const CampaignDuration: React.FC<CampaignDurationProps> = (props) => {
  const { disable } = props;
  const {
    settings,
    options,
    fields: {
      duration: {
        set: setDuration,
        enabled: durationEnabled,
        editable: durationEditable,
      },
    },
  } = useSettings();

  const { deliveryType, audiences } = settings;

  const durationSuggestion = useDuration();

  const isCustomSelection =
    !!settings.duration &&
    !DURATION_DAYS.find((d) => d.label === settings.duration?.label);

  const description = React.useCallback(
    (duration: Duration) => {
      const publishedAt = options.canChangePublicationDate
        ? settings.publishedAt
        : DateTime.now();

      const suffix = duration.value > 1 ? 's' : '';

      const startDateString = publishedAt
        ? publishedAt.toFormat('MMM dd, yyyy')
        : 'today';

      const endDateString = addDuration(
        publishedAt || DateTime.now(),
        duration
      ).toFormat('MMMM dd, yyyy');

      return `${duration.value} ${duration.unit?.value}${suffix} from ${startDateString} is ${endDateString}`;
    },
    [options.canChangePublicationDate, settings.publishedAt]
  );

  const tooltip = React.useCallback(
    () => (
      <Tooltip
        description="
      How long the campaign is relevant. This is the timeframe in which the 
      orchestration engine will attempt to reach the audience."
      />
    ),
    []
  );

  const startDate = options.canChangePublicationDate
    ? settings.publishedAt || DateTime.now()
    : DateTime.now();

  const displaySuggestion = shouldDisplaySuggestedDuration(
    startDate,
    audiences,
    durationSuggestion,
    settings.duration,
    deliveryType
  );

  return (
    <Block
      className={cx(styles.scheduleBlock, {
        [styles.disabled]: !durationEnabled,
      })}
    >
      <div className={styles.fieldsetColumn}>
        <h4 className={styles.fieldsetColumnTitle}>
          <span>Duration</span>
          {durationEnabled && <HoverTooltip align="left" content={tooltip} />}
        </h4>
        <div className={styles['radio-group']}>
          {DURATION_DAYS.map((duration) => (
            <button
              key={duration.value}
              className={cx(styles['radio-button'], {
                [styles.selected]:
                  durationEnabled &&
                  isSameDuration(duration, settings.duration),
              })}
              type="button"
              disabled={!durationEnabled || !durationEditable || disable}
              value={duration.value}
              onClick={() => setDuration(duration)}
            >
              {duration.label}
            </button>
          ))}
          <DurationInput
            description={
              durationEnabled && settings.duration
                ? description(settings.duration)
                : ''
            }
            showLabel={isCustomSelection}
            value={settings.duration}
            onDurationChange={setDuration}
            disableDurationDropdown={
              !durationEnabled || !durationEditable || disable
            }
          />
        </div>
      </div>
      {durationEnabled && (
        <div className={styles.description}>
          {durationEnabled && settings.duration
            ? description(settings.duration)
            : ''}
        </div>
      )}

      {displaySuggestion && (
        <div className={styles.description}>
          <Body color={color.brandTintDark}>
            Recommended min. duration: {durationSuggestion}{' '}
            {pluralize(durationSuggestion || 14, 'day')}
          </Body>
        </div>
      )}
    </Block>
  );
};
