import React from 'react';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useUserByIdsQuery, useUsersInfiniteQuery } from 'hooks/user';
import { useProgram } from 'contexts/program';
import { OptionType } from 'hooks/common';
import { useDebounce } from 'hooks/useDebounce';
import { User } from 'models/user';

type PropsType = {
  values?: string[];
  onChange: (values: string[]) => void;
  onClose?: () => void;
  searchPlaceholder?: string;
  label?: string;
};

export const UsersFilter: React.FC<PropsType> = ({
  values,
  onChange,
  label,
  searchPlaceholder,
  onClose,
}) => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = React.useState<string>();

  const {
    data: selectedUsers,
    isLoading: selectedUsersLoading,
  } = useUserByIdsQuery(programId, values?.map((v) => Number(v)) || [-1]);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useUsersInfiniteQuery({ programId, search: useDebounce(searchTerm) });

  const usersByid = React.useMemo(() => {
    const map: { [key: number]: User } = {};
    data.forEach((u) => {
      map[u.id] = u;
    });
    selectedUsers?.forEach((u) => {
      if (!map[u.id]) map[u.id] = u;
    });
    return map;
  }, [data, selectedUsers]);

  const selectedOptions = React.useMemo(() => {
    const opts: OptionType[] = [];
    values?.forEach((id) => {
      if (id === 'system') {
        opts.push({
          value: 'system',
          label: 'System',
        });
      }
      const user = usersByid[Number(id)];
      if (!user) return;
      opts.push({
        value: String(user.id),
        label: `${user.firstName} ${user.lastName}`,
      });
    });

    return opts;
  }, [usersByid, values]);

  const options = React.useMemo<OptionType[]>(() => {
    const system = {
      value: 'system',
      label: 'System',
    };
    const notSelected = data
      .filter((u) => !values?.includes(String(u.id)))
      .map(
        (user): OptionType => {
          return {
            value: String(user.id),
            label: `${user.firstName} ${user.lastName}`,
          };
        }
      )
      .concat(system);
    return [...selectedOptions, ...notSelected].sort((a, b) => {
      const aLower = a.label.toLowerCase();
      const bLower = b.label.toLowerCase();
      if (aLower < bLower) return -1;
      if (aLower > bLower) return 1;
      return 0;
    });
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <FilterDropdown
      onChange={(value) => {
        onChange(value);
      }}
      onClose={onClose}
      options={options}
      selectedOptions={selectedOptions}
      label={label || 'User'}
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      isLoading={isLoading || selectedUsersLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      maxHeight={400}
      searchPlaceholder={searchPlaceholder || 'Search users'}
    />
  );
};
