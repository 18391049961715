import ParseError from 'srt-validator/dist/utils/parse-error';
import srtValidator from 'srt-validator';

const UPDATED_ERROR_MESSAGES = {
  validatorErrorStartTime: {
    old: 'start time should be less than end time',
    new: 'Start time of subtitle occurs after its end time',
  },
  validatorErrorEndTime: {
    old: 'start time should be less than previous end time',
    new: 'Start time of subtitle occurs after its previous end time',
  },
  validatorErrorSequenceNumberStart: {
    old: 'number of sequence need to start with',
    new: 'Subtitle sequence should begin with',
  },
  validatorErrorSequenceNumberIncrement: {
    old: 'number of sequence need to increment by',
    new: 'Subtitle sequence should increment by',
  },
  parserErrorMissingText: {
    old: 'Missing caption text',
    new: 'Caption text is missing',
  },
  parserErrorMissingSequenceNumber: {
    old: 'Missing sequence number',
    new: 'Sequence number is missing',
  },
  parserErrorInvalidSequenceNumber: {
    old: 'Expected Integer for sequence number:',
    new: 'Expected sequence number instead of',
  },
  parserErrorMissingTimeSpan: {
    old: 'Missing time span',
    new: 'Time span is missing',
  },
  parserErrorInvalidTimeSpan: {
    old: 'Invalid time span:',
    new: 'Time span is invalid:',
  },
  parserErrorInvalidTimeStamp: {
    old: 'Invalid time stamp:',
    new: 'Time stamp is invalid:',
  },
};

export const WEBVTT_EXTENSION = '.vtt';
export const WEBVTT_MIME_TYPE = 'text/vtt';

export const SUBRIP_EXTENSION = '.srt';
export const SUBRIP_MIME_TYPE = 'application/x-subrip';

export const isSrt = (file: File): boolean =>
  file.type === SUBRIP_MIME_TYPE ||
  file.name.toLowerCase().endsWith(SUBRIP_EXTENSION);

export const validateCaptionText = (text: string): ParseError[] => {
  // according to this comment https://firstup-io.atlassian.net/browse/FE-1143?focusedCommentId=530572
  // we should support both 00:00:00.000 and 00:00:00,000 formats
  const newCaptionText = text.replace(/(\d{2}:\d{2}:\d{2})\.(\d{3})/g, '$1,$2');
  const result = srtValidator(newCaptionText || '') as ParseError[];
  return updateErrorMessages(result);
};

function updateErrorMessages(errors: ParseError[]) {
  const result = [] as ParseError[];
  errors.forEach((msg) => {
    const updatedMessage = { ...msg };
    if (UPDATED_ERROR_MESSAGES[msg.errorCode]) {
      updatedMessage.message = msg.message.replace(
        UPDATED_ERROR_MESSAGES[msg.errorCode].old,
        UPDATED_ERROR_MESSAGES[msg.errorCode].new
      );
    }
    result.push(updatedMessage as ParseError);
  });
  return result;
}
