import React from 'react';
import styles from './label.module.css';

export const Label: React.FC<{
  name: string;
  containerClassName?: string;
}> = ({ name, containerClassName = styles.container, children }) => (
  <div className={containerClassName}>
    <div>{name}</div>
    <div>{children}</div>
  </div>
);
