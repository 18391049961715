import { Box } from 'DesignSystem/Components';
import React from 'react';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import cx from 'classnames';
import { Button } from 'DesignSystem/Form';
import { ChevronLeft, ChevronRight, TrashFlatLid } from 'shared/icons';
import { Icon } from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { JourneyMode } from 'models/journeys/journey';
import {
  useJourneyState,
  JOURNEY_ACTION_DISABLED_MESSAGE,
  useJourneyDispatch,
} from 'contexts/journeys/journey';
import { centerY } from '../utils/node';
import styles from './node.module.css';
import { DrawerState } from '../../JourneyDrawer/drawer';

const BaseNodeMoveArrow: React.FC<{
  position: Position.Left | Position.Right;
  onMove: () => void;
  show?: boolean;
  disabled?: boolean;
}> = ({ position, onMove, show = true, disabled = false }) => {
  return (
    <>
      {show && (
        <Box
          className={cx(styles.move, {
            [styles.moveLeft]: position === Position.Left,
            [styles.moveRight]: position === Position.Right,
          })}
        >
          <Button
            onClick={onMove}
            compact
            layoutOnly
            disabled={disabled}
            title={
              !disabled ? `Move ${position}` : JOURNEY_ACTION_DISABLED_MESSAGE
            }
            icon={
              position === Position.Left ? <ChevronLeft /> : <ChevronRight />
            }
          />
        </Box>
      )}
    </>
  );
};

export const BaseNode: React.FC<
  NodeProps & {
    children?: React.ReactElement;
    className?: string;
    activateOnClick?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    clean?: boolean;
    type?: string;
    hideMoveLeft?: boolean;
    hideMoveRight?: boolean;
    disableDelete?: boolean;
    onDelete?: (id: string) => void;
    onMove?: (id: string, position: Position.Left | Position.Right) => void;
    width?: number;
    height?: number;
  }
> = ({
  id,
  children,
  className,
  activateOnClick,
  onClick,
  onDelete,
  onMove,
  type,
  hideMoveLeft,
  hideMoveRight,
  disableDelete,
  sourcePosition,
  targetPosition,
  width = 300,
}) => {
  const {
    activeStep,
    canMoveStep,
    deleteStep,
    setDrawerState,
    setActiveStepId,
    topologyEditable,
    journeyMode,
    setShowErrorsList,
    errors,
  } = useJourneyState();
  const dispatch = useJourneyDispatch();

  const hideActions = journeyMode === JourneyMode.View;

  const flowInstance = useReactFlow();

  const isSelected = React.useMemo(() => activeStep?.id === id, [
    activeStep,
    id,
  ]);

  const isErrored = errors?.graph?.[id] !== undefined;

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      setShowErrorsList(false);
      if (activateOnClick && !isSelected) {
        setActiveStepId(id);
        centerY(flowInstance, id);
      }

      // Run node-specific handlers
      if (onClick) {
        onClick(event);
      }
    },
    [
      activateOnClick,
      isSelected,
      flowInstance,
      id,
      setActiveStepId,
      onClick,
      setShowErrorsList,
    ]
  );

  const handleDelete = React.useCallback(() => {
    if (disableDelete) return;
    deleteStep(id);
    if (isSelected) {
      setDrawerState(DrawerState.Closed);
    }
    if (onDelete) {
      onDelete(id);
    }
  }, [deleteStep, disableDelete, id, onDelete, isSelected, setDrawerState]);

  const handleMove = React.useCallback(
    (direction: Position.Left | Position.Right) => {
      dispatch({ type: 'step/moved', direction, stepId: id });
      if (onMove) {
        onMove(id, direction);
      }
    },
    [dispatch, id, onMove]
  );

  const moveLeft = React.useCallback(() => {
    handleMove(Position.Left);
  }, [handleMove]);

  const moveRight = React.useCallback(() => {
    handleMove(Position.Right);
  }, [handleMove]);

  const targetHandle = React.useMemo(() => {
    if (targetPosition !== Position.Right && targetPosition !== Position.Left)
      return null;

    return (
      <Handle
        className={styles.handle}
        type="target"
        position={targetPosition}
        isConnectable
      />
    );
  }, [targetPosition]);

  const sourceHandle = React.useMemo(() => {
    if (sourcePosition !== Position.Right && sourcePosition !== Position.Left)
      return null;

    return (
      <Handle
        className={styles.handle}
        type="source"
        position={sourcePosition}
        isConnectable
      />
    );
  }, [sourcePosition]);

  return (
    <Box className={cx([styles.baseNodeContainer, className])}>
      {targetHandle}
      <BaseNodeMoveArrow
        position={Position.Left}
        onMove={moveLeft}
        disabled={!topologyEditable}
        show={!hideActions && !hideMoveLeft && canMoveStep(id, Position.Left)}
      />
      <Box
        width={width}
        key={id}
        onClick={handleClick}
        className={cx(styles.nodeBody, {
          [styles.erroredNode]: isErrored,
          [styles.selectedNode]: isSelected && !isErrored,
          [styles.selectableNode]: type !== 'end',
        })}
      >
        {isErrored && (
          <div className={styles.warningIcon}>
            <Icon size={16}>warning</Icon>
          </div>
        )}
        {children}
      </Box>
      <BaseNodeMoveArrow
        position={Position.Right}
        onMove={moveRight}
        disabled={!topologyEditable}
        show={!hideActions && !hideMoveRight && canMoveStep(id, Position.Right)}
      />
      {sourceHandle}
      <Box className={styles.actions}>
        {!disableDelete && !hideActions && (
          <Button
            className={styles.delete}
            icon={<TrashFlatLid />}
            layoutOnly
            onClick={handleDelete}
            disabled={!topologyEditable}
            title={
              topologyEditable ? 'Delete step' : JOURNEY_ACTION_DISABLED_MESSAGE
            }
          />
        )}
      </Box>
    </Box>
  );
};

// do we need this?
export const NodeContent: React.FC<{
  className?: string;
}> = ({ children, className }) => {
  return (
    <Flex start className={cx([styles.body, className])}>
      {children}
    </Flex>
  );
};
