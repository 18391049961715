import React from 'react';
import { SearchBar } from 'shared/SearchBar';
import { FilterDropdown } from 'shared/FilterDropdown';

import styles from '../library.module.css';

const STATUSES = [
  { value: 'published', label: 'Published' },
  { value: 'archived', label: 'Archived' },
];

type PropsType = {
  searchTerm?: string;
  setSearchTerm: (term: string) => void;
  statuses?: string[];
  setStatuses: (statuses: string[]) => void;
};

export const FiltersBar: React.FC<PropsType> = ({
  searchTerm,
  setSearchTerm,
  statuses,
  setStatuses,
}) => {
  const selectedOptions = React.useMemo(() => {
    return STATUSES.filter((opt) => statuses?.includes(opt.value));
  }, [statuses]);

  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search templates"
        panelClassName={styles.searchBar}
      />
      <FilterDropdown
        label="Status"
        onChange={setStatuses}
        options={STATUSES}
        selectedOptions={selectedOptions}
      />
    </div>
  );
};
