import { RouteComponentProps } from '@reach/router';
import { useFontQuery } from 'hooks/useLibrary';
import { defaultFontStylesheet, FontStylesheet } from 'models/library';
import React from 'react';
import { uniqueId } from 'hooks/useUniqueId';
import { EditorComponent } from './EditorComponent';

export const FontEditor: React.FC<RouteComponentProps<{
  id: string | 'new';
}>> = ({ id }) => {
  const [font, setFont] = React.useState<FontStylesheet>({
    ...defaultFontStylesheet,
    identifier: uniqueId(),
  });
  const [hasLoaded, setHasLoaded] = React.useState<boolean>();

  const onFetchSuccess = React.useCallback((data: FontStylesheet) => {
    setFont(data);
  }, []);

  const { mutate: load, isLoading } = useFontQuery(onFetchSuccess);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!id || id === 'new') {
      setHasLoaded(true);
      return;
    }

    if (!font && !hasLoaded && id) {
      setHasLoaded(true);
      load(Number(id));
    }

    if (font && font.id !== id) {
      setHasLoaded(true);
      load(Number(id));
    }
  }, [id, font, load, hasLoaded, isLoading]);

  return <EditorComponent existingData={font} isLoading={isLoading} />;
};
