import * as React from 'react';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { EmailAlias } from 'models/email-alias';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { FocusDropdown } from 'shared/FocusDropdown';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { Dropdown } from './Dropdown';
import styles from './email-alias-select.module.css';

export type PropsType = {
  selectedEmails: Array<EmailAlias>;
  onChange: (emails: Array<EmailAlias>) => void;
  maxDropdownHeight?: number;
  placeholder?: string;
  name?: string | string[];
  upward?: boolean;
};

export const EmailAliasSelect: React.FC<PropsType> = (props) => {
  const {
    selectedEmails,
    onChange,
    maxDropdownHeight = 400,
    placeholder,
    upward = false,
  } = props;

  const [searchText, setSearchText] = React.useState('');

  const { id: programId } = useProgram();
  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useEmailAliasesInfiniteQuery({
    programId,
    search: useDebounce(searchText),
    status: ['active'],
  });

  const selectedIds = selectedEmails.map((email) => email.id || '');
  const selectedValues = selectedEmails.map((emails) => `${emails.name}`);

  const emails = [...data];
  const emailIds: Array<string> = data.map((item) => item.id || '');
  const uniqueIds = new Set([...emailIds]);
  selectedEmails.forEach((value) => {
    if (value.id && !uniqueIds.has(value.id)) {
      emails.push(value);
    }
  });

  function handleRemove(index: number) {
    const newSelectedEmails = [...selectedEmails];
    newSelectedEmails.splice(index, 1);
    onChange(newSelectedEmails);
  }

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedAudiences = ids
      .map((id) => emails.find((email) => email.id === id))
      .filter((item) => !!item);
    onChange(newSelectedAudiences as Array<EmailAlias>);
  }

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={emails}
        selectedIds={selectedIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        maxHeight={maxDropdownHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        upward={upward}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={selectedValues}
            onRemoveSelectedValueAt={handleRemove}
            placeholder={placeholder}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};
