import React from 'react';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { useProgram } from 'contexts/program';
import { SearchInput } from 'shared/SearchInput';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { Pills } from 'DesignSystem/Components/Pills';
import { useDebounce } from 'hooks/useDebounce';
import { Dropdown } from 'components/author-alias/AuthorAliasSelect/Dropdown';
import { useAuthorAliasesInfiniteQuery } from 'hooks/author-alias';
import { AliasData } from 'services/api-author-alias';
import styles from './styles.module.css';

type PropsType = {
  value: AliasData[];
  onChange: (value: AliasData[]) => void;
};

export const AuthorAliasSelector: React.FC<PropsType> = ({
  value,
  onChange,
}) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAuthorAliasesInfiniteQuery({
    pageSize: 20,
    programId,
    search: useDebounce(search),
    statuses: ['active'],
  });

  const selectedIds: string[] = [];
  const selectedAliasesByIds: { [key: string]: AliasData } = {};
  value.forEach((alias: AliasData) => {
    selectedIds.push(alias.id.toString() || '');
    selectedAliasesByIds[alias.userId || ''] = alias;
  });

  const aliases = data.map((alias) => {
    if (selectedAliasesByIds[alias.userId || '-'])
      return selectedAliasesByIds[alias.userId || '-'];
    return alias;
  });
  const aliasIds: Array<number> = data.map((item) => item.id || 0);
  const uniqueIds = new Set([...aliasIds]);
  value.forEach((alias: AliasData) => {
    if (alias.id && !uniqueIds.has(alias.id)) {
      aliases.push(alias);
    }
  });

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedAliases = ids
      .map((id) => aliases.find((alias) => alias.id?.toString() === id))
      .filter((item) => !!item);
    onChange(newSelectedAliases as Array<AliasData>);
  }

  function dropdown() {
    return (
      <Text.Body bold block>
        <Dropdown
          values={aliases as AliasData[]}
          selectedIds={selectedIds}
          onSelectedIdsChange={handleSelectedIdsChange}
          maxHeight={300}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Text.Body>
    );
  }

  return (
    <>
      <Text.Subheading bold block>
        Author Aliases
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select at least one author alias in order to take a bulk action
      </Text.Body>
      <Box margin={[16, 0, 16, 0]}>
        <ClickDropdown
          dropdownRenderProp={dropdown}
          matchReferenceWidth
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearch('');
          }}
          ignoreKeys
        >
          <div>
            <SearchInput
              placeholder="Search author aliases"
              value={search || ''}
              onChange={setSearch}
              labelClassName={styles.SearchInput}
            />
          </div>
        </ClickDropdown>
      </Box>

      <Flex start wrap className={styles.PillsContainer}>
        {value.map((alias, i) => (
          <Pills
            key={`author-alias-${alias.id}`}
            values={[{ id: i }]}
            render={() => <>{alias.displayName}</>}
            onClose={(t) => {
              const index = t.id;
              const values = [
                ...value.slice(0, index),
                ...value.slice(index + 1),
              ];

              onChange(values);
            }}
            padding={[0, 12, 0, 8]}
          />
        ))}
      </Flex>
    </>
  );
};
