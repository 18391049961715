import { useQuery } from 'react-query';
import camelcaseKeys from 'camelcase-keys';
import {
  ChannelContributor,
  ChannelContributorData,
  ChannelContributorsResponseData,
  fetchChannelContributors,
} from '../services/api-channel-contributors';
import { useProgram } from '../contexts/program';
import { QueryResponse } from './common';

const mapChannelContributorData = (data: Array<ChannelContributorData>) => {
  return data.map((d) => {
    return { id: d.id, ...d.attributes };
  });
};

export const useChannelContributorsQuery = (): QueryResponse<
  Array<ChannelContributor>
> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<
    ChannelContributorsResponseData,
    Error
  >(
    [`channel-contributors-${programId}`],
    () => fetchChannelContributors({ programId }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: camelcaseKeys(mapChannelContributorData(data ? data.data : [])),
  };
};
