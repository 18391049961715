import React from 'react';
import cx from 'classnames';
import { ReactComponent as Ellipse } from './Ellipse.svg';
import styles from './loading-spinner.module.css';

const quarterColorVariants = {
  brand: 'var(--color-brandTintDark)',
  blue: 'var(--color-blueFull)',
} as const;

export const LoadingSpinner: React.FC<{
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'normal';
  color?: keyof typeof quarterColorVariants;
}> = ({ size = 'normal', color = 'brand' }) => {
  const stroke = quarterColorVariants[color];
  return (
    <div
      role="status"
      aria-label="Loading"
      className={cx(styles.dualRing, {
        [styles.xSmallSize]: size === 'xsmall',
        [styles.smallSize]: size === 'small',
        [styles.mediumSize]: size === 'medium',
        [styles.largeSize]: size === 'large',
      })}
    >
      <Ellipse />
      <Quarter strokeColor={stroke} />
    </div>
  );
};

function Quarter({
  strokeColor = 'var(--color-brandTintDark)',
}: {
  strokeColor: string;
}) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 0.5C32 17.897 17.897 32 0.5 32"
        stroke={strokeColor}
        strokeWidth="7"
      />
    </svg>
  );
}
