import React from 'react';

type UseGeneralFieldsReturn = {
  shortcutEnabled: boolean;
  handleShortcutEnabledToggle: () => void;
  name: string;
  handleNameChange: (value: string) => void;
  description: string;
  handleDescriptionChange: (value: string) => void;
  iconSrc: string;
  handleIconSrcChange: (url: string) => void;
  headerSrc: string;
  handleHeaderSrcChange: (url: string) => void;
  setShortcutEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};
export function useGeneralShortcutsFields(): UseGeneralFieldsReturn {
  const [shortcutEnabled, setShortcutEnabled] = React.useState(true);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [iconSrc, setIconSrc] = React.useState('');
  const [headerSrc, setHeaderSrc] = React.useState('');

  const handleNameChange = React.useCallback((value: string) => {
    setName(value);
  }, []);

  const handleDescriptionChange = React.useCallback((value: string) => {
    setDescription(value);
  }, []);

  const handleShortcutEnabledToggle = React.useCallback(() => {
    setShortcutEnabled((prev) => !prev);
  }, []);

  const handleIconSrcChange = React.useCallback((url: string) => {
    setIconSrc(url);
  }, []);

  const handleHeaderSrcChange = React.useCallback((url: string) => {
    setHeaderSrc(url);
  }, []);

  return {
    shortcutEnabled,
    handleShortcutEnabledToggle,
    name,
    handleNameChange,
    description,
    handleDescriptionChange,
    iconSrc,
    handleIconSrcChange,
    headerSrc,
    handleHeaderSrcChange,
    setShortcutEnabled,
  };
}
