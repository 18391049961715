import React from 'react';
import { IconNames, MAIcon } from 'shared/MAIcon';
import cx from 'classnames';
import styles from './styles.module.css';

export type BannerProps = {
  name: IconNames;
  actionButton: React.ReactNode;
};

export const MessageBanner: React.FC<React.PropsWithChildren<BannerProps>> = ({
  name,
  children,
  actionButton,
}) => (
  <div
    key={name}
    role="alert"
    aria-label={name}
    className={cx(
      styles.HorizontallySpaced,
      styles.Banner,
      styles.VerticallyCentered,
      styles[name]
    )}
  >
    <div
      className={cx(
        styles.HorizontallySpaced,
        styles.Gap8,
        styles.VerticallyCentered
      )}
    >
      <MAIcon name={name} />
      <p className={styles.BannerContent}>{children}</p>
    </div>
    {actionButton}
  </div>
);
