import * as React from 'react';
import { PublisherProvider } from 'contexts/publisher';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useProgram } from 'contexts/program';
import qs from 'qs';
import { TemplateProvider } from 'contexts/template';
import { TemplateContainerType } from 'models/template';

export const Screen: React.FC<RouteComponentProps<{
  id: number | 'new';
}>> = ({ children, id = 'new' }) => {
  const program = useProgram();
  const location = useLocation();
  const queryString = location.search || '?';
  const params = qs.parse(queryString, { ignoreQueryPrefix: true });

  const qs_source = params.source || '';
  const qs_id = params.id || '';

  return (
    <TemplateProvider
      programId={program.id}
      source={qs_source}
      id={qs_id}
      containerType={TemplateContainerType.Publisher}
    >
      <PublisherProvider
        programId={program.id}
        id={id === 'new' ? id : Number(id)}
      >
        {children}
      </PublisherProvider>
    </TemplateProvider>
  );
};
