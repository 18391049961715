import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';

import { LanguageControl } from 'models/language-controls/language-control';
import {
  bulkDeleteLanguageControls,
  BulkDeleteLanguageControlsParams,
  createLanguageControl,
  CreateLanguageControlParams,
  deleteLanguageControl,
  duplicateLanguageControl,
  LanguageControlParams,
  updateLanguageControl,
  UpdateLanguageControlParams,
} from 'services/api-language-controls';

type UseMutation<TData, TError, TVariables> = (
  options?: UseMutationOptions<TData, TError, TVariables>
) => Pick<UseMutationResult<TData, TError, TVariables>, 'isLoading' | 'mutate'>;

export const useDuplicateLanguageControlMutation: UseMutation<
  LanguageControl,
  Error,
  LanguageControlParams
> = (options) => {
  const { mutate, isLoading } = useMutation(duplicateLanguageControl, options);
  return { mutate, isLoading };
};

export const useDeleteLanguageControlMutation: UseMutation<
  void,
  Error,
  LanguageControlParams
> = (options) => {
  const { mutate, isLoading } = useMutation(deleteLanguageControl, options);
  return { mutate, isLoading };
};

export const useBulkDeleteLanguageControlsMutation: UseMutation<
  void,
  Error,
  BulkDeleteLanguageControlsParams
> = (options) => {
  const { mutate, isLoading } = useMutation(
    bulkDeleteLanguageControls,
    options
  );
  return { mutate, isLoading };
};

export const useCreateLanguageControlMutation: UseMutation<
  LanguageControl,
  Error,
  CreateLanguageControlParams
> = (options) => {
  const { mutate, isLoading } = useMutation(createLanguageControl, options);
  return { mutate, isLoading };
};

export const useUpdateLanguageControlMutation: UseMutation<
  LanguageControl,
  Error,
  UpdateLanguageControlParams
> = (options) => {
  const { mutate, isLoading } = useMutation(updateLanguageControl, options);
  return { mutate, isLoading };
};
