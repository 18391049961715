import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { CircledInfo } from 'shared/icons';

export const EmptyAudience: React.FC = () => {
  return (
    <Flex center style={{ minHeight: '100%' }}>
      <Flex column>
        <CircledInfo />
        <br />
        Add audiences to see predicted <br />
        performance for your campaign
      </Flex>
    </Flex>
  );
};
