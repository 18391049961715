import { Button } from '@socialchorus/shared-ui-components';
import React, { ComponentProps, FC, useCallback } from 'react';
import { useFileUpload } from 'hooks/useFileUpload';

type ButtonProps = ComponentProps<typeof Button>;

type SharedPropsType = {
  shouldClear?: boolean | number;
  accept: string;
  aspectRatio?: number;
  variant?: ButtonProps['variant'];
} & Omit<ButtonProps, 'onClick' | 'variant'>;

export type FileUploadButtonPropsType = {
  onFileSelect: (file: File) => void;
} & SharedPropsType;

export type MultiFileUploadButtonPropsType = {
  onFileSelect: (file: File[]) => void;
  multiple?: boolean;
} & SharedPropsType;

export const MultiFileUploadButton: FC<MultiFileUploadButtonPropsType> = (
  props
) => {
  const {
    shouldClear,
    onFileSelect,
    accept,
    children,
    aspectRatio,
    multiple = true,
    variant = 'primary',
    ...buttonProps
  } = props;

  const {
    setFileUploadRef,
    handleButtonClick,
    handleFileChange,
  } = useFileUpload({ shouldClear, aspectRatio, onFileSelect });

  return (
    <>
      <Button
        onClick={handleButtonClick}
        // eslint-disable-next-line  react/jsx-props-no-spreading
        {...({ variant, ...buttonProps } as ButtonProps)}
      >
        {children}
      </Button>
      <input
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        ref={setFileUploadRef}
        onChange={handleFileChange}
        multiple={multiple}
      />
    </>
  );
};

export const FileUploadButton: FC<FileUploadButtonPropsType> = ({
  onFileSelect,
  children,
  ...props
}) => {
  const handleMultipleFileChange = useCallback(
    (files: File[]) => {
      if (files[0]) onFileSelect(files[0]);
    },
    [onFileSelect]
  );

  /* eslint-disable  react/jsx-props-no-spreading */
  return (
    <MultiFileUploadButton
      {...props}
      multiple={false}
      onFileSelect={handleMultipleFileChange}
    >
      {children}
    </MultiFileUploadButton>
  );
  /* eslint-enable  react/jsx-props-no-spreading */
};
