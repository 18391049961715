import React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Design } from 'components/publisher/pages/Design';
import { Card } from 'components/publisher/pages/Card';
import { Preview } from 'components/publisher/pages/Preview';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { usePublisher } from 'contexts/publisher';
import { ComposeComponent } from 'components/publisher/theme/Compose/Component';
import { PublisherFooter } from 'components/publisher/theme/PublisherFooter/PublisherFooter';
import { useCampaignHooks } from 'components/publisher/theme/Compose/useCampaignHooks';
import { useFontOptions } from 'hooks/useFontOptions';

type ComposeProps = RouteComponentProps & {
  implementation: 'old' | 'new';
};

export const Compose: React.FC<ComposeProps> = ({ implementation }) => {
  return (
    <Router>
      <Theme path="/">
        {/* Compose sub-pages */}
        <Design path="design" implementation={implementation} />
        <Card path="card" />
        <Preview path="preview" />

        {/* The defaults for the directory */}
        <Redirect from="/" to="design" noThrow />
        <Redirect from="*" to="/" default noThrow />
      </Theme>
    </Router>
  );
};

export const Theme: React.FC<RouteComponentProps> = ({ children }) => {
  const { post, update } = usePublisher();
  useSettings({ syncDefaults: true });

  const { fontOptions, isLoading: fontsLoading } = useFontOptions();

  const designEditor = useCampaignHooks({
    post,
    update,
    fontOptions,
  });

  const { contentPermissions } = useSettings();

  const navigationComponent = <PublisherFooter nextPage="orchestrate" />;
  if (fontsLoading) return null;

  return (
    <ComposeComponent
      navigationComponent={navigationComponent}
      designEditor={designEditor}
      designPermission={contentPermissions}
    >
      {children}
    </ComposeComponent>
  );
};
