import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTopicFormCtx } from '../../context';
import { useApplicableErrors } from '../../../topicErrorHooks';
import Footer from '../common/Footer';
import { PublishErrorsModal } from './PublishErrorsModal';

export const DraftFooter: FC<RouteComponentProps> = () => {
  const {
    isTopicUpdating,
    isTopicPublishing,
    handlePublish,
    handlePublishAttempt,
    publishErrorsModalToggle,
  } = useTopicFormCtx();

  const errors = useApplicableErrors();

  return (
    <>
      {publishErrorsModalToggle.isOpen && (
        <PublishErrorsModal onDismiss={publishErrorsModalToggle.close} />
      )}
      <Footer
        ctaText="Publish"
        ctaDisabled={isTopicUpdating || isTopicPublishing}
        onCtaClick={async () => {
          if (errors.length === 0) {
            handlePublish();
          } else {
            handlePublishAttempt();
            publishErrorsModalToggle.open();
          }
        }}
      />
    </>
  );
};
