import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { MAIcon } from 'shared/MAIcon';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useUser } from 'contexts/user';
import { fetchPersonalizedFieldsFileById } from 'services/api-personalized-fields';
import { fetchLiquidVariables } from 'services/api-liquid-variables';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import {
  mapFromServerData,
  useDeletePersonalizedFieldsFile,
  usePersonalizedFieldsFilesQuery,
} from 'hooks/personalized-fields';
import {
  ErrorModalConfig,
  FileModal,
  FileModalConfig,
  UpdateModalConfig,
  UploadModalConfig,
  PersonalizedFieldsModalResult,
} from './Modals';
import { FilePreview } from './FilePreview';
import styles from './styles.module.css';

export const PersonalizedFields: React.FC = () => {
  const { id: campaignId, save, setLiquidVariables } = usePublisher();

  React.useEffect(() => {
    if (campaignId === 'new') {
      save();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const { id: programId } = useProgram();
  const { id: userId } = useUser();
  const { setFlashMessage } = useFlashMessage();
  const { data, isLoading } = usePersonalizedFieldsFilesQuery(campaignId);
  const { deleteFile } = useDeletePersonalizedFieldsFile({
    onSuccess: (fileId: number) => {
      const file = findFile(fileId, true);
      setFlashMessage({
        severity: 'info',
        message: `${file?.name || 'File'} deleted`,
      });
      setFiles((list) => list?.filter(({ id }) => id !== fileId));
      fetchLiquidVariables(programId, campaignId)
        .then((liquidVarsData) => {
          if (setLiquidVariables) setLiquidVariables(liquidVarsData);
        })
        .catch((error) => {
          setFlashMessage({
            severity: 'error',
            message: error?.message,
          });
        });
    },
    onError: ({ message }) => setFlashMessage({ severity: 'error', message }),
  });

  const [files, setFiles] = React.useState<PersonalizedFieldsFile[]>();
  const [
    activeModal,
    setActiveModal,
  ] = React.useState<FileModalConfig | null>();

  const loaded = React.useRef(false);
  React.useEffect(() => {
    if (!loaded.current && !isLoading && data) {
      setFiles(data);
      loaded.current = true;
    }
  }, [loaded, isLoading, data]);

  const findFile = React.useCallback(
    (fileId: number, silent?: boolean) => {
      const file = files?.find(({ id }) => id === fileId);
      if (!file && !silent)
        setFlashMessage({
          severity: 'error',
          message: 'Unable to find the file.',
        });
      return file;
    },
    [files, setFlashMessage]
  );

  const updateList = (file: PersonalizedFieldsFile) => {
    setFiles((list) => {
      if (list?.find((f) => f.id === file.id))
        return list?.map((f) => (f.id === file.id ? file : f));
      return (list || []).concat([file]);
    });
  };

  const updateModalState = React.useCallback(
    async (
      input: { id: number | null } | PersonalizedFieldsModalResult
    ): Promise<undefined> => {
      type T1 = keyof typeof input;
      const maybe = (prop: string): typeof input[T1] | undefined => {
        const key = prop as T1;
        return key in input ? input[key] : undefined;
      };
      // try to get the file out of the list of files
      let fileId: number | null | undefined = maybe('id');
      let file: PersonalizedFieldsFile | null | undefined;
      if ('result' in input) {
        if ('state' in input.result) file = input.result;
        else if ('id' in input.result) fileId = input.result.id;
      }
      if (fileId && !file) file = findFile(fileId, true);
      const state = file?.state;
      // get the latest version of discarded, new, or in-progress files
      if (file && state === 'discarded') {
        deleteFile(file.id);
        file = null;
      } else if (
        fileId &&
        (!file || file.state === 'in_progress' || file.state === 'created')
      ) {
        try {
          file = mapFromServerData(
            await fetchPersonalizedFieldsFileById(programId, fileId)
          );
        } catch (e) {
          setFlashMessage({ severity: 'error', message: (e as Error).message });
        }
        if (!file) return;
      }
      if (file?.id) updateList(file);
      // configure the required modal based on the file and/or errors
      if (state === 'discarded') {
        setActiveModal({ type: 'upload' } as UploadModalConfig);
      } else if ('errors' in input) {
        let name;
        if (input?.result && 'name' in input.result) name = input.result.name;
        setActiveModal({
          type: 'error',
          fileId,
          name,
          errors: maybe('errors'),
        } as ErrorModalConfig);
      } else if (file?.expires) {
        setActiveModal(null);
      } else if (file) {
        setActiveModal({ type: 'update', file } as UpdateModalConfig);
      } else if ('result' in input && 'id' in input.result) {
        setActiveModal((m) => {
          if (m?.type === 'error')
            return { type: 'upload', id: fileId } as UploadModalConfig;
          return null;
        });
      } else {
        setActiveModal(null);
      }
    },
    [programId, findFile, deleteFile, setFlashMessage]
  );

  const openModal = React.useCallback(
    (fileId: number) => {
      const file = findFile(fileId);
      if (file) setActiveModal({ type: 'update', file } as UpdateModalConfig);
    },
    [findFile, setActiveModal]
  );

  return (
    <div className={styles.PersonalizedFieldsPanel}>
      <div>
        <Text.Heading bold block>
          Personalized Fields
        </Text.Heading>
        <Text.Body block color={Text.color.gray60}>
          Upload UTF-8 encoded CSV files with values to be used as personalized
          fields.
        </Text.Body>
      </div>
      <hr />
      <div>
        <Button
          block
          icon={<MAIcon name="upload" />}
          label="Upload .csv"
          className={styles.UploadButton}
          onClick={() => setActiveModal({ type: 'upload' })}
        />
      </div>
      {files?.length ? (
        <div>
          <Text.Body bold block>
            Uploaded Files
          </Text.Body>
          {files?.map((file) => (
            <FilePreview
              key={`${file.id}.${file.state}.${file.processedRows}`}
              file={file}
              onEdit={openModal}
              onRemove={deleteFile}
              isOwner={file.creatorId === userId}
            />
          ))}
        </div>
      ) : null}
      {activeModal && (
        <FileModal
          config={activeModal}
          onCancel={(file?: PersonalizedFieldsFile) => {
            if (file?.id) updateList(file);
            setActiveModal(null);
          }}
          onSubmit={updateModalState}
        />
      )}
    </div>
  );
};
