import React from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { LitmusRole } from 'models/role';
import styles from '../imports.module.css';

type StatsDisplay = {
  experience: number;
  studio: { group: string; value: number | undefined }[];
};

const preparePermissionsStats = (roles: LitmusRole[]) => {
  const studio = roles.map((role) => {
    return { group: role.title, value: 0 };
  });
  return {
    experience: 0,
    studio,
  };
};

const prepareStats = () => {
  const roles = [
    'Analyst',
    'Publisher',
    'Topic Manager',
    'Community Manager',
    'Administrator',
  ];

  const studio = roles.map((role) => {
    return { group: role, value: 0 };
  });
  return {
    experience: 0,
    studio,
  };
};

export const EmptyStats: React.FC<{
  permissionsEnabled: boolean;
  roles: LitmusRole[];
}> = ({ roles, permissionsEnabled }) => {
  let { experience, studio }: StatsDisplay = {
    experience: 0,
    studio: [],
  };

  if (!permissionsEnabled) {
    ({ experience, studio } = prepareStats());
  }
  if (permissionsEnabled) {
    ({ experience, studio } = preparePermissionsStats(roles));
  }

  return (
    <>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Experience">
            <div className={styles.pill}>
              <div className={styles.twoCols}>
                <span>Members</span>
                <span>{experience}</span>
              </div>
            </div>
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Studio">
            <div className={styles.pill}>
              {studio.map((item) => (
                <div key={item.group} className={styles.twoCols}>
                  <span>{item.group}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </Field>
        </div>
      </Fieldset>
    </>
  );
};
