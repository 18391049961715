import * as React from 'react';
import { LinkData } from 'models/publisher/block';
import { useMenu } from 'components/publisher/blocks/forms/fields/shared/SourceMenu';
import * as SM from './useSourceMenu';
import { ExitBlocker } from '../components/ExitBlocker';
import { useKeepOpen } from '../../shared/KeepOpen';
import { SOURCE_MENU_OPTIONS } from '../components/SourceMenuOptions';

type Props = {
  onEdit: (index: number) => void;
  onUpdate: (links: LinkData[]) => void;
  onClearAll: () => void;
  links: LinkData[];
  source: ReturnType<typeof SM.useLinksSourceMenu>;
};

export function useList({
  links,
  onEdit,
  onUpdate,
  onClearAll,
  source,
}: Props): Omit<Props, 'source'> & {
  remove(index: number): void;
  menu: SM.Menu;
} {
  const remove = React.useCallback(
    (index: number) => {
      links.splice(index, 1);
      onUpdate([...links]);
    },
    [links, onUpdate]
  );

  const handleOnCreate = React.useCallback(
    (initial?: SM.Source) => {
      onEdit(links.length);
      if (!initial) return;
      source.open(initial);
    },
    [links, onEdit, source]
  );

  useKeepOpen(ExitBlocker, links.length < 1);
  const menu = useMenu(SOURCE_MENU_OPTIONS, handleOnCreate);
  return { remove, menu, links, onEdit, onUpdate, onClearAll };
}
