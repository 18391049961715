import { useQuery } from 'react-query';
import { Facet } from 'models/facet';
import {
  fetchUsersFacets,
  FetchFacetsResponse,
  GroupQueryGroupTitles,
  fetchGroupQueryGroupTitles,
} from 'services/api-facets';
import { QueryResponse } from './common';

export const useAudienceFacetsQuery = (
  programId: number,
  q: string,
  facetNames: string[]
): QueryResponse<Facet[] | undefined> => {
  const queryKey = ['audienceFacets', programId, q, facetNames];

  const { isLoading, error, data } = useQuery<FetchFacetsResponse, Error>(
    queryKey,
    () => fetchUsersFacets(q, programId, facetNames),
    { retry: false }
  );

  return { isLoading, errorMessage: error?.message, data };
};

export const useFetchGroupQueryGroupTitles = (
  programId: number,
  groupName: string
): QueryResponse<GroupQueryGroupTitles | undefined> => {
  const queryKey = ['audienceQueryGroupNames', programId, groupName];
  const { isLoading, error, data } = useQuery<GroupQueryGroupTitles, Error>(
    queryKey,
    () => fetchGroupQueryGroupTitles(programId, groupName),
    { retry: false }
  );

  return { isLoading, errorMessage: error?.message, data };
};
