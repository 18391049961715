import * as React from 'react';
import { ConfirmModal } from 'DesignSystem/Components';
import * as Type from 'DesignSystem/Typography';
import {
  MAX_CONTENT_SIZE_IN_MB,
  KNOWLEDGE_BASE_SIZE_LIMIT_URL,
} from 'utility/constants';

interface ContentSizeLimitWarningProps {
  isSizeLimitExceeded: boolean | undefined;
  currentSize: number | undefined;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
}

const ContentSizeLimitWarning: React.FC<ContentSizeLimitWarningProps> = ({
  isSizeLimitExceeded,
  currentSize,
  isVisible,
  setIsVisible,
}) => {
  const displaySize = isSizeLimitExceeded
    ? `Over ${MAX_CONTENT_SIZE_IN_MB} MB`
    : `${currentSize} of ${MAX_CONTENT_SIZE_IN_MB} MB`;

  return (
    <>
      <span data-test="limitExceedCircle">
        <Type.PageHeading
          block={false}
          color={
            isSizeLimitExceeded ? Type.color.redFull : Type.color.greenFull
          }
        >
          &bull;
        </Type.PageHeading>
      </span>
      <Type.Caption>&nbsp;Size Limit: {displaySize}&nbsp;</Type.Caption>

      {isSizeLimitExceeded && (
        <>
          {isVisible && (
            <ConfirmModal
              onConfirm={() => setIsVisible(false)}
              confirmLabel="OK"
              title="Campaign Size Limit Exceeded"
              onCancel={() => {}}
              hideCancel
            >
              <Type.Body color={Type.color.gray60}>
                <b>
                  The content size has exceeded the {MAX_CONTENT_SIZE_IN_MB} MB
                  limit.
                </b>
                <p>
                  For help reducing the content size, you can see common causes
                  and solutions in{' '}
                  <a
                    href={KNOWLEDGE_BASE_SIZE_LIMIT_URL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    this Knowledge Base article.
                  </a>
                </p>
              </Type.Body>
            </ConfirmModal>
          )}
        </>
      )}
    </>
  );
};

export default ContentSizeLimitWarning;
