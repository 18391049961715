import { pluralize } from 'utility/text';
import { Criterion } from './publisher/criterion';
import { Author, ProgramAuthor } from './author';
import { DisplaySettings } from './display-settings';
import { TopicTag } from './topic-tag';

export interface TopicImage {
  width?: number;
  height?: number;
  filesize?: number;
  format?: string;
  url: string;
}

export type Topic = {
  autoPublish: boolean;
  backgroundImageUrl?: string;
  uploadedImage?: null | TopicImage;
  createdAt: string;
  default: boolean;
  description: string;
  followerCount: number;
  id: number | string;
  name: string;
  programId: number;
  criterionV2?: string | Criterion;
  targeted: boolean;
  published: boolean;
  archived: boolean;
  draft: boolean;
  updatedAt: string;
  groupIds: Array<string>;
  isPromoted?: boolean;
  contributorIds: Array<number>;
  isUserSubmittable: boolean;
  autoFollow: boolean;
  autoFollowCriterion?: Criterion;
  backgroundColor: string;
  stats: TopicStats | null;
  appendedHashtag?: string;
  autoFollowGroupIds: string[];
  landingPageTabs?: LandingPageTab[];
  coverImageUrl?: null | string;
  useCoverImage: boolean;
  parentId?: number;
  draftId?: number;
  channelTags?: TopicTag[];
  publishDraftAttempted: boolean;
};

export enum LandingPageTabType {
  About = 'about',
  Posts = 'posts',
  Members = 'members',
  Shortcuts = 'shortcuts',
}

export interface PinnableContent {
  id: number;
  contentType?: string;
  title?: string;
  summary?: string;
  descriptionText?: string;
  imageUrl?: string;
  publishedAt?: string;
  contentAuthor: Author | ProgramAuthor;
  displaySettings?: DisplaySettings;
}

export type LandingPageTab = {
  id?: string;
  position: number;
  isHidden: boolean;
} & (
  | { tabType: LandingPageTabType.Posts; pinnedContents: PinnableContent[] }
  | { tabType: LandingPageTabType.About }
  | { tabType: LandingPageTabType.Shortcuts }
  | { tabType: LandingPageTabType.Members }
);

export type TopicStats = {
  postsCount: number;
  created: string;
  lastUpdated?: string;
  postsPerWeek: number;
  followerCount: number;
  contributorCount: number;
};

export const TOPIC = {
  archived: false,
  autoFollow: false,
  autoFollowCriterion: undefined,
  autoPublish: false,
  backgroundColor: '',
  contributorIds: [],
  createdAt: '',
  default: false,
  description: '',
  followerCount: 0,
  groupIds: [],
  id: 0,
  isUserSubmittable: false,
  name: '',
  programId: 0,
  published: false,
  targeted: false,
  updatedAt: '',
  stats: {
    postsCount: 0,
    postsPerWeek: 0,
  },
};

export type TopicErrors = {
  title?: string;
  description?: string;
  autoFollowGroupIds?: string;
  published?: string;
  landing_page_tabs?: {
    about?: string;
  };
};

export type ContributorTopicType = {
  id: string;
  name: string;
  default?: boolean;
  hidden?: boolean;
  contributor_ids?: number[];
  description?: string;
  criterion_v2?: string | Criterion;
};

export type TopicShortcut = {
  id: string;
  name: string;
  description?: string;
  iconUrl?: string;
  position: number;
  links: {
    name: string;
    url: string;
    updatedAt: string;
  }[];
};

export function selectedTopics(topics: Array<Topic>): string {
  const title = pluralize(topics.length, 'Topic');
  const namesList = topics.map((ct) => ct.name).join(', ');
  const hasOrHave = pluralize(topics.length, 'has');
  return `${title} ${namesList} ${hasOrHave} been selected`;
}

export function isTopic(value: Topic | unknown): value is Topic {
  return (value as Topic).followerCount !== undefined;
}

function hasRequiredFields(topic: Partial<Topic>): TopicErrors {
  const errors: TopicErrors = {};

  if (topic.name === undefined || topic.name.trim().length === 0) {
    errors.title = 'Name Missing';
  }

  if (
    topic.description === undefined ||
    topic.description.trim().length === 0
  ) {
    errors.title = 'Description Missing';
  }

  return errors;
}

function autoFollowGroupIdError(topic: Partial<Topic>): TopicErrors {
  // an empty allowed target allows all autofollow criterions
  if (!topic.groupIds || !topic.groupIds.length) return {};
  if (!topic.autoFollowGroupIds) return {};

  const invalidAutoFollowGroup = topic.autoFollowGroupIds.some(
    (id) => !topic.groupIds?.includes(id)
  );

  return invalidAutoFollowGroup
    ? {
        autoFollowGroupIds:
          'Auto-follow audiences must be included in the overall audiences',
      }
    : {};
}

function publishedError(topic: Partial<Topic>): TopicErrors {
  return topic.autoFollow && !topic.published
    ? { published: 'Must be visible if Auto-follow is enabled' }
    : {};
}

export function topicErrors(topic: Partial<Topic>): TopicErrors | undefined {
  const errors = {
    ...autoFollowGroupIdError(topic),
    ...publishedError(topic),
    ...hasRequiredFields(topic),
  };
  return Object.keys(errors).length === 0 ? undefined : errors;
}

export function buildCriterion(ids?: string[]): Criterion | undefined {
  if (!ids || ids.length === 0) return undefined;

  return {
    or: ids.map((id) => {
      return { key: 'group', value: id, operator: '=' };
    }),
  };
}

export function updateTopic(topic: Topic, updates: Partial<Topic>): Topic {
  const updated: Topic = { ...topic, ...updates };

  return {
    ...updated,
    criterionV2: buildCriterion(updated.groupIds),
    autoFollowCriterion: buildCriterion(updated.autoFollowGroupIds),
  };
}

export function validateTopic(
  topic: Partial<Topic> | undefined
): string | undefined {
  const isRecommendedAndTargeted = topic?.isPromoted && topic.groupIds?.length;
  const isRecommendedAndHidden = topic?.isPromoted && !topic.published;

  if (isRecommendedAndTargeted) {
    return 'Recommended topics cannot have an audience.';
  }

  if (isRecommendedAndHidden) {
    return 'Recommended topics cannot be hidden.';
  }

  return undefined;
}
