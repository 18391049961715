import * as React from 'react';
import { useProgram } from 'contexts/program';
import { RenderingVariables } from 'models/publisher/block';

export const useFieldVariables = (): RenderingVariables => {
  const program = useProgram();

  const useVarsFromDesign = React.useMemo(
    (): RenderingVariables => ({
      program_theme_color: program.themeColor || '',
      program_accent_color: program.secondaryColor || '',
      program_header_image: program.headerImageUrl || '',
      program_icon_image: program.iconImageUrl || '',
      program_logo_image: program.logoImageUrl || '',
      author_name: program?.programAuthor?.displayName || '',
      author_avatar: program?.programAuthor?.avatarUrl || '',
      // TODO: implement read time in seconds
      // read_time_in_seconds: postData?.content?.readTimeInSeconds,
    }),
    [program]
  );
  return useVarsFromDesign;
};
