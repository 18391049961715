import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useSignInConfigQuery } from 'hooks/program';
import { SignInConfig } from 'models/signInConfig';
import { FirstupSection } from './FirstupSection';
import { SsoSection } from './SsoSection';
import { useActions } from './useActions';
import styles from './styles.module.css';

export const UserAccess: React.FC<RouteComponentProps> = () => {
  const { data: signInConfig, errorMessage } = useSignInConfigQuery();
  const { update } = useActions((savedConfig) => {
    setConfig(savedConfig);
  });
  const [config, setConfig] = React.useState<SignInConfig>();

  React.useEffect(() => {
    if (signInConfig) {
      setConfig(signInConfig);
    }
  }, [signInConfig]);

  function setDefault(defaultOpt: string) {
    if (config) {
      update({ ...config, defaultSignInMethod: defaultOpt });
    }
  }
  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        title="User Access"
        description="Configure how workers will access your program"
        breadcrumbs={[
          { label: 'Configure', to: '..' },
          { label: 'User Access' },
        ]}
      >
        {errorMessage && <>{errorMessage}</>}
        {config && (
          <FirstupSection signInConfig={config} setDefault={setDefault} />
        )}
        <div className={styles.SsoSectionContainer}>
          {config && config.canEnable.sso && (
            <SsoSection signInConfig={config} setDefault={setDefault} />
          )}
        </div>
      </FormPage>
    </WithPermission>
  );
};
