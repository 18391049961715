import React from 'react';
import cx from 'classnames';
import layout from 'DesignSystem/Layout/layout.module.css';
import styles from './journey-drawer.module.css';

export interface IDrawerTab {
  label: string;
  id: string;
}

interface IDrawerTabs {
  /**
   * An array of drawer tabs, used to populate the tab navigation
   * */
  tabs: IDrawerTab[];

  /**
   * The ID of the currently active tab.
   * DrawerTabs component is considered a controlled component and
   * the parent component is responsible for maintaining the state of the tabs.
   * */
  activeId: string;

  /**
   * Called when the active tab changes via user interaction with the tab navigation
   * */
  onSelectTab: (tabId: string) => void;

  className?: string;
}

const DrawerTabs: React.FC<IDrawerTabs> = ({
  tabs,
  activeId,
  onSelectTab,
  className,
}) => {
  const handleSelectTab = (tab: IDrawerTab) => {
    onSelectTab?.(tab.id);
  };

  return (
    <ul className={cx([layout.Tabs, className])}>
      {tabs?.map((tab) => (
        <li key={tab.id}>
          <button
            type="button"
            className={cx(layout.TabButton, styles.tabButton, {
              [layout.TabButtonActive]: tab.id === activeId,
            })}
            onClick={() => handleSelectTab(tab)}
          >
            {tab.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default DrawerTabs;
