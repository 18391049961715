import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { SignInConfig } from 'models/signInConfig';
import { Checkbox } from 'shared/Checkbox';
import styles from '../styles.module.css';

type PropsType = {
  config: SignInConfig;
  setConfig: (config: SignInConfig) => void;
};

export const AssertionsInput: React.FC<PropsType> = ({ config, setConfig }) => {
  return (
    <div className={styles.SsoInput}>
      <Text.Subheading block bold>
        SAML Assertions/Request
      </Text.Subheading>
      <div className={styles.Radio}>
        <Checkbox
          checked={config.sso.assertions === 'signed'}
          onSelect={() => {
            setConfig({
              ...config,
              sso: { ...config.sso, assertions: 'signed' },
            });
          }}
          type="radio"
          id="signed-assertions"
        />
        <label htmlFor="signed-assertions" className={styles.RadioLabel}>
          Signed
        </label>
      </div>

      <div className={styles.Radio}>
        <Checkbox
          checked={config.sso.assertions === 'unsigned'}
          onSelect={() => {
            setConfig({
              ...config,
              sso: { ...config.sso, assertions: 'unsigned' },
            });
          }}
          type="radio"
          id="unsigned-assertions"
        />
        <label htmlFor="unsigned-assertions" className={styles.RadioLabel}>
          Unsigned
        </label>
      </div>
    </div>
  );
};
