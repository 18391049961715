import React from 'react';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { BulkSelectActionType } from 'shared/BulkSelect/Actions';
import { EmailAliasBulkActionFilters } from 'hooks/common';
import { useActions } from '../useActions';

type PropsType = {
  children: (isBulkSelectMode: boolean) => React.ReactNode;
  totalRecords: number;
  filterConfig: EmailAliasBulkActionFilters;
};

export const WithBulkSelect: React.FC<PropsType> = ({
  children,
  totalRecords,
  filterConfig,
}) => {
  const [isBulkSelectMode, setIsBulkSelectMode] = React.useState(false);
  const { bulkArchive, bulkUnarchive } = useActions();

  const actions: BulkSelectActionType[] = [
    {
      type: 'button',
      actions: [
        {
          label: 'Archive',
          iconName: 'archive',
          onClick: (bulkSelection) => {
            bulkArchive(bulkSelection, filterConfig);
            return true;
          },
        },
      ],
    },
    {
      type: 'button',
      actions: [
        {
          label: 'Unarchive',
          iconName: 'Unarchive',
          onClick: (bulkSelection) => {
            bulkUnarchive(bulkSelection, filterConfig);
            return true;
          },
        },
      ],
    },
  ];
  return (
    <BulkSelectWrapper
      totalRecords={totalRecords}
      actions={actions}
      setIsBulkSelectMode={setIsBulkSelectMode}
    >
      {children(isBulkSelectMode)}
    </BulkSelectWrapper>
  );
};
