import { useInfiniteQuery } from 'react-query';
import { WorkflowTemplate } from 'models/workflow-templates';
import {
  fetchWorkflowTemplates,
  FetchProps,
  WorkflowTemplatesCollection,
} from 'services/api-workflow-templates';
import { InfiniteQueryResponse, nextPageToFetch } from '../common';

export const useWorkflowTemplatesInfiniteQuery = (
  props: Omit<FetchProps, 'page' | 'pageSize'>
): InfiniteQueryResponse<WorkflowTemplate> => {
  const key = ['workflow-templates-infinite', JSON.stringify(props)];
  const res = useInfiniteQuery<WorkflowTemplatesCollection, Error>(
    key,
    async ({ pageParam }: { pageParam?: number }) =>
      fetchWorkflowTemplates({ ...props, pageSize: 100, page: pageParam }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, 100),
    }
  );
  const pages = res.data?.pages ?? [];
  const templates: WorkflowTemplate[] =
    pages
      .map((page) => (page.data ?? []).map((item) => item.attributes))
      .flat(1) || [];
  return {
    isLoading: res.isFetching,
    errorMessage: res.error?.message,
    isFetchingNextPage: res.isFetchingNextPage,
    fetchNextPage: res.fetchNextPage,
    hasNextPage: res.hasNextPage,
    data: templates,
    meta: pages[0]?.meta,
  };
};
