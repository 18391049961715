import React, { useState } from 'react';
import { SaveModalButtons } from 'App/Program/Editors/Publisher/Deliver/Shared/SaveModalButtons';
import { Box } from 'DesignSystem/Components/Box';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';
import { Caption, Subheading } from 'DesignSystem/Typography';
import { DateTime } from 'luxon';
import { Checkbox } from 'shared/Checkbox';
import { FlipSwitch } from 'shared/FlipSwitch';
import { Button } from 'DesignSystem/Form';
import { Icon } from 'shared/Icon';
import { Calendar } from 'shared/DateInput/Calendar';
import { Time } from 'shared/DateInput/Time';
import { Popover } from 'DesignSystem/Components/Popover';
import style from './schedule-content.module.css';

const VOWELS = ['A', 'E', 'I', 'O', 'U'];
const getArticle = (text: string) =>
  VOWELS.indexOf(text[0].toUpperCase()) > -1 ? 'an' : 'a';

export const ScheduleContentEdit: React.FC<{
  publicationState?: string;
  saveAsDraft?: () => void;
  dismiss: DismissType;
  publishedAt: DateTime | undefined;
  archiveAt: DateTime | undefined;
  onDatesChange: (
    publishedAt: DateTime | undefined,
    archiveAt: DateTime | undefined
  ) => void;
}> = ({
  publicationState,
  saveAsDraft,
  dismiss,
  publishedAt,
  archiveAt,
  onDatesChange,
}) => {
  const [localPublishedAt, setLocalPublishedAt] = useState(publishedAt);
  const [localArchiveAt, setLocalArchiveAt] = useState(archiveAt);
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [showPublishedAtDateInput, setShowPublishedAtDateInput] = useState(
    !!publishedAt
  );

  const [showArchiveDateForm, setShowArchiveDateForm] = useState(
    archiveAt !== undefined
  );

  const calendarDropdown = (
    dateField: DateTime | undefined,
    setDateField: (date: DateTime | undefined) => void
  ) => (
    <Box width={336} style={{ cursor: 'auto', position: 'relative' }}>
      <Popover centered padding={0}>
        <Calendar
          selectedDate={dateField || DateTime.now()}
          onChange={setDateField}
        />
      </Popover>
    </Box>
  );

  const timeDropdown = (
    dateField: DateTime | undefined,
    setDateField: (date: DateTime | undefined) => void
  ) => (
    <Box width={250} style={{ cursor: 'auto', position: 'relative' }}>
      <Popover centered padding={0}>
        <Time date={dateField || DateTime.now()} onChange={setDateField} />
      </Popover>
    </Box>
  );

  const dateTimeInput = (
    dateField: DateTime | undefined,
    setDateField: (date: DateTime | undefined) => void,
    fieldName: string
  ) => {
    return (
      <>
        <Box>
          <Box style={{ display: 'flex' }}>
            <div style={{ margin: '0 8px 0 60px', width: '195px' }}>
              <ClickDropdown
                placement="top-end"
                dropdownRenderProp={() =>
                  calendarDropdown(dateField, setDateField)
                }
              >
                <Button
                  className={!dateField ? style.ButtonError : ''}
                  input
                  block
                  badge={<Icon iconName="calendar" />}
                  label={
                    dateField?.toFormat('d MMM y') || `Select ${fieldName} date`
                  }
                />
              </ClickDropdown>
            </div>
            <div style={{ width: '195px' }}>
              <ClickDropdown
                placement="top-end"
                dropdownRenderProp={() => timeDropdown(dateField, setDateField)}
              >
                <Button
                  className={!dateField ? style.ButtonError : ''}
                  input
                  block
                  badge={<Icon iconName="clock" />}
                  label={dateField?.toFormat('t') || `Select ${fieldName} time`}
                />
              </ClickDropdown>
            </div>
          </Box>
          <Box style={{ display: 'flex' }} margin={[0, 15]}>
            {!dateField && (
              <Box margin={[1, 'auto', 0, 45]} style={{ display: 'flex' }}>
                <Caption>
                  <div className={style.Error}>
                    <Icon iconName="exclamation-triangle" />
                    &nbsp;
                    {`Select ${getArticle(
                      fieldName
                    )} ${fieldName} date and time`}
                  </div>
                </Caption>
              </Box>
            )}
            <Box margin={[1, 0, 0, 'auto']} style={{ display: 'flex' }}>
              <Caption>Time zone: {DateTime.now().toFormat('ZZZZ')}</Caption>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const publishedAtRadios = (
    <>
      <Box
        margin={[0, 20]}
        padding={[0, 20, 20, 0]}
        style={{ display: 'flex' }}
      >
        <Checkbox
          type="radio"
          checked={!showPublishedAtDateInput}
          onChange={() => {
            setLocalPublishedAt(undefined);
            setShowPublishedAtDateInput(!showPublishedAtDateInput);
            setSaveEnabled(true);
          }}
        />
        <Box padding={[0, 20]}>
          {publicationState === 'scheduled'
            ? 'Move to Draft'
            : 'Publish Immediately'}
        </Box>
      </Box>
      <Box
        dataTest="schedule-for-later-checkbox"
        margin={[0, 20]}
        padding={[0, 20, 20, 0]}
        style={{ display: 'flex' }}
      >
        <Checkbox
          type="radio"
          onChange={() => {
            setShowPublishedAtDateInput(!showPublishedAtDateInput);
            setSaveEnabled(true);
          }}
          checked={showPublishedAtDateInput}
        />
        <Box padding={[0, 20]}>Schedule for later</Box>
      </Box>
      {showPublishedAtDateInput &&
        dateTimeInput(
          localPublishedAt,
          (date) => {
            setLocalPublishedAt(date);
            setSaveEnabled(true);
          },
          'publish'
        )}
    </>
  );

  const archiveAtRadios =
    showArchiveDateForm &&
    dateTimeInput(
      localArchiveAt,
      (date) => {
        setLocalArchiveAt(date);
        setSaveEnabled(true);
      },
      'archive'
    );

  return (
    <Box style={{ color: 'black' }}>
      <Box padding={20}>
        <Subheading bold>Schedule</Subheading>
        <Box margin={[0, 0, 20, 0]}>
          <Caption>
            Publish time determines when a campaign is made active, and
            therefore is visible on Experience or deliveries can be made, if
            applicable.
          </Caption>
        </Box>

        {publishedAtRadios}

        <Box
          margin={[20, 0, 0, 20]}
          padding={[0, 20, 20, 0]}
          style={{ display: 'flex' }}
        >
          <Box>Add archive date</Box>
          <Box margin={[0, 0, 0, 'auto']}>
            <FlipSwitch
              shape="rounded"
              size="xl"
              on={showArchiveDateForm}
              onChange={() => {
                setLocalArchiveAt(undefined);
                setShowArchiveDateForm(!showArchiveDateForm);
                setSaveEnabled(true);
              }}
            />
          </Box>
        </Box>

        {archiveAtRadios}
      </Box>
      <Box margin={[0, 20, 20, 0]}>
        <SaveModalButtons
          disabled={
            (showPublishedAtDateInput && localPublishedAt === undefined) ||
            (showArchiveDateForm && localArchiveAt === undefined) ||
            !saveEnabled
          }
          onSave={() => {
            if (
              saveAsDraft &&
              publicationState === 'scheduled' &&
              localPublishedAt === undefined
            ) {
              saveAsDraft();
              dismiss();
            } else {
              onDatesChange(localPublishedAt, localArchiveAt);
              dismiss();
            }
          }}
          onCancel={dismiss}
        />
      </Box>
    </Box>
  );
};
