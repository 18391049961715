import { personalizationVariableRegExp } from 'models/liquid-variable';
/**
 * Content editable spans automatically encode
 * html looking stuff. So far these are all
 * the entities that are encoded. If more show up
 * add them to this and the next function
 */
export function encodeText(text: string): string {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/ /g, '&nbsp;');
}

/**
 * Opposite direction of the `encodeText` function
 */
export function decodeText(text: string): string {
  return text
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
}

/**
 * The decoded and trimmed text.
 * This is the value passed back to the parent
 */
export function normalText(text = ''): string {
  return decodeText(text).trim();
}

// TODO (will add proper variable counting in next story)
// "Hello {{first_name}} {{BLAH}}."
// Should say 17, since {{BLAH}} is not a variable.
// Right now says 10 because it blindly collapses {{BLAH}}
// When the function is called from within the component, it has access to
// the list of variables, and can pass them in to properly count the vars.
export function countCharsByBlindlyCollapsingEntitiesAndVariables(
  text: string
): number {
  return text.replace(/&\w{2,4};/g, '_').replace(/{{\w+}}/g, '_').length;
}

export function stripHtmlTags(html: string): string {
  let tagless = '';
  for (let i = 0; i < html.length; i += 1) {
    const char = html[i];
    if (char === '<') {
      i += html.substring(i).indexOf('>');
    } else {
      tagless += char;
    }
  }
  return tagless;
}

/**
 * Main parsing function.
 * Always returns a sequence of input of variables of the form:
 *   Input -> [Text, (Variable, Text)*]
 * TODO: memoize on text+variables
 */
type Variable = {
  type: 'variable';
  value: string;
  description: string;
  display_value?: string;
};
type Input = { type: 'input'; value: string; display_value?: string };
export function parseTextWithVariables(
  text: string
): (Omit<Variable, 'description'> | Input)[] {
  const parts: (Omit<Variable, 'description'> | Input)[] = [];
  let input = '';
  const regex = personalizationVariableRegExp;
  for (let pos = 0; pos < text.length; ) {
    const trunc = text.substr(pos);
    if (trunc.substr(0, 2) === '{{') {
      const group = trunc.match(regex);
      if (group) {
        parts.push({ type: 'input', value: input, display_value: undefined });
        input = '';
        const end = trunc.indexOf('}}') + 2;
        parts.push({
          type: 'variable',
          display_value: group[1],
          value: group[0],
        });
        pos += end;
        /* eslint-disable-next-line no-continue */
        continue;
      }
    }
    input += trunc[0];
    pos += 1;
  }
  parts.push({ type: 'input', value: decodeText(input) });
  input = '';
  return parts;
}

/**
 * Finds all the matching variables for some leading text
 * TODO memoize this on variables+text
 */
export function matchVariables(
  variables: { key: string; description: string }[],
  text: string
): { key: string; description: string }[] {
  const matches = variables.filter((variable) => {
    // 'name' matches: first_name, last_name, user_name, etc
    const noBraces = text.replace(/[{}]/g, '');
    return variable.key.indexOf(noBraces) !== -1;
  });
  return matches;
}

/**
 * Helper function for getting the caret position
 * https://stackoverflow.com/questions/22677931/react-js-onchange-event-for-contenteditable/27255103#27255103
 */
function getCaretCharacterOffsetWithin(element: HTMLElement) {
  let caretOffset = 0;
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore old browser compatibility
  const doc = element.ownerDocument || element.document;
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore old browser compatibility
  const win = doc.defaultView || doc.parentWindow;
  if (typeof win.getSelection !== 'undefined') {
    const sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore old browser compatibility
    const sel = doc.selection;
    if (sel && sel.type !== 'Control') {
      const textRange = sel.createRange();
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore old browser compatibility
      const preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
    }
  }
  return caretOffset;
}

/**
 * Gets the caret position within an element
 */
export function getCaretWithin(
  element: HTMLElement
): {
  offset: number;
  atStart: boolean;
  atEnd: boolean;
} {
  const offset = getCaretCharacterOffsetWithin(element);
  return {
    offset,
    atStart: offset === 0,
    atEnd: offset === element.innerText.length,
  };
}

/**
 * helper function for setting the caret position
 * https://stackoverflow.com/questions/6240139/highlight-text-range-using-javascript/6242538#6242538
 */
function getTextNodesIn(node: Node) {
  const textNodes: CharacterData[] = [];
  if (node.nodeType === 3) {
    textNodes.push(node as CharacterData);
  } else {
    const children = node.childNodes;
    for (let i = 0, len = children.length; i < len; i += 1) {
      textNodes.push(...getTextNodesIn(children[i]));
    }
  }
  return textNodes;
}

/**
 * Sets the caret at a certain position within an element
 */
export function setCaretOffsetWithin(offset: number, element: Node): void {
  const end = offset;
  if (document.createRange && window.getSelection) {
    const range = document.createRange();
    range.selectNodeContents(element);
    const textNodes = getTextNodesIn(element);
    let foundStart = false;
    let charCount = 0;
    let endCharCount: number | undefined;

    /* eslint-disable-next-line */
    for (let i = 0, textNode; (i += 1), (textNode = textNodes[i]); ) {
      endCharCount = charCount + textNode.length;
      if (
        !foundStart &&
        offset >= charCount &&
        (offset < endCharCount ||
          (offset === endCharCount && i <= textNodes.length))
      ) {
        range.setStart(textNode, offset - charCount);
        foundStart = true;
      }
      if (foundStart && offset <= endCharCount) {
        range.setEnd(textNode, end - charCount);
        break;
      }
      charCount = endCharCount;
    }

    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore other browser support
  } else if (document.selection && document.body.createTextRange) {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore other browser support
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(element);
    textRange.collapse(true);
    textRange.moveEnd('character', end);
    textRange.moveStart('character', offset);
    textRange.select();
  }
}
