import * as React from 'react';
import Select from 'react-select';
import { FieldInput } from 'DesignSystem/Form';
import { PollPreset } from 'models/publisher/block';
import { FieldLegend } from '../../shared/FieldLegend';

type PollOption = { label: string; value: PollPreset };

export const Preset: React.FC<{
  preset: PollPreset;
  presets: PollOption[];
  onChange(preset: PollPreset): void;
}> = ({ preset, presets, onChange }) => (
  <FieldInput
    legend={<FieldLegend>Type</FieldLegend>}
    htmlFor="block-poll-preset"
  >
    <Select
      inputId="block-poll-preset"
      defaultValue={presets.find(({ value }) => value === preset)}
      options={presets}
      // @ts-expect-error The types for this dependency are hard to work with.
      onChange={({ value }: Partial<PollOption> = {}) => {
        if (!value) return;
        onChange(value);
      }}
    />
  </FieldInput>
);
