import * as React from 'react';
import { FieldInput, Toggle } from 'DesignSystem/Form';
import { FieldLegend } from '../../shared/FieldLegend';

export const Results: React.FC<{
  visibility: boolean;
  canToggleVisibility: boolean;
  toggleVisibility(): void;
}> = ({ visibility, canToggleVisibility, toggleVisibility }) => (
  <FieldInput
    legend={<FieldLegend>Show Results to User?</FieldLegend>}
    htmlFor="block-poll-results"
  >
    <Toggle
      htmlForId="block-poll-results"
      on={visibility}
      disabled={!canToggleVisibility}
      onChange={toggleVisibility}
    />
  </FieldInput>
);
