import { useEffect } from 'react';
import jQuery from 'jquery';
import 'jqueryui';

export function useOnReady(onReady: ($: typeof jQuery) => void): void {
  useEffect(() => {
    jQuery(() => onReady(jQuery));
    // Used as "onMount", only runs once.
    // eslint-disable-next-line
  }, []);
}

export function useJquery(onReady: ($: typeof jQuery) => void): void {
  useEffect(() => {
    jQuery(() => onReady(jQuery));
  }, [onReady]);
}
