import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { FormModal } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';

export const WeekendDeliveryModal: React.FC<{
  onCancel: () => void;
  onSubmit: () => void;
  optimizedDeliveryEnabled?: boolean;
  retries?: number;
}> = ({ onCancel, onSubmit, optimizedDeliveryEnabled, retries }) => {
  let content;

  if (optimizedDeliveryEnabled) {
    content =
      'The campaign duration only spans a weekend. No deliveries will be made. To include non-weekend days and increase potential retargeting attempts, reschedule the publish time and extend the campaign duration or turn Engagement Boost off to publish at your selected time.';
  } else if (retries === -1) {
    content =
      'The campaign duration only spans a weekend, with initial delivery at your selected publish time. To include non-weekend days and increase potential retargeting attempts, consider extending the campaign duration.';
  } else {
    content =
      'The campaign is scheduled to be published over a weekend, with delivery at your selected publish time.';
  }

  return (
    <FormModal
      entityText=""
      actionText="Weekend Delivery Warning"
      width="680px"
      contentPadding="32px"
      onCancel={onCancel}
      submitButton={
        <Button
          disabled={false}
          onClick={optimizedDeliveryEnabled ? onCancel : onSubmit}
          label={optimizedDeliveryEnabled ? 'Reschedule Campaign' : 'Publish'}
        />
      }
    >
      <Text.Body>{content || ''}</Text.Body>
    </FormModal>
  );
};
