import * as React from 'react';
import { Post } from 'models/publisher/post';
import { isImageBlock } from 'models/publisher/block';
import { useCardEditor } from 'contexts/publisher/compose/card';
import { usePublisher } from 'contexts/publisher';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { CardPreview } from '../CardPreview';
import styles from './web.module.css';

type PropsType = {
  title: string;
  summary: string;
  post: Post;
  showTitle: boolean;
};

const PostPreview: React.FC<PropsType> = ({ title, summary, showTitle }) => (
  <>
    <CardPreview styles={styles} viewType="web" />
    <div className={styles.text}>
      {showTitle && (
        <div
          dir="auto"
          className={styles.title} // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <div
        dir="auto"
        className={styles.summary} // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: summary }}
      />
    </div>
  </>
);

const Item: React.FC<{ title: string }> = ({ title }) => (
  <>
    <div className={styles.itemImage} />
    <div className={styles.itemTitle}>{title}</div>
  </>
);

export const Web: React.FC = () => {
  const { cta } = useCardEditor();
  const { post } = usePublisher();
  const { instances } = React.useContext(BlocksEditorContext);
  const { title, summary } = cta;

  const showTitle = !cta.customEnabled || !isImageBlock(instances[0].block);

  return (
    <div className={styles.web}>
      <div className={styles.address}>https://....</div>
      <div className={styles.content}>
        <div className={styles.latest}>
          <PostPreview
            title={title}
            summary={summary}
            post={post}
            showTitle={showTitle}
          />
        </div>
        <div className={styles.others}>
          <div className={styles.item}>
            <Item title="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
          </div>
          <div className={styles.item}>
            <Item title="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
          </div>
          <div className={styles.item}>
            <Item title="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
          </div>
        </div>
      </div>
    </div>
  );
};
