import { InclusiveRule } from 'models/inclusive-language';
import {
  ThumbnailType,
  MenuItemsType,
} from '../../../../shared/BannerListItem';

const BACKGROUND_COLORS = ['#5D7E80', '#5D6480', '#6797AC'];

export function inclusiveRuleToThumbnail(
  inclusiveRule: Pick<InclusiveRule, 'suggestions'>,
  index: number
): ThumbnailType {
  return {
    title: inclusiveRule.suggestions[0][0],
    color: 'var(--color-gray05)',
    backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
  };
}

export function inclusiveRuleToMenuItems(
  programId: number,
  inclusiveRule: InclusiveRule,
  onDestroy: () => void
): MenuItemsType {
  const { id } = inclusiveRule;

  const result: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${programId}/configure/style-guide/${id}/edit`,
    },
    {
      title: 'Delete',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDestroy();
      },
    },
  ];

  return result;
}
