import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { StyleEditorContext } from 'contexts/publisher/compose/style';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { CanvasBlockPanelContextProvider } from 'contexts/publisher/compose/canvas-block-panel';
import { CanvasFieldsPanelContextProvider } from 'contexts/publisher/compose/canvas-fields-panel';
import { CanvasStylePanelContextProvider } from 'contexts/publisher/compose/canvas-style-panel';
import { NavigationBlockerContextProvider } from 'contexts/publisher/compose/navigation-blocker';
import { CardEditorContext } from 'contexts/publisher/compose/card';
import { CanvasGlobalPanelContextProvider } from 'contexts/publisher/compose/canvas-global-panel';
import {
  DesignPermission,
  DesignPermissionContext,
} from 'components/publisher/theme/Compose/DesignPermission';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { DragDropContext } from '../../blocks/dnd/DragDropContext';
import { DesignEditor } from './useCampaignHooks';
import { Sidebar } from './Sidebar';

export type ComposeComponentProps = RouteComponentProps & {
  navigationComponent: React.ReactNode;
  designEditor: DesignEditor;
  designPermission: DesignPermission;
};

export const ComposeComponent: React.FC<ComposeComponentProps> = ({
  children,

  navigationComponent,
  designEditor,
  designPermission,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <PreviewContext.Provider value={designEditor.preview}>
        <StyleEditorContext.Provider value={designEditor.styleEditor}>
          <CardEditorContext.Provider value={designEditor.cardEditor}>
            <BlocksEditorContext.Provider value={designEditor.blocksEditor}>
              <DesignPermissionContext.Provider value={designPermission}>
                <DragDropContext>
                  <NavigationBlockerContextProvider
                    value={designEditor.navigationBlocker}
                  >
                    <CanvasBlockPanelContextProvider>
                      <CanvasStylePanelContextProvider>
                        <CanvasFieldsPanelContextProvider>
                          <CanvasGlobalPanelContextProvider>
                            <Sidebar blocksEditor={designEditor.blocksEditor}>
                              {children}
                            </Sidebar>
                            {navigationComponent}
                          </CanvasGlobalPanelContextProvider>
                        </CanvasFieldsPanelContextProvider>
                      </CanvasStylePanelContextProvider>
                    </CanvasBlockPanelContextProvider>
                  </NavigationBlockerContextProvider>
                </DragDropContext>
              </DesignPermissionContext.Provider>
            </BlocksEditorContext.Provider>
          </CardEditorContext.Provider>
        </StyleEditorContext.Provider>
      </PreviewContext.Provider>
    </DndProvider>
  );
};
