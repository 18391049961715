import React, { useEffect } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { usePublisher } from 'contexts/publisher';
import { useProgram } from 'contexts/program';
import { useProgramCustomizationsQuery } from 'hooks/feature-flags';
import { usePublisherValidator } from 'hooks/validators';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useDeliveryValidator } from 'hooks/useDeliveryValidator';
import { ReviewComponent } from './Component';
import { PublisherFooter } from '../../theme/PublisherFooter/PublisherFooter';

export const Review: React.FC<RouteComponentProps> = () => {
  const publisher = usePublisher();
  const { id: programId } = useProgram();
  const { post } = publisher;
  const { data: featureFlags } = useProgramCustomizationsQuery(programId);
  const validator = usePublisherValidator();
  const { callToAction, blocks } = post;
  const { settings } = useSettings({
    syncDefaults: true,
  });

  const { isValid, errors } = validator.validate({
    featureFlags,
    settings,
    callToAction,
    blocks,
  });

  const navigate = useNavigate();
  const { validate: validateDelivery } = useDeliveryValidator();

  useEffect(() => {
    if (!validateDelivery()) {
      navigate(`/${programId}/edit/publisher/${post.content.id}/deliver`);
    }
    // eslint-disable-next-line
  }, [navigate]);

  const footer = <PublisherFooter />;

  return (
    <ReviewComponent
      isValid={isValid}
      errors={errors}
      post={post}
      footer={footer}
    />
  );
};
