import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { background, PageHeading } from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { PublisherFooter } from 'components/publisher/theme/PublisherFooter/PublisherFooter';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useNewReviewValidator } from 'hooks/validators';
import { StatusSection } from './StatusSection';
import { ProjectedDeliverySection } from './ProjectedDeliverySection';
import { ContentPreviewSection } from './ContentPreviewSection';
import styles from './review.module.css';
import { ReviewErrorsList } from './ReviewErrorList';
import errorsMap from './errorsMap';

export const NewReview: React.FC<RouteComponentProps> = () => {
  const {
    permissions: { audienceAccess },
  } = useSettings();

  const { isValid, errors, validate } = useNewReviewValidator();

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.reviewContainer}>
      <Helmet
        bodyAttributes={{
          class: background.gray00,
        }}
      />
      <Box maxWidth={1150} margin={[0, 'auto']}>
        <Box className={styles.heading}>
          <PageHeading>Review</PageHeading>
        </Box>
        <Box style={{ display: 'flex' }} margin={[32, 0, 0, 0]}>
          <div className={styles.mainSectionContainer}>
            <ReviewErrorsList errors={errors} messagesMap={errorsMap} />
            <StatusSection isReadyToPublish={isValid} />
            {audienceAccess && <ProjectedDeliverySection />}
          </div>
          <div className={styles.previewSectionContainer}>
            <ContentPreviewSection />
          </div>
          <PublisherFooter />
        </Box>
      </Box>
    </div>
  );
};
