import { User } from 'models/user';
import { Program } from 'models/program';
import { Permissions } from 'models/permissions';
import { useAttempts } from './attempts';

declare const pendo: { initialize: (options: Record<string, unknown>) => void };
const SKIP = process.env.NODE_ENV === 'development';

export function usePendo(
  user?: User,
  program?: Program,
  permissions?: Permissions
): void {
  useAttempts(
    () => {
      if (SKIP || !user || !program || !permissions || !pendo.initialize)
        return false;
      pendo.initialize({
        visitor: {
          id: user.id,
          email: `${user.email}`,
          full_name:
            `${user.preferredName}` || `${user.firstName} ${user.lastName}`,
          role: user.role,
          role_v2: permissions.roleV2.join(', '),
        },
        account: {
          id: program.id,
          name: program.name,
          org_code: program.brandSlug,
        },
      });
      return true;
    },
    {
      maxAttempts: 20,
    }
  );
}
