import React, { FC } from 'react';
import { DateTime } from 'luxon';
import { Button } from 'DesignSystem/Form';
import { ClickDropdown } from 'shared/ClickDropdown';
import { Calendar } from 'shared/DateInput/Calendar';
import { Icon } from 'shared/Icon';
import styles from './new-date-range.module.css';

export type DateDropdownProps = {
  date: DateTime;
  onDateChange: (newDate?: DateTime) => void;
  dateFormat: string;
  maxDate?: DateTime;
  minDate?: DateTime;
};

export const DateDropdown: FC<DateDropdownProps> = ({
  date,
  dateFormat,
  onDateChange,
  maxDate,
  minDate,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDateChange = (newDate?: DateTime) => {
    onDateChange(newDate);
    setIsOpen(false);
  };

  return (
    <ClickDropdown
      onOpen={() => setIsOpen(true)}
      isOpen={isOpen}
      dropdownRenderProp={
        <div className={styles.filterDropdownContainer}>
          <Calendar
            onChange={handleDateChange}
            selectedDate={date}
            maxDate={maxDate}
            minDate={minDate}
            disableAutoSelectOnCalendarChange
          />
        </div>
      }
    >
      <Button
        className={styles.dateButton}
        input
        icon={<Icon iconName="calendar-o" />}
        label={date.toFormat(dateFormat)}
      />
    </ClickDropdown>
  );
};
