import * as React from 'react';
import videojs from 'video.js';
import { Video, CaptionLocaleUrl } from 'models/video';
import { useUniqueId } from 'hooks/useUniqueId';

/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const UploadedVideoPlayer: React.FC<{
  video: Video;
  onResize?: () => void;
}> = (props) => {
  const { video, onResize } = props;
  const id = useUniqueId();

  React.useEffect(() => {
    const videoPlayer =
      videojs.getPlayer(id) ||
      videojs(id, {
        fluid: true,
        html5: {
          vhs: {
            enableLowInitialPlaylist: true,
          },
        },
      });
    const captions: Array<CaptionLocaleUrl> = video.captionUrls || [];
    let defaultCaptionLanguage: string | undefined;
    if (video.defaultShowCaptions) {
      const captionsByLanguage = captions.reduce<
        Record<string, CaptionLocaleUrl>
      >(
        (memo, caption) => ({
          default: caption,
          ...memo,
          [caption.language]: caption,
        }),
        {}
      );
      const preferredLanguage = navigator.language.split('-')[0];
      const videoLanguage = video.locale;
      defaultCaptionLanguage = (
        captionsByLanguage[preferredLanguage] ||
        (videoLanguage && captionsByLanguage[videoLanguage]) ||
        captionsByLanguage.default ||
        {}
      ).language;
    }

    // check if the browser is IE
    if (
      /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
      navigator.userAgent.indexOf('Trident/') > -1
    ) {
      videoPlayer.src({
        src: `${video.mp4Url}?${video.secureQueryParams}`,
        type: 'video/mp4',
      });
    } else {
      videoPlayer.src({
        src: `${video.url}?${video.secureQueryParams}`,
        type: 'application/x-mpegURL',
      });
    }

    videoPlayer.poster(video.previewImageUrl || '');

    // have every request pass the signature
    (videojs as any).Vhs.xhr.beforeRequest = (options: any) => {
      const params = video.secureQueryParams;
      const result = { ...options };
      if (options.uri.indexOf(params) < 0) {
        result.uri = `${options.uri}?${params}`;
      }
      return result;
    };

    videoPlayer.ready(() => {
      captions.forEach((caption) => {
        const isDefaultCaption = defaultCaptionLanguage === caption.language;

        videoPlayer.addRemoteTextTrack(
          {
            kind: 'captions',
            language: caption.language,
            label: caption.label,
            src: `${caption.url}?${video.secureQueryParams}`,
            default: isDefaultCaption,
            mode: isDefaultCaption ? 'showing' : 'disabled',
          },
          false
        );
      });

      const trackSettings = (videoPlayer as any).textTrackSettings;
      trackSettings.setValues({
        color: video.captionsColor,
        backgroundColor: video.captionsColorBackground,
        fontPercent: parseFloat(
          ((video.captionsFontSize ?? 100) / 100).toFixed(2)
        ),
      });
      trackSettings.updateDisplay();
    });

    videoPlayer.on('resize', () => onResize?.());

    // TODO:
    // This may not be necessary any more.  It may have been a bug in the particular version
    // of video.js we were using in bossanova.  It doesn't play nicely with typescript anyway.

    // this requires a bit of explanation --
    // the playlist index.m3u8 refers to a caption playlist caption-{}.vtt
    // after ready but before play, that playlist is built and added
    // however, it is always malformed, with cue times being NaN.
    // the file nor the videojs webvtt parser is at issue, something else is going on
    // this removes all caption data added through the playlist, relying on the fact that
    // they are of kind "subtitles"
    // instead, the captions manually added through the 'ready' callback will be used

    videoPlayer.on('play', () => {
      const trackList = videoPlayer.remoteTextTracks();
      for (let i = 0; i < trackList.length; i += 1) {
        const track = trackList[i];
        if (track.kind === 'subtitles') {
          videoPlayer.removeRemoteTextTrack(
            (track as unknown) as HTMLTrackElement
          );
        }
      }
    });

    // Prevent clicks from bubbling past the video player
    const stopClickPropagation = (event: MouseEvent) => event.stopPropagation();
    videoPlayer.on('click', stopClickPropagation);
    return () => videoPlayer.off('click', stopClickPropagation);
  }, [
    id,
    onResize,
    video.captionUrls,
    video.defaultShowCaptions,
    video.locale,
    video.captionsColor,
    video.captionsColorBackground,
    video.captionsFontSize,
    video.mp4Url,
    video.previewImageUrl,
    video.secureQueryParams,
    video.url,
  ]);

  if (!video) {
    return <></>;
  }

  return (
    <div>
      <video
        id={id}
        className="video-js vjs-big-play-centered"
        style={{ width: '100%', height: '100%' }}
        poster={video.previewImageUrl}
        controls
      />
    </div>
  );
};
