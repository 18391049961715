import * as React from 'react';
import cx from 'classnames';
import { useCanvasStylePanel } from 'contexts/publisher/compose/canvas-style-panel';
import { useOneOf } from 'hooks/useOneOf';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { Alert, AlertType } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { GlobalTab } from './GlobalTab';
import { SelectedTab } from './SelectedTab';
import styles from './styles.module.css';

export const Tabs: React.FC<{ tab: ReturnType<typeof useOneOf> }> = ({
  tab,
}) => {
  const [shouldOpenThis, setShouldOpenThis] = React.useState(false);
  const { selected } = React.useContext(BlocksEditorContext);
  const newPanel = useCanvasStylePanel();

  const { canEdit, errors, isLoading: isEditableLoading } = React.useContext(
    DesignPermissionContext
  );

  React.useLayoutEffect(() => {
    if (selected) setShouldOpenThis(true);
  }, [selected]);

  React.useLayoutEffect(() => {
    if (tab.selected.isActive && !selected) {
      tab.global.activate();
    }
  }, [newPanel, selected, tab.selected.isActive, tab.global]);

  React.useLayoutEffect(() => {
    if (tab.global.isActive && shouldOpenThis && selected) {
      tab.selected.activate();
    }
  }, [selected, shouldOpenThis, tab.selected, tab.global.isActive]);

  const openGlobal = React.useCallback(() => {
    setShouldOpenThis(false);
    tab.global.activate();
  }, [tab.global]);

  const openThis = React.useCallback(() => {
    setShouldOpenThis(true);
    tab.selected.activate();
  }, [tab.selected]);

  return (
    <div id="publisher-global-styles-sidebar">
      <div className={cx(styles.sidebarPadding, styles.sidebarMarginOld)}>
        {!isEditableLoading && !canEdit && (
          <div className={styles.sidebarMarginOld}>
            <Alert
              type={AlertType.error}
              title="Editing unavailable"
              message={errors.map((error) => (
                <div>{error}</div>
              ))}
              bgColor="red"
              enableIcon
              icon={<MAIcon name="warning" />}
            />
          </div>
        )}
        {tab.global.isActive && <strong className={styles.title}>Style</strong>}
        <div className={styles.tabs}>
          <button
            type="button"
            onClick={openGlobal}
            data-test="global-design-tab"
            className={cx({ [styles.active]: tab.global.isActive })}
          >
            Global
          </button>
          {selected && (
            <button
              type="button"
              onClick={openThis}
              data-test="selected-design-tab"
              className={cx({ [styles.active]: tab.selected.isActive })}
            >
              Selected
            </button>
          )}
        </div>
      </div>
      {tab.global.isActive ? <GlobalTab disabled={!canEdit} /> : null}
      {tab.selected.isActive && selected ? (
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        <SelectedTab {...selected} />
      ) : null}
    </div>
  );
};
