import * as React from 'react';
import { useUsersInfiniteQuery } from 'hooks/user';
import { User } from 'models/user';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { FocusDropdown } from 'shared/FocusDropdown';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { Dropdown } from './Dropdown';
import styles from './users-select.module.css';

export type PropsType = {
  selectedUsers: Array<User>;
  onSelectedUsersChange: (users: Array<User>) => void;
  maxDropdownHeight?: number;
  placeholder?: string;
  name?: string | string[];
  upward?: boolean;
  disabled?: boolean;
};

export const UsersSelect: React.FC<PropsType> = (props) => {
  const {
    selectedUsers,
    onSelectedUsersChange,
    maxDropdownHeight = 270,
    placeholder,
    upward = false,
    disabled,
  } = props;

  const [searchText, setSearchText] = React.useState('');

  const { id: programId } = useProgram();
  const {
    isLoading,
    data: values,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useUsersInfiniteQuery({
    programId,
    search: useDebounce(searchText),
  });

  const selectedIds = selectedUsers.map((user) => String(user.id));
  const selectedValues = selectedUsers.map(
    (user) => `${user?.firstName} (${user?.lastName})`
  );

  const users = [...values];
  const userIds: Array<number> = values.map((item) => item.id);
  const uniqueIds = new Set([...userIds]);
  selectedUsers.forEach((value) => {
    if (value.id && !uniqueIds.has(value.id)) {
      users.push(value);
    }
  });

  function handleRemove(index: number) {
    const newSelectedAudiences = [...selectedUsers];
    newSelectedAudiences.splice(index, 1);
    onSelectedUsersChange(newSelectedAudiences);
  }

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedUsers = ids
      .map((id) => users.find((user) => String(user.id) === id))
      .filter((item) => !!item);
    onSelectedUsersChange(newSelectedUsers as Array<User>);
  }

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={users}
        selectedIds={selectedIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        maxHeight={maxDropdownHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        upward={upward}
        disabled={disabled}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={selectedValues}
            onRemoveSelectedValueAt={handleRemove}
            placeholder={placeholder}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};
