import * as React from 'react';
import { Facet, condenseFacet } from 'models/facet';
import styles from './facets.module.css';

type PropsType = {
  facet: Facet;
  label: string;
  emptyExpression?: boolean;
  totalCount: number;
};

export const FacetDetails: React.FC<PropsType> = (props) => {
  const { label, facet: rawFacet, emptyExpression, totalCount } = props;

  const facet = condenseFacet(rawFacet, totalCount);

  return (
    <>
      {emptyExpression ? (
        <div>
          <div className={styles.facetName}>{label}</div>
          <div className={styles.value}>
            <span>Any</span>
            <span>0</span>
          </div>
        </div>
      ) : (
        facet.facetData.length > 0 && (
          <div>
            <div className={styles.facetName}>{label}</div>
            {facet.facetData.map((value) => (
              <div className={styles.value} key={value.value}>
                <span>{value.value}</span>
                <span>{value.count}</span>
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
};
