import React from 'react';
import { Redirect, Router, RouteComponentProps } from '@reach/router';
import { NotFoundScreen } from 'shared/NotFoundScreen';
import { AuthenticatedInProgram } from './AuthenticatedInProgram';
import { ProgramAccess } from './ProgramAccess';
import { Main } from './Main';
import { Configuration } from './Configuration';
import { Editors } from './Editors';

export const Program: React.FC<RouteComponentProps<{
  programId: string;
  '*': string; // This is how Reach sends the wildcard part of the route.
}>> = ({ programId = '0', '*': subPath = '', navigate }) => {
  const programIdNumber = parseInt(programId, 10);
  if (Number.isNaN(programIdNumber)) {
    return <NotFoundScreen />;
  }
  return (
    <AuthenticatedInProgram programId={programId}>
      <ProgramAccess
        navigate={navigate}
        programId={programIdNumber}
        subPath={subPath}
      >
        <Router>
          <Main path="app/*" />
          <Configuration path="configure/*" />
          <Editors path="edit/*" />
          <Redirect from="*" to="app" noThrow default />
        </Router>
      </ProgramAccess>
    </AuthenticatedInProgram>
  );
};
