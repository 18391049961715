import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Clock, TextStyle2Word } from 'shared/icons';
import { SectionCard } from '../../SectionCard';
import styles from './content-stats.module.css';

export const ContextStats: React.FC<{
  wordCount: number | undefined;
  readTimeInSeconds: number;
}> = ({ wordCount, readTimeInSeconds }) => {
  const readTimeInMinutes = (seconds: number) => {
    return (seconds / 60).toFixed(1);
  };

  return (
    <Box className={styles.StatsContainer} margin={[23, 0, 0, 0]}>
      {wordCount !== undefined && (
        <div className={styles.WordCountStatsBox}>
          <SectionCard padding={[8, 8]} bgColor="gray">
            <div style={{ display: 'flex' }}>
              <Box margin={[15, 0, 0, 0]}>
                <TextStyle2Word />
              </Box>
              <Box margin={[1, 0, 0, 9]}>
                <div>Word Count</div>
                <div className={styles.StatValue}>{wordCount}</div>
              </Box>
            </div>
          </SectionCard>
        </div>
      )}
      <div className={styles.ReadTimeStatsBox}>
        <SectionCard padding={[9, 5]} bgColor="gray">
          <div style={{ display: 'flex' }}>
            <Box margin={[11, 6, 0, 0]}>
              <Clock height={19} width={19} />
            </Box>
            <Box>
              <div>Read Time </div>
              <div className={styles.StatValue}>
                {readTimeInMinutes(readTimeInSeconds)} min
              </div>
            </Box>
          </div>
        </SectionCard>
      </div>
    </Box>
  );
};
