import * as React from 'react';
import { useQueryStringState } from './useQueryStringState';

type ReturnType = [string, (term: string) => void];

export function cast(value: unknown): string {
  if (typeof value !== 'string') {
    return '';
  }
  return value;
}

export function useSearchBarQueryString(): ReturnType {
  const [searchTerm, navigateSearchTerm] = useQueryStringState({
    key: 'search',
    cast,
  });

  const [inputValue, setInputValue] = React.useState(searchTerm);
  const replace = !!inputValue;

  const setSearchTerm = React.useCallback(
    (term: string) => {
      setInputValue(term);
      navigateSearchTerm(term, { replace });
    },
    [replace, navigateSearchTerm]
  );

  return [inputValue, setSearchTerm];
}
