import * as React from 'react';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Post } from 'models/publisher/post';
import styles from './email.module.css';

export const Email: React.FC<{ post?: Post }> = ({ children, post }) => {
  const maxWidth = post?.styles.emailWidth ?? 600;
  return (
    <div className={styles.email} style={{ maxWidth }}>
      <div className={styles.buttons}>
        <SVGIcon name="PencilBoxOutline" />
        <SVGIcon name="Email" />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
