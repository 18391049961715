import React from 'react';
import { Link } from '@reach/router';
import * as Text from 'DesignSystem/Typography';
import { MetabaseReport, PlaceHolderReport } from 'models/insight/Report';
import { Icon } from 'shared/Icon';
import {
  ReportCardHeaderPanelPropsType,
  ReportItemCardHeader,
} from 'App/Program/Main/Insight/components/ReportsList/ReportItemCardHeader';
import { LikeButton } from 'App/Program/Main/Insight/components/ReportButtons/LikeButton';
import { DeleteBookmarkButton } from 'App/Program/Main/Insight/components/ReportButtons/DeleteBookmarkButton';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import style from './ReportsListStyle.module.css';

// accepts report models with optional params for rendering purposes
export type ReportForCollectionViewProps = (
  | PlaceHolderReport
  | MetabaseReport
) & {
  headerPanelProps?: ReportCardHeaderPanelPropsType; // for views that require a header
  shouldShowLockIcon?: boolean; // shows a lock icon in lower right corner (indicates a private/public report)
  bookmarkId?: number;
  queryString?: string;
};
export type ReportItemPropsType = {
  report: ReportForCollectionViewProps;
  shouldShowLockIcon?: boolean;
  onClick?: (report: ReportForCollectionViewProps) => void;
};

export const ReportItemCard: React.FC<ReportItemPropsType> = ({
  report,
  onClick,
}) => {
  const headerProps = report.headerPanelProps;
  const queryString = report.queryString ? `?${report.queryString}` : '';
  const linkRef = React.useRef<HTMLAnchorElement | null>(null);

  const { id: programId } = useProgram();
  const showReportLikes = !!useFeatureFlagsQuery(
    programId,
    'Studio.Insights.ShowReportLikes'
  ).data?.value;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={style.flexWrapMobileFillItem}
      onClick={() => onClick && onClick(report)}
    >
      {/* upon clicking on a card, navigate to the report page and pass the report as a reach router state */}
      <article className={style.reportCardItem}>
        <Link
          onMouseEnter={() => linkRef.current?.focus()}
          onMouseLeave={() => linkRef.current?.blur()}
          to={`../../report/${report.id}${queryString}`}
          ref={linkRef}
          state={report}
        >
          <header title={report.title ?? ''} className={style.reportCardHeader}>
            {/* This component should only render if there is a schedule author */}
            {headerProps && (
              <ReportItemCardHeader
                title={headerProps.title}
                subtitle={headerProps.subtitle}
                thumbnailInitials={headerProps.thumbnailInitials}
                avatarUrl={headerProps.avatarUrl}
              />
            )}
            <Text.Body bold>{report.title}</Text.Body>
          </header>
          <main
            title={report.description ?? ''}
            className={style.reportCardBody}
          >
            <Text.Body>{report.description}</Text.Body>
          </main>
        </Link>

        <footer className={style.reportCardFooter}>
          {showReportLikes && (
            <LikeButton reportId={report.id} userLiked={report.userLiked} />
          )}
          {report.bookmarkId && (
            <DeleteBookmarkButton
              className={style.reportCardFooterItem}
              title={report.title}
              reportId={report.id}
              bookmarkId={report.bookmarkId}
              isPublic={!report.shouldShowLockIcon}
            />
          )}
          {/* These icons will need separate components later */}
          {report.shouldShowLockIcon && (
            <div className={style.reportCardFooterItemPullRight}>
              <Icon iconName="lock" iconType="FA" title="private" />
            </div>
          )}
        </footer>
      </article>
    </div>
  );
};
