import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Body } from 'DesignSystem/Typography';
import styles from './metrics.module.css';

export const Metric: React.FC<{
  label: string;
  value?: number;
}> = ({ label, value }) => (
  <Box key={label} className={styles.metric}>
    <Body>{label}:</Body>
    <Body semibold>&nbsp;{value}</Body>
    {/* {rate !== undefined && <Body semibold>&nbsp;({rate}%)</Body>} */}
  </Box>
);
