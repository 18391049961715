export type FacetData = {
  value: string;
  count: number;
};

export type Facet = {
  facetName: string;
  facetData: FacetData[];
};

export function condenseFacet(facet: Facet, totalCount: number): Facet {
  const totalWithValues = facet.facetData.reduce(
    (sum, item) => sum + item.count,
    0
  );
  const totalBlank = Math.max(0, totalCount - totalWithValues);

  const facetsWithoutOther = facet.facetData.filter(
    (item) => item.value !== 'OTHER'
  );

  const facetsWithBlank = [...facetsWithoutOther];
  if (totalBlank > 0) {
    facetsWithBlank.push({ value: 'No Value', count: totalBlank });
  }

  const topFacets = [...facetsWithBlank]
    .sort((f1, f2) => f2.count - f1.count)
    .slice(0, 3);
  const totalFromTopFacets = topFacets.reduce(
    (sum, item) => sum + item.count,
    0
  );

  if (totalFromTopFacets < totalCount) {
    topFacets.push({ value: 'Other', count: totalCount - totalFromTopFacets });
  }

  return { ...facet, facetData: [...topFacets] };
}
