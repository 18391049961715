import React from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { Box, FormModal } from 'DesignSystem/Components';
import { Button, FieldInput, Radio } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { VideoFieldData } from 'models/donkey';
import { FetchedCaption } from 'services/api-captions';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { languageDisplayName } from 'utility/language';
import {
  CaptionsSubmittedSuccessProps,
  CaptionsType,
  UpdateCaptions,
  useCaptionSettings,
} from '../../hooks/useCaptionSettings';
import { UploadedCaptionsForm } from './UploadedCaptionsForm';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';
import { CaptionsTranslation } from './CaptionsTranslation';
import { CaptionDeletionModal } from './CaptionDeletionModal';
import { CaptionsVideoPreview } from './CaptionsVideoPreview';
import { CaptionValidationError } from './CaptionValidationError';
import styles from '../video.module.css';

export type CaptionSettingsModalProps = {
  onClose: () => void;
  updateCaptions: UpdateCaptions;
  isCaptionsUpdating: boolean;
  currentCaption?: FetchedCaption;
  fieldData: VideoFieldData;
  isDesignAsset: boolean;
};

export const CaptionSettings: React.FC<CaptionSettingsModalProps> = ({
  onClose,
  updateCaptions,
  isCaptionsUpdating,
  currentCaption,
  fieldData,
  isDesignAsset,
}) => {
  const {
    onFormSubmit: submitForm,
    captionText,
    onCaptionTextChange,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    isDisabled,
    validationErrors,
    isTranscribing,
    isTranslating,
    captionsType,
    setCaptionsType,
    autoTranslateCaptions,
    setAutoTranslateCaptions,
    selectedLanguages,
    setSelectedLanguages,
  } = useCaptionSettings({
    updateCaptions,
    isCaptionsUpdating,
    currentCaption,
    videoId: fieldData?.video_id,
    isDesignAsset,
  });
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

  const {
    isCaptionTranscriptionEnabled,
    isCaptionTranslationEnabled,
    isLoadingFeatureFlags,
  } = useCaptionsJobs();
  const selectUpload = () => {
    setCaptionsType(CaptionsType.UPLOAD);
    setAutoTranslateCaptions(false);
    setSelectedLanguages([]);
  };
  const selectGenerate = () => setCaptionsType(CaptionsType.GENERATE);

  const toggleAutoTranslateCaptions = React.useCallback(() => {
    if (autoTranslateCaptions) {
      setSelectedLanguages([]);
    }

    setAutoTranslateCaptions(!autoTranslateCaptions);
  }, [autoTranslateCaptions, setAutoTranslateCaptions, setSelectedLanguages]);

  let isFormDisabled = isDisabled;
  if (
    captionsType === CaptionsType.GENERATE &&
    (!autoTranslateCaptions || selectedLanguages.length > 0)
  ) {
    isFormDisabled = false;
  }

  const { setFlashMessage } = useFlashMessage();

  const showFlashMessage = React.useCallback(
    (props: CaptionsSubmittedSuccessProps) => {
      if (props.transcribed) {
        setFlashMessage({
          severity: 'progress',
          message: 'Your captions are being processed.',
        });
      } else if (props.uploaded) {
        if (props.edited) {
          setFlashMessage({
            severity: 'info',
            message: 'Caption edits have been saved.',
          });
        } else {
          setFlashMessage({
            severity: 'progress',
            message: 'Your captions are being processed.',
          });
        }
      } else if (props.deleted) {
        setFlashMessage({
          severity: 'info',
          message: 'Your caption file has been deleted.',
        });
      }
    },
    [setFlashMessage]
  );

  const onSubmit = React.useCallback(
    () =>
      submitForm({
        onSuccess: (props: CaptionsSubmittedSuccessProps) => {
          if (isCaptionTranscriptionEnabled) {
            showFlashMessage(props);
          }
          onClose();
        },
      }),
    [isCaptionTranscriptionEnabled, onClose, showFlashMessage, submitForm]
  );

  if (isLoadingFeatureFlags) return null;
  return (
    <FormModal
      modalBodyClassName={
        isCaptionTranscriptionEnabled ? styles.modalBodyWrapper : undefined
      }
      entityText={
        (isCaptionTranscriptionEnabled &&
          (captionText ? 'Edit Captions' : 'Add Captions')) ||
        'Caption Settings'
      }
      actionText=""
      description={
        isCaptionTranscriptionEnabled && !captionText
          ? 'Captions can be edited, translated, and downloaded after processing.'
          : undefined
      }
      submitButton={
        <CaptionSubmitButton
          onSubmit={onSubmit}
          submitLabel={
            isCaptionTranscriptionEnabled && captionText ? 'Save' : 'Continue'
          }
          isDisabled={isFormDisabled}
          isLoading={isCaptionsUpdating || isTranscribing || isTranslating}
        />
      }
      onCancel={onClose}
    >
      <Box
        className={
          isCaptionTranscriptionEnabled
            ? styles.addCaptions
            : styles.captionsSettings
        }
      >
        {isCaptionTranscriptionEnabled && (
          <Flex column className={styles.captionsFormWrapper}>
            {currentCaption ? (
              <>
                <div className={styles.videoPreviewWrapper}>
                  <CaptionsVideoPreview fieldData={fieldData} />
                </div>
                {isDeleting && (
                  <CaptionDeletionModal
                    onCancel={() => setIsDeleting(false)}
                    onDelete={() => {
                      updateCaptions({
                        toRemove: currentCaption.url,
                        onSuccess: () => {
                          setIsDeleting(false);
                          onClose();
                        },
                      });
                    }}
                    language={languageDisplayName(currentCaption.language)}
                    isLoading={isCaptionsUpdating}
                  />
                )}
              </>
            ) : (
              <>
                <Box style={{ display: 'flex' }}>
                  <Radio
                    type="radio"
                    label={
                      <Box margin={[0, 0, 0, -8]}>
                        <Text.Body>Automatically generate captions</Text.Body>
                      </Box>
                    }
                    checked={captionsType === CaptionsType.GENERATE}
                    onSelect={selectGenerate}
                  />
                </Box>
                {isCaptionTranslationEnabled &&
                  captionsType === CaptionsType.GENERATE && (
                    <>
                      <Box padding={[16, 0, 0, 32]} style={{ display: 'flex' }}>
                        <Radio
                          label={
                            <Box margin={[0, 0, 0, -8]}>
                              <Text.Body>
                                Auto-translate these captions
                              </Text.Body>
                            </Box>
                          }
                          checked={autoTranslateCaptions}
                          onChange={toggleAutoTranslateCaptions}
                        />
                      </Box>
                      {autoTranslateCaptions && (
                        <Box padding={[0, 0, 0, 32]}>
                          <CaptionsTranslation
                            disabled={!autoTranslateCaptions}
                            selectedLanguages={selectedLanguages}
                            setSelectedLanguages={setSelectedLanguages}
                          />
                        </Box>
                      )}
                    </>
                  )}

                <Box
                  padding={[16, 0]}
                  className={styles.uploadCaptionsWrapper}
                  style={{ display: 'flex' }}
                >
                  <Radio
                    type="radio"
                    label={
                      <Flex className={styles.uploadCaptionsLabel} column start>
                        <Text.Body>Upload your own caption file(s)</Text.Body>
                        <Text.Caption>
                          You can select multiple files at once for upload
                        </Text.Caption>
                      </Flex>
                    }
                    checked={captionsType === CaptionsType.UPLOAD}
                    onSelect={selectUpload}
                  />
                </Box>
              </>
            )}
            <CaptionValidationError validationErrors={validationErrors} />
            {(captionsType === CaptionsType.UPLOAD || captionText) && (
              <UploadedCaptionsForm
                captionText={captionText}
                onCaptionTextChange={onCaptionTextChange}
                onReplaceClick={onReplaceClick}
                onRemoveClick={() => setIsDeleting(true)}
                onUploadClick={onUploadClick}
                fileInput={fileInput}
                errorMessage={errorMessage}
                editorDisabled={isCaptionsUpdating}
                dropzoneDisabled={captionsType === CaptionsType.GENERATE}
                canRemove={!!currentCaption}
              />
            )}
          </Flex>
        )}
        {!isCaptionTranscriptionEnabled && (
          <FieldInput>
            <UploadedCaptionsForm
              captionText={captionText}
              validationErrors={validationErrors}
              onReplaceClick={onReplaceClick}
              onRemoveClick={onRemoveClick}
              onUploadClick={onUploadClick}
              fileInput={fileInput}
              errorMessage={errorMessage}
              canRemove={!!captionText}
            />
          </FieldInput>
        )}
      </Box>
    </FormModal>
  );
};

const CaptionSubmitButton: React.FC<{
  onSubmit: () => void;
  submitLabel: string;
  isDisabled: boolean;
  isLoading: boolean;
}> = ({ onSubmit, submitLabel, isDisabled, isLoading }) => {
  const label = isLoading ? (
    <>
      {submitLabel}&nbsp;
      <LoadingSpinner size="xsmall" />
    </>
  ) : (
    submitLabel
  );

  return (
    <Button
      disabled={isDisabled || isLoading}
      onClick={onSubmit}
      label={label}
    />
  );
};
