import React from 'react';
import { ImageData } from 'models/image';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useChangingImgSrc } from 'hooks/useChangingImgSrc';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { LoadingSpinner } from 'shared/LoadingSpinner';

export const Thumbnail: React.FC<{
  item: ImageData;
  onRemove: () => void;
  onEdit: () => void;
  canRemove?: boolean;
}> = ({ item: image, onRemove, onEdit, canRemove = true }) => {
  const imageUrl = useChangingImgSrc(image.url);
  return (
    <div
      style={{
        backgroundImage: `url("${imageUrl}")`,
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        borderRadius: '4px',
        cursor: 'move',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: '10px',
        fontSize: '10px',
        fontWeight: 'bold',
        opacity: image.processed ? '100%' : '50%',
      }}
      title={image.altText}
    >
      <Actions onEdit={onEdit} onRemove={onRemove} canRemove={canRemove} />
      {!image.processed && <Loading />}
    </div>
  );
};

function Actions({
  onEdit,
  onRemove,
  canRemove,
}: {
  onRemove(): void;
  onEdit(): void;
  canRemove: boolean;
}) {
  return (
    <Box relative>
      <Box
        absolute
        top={-10}
        right={-10}
        style={{
          borderRadius: '0 0 0 5px',
          backgroundColor: 'rgba(var(--color-brandFullRGB), 0.5)',
        }}
      >
        <Flex end>
          <Button
            onClick={onEdit}
            label={<Icon name="Edit" size={15} fill="var(--color-gray00)" />}
          />
          <Space size={6} />
          {canRemove && (
            <Button
              onClick={onRemove}
              label={<Icon name="Close" size={12} fill="var(--color-gray00)" />}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
}

function Loading() {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '17px',
      }}
    >
      <LoadingSpinner size="medium" />
    </div>
  );
}

function Button({
  onClick,
  label,
}: {
  onClick: () => void;
  label: React.ReactNode;
}) {
  return (
    <button
      style={{
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
      }}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  );
}
