import {
  UseMutateAsyncFunction,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  AliasCollectionData,
  AliasData,
  createAuthorAlias,
  updateAuthorAlias,
  fetchAuthorAliases,
  fetchById,
  fetchByIds,
  FetchProps,
  NewAliasData,
  fetchAuthorAliasesPage,
  bulkUnarchiveAuthorAliases,
  bulkArchiveAuthorAliases,
  archiveAuthorAlias,
  unarchiveAuthorAlias,
  createAuthorAliasOnly,
  bulkAffectedAuthorAliases,
  BulkAffectedAuthorAliasesOptions,
  AuthorAliasBulkAffected,
} from 'services/api-author-alias';
import { useProgram } from 'contexts/program';
import { AuthorAlias } from 'models/author-alias';
import { PaginationData } from 'services/common';
import {
  AuthorAliasBulkActionFilters,
  BulkSelection,
  // nextPageToFetch,
  InfiniteQueryResponse,
  MutationOptions,
  QueryError,
  QueryResponse,
  useInfiniteApiQuery,
} from './common';

export const useAuthorAliasQuery = (
  programId: number,
  id: number
): QueryResponse<AliasData> => {
  const { isLoading, error, data } = useQuery<AliasData, Error>({
    queryKey: ['author-alias', programId, id],
    queryFn: () => fetchById(id, programId),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useAuthorAliasByIdsQuery = (
  programId: number,
  ids: number[]
): QueryResponse<Array<AuthorAlias>> => {
  const { isLoading, error, data } = useQuery<AliasCollectionData, Error>(
    ['author-aliases', programId, ids], // Cache key, must be distinct for different query params
    () => fetchByIds(ids, programId),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data || [],
  };
};

export const useAuthorAliasesWithUsersInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<AuthorAlias> => {
  const { programId, pageSize = 20, search } = props;
  const key = ['author-aliases-users-infinite', JSON.stringify(props)];
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AliasCollectionData, Error>(
    key,
    async ({ pageParam }: { pageParam?: { page: number; type: string } }) => {
      return fetchAuthorAliases(
        programId,
        pageParam?.page,
        pageSize,
        pageParam?.type,
        search
      );
    },
    {
      getNextPageParam: (lastGroup) => {
        if (lastGroup.data.length === 0 && lastGroup.type === 'user') {
          return undefined;
        }

        if (lastGroup.data.length === 0 && lastGroup.type !== 'user') {
          return { page: 1, type: 'user' };
        }

        return {
          page: (lastGroup.meta.pageNumber as number) + 1,
          type: lastGroup.type,
        };
      },
    }
  );

  const batchData = data?.pages.map((batch) => (batch ? batch.data : []));

  const flatData = batchData && batchData.flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};

interface AuthorAliasMetadata extends PaginationData {
  totalArchivableRecords?: number;
}

export const useAuthorAliasesInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<AliasData, AuthorAliasMetadata> => {
  const { programId, search, statuses, userIds, types, pageSize } = props;
  const key = 'author-aliases-infinite';
  const {
    isLoading,
    errorMessage,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    meta,
  } = useInfiniteApiQuery(key, fetchAuthorAliasesPage, {
    programId,
    pageSize,
    search,
    statuses,
    userIds,
    types,
  });

  return {
    isLoading,
    errorMessage,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: data || [],
    meta,
  };
};

export const useAuthorAliasBulkArchive = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  archive: (
    bulkSelection: BulkSelection,
    filterConfig: AuthorAliasBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();
  const archive = (
    bulkSelection: BulkSelection,
    filterConfig: AuthorAliasBulkActionFilters
  ) => {
    const request = bulkArchiveAuthorAliases(
      programId,
      bulkSelection,
      filterConfig
    );

    request
      .then(() => {
        queryClient.invalidateQueries(['author-aliases-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((error) => onError?.(error));
  };
  return {
    archive,
  };
};

export const useAuthorAliasBulkUnarchive = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  unArchive: (
    bulkSelection: BulkSelection,
    filterConfig: AuthorAliasBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();
  const unArchive = (
    bulkSelection: BulkSelection,
    filterConfig: AuthorAliasBulkActionFilters
  ) => {
    const request = bulkUnarchiveAuthorAliases(
      programId,
      bulkSelection,
      filterConfig
    );

    request
      .then(() => {
        queryClient.invalidateQueries(['author-aliases-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((error) => {
        onError?.(error);
      });
  };
  return {
    unArchive,
  };
};

export const useAuthorAliasBulkAffected = (): {
  isLoadingArchive: boolean;
  isLoadingUnarchive: boolean;
  data: AuthorAliasBulkAffected | undefined;
  fetchToBeUpdated: UseMutateAsyncFunction<
    AuthorAliasBulkAffected,
    QueryError,
    BulkAffectedAuthorAliasesOptions,
    unknown
  >;
} => {
  const { id: programId } = useProgram();
  const { isLoading, mutateAsync, data, variables } = useMutation<
    AuthorAliasBulkAffected,
    QueryError,
    BulkAffectedAuthorAliasesOptions
  >(bulkAffectedAuthorAliases(programId));

  const isLoadingArchive =
    isLoading && variables?.performedAction === 'archive';
  const isLoadingUnarchive =
    isLoading && variables?.performedAction === 'unarchive';

  return {
    isLoadingArchive,
    isLoadingUnarchive,
    data,
    fetchToBeUpdated: mutateAsync,
  };
};

export const useCreateAuthorAlias = (
  programId: number,
  { onError, onSuccess }: MutationOptions<AliasData, AliasData> = {}
): { create: (data: NewAliasData) => void } => {
  const create = (data: NewAliasData) => {
    createAuthorAlias(programId, data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        if (onSuccess) onSuccess(response.data as AliasData);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { create };
};

export const useCreateAuthorAliasOnly = (
  programId: number,
  { onError, onSuccess }: MutationOptions<AliasData, string> = {}
): { create: (data: NewAliasData) => void } => {
  const create = (data: NewAliasData) => {
    createAuthorAliasOnly(programId, data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        if (onSuccess) onSuccess(response.data as AliasData);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { create };
};

export const useArchiveAuthorAlias = ({
  onSuccess,
  onError,
}: MutationOptions<AliasData, Error> = {}): {
  archive: (aliasId: number) => void;
} => {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const archive = (aliasId: number) => {
    archiveAuthorAlias(programId, aliasId)
      .then((alias) => {
        queryClient.invalidateQueries(['author-aliases-infinite']);
        if (onSuccess) onSuccess(alias);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { archive };
};

export const useUnarchiveAuthorAlias = ({
  onSuccess,
  onError,
}: MutationOptions<AliasData, Error> = {}): {
  unarchive: (aliasId: number) => void;
} => {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const unarchive = (aliasId: number) => {
    unarchiveAuthorAlias(programId, aliasId)
      .then((alias) => {
        queryClient.invalidateQueries(['author-aliases-infinite']);
        if (onSuccess) onSuccess(alias);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { unarchive };
};

export const useUpdateAuthorAlias = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  update: (authorAlias: AliasData) => void;
} => {
  const { id: programId } = useProgram();
  const update = (authorAlias: AliasData) => {
    updateAuthorAlias(programId, authorAlias)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};
