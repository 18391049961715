import React, { FC } from 'react';
import { Button, Icon } from '@socialchorus/shared-ui-components';
import * as Text from 'DesignSystem/Typography';
import { FeedPreview } from 'models/feed-preview';
import styles from './styles.module.css';
import { useTopicFormCtx } from '../../../../context';
import { HighlightableContainer } from '../../components/HighlightableContainer';
import { PostsFeed } from './Post';
import { ModalType } from '../../../../context/modals';

interface PinnedPostsDisplayProps {
  contents: FeedPreview[];
}

export const PinnedPostsDisplay: FC<PinnedPostsDisplayProps> = ({
  contents,
}) => {
  const {
    state: { activeSidebar },
    toggleSidebar,
    toggleModal,
  } = useTopicFormCtx();

  const handleEditPinnedPosts = () => {
    toggleSidebar('editPinnedPosts');
  };

  const openPostsModal = () => {
    toggleModal({ type: ModalType.AddPinnedPosts });
  };

  return (
    <HighlightableContainer
      className={styles.PinnedPosts__Container}
      hoverLabel="Pinned Posts"
      active={activeSidebar === 'editPinnedPosts'}
      editIcon={!!contents.length}
    >
      {contents.length === 0 ? (
        <div className={styles.PinnedPosts__Empty__Content}>
          <div className={styles.PinnedPosts__Empty__TextContainer}>
            <Text.Heading semibold>Pinned Posts</Text.Heading>
            <Text.Body>
              <span className={styles.PinnedPosts__Empty__Description}>
                There are no pinned posts in this topic yet
              </span>
            </Text.Body>
          </div>
          <Button
            label="Pinned Posts"
            leftIcon={<Icon>add</Icon>}
            variant="primary"
            onClick={openPostsModal}
          />
        </div>
      ) : (
        <>
          <div className={styles.pinnedPostsLabel}>{`${
            contents.length
          } Pinned Post${contents.length === 1 ? '' : 's'}`}</div>
          <PostsFeed contents={contents} pinned />
          <button
            type="button"
            className={styles.editPinnedPosts_WrapperButton}
            onClick={handleEditPinnedPosts}
            aria-label="Edit pinned posts"
          />
        </>
      )}
    </HighlightableContainer>
  );
};
