import React, { CSSProperties } from 'react';
import { Button } from 'DesignSystem/Form';
import { FileUploadButton } from 'shared/FileUploadButton';
import { useProgramIdState } from 'contexts/program';
import { ImageData } from 'models/image';
import { useImageUploader } from 'hooks/useImageUploader';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Text } from 'DesignSystem/Typography';
import styles from './create.module.css';

type ImageUploadInputProps = {
  imageSrc: string;
  setImageSrc: (src: string) => void;
  // eslint-disable-next-line react/require-default-props
  fileRemoveHandler?: () => void;
  imageAlt: string;
  imagePreviewStyle?: CSSProperties;
  isDefaultImage?: boolean;
};

export default function ImageUploadInput({
  imageSrc,
  setImageSrc,
  fileRemoveHandler = () => setImageSrc(''),
  imageAlt,
  imagePreviewStyle = {},
  isDefaultImage = false,
}: ImageUploadInputProps): JSX.Element {
  const [programId] = useProgramIdState();

  const onIconUpload = React.useCallback(
    (data: ImageData) => {
      if (data.url) {
        setImageSrc(data.url);
      }
    },
    [setImageSrc]
  );

  const iconUploader = useImageUploader({ onUpload: onIconUpload, programId });

  const handleFileSelect = React.useCallback(
    (newFile: File) => {
      iconUploader.update(newFile);
    },
    [iconUploader]
  );

  const handleFileRemove = React.useCallback(() => {
    fileRemoveHandler();
    iconUploader.remove();
  }, [fileRemoveHandler, iconUploader]);

  const hasUploadError = iconUploader.errors.length > 0;
  return (
    <>
      {imageSrc && !iconUploader.isUploading && !hasUploadError && (
        <img
          className={styles.imagePreview}
          style={imagePreviewStyle}
          src={imageSrc}
          alt={imageAlt}
        />
      )}
      {hasUploadError && (
        <Text className={{ Block: true }}>
          Oops! Something went wrong while uploading the image!
        </Text>
      )}
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        {iconUploader.isUploading && !hasUploadError ? (
          <LoadingSpinner />
        ) : (
          <>
            <FileUploadButton
              accept="image/*"
              type="secondary"
              onFileSelect={handleFileSelect}
              shouldClear
              className={styles.changeButton}
            >
              Change
            </FileUploadButton>
            <Button
              className={styles.removeButton}
              onClick={handleFileRemove}
              label="Remove"
              disabled={!imageSrc || isDefaultImage}
            />
          </>
        )}
      </div>
    </>
  );
}

const defaultProps = {
  imagePreviewStyle: {},
};

ImageUploadInput.defaultProps = defaultProps;
