import React, { useCallback, useEffect } from 'react';
import { ImageData } from 'models/image';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useContentImageUploader } from 'hooks/useContentImage';
import { useFlashMessage } from 'contexts/flasher';
import styles from './collection.module.css';

type Uploader<T> = {
  onUpload: (data: ImageData) => void;
  data: T;
};

type FileData = { id: string; file: File };
type UrlData = string;

export const FileUploader: React.FC<Uploader<FileData>> = ({
  data,
  onUpload,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const uploadErrorHandler = useCallback(
    (e, file) => {
      let errorMessage = e;
      if (file && file.name) {
        errorMessage = `${file.name}: ${e}`;
      }
      setFlashMessage({
        severity: 'error',
        message: errorMessage,
      });
    },
    [setFlashMessage]
  );

  const { uploadFile, isUploading } = useContentImageUploader({
    onUpload,
    onError: uploadErrorHandler,
  });

  useEffect(() => {
    uploadFile(data.file);
    // triggering a upload off of a uploadFile state change will create an infinite loop of uploads
    // since passing data to uploadFile will change state that it is dependant on
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {isUploading && (
        <div
          className={styles.loading}
          data-test="block-modal-images-collection-uploader-spinner"
        >
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export const UrlUploader: React.FC<Uploader<UrlData>> = ({
  data,
  onUpload,
}) => {
  const { uploadUrl, isUploading } = useContentImageUploader({ onUpload });

  useEffect(() => {
    uploadUrl(data);
    // triggering a upload off of a uploadUrl state change will create an infinite loop of uploads
    // since passing data to uploadUrl will change state that it is dependant on
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {isUploading && (
        <div
          className={styles.loading}
          data-test="block-modal-images-collection-uploader-spinner"
        >
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};
