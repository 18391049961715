import * as React from 'react';
import { ImageData, isImageDataArray, MAX_GALLERY_SIZE } from 'models/image';
import { FileDropZone } from 'shared/FileDropZone';
import { useBulkUploader } from 'hooks/useBulkUploader';
import { UpdateOpts } from '../../../useFieldForm';
import { Source } from '../../shared/SourceMenu';

export function useDropZone(props: {
  images: Array<ImageData>;
  onRemove: (index: number) => void;
  onSetCurrent: (index: number) => void;
  replaceItems: (image: ImageData[], opts?: UpdateOpts) => void;
  onDrop: (image: Array<ImageData>) => void;
  onReorder: (image: Array<ImageData>) => void;
  onClearAll: () => void;
  onCreate: (source?: Source) => void;
}): {
  onFileSelect(file: File): void;
  handleUpload(files: File[] | ImageData[] | DataTransferItemList): void;
  uploadingFiles: ReturnType<typeof useBulkUploader>['uploadingFiles'];
  uploadingUrls: ReturnType<typeof useBulkUploader>['uploadingUrls'];
  onFileUpload: ReturnType<typeof useBulkUploader>['onFileUpload'];
  onUrlUpload: ReturnType<typeof useBulkUploader>['onUrlUpload'];
  hasPendingUploads: ReturnType<typeof useBulkUploader>['hasPendingUploads'];
} {
  const {
    handleFileUpload,
    handleUrlUpload,
    uploadingFiles,
    uploadingUrls,
    onFileUpload,
    onUrlUpload,
    hasPendingUploads,
  } = useBulkUploader({
    length: props.images.length,
    onDrop: props.onDrop,
    saveDisabled: false,
    enableSave: () => {},
    disableSave: () => {},
    maxSize: MAX_GALLERY_SIZE,
  });

  const onImageCreate = React.useCallback(
    (data: ImageData[] | File[] | DataTransferItemList) => {
      if (isImageDataArray(data)) {
        handleUrlUpload(data);
      } else if (data instanceof DataTransferItemList) {
        const files: File[] = [];
        for (let i = 0; i < data.length; i += 1) {
          const file = data[i].getAsFile();
          if (file) files.push(file);
        }
        handleFileUpload(files);
      } else {
        handleFileUpload(data);
      }
    },
    [handleFileUpload, handleUrlUpload]
  );

  const onFileSelect = React.useCallback(
    (file: File) => handleFileUpload([file]),
    [handleFileUpload]
  );
  const handleUpload = onImageCreate;
  return {
    onFileSelect,
    handleUpload,
    uploadingFiles,
    uploadingUrls,
    onFileUpload,
    onUrlUpload,
    hasPendingUploads,
  };
}

export const DropZone: React.FC<{
  onFileSelect: (file: File) => void;
  handleUpload: (files: File[] | ImageData[] | DataTransferItemList) => void;
}> = ({ onFileSelect, handleUpload, children }) => {
  return (
    <div style={{ position: 'relative' }}>
      <FileDropZone
        withButton={false}
        accept="image/*"
        onFileSelect={onFileSelect}
        handleUpload={handleUpload}
      >
        {children}
      </FileDropZone>
    </div>
  );
};
