import { useQuery } from 'react-query';
import {
  fetchImagesAvailability,
  onImagesAvailabilitySuccess,
  ServerImagesAvailabilityData,
} from 'services/api-assets';
import { QueryError, QueryResponse } from './common';

function fetch(urls: Array<string>, onSuccess?: onImagesAvailabilitySuccess) {
  if (urls.length) {
    return fetchImagesAvailability(urls, onSuccess);
  }

  return undefined;
}

export const useImagesAvailabilityQuery = (
  urls: Array<string>,
  onSuccess?: onImagesAvailabilitySuccess
): QueryResponse<ServerImagesAvailabilityData> => {
  const { isLoading, error, data } = useQuery<
    ServerImagesAvailabilityData | undefined,
    QueryError
  >(['images-availability=fetch', urls], () => fetch(urls, onSuccess), {
    refetchOnWindowFocus: false,
    enabled: !!urls,
    retry: false,
  });

  return {
    isLoading: isLoading && urls.length > 0,
    errorMessage:
      data?.status === 'unavailable' && urls.length === 0
        ? 'We are unable to access the image for this link due to the link’s settings. Please upload an image or try a different link.'
        : error?.message,
    data: urls.length === 0 ? undefined : data,
  };
};
