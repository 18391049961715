import { Settings } from 'models/publisher/settings';
import { useState } from 'react';
import { useBasicValidator, ValidatorsType } from './useBasicValidator';

const ERRORS = {
  NO_TOPICLESS_WITHOUT_CHANNEL:
    'This content cannot be published without at least one channel selected. Please select a channel, or add a topic for this content.',
  MESSAGE_SUBJECT_LENGTH: 'Messages preview text is too long',
};

type ValidatorType = {
  settings: Settings;
};

// TODO: This could be replaced by the usePublisherValidator
const validators: ValidatorsType<ValidatorType> = {
  no_topicless_without_channel: ({ settings }) =>
    settings.audiences.length > 0 &&
    settings.contentTopics.length === 0 &&
    !settings.deliveryChannels.email &&
    !settings.deliveryChannels.assistant &&
    !settings.deliveryChannels.push &&
    settings.includeInForYou === false,
};

export const useChannelValidator: () => {
  errors: Array<string>;
  validate: (d: ValidatorType) => boolean;
} = () => {
  const validator = useBasicValidator<ValidatorType>(validators, ERRORS);
  const [errors, setErrors] = useState<Array<string>>([]);

  const validate = (d: ValidatorType) => {
    const res = validator.validate(d);
    setErrors(res.errors);
    return res.errors.length === 0;
  };

  return { errors, validate };
};
