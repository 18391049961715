export function uniqueBy<T>(
  values: T[],
  by: ((value: T) => number) | ((value: T) => string)
): T[] {
  const set: { [key: string]: T } = {};

  values.forEach((value) => {
    set[by(value)] = value;
  });

  return Object.values(set);
}

export function unique(values: string[]): string[] {
  return uniqueBy(values, (value) => value);
}
