import * as React from 'react';
import { Link } from '@reach/router';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useProgram } from 'contexts/program';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { Plus } from 'shared/icons';
import { NavLink } from 'shared/NavLink';
import {
  SimpleBlockConfig,
  useSimpleBlocks,
} from 'hooks/publisher/useSimpleBlocks';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Button } from 'DesignSystem/Form';
import styles from './TemplateSelector.module.css';

type SidebarProps = {
  onSelect?: (template_id?: string) => void;
};

type SidebarComponentProps = { blocks: ReturnType<typeof useSimpleBlocks> };

const SidebarWithNavigation: React.FC<SidebarComponentProps> = ({ blocks }) => {
  const { id: programId } = useProgram();
  const kaiframesEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Parity.Kaiframes'
  ).data?.value;
  return (
    <div className={styles.sidebar}>
      <section className={styles.start}>
        <strong>Start New</strong>
        <NavLink to={`/${programId}/edit/publisher`}>
          <div data-test="new-blank-campaign">
            <Plus />
          </div>
          <span>Blank</span>
        </NavLink>
      </section>
      <section className={styles.simple}>
        <strong>Simple</strong>
        {blocks.map(({ block, Icon, title, pendoTag }) => (
          <NavLink
            dataPendo={pendoTag}
            key={block.identifier}
            to={`/${programId}/edit/publisher?source=library&id=${block.identifier}`}
          >
            <div>
              <Icon />
            </div>
            <span>{title}</span>
          </NavLink>
        ))}
      </section>
      {kaiframesEnabled && (
        <section className={styles.classic}>
          <Text.Caption>use classic publisher</Text.Caption>
          <Flex spread>
            <Link to={`/${programId}/edit/content/new`}>
              <Text.Caption>Article</Text.Caption>
            </Link>
            <Link to={`/${programId}/edit/content/link`}>
              <Text.Caption>Link</Text.Caption>
            </Link>
            <Link to={`/${programId}/edit/content/image`}>
              <Text.Caption>Image</Text.Caption>
            </Link>
            <Link to={`/${programId}/edit/content/note`}>
              <Text.Caption>Note</Text.Caption>
            </Link>
            <Link to={`/${programId}/edit/content/video`}>
              <Text.Caption>Video</Text.Caption>
            </Link>
          </Flex>
        </section>
      )}
    </div>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({ onSelect }) => {
  const { omitLibraryCategories } = React.useContext(BlocksEditorContext);

  const simpleBlocksConfig: SimpleBlockConfig = {
    omitNames: ['rich_text'],
    omitCategories: omitLibraryCategories,
  };

  const blocks = useSimpleBlocks(simpleBlocksConfig);

  if (!onSelect) {
    return <SidebarWithNavigation blocks={blocks} />;
  }

  return (
    <div className={styles.sidebar}>
      <section className={styles.start}>
        <strong>Start New</strong>
        <Button
          layoutOnly
          className={styles.navigationButton}
          label={
            <>
              <div
                data-test="new-blank-campaign"
                className={styles.iconWrapper}
              >
                <Plus />
              </div>
              <span>Blank</span>
            </>
          }
          onClick={() => onSelect()}
        />
      </section>
      <section className={styles.simple}>
        <strong>Simple</strong>
        {blocks.map(({ block, Icon, title }) => (
          <Button
            className={styles.navigationButton}
            layoutOnly
            label={
              <>
                <div
                  key={`simple-blocks-button-${title}`}
                  className={styles.iconWrapper}
                >
                  <Icon />
                </div>
                <span>{title}</span>
              </>
            }
            onClick={() => onSelect(block.identifier)}
          />
        ))}
      </section>
    </div>
  );
};
