import { useNavigate } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import {
  useUpdateInclusiveRule,
  useCreateInclusiveRule,
  useArchiveInclusiveRule,
} from 'hooks/inclusive-language/useQueries';
import { InclusiveRule } from 'models/inclusive-language';
import { useFlashServerErrors } from 'utility/errors';

export const useActions = (
  setInclusiveRule?: (inclusiveRule: InclusiveRule) => void
): {
  archive: (inclusiveRule: InclusiveRule) => void;
  update: (inclusiveRule: InclusiveRule) => void;
  create: (inclusiveRule: Partial<InclusiveRule>) => void;
} => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const inclusiveRuleListUrl = `/${programId}/configure/style-guide`;

  const { update } = useUpdateInclusiveRule(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Rule updated successfully',
      });
      navigate(inclusiveRuleListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update rule');
    },
  });

  const { create } = useCreateInclusiveRule(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Rule created successfully',
      });
      navigate(inclusiveRuleListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not create rule');
    },
  });

  const { archive } = useArchiveInclusiveRule(programId, {
    onSuccess: (data) => {
      setInclusiveRule?.(data);
      setFlashMessage({
        severity: 'info',
        message: 'InclusiveRule archive successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update inclusiveRule',
      }),
  });

  return { archive, update, create };
};
