import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Checkbox } from 'shared/Checkbox';
import { SignInConfig } from 'models/signInConfig';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import styles from '../styles.module.css';

type PropsType = {
  config: SignInConfig;
  setConfig: (config: SignInConfig) => void;
};

export const OtherEmailCheckbox: React.FC<PropsType> = ({
  config,
  setConfig,
}) => {
  return (
    <div className={styles.AccessSettingsCheckbox}>
      <Checkbox
        checked={config.enabled.otherEmail}
        onChange={(checked) => {
          setConfig({
            ...config,
            enabled: { ...config.enabled, otherEmail: checked },
            verify: { ...config.verify, otherEmail: checked },
          });
        }}
        label={
          <>
            <Text.Subheading block>Other Email Domains</Text.Subheading>
            <Text.Body color={Text.color.gray70} block>
              Allow users to access the community with any email domain.
            </Text.Body>
          </>
        }
      />
      {config.enabled.otherEmail && (
        <Box margin={[0, 0, 16, 44]}>
          <Alert
            type={AlertType.info}
            message="User Verification will be required for Other Email Domains."
            bgColor="white"
            enableIcon
            compact
          />
        </Box>
      )}
    </div>
  );
};
