import { Button, Icon } from '@socialchorus/shared-ui-components';
import { useProgram } from 'contexts/program';
import { useUpdateTopicAboutPage } from 'hooks/topics';
import React, { FC, useCallback, useContext } from 'react';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { navigate } from '@reach/router';
import { useTopicFormCtx } from '../../../../../../context';
import { AboutDesignContext } from '../../../../contexts/about';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';

import styles from './styles.module.css';
import { ModalType } from '../../../../../../context/modals';

const AboutPreview: FC = () => {
  const { selectTab } = useTopicFormCtx();
  const handleViewAll = useCallback(() => {
    selectTab('about');
  }, [selectTab]);
  const { topic, baseUrl, toggleModal } = useTopicFormCtx();
  const { aboutPage } = useContext(AboutDesignContext);
  const emptyAboutLabel = 'There is no About content yet';
  const emptyPreviewLabel = 'There is no About preview yet';
  const { id: programId } = useProgram();

  const { update } = useUpdateTopicAboutPage(programId, topic.id, {
    onSuccess: (data) => {
      navigate(`${baseUrl}/about/${data.designId}`);
    },
  });

  const onAddAboutClick = () => {
    update({ createDesign: true });
  };
  const onEditPreviewClick = () => {
    toggleModal({ type: ModalType.EditAboutPreview });
  };

  return (
    <PreviewCard
      title="About"
      link={<CardLink onClick={handleViewAll}>View all</CardLink>}
    >
      {!aboutPage ? (
        <LoadingSpinner />
      ) : (
        <>
          {!aboutPage.isEmpty && aboutPage.previewContent ? (
            <span className={styles.AboutPreviewContent}>
              {aboutPage.previewContent}
            </span>
          ) : (
            <CardPlaceholder>
              {aboutPage.isEmpty ? emptyAboutLabel : emptyPreviewLabel}
              <Button
                variant="primary"
                leftIcon={<Icon>add</Icon>}
                label={aboutPage.isEmpty ? 'Content' : 'Preview'}
                onClick={
                  aboutPage.isEmpty ? onAddAboutClick : onEditPreviewClick
                }
              />
            </CardPlaceholder>
          )}
        </>
      )}
    </PreviewCard>
  );
};

export default AboutPreview;
