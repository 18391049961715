import React from 'react';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { TextInput } from 'shared/TextInput';
import { Textarea } from 'shared/forms/Textarea';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { EmailAlias } from 'models/email-alias';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TopicsSection } from './TopicsSection';
import styles from './form.module.css';

export type EmailAliasFormFields = Pick<
  EmailAlias,
  | 'name'
  | 'description'
  | 'senderName'
  | 'senderEmail'
  | 'replyToEmail'
  | 'onBehalfOfSenderEnabled'
  | 'onBehalfOfSenderEnabled'
  | 'onBehalfName'
  | 'onBehalfEmail'
  | 'customPhysicalAddressEnabled'
  | 'customPhysicalAddressEnabled'
  | 'companyName'
  | 'addressOne'
  | 'addressTwo'
  | 'city'
  | 'state'
  | 'zip'
>;

type PropsType = {
  emailAlias: EmailAliasFormFields;
  onChange: (data: Partial<EmailAliasFormFields>) => void;
  errors: Record<keyof EmailAliasFormFields, string | undefined>;
  onFieldBlur: (field: keyof EmailAliasFormFields) => void;
  touched: Record<keyof EmailAliasFormFields, boolean>;
};

export const Form: React.FC<PropsType> = ({
  emailAlias,
  onChange,
  onFieldBlur,
  errors,
  touched,
}) => {
  const { id: programId } = useProgram();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );

  return (
    <div className={styles.form}>
      <Section title="Labeling">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Name" labelForChild>
              <TextInput
                required
                onChange={(name) => onChange({ name })}
                onBlur={() => onFieldBlur('name')}
                hasError={Boolean(touched.name && errors.name)}
                errorMessage={errors.name}
                value={emailAlias.name}
                className={styles.textField}
              />
            </Field>
            <Field label="Description" labelForChild>
              <Textarea
                onChange={(description) => onChange({ description })}
                onBlur={() => onFieldBlur('description')}
                hasError={Boolean(touched.description && errors.description)}
                value={emailAlias.description}
                className={styles.textField}
              />
            </Field>
          </div>
        </Fieldset>
      </Section>

      <Section title="General">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="From name" labelForChild>
              <TextInput
                required
                onChange={(senderName) => onChange({ senderName })}
                onBlur={() => onFieldBlur('senderName')}
                hasError={Boolean(touched.senderName && errors.senderName)}
                errorMessage={errors.senderName}
                value={emailAlias.senderName}
                className={styles.textField}
              />
            </Field>
            <Field label="From email" labelForChild>
              <TextInput
                required
                onChange={(senderEmail) => onChange({ senderEmail })}
                onBlur={() => onFieldBlur('senderEmail')}
                type="email"
                hasError={Boolean(touched.senderEmail && errors.senderEmail)}
                errorMessage={errors.senderEmail}
                value={emailAlias.senderEmail}
                className={styles.textField}
              />
            </Field>
            <Field label="Reply-to email" labelForChild>
              <TextInput
                required
                onChange={(replyToEmail) => onChange({ replyToEmail })}
                onBlur={() => onFieldBlur('replyToEmail')}
                type="email"
                hasError={Boolean(touched.replyToEmail && errors.replyToEmail)}
                errorMessage={errors.replyToEmail}
                value={emailAlias.replyToEmail}
                className={styles.textField}
              />
            </Field>
          </div>
        </Fieldset>
      </Section>

      <Section title="On behalf of">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="On behalf sender" labelForChild>
              <div className="kai-flex-row">
                <div className={styles.marginRight}>
                  <FlipSwitch
                    size="xl"
                    shape="rounded"
                    on={emailAlias.onBehalfOfSenderEnabled === true}
                    onChange={(value) =>
                      value
                        ? onChange({ onBehalfOfSenderEnabled: true })
                        : onChange({ onBehalfOfSenderEnabled: false })
                    }
                  />
                </div>
                Display &apos;on behalf of&apos; for this address
              </div>
            </Field>
            {emailAlias.onBehalfOfSenderEnabled && (
              <>
                <Field label="Sender name" labelForChild>
                  <TextInput
                    onChange={(onBehalfName) => onChange({ onBehalfName })}
                    onBlur={() => onFieldBlur('onBehalfName')}
                    hasError={Boolean(
                      touched.onBehalfName && errors.onBehalfName
                    )}
                    errorMessage={errors.onBehalfName}
                    value={emailAlias.onBehalfName}
                    className={styles.textField}
                  />
                </Field>
                <Field label="Sender email" labelForChild>
                  <TextInput
                    onChange={(onBehalfEmail) => onChange({ onBehalfEmail })}
                    onBlur={() => onFieldBlur('onBehalfEmail')}
                    type="email"
                    hasError={Boolean(
                      touched.onBehalfEmail && errors.onBehalfEmail
                    )}
                    errorMessage={errors.onBehalfEmail}
                    value={emailAlias.onBehalfEmail}
                    className={styles.textField}
                  />
                </Field>
              </>
            )}
          </div>
        </Fieldset>
      </Section>
      <Section title="Physical address">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Physical address" labelForChild>
              <div className="kai-flex-row">
                <div className={styles.marginRight}>
                  <FlipSwitch
                    size="xl"
                    shape="rounded"
                    on={emailAlias.customPhysicalAddressEnabled === true}
                    onChange={(value) =>
                      value
                        ? onChange({ customPhysicalAddressEnabled: true })
                        : onChange({ customPhysicalAddressEnabled: false })
                    }
                  />
                </div>
                Use custom physical address
              </div>
            </Field>
          </div>
        </Fieldset>
        {emailAlias.customPhysicalAddressEnabled && (
          <>
            <Fieldset>
              <div className={fieldsetStyles.fieldGroupContainer}>
                <Field label="Company name" labelForChild>
                  <TextInput
                    onChange={(companyName) => onChange({ companyName })}
                    onBlur={() => onFieldBlur('companyName')}
                    hasError={Boolean(
                      touched.companyName && errors.companyName
                    )}
                    errorMessage={errors.companyName}
                    value={emailAlias.companyName}
                    className={styles.textField}
                  />
                </Field>
              </div>
            </Fieldset>

            <Fieldset>
              <div className={fieldsetStyles.fieldGroupContainer}>
                <Field label="Address">
                  <div className={styles.addressFields}>
                    <TextInput
                      onChange={(addressOne) => onChange({ addressOne })}
                      onBlur={() => onFieldBlur('addressOne')}
                      hasError={Boolean(
                        touched.addressOne && errors.addressOne
                      )}
                      errorMessage={errors.addressOne}
                      value={emailAlias.addressOne}
                      className={styles.textField}
                      placeholder="Address line 1"
                    />
                    <TextInput
                      onChange={(addressTwo) => onChange({ addressTwo })}
                      onBlur={() => onFieldBlur('addressTwo')}
                      hasError={Boolean(
                        touched.addressTwo && errors.addressTwo
                      )}
                      errorMessage={errors.addressTwo}
                      value={emailAlias.addressTwo}
                      className={styles.textField}
                      placeholder="Address line 2"
                    />
                    <TextInput
                      onChange={(city) => onChange({ city })}
                      onBlur={() => onFieldBlur('city')}
                      hasError={Boolean(touched.city && errors.city)}
                      errorMessage={errors.city}
                      value={emailAlias.city}
                      className={styles.textField}
                      placeholder="City"
                    />
                    <div className={styles.stateZipInputs}>
                      <TextInput
                        onChange={(state) => onChange({ state })}
                        onBlur={() => onFieldBlur('state')}
                        hasError={Boolean(touched.state && errors.state)}
                        errorMessage={errors.state}
                        value={emailAlias.state}
                        placeholder="State / Providence"
                      />
                      <TextInput
                        onChange={(zip) => onChange({ zip })}
                        onBlur={() => onFieldBlur('zip')}
                        hasError={Boolean(touched.zip && errors.zip)}
                        errorMessage={errors.zip}
                        value={emailAlias.zip}
                        placeholder="Zip / Postal code"
                      />
                    </div>
                  </div>
                </Field>
              </div>
            </Fieldset>
          </>
        )}
      </Section>
      {!showPermissionsUI?.value && (
        <TopicsSection emailAlias={emailAlias} onChange={onChange} />
      )}
    </div>
  );
};
