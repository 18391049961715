import * as React from 'react';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Box } from 'DesignSystem/Components';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Plus } from 'shared/icons';
import { useDrop } from 'react-dnd';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useSimpleBlocks } from 'hooks/publisher/useSimpleBlocks';
import { Subheading, Body, background } from 'DesignSystem/Typography';
import styles from './empty-row.module.css';

export const EmptyRow: React.FC<{ onInsert(): void }> = ({ onInsert }) => {
  const { data: newEditors } = useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  );
  if (!newEditors) return null;
  if (!newEditors.value) return <OldEmptyRow onInsert={onInsert} />;
  return <NewEmptyRow onInsert={onInsert} />;
};

export const NewEmptyRow: React.FC<{ onInsert(): void }> = ({ onInsert }) => {
  const { insert: canvasInsert } = React.useContext(BlocksEditorContext);
  const blocks = useSimpleBlocks({ extended: true });
  const insert = React.useCallback(
    (title: string) => {
      const data = blocks[blocks.findIndex((block) => block.title === title)];
      if (data) canvasInsert(data.block.asset.blocks);
    },
    [canvasInsert, blocks]
  );
  const inserter = React.useRef(insert);
  inserter.current = insert;

  const [{ isOver, title }, dropRef] = useDrop<
    { id: string },
    unknown,
    { isOver: boolean; title: string }
  >(() => ({
    accept: 'add-block-from-panel-to-canvas',
    drop: (item) => inserter.current(item.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      title: monitor.getItem()?.id ?? '',
    }),
  }));
  return (
    <div ref={dropRef}>
      <Box height={300} padding={4}>
        {isOver ? (
          <Flex
            column
            style={{ width: '100%', height: '100%' }}
            color={background.gray05}
          >
            <Subheading block>
              Drop <strong>{title}</strong> here
            </Subheading>
          </Flex>
        ) : (
          <Flex
            column
            center
            style={{ padding: '36px', height: '100%', textAlign: 'center' }}
          >
            <Subheading bold block>
              You have a blank canvas. <br />
              Start by adding some blocks.
            </Subheading>
            <Box padding={20}>
              <Button onClick={onInsert} label="Add block" icon={<Plus />} />
            </Box>
            <Flex>
              <Body block>
                Don&apos;t know where to start?
                <br />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://support.firstup.io/hc/en-us/categories/1500000898182-Campaigns"
                >
                  Check out the Knowledge Base
                </a>
                .
              </Body>
            </Flex>
          </Flex>
        )}
      </Box>
    </div>
  );
};

export const OldEmptyRow: React.FC<{ onInsert(): void }> = ({ onInsert }) => (
  <div className={styles.basics}>
    <button
      type="button"
      className={styles.addLayoutButton}
      data-test="add-layout"
      onClick={onInsert}
    >
      <div>
        <SVGIcon name="Plus" />
      </div>
      <div>Add Layout</div>
    </button>
  </div>
);
