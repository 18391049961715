import camelcaseKeys from 'camelcase-keys';
import qs from 'qs';
import { DateTime } from 'luxon';

import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type InsightsProps = {
  programId: number;
  contentId: number;
  unixStartDate: number;
  unixEndDate: number;
  timezone: string;
};

export type SummaryProps = {
  programId: number;
  contentId: number;
};

export type ContentInsights = {
  openRate: number;
  viewers: number;
  usersOpened: number;
  usersClickedALink: number;
  usersLiked: number;
  usersBookmarked: number;
  usersCommented: number;
  usersShared: number;
  usersAcknowledged: number;
  averageCtr: number;
};

export type MetricsSummary = {
  assistant: Metrics;
  email: Metrics;
  push: Metrics;
  targeted: number;
  steps: number;
  // TODO: API should be returning same form as email/assistant/push
  aggregate: Aggregate[];
  epochStart: number;
  epochEnd: number;
  executionStateUpdatedAt: string;
  executionState: string;
  metricsUpdatedAt: string;
};

export type ResultsSummary = MetricsSummary & {
  uniqueMeaningfulEngagements?: number;
};

export type Metrics = {
  delivered?: number;
  initial?: number;
  sent?: number;
  open?: number;
  openByMachine?: number;
};

export type Aggregate = {
  name: string;
  count: number;
};

export const defaultMetrics: Metrics = {
  delivered: 0,
  initial: 0,
  sent: 0,
  open: 0,
  openByMachine: 0,
};

export const defaultMetricsSummary: MetricsSummary = {
  assistant: defaultMetrics,
  email: defaultMetrics,
  push: defaultMetrics,
  targeted: 0,
  steps: 0,
  aggregate: [],
  epochStart: 0,
  epochEnd: 0,
  executionStateUpdatedAt: DateTime.now().toFormat('yyyy-MM-dd'),
  executionState: DateTime.now().toFormat('yyyy-MM-dd'),
  metricsUpdatedAt: DateTime.now().toFormat('yyyy-MM-dd'),
};

export const fetchContentInsights = async (
  props: InsightsProps
): Promise<ContentInsights> => {
  const {
    programId,
    contentId,
    timezone: time_zone,
    unixStartDate: published_since,
    unixEndDate: published_before,
  } = props;
  const query = qs.stringify({
    time_zone,
    published_since,
    published_before,
    uniqueness: true,
  });
  const url = `${apiRoot}/samba/programs/${programId}/contents/${contentId}/metrics/insights?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response
      .json()
      .then((json) => camelcaseKeys(json.data, { deep: true }));
  }
  throw new Error(`Error fetching content insights: ${response.status}`);
};

export const fetchMetricsSummary = async (
  props: SummaryProps
): Promise<MetricsSummary> => {
  const { programId, contentId } = props;
  const url = `${apiRoot}/samba/programs/${programId}/contents/${contentId}/communications/metrics_summary`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching metrics insights: ${response.status}`);
};

export const fetchResultsSummary = async (
  props: SummaryProps
): Promise<ResultsSummary> => {
  const { programId, contentId } = props;
  const url = `${apiRoot}/samba/programs/${programId}/contents/${contentId}/communications/results_summary`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching metrics insights: ${response.status}`);
};
