import { useNavigate } from '@reach/router';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  useQuery,
  UseMutationResult,
} from 'react-query';
import { Content } from 'models/content';
import { CustomSlug } from 'models/custom-slug';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { FlashMessageType } from 'models/flash-message';
import { useFlashServerErrors } from 'utility/errors';
import {
  OptionType,
  MutationOptions,
  InfiniteQueryResponse,
  QueryResponse,
} from 'hooks/common';
import {
  createCustomSlug,
  updateCustomSlug,
  CustomSlugCollectionData,
  FetchParams,
  fetchCustomSlugs,
  deleteCustomSlug,
  fetchCustomSlug,
  CustomSlugFilterOptions,
  duplicateCustomSlug,
  CustomSlugMemberDataWithoutIncluded,
} from '../services/api-custom-slugs';
import { ValidationError } from '../services/Errors/ValidationError';

const customSlugKeys = {
  all: ['custom-slugs'] as const,
  lists: () => [...customSlugKeys.all, 'list'] as const,
  list: (queryParams: CustomSlugFilterOptions) =>
    [...customSlugKeys.lists(), queryParams] as const,
  details: () => [...customSlugKeys.all, 'detail'] as const,
  detail: (id: number) => [...customSlugKeys.details(), id] as const,
};

export function mapServerDataToCustomSlugs(
  serverData: CustomSlugCollectionData
): Array<CustomSlug> {
  return serverData.data.map(({ attributes }) => attributes);
}

export const useCreateCustomSlug = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  create: (customSlug: Partial<CustomSlug>) => void;
} => {
  const flashServerErrors = useFlashServerErrors();
  const { setFlashMessage } = useFlashMessage();
  const errorFlasher = (error: Error) => {
    if (error instanceof ValidationError) {
      const [message] = JSON.parse(error.message).errors;
      if (message) {
        const existingSlug = /^Custom slug already exists: (\S+)$/.exec(
          message
        )?.[1];
        if (existingSlug) {
          setFlashMessage({
            message: `Custom URL already exists: ${existingSlug}`,
            severity: 'error' as const,
          });
          return;
        }
      }
    }
    flashServerErrors(error, 'Could not create Custom URL');
  };
  const create = (customSlug: Partial<CustomSlug>) => {
    createCustomSlug(programId, customSlug)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
        errorFlasher(err);
      });
  };

  return { create };
};

export const useCustomSlugFiltersData = (
  programId: number
): Array<OptionType> => {
  // Get the main data that contains the creators
  const { data: customSlugs } = useCustomSlugInfiniteQuery({
    programId,
  });

  // For uniqueness and avoid repeated values, the Map handles it
  const uniqCreators = new Map<number, OptionType>();
  customSlugs.forEach((customSlug) =>
    uniqCreators.set(Number(customSlug.createdBy), {
      value: customSlug.createdBy.toString(),
      label: customSlug.creator.fullName,
    })
  );

  // Cast the Map structure type to an array of OptioTypes, required by the dropdown filter
  const creatorList: OptionType[] = Array.from(
    uniqCreators.values()
  ) as OptionType[];

  return creatorList;
};

export const useCustomSlugInfiniteQuery = (
  props: Omit<FetchParams, 'page'>
): InfiniteQueryResponse<CustomSlug> => {
  const { programId, filters } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      ...(filters ? customSlugKeys.list(filters) : customSlugKeys.lists()),
    ],
    queryFn: async ({ pageParam }) =>
      fetchCustomSlugs({
        programId,
        filters,
        page: pageParam as number,
      }),
  });

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToCustomSlugs(batch) : []))
      .flat(1);

  // It makes sure if it is an error and if the message is empty it adds a fallback, also if the error is not present then it needs to be empty
  const catchedError =
    error instanceof Error ? error.message || 'Something went wrong' : '';

  return {
    isLoading: isFetching,
    errorMessage: catchedError,
    isFetchingNextPage,
    fetchNextPage,
    meta: data?.pages[0].meta,
    data: flatData || [],
  };
};

export const useCustomSlug = (
  programId: number,
  id: number
): QueryResponse<CustomSlug> => {
  const { data: rawData, error, isFetching: isLoading } = useQuery(
    [customSlugKeys.detail(id)],
    async () =>
      fetchCustomSlug({
        programId,
        id,
      })
  );

  const customSlugData = rawData?.data.attributes;
  const contentsData = rawData?.included;
  const contentData =
    contentsData && contentsData[0] && (contentsData[0].attributes as Content);
  const errorMessage =
    error instanceof Error ? error.message || 'Something went wrong' : '';
  if (customSlugData && contentData) customSlugData.content = contentData;

  return {
    isLoading,
    errorMessage,
    data: customSlugData,
  };
};

export function useUpdateCustomSlug(): UseMutationResult<
  CustomSlug,
  Error,
  Partial<CustomSlug>
> {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const errorFlasher = (error: Error): FlashMessageType => {
    const severity = 'error';
    if (!(error instanceof ValidationError))
      return {
        message: error.message,
        severity,
      };

    const message = JSON.parse(error.message).errors[0];
    if (!message)
      return {
        message: error.message,
        severity,
      };

    const existingSlug = /^Custom slug already exists: ([^\p{C}]+)/.exec(
      message
    )?.[1];
    if (!existingSlug)
      return {
        message: error.message,
        severity,
      };

    return {
      message: `Custom URL already exists: ${existingSlug}`,
      severity,
    };
  };
  return useMutation<CustomSlug, Error, Partial<CustomSlug>>(
    (customSlug: Partial<CustomSlug>) => updateCustomSlug(customSlug),
    {
      onSuccess: () => {
        setFlashMessage({
          severity: 'info',
          message: 'Custom URL updated successfully',
        });
        navigate(`/${programId}/configure/custom-urls`);
      },
      onError: (error) => {
        setFlashMessage(errorFlasher(error));
      },
      onSettled: (_data, _error, variables) => {
        if (variables.id) {
          const queryKey = customSlugKeys.detail(variables.id);
          queryClient.invalidateQueries(`${queryKey}`);
        }
      },
    }
  );
}

export function useDeleteCustomSlug({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}): UseMutationResult<void, Error, number> {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();
  return useMutation<void, Error, number>(
    (id: CustomSlug['id']) => deleteCustomSlug({ programId, customSlugId: id }),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        setFlashMessage({ message: 'Custom URL deleted', severity: 'info' });
      },
      onError: () => {
        setFlashMessage({
          message: 'Error deleting Custom URL',
          severity: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([...customSlugKeys.lists()]);
      },
    }
  );
}

export function useDuplicateCustomSlug({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}): UseMutationResult<CustomSlugMemberDataWithoutIncluded, Error, number> {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();
  const errorFlasher = (error: Error): FlashMessageType => {
    const severity = 'error';
    if (!(error instanceof ValidationError))
      return {
        message: error.message,
        severity,
      };

    const message = JSON.parse(error.message).errors[0];
    if (!message)
      return {
        message: error.message,
        severity,
      };

    const duplicatedSlugId = /^Custom slug already duplicated: #(\d+)$/.exec(
      message
    )?.[1];
    if (!duplicatedSlugId)
      return {
        message: error.message,
        severity,
      };

    return {
      message: 'Custom URL already duplicated',
      severity,
      timeout: 15000,
      navigateButton: {
        text: 'Click here to edit',
        path: `/${programId}/configure/custom-urls/${duplicatedSlugId}/edit`,
      },
    };
  };
  return useMutation(
    (id: CustomSlug['id']) =>
      duplicateCustomSlug({ programId, customSlugId: id }),
    {
      onSuccess: (customSlugMemberData) => {
        queryClient.invalidateQueries([...customSlugKeys.lists()]);
        if (onSuccess) {
          onSuccess();
        }
        const customSlugId = customSlugMemberData.data.attributes.id;
        const navigateButton = {
          text: 'Click here to edit',
          path: `/${programId}/configure/custom-urls/${customSlugId}/edit`,
        };
        setFlashMessage({
          message: 'Custom URL duplicated',
          severity: 'info',
          timeout: 15000,
          navigateButton,
        });
      },
      onError: (error) => {
        setFlashMessage(errorFlasher(error));
      },
    }
  );
}
