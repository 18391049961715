import React from 'react';
import {
  enterKey,
  deleteKey,
  backspaceKey,
  leftKey,
  rightKey,
  upKey,
  downKey,
} from './event-lib';
import { getCaretWithin, encodeText, decodeText, stripHtmlTags } from './lib';
import styles from './vti.module.css';

type Props = {
  onDeletePreviousVariable: () => void;
  onPreviousInput: () => void;
  onNextInput: () => void;
  onChange: (changes: {
    text: string;
    html?: string;
    newVariable?: boolean;
  }) => void;
  onSuggest: (part: string, fn: (key: string) => void) => void;
  // onBlur: () => void;
  html: string;
  index: number;
  disabled: boolean;
  onCaretMoved?: (value: number) => void;
};

class EditableInput extends React.Component<Props> {
  private abort = false;

  private isMatchingText: boolean;

  private lastEmit = '';

  private span: HTMLSpanElement | null = null;

  private parentNode: (Node & ParentNode) | null = null;

  private caretPosition: number = 0;

  constructor(props: Props) {
    super(props);
    // The parent has this information already, so it seems natural
    // to pass it in. But that will cause a rerender, which makes things
    // harder than easier for maintaining focus, cursor position, etc.
    this.isMatchingText = false;
  }

  componentDidMount(): void {
    // This component consists of a single node, that sometimes gets disconnected
    // from the dom. We need to keep track of something in order to put it back.
    if (!this.span) return;
    if (!this.span.parentNode) return;
    this.parentNode = this.span.parentNode;
    this.lastEmit = stripHtmlTags(this.span.innerHTML);
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    // Before comparing the span.innerHTML, we need to fully decode and
    // re-encode it. The biggest reason is that a browser stores a sequences of
    // spaces as ' &nbsp; &nbsp; ', interleaved. So it comes at us in mixed
    // form.
    if (!this.span) return true;

    const { disabled } = this.props;
    if (disabled !== nextProps.disabled) return true;

    const reEncoded = encodeText(this.span.innerText);
    return nextProps.html !== reEncoded;
  }

  // Ensure that the span contents are updated when the input's value
  // is changed externally.
  componentDidUpdate() {
    const { html } = this.props;
    if (this.span && html !== encodeText(this.span.innerText)) {
      this.span.innerText = decodeText(html);
    }

    // Set caret position after update
    if (this.span) {
      setTimeout(() => {
        if (this.span) {
          this.setCaretPosition(this.span.innerText, this.caretPosition);
        }
      }, 0);
    }
  }

  componentWillUnmount(): void {
    // this was the source of the mystery "null" values for the ref.
    // everytime the editable span was cleared out, react would unmount it!
    this.abort = true;
  }

  maybeTriggerSuggestions = (
    caret: number,
    matchable: string,
    text: string
  ) => {
    if (!this.span) return;
    const { onSuggest } = this.props;
    let part = '';
    this.isMatchingText = false;
    for (let i = caret; i >= 0; i -= 1) {
      const char = matchable[i];
      part = char + part;
      if (part.substr(0, 2) === '{{') {
        this.isMatchingText = true;
        onSuggest(part, (variable) => {
          if (!this.span) return;
          if (this.abort) return;
          this.isMatchingText = false;
          const prepend = text.substr(0, i);
          let append = text.substr(caret);
          if (append && append[0] === '{') append = append.substr(1);
          const html = `${prepend}{{${variable}}}${append}`;
          this.span.innerText = prepend;
          this.emitChange({ html, newVariable: true });
        });
        break;
      }
    }
  };

  private setCaretPosition = (emitting: string, caretPosition: number) => {
    if (this.span) {
      const newContentLength = emitting.length;
      const newCaretPosition =
        caretPosition <= newContentLength ? caretPosition : newContentLength;

      const range = document.createRange();
      const selection = window.getSelection();

      if (this.span.childNodes.length > 0) {
        const textNode = this.span.childNodes[0];
        range.setStart(textNode, newCaretPosition);
        range.collapse(true);

        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  };

  emitChange = (
    changes: { html?: string; newVariable?: boolean } = {},
    reportCaretPosition = true
  ) => {
    const { onCaretMoved } = this.props;
    if (this.span && onCaretMoved && reportCaretPosition) {
      this.caretPosition = getCaretWithin(this.span).offset; // Cache caret position
      onCaretMoved(getCaretWithin(this.span).offset);
    }

    if (this.abort) return;
    if (!this.span || !this.parentNode) return;
    let html = changes.html || this.span.innerHTML || '';
    const { index, onChange } = this.props;

    // FLY-4762 detects the bug described above.
    // We need to know if this bug was found and addressed so that
    // the update will happen when it would have otherwise been skipped.
    let fixing = false;

    if (this.span.parentNode === null) {
      fixing = true;
      // They were deleting everything, so the intended value is the empty string
      html = '';
      // There is a chance that the browser has put a BR element in there.
      const brs = this.parentNode.querySelectorAll('br');
      for (let i = brs.length - 1; i >= 0; i -= 1) {
        this.parentNode.removeChild(this.parentNode.children[i]);
      }
      // The span needs put back into the DOM in the right order, too.
      this.parentNode.insertBefore(this.span, this.parentNode.children[index]);
      // put it back :)
      this.span.focus();
    }

    const emitting = stripHtmlTags(html);

    if (emitting !== this.lastEmit || fixing) {
      this.lastEmit = emitting;
      onChange({ ...changes, text: emitting });
    }
    if (this.span && (html !== emitting || fixing)) {
      // Call the new method to set the caret position
      const caretInfo = getCaretWithin(this.span);
      const caretPosition = caretInfo ? caretInfo.offset : 0;
      this.span.innerHTML = emitting;

      setTimeout(() => this.setCaretPosition(emitting, caretPosition), 0);
    }
  };

  onBlur: () => void = () => {
    // const { onBlur } = this.props;
    this.emitChange({}, false);
    // onBlur();
  };

  render(): JSX.Element | null {
    const {
      disabled,
      html,
      onDeletePreviousVariable,
      onPreviousInput,
      onNextInput,
      onCaretMoved,
    } = this.props;
    /* eslint-disable react/no-danger */
    return (
      <span
        role="textbox"
        aria-label="text input"
        contentEditable={!disabled}
        className={`${styles.editable} -vti-clickable`}
        ref={(ref) => {
          this.span = ref;
        }}
        tabIndex={0}
        onClick={() => {
          if (this.span && onCaretMoved) {
            onCaretMoved(getCaretWithin(this.span).offset);
          }
        }}
        onFocus={() => {
          if (this.span && onCaretMoved) {
            onCaretMoved(getCaretWithin(this.span).offset);
          }
        }}
        onInput={() => this.emitChange}
        onBlur={this.onBlur}
        dangerouslySetInnerHTML={{ __html: html }}
        onKeyDown={(event) => {
          const { atStart, atEnd } = getCaretWithin(
            (event.target as unknown) as HTMLElement
          );
          if (enterKey(event)) {
            // There is never a time we want the enter key to enter a newline
            // or submit the form this input is contained in..
            event.preventDefault();
          }
          if (enterKey(event) && !this.isMatchingText) {
            // If it is matching text, then the enter key will be caught via
            // the keyboard shortcuts in VariablesContainer. So the change
            // ends up being emitted.
            this.emitChange();
            return;
          }
          if (
            (enterKey(event) || upKey(event) || downKey(event)) &&
            this.isMatchingText
          ) {
            // Let the enter and nav keys work as expected, unless we are
            // showing some matches, then we don't want these to do anything.
            return;
          }

          // Navigate among the other inputs
          if (backspaceKey(event) && atStart) {
            onDeletePreviousVariable();
            return;
          }
          if (leftKey(event) && atStart) {
            onPreviousInput();
            return;
          }
          if (rightKey(event) && atEnd) {
            onNextInput();
            return;
          }
          if (deleteKey(event)) {
            // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12765765/
            // This is for the sake of Win 10 Edge.
            // When the user "selects all" of this span, and clicks "Delete",
            // Microsoft Edge will remove the element from the DOM.
            // The onKeyUp will never fire, no unmounting event is ever called,
            // the node become orphaned. It does not even delete the text from
            // the span! After removing the node, if that was the last child among
            // siblings, then Edge will insert a BR element as well.
            // This is all detected and handled in `emitChange()`.
            // But it all doesn't happen until after this event bubbles up.
            // ...So we wait a moment...
            setTimeout(this.emitChange, 10);
          }
        }}
        onKeyPress={(event) => {
          if (this.abort) return;
          if (!this.span) return;
          const { offset } = getCaretWithin(
            (event.target as unknown) as HTMLElement
          );
          let matchable = this.span.innerText.substr(0, offset);
          // Usually `event.key` has the text representation of the character that
          // will be printed. Since this is on keyPress, many meta/ctrl characters
          // are easily avoided. However, the Enter and Escape keys show up, and
          // we don't want to use those in matching.
          matchable += ['Enter', 'Escape'].includes(event.key) ? '' : event.key;
          this.maybeTriggerSuggestions(offset, matchable, this.span.innerText);
        }}
        onKeyUp={() => {
          // This is for the sake of IE11. It is unreliable when it
          // comes to the `onInput` event. We could add a bunch of
          // checks here to make sure it is a "typable" key, but
          // the emit change is smart enough to avoid rendundant
          // calls anyway. As such, this fix doesn't seem to bother the
          // other browsers.
          this.emitChange();
        }}
      />
    );
    /* eslint-enable react/no-danger */
  }
}

const WithDefaults: React.FC<Props> = (props) => {
  const {
    onDeletePreviousVariable,
    onPreviousInput,
    onNextInput,
    onChange,
    onSuggest,
    // onBlur = () => {},
    html = '',
    disabled = false,
    index,
    onCaretMoved,
  } = props;
  return (
    <EditableInput
      onDeletePreviousVariable={onDeletePreviousVariable}
      onPreviousInput={onPreviousInput}
      onNextInput={onNextInput}
      onChange={onChange}
      onSuggest={onSuggest}
      html={html}
      disabled={disabled}
      index={index}
      onCaretMoved={onCaretMoved}
    />
  );
};

export default WithDefaults;
