import * as React from 'react';
import { getScrollParent } from 'utility/dom';
import { usePortal } from 'hooks/usePortal';
import { useToggle } from 'hooks/useToggle';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';

const FOOTER = 75;
const GUTTER = 3;
const GUTTERS = GUTTER * 2;
const SHIFT = GUTTER;

// constants for adjusting the new editor placements
const MORE_RESERVED = 38;
const HEADER_HEIGHT = 52;
const RIGHT_PANEL_POS = 325;

export function useSidebarPopout(
  options: Partial<{
    top: number;
  }> = {}
): {
  ref: React.RefObject<HTMLDivElement>;
  toggle: () => void;
  visible: boolean;
  portal: ReturnType<typeof usePopoutPortal>;
} {
  const newEditorFlag = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;
  const { value: visible, toggle, disable } = useToggle();
  const ref = React.useRef<HTMLDivElement>(null);
  const portal = usePopoutPortal(ref, visible, options);

  React.useEffect(() => {
    const hide = ({ target }: { target: EventTarget | null }) => {
      if (!visible) return;
      if (!portal.ref.current) return;
      if (portal.ref.current.contains(target as Node)) return;
      disable();
    };
    const els = newEditorFlag
      ? [
          document.getElementById('publisher-selected-sidebar'),
          document.getElementById('publisher-global-styles-sidebar'),
        ]
      : [document];

    els.forEach((el: HTMLElement | Document | null) => {
      if (el) el.addEventListener('click', hide);
    });
    return () => {
      if (els)
        els.forEach(
          (el: HTMLElement | Document | null) =>
            el && el.removeEventListener('click', hide)
        );
    };
  }, [disable, visible, portal.ref, newEditorFlag]);
  return { toggle, visible, ref, portal };
}

function usePopoutPortal(
  containerRef: React.RefObject<HTMLDivElement>,
  visible: boolean,
  options: Partial<{ top: number }> = {}
): {
  ref: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
  ready: boolean;
  portal: Element;
} {
  const newEditorFlag = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;
  const reserved = FOOTER + (newEditorFlag ? MORE_RESERVED : 0);
  const ref = React.useRef<HTMLDivElement>(null);
  const portal = usePortal(`publisher-sidebar-popout-portal`);
  const [pos, setPos] = React.useState({
    top: options.top ?? 0,
    left: 0,
  });
  React.useEffect(() => {
    if (!containerRef.current || !ref.current || !visible || !portal.ready)
      return;
    const { height } = ref.current.getBoundingClientRect();
    const scrollParent = getScrollParent(containerRef.current);
    const { x, y, width } = containerRef.current?.getBoundingClientRect();
    const naiveTop = Math.max(GUTTER, y + (options.top ?? 0));
    const maxHeight = window.innerHeight - reserved - GUTTERS;
    const maxY = naiveTop + height - GUTTER;
    const top = maxY > maxHeight ? naiveTop - (maxY - maxHeight) : naiveTop;
    const left =
      x +
      width +
      (scrollParent && scrollParent !== document.documentElement
        ? // add a little extra when there seems to be a scrollbar
          SHIFT + 20
        : SHIFT);
    setPos({ top, left });
  }, [options.top, visible, containerRef, ref, portal.ready, reserved]);

  const leftRight =
    newEditorFlag &&
    containerRef.current?.closest('#publisher-selected-sidebar')
      ? { right: `${RIGHT_PANEL_POS}px` }
      : { left: `${pos.left}px` };
  const headerOffset = newEditorFlag ? HEADER_HEIGHT : 0;
  const topWithOffset = Math.max(headerOffset, pos.top);
  return {
    ref,
    style: {
      ...leftRight,
      top: `${topWithOffset}px`,
      maxHeight: `calc(100vh - ${reserved + GUTTERS}px)`,
      overflow: 'auto',
      visibility: pos.left ? 'visible' : 'hidden',
    },
    portal: portal.target,
    ready: portal.ready,
  };
}
