import { useQuery, useInfiniteQuery } from 'react-query';
import { UserExport } from 'models/user-export';
import {
  fetchUserExports,
  fetchExport,
  UserExportCollectionData,
  UserExportFetchParams,
} from 'services/api-exports';

import {
  InfiniteQueryResponse,
  MutationOptions,
  QueryResponse,
} from './common';

export const useUserExportQuery = (
  programId: number,
  jobId: number
): QueryResponse<UserExport> => {
  const { isLoading, error, data } = useQuery<UserExport, Error>(
    `job-${jobId}`,
    () => fetchExport(programId, jobId),
    { retry: false, cacheTime: 0 }
  );

  return {
    isLoading,
    data,
    errorMessage: error?.message,
  };
};

export function mapServerDataToUserExport(
  serverData: UserExportCollectionData
): Array<UserExport> {
  return serverData.data.map((entity: UserExport) => entity);
}

export const useUserExportInfiniteQuery = (
  props: Omit<UserExportFetchParams, 'page'>
): InfiniteQueryResponse<UserExport> => {
  const { order, programId, pageSize = 20, search, status } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<UserExportCollectionData, Error>(
    `user-imports-infinite-${JSON.stringify(props)}`,
    async ({ pageParam = 1 }) =>
      fetchUserExports({
        order,
        programId,
        search,
        status,
        page: { number: pageParam, size: pageSize },
      }),
    {
      getNextPageParam: (lastGroup) => {
        if (lastGroup && lastGroup.meta.pageNumber < lastGroup.meta.totalPages)
          return lastGroup.meta.pageNumber + 1;

        return undefined;
      },
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToUserExport(batch) : []))
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};

export const useGenerateJobReport = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): { download: (jobId: number) => void } => {
  const download = (jobId: number) => {
    fetchExport(programId, jobId)
      .then((response) => {
        if (onSuccess) onSuccess(response.status);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { download };
};
