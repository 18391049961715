import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ContentOrPollBanner } from 'components/content/ContentBanner';
import { useBulkSelect } from 'contexts/bulk-select';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useContentsInfiniteQuery } from 'hooks/content';
import { useReachByContentQueries } from 'hooks/reach';
import { PinnableContent } from 'models/topic';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { Content, isContent } from 'models/content';
import { Post } from '../types';
import styles from '../posts.module.css';

type PostsFinderViewProps = Pick<
  ReturnType<typeof useContentsInfiniteQuery>,
  'isLoading' | 'isFetchingNextPage' | 'fetchNextPage' | 'hasNextPage'
> & {
  content: Post[];
  reachByContent: ReturnType<typeof useReachByContentQueries>;
  onSelected: (
    selected: PinnableContent[],
    remainderSelectedIds: string[]
  ) => void;
  height: number;
};

export function PostsFinderView({
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  content, // this cannot include PinnedContent
  reachByContent,
  onSelected,
  height: containerHeight,
}: PostsFinderViewProps): ReactElement {
  const { selectedIds } = useBulkSelect();

  const parentRef = useRef<HTMLDivElement>(null);
  const [selectedContent, setSelectedContent] = useState<
    Record<string, PinnableContent>
  >({});
  const [
    remainderSelectedContentIds,
    setRemainderSelectedContentIds,
  ] = useState<string[]>([]);

  useEffect(() => {
    function getContentRowId(id: number) {
      return `content-${id}`;
    }

    const selectedIdsSet = new Set(selectedIds);
    const fetchedSelectedContent = content.filter(
      (item): item is Content =>
        selectedIdsSet.has(getContentRowId(item.id)) && isContent(item) // this only includes content that is fetched, and therefore is not a subset of `selectedIds`
    );
    // here, get a list of selected content that is not fetched yet in the current page set.
    // we need to keep track of them, otherwise any content ids missing from selectedContent will be interpreted by the system as unselected.

    // it is not possible to fetch content by id from kai without a refactor and a new API endpoint.
    // The problem is exacerbated by the fact that PinnedContent cannot be safely cast into a Post. If it could, we could just init selectedContent with an initial value.
    // instead, our only source of initial values has to be the ids.
    // so, we will keep this list of as-yet-unfetched content ids separate, and only remove them when they are fetched.
    // this 'unfeched' list will be passed into onSelected so that they will not be considered unselected by the handler.

    const unfetchedSelectedIds = selectedIds.filter(
      (id) =>
        !fetchedSelectedContent.some((item) => getContentRowId(item.id) === id)
    );

    // Update selected content if they are fetched and remove contents that are no longer selected
    setSelectedContent((prev) => ({
      ...Object.fromEntries(
        Object.entries(prev).filter(([id]) => selectedIdsSet.has(id))
      ),
      ...Object.fromEntries(
        fetchedSelectedContent.map((item) => [getContentRowId(item.id), item])
      ),
    }));

    setRemainderSelectedContentIds(unfetchedSelectedIds);
  }, [content, selectedIds]);

  useEffect(() => {
    onSelected(Object.values(selectedContent), remainderSelectedContentIds);
  }, [onSelected, selectedContent, remainderSelectedContentIds]);

  return (
    <InfiniteContainer
      parentRef={parentRef}
      style={{
        height: containerHeight,
        maxWidth: '100%',
      }}
    >
      {(height) => (
        <div
          style={{
            marginTop: 40,
          }}
        >
          <ListHeader />
          <InfiniteBannerList
            isLoading={isLoading}
            itemCount={content.length}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            height={height}
            parentRef={parentRef}
          >
            {(index: number) => (
              <ContentOrPollBanner
                item={content[index]}
                reachByContent={reachByContent}
                disableCommentsLinks
                disableContentActions
              />
            )}
          </InfiniteBannerList>
        </div>
      )}
    </InfiniteContainer>
  );
}

function ListHeader(): ReactElement {
  const { selectedCount } = useBulkSelect();

  return (
    <div className={styles.postFinderHeader}>
      <span>{selectedCount} selected</span>
    </div>
  );
}
