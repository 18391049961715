import React, { FC, useEffect } from 'react';
import { DateTime } from 'luxon';
import { DateDropdown } from './date-dropdown';
import styles from './new-date-range.module.css';

export type DateRangeValue = [DateTime, DateTime];

export type DateRangeProps = {
  dates: DateRangeValue;
  onDateChange: (newDates: DateRangeValue) => void;
  dateFormat?: string;
  maxDate?: DateTime;
  minDate?: DateTime;
  label?: string;
};

const START_DATE = 0;
const END_DATE = 1;
type CalendarIndex = typeof START_DATE | typeof END_DATE;

export const DateRange: FC<DateRangeProps> = ({
  dates,
  onDateChange,
  dateFormat = 'MM/dd/yyyy',
  maxDate,
  minDate,
  label = 'Date Range:',
}) => {
  const [fromDate, toDate] = dates;

  useEffect(() => {
    if (fromDate.startOf('day') > toDate.startOf('day'))
      throw new Error(`Invalid range: start date is greater than end date.`);
  }, [fromDate, toDate]);

  const handleDateChange = (calendarIndex: CalendarIndex) => (
    selectedDate?: DateTime
  ) => {
    if (!selectedDate) return;
    const newDates: DateRangeValue = [...dates];
    newDates[calendarIndex] = selectedDate;
    onDateChange(newDates);
  };

  return (
    <div className={styles.mainWrapper}>
      <p className={styles.label}>{label}</p>
      <DateDropdown
        date={fromDate}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={toDate}
        onDateChange={handleDateChange(START_DATE)}
      />
      <span className={styles.separator}>-</span>
      <DateDropdown
        date={toDate}
        dateFormat={dateFormat}
        minDate={fromDate}
        maxDate={maxDate}
        onDateChange={handleDateChange(END_DATE)}
      />
    </div>
  );
};
