import React, { useCallback } from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { IdentityScope } from 'models/identity';
import { User } from 'models/user';
import { TemplatesSelect } from 'components/templates/TemplatesSelect';
import { Template } from 'models/library';
import { useHasNoRestrictions } from './hooks/roles';

type PropsType = {
  selectedIds: string[];
  onChange: (value: IdentityScope[]) => void;
  role: User['role'];
};

export const TemplatesSelector: React.FC<PropsType> = ({
  selectedIds,
  onChange,
  role,
}) => {
  const hasNoRestrictions = useHasNoRestrictions(`${role}`);

  const onSelectedTemplatesChange = useCallback(
    (templates: Template[]) => {
      const scopes: IdentityScope[] = templates.map((template) => {
        return {
          type: 'publisher_template',
          name: `publisher_template:${template.id}`,
        };
      });
      onChange(scopes);
    },
    [onChange]
  );

  return (
    <Fieldset>
      <div className={fieldsetStyles.fieldGroupContainer}>
        <Field label="Templates">
          {hasNoRestrictions ? (
            <div>Selected role has access to all templates</div>
          ) : (
            <TemplatesSelect
              selectedTemplateIds={selectedIds}
              onSelectedTemplatesChange={onSelectedTemplatesChange}
              placeholder="Search templates"
            />
          )}
        </Field>
      </div>
    </Fieldset>
  );
};
