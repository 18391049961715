import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { DismissType } from 'DesignSystem/Components/ClickDropdown';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Pills } from 'DesignSystem/Components/Pills';
import { Button } from 'DesignSystem/Form';
import { Plus } from 'shared/icons';
import { initiativeSort, SelectInitiative } from '../SelectInitiative';
import { ContentTitle, SettingsCard } from '../../../SettingsCard';
import { InfoTooltip } from '../../../Shared/InfoTooltip';

interface InitiativesSectionProps {
  disabled?: boolean;
}

export const InitiativesSection: React.FC<InitiativesSectionProps> = ({
  disabled,
}) => {
  const { settings } = useSettings();
  const [editing, setEditing] = useState(false);

  const selectInitiative = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => (
    <SelectInitiative
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
    />
  );

  return (
    <SettingsCard
      dataTest="initiatives-settings"
      isEditing={false}
      editRenderProp={selectInitiative}
      disabled={disabled}
      onEditingToggle={(value: boolean) => {
        setEditing(value);
      }}
      showEditButton={settings.initiativeTags.length > 0}
    >
      {(toggleEditing: () => void) => (
        <>
          <Box margin={[0, 0, 0, 0]}>
            <ContentTitle>
              Initiatives
              <InfoTooltip message="Add initiatives to tag campaigns for reporting." />
            </ContentTitle>
          </Box>
          <Box>
            <Pills
              values={settings.initiativeTags.sort(initiativeSort)}
              render={(initiative) => initiative.name}
            />

            {settings.initiativeTags.length === 0 && !editing && (
              <Box>
                <Button
                  disabled={disabled}
                  compact
                  onClick={toggleEditing}
                  label={
                    <>
                      <Plus />
                      <Box padding={[0, 12]}>Add initiatives</Box>
                    </>
                  }
                  secondary
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </SettingsCard>
  );
};
