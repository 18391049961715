import * as React from 'react';
import { IconType, Icon } from '../Icon';

export const Content: React.FC<{ title: string } & IconType> = ({
  iconName,
  iconType,
  title,
}) =>
  iconName ? (
    <Icon iconName={iconName} iconType={iconType} />
  ) : (
    <span>{title.substring(0, 2)}</span>
  );
