import {
  NestableCheckboxMenuType,
  NestableMenuDividerType,
  NestableMenuHeaderType,
  NestableMenuType,
  NestableRadioMenuType,
  NestableMenuItemType,
  NestableSimpleMenuType,
  NestableSubmenuType,
} from 'shared/NestableMenu/types';

// NestableMenuItemType type identifiers
export function isMenuDividerType<TValue>(
  item: NestableMenuItemType<TValue>
): item is NestableMenuDividerType {
  return (item as NestableMenuDividerType).isMenuDivider;
}

export function isMenuHeaderType<TValue>(
  item: NestableMenuItemType<TValue>
): item is NestableMenuHeaderType {
  return !!(item as NestableMenuHeaderType).menuHeaderLabel;
}

export function isSubMenuType<TValue>(
  item: NestableMenuItemType<TValue>
): item is NestableSubmenuType<TValue> {
  return !!(item as NestableSubmenuType<TValue>).subMenu;
}

// NestableMenuType type identifiers
export function isSimpleMenuType<TValue>(
  item: NestableMenuType<TValue>
): item is NestableSimpleMenuType<TValue> {
  return !!(item as NestableSimpleMenuType<TValue>).simpleMenu;
}

export function isRadioMenuType<TValue>(
  item: NestableMenuType<TValue>
): item is NestableRadioMenuType<TValue> {
  return !!(item as NestableRadioMenuType<TValue>).radioMenu;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isCheckBoxMenu<TValue>(
  item: NestableMenuType<TValue>
): item is NestableCheckboxMenuType<TValue> {
  return !!(item as NestableCheckboxMenuType<TValue>).checkboxMenu;
}
