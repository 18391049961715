import React from 'react';
import { DateTime } from 'luxon';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { UserExport } from 'models/user-export';
import { useExportToMenuItems, userExportToThumbnail } from './mappings';
import { generateCompletionString } from '../shared/helper';
import styles from '../shared/user-imports.module.css';

export type PropsType = {
  data: UserExport;
};

const formatDate = (d: string | undefined) => {
  return d ? DateTime.fromJSDate(new Date(d)) : undefined;
};

export const UserExportBanner: React.FC<PropsType> = ({ data }) => {
  const menuItems = useExportToMenuItems();
  const thumbnail = userExportToThumbnail(data);
  const showCompletionTime = data.status === 'completed';
  const createdAt = formatDate(data.createdAt)?.toFormat('MMM dd, yyyy hh:mma');
  const completionTime = generateCompletionString(
    data.createdAt,
    data.updatedAt
  );

  const handleFileDownload = () => {
    if (!data.downloadUrl) return;
    window.open(data.downloadUrl, '_blank');
  };

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`user-import-${data.id}`}
      hideFirstMenuItem
    >
      <div
        className={styles.bannerWrapper}
        onClick={handleFileDownload}
        onKeyDown={() => {}}
        tabIndex={0}
        role="button"
      >
        <div className={styles.basic}>
          <div className={styles.title}>{data.identifier}</div>
          <p className={styles.description} title={data.status}>
            {data.status}
          </p>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>TIME</span>
          <span className={styles.stats}>{createdAt || 'NA'}</span>
          <span className={styles.subStats}>
            {showCompletionTime && completionTime}
          </span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>USER</span>
          <span className={styles.stats}>{data.ownerName || 'NA'}</span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>RECORDS PROCESSED</span>
          <span className={styles.stats}>{data.userCount || 'NA'}</span>
        </div>
      </div>
    </EmptyBanner>
  );
};
