import {
  ThumbnailType,
  MenuItemsType,
  IconStatType,
} from 'shared/BannerListItem';

import { ExternalSource } from 'models/external-source';

type TitleAttributes = 'alias' | 'identifier';

export function externalSourceTitle(
  externalSource: Pick<ExternalSource, TitleAttributes>
): string {
  return externalSource.alias || externalSource.identifier;
}

export function externalSourceToThumbnail(
  externalSource: Pick<ExternalSource, 'type' | TitleAttributes>
): ThumbnailType {
  // A touch unfortunate to have a property with a name that is a reserved word in typescript
  const sourceType =
    externalSource.type === 'twitter' ? 'x-twitter' : externalSource.type;

  const BACKGROUND_COLORS: { [key: string]: string } = {
    'x-twitter': '#000000',
    facebook: '#1775eb',
    rss: '#f8a003',
    instagram: '#e14a7a',
    pinterest: '#c6343b',
  };

  return {
    title: externalSourceTitle(externalSource),
    iconName: sourceType,
    iconType: 'FA',
    backgroundColor: BACKGROUND_COLORS[sourceType],
    color: '#ffffff',
  };
}

export function externalSourceToMenuItems(
  programId: number,
  externalSource: Pick<ExternalSource, 'id' | 'active'>,
  actions: {
    archive: (externalSourceId: number) => void;
    unarchive: (externalSourceId: number) => void;
    harvest: (externalSourceId: number) => void;
  }
): MenuItemsType {
  const { id } = externalSource;

  let result: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${programId}/configure/feeds/${id}/edit`,
    },
  ];

  if (externalSource.active) {
    result = [
      ...result,
      {
        title: 'Refresh',
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          actions.harvest(externalSource.id);
        },
      },
      {
        title: 'Archive',
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          actions.archive(externalSource.id);
        },
      },
    ];
  } else {
    result = [
      ...result,
      {
        title: 'Unarchive',
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          actions.unarchive(externalSource.id);
        },
      },
    ];
  }

  return result;
}

type MetaAttributes =
  | 'autoPublish'
  | 'isShareable'
  | 'appendedHashtagEnabled'
  | 'appendedHashtag';

export function externalSourceToMetaStats(
  externalSource: Pick<ExternalSource, MetaAttributes>
): Array<IconStatType> {
  const {
    autoPublish,
    isShareable,
    appendedHashtagEnabled,
    appendedHashtag,
  } = externalSource;

  const result: Array<IconStatType> = [];

  if (autoPublish) {
    result.push({
      iconName: 'Inbox',
      iconType: 'SVG',
      text: 'Auto publish',
    });
  }

  if (isShareable) {
    result.push({
      iconName: 'Share',
      iconType: 'SVG',
      text: 'Shareable',
    });
  }

  if (appendedHashtagEnabled && appendedHashtag) {
    result.push({
      iconName: 'hashtag',
      iconType: 'FA',
      text: appendedHashtag,
    });
  }

  return result;
}
