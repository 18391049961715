import React from 'react';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { useProgram } from 'contexts/program';
import { useVideoCaptionValidator } from 'hooks/useVideoCaptionValidator';
import { uniqueId } from 'hooks/useUniqueId';
import {
  FetchedCaption,
  TranscriptionJob,
  TranslationJob,
} from 'services/api-captions';
import {
  SUBRIP_TIMING,
  vttToSrt,
  WEBVTT_TIMING,
} from '../utils/caption-format-converter';
import {
  isSrt,
  SUBRIP_EXTENSION,
  SUBRIP_MIME_TYPE,
  validateCaptionText,
  WEBVTT_EXTENSION,
  WEBVTT_MIME_TYPE,
} from '../utils/caption-format-validator';
import {
  useCaptionsJobs,
  useTranscribeVideo,
  useTranslateVideo,
} from './useCaptionsJobs';

export type UpdateCaptions = (params: {
  toAdd?: File;
  toRemove?: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => void;

export type CaptionsSubmittedSuccessProps = {
  uploaded?: boolean;
  edited?: boolean;
  deleted?: boolean;
  transcribed?: boolean;
  translated?: boolean;
};

export enum CaptionsType {
  GENERATE = 'generate',
  UPLOAD = 'upload',
}

type UseCaptionSettings = (props: {
  updateCaptions: UpdateCaptions;
  isCaptionsUpdating: boolean;
  currentCaption?: FetchedCaption;
  videoId?: number;
  isDesignAsset?: boolean;
}) => {
  onFormSubmit: (options?: {
    onSuccess?: (props: CaptionsSubmittedSuccessProps) => void;
    onError?: (errors: Error[]) => void;
  }) => void;
  captionText?: string;
  onCaptionTextChange: (captionText: string) => void;
  onReplaceClick: () => void;
  onRemoveClick: () => void;
  onUploadClick: () => void;
  fileInput: React.ReactNode;
  errorMessage?: string;
  isDisabled: boolean;
  validationErrors?: ParseError[];
  isTranscribing: boolean;
  isTranslating: boolean;
  captionsType: CaptionsType | null;
  setCaptionsType: (captionsType: CaptionsType | null) => void;
  autoTranslateCaptions: boolean;
  setAutoTranslateCaptions: (value: boolean) => void;
  selectedLanguages: string[];
  setSelectedLanguages: (languages: string[]) => void;
};

export const useCaptionSettings: UseCaptionSettings = ({
  updateCaptions,
  isCaptionsUpdating,
  currentCaption,
  videoId,
  isDesignAsset,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const isFileEditRef = React.useRef<boolean>(false);
  const toAddRef = React.useRef<File | undefined>(undefined);
  const toRemoveRef = React.useRef<string | undefined>(undefined);

  const onUploadClick = () => fileInputRef.current?.click();
  const [captionText, setCaptionText] = React.useState<string | undefined>(
    currentCaption?.text
  );

  const [validationErrors, setValidationErrors] = React.useState<
    ParseError[]
  >();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  const onReplaceClick = () => {
    onUploadClick();
  };

  const onRemoveClick = () => {
    if (currentCaption) toRemoveRef.current = currentCaption.url;
    toAddRef.current = undefined;
    setCaptionText(undefined);
    setValidationErrors(undefined);
    setErrorMessage(undefined);
    setIsDisabled(typeof toRemoveRef.current === 'undefined');
  };

  const onCaptionTextChange = async (text: string) => {
    setValidationErrors(undefined);
    setErrorMessage(undefined);

    if (text === captionText) {
      toAddRef.current = undefined;
      if (currentCaption) {
        // Only evaluate if editing an existing caption.
        // Editing an uploaded caption should not disable the form.
        setIsDisabled(typeof toRemoveRef.current === 'undefined');
      }
      return;
    }

    const file = createCaptionFile(text);
    if (file) {
      handleNewFile(file, true);
    }

    setIsDisabled(
      typeof toAddRef.current === 'undefined' &&
        typeof toRemoveRef.current === 'undefined'
    );
  };

  const { validate } = useVideoCaptionValidator();

  const [captionsType, setCaptionsType] = React.useState<CaptionsType | null>(
    null
  );

  const [autoTranslateCaptions, setAutoTranslateCaptions] = React.useState(
    false
  );

  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    []
  );

  const { id: programId } = useProgram();

  const {
    isCaptionTranscriptionEnabled,
    setTranscriptionJob,
    setTranslationJob,
  } = useCaptionsJobs();

  const { startTranscription, isTranscribing } = useTranscribeVideo({
    onSuccess: setTranscriptionJob,
  });

  const { startTranslation, isTranslating } = useTranslateVideo({
    onSuccess: setTranslationJob,
    languages: selectedLanguages,
  });

  React.useEffect(() => {
    if (currentCaption) {
      setCaptionsType(null);
    } else if (isCaptionTranscriptionEnabled) {
      setCaptionsType(CaptionsType.GENERATE);
    } else {
      setCaptionsType(CaptionsType.UPLOAD);
    }
  }, [currentCaption, isCaptionTranscriptionEnabled]);

  const handleNewFile = React.useCallback(
    (file: File, isEdit: boolean) => {
      const { isValid, errors } = validate({ file });
      if (isValid) {
        toAddRef.current = file;
        isFileEditRef.current = isEdit;
        return;
      }

      if (errors.length) {
        setErrorMessage(`${file.name}: ${errors.join(', ')}`);
      }
    },
    [validate]
  );

  const onInputChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setValidationErrors(undefined);
      setErrorMessage(undefined);

      let newCaptionValidationErrors: ParseError[] = [];
      const file = event.currentTarget.files?.[0];
      if (file) {
        handleNewFile(file, false);
        const newCaptionText = await file.text();
        newCaptionValidationErrors = validateCaptionText(
          isSrt(file) ? newCaptionText : vttToSrt(newCaptionText)
        );
        setValidationErrors(newCaptionValidationErrors);
        setCaptionText(newCaptionText);
      }
      setIsDisabled(
        (typeof toAddRef.current === 'undefined' &&
          typeof toRemoveRef.current === 'undefined') ||
          !!newCaptionValidationErrors.length
      );

      if (fileInputRef.current) fileInputRef.current.value = '';
    },
    [handleNewFile]
  );

  const createCaptionFile = (editedCaptionText: string) => {
    if (!editedCaptionText || editedCaptionText === captionText)
      return undefined;

    const fileInfo = {
      extension: '.txt',
      mimeType: '',
    };
    if (WEBVTT_TIMING.test(editedCaptionText)) {
      fileInfo.extension = WEBVTT_EXTENSION;
      fileInfo.mimeType = WEBVTT_MIME_TYPE;
    } else if (SUBRIP_TIMING.test(editedCaptionText)) {
      fileInfo.extension = SUBRIP_EXTENSION;
      fileInfo.mimeType = SUBRIP_MIME_TYPE;
    }

    const filename = `edited_caption_${uniqueId()}${
      currentCaption ? `_${currentCaption.language}` : ''
    }${fileInfo.extension}`;

    return new File([editedCaptionText], filename, {
      type: fileInfo.mimeType,
    });
  };

  const generateCaptions = React.useCallback(
    ({ onSuccess, onError } = {}) => {
      const generationPromises: Promise<
        TranscriptionJob | TranslationJob
      >[] = [];
      const successProps: CaptionsSubmittedSuccessProps = {};

      generationPromises.push(
        new Promise((resolve, reject) => {
          startTranscription(
            {
              programId,
              videoId,
              isDesignAsset,
            },
            {
              onSuccess: (job) => {
                successProps.transcribed = true;
                resolve(job);
              },
              onError: reject,
            }
          );
        })
      );

      if (selectedLanguages.length > 0) {
        generationPromises.push(
          new Promise((resolve, reject) => {
            startTranslation(
              {
                programId,
                videoId,
                isDesignAsset,
                languages: selectedLanguages,
              },
              {
                onSuccess: (job) => {
                  successProps.translated = true;
                  resolve(job);
                },
                onError: reject,
              }
            );
          })
        );
      }

      Promise.allSettled(generationPromises).then((results) => {
        const errors: Error[] = [];
        results.forEach(
          (result) =>
            result.status === 'rejected' &&
            errors.push(new Error(result.reason))
        );
        if (errors.length) {
          onError?.(errors);
        } else {
          onSuccess?.(successProps);
        }
      });
    },
    [
      isDesignAsset,
      programId,
      selectedLanguages,
      startTranscription,
      startTranslation,
      videoId,
    ]
  );

  const onFormSubmit = React.useCallback<
    (options?: {
      onSuccess?: (props: CaptionsSubmittedSuccessProps) => void;
      onError?: (errors: Error[]) => void;
    }) => void
  >(
    async ({ onSuccess, onError } = {}) => {
      if (captionsType === CaptionsType.GENERATE) {
        await generateCaptions({ onSuccess, onError });
        return;
      }

      // Using refs for this check because the input change callback won't
      // wait for react state changes.
      const noChange = !toAddRef.current && !toRemoveRef.current;
      const hasErrors = !!errorMessage || !!validationErrors?.length;
      if (noChange || hasErrors) return;

      if (toAddRef.current && isFileEditRef.current) {
        // Validate edits before submission
        const newCaptionText = await toAddRef.current.text();
        const newCaptionValidationErrors = validateCaptionText(
          isSrt(toAddRef.current) ? newCaptionText : vttToSrt(newCaptionText)
        );
        setValidationErrors(newCaptionValidationErrors);
        if (newCaptionValidationErrors?.length) {
          onError?.(newCaptionValidationErrors);
          return;
        }
      }

      updateCaptions({
        toAdd: toAddRef.current,
        toRemove: currentCaption?.url,
        onSuccess: () => {
          onSuccess?.({
            uploaded: !!toAddRef.current,
            edited: isFileEditRef.current,
            deleted: !!toRemoveRef.current,
          });
        },
        onError: (error) => onError?.([error]),
      });
    },
    [
      captionsType,
      currentCaption?.url,
      errorMessage,
      generateCaptions,
      updateCaptions,
      validationErrors?.length,
    ]
  );

  const fileInput = React.useMemo(
    () => (
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept={`${SUBRIP_MIME_TYPE},${SUBRIP_EXTENSION},${WEBVTT_EXTENSION}`}
        disabled={isCaptionsUpdating}
        onChange={onInputChange}
      />
    ),
    [isCaptionsUpdating, onInputChange]
  );

  return {
    onFormSubmit,
    captionText,
    onCaptionTextChange,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    validationErrors,
    isDisabled,
    isTranscribing,
    isTranslating,
    captionsType,
    setCaptionsType,
    autoTranslateCaptions,
    setAutoTranslateCaptions,
    selectedLanguages,
    setSelectedLanguages,
  };
};
