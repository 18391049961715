import {
  CampaignEvent,
  FetchProps,
  fetchSupportedCampaignEvents,
} from 'services/api-campaign-events';
import { useQuery } from 'react-query';
import { QueryResponse } from './common';

export const useSupportedCampaignEventsQuery = (
  props: FetchProps
): QueryResponse<Array<CampaignEvent>> => {
  const { isLoading, error, data, isSuccess } = useQuery<
    Array<CampaignEvent>,
    Error
  >(
    ['campaign-events', { ...props }],
    () => fetchSupportedCampaignEvents(props.programId),
    {
      retry: false,
      onError: props.onError,
    }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    isSuccess,
    data,
  };
};
