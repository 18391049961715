import type { Editor } from '@tiptap/react';
import cx from 'classnames';
import React, { useState } from 'react';
import { useClickDropdown } from 'shared/ClickDropdown';
import { Picker, PICKER_NO_COLOR } from 'shared/ColorPreview';
import { MAIcon } from 'shared/MAIcon';
import editorStyles from '../editor.module.css';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionHighlight: React.FC<{ editor: Editor }> = ({
  editor,
}) => {
  const { ClickDropdown } = useClickDropdown();
  const [prevHighlight, setPrevHighlight] = useState<string>('');
  const isActive = editor.isActive('highlight');
  const highlight_color =
    editor.getAttributes('highlight').color ?? PICKER_NO_COLOR;

  const onPickerChange = (value: string) => {
    setPrevHighlight(value);

    const editorFocus = editor.chain().focus();
    if (value === PICKER_NO_COLOR) {
      editorFocus.unsetHighlight().run();
    } else {
      editorFocus.toggleHighlight({ color: value }).run();
    }
  };

  return (
    <div
      className={cx(editorStyles.fontHighlightAction, {
        [editorStyles.isActive]: isActive,
      })}
    >
      <ClickDropdown
        dropdownRenderProp={() => (
          <Picker
            includeNone
            onChange={onPickerChange}
            value={highlight_color}
          />
        )}
      >
        <ToolbarButton
          disabled={!editor.can().setColor('')}
          aria-label="Highlight Color"
          icon={
            <>
              <MAIcon
                name="border_color"
                className={editorStyles.fontHighlightToggleButton}
              />
              <MAIcon
                name="border_color"
                className={editorStyles.fontHighlightSelectColorButton}
                style={{ color: prevHighlight }}
              />
            </>
          }
        />
      </ClickDropdown>
    </div>
  );
};
