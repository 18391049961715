import { useFlashMessage } from 'contexts/flasher';
import { Program } from 'models/program';
import { useUpdateProgram } from 'hooks/program';
import { useProgram } from 'contexts/program';
import { useFlashServerErrors } from 'utility/errors';

export const useActions = (): {
  update: (program: Program) => void;
} => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();

  const { update } = useUpdateProgram(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Program updated successfully',
      });
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update Program');
    },
  });

  return { update };
};
