import { useQuery } from 'react-query';
import { Page, PaginationData } from 'services/common';
import { Author } from 'models/author';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  prepareQueryString,
  request,
} from './api-shared';
import { QueryResponse } from '../hooks/common';

type AuthorData = {
  id: string; // it comes from studio as a string, let's not touch it to not break anything in studio
  attributes: {
    name: string | null;
    firstName: string | null;
    lastName: string | null;
    displayName: string | null;
    primaryEmail: string | null;
    avatarUrl: string | null;
    role: string | null;
    authorAliasId: number | null;
  };
};

export type AuthorCollectionData = {
  data: Array<Author>;
  meta: PaginationData;
  type?: string;
};

type AuthorAliasData = {
  avatarUrl: null;
  displayName: string;
  id: number;
  programId: number;
  userId: number;
  authorAliasId: number; // backfill content_author record in backend
};

function authorNameForDisplay({ id, attributes }: AuthorData) {
  if (attributes.name) {
    return attributes.name;
  }
  if (attributes.firstName || attributes.lastName) {
    return [attributes.firstName, attributes.lastName].join(' ').trim();
  }
  if (attributes.displayName) {
    return attributes.displayName;
  }
  if (attributes.primaryEmail) {
    return attributes.primaryEmail;
  }
  return `User ${id}`;
}

export function transformAuthorJsonToAuthor(author: AuthorData): Author {
  return {
    defaultDisplayName: authorNameForDisplay(author),
    userId: parseInt(author.id, 10),
    displayName: authorNameForDisplay(author),
    email: author.attributes.primaryEmail ?? undefined,
    avatarUrl: author.attributes.avatarUrl ?? undefined,
    role: author.attributes.role ?? undefined,
  };
}

export function transformAliasJsonToAuthor(author: AuthorAliasData): Author {
  return {
    id: author.id,
    defaultDisplayName: author.displayName,
    userId: author.userId,
    displayName: author.displayName,
    avatarUrl: author.avatarUrl ?? undefined,
    authorAliasId: author.id,
    programId: author.programId,
  };
}

type FetchParams = {
  programId: number;
  useAuthorAliases?: boolean;
  page?: number;
  pageSize?: number;
  query?: string;
  statuses?: string[];
  forgotten?: boolean;
  ids?: number[];
  id?: number[];
  alias_ids?: number[];
  exclude_audiences?: boolean;
};

export async function fetchAuthorPage({
  programId,
  useAuthorAliases,
  ...queryParams
}: FetchParams): Promise<Page<Author>> {
  const queryString = prepareQueryString({ ...queryParams });
  const query = useAuthorAliases ? `author_aliases` : `users/authors`;
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/${query}?${queryString}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data, meta, ...rest }) => {
        return {
          data: data.map(
            useAuthorAliases
              ? transformAliasJsonToAuthor
              : transformAuthorJsonToAuthor
          ),
          meta: {
            currentPage: meta.pageNumber,
            totalRecords: meta.totalObjects,
            ...meta,
          },
          ...rest,
        };
      });
  }

  throw new Error(`Error fetching authors: ${response.status}`);
}

// a copy of the endpoint above with the following corrections:
// only `page` and `query` are accepted. The api does not accept `per_page` param
// this endpoint only returns the list of models and no additional metadata
export type AuthorsFetchParams = {
  programId: number;
  page?: number;
  query?: string;
  id?: number[];
  ids?: number[];
};
export async function fetchAndTransformPaginatedPublishers({
  programId,
  ...queryParams
}: AuthorsFetchParams): Promise<Author[]> {
  const queryString = prepareQueryString(queryParams);
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/users/authors?${queryString}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data }) => data.map(transformAuthorJsonToAuthor));
  }

  throw new Error(`Error fetching authors: ${response.status}`);
}

export const usePublishersQuery = (
  props: FetchParams
): QueryResponse<Author[]> => {
  const { isLoading, error, data } = useQuery<Author[], Error>(
    ['publishers', { ...props }],
    () => fetchAndTransformPaginatedPublishers(props),
    {
      retry: false,
    }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export async function fetchAndTransformPaginatedAuthorAliases({
  programId,
  ...queryParams
}: AuthorsFetchParams): Promise<Author[]> {
  const queryString = prepareQueryString(queryParams);
  // const search = !!queryParams.id;
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/author_aliases?${queryString}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data }) => data.map(transformAliasJsonToAuthor));
  }

  throw new Error(`Error fetching authors: ${response.status}`);
}

export const useAuthorAliasesQuery = (
  props: FetchParams
): QueryResponse<Author[]> => {
  const { isLoading, error, data } = useQuery<Author[], Error>(
    ['author_aliases', { ...props }],
    () => fetchAndTransformPaginatedAuthorAliases(props),
    {
      retry: false,
    }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
