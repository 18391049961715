import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { WithPermission } from 'shared/WithPermission';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { Section } from 'DesignSystem/Form';
import { ConfirmationModalShow } from './ConfirmationModal';
import { DefaultChannelsShow } from './DefaultChannels';
import { useConfirmationModal } from './ConfirmationModal/useConfirmationModal';
import { useDefaultChannels } from './DefaultChannels/useDefaultChannels';
import { useEngagementBoostDefaults } from './EngagementBoostDefaults/useEngagementBoostDefaults';
import { EngagementBoostDefaultsShow } from './EngagementBoostDefaults';
import styles from './campaign-settings.module.css';

export const CampaignSettingsShow: React.FC<RouteComponentProps> = () => {
  const {
    enabled: defaultChannelsEnabled,
    channelsValues,
  } = useDefaultChannels();
  const {
    enabled: confirmationModalEnabled,
    modalEnabled,
    initialThreshold,
  } = useConfirmationModal();
  const {
    enabled: engagementBoostDefault,
    engBoostDefaultsValues,
  } = useEngagementBoostDefaults();

  return (
    <WithPermission permissions={['configureCampaignSettingsAccess']}>
      <ListPage
        title="Campaign Settings"
        description="Configure campaign settings and defaults"
        breadcrumbs={[
          { to: '..', label: 'Configure' },
          { label: 'Campaign Settings' },
        ]}
        actions={[
          {
            icon: <Icon name="Edit" size={20} fill="var(--color-gray00)" />,
            label: 'Edit Settings',
            to: 'edit',
          },
        ]}
      >
        <Section className={styles.SettingsSection}>
          {defaultChannelsEnabled && (
            <DefaultChannelsShow channelsValues={channelsValues} />
          )}
          {engagementBoostDefault && (
            <EngagementBoostDefaultsShow
              engBoostDefaultsValues={engBoostDefaultsValues}
            />
          )}
          {confirmationModalEnabled && (
            <ConfirmationModalShow
              modalEnabled={modalEnabled}
              threshold={initialThreshold}
            />
          )}
        </Section>
      </ListPage>
    </WithPermission>
  );
};
