import * as React from 'react';
import { SocialFieldData } from 'models/publisher/block';
import { FieldFormProps } from '../../useFieldForm';
import { Panel } from '../shared/Panel';
import { useKeepOpen } from '../shared/KeepOpen';
import { ModalForm } from './components/ModalForm';
import { List } from './components/List';
import { ExitBlocker } from './components/ExitBlocker';
import { useSocialDataMutator } from './hooks/useSocialDataMutator';
import { useSocialDataSelector } from './hooks/useSocialDataSelector';

export const Social: React.FC<FieldFormProps<SocialFieldData[]>> = (props) => {
  const mutator = useSocialDataMutator(props);
  const selected = useSocialDataSelector(mutator);
  const isEmpty = mutator.items.length < 1;
  useKeepOpen(ExitBlocker, isEmpty);
  return (
    <Panel
      title="Social Links Collection"
      description="Display social links for your program"
      helperHref="https://support.firstup.io/hc/en-us/articles/4466926885271-Create-a-Social-Link-Block"
    >
      <List
        links={mutator.items}
        onSelect={selected.select}
        onUpdate={mutator.changeItems}
      />
      {selected.item ? (
        <ModalForm
          initial={selected.item}
          onSave={selected.change}
          onCancel={selected.deselect}
        />
      ) : null}
    </Panel>
  );
};
