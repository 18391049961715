import React from 'react';
import cx from 'classnames';
import style from '../../Filters.module.css';

type InputTextPropsType = {
  onChange: (value: string) => void;
  value: string;
};
export const InputText: React.FC<InputTextPropsType> = ({
  onChange,
  value,
}) => {
  return (
    <div className={style.filterDropdownInputTextBody}>
      <input
        type="text"
        value={value}
        className={cx(style.filterDropdownInputText)}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
