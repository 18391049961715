import * as React from 'react';
import Select, { components, OptionProps, ValueType } from 'react-select';
import * as Text from 'DesignSystem/Typography';
import { Box, Tooltip } from 'DesignSystem/Components';
import { Checkbox } from 'DesignSystem/Form';
import checkboxStyles from 'shared/Checkbox/Checkbox.module.css';
import {
  PersonalizedFieldsAttr,
  PersonalizedFieldsFile,
} from 'models/personalized-fields';
import { MAIcon } from 'shared/MAIcon';
import styles from '../styles.module.css';

export type UpdateModalMode =
  | 'new'
  | 'waiting'
  | 'error'
  | 'confirm'
  | 'complete';

type IdFieldOption = { value: number; label: string };
type WrappedIdFieldOption = ValueType<IdFieldOption>;
const toOption = (item: PersonalizedFieldsAttr) => {
  return { value: item.id, label: item.name };
};

const EmailOption = {
  value: 'email',
  label: 'Email',
  icon: 'alternate_email',
};
const UIDOption = {
  value: 'universal_identifier',
  label: 'Universal Identifier',
  icon: 'numbers',
};
type IdTypeOption = typeof EmailOption | typeof UIDOption;
type WrappedIdTypeOption = ValueType<IdTypeOption>;
const IdTypes = {
  email: EmailOption as IdTypeOption,
  universal_identifier: UIDOption as IdTypeOption,
} as Record<string, IdTypeOption>;

const IconOption = (props: OptionProps<IdTypeOption>) => {
  const { Option } = components;
  const {
    data: { icon, label },
  } = props;
  return (
    <Option {...props /* eslint-disable-line react/jsx-props-no-spreading */}>
      <MAIcon name={icon} />
      {label}
    </Option>
  );
};

const IndeterminateCheckbox: React.FC<{ name: string }> = ({ name }) => {
  return (
    <label style={{ display: 'flex' }}>
      <div className={checkboxStyles.checkboxContainer}>
        <MAIcon
          name="horizontal_rule"
          className={`${styles.IndeterminateCheckbox} styled-checkbox`}
        />
      </div>
      <div className={checkboxStyles.checkboxLabel}>{name}</div>
    </label>
  );
};

const Checkboxes: React.FC<{
  attributes: PersonalizedFieldsAttr[];
  onChange: (id: number, checked: boolean) => void;
}> = ({ attributes, onChange }) => {
  const toCheckbox = ({
    id,
    active,
    employeeId,
    name,
  }: PersonalizedFieldsAttr) => {
    const key = `${id}${active}${employeeId}`;
    return employeeId ? (
      <Tooltip content={name} placement="top-start">
        <div>
          <IndeterminateCheckbox key={key} name={name} />
        </div>
      </Tooltip>
    ) : (
      <Tooltip content={name} placement="top-start">
        <div>
          <Checkbox
            key={key}
            name={key}
            checked={active}
            label={name}
            onChange={(x) => onChange(id, x)}
          />
        </div>
      </Tooltip>
    );
  };
  const mid = Math.ceil(attributes.length / 2);
  return (
    <Box
      className={styles.AttrCheckboxes}
      height={Math.ceil(attributes.length / 2) * 40}
    >
      <Box>{attributes.slice(0, mid).map(toCheckbox)}</Box>
      <Box>{attributes.slice(mid).map(toCheckbox)}</Box>
    </Box>
  );
};

export const FileSettings: React.FC<{
  file: PersonalizedFieldsFile;
  mode: UpdateModalMode;
  onChange: (settings: Partial<PersonalizedFieldsFile>) => void;
}> = ({ file, mode, onChange, children }) => {
  const [idFieldChoices, setIdFieldChoices] = React.useState<IdFieldOption[]>(
    []
  );
  const [idType, setIdType] = React.useState<WrappedIdTypeOption>(null);
  const [idFieldFilter, setIdFieldFilter] = React.useState<string>();
  const [attributes, setAttributes] = React.useState<PersonalizedFieldsAttr[]>(
    []
  );

  const fileState = React.useRef('');
  React.useEffect(() => {
    if (fileState.current !== file.state) {
      setIdType(file.idType ? IdTypes[file.idType] : null);
      setAttributes(file.attributes.sort(({ id }, { id: rhs }) => id - rhs));
      fileState.current = file.state;
    }
  }, [fileState, file]);

  React.useEffect(() => {
    const filter = idFieldFilter?.toLowerCase();
    setIdFieldChoices(
      (filter
        ? attributes.filter(({ name }) => name.toLowerCase().includes(filter))
        : attributes
      ).map(toOption)
    );
  }, [attributes, idFieldFilter]);

  const updateAttributes = React.useCallback(
    (callback: (attrs: typeof attributes) => typeof attributes) => {
      setAttributes((attrs) => {
        const result = callback(attrs);
        onChange({ attributes: result });
        return result;
      });
    },
    [onChange]
  );

  const idFieldChange = React.useCallback(
    (opt: WrappedIdFieldOption) => {
      const { value } = opt as IdFieldOption;
      updateAttributes((attrs) =>
        attrs.map((a) => ({ ...a, employeeId: value === a.id }))
      );
    },
    [updateAttributes]
  );
  const idTypeChange = React.useCallback(
    (opt: WrappedIdTypeOption) => {
      const option = opt as IdTypeOption;
      onChange({ idType: option.value });
      setIdType(option);
    },
    [onChange]
  );
  const fieldActiveToggle = React.useCallback(
    (id: number, active: boolean) => {
      updateAttributes((attrs) =>
        attrs.map((a) => (a.id === id ? { ...a, active } : { ...a }))
      );
    },
    [updateAttributes]
  );

  return (
    <Box className={styles.UpdateForm}>
      <Text.Body bold>{file.name}</Text.Body>
      <Box margin="24px 0 0 0">
        <Text.Body block>
          {mode === 'new'
            ? 'Select the column that is your employee identifier'
            : 'Employee identifier column'}
        </Text.Body>
      </Box>
      <Select
        placeholder="Type to search columns"
        value={attributes
          .filter(({ employeeId }) => employeeId)
          .map(toOption)
          .pop()}
        options={idFieldChoices}
        isDisabled={mode === 'complete' || mode === 'confirm'}
        onChange={idFieldChange}
        onInputChange={setIdFieldFilter}
        className={`${styles.SettingsSelect} ${styles.SettingsIdFieldSelect}`}
      />
      <Box>
        <Text.Body block>
          Identifier type
          <Tooltip content="Universal identifier is the value used to identify each individual in your community. Each user must have a unique value for either email or universal identifier">
            <span className={styles.TooltipIcon}>
              <MAIcon name="help" />
            </span>
          </Tooltip>
        </Text.Body>
      </Box>
      <Select
        placeholder="Click to make a selection"
        defaultValue={idType}
        value={idType}
        options={[IdTypes.email, IdTypes.universal_identifier]}
        isDisabled={mode === 'complete' || mode === 'confirm'}
        className={styles.SettingsSelect}
        components={{ Option: IconOption }}
        onChange={idTypeChange}
      />
      <Box margin="20px 0 0 0">
        <Text.Body>
          Manage columns (all columns will become variables)
        </Text.Body>
      </Box>
      <Checkboxes attributes={attributes} onChange={fieldActiveToggle} />
      {children}
    </Box>
  );
};
