import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Subheading } from 'DesignSystem/Typography';
import { Popover } from 'DesignSystem/Components/Popover';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';
import { Author, AuthorAlias, BaseAuthor } from 'models/author';
import { SearchInput } from 'shared/SearchInput';
import { Button } from 'DesignSystem/Form';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { useAuthorsList } from 'hooks/useAuthorsList';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { AuthorName } from '../AuthorName';

interface SelectAuthorProps {
  author: BaseAuthor;
  disabled?: boolean;
  onSaveDisabledChange: (disabled: boolean) => void;
  setDismissable?: (dismissable: boolean) => void;
}

export type SelectAuthorHandle = {
  getSettings: () => {
    contentAuthor: BaseAuthor;
  };
};

export const SelectAuthor = React.forwardRef<
  SelectAuthorHandle,
  SelectAuthorProps
>(
  (
    {
      author,
      disabled = false,
      setDismissable = undefined,
      onSaveDisabledChange,
    }: SelectAuthorProps,
    ref: React.Ref<SelectAuthorHandle | null>
  ) => {
    const [saveEnabled, setSaveEnabled] = useState<boolean>(true);
    const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
    const [selectedAuthor, setSelectedAuthor] = useState<BaseAuthor>(author);
    const [searchText, setSearchText] = useState<string>('');
    const { id: programId } = useProgram();
    const useAuthorAliases = !!useFeatureFlagsQuery(
      programId,
      'Studio.Publish.AuthorAliases'
    ).data?.value;

    const {
      data: authorsData,
      isLoading,
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage,
    } = useAuthorsList(searchText, true);

    useImperativeHandle(ref, () => ({
      getSettings() {
        return {
          contentAuthor: selectedAuthor,
        };
      },
    }));

    const getAuthorRow = React.useCallback(
      (rowId) => {
        const rowData = rowId.split('||');
        if (useAuthorAliases) {
          return authorsData.find(
            (a) =>
              a.displayName === rowData[0] &&
              `${a.authorAliasId}` === rowData[1]
          );
        }

        return authorsData.find((a) => {
          const authorWithType = a as Author;
          return (
            authorWithType.displayName === rowData[0] &&
            `${authorWithType.userId}` === rowData[1]
          );
        });
      },
      [authorsData, useAuthorAliases]
    );

    const authorRowRenderer = (dismiss: DismissType) => (rowId: string) => {
      const authorRow = getAuthorRow(rowId);
      return (
        authorRow && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            padding={[9, 16, 0, 16]}
            onClick={() => {
              setSelectedAuthor(authorRow);
              if (selectedAuthor.displayName !== authorRow.displayName) {
                setSaveEnabled(true);
              }
              dismiss();
            }}
          >
            <Box>
              <AuthorName author={authorRow} />
            </Box>
          </Box>
        )
      );
    };

    // disable dismiss if the save button has been enabled
    useEffect(() => {
      if (setDismissable) setDismissable(!saveEnabled);
      onSaveDisabledChange(!saveEnabled);
    }, [setDismissable, saveEnabled, onSaveDisabledChange]);

    const selectAuthorDropdown = (dismiss: DismissType) => (
      <Box margin={[-8, 0, 0, 0]} minWidth={400}>
        <Popover padding={4}>
          <InfiniteSelect
            rowIds={authorsData.map((a) => {
              const authorWithType = useAuthorAliases
                ? (a as AuthorAlias)
                : (a as Author);
              return useAuthorAliases
                ? `${authorWithType.displayName}||${authorWithType.authorAliasId}`
                : `${authorWithType.displayName}||${authorWithType.userId}`;
            })}
            rowRenderProp={authorRowRenderer(dismiss)}
            maxHeight={400}
            itemHeight={40}
            selectedIds={[selectedAuthor.displayName]}
            onSelectedIdsChange={(ids) => ids}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            hoverFocus={false}
            clearDisabled
            searchTerm={searchText}
            onSearchTermChange={setSearchText}
            searchEnabled={false}
            noShadow
            multiSelect={false}
          />
        </Popover>
      </Box>
    );

    return (
      <>
        <Box padding={[0, 16, 16, 0]}>
          <Subheading bold>Author</Subheading>
        </Box>
        <Box dataTest="author-selector" padding={[0, 0, 8, 0]}>
          <ClickDropdown
            dropdownRenderProp={selectAuthorDropdown}
            matchReferenceWidth
            disabled={disabled}
            autoUpdate
            ignoreKeys
            referencePress={false}
            onOpen={() => setDropDownOpen(true)}
            onClose={() => {
              setDropDownOpen(false);
              setSearchText('');
            }}
          >
            {!dropDownOpen ? (
              <Button
                block
                input
                label={<AuthorName author={selectedAuthor} />}
                badge={<SVGIcon name="DownChevron" />}
              />
            ) : (
              <div>
                <SearchInput
                  placeholder="Search Author"
                  value={searchText || ''}
                  onChange={setSearchText}
                />
              </div>
            )}
          </ClickDropdown>
        </Box>
      </>
    );
  }
);

SelectAuthor.defaultProps = {
  disabled: false,
  setDismissable: undefined,
};
