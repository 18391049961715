import * as React from 'react';
import { createPortal } from 'react-dom';
import { useSidebarPopout } from './useSidebarPopout';
import style from './style.module.css';

export const SidebarPopout: React.FC<ButtonProps & MenuProps> = ({
  buttonChildren,
  buttonClassName,
  menuChildren,
  topOffset,
  dataTestButton,
  dataTestMenu,
  disabled,
}) => {
  const { ref, visible, toggle, portal } = useSidebarPopout({ top: topOffset });
  const menu = React.useMemo(() => {
    if (!portal.ready) return null;
    return createPortal(
      <div
        className={style.PopoutMenu}
        ref={portal.ref}
        style={portal.style}
        data-test={dataTestMenu}
      >
        {menuChildren}
      </div>,
      portal.portal
    );
  }, [
    portal.ready,
    portal.portal,
    portal.ref,
    portal.style,
    menuChildren,
    dataTestMenu,
  ]);

  return (
    <div ref={ref}>
      <button
        type="button"
        className={buttonClassName}
        onClick={toggle}
        data-test={dataTestButton}
        disabled={disabled}
      >
        {buttonChildren}
      </button>
      {visible && menu}
    </div>
  );
};

type ButtonProps = {
  buttonChildren: React.ReactNode;
  buttonClassName?: string;
  dataTestButton?: string;
  disabled?: boolean;
};

type MenuProps = {
  menuChildren: React.ReactNode;
  topOffset?: number;
  dataTestMenu?: string;
};
