import React from 'react';
import { ImageData } from 'models/image';
import {
  LinkData,
  LinkType,
  SocialData,
  SocialType,
  blankImageUrl,
} from 'models/publisher/block';
import { getSocialNetworkImage } from 'utility/social-network-logos';
import { UPLOAD } from 'components/publisher/blocks/forms/fields/shared/SourceMenuConst';
import { ServerImagesAvailabilityData } from 'services/api-assets';
import { useLinkFetchQuery } from './useLinkFetchQuery';
import { useDebounceWithState } from './useDebounce';
import { uniqueId } from './useUniqueId';
import { useImagesAvailabilityQuery } from './useImagesAvailabilityQuery';

type PropsType = {
  initial?: LinkData | SocialData;
  type: LinkType | SocialType;
};

type HookFunc = (
  props: PropsType
) => {
  setUrl: (url: string) => void;
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setCallToAction: (callToAction: string) => void;
  setOpenInNewTab: (value: boolean) => void;
  setImage: (image: ImageData) => void;
  setAltText: (altText: string) => void;
  setReadTime: (time: string) => void;
  deleteImage: () => void;
  reset: () => void;
  canDeleteImage: boolean;
  isLoading: boolean;
  imagesAvailability: {
    isLoading: boolean;
    status?: ServerImagesAvailabilityData['status'];
  };
  hasChanges: boolean;
  link: LinkData | SocialData;
  error?: string;
  isWaitingForDebounce: boolean;
};

export function empty(type: LinkType | SocialType): LinkData | SocialData {
  return {
    title: {
      type: 'plain-text',
      value: '',
    },
    description: {
      type: 'plain-text',
      value: '',
    },
    url: '',
    image: {
      url: type === 'social' ? getSocialNetworkImage().url : '',
      processed: false,
    },
    openInNewTab: true,
    callToAction: {
      type: 'plain-text',
      value: 'Read more →',
    },
    images: [],
    uuid: uniqueId(),
    type,
  };
}

export const useLinkFetcher: HookFunc = ({
  initial: suppliedInitial,
  type,
}) => {
  const initial = suppliedInitial || empty(type);
  const [link, setLink] = React.useState<LinkData | SocialData>(
    initial || empty(type)
  );
  const [identifier, setIdentifier] = React.useState<number>();
  const [recentlyDeleted, setRecentlyDeleted] = React.useState<boolean>(false);
  const {
    debouncedValue: debouncedLink,
    isWaiting: isWaitingForDebounce,
  } = useDebounceWithState(link.url, 1500);
  const fetchLink =
    (recentlyDeleted && link.url) ||
    (!recentlyDeleted && debouncedLink !== initial.url)
      ? debouncedLink
      : '';

  const fetcher = useLinkFetchQuery(fetchLink);

  const onImagesAvailabilitySuccess = React.useCallback(
    (data: ServerImagesAvailabilityData) => {
      if (data.status === 'unavailable') {
        setLink((prev) => ({
          ...prev,
          images: [],
          image: { url: blankImageUrl, processed: true },
        }));
      }
    },
    []
  );
  const imagesAvailabilityFetcher = useImagesAvailabilityQuery(
    fetcher.data && fetcher.data.images
      ? fetcher.data.images.map((d) => d.url)
      : [],
    onImagesAvailabilitySuccess
  );

  const isCustomImage =
    initial.images.some((i) => i.source === UPLOAD) ||
    initial.image.source === UPLOAD;

  React.useEffect(() => {
    if (
      fetcher.data &&
      (identifier !== fetcher.data.id ||
        (identifier === fetcher.data.id &&
          recentlyDeleted &&
          link.url.length > 0))
    ) {
      setRecentlyDeleted(false);
      const predefinedImage: ImageData = getSocialNetworkImage(
        fetcher.data.requestedUrl
      );
      const imageValue = fetcher.data?.imageUrl || fetcher.data.images[0]?.url;

      const newLinkImg =
        fetcher.data.imageUrl === null && fetcher.data.images.length < 1
          ? predefinedImage
          : {
              url: imageValue,
              processed: imageValue === blankImageUrl || undefined,
            };
      const images = [...fetcher.data.images];

      if (fetcher.data.imageUrl) {
        images.unshift({ url: fetcher.data.imageUrl });
      }
      const newLink = {
        images: [],
        title: {
          type: 'plain-text',
          value: fetcher.data.title,
        },
        openInNewTab: link.openInNewTab,
        callToAction: fetcher.data.callToAction
          ? { type: 'plain-text', value: fetcher.data.callToAction }
          : link.callToAction || empty(type).callToAction,
        description: {
          type: 'plain-text',
          value: fetcher.data.description,
        },
        url: fetcher.data.requestedUrl,
        requestedUrl: fetcher.data.requestedUrl,
        uuid: uniqueId(),
        image: newLinkImg,
        type: 'link',
      };

      const urlTable: { [url: string]: true } = {};
      const fetchedImages = images.filter((img) => {
        if (!urlTable[img.url]) {
          urlTable[img.url] = true;
          return true;
        }
        return false;
      });
      if (link.type === 'link') {
        const value = {
          ...newLink,
          image: newLinkImg,
          images: fetchedImages,
          readTimeInSeconds: fetcher.data.readTimeInSeconds,
        } as LinkData;
        setLink(value);
      } else {
        const value = {
          ...newLink,
          image:
            predefinedImage.url.length > 0
              ? predefinedImage
              : empty(type).image,
          images: predefinedImage.url.length > 0 ? [] : fetchedImages,
          type: 'social',
        } as SocialData;
        setLink(value);
      }
      setIdentifier(fetcher.data.id);
    }
  }, [
    initial,
    fetcher.data,
    link.url,
    link.type,
    identifier,
    type,
    link,
    isCustomImage,
    recentlyDeleted,
  ]);

  const setTitle = React.useCallback(
    (title: string) =>
      setLink({ ...link, title: { type: 'plain-text', value: title } }),
    [link]
  );

  const setDescription = React.useCallback(
    (description: string) =>
      setLink({
        ...link,
        description: { type: 'plain-text', value: description },
      }),
    [link]
  );

  const setCallToAction = React.useCallback(
    (callToAction: string) =>
      setLink({
        ...link,
        callToAction: { type: 'plain-text', value: callToAction },
      }),
    [link]
  );

  const setOpenInNewTab = React.useCallback(
    (openInNewTab: boolean) => {
      setLink({
        ...link,
        openInNewTab,
      });
    },
    [link]
  );

  const setReadTime = React.useCallback(
    (time: string) => {
      if (link.type === 'link') {
        setLink({
          ...link,
          readTimeInSeconds: time ? parseInt(time, 10) : 0,
        });
      }
    },
    [link]
  );

  const setImage = React.useCallback(
    (image: ImageData) => setLink({ ...link, image }),
    [link]
  );

  const setAltText = React.useCallback(
    (altText: string) =>
      setLink({
        ...link,
        image: {
          ...link.image,
          altText,
        },
      }),
    [link]
  );

  const setUrl = React.useCallback(
    (url: string) => {
      const { callToAction } = { ...link };
      setLink({
        ...empty(type),
        callToAction,
        url,
        openInNewTab: link.openInNewTab,
      });
    },
    [link, type]
  );

  const hasChanges = React.useMemo(() => {
    if (!initial) return true;

    return (
      initial.url !== link.url ||
      initial.title.value !== link.title.value ||
      initial.description.value !== link.description.value ||
      initial.image.url !== link.image.url ||
      initial.image.altText !== link.image.altText ||
      initial.callToAction?.value !== link.callToAction?.value ||
      initial.openInNewTab !== link.openInNewTab ||
      (link.type === 'link' &&
        initial.readTimeInSeconds !== link.readTimeInSeconds)
    );
  }, [initial, link]);

  const deleteImage = React.useCallback(() => {
    setLink({
      ...link,
      image: initial.image || empty(type).image,
    });
  }, [setLink, link, initial.image, type]);

  const canDeleteImage = !!(
    link.image?.url &&
    (link.image.url !== initial.image?.url || link.image?.source === UPLOAD)
  );

  const reset = React.useCallback(() => {
    setLink({ ...empty(type) });
    setRecentlyDeleted(true);
  }, [type]);

  return {
    link,
    setUrl,
    setTitle,
    setImage,
    setDescription,
    setCallToAction,
    setAltText,
    setReadTime,
    setOpenInNewTab,
    deleteImage,
    canDeleteImage,
    isLoading: fetcher.isLoading,
    error: fetcher.errorMessage || imagesAvailabilityFetcher.errorMessage,
    imagesAvailability: {
      isLoading: imagesAvailabilityFetcher.isLoading,
      status: imagesAvailabilityFetcher.data?.status,
    },
    hasChanges,
    isWaitingForDebounce,
    reset,
  };
};
