import React from 'react';
import { CircleCheck } from 'shared/icons';
import { Box } from 'DesignSystem/Components';
import styles from './status-label.module.css';

export const StatusLabel: React.FC<{
  isReadyToPublish: boolean;
}> = ({ isReadyToPublish }) => {
  return (
    <div className={styles.Title}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isReadyToPublish ? (
          <>
            <CircleCheck width={25.33} height={25.33} />
            <Box margin={[0, 0, 0, 20]}>Ready to Publish</Box>
          </>
        ) : (
          <>
            <CircleCheck
              width={25.33}
              height={25.33}
              className={styles.circleCheckAlmostReady}
            />
            <Box margin={[0, 0, 0, 20]}>Almost Ready to Publish</Box>
          </>
        )}
      </div>
    </div>
  );
};
