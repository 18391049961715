import React from 'react';
import cx from 'classnames';
import style from '../../Filters.module.css';

type InputNumberPropsType = {
  onChange: (value: number) => void;
  value: number;
  onFocus?: () => void;
  onBlur?: () => void;
};
export const InputNumber: React.FC<InputNumberPropsType> = ({
  onChange,
  value,
  onFocus,
  onBlur,
}) => {
  return (
    <div className={style.filterDropdownInputTextBody}>
      <button
        className={cx(
          style.filterDropdownInputNumberMinusBtn,
          style.filterDropdownInputNumberBtn
        )}
        type="button"
        tabIndex={0}
        onClick={() => onChange(value - 1)}
      >
        -
      </button>
      <input
        type="number"
        value={value}
        className={cx(style.filterDropdownInputText)}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button
        className={cx(
          style.filterDropdownInputNumberPlusBtn,
          style.filterDropdownInputNumberBtn
        )}
        type="button"
        tabIndex={0}
        onClick={() => onChange(value + 1)}
      >
        +
      </button>
    </div>
  );
};
