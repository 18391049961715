import React, { FC, useEffect } from 'react';
import { navigate, RouteComponentProps, Router } from '@reach/router';
import { useProgram } from '../../../../../../contexts/program';
import DetailsTab from './Details';
import SettingsTab from './Settings';
import { useTopicQuery } from '../../../../../../hooks/topics';
import { useFlashServerErrors } from '../../../../../../utility/errors';
import { LoadingScreen } from '../../../../../../shared/LoadingScreen';
import { MainContainer } from './MainContainer';

const TopicDetails: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  if (!id) throw new Error('id is required');
  const { id: programId } = useProgram();
  const { data: topic, error } = useTopicQuery(programId, id);
  const flashServerErrors = useFlashServerErrors();
  useEffect(() => {
    if (error) {
      flashServerErrors(error, 'Unable to view topic');
      navigate(`/${programId}/configure/topics`);
    }
  }, [error, programId, flashServerErrors]);

  if (!topic) {
    return <LoadingScreen />;
  }

  return (
    <MainContainer topic={topic}>
      <Router primary={false}>
        <DetailsTab topic={topic} path="/" />
        <SettingsTab topic={topic} path="/settings" />
      </Router>
    </MainContainer>
  );
};

export default TopicDetails;
