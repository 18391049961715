import React from 'react';
import { DateTime } from 'luxon';
import { pluralize } from 'utility/text';

export type TimeDelta =
  | { value: number; unit: string; formatted: string }
  | undefined;

export const useTimeDelta = (fromTime: DateTime | undefined): TimeDelta => {
  const timeDelta = React.useMemo(() => {
    if (fromTime) {
      const lastSaveLocal = fromTime?.setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const delta = Math.max(
        0,
        DateTime.local().toMillis() - lastSaveLocal.toMillis()
      );
      const seconds = delta / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      let value;
      let unit;
      if (days > 1) {
        value = days;
        unit = 'day';
      } else if (hours > 1) {
        value = hours;
        unit = 'hour';
      } else if (minutes > 1) {
        value = minutes;
        unit = 'minute';
      } else {
        value = seconds;
        unit = 'second';
      }
      const floor = Math.floor(value);
      return {
        value,
        unit,
        formatted: `${floor} ${pluralize(floor, unit)} ago`,
      };
    }
    return undefined;
  }, [fromTime]);

  return timeDelta;
};
