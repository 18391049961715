import * as React from 'react';
import cx from 'classnames';
import { useUniqueId } from 'hooks/useUniqueId';
import { useJquery, useOnReady } from 'hooks/useJquery';
import styles from './styles.module.css';

// So you found yourself in here, maybe wanting more options?
// jQuery likes classNames for the effects, so avoid
// passing in styles, etc. Create a specialized className,
// and add it as an option. See `smallImage` as an example.
type IdFn<T> = (item: T, index: number) => string;

type SortableItemProps<T> = {
  items: T[];
  idAttr: keyof T | IdFn<T>;
  Item: React.FC<{ item: T; index: number }>;
  onSort(items: T[]): void;
};

type SortableDisplayProps = Partial<{
  itemType: 'List' | 'GridSm';
  width: string;
  height: string;
  handleClass: string;
  className: string;
}>;

export function Sortable<T>({
  Item,
  items,
  idAttr,
  onSort,
  itemType = 'List',
  height,
  width,
  handleClass,
  className,
}: SortableItemProps<T> & SortableDisplayProps): React.ReactElement {
  const idFunction = React.useCallback<IdFn<T>>(
    (item, index) => {
      const key =
        typeof idAttr === 'function' ? idAttr(item, index) : item[idAttr];
      return (key || `key-${index}`) as string;
    },
    [idAttr]
  );
  const onSortIds = React.useCallback(
    (ids: string[]) => {
      const sortedItems: T[] = ids.map((id) =>
        items.find(
          (item, index) => String(idFunction(item, index)) === String(id)
        )
      ) as T[];
      onSort(sortedItems);
    },
    [idFunction, items, onSort]
  );
  const id = useUniqueId();
  useOnReady(($) => {
    const ul = $(`#${id}`);
    ul.sortable();
    ul.disableSelection();
  });
  useJquery(($) => {
    const ul = $(`#${id}`);
    ul.sortable({
      handle: handleClass,
      // When not using a handle, drag will not start if pressing one of these "input,textarea,button,select,option"
      cancel: handleClass ? '' : 'input,textarea,button,select,option',
      update: () => onSortIds(ul.sortable('toArray')),
    });
  });
  return (
    <ul
      style={{ height, width }}
      id={id}
      className={cx(styles.List, className)}
    >
      {items.map((item, index) => (
        <li
          id={idFunction(item, index)}
          key={idFunction(item, index)}
          className={cx({
            [styles.ItemGridSm]: itemType === 'GridSm',
            [styles.ItemList]: itemType === 'List',
          })}
        >
          <Item index={index} item={item} />
        </li>
      ))}
    </ul>
  );
}
