import React from 'react';
import { Subheading, Caption } from 'DesignSystem/Typography';
import { FieldLegend } from 'components/publisher/blocks/forms/fields/shared/FieldLegend';
import { FieldInput } from 'DesignSystem/Form';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { PersonalizationInput } from 'App/Program/Editors/Publisher/Deliver/Notifications/PersonalizationInput';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './communication-config.module.css';

export const NotificationField: React.FC<{
  field: string;
  fieldName: string;
  placeholder?: string;
  inputPrefixText?: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  maxSize?: number;
  errorMessage?: string;
}> = ({
  field,
  onChange,
  fieldName,
  placeholder,
  inputPrefixText,
  errorMessage,
  disabled = false,
  maxSize = MAX_NOTIFICATION_LENGTH,
}) => {
  return (
    <>
      <FieldInput
        fieldSetClass={styles.Fieldset}
        legend={
          <FieldLegend block>
            <Subheading bold>{fieldName}</Subheading>
          </FieldLegend>
        }
      >
        <Flex column>
          <PersonalizationInput
            errorMessage={!!errorMessage}
            inputPrefixText={inputPrefixText}
            maxSize={maxSize}
            value={field}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
          />
          <Flex className={styles.errorsContainer} spread>
            <Flex>
              {errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
              )}
            </Flex>
            <Caption>
              {field.length} / {maxSize} characters
            </Caption>
          </Flex>
        </Flex>
      </FieldInput>
    </>
  );
};
