import * as SourceMenu from '../SourceMenu/useSourceMenu';

const { OPENED, CLOSED } = SourceMenu;
export { OPENED, CLOSED };

export const EXTERNAL = 'external' as const;
export const LIBRARY = 'library' as const;

export type Source = typeof EXTERNAL | typeof LIBRARY;

export const MENU: SourceMenu.Config<Source> = [
  { name: 'URL', source: EXTERNAL },
  { name: 'Content Library', source: LIBRARY },
];

export function useLinksSourceMenu(
  initial: SourceMenu.State<Source> = { source: EXTERNAL, visibility: CLOSED }
): SourceMenu.MenuIface<Source> {
  return SourceMenu.useSourceMenu(MENU, initial);
}
