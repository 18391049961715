import React, { FC, useContext } from 'react';
import { ConfirmModal } from 'DesignSystem/Components';
import { useApplicableErrors } from '../../../topicErrorHooks';
import { TopicErrorsPillToggleContext } from '../common/ErrorPill/TopicErrorsPillToggleContext';

export interface PublishErrorsModalProps {
  onDismiss: () => void;
}

export const PublishErrorsModal: FC<PublishErrorsModalProps> = ({
  onDismiss,
}) => {
  const { length: errorCount } = useApplicableErrors();
  const { openTopicErrorsPill } = useContext(TopicErrorsPillToggleContext);

  function handleDismiss() {
    openTopicErrorsPill();
    onDismiss();
  }

  const message =
    errorCount === 1
      ? 'There is 1 error that needs to be fixed before publishing this topic.'
      : `There are ${errorCount} errors that need to be fixed before publishing this topic.`;

  return (
    <ConfirmModal
      onCancel={handleDismiss}
      title="Errors"
      hideCancel
      onConfirm={handleDismiss}
      confirmLabel="OK"
    >
      <p>{message}</p>
    </ConfirmModal>
  );
};
