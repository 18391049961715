import React from 'react';
import { Template } from 'models/library';
import { TemplatesList } from './TemplatesList';
import { useLibraryTemplates } from '../../hooks/useLibrary';

export const TemplateSelector: React.FC<{
  parentRef: React.RefObject<HTMLDivElement>;
  onSelect?: (template: Template) => void;
}> = ({ parentRef, onSelect }) => {
  const {
    isLoading,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useLibraryTemplates({
    filter: { type: 'all' },
    sortBy: 'title',
    permittedToUseOnly: true,
  });
  return (
    <TemplatesList
      isLoading={isLoading}
      templates={data}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      parentRef={parentRef}
      onSelect={onSelect}
    />
  );
};
