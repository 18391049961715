import * as React from 'react';
import cx from 'classnames';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import {
  ContentDesignTabs,
  TabType,
} from 'components/publisher/blocks/panels/ContentDesignTabs';
import { Alert, AlertType } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { SelectedTab } from './SelectedTab';
import styles from './styles.module.css';
import { CanvasStylesPanel } from './CanvasPanels';

export const SelectedSidebar: React.FC = () => {
  const { selected } = React.useContext(BlocksEditorContext);

  const { canEdit, errors, isLoading: isEditableLoading } = React.useContext(
    DesignPermissionContext
  );

  const contentTabButton = React.useMemo(() => {
    if (!selected?.id) return null;
    const selector = `[data-block-id="${selected?.id}"][data-block-action="open-editor"]`;
    return document.querySelector(selector) as HTMLElement | null;
  }, [selected?.id]);

  const sectionTabButton = React.useMemo(() => {
    if (!selected?.id) return null;
    const selector = `[data-block-id="${selected?.id}"][data-block-action="open-section"]`;
    return document.querySelector(selector) as HTMLElement | null;
  }, [selected?.id]);

  const goToContentTab = React.useCallback(
    (tabName: TabType) => {
      if (tabName === TabType.Content) {
        contentTabButton?.click();
      }
      if (tabName === TabType.Section) {
        sectionTabButton?.click();
      }
    },
    [contentTabButton, sectionTabButton]
  );

  const visibleTabs = React.useMemo(() => {
    const tabs = ['design'];
    if (contentTabButton) {
      tabs.push('content');
    }

    return tabs as Array<TabType>;
  }, [contentTabButton]);

  return selected && selected.block.name !== 'custom_html' ? (
    <CanvasStylesPanel side="right">
      <div id="publisher-selected-sidebar">
        <div className={cx(styles.sidebarPadding, styles.sidebarMargin)}>
          {!isEditableLoading && !canEdit && (
            <div className={cx(styles.sidebarMargin)}>
              <Alert
                type={AlertType.error}
                title="Editing unavailable"
                message={errors.map((error) => (
                  <div>{error}</div>
                ))}
                bgColor="red"
                enableIcon
                icon={<MAIcon name="warning" />}
              />
            </div>
          )}
          <ContentDesignTabs
            visibleTabs={visibleTabs}
            active={TabType.Design}
            activate={goToContentTab}
          />
        </div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <SelectedTab {...selected} />
      </div>
    </CanvasStylesPanel>
  ) : null;
};
