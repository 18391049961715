import * as React from 'react';
import { useProgram } from 'contexts/program';
import { FocusDropdown } from 'shared/FocusDropdown';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { TopicTag } from 'models/topic-tag';
import { useTopicTagsInfiniteQuery } from 'hooks/topic-tags';
import { useDebounce } from 'hooks/useDebounce';
import { Dropdown } from './Dropdown';
import styles from './topic-tag-select.module.css';

export type PropsType = {
  selectedTopicTags: Array<TopicTag>;
  onSelectedTopicTagsChange: (topicTags: Array<TopicTag>) => void;
  disabled?: boolean;
};

export const TopicTagSelect: React.FC<PropsType> = (props) => {
  const { selectedTopicTags, onSelectedTopicTagsChange, disabled } = props;

  const [searchText, setSearchText] = React.useState('');
  const debouncedSearchText = useDebounce(searchText, 300);

  const { id: programId } = useProgram();
  const {
    isLoading,
    data: values,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTopicTagsInfiniteQuery({
    programId,
    query: debouncedSearchText,
  });

  const selectedIds = selectedTopicTags.map((tag) => tag.id || '');
  const selectedValues = selectedTopicTags.map((tag) => tag?.name || '');

  const topicTags = [...values];
  const TopicTagIds: Array<string> = values.map((item) => item.id || '');
  const uniqueIds = new Set([...TopicTagIds]);
  selectedTopicTags.forEach((value) => {
    if (value.id && !uniqueIds.has(value.id)) {
      topicTags.push(value);
    }
  });

  function handleRemove(index: number) {
    const newSelectedTopicTags = [...selectedTopicTags];
    newSelectedTopicTags.splice(index, 1);
    onSelectedTopicTagsChange(newSelectedTopicTags);
  }

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedTopicTags = ids
      .map((id) =>
        topicTags.find((audience) => audience?.id?.toString() === id.toString())
      )
      .filter((item) => !!item);
    onSelectedTopicTagsChange(newSelectedTopicTags as Array<TopicTag>);
  }

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={topicTags}
        selectedIds={selectedIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        disabled={disabled}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={selectedValues}
            onRemoveSelectedValueAt={handleRemove}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};
