import { Editor } from '@tiptap/core';
import { useToggle } from 'hooks/useToggle';
import React from 'react';
import { Text } from 'DesignSystem/Typography';
import { Button, styles as formStyles } from 'DesignSystem/Form';
import cx from 'classnames';
import { FileUploadButton } from 'shared/FileUploadButton';
import { useProgramIdState } from 'contexts/program';
import { useImageUploader } from 'hooks/useImageUploader';
import { ImageData } from 'models/image';
import { ImageUploaderModal } from 'shared/Modal/ImageUploaderModal';
import modalStyles from 'shared/Modal/image-uploader-modal.module.css';
import { ToolbarButton } from './EditorActions/shared/ToolbarButton';

export function EditorActionInsertImage({
  editor,
}: {
  editor: Editor;
}): JSX.Element {
  const [imageData, setImageData] = React.useState<ImageData | null>(null);

  const { value: showModal, toggle: toggleModal } = useToggle();
  const [programId] = useProgramIdState();

  const onUpload = React.useCallback(
    (data: ImageData) => {
      if (data) {
        setImageData(data);
      }
    },
    [setImageData]
  );

  const {
    update: updateImage,
    isUploading,
    errors: imageUploadErrors,
  } = useImageUploader({
    onUpload,
    programId,
  });

  const handleFileSelect = React.useCallback(
    (newFile: File) => {
      updateImage(newFile);
    },
    [updateImage]
  );

  const insertImage = () => {
    if (!imageData) return;
    editor.chain().focus().setImage({ src: imageData.url }).run();
    resetImage();
    toggleModal();
  };

  const resetImage = () => setImageData(null);

  return (
    <>
      <ToolbarButton
        onClick={() => {
          toggleModal();
        }}
        aria-label="Insert image"
        icon="photo"
      />
      {showModal && (
        <ImageUploaderModal
          errors={imageUploadErrors}
          onCancel={toggleModal}
          image={imageData}
          handleFileSelect={handleFileSelect}
          handleFileRemove={resetImage}
          uploading={isUploading}
          submitButton={<Button onClick={insertImage} label="Save" />}
        >
          <div className={modalStyles.uploader}>
            <section className={modalStyles.content}>
              <Text
                as="p"
                className={{
                  Subheading: true,
                  Semibold: true,
                }}
                style={{
                  margin: 0,
                }}
              >
                Drop file to insert
              </Text>
              <Text
                as="p"
                className={{
                  Subheading: true,
                }}
                style={{
                  margin: 0,
                }}
              >
                or
              </Text>
              <FileUploadButton
                accept={'image/*'}
                type="blank"
                onFileSelect={handleFileSelect}
                className={cx(formStyles.Button)}
              >
                Select File
              </FileUploadButton>
            </section>
            <Text
              as="p"
              className={{
                Caption: true,
                gray60: true,
              }}
            >
              Maximum upload file size: 10 MB.
            </Text>
          </div>
        </ImageUploaderModal>
      )}
    </>
  );
}
