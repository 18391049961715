import { useCampaignTarget } from 'components/publisher/settings/SettingsEditor/useCampaignTarget';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useTopicsSubscribersInAudienceQuery } from './audience';
import { useStickyResponse } from './common';

export function useContentUserCount(): {
  uniqueUserCount: number | undefined;
  topicSubscribersCount: number | undefined;
  isLoading: boolean;
} {
  const { audiences, topics, all } = useCampaignTarget();

  const {
    post: { settings },
  } = usePublisher();

  const {
    limitVisibilityToAudience,
    audiences: audienceFromSettings,
  } = settings;
  const { id: programId } = useProgram();

  const { isLoading, data: topicSubscribersInAudience } = useStickyResponse(
    useTopicsSubscribersInAudienceQuery(
      programId,
      settings.contentTopics,
      audienceFromSettings,
      { enabled: limitVisibilityToAudience }
    )
  );

  const uniqueUserCount = limitVisibilityToAudience
    ? audiences.users?.totalObjects
    : all.data?.totalObjects;

  const topicSubscribersCount = limitVisibilityToAudience
    ? topicSubscribersInAudience?.totalObjects
    : topics.data?.totalObjects;

  return {
    isLoading,
    uniqueUserCount,
    topicSubscribersCount,
  };
}
