import { useProgram } from 'contexts/program';
import { useResetPassword } from 'hooks/user';
import React, { useState } from 'react';
import { Modal } from 'shared/Modal';
import { useFlashMessage } from 'contexts/flasher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { usePermissions } from 'contexts/permissions';
import { User } from 'models/user';
import { Icon } from 'shared/Icon';
import styles from './sidebar.module.css';

interface ResetPasswordProps {
  userToReset: User;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ userToReset }) => {
  const { id: programId } = useProgram();
  const [passwordResetLink, setPasswordResetLink] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { setFlashMessage } = useFlashMessage();

  const { resetPassword } = useResetPassword(programId, {
    onSuccess: (url) => {
      setPasswordResetLink(() => url);
      setShowPasswordModal(() => true);
    },
    onError: (err) => setFlashMessage({ severity: 'error', message: err }),
  });

  const copyPasswordLink = () => {
    navigator.clipboard
      .writeText(passwordResetLink)
      .then(() =>
        setFlashMessage({
          severity: 'info',
          message: 'Password Reset URL has been copied to the clipboard.',
        })
      )
      .catch((e) => e);
  };

  const { canPromoteTo } = usePermissions();

  const showPermissionsUI = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  ).data?.value;

  const userToResetIsInStatusThatAllowsPasswordReset =
    userToReset.status &&
    ['active', 'registering'].includes(userToReset.status);

  if (!userToResetIsInStatusThatAllowsPasswordReset) return null;

  const currentUserHasPermissionsToResetPassword =
    showPermissionsUI ||
    canPromoteTo.includes(userToReset.role || 'super_admin');

  if (!currentUserHasPermissionsToResetPassword) return null;

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => resetPassword(Number(userToReset.id))}
      >
        <span>
          <Icon iconName="ResetPassword" iconType="SVG" />
        </span>
        <span>Reset Password</span>
      </div>
      <Modal
        showModal={showPasswordModal}
        title="Reset Password"
        showTitle
        onClose={() => setShowPasswordModal(false)}
      >
        <div className={styles.passwordLinkWarning}>
          This Reset Password link will expire in one hour.
        </div>
        <div className={styles.passwordLinkWrapper}>{passwordResetLink}</div>
        <div>
          <button
            className={styles.copyButton}
            type="button"
            onClick={copyPasswordLink}
          >
            Copy
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ResetPassword;
