import React from 'react';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { Field, Fieldset } from 'shared/Fieldset';
import { FileUploadButton } from 'shared/FileUploadButton';
import { Section } from 'shared/SectionBlocks';
import { Stats } from '../../Configuration/Content/Users/Import/View/Stats';
import { EmptyStats } from '../../Configuration/Content/Users/Import/View/EmptyStats';
import styles from './import-users.module.css';
import { useProgram } from '../../../../contexts/program';
import { useFeatureFlagsQuery } from '../../../../hooks/feature-flags';
import { useRolesInfiniteQuery } from '../../../../hooks/roles';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

type SideBarType = {
  job?: BulkUploadJob;
  error?: string;
  onFileSelect: (file: File) => void;
};

export const ImportUsersForm: React.FC<SideBarType> = ({
  job,
  onFileSelect,
  error,
}) => {
  const { id: programId } = useProgram();

  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;

  const { data: roles, isLoading: isRolesLoading } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });

  const downloadTemplate = () => {
    const csv =
      'First Name,Last Name,Email Address,Universal Identifier,Program Role\n';
    if (navigator.msSaveBlob) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      navigator.msSaveBlob(blob, 'template_example.csv');
    } else {
      const uri = `data:text/csv;charset=utf-8,${csv}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = 'template_example.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const errorText = () => {
    return job && job.errors && job.errors.length > 0
      ? job.errors.join('. ')
      : '';
  };

  return (
    <div className={styles.middleBar}>
      {!job && (
        <>
          <Section title="">
            <Fieldset>
              <div className={styles.fieldGroupContainer}>
                <Field label="">
                  <FileUploadButton
                    accept="text/csv"
                    iconName="upload"
                    iconType="FA"
                    onFileSelect={onFileSelect}
                  >
                    Choose file...
                  </FileUploadButton>
                  <p className={styles.havingTrouble}>
                    Having trouble?{' '}
                    <a
                      href="#1"
                      onClick={() => downloadTemplate()}
                      className={styles.downloadTemplateLink}
                    >
                      Download a template
                    </a>
                  </p>
                </Field>
              </div>
            </Fieldset>
          </Section>
        </>
      )}

      {error && (
        <div className={styles.rowInfo}>
          <div className={styles.label}>Label</div>
          <div className={styles.description}>{error}</div>
        </div>
      )}
      {job && job.errors && job.errors.length > 0 && (
        <Section title="Error">
          <Fieldset>
            <div className={styles.fieldGroupContainer}>
              <Field label="Label">{errorText()}</Field>
            </div>
          </Fieldset>
        </Section>
      )}
      {job && (
        <Section title="Summary">
          <Fieldset>
            <div className={styles.fieldGroupContainer}>
              <Field label="Label">{job?.fileName}</Field>
            </div>
          </Fieldset>
          {job.data?.stats && !isRolesLoading && (
            <Stats
              stats={job.data.stats}
              roles={roles}
              permissionsEnabled={permissionsEnabled}
            />
          )}
          {!job.data?.stats && !isRolesLoading && (
            <EmptyStats roles={roles} permissionsEnabled={permissionsEnabled} />
          )}
        </Section>
      )}
    </div>
  );
};
