import React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
// import { Pills } from 'DesignSystem/Components/Pills';
import { Button, Section } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { DefaultAvatar } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { AliasData } from 'services/api-author-alias';
// import { UserData } from 'services/api-user';
import { useAuthorAliasQuery } from 'hooks/author-alias';
// import { useUsersInfiniteQuery } from 'hooks/user';

import forms from 'DesignSystem/Form/forms.module.css';
import styles from './author-alias.module.css';

// type UserDataWithName = UserData & { displayName: string };

export const ViewAuthorAlias: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { data: authorAlias, errorMessage, isLoading } = useAuthorAliasQuery(
    programId,
    id as number
  );
  // const { data: studioUsers, errorMessage: usersError } = useUsersInfiniteQuery(
  //   {
  //     programId,
  //     scopes: [`author_alias:${id}`],
  //   }
  // );
  const { setFlashMessage } = useFlashMessage();

  const [redirectUrl, setRedirectUrl] = React.useState('');

  React.useEffect(() => {
    if (errorMessage)
      setFlashMessage({ severity: 'error', message: upcase(errorMessage) });
    if (errorMessage?.toLowerCase() === 'forbidden') setRedirectUrl('../../');
  }, [errorMessage, setFlashMessage]);

  // React.useEffect(() => {
  //   if (usersError)
  //     setFlashMessage({
  //       severity: 'error',
  //       message: 'Failed to load studio users',
  //     });
  // }, [usersError, setFlashMessage]);

  function upcase(word = '') {
    return word[0]?.toUpperCase() + word.slice(1);
  }

  // function with_display_name(callback: (user: UserDataWithName) => ReactNode) {
  //   return (user: UserData) =>
  //     callback({
  //       ...user,
  //       displayName: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
  //     });
  // }

  function icon(type: string, { avatarUrl, displayName }: Partial<AliasData>) {
    const name = type === 'team' ? `the ${displayName} alias` : displayName;
    return (
      <span
        className={styles[`${upcase(type)}Avatar`]}
        style={avatarUrl ? { backgroundImage: `url(${avatarUrl})` } : {}}
        title={name ? `The avatar for ${name}` : 'The default avatar'}
      >
        {!avatarUrl && <DefaultAvatar />}
      </span>
    );
  }

  return redirectUrl ? (
    <Redirect noThrow to={redirectUrl} />
  ) : (
    <WithPermission permissions={['confContentAccess']}>
      <FormPage
        title={authorAlias?.displayName || 'Author Alias Details'}
        breadcrumbs={[
          { label: 'Configure', to: '../../..' },
          { label: 'Aliases', to: '../..' },
          { label: authorAlias?.displayName || 'Author Alias Details' },
        ]}
      >
        <Section
          title="Alias Settings"
          description={
            authorAlias?.aliasType === 'program'
              ? 'The program alias can only be edited through community configuration.'
              : undefined
          }
          button={
            authorAlias && authorAlias.aliasType !== 'program' ? (
              <Button compact href="../edit" label="Edit" secondary />
            ) : undefined
          }
        >
          <div className={forms.body}>
            {isLoading && (
              <div className={styles.Loading}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && (
              <>
                <dl className={styles.PropertiesWrapper}>
                  <dt
                    className={
                      authorAlias?.aliasType === 'user'
                        ? styles.PropertiesLabelWellPadded
                        : styles.PropertiesLabel
                    }
                  >
                    Display Name
                  </dt>
                  <dd
                    className={
                      authorAlias?.aliasType === 'user'
                        ? styles.PropertiesValueWellPadded
                        : styles.PropertiesValue
                    }
                  >
                    {authorAlias?.aliasType === 'user' &&
                      icon('user', authorAlias || {})}
                    {authorAlias?.displayName}
                  </dd>
                </dl>
                {authorAlias?.aliasType !== 'program' && (
                  <dl className={styles.PropertiesWrapper}>
                    <dt className={styles.PropertiesLabel}>Description</dt>
                    <dd
                      className={
                        authorAlias?.description
                          ? styles.PropertiesValue
                          : styles.NoValue
                      }
                    >
                      {authorAlias?.description || 'No description'}
                    </dd>
                  </dl>
                )}
                {authorAlias?.aliasType !== 'user' && (
                  <dl className={styles.PropertiesWrapper}>
                    <dt className={styles.PropertiesLabel}>Display Avatar</dt>
                    <dd className={styles.PropertiesValue}>
                      {icon('team', authorAlias || {})}
                    </dd>
                  </dl>
                )}
                {/*
                 *<dl className={styles.PropertiesWrapper}>
                 *  <dt className={styles.PropertiesLabelPadded}>Studio Users</dt>
                 *  <dd className={styles.PropertiesValueForPills}>
                 *    <Pills
                 *      values={studioUsers}
                 *      render={with_display_name(({ displayName }) => (
                 *        <span className={styles.PillText}>{displayName}</span>
                 *      ))}
                 *      pillIcon={with_display_name((user) => icon('pill', user))}
                 *    />
                 *  </dd>
                 *</dl>
                 */}
              </>
            )}
          </div>
        </Section>
      </FormPage>
    </WithPermission>
  );
};
