import React from 'react';
import { Filter } from 'contexts/workflow/filters';
import { InitiativesFilter } from 'components/initiatives/InitiativeSelect/Filter';
import { FilterDropdown } from './FilterDropdown';

type PropsType = {
  filter: Filter;
  setFilterValues: (name: string, value: Array<string>) => void;
};

export const FilterItem: React.FC<PropsType> = ({
  filter,
  setFilterValues,
}) => {
  const onChannelChange = React.useCallback(
    (channels) => {
      setFilterValues('channels', channels);
    },
    [setFilterValues]
  );

  const onVisibilityChange = React.useCallback(
    (visibility) => {
      setFilterValues('visibility', visibility);
    },
    [setFilterValues]
  );

  const onInitiativeChange = React.useCallback(
    (initiatives) => {
      setFilterValues('initiatives', initiatives);
    },
    [setFilterValues]
  );

  switch (filter.name) {
    case 'channels':
      return (
        <FilterDropdown
          label="Type"
          states={filter.states as string[]}
          value={filter.values as string[]}
          onChange={onChannelChange}
        />
      );
    case 'visibility':
      return (
        <FilterDropdown
          label="Visibility"
          states={filter.states as string[]}
          value={filter.values as string[]}
          onChange={onVisibilityChange}
        />
      );
    case 'initiatives':
      return (
        <InitiativesFilter
          value={filter.values as string[]}
          onChange={onInitiativeChange}
        />
      );
    default:
      return <div>Unknown filter</div>;
  }
};
