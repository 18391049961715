import React from 'react';

import vtiStyles from 'shared/VariableTextInput/vti.module.css';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { useContextCommunication } from 'hooks/context_communication';
import { VARIABLES_RE } from 'utility/variablesRegex';
import styles from '../PersonalizationInput.module.css';

type SubjectLinePreviewProps = {
  subjectText?: string;
};

export const SubjectLinePreview: React.FC<SubjectLinePreviewProps> = ({
  subjectText,
}) => {
  const { id: programId } = useProgram();

  const { data: communicationMentionsData } = useContextCommunication(
    programId,
    false
  );
  const variableTokens = React.useMemo(
    () => communicationMentionsData?.map((variable) => `{{${variable.key}}}`),
    [communicationMentionsData]
  );

  // extract unique variables from the provided subject line (anything between {{ and }})
  const variables = React.useMemo(
    () =>
      subjectText
        ?.match(VARIABLES_RE)
        ?.filter((value, index, self) => self.indexOf(value) === index)
        ?.filter((value) => variableTokens?.includes(value)),
    [variableTokens, subjectText]
  );

  // split the subject line on any variables and retain the variable delimeter
  const previewTokens = React.useMemo(() => {
    if (variables?.length === 0) {
      return [subjectText || ''];
    }

    const variableSplit = new RegExp(`(${variables?.join('|')})`, 'g');
    return subjectText
      ?.split(variableSplit)
      .filter((token) => token.length > 0); // remove any empty matches
  }, [subjectText, variables]);

  const renderToken = (token: string) => (
    <span
      key={`render-token-${token}`}
      className={cx({
        [vtiStyles.variableTextInput]: variables?.includes(token),
        [styles.variableText]: variables?.includes(token),
      })}
    >
      {variables?.includes(token)
        ? communicationMentionsData?.find(
            (variable) => `{{${variable.key}}}` === token
          )?.name
        : token}
    </span>
  );

  return <>{previewTokens?.map(renderToken)}</>;
};
