import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { FilterDropdown } from 'shared/FilterDropdown';

type PropTypes = {
  onStatusChange: (value: string[]) => void;
  selectedStatus?: string[];
};

export const FiltersBar: React.FC<PropTypes> = ({
  selectedStatus,
  onStatusChange,
}) => {
  const statusOptions = [
    { value: 'created', label: 'Created' },
    { value: 'processing', label: 'Processing' },
    { value: 'partial', label: 'Partial' },
    { value: 'completed', label: 'Completed' },
    { value: 'failed', label: 'Failed' },
  ];
  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.filterSortRow}>
        <div className={styles.filters}>
          <FilterDropdown
            label="Status"
            options={statusOptions}
            onChange={onStatusChange}
            selectedOptions={statusOptions.filter((opt) =>
              selectedStatus?.includes(opt.value)
            )}
          />
        </div>
      </div>
    </div>
  );
};
