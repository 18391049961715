import { useInfiniteQuery } from 'react-query';
import {
  MicroappsCollectionData,
  fetchMicroapps,
  FetchProps,
} from 'services/api-microapps';
import { InfiniteQueryResponse, nextPageToFetch } from './common';
import { Microapp } from '../models/microapp';

// function mapDataToMicroapp(data: MicroappData): Microapp {
//   return { ...data.attributes, id: data.id };
// }

export const useMicroappsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<Microapp> => {
  const { programId, search, perPage = 100, status } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<MicroappsCollectionData, Error>(
    ['microapps', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchMicroapps({
        programId,
        search,
        page: pageParam as number,
        status,
        perPage,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, perPage),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) =>
        batch
          ? batch.data.map((microappData) => {
              return {
                ...microappData,
                id: microappData.id,
              };
            })
          : []
      )
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    meta: data?.pages[0].meta,
    data: flatData || [],
  };
};
