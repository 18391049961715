import React, { useEffect, useState } from 'react';
import { Modal } from 'shared/Modal';
import { Button } from 'shared/Button';
import { Textarea } from 'shared/forms/Textarea';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { usePost } from 'hooks/publisher';

import styles from './notes-modal.module.css';

type NoteslModalProps = {
  postId: number;
  onClose: () => void;
  programId: number;
};

export const NotesModal: React.FC<NoteslModalProps> = ({
  postId,
  onClose,
  programId,
}) => {
  const {
    updateNotes,
    status: { isSaving },
    post: currentPost,
  } = usePost(programId, postId, false);
  const [notes, setNotes] = useState(currentPost?.content.contentNote || '');

  useEffect(() => {
    if (currentPost) {
      setNotes(currentPost?.content.contentNote || '');
    }
  }, [currentPost]);

  const handleSaving = () => {
    updateNotes(notes, onClose);
  };
  return (
    <Modal title="Campaign Notes" showTitle showModal onClose={onClose}>
      <form className={styles.form}>
        <Textarea
          onChange={(note) => setNotes(note)}
          value={notes}
          className={styles.textArea}
          rows={7}
        />
        <div className={styles.buttonsWrapper}>
          {isSaving && (
            <div className={styles.spinner}>
              <LoadingSpinner size="small" />
            </div>
          )}
          <Button
            type="primary"
            disabled={isSaving || notes.length <= 0}
            onClick={handleSaving}
          >
            Save
          </Button>
          <div className={styles.cancelWrapper}>
            <Button type="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
