import snakecaseKeys from 'snakecase-keys';
import { request, deepCamelcaseKeys } from 'services/api-shared';
import qs from 'qs';
import { PaginationData } from './common';

export type FetchParams = {
  page?: number;
  pageSize?: number;
  status?: string | string[];
  programId: number;
};

export type ContentTemplateData = {
  id: string;
  attributes: {
    name: string;
    status: string;
  };
};

export type ContentTemplatesCollectionData = {
  data: Array<ContentTemplateData>;
  meta: PaginationData;
};

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}/samba`;

export const fetchContentTemplates = async (
  props: FetchParams
): Promise<ContentTemplatesCollectionData> => {
  const { programId, ...queryParams } = props;
  const query = qs.stringify(snakecaseKeys(queryParams));
  const url = `${apiRoot}/programs/${programId}/content_templates?${query}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching content templates: ${response.status}`);
};
