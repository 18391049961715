import React, { createContext, useContext, useState } from 'react';

type StickyNavBarContextData = {
  isShowStickyNavBar: boolean;
  isShowNavShadow: boolean;
  handlePageScroll: (e: React.RefObject<HTMLDivElement>) => void;
  setIsShowStickyNavBar: (isShow: boolean) => void;
};

const StickyNavBarContext = createContext<StickyNavBarContextData>({
  isShowStickyNavBar: true,
  isShowNavShadow: false,
  handlePageScroll: (() => {}) as (e: React.RefObject<HTMLDivElement>) => void,
  setIsShowStickyNavBar: (() => {}) as (isShow: boolean) => void,
});

export const useStickyNavBarContext = (): StickyNavBarContextData => {
  const context = useContext(StickyNavBarContext);
  return context;
};

export const StickyNavBarProvider: React.FC = ({ children }) => {
  const [isShowStickyNavBar, setIsShowStickyNavBar] = useState(true);
  const [isShowNavShadow, setIsShowNavShadow] = useState(false);

  const handlePageScroll = React.useCallback(
    (el: React.RefObject<HTMLDivElement>) => {
      if (!el || !el.current) return;

      const element = el.current;
      element.addEventListener('scroll', () => {
        if (element.scrollTop > 5) setIsShowNavShadow(() => true);
        else setIsShowNavShadow(false);
      });
    },
    []
  );

  const value = {
    isShowStickyNavBar,
    isShowNavShadow,
    handlePageScroll,
    setIsShowStickyNavBar,
  };

  return (
    <StickyNavBarContext.Provider value={value}>
      {children}
    </StickyNavBarContext.Provider>
  );
};
