import React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { useProgram } from 'contexts/program';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { LitmusRole } from 'models/role';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';
import { Details } from './Details';
import { GetScopesData } from './get-scopes-data';
import styles from '../bulk-updates.module.css';

const prepareError = (errors?: string[]) => {
  return errors && errors.length ? 'Some users failed to update' : 'No errors';
};

export const Main: React.FC<{
  job: BulkUpdatePermissionsJob;
}> = ({ job }) => {
  const errorMessage = prepareError(job.errors?.messages);
  const { id: programId } = useProgram();
  const definedScopes = GetScopesData(job.scopes);

  const { data: roles, isLoading: isRolesLoading } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });
  return (
    <div className={styles.userImportsPage}>
      <Section title="Error">
        <Row>
          <Block>
            <div>{errorMessage}</div>
          </Block>
        </Row>
      </Section>
      <Section title="Summary">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Job ID">{job.id || 'NA'}</Field>
          </div>
        </Fieldset>
        {!isRolesLoading && (
          <Details
            action={job.action}
            jobRole={job.roles[0]}
            jobScopes={job.scopes}
            definedRoles={roles as LitmusRole[]}
            definedScopes={definedScopes}
          />
        )}
      </Section>
    </div>
  );
};
