import React, { FC } from 'react';
import { Topic } from 'models/topic';
import { Redirect, Router } from '@reach/router';
import { TopicFormContext, useTopicFromCtxValue } from './context';
import DesignView from './components/DesignView';
import SettingsView from './components/SettingsView';
import { AboutEditor } from './components/AboutEditor';
import { ReviewView } from './components/ReviewView/ReviewView';
import { AboutDesignContextProvider } from './components/DesignView/contexts/about';
import {
  TopicErrorsApplicableOnlyAfterPublishAttempt,
  TopicErrorProvider,
} from '../topicErrorHooks';
import { topicErrorDefinitions } from '../topicErrorDefinitions';
import { TopicErrorsPillToggleProvider } from './components/common/ErrorPill/TopicErrorsPillToggleContext';

interface Props {
  topic: Topic;
  baseUrl: string;
}

const TopicForm: FC<Props> = ({ topic, baseUrl }): JSX.Element => {
  const ctx = useTopicFromCtxValue(baseUrl, topic);
  return (
    <TopicFormContext.Provider value={ctx}>
      <AboutDesignContextProvider programId={topic.programId} topic={topic}>
        <TopicErrorsApplicableOnlyAfterPublishAttempt>
          <TopicErrorProvider errorDefinitions={topicErrorDefinitions}>
            <TopicErrorsPillToggleProvider initalValue={false}>
              <Router>
                <Redirect from="/" to="design" noThrow />
                <DesignView path="design" />
                <AboutEditor path="about/:id/*" />
                <SettingsView path="settings" />
                <ReviewView path="review" />
              </Router>
            </TopicErrorsPillToggleProvider>
          </TopicErrorProvider>
        </TopicErrorsApplicableOnlyAfterPublishAttempt>
      </AboutDesignContextProvider>
    </TopicFormContext.Provider>
  );
};

export default TopicForm;
