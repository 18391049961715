import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { BossViewPage } from 'shared/BossView/Page';

export const LegacyUserAccess: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['securityAccess']}>
    <BossViewPage
      title="User Access"
      src="#sign-in-options"
      app="programs"
      returnUrls={['/configure/user-access']}
    />
  </WithPermission>
);
