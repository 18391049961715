import React, { ComponentProps } from 'react';
import { ConfirmModal, Tooltip } from 'DesignSystem/Components';
import {
  mergeReactQueryStatuses,
  useRefetchPendingAthenaOutput,
} from 'hooks/athena';
import { useUniqueId } from 'hooks/useUniqueId';
import { DefaultLoading } from 'shared/InfiniteList/DefaultLoading';
import { Text } from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { MAIcon } from 'shared/MAIcon';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { QueryStatus } from 'react-query';
import { Sparkles } from 'shared/icons';
import { SupportedCommands } from 'services/api-ai-chat-completion';
import modalStyles from './summarize-with-ai-modal.module.css';
import { useSummarizeWithAiContext } from './summarize-with-ai';

const modalTitles: Record<SupportedCommands | string, string> = {
  modal_summary: 'Improve my writing with AI',
  fix_spelling_grammar: 'Fix spelling and grammar with AI',
  use_concise_language: 'Use concise language with AI',
  change_tone: 'Change tone with AI',
};

const MODAL_DESCRIPTION =
  'Enhance entire text blocks or specific highlighted sections of text in the editor';

export function SummarizeWithAiModal({
  onModalConfirm,
  onModalCancel,
  command,
}: {
  onModalConfirm: (content: string) => void;
  onModalCancel: () => void;
  command: string;
}): JSX.Element | null {
  const {
    summarizableContent,
    athenaTaskOutputId,
    regenerateSummarizationTask,
    resetSummarizationState,
    createTaskStatus,
  } = useSummarizeWithAiContext();

  const {
    data: athenaOutputData,
    status: pollingStatus,
    stopRefetching,
  } = useRefetchPendingAthenaOutput(athenaTaskOutputId ?? '', {
    pendingRefetchInterval: 1000,
  });

  if (!summarizableContent) {
    return null;
  }

  const summarizeStatus: QueryStatus = mergeReactQueryStatuses(
    createTaskStatus,
    pollingStatus
  );

  const isError = summarizeStatus === 'error';
  const isDataLoading =
    (!athenaOutputData?.value && !isError) || summarizeStatus === 'loading';
  const isAthenaOutputComplete = athenaOutputData?.status === 'complete';

  const cancelLabel = isError ? 'Close' : 'Cancel';
  const confirmLabel = isError ? 'Retry' : 'Apply';

  const insertSummarization = () => {
    if (!athenaOutputData) {
      return;
    }
    onModalConfirm(athenaOutputData.value);
    resetSummarizationState();
  };

  const handleConfirm = isError
    ? regenerateSummarizationTask
    : insertSummarization;

  function handleCancel() {
    onModalCancel();
    resetSummarizationState();
    stopRefetching();
  }

  return (
    <ConfirmModal
      title={modalTitles[command] ?? 'Change tone with AI'}
      description={MODAL_DESCRIPTION}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      confirmTooltipLabel="Suggested text loading"
      confirmIcon={!isError && <Sparkles fill="white" />}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      disabled={!isAthenaOutputComplete && !isError}
      minPadding={0}
      width="70vw"
      actionElements={
        isError ? null : (
          <RegenerateButton
            onClick={regenerateSummarizationTask}
            disabled={!isAthenaOutputComplete}
          />
        )
      }
      maxWidth="960px"
    >
      <section className={modalStyles.modalContainer} role="group">
        {isDataLoading && <DefaultLoading />}
        {isError && <AIError />}
        {athenaOutputData?.value && summarizeStatus === 'success' && (
          <>
            <ComparisonTable heading="">
              <ComparisonTableItem
                label="Original"
                value={summarizableContent}
              />
              <ComparisonTableItem
                label="Suggested"
                value={athenaOutputData.value}
              />
            </ComparisonTable>
          </>
        )}
      </section>
    </ConfirmModal>
  );
}

function AIError() {
  return (
    <div className={modalStyles.aiError}>
      <SVGIcon
        name="WarningTriangle"
        size={16}
        className={modalStyles.errorIcon}
      />
      <span>AI failed to summarize text</span>
    </div>
  );
}

type RegenerateButtonProps = Pick<
  ComponentProps<typeof Button>,
  'onClick' | 'disabled'
>;

function RegenerateButton({ onClick, disabled }: RegenerateButtonProps) {
  const onClickOrDisabled = disabled ? undefined : onClick;
  return (
    <Tooltip offset={-5} placement="top-end" content="Redo">
      <Button
        minimal
        layoutOnly
        aria-label="Redo"
        aria-disabled={disabled}
        onClick={onClickOrDisabled}
        className={modalStyles.regenerateButton}
        icon={<MAIcon name="refresh" />}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      />
    </Tooltip>
  );
}

function ComparisonTable({
  heading,
  children,
}: {
  heading: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <Text className={{ Subheading: true, Semibold: true }} as="h2">
        {heading}
      </Text>
      <div className={modalStyles.comparisonTable}>{children}</div>
    </>
  );
}

function ComparisonTableItem({
  label,
  value,
}: {
  label: string;
  value: string;
}): JSX.Element {
  const uuid = useUniqueId();
  return (
    <div className={modalStyles.comparisonItem}>
      <div className={modalStyles.mb8}>
        <Text
          className={{ Caption: true, gray40: true }}
          htmlFor={uuid}
          as="label"
        >
          {label}
        </Text>
      </div>
      <div
        id={uuid}
        className={modalStyles.comparisonTextarea} // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: value || '' }}
      />
    </div>
  );
}
