import React from 'react';
import { Alert, AlertType, Box } from 'DesignSystem/Components';

export const DeliveryErrors: React.FC<{ errors: string[] }> = ({ errors }) => {
  return (
    <>
      {errors.map((err) => (
        <Box key={err} margin={[0, 0, 30, 0]}>
          <Alert
            type={AlertType.error}
            message={err}
            bgColor="red"
            enableIcon
          />
        </Box>
      ))}
    </>
  );
};
