import { usePermissions } from 'contexts/permissions';
import {
  contentListPages,
  ContentListPage,
} from './content-list-page-defaults';

const pageIdsHiddenWithoutPublishAccess = ['review'];

export const useAvailableContentPages = (): ContentListPage[] => {
  const {
    permissions: { publishCampaign },
  } = usePermissions();

  const availablePages = contentListPages.filter(
    (p) => publishCampaign || !pageIdsHiddenWithoutPublishAccess.includes(p.id)
  );
  return availablePages;
};
