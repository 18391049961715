import { useState, useCallback } from 'react';

// Straight-up boolean toggle.
export function useToggle(
  initialValue = false
): {
  value: boolean;
  enable: () => void;
  disable: () => void;
  toggle: () => void;
} {
  const [value, setValue] = useState(initialValue);
  const enable = useCallback(() => setValue(true), []);
  const disable = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(!value), [value]);
  return { value, enable, disable, toggle };
}

// Similar to toggle, but with delays for UI elements
export function useVisibility(
  opts: Partial<{
    openDelay: number;
    closeDelay: number;
  }> = {}
): ReturnType<typeof useToggle> & { keepopen(): void } {
  const {
    value,
    enable: toggleOn,
    disable: toggleOff,
    toggle: toggleSwitch,
  } = useToggle();
  const [opening, setOpening] = useState<ReturnType<typeof setTimeout>>();
  const [closing, setClosing] = useState<ReturnType<typeof setTimeout>>();

  const enable = useCallback(() => {
    if (!opening && !value)
      setOpening(
        setTimeout(() => {
          setOpening(undefined);
          toggleOn();
        }, opts.openDelay ?? 0)
      );
    if (closing) {
      clearTimeout(closing);
      setClosing(undefined);
    }
  }, [opts.openDelay, opening, closing, value, toggleOn]);

  const disable = useCallback(() => {
    if (value)
      setClosing(
        setTimeout(() => {
          setClosing(undefined);
          toggleOff();
        }, opts.closeDelay ?? 500)
      );
    if (opening) {
      clearTimeout(opening);
      setOpening(undefined);
    }
  }, [opts.closeDelay, value, opening, toggleOff]);

  const toggle = useCallback(() => {
    if (opening) {
      clearTimeout(opening);
      setOpening(undefined);
    }
    if (closing) {
      clearTimeout(closing);
      setClosing(undefined);
    }
    toggleSwitch();
  }, [toggleSwitch, opening, closing]);

  const keepopen = useCallback(() => {
    if (closing) {
      clearTimeout(closing);
      setClosing(undefined);
    }
  }, [closing]);
  return { value, toggle, enable, disable, keepopen };
}
