import { FetchProps } from '../../../../services/api-content';

export type ContentListPage = {
  label: string;
  id: string;
  defaultFetchProps: Omit<FetchProps, 'programId'>;
};

const pageSize = 20;

export const contentListPages: ContentListPage[] = [
  {
    id: 'sent',
    label: 'Sent',
    defaultFetchProps: {
      sort: 'published_at',
      sortDirection: 'desc',
      publicationState: ['published'],
      processingState: ['completed', 'processing'],
      pageSize,
    },
  },
  {
    id: 'scheduled',
    label: 'Scheduled',
    defaultFetchProps: {
      sort: 'published_at',
      sortDirection: 'asc',
      publicationState: ['scheduled'],
      processingState: ['completed', 'processing'],
      pageSize,
    },
  },
  {
    id: 'draft',
    label: 'Draft',
    defaultFetchProps: {
      sort: 'updated_at',
      sortDirection: 'desc',
      publicationState: ['draft'],
      processingState: ['completed', 'processing'],
      pageSize,
    },
  },
  {
    id: 'review',
    label: 'Needs Review',
    defaultFetchProps: {
      sort: 'updated_at',
      sortDirection: 'desc',
      publicationState: ['for_review'],
      processingState: ['completed', 'processing'],
      pageSize,
    },
  },
  {
    id: 'archived',
    label: 'Archived',
    defaultFetchProps: {
      sort: 'updated_at',
      sortDirection: 'desc',
      publicationState: ['archived'],
      processingState: ['completed', 'processing'],
      pageSize,
    },
  },
];
