import * as React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { FAIcon } from '../Icon/FAIcon';
import { DateRangeInputActionsType, DateRangeInputStateType } from './types';
import { Month } from './Month';
import styles from './monthly-calendars.module.css';

type PropsType = {
  actions: DateRangeInputActionsType;
  allowFutureSelection: boolean;
  calendarMonth: DateTime;
  className?: string;
  dateRange: DateRangeInputStateType['dateRange'];
  selectedDate: DateRangeInputStateType['selectedDate'];
};

export const MonthlyCalendars: React.FC<PropsType> = (props) => {
  const {
    actions,
    allowFutureSelection,
    calendarMonth,
    className,
    dateRange,
    selectedDate,
  } = props;

  return (
    <div className={cx(styles.monthlyCalendar, className)}>
      <button
        className={styles.leftShift}
        onClick={actions.previousCalendarMonth}
        type="button"
      >
        <FAIcon name="chevron-left" />
      </button>
      <div className={styles.leftMonth}>
        <Month
          onDateClick={actions.setCurrentRangeDate}
          month={calendarMonth.minus({ months: 1 })}
          selectedDate={selectedDate}
          dateRange={dateRange}
          allowFutureSelection={allowFutureSelection}
        />
      </div>
      <div className={styles.rightMonth}>
        <Month
          onDateClick={actions.setCurrentRangeDate}
          month={calendarMonth}
          selectedDate={selectedDate}
          dateRange={dateRange}
          allowFutureSelection={allowFutureSelection}
        />
      </div>
      <button
        className={styles.rightShift}
        onClick={actions.nextCalendarMonth}
        type="button"
      >
        <FAIcon name="chevron-right" />
      </button>
    </div>
  );
};
