import {
  Button,
  Container,
  Icon,
  Label,
  TabsContentContainer,
  TabsList,
  TabsRootContainer,
  useCssVariableOverride,
} from '@socialchorus/shared-ui-components';
import React, { ComponentProps, FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import cx from 'classnames';
import { capitalizeWords } from 'utility/strings';
import { useProgram } from 'contexts/program';
import { SidebarName, TabName, useTopicFormCtx } from '../../context';
import MainContainer from '../common/MainContainer';
import Toolbar, { ToolbarAction } from '../common/Toolbar';
import Footer from '../common/Footer';
import EditHeaderSidebar from './sidebars/EditHeaderSidebar';
import NavigationSidebar from './sidebars/NavigationSidebar';
import { ShortcutsSidebar } from './sidebars/ShortcutsSidebar';
import EditPinnedPostsSidebar from './sidebars/EditPinnedPostsSidebar';
import { SidebarComponent } from './sidebars/type';
import Members from './tabs/Members';
import { ShortcutsTab } from './tabs/Shortcuts';
import Posts from './tabs/Posts';
import Header from '../common/Header';
import { BlockNavigationContextProvider } from '../common/contexts/blockNavigation';
import styles from './styles.module.css';
import { AboutTab } from './tabs/About';
import { AboutSidebar } from './sidebars/AboutSidebar';
import { HighlightableContainer } from './components/HighlightableContainer';
import Modals from './modals';

const SidebarComponents: Record<SidebarName, SidebarComponent> = {
  navigation: NavigationSidebar,
  editHeader: EditHeaderSidebar,
  editPinnedPosts: EditPinnedPostsSidebar,
  shortcuts: ShortcutsSidebar,
  about: AboutSidebar,
};

const DesignView: FC<RouteComponentProps> = () => {
  const { topic, state, selectTab, toggleSidebar, baseUrl } = useTopicFormCtx();
  const { themeColor, secondaryColor } = useProgram();
  const { colorVariableOverrides } = useCssVariableOverride({
    primaryColor: themeColor || '', // empty strings will not result in any variable overrides
    secondaryColor: secondaryColor || '',
  });

  const handleTabChange = (tab: string) => {
    selectTab(tab as TabName);
  };

  const handleSidebarClose = () => {
    toggleSidebar(null);
  };

  const handleHeaderEdit = () => {
    toggleSidebar('editHeader');
  };

  const handleNavEdit = () => {
    toggleSidebar('navigation');
  };

  const Sidebar = state.activeSidebar
    ? SidebarComponents[state.activeSidebar]
    : null;

  const topicImage = state?.fields?.image?.url ?? topic?.backgroundImageUrl;

  return (
    <>
      <MainContainer>
        <Header />
        <Toolbar>
          <ToolbarAction icon="post_add" onClick={handleNavEdit} />
        </Toolbar>

        <TabsRootContainer
          value={state.activeTab}
          onValueChange={handleTabChange}
          style={colorVariableOverrides as Record<string, string>}
        >
          <div className={styles.Container}>
            <Container className={styles.HeaderSection}>
              <HighlightableContainer
                className={cx(
                  !state.fields.coverEnabled &&
                    styles.HighlightableArea__NoCover
                )}
                active={state.activeSidebar === 'editHeader'}
                hoverLabel="Header"
                editIcon
              >
                {state.fields.coverEnabled && (
                  <div className={styles.CoverImage__Container}>
                    {state.fields.cover ? (
                      <img
                        className={styles.CoverImage}
                        src={state.fields.cover.url}
                        alt="Cover"
                      />
                    ) : (
                      <>
                        <div className={styles.CoverImageDefault} />
                        <Label className={styles.CoverImageDefaultLabel}>
                          Default Cover Image
                        </Label>
                      </>
                    )}
                  </div>
                )}

                <Container
                  variant="border"
                  className={styles.TopicImage__Container}
                >
                  {topicImage ? (
                    <img
                      className={styles.TopicImage}
                      src={topicImage}
                      width={45}
                      height={45}
                      alt=""
                    />
                  ) : (
                    <div
                      className={styles.TopicImageDefault}
                      style={{ backgroundColor: topic.backgroundColor }}
                    />
                  )}
                </Container>

                <div className={styles.HeaderContent}>
                  <button
                    type="button"
                    className={styles.HeaderContent__Texts}
                    onClick={handleHeaderEdit}
                  >
                    <span className="text-main-heading">
                      {state.fields.name || 'Topic Name'}
                    </span>

                    <span className="text-body-1 font-medium-light text-black-60">
                      {state.fields.description ||
                        'This is the topic description, if one has been entered.'}
                    </span>
                  </button>

                  <div className={styles.HeaderActions}>
                    <Button
                      variant="primary"
                      size="compact"
                      label="Follow"
                      leftIcon={<Icon>add</Icon>}
                      disabled
                    />
                  </div>
                </div>
              </HighlightableContainer>
              <div className={styles.HeaderTabs}>
                {state.fields.enabledTabs.length > 1 && (
                  <TabsList
                    tabs={state.fields.enabledTabs.map((tab) => ({
                      label: capitalizeWords(tab),
                      value: tab,
                    }))}
                  />
                )}
              </div>
            </Container>

            <TabsContentContainer value="about">
              <AboutTab />
            </TabsContentContainer>

            <TabsContentContainer value="posts">
              <Posts topic={topic} />
            </TabsContentContainer>

            <TabsContentContainer value="shortcuts">
              <ShortcutsTab />
            </TabsContentContainer>

            <TabsContentContainer value="members">
              <Members topic={topic} />
            </TabsContentContainer>
          </div>
        </TabsRootContainer>
        {Sidebar && <Sidebar onClose={handleSidebarClose} />}
        <Footer ctaText="Next: Settings" ctaHref={`${baseUrl}/settings`} />
      </MainContainer>
      <Modals />
    </>
  );
};

export default function DesignViewExport(
  props: ComponentProps<typeof DesignView>
): React.ReactElement {
  return (
    <BlockNavigationContextProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DesignView {...props} />
    </BlockNavigationContextProvider>
  );
}
