import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { selectedChannels } from 'models/channel';
import {
  useNotificationsValidator,
  defaultFirstNotification,
  Notification,
} from 'models/notification';
import { useState } from 'react';
import { useNotifications } from '../../../Notifications/useNotifications';

export function useEditableNotifications(): readonly [
  Notification[],
  {
    readonly addNotification: () => void;
    readonly updateNotification: (
      data: Partial<Notification>,
      index: number
    ) => void;
    readonly deleteNotification: (index: number) => void;
    readonly saveNotifications: () => void;
    readonly isFormDirty: boolean;
    readonly isNotificationsValid: boolean;
  }
] {
  const {
    defaultNotificationPreviewText,
    defaultNotificationText,
    firstNotification,
    onNotificationsChange,
    reTargetingNotifications,
  } = useNotifications();
  const {
    settings: { deliveryChannels },
  } = useSettings();

  const [retargetingNotifs, setRetargetingNotifs] = useState([
    ...reTargetingNotifications,
  ]);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const isNotificationsValid = useNotificationsValidator(
    retargetingNotifs,
    selectedChannels(deliveryChannels)
  );

  function saveNotifications() {
    onNotificationsChange([firstNotification, ...retargetingNotifs]);
  }

  function addNotification() {
    setRetargetingNotifs((prevNotifs) => {
      const newNotification: Notification = {
        ...defaultFirstNotification,
        text: defaultNotificationText,
        previewText: defaultNotificationPreviewText,
        pushText: defaultNotificationText,
        notificationCenterText: defaultNotificationText,
        order: prevNotifs.length + 1,
      };
      return [...prevNotifs, newNotification];
    });
    setIsFormDirty(true);
  }

  function updateNotification(data: Partial<Notification>, index: number) {
    setRetargetingNotifs((prevNotifs) => {
      const updatedNotifs = [...prevNotifs];
      updatedNotifs[index] = {
        ...updatedNotifs[index],
        ...data,
      };
      return updatedNotifs;
    });
    setIsFormDirty(true);
  }

  const deleteNotification = (index: number) => {
    setRetargetingNotifs((prevNotifs) => {
      const updatedNotifs = [...prevNotifs];
      updatedNotifs.splice(index, 1);
      return updatedNotifs;
    });
    setIsFormDirty(true);
  };

  return [
    retargetingNotifs,
    {
      addNotification,
      updateNotification,
      deleteNotification,
      saveNotifications,
      isFormDirty,
      isNotificationsValid,
    },
  ] as const;
}
