export const alwaysEnabledPermissions = ['home'];
export const enabledByDefault = [
  'feed',
  'initiatives',
  'topics',
  'email_alias',
  'user_export',
  'user_import',
  'configure_admin_activity_feed',
  'configure_experience_settings',
  'configure_microapps',
];
