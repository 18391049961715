import React, { useCallback } from 'react';
import { SearchInput } from 'shared/SearchInput';
import { ListDropdownContext } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { FilterDropdownContainer } from 'App/Program/Main/Insight/components/Filters/shared/FilterDropdownContainer';
import { FilterDropdownHeader } from 'App/Program/Main/Insight/components/Filters/shared/header/FilterDropdownHeader';
import { ClearSelectionButton } from 'App/Program/Main/Insight/components/Filters/shared/header/ClearSelectionButton';
import { FilterDropdownBody } from 'App/Program/Main/Insight/components/Filters/shared/body/FilterDropdownBody';
import { FilterDropdownFooter } from 'App/Program/Main/Insight/components/Filters/shared/footer/FilterDropdownFooter';
import { DoneButton } from 'App/Program/Main/Insight/components/Filters/shared/footer/DoneButton';
import filterStyles from 'App/Program/Main/Insight/components/Filters/Filters.module.css';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';
import { isListFilter, isStaticListFilter } from 'models/insight/Filter';
import { PaginatedListDropdownContext } from '../contexts/PaginatedListDropdownContext';
import { SelectableListItem } from '../../../shared/body/SelectableListItem';

/*
 * This component provides the header and footer for a list dropdown
 * list contents are rendered as children
 * can use normal list, <FixedList /> or `<InfiniteLoader /> components
 * */
export const BaseListDropdown: React.FC = ({ children }) => {
  const {
    dispatchNewlySelectedItem,
    items,
    itemsAreSelected,
  } = React.useContext(ListDropdownContext);

  const {
    filter,
    searchString,
    setSearchString,
    customHeaderComponent,
    filterState,
  } = React.useContext(FilterDropdownContext);

  const { setShowReset } = React.useContext(ReportContext);

  const { errorMessage, isLoadingMoreItems, onSearch } = React.useContext(
    PaginatedListDropdownContext
  );

  const renderAppropriateMessage = () => {
    if (errorMessage) {
      return 'Error Fetching Results';
    }
    if (isSearching || isLoadingMoreItems) {
      return 'Loading...';
    }
    return 'None Available';
  };
  const { getSelectedFiltersStateObj } = React.useContext(FiltersStateContext);

  const [selectAllState, setSelectAllState] = React.useState(false);
  const [selectAllPartialState, setSelectAllPartialState] = React.useState(
    false
  );
  const [isSearching, setIsSearching] = React.useState(false);

  React.useEffect(() => {
    if (!isLoadingMoreItems) setIsSearching(false);
  }, [isLoadingMoreItems]);

  React.useEffect(() => {
    const selectedValues = getSelectedFiltersStateObj();
    if (selectedValues[filter.slug]?.length === items.length) {
      setSelectAllPartialState(false);
      setSelectAllState(true);
    } else if (selectedValues[filter.slug] === null) {
      setSelectAllPartialState(false);
      setSelectAllState(false);
    } else {
      setSelectAllPartialState(true);
      setSelectAllState(false);
    }
  }, [filter.slug, getSelectedFiltersStateObj, items.length]);

  const selectAll = () => {
    if (items.length > 0) setShowReset(true);
    if (selectAllState) {
      setSelectAllState(false);
      dispatchNewlySelectedItem({ action: 'clearAll' });
      return;
    }
    const allItemsMap = new Map();
    items.forEach((item: Record<string, unknown>) => {
      allItemsMap.set(item.value, item);
    });
    dispatchNewlySelectedItem({
      action: 'appendNewItemsToExistingList',
      newItems: allItemsMap,
    });
    setSelectAllState(true);
  };

  const hasListValues = !filterState.availableListValues;

  const handleSearch = useCallback(() => {
    if (onSearch) onSearch();
    else if (
      items.length > 0 &&
      isListFilter(filter) &&
      !isStaticListFilter(filter) &&
      hasListValues
    ) {
      setIsSearching(true);
    }
  }, [items, filter, hasListValues, onSearch]);

  return (
    <FilterDropdownContainer>
      <>
        <FilterDropdownHeader>
          {filter.allowFiltering && (
            <div className={filterStyles.filterDropdownHeaderSearchGroup}>
              <SearchInput
                onChange={setSearchString}
                value={searchString}
                onSearch={handleSearch}
                placeholder="Find..."
                searchIconSize={12}
                inputClassName={filterStyles.filterDropdownHeaderSearchInput}
              />
            </div>
          )}
          {/* any custom headers will go here. Currently only <ContentPostListDropdown> has one */}
          {customHeaderComponent ?? null}
          {filter.allowMultiple && (
            <SelectableListItem
              onClick={selectAll}
              isSelected={selectAllState}
              isPartiallySelected={selectAllPartialState}
              rowType="checkbox"
              label="Select All"
            />
          )}
        </FilterDropdownHeader>
        {isSearching || items.length === 0 ? (
          <FilterDropdownBody>
            <div className={filterStyles.filterDropdownEmptyBody}>
              {renderAppropriateMessage()}
            </div>
          </FilterDropdownBody>
        ) : (
          <>
            <FilterDropdownBody>{children}</FilterDropdownBody>
            <FilterDropdownFooter>
              <ClearSelectionButton
                disabled={!itemsAreSelected}
                onClick={() =>
                  dispatchNewlySelectedItem({ action: 'clearAll' })
                }
              />
              <DoneButton />
            </FilterDropdownFooter>
          </>
        )}
      </>
    </FilterDropdownContainer>
  );
};
