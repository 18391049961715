/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { Button } from 'shared/Button';
import { ClickDropdown } from 'shared/ClickDropdown';
import { Menu } from './useSourceMenu';
import styles from './source-menu.module.css';

export const SourceMenu: React.FC<{ menu: Menu }> = ({ menu, children }) => {
  const renderTrigger = React.useCallback(
    () => (
      <React.Fragment key="source-menu-trigger">
        {React.Children.count(children) > 0 ? (
          children
        ) : (
          <Button
            dataTest="change-source-button"
            key="button"
            onClick={() => {}}
            type="secondary"
          >
            Change
          </Button>
        )}
      </React.Fragment>
    ),
    [children]
  );
  const renderMenu = React.useCallback(
    (dismiss: () => void) => (
      <div className={styles.dropdown}>
        {menu.map((item) => (
          <div
            key={item.name}
            className={styles.button}
            data-test={`add-${item.source}`}
            onClick={() => {
              item.onClick();
              dismiss();
            }}
          >
            {item.component || <span>{item.name}</span>}
          </div>
        ))}
      </div>
    ),
    [menu]
  );
  return (
    <ClickDropdown
      dropdownClassName={styles.wrapper}
      dropdownRenderProp={renderMenu}
    >
      {renderTrigger()}
    </ClickDropdown>
  );
};
