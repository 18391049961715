import React from 'react';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { DoneButton } from 'App/Program/Main/Insight/components/Filters/shared/footer/DoneButton';
import { FilterDropdownContainer } from 'App/Program/Main/Insight/components/Filters/shared/FilterDropdownContainer';
import { FilterDropdownBody } from 'App/Program/Main/Insight/components/Filters/shared/body/FilterDropdownBody';
import { FilterDropdownFooter } from 'App/Program/Main/Insight/components/Filters/shared/footer/FilterDropdownFooter';
import { InputNumber } from 'App/Program/Main/Insight/components/Filters/shared/body/InputNumber';
import { InputText } from 'App/Program/Main/Insight/components/Filters/shared/body/InputText';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { ReportContext } from '../../../contexts/ReportContext';

/**
 * Input dropdown that can support number and text types
 * note that an empty input will yield a `null` as the internal state value
 * this is in contrast to the usual usage of empty string
 * This is done because the higher filter state contexts expect null for empty values from all dropdowns
 * however we still use empty string in the actual <input /> so that it remains a controlled component
 * */
type PropsType = {
  inputType: 'number' | 'text';
};
export const InputDropdown: React.FC<PropsType> = ({ inputType }) => {
  const {
    filter,
    defaultValue,
    setPillButtonDescriptionWithDefault,
  } = React.useContext(FilterDropdownContext);

  const { filtersStateAction } = React.useContext(FiltersStateContext);
  const [value, setValue] = React.useState<string | null>(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const { setShowReset } = React.useContext(ReportContext);
  React.useEffect(() => setValue(defaultValue || null), [defaultValue]);

  React.useEffect(() => {
    if (isFocused === false && value === 'NaN') {
      filtersStateAction({
        action: 'removeFilter',
        filter,
      });
    } else {
      setPillButtonDescriptionWithDefault(value);
      filtersStateAction({
        action: 'setSingleFilterValue',
        filter,
        value,
      });
    }
  }, [
    setPillButtonDescriptionWithDefault,
    value,
    filter,
    filtersStateAction,
    isFocused,
  ]);

  const handleSetValue = (val: string | null) => {
    setShowReset(true);
    setValue(val);
  };

  return (
    <FilterDropdownContainer>
      <FilterDropdownBody>
        {inputType === 'number' ? (
          <InputNumber
            onChange={(newValue) => handleSetValue(String(newValue))}
            value={Number(value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        ) : (
          <InputText
            onChange={(text) => handleSetValue(text || null)}
            value={value || ''}
          />
        )}
      </FilterDropdownBody>
      <FilterDropdownFooter>
        <>
          <div />
        </>
        <DoneButton />
      </FilterDropdownFooter>
    </FilterDropdownContainer>
  );
};
