import { InfiniteQueryResponse, QueryResponse } from 'hooks/common';
import { LiquidVariable } from 'models/liquid-variable';
import { useInfiniteQuery } from 'react-query';
import {
  fetchLiquidVariables,
  LiquidVariablesCollectionData,
} from 'services/api-liquid-variables';
import { FetchProps } from 'services/api-user';

const EMPTY_ARRAY: never[] = [];

export const useLiquidVariablesInfiniteQuery = (
  props: Omit<FetchProps, 'page'>,
  campaignId: number | 'new'
): InfiniteQueryResponse<LiquidVariable> => {
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery<LiquidVariablesCollectionData, Error>(
    ['liquid-variables', JSON.stringify(props)],
    async () => fetchLiquidVariables(props.programId, campaignId)
  );
  const extractedData = data?.pages[0] || EMPTY_ARRAY;

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    data: extractedData,
  };
};

// We have 2 variables in total
export const useLiquidVariablesQuery = (
  programId: FetchProps['programId'],
  campaignId: number | 'new'
): QueryResponse<LiquidVariable[]> => {
  const { data, isLoading, errorMessage } = useLiquidVariablesInfiniteQuery(
    {
      programId,
      pageSize: 100,
    },
    campaignId
  );

  return {
    isLoading,
    errorMessage,
    data,
  };
};
