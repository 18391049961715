const ALLOWED_KEYS = [
  'first_name',
  'last_name',
  'preferred_name',
  'universal_identifier',
  'brand_slug',
  'program_address',
  'program_hashtag',
  'program_name',
];

export const MAX_PREVIEW_TEXT_SIZE = 200;

export type ContextCommunication = {
  key: string;
  name: string;
  description: string;
};

export function allowedContextCommunications(
  arr: ContextCommunication[] | undefined
): ContextCommunication[] {
  return arr?.filter(({ key }) => ALLOWED_KEYS.includes(key)) || [];
}
