import * as React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { WorkflowFiltersProvider } from 'contexts/workflow/filters';
import { useProgram } from 'contexts/program';
import { WithPermission } from 'shared/WithPermission';
import { defaultListPages } from './default-list-pages';
import { CreatePage } from './CreatePage';
import { EditPage } from './EditPage';
import { ListPage } from './ListPage';

export const Workflows: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  return (
    <WithPermission permissions={['workflowsAccess']}>
      <WorkflowFiltersProvider>
        <Router>
          {defaultListPages.map((page) => (
            <ListPage
              key={page.key}
              path={page.key}
              defaultFetchProps={{ programId, ...page.fetchProps }}
            />
          ))}
          <CreatePage path="new" />
          <EditPage path="edit/:id" />
          <Redirect from="/" to={defaultListPages[0].key} noThrow />
          <Redirect from="*" to="/" noThrow default />
        </Router>
      </WorkflowFiltersProvider>
    </WithPermission>
  );
};
