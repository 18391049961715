import React, { useState } from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { JourneyTabProvider } from 'contexts/journeys/journey-tab';
import { PermissionDeniedScreen } from 'shared/PermissionDeniedScreen';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { JourneyListPage } from './JourneyListPage';
import { JourneyNewPage } from './JourneyNewPage';
import { JourneyEditPage } from './JourneyEditPage';
import { JourneyViewPage } from './JourneyViewPage';
import { ErrorHandlerProvider } from './error-handler-context';

export const Journey: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const [journeyTabState, setJourneyTabState] = useState<string>();
  const journey = useFeatureFlagsQuery(programId, 'Studio.Journeys.Enabled');

  const journeysEnabled = journey.isLoading || !!journey?.data?.value;

  return journeysEnabled ? (
    <ErrorHandlerProvider>
      <JourneyTabProvider
        journeyTabState={journeyTabState}
        setJourneyTabState={setJourneyTabState}
      >
        <WithPermission permissions={['journeysAccess']}>
          <Router>
            <JourneyListPage path="current" />
            <JourneyListPage path="archive" />

            <JourneyNewPage path="new/edit" />

            <JourneyEditPage path=":journeyId/edit" />

            <JourneyViewPage path=":journeyId" />

            <Redirect from="/" to="current" noThrow />
            <Redirect from="*" to="/" noThrow default />
          </Router>
        </WithPermission>
      </JourneyTabProvider>
    </ErrorHandlerProvider>
  ) : (
    <PermissionDeniedScreen />
  );
};
