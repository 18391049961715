import React from 'react';
import { Button } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { likeReport } from 'services/api-insights';

type Props = {
  reportId: number;
  userLiked: boolean;
  primary?: true;
};

const LikeIcon: React.FC<{ userLiked?: boolean }> = ({ userLiked }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{userLiked ? 'Unlike this report' : 'Like this report'}</title>
    {userLiked ? (
      <path
        d="M12.0127 21.302C12.0042 21.3022 11.996 21.3022 11.9875 21.302C11.8912 21.302 11.7937 21.2732 11.7089 21.213C11.4415 21.0232 5.1285 16.5205 2.80253 12.7222C2.79941 12.717 2.79651 12.7119 2.79339 12.7068C2.27435 11.8458 2 10.8573 2 9.84661C2 6.78815 4.48835 4.2998 7.54681 4.2998C9.3176 4.2998 10.9619 5.14115 12 6.53856C13.0381 5.14115 14.6826 4.2998 16.4532 4.2998C19.5117 4.2998 22 6.78815 22 9.84661C22 10.8573 21.7256 11.8458 21.2066 12.7068C21.2037 12.7119 21.2008 12.717 21.1975 12.7222C18.8717 16.5205 12.5587 21.0232 12.2911 21.213C12.2068 21.2732 12.1093 21.302 12.0127 21.302Z"
        fill="currentColor"
      />
    ) : (
      <path
        d="M15.3232 17.5603C13.8668 18.8037 12.5633 19.7784 12 20.1898C11.4366 19.7782 10.1332 18.8035 8.67698 17.5603C6.78405 15.9442 4.73363 13.958 3.65865 12.2053L3.65606 12.201L3.64979 12.1905L3.6498 12.1905C3.22516 11.4861 3 10.6762 3 9.84661C3 7.34044 5.04063 5.2998 7.54681 5.2998C8.99853 5.2998 10.3462 5.98927 11.1973 7.13488L12 8.2155L12.8027 7.13488C13.6538 5.98928 15.0017 5.2998 16.4532 5.2998C18.9594 5.2998 21 7.34044 21 9.84661C21 10.6762 20.7748 11.4861 20.3502 12.1905L20.3429 12.2025L20.3413 12.2054C19.2665 13.958 17.2161 15.9442 15.3232 17.5603Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    )}
  </svg>
);

export const useUserLikeToggle: (
  props: Pick<Props, 'reportId' | 'userLiked'>
) => [boolean, () => void] = ({ reportId, userLiked }) => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();

  // must dynamically set the like state because `props.userLiked` can be different values
  // we always want the most recent state from the props
  const [liked, setLiked] = React.useState(false);
  React.useEffect(() => setLiked(userLiked), [userLiked]);

  const toggleUserLike = React.useCallback(() => {
    (async () => {
      const likeResponse = await likeReport({
        programId,
        reportId,
        like: !liked,
      });

      if (likeResponse.ok) {
        setLiked(!liked);
      } else {
        setFlashMessage({
          severity: 'error',
          message: 'Unable to save report at this time.',
        });
      }
    })();
  }, [programId, reportId, liked, setFlashMessage]);
  return [liked, toggleUserLike];
};

export const LikeButton: React.FC<Props> = ({
  reportId,
  userLiked,
  primary,
}) => {
  const [liked, toggleLiked] = useUserLikeToggle({ reportId, userLiked });
  return React.useMemo(
    () => (
      <div style={{ marginRight: primary ? '20px' : 0 }}>
        <Button
          layoutOnly={!primary}
          compact={!primary}
          label={<LikeIcon userLiked={liked} />}
          onClick={toggleLiked}
        />
      </div>
    ),
    [toggleLiked, liked, primary]
  );
};
