import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

type NavigationBlocker = {
  enable: () => void;
  disable: () => void;
  blocker: {
    enabled: boolean;
    message: string;
  };
};

export const useNavigationBlockerContext: (
  config?: Partial<{
    noThrow: boolean;
  }>
) => NavigationBlocker = (config = {}) => {
  const context = useContext(NavigationBlockerContext);
  if (!context && !config.noThrow) {
    throw new Error('The NavigationBlockerContext has no provider.');
  }
  if (!context)
    return {
      enable() {},
      disable() {},
      blocker: { enabled: false, message: '' },
    };
  return context;
};

export const useNavigationBlocker = (
  message = 'You should fix the errors before leaving the page'
): NavigationBlocker => {
  const [hasError, setError] = useState(false);
  const enable = useCallback(() => setError(true), []);
  const disable = useCallback(() => setError(false), []);

  useEffect(() => {
    const confirmation =
      'Reload the publisher? Any changes you have made will not be saved.';
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (hasError) {
        e.preventDefault();
        e.returnValue = confirmation;
      }
      return confirmation;
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => window.removeEventListener('beforeunload', beforeUnload);
  }, [hasError]);

  return {
    enable,
    disable,
    blocker: useMemo(() => ({ message, enabled: hasError }), [
      hasError,
      message,
    ]),
  };
};

const NavigationBlockerContext = createContext<NavigationBlocker | undefined>(
  undefined
);

export const NavigationBlockerContextProvider =
  NavigationBlockerContext.Provider;
