import * as React from 'react';
import { useQuery } from 'react-query';
import { useProgram } from 'contexts/program';
import { ReportDownload } from 'models/report-download';
import { useFlashMessage } from 'contexts/flasher';
import { fetchDownloads, fetchPresignedDownload } from 'services/api-insights';
import { useCollectionTabs } from './useCollectionTabs';

export function useReportDownloadsData(): {
  isError: boolean;
  isLoading: boolean;
  noMatches: boolean;
  filter: { searchTerm: string; setSearchTerm(term: string): void };
  tabs: Array<{ to: string; label: string }>;
  dateRanges: string[];
  reportsByRange(dateRange: string): ReportDownload[];
  download(data: ReportDownload): void;
  unlock(data: ReportDownload): void;
} {
  const flasher = useFlashMessage();
  const collectionTabs = useCollectionTabs();
  const tabs = React.useMemo(
    () =>
      collectionTabs.map((tab) => ({
        to: `./collections/${tab.to}`,
        label: tab.label,
      })),
    [collectionTabs]
  );

  const [searchTerm, setSearchTerm] = React.useState('');
  const filter = React.useMemo(() => ({ searchTerm, setSearchTerm }), [
    searchTerm,
    setSearchTerm,
  ]);

  const programId = useProgram().id;
  const {
    isLoading,
    data: unfiltered,
    isError,
  } = useQuery(`${programId}/insights/downloads`, () =>
    fetchDownloads(programId)
  );

  const data = React.useMemo(() => {
    if (!searchTerm) return unfiltered ?? [];
    return (unfiltered ?? []).filter(({ name, url, date_range }) =>
      `${name.toLocaleLowerCase()}
       ${url.toLocaleLowerCase()}
       ${date_range.toLocaleLowerCase()}`.includes(
        searchTerm.toLocaleLowerCase()
      )
    );
  }, [unfiltered, searchTerm]);

  const [presigned, setPresigned] = React.useState<Record<string, string>>({});

  const reportsByRange = React.useCallback(
    (dateRange: string) => {
      return data
        .filter((row) => row.date_range === dateRange)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((rep) => ({
          ...rep,
          status:
            // eslint-disable-next-line no-nested-ternary
            (presigned[rep.id] !== undefined
              ? presigned[rep.id]
                ? 'ready'
                : 'loading'
              : 'locked') as ReportDownload['status'],
          url: presigned[rep.id] || rep.url,
        }));
    },
    [data, presigned]
  );
  const noMatches = !!(!isLoading && !data.length);

  const download = React.useCallback((report: ReportDownload) => {
    // set immediate helps when they are opening many at once.
    // Otherwise, only the first or last will be opened
    setImmediate(() => {
      const link = document.createElement('a');
      link.href = report.url;
      link.download = `${report.date_range}-${report.name}`;
      link.click();
      // window.open(report.url);
    });
  }, []);

  const unlock = React.useCallback(
    (report: ReportDownload) => {
      // update data status in collection to 'loading'
      setPresigned((urls) => ({
        ...urls,
        [report.id]: '',
      }));
      // fetch report.url
      // get presigned url from response
      // update data status in collection to 'ready'
      fetchPresignedDownload(programId, report.id).then((respData) => {
        if (!respData) {
          flasher.setFlashMessage({
            severity: 'error',
            message: 'Unexpected Error',
            details: 'Failed to create a presigned URL',
            timeout: false,
          });
          setPresigned((urls) => {
            const copy = { ...urls };
            delete copy[report.id];
            return copy;
          });
        } else {
          const { id, url } = respData;
          setPresigned((urls) => ({
            ...urls,
            [id]: url,
          }));
        }
      });
    },
    [programId, flasher]
  );

  const dateRanges = React.useMemo(() => {
    const unique = (arr: string[]) =>
      arr.filter((v, i, a) => a.indexOf(v) === i);
    return unique(
      data
        .map(({ date_range }) => date_range)
        .sort((a, b) => {
          // It just so happens that the date ranges formats can be
          // compared directly as strings without casting to dates.
          // Negate for most recent first...
          return 0 - a.localeCompare(b);
        })
    );
  }, [data]);

  return {
    dateRanges,
    filter,
    tabs,
    isLoading,
    reportsByRange,
    noMatches,
    isError,
    download,
    unlock,
  };
}
