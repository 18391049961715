import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { JourneyProvider } from 'contexts/journeys/journey';
import { useProgram } from 'contexts/program';
import { useJourneyQuery } from 'hooks/journeys/journeys';
import { LoadingScreen } from 'shared/LoadingScreen';
import { JourneyMode } from 'models/journeys/journey';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { JourneyCanvas } from '../JourneyCanvas';
import {
  DEFAULT_ERROR_MESSAGE,
  useJourneyErrorHandler,
} from '../error-handler-context';

export const JourneyEditPage: React.FC<RouteComponentProps<{
  journeyId: string;
}>> = ({ journeyId = '0' }) => {
  const { id: programId } = useProgram();
  const { data: journey, isLoading } = useJourneyQuery({
    programId,
    journeyId: Number(journeyId),
  });

  const { setErrorMessage } = useJourneyErrorHandler();

  const handleError = () => {
    navigate(`/${programId}/app/journeys/`);
    setErrorMessage(DEFAULT_ERROR_MESSAGE);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!journey) {
    navigate(`/${programId}/app/journeys/`);
    setErrorMessage(DEFAULT_ERROR_MESSAGE);
    return null;
  }

  if (
    (!journey.draftGraph && journey.liveGraph) ||
    journey.state === 'archived'
  ) {
    navigate(`/${programId}/app/journeys/${journey.id}`, { replace: true });
    return null;
  }

  return (
    <ErrorBoundary onError={handleError}>
      <JourneyProvider journey={journey} mode={JourneyMode.Edit}>
        <JourneyCanvas />
      </JourneyProvider>
    </ErrorBoundary>
  );
};
