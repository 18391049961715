import { FroalaOptions } from 'froala-editor';
import { injectPluginsAndToolbarButtons } from './froalaCodeViewUtils';

export function addVideoPlugin(
  options: Partial<FroalaOptions>
): Partial<FroalaOptions> {
  return {
    ...options,
    ...injectPluginsAndToolbarButtons(options, ['video'], ['insertVideo']),
    videoAllowedProviders: ['youtube', 'vimeo'],
    videoUpload: false,
  };
}
