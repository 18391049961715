import React, { useCallback, useMemo } from 'react';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import { Topic } from 'models/topic';
import { useProgram } from 'contexts/program';
import { SearchInput } from 'shared/SearchInput';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { Pills } from 'DesignSystem/Components/Pills';
import styles from './styles.module.css';

type PropsType = {
  value: Array<Topic>;
  onChange: (value: Array<Topic>) => void;
};

export const TopicSelector: React.FC<PropsType> = ({ value, onChange }) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');
  const allOption = React.useMemo(() => {
    return { id: 'all', name: 'All Topics' } as Topic;
  }, []);

  const {
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useTopicsInfiniteQuery({ programId, search });

  const topicsByRowId = useMemo(() => {
    const map: { [key: string]: Topic } = {};
    map.all = allOption;
    data.forEach((topic) => {
      map[topic.id] = topic;
    });
    // retain the currently selected topics
    value.forEach((topic) => {
      if (!map[topic.id]) map[topic.id] = topic;
    });
    return map;
  }, [allOption, data, value]);

  const rowIds = Object.keys(topicsByRowId);

  const selectedIds = React.useMemo(() => {
    return value.map(({ id }) => `${id}`);
  }, [value]);

  const renderRow = (rowId: string) => {
    if (rowId === 'all') {
      return (
        <Text.Body bold block>
          All Topics
        </Text.Body>
      );
    }
    const item = topicsByRowId[rowId];
    return item ? (
      <Text.Body bold block>
        {item.name}
      </Text.Body>
    ) : null;
  };

  const onSelectedIdsChange = useCallback(
    (ids: string[]) => {
      if (ids.includes('all') && !selectedIds.includes('all')) {
        onChange([allOption]);
        return;
      }

      onChange(
        ids
          .filter((id) => id !== 'all')
          .map((rowId) => ({ ...topicsByRowId[rowId], hidden: false }))
      );
    },
    [allOption, onChange, selectedIds, topicsByRowId]
  );

  const dropdown = (
    <InfiniteSelect
      rowIds={rowIds}
      rowRenderProp={renderRow}
      maxHeight={300}
      itemHeight={60}
      selectedIds={selectedIds}
      onSelectedIdsChange={onSelectedIdsChange}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      allOptionId="all"
    />
  );

  return (
    <>
      <Text.Subheading block bold>
        Topics
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select at least one topic in order to take a bulk action
      </Text.Body>
      <Box margin={[16, 0, 16, 0]}>
        <ClickDropdown
          dropdownRenderProp={dropdown}
          matchReferenceWidth
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearch('');
          }}
          ignoreKeys
        >
          <div>
            <SearchInput
              placeholder="Search topics"
              value={search || ''}
              onChange={setSearch}
              labelClassName={styles.SearchInput}
            />
          </div>
        </ClickDropdown>
      </Box>

      <Flex start wrap className={styles.PillsContainer}>
        {value.map((topic, i) => (
          <Pills
            key={`topic-${topic.id}`}
            values={[{ id: i }]}
            render={() => <>{topic.name}</>}
            onClose={(t) => {
              const index = t.id;
              const values = [
                ...value.slice(0, index),
                ...value.slice(index + 1),
              ];

              onChange(values);
            }}
            padding={[0, 12, 0, 8]}
          />
        ))}
      </Flex>
    </>
  );
};
