import React from 'react';

export function DefaultAvatar(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#817F8A" />
      <path
        opacity="0.5"
        d="M20 19.6618C18.5829 19.6618 17.4235 19.211 16.5217 18.3092C15.62 17.4074 15.1691 16.248 15.1691 14.8309C15.1691 13.4138 15.62 12.2544 16.5217 11.3527C17.4235 10.4509 18.5829 10 20 10C21.4171 10 22.5765 10.4509 23.4783 11.3527C24.38 12.2544 24.8309 13.4138 24.8309 14.8309C24.8309 16.248 24.38 17.4074 23.4783 18.3092C22.5765 19.211 21.4171 19.6618 20 19.6618ZM9.69403 30V26.9726C9.69403 26.1567 9.898 25.4589 10.3059 24.8792C10.7139 24.2995 11.2399 23.8594 11.884 23.5588C13.3226 22.9147 14.7021 22.4316 16.0225 22.1095C17.343 21.7874 18.6688 21.6264 20 21.6264C21.3312 21.6264 22.6516 21.7928 23.9613 22.1256C25.2711 22.4584 26.6452 22.9361 28.0837 23.5588C28.7493 23.8594 29.2861 24.2995 29.694 24.8792C30.102 25.4589 30.3059 26.1567 30.3059 26.9726V30H9.69403Z"
        fill="#1B1A1F"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" />
    </svg>
    /* eslint-enable react/jsx-props-no-spreading */
  );
}
