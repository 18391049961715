import React from 'react';
import Modal from 'simple-react-modal';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { deleteReportBookmark } from 'services/api-insights';
import { CollectionsContext } from 'App/Program/Main/Insight/contexts/CollectionsContext';
import styles from 'App/Program/Main/Insight/pages/ReportPage.module.css';

type Props = {
  className: string;
  title: string | null;
  reportId: number;
  bookmarkId: number;
  isPublic: boolean;
};

export const DeleteBookmarkButton: React.FC<Props> = ({
  title,
  reportId,
  bookmarkId,
  className,
  isPublic,
}) => {
  const { invalidateBookmarks } = React.useContext(CollectionsContext);
  const [deleteBookmarkModalOpen, toggleModal] = React.useState(false);

  const { id: programId } = useProgram();

  const showModal = () => toggleModal(true);
  const hideModal = () => toggleModal(false);
  const displayedTitle = (text: string | null) =>
    text === null ? 'Untitled' : text;

  const deleteBookmark = () => {
    deleteReportBookmark({ programId, reportId, id: bookmarkId }).then(() => {
      invalidateBookmarks({ programId });
      hideModal();
    });
  };
  const modalStyle = {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '0',
    width: '450px',
  };

  return (
    <>
      <div
        className={className}
        role="button"
        onClick={showModal}
        onKeyPress={showModal}
        tabIndex={0}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 10 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>bookmark</title>
          <path
            d="M8 10L4.667 8.547 1.333 10V1.333H8V10zM8 0H1.333C.597 0 0 .597 0 1.333V12l4.667-2 4.666 2V1.333C9.333.593 8.733 0 8 0z"
            fill="currentColor"
          />
        </svg>
      </div>
      <Modal
        style={{ padding: '0' }}
        show={deleteBookmarkModalOpen}
        containerStyle={modalStyle}
      >
        <div className={styles.banner}>Delete bookmark</div>
        <div className={styles.modalActions}>
          <div>
            Are you sure you want to delete `{displayedTitle(title)}`?
            {isPublic && (
              <p className={styles.alertMessage}>
                You are about to remove a shared bookmark that is used by others
                in your organization! Are you sure you want to do this?
              </p>
            )}
          </div>
          <div className={styles.actions}>
            <button
              className={styles.uiButtons}
              type="button"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              className={cx(styles.primary, styles.uiButtons)}
              type="button"
              onClick={deleteBookmark}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
