/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { Keys, useKeyboardEventHandler } from 'hooks/useKeyboardTrigger';
import {
  useFloating,
  flip,
  shift,
  useInteractions,
  useDismiss,
  size,
  offset,
  useId,
  FloatingOverlay,
  autoUpdate as floatingAutoUpdate,
} from '@floating-ui/react';
import styles from './click-dropdown.module.css';

export type DismissType = () => void;
export type DropdownRenderPropType =
  | React.ReactNode
  | ((
      dismiss: DismissType,
      setDismissable?: (dismissable: boolean) => void,
      updatePosition?: () => void
    ) => React.ReactNode);

// This component is meant to be "subclassed", so to speak.  There are concrete
// derived components that wrap this one and supply specialized dropdown contents.
// In all those cases, it needs to expose these props and pass them through to this
// parent so the common hover behavior can be provided.
export type PassThroughPropsType = {
  dropdownClassName?: string;
  id?: string;
  disabled?: boolean;
  isOpen?: boolean;
  onOpen?: (id?: string, toggle?: Element | null) => void;
  onClose?: (id?: string) => void;
  onDropdownClick?: () => void;
  matchReferenceWidth?: boolean;
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'right-start'
    | 'right-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'left-start'
    | 'left-end'
    | undefined;
  dismissable?: boolean;
  ignoreKeys?: boolean;
  asModal?: boolean;
  autoUpdate?: boolean;
  referencePress?: boolean;
};

type PropsType = {
  children: React.ReactElement;
  dropdownRenderProp: DropdownRenderPropType;
} & PassThroughPropsType;

export type ClickDropdownDismissableType = {
  setDismissable: (dismissable: boolean) => void;
};

export const SelectWrapper: React.FC<{
  asModal: boolean;
  dismiss: DismissType;
  canDismiss: boolean;
}> = ({ children, asModal, dismiss, canDismiss }) => {
  return asModal ? (
    <FloatingOverlay
      id="floating-overlay"
      style={{ zIndex: 1001 }}
      lockScroll
      onClick={(e) => {
        if (e.target !== e.currentTarget) return;
        if (canDismiss) dismiss();
      }}
    >
      {children}
    </FloatingOverlay>
  ) : (
    <>{children}</>
  );
};

export const ClickDropdown: React.FC<PropsType> = (props) => {
  const {
    dropdownClassName = 'dropdown-align-left',
    dropdownRenderProp,
    id,
    isOpen,
    disabled,
    onOpen,
    onClose,
    onDropdownClick,
    children,
    matchReferenceWidth = true,
    placement = 'bottom',
    dismissable = true,
    ignoreKeys = false,
    asModal = false,
    autoUpdate = false,
    referencePress = true,
  } = props;

  const [currentDropdownOpen, setDropdownOpen] = React.useState(isOpen);
  const [previousIsOpenProp, setPreviousIsOpenProp] = React.useState(isOpen);
  const [canDismiss, setCanDismiss] = React.useState(dismissable);

  const floatingId = useId();

  // The previous version had no callbacks or effects really.
  // It would call the setters during render, and the tests expected
  // an immediate rendering, and moving the snippet of code into
  // an effect caused the tests to fails. This keeps the current
  // behavior by somewhat duplicating the next state, but also
  // keeps the performance improvements with callbacks and effects.
  const isDropdownOpen = React.useMemo(() => {
    if (previousIsOpenProp === isOpen) return currentDropdownOpen;
    return isOpen;
  }, [isOpen, currentDropdownOpen, previousIsOpenProp]);

  React.useEffect(() => {
    if (isOpen !== previousIsOpenProp) {
      setPreviousIsOpenProp(isOpen);
      setDropdownOpen(isOpen);
    }
  }, [isOpen, previousIsOpenProp, isDropdownOpen]);

  const hideDropdown = React.useCallback(() => {
    if (isDropdownOpen && onClose) {
      onClose(id);
    }
    return setDropdownOpen(false);
  }, [id, isDropdownOpen, onClose]);

  const showDropdown = React.useCallback(() => {
    if (disabled) {
      return;
    }
    if (!isDropdownOpen && onOpen) {
      onOpen(id, null);
    }
    setDropdownOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, disabled, isDropdownOpen, onOpen]);

  const toggleDropdown = React.useCallback(() => {
    if (isDropdownOpen) {
      hideDropdown();
    } else {
      showDropdown();
    }
  }, [isDropdownOpen, hideDropdown, showDropdown]);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    refs,
    update,
  } = useFloating<HTMLElement>({
    open: isDropdownOpen,
    onOpenChange: toggleDropdown,
    placement,
    whileElementsMounted: autoUpdate ? floatingAutoUpdate : undefined,
    middleware: [
      ...(matchReferenceWidth
        ? [
            size({
              apply({ rects }) {
                if (refs?.floating?.current) {
                  Object.assign(refs.floating.current.style, {
                    width: `${rects.reference.width}px`,
                  });
                }
              },
            }),
          ]
        : []),
      shift(),
      flip(),
      offset({ mainAxis: 5 }),
    ],
  });

  useInteractions([
    useDismiss(context, {
      enabled: canDismiss,
      referencePress,
    }),
  ]);

  const handleDropdownClick = React.useCallback(() => {
    if (onDropdownClick) {
      onDropdownClick();
    }
  }, [onDropdownClick]);

  const keyTriggeredShowDropdown = React.useCallback(() => {
    if (!isDropdownOpen) {
      showDropdown();
    }
  }, [isDropdownOpen, showDropdown]);

  const keyTriggeredHideDropdown = React.useCallback(() => {
    if (isDropdownOpen) {
      hideDropdown();
    }
  }, [isDropdownOpen, hideDropdown]);

  const { onKeyDown, onKeyUp } = useKeyboardEventHandler({
    onIncludedKey: keyTriggeredShowDropdown,
    keys: [Keys.Enter],
    onAnyOtherKey: ignoreKeys ? undefined : keyTriggeredHideDropdown,
  });

  const childWithProps = React.cloneElement(children, {
    ref: reference,
    onClick: () => {
      if (!isDropdownOpen || (referencePress && isDropdownOpen))
        toggleDropdown();
    },
    onKeyDown,
    className: cx(children.props.className, 'click-dropdown-target'),
    onKeyUp,
    ...(id ? { id } : { id: floatingId }),
  });

  return (
    <>
      {childWithProps}
      {isDropdownOpen && (
        <SelectWrapper
          asModal={asModal}
          canDismiss={canDismiss}
          dismiss={hideDropdown}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={handleDropdownClick}
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={`${styles.Popover} ${dropdownClassName}`}
          >
            {typeof dropdownRenderProp === 'function'
              ? dropdownRenderProp(
                  hideDropdown,
                  (dismiss: boolean) => setCanDismiss(dismiss),
                  update
                )
              : dropdownRenderProp}
          </div>
        </SelectWrapper>
      )}
    </>
  );
};

export const useClickDropdown = (): {
  isOpen: boolean;
  ClickDropdown: React.FC<PropsType>;
} => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdown: React.FC<PropsType> = React.useCallback(
    ({ children, ...props }) => (
      <ClickDropdown
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </ClickDropdown>
    ),
    [isOpen, setIsOpen]
  );
  return {
    isOpen,
    ClickDropdown: dropdown,
  };
};
