import React from 'react';
import { useNavigate } from '@reach/router';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { Permissions } from 'models/permissions';

// requiredPermission will check for one spcific permssion
type PropsType = {
  children: React.ReactNode;
  permissions: Array<keyof Permissions['permissions']>;
  operation?: 'some' | 'all';
};

export const WithPermission: React.FC<PropsType> = ({
  children,
  permissions,
  operation = 'some',
}) => {
  const program = useProgram();
  const { permissions: userPermissions } = usePermissions();
  let allowed = false;
  if (operation === 'some') {
    allowed = permissions.some((permission) => {
      return userPermissions[permission];
    });
  } else {
    allowed = permissions.every((permission) => {
      return userPermissions[permission];
    });
  }

  const navigate = useNavigate();
  if (!allowed) {
    navigate(`/${program.id}/`);
  }

  return <>{children}</>;
};
