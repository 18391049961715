import React from 'react';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body } from 'DesignSystem/Typography';
import { IconNames, MAIcon } from 'shared/MAIcon';
import { AddableStepTypes } from 'models/journeys/journey';
import style from '../edges.module.css';

export type StepOption = {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
};

const iconDefinitions: Record<
  AddableStepTypes,
  {
    name: IconNames;
    color: React.CSSProperties['color'];
    background: React.CSSProperties['background'];
  }
> = {
  communication: {
    name: 'subtitles',
    color: 'var(--color-gray00)',
    background: 'var(--color-brandTintDark)',
  },
  decision: {
    name: 'account_tree',
    color: 'var(--color-gray00)',
    background: 'var(--color-yellowFull)',
  },
  delay: {
    name: 'schedule',
    color: 'var(--color-gray00)',
    background: 'var(--color-orangeFull)',
  },
};

export const AddStepOptionIcon: React.FC<{
  option: AddableStepTypes;
}> = ({ option }) => {
  const { name, color, background } = iconDefinitions[option];
  return (
    <Flex
      center
      className={style.AddStepOptionIcon}
      style={{
        color,
        background,
      }}
    >
      <MAIcon name={name} />
    </Flex>
  );
};

export const AddStepOption: React.FC<StepOption> = ({
  icon,
  title,
  onClick,
}) => {
  const Component = (
    <Flex>
      {icon}
      <Body semibold>{title}</Body>
    </Flex>
  );

  return (
    <Button
      className={style.AddStepOptionButton}
      onClick={onClick}
      icon={Component}
      borderless
      clearText
      minimal
    />
  );
};
