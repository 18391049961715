import React from 'react';
import { ConfirmModal } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { Body } from 'DesignSystem/Typography';
import { MAIcon } from 'shared/MAIcon';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './styles.module.css';

type CaptionDeletionModalProps = {
  onCancel: () => void;
  onDelete: () => void;
  language: string;
  isLoading?: boolean;
};

const MODAL_BODY_COPY =
  'Captions in this language will no longer be available for this video. This action cannot be undone.';

export const CaptionDeletionModal: React.FC<CaptionDeletionModalProps> = ({
  onCancel,
  onDelete,
  language,
  isLoading,
}) => (
  <ConfirmModal
    title={`Delete ${language} Captions?`}
    titleIcon={
      <div>
        <MAIcon name="warning" className={styles.deletionModalIcon} />
      </div>
    }
    onCancel={onCancel}
    confirmButton={
      <CaptionDeleteButton
        onDelete={onDelete}
        deleteLabel="Delete"
        isLoading={isLoading}
        isDisabled={isLoading}
      />
    }
  >
    <Body semibold>{MODAL_BODY_COPY}</Body>
  </ConfirmModal>
);

const CaptionDeleteButton: React.FC<{
  onDelete: () => void;
  deleteLabel: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}> = ({ onDelete, deleteLabel, isDisabled, isLoading }) => {
  const label = isLoading ? (
    <>
      {deleteLabel}&nbsp;
      <LoadingSpinner size="xsmall" />
    </>
  ) : (
    deleteLabel
  );

  return (
    <Button
      disabled={isDisabled || isLoading}
      onClick={onDelete}
      label={label}
    />
  );
};
