import * as React from 'react';
import { WorkflowTemplate } from 'models/workflow-templates';
import { useWorkflowTemplatesInfiniteQuery } from 'hooks/workflow-templates/useQueries';
import { FocusDropdown } from 'shared/FocusDropdown';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { useDebounce } from 'hooks/useDebounce';
import { useProgram } from 'contexts/program';
import { Dropdown } from './Dropdown';
import styles from './templates-select.module.css';

export type PropsType = {
  selectedTemplateIds: string[];
  onSelectedTemplatesChange: (templates: WorkflowTemplate[]) => void;
  maxDropdownHeight?: number;
  placeholder?: string;
  name?: string | string[];
  upward?: boolean;
};

export const WorkflowTemplatesSelect: React.FC<PropsType> = (props) => {
  const {
    selectedTemplateIds,
    onSelectedTemplatesChange,
    maxDropdownHeight = 400,
    placeholder,
    upward = false,
  } = props;
  const [searchText, setSearchText] = React.useState('');
  const [templatesLoaded, setTemplatesLoaded] = React.useState<
    WorkflowTemplate[]
  >([]);

  const { id: programId } = useProgram();
  const debouncedSearchText = useDebounce(searchText);

  const {
    isLoading,
    data: dropdownTemplates,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useWorkflowTemplatesInfiniteQuery({
    programId,
    search: debouncedSearchText,
    status: 'published',
  });

  React.useEffect(() => {
    const idsOfTemplatesLoaded = new Set(
      templatesLoaded.map((template) => template.id)
    );
    if (!isLoading) {
      const newTemplatesLoaded = [...templatesLoaded];
      let isUpdated = false;
      dropdownTemplates.forEach((template) => {
        if (idsOfTemplatesLoaded.has(template.id)) {
          return;
        }
        newTemplatesLoaded.push(template);
        idsOfTemplatesLoaded.add(template.id);
        isUpdated = true;
      });

      if (isUpdated) {
        setTemplatesLoaded(newTemplatesLoaded);
      }
    }
  }, [
    dropdownTemplates,
    isLoading,
    programId,
    selectedTemplateIds,
    templatesLoaded,
  ]);

  const handleRemove = React.useCallback(
    (index: number) => {
      const newSelectedTemplates = templatesLoaded.filter(({ id }) =>
        selectedTemplateIds.includes(String(id))
      );
      newSelectedTemplates.splice(index, 1);
      onSelectedTemplatesChange(newSelectedTemplates);
    },
    [templatesLoaded, onSelectedTemplatesChange, selectedTemplateIds]
  );

  const handleSelectedIdsChange = React.useCallback(
    (ids: string[]) => {
      const newSelectedTemplates = ids
        .map((id) =>
          templatesLoaded.find((template) => template.id === Number(id))
        )
        .filter((item) => !!item);
      onSelectedTemplatesChange(newSelectedTemplates as WorkflowTemplate[]);
    },
    [onSelectedTemplatesChange, templatesLoaded]
  );

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={dropdownTemplates}
        selectedIds={selectedTemplateIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        maxHeight={maxDropdownHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        upward={upward}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={templatesLoaded
              .filter((template) =>
                selectedTemplateIds.includes(String(template.id))
              )
              .map((template) => template.name)}
            onRemoveSelectedValueAt={handleRemove}
            placeholder={placeholder}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};
