import React, { RefObject, useState } from 'react';

import { useProgram } from 'contexts/program';
import { BulkSelection, useInfiniteApiQuery } from 'hooks/common';
import { useBulkDeleteLanguageControlsMutation } from 'hooks/language-controls/useMutations';
import { useUserByIdsQuery } from 'hooks/user';
import { ParameterizedFilters } from 'models/language-controls/filter';
import { LanguageControl } from 'models/language-controls/language-control';
import {
  fetchLanguageControls,
  FetchLanguageControlsParams,
  QueryParameters,
} from 'services/api-language-controls';
import { ActionButtonType } from 'shared/BulkSelect/Actions';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { LanguageControlBanner } from './LanguageControlBanner';

type PropsType = {
  parentRef: RefObject<HTMLDivElement>;
  height: number;
  queryParameters: QueryParameters;
  parameterizedFilters: ParameterizedFilters;
};

export const LanguageControlsList: React.FC<PropsType> = ({
  parentRef,
  height,
  queryParameters,
  parameterizedFilters,
}) => {
  const { id: programId } = useProgram();

  const setIsBulkSelectMode = useState(false)[1];

  const {
    data,
    meta,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteApiQuery<FetchLanguageControlsParams, LanguageControl>(
    'language-controls',
    fetchLanguageControls,
    {
      ...queryParameters,
      ...parameterizedFilters,
    }
  );

  const {
    mutate: bulkDeleteLanguageControls,
    isLoading: isBulkDeleting,
  } = useBulkDeleteLanguageControlsMutation({
    onSuccess: () => {
      refetch?.();
    },
  });

  const bulkActions: ActionButtonType[] = [
    {
      label: 'Delete',
      onClick: (bulkSelection: BulkSelection) => {
        if (bulkSelection.type === 'all') {
          bulkDeleteLanguageControls({
            programId,
            ...parameterizedFilters,
          });
        } else if (bulkSelection.type === 'none') {
          bulkDeleteLanguageControls({
            programId,
            ids: bulkSelection.includedIds.map((id) => Number(id)),
          });
        }
        return true;
      },
      isLoading: isBulkDeleting,
      disabled: isBulkDeleting,
    },
  ];

  const creatorIds = [
    ...new Set(data.map((languageControl) => languageControl.creatorId)),
  ];

  const { data: creators } = useUserByIdsQuery(programId, creatorIds, {
    enabled: !!creatorIds.length,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  const findCreator = (creatorId: number) =>
    creators?.find((creator) => Number(creator.id) === creatorId);

  return (
    <BulkSelectWrapper
      disabled={!bulkActions.length}
      totalRecords={meta?.totalRecords ?? 0}
      itemName="language control"
      actions={[
        {
          type: 'select',
          groupLabel: 'Actions',
          actions: bulkActions,
        },
      ]}
      setIsBulkSelectMode={setIsBulkSelectMode}
    >
      <InfiniteBannerList
        itemCount={data.length}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        height={height}
        parentRef={parentRef}
      >
        {(index: number) => (
          <LanguageControlBanner
            languageControl={data[index]}
            creator={findCreator(data[index].creatorId)}
            refetchList={refetch}
          />
        )}
      </InfiniteBannerList>
    </BulkSelectWrapper>
  );
};
