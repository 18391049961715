import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { Initiative } from 'models/initiative';
import { WithPermission } from 'shared/WithPermission';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { useActions } from './useActions';

export const NewInitiative: React.FC<RouteComponentProps> = () => {
  const { create } = useActions();

  const [initiative, setInitiative] = React.useState<Partial<Initiative>>({});

  function onChange(updatedData: Partial<Initiative>) {
    setInitiative({ ...initiative, ...updatedData });
  }

  const header = (
    <>
      <h1 className="page-header">Initiative</h1>
    </>
  );

  const sidebar = <></>;

  const main = <Form initiative={initiative} onChange={onChange} />;

  function onSave() {
    create(initiative);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <Helmet>
        <title>Create Initiative</title>
      </Helmet>
      {initiative && (
        <MainEditor
          header={header}
          main={main}
          sidebar={sidebar}
          footer={<Footer initiative={initiative} action={onSave} />}
        />
      )}
    </WithPermission>
  );
};
