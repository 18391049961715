import {
  ErrorsMap,
  errorsMap,
} from 'components/publisher/pages/Review/Summary/ErrorList';

// Reuse old review errors texts, but update links
export default {
  ...errorsMap,
  usedLiquidSyntax: {
    link: errorsMap.usedLiquidSyntax.link,
    title: errorsMap.usedLiquidSyntax.title,
    text: errorsMap.usedLiquidSyntax.text,
    isWarning: errorsMap.usedLiquidSyntax.isWarning,
  },
  feature_enabled: {
    link: '../deliver',
    text: errorsMap.feature_enabled.text,
  },
  no_topic_or_audience: {
    link: '../deliver',
    text: errorsMap.no_topic_or_audience.text,
  },
  archive_date_in_past: {
    link: '../deliver',
    text: errorsMap.archive_date_in_past.text,
  },
  no_title_or_description: {
    link: '../compose/design',
    text: errorsMap.no_title_or_description.text,
  },
  no_blocks: {
    link: '../compose/design',
    text: errorsMap.no_blocks.text,
  },
  notification_datetime_in_past: {
    link: '../deliver',
    text: errorsMap.notification_datetime_in_past.text,
  },
  notification_datetimes_are_missing: {
    link: '../deliver',
    text: errorsMap.notification_datetimes_are_missing.text,
  },
  notification_has_empty_text: {
    link: '../deliver',
    text: errorsMap.notification_has_empty_text.text,
  },
  notification_datetimes_same_date: {
    link: '../deliver',
    text: errorsMap.notification_datetimes_same_date.text,
  },
  channel_override_no_audience: {
    link: '../deliver',
    text: errorsMap.channel_override_no_audience.text,
  },
  no_delivery_for_audience: {
    link: '../deliver',
    text: errorsMap.no_delivery_for_audience.text,
  },
  message_notification_length: {
    link: '../deliver',
    text: errorsMap.message_notification_length.text,
  },
  // new error messages for new review page
  notifications_and_disabled_engagement_boost: {
    link: '../deliver',
    text:
      'Retargeting is not enabled for this campaign. Please remove any configured retargeting notifications',
  },
  campaign_limit_exceeded: {
    link: '../compose/design',
    text: errorsMap.campaign_limit_exceeded.text,
  },
} as ErrorsMap;
