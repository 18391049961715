import React, { createContext, useContext } from 'react';
import { Program } from '../models/program';

type ProgramContextData = {
  program: Program;
  setProgramId: (id: number) => void;
};

// The context as stored internally contains both a program model
// and a setter function to change the current programId.
const ProgramContext = createContext({
  program: { id: 0 },
  setProgramId: (() => {}) as (id: number) => void,
});

const useProgramContext = (): ProgramContextData => {
  const context = useContext(ProgramContext);
  if (context === undefined) {
    throw new Error(
      'Program context hooks require a containing ProgramProvider'
    );
  }
  return context;
};

// useProgram returns the program model stored in the context.
export const useProgram = (): Program => {
  const { program } = useProgramContext();
  return program;
};

// useProgramIdState is for getting/setting the current program id. It
// provides a getter/setter tuple whose semantics match those of useState().
// It relies on the setProgramId function supplied when the context is set
// using ProgramProvider.
export const useProgramIdState = (): [number, (arg0: number) => void] => {
  const { program, setProgramId } = useProgramContext();
  return [program.id, setProgramId];
};

export const ProgramProvider: React.FC<{
  value: Program;
  setProgramId: (id: number) => void;
}> = ({ children, value, setProgramId }) => {
  const contextValue = { program: value, setProgramId };
  return (
    <ProgramContext.Provider value={contextValue}>
      {children}
    </ProgramContext.Provider>
  );
};
