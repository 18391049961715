import * as React from 'react';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import {
  PersonalizedFieldsUploadErrors,
  PersonalizedFieldsProcessingErrors,
} from 'services/api-personalized-fields';
import {
  ErrorModalConfig,
  ErrorModalResult,
  FileErrorsModal,
} from './FileErrorsModal';
import {
  UpdateFileModal,
  UpdateModalConfig,
  UpdateModalResult,
} from './UpdateFileModal';
import {
  UploadFileModal,
  UploadModalConfig,
  UploadModalResult,
} from './UploadFileModal';

export type { UpdateModalResult } from './UpdateFileModal';
export type { UploadModalResult } from './UploadFileModal';
export type { ErrorModalResult } from './FileErrorsModal';
export type AnyKindOfModalResult =
  | ErrorModalResult
  | UpdateModalResult
  | UploadModalResult;
export type PersonalizedFieldsModalResult = {
  result: AnyKindOfModalResult;
  errors?: PersonalizedFieldsUploadErrors | PersonalizedFieldsProcessingErrors;
};

export type { UpdateModalConfig } from './UpdateFileModal';
export type { UploadModalConfig } from './UploadFileModal';
export type { ErrorModalConfig } from './FileErrorsModal';
export type FileModalConfig =
  | ErrorModalConfig
  | UpdateModalConfig
  | UploadModalConfig;

export const FileModal: React.FC<{
  config: FileModalConfig;
  onCancel: (file?: PersonalizedFieldsFile) => void;
  onSubmit: (result: PersonalizedFieldsModalResult) => void;
}> = ({ config, onCancel, onSubmit }) => {
  switch (config.type) {
    case 'error':
      // eslint-disable-next-line no-case-declarations
      const { fileId, name, errors: fileErrors } = config as ErrorModalConfig;
      return (
        <FileErrorsModal
          fileId={fileId}
          name={name}
          errors={fileErrors}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'update':
      // eslint-disable-next-line no-case-declarations
      const { file } = config as UpdateModalConfig;
      return (
        <UpdateFileModal file={file} onCancel={onCancel} onSubmit={onSubmit} />
      );
    case 'upload':
      return <UploadFileModal onCancel={onCancel} onSubmit={onSubmit} />;
    default:
  }
  return null;
};
