import * as React from 'react';
import { useLocation } from '@reach/router';

const contextPrototype: {
  previous: (pathPrefix: string | string[]) => string;
} = { previous: (str) => str.toString() };

export const HistoryContext = React.createContext(contextPrototype);

export const useHistory = (): typeof contextPrototype => {
  const context = React.useContext(HistoryContext);
  if (context === undefined) {
    throw new Error(
      'History context hooks require a containing HistoryProvider'
    );
  }
  return context;
};

export const HistoryProvider: React.FC = ({ children }) => {
  const { pathname, search } = useLocation();

  const [history, setHistory] = React.useState<string[]>([]);

  React.useEffect(() => {
    setHistory((h) => [`${pathname}${search}`, ...h]);
  }, [pathname, search]);

  const programPrefix = React.useMemo(() => {
    const match = pathname.match(/^(\/\d+)\//);
    return (match && match[1]) || '';
  }, [pathname]);

  const previous = React.useCallback(
    (param: string | string[]) => {
      const list = typeof param === 'string' ? [param] : param;
      return (
        history.find((url) => {
          return list.find((potential) => {
            return url.indexOf(potential) === programPrefix.length;
          });
        }) || `${programPrefix}${list[0]}`
      );
    },
    [history, programPrefix]
  );

  const value: typeof contextPrototype = React.useMemo(
    () => ({
      previous,
    }),
    [previous]
  );

  return React.createElement(HistoryContext.Provider, { value }, children);
};
