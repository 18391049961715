import React from 'react';

import { Flex } from 'DesignSystem/Layout/Flex';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Box } from 'DesignSystem/Components';
import { JourneyModal, JourneyModalProps } from '../../JourneyModal';

export const SaveJourneyModal: React.FC<Pick<
  JourneyModalProps,
  'action' | 'actionsDisabled' | 'cancel'
>> = ({ action, actionsDisabled, cancel }) => (
  <JourneyModal
    name="Save changes"
    header="Save changes"
    body={
      !actionsDisabled ? (
        <Flex start>
          All changes will be saved before publishing. Would you like to save
          now and continue?
        </Flex>
      ) : (
        <Flex column>
          <LoadingSpinner size="small" />
          <Box padding={[8, 0, 0, 0]}>Saving changes</Box>
        </Flex>
      )
    }
    action={action}
    actionLabel="Save changes"
    cancel={cancel}
    cancelLabel="Cancel"
    actionsDisabled={actionsDisabled}
  />
);
