import React from 'react';
import qs from 'qs';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { Topic } from '../../../../../../../models/topic';
import styles from './styles.module.css';
import { Button, Section } from '../../../../../../../DesignSystem/Form';
import { MetricsItem } from '../../../../../Main/ContentResults/ContentResultsPage/MetricsItem';
import { useProgram } from '../../../../../../../contexts/program';
import { MAIcon } from '../../../../../../../shared/MAIcon';
import { usePermissions } from '../../../../../../../contexts/permissions';

interface Props extends RouteComponentProps {
  topic: Topic;
}

function getReportUrl(programId: number, topicId: number | string): string {
  const start = DateTime.now().minus({ days: 7 }).toISODate();
  const end = DateTime.now().toISODate();

  const searchParams = qs.stringify({
    date_range: [`${start}~${end}`],
    channel: [topicId],
  });
  return `/${programId}/app/insights/report/topics-overview?${searchParams}`;
}
const metricStyle = {
  flexGrow: 1,
};

export function TopicStats({ topic }: Props): JSX.Element | null {
  const { id: programId } = useProgram();
  const { permissions } = usePermissions();
  const reportUrl = getReportUrl(programId, topic.id);
  if (!topic.stats) {
    return null;
  }
  return (
    <Section
      className={styles.section}
      title="Activity"
      description="Review the activity of your topic"
      button={
        permissions.insightsAccess && (
          <Button
            compact
            openInNewTab
            icon={<MAIcon name="open_in_new" />}
            href={reportUrl}
            label="View Full Report"
            secondary
          />
        )
      }
    >
      <div className={styles.metricsList}>
        <MetricsItem
          name="Contributors"
          stat={{
            type: 'number',
            value: topic.stats.contributorCount,
          }}
          style={metricStyle}
        />
        <MetricsItem
          name="Members"
          stat={{
            type: 'number',
            value: topic.stats.followerCount,
          }}
          style={metricStyle}
        />
        <MetricsItem
          name="Posts Per Week"
          stat={{
            type: 'number',
            value: topic.stats.postsPerWeek,
          }}
          style={metricStyle}
        />
      </div>
    </Section>
  );
}
