import React from 'react';
import cx from 'classnames';
import filterStyles from 'DesignSystem/Table/filters.module.css';

export const FilterBoolean: React.FC<{
  title: string;
  checked?: boolean;
  onClick: () => void;
}> = ({ title, checked, onClick }) => (
  <div>
    <button
      type="button"
      onClick={onClick}
      className={cx(filterStyles.FilterTrigger, {
        [filterStyles.FilterTriggerApplied]: checked,
      })}
    >
      {title}
      {checked ? <strong>&nbsp;is Selected</strong> : null}
    </button>
  </div>
);
