import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type Props = {
  tenant: string;
  feature: string;
  reference_type: string;
  reference: string;
  vote?: string;
  comment_text?: string;
};

export type FeedbackData = {
  outputs: Array<string>;
  status: string;
};

export const postFeedback = async (props: Props): Promise<FeedbackData> => {
  const url = `${apiRoot}/v2/tenants/${props.tenant}/feedbacks`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(props),
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`Error fetching AI response: ${response.status}`);
};
