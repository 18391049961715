import { ThumbnailType } from 'shared/BannerListItem';
import { Microapp } from 'models/microapp';

const BACKGROUND_COLORS = ['#5D7E80', '#5D6480', '#6797AC'];

export function microappToThumbnail(
  microapp: Pick<Microapp, 'name'>,
  index: number
): ThumbnailType {
  return {
    title: microapp.name[0],
    color: '#F3F5F7',
    backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
  };
}
