import * as React from 'react';
import { firstHighlightableIndex } from './utils';

export type PropsType = {
  autofocus?: boolean;
  highlightable?: boolean;
  highlight?: number;
  itemHighlightable: (index: number) => boolean;
  itemCount: number;
  enableFocus: () => void;
  setHighlight: (index: number) => void;
};

export const useAutofocusAtTop = (props: PropsType): void => {
  const {
    autofocus,
    highlightable,
    highlight,
    itemHighlightable,
    itemCount,
    enableFocus,
    setHighlight,
  } = props;

  React.useEffect(() => {
    if (autofocus && highlightable && highlight == null) {
      enableFocus();
      const newHighlight = firstHighlightableIndex({
        itemCount,
        itemHighlightable,
      });
      if (newHighlight >= 0) {
        setHighlight(newHighlight);
      }
    }
  }, [
    autofocus,
    highlightable,
    highlight,
    itemCount,
    itemHighlightable,
    enableFocus,
    setHighlight,
  ]);
};
