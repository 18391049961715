import { useProgram } from 'contexts/program';
import { useUserExportQuery } from 'hooks/audience';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { UserExportData } from 'services/api-audiences';

export const UserExportLoader: React.FC<{
  userExportData: UserExportData;
  onDownloadStart: () => void;
}> = ({ userExportData, onDownloadStart }) => {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries(['userExport', userExportData.id]);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  });
  const { data } = useUserExportQuery(userExportData.id, programId);
  if (data && data.status === 'completed') {
    window.location.href = data.downloadUrl;
    onDownloadStart();
  }

  return null;
};
