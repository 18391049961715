import React from 'react';
import { NodeProps } from 'reactflow';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { useJourneyState } from 'contexts/journeys/journey';
import { useJourneyStepMetrics } from 'hooks/journeys/journey-metrics';
import {
  CommunicationStep,
  JourneyMode,
  MetricsMode,
} from 'models/journeys/journey';
import { BaseNode, NodeContent } from './BaseNode';
import styles from './node.module.css';
import { CommunicationIcon, EmptyCommunicationIcon } from '../icons';
import { ContentListState } from '../../JourneyContentListDrawer/Drawer';
import { Metrics } from './Metrics';
import { Metric } from './Metrics/Metric';

export const CommunicationNode = React.memo((props: NodeProps) => {
  const { id } = props;

  const {
    journey,
    currentGraph,
    metricsMode,
    journeyMode,
    setActiveStepId,
    setContentListState,
    getStep,
  } = useJourneyState();

  const step = getStep('communication', id);

  const isEmpty = !step?.designId;

  const handleClick = () => {
    if (isEmpty && journeyMode === JourneyMode.Edit) {
      setActiveStepId(id);
      setContentListState(ContentListState.Open);
    }
  };

  if (!step) {
    return <div />;
  }

  return (
    <BaseNode
      key={id}
      activateOnClick={!isEmpty}
      onClick={handleClick}
      className={
        isEmpty && journeyMode === JourneyMode.View ? styles.disabledNode : ''
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <NodeContent>
        <Flex column start className={styles.nodeContainer}>
          <Flex start className={styles.bodyContainer}>
            <Box className={styles.icon}>
              {isEmpty ? <EmptyCommunicationIcon /> : <CommunicationIcon />}
            </Box>
            <Flex className={styles.messageBody} column start>
              <span>Communication</span>
              <span>{step?.title}</span>
            </Flex>
          </Flex>
          {journey?.id && currentGraph?.id && currentGraph?.isLive && (
            <CommunicationMetrics
              journeyId={journey.id}
              graphId={currentGraph.id}
              step={step}
              mode={metricsMode}
            />
          )}
        </Flex>
      </NodeContent>
    </BaseNode>
  );
});

const CommunicationMetrics: React.FC<{
  journeyId: number;
  graphId: number;
  step: CommunicationStep;
  mode: MetricsMode;
}> = ({ journeyId, graphId, step, mode }) => {
  const { data, isLoading } = useJourneyStepMetrics(
    journeyId,
    graphId,
    'communication',
    step.id
  );

  const getMetricsComp = () => {
    if (data && mode === MetricsMode.members) {
      return (
        <>
          <Metric label="Entered" value={data.entered} />
          <Metric label="Current" value={data.current} />
          <Metric label="Exited" value={data.completed} />
        </>
      );
    }

    if (data && mode === MetricsMode.engagement) {
      return (
        <>
          <Metric label="Sent" value={data.sent} />
          <Metric label="Delivered" value={data.delivered} />
          <Metric label="Opens" value={data.opened} />
          <Metric label="Clicks" value={data.clicked} />
        </>
      );
    }

    return undefined;
  };
  return <Metrics isLoading={isLoading}>{getMetricsComp()}</Metrics>;
};
