import { Section } from 'DesignSystem/Form';
import { useUniqueId } from 'hooks/useUniqueId';
import React, { FC, PropsWithChildren, useContext } from 'react';
import cx from 'classnames';
import { Text } from 'DesignSystem/Typography';
import { TabName, useTopicFormCtx } from '../../context';
import { AboutDesignContext } from '../DesignView/contexts/about';
import styles from './styles.module.css';

const on = ': ON';

export const ReviewTopicLandingPageContent: FC = () => {
  const { baseUrl, state, topic } = useTopicFormCtx();
  const { aboutPage } = useContext(AboutDesignContext);

  return (
    <Section title="Landing Page Content" href={`${baseUrl}/design`}>
      <div className={cx(styles.VerticallySpaced, styles.Gap16)}>
        <InfoSection name="Header">
          <ErrorField
            label="Name"
            condition={!!state.fields.name}
            positive={state.fields.name}
            negative="Missing"
          />
          <ErrorField
            label="Description"
            condition={!!state.fields.description}
            positive={state.fields.description}
            negative="Missing"
          />
          <StaticField label="Topic Image">
            {state.fields.image?.url ? 'Image Uploaded' : 'Default Topic Image'}
          </StaticField>

          <StaticField label="Cover Image">
            {!state.fields.coverEnabled
              ? 'OFF'
              : `ON, ${
                  state.fields.cover?.url
                    ? 'Image Uploaded'
                    : 'Default Cover Image'
                }`}
          </StaticField>
        </InfoSection>

        <VisibleWhenEnabledTabs contains="about">
          <InfoSection name="About Page" suffix={on}>
            <ErrorField
              label="Content"
              condition={!!aboutPage && !aboutPage.isEmpty}
              positive="Added"
              negative="Not added"
            />
            <ErrorField
              label="Preview"
              condition={!!aboutPage?.previewContent}
              positive="Added"
              negative="Not added"
            />
          </InfoSection>
        </VisibleWhenEnabledTabs>

        <VisibleWhenEnabledTabs contains="posts">
          <InfoSection name="Posts Page" suffix={on}>
            <StaticField label="Posts">
              {topic.stats?.postsCount ?? 0}
            </StaticField>
            <StaticField label="Pinned Posts">
              {state.fields.pinnedPosts.length}
            </StaticField>
          </InfoSection>
        </VisibleWhenEnabledTabs>

        <VisibleWhenEnabledTabs contains="shortcuts">
          <InfoSection name="Shortcuts Page" suffix={on}>
            <ErrorField
              label="Shortcuts"
              condition={!!state.fields.shortcuts.length}
              positive={state.fields.shortcuts.length}
              negative="None added"
            />
          </InfoSection>
        </VisibleWhenEnabledTabs>

        <VisibleWhenEnabledTabs contains="members">
          <InfoSection name="Members Page" suffix={on}>
            <StaticField label="Members">
              {topic.stats?.followerCount ?? 0}
            </StaticField>
            <StaticField label="Contributors">
              {topic.stats?.contributorCount ?? 0}
            </StaticField>
          </InfoSection>
        </VisibleWhenEnabledTabs>
      </div>
    </Section>
  );
};

const InfoSection: FC<PropsWithChildren<{
  name: string;
  suffix?: string;
}>> = ({ children, name: sectionName, suffix: sectionSuffix }) => {
  const id = useUniqueId();
  return (
    <Section ariaLabelledBy={id}>
      <div className={styles.Flex}>
        <div className={styles.HalfWidth}>
          <p>
            <strong>
              <span id={id}>{sectionName}</span>
              {sectionSuffix}
            </strong>
          </p>
        </div>
        <div
          className={cx(styles.VerticallySpaced, styles.Gap8, styles.HalfWidth)}
        >
          {children}
        </div>
      </div>
    </Section>
  );
};

const VisibleWhenEnabledTabs: FC<PropsWithChildren<{ contains: TabName }>> = ({
  children,
  contains,
}) => {
  const { state } = useTopicFormCtx();
  if (state.fields.enabledTabs.includes(contains)) {
    return <>{children}</>;
  }
  return null;
};

type StaticFieldProps = {
  label: string;
};

const StaticField: React.FC<React.PropsWithChildren<StaticFieldProps>> = ({
  label,
  children,
}) => (
  <Text as="p" className={{ Body: true }}>
    {label}: <strong>{children}</strong>
  </Text>
);

type ErrorFieldProps = {
  label: string;
  condition: boolean;
  positive: number | string;
  negative: string;
};

const ErrorField: React.FC<ErrorFieldProps> = ({
  label,
  condition,
  positive,
  negative,
}) => (
  <Text as="p" className={{ Body: true }}>
    {label}:{' '}
    {condition ? (
      <strong>{positive}</strong>
    ) : (
      <Text className={{ redFull: true }}>{negative}</Text>
    )}
  </Text>
);
