import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useAudiencesQuery } from 'hooks/audience';
import { getFeedPreviewQueryKey } from 'hooks/feedPreview';
import {
  useTopicShortcutsQuery,
  useUpdateTopic,
  usePublishTopic,
} from 'hooks/topics';
import { Audience } from 'models/audience';
import {
  buildCriterion,
  LandingPageTab,
  LandingPageTabType,
  PinnableContent,
  Topic,
  TopicImage,
  TopicShortcut,
} from 'models/topic';
import { NetworkError } from 'services/Errors/NetworkError';
import { navigate } from '@reach/router';
import { useToggle } from 'hooks/useToggle';
import { ConflictError } from 'services/Errors/ConflictError';
import { Modal, ToggleModal } from './modals';

export type FormView = 'design' | 'settings' | 'review';

export type TabName = 'about' | 'posts' | 'shortcuts' | 'members';

export type SidebarName =
  | 'navigation'
  | 'editHeader'
  | 'editPinnedPosts'
  | 'shortcuts'
  | 'about';

export const AvailableTabs: LandingPageTabType[] = [
  LandingPageTabType.About,
  LandingPageTabType.Posts,
  LandingPageTabType.Shortcuts,
  LandingPageTabType.Members,
];

type ImageField =
  | null // No image
  | TopicImage // Image that is already uploaded to the server
  | { file: File; url: string }; // Image that is not uploaded yet. Url can be created with https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static

/** This just first iteration of the form state. It might be changed in the future */
export interface FormState {
  activeTab: TabName;
  activeSidebar: SidebarName | null;
  modal: Modal;

  fields: {
    name: string;
    description: string;
    image: ImageField;
    cover: ImageField;
    coverEnabled: boolean;
    shortcuts: TopicShortcut[];
    pinnedPosts: PinnableContent[];
    enabledTabs: TabName[];
    published: boolean;
    isPromoted: boolean;
    targeted: boolean;
    audience: Array<Audience>;
    autoFollow: boolean;
    autoFollowAudience: Array<Audience>;
    autoFollowCriterion: boolean;
    isUserSubmittable: boolean;
    autoPublish: boolean;
    publishDraftAttempted: boolean;
  };
}

// field keys that belong in the Topic model
const TOPIC_FIELD_KEYS = [
  'name',
  'description',
  'image',
  'cover',
  'enabledTabs',
  'coverEnabled',
  // Settings
  'published',
  'isPromoted',
  'targeted',
  'autoFollow',
  'isUserSubmittable',
  'autoPublish',
  'publishDraftAttempted',
] as const;

type TopicFieldKeys = typeof TOPIC_FIELD_KEYS[number];
export type TopicFields = Pick<FormState['fields'], TopicFieldKeys>;

type SanitizeRule = (fields: FormState['fields']) => FormState['fields'];

// This is a list of rules that will be applied to the form state before updating the topic
const SANITIZE_RULES: SanitizeRule[] = [
  (fields) => ({
    ...fields,
    name: fields.name.trim(),
    description: fields.description.trim(),
  }),
  // If the topic is not published, it cannot be recommended
  (fields) => ({
    ...fields,
    isPromoted: fields.published ? fields.isPromoted : false,
  }),
  // If the topic is not targeted, drop the audience select value
  (fields) => ({
    ...fields,
    audience: fields.targeted ? fields.audience : [],
  }),
  // If the topic is not auto-followed or auto-follow is targeted to entire audience, drop the auto-follow audience select value
  (fields) => ({
    ...fields,
    autoFollowAudience:
      fields.autoFollow && fields.autoFollowCriterion
        ? fields.autoFollowAudience
        : [],
  }),
  // If the topic is not user submittable, it cannot be auto-published
  (fields) => ({
    ...fields,
    autoPublish: fields.isUserSubmittable ? fields.autoPublish : false,
  }),
];

function sanitizeSettings(fields: FormState['fields']): FormState['fields'] {
  return SANITIZE_RULES.reduce((acc, rule) => rule(acc), fields);
}

function keyIsTopicField(key: string): key is typeof TOPIC_FIELD_KEYS[number] {
  return ((TOPIC_FIELD_KEYS as unknown) as string[]).includes(key);
}

function stateToTopic(topic: Topic, state: FormState): Topic {
  const {
    cover,
    coverEnabled,
    image,
    enabledTabs,
    ...attr
  } = Object.fromEntries(
    Object.entries(state.fields).filter(([key]) => keyIsTopicField(key))
  ) as Pick<FormState['fields'], typeof TOPIC_FIELD_KEYS[number]>;

  const groupIds = state.fields.audience.map((audience) => audience.name);
  const autoFollowGroupIds = state.fields.autoFollowAudience.map(
    (audience) => audience.name
  );

  return {
    ...topic,
    ...attr,
    groupIds,
    criterionV2: buildCriterion(groupIds),
    autoFollowGroupIds,
    autoFollowCriterion: buildCriterion(autoFollowGroupIds),
    uploadedImage: image,
    coverImageUrl: cover ? cover.url : null,
    useCoverImage: coverEnabled,
    landingPageTabs: AvailableTabs.map((tabType) => {
      const tabInfo = {
        isHidden: !state.fields.enabledTabs.includes(tabType),
        position: enabledTabs.indexOf(tabType),
      };

      if (tabType === LandingPageTabType.Posts) {
        return {
          ...tabInfo,
          tabType,
          pinnedContents: state.fields.pinnedPosts,
        };
      }
      return { ...tabInfo, tabType };
    }),
  };
}

const DEFAULT_LANDING_PAGE_TABS: LandingPageTab[] = [
  {
    tabType: LandingPageTabType.Posts,
    isHidden: false,
    position: 1,
    pinnedContents: [],
  },
];

function getEnabledTabs(topic: Topic): TabName[] {
  const tabs =
    topic.landingPageTabs && topic.landingPageTabs.length > 0
      ? topic.landingPageTabs
      : DEFAULT_LANDING_PAGE_TABS;
  return tabs
    .filter((tab) => !tab.isHidden)
    .sort((a, b) => {
      return a.position - b.position;
    })
    .map((tab) => tab.tabType) as TabName[];
}

function getTopicStateFields(topic: Topic): TopicFields {
  return {
    name: topic.name,
    description: topic.description,
    image: topic.uploadedImage || null,
    cover: topic.coverImageUrl ? { url: topic.coverImageUrl } : null,
    coverEnabled: topic.useCoverImage,
    enabledTabs: getEnabledTabs(topic),
    published: topic.published || false,
    isPromoted: topic.isPromoted || false,
    targeted: topic.targeted,
    autoFollow: topic.autoFollow || false,
    isUserSubmittable: topic.isUserSubmittable || false,
    autoPublish: topic.autoPublish || false,
    publishDraftAttempted: topic.publishDraftAttempted || false,
  };
}

function createFieldState(
  topic: Topic,
  additionalData: {
    enabledTabs?: TabName[];
    audience?: Audience[];
    autoFollowAudience?: Audience[];
    shortcuts?: TopicShortcut[];
  } = {}
): FormState['fields'] {
  const {
    enabledTabs = ['posts'],
    audience = [],
    autoFollowAudience = [],
    shortcuts = [],
  } = additionalData;

  const postsTab = topic.landingPageTabs?.find(
    (tab) => tab.tabType === 'posts'
  );
  let pinnedPosts: PinnableContent[] = [];
  if (postsTab && 'pinnedContents' in postsTab) {
    pinnedPosts = postsTab.pinnedContents;
  }

  return {
    ...getTopicStateFields(topic),
    shortcuts,
    pinnedPosts,
    enabledTabs,
    audience,
    autoFollowCriterion: autoFollowAudience.length > 0,
    autoFollowAudience,
  };
}

interface HandleChangeOptions {
  resetPublishError?: boolean;
}

type HandleChange = <K extends keyof FormState['fields']>(
  key: K,
  value: FormState['fields'][K],
  opts?: HandleChangeOptions
) => void;

type ValidationState = {
  isValid: boolean;
  isValidating: boolean;
};

export interface TopicFormCtxType {
  topic: Topic;
  state: FormState;
  baseUrl: string;
  selectTab: (tab: TabName) => void;
  toggleSidebar: (sidebar: SidebarName | null) => void;
  toggleModal: ToggleModal;
  handleChange: HandleChange;
  handleSave: () => void;
  handlePublish: () => void;
  handlePublishAttempt: () => Promise<void>;
  validationState: ValidationState;
  setValidationState: Dispatch<SetStateAction<ValidationState>>;
  hasUnsavedChanges: boolean;
  isTopicUpdating: boolean;
  isTopicPublishing: boolean;
  topicUpdatedAt?: Date;
  topicUpdateError?: Error;
  topicPublishError?: Error;
  publishErrorsModalToggle: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
}

export const TopicFormContext = createContext<TopicFormCtxType>(
  {} as TopicFormCtxType
);

export function useTopicFromCtxValue(
  baseUrl: string,
  topic: Topic
): TopicFormCtxType {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();

  const [validationState, setValidationState] = useState<ValidationState>({
    isValid: true,
    isValidating: false,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [topicUpdatedAt, setTopicUpdatedAt] = useState<Date>();
  const [topicUpdateError, setTopicUpdateError] = useState<Error>();
  const [topicPublishError, setTopicPublishError] = useState<Error>();
  const returnUrl = useMemo(() => baseUrl.split('/').slice(0, -2).join('/'), [
    baseUrl,
  ]);

  const { updateAsync, isLoading: isTopicUpdating } = useUpdateTopic(
    programId,
    {
      onMutate: () => {
        setTopicUpdateError(undefined);
        setTopicUpdatedAt(undefined);
      },
      onSuccess: () => {
        setTopicUpdatedAt(new Date());
        setHasUnsavedChanges(false);
      },
      onError: (err) => {
        if (err instanceof NetworkError) {
          setFlashMessage({
            severity: 'error',
            message:
              'Unable to save changes due to a network error.\nPlease check your internet connection and try again.',
          });
          return;
        }
        setTopicUpdateError(err);
      },
    }
  );

  const { publish, isLoading: isTopicPublishing } = usePublishTopic(programId, {
    onMutate: () => {
      updateFieldState({ publishDraftAttempted: true });
      setTopicPublishError(undefined);
    },
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Topic published successfully',
      });
      navigate(returnUrl);
    },
    onError: (err) => {
      if (err instanceof NetworkError) {
        setFlashMessage({
          severity: 'error',
          message:
            'Unable to publish topic due to a network error.\nPlease check your internet connection and try again.',
        });
        return;
      }

      if (err instanceof ConflictError) {
        publishErrorsModalToggle.enable();
      } else {
        setFlashMessage({
          severity: 'error',
          message: 'Unable to publish topic',
        });
      }

      setTopicPublishError(err);
    },
  });

  const { data: audience } = useAudiencesQuery(
    {
      name: topic.groupIds?.length ? topic.groupIds : [''],
      pageSize: topic.groupIds?.length,
      programId: topic.programId || 0,
    },
    { enabled: !!topic.groupIds?.length }
  );

  const { data: autoFollowAudience } = useAudiencesQuery(
    {
      name: topic.autoFollowGroupIds?.length ? topic.autoFollowGroupIds : [''],
      pageSize: topic.autoFollowGroupIds?.length,
      programId: topic.programId || 0, // If topic is new, query would be disabled anyway
    },
    { enabled: !!topic.autoFollowGroupIds?.length }
  );

  const { data: shortcuts } = useTopicShortcutsQuery(programId, topic.id);

  const [state, setState] = useState<FormState>(() => {
    const enabledTabs = getEnabledTabs(topic);
    return {
      activeTab: enabledTabs[0],
      activeSidebar: null,
      modal: { type: null },
      fields: createFieldState(topic, {
        enabledTabs,
        audience,
        autoFollowAudience,
        shortcuts,
      }),
    };
  });

  const selectTab: TopicFormCtxType['selectTab'] = useCallback((tab) => {
    setState((currentState) => ({
      ...currentState,
      activeTab: tab,
    }));
  }, []);

  const toggleSidebar: TopicFormCtxType['toggleSidebar'] = useCallback(
    (sidebar) => {
      setState((currentState) => ({
        ...currentState,
        activeSidebar: sidebar,
      }));
    },
    []
  );

  const toggleModal = useCallback<ToggleModal>((modal) => {
    setState((currentState) => ({
      ...currentState,
      modal,
    }));
  }, []);

  const publishErrorsModalToggle = useToggle();

  const handleChange: HandleChange = useCallback((key, value, opts) => {
    setTopicUpdatedAt(undefined);
    setState((currentState) => {
      const newState = { ...currentState };
      newState.fields = {
        ...currentState.fields,
        [key]: value,
      };
      // If user disabled current tab, switch to the first enabled tab
      if (
        key === 'enabledTabs' &&
        !newState.fields.enabledTabs.includes(currentState.activeTab)
      ) {
        const [firstEnabledTab] = newState.fields.enabledTabs;
        newState.activeTab = firstEnabledTab;
      }

      // If user changed any field, mark the form as unsaved.
      // Shortcuts are currently the only exception, as they are saved separately.
      if (key !== 'shortcuts') {
        setHasUnsavedChanges(true);
      }

      if (key === 'audience') {
        const newAudience = value as Array<Audience>;
        const currentAutoFollowAudience = newState.fields.autoFollowAudience;

        // E.g if we have
        // newAudience = [1, 2]
        // currentAutoFollowAudience = [1, 2, 3]
        // we want to keep [1, 2] and remove 3
        const autoFollowGroupsStillPresentInAudience = currentAutoFollowAudience.filter(
          (autoFollowGroup) =>
            newAudience.some((newGroup) => newGroup.id === autoFollowGroup.id)
        );

        newState.fields.autoFollowAudience = autoFollowGroupsStillPresentInAudience;
      }

      if (opts?.resetPublishError) setTopicPublishError(undefined);
      return newState;
    });
  }, []);

  const updateFieldState = useCallback(
    (updates: Partial<FormState['fields']>) => {
      setState((prev) => ({
        ...prev,
        fields: {
          ...prev.fields,
          ...updates,
        },
      }));
    },
    []
  );

  const validForUpdate =
    validationState.isValid && !validationState.isValidating;

  const updateTopic = useCallback(async () => {
    if (validForUpdate) {
      const newState = {
        ...state,
        fields: sanitizeSettings(state.fields),
      };
      // Partial state update with sanatized name and description.
      // Keeping other settings as even if not relevant, it allows the user to be able to easily switch back.
      updateFieldState({
        name: newState.fields.name,
        description: newState.fields.description,
      });
      await updateAsync(stateToTopic(topic, newState));
      queryClient.invalidateQueries(
        getFeedPreviewQueryKey(programId, topic.id)
      );
    }
  }, [
    validForUpdate,
    updateFieldState,
    state,
    updateAsync,
    topic,
    queryClient,
    programId,
  ]);

  const handleSave = useCallback(async () => {
    try {
      await updateTopic();
    } catch {
      // Ignore the error, this will be handled by the onError handler provided to useUpdateTopic
    }
  }, [updateTopic]);

  const handlePublish = useCallback(async () => {
    if (hasUnsavedChanges) {
      try {
        await updateTopic();
      } catch {
        // Avoid publishing if there was an error updating the topic
        return;
      }
    }
    publish(Number(topic.id));
  }, [hasUnsavedChanges, publish, topic.id, updateTopic]);

  useEffect(() => {
    updateFieldState({ audience: audience ?? [] });
  }, [audience, updateFieldState]);

  useEffect(() => {
    updateFieldState({
      autoFollowAudience: autoFollowAudience ?? [],
      autoFollowCriterion: autoFollowAudience && autoFollowAudience.length > 0,
    });
  }, [autoFollowAudience, updateFieldState]);

  useEffect(() => {
    updateFieldState({ shortcuts: shortcuts ?? [] });
  }, [shortcuts, updateFieldState]);

  const handlePublishAttempt = async () => {
    if (state.fields.publishDraftAttempted) return;

    const newState = {
      ...state,
      fields: {
        ...state.fields,
        publishDraftAttempted: true,
      },
    };

    try {
      await updateAsync(stateToTopic(topic, newState));
    } catch {
      // Ignore the errors as this will be handled by the onError handler provided to useUpdateTopic
    }
    updateFieldState({ publishDraftAttempted: true });
  };

  return {
    topic,
    state,
    baseUrl,
    selectTab,
    toggleSidebar,
    toggleModal,
    handleChange,
    handleSave,
    handlePublish,
    validationState,
    setValidationState,
    hasUnsavedChanges,
    isTopicUpdating,
    isTopicPublishing,
    topicUpdatedAt,
    topicUpdateError,
    topicPublishError,
    handlePublishAttempt,
    publishErrorsModalToggle: {
      isOpen: publishErrorsModalToggle.value,
      open: publishErrorsModalToggle.enable,
      close: publishErrorsModalToggle.disable,
    },
  };
}

export function useTopicFormCtx(): TopicFormCtxType {
  return useContext(TopicFormContext);
}
