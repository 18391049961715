import React from 'react';
import { Gray10PillClass } from 'DesignSystem/Components/Pill';
import { Subsection } from 'DesignSystem/Form';
import { Pills } from 'DesignSystem/Components/Pills';
import { DeliveryChannelOption } from './useDefaultChannels';

export const DefaultChannelsShow: React.FC<{
  channelsValues: Array<DeliveryChannelOption>;
}> = ({ channelsValues }) => {
  return (
    <Subsection
      title="Default Channels"
      description="Channels pre-selected when an audience is added on the Deliver page"
    >
      <Pills
        values={channelsValues}
        render={({ label, checked }) => (
          <>
            {label}:&nbsp;
            <strong>{(checked && 'ON') || 'OFF'}</strong>
          </>
        )}
        pillIcon={({ smallIcon }) => smallIcon}
        pillClassName={Gray10PillClass}
        small
        padding={[0, 8]}
      />
    </Subsection>
  );
};
