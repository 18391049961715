import React from 'react';
import styles from './circle-gauge.module.css';

export const CircleGauge: React.FC<{
  radius: number;
  maximum: number;
  value: number;
}> = ({ radius, maximum, value }) => {
  const diameter = radius * 2;
  const circumference = 7 * 2 * Math.PI;
  const percentage = value / maximum;
  return (
    <span className={styles.gauge}>
      <svg
        width={diameter}
        height={diameter}
        viewBox={`0 0 ${diameter} ${diameter}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={styles.background}
          cx={radius}
          cy={radius}
          r="6"
          strokeWidth="2"
        />
        <circle
          className={styles.indicator}
          cx={radius}
          cy={radius}
          r="6"
          strokeWidth="2"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={`${circumference - percentage * circumference}`}
        />
      </svg>
    </span>
  );
};
