import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';

export const CreatePage: React.FC<RouteComponentProps> = () => (
  <BossViewPage
    app="samba"
    title="Workflow Template Creator"
    src="#galleries/new"
    returnUrls={[
      '/configure/workflow-templates/archived',
      '/configure/workflow-templates/draft',
      '/configure/workflow-templates/published',
    ]}
  />
);
