import { IframeFieldData, ImageFieldData } from 'models/donkey';
import { IframeData, IframeMode } from 'models/iframe';
import { iframeFieldToData, iframeToField } from 'models/publisher/block';
import { useCallback, useState } from 'react';
import { FieldFormProps } from '../../../useFieldForm';

type IframeUpdateArgs = {
  mode?: IframeMode;
  url?: string;
  rawHtml?: string;
  image?: ImageFieldData;
};

export const useIFrameDataMutator: (
  props: FieldFormProps<IframeFieldData>
) => {
  data: IframeData;
  update: (updatedData: IframeUpdateArgs) => void;
} = ({ data, onChange }) => {
  const [iframeData, setIframeData] = useState<IframeData>(
    iframeFieldToData(data)
  );

  const update = useCallback(
    (updatedData: IframeUpdateArgs) => {
      const newIframeData: IframeData = {
        mode: updatedData.mode ?? iframeData.mode,
        url: updatedData.url ?? iframeData.url,
        rawHtml: updatedData.rawHtml ?? iframeData.rawHtml,
        image: updatedData.image ?? iframeData.image,
      };
      setIframeData(newIframeData);
      onChange(iframeToField(newIframeData));
    },
    [iframeData, onChange]
  );

  return {
    data: iframeData,
    update,
  };
};
