import React from 'react';
import { useCancelJourneyProcessing } from 'hooks/journeys/journeys';
import { useToggle } from 'hooks/useToggle';
import { Journey } from 'models/journeys/journey';
import { ValidationError } from 'services/Errors/ValidationError';
import { PrimaryButton } from './Buttons';
import { ErrorJourneyModal } from './PublishModals/ErrorJourneyModal';

export function CancelPublishing({
  id,
}: {
  id: NonNullable<Journey['id']>;
}): JSX.Element {
  const { value: showErrorsModal, toggle: toggleErrorsModal } = useToggle();
  const {
    cancelJourneyProcessing,
    status,
    error,
  } = useCancelJourneyProcessing();
  const isCancelling = status === 'loading';
  return (
    <>
      <PrimaryButton
        label={isCancelling ? 'Cancelling...' : 'Cancel Publish'}
        onClick={() =>
          cancelJourneyProcessing(id, {
            onError: toggleErrorsModal,
          })
        }
        disabled={isCancelling || showErrorsModal}
      />
      {showErrorsModal && (
        <ErrorJourneyModal
          action={toggleErrorsModal}
          title="Publishing was not cancelled"
          error={cancelPublishingErrorMessage(error)}
        />
      )}
    </>
  );
}

export function cancelPublishingErrorMessage(error: unknown): string {
  if (error instanceof ValidationError) {
    return 'This journey has already finished publishing and is now active. You can stop the journey to return it to draft status.';
  }

  return 'An error occurred while cancelling publishing. Please try again.';
}
