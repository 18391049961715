import { useCallback } from 'react';
import { PollFieldData } from 'models/publisher/block';
import { FieldFormProps } from '../../../useFieldForm';

export const usePollDataMutator: (
  props: FieldFormProps<PollFieldData>
) => {
  poll: PollFieldData;
  updatePoll: (poll: Partial<PollFieldData>) => void;
} = ({ data, onChange }) => {
  const updatePoll = useCallback(
    (value: Omit<Partial<PollFieldData>, 'poll_id' | 'processed'>) => {
      const updated = { ...data, ...value };
      onChange(updated);
    },
    [onChange, data]
  );
  return {
    poll: data,
    updatePoll,
  };
};
