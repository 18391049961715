import React, { ChangeEvent, useState } from 'react';
import styles from './TextInput.module.css';

export const TextInput: React.FC<{
  onChange: (value: string[]) => void;
  value: string[];
  type?: string;
}> = ({ onChange, value, type = 'text' }) => {
  const [selectedValue, setSelectedValue] = useState<string>(value[0] || '');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    onChange([event.target.value] || []);
  };

  function isNumeric(data: string) {
    return /^-?\d+$/.test(data);
  }

  return (
    <input
      type={type}
      className={styles.numberInput}
      value={selectedValue}
      onKeyPress={(e) => {
        if (type === 'number' && !isNumeric(e.key)) e.preventDefault();
      }}
      onChange={handleChange}
      placeholder={type === 'number' ? 'Number Value' : 'Value'}
    />
  );
};
