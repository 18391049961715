import { useCallback } from 'react';
import { PollFieldData } from 'models/publisher/block';
import { usePollDataMutator } from './usePollDataMutator';

export function usePollThankYouText({
  poll: { thank_you_text },
  updatePoll,
}: ReturnType<typeof usePollDataMutator>): Pick<
  PollFieldData,
  'thank_you_text'
> & {
  changeThankYouText(to: string): void;
} {
  const changeThankYouText = useCallback(
    (text = '') => updatePoll({ thank_you_text: text }),
    [updatePoll]
  );
  return { thank_you_text, changeThankYouText };
}
