import * as React from 'react';
import cx from 'classnames';
import { Button } from 'DesignSystem/Form';
import { SearchBar } from 'shared/SearchBar';
import styles from './dropdown-menu.module.css';

type PropsType = {
  maxHeight: number;
  searchEnabled?: boolean;
  searchTerm?: string;
  searchPlaceholder?: string;
  onSearchTermChange?: (value: string) => void;
  clearEnabled?: boolean;
  onClear?: () => void;
  className?: string;
  childrenContentHeight: number;
  children: (height: number) => React.ReactNode;
  hasClearButton?: boolean;
  dismissButton?: string | React.ReactNode;
  onDismissRef?: React.MutableRefObject<() => void>;
};

export const DropdownMenu: React.FC<PropsType> = (props) => {
  const {
    maxHeight,
    searchEnabled,
    searchPlaceholder,
    searchTerm,
    onSearchTermChange,
    clearEnabled,
    onClear,
    childrenContentHeight,
    children,
    hasClearButton,
    onDismissRef,
    dismissButton,
    className,
  } = props;

  const [childPaneHeight, setChildPaneHeight] = React.useState(0);
  const headerRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const footerRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  React.useLayoutEffect(() => {
    const headerHeight =
      headerRef.current?.getBoundingClientRect()?.height || 0;
    const footerHeight =
      footerRef.current?.getBoundingClientRect()?.height || 0;
    setChildPaneHeight(
      Math.min(maxHeight - (headerHeight + footerHeight), childrenContentHeight)
    );
  }, [
    headerRef,
    childrenContentHeight,
    maxHeight,
    clearEnabled,
    searchEnabled,
  ]);

  return (
    <div className={cx(className, styles.DropdownMenu)}>
      {searchEnabled && onSearchTermChange && (
        <div ref={headerRef} className={styles.DropdownHeader}>
          <SearchBar
            compact
            width="100%"
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChange={onSearchTermChange}
            hasClearButton={hasClearButton}
          />
        </div>
      )}
      <div className={styles.DropdownList}>{children(childPaneHeight)}</div>
      {(dismissButton || clearEnabled) && (
        <div ref={footerRef} className={styles.DropdownFooter}>
          <Button
            compact
            text
            disabled={!clearEnabled}
            onClick={onClear}
            label="Clear"
          />
          {dismissButton && (
            <Button
              compact
              disabled={!dismissButton}
              onClick={() => onDismissRef?.current()}
              label={dismissButton}
            />
          )}
        </div>
      )}
    </div>
  );
};
