import React, { ComponentProps } from 'react';
import { usePermissions } from 'contexts/permissions';
import { PageHeader } from 'DesignSystem/Layout/Pages';
import { BOX_CONFIGURATION_PAGE_BREADCRUMBS } from './breadcrumbs';
import { getBoxConfigurationPageTabs } from './tabs';

type BoxConfigurationPageProps = Partial<ComponentProps<typeof PageHeader>>;

export const BOX_CONFIGURATION_PAGE_TITLE = 'Box Knowledge Management';
export const BOX_CONFIGURATION_PAGE_DESCRIPTION =
  'Create and manage Box folders. Link an audience to folders.';

/* eslint-disable react/jsx-props-no-spreading */
export const BoxConfigurationPageHeader: React.FC<BoxConfigurationPageProps> = (
  props
) => {
  const { permissions } = usePermissions();

  return (
    <PageHeader
      title={BOX_CONFIGURATION_PAGE_TITLE}
      description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
      tabs={getBoxConfigurationPageTabs(permissions)}
      breadcrumbs={BOX_CONFIGURATION_PAGE_BREADCRUMBS}
      {...props}
    />
  );
};
/* eslint-enable react/jsx-props-no-spreading */
