import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';

export type PostDetermineTopicSuggestionsRequest = {
  programId: number;
  contentId: number;
};

export type PostDetermineTopicSuggestionsResponse = {
  outputId?: string;
};

export const postDetermineTopicSuggestions = async (
  requestParameters: PostDetermineTopicSuggestionsRequest
): Promise<PostDetermineTopicSuggestionsResponse> => {
  const { programId, contentId } = requestParameters;
  const body = JSON.stringify({ content_id: contentId });
  const response = await request(
    `${bossanovaDomain}/v2/tenants/program:${programId}/topic_suggestions`,
    {
      method: 'POST',
      body,
    }
  );

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  if (response.status === 204) {
    return {};
  }

  throw new Error(`Error requesting topic suggestions`);
};

export type GetDeterminedTopicSuggestionsRequest = {
  programId: number;
  outputId: string;
};

export enum TopicSuggestionsResponseStatus {
  Complete = 'complete',
  Failed = 'failed',
  Pending = 'pending',
}

export type GetDeterminedTopicSuggestionsResponse = {
  status: TopicSuggestionsResponseStatus;
  channelIds: Array<number>;
  message: string;
};

export const getDeterminedTopicSuggestions = async (
  requestParameters: GetDeterminedTopicSuggestionsRequest
): Promise<GetDeterminedTopicSuggestionsResponse> => {
  const { programId, outputId } = requestParameters;
  const response = await request(
    `${bossanovaDomain}/v2/tenants/program:${programId}/topic_suggestions?output_id=${outputId}`
  );

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error getting the determined topic suggestions`);
};
