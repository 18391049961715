import * as React from 'react';
import * as DesignSystem from 'DesignSystem/Components/Modal';
import { useProcessedContentImage } from 'hooks/useContentImage';
import { Button } from 'DesignSystem/Form';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Editor } from './Editor';
import { useSocialDataItemForm } from '../hooks/useSocialDataItemForm';
import { SubmittableForm } from '../../shared/SubmittableForm';
import { CUSTOM } from '../../shared/messages';

export const ModalForm: React.FC<
  { onCancel(): void } & Parameters<typeof useSocialDataItemForm>[0]
> = ({ onCancel, ...props }) => {
  const form = useSocialDataItemForm(props);

  const imageProcessingState = useProcessedContentImage({
    onProcessed: form.setPostProcessedImage,
    image: form.image.value,
    linkType: form.fetcher.link.type,
  });

  const currentlyBackgroundProcessing =
    !!form.image.value.imageId &&
    !!form.image.value.url &&
    imageProcessingState === 'processing';

  return (
    <DesignSystem.FormModal
      onCancel={onCancel}
      entityText="social links"
      actionText="update"
      submitButton={
        <Button
          icon={
            currentlyBackgroundProcessing && <LoadingSpinner size="small" />
          }
          label={
            currentlyBackgroundProcessing
              ? 'Processing Image'
              : 'Update Social Links'
          }
          onClick={form.saveChanges}
          disabled={form.disabled || currentlyBackgroundProcessing}
        />
      }
    >
      <Error error={!form.isLoading ? form.error : undefined} />
      <SubmittableForm onSubmit={form.saveChanges} disabled={form.disabled}>
        <Editor form={form} />
      </SubmittableForm>
    </DesignSystem.FormModal>
  );
};

const Error: React.FC<{ error?: string }> = ({ error }) =>
  !error ? null : (
    <div>
      {CUSTOM[error] || (
        <span>
          There was an error: <strong>{error}</strong>
        </span>
      )}
    </div>
  );
