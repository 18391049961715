import { bossanovaDomain, request } from './api-shared';

export type ChannelContributorsResponseData = {
  data: Array<ChannelContributorData>;
};

export type ChannelContributorData = {
  id: number;
  attributes: ChannelContributor;
};

export type ChannelContributor = {
  contributorId: number;
  channelId: number;
};

type ChannelContibutorsRequestType = {
  programId: number;
};

export const fetchChannelContributors = async (
  props: ChannelContibutorsRequestType
): Promise<ChannelContributorsResponseData> => {
  const url = `${bossanovaDomain}/samba/programs/${props.programId}/users/channel_contributors`;
  const response = await request(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(`Error fetching Topic Managers: ${response.status}`);
};
