export function humanFileSize(bytes = 0): string {
  if (bytes === 0 || bytes === null || bytes === undefined) return '0 Bytes';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / 1024 ** i).toFixed()} ${sizes[i]}`;
}

function padZero(num: number) {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}

export function humanDuration(durationInSeconds = 0): string {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  let result = '';
  if (hours > 0) {
    result = `${hours}:`;
    result += padZero(minutes);
  } else {
    result = `${minutes}`;
  }
  result += `:${padZero(seconds)}`;

  return result;
}
