import React, { useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useProgram } from 'contexts/program';
import { PublisherType } from 'models/library';
import { LoadingScreen } from 'shared/LoadingScreen';
import { useFlashMessage } from 'contexts/flasher';
import { DesignContext } from 'contexts/design';
import { useDesignContext } from 'hooks/design';
import { useTopicFormCtx } from '../../context';
import { DesignerWrapper } from './DesignerWrapper';

export const AboutEditor: React.FC<RouteComponentProps<{ id: number }>> = ({
  id,
}) => {
  const { baseUrl } = useTopicFormCtx();

  const program = useProgram();

  const designContext = useDesignContext({
    programId: program.id,
    id: Number(id),
    redirectOnSave: false,
    publisherType: PublisherType.topicPages,
  });
  const { status, error } = { ...designContext };

  const { setFlashMessage } = useFlashMessage();
  useEffect(() => {
    if (!id) {
      setFlashMessage({
        message: 'Failed to load editor. Try again',
        severity: 'error',
      });
      navigate(baseUrl);
    }
  });
  if (!id) {
    return <LoadingScreen />;
  }

  if (status.isLoading) return <LoadingScreen />;

  if (error && error.length > 0) return <p>Error: {error}</p>;

  return (
    <DesignContext.Provider value={designContext}>
      <DesignerWrapper />
    </DesignContext.Provider>
  );
};
