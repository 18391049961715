import React from 'react';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { useProgram } from 'contexts/program';
import { SearchInput } from 'shared/SearchInput';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { Pills } from 'DesignSystem/Components/Pills';
import { useAudiencesInfiniteQuery } from 'hooks/audience';
import { useDebounce } from 'hooks/useDebounce';
import { Audience } from 'models/audience';
import { Dropdown } from 'components/audience/AudienceSelect/Dropdown';
import styles from './styles.module.css';

type PropsType = {
  value: Array<Audience>;
  onChange: (value: Array<Audience>) => void;
};

export const AudienceSelector: React.FC<PropsType> = ({ value, onChange }) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAudiencesInfiniteQuery({
    programId,
    search: useDebounce(search),
    statuses: 'enabled',
  });

  const selectedIds = value.map((audience) => audience.id || '');

  const audiences = [...data];
  const audienceIds: Array<string> = data.map((item) => item.id || '');
  const uniqueIds = new Set([...audienceIds]);
  value.forEach((audience) => {
    if (audience.id && !uniqueIds.has(audience.id)) {
      audiences.push(audience);
    }
  });

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedAudiences = ids
      .map((id) =>
        audiences.find((audience) => audience?.id?.toString() === id.toString())
      )
      .filter((item) => !!item);
    onChange(newSelectedAudiences as Array<Audience>);
  }

  function dropdown() {
    return (
      <Text.Body bold block>
        <Dropdown
          values={audiences}
          selectedIds={selectedIds}
          onSelectedIdsChange={handleSelectedIdsChange}
          maxHeight={300}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Text.Body>
    );
  }

  return (
    <>
      <Text.Subheading bold block>
        Audiences
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select at least one audience in order to take a bulk action
      </Text.Body>
      <Box margin={[16, 0, 16, 0]}>
        <ClickDropdown
          dropdownRenderProp={dropdown}
          matchReferenceWidth
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearch('');
          }}
          ignoreKeys
        >
          <div>
            <SearchInput
              placeholder="Search audiences"
              value={search || ''}
              onChange={setSearch}
              labelClassName={styles.SearchInput}
            />
          </div>
        </ClickDropdown>
      </Box>

      <Flex start wrap className={styles.PillsContainer}>
        {value.map((audience, i) => (
          <Pills
            key={`audience-${audience.id}`}
            values={[{ id: i }]}
            render={() => <>{audience.title}</>}
            onClose={(t) => {
              const index = t.id;
              const values = [
                ...value.slice(0, index),
                ...value.slice(index + 1),
              ];

              onChange(values);
            }}
            padding={[0, 12, 0, 8]}
          />
        ))}
      </Flex>
    </>
  );
};
