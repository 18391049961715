import React from 'react';

import { Facet } from 'models/facet';
import styles from './facets.module.css';
import { FacetDetails } from './FacetDetails';
import { presentableFacetNames } from './constants';

export const FacetSidebarDisplay: React.FC<{
  displayCount: number;
  displayFacets: Facet[];
  emptyExpression?: boolean;
}> = ({ displayCount, displayFacets, emptyExpression }) => (
  <div className={styles.main}>
    {emptyExpression ? (
      <div className={styles.totalCount}>No audience selected</div>
    ) : (
      <div className={styles.totalCount}>{displayCount} total</div>
    )}

    {displayFacets &&
      displayFacets.map((facet) => (
        <FacetDetails
          emptyExpression={emptyExpression}
          label={presentableFacetNames[facet.facetName]}
          facet={facet}
          totalCount={displayCount}
          key={facet.facetName}
        />
      ))}
  </div>
);
