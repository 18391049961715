import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';

export const toDuration = (
  milliseconds: number
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  const millisecondsInDay = 60 * 60 * 24 * 1000;
  const days = Math.floor(milliseconds / millisecondsInDay);
  const remHoursInMilliseconds = milliseconds % millisecondsInDay;
  const hours = Math.floor(remHoursInMilliseconds / 3600000);
  const remTime = milliseconds % 3600000;
  const minutes = Math.floor(remTime / 60000);

  return {
    days,
    hours,
    minutes,
  };
};

export const generateCompletionString = (
  data: BulkUpdatePermissionsJob
): string => {
  if (!data.processedAt) return '';
  const timeDifference =
    Date.parse(data.processedAt) - Date.parse(data.submittedDatetime);
  const { days, hours, minutes } = toDuration(timeDifference);
  return days
    ? `Processed in ${days}d ${hours}h ${minutes}m`
    : `Processed in ${hours}h ${minutes}m`;
};

export const generateDetailReportString = (
  data: BulkUpdatePermissionsJob
): string => {
  if (!data.totalUsers) return '';

  const { processedUsers, totalUsers, failedUsers } = data;
  let reportString = `${processedUsers} of ${totalUsers} processed`;

  if (failedUsers > 0) reportString += ` - ${failedUsers} failed to update`;

  return reportString;
};

export const generateReportString = (
  data: BulkUpdatePermissionsJob
): string => {
  if (!data) return '';
  const { processedUsers, totalUsers } = data;
  return `${processedUsers || 0} of ${totalUsers} processed - ${0} duplicates`;
};

export const generateStatString = (
  data: BulkUpdatePermissionsJob
): {
  description: string;
  processed: string;
} => {
  let description = '';
  let processed = !data.totalUsers
    ? `0`
    : `${data.processedUsers || 0} of ${data.totalUsers}`;
  switch (data.state) {
    case 'failed':
      description = `Failed`;
      break;
    case 'partial':
      description = `Partial - some users failed to update`;
      break;
    case 'created':
      description = `Created`;
      break;
    case 'processing':
      description =
        data.processedUsers && data.totalUsers
          ? `Processing (${Math.round(
              (data.processedUsers / data.totalUsers) * 100
            )}%)`
          : `Processing`;
      break;
    case 'completed':
      description = 'Completed';
      processed = `${data.totalUsers}`;
      break;
    default:
      description =
        data.processedUsers && data.totalUsers
          ? `Processing (${Math.round(
              (data.processedUsers / data.totalUsers) * 100
            )}%)`
          : 'Processing';
      processed = `NA`;
  }

  return {
    description,
    processed,
  };
};
