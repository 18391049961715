// ie-11 safe utility functions
// IMPORTANT!! these all perform SHALLOW COPIES of objects
// if deep copies are required then perform them manually or use a different utility
export function safeCopyMap<T1, T2>(oldMap: Map<T1, T2>): Map<T1, T2> {
  const newMap = new Map<T1, T2>();
  oldMap.forEach((value, key) => newMap.set(key, value));
  return newMap;
}

export function safeCopySet<T>(oldSet: Set<T>): Set<T> {
  const newSet = new Set<T>();
  oldSet.forEach((value) => newSet.add(value));
  return newSet;
}

export function safeTransformMapValuesToArray<T1, T2>(
  map: Map<T1, T2>
): Array<T2> {
  const newArray = new Array<T2>();
  map.forEach((value) => newArray.push(value));
  return newArray;
}

export function safeTransformSetToArray<T>(set: Set<T>): Array<T> {
  const newArray = new Array<T>();
  set.forEach((value) => newArray.push(value));
  return newArray;
}

export function safeTransformArrayToSet<T>(array: Array<T>): Set<T> {
  const newSet = new Set<T>();
  array.forEach((value) => newSet.add(value));
  return newSet;
}
