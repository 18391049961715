import React from 'react';
import { ListDropdownItem } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { useProgram } from 'contexts/program';
import { useSemiPaginatedPublishers } from 'hooks/insights/useSemiPaginatedQuery';
import { PaginatedListDropdownProvider } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { PaginatedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/PaginatedListDropdown';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { Author } from '../../../../../../../../models/author';
import { usePublishersQuery } from '../../../../../../../../services/api-authors';

export const PublishersListDropdown: React.FC = () => {
  const { id: programId } = useProgram();
  const { defaultValues, debouncedSearchString } = React.useContext(
    FilterDropdownContext
  );

  const defaultIds = (defaultValues
    ? Array.from(defaultValues.values())
    : []
  ).map((i) => parseInt(i, 10));

  const {
    data: initialData,
    isLoading: isInitialDataLoading,
  } = usePublishersQuery({
    programId,
    id: defaultIds,
  });

  const {
    aggregatedData,
    fetchNext,
    isLoading,
    hasMoreToLoad,
    isFetching,
  } = useSemiPaginatedPublishers({
    programId,
    query: debouncedSearchString,
  });

  // note: useSemiPaginatedPublishers() returns a partial list of records
  // the data will be accumulated internal in the PaginatedListDropdownProvider
  const allItems: ListDropdownItem[] = React.useMemo(() => {
    if (aggregatedData === undefined && initialData === undefined) return [];
    // Dedupe
    const mergedDataHash: { [key: string]: Author } = {};

    if (!debouncedSearchString) {
      initialData?.forEach((t) => {
        mergedDataHash[`${t.userId}`] = t;
      });
    }

    aggregatedData?.forEach((t) => {
      mergedDataHash[`${t.userId}`] = t;
    });

    // Back to an array
    const mergedData: Author[] = Object.keys(mergedDataHash).map(
      (k) => mergedDataHash[k]
    );
    return mergedData.map((author) => ({
      label: String(author.defaultDisplayName),
      value: String(author.userId),
      uuid: String(author.userId),
    }));
  }, [aggregatedData, debouncedSearchString, initialData]);

  // fetchNext if more items available
  React.useEffect(() => {
    if (allItems.length === 0) fetchNext();
  }, [allItems, fetchNext]);

  return (
    <PaginatedListDropdownProvider
      allItems={allItems}
      fetchNext={fetchNext}
      isLoadingMoreItems={isLoading || isFetching || isInitialDataLoading}
      hasMoreToLoad={hasMoreToLoad}
      defaultValues={defaultValues}
    >
      <PaginatedListDropdown />
    </PaginatedListDropdownProvider>
  );
};
