import {
  isStringVariableFieldData,
  isBooleanVariableFieldData,
  FieldDefinition,
  FieldData,
  RenderingVariables,
} from 'models/publisher/block';

export const resolveVariables: (args: {
  vals: RenderingVariables;
  fields: FieldDefinition[];
  fieldData: FieldData;
}) => FieldData = ({ fields, fieldData, vals }) => {
  const values: FieldData = {};
  fields.forEach((field) => {
    if (field.type === 'variable') {
      const data = fieldData[field.name];
      if (isStringVariableFieldData(data)) {
        if (vals[data.ref] !== data.value) {
          values[field.name] = {
            ...data,
            value: vals[data.ref] ?? '',
          };
        }
      } else if (isBooleanVariableFieldData(data)) {
        if (vals[data.ref] !== data.value) {
          values[field.name] = {
            ...data,
            value: !!vals[data.ref],
          };
        }
      }
    }
  });
  return values;
};
