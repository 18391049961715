export enum ProcessActions {
  Activate = 'activate',
  Draft = 'draft',
  Promote = 'promote',
  Demote = 'demote',
  Archive = 'archive',
}

export type Channel = 'email' | 'push' | 'assistant';

export const Statuses = ['published', 'draft', 'archived'];
export type Status = typeof Statuses[number];

export type WorkflowTemplate = {
  id: number;
  contentId: number;
  name: string;
  description: string;
  channel: Channel | Channel[];
  status: Status;
  scope: 'global' | 'program';
  multinode: boolean;
  identifier: string;
  previewUrl: string;
  enabledForContributors: boolean;
};
