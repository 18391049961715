import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { SignInConfig } from 'models/signInConfig';
import { Checkbox } from 'shared/Checkbox';
import styles from '../styles.module.css';

type PropsType = {
  config: SignInConfig;
  setConfig: (config: SignInConfig) => void;
};

export const FormatInput: React.FC<PropsType> = ({ config, setConfig }) => {
  return (
    <div className={styles.SsoInput}>
      <Text.Subheading block bold>
        Name ID Format
      </Text.Subheading>
      <div className={styles.Radio}>
        <Checkbox
          checked={config.sso.format === 'email'}
          onSelect={() => {
            setConfig({
              ...config,
              sso: { ...config.sso, format: 'email' },
            });
          }}
          type="radio"
          id="email-format"
        />
        <label htmlFor="email-format" className={styles.RadioLabel}>
          Email
        </label>
      </div>

      <div className={styles.Radio}>
        <Checkbox
          checked={config.sso.format === 'unspecified'}
          onSelect={() => {
            setConfig({
              ...config,
              sso: { ...config.sso, format: 'unspecified' },
            });
          }}
          type="radio"
          id="unspecified-format"
        />
        <label htmlFor="unspecified-format" className={styles.RadioLabel}>
          Unspecified
        </label>
      </div>
    </div>
  );
};
