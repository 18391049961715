import { titleCase } from 'utility/text';

export type DeliveryType = 'optimize' | 'hybrid' | 'override';

export const OPTIMIZE = 'optimize';
export const HYBRID = 'hybrid';
export const OVERRIDE = 'override';

export const DELIVERY_TYPES: DeliveryType[] = [OPTIMIZE, HYBRID, OVERRIDE];

export const isDeliveryType = (
  deliveryType: DeliveryType | unknown
): deliveryType is DeliveryType => {
  const delType = deliveryType as DeliveryType;
  return !!delType && DELIVERY_TYPES.includes(delType);
};

export const getFormattedName = (
  type: DeliveryType,
  retries: number
): string => {
  if (type === OVERRIDE || type === OPTIMIZE) {
    return `"${titleCase(type)}"`;
  }

  return `"${titleCase(type)} ${retries === 0 ? 'without' : 'with'} retries"`;
};
