import React, { useCallback, useState } from 'react';
import { Box, Popover } from 'DesignSystem/Components';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { useDebounce } from 'hooks/useDebounce';
import { useProgram } from 'contexts/program';
import { User } from 'models/user';
import { Body, Caption } from 'DesignSystem/Typography';
import { useInfiniteApiQuery } from 'hooks/common';
import { fetchStudioContactPage } from 'services/api-studio-contacts';
import { useUser } from 'contexts/user';
import { SearchInput } from 'shared/SearchInput';

export const SelectUsers: React.FC<{
  selectedUsers: User[];
  onUserChange: (users: User[]) => void;
}> = ({ selectedUsers, onUserChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { id: programId } = useProgram();
  const currentUser = useUser();

  const {
    data: studioContactsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery('studio_contacts', fetchStudioContactPage, {
    programId,
    query: useDebounce(searchTerm),
    pageSize: 20,
  });

  const recipients = React.useMemo(() => {
    const memo = studioContactsData
      .map(
        ({ userId, displayName, email }) =>
          ({
            id: userId,
            displayName,
            email,
          } as User)
      )
      .filter(({ id }) => id !== currentUser.id);

    const displayName =
      currentUser.displayName ??
      `${currentUser.preferredName || currentUser.firstName} ${
        currentUser.lastName
      }`;

    if (
      !isLoading &&
      searchTerm
        .split(' ')
        .filter((i) => i)
        .some((term) => displayName.toLowerCase().includes(term.toLowerCase()))
    ) {
      memo.unshift({
        id: currentUser.id,
        displayName,
        email: currentUser.email,
      } as User);
    }

    return memo;
  }, [
    currentUser.displayName,
    currentUser.email,
    currentUser.firstName,
    currentUser.id,
    currentUser.lastName,
    currentUser.preferredName,
    isLoading,
    studioContactsData,
    searchTerm,
  ]);

  const findById = (usrs: User[], id: string) =>
    usrs.find(({ id: uId }) => uId === Number(id));

  const rowRenderer = useCallback(
    (usrs: User[]) => (id: string) => {
      const user = findById(usrs, id);
      return user ? (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box margin={[0, 16, 0, 0]}>
            <Box>
              <Body>{user.displayName}</Body>
            </Box>
            {user.email && <Caption>{user.email}</Caption>}
          </Box>
        </Box>
      ) : null;
    },
    []
  );

  const onChange = (id: string) => {
    const exitedUser = findById(selectedUsers, id);
    if (exitedUser) {
      onUserChange(
        selectedUsers.filter(({ id: uId }) => uId !== exitedUser.id)
      );
      return;
    }

    const fetchedUser = findById(recipients, id);
    if (fetchedUser) {
      onUserChange([...selectedUsers, fetchedUser]);
    }
  };

  const selectUsersDropdown = (
    <Box absolute minWidth={616} margin={[-8, 0, 0, 0]}>
      <Popover padding={4}>
        <InfiniteSelect
          rowIds={recipients.map(({ id }) => String(id))}
          rowRenderProp={rowRenderer(recipients)}
          maxHeight={400}
          itemHeight={50}
          selectedIds={selectedUsers.map(({ id }) => String(id))}
          onSelectedIdsChange={() => {}}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          onHandleChange={onChange}
          hoverFocus={false}
          clearDisabled
          searchEnabled={false}
          existenceGranted={false}
          noShadow
        />
      </Popover>
    </Box>
  );

  return (
    <>
      <ClickDropdown
        dropdownRenderProp={selectUsersDropdown}
        matchReferenceWidth
        autoUpdate
        referencePress={false}
        ignoreKeys
        onClose={() => {
          setSearchTerm('');
        }}
      >
        <div>
          <SearchInput
            placeholder="Search Recipients"
            value={searchTerm}
            onChange={setSearchTerm}
          />
        </div>
      </ClickDropdown>
    </>
  );
};
