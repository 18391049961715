import React from 'react';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { DateRangeInputWrapper } from './DateRangeInputWrapper';
import { ReportContext } from '../../../contexts/ReportContext';

export const DateRangeDropdown: React.FC = () => {
  const { filtersStateAction } = React.useContext(FiltersStateContext);
  const { setShowReset } = React.useContext(ReportContext);

  const updateFilter = React.useCallback(
    (filter, value) =>
      filtersStateAction({
        action: 'setSingleFilterValue',
        filter,
        value,
      }),
    [filtersStateAction]
  );

  return (
    <DateRangeInputWrapper
      setShowReset={setShowReset}
      updateFilter={updateFilter}
      shouldUseTimezone
    />
  );
};
