import * as React from 'react';
import { Video } from 'models/video';
import { Upload } from './Upload';
import { External } from './External';
import { useVideoSourceMenu, VideoSourceMenu } from '../useVideoSourceMenu';

export const VideoUploader: React.FC<{
  video?: Video;
  onChange: (video: Video) => void;
  source?: VideoSourceMenu['source'];
  disableSave?: () => void;
  enableSave?: () => void;
}> = ({ video, onChange, source, disableSave, enableSave }) => {
  const sourceMenu = useVideoSourceMenu(source);
  return (
    <>
      {sourceMenu.source === 'upload' && (
        <Upload
          sourceMenu={sourceMenu}
          onChange={onChange}
          video={video?.sourceType === 'admin_created' ? video : undefined}
          disableSave={disableSave}
          enableSave={enableSave}
        />
      )}
      {sourceMenu.source === 'external' && (
        <External
          sourceMenu={sourceMenu}
          video={video?.sourceType !== 'admin_created' ? video : undefined}
          onChange={onChange}
          disableSave={disableSave}
          enableSave={enableSave}
        />
      )}
    </>
  );
};
