/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import cx from 'classnames';
import { Background, Border, Color } from '../Typography';

export const Box: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    BoxLayoutProps &
    BoxStyleProps
> = ({
  children,
  className,
  color,
  background,
  border,
  relative,
  absolute,
  padding,
  margin,
  center,
  top,
  left,
  right,
  bottom,
  width,
  height,
  minHeight,
  minWidth,
  maxHeight,
  maxWidth,
  radius,
  dataTest,
  style = {},
  ...divProps
}) => {
  const colors = Array.isArray(color) ? color : [color];
  const backgrounds = Array.isArray(background) ? background : [background];
  const borders = Array.isArray(border) ? border : [border];
  const position =
    (relative && 'relative') || (absolute && 'absolute') || undefined;
  return (
    <div
      data-test={dataTest}
      data-testid={dataTest}
      className={cx([...colors, ...borders, ...backgrounds], {
        [`${className}`]: !!className,
      })}
      style={{
        ...style,
        position,
        left,
        top,
        right,
        bottom,
        textAlign: center ? 'center' : undefined,
        padding: toPx(padding),
        margin: toPx(margin),
        width: toPx(width),
        height: toPx(height),
        minWidth: toPx(minWidth),
        minHeight: toPx(minHeight),
        maxWidth: toPx(maxWidth),
        maxHeight: toPx(maxHeight),
        borderRadius: toPx(radius),
      }}
      {...divProps}
    >
      {children}
    </div>
  );
};

type BoxLayoutProps = {
  center?: boolean;
  absolute?: boolean;
  relative?: boolean;
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  width?: number | string;
  height?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
  padding?: number | number[] | string | string[];
  margin?: number | string | Array<number | string>;
  radius?: number | number[] | string | string[];
  dataTest?: string;
};

type BoxStyleProps = {
  color?: Background | Color | Array<Background | Color>;
  border?: Border | Border[];
  background?: Background;
};

function toPx(n?: number | string | Array<number | string>) {
  if (!n && n !== 0) return undefined;
  const ns = Array.isArray(n) ? n : [n];
  return ns.map((m) => (typeof m === 'string' ? m : `${m}px`)).join(' ');
}
