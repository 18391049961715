import React from 'react';
import Select, { ValueType } from 'react-select';
import { Alert, AlertType } from 'DesignSystem/Components';
import {
  FontData,
  FontStyleOption,
  FontStyleOptions,
  FontWeightOption,
  FontWeightOptions,
} from 'models/font';
import { FontStylesheet } from 'models/library';
import { MAIcon } from 'shared/MAIcon';
import { FontUploader } from './FontUploader';
import styles from './font-editor.module.css';

type FontProps = {
  font: FontData;
  onDelete: () => void;
  onUpdate: (
    field: string,
    value: ValueType<{ value: number | string; label: string }>
  ) => void;
  onUpload: (font: FontData) => void;
  setIsWorking: (identifier: string, value: boolean) => void;
  stylesheetFontFamily: FontStylesheet['asset']['fontFamily'];
};

const DEFAULT_FONT_WEIGHT: FontWeightOption = {
  value: 400,
  label: 'Normal (400)',
};
const DEFAULT_FONT_STYLE: FontStyleOption = {
  value: 'normal',
  label: 'Regular',
};
const FONT_UNRELATED_ERROR_MESSAGE =
  'This font does not match this font family';
const FONT_UNRELATED_ALERT_MESSAGE =
  "This font doesn't match the font family and must be removed to save.";

export const Font: React.FC<FontProps> = ({
  font,
  onDelete,
  onUpdate,
  onUpload,
  setIsWorking,
  stylesheetFontFamily,
}) => {
  const fontWeight = FontWeightOptions.filter((o) => o.value === font.weight);

  const fontStyle = FontStyleOptions.filter((o) => o.value === font.style);

  const isFontUnrelated =
    !!font.family &&
    !!stylesheetFontFamily &&
    font.family !== stylesheetFontFamily;

  return (
    <>
      {isFontUnrelated && (
        <Alert
          type={AlertType.error}
          message={FONT_UNRELATED_ALERT_MESSAGE}
          bgColor="red"
          icon={<MAIcon name="warning" />}
          enableIcon
        />
      )}
      <div className={styles.variants}>
        <Select
          value={fontWeight}
          onChange={(value) => onUpdate('weight', value)}
          options={FontWeightOptions}
          defaultValue={DEFAULT_FONT_WEIGHT}
        />
        <Select
          value={fontStyle}
          onChange={(value) => onUpdate('style', value)}
          options={FontStyleOptions}
          defaultValue={DEFAULT_FONT_STYLE}
        />
        <FontUploader
          setIsWorking={setIsWorking}
          deleteVariant={onDelete}
          data={font}
          onChange={onUpload}
          errorMessage={
            isFontUnrelated ? FONT_UNRELATED_ERROR_MESSAGE : undefined
          }
        />
      </div>
    </>
  );
};
