import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

// we disable a default feature of react-query that reloads all cached queries when the window focuses
// it is possible to enable this feature on a query-specific basis as desired
// more info https://react-query.tanstack.com/guides/window-focus-refetching
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const ApiProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
