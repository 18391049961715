import * as React from 'react';
import {
  BASE_STYLING,
  ColorField,
  FontField,
  FontOption,
  SizeField,
} from 'models/publisher/style';
import { StyleData, Styling } from 'models/publisher/block';

const contextPrototype: {
  style: Styling;
  blockStyle: StyleData;
  changeStyle: (style: Styling) => void;
  changeBlockStyle: (styleData: StyleData) => void;
  colorChanger: (field: ColorField) => (color: string) => void;
  fontChanger: (field: FontField) => (font: string) => void;
  fontOptions: FontOption[];
  sizeChanger: (field: SizeField) => (size: number) => void;
  revertToGlobalStyle: () => void;
  displayValue: (
    key: keyof Styling,
    field: ColorField | FontField | SizeField
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
} = {
  style: BASE_STYLING,
  blockStyle: {},
  changeStyle: () => {},
  changeBlockStyle: () => {},
  colorChanger: () => () => {},
  sizeChanger: () => () => {},
  fontChanger: () => () => {},
  revertToGlobalStyle: () => {},
  displayValue: () => '',
  fontOptions: [],
};

export const StyleEditorContext = React.createContext(contextPrototype);

export const useStyleEditor = (): typeof contextPrototype => {
  const context = React.useContext(StyleEditorContext);
  if (context === undefined) {
    throw new Error('Style Editor context hooks require a provider');
  }
  return context;
};
