import { Audience } from 'models/audience';
import { AliasData, fetchByIds } from 'services/api-author-alias';
import { EmailAlias } from 'models/email-alias';
import { Template } from 'models/library';
import { Topic } from 'models/topic';
import React from 'react';
import { TemplateContainerType } from 'models/template';

import { fetchAudience } from 'services/api-audiences';
import { fetchById } from 'services/api-email-alias';
import { fetchTopics } from 'services/api-topics';
import { useProgram } from 'contexts/program';
import { getTemplate } from 'services/api-library';
import { mapDataToAudience } from 'hooks/audience';
import { Scope, ScopeType } from './scope';

interface ScopeData {
  audiences: Audience[];
  authorAliases: AliasData[];
  emailAliases: EmailAlias[];
  templates: Template[];
  topics: Topic[];
}

type FiltersValueType = Audience | AliasData | EmailAlias | Template | Topic;

export const useSelectionsData = (
  selectedScopeIds: Scope,
  currentData: ScopeData
): ScopeData => {
  const { id: programId } = useProgram();
  const [selectedData, setSelectedData] = React.useState<ScopeData>({
    audiences: [],
    authorAliases: [],
    emailAliases: [],
    templates: [],
    topics: [],
  });

  // Load selectedData, to preserve the selected state
  React.useEffect(() => {
    (async () => {
      const newData = await (Object.keys(
        selectedScopeIds
      ) as ScopeType[]).reduce(
        async (
          accPromise: Promise<ScopeData>,
          type: ScopeType
        ): Promise<ScopeData> => {
          const acc = await accPromise;
          const values = await Promise.all(
            selectedScopeIds[type].map(async (id: string) => {
              return (
                findInArray(id, selectedData[type] ?? []) ||
                findInArray(id, currentData[type] ?? []) ||
                // eslint-disable-next-line no-return-await
                (await fetchPermissionData(type, programId, id))
              );
            })
          );
          return { ...acc, [type]: values.filter(Boolean) };
        },
        {} as Promise<ScopeData>
      );
      setSelectedData(newData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedScopeIds)]);

  // union data to preserve selected ones on top even if filter values change due search or scroll
  const unionData = React.useMemo(() => {
    return Object.keys(selectedData).reduce(
      (acc: ScopeData, scopeType: string) => {
        return {
          ...acc,
          [scopeType]: unionArraysByObjectIds(
            selectedData[scopeType as ScopeType],
            currentData[scopeType as ScopeType]
          ),
        };
      },
      {} as ScopeData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, currentData]);

  return unionData;
};

const unionArraysByObjectIds = (
  arr1: FiltersValueType[],
  arr2: FiltersValueType[]
): FiltersValueType[] => {
  const idMap = new Map<string, FiltersValueType>();
  const getMapKey = (obj: FiltersValueType) => String(obj.id);

  arr1.forEach((obj) => {
    idMap.set(getMapKey(obj), obj);
  });
  arr2.forEach((obj) => {
    idMap.set(getMapKey(obj), obj);
  });
  return Array.from(idMap.values());
};

const fetchPermissionData = async (
  type: ScopeType,
  programId: number,
  id: string
): Promise<FiltersValueType> => {
  const actions: Record<ScopeType, () => Promise<FiltersValueType>> = {
    audiences: async () => {
      const response = await fetchAudience(programId, id);
      return mapDataToAudience(response);
    },
    authorAliases: async () => {
      const { data } = await fetchByIds([Number(id)], programId);
      return (data[0] as unknown) as AliasData;
    },
    emailAliases: async () => {
      const data = await fetchById(programId, id);
      return { id: data.id, ...data.attributes };
    },
    templates: async () => {
      const { data } = await getTemplate({
        programId,
        templateId: Number(id),
        containerType: TemplateContainerType.Template,
      });
      return { id: data.id, ...data.attributes } as Template;
    },
    topics: async () => {
      const data = await fetchTopics({ programId, ids: [Number(id)] });
      return data.data[0];
    },
  };

  return actions[type]();
};

const findInArray = (id: string, arr: FiltersValueType[]) => {
  return arr.find((obj: FiltersValueType) => obj.id === id);
};
