import React, { useState, useRef } from 'react';
import { User } from 'models/user';
import { useProgram } from 'contexts/program';
import { useUserQuery } from 'hooks/user';
import { RouteComponentProps } from '@reach/router';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import equal from 'fast-deep-equal/react';
import { Topic } from 'models/topic';
import { useUserIdentityQuery } from 'hooks/identities';
import { Role } from 'models/role';
import { IdentityScope } from 'models/identity';
import { UserMainView } from './Main';
import { Sidebar } from '../Sidebar';
import { UserViewFooter } from './footer';

export const UserProfile: React.FC<RouteComponentProps<{
  id?: string;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );
  const { isLoading, errorMessage, data } = useUserQuery(programId, Number(id));
  const {
    isLoading: isIdentityLoading,
    data: userIdentity,
  } = useUserIdentityQuery(programId, `${id}`);

  const user = data as User;
  const role = useRef('');

  const [isDataChanged, setIsDataChanged] = useState(false);
  const [userData, setUserData] = useState(user);
  const [userTopicIds, setUserTopicIds] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
  const [identityScopes, setIdentityScopes] = useState<IdentityScope[]>([]);
  const [initialIdentityScopes, setInitialIdentityScopes] = useState<
    IdentityScope[]
  >([]);
  const [permissionsChanged, setPermissionChanged] = useState(false);
  React.useEffect(() => {
    setIdentityScopes(userIdentity?.scopes || []);
    setInitialIdentityScopes(userIdentity?.scopes || []);
  }, [userIdentity?.scopes]);

  React.useEffect(() => {
    if (!user) return;
    if (showPermissionsUI?.value) {
      role.current = userIdentity?.roles[0]?.name || 'member';
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      role.current = user.role;
    }

    setUserData(() => ({
      ...user,
      role: role.current as Role,
    }));
    setUserTopicIds((uTopicIds: string[]) => {
      if (!user || !user.contributorChannels) return uTopicIds;
      return user.contributorChannels?.map((topic) => topic.id);
    });
  }, [data, user, showPermissionsUI, userIdentity]);

  React.useEffect(() => {
    if (!userData || !userIdentity) return;
    const identityChanged = !equal(identityScopes, initialIdentityScopes);
    const roleChanged = userData.role !== userIdentity.roles[0]?.name;
    setPermissionChanged(roleChanged || identityChanged);
  }, [identityScopes, initialIdentityScopes, userData, userIdentity]);

  React.useEffect(() => {
    // Checks if any state differs from the original state
    // If any one( of the state variables are changed, the user can save
    // Logic so the statment evaluates to true is any of the data is different (is not equal)
    // After user fills in empty federatedIdentifier, data changes from null to "" so further logic was needed to cover this case
    if (user && userData) {
      if (!valid()) {
        setIsDataChanged(false);
        return;
      }
      const federatedIdentifierChanged =
        (user.federatedIdentifier || '') !==
        (userData.federatedIdentifier || '').trim();
      const emailChanged = (user.email || '') !== (userData.email || '').trim();
      const usernameChanged =
        (user.username || '') !== (userData.username || '').trim();
      const restChanged = !equal(
        { ...userData, federatedIdentifier: '', email: '', username: '' },
        {
          ...user,
          role: role.current,
          federatedIdentifier: '',
          email: '',
          username: '',
        }
      );
      setIsDataChanged(
        permissionsChanged ||
          federatedIdentifierChanged ||
          emailChanged ||
          usernameChanged ||
          restChanged
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, permissionsChanged]);

  const valid = (): boolean => {
    return (
      !!userData.firstName?.trim() &&
      !!userData.lastName?.trim() &&
      (!!userData.email?.trim() || !!userData.federatedIdentifier?.trim())
    );
  };

  if (isLoading || isIdentityLoading) {
    return <> Loading... </>;
  }

  if (errorMessage) {
    return <>Error: {errorMessage || 'An unexpected error occurred'}</>;
  }

  const convertTopicToContributorTopicType = (topic: Topic) => {
    return {
      id: topic.id.toString(),
      name: topic.name,
      default: topic.default,
      hidden: topic.archived,
      contributor_ids: topic.contributorIds,
      description: topic.description,
      criterion_v2: topic.criterionV2,
    };
  };

  const filterSelections = (
    selections: Topic[],
    topicsById: { [key: string]: Topic }
  ) => {
    const selectionsToUpdate: { [key: string]: { toRemove: boolean } } = {};
    userTopicIds.forEach((topicId: string) => {
      selectionsToUpdate[topicId] = { toRemove: true };
    });
    selections.forEach((selection) => {
      if (selectionsToUpdate[selection.id]) {
        delete selectionsToUpdate[selection.id];
        return;
      }

      selectionsToUpdate[selection.id] = { toRemove: false };
    });

    const userId = Number(user.id);
    return Object.keys(selectionsToUpdate).map((topicId) => {
      const { contributorIds } = topicsById[topicId];
      if (selectionsToUpdate[topicId].toRemove) {
        return {
          ...topicsById[topicId],
          contributorIds: contributorIds.filter((cid) => cid !== userId),
        };
      }

      return {
        ...topicsById[topicId],
        contributorIds: [...contributorIds, userId],
      };
    });
  };

  const handleDataChange = (field: string, value: string) => {
    setUserData(
      () =>
        ({
          ...userData,
          [field]: value,
        } as User)
    );
  };

  const handleContributorTopicChange = (
    selections: Topic[],
    topicsById: { [key: string]: Topic }
  ) => {
    const filteredSelections = filterSelections(selections, topicsById);
    setSelectedTopics(() => filteredSelections);
    setUserData(() => ({
      ...userData,
      contributorChannels: selections.map(convertTopicToContributorTopicType),
    }));
  };

  // const canPerformAction = isDataChanged && !!user.firstName?.trim() &&
  //   !!data.lastName?.trim() &&
  //   (!!data.email?.trim() || !!user.federatedIdentifier?.trim());

  const header = (
    <>
      <h1 className="page-header">User</h1>
    </>
  );

  const main = (
    <UserMainView
      data={userData || user}
      identityScopes={identityScopes}
      initialIdentityScopes={initialIdentityScopes}
      onDataChange={handleDataChange}
      onIdentityScopesChange={setIdentityScopes}
      onTopicChange={handleContributorTopicChange}
    />
  );
  const sidebar = <Sidebar data={userData} onDataChange={handleDataChange} />;
  const footer = (
    <UserViewFooter
      data={userData || user}
      pristineUserData={user}
      selectedTopics={selectedTopics}
      identityScopes={identityScopes}
      canPerformAction={isDataChanged}
      permissionsChanged={permissionsChanged}
    />
  );

  return (
    <MainEditor header={header} main={main} sidebar={sidebar} footer={footer} />
  );
};
