import React from 'react';
import { Button } from 'DesignSystem/Form';
import { GoTo2, SaveSuccess } from 'shared/icons';
import { Heading } from 'DesignSystem/Typography';
import { usePermissions } from 'contexts/permissions';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { Status } from 'hooks/content/useCampaignStatus';
import { Duration } from 'luxon';
import { titleCase } from 'utility/text';

export const SimplePrediction: React.FC<{
  campaignStatus?: Status;
  timeLeft?: Duration;
  showFullPrediction: () => void;
  reportLinkUrl: string;
}> = ({ campaignStatus, timeLeft, showFullPrediction, reportLinkUrl }) => {
  const {
    permissions: { insightsAccess },
  } = usePermissions();

  return (
    <Flex center style={{ minHeight: '100%' }}>
      <Flex column>
        <aside>
          {campaignStatus === 'completed' && (
            <Box center>
              <Box margin={[0, 0, 18, 0]}>
                <SaveSuccess />
              </Box>
              Campaign delivery complete
            </Box>
          )}
          {campaignStatus === 'started' && timeLeft && (
            <Box center>
              <Heading block>
                <strong>
                  {titleCase(
                    timeLeft.shiftTo('days').mapUnits(Math.round).toHuman()
                  )}{' '}
                  Left
                </strong>
              </Heading>
              Campaign delivery in progress
            </Box>
          )}

          <Box width={274}>
            {insightsAccess && (
              <Box margin={[48, 0, 8, 0]}>
                <Button
                  block
                  openInNewTab
                  href={reportLinkUrl}
                  label={
                    <>
                      <GoTo2 /> View Delivery Report
                    </>
                  }
                />
              </Box>
            )}
            {campaignStatus === 'started' && (
              <Box>
                <Button
                  secondary
                  block
                  onClick={showFullPrediction}
                  label="View Remaining Prediction"
                />
              </Box>
            )}
          </Box>
        </aside>
      </Flex>
    </Flex>
  );
};
