import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { LoadingScreen } from 'shared/LoadingScreen';
import qs from 'qs';

// Relay for IDP-initiated login. This path is configured as the Default Relay State on the external
// IDP. We get redirected here after the user starts on the IDP and then logs into IAM. To ensure
// the token is issued correctly, we must restart the login process as if it had been initiated from
// our application; this should be transparent to the user as they already have an IAM session. We
// restart by redirecting to the root path with the provided program_id.

export const Relay: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    window.location.href = `/${params.program_id}`;
  });

  return <LoadingScreen />;
};
