import * as React from 'react';
import { useCallback } from 'react';
import { useFileUpload } from 'hooks/useFileUpload';
import { ButtonType } from 'shared/Button';
import { Button } from './Button';

type SharedPropsType = {
  shouldClear?: boolean | number;
  accept: string;
  aspectRatio?: number;
  id?: string;
} & Omit<ButtonType, 'onClick'>;

export type FileUploadButtonPropsType = {
  onFileSelect: (file: File) => void;
} & SharedPropsType;

export type MultiFileUploadButtonPropsType = {
  onFileSelect: (file: File[]) => void;
  multiple?: boolean;
} & SharedPropsType;

export const MultiFileUploadButton: React.FC<MultiFileUploadButtonPropsType> = (
  props
) => {
  const {
    shouldClear,
    onFileSelect,
    accept,
    children,
    // ButtonType
    disabled,
    type,
    compact,
    iconType,
    iconName,
    aspectRatio,
    className,
    id,
    multiple = true,
  } = props;

  const {
    setFileUploadRef,
    handleButtonClick,
    handleFileChange,
  } = useFileUpload({ shouldClear, aspectRatio, onFileSelect });

  return (
    <>
      <Button
        id={id}
        onClick={handleButtonClick}
        disabled={disabled}
        type={type}
        compact={compact}
        iconType={iconType}
        iconName={iconName}
        className={className}
      >
        {children}
      </Button>
      <input
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        ref={setFileUploadRef}
        onChange={handleFileChange}
        multiple={multiple}
      />
    </>
  );
};

export const FileUploadButton: React.FC<FileUploadButtonPropsType> = ({
  onFileSelect,
  children,
  ...props
}) => {
  const handleMultipleFileChange = useCallback(
    (files: File[]) => {
      if (files[0]) onFileSelect(files[0]);
    },
    [onFileSelect]
  );

  /* eslint-disable  react/jsx-props-no-spreading */
  return (
    <MultiFileUploadButton
      {...props}
      multiple={false}
      onFileSelect={handleMultipleFileChange}
    >
      {children}
    </MultiFileUploadButton>
  );
  /* eslint-enable  react/jsx-props-no-spreading */
};
