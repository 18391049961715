import React from 'react';
import { Icon } from '@socialchorus/shared-ui-components';
import { Box } from '../../../../../../DesignSystem/Components';
import styles from './readonly-communication-config.module.css';
import { Subheading } from '../../../../../../DesignSystem/Typography';
import { DeliveryChannel } from '../../../../../../models/journeys/journey';
import { splitWords, titleCase } from '../../../../../../utility/text';

const channelTitle = (channel: DeliveryChannel): string =>
  titleCase(splitWords(channel.name));

const channelIconName = (channel: DeliveryChannel): string => {
  const iconNames = {
    email: 'mail',
    notification_center: 'notifications',
    push: 'phone_iphone',
  };

  return iconNames[channel.name];
};

const Section: React.FC<{
  title: string;
}> = ({ title, children }) => {
  if (React.Children.toArray(children).length === 0) return null;

  return (
    <Box className={styles.configSection}>
      <Box margin={[16, 0, 16, 0]}>
        <Subheading semibold>{title}</Subheading>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

const ChannelSettingsSection: React.FC<{
  channel: DeliveryChannel;
}> = ({ channel, children }) => {
  return (
    <Box
      data-testid={`readonly-communication-config-${channel.name}-settings`}
      margin={[0, 0, 24, 0]}
    >
      <Box className="flex gap-2" padding={[8, 0, 8, 16]}>
        <Icon size={24}>{channelIconName(channel)}</Icon>
        {channelTitle(channel)}
      </Box>
      <Box margin={[16, 0, 0, 24]}>{children}</Box>
    </Box>
  );
};

export { Section, ChannelSettingsSection, channelTitle, channelIconName };
