import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ConfirmModal } from 'DesignSystem/Components';
import { ChevronDown } from 'shared/icons';
import { HoverIconMenu, ItemType } from 'shared/hover-dropdown/HoverIconMenu';
import { StatusColor } from 'shared/NavigationBars/utils';
import statusStyles from 'shared/NavigationBars/navigation-bars.module.css';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Journey, JourneyMode } from 'models/journeys/journey';
import {
  useJourneyCreateDraftAction,
  useJourneyStopAction,
} from 'hooks/journeys/useJourneyActions';
import { useProgram } from 'contexts/program';
import { useJourneyState } from 'contexts/journeys/journey';
import classNames from 'classnames';
import { Text } from 'DesignSystem/Typography';
import { useCancelJourneyProcessing } from 'hooks/journeys/journeys';
import { asserts } from 'utility/asserts';
import { useToggle } from 'hooks/useToggle';
import styles from './journey-mode-switcher.module.css';
import {
  CloudStatus,
  useJourneyPersistenceStatus,
} from '../JourneyCanvasHeader/useJourneyPersistenceStatus';
import JourneyDangerActionModal, {
  useJourneyActionModal,
} from '../JourneyModal/JourneyDangerActionModal';

type JourneyStatusTagMenuProps = {
  journey: Journey;
  mode: JourneyMode;
  updateJourney: (journey: Journey) => void;
};

const JourneyStatusTagMenu: React.FC<JourneyStatusTagMenuProps> = ({
  journey,
  mode,
}) => {
  const [
    journeyActionModal,
    setOpenedModal,
    actionModal,
  ] = useJourneyActionModal(journey);

  const {
    value: showUnsavedChangesModal,
    toggle: toggleUnsavedChangesModal,
  } = useToggle();

  const navigate = useNavigate();

  const { id: programId } = useProgram();
  const { draftCreatingProcess, setDraftCreatingProcess } = useJourneyState();

  const navigateToLive = () =>
    navigate(`/${programId}/app/journeys/${journey.id}`);

  const persistenceStatus = useJourneyPersistenceStatus();

  const {
    call: createDraft,
    isLoading: isDraftCreating,
  } = useJourneyCreateDraftAction(journey);

  useEffect(() => {
    setDraftCreatingProcess(isDraftCreating);
  }, [setDraftCreatingProcess, isDraftCreating]);

  const dismissModal = () => {
    setOpenedModal(null);
  };

  const { isLoading: isStoppingJourney } = useJourneyStopAction(
    journey,
    () => {
      dismissModal();
      navigate(`/${programId}/app/journeys`);
    },
    dismissModal
  );

  const {
    cancelJourneyProcessing,
    status: cancelJourneyProcessingStatus,
  } = useCancelJourneyProcessing();

  const isCancellingJourneyProcessing =
    cancelJourneyProcessingStatus === 'loading';

  const cancelPublishingItem: ItemType = {
    title: isCancellingJourneyProcessing ? 'Cancelling...' : 'Cancel Publish',
    onClick: () => {
      asserts(journey.id !== undefined, 'Journey ID must be defined');
      cancelJourneyProcessing(journey.id);
    },
    disabled: isCancellingJourneyProcessing,
  };

  const openActiveAction =
    persistenceStatus.cloudStatus === CloudStatus.UpToDate
      ? { href: `/${programId}/app/journeys/${journey.id}` }
      : { onClick: () => toggleUnsavedChangesModal() };

  // Only when the journey is in edit mode and has a live graph
  const openActive: ItemType = {
    title: 'Open Active',
    ...openActiveAction,
  };

  // Only when the journey is in view mode
  const stopJourneyItem: ItemType = {
    title: isStoppingJourney ? 'Stopping...' : 'Stop Journey...',
    danger: true,
    onClick: () => setOpenedModal('stop'),
  };

  const pauseJourneyItem: ItemType = {
    title: isStoppingJourney ? 'Pausing...' : 'Pause Journey',
    danger: false,
    onClick: () => setOpenedModal('pause'),
  };

  const resumeJourneyItem: ItemType = {
    title: isStoppingJourney ? 'Unpausing...' : 'Resume Journey',
    danger: false,
    onClick: () => setOpenedModal('resume'),
  };

  // Only when the journey is in view mode
  const deleteJourneyDraftGraph: ItemType = {
    title: isStoppingJourney ? 'Deleting...' : 'Delete Draft...',
    danger: true,
    onClick: () => setOpenedModal('delete-draft'),
  };

  // Only when the journey is in view mode and has no draft graph
  const createDraftItem: ItemType = {
    title: draftCreatingProcess ? 'Creating...' : 'Create Draft',
    onClick: createDraft,
    disabled: draftCreatingProcess,
  };

  // Only when the journey is in view mode and has a draft graph
  const openDraft: ItemType = {
    title: 'Open Draft',
    href: `/${programId}/app/journeys/${journey.id}/edit`,
  };

  // Only when the journey is in edit mode and has a draft graph and live graph
  // TODO: Implement delete draft
  // const deleteDraft: ItemType = {
  //   title: 'Delete Draft...',
  //   danger: true,
  //   onClick: () => {

  //   },
  // };

  const actionItems = {
    openActive,
    stopJourneyItem,
    pauseJourneyItem,
    resumeJourneyItem,
    deleteJourneyDraftGraph,
    createDraftItem,
    openDraft,
    cancelPublishingItem,
  };

  const isLoading = draftCreatingProcess || isStoppingJourney;
  const isJourneyProcessing = journey.state === 'processing';

  const menuItems = buildStatusTagItems(journey, mode, actionItems, {
    isWorking: isLoading,
    isProcessing: isJourneyProcessing,
  });

  let status: string;
  let statusColor: StatusColor;
  if (journey.state === 'archived') {
    status = 'Archived';
    statusColor = StatusColor.archived;
  } else if (isJourneyProcessing) {
    status = 'Publishing';
    statusColor = StatusColor.publishing;
  } else if (mode === JourneyMode.Edit) {
    status = 'Draft';
    statusColor = StatusColor.draft;
  } else if (journey.state === 'paused') {
    status = 'Paused';
    statusColor = StatusColor.paused;
  } else {
    status = 'Active';
    statusColor = StatusColor.active;
  }

  const statusText = (
    <Text
      className={{
        gray90: true,
      }}
    >
      {status}
    </Text>
  );

  return (
    <>
      {showUnsavedChangesModal && (
        <ConfirmModal
          title="Unsaved changes"
          confirmLabel="Proceed"
          onConfirm={navigateToLive}
          onCancel={toggleUnsavedChangesModal}
        >
          This draft has unsaved changes. Are you sure that you want to exit?
        </ConfirmModal>
      )}

      {journeyActionModal !== null && (
        <JourneyDangerActionModal
          title={actionModal.title}
          confirmLabel={actionModal.confirmLabel}
          description={actionModal.description}
          isLoading={actionModal.isLoading}
          onConfirm={actionModal.onConfirm}
          onCancel={actionModal.onCancel}
        />
      )}

      <div className={styles.statusTagContainer}>
        {menuItems.length > 0 ? (
          <HoverIconMenu
            menuItems={menuItems}
            dropdownClassName="dropdown-align-right"
            openDelay="click"
            ariaLabel={status}
          >
            <StatusTag hoverable color={statusColor} after={<ChevronDown />}>
              {statusText}
            </StatusTag>
          </HoverIconMenu>
        ) : (
          <StatusTag color={statusColor}>
            {isLoading ? <LoadingSpinner size="small" /> : statusText}
          </StatusTag>
        )}
        {isJourneyProcessing && <LoadingSpinner size="small" color="blue" />}
      </div>
    </>
  );
};

type StatusTagProps = {
  children: React.ReactNode;
  after?: React.ReactNode;
  color?: string;
  hoverable?: boolean;
};
function StatusTag({
  children,
  after,
  color,
  hoverable = false,
}: StatusTagProps) {
  const statusTagClassNames = classNames(
    statusStyles.statusTag,
    styles.statusTag,
    hoverable && styles.hoverable
  );
  return (
    <Flex className={statusTagClassNames} style={{ backgroundColor: color }}>
      {children}
      {after}
    </Flex>
  );
}

export default JourneyStatusTagMenu;

type StatusTagActions = {
  openActive: ItemType;
  stopJourneyItem: ItemType;
  pauseJourneyItem: ItemType;
  resumeJourneyItem: ItemType;
  deleteJourneyDraftGraph: ItemType;
  createDraftItem: ItemType;
  openDraft: ItemType;
  cancelPublishingItem: ItemType;
};

function buildStatusTagItems(
  journey: Journey,
  mode: JourneyMode,
  items: StatusTagActions,
  ctx: { isWorking: boolean; isProcessing: boolean }
): ItemType[] {
  const {
    openActive,
    stopJourneyItem,
    pauseJourneyItem,
    resumeJourneyItem,
    deleteJourneyDraftGraph,
    createDraftItem,
    openDraft,
    cancelPublishingItem,
  } = items;

  if (ctx.isProcessing) {
    return [cancelPublishingItem];
  }

  if (ctx.isWorking) {
    return [];
  }

  if (mode === JourneyMode.View && journey.state === 'archived') {
    return [];
  }

  const menuItems = [];
  switch (mode) {
    /**
     * Edit mode
     */
    case JourneyMode.Edit: {
      if (journey.liveGraph) {
        menuItems.push(openActive);

        if (journey.draftGraph) {
          menuItems.push(deleteJourneyDraftGraph);
        }
      }
      return menuItems;
    }

    /**
     * View mode
     */
    case JourneyMode.View: {
      menuItems.push(stopJourneyItem);
      if (journey.state === 'paused') {
        menuItems.push(resumeJourneyItem);
      }

      if (journey.draftGraph) {
        menuItems.push(openDraft);
        if (journey.state === 'active') {
          menuItems.push(pauseJourneyItem);
        }
      } else {
        if (journey.state === 'active') {
          menuItems.push(pauseJourneyItem);
        }
        menuItems.push(createDraftItem);
      }

      return menuItems;
    }

    default:
      exhaustiveModeCheck(mode);
      return [];
  }
}

function exhaustiveModeCheck(mode: unknown) {
  throw new Error(`Unhandled mode: ${mode}`);
}
