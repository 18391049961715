import React from 'react';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { useProgram } from 'contexts/program';
import { SearchInput } from 'shared/SearchInput';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { Pills } from 'DesignSystem/Components/Pills';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { useDebounce } from 'hooks/useDebounce';
import { Dropdown } from 'components/email-alias/EmailAliasSelect/Dropdown';
import { EmailAlias } from 'models/email-alias';
import styles from './styles.module.css';

type PropsType = {
  value: EmailAlias[];
  onChange: (value: EmailAlias[]) => void;
};

export const EmailAliasSelector: React.FC<PropsType> = ({
  value,
  onChange,
}) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useEmailAliasesInfiniteQuery({
    programId,
    search: useDebounce(search),
    status: ['active'],
  });

  const selectedIds = value.map((email) => email.id || '');

  const emails = [...data];
  const emailIds: Array<string> = data.map((item) => item.id || '');
  const uniqueIds = new Set([...emailIds]);
  value.forEach((alias) => {
    if (alias.id && !uniqueIds.has(alias.id)) {
      emails.push(alias);
    }
  });

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedAudiences = ids
      .map((id) => emails.find((email) => email.id === id))
      .filter((item) => !!item);
    onChange(newSelectedAudiences as Array<EmailAlias>);
  }

  function dropdown() {
    return (
      <Text.Body bold block>
        <Dropdown
          values={emails}
          selectedIds={selectedIds}
          onSelectedIdsChange={handleSelectedIdsChange}
          maxHeight={300}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Text.Body>
    );
  }

  return (
    <>
      <Text.Subheading bold block>
        Email Aliases
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select at least one email alias in order to take a bulk action
      </Text.Body>
      <Box margin={[16, 0, 16, 0]}>
        <ClickDropdown
          dropdownRenderProp={dropdown}
          matchReferenceWidth
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearch('');
          }}
          ignoreKeys
        >
          <div>
            <SearchInput
              placeholder="Search email aliases"
              value={search || ''}
              onChange={setSearch}
              labelClassName={styles.SearchInput}
            />
          </div>
        </ClickDropdown>
      </Box>

      <Flex start wrap className={styles.PillsContainer}>
        {value.map((alias, i) => (
          <Pills
            key={`email-alias-${alias.id}`}
            values={[{ id: i }]}
            render={() => <>{alias.name}</>}
            onClose={(t) => {
              const index = t.id;
              const values = [
                ...value.slice(0, index),
                ...value.slice(index + 1),
              ];

              onChange(values);
            }}
            padding={[0, 12, 0, 8]}
          />
        ))}
      </Flex>
    </>
  );
};
