import * as React from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import { usePortal } from 'hooks/usePortal';
import styles from './slide-in.module.css';

const FX_TIMEOUT = 350;

// CSS animations were transitioning from the start-state to
// the in-progress state before the start-state styles were
// being applied. This effectively removes the effect of the
// navigation. There was a workaround of putting a timeout
// on the effect, but the length of the timeout seemed to depend
// on the complexity of the children being rendered, making it
// unreliable. Instead, this uses an effect as a `componentDidMount`
// to start the animation after the children have been rendered.
const ReadyContent: React.FC<{ onMount: () => void }> = ({
  onMount,
  children,
}) => {
  React.useEffect(() => {
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

// This recreates the semantics of the `react-transition-group`
// The done states allow the effect to be removed. Leaving the effect
// on the components causes visible artifacts when resizing the window.
export const SlideIn: React.FC<{
  children: (slideOut: (afterSlideOut: () => void) => void) => React.ReactNode;
  name: string;
}> = ({ children, name }) => {
  const portal = usePortal(name);
  const [animationState, setAnimationState] = React.useState<
    | 'in-start'
    | 'in-progress'
    | 'in-done'
    | 'out-start'
    | 'out-progress'
    | 'out-done'
  >('in-start');

  const animateIn = React.useCallback(() => {
    if (animationState === 'in-start')
      requestAnimationFrame(() => {
        setAnimationState('in-progress');
        setTimeout(() => {
          setAnimationState('in-done');
        }, FX_TIMEOUT);
      });
  }, [animationState]);

  const animateOut = React.useCallback((afterSlideOut: () => void) => {
    setAnimationState('out-start');
    requestAnimationFrame(() => {
      setAnimationState('out-progress');
      setTimeout(() => {
        setAnimationState('out-done');
        afterSlideOut();
      }, FX_TIMEOUT);
    });
  }, []);

  if (!portal.ready) return null;
  return createPortal(
    <div className={styles.modal}>
      <div className={cx(styles[animationState], styles.willChange)}>
        <div className={cx(styles.content)}>
          <ReadyContent onMount={animateIn}>
            {children(animateOut)}
          </ReadyContent>
        </div>
      </div>
    </div>,
    portal.target
  );
};
