import React, { ReactElement, useRef, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from '@reach/router';
import { useClickDropdown } from 'shared/ClickDropdown';
import { NavLink } from 'shared/NavLink';
import { Icon } from 'shared/Icon';
import { ChevronDown, ChevronUp, Save } from 'shared/icons';
import { useNavigationBlockerContext } from 'contexts/publisher/compose/navigation-blocker';
import { useFlashMessage } from 'contexts/flasher';
import { Link, MenuItem, useFooter } from 'hooks/useFooter';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { usePersistanceStatus } from 'components/publisher/theme/PublisherFooter/PostPersistanceStatus';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Menu } from './Menu';
import styles from './navigation-footer.module.css';
import { Tooltip } from '../Tooltip';
import { HoverTooltip } from '../HoverTooltip';

type DropdownMenuProps = {
  menuItems: MenuItemsType;
  hideDropdown: () => void;
};
const defaultMenuItems: MenuItemsType = {};
export function DropdownMenu({
  hideDropdown,
  menuItems: originalMenuItems,
}: DropdownMenuProps): ReactElement {
  const [menuItems, setMenuItems] = React.useState(defaultMenuItems);
  React.useEffect(() => {
    const adjustedMenuItems: MenuItemsType = {};
    Object.keys(originalMenuItems).forEach((fieldKey) => {
      adjustedMenuItems[fieldKey] = originalMenuItems[fieldKey].map((item) => {
        const onClick = item.isMenuClosedOnClick
          ? () => {
              if (item?.onClick) {
                item.onClick();
              }
              hideDropdown();
            }
          : item.onClick;
        return { ...item, onClick };
      });
    });
    setMenuItems(adjustedMenuItems);
  }, [hideDropdown, originalMenuItems]);

  return <Menu menuItems={menuItems} />;
}

export type MenuItemsType = { [sectionName: string]: Array<MenuItem> };
type Props = {
  leftMenuItems?: MenuItemsType;
  rightMenuItems?: MenuItemsType;
  rightMenuAction?: <T>(arg: T) => void;
  links?: Array<Link>;
  action?: () => void;
  actionName?: string;
  actionClassName?: string;
  actionIconName?: string;
  nextPage?: string;
  title?: string;
  canPerformAction: boolean;
  status?: string;
  usePrevArrow?: boolean;
  hideSubmit?: boolean;
  hideSave?: boolean;
  hideMenu?: boolean;
  exitPath?: string;
  isWorking?: boolean;
  exitHandler?: () => void;
  saveDropdownDisabledMessage?: string;
};

const Button: React.FC<{
  onClick: () => void;
  className: string;
  name: string;
  iconName: string;
  disabled?: boolean;
}> = ({ onClick, className, name, iconName, disabled }) => {
  const isSave = name.toLowerCase() === 'save';
  return (
    <button
      type="button"
      className={cx(className, { [styles.disabled]: disabled })}
      onClick={!disabled ? onClick : undefined}
    >
      {isSave && (
        <span className={styles.saveIcon}>
          <span className={styles.saveBg}>
            <Icon iconName={iconName} iconType="SVG" />
          </span>
        </span>
      )}
      <span>{name}</span>
      {!isSave && (
        <span>
          <Icon iconName={iconName} iconType="SVG" useCurrentColor />
        </span>
      )}
    </button>
  );
};

const SaveDropdown: React.FC<{
  isWorking: boolean;
  status: string;
  menuItems: { [sectionName: string]: Array<MenuItem> };
  action: <T>(arg: T) => void;
  disabledMessage?: string;
}> = ({ isWorking, menuItems, status, action, disabledMessage }) => {
  const { cloudStatus } = usePersistanceStatus();
  const { ClickDropdown: Dropdown, isOpen } = useClickDropdown();
  const saveButton = useRef<HTMLButtonElement>(null);
  const [width, setWidth] = useState(window.innerWidth);
  useResizeObserver({
    ref: saveButton,
    onResize: () => {
      if (saveButton.current) setWidth(window.innerWidth);
    },
  });

  const isDisabled = !!disabledMessage;

  const SaveButton = (
    <button
      className={cx(styles.title, styles.inlineSave, styles.saveButton, {
        [styles.disabled]: isWorking || isDisabled,
      })}
      type="button"
      disabled={isWorking || isDisabled}
      onClick={() =>
        action({
          shouldValidate: status !== 'draft',
        })
      }
      ref={saveButton}
    >
      {width > 1400 ? (
        <>
          <div
            className={styles.title}
            style={{ textAlign: 'right', paddingTop: '10px', width: '100%' }}
          >
            Save
          </div>
          <div className={cx(styles.lastSave)}>
            <div
              className={styles.statusCircle}
              style={{
                backgroundColor: cloudStatus === 'Up to date' ? 'green' : 'red',
              }}
            />
            <div className={styles.status}>{cloudStatus}</div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.inlineSaveIcon}>
            <Save />
          </div>
          <div className={cx(styles.lastSave)}>
            <div
              className={styles.statusCircle}
              style={{
                backgroundColor: cloudStatus === 'Up to date' ? 'green' : 'red',
              }}
            />
          </div>
        </>
      )}
    </button>
  );

  return (
    <>
      <div className={styles.spinnerWrapper}>
        {isWorking && <LoadingSpinner size="medium" />}
      </div>
      {isDisabled ? (
        <HoverTooltip
          align="right"
          content={<Tooltip description={disabledMessage} />}
          icon={SaveButton}
        />
      ) : (
        SaveButton
      )}
      <Dropdown
        left={-270}
        upward
        dropdownClassName={styles.dropdown}
        dropdownRenderProp={<Menu menuItems={menuItems} />}
      >
        <div className={cx(styles.info, styles.saveInfo)}>
          <div className={styles.iconContainer}>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export const NavigationFooter: React.FC<Props> = (props) => {
  const { setFlashMessage } = useFlashMessage();
  const {
    nextPage,
    leftMenuItems,
    rightMenuItems,
    rightMenuAction,
    links,
    exitPath,
    exitHandler,
    title,
    canPerformAction,
    status,
    usePrevArrow,
    hideSubmit,
    hideSave,
    hideMenu,
    isWorking,
    saveDropdownDisabledMessage,
  } = props;
  const footer = useFooter(props);
  const navigate = useNavigate();
  const { blocker } = useNavigationBlockerContext({ noThrow: true });
  const {
    ClickDropdown: LeftDropdown,
    isOpen: leftMenuIsOpen,
  } = useClickDropdown();
  const { contentPermissions } = useSettings();
  const { canEdit } = contentPermissions;

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
    else if (exitPath) navigate(exitPath);
  }, [navigate, exitPath, exitHandler]);

  return (
    <>
      <nav className={styles.navigation}>
        <div className={styles.subNav}>
          <div className={styles.controls}>
            <div className={styles.closeButtonContainer}>
              <button
                type="button"
                className={styles.close}
                onClick={handleClose}
              >
                {usePrevArrow ? (
                  <span className={styles.arrowIcon}>
                    <Icon iconName="ArrowPrevLong" iconType="SVG" size={32} />
                  </span>
                ) : (
                  <span className={styles.closeIcon}>
                    <Icon iconName="CloseLong" iconType="SVG" />
                  </span>
                )}
                <div className={styles.exit}>Exit</div>
              </button>
            </div>
            {hideMenu && title && (
              <div className={styles.infoWrapper}>
                <div className={styles.info}>
                  <div className={cx(styles.title, styles.noMenu)}>{title}</div>
                </div>
              </div>
            )}
            {!hideMenu && leftMenuItems && canEdit && (
              <LeftDropdown
                upward
                dropdownClassName={styles.dropdown}
                dropdownRenderProp={(hideDropdown) => (
                  <DropdownMenu
                    hideDropdown={hideDropdown}
                    menuItems={leftMenuItems}
                  />
                )}
              >
                <div className={styles.infoWrapper}>
                  <div className={styles.info}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.title} dir="auto">
                        {title}
                      </div>
                      <div className={styles.iconContainer}>
                        {leftMenuIsOpen ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    </div>
                    <div className={styles.status}>{status}</div>
                  </div>
                  <div className={styles.hoverInfo}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.title} dir="auto">
                        {title}
                      </div>
                      <div className={styles.iconContainer}>
                        {leftMenuIsOpen ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    </div>
                    <div className={styles.status}>{status}</div>
                  </div>
                </div>
              </LeftDropdown>
            )}
          </div>
          {links && (
            <div className={styles.links}>
              {links.map((link: Link) => (
                <NavLink
                  disabled={link.disabled}
                  key={link.name}
                  to={link.href}
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          )}
          <div className={styles.rightMenuItems}>
            {!hideSave &&
              (canEdit || saveDropdownDisabledMessage) &&
              rightMenuItems &&
              rightMenuAction &&
              status &&
              isWorking !== undefined && (
                <SaveDropdown
                  isWorking={isWorking}
                  menuItems={rightMenuItems}
                  status={status}
                  action={rightMenuAction}
                  disabledMessage={saveDropdownDisabledMessage}
                />
              )}
          </div>
        </div>
        {!hideSubmit && (
          <>
            {nextPage ? (
              <Button
                className={styles.continue}
                onClick={() => {
                  if (blocker && blocker.enabled) {
                    setFlashMessage({
                      message: blocker.message,
                      severity: 'error',
                    });
                    return;
                  }
                  footer.goForward(nextPage);
                }}
                name="Continue"
                iconName="Arrow"
              />
            ) : (
              <Button
                className={styles.save}
                onClick={footer.action || (() => {})}
                name={footer.actionName || ''}
                iconName="Check"
                disabled={!canPerformAction}
              />
            )}
          </>
        )}
      </nav>
    </>
  );
};
