import React from 'react';
import { DateTime } from 'luxon';
import { Content } from 'models/content';
import { useContentReportUrls } from 'hooks/content/useContentReportUrls';
import { useResultsSummaryQuery } from 'hooks/content-insights';
import { useNotificationCenterLabel } from 'hooks/notification-center';
import { MetricsSection } from './MetricsSection';

type PropsType = {
  content: Content;
  isLoadingSummary: ReturnType<typeof useResultsSummaryQuery>['isLoading'];
  summary: ReturnType<typeof useResultsSummaryQuery>['data'];
};

export const DeliverySection: React.FC<PropsType> = ({
  content,
  isLoadingSummary: isLoading,
  summary,
}) => {
  const now = DateTime.now().toUTC();
  const startedAt = content.createdAt
    ? DateTime.fromISO(content.createdAt)
    : now;
  const { getDeliveryDetailsUrl } = useContentReportUrls({
    content,
    startedAt,
    version: 2,
  });

  const counter = (names: string[]): number | undefined =>
    summary?.aggregate
      .filter((item) => names.includes(item.name))
      .map((item) => item.count)
      .reduce((a, b) => a + b, 0);

  const delivered = counter(['DELIVERED']);
  const dropped = counter(['DROPPED']);
  const errors = counter(['SENT_ERROR', 'DELIVERY_ERROR']);
  const notificationCenterLabel = useNotificationCenterLabel();

  return (
    <MetricsSection
      title="Delivery"
      description="Review delivery details of your campaign. Results may take up to 1 hour to appear here."
      reportLink={{
        dataPendo: 'view-delivery-report',
        href: getDeliveryDetailsUrl(),
        label: 'View Full Report',
      }}
      subsections={[
        {
          id: 'delivery--default',
          metrics: [
            {
              isLoading,
              name: 'Successful Delivery',
              tooltip:
                'The total number of messages successfully delivered to users.',
              stat: { type: 'count', value: delivered },
            },
            {
              isLoading,
              name: 'Dropped',
              tooltip:
                'The total number of messages that have been dropped. A drop occurs if the user is missing from the audience or has been unsubscribed from the program at the time of delivery.',
              stat: { type: 'count', value: dropped },
              highlight: true,
            },
            {
              isLoading,
              name: 'Errors',
              tooltip: 'The total number of failed sends and/or deliveries.',
              stat: { type: 'count', value: errors },
              highlight: true,
            },
            {
              isLoading,
              name: 'Emails',
              tooltip: 'The number of emails delivered to users.',
              stat: { type: 'count', value: summary?.email.delivered },
            },
            {
              isLoading,
              name: 'Push Notifications',
              tooltip: 'The number of push notifications delivered to users.',
              stat: { type: 'count', value: summary?.push.delivered },
            },
            {
              isLoading,
              name: notificationCenterLabel,
              tooltip: `The number of ${notificationCenterLabel} notifications delivered to users.`,
              stat: { type: 'count', value: summary?.assistant.delivered },
            },
          ],
        },
      ]}
    />
  );
};
