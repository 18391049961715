import * as React from 'react';

import cx from 'classnames';
import {
  HoverDropdown,
  PassThroughPropsType,
  DropdownRenderPropType,
} from '../HoverDropdown';
import { IconMenu, ItemType as IconMenuItemType } from '../../IconMenu';
import styles from './HoverIconMenu.module.css';

export type ItemType = IconMenuItemType;

type PropsType = {
  menuItems: Array<IconMenuItemType>;
  footerComponent?: React.ReactNode;
  layout?: 'classic' | 'journeys';
} & PassThroughPropsType;

export const HoverIconMenu: React.FC<PropsType> = (props) => {
  const { menuItems, footerComponent, layout, ...passThroughProps } = props;
  const normalMenuItems = menuItems.filter((item) => !item?.danger);
  const dangerMenuItems = menuItems.filter((item) => item?.danger);
  const name = passThroughProps.profileName;

  const hasHr = normalMenuItems.length > 0 && dangerMenuItems.length > 0;
  const dropdownRenderProp: DropdownRenderPropType = (dismiss) => (
    <div
      className={cx(styles.dropdown, {
        [styles.journeysDropdown]: layout === 'journeys',
      })}
      data-testid="icon-menu-ul"
    >
      {name && <div className={styles.profileName}>{name}</div>}
      <IconMenu
        className={styles.normalMenuItems}
        layout={layout}
        {...{ menuItems: normalMenuItems, dismiss }}
      />
      {hasHr && (
        <hr
          className={cx(styles.horizontal, {
            [styles.journeysLayoutDivider]: layout === 'journeys',
          })}
        />
      )}
      {dangerMenuItems.length > 0 && (
        <>
          <IconMenu
            layout={layout}
            className={styles.dangerMenuItems}
            {...{ menuItems: dangerMenuItems, dismiss }}
          />
        </>
      )}
      {footerComponent}
    </div>
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <HoverDropdown
      dropdownRenderProp={dropdownRenderProp}
      {...passThroughProps}
    />
  );
};
