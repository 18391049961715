import { filters as initialFilters, Filter } from 'models/journeys/filter';
import { useMemo } from 'react';
import { DateRange } from 'shared/DateRangeInput/DateRange';
import { clone } from 'utility/deep-merge';

export const useInitialSearchQuery = (
  searchQuery: string
): {
  initialFiltersData: Filter[];
  initialSearch: string;
} => {
  const filters = useMemo(() => {
    const searchedUrl = decodeURIComponent(searchQuery).replace(
      /\[.*?\]/g,
      '[]'
    );
    const urlParams = new URLSearchParams(searchedUrl);
    const editingState = urlParams.getAll('editing_state[]');
    const createdBy = urlParams.getAll('created_by[]');
    const createdSince = urlParams.get('created_since');
    const createdBefore = urlParams.get('created_before');
    const initialSearchText = urlParams.get('search') ?? '';
    const initialFiltersClone = clone(initialFilters);
    const publicationState = initialFiltersClone.find(
      (filter) => filter.name === 'publicationState'
    );
    if (editingState.length && publicationState) {
      publicationState.value = editingState;
    }

    const author = initialFiltersClone.find(
      (filter) => filter.name === 'author'
    );
    if (createdBy.length && author) {
      author.value = createdBy;
    }

    const createdDate = initialFiltersClone.find(
      (filter) => filter.name === 'createdDate'
    );
    if (createdDate) {
      // used default value of initial filter which was lost during deepclone
      createdDate.value = initialFilters.find(
        (filter) => filter.name === 'createdDate'
      )?.value;
      if (createdSince && createdBefore) {
        const dateKey = `${createdSince}~${createdBefore}`;
        const createdDateAbsoluteRange = DateRange.buildFromKey(dateKey);
        createdDate.value = createdDateAbsoluteRange;
        createdDate.active = true;
      }
    }
    return {
      initialSearch: initialSearchText,
      initialFiltersData: initialFiltersClone,
    };
  }, [searchQuery]);
  return filters;
};
