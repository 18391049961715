import * as React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { DefinitionBlock, FieldData, Targets } from 'models/publisher/block';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { Editable } from './Editable';
import { Static } from './Static';

type StaticProps = {
  block: DefinitionBlock;
  delivery?: Targets;
};

type EditableProps = StaticProps & {
  blockId: string;
  isDragging: boolean;
  removeByBlockId: (blockId: string) => void;
  onChangeData: (blockId: string, data: FieldData) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

function isEditableProps(
  props: StaticProps | EditableProps
): props is EditableProps {
  /* eslint-disable-next-line no-prototype-builtins */
  return props.hasOwnProperty('blockId');
}

export const BlockRow: React.FC<StaticProps | EditableProps> = (props) => {
  const { canEdit } = React.useContext(DesignPermissionContext);

  /* eslint-disable react/jsx-props-no-spreading */
  if (canEdit && isEditableProps(props)) return <Editable {...props} />;
  return <Static {...props} />;
  /* eslint-enable react/jsx-props-no-spreading */
};
