import React, { ChangeEvent, useCallback } from 'react';
import cx from 'classnames';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useUniqueId } from 'hooks/useUniqueId';
import { SearchInputType } from './types';
import styles from './search-input.module.css';
import { Close } from '../icons';
import { Icon } from '../Icon';

export const SearchInput: React.FC<SearchInputType> = React.forwardRef(
  (
    {
      value,
      onChange,
      onFocus,
      onBlur,
      width,
      onSearch,
      panelClassName,
      labelClassName,
      inputClassName,
      placeholder,
      errorMessage,
      searchIconSize = 24,
      totalRecords,
      hasClearButton = true,
      showSpinner = false,
      hasError = false,
    },
    ref
  ) => {
    const inputId = useUniqueId();

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (onSearch) onSearch();
        onChange(event.target.value);
      },
      [onChange, onSearch]
    );

    const clearSearch = useCallback(() => {
      if (onSearch) onSearch();
      onChange('');
    }, [onChange, onSearch]);

    return (
      <>
        <div
          data-test="search-input"
          className={cx(styles.wrapper, {
            [`${panelClassName}`]: panelClassName,
            [`${styles.hasError}`]: hasError,
            [styles.hasClearButton]: hasClearButton,
          })}
          style={{ width: width ?? 'auto' }}
        >
          <label className={cx(styles.label, labelClassName)} htmlFor={inputId}>
            <Icon
              iconName="Magnify"
              iconType="SVG"
              useCurrentColor
              size={searchIconSize}
              title="Search it!"
            />
            <input
              ref={ref}
              className={cx(styles.input, inputClassName)}
              id={inputId}
              type="text"
              value={value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
            />
            {hasClearButton && value !== '' && (
              <button
                data-test="close-search"
                className={styles.closeButton}
                onClick={clearSearch}
                type="button"
              >
                <Close />
              </button>
            )}
            {showSpinner && (
              <button
                data-test="close-search"
                className={styles.closeButton}
                type="button"
              >
                <LoadingSpinner size="small" />
              </button>
            )}
          </label>
          {!!totalRecords && <span>{totalRecords} found</span>}
        </div>
        {hasError && (
          <div className={styles.error}>{errorMessage || 'Required'}</div>
        )}
      </>
    );
  }
);
