import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { ReactComponent as ProgressBarBackground } from './ProgressBarBackground.svg';
import { ReactComponent as Progress } from './Progress.svg';
import styles from './loading-screen.module.css';

export const LoadingScreen: React.FC<RouteComponentProps> = () => (
  <div className={styles.wrapper}>
    <div className={styles.logoWrapper}>
      <SVGIcon size={120} name="Logo" />
      <div className={styles.progressBar} data-testid="progressBar">
        <ProgressBarBackground />
        <Progress />
      </div>
    </div>
  </div>
);
