import React from 'react';
import { LitmusRole } from 'models/role';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Section } from 'DesignSystem/Form';
import { Spinner } from '../../Shared/Spinner';
import styles from '../roles.module.css';
import { PermissionRow } from '../PermissionRow';
import { usePermissionFilters } from '../usePermissionFilter';

type PropsType = {
  role: Partial<LitmusRole>;
  programId: number;
};

export const Permissions: React.FC<PropsType> = ({ role, programId }) => {
  const { isLoading, data: permissions } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'secondary',
    hidden: false,
  });

  const { data: customBlocksFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CustomBlocks'
  );

  const { data: insightsPlusEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration'
  );

  const { filterPermissions } = usePermissionFilters(programId);

  const sortedPermissions = permissions.sort((a, b) => {
    if (a.sequence < b.sequence) return -1;
    if (a.sequence > b.sequence) return 1;
    return 0;
  });

  const mainPermissions = sortedPermissions
    .filter((p) => p.parent === null)
    .filter((p) => {
      if (p.name === 'journeys') return false;

      if (p.name === 'insights_plus_access') {
        return Boolean(insightsPlusEnabled?.value);
      }

      return true;
    });

  const subPermissionsMap: { [key: string]: LitmusRole[] } = {};
  sortedPermissions.forEach((sortedPermission) => {
    subPermissionsMap[sortedPermission.name] = filterPermissions(
      sortedPermissions
    ).filter(
      (subPermission) =>
        subPermission.parent === sortedPermission.name &&
        (subPermission.name !== 'library_custom_blocks' ||
          customBlocksFlag?.value)
    );
  });

  return (
    <Section
      title="Permissions"
      href={
        !role.isSystemRole
          ? `/${programId}/configure/roles/${
              role.id as number
            }/edit/permissions`
          : ''
      }
      className={styles.section}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        mainPermissions.map((permission) => (
          <PermissionRow
            key={permission.name}
            permission={permission}
            on={(p) => {
              return (
                role.aliases?.some((alias) => alias.name === p.name) || false
              );
            }}
            onChange={(f) => f}
            subPermissionsMap={subPermissionsMap}
            disabled
          />
        ))
      )}
    </Section>
  );
};
