import React from 'react';
import cx from 'classnames';
import filterStyles from 'DesignSystem/Table/filters.module.css';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { Icon } from 'shared/Icon';
import { isRequiredFilter, Filter } from 'models/insight/Filter';

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
type Props = {
  onCloseButtonClick: (Filter: Filter) => void;
};
export const PillButton: React.FC<Props> = ({ onCloseButtonClick }) => {
  const {
    toggleVisible,
    filter,
    filterState,
    pillButtonDescription,
  } = React.useContext(FilterDropdownContext);

  return (
    <div onClick={toggleVisible} style={{ margin: '4px' }}>
      <div
        className={cx(filterStyles.FilterTrigger, {
          [filterStyles.FilterTriggerApplied]:
            pillButtonDescription && pillButtonDescription !== 'Any',
        })}
      >
        {filterState.isLoading ? (
          <span>
            {filter.label} <b>Loading...</b>
          </span>
        ) : (
          <span>
            {filter.label}{' '}
            {pillButtonDescription && (
              <span>
                is&nbsp;
                <b>
                  {pillButtonDescription.length > 30
                    ? `${pillButtonDescription.slice(0, 30)}...`
                    : pillButtonDescription}
                </b>
              </span>
            )}
          </span>
        )}
        {!isRequiredFilter(filter) && (
          <span
            aria-roledescription="close"
            onClick={() => onCloseButtonClick(filterState.filter)}
            className={filterStyles.FilterTriggerCloseButton}
          >
            <Icon iconName="Close" iconType="SVG" size={9} />
          </span>
        )}
      </div>
    </div>
  );
};
