import * as React from 'react';
import { Filter, Template } from 'models/library';
import { useLibraryTemplates } from 'hooks/useLibrary';
import { useDebounce } from 'hooks/useDebounce';
import { Dropdown } from 'components/templates/TemplatesSelect/Dropdown';
import { Box, ClickDropdown } from 'DesignSystem/Components';
import { SearchInput } from 'shared/SearchInput';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Pills } from 'DesignSystem/Components/Pills';
import * as Text from 'DesignSystem/Typography';

import styles from './styles.module.css';

export type PropsType = {
  value: Template[];
  onChange: (value: Template[]) => void;
};

export const TemplateSelector: React.FC<PropsType> = ({ value, onChange }) => {
  const [search, setSearch] = React.useState('');
  const debouncedSearchText = useDebounce(search);

  const filter: Filter = React.useMemo(() => {
    if (!debouncedSearchText) {
      return { type: 'all' };
    }
    return { type: 'search', search: debouncedSearchText };
  }, [debouncedSearchText]);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useLibraryTemplates({ filter, restrictedOnly: true, pageSize: 50 });

  const selectedIds = value.map((template) => template.id);
  const templates = [...data];
  const templateIds: Array<string> = data.map((item) => item.id || '');
  const uniqueIds = new Set([...templateIds]);
  value.forEach((template) => {
    if (template.id && !uniqueIds.has(template.id)) {
      templates.push(template);
    }
  });

  const handleSelectedIdsChange = (ids: string[]) => {
    const newSelectedTemplates = ids
      .map((id) => templates.find((template) => template.id === id))
      .filter((item) => !!item);
    onChange(newSelectedTemplates as Template[]);
  };

  function dropdown() {
    return (
      <Text.Body bold block>
        <Dropdown
          values={data}
          selectedIds={selectedIds}
          onSelectedIdsChange={handleSelectedIdsChange}
          maxHeight={300}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Text.Body>
    );
  }

  return (
    <>
      <Text.Subheading bold block>
        Templates
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select at least one template in order to take a bulk action
      </Text.Body>
      <Box margin={[16, 0, 16, 0]}>
        <ClickDropdown
          dropdownRenderProp={dropdown}
          matchReferenceWidth
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearch('');
          }}
          ignoreKeys
        >
          <div>
            <SearchInput
              placeholder="Search templates"
              value={search || ''}
              onChange={setSearch}
              labelClassName={styles.SearchInput}
            />
          </div>
        </ClickDropdown>
      </Box>

      <Flex start wrap className={styles.PillsContainer}>
        {value.map((template, i) => (
          <Pills
            key={`template-${template.id}`}
            values={[{ id: i }]}
            render={() => <>{template.title}</>}
            onClose={(t) => {
              const index = t.id;
              const values = [
                ...value.slice(0, index),
                ...value.slice(index + 1),
              ];

              onChange(values);
            }}
            padding={[0, 12, 0, 8]}
          />
        ))}
      </Flex>
    </>
  );
};
