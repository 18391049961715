import React from 'react';
import { useNavigate } from '@reach/router';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import {
  useArchiveWorkflow,
  useCopyWorkflow,
  useStartDraftWorkflow,
} from 'hooks/workflows/actions';

type WorkflowCallback = (workflowId: number) => void;
type WorkflowModalActions = {
  showingModal: boolean;
  showModal(): void;
  closeModal(): void;
};

export type WorkflowActions = {
  view: WorkflowCallback;
  archive: WorkflowCallback;
  copy: WorkflowCallback;
  edit: WorkflowCallback;
} & {
  confirmArchive?: WorkflowModalActions;
};

export const useActions = (): WorkflowActions => {
  const [showingArchiveModal, setShowingArchiveModal] = React.useState(false);
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const confirmArchive = React.useMemo(
    () => ({
      showingModal: showingArchiveModal,
      showModal: () => setShowingArchiveModal(true),
      closeModal: () => setShowingArchiveModal(false),
    }),
    [showingArchiveModal, setShowingArchiveModal]
  );

  const handleError = React.useCallback(
    (message: string) => () => {
      setFlashMessage({
        severity: 'error',
        message,
      });
    },
    [setFlashMessage]
  );

  const view = React.useCallback(
    (workflowId: number) => {
      navigate(`/${programId}/configure/workflows/edit/${workflowId}`);
    },
    [programId, navigate]
  );

  const { archive } = useArchiveWorkflow(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(['workflows-infinite']);
      setFlashMessage({
        severity: 'info',
        message: 'Workflow archived successfully',
      });
    },
    onError: handleError('Could not archive workflow'),
  });

  const { copy } = useCopyWorkflow(programId, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['workflows-infinite']);
      setFlashMessage({
        severity: 'info',
        message: 'Workflow copied successfully',
      });
      view(data.id);
    },
    onError: handleError('Could not copy workflow'),
  });

  const { startDraft: edit } = useStartDraftWorkflow(programId, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['workflows-infinite']);
      view(data.id);
    },
    onError: handleError('Could not start workflow draft'),
  });

  return {
    edit,
    view,
    archive,
    copy,
    confirmArchive,
  };
};
