export type CampaignVariable = {
  name: string;
  active: boolean;
  fallback_value?: string;
  display_name?: string;
};

export const populateDisplayNames = (attrs: CampaignVariable[]): void => {
  attrs.forEach((attr) => {
    // eslint-disable-next-line no-param-reassign
    attr.display_name = formatAttributeDisplayName(attr.name);
  });
};

const CUSTOM_ATTRIBUTE_PREFIX = 'cstm_';
const STANDARD_ATTRIBUTES = [
  'birthdate',
  'business_unit',
  'city',
  'company',
  'cost_center',
  'country',
  'department',
  'display_name',
  'division',
  'employee_id',
  'employee_type',
  'first_name',
  'gender',
  'job_title',
  'last_name',
  'locale',
  'manager',
  'preferred_language',
  'start_date',
  'state',
  'timezone',
  'universal_identifier',
  'work_location',
];

export const isCustom = (label: string): boolean => {
  return label.startsWith(CUSTOM_ATTRIBUTE_PREFIX);
};

export const isDuplicateStandard = (label: string): boolean => {
  if (isCustom(label)) {
    return STANDARD_ATTRIBUTES.includes(
      label.substring(CUSTOM_ATTRIBUTE_PREFIX.length)
    );
  }

  return false;
};

const toCamelCase = (str: string): string => {
  return str.replace(/_(\w)?/g, (match) => match.substr(1).toUpperCase());
};

export const formatAttributeDisplayName = (label: string): string => {
  let cleanLabel = label;
  if (isCustom(label)) {
    // strip off the custom prefix from beginning when
    // constructing the display name
    cleanLabel = label.substring(CUSTOM_ATTRIBUTE_PREFIX.length);
  }
  const [firstChar, ...remainingChars] = toCamelCase(cleanLabel).split('');
  const str = `${firstChar.toUpperCase()}${remainingChars.join('')}`.replace(
    /(At)$/g,
    'On'
  );
  return str.replace(/[A-Z]/g, ' $&');
};
