import React, { useEffect, useState } from 'react';
import { useProgram } from 'contexts/program';
import { PaginatedListDropdownProvider } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { PaginatedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/PaginatedListDropdown';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { useSemiPaginatedAuthorAliases } from 'hooks/insights/useSemiPaginatedQuery';
import { useAuthorAliasesQuery } from 'services/api-authors';
import { Author } from 'models/author';

export const AuthorAliasListDropdown: React.FC = () => {
  const { id: programId } = useProgram();
  const [allItems, setAllItems] = useState<
    {
      label: string;
      value: string;
      uuid: string;
    }[]
  >([]);
  const { defaultValues, debouncedSearchString } = React.useContext(
    FilterDropdownContext
  );
  const defaultIds = (defaultValues
    ? Array.from(defaultValues.values())
    : []
  ).map((i) => parseInt(i, 10));

  const {
    data: initialData,
    isLoading: isInitialDataLoading,
  } = useAuthorAliasesQuery({
    programId,
    ids: defaultIds,
    pageSize: defaultIds.length,
  });

  const {
    aggregatedData,
    fetchNext,
    isLoading,
    hasMoreToLoad,
    isFetching,
  } = useSemiPaginatedAuthorAliases({
    programId,
    query: debouncedSearchString,
  });

  useEffect(() => {
    if (aggregatedData === undefined && initialData === undefined) {
      setAllItems([]);
      return;
    }
    // Dedupe
    const mergedDataHash: { [key: string]: Author } = {};

    if (!debouncedSearchString && !isInitialDataLoading) {
      initialData?.forEach((t) => {
        mergedDataHash[`${t.id}`] = t;
      });
    }

    aggregatedData?.forEach((t) => {
      mergedDataHash[`${t.id}`] = t;
    });

    // Back to an array
    const mergedData: Author[] = Object.keys(mergedDataHash).map(
      (k) => mergedDataHash[k]
    );

    const mergedArray = mergedData
      .map((author) => ({
        label: String(author.defaultDisplayName),
        value: String(author.authorAliasId),
        uuid: String(author.authorAliasId),
      }))
      .sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });

    setAllItems(mergedArray);
  }, [
    aggregatedData,
    debouncedSearchString,
    initialData,
    isInitialDataLoading,
  ]);

  // fetchNext if more items available
  useEffect(() => {
    if (allItems.length === 0) fetchNext();
  }, [allItems, fetchNext]);

  return (
    <PaginatedListDropdownProvider
      allItems={allItems}
      fetchNext={fetchNext}
      isLoadingMoreItems={isLoading || isFetching || isInitialDataLoading}
      hasMoreToLoad={hasMoreToLoad}
      defaultValues={defaultValues}
    >
      <PaginatedListDropdown />
    </PaginatedListDropdownProvider>
  );
};
