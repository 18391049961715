import * as React from 'react';
import { CardEditorContext } from 'contexts/publisher/compose/card';
import {
  CallToAction,
  defaultCallToAction,
} from '../models/publisher/call-to-action';

export function useCardEditor(
  baseCtaData: CallToAction = defaultCallToAction,
  onChange: (cta: CallToAction) => void
): React.ContextType<typeof CardEditorContext> {
  const [cta, setCta] = React.useState(baseCtaData);
  const change = React.useCallback(
    (changed: CallToAction) => {
      setCta(changed);
      if (onChange) onChange(changed);
    },
    [setCta, onChange]
  );

  React.useEffect(() => {
    setCta(baseCtaData);
  }, [baseCtaData]);

  const handleAltTextChange = React.useCallback(
    (altText: string) => {
      change({ ...cta, altText });
    },
    [cta, change]
  );

  return {
    cta,
    change,
    handleAltTextChange,
  };
}
