import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useSignInConfigQuery } from 'hooks/program';
import { Section, Subsection } from 'DesignSystem/Form';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import * as Text from 'DesignSystem/Typography';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TextInput } from 'shared/TextInput';
import { SignInConfig } from 'models/signInConfig';
import { MAIcon } from 'shared/MAIcon';
import { ConfirmModal } from 'DesignSystem/Components';
import { AssertionsInput } from './AssertionsInput';
import { FormatInput } from './FormatInput';
import { LoginPageLabelInput } from './LoginPageLabelInput';
import { useActions } from '../useActions';
import styles from '../styles.module.css';

export const UserAccessSso: React.FC<RouteComponentProps> = () => {
  const { data: signInConfig, errorMessage } = useSignInConfigQuery();
  const [savedConfig, setSavedConfig] = React.useState<SignInConfig>();
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const { update } = useActions(setSavedConfig);

  const [config, setConfig] = React.useState<SignInConfig>();
  const [errors, setErrors] = React.useState<
    Record<string, string | undefined>
  >({});

  const firstupEnabled =
    signInConfig?.enabled.knownEmail ||
    signInConfig?.enabled.otherEmail ||
    signInConfig?.enabled.username;

  React.useEffect(() => {
    if (signInConfig) {
      setConfig({
        ...signInConfig,
        enabled: { ...signInConfig.enabled, sso: true },
      });
      setSavedConfig(signInConfig);
    }
  }, [signInConfig]);
  function disableSave() {
    if (!config || !savedConfig) {
      return true;
    }

    if (JSON.stringify(config) === JSON.stringify(savedConfig)) {
      return true;
    }

    if (!config.enabled.sso) return false;

    if (!config.sso.ssoTargetUrl || config.sso.ssoTargetUrl.trim() === '')
      return true;
    if (!config.sso.sloTargetUrl || config.sso.sloTargetUrl.trim() === '')
      return true;
    if (!config.sso.entityId || config.sso.entityId.trim() === '') return true;
    if (
      (!config.sso.certificate || config.sso.certificate.trim() === '') &&
      !config.sso.certificatePresent
    )
      return true;

    return false;
  }

  const submit = () => {
    if (isConfirmOpen) setIsConfirmOpen(false);
    const data = { ...config };
    if (data?.enabled?.sso && !firstupEnabled) {
      data.defaultSignInMethod = 'sso';
    }
    if (!data?.enabled?.sso && firstupEnabled) {
      data.defaultSignInMethod = 'usernameOrEmail';
    }
    update(data as SignInConfig);
  };

  const title = (
    <div className={styles.Title}>
      <span>SSO via SAML</span>
      {signInConfig?.defaultSignInMethod === 'sso' && (
        <span className={styles.DefaultBill}>Default</span>
      )}
    </div>
  );

  function validateRequired(key: string, value: string) {
    if (value.trim() === '') {
      setErrors({ ...errors, [key]: 'This field is required' });
      return;
    }

    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
  }

  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        title="User Access"
        description="Configure how workers will access your program"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'User Access', to: '..' },
          { label: 'SSO' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <SVGIcon name="SaveSuccess" />,
            onClick: () => {
              if (!config?.enabled.sso) {
                setIsConfirmOpen(true);
                return;
              }
              submit();
            },
            disabled: disableSave(),
          },
        ]}
      >
        {errorMessage && <>{errorMessage}</>}

        {config && (
          <Section
            title={title}
            description="Set up community standard community sign-in options. NOTE: Enabling or disabling Username or Email methods will only affect new registrations (existing users will still be able to sign-in with their chosen method)."
            className={styles.MainSection}
          >
            <Subsection title="">
              <div className={styles.EnabledToggle}>
                <div>
                  <Text.Subheading block bold>
                    SSO Sign-in Method
                  </Text.Subheading>
                  <Text.Body block color={Text.color.gray70}>
                    Enable to configure SSO access for the community.
                  </Text.Body>
                </div>
                <FlipSwitch
                  onChange={(value) => {
                    if (!firstupEnabled && !value) {
                      setErrors({
                        ...errors,
                        toggle: `At least one sign-in method must be enabled in order for users to access the community.
                          In order to disable one sign-in method,
                          the other method must first be set up and enabled.`,
                      });
                      return;
                    }
                    setConfig({
                      ...config,
                      enabled: {
                        ...config.enabled,
                        sso: value || false,
                      },
                    });
                  }}
                  on={config.enabled.sso}
                />
              </div>
              {errors.toggle && (
                <div className={styles.Error}>
                  <MAIcon name="warning" />
                  {errors.toggle}
                </div>
              )}
            </Subsection>
            {config.enabled.sso && (
              <>
                <LoginPageLabelInput config={config} setConfig={setConfig} />
                <Subsection title="">
                  <div className={styles.SsoInput}>
                    <Text.Subheading block bold>
                      Entity ID
                    </Text.Subheading>
                    <TextInput
                      value={config.sso.entityId}
                      className={styles.TextField}
                      placeholder="https://sso.provider.com/example"
                      onBlur={(value) => {
                        validateRequired('entityId', value);
                      }}
                      onChange={(value) => {
                        validateRequired('entityId', value);
                        setConfig({
                          ...config,
                          sso: { ...config.sso, entityId: value },
                        });
                      }}
                    />
                    {errors.entityId && (
                      <div className={styles.Error}>
                        <MAIcon name="warning" />
                        {errors.entityId}
                      </div>
                    )}
                  </div>

                  <div className={styles.SsoInput}>
                    <Text.Subheading block bold>
                      SSO Target URL
                    </Text.Subheading>
                    <TextInput
                      value={config.sso.ssoTargetUrl}
                      className={styles.TextField}
                      placeholder="https://sso.provider.com/example-target-url"
                      onBlur={(value) => {
                        validateRequired('ssoTargetUrl', value);
                      }}
                      onChange={(value) => {
                        validateRequired('ssoTargetUrl', value);
                        setConfig({
                          ...config,
                          sso: { ...config.sso, ssoTargetUrl: value },
                        });
                      }}
                    />
                    {errors.ssoTargetUrl && (
                      <div className={styles.Error}>
                        <MAIcon name="warning" />
                        {errors.ssoTargetUrl}
                      </div>
                    )}
                  </div>

                  <div className={styles.SsoInput}>
                    <Text.Subheading block bold>
                      SLO Target URL
                    </Text.Subheading>
                    <TextInput
                      value={config.sso.sloTargetUrl}
                      className={styles.TextField}
                      placeholder="https://slo.provider.com/example-target-url"
                      onBlur={(value) => {
                        validateRequired('sloTargetUrl', value);
                      }}
                      onChange={(value) => {
                        validateRequired('sloTargetUrl', value);
                        setConfig({
                          ...config,
                          sso: { ...config.sso, sloTargetUrl: value },
                        });
                      }}
                    />
                    {errors.sloTargetUrl && (
                      <div className={styles.Error}>
                        <MAIcon name="warning" />
                        {errors.sloTargetUrl}
                      </div>
                    )}
                  </div>

                  <FormatInput config={config} setConfig={setConfig} />
                  <AssertionsInput config={config} setConfig={setConfig} />
                </Subsection>
                <Subsection title="Certificate">
                  <div className={styles.CertificateInput}>
                    <TextInput
                      value={config.sso.certificate}
                      placeholder="Certificate"
                      onBlur={(value) => {
                        if (!config.sso.certificatePresent) {
                          validateRequired('certificate', value);
                        }
                      }}
                      onChange={(value) => {
                        if (!config.sso.certificatePresent) {
                          validateRequired('certificate', value);
                        }
                        setConfig({
                          ...config,
                          sso: { ...config.sso, certificate: value },
                        });
                      }}
                    />
                    <a href="https://lib.socialchorus.com/sso/cert/saml.socialchorus.com.combined.crt">
                      <SVGIcon name="Download" />
                    </a>
                  </div>
                  {errors.certificate && (
                    <div className={styles.Error}>
                      <MAIcon name="warning" />
                      {errors.certificate}
                    </div>
                  )}
                </Subsection>
              </>
            )}
          </Section>
        )}
      </FormPage>
      {isConfirmOpen && (
        <ConfirmModal
          title="Changes to sign-in method"
          confirmLabel="Disable"
          onConfirm={submit}
          onCancel={() => {
            setIsConfirmOpen(false);
          }}
        >
          Disabling this sign-in method will automatically set the other sign-in
          method as the default method, and it will be the only method that
          users will be able to use to sign-in to the community.
        </ConfirmModal>
      )}
    </WithPermission>
  );
};
