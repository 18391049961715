import * as React from 'react';
import { LinkData } from 'models/publisher/block';
import { ImageData } from 'models/image';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Toast } from 'DesignSystem/Components/Toast';
import { useLinksSourceMenu } from '../hooks/useSourceMenu';
import { UrlInput } from '../../shared/UrlInput';

type PropsType = {
  initial?: LinkData;
  link: LinkData;
  onChange: (link: LinkData) => void;
  onUrlChange: (url: string) => void;
  hasChanges: boolean;
  setUrl: (url: string) => void;
  setImage: (image: ImageData | File) => void;
  error?: string;
  loading?: boolean;
  source: ReturnType<typeof useLinksSourceMenu>;
  reset: () => void;
};

export const External: React.FC<PropsType> = ({
  initial,
  onChange,
  onUrlChange,
  link,
  hasChanges,
  setUrl,
  loading,
  reset,
  error,
}) => {
  React.useEffect(() => {
    if (hasChanges)
      onChange({
        ...link,
        source: 'external',
      });
  }, [hasChanges, initial, link, onChange]);

  const handleUrlChange = React.useCallback(
    (url: string) => {
      if (url) {
        setUrl(url);
      } else {
        reset();
      }
      onUrlChange(url);
    },
    [onUrlChange, setUrl, reset]
  );

  const onReset = React.useCallback(() => {
    if (!loading) {
      reset();
    }
  }, [loading, reset]);

  const disabled = ![undefined, 'external'].includes(link.source);
  const label = disabled ? 'Campaign URL' : 'URL';
  const value = link ? link.requestedUrl || link.url : '';

  return (
    <Flex column start>
      {error && <Toast message={error} severity="warning" />}
      <UrlInput
        loading={loading}
        label={label}
        value={value}
        onReset={onReset}
        onChange={handleUrlChange}
      />
    </Flex>
  );
};
