import React, { FC, MouseEventHandler, useCallback, useContext } from 'react';
import { LowerBar } from 'shared/NavigationBars/FixedBars';
import { Link } from 'hooks/useFooter';
import { capitalizeWords } from 'utility/strings';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { NavLink } from 'shared/NavLink';
import * as Type from 'DesignSystem/Typography';
import { FormView, useTopicFormCtx } from '../../context';
import { BlockNavigationContext } from './contexts/blockNavigation';

interface Props {
  /* Navigate handler, return false to stop navigation or true to proceed */
  onNavigate?: (url: string) => boolean;
  /* Button text */
  ctaText?: string;
  /* Button url */
  ctaHref?: string;
  /* Button disabled */
  ctaDisabled?: boolean;
  /* Button click handler */
  onCtaClick?: () => void;
}

const Footer: FC<Props> = ({
  ctaText,
  ctaHref,
  ctaDisabled,
  onCtaClick,
  onNavigate,
}) => {
  const { baseUrl } = useTopicFormCtx();
  const { shouldBlockNavigation } = useContext(BlockNavigationContext);

  const viewSequence: FormView[] = ['design', 'settings', 'review'];

  const links: Link[] = viewSequence.map((view) => ({
    name: capitalizeWords(view),
    href: `${baseUrl}/${view}`,
    validateClick: () => (onNavigate ? onNavigate(`${baseUrl}/${view}`) : true),
  }));
  let nextButton = null;

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      if (shouldBlockNavigation()) {
        e.preventDefault();
        return;
      }
      onCtaClick?.();
    },
    [shouldBlockNavigation, onCtaClick]
  );

  if (ctaText) {
    nextButton = (
      <Button
        label={ctaText}
        href={ctaHref}
        disabled={ctaDisabled}
        onClick={onClick}
      />
    );
  }

  return (
    <LowerBar ariaLabel="Topic Form Lower">
      <Flex>
        {links.map((link) => (
          <div key={link.href}>
            <NavLink
              disabled={link.disabled}
              to={link.href}
              validateClick={link.validateClick}
            >
              <Type.PageHeading block={false}>&bull;</Type.PageHeading>
              <Type.Subheading>{link.name}</Type.Subheading>
            </NavLink>
          </div>
        ))}
      </Flex>
      <Flex>{nextButton}</Flex>
    </LowerBar>
  );
};

export default Footer;
