import React from 'react';
import { NewFilterButton } from 'DesignSystem/Table/NewFilterButton';
import { BooleanFilter } from 'contexts/people/filters';
import NestableMenu from 'shared/NestableMenu';
import { NestableMenuType } from 'shared/NestableMenu/types';

/*
 * This button will display a NestableMenu containing all available filters for a report
 * Currently it will display everything in a single flat list
 * Eventually we will display user attribute filters in a nested submenu
 * */
type PropsType = {
  filters: BooleanFilter[];
  onSelectFilter: (filter: BooleanFilter) => void;
};
export const NewFiltersButton: React.FC<PropsType> = ({
  filters,
  onSelectFilter,
}) => {
  const rootMenuData: NestableMenuType<BooleanFilter> = React.useMemo(() => {
    return {
      simpleMenu: {
        onSelect: onSelectFilter,
        items: filters.map((filter) => {
          return { label: filter.label, key: filter.name, value: filter };
        }),
      },
    };
  }, [filters, onSelectFilter]);

  const menuButton = React.useMemo(
    () => (
      <div>
        {/* the nestable menu insists on this for an undetermined reason */}
        <NewFilterButton />
      </div>
    ),
    []
  );

  return <NestableMenu menuButton={menuButton} rootMenuData={rootMenuData} />;
};
