import React, { useCallback } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { Button, styles as formStyles } from 'DesignSystem/Form';
import { Text } from 'DesignSystem/Typography';
import { useImageUploader } from 'hooks/useImageUploader';
import { ImageData } from 'models/image';
import { FileDropZone } from 'shared/FileDropZone';
import { FileUploadButton } from 'shared/FileUploadButton';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MAIcon } from 'shared/MAIcon';
import styles from './styles.module.css';

type ImageDropzoneInputProps = {
  imageSrc: string | null | undefined;
  onChange: (val: { url: string } | null) => void;
};

export function ImageDropzoneInput({
  imageSrc,
  onChange,
}: ImageDropzoneInputProps): React.ReactElement {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const uploader = useImageUploader({
    onUpload: (data: ImageData) => data.url && onChange({ url: data.url }),
    programId,
    onError: (message) => setFlashMessage({ message, severity: 'error' }),
  });

  const handleFileChange = useCallback<(file: File) => void>(
    (file) => {
      uploader.update(file);
    },
    [uploader]
  );

  const InnerContent = !imageSrc ? (
    <div className={styles.Dropzone}>
      <section className={styles.Dropzone__Content}>
        <Text
          as="p"
          className={{
            Subheading: true,
            Semibold: true,
          }}
          style={{
            margin: 0,
          }}
        >
          Drop file to upload
        </Text>
        <Text
          as="p"
          className={{
            Subheading: true,
          }}
          style={{
            margin: 0,
          }}
        >
          or
        </Text>
        <FileUploadButton
          accept="image/*"
          type="blank"
          onFileSelect={handleFileChange}
          className={formStyles.Button}
        >
          Select File
        </FileUploadButton>
      </section>
      <Text
        as="span"
        className={{
          Caption: true,
          gray60: true,
        }}
      >
        Maximum upload file size: 10 MB.
      </Text>
    </div>
  ) : (
    <div className={styles.Preview__Container}>
      <img src={imageSrc || ''} alt="uploaded" className={styles.Preview} />
      {imageSrc && (
        <div className={styles.Actions}>
          <FileUploadButton
            accept="image/*"
            type="blank"
            compact
            onFileSelect={handleFileChange}
            className={styles.ReplaceButton}
          >
            Replace
          </FileUploadButton>
          <Button
            icon={<MAIcon name="delete" />}
            secondary
            borderless
            className={styles.DeleteButton}
            onClick={() => onChange(null)}
          />
        </div>
      )}
    </div>
  );

  return (
    <div style={{ position: 'relative' }}>
      <FileDropZone
        dropLabel="Drop to upload file"
        onFileSelect={handleFileChange}
        withButton={false}
        disabled={uploader.isUploading}
        accept="image/*"
      >
        {uploader.isUploading ? (
          <div className={styles.Loading__Container}>
            <LoadingSpinner />
          </div>
        ) : (
          InnerContent
        )}
      </FileDropZone>
    </div>
  );
}
