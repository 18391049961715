import React, { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Audience } from 'models/audience';
import { ClickDropdown } from 'shared/ClickDropdown';
import cx from 'classnames';
import { useAudiencesInfiniteQuery } from 'hooks/audience';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import styles from 'components/content/ContentFilterBar/filters.module.css';

export const AudiencesFilter: React.FC<{
  value: Array<Audience>;
  onChange: (value: Array<Audience>) => void;
  onClose?: () => void;
}> = ({ value, onChange, onClose }) => {
  const { id: programId } = useProgram();

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAudiencesInfiniteQuery({
    programId,
  });

  const audiencesByRowId = useMemo(() => {
    const map: { [key: string]: Audience } = {};

    data.forEach((audience) => {
      map[audience.name] = audience;
    });
    value.forEach((audience) => {
      if (!map[audience.name]) map[audience.name] = audience;
    });
    return map;
  }, [data, value]);

  const rowIds = Object.keys(audiencesByRowId);
  const selectedIds = value.map(({ name }) => name);

  const renderRow = React.useCallback(
    (rowId: string) => {
      const item = audiencesByRowId[rowId];
      return item ? (
        <div className={cx(styles.title, styles.filterTitle)}>
          <span>{item.title}</span>
        </div>
      ) : null;
    },
    [audiencesByRowId]
  );

  const onSelectedIdsChange = useCallback(
    (ids: Array<string>) => onChange(ids.map((id) => audiencesByRowId[id])),
    [onChange, audiencesByRowId]
  );

  const selectedValues = React.useMemo(
    () => selectedIds.map((id) => audiencesByRowId[id].title),
    [audiencesByRowId, selectedIds]
  );

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={300}
        itemHeight={32}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        itemClassName="filter-item"
        checkboxClassName={styles.filterCheckbox}
        hasClearSearchButton
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [
    renderRow,
    rowIds,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    onSelectedIdsChange,
    selectedIds,
  ]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
      <TriggerButton
        name="Audiences"
        values={selectedValues}
        onClose={onClose}
      />
    </ClickDropdown>
  );
};
