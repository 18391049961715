import { reassignStepEdge } from 'App/Program/Main/Journey/JourneyCanvas/utils/graph';
import {
  Journey,
  DecisionStep,
  Step,
  CommunicationStep,
  DelayStep,
  DecisionEdge,
} from 'models/journeys/journey';
import type { JourneyActionFor } from './journey-reducer';
import { mustFindDraftStep } from './steps';

export function handleStepDeleted(
  state: Journey,
  action: JourneyActionFor<'step/deleted'>
): Journey {
  const step = mustFindDraftStep(state, action.stepId);
  if (step.type === 'decision') {
    return deleteDecisionStep(state, step);
  }
  if (step.type === 'communication' || step.type === 'delay') {
    return deleteNonDecisionStep(state, step);
  }

  return state;
}
export function handleStepDecisionOptionDeleted(
  state: Journey,
  action: JourneyActionFor<'step/decision/deleted'>
): Journey {
  const step = mustFindDraftStep(state, action.stepId);
  if (step.type !== 'decision') {
    return state;
  }
  return deleteDecisionOption(state, step, action.edge);
}

function deleteDecisionOption(state: Journey, step: Step, edge: DecisionEdge) {
  const currentGraph = state.draftGraph;
  if (!currentGraph) {
    return state;
  }
  const nodeId = edge.targetId;
  const stepsToDelete: Array<string> = [];
  const next = step.next.filter(
    (s) => s.targetId !== edge.targetId
  ) as DecisionEdge[];
  const newDecisionStep = {
    ...step,
    next,
  };

  function findStepsToDelete(deletedEdgeId: string): void {
    if (currentGraph) {
      const deletedStep = currentGraph.steps.find(
        (s) => s.id === deletedEdgeId
      );
      if (deletedStep) {
        stepsToDelete.push(deletedStep.id);
        if (deletedStep.next) {
          deletedStep.next.forEach((s) => findStepsToDelete(s.targetId));
        }
      }
    }
  }

  findStepsToDelete(nodeId);

  return {
    ...state,
    draftGraph: {
      ...currentGraph,
      steps: currentGraph.steps
        .filter((s) => !stepsToDelete.includes(s.id))
        .map((s) => (s.id === newDecisionStep.id ? newDecisionStep : s)),
    },
  };
}

function deleteNonDecisionStep(
  state: Journey,
  step: CommunicationStep | DelayStep
) {
  const currentGraph = state.draftGraph;
  const stepBefore = currentGraph?.steps.find((s) =>
    s.next.map((n) => n.targetId).includes(step.id)
  );
  const stepAfterId = step.next.length === 1 && step.next[0].targetId;

  if (!stepBefore || !stepAfterId || !currentGraph) return state;

  const newStepBefore = reassignStepEdge(stepBefore, step.id, stepAfterId);

  const newSteps: Step[] = currentGraph.steps
    .filter((s) => s.id !== step.id)
    .map((s) => (s.id === stepBefore.id ? newStepBefore : s));

  const newRootStepId =
    currentGraph.rootStepId === step.id ? stepAfterId : currentGraph.rootStepId;

  return {
    ...state,
    draftGraph: {
      ...currentGraph,
      steps: newSteps,
      rootStepId: newRootStepId,
    },
  };
}

function getAllChildrenIds(state: Journey, st: Step): string[] {
  const toDelete = st.next.flatMap((n) => {
    const sp = state.draftGraph?.steps.find((s) => s.id === n.targetId);
    if (!sp) return [];
    return getAllChildrenIds(state, sp);
  });

  return [st.id].concat(toDelete);
}

function deleteDecisionStep(state: Journey, step: DecisionStep) {
  const { draftGraph } = state;
  const currentSteps = state.draftGraph?.steps;

  if (!draftGraph || !currentSteps) {
    return state;
  }
  const stepBefore = currentSteps.find((s) =>
    s.next.map((n) => n.targetId).includes(step.id)
  );

  if (!stepBefore) return state;

  const defaultPathEdge = step.next[step.next.length - 1];
  const allOtherPathEdges = step.next.slice(0, -1);

  const childIds = allOtherPathEdges
    .map(({ targetId }) => currentSteps.find((s) => s.id === targetId))
    .filter((s): s is Step => s !== undefined)
    .flatMap((st) => getAllChildrenIds(state, st));

  const newStepBefore = reassignStepEdge(
    stepBefore,
    step.id,
    defaultPathEdge.targetId
  );

  const newSteps = draftGraph.steps
    .filter((s) => s.id !== step.id && !childIds.includes(s.id))
    .map((s) => (s.id === stepBefore.id ? newStepBefore : s));

  const newRootStepId =
    draftGraph.rootStepId === step.id
      ? defaultPathEdge.targetId
      : draftGraph.rootStepId;

  return {
    ...state,
    draftGraph: {
      ...draftGraph,
      steps: newSteps,
      rootStepId: newRootStepId,
    },
  };
}
