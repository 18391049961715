import { Tooltip } from 'DesignSystem/Components';
import * as React from 'react';
import { MAIcon } from 'shared/MAIcon';
import styles from './navigation-bars.module.css';

export const CloseButton: React.FC<{ handleClose(): void }> = ({
  handleClose,
}) => (
  <Tooltip content="Close">
    <div
      className={styles.Button}
      role="button"
      onKeyPress={(e) => {
        if (['Enter', ' '].includes(e.key)) handleClose();
      }}
      tabIndex={0}
      onClick={handleClose}
    >
      <MAIcon name="close" />
    </div>
  </Tooltip>
);
