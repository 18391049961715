import { LinkData, SocialData } from 'models/publisher/block';
import * as React from 'react';
import * as Dnd from 'react-beautiful-dnd';

type PropsType = {
  links: Array<LinkData | SocialData>;
  onDrop: (links: Array<LinkData | SocialData>) => void;
};

export const DnDContext: React.FC<PropsType> = ({
  children,
  links,
  onDrop,
}) => {
  const move = React.useCallback(
    (result: Dnd.DropResult) => {
      if (!result.destination) return;
      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      const others = [
        ...links.slice(0, fromIndex),
        ...links.slice(fromIndex + 1),
      ];
      onDrop([
        ...others.slice(0, toIndex),
        links[fromIndex],
        ...others.slice(toIndex),
      ]);
    },
    [links, onDrop]
  );

  const dragEnd = React.useCallback((result: Dnd.DropResult) => move(result), [
    move,
  ]);

  return (
    <Dnd.DragDropContext onDragEnd={dragEnd}>{children}</Dnd.DragDropContext>
  );
};
