import React from 'react';
import { DefaultAvatar } from './icons';

type AvatarProps = {
  width: number;
  height: number;
  src?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
};

export function Avatar({
  width,
  height,
  src,
  alt,
  className,
  style,
}: AvatarProps): React.ReactElement {
  return src ? (
    <img
      src={src}
      alt={alt ?? ''}
      width={width}
      height={height}
      style={{
        aspectRatio: '1',
        objectFit: 'cover',
        borderRadius: '50%',
        ...style,
      }}
      className={className}
    />
  ) : (
    <DefaultAvatar
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '50%',
        ...style,
      }}
      className={className}
    />
  );
}

Avatar.defaultProps = {
  src: undefined,
  alt: undefined,
  className: undefined,
  style: undefined,
};
