import React from 'react';
import cx from 'classnames';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import styles from './activities.module.css';

type PropsType = {
  sort: {
    name?: string;
    dir?: 'asc' | 'desc';
  };
  onSort: (name: string, dir: 'asc' | 'desc') => void;
};

export const ListHeaderBar: React.FC<PropsType> = ({ sort, onSort }) => {
  const onClick = (name: string) => {
    let dir: 'asc' | 'desc' = 'asc';
    if (sort.name === name && sort.dir === 'asc') {
      dir = 'desc';
    }
    onSort(name, dir);
  };
  return (
    <div className={styles.ListHeader}>
      <div>User Name</div>
      <div>Event</div>
      <div>
        <div
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          className={styles.Sortable}
          onClick={() => {
            onClick('date');
          }}
        >
          Date
          <span
            className={cx(styles.Icon, {
              [styles.Sorted]: sort.name === 'date',
            })}
          >
            {sort.name === 'date' && sort.dir === 'desc' ? (
              <SVGIcon name="ArrowDownward" />
            ) : (
              <SVGIcon name="ArrowUpward" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
