import { useState } from 'react';

type Props<Vals extends string[] = string[]> = {
  names: Vals;
  initial?: Vals[number];
};

export type OneOf<T extends string[] = string[]> = Record<
  T[number],
  {
    isActive: boolean;
    activate: () => void;
  }
>;

export function useOneOf<T extends string[] = string[]>(
  props: Props<T>
): OneOf<T> {
  const hasInitial = props.initial && props.names.includes(props.initial);
  const selected = hasInitial ? props.initial : props.names[0];
  const [chosen, setChosen] = useState(selected);
  return props.names.reduce(
    (map, name) => ({
      ...map,
      [name]: {
        isActive: name === chosen,
        activate: () => setChosen(name),
      },
    }),
    {} as OneOf
  );
}
