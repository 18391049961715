import { BulkSelectProvider } from 'contexts/bulk-select';
import * as React from 'react';
import { BulkSelectBar, BulkSelectBarPropType } from '../Bar';

type PropType = {
  totalRecords: number;
  disabled?: boolean;
  recordsToUpdate?: number;
  allowSelection?: boolean;
  clearOnFilterUpdate?: boolean;
} & BulkSelectBarPropType;

export const BulkSelectWrapper: React.FC<PropType> = (props) => {
  const {
    totalRecords,
    actions,
    recordsToUpdate,
    children,
    moreActions,
    itemName,
    setIsBulkSelectMode,
    disabled,
    allowSelection = true,
    clearOnFilterUpdate,
  } = props;

  if (disabled) return <>{children}</>;

  return (
    <BulkSelectProvider totalRecords={totalRecords}>
      <BulkSelectBar
        actions={actions}
        moreActions={moreActions}
        itemName={itemName}
        recordsToUpdate={recordsToUpdate}
        setIsBulkSelectMode={setIsBulkSelectMode}
        allowSelection={allowSelection}
        clearOnFilterUpdate={clearOnFilterUpdate}
      />
      {children}
    </BulkSelectProvider>
  );
};
