import React from 'react';
import { FilterDropdownStructureContext } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import styles from '../../Filters.module.css';

export const FilterDropdownFooter: React.FC = ({ children }) => {
  const { setElemFooter } = React.useContext(FilterDropdownStructureContext);

  return (
    <div ref={setElemFooter} className={styles.filterDropdownFooter}>
      {children}
    </div>
  );
};
