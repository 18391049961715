import React from 'react';
import { Button } from 'DesignSystem/Form';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';

type ClearSelectionButtonPropsType = {
  onClick: () => void;
  disabled?: boolean;
};

export const ClearSelectionButton: React.FC<ClearSelectionButtonPropsType> = ({
  onClick,
  disabled,
}) => {
  const { setShowReset } = React.useContext(ReportContext);
  const handleClick = () => {
    setShowReset(true);
    onClick();
  };
  return (
    <Button
      text
      compact
      disabled={disabled}
      onClick={handleClick}
      label="Clear"
    />
  );
};
