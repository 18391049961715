export function moveUpHighlight(props: {
  index: number;
  itemHighlightable: (index: number) => boolean;
}): number {
  const { index, itemHighlightable } = props;

  let result = index;

  do {
    result -= 1;
  } while (!itemHighlightable(result) && result >= 0);

  if (result < 0) {
    result = index;
  }

  return result;
}

export function moveDownHighlight(props: {
  index: number;
  itemHighlightable: (index: number) => boolean;
  itemCount: number;
}): number {
  const { index, itemHighlightable, itemCount } = props;

  let result = index;

  do {
    result += 1;
  } while (!itemHighlightable(result) && result < itemCount);

  if (result >= itemCount) {
    result = index;
  }

  return result;
}

export function firstHighlightableIndex(props: {
  itemHighlightable: (index: number) => boolean;
  itemCount: number;
}): number {
  const { itemHighlightable, itemCount } = props;

  let index = 0;
  while (index < itemCount && !itemHighlightable(index)) {
    index += 1;
  }
  if (index < itemCount && itemHighlightable(index)) {
    return index;
  }
  return -1;
}
