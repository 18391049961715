import { StylesConfig } from 'react-select';

export const selectStyles: StylesConfig = {
  control: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: 'var(--color-gray00)',
    borderColor: 'var(--color-gray20)',
    borderRadius: '4px',
    cursor: 'pointer',
    height: '32px',
    padding: '8px',
    minHeight: '32px !important',
  }),
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: 'var(--color-gray00)',
    padding: '5px',
    width: '100%',
    cursor: 'pointer',
    fontSize: 'var(--font-size-caption)',
  }),
  menuList: (provided: React.CSSProperties) => ({
    ...provided,
    maxHeight: '80px',
  }),
  option: (provided: React.CSSProperties) => ({
    ...provided,
    cursor: 'pointer',
  }),
  container: (provided: React.CSSProperties) => ({
    ...provided,
    minWidth: '75px',
    height: '32px',
  }),
  valueContainer: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 'var(--font-size-caption)',
    height: '16px',
    padding: '0',
    minWidth: '55px',
  }),
  indicatorsContainer: (provided: React.CSSProperties) => ({
    ...provided,
    height: '16px',
    width: '20px',
    padding: '0',
  }),
  singleValue: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '0',
    margin: '0',
    top: '45%',
  }),
  indicatorSeparator: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '0',
    margin: '0',
    display: 'none',
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '0',
    margin: '0',
  }),
};
