import { ImageData } from 'models/image';
import { stripHtml, extractSentences } from 'utility/text';
import { VARIABLES_RE } from 'utility/variablesRegex';
import {
  DataBlock,
  FieldType,
  getImageValue,
  hiddenFields,
  isImageFieldData,
  isLinkFieldData,
  isPollFieldData,
  isRichTextFieldData,
  isSimpleTextFieldData,
  isVideoFieldData,
  RenderingVariables,
} from './block';

const MAX_SUMMARY_LENGTH = 500;

export type CallToAction = {
  title: string;
  summary: string;
  useTextFromContent: boolean;
  preferCustom: boolean;
  customAvailable: boolean;
  customEnabled: boolean;
  altText: string;
  image?: ImageData;
};

export const defaultCallToAction: CallToAction = {
  title: '',
  summary: '',
  useTextFromContent: true,
  preferCustom: true,
  customAvailable: false,
  customEnabled: false,
  altText: '',
};

function extractImageDataFromField(
  field: FieldType,
  variables?: RenderingVariables
): ImageData[] {
  if (Array.isArray(field))
    return field.flatMap((f) => extractImageDataFromField(f, variables));
  if (isImageFieldData(field))
    return [
      {
        url: getImageValue(field, variables),
        imageId: field.image_id,
        altText: field.alt_text,
        processed: field.processed,
      },
    ];
  if (isVideoFieldData(field))
    return [
      {
        url: field.custom_preview_image_url || field.preview_image_url,
        altText: field.image_alt_text,
        processed: field.processed,
      },
    ];
  if (isLinkFieldData(field))
    return [
      {
        url: getImageValue(field.image),
        altText: field.image.alt_text,
        imageId: field.image.image_id,
        processed: field.image.processed,
      },
    ];
  return [];
}

function getFields(blocks: DataBlock[]): FieldType[] {
  return blocks.flatMap((block) => Object.values(block.field_data));
}

export function extractImages(
  blocks: DataBlock[],
  variables?: RenderingVariables
): ImageData[] {
  const fields = getFields(blocks);

  // Flatten the array of fields and extract ImageData from each field, passing the optional variables
  return fields.flatMap((field) => extractImageDataFromField(field, variables));
}

const baseCase = {
  title: (field: FieldType): string | undefined => {
    if (isSimpleTextFieldData(field) && field.value) return field.value;
    if (isPollFieldData(field) && field.question) return field.question;
    if (isLinkFieldData(field) && field.title?.value) return field.title.value;
    return undefined;
  },
  summary: (field: FieldType): string | undefined => {
    if (isRichTextFieldData(field) && field.value) return field.value;
    if (isLinkFieldData(field) && field.description?.value)
      return field.description.value;
    return undefined;
  },
};

// Look out, I'm recursive
export function getFirst(
  field: FieldType[] | FieldType,
  type: 'title' | 'summary'
): string | undefined {
  if (Array.isArray(field)) {
    let i = 0;
    while (i < field.length) {
      const val = getFirst(field[i], type);
      i += 1;
      if (val) {
        return val;
      }
    }
  } else return baseCase[type](field);

  return undefined;
}

export function filterEmailLinkBlock(blocks: DataBlock[]): DataBlock[] {
  return blocks.filter((block) => block.name !== 'email_link');
}

export function extractCoverTitle(blocks: DataBlock[]): string {
  const filteredBlocks = filterEmailLinkBlock(blocks);
  const fields = getFields(filteredBlocks);

  const firstBlockFlags = filteredBlocks[0]?.format_data?.flags as
    | string[]
    | undefined;
  const { hiddenTitle } = hiddenFields(firstBlockFlags);
  const text = hiddenTitle ? '' : getFirst(fields, 'title') || '';

  return stripHtml(text);
}

function extractPlainTextForSummary(blocks: DataBlock[]) {
  const filteredBlocks = filterEmailLinkBlock(blocks);
  const fields = getFields(filteredBlocks);
  const firstBlockFlags = filteredBlocks[0]?.format_data?.flags as
    | string[]
    | undefined;
  const { hiddenDescription } = hiddenFields(firstBlockFlags);
  const richText = hiddenDescription ? '' : getFirst(fields, 'summary') || '';

  return stripHtml(richText);
}

export function extractCoverSummary(blocks: DataBlock[]): string {
  const filteredBlocks = filterEmailLinkBlock(blocks);
  return extractPlainTextForSummary(filteredBlocks);
}

export function canSummaryHoldAllContentWithCustomCover(
  blocks: DataBlock[]
): boolean {
  const plainText = extractPlainTextForSummary(blocks);
  return plainText.length <= MAX_SUMMARY_LENGTH;
}

export function firstValidSentence(text: string): string {
  return extractSentences(text, { excludeVars: true })[0] || '';
}

export function extractCoverImages(blocks: DataBlock[]): ImageData[] {
  const images = extractImages(blocks);
  if (images.length > 0) return [images[0]];
  return [];
}

export function wrapVariables(text: string): string {
  return text.replace(VARIABLES_RE, '<span class="variable">{{$1}}</span>');
}
