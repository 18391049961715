import React from 'react';

export const AuthenticationError: React.FC<{ error: Error }> = ({ error }) => {
  // TODO: show an error page which is styled like IAM's error page, and which refreshes
  // automatically after a timeout.
  return (
    <>
      <p>{error.toString()}</p>
      <p>
        <a href="/">Try again</a>
      </p>
    </>
  );
};
