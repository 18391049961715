import * as React from 'react';
import { erasePost } from 'services/api-post';

type EraseContentProps = {
  programId: number;
  contentId: number;
};

export const useContentEraser = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}): {
  erase: (params: EraseContentProps) => void;
  isProcessing: boolean;
} => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const perform = ({ contentId, programId }: EraseContentProps) => {
    setIsProcessing(true);
    return erasePost({ programId, postId: contentId })
      .then(onSuccess)
      .catch(onError)
      .finally(() => setIsProcessing(false));
  };

  return { erase: perform, isProcessing };
};
