import React from 'react';
import { matchVariables } from './lib';
import { upKey, downKey, enterKey, escapeKey } from './event-lib';
import styles from './vti.module.css';

type Props = {
  onSelectVariable: (key: string) => void;
  onHide: () => void;
  variables: { key: string; description: string }[];
  matchingText?: string;
};

type State = Pick<Props, 'variables' | 'matchingText'> & {
  hoverIndex: number;
};

export class DropdownContainer extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, prevState: State): State {
    const state: State = {
      ...prevState,
      matchingText: props.matchingText,
      variables: props.variables,
    };
    // The reason matchingText is moved from the props to the state
    // is because this function is static and otherwise doesn't
    // have access to the previous set of props.
    //
    // This lets the hover index be reset when the matching text
    // changes.
    if (state.matchingText !== prevState.matchingText) {
      state.hoverIndex = -1;
    }
    return state;
  }

  private abort = false;

  constructor(props: Props) {
    super(props);
    this.state = DropdownContainer.getDerivedStateFromProps(props, {
      variables: [],
      hoverIndex: 0,
    });
    this.navigateVariables = this.navigateVariables.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('keydown', this.navigateVariables);
  }

  componentWillUnmount(): void {
    this.abort = true;
    window.removeEventListener('keydown', this.navigateVariables);
  }

  navigateVariables(event: KeyboardEvent): void {
    const { matchingText, variables, hoverIndex } = this.state;
    const { onHide, onSelectVariable } = this.props;
    if (!this.abort && matchingText) {
      const matches = matchVariables(variables, matchingText);
      if (upKey(event)) {
        this.setState({ hoverIndex: Math.max(0, hoverIndex - 1) });
      } else if (downKey(event)) {
        this.setState({
          hoverIndex: Math.min(matches.length - 1, hoverIndex + 1),
        });
      } else if (enterKey(event)) {
        onSelectVariable(variables[hoverIndex].key);
        onHide();
      } else if (escapeKey(event)) {
        // since the parent controls the matching text, and we determine
        // if it is visible or not via the matchign text, we kindly ask
        // the parent to hide us.
        onHide();
      }
    }
  }

  render(): JSX.Element | null {
    const { variables, matchingText, hoverIndex } = this.state;
    const { onSelectVariable } = this.props;
    const matches = matchVariables(variables, matchingText || '');
    return matchingText && matchingText.length && matches.length ? (
      <div style={{ position: 'relative' }}>
        <div className={styles.dropdown}>
          {matches.map((variable, i) => (
            <div
              role="option"
              aria-selected={i === hoverIndex}
              key={variable.key}
              tabIndex={0}
              className={i === hoverIndex ? 'hover' : ''}
              onClick={() => onSelectVariable(variable.key)}
              onKeyDown={() => {}}
              onFocus={() => {}}
              onMouseOver={() => this.setState({ hoverIndex: i })}
            >
              <var className={styles.variable}>{variable.key}</var>
              <div className={styles.description}>{variable.description}</div>
            </div>
          ))}
        </div>
      </div>
    ) : null;
  }
}
