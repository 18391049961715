import * as donkey from 'models/donkey';

export type Formatting = donkey.Formatting;
export type FormatData = donkey.FormatData;
export type FormatOptions = donkey.FormatOptions;

/* 🙈
 * We need to fix this from Donkey.
 *
 * In Donkey, `yarn export_types` only emits the declarations.
 * Enums are lost in the shuffle. We should address this by either:
 *
 *      (short term solution)
 *   1) Converting the enums to a Record with typed key and values
 *
 *      (long term solution)
 *   2) Exporting code along with types. This would also be useful for
 *      avoiding HTTP requests to Donkey, though that doesn't matter here.
 *
 * Until this addressed, you may notice these enums falling out of sync
 * with the ones in Donkey. We should take care to keep them in sync.
 * */

export enum Layout {
  FullWidth = '6',
  OneByFive = '1|5',
  TwoByFour = '2|4',
  ThreeByThree = '3|3',
  ThreeByThreeGrid = '3|3 Grid',
  ThreeByThreeList = '3|3 List',
  TwoByTwoByTwo = '2|2|2',
  FourByTwo = '4|2',
  Gallery = 'gallery',
  FullBleed = 'fullBleed',
}

export enum Alignment {
  ImageLeft = 'ImageLeft',
  ImageRight = 'ImageRight',
}

export enum AspectRatio {
  None = 'none',
  Auto = 'auto',
  Square = '1:1',
  Portrait = '3:4',
  ThreeByTwo = '3:2',
  Landscape = '2:1',
  Wide = '3:1',
  FullBleed = 'fullBleed',
}

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum VerticalAlignment {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum LinkTypes {
  Text = 'text',
  Button = 'button',
}
