import { useFlashMessage } from 'contexts/flasher';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Box } from 'DesignSystem/Components/Box';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';
import { Pill } from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import { Popover } from 'DesignSystem/Components/Popover';
import { Button, Input } from 'DesignSystem/Form';
import { Body, border, Caption, Subheading } from 'DesignSystem/Typography';
import {
  useCreateInitiative,
  useInitiativesInfiniteQuery,
} from 'hooks/initiatives';
import { Initiative } from 'models/initiative';
import React, { useEffect, useMemo, useState } from 'react';
import { InfiniteList } from 'shared/InfiniteList';
import { useFlashServerErrors } from 'utility/errors';
import { pluralize } from 'utility/text';
import cx from 'classnames';
import { SaveModalButtons } from '../../../Shared/SaveModalButtons';
import styles from './select-initiative.module.css';
import { InfoTooltip } from '../../../Shared/InfoTooltip';

interface SelectInitiativeProps {
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  setDismissable?: (dismissable: boolean) => void;
}

export const initiativeSort = (a: Initiative, b: Initiative): number =>
  a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;

export const SelectInitiative: React.FC<SelectInitiativeProps> = ({
  disabled,
  onCancel,
  onSave,
  setDismissable,
}) => {
  const {
    update,
    settings: { initiativeTags },
  } = useSettings();

  const {
    permissions: { initiativeAccess },
  } = usePermissions();

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const { id: programId } = useProgram();
  const [searchText, setSearchText] = React.useState('');
  const [selectedInitiatives, setSelectedInitiatives] = React.useState<
    Initiative[]
  >(initiativeTags);

  const infiniteInitiativesQuery = useInitiativesInfiniteQuery({
    programId,
    pageSize: 20,
    query: searchText,
    statuses: ['active'],
  });

  const filteredInitiatives = useMemo(() => {
    return infiniteInitiativesQuery.data
      .filter(
        (initiative) =>
          selectedInitiatives.find(
            (selected) => selected.id === initiative.id
          ) === undefined
      )
      .sort(initiativeSort);
  }, [infiniteInitiativesQuery.data, selectedInitiatives]);

  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();

  // disable dismiss if the save button has been enabled
  useEffect(() => {
    if (setDismissable) setDismissable(!saveEnabled);
  }, [setDismissable, saveEnabled]);

  const { create: createInitiative } = useCreateInitiative(programId, {
    onSuccess: (newInitiative) => {
      setFlashMessage({
        severity: 'info',
        message: 'Initiative created successfully',
      });
      setSelectedInitiatives([...selectedInitiatives, newInitiative]);
      setSaveEnabled(true);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not create initiative');
    },
  });

  const selectInitiativeDropdown = (dismiss: DismissType) => (
    <Box width={480}>
      <Popover centered>
        <InfiniteList
          itemCount={filteredInitiatives.length}
          itemHeight={40}
          height={200}
          highlightable
          isLoading={infiniteInitiativesQuery.isLoading}
          hasNextPage={infiniteInitiativesQuery.hasNextPage}
          fetchNextPage={infiniteInitiativesQuery.fetchNextPage}
          isFetchingNextPage={infiniteInitiativesQuery.isFetchingNextPage}
          overscan={10}
        >
          {(index: number) => {
            const initiative: Initiative = filteredInitiatives[index];
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                padding={[0, 8]}
                height={40}
                onClick={() => {
                  setSelectedInitiatives([...selectedInitiatives, initiative]);
                  setSaveEnabled(true);
                  dismiss();
                }}
              >
                <Pill
                  value={initiative}
                  render={(i) => i.name}
                  className={cx(styles.InitiativePill)}
                  singleLine
                />
                <Box>
                  <Caption>{`${initiative.postsCount} ${pluralize(
                    initiative.postsCount,
                    'post'
                  )}`}</Caption>
                </Box>
              </Box>
            );
          }}
        </InfiniteList>
        {initiativeAccess &&
          searchText.length > 0 &&
          !filteredInitiatives.some(
            (initiative) =>
              initiative.name.toLowerCase() === searchText.toLowerCase()
          ) && (
            <Box
              border={[border.solid, border.width1, border.gray10]}
              style={{
                display: 'flex',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: 'none',
              }}
            >
              <Button
                text
                block
                alignContent="left"
                className={styles.CreateButton}
                label={
                  <>
                    <Body>Create</Body>
                    <Pill
                      value={searchText}
                      render={(t) => t}
                      className={styles.CreatePill}
                    />
                  </>
                }
                onClick={() => {
                  createInitiative({
                    name: searchText,
                  });
                  dismiss();
                }}
              />
            </Box>
          )}
      </Popover>
    </Box>
  );

  return (
    <>
      <Box padding={[0, 16, 16, 0]}>
        <Subheading bold>
          Initiatives
          <InfoTooltip message="Add initiatives to tag campaigns for reporting." />
        </Subheading>
      </Box>
      <Box padding={[0, 16, 8, 0]}>
        <ClickDropdown
          dropdownRenderProp={selectInitiativeDropdown}
          disabled={disabled}
          placement="top"
          autoUpdate
          referencePress={false}
          onClose={() => {
            setSearchText('');
          }}
          ignoreKeys
        >
          <Input
            placeholder={
              initiativeAccess
                ? `Select initiatives or create your own`
                : `Select initiatives`
            }
            block
            value={searchText}
            onChange={setSearchText}
            maxCharacters={30}
            showCharacterCount
          />
        </ClickDropdown>
      </Box>
      <Box maxWidth={512} padding={[0, 16]}>
        <Pills
          values={selectedInitiatives.sort(initiativeSort)}
          render={(initiative) => initiative.name}
          onClose={(initiative: Initiative) => {
            const initiatives = selectedInitiatives.filter(
              (selectedInitiative: Initiative) =>
                selectedInitiative.id !== initiative.id
            );
            setSelectedInitiatives(initiatives);
            setSaveEnabled(true);
          }}
        />
      </Box>
      <SaveModalButtons
        onCancel={onCancel}
        onSave={() => {
          if (saveEnabled) {
            update({
              initiativeTags: selectedInitiatives,
            });

            if (onSave) {
              onSave();
            }
          }
        }}
        disabled={!saveEnabled}
      />
    </>
  );
};
