import * as React from 'react';
import cx from 'classnames';
import { deepMerge } from 'utility/deep-merge';
import { DefinitionBlock } from 'models/publisher/block';
import { FormatData } from 'models/publisher/format';
import { SidebarPopout } from '../SidebarPopout';
import { BlockIcons } from './arrangement-icons';
import { useMapCombos } from './useMapCombos';
import styles from './arrangement.module.css';

export const Arrangement: React.FC<{
  id: string;
  block: DefinitionBlock;
  onChange: (id: string, data: FormatData) => void;
}> = ({ id, block, onChange }) => {
  const changeFormatting = React.useCallback(
    (data: FormatData) => {
      onChange(id, deepMerge(block.format_data ?? {}, data));
    },
    [id, block, onChange]
  );
  const mapCombos = useMapCombos(block.format_options);
  return (
    <div className="kai-flex-row">
      <strong>Arrangement</strong>
      <SidebarPopout
        dataTestButton="arrangement-btn"
        buttonClassName={styles.icon}
        buttonChildren={
          <>
            <div />
            <div />
          </>
        }
        menuChildren={
          <div data-test="arrangement-menu">
            {mapCombos((data) => {
              const Icon =
                BlockIcons[block.name] &&
                BlockIcons[block.name][data.layout][data.alignment];
              const current =
                block.format_data?.layout === data.layout &&
                block.format_data?.alignment === data.alignment;
              return (
                Icon && (
                  <button
                    key={`${data.layout}--${data.alignment}`}
                    data-test="arrangement-item"
                    type="button"
                    className={styles.option}
                    onClick={() => {
                      changeFormatting(data);
                    }}
                  >
                    <Icon />
                    <div className={cx({ [styles.checked]: current })} />
                  </button>
                )
              );
            })}
          </div>
        }
      />
    </div>
  );
};

export function canEditArrangement(block: DefinitionBlock): boolean {
  return !!(
    BlockIcons[block.name] &&
    block.format_options?.alignments?.length &&
    block.format_options?.alignments?.length
  );
}
