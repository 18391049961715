export const languageDisplayName = (
  languageCode: string,
  displayNames?: Intl.DisplayNames
): string => {
  const languageNames =
    displayNames ??
    new Intl.DisplayNames(undefined, {
      type: 'language',
      languageDisplay: 'standard',
    });

  return languageNames.of(languageCode) || languageCode;
};
