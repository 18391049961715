import React from 'react';
import { Router, Redirect, RouteComponentProps } from '@reach/router';
import { Theme } from 'components/publisher/pages/Results/Theme';
import { ContentSummary } from 'components/publisher/pages/Results/ContentSummary';
import { Audience } from 'components/publisher/pages/Results/Report/Audience';
import { Publisher } from 'components/publisher/pages/Results/Report/Publisher';
import { Channel } from 'components/publisher/pages/Results/Report/Channel';
import { Campaign } from 'components/publisher/pages/Results/Report/Campaign';

export const Results: React.FC<RouteComponentProps> = () => (
  <Router>
    <Theme path="/">
      <ContentSummary path="content-summary" />
      <Audience path="audience-report" />
      <Publisher path="publisher-report" />
      <Channel path="channel-report" />
      <Campaign path="campaign-report" />
      <Redirect from="*" to="content-summary" noThrow default />
    </Theme>
  </Router>
);
