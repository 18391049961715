import * as React from 'react';
import { RefObject } from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { FontStylesheet } from 'models/library';
import { FontBanner } from './FontBanner';

type PropsType = {
  fonts: Array<FontStylesheet>;
  parentRef: RefObject<HTMLDivElement>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  errorMessage?: string;
  height: number;
  invalidateQuery?: () => void;
  customFontsEnabled?: boolean;
};

export const FontsList: React.FC<PropsType> = (props) => {
  const {
    fonts,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    parentRef,
    height,
    invalidateQuery,
    customFontsEnabled,
  } = props;

  const itemCount = fonts.length || 0;

  const fontsData = React.useMemo(() => {
    return (fonts || []).sort((a: FontStylesheet, b: FontStylesheet) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  }, [fonts]);

  return (
    <InfiniteBannerList
      itemCount={itemCount}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      height={height}
      parentRef={parentRef}
      noItemsComponent={<div>No Fonts match that criteria.</div>}
    >
      {(index: number) => (
        <FontBanner
          customFontsEnabled={customFontsEnabled}
          invalidateQuery={invalidateQuery}
          font={fontsData[index]}
        />
      )}
    </InfiniteBannerList>
  );
};
