export enum RestrictedFields {
  SEND_EMAIL,
  SEND_PUSH,
  SEND_TO_ASSISTANT,
  SET_CRITICAL,
  EMAIL_SENDER_ALIAS,
  SET_FEATURED,
}

export type FieldPermissions =
  | 'sendEmailAccess'
  | 'sendPushAccess'
  | 'sendAssistantAccess'
  | 'setAsCriticalAccess'
  | 'setFeatured';

export const RESTRICTED_FIELDS_MAP: {
  [K in FieldPermissions]: RestrictedFields[];
} = {
  sendEmailAccess: [
    RestrictedFields.SEND_EMAIL,
    RestrictedFields.EMAIL_SENDER_ALIAS,
  ],
  sendPushAccess: [RestrictedFields.SEND_PUSH],
  sendAssistantAccess: [RestrictedFields.SEND_TO_ASSISTANT],
  setAsCriticalAccess: [RestrictedFields.SET_CRITICAL],
  setFeatured: [RestrictedFields.SET_FEATURED],
};
