import { QueryResponse } from 'hooks/common';
import { useQuery } from 'react-query';
import {
  MetabaseReport,
  transformJsonToMetabaseReport,
} from 'models/insight/Report';
import {
  BookmarkedReport,
  transformJsonToBookmarkedReport,
} from 'models/insight/bookmarkedReport';
import {
  Collection,
  transformJsonToCollection,
} from 'models/insight/Collection';
import {
  ApiDataJson,
  ApiGetDataJson,
  ApiGetIndexDataJson,
} from 'models/insight/json/baseApiJson';
import {
  auditReportDownload,
  DojoFilterFetchProps,
  fetchBookmarkedCollection,
  fetchCustomCollections,
  fetchDojoFilterDetails,
  fetchOverviewReport,
  fetchRelatedReports,
  fetchReportDetails,
  fetchUpdatedMetabaseUrl,
  FilterStateWithSingleValuesObj,
  FilterValue,
  MetabaseEmbedUrlResponse,
  ProgramIdProp,
  ReportFetchProps,
} from 'services/api-insights';
import { Filter, transformJsonToFilter } from 'models/insight/Filter';

/*
 * GENERIC HOOK DECLARATIONS
 * */

// generic hook to get one resource
const useGetApiHook = <TProps, TJson, TModel>(
  props: TProps,
  cacheKey: string,
  fetchFn: (props: TProps) => Promise<ApiGetDataJson<TJson>>,
  transFn: (json: TJson) => TModel
): QueryResponse<TModel> => {
  const { isLoading, data, error } = useQuery<ApiGetDataJson<TJson>, Error>(
    [cacheKey],
    () => fetchFn(props),
    { retry: false }
  );

  const model = data?.data ? transFn(data.data.attributes) : undefined;

  return {
    isLoading,
    errorMessage: error?.message,
    data: model,
  };
};

// generic hook to get an index of resources
const useGetIndexApiHook = <TProps, TJson, TModel>(
  props: TProps,
  cacheKey: string,
  fetchFn: (props: TProps) => Promise<ApiGetIndexDataJson<TJson>>,
  transFn: (json: TJson) => TModel
): QueryResponse<TModel[]> => {
  const { isLoading, data, error } = useQuery<
    ApiGetIndexDataJson<TJson>,
    Error
  >([cacheKey], () => fetchFn(props), { retry: false });

  const models = data?.data
    ? data.data.map((d) => transFn(d.attributes))
    : undefined;

  return {
    isLoading,
    errorMessage: error?.message,
    data: models,
  };
};

// generic hook to get dojo resource
const useGetDojoApiHook = <TProps, TJson, TModel>(
  props: TProps,
  cacheKey: string,
  fetchFn: (props: TProps) => Promise<ApiDataJson<TJson>>,
  transFn: (json: TJson) => TModel
): QueryResponse<TModel> => {
  const { isLoading, data, error } = useQuery<ApiDataJson<TJson>, Error>(
    [cacheKey],
    () => fetchFn(props),
    { retry: false }
  );

  const model = data?.data ? transFn(data.data) : undefined;

  return {
    isLoading,
    errorMessage: error?.message,
    data: model,
  };
};

/*
 * API HOOKS
 * Be sure to explicitly call `JSON.stringify()` on props otherwise it will not properly serialize
 * and the `useQuery()` hook will not know to make new api requests when props change
 * */
export const useInsightsOverviewReport = (
  props: ProgramIdProp
): QueryResponse<MetabaseReport> => {
  return useGetApiHook(
    props,
    `insights:report:overview:${JSON.stringify(props)}`,
    fetchOverviewReport,
    transformJsonToMetabaseReport
  );
};

export const useMetabaseReport = (
  props: ReportFetchProps
): QueryResponse<MetabaseReport> => {
  return useGetApiHook(
    props,
    `insights:report:metabase:${JSON.stringify(props)}`,
    fetchReportDetails,
    transformJsonToMetabaseReport
  );
};

export const fetchEmbedUrl = (
  props: ReportFetchProps & { filterState?: FilterStateWithSingleValuesObj }
): Promise<MetabaseEmbedUrlResponse> => {
  // boolean values need to be converted to 'Y' and 'N' for some reason
  const modifiedFilterState: FilterStateWithSingleValuesObj = {};
  Object.keys(props.filterState || {}).forEach((attr) => {
    let value:
      | FilterValue[]
      | FilterValue
      | null
      | undefined = props?.filterState ? props.filterState[attr] : null;
    if (value === true) value = 'Y';
    else if (value === false) value = 'N';
    modifiedFilterState[attr] = value;
  });

  return fetchUpdatedMetabaseUrl({
    programId: props.programId,
    reportId: props.reportId,
    filterState: modifiedFilterState,
  });
};

export const auditMetabaseReportDownload = (props: string): Promise<void> =>
  auditReportDownload(props);

export const useDojoFilterDetails = (
  props: DojoFilterFetchProps
): QueryResponse<Filter> => {
  return useGetDojoApiHook(
    props,
    `insights:report:filter:${JSON.stringify(props)}`,
    fetchDojoFilterDetails,
    transformJsonToFilter
  );
};

export const useRelatedReports = (
  props: ReportFetchProps
): QueryResponse<MetabaseReport[]> => {
  return useGetIndexApiHook(
    props,
    `insights:report:related:${JSON.stringify(props)}`,
    fetchRelatedReports,
    transformJsonToMetabaseReport
  );
};

export const useBookmarkedCollection = (
  props: ProgramIdProp
): QueryResponse<BookmarkedReport[]> => {
  return useGetIndexApiHook(
    props,
    `insights:collection:bookmarked:${JSON.stringify(props)}`,
    fetchBookmarkedCollection,
    transformJsonToBookmarkedReport
  );
};

export const useCustomCollections = (
  props: ProgramIdProp
): QueryResponse<Collection[]> => {
  return useGetIndexApiHook(
    props,
    `insights:collections:custom:${JSON.stringify(props)}`,
    fetchCustomCollections,
    transformJsonToCollection
  );
};
