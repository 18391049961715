import { Audience } from 'models/audience';
import { Topic } from 'models/topic';

export const archivedAudiences = (value: Audience): Audience => {
  if (value.state === 'disabled') {
    return {
      ...value,
      title: `${value.title} (Archived)`,
    };
  }
  return value;
};

export const archivedTopics = (value: Topic): Topic => {
  if (value.archived) {
    return {
      ...value,
      name: `${value.name} (Archived)`,
    };
  }
  return value;
};
