import React, { useMemo } from 'react';
import { BigIconButton } from '@socialchorus/shared-ui-components';
import { Topic } from '../../../../../../../models/topic';
import { Section } from '../../../../../../../DesignSystem/Form';
import { LoadingSpinner } from '../../../../../../../shared/LoadingSpinner';
import styles from './styles.module.css';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { SearchInput } from '../../../../../../../shared/SearchInput';
import { useTopicShortcutsQuery } from '../../../../../../../hooks/topics';
import { DEFAULT_SHORTCUT_ICON } from '../../TopicForm/components/common/constants';
import NonEditablePlaceholder from '../../TopicForm/components/DesignView/components/NonEditablePlaceholder';
import { pluralize } from '../../../../../../../utility/text';

interface Props {
  topic: Topic;
}

export function TopicShotcuts({ topic }: Props): JSX.Element | null {
  const hasShortcutsTab = topic.landingPageTabs?.some(
    (tab) => tab.tabType === 'shortcuts' && !tab.isHidden
  );
  const [search, setSearch] = React.useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { isLoading, data } = useTopicShortcutsQuery(topic.programId, topic.id);
  const shortcutsCount = data?.length || 0;
  const filteredData = useMemo(() => {
    const searchQuery = debouncedSearch.toLowerCase().trim();
    if (!data) {
      return [];
    }
    if (!searchQuery) {
      return data;
    }
    return data?.filter((shortcut) => {
      const name = shortcut.name.toLowerCase();
      const description = (shortcut.description || '').toLowerCase();
      return name.includes(searchQuery) || description.includes(searchQuery);
    });
  }, [data, debouncedSearch]);

  if (!hasShortcutsTab) {
    return null;
  }

  let content;
  if (isLoading) {
    content = (
      <div className={styles.loadingPlaceholder}>
        <LoadingSpinner />
      </div>
    );
  } else if (!filteredData.length) {
    content = (
      <NonEditablePlaceholder
        title="No Shortcuts"
        description={
          search.trim()
            ? 'No shortcuts match your search query'
            : 'No shortcuts have been added to this topic'
        }
      />
    );
  } else {
    content = (
      <div className={styles.ShortcutsGrid}>
        {filteredData.map((shortcut) => (
          <BigIconButton
            key={shortcut.id}
            disabled
            className={styles.shortcut}
            imgSrc={shortcut?.iconUrl ?? DEFAULT_SHORTCUT_ICON}
            label={shortcut.name}
          />
        ))}
      </div>
    );
  }
  return (
    <Section title="Shortcuts" className={styles.topicPosts}>
      <div className={styles.searchWrapper}>
        <SearchInput
          panelClassName={styles.searchBar}
          placeholder="Search Shortcuts"
          onChange={setSearch}
          value={search}
        />
        <div className={styles.resultsCount}>
          {shortcutsCount} {pluralize(shortcutsCount, 'shortcut')}
        </div>
      </div>
      {content}
    </Section>
  );
}
