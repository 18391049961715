import React, { createContext, useContext } from 'react';

type JourneyTabContextData = {
  journeyTabState?: string;
  setJourneyTabState: (tabState?: string) => void;
};

const JourneyTabContext = createContext({
  setJourneyTabState: (() => {}) as (tabState?: string) => void,
});

export const useJourneyTabContext = (): JourneyTabContextData => {
  const context = useContext(JourneyTabContext);
  if (context === undefined) {
    throw new Error(
      'Journey tab context hooks require a containing JourneyTabProvider'
    );
  }
  return context;
};

export const useJourneyTabState = (): {
  journeyTabState?: string;
  setJourneyTabState: (tabState?: string) => void;
} => {
  const { journeyTabState, setJourneyTabState } = useJourneyTabContext();
  return { journeyTabState, setJourneyTabState };
};

export const JourneyTabProvider: React.FC<{
  journeyTabState?: string;
  setJourneyTabState: (tabState?: string) => void;
}> = ({ children, journeyTabState, setJourneyTabState }) => {
  const contextValue = {
    journeyTabState,
    setJourneyTabState,
  };
  return (
    <JourneyTabContext.Provider value={contextValue}>
      {children}
    </JourneyTabContext.Provider>
  );
};
