import React, { useState } from 'react';
import { Button, FormSection, FormSubsection } from 'DesignSystem/Form';
import { Label } from 'shared/Label';
import { Modal } from 'DesignSystem/Components';
import { Text } from 'DesignSystem/Typography';
import { MAIcon } from 'shared/MAIcon';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { ContentBannerHeader } from 'components/content/ContentBanner/ContentBanner';
import { Thumbnail } from 'shared/Thumbnail';
import {
  ContentFilterBar,
  ContentFilterFetchProps,
} from 'components/content/ContentFilterBar/ContentFilterBar';
import {
  ContentFiltersContext,
  ContentFiltersProvider,
  defaultState as defaultContentFiltersState,
} from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { FetchProps } from 'services/api-user';
import { useCustomSlugContext } from '../useCustomSlugContext';
import { CampaignList } from './CampaignList';
import styles from '../custom-slugs.module.css';
import modalStyles from './point-to-campaign-modal.module.css';

export const CampaignForm = (): JSX.Element => {
  const {
    selectedCampaign,
    toggleSelectedCampaignModal,
    showSelectedCampaignModal,
  } = useCustomSlugContext();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const defaultContentFilterState = defaultContentFiltersState({
    standard: {
      publicationState: {
        name: 'publication_state',
        field: 'publication_state',
        label: 'State',
        values: ['published'],
        isVisible: true,
      },
      contentTypes: {
        name: 'content_types',
        field: 'include_types',
        label: 'Content Type',
        states: ['article', 'link', 'note', 'image', 'video'],
        isVisible: true,
      },
    },
    boolean: {},
    date: {},
  });

  return (
    <div className={styles.campaignFormWrap}>
      <FormSection title="Point to Campaign">
        <FormSubsection title="">
          {!selectedCampaign ? (
            <>
              <div className={styles.campaignFormButton}>
                <Label
                  containerClassName={styles.campaignFormLabel}
                  name="Select the campaign you want the URL to point to"
                />
                <Button
                  onClick={() => {
                    toggleSelectedCampaignModal();
                  }}
                  label="Select a campaign"
                />
              </div>
            </>
          ) : (
            <SelectedCampaign />
          )}

          {showSelectedCampaignModal && (
            <ContentFiltersProvider
              defaultFiltersState={defaultContentFilterState}
            >
              <CampaignListModal parentRef={parentRef} />
            </ContentFiltersProvider>
          )}
        </FormSubsection>
      </FormSection>
    </div>
  );
};

function SelectedCampaign() {
  const {
    selectedCampaign,
    toggleSelectedCampaignModal,
    handleClearCampaign,
  } = useCustomSlugContext();

  if (!selectedCampaign) {
    return null;
  }

  return (
    <div className={styles.selectedCampaignContainer}>
      <div className={styles.selectedCampaignInfo}>
        <div className={styles.selectedCampaignIcon}>
          <Thumbnail
            title={selectedCampaign.title || ''}
            backgroundColor="#b8a0b6"
            imageUrl={selectedCampaign.imageUrl}
            size="large"
          />
        </div>
        <ContentBannerHeader
          width="100%"
          title={selectedCampaign.title || selectedCampaign.summary}
          publishedAt={selectedCampaign.publishedAt}
          displayName={selectedCampaign.contentAuthor.displayName}
        />
      </div>

      <div className={styles.selectedCampaignButtons}>
        <Button
          onClick={() => toggleSelectedCampaignModal()}
          secondary
          label="Replace"
        />
        <Button
          onClick={() => {
            handleClearCampaign();
          }}
          secondary
          borderless
          icon={<MAIcon name="close" />}
        />
      </div>
    </div>
  );
}

function CampaignListModal({
  parentRef,
}: {
  parentRef: React.RefObject<HTMLDivElement>;
}) {
  const { toggleSelectedCampaignModal } = useCustomSlugContext();
  const { id: programId } = useProgram();
  const { filters, setValue, setBooleanValue } = React.useContext(
    ContentFiltersContext
  );

  const [filterFetchProps, setFilterFetchProps] = useState<
    ContentFilterFetchProps
  >({});

  const defaultFetchProps = {
    sort: 'published_at',
    sortDirection: 'desc',
    pageSize: 20,
  };

  const fetchProps = {
    ...defaultFetchProps,
    ...useDebounce(filterFetchProps),
    programId,
  } as FetchProps;

  return (
    <Modal name="action-text" minPadding={0} innerPadding={0} width="80vw">
      <div className={modalStyles.container}>
        <div className={modalStyles.header}>
          <div className={modalStyles.modalTitle}>
            <Text
              className={{
                Heading: true,
                gray90: true,
                Semibold: true,
              }}
              as="h2"
            >
              Point URL to Campaign
            </Text>
            <Text
              as="p"
              className={{
                Body: true,
                gray60: true,
              }}
            >
              Select the campaign you want the URL to point to
            </Text>
          </div>
          <Button
            onClick={() => {
              toggleSelectedCampaignModal();
            }}
            secondary
            borderless
            icon={<MAIcon name="close" />}
          />
        </div>
        <div className={modalStyles.body}>
          <div className={modalStyles.contentFilterBar}>
            <ContentFilterBar
              fetchProps={filterFetchProps}
              onChange={setFilterFetchProps}
              filters={filters}
              setValue={setValue}
              setBooleanValue={setBooleanValue}
            />
          </div>
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <CampaignList
                height={height}
                parentRef={parentRef}
                fetchProps={fetchProps}
                filters={filters}
              />
            )}
          </InfiniteContainer>
        </div>
      </div>
    </Modal>
  );
}

CampaignForm.displayName = 'CampaignForm';
