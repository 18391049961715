import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Flex } from 'DesignSystem/Layout/Flex';
import { DragHandle } from 'shared/DragHandle';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { MAIcon } from 'shared/MAIcon';
import styles from './drag-drop-handle.module.css';

export const DragDropHandle: React.FC<{
  label: string;
  dragHandleProps: DraggableProvidedDragHandleProps;
  isVisible: boolean;
  hasBlockTargeting: boolean;
}> = ({ label, dragHandleProps, isVisible, hasBlockTargeting }) => {
  const blockTargetsEnabled = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.BlockTargets'
  ).data?.value;
  return (
    <div
      style={{ display: isVisible ? 'block' : 'none' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dragHandleProps}
      className={styles.dragDropHandle}
    >
      <Flex center>
        <DragHandle />
        <span style={{ marginLeft: 4 }}>{label}</span>
        {hasBlockTargeting && blockTargetsEnabled && (
          <span className={styles.visibility}>
            <MAIcon name="visibility_off" className={styles.icon} />
          </span>
        )}
      </Flex>
    </div>
  );
};
