import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';

import {
  LanguageControl,
  LanguageControlCreator,
  LanguageControlTypes,
} from 'models/language-controls/language-control';
import { User } from 'models/user';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';
import { fetchByIds } from './api-user';
import { Page } from './common';
import { fromJsonApiObject, toJsonApiObject } from './helpers/json-api';

export type QueryParameters = {
  programId: number;
  page?: number;
  pageSize?: number;
};

export type FetchLanguageControlsParams = QueryParameters & FilterParameters;

type FilterParameters = {
  search?: string;
  creatorIds?: number[];
  controlTypes?: LanguageControlTypes[];
  createdAtStart?: string;
  createdAtEnd?: string;
  updatedAtStart?: string;
  updatedAtEnd?: string;
};

export const fetchLanguageControls = async (
  params: FetchLanguageControlsParams
): Promise<Page<LanguageControl>> => {
  const { programId, ...queryParams } = params;
  const query = qs.stringify(snakecaseKeys(queryParams), {
    arrayFormat: 'comma',
  });

  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls?${query}`
  );

  if (response.status === 200) {
    const { meta, ...rest } = await response.json();
    return deepCamelcaseKeys({
      data: fromJsonApiObject<LanguageControl[]>(rest),
      meta: {
        ...meta,
        currentPage: meta.page.number,
      },
    });
  }

  throw new Error(`Error fetching language controls: ${response.status}`);
};

export const fetchLanguageControlsCreators = async (
  params: QueryParameters & { search?: string }
): Promise<Page<LanguageControlCreator>> => {
  const { programId, search, ...queryParams } = params;
  const query = qs.stringify(snakecaseKeys(queryParams));

  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls/creators?${query}`
  );

  if (response.status === 200) {
    const { data, meta } = await response.json();

    const usersResponse = await fetchByIds(data, programId);
    return deepCamelcaseKeys({
      data: usersResponse.data
        .map((user) => ({
          id: Number(user.id),
          displayName:
            (user as User).displayName || `${user.firstName} ${user.lastName}`,
        }))
        // A bit of a hack to support search term filtering.
        // Only the creator id exists in Pony. The creator names are then
        // fetched from Governor, and the filtering by name is done here.
        .filter(
          (user) =>
            !search ||
            user.displayName.toLowerCase().includes(search.toLowerCase())
        ),
      meta: {
        ...meta,
        currentPage: meta.page.number,
      },
    });
  }

  throw new Error(
    `Error fetching language control creators: ${response.status}`
  );
};

export type LanguageControlParams = {
  programId: number;
  id: number;
};

export const duplicateLanguageControl = async ({
  programId,
  id,
}: LanguageControlParams): Promise<LanguageControl> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls/${id}/duplicate`,
    {
      method: 'POST',
    }
  );

  if (response.status === 201) {
    const data = await response.json();
    return deepCamelcaseKeys(data);
  }

  throw new Error(`Error duplicating language control: ${response.status}`);
};

export const deleteLanguageControl = async ({
  programId,
  id,
}: LanguageControlParams): Promise<void> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls/${id}`,
    {
      method: 'DELETE',
    }
  );

  if (response.status !== 200) {
    throw new Error(`Error deleting language control: ${response.status}`);
  }
};

type Never<Type> = {
  [K in keyof Type]?: never;
};

export type BulkDeleteLanguageControlsParams = {
  programId: number;
} & (
  | (FilterParameters & { ids?: never })
  | ({ ids: number[] } & Never<FilterParameters>)
);

export const bulkDeleteLanguageControls = async ({
  programId,
  ...params
}: BulkDeleteLanguageControlsParams): Promise<void> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls/bulk_delete`,
    {
      method: 'POST',
      body: JSON.stringify(snakecaseKeys(params)),
    }
  );

  if (response.status !== 200) {
    throw new Error(`Error deleting language controls: ${response.status}`);
  }
};

export const fetchLanguageControl = async ({
  programId,
  id,
}: LanguageControlParams): Promise<LanguageControl> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls/${id}`
  );

  if (response.status === 200) {
    return fromJsonApiObject<LanguageControl>(await response.json());
  }

  throw new Error(`Error fetching language control: ${response.status}`);
};

export type CreateLanguageControlParams = Omit<
  UpdateLanguageControlParams,
  'id'
>;

export const createLanguageControl = async ({
  programId,
  ...languageControlParams
}: CreateLanguageControlParams): Promise<LanguageControl> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls`,
    {
      method: 'POST',
      body: JSON.stringify(toJsonApiObject(languageControlParams)),
    }
  );

  if (response.status === 201) {
    return fromJsonApiObject<LanguageControl>(await response.json());
  }

  throw new Error(`Error creating language control: ${response.status}`);
};

export type UpdateLanguageControlParams = Omit<
  LanguageControl,
  'creatorId' | 'createdAt'
>;

export const updateLanguageControl = async ({
  programId,
  id,
  ...languageControlParams
}: UpdateLanguageControlParams): Promise<LanguageControl> => {
  const response = await request(
    `${bossanovaDomain}/v2/programs/${programId}/language_controls/${id}`,
    {
      method: 'PUT',
      body: JSON.stringify(toJsonApiObject(languageControlParams)),
    }
  );

  if (response.status === 200) {
    return fromJsonApiObject<LanguageControl>(await response.json());
  }

  throw new Error(`Error updating language control: ${response.status}`);
};
