import * as React from 'react';
import cx from 'classnames';
import { Box } from '../Box';
import { Pill } from '../Pill';
import styles from './pills.module.css';

interface PillsProps<T extends { id?: string | number }> {
  values: Array<T>;
  render: (pill: T) => React.ReactNode;
  onClick?: (value: T) => void;
  onClose?: (value: T) => void;
  className?: string;
  pillClassName?: string | ((pill: T) => string);
  pillIcon?: (value: T) => React.ReactNode;
  padding?: number | number[];
  small?: boolean;
  dataTest?: string;
  valueTransformer?: (value: T) => T;
  closeButtonContents?: string;
  largeCloseButton?: boolean;
}

export function Pills<T extends { id?: string | number }>(
  props: React.PropsWithChildren<PillsProps<T>>
): JSX.Element {
  const {
    values,
    render,
    onClick,
    onClose,
    className,
    pillClassName,
    pillIcon,
    padding,
    small = false,
    dataTest,
    valueTransformer,
    closeButtonContents,
    largeCloseButton,
  } = props;
  return (
    <Box
      dataTest={dataTest}
      className={cx(styles.Pills, {
        [`${className}`]: !!className,
      })}
    >
      {values.map((value) => (
        <Pill<T>
          value={
            typeof valueTransformer === 'function'
              ? valueTransformer(value)
              : value
          }
          key={`pill-${value.id}`}
          render={render}
          onClose={onClose}
          onClick={onClick}
          icon={pillIcon ? pillIcon(value) : undefined}
          className={
            typeof pillClassName === 'function'
              ? pillClassName(value)
              : pillClassName
          }
          padding={padding}
          minHeight={small ? 26 : 32}
          closeButtonContents={closeButtonContents}
          largeCloseButton={largeCloseButton}
        />
      ))}
    </Box>
  );
}
