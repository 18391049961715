import { BulkUploadJob } from 'models/bulk-upload-job';

export const toDuration = (
  milliseconds: number
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  const millisecondsInDay = 60 * 60 * 24 * 1000;
  const days = Math.floor(milliseconds / millisecondsInDay);
  const remHoursInMilliseconds = milliseconds % millisecondsInDay;
  const hours = Math.floor(remHoursInMilliseconds / 3600000);
  const remTime = milliseconds % 3600000;
  const minutes = Math.floor(remTime / 60000);

  return {
    days,
    hours,
    minutes,
  };
};

/**
 *
 * @param startTime Date String
 * @param endTime Date String
 * @returns formatted string.
 */
export const generateCompletionString = (
  startTime: string,
  endTime: string
): string => {
  if (!endTime) return '';
  const timeDifference = Date.parse(endTime) - Date.parse(startTime);
  const { days, hours, minutes } = toDuration(timeDifference);
  return days
    ? `Processed in ${days}d ${hours}h ${minutes}m`
    : `Processed in ${hours}h ${minutes}m`;
};

export const generateStatString = (
  data: BulkUploadJob
): {
  description: string;
  processed: string;
} => {
  const { errors } = data.data;
  if (!data.data.stats) {
    return {
      description: `Failed - ${errors ? errors[0] : ''}`,
      processed: '-',
    };
  }

  const { imported, totalItems } = data.data.stats;
  let description = '';
  let processed = '';
  switch (data.status) {
    case 'failed':
      description = `Failed - ${errors ? errors[0] : ''}`;
      processed = `0 of ${totalItems}`;
      break;
    case 'partial':
      description = `Partially complete`;
      processed = `${imported || 0} of ${totalItems}`;
      break;
    case 'complete':
      description = 'Complete';
      processed = `${totalItems}`;
      break;
    default:
      description =
        imported && totalItems
          ? `Processing (${Math.round((imported / totalItems) * 100)}%)`
          : '';
      processed = `NA`;
  }
  if (!totalItems) processed = 'NA';

  return {
    description,
    processed,
  };
};
