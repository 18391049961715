import React, { useEffect, useMemo, useRef } from 'react';
import {
  axisBottom,
  axisLeft,
  AxisScale,
  interpolateSpectral,
  quantize,
  scaleBand,
  scaleLinear,
  ScaleOrdinal,
  scaleOrdinal,
  select,
} from 'd3';
import { ChartDimensions } from './Util';
import { NoDataChart } from './NoDataChart';

export type BarData = { label: string; value: number };
export type BarChartDimensions = ChartDimensions & {
  bars: {
    padding: {
      inner: number;
      outer: number;
    };
  };
};

export type CustomDrawingFunction = (
  bars: Array<BarData>,
  chartDimensions: BarChartDimensions,
  scaleX?: AxisScale<string> | AxisScale<number>,
  scaleY?: AxisScale<string> | AxisScale<number>,
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>
) => React.ReactFragment;

interface IBarChart {
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  data: Array<BarData>;
  customColors?: ScaleOrdinal<string, unknown>;
  customDrawing?: CustomDrawingFunction;
  chartDimensions?: BarChartDimensions;
}

export const BarChart: React.FC<IBarChart> = ({
  hideXAxis = false,
  hideYAxis = false,
  data,
  customColors,
  customDrawing,
  chartDimensions = {
    height: 480,
    width: 640,
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    bars: {
      padding: {
        inner: 0,
        outer: 0,
      },
    },
  },
}) => {
  const {
    padding,
    width,
    height,
    bars: { padding: barPadding },
  } = chartDimensions;

  const domain = useMemo(
    () => [0, Math.max(...data.map((d) => d.value), 1) * 1.1],
    [data]
  );

  const xAxis = useRef<SVGGElement>(null);
  const yAxis = useRef<SVGGElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const x = useMemo(
    () =>
      scaleBand(
        data.map((d) => d.label),
        [padding.left, width - padding.right]
      )
        .paddingInner(barPadding.inner)
        .paddingOuter(barPadding.outer),
    [
      barPadding.inner,
      barPadding.outer,
      data,
      padding.left,
      padding.right,
      width,
    ]
  );
  const y = useMemo(
    () => scaleLinear(domain, [height - padding.bottom, padding.top]),
    [domain, height, padding.bottom, padding.top]
  );

  useEffect(() => {
    if (xAxis.current === null) {
      return;
    }
    select(xAxis.current).call(axisBottom(x));
  }, [xAxis, x]);

  useEffect(() => {
    if (yAxis.current === null) {
      return;
    }
    select(yAxis.current).call(axisLeft(y));
  }, [yAxis, y]);

  const colors =
    customColors ??
    scaleOrdinal()
      .domain(data.map((d) => d.label))
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          data.length
        ).reverse()
      );

  const drawCustom = useMemo(() => {
    return customDrawing
      ? customDrawing(data, chartDimensions, x, y, colors)
      : undefined;
  }, [chartDimensions, colors, customDrawing, data, x, y]);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = select(svgRef.current);

    svg
      .selectAll('rect')
      .data(data)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('x', (d) => x(d.label) || 0)
            .attr('y', height - padding.bottom)
            .attr('width', x.bandwidth())
            .attr('height', 0)
            .attr('fill', (_, i) => `${colors(i as never)}` || '#ABABAB'),
        (update) => update,
        (exit) => exit.remove()
      )
      .transition()
      .duration(750)
      .attr('x', (d) => x(d.label) || 0)
      .attr('y', (d) => y(d.value) || 0)
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - padding.bottom - (y(d.value) || 0))
      .attr('fill', (_, i) => `${colors(i as never)}` || '#ABABAB');
  }, [data, x, y, colors, height, padding.bottom]);

  return data === undefined ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
      {!hideXAxis && (
        <g ref={xAxis} transform={`translate(0,${height - padding.bottom})`} />
      )}
      {!hideYAxis && (
        <g ref={yAxis} transform={`translate(${padding.left},0)`} />
      )}
      {drawCustom}
    </svg>
  );
};
