import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';

const TopicV2: FC<RouteComponentProps<{ id: string }>> = ({ children }) => {
  return (
    <WithPermission permissions={['confContentAccess', 'topicsAccess']}>
      {children}
    </WithPermission>
  );
};

export default TopicV2;
