import React, { useState } from 'react';
import filtersStyles from 'components/content/ContentFilterBar/filters.module.css';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { ClickDropdown } from 'shared/ClickDropdown';
import { Icon } from 'shared/Icon';
import { Close } from 'shared/icons';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useProgram } from 'contexts/program';

import { useTopicTagsQuery } from 'hooks/topic-tags';
import {
  statusOptions,
  statusOptionsWithDrafts,
  autoFollowOptions,
  userSubmittableOptions,
  autoPublishOptions,
  visibilityOptions,
} from './constants';
import styles from './topics.module.css';

type PropsType = {
  onChange: (filterName: string, values: string[]) => void;
  status?: string[];
  visibility?: string[];
  autoPublish?: string[];
  autoFollow?: string[];
  userSubmittable?: string[];
  topicTags?: string[];
};

type Filter = {
  title: string;
  iconName?: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onClose: () => void;
  multiSelect?: boolean;
};

const extendedActiveStatuses = [
  'active_without_draft',
  'active_with_draft',
  'active_draft',
];

const extendedArchivedStatuses = [
  'archived_without_draft',
  'archived_with_draft',
  'archived_draft',
];

const isStatusInSet = (statuses: string[]) => (s: string) =>
  statuses.includes(s);
const isExtendedActiveStatus = isStatusInSet(extendedActiveStatuses);
const isExtendedArchivedStatus = isStatusInSet(extendedArchivedStatuses);

export const Filters: React.FC<PropsType> = ({
  onChange,
  status,
  visibility,
  autoFollow,
  autoPublish,
  userSubmittable,
  topicTags,
}) => {
  const { id: programId } = useProgram();
  const [isOpen, setIsOpen] = useState(false);
  const menuItems: Filter[] = [
    {
      title: 'Visibility',
      iconName: visibility?.includes('Visibility') ? 'check' : '',
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
      },
      onClose: () => {
        onChange('visibility', []);
      },
      multiSelect: true,
    },
  ];

  const preSelectedVisibilityFilter = visibility?.length ? menuItems : [];
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>(
    preSelectedVisibilityFilter
  );

  const selectedFiltersTitles: string[] = selectedFilters.map(
    (filter: Filter) => filter.title
  );

  const unselectedFilters = menuItems.filter(
    (menuItem) => !selectedFiltersTitles.includes(menuItem.title)
  );

  const deselectFilter = (selectedFilter: Filter) => {
    setSelectedFilters((filters: Filter[]) =>
      filters.filter((f: Filter) => f.title !== selectedFilter.title)
    );
    selectedFilter.onClose();
  };

  const dropdownRenderProp = (dismiss: () => void) => (
    <div className={styles.moreButtonMenu}>
      {unselectedFilters.map((menuItem) => (
        <div className={styles.filtersMenuItem}>
          {/* eslint-disable-next-line */}
          <a
            onClick={(e) => {
              menuItem.onClick(e);
              setSelectedFilters(
                (filters: Filter[]) => [...filters, menuItem] as Filter[]
              );
              dismiss();
            }}
          >
            {menuItem.title}
          </a>
        </div>
      ))}
    </div>
  );

  const handleStatusChange = (values: string[]) => {
    let adjustedValues = values;

    const replaceStatuses = (statuses: string[], replacement: string) => {
      if (statuses.every((element) => values.includes(element))) {
        adjustedValues = adjustedValues.filter(
          (v) => !isStatusInSet(statuses)(v)
        );
        adjustedValues.push(replacement);
      }
    };
    // If all extended active statuses are selected, replace them with just 'active'
    replaceStatuses(extendedActiveStatuses, 'active');
    // If all extended archived statuses are selected, replace them with just 'archived'
    replaceStatuses(extendedArchivedStatuses, 'archived');
    onChange('status', adjustedValues);
  };

  const topicLandingV2Enabled = useFeatureFlagsQuery(
    programId,
    'EE.TopicLanding.V2.ConfigEnabled'
  ).data?.value;

  const filterStatusOptions = topicLandingV2Enabled
    ? statusOptionsWithDrafts
    : statusOptions;

  const { data: isTopicClassificationEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.ShowTopicClassification'
  );
  const {
    isLoading: isLoadingTopicTags,
    data: topicTagsList,
  } = useTopicTagsQuery({ programId });

  const topicTagsOptions =
    isLoadingTopicTags || !topicTagsList
      ? []
      : topicTagsList?.map((tag) => ({
          value: tag.id.toString(),
          label: tag.name,
        }));

  return (
    <>
      <FilterDropdown
        label="Status"
        options={filterStatusOptions}
        maxHeight={topicLandingV2Enabled ? 250 : undefined}
        onChange={(values) => handleStatusChange(values)}
        selectedOptions={filterStatusOptions.filter((opt) => {
          return (
            status?.includes(opt.value) ||
            // If 'active' is set, show all extended active statuses as selected
            (isExtendedActiveStatus(opt.value) && status?.includes('active')) ||
            // If 'archived' is set, show all extended archived statuses as selected
            (isExtendedArchivedStatus(opt.value) &&
              status?.includes('archived'))
          );
        })}
      />
      {isTopicClassificationEnabled?.value && (
        <FilterDropdown
          label="Classification"
          options={topicTagsOptions}
          onChange={(values) => onChange('topicTags', values)}
          selectedOptions={topicTagsOptions.filter((opt) =>
            topicTags?.includes(opt.value)
          )}
        />
      )}
      <FilterDropdown
        label="Auto-follow"
        options={autoFollowOptions}
        onChange={(values) => onChange('autoFollow', values)}
        selectedOptions={autoFollowOptions.filter((opt) =>
          autoFollow?.includes(opt.value)
        )}
      />
      <FilterDropdown
        label="Member Submissions"
        options={userSubmittableOptions}
        onChange={(values) => onChange('userSubmittable', values)}
        selectedOptions={userSubmittableOptions.filter((opt) =>
          userSubmittable?.includes(opt.value)
        )}
      />
      <FilterDropdown
        label="Auto-publish"
        options={autoPublishOptions}
        onChange={(values) => onChange('autoPublish', values)}
        selectedOptions={autoPublishOptions.filter((opt) =>
          autoPublish?.includes(opt.value)
        )}
      />
      <div className={styles.dropdownWrapper}>
        {selectedFilters.map((filter: Filter) =>
          filter.multiSelect ? (
            <FilterDropdown
              key={filter.title}
              label="Visibility"
              options={visibilityOptions}
              onChange={(values) => onChange('visibility', values)}
              selectedOptions={visibilityOptions.filter((opt) =>
                visibility?.includes(opt.value)
              )}
              onClose={() => deselectFilter(filter)}
            />
          ) : (
            <div key={filter.title} className={styles.filterTrigger}>
              <div>{filter.title}</div>
              {/* eslint-disable-next-line */}
              <button
                className={styles.closeButton}
                onClick={() => deselectFilter(filter)}
                type="button"
              >
                <Close />
              </button>
            </div>
          )
        )}
        {unselectedFilters.length > 0 && (
          <ClickDropdown
            dropdownRenderProp={dropdownRenderProp}
            onOpen={() => setIsOpen(true)}
            onDropdownClick={() => setIsOpen(false)}
            isOpen={isOpen}
          >
            <div role="button" className={filtersStyles.icon}>
              <Icon iconName="Plus" iconType="SVG" useCurrentColor />
            </div>
          </ClickDropdown>
        )}
      </div>
    </>
  );
};
