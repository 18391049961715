import { useState, useEffect, useCallback, useMemo } from 'react';
import { Topic } from 'models/topic';
import {
  postDetermineTopicSuggestions,
  getDeterminedTopicSuggestions,
  TopicSuggestionsResponseStatus,
  GetDeterminedTopicSuggestionsResponse,
  PostDetermineTopicSuggestionsResponse,
} from 'services/api-topic-suggestions';
import { useTopicsQuery } from './topics';

async function determineTopicSuggestions(
  programId: number,
  contentId: number
): Promise<PostDetermineTopicSuggestionsResponse> {
  try {
    return await postDetermineTopicSuggestions({ programId, contentId });
  } catch (error) {
    throw new Error('Error determining topic suggestions');
  }
}

async function fetchTopicSuggestions(
  programId: number,
  outputId: string,
  attempt = 0
): Promise<GetDeterminedTopicSuggestionsResponse> {
  const maxRetries = 60;
  const delay = 500;
  try {
    const response = await getDeterminedTopicSuggestions({
      programId,
      outputId,
    });

    if (
      response?.status === TopicSuggestionsResponseStatus.Complete ||
      response?.status === TopicSuggestionsResponseStatus.Failed
    ) {
      return response;
    }

    if (
      response?.status === TopicSuggestionsResponseStatus.Pending &&
      attempt < maxRetries - 1
    ) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      return fetchTopicSuggestions(programId, outputId, attempt + 1);
    }

    throw new Error('Max attempts reached');
  } catch (error) {
    throw new Error('Error fetching topic suggestions');
  }
}

export type UseTopicSuggestionsResponse = {
  suggestedTopics: Topic[] | null;
  isLoading: boolean;
  isError: boolean;
};

export const useSuggestedTopics = (
  programId: number,
  contentId: number
): UseTopicSuggestionsResponse => {
  const [suggestedTopicIds, setSuggestedTopicIds] = useState<number[] | null>(
    null
  );
  const [suggestedTopics, setSuggestedTopics] = useState<Topic[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleError = useCallback(() => {
    setIsError(true);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    async function handleFetchTopicSuggestions() {
      if (!programId || !contentId) return;

      try {
        const { outputId } = await determineTopicSuggestions(
          programId,
          contentId
        );

        if (outputId) {
          const { status, channelIds } = await fetchTopicSuggestions(
            programId,
            outputId
          );

          if (status === 'failed') {
            handleError();
          } else {
            setSuggestedTopicIds(channelIds);
          }
        } else {
          setSuggestedTopicIds([]);
        }
      } catch (error) {
        handleError();
      }
    }
    handleFetchTopicSuggestions();
  }, [programId, contentId, handleError]);

  const topicsQueryIds = useMemo(() => {
    return suggestedTopicIds && suggestedTopicIds.length > 0
      ? suggestedTopicIds
      : [0];
  }, [suggestedTopicIds]);

  const { data: topicsData } = useTopicsQuery({
    programId,
    ids: topicsQueryIds,
  });

  useEffect(() => {
    if (suggestedTopicIds && topicsData) {
      if (topicsData.length > 0) {
        setSuggestedTopics(topicsData);
      }
      setIsLoading(false);
    }
  }, [topicsData, suggestedTopicIds]);

  return {
    suggestedTopics,
    isLoading,
    isError,
  };
};
