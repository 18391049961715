import {
  PlaceHolderReport,
  transformJsonToPlaceholderReport,
} from 'models/insight/Report';
import { CollectionJson } from './json/collectionJson';

export type Collection = {
  id: number;
  description: string;
  reportCount: number;
  status: string;
  title: string;
  tags: string[];
  reports: PlaceHolderReport[];
};

export const transformJsonToCollection = (json: CollectionJson): Collection => {
  return {
    id: json.id,
    description: json.description,
    reportCount: json.report_count,
    status: json.status,
    tags: json.tags,
    title: json.title,
    reports: json.reports?.map(transformJsonToPlaceholderReport) || [],
  };
};
