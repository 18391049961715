import React from 'react';
import { useProgram } from 'contexts/program';
import { AbsoluteRange } from 'shared/DateRangeInput/DateRange';
import { FetchProps } from 'services/api-content';
import { getTotalHeight } from 'utility/dom';
import { useAdminPreferences } from 'contexts/admin-preferences';
import styles from './Week.module.css';
import { WeekDay } from './WeekDay';

type WeekCalendarProps = {
  dateRange: AbsoluteRange;
  fetchProps: FetchProps;
};

export const Week: React.FC<WeekCalendarProps> = ({
  dateRange,
  fetchProps,
}) => {
  const WEEK_WRAPPER_ID = 'week-wrapper-element';
  const days = Array.from(Array(7).keys());
  const { id: programId } = useProgram();
  const { adminPreferences } = useAdminPreferences();

  React.useEffect(() => {
    const el = document.getElementById(WEEK_WRAPPER_ID);
    if (el) {
      // 100 is 200 (card width) / 2
      // 65 is padding left.
      // We want to scroll the view to the 50% of Sunday
      el.scrollLeft = 165;
    }
  }, []);

  React.useEffect(() => {
    // week wrapper, header
    const ww = document.getElementById(WEEK_WRAPPER_ID);
    const hd = ww?.previousElementSibling;
    if (!ww || !hd) {
      if (ww) ww.style.height = `100%`;
      return;
    }
    const taken = getTotalHeight(hd);
    const height = document.body.clientHeight - taken;
    ww.style.height = `${height}px`;
  });

  React.useEffect(() => {
    const scroller = document.querySelector(`.${styles.scrollable}`);
    if (!scroller) return undefined;
    const handleScroll = () => {
      if (scroller.scrollTop) scroller.classList.add(styles.scrolled);
      else scroller.classList.remove(styles.scrolled);
    };
    scroller.addEventListener('scroll', handleScroll);
    return () => scroller.removeEventListener('scroll', handleScroll);
  }, []);

  if (adminPreferences && adminPreferences.hideWeekend) {
    days.shift();
    days.pop();
  }

  return (
    <div id={WEEK_WRAPPER_ID} className={styles.wrapper}>
      <div className={styles.scrollable}>
        <div className={styles.shadow} />
        <div className={styles.week}>
          {days.map((day: number) => {
            const { start } = dateRange;
            const date = start.plus({ days: day });
            return (
              <WeekDay
                fetchProps={fetchProps}
                programId={programId}
                key={`week-day-${date.toString()}`}
                date={date}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
