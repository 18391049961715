import React, { useCallback } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import styles from './filters.module.css';
import { TriggerButton } from './TriggerButton';

export const PublicationStateFilter: React.FC<{
  value: Array<string>;
  states: Array<string>;
  onChange: (value: Array<string>) => void;
  align?: 'left' | 'right' | 'center';
}> = ({ value, states, onChange, align = 'left' }) => {
  const renderRow = React.useCallback((text: string) => {
    return (
      <div>
        <span className={styles.filterTitle}>{text}</span>
      </div>
    );
  }, []);

  const onSelectedIdsChange = useCallback((ids: string[]) => onChange(ids), [
    onChange,
  ]);

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );
  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        rowIds={states}
        rowRenderProp={renderRow}
        maxHeight={400}
        itemHeight={32}
        selectedIds={value}
        onSelectedIdsChange={onSelectedIdsChange}
        className="no-search"
        itemClassName="filter-item"
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [onSelectedIdsChange, value, states, renderRow]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  return (
    <ClickDropdown
      dropdownRenderProp={dropdownRenderProp}
      dropdownClassName={`dropdown-align-${align}`}
    >
      <TriggerButton name="Status" values={value} />
    </ClickDropdown>
  );
};
