import React from 'react';
import { useNavigate } from '@reach/router';
import { DateTime } from 'luxon';
import { Icon } from 'shared/Icon';
import { useProgram } from 'contexts/program';
import { CSVDownloader } from 'react-papaparse';
import { useFlashMessage } from 'contexts/flasher';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { splitWords } from 'utility/text';
import { useGenerateErrorReport } from 'hooks/user-imports';
import {
  generateCompletionString,
  generateReportString,
} from '../../shared/helper';
import styles from './sidebar.module.css';

export const EmptySidebar: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.col}>
        <div>
          <span>Status</span>
          <span>Not yet created</span>
        </div>
        <div>
          <span>Records</span>
          <span>NA</span>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        <div className={styles.Disabled}>
          <span>
            <Icon iconName="Download" iconType="SVG" />
          </span>
          <span>Download rejected records</span>
        </div>
        <div className={styles.Disabled}>
          <span>
            <Icon iconName="ArrowNext" iconType="SVG" />
          </span>
          <span>Go to audience</span>
        </div>
      </div>
    </div>
  );
};

export const Sidebar: React.FC<{
  data: BulkUploadJob;
}> = ({ data }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const audienceId = data.primaryGroup?.id;

  const [csvRows, setCsvRows] = React.useState<string>('');
  const [file, setFile] = React.useState<{
    title: string;
    rows: string;
  }>({
    title: '',
    rows: '',
  });
  React.useEffect(() => {
    if (!csvRows) return;
    const trigger: HTMLButtonElement | null = document.querySelector(
      '.csv-trigger'
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    trigger!.click();
    setCsvRows('');
  }, [csvRows]);

  const { download: downloadErrorReport } = useGenerateErrorReport(programId, {
    onSuccess: (rows) => {
      setFile({
        title: `${data.fileName.replace('.csv', '')}-rejectedrows`,
        rows,
      });
      setCsvRows(rows);
    },
    onError: () => {
      setFlashMessage({
        message: 'Unable to download error file.',
        severity: 'error',
      });
    },
  });

  const handleNavigation = () => {
    if (audienceId)
      navigate(`/${programId}/app/people/audiences/${audienceId}`);
  };

  const handleReportDownload = (jobId: number) => {
    if (data.status === 'partial') {
      downloadErrorReport(jobId);
    }
  };

  const date = (d: string | undefined) => {
    return d ? DateTime.fromJSDate(new Date(d)) : undefined;
  };

  const hasNavigation = ['complete', 'partial'].includes(data.status);
  const hasRejections = data.status === 'partial';
  const createdAt = date(data.createdAt)?.toFormat('MMMM dd, yyyy');
  const completionString = generateCompletionString(data);
  const reportString = generateReportString(data);

  return (
    <div className={styles.wrapper}>
      <div className={styles.col}>
        <div>
          <span>Created</span>
          <span>{createdAt}</span>
        </div>
        <div>
          <span>Status</span>
          <span>{splitWords(data.status)}</span>
        </div>
        <div>
          <span>Duration</span>
          <span>{completionString}</span>
        </div>
        <div>
          <span>Records</span>
          <span>{reportString}</span>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        <div
          className={hasRejections ? styles.Active : styles.Disabled}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => handleReportDownload(Number(data.id))}
        >
          <span>
            <Icon iconName="Download" iconType="SVG" />
          </span>
          <span>Download rejected records</span>
        </div>
        <div
          className={hasNavigation ? styles.Active : styles.Disabled}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={handleNavigation}
        >
          <span>
            <Icon iconName="ArrowNext" iconType="SVG" />
          </span>
          <span>Go to audience</span>
        </div>
      </div>
      <CSVDownloader data={file.rows} filename={file.title} type="link">
        <button
          type="button"
          className="csv-trigger"
          style={{ visibility: 'hidden' }}
        >
          .
        </button>
      </CSVDownloader>
    </div>
  );
};
