import * as React from 'react';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { uniqueId } from 'hooks/useUniqueId';
import { empty } from 'hooks/useLinkFetcher';
import { ContentPicker } from 'shared/Library/ContentPicker';
import { LinkData } from 'models/publisher/block';

export const Library: React.FC<{
  onChange: (images: LinkData[]) => void;
  onClose: () => void;
  maxSelections: number;
}> = ({ onChange, onClose, maxSelections }) => {
  return (
    <SlideIn name="block-editor-link-library">
      {(slideOut) => (
        <ContentPicker
          maxSelections={maxSelections}
          onChange={(contents) => {
            onChange(
              contents.map((content) => ({
                url: content.asset.post_url,
                title: { type: 'plain-text', value: content.title } || '',
                description: {
                  type: 'plain-text',
                  value: content.description,
                },
                image: {
                  url: content.asset.image_url ?? '',
                  processed: true,
                },
                openInNewTab: true,
                callToAction: empty('link').callToAction,
                images: [],
                uuid: uniqueId(),
                source: 'library',
                type: 'link',
              }))
            );
            slideOut(onClose);
          }}
        />
      )}
    </SlideIn>
  );
};
