import { Position, Node, ReactFlowInstance } from 'reactflow';
import { Step } from 'models/journeys/journey';

const NODE_WIDTH = 300;
const DRAWER_PARTIAL_OPEN_CANVAS_FACTOR = 0.3;
const NAVBAR_HEIGHT = 48;

export const nodeFromStep = (step: Step): Node => {
  return {
    id: step.id,
    position: { x: 0, y: 0 },
    type: step.type,
    width: NODE_WIDTH,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    data: {},
  };
};

export const centerY = (
  flowInstance: ReactFlowInstance,
  nodeId: string
): void => {
  const viewport = flowInstance.getViewport();
  const canvasNode = flowInstance.getNode(nodeId);

  const yPos = canvasNode?.position.y;
  if (yPos === undefined) {
    return;
  }

  const canvasHeight =
    window.innerHeight * DRAWER_PARTIAL_OPEN_CANVAS_FACTOR - NAVBAR_HEIGHT;
  const initialY = -yPos * viewport.zoom;

  flowInstance.setViewport(
    {
      ...viewport,
      y: initialY + canvasHeight / 2,
    },
    { duration: 350 }
  );
};

export const centerX = (
  flowInstance: ReactFlowInstance,
  nodeId: string
): void => {
  const viewport = flowInstance.getViewport();
  const canvasNode = flowInstance.getNode(nodeId);

  const xPos = canvasNode?.position.x;
  if (xPos === undefined) {
    return;
  }

  const canvasWidth = window.innerWidth;
  const nodeWidth = NODE_WIDTH * viewport.zoom;
  const initialX = -xPos * viewport.zoom;

  flowInstance.setViewport(
    {
      ...viewport,
      x: initialX + (canvasWidth / 2 - nodeWidth / 2),
    },
    { duration: 350 }
  );
};

export const center = (
  flowInstance: ReactFlowInstance,
  nodeId: string
): void => {
  const viewport = flowInstance.getViewport();
  const canvasNode = flowInstance.getNode(nodeId);

  const xPos = canvasNode?.position.x;
  const yPos = canvasNode?.position.y;
  if (xPos === undefined || yPos === undefined) {
    return;
  }

  const canvasWidth = window.innerWidth;
  const nodeWidth = NODE_WIDTH * viewport.zoom;
  const initialX = -xPos * viewport.zoom;

  const canvasHeight =
    window.innerHeight * DRAWER_PARTIAL_OPEN_CANVAS_FACTOR - NAVBAR_HEIGHT;
  const initialY = -yPos * viewport.zoom;

  flowInstance.setViewport(
    {
      ...viewport,
      x: initialX + (canvasWidth / 2 - nodeWidth / 2),
      y: initialY + canvasHeight / 2,
    },
    { duration: 350 }
  );
};
