import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Caption, Body } from 'DesignSystem/Typography';
import { FlipSwitch } from 'shared/FlipSwitch';

export const CampaignSettingsCard: React.FC<{
  title: string;
  description?: string;
  disabled?: boolean;
  on?: boolean;
  onToggle: (value?: boolean) => void;
}> = ({
  title,
  description,
  children,
  disabled = false,
  on = false,
  onToggle,
}) => {
  return (
    <Box relative style={{ display: 'flex' }}>
      <Box margin={[0, 0, 0, 15]}>
        <Body block>
          {title}: <Body bold>{on ? 'ON' : 'OFF'}</Body>
        </Body>
        {description && <Caption block>{description}</Caption>}
        <Box absolute right={28} top={22}>
          <FlipSwitch disabled={disabled} onChange={onToggle} on={on} />
        </Box>
        {children}
      </Box>
    </Box>
  );
};
