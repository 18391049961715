import React from 'react';
import { Button } from 'DesignSystem/Form';
import { copyToClipboard } from 'utility/copy-to-clipboard';
import styles from 'App/Program/Main/Insight/pages/ReportPage.module.css';

export const ShareButton: React.FC<{ shareUrl: string }> = ({ shareUrl }) => {
  // const toastRef = React.useRef<HTMLElement>();
  const [animation, setAnimation] = React.useState(styles.hideToast);

  const handleShare = React.useCallback(
    (e: React.MouseEvent) => {
      setAnimation(styles.toastStyle);
      e.preventDefault();
      copyToClipboard(shareUrl);
      // toggleRecentlyClicked(true);
      setTimeout(() => setAnimation(styles.hideToast), 1000);
    },
    [shareUrl]
  );

  return (
    <div style={{ position: 'relative' }}>
      <Button
        text
        label={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Share this report</title>
            <path
              d="M16.2427 13.4143L19.0711 10.5859C20.6332 9.02377 20.6332 6.49111 19.0711 4.92901V4.92901C17.509 3.36692 14.9763 3.36692 13.4142 4.92901L10.5858 7.75744M13.4142 16.2427L10.5858 19.0711C9.02372 20.6332 6.49106 20.6332 4.92897 19.0711V19.0711C3.36687 17.5091 3.36687 14.9764 4.92897 13.4143L7.75739 10.5859M15.5356 8.46455L8.4645 15.5356"
              stroke="var(--color-gray90)"
              strokeWidth="2"
            />
          </svg>
        }
        onClick={handleShare}
      />
      <div className={animation}> Link Copied! </div>
    </div>
  );
};
