import React from 'react';
import { useProgram } from 'contexts/program';
import { NavigationFooter } from 'shared/NavigationFooter';

export const Footer: React.FC<{
  title: string;
}> = ({ title }) => {
  const { id: programId } = useProgram();
  const exitPath = `/${programId}/configure/bulk-updates`;
  const links = [
    {
      name: '',
      href: '.',
    },
  ];

  return (
    <NavigationFooter
      links={links}
      canPerformAction
      actionName="Save"
      action={() => {}}
      exitPath={exitPath}
      leftMenuItems={{}}
      title={title}
      hideMenu
      hideSave
      hideSubmit
      usePrevArrow
    />
  );
};
