import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { SearchBar } from 'shared/SearchBar';
import { Filters } from './Filters';

type PropsType = {
  onFilterChange: (filterName: string, values: string[] | undefined) => void;
  searchTerm?: string;
  setSearchTerm: (searchTerm: string) => void;
  statuses?: string[];
  types?: string[];
  users?: string[];
};

export const FiltersBar: React.FC<PropsType> = ({
  onFilterChange,
  searchTerm,
  setSearchTerm,
  statuses,
  types,
  users,
}) => {
  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search aliases"
        panelClassName={styles.searchBarWrapper}
      />
      <Filters
        onChange={onFilterChange}
        statuses={statuses}
        types={types}
        users={users}
      />
    </div>
  );
};
