import React from 'react';
import { useQueryClient } from 'react-query';
import { Collection } from 'models/insight/Collection';
import { useProgram } from 'contexts/program';
import { RouteComponentProps } from '@reach/router';
import { QueryResponse } from 'hooks/common';
import { BookmarkedReport } from 'models/insight/bookmarkedReport';
import { MetabaseReport } from 'models/insight/Report';
import { ProgramIdProp, auditReportDownload } from 'services/api-insights';
import {
  useBookmarkedCollection,
  useCustomCollections,
  useInsightsOverviewReport,
} from 'hooks/insights/useInsightsApi';

export type CollectionsContextType = {
  isLoadingAny: boolean;
  customCollectionsQuery: QueryResponse<Collection[]>;
  overviewCollectionQuery: QueryResponse<MetabaseReport>;
  bookmarkedCollectionQuery: QueryResponse<BookmarkedReport[]>;
  getCollection: (collectionId?: string) => Collection | undefined;
  invalidateBookmarks: (props: ProgramIdProp) => void;
  auditDownload: (token: string) => void;
};

const defaultState: CollectionsContextType = {
  customCollectionsQuery: {
    isLoading: false,
  },
  overviewCollectionQuery: {
    isLoading: false,
  },
  bookmarkedCollectionQuery: {
    isLoading: false,
  },
  getCollection: () => undefined,
  isLoadingAny: false,
  invalidateBookmarks: () => undefined,
  auditDownload: () => undefined,
};

export const CollectionsContext = React.createContext<CollectionsContextType>(
  defaultState
);

export const CollectionsProvider: React.FC<RouteComponentProps> = ({
  children,
}) => {
  // fetch the collections from api or from `useQuery()` internal cache
  const { id: programId } = useProgram();
  const customCollectionsQuery = useCustomCollections({ programId });
  const overviewCollectionQuery = useInsightsOverviewReport({ programId });
  const bookmarkedCollectionQuery = useBookmarkedCollection({
    programId,
  });
  const auditDownload = auditReportDownload;
  const queryClient = useQueryClient();
  const invalidateBookmarks = (props: ProgramIdProp) => {
    queryClient.invalidateQueries(
      `insights:collection:bookmarked:${JSON.stringify(props)}`
    );
  };

  if (customCollectionsQuery.data) {
    customCollectionsQuery.data = customCollectionsQuery.data.filter(
      ({ reports }) => reports.length
    );
  }

  const isLoadingAny =
    customCollectionsQuery?.isLoading ||
    overviewCollectionQuery?.isLoading ||
    bookmarkedCollectionQuery?.isLoading;
  /**
   * Retrieve a collection based on an id or a label
   * numeric ids should be fetched from custom collections
   * labels will fetch from bookmarks or overview
   * if additional labels are added then expand on the switch statement below
   * Note that "saved" and "bookmarked" are used interchangeably
   * */
  const getCollection = (collectionId?: string): Collection | undefined => {
    if (Number(collectionId)) {
      return customCollectionsQuery?.data?.find(
        (c) => c.id === Number(collectionId)
      );
    }
    return undefined;
  };
  return (
    <CollectionsContext.Provider
      value={{
        ...defaultState,
        customCollectionsQuery,
        overviewCollectionQuery,
        bookmarkedCollectionQuery,
        getCollection,
        isLoadingAny,
        invalidateBookmarks,
        auditDownload,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};
