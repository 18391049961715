import { Link } from '@reach/router';
import { UsersList } from 'App/Program/Main/People/UsersList';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { useUsersInfiniteQuery } from 'hooks/user';
import React, { useState } from 'react';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { SearchBar } from 'shared/SearchBar';
import { useAudienceUsersQuery, useExportAudience } from 'hooks/audience';
import { Audience } from 'models/audience';
import { UserExportData } from 'services/api-audiences';
import { useStickyResponse } from 'hooks/common';
import { useFlashMessage } from 'contexts/flasher';
import styles from './PreviewUsers.module.css';

type PropsType = {
  query: string;
  audience?: Audience;
};

export const PreviewUsers: React.FC<PropsType> = (props) => {
  const { audience, query } = props;
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useState('');
  const [userExportData, setUserExportData] = useState<UserExportData>();
  const search = useDebounce(searchTerm);
  const { setFlashMessage } = useFlashMessage();
  const {
    isLoading,
    data,
    meta,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
  } = useUsersInfiniteQuery({ programId, search, query, audienceQuery: true });

  const { isLoading: loading } = useStickyResponse(
    useAudienceUsersQuery(programId, query)
  );
  // eslint-disable-next-line
  const { data: isEmpty } = useStickyResponse({
    isLoading: loading,
    data: query === '',
  });
  const { exportAudience } = useExportAudience(audience, {
    onSuccess: (response) => {
      setUserExportData(response);
      setFlashMessage({
        message:
          'Your export is being prepared. The file will be available in Configure > Content - User Exports.',
        severity: 'info',
        children: (
          <Link to={`/${programId}/configure/exports`}>Exports Page</Link>
        ),
      });
    },
  });

  if (userExportData && userExportData.status === 'completed') {
    const url = userExportData.downloadUrl;
    setUserExportData(undefined);
    window.location.href = url;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search users"
          totalRecords={meta?.totalRecords}
          panelClassName={styles.searchBarPanel}
          labelClassName={styles.searchBarLabel}
          searchIconSize={20}
        />
        <button
          type="button"
          className={styles.exportButton}
          onClick={exportAudience}
        >
          <SVGIcon name="Export" size={20} />
          Export
        </button>
      </div>
      {errorMessage && <>{errorMessage}</>}
      {data && !errorMessage && (
        <UsersList
          users={data}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
    </>
  );
};
