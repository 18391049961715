import React from 'react';
import { Tag as BaseTag } from '@socialchorus/shared-ui-components';
import { DeliveryChannel } from '../../../../../../models/journeys/journey';
import { channelIconName, channelTitle } from './layout';
import styles from './readonly-communication-config.module.css';

const Tag: React.VFC<{ label: string; iconName?: string }> = ({
  label,
  iconName,
}) => (
  <BaseTag label={label} iconName={iconName} compact className={styles.tag} />
);

const ChannelTag: React.VFC<{ channel?: DeliveryChannel }> = ({ channel }) => {
  if (!channel) return null;

  return (
    <Tag label={channelTitle(channel)} iconName={channelIconName(channel)} />
  );
};

export { Tag, ChannelTag };
