import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useAudienceUsersQuery } from 'hooks/audience';
import {
  isActiveDeliveryThreshold,
  useProgramDeliveryThreshold,
} from 'hooks/campaign-settings';
import { useStickyResponse } from 'hooks/common';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { isDraft } from 'models/content';
import { Audience, combinedAudiencesQuery } from 'models/audience';
import { Post } from 'models/publisher/post';

export const usePublishConfirmed = (): {
  publishConfirmed: boolean;
  usersCount: number;
} => {
  const { id: programId } = useProgram();
  const deliveryThresholdFeatureEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.DeliveryThresholdEnabled'
  ).data?.value;

  const { deliveryThreshold } = useProgramDeliveryThreshold();
  const deliveryThresholdEnabled =
    deliveryThresholdFeatureEnabled &&
    isActiveDeliveryThreshold(deliveryThreshold);
  const { post } = usePublisher();
  const { audiences, oldAudiences, newAudiences } = postAudiences(post);

  const totalUsersCount = useAudienceUsers(audiences, programId);
  const oldUsersCount = useAudienceUsers(oldAudiences, programId);

  const overrideEnabled =
    !post.settings.optimizedDeliveryEnabled && post.settings.retries === 0;

  const usersCount = isDraft(post.content)
    ? totalUsersCount
    : totalUsersCount - oldUsersCount;
  const exceeding = usersCount >= Number(deliveryThreshold);
  const publishConfirmed =
    deliveryThresholdEnabled &&
    exceeding &&
    (isDraft(post.content)
      ? audiences.length !== 0
      : newAudiences.length !== 0 && !tooOld(post) && overrideEnabled);

  return {
    publishConfirmed,
    usersCount,
  };
};

const postAudiences = (
  post: Post
): {
  audiences: Audience[];
  oldAudiences: Audience[];
  newAudiences: Audience[];
} => {
  const { audiences } = post.settings;
  const oldAudiences = post.settings.audiencesCopy || [];
  const audienceIds = oldAudiences.map((a) => a.id);
  const newAudiences = audiences.filter((a) => !audienceIds.includes(a.id));
  return {
    audiences,
    oldAudiences,
    newAudiences,
  };
};

const tooOld = (post: Post): boolean => {
  if (!post.settings.publishedAt) return false;
  const postAgeDays =
    DateTime.now().diff(post.settings.publishedAt, 'days').toObject().days || 0;

  return postAgeDays > 1;
};

const useAudienceUsers = (
  audiences: Audience[],
  programId: number,
  retryCount = 2
): number => {
  const audiencesQuery = combinedAudiencesQuery(audiences);
  const { data: usersCountData } = useStickyResponse(
    useAudienceUsersQuery(programId, audiencesQuery, { retry: retryCount })
  );
  return usersCountData?.totalObjects || 0;
};
