import * as React from 'react';
import cx from 'classnames';
import {
  InfiniteList,
  InfiniteLoadProps as ImportedInfiniteLoadProps,
} from 'shared/InfiniteList';
import { SearchAndClearPane } from 'shared/SearchAndClearPane';
import styles from 'shared/forms/RadioSelectField/radio-select-field.module.css';

export type InfiniteLoadProps = ImportedInfiniteLoadProps;

export type DropdownProps = {
  selectedId: string;
  onSelectedIdChange: (newSelectedId: string) => void;
  searchEnabled?: boolean;
  searchTerm?: string;
  onSearchTermChange?: (value: string) => void;
  autofocus?: boolean;
  onHighlight?: (id: string) => void;
  itemClassName?: string;
};

type PropsType = {
  rowIds: Array<string>;
  rowRenderProp: (index: string) => React.ReactNode;
  maxHeight: number;
  itemHeight: number;
  className?: string;
  searchPlaceholder?: string;
} & DropdownProps &
  InfiniteLoadProps;

export const RadioSelectField: React.FC<PropsType> = (props) => {
  const {
    rowIds = [],
    rowRenderProp,
    maxHeight,
    itemHeight,
    className,
    // DropdownProps
    selectedId,
    onSelectedIdChange,
    searchEnabled,
    searchTerm,
    searchPlaceholder,
    onSearchTermChange,
    autofocus,
    onHighlight,
    itemClassName,
    // Infinite load props
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
  } = props;

  function handleChange(id: string) {
    onSelectedIdChange(id);
  }

  function handleSelect(index: number) {
    handleChange(rowIds[index]);
  }

  function handleHighlight(index: number) {
    if (onHighlight) {
      onHighlight(rowIds[index]);
    }
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <SearchAndClearPane
      maxHeight={maxHeight}
      childrenContentHeight={itemHeight * rowIds.length}
      searchEnabled={searchEnabled}
      searchTerm={searchTerm}
      searchPlaceholder={searchPlaceholder}
      onSearchTermChange={onSearchTermChange}
      className={className}
    >
      {(height) => (
        <InfiniteList
          itemCount={rowIds.length}
          itemHeight={itemHeight}
          height={height}
          highlightable
          onSelect={handleSelect}
          onHighlight={handleHighlight}
          autofocus={autofocus}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          threshold={threshold}
          overscan={overscan}
        >
          {(index) => {
            const id = rowIds[index];
            return (
              <div key={id} className={cx(styles.row, itemClassName)}>
                <div className={styles.rowCheckbox}>
                  <input
                    type="radio"
                    checked={selectedId === id}
                    onChange={() => handleChange(id)}
                    tabIndex={-1}
                  />
                </div>
                <div
                  className={styles.rowLabel}
                  onClick={() => handleChange(id)}
                >
                  {rowRenderProp(id)}
                </div>
              </div>
            );
          }}
        </InfiniteList>
      )}
    </SearchAndClearPane>
  );
};
