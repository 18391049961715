import React from 'react';
import Select from 'react-select';
import { useRolesInfiniteQuery } from 'hooks/roles';
import * as Text from 'DesignSystem/Typography';
import { useProgram } from 'contexts/program';
import { OptionType } from 'hooks/common';
import { LitmusRole } from 'models/role';

type PropsType = {
  onChange: (role: Partial<LitmusRole>) => void;
};
export const RoleSelector: React.FC<PropsType> = ({ onChange }) => {
  const { id: programId } = useProgram();
  const { data: roles } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });

  const options = roles.map((role) => {
    return { value: role.name, label: role.title };
  });
  options.push({ value: 'member', label: 'Member' });
  options.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
  });

  return (
    <Text.Body>
      <Select
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: '300px',
            marginTop: '16px',
          }),

          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: 'var(--color-gray90)',
          }),

          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: 'var(--color-gray90)',
          }),
        }}
        placeholder="Select role"
        options={options}
        onChange={(option) => {
          const opt = option as OptionType;
          onChange({ name: opt.value, title: opt.label });
        }}
      />
    </Text.Body>
  );
};
