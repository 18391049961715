import React from 'react';
import { DateTime } from 'luxon';
import { Post } from 'models/publisher/post';
import { ReactComponent as Time } from './Time.svg';
import styles from './publish.module.css';

export const Publish: React.FC<{
  post: Post;
}> = ({ post }) => {
  const isToday = (date: DateTime) => {
    const today = DateTime.now();
    return (
      date.day === today.day &&
      date.year === today.year &&
      date.month === today.month
    );
  };

  const { publishedAt } = post.settings;
  return publishedAt === undefined ? (
    <div className={styles.block}>
      <Time height="75" width="75" className={styles.timeIcon} />
      <h3 className={styles.text}>Publish now</h3>
    </div>
  ) : (
    <div className={styles.block}>
      <Time height="75" width="75" className={styles.timeIcon} />
      {isToday(publishedAt) ? (
        <h3 className={styles.text}>
          Publish today at {publishedAt.toFormat('hh:mm a')}
        </h3>
      ) : (
        <h3 className={styles.text}>
          Schedule for {publishedAt.toFormat('MMM d')} at{' '}
          {publishedAt.toFormat('hh:mm a')}
        </h3>
      )}
    </div>
  );
};
