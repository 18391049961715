import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import cx from 'classnames';

import { useMemo } from 'react';

import { InfoTooltip } from 'App/Program/Editors/Publisher/Deliver/Shared/InfoTooltip';
import styles from './metrics-item.module.css';

export type Stat = {
  type: string;
  value?: number | string;
};

export const MetricsItem: React.FC<{
  name: string;
  stat: Stat;
  isLoading?: boolean;
  highlight?: boolean;
  testId?: string;
  tooltip?: string | React.ReactElement;
  style?: React.CSSProperties;
}> = ({ name, isLoading, stat, highlight, testId, tooltip, style }) => {
  const formattedStat = useMemo(() => {
    if (isLoading) {
      return <span>...</span>;
    }

    if (!isLoading && stat.value === undefined) {
      return <span>N/A</span>;
    }

    if (!isLoading && stat.type === 'percent') {
      return <span>{stat.value}%</span>;
    }

    return <span>{stat.value}</span>;
  }, [stat, isLoading]);

  return (
    <Flex
      column
      spread
      className={cx(styles.metricsItem, {
        [styles.highlight]: highlight,
      })}
      style={style}
    >
      <Flex center>
        <div className={styles.itemName}>{name}</div>
        {tooltip && <InfoTooltip message={tooltip} />}
      </Flex>
      <div
        className={cx(styles.itemStats, {
          [styles.highlight]: highlight,
        })}
        data-testid={testId}
      >
        {formattedStat}
      </div>
    </Flex>
  );
};
