import { useEffect, useRef } from 'react';

export const useHasChanged = <T extends unknown>(
  value: T,
  compare?: (a?: T, b?: T) => boolean
): boolean => {
  const prevVal = usePrevious(value);

  return compare ? compare(value, prevVal) : prevVal !== value;
};

/**
 * Returns the previous value of the given value.
 *
 * @deprecated Use `usePrevious` from `hooks/use-previous` instead.
 * Relies on reading the previous value from a ref during render and a side effect
 * which is not recommended by React documentation.
 * @see https://react.dev/reference/react/useRef#caveats
 * @see https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
 */
export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
