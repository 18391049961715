import React from 'react';

import { useProgram } from 'contexts/program';
import {
  isReplaceLanguageControl,
  LanguageControl,
} from 'models/language-controls/language-control';
import {
  CreateLanguageControlParams,
  UpdateLanguageControlParams,
} from 'services/api-language-controls';
import {
  useCreateLanguageControlMutation,
  useUpdateLanguageControlMutation,
} from './useMutations';
import { useFetchLanguageControlQuery } from './useQueries';

type UseLanguageControls = (props: {
  id: 'new' | number;
}) => {
  languageControl: Partial<LanguageControl>;
  isLoading: boolean;
  isPersisting: boolean;
  isDisabled: boolean;
  onChange: (data: Partial<LanguageControl>) => void;
  save: (options?: {
    onSuccess?: (data: LanguageControl) => void;
    onError?: (error: Error) => void;
  }) => void;
};

export const useLanguageControls: UseLanguageControls = ({ id }) => {
  const { id: programId } = useProgram();

  const [languageControl, setLanguageControl] = React.useState<
    Partial<LanguageControl>
  >({
    programId,
  });

  const { isLoading, isFetching } = useFetchLanguageControlQuery(
    {
      programId,
      id: id === 'new' ? 0 : id,
    },
    {
      enabled: id !== 'new',
      onSuccess: setLanguageControl,
    }
  );

  const {
    mutate: createLanguageControl,
    isLoading: isCreating,
  } = useCreateLanguageControlMutation({
    onSuccess: setLanguageControl,
  });

  const {
    mutate: updateLanguageControl,
    isLoading: isUpdating,
  } = useUpdateLanguageControlMutation({
    onSuccess: setLanguageControl,
  });

  const isDisabled =
    !languageControl.controlType ||
    !languageControl.locale ||
    !languageControl.target ||
    (isReplaceLanguageControl(languageControl) && !languageControl.replacement);

  const handleSave = (options = {}) => {
    if (isDisabled) return;
    if (languageControl.id) {
      updateLanguageControl(
        languageControl as UpdateLanguageControlParams,
        options
      );
    } else {
      createLanguageControl(
        languageControl as CreateLanguageControlParams,
        options
      );
    }
  };

  return {
    languageControl,
    isLoading: isLoading || isFetching,
    isPersisting: isUpdating || isCreating,
    isDisabled,
    onChange: setLanguageControl,
    save: handleSave,
  };
};
