import React from 'react';
import { Box } from 'DesignSystem/Components';
import { ScheduleInsightByChannel } from 'models/publisher/orchestration-insights';
import { DateTime } from 'luxon';
import { getNumberSuffix } from 'utility/text';
import { Text } from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useNotificationCenterLabel } from 'hooks/notification-center';
import { SectionCard } from '../../SectionCard';
import styles from './daily-stats.module.css';

export const DailyStats: React.FC<{
  insights: ScheduleInsightByChannel[];
  publishedAt: string | undefined;
}> = ({ insights, publishedAt }) => {
  const notificationCenterLabel = useNotificationCenterLabel();
  const descriptorMap: { [key: string]: JSX.Element } = {
    'the post in the selected topics or the For You feed': (
      <>
        have access to this campaign in the <strong>selected topics</strong> or
        the <strong>For You feed</strong>
      </>
    ),
    'an Email': (
      <>
        be sent an <strong>email</strong>
      </>
    ),
    'an Assistant notification': (
      <>
        be sent a notification through
        <strong> {notificationCenterLabel}</strong>
      </>
    ),
    'a Push notification': (
      <>
        be sent a <strong>push notification</strong>
      </>
    ),
  };

  const formattedDate = (label: string) => {
    const [, daysCount] = label.split(' ');
    const publishDate = publishedAt
      ? DateTime.fromJSDate(new Date(publishedAt))
      : DateTime.now();
    const date = publishDate.plus({ day: parseInt(daysCount, 10) - 1 });

    return date.toFormat(`cccc LLLL d'${getNumberSuffix(date.day)}' kkkk`);
  };

  return (
    <div className={styles.StatsSection}>
      <Flex className={styles.InformationalMessage} start>
        <InformationIcon />
        <Text className={{ Caption: true }}>
          Estimated delivery dates are shown in your time zone. Recipients’
          delivery dates may differ depending on their time zone.
        </Text>
      </Flex>
      {insights.map((insight) => (
        <div key={insight.label} className={styles.SectionCard}>
          <SectionCard padding={[16, 24]}>
            <Box className={styles.StatsLine}>
              <div className={styles.DateContainer}>
                <strong>{insight.label}</strong> •{' '}
                {formattedDate(insight.label)}
              </div>

              <Box className={styles.PercentageStats}>
                {insight.items.map((item) => (
                  <div key={item.channelDescriptor} className={styles.StatLine}>
                    <strong>{item.percentage}%</strong> of users will{' '}
                    {descriptorMap[item.channelDescriptor]}
                  </div>
                ))}
              </Box>
            </Box>
          </SectionCard>
        </div>
      ))}
      {/* We may resurrect it later */}
      {/* <Box style={{ display: 'flex' }}>
        <Box className={styles.BreakdownLink}>View Full Breakdown</Box>
      </Box> */}
    </div>
  );
};

function InformationIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
        fill="#1890FF"
      />
    </svg>
  );
}
