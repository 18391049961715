import React, { createContext, useContext } from 'react';

type FeedbackContextData = {
  feature: string;
  task_id: string;
  page: string;
  requestFeedback: (feature: string, task_id: string) => void;
  resetFeedback: () => void;
};

const FeedbackPrototype: FeedbackContextData = {
  feature: '',
  task_id: '',
  page: '',
  requestFeedback: () => {},
  resetFeedback: () => {},
};

export const FeedbackContext = createContext(FeedbackPrototype);

export const useFeedback = (): FeedbackContextData => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error(
      'Feedback context hooks require a containing FeedbackContext'
    );
  }
  return context;
};

export const FeedbackProvider: React.FC = ({ children }) => {
  const [feature, setFeature] = React.useState(
    sessionStorage.getItem('feature') || ''
  );
  const [task_id, setTaskId] = React.useState(
    sessionStorage.getItem('task_id') || ''
  );
  const [page, setPage] = React.useState(sessionStorage.getItem('page') || '');

  const resetFeedback = React.useCallback(() => {
    setPage('');
    sessionStorage.removeItem('page');
  }, []);

  const requestFeedback = React.useCallback((f: string, t: string) => {
    const domainAndPath = window.location.origin + window.location.pathname;
    setPage(domainAndPath);
    setFeature(f);
    setTaskId(t);
    sessionStorage.setItem('feature', f);
    sessionStorage.setItem('task_id', t);
    sessionStorage.setItem('page', domainAndPath);
  }, []);

  const value = {
    feature,
    task_id,
    page,
    requestFeedback,
    resetFeedback,
  };
  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
};
