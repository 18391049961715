import * as React from 'react';
import cx from 'classnames';
import styles from './clear-button.module.css';

type PropsType = {
  className?: string;
  onClick: () => void;
};

export const ClearButton: React.FC<PropsType> = (props) => {
  const { className, onClick } = props;

  return (
    <div className={cx(styles.wrapper, className)}>
      <button className={styles.button} onClick={onClick} type="button">
        Any
      </button>
    </div>
  );
};
