import React from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from '@reach/router';
import { Author, AuthorAlias, isAuthor, isAuthorAlias } from 'models/author';
import { isTopic, Topic } from 'models/topic';
import { fetchByIds } from 'services/api-user';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { fetchTopicById } from 'services/api-topics';
import { deepMerge } from 'utility/deep-merge';
import { findInQueryParam } from 'utility/findInQueryParam';
import { fetchByIds as fetchAuthorAliasesByIds } from 'services/api-author-alias';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { DeepPartial } from 'utility-types';
import { ContentType } from 'components/content/ContentFilterBar/ContentTypeFilter';

export type BaseFilter = {
  name: string;
  field: string;
  label: string;
  values: Array<string | Author | Topic>;
  states?: Array<string>;
  isVisible: boolean;
  allSource?: string;
};

export type Filter = BaseFilter | ContentTypesFilter;

export type BooleanFilter = {
  name: string;
  field?: string;
  label: string;
  value?: boolean;
};

export type DateFilter = {
  type: string;
  name: string;
  field: string;
  label: string;
  queryFields: string[];
  isSelected: boolean;
  value?: string;
};

type SearchProps = {
  hasValue: boolean;
  fieldValue: string;
};

export type ContentFiltersContextType = {
  filters: FiltersStateType;
  setBooleanValue: (
    name: string,
    value: boolean,
    shouldInvokeCallback?: boolean
  ) => void;
  setDateValue: (
    name: string,
    value: string,
    shouldInvokeCallback?: boolean
  ) => void;
  setValue: (
    name: string,
    values: Array<string>,
    shouldInvokeCallback?: boolean
  ) => void;
  setFilterSelected: (name: string, visible: boolean) => void;
  setVisibility: (name: string, visible: boolean) => void;
  updateQueryString: (name: string, value: string) => void;
  searchParams: SearchProps;
};

export const ContentFiltersContext = React.createContext<
  ContentFiltersContextType
>({
  setBooleanValue: () => {},
  setValue: () => {},
  setDateValue: () => {},
  setVisibility: () => {},
  setFilterSelected: () => {},
  searchParams: { hasValue: false, fieldValue: '' },
  updateQueryString: () => {},
  filters: {
    standard: {},
    boolean: {},
    date: {},
  },
});

type ContentTypesFilter = {
  name: 'content_types';
  states: ContentType[];
} & Omit<BaseFilter, 'name' | 'states'>;

export type FiltersStateType = {
  standard: { [name: string]: Filter };
  boolean: { [name: string]: BooleanFilter };
  date: { [name: string]: DateFilter };
};

/*
Put all possible content filters here.

⚠️Any added filters *must* be compatible with bulk operations, such as bulk archiving.⚠️
Ensure params are being passed in the request, and are parsed correctly on the receiving end.
 */
export const defaultState = (
  partialState?: DeepPartial<FiltersStateType>
): FiltersStateType => {
  const initial = {
    standard: {
      publicationState: {
        name: 'publication_state',
        field: 'publication_state',
        label: 'State',
        values: [],
        states: ['published', 'scheduled', 'draft', 'archived'],
        isVisible: false,
      },
      topics: {
        name: 'topics',
        field: 'content_channel_ids',
        label: 'Topic',
        values: [],
        isVisible: true,
      },
      contentTypes: {
        name: 'content_types',
        field: 'include_types',
        label: 'Content Type',
        values: [],
        isVisible: true,
      },
      studioVersions: {
        name: 'studio_versions',
        field: 'include_versions',
        label: 'Studio Version',
        values: [],
        isVisible: true,
      },
      flaggedEntities: {
        name: 'flagged_entities',
        field: 'flagged_entities',
        label: 'Flagged',
        values: [],
        isVisible: true,
      },
      initiatives: {
        name: 'initiatives',
        field: 'initiative_tag_ids',
        label: 'Initiatives',
        values: [],
        isVisible: true,
      },
      publishers: {
        name: 'publishers',
        field: 'publisher_ids',
        label: 'Author',
        values: [],
        isVisible: true,
      },
      author_aliases: {
        name: 'author_aliases',
        field: 'author_alias_ids',
        label: 'Author Alias',
        values: [],
        isVisible: true,
      },
      creators: {
        name: 'creators',
        field: 'creator_ids',
        label: 'Creator',
        values: [],
        isVisible: true,
      },
      // admin-created, external, submitted
      sourceTypes: {
        name: 'source_types',
        field: 'source_type',
        label: 'Sources',
        values: [],
        isVisible: false,
      },
      // pinterest, rss, twitter by id
      externalSources: {
        name: 'external_sources',
        field: 'content_source_id',
        label: 'External',
        values: [],
        isVisible: false,
      },
      submitters: {
        name: 'submitters',
        field: 'submitter_ids',
        label: 'User-Submitted',
        values: [],
        isVisible: false,
        allSource: 'submitted',
      },
    },
    boolean: {
      featured: {
        name: 'featured',
        field: 'featured',
        label: 'Featured',
      },
      shareable: {
        name: 'shareable',
        label: 'Shareable',
      },
      commentable: {
        name: 'commentable',
        field: 'commentable',
        label: 'Commentable',
      },
      translatable: {
        name: 'translatable',
        field: 'translatable',
        label: 'Translatable',
      },
      resources: {
        name: 'resources',
        field: 'is_resource',
        label: 'Resources',
      },
      sources: {
        name: 'sources',
        label: 'Sources',
        field: 'combined_sources_mode',
      },
    },
    date: {
      creationDate: {
        type: 'date',
        name: 'creationDate',
        label: 'Creation Date',
        field: 'creation_date',
        queryFields: ['created_since', 'created_before'],
        isSelected: false,
        value: '',
      },
      publishDate: {
        type: 'date',
        name: 'publishDate',
        label: 'Publish Date',
        field: 'publish_date',
        queryFields: ['published_since', 'published_before'],
        isSelected: false,
        value: '',
      },
    },
  };

  return deepMerge(initial, partialState, { arrays: 'replace' });
};

export type ParameterizedFilters = { [p: string]: unknown };

type FilterTypes = keyof FiltersStateType;

type ParameterizeFiltersProcessor<T extends FilterTypes> = ({
  key,
  filter,
}: {
  key: keyof FiltersStateType[T];
  filter: FiltersStateType[T][keyof FiltersStateType[T]];
}) => ParameterizedFilters | undefined;

export type ParameterizeFiltersProcessors = {
  standard: ParameterizeFiltersProcessor<'standard'>;
  boolean: ParameterizeFiltersProcessor<'boolean'>;
  date: ParameterizeFiltersProcessor<'date'>;
};

export const parameterizeFilters = (
  filters: FiltersStateType,
  processors: ParameterizeFiltersProcessors
): ParameterizedFilters => {
  const filterTypes = Object.keys(filters) as FilterTypes[];
  return filterTypes.reduce((allParams, filterType) => {
    if (!processors[filterType])
      throw new Error(
        `A processor must be provided for filter type ${filterType}`
      );

    return {
      ...allParams,
      ...Object.entries(filters[filterType]).reduce(
        (groupParams, [key, filter]) => ({
          ...groupParams,
          ...processors[filterType]({ key, filter }),
        }),
        {} as ParameterizedFilters
      ),
    };
  }, {} as ParameterizedFilters);
};

export const deriveFilterValues = (
  values: Array<string | AuthorAlias | Author | Topic>,
  useAuthorAliases: boolean
): Array<number | string> => {
  if (useAuthorAliases) {
    if (values.every((value) => isAuthorAlias(value))) {
      return (values as AuthorAlias[]).map((a) => a.authorAliasId);
    }
  } else if (values.every((value) => isAuthor(value))) {
    return (values as Author[]).map((a) => a.userId);
  }

  if (values.every((value) => isTopic(value))) {
    return (values as Topic[]).map((a) => a.id);
  }

  return values as string[];
};

const createValuesFromQuery = async (
  key: string,
  values: string[],
  program_id: number
): Promise<Author[] | Topic[] | string[]> => {
  if (key === 'publishers' || key === 'creators') {
    const data = await fetchByIds(
      values.map((id) => Number(id)),
      program_id
    );
    return data.data.map(
      (d) =>
        ({
          userId: d.id,
          displayName: `${d.firstName || ''} ${d.lastName || ''}`,
        } as Author)
    );
  }

  if (key === 'author_aliases') {
    const data = await fetchAuthorAliasesByIds(values.map(Number), program_id);
    return data.data.map(
      (d) =>
        ({
          authorAliasId: d.id,
          displayName: d.displayName,
        } as Author)
    );
  }

  if (key === 'topics') {
    return Promise.all(
      values.map(async (id) => fetchTopicById(program_id, id))
    );
  }

  return values;
};

type FilterProps = {
  customDefaultState?: FiltersStateType;
  filterCallback?: (filters: FiltersStateType) => void;
};

export const useFilters = ({
  customDefaultState,
  filterCallback,
}: FilterProps): {
  filters: FiltersStateType;
  setBooleanValue: (
    name: string,
    value: boolean,
    shouldInvokeCallback?: boolean
  ) => void;
  setDateValue: (
    name: string,
    values: string,
    shouldInvokeCallback?: boolean
  ) => void;
  setValue: (
    name: string,
    values: Array<string | Author | Topic>,
    shouldInvokeCallback?: boolean
  ) => void;
  setVisibility: (name: string, value: boolean) => void;
  setFilterSelected: (name: string, value: boolean) => void;
  updateQueryString: (name: string, value: string) => void;
  clearFilters: () => void;
  searchParams: SearchProps;
} => {
  const [filters, setFiltersState] = React.useState<FiltersStateType>(
    customDefaultState ?? defaultState()
  );
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = location.search;
  const params = qs.parse(queryString, { ignoreQueryPrefix: true });

  const setVisibility = React.useCallback(
    (name: string, visible: boolean) => {
      const filterToUpdate = filters.standard[name];
      if (filterToUpdate) {
        filterToUpdate.isVisible = visible;
        const newState = {
          ...filters.standard,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          standard: newState,
        });
      }
    },
    [filters]
  );

  const setFilterSelected = React.useCallback(
    (name: string, isSelected: boolean) => {
      const filterToUpdate = filters.date[name];
      if (filterToUpdate) {
        filterToUpdate.isSelected = isSelected;
        const newState = {
          ...filters.date,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          date: newState,
        });
      }
    },
    [filters]
  );

  const setBooleanValue = React.useCallback(
    (name: string, value: boolean, shouldInvokeCallback = true) => {
      const filterToUpdate = filters.boolean[name];
      if (filterToUpdate) {
        filterToUpdate.value = value;
        const newState = {
          ...filters.boolean,
          [name]: filterToUpdate,
        };
        setFiltersState({
          ...filters,
          boolean: newState,
        });
        if (shouldInvokeCallback && filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  const setValue = React.useCallback(
    (
      name: string,
      values: Array<string | Author | Topic>,
      shouldInvokeCallback = true
    ) => {
      const filterToUpdate = filters.standard[name];
      if (filterToUpdate && filterToUpdate.values !== values) {
        filterToUpdate.values = values;
        const newState = { ...filters.standard, [name]: filterToUpdate };

        const state = {
          ...filters,
          standard: newState,
        };
        setFiltersState(state);
        if (shouldInvokeCallback && filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  const setDateValue = React.useCallback(
    (name: string, value: string, shouldInvokeCallback = true) => {
      const filterToUpdate = filters.date[name];
      if (filterToUpdate && filterToUpdate.value !== value) {
        filterToUpdate.value = value;
        const newState = { ...filters.date, [name]: filterToUpdate };

        const state = {
          ...filters,
          date: newState,
        };
        setFiltersState(state);
        if (shouldInvokeCallback && filterCallback) filterCallback(filters);
      }
    },
    [filters, setFiltersState, filterCallback]
  );

  const updateQueryString = React.useCallback(
    (name: string, value: string) => {
      if (value) {
        const query = qs.stringify({ ...params, [name]: value });
        navigate(`${location.pathname}${query ? `?${query}` : ''}`);
      } else {
        delete params[name];
        const query = qs.stringify(params);
        navigate(`${location.pathname}${query ? `?${query}` : ''}`);
      }
    },
    [location.pathname, navigate, params]
  );

  const clearFilters = () => {
    setFiltersState(customDefaultState ?? defaultState());
  };

  return {
    filters,
    setValue,
    setBooleanValue,
    setDateValue,
    setVisibility,
    setFilterSelected,
    updateQueryString,
    clearFilters,
    searchParams: {
      hasValue: Object.prototype.hasOwnProperty.call(params, 'search'),
      fieldValue: params.search as string,
    },
  };
};

const useValidatedDefaultFiltersState: (
  filtersState?: FiltersStateType
) => FiltersStateType = (filtersState) => {
  const {
    permissions: { moderateCommentAccess, moderateContentAccess },
  } = usePermissions();

  const getIsFilterValid = (filter: string) => {
    switch (filter) {
      case 'flagged_entities':
        return moderateCommentAccess || moderateContentAccess;
      default:
        return true;
    }
  };

  const defaultFiltersState = filtersState ?? defaultState();
  const boolFilterNames = Object.keys(defaultFiltersState.boolean);
  boolFilterNames.forEach((filterName) => {
    if (!getIsFilterValid(filterName)) {
      delete defaultFiltersState.boolean[filterName];
    }
  });

  return defaultFiltersState;
};

export const ContentFiltersProvider: React.FC<{
  defaultFiltersState?: FiltersStateType;
  skipQueryParams?: boolean;
}> = ({ defaultFiltersState, children, skipQueryParams }) => {
  const { id: programId } = useProgram();
  const location = useLocation();
  const navigate = useNavigate();
  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;

  const setQueryParams = React.useCallback(
    (filters: FiltersStateType) => {
      if (skipQueryParams) return;
      const queryParams: ParameterizedFilters = {
        ...parameterizeFilters(filters, {
          standard: ({ key, filter }) => {
            const allOptionEnabled =
              filter.allSource &&
              filters.standard.sourceTypes?.values.includes(filter.allSource);
            return filter.values.length > 0 && !allOptionEnabled
              ? {
                  [key]: deriveFilterValues(
                    filter.values,
                    filter.name === 'author_aliases' && useAuthorAliases
                  ).join(','),
                }
              : undefined;
          },
          boolean: ({ key, filter }) =>
            filter.value ? { [key]: 'true' } : undefined,
          date: ({ key, filter }) =>
            filter.isSelected && filter.value
              ? { [key]: filter.value }
              : undefined,
        }),
      };

      const { hasValue, fieldValue } = findInQueryParam(
        location.search,
        'search'
      );
      if (hasValue) {
        queryParams.search = fieldValue;
      }
      const query = qs.stringify(queryParams);
      setShouldUpdateValuesFromQuery(false);

      const isQueryUpdated = location.search.replace('?', '') !== query;
      if (isQueryUpdated)
        navigate(`${location.pathname}${query ? `?${query}` : ''}`);
    },
    [
      skipQueryParams,
      location.search,
      location.pathname,
      navigate,
      useAuthorAliases,
    ]
  );

  const validatedDefaultState = useValidatedDefaultFiltersState(
    defaultFiltersState
  );

  const {
    filters,
    setValue,
    setDateValue,
    setBooleanValue,
    setVisibility,
    setFilterSelected,
    searchParams,
    updateQueryString,
    clearFilters,
  } = useFilters({
    customDefaultState: validatedDefaultState,
    filterCallback: setQueryParams,
  });

  const [shouldSetQueryParams, setShouldSetQueryParams] = React.useState(false);
  const [
    shouldUpdateValuesFromQuery,
    setShouldUpdateValuesFromQuery,
  ] = React.useState(!!location.search);
  const queryString = location.search || '?';

  React.useEffect(() => {
    const params = qs.parse(queryString, { ignoreQueryPrefix: true });
    if (Object.keys(params).length === 0) {
      clearFilters();
    }

    // update the values from query params
    async function updateValuesFromQuery() {
      const promises = Object.keys(params).map(async (key) => {
        const keyValue = params[key] as string;
        if (keyValue) {
          if (filters.standard[key]) {
            const values = await createValuesFromQuery(
              key,
              keyValue.split(','),
              programId
            );
            setValue(key, values, false);
          } else if (filters.boolean[key]) {
            setBooleanValue(key, true, false);
          } else if (filters.date[key]) {
            setFilterSelected(key, true);
            setDateValue(key, keyValue, false);
          }
        }
      });

      Promise.all(promises).then(() => setShouldSetQueryParams(true));
    }

    if (shouldUpdateValuesFromQuery) {
      updateValuesFromQuery();
    } else {
      setShouldUpdateValuesFromQuery(true);
    }
    // queryString is a dependency because the queryString might change
    // when studio admin uses the notifications menu links
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  React.useEffect(() => {
    if (shouldSetQueryParams) {
      setQueryParams(filters);
      setShouldSetQueryParams(false);
    }
  }, [filters, shouldSetQueryParams, setQueryParams]);

  const pureUpdateQueryString = React.useCallback(
    (name, value) => {
      updateQueryString(name, value);
      // to avoid updating the values from query params in the hook above
      setShouldUpdateValuesFromQuery(false);
    },
    [updateQueryString]
  );
  return (
    <ContentFiltersContext.Provider
      value={{
        setVisibility,
        setBooleanValue,
        setFilterSelected,
        searchParams,
        updateQueryString: pureUpdateQueryString,
        setValue,
        setDateValue,
        filters,
      }}
    >
      {children}
    </ContentFiltersContext.Provider>
  );
};
