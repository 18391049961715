import React from 'react';
import Select from 'react-select';
import * as Text from 'DesignSystem/Typography';
import { OptionType } from 'hooks/common';

type PropsType = {
  onChange: (action: string) => void;
};
export const ActionSelector: React.FC<PropsType> = ({ onChange }) => {
  const options = [
    { value: 'add', label: 'Add' },
    { value: 'replace', label: 'Replace' },
  ];

  return (
    <>
      <Text.Subheading block bold>
        Select an Action
      </Text.Subheading>
      <Text.Body color={Text.color.gray60}>
        Select an action to be taken on the permission(s)
      </Text.Body>
      <Text.Body>
        <Select
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '300px',
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              width: '300px',
              marginTop: '16px',
            }),

            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: 'var(--color-gray90)',
            }),

            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              color: 'var(--color-gray90)',
            }),
          }}
          placeholder="Select Action"
          options={options}
          onChange={(option) => {
            const opt = option as OptionType;
            onChange(opt.value);
          }}
        />
      </Text.Body>
    </>
  );
};
