import React from 'react';
import cx from 'classnames';
import { ExternalSource } from 'models/external-source';
import { useProgram } from 'contexts/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Field, Fieldset } from 'shared/Fieldset';
import { ClickDropdown } from 'shared/ClickDropdown';
import { RadioSelectField } from 'shared/forms/RadioSelectField';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { ContentTemplate } from 'models/content-template';
import { useContentTemplatesQuery } from 'hooks/content-templates';
import { FlipSwitch } from 'shared/FlipSwitch';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from './form.module.css';

type PropsType = {
  externalSource: Partial<ExternalSource>;
  onChange: (data: Partial<ExternalSource>) => void;
};

export const ExpandArticlesInput: React.FC<PropsType> = ({
  externalSource,
  onChange,
}) => {
  const { id: programId } = useProgram();
  const [contentTemplates, setContentTemplates] = React.useState<
    ContentTemplate[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState<
    ContentTemplate
  >();
  const { data, isLoading } = useContentTemplatesQuery({
    programId,
  });

  React.useEffect(() => {
    if (data && data.length > 0) {
      setContentTemplates(data);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  React.useEffect(() => {
    if (externalSource.contentTemplateId && contentTemplates.length > 0) {
      setSelectedTemplate(
        contentTemplates.find(
          (t) => Number(t.id) === externalSource.contentTemplateId
        )
      );
    }
  }, [externalSource.contentTemplateId, contentTemplates]);

  function renderDropdownItem(id: string) {
    let name = 'None';
    if (id !== '0') {
      const contentTemplate = contentTemplates.find((t) => t.id === id);
      if (!contentTemplate) return null;
      name = contentTemplate.name;
    }

    return <div className={styles.dropdownItem}>{name}</div>;
  }

  const rowIds = ['0'].concat(contentTemplates.map((t) => t.id));

  function onSelectedChange(selectedId: string) {
    if (selectedId === '0') {
      onChange({ contentTemplateId: null });
      return;
    }

    onChange({ contentTemplateId: Number(selectedId) });
  }

  const dropdown = (
    <div className={styles.dropdown}>
      <RadioSelectField
        rowIds={rowIds}
        rowRenderProp={renderDropdownItem}
        maxHeight={400}
        itemHeight={40}
        selectedId={
          externalSource.contentTemplateId
            ? String(externalSource.contentTemplateId)
            : rowIds[0]
        }
        onSelectedIdChange={onSelectedChange}
      />
    </div>
  );

  return (
    <>
      <Fieldset>
        <div
          className={cx(
            fieldsetStyles.fieldGroupContainer,
            styles.multipleFields
          )}
        >
          <Field label="Expand articles" className={styles.firstField}>
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <FlipSwitch
                  size="xl"
                  shape="rounded"
                  on={externalSource.displayInternalContent === true}
                  onChange={(value) =>
                    value
                      ? onChange({
                          displayInternalContent: true,
                        })
                      : onChange({
                          displayInternalContent: false,
                        })
                  }
                />
              </div>
              Expand to article format
            </div>
          </Field>
          <Field label="Content template">
            {isLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && (
              <ClickDropdown
                dropdownRenderProp={dropdown}
                dropdownClassName={cx(
                  'dropdown-align-left',
                  styles.clickDropDown
                )}
                disabled={!externalSource.displayInternalContent}
              >
                <button
                  type="button"
                  className={cx(styles.dropdownButton, {
                    [styles.disabledDropdownButton]: !externalSource.displayInternalContent,
                  })}
                >
                  <div className={styles.dropdownSelectedItem}>
                    {selectedTemplate?.name || 'None'}
                  </div>

                  <SVGIcon name="ExpandList" />
                </button>
              </ClickDropdown>
            )}
          </Field>
        </div>
      </Fieldset>
    </>
  );
};
