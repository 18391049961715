import { Button } from '@socialchorus/shared-ui-components';
import React, { FC, useContext, useRef, useState, useCallback } from 'react';
import * as Text from 'DesignSystem/Typography';
import { MAIcon } from 'shared/MAIcon';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useUpdateTopicAboutPage } from 'hooks/topics';
import { navigate } from '@reach/router';
import { FieldError } from 'shared/Fieldset/FieldError';
import { useProgram } from 'contexts/program';
import { useIsErrorApplicable } from 'App/Program/Configuration/Topics/TopicV2/topicErrorHooks';
import styles from './styles.module.css';
import { BaseTab } from '../BaseTab';
import { HighlightableContainer } from '../../components/HighlightableContainer';
import { AboutDesignContext } from '../../contexts/about';
import { useTopicFormCtx } from '../../../../context';

const EmptyAboutTab: FC = () => {
  const { id: programId } = useProgram();
  const { topic, baseUrl } = useTopicFormCtx();

  const { update } = useUpdateTopicAboutPage(programId, topic.id, {
    onSuccess: (data) => {
      navigate(`${baseUrl}/about/${data.designId}`);
    },
  });

  const contentMissingApplicable = useIsErrorApplicable('Missing Content');

  const onAddAboutClick = () => {
    update({ createDesign: true });
  };

  return (
    <>
      <div className={styles.NoAbout}>
        <div className={styles.NoAbout__Texts}>
          <Text.Heading semibold>About</Text.Heading>
          <Text.Body>
            <span className={styles.NoAbout__Texts__Description}>
              There is no content in the About page yet
            </span>
          </Text.Body>
        </div>

        <Button
          variant="primary"
          className={styles.AddAboutButton}
          leftIcon={<MAIcon name="add" />}
          label="Content"
          onClick={onAddAboutClick}
        />
        {contentMissingApplicable && (
          <FieldError
            error="Content and Preview required"
            ariaLabel="Missing Content and Preview"
          />
        )}
      </div>
    </>
  );
};

type AboutWithContentTabProps = {
  content: string;
  onOpenAboutSidebar: () => void;
};

const AboutWithContentTab: FC<AboutWithContentTabProps> = ({
  content,
  onOpenAboutSidebar,
}) => {
  const iframe = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(2000);
  const onLoad = useCallback(() => {
    const scrollHeight = iframe.current?.contentDocument?.body?.scrollHeight;
    if (scrollHeight) setHeight(scrollHeight);
  }, []);
  return (
    <>
      <button
        type="button"
        className={styles.EditButton}
        onClick={onOpenAboutSidebar}
      >
        <iframe
          title="about-page"
          srcDoc={content}
          onLoad={onLoad}
          ref={iframe}
          style={{
            width: '100%',
            height: `${height}px`,
            minHeight: '100%',
          }}
        />
      </button>
    </>
  );
};

export const AboutTab = (): JSX.Element => {
  const { state, toggleSidebar } = useTopicFormCtx();
  const { aboutPage } = useContext(AboutDesignContext);
  const handleAboutEdit = () => {
    toggleSidebar('about');
  };

  return (
    <BaseTab currentTab="about">
      <HighlightableContainer
        className={styles.Container}
        hoverLabel="About"
        active={state.activeSidebar === 'about'}
        editIcon={!aboutPage?.isEmpty}
      >
        {!aboutPage && <LoadingSpinner />}
        {aboutPage && !aboutPage.isEmpty && (
          <AboutWithContentTab
            content={aboutPage?.pageContent}
            onOpenAboutSidebar={handleAboutEdit}
          />
        )}
        {aboutPage && aboutPage.isEmpty && <EmptyAboutTab />}
      </HighlightableContainer>
    </BaseTab>
  );
};
