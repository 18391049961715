import React from 'react';
import { NewFilterButton } from 'DesignSystem/Table/NewFilterButton';
import NestableMenu from 'shared/NestableMenu';
import { NestableMenuType } from 'shared/NestableMenu/types';
import { Filter } from '../../../../../models/journeys/filter';

export const NewFiltersButton: React.FC<{
  filters: Filter[];
  onSelect: (filter: Filter) => void;
}> = ({ filters, onSelect }) => {
  const rootMenuData: NestableMenuType<Filter> = React.useMemo(() => {
    return {
      simpleMenu: {
        onSelect,
        items: filters.map((filter) => {
          return { label: filter.label, key: filter.name, value: filter };
        }),
      },
    };
  }, [filters, onSelect]);

  const menuButton = React.useMemo(
    () => (
      <div>
        {/* the nestable menu insists on this for an undetermined reason */}
        <NewFilterButton layout="journeys" />
      </div>
    ),
    []
  );

  return <NestableMenu menuButton={menuButton} rootMenuData={rootMenuData} />;
};
