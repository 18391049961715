import React from 'react';
import { useQueryClient } from 'react-query';
import {
  canViewResults,
  Content,
  isArchived,
  isContent,
  isPublished,
  isPublishProtectedContent,
} from 'models/content';
import { MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { useProgramIdState } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFlashMessage } from 'contexts/flasher';
import { useContentDuplicator } from 'hooks/useContentDuplicator';
import { useContentEraser } from 'hooks/content/useContentEraser';
import { useStateMutation } from 'hooks/state-mutator';
import { Post } from 'models/publisher/post';
import { usePermissions } from 'contexts/permissions';
import { useContentReportUrls } from 'hooks/content/useContentReportUrls';
import { useFeatureFlag } from 'hooks/useFeatureFlags';

export const useContentActions = (
  content: Content
): {
  menuItems: MenuItemsType;
  deleteItem?: {
    deleteAction: () => void;
    showingDeleteModal: boolean;
    toggleDeleteModal: () => void;
    resource: string;
  };
} => {
  const { getArticleUrl } = useContentReportUrls({
    content,
  });
  const post = React.useMemo(() => ({ content } as Post), [content]);
  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const [programId] = useProgramIdState();
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();
  const resultsSummaryEnabled = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ResultsSummary'
  ).data?.value;
  const emailOnly = !!useFeatureFlag('emailOnly');
  const invalidateQuery = React.useCallback(
    (message: string) => () => {
      queryClient.invalidateQueries(['paged-contents']);
      setFlashMessage({
        severity: 'info',
        message,
      });
    },
    [queryClient, setFlashMessage]
  );

  const {
    permissions: { insightsAccess, publishCampaign },
  } = usePermissions();

  const canPublish = publishCampaign;
  const isContentPublishProtected =
    !canPublish && isPublishProtectedContent(content.publicationState);
  const contentCanOnlyPreview =
    !content.meta.editable || isContentPublishProtected;

  const canChangeContentStatus = content.meta.editable && canPublish;

  const toggleDeleteModal = () => {
    setShowingDeleteModal((prev) => !prev);
  };

  const { duplicate: performDuplication } = useContentDuplicator();

  const { mutate: mutateArchive } = useStateMutation({
    action: 'archive',
    onSuccess: invalidateQuery('Content has been archived'),
  });

  const { mutate: mutateUnarchive } = useStateMutation({
    action: 'draft',
    onSuccess: invalidateQuery('Content has been unarchived'),
  });

  const { erase: performErasing } = useContentEraser({
    onSuccess: invalidateQuery('Campaign deleted'),
  });

  const eraseCampaign = React.useCallback(() => {
    performErasing({ contentId: content.id, programId });
  }, [performErasing, content.id, programId]);

  const duplicate = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      performDuplication({ post, programId });
    },
    [performDuplication, post, programId]
  );

  const archive = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      mutateArchive({ post, programId });
    },
    [mutateArchive, post, programId]
  );

  const unarchive = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      mutateUnarchive({ post, programId });
    },
    [mutateUnarchive, post, programId]
  );

  const menuItems: MenuItemsType = React.useMemo(() => {
    return [
      {
        title: 'View Results',
        href: `/${programId}/app/content/results/${content.id}`,
        display: resultsSummaryEnabled && canViewResults(content),
      },
      {
        title: contentCanOnlyPreview ? 'Open' : 'Edit',
        href: `/${programId}/edit/publisher/${content.id}`,
        display: true,
      },
      {
        title: 'View full report',
        disabled: !insightsAccess,
        href: getArticleUrl(),
        newTab: true,
        display: isContent(content) && content.version === 1,
      },
      {
        title: 'Unarchive',
        onClick: unarchive,
        display: isArchived(content),
      },
      {
        title: 'Archive',
        onClick: archive,
        disabled: !canChangeContentStatus,
        display: !isArchived(content),
      },
      {
        title: 'Duplicate',
        onClick: duplicate,
        display: true,
      },
      {
        title: 'Open in Experience',
        href: content.permalinkUrl,
        newTab: true,
        display: isPublished(content) && !emailOnly,
      },
      {
        title: 'Delete',
        onClick: (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setShowingDeleteModal(true);
        },
        disabled: !canChangeContentStatus,
        danger: true,
        display: true,
      },
    ].filter((item) => item.display);
  }, [
    programId,
    content,
    resultsSummaryEnabled,
    contentCanOnlyPreview,
    insightsAccess,
    getArticleUrl,
    unarchive,
    archive,
    canChangeContentStatus,
    duplicate,
    emailOnly,
  ]);

  return {
    menuItems,
    deleteItem: {
      deleteAction: eraseCampaign,
      resource: 'campaign',
      showingDeleteModal,
      toggleDeleteModal,
    },
  };
};
