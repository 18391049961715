import * as React from 'react';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useLibraryBlockCategories, useLibraryBlocks } from 'hooks/useLibrary';
import { Blocks, Item } from 'models/library';
import { PickerScreen } from './PickerScreen';

export const BlocksPicker: React.FC<{
  onChange: (block: Array<Blocks>) => void;
  onCancel?: () => void;
  maxSelections: number;
  useModal?: boolean;
}> = ({ onChange, maxSelections, onCancel, useModal }) => {
  const change = React.useCallback(
    (items: Array<Item>) => onChange(items as Array<Blocks>),
    [onChange]
  );

  const { omitLibraryCategories } = React.useContext(BlocksEditorContext);

  return (
    <PickerScreen
      useModal={useModal}
      libraryType="content_blocks"
      topOffset={13}
      useLibraryItems={useLibraryBlocks}
      useCategories={useLibraryBlockCategories}
      omitCategories={omitLibraryCategories}
      label="block"
      maxSelections={maxSelections}
      onChange={change}
      onCancel={onCancel}
      defaultCategoryLabel="Everything"
    />
  );
};
