import React from 'react';
import { usePublisher } from 'contexts/publisher';
import { TimeDelta, useTimeDelta } from 'hooks/useTimeDelta';
import styles from './post-persistance-status.module.css';

export const usePersistanceStatus = (): {
  timeDelta: TimeDelta;
  cloudStatus: string;
} => {
  const {
    post: {
      content: { id: contentId },
    },
    status: { lastModified, lastSaveSuccess },
  } = usePublisher();

  const timeDelta = useTimeDelta(lastSaveSuccess);

  const cloudStatus = React.useMemo(() => {
    if (contentId && lastModified && lastSaveSuccess) {
      if (lastModified.toMillis() <= lastSaveSuccess.toMillis())
        return 'Up to date';
      return 'Out of date';
    }
    if (contentId && lastSaveSuccess) {
      return 'Up to date';
    }
    return 'Not Saved';
  }, [contentId, lastModified, lastSaveSuccess]);

  return { timeDelta, cloudStatus };
};

export const PersistanceStatus: React.FC<{
  timeDelta: TimeDelta;
  label: string;
  defaultValue?: string;
}> = ({ timeDelta, label, defaultValue = '' }) => {
  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <div data-testid="persistance-status">
          {label}: {timeDelta ? timeDelta.formatted : defaultValue}
        </div>
      </div>
    </div>
  );
};

export const PostPersistanceStatus: React.FC = () => {
  const { timeDelta } = usePersistanceStatus();
  return (
    <PersistanceStatus
      timeDelta={timeDelta}
      label="Last Saved"
      defaultValue="Not saved"
    />
  );
};
