import React from 'react';
import { FilterDropdown } from 'shared/FilterDropdown';
import styles from 'shared/FilterDropdown/filter-dropdown.module.css';
import { statusOptions } from './constants';

type PropsType = {
  onChange: (filterName: string, values: string[]) => void;
  statuses?: string[];
};

export const Filters: React.FC<PropsType> = ({ onChange, statuses }) => {
  return (
    <div className={styles.filterSortRow}>
      <div className={styles.filters}>
        <FilterDropdown
          label="Status"
          options={statusOptions}
          onChange={(values) => onChange('status', values)}
          selectedOptions={statusOptions.filter((opt) =>
            statuses?.includes(opt.value)
          )}
        />
      </div>
    </div>
  );
};
