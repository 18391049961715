import * as React from 'react';
import { ImageData } from 'models/image';

export function useImageSelector({
  current,
  setImage,
  images,
}: {
  current: ImageData;
  setImage: (image: ImageData) => void;
  images: ImageData[];
}): {
  hasPrev: boolean;
  hasNext: boolean;
  index: number;
  prev(): void;
  next(): void;
  images: ImageData[];
} {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    if (current.url) {
      setIndex(images.findIndex((image) => image.url === current.url));
    }
  }, [current, images]);

  const hasNext = index < images.length - 1;
  const next = React.useCallback(() => {
    if (hasNext) {
      setIndex(index + 1);
      const img = images[index + 1];
      img.processed = img.processed || false;
      setImage(img);
    }
  }, [hasNext, images, index, setImage]);

  const hasPrev = index >= 1;
  const prev = React.useCallback(() => {
    if (hasPrev) {
      setIndex(index - 1);
      const img = images[index - 1];
      img.processed = img.processed || false;
      setImage(img);
    }
  }, [hasPrev, images, index, setImage]);
  return {
    hasPrev,
    prev,
    hasNext,
    next,
    index,
    images,
  };
}
