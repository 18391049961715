import * as React from 'react';
import { useState } from 'react';
import { Category } from 'models/library';
import { FormModal } from 'DesignSystem/Components';
import { pluralize } from 'utility/text';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Heading } from 'DesignSystem/Typography';
import { ImageUploader } from '../ImageUploader';
import { usePickerScreen } from './usePickerScreen';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { Items } from './Items';
import { Pagination } from './Pagination';
import styles from './picker-screen.module.css';

export const ModalPicker: React.FC<ReturnType<typeof usePickerScreen>> = ({
  addToAppendedItems,
  allItems,
  allowFiltering,
  canModifyCollection,
  canUploadImages,
  categories,
  collection,
  defaultCategoryLabel,
  handleDeleteItem,
  hasCustomCategories,
  isFilteringAllowed,
  label,
  libraryType,
  maxSelections,
  onCancel,
  onChange,
  refs,
  select,
  setSpinnersCount,
  showImageUploader,
  spinnersCount,
}) => {
  const entityText = pluralize(maxSelections, label);

  const [searchText, setSearchText] = useState('');
  const [categoryId, setCategoryId] = useState<number | null>();
  const customBlockCategory = categories.data?.find(
    (category: Category) => category.identifier === 'custom_block'
  );

  const isCustomBlockCategorySelected = categoryId === customBlockCategory?.id;

  const emptycustomCategoryText =
    'Saved blocks from the design editor will be available here.';
  return (
    <FormModal
      disabled={collection.selection.length < 1}
      actionText="select"
      entityText={entityText}
      onCancel={() => {
        if (onCancel) onCancel();
        else onChange([]);
      }}
      onSubmit={() => onChange(collection.selection)}
      minPadding={0}
    >
      <div
        style={{
          width: 'calc(100vw - 250px)',
          height: 'calc(100vh - 300px)',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: 1,
          }}
        >
          <div
            style={{
              position: 'fixed',
              width: '360px',
              height: 'calc(100vh - 300px)',
              overflow: 'auto',
            }}
          >
            <Sidebar
              useModal
              collection={collection}
              label={label}
              sidebarRef={refs.sidebarElement}
              onChange={onChange}
              onCancel={onCancel}
              categories={categories.data}
              invalidateQuery={categories.invalidateQuery}
              hasCustomCategories={hasCustomCategories}
              defaultCategoryLabel={defaultCategoryLabel}
              isFilteringAllowed={isFilteringAllowed}
              onCategoryIdChange={setCategoryId}
            />
          </div>
        </div>
        <div style={{ paddingLeft: '365px' }}>
          <Header
            collection={collection}
            label={label}
            maxSelections={maxSelections}
            onSearchTextChange={setSearchText}
          />
          {canUploadImages && (
            <ImageUploader
              filterByCategory={collection.filterByCategory}
              setSpinnersCount={setSpinnersCount}
              length={allItems.length}
              filter={collection.filter}
              addToAppendedItems={addToAppendedItems}
              isButton
              allowFiltering={allowFiltering}
            />
          )}
          {!canUploadImages &&
            (collection.items.length === 0 || allItems.length === 0) &&
            searchText?.length === 0 &&
            !collection.isLoading && (
              <Flex center column className={styles.empty}>
                {isCustomBlockCategorySelected ? (
                  <>
                    <Heading>No custom blocks yet.</Heading>
                    <Heading>{emptycustomCategoryText}</Heading>
                  </>
                ) : (
                  <Heading>No images yet</Heading>
                )}
              </Flex>
            )}
          {canUploadImages && (
            <ImageUploader
              filterByCategory={collection.filterByCategory}
              setSpinnersCount={setSpinnersCount}
              length={allItems.length}
              filter={collection.filter}
              addToAppendedItems={addToAppendedItems}
              allowFiltering={allowFiltering}
            >
              {!showImageUploader && (
                <div ref={refs.wrapperElement}>
                  <Items
                    handleDeleteItem={handleDeleteItem}
                    spinnersCount={spinnersCount}
                    filter={collection.filter}
                    isLoading={collection.isLoading}
                    isSelected={collection.isSelected}
                    items={allItems}
                    label={label}
                    maxSelections={maxSelections}
                    select={select}
                    selectedCount={collection.selection.length}
                    unselect={collection.unselect}
                    libraryType={libraryType}
                    canModifyCollection={canModifyCollection}
                  />
                </div>
              )}
              {collection.filter.type !== 'selected' &&
                !collection.isLoading &&
                collection.items.length > 0 && (
                  <div className={styles.pagination}>
                    <Pagination
                      label={label}
                      pagination={collection.pagination}
                    />
                  </div>
                )}
            </ImageUploader>
          )}
          {!canUploadImages && (
            <>
              {!showImageUploader && (
                <div ref={refs.wrapperElement}>
                  <Items
                    handleDeleteItem={handleDeleteItem}
                    spinnersCount={spinnersCount}
                    filter={collection.filter}
                    isLoading={collection.isLoading}
                    isSelected={collection.isSelected}
                    items={allItems}
                    label={label}
                    maxSelections={maxSelections}
                    select={select}
                    selectedCount={collection.selection.length}
                    unselect={collection.unselect}
                    libraryType={libraryType}
                    canModifyCollection={canModifyCollection}
                    searchText={searchText}
                  />
                </div>
              )}
              {collection.filter.type !== 'selected' &&
                !collection.isLoading &&
                allItems.length !== 0 && (
                  <div className={styles.pagination}>
                    <Pagination
                      label={label}
                      pagination={collection.pagination}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </FormModal>
  );
};
