import React from 'react';
import cx from 'classnames';
import styles from './half-gauge.module.css';
import { Tooltip } from '../../Tooltip';
import { HoverTooltip } from '../../HoverTooltip';

/*
  This uses two ellipses to build the gauge (A circle didn't look quite right).
  The 'indicator' is tricky. It relies on the dash-styled stroke of the ellipse starting 
  at the right spot, and going the correct distance, but with the math, it should work for
  any percentage, and any width of gauge you want to use.

  If a value is not supplied, the gauge will be half full, but will not show a number.
  The "signal" property controlls the style/color.
*/
export const HalfGauge: React.FC<{
  value?: number;
  width: number;
  label: string;
  signal: 'success' | 'error' | 'neutral';
}> = ({ value, width, label, signal }) => {
  const outerWidth = width;
  const outerHeight = (outerWidth * 1.02) / 2;
  const strokeWidth = 13;
  const rX = outerWidth / 2;
  const rY = outerHeight;
  const circumference = Math.PI * Math.sqrt(2 * (rX ** 2 + rY ** 2));
  const percentage = value ?? 0;

  const dropdown = React.useCallback(() => {
    let description: React.ReactNode;
    switch (label) {
      case 'Performance': {
        description = (
          <div className={styles.tooltipContent}>
            <div>
              Based on your audience and the Orchestrate selections you&apos;ve
              made, this is our estimated user engagement performance for your
              content.
            </div>
            <div>
              Your Performance Score is calculated according to your priority,
              the channels used to reach your audience (informed by priority),
              and user engagement on the topics you&apos;ve selected (if any).
            </div>
          </div>
        );
        break;
      }
      case 'Fatigue':
        description = (
          <div className={styles.tooltipContent}>
            <div>
              Based on the Orchestrate selections you&apos;ve made, this is our
              estimated information overload level that considers your
              audience&apos;s time and attention.
            </div>
            <div>
              Your Fatigue Score is calculated according to the priority,
              duration, channels used to reach your audience (informed by
              priority), and user engagement on the topics you&apos;ve selected
              (if any).
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return description ? (
      <Tooltip description={description} title={label} />
    ) : undefined;
  }, [label]);

  return (
    <div className={styles.gauge}>
      <svg
        width={outerWidth}
        height={outerHeight}
        viewBox={`${-rX - strokeWidth / 2} 
          ${-rY - strokeWidth / 2}
          ${outerWidth + strokeWidth}
          ${outerHeight}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          className={styles.background}
          cx={0}
          cy={0}
          rx={rX}
          ry={rY}
          strokeWidth="1.45582"
          strokeDasharray="1.45 2.91"
        />
        <ellipse
          className={cx(styles.indicator, styles[signal])}
          cx={0}
          cy={0}
          rx={rX}
          ry={rY}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={`${
            circumference - (percentage / 100 / 2) * circumference
          }`}
        />
        <text
          textAnchor="middle"
          className={cx(styles.value, styles[`gauge-value-${signal}`])}
          x={0}
          y={-10}
        >
          {value ?? '  '}
        </text>
      </svg>

      <div
        style={{ textAlign: 'center' }}
        className={cx(styles.value, styles[`gauge-value-${signal}`])}
      >
        {value ?? '  '}
      </div>

      <div className={styles.label}>{label}</div>
      <div className={styles.helpIcon}>
        <HoverTooltip align="right" content={dropdown} />
      </div>
    </div>
  );
};
