import { useState } from 'react';

import {
  fetchPostComments,
  deferCommentsFlags,
  deletePostComments,
  CommentsCollectionData,
  Comment,
} from 'services/api-content';
import { QueryError, QueryResponse } from 'hooks/common';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from 'react-query';

type FetchPostCommentsProps = {
  programId: number;
  postId: number;
};

type DeferCommentsFlagsProps = {
  programId: number;
  postId: number;
  commentId: number;
  comment: Comment;
};

type DeletePostCommentsProps = {
  programId: number;
  postId: number;
  commentId: number;
};

type MutationResponse<T> = {
  mutate: (data: T) => void;
  isLoading: boolean;
  errorMessage?: string;
  activeId: number | string | null;
};

export const useCommentQuery = ({
  postId,
  programId,
}: FetchPostCommentsProps): QueryResponse<CommentsCollectionData> & {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CommentsCollectionData, Error>>;
} => {
  const { refetch, isLoading, error, data } = useQuery<
    CommentsCollectionData,
    Error
  >(
    ['comment_posts', { postId, programId }],
    () => fetchPostComments({ postId, programId }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
    refetch,
  };
};

export const useDeferFlagsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: QueryError) => void;
}): MutationResponse<DeferCommentsFlagsProps> => {
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const { mutate, isLoading, error } = useMutation(
    ['comments/defer_flags'],
    deferCommentsFlags,
    {
      onSuccess,
      onError,
      onMutate: ({ commentId }) => setActiveId(commentId),
      onSettled: () => setActiveId(null),
    }
  );
  return { mutate, activeId, isLoading, errorMessage: error?.message };
};

export const useDeleteCommentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: QueryError) => void;
}): MutationResponse<DeletePostCommentsProps> => {
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const { mutate, isLoading, error } = useMutation(
    ['delete_comment'],
    deletePostComments,
    {
      onSuccess,
      onError,
      onMutate: ({ commentId }) => setActiveId(commentId),
      onSettled: () => setActiveId(null),
    }
  );
  return { mutate, activeId, isLoading, errorMessage: error?.message };
};
