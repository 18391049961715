import * as React from 'react';
import cx from 'classnames';
import { useUniqueId } from 'hooks/useUniqueId';
import styles from './flip-switch.module.css';

/**
 * 'theme' prop controls the color scheme:
 *   'default' - visually distinct states for On and Off. (default)
 *   'equal' - two equally-weighted options; toggling doesn't change color.
 *   'inactive' - colored as if disabled, but still can be toggled.
 */
export function FlipSwitch<T = true>({
  on,
  onChange,
  value = (true as unknown) as T,
  htmlForId,
  dataTest,
  theme = 'default',
  disabled,
  size = 'default',
  shape = 'default',
}: {
  on: boolean;
  onChange: (value?: T) => void;
  value?: T;
  htmlForId?: string;
  dataTest?: string;
  theme?: 'default' | 'equal' | 'inactive';
  disabled?: boolean;
  size?: 'default' | 'lg' | 'xl';
  shape?: 'default' | 'rounded';
}): JSX.Element {
  const autoId = useUniqueId();
  const htmlFor = htmlForId || autoId;

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) onChange(value);
      else onChange(undefined);
    },
    [onChange, value]
  );

  let wrapperClass = styles.wrapper;
  if (theme === 'inactive') {
    wrapperClass = cx(wrapperClass, styles.inactiveTheme);
  } else if (theme === 'equal') {
    wrapperClass = cx(wrapperClass, styles.equalTheme);
  }

  const shapeClasses = {
    rounded: styles.rounded,
    default: '',
  };

  const sizeClasses = {
    lg: styles.sizeLg,
    xl: styles.sizeXl,
    default: '',
  };

  const sizeClass = sizeClasses[size];
  const shapeClass = shapeClasses[shape];

  return (
    <div className={`${wrapperClass} ${sizeClass} ${shapeClass}`}>
      <label
        className={cx(styles.label, { [styles.checked]: on })}
        htmlFor={htmlFor}
        data-test={dataTest}
      >
        <input
          checked={on}
          className={styles.checkbox}
          id={htmlFor}
          type="checkbox"
          onChange={handleChange}
          disabled={disabled}
        />
        <span className={cx(styles.button)} />
      </label>
    </div>
  );
}

FlipSwitch.defaultProps = {
  value: true,
  htmlForId: '',
  dataTest: undefined,
  theme: 'default',
  shape: 'default',
  size: 'default',
  disabled: false,
};
