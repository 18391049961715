import React from 'react';
import cx from 'classnames';
import { CampaignResultsData } from 'services/api-insights';
import styles from '../MetricsCards.module.css';
import { NumberChart } from '../../../../../../../shared/Charts/NumberChart';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IUsersTargetedCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const chartDimensions = {
  height: 184,
  width: 225,
  padding: {
    top: 24,
    bottom: 34,
    right: 10,
    left: 10,
  },
};

export const UsersTargetedCard: React.FC<IUsersTargetedCard> = ({
  data,
  isLoading,
}) => {
  const reached = data?.campaign.stats.reached || 0;

  return (
    <div className={cx(styles.metricCard, styles.metricCardQuarter)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>Users Reached</h1>
      </div>
      {isLoading ? (
        <>
          <div className={styles.metricCardBody}>
            <LoadingChart chartDimensions={chartDimensions} />
          </div>
          <div className={styles.metricCardBody}>
            <p className={cx(styles.centeredText, styles.metricCardSubtext)} />
          </div>
        </>
      ) : (
        <>
          {' '}
          <div className={styles.metricCardBody}>
            <NumberChart data={reached} chartDimensions={chartDimensions} />
          </div>
        </>
      )}
    </div>
  );
};
