import React from 'react';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { useNavigate } from '@reach/router';
import { NavigationFooter } from 'shared/NavigationFooter';
import { useCreateUser } from 'hooks/user';
import { NewUserData } from 'services/api-user';
import { User } from 'models/user';
import { IdentityScope } from 'models/identity';
import { useUpsertUserIdentity } from 'hooks/identities';
import { useUpdateTopic } from 'hooks/topics';
import { Topic } from 'models/topic';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useRoleQuery } from 'hooks/roles';

export const UserFormFooter: React.FC<{
  data: User;
  selectedTopics: Topic[];
  identityScopes: IdentityScope[];
}> = ({ data, identityScopes, selectedTopics }) => {
  const navigate = useNavigate();
  const { id: programId } = useProgram();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );
  const exitPath = `/${programId}/app/people/users`;

  const { setFlashMessage } = useFlashMessage();

  const { upsert: upsertUserIdentity } = useUpsertUserIdentity({
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update user identity',
      }),
  });

  const { data: roleData } = useRoleQuery(programId, data.role as string);

  const { create } = useCreateUser(programId, {
    onSuccess: (response) => {
      if (showPermissionsUI?.value) {
        upsertUserIdentity({
          tenant: `program:${programId}`,
          name: `user:${response.id}`,
          roles: data.role ? [{ name: data.role }] : [],
          active: true,
          scopes: identityScopes,
        });
      }

      setFlashMessage({
        severity: 'info',
        message: 'User created successfully',
      });

      if (selectedTopics && selectedTopics.length) {
        selectedTopics.forEach((topic: Topic) => {
          updateTopic({
            ...topic,
            contributorIds: [...topic.contributorIds, response.id],
          });
        });
      }

      navigate(exitPath);
    },
    onError: (error) => {
      let message = 'Could not create user';
      try {
        message += `\n${JSON.parse(error).errors[0][1][0].message}`;
        // eslint-disable-next-line no-empty
      } catch (Error) {}
      setFlashMessage({ severity: 'error', message });
    },
  });

  const { update: updateTopic } = useUpdateTopic(programId, {
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update user topics',
      }),
  });

  const menuItems = {
    section1: [
      {
        element: <div>Menu?</div>,
        visible: true,
      },
    ],
  };

  const links = [
    {
      name: '',
      href: '.',
    },
  ];

  const canPerformAction =
    !!data.firstName?.trim() &&
    !!data.lastName?.trim() &&
    (!!data.email?.trim() || !!data.federatedIdentifier?.trim());

  const handleSave = () => {
    const roleWithNoRestrictions =
      data?.role !== 'member' &&
      !roleData?.aliases.some((x) => x.name === 'no_restrictions');

    function validateUser(): boolean {
      if (!hasScopesSelected(identityScopes, ['audience', 'topic'])) {
        setFlashMessage({
          severity: 'error',
          message:
            'At least one topic OR audience restriction must be set for this user',
        });

        return false;
      }

      return true;
    }

    if (showPermissionsUI?.value && roleWithNoRestrictions && !validateUser())
      return;

    create({
      ...data,
      adminPermission: showPermissionsUI?.value ? 'member' : data.role,
      permissions: {
        canBlock: true,
        canEdit: true,
        canHide: true,
        canPromote: true,
        canForget: true,
        canExportData: true,
      },
    } as NewUserData);
  };

  return (
    <NavigationFooter
      links={links}
      canPerformAction={canPerformAction}
      actionName="Save"
      action={handleSave}
      exitPath={exitPath}
      leftMenuItems={menuItems}
      title={`${data.firstName || ''} ${data.lastName || ''}`}
      hideMenu
    />
  );
};

function hasScopesSelected(
  identityScopes: IdentityScope[],
  needed_topics: string[]
): boolean {
  return identityScopes.some(({ type }) => needed_topics.includes(type));
}
