import qs from 'qs';
import snakecaseKeys from 'snakecase-keys';
import {
  DataBlock,
  RenderingVariables,
  Styling,
  Targets,
  RenderFlags,
} from 'models/publisher/block';
import { deepCamelcaseKeys, request } from './api-shared';
import { fqApiUrl } from './common';

export type EmailPreview = {
  displayName: string;
  client: string;
  os: string;
  category: string;
  screenshots: Record<string, string>;
  status: Status;
};

export enum Status {
  Processing = 'Processing',
  Completed = 'Completed',
}

export async function fetchEmailPreviews(props: {
  programId: number;
  referenceId: string;
}): Promise<EmailPreview[]> {
  const { programId, referenceId } = props;

  const queryString = qs.stringify(snakecaseKeys({ referenceId }));
  const response = await request(
    fqApiUrl(`api/v1/programs/${programId}/email_previews?${queryString}`)
  );

  if (response.status === 200) return response.json().then(deepCamelcaseKeys);

  throw new Error(`Error fetching email previews: ${response.status}`);
}

export async function createEmailPreviews(props: {
  programId: number;
  blocks: DataBlock[];
  styles: Styling;
  variables: RenderingVariables;
  referenceId: string;
  delivery: Targets;
  flags: RenderFlags;
}): Promise<void> {
  const {
    programId,
    blocks,
    styles,
    variables,
    referenceId,
    delivery,
    flags: { webFontsEnabled, preferOutlook365 },
  } = props;
  const isOutlook365Prefered = preferOutlook365;
  const queryString = qs.stringify({
    reference_id: referenceId,
    delivery,
    webfonts: webFontsEnabled,
    preferOutlook365: isOutlook365Prefered,
  });
  const response = await request(
    fqApiUrl(`api/v1/programs/${programId}/email_previews?${queryString}`),
    {
      method: 'POST',
      body: JSON.stringify({
        blocks,
        styles,
        meta: variables,
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status === 200) return;

  throw new Error(`Error creating email previews: ${response.status}`);
}
