import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { GenericSelect } from 'shared/Select';
import { Section } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { NumericInput } from 'shared/NumericInput';
import { useUpdateBrand, useUpdateProgram } from 'hooks/program';
import { useUser } from 'contexts/user';
import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';
import { FlashMessageType } from 'models/flash-message';
import { parseSessionTimeout, parseTimeoutValueToNumber } from '../helper';
import styles from '../session.module.css';

const predicateOptions = [
  {
    value: 'never',
    label: 'Never',
  },
  {
    value: 'every',
    label: 'Every',
  },
];

const intervalOptions = [
  {
    label: 'Minutes',
    value: 'minute',
  },
  {
    label: 'Hours',
    value: 'hour',
  },
  {
    label: 'Days',
    value: 'day',
  },
];

export const EditSessionOptions: React.FC<RouteComponentProps> = () => {
  const { page } = useParams();
  const program = useProgram();
  const { accessiblePrograms } = useUser();
  const { eeSessionTimeout, sessionTimeoutMinutes, id: programId } = program;
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();

  const brandId =
    accessiblePrograms.find((prog) => prog.id === programId)?.brandId || 0;
  const isExperience = page === 'Experience';
  const isStudio = page === 'Studio';

  const incomingTimeout = isExperience
    ? eeSessionTimeout
    : sessionTimeoutMinutes;

  const {
    predicate: parsedPredicate,
    value: timeoutValue,
    interval: parsedInterval,
  } = parseSessionTimeout(incomingTimeout, isExperience);

  const [predicate, setPredicate] = React.useState<string>(parsedPredicate);
  const [value, setValue] = React.useState<number>(timeoutValue);
  const [interval, setInterval] = React.useState<string>(parsedInterval);
  const flashMessage: FlashMessageType = {
    message: 'Program updated successfully',
    severity: 'info',
  };

  const { update: updateBrand } = useUpdateBrand(brandId, {
    onSuccess: () => setFlashMessage(flashMessage),
  });

  const { update: updateProgram } = useUpdateProgram(programId, {
    onSuccess: () => setFlashMessage(flashMessage),
    onError: (error) => {
      flashServerErrors(error, 'Could not save settings');
    },
  });

  const onSave = () => {
    if (interval === 'minute' && value < 5) {
      setFlashMessage({
        message: 'Sessions may be no shorter than 5 minutes',
        severity: 'error',
      });

      return;
    }

    const timeoutMinutes =
      predicate === 'never'
        ? null
        : parseTimeoutValueToNumber(value, interval, isExperience);

    if (isStudio && brandId) {
      updateBrand({
        id: brandId,
        slug: program.brandSlug,
        sessionTimeoutMinutes: timeoutMinutes,
      });
    }

    if (isExperience) {
      updateProgram({
        ...program,
        eeSessionTimeout: timeoutMinutes,
      });
    }
  };

  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        breadcrumbs={[
          { to: '../../..', label: 'Configure' },
          { label: 'User Session Timeout', to: '../..' },
          { label: page },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <Icon name="SaveSuccess" />,
            onClick: onSave,
          },
        ]}
        title="User Session Timeout"
      >
        {isExperience && (
          <Section title="Member Experience" className={styles.section}>
            <div>
              <h3 className={styles.itemTitle}>Session Timeout</h3>
              <div className={styles.itemDesc}>
                <span>Automatically sign users out after a period of time</span>
              </div>
              <div className={styles.row}>
                <span>Sign out inactive users after</span>
                <span className={styles.row}>
                  <GenericSelect
                    onChange={setPredicate}
                    options={predicateOptions}
                    value={predicate}
                  />
                  {predicate === 'every' && (
                    <>
                      <NumericInput
                        value={timeoutValue}
                        onChange={setValue}
                        minValue={0}
                        maxValue={999}
                        maxLength={3}
                      />
                      <GenericSelect
                        onChange={setInterval}
                        options={intervalOptions}
                        value={interval}
                      />
                    </>
                  )}
                </span>
              </div>
            </div>
          </Section>
        )}
        {isStudio && (
          <Section title="Studio" className={styles.section}>
            <div>
              <h3 className={styles.itemTitle}>Session Timeout</h3>
              <div className={styles.itemDesc}>
                <span>Automatically sign users out after a period of time</span>
              </div>
              <div className={styles.row}>
                <span>Sign out inactive users after</span>
                <span className={styles.row}>
                  <GenericSelect
                    onChange={setPredicate}
                    options={predicateOptions}
                    value={predicate}
                  />
                  {predicate === 'every' && (
                    <>
                      <NumericInput
                        value={timeoutValue}
                        onChange={setValue}
                        minValue={0}
                        maxValue={999}
                        maxLength={3}
                      />
                      <GenericSelect
                        onChange={setInterval}
                        options={intervalOptions}
                        value={interval}
                      />
                    </>
                  )}
                </span>
              </div>
            </div>
          </Section>
        )}
      </FormPage>
    </WithPermission>
  );
};
