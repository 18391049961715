import * as React from 'react';
import * as Text from 'DesignSystem/Typography';

export const Panel: React.FC<{
  title: string;
  description: string;
  helperHref?: string;
}> = ({ title, description, helperHref, children }) => (
  <>
    <Text.Body bold block>
      {title}
    </Text.Body>
    <Text.Body>
      {description}
      {helperHref && (
        <>
          <br />
          <a target="_blank" rel="noreferrer" href={helperHref}>
            Learn More
          </a>
        </>
      )}
    </Text.Body>
    <hr />
    {children}
  </>
);
