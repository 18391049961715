import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Notification } from 'models/notification';

import { Priority } from 'models/priority';
import { DeliveryType, OVERRIDE } from 'models/delivery-type';
import { MIN_RETRY_INTERVALS } from 'models/publisher/settings';
import { buildDuration, Duration } from 'models/duration';
import { useEffect, useState } from 'react';

const calculateDurationSuggestion = (
  priority: Priority,
  notifications: Notification[],
  retries: number,
  optimizedDeliveryEnabled?: boolean,
  deliveryType?: DeliveryType,
  deliveryPageVersion?: number
): Duration | undefined => {
  // No concept of a duration if there is no optimized delivery and retries
  if (
    (deliveryPageVersion === 2 &&
      optimizedDeliveryEnabled === false &&
      retries === 0) ||
    (deliveryPageVersion === 1 && deliveryType === OVERRIDE) ||
    priority === undefined
  ) {
    return undefined;
  }

  // Assuming avg number of retries for a retargeting campaign is 2
  // If notifications are greater than assumed retries, use that count instead
  // NOTE: This is where we would inject smart calc of predicted retries
  const projectedRetries = Math.max(
    retries === -1 ? 2 : retries,
    notifications.length - 1
  );

  // If optimizedDeliveryEnabled, give extra time for the initial attempt
  // Otherwise, first attempt will be sent immediately and we only need
  // to give time for the retries
  const intervals = optimizedDeliveryEnabled
    ? projectedRetries + 1
    : projectedRetries;

  const days = priority?.value
    ? MIN_RETRY_INTERVALS[priority.value] * intervals
    : 14;

  // Can't be greater than 14 or less than 3
  const adjDays = Math.max(3, Math.min(days, 14));

  return buildDuration(adjDays, 'day');
};

export const useDuration: () => number | undefined = () => {
  const [duration, setDuration] = useState<number | undefined>(undefined);

  const {
    settings: {
      deliveryType,
      optimizedDeliveryEnabled,
      retries,
      priority,
      notifications,
      deliveryPageVersion,
    },
  } = useSettings();

  useEffect(() => {
    if (priority && notifications) {
      const calculatedDuration = calculateDurationSuggestion(
        priority,
        notifications,
        retries,
        optimizedDeliveryEnabled,
        deliveryType,
        deliveryPageVersion
      );
      setDuration(calculatedDuration?.value);
    }
  }, [
    deliveryType,
    notifications,
    priority,
    retries,
    optimizedDeliveryEnabled,
    deliveryPageVersion,
  ]);

  return duration;
};
