import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Program } from 'models/program';
import { TextInput } from 'shared/TextInput';
import { MAIcon } from 'shared/MAIcon';
import { processHashtagValue, validateHashtagValue } from './utils';
import styles from './styles.module.css';

type PropsType = {
  program: Program;
  setProgram: (program: Program) => void;
  errors: Record<string, string | undefined>;
  setErrors: (errors: Record<string, string | undefined>) => void;
};

export const AppendedHashtagInput: React.FC<PropsType> = ({
  program,
  setProgram,
  errors,
  setErrors,
}) => {
  const [hashtag, setHashtag] = React.useState(program.complianceHashtag || '');
  return (
    <div className={styles.Input}>
      <Text.Subheading block bold>
        Appended Hashtag
      </Text.Subheading>
      <Text.Body block color={Text.color.gray60}>
        This hashtag will be added to all user shares from the platform. It is
        one of the ways that a user can disclose their relationship with your
        organization.
      </Text.Body>
      <TextInput
        value={hashtag}
        className={styles.TextField}
        placeholder="Enter hashtag"
        onChange={(value) => setHashtag(value)}
        onBlur={(value) => {
          if (!validateHashtagValue([value])) {
            setErrors({ ...errors, appendedHashtag: 'Invalid hashtag value' });
            return;
          }
          if (errors.appendedHashtag) {
            setErrors({ ...errors, appendedHashtag: undefined });
          }
          const newValue = processHashtagValue(value);
          setProgram({ ...program, complianceHashtag: newValue });
        }}
      />
      {errors.appendedHashtag && (
        <div className={styles.Error}>
          <MAIcon name="warning" /> {errors.appendedHashtag}
        </div>
      )}
    </div>
  );
};
