import { titleCase } from 'utility/text';
import {
  DataSource,
  FilterJson,
  isOptionDefault,
  Option,
  PlaceholderFilterJson,
  ValueGroups,
  WidgetType,
} from './json/filterJson';

const sanitizedFilterName = (filterName?: string): string =>
  filterName?.trim().replace(/\s+/, ' ') || '';

export const transformJsonToFilter = (json: FilterJson): Filter => {
  return {
    dataType: json.data_type,
    slug: json.slug,
    default: isOptionDefault(json.default) ? json.default.value : json.default,
    label: titleCase(sanitizedFilterName(json.name)),
    widgetType: json.type,
    internalId: json.internal_id,
    initialRequired: json.initial_required,
    options: json.options,
    allowMultiple: json.allow_multiple,
    allowEmpty: json.allow_empty,
    allowFiltering: json.allow_filtering,
    allowSearching: json.allow_searching,
    groupedValues: json.grouped_values,
    values: json.values,
    valuesPending: json.values_pending,
    subFilterSlug: json.subFilter,
    isSubFilter: json.isSubFilter,
  };
};

export type BaseFilter = {
  slug: string;
  label: string;
  // default usually are string or null. but category filters can have arrays of number/strings
  default?: number[] | string[] | string | number | null;
  internalId?: string;
  initialRequired?: boolean | null;
  options?: Option[];
  allowMultiple?: boolean;
  allowEmpty?: boolean;
  allowFiltering?: boolean;
  allowSearching?: boolean;
  dataType: DataSource;
  groupedValues?: ValueGroups;
  values?: Option[];
  valuesPending?: boolean;
  subFilterSlug?: string;
  isSubFilter?: boolean;
  subFilter?: Filter; // this will be manually set within a context object
};

export type PlaceholderFilter = Pick<
  Filter,
  'dataType' | 'default' | 'label' | 'slug' | 'widgetType'
>;

export const transformJsonToPlaceholderFilter = (
  json: PlaceholderFilterJson
): PlaceholderFilter => {
  return {
    dataType: json.data_type,
    slug: json.slug,
    default: isOptionDefault(json.default) ? json.default.value : json.default,
    label: titleCase(sanitizedFilterName(json.name)),
    widgetType: json.type,
  };
};

// ---------------------------

export type DateRangeFilter = BaseFilter & {
  widgetType: WidgetType.Date;
};
export function isDateRangeFilter(filter: Filter): filter is DateRangeFilter {
  return (filter as DateRangeFilter).widgetType === WidgetType.Date;
}
export function isDefaultDateRangeFilter(filter: Filter): boolean {
  return (
    isDateRangeFilter(filter) &&
    (filter.slug === 'date' ||
      filter.slug === 'date_range' ||
      filter.slug === 'date_filter')
  );
}

export function isRequiredFilter(filter: Filter): boolean {
  return filter.initialRequired || isDefaultDateRangeFilter(filter);
}

export function isRequiredOrPaginatedFilter(filter: Filter): boolean {
  return (
    filter.initialRequired ||
    isDefaultDateRangeFilter(filter) ||
    PaginatedFilter(filter)
  );
}

export function PaginatedFilter(filter: Filter): boolean {
  return (
    filter.dataType === DataSource.Publishers ||
    filter.dataType === DataSource.AuthorAlias ||
    filter.dataType === DataSource.Initiatives ||
    filter.dataType === DataSource.Topics ||
    filter.dataType === DataSource.Campaigns
  );
}

export type NumberInputFilter = BaseFilter & {
  widgetType: WidgetType.Number;
};
export function isNumberInputFilter(
  filter: Filter
): filter is NumberInputFilter {
  return (filter as NumberInputFilter).widgetType === WidgetType.Number;
}

export type TextInputFilter = BaseFilter & {
  widgetType: WidgetType.Text;
};
export function isTextInputFilter(filter: Filter): filter is TextInputFilter {
  return (filter as TextInputFilter).widgetType === WidgetType.Text;
}

export type BooleanFilter = BaseFilter & {
  widgetType: WidgetType.Toggle;
};
export function isBooleanFilter(filter: Filter): filter is BooleanFilter {
  return (filter as BooleanFilter).widgetType === WidgetType.Toggle;
}

export type ListFilter = BaseFilter & {
  widgetType: WidgetType.Category;
};
export function isListFilter(filter: Filter): filter is ListFilter {
  return (filter as ListFilter).widgetType === WidgetType.Category;
}

// these are all different types of List filters
// however due to a typescript issue we cannot include these in the list of `Filter` definitions
export type ViewByFilter = ListFilter & {
  dataType: DataSource.ViewBy;
  groupedValues: ValueGroups;
};
export function isViewByFilter(filter: ListFilter): filter is ViewByFilter {
  return (
    (filter as ListFilter).dataType === DataSource.ViewBy &&
    !!(filter as ListFilter).groupedValues
  );
}

// studio API: samba/programs/:p_id/users/authors?query=&page=1
export type PublisherFilter = ListFilter & {
  dataType: DataSource.Publishers;
};
export function isPublisherFilter(
  filter: ListFilter
): filter is PublisherFilter {
  return (filter as ListFilter).dataType === DataSource.Publishers;
}

// studio API: samba/programs/:p_id/author_aliases?query=&page=1
export type AuthorAliasFilter = ListFilter & {
  dataType: DataSource.AuthorAlias;
};
export function isAuthorAliasFilter(
  filter: ListFilter
): filter is AuthorAliasFilter {
  return (filter as ListFilter).dataType === DataSource.AuthorAlias;
}

export type CampaignFilter = ListFilter & {
  dataType: DataSource.Campaigns;
};
export function isCampaignFilter(filter: ListFilter): filter is CampaignFilter {
  return (filter as ListFilter).dataType === DataSource.Campaigns;
}

export type ContentPostFilter = ListFilter & {
  dataType: DataSource.ContentPosts;
};
export function isContentPostFilter(
  filter: ListFilter
): filter is ContentPostFilter {
  return (
    (filter as ListFilter).dataType === DataSource.ContentPosts &&
    !(filter as ListFilter).slug.match(/source|type/gi)
  );
}

// filter.data_type = 'channels', filter.name = 'Channels'
// NOTE! internally dojo/metabase refers to this as channels
// on the front end however we will refer to these as topics
// we manually override the filter label in context/Report.tsx
// in the future we may want to change the internal name as well in dojo/metabase
// studio API: /programs/176/content_channels?
//             page=1&page_size=10000&status%5B%5D=active&search=&
//             sort_by=updated_at&sort_direction=desc&include_default=true
export type TopicFilter = ListFilter & {
  dataType: DataSource.Topics;
};
export function isTopicFilter(filter: ListFilter): filter is TopicFilter {
  return (filter as ListFilter).dataType === DataSource.Topics;
}

export type PollFilter = ListFilter & {
  dataType: DataSource.Poll;
};

export function isPollFilter(filter: ListFilter): filter is PollFilter {
  return (filter as ListFilter).dataType === DataSource.Poll;
}

// filter.data_type = 'initiatives'
export type InitiativeFilter = ListFilter & {
  dataType: DataSource.Initiatives;
};
export function isInitiativeFilter(
  filter: ListFilter
): filter is InitiativeFilter {
  return (filter as ListFilter).dataType === DataSource.Initiatives;
}

// filter.values = []. if this is the case then we treat it as a static dropdown filter
// in the implementing UI, we should check to ensure that the previous list types have been exhausted first

export type StaticListFilter = ListFilter & {
  values: Option[];
};
export function isStaticListFilter(
  filter: ListFilter
): filter is StaticListFilter {
  return (
    filter.values !== undefined &&
    Array.isArray(filter.values) &&
    filter.values.length > 0 &&
    filter.values.every(
      (val) => val.value !== undefined && val.label !== undefined
    )
  );
}

/* A Dojo filter are category filters and has a values_pending=true
 * such filters expect to make an additional API request to dojo for filter values
 * api uses `filter_slug` that comes from the filter params
 * program/:p_id/reports/:r_id/filters/:filter_slug/?date_range='
 * */
export type DojoListFilter = ListFilter & {
  dataType: DataSource.Attributes;
  valuesPending: boolean;
};
export function isDojoListFilter(filter: Filter): filter is DojoListFilter {
  return isListFilter(filter) && !!filter.valuesPending;
}

export const isAttributeFilter = (filter: Filter): boolean =>
  filter.dataType === DataSource.Attributes ||
  filter.slug.indexOf('attribute_') >= 0;

export type Filter =
  | DateRangeFilter
  | BooleanFilter
  | NumberInputFilter
  | TextInputFilter
  | ListFilter;
