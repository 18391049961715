import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  NodeProps,
  Controls,
  Panel,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useQueryClient } from 'react-query';
import cx from 'classnames';
import { useJourneyState } from 'contexts/journeys/journey';
import { JourneyGraph, Steps } from 'models/journeys/journey';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { journeysKeys } from 'hooks/journeys/journeys';
import { useValidateJourney } from 'services/api-journey';
import { DefaultEdge, DecisionEdge } from './edges/BaseEdge';
import {
  CommunicationNode,
  DecisionNode,
  DelayNode,
  EndNode,
  StartNode,
} from './nodes';
import { JourneyDrawer } from '../JourneyDrawer';
import { JourneyCanvasHeader } from '../JourneyCanvasHeader';
import { buildGraph } from './utils/graph';
import { JourneyContentListDrawer } from '../JourneyContentListDrawer';
import { DrawerState } from '../JourneyDrawer/drawer';
import { JourneyMetricsTypeMenu } from './JourneyMetricsTypeMenu';
import styles from './canvas.module.css';
import { usePollProcessingJourneys } from '../use-poll-processing-journeys';
import { ErrorJourneyModal } from '../JourneyCanvasHeader/PublishModals/ErrorJourneyModal';
import { JourneyContentDesignProvider } from '../JourneyContentDesigner/JourneyContentDesignProvider';

const nodeElements: Record<
  keyof Steps,
  React.MemoExoticComponent<(props: NodeProps) => JSX.Element>
> = {
  communication: CommunicationNode,
  end: EndNode,
  delay: DelayNode,
  decision: DecisionNode,
  start: StartNode,
};

const edgeElements = {
  baseEdge: DefaultEdge,
  decisionEdge: DecisionEdge,
};

export const JourneyCanvas: React.FC = () => {
  const {
    journey,
    currentGraph,
    drawerState,
    setDrawerState,
  } = useJourneyState();
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();
  const programId = useProgram().id;
  const [shouldPoll, setShouldPoll] = useState(journey?.state === 'processing');
  const [showErrorsModal, setShowErrorsModal] = useState(false);

  // Avoid invalidating queries when polling to persist error dialog
  const { publishedJourneys, updatedJourneys } = usePollProcessingJourneys(
    [String(journey?.id)],
    {
      enabled: shouldPoll,
      invalidateQueries: false,
    }
  );

  const hasFinishedProcessing =
    publishedJourneys !== undefined && publishedJourneys.length > 0;

  if (hasFinishedProcessing && shouldPoll) {
    setFlashMessage({
      message: 'This journey has finished processing.',
      severity: 'info',
    });
    setShouldPoll(false);
    queryClient.invalidateQueries([...journeysKeys.details()]);
  }

  const updated = updatedJourneys !== undefined && updatedJourneys.length > 0;
  const { errors } = useValidateJourney({
    programId,
    journey: updated ? journey : undefined,
  });

  const handleCloseErrorModal = useCallback(() => {
    // Refresh the journey details to get the latest state
    setShowErrorsModal(false);
    queryClient.invalidateQueries([...journeysKeys.details()]);
  }, [queryClient]);

  if (errors && updated && shouldPoll) {
    setShowErrorsModal(true);
    setShouldPoll(false);
  }

  if (!journey || !currentGraph) return null;
  return (
    <>
      <ReactFlowProvider>
        <JourneyContentDesignProvider>
          <JourneyCanvasHeader />
          <Flow
            graph={currentGraph}
            drawerState={drawerState}
            setDrawerState={setDrawerState}
          />
          {showErrorsModal && (
            <ErrorJourneyModal
              action={handleCloseErrorModal}
              title="Processing Error"
              error="There was an error processing an asset. Try publishing again, and if the error persists, you may need to replace the asset."
            />
          )}
          <JourneyDrawer />
          <JourneyContentListDrawer />
        </JourneyContentDesignProvider>
      </ReactFlowProvider>
    </>
  );
};

const Flow: React.FC<{
  graph: JourneyGraph;
  drawerState: DrawerState;
  setDrawerState: (state: DrawerState) => void;
}> = ({ graph, drawerState, setDrawerState }) => {
  const { edges, nodes } = React.useMemo(() => buildGraph(graph), [graph]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [flowViewY, setFlowViewY] = useState(0);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const NAVBAR_HEIGHT = 48;
      const canvasRect = containerRef.current.getBoundingClientRect();
      // set vertical mid point of flow screen
      setFlowViewY((canvasRect.height - NAVBAR_HEIGHT) / 2);
    }
  }, []);

  const canvasClasses = cx(styles.journeyCanvas, {
    [styles.open]: drawerState === DrawerState.Partial,
  });

  const closeDrawer = () =>
    drawerState !== DrawerState.Closed && setDrawerState(DrawerState.Closed);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div ref={containerRef} className={canvasClasses}>
      {flowViewY > 0 ? (
        // conditional statement needed because `defaultViewport` will keep only inital value for y axis
        <ReactFlow
          nodesDraggable={false}
          nodesConnectable={false}
          preventScrolling
          zoomOnDoubleClick={false}
          defaultViewport={{ x: 24, y: flowViewY, zoom: 1 }}
          minZoom={0.5}
          maxZoom={1}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeElements}
          edgeTypes={edgeElements}
          proOptions={{ hideAttribution: true }}
          nodeOrigin={[0, 0.5]}
          onPaneClick={closeDrawer}
        >
          <Controls showInteractive={false} position="top-left" />
          <Panel position="top-right">
            <JourneyMetricsTypeMenu />
          </Panel>
        </ReactFlow>
      ) : (
        <></>
      )}
    </div>
  );
};
