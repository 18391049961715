import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';
import { BulkUploadJob } from 'models/bulk-upload-job';

export type UserImportFetchParams = {
  search?: string;
  page?: { size: number; number: number };
  pageSize?: number;
  filter?: string | string[];
  programId: number;
  order: string;
};

export type UserImportCollectionData = {
  data: Array<BulkUploadJob>;
  meta: {
    totalObjects: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  };
};

export type ImportsErrorReportType = {
  csvRows: string;
};

export const fetchUserImports = async (
  props: UserImportFetchParams
): Promise<UserImportCollectionData> => {
  const { programId, ...queryParams } = props;
  const { filter } = props;
  let query = qs.stringify(snakeCaseKeys({ ...queryParams, filter }), {
    arrayFormat: 'brackets',
  });

  query = filter && filter === 'complete' ? `${query},partial` : query;
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs?${query}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching imports`);
};

export const fetchJobReport = async (
  programId: number,
  jobId: number
): Promise<ImportsErrorReportType> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs/${jobId}/job_data`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error('Error generating job reports');
};

export const fetchImportJob = async (
  programId: number,
  jobId: number
): Promise<BulkUploadJob> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs/${jobId}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching imports`);
};

export const fetchErrorReport = async (
  programId: number,
  jobId: number
): Promise<ImportsErrorReportType> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs/${jobId}/error_report`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error('Error generating error reports');
};
