import React, { useCallback, useEffect, useMemo, useState } from 'react';
import equal from 'fast-deep-equal';
import { useProgram } from 'contexts/program';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
  useUpdateProgramCustomizations,
} from 'hooks/feature-flags';
import {
  CHANNELS_ALL_ENABLED,
  Channel,
  DeliveryChannels,
  defaultChannels,
  withModifiers,
  withProgramChannelDefaults,
} from 'models/channel';
import { FeatureFlag } from 'models/feature-flag';
import { MAIcon } from 'shared/MAIcon';
import { useNotificationCenterLabel } from 'hooks/notification-center';
import styles from './default-channels.module.css';

export type DeliveryChannelOption = {
  id: number;
  name: Channel;
  label: string;
  checked: boolean;
  icon: JSX.Element;
  smallIcon: JSX.Element;
};

export const useDefaultChannels = (
  onSaveSuccess?: () => void,
  onSaveError?: () => void
): {
  enabled: boolean;
  channelsValues: Array<DeliveryChannelOption>;
  deliveryChannels: DeliveryChannels;
  isDefault: boolean;
  setDeliveryChannels: (channels: DeliveryChannels) => void;
  setToDefaults: () => void;
  onSave: () => void;
} => {
  const { id: programId } = useProgram();
  const { data: programCustomizations } = useProgramCustomizationsQuery(
    programId
  );
  const { update } = useUpdateProgramCustomizations(programId, {
    onSuccess: onSaveSuccess,
    onError: onSaveError,
  });

  const setChannelDefaultsEnabled = !!useFeatureFlagsQuery(
    programId,
    'SetChannelDefaults.Enabled'
  ).data?.value;

  const selectedChannels = useMemo(
    () =>
      withModifiers(
        [
          withProgramChannelDefaults(
            programCustomizations?.channelDefaults?.value,
            setChannelDefaultsEnabled
          ),
        ],
        defaultChannels
      ),
    [programCustomizations?.channelDefaults?.value, setChannelDefaultsEnabled]
  );

  const notificationCenter = useNotificationCenterLabel();

  const channelsValues: Array<DeliveryChannelOption> = [
    {
      id: 1,
      name: 'email',
      label: 'Email',
      checked: selectedChannels.email,
      icon: <MAIcon name="mail" />,
      smallIcon: <MAIcon name="mail" className={styles.SmallIcon} />,
    },
    {
      id: 2,
      name: 'push',
      label: 'Push Notification',
      checked: selectedChannels.push,
      icon: <MAIcon name="phone_iphone" />,
      smallIcon: <MAIcon name="phone_iphone" className={styles.SmallIcon} />,
    },
    {
      id: 3,
      name: 'assistant',
      label: notificationCenter,
      checked: selectedChannels.assistant,
      icon: <MAIcon name="lightbulb" />,
      smallIcon: <MAIcon name="lightbulb" className={styles.SmallIcon} />,
    },
  ];

  const [deliveryChannels, setDeliveryChannels] = useState(selectedChannels);
  useEffect(() => {
    setDeliveryChannels(selectedChannels);
  }, [setDeliveryChannels, selectedChannels]);

  const isDefault = equal(deliveryChannels, CHANNELS_ALL_ENABLED);
  const setToDefaults = () => {
    setDeliveryChannels(CHANNELS_ALL_ENABLED);
  };
  const onSave = useCallback(() => {
    const json = JSON.stringify(deliveryChannels);

    const featureFlag: FeatureFlag = {
      value: json,
      programId,
      type: 'channel_defaults',
      label: 'Channel Defaults',
    };

    update(featureFlag);
  }, [deliveryChannels, programId, update]);

  return {
    enabled: setChannelDefaultsEnabled,
    channelsValues,
    deliveryChannels,
    isDefault,
    setDeliveryChannels,
    setToDefaults,
    onSave,
  };
};
