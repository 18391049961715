import * as React from 'react';
import { Caption, background } from 'DesignSystem/Typography';
import { FieldInput, Button } from 'DesignSystem/Form';
import { ImagePicker } from 'shared/Library/ImagePicker';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useToggle, useVisibility } from 'hooks/useToggle';
import { Box } from 'DesignSystem/Components';
import { SocialData } from 'models/publisher/block';
import { IMAGE_ACCEPT_STRING } from 'models/image';
import { FieldLegend } from '../../shared/FieldLegend';

export const ImageInput: React.FC<{
  value: SocialData['image'];
  isUploading: boolean;
  onChange: (image: SocialData['image'] | File) => void;
  onReset: (image: SocialData['image']) => void;
}> = ({ value, onChange, isUploading }) => {
  const viz = useVisibility();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  return (
    <FieldInput
      htmlFor="social-edit-icon"
      legend={<FieldLegend>Image</FieldLegend>}
    >
      <div
        onMouseLeave={viz.disable}
        onBlur={() => {
          // inner clicks will fire this in most browsers, but
          // we don't want it to actually close.
        }}
        onMouseMove={viz.keepopen}
        style={{ display: 'inline-block', position: 'relative' }}
      >
        <button
          style={{ border: 'none', background: 'transparent', padding: '0' }}
          type="button"
          onClick={viz.enable}
          id="social-edit-icon"
        >
          <Box
            radius={4}
            style={{
              border: 'solid 1px var(--color-gray30)',
              cursor: 'pointer',
            }}
          >
            <Box padding={4}>
              {isUploading && <LoadingSpinner />}
              {isUploading || !value.url ? null : (
                <img
                  src={value.url}
                  alt="Social Network Icon"
                  style={{
                    display: 'block',
                    width: '68px',
                    height: 'auto',
                  }}
                />
              )}
            </Box>
            <Box
              radius={[0, 0, 4, 4]}
              padding={4}
              background={background.gray05}
            >
              <Caption>Replace</Caption>
            </Box>
          </Box>
        </button>
        <input
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
          accept={IMAGE_ACCEPT_STRING}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            viz.toggle();
            const file = (event.currentTarget.files || [])[0];
            if (file) onChange(file);
          }}
          onAbort={() => viz.toggle()}
        />
        <ImageMenu
          visible={viz.value}
          onChange={onChange}
          onClose={viz.toggle}
          fileInputRef={fileInputRef}
        />
      </div>
    </FieldInput>
  );
};

const ImageMenu: React.FC<{
  visible: boolean;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  onClose(): void;
  onChange(data: SocialData['image'] | File): void;
}> = ({ fileInputRef, onClose, onChange, visible }) => {
  const library = useToggle();
  if (!visible) return null;
  return (
    <Box
      style={{ boxShadow: '0 2px 5px var(--color-gray30)' }}
      absolute
      top="20%"
      left="20%"
      background={background.gray00}
      radius={4}
    >
      <div>
        <Button
          onClick={() => fileInputRef.current?.click()}
          block
          clearText
          label={<>Upload File</>}
        />
      </div>
      <Button
        onClick={library.enable}
        block
        clearText
        label={<>From Library</>}
      />
      {library.value && (
        <ImagePicker
          useModal
          maxSelections={1}
          onCancel={() => {
            library.disable();
            onClose();
          }}
          onChange={(images) => {
            const img = images[0];
            if (img) onChange({ url: img.asset.url, processed: false });
            library.disable();
            onClose();
          }}
        />
      )}
    </Box>
  );
};
