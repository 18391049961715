import React, { useRef } from 'react';
import * as Froala from 'components/publisher/blocks/instances/froala-configs';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { TipTapMenuBar } from 'components/publisher/blocks/instances/TipTapMenuBar';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { CodeViewCallback } from './useEditableInstance';

export const TipTapFieldEditor: React.FC<{
  field: string;
  initialValue: string;
  kind: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  codeViewCallback: CodeViewCallback;
  froalaOptions: Froala.Options;
}> = ({ initialValue, onFocus, onChange }) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const onFocusRef = useRef(onFocus);
  onFocusRef.current = onFocus;

  const theEditor = useEditor({
    extensions: [StarterKit, TextStyle, Color],
    content: initialValue,
    onFocus: () => onFocusRef.current(),
    onUpdate: ({ editor }) => {
      onChangeRef.current(editor.getHTML());
    },
  });

  return (
    <>
      <TipTapMenuBar editor={theEditor} />
      <EditorContent editor={theEditor} />
    </>
  );
};
