import * as React from 'react';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import {
  useAudienceArchive,
  useAudienceDuplicate,
  useAudienceUnarchive,
  useAudienceCreateSnapshot,
  useAddAudienceToFavorites,
  useRemoveAudienceFromFavorites,
} from 'hooks/audience';
import { useQueryClient } from 'react-query';
import { Audience } from 'models/audience';
import { AudienceBanner } from './AudienceBanner';
import {
  audienceToMenuItems,
  audienceToAudienceTypeStat,
  audienceToThumbnail,
  audienceToQueryTypeStat,
  audienceToUsersCountStat,
  audienceToTagsStat,
} from './mappings';

type PropsType = {
  audience: Audience;
  favoriteAudiences?: number[];
  hideUserCount?: boolean;
};

export const AudiencesListItem: React.FC<PropsType> = (props) => {
  const { id: programId } = useProgram();
  const permissions = usePermissions();
  const { audience, favoriteAudiences, hideUserCount } = props;
  const queryClient = useQueryClient();

  const favoriteAudience = audience.id
    ? favoriteAudiences?.includes(Number(audience.id))
    : false;
  const { addToFavorites } = useAddAudienceToFavorites(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['favoriteAudiences']);
    },
  });

  const { removeFromFavorites } = useRemoveAudienceFromFavorites(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['favoriteAudiences']);
    },
  });

  const { archive } = useAudienceArchive(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['audiences-infinite']);
    },
  });

  const { unarchive } = useAudienceUnarchive(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['audiences-infinite']);
    },
  });

  const { duplicate } = useAudienceDuplicate(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['audiences-infinite']);
    },
  });

  const { createSnapshot } = useAudienceCreateSnapshot(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['audiences-infinite']);
    },
  });
  const thumbnail = audienceToThumbnail(audience);
  const menuItems = audienceToMenuItems(
    programId,
    audience,
    permissions,
    duplicate,
    archive,
    unarchive,
    createSnapshot,
    addToFavorites,
    removeFromFavorites,
    favoriteAudience
  );

  const audienceType = audienceToAudienceTypeStat(audience);
  const queryType = audienceToQueryTypeStat(audience);
  const tags = audienceToTagsStat(audience);
  const userCount = audienceToUsersCountStat(audience);

  return (
    <AudienceBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      headline={audience.title}
      description={audience.description}
      userCount={userCount}
      audienceType={audienceType}
      queryType={queryType}
      tags={tags}
      lastUpdate={audience.updatedAt}
      creator={audience.creator}
      createdAt={audience.createdAt}
      rowId={audience.id}
      hideUserCount={hideUserCount}
    />
  );
};
