import * as React from 'react';
import { LinkData, SocialData } from 'models/publisher/block';
import { ConfirmDelete } from 'shared/ConfirmDelete';
import { useLinkFetcher } from 'hooks/useLinkFetcher';
import {
  useContentImageUploader,
  useProcessedContentImage,
} from 'hooks/useContentImage';
import { isImageData, ImageData } from 'models/image';
import { useFlashMessage } from 'contexts/flasher';
import { usePublisher } from 'contexts/publisher';
import { UPLOAD } from 'components/publisher/blocks/forms/fields/shared/SourceMenuConst';
import { useLinksSourceMenu, OPENED, LIBRARY } from '../useLinksSourceMenu';
import { External } from './External';
import { FormBody } from './FormBody';
import { Library } from './Library';
import styles from './link-fetcher.module.css';

type PropsType = {
  initial?: LinkData | SocialData;
  source: ReturnType<typeof useLinksSourceMenu>;
  onRemoveLink?: () => void;
  onChangeLink: (link: LinkData | SocialData) => void;
  onUrlChange: (url: string) => void;
  onApplyCollection: (link: LinkData[]) => void;
  onError?: (message: string) => void;
  onLoading?: (loading: boolean) => void;
  onCancel: () => void;
  setApplyDisabled: (value: boolean) => void;
};

export const Editor: React.FC<PropsType> = ({
  initial,
  onRemoveLink = () => {},
  onError = () => {},
  onLoading = () => {},
  onChangeLink,
  onUrlChange,
  onApplyCollection,
  source,
  onCancel,
  setApplyDisabled,
}) => {
  const [isDeleting, setDeleting] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { isEditingTemplate } = usePublisher();

  const remove = React.useCallback(() => {
    onRemoveLink();
    setDeleting(false);
  }, [onRemoveLink]);
  const fetcher = useLinkFetcher({
    initial,
    type: initial ? initial.type : 'link',
  });
  const { setFlashMessage } = useFlashMessage();
  const [imageProcessingErrored, setImageProcessingErrored] = React.useState(
    false
  );

  const onUploadOrProcessError = React.useCallback(
    (e) => {
      setFlashMessage({
        severity: 'error',
        message: e,
      });
      setImageProcessingErrored(true);
      setIsProcessing(false);
    },
    [setFlashMessage]
  );

  const { uploadUrl, uploadFile, isUploading } = useContentImageUploader({
    onUpload: fetcher.setImage,
    onError: onUploadOrProcessError,
  });

  React.useEffect(() => {
    setApplyDisabled(isUploading || isProcessing || imageProcessingErrored);
  }, [
    fetcher.link.image,
    fetcher.link.images,
    imageProcessingErrored,
    isProcessing,
    isUploading,
    setApplyDisabled,
  ]);

  const setLinkImage = React.useCallback(
    (data: ImageData | File) => {
      if (isImageData(data)) uploadUrl(data.url);
      else uploadFile(data);
      setImageProcessingErrored(false);

      // we are using useHostImageFileMutation in useContentImage that calls for
      // host_image endpoint. These images are not ContentImages that are being processed.
      // If we wish to process these images, useProcessedContentImage
      // also needs to accomodate for the fact
      if (!isEditingTemplate) {
        setIsProcessing(true);
      }
    },
    [uploadUrl, uploadFile, isEditingTemplate]
  );

  const setUrl = React.useCallback(
    (url) => {
      fetcher.setUrl(url);
      setImageProcessingErrored(false);
    },
    [fetcher]
  );

  useProcessedContentImage({
    image: fetcher.link.image,
    onProcessed: (data) => {
      fetcher.setImage(data);
      setIsProcessing(false);
    },
    onError: onUploadOrProcessError,
  });

  React.useEffect(() => {
    if (fetcher.error) onError(fetcher.error);
    else onError('');
  }, [onError, fetcher.error]);

  React.useEffect(() => {
    onLoading(fetcher.isLoading);
  }, [onLoading, fetcher.isLoading]);

  const isCustomImage =
    fetcher.link.images.some((i) => i.source === UPLOAD) ||
    fetcher.link.image.source === UPLOAD;

  const hasPlaceholderImage = initial?.image?.isPlaceholder;
  const [autoRun, setAutoRun] = React.useState(fetcher.link.uuid);

  React.useEffect(() => {
    if (fetcher.link.uuid !== autoRun) {
      const linkImg = fetcher.link.images[0];
      if (linkImg && (!isCustomImage || hasPlaceholderImage)) {
        setLinkImage({ ...linkImg, processed: linkImg.processed ?? false });
        setAutoRun(fetcher.link.uuid);
      }
    }
  }, [
    setLinkImage,
    hasPlaceholderImage,
    autoRun,
    fetcher.link.uuid,
    fetcher.link.images,
    fetcher,
    isCustomImage,
  ]);

  return (
    <div className={styles.container}>
      {isDeleting && (
        <ConfirmDelete
          entity="Link"
          onRemove={remove}
          cancelRemove={() => setDeleting(false)}
        />
      )}
      <External
        hasChanges={fetcher.hasChanges}
        link={fetcher.link}
        setImage={setLinkImage}
        error={fetcher.error}
        source={source}
        setUrl={setUrl}
        initial={initial}
        onChange={onChangeLink}
        onUrlChange={onUrlChange}
        loading={fetcher.isLoading}
      />
      {source.visibility === OPENED && source.source === LIBRARY && (
        <Library
          onChange={(images: LinkData[]) => {
            source.close();
            if (!images.length) onCancel();
            if (images.length) onApplyCollection(images);
          }}
          onClose={() => {
            source.close();
            onCancel();
          }}
          maxSelections={50}
        />
      )}
      <FormBody
        imageProcessingErrored={imageProcessingErrored}
        hasChanges={fetcher.hasChanges}
        onChange={onChangeLink}
        initial={initial}
        isCustomImage={isCustomImage}
        link={fetcher.link}
        deleteImage={fetcher.deleteImage}
        canDeleteImage={fetcher.canDeleteImage}
        setDescription={fetcher.setDescription}
        setOpenInNewTab={fetcher.setOpenInNewTab}
        setImage={setLinkImage}
        isUploading={isUploading || isProcessing}
        setAltText={fetcher.setAltText}
        setTitle={fetcher.setTitle}
        setCallToAction={fetcher.setCallToAction}
        setReadTime={fetcher.setReadTime}
      />
    </div>
  );
};
