import React from 'react';
import { useDesign } from 'contexts/design';
import { useProgramIdState } from 'contexts/program';
import {
  useContentAttachmentQuery,
  useDesignAttachmentQuery,
} from 'hooks/attachment';
import { AttachmentData } from 'models/attachment';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { Caption } from 'DesignSystem/Typography';
import { ReactComponent as ScanningSVG } from '../icons/scanning.svg';
import { ReactComponent as CleanSVG } from '../icons/clean.svg';
import { ReactComponent as InfectedSVG } from '../icons/infected.svg';
import styles from './virus-scan.module.css';

type PropsType = {
  id: string;
  initialStatus: string;
};

const REFRESH_STATUSES = ['waiting', 'scanning'];

const STATUS_TEXT = {
  scanning: 'Scanning file',
  clean: 'Safe for use',
  infected: 'Virus detected',
  error: 'Error during scan',
};

const STATUS_SUB_TEXT = {
  scanning: 'Duplication disabled until scan is complete',
  clean: null,
  infected: 'Remove or replace the file',
  error: 'Remove or replace the file',
};

function iconForStatus(status: string): React.ReactElement | null {
  switch (status) {
    case 'waiting':
    case 'scanning':
      return (
        <div className={styles.scanningAnimation}>
          <ScanningSVG />
        </div>
      );
    case 'unsafe':
    case 'error':
      return <InfectedSVG />;
    case 'safe':
      return <CleanSVG />;
    default:
      return null;
  }
}

function textForStatus(status: string): string | null {
  switch (status) {
    case 'waiting':
    case 'scanning':
      return STATUS_TEXT.scanning;
    case 'unsafe':
      return STATUS_TEXT.infected;
    case 'error':
      return STATUS_TEXT.error;
    case 'safe':
      return STATUS_TEXT.clean;
    default:
      return null;
  }
}

function subTextForStatus(status: string): string | null {
  switch (status) {
    case 'waiting':
    case 'scanning':
      return STATUS_SUB_TEXT.scanning;
    case 'unsafe':
      return STATUS_SUB_TEXT.infected;
    case 'error':
      return STATUS_SUB_TEXT.error;
    case 'safe':
      return STATUS_SUB_TEXT.clean;
    default:
      return null;
  }
}

export const VirusScanStatus: React.FC<PropsType> = ({ id, initialStatus }) => {
  const [programId] = useProgramIdState();
  const { active: isDesignAsset } = useDesign();
  const [status, setStatus] = React.useState(initialStatus);
  const poller = React.useRef<ReturnType<typeof window.setTimeout>>();

  const attachmentQueryProps = {
    programId,
    id,
    enabled: false,
  };
  const contentAttachmentQuery = useContentAttachmentQuery(
    attachmentQueryProps
  );
  const designAttachmentQuery = useDesignAttachmentQuery(attachmentQueryProps);
  const { refetch } = isDesignAsset
    ? designAttachmentQuery
    : contentAttachmentQuery;

  const refresh = React.useCallback(async () => {
    if (!REFRESH_STATUSES.includes(status)) return;
    const { data } = await refetch();
    const attachmentData = data as AttachmentData;

    setStatus(attachmentData.status);
    poller.current = setTimeout(refresh, 5000);
  }, [refetch, status]);

  React.useEffect(() => {
    refresh();
    return () => {
      if (poller.current) clearTimeout(poller.current);
    };
  }, [refresh]);

  const subText = subTextForStatus(status);

  return (
    <>
      <Flex start>
        {iconForStatus(status)}
        <Space />
        {textForStatus(status)}
      </Flex>
      {subText && <Caption>{subText}</Caption>}
    </>
  );
};
