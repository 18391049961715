import * as React from 'react';
import cx from 'classnames';
import { ChangeEventHandler } from 'react';
import { useUniqueId } from 'hooks/useUniqueId';
import styles from './Checkbox.module.css';
import { SVGIcon } from '../Icon/SVGIcon';
import { Circle } from '../icons';

export type CheckboxType = {
  onChange?: (checked: boolean) => void;
  onSelect?: () => void;
  onDeselect?: () => void;
  onClick?: (e: React.MouseEvent) => void;
  defaultChecked?: boolean;
  checked: boolean | 'partial';
  name?: string;
  id?: string;
  type?: 'checkbox' | 'radio';
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  checkedClassName?: string;
  disabledClassName?: string;
  labelClassName?: string;
  label?: React.ReactNode;
};

export const Checkbox: React.FC<CheckboxType> = ({
  onChange,
  onClick,
  onSelect,
  onDeselect,
  checked,
  name,
  id,
  type = 'checkbox',
  style,
  disabled,
  className,
  checkedClassName,
  disabledClassName,
  labelClassName,
  label,
}) => {
  const handleChange = React.useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (disabled) {
        return;
      }
      if (e.target.checked) {
        if (onChange) onChange(true);
        if (onSelect) onSelect();
      } else {
        if (onChange) onChange(false);
        if (onDeselect) onDeselect();
      }
    },
    [disabled, onChange, onSelect, onDeselect]
  );

  const generatedId = useUniqueId();
  const inputId = id ?? generatedId;

  const content = React.useMemo(() => {
    if (type === 'radio') return <Circle />;
    if (checked === 'partial') return <SVGIcon name="BulkDeselect" />;
    return <SVGIcon name="Checkbox" />;
  }, [type, checked]);
  return (
    <label htmlFor={inputId} style={{ display: 'flex' }}>
      <div
        className={cx(styles.checkboxContainer, className)}
        style={style || {}}
      >
        <input
          className={styles.hiddenCheckbox}
          name={name}
          type={type === 'radio' ? 'radio' : 'checkbox'}
          id={inputId}
          onChange={handleChange}
          checked={!!checked}
          onClick={onClick}
        />
        <div
          className={cx(styles.styledCheckbox, 'styled-checkbox', {
            [styles[type]]: !!type,
            [styles.checkbox]: !type,
            [`${checkedClassName}`]: checked && checkedClassName,
            [styles.checked]: checked && !checkedClassName,
            [styles.unchecked]: !checked,
            [`${disabledClassName}`]: disabled && disabledClassName,
            [styles.disabled]: disabled && !disabledClassName,
          })}
        >
          {content}
        </div>
      </div>
      {label && (
        <div className={cx(styles.checkboxLabel, labelClassName)}>{label}</div>
      )}
    </label>
  );
};
