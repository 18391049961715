import { FetchProps } from 'services/api-workflows';

export type DefaultListPage = {
  label: string;
  key: string;
  fetchProps: Partial<FetchProps>;
};

const pageSize = 20;

export const defaultListPages: DefaultListPage[] = [
  {
    key: 'active',
    label: 'Active',
    fetchProps: {
      sortBy: 'updated_at',
      sortDirection: 'desc',
      editingStates: ['active'],
      pageSize,
    },
  },
  {
    key: 'paused',
    label: 'Paused',
    fetchProps: {
      sortBy: 'updated_at',
      sortDirection: 'desc',
      editingStates: ['paused'],
      pageSize,
    },
  },
  {
    key: 'completed',
    label: 'Completed',
    fetchProps: {
      sortBy: 'updated_at',
      sortDirection: 'desc',
      editingStates: ['completed'],
      pageSize,
    },
  },
  {
    key: 'draft',
    label: 'Draft',
    fetchProps: {
      sortBy: 'updated_at',
      sortDirection: 'desc',
      editingStates: ['draft'],
      pageSize,
    },
  },
  {
    key: 'archived',
    label: 'Archived',
    fetchProps: {
      sortBy: 'updated_at',
      sortDirection: 'desc',
      editingStates: ['archived'],
      pageSize,
    },
  },
];
