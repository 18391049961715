import { useProgram } from 'contexts/program';
import { QueryResponse } from 'hooks/common';
import {
  JourneyExecutionMetrics,
  Step,
  StepMetrics,
  Steps,
} from 'models/journeys/journey';
import { useQuery } from 'react-query';
import { fetchJourneyMetrics } from 'services/api-journey-metrics';

export const useJourneyMetricsQuery = (props: {
  programId: number;
  journeyId: number;
  graphId: number;
}): QueryResponse<JourneyExecutionMetrics> => {
  const { programId, journeyId, graphId } = props;
  const { data, isLoading, error } = useQuery(
    ['journey-metrics', { ...props }],
    () => fetchJourneyMetrics({ programId, journeyId, graphId }),
    {
      retry: 1,
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
    }
  );

  return {
    data,
    isLoading,
    errorMessage: error instanceof Error ? error.message : undefined,
  };
};

export const useJourneyStepMetrics = <T extends keyof Steps>(
  journeyId: number,
  graphId: number,
  stepType: T,
  stepId: Step['id']
): { data: StepMetrics<T> | undefined; isLoading: boolean } => {
  const { id: programId } = useProgram();
  const { data, isLoading } = useJourneyMetricsQuery({
    programId,
    journeyId,
    graphId,
  });

  if (stepType === data?.stepMetrics[stepId]?.stepType) {
    return { data: data.stepMetrics[stepId] as StepMetrics<T>, isLoading };
  }
  return { data: undefined, isLoading };
};

export const useJourneyStartMetrics = (
  journeyId: number,
  graphId: number
): {
  data: { entered: number; current: number } | undefined;
  isLoading: boolean;
} => {
  const { id: programId } = useProgram();
  const { data, isLoading } = useJourneyMetricsQuery({
    programId,
    journeyId,
    graphId,
  });

  if (data === undefined) return { data, isLoading };

  const { entered, current } = data;
  return { data: { entered, current }, isLoading };
};
