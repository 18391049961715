/* TODO: Fix accessibility in MAGIC-5749 */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import cx from 'classnames';
import styles from '../AudienceBuilder.module.css';

export const ExpressionOperator: React.FC<{
  onChange: (value: string) => void;
  theme?: 'default' | 'inactive';
  value: string;
}> = ({ onChange, theme = 'default', value }) => {
  const andStatusStyle =
    theme !== 'inactive' && value === 'AND' ? styles.active : styles.inactive;
  const orStatusStyle =
    theme !== 'inactive' && value === 'OR' ? styles.active : styles.inactive;

  const handleAndClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onChange('AND');
  };

  const handleOrClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onChange('OR');
  };

  return (
    <>
      <div
        className={cx(styles.expressionOperatorLabel, andStatusStyle)}
        onClick={handleAndClick}
      >
        AND
      </div>
      <div className={styles.expressionOperator}>
        <FlipSwitch
          on={value === 'OR'}
          value="OR"
          onChange={(OR) => onChange(OR ?? 'AND')}
          theme={theme === 'inactive' ? 'inactive' : 'equal'}
        />
      </div>
      <div
        className={cx(styles.expressionOperatorLabel, orStatusStyle)}
        onClick={handleOrClick}
      >
        OR
      </div>
    </>
  );
};
