import {
  SettingsEditor,
  useSettings,
} from 'contexts/publisher/orchestrate/use-settings';
import { useCallback, useEffect, useState } from 'react';
import { Suggestion } from './suggestions/Suggestion';
import {
  useDurationSuggestion,
  apply as applyDuration,
} from './suggestions/useSuggestionDuration';

export enum SuggestionsState {
  normal, // one or more suggestions, and zero or more ignored suggestions
  noSuggestions, // no suggestions at all
  allSuggestionsIgnored, // all suggestions ignored
}

export type SuggestionsManager = {
  loading: boolean;
  suggestions: Suggestion[];
  state: SuggestionsState;
  badgeValue: number;
};

export const useSuggestions: () => SuggestionsManager = () => {
  const settings = useSettings();

  // this will hold the suggestions that will appear
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  // invoke the hook for each suggestion
  const withDurationSuggestion = useDurationSuggestion();

  const calculateState = useCallback(() => {
    if (suggestions.length === 0) {
      return SuggestionsState.noSuggestions;
    }
    return SuggestionsState.normal;
  }, [suggestions.length]);

  useEffect(() => {
    // create object with their apply functions
    const fixes: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: (settings: SettingsEditor, value?: any) => () => void;
    } = {
      extend_duration: applyDuration, // the id should match the id of its suggestion
    };

    // here we can add suggestions to the array of suggestions that will be taken in consideration
    const enabledSuggestions = [withDurationSuggestion];

    // sugestions come undefined if they don't apply for current settings. Filter them.
    // Then populate the suggestions with their apply method, do the same for the ignored property
    setSuggestions(
      (enabledSuggestions.filter((s) => s !== undefined) as Suggestion[]).map(
        (sug) => ({
          ...sug,
          apply: fixes[sug.id] ? fixes[sug.id](settings, sug.value) : undefined,
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withDurationSuggestion]);

  // loading is hardcoded false, but this will be used when they are loaded from the server
  return {
    loading: false,
    suggestions,
    state: calculateState(),
    badgeValue: suggestions.length,
  };
};
