import React, { useCallback } from 'react';
import {
  HYBRID,
  OPTIMIZE,
  OVERRIDE,
  Settings,
} from 'models/publisher/settings';
import { pluralize } from 'utility/text';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { DurationOptions } from 'models/duration';
import { Icon } from 'shared/Icon';
import { shouldDisplaySuggestedDuration } from 'components/publisher/settings/SettingsEditor/sections/CampaignDuration';
import { DateTime } from 'luxon';
import { useDuration } from 'App/Program/Editors/Publisher/Deliver/DirectDelivery/DirectDeliverySection/EngagementeBoostSection/useDuration';
import styles from './reviewexplanatory.module.css';

export const ReviewExplanatory: React.FC<{
  settings: Settings;
  usersCount: number;
  isNewOrchestratePage: boolean;
}> = ({ settings, usersCount, isNewOrchestratePage }) => {
  const { contentPermissions, update, options } = useSettings();
  const { canEdit } = contentPermissions;
  const {
    deliveryType,
    contentTopics,
    publishedAt,
    deliveryChannels,
    duration,
    audiences,
    retries,
    optimizedDeliveryEnabled,
  } = settings;

  const topicsNames = React.useMemo(
    () => contentTopics.map(({ name }) => name).join(', '),
    [contentTopics]
  );

  const channelNames = React.useMemo(() => {
    return Object.keys(deliveryChannels)
      .filter(
        (key) => deliveryChannels[key as keyof Settings['deliveryChannels']]
      )
      .join(', ');
  }, [deliveryChannels]);

  const onApplyClick = useCallback(
    (durationSuggestion: number | undefined) => {
      if (!durationSuggestion) return;
      const newDuration = {
        label: `${durationSuggestion} d`,
        value: durationSuggestion,
        unit: DurationOptions[0],
      };
      update({ duration: newDuration });
    },
    [update]
  );

  const durationSuggestion = useDuration();

  const renderDurationSuggestion = React.useMemo(() => {
    const startDate = options.canChangePublicationDate
      ? settings.publishedAt || DateTime.now()
      : DateTime.now();

    const displaySuggestion = shouldDisplaySuggestedDuration(
      startDate,
      audiences,
      durationSuggestion,
      settings.duration,
      deliveryType
    );

    if (displaySuggestion) {
      return (
        <div className={styles.suggestionText}>
          <Icon iconName="exclamation-triangle" /> We suggest{' '}
          <b>
            {durationSuggestion} {pluralize(durationSuggestion || 14, 'day')}{' '}
          </b>{' '}
          as your minimum duration, based on your campaign settings.{' '}
          {deliveryType === 'optimize' && (
            <>
              Note: We are not guaranteeing all notifications will be delivered
              for all users within this suggested timeframe.{' '}
            </>
          )}
          <button
            type="button"
            onClick={() => onApplyClick(durationSuggestion)}
            disabled={!canEdit}
            className={styles.applySuggestionButton}
          >
            Apply suggestion
          </button>
        </div>
      );
    }
    return null;
  }, [
    options.canChangePublicationDate,
    settings.publishedAt,
    settings.duration,
    audiences,
    durationSuggestion,
    deliveryType,
    canEdit,
    onApplyClick,
  ]);

  const audienceExplainText = React.useMemo(() => {
    const deliverNow = publishedAt === undefined;
    const durationValue =
      duration && `${duration.value} ${pluralize(duration.unit?.value)}`;
    const formattedPublishedAt =
      publishedAt && publishedAt.toFormat('MMM d, h:mm a');

    const isOptimize =
      deliveryType === OPTIMIZE ||
      (isNewOrchestratePage && optimizedDeliveryEnabled && retries !== 0);
    const isHybrid =
      deliveryType === HYBRID ||
      (isNewOrchestratePage && !optimizedDeliveryEnabled && retries !== 0);
    const isOverride =
      deliveryType === OVERRIDE ||
      (isNewOrchestratePage && !optimizedDeliveryEnabled && retries === 0);
    const isOptimizeInitialNoRetries =
      isNewOrchestratePage && optimizedDeliveryEnabled && retries === 0;

    if (isOptimize) {
      return deliverNow ? (
        <>
          <>
            The orchestration engine will engage with <b>{usersCount}</b> users
            over the next <b>{durationValue}</b> across <b>{channelNames}</b>.
          </>{' '}
          {!settings.notifDeliveryTimesEnabled && (
            <p>{renderDurationSuggestion}</p>
          )}
        </>
      ) : (
        <>
          <>
            The orchestration engine will attempt to reach <b>{usersCount}</b>{' '}
            users via <b>{channelNames}</b> for <b>{durationValue}</b> after{' '}
            <b>{formattedPublishedAt}</b>.
          </>{' '}
          {!settings.notifDeliveryTimesEnabled && (
            <p>{renderDurationSuggestion}</p>
          )}
        </>
      );
    }

    if (isOptimizeInitialNoRetries) {
      return deliverNow ? (
        <>
          <>
            <b>{usersCount}</b> users will receive this campaign today at the
            best time based on individual viewing habits across{' '}
            <b>{channelNames}</b>.
          </>{' '}
          {!settings.notifDeliveryTimesEnabled && (
            <p>{renderDurationSuggestion}</p>
          )}
        </>
      ) : (
        <>
          <>
            <b>{usersCount}</b> users will be sent this campaign on{' '}
            <b>{publishedAt && publishedAt.toFormat('MMM d')}</b> at the best
            time based on individual viewing habits across <b>{channelNames}</b>
            .
          </>{' '}
          {!settings.notifDeliveryTimesEnabled && (
            <p>{renderDurationSuggestion}</p>
          )}
        </>
      );
    }

    if (isHybrid) {
      return deliverNow ? (
        <>
          <>
            <b>{usersCount}</b> users will immediately receive this campaign via{' '}
            <b>{channelNames}</b>. The audience will receive re-engagement{' '}
            communications as needed over the next <b>{durationValue}</b>.
          </>{' '}
          {!settings.notifDeliveryTimesEnabled && (
            <p>{renderDurationSuggestion}</p>
          )}
        </>
      ) : (
        <>
          <>
            <b>{usersCount}</b> users will receive this campaign via{' '}
            <b>{channelNames}</b> at <b>{formattedPublishedAt}</b>. The audience
            will receive re-engagement communications as needed for{' '}
            <b>{durationValue}</b> following the publish date.
          </>{' '}
          {!settings.notifDeliveryTimesEnabled && (
            <p>{renderDurationSuggestion}</p>
          )}
        </>
      );
    }

    if (isOverride) {
      return deliverNow ? (
        <>
          <b>{usersCount}</b> users will immediately receive this campaign via{' '}
          <b>{channelNames}</b>.
        </>
      ) : (
        <>
          <b>{usersCount}</b> users will receive this campaign via{' '}
          <b>{channelNames}</b> at <b>{formattedPublishedAt}</b>.
        </>
      );
    }

    return null;
  }, [
    publishedAt,
    duration,
    deliveryType,
    isNewOrchestratePage,
    optimizedDeliveryEnabled,
    retries,
    usersCount,
    channelNames,
    settings.notifDeliveryTimesEnabled,
    renderDurationSuggestion,
  ]);

  return (
    <div className={styles.text}>
      {topicsNames && (
        <>
          This campaign will be visible in <b>{topicsNames}</b>.{' '}
        </>
      )}
      {audiences.length !== 0 && audienceExplainText}
    </div>
  );
};
