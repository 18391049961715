import { usePollDataMutator } from './usePollDataMutator';
import { usePollAnswers } from './usePollAnswers';
import { usePollPreset } from './usePollPreset';
import { usePollQuestion } from './usePollQuestion';
import { usePollThankYouText } from './usePollThankYouText';
import { usePollResults } from './usePollResults';
import { usePresetAnswersEffect } from './usePresetAnswersEffect';

type PollFieldAPI = ReturnType<typeof usePollAnswers> &
  ReturnType<typeof usePollPreset> &
  ReturnType<typeof usePollQuestion> &
  ReturnType<typeof usePollThankYouText> &
  ReturnType<typeof usePollResults>;

export function usePollFields(
  mutator: ReturnType<typeof usePollDataMutator>
): PollFieldAPI {
  const answers = usePollAnswers(mutator);
  const question = usePollQuestion(mutator);
  const thankYouText = usePollThankYouText(mutator);
  const preset = usePollPreset(mutator);
  const results = usePollResults(mutator);
  usePresetAnswersEffect({ ...preset, ...answers });
  return { ...answers, ...question, ...preset, ...results, ...thankYouText };
}
