import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Span, styles, Caption } from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components/Box';
import { DismissType } from 'DesignSystem/Components';

import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Pills } from 'DesignSystem/Components/Pills';
import { Topic } from 'models/topic';
import { Button } from 'DesignSystem/Form/InputElements';
import { pluralize } from 'utility/text';
import icon from 'assets/images/orchestrate-topic-icon.png';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useTopicFollowersQuery } from 'hooks/topics';
import { useProgram } from 'contexts/program';
import { ReactComponent as CircleCheckIcon } from 'shared/icons/CheckCircle.svg';
import { ReactComponent as CancelIcon } from 'shared/icons/Cancel.svg';
import {
  GreenTintDarkPillClass,
  OrangeTintDarkPillClass,
} from 'DesignSystem/Components/Pill';
import { usePermissions } from 'contexts/permissions';
import { AddTopics } from './AddTopics';
import { SectionContainer } from '../SettingsCard/SectionContainer';
import { ContentTitle, SettingsCard } from '../SettingsCard';
import { archivedTopics } from '../Shared/common-utils';

interface TopicSectionProps {
  disabled?: boolean;
}

interface SummaryPill {
  id: number;
  enabled: boolean;
  text: React.ReactNode;
}

export const TopicSection: React.FC<TopicSectionProps> = ({ disabled }) => {
  const {
    settings,
    contentPermissions: { canEdit },
  } = useSettings();
  const Bold = Span(styles.Bold);
  const { id: programId } = useProgram();

  const {
    permissions: { setFeatured },
  } = usePermissions();

  const addTopics = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => (
    <AddTopics
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
    />
  );

  const renderPill = (value: Topic) => (
    <>
      {value.name}&nbsp;
      {canEdit && (
        <>
          <Bold>·</Bold>&nbsp;{value.followerCount}
        </>
      )}
    </>
  );

  const topicsFollowers = useTopicFollowersQuery(
    programId,
    (settings?.contentTopics || []).map((topic) => topic.id),
    true
  );

  const subscribers = topicsFollowers.data?.totalObjects || 0;
  const subscriberLabel = `${subscribers} ${pluralize(
    subscribers,
    'subscriber'
  )}`;

  const hasTopics = settings.contentTopics.length !== 0;
  const handleFeaturedPermission =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Featured').data
      ?.value as boolean) || false;
  const showIsFeaturedPill =
    !handleFeaturedPermission || setFeatured || settings.isFeatured;
  const { resourcesEnabled } = useFeatureFlags();

  // create list of pills as a summary
  const summaryPills: SummaryPill[] = [];

  if (resourcesEnabled) {
    summaryPills.push({
      id: 0,
      enabled: settings.isResource,
      text: settings.isResource
        ? 'Included in Resources'
        : 'Not included in Resources',
    });
  }

  if (showIsFeaturedPill) {
    summaryPills.push({
      id: 1,
      enabled: settings.isFeatured,
      text: settings.isFeatured ? (
        <>
          Included in Featured:&nbsp;
          <b>&ldquo;{settings.contentLabel?.text}&rdquo;</b>
        </>
      ) : (
        'Not included in Featured'
      ),
    });
  }

  summaryPills.sort((a, b) => Number(b.enabled) - Number(a.enabled));

  const renderSummaryPill = ({ text }: SummaryPill) => <>{text}</>;
  const renderSummaryPillIcon = ({ enabled }: SummaryPill) =>
    enabled ? <CircleCheckIcon /> : <CancelIcon />;
  const renderSummaryPillClass = ({ enabled }: SummaryPill) =>
    enabled ? GreenTintDarkPillClass : OrangeTintDarkPillClass;

  return (
    <SectionContainer
      title="Feed"
      caption="Select Topics to send campaign to user feeds."
      smallHeader={hasTopics}
      icon={icon}
    >
      <SettingsCard
        dataTest="topics-settings"
        editRenderProp={addTopics}
        showEditButton={hasTopics}
        isEditing={false}
        editingMargin={[!hasTopics ? 55 : 30, 32, 15, 32]}
        disabled={disabled}
      >
        {(toggleEditing: () => void) => (
          <Box>
            {!hasTopics ? (
              <Button
                onClick={() => {
                  datadogRum.addAction('add_topics');
                  toggleEditing();
                }}
                label="Add Topics"
                disabled={disabled}
              />
            ) : (
              <>
                <Box margin={[32, 0, 0, 0]}>
                  <ContentTitle>Topics</ContentTitle>
                </Box>
                <Pills
                  values={settings.contentTopics}
                  dataTest="topic-pills"
                  render={renderPill}
                  valueTransformer={archivedTopics}
                />
                {canEdit && settings.contentTopics.length > 0 && (
                  <Box margin={[-9, 0, 0, 0]}>
                    <Caption>{subscriberLabel}</Caption>
                  </Box>
                )}
                <Box margin={[24, 0, 0, 0]}>
                  <Pills
                    small
                    values={summaryPills}
                    render={renderSummaryPill}
                    pillIcon={renderSummaryPillIcon}
                    pillClassName={renderSummaryPillClass}
                    dataTest="summary-pills"
                    padding={[0, 8]}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </SettingsCard>
    </SectionContainer>
  );
};
