import * as React from 'react';
import cx from 'classnames';
import { DefinitionBlock } from 'models/publisher/block';
import {
  FormatData,
  TextAlignment as Alignments,
} from 'models/publisher/format';
import { deepMerge } from 'utility/deep-merge';
import { TextAlignCenter, TextAlignLeft, TextAlignRight } from 'shared/icons';
import styles from './text-alignment.module.css';

export const TextAlignment: React.FC<{
  id: string;
  block: DefinitionBlock;
  onChange: (id: string, data: FormatData) => void;
}> = ({ id, block, onChange }) => {
  const changeFormatting = React.useCallback(
    (data: FormatData) => {
      onChange(id, deepMerge(block.format_data ?? {}, data));
    },
    [id, block, onChange]
  );

  const alignmentMap = [
    {
      icon: <TextAlignLeft />,
      value: Alignments.Left,
    },
    {
      icon: <TextAlignCenter />,
      value: Alignments.Center,
    },
    {
      icon: <TextAlignRight />,
      value: Alignments.Right,
    },
  ];

  return (
    <div>
      <strong>{block.name !== 'social' && 'Text '}Alignment</strong>
      <div className={cx(styles.iconsBox)}>
        {alignmentMap.map(({ icon, value }) => (
          <button
            key={value}
            type="button"
            className={cx(
              styles.alignmentButton,
              block.format_data?.text_alignment === value && styles.active
            )}
            onClick={() => changeFormatting({ text_alignment: value })}
          >
            {icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export function canEditTextAlignment(block: DefinitionBlock): boolean {
  return !!block.format_options?.text_alignments?.length;
}
