import FroalaEditor, { SummarizeWithAiPlugin } from 'froala-editor';
import React, { useState } from 'react';
import { sparklesSvg } from 'shared/icons/Sparkles';
import { useAthenaTask } from 'hooks/athena';
import {
  SupportedCommands,
  createContentCoverAthenaTask,
} from 'services/api-ai-chat-completion';
import { useProgram } from 'contexts/program';
import { raise } from 'utility/errors';
import { useAICoverGenerationFeatureFlag } from 'hooks/content-cover-generation';
import { QueryStatus } from 'react-query';
import { usePublisher } from 'contexts/publisher';
import styles from './summarize-with-ai.module.css';
import { AiOptions, AiOption } from './dropdownOptions';

type SummarizeWithAiConextValue = {
  setSummarizableContent: (content: string | null) => void;
  setCommand: (command: SupportedCommands) => void;
  summarizableContent: string | null;
  athenaTaskOutputId: string | null;
  createSummarizationTask: () => void;
  regenerateSummarizationTask: () => void;
  resetSummarizationState: () => void;
  createTaskStatus: QueryStatus;
  command: string;
};

const SummarizeWithAiContext = React.createContext<SummarizeWithAiConextValue | null>(
  null
);

export function useSummarizeWithAiContext(): SummarizeWithAiConextValue {
  const context = React.useContext(SummarizeWithAiContext);
  if (context === null) {
    throw new Error(
      'useSummarizeWithAiContext must be used within a SummarizeWithAiProvider'
    );
  }
  return context;
}

export function SummarizeWithAiProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element | null {
  const [summarizableContent, setSummarizableContent] = useState<string | null>(
    null
  );

  const [command, setCommand] = useState<SupportedCommands>('modal_summary');

  const programId = useProgram().id;

  const [athenaTaskOutputId, setAthenaTaskOutputId] = useState<string | null>(
    null
  );

  const { post } = usePublisher();

  const {
    createTask: createSummarizationTask,
    outputId,
    reset,
    status,
  } = useAthenaTask({
    createTaskFn: () =>
      createContentCoverAthenaTask({
        command,
        content: summarizableContent ?? raise('No content to summarize'),
        programId,
        contentId: post?.content.id,
      }),
    outputIdSelector: (task) => task.outputId,
    outputStrategy: 'fetch',
  });

  if (outputId && outputId !== athenaTaskOutputId) {
    setAthenaTaskOutputId(outputId);
  }

  function regenerateSummarizationTask() {
    reset();
    setAthenaTaskOutputId(null);
    createSummarizationTask();
  }

  function resetSummarizationState() {
    setSummarizableContent(null);
    setAthenaTaskOutputId(null);
  }

  return (
    <SummarizeWithAiContext.Provider
      value={{
        setSummarizableContent,
        setCommand,
        summarizableContent,
        athenaTaskOutputId,
        createSummarizationTask,
        regenerateSummarizationTask,
        resetSummarizationState,
        createTaskStatus: status,
        command,
      }}
    >
      {children}
    </SummarizeWithAiContext.Provider>
  );
}

function getCommandTitle(canSummarize: boolean): string {
  return canSummarize
    ? 'Enhance with AI'
    : 'Type or highlight at least 2 sentences to enable AI summarize';
}
export const SUMMARIZE_WITH_AI_PLUGIN_NAME = 'summarizeWithAi';

export function useFroalaSummarizeWithAi({
  setSummarizableContent,
  setCommand,
  createSummarizationTask,
  editor,
}: {
  setSummarizableContent: (content: string) => void;
  setCommand: (command: SupportedCommands) => void;
  createSummarizationTask: () => void;
  editor: FroalaEditor | undefined;
}): void {
  const aiCoverGenerationEnabled = useAICoverGenerationFeatureFlag();

  if (aiCoverGenerationEnabled) {
    // eslint-disable-next-line func-names
    FroalaEditor.PLUGINS.summarizeWithAi = function (
      editorInstance: FroalaEditor
    ) {
      function getSelectedText() {
        return editorInstance.selection?.text() || null;
      }

      function getBlockHTMLText() {
        // Casting as | undefined is because on the initial render
        // the blocks are not yet available.
        const blocks = editorInstance.html?.get(false);

        if (!blocks) {
          return null;
        }
        return blocks;
      }

      function handleSummarizeContentClick() {
        const content = getSelectedText() ?? getBlockHTMLText();

        if (!content) {
          return;
        }

        setSummarizableContent(content);
        createSummarizationTask();
      }

      function canSummarize(): boolean {
        const content = getSelectedText() ?? getBlockHTMLText();

        if (!content) {
          return false;
        }

        return content.length >= 20;
      }

      const plugin: SummarizeWithAiPlugin = {
        _init: () => {},
        setSummarizableContent: (content: string) => {
          setSummarizableContent(content);
        },
        setCommand: (command: string) => {
          setCommand(command as SupportedCommands);
        },
        getSelectedText,
        handleSummarizeContentClick,
        canSummarize,
      };

      return plugin;
    };

    FroalaEditor.DefineIconTemplate('sparkles_svg', sparklesSvg());

    FroalaEditor.DefineIcon(SUMMARIZE_WITH_AI_PLUGIN_NAME, {
      NAME: 'sparkles_svg',
      template: 'sparkles_svg',
    });

    if (!editor) {
      return;
    }

    const plugin = FroalaEditor.PLUGINS.summarizeWithAi(
      editor
    ) as SummarizeWithAiPlugin;
    const meetsSummariseRequirements = plugin.canSummarize();

    FroalaEditor.RegisterCommand(SUMMARIZE_WITH_AI_PLUGIN_NAME, {
      title: getCommandTitle(meetsSummariseRequirements),
      type: 'dropdown',
      html() {
        return `<ul class='fr-dropdown-list'></ul>`;
      },
      plugin: SUMMARIZE_WITH_AI_PLUGIN_NAME,
      refresh($btn: JQuery<HTMLElement>) {
        const commandTitle = getCommandTitle(
          this.summarizeWithAi?.canSummarize() ?? false
        );

        if (this.summarizeWithAi?.canSummarize()) {
          $btn.removeAttr('title');
          $btn.attr('data-title', commandTitle);
          $btn.removeClass('fr-disabled');
          $btn.css({
            opacity: 1,
            cursor: 'pointer',
          });
        } else {
          $btn.removeAttr('data-title');
          $btn.addClass('fr-disabled');
          $btn.attr('title', commandTitle);
          $btn.css({
            opacity: 0.5,
            cursor: 'default',
          });
        }
      },
      refreshOnShow(elem, container) {
        const choices = container.get(0).querySelector('ul.fr-dropdown-list');
        choices.innerHTML = '';

        if (!this.summarizeWithAi?.canSummarize()) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        choices.addEventListener('mouseover', (event: any) => {
          const expandLi = event.target.closest('[data-cmd="expand"]');
          if (expandLi) {
            const fileName = expandLi.dataset.param;
            const secondaryDropdown = document.querySelector(
              `.fr-dropdown-list-${fileName}`
            );
            if (secondaryDropdown) {
              const rect = expandLi.getBoundingClientRect();
              const secondaryDropdownStyle = {
                display: 'block',
                left: `${rect.right}px`,
                top: `${rect.top}px`,
                zIndex: '9999',
                backgroundColor: 'white',
                listStyle: 'none',
                cursor: 'pointer',
                padding: '8px 0px',
                boxShadow:
                  '0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
              };

              Object.assign(
                (secondaryDropdown as HTMLElement).style,
                secondaryDropdownStyle
              );
            }

            let isMouseInSecondaryDropdown = false;
            let timeoutId: ReturnType<typeof setTimeout>;

            expandLi.addEventListener('mouseleave', () => {
              timeoutId = setTimeout(() => {
                if (!isMouseInSecondaryDropdown) {
                  (secondaryDropdown as HTMLElement).style.display = 'none';
                }
              }, 1);
            });

            (secondaryDropdown as HTMLElement).addEventListener(
              'mouseenter',
              () => {
                isMouseInSecondaryDropdown = true;
              }
            );

            (secondaryDropdown as HTMLElement).addEventListener(
              'mouseleave',
              () => {
                isMouseInSecondaryDropdown = false;
                (secondaryDropdown as HTMLElement).style.display = 'none';
              }
            );

            (secondaryDropdown as HTMLElement).addEventListener(
              'mouseenter',
              () => {
                clearTimeout(timeoutId);
              }
            );
          }
        });

        AiOptions.forEach((option: AiOption) => {
          if (option.command === 'change_tone') {
            choices.innerHTML += `<li>
            <a class="${styles.frOption} fr-command fr-title" data-cmd="expand" data-param="${option.command}" tabindex="1">
              <div class="${styles.frOptionContainer}">
                  ${option.svgString}
                  <span>${option.text}</span>
                  <span style='position: absolute; left: 86%; font-size: 20px;'>&rsaquo;</span>
              </div>
            </a>
          </li>`;

            let secondaryDropdown = document.querySelector(
              `.fr-dropdown-list-${option.command}`
            );

            if (!secondaryDropdown) {
              secondaryDropdown = document.createElement('ul');
              secondaryDropdown.className = `fr-dropdown-list fr-dropdown-list-change_tone`;
              (secondaryDropdown as HTMLElement).style.display = 'none';
              (secondaryDropdown as HTMLElement).style.position = 'absolute';
            } else {
              secondaryDropdown.innerHTML = '';
            }
            const subOptions = option.options;
            if (!subOptions) {
              return;
            }
            let secondaryOptionsHtml = '';
            subOptions.forEach((subOption: string) => {
              secondaryOptionsHtml += `<li class="fr-variable">
                <a class=" ${
                  styles.frSubOption
                } fr-command fr-title" data-cmd="${SUMMARIZE_WITH_AI_PLUGIN_NAME}" id=${
                option.command
              }_${subOption.toLowerCase()} data-param1="${
                option.command
              }_${subOption.toLowerCase()}" tabindex="1">
                <div class=${styles.frOptionContainer}>  
                  <div>${subOption}</div>
                 </div>
                </a>
                </li>`;
            });

            (secondaryDropdown as HTMLElement).innerHTML += secondaryOptionsHtml;

            const secondaryOptions = secondaryDropdown.querySelectorAll(
              'li.fr-variable a'
            );

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            secondaryOptions.forEach((dropdownOption: any) => {
              dropdownOption.addEventListener(
                'mousedown',
                (event: MouseEvent) => {
                  const { id } = event.currentTarget as HTMLElement;
                  // Save the selection so we can restore it before confirming the modal.
                  editor.selection?.save();
                  const val = id?.toString() || '';

                  this?.summarizeWithAi?.setCommand(val);
                  this?.summarizeWithAi?.handleSummarizeContentClick();

                  (secondaryDropdown as HTMLElement).style.display = 'none';
                }
              );
            });

            document.body.appendChild(secondaryDropdown);
          } else {
            choices.innerHTML += `<li>
            <a class="${styles.frOption} fr-command fr-title" data-cmd="${SUMMARIZE_WITH_AI_PLUGIN_NAME}" data-param1="${option.command}" tabIndex="1">
                <div class="${styles.frOptionContainer}">
                  ${option.svgString}
                  <span>${option.text}</span>
                </div>
            </a>
          </li>`;
          }
        });
      },
      callback(cmd: string, val: string) {
        if (!this.summarizeWithAi || !this.summarizeWithAi.canSummarize()) {
          return;
        }

        // Save the selection so we can restore it before confirming the modal.
        editor.selection?.save();

        this.summarizeWithAi.setCommand(val);
        this.summarizeWithAi.handleSummarizeContentClick();
      },
    });
  }
}
