import {
  createContext,
  createElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePublisher } from 'contexts/publisher';
import { useDocumentClick } from 'hooks/useDocumentEvent';
import { EditorProps } from '../modal-field-editor/useEditor';

type PanelContext = {
  isBlocking: boolean;
  blocker: null | React.ReactElement;
  setBlocker(comp: null | React.ReactElement): void;
  confirmClose(): void;
  continueOpened(): void;
};
const CONTEXT = createContext<PanelContext>({
  isBlocking: false,
  blocker: null,
  setBlocker() {},
  confirmClose() {},
  continueOpened() {},
});

export const PanelProvider: React.FC<Pick<EditorProps, 'hideBlockEditor'>> = ({
  hideBlockEditor,
  children,
}) => {
  const autosaver = useAutosavePauser();
  const blocker = useExitBlocker(hideBlockEditor);
  const value: PanelContext = { ...blocker, ...autosaver };
  return createElement(CONTEXT.Provider, { value }, children);
};

export function usePanelContext(): PanelContext {
  return useContext(CONTEXT);
}

// helper hooks
function useExitBlocker(onClose: EditorProps['hideBlockEditor']) {
  const [isBlocking, setIsBlocking] = useState(false);
  const [blocker, setBlocker] = useState<PanelContext['blocker']>(null);
  const handleExitAttempt = useCallback(() => {
    if (blocker) setIsBlocking(true);
    else onClose();
  }, [onClose, blocker]);
  const continueOpened = useCallback(() => {
    setIsBlocking(false);
  }, []);
  const confirmClose = useCallback(() => {
    onClose();
  }, [onClose]);
  useDocumentClick(handleExitAttempt);
  return { isBlocking, blocker, setBlocker, continueOpened, confirmClose };
}

function useAutosavePauser() {
  const pub = usePublisher();
  const autoRefs = useRef({
    pause: pub.pauseAutosave,
    unpause: pub.unpauseAutosave,
    trigger: pub.update,
  });
  autoRefs.current.pause = pub.pauseAutosave;
  autoRefs.current.unpause = pub.unpauseAutosave;
  autoRefs.current.trigger = pub.update;
  useEffect(() => {
    const autosave = autoRefs.current;
    autosave.pause();
    return () => {
      autosave.unpause();
      autosave.trigger({});
    };
  }, []);
  return {};
}
