import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useModal } from 'contexts/modal';
import { duplicate, ContentData } from 'services/api-content';
import { Post } from 'models/publisher/post';

type DuplicateContentProps = {
  programId: number;
  post: Post;
};

const ERROR_MESSAGES = Object.freeze({
  default: 'There was an error duplicating.',
  attachment_scanning:
    'Attachment file is still processing. Please wait for processing to complete and try duplicating your campaign again.',
  attachment_infected:
    'Virus detected in the attachment file. Please remove or replace the attachment and try duplicating your campaign again.',
  personalized_variable_unsupported:
    'Campaigns with personalized fields in the header cannot be duplicated. Please edit your campaign to duplicate.',
});

export const useContentDuplicator = (): {
  duplicate: (params: DuplicateContentProps) => void;
  isDuplicating: boolean;
} => {
  const [isDuplicating, setIsDuplicating] = React.useState(false);
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();
  const { showErrorModal } = useModal();
  const perform = ({ post, programId }: DuplicateContentProps) => {
    setIsDuplicating(true);
    const onSuccess = (copy: ContentData): void => {
      queryClient.invalidateQueries(['paged-contents']);
      setFlashMessage({
        timeout: 15000,
        severity: 'info',
        message: 'Campaign duplicated.',
        navigateButton: {
          text: 'Click here to edit',
          path: `/${programId}/edit/publisher/${Number(copy.id)}`,
        },
      });
    };
    const onError = (error: Error): void => {
      const errorMessage = error?.message ?? '';

      if (errorMessage.includes('Attachment file')) {
        let message = ERROR_MESSAGES.default;
        if (errorMessage.includes('is still processing.')) {
          message = ERROR_MESSAGES.attachment_scanning;
        } else if (errorMessage.includes('is infected.')) {
          message = ERROR_MESSAGES.attachment_infected;
        }
        showErrorModal({
          title: 'Duplication Error',
          message,
        });
      } else if (errorMessage.includes('is not a supported variable')) {
        setFlashMessage({
          timeout: 5000,
          severity: 'error',
          message: ERROR_MESSAGES.personalized_variable_unsupported,
        });
      } else {
        setFlashMessage({
          timeout: 5000,
          severity: 'error',
          message: ERROR_MESSAGES.default,
        });
      }
    };
    return duplicate(programId, post.content.id)
      .then(onSuccess)
      .catch(onError)
      .finally(() => setIsDuplicating(false));
  };

  return { duplicate: perform, isDuplicating };
};
