import React, { useState, useEffect } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import {
  Body,
  border,
  Subheading,
  FieldLabel,
  color,
} from 'DesignSystem/Typography';
import { Button, Input } from 'DesignSystem/Form/InputElements';
import { SelectGroup } from 'DesignSystem/Form/SelectGroup';
import { DismissType, Popover } from 'DesignSystem/Components';
import { GenericSelect } from 'shared/Select';
import { OptionType } from 'hooks/common';
import { Icon } from 'shared/Icon';
import {
  Duration,
  durationDaysByCustomValue,
  DURATION_DAYS_BY_VALUE,
} from 'models/duration';

type DurationOption = {
  name?: string;
  value?: Duration;
};

const items: DurationOption[] = [
  { value: DURATION_DAYS_BY_VALUE[7] },
  { value: DURATION_DAYS_BY_VALUE[14] },
  { value: durationDaysByCustomValue(21) },
  { value: durationDaysByCustomValue(28) },
  { name: 'custom', value: undefined },
];

// For now we only have one option
const durationTypeOptions: OptionType[] = [{ label: 'Days', value: 'days' }];

export const ChangeDuration: React.FC<{
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: (duration: Duration) => void;
  dismiss: DismissType;
  value?: Duration;
  suggestedValue?: number;
  setDismissable?: (dismissable: boolean) => void;
}> = ({
  disabled,
  onCancel,
  onSave,
  dismiss,
  value,
  suggestedValue,
  setDismissable,
}) => {
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [durationInputText, setDurationInputText] = useState<
    string | undefined
  >();
  const [durationInputError, setDurationInputError] = useState<
    string | undefined
  >();

  const [selectedDuration, setSelectedDuration] = useState<DurationOption>(
    items[0]
  );

  useEffect(() => {
    // check if the prop value is custom or not.
    const isCustomSelection =
      !!value &&
      !items.find(
        (d) =>
          d.value?.value === value?.value &&
          d.value?.unit.value === value?.unit.value
      );

    if (isCustomSelection) {
      if (value?.value) setDurationInputText(`${value?.value}`);
      setSelectedDuration({ name: 'custom', value });
    } else {
      setSelectedDuration({ value });
      // if there's no custom selection made, lets default the input to the suggested or the default
      setDurationInputText(`${suggestedValue || 14}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processEnteredDuration = (newValue: string) => {
    setDurationInputText(newValue);
    const numericValue = parseInt(newValue, 10) || 0;
    if (numericValue <= 0) {
      setDurationInputError('Duration must be longer than 0 days');
      return;
    }
    if (numericValue >= 365) {
      setDurationInputError('Duration must be shorter than than 365 days');
      return;
    }

    setSaveEnabled(true);
    setDurationInputError(undefined);

    // update the selected duration. We need to create an object for that
    setSelectedDuration({
      name: 'custom',
      value: durationDaysByCustomValue(numericValue),
    });
  };

  const handleInputChange = (newValue: string) => {
    processEnteredDuration(newValue);
  };

  const handleDurationSelectorChange = (newValue: DurationOption) => {
    if (newValue.name === 'custom') {
      if (durationInputError) {
        // if the selected tab is custom, and there is an error  with the previous entered duration, use the last working one
        setSelectedDuration({
          name: 'custom',
          value: selectedDuration.value,
        });
        setDurationInputText(`${selectedDuration.value?.value}`);
        setDurationInputError(undefined);
      } else processEnteredDuration(durationInputText || '');
    } else {
      setSelectedDuration(newValue);
    }
    setSaveEnabled(true);
  };

  const hasCustomDurationError =
    selectedDuration?.name === 'custom' && durationInputError !== undefined;

  const enableSaveButtom = saveEnabled && !disabled && !hasCustomDurationError;

  useEffect(() => {
    if (setDismissable) setDismissable(!enableSaveButtom);
  }, [setDismissable, enableSaveButtom]);

  return (
    <Box width={512}>
      <Popover centered padding={0}>
        <Box padding={23}>
          <Box>
            <Box margin={[0, 0, 16, 0]}>
              <Subheading bold>Duration</Subheading>
              <Box margin={[10, 0, 0, 0]} style={{ display: 'flex' }}>
                <Body>
                  The duration is the timeframe within which users can be
                  notified with this campaign.
                </Body>
              </Box>
            </Box>
            <SelectGroup
              values={items}
              selected={selectedDuration}
              render={(duration: DurationOption) => (
                <Body>
                  {duration.value
                    ? `${duration.value.value} ${duration.value.unit.label}`
                    : 'Custom'}{' '}
                </Body>
              )}
              compare={(duration) =>
                duration.name || duration?.value?.label || ''
              }
              onChange={(duration) => {
                handleDurationSelectorChange(duration);
              }}
            />
          </Box>
          {selectedDuration?.name === 'custom' && (
            <Box margin={[17, 0, 0, 32]}>
              <FieldLabel>Custom Duration</FieldLabel>
              <Box style={{ display: 'flex' }} margin={[16, 0, 15, 0]}>
                <Input
                  compact
                  hasError={!!durationInputError}
                  onChange={handleInputChange}
                  value={durationInputText}
                />
                &nbsp;
                <GenericSelect
                  onChange={() => {}}
                  options={durationTypeOptions}
                />
              </Box>
              {durationInputError && (
                <Box margin={[-5, 0, 20, 0]} color={color.redFull}>
                  <Icon iconName="warning" iconType="SVG" />{' '}
                  {durationInputError}
                </Box>
              )}
              {suggestedValue && (
                <Body>
                  We suggest <b>{suggestedValue} days</b> as your minimum
                  duration, based on your campaign settings.
                </Body>
              )}
            </Box>
          )}
        </Box>
        <Box
          border={[border.solid, border.width1, border.gray10]}
          style={{
            display: 'flex',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none',
            justifyContent: 'right',
          }}
        >
          <Box padding={16}>
            <Button secondary action label="Cancel" onClick={onCancel} />
            <Button
              action
              disabled={!enableSaveButtom}
              label="Save"
              onClick={() => {
                if (onSave && selectedDuration.value) {
                  onSave(selectedDuration?.value);
                  dismiss();
                }
              }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
