import { useQuery } from 'react-query';
import { useProgram } from 'contexts/program';
import { SignInConfig } from 'models/signInConfig';
import { MutationOptions, QueryResponse } from './common';
import { Program } from '../models/program';
import {
  fetchProgram,
  fetchSignInConfig,
  ProgramData,
  switchProgram,
  updateBrand,
  updateProgram,
  updateSignInConfig,
} from '../services/api-program';
import { bossanovaDomain } from '../services/api-shared';

const normalizeBossanovaUrl = (url: string): string => {
  return /^https?:\/\//.test(url) ? url : `${bossanovaDomain}${url}`;
};

const mapDataToProgram = (data: ProgramData): Program => ({
  ...data,
  programAuthor: {
    defaultDisplayName: data.name || data.id.toString(10),
    displayName: data.name || data.id.toString(10),
    avatarUrl: data.iconImageUrl,
  },
  iconImageUrl: data.iconImageUrl && normalizeBossanovaUrl(data.iconImageUrl),
});

export const useProgramQuery = (programId: number): QueryResponse<Program> => {
  const { isLoading, error, data } = useQuery<ProgramData, Error>(
    ['program', programId], // Cache key, must be distinct for different query params
    () => fetchProgram(programId),
    { retry: false }
  );
  return {
    isLoading,
    error,
    data: data && mapDataToProgram(data),
  };
};

export const testable = {
  mapDataToProgram,
};

export const useUpdateBrand = (
  brandId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  update: (data: Program) => void;
} => {
  const update = (data: Program) => {
    updateBrand(brandId, data)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { update };
};

export const useUpdateProgram = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  update: (data: Program) => void;
} => {
  const update = (data: Program) => {
    updateProgram(programId, data)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useSignInConfigQuery = (): QueryResponse<SignInConfig> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<SignInConfig, Error>(
    ['sign-in-config', programId],
    () => fetchSignInConfig(programId),
    { retry: false }
  );
  return {
    isLoading,
    error,
    data,
  };
};

export const useUpdateSignInConfig = ({
  onSuccess,
  onError,
}: MutationOptions<SignInConfig, Error> = {}): {
  update: (data: SignInConfig) => void;
} => {
  const { id: programId } = useProgram();
  const update = (data: SignInConfig) => {
    updateSignInConfig(programId, data)
      .then((config) => {
        if (onSuccess) onSuccess(config);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useSwitchProgram = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  switchProg: (targetProgramId: number) => void;
} => {
  const switchProg = (targetProgramId: number) => {
    switchProgram(programId, targetProgramId)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { switchProg };
};
