import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Checkbox } from 'shared/Checkbox';
import { SignInConfig } from 'models/signInConfig';
import { Box } from 'DesignSystem/Components';
import { TextInput } from 'shared/TextInput';
import { Button } from 'shared/Button';
import { Pills } from 'DesignSystem/Components/Pills';
import { MAIcon } from 'shared/MAIcon';
import styles from '../styles.module.css';

type PropsType = {
  config: SignInConfig;
  setConfig: (config: SignInConfig) => void;
  errors: Record<string, string | undefined>;
  setErrors: (errors: Record<string, string | undefined>) => void;
};

export const KnownEmailCheckbox: React.FC<PropsType> = ({
  config,
  setConfig,
  errors,
  setErrors,
}) => {
  const [domain, setDomain] = React.useState<string>('');
  return (
    <div className={styles.AccessSettingsCheckbox}>
      <Checkbox
        checked={config.enabled.knownEmail}
        onChange={(checked) =>
          setConfig({
            ...config,
            enabled: { ...config.enabled, knownEmail: checked },
          })
        }
        label={
          <>
            <Text.Subheading block>Known Email Domains</Text.Subheading>
            <Text.Body color={Text.color.gray70} block>
              Allow users to access the community with specific email domains.
              At least one allowed domain is required.
            </Text.Body>
          </>
        }
      />
      {config.enabled.knownEmail && (
        <Box margin={[0, 0, 16, 60]}>
          <Text.Subheading block>Add Email Domains</Text.Subheading>
          <Text.Body color={Text.color.gray70} block>
            List the email domain(s) allowed to access the community (ex.
            acme.com) This is required.
          </Text.Body>
          <div className={styles.DomainInput}>
            <TextInput
              value={domain}
              onChange={setDomain}
              maximum={200}
              placeholder="Enter domain"
              onBlur={(value) => {
                if (value.trim() === '' && config.domains.length === 0) {
                  setErrors({
                    ...errors,
                    domain: 'Please enter at least one domain',
                  });
                }
              }}
            />
            <Button
              type="secondary"
              onClick={() => {
                const value = domain.trim().toLowerCase();
                if (!/^([a-z0-9-]+\.)+[a-z0-9-]{2,}$/.test(value)) {
                  setErrors({
                    ...errors,
                    domain: 'Please enter a valid domain.',
                  });
                  return;
                }
                setErrors({ ...errors, domain: undefined });
                setDomain('');
                if (config.domains.includes(value)) return;
                setConfig({ ...config, domains: [...config.domains, value] });
              }}
            >
              Add
            </Button>
          </div>
          {errors.domain && (
            <div className={styles.Error}>
              <MAIcon name="warning" />
              {errors.domain}
            </div>
          )}
          <div className={styles.Domains}>
            {config.domains.map((d, i) => (
              <Pills
                key={d}
                values={[{ id: i }]}
                render={() => <>{d}</>}
                pillClassName={styles.DomainBill}
                onClose={(value) => {
                  const index = value.id;
                  const newDomains = [
                    ...config.domains.slice(0, index),
                    ...config.domains.slice(index + 1),
                  ];
                  if (newDomains.length === 0) {
                    setErrors({
                      ...errors,
                      domain: 'Please enter at least one domain',
                    });
                  }
                  setConfig({ ...config, domains: newDomains });
                }}
                padding={[0, 12, 0, 8]}
              />
            ))}
          </div>
          <div className={styles.UserVerification}>
            <Checkbox
              checked={config.verify.knownEmail}
              onChange={(checked) =>
                setConfig({
                  ...config,
                  verify: { ...config.verify, knownEmail: checked },
                })
              }
              label={
                <>
                  <Text.Subheading block>
                    Require User Verification
                  </Text.Subheading>
                  <Text.Body color={Text.color.gray70} block>
                    Selecting this setting will require confirmation of specific
                    user identity details to access the community. User
                    Verification must be set-up before selecting this setting.
                  </Text.Body>
                </>
              }
            />
          </div>
        </Box>
      )}
    </div>
  );
};
