import { createContext, Dispatch, SetStateAction } from 'react';
import { Topic } from 'models/topic';

type TopicContextType = {
  topic: Topic | undefined;
  setTopic: Dispatch<SetStateAction<Topic | undefined>>;
  isLoading: boolean;
  errorMessage?: string;
};

export const TopicContext = createContext<TopicContextType>({
  topic: undefined,
  setTopic: () => {},
  isLoading: false,
  errorMessage: undefined,
});
