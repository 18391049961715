import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { UserProvider } from 'contexts/user';
import { useCurrentUserQuery } from 'hooks/user';
import { LoadingScreen } from 'shared/LoadingScreen';
import { AuthenticationError } from '../Authentication/AuthenticationError';

export const Authenticated: React.FC<RouteComponentProps> = ({ children }) => {
  const { isLoading, errorMessage, data } = useCurrentUserQuery();
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (errorMessage || !data) {
    return (
      <AuthenticationError
        error={new Error(errorMessage || 'an unexpected error occurred')}
      />
    );
  }
  return <UserProvider value={data}>{children}</UserProvider>;
};
