import {
  BaseEdge as ReactFlowBaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from 'reactflow';
import React from 'react';
import { useJourneyDispatch, useJourneyState } from 'contexts/journeys/journey';
import { useToggle } from 'hooks/useToggle';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { AddableStepTypes, JourneyMode } from 'models/journeys/journey';
import { centerY } from '../utils/node';
import { AddStepOptionIcon, StepOption } from './AddStep/AddStepOption';
import { AddStep } from './AddStep';
import style from './edges.module.css';

type BaseEdgeProps = {
  disabled?: boolean;
  options: Array<{ type: AddableStepTypes; title: StepOption['title'] }>;
  showLabel?: boolean;
  edgeProps: EdgeProps;
};

const addStepOptions: Array<{
  type: AddableStepTypes;
  title: StepOption['title'];
}> = [
  {
    type: 'communication',
    title: 'Communication',
  },
  {
    type: 'decision',
    title: 'Decision',
  },
  {
    type: 'delay',
    title: 'Delay',
  },
];

export const DecisionEdge: React.FC<EdgeProps> = (edgeProps) => {
  const { journeyMode } = useJourneyState();
  return (
    <>
      <BaseEdge
        disabled={journeyMode === JourneyMode.View}
        options={addStepOptions}
        showLabel
        edgeProps={edgeProps}
      />
    </>
  );
};

export const DefaultEdge: React.FC<EdgeProps> = (edgeProps) => {
  const { journeyMode } = useJourneyState();
  return (
    <>
      <BaseEdge
        disabled={journeyMode === JourneyMode.View}
        options={addStepOptions}
        edgeProps={edgeProps}
      />
    </>
  );
};

const BaseEdge: React.FC<BaseEdgeProps> = ({
  disabled,
  options,
  showLabel,
  edgeProps: {
    id,
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    target,
    source,
    data,
  },
}) => {
  const HANDLE_OFFSET = 4;

  const {
    value: addStepVisible,
    enable: showAddStep,
    disable: hideAddStep,
  } = useToggle();
  const {
    value: addStepMenuVisible,
    disable: hideAddStepMenu,
    toggle: toggleAddStepMenu,
  } = useToggle();

  const identifier = `add-step-menu-${id}`;
  const arrowSpacing = 48;

  React.useEffect(() => {
    // Hides the add step button and menu when clicking outside of the menu
    const hide = (evt: MouseEvent) => {
      if (document.getElementById(identifier)?.contains(evt.target as Node))
        return;

      hideAddStep();
      hideAddStepMenu();
    };
    document.addEventListener('click', hide);
    return () => document.removeEventListener('click', hide);
  }, [hideAddStep, hideAddStepMenu, identifier]);

  const { setActiveStepId, topologyEditable } = useJourneyState();
  const dispatch = useJourneyDispatch();
  const flowInstance = useReactFlow();

  const onAddStepClick = (type: AddableStepTypes) => () => {
    dispatch({
      type: 'step/inserted',
      sourceId: source,
      targetId: target,
      stepType: type,
    });
    hideAddStep();
    hideAddStepMenu();
  };

  const onLabelClick = () => {
    setActiveStepId(source);
    centerY(flowInstance, id);

    setImmediate(() => {
      const el = document.querySelector(`[data-target-id="${target}"]`);
      el?.scrollIntoView();
    });
  };

  const stepOptions = options.map(({ type, title }) => ({
    title,
    icon: <AddStepOptionIcon option={type} />,
    onClick: onAddStepClick(type),
  }));
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sourceX - HANDLE_OFFSET,
    sourceY,
    sourcePosition,
    targetX: targetX + HANDLE_OFFSET,
    targetY,
    targetPosition,
    centerX: showLabel ? sourceX + 35 : undefined,
    borderRadius: 0,
  });

  const addStepPos = {
    x: showLabel ? targetX - 43 : labelX,
    y: showLabel ? targetY : labelY,
  };

  return (
    <>
      <ReactFlowBaseEdge id={id} path={edgePath} interactionWidth={0} />
      <EdgeLabelRenderer>
        {!disabled ? (
          <Flex
            center
            className={style.AddStepEdge}
            style={{
              width: 90 - arrowSpacing,
              transform: `translate(-50%, -50%) translate(${addStepPos.x}px, ${addStepPos.y}px)`,
              zIndex: addStepMenuVisible ? 2000 : undefined,
            }}
            id={identifier}
            onMouseEnter={showAddStep}
            onMouseLeave={() => !addStepMenuVisible && hideAddStep()}
          >
            {addStepVisible && (
              <AddStep
                disabled={!topologyEditable}
                options={stepOptions}
                menuOpen={addStepMenuVisible}
                onToggleMenu={toggleAddStepMenu}
              />
            )}
          </Flex>
        ) : null}

        {showLabel ? (
          <Box
            left={sourceX + 50}
            top={targetY}
            className={style.EdgeLabel}
            onClick={onLabelClick}
          >
            {data?.label}
          </Box>
        ) : null}
      </EdgeLabelRenderer>
    </>
  );
};
