import React, { useState } from 'react';
import { Topic } from 'models/topic';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { TopicSelect } from 'components/topics/TopicSelect';
import { IdentityScope } from 'models/identity';
import { User } from 'models/user';
import { useHasNoRestrictions } from './hooks/roles';

export const TopicSelector: React.FC<{
  permittedTopics: Topic[];
  setPermittedTopics: (value: Topic[]) => void;
  onChange: (value: IdentityScope[]) => void;
  scope?: IdentityScope[];
  role: User['role'];
}> = ({ permittedTopics, setPermittedTopics, onChange, scope, role }) => {
  const [allOption, setAllOption] = useState<boolean>(() => {
    if (!scope || scope.length === 0) return false;
    return (
      JSON.stringify(scope[0].name) === JSON.stringify({ import: 'tenant' })
    );
  });

  const hasNoRestrictions = useHasNoRestrictions(`${role}`);

  const onAllSelectedChange = React.useCallback(
    (selected: boolean) => {
      setAllOption(selected);
      if (selected) {
        onChange([{ type: 'topic', name: { import: 'tenant' } }]);
      } else {
        onChange([]);
      }
    },
    [onChange]
  );

  const handleChange = React.useCallback(
    (topics: Topic[]) => {
      setAllOption(false);
      setPermittedTopics(topics);
      const scopes: IdentityScope[] = topics.map((topic) => {
        return {
          type: 'topic',
          name: `topic:${topic.id}`,
        };
      });
      onChange(scopes);
    },
    [onChange, setPermittedTopics]
  );

  return (
    <Fieldset>
      <div className={fieldsetStyles.fieldGroupContainer}>
        <Field label="Topics">
          {hasNoRestrictions ? (
            <div>Selected role has access to all topics</div>
          ) : (
            <TopicSelect
              value={permittedTopics}
              onChange={handleChange}
              allOption={{
                selected: allOption,
                onChange: onAllSelectedChange,
              }}
            />
          )}
        </Field>
      </div>
    </Fieldset>
  );
};
