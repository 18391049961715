import { PermissionCapabilities } from 'models/permissions';

export type DeliveryChannels = {
  feed: boolean;
  email: boolean;
  assistant: boolean;
  push: boolean;
};

export type Channel = keyof DeliveryChannels;
export type DirectDeliveryChannel = keyof Omit<DeliveryChannels, 'feed'>;

export type JourneyDeliveryChannels = Omit<DeliveryChannels, 'assistant'> & {
  notification_center: boolean;
};

export type JourneyChannel = keyof JourneyDeliveryChannels;

export const CHANNELS: Channel[] = ['email', 'assistant', 'push', 'feed'];

export const CHANNELS_ALL_ENABLED: DeliveryChannels = {
  feed: true,
  email: true,
  assistant: true,
  push: true,
};

export const defaultChannels: DeliveryChannels = {
  feed: true,
  push: true,
  assistant: true,
  email: true,
};

export const isChannel = (channel: Channel | unknown): channel is Channel => {
  const chan = channel as Channel;
  return !!chan && CHANNELS.includes(chan);
};

export const isChannelsArray = (
  channels: Parameters<typeof isChannel>[0][]
): channels is Channel[] => {
  return channels.filter(isChannel).every(isChannel);
};

export const allExceptFeedDisabled = (channels: DeliveryChannels): boolean => {
  return Object.entries(channels).every(
    ([channel, enabled]) => channel === 'feed' || !enabled
  );
};

export const someExceptFeedEnabled = (channels: DeliveryChannels): boolean => {
  return Object.entries(channels).some(
    ([channel, enabled]) => channel !== 'feed' && enabled
  );
};

export const selectedChannels = (channels: DeliveryChannels): Channel[] => {
  return Object.entries(channels)
    .filter(([_, selected]) => selected)
    .map(([channel, _]) => channel) as Channel[];
};

/**
 * Modifier
 * ==========
 * Modifier is a function that takes a setting (in this case, deliveryChannels),
 * and returns a modified version of it.
 *
 * Using the withModifiers, we pass a number of Modifiers and a starting value
 * and withModifiers will exectue each in turn, returning the final modified verison.

 * This strips out all assigning and reassigning and lets us focus on whats important:
 * the value we start with, the steps we take along the way, and the value we end with.

 * Example:
 *
 * const modifiedDeliveryChannels = withModifiers([
 *  withEmailOnly(isEmailOnly),
 *  withDefaults(defaults)
 * ], initialDeliveryChannels)
 *
 * If this pattern is useful, we can abstract this into a generic Modifier
 * for other settings
 */

type Modifier = (channels: DeliveryChannels) => DeliveryChannels;

export const withModifiers = (
  modifiers: Modifier[],
  initialValue: DeliveryChannels
): DeliveryChannels => {
  return modifiers.reduce((acc, modifier) => {
    return modifier(acc);
  }, initialValue);
};

/**
 * If Email Only, only email is selectable
 */
export const withEmailOnly = (isEmailOnly: boolean): Modifier => {
  return ({ assistant, email, push, feed }) => ({
    feed: !isEmailOnly && feed,
    assistant: !isEmailOnly && assistant,
    email: isEmailOnly || email,
    push: !isEmailOnly && push,
  });
};

/**
 * If Topic Only, only feed is selectable
 */
export const withTopicOnly = (isTopicOnly: boolean): Modifier => {
  return ({ assistant, email, push, feed }) => ({
    feed: isTopicOnly || feed,
    assistant: !isTopicOnly && assistant,
    email: !isTopicOnly && email,
    push: !isTopicOnly && push,
  });
};

/**
 * If setChannelDefaultsEnabled FeatureFlag is enabled, set
 * DeliveryChannels to channelDefaults where they are defined
 * Otherwise, fall back to given deliveryChannels
 */
export const withProgramChannelDefaults = (
  channelDefaults?: Partial<DeliveryChannels>,
  setChannelDefaultsEnabled?: boolean
): Modifier => {
  return ({ assistant, email, push, feed }) => {
    if (!setChannelDefaultsEnabled) {
      return {
        assistant,
        email,
        push,
        feed,
      };
    }

    return {
      feed: channelDefaults?.feed ?? feed,
      assistant: channelDefaults?.assistant ?? assistant,
      email: channelDefaults?.email ?? email,
      push: channelDefaults?.push ?? push,
    };
  };
};

/**
 * Where RestrictedField is present, channel is deseleted
 *
 * TODO: Check permissions feature flag here? We're checking the feature flag for the withProgramChannelDefaults so may make sense to do it here as well
 */
export const withRestrictedFields = (
  restrictedFields: Pick<
    PermissionCapabilities,
    'sendEmailAccess' | 'sendAssistantAccess' | 'sendPushAccess'
  >
): Modifier => {
  return ({ assistant, email, push, feed }) => ({
    feed,
    assistant: assistant && restrictedFields.sendAssistantAccess,
    email: email && restrictedFields.sendEmailAccess,
    push: push && restrictedFields.sendPushAccess,
  });
};
