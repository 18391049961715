import React from 'react';
import {
  Router,
  Redirect,
  RouteComponentProps,
  useNavigate,
} from '@reach/router';
import { Audience } from 'models/audience';

import { WithPermission } from 'shared/WithPermission';
import { UserFiltersProvider } from 'contexts/people/filters';
import { AudiencesListPage } from './Audiences/AudiencesListPage';
import { AudienceBuilderPage } from './AudienceBuilderPage';
import { UsersListPage } from './UsersListPage';
import { UserProfile } from './User/Edit';
import { UserCreation } from './User/New';

export const People: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const onCreateAudience = ({ id }: Audience) => {
    navigate(`${id}`, { replace: true });
  };

  return (
    <WithPermission permissions={['peopleAccess']}>
      <UserFiltersProvider>
        <Router>
          <UsersListPage path="users" />
          <AudiencesListPage path="audiences" />
          <AudienceBuilderPage path="audiences/:groupId" />
          <AudienceBuilderPage path="audiences/new" onSave={onCreateAudience} />
          <UserCreation path="users/new" />
          <UserProfile path="users/:id/edit" />
          <Redirect from="/" to="audiences" noThrow />
          <Redirect from="*" to="/" noThrow default />
        </Router>
      </UserFiltersProvider>
    </WithPermission>
  );
};
