import * as React from 'react';
import { InclusiveRule } from 'models/inclusive-language';
import { pluralize } from 'utility/text';
import { Match } from './types';
import { useInclusiveLanguageQuery } from './useQueries';

export class Analyzer {
  constructor(readonly dataRef: React.RefObject<false | InclusiveRule[]>) {}

  static compile(candidates: Match[]): Match[] {
    candidates.sort((a, b) => {
      const ordered = a.offset - b.offset;
      if (!ordered) return b.term.length - a.term.length;
      return ordered;
    });
    return candidates.filter(
      (candidate, idx) =>
        !idx || // safe guard for "nested" suggestions
        candidate.offset >=
          candidates[idx - 1].offset + candidates[idx - 1].term.length
    );
  }

  static indexesOf(searchIn: string, searchFor: string): number[] {
    const indices: number[] = [];
    let chunk = searchIn.substr(0);
    // eslint-disable-next-line
    while (true) {
      const offset = chunk.indexOf(searchFor);
      if (offset < 0) break; // bail on no more matches
      if (
        !(offset > 0 && chunk.substr(offset - 1, 1).match(/\w/i)) &&
        !(
          offset >= 0 && chunk.substr(offset + searchFor.length, 1).match(/\w/i)
        )
      ) {
        const prevOffset = indices.length
          ? indices[indices.length - 1] + searchFor.length
          : 0;
        indices.push(prevOffset + offset);
      }
      chunk = chunk.substr(offset + searchFor.length);
    }
    return indices;
  }

  static matchCase(formatted: string, unformatted: string): string {
    if (formatted.toLocaleUpperCase() === formatted)
      return unformatted.toLocaleUpperCase();
    if (formatted.toLocaleLowerCase() === formatted)
      return unformatted.toLocaleLowerCase();
    return (
      unformatted.substr(0, 1).toLocaleUpperCase() +
      unformatted.substr(1).toLocaleLowerCase()
    );
  }

  ready(): boolean {
    return Array.isArray(this.dataRef.current);
  }

  suggestions(text?: string | null): Match[] {
    if (!Array.isArray(this.dataRef.current)) return [];
    const rules = this.dataRef.current;
    const suggestions: Match[] = [];
    const lcText = text || '';
    const existingTermsLC = rules.map((rule) => rule.terms).flat();
    rules.forEach((rule) => {
      rule.terms
        .filter((t) => t.length > 0)
        .forEach((term) => {
          Analyzer.indexesOf(lcText, term).forEach((offset) => {
            const input = (text || '').substr(offset, term.length);
            suggestions.push({
              term: input,
              offset,
              explanation: rule.explanation,
              suggestion: rule.suggestions,
            });
          });
          const pluralText = pluralize(term).toLocaleLowerCase();
          const pluralTermIndex = existingTermsLC.indexOf(pluralText);
          // It should not execute Analyzer if rules have plural definition
          if (pluralTermIndex === -1) {
            Analyzer.indexesOf(lcText, pluralText).forEach((offset) => {
              const input = (text || '').substr(offset, pluralize(term).length);
              suggestions.push({
                term: input,
                offset,
                explanation: rule.explanation,
                suggestion: rule.suggestions.map((s) => pluralize(s)),
              });
            });
          }
        });
    });
    return Analyzer.compile(suggestions);
  }
}

export function useTextAnalyzer(programId: number): Analyzer {
  const query = useInclusiveLanguageQuery(programId);
  const data = !query.isLoading && (query.data ?? []);
  const dataRef = React.useRef(data);
  dataRef.current = data;
  const [analyzer] = React.useState(new Analyzer(dataRef));
  return analyzer;
}
