import React, { useEffect, useRef } from 'react';
import styles from './modal.module.css';

export const Modal: React.FC<{
  title: string;
  showTitle: boolean;
  showModal: boolean;
  onClose: () => void;
  modalStyle?: React.CSSProperties;
  modalBodyStyle?: React.CSSProperties;
  identifier?: string;
  modalAriaLabel?: string;
}> = ({
  children,
  title,
  showTitle,
  showModal,
  onClose,
  modalStyle,
  modalBodyStyle,
  identifier,
  modalAriaLabel,
}) => {
  /** REFS */
  const modalVeil = useRef<HTMLDivElement>(document.createElement('div'));
  const modalBody = useRef<HTMLDivElement>(document.createElement('div'));

  const fadeIn = () => {
    if (!modalVeil || !modalVeil.current) return;
    const veil = modalVeil.current;
    let opacity = 0;
    const body = modalBody.current;

    veil.style.display = 'flex';
    body.style.opacity = '0';
    const fadeInAnimation = setInterval(() => {
      if (opacity > 0.4) {
        body.style.opacity = '1';
        clearInterval(fadeInAnimation);
        return;
      }

      opacity += 0.1;
      veil.style.background = `rgba(0,0,0, ${opacity})`;
      body.style.opacity = `${opacity}`;
    }, 65);
  };

  const fadeOut = () => {
    if (!modalVeil || !modalVeil.current) return;

    const veil = modalVeil.current;
    let opacity = 0.4;
    const body = modalBody.current;

    const fadeOutAnimation = setInterval(() => {
      if (opacity <= 0) {
        veil.style.display = 'none';
        clearInterval(fadeOutAnimation);
        onClose();
        return;
      }

      opacity -= 0.1;
      veil.style.background = `rgba(0,0,0, ${opacity})`;
      body.style.opacity = `${opacity}`;
    }, 65);
  };

  const handleModalClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!modalBody.current) return;
    const { target } = (event as unknown) as { target: Node | null };
    if (modalBody.current.contains(target)) return;
    fadeOut();
  };

  /** */
  useEffect(() => {
    if (showModal) {
      fadeIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div
      data-identifier={identifier}
      role="dialog"
      aria-label={modalAriaLabel}
      style={{ height: 0, margin: 0, padding: 0 }}
    >
      <div
        className={styles.modal}
        style={modalStyle}
        ref={modalVeil}
        onClick={handleModalClick}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <div
          className={styles.modalBody}
          ref={modalBody}
          style={modalBodyStyle}
          onClick={() => {}}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <div className={styles.modalHeader}>
            {showTitle ? (
              <div className={styles.modalTitle}>{title}</div>
            ) : (
              <></>
            )}
            <button
              className={styles.closeIcon}
              onClick={() => fadeOut()}
              type="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              &times;
            </button>
          </div>
          <div className={styles.modalContent}>
            {/* eslint-disable-next-line  */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
