import { WorkflowTemplate, ProcessActions } from 'models/workflow-templates';
import { MutationOptions } from 'hooks/common';
import { processWorkflowTemplate } from 'services/api-workflow-templates';

export const useArchiveWorkflowTemplate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<WorkflowTemplate> = {}
): {
  archive: (templateId: number) => void;
} => {
  const archive = (templateId: number) => {
    processWorkflowTemplate(programId, templateId, ProcessActions.Archive)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { archive };
};

export const useDraftWorkflowTemplate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<WorkflowTemplate> = {}
): {
  draft: (templateId: number) => void;
} => {
  const draft = (templateId: number) => {
    processWorkflowTemplate(programId, templateId, ProcessActions.Draft)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { draft };
};

export const useActivateWorkflowTemplate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<WorkflowTemplate> = {}
): {
  activate: (templateId: number) => void;
} => {
  const activate = (templateId: number) => {
    processWorkflowTemplate(programId, templateId, ProcessActions.Activate)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { activate };
};

export const usePromoteWorkflowTemplate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<WorkflowTemplate> = {}
): {
  promote: (templateId: number) => void;
} => {
  const promote = (templateId: number) => {
    processWorkflowTemplate(programId, templateId, ProcessActions.Promote)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { promote };
};

export const useDemoteWorkflowTemplate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<WorkflowTemplate> = {}
): {
  demote: (templateId: number) => void;
} => {
  const demote = (templateId: number) => {
    processWorkflowTemplate(programId, templateId, ProcessActions.Demote)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { demote };
};
