import { Shortcut } from 'models/shortcut';
import snakecaseKeys from 'snakecase-keys';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';

export type ShortcutFetchParams = {
  programId: number;
};
export type ShortcutParams = {
  programId: number;
  shortcutId: number;
};
export type ShortcutOrderParams = {
  ids: Array<number>;
};

export type ShortcutsData = {
  data: Array<Shortcut>;
};

export type ShortcutData = {
  data: Shortcut;
};

export const fetchShortcuts = async (
  props: ShortcutFetchParams
): Promise<ShortcutsData> => {
  const { programId } = props;

  const url = `${bossanovaDomain}/samba/programs/${programId}/shortcuts`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching shortcuts`);
};

export const fetchShortcut = async (
  props: ShortcutParams
): Promise<ShortcutData> => {
  const { programId, shortcutId } = props;

  if (Number.isNaN(shortcutId)) {
    return {
      data: {} as Shortcut,
    };
  }

  const url = `${bossanovaDomain}/samba/programs/${programId}/shortcuts/${shortcutId}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching a shortcut`);
};

type CreateShortcutProps = {
  programId: number;
  payload: { data: Partial<Shortcut> };
};
export const createShortcut = async ({
  programId,
  payload,
}: CreateShortcutProps): Promise<Shortcut> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/shortcuts`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(payload)),
  });

  if (!response.ok) {
    throw new Error('Error creating shortcut');
  }

  return response.json().then((output) => deepCamelcaseKeys(output)?.data);
};
export const orderShortcuts = async (
  programId: number,
  payload: ShortcutOrderParams
): Promise<null> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/shortcuts/order`;
  const response = await request(url, {
    body: JSON.stringify({ data: payload }),
    method: 'put',
  });

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error updating order of shortcuts`);
};

export const updateShortcut = async ({
  programId,
  payload,
}: CreateShortcutProps): Promise<Shortcut> => {
  const { id, ...data } = payload.data;
  const url = `${bossanovaDomain}/samba/programs/${programId}/shortcuts/${id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(
      snakecaseKeys({
        data: {
          ...data,
          configuration: JSON.stringify({ ...data.configuration }),
          criterion: data.criterion && JSON.stringify({ ...data.criterion }),
        },
      })
    ),
  });

  if (response.ok) {
    return response.json().then((output) => deepCamelcaseKeys(output)?.data);
  }

  throw new Error(`Error updating a shortcut`);
};

export type ShortcutDeleteParams = {
  programId: number;
  id: Shortcut['id'];
};
export const deleteShortcut = async ({
  programId,
  id,
}: ShortcutDeleteParams): Promise<null> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/shortcuts/${id}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status !== 204) {
    throw new Error(`Error deleting shortcut`);
  }
  return null;
};
