import React from 'react';
import styles from './styles.module.css';

interface Props {
  label: string;
  value: string;
}

export function Option({ label, value }: Props): JSX.Element {
  return (
    <div className={styles.option}>
      <span className={styles.label}>{label}: </span>
      <strong className={styles.value}>{value}</strong>
    </div>
  );
}
