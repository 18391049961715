import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { EmailAlias } from 'models/email-alias';
import { BulkSelection, EmailAliasBulkActionFilters } from 'hooks/common';
import { EmptyablePage, Page } from './common';
import { request, kebabCaseKeys, removeEmptyKeys } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  search?: string;
  page?: number;
  perPage?: number;
  status?: string[];
};

export type EmailsCollectionData = Page<EmailData>;
export type EmailsEmptyableCollectionData = EmptyablePage<EmailData>;

export type EmailData = {
  id: string;
  type: string;
  attributes: Omit<EmailAlias, 'id'>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapPaginationData(response: any) {
  if (response.meta?.page && !response.meta?.currentPage) {
    return {
      ...response,
      meta: {
        ...response.meta,
        currentPage: response.meta.page,
      },
    };
  }
  return response;
}

export const fetchEmailAliases = async (
  props: FetchProps
): Promise<EmailsCollectionData> => {
  const { programId, search, page, perPage, status } = props;

  const q = qs.stringify(snakecaseKeys({ search, page, perPage, status }), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses?${q}`;

  const response = await request(url);
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });
    return mapPaginationData(result);
  }
  throw new Error(`Error fetching emails: ${response.status}`);
};

export const fetchById = async (
  programId: number,
  id: string
): Promise<EmailData> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/${id}`;

  const response = await request(url);
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });
    return mapPaginationData(result);
  }
  throw new Error(`Error fetching email: ${response.status}`);
};

export const fetchDefaultEmailAlias = async (
  programId: number
): Promise<EmailData> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/default`;
  const response = await request(url);
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });
    return mapPaginationData(result);
  }
  throw new Error(`Error fetching default email: ${response.status}`);
};

export const setEmailAliasAsDefault = async (
  programId: number,
  emailAliasId: string
): Promise<string> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/${emailAliasId}/default`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error setting email as default: ${response.status}`);
};

export const archiveEmailAlias = async (
  programId: number,
  emailAliasId: string
): Promise<EmailData> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/${emailAliasId}/archive`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error unarchive email: ${response.status}`);
};

export const updateEmailAlias = async (
  programId: number,
  emailAlias: EmailAlias
): Promise<EmailData> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/${emailAlias.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(kebabCaseKeys(emailAlias)),
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error updating email: ${response.status}`);
};

export const createEmailAlias = async (
  programId: number,
  emailAlias: Partial<EmailAlias>
): Promise<EmailData> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(kebabCaseKeys(emailAlias)),
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error creating email: ${response.status}`);
};

export const unarchiveEmailAlias = async (
  programId: number,
  emailAliasId: string
): Promise<EmailData> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/${emailAliasId}/unarchive`;
  const response = await request(url, {
    method: 'POST',
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error unarchive email: ${response.status}`);
};

export const bulkArchiveEmailAlias = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: EmailAliasBulkActionFilters
): Promise<undefined> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/bulk_archive`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({
        bulkSelection,
        ...removeEmptyKeys(filterConfig),
      })
    ),
  });

  if (response.status === 200) return;

  throw new Error(`Error archive email alias: ${response.status}`);
};

export const bulkUnarchiveEmailAlias = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: EmailAliasBulkActionFilters
): Promise<undefined> => {
  const url = `${apiRoot}/samba/programs/${programId}/contact_addresses/bulk_unarchive`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(
      snakecaseKeys({
        bulkSelection,
        ...removeEmptyKeys(filterConfig),
      })
    ),
  });

  if (response.status === 200) return;

  throw new Error(`Error unarchive email alias: ${response.status}`);
};
