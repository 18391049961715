import React, { FC } from 'react';
import { ConfirmModal } from 'DesignSystem/Components';

interface Props {
  onClose: () => void;
}
export const ErrorModal: FC<Props> = ({ children, onClose }) => {
  return (
    <ConfirmModal
      title="Error"
      onConfirm={onClose}
      onCancel={onClose}
      confirmLabel="Close"
      hideCancel
    >
      {children}
    </ConfirmModal>
  );
};
