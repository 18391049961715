import React from 'react';
import { Router, Redirect, RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { useLibraryAccess } from './useLibraryAccess';
import { BlocksLibrary } from './Blocks';
import { PollsLibrary } from './Polls';
import { TemplatesListPage } from './templates';
import { FontsListPage } from './fonts';

export const Library: React.FC<RouteComponentProps> = () => {
  const { pages } = useLibraryAccess();
  return (
    <WithPermission permissions={['libraryAccess']}>
      <Router>
        <BlocksLibrary path="/blocks" />
        <TemplatesListPage path="/templates" />
        {/* uncomment this when the journey templates have been seeded in scope of FE-7003 */}
        {/* <TemplatesListPage isJourneyTemplates path="/journey_templates" /> */}
        <FontsListPage path="/fonts" />
        <PollsLibrary path="/polls" />
        <Redirect from="/" to={pages[0]?.to || '..'} noThrow />
        <Redirect from="*" to="/" noThrow default />
      </Router>
    </WithPermission>
  );
};
