import * as React from 'react';
import { useEffect } from 'react';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Option } from './types';

interface PropsType {
  options: Array<Option>;
  value: Array<Option>;
  onChange: (value: Array<Option>) => void;
  onClose: () => void;
}

export const Dropdown: React.FC<PropsType> = ({
  options,
  value,
  onChange,
  onClose,
}) => {
  const onDismissRef = React.useRef<() => void>(onClose);
  useEffect(() => {
    onDismissRef.current = onClose;
  }, [onClose]);

  const optionDic: Record<string, Option> = {};
  const rowIds: string[] = [];
  const selectedIds = value.map((item) => item.name);
  options.forEach((option) => {
    optionDic[option.name] = option;
    rowIds.push(option.name);
  }, {});

  const handleChange = (ids: string[]) => {
    const newValue = ids.map((id) => optionDic[id]);
    onChange(newValue);
  };

  function optionRenderer(key: string) {
    const option = optionDic[key];
    if (option.hidden) return null;
    return <div>{option.label}</div>;
  }

  return (
    <InfiniteSelect
      rowIds={rowIds}
      rowRenderProp={optionRenderer}
      selectedIds={selectedIds}
      onSelectedIdsChange={handleChange}
      dismissButton="Done"
      onDismissRef={onDismissRef}
      itemHeight={40}
      maxHeight={240}
    />
  );
};
