import { ConfirmModal } from 'DesignSystem/Components';
import { useToggle } from 'hooks/useToggle';
import { Alert } from '@socialchorus/shared-ui-components';

export function PushOnlyWarning(): JSX.Element {
  const { disable, value: modalOpen } = useToggle(true);
  return (
    <>
      {modalOpen && (
        <ConfirmModal
          title="Unsupported Configuration"
          onCancel={disable}
          hideCancel
          confirmLabel="Continue"
          onConfirm={disable}
        >
          This campaign uses a channel configuration that is no longer
          supported. It will work as presently configured, but if you edit the
          channels, Notification Center will be required to send a push
          notification.
        </ConfirmModal>
      )}

      <Alert
        title="Unsupported Channel Configuration"
        description="Delivery to Notification Center is required to send push notifications."
        type="warning"
      />
    </>
  );
}
