import * as React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { LoadingScreen } from 'shared/LoadingScreen';
import { useLibraryAccess } from '../useLibraryAccess';
import { FontsListPage as Actual } from './FontsListPage';

export const FontsListPage: React.FC<RouteComponentProps> = () => {
  const access = useLibraryAccess();
  if (!access.ready) return <LoadingScreen />;
  if (!access.has('fonts')) {
    navigate('..');
    return null;
  }
  return <Actual tabs={access.pages} />;
};
