import { LANGS_UNICODE } from './constants';

export function isRightToLeftLangs(text: string): boolean {
  const rtlLangUnicodes = Object.values(LANGS_UNICODE).join('');
  const langsRegx = new RegExp(`[${rtlLangUnicodes}]`);
  return langsRegx.test(text);
}

export function detectLanguageDirection(text: string): string {
  return isRightToLeftLangs(text) ? 'rtl' : 'ltr';
}
