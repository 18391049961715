import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { SearchBar } from 'shared/SearchBar';
import { FilterDropdown } from 'shared/FilterDropdown';

type PropTypes = {
  onSearchTermChange: (value: string) => void;
  onStatusChange: (value: string[]) => void;
  searchTerm: string;
  selectedStatus?: string[];
};

export const FiltersBar: React.FC<PropTypes> = ({
  searchTerm,
  selectedStatus,
  onSearchTermChange,
  onStatusChange,

  // onStatusChange,
}) => {
  const statusOptions = [
    { value: 'processing', label: 'Processing' },
    { value: 'complete', label: 'Complete' },
    { value: 'failed', label: 'Failed' },
  ];
  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={onSearchTermChange}
        placeholder="Search imports"
        panelClassName={styles.searchBarWrapper}
      />
      <div className={styles.filterSortRow}>
        <div className={styles.filters}>
          <FilterDropdown
            label="Status"
            options={statusOptions}
            onChange={onStatusChange}
            selectedOptions={statusOptions.filter((opt) =>
              selectedStatus?.includes(opt.value)
            )}
          />
        </div>
      </div>
    </div>
  );
};
