import {
  FieldData,
  FieldDefinition,
  FieldType,
  toAttachmentFieldData,
  toBoxIntegrationFieldData,
  toButtonLinkFieldData,
  toDateFieldData,
  toImageFieldData,
  toImagesFieldData,
  toLinksFieldData,
  toPollFieldData,
  toSocialsFieldData,
  toUsersFieldData,
  toVideoFieldData,
} from 'models/publisher/block';
import React from 'react';
import { Attachment } from './Attachment';
import { BoxIntegration } from './BoxIntegration';
import { DateTimeField } from './DateTime';
import { SingleImage } from './images/SingleImage';
import { MultiImage } from './images/MultiImage';
import { MultiLink } from './links/MultiLink';
import { PollModern } from './PollModern';
import { Users } from './Users';
import { Video } from './Video';
import { UpdateOpts } from '../useEditor';
import { ButtonLinkField } from './links/ButtonLink';

type ModalFieldProps = {
  field: FieldDefinition;
  default_data: FieldData;
  data?: FieldData;
  onChange: (data: FieldType, opts?: UpdateOpts) => void;
  enableSave: () => void;
  disableSave: () => void;
  saveDisabled: boolean;
  apply?: () => void;
};

const linkFieldEditor = (props: ModalFieldProps): React.ReactElement => {
  const { data = {}, field, onChange } = props;
  const fn = field.name === 'social' ? toSocialsFieldData : toLinksFieldData;
  return (
    <MultiLink
      field={field}
      data={fn(data[field.name])}
      default_data={fn(data[field.name])}
      onChange={onChange}
    />
  );
};

export const modalEditableFields: {
  [type: string]: (props: ModalFieldProps) => React.ReactElement;
} = {
  attachment: ({ data = {}, field, onChange }) => {
    return (
      <Attachment
        field={field}
        data={toAttachmentFieldData(data[field.name])}
        default_data={toAttachmentFieldData(data[field.name])}
        onChange={onChange}
      />
    );
  },
  'box-integration': ({ data = {}, field, onChange }) => {
    return (
      <BoxIntegration
        field={field}
        data={toBoxIntegrationFieldData(data[field.name])}
        default_data={toBoxIntegrationFieldData(data[field.name])}
        onChange={onChange}
      />
    );
  },
  date: ({ data = {}, default_data, field, onChange }) => {
    return (
      <DateTimeField
        field={field}
        data={toDateFieldData(data[field.name])}
        default_data={toDateFieldData(default_data[field.name])}
        onChange={onChange}
      />
    );
  },
  image: ({
    data = {},
    default_data,
    field,
    onChange,
    apply,
    enableSave,
    disableSave,
  }) => {
    return (
      <SingleImage
        apply={apply}
        field={field}
        data={toImageFieldData(data[field.name])}
        default_data={toImageFieldData(default_data[field.name])}
        onChange={onChange}
        enableSave={enableSave}
        disableSave={disableSave}
      />
    );
  },
  'image[]': ({
    data = {},
    default_data,
    field,
    onChange,
    enableSave,
    disableSave,
    saveDisabled,
  }) => {
    return (
      <MultiImage
        field={field}
        data={toImagesFieldData(data[field.name])}
        default_data={toImagesFieldData(default_data[field.name])}
        onChange={onChange}
        saveDisabled={saveDisabled}
        enableSave={enableSave}
        disableSave={disableSave}
      />
    );
  },
  'link[]': linkFieldEditor,
  'button-link': ({ data = {}, field, onChange }) => {
    return (
      <ButtonLinkField
        field={field}
        data={toButtonLinkFieldData(data[field.name])}
        default_data={toButtonLinkFieldData(data[field.name])}
        onChange={onChange}
      />
    );
  },
  'social[]': linkFieldEditor,
  poll: ({ data = {}, default_data, field, onChange }) => {
    return (
      <PollModern
        field={field}
        data={toPollFieldData(data[field.name])}
        default_data={toPollFieldData(default_data[field.name])}
        onChange={onChange}
      />
    );
  },
  'user[]': ({ data = {}, default_data, field, onChange }) => {
    return (
      <Users
        field={field}
        data={toUsersFieldData(data[field.name])}
        default_data={toUsersFieldData(default_data[field.name])}
        onChange={onChange}
      />
    );
  },
  video: ({
    data = {},
    default_data,
    field,
    onChange,
    disableSave,
    enableSave,
  }) => {
    return (
      <Video
        field={field}
        data={toVideoFieldData(data[field.name])}
        default_data={toVideoFieldData(default_data[field.name])}
        onChange={onChange}
        disableSave={disableSave}
        enableSave={enableSave}
      />
    );
  },
};
