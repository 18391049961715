import React, { FC } from 'react';
import { TabName, useTopicFormCtx } from '../../../../context';
import PostsPreview from './preview/Posts';
import MembersPreview from './preview/Members';
import AboutPreview from './preview/About';
import styles from './styles.module.css';
import ShortcutsPreview from './preview/Shortcuts';
import StatsPreview from './preview/StatsPreview';

interface Props {
  currentTab: TabName;
}

const PreviewCards: Record<TabName, FC> = {
  about: AboutPreview,
  posts: PostsPreview,
  shortcuts: ShortcutsPreview,
  members: MembersPreview,
};

export const BaseTab: FC<Props> = ({ children, currentTab }) => {
  const { state } = useTopicFormCtx();
  const tabs = state.fields.enabledTabs;
  const showStats = tabs.includes('posts');

  // First tab is "home" and should have preview cards for all other tabs, as long as there is a preview to show.
  const hasPreview = tabs[0] === currentTab && (tabs.length > 1 || showStats);

  let sidebar = null;
  if (hasPreview) {
    sidebar = (
      <div className={styles.sidebar}>
        {tabs.map((tab) => {
          if (tab === currentTab) {
            return null;
          }
          const Card = PreviewCards[tab];
          return <Card key={tab} />;
        })}
        {showStats && <StatsPreview />}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={hasPreview ? styles.content : styles.contentNoSidebar}>
        {children}
      </div>
      {sidebar}
    </div>
  );
};
