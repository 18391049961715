import { Flex } from 'DesignSystem/Layout/Flex';
import React from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useProgram } from 'contexts/program';
import { SearchBar } from 'shared/SearchBar';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useDebounce } from 'hooks/useDebounce';
import { useJourneyTemplatesInfiniteQuery } from 'hooks/journeys/template';
import styles from './journey-list-page.module.css';
import { JourneyTemplateBanner } from './JourneyTemplateBanner';
import { BlankJourneyButton } from './BlankJourneyButton';

const columnCount = 4;
export const JourneyTemplatesInfiniteList: React.FC = () => {
  const { id: programId } = useProgram();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();

  const search = useDebounce(searchTerm, 1000);
  const {
    data,
    isLoading,
    errorMessage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useJourneyTemplatesInfiniteQuery(
    {
      programId,
      pageSize: 20,
      search,
      sortBy: 'name',
    },
    {}
  );

  const rows = React.useCallback(
    (index) => {
      const result = [];
      const offset = search ? 0 : -1;
      const itemIndex = index * columnCount;
      if (index === 0 && !search) {
        result.push(<BlankJourneyButton key="blank-canvas" />);
      }
      // move the index to account for the blank canvas button
      for (let i = offset; i < columnCount + offset; i += 1) {
        if (data[itemIndex + i]) {
          result.push(
            <JourneyTemplateBanner
              key={data[itemIndex + i].name}
              template={data[itemIndex + i]}
            />
          );
        }
      }
      return result;
    },
    [data, search]
  );

  return (
    <>
      {isLoading && (
        <Flex className={styles.modalBody}>
          <LoadingSpinner />
        </Flex>
      )}
      {errorMessage && <div>{errorMessage}</div>}
      {data && !errorMessage && !isLoading && (
        <Flex column start className={styles.modalBody}>
          <Flex end className={styles.modalFilterBar}>
            <SearchBar
              placeholder="Search Templates"
              value={searchTerm}
              onChange={setSearchTerm}
            />
          </Flex>
          {data.length > 0 && (
            <Flex wrap start className={styles.templateButtonsWrapper}>
              <InfiniteContainer
                isFullWidth
                isStandalone
                style={{ height: '510px' }}
                parentRef={parentRef}
              >
                {(height: number) => (
                  <InfiniteBannerList
                    isLoading={isLoading}
                    itemCount={Math.ceil((data.length + 1) / columnCount)}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    height={height}
                    rowHeight={172}
                    parentRef={parentRef}
                  >
                    {(index: number) => {
                      return (
                        <Flex className={styles.rowWrapper}>{rows(index)}</Flex>
                      );
                    }}
                  </InfiniteBannerList>
                )}
              </InfiniteContainer>
            </Flex>
          )}
          {data.length === 0 && (
            <Flex className={styles.noItemsFound} center>
              No items found.
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};
