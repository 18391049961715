import * as React from 'react';
import * as Blocks from 'models/publisher/block';
import { User } from 'models/user';
import { FieldFormProps } from '../../../useFieldForm';

export function useUsersDataMutator({
  data,
  onChange,
}: FieldFormProps<Blocks.UserFieldData[]>): {
  value: User[];
  onChange: (users?: User | User[]) => void;
} {
  const value = React.useMemo(() => Blocks.fieldsToUsers(data), [data]);
  const handleChange = React.useCallback(
    (users?: User | User[]) => {
      if (!users) {
        onChange(Blocks.usersToFields([]));
        return;
      }
      onChange(Blocks.usersToFields(!Array.isArray(users) ? [users] : users));
    },
    [onChange]
  );
  return { value, onChange: handleChange };
}
