import React from 'react';
import { useDesign } from 'contexts/design';
import { Box } from 'DesignSystem/Components';
import { languageDisplayName } from 'utility/language';
import { FetchedCaption } from 'services/api-captions';
import { CaptionPreview } from './CaptionPreview';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';

export const List: React.FC<{
  captions: FetchedCaption[];
  locale?: string;
  onEdit: (index: number) => void;
  onRemove: (
    index: number,
    options?: { onSuccess?: () => void; onError?: (error: Error) => void }
  ) => void;
  filename: string;
  videoId?: number;
  isCaptionsUpdating: boolean;
}> = ({
  captions,
  onEdit,
  onRemove,
  filename,
  videoId,
  locale,
  isCaptionsUpdating,
}) => {
  const { active: isDesignAsset } = useDesign();
  const { isCaptionTranscriptionEnabled } = useCaptionsJobs();

  const sortCaptions = (sortableCaptions: FetchedCaption[]) => {
    const languageDisplay = new Intl.DisplayNames(undefined, {
      type: 'language',
      languageDisplay: 'standard',
    });

    return sortableCaptions.sort((a: FetchedCaption, b: FetchedCaption) =>
      languageDisplayName(a.language, languageDisplay).localeCompare(
        languageDisplayName(b.language, languageDisplay)
      )
    );
  };

  return (
    <>
      <ul style={{ listStyleType: 'none' }}>
        {sortCaptions(captions).map((caption, index) => (
          <li key={caption.url}>
            <Box margin={isCaptionTranscriptionEnabled ? [8, 0, 0, 0] : [3, 0]}>
              <CaptionPreview
                locale={locale}
                filename={filename}
                onEdit={() => onEdit(index)}
                onRemove={(options = {}) => onRemove(index, options)}
                caption={caption}
                videoId={videoId}
                isDesignAsset={isDesignAsset}
                isCaptionsUpdating={isCaptionsUpdating}
              />
            </Box>
          </li>
        ))}
      </ul>
    </>
  );
};
