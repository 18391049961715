import * as React from 'react';
import cx from 'classnames';
import { FontOption } from 'models/publisher/style';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { ChevronDown } from 'shared/icons';
import buttonStyles from 'shared/styles/buttons.module.css';
import { SidebarPopout } from '../SidebarPopout';
import styles from '../sidebar-dropdown.module.css';

const getFirstWord = (fontValue: string) => {
  const match = fontValue.match(/['"]?([^'",]+)['"]?/);
  return match ? match[1] : fontValue.split(/\s+/)[0];
};

export const Font: React.FC<{
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
  options: FontOption[];
}> = ({ onChange, value, disabled, options }) => {
  let font = options.find((f) => {
    return f.value === value;
  });

  if (!font && value) {
    const selectedFontFirstWord = getFirstWord(value);
    font = options.find((f) => getFirstWord(f.value) === selectedFontFirstWord);
  }

  const setFont = React.useCallback(
    (fontName: string) => {
      const option = options.find((f) => f.value === fontName);
      if (option) {
        // check if sanitizing is still necessary
        onChange(option.value);
      }
    },
    [options, onChange]
  );

  return (
    <SidebarPopout
      dataTestButton="font-picker-trigger"
      buttonClassName={styles.menuButton}
      buttonChildren={
        <>
          <span
            style={{
              fontFamily: font?.value,
            }}
            className={styles.label}
          >
            {font?.label ?? ''}
          </span>
          <span className={styles.append}>
            <ChevronDown />
          </span>
        </>
      }
      menuChildren={
        <ol className={styles.dropdown}>
          {options.map((option) => (
            <li
              key={`${option.value}-${option.id ? option.id : 'standard'}`}
              className={cx(styles.option, styles.label)}
            >
              <button
                type="button"
                onClick={() => {
                  setFont(option.value);
                }}
                className={buttonStyles.buttonReset}
                style={{ fontFamily: option.value }}
              >
                {option.label}
              </button>
              {option.value === value && <SVGIcon name="Check" />}
            </li>
          ))}
        </ol>
      }
      disabled={disabled}
    />
  );
};
