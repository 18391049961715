import React, { createContext, useContext } from 'react';
import { Audience, defaultAudience } from 'models/audience';

type AudienceContextData = {
  audience: Audience;
};

const AudienceContext = createContext({
  audience: defaultAudience({ programId: 0 }),
});

const useAudienceContext = (): AudienceContextData => {
  const context = useContext(AudienceContext);

  if (context === undefined) {
    throw new Error(
      'useAudienceContext() must be used within an <AudienceProvider>'
    );
  }

  return context;
};

export const useAudience = (): Audience => {
  const { audience } = useAudienceContext();
  return audience;
};

export const AudienceProvider: React.FC<{ audience: Audience }> = ({
  children,
  audience,
}) => (
  <AudienceContext.Provider value={{ audience }}>
    {children}
  </AudienceContext.Provider>
);
