import camelcaseKeys from 'camelcase-keys';
import { FeedPreview } from 'models/feed-preview';
import { bossanovaDomain, prepareQueryString, request } from './api-shared';

export interface FeedQueryParams {
  ids?: number[];
  pinned?: boolean;
  pageSize?: number;
  query?: string;
}

interface ResponseMeta {
  page: number;
  perPage: number;
  totalPages: number;
  totalResults: number;
}

export interface FeedPreviewResponse {
  data: FeedPreview[];
  /* Currently is only available for search results */
  meta?: ResponseMeta;
}

export async function getFeedPreview(
  programId: number,
  topicId: number,
  params: FeedQueryParams = {}
): Promise<FeedPreviewResponse> {
  const queryString = prepareQueryString(params);
  let url = `${bossanovaDomain}/v2/programs/${programId}/feed_preview/${topicId}`;
  if (params.query) {
    url += '/search';
  }
  const response = await request(`${url}?${queryString}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    const result = await response.json();
    return {
      data: transformFeedPreviewResponse(result.data),
      meta: camelcaseKeys(result.meta),
    };
  }

  throw new Error(`Error fetching feed preview: ${response.status}`);
}

function transformFeedPreviewResponse(data: unknown): FeedPreview[] {
  if (!Array.isArray(data)) {
    throw new Error('Feed preview response is not an array');
  }

  return data.map((item) => ({
    id: item.id,
    ...camelcaseKeys(item.attributes, {
      deep: true,
    }),
  }));
}
