import React from 'react';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { useProgram } from 'contexts/program';
import { EmailAlias } from 'models/email-alias';
import { useSetEmailAliasAsDefault } from 'hooks/email-alias';
import { useFlashMessage } from 'contexts/flasher';
import { useActions } from './useActions';
import styles from './email-alias.module.css';
import { emailAliasToMenuItems, emailAliasToThumbnail } from './mappings';

type PropsType = {
  emailAlias: EmailAlias;
  index: number;
};

export const EmailAliasBanner: React.FC<PropsType> = ({
  emailAlias,
  index,
}) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const { archive: archiveEmail, unarchive: unarchiveEmail } = useActions();

  const { setAsDefault } = useSetEmailAliasAsDefault(emailAlias, () =>
    setFlashMessage({
      severity: 'info',
      message: `${emailAlias.name} set as default!`,
    })
  );

  const thumbnail = emailAliasToThumbnail(emailAlias, index);
  const menuItems = emailAliasToMenuItems(
    programId,
    emailAlias,
    setAsDefault,
    archiveEmail,
    unarchiveEmail
  );

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`${emailAlias.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{emailAlias.name}</div>
          <p style={{ fontSize: '14px' }}>
            From: {emailAlias.senderName}&lt;{emailAlias.senderEmail}&gt;
            <br />
            Reply-to: {emailAlias.replyToEmail}
          </p>
        </div>
      </div>
    </EmptyBanner>
  );
};
