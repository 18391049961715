import React, { useEffect } from 'react';
import { Topic } from 'models/topic';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { FileUploadButton } from 'shared/FileUploadButton';
import { useImageUploader } from 'hooks/useImageUploader';
import { useProgram } from 'contexts/program';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { TextInput } from 'shared/TextInput';
import { Textarea } from 'shared/forms/Textarea';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Trash } from 'shared/icons';
import { TopicTagSelect } from 'components/topics/TopicTagSelect';
import { TopicTag } from 'models/topic-tag';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import styles from './form.module.css';

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>) => void;
};

export const GeneralSection: React.FC<PropsType> = ({ topic, onChange }) => {
  const { id: programId } = useProgram();

  const uploader = useImageUploader({
    programId,
    onUpload: (imageData) => {
      onChange({ uploadedImage: { url: imageData.url } });
    },
  });

  const [topicTags, setTopicTags] = React.useState<TopicTag[]>([]);
  const updateTopicTags = (values: TopicTag[]) => {
    setTopicTags(values);
    onChange({ channelTags: values });
  };

  const { data: isTopicClassificationEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.ShowTopicClassification'
  );

  useEffect(() => {
    if (topic.channelTags && topic.channelTags.length > 0) {
      setTopicTags(topic.channelTags);
    }
  }, [topic.channelTags]);

  return (
    <Section title="General">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Image">
            <div className={styles.imageField}>
              <FileUploadButton
                onFileSelect={(file) => {
                  uploader.update(file);
                }}
                accept="image/png,image/jpg,image/jpeg"
                iconName="plus"
                iconType="SVG"
                disabled={topic?.default}
              >
                Upload Image
              </FileUploadButton>
              {uploader.isUploading && (
                <div className={styles.imagePreview}>
                  <LoadingSpinner />
                </div>
              )}
              {topic.uploadedImage?.url && (
                <div
                  className={styles.imagePreview}
                  style={{
                    backgroundImage: `url(${topic.uploadedImage.url})`,
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      onChange({ uploadedImage: null });
                    }}
                    disabled={topic?.default}
                  >
                    <Trash />
                  </button>
                </div>
              )}
            </div>
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Name">
            <TextInput
              onChange={(name) => onChange({ name })}
              className={styles.textField}
              value={topic.name}
              disabled={topic?.default}
            />
          </Field>
        </div>
      </Fieldset>

      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Description">
            <Textarea
              value={topic.description}
              onChange={(description) => onChange({ description })}
              disabled={topic?.default}
            />
          </Field>
        </div>
      </Fieldset>

      {isTopicClassificationEnabled?.value && (
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Tags (classification)">
              <TopicTagSelect
                selectedTopicTags={topicTags}
                onSelectedTopicTagsChange={updateTopicTags}
                disabled={topic?.default}
              />
            </Field>
          </div>
        </Fieldset>
      )}
    </Section>
  );
};
