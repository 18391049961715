import React, { ComponentProps } from 'react';
import { Slider } from 'shared/Slider';
import styles from './styles.module.css';

// Mostly a wrapper for the Slider, with some style adjustment
export const Size: React.FC<ComponentProps<typeof Slider>> = (sliderProps) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Slider {...sliderProps} styles={{ wrapper: styles.slider }} />
  /* eslint-enable react/jsx-props-no-spreading */
);
