import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Close, WarningTriangle } from 'shared/icons';
import { Button } from 'DesignSystem/Form';
import cx from 'classnames';
import styles from './styles.module.css';

type PropsType = {
  onDismiss?: () => void;
  message: string;
  severity?: 'error' | 'warning';
};
export const Toast: React.FC<PropsType> = ({
  message,
  onDismiss,
  severity = 'error',
}) => {
  return (
    <Flex className={cx(styles.error, styles[severity])} start>
      <WarningTriangle />
      <span>{message}</span>
      {onDismiss && (
        <Button
          className={styles[`${severity}Button`]}
          clearText
          layoutOnly
          onClick={onDismiss}
          icon={<Close />}
        />
      )}
    </Flex>
  );
};
