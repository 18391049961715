import * as React from 'react';
import cx from 'classnames';
import styles from './color-preview.module.css';

export const Swatch: React.FC<{
  className?: string;
  value?: string;
}> = ({ className = '', value = '' }) => (
  <div
    className={cx({
      [styles.swatch]: !className,
      [className]: !!className,
    })}
    style={{ backgroundColor: value }}
  />
);
