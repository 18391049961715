import React from 'react';
import TableauVizComponent, {
  OptionalTableauVizProps,
  TableauVizCustomProps,
  TableauVizRef,
} from '../TableauVizComponent';

const tableauDomain = process.env.REACT_APP_TABLEAU_DOMAIN;

export interface UseTableauParams {
  ref: TableauVizRef;
  src: string;
  token: string;
  optionalProperties: OptionalTableauVizProps;
}
export interface UseTableauReturn {
  component: React.ReactNode;
}
export default function useTableauEmbed(
  args: UseTableauParams
): UseTableauReturn {
  const { ref, src, token, optionalProperties } = args;
  const {
    onCustomMarkContextMenuEvent,
    onCustomViewLoaded,
    onCustomViewRemoved,
    onCustomViewSaved,
    onCustomViewSetDefault,
    onEditButtonClicked,
    onEditInDesktopButtonClicked,
    onFilterChanged,
    onFirstInteractive,
    onFirstVizSizeKnown,
    onMarkSelectionChanged,
    onToolbarStateChanged,
    onTabSwitched,
    onParameterChanged,
    onUrlAction,
    onWorkbookPublished,
    onWorkbookPublishedAs,
    onWorkbookReadyToClose,
  } = optionalProperties;

  const customProps = React.useMemo<TableauVizCustomProps>(
    () => ({
      src: `${tableauDomain}/views/${src.replace('/sheets', '')}`,
      height: 800,
      token,
      ...optionalProperties,
    }),
    [src, token, optionalProperties]
  );

  return {
    component: (
      <TableauVizComponent
        ref={ref}
        src={customProps.src}
        token={customProps.token}
        initialQueryParams={optionalProperties?.initialQueryParams}
        hide-tabs={optionalProperties?.['hide-tabs']}
        onCustomMarkContextMenuEvent={onCustomMarkContextMenuEvent}
        onCustomViewLoaded={onCustomViewLoaded}
        onCustomViewRemoved={onCustomViewRemoved}
        onCustomViewSaved={onCustomViewSaved}
        onCustomViewSetDefault={onCustomViewSetDefault}
        onEditButtonClicked={onEditButtonClicked}
        onEditInDesktopButtonClicked={onEditInDesktopButtonClicked}
        onFilterChanged={onFilterChanged}
        onFirstInteractive={onFirstInteractive}
        onFirstVizSizeKnown={onFirstVizSizeKnown}
        onMarkSelectionChanged={onMarkSelectionChanged}
        onToolbarStateChanged={onToolbarStateChanged}
        onTabSwitched={onTabSwitched}
        onParameterChanged={onParameterChanged}
        onUrlAction={onUrlAction}
        onWorkbookPublished={onWorkbookPublished}
        onWorkbookPublishedAs={onWorkbookPublishedAs}
        onWorkbookReadyToClose={onWorkbookReadyToClose}
      />
    ),
  };
}
