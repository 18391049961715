import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';

export const EditPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  return (
    <BossViewPage
      app="samba"
      title="Workflow Template Editor"
      src={`#galleries/${id}`}
      returnUrls={[
        '/configure/workflow-templates/archived',
        '/configure/workflow-templates/draft',
        '/configure/workflow-templates/published',
      ]}
    />
  );
};
