import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import { useProgram } from 'contexts/program';
import {
  FeedQueryParams,
  getFeedPreview,
  FeedPreviewResponse,
} from 'services/api-feed-preview';

type UseFeedPreviewResult = UseQueryResult<FeedPreviewResponse, Error>;

export const getFeedPreviewQueryKey = (
  programId: number,
  topicId?: string | number,
  params?: FeedQueryParams
): QueryKey => ['feedPreviews', programId, topicId, params];

export function useFeedPreviewQuery(
  topicId?: string | number,
  params?: FeedQueryParams
): UseFeedPreviewResult {
  const { id: programId } = useProgram();

  return useQuery(getFeedPreviewQueryKey(programId, topicId, params), () =>
    typeof topicId === 'number' || typeof topicId === 'string'
      ? getFeedPreview(programId, Number(topicId), params)
      : undefined
  );
}
