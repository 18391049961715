import * as React from 'react';
import { IMAGE_ACCEPT_STRING, ImageData } from 'models/image';
import { itemToImage } from 'shared/Library/ImagePicker';
import { External } from '../components/External';
import * as SourceMenu from '../../shared/SourceMenu';
import { UPLOAD, LIBRARY, EXTERNAL } from '../../shared/SourceMenuConst';
import { LibraryPicker } from '../components/LibraryPicker';

const { OPENED, CLOSED, DEFAULT_MENU_OPTIONS } = SourceMenu;
export { OPENED, CLOSED };

export function useGallerySourceMenu(
  initial: SourceMenu.State<SourceMenu.Source> = {
    source: UPLOAD,
    visibility: CLOSED,
  },
  onCreate: (data: ImageData[] | File[] | DataTransferItemList) => void,
  maxSelections: number
): SourceMenu.MenuIface<SourceMenu.Source> & {
  fileInput: React.ReactNode;
  libraryInput: React.ReactNode;
  externalInput: React.ReactNode;
} {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onUploadClick = React.useCallback(() => inputRef.current?.click(), [
    inputRef,
  ]);
  const source = SourceMenu.useSourceMenu(DEFAULT_MENU_OPTIONS, initial);
  source.menu = React.useMemo(
    () =>
      source.menu.map((item) =>
        item.source !== UPLOAD
          ? item
          : {
              ...item,
              onClick: onUploadClick,
            }
      ),
    [onUploadClick, source.menu]
  );

  const fileInput = React.useMemo(
    () => (
      <span
        style={{
          display: 'block',
          position: 'absolute',
          height: '1px',
          width: '1px',
          overflow: 'hidden',
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept={IMAGE_ACCEPT_STRING}
          data-test="upload-image"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file =
              event.currentTarget.files && event.currentTarget.files[0];
            if (file) onCreate([file]);
          }}
        />
      </span>
    ),
    [inputRef, onCreate]
  );

  const sourceExternalOnChange = React.useCallback(
    (url: string) => {
      onCreate([{ url, altText: '', processed: false }]);
      source.close();
    },
    [onCreate, source]
  );
  const externalInput = React.useMemo(
    () =>
      source.source !== EXTERNAL || source.visibility !== OPENED ? null : (
        <External onCancel={source.close} onChange={sourceExternalOnChange} />
      ),
    [sourceExternalOnChange, source.close, source.source, source.visibility]
  );

  const libraryInput = React.useMemo(
    () =>
      source.visibility !== OPENED || source.source !== LIBRARY ? null : (
        <LibraryPicker
          maxSelections={maxSelections}
          onCancel={source.close}
          onChange={(images) => {
            onCreate(images.map(itemToImage));
            source.close();
          }}
        />
      ),
    [maxSelections, source, onCreate]
  );
  return {
    ...source,
    fileInput,
    libraryInput,
    externalInput,
  };
}
