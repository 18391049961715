import React from 'react';
import Select, { ValueType } from 'react-select';
import { useProgramIdState } from '../../contexts/program';
import { useUser } from '../../contexts/user';
import { defaultProgramId } from '../../models/user';

export const OrgPicker: React.FC = () => {
  const user = useUser();
  const { accessibleBrands, accessiblePrograms } = user;
  const [programId, setProgramId] = useProgramIdState();

  // Current brand depends on current program, because we have only program-specific pages.
  const brandId =
    accessiblePrograms?.find((p) => p.id === programId)?.brandId || 0;
  const setBrandId = (id: number) => {
    const progId = defaultProgramId(user, id);
    if (progId) setProgramId(progId);
  };

  // Exclude brands that have no programs, because there's no way to switch to them.
  // This may change if we add brand-specific pages in the future.
  const validBrandIds = accessiblePrograms.map((p) => p.brandId);
  const validBrands = accessibleBrands.filter((b) =>
    validBrandIds.includes(b.id)
  );

  type OptionType = { label: string; value: number };
  const options = validBrands.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const currentOption = options.find((opt) => opt.value === brandId);
  const handleChange = (option: ValueType<OptionType>) =>
    setBrandId((option as OptionType).value);

  return (
    <Select
      classNamePrefix="white"
      menuPlacement="top"
      onChange={handleChange}
      options={options}
      value={currentOption}
    />
  );
};
