import * as React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useInitializeTopic, useTopicsInfiniteQuery } from 'hooks/topics';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { WithPermission } from 'shared/WithPermission';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Button, IconButton } from '@socialchorus/shared-ui-components';
import { Icon } from 'shared/Icon';
import { usePermissions } from 'contexts/permissions';
import { TopicsList } from './TopicsList';
import { FiltersBar } from './FiltersBar';
import styles from './topics.module.css';

export const TopicsListPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();

  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [status, setStatus] = useFilterDropdownQueryString('status', [
    'active',
  ]);

  const [visibility, setVisibility] = useFilterDropdownQueryString(
    'visibility'
  );
  const [autoPublish, setAutoPublish] = useFilterDropdownQueryString(
    'autoPublish'
  );
  const [autoFollow, setAutoFollow] = useFilterDropdownQueryString(
    'autoFollow'
  );
  const [userSubmittable, setUserSubmittable] = useFilterDropdownQueryString(
    'userSubmittable'
  );
  const [topicTags, setTopicTags] = useFilterDropdownQueryString('topicTags');

  const parentRef = React.useRef<HTMLDivElement>(null);

  const [newTopicButtonDisabled, setNewTopicButtonDisabled] = React.useState(
    false
  );

  const filterChangeCallbacks: { [key: string]: (values: string[]) => void } = {
    status: setStatus,
    visibility: setVisibility,
    autoPublish: setAutoPublish,
    autoFollow: setAutoFollow,
    userSubmittable: setUserSubmittable,
    topicTags: setTopicTags,
  };

  function onFilterChange(filterName: string, values: string[]) {
    filterChangeCallbacks[filterName](values);
  }

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
    meta,
  } = useTopicsInfiniteQuery({
    programId,
    status: useDebounce(status),
    visibility: useDebounce(visibility),
    autoPublish: useDebounce(autoPublish),
    autoFollow: useDebounce(autoFollow),
    userSubmittable: useDebounce(userSubmittable),
    topicTags: useDebounce(topicTags),
    search: useDebounce(searchTerm),
  });
  const topicLandingV2Enabled = useFeatureFlagsQuery(
    programId,
    'EE.TopicLanding.V2.ConfigEnabled'
  ).data?.value;
  const topicLimitsEnabled = useFeatureFlagsQuery(
    programId,
    'EE.UserGeneratedContent.TopicLimitsEnabled'
  ).data?.value;

  const { permissions } = usePermissions();

  const showTopicLimitToggle =
    topicLimitsEnabled && permissions.configureTopicLimit;

  const onNewTopicClick = () => {
    setNewTopicButtonDisabled(true);
    try {
      if (topicLandingV2Enabled) {
        initializeTopic();
      } else {
        navigate(`/${programId}/configure/topics/new`);
      }
    } catch {
      setNewTopicButtonDisabled(false);
    }
  };
  const { initializeTopic, isLoading: isCreatingTopic } = useInitializeTopic(
    programId,
    {
      onSuccess: (topic) => {
        navigate(`/${programId}/configure/topics/${topic.id}/edit`);
      },
    }
  );

  const TopicListActions = (
    <div className={styles.topicListActions}>
      {/* to minimize the visual impact of the pop-in of just the settings button, going to instead pop-in the entire action list (page renders before flag value is defined) */}
      {showTopicLimitToggle !== undefined && (
        <>
          <Button
            leftIcon={<Icon iconName="plus" />}
            variant="primary"
            label="New Topic"
            onClick={onNewTopicClick}
            isLoading={isCreatingTopic}
            disabled={newTopicButtonDisabled}
          />
          {showTopicLimitToggle && (
            <IconButton
              iconName="settings"
              size="default"
              title="Global Topic Settings"
              onClick={() =>
                navigate(`/${programId}/configure/topics/settings`)
              }
            />
          )}
        </>
      )}
    </div>
  );
  return (
    <WithPermission permissions={['confContentAccess', 'topicsAccess']}>
      <ListPage
        title="Topics"
        breadcrumbs={[{ to: '..', label: 'Configure' }, { label: 'Topics' }]}
        actionsOverride={TopicListActions}
        filterbar={
          <FiltersBar
            onFilterChange={onFilterChange}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            status={status}
            visibility={visibility}
            autoPublish={autoPublish}
            autoFollow={autoFollow}
            userSubmittable={userSubmittable}
            topicTags={topicTags}
          />
        }
      >
        {errorMessage && <>{errorMessage}</>}
        {data && !errorMessage && (
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <TopicsList
                parentRef={parentRef}
                errorMessage={errorMessage}
                isLoading={isLoading}
                topics={data}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                meta={meta}
                height={height}
                filterConfig={{
                  status,
                  visibility,
                  autoPublish,
                  autoFollow,
                  userSubmittable,
                  topicTags,
                  search: searchTerm,
                }}
              />
            )}
          </InfiniteContainer>
        )}
      </ListPage>
    </WithPermission>
  );
};
