import React from 'react';
import { ExternalSource } from 'models/external-source';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import styles from './sidebar.module.css';

export const Sidebar: React.FC<{
  externalSource: ExternalSource;
  archive: (externalSourceId: number) => void;
  unarchive: (externalSourceId: number) => void;
}> = ({ externalSource, archive, unarchive }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <button
          type="button"
          onClick={() => {
            if (externalSource.active) {
              archive(externalSource.id);
            } else {
              unarchive(externalSource.id);
            }
          }}
          className={styles.action}
        >
          <span>
            <SVGIcon name="Folder" />
          </span>
          <span>{externalSource.active ? 'Archive' : 'Unarchive'}</span>
        </button>
      </div>
    </div>
  );
};
