import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { BlocksPicker } from 'shared/Library/BlocksPicker';

export const BlocksLibrary: React.FC<RouteComponentProps> = () => {
  const goBack = React.useCallback(() => {
    navigate('../library');
  }, []);

  return <BlocksPicker maxSelections={1} onChange={goBack} />;
};
