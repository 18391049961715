import camelcaseKeys from 'camelcase-keys';
import { request } from './api-shared';

export type FetchProps = {
  programId: number;
  adminId?: number;
};

export type DraftsCount = { draftsCount: number; ownDraftsCount: number };
export type SubmittedCount = { submittedCount: number };
export type FlaggedCount = {
  flaggedCommentsCount: number;
  flaggedContentCount: number;
};
export type FeedPostsCount = { feedPostsCount: number };

export type DraftsCountData = {
  data: DraftsCount;
};

export type SubmittedCountData = {
  data: SubmittedCount;
};

export type FlaggedCommentsCountData = {
  data: FlaggedCount;
};

export type FeedPostsCountData = {
  data: FeedPostsCount;
};

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export const fetchDraftsCount = async (
  props: FetchProps
): Promise<DraftsCountData> => {
  const { programId, adminId } = props;

  const q = `admin_id=${adminId}`;
  const url = `${apiRoot}/samba/programs/${programId}/contents/metadata/drafts_count?${q}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching drafts count: ${response.status}`);
};

export const fetchSubmittedCount = async (
  props: FetchProps
): Promise<SubmittedCountData> => {
  const { programId } = props;

  const url = `${apiRoot}/samba/programs/${programId}/contents/metadata/submitted_count`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching submitted posts count: ${response.status}`);
};

export const fetchFlaggedCount = async (
  props: FetchProps
): Promise<FlaggedCommentsCountData> => {
  const { programId } = props;

  const url = `${apiRoot}/samba/programs/${programId}/contents/metadata/flagged_count`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching flagged count: ${response.status}`);
};

export const fetchFeedPostsCount = async (
  props: FetchProps
): Promise<FeedPostsCountData> => {
  const { programId } = props;

  const url = `${apiRoot}/samba/programs/${programId}/contents/metadata/feed_posts_count`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching feed posts count: ${response.status}`);
};
