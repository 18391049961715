import * as React from 'react';
import { ImageData } from 'models/image';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import {
  useContentImageUploader,
  useProcessedContentImage,
} from 'hooks/useContentImage';
import { useFlashMessage } from 'contexts/flasher';

type UploaderProps<PayloadType> = {
  onUpload: (data: ImageData) => void;
  onProcessed: (data: ImageData) => void;
  data: PayloadType;
};

export const FileUploader: React.FC<UploaderProps<{
  id: string;
  file: File;
}>> = ({ data, onUpload, onProcessed }) => {
  const { setFlashMessage } = useFlashMessage();
  const uploadErrorHandler = React.useCallback(
    (e, file) => {
      let errorMessage = e;
      if (file && file.name) {
        errorMessage = `${file.name}: ${e}`;
      }
      setFlashMessage({
        severity: 'error',
        message: errorMessage,
      });
    },
    [setFlashMessage]
  );

  const { uploadFile, isUploading, image } = useContentImageUploader({
    onUpload,
    onError: uploadErrorHandler,
  });
  useProcessedContentImage({ image, onProcessed });

  const uploadRef = React.useRef(uploadFile);
  uploadRef.current = uploadFile;
  React.useEffect(() => {
    uploadRef.current(data.file);
  }, [data]);

  return (
    <>
      {isUploading && (
        <div data-test="block-modal-images-collection-uploader-spinner">
          <LoadingSpinner size="small" />
        </div>
      )}
    </>
  );
};

export const UrlUploader: React.FC<UploaderProps<string>> = ({
  data,
  onUpload,
  onProcessed,
}) => {
  const uploader = useContentImageUploader({ onUpload });
  useProcessedContentImage({ image: uploader.image, onProcessed });

  const uploadRef = React.useRef(uploader.uploadUrl);
  uploadRef.current = uploader.uploadUrl;
  React.useEffect(() => {
    uploadRef.current(data);
  }, [data]);

  return (
    <>
      {uploader.isUploading && (
        <div data-test="block-modal-images-collection-uploader-spinner">
          <LoadingSpinner size="small" />
        </div>
      )}
    </>
  );
};
