import FroalaEditor, {
  FroalaOptions,
  ToolbarButtonName,
  ToolbarButtons,
} from 'froala-editor';

/**
 * Injects any additional plugins and toolbar buttons into the Froala editor
 * options without overriding existing options. This is a pain to read because
 * our Toolbar options are overly-complicated so we have to account for nil,
 * an array of strings, or a fairly complicated object.
 */
export function injectPluginsAndToolbarButtons(
  options: Partial<FroalaOptions>,
  incomingPlugins: string[],
  incomingToolbarButtons: ToolbarButtonName[]
): Partial<FroalaOptions> {
  const existingPlugins = options.pluginsEnabled || [];
  const pluginsEnabled = [...new Set([...existingPlugins, ...incomingPlugins])];

  let toolbarButtons: FroalaOptions['toolbarButtons'];
  if (!options.toolbarButtons) {
    toolbarButtons = incomingToolbarButtons;
  } else if (Array.isArray(options.toolbarButtons)) {
    toolbarButtons = [
      ...new Set([...options.toolbarButtons, ...incomingToolbarButtons]),
    ];
  } else {
    // Froala's typing for options.toolbarButtons is object
    // which is as good as unknown, or any. If the above conditions
    // are untrue, this assumes it is a Partial<ToolbarButtons> type.
    const moreMisc = (options.toolbarButtons as Partial<ToolbarButtons>)
      ?.moreMisc || { buttons: [] };
    toolbarButtons = {
      ...options.toolbarButtons,
      moreMisc: {
        ...moreMisc,
        buttons: [...new Set([...moreMisc.buttons, ...incomingToolbarButtons])],
      },
    };
  }

  return {
    ...options,
    pluginsEnabled,
    toolbarButtons,
  };
}

/**
 * There is bug in Froala editor that causes toolbar to hide when user is typing in code view and there is no way to
 * bring it back. I've reported this bug https://github.com/froala/wysiwyg-editor/issues/4738 but it's not fixed yet.
 * This function fixes this bug by listening on keyboard and mouse events and mimicking toolbar show/hide behavior
 * from normal view.
 * @param editor
 */
export function fixCodeViewToolbar(editor: FroalaEditor): void {
  // Timeout that will show toolbar after user stops typing
  let toolbarTimeout: NodeJS.Timeout | null = null;
  // Used to track if toolbar is visible or not. There is no way to check this from Froala API
  let toolbarVisible = false;
  // Will prevent Froala from hiding toolbar if set to false
  let allowHidingToolbar = true;

  function onKeyUp() {
    // When user is typing in code view, allow hiding toolbar
    allowHidingToolbar = true;
    if (toolbarVisible) {
      editor.toolbar.hide();
    }

    if (toolbarTimeout) {
      clearTimeout(toolbarTimeout);
    }
    // After 500ms show toolbar again
    toolbarTimeout = setTimeout(() => {
      allowHidingToolbar = false;
      editor.toolbar.show();
    }, 500);
  }

  function onClick() {
    // When user clicks in code view, don't hide toolbar and show it if it's hidden
    allowHidingToolbar = false;
    if (!toolbarVisible) {
      editor.toolbar.show();
    }
  }

  function onBlur() {
    if (toolbarTimeout) {
      clearTimeout(toolbarTimeout);
    }
    allowHidingToolbar = true;
    editor.toolbar.hide();
  }

  editor.events.on('commands.after', (cmd) => {
    if (cmd !== 'html') {
      return;
    }
    // This is text area that is used for code editing
    const $codeInput = editor.$oel.find('textarea.fr-code');
    // If code view is active, we need to listen to keyup and mouseup events
    if (editor.codeView.isActive()) {
      $codeInput.on('keyup', onKeyUp);
      $codeInput.on('click', onClick);
      $codeInput.on('focusout', onBlur);
      allowHidingToolbar = false;
    } else {
      $codeInput.off('keyup', onKeyUp);
      $codeInput.off('click', onClick);
      $codeInput.off('focusout', onBlur);
      allowHidingToolbar = true;
      if (toolbarTimeout) {
        clearTimeout(toolbarTimeout);
      }
    }
  });
  editor.events.on('toolbar.show', () => {
    toolbarVisible = true;
  });
  editor.events.on('toolbar.hide', () => {
    toolbarVisible = false;
    return allowHidingToolbar;
  });
}
