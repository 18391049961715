import React from 'react';
import { IColumn, UserActivityStreamCard } from '../UserActivityStreamCard';
import { formatDate } from '../../../../../../../shared/Charts/Util';

export const OmniUserActivityCard: React.FC<{
  campaignId: number;
}> = ({ campaignId }) => {
  const columns: IColumn[] = [
    { key: 'user_name', header: 'Name', width: '15%' },
    { key: 'email', header: 'Email', width: '15%' },
    { key: 'channel', header: 'Channel', width: '10%' },
    {
      key: 'last_previewed',
      header: 'Last Previewed',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_opened',
      header: 'Last Opened',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_engaged',
      header: 'Last Engaged',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_activity',
      header: 'Last Activity',
      width: '15%',
      formatCell: formatDate,
    },
  ];

  return (
    <UserActivityStreamCard
      campaignId={campaignId}
      columns={columns}
      hasSearch
    />
  );
};
