import React from 'react';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { ExclamationTriangleOutlined } from 'shared/icons';
import styles from '../video.module.css';

const VALIDATION_ERROR_MESSAGE =
  'There is a formatting error that must be corrected before you can save your edits';

export const CaptionValidationError: React.FC<{
  validationErrors: ParseError[] | undefined;
}> = ({ validationErrors }) => {
  if (!validationErrors || validationErrors.length === 0) return null;
  return (
    <Box margin={[0, 0, 16]}>
      <Alert
        type={AlertType.error}
        message={VALIDATION_ERROR_MESSAGE}
        bgColor="red"
        icon={
          <div className={styles.alertIconWrapper}>
            <ExclamationTriangleOutlined />
          </div>
        }
        enableIcon
        compact
      />
    </Box>
  );
};
