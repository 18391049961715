import React from 'react';
import { RouteComponentProps, redirectTo } from '@reach/router';
import {
  useAuthenticationCallback,
  restartAuthAllowed,
} from 'hooks/authentication';
import { LoadingScreen } from 'shared/LoadingScreen';
import { AuthenticationError } from './AuthenticationError';

export const Callback: React.FC<RouteComponentProps> = () => {
  const { error, isLoading } = useAuthenticationCallback();

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (restartAuthAllowed(error)) {
    // Error is recoverable by restarting the login flow. For example, a user followed a link to the
    // IAM login screen that was generated in a different browser session.
    redirectTo('/');
  }
  if (error) {
    return <AuthenticationError error={error} />;
  }
  return <LoadingScreen />;
};
