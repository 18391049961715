import { Button } from 'DesignSystem/Form';
import { FlexItem, Flex } from 'DesignSystem/Layout/Flex';
import React from 'react';
import { TextInput } from 'shared/TextInput';
import { EditFilled } from 'shared/icons';
import { Box } from 'DesignSystem/Components';
import styles from './EditableLabel.module.css';

type EditableLabelProps = {
  label: string;
  onSave: (savedValue: string) => void;
  className?: string;
  maximum?: number;
  observable?: unknown;
  mainHeading?: boolean;
};

export const EditableLabel: React.FC<EditableLabelProps> = ({
  label,
  onSave,
  className,
  maximum,
  observable,
  mainHeading,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editValue, setEditValue] = React.useState(label);

  const startEditMode = () => {
    setIsEditing(!isEditing);
  };

  const saveEdit = () => {
    setIsEditing(false);
    onSave(editValue);
  };

  const cancelEdit = React.useCallback(() => {
    setIsEditing(false);
    setEditValue(label);
  }, [label]);

  React.useEffect(() => cancelEdit(), [cancelEdit, observable]);

  return (
    <Box className={className}>
      {isEditing ? (
        <>
          <TextInput
            autoFocus
            value={editValue}
            maximum={maximum}
            onChange={setEditValue}
          />
          <Button
            onClick={saveEdit}
            label="Save"
            className={styles.inlineButton}
          />
          <Button
            onClick={cancelEdit}
            secondary
            label="Cancel"
            className={styles.inlineButton}
          />
        </>
      ) : (
        <FlexItem start>
          <Flex column onClick={startEditMode} className={styles.clickable}>
            {label}
          </Flex>
          <Button
            onClick={startEditMode}
            layoutOnly
            className={
              mainHeading ? styles.editButtonMainHeading : styles.editButton
            }
            icon={<EditFilled className={styles.editIcon} />}
          />
        </FlexItem>
      )}
    </Box>
  );
};
