import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { useJourneyState } from 'contexts/journeys/journey';
import { UNNAMED_JOURNEY } from 'models/journeys/journey';
import { EditableTitle } from 'shared/NavigationBars/utils';
import JourneyStatusTagMenu from '../JourneyStatusTagMenu';
import styles from './journey-canvas-header.module.css';

export const TitleBar: React.FC<{
  canEditTitle: boolean;
}> = ({ canEditTitle }) => {
  const { journey, updateName, journeyMode, updateJourney } = useJourneyState();
  const title = journey?.name || '';
  return (
    <Flex className={styles.journeyHeading}>
      <Type.Subheading color={Type.color.gray40}>
        Create Journey
      </Type.Subheading>
      <Box color={Type.color.gray40} padding={[0, 8, 0, 12]}>
        /
      </Box>
      {canEditTitle ? (
        <EditableTitle
          title={title ?? ''}
          updateTitle={updateName}
          maxLength={100}
          defaultTitle={UNNAMED_JOURNEY}
        />
      ) : (
        <PlainTitle title={title ?? ''} visible={!!title} />
      )}
      {journey && (
        <JourneyStatusTagMenu
          journey={journey}
          mode={journeyMode}
          updateJourney={updateJourney}
        />
      )}
    </Flex>
  );
};

const PlainTitle: React.FC<{ visible: boolean; title: string }> = ({
  visible,
  title,
}) =>
  !visible ? null : (
    <Type.Subheading bold color={Type.color.gray90}>
      {title}
    </Type.Subheading>
  );
