import { useEffect, useState } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import { useProgram } from '../../contexts/program';
import {
  fetchUserToken,
  FetchUserTokenResult,
} from '../../services/api-box-mangement';

type UseBoxTokenProps = {
  resourceType: 'file' | 'folder';
  resourceId: string;
};

type UseBoxTokenResult = {
  token: string;
  refetch: () => Promise<QueryObserverResult<FetchUserTokenResult>>;
  isLoading: boolean;
};

export function useBoxToken({
  resourceType,
  resourceId,
}: UseBoxTokenProps): UseBoxTokenResult {
  const { id: programId } = useProgram();

  const { refetch, isLoading, data } = useQuery(
    ['box_token', { programId, resourceType, resourceId }],
    () => fetchUserToken(programId),
    {
      staleTime: Infinity, // we manage the refetch ourselves
    }
  );
  const [refreshTimeout, setRefreshTimeout] = useState<number>();

  useEffect(() => {
    if (!data?.tokenExpiresAt) return;

    const expiresIn = new Date(data.tokenExpiresAt).getTime() - Date.now();

    setRefreshTimeout((timeout) => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
      return setTimeout(refetch, expiresIn);
    });
  }, [data?.tokenExpiresAt, refetch]);

  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [refreshTimeout]);

  return { token: data?.token || '', isLoading, refetch };
}
