import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { DateTime } from 'luxon';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import {
  PersonalizedFieldsFile,
  IndefiniteDate,
} from 'models/personalized-fields';
import { Calendar } from 'shared/DateInput/Calendar';
import { ClickDropdown } from 'shared/ClickDropdown';
import { MAIcon } from 'shared/MAIcon';
import { UpdateModalMode } from './FileSettings';
import { Tag } from './Tag';
import styles from '../styles.module.css';

const DateDropdown: React.FC<{
  date: IndefiniteDate;
  onChange: (date: IndefiniteDate) => void;
}> = ({ date: indefDate, onChange }) => {
  const { date, indefinite } = indefDate;
  const tomorrow = DateTime.now().plus({ day: 1 });

  const [isOpen, setIsOpen] = React.useState(false);

  const onUpdate = (changes?: DateTime | 'indefinite') => {
    if (changes) {
      onChange(
        changes === 'indefinite' ? { indefinite: true } : { date: changes }
      );
      setIsOpen(false);
    }
  };

  return (
    <ClickDropdown
      upward
      onOpen={() => setIsOpen(true)}
      isOpen={isOpen}
      dropdownRenderProp={
        <div>
          <Calendar
            onChange={onUpdate}
            selectedDate={indefinite ? tomorrow : (date as DateTime)}
            minDate={tomorrow}
            disableAutoSelectOnCalendarChange
          >
            <Flex end>
              <Button
                compact
                onClick={() => onUpdate('indefinite')}
                label="Indefinitely"
              />
            </Flex>
          </Calendar>
        </div>
      }
    >
      <Button
        block
        input
        alignContent="left"
        icon={<MAIcon name="calendar_month" style={{ fontSize: 16 }} />}
        label={indefinite ? 'Indefinitely' : date?.toFormat('yyyy-MM-dd')}
      />
    </ClickDropdown>
  );
};

export const ConfirmFile: React.FC<{
  mode: UpdateModalMode;
  file: PersonalizedFieldsFile;
  onChange: (date: IndefiniteDate) => void;
}> = ({ mode, file: { totalRows, expires, attributes }, onChange }) => {
  const [date, setDate] = React.useState<IndefiniteDate>(
    expires || { indefinite: true }
  );
  const onDateChange = (value: IndefiniteDate) => {
    setDate(value);
    onChange(value);
  };

  return (
    <Box className={styles.ConfirmForm} padding="8px 16px 16px 16px">
      {mode === 'confirm' && (
        <Box>
          <table className={styles.FileInfoTable}>
            <thead>
              <tr>
                <th>Columns</th>
                <th>Rows</th>
                <th>Status</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              <td>{attributes.length}</td>
              <td>{totalRows}</td>
              <td>
                <Tag state="complete" message="Validated" />
              </td>
              <td>Dataset is ready to be uploaded</td>
            </tbody>
          </table>
        </Box>
      )}
      <Box>
        <Text.Body block>Retain file until:</Text.Body>
      </Box>
      <DateDropdown date={date} onChange={onDateChange} />
      {!date.indefinite && (
        <Text.ItalicCaption>
          Note: After the retention date, data will be automatically removed
          from our system. If the campaign is still visible in the feed, users
          will see any default variables set for those fields; otherwise, the
          fields will appear blank.
        </Text.ItalicCaption>
      )}
    </Box>
  );
};
