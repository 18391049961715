import * as React from 'react';
import { useCanvasStylePanel } from 'contexts/publisher/compose/canvas-style-panel';
import { useCanvasBlockPanel } from 'contexts/publisher/compose/canvas-block-panel';
import { useCanvasFieldsPanel } from 'contexts/publisher/compose/canvas-fields-panel';
import { useCanvasGlobalPanel } from 'contexts/publisher/compose/canvas-global-panel';
import { useDocumentClick } from 'hooks/useDocumentEvent';
import { Layer } from 'shared/Overlay/Layer';
import { FixedPanel } from 'DesignSystem/Components';

type CanvasPanelPropsType = {
  visible: boolean;
  disable: () => void;
  side: 'left' | 'right';
  hasCloseButton?: boolean;
};

const CanvasPanel: React.FC<CanvasPanelPropsType> = ({
  hasCloseButton,
  disable,
  children,
  visible,
  side,
}) => {
  const [opened, setOpened] = React.useState(false);

  useDocumentClick(
    React.useCallback(() => {
      if (!hasCloseButton) {
        if (opened) {
          disable();
          setOpened(false);
        }
      }
    }, [hasCloseButton, opened, disable, setOpened])
  );
  const closePanel = React.useCallback(() => {
    disable();
    setOpened(false);
  }, [disable, setOpened]);

  if (!visible) return null;
  return (
    <Layer name="global-styles-sidebar">
      <FixedPanel close={hasCloseButton ? closePanel : undefined} side={side}>
        {children}
      </FixedPanel>
    </Layer>
  );
};

export const CanvasStylesPanel: React.FC<{
  side?: 'left' | 'right';
  hasCloseButton?: boolean;
}> = ({ children, side = 'left', hasCloseButton }) => {
  const { visible, disable } = useCanvasStylePanel();

  useDocumentClick(
    React.useCallback(() => {
      if (visible) {
        disable();
      }
    }, [visible, disable])
  );

  return (
    <CanvasPanel
      visible={visible}
      disable={disable}
      side={side}
      hasCloseButton={hasCloseButton}
    >
      {children}
    </CanvasPanel>
  );
};

export const CanvasGlobalPanel: React.FC<{
  side?: 'left' | 'right';
  hasCloseButton?: boolean;
}> = ({ children, side = 'left', hasCloseButton }) => {
  const { visible, disable } = useCanvasGlobalPanel();
  return (
    <CanvasPanel
      visible={visible}
      disable={disable}
      side={side}
      hasCloseButton={hasCloseButton}
    >
      {children}
    </CanvasPanel>
  );
};

export const CanvasBlockPanel: React.FC = ({ children }) => {
  const { visible, disable } = useCanvasBlockPanel();
  if (!visible) return null;
  return (
    <Layer name="global-block-sidebar">
      <FixedPanel close={disable} side="left">
        {children}
      </FixedPanel>
    </Layer>
  );
};

export const CanvasFieldsPanel: React.FC = ({ children }) => {
  const { visible, disable } = useCanvasFieldsPanel();
  if (!visible) return null;
  return (
    <Layer name="global-fields-sidebar">
      <FixedPanel close={disable} side="left">
        {children}
      </FixedPanel>
    </Layer>
  );
};
