import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useBulkUpdateJobsInfiniteQuery } from 'hooks/bulk-update-permissions';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { WithPermission } from 'shared/WithPermission';
import { FiltersBar } from './FiltersBar';
import { BulkUpdateBanner } from './BulkUpdateBanner';

export const BulkUpdatesListPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();

  const parentRef = React.useRef<HTMLDivElement>(null);

  const [selectedStatus, setSelectedStatus] = React.useState<string[]>();

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
  } = useBulkUpdateJobsInfiniteQuery({
    programId,
    states: useDebounce(selectedStatus),
  });

  const itemCount = data.length || 0;

  return (
    <WithPermission permissions={['configureRoleAccess']}>
      <ListPage
        title="Bulk Updates"
        breadcrumbs={[
          { to: '..', label: 'Configure' },
          { label: 'Bulk Updates' },
        ]}
        filterbar={
          <FiltersBar
            selectedStatus={selectedStatus}
            onStatusChange={setSelectedStatus}
          />
        }
      >
        {errorMessage && <>{errorMessage}</>}
        {data && !errorMessage && (
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <InfiniteBannerList
                itemCount={itemCount}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                height={height}
                parentRef={parentRef}
                noItemsComponent={
                  <div>No Bulk Updates match that criteria.</div>
                }
              >
                {(index: number) => <BulkUpdateBanner data={data[index]} />}
              </InfiniteBannerList>
            )}
          </InfiniteContainer>
        )}
      </ListPage>
    </WithPermission>
  );
};
