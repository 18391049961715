import React from 'react';
import { SimpleBuilder } from 'App/Program/Editors/AudienceBuilder/ExpressionBuilder/SimpleBuilder';
import { ExpressionCount } from 'App/Program/Editors/AudienceBuilder/ExpressionBuilder/ExpressionCount';
import { ExpressionOperator } from 'App/Program/Editors/AudienceBuilder/ExpressionBuilder/ExpressionOperator';
import {
  ComplexExpression,
  updateComplexExpression,
  emptyExpression,
  defaultComplexExpression,
  ComplexExpressionTerm,
  SimpleExpression,
} from 'models/expression';
import { Button } from 'DesignSystem/Form';
import { Plus } from 'shared/icons';

import { isDateCriterion } from 'App/Program/Editors/AudienceBuilder/CriterionSelect';
import { DateTime } from 'luxon';
import { MenuPlacement } from 'react-select';
import styles from './AudienceSelect.module.css';

interface AudienceSelectProps {
  value: ComplexExpression;
  onChange: (expression: ComplexExpression) => void;
  showCount?: boolean;
  hasError?: boolean;
  menuPlacement?: MenuPlacement;
  canEdit?: boolean;
}

export const editableTextToQuery = (text: string): string => {
  if (text === '') return 'status:not_blocked';
  return `(${text}) AND status:not_blocked`;
};

export const AudienceSelect: React.FC<AudienceSelectProps> = ({
  value,
  onChange,
  hasError = false,
  showCount = false,
  menuPlacement = 'auto',
  canEdit = true,
}) => {
  const handleChange = (expression: ComplexExpression) => {
    expression.terms.forEach((term: ComplexExpressionTerm) => {
      if (term.expression.type === 'complex') {
        const { terms } = term.expression;
        terms.forEach((t: ComplexExpressionTerm) => {
          const exp = t.expression as SimpleExpression;

          if (exp.criterion && isDateCriterion(exp.criterion)) {
            // selecting a date criterion in the audience builder defaults to an empty array but displays the current date
            if (exp.values.length === 0) {
              const newDate = DateTime.now().toISODate();
              exp.values = [newDate];
            }
          }
        });
      }
    });
    onChange(expression);
  };
  const {
    addExpression,
    removeExpression,
    setExpression,
    setOperator,
  } = updateComplexExpression(value, handleChange);

  const isSingleExpression = value.terms.length === 1;

  return (
    <div>
      {showCount ? (
        <div className={styles.count}>
          <ExpressionCount
            isCompact
            expression={value}
            editableTextToQuery={editableTextToQuery}
            memberLabel="Member"
          />
        </div>
      ) : null}

      {!isSingleExpression ? (
        <div className={styles.operatorSwitch}>
          <ExpressionOperator onChange={setOperator} value={value.operator} />
        </div>
      ) : null}

      <div>
        {value.terms.map((grouping, index) =>
          grouping.expression.type === 'complex' ? (
            <GroupBuilder
              hasError={hasError}
              key={grouping.key}
              isAlone={isSingleExpression}
              value={grouping.expression}
              onChange={(expression) => setExpression(index, expression)}
              menuPlacement={menuPlacement}
              canEdit={canEdit}
              onDelete={() => {
                removeExpression(index);
              }}
            />
          ) : null
        )}
      </div>
      <Button
        onClick={() => addExpression(defaultComplexExpression())}
        icon={<Plus />}
        label="Add Group"
        layoutOnly
        className={styles.addGroupButton}
        disabled={!canEdit}
      />
    </div>
  );
};

const GroupBuilder: React.FC<
  AudienceSelectProps & {
    onDelete: () => void;
    isAlone: boolean;
    menuPlacement?: MenuPlacement;
  }
> = ({
  value,
  onChange,
  onDelete,
  isAlone,
  hasError,
  menuPlacement = 'auto',
  canEdit = true,
}) => {
  const {
    addExpression,
    removeExpression,
    setExpression,
    setOperator,
  } = updateComplexExpression(value, onChange);

  const isSingleExpression = value.terms.length === 1;
  const isAloneWithSingleExpression = isAlone && isSingleExpression;

  return (
    <div className={styles.groupBuilder}>
      {!isSingleExpression && (
        <div className={styles.operatorSwitch}>
          <ExpressionOperator onChange={setOperator} value={value.operator} />
        </div>
      )}
      {value.terms.map((simpleTerm, index) => {
        switch (simpleTerm.expression.type) {
          case 'simple': {
            const expressionData = simpleTerm.expression as SimpleExpression;
            const allowLastItemClear = !!expressionData?.criterion;
            return (
              <SimpleBuilder
                hasError={hasError}
                key={simpleTerm.key}
                isAlone={isAloneWithSingleExpression}
                canEdit={canEdit}
                applyDisabledStyles
                value={simpleTerm.expression}
                menuPlacement={menuPlacement}
                onChange={(expression) => setExpression(index, expression)}
                onDelete={() => {
                  if (isAloneWithSingleExpression) {
                    setExpression(index, emptyExpression());
                    return;
                  }

                  removeExpression(index);

                  // If this is the last expression in the group, delete the group
                  if (isSingleExpression) {
                    onDelete();
                  }
                }}
                editableTextToQuery={editableTextToQuery}
                clearLastItem={allowLastItemClear}
              />
            );
          }
          default:
            return null;
        }
      })}
      <Button
        onClick={() => addExpression(emptyExpression())}
        icon={<Plus />}
        label="Add Criteria"
        layoutOnly
        className={styles.addCriteriaButton}
        disabled={!canEdit}
      />
    </div>
  );
};
