import { FormModal } from 'DesignSystem/Components/Modal';
import { Button } from 'DesignSystem/Form';
import { Text, Caption } from 'DesignSystem/Typography';
import { ImageData } from 'models/image';
import React from 'react';
import { FileDropZone } from 'shared/FileDropZone';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MAIcon } from 'shared/MAIcon';
import styles from './image-uploader-modal.module.css';

type ImageUploaderModalProps = {
  errors?: string[];
  onCancel: () => void;
  handleFileSelect: (file: File) => void;
  handleFileRemove: () => void;
  uploading: boolean;
  submitButton: JSX.Element;
  image: ImageData | null;
  children: React.ReactNode;
};

export function ImageUploaderModal({
  errors,
  onCancel,
  handleFileSelect,
  handleFileRemove,
  uploading,
  submitButton,
  image,
  children,
}: ImageUploaderModalProps): JSX.Element {
  return (
    <FormModal
      actionText="Insert"
      entityText="Image"
      width="680px"
      contentPadding="32px 24px"
      onCancel={onCancel}
      submitButton={submitButton}
    >
      {uploading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingSpinner size="normal" />
        </div>
      )}
      {!uploading && errors && errors.length > 0 && (
        <div className={styles.errors}>
          <Caption>Errors occurred while uploading:</Caption>
          <ul>
            {errors.map((error) => (
              <li key={error}>
                <Caption>{error}</Caption>
              </li>
            ))}
          </ul>
        </div>
      )}
      {image && !uploading && (
        <FileList>
          <FilePreview file={image} handleFileRemove={handleFileRemove} />
        </FileList>
      )}
      {!image && !uploading && (
        <FileDropZone
          dropLabel="Drop to upload file"
          onFileSelect={handleFileSelect}
          withButton={false}
          accept="image/*"
        >
          {children}
        </FileDropZone>
      )}
    </FormModal>
  );
}

ImageUploaderModal.defaultProps = {
  errors: [],
};

function FileList({ children }: { children: React.ReactNode }): JSX.Element {
  return <ul className={styles.filePreview}>{children}</ul>;
}

function FilePreview({
  file,
  handleFileRemove,
}: {
  file: ImageData;
  handleFileRemove: () => void;
}) {
  const fileExtension = file.url.split('.').pop();
  return (
    <li className={styles.filePreviewListItem}>
      <img src={file.url} alt={file.altText} />
      <div className={styles.filePreviewNameContainer}>
        <Text className={{ Body: true, Semibold: true }}>
          {`${file.originalFilename}.${fileExtension}`}
        </Text>
      </div>
      <Button
        borderless
        secondary
        minimal
        onClick={handleFileRemove}
        icon={<MAIcon name="close" />}
      />
    </li>
  );
}
