// This file exists because all of Kai's API code is designed to fetch outbound authentication info
// from a globally accessible location when making API calls. A better model would be to just call
// useActor() everywhere the authentication info is needed. But until we change all that code, we
// need a place to store the actor globally so it can be retrieved without calling a hook.
import { Actor } from '@socialchorus/iam-react';

let storedActor: Actor | undefined;

export function getActor(): Actor | undefined {
  return storedActor;
}

export function setActor(actor?: Actor): void {
  storedActor = actor;
}
