import React from 'react';
import Select, { components, StylesConfig, Theme } from 'react-select';
import { Icon } from 'shared/Icon';

export const selectStyles: StylesConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: (provided: React.CSSProperties, state: any) => ({
    ...provided,
    backgroundColor: 'var(--color-gray00)',
    borderColor: 'var(--color-gray10)',
    borderRadius: '6px',
    fontSize: '14px',
    cursor: 'pointer',
    border:
      state.isFocused && '1px solid var(--color-brandTintDark) !important',
    outline: state.isFocused && 'none !important',
    boxShadow: state.isFocused && '0 0 0 4px rgba(88, 55, 108, 0.1)',
  }),
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: 'var(--color-gray00)',
    padding: '5px',
    width: '100%',
    cursor: 'pointer',
  }),
  multiValue: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: 'var(--color-gray05)',
    borderRadius: '60px',
    fontSize: '12px',
    padding: '4px',
    cursor: 'pointer',
  }),
  multiValueRemove: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'var(--color-gray50)',
    cursor: 'pointer',
    '&:hover': { background: 'none', color: 'var(--color-gray50)' },
  }),
  option: (provided: React.CSSProperties) => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'var(--color-gray90)',
  }),
};

export const selectTheme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--color-gray05)',
    primary25: 'var(--color-gray05)',
    neutral0: 'var(--color-gray70)',
  },
});

const DropdownIndicator: React.FC = () => (
  <div style={{ padding: '3px 6px 0 0' }}>
    <Icon iconName="ExpandList" iconType="SVG" />
  </div>
);

const MultiValueRemove: React.FC = (
  props: React.ComponentProps<typeof components.MultiValueRemove>
) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <components.MultiValueRemove {...props}>
    <Icon iconName="Close" iconType="SVG" size={9} />
  </components.MultiValueRemove>
);

const NotShown: React.FC = () => null;

export const DisabledSelect: React.FC<{
  placeholder: string;
  className?: string;
}> = ({ placeholder, className }) => (
  <Select
    components={{
      DropdownIndicator,
      IndicatorSeparator: NotShown,
    }}
    isDisabled
    placeholder={placeholder}
    styles={selectStyles}
    className={className}
  />
);

export const selectComponents = {
  DropdownIndicator,
  IndicatorSeparator: NotShown,
  MultiValueRemove,
};
