import { SVGIconName } from 'shared/Icon/SVGIcon/definitions';

// TODO: May be a way to mimic what we're doing with Channel
export type PriorityName = 'NICE' | 'SHOULD' | 'MUST' | 'CRITICAL';

export type Priority = {
  label: string;
  value: PriorityName;
  description: string;
  examples: string;
  icon: SVGIconName;
};

export const PRIORITY_NAMES: PriorityName[] = [
  'NICE',
  'SHOULD',
  'MUST',
  'CRITICAL',
];

export const isPriorityName = (
  priorityName: PriorityName | unknown
): priorityName is PriorityName => {
  const name = priorityName as PriorityName;
  return !!name && PRIORITY_NAMES.includes(name);
};

export const PRIORITIES_BY_NAME: Record<PriorityName, Priority> = {
  NICE: {
    label: 'Nice to know',
    value: 'NICE',
    description: 'Culture-building posts · Light on tone · Information',
    icon: 'TriangularPercussion',
    examples:
      'Anniversaries · Employee Highlights · Promotions · Volunteer Activities',
  },
  SHOULD: {
    label: 'Should know',
    value: 'SHOULD',
    description: 'FYI posts · Informative but not imperative message',
    icon: 'Violin',
    examples:
      'Upcoming Events · Weekly Company Updates · Company Strategic Initiatives · Newsletters',
  },
  MUST: {
    label: 'Must know',
    value: 'MUST',
    description:
      'Important information posts · Requires employee action or has a direct employee impact',
    icon: 'Drums',
    examples:
      'Benefits Enrollment · Policy/Regulation Changes · Corporate Procedures · Security Training',
  },
  CRITICAL: {
    label: 'Critical',
    value: 'CRITICAL',
    description:
      'Essential information posts · Requires immediate attention or change in behavior',
    icon: 'FrenchHorn',
    examples:
      'Wide-reaching Internet Outage · Security Breach · Unplanned Office Closures · Health & Safety Protocols',
  },
};

export const PRIORITIES = Object.values(PRIORITIES_BY_NAME);

export const priorityLabel = (priorityName?: string): string | undefined => {
  return (
    (isPriorityName(priorityName) && PRIORITIES_BY_NAME[priorityName]?.label) ||
    undefined
  );
};
