import { useProgram } from 'contexts/program';
import { useTopicAboutPageQuery } from 'hooks/topics';
import {
  LandingPageTabType,
  Topic,
  topicErrors,
  TopicErrors,
} from 'models/topic';
import { useMemo } from 'react';

export function useTopicErrors(
  topic: Partial<Topic> | undefined
): TopicErrors | undefined {
  const { id: programId } = useProgram();

  const {
    isLoading: isAboutPageLoading,
    isEmpty: isAboutPageEmpty,
  } = useTopicAboutPageQuery(programId, String(topic?.id));

  const topicObjectErrors = useMemo(() => (topic ? topicErrors(topic) : {}), [
    topic,
  ]);

  const aboutPageEnabled = useMemo(
    () =>
      topic?.landingPageTabs?.some(
        (tab) => tab.tabType === LandingPageTabType.About && !tab.isHidden
      ) ?? false,
    [topic]
  );

  const landingPageTabErrors: TopicErrors['landing_page_tabs'] = useMemo(() => {
    const aboutError =
      !isAboutPageLoading && aboutPageEnabled && isAboutPageEmpty
        ? { about: "Add content or disable 'About' page to save" }
        : undefined;

    const errors = {
      ...aboutError,
    };

    return Object.keys(errors).length === 0 ? undefined : errors;
  }, [aboutPageEnabled, isAboutPageEmpty, isAboutPageLoading]);

  return useMemo(() => {
    const errors = {
      ...topicObjectErrors,
      ...(landingPageTabErrors
        ? { landing_page_tabs: landingPageTabErrors }
        : undefined),
    };
    return Object.keys(errors).length === 0 ? undefined : errors;
  }, [landingPageTabErrors, topicObjectErrors]);
}
