import React, { useContext } from 'react';
import { Button, Container, Icon } from '@socialchorus/shared-ui-components';
import { Spinner } from 'App/Program/Configuration/Shared/Spinner';
import { useLinkNavigation } from 'hooks/use-link-navigation';
import { FieldError } from 'shared/Fieldset/FieldError';
import { missingPreview } from 'App/Program/Configuration/Topics/TopicV2/topicErrorDefinitions';
import { useIsErrorApplicable } from 'App/Program/Configuration/Topics/TopicV2/topicErrorHooks';
import Sidebar from '../../../common/Sidebar';
import { SidebarComponent } from '../type';
import { useTopicFormCtx } from '../../../../context';
import styles from './styles.module.css';
import { AboutDesignContext } from '../../contexts/about';
import { ModalType } from '../../../../context/modals';

type PreviewSidebarProps = {
  previewContent: string;
};

const PreviewCard = ({ previewContent }: PreviewSidebarProps) => {
  const { toggleModal } = useTopicFormCtx();
  const onEditPreviewClick = () => {
    toggleModal({ type: ModalType.EditAboutPreview });
  };

  const isPreviewMissingApplicable = useIsErrorApplicable('Missing Preview');

  return (
    <>
      {previewContent ? (
        <>
          <div className={styles.PreviewCardContainer}>
            <span className={styles.PreviewCardTitle}>About</span>
            <span className={styles.PreviewCardContent}>{previewContent}</span>
          </div>
          <div className={styles.PreviewCardEditButtonContainer}>
            <Button
              variant="text"
              size="compact"
              label="Edit Preview"
              onClick={onEditPreviewClick}
            />
          </div>
        </>
      ) : (
        <div className={styles.EmptyPreview}>
          <Button
            size="compact"
            variant="primary"
            leftIcon={<Icon>add</Icon>}
            label="Preview"
            fullWidth
            onClick={onEditPreviewClick}
          />
          {isPreviewMissingApplicable && (
            <FieldError error="Required" ariaLabel={missingPreview.name} />
          )}
        </div>
      )}
    </>
  );
};

export const AboutSidebar: SidebarComponent = ({ onClose }) => {
  const { baseUrl, state, toggleSidebar } = useTopicFormCtx();
  const { aboutPage } = useContext(AboutDesignContext);
  const getLinkNavigation = useLinkNavigation();

  if (!aboutPage?.pageContent) {
    toggleSidebar(null);
    return null;
  }

  return (
    <Sidebar
      title="Content"
      onClose={onClose}
      onBeforeClose={() => !state.modal.type}
      position="right"
      closeButton
    >
      <div className={styles.AboutSidebar}>
        {!aboutPage ? (
          <Spinner />
        ) : (
          <>
            <Container className={styles.Container} fullWidth>
              <PreviewCard previewContent={aboutPage.previewContent} />
            </Container>
            <Button
              variant="primary"
              leftIcon={<Icon>edit</Icon>}
              label="Edit Content"
              fullWidth
              href={getLinkNavigation(`${baseUrl}/about/${aboutPage.designId}`)}
            />
          </>
        )}
      </div>
    </Sidebar>
  );
};
