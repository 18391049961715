import React from 'react';
import { Menu, MenuButton } from '@szhsin/react-menu';
import './styles.css';
import { NestableMenuType } from 'shared/NestableMenu/types';
import { renderMenu } from 'shared/NestableMenu/components';
/*
 * This is a dropdown menu component that utilizes the `react-menu` library
 * https://szhsin.github.io/react-menu/ (v1.9.0 as of April 7, 2021)
 *
 * The component here is designed to be CONFIGURATION-DRIVEN
 * - This means it will render completely from the data given via `rootMenuData` prop
 *   - ie, you should NOT have to manually render the underlying library components: <Menu>, <MenuItem>, etc....
 *   - Instead, you should only worry about the top level <NestableMenu /> and the data you provide to it
 * - See `NestableMenu.stories.tsx` for complete examples of valid data structures
 * - See `types.ts` for the data structure that is expected for the various menus
 * - `styles.css` provides overrides so that the menu matches the designs for kai
 *
 * Features:
 * - able to render simple lists, radio menus, and checkbox multiselect menus
 * - able to render menu subheaders and dividers
 * - supports deeply nested menus (best practice: use only 1 level of nesting)
 * - separate select handler functions can be provided to each submenu
 * - menus automatically handle boundary detection and placement and scrolling
 * - styling matches Michael's designs for kai
 *   - styles can be overridden by importing `NestableMenu/styles.css`
 * - customize the button used to trigger the dropdown visibility
 *
 * Usage Notes:
 * - each submenu should only be of ONE type... simple/radio/checkbox
 *   - nested submenus can be different types.
 *   - eg, you can have a top-level simple menu and a sub level radio/checkbox menu
 *   - See the stories file "MixedModeSubMenu" for an example
 * - invoke the component with the type of the value object in your menu option
 *   - eg, <NestableMenu<FilterObject> {...props} />
 *   - this type must match the type used in any select handlers passed in the props
 * - each submenu requires exactly ONE select handler function
 *   - the handler function will receive the `value` from the data structure
 *   - ensure that your `value` object is uniquely identifiable
 *   - simple/radio select handlers will return the ONE value selected
 *   - checkbox select handlers return 1) a js map of all values selected and 2) the selected value
 * - each menu item requires a unique `key` that you must generate
 * - be sure to test the `rootMenuData` object that you provide to ensure it renders for your needs
 *   - try to manually construct your data structure first before automating the construction
 *   - when automating data construction, it is highly recommended to explicitly
 *     cast your data to the appropriate type so the typechecker can validate your data
 *     - eg: `const items: NestableMenuItemType[] = some_data.map(...)`
 *
 * Known Issues:
 * 1----
 * The menu components are rendered recursively, as such we utilize function hoisting
 * see component.tsx for details
 *
 * 2----
 * this component is meant to abstract away the underlying library components
 * and all renderings of the menu should only happen through the configuration data
 * ie, do not attempt to render child components within the root `<NestableMenu />`
 *
 * 3----
 * Most of the components in this library are pure components (meaning they don't utilize hooks)
 * However, radiomenu and checkbox menu do utilize hooks to maintain state
 * If you were to change the input data such that a radio/checkbox menu is removed
 * after an initial render, then react might throw a "hooks was unexpectedly removed" error
 * In those cases, try...
 * - avoid conditionally removing radio/checkbox items
 * - rerender the entire menu component with the new data source
 * - in the future, we might want to refactor this to be more resilient
 * */

export type NestableMenuPropsType<TValue> = {
  dropdownLabel?: string;
  menuButton?: JSX.Element;
  keepOpen?: boolean;
  rootMenuData: NestableMenuType<TValue>;
};

function NestableMenu<TValue>({
  dropdownLabel = 'Open',
  keepOpen = false,
  menuButton,
  rootMenuData,
}: NestableMenuPropsType<TValue>): JSX.Element {
  return (
    <Menu
      menuButton={menuButton || <MenuButton>{dropdownLabel}</MenuButton>}
      onClick={(e) => {
        e.keepOpen = keepOpen;
      }}
      offsetY={10}
    >
      {renderMenu<TValue>(rootMenuData)}
    </Menu>
  );
}

export default NestableMenu;
