import { useProgram } from 'contexts/program';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { LitmusRole } from 'models/role';

export const useRolePermissionsCount = (
  role: LitmusRole
): {
  count: number;
} => {
  const { id: programId } = useProgram();
  const { data: showActivitiesUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Activities.UI'
  );
  const { data: showPersonalizedFieldsToggle } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFields'
  );
  const { data: showAttributeManagementToggle } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFieldsV2'
  );
  const { data: showTopicLimitToggle } = useFeatureFlagsQuery(
    programId,
    'EE.UserGeneratedContent.TopicLimitsEnabled'
  );
  const { data: permissions } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'secondary',
  });
  const permissionsNames = permissions
    .filter((x) => {
      if (
        !showActivitiesUI?.value &&
        x.name === 'configure_admin_activity_feed'
      )
        return false;

      if (
        !showPersonalizedFieldsToggle?.value &&
        x.name === 'set_personalized_fields'
      )
        return false;

      if (
        !showAttributeManagementToggle?.value &&
        x.name === 'configure_attribute_management'
      )
        return false;

      if (!showTopicLimitToggle?.value && x.name === 'configure_topic_limit')
        return false;

      return true;
    })
    .filter((x) => !x.hidden)
    .map((x) => x.name);

  const commonElements = role.aliases.filter(
    (e) => permissionsNames.indexOf(e.name) > -1
  );
  return { count: commonElements.length };
};
