import {
  DefinitionBlock,
  FieldData,
  isBlockFieldData,
} from 'models/publisher/block';

export function identityFn<T>(arg: T): T {
  return arg;
}

export function attrExtractionFn<T>(attr: string) {
  return (data: unknown): T =>
    (data as T) && ((data as { [attr: string]: T })[attr] as T);
}

type Config<T> = {
  match: (data: unknown) => boolean;
  extract?: (data: unknown) => T;
};
type Data = Partial<DefinitionBlock<FieldData>> | FieldData;
type Result<T> = Array<T>;

function extractAll<T>(data: Data | string, config: Config<T>): Result<T> {
  if (data == null || data === undefined) return [];

  const extractFn = config.extract || identityFn;
  if (config.match(data)) return [extractFn(data as T)];

  const values: Result<T> = [];
  if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      const keyVal = data[key as keyof typeof data] as typeof data;
      values.push(...extractAll(keyVal, config));
    });
  } else if (Array.isArray(data)) {
    data.forEach((dataEntry) => {
      values.push(...extractAll(dataEntry, config));
    });
  }

  return values;
}

function digData<T>(
  data: Data,
  config: Config<T>
): ReturnType<typeof extractAll> {
  return extractAll(data, config).filter((value) => !!value);
}

export const digBlock: typeof digData = (block, config) => {
  return digData(block, config);
};

export const digFieldData: typeof digData = (block, config) => {
  const { field_data } = block;
  if (!isBlockFieldData(field_data)) return [];

  return digData(field_data, config);
};
