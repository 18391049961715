import React, { MouseEvent, useState } from 'react';
import { OptionType } from 'hooks/common';
import { FAIcon } from 'shared/Icon/FAIcon';
import styles from './AudienceBuilder.module.css';

export const CriteriaMenuItem: React.FC<{
  criteria: {
    label: string;
    options: OptionType[];
  };
  expression: string | undefined;
  setExpression: (e: string) => void;
}> = ({ criteria, expression, setExpression }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClick = (e: MouseEvent<HTMLDivElement>, option: OptionType) => {
    e.stopPropagation();
    const value =
      expression === '' || expression === '*'
        ? `${option.value}:*`
        : `${expression} AND ${option.value}:*`;
    setExpression(value);
  };
  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <div className={styles.criteriaMenuItem} onClick={handleOpen}>
      <div className={styles.criteriaCategory}>
        <FAIcon name={open ? 'chevron-up' : 'chevron-down'} />
        <span className={styles.criteriaCategoryText}>{criteria.label}</span>
      </div>
      {open &&
        criteria.options.map((option) => (
          <div
            className={styles.criteriaLabel}
            onClick={(e) => handleClick(e, option)}
          >
            <div className={styles.criteriaLabelText}>{option.label}</div>
          </div>
        ))}
    </div>
  );
};
