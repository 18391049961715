import React from 'react';
import cx from 'classnames';
import { FAIcon as Icon } from 'shared/Icon/FAIcon';
import styles from './input.module.css';

type Props = {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  maxLength?: number;
};

export const NumericInput: React.FC<Props> = ({
  value,
  onChange,
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER,
  maxLength = Number.MAX_SAFE_INTEGER,
}) => {
  const [number, setNumber] = React.useState(Number(value) || 0);

  React.useEffect(() => {
    onChange(number);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  const handleChange = (val: number) => {
    if (Number.isNaN(val) || val < minValue || val > maxValue) return;
    if (val.toString().length > maxLength) {
      setNumber(Number(val.toString().substring(0, maxLength)));
      return;
    }
    setNumber(val);
  };

  return (
    <div className={styles.input}>
      <button type="button" onClick={() => handleChange(number - 1)}>
        <>
          <Icon name="minus" />
        </>
      </button>
      <input
        type="text"
        min={minValue}
        className={cx(styles.inputElement)}
        onChange={(e) => handleChange(Number(e.target.value))}
        value={number}
      />
      <button type="button" onClick={() => handleChange(number + 1)}>
        <>
          <Icon name="plus" />
        </>
      </button>
    </div>
  );
};
