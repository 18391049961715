import React from 'react';
import * as DesignForm from 'DesignSystem/Form';
import { InclusiveRule } from 'models/inclusive-language';
import { Textarea } from 'shared/forms/Textarea';
import { PillsInput } from 'shared/PillIsnput';
import styles from './form.module.css';

export const Form: React.FC<{
  inclusiveRule: Partial<InclusiveRule>;
  onChange: (data: Partial<InclusiveRule>) => void;
}> = ({ inclusiveRule, onChange }) => (
  <DesignForm.Section>
    <DesignForm.Subsection
      title="Original Text"
      description="When these terms are found, replacements will be suggested."
    >
      <PillsInput
        onSelectedValuesChange={(terms) => onChange({ terms })}
        values={inclusiveRule.terms || []}
        className={styles.textField}
      />
    </DesignForm.Subsection>
    <DesignForm.Subsection title="Suggested Text">
      <PillsInput
        disabled={
          inclusiveRule.suggestions && inclusiveRule.suggestions.length >= 3
        }
        onSelectedValuesChange={(suggestions) => onChange({ suggestions })}
        values={inclusiveRule.suggestions || []}
        className={styles.textField}
      />
    </DesignForm.Subsection>
    <DesignForm.Subsection
      title="Rule Explanation"
      description="Context or instructions for applying the rule."
    >
      <Textarea
        onChange={(explanation) => onChange({ explanation })}
        value={inclusiveRule.explanation}
        className={styles.textField}
      />
    </DesignForm.Subsection>
  </DesignForm.Section>
);
