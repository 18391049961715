import { CampaignVariable } from 'models/campaign-variable';
import { bossanovaDomain, request } from './api-shared';
import { NotFoundError } from './Errors/NotFoundError';

type CampaignVariablesResponse = {
  data: Array<string>;
};

type CampaignVariableConfigurationsResponse = {
  data: {
    configuration: Array<CampaignVariable>;
  };
};

const fetchCampaignVariables = async (
  programId: number
): Promise<CampaignVariablesResponse['data']> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/campaign_variables`;
  const response = await request(url);
  if (response.status === 200) {
    const campaignVariablesResponse: CampaignVariablesResponse = await response.json();
    return campaignVariablesResponse.data;
  }
  throw new Error(`Error fetching bulk upload: ${response.status}`);
};

const fetchCampaignVariableConfigurations = async (
  programId: number
): Promise<CampaignVariableConfigurationsResponse['data']['configuration']> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/campaign_variable_configuration`;
  try {
    const response = await request(url);
    if (response.status === 200) {
      const responseData: CampaignVariableConfigurationsResponse = await response.json();
      return responseData.data.configuration;
    }
    if (response.status === 404) {
      return fetchCampaignVariableConfigurationIfNotFound(programId);
    }
    throw new Error(`Error fetching bulk upload: ${response.status}`);
  } catch (e) {
    if (e instanceof NotFoundError) {
      return fetchCampaignVariableConfigurationIfNotFound(programId);
    }
    throw e;
  }
};

const fetchCampaignVariableConfigurationIfNotFound = async (
  programId: number
) => {
  // Fetch the list of campaign variables
  const campaignVariables = await fetchCampaignVariables(programId);

  const cleanedVariables = campaignVariables.map((name) => ({
    name,
    active:
      name === 'first_name' ||
      name === 'last_name' ||
      name === 'universal_identifier',
  }));

  // Calling updateCampaignVariableConfigurations when its 404/NotFoundError so as to create record.
  await updateCampaignVariableConfigurations(programId, cleanedVariables);

  return cleanedVariables;
};

/**
 * grabs both campaign variables and configurations and merges them
 * together.  any variable with a config is constructed using defaults
 * and configuration without a variable is discarded. assumed to be removed/deleted from source
 * @param programId
 * @returns
 */
export const fetchAllCampaignVariableConfigurations = async (
  programId: number
): Promise<Array<CampaignVariable>> => {
  const ret: CampaignVariable[] = [];

  // Fetch the list of campaign variables
  // Fetch the configurations for variables that are set
  const [
    campaignVariables,
    campaignVariableConfigurations,
  ] = await Promise.all([
    fetchCampaignVariables(programId),
    fetchCampaignVariableConfigurations(programId),
  ]);
  campaignVariables.forEach((variableName) => {
    const config = campaignVariableConfigurations.find(
      (c: CampaignVariable) => c.name === variableName
    );
    if (config) {
      ret.push(config);
    } else {
      ret.push({
        name: variableName,
        active: false,
      });
    }
  });

  return ret;
};

export const updateCampaignVariableConfigurations = async (
  programId: number,
  variables: CampaignVariable[]
): Promise<void> => {
  const cleanedVariables = variables.map((v) => {
    return {
      name: v.name,
      active: v.active,
      fallback_value: v.fallback_value,
    };
  });
  const url = `${bossanovaDomain}/v2/programs/${programId}/campaign_variable_configuration`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify({ config: cleanedVariables }),
  });
  if (response.status !== 200) {
    throw new Error(
      `Error updating campaign variable configurations: ${response.status}`
    );
  }
};
