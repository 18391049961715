import React from 'react';
import { Subsection } from 'DesignSystem/Form';
import { TextInput } from 'shared/TextInput';
import { AliasData } from 'services/api-author-alias';
import styles from '../author-alias.module.css';

function upcase(word: string | undefined) {
  return word ? word[0].toUpperCase() + word.slice(1) : '';
}

export const DescriptionSection: React.FC<{
  aliasData: Partial<AliasData>;
  onChange: (description: string) => void;
  onBlur?: (value: string) => void;
  errorMessage?: string;
  hasError?: boolean;
}> = ({
  aliasData: { aliasType, description = '' },
  onChange,
  onBlur,
  errorMessage,
  hasError,
}) => (
  <Subsection
    title="Description"
    description={`(Optional) Description/nickname for the ${upcase(
      aliasType
    )} Alias that will only appear in Studio`}
    divider={false}
  >
    <TextInput
      className={styles.textField}
      value={description}
      onChange={(value) => value.length <= 140 && onChange(value)}
      placeholder="Enter a description"
      onBlur={onBlur}
      errorMessage={errorMessage}
      hasError={hasError}
    />
    <div className={styles.DescriptionCount}>
      {description?.length || 0}/140 characters
    </div>
  </Subsection>
);
