import * as React from 'react';
import { Audience, audienceQuery } from 'models/audience';
import { Expression } from 'models/expression';
import { ExpressionBuilder } from 'App/Program/Editors/AudienceBuilder/ExpressionBuilder';
import { PreviewUsers } from 'App/Program/Editors/AudienceBuilder/PreviewUsers';
import { TopControls } from './TopControls';
import styles from './builder.module.css';

type PropsType = {
  audience: Audience;
  onAudienceChange: (newAudience: Audience) => void;
  isPreviewMode?: boolean;
  setPreviewMode: (value: boolean) => void;
  isSaveAsMode?: boolean;
  setSaveAsMode: (value: boolean) => void;
  newDateOperatorsEnabled: boolean;
};

export const Editor: React.FC<PropsType> = (props) => {
  const {
    audience,
    onAudienceChange,
    isPreviewMode,
    setPreviewMode,
    isSaveAsMode,
    setSaveAsMode,
    newDateOperatorsEnabled,
  } = props;

  const { getExpression, setExpression, editableTextToQuery } = audienceQuery(
    audience,
    onAudienceChange,
    newDateOperatorsEnabled
  );

  const [savedExpression, setSavedExpression] = React.useState(getExpression());
  const updateExpression = (e: Expression) => {
    setExpression(e);
    setSavedExpression(e);
  };

  return (
    <div className={styles.editorPane}>
      <TopControls
        audience={audience}
        onAudienceChange={onAudienceChange}
        isPreviewMode={isPreviewMode}
        setPreviewMode={setPreviewMode}
        isSaveAsMode={isSaveAsMode}
        setSaveAsMode={setSaveAsMode}
      />
      {isPreviewMode ? (
        <PreviewUsers query={audience.query || ''} />
      ) : (
        <div className={styles.editor}>
          <ExpressionBuilder
            depth={0}
            onChange={updateExpression}
            value={savedExpression}
            isAlone
            onDelete={() => {}}
            editableTextToQuery={editableTextToQuery}
            audienceName={audience.title}
          />
        </div>
      )}
    </div>
  );
};
