import { Container, Icon } from '@socialchorus/shared-ui-components';
import cx from 'classnames';
import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import styles from './styles.module.css';

type HighlightableContainerProps = Omit<
  ComponentProps<typeof Container>,
  'children'
> & {
  hoverLabel: string;
  children: ReactNode;
  active?: boolean;
  editIcon?: boolean;
};

export function HighlightableContainer({
  className,
  hoverLabel,
  children,
  active,
  editIcon,
  ...containerProps
}: HighlightableContainerProps): ReactElement {
  return (
    <Container
      className={cx(
        styles.HighlightableContainer,
        active && styles.Active,
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(containerProps as ComponentProps<typeof Container>)}
    >
      <span className={styles.HoverLabel}>{hoverLabel}</span>
      {children}
      {editIcon && (
        <div className={styles.EditIcon}>
          <Icon size={24}>edit</Icon>
        </div>
      )}
    </Container>
  );
}

HighlightableContainer.defaultProps = {
  editIcon: false,
};
