import React from 'react';
import { DecisionEdge } from 'models/journeys/journey';
import { ComplexExpression, defaultComplexExpression } from 'models/expression';
import { Button } from 'DesignSystem/Form';
import { ChevronDown, ChevronUp, Trash } from 'shared/icons';
import { TextInput } from 'shared/TextInput';
import { Flex } from 'DesignSystem/Layout/Flex';
import { JOURNEY_ACTION_DISABLED_MESSAGE } from 'contexts/journeys/journey';
import styles from './decision-config.module.css';
import { AudienceSelect } from '../shared/AudienceSelect';
import { EditableLabel } from '../shared/EditableLabel';

interface DecisionConditionProps {
  condition: DecisionEdge;
  isLast?: boolean;
  isFirst?: boolean;
  topologyEditable: boolean;
  canEdit?: boolean;
  onChange?: (condition: DecisionEdge) => void;
  onRemove?: (condition: DecisionEdge) => void;
  onReorder?: (condition: DecisionEdge, order: number) => void;
}

export const DecisionCondition: React.FC<DecisionConditionProps> = ({
  condition,
  isLast,
  isFirst,
  topologyEditable,
  canEdit = true,
  onChange,
  onRemove,
  onReorder,
}) => {
  const setConditionIndex = (order: number) => onReorder?.(condition, order);
  const removeCondition = () => onRemove?.(condition);
  const handleOrderChange = (s: string) =>
    setConditionIndex(parseInt(s, 10) - 1);
  const moveUp = () => setConditionIndex(condition.order - 1);
  const moveDown = () => setConditionIndex(condition.order + 1);

  const expression =
    condition.criterion ?? defaultComplexExpression(defaultComplexExpression());

  const handleCriterionChange = (updatedExpression: ComplexExpression) => {
    onChange?.({ ...condition, criterion: updatedExpression });
  };

  const getLabel = (label: string | undefined) =>
    label || `Split Description ${condition.order + 1}`;

  if (condition.isDefault) {
    return <DecisionDefaultCondition condition={condition} />;
  }

  return (
    <div
      key={condition.targetId}
      className={styles.condition}
      data-target-id={condition.targetId}
    >
      <Flex start>
        <div className={styles.conditionLeft}>
          {canEdit ? (
            <EditableLabel
              label={getLabel(condition.label)}
              maximum={50}
              onSave={(savedLabel: string) => {
                const newLabel = getLabel(savedLabel);
                onChange?.({
                  ...condition,
                  label: newLabel,
                });
              }}
              className={styles.conditionDescription}
            />
          ) : (
            <div className={styles.conditionDescription}>
              <span>{getLabel(condition.label)}</span>
            </div>
          )}
          <AudienceSelect
            value={expression}
            onChange={handleCriterionChange}
            menuPlacement="bottom"
            canEdit={canEdit}
          />
        </div>
        {canEdit ? (
          <Flex start>
            <TextInput
              value={(condition.order + 1).toString()}
              className={styles.indexInput}
              onChange={handleOrderChange}
              disabled={!topologyEditable}
              title={
                topologyEditable
                  ? 'Change order'
                  : JOURNEY_ACTION_DISABLED_MESSAGE
              }
            />
            <div className={styles.orderButtons}>
              {!isFirst ? (
                <Button
                  className={styles.orderButton}
                  icon={<ChevronUp />}
                  onClick={moveUp}
                  layoutOnly
                  disabled={!topologyEditable}
                  title={
                    topologyEditable
                      ? 'Move up'
                      : JOURNEY_ACTION_DISABLED_MESSAGE
                  }
                />
              ) : null}
              {!isLast ? (
                <Button
                  className={styles.orderButton}
                  icon={<ChevronDown />}
                  onClick={moveDown}
                  layoutOnly
                  disabled={!topologyEditable}
                  title={
                    topologyEditable
                      ? 'Move down'
                      : JOURNEY_ACTION_DISABLED_MESSAGE
                  }
                />
              ) : null}
            </div>
            {topologyEditable ? (
              <Button icon={<Trash />} onClick={removeCondition} layoutOnly />
            ) : null}
          </Flex>
        ) : null}
      </Flex>
    </div>
  );
};

const DecisionDefaultCondition: React.FC<{ condition: DecisionEdge }> = ({
  condition,
}) => {
  return (
    <div className={styles.condition} data-target-id={condition.targetId}>
      <Flex start>
        <div className={styles.conditionLeft}>
          <div className={styles.conditionDescription}>
            <span>All others</span>
          </div>
        </div>
      </Flex>
      <p>
        All members who don’t meet the criteria of the other splits will be sent
        to this split.
      </p>
    </div>
  );
};
