import * as React from 'react';
import { Audience } from 'models/audience';
import { ArrowLeft } from 'shared/icons';
import styles from './builder.module.css';

type PropsType = {
  audience: Audience;
  onCloseBuilder: () => void;
  onDone: () => void;
  isSaving?: boolean;
};

export const LeftControls: React.FC<PropsType> = (props) => {
  const { audience, onCloseBuilder, onDone, isSaving } = props;

  const isSaveable =
    audience.title !== '' && !(audience.query === '' || audience.query === '*');

  return (
    <div className={styles.leftControls}>
      <button type="button" onClick={onCloseBuilder} className={styles.dismiss}>
        <ArrowLeft />
      </button>
      <h2 className={styles.title}>New audience</h2>
      <button
        type="button"
        onClick={onDone}
        className={styles.done}
        disabled={!isSaveable || isSaving}
      >
        {isSaving ? 'Saving...' : 'Done'}
      </button>
    </div>
  );
};
