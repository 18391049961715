import * as React from 'react';
import { FeatureFlag } from 'models/feature-flag';
import { FlipSwitch } from 'shared/FlipSwitch';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useUpdateProgramCustomizations } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './capabilities.module.css';

type PropsType = {
  programCustomization: FeatureFlag;
};

export const ListItem: React.FC<PropsType> = ({ programCustomization }) => {
  const [isWorking, setIsWorking] = React.useState(false);
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const { update } = useUpdateProgramCustomizations(programId, {
    onSuccess: () => {
      setIsWorking(false);
      setFlashMessage({
        message: 'Settings updated successfully',
        severity: 'info',
      });
    },
  });

  const changeProgramCustomizationValue = React.useCallback(
    (name: string, value: boolean) => {
      const featureFlag: FeatureFlag<boolean> = {
        type: name,
        label: name,
        value,
        programId,
      };
      setIsWorking(true);
      update(featureFlag);
    },
    [programId, update]
  );

  const handleChange = React.useCallback(
    (value) => {
      changeProgramCustomizationValue(programCustomization.label, value);
    },
    [changeProgramCustomizationValue, programCustomization.label]
  );

  const titleCase = React.useMemo(() => {
    return programCustomization.label
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
  }, [programCustomization.label]);

  const description = React.useMemo(() => {
    return (
      programCustomization.description
        ?.trim()
        .split('.')
        .filter((s) => s) || []
    );
  }, [programCustomization.description]);

  return (
    <>
      <Flex column start className={styles.listItemWrapper}>
        <div className={styles.listItemName}>{titleCase}</div>
        <div className={styles.listItemDescription}>
          {description.map((s: string) => (
            <p>{s}.</p>
          ))}
        </div>
      </Flex>
      <Flex>
        <div className={cx(styles.scope, styles.programScope)}>Program</div>
        <Flex className={styles.value}>
          {!isWorking && (
            <FlipSwitch
              onChange={handleChange}
              on={programCustomization.value === true}
            />
          )}
          {isWorking && <LoadingSpinner size="small" />}
        </Flex>
      </Flex>
    </>
  );
};
