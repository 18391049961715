import { Expression } from 'models/expression';
import { capitalize } from 'utility/strings';

export const isEveryone = (expression: Expression): boolean => {
  if (expression.type === 'complex') {
    const iterator = expression.operator === 'OR' ? 'some' : 'every';
    return expression.terms[iterator]((term) => isEveryone(term.expression));
  }
  if (expression.type === 'simple') {
    return (
      expression.criterion === 'group' &&
      expression.values.includes('all_users')
    );
  }
  return false;
};

export const parseCriterion = (
  expression: Expression
): { count: number; text: string } => {
  if (expression.type === 'simple') {
    return {
      count: 1,
      text: `${capitalize(expression.criterion)} ${
        expression.operator
      } ${expression.values.join(', ')}`,
    };
  }

  if (expression.type === 'complex') {
    const { counts, texts } = expression.terms.reduce<{
      counts: number;
      texts: string[];
    }>(
      (acc, term) => {
        const { count, text } = parseCriterion(term.expression);
        return {
          counts: acc.counts + count,
          texts: [...acc.texts, text],
        };
      },
      { counts: 0, texts: [] }
    );
    return {
      count: counts,
      text: texts.join(` ${expression.operator.toLowerCase()} `),
    };
  }

  return { count: 0, text: '' };
};
