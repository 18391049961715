import React from 'react';
import cx from 'classnames';
import Modal from 'simple-react-modal';
import { Button } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import styles from 'App/Program/Main/Insight/pages/ReportPage.module.css';
import {
  FilterStateObj,
  addReportBookmark as bookmarkReport,
} from 'services/api-insights';
import { ValidationError } from 'services/Errors/ValidationError';

type ModalProps = {
  reportTitle: string;
  reportId: number;
  selectedFiltersStateObj: FilterStateObj;
};

export const BookmarkButton: React.FC<ModalProps> = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(false);
  const [saveBtnDisabled, changeBtnStatus] = React.useState(true);
  const [bookmarkTitle, setBookmarkTitle] = React.useState('');
  const [
    bookmarkValidationMessage,
    setBookmarkValidationMessage,
  ] = React.useState('');
  const { reportTitle, reportId, selectedFiltersStateObj } = props;
  const { id: programId } = useProgram();

  const containerStyle = React.useCallback(
    (height: number, width = 450) => ({
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '0',
      width: `${width}px`,
      height: `${height}px`,
    }),
    []
  );

  const displayModal = React.useCallback(() => setShowModal(true), []);
  const hideModal = React.useCallback(() => {
    setShowModal(false);
    setBookmarkValidationMessage('');
  }, []);

  const handleUserReportBookmark = React.useCallback(async () => {
    try {
      await bookmarkReport({
        programId,
        reportId,
        title: bookmarkTitle,
        filterState: selectedFiltersStateObj,
        public: isPublic,
      });

      hideModal();
      changeBtnStatus(true);
      setShowSuccessModal(true);
    } catch (error: unknown) {
      if (error instanceof ValidationError) {
        setBookmarkValidationMessage(
          (JSON.parse(error.message) as { title: string[] }).title[0]
        );
      }
    }
  }, [
    programId,
    reportId,
    bookmarkTitle,
    selectedFiltersStateObj,
    isPublic,
    hideModal,
    setBookmarkValidationMessage,
  ]);

  const handleTitleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value.trim()) {
        changeBtnStatus(true);
      } else {
        changeBtnStatus(false);
      }

      setBookmarkTitle(event.target.value);
      if (bookmarkValidationMessage) setBookmarkValidationMessage('');
    },
    [bookmarkValidationMessage]
  );

  const ButtonWrapper = React.useCallback(
    ({ children }) => <Button text label={children} onClick={displayModal} />,
    [displayModal]
  );

  return (
    <>
      <ButtonWrapper>
        <svg
          width="18"
          height="18"
          viewBox="0 0 10 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Add a bookmark for this report</title>
          <path d="M8 10L4.667 8.547 1.333 10V1.333H8V10zM8 0H1.333C.597 0 0 .597 0 1.333V12l4.667-2 4.666 2V1.333C9.333.593 8.733 0 8 0z" />
        </svg>
      </ButtonWrapper>
      <Modal
        style={{ padding: '0' }}
        show={showModal}
        containerStyle={containerStyle(300, 520)}
      >
        <div className={styles.banner}>Bookmark report</div>
        <div className={styles.modalActions}>
          <div>
            Save this report with the exact filters you have applied, so you can
            easily come back to it later.
            <input
              type="text"
              id="input-bookmark-title"
              placeholder={reportTitle}
              maxLength={100}
              onChange={handleTitleChange}
              required
            />
            {bookmarkValidationMessage ? (
              <label
                htmlFor="input-bookmark-title"
                className={styles.inputError}
              >
                {bookmarkValidationMessage}
              </label>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.visiblityOption}>
            <small>Make public</small>&nbsp;
            <input
              type="checkbox"
              onChange={(event) => setIsPublic(event.target.checked)}
            />
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.uiButtons}
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className={cx(styles.primary, styles.uiButtons)}
              onClick={handleUserReportBookmark}
              disabled={saveBtnDisabled}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ padding: '0' }}
        show={showSuccessModal}
        containerStyle={containerStyle(175)}
      >
        <div className={styles.banner}>Success</div>
        <div className={styles.modalActions}>
          <div>Report saved to your Saved Reports collection.</div>
          <div className={styles.actions}>
            <button
              type="button"
              className={cx(styles.primary, styles.uiButtons)}
              onClick={() => setShowSuccessModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
