import React from 'react';
import cx from 'classnames';
import { titleCase, pluralize } from 'utility/text';
import { Category, Item, LibraryCategory } from 'models/library';
import { useLibraryCategoryMutation } from 'hooks/useLibrary';
import { useProgram } from 'contexts/program';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { deleteLibraryCategory } from 'services/api-library';
import { TextInput } from 'shared/TextInput';
import { Check, Close, Trash } from 'shared/icons';
import { useFlashMessage } from 'contexts/flasher';
import { DEFAULT_ERROR_MESSAGE } from 'models/flash-message';
import { uniqueId } from 'hooks/useUniqueId';
import { Modal } from 'shared/Modal';
import { Button } from 'DesignSystem/Form';
import { usePermissions } from 'contexts/permissions';
import { UseCollection } from './useCollection';
import styles from './sidebar.module.css';

const compareTitle = (a: Category, b: Category): number =>
  a.title.toLowerCase().localeCompare(b.title.toLowerCase());

type PropsType = {
  collection: UseCollection<Item>;
  sidebarRef: React.RefObject<HTMLDivElement>;
  label: string;

  onChange: (items: Array<Item>) => void;
  onCancel?: () => void;
  useModal?: boolean;
  defaultCategoryLabel: string;
  categories?: Array<Category>;
  divider?: 'line' | 'group';
  hasCustomCategories?: boolean;
  invalidateQuery?: () => void;
  isFilteringAllowed: boolean;
  onCategoryIdChange?: (id: number) => void;
};

export const Sidebar: React.FC<PropsType> = ({
  collection,
  sidebarRef,
  label,
  onChange,
  onCancel,
  defaultCategoryLabel,
  categories = [],
  hasCustomCategories,
  isFilteringAllowed,
  useModal,
  invalidateQuery,
  onCategoryIdChange,
}) => {
  const [category, setCategory] = React.useState<LibraryCategory | undefined>();
  const [allCategories, setAllCategories] = React.useState(categories);
  const [categoryForDeleting, setCategoryForDeleting] = React.useState<
    LibraryCategory | undefined
  >();
  const editorRef = React.useRef<HTMLDivElement>(null);
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const { permissions } = usePermissions();

  const setAllOrderedCategories = React.useCallback((categoriesList) => {
    setAllCategories(categoriesList.sort(compareTitle));
  }, []);

  React.useEffect(() => {
    if (categories.length > 0 && allCategories.length !== categories.length) {
      setAllOrderedCategories(categories);
    }
  }, [categories, allCategories.length, setAllOrderedCategories]);

  const closeCategoryEditor = React.useCallback(() => {
    setCategory(undefined);
  }, []);

  const filterByCategory = React.useCallback(
    (category_id) => {
      if (isFilteringAllowed) {
        closeCategoryEditor();
        collection.filterByCategory(category_id);
        if (onCategoryIdChange) {
          onCategoryIdChange(category_id);
        }
      }
    },
    [closeCategoryEditor, collection, isFilteringAllowed, onCategoryIdChange]
  );

  const clearCategoryFilter = React.useCallback(() => {
    if (isFilteringAllowed) {
      collection.clearFilter();
    }
  }, [collection, isFilteringAllowed]);

  const handleInvalidateQuery = React.useCallback(() => {
    if (invalidateQuery) {
      invalidateQuery();
    }
  }, [invalidateQuery]);

  const onCategorySaveSuccess = React.useCallback(
    (data) => {
      handleInvalidateQuery();
      const exists = allCategories.find((c) => c.id === data.id);
      let filteredCategories: Array<LibraryCategory>;
      if (exists) {
        filteredCategories = allCategories.map((c) =>
          c.id !== data.id ? c : data
        );
      } else {
        filteredCategories = [...allCategories, data];
      }

      setAllOrderedCategories(filteredCategories);
      closeCategoryEditor();
      filterByCategory(data.id);
    },
    [
      allCategories,
      closeCategoryEditor,
      filterByCategory,
      setAllOrderedCategories,
      handleInvalidateQuery,
    ]
  );

  const onCategorySaveError = React.useCallback(
    (data) => {
      setFlashMessage({
        severity: 'error',
        message: DEFAULT_ERROR_MESSAGE,
        details: data.data,
      });
    },
    [setFlashMessage]
  );

  const {
    mutate: saveCategory,
    isSaving: isSavingCategory,
  } = useLibraryCategoryMutation({
    onSuccess: onCategorySaveSuccess,
    onError: onCategorySaveError,
  });

  const openCategoryEditor = React.useCallback(
    (c: LibraryCategory | undefined) => {
      setCategory(c);
    },
    []
  );

  const closeDeleteModal = React.useCallback(() => {
    setCategoryForDeleting(undefined);
  }, []);

  const openNewCategoryEditor = React.useCallback(() => {
    openCategoryEditor({
      id: 0,
      title: '',
      identifier: uniqueId(),
      description: 'Our category',
      program_id: programId,
      status: 'published',
      library_type: 'content_image',
    });
  }, [openCategoryEditor, programId]);

  const changeCategoryName = React.useCallback(
    (value: string) => {
      const newCategory = {
        ...category,
        title: value,
      } as LibraryCategory;
      setCategory(newCategory);
    },
    [category]
  );

  const handlePageClick = React.useCallback(
    (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        closeCategoryEditor();
      }
    },
    [closeCategoryEditor, editorRef]
  );

  const isUnique = React.useMemo(() => {
    if (category) {
      return (
        allCategories.filter(
          (c) => c.title.toLowerCase() === category.title.toLowerCase()
        ).length === 0
      );
    }
    return false;
  }, [allCategories, category]);

  const isCategoryNameValid = React.useMemo(() => {
    return category && category.title.length > 0 && isUnique;
  }, [category, isUnique]);

  const handleSaveCategory = React.useCallback(() => {
    if (category && isCategoryNameValid) {
      saveCategory({ item: category, programId });
    }
  }, [category, isCategoryNameValid, programId, saveCategory]);

  const handleDeleteCategory = React.useCallback((cat) => {
    setCategoryForDeleting(cat);
  }, []);

  const performDeleteCategory = React.useCallback(
    (cat) => {
      deleteLibraryCategory({
        programId,
        item: cat,
        onSuccess: handleInvalidateQuery,
      });
      if (allCategories) {
        setAllCategories(allCategories.filter((c) => c.id !== cat.id));
      }
      setCategoryForDeleting(undefined);
    },
    [programId, handleInvalidateQuery, allCategories]
  );

  const isShowingDeleteConformation = React.useMemo(() => {
    return categoryForDeleting !== undefined;
  }, [categoryForDeleting]);

  const canAddCategory = React.useMemo(() => {
    return permissions.libraryImagesAccess && isFilteringAllowed;
  }, [isFilteringAllowed, permissions.libraryImagesAccess]);

  const isSelected = React.useCallback(
    (cat) => {
      return (
        collection.filter.type === 'category' && collection.filter.id === cat.id
      );
    },
    [collection.filter]
  );

  React.useEffect(() => {
    document.addEventListener('click', handlePageClick);
    return () => document.removeEventListener('click', handlePageClick);
  }, [closeCategoryEditor, handlePageClick]);

  return (
    <div className={styles.sidebar}>
      {hasCustomCategories && isShowingDeleteConformation && (
        <Modal
          showModal={isShowingDeleteConformation}
          title="Delete Category?"
          showTitle
          onClose={closeDeleteModal}
        >
          <div className={styles.deleteWarning}>
            This category and all of it&apos;s images will be deleted. This
            cannot be undone.
          </div>
          <div className={styles.deleteButtons}>
            <Button
              warning
              label="Delete"
              onClick={() => performDeleteCategory(categoryForDeleting)}
            />

            <Button secondary label="Cancel" onClick={closeDeleteModal} />
          </div>
        </Modal>
      )}
      <div
        className={cx({
          [styles.sidebarFixed]: !useModal,
          [styles.sidebarModal]: useModal,
        })}
        ref={(!useModal && sidebarRef) || (() => {})}
      >
        {!useModal && (
          <>
            <div className={styles.label}>{titleCase(pluralize(label))}</div>
            <aside className={styles.actionsAside}>
              <button
                type="button"
                className={styles.applyButton}
                disabled={collection.selection.length === 0}
                onClick={() => {
                  onChange(collection.selection);
                }}
                data-test="apply-selection"
              >
                Use{' '}
                {collection.selection.length > 1
                  ? `${collection.selection.length} ${titleCase(
                      pluralize(2, label)
                    )}`
                  : `${titleCase(label)}`}
              </button>
              <button
                className={styles.cancelButton}
                type="button"
                onClick={() => {
                  // do not shorten to (onCancel && onCancel() || onChange && onChange([]) )
                  // in this case both functions will run on cancel, breaking the publisher
                  if (onCancel) {
                    onCancel();
                  } else if (onChange) {
                    onChange([]);
                  }
                }}
              >
                Cancel
              </button>
            </aside>
          </>
        )}
        <aside className={styles.aside}>
          <ul>
            {hasCustomCategories && (
              <>
                <li>Our categories</li>
                {allCategories
                  .filter((cat) => cat.program_id)
                  .map((cat) => (
                    <li key={cat.id} className={styles.ourCategory}>
                      {(!category || category.id !== cat.id) && (
                        <>
                          <button
                            type="button"
                            className={cx(styles.ourCategoryName, {
                              [styles.locked]:
                                !isFilteringAllowed && !isSelected(cat),
                              [styles.active]: isSelected(cat),
                            })}
                            onClick={() => filterByCategory(cat.id)}
                            title={cat.description}
                          >
                            {cat.title}
                          </button>
                          {canAddCategory && (
                            <div className={styles.ourCategoryControls}>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openCategoryEditor(cat);
                                }}
                              >
                                <SVGIcon name="Edit" size={20} />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteCategory(cat)}
                              >
                                <Trash />
                              </button>
                            </div>
                          )}
                        </>
                      )}
                      {category && category.id === cat.id && (
                        <div
                          ref={editorRef}
                          className={styles.editorInputsContainer}
                        >
                          <TextInput
                            className={styles.categoryInput}
                            maximum={30}
                            value={category.title}
                            onChange={changeCategoryName}
                            placeholder="Category name"
                          />
                          <button
                            onClick={handleSaveCategory}
                            className={cx(styles.applyChanges, {
                              [styles.canApplyChanges]: isCategoryNameValid,
                            })}
                            disabled={!isCategoryNameValid}
                            type="button"
                          >
                            <Check />
                          </button>
                          <button
                            onClick={closeCategoryEditor}
                            className={styles.editorControls}
                            type="button"
                          >
                            <Close />
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                <li className={styles.buttons}>
                  {canAddCategory && (
                    <div>
                      {(!category || (category && category.id > 0)) && (
                        <button
                          className={styles.addNew}
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            openNewCategoryEditor();
                          }}
                        >
                          + Add a new category
                        </button>
                      )}
                      {category && category.id === 0 && (
                        <div
                          ref={editorRef}
                          className={cx(styles.editorContainer, {
                            [styles.isSaving]: isSavingCategory,
                          })}
                        >
                          <div className={styles.editorInputsContainer}>
                            <TextInput
                              maximum={30}
                              hasError={!isUnique}
                              className={styles.categoryInput}
                              value={category.title}
                              onChange={changeCategoryName}
                              placeholder="Category name"
                            />
                            <Button
                              onClick={handleSaveCategory}
                              disabled={!isUnique}
                              icon={
                                <div
                                  className={cx(styles.applyChanges, {
                                    [styles.canApplyChanges]: isCategoryNameValid,
                                  })}
                                >
                                  <Check />
                                </div>
                              }
                            />
                            <Button
                              onClick={closeCategoryEditor}
                              icon={
                                <div>
                                  <Close />
                                </div>
                              }
                            />
                          </div>
                          {!isUnique && (
                            <div className={styles.error}>
                              Category name is taken.
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className={styles.divider} />
                </li>
              </>
            )}
            <li>Other Categories</li>
            <li key="default_category">
              <button
                className={cx({
                  [styles.locked]: !isFilteringAllowed,
                  [styles.active]: collection.filter.type === 'all',
                })}
                type="button"
                onClick={clearCategoryFilter}
              >
                {defaultCategoryLabel}
              </button>
            </li>
            {allCategories
              .filter((cat) => !cat.program_id)
              .map((cat) => (
                <li key={cat.id}>
                  <button
                    type="button"
                    className={cx({
                      [styles.locked]: !isFilteringAllowed && !isSelected(cat),
                      [styles.active]: isSelected(cat),
                    })}
                    onClick={() => filterByCategory(cat.id)}
                    title={cat.description}
                  >
                    {cat.title}
                  </button>
                </li>
              ))}
          </ul>
        </aside>
      </div>
    </div>
  );
};
