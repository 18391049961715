import React, { FC } from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TabName } from '../../../../context';
import styles from './styles.module.css';

interface Props {
  value: TabName;
  onEnable: (tab: TabName) => void;
}

const HiddenItem: FC<Props> = ({ value, onEnable }) => {
  const handleChange = React.useCallback(() => {
    onEnable(value);
  }, [onEnable, value]);

  return (
    <div className={styles.Item}>
      {value}
      <FlipSwitch on={false} value={value} onChange={handleChange} />
    </div>
  );
};

export default HiddenItem;
