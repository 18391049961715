import React, { FC } from 'react';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { useDeleteTopicShortcut } from 'hooks/topics';
import { TopicShortcut } from 'models/topic';
import { useTopicFormCtx } from '../../../../context';

interface Props {
  shortcut: TopicShortcut;
  onClose: () => void;
}

export const DeleteShortcutModal: FC<Props> = ({ shortcut, onClose }) => {
  const { id: programId } = useProgram();
  const { topic } = useTopicFormCtx();
  const { destroy, isLoading: isDeleting } = useDeleteTopicShortcut(
    programId,
    topic.id
  );

  const onConfirm = async () => {
    await destroy(shortcut);
    onClose();
  };

  return (
    <ConfirmModal
      title="Delete Shortcut"
      onCancel={onClose}
      disableCancel={isDeleting}
      confirmButton={
        <Button
          isLoading={isDeleting}
          onClick={onConfirm}
          label="Confirm Delete"
        />
      }
    >
      Are you sure you want to delete this shortcut?
    </ConfirmModal>
  );
};
