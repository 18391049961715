import React, { FC, useCallback, useMemo } from 'react';
import { uniqueId } from 'hooks/useUniqueId';
import { Checkbox } from 'DesignSystem/Form';
import { Control, Field, Label } from './common';

interface Props {
  name: string;
  checked: boolean;
  onChange: (name: string, on: boolean) => void;
}

const CheckboxField: FC<Props> = ({ children, name, checked, onChange }) => {
  const id = useMemo(() => `checkbox-${uniqueId()}`, []);
  const handleChange = useCallback(
    (on) => {
      onChange(name, !!on);
    },
    [name, onChange]
  );
  return (
    <Field>
      <Control>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name={name}
          id={id}
        />
      </Control>
      <Label htmlFor={id}>{children}</Label>
    </Field>
  );
};

export default CheckboxField;
