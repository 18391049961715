import QS from 'qs';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import {
  Channel,
  EditingState,
  Workflow,
  WorkflowSummary,
} from 'models/workflows';
import { request } from './api-shared';
import { fqApiUrl, Page } from './common';

export type FetchProps = {
  programId: number;
  page?: number;
  pageSize?: number;
  editingStates?: EditingState[];
  channels?: Channel[];
  search?: string;
  visibility?: string[];
  sortBy?: SortColumn;
  sortDirection?: 'asc' | 'desc';
};

export type SortColumn = 'from_date' | 'name' | 'updated_at';

export type WorkflowCollection = Page<Workflow>;

export async function fetchWorkflows({
  programId,
  visibility = [],
  editingStates = [],
  page = 1,
  pageSize = 20,
  sortBy = 'updated_at',
  sortDirection = 'desc',
  ...queryProps
}: FetchProps): Promise<WorkflowCollection> {
  const apiVisibility = visibility.length !== 2 ? visibility[0] : 'all';
  // TODO This api converts from kai semantics to boss semantics
  //      and these "fixes" can be moved upward in the codebase
  //      as the usage and design stabilizes.
  const apiEditingStates = editingStates.map((state) =>
    state === 'archived' ? 'deleted' : state
  );
  if (apiEditingStates.includes('active')) apiEditingStates.push('running');
  if (apiEditingStates.includes('completed')) apiEditingStates.push('all_sent');

  const qs = QS.stringify({
    ...snakecaseKeys({
      ...queryProps,
      page,
      perPage: pageSize,
      visibility: apiVisibility,
      editingStates: apiEditingStates,
    }),
    // TODO: Standardize the legacy campaign filter params -- classic studio uses camelCase
    sortBy,
    sortDirection,
  });
  const url = fqApiUrl(`samba/programs/${programId}/campaigns?${qs}`);
  const res = await request(url);
  if (res.status !== 200)
    throw new Error(`Error fetching workflows: ${res.status}`);

  const data = await res.json();
  const collection: WorkflowCollection = camelcaseKeys(data, { deep: true });
  if (collection.meta && !collection.meta.currentPage) {
    collection.meta.currentPage = page;
  }
  return collection;
}

export type WorkflowSummaryFetchProps = {
  programId: number;
  workflowId: number;
};

export async function fetchWorkflowSummary({
  programId,
  workflowId,
}: WorkflowSummaryFetchProps): Promise<WorkflowSummary> {
  const url = fqApiUrl(
    `samba/programs/${programId}/campaigns/${workflowId}/metrics/show_summary`
  );
  const res = await request(url);
  if (res.status !== 200)
    throw new Error(`Error fetching workflow summary: ${res.status}`);

  const data = await res.json();
  return camelcaseKeys(data, { deep: true });
}

export async function activateWorkflow(
  programId: number,
  workflowId: number
): Promise<Workflow> {
  const url = fqApiUrl(
    `samba/programs/${programId}/campaigns/${workflowId}/activate`
  );
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }
  throw new Error(`Error activating workflow: ${response.status}`);
}

export async function archiveWorkflow(
  programId: number,
  workflowId: number
): Promise<Workflow> {
  const url = fqApiUrl(`samba/programs/${programId}/campaigns/${workflowId}`);
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }
  throw new Error(`Error archiving workflow: ${response.status}`);
}

export async function copyWorkflow(
  programId: number,
  workflowId: number
): Promise<Workflow> {
  const url = fqApiUrl(
    `samba/programs/${programId}/campaigns/${workflowId}/copy`
  );
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }
  throw new Error(`Error copying workflow: ${response.status}`);
}

export async function startDraftWorkflow(
  programId: number,
  workflowId: number
): Promise<Workflow> {
  const url = fqApiUrl(
    `samba/programs/${programId}/campaigns/${workflowId}/start_draft`
  );
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }
  throw new Error(`Error starting draft workflow: ${response.status}`);
}
