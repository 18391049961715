import React, { useMemo, useCallback } from 'react';
import cx from 'classnames';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { useProgram } from 'contexts/program';
import { useInfiniteApiQuery } from 'hooks/common';
import { fetchInitiatives } from 'services/api-initiatives';
import { Initiative } from 'models/initiative';
import { ClickDropdown, ClickDropdownHandle } from 'shared/ClickDropdown';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import styles from './initiative-select.module.css';

export const InitiativesFilter: React.FC<{
  value: Array<string>;
  onChange: (initiatives: Array<string>) => void;
  align?: 'left' | 'right' | 'center';
}> = ({ value, onChange, align = 'left' }) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');
  const clearSearch = React.useCallback(() => {
    setSearch('');
  }, []);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery('initiatives', fetchInitiatives, {
    programId,
    pageSize: 20000,
    query: search,
  });

  const initiativesByRowId: { [rowId: string]: Initiative } = useMemo(
    () =>
      Object.fromEntries(
        data.map(({ attributes }) => [`${attributes.id}`, attributes])
      ),
    [data]
  );

  const rowIds = data.map((d) => d.id);

  const onSelectedIdsChange = useCallback((ids: string[]) => onChange(ids), [
    onChange,
  ]);

  const renderRow = React.useCallback(
    (rowId: string) => {
      const item = initiativesByRowId[rowId];
      return item ? (
        <div className={styles.filterTitle}>{item.name}</div>
      ) : null;
    },
    [initiativesByRowId]
  );

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={300}
        itemHeight={30}
        selectedIds={value}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        searchEnabled
        searchTerm={search}
        searchPlaceholder="Search initiatives"
        onSearchTermChange={setSearch}
        itemClassName="filter-item"
        className={cx('no-search')}
        hasClearSearchButton
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [
    onSelectedIdsChange,
    renderRow,
    rowIds,
    value,
    search,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  ]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  const names = React.useMemo(() => {
    return data
      .filter((t) => value?.includes(`${t.id}`))
      .map((t) => t.attributes.name);
  }, [data, value]);

  const clickDropdownRef = React.useRef<ClickDropdownHandle>(null);

  // When a new dataset is fetched, the width of the dropdown may change causing it to
  // possibly overflow off of the screen. The use of setImmediate is necessary to wait
  // for the InfiniteSelect component to finish rendering before having the dropdown check
  // if its positioning needs to be corrected.
  React.useEffect(() => {
    setImmediate(() => clickDropdownRef.current?.correctDropdownOverflow());
  }, [data]);

  return (
    <ClickDropdown
      onClose={clearSearch}
      dropdownRenderProp={dropdownRenderProp}
      dropdownClassName={`dropdown-align-${align}`}
      ref={clickDropdownRef}
    >
      <TriggerButton name="Initiatives" values={names} />
    </ClickDropdown>
  );
};
