import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { BossViewPage } from 'shared/BossView/Page';
import { MainPanel } from './MainPanel';

export const Capabilities: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { isLoading, data } = useProgramCustomizationsQuery(programId);
  const { data: featureFlagsManagement } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.FeatureFlagManagement'
  );

  if (featureFlagsManagement && featureFlagsManagement.value === true) {
    return (
      <WithPermission permissions={['configureAccountAccess']}>
        <FormPage
          breadcrumbs={[
            { to: '..', label: 'Configure' },
            { label: 'Capabilities' },
          ]}
          title="Capabilities"
        >
          <MainPanel isLoading={isLoading} featureFlags={data} />
        </FormPage>
      </WithPermission>
    );
  }

  return (
    <BossViewPage
      title="Capabilities"
      src="#program-customizations"
      app="samba"
      returnUrls={['/configure/capabilities']}
    />
  );
};
