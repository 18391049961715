import React, { useState } from 'react';
import cx from 'classnames';
import { Fieldset, Field } from 'shared/Fieldset';
import { ImageData, isImageDataArray } from 'models/image';
import { TextInput } from 'shared/TextInput';
import { AltTextTooltip } from 'shared/AltTextTooltip';
import {
  useContentImageUploader,
  useProcessedContentImage,
} from 'hooks/useContentImage';
import { Source, UPLOAD } from 'models/source';
import { DevReveal, PrettyJson } from 'DevMode';
import { SourceMenu } from '../../SourceMenu';
import { ReactComponent as EmptyImage } from './empty-image.svg';
import * as SourceField from './useImagesSourceMenu';
import formStyles from '../../form.module.css';
import styles from './upload.module.css';
import { UpdateOpts } from '../../../useEditor';

type FormData = {
  image?: ImageData;
  initialSource?: Source;
  enableSave?: () => void;
  disableSave?: () => void;
};

type FormEvents = {
  onUpdate: (image?: ImageData, opts?: UpdateOpts) => void;
  // When omitted, the buttons won't be shown in the UI
  onRemove?: () => void;
};

export const Form: React.FC<FormData & FormEvents> = ({
  image,
  initialSource = UPLOAD,
  onUpdate,
  enableSave,
  disableSave,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onUploadOrProcessError = (message: string) => {
    uploader.remove();
    setErrorMessage(message);
    setIsProcessing(false);
  };

  const uploader = useContentImageUploader({
    onUpload: (imageData) => {
      setIsProcessing(!imageData.processed);
      onUpdate(imageData);
    },
    onError: onUploadOrProcessError,
  });

  useProcessedContentImage({
    image: uploader.image,
    onProcessed: (data) => {
      onUpdate(data, { shouldValidate: false });
      setIsProcessing(false);
    },
    onError: onUploadOrProcessError,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const onCreate = (data: ImageData[] | File[] | DataTransferItemList) => {
    uploader.remove();
    setErrorMessage('');

    if (isImageDataArray(data)) {
      uploader.uploadUrl(data[0].url);
    } else if (data instanceof DataTransferItemList) {
      const file = data[0].getAsFile();
      if (file) uploader.uploadFile(file);
    } else {
      uploader.uploadFile(data[0]);
    }
  };

  const field = SourceField.useImagesSourceMenu(
    {
      source: initialSource,
      visibility: image?.url ? SourceField.CLOSED : SourceField.OPENED,
    },
    onCreate,
    1
  );

  React.useEffect(() => {
    // only enable/disable the save button when both callbacks are provided
    if (!enableSave || !disableSave) {
      return;
    }

    if (uploader.isUploading || errorMessage || isProcessing) {
      disableSave();
    } else {
      enableSave();
    }
  }, [
    disableSave,
    enableSave,
    errorMessage,
    isProcessing,
    uploader.isUploading,
  ]);

  return (
    <Fieldset className={cx(formStyles.fieldset)}>
      <Field
        className={cx(formStyles.field, styles.imageField)}
        label="Image"
        isLoading={uploader.isUploading || isProcessing}
      >
        <DevReveal
          view={
            <PrettyJson
              value={{
                isUploading: uploader.isUploading,
                errorMessage,
                isProcessing,
              }}
            />
          }
        >
          {image?.url ? (
            <img
              className={formStyles.image}
              src={image.url}
              alt={image.altText}
              title={image.altText}
            />
          ) : (
            <div>
              <EmptyImage />
            </div>
          )}
        </DevReveal>
      </Field>
      <Field className={cx(formStyles.field, styles.imageField)} label="">
        <div className={formStyles.actions}>
          <div className={formStyles.change}>
            <SourceMenu menu={field.menu} />
          </div>
          {field.fileInput}
          {field.libraryInput}
          {field.externalInput}
        </div>
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </Field>
      <Field
        label="Alt text"
        className={cx(formStyles.field, styles.imageField)}
        tooltip={<AltTextTooltip className="tooltip-content" />}
      >
        <TextInput
          className="flex-fill"
          value={image?.altText || ''}
          placeholder="Alt Text"
          onChange={(altText) =>
            onUpdate({
              ...image,
              url: image?.url || '',
              altText,
              processed: !!image?.processed,
            })
          }
        />
      </Field>
    </Fieldset>
  );
};
