import * as React from 'react';
import { CloudbeesFeatureFlag } from 'models/feature-flag';
import { FlipSwitch } from 'shared/FlipSwitch';
import { useProgram } from 'contexts/program';
import cx from 'classnames';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useFlashMessage } from 'contexts/flasher';
import { useUpdateCloudbeesFeatureFlag } from 'hooks/feature-flags';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './capabilities.module.css';

type PropsType = {
  programCustomization: CloudbeesFeatureFlag;
};

export const ListItemCloudbees: React.FC<PropsType> = ({
  programCustomization,
}) => {
  const { id: programId } = useProgram();
  const [isWorking, setIsWorking] = React.useState(false);
  const { setFlashMessage } = useFlashMessage();
  const isBooleanGlobal = React.useMemo(() => {
    return programCustomization.conditions.length === 0;
  }, [programCustomization.conditions.length]);

  const property = React.useMemo(() => {
    return programCustomization.conditions.find(
      (c) => c.property && c.property.name === 'program_id'
    );
  }, [programCustomization.conditions]);

  const index = React.useMemo(() => {
    if (property) return programCustomization.conditions.indexOf(property);
    return -1;
  }, [programCustomization.conditions, property]);

  const on = React.useMemo(() => {
    if (isBooleanGlobal) {
      return programCustomization.value;
    }
    if (property && index !== -1) {
      return property.property.operand.includes(`${programId}`);
    }
    return false;
  }, [index, isBooleanGlobal, programCustomization.value, programId, property]);

  const [flipSwitchState, setFlipSwitchState] = React.useState(on);

  const updateCloudbees = useUpdateCloudbeesFeatureFlag(programId, {
    onSuccess: () => {
      setIsWorking(false);
      setFlashMessage({
        message: 'Settings updated successfully',
        severity: 'info',
      });
    },
  });

  const changeFeatureFlagValue = React.useCallback(
    (name, path, value) => {
      const newValue = {
        op: 'replace',
        path,
        value: value || false,
      };
      updateCloudbees.update(name, newValue);
    },
    [updateCloudbees]
  );

  const handleChange = React.useCallback(
    (value) => {
      setIsWorking(true);
      if (isBooleanGlobal) {
        changeFeatureFlagValue(programCustomization.name, '/value', value);
        setFlipSwitchState(!flipSwitchState);
      } else if (property && index !== -1) {
        const propertyValue = property.property.operand;
        let newValue: Array<string> = [];

        if (value) {
          if (propertyValue.includes(`${programId}`)) {
            newValue = propertyValue;
          } else {
            newValue = [...propertyValue, `${programId}`];
          }
        } else {
          newValue = propertyValue.filter((v) => v !== `${programId}`);
        }
        setFlipSwitchState(!flipSwitchState);
        changeFeatureFlagValue(
          programCustomization.name,
          `/conditions/${index}/property/operand`,
          newValue
        );
      }
    },
    [
      changeFeatureFlagValue,
      flipSwitchState,
      index,
      isBooleanGlobal,
      programCustomization.name,
      programId,
      property,
    ]
  );

  const isPerProgram = React.useMemo(() => {
    return (
      !isBooleanGlobal &&
      programCustomization.conditions
        .map((c) => Object.keys(c))
        .flat()
        .includes('property')
    );
  }, [isBooleanGlobal, programCustomization.conditions]);

  const hasValue = React.useMemo(() => {
    if (isBooleanGlobal) {
      return false;
    }
    return !programCustomization.conditions
      .map((c) => Object.keys(c))
      .flat()
      .includes('property');
  }, [isBooleanGlobal, programCustomization.conditions]);

  return (
    <>
      <div className={styles.listItem}>
        <div className={styles.listItemName}>{programCustomization.name}</div>
        <div className={styles.listItemDescription}>
          {programCustomization.description}
        </div>
      </div>
      <Flex>
        <div
          className={cx(styles.scope, {
            [styles.programScope]: isPerProgram,
            [styles.globalScope]: !isPerProgram,
          })}
        >
          {isPerProgram ? 'Program' : 'Global'}
        </div>
        <Flex className={styles.value}>
          {isWorking && <LoadingSpinner size="small" />}
          {!isWorking && !hasValue && (
            <FlipSwitch onChange={handleChange} on={flipSwitchState} />
          )}
          {!isWorking &&
            hasValue &&
            programCustomization.conditions.map((c) => c.value)}
        </Flex>
      </Flex>
    </>
  );
};
