import * as React from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { Design } from 'models/design';
import {
  copyDesign,
  CopyDesignProps,
  DesignData,
  upsertDesign,
} from 'services/api-design';
import { useNavigate } from '@reach/router';
import { DesignError } from 'contexts/design';

export type DesignStatus = {
  isSaving: boolean;
  lastModified?: DateTime;
  lastSaveSuccess?: DateTime;
  error?: 'unauthorized' | 'not-found';
};

export const defaultDesignStatus: DesignStatus = {
  isSaving: false,
};

export type UsePersistDesign = {
  save: (
    options?: Partial<{
      shouldValidate: boolean;
      design: Design;
      onSuccess: (design: DesignData) => void;
      onError: (errors: Array<DesignError>) => void;
      callback: (design: DesignData) => void;
    }>
  ) => void;
  status: DesignStatus;
};

type UsePersistDesignProps = {
  currentDesign: Design | undefined;
  setDesign: React.Dispatch<React.SetStateAction<Design>>;
  readOnly: boolean;
  redirectOnSave?: boolean;
};

export const usePersistDesign = ({
  setDesign,
  currentDesign,
  readOnly,
  redirectOnSave = true,
}: UsePersistDesignProps): UsePersistDesign => {
  const lastUpdatedAt = currentDesign?.updatedAt
    ? DateTime.fromISO(currentDesign.updatedAt)
    : undefined;
  const [lastSaveSuccess, setLastSaveSuccess] = React.useState<
    DateTime | undefined
  >(lastUpdatedAt);
  const navigate = useNavigate();

  const onSaveSuccess = React.useCallback(
    (designData: DesignData) => {
      setDesign((current) => {
        if (!current) return designData.attributes;
        return {
          ...current,
          id: designData.attributes.id,
          updatedAt: designData.attributes.updatedAt,
        };
      });
      // @ts-expect-error Some undocumented attribute in use here.
      setLastSaveSuccess(DateTime.fromISO(designData?.attributes?.updatedAt));
      if (redirectOnSave) {
        navigate(`../${designData.attributes.id}/design`, {
          replace: true,
        });
      }
    },
    [navigate, redirectOnSave, setDesign]
  );

  const [isMutateLoading, mutate] = useDesignMutation(onSaveSuccess);
  const isLoading = isMutateLoading;

  const save = React.useCallback<UsePersistDesign['save']>(
    ({ onSuccess, onError, design } = {}) => {
      const designData = design ?? currentDesign;
      if (!designData) return;
      if (readOnly) return;

      mutate(
        {
          ...designData,
        },
        {
          onSuccess,
          onError: (error: Error) => {
            if (error.message && onError) {
              onError(JSON.parse(error.message).errors as Array<DesignError>);
            }
          },
        }
      );
    },
    [currentDesign, readOnly, mutate]
  );

  return {
    save,
    status: {
      isSaving: isLoading,
      lastSaveSuccess,
    },
  };
};

function useDesignMutation(
  onSuccess: (designData: DesignData) => void
): [boolean, UseMutateFunction<DesignData, Error, Design>] {
  const { id: programId } = useProgram();

  const { isLoading, mutate } = useMutation<DesignData, Error, Design>(
    ['design/upsert'],
    async (design: Design) => upsertDesign(programId, design),
    { onSuccess }
  );
  return [isLoading, mutate];
}

export const useCopyDesignMutation = (): {
  isLoading: boolean;
  mutate: UseMutateFunction<Design, Error, CopyDesignProps>;
} => {
  const programId = useProgram().id;

  const { isLoading, mutate } = useMutation<Design, Error, CopyDesignProps>(
    (props: CopyDesignProps) => copyDesign(programId, props)
  );

  return { isLoading, mutate };
};
