import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { ConfirmDeleteV2 as ConfirmDelete } from 'shared/ConfirmDelete';
import { MAIcon } from 'shared/MAIcon';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import styles from './styles.module.css';

export const FilePreview: React.FC<{
  onEdit: (id: number) => void;
  onRemove?: (id: number) => void;
  file: PersonalizedFieldsFile;
  isOwner?: boolean;
}> = ({ onEdit, onRemove, file, isOwner }) => {
  const {
    id,
    idType,
    name,
    state,
    expires,
    processedRows = 0,
    totalRows: rows = 0,
  } = file;
  const [message, setMessage] = React.useState('');
  const [needsAction, setNeedsAction] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    if (state === 'complete') {
      setMessage(`${rows.toLocaleString()} user${rows === 1 ? '' : 's'}`);
      setNeedsAction(!expires);
    } else if (state === 'in_progress' || (state === 'created' && idType)) {
      const percent = Math.round((100 * processedRows) / (rows || 1));
      setMessage(`Processing (${percent}% complete)`);
      setNeedsAction(false);
    } else if (state === 'created') {
      setNeedsAction(true);
    } else {
      setMessage('Failed to upload or process the file');
      setNeedsAction(false);
    }
  }, [idType, state, expires, processedRows, rows]);

  React.useEffect(() => {
    if (needsAction) setMessage('Upload incomplete; action needed');
  }, [needsAction]);

  const showDialog = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleting(true);
  }, []);

  const remove = React.useCallback(
    (event?: React.MouseEvent) => {
      setDeleting(false);
      if (onRemove) onRemove(id);
      if (event) event.stopPropagation();
    },
    [onRemove, id]
  );

  return (
    <Box
      className={cx(styles.FilePreview, {
        [styles.Failed]: state === 'failed',
        [styles.NeedsAction]: needsAction,
        [styles.NoBorder]: deleting,
      })}
    >
      {deleting && (
        <ConfirmDelete
          entity="Personalized Fields File"
          onRemove={remove}
          cancelRemove={() => setDeleting(false)}
        />
      )}
      <Flex center style={{ padding: '8px 8px' }}>
        <div style={{ flex: 'auto' }}>
          <div className="line-clamp-1">
            <Text.Body bold>{name}</Text.Body>
          </div>
          <div className={`line-clamp-1 ${Text.styles.Caption}`}>{message}</div>
        </div>
        {isOwner && (
          <Button
            title="Edit File"
            minimal
            clearText
            icon={<MAIcon name={state === 'failed' ? 'warning' : 'settings'} />}
            onClick={() => onEdit(id)}
          />
        )}
        {onRemove && isOwner && (
          <Button
            title="Remove File"
            minimal
            clearText
            icon={<MAIcon name="delete" />}
            onClick={showDialog}
          />
        )}
      </Flex>
    </Box>
  );
};
