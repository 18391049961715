import * as React from 'react';
import { Item, Filter, Category } from 'models/library';
import { InfiniteQueryResponse, PaginationState } from 'hooks/common';
import { useDebounce } from 'hooks/useDebounce';

export type UseCollection<T extends Item = Item> = {
  isLoading: boolean;
  filter: Filter;
  items: T[];
  selection: T[];
  clearFilter: () => void;
  filterByCategory: (id: number) => void;
  filterBySelected: () => void;
  filterBySearch: (term: string) => void;
  isSelected: (item: T) => boolean;
  select: (item: T) => void;
  unselect: (item: T) => void;
  pagination: PaginationState;
};

export const useCollection = <T extends Item>(
  useCollectionSource: (params: { filter: Filter }) => InfiniteQueryResponse<T>,
  maxSelections = 1,
  omitCategories: Category['identifier'][] = []
): UseCollection<T> => {
  const [filter, setFilter] = React.useState<Filter>({ type: 'all' });
  const [selection, setSelection] = React.useState<T[]>([]);
  const debouncedFilter = useDebounce(filter);
  const liveFilter = filter.type === 'search' ? debouncedFilter : filter;
  const { isLoading, data: items, ...pagination } = useCollectionSource({
    filter: liveFilter,
  });

  const handleSelection = React.useCallback(
    (item: T, op: 'add' | 'remove') => {
      if (op === 'remove')
        setSelection(selection.filter((selected) => selected.id !== item.id));
      else {
        let next: T[] = [...selection];
        const ids = next.map((selected) => selected.id);
        if (!ids.includes(item.id)) next.push(item);
        next = next.slice(0, maxSelections);
        setSelection(next);
      }
    },
    [maxSelections, selection, setSelection]
  );

  const selectedIds = React.useMemo(() => selection.map((item) => item.id), [
    selection,
  ]);

  const visibleItems = React.useMemo(() => {
    if (filter.type === 'selected') return selection;
    if (!omitCategories.length) return items;
    return items.filter(
      (item) =>
        !item.categories.some((category) =>
          omitCategories.includes(category.identifier)
        )
    );
  }, [filter.type, selection, omitCategories, items]);

  return {
    isLoading,
    filter,
    items: visibleItems,
    selection,
    pagination,
    filterByCategory: (id: number) => setFilter({ type: 'category', id }),
    filterBySearch: (term = '') => setFilter({ type: 'search', search: term }),
    filterBySelected: () => setFilter({ type: 'selected' }),
    clearFilter: () => setFilter({ type: 'all' }),
    isSelected: (item: T) => selectedIds.includes(item.id),
    select: (item: T) => handleSelection(item, 'add'),
    unselect: (item: T) => handleSelection(item, 'remove'),
  };
};
