import React from 'react';

import { Label } from 'models/label';
import { Box } from 'DesignSystem/Components';
import { LabelDropdown } from 'App/Program/Editors/Publisher/Deliver/FeaturedLabel/LabelDropdown';
import { FieldLabel } from 'DesignSystem/Typography';

const ACKNOWLEDGEMENT_LABELS: Label[] = [
  { preset: 'default', text: 'I acknowledge' },
  { preset: 'read', text: 'I have read and accept' },
  { preset: 'accept', text: 'I accept' },
  { preset: 'watched', text: 'I have watched and accept' },
];

const ACKNOWLEDGEMENT_LABEL_MAX_LENGTH = 40;

export function acknowledgementLabelUtils(): {
  labels: Label[];
  defaultLabel: Label;
  maxLength: number;
  findByPreset: (preset: string) => Label | undefined;
  validateLabel: (label?: Label) => boolean;
} {
  const defaultLabel =
    ACKNOWLEDGEMENT_LABELS.find((l) => l.preset === 'default') ||
    ACKNOWLEDGEMENT_LABELS[0];

  const findByPreset = (preset: string): Label | undefined =>
    ACKNOWLEDGEMENT_LABELS.find((l) => l.preset === preset);

  const validateLabel = (labelToValidate?: Label): boolean => {
    if (!labelToValidate) return false;
    const trimmedText = labelToValidate.text.trim();
    return (
      trimmedText.length > 0 &&
      trimmedText.length <= ACKNOWLEDGEMENT_LABEL_MAX_LENGTH
    );
  };

  return {
    labels: ACKNOWLEDGEMENT_LABELS,
    defaultLabel,
    maxLength: ACKNOWLEDGEMENT_LABEL_MAX_LENGTH,
    findByPreset,
    validateLabel,
  };
}

export const AcknowledgementConfig: React.FC<{
  label?: Label;
  onChange: (label?: Label) => void;
  errorMessage?: string;
}> = ({ label, onChange }) => {
  const { labels, maxLength, defaultLabel } = acknowledgementLabelUtils();

  const onAcknowledgementLabelChange = React.useCallback(
    (newLabel?: Label) => {
      if (!newLabel) {
        onChange(newLabel);
        return;
      }
      const trimmedLabel = { ...newLabel, text: newLabel.text.trim() };
      onChange(trimmedLabel);
    },
    [onChange]
  );

  return (
    <Box>
      <FieldLabel>Acknowledgement Label:</FieldLabel>
      <Box margin={[15, 0, 0, 0]}>
        <LabelDropdown
          placeholder="Select Acknowledgement Text"
          defaultSelectedLabel={label || defaultLabel}
          onChange={onAcknowledgementLabelChange}
          labels={labels}
          maxWidth={maxLength}
        />
      </Box>
    </Box>
  );
};
