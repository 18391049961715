import { createContext } from 'react';

type PagesControlContextType = {
  pinnedPostsIncluded: boolean;
  setPinnedPostsIncluded: (value: boolean) => void;
};

/**
 * Context to preserve state between re-renders of sortable items
 */
export const PagesControlContext = createContext<PagesControlContextType>({
  pinnedPostsIncluded: false,
  setPinnedPostsIncluded: () => {},
});
