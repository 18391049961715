import React, { FC } from 'react';
import { MAIcon } from 'shared/MAIcon';
import { Alert, AlertType } from 'DesignSystem/Components';
import styles from './styles.module.css';
import { useApplicableErrorsInGroup } from '../../../topicErrorHooks';

export const SettingsViewErrors: FC = () => {
  const applicableErrors = useApplicableErrorsInGroup('Settings');

  if (applicableErrors.length === 0) return null;

  if (applicableErrors.length === 1)
    return (
      <Alert
        bgColor="red"
        title={applicableErrors[0].text}
        type={AlertType.error}
        icon={<MAIcon name="warning" />}
        enableIcon
      />
    );

  return (
    <Alert
      type={AlertType.error}
      bgColor="red"
      title={`There are ${applicableErrors.length} errors to correct`}
      message={
        <ul aria-label="Settings Errors" className={styles.ErrorList}>
          {applicableErrors.map((error) => (
            <li key={error.name}>{error.text}</li>
          ))}
        </ul>
      }
    />
  );
};
