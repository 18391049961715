import React from 'react';
import {
  CampaignVariable,
  isCustom,
  isDuplicateStandard,
} from 'models/campaign-variable';
import styles from './styles.module.css';

export const CustomLabel: React.FC<{
  variable: CampaignVariable;
}> = ({ variable }) => {
  if (isCustom(variable.name) && isDuplicateStandard(variable.name)) {
    return <span className={styles.CustomLabel}>(custom)</span>;
  }
  return null;
};
