import React from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import { UserSelect } from 'components/users/UserSelect';
import {
  UserFieldData,
  usersToFields,
  fieldsToUsers,
} from 'models/publisher/block';
import { User } from 'models/user';
import { FieldProps } from './FieldProps';

export const Users: React.FC<FieldProps<UserFieldData[]>> = ({
  field,
  data,
  onChange,
}) => {
  const initialValue = React.useMemo(() => fieldsToUsers(data), [data]);
  const handleChange = React.useCallback(
    (users?: User | User[]) => {
      if (!users) {
        onChange(usersToFields([]));
        return;
      }
      onChange(usersToFields(!Array.isArray(users) ? [users] : users));
    },
    [onChange]
  );
  return (
    <Fieldset>
      <Field label={field.display_name}>
        <UserSelect
          initialValue={initialValue}
          onChange={handleChange}
          isMulti
        />
      </Field>
    </Fieldset>
  );
};
