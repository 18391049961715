import React from 'react';
import { Box, ClickDropdown, Popover } from 'DesignSystem/Components';
import { Icon } from 'shared/Icon';
import { Button } from 'DesignSystem/Form';
import { DateTime } from 'luxon';
import { Time } from 'shared/DateInput/Time';
import { VisuallyHidden } from 'shared/VisuallyHidden';
import { useUniqueId } from 'hooks/useUniqueId';

export type TimeSelectProps = {
  date?: DateTime;
  placeholder?: string;
  onChange: (changedTime?: DateTime) => void;
  label?: string;
};

export const TimeSelect = React.memo((props: TimeSelectProps) => {
  const { date, placeholder, onChange } = props;

  const id = `time-select__${useUniqueId()}`;
  const labelId = `${id}__label`;
  return (
    <>
      <VisuallyHidden id={labelId}>{props.label}</VisuallyHidden>
      <ClickDropdown
        id={id}
        dropdownRenderProp={() => (
          <Box width={250} style={{ cursor: 'auto', position: 'relative' }}>
            <Popover centered padding={0}>
              <Time date={date || DateTime.now()} onChange={onChange} />
            </Popover>
          </Box>
        )}
      >
        <Button
          aria-labelledby={labelId}
          input
          block
          badge={<Icon iconName="clock" />}
          label={date?.toFormat('t') || placeholder || 'HH:MM'}
        />
      </ClickDropdown>
    </>
  );
});
