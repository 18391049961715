import * as React from 'react';
import { LinkData } from 'models/publisher/block';
import { useLinkFetcher } from 'hooks/useLinkFetcher';
import { useContentImageUploader } from 'hooks/useContentImage';
import { isImageData, ImageData } from 'models/image';
import { useLinksSourceMenu } from '../hooks/useSourceMenu';
import { External } from './External';
import { FormBody } from './FormBody';

export const Editor: React.FC<{
  initial?: LinkData;
  source: ReturnType<typeof useLinksSourceMenu>;
  onChangeLink: (link: LinkData) => void;
  onUrlChange: (url: string) => void;
  onApplyCollection: (link: LinkData[]) => void;
  onError?: (message: string) => void;
  onLoading?: (loading: boolean) => void;
  onCancel: () => void;
  setIsWaitingForDebounce: (value: boolean) => void;
}> = ({
  initial,
  onError = () => {},
  onLoading = () => {},
  onChangeLink,
  onUrlChange,
  source,
  setIsWaitingForDebounce,
}) => {
  const fetcher = useLinkFetcher({ initial, type: 'link' });

  React.useEffect(() => {
    setIsWaitingForDebounce(fetcher.isWaitingForDebounce);
  }, [fetcher.isWaitingForDebounce, setIsWaitingForDebounce]);

  const {
    uploadUrl,
    uploadFile,
    isUploading,
    errors,
  } = useContentImageUploader({
    onUpload: fetcher.setImage,
  });

  const setLinkImage = React.useCallback(
    (data: ImageData | File) => {
      if (isImageData(data)) uploadUrl(data.url);
      else uploadFile(data);
    },
    [uploadUrl, uploadFile]
  );

  React.useEffect(() => {
    if (fetcher.error) {
      onError(fetcher.error);
    } else if (errors.length) {
      onError(errors[0]);
    } else onError('');
  }, [onError, fetcher.error, errors]);

  React.useEffect(() => {
    onLoading(fetcher.isLoading);
  }, [onLoading, fetcher.isLoading]);

  const isCustomImage = !fetcher.link.images.some(
    (i) => i.url === fetcher.link.image.url
  );

  const hasPlaceholderImage = initial?.image?.isPlaceholder;
  const [autoRun, setAutoRun] = React.useState('');

  React.useEffect(() => {
    if (fetcher.link.uuid !== autoRun) {
      const linkImg = fetcher.link.images[0];
      if (linkImg && (!isCustomImage || hasPlaceholderImage)) {
        setLinkImage({ ...linkImg, processed: linkImg.processed ?? false });
        setAutoRun(fetcher.link.uuid);
      }
    }
  }, [
    setLinkImage,
    hasPlaceholderImage,
    autoRun,
    fetcher.link.uuid,
    fetcher.link.images,
    fetcher,
    isCustomImage,
  ]);

  return (
    <div>
      <External
        reset={fetcher.reset}
        hasChanges={fetcher.hasChanges}
        link={fetcher.link as LinkData}
        setImage={setLinkImage}
        error={fetcher.error}
        source={source}
        setUrl={fetcher.setUrl}
        initial={initial}
        onChange={onChangeLink}
        onUrlChange={onUrlChange}
        loading={fetcher.isLoading}
      />
      <FormBody
        fetcher={fetcher}
        isCustomImage={isCustomImage}
        setImage={setLinkImage}
        isUploading={isUploading}
      />
    </div>
  );
};
