import * as React from 'react';
import { useQuery } from 'react-query';
import { EmptyBanner, MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { ItemType as MenuItemType } from 'shared/hover-dropdown/HoverIconMenu';
import { useProgram } from '../../../../../contexts/program';
import { fetchTableauWorkbookPreviewImage } from '../../../../../services/api-insights';
import { DefaultLoading } from '../../../../../shared/InfiniteList/DefaultLoading';
import styles from './workbook-banner.module.css';

export type PropType = {
  workbook: Tableau.Workbook;
  programId: number;
};

export const WorkbookBanner: React.FC<PropType> = ({ workbook }) => {
  const menuItems: MenuItemsType = React.useMemo(() => {
    const items = [] as Array<MenuItemType>;
    items.push({
      title: 'View Workbook',
      href: `workbooks/${workbook.id}/dashboards/${workbook.defaultViewId}`,
    });
    return items;
  }, [workbook]);
  const { id: programId } = useProgram();

  const { isLoading, data: previewImage } = useQuery<unknown, unknown, string>(
    ['tableau-workbooks-preview-images', programId, workbook.id],
    async () => fetchTableauWorkbookPreviewImage(programId, workbook.id)
  );

  return (
    <EmptyBanner menuItems={menuItems} hideMenuDropdown>
      <div className={styles.wrapper}>
        <div className={styles.col}>
          {isLoading ? (
            <DefaultLoading />
          ) : (
            <img
              src={`data:image/png;base64,${previewImage}`}
              alt={workbook.name}
              style={{ height: 100, padding: 15 }}
            />
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.title} title={workbook.name}>
            {workbook.name}
          </div>
        </div>
      </div>
    </EmptyBanner>
  );
};
