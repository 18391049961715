import {
  Redirect,
  redirectTo,
  RouteComponentProps,
  Router,
} from '@reach/router';
import { useTableauLibrary } from 'components/tableauEmbed/useTableau/useTableauLibrary';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import * as React from 'react';
import { WithPermission } from '../../../../shared/WithPermission';
import { WorkbooksProvider } from './contexts/WorkbooksProvider';
import { Tableau } from './pages/Tableau';
import { WorkbookListPage } from './Workbooks/WorkbookListPage';
import { useUser } from '../../../../contexts/user';

export const TableauLanding: React.FC<RouteComponentProps> = () => {
  const programId = useProgram().id;
  const { id: currentUserId } = useUser();

  const {
    data: tableauIntegrationEnabled,
    isLoading: integrationFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Insights.TableauIntegration');

  const {
    data: tableauIntegrationOverride,
    isLoading: overrideFlagLoading,
  } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration.Override',
    currentUserId
  );

  const flagsLoading = integrationFlagLoading || overrideFlagLoading;
  const ready = useTableauLibrary();

  React.useEffect(() => {
    if (
      !flagsLoading &&
      !(
        tableauIntegrationEnabled?.value || tableauIntegrationOverride?.value
      ) &&
      ready
    ) {
      redirectTo('..');
    }
  }, [
    tableauIntegrationEnabled,
    tableauIntegrationOverride,
    flagsLoading,
    ready,
  ]);

  return (
    <WithPermission permissions={['insightsPlusAccess']}>
      <Router primary={false}>
        {ready && (
          <>
            <WorkbooksProvider path="workbooks">
              <WorkbookListPage path="/" />
            </WorkbooksProvider>
            <Tableau path="workbooks/:workbook_id/dashboards/:dashboard_id" />
          </>
        )}
        <Redirect from="/" to="workbooks" noThrow />
      </Router>
    </WithPermission>
  );
};
