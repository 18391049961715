import React, { ReactNode, ReactNodeArray } from 'react';
import {
  MenuDivider,
  MenuHeader,
  MenuItem,
  MenuRadioGroup,
  SubMenu,
} from '@szhsin/react-menu';
import {
  NestableSimpleMenuType,
  NestableRadioMenuType,
  NestableCheckboxMenuType,
  NestableMenuCheckboxStateType,
  NestableMenuOptionItemType,
  NestableMenuTypesEnum,
  NestableMenuItemType,
  NestableMenuDividerType,
  NestableMenuHeaderType,
  NestableSubmenuType,
  NestableMenuType,
} from 'shared/NestableMenu/types';
import {
  isMenuDividerType,
  isMenuHeaderType,
  isRadioMenuType,
  isSimpleMenuType,
  isSubMenuType,
} from 'shared/NestableMenu/utils';

/**
 * This file contains the function components to render the NestableMenu
 * Note that only `renderMenu()` should be used. All other functions should be internal
 * Note that renderMenu() should be invoked with a generic type corresponding
 * to the type of the `value` attribute of the menu option
 *
 * Note that the menu components are rendered recursively, so there is a need
 * to utilize traditional javascript function hoisting.
 * Because of this, we should NOT separate these components to different files
 * since that will create cyclic imports
 *
 * Note that the function components are named and utilized like normal functions.
 * The `react-menu` library forbids using wrapped components. However, we can
 * are allowed to return the library components if the wrapped components are
 * invoked like normal functions. As such, all of the wrapped components below
 * are invoked like normal functions, although for all intents and purposes they
 * are function components (ie you can use react hooks and return jsx).
 * For semantics, we will let them return a common `NonFunctionComponentReturnType`.
 *
 * Note that the radio menu and checkbox menu functions have capitalized names
 * this is a requirement for using react hooks within that component
 *
 * eg.. This will not work (`react-menu` will raise a console warning)
 *   const MyMenuItem = (text) => <MenuItem>{text}</MenuItem>
 *   const App = () => (
 *     <Menu>
 *        <MyMenuItem text="hello" />
 *     </Menu>
 *   )
 *
 *   ... But This will work
 *    const myMenuItem = (text) => <MenuItem>{text}</MenuItem>
 *    const App = () => (
 *      <Menu>
 *           {myMenuItem('hello')}
 *           {myMenuItem('world')}
 *      </Menu>\
 *
 * */
type NonFunctionComponentReturnType = ReactNode | ReactNodeArray | null;

// We enable function hoisting to allow for recursive rendering of menu components
/* eslint-disable @typescript-eslint/no-use-before-define */

// rendering the menu. can be called recursively by the submenu renderer
export function renderMenu<TValue>(
  menuData: NestableMenuType<TValue>
): NonFunctionComponentReturnType {
  if (isSimpleMenuType<TValue>(menuData)) return renderSimpleMenu(menuData);
  if (isRadioMenuType<TValue>(menuData)) return RadioMenu(menuData);
  return CheckboxMenu(menuData);
}

function renderSubmenu<TValue>(
  submenuData: NestableSubmenuType<TValue>
): NonFunctionComponentReturnType {
  return (
    <SubMenu label={submenuData.label} key={submenuData.key}>
      {renderMenu<TValue>(submenuData.subMenu)}
    </SubMenu>
  );
}

function renderMenuDivider({
  key,
}: NestableMenuDividerType): NonFunctionComponentReturnType {
  return <MenuDivider key={key} />;
}

function renderMenuHeader({
  menuHeaderLabel,
  key,
}: NestableMenuHeaderType): NonFunctionComponentReturnType {
  return <MenuHeader key={key}>{menuHeaderLabel}</MenuHeader>;
}

// called by the simple/radio/checkbox menus to generate the menu item
type MenuItemPropsType<TValue> = {
  menuType: NestableMenuTypesEnum;
  item: NestableMenuItemType<TValue>;
  onSelect?: (val: TValue) => void;
  onSelectCheckboxItem?: (val: NestableMenuOptionItemType<TValue>) => void;
  isChecked?: boolean; // only applicable to checkboxes
};
function renderMenuItem<TValue>({
  item,
  menuType,
  onSelect,
  onSelectCheckboxItem,
  isChecked,
}: MenuItemPropsType<TValue>): NonFunctionComponentReturnType {
  if (isSubMenuType<TValue>(item)) return renderSubmenu<TValue>(item);
  if (isMenuHeaderType(item)) return renderMenuHeader(item);
  if (isMenuDividerType(item)) return renderMenuDivider(item);
  return renderOptionItem({
    item,
    onSelect,
    onSelectCheckboxItem,
    menuType,
    isChecked,
  });
}

function renderSimpleMenu<TValue>({
  simpleMenu,
}: NestableSimpleMenuType<TValue>): NonFunctionComponentReturnType {
  const { items, onSelect } = simpleMenu;
  const menuType = 'simple';

  return items.map((item) => {
    return renderMenuItem({ item, menuType, onSelect });
  });
}

function RadioMenu<TValue>({
  radioMenu,
}: NestableRadioMenuType<TValue>): NonFunctionComponentReturnType {
  const { items, onSelect } = radioMenu;
  const menuType = 'radio';
  const [radioValue, setRadioValue] = React.useState<TValue>();

  const handleSelect = (newValue: TValue) => {
    setRadioValue(newValue);
    onSelect(newValue);
  };

  // NOTE: a radio menu can only render the <MenuItem>
  // supplying other types (submenus, dividers, headers) will raise an error in typescript
  // and also raise a runtime warning in the console from `react-menu` library
  return (
    <MenuRadioGroup value={radioValue}>
      {items.map((item) => {
        return renderOptionItem({
          item,
          onSelect: handleSelect,
          menuType,
        });
      })}
    </MenuRadioGroup>
  );
}

function CheckboxMenu<TValue>({
  checkboxMenu,
}: NestableCheckboxMenuType<TValue>): NonFunctionComponentReturnType {
  const { items, onSelect } = checkboxMenu;
  const menuType = 'checkbox';

  // need to manually track a copy of the "previous" checked values
  const prevCheckedValues = React.useRef<
    NestableMenuCheckboxStateType<TValue>
  >();

  // a reducer is used to update the map of checked items
  // if an item is found in the map, we remove it
  // if not, we add it in
  // we use the `prevCheckedValues` ref to manually invoke the `onSelect()` callback
  // this is necessary because this reducer can be invoked multiple times
  // on selection event with the same value for some reason
  const [allValues, setValue] = React.useReducer(
    (
      state: NestableMenuCheckboxStateType<TValue>,
      newItem: NestableMenuOptionItemType<TValue>
    ) => {
      // create a new map based on the old state with a loop to support IE11
      // because new Map([iterable]) is not supported
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#browser_compatibility
      const newState = new Map();
      state.forEach((value: TValue, key: string) => newState.set(key, value));

      if (newState.has(newItem.key)) {
        newState.delete(newItem.key);
      } else {
        newState.set(newItem.key, newItem.value);
      }

      if (prevCheckedValues.current?.size !== newState.size) {
        onSelect(newState, newItem.value);
        prevCheckedValues.current = newState;
      }

      return newState;
    },
    new Map()
  );

  return items.map((item) => {
    const isChecked = allValues.has(item.key);
    return renderMenuItem({
      item,
      onSelectCheckboxItem: setValue,
      menuType,
      isChecked,
    });
  });
}

// The option item component is utilized by the simple/radio/checkbox menus
// the simple/radio menus invoke the select handlers with the option value
// the checkbox menu invokes the select handler with the option item
// that is because the checkbox menu needs access to the item key
type NestableMenuOptionItemPropsType<TValue> = {
  menuType: NestableMenuTypesEnum;
  item: NestableMenuOptionItemType<TValue>;
  onSelect?: (val: TValue) => void;
  onSelectCheckboxItem?: (val: NestableMenuOptionItemType<TValue>) => void;
  isChecked?: boolean;
};

function renderOptionItem<TValue>({
  item,
  onSelect,
  onSelectCheckboxItem,
  isChecked,
  menuType,
}: NestableMenuOptionItemPropsType<TValue>): NonFunctionComponentReturnType {
  const optionItem = (
    <div className="rc-menu__item--wrapper">
      <div>{item.label}</div>
      <div className="rc-menu__item--description">{item.description}</div>
    </div>
  );

  return menuType === 'checkbox' ? (
    <MenuItem
      value={item.value}
      onClick={() => onSelectCheckboxItem && onSelectCheckboxItem(item)}
      key={item.key}
      checked={isChecked}
      type="checkbox"
    >
      {optionItem}
    </MenuItem>
  ) : (
    <MenuItem
      value={item.value}
      // the event type is custom-defined in the plugin library and cannot be exported
      onClick={(e) => onSelect && onSelect(e.value)}
      key={item.key}
    >
      {optionItem}
    </MenuItem>
  );
}
