import { useEffect, useState } from 'react';
import { useDelayedAction } from './useDelayedAction';

export function useAutosave(
  callback: () => void | Promise<void>,
  isSavePending: boolean
): {
  scheduleSave: (afterSaveCallback?: () => void) => void;
  cancelSave: () => void;
  autosaveScheduled: boolean;
} {
  const [hasNextSave, setHasNextSave] = useState(false);
  const [autosaveScheduled, setAutosaveScheduled] = useState(false);
  const saveAction = useDelayedAction(callback, 10 * 1000);

  // handle any straggler autosaves that need to be made
  useEffect(() => {
    if (!isSavePending && hasNextSave) {
      saveAction.schedule(() => setAutosaveScheduled(false));
      setHasNextSave(false);
    }
  }, [isSavePending, setHasNextSave, hasNextSave, saveAction]);

  function scheduleSave(afterSaveCallback?: () => void) {
    setAutosaveScheduled(true);
    // if we have a pending save do not attempt another save
    // instead allow the hook to make a new request once the current save is complete
    if (isSavePending) {
      setHasNextSave(true);
    } else {
      saveAction.schedule(() => {
        setAutosaveScheduled(false);
        if (afterSaveCallback) afterSaveCallback();
      });
      setHasNextSave(false);
    }
  }

  return {
    cancelSave: saveAction.cancel,
    scheduleSave,
    autosaveScheduled,
  };
}
