import React from 'react';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { Initiative } from 'models/initiative';
import { useFlashMessage } from 'contexts/flasher';
import {
  useArchiveInitiative,
  useDestroyInitiative,
  useUnarchiveInitiative,
} from 'hooks/initiatives';
import { useProgram } from 'contexts/program';
import styles from './initiatives.module.css';
import { initiativeToThumbnail, initiativeToMenuItems } from './mappings';

type PropsType = {
  initiative: Initiative;
  index: number;
};

export const InitiativeBanner: React.FC<PropsType> = ({
  initiative,
  index,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();
  const { destroy } = useDestroyInitiative(programId, {
    onSuccess: () =>
      setFlashMessage({
        severity: 'info',
        message: `Initiative deleted successfully!`,
      }),
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Error happened while deleting the initiative',
      }),
  });

  const { archive } = useArchiveInitiative(programId, {
    onSuccess: () =>
      setFlashMessage({
        severity: 'info',
        message: `Initiative archived successfully!`,
      }),
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Error happened while archiving the initiative',
      }),
  });

  const { unarchive } = useUnarchiveInitiative(programId, {
    onSuccess: () =>
      setFlashMessage({
        severity: 'info',
        message: `Initiative unarchived successfully!`,
      }),
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Error happened while unarchiving the initiative',
      }),
  });
  const thumbnail = initiativeToThumbnail(initiative, index);
  const menuItems = initiativeToMenuItems(
    programId,
    initiative,
    () => destroy(initiative),
    () => archive(initiative),
    () => unarchive(initiative)
  );
  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`${initiative.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{initiative.name}</div>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>Status</span>
          <span className={styles.stats}>
            {initiative.isActive ? 'Active' : 'Archived'}
          </span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>POSTS</span>
          <span className={styles.stats}>{initiative.postsCount}</span>
        </div>
      </div>
    </EmptyBanner>
  );
};
