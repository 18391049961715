import React, { useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { WithPermission } from 'shared/WithPermission';
import { Icon } from 'shared/Icon';
import { Section } from 'DesignSystem/Form';
import { Sortable } from 'DesignSystem/Components';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { PERMISSIONS, TITLE } from '../constants';
import { AttributeSelect } from './AttributeSelect';
import useSearchableAttributes from './hooks/useSearchableAttributes';
import styles from './styles.module.css';

export const SearchAttributes: React.FC<RouteComponentProps> = () => {
  const {
    loading,
    selected,
    available,
    isChanged,
    handleChange,
    handleSave,
  } = useSearchableAttributes();

  const handleDelete = useCallback(
    (e) => {
      const { value } = e.currentTarget;
      handleChange(selected.filter((s) => s.name !== value));
    },
    [selected, handleChange]
  );

  return (
    <WithPermission permissions={PERMISSIONS}>
      <FormPage
        title="Search Attributes"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: TITLE, to: '..' },
          { label: 'Edit Search Attributes' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <SVGIcon name="SaveSuccess" />,
            disabled: selected.length === 0 || loading || !isChanged,
            onClick: handleSave,
          },
        ]}
      >
        <Section
          title="Search Attributes"
          description="Select up to 3 attributes. Results will be sorted in the order selected."
        >
          <div className={styles.Section}>
            <div className={loading ? styles.ContentHidden : styles.Content}>
              <AttributeSelect
                options={available}
                value={selected}
                onChange={handleChange}
              />
              <Sortable
                items={selected}
                idAttr="name"
                handleClass={`.${styles.ItemContent}`}
                className={styles.SelectedAttributes}
                onSort={handleChange}
                Item={({ item }) => (
                  <div className={styles.Item}>
                    <div className={styles.ItemContent}>{item.label}</div>
                    <button
                      type="button"
                      className={styles.Delete}
                      value={item.name}
                      onClick={handleDelete}
                    >
                      <Icon iconName="Times" iconType="SVG" size={12} />
                    </button>
                  </div>
                )}
              />
            </div>
            <div className={loading ? styles.SpinnerVisible : styles.Spinner}>
              <LoadingSpinner />
            </div>
          </div>
        </Section>
      </FormPage>
    </WithPermission>
  );
};
