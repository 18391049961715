import { useProgram } from 'contexts/program';
import { useEffect, useMemo, useState } from 'react';
import type { Content } from '../models/content';
import {
  CampaignResultsData,
  fetchCampaignResultsData,
} from '../services/api-insights';
import { useTopicSubscribersAudienceWithLoading } from './audience';
import { combinedAudiencesQuery } from '../models/audience';
import { Settings } from '../models/publisher/settings';

interface IUseCampaignResults {
  results: null | CampaignResultsData;
  isLoading: boolean;
}

export const useCampaignResults = (
  content: Content,
  settings: Settings
): IUseCampaignResults => {
  const { id: programId } = useProgram();
  const [results, setResults] = useState<CampaignResultsData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { audiences: audienceFromSettings, contentTopics } = settings;

  const {
    data: topicAudiences,
    isLoading: audienceLoading,
  } = useTopicSubscribersAudienceWithLoading(programId, contentTopics);

  const query = useMemo(() => {
    if (audienceLoading) {
      return undefined;
    }
    const allAudiences = [...topicAudiences, ...audienceFromSettings];

    return combinedAudiencesQuery(allAudiences);
  }, [audienceLoading, topicAudiences, audienceFromSettings]);

  useEffect(() => {
    if (loaded || isLoading || query === undefined) {
      return;
    }

    setIsLoading(true);

    fetchCampaignResultsData({
      programId,
      contentId: content.id,
      query,
    })
      .then((d) => {
        setResults(d.data);
        setIsLoading(false);
        setLoaded(true);
      })
      .catch(() => {
        setIsLoading(false);
        setLoaded(true);
      });
  }, [content, isLoading, loaded, programId, setIsLoading, query]);

  return {
    results,
    isLoading,
  };
};
