import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useSignInConfigQuery } from 'hooks/program';
import { Section, Subsection } from 'DesignSystem/Form';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import * as Text from 'DesignSystem/Typography';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TextInput } from 'shared/TextInput';
import { SignInConfig } from 'models/signInConfig';
import { MAIcon } from 'shared/MAIcon';
import { ConfirmModal } from 'DesignSystem/Components/Modal';
import { UserNameCheckbox } from './UserNameCheckbox';
import { KnownEmailCheckbox } from './KnownEmailCheckbox';
import { OtherEmailCheckbox } from './OtherEmailCheckbox';
import { LoginPageLabelInput } from './LoginPageLabelInput';
import { useActions } from '../useActions';
import styles from '../styles.module.css';

export const UserAccessFirstup: React.FC<RouteComponentProps> = () => {
  const { data: signInConfig, errorMessage } = useSignInConfigQuery();
  const [savedConfig, setSavedConfig] = React.useState<SignInConfig>();
  const [config, setConfig] = React.useState<SignInConfig>();
  const [enabled, setEnabled] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<
    Record<string, string | undefined>
  >({});
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const { update } = useActions(setSavedConfig);

  React.useEffect(() => {
    if (signInConfig) {
      setSavedConfig(signInConfig);
      setConfig(signInConfig);
    }
  }, [signInConfig]);

  React.useEffect(() => {
    if (
      enabled &&
      !config?.enabled.username &&
      !config?.enabled.knownEmail &&
      !config?.enabled.otherEmail
    ) {
      setErrors({
        options: 'Please select at least one sign-in option',
      });
      return;
    }

    if (errors.options) {
      setErrors({ ...errors, options: undefined });
    }
    // eslint-disable-next-line
  }, [config, enabled]);

  function disableSave() {
    if (!config || !savedConfig) {
      return true;
    }

    if (JSON.stringify(config) === JSON.stringify(savedConfig)) {
      return true;
    }

    return Object.keys(errors).some((key) => {
      return errors[key] && key !== 'toggle';
    });
  }

  const title = (
    <div className={styles.Title}>
      <span>Firstup Sign-in and Registration</span>
      {(signInConfig?.defaultSignInMethod === 'usernameOrEmail' ||
        (enabled && !signInConfig?.enabled.sso)) && (
        <span className={styles.DefaultBill}>Default</span>
      )}
    </div>
  );

  const submit = () => {
    if (isConfirmOpen) setIsConfirmOpen(false);
    const data = { ...config };
    if (!enabled && data?.enabled?.sso) {
      data.defaultSignInMethod = 'sso';
    }
    if (enabled && !data?.enabled?.sso) {
      data.defaultSignInMethod = 'usernameOrEmail';
    }
    update(data as SignInConfig);
  };

  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        title="User Access"
        description="Configure how workers will access your program"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'User Access', to: '..' },
          { label: 'Firstup' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <SVGIcon name="SaveSuccess" />,
            onClick: () => {
              if (!enabled) {
                setIsConfirmOpen(true);
                return;
              }
              submit();
            },
            disabled: disableSave(),
          },
        ]}
      >
        {errorMessage && <>{errorMessage}</>}

        {config && (
          <Section
            title={title}
            description="Set up community standard community sign-in options. NOTE: Enabling or disabling Username or Email methods will only affect new registrations (existing users will still be able to sign-in with their chosen method)."
            className={styles.MainSection}
          >
            <Subsection title="">
              <div className={styles.EnabledToggle}>
                <div>
                  <Text.Subheading block bold>
                    Firstup Sign-in and Registration
                  </Text.Subheading>
                  <Text.Body block color={Text.color.gray70}>
                    Enable to select and configure at least one standard sign-in
                    option.
                  </Text.Body>
                </div>
                <FlipSwitch
                  onChange={(value) => {
                    if (enabled && !signInConfig?.enabled.sso) {
                      setErrors({
                        ...errors,
                        toggle: `At least one sign-in method must be enabled in order for users to access the community.
                          In order to disable one sign-in method,
                          the other method must first be set up and enabled.`,
                      });
                      return;
                    }
                    setEnabled(!enabled);
                    if (!value) {
                      setConfig({
                        ...config,
                        enabled: {
                          ...config.enabled,
                          username: false,
                          knownEmail: false,
                          otherEmail: false,
                        },
                      });
                    }
                  }}
                  on={enabled}
                />
              </div>
              {errors.toggle && (
                <div className={styles.Error}>
                  <MAIcon name="warning" />
                  {errors.toggle}
                </div>
              )}
            </Subsection>
            {enabled && (
              <>
                <Subsection
                  title="Access Settings"
                  description="Please select at least one sign-in option for users to access the community."
                >
                  <UserNameCheckbox config={config} setConfig={setConfig} />
                  <KnownEmailCheckbox
                    config={config}
                    setConfig={setConfig}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <OtherEmailCheckbox config={config} setConfig={setConfig} />
                  {errors.options && (
                    <div className={styles.Error}>
                      <MAIcon name="warning" />
                      {errors.options}
                    </div>
                  )}
                </Subsection>

                <LoginPageLabelInput config={config} setConfig={setConfig} />

                <Subsection
                  title="Field Helper Text"
                  description="Customize the helper text in the input field. (ex. Username)"
                >
                  <TextInput
                    className={styles.TextField}
                    value={config.labels.localPlaceholder}
                    onChange={(value) => {
                      setConfig({
                        ...config,
                        labels: {
                          ...config.labels,
                          localPlaceholder: value,
                        },
                      });
                    }}
                    placeholder="Username/Email"
                  />
                </Subsection>
              </>
            )}
          </Section>
        )}
      </FormPage>
      {isConfirmOpen && (
        <ConfirmModal
          title="Changes to sign-in method"
          confirmLabel="Disable"
          onConfirm={submit}
          onCancel={() => {
            setIsConfirmOpen(false);
          }}
        >
          Disabling this sign-in method will automatically set the other sign-in
          method as the default method, and it will be the only method that
          users will be able to use to sign-in to the community.
        </ConfirmModal>
      )}
    </WithPermission>
  );
};
