import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import {
  ContentInsights,
  MetricsSummary,
  ResultsSummary,
  fetchContentInsights,
  fetchMetricsSummary,
  fetchResultsSummary,
} from 'services/api-content-insights';
import { QueryResponse } from './common';

type InsightsProps = {
  contentId: number;
  startDate: DateTime;
  endDate?: DateTime;
  timezone: string;
};

type SummaryProps = {
  contentId: number;
};

export const useContentInsightsQuery = (
  props: InsightsProps
): QueryResponse<ContentInsights> => {
  const { startDate, endDate } = props;
  const { id: programId } = useProgram();
  const unixStartDate = Math.round(startDate.toMillis() / 1000);
  const unixEndDate = Math.round(
    (endDate ? endDate.toMillis() : DateTime.now().toUTC().toMillis()) / 1000
  );
  const { isLoading, error, data } = useQuery<ContentInsights, Error>(
    ['content-insights', { contentId: props.contentId, programId }],
    () =>
      fetchContentInsights({
        ...props,
        unixStartDate,
        unixEndDate,
        programId,
      }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useMetricsSummaryQuery = (
  props: SummaryProps
): QueryResponse<MetricsSummary> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<MetricsSummary, Error>(
    ['metrics-summary', { contentId: props.contentId, programId }],
    () =>
      fetchMetricsSummary({
        ...props,
        programId,
      }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useResultsSummaryQuery = (
  props: SummaryProps
): QueryResponse<ResultsSummary> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<ResultsSummary, Error>(
    ['results-summary', { contentId: props.contentId, programId }],
    () =>
      fetchResultsSummary({
        ...props,
        programId,
      }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
