import camelcaseKeys from 'camelcase-keys';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type ImportConfigurationData = {
  dobFormat: string;
};

export const fetchImportConfigurationDobFormat = async (
  programId: number
): Promise<ImportConfigurationData> => {
  const url = `${apiRoot}/samba/programs/${programId}/import_configurations/dob_format`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching import configuration: ${response.status}`);
};
