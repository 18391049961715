import React from 'react';
import { VariableSizeList } from 'react-window';
import {
  DEFAULT_DROPDOWN_WIDTH,
  FilterDropdownStructureContext,
} from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import { ListDropdownContext } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { SelectableListItem } from 'App/Program/Main/Insight/components/Filters/shared/body/SelectableListItem';
import { BaseListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/BaseListDropdown';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';

export const WindowedListDropdown: React.FC = () => {
  const { infiniteListBodyHeight } = React.useContext(
    FilterDropdownStructureContext
  );
  const { filter } = React.useContext(FilterDropdownContext);
  const {
    items,
    dispatchNewlySelectedItem,
    itemIsSelected,
    getItemHeight,
  } = React.useContext(ListDropdownContext);
  const { setShowReset } = React.useContext(ReportContext);

  return (
    <BaseListDropdown>
      <VariableSizeList
        itemSize={getItemHeight}
        height={infiniteListBodyHeight}
        itemCount={items.length}
        width={DEFAULT_DROPDOWN_WIDTH}
      >
        {({ index: apparentIndex, style }) => {
          const item = items[apparentIndex];
          return (
            <SelectableListItem
              onClick={() => {
                setShowReset(true);
                dispatchNewlySelectedItem({
                  action: 'toggle',
                  item,
                });
              }}
              rowType={filter.allowMultiple ? 'checkbox' : 'radio'}
              isSelected={itemIsSelected(item)}
              label={item.label}
              style={style}
              innerListItemStyle={{ height: getItemHeight(apparentIndex) }}
            />
          );
        }}
      </VariableSizeList>
    </BaseListDropdown>
  );
};
