import * as React from 'react';
import { useQueryStringState } from './useQueryStringState';

type FilterState = Array<string>;

export function cast(value: unknown): FilterState {
  if (!Array.isArray(value)) {
    return [];
  }
  return value.filter((item) => typeof item === 'string');
}

type ReturnType = [FilterState, (newSelecteds: FilterState) => void];

export const useFilterDropdownQueryString = (
  key: string,
  defaultValue?: string[]
): ReturnType => {
  const [initialSelectedIds, navigateSelectedIds] = useQueryStringState({
    key,
    cast,
    defaultValue,
  });

  const [selectedIds, setSelectedIds] = React.useState<FilterState>(
    initialSelectedIds
  );

  const update = React.useCallback(
    (newSelectedIds: FilterState) => {
      setSelectedIds(newSelectedIds);
      navigateSelectedIds(newSelectedIds);
    },
    [navigateSelectedIds]
  );

  return [selectedIds, update];
};

type props<T> = {
  key: string;
  defaultValue?: T;
  boolean?: boolean;
};

export const useGenericFilterDropdownQueryString = <T>({
  key,
  defaultValue,
  boolean = false,
}: props<T>): [T, (newValue: T) => void] => {
  const [initialValue, navigateValue] = useQueryStringState<T>({
    key,
    defaultValue,
    boolean,
  });

  const [value, setValue] = React.useState<T>(initialValue);

  const update = React.useCallback(
    (newValue: T) => {
      setValue(newValue);
      navigateValue(newValue);
    },
    [navigateValue]
  );

  return [value, update];
};
