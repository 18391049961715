import React from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import { EmailAliasSelect } from 'components/email-alias/EmailAliasSelect';
import { useProgram } from 'contexts/program';
import { EmailAlias } from 'models/email-alias';
import { User } from 'models/user';
import { useEmailAliasByIdQueries } from 'hooks/email-alias';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { IdentityScope } from 'models/identity';
import { useHasNoRestrictions } from './hooks/roles';

type PropsType = {
  selectedIds: string[];
  onChange: (value: IdentityScope[]) => void;
  role: User['role'];
};

export const EmailSendersSelector: React.FC<PropsType> = ({
  selectedIds,
  onChange,
  role,
}) => {
  const { id: programId } = useProgram();
  const hasNoRestrictions = useHasNoRestrictions(`${role}`);

  const emailQueries = useEmailAliasByIdQueries(programId, selectedIds);

  const [selectedEmails, setSelectedEmails] = React.useState<EmailAlias[]>([]);

  React.useEffect(() => {
    if (emailQueries.every((q) => !!q.data)) {
      // eslint-disable-next-line
      // @ts-ignore
      setSelectedEmails(emailQueries.map((q) => q.data));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(emailQueries)]);

  function onSelectedEmailsChange(emails: EmailAlias[]) {
    setSelectedEmails(emails);
    const scopes: IdentityScope[] = emails.map((email) => {
      return { type: 'sender_account', name: `sender_account:${email.id}` };
    });
    onChange(scopes);
  }

  return (
    <Fieldset>
      <div className={fieldsetStyles.fieldGroupContainer}>
        <Field label="Email Aliases">
          {hasNoRestrictions ? (
            <div>Selected role has access to all email aliases</div>
          ) : (
            <EmailAliasSelect
              selectedEmails={selectedEmails}
              onChange={onSelectedEmailsChange}
              placeholder="Search email aliases"
            />
          )}
        </Field>
      </div>
    </Fieldset>
  );
};
