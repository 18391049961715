import * as React from 'react';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';

type Page = 'fonts' | 'templates' | 'journey_templates';

// Note to the developer...
//
//   Prior to the new permissions, our permissions object
// was guaranteed to have been fetched by this point.
//
//   Not by design, just by the natural order of checking that
// had to have happened by the time it reaches this nested
// state of the router.
//
//   As we moved to new permissions, the asynchronous checks
// for feature flags broken the assumption that the app knows
// all it needs to know by the time it reaches the router.
//
//   This logic was placed at the top of the router component
// for the library page. The logic needed to either:
// 1) provide fallback pages for some routes, while determining
//    the permissions and access
// 2) move the permissions into the page
//
//   The second seems like the obviously better answer, since
// the page is the area that contains the domain logic, including
// access requirements. If we had some declarative, eagerly loading
// permissions framework, maybe we could move it cleanly into
// the router, but we don't yet.
//
//   So the logic in the router component was moved here, and the
// two components that use it can figure out how to use the
// information provided by it.

export function useLibraryAccess(): {
  ready: boolean;
  pages: { to: Page; label: string }[];
  has(to: Page): boolean;
} {
  const { id: programId } = useProgram();
  const fontsPageEnabledFlag = useFeatureFlagsQuery(
    programId,
    'Studio.Library.FontsPage'
  ).data;
  const fontsPageEnabled = fontsPageEnabledFlag?.value;

  const {
    permissions: { libraryFontsAccess, manageTemplateAccess },
  } = usePermissions();

  const hasFontsPageAccess = React.useMemo(() => {
    return fontsPageEnabled && libraryFontsAccess;
  }, [fontsPageEnabled, libraryFontsAccess]);

  const pages = React.useMemo(() => {
    const wip = [];
    if (manageTemplateAccess)
      wip.push({ to: 'templates', label: 'Templates' } as const);
    if (hasFontsPageAccess) wip.push({ to: 'fonts', label: 'Fonts' } as const);
    /* uncomment this when the journey templates have been seeded in scope of FE-7003 */
    // if (journeysEnabled && journeysEnabled.value && journeysAccess)
    //   wip.push({
    //     to: 'journey_templates',
    //     label: 'Journey Templates',
    //   } as const);
    return wip;
  }, [hasFontsPageAccess, manageTemplateAccess]);

  const ready = !!fontsPageEnabled;
  const has = React.useCallback(
    (href: string) => !!pages.find(({ to }) => to === href),
    [pages]
  );

  return { pages, ready, has };
}
