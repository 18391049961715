import React from 'react';
import { LitmusRole } from 'models/role';
import { Section } from 'DesignSystem/Form';
import styles from '../roles.module.css';
import formStyles from '../form.module.css';
import { useRolePermissionsCount } from '../useRolePermissionsCount';

type PropsType = {
  role: LitmusRole;
  programId: number;
};

type RowProps = {
  title: string;
  body?: string;
};

const Row: React.FC<RowProps> = ({ title, body }) => {
  return (
    <div className={styles.roleDetailRow}>
      <span className={styles.roleDetailTitle}>{title}</span>
      <span className={styles.roleDetailBody}>{body || 'N/A'}</span>
    </div>
  );
};

export const General: React.FC<PropsType> = ({ role, programId }) => {
  const { count: permissionsCount } = useRolePermissionsCount(role);

  return (
    <Section
      title="General"
      href={
        !role.isSystemRole
          ? `/${programId}/configure/roles/${role.id as number}/edit/general`
          : ''
      }
      className={styles.section}
    >
      <div className={formStyles.SectionBody}>
        <Row title="Name" body={role.title} />
        <Row title="Description" body={role.description} />
        <Row title="Permissions" body={permissionsCount.toString()} />
      </div>
    </Section>
  );
};
