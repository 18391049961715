import React from 'react';
import { useUniqueId } from 'hooks/useUniqueId';
import { LinkData, SocialData } from 'models/publisher/block';
import { Draggable } from 'react-beautiful-dnd';
import { Preview } from './Preview';

type PropsType = {
  index: number;
  link: LinkData | SocialData;
  onRemove: () => void;
  onClick: () => void;
};

export const DraggablePreview: React.FC<PropsType> = ({
  index,
  link,
  onRemove,
  onClick,
}) => {
  const uniqueId = useUniqueId();

  return (
    <Draggable draggableId={uniqueId} index={index}>
      {(dragProvided, { isDragging }) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          <Preview
            isDragging={isDragging}
            link={link}
            onEdit={onClick}
            onRemove={onRemove}
          />
        </div>
      )}
    </Draggable>
  );
};
