import { useProgram } from 'contexts/program';
import React from 'react';
import {
  QueryStatus,
  MutationFunction,
  UseBaseQueryOptions,
  useMutation,
  UseQueryOptions,
  useQuery,
  UseMutateFunction,
  useQueryClient,
} from 'react-query';
import { QueryState } from 'react-query/types/core/query';
import {
  AthenaOutputResponse,
  fetchAthenaOutput,
  pollAthenaOutput,
} from 'services/api-ai-chat-completion';
import { QueryResponseWithStatus } from './common';

const statusPriority: { [key in QueryStatus]: number } = {
  loading: 4,
  error: 3,
  success: 2,
  idle: 1,
};

/**
 * Merges multiple React Query statuses and returns the status with the highest priority.
 * @param statuses - An array of React Query statuses.
 * @returns The React Query status with the highest priority.
 */
export function mergeReactQueryStatuses(
  ...statuses: QueryStatus[]
): QueryStatus {
  let maxPriority = 1; // Default to the lowest priority
  let maxStatus: QueryStatus = 'idle';

  statuses.forEach((status) => {
    if (statusPriority[status] > maxPriority) {
      maxPriority = statusPriority[status];
      maxStatus = status;
    }
  });

  return maxStatus;
}

type UseAthenaTaskOptions<TData, TVariables> = {
  createTaskFn: MutationFunction<TData, TVariables>;
  outputIdSelector: (data: TData) => string;
  /**
   * Output response strategy to use.
   * - `poll` - Polls for the output until it is complete.
   * - `fetch` - Fetches the output once and does not poll.
   */
  outputStrategy?: 'poll' | 'fetch';
} & Pick<
  UseBaseQueryOptions<AthenaOutputResponse, unknown, AthenaOutputResponse>,
  'onSuccess'
>;

type UseAthenaTaskReturn<TData = unknown, TVariables = void> = {
  createTask: UseMutateFunction<TData, unknown, TVariables, unknown>;
  data: AthenaOutputResponse | undefined;
  status: QueryStatus;
  error: unknown;
  outputId: AthenaOutputResponse['id'] | '';
  reset: () => void;
};

/**
 * A React Query hook that handles creating an Athena task and polling for the output as a single operation.
 * The returned status is the highest priority status of the two operations.
 * Data is the output response.
 *
 * @param UseAthenaTaskOptions - Options for the hook.
 * @param UseAthenaTaskOptions.createTaskFn - The mutation function that creates the Athena task.
 * @param UseAthenaTaskOptions.outputIdSelector - A function that extracts the output ID field from the task creation response.
 * @param UseAthenaTaskOptions.onSuccess - This callback will fire any time the task query successfully fetches new data. *Note:* useQuery onSuccess is generally considered an anti-pattern and is deprecated in v5, see [here](https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose) for more info.
 * @param UseAthenaTaskOptions.outputDelivery - Output delivery strategy to use. Defaults to `poll`.
 */
export function useAthenaTask<TData = unknown, TVariables = void>({
  createTaskFn,
  outputIdSelector,
  onSuccess,
  outputStrategy = 'poll',
}: UseAthenaTaskOptions<TData, TVariables>): UseAthenaTaskReturn<
  TData,
  TVariables
> {
  const programId = useProgram().id;
  const [outputId, setOutputId] = React.useState('');
  const {
    mutate: createTask,
    status: chatCompletionStatus,
    error: mutationError,
    reset,
  } = useMutation(createTaskFn, {
    onSuccess: (mutationResponse) => {
      setOutputId(outputIdSelector(mutationResponse));
    },
  });

  const pollingFn =
    outputStrategy === 'poll'
      ? () => pollAthenaOutput({ programId, outputId })
      : // replace with real fetcher
        () => fetchAthenaOutput(programId, outputId);

  const {
    data: athenaOutputData,
    status: taskOutputQueryStatus,
    error: queryError,
  } = usePollAthenaOutput(outputId, {
    queryFn: pollingFn,
    enabled: Boolean(outputId),
    retry: false,
    onSuccess,
  });

  const mergedStatuses = mergeReactQueryStatuses(
    chatCompletionStatus,
    taskOutputQueryStatus
  );

  const hasCompleted =
    mergedStatuses === 'success' &&
    Boolean(athenaOutputData?.status === 'complete');

  if (hasCompleted && outputId) {
    setOutputId('');
  }

  return {
    createTask,
    data: athenaOutputData,
    status: mergedStatuses,
    error: mutationError || queryError,
    outputId,
    reset,
  };
}

const athenaOutputQueryKeys = {
  all: ['athena-outputs'] as const,
  output: (outputId: string) =>
    [...athenaOutputQueryKeys.all, outputId] as const,
};

type UsePollAthenaOutputOptions = Pick<
  UseQueryOptions<AthenaOutputResponse, unknown, AthenaOutputResponse>,
  'enabled' | 'onSuccess' | 'retry' | 'queryFn'
>;

/**
 * Polls for an Athena output until it is complete.
 * @param outputId - The ID of the output to poll.
 * @param queryOptions - Options to pass to useQuery.
 */
export function usePollAthenaOutput(
  outputId: string,
  queryOptions?: UsePollAthenaOutputOptions
): QueryResponseWithStatus<AthenaOutputResponse> & { error: Error | null } {
  const programId = useProgram().id;

  const { isLoading, error, data, status } = useQuery({
    queryKey: [...athenaOutputQueryKeys.output(outputId)],
    queryFn: () => pollAthenaOutput({ programId, outputId }),
    staleTime: Infinity,
    enabled: Boolean(outputId),
    ...queryOptions,
  });

  return {
    isLoading,
    error: error instanceof Error ? error : null,
    errorMessage: error instanceof Error ? error.message : undefined,
    data,
    status,
  };
}

/**
 * Continually refetches an Athena output while pending until complete or failed.
 * This is useful for displaying the output value as it is generated, giving a streaming effect.
 *
 * @param outputId - The ID of the Athena output.
 * @param queryOptions - Optional query options.
 * @param queryOptions.pendingRefetchInterval - The interval (in milliseconds) at which the output should be refetched while it is still pending.
 *
 */
export function useRefetchPendingAthenaOutput(
  outputId: string,
  queryOptions?: {
    pendingRefetchInterval?: number;
  }
): QueryResponseWithStatus<AthenaOutputResponse> & {
  error: Error | null;
  stopRefetching: () => void;
} {
  const [shouldRefetch, setShouldRefetch] = React.useState(true);
  const programId = useProgram().id;

  const { pendingRefetchInterval = 1000 } = queryOptions || {};

  function refetchIntervalFn(
    data: AthenaOutputResponse | undefined,
    state: QueryState<AthenaOutputResponse, unknown> | undefined
  ) {
    const hasError = state?.error;
    if (!shouldRefetch || data?.status !== 'pending' || hasError) {
      return false;
    }

    return pendingRefetchInterval;
  }

  const queryClient = useQueryClient();
  const queryKey = [...athenaOutputQueryKeys.output(outputId)];
  const queryData = queryKey
    ? queryClient.getQueryData<AthenaOutputResponse>(queryKey)
    : undefined;
  const queryState = queryClient.getQueryState<AthenaOutputResponse>(queryKey);

  const refetchInterval = refetchIntervalFn(queryData, queryState);

  const { isLoading, error, data, status } = useQuery({
    queryKey,
    queryFn: () => fetchAthenaOutput(programId, outputId),
    retry: false,
    enabled: Boolean(outputId),
    refetchInterval,
  });

  return {
    isLoading,
    error: error instanceof Error ? error : null,
    errorMessage: error instanceof Error ? error.message : undefined,
    data,
    status,
    stopRefetching: () => setShouldRefetch(false),
  };
}
