import * as React from 'react';
import { useLibraryImageCategories, useLibraryImages } from 'hooks/useLibrary';
import { Image, Item } from 'models/library';
import { ImageData } from 'models/image';
import { PickerScreen } from './PickerScreen';

export const ImagePicker: React.FC<{
  onChange: (images: Image[]) => void;
  onCancel: () => void;
  maxSelections: number;
  useModal?: boolean;
}> = ({ onChange, maxSelections, onCancel, useModal = false }) => {
  const change = React.useCallback(
    (items: Array<Item>) => onChange(items as Array<Image>),
    [onChange]
  );

  return (
    <PickerScreen
      useModal={useModal}
      libraryType="content_image"
      useLibraryItems={useLibraryImages}
      useCategories={useLibraryImageCategories}
      label="image"
      maxSelections={maxSelections}
      onChange={change}
      onCancel={onCancel}
    />
  );
};

export function itemToImage(image: Image): ImageData {
  return {
    url: image.asset.url,
    altText: image.title,
    processed: false,
  };
}
