import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex } from 'DesignSystem/Layout/Flex';

export const ClassicContent: React.FC<RouteComponentProps<{ id: string }>> = ({
  id: contentId,
}) => {
  const { id: programId } = useProgram();

  const { data: ready } = useFeatureFlagsQuery(
    programId,
    'Studio.Parity.Kaiframes'
  );
  const useKaiframe = ready && ready.value;

  React.useEffect(() => {
    if (ready && !useKaiframe) {
      const BOSS_ROOT = process.env.REACT_APP_BOSSANOVA_DOMAIN;
      window.location.replace(
        `${BOSS_ROOT}/programs/${programId}/r/editor/content/${contentId}`
      );
    }
  }, [programId, contentId, ready, useKaiframe]);

  if (!ready)
    return (
      <Flex center>
        <LoadingSpinner />
      </Flex>
    );
  return !useKaiframe ? null : (
    <BossViewPage
      app="samba"
      fullScreen
      title="Content Editor"
      src={`editor/content/${contentId}`}
      returnUrls={['/app/content']}
      newSlugs={['new', 'link', 'video', 'note', 'image']}
    />
  );
};
