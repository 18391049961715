import React, { createContext, useContext, useState } from 'react';
import { ErrorModal } from 'DesignSystem/Components';

type ModalData = {
  title: string;
  message: string;
};

type ModalContextData = {
  showErrorModal: (data: ModalData) => void;
};

const ModalPrototype: ModalContextData = {
  showErrorModal: () => {},
};

const ModalContext = createContext<ModalContextData>(ModalPrototype);

export const useModal = (): ModalContextData => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('Modal context hooks require a containing ModalContext');
  }
  return context;
};

export const ModalProvider: React.FC = ({ children }) => {
  const [errorModal, setErrorModal] = useState<ModalData>();

  const value = {
    showErrorModal: setErrorModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {errorModal && (
        <ErrorModal
          title={errorModal.title}
          onConfirm={() => setErrorModal(undefined)}
        >
          {errorModal.message}
        </ErrorModal>
      )}
    </ModalContext.Provider>
  );
};
