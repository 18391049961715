import React from 'react';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { useFlashMessage } from 'contexts/flasher';
import { useArchiveInclusiveRule } from 'hooks/inclusive-language/useQueries';
import { useProgram } from 'contexts/program';
import { InclusiveRule } from 'models/inclusive-language';
import { ArrowRight } from 'shared/icons';
import styles from './inclusive-rules.module.css';
import { inclusiveRuleToThumbnail, inclusiveRuleToMenuItems } from './mappings';

type PropsType = {
  inclusiveRule: InclusiveRule;
  index: number;
};

export const InclusiveRuleBanner: React.FC<PropsType> = ({
  inclusiveRule,
  index,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();

  const { archive } = useArchiveInclusiveRule(programId, {
    onSuccess: () =>
      setFlashMessage({
        severity: 'info',
        message: `Inclusive rule deleted successfully!`,
      }),
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Error happened while deleting the rule',
      }),
  });

  const thumbnail = inclusiveRuleToThumbnail(inclusiveRule, index);
  const menuItems = inclusiveRuleToMenuItems(programId, inclusiveRule, () =>
    archive(inclusiveRule)
  );
  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`${inclusiveRule.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>
            <span className={styles.terms}>
              {inclusiveRule.terms.join(', ')}
            </span>
            <span className={styles.arrowContainer}>
              <ArrowRight />
            </span>
            <span className={styles.terms}>
              {inclusiveRule.suggestions.join(', ')}
            </span>
          </div>
          <div className={styles.explanation}>{inclusiveRule.explanation}</div>
        </div>
      </div>
    </EmptyBanner>
  );
};
