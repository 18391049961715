import * as SourceMenu from '../SourceMenu/useSourceMenu';

const { OPENED, CLOSED } = SourceMenu;
export { OPENED, CLOSED };

export const UPLOAD = 'upload' as const;
export const EXTERNAL = 'external' as const;
export type Source = typeof UPLOAD | typeof EXTERNAL;
export type VideoSourceMenu = SourceMenu.MenuIface<Source>;

export const MENU: SourceMenu.Config<Source> = [
  { name: 'Upload', source: UPLOAD },
  { name: 'URL', source: EXTERNAL },
];

export function useVideoSourceMenu(
  source: Source = UPLOAD
): SourceMenu.MenuIface<Source> {
  return SourceMenu.useSourceMenu(MENU, { source, visibility: OPENED });
}

export function isVideoSource(data: Source | unknown): data is Source {
  return data === UPLOAD || data === EXTERNAL;
}
