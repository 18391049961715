import { DateTime } from 'luxon';
import { JourneyTemplateListItem } from 'models/journeys/journey';
import { maybe } from 'utility/objectUtils';

export type JourneyTemplatePaginationData = {
  total: number;
  page: {
    size: number;
    number: number;
  };
};

export type JourneyTemplateCollectionData = {
  data: JourneyTemplateListItem[];
  after: unknown;
  meta?: JourneyTemplatePaginationData;
};

type ServerJourneyListTemplateData = Omit<
  JourneyTemplateCollectionData,
  'data'
> & {
  data: (Omit<JourneyTemplateListItem, 'createdAt' | 'updatedAt'> & {
    createdAt?: string;
    updatedAt?: string;
  })[];
};

export const deserializeJourneyTemplateListItems = (
  serverJourneyListTemplateData: ServerJourneyListTemplateData
): JourneyTemplateCollectionData => {
  const newData = serverJourneyListTemplateData.data.map((j) => {
    return {
      ...j,
      createdAt: maybe(j?.createdAt, DateTime.fromISO),
      updatedAt: maybe(j?.updatedAt, DateTime.fromISO),
    };
  });

  return {
    ...serverJourneyListTemplateData,
    after: serverJourneyListTemplateData.after,
    data: newData,
  };
};
