import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { ActionButtonType } from 'shared/BulkSelect/Actions';

type BulkSelectContextData = {
  selectedIds: string[];
  excludedIds: string[];
  selectedCount: number;
  bulkSelectEnabled: boolean;
  isBulkSelectMode: boolean;
  totalRecords: number;
  isAllSelected: boolean;
  onSelectionChange: (id: string, selected: boolean) => void;
  selectAll: () => void;
  closeBulkSelect: () => void;
  clearBulkSelect: () => void;
  isListItemSelected: (id?: string) => boolean;
  selectedAction?: ActionButtonType;
  setSelectedAction: (action: ActionButtonType | undefined) => void;
  cancel: () => void;
};

const BulkSelectContext = createContext<BulkSelectContextData>({
  selectedIds: [],
  excludedIds: [],
  selectedCount: 50,
  bulkSelectEnabled: false,
  isBulkSelectMode: false,
  totalRecords: 0,
  isAllSelected: false,
  selectAll: () => {},
  onSelectionChange: (() => {}) as (id: string, selected: boolean) => void,
  closeBulkSelect: () => {},
  clearBulkSelect: () => {},
  isListItemSelected: () => false,
  selectedAction: undefined,
  cancel: () => {},
  setSelectedAction: (() => {}) as (
    action: ActionButtonType | undefined
  ) => void,
} as BulkSelectContextData);

export const useBulkSelect = (): BulkSelectContextData => {
  return useContext(BulkSelectContext);
};

export const BulkSelectProvider: React.FC<{
  selectedIds?: string[];
  totalRecords: number;
}> = ({ children, totalRecords, selectedIds: selectedIdsProp }) => {
  const { id: programId } = useProgram();
  const { data: bulkSelectFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.BulkSelect'
  );
  const [isAllSelected, setIsAllSelected] = useState<boolean>();
  const [isBulkSelectMode, setIsBulkSelectMode] = useState<boolean>();
  const [selectedIds, setSelectedIds] = useState<string[]>(
    selectedIdsProp ?? []
  );
  const [excludedIds, setExcludedIds] = useState<string[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedAction, setSelectedAction] = useState(undefined);

  useEffect(() => {
    setSelectedCount(() => {
      if (isAllSelected && totalRecords)
        return totalRecords - excludedIds.length;
      return selectedIds.length;
    });
  }, [excludedIds, isAllSelected, selectedIds, totalRecords]);

  const clearSelectedIds = useCallback(() => {
    setSelectedIds((prev) => {
      if (prev.length === 0) return prev;
      return [];
    });
  }, []);

  const clearExcludedIds = useCallback(() => {
    setExcludedIds((prev) => {
      if (prev.length === 0) return prev;
      return [];
    });
  }, []);

  const onSelectionChange = useCallback(
    (id: string, selected: boolean) => {
      setIsBulkSelectMode(true);
      if (isAllSelected) {
        clearSelectedIds();
        setExcludedIds((prev) => {
          if (!selected && !prev.includes(id)) {
            return [...prev, id];
          }

          const indexToRemove = prev.indexOf(id);

          if (indexToRemove === -1) return prev;
          return [
            ...prev.slice(0, indexToRemove),
            ...prev.slice(indexToRemove + 1),
          ];
        });
      } else {
        clearExcludedIds();
        setSelectedIds((prev) => {
          if (selected && !prev.includes(id)) {
            return [...prev, id];
          }

          const indexToRemove = prev.indexOf(id);

          if (indexToRemove === -1) return prev;
          return [
            ...prev.slice(0, indexToRemove),
            ...prev.slice(indexToRemove + 1),
          ];
        });
      }
    },
    [clearExcludedIds, clearSelectedIds, isAllSelected]
  );

  const value = useMemo(
    () =>
      ({
        selectedIds,
        excludedIds,
        totalRecords,
        selectedCount,
        bulkSelectEnabled: bulkSelectFlag?.value,
        isBulkSelectMode,
        isAllSelected,
        onSelectionChange,
        selectAll: () => {
          setIsBulkSelectMode(true);
          setIsAllSelected(true);
          clearExcludedIds();
          clearSelectedIds();
        },
        closeBulkSelect: () => {
          setIsBulkSelectMode(false);
          setIsAllSelected(false);
          clearExcludedIds();
          clearSelectedIds();
        },
        clearBulkSelect: () => {
          setIsAllSelected(false);
          clearExcludedIds();
          clearSelectedIds();
          setSelectedAction(undefined);
        },
        isListItemSelected: (id?: string) => {
          if (!id) return false;
          if (isAllSelected) return !excludedIds?.includes(id);
          return selectedIds?.includes(id) || false;
        },
        selectedAction,
        setSelectedAction,
        cancel: () => {
          setSelectedAction(undefined);
        },
      } as BulkSelectContextData),
    [
      bulkSelectFlag?.value,
      clearExcludedIds,
      clearSelectedIds,
      excludedIds,
      isAllSelected,
      isBulkSelectMode,
      onSelectionChange,
      selectedAction,
      selectedCount,
      selectedIds,
      totalRecords,
    ]
  );

  return (
    <BulkSelectContext.Provider value={value}>
      {children}
    </BulkSelectContext.Provider>
  );
};
