import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Topic } from '../../../../../../../models/topic';
import { TopicStatus } from './TopicStatus';
import { TopicStats } from './TopicStats';
import { TopicPosts } from './TopicPosts';
import { TopicShotcuts } from './TopicShortcuts';

interface Props extends RouteComponentProps {
  topic: Topic;
}

function Details({ topic }: Props): JSX.Element {
  return (
    <>
      <TopicStatus topic={topic} />
      <TopicStats topic={topic} />
      <TopicPosts topic={topic} />
      <TopicShotcuts topic={topic} />
    </>
  );
}

export default Details;
