import * as React from 'react';
import * as Luxon from 'luxon';
import * as Blocks from 'models/publisher/block';
import { FieldFormProps } from '../../../useFieldForm';

export function useDateTimeDataMutator({
  data,
  default_data,
  onChange,
}: FieldFormProps<Blocks.DateFieldData>): {
  value: Luxon.DateTime;
  onChange: (updated: Luxon.DateTime) => void;
} {
  const value = React.useMemo(() => Blocks.fieldToDate(data || default_data), [
    data,
    default_data,
  ]);
  const handleChange = React.useCallback(
    (updated: Luxon.DateTime) => {
      onChange(Blocks.dateToField(updated));
    },
    [onChange]
  );
  return { value, onChange: handleChange };
}
