import { CustomHtmlField, DefinitionBlock } from 'models/publisher/block';
import { FieldType, RenderError, Targets } from 'models/donkey';
import React, { useRef } from 'react';
import { useRenderer } from 'hooks/content-blocks';
import { Modal } from 'DesignSystem/Components/Modal';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import {
  EditorContent,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useEditor,
} from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { lowlight } from 'lowlight/lib/core';
import html from 'highlight.js/lib/languages/xml';
import './highlight-js.css';
import { useOneOf } from 'hooks/useOneOf';
import cx from 'classnames';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Icon } from 'shared/Icon';
import { MAIcon } from 'shared/MAIcon';
import { useDebounce } from 'hooks/useDebounce';
import styles from './editor.module.css';
import { SectionContent } from '../SectionContent';

lowlight.registerLanguage('html', html);

const CodeBlockComponent = () => {
  return (
    <NodeViewWrapper className="code-block">
      <div className={styles.editorNodeViewWrapper}>
        <pre className={styles.wrapperContent}>
          <NodeViewContent as="code" />
        </pre>
      </div>
    </NodeViewWrapper>
  );
};

export const FullHtmlEditor: React.FC<{
  blockId: string;
  html: string;
  block: DefinitionBlock<{ customHtml: CustomHtmlField }>;
  onLeave: () => void;
  onChange: (fieldName: string, data: FieldType) => void;
}> = ({ block, onChange, blockId, onLeave }) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [target, changeTarget] = React.useState<DefinitionBlock['target']>(
    block.target
  );
  const [blockWithEditorValues, setBlockWithEditorValues] = React.useState(
    block
  );
  const { updateTarget } = React.useContext(BlocksEditorContext);
  const blockTargetsEnabled = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.BlockTargets'
  ).data?.value;

  const tab = useOneOf({
    names: ['content', 'section'],
    initial: 'content',
  });

  const [delayTimer, setDelayTimer] = React.useState(2000);

  const editor = useEditor({
    extensions: [
      Document.extend({
        content: 'codeBlock+',
      }),
      Paragraph,
      Text,
      CodeBlockLowlight.extend({
        name: 'codeBlock',
        group: 'codeBlock',
        addNodeView() {
          return ReactNodeViewRenderer(CodeBlockComponent);
        },
      }).configure({
        defaultLanguage: 'html',
        exitOnArrowDown: false,
        exitOnTripleEnter: false,
        lowlight,
      }),
    ],

    onUpdate({ editor: ed }) {
      const editorText: string = ed.getText() || '';
      // this is only necessary for validation, actual saving is done in saveAndClose
      setDelayTimer(2000);
      setBlockWithEditorValues({
        ...block,
        field_data: { customHtml: { type: 'custom-html', value: editorText } },
      });
    },
    content: {
      type: 'doc',
      content: [
        {
          type: 'codeBlock',
          content: [
            {
              type: 'text',
              text: block.field_data.customHtml.value,
            },
          ],
        },
      ],
    },
  });

  const initialEditorContent = React.useMemo(
    () => ({
      type: 'doc',
      content: [
        {
          type: 'codeBlock',
          content: [
            {
              type: 'text',
              text: block.field_data.customHtml.value,
            },
          ],
        },
      ],
    }),
    [block.field_data.customHtml.value]
  );

  const openModal = React.useCallback(() => {
    setDelayTimer(0);
    // Set the editor content to the initial content when modal is opened
    if (editor) {
      editor.commands.setContent(initialEditorContent);
      setBlockWithEditorValues({
        ...block,
        field_data: {
          customHtml: {
            type: 'custom-html',
            value: block.field_data.customHtml.value,
          },
        },
      });
    }

    setModalOpen(true);
  }, [block, editor, initialEditorContent]);

  const closeModal = React.useCallback(() => {
    // Set the editor content to the initial content when modal is opened
    if (editor) {
      editor.commands.setContent(initialEditorContent);
    }

    setModalOpen(false);
  }, [editor, initialEditorContent]);

  const rendererStyles = React.useMemo(() => {
    return { colors: {}, sizes: {}, fonts: {} };
  }, []);

  const renderResult = useRenderer(
    block,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    rendererStyles,
    Targets.web
  );

  const errorRenderResult = useRenderer(
    useDebounce(blockWithEditorValues, delayTimer),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    rendererStyles,
    Targets.web
  );

  const showPlaceholder =
    !block.field_data.customHtml.value || renderResult.errors.length > 0;
  const showLoading =
    block.field_data.customHtml.value && renderResult.isLoading;
  const showPreview =
    block.field_data.customHtml.value && !renderResult.isLoading;

  const previewContainer = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (previewContainer.current) {
      const shadow = previewContainer.current.shadowRoot
        ? previewContainer.current.shadowRoot
        : previewContainer.current.attachShadow({ mode: 'open' });
      shadow.innerHTML = renderResult.html;
    }
  }, [renderResult.html]);

  const saveAndClose = React.useCallback(async () => {
    await updateTarget(blockId, target);

    if (!editor?.getText()) {
      return;
    }
    await onChange('customHtml', {
      type: 'custom-html',
      value: editor?.getText() || '',
    });
    onLeave();
    closeModal();
  }, [blockId, closeModal, editor, onChange, onLeave, target, updateTarget]);

  const leaveAndClose = () => {
    onLeave();
    closeModal();
  };

  const enableContent = React.useCallback(() => {
    tab.content.activate();
  }, [tab.content]);

  const enableSection = React.useCallback(() => {
    if (blockTargetsEnabled) {
      tab.section.activate();
    }
  }, [blockTargetsEnabled, tab.section]);

  return (
    <>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */}
      {showPreview && (
        <div
          className={styles.fullHtmlEditorPreview}
          ref={previewContainer}
          onClick={openModal}
          style={{ display: showPreview ? 'block' : 'none' }}
        />
      )}
      {showPlaceholder && (
        <div
          onClick={openModal}
          style={{
            border: '16px solid white',
            textAlign: 'center',
            borderRadius: '5px',
            padding: '50px 10px',
            backgroundColor: '#F5F3FF',
          }}
        >
          <MAIcon
            name="code"
            style={{
              color: 'white',
              backgroundColor: 'var(--color-gray90)',
              opacity: 0.25,
              borderRadius: '4px',
              fontSize: '40px',
            }}
          />
          <br />
          <p>Click to launch the code editor</p>
        </div>
      )}
      {showLoading && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <LoadingSpinner />
        </div>
      )}
      {modalOpen && (
        <Modal
          name="edit"
          minPadding={0}
          innerPadding={0}
          width="80vw"
          maxHeight="85vh"
        >
          <div className={styles.fullHtmlEditor}>
            <Flex className={styles.modalHeader} start>
              <Button
                layoutOnly
                text
                onClick={enableContent}
                label="Content"
                className={cx(styles.tabButton, {
                  [styles.activeTab]: tab.content.isActive,
                })}
              />
              {blockTargetsEnabled && (
                <Button
                  layoutOnly
                  text
                  onClick={enableSection}
                  label="Section"
                  className={cx(styles.tabButton, {
                    [styles.activeTab]: tab.section.isActive,
                  })}
                />
              )}
            </Flex>
            {tab.content.isActive && (
              <div className={styles.editorContent}>
                <EditorContent editor={editor} />
                {!!errorRenderResult.errors &&
                  errorRenderResult.errors.length > 0 && (
                    <div className={styles.editorErrors}>
                      <div className={styles.editorWarningBadge}>
                        <Icon iconName="exclamation-triangle" />
                      </div>
                      <div className={styles.editorErrorMessage}>
                        <strong>Warning:</strong>
                        <ul>
                          {errorRenderResult.errors.map(
                            (error: RenderError) => (
                              <li key={`error-item-key-${error}`}>
                                {error.message}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>

          {blockTargetsEnabled && tab.section.isActive && (
            <div className={styles.sectionVisibilityWrapper}>
              <SectionContent
                block={{ ...block, target }}
                blockId={blockId}
                onTargetChange={changeTarget}
              />
            </div>
          )}
          <Flex className={styles.modalFooter} end>
            <Button
              className={styles.buttonCancel}
              secondary
              label="Cancel"
              onClick={leaveAndClose}
            />
            <Button label="Apply" onClick={saveAndClose} />
          </Flex>
        </Modal>
      )}
    </>
  );
};
