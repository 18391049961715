import { DateTime, Interval } from 'luxon';
import { identityFn } from 'utility/data-extractor';

import { useProgram } from 'contexts/program';
import { useContextCommunication } from 'hooks/context_communication';
import {
  personalizationVariableRegExp,
  personalizationVariableRegExpOpen,
} from 'models/liquid-variable';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { buildDuration, Duration, UnitValue } from './duration';
import { Channel } from './channel';

// TODO: Move to util?
// TODO: There's a way to DRY this
function maxBy<T>(arr: Array<T>, by: (x: T) => number) {
  const result = arr.map((el) => {
    return by(el);
  });
  const max = Math.max.apply(null, result);
  return arr[result.indexOf(max)];
}

function minBy<T>(arr: Array<T>, by: (x: T) => number) {
  const result = arr.map((el) => {
    return by(el);
  });
  const min = Math.min.apply(null, result);
  return arr[result.indexOf(min)];
}

export const MAX_NOTIFICATION_LENGTH = 120;

export type Notification = {
  text: string;
  previewText?: string;
  pushText?: string;
  notificationCenterText?: string;
  notificationCenterMarkAsImportant?: boolean;
  order: number;
  uuid?: string;
  dateTime?: DateTime;
  isPublishDateTime?: boolean;
  persisted?: boolean;
};

export const defaultFirstNotification: Notification = {
  text: '',
  order: 1,
  persisted: false,
};

export const isNotification = (
  notification: Notification | unknown
): notification is Notification => {
  const notif = notification as Notification;

  if (!notif) {
    return false;
  }
  if (typeof notif.text !== 'string') {
    return false;
  }
  if (typeof notif.order !== 'number') {
    return false;
  }
  if (notif.previewText != null && typeof notif.previewText !== 'string') {
    return false;
  }
  if (notif.pushText != null && typeof notif.pushText !== 'string') {
    return false;
  }
  if (notif.uuid != null && typeof notif.uuid !== 'string') {
    return false;
  }
  if (notif.dateTime != null && !DateTime.isDateTime(notif.dateTime)) {
    return false;
  }
  if (
    notif.isPublishDateTime != null &&
    typeof notif.isPublishDateTime !== 'boolean'
  ) {
    return false;
  }

  return true;
};

export const isNotificationsArray = (
  notifications: Parameters<typeof isNotification>[0][]
): notifications is Notification[] => {
  return notifications.every(isNotification);
};

// TODO add better parsing for liquid variables.
// Regex go burrrrr
export const areVariablesValid = (
  text: string,
  allowedVariables: string[]
): boolean => {
  const group = text.match(personalizationVariableRegExp);
  const groupOpen = text.match(personalizationVariableRegExpOpen);
  if (!group) {
    return !groupOpen;
  }
  const groupIndex = group.index ? group.index : 0;

  const variable = group[1];
  const foundInAllowed = allowedVariables.find((v) => v === variable);
  if (!foundInAllowed) {
    return false;
  }

  const restOfText = text.substring(groupIndex + group[0].length);
  if (restOfText) {
    return areVariablesValid(restOfText, allowedVariables);
  }

  return true;
};

export const isNotificationValid = (
  notification: Notification,
  allowedVariables: string[],
  enabledChannels: Channel[],
  notificationCenterEnabled: boolean
): {
  validNotification: boolean;
  pushText: boolean;
  previewText: boolean;
  text: boolean;
  notificationCenterText: boolean;
} => {
  if (!isNotification(notification)) {
    return {
      validNotification: false,
      pushText: false,
      previewText: false,
      text: false,
      notificationCenterText: false,
    };
  }
  const textValid = areVariablesValid(notification.text, allowedVariables);
  const pushTextValid =
    !notification.pushText ||
    areVariablesValid(notification.pushText, allowedVariables);
  const previewTextValid =
    !notification.previewText ||
    areVariablesValid(notification.previewText, allowedVariables);
  const notificationCenterTextValid =
    !notification.notificationCenterText ||
    areVariablesValid(notification.notificationCenterText, allowedVariables);

  const validNotification =
    validateTextsPresence(
      notification,
      enabledChannels,
      notificationCenterEnabled
    ) &&
    textValid &&
    pushTextValid &&
    previewTextValid;

  return {
    validNotification,
    pushText: pushTextValid,
    previewText: previewTextValid,
    text: textValid,
    notificationCenterText: notificationCenterTextValid,
  };
};

export const validateTextsPresence = (
  notification: Notification,
  enabledChannels: Channel[],
  notificationCenterEnabled: boolean
): boolean => {
  const { text, previewText, pushText, notificationCenterText } = notification;
  const textLen = text.trim().length;
  const previewTextLen = previewText == null ? 0 : previewText.trim().length;
  const pushTextLen = pushText == null ? 0 : pushText.trim().length;
  const notificationCenterTextLen =
    notificationCenterText == null ? 0 : notificationCenterText.trim().length;

  return enabledChannels.every((channel) => {
    switch (channel) {
      case 'email':
        return (
          textLen > 0 &&
          textLen <= MAX_NOTIFICATION_LENGTH &&
          previewTextLen <= MAX_NOTIFICATION_LENGTH
        );
      case 'push':
        return pushTextLen > 0 && pushTextLen <= MAX_NOTIFICATION_LENGTH;
      case 'assistant':
        if (!notificationCenterEnabled) {
          // We don't have any text to validate for `assistant` unless notification center is enabled
          return true;
        }
        return (
          notificationCenterTextLen > 0 &&
          notificationCenterTextLen <= MAX_NOTIFICATION_LENGTH
        );
      default:
        return true;
    }
  });
};

export const areNotificationsValid = (
  notifications: Array<Notification>,
  allowedVariables: string[],
  enabledChannels: Channel[],
  notificationCenterEnabled: boolean
): boolean => {
  const validationFunc = (n: Notification) =>
    isNotificationValid(
      n,
      allowedVariables,
      enabledChannels,
      notificationCenterEnabled
    ).validNotification;
  return notifications.map(validationFunc).every(identityFn);
};

export const useNotificationsValidator = (
  notifications: Notification[],
  enabledChannels: Channel[]
): boolean => {
  const { id: programId } = useProgram();
  const isNotificationCenterEnabled = useNotificationCenterEnabled();

  const { data: communicationMentionsData } = useContextCommunication(
    programId,
    false
  );

  return areNotificationsValid(
    notifications,
    (communicationMentionsData || []).map((c) => c.key),
    enabledChannels,
    isNotificationCenterEnabled
  );
};

export const useNotificationValidator = (
  notification: Notification,
  enabledChannels: Channel[]
): ReturnType<typeof isNotificationValid> => {
  const { id: programId } = useProgram();
  const isNotificationCenterEnabled = useNotificationCenterEnabled();

  const { data: communicationMentionsData } = useContextCommunication(
    programId,
    false
  );

  return isNotificationValid(
    notification,
    (communicationMentionsData || []).map((c) => c.key),
    enabledChannels,
    isNotificationCenterEnabled
  );
};

export const sortNotifications = (
  notifications: Notification[]
): Notification[] => {
  const newNotifications = [...notifications];

  return newNotifications.sort(sortByDateTime).map(reorderByIndex);
};

// Note: Non-date Notifications should always go last
const sortByDateTime = (a: Notification, b: Notification): number => {
  if (a?.dateTime && b?.dateTime) {
    return a.dateTime.toMillis() - b.dateTime.toMillis();
  }
  if (!a?.dateTime && b?.dateTime) {
    return 1;
  }
  if (!a?.dateTime && !b?.dateTime) {
    return 0;
  }
  return -1;
};

const reorderByIndex = (n: Notification, i: number): Notification => ({
  ...n,
  order: i + 1,
});

type NotificationDateParts = Notification & {
  dateTime: DateTime;
};

export const calculateDuration = (notifications: Notification[]): Duration => {
  if (notifications.length < 2) {
    // TODO: this is prob not the place to do this....
    throw Error('Cannot calculate duration on a single Notification');
  }

  const newNotifications = [...notifications];

  const notificationDates = newNotifications.filter(
    (n) => n?.dateTime
  ) as NotificationDateParts[];

  const maxNotif = maxBy(notificationDates, (n) => n.dateTime.toMillis());
  const minNotif = minBy(notificationDates, (n) => n.dateTime.toMillis());

  // add an extra day for current day
  const days = Interval.fromDateTimes(
    minNotif.dateTime,
    maxNotif.dateTime
  ).length('days');
  const durationValue = Math.round(days) + 1;
  return buildDuration(durationValue, 'day' as UnitValue);
};

export const initializeNotificationsDateArray = (
  notifications: Notification[],
  publishedAt: DateTime
): Notification[] => {
  const newNotifications = [...notifications];
  const firstNotif = newNotifications[0];

  if (!firstNotif?.dateTime) {
    const newNotif = updateDateTime(firstNotif, publishedAt, true);
    return [newNotif].concat(newNotifications.slice(1));
  }
  return notifications;
};

export const updateDateTime = (
  notification: Notification,
  dateTime: DateTime,
  isPublishDateTime: boolean
): Notification => {
  return {
    ...notification,
    dateTime,
    isPublishDateTime,
  };
};
