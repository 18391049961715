import cx from 'classnames';
import * as React from 'react';
import styles from './sidebar.module.css';
import { MAIcon } from '../../../../shared/MAIcon';

interface SidebarInputErrorProps {
  disabled?: boolean;
  message?: string;
}

export const SidebarInputError: React.FC<SidebarInputErrorProps> = ({
  disabled,
  message,
}) => {
  return (
    <>
      <div
        className={cx(styles.ctaErrorMessage, {
          [styles.ctaErrorMessageDisabled]: disabled,
        })}
      >
        <MAIcon name="warning" className={styles.warningSign} />
        {message || 'An unexpected error occurred. Please try again.'}
      </div>
    </>
  );
};
