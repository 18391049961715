import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Box } from 'DesignSystem/Components/Box';
import cx from 'classnames';
import styles from './year.module.css';

export const Year: React.FC<{
  selectedDate: DateTime;
  onChange: (date: DateTime | undefined) => void;
  rowsCount?: number;
  colsCount?: number;
  yearsOffset?: number;
}> = ({
  selectedDate,
  onChange,
  rowsCount = 4,
  colsCount = 3,
  yearsOffset = 0,
}) => {
  const yearsTable = useMemo(() => {
    let year = DateTime.now().year - (1 + yearsOffset);
    const yearsRows = [];

    for (let i = 0; i < rowsCount; i += 1) {
      const yearsCol = [];
      for (let j = 0; j < colsCount; j += 1) {
        yearsCol.push((year += 1));
      }
      yearsRows.push(yearsCol);
    }

    return yearsRows;
  }, [colsCount, rowsCount, yearsOffset]);

  return (
    <div className={styles.mainWrapper}>
      {yearsTable.map((yearsRow) => (
        <div key={yearsRow[0]} className={styles.yearWrapper}>
          {yearsRow.map((year) => (
            <Box
              key={year}
              onClick={() => onChange(selectedDate.set({ year }))}
              className={cx(
                styles.yearCell,
                selectedDate.year === year ? styles.selected : styles.default
              )}
            >
              {year}
            </Box>
          ))}
        </div>
      ))}
    </div>
  );
};
