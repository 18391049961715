import React from 'react';
import { useProgramIdState } from 'contexts/program';
import { useContentAttachmentQuery } from 'hooks/attachment';
import { AttachmentData } from 'models/attachment';
import { ReactComponent as ScanningSVG } from './Scanning.svg';
import { ReactComponent as CleanSVG } from './Clean.svg';
import { ReactComponent as InfectedSVG } from './Infected.svg';
import styles from './upload.module.css';

type PropsType = {
  id: string;
  initialStatus: string;
};

const REFRESH_STATUSES = ['waiting', 'scanning'];

const STATUS_TEXT = {
  scanning: 'Scanning file',
  clean: 'Safe for use',
  infected: 'Virus detected',
  error: 'Error during scan',
};

function iconForStatus(status: string): React.ReactElement | null {
  switch (status) {
    case 'waiting':
    case 'scanning':
      return (
        <div className={styles.scanning}>
          <ScanningSVG />
        </div>
      );
    case 'unsafe':
    case 'error':
      return <InfectedSVG />;
    case 'safe':
      return <CleanSVG />;
    default:
      return null;
  }
}

function textForStatus(status: string): string | null {
  switch (status) {
    case 'waiting':
    case 'scanning':
      return STATUS_TEXT.scanning;
    case 'unsafe':
      return STATUS_TEXT.infected;
    case 'error':
      return STATUS_TEXT.error;
    case 'safe':
      return STATUS_TEXT.clean;
    default:
      return null;
  }
}

export const ScanStatus: React.FC<PropsType> = ({ id, initialStatus }) => {
  const [programId] = useProgramIdState();
  const [status, setStatus] = React.useState(initialStatus);
  const poller = React.useRef<ReturnType<typeof window.setTimeout>>();
  const { refetch } = useContentAttachmentQuery({
    programId,
    id,
    enabled: false,
  });

  const refresh = React.useCallback(async () => {
    if (!REFRESH_STATUSES.includes(status)) return;
    const { data } = await refetch();
    const attachmentData = data as AttachmentData;

    setStatus(attachmentData.status);
    poller.current = setTimeout(refresh, 5000);
  }, [refetch, status]);

  React.useEffect(() => {
    refresh();
    return () => {
      if (poller.current) clearTimeout(poller.current);
    };
  }, [refresh]);

  return (
    <div className={styles.scanStatus}>
      {iconForStatus(status)}
      {textForStatus(status)}
    </div>
  );
};
