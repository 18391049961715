import {
  Journey,
  Steps,
  isStepType,
  Step,
  JourneyGraph,
} from 'models/journeys/journey';
import { asserts } from 'utility/asserts';

export function updateStep(state: Journey, st: Step): Journey {
  const { draftGraph } = state;
  if (!draftGraph) {
    return state;
  }
  return {
    ...state,
    draftGraph: {
      ...draftGraph,
      steps: draftGraph.steps.map((s) => (s.id === st.id ? st : s)),
    },
  };
}

/**
 * Finds a specific draft step in the journey state based on the provided step ID.
 * Throws an error if the step is not found.
 *
 * @param state - The journey from which to retrieve the step.
 * @param stepId - The ID of the step to find.
 * @returns The step with the specified ID.
 * @throws Will throw an error if the step is not found.
 */
export function mustFindDraftStep(state: Journey, stepId: Step['id']): Step {
  const step = findStep(state, stepId, (journey) => journey.draftGraph);
  asserts(step !== undefined, 'Step must exist');
  return step;
}

/**
 * Finds a specific step in the journey state based on the provided step ID and graph strategy.
 *
 * @param state - The journey from which to retrieve the step.
 * @param stepId - The ID of the step to find.
 * @param selectGraphStrategy - A function that selects the appropriate graph strategy for the journey, e.g. draft or live.
 * @returns The step with the specified ID, or undefined if the step is not found.
 */
export function findStep(
  state: Journey,
  stepId: Step['id'],
  selectGraphStrategy: (journey: Journey) => JourneyGraph | undefined
): Step | undefined {
  const draftSteps = selectGraphStrategy(state)?.steps ?? [];

  return draftSteps.find((s) => s.id === stepId);
}

/**
 * Retrieves a specific draft step, typed as TStep,
 * from the journey state based on the provided type and step ID.
 *
 * @template TStep - The type of the step to retrieve.
 * @param state - The journey from which to retrieve the step.
 * @param type - The type of the step to retrieve.
 * @param stepId - The ID of the step to retrieve.
 * @returns The draft step typed as TStep, or undefined if the step does not exist or is not of the correct type.
 */
export function getDraftStep<TStep extends keyof Steps>(
  state: Journey,
  type: TStep,
  stepId: Step['id']
): Steps[TStep] | undefined {
  return getStep(state, type, stepId, (journey) => journey.draftGraph);
}

/**
 * Retrieves a specific step, typed as TStep,
 * from the journey state based on the provided type and step ID.
 *
 * @template TStep - The type of the step to retrieve.
 * @param state - The journey from which to retrieve the step.
 * @param type - The type of the step to retrieve.
 * @param stepId - The ID of the step to retrieve.
 * @param selectGraphStrategy - A function that selects the appropriate graph strategy for the journey.
 * @returns The step typed as TStep, or undefined if the step does not exist or is not of the correct type, e.g. draft or live.
 */
export function getStep<TStep extends keyof Steps>(
  state: Journey,
  type: TStep,
  stepId: Step['id'],
  selectGraphStrategy: (journey: Journey) => JourneyGraph | undefined
): Steps[TStep] | undefined {
  const step = findStep(state, stepId, selectGraphStrategy);

  if (!step) {
    return undefined;
  }

  if (!isStepType(step, type)) {
    return undefined;
  }

  return step;
}

export function findPreviousStep(state: Journey, id: string): Step | undefined {
  const currentGraph = state.draftGraph;
  return currentGraph?.steps.find((s) =>
    s.next.some((edge) => edge.targetId === id)
  );
}
