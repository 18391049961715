import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useUserImportInfiniteQuery } from 'hooks/user-imports';
import { useQueryClient } from 'react-query';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { Icon } from 'shared/Icon';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { WithPermission } from 'shared/WithPermission';
import { ImportUsersSlideIn } from 'App/Program/Main/People/ImportUsersSlideIn';
import { FiltersBar } from './FiltersBar';
import { UserImportBanner } from './UserImportBanner';

export const UserImports: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();

  const parentRef = React.useRef<HTMLDivElement>(null);

  const [isImportOpen, setIsImportOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [selectedStatus, setSelectedStatus] = React.useState<string[]>();
  const queryClient = useQueryClient();

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
  } = useUserImportInfiniteQuery({
    programId,
    search: useDebounce(searchTerm),
    filter: useDebounce(selectedStatus),
    order: 'created_at_desc',
  });

  const itemCount = data.length || 0;

  return (
    <WithPermission permissions={['confContentAccess']}>
      <ListPage
        title="User Imports"
        breadcrumbs={[
          { to: '..', label: 'Configure' },
          { label: 'User Imports' },
        ]}
        actions={[
          {
            label: 'Import',
            onClick: () => setIsImportOpen(true),
            icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
          },
        ]}
        filterbar={
          <FiltersBar
            searchTerm={searchTerm}
            selectedStatus={selectedStatus}
            onSearchTermChange={setSearchTerm}
            onStatusChange={setSelectedStatus}
          />
        }
      >
        {errorMessage && <>{errorMessage}</>}
        {data && !errorMessage && (
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <InfiniteBannerList
                itemCount={itemCount}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                height={height}
                parentRef={parentRef}
                noItemsComponent={<div>No Imports match that criteria.</div>}
              >
                {(index: number) => <UserImportBanner data={data[index]} />}
              </InfiniteBannerList>
            )}
          </InfiniteContainer>
        )}
        {isImportOpen && (
          <ImportUsersSlideIn
            name="importUsers"
            onClose={() => {
              setIsImportOpen(false);
              queryClient.invalidateQueries('user-imports-infinite');
            }}
          />
        )}
      </ListPage>
    </WithPermission>
  );
};
