import { useEffect } from 'react';
import { useLocation, globalHistory, useNavigate } from '@reach/router';

type UseBeforeUnload = (shouldPrompt: boolean, message: string) => void;

export const useBeforeUnload: UseBeforeUnload = (shouldPrompt, message) => {
  useEffect(() => {
    if (!shouldPrompt) {
      return;
    }
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.returnValue = message;
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [shouldPrompt, message]);
};

export const useBeforeNavigate: UseBeforeUnload = (
  shouldPrompt: boolean,
  message: string
) => {
  const curLocation = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      const isNavigatingAway =
        (action === 'PUSH' &&
          !location.pathname.includes(window.location.pathname)) ||
        action === 'POP';
      if (isNavigatingAway && shouldPrompt) {
        // eslint-disable-next-line no-alert
        if (!window.confirm(message)) {
          navigate(`${curLocation.pathname}`);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPrompt, message]);
};
