import * as React from 'react';
import { SearchBar } from 'shared/SearchBar';
import { FiltersStateType } from 'contexts/workflow/filters';
import { FetchProps } from 'services/api-workflows';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { FilterItem } from './FilterItem';

type PropTypes = {
  filters: FiltersStateType;
  fetchProps: FilterFetchProps;
  onChange: (fetchProps: FilterFetchProps) => void;
  setValue: (name: string, values: string[]) => void;
};

type FilterFetchProps = Pick<FetchProps, 'search'>;

export const FiltersBar: React.FC<PropTypes> = ({
  filters,
  fetchProps,
  onChange,
  setValue,
}) => {
  const updateProp = React.useCallback(
    (prop: keyof FetchProps, value: FetchProps[typeof prop]) =>
      onChange({ ...fetchProps, [prop]: value }),
    [fetchProps, onChange]
  );

  const onSearchChange = React.useCallback(
    (value) => updateProp('search', value),
    [updateProp]
  );

  const handleFilter = React.useCallback(
    (name, value) => {
      setValue(name, value);
    },
    [setValue]
  );

  const visibleFilters = Object.keys(filters.standard).filter(
    (f) => filters.standard[f].isVisible
  );

  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={fetchProps.search}
        onChange={onSearchChange}
        placeholder="Search Workflows"
      />
      {visibleFilters.map((key) => (
        <FilterItem
          key={key}
          filter={filters.standard[key]}
          setFilterValues={handleFilter}
        />
      ))}
    </div>
  );
};
