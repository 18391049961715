import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { PageHeader } from 'DesignSystem/Layout/Pages';
import { ViewPort } from 'models/insight/Report';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useProgram } from 'contexts/program';
import { NavLink } from 'shared/NavLink';
import qs from 'qs';
import { MessageBubble } from '../../../../../DesignSystem/Layout/MessageBubble';
import { NewTabButton } from '../components/ReportButtons/NewTabButton';
import { ReportContext } from '../contexts/ReportContext';
import { MetabaseEmbedIframe } from '../components/MetabaseEmbedIframe';
import { NewFiltersButton } from '../components/Filters/NewFiltersButton';
import { ResetButton } from '../components/Filters/shared/footer/ResetButton';
import { FilterDropdown } from '../components/Filters/FilterDropdown';
import { LikeButton } from '../components/ReportButtons/LikeButton';
import { FiltersStateContext } from '../contexts/FiltersStateContext';
import { FilterDropdownProvider } from '../components/Filters/FilterDropdownContext';
import { BookmarkButton } from '../components/ReportButtons/BookmarkButton';
import { DownloadButton } from '../components/ReportButtons/DownloadButton';
import insightStyles from '../insight.module.css';
import filterStyles from '../components/Filters/Filters.module.css';
import { RelatedReports } from './RelatedReports';
import styles from './ReportPage.module.css';
import { ShareButton } from '../components/ReportButtons/ShareButton';

const InProgress = () => (
  <div className={styles.downloadInProgress}>
    Downloading report image...
    <small>It may take a moment to generate</small>
  </div>
);

const Loading = () => (
  <div className={styles.reportLoadingSpinnerContainer}>
    <LoadingSpinner />
  </div>
);

const Error = () => (
  <header className={styles.titleBar}>
    <div className={styles.titleContainer}>
      <h1>Unable to Load Report</h1>
      <p>
        An error occurred.{' '}
        <NavLink to="../../collections/overview">
          Go back to Insights Overview
        </NavLink>
      </p>
    </div>
  </header>
);

export const ReportPage: React.FC<RouteComponentProps> = () => {
  const {
    reportId,
    description,
    title,
    userLiked,
    metabaseEmbedUrl,
    metabaseReportQuery,
    relatedReports,
    showReset,
    setShowReset,
    auditDownload,
  } = React.useContext(ReportContext);

  const {
    filtersStateArray,
    unSelectedFilters,
    requiredFiltersWithNoSelections,
    filtersStateAction,
    getSelectedFiltersStateObj,
    getFilterStateObjArray,
    getShareableLink,
    metabaseFilterQuery,
  } = React.useContext(FiltersStateContext);

  const { id: programId } = useProgram();
  const [downloading, setDownloading] = React.useState(false);
  const [viewPort, setViewPort] = React.useState<ViewPort>({
    deviceScaleFactor: 1,
    height: 600,
    width: 900,
  });
  const selectedFiltersStateObj = getSelectedFiltersStateObj();
  const showReportLikes = !!useFeatureFlagsQuery(
    programId,
    'Studio.Insights.ShowReportLikes'
  ).data?.value;

  function requiredFiltersWithNoSelectionsWarningMessage(): string {
    const unSelectedRequiredFilters = requiredFiltersWithNoSelections || [];
    const filtersCount = unSelectedRequiredFilters.length;

    if (filtersCount === 0) return '';

    return `This report may not load properly until the following filter${
      filtersCount > 1 ? 's' : ''
    } ${filtersCount > 1 ? 'are' : 'is'} set: ${unSelectedRequiredFilters
      .map((f) => f.filter.label)
      .join(', ')}`;
  }

  if (!metabaseReportQuery?.data)
    return (
      <article className={insightStyles.pageHorizontalMargin}>
        {metabaseReportQuery?.isLoading ? <Loading /> : <Error />}
      </article>
    );

  const {
    dojoUrl,
    metabaseUrl,
    programIdSlug,
    oldStudioUrl,
  } = metabaseReportQuery?.data;
  const programIdQuery = `&${programIdSlug}=${programId}`;
  const filterParams = `?${qs.stringify(getSelectedFiltersStateObj())}`;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <div
      style={{
        padding: '0 32px',
        overflowX: 'hidden',
        minHeight: 'calc(100vh - var(--layout-site-header-height))',
      }}
    >
      <Helmet titleTemplate="Studio - Insights - %s">
        <title>{title || 'Report'}</title>
      </Helmet>
      <PageHeader
        title={title || 'Report'}
        description={description || undefined}
        breadcrumbs={[
          { label: 'Insights', to: '../..' },
          { label: `${title}` },
        ]}
        filterbar={
          <div className={filterStyles.FilterBar}>
            {unSelectedFilters && unSelectedFilters.length > 0 && (
              <NewFiltersButton
                filterStates={unSelectedFilters}
                onSelectFilter={(filterState) => {
                  setShowReset(true);
                  filtersStateAction({
                    action: 'addFilter',
                    filterState,
                  });
                }}
              />
            )}
            {filtersStateArray?.map((filterState) => (
              <FilterDropdownProvider
                key={filterState.filter.slug}
                filter={filterState.filter}
                filterState={filterState}
              >
                <FilterDropdown />
              </FilterDropdownProvider>
            ))}
            {showReset && <ResetButton />}
          </div>
        }
        actionsOverride={
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'inline-flex' }}>
                {showReportLikes && (
                  <LikeButton
                    primary
                    userLiked={userLiked}
                    reportId={reportId}
                  />
                )}
                <BookmarkButton
                  reportTitle={title || ''}
                  reportId={reportId}
                  selectedFiltersStateObj={selectedFiltersStateObj}
                />
                <DownloadButton
                  title={title}
                  reportId={reportId}
                  viewPort={viewPort}
                  params={selectedFiltersStateObj}
                  labels={getFilterStateObjArray()}
                  downloading={downloading}
                  setDownloading={setDownloading}
                />
                <ShareButton shareUrl={getShareableLink()} />
              </div>
            </div>
            <div style={{ display: 'block', marginTop: '20px' }}>
              <div style={{ display: 'inline-flex' }}>
                {oldStudioUrl ? (
                  <NewTabButton
                    url={oldStudioUrl + filterParams}
                    label="Old Studio"
                    tooltipMessage="View report in Old Studio"
                  />
                ) : null}
                {dojoUrl ? (
                  <NewTabButton
                    url={dojoUrl}
                    label="Dojo"
                    tooltipMessage="View report in dojo console"
                  />
                ) : null}
                {metabaseUrl ? (
                  <NewTabButton
                    url={metabaseUrl + metabaseFilterQuery() + programIdQuery}
                    label="Metabase"
                    tooltipMessage="View report in metabase console"
                  />
                ) : null}
                {dojoUrl && metabaseUrl ? (
                  <NewTabButton
                    url={getShareableLink()}
                    label="Refresh"
                    tooltipMessage="Open report in new page with permalinked urls"
                  />
                ) : null}
              </div>
            </div>
          </div>
        }
      />
      {/* show a flash message bubble, if any */}
      {!metabaseReportQuery.data.reportFlashMessage ? null : (
        <MessageBubble message={metabaseReportQuery.data.reportFlashMessage} />
      )}
      {/* show a message bubble if there are any required filters with no selections */}
      {(requiredFiltersWithNoSelections || []).length === 0 ? null : (
        <MessageBubble
          message={requiredFiltersWithNoSelectionsWarningMessage()}
        />
      )}

      {/* returning null explicitly to optimize conditional rendering */}
      {metabaseEmbedUrl === undefined ? (
        <Loading />
      ) : (
        <MetabaseEmbedIframe
          embedUrl={metabaseEmbedUrl}
          setViewPort={setViewPort}
          auditDownload={auditDownload}
        />
      )}
      <footer>
        <RelatedReports
          relatedReports={relatedReports}
          onClick={() => filtersStateAction({ action: 'clearAllFilters' })}
        />
        {downloading && <InProgress />}
      </footer>
    </div>
  );
};
