import * as React from 'react';
import * as Blocks from 'models/publisher/block';
import { FieldInput } from 'DesignSystem/Form';
import { Subheading } from 'DesignSystem/Typography';
import { DateTimeInput } from 'shared/DateTimeInput';
import { FieldFormProps } from '../../useFieldForm';
import { useDateTimeDataMutator } from './hooks/useDateTimeDataMutator';

export const DateTime: React.FC<FieldFormProps<Blocks.DateFieldData>> = (
  props
) => {
  const { value, onChange } = useDateTimeDataMutator(props);
  return (
    <FieldInput legend={<Subheading>Date and Time</Subheading>}>
      <DateTimeInput frameless value={value} onChange={onChange} />
    </FieldInput>
  );
};
