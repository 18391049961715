import * as React from 'react';
import { truncate } from 'utility/text';
import { Content } from 'models/content';
import { Box } from 'DesignSystem/Components';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Icon } from 'shared/Icon';
import { MetricsButton } from './MetricsButton';

type MediaItemPropsType = {
  key: string;
  title: string;
  iconName: string;
  reportUrl: string;
  dataPendo?: string;
};

const MediaItem: React.FC<MediaItemPropsType> = ({
  iconName,
  title,
  reportUrl,
  dataPendo,
}) => (
  <Box
    className="embedded-media-item"
    padding={[16, 24]}
    margin={[20, 0, 0, 0]}
    width="100%"
    height={64}
  >
    <Box>
      <Icon iconName={iconName} /> <Text.Body>{title}</Text.Body>
    </Box>
    <MetricsButton dataPendo={dataPendo} href={reportUrl} label="View" />
  </Box>
);

type EmbeddedMediaPropsType = {
  content: Content;
  getPollUrl(id: number): string;
  getVideoUrl(id: number): string;
};

export const EmbeddedMedia: React.FC<EmbeddedMediaPropsType> = ({
  content,
  getPollUrl,
  getVideoUrl,
}) => {
  const polls = content?.meta?.polls ?? [];
  const videos = content?.meta?.videos ?? [];
  if (!polls.length && !videos.length) return null;

  return (
    <Box style={{ borderTop: '1px solid var(--color-gray10)' }}>
      <Text.Subheading bold>Embedded Media</Text.Subheading>
      <Flex spread column>
        {polls.map((poll: { id: number; name: string }) => (
          <MediaItem
            dataPendo="view-poll-summary"
            key={`poll-${poll.id}`}
            iconName="poll"
            title={`Poll Summary: ${truncate(poll.name, { size: 64 })}`}
            reportUrl={getPollUrl(poll.id)}
          />
        ))}
        {videos.map(({ id }, idx) => (
          <MediaItem
            dataPendo="view-video-performance"
            key={`video-${id}`}
            iconName="video"
            title={`Video Performance: Video ${idx + 1}`}
            reportUrl={getVideoUrl(id)}
          />
        ))}
      </Flex>
    </Box>
  );
};
