import * as React from 'react';
import { useCallback } from 'react';
import { Filter } from 'models/journeys/filter';
import { JourneyFilter } from './JourneyFilter';
import { NewFiltersButton } from './NewFiltersButton';
import styles from './filters.module.css';

export const JourneyFilters: React.FC<{
  filters: Filter[];
  onChange: (filters: Filter[]) => void;
}> = ({ filters, onChange }) => {
  const handleRemove = useCallback(
    (filter: Filter) => {
      onChange(
        filters.map((f) =>
          f.name !== filter.name ? f : { ...filter, active: false }
        )
      );
    },
    [onChange, filters]
  );

  const handleAdd = useCallback(
    (filter: Filter) => {
      onChange(
        filters.map((f) =>
          f.name !== filter.name ? f : { ...filter, active: true }
        )
      );
    },
    [onChange, filters]
  );

  const handleFilterChange = useCallback(
    (filter: Filter) => {
      onChange(filters.map((f) => (f.name !== filter.name ? f : filter)));
    },
    [filters, onChange]
  );

  const inactiveFilters = filters.filter((filter: Filter) => !filter.active);

  return (
    <div className={styles.filtersWrapper}>
      {filters
        .filter((f) => f.active)
        .map((filter: Filter) => (
          <JourneyFilter
            key={filter.name}
            filter={filter}
            onChange={handleFilterChange}
            onRemove={handleRemove}
          />
        ))}
      {inactiveFilters.length > 0 && (
        <NewFiltersButton
          filters={filters.filter((f) => !f.active)}
          onSelect={handleAdd}
        />
      )}
    </div>
  );
};
