import { useInfiniteQuery } from 'react-query';
import {
  fetchUserActivityStreamData,
  UserActivityStreamData,
} from '../services/api-insights';
import { useProgram } from '../contexts/program';
import { InfiniteQueryResponse } from './common';

export type UserActivityStreamCollectionData = {
  data: Array<UserActivityStreamData>;
  nextPage: () => boolean;
};

interface UseCampaignActivityDataProps {
  campaignId: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  search: string;
  channel?: 'Email';
}

export const useCampaignActivityDataInfiniteQuery = ({
  campaignId,
  pageSize,
  sortBy,
  sortDirection,
  search,
  channel,
}: UseCampaignActivityDataProps): InfiniteQueryResponse<
  UserActivityStreamData
> => {
  const { id: programId } = useProgram();

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<UserActivityStreamCollectionData, Error>(
    ['campaignActivity', campaignId, sortBy, sortDirection, search, channel],
    async ({ pageParam = 0 }) => {
      const response = await fetchUserActivityStreamData({
        contentId: campaignId,
        programId,
        page: pageParam,
        pageSize,
        sortBy,
        sortDirection,
        search,
        channel,
      });
      return {
        data: response.data ?? [],
        nextPage: response.data.length === pageSize ? pageParam + 1 : null,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  const rowData = data
    ? data.pages.map((batch) => (batch?.data || []).map((item) => item)).flat(1)
    : [];

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: rowData,
  };
};
