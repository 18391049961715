import React from 'react';
import {
  ListDropdownItem,
  ListDropdownProvider,
} from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';

/*
 * this provider wraps around the ListDropdownContext by..
 *
 * 1. continuously accumulating a list of paginated items (nextPageOfItems)
 * implementing components will also need to provide pagination-related props
 * recommended: utilize the `useSemiPaginatedQuery()` hook with this provider
 * recommended2: utilize the `PaginatedDropdown` component with this provider
 *
 * 2. taking a list of all items (allItems).
 * this is the case when using `useInfiniteQuery()` hook
 *
 * USE ONLY options 1 or 2, do not send both props
 * */

export type PaginatedListDropdownContextType = {
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void>;
  isLoadingMoreItems: boolean;
  hasMoreToLoad: boolean;
  errorMessage: string | undefined;
  onSearch?: () => void;
};
export const PaginatedListDropdownContext = React.createContext<
  PaginatedListDropdownContextType
>({
  loadMoreItems: () => new Promise<void>((res) => res()),
  isLoadingMoreItems: false,
  hasMoreToLoad: true,
  errorMessage: undefined,
  onSearch: undefined,
});

type PaginatedListDropdownProviderPropsType = {
  allItems: ListDropdownItem[];
  fetchNext: () => void;
  isLoadingMoreItems: boolean;
  hasMoreToLoad: boolean;
  defaultValues?: Set<string>;
  errorMessage?: string;
  onSearch?: () => void;
};
export const PaginatedListDropdownProvider: React.FC<PaginatedListDropdownProviderPropsType> = ({
  children,
  allItems,
  fetchNext,
  isLoadingMoreItems,
  hasMoreToLoad,
  defaultValues,
  errorMessage,
  onSearch,
}) => {
  // function used by <InfiniteLoader /> to load more items
  // for us it will always wrap the `fetchNext()` in a promise
  // fetchNext() should update `allItems` that are passed in
  const loadMoreItems = (): Promise<void> => {
    return new Promise((resolve) => {
      if (fetchNext !== undefined) fetchNext();
      resolve();
    });
  };

  return (
    <PaginatedListDropdownContext.Provider
      value={{
        loadMoreItems,
        isLoadingMoreItems,
        hasMoreToLoad,
        errorMessage,
        onSearch,
      }}
    >
      <ListDropdownProvider allItems={allItems} defaultValues={defaultValues}>
        {children}
      </ListDropdownProvider>
    </PaginatedListDropdownContext.Provider>
  );
};
