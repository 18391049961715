import { PaginatedListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/PaginatedListDropdown';
import { ListDropdownItem } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { PaginatedListDropdownProvider } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { useProgram } from 'contexts/program';
import { useTopicsInfiniteQuery, useTopicsQuery } from 'hooks/topics';
import { Topic } from 'models/topic';
import React from 'react';

export const TopicsListDropdown: React.FC = () => {
  const { id: programId } = useProgram();
  const { defaultValues, debouncedSearchString } = React.useContext(
    FilterDropdownContext
  );
  const defaultIds = (defaultValues
    ? Array.from(defaultValues.values())
    : []
  ).map((i) => parseInt(i, 10));

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTopicsInfiniteQuery({
    programId,
    search: debouncedSearchString,
  });

  const { data: initialData, isLoading: isInitialDataLoading } = useTopicsQuery(
    {
      programId,
      enabled: !!(defaultIds && defaultIds.length > 0),
      ids: defaultIds,
    }
  );

  // note: useTopicsInfiniteQuery() returns the aggregated list of all records
  const allItems: ListDropdownItem[] = React.useMemo(() => {
    if (data === undefined && initialData === undefined) return [];
    // Dedupe
    const mergedDataHash: { [key: string]: Topic } = {};

    if (!debouncedSearchString) {
      initialData?.forEach((t) => {
        mergedDataHash[`${t.id}`] = t;
      });
    }
    data?.forEach((t) => {
      mergedDataHash[`${t.id}`] = t;
    });

    // Back to an array
    const mergedData: Topic[] = Object.keys(mergedDataHash).map(
      (k) => mergedDataHash[k]
    );
    return mergedData.map((topic) => ({
      label: String(topic.name),
      value: String(topic.id),
      uuid: String(topic.id),
    }));
  }, [data, initialData, debouncedSearchString]);

  return (
    <PaginatedListDropdownProvider
      allItems={allItems}
      fetchNext={fetchNextPage}
      isLoadingMoreItems={
        isLoading || isInitialDataLoading || isFetchingNextPage
      }
      hasMoreToLoad={hasNextPage || false}
      defaultValues={defaultValues}
    >
      <PaginatedListDropdown />
    </PaginatedListDropdownProvider>
  );
};
