import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { KeepOpen } from '../../shared/KeepOpen';

export const ExitBlocker: React.FC = () => (
  <KeepOpen keepOpenLabel="Edit Links" confirmCloseLabel="Close Anyway">
    <Type.Heading block>At least 1 link is required</Type.Heading>
    <Type.Body block>
      You will not be able to save the content until you add at least 1 social
      link.
    </Type.Body>
  </KeepOpen>
);
