import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Button, Checkbox, Section } from 'DesignSystem/Form';
import { fetchFeatureFlagsV2 } from 'services/api-feature-flags';
import { QueryResponse } from 'hooks/common';
import { FeatureFlag } from 'models/feature-flag';
import { useQuery } from 'react-query';
import { Body } from 'DesignSystem/Typography';
import { useProgram } from 'contexts/program';
import { acceptAITerms } from 'services/api-accept-ai-terms';
import styles from './commsai-review-terms.module.css';

export const useFeatureFlagsQuery = (
  programId: number,
  flag: string,
  user_id?: number
): QueryResponse<FeatureFlag<boolean>> => {
  const { isLoading, error, data } = useQuery<FeatureFlag<boolean>, Error>(
    ['feature_flag', programId, flag, user_id],
    () => fetchFeatureFlagsV2(programId, flag, user_id, { cache: false }),
    { retry: false, refetchOnMount: false, refetchOnWindowFocus: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const CommsAIReviewTerms: React.FC<RouteComponentProps> = () => {
  const program = useProgram();

  const terms = `In order to use Firstup's CommunicationAI Integration (the "Integration"), an authorized signatory of your organization must first agree to the terms and conditions set forth in Generative Artificial Intelligence Integration Product Attachment/Addendum (the "AI Terms") set forth in the link below, which will apply to your organization's use of the Integration, notwithstanding any terms or conditions to the contrary set forth in any existing agreement between your organization and Firstup.`;

  const checkboxLabel = (
    <>
      I understand by clicking the &quot;Accept&quot; button below, I am
      accepting the{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://firstup.io/legal/generative-artificial-intelligence-integration-product-attachment-addendum/"
      >
        AI Terms
      </a>{' '}
      on behalf of my organization and am representing that I am authorized to
      do so.
    </>
  );

  const [message, setMessage] = React.useState('');
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const handleChange = (checked: boolean) => {
    setTermsAccepted(checked);
  };

  const handleAccept = async () => {
    try {
      const response = await acceptAITerms({
        tenant: `program:${program.id}`,
        document: '2024-ai-terms-v1',
        accepted: termsAccepted,
      });
      if (response.status === 200) {
        const url = `/${program.id}/configure/feature-settings`;
        window.location.href = url;
      }
    } catch (error) {
      setMessage('You are not authorized to accept the terms.');
    }
  };

  return (
    <WithPermission permissions={['featureEditAccess']}>
      <FormPage
        title="CommunicationAI Terms"
        breadcrumbs={[
          { label: 'Configure', to: '..' },
          { label: 'Feature Settings', to: '..' },
          { label: 'CommunicationAI Terms' },
        ]}
      >
        <Section>
          <Body>
            <div className={styles.Terms}>
              {terms}
              <div className={styles.TermsLink}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://firstup.io/legal/generative-artificial-intelligence-integration-product-attachment-addendum/"
                >
                  AI Terms
                </a>
              </div>
            </div>
            <Checkbox
              onChange={handleChange}
              className={styles.TermsCheckbox}
              checked={termsAccepted}
              label={checkboxLabel}
            />
            <Button
              onClick={handleAccept}
              disabled={!termsAccepted}
              className={styles.TermsAcceptButton}
              label="Accept Terms"
            />
            {message && <div className={styles.TermsMessage}>{message}</div>}
          </Body>
        </Section>
      </FormPage>
    </WithPermission>
  );
};
