import * as React from 'react';
import { User } from 'models/user';
import { InfiniteSelect, InfiniteLoadProps } from 'shared/InfiniteSelect';
import avatar from 'assets/images/Avatars.png';
import { splitWords } from 'utility/text';
import styles from './users-select.module.css';

export type PropsType = {
  values: Array<User>;
  selectedIds: Array<string>;
  onSelectedIdsChange: (ids: Array<string>) => void;
  maxHeight?: number;
} & InfiniteLoadProps;

export const Dropdown: React.FC<PropsType> = (props) => {
  const {
    values,
    selectedIds,
    onSelectedIdsChange,
    maxHeight = 400,
    // Infinite load props
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
  } = props;

  const itemHeight = 60;

  const rowIds: Array<string> = values.map((item) => String(item.id));

  const formatRole = (role: string) => {
    switch (role) {
      case 'channel_contributor':
        return 'topic_manager';
      case 'program_admin':
      case 'program_manager':
        return 'community_manager';
      default:
        return role;
    }
  };

  const rowRenderProp = (id: string) => {
    const user = values.find((item) => String(item.id) === id);
    const userRole = splitWords(formatRole(user?.role || ''));

    if (user) {
      const { firstName, lastName } = user;
      return (
        <div key={id} className={styles.row}>
          {user.avatarUrl && !user.avatarUrl.startsWith('/') ? (
            <div
              className={styles.avatar}
              style={{
                backgroundImage: `url(${user.avatarUrl})`,
              }}
            />
          ) : (
            <div className={styles.avatar}>
              <img alt="user avatar" src={avatar} />
            </div>
          )}
          <div>
            <div className={styles.title}>{`${firstName} ${lastName}`}</div>
            <div className={styles.role}>{userRole}</div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.dropdown}>
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={rowRenderProp}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        itemHeight={itemHeight}
        maxHeight={maxHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        threshold={threshold}
        overscan={overscan}
      />
    </div>
  );
};
