import { useQuery, useInfiniteQuery } from 'react-query';
import { ExternalSource } from 'models/external-source';
import {
  QueryResponse,
  InfiniteQueryResponse,
  nextPageToFetch,
  MutationOptions,
  BulkSelection,
  ExternalSourceActionFilters,
} from './common';
import {
  fetchExternalSources,
  FetchProps,
  ExternalSourcesCollectionData,
  ExternalSourceData,
  fetchById,
  updateExternalSource,
  createExternalSource,
  archiveExternalSource,
  harvestExternalSource,
  bulkArchiveExternalSources,
  bulkUnarchiveExternalSources,
  unarchiveExternalSource,
} from '../services/api-external-source';

export function mapServerDataToExternalSources(
  serverData: ExternalSourcesCollectionData
): Array<ExternalSource> {
  return serverData.data.map((entity: ExternalSourceData) => entity.attributes);
}

export const useExternalSourcesQuery = (
  props: FetchProps
): QueryResponse<Array<ExternalSource>> => {
  const { isLoading, error, data } = useQuery<
    ExternalSourcesCollectionData,
    Error
  >(['external_sources', { ...props }], () => fetchExternalSources(props), {
    retry: false,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapServerDataToExternalSources(data),
  };
};

export const useExternalSourcesInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<ExternalSource> => {
  const {
    programId,
    search,
    pageSize = 20,
    sortBy,
    sortDirection,
    statuses,
    types,
    autoPublish,
    shareable,
  } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ExternalSourcesCollectionData, Error>(
    ['external-sources-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchExternalSources({
        programId,
        search,
        pageSize,
        sortBy,
        sortDirection,
        statuses,
        types,
        autoPublish,
        shareable,
        page: pageParam as number,
      }),
    {
      cacheTime: 0,
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToExternalSources(batch) : []))
      .flat(1);
  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

export const useExternalSourceQuery = (
  programId: number,
  externalSourceId: number
): QueryResponse<ExternalSource> => {
  const { isLoading, error, data } = useQuery<ExternalSourceData, Error>({
    queryFn: () => fetchById(programId, externalSourceId),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.attributes,
  };
};

export const useUpdateExternalSource = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  update: (externalSource: ExternalSource) => void;
} => {
  const update = (externalSource: ExternalSource) => {
    const data = externalSource;
    if (externalSource.contentChannels.length > 0) {
      data.active = true;
    }
    updateExternalSource(programId, data)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useCreateExternalSource = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  create: (externalSource: Partial<ExternalSource>) => void;
} => {
  const create = (externalSource: Partial<ExternalSource>) => {
    createExternalSource(programId, externalSource)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { create };
};

export const useArchiveExternalSource = (
  programId: number,
  { onSuccess, onError }: MutationOptions<ExternalSource> = {}
): {
  archive: (externalSourceId: number) => void;
} => {
  const archive = (externalSourceId: number) => {
    archiveExternalSource(programId, externalSourceId)
      .then((data) => {
        if (onSuccess) onSuccess(data.attributes);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { archive };
};

export const useUnarchiveExternalSource = (
  programId: number,
  { onSuccess, onError }: MutationOptions<ExternalSource, Error> = {}
): {
  unarchive: (externalSourceId: number) => void;
} => {
  const unarchive = (externalSourceId: number) => {
    unarchiveExternalSource(programId, externalSourceId)
      .then((data) => {
        if (onSuccess) onSuccess(data.attributes);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { unarchive };
};

export const useHarvestExternalSource = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  harvest: (externalSourceId: number) => void;
} => {
  const harvest = (externalSourceId: number) => {
    harvestExternalSource(programId, externalSourceId)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { harvest };
};

export const useBulkArchiveExternalSources = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  bulkArchive: (
    bulkSelection: BulkSelection,
    filterConfig: ExternalSourceActionFilters
  ) => void;
} => {
  const bulkArchive = (
    bulkSelection: BulkSelection,
    filterConfig: ExternalSourceActionFilters
  ) => {
    bulkArchiveExternalSources(programId, bulkSelection, filterConfig)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { bulkArchive };
};

export const useBulkUnarchiveExternalSources = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  bulkUnarchive: (
    bulkSelection: BulkSelection,
    filterConfig: ExternalSourceActionFilters
  ) => void;
} => {
  const bulkUnarchive = (
    bulkSelection: BulkSelection,
    filterConfig: ExternalSourceActionFilters
  ) => {
    bulkUnarchiveExternalSources(programId, bulkSelection, filterConfig)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { bulkUnarchive };
};
