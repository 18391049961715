import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Box } from 'DesignSystem/Components';
import { Subheading } from 'DesignSystem/Typography';
import {
  Settings,
  SHAREABLE_MESSAGE_MAX_LENGTH,
  SLUG_MAX_LENGTH,
} from 'models/publisher/settings';
import { usePublisher } from 'contexts/publisher';
import { toSlug } from 'utility/text';
import { isPublished } from 'models/content';
import {
  AcknowledgementConfig,
  acknowledgementLabelUtils,
} from 'shared/AcknowledgementConfig';
import { Label } from 'models/label';
import { EngagementFlag, EngagementFlags } from './useEngagementToggles';
import { ToggleCheckbox } from './EngagementEdit/ToggleCheckbox';
import { SharableDataForm } from './EngagementEdit/SharableDataForm';

export type EngagementEditHandle = {
  getSettings: () => {
    flags: EngagementFlags;
    slug: string;
    shareMessage: string;
    acknowledgementLabel: Label | undefined;
  };
};

type Props = {
  settings: Settings;
  onSaveDisabledChange: (disabled: boolean) => void;
  engagementFlags: EngagementFlags;
  setDismissable?: (dismissable: boolean) => void;
};

export const EngagementEdit = React.forwardRef<EngagementEditHandle, Props>(
  (
    { engagementFlags, settings, onSaveDisabledChange, setDismissable }: Props,
    ref: React.Ref<EngagementEditHandle | null>
  ) => {
    const {
      post: {
        content,
        callToAction: { title },
      },
    } = usePublisher();
    const { slug, shareMessage, acknowledgementLabel } = settings;

    const [localSlug, setLocalSlug] = useState(toSlug(slug || title));
    const [localEngagementFlags, setLocalEngagementFlags] = useState(
      engagementFlags
    );
    const [localShareMessage, setLocalShareMessage] = useState(
      shareMessage || title
    );
    const [localAcknowledgementLabel, setLocalAcknowledgementLabel] = useState<
      Label | undefined
    >(acknowledgementLabel);
    const {
      validateLabel: validateAcknowledgementLabel,
      defaultLabel: defaultAcknowledgementLabel,
    } = acknowledgementLabelUtils();

    const showSharableForm = useMemo(
      () => localEngagementFlags.isShareable.value,
      [localEngagementFlags]
    );
    const showAcknowledgeLabelSelector = localEngagementFlags.acknowledge.value;
    const [configHasChanged, setConfigHasChanged] = useState(false);

    const markConfigChange = () => {
      if (!configHasChanged) setConfigHasChanged(true);
    };

    const saveDisabled = useMemo(() => {
      const slugInvalid =
        localSlug.length === 0 || localSlug.length > SLUG_MAX_LENGTH;
      const shareInvalid =
        localShareMessage.length === 0 ||
        localShareMessage.length > SHAREABLE_MESSAGE_MAX_LENGTH;

      const acknowledgeInvalid =
        localEngagementFlags.acknowledge.value &&
        !validateAcknowledgementLabel(localAcknowledgementLabel);

      return (
        acknowledgeInvalid ||
        (showSharableForm && (slugInvalid || shareInvalid))
      );
    }, [
      localSlug.length,
      localShareMessage.length,
      localEngagementFlags.acknowledge.value,
      validateAcknowledgementLabel,
      localAcknowledgementLabel,
      showSharableForm,
    ]);

    useImperativeHandle(ref, () => ({
      getSettings() {
        return {
          flags: localEngagementFlags,
          slug: toSlug(localSlug),
          shareMessage: localShareMessage,
          acknowledgementLabel:
            localAcknowledgementLabel || defaultAcknowledgementLabel,
        };
      },
    }));

    useEffect(() => {
      onSaveDisabledChange(saveDisabled);
    }, [onSaveDisabledChange, saveDisabled]);

    const flags = useMemo(() => {
      return {
        isCommentable: localEngagementFlags.isCommentable,
        isTranslatable: localEngagementFlags.isTranslatable,
        acknowledge: {
          ...localEngagementFlags.acknowledge,
          disabled: localEngagementFlags.isShareable.value,
        },
        isShareable: {
          ...localEngagementFlags.isShareable,
          disabled: localEngagementFlags.acknowledge.value,
        },
      } as { [key: string]: EngagementFlag };
    }, [localEngagementFlags]);

    const onCheckboxChange = (name: string) => {
      const flag = localEngagementFlags[name];
      markConfigChange();
      setLocalEngagementFlags({
        ...localEngagementFlags,
        [name]: {
          ...flag,
          value: !flag.value,
        },
      });
    };

    // disable dismiss if the save button has been enabled
    useEffect(() => {
      if (setDismissable) setDismissable(saveDisabled);
    }, [setDismissable, saveDisabled]);

    useEffect(() => {
      if (!showAcknowledgeLabelSelector) {
        setLocalAcknowledgementLabel(undefined);
      }
    }, [showAcknowledgeLabelSelector, setLocalAcknowledgementLabel]);

    return (
      <>
        <Box padding={[0, 16, 16, 0]}>
          <Box padding={[32, 0, 20, 0]}>
            <Subheading bold>Engagement</Subheading>
          </Box>

          {Object.keys(flags).map(
            (flagName) =>
              flags[flagName].available && (
                <div key={flagName} data-test={`checkbox-${flagName}`}>
                  <ToggleCheckbox
                    flag={flags[flagName]}
                    onChange={() => onCheckboxChange(flagName)}
                  />

                  {flagName === 'acknowledge' && showAcknowledgeLabelSelector && (
                    <Box margin={[0, 6, 12, 63]}>
                      <AcknowledgementConfig
                        label={localAcknowledgementLabel}
                        onChange={(val: Label | undefined) => {
                          markConfigChange();
                          setLocalAcknowledgementLabel(val);
                        }}
                      />
                    </Box>
                  )}

                  {flagName === 'isShareable' && showSharableForm && (
                    <SharableDataForm
                      message={localShareMessage}
                      slug={localSlug}
                      setMessage={(val: string) => {
                        markConfigChange();
                        setLocalShareMessage(val);
                      }}
                      setSlug={(val: string) => {
                        markConfigChange();
                        setLocalSlug(val);
                      }}
                      contentWasPublished={isPublished(content)}
                    />
                  )}
                </div>
              )
          )}
        </Box>
      </>
    );
  }
);

EngagementEdit.defaultProps = {
  setDismissable: undefined,
};
