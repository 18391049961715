import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Body } from 'DesignSystem/Typography';
import { ContentTitle } from '../../SettingsCard';

export const SharableDataPreview: React.FC<{
  data: {
    shareMessage: string;
    slug: string;
  };
}> = ({ data: { shareMessage, slug } }) => (
  <Box>
    <Box padding={[23, 0, 0, 0]}>
      <ContentTitle>Sharable message</ContentTitle>
    </Box>
    <Box style={{ wordBreak: 'break-all' }}>
      <Body>{shareMessage}</Body>
    </Box>
    <Box padding={[32, 0, 0, 0]}>
      <ContentTitle>Public URL Slug</ContentTitle>
    </Box>
    <Box>
      <Body>{slug}</Body>
    </Box>
  </Box>
);
