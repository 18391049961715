import React from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useSupportedCampaignEventsQuery } from 'hooks/campaign-events';
import { OptionType } from 'hooks/common';
import { GenericSelect } from 'shared/Select';

export const SUPPORTED_CAMPAIGN_EVENTS = Object.freeze({
  'program.user.invitation.created': 'Invitation to Firstup',
  'user.program.joined': 'Sign Up to Firstup',
});

export type EventSelectProps = {
  event?: string;
  onChange: (changedEvent?: string) => void;
};

export const EventSelect = React.memo((props: EventSelectProps) => {
  const { event, onChange } = props;

  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const handleError = React.useCallback(() => {
    setFlashMessage({
      message: 'Unable to fetch events',
      severity: 'error',
    });
  }, [setFlashMessage]);

  const {
    isLoading,
    isSuccess,
    data: supportedCampaignEvents,
  } = useSupportedCampaignEventsQuery({
    programId,
    onError: handleError,
  });

  const startEventOptions: OptionType[] = React.useMemo(() => {
    if (!supportedCampaignEvents) return [];

    return supportedCampaignEvents
      .filter(
        (supportedCampaignEvent) =>
          supportedCampaignEvent.verb in SUPPORTED_CAMPAIGN_EVENTS
      )
      .map((supportedCampaignEvent) => ({
        label:
          SUPPORTED_CAMPAIGN_EVENTS[
            supportedCampaignEvent.verb as keyof typeof SUPPORTED_CAMPAIGN_EVENTS
          ],
        value: supportedCampaignEvent.verb,
      }));
  }, [supportedCampaignEvents]);

  return (
    <>
      {!isLoading && isSuccess && (
        <GenericSelect
          value={event}
          onChange={onChange}
          options={startEventOptions}
          placeholder="Choose event type"
        />
      )}
    </>
  );
});
