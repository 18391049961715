import * as React from 'react';
import { useProgram } from 'contexts/program';
import { RenderingVariables } from 'models/publisher/block';
import { Post } from 'models/publisher/post';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { hasDefaultLocalAvatar } from 'models/author';

// The only part of the post that is inspected for variable
// values is the settings. This can be used with proper Posts,
// or with the trimmed down Previewable Post. Or anything, really,
// that is willing to supply some settings to pull from.
type PostData = {
  content?: Post['content'];
  settings?: Post['settings'];
};

const getPermalinkText = (post?: PostData) => {
  if (post?.settings?.acknowledge)
    return 'This campaign requires acknowledgment. Open in the web experience';
  if (post?.settings?.isShareable)
    return 'Want to spread the news? Open in web to share!';
  if (post?.settings?.isCommentable)
    return 'Join the conversation! Open in web experience to comment';
  return 'View in web experience';
};

const getAuthorAvatar = (post?: PostData) => {
  const author = post?.settings?.contentAuthor;
  const avatarUrl = author?.avatarUrl;
  return author && avatarUrl && !hasDefaultLocalAvatar(author) ? avatarUrl : '';
};

const getPublishedAtIso = (post?: PostData) => {
  const dateTime = post?.settings?.publishedAt;
  // PUB-1846 It seems like a `string` is appearing unexpectedly..
  if (dateTime?.toISO) return dateTime.toISO();
  return '';
};

// This functional hook offers two ways of getting field variables:
//   1) provide the needed post data, and it will provide the defined variables
//   2) use the function it returns to ask the same question, but later.
//
// This hook depends on the program context, but requires the caller to provide
// the post data either up front, or for sure in the returned callback.
//
// If no data is supplied up front, reasonable defaults will be used instead,
// but these values shouldn't be relied on.
export const useFieldVariables = (
  initPostData?: Post
): RenderingVariables & {
  fromPost: (post: PostData) => RenderingVariables;
} => {
  const program = useProgram();
  const { settings } = useSettings();

  const permalinkFlag = useFeatureFlagsQuery(
    program.id,
    'Publisher.OpenInExperienceLink'
  );

  const blockTargetsEnabled = !!useFeatureFlagsQuery(
    program.id,
    'Studio.Publish.BlockTargets'
  ).data?.value;

  const useVarsFromPost = React.useCallback(
    (postData: PostData): RenderingVariables => ({
      content_permalink: postData.content?.permalinkUrl || '',
      program_theme_color: program.themeColor || '',
      program_accent_color: program.secondaryColor || '',
      program_header_image: program.headerImageUrl || '',
      program_icon_image: program.iconImageUrl || '',
      program_logo_image: program.logoImageUrl || '',
      author_name: postData?.settings?.contentAuthor?.displayName || '',
      author_avatar: getAuthorAvatar(postData),
      published_at_iso: getPublishedAtIso(postData) || undefined,
      permalink_text:
        !blockTargetsEnabled && permalinkFlag.data?.value
          ? getPermalinkText(postData)
          : '',
      read_time_in_seconds: postData?.content?.readTimeInSeconds,
    }),
    [blockTargetsEnabled, permalinkFlag.data?.value, program]
  );
  return {
    ...useVarsFromPost({
      settings,
      ...initPostData,
    }),
    fromPost: useVarsFromPost,
  };
};
