import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { Button, Section } from 'DesignSystem/Form';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { WithPermission } from 'shared/WithPermission';
import { useFlashMessage } from 'contexts/flasher';
import { ConfirmationModalEdit } from './ConfirmationModal';
import { DefaultChannelsEdit } from './DefaultChannels';
import { useDefaultChannels } from './DefaultChannels/useDefaultChannels';
import { useConfirmationModal } from './ConfirmationModal/useConfirmationModal';
import { EngagementBoostDefaultsEdit } from './EngagementBoostDefaults';
import { useEngagementBoostDefaults } from './EngagementBoostDefaults/useEngagementBoostDefaults';
import styles from './campaign-settings.module.css';

export const CampaignSettingsEdit: React.FC<RouteComponentProps> = () => {
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const onSaveSuccess = () => {
    setFlashMessage({
      message: 'Campaign Settings updated successfully',
      severity: 'info',
    });
  };

  const onSaveError = () => {
    setFlashMessage({
      message: 'Campaign Settings failed to update',
      severity: 'error',
    });
  };
  const {
    enabled: defaultChannelsEnabled,
    channelsValues,
    deliveryChannels,
    isDefault: isDefaultChannels,
    setDeliveryChannels,
    onSave: onSaveChannels,
    setToDefaults: setToDefaultChannels,
  } = useDefaultChannels(onSaveSuccess);
  const {
    engBoostDefaults,
    setEngBoostDefaults,
    enabled: engagementBoostDefault,
    isDefault: isDefaultEngagementBoost,
    onSave: onSaveEngagementBoost,
    setToDefaults: setToDefaultEngagementBoost,
  } = useEngagementBoostDefaults(onSaveSuccess, onSaveError);
  const {
    enabled: confirmationModalEnabled,
    modalEnabled,
    setModalEnabled,
    threshold,
    setThreshold,
    onSave: onSaveModal,
    isValid: isValidModalThreshold,
  } = useConfirmationModal(onSaveSuccess, onSaveError);

  const restoreDeliveryDefaultsBtn = (
    <Button
      compact
      secondary
      label="Restore Recommended Defaults"
      disabled={isDefaultChannels && isDefaultEngagementBoost}
      onClick={() => {
        setToDefaultChannels();
        setToDefaultEngagementBoost();
      }}
    />
  );

  return (
    <WithPermission permissions={['configureCampaignSettingsAccess']}>
      <ListPage
        title="Campaign Settings"
        description="Configure campaign settings and defaults"
        breadcrumbs={[
          { to: '../..', label: 'Configure' },
          { to: '..', label: 'Campaign Settings' },
        ]}
        actions={[
          {
            icon: <Icon name="SaveSuccess" />,
            label: 'Save',
            disabled: !isValidModalThreshold,
            onClick: () => {
              onSaveModal();
              onSaveChannels();
              onSaveEngagementBoost();
              navigate('.');
            },
          },
        ]}
      >
        {(defaultChannelsEnabled || engagementBoostDefault) && (
          <Section
            title="Direct Delivery Defaults"
            description="These defaults will be applied to all future campaigns, including saved templates that do not have delivery settings configured."
            button={restoreDeliveryDefaultsBtn}
            className={styles.SettingsSection}
          >
            {defaultChannelsEnabled && (
              <DefaultChannelsEdit
                channelsValues={channelsValues}
                channels={deliveryChannels}
                setChannels={setDeliveryChannels}
              />
            )}
            {engagementBoostDefault && (
              <EngagementBoostDefaultsEdit
                engBoostDefaults={engBoostDefaults}
                setEngBoostDefaults={setEngBoostDefaults}
              />
            )}
          </Section>
        )}
        {confirmationModalEnabled && (
          <Section
            title="Audience Size Confirmation"
            description="Users will be prompted to confirm their selection when the audience size threshold has been reached or exceeded"
            className={styles.SettingsSection}
          >
            <ConfirmationModalEdit
              modalEnabled={modalEnabled}
              setModalEnabled={setModalEnabled}
              threshold={threshold}
              setThreshold={setThreshold}
              isValid={isValidModalThreshold}
            />
          </Section>
        )}
      </ListPage>
    </WithPermission>
  );
};
