import * as React from 'react';
import { Blocks, Category, Content, Filter, Image, Item } from 'models/library';
import { InfiniteQueryResponse, QueryResponse } from 'hooks/common';
import { usePrevious } from 'hooks/usePrevious';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { uniqueBy } from 'utility/unique';
import { deleteLibraryItem, UpsertType } from 'services/api-library';
import { useCollection, UseCollection } from './useCollection';
import { useScrollEffect } from './useScrollEffect';

export function usePickerScreen({
  defaultCategoryLabel = 'All',
  onChange,
  onCancel,
  maxSelections,
  useLibraryItems,
  useCategories,
  omitCategories = [],
  label,
  libraryType,
}: {
  label: string;
  topOffset?: number;
  defaultCategoryLabel?: string;
  onChange: (items: Item[]) => void;
  onCancel?: () => void;
  maxSelections: number;
  useLibraryItems: (params: { filter: Filter }) => InfiniteQueryResponse<Item>;
  useCategories: () => QueryResponse<Category[]>;
  omitCategories?: Category['identifier'][];
  libraryType: Image['type'] | Content['type'] | Blocks['type'];
}): {
  addToAppendedItems: (item: Item) => void;
  allItems: Item[];
  allowFiltering(value: boolean): void;
  canModifyCollection: boolean;
  canUploadImages: boolean;
  categories: QueryResponse<Category[]>;
  collection: UseCollection<Item>;
  defaultCategoryLabel: string;
  handleDeleteItem: (item: Item) => void;
  hasCustomCategories: boolean;
  isFilteringAllowed: boolean;
  label: string;
  libraryType: Image['type'] | Content['type'] | Blocks['type'];
  maxSelections: number;
  onCancel?: () => void;
  onChange: (items: Item[]) => void;
  refs: ReturnType<typeof useScrollEffect>['refs'];
  select: UseCollection<Item>['select'];
  setSpinnersCount(num: number): void;
  showImageUploader: boolean;
  spinnersCount: number;
} {
  const collection = useCollection(
    useLibraryItems,
    maxSelections,
    omitCategories
  );
  const allCategories = useCategories();
  const { permissions } = usePermissions();
  const { id: programId } = useProgram();
  const [spinnersCount, setSpinnersCount] = React.useState(0);
  const [isFilteringAllowed, allowFiltering] = React.useState(true);
  const appendedItems = React.useRef<typeof collection.items>([]);
  const [deletedItems, setDeletedItems] = React.useState<
    Array<string | number>
  >([]);
  const hasCustomCategories = libraryType === 'content_image';
  const select = React.useCallback(
    (item: Item) => {
      collection.select(item);
      if (maxSelections === 1) onChange([item]);
    },
    [collection, maxSelections, onChange]
  );
  const paging = React.useRef(collection.pagination);
  paging.current = { ...collection.pagination };

  const categories = React.useMemo(() => {
    const omittedCategories = [...omitCategories];

    if (!omittedCategories.length) return allCategories;

    return {
      ...allCategories,
      data: allCategories.data?.filter(
        (category) => !omittedCategories.includes(category.identifier)
      ),
    };
  }, [omitCategories, allCategories]);

  const { refs } = useScrollEffect(paging);
  refs.sidebarSize.current = (categories.data || []).length;

  const canModifyCollection = React.useMemo(() => {
    // Return immediately if missing permission for the relative libraryType
    if (
      libraryType === 'content_post' ||
      (libraryType === 'content_image' && !permissions.libraryImagesAccess) ||
      (libraryType === 'content_blocks' &&
        !permissions.libraryCustomBlocksAccess)
    )
      return false;

    const selectedCategory = categories.data?.find(
      (category) =>
        collection.filter.type === 'category' &&
        category.id === collection.filter.id
    );

    if (libraryType === 'content_image')
      return (
        collection.filter.type === 'category' &&
        (selectedCategory === undefined || selectedCategory.program_id !== null)
      );

    if (libraryType === 'content_blocks')
      return selectedCategory?.identifier === 'custom_block';

    return false;
  }, [
    libraryType,
    permissions.libraryImagesAccess,
    permissions.libraryCustomBlocksAccess,
    categories.data,
    collection.filter,
  ]);

  const prevFilter = usePrevious(collection.filter);

  const addToAppendedItems = React.useCallback(
    (item: Item) => {
      const newAppendedItems = [item, ...appendedItems.current];
      appendedItems.current = newAppendedItems;
    },
    [appendedItems]
  );

  const allItems = React.useMemo(() => {
    const newItems = uniqueBy<Item>(
      [...appendedItems.current, ...collection.items],
      (item) => item.id
    );
    return newItems.filter((i) => !deletedItems.includes(i.id));
  }, [appendedItems, collection.items, deletedItems]);

  const handleDeleteItem = React.useCallback(
    (item: Item) => {
      deleteLibraryItem({ programId, item: item as UpsertType['item'] });
      setDeletedItems([...deletedItems, item.id]);
    },
    [deletedItems, programId]
  );

  React.useEffect(() => {
    if (
      collection.filter.type === 'category' &&
      prevFilter &&
      prevFilter.type === 'category' &&
      prevFilter.id !== collection.filter.id
    ) {
      appendedItems.current = [];
    }
  }, [collection.filter, prevFilter]);

  const isImagesLibrary = React.useMemo(() => {
    return libraryType === 'content_image';
  }, [libraryType]);

  const canUploadImages = React.useMemo(() => {
    return canModifyCollection && isImagesLibrary;
  }, [canModifyCollection, isImagesLibrary]);

  const showImageUploader = React.useMemo(() => {
    return canUploadImages && !allItems.length;
  }, [allItems.length, canUploadImages]);
  return {
    addToAppendedItems,
    allItems,
    allowFiltering,
    canModifyCollection,
    canUploadImages,
    categories,
    collection,
    defaultCategoryLabel,
    handleDeleteItem,
    hasCustomCategories,
    isFilteringAllowed,
    label,
    libraryType,
    maxSelections,
    onCancel,
    onChange,
    refs,
    select,
    setSpinnersCount,
    showImageUploader,
    spinnersCount,
  };
}
