import * as React from 'react';
import cx from 'classnames';
import { RouteComponentProps } from '@reach/router';
import { Icon } from '@socialchorus/shared-ui-components';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Section } from 'DesignSystem/Form';
import { Gray10PillClass, Pill } from 'DesignSystem/Components/Pill';
import { useProgram } from 'contexts/program';
import { useUserGeneratedContentTopicLimitQuery } from 'hooks/feature-flags';
import { Spinner } from '../Shared/Spinner';
import styles from './topics.module.css';

export const TopicsSettingsPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const {
    isLoading,
    errorMessage,
    data,
  } = useUserGeneratedContentTopicLimitQuery(programId);

  const topicLimitCount = data?.userGeneratedContentTopicLimit?.value;
  const topicLimitEnabled = !!topicLimitCount && +topicLimitCount > 0;

  const iconName = topicLimitEnabled ? 'check_circle' : 'highlight_off';

  return (
    <WithPermission permissions={['configureTopicLimit']}>
      <FormPage
        title="Global Topic Settings"
        breadcrumbs={[
          { to: '../..', label: 'Configure' },
          { to: '..', label: 'Topics' },
          { label: 'Global Settings' },
        ]}
      >
        {errorMessage && <>{errorMessage}</>}
        {isLoading && <Spinner />}
        {!errorMessage && !isLoading && (
          <Section
            title="Topic Posting Limit"
            description="Restrict the number of topics a member can post in to prevent spam and maintain content relevance."
            href="./edit"
          >
            <div className={styles.topicLimitPillContainer}>
              <Pill
                icon={<Icon>{iconName}</Icon>}
                className={Gray10PillClass}
                value={
                  <>
                    Restrict Number of Topics Users Can Post To:&nbsp;
                    <b>{topicLimitEnabled ? 'ON' : 'OFF'}</b>
                  </>
                }
              />
            </div>

            {topicLimitEnabled ? (
              <div
                className={cx(
                  'text-body-2',
                  styles.topicLimitCriteriaDisplayContainer
                )}
              >
                Confirmation Criteria:&nbsp;
                <b>maximum&nbsp;{topicLimitCount}&nbsp;topics</b>
              </div>
            ) : null}
          </Section>
        )}
      </FormPage>
    </WithPermission>
  );
};
