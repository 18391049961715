import React from 'react';
import { User } from 'models/user';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from '../../../user-profile.module.css';

export const Questions: React.FC<{
  data: User;
}> = ({ data }) => {
  const { responses } = data;
  if (!responses || !responses.length)
    return (
      <Section title="Questions" className={styles.userPage}>
        <Row>
          <Block>
            <div className={styles.empty}>
              This user does not have any questions.
            </div>
          </Block>
        </Row>
      </Section>
    );

  return (
    <Section title="Questions" className={styles.userPage}>
      {responses.map((response) => (
        <Fieldset key={response.question_id}>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label={response.question_text}>
              {response.answer_text || 'NA'}
            </Field>
          </div>
        </Fieldset>
      ))}
    </Section>
  );
};
