import React from 'react';
import { Box } from 'DesignSystem/Components';
import { FieldLabel } from 'DesignSystem/Typography';
import { EmailSenderAliasField } from 'App/Program/Editors/Publisher/Deliver/Channels/EmailSenderAliasField';
import { EmailSenderAlias } from 'models/publisher/settings';
import { CommunicationStepErrors } from 'models/journeys/journey-errors';
import { NotificationField } from './NotificationField';

export const EmailConfig: React.FC<{
  emailSenderAlias?: EmailSenderAlias;
  subject: string;
  previewText: string;
  onEmailAliasChange: (alias: EmailSenderAlias) => void;
  onSubjectChange: (subject: string) => void;
  onPreviewTextChange: (previewText: string) => void;
  disabled: boolean;
  errors?: CommunicationStepErrors;
}> = ({
  emailSenderAlias,
  subject,
  previewText,
  onEmailAliasChange,
  onSubjectChange,
  onPreviewTextChange,
  disabled,
  errors,
}) => {
  return (
    <>
      <EmailSenderAliasField
        onAliasChange={onEmailAliasChange}
        value={emailSenderAlias}
        disabled={disabled}
        asModal={false}
        hasError={!!errors?.emailChannelAddress?.length}
      />
      <Box dataTest="subject-line" margin={[16, 0, 0, 0]}>
        <FieldLabel>Subject Line</FieldLabel>
        <NotificationField
          errorMessage={errors?.emailChannelSubject?.join(', ')}
          field={subject}
          onChange={onSubjectChange}
          fieldName=""
          placeholder="Notification Placeholder"
          disabled={disabled}
        />
      </Box>
      <Box dataTest="preview" margin={[0, 0, 0, 0]}>
        <FieldLabel>Preview</FieldLabel>
        <NotificationField
          errorMessage={errors?.emailChannelPreview?.join(', ')}
          field={previewText}
          onChange={onPreviewTextChange}
          fieldName=""
          placeholder="Notification Placeholder"
          disabled={disabled}
        />
      </Box>
    </>
  );
};

export const PushConfig: React.FC<{
  text: string;
  onUpdateText: (text: string) => void;
  disabled: boolean;
}> = ({ text, onUpdateText, disabled }) => {
  return (
    <Box dataTest="push-notification" margin={[16, 55, 0, 0]}>
      <FieldLabel>Message</FieldLabel>
      <NotificationField
        field={text}
        onChange={onUpdateText}
        fieldName=""
        placeholder="TEXT PLACEHOLDER"
        disabled={disabled}
      />
    </Box>
  );
};
