import { RouteComponentProps, useParams } from '@reach/router';
import * as React from 'react';
import { useProgram } from 'contexts/program';
import { useCustomSlug } from 'hooks/custom-slugs';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useActions } from './useActions';
import { SlugEditForm } from './components/SlugEditForm';

export const CustomSlugsEdit: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { id } = useParams();
  const { data, isLoading } = useCustomSlug(programId, Number(id));
  const { update } = useActions();

  const content = data?.content;
  const contentLoaded = data?.contentId ? !!(data.contentId && content) : true;

  return (
    <>
      {(isLoading || !contentLoaded) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && contentLoaded && (
        <SlugEditForm
          customSlug={data}
          action={update}
          editing
          originalContent={content}
        />
      )}
    </>
  );
};
