import * as React from 'react';
import cx from 'classnames';
import { Icon } from '../Icon';
import styles from './multi-value-text-input.module.css';

type PropsType = {
  textValue?: string;
  onTextValueChange: (value: string) => void;
  placeholder?: string;
  callToAction?: string;
  selectedValues?: Array<string>;
  onSelectedValuesChange?: (values: Array<string>) => void;
  onRemoveSelectedValueAt?: (index: number) => void;
  onFocus?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  icon?: React.ReactNode;
  disabled?: boolean;
};

/**
 * There are two available callbacks for being notified that a selected value is to be removed.
 * Both are optional, but at least one should be used.
 *
 * `onSelectedValuesChange` is for the simple case that the string labels for the selected values
 *    match one for one with the available values (all are simple strings) and no further
 *    processing is needed upon removal beyond deleting it from the `selectedValues` array.
 *
 *  `onRemoveSelectedValueAt` passes back the index of the item to be removed to allow for more
 *    flexibility on how to remove the item.  For example, in the case of an audience selector,
 *    the labels do not match one for one with the choices as the labels likely will be shortened
 *    versions of the audience name and will not contain a description.  Also, when a selection is
 *    removed, it should be added back to the pool of available choices, so passing the index back
 *    to the parent will allow for that additional processing.
 */
export const MultiValueTextInput: React.FC<PropsType> = (props) => {
  const {
    textValue,
    onTextValueChange,
    placeholder,
    callToAction,
    icon,
    selectedValues = [],
    onSelectedValuesChange,
    onRemoveSelectedValueAt,
    onFocus,
    inputRef: suppliedInputRef,
    disabled,
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const myRef = React.useRef<HTMLInputElement>(null);
  const inputRef = suppliedInputRef || myRef;

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    onTextValueChange(event.target.value);
  }

  function handleClear() {
    onTextValueChange('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const handleTextInputFocus = React.useCallback(() => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  function handleTextInputBlur() {
    setIsFocused(false);
  }

  const showCallToAction = React.useMemo(
    () =>
      callToAction &&
      callToAction.length > 0 &&
      (!selectedValues || selectedValues.length === 0) &&
      !isFocused,
    [callToAction, selectedValues, isFocused]
  );

  const hideCallToAction = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      handleTextInputFocus();
    }
  }, [handleTextInputFocus, inputRef]);

  const placeholderText = React.useMemo(() => {
    if (callToAction && showCallToAction) {
      if (isFocused) {
        return placeholder;
      }
      return '';
    }
    return placeholder;
  }, [isFocused, callToAction, placeholder, showCallToAction]);

  function handleRemove(index: number) {
    hideCallToAction();
    if (onSelectedValuesChange) {
      onSelectedValuesChange(selectedValues.filter((_value, i) => index !== i));
    }
    if (onRemoveSelectedValueAt) {
      onRemoveSelectedValueAt(index);
    }
  }
  // Note: The label is not guaranteed to be unique, so it is probably not the best choice for the key.
  // But, it is highly, highly unlikely not to be unique and the use case for this component is as part
  // of the search bar in multi select dropdown.  If we have duplicates in the dropdown choices, that's
  // a whole other issue....
  return (
    <div className={cx(styles.multiValueTextInput, { focus: isFocused })}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {showCallToAction && (
        <button
          type="button"
          onClick={hideCallToAction}
          className={styles.callToAction}
        >
          {callToAction}
        </button>
      )}
      {selectedValues.map((label, index) => (
        <div className={styles.selected} key={`${label}`}>
          <span>{label}</span>
          <button
            type="button"
            className={styles.remove}
            onClick={() => handleRemove(index)}
          >
            <Icon iconName="Times" size={12} iconType="SVG" />
          </button>
        </div>
      ))}
      <div className={styles.inputAndClear}>
        <input
          type="text"
          value={textValue}
          onChange={handleTextChange}
          placeholder={placeholderText}
          ref={inputRef}
          onFocus={handleTextInputFocus}
          onBlur={handleTextInputBlur}
          disabled={disabled}
        />
        {textValue && (
          <button
            type="button"
            className={styles.clear}
            onMouseDown={handleClear}
          >
            <span>
              <Icon iconName="Times" size={12} iconType="SVG" />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};
