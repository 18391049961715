import { useState, useEffect } from 'react';

// Pilfered from https://usehooks.com/useDebounce/
export function useDebounce<T>(value: T, delay = 300): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// useDebounce function is used a lot, so I'm adding a variant that returns
// whether the timeout is active or not
// to prevent the need to complicate the code in the places where normal useDebounce is used
export function useDebounceWithState<T>(
  value: T,
  delay = 300
): { debouncedValue: T; isWaiting: boolean } {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    // Update debounced value after delay
    setIsWaiting(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsWaiting(false);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      setIsWaiting(false);
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, isWaiting };
}
