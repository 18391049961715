import React from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import { Body, Caption } from 'DesignSystem/Typography';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { SVGIconName } from 'shared/Icon/SVGIcon/definitions';
import styles from './form.module.css';

type PropsType = {
  flag: string;
  icon: SVGIconName;
  title: string;
  description: string;
  enabled: boolean;
  onChange: (name: string, value: boolean, undo: () => void) => void;
  disabled?: boolean;
};

export const Row: React.FC<PropsType> = ({
  flag,
  icon,
  title,
  description,
  enabled,
  onChange,
  disabled,
}) => {
  const [checked, setChecked] = React.useState<boolean>(enabled);

  const PermissionInfo = (
    <div className={styles.PermissionInfo}>
      <div className={styles.PermissionIcon}>
        <SVGIcon name={icon} />
      </div>
      <div className={styles.PermissionCol}>
        <div className={styles.Title}>
          <Body bold>{title}</Body>
        </div>
        <div>
          <Caption>{description}</Caption>
        </div>
      </div>
    </div>
  );

  const undo = () => {
    setChecked(checked);
  };

  return (
    <div className={styles.PermissionRow}>
      <div className={styles.Permission}>
        {PermissionInfo}
        <div className={styles.PermissionCol}>
          <FlipSwitch<boolean>
            theme={disabled ? 'inactive' : 'default'}
            disabled={disabled}
            onChange={() => {
              setChecked(!checked);
              onChange(flag, !checked, undo);
            }}
            on={checked}
          />
        </div>
      </div>
    </div>
  );
};
