import React from 'react';
import {
  linksToFields,
  fieldsToLinks,
  LinkFieldData,
  fieldToLink,
  LINK,
  SOCIAL,
  toLinkFieldData,
  LinkData,
  SocialFieldData,
  SocialData,
  fieldToSocial,
  toSocialFieldData,
} from 'models/publisher/block';

import { FieldProps } from '../FieldProps';
import { useLinksSourceMenu } from './useLinksSourceMenu';

export const useMultiLinks: (
  props: FieldProps<(LinkFieldData | SocialFieldData)[]>,
  source: ReturnType<typeof useLinksSourceMenu>
) => {
  current?: LinkData | SocialData;
  applyEdits: (data: LinkData | LinkData[] | SocialData | SocialData[]) => void;
  closeEditor: () => void;
  links: (LinkData | SocialData)[];
  openEditorByIndex: (index: number) => void;
  listUpdate: (data: (LinkData | SocialData)[]) => void;
  clearList: () => void;
} = ({ data, default_data, onChange, field }, source) => {
  const [links, setLinks] = React.useState(fieldsToLinks(data || default_data));
  const [editingIndex, setEditingIndex] = React.useState<number | undefined>();

  const applyEdits = React.useCallback(
    (updated: LinkData | LinkData[] | SocialData | SocialData[]) => {
      if (editingIndex === undefined)
        throw new Error(`failed to edit link[undefined]`);
      else {
        // the "else" is redundant, but makes TS happy
        const collection = Array.isArray(updated) ? updated : [updated];
        const prior = links.slice(0, editingIndex);
        const after = links.slice(editingIndex + 1);
        const newLinks = [...prior, ...collection, ...after];
        setLinks(newLinks);
        onChange(linksToFields(newLinks));
        source.close();
        setEditingIndex(undefined);
      }
    },
    [links, onChange, editingIndex, source]
  );

  const listUpdate = React.useCallback(
    (updated: Array<LinkData | SocialData>) => {
      setLinks(updated);
      onChange(linksToFields(updated));
    },
    [onChange]
  );

  const clearList = React.useCallback(() => listUpdate([]), [listUpdate]);

  const openEditorByIndex = React.useCallback(
    (index: number) => {
      source.close();
      // if (!links[index])
      //   throw new Error(`failed to open editor for link[${index}]`);
      setEditingIndex(index);
    },
    [source]
  );

  const closeEditor = React.useCallback(() => {
    source.close();
    setEditingIndex(undefined);
  }, [source]);

  const current = React.useMemo(() => {
    if (editingIndex === undefined) return undefined;
    if (links[editingIndex]) return links[editingIndex];
    const newLink = field.name === 'social' ? { ...SOCIAL } : { ...LINK };
    if (editingIndex === links.length)
      if (newLink.type === 'social') {
        return fieldToSocial(toSocialFieldData(newLink));
      }
    return fieldToLink(toLinkFieldData(newLink));
  }, [field.name, editingIndex, links]);

  return {
    current,
    applyEdits,
    closeEditor,
    links,
    openEditorByIndex,
    listUpdate,
    clearList,
  };
};
