import React from 'react';
import { ChartDimensions } from './Util';
import { NoDataChart } from './NoDataChart';

interface INumberChart {
  data: number;
  chartDimensions?: NumberChartDimensions;
}

type NumberChartDimensions = ChartDimensions;

export const NumberChart: React.FC<INumberChart> = ({
  data,
  chartDimensions = {
    width: 300,
    height: 300,
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
}) => {
  const { width, height } = chartDimensions;

  return data === undefined ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <text
        x={width * 0.5}
        y={height * 0.5}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={30}
      >
        {data.toLocaleString('en', { maximumFractionDigits: 0 })}
      </text>
    </svg>
  );
};
