import * as React from 'react';
import { SVGIconName } from 'shared/Icon/SVGIcon/definitions';
import { SVGIcon, isSvgIconName } from './SVGIcon';
import { FAIcon } from './FAIcon';

// This is intended to be used by larger components that contain an Icon (like an IconMenuItem), so
// they are prefixed with "icon" to distinguish them from the name or type of the containing thing.
export type IconType = {
  iconName?: SVGIconName | string;
  iconType?: 'FA' | 'SVG';
};

export type PropsType = {
  className?: string;
  size?: number;
  useCurrentColor?: boolean;
  title?: string;
} & IconType;

export const Icon: React.FC<PropsType> = (props) => {
  const {
    className,
    iconName,
    iconType = 'FA',
    size,
    useCurrentColor,
    title,
  } = props;

  if (iconName) {
    if (isSvgIconName(iconName) && iconType === 'SVG') {
      let svgProps = {};
      if (size) {
        svgProps = { size };
      }
      if (useCurrentColor) {
        svgProps = { ...svgProps, fill: 'currentColor' };
      }
      /* eslint-disable react/jsx-props-no-spreading */
      return (
        <SVGIcon
          className={className}
          name={iconName}
          title={title}
          {...svgProps}
        />
      );
    }
    return <FAIcon className={className} name={iconName} title={title} />;
  }
  return null;
};
