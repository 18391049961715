import React from 'react';

import { Flex } from 'DesignSystem/Layout/Flex';
import { WarningTriangle } from 'shared/icons';
import { Box } from 'DesignSystem/Components';
import { JourneyModal, JourneyModalProps } from '../../JourneyModal';
import styles from '../journey-canvas-header.module.css';

export const ErrorJourneyModal: React.FC<
  Pick<JourneyModalProps, 'action'> & { title: string; error: string }
> = ({ action, title, error }) => (
  <JourneyModal
    name="Publish journey"
    header={
      <Flex start className={styles.modalErrorHeader}>
        <WarningTriangle />
        <Box padding={[3, 0, 0, 6]}>{title}</Box>
      </Flex>
    }
    body={<Flex className={styles.modalError}>{error}</Flex>}
    action={action}
    actionLabel="OK"
  />
);
