import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ContentReporter } from 'models/content-reporter';
import {
  clearContentReporters,
  fetchContentReporters,
} from 'services/api-content-flags';
import { MutationOptions, QueryResponse } from '../common';

type UseContentReportersProps = {
  programId: number;
  contentId: number;
};

export function useContentReporters({
  programId,
  contentId,
}: UseContentReportersProps): QueryResponse<ContentReporter[]> {
  return useQuery<ContentReporter[], Error>(
    ['content_reporters', programId, contentId],
    () => fetchContentReporters(programId, contentId),
    { retry: false }
  );
}

type UseClearContentReportersProps = UseContentReportersProps;
type UseClearContentReportersResponse = {
  clear: () => Promise<void>;
  isLoading: boolean;
};

export function useClearContentReporters(
  { programId, contentId }: UseClearContentReportersProps,
  { onSuccess, onError }: MutationOptions<void, Error> = {}
): UseClearContentReportersResponse {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const clear = async () => {
    setIsLoading(true);
    try {
      await clearContentReporters(programId, contentId);
      await queryClient.invalidateQueries([
        'content_reporters',
        programId,
        contentId,
      ]);
      onSuccess?.();
    } catch (error) {
      if (error instanceof Error) {
        onError?.(error);
      } else {
        onError?.(new Error('unknown error'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { clear, isLoading };
}
