import React from 'react';
import { NavLink } from 'shared/NavLink';
import styles from './sidebar.module.css';

export const Sidebar: React.FC = () => (
  <div className={styles.container}>
    <NavLink to="content-summary">Content summary</NavLink>
    <NavLink to="audience-report">Audience report</NavLink>
    <NavLink to="publisher-report">Publisher report</NavLink>
    <NavLink to="channel-report">Channel report</NavLink>
    <NavLink to="campaign-report">Campaign report</NavLink>
  </div>
);
