import React from 'react';
import { Audience } from 'models/audience';
import { Label } from 'shared/Label';
import infoStyles from '../info.module.css';

export const AudienceList: React.FC<{ audiences: Array<Audience> }> = ({
  audiences,
}) => {
  return (
    <div>
      <Label name="Audiences" containerClassName={infoStyles.labelContainer}>
        {audiences.map((audience) => (
          <div key={`${audience.id}`}>
            {audience.title} ({audience.totalUsers.toLocaleString()})
          </div>
        ))}
      </Label>
    </div>
  );
};
