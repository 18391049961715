import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { LanguageControl } from 'models/language-controls/language-control';
import {
  fetchLanguageControl,
  LanguageControlParams,
} from 'services/api-language-controls';

type UseQuery<TData, TError, TVariables> = (
  params: TVariables,
  options?: UseQueryOptions<TData, TError, TData>
) => Pick<UseQueryResult<TData, TError>, 'data' | 'isLoading' | 'isFetching'>;

export const useFetchLanguageControlQuery: UseQuery<
  LanguageControl,
  Error,
  LanguageControlParams
> = ({ programId, id }, options = {}) => {
  const { data, isLoading, isFetching } = useQuery(
    ['language-controls', programId, id],
    () => fetchLanguageControl({ programId, id }),
    options
  );
  return { data, isLoading, isFetching };
};
