import React from 'react';
import { Picker, Swatch } from 'shared/ColorPreview';
import { SidebarPopout } from './SidebarPopout';
import styles from './styles.module.css';

const PROGRAM_COLORS_TOP_OFFSET = -265;

export const Color: React.FC<{
  onChange: (value: string) => void;
  colors?: string[];
  value?: string;
  hideBrandColors?: boolean;
  disabled?: boolean;
}> = ({ onChange, colors, value, hideBrandColors, disabled }) => (
  <SidebarPopout
    dataTestButton="color-picker-trigger"
    buttonClassName={styles.preview}
    buttonChildren={<Swatch className={styles.swatch} value={value} />}
    menuChildren={
      <Picker
        onChange={onChange}
        value={value}
        colors={colors}
        hideBrandColors={hideBrandColors}
      />
    }
    topOffset={PROGRAM_COLORS_TOP_OFFSET}
    disabled={disabled}
  />
);
