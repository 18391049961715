import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { DESCRIPTION, PERMISSIONS, TITLE } from './constants';
import { useProgram } from '../../../../contexts/program';
import SearchArttributesView from './SearchAttributesView';

export const Profile: React.FC<RouteComponentProps> = ({ uri }) => {
  const { id: programId } = useProgram();

  return (
    <WithPermission permissions={PERMISSIONS}>
      <FormPage
        title={TITLE}
        description={DESCRIPTION}
        breadcrumbs={[{ label: 'Configure', to: '..' }, { label: TITLE }]}
      >
        <SearchArttributesView programId={programId} baseUrl={uri} />
      </FormPage>
    </WithPermission>
  );
};
