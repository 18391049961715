import { HalfGauge } from 'shared/statistics/HalfGauge';
import React from 'react';
import { Performance } from 'models/publisher/orchestration-insights';
import styles from './performance-insights.module.css';

export const PerformanceInsights: React.FC<{ performance: Performance }> = ({
  performance,
}) => {
  return (
    <div>
      <div className={styles.gauges}>
        {performance.gauges.map(({ label, value, signal }) => {
          return (
            <HalfGauge
              key={label}
              label={label}
              value={value}
              width={140}
              signal={signal}
            />
          );
        })}
      </div>
    </div>
  );
};
