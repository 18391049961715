import React, { useEffect } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { LitmusRole, validateRole } from 'models/role';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useFlashMessage } from 'contexts/flasher';
import { WithPermission } from 'shared/WithPermission';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Form } from './Form';
import { useActions } from './useActions';
import { alwaysEnabledPermissions } from './constant';

export const NewRolePage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const normalizeRoleNames =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.NormalizeRoleNames')
      .data?.value as boolean) || false;
  const showCustomRoles = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowCustomRoles'
  );

  useEffect(() => {
    if (showCustomRoles.isLoading) {
      return;
    }
    if (!showCustomRoles.data?.value) {
      navigate(`/${programId}/configure/roles`);
    }
  }, [
    navigate,
    programId,
    showCustomRoles.data?.value,
    showCustomRoles.isLoading,
  ]);

  const { setFlashMessage } = useFlashMessage();
  const [role, setRole] = React.useState<Partial<LitmusRole>>({
    type: 'primary',
    aliases: alwaysEnabledPermissions.map((name) => ({ name })),
  });
  const { create } = useActions();

  function onChange(updatedData: Partial<LitmusRole>) {
    let data: Partial<LitmusRole> = updatedData;
    if (data.title && !normalizeRoleNames) {
      data = { ...data, name: data.title.toLowerCase().replace(/ /g, '_') };
    }

    setRole({ ...role, ...data });
  }

  function handleSave() {
    const validationError = validateRole(role);
    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });

      return;
    }

    create(role);
  }

  if (showCustomRoles.isLoading || !showCustomRoles.data?.value) {
    return <></>;
  }

  return (
    <WithPermission permissions={['configureRoleAccess']}>
      <FormPage
        title="Create role"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'Roles', to: '..' },
          { label: 'Create role' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <Icon name="SaveSuccess" />,
            onClick: handleSave,
          },
        ]}
      >
        <Form role={role} onChange={onChange} />
      </FormPage>
    </WithPermission>
  );
};
