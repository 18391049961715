import * as React from 'react';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useToggle } from 'hooks/useToggle';
import { Content } from 'models/content';
import { CustomSlug } from 'models/custom-slug';
import { WithPermission } from 'shared/WithPermission';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { CustomSlugContext } from '../useCustomSlugContext';
import { SlugFieldsError, SlugsForm } from './SlugsForm';
import { CampaignForm } from './CampaignForm';

export const SlugEditForm: React.FC<{
  action: (data: Partial<CustomSlug>) => void;
  customSlug?: CustomSlug;
  editing?: boolean;
  originalContent?: Content;
}> = ({ customSlug, action, editing, originalContent }) => {
  const [slugFields, setSlugFields] = React.useState<Partial<CustomSlug>>(
    customSlug || {}
  );

  const [formFieldErrors, setFormFieldErrors] = React.useState<SlugFieldsError>(
    {
      slugErrorMessage: '',
      nameErrorMessage: '',
    }
  );

  const [selectedCampaign, setSelectedCampaign] = React.useState<
    Content | undefined
  >(originalContent);

  const {
    value: showSelectedCampaignModal,
    toggle: toggleSelectedCampaignModal,
  } = useToggle();

  const handleSelectCampaign = (content: Content) => {
    setSelectedCampaign(content);
  };

  const handleClearCampaign = () => {
    setSelectedCampaign(undefined);
  };

  const handleAction = () => {
    const customSlugData: Partial<CustomSlug> = {
      ...slugFields,
      contentId: selectedCampaign?.id,
    };
    action(customSlugData);
  };
  const title = `${editing ? 'Edit' : 'Create'} Custom URL`;

  const isSaveAllowed = () => {
    const noFieldsErrors =
      !!formFieldErrors.slugErrorMessage || !!formFieldErrors.nameErrorMessage;
    const noFieldsEmpty = !slugFields.slug || !slugFields.name;

    return noFieldsErrors || noFieldsEmpty;
  };

  return (
    <WithPermission permissions={['confContentAccess']}>
      <FormPage
        title={title}
        breadcrumbs={[
          { to: `../..${editing ? '/..' : ''}`, label: 'Configure' },
          { to: `..${editing ? '/..' : ''}`, label: 'Custom URLs' },
          { label: title },
        ]}
        actions={[
          {
            label: 'Save',
            onClick: handleAction,
            icon: <Icon name="SaveSuccess" />,
            disabled: isSaveAllowed(),
          },
        ]}
      >
        <CustomSlugContext.Provider
          value={{
            ...slugFields,
            setSelectedCampaign: handleSelectCampaign,
            selectedCampaign,
            showSelectedCampaignModal,
            toggleSelectedCampaignModal,
            handleClearCampaign,
          }}
        >
          <SlugsForm
            formFieldErrors={formFieldErrors}
            setFormFieldErrors={setFormFieldErrors}
            setSlugFormFields={setSlugFields}
            slugFormFields={slugFields}
          />
          <CampaignForm />
        </CustomSlugContext.Provider>
      </FormPage>
    </WithPermission>
  );
};
