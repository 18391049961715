import * as React from 'react';
import { Body, color } from 'DesignSystem/Typography';

export const FieldLegend: React.FC<{
  block?: boolean;
  children: React.ReactNode;
}> = ({ block = false, children }) => (
  <Body block={block} color={color.gray90}>
    {children}
  </Body>
);
