import React from 'react';

import { FilterTriggerButton } from 'DesignSystem/Table/FilterTriggerButton';
import { DateFilter as DateFilterType } from 'models/language-controls/filter';
import { ClickDropdown } from 'shared/ClickDropdown';
import DateRangeInput from 'shared/DateRangeInput';
import { RelativeRange } from 'shared/DateRangeInput/DateRange';
import styles from './filters.module.css';

type PropsType = {
  filter: DateFilterType;
  onChange: (filter: DateFilterType) => void;
};

export const DateFilter: React.FC<PropsType> = ({ filter, onChange }) => {
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdownRenderProp = (dismiss: () => void) => {
    onDismissRef.current = dismiss;
    return (
      <div className={styles.filterDropdownContainer}>
        <DateRangeInput
          onStateChange={(dateRange) =>
            onChange({ ...filter, value: dateRange })
          }
          initialValue={filter.value}
          onDone={onDismissRef.current}
        />
      </div>
    );
  };

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp} cursorType="default">
      <FilterTriggerButton
        name={filter.label}
        values={[filter.value?.label ?? '']}
        onClose={() =>
          onChange({
            ...filter,
            value: RelativeRange.build('past30days~'),
            active: false,
          })
        }
      />
    </ClickDropdown>
  );
};
