import { useQueries, useQuery, UseQueryResult } from 'react-query';
import { QueryResponse } from './common';
import {
  FetchProps,
  fetchReach,
  ReachCollectionData,
  ReachData,
} from '../services/api-reach';
import { ContentPage } from '../models/content-page';

export type ReachByContentId = {
  [contentId: number]: number;
};
export function mapServerDataToReach(
  reach: Array<ReachData>
): ReachByContentId {
  const result: ReachByContentId = {};
  reach.forEach((r: ReachData) => {
    result[r.id] = r.reach;
  });
  return result;
}

export const useReachQuery = (
  props: FetchProps
): QueryResponse<ReachByContentId> => {
  const { isLoading, error, data } = useQuery<ReachCollectionData, Error>(
    ['reach', { ...props }],
    () => fetchReach(props),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapServerDataToReach(data.data),
  };
};

export function useReachByContentQueries(
  programId: number,
  pages: ContentPage[]
): ReachByContentId {
  const reachQueries =
    pages.map((page: ContentPage) => {
      const ids = page.data.map((item) => item.id);
      const reachProps = { programId, ids };
      return {
        queryKey: ['reach', reachProps],
        queryFn: () => fetchReach(reachProps),
        options: { retry: false },
      };
    }) || [];

  const reachByPage = useQueries(reachQueries) as Array<
    UseQueryResult<ReachCollectionData>
  >;

  const reachByContent: { [id: number]: number } = {};

  reachByPage.forEach((reachPage: UseQueryResult<ReachCollectionData>) => {
    (reachPage?.data?.data || []).forEach((reachItem) => {
      reachByContent[reachItem.id] = reachItem.reach;
    });
  });

  return reachByContent;
}
