import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { TemplateProvider, useInitialTemplate } from 'contexts/template';
import { TemplateAssetProvider } from 'contexts/template-asset';
import { defaultTemplate, newTemplate } from 'models/library';

export const Screen: React.FC<RouteComponentProps<{ id: number | 'new' }>> = ({
  children,
  id = 'new',
}) => {
  const program = useProgram();

  const valueFromState = useInitialTemplate();
  const initialValue = valueFromState
    ? newTemplate(valueFromState)
    : defaultTemplate;

  return (
    <TemplateProvider
      programId={program.id}
      id={id}
      source="template"
      initialValue={initialValue}
    >
      <TemplateAssetProvider programId={program.id}>
        {children}
      </TemplateAssetProvider>
    </TemplateProvider>
  );
};
