import React from 'react';
import { FilterDropdownStructureContext } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import styles from '../Filters.module.css';

export const FilterDropdownContainer: React.FC = ({ children }) => {
  const { setElemDropdown } = React.useContext(FilterDropdownStructureContext);

  return (
    <div ref={setElemDropdown} className={styles.filterDropdownContainer}>
      {children}
    </div>
  );
};
