import {
  MutationOptions,
  useMutation,
  UseMutationResult,
  useQuery,
} from 'react-query';
import { RenderFlags, Targets } from 'models/publisher/block';
import { QueryError, QueryResponse } from './common';
import {
  createEmailPreviews,
  EmailPreview,
  fetchEmailPreviews,
} from '../services/api-email-previews';
import { PreviewablePost } from './content-blocks';
import { useFieldVariables } from './publisher/useFieldVariables';
import { useHash } from './useHash';
import { useFeatureFlagsQuery } from './feature-flags';

export const useEmailPreviewQuery = (props: {
  programId: number;
  post: PreviewablePost;
  delivery: Targets;
  flags: RenderFlags;
  enabled: boolean;
  refetch: boolean;
}): QueryResponse<EmailPreview[]> => {
  const { programId, post, delivery, flags, enabled, refetch } = props;
  const { fromPost } = useFieldVariables();
  const referenceId = useReferenceId({
    programId,
    blocks: post.blocks,
    styles: post.styles,
    variables: fromPost(post),
    delivery,
    flags,
  });

  const { isLoading, error, data } = useQuery<EmailPreview[], Error>(
    ['email_preview', programId, referenceId],
    () =>
      fetchEmailPreviews({
        programId,
        referenceId,
      }),
    {
      enabled: enabled && !!referenceId,
      refetchInterval: refetch ? 2000 : false,
      retry: false,
    }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useCreateEmailPreview = ({
  programId,
  post,
  delivery,
  webFontsEnabled,
  onSuccess,
  onError,
}: {
  programId: number;
  post: PreviewablePost;
  delivery: Targets;
  webFontsEnabled: boolean;
} & MutationOptions<void, QueryError>): UseMutationResult<
  void,
  QueryError,
  void
> => {
  const { fromPost } = useFieldVariables();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );
  const flags: RenderFlags = {
    webFontsEnabled,
    preferOutlook365,
  };
  const createParams = {
    programId,
    blocks: post.blocks,
    styles: post.styles,
    variables: fromPost(post),
    delivery,
    flags,
  };
  const referenceId = useReferenceId(createParams);

  return useMutation(
    () => createEmailPreviews({ ...createParams, referenceId }),
    { onSuccess, onError }
  );
};

type useReferenceIdParams = Omit<
  Parameters<typeof createEmailPreviews>[0],
  'referenceId'
>;

const useReferenceId = (params: useReferenceIdParams) =>
  useHash(JSON.stringify(params));
