import * as React from 'react';
import { SearchBar } from '../SearchBar';
import { calculateChildPaneHeight } from './utils';
import styles from './search-and-clear-pane.module.css';

type PropsType = {
  maxHeight: number;
  searchEnabled?: boolean;
  searchTerm?: string;
  searchPlaceholder?: string;
  onSearchTermChange?: (value: string) => void;
  clearEnabled?: boolean;
  onClear?: () => void;
  className?: string;
  childrenContentHeight: number;
  children: (height: number) => React.ReactNode;
  hasClearButton?: boolean;
  dismissButton?: string | React.ReactNode;
  onDismissRef?: React.MutableRefObject<() => void>;
};

export const SearchAndClearPane: React.FC<PropsType> = (props) => {
  const {
    maxHeight,
    searchEnabled,
    searchPlaceholder,
    searchTerm,
    onSearchTermChange,
    clearEnabled,
    onClear,
    className,
    childrenContentHeight,
    children,
    hasClearButton,
  } = props;

  const [childPaneHeight, setChildPaneHeight] = React.useState(0);
  const headerRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  React.useLayoutEffect(() => {
    const headerHeight =
      headerRef.current?.getBoundingClientRect()?.height || 0;
    setChildPaneHeight(
      calculateChildPaneHeight({
        headerHeight,
        childrenContentHeight,
        maxHeight,
      })
    );
  }, [
    headerRef,
    childrenContentHeight,
    maxHeight,
    clearEnabled,
    searchEnabled,
  ]);

  return (
    <div className={className}>
      <div className={styles.header} ref={headerRef}>
        {searchEnabled && onSearchTermChange && (
          <SearchBar
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChange={onSearchTermChange}
            hasClearButton={hasClearButton}
            panelClassName={styles.searchBar}
            searchIconSize={24}
          />
        )}
        {clearEnabled && (
          <button type="button" className={styles.clear} onClick={onClear}>
            Clear selected items
          </button>
        )}
      </div>
      {children(childPaneHeight)}
    </div>
  );
};
