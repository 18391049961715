import React from 'react';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { PillButton } from 'App/Program/Main/Insight/components/Filters/shared/PillButton';
import style from 'App/Program/Main/Insight/components/Filters/Filters.module.css';
import { Filter } from 'models/insight/Filter';
import { FilterDropdownStructureContext } from './DropdownStructureContext';

type Props = {
  onCloseButtonClick: (Filter: Filter) => void;
};
export const PillButtonDropdown: React.FC<Props> = ({
  onCloseButtonClick,
  children,
}) => {
  const refContainer = React.useRef<HTMLDivElement | null>(null);
  const { setVisible, isVisible, filterState } = React.useContext(
    FilterDropdownContext
  );

  // we manually reorient the dropdown
  const {
    leftPositionForOverflowingDropdowns,
    setPillButtonContainer,
  } = React.useContext(FilterDropdownStructureContext);

  // Known issue: click events do not register when clicking on iframes.
  // need to revisit this issue in the future
  const catchOuterClick = React.useCallback(
    (event: MouseEvent) => {
      if (isVisible && !refContainer.current?.contains(event.target as Node)) {
        setVisible(false);
      }
    },
    [isVisible, setVisible, refContainer]
  );

  React.useEffect(() => {
    window.addEventListener('mousedown', catchOuterClick);
    return () => {
      window.removeEventListener('mousedown', catchOuterClick);
    };
  });

  // auto-open any filter for which `shouldAutoOpen = true`
  // this attribute should only be set when a filter is manually selected
  React.useEffect(() => setVisible(filterState.shouldAutoOpen || false), [
    filterState,
    setVisible,
  ]);

  // function that sets the ref for this component and in the structure context
  const setPillBtnContainerRef = (ref: HTMLDivElement) => {
    setPillButtonContainer(ref);
    refContainer.current = ref;
  };

  const dropdownStyles: React.CSSProperties = {
    visibility: isVisible ? 'visible' : 'hidden',
  };

  if (leftPositionForOverflowingDropdowns) {
    dropdownStyles.left = leftPositionForOverflowingDropdowns;
  }

  return (
    /* This is the outermost container that wraps both the button and dropdown */
    <div
      ref={setPillBtnContainerRef}
      className={style.pillButtonDropdownContainer}
    >
      {/* The button container */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <PillButton onCloseButtonClick={onCloseButtonClick} />
      {/* The dropdown container rendered as a child. Note that the dropdown child
          is ALWAYS rendered on the DOM tree. Its visibility is handled via CSS.
          This way we can preserve any child states as the dropdowns open/close */}
      <div className={style.pillButtonDropdown} style={dropdownStyles}>
        {children}
      </div>
    </div>
  );
};
