import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type Props = {
  tenant: string;
  document: string;
  accepted: boolean;
};

export const acceptAITerms = async (props: Props): Promise<Response> => {
  const url = `${apiRoot}/v2/tenants/${props.tenant}/terms`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(props),
  });
  if (response.status === 200) {
    return response;
  }
  throw new Error(`Error fetching AI response: ${response.status}`);
};
