import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import {
  BulkSelection,
  InfiniteQueryResponse,
  nextPageToFetch,
  QueryResponse,
  MutationOptions,
  InitiativeBulkActionFilters,
} from './common';
import { Initiative } from '../models/initiative';
import {
  fetchInitiatives,
  FetchProps,
  InitiativesCollectionData,
  InitiativeData,
  archiveInitiatives,
  unarchiveInitiatives,
  activateInitiatives,
  deleteInitiatives,
  fetchById,
  updateInitiative,
  createInitiative,
} from '../services/api-initiatives';

export function mapServerDataToInitiatives(
  serverData: InitiativesCollectionData
): Array<Initiative> {
  return serverData.data.map((entity: InitiativeData) => entity.attributes);
}

export const useInitiativesQuery = (
  props: FetchProps
): QueryResponse<Array<Initiative>> => {
  const { isLoading, error, data } = useQuery<InitiativesCollectionData, Error>(
    ['initiatives', { ...props }],
    () => fetchInitiatives(props),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapServerDataToInitiatives(data),
  };
};

// infinite query hook for the initiatives api
// based off of useTopicsInfiniteQuery()
export const useInitiativesInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<Initiative> => {
  // initiatives endpoint is supposed to return `meta` attribute but its missing for some reason
  // super low effort fix: request a ton of data up front
  const { programId, query, pageSize = 20000, statuses } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<InitiativesCollectionData, Error>(
    ['initiatives-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchInitiatives({
        programId,
        query,
        page: pageParam as number,
        statuses,
        pageSize,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) =>
        batch
          ? batch.data.map((initiativeData) => initiativeData.attributes)
          : []
      )
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

export const useInitiativeBulkArchive = (
  programId: number,
  onSuccess?: () => void
): {
  archive: (
    bulkSelection: BulkSelection,
    filterConfig?: InitiativeBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const archive = (
    bulkSelection: BulkSelection,
    filterConfig?: InitiativeBulkActionFilters
  ) => {
    const request = archiveInitiatives(programId, bulkSelection, filterConfig);

    request.then(() => {
      queryClient.invalidateQueries(['initiatives-infinite']);
      if (onSuccess) onSuccess();
    });
  };
  return {
    archive,
  };
};

export const useInitiativeBulkUnarchive = (
  programId: number,
  onSuccess?: () => void
): {
  unarchive: (
    bulkSelection: BulkSelection,
    filterConfig: InitiativeBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const unarchive = (
    bulkSelection: BulkSelection,
    filterConfig: InitiativeBulkActionFilters
  ) => {
    const request = unarchiveInitiatives(
      programId,
      bulkSelection,
      filterConfig
    );

    request.then(() => {
      queryClient.invalidateQueries(['initiatives-infinite']);
      if (onSuccess) onSuccess();
    });
  };
  return {
    unarchive,
  };
};

export const useInitiativeBulkActive = (
  programId: number,
  onSuccess?: () => void
): {
  active: (
    bulkSelection: BulkSelection,
    filterConfig?: InitiativeBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const active = (
    bulkSelection: BulkSelection,
    filterConfig?: InitiativeBulkActionFilters
  ) => {
    const request = activateInitiatives(programId, bulkSelection, filterConfig);

    request.then(() => {
      queryClient.invalidateQueries(['initiatives-infinite']);
      if (onSuccess) onSuccess();
    });
  };
  return {
    active,
  };
};

export const useInitiativeBulkDelete = (
  programId: number,
  onSuccess?: () => void
): {
  remove: (
    bulkSelection: BulkSelection,
    filterConfig?: InitiativeBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const remove = (
    bulkSelection: BulkSelection,
    filterConfig?: InitiativeBulkActionFilters
  ) => {
    const request = deleteInitiatives(programId, bulkSelection, filterConfig);

    request.then(() => {
      queryClient.invalidateQueries(['initiatives-infinite']);
      if (onSuccess) onSuccess();
    });
  };
  return {
    remove,
  };
};

export const useInitiativeQuery = (
  programId: number,
  id: number
): QueryResponse<Initiative> => {
  const { isLoading, error, data } = useQuery<Initiative, Error>({
    queryFn: () => fetchById(programId, id),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useUpdateInitiative = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Initiative, Error> = {}
): {
  update: (initiative: Initiative) => void;
} => {
  const update = (initiative: Initiative) => {
    updateInitiative(programId, initiative)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useCreateInitiative = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Initiative, Error> = {}
): {
  create: (initiative: Partial<Initiative>) => void;
} => {
  const create = (initiative: Partial<Initiative>) => {
    createInitiative(programId, initiative)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { create };
};

export const useArchiveInitiative = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Initiative> = {}
): {
  archive: (initiative: Initiative) => void;
} => {
  const queryClient = useQueryClient();
  const archive = (initiative: Initiative) => {
    updateInitiative(programId, { ...initiative, isActive: false })
      .then((data) => {
        queryClient.invalidateQueries(['initiatives-infinite']);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { archive };
};

export const useUnarchiveInitiative = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Initiative> = {}
): {
  unarchive: (initiative: Initiative) => void;
} => {
  const queryClient = useQueryClient();
  const unarchive = (initiative: Initiative) => {
    updateInitiative(programId, { ...initiative, isActive: true })
      .then((data) => {
        queryClient.invalidateQueries(['initiatives-infinite']);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { unarchive };
};

export const useDestroyInitiative = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  destroy: (initiative: Initiative) => void;
} => {
  const queryClient = useQueryClient();
  const destroy = (initiative: Initiative) => {
    updateInitiative(programId, { ...initiative, isArchived: true })
      .then(() => {
        queryClient.invalidateQueries(['initiatives-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { destroy };
};
