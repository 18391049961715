import {
  DataBlock,
  DefinitionBlock,
  RenderError,
  Styling,
  RenderingVariables,
  Targets,
  RenderFlags,
} from 'models/publisher/block';
import { PreviewAs } from 'models/variable-previews';
import { fqApiUrl } from './common';
import { request } from './api-shared';

export async function previewBlocks(
  programId: number,
  blocks: DataBlock[],
  styles: Styling,
  delivery: Targets = Targets.email,
  variables: RenderingVariables,
  previewAs: PreviewAs,
  { webFontsEnabled = true, preferOutlook365 = false }: RenderFlags,
  contentId?: number,
  permittedPersonalizedFieldFiles?: string[]
): Promise<string> {
  const endpoint = previewAs ? 'preview' : 'render';
  // do not go through pony if previewing content as-is
  let url = fqApiUrl(
    `api/v1/programs/${programId}/content_blocks/${endpoint}?delivery=${delivery}&webfonts=${webFontsEnabled}&content_id=${contentId}&preferOutlook365=${preferOutlook365}`
  );

  if (previewAs) {
    url = `${url}&variables_preview_type=${previewAs}`;
  }

  if (permittedPersonalizedFieldFiles) {
    url = `${url}&permitted_personalized_field_files=${permittedPersonalizedFieldFiles.join(
      ','
    )}`;
  }

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      styles,
      blocks,
      delivery,
      preview: 'true',
      webfonts: webFontsEnabled,
      preferOutlook365,
      meta: variables,
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    const html_string_in_json = await response.json();
    if (html_string_in_json?.errors)
      throw new Error(JSON.stringify({ errors: html_string_in_json.errors }));
    return html_string_in_json;
  }
  throw new Error(`${await response.text()}`);
}

export async function validateBlocks(
  programId: number,
  blocks: DataBlock[]
): Promise<{
  valid: boolean;
  errors: RenderError[];
}> {
  const url = fqApiUrl(`api/v1/programs/${programId}/content_blocks/validate`);
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      blocks,
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`${await response.text()}`);
}

export async function validateBlock(
  programId: number,
  block: DataBlock
): Promise<{
  valid: boolean;
  errors: RenderError[];
}> {
  return validateBlocks(programId, [block]);
}

export async function resolveBlocks(
  programId: number,
  blocks: DataBlock[]
): Promise<DefinitionBlock[]> {
  const url = fqApiUrl(`api/v1/programs/${programId}/content_blocks/resolve`);
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      blocks,
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`${await response.text()}`);
}

export async function resolveBlock(
  programId: number,
  block: DataBlock
): Promise<DefinitionBlock[]> {
  return resolveBlocks(programId, [block]);
}
