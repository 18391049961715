import { useNavigate } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import {
  useCreateCustomSlug,
  useUpdateCustomSlug,
  useDeleteCustomSlug,
  useDuplicateCustomSlug,
} from 'hooks/custom-slugs';
import { CustomSlug } from 'models/custom-slug';

export const useActions = (): {
  create: (data: Partial<CustomSlug>) => void;
  duplicate: (customSlugId: number) => void;
  destroy: (customSlugId: number) => void;
  update: (data: Partial<CustomSlug>) => void;
} => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();

  const customSlugsMainPage = `/${programId}/configure/custom-urls`;

  const { mutate: duplicate } = useDuplicateCustomSlug();

  const { mutate: deleteAction } = useDeleteCustomSlug();

  const { mutate: update } = useUpdateCustomSlug();

  const { create } = useCreateCustomSlug(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Custom URL created successfully',
      });
      navigate(customSlugsMainPage);
    },
  });

  return {
    create,
    update,
    destroy: deleteAction,
    duplicate,
  };
};
