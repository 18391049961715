import React from 'react';
import { Initiative } from 'models/initiative';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';

import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';

import { Textarea } from 'DesignSystem/Form';
import styles from './form.module.css';

type PropsType = {
  initiative: Partial<Initiative>;
  onChange: (data: Partial<Initiative>) => void;
};

export const Form: React.FC<PropsType> = ({ initiative, onChange }) => {
  return (
    <div className={styles.form}>
      <Section title="General">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Title">
              <Textarea
                onChange={(name) => onChange({ name })}
                value={initiative.name}
                className={styles.textField}
                showCharacterCount
                maxLength={200}
              />
            </Field>
          </div>
        </Fieldset>
      </Section>
    </div>
  );
};
