export enum WidgetType {
  Category = 'category', // a typical drop down
  Date = 'date', // uses the date range selector
  Number = 'number', // allows the user to enter a number
  Text = 'text', // or enter free-form text
  Toggle = 'toggle', // a 3-state toggle (Y,N,nil)
}

export enum DataSource {
  Analyze = 'analyze', // Will ask Dojo for the option values.
  Topics = 'channels', // Dojo/metabase refers to this internally as `channels`
  // on front end we want to call these topics
  Initiatives = 'initiatives', // will get their option values from Studio
  Publishers = 'publishers',
  AuthorAlias = 'author_alias',
  Campaigns = 'campaigns', // campaigns make paginated req through dojo to hermes
  ViewBy = 'view_by',
  Attributes = 'attributes', // Will ask Dojo for the option values
  Platforms = 'platform',
  ContentSources = 'content_sources',
  ContentTypes = 'content_types',
  ContentPosts = 'content_posts',
  Countries = 'countries', // TODO: where does this come from?
  Unknown = 'unknown', // usually these are attributes
  Poll = 'poll',
}

export type Option = { label: string; value: string; archived?: boolean };
export type Values = string[];
export type ValueGroups = { [label: string]: Values };

// Legacy Structure of FilterParam
export type FilterJson = {
  type: WidgetType; // Controls the user experience
  data_type: DataSource; // How to find the values for the options
  slug: string; // The name, originates in Metabase
  name: string; // Display name, may differ by program
  allow_empty?: boolean; // When false, a value is required
  allow_filtering?: boolean; // Enables the filter box in the drop down
  allow_multiple?: boolean; // Whether or not they can choose many values
  allow_searching?: boolean; // Will send "filter" to server for more options
  sort_values?: boolean; // Usually true, false for certain lists (like numbers)
  grouped_values?: ValueGroups; // A new feature. Group the values, and never re-order
  refresh_after_date_change?: boolean; // When a date changes, refresh values from server
  options?: Option[]; // The label/value pairs presented to user to chose from
  values?: Option[]; // A list of strings, the values selected from the options
  default?: string | Option | null; // the default value to use if 'values' is empty.
  // some filters (eg "content_type") pass an Option object as a default
  initial_required?: boolean; // indicates if a filter should appear as part of the initial set of filters on a report
  values_pending?: boolean; // if true, means that the values are dynamically fetched from dojo api

  // string that is the internal name for a filter, set by dojo
  // primarily used to match an attribute filter with its view_by counterpart
  // eg "Department", slug = "attribute__department", internal_id = "attribute_department"
  internal_id?: string;
  subFilter?: string; // slug of a subfilter for the current filter
  isSubFilter?: boolean; // indicates if the current filter is a sub filter
};

// A cached version of filters that accompany "placeholder" reports
// ie cached reports that are returned as part of the collection API call
// these filters lack the full list of attributes and SHOULD NOT be used
// for rendering filters on the report page because some filters are meant
// for internal use only (such as program_id)
export type PlaceholderFilterJson = Pick<
  FilterJson,
  'data_type' | 'default' | 'name' | 'slug' | 'type'
>;

export const DATE_RANGE_FILTER_SLUG = 'date_range';
export const DATE_RANGE_FILTER_DEFAULT_VALUE = 'past30days~';

export function isOptionDefault(
  defaultVal: string | Option | null | undefined
): defaultVal is Option {
  if (
    defaultVal === undefined ||
    defaultVal === null ||
    typeof defaultVal === 'string'
  )
    return false;
  return 'value' in defaultVal;
}
