import { useInfiniteQuery, useQuery } from 'react-query';
import { LitmusRole } from 'models/role';
import {
  fetchRoles,
  RolesCollectionData,
  RolesFetchParams,
  createRole,
  fetchRoleByName,
  fetchRoleById,
  updateRole,
  RoleData,
  bulkUpdatePermission,
  BulkUpdateParams,
} from 'services/api-role';

import {
  InfiniteQueryResponse,
  MutationOptions,
  QueryResponse,
} from './common';
import { useProgram } from '../contexts/program';
import { useUserIdentityQuery } from './identities';

export function mapServerDataToRole(
  serverData: RolesCollectionData
): Array<LitmusRole> {
  return serverData.data.map((entity: LitmusRole) => entity);
}

export const useRolesInfiniteQuery = (
  props: Omit<RolesFetchParams, 'page'>
): InfiniteQueryResponse<LitmusRole> => {
  const { order, programId, pageSize = 20, type, search, hidden } = props;
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<RolesCollectionData, Error>(
    ['user-imports-infinite', JSON.stringify(props)],
    async ({ pageParam = 1 }) =>
      fetchRoles({
        order,
        programId,
        search,
        page: { number: pageParam, size: pageSize },
        type,
        hidden,
      }),
    {
      getNextPageParam: (lastGroup) => {
        const group = lastGroup || {};
        if (group.meta && group.meta.pageNumber < group.meta.totalPages)
          return group.meta.pageNumber + 1;

        return undefined;
      },
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToRole(batch) : []))
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};

export const useRoleQuery = (
  programId: number,
  name: string
): QueryResponse<LitmusRole> => {
  const { isLoading, error, data } = useQuery<RoleData, Error>({
    queryKey: `${programId}-role-${name}`,
    queryFn: () => fetchRoleByName(programId, name),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export const useRoleQueryById = (
  programId: number,
  id: number
): QueryResponse<LitmusRole> => {
  const { isLoading, error, data } = useQuery<RoleData, Error>({
    queryKey: `${programId}-role-${id}`,
    queryFn: () => fetchRoleById(programId, id),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export const useCreateRole = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  create: (role: Partial<LitmusRole>) => void;
} => {
  const { id: programId } = useProgram();
  const create = (role: Partial<LitmusRole>) => {
    createRole(programId, role)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { create };
};

export const useUpdateRole = ({
  onSuccess,
  onError,
}: MutationOptions<LitmusRole, Error> = {}): {
  update: (role: LitmusRole) => void;
} => {
  const { id: programId } = useProgram();
  const update = (role: LitmusRole) => {
    updateRole(programId, role)
      .then((data) => {
        if (onSuccess) onSuccess(data.data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useUserRole = (userId: number): QueryResponse<LitmusRole> => {
  const { id: programId } = useProgram();
  const {
    data: userIdentity,
    isLoading: isIdentityLoading,
  } = useUserIdentityQuery(programId, `${userId}`);
  const roleName = userIdentity?.roles[0]?.name;

  const { data, isLoading } = useQuery<RoleData, Error>({
    queryKey: `${programId}-role-${roleName}`,
    queryFn: () => fetchRoleByName(programId, roleName || ''),
    // The query will not execute until the roleName exists
    enabled: !!roleName,
  });

  return {
    isLoading: isLoading || isIdentityLoading,
    data: data?.data,
  };
};

export const useBulkUpdatePermissions = ({
  onSuccess,
  onError,
}: MutationOptions<{ id: string }, Error>): {
  updateBulk: (props: BulkUpdateParams) => void;
} => {
  const { id: programId } = useProgram();
  const updateBulk = (props: BulkUpdateParams) => {
    bulkUpdatePermission(programId, props)
      .then(onSuccess)
      .catch((err) => {
        if (onError) onError(err);
      });
  };

  return { updateBulk };
};
