import React from 'react';
import { DateTime } from 'luxon';
import { Topic } from 'models/topic';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { useTopContributorsQuery } from 'hooks/topics';
import avatar from 'assets/images/Avatars.png';
import styles from './sidebar.module.css';

export const Sidebar: React.FC<{
  topic: Partial<Topic>;
  archive: (topicId: number) => void;
  unarchive: (topicId: number) => void;
}> = ({ topic, archive, unarchive }) => {
  const {
    data: topContributors,
    isLoading: isTopContributorsLoading,
  } = useTopContributorsQuery(Number(topic.id));

  return (
    <div className={styles.wrapper}>
      {Number(topic.stats?.postsCount) > 0 &&
        !isTopContributorsLoading &&
        topContributors && (
          <div className={styles.row}>
            <div className={styles.header}>Top contributors</div>
            {topContributors.map((user) => (
              <div className={styles.topContributor} key={`user-${user.id}`}>
                {user.avatarUrl && !user.avatarUrl.startsWith('/') ? (
                  <div
                    className={styles.avatar}
                    style={{
                      backgroundImage: `url(${user.avatarUrl})`,
                    }}
                  />
                ) : (
                  <div className={styles.avatar}>
                    <img alt="user avatar" src={avatar} />
                  </div>
                )}
                <div>
                  <div
                    className={styles.title}
                  >{`${user.firstName} ${user.lastName}`}</div>
                  <div className={styles.role}>{user.role}</div>
                </div>
              </div>
            ))}
          </div>
        )}

      <div className={styles.row}>
        <div className={styles.stats}>
          <span>Subscribers</span>
          <span>{topic.followerCount}</span>
        </div>
        <div className={styles.stats}>
          <span>Total posts</span>
          <span>{topic.stats?.postsCount}</span>
        </div>
        <div className={styles.stats}>
          <span>Posts / week</span>
          <span>{topic.stats?.postsPerWeek}</span>
        </div>
        <div className={styles.stats}>
          <span>Last update</span>
          <span>
            {topic.stats?.lastUpdated
              ? DateTime.fromISO(topic.stats?.lastUpdated).toRelative()
              : 'N/A'}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <button
          type="button"
          onClick={() => {
            if (topic.archived) {
              unarchive(topic.id as number);
            } else {
              archive(topic.id as number);
            }
          }}
          className={styles.action}
        >
          <span>
            <SVGIcon name="Folder" />
          </span>
          <span>{topic.archived ? 'Unarchive' : 'Archive'}</span>
        </button>
      </div>
    </div>
  );
};
