import * as React from 'react';
import cx from 'classnames';
import styles from './timezone-button.module.css';
import { Timezone } from './DateRange';

type PropsType = {
  className?: string;
  onClick: () => void;
  timezone: Timezone;
};

export const TimezoneButton: React.FC<PropsType> = (props) => {
  const { className, onClick, timezone } = props;

  return (
    <div className={cx(styles.wrapper, className)}>
      <button className={styles.button} onClick={onClick} type="button">
        {timezone.label} {timezone.offset}
      </button>
    </div>
  );
};
