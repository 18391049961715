import React from 'react';
import { Topic, TopicErrors } from 'models/topic';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { Checkbox } from 'shared/Checkbox';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from './form.module.css';

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>) => void;
  errors?: TopicErrors;
};

export const VisibilitySection: React.FC<PropsType> = ({
  topic,
  onChange,
  errors,
}) => {
  return (
    <Section title="Visibility">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Options" error={errors?.published}>
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <Checkbox
                  checked={Boolean(topic.published)}
                  onChange={(value) => onChange({ published: value })}
                  disabled={topic?.default}
                />
              </div>
              Show in Experience
            </div>
          </Field>
        </div>
      </Fieldset>
    </Section>
  );
};
