import { Level } from '@tiptap/extension-heading';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { GenericSelect } from 'shared/Select';
import editorStyles from '../editor.module.css';
import { DEFAULT_EDITOR_FONT_SIZE } from '../constants';

const headerOptions = {
  '1': {
    label: 'H1',
    fontSize: 30,
  },
  '2': {
    label: 'H2',
    fontSize: 22,
  },
  '3': {
    label: 'H3',
    fontSize: 18,
  },
  '4': {
    label: 'H4',
    fontSize: 15,
  },
  '5': {
    label: 'H5',
    fontSize: 12,
  },
  disable: {
    label: 'Paragraph',
    fontSize: DEFAULT_EDITOR_FONT_SIZE,
  },
};

const getHeaderLevel = (editor: Editor) => {
  return editor.getAttributes('heading').level
    ? editor.getAttributes('heading').level.toString()
    : 'disable';
};

export const EditorActionSelectHeading: React.FC<{ editor: Editor }> = ({
  editor,
}) => {
  const onChange = (value: string) => {
    let editorFocus = editor.chain().focus();

    const level = parseInt(value, 10) as Level;
    if (level) {
      editorFocus = editorFocus.setHeading({ level });
    } else {
      editorFocus = editorFocus.setNode('paragraph');
    }

    editorFocus
      .setFontSize(
        value in headerOptions
          ? headerOptions[value as keyof typeof headerOptions].fontSize
          : DEFAULT_EDITOR_FONT_SIZE
      )
      .run();
  };

  const options = Object.entries(headerOptions).map(([key, opt]) => ({
    value: key,
    label: opt.label,
  }));

  return (
    <div className={editorStyles.tiptapDropdownContainer}>
      <GenericSelect
        onChange={onChange}
        options={options}
        value={getHeaderLevel(editor)}
      />
    </div>
  );
};
