export enum AXIS_TYPE {
  CARDINAL = 'cardinal',
  ORDINAL = 'ordinal',
}

export const DEFAULT_ANIMATION_TIME = 1000;

export type ChartDimensions = {
  width: number;
  height: number;
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
};

export function formatDate(date?: string): string {
  if (!date) return '';
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const newDate = new Date(date);
  const month = months[newDate.getMonth()];
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  let hour = newDate.getHours();
  const minute = newDate.getMinutes().toString().padStart(2, '0');
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour %= 12;
  hour = hour || 12; // the hour '0' should be '12'
  return `${month} ${day}, ${year} ${hour}:${minute} ${ampm}`;
}

export const formatSubscriptionStatus = (date?: string): string => {
  return date && date !== null
    ? `Unsubscribed\n${formatDate(date)}`
    : 'Subscribed';
};
