import React from 'react';
import cx from 'classnames';
import { isPrePublish, Post } from 'models/publisher/post';
import publisherStyles from 'components/publisher/publisher.module.css';
import { Flex } from 'DesignSystem/Layout/Flex';
import { PerformanceInsights } from 'components/publisher/insights/PerformanceInsights';
import { Target } from 'components/publisher/settings/OrchestrationInsights/Target';
import { Channels } from 'components/publisher/settings/OrchestrationInsights/Channels';
import { Schedule } from 'components/publisher/settings/OrchestrationInsights/Schedule';
import { Hints } from 'components/publisher/settings/OrchestrationInsights/Hints';
import { ArrowLeft } from 'shared/icons';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { computeMaxRetries, Settings } from 'models/publisher/settings';
import { Insights } from 'models/publisher/orchestration-insights';
import { ReTargeting } from 'components/publisher/settings/OrchestrationInsights/ReTargeting';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { SimplePrediction } from 'components/publisher/settings/OrchestrationInsights/SimplePrediction';
import { EmptyAudience } from 'components/publisher/settings/OrchestrationInsights/EmptyAudience';
import { Spinner } from 'App/Program/Configuration/Shared/Spinner';
import { useContentReportUrls } from 'hooks/content/useContentReportUrls';
import { useResultsSummaryQuery } from 'hooks/content-insights';
import { useCampaignStatus } from 'hooks/content/useCampaignStatus';
import { hasAudience } from 'models/content';
import styles from './orchestrate-sidebar.module.css';

// TODO: Remove post to reduce confusion between duplicate
//  attribute (settings vs post.settings)
export const OrchestrateSidebar: React.FC<{
  post: Post;
  settings: Settings;
  insights: Insights;
}> = ({ post, settings, insights }) => {
  const [showFullPrediction, setShowFullPrediction] = React.useState(false);
  const { id: programId } = useProgram();
  const { getDeliveryDetailsUrl } = useContentReportUrls({
    content: post.content,
  });

  const {
    data: summary,
    isLoading: isLoadingSummary,
  } = useResultsSummaryQuery({ contentId: post.content.id });
  const {
    data: simplePredictionEnabled,
    isLoading: isSimplePredictionFlagLoading,
  } = useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.SimplePredictionPlan'
  );

  const { campaignStatus, timeLeft } = useCampaignStatus(post, summary);

  const maxRetries = React.useMemo(
    () => computeMaxRetries(settings, insights.maxRetries),
    [settings, insights.maxRetries]
  );

  const canSeeSimplePrediction = React.useMemo(
    () =>
      simplePredictionEnabled?.value &&
      !isPrePublish(post) &&
      hasAudience(post.content),
    [simplePredictionEnabled, post]
  );

  const showSimplePrediction = canSeeSimplePrediction && !showFullPrediction;

  if (isSimplePredictionFlagLoading && isLoadingSummary) {
    return <Spinner />;
  }

  if (settings.audiences.length === 0) {
    return <EmptyAudience />;
  }

  if (showSimplePrediction) {
    return (
      <SimplePrediction
        timeLeft={timeLeft}
        campaignStatus={campaignStatus}
        showFullPrediction={() => setShowFullPrediction(true)}
        reportLinkUrl={getDeliveryDetailsUrl()}
      />
    );
  }

  return (
    <Flex center className={styles.DeliverSidebar}>
      <Flex column className={styles.DeliverSidebarColumn}>
        <aside className={styles.Sidebar}>
          {canSeeSimplePrediction && (
            <Box padding={[0, 10, 10, 10]}>
              <Button
                compact
                clearText
                icon={<ArrowLeft />}
                label="Go Back"
                onClick={() => setShowFullPrediction(false)}
              />
            </Box>
          )}
          <div>
            <div className={cx('section', publisherStyles.planSection)}>
              <h3 className={publisherStyles.planSectionHeader}>Plan</h3>

              <PerformanceInsights performance={insights.performance} />
            </div>

            <div className="section">
              <Target />
            </div>

            <div className="section">
              <h3 className="section-header">Channels</h3>

              {insights.isLoading ? (
                <Spinner />
              ) : (
                <Channels
                  settings={settings}
                  channelsInsights={insights.channelsInsights}
                />
              )}
            </div>

            <div className="section">
              {insights.isLoading ? (
                <Spinner />
              ) : (
                <Schedule
                  higherPriority={insights.higherPriority}
                  competitions={insights.competitions}
                  scheduleInsights={insights.scheduleInsights}
                />
              )}
            </div>

            <div className="section">
              <h3 className="section-header">Retargeting</h3>
              {insights.isLoading ? (
                <Spinner />
              ) : (
                <ReTargeting maxRetries={maxRetries} />
              )}
            </div>

            <div className="section">
              <Hints />
            </div>
          </div>
        </aside>
      </Flex>
    </Flex>
  );
};
