import {
  MessagesType,
  useBasicValidator,
  ValidateFunc,
  ValidatorsType,
} from './useBasicValidator';

const ALLOWED_MIME_TYPES = ['application/x-subrip', 'text/vtt'];

const ALLOWED_EXTENSION_TYPES = ['vtt', 'srt'];

type ValidatorType = {
  file: File;
};

const validators: ValidatorsType<ValidatorType> = {
  allowed_extension_type: ({ file }) => {
    const { type, name } = file;
    const extension = name.split('.').pop() || '';

    return (
      !ALLOWED_MIME_TYPES.includes(type) &&
      !ALLOWED_EXTENSION_TYPES.includes(extension)
    );
  },
};

const errorMessages: MessagesType = {
  ALLOWED_EXTENSION_TYPE: 'Caption format is not supported.',
};

export const useVideoCaptionValidator: () => {
  validate: ValidateFunc<ValidatorType>;
} = () => {
  return useBasicValidator<ValidatorType>(validators, errorMessages);
};
