import * as React from 'react';
import { useLibrary, useLibraryBlockCategories } from 'hooks/useLibrary';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { Blocks, Category } from 'models/library';
import { postHasPoll } from 'models/publisher/block';
import { BoxIcon } from 'shared/Box/BoxIcon';
import { MAIcon } from 'shared/MAIcon';

const GLOBAL_PRESETS: Array<{
  name: string;
  title: string;
  pendoTag?: string;
  Icon: React.FC;
}> = [
  {
    name: 'article',
    Icon: () => MAIcon({ name: 'article' }),
    title: 'Article',
    pendoTag: 'pendo-create-article',
  },
  {
    name: 'links',
    Icon: () => MAIcon({ name: 'add_link' }),
    title: 'Links',
    pendoTag: 'pendo-create-links',
  },
  {
    name: 'video',
    Icon: () => MAIcon({ name: 'slideshow' }),
    title: 'Video',
    pendoTag: 'pendo-create-video',
  },
  {
    name: 'poll_modern',
    Icon: () => MAIcon({ name: 'insert_chart' }),
    title: 'Poll',
    pendoTag: 'pendo-create-polls',
  },
  {
    name: 'single_image',
    Icon: () => MAIcon({ name: 'photo' }),
    title: 'Image',
    pendoTag: 'pendo-create-images',
  },
  {
    name: 'rich_text',
    Icon: () => MAIcon({ name: 'text_fields' }),
    title: 'Text',
  },
  {
    name: 'custom_html',
    Icon: () => MAIcon({ name: 'code' }),
    title: 'HTML',
  },
];

// We can put blocks that show up in the panel here,
// and let the `GLOBAL` set remain unchanged.
const EXTENDED_PRESETS: typeof GLOBAL_PRESETS = [
  {
    name: 'button_link',
    Icon: () => MAIcon({ name: 'touch_app' }),
    title: 'Button',
  },
  {
    name: 'quote',
    Icon: () => MAIcon({ name: 'format_quote' }),
    title: 'Quote',
  },
  {
    name: 'attachment',
    Icon: () => MAIcon({ name: 'attachment' }),
    title: 'Attachment',
  },
  {
    name: 'divider',
    Icon: () => MAIcon({ name: 'horizontal_rule' }),
    title: 'Divider',
  },
  { name: 'spacer', Icon: () => MAIcon({ name: 'expand' }), title: 'Spacer' },
  {
    name: 'social',
    Icon: () => MAIcon({ name: 'share' }),
    title: 'Social',
  },
  {
    name: 'image_gallery',
    Icon: () => MAIcon({ name: 'photo_library' }),
    title: 'Gallery',
  },
];

// These blocks are not included by default, and must be manually requested
// using the include parameter.
const MANUAL_PRESETS: typeof GLOBAL_PRESETS = [
  {
    name: 'box_integration',
    Icon: () => BoxIcon({ color: 'black' }),
    title: 'Box',
  },
];

export type SimpleBlockConfig = {
  omitNames?: string[];
  includeNames?: string[];
  omitCategories?: Category['identifier'][];
  extended?: boolean;
  blockIdentifierSubstitutes?: Record<string, string>;
};

export function useSimpleBlocks(
  config?: SimpleBlockConfig
): Array<{
  block: Blocks;
  Icon: React.FC;
  title: string;
  pendoTag?: string;
}> {
  const categories = useLibraryBlockCategories();
  const categoriesIds =
    categories.data
      ?.filter((category) => category.identifier === 'custom_block')
      ?.map((cat) => cat.id) || [];
  const library = useLibrary({ excludeCategoriesIds: categoriesIds });
  const hiddenPollBlocks = !!useFeatureFlag('hiddenPollBlocks');
  const pairs: ReturnType<typeof useSimpleBlocks> = [];

  let candidates = [...GLOBAL_PRESETS];
  if (config?.extended) candidates = candidates.concat(EXTENDED_PRESETS);
  if (config?.includeNames)
    candidates = candidates.concat(
      EXTENDED_PRESETS.filter(({ name }) =>
        config?.includeNames?.includes(name)
      ),
      MANUAL_PRESETS.filter(({ name }) => config?.includeNames?.includes(name))
    );

  candidates.forEach(({ name, Icon, title, pendoTag }) => {
    // eslint-disable-next-line no-param-reassign
    name = config?.blockIdentifierSubstitutes?.[name] || name;
    const item = library[name];
    if (!item || !item.asset || !item.asset.blocks) return;
    if (hiddenPollBlocks && postHasPoll({ blocks: item.asset.blocks })) return;
    if (config?.omitNames?.includes(name)) return;
    if (
      item.categories.some(({ identifier }) =>
        config?.omitCategories?.includes(identifier)
      )
    )
      return;
    pairs.push({ block: item, Icon, title, pendoTag });
  });
  return pairs;
}
