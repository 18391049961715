import * as React from 'react';
import { User } from 'models/user';
import { DateTime } from 'luxon';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useFlashMessage } from 'contexts/flasher';
import { LitmusRole } from 'models/role';
import {
  useInviteUsers,
  useActivateUser,
  useDeactivateUser,
  useHideUser,
  useExportUsers,
} from 'hooks/user';
import { ExportsDownload } from 'shared/Exports/User';
import {
  userToName,
  userToDescription,
  userToRole,
  userToThumbnail,
  userToMenuItems,
  userToLitmusRole,
} from './mappings';
import styles from './people.module.css';

function date(d: string | undefined) {
  return d ? DateTime.fromJSDate(new Date(d)) : undefined;
}

const MainColumn: React.FC<{
  id: number;
  name?: string;
  description?: string;
  location?: string;
  role?: string;
  department?: string;
  title?: string;
  litmusRoles?: Array<LitmusRole>;
}> = ({ id, name, description, location, role, department, title }) => {
  const attributes = [role, department, location, title].filter(Boolean);

  return (
    <section className={styles.col1}>
      <div className={styles.title} title={name}>
        {name || <>&nbsp;</>}
      </div>
      <p className={styles.description} title={description}>
        {description || <>&ndash;</>}
      </p>
      <div className={styles.attributes}>
        <ul>
          {attributes.map((attr: string | undefined) => (
            <li key={`${attr}-${id}`}>
              <span>{attr}</span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const StatColumn: React.FC<{
  header?: string;
  value?: string;
  footer?: string;
}> = ({ header, value, footer }) => (
  <section className={styles.statCol}>
    <span className={styles.statHeader} title={header}>
      {header || <>&nbsp;</>}
    </span>
    <span className={styles.stat} title={value}>
      {value || <>&ndash;</>}
    </span>
    <span className={styles.statFooter} title={footer}>
      {footer || <>&nbsp;</>}
    </span>
  </section>
);

export const UserFullListItem: React.FC<{
  user: User;
  permissionsEnabled: boolean;
  litmusRoles: Array<LitmusRole>;
}> = ({ user, permissionsEnabled, litmusRoles }) => {
  const program = useProgram();
  const { id: programId } = program;
  const { role: currentUserRole, permissions } = usePermissions();
  const { setFlashMessage } = useFlashMessage();
  const [exportId, setExportId] = React.useState(0);

  const createdAt = date(user.membershipCreatedAt)?.toFormat('MMM dd, yyyy');
  const lastActive = date(user.lastVisitedAt)?.toRelative() || undefined;
  const location =
    user.city && user.state
      ? `${user.city}, ${user.state}`
      : user.city || user.state;

  const showFlashMessage = (message: string, isError = false): void => {
    setFlashMessage({
      severity: isError ? 'error' : 'info',
      message,
    });
  };

  const { activate: activateUser } = useActivateUser(programId, {
    onSuccess: () => showFlashMessage('User activated.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { deactivate: deactivateUser } = useDeactivateUser(programId, {
    onSuccess: () => showFlashMessage('User deactivated.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { invite: inviteUser } = useInviteUsers(programId, {
    onSuccess: () => showFlashMessage('User invited.'),
    onError: (err) => showFlashMessage(err, true),
  });

  const { hide: hideUser, unHide: unHideUser } = useHideUser(programId, {
    onSuccess: (msg) => showFlashMessage(msg),
    onError: (err) => showFlashMessage(err, true),
  });

  const { doExport: exportUser } = useExportUsers(programId, {
    onSuccess: (id) => {
      setExportId(() => Number(id));
    },
    onError: (err) => showFlashMessage(err, true),
  });

  const menuItems = userToMenuItems(
    permissionsEnabled,
    user,
    program,
    programId,
    currentUserRole,
    permissions.hideUserAccess,
    inviteUser,
    activateUser,
    deactivateUser,
    hideUser,
    unHideUser,
    exportUser
  );

  const menuFooter = lastActive && (
    <p className={styles.menuFooter}>
      <div>Last Active</div>
      <strong>{lastActive || 'NA'}</strong>
    </p>
  );

  return (
    <EmptyBanner
      thumbnail={userToThumbnail(user)}
      menuFooter={menuFooter}
      menuItems={menuItems}
      rowId={user.id.toString()}
    >
      <MainColumn
        id={user.id}
        name={userToName(user)}
        description={userToDescription(user)}
        role={
          permissionsEnabled
            ? userToLitmusRole(user, litmusRoles)
            : userToRole(user)
        }
        department={user.department}
        location={location}
        title={user.jobTitle}
      />
      <div className={styles.statColWrapper}>
        <StatColumn
          header="90 day activity"
          value={user.activity90Day || 'NA'}
        />
        <StatColumn header="Last active" value={lastActive || 'NA'} />
        <StatColumn header="Pf. channel" value={user.channel90Day || 'NA'} />
        <StatColumn header="Created" value={createdAt || 'NA'} />
      </div>
      {exportId > 0 && (
        <ExportsDownload exportId={exportId} programId={programId} />
      )}
    </EmptyBanner>
  );
};
