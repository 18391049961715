import { reassignStepEdge } from 'App/Program/Main/Journey/JourneyCanvas/utils/graph';
import {
  DEFAULT_EDGE_TARGET_ID,
  Journey,
  Step,
  Steps,
} from 'models/journeys/journey';
import { Position } from 'reactflow';
import type { JourneyActionFor } from './journey-reducer';
import { findPreviousStep, findStep, mustFindDraftStep } from './steps';

export function handleStepMoved(
  state: Journey,
  action: JourneyActionFor<'step/moved'>
): Journey {
  const { direction, stepId } = action;
  if (!canMoveStep(state, stepId, direction)) {
    return state;
  }

  const currentStep = mustFindDraftStep(state, stepId);

  if (direction === Position.Left) {
    return moveLeft(state, currentStep);
  }
  return moveRight(state, currentStep);
}

function moveLeft(state: Journey, currentStep: Step): Journey {
  const currentGraph = state.draftGraph;
  const previousStep = findPreviousStep(state, currentStep.id);
  if (!previousStep || !currentGraph) return state;

  let { rootStepId } = currentGraph;
  if (previousStep.id === rootStepId) {
    rootStepId = currentStep.id;
  }

  const previousGrandStep = findPreviousStep(state, previousStep.id);
  if (!previousGrandStep) return state;

  // when moving left the next step is allowed to not exist
  const nextStep =
    currentStep.next.length === 1
      ? mustFindDraftStep(state, currentStep.next[0].targetId)
      : undefined;

  // create a new step from currentStep and replace current step's next target to previous step,
  // or set it to `default` if it does not yet have a next target
  const updatedCurrentStep = reassignStepEdge(
    currentStep,
    nextStep ? nextStep.id : DEFAULT_EDGE_TARGET_ID,
    previousStep.id
  );

  // create a new step from previousStep and replace previous step's next target to next step if it exists
  // otherwise set the step's next target to `default`
  const updatedPreviousStep = reassignStepEdge(
    previousStep,
    currentStep.id,
    nextStep ? nextStep.id : DEFAULT_EDGE_TARGET_ID
  );

  // create a new step from previousGrandStep and replace previous grand-step's next target to current step
  const updatedPreviousGrandStep = reassignStepEdge(
    previousGrandStep,
    previousStep.id,
    currentStep.id
  );

  // generate a new set of journey steps replacing the modified steps with the newly created ones
  const updatedSteps = currentGraph.steps.map(
    (s) =>
      [updatedCurrentStep, updatedPreviousStep, updatedPreviousGrandStep].find(
        (step) => s.id === step?.id
      ) || s
  );

  return {
    ...state,
    draftGraph: {
      ...currentGraph,
      steps: updatedSteps,
      rootStepId,
    },
  };
}

function moveRight(state: Journey, currentStep: Step): Journey {
  // moving a step right is the same as moving the next step left
  const nextStep =
    currentStep.next.length === 1
      ? mustFindDraftStep(state, currentStep.next[0].targetId)
      : undefined;

  if (!nextStep) return state;
  return moveLeft(state, nextStep);
}

export function canMoveStep(
  state: Journey,
  id: string,
  direction: Position.Left | Position.Right
): boolean {
  if (direction === Position.Left) {
    const prevStep = findPreviousStep(state, id);
    return !!prevStep && !BLOCKED_MOVE_TYPES.includes(prevStep.type);
  }

  const currentStep = findStep(state, id, (journey) => journey.draftGraph);
  if (!currentStep) return false;
  if (currentStep.next.length !== 1) return false;

  const nextStep = mustFindDraftStep(state, currentStep.next[0].targetId);
  return !!nextStep && !BLOCKED_MOVE_TYPES.includes(nextStep.type);
}

const BLOCKED_MOVE_TYPES: Array<keyof Steps> = ['start', 'end', 'decision'];
