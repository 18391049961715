import React from 'react';
import { LinkData, SocialData } from 'models/publisher/block';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { Editor } from './Editor';
import { useLinksSourceMenu } from './useLinksSourceMenu';
import styles from '../../../editing.module.css';

const customMessages: Record<string, string> = {
  'Max preview retries reached!':
    'No previews available. There was an error reaching that URL, Please continue.',
  'not found':
    'No previews available. There was an error reaching that URL, Please continue.',
};

type PropsType = {
  initial?: LinkData | SocialData;
  onSave: (link: LinkData | LinkData[] | SocialData | SocialData[]) => void;
  onCancel: () => void;
  source: ReturnType<typeof useLinksSourceMenu>;
};

export const SingleLink: React.FC<PropsType> = ({
  initial,
  onSave,
  onCancel,
  source,
}) => {
  const [link, setLink] = React.useState<LinkData | SocialData | undefined>(
    initial
  );
  const [error, setError] = React.useState('');
  const [onLoading, setOnLoading] = React.useState(false);
  const [urlTouched, setUrlTouched] = React.useState(false);
  const [applyDisabled, setApplyDisabled] = React.useState(link?.uuid === '');

  const handleDone = React.useCallback(() => {
    if (link) {
      onSave({
        ...link,
        url: link.requestedUrl || link.url,
      });
    }
  }, [link, onSave]);

  const saveCollection = React.useCallback(
    (links: LinkData[] | SocialData[]) => {
      onSave(links);
    },
    [onSave]
  );

  const onUrlChange = () => {
    setApplyDisabled(true);
    setUrlTouched(true);
  };

  React.useEffect(() => {
    if (urlTouched) {
      setApplyDisabled(!link?.url || onLoading);
    }
  }, [link?.url, onLoading, urlTouched]);

  return (
    <SlideIn name="publisher-block-links-link-slide-in">
      {(slideOut) => (
        <div className={styles.layout}>
          <aside>
            <h2>Edit Link</h2>
            <button
              type="button"
              data-test="link-edit-apply-btn"
              disabled={applyDisabled}
              className={styles.applyButton}
              onClick={() => {
                slideOut(handleDone);
              }}
            >
              Apply
            </button>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={() => {
                slideOut(onCancel);
              }}
            >
              Cancel
            </button>
            {error && !onLoading && (
              <div className={styles.errors}>
                {customMessages[error] ?? (
                  <>
                    There was an error: <strong>{error}</strong>
                  </>
                )}
              </div>
            )}
          </aside>
          <div className={styles.main}>
            <Editor
              source={source}
              onError={setError}
              initial={initial}
              onChangeLink={setLink}
              onApplyCollection={saveCollection}
              onCancel={onCancel}
              onLoading={setOnLoading}
              onUrlChange={onUrlChange}
              setApplyDisabled={setApplyDisabled}
            />
          </div>
        </div>
      )}
    </SlideIn>
  );
};
