import React, { useState } from 'react';
import { useNavigate } from '@reach/router';

export type MenuItem = {
  name?: React.ReactNode;
  // not required for now as we still have a few non-functional menu items
  onClick?: () => void;
  isMenuClosedOnClick?: boolean;
  element?: React.ReactNode;
  disabled?: boolean;
  visible?: boolean;
  isWorking?: boolean;
};

type Props = {
  menuItems?: { [sectionName: string]: Array<MenuItem> };
  links?: Array<Link>;
  action?: () => void;
  actionName?: string;
};

export type Link = {
  href: string;
  name: string;
  disabled?: boolean;
  validateClick?: () => boolean;
};

export type Footer = {
  menuItems?: { [sectionName: string]: Array<MenuItem> };
  links?: Array<Link>;
  activeLink: string;
  goForward: (link?: string) => void;
  goBack: () => void;
  action?: () => void;
  title?: string;
  setTitle: (title: string) => void;
  status?: string;
  setStatus: (status: string) => void;
  actionName?: string;
};

export const useFooter: (props: Props) => Footer = ({
  menuItems,
  links: suppliedLinks,
  action,
  actionName,
}) => {
  const links = React.useMemo(() => suppliedLinks || [], [suppliedLinks]);
  const [active, setActive] = useState<string>(links[0]?.name ?? '');
  const [title, setTitle] = useState<string>();
  const [status, setStatus] = useState<string>();
  const navigate = useNavigate();

  const goForward = React.useCallback(
    (link?: string) => {
      if (link) {
        const existing = links.find(
          (l) => l.name.toLowerCase() === link.toLowerCase()
        );
        if (existing) {
          setActive(existing.name);
          navigate(existing.href);
        }
      } else {
        const activeIndex = links.findIndex(
          (l) => l.name.toLowerCase() === active.toLocaleLowerCase()
        );
        if (activeIndex !== -1 && activeIndex < links.length - 1) {
          const newLink = links[activeIndex + 1];
          setActive(newLink.name);
          navigate(newLink.href);
        }
      }
    },
    [navigate, setActive, links, active]
  );

  const goBack = React.useCallback(() => {
    const activeIndex = links.findIndex((l) => l.name === active);
    if (activeIndex > 0 && activeIndex < links.length - 1) {
      setActive(links[activeIndex - 1].name);
    }
  }, [setActive, links, active]);

  return {
    menuItems,
    links,
    activeLink: 'foo',
    goForward,
    goBack,
    action,
    actionName,
    title,
    setTitle,
    status,
    setStatus,
  };
};
