import * as React from 'react';
import Select, { IndicatorProps, components } from 'react-select';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { usePollAssetsQuery } from 'hooks/polls';
import { useUniqueIds } from 'hooks/useUniqueId';
import { PollFieldData } from 'models/publisher/block';
import { Icon } from 'shared/Icon';
import { Fieldset, Field } from 'shared/Fieldset';
import { FieldProps } from '../FieldProps';
import { usePollState } from './usePollState';
import { Answers } from './Answers';
import styles from './poll-modern.module.css';

const PRESETS = {
  single: 'Single choice',
  multiple: 'Multiple choice',
  satisfaction: 'Satisfaction',
  stars: 'Star Rating',
  yes_no: 'Yes/No',
} as const;

export type Option = {
  value: string;
  label: string;
};

const ExpandList: React.FC<IndicatorProps<Option>> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props} className={styles.expand}>
    <Icon iconName="ExpandList" iconType="SVG" />
  </components.DropdownIndicator>
);

export const PollModern: React.FC<FieldProps<PollFieldData>> = ({
  data,
  default_data,
  onChange,
}) => {
  const { id: programId } = useProgram();
  const { data: assets } = usePollAssetsQuery(programId);
  const initial = data.question !== '' ? data : default_data;
  const { poll, updatePoll } = usePollState(initial, onChange, assets);
  const [
    presetId,
    questionId,
    answersId,
    resultsId,
    thankYouTextId,
  ] = useUniqueIds(5);
  const { role } = usePermissions();
  return (
    <Fieldset>
      <Field label="Type" labelFor={presetId}>
        <Select
          inputId={presetId}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: ExpandList,
          }}
          defaultValue={{ label: PRESETS[poll.preset], value: poll.preset }}
          options={Object.keys(PRESETS)
            .filter((name) => !!PRESETS[name as keyof typeof PRESETS])
            .map((name) => ({
              label: PRESETS[name as keyof typeof PRESETS],
              value: name,
            }))}
          onChange={(options) => {
            const option: Option = Array.isArray(options)
              ? options[0]
              : options;
            if (option && option.value)
              updatePoll({
                preset: (option.value as unknown) as keyof typeof PRESETS,
              });
          }}
        />
      </Field>
      <Field label="Question" labelFor={questionId}>
        <textarea
          id={questionId}
          value={poll.question}
          onChange={(e) => updatePoll({ question: e.target.value })}
          className={styles.question}
          placeholder="Type question here"
        />
      </Field>
      <Answers answersId={answersId} poll={poll} updatePoll={updatePoll} />
      <Field label="Show Results to User" labelFor={resultsId}>
        <input
          id={resultsId}
          type="checkbox"
          checked={poll.show_results_to_user}
          disabled={role === 'channel_contributor'}
          onChange={(e) => {
            if (role !== 'channel_contributor')
              updatePoll({ show_results_to_user: e.target.checked });
          }}
        />
      </Field>
      <Field label="Confirmation message" labelFor={thankYouTextId}>
        <textarea
          maxLength={200}
          value={poll.thank_you_text}
          id={thankYouTextId}
          placeholder="Thank you for voting!"
          className={styles.question}
          onChange={(e) => {
            updatePoll({ thank_you_text: e.target.value });
          }}
        />
      </Field>
    </Fieldset>
  );
};
