import {
  allowedContextCommunications,
  ContextCommunication,
} from 'models/context-communication';
import { useQuery } from 'react-query';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgramQuery } from 'hooks/program';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import {
  fetchContextCommunication,
  ContextCommunicationData,
} from 'services/api-context-communication';
import { QueryResponse } from './common';

export const useContextCommunication = (
  programId: number,
  allowed = true
): QueryResponse<Array<ContextCommunication>> => {
  const { isLoading, error, data } = useQuery<ContextCommunicationData, Error>(
    ['Context Communication', programId],
    () => fetchContextCommunication({ programId }),
    { retry: false }
  );
  const { data: defaultAddress } = useDefaultEmailAddress();

  const program = useProgramQuery(programId);

  const { data: personalizedFieldsFlagV2 } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFieldsV2'
  );

  let filteredData = data;

  if (!personalizedFieldsFlagV2?.value) {
    filteredData = filteredData?.filter(
      ({ key }) => key === 'first_name' || key === 'last_name'
    );
  }

  const filterConditions = [
    { condition: !program.data?.brandSlug, key: 'brand_slug' },
    { condition: !program.data?.name, key: 'program_name' },
    { condition: !program.data?.complianceHashtag, key: 'program_hashtag' },
    {
      condition: !defaultAddress?.customPhysicalAddressEnabled,
      key: 'program_address',
    },
  ];

  filterConditions.forEach(({ condition, key }) => {
    if (condition) {
      filteredData = filteredData?.filter(
        ({ key: itemKey }) => itemKey !== key
      );
    }
  });
  const filteredDataWithUpdatedName = filteredData?.map((item) => {
    if (item.key === 'universal_identifier') {
      return { ...item, name: 'universal identifier' };
    }
    return item;
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: allowed
      ? allowedContextCommunications(filteredDataWithUpdatedName)
      : filteredDataWithUpdatedName,
  };
};
