import React from 'react';
import {
  Filter,
  isListFilter,
  isRequiredOrPaginatedFilter,
} from 'models/insight/Filter';
import {
  DeepLinkedParamsType,
  FiltersStateMap,
} from 'App/Program/Main/Insight/contexts/filtersStateReducer';

/**
 * this hook will return the initial filters necessary for a given list of all filters
 * and the deep linked params for a report
 *
 * "staticFilters" are the first 4 non-attribute filters
 * "deepLinkedFilters" are those that are in the query params
 * */

export const useInitialFilters = (
  allFilters: Filter[] | undefined,
  deepLinkedParams: DeepLinkedParamsType,
  staticFilters: Filter[]
): FiltersStateMap => {
  // any filters that are marked as required for a certain report
  const initialRequiredFilters: Filter[] = React.useMemo(() => {
    if (allFilters === undefined) return [];
    return allFilters.filter((f) => isRequiredOrPaginatedFilter(f));
  }, [allFilters]);

  const hasValues: Filter[] = React.useMemo(() => {
    return allFilters?.filter((f) => f.values && f.values.length > 0) || [];
  }, [allFilters]);

  const deepLinkedFilters: Filter[] = React.useMemo(() => {
    if (allFilters === undefined) return [];

    // cross reference the default query params object and the list of all filters
    // any deep linked params will take precedence over any "default" values
    return allFilters.filter((f) => deepLinkedParams.has(f.slug));
  }, [deepLinkedParams, allFilters]);

  const implicitValues = React.useMemo(() => {
    return hasValues?.filter(
      (f) =>
        staticFilters.every((s) => s.slug !== f.slug) &&
        deepLinkedFilters.every((d) => d.slug !== f.slug)
    );
  }, [hasValues, staticFilters, deepLinkedFilters]);

  const filtersWithDefault: Filter[] = React.useMemo(() => {
    return allFilters?.filter((f) => !!f.default) || [];
  }, [allFilters]);

  // the merged map of filters based on: initial, static, deep linked filters
  const mergedFilters: FiltersStateMap = React.useMemo(() => {
    const result: FiltersStateMap = new Map();

    const staticFiltersNotDeepLinked = staticFilters.filter(
      (f) => !deepLinkedParams.has(f.slug)
    );

    // add deep linked filters to the results
    // subFilter defaults will be accounted for also from the recursive call to setFilterAndValues()
    deepLinkedFilters.forEach((filter) => {
      result.set(filter.slug, {
        filter,
        selectedValues: deepLinkedParams.get(filter.slug) || new Set(),

        // attribute filters will need to be preemptively marked as in loading state
        // this prevents the pill button text from flickering since multiple
        // sources will be modifying the filter loading state
        isLoading: isListFilter(filter) && !!filter.valuesPending,
      });
    });

    // add initial required filters and static filters to the results
    initialRequiredFilters
      .concat(staticFiltersNotDeepLinked)
      .concat(implicitValues)
      .concat(filtersWithDefault)
      .forEach(function setFilterAndValues(filter) {
        const values = new Set<string>();

        // here we attempt to set any initial values to our initial and static filters
        // however, at this point only hard coded filters will have `filter.values`
        // filters with `pending_values=true` will retrieve their values when a date range is set. see
        // src/App/Program/Main/Insight/hooks/useInsightsFiltersWithUpdatedDojoListValues.ts
        // however, even without `filter.values` we will still set the default
        // default values will be matched with their labels later
        // filter defaults can be single strings or arrays of string/numbers
        if (filter.default) {
          if (Array.isArray(filter.default)) {
            filter.default.forEach((value: number | string) =>
              values.add(String(value))
            );
          } else {
            values.add(String(filter.default));
          }
        }
        result.set(filter.slug, {
          filter,
          selectedValues: values,
          isLoading: false,
        });

        // add any subfilters that may exist from the initial list of filters
        if (filter.subFilter) setFilterAndValues(filter.subFilter);
      });

    return result;
  }, [
    initialRequiredFilters,
    filtersWithDefault,
    implicitValues,
    staticFilters,
    deepLinkedFilters,
    deepLinkedParams,
  ]);

  return mergedFilters;
};
