import * as React from 'react';
import { DateTime, Interval } from 'luxon';
import { InfiniteContainer } from '../../../../../../DesignSystem/Layout/ListContainers';
import { BulkSelectWrapper } from '../../../../../../shared/BulkSelect/Wrapper';
import { InfiniteBannerList } from '../../../../../../shared/InfiniteBannerList';
import { Box } from '../../../../../../DesignSystem/Components';
import { Flex } from '../../../../../../DesignSystem/Layout/Flex';
import { BulkSelection } from '../../../../../../hooks/common';
import { BulkSelectActionType } from '../../../../../../shared/BulkSelect/Actions';
import { EmptyBanner } from '../../../../../../shared/BannerListItem/EmptyBanner';
import {
  Body,
  Caption,
  color,
} from '../../../../../../DesignSystem/Typography';
import { Tooltip } from '../../../../../../shared/Tooltip';
import { HoverDropdown } from '../../../../../../shared/hover-dropdown/HoverDropdown';

export type DataDownloadItem = {
  name: string;
  files?: {
    filename: string;
    activity_type: string;
    generated_at: string;
  }[];
  generated_at?: string;
};

export const DataDownloads: React.FC<{
  isLoading: boolean;
  data: Array<DataDownloadItem>;
  isFolders: boolean;
  downloadFileHandler: (ids: string[]) => void;
}> = ({ isLoading, data, isFolders, downloadFileHandler }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  return (
    <InfiniteContainer parentRef={parentRef} isFullWidth>
      {(height: number) => (
        <DownloadList
          height={height}
          isLoading={isLoading}
          isFolders={isFolders}
          data={data}
          downloadFileHandler={downloadFileHandler}
        />
      )}
    </InfiniteContainer>
  );
};

const DownloadList = ({
  height,
  isLoading,
  isFolders,
  data,
  downloadFileHandler,
}: {
  height: number;
  isLoading: boolean;
  isFolders: boolean;
  data: Array<DataDownloadItem>;
  downloadFileHandler: (ids: string[]) => void;
}) => {
  const actions: Array<BulkSelectActionType> = [
    { type: 'select', groupLabel: 'Actions', actions: [] },
    {
      type: 'button',
      actions: [
        {
          label: 'Download',
          designSystem: true,
          confirm: false,
          onClick: (bulkSelection: BulkSelection) => {
            let includedIds = [];
            if (bulkSelection.type === 'none') {
              includedIds = bulkSelection.includedIds;
            } else {
              for (let i = 0; i < data.length; i += 1) {
                if (!bulkSelection.excludedIds.includes(i.toString())) {
                  includedIds.push(i.toString());
                }
              }
            }
            downloadFileHandler(includedIds);
            return true;
          },
        },
      ],
    },
  ];

  const getInterval = (untilDate?: string) => {
    if (untilDate === undefined) {
      return -1;
    }
    const calculatedTime = DateTime.fromFormat(untilDate, 'yyyy-MM-dd').plus({
      days: 7,
    });
    if (calculatedTime.isValid) {
      return (
        Interval.fromDateTimes(
          DateTime.now().toUTC(),
          calculatedTime
        ).toDuration(['days', 'hours', 'minutes', 'seconds']).days || 0
      );
    }
    return -1;
  };

  const getDaysLeftString = (daysLeft: number) => {
    if (daysLeft < 0) {
      return '---';
    }
    if (daysLeft < 1) {
      return '< 1 day';
    }
    return daysLeft === 1 ? '1 day' : `${daysLeft} days`;
  };

  return (
    <BulkSelectWrapper
      totalRecords={data?.length ?? 0}
      itemName={isFolders ? 'Folders' : 'Files'}
      actions={actions}
    >
      <InfiniteBannerList
        isLoading={isLoading}
        itemCount={data.length}
        height={height - 54 - 20}
        fetchNextPage={() => {}}
        isFetchingNextPage={false}
      >
        {(index: number) => {
          const dataDownloadItem = data[index];
          const fileNumber = dataDownloadItem.files?.length ?? 0;
          const daysLeft = getInterval(dataDownloadItem.generated_at);
          return isFolders ? (
            <EmptyBanner
              rowId={index.toString()}
              hideFirstMenuItem
              menuItems={[
                {
                  title: 'Open Folder',
                  href: `downloads/${dataDownloadItem.name}`,
                },
                {
                  title: 'Download',
                  href: '',
                  onClick: () => {
                    downloadFileHandler([index.toString()]);
                  },
                },
              ]}
            >
              <Flex start spread style={{ width: '100%' }}>
                <Box padding={5}>
                  <Flex start style={{ columnGap: '16px' }}>
                    <Body>
                      <i
                        className="material-symbols-rounded"
                        style={{ verticalAlign: 'middle' }}
                      >
                        folder
                      </i>
                    </Body>
                    <Body>{dataDownloadItem.name}</Body>
                  </Flex>
                </Box>
                <Box padding={5}>
                  <Flex column>
                    <Caption semibold>CONTAINS</Caption>
                    <Body>
                      {fileNumber} file{fileNumber === 1 ? '' : 's'}
                    </Body>
                  </Flex>
                </Box>
                <Box padding={5}>
                  <Flex column>
                    <Caption semibold>FILE EXPIRY</Caption>
                    <Body color={daysLeft < 3 ? color.redFull : undefined}>
                      <HoverDropdown
                        dropdownRenderProp={() =>
                          daysLeft < 3 ? (
                            <Tooltip title="Exports for this date will expire soon and no longer be available." />
                          ) : null
                        }
                      >
                        {getDaysLeftString(daysLeft)}
                      </HoverDropdown>
                    </Body>
                  </Flex>
                </Box>
                <Box padding={5} />
              </Flex>
            </EmptyBanner>
          ) : (
            <EmptyBanner
              rowId={index.toString()}
              menuItems={[
                {
                  title: 'Download',
                  href: '',
                  onClick: () => {
                    downloadFileHandler([index.toString()]);
                  },
                },
              ]}
            >
              <Flex start>
                <Box padding={5}>
                  <i
                    className="material-symbols-rounded"
                    style={{ verticalAlign: 'middle' }}
                  >
                    description
                  </i>
                </Box>
                <Box padding={5}>
                  <Body>{dataDownloadItem.name}</Body>
                </Box>
              </Flex>
            </EmptyBanner>
          );
        }}
      </InfiniteBannerList>
    </BulkSelectWrapper>
  );
};
