import React from 'react';
import cx from 'classnames';
import styles from './search-bar.module.css';
import { SearchInput } from '../SearchInput';

export type SearchBarPropsType = {
  onChange: (text: string) => void;
  value?: string;
  placeholder?: string;
  totalRecords?: number;
  panelClassName?: string;
  labelClassName?: string;
  searchIconSize?: number;
  hasClearButton?: boolean;
  showSpinner?: boolean;
  compact?: boolean;
  width?: string;
  inputRef?: React.Ref<HTMLInputElement>;
};

export const SearchBar: React.FC<SearchBarPropsType> = ({
  onChange,
  value = '',
  placeholder = '',
  width,
  totalRecords,
  panelClassName,
  labelClassName,
  searchIconSize,
  compact,
  hasClearButton,
  showSpinner,
  inputRef,
}) => (
  <SearchInput
    panelClassName={cx({
      [styles.panel]: !panelClassName,
      [styles.compact]: compact ?? true,
    })}
    ref={inputRef}
    width={width}
    labelClassName={labelClassName || styles.label}
    inputClassName={styles.input}
    buttonClassName={styles.button}
    exportClassName={styles.export}
    searchIconSize={searchIconSize}
    value={value}
    totalRecords={totalRecords}
    placeholder={placeholder || 'Search items'}
    onChange={onChange}
    hasClearButton={hasClearButton}
    showSpinner={showSpinner}
  />
);
