import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Section, Subsection } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import styles from './styles.module.css';

export const CommunityDetails: React.FC<RouteComponentProps> = () => {
  const program = useProgram();
  return (
    <WithPermission permissions={['configureAdvocacyAccess']}>
      <FormPage
        title="Community Details"
        description="Manage community name, hashtags and more."
        breadcrumbs={[
          { label: 'Configure', to: '..' },
          { label: 'Community Details' },
        ]}
      >
        <Section
          href={`/${program.id}/configure/community-details/edit`}
          title="Community Details"
        >
          <Subsection title="Community Name" divider={false}>
            {program.name}
          </Subsection>
          <Subsection title="Community Slug URL" divider={false}>
            {program.slug}
          </Subsection>
          <Subsection title="Appended Hashtag" divider={false}>
            {program.complianceHashtag
              ? `#${program.complianceHashtag}`
              : 'None'}
          </Subsection>

          {program.gdprComplianceEnabled && (
            <Subsection title="Privacy Contacts">
              {!program.gdprComplianceContacts && 'None'}
              {program.gdprComplianceContacts && (
                <div className={styles.FlexContainer}>
                  {program.gdprComplianceContacts.split(',').map((c) => (
                    <div className={styles.Pill} key={c}>
                      {c.trim()}
                    </div>
                  ))}
                </div>
              )}
            </Subsection>
          )}
        </Section>
      </FormPage>
    </WithPermission>
  );
};
