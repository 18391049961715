import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useInitiativeQuery } from 'hooks/initiatives';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { Initiative } from 'models/initiative';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { Sidebar } from './components/Sidebar';
import { useActions } from './useActions';

import styles from './initiatives.module.css';
import { WithPermission } from '../../../../shared/WithPermission';

export const EditInitiative: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const [initiative, setInitiative] = React.useState<Initiative>();

  const { data, isLoading, errorMessage } = useInitiativeQuery(
    programId,
    id as number
  );

  const { update, archive, unarchive, destroy } = useActions(setInitiative);

  function onChange(updatedData: Partial<Initiative>) {
    setInitiative({ ...(initiative as Initiative), ...updatedData });
  }

  React.useEffect(() => {
    if (data) setInitiative(data);
  }, [data]);

  const header = (
    <>
      <h1 className="page-header">Initiative</h1>
    </>
  );

  const main = (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && (
        <Form initiative={initiative as Initiative} onChange={onChange} />
      )}
    </>
  );

  function onSave() {
    update(initiative as Initiative);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <Helmet>
        <title>Edit Initiative</title>
      </Helmet>
      {initiative && (
        <MainEditor
          header={header}
          main={main}
          sidebar={
            <Sidebar
              initiative={initiative}
              archive={archive}
              unarchive={unarchive}
              destroy={destroy}
            />
          }
          footer={<Footer initiative={initiative} action={onSave} />}
        />
      )}
    </WithPermission>
  );
};
