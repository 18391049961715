import * as React from 'react';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useLibraryTemplates } from 'hooks/useLibrary';
import { useDebounce } from 'hooks/useDebounce';
import { useUser } from 'contexts/user';
import { TemplatesList } from './TemplatesList';
import { FiltersBar } from './FiltersBar';

export const TemplatesListPage: React.FC<{
  isJourneyTemplates?: boolean;
  tabs?: { to: string; label: string }[];
}> = ({ tabs, isJourneyTemplates }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const { superAdmin } = useUser();

  const [searchTerm, setSearchTerm] = React.useState('');

  const [filterStatus, setFilterStatus] = React.useState<Array<string>>([
    'published',
  ]);
  const filterStatusDebounced = useDebounce(filterStatus);

  const statusFilteringProps = React.useMemo(() => {
    if (filterStatusDebounced.length === 1) {
      if (filterStatusDebounced[0] === 'published') {
        return {
          includeDisabled: false,
        };
      }
      if (filterStatusDebounced[0] === 'archived') {
        return { onlyDisabled: true, includeDisabled: false };
      }
    }

    return {
      includeDisabled: true,
    };
  }, [filterStatusDebounced]);

  const publicationState = React.useMemo(() => {
    // non-superadmin users don't need to see globaly archived items
    if (!superAdmin) {
      return ['published'];
    }
    // the superadmin should the items that the users have archived ('published' with disabled_library_item)
    // and the items that the superadmin has archived ('archived')
    if (
      (filterStatusDebounced.length === 1 &&
        filterStatusDebounced[0] === 'archived') ||
      filterStatusDebounced.length === 0
    ) {
      return ['published', 'archived'];
    }

    return filterStatusDebounced;
  }, [filterStatusDebounced, superAdmin]);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
    invalidateQuery,
  } = useLibraryTemplates({
    filter: {
      type: 'search',
      search: searchTerm,
      status: publicationState,
    },
    ...statusFilteringProps,
    permittedToUseOnly: true,
    isJourneyTemplates,
  });

  const handleFilter = React.useCallback((values: string[]) => {
    if (values.length > 0) {
      setFilterStatus(values);
    } else {
      setFilterStatus(['active']);
    }
  }, []);

  return (
    <ListPage
      title="Library"
      breadcrumbs={[{ label: 'Library' }]}
      tabs={tabs}
      filterbar={
        <FiltersBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          statuses={filterStatusDebounced}
          setStatuses={handleFilter}
        />
      }
    >
      {errorMessage && <>{errorMessage}</>}
      {data && !errorMessage && (
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <TemplatesList
              invalidateQuery={invalidateQuery}
              parentRef={parentRef}
              templates={data}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              height={height}
            />
          )}
        </InfiniteContainer>
      )}
    </ListPage>
  );
};
