import * as React from 'react';
import * as Blocks from 'models/publisher/block';
import { FieldInput } from 'DesignSystem/Form';
import { Subheading } from 'DesignSystem/Typography';
import { UserSelect } from 'components/users/UserSelect';
import { FieldFormProps } from '../../useFieldForm';
import { useUsersDataMutator } from './hooks/useUsersDataMutator';

export const Users: React.FC<FieldFormProps<Blocks.UserFieldData[]>> = (
  props
) => {
  const { value, onChange } = useUsersDataMutator(props);
  return (
    <FieldInput legend={<Subheading>Add and Remove Users</Subheading>}>
      <UserSelect initialValue={value} onChange={onChange} isMulti />
    </FieldInput>
  );
};
