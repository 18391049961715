import React, { useCallback, useState } from 'react';
import { Topic } from 'models/topic';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import {
  ListReturnType,
  useTopicsList,
} from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/useTopicsList';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Body, Caption } from 'DesignSystem/Typography';
import { pluralize } from 'utility/text';
import { SearchInput } from 'shared/SearchInput';

interface SelectTopicProps {
  disabled?: boolean;
  selectedTopics: Array<Topic>;
  onTopicsChange: (value: Array<Topic>) => void;
}

export const SelectTopic: React.FC<SelectTopicProps> = ({
  disabled,
  selectedTopics,
  onTopicsChange,
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const topicsData = useTopicsList(selectedTopics, searchText, onTopicsChange);

  const rowRenderer = useCallback(
    (dataByRowId: ListReturnType['dataByRowId']) => (rowId: string) => {
      const item: Topic = dataByRowId[rowId] as Topic;
      return item ? (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box margin={[0, 16, 0, 0]}>
            <Body>{item.name}</Body>
          </Box>
          <Box style={{ marginLeft: 'auto' }}>
            <Caption>
              {`${item.followerCount} ${pluralize(
                item.followerCount,
                'subscriber'
              )}`}
            </Caption>
          </Box>
        </Box>
      ) : null;
    },
    []
  );

  const selectRow = (
    selectedIds: ListReturnType['selectedIds'],
    onSelectedIdsChange: ListReturnType['onSelectedIdsChange'],
    updateCache: (id: string) => void
  ) => (id: string) => {
    updateCache(id);
    const newSelectedIds = [...selectedIds];
    const index = selectedIds.indexOf(id);
    if (index >= 0) {
      newSelectedIds.splice(index, 1);
    } else {
      newSelectedIds.push(id);
    }
    onSelectedIdsChange(newSelectedIds);
  };

  const selectTopicDropdown = (
    <Box margin={[-8, 0, 0, 0]} minWidth={400}>
      <Popover padding={4}>
        <InfiniteSelect
          existenceGranted={false}
          rowIds={topicsData.dataRowIds}
          rowRenderProp={rowRenderer(topicsData.dataByRowId)}
          maxHeight={300}
          itemHeight={40}
          selectedIds={topicsData.selectedIds}
          onSelectedIdsChange={topicsData.onSelectedIdsChange}
          fetchNextPage={topicsData.fetchNextPage}
          hasNextPage={topicsData.hasNextPage}
          isFetchingNextPage={topicsData.isFetchingNextPage}
          isLoading={topicsData.isLoading}
          onHandleChange={selectRow(
            topicsData.selectedIds,
            topicsData.onSelectedIdsChange,
            topicsData.updateCache
          )}
          hoverFocus={false}
          clearDisabled
          searchEnabled={false}
          searchTerm={searchText}
          onSearchTermChange={setSearchText}
          noShadow
        />
      </Popover>
    </Box>
  );

  return (
    <>
      <ClickDropdown
        dropdownRenderProp={selectTopicDropdown}
        matchReferenceWidth
        disabled={disabled}
        autoUpdate
        referencePress={false}
        onClose={() => {
          setSearchText('');
        }}
        ignoreKeys
      >
        <div>
          <SearchInput
            placeholder="Search topics"
            value={searchText || ''}
            onChange={setSearchText}
          />
        </div>
      </ClickDropdown>
    </>
  );
};
