export type PaginationData = {
  totalPages?: number;
  pageNumber?: number;
  totalRecords: number;
  currentPage: number;
};

export type Page<T> = {
  data: T[];
  meta: PaginationData;
};

export type EmptyablePage<T> = {
  data: T[];
  meta?: PaginationData;
};

export function fqApiUrl(pathWithOrWithoutSlash: string): string {
  const path = pathWithOrWithoutSlash.replace(/^\/+/, '');
  return `${process.env.REACT_APP_BOSSANOVA_DOMAIN}/${path}`;
}
