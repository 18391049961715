// perform a shallow comparison of two objects
// ignoring eslint warnings because it doesn't like `any` or `hasOwnProperty()`
// https://stackoverflow.com/a/52323412
export function shallowObjectCompare<T>(obj1: T, obj2: T): boolean {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(obj1).length === Object.keys(obj2).length &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(obj1).every(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-prototype-builtins
      (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    )
  );
}
