import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Sortable } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { SourceMenu } from '../../shared/SourceMenu';
import { LinkPreview } from '../../shared/LinkPreview';
import { useList } from '../hooks/useList';

export const List: React.FC<Parameters<typeof useList>[0]> = (props) => {
  const { links, onUpdate, onEdit, remove, menu } = useList(props);
  return (
    <>
      <Sortable
        items={links}
        idAttr="uuid"
        onSort={onUpdate}
        Item={({ item, index }) => (
          <LinkPreview
            key={item.url}
            link={item}
            onEdit={() => onEdit(index)}
            onRemove={links.length < 2 ? undefined : () => remove(index)}
          />
        )}
      />
      <br />
      <SourceMenu menu={menu} top={1} left={46}>
        <Button
          block
          icon={
            <Text.PageHeading color={Text.color.gray00}>+</Text.PageHeading>
          }
          label="Add Links"
        />
      </SourceMenu>
    </>
  );
};
