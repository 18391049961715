import { DateTime } from 'luxon';
import { ParsedAuditEvent, Update } from 'services/api-program';
import { isEmpty, isEmptyArray } from 'utility/objectUtils';
import { Topic } from 'models/topic';

const EVENT_CHANGE_MAX_LEN = 50;

function sliceString(str: string) {
  if (str && str.length > EVENT_CHANGE_MAX_LEN) {
    return `${str.slice(0, EVENT_CHANGE_MAX_LEN).trim()}...`;
  }
  return str;
}

type UpdateType = Record<string, number[] | string[] | unknown[]>;
function getNonEmptyUpdates(updateData: unknown) {
  const updates = (updateData || {}) as UpdateType;
  const result: Record<string, unknown> = {};
  Object.keys(updates).forEach((key) => {
    if (Array.isArray(updates[key])) {
      if (
        updates[key].filter((d) => d).length !== 0 &&
        updates[key][0] !== updates[key][1]
      ) {
        result[key] = updates[key];
      }
    } else {
      const PUBLIC_CONTENT_FIELD = 'public-content';
      const PREPOPULATED_SHARE_MESSAGE_FIELD = 'prepopulated-share-message';
      // we don't want all the event types to be listed
      if (
        ![PUBLIC_CONTENT_FIELD, PREPOPULATED_SHARE_MESSAGE_FIELD].includes(key)
      ) {
        result[key] = updates[key];
      }
    }
  });
  return (result as unknown) as Update[];
}

function stringFromTo(updates: UpdateType, name: string) {
  const updates1 = (updates[1] as unknown) as string;
  const updates0 = (updates[0] as unknown) as string;
  if (updates1 === null || updates1 === '') {
    return ` removed ${name}`;
  }
  return ` set ${name} ${
    updates[0] && ((updates as unknown) as string[]).length > 0
      ? `from "${sliceString(updates0)}"`
      : ''
  } to "${sliceString(updates1)}"`;
}

function channelNames(ids: number[] = [], externalSources: Topic[] = []) {
  const contentChannels = externalSources.map((source) => ({
    id: parseInt(source.id as string, 10),
    name: source.name,
  }));
  return ids
    .map(
      (id) =>
        contentChannels.find((c) => c.id === id && c.name?.length > 0)?.name
    )
    .filter((c) => !!c)
    .join(', ');
}

type PromotionNames = {
  lite: string;
  announce: string;
  require: string;
};
const promotionNames: PromotionNames = {
  lite: 'announce',
  announce: 'promote',
  require: 'require',
};

type ResultType = string[] & { removed?: unknown[] };
// massage one update into a more readable message
function processUpdate(
  key: string,
  update: UpdateType = {},
  changes: string[],
  externalSources: Topic[]
) {
  const result: ResultType = [];
  switch (key) {
    case 'display-name':
      return [` set author to ${update[1]}`];
    case 'note':
      return [stringFromTo(update, 'note')];
    case 'title':
      return [stringFromTo(update, 'title')];
    case 'channels':
      if (!isEmptyArray(update.inserted)) {
        result.push(
          ` added topic${update.inserted.length > 1 ? 's' : ''} ${channelNames(
            update.inserted as number[],
            externalSources
          )}`
        );
      }
      if (!isEmptyArray(update.removed)) {
        result.push(
          ` removed topic${update.removed.length > 1 ? 's' : ''} ${channelNames(
            update.removed as number[],
            externalSources
          )}`
        );
      }
      return result;
    case 'images':
      if (!isEmptyArray(update.inserted) && !update.removed) {
        result.push(' added image to post');
      }
      if (!isEmptyArray(update.removed) && !update.inserted) {
        result.push(' removed image from post');
      }
      if (!isEmptyArray(update.inserted) && !isEmptyArray(result.removed)) {
        changes.push(' replaced post image');
      }
      return result;
    case 'videos':
      if (!isEmptyArray(update.inserted) && !update.removed) {
        result.push(' added video to post');
      }
      if (!isEmptyArray(update.removed) && !update.inserted) {
        result.push(' removed video from post');
      }
      if (!isEmptyArray(update.inserted) && !isEmptyArray(result.removed)) {
        changes.push(' replaced post video');
      }
      return result;
    case 'is-shareable':
      if ((update[0] as unknown) === false && (update[1] as unknown) === true) {
        result.push(' made post shareable');
      }
      if ((update[0] as unknown) === true && (update[1] as unknown) === false) {
        result.push(' made post private');
      }
      return result;
    case 'summary':
      return [' modified post summary'];
    case 'body':
      return [' modified post body'];
    case 'published-at':
      if (update[1])
        return [
          ` set post schedule to ${DateTime.fromISO(
            (update[1] as unknown) as string
          ).toFormat('MMMM Do YYYY, h:mm A')}`,
        ];
      return [' removed post schedule'];
    case 'expired-at':
      if (update[1]) {
        return [
          ` set post archive date to ${DateTime.fromISO(
            (update[1] as unknown) as string
          ).toFormat('MMMM Do YYYY, h:mm A')}`,
        ];
      }
      return [' removed post archive date'];
    case 'prepopulated-share-message':
      // this needs explanation:
      // the share message is set by default on the back-end, regardless of the admin actions
      // this holds true even for non-shareable posts (e.g. notes)
      // this check ("update[0]") incorporates that special knowledge
      // whenever the backend behavior is touched, this check should be removed.
      if (update[0]) return [` set share message to "${update[1]}"`];
      break;
    case 'featured-at':
      if (update[0] && !update[1]) {
        return [' stopped featuring the post'];
      }
      if (!update[0] && update[1]) {
        return [' featured the post'];
      }
      break;
    case 'comment-thread':
      if (update.active[0] && !update.active[1]) {
        return [' disabled commenting on the post'];
      }
      if (!update.active[0] && update.active[1]) {
        return [' enabled commenting on the post'];
      }
      break;
    case 'display-settings':
      if (update['message-card-color']) {
        return ['changed note color'];
      }
      break;
    case 'content-promotion':
      if (update['promotion-type']) {
        const pt = update['promotion-type'];
        const pt1 = pt[1] as keyof PromotionNames;
        return [`set smart campaign to ${promotionNames[pt1]}`];
      }
      if (update.configuration) {
        return ['modified smart campaign'];
      }
      break;
    case 'content-promotions':
      if (update.removed) {
        return ['removed smart campaign'];
      }
      break;
    case 'initiative-tags':
      return ['modified tags'];
    default:
      return [];
  }
  return [];
}

export function concatChangesMessage(changes: Array<string>): string {
  return changes.reduce((concatedMessage, change, idx) => {
    const isChangeNotEmpty = change?.length > 0;
    if (!isChangeNotEmpty) {
      return concatedMessage;
    }

    let delimiter = '';
    if (
      concatedMessage !== '' &&
      idx === changes.length - 1 &&
      isChangeNotEmpty
    ) {
      delimiter = ' and ';
    } else if (idx > 0) {
      delimiter = ', ';
    }

    return concatedMessage + delimiter + change;
  }, '');
}

function extractAdminLink(msg: string): string {
  const r = /<a.*<\/a>/;
  const m = msg?.match(r);
  return (m && !isEmptyArray(m as string[]) && m[0]) || '';
}

export type ProcessedAuditEvent = {
  id: string | number;
  record_id: number;
  admin_link?: string;
  action: string;
  messages: string[];
  message: string;
  occurred_at: string;
  avatar_url: string;
  author_name?: string;
};
function processEvent(
  event: ParsedAuditEvent,
  externalSources: Topic[]
): ProcessedAuditEvent {
  const { attributes } = event;
  const changes: string[] = Object.entries(attributes.updates || {})
    .reduce((processedChangesArr: string[], [key, update]) => {
      return processedChangesArr.concat(
        processUpdate(
          key,
          update as UpdateType,
          processedChangesArr,
          externalSources
        )
      );
    }, [])
    .filter((c) => c);
  let message = attributes.message.replace(
    `modified content ${attributes.recordId}`,
    `${concatChangesMessage(changes)}`
  );
  message = message.replace(`content ${attributes.recordId}'s`, '');
  message = message.replace(`content ${attributes.recordId}`, 'post');
  message = message.replace("'s note", '');

  // note -- the location of this code (ie in api) has it rely on the endpoint (governor)
  // to perform checks on permissions, internationalize, even build links,
  // when issuing attribute.message.
  // it is unfortunate to
  // - have display logic in governor
  // - massage it in studio when the display doesn't match what is desired
  // In the future,
  // - this code (ie rendering of audit events) should be moved outside of 'api'
  // - it should perform conditional rendering locally

  // publishers and contributors don't see a link to the actor -- instead just the name
  const admin_link =
    extractAdminLink(attributes.message) || attributes.authorName;
  const actionNames: Record<string, string> = {
    draft: 'drafted',
    create: 'created',
    update: 'updated',
    publish: 'published',
    schedule: 'scheduled',
    restore: 'restored',
    destroy: 'deleted',
    delete: 'deleted',
  };
  let action: string = actionNames[attributes.action] || attributes.action;
  const kinds: Record<string, string> = {
    content: 'post',
    content_note: 'note',
    polls: 'poll',
  };
  const object = kinds[attributes.kind] || attributes.kind;

  // for a post, say 'archived', not 'deleted'
  if (action === 'deleted' && object === 'post') action = 'archived';

  return {
    id: event.id,
    record_id: attributes.recordId,
    admin_link,
    action: ` ${action} ${object}`,
    messages: changes,
    message,
    occurred_at: attributes.occurredAt,
    avatar_url: attributes.userAvatarUrl,
    author_name: attributes.authorName,
  };
}

type ParseActivitiesResponseProps = {
  data: Array<ParsedAuditEvent>;
  externalSources: Topic[];
};
export const parseActivitiesResponse = ({
  data,
  externalSources,
}: ParseActivitiesResponseProps): Array<ProcessedAuditEvent> =>
  // parse the updates as json
  ([...data] || [])
    .map((d) => {
      let { updates } = d.attributes;
      if (!isEmpty(d.attributes.updates)) {
        try {
          updates = JSON.parse(`${d.attributes.updates}`);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }

      const newItem: ParsedAuditEvent = {
        ...d,
        attributes: { ...d.attributes, updates },
      };
      return newItem;
    })
    // drop events that describe empty updates (keep events that describe no update - e.g. delete / publish )
    .filter((d) => {
      return (
        isEmpty(d.attributes.updates) ||
        !isEmpty(getNonEmptyUpdates(d.attributes.updates))
      );
    })
    // and process
    .map((d) => {
      const newItem = { ...d };
      newItem.attributes.updates = getNonEmptyUpdates(d.attributes.updates);
      return processEvent(newItem, externalSources);
    });
