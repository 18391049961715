import React, { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { LitmusRole } from 'models/role';
import { ClickDropdown } from 'shared/ClickDropdown';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import cx from 'classnames';
import { useRolesInfiniteQuery } from 'hooks/roles';
import styles from './role-select.module.css';

export const RolesFilter: React.FC<{
  value: Array<LitmusRole>;
  onChange: (value: Array<LitmusRole>) => void;
  onClose?: () => void;
}> = ({ value, onChange, onClose }) => {
  const { id: programId } = useProgram();

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });

  const rolesByRowId = useMemo(() => {
    const map: { [key: string]: LitmusRole } = {
      member: {
        parent: '',
        tenant: '',
        name: 'member',
        title: 'Member',
        description: '',
        sequence: 0,
        aliases: [],
        type: 'primary',
      },
    };

    data.forEach((role) => {
      map[role.name] = role;
    });
    value.forEach((role) => {
      if (!map[role.name]) map[role.name] = role;
    });
    return map;
  }, [data, value]);

  const rowIds = Object.keys(rolesByRowId);
  const selectedIds = value.map(({ name }) => name);

  const renderRow = React.useCallback(
    (rowId: string) => {
      const item = rolesByRowId[rowId];
      return item ? (
        <div className={cx(styles.title, styles.filterTitle)}>
          <span>{item.title}</span>
        </div>
      ) : null;
    },
    [rolesByRowId]
  );

  const onSelectedIdsChange = useCallback(
    (ids: Array<string>) => onChange(ids.map((id) => rolesByRowId[id])),
    [onChange, rolesByRowId]
  );

  const selectedValues = React.useMemo(
    () => selectedIds.map((id) => rolesByRowId[id].title),
    [rolesByRowId, selectedIds]
  );

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={300}
        itemHeight={32}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        itemClassName="filter-item"
        checkboxClassName={styles.filterCheckbox}
        hasClearSearchButton
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [
    renderRow,
    rowIds,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    onSelectedIdsChange,
    selectedIds,
  ]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
      <TriggerButton name="Role" values={selectedValues} onClose={onClose} />
    </ClickDropdown>
  );
};
