import React from 'react';
import ReactDOM from 'react-dom';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './inline-dropzone.module.css';

export type DropzoneSettings = {
  type: 'attachment' | 'image' | 'video';
  field: string;
};

export function isMouseOver(x: number, y: number, blockRect: DOMRect): boolean {
  return (
    x > blockRect.x &&
    x < blockRect.x + blockRect.width &&
    y > blockRect.y &&
    y < blockRect.y + blockRect.height
  );
}

export function blockNode(id: string): Element | null {
  return document.querySelector(`#${id}`);
}

export function rect(node: Element): DOMRect {
  return node.getBoundingClientRect();
}

export function editors(node: Element): NodeListOf<Element> {
  return node.querySelectorAll('.file-dropzone');
}

export function createDropzone(
  node: Element,
  dropzoneData: Element,
  onDrop: (settings: DropzoneSettings) => (event: Event) => void
): void {
  const dropzone = document.createElement('div');
  const settings: DropzoneSettings = {
    type: dropzoneData.getAttribute(
      'data-dropzone-type'
    ) as DropzoneSettings['type'],
    field: dropzoneData.getAttribute('data-dropzone-field') as string,
  };

  dropzone.className = styles.dropzone;
  dropzone.innerText = `Drop to upload ${settings.type}`;
  dropzone.addEventListener('drop', onDrop(settings));
  (node as HTMLDivElement).style.setProperty('position', 'relative');
  node.appendChild(dropzone);
}

export function createLoading(node: Element): void {
  const mountPoint = document.createElement('div');
  node.appendChild(mountPoint);

  const loadingElement = React.createElement(
    'div',
    { className: styles.loading },
    React.createElement(LoadingSpinner),
    React.createElement(
      'p',
      { className: styles.loadingPrompt },
      "Uploading... Please don't navigate away"
    )
  );
  ReactDOM.render(loadingElement, mountPoint);
}

export function removeLoading(node: Element): void {
  const loading = node.querySelector(`.${styles.loading}`);
  loading?.remove();
}

export function removeAllDropzones(): void {
  const dropzones = document.querySelectorAll(`.${styles.dropzone}`);
  dropzones.forEach((zone) => zone.remove());
}
