import { useMemo } from 'react';
import lunr from 'lunr';
import { Collection } from 'models/insight/Collection';
import { PlaceHolderReport as Report } from 'models/insight/Report';

export type CollectionReport = Report & { collections: Collection[] };

export const useReportFinder = (
  collections: Collection[]
): ((text: string) => CollectionReport[]) => {
  const allReports = useMemo(() => {
    const reports: { [id: string]: CollectionReport } = {};
    collections.forEach((coll) =>
      coll.reports.forEach((report) => {
        const { id } = report;
        reports[id] = reports[id] || report;
        reports[id].collections = reports[id].collections || [];
        reports[id].collections.push(coll);
      })
    );
    return Object.values(reports);
  }, [collections]);

  const searchableReportTexts = useMemo(() => {
    return allReports.map((report) => ({
      id: report.id,
      text: `${report.title}`,
      des: `${report.description}`,
      coll_text: `${report.collections.map((c) => `${c.title}`).join(' ')}`,
    }));
  }, [allReports]);

  const lunrIndex = useMemo(() => {
    return lunr(function idx() {
      this.ref('id');
      this.field('text', { boost: 15 });
      this.field('des', { boost: 10 });
      this.field('coll_text', { boost: 5 });
      searchableReportTexts.forEach((report) => {
        this.add(report);
      });
    });
  }, [searchableReportTexts]);

  const findLunrSearch = (text: string) => {
    try {
      // Lunr provides a robust searching syntax.
      // But '"' are taken literally, whereas people use them for grouping
      return lunrIndex.search(
        `%${text.replace(/"/g, '').trim().replace(/ /g, '_')}%`
      );
    } catch (_e1) {
      // But when the user types some special characters, Lunr will
      // often fail trying to parse it. Give it another go without'em.
      try {
        const stripped = text.replace(/[:~*^+-]/g, '');
        if (stripped) return lunrIndex.search(stripped.trim());
      } catch (_e2) {
        // It will be merged with the "simple search" results anyway.
      }
      return [];
    }
  };

  const findSimpleSearch = (text: string) =>
    searchableReportTexts.filter(
      (r) =>
        r.text
          .toLocaleLowerCase()
          .indexOf(text.replace(/"/g, '').trim().toLocaleLowerCase()) >= 0 ||
        r.des
          .toLocaleLowerCase()
          .indexOf(text.replace(/"/g, '').trim().toLocaleLowerCase()) >= 0 ||
        r.coll_text
          .toLocaleLowerCase()
          .indexOf(text.replace(/"/g, '').trim().toLocaleLowerCase()) >= 0
    );

  return (text: string) => {
    const lunrMatches = findLunrSearch(text);
    const lookup = allReports.reduce(
      (
        matches: { [id: string]: CollectionReport },
        report: CollectionReport
      ) => {
        /* eslint-disable no-param-reassign */
        matches[report.id] = report;
        /* eslint-disable no-param-reassign */
        return matches;
      },
      {}
    );
    const weighted: CollectionReport[] = lunrMatches
      .map((res) => {
        const match = lookup[res.ref];
        delete lookup[res.ref];
        return match;
      })
      .filter((o) => o);
    const simpleMatches = findSimpleSearch(text);
    const simple: CollectionReport[] = simpleMatches
      .map((res) => {
        const match = lookup[res.id];
        delete lookup[res.id];
        return match;
      })
      .filter((o) => o);

    return [...weighted, ...simple];
  };
};
