import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  search: string;
};

export type ChatCompletionData = {
  outputs: Array<string>;
  task_ids: Array<string>;
  status: string;
};

export const fetchAINavigationIntent = async (
  props: FetchProps
): Promise<ChatCompletionData> => {
  const { programId } = props;

  const url = `${apiRoot}/samba/programs/${programId}/ai_navigation`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(props),
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`Error fetching AI response: ${response.status}`);
};
