import React from 'react';
import { useProgram } from 'contexts/program';
import { mapDataToAudience } from 'hooks/audience';
import { Scope } from 'models/bulk-update-permissions-job';
import { Template } from 'models/library';
import { TemplateContainerType } from 'models/template';
import { fetchById as GetAuthorAliasById } from 'services/api-author-alias';
import { fetchAudience } from 'services/api-audiences';
import { fetchById } from 'services/api-email-alias';
import { getTemplate } from 'services/api-library';
import { fetchTopicById } from 'services/api-topics';

export type ScopeData = {
  jobScope: string;
  scopeTitle: string;
};

export const GetScopesData = (bulkUpdateJobScopes: Scope[]): ScopeData[] => {
  const { id: programId } = useProgram();
  const [selectedJobScopes, setSelectedJobScopes] = React.useState<ScopeData[]>(
    []
  );

  // Load the job's scopes
  React.useEffect(() => {
    (async () => {
      const newScopeData: ScopeData[] = [];

      await Promise.all(
        bulkUpdateJobScopes.map(async (scope) => {
          const scopeName = await getScopeName(programId, scope.name);
          newScopeData.push({ jobScope: scope.name, scopeTitle: scopeName });
        })
      );

      setSelectedJobScopes(newScopeData);
    })();
  }, [programId, bulkUpdateJobScopes]);

  return selectedJobScopes;
};

async function getScopeName(programId: number, scope: string) {
  const [type, id] = scope.split(':');

  switch (type) {
    case 'topic': {
      const topic = await fetchTopicById(programId, id);
      return topic.name;
    }
    case 'audience': {
      const response = await fetchAudience(programId, id);
      const audience = mapDataToAudience(response);
      return audience.title;
    }
    case 'publisher_template': {
      const templateData = await getTemplate({
        programId,
        templateId: Number(id),
        containerType: TemplateContainerType.Template,
      });
      const template = {
        id: templateData.data.id,
        ...templateData.data.attributes,
      } as Template;
      return template.title;
    }
    case 'sender_account': {
      const email_alias = await fetchById(programId, id);
      return email_alias.attributes.name;
    }
    case 'author_alias':
    default: {
      const author_alias = await GetAuthorAliasById(Number(id), programId);
      return author_alias.displayName;
    }
  }
}
