import React from 'react';
import DateRangeInput from 'shared/DateRangeInput';
import { FilterDropdownContainer } from 'App/Program/Main/Insight/components/Filters/shared/FilterDropdownContainer';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { Filter } from 'models/insight/Filter';
import {
  AbsoluteRange,
  DateRange,
  RelativeRange,
  Timezone,
} from 'shared/DateRangeInput/DateRange';
import { DateTime } from 'luxon';
import { FilterValue } from 'services/api-insights';

type Props = {
  updateFilter: (filter: Filter, value: FilterValue) => void;
  setShowReset?: (arg0: boolean) => void;
  shouldUseTimezone?: boolean;
  initialValue?: DateRange;
};
export const DateRangeInputWrapper: React.FC<Props> = ({
  updateFilter,
  setShowReset,
  shouldUseTimezone,
  initialValue,
}) => {
  const {
    filter,
    setVisible,
    defaultValue,
    setPillButtonDescriptionWithDefault,
  } = React.useContext(FilterDropdownContext);

  // We only allow undefined when the hook is initialized
  // we should never be setting this variable to undefined anywhere
  // setting undefined as a value explicitly will cause subtle bugs
  // the date range dropdown component will always return a date range object
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>(
    initialValue
  );
  const [previousRange, setPreviousRange] = React.useState<DateRange>();
  const [timezone, setTimezone] = React.useState<Timezone | undefined>(
    Timezone.build_from_offset(DateTime.local().toFormat('ZZZZZ'), true)
  );

  const defaultDateRange: DateRange | undefined = React.useMemo(() => {
    if (initialValue) {
      return initialValue;
    }
    return defaultValue === undefined
      ? undefined
      : DateRange.buildFromKey(defaultValue);
  }, [defaultValue, initialValue]);

  React.useEffect(() => {
    if (
      !initialValue &&
      defaultDateRange !== undefined &&
      defaultDateRange === initialValue
    ) {
      setDateRange(defaultDateRange);
    }
  }, [dateRange, defaultDateRange, initialValue]);

  React.useEffect(() => {
    if (!dateRange) {
      return;
    }
    const dateRangeKey = dateRange
      ?.toAbsolute()
      .extendEndDateFromTodayToTomorrowMaybe()
      .formatStandard();

    let dateRangeText;
    if (dateRange instanceof RelativeRange) {
      dateRangeText = dateRange.label;
    } else if (dateRange instanceof AbsoluteRange) {
      dateRangeText = dateRange.format('D');
      if (timezone) {
        const zoneText = ` (${DateTime.local()
          .setZone(timezone.key)
          .toFormat('ZZZ z')})`;
        dateRangeText += zoneText.toUpperCase();
      }
    }

    // update pill text
    setPillButtonDescriptionWithDefault(dateRangeText);

    // update showReset, if dateRange update
    if (
      dateRange &&
      previousRange &&
      setShowReset &&
      dateRange.key !== previousRange.key
    )
      setShowReset(true);
    // update Previous Range
    setPreviousRange(dateRange);

    // update selected filter state
    updateFilter(filter, dateRangeKey);
  }, [
    timezone,
    dateRange,
    setPillButtonDescriptionWithDefault,
    updateFilter,
    filter,
    setShowReset,
    previousRange,
  ]);

  const onDone = (selectedDateRange: DateRange): void => {
    setDateRange(selectedDateRange);
    setVisible(false);
  };

  return (
    <FilterDropdownContainer>
      <DateRangeInput
        onStateChange={setDateRange}
        onZoneChange={setTimezone}
        initialValue={defaultDateRange}
        shouldUseTimezone={shouldUseTimezone}
        allowFutureSelection
        onDone={onDone}
      />
    </FilterDropdownContainer>
  );
};
