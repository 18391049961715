import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionTextAlignRight: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    onClick={() => {
      editor.chain().focus().setTextAlign('right').run();
    }}
    disabled={!editor.can().chain().focus().setTextAlign('right').run()}
    isActive={editor.isActive({ textAlign: 'right' })}
    icon="format_align_right"
    aria-label="Right align"
  />
);
