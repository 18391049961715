import React from 'react';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import {
  useCreateEmailPreview,
  useEmailPreviewQuery,
} from 'hooks/email-preview';
import { Targets } from 'models/publisher/block';
import {
  EmailPreview as EmailPreviewType,
  Status,
} from 'services/api-email-previews';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MAIcon } from 'shared/MAIcon';
import { groupArrayBy } from 'utility/objectUtils';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { DevModeContext } from '../index';
import styles from './email-preview.module.css';

export const EmailPreview: React.FC<{
  delivery?: Targets;
  webFontsEnabled: boolean;
}> = ({ delivery = Targets.email, webFontsEnabled }) => {
  const { enabled } = React.useContext(DevModeContext);
  const {
    isLoading,
    disabled,
    emailPreviewsByCategory,
    mutate,
  } = useEmailPreview({ delivery, webFontsEnabled, enabled });

  if (!enabled) return null;

  if (isLoading)
    return (
      <Flex>
        <LoadingSpinner />
      </Flex>
    );

  return emailPreviewsByCategory ? (
    <ul>
      {Object.keys(emailPreviewsByCategory).map((category) => (
        <EmailPreviewCategory
          category={category}
          emailPreviews={emailPreviewsByCategory[category]}
          key={category}
        />
      ))}
    </ul>
  ) : (
    <Button
      onClick={() => mutate()}
      disabled={disabled}
      label="Create email previews"
      className={styles.createPreviewButton}
      block
    />
  );
};

const useEmailPreview: (props: {
  delivery: Targets;
  webFontsEnabled: boolean;
  enabled: boolean;
}) => {
  isLoading: boolean;
  disabled: boolean;
  mutate: ReturnType<typeof useCreateEmailPreview>['mutate'];
  emailPreviewsByCategory?: Record<
    EmailPreviewType['category'],
    EmailPreviewType[]
  >;
} = ({ delivery, webFontsEnabled, enabled }) => {
  const { id: programId } = useProgram();
  const { post } = usePublisher();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [refetch, setRefetch] = React.useState<boolean>(false);
  const timeoutFetchRef = React.useRef<NodeJS.Timeout>();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );
  const flags = { webFontsEnabled, preferOutlook365 };
  const { data, isLoading: isFetching } = useEmailPreviewQuery({
    programId,
    post,
    delivery,
    flags,
    enabled,
    refetch,
  });

  React.useEffect(() => setIsLoading(data?.length ? false : isFetching), [
    data?.length,
    isFetching,
  ]);

  React.useEffect(() => {
    if (data?.length) {
      const areAllCompleted = data.every(
        (emailPreview) => emailPreview.status === Status.Completed
      );
      if (!areAllCompleted) return;

      setRefetch(false);
      if (timeoutFetchRef.current) {
        clearTimeout(timeoutFetchRef.current);
        timeoutFetchRef.current = undefined;
      }
    }
  }, [data]);

  const { mutate, isLoading: isCreating } = useCreateEmailPreview({
    programId,
    post,
    delivery,
    webFontsEnabled,
    onSuccess: () => {
      setIsLoading(true);

      // Hacky: keep checking for email previews to be processed
      // with a timeout of 15 seconds.
      setRefetch(true);
      timeoutFetchRef.current = setTimeout(() => {
        setRefetch(false);
        timeoutFetchRef.current = undefined;
      }, 15000);
    },
  });

  const emailPreviewsByCategory = React.useMemo(
    () =>
      data?.length
        ? groupArrayBy<EmailPreviewType, EmailPreviewType['category']>(
            data,
            ({ category }) => category
          )
        : undefined,
    [data]
  );

  return {
    isLoading,
    disabled: isCreating,
    mutate,
    emailPreviewsByCategory,
  };
};

const EmailPreviewCategory: React.FC<{
  category: EmailPreviewType['category'];
  emailPreviews: EmailPreviewType[];
}> = ({ category, emailPreviews }) => (
  <li>
    {category}
    <ul className={styles.clientList}>
      {emailPreviews.map((preview) => (
        <EmailPreviewItem
          preview={preview}
          key={`${preview.displayName} ${preview.os}`}
        />
      ))}
    </ul>
  </li>
);

const EmailPreviewItem: React.FC<{ preview: EmailPreviewType }> = ({
  preview,
}) => {
  const screenshots = Object.keys(preview.screenshots)
    .map<React.ReactNode>((size) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={preview.screenshots[size]}
        className={styles.screenshotLink}
      >
        {size}
      </a>
    ))
    .reduce((prev, curr) => [prev, ', ', curr]);

  return (
    <li className={styles.clientItem}>
      {preview.status === Status.Processing && (
        <span style={{ verticalAlign: 'middle' }}>
          <MAIcon name="sync" />
        </span>
      )}
      {preview.displayName} {preview.os}: {screenshots}
    </li>
  );
};
