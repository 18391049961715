import { useQuery, useQueryClient } from 'react-query';
import camelcaseKeys from 'camelcase-keys';
import { MutationOptions, QueryResponse } from './common';
import {
  fetchAllFeatureFlags,
  fetchFeatureFlags,
  fetchProgramCustomizations,
  fetchUserGeneratedContentTopicLimit,
  updateCloudbeesFeatureFlag,
  updateProgramCustomizations,
  updateUserGeneratedContentTopicLimit,
} from '../services/api-feature-flags';
import {
  CloudbeesFeatureFlag,
  FeatureFlag,
  FeatureFlags,
  UpsertFeatureFlagParams,
} from '../models/feature-flag';

export const mapServerDataToFeatureFlags: (
  values: FeatureFlag[]
) => FeatureFlags = (values) => {
  const result = values.reduce((flags, value) => {
    return { [value.type as keyof FeatureFlags]: value, ...flags };
  }, {} as FeatureFlags);
  return camelcaseKeys(result);
};

export const useProgramCustomizationsQuery = (
  programId: number
): QueryResponse<FeatureFlags> => {
  const { isLoading, error, data } = useQuery<FeatureFlag[] | undefined, Error>(
    ['program_customization', programId],
    () => fetchProgramCustomizations(programId),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data ? mapServerDataToFeatureFlags(data) : {},
  };
};

export const useUserGeneratedContentTopicLimitQuery = (
  programId: number
): QueryResponse<FeatureFlags> => {
  const { isLoading, error, data } = useQuery<FeatureFlag | undefined, Error>(
    ['topic_limit', programId],
    () => fetchUserGeneratedContentTopicLimit(programId),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data ? mapServerDataToFeatureFlags([data]) : {},
  };
};

export const useUpdateProgramCustomizations = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  update: (featureFlag: FeatureFlag) => void;
} => {
  const queryClient = useQueryClient();
  const update = (featureFlag: FeatureFlag) => {
    updateProgramCustomizations(programId, featureFlag)
      .then(() => {
        queryClient.invalidateQueries(['program_customization', programId]);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { update };
};

export const useUpdateUserGeneratedContentTopicLimit = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  update: (topicLimit: FeatureFlag) => void;
} => {
  const queryClient = useQueryClient();
  const update = (topicLimit: FeatureFlag) => {
    updateUserGeneratedContentTopicLimit(programId, topicLimit)
      .then(() => {
        queryClient.invalidateQueries(['topic_limit', programId]);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { update };
};

export const useUpdateCloudbeesFeatureFlag = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  update: (name: string, params: UpsertFeatureFlagParams) => void;
} => {
  const queryClient = useQueryClient();
  const update = (name: string, params: UpsertFeatureFlagParams) => {
    updateCloudbeesFeatureFlag(programId, name, params)
      .then(() => {
        queryClient.invalidateQueries(['cloudbees_feature_flag', programId]);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { update };
};

export const useFeatureFlagsQuery = (
  programId: number,
  flag: string,
  user_id?: number
): QueryResponse<FeatureFlag> => {
  const { isLoading, error, data } = useQuery<FeatureFlag, Error>(
    ['feature_flag', programId, flag, user_id],
    () => fetchFeatureFlags(programId, flag, user_id),
    { retry: false, refetchOnMount: false, refetchOnWindowFocus: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useAllFeatureFlagsQuery = (
  programId: number
): QueryResponse<Array<CloudbeesFeatureFlag>> => {
  const { isLoading, error, data } = useQuery<
    Array<CloudbeesFeatureFlag>,
    Error
  >(['all_feature_flags', programId], () => fetchAllFeatureFlags(programId), {
    retry: false,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
