import * as React from 'react';
import { Container } from './Container';
import { Content } from './Content';

export const Thumbnail: React.FC<
  React.ComponentProps<typeof Container> & React.ComponentProps<typeof Content>
> = ({ iconType, iconName, title, imageUrl, color, backgroundColor, size }) => (
  <Container
    imageUrl={imageUrl}
    color={color}
    backgroundColor={backgroundColor}
    size={size}
  >
    {!imageUrl && (
      <Content iconName={iconName} iconType={iconType} title={title} />
    )}
  </Container>
);
