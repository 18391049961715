// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
type UserAgentData = { brands: [{ brand: string }] };
type ChromeNavigator = Navigator & Record<'userAgentData', UserAgentData>;
const nav = navigator as ChromeNavigator;
export const Firefox = /\bGecko\/\d/.test(nav.userAgent);
export const Safari =
  /\bAppleWebKit\/\d/.test(nav.userAgent) &&
  !nav.userAgentData?.brands?.some(({ brand }) => brand === 'Google Chrome');

export type MaybeNode = Node | null | undefined;

export const varAttr = 'data-liquid-unique-id';
export const sepClass = 'liquid-separator-dummy-class';
export const varSelector = `span[${varAttr}]`;
export const sepSelector = `span.${sepClass}`;
export const fmtSelector = 'em,s,strong,sub,sup,u,span[style*="color:"]';
export const isvar = (node: MaybeNode): boolean =>
  !!node &&
  node.nodeType === Node.ELEMENT_NODE &&
  (node as Element).hasAttribute(varAttr);
export const issep = (node: MaybeNode): boolean =>
  !!node &&
  node.nodeType === Node.ELEMENT_NODE &&
  (node as Element).className === sepClass;
// froala adds format elements that are empty or contain only 0x200a and 0x200b
export const isfmt = (node: MaybeNode): boolean =>
  !!node &&
  node.nodeType === Node.ELEMENT_NODE &&
  (node as Element).matches(fmtSelector) &&
  (!(node as Element).textContent ||
    /^[\u200a\u200b]*$/.test((node as Element).textContent as string));
