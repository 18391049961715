import cx from 'classnames';
import * as React from 'react';
import styles from './layout.module.css';

type MessageType = 'info' | 'success' | 'warning' | 'danger';
export type MessageBubbleData = {
  message: string;
  messageType?: MessageType;
};

export const MessageBubble: React.FC<MessageBubbleData> = ({
  message,
  messageType = 'info',
}) => {
  return (
    <div
      className={cx(styles.MessageBubble, {
        [styles['MessageBubble--info']]: messageType === 'info',
        [styles['MessageBubble--success']]: messageType === 'success',
        [styles['MessageBubble--warning']]: messageType === 'warning',
        [styles['MessageBubble--danger']]: messageType === 'danger',
      })}
    >
      {message}
    </div>
  );
};
