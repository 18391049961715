import { Audience } from 'models/audience';

export const removeStandardRoleAudiences = (
  audiences: Audience[]
): Audience[] => {
  return audiences.filter(
    (audience) =>
      audience.type !== 'standard' ||
      !['group_admins', 'content_creators', 'content_editors'].includes(
        audience.name
      )
  );
};
