import React from 'react';
import { Modal, Box, ConfirmModal } from 'DesignSystem/Components';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { useBulkSelect } from 'contexts/bulk-select';
import { UserBulkActionFilters } from 'hooks/common';
import { useUniqueId } from 'hooks/useUniqueId';
import * as Text from 'DesignSystem/Typography';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { useBulkUpdatePermissions } from 'hooks/roles';
import { useFlashMessage } from 'contexts/flasher';
import { LitmusRole } from 'models/role';
import { RoleSelector } from './RoleSelector';
import { useJobProgressFlashMessage } from '../Shared/progress-flasher';

import styles from './styles.module.css';

type PropsType = {
  filter: UserBulkActionFilters;
  close: () => void;
};
export const EditRoleModal: React.FC<PropsType> = ({ filter, close }) => {
  const bulkSelectData = useBulkSelect();
  const setJobProgressFlashMessage = useJobProgressFlashMessage();
  const { setFlashMessage } = useFlashMessage();
  const [role, setRole] = React.useState<Partial<LitmusRole>>();
  const [openConfirmModel, setOpenConfirmModel] = React.useState(false);
  const { updateBulk } = useBulkUpdatePermissions({
    onSuccess: ({ id }) => {
      setJobProgressFlashMessage(id, bulkSelectData);
      bulkSelectData.clearBulkSelect();
      close();
    },
    onError: ({ message }) => {
      setFlashMessage({ severity: 'error', message });
    },
  });

  const onConfirm = () => {
    updateBulk({
      filter: {
        ...filter,
        query: filter?.search,
        ids: bulkSelectData.selectedIds,
        excludedIds: bulkSelectData.excludedIds,
      },
      role: role?.name,
      bulkAction: 'add',
    });
  };

  const cancelButton = React.useRef(false);
  const containerId = useUniqueId();

  return (
    <>
      {!openConfirmModel && (
        <Modal
          name="edit-role-modal"
          width={960}
          innerPadding={0}
          onClick={(e) => {
            const contained = document
              .getElementById(containerId)
              ?.contains(e.target as HTMLElement);
            if (!contained || cancelButton.current) {
              bulkSelectData.cancel();
              close();
            }
          }}
        >
          <div id={containerId} className={styles.EditRoleModal}>
            <Box
              padding={24}
              style={{ borderBottom: '1px solid var(--color-gray10)' }}
            >
              <Flex spread>
                <Text.Heading bold block>
                  Bulk Update Role
                </Text.Heading>
                <Button
                  onClick={() => {
                    cancelButton.current = true;
                  }}
                  clearText
                  label={<Text.Heading>×</Text.Heading>}
                />
              </Flex>

              <Text.Body color={Text.color.gray60}>
                Update role for {bulkSelectData.selectedCount} users
              </Text.Body>
            </Box>
            <Box
              padding={[48, 116, 48, 116]}
              style={{ borderBottom: '1px solid var(--color-gray10)' }}
            >
              <Text.Subheading block bold>
                Select a Role
              </Text.Subheading>
              <Text.Body color={Text.color.gray60}>
                Select a role to apply to all selected users
              </Text.Body>
              <RoleSelector onChange={setRole} />
            </Box>
            <Box margin={[16, 24]}>
              <Flex end>
                <Button
                  secondary
                  label="Cancel"
                  className="cancel-button"
                  onClick={() => {
                    cancelButton.current = true;
                  }}
                />
                <Space row size={16} />
                <Button
                  label="Confirm"
                  onClick={() => {
                    setOpenConfirmModel(true);
                  }}
                  disabled={!role}
                  icon={<SVGIcon name="Check" size={16} />}
                />
              </Flex>
            </Box>
          </div>
        </Modal>
      )}
      {openConfirmModel && (
        <ConfirmModal
          title="Bulk Update Role"
          description="Existing roles for all selected users will be updated to the role below"
          confirmLabel="Confirm"
          onConfirm={onConfirm}
          confirmIcon={<SVGIcon name="Check" size={16} />}
          onCancel={() => {
            bulkSelectData.cancel();
            close();
          }}
        >
          <Box padding={8}>
            The role for{' '}
            <Text.Body bold>{bulkSelectData.selectedCount}</Text.Body> users
            will be updated to <Text.Body bold>{role?.title}</Text.Body>
          </Box>
        </ConfirmModal>
      )}
    </>
  );
};
