import * as React from 'react';
import {
  Redirect,
  RouteComponentProps,
  Router,
  useNavigate,
} from '@reach/router';
import { ContentFiltersProvider } from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { LoadingScreen } from 'shared/LoadingScreen';
import { WithPermission } from 'shared/WithPermission';
import { PublicationState } from 'models/content';
import { useAvailableContentPages } from './availableContentPages';
import { ContentListPage } from './ContentListPage/ContentListPage';

export const Content: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const availablePages = useAvailableContentPages();

  return (
    <WithPermission permissions={['campaignsAccess']}>
      <ContentFiltersProvider>
        <Router>
          {availablePages.map((page) => (
            <ContentListPage
              key={page.id}
              path={page.id}
              pageName={
                page.defaultFetchProps
                  ?.publicationState?.[0] as PublicationState
              }
              defaultFetchProps={{ programId, ...page.defaultFetchProps }}
            />
          ))}
          <Redirect from="/" to={availablePages[0].id} noThrow />
          <Redirect from="*" to="/" noThrow default />
        </Router>
      </ContentFiltersProvider>
    </WithPermission>
  );
};

export const ContentContextEscape: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!location) return;
    const url = `${location.pathname}${location.search}`.replace(
      /start-content/,
      'content'
    );
    navigate(url);
  }, [navigate, location]);
  return <LoadingScreen />;
};
