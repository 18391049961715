import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useProgram } from 'contexts/program';
import { useInclusiveRuleQuery } from 'hooks/inclusive-language/useQueries';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { InclusiveRule } from 'models/inclusive-language';
import { useInclusiveRuleValidator } from 'hooks/useInclusiveRuleValidator';
import { Form } from './components/Form';
import { useActions } from './useActions';
import styles from './inclusive-rules.module.css';

export const EditInclusiveRule: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const [inclusiveRule, setInclusiveRule] = React.useState<InclusiveRule>({
    terms: [],
    suggestions: [],
    explanation: '',
  });
  const { id: programId } = useProgram();
  const validator = useInclusiveRuleValidator();
  const { errors } = validator.validate({ rule: inclusiveRule });
  const { update } = useActions(setInclusiveRule);
  const query = useInclusiveRuleQuery(programId, id ?? 0);
  const { data, isLoading, errorMessage } = query;

  const onChange = React.useCallback(
    (updatedData: Partial<InclusiveRule>) => {
      setInclusiveRule({ ...inclusiveRule, ...updatedData });
    },
    [inclusiveRule]
  );

  const onSave = React.useCallback(() => {
    update(inclusiveRule);
  }, [inclusiveRule, update]);

  React.useEffect(() => {
    if (data) setInclusiveRule(data);
  }, [data]);

  return (
    <FormPage
      title="Edit Rule"
      description="Language guide rules are a way to provide your content creators with writing suggestions. Use rules to keep content on-brand, increase the use of inclusive language, and create more consistency in your organization’s writing."
      actions={[
        {
          label: 'Save',
          icon: <Icon name="SaveSuccess" />,
          onClick: onSave,
          disabled: errors.length > 0,
        },
      ]}
      breadcrumbs={[
        { to: '../../..', label: 'Configure' },
        { to: '../..', label: 'Language Guide' },
        { label: 'Edit Rule' },
      ]}
    >
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && (
        <Form inclusiveRule={inclusiveRule} onChange={onChange} />
      )}
    </FormPage>
  );
};
