import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { WithPermission } from 'shared/WithPermission';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useProgram } from 'contexts/program';
import { Icon } from 'shared/Icon';
import { useCustomSlugInfiniteQuery } from 'hooks/custom-slugs';
import { useDebounce } from 'hooks/useDebounce';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import {
  CustomSlugFilter,
  CustomSlugFilterValue,
} from 'services/api-custom-slugs';
import { CustomSlugsList } from './CustomSlugList';
import { FiltersBar } from './FiltersBar';

export const CustomSlugsShow: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [createdByTerm, setCreatedByTerm] = useFilterDropdownQueryString(
    'createdBy'
  );

  const parentRef = React.useRef<HTMLDivElement>(null);

  const { data, isLoading, errorMessage } = useCustomSlugInfiniteQuery({
    programId,
    filters: {
      search: useDebounce(searchTerm),
      createdBy: useDebounce(createdByTerm),
    },
  });

  const filterChangeCallbacks: Record<
    CustomSlugFilter,
    ReturnType<typeof useFilterDropdownQueryString>[1]
  > = {
    createdBy: setCreatedByTerm,
  };

  function onFilterChange(
    filterName: CustomSlugFilter,
    values: CustomSlugFilterValue
  ) {
    filterChangeCallbacks[filterName](values);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <ListPage
        title="Custom URLs"
        description="Create and manage custom URL links that campaigns can point to"
        breadcrumbs={[
          { to: '..', label: 'Configure' },
          { label: 'Custom URLs' },
        ]}
        filterbar={
          <FiltersBar
            setSearchTerm={setSearchTerm}
            onFilterChange={onFilterChange}
            filterTerms={{ searchTerm, createdByTerm }}
          />
        }
        actions={[
          {
            label: 'Custom URL',
            to: './new',
            icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
          },
        ]}
      >
        {errorMessage && <>{errorMessage}</>}
        {data && !isLoading && !errorMessage && (
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <CustomSlugsList
                height={height}
                parentRef={parentRef}
                filterConfig={{}}
                customSlugs={data}
                isLoading={isLoading}
              />
            )}
          </InfiniteContainer>
        )}
      </ListPage>
    </WithPermission>
  );
};
