import React from 'react';
import {
  FileUploadButton,
  FileUploadButtonPropsType as ButtonProps,
} from 'shared/FileUploadButton';
import cx from 'classnames';
import styles from './file-drop-zone.module.css';

type PropsType = {
  withButton?: boolean;
  dropLabel?: string;
  dropZoneClassName?: string;
  handleUpload?: (items: DataTransferItemList) => void;
  aspectRatio?: number;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  btnClassName?: string;
} & Omit<ButtonProps, 'className'>;

export const FileDropZone: React.FC<PropsType> = ({
  withButton = true,
  dropLabel = 'Drop Here',
  dropZoneClassName,
  children,
  onDragEnd,
  onDragStart,
  btnClassName,
  ...props
}) => {
  const [isDragging, setIsDragging] = React.useState(false);

  React.useEffect(() => {
    if (isDragging && onDragStart) onDragStart();
    if (!isDragging && onDragEnd) onDragEnd();
  }, [isDragging, onDragEnd, onDragStart]);

  const fileDrop = React.useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();
      const { items } = event.dataTransfer;
      setIsDragging(false);

      if (props.handleUpload) {
        props.handleUpload(items);
        return;
      }
      const file = items[0].getAsFile();
      if (file) props.onFileSelect(file);
    },
    [props]
  );

  React.useEffect(() => {
    const onDragEnter = () => setIsDragging(true);
    const onDrop = () => setIsDragging(false);
    const onDragLeave = ({ clientX, clientY }: DragEvent) => {
      const { width, height } = document.body.getBoundingClientRect();
      const outHorz = clientX < 0 || clientX > width;
      const outVert = clientY < 0 || clientY > height;
      if (outHorz || outVert) setIsDragging(false);
    };

    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('dragleave', onDragLeave);
    window.addEventListener('drop', onDrop);

    return () => {
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('dragleave', onDragLeave);
      window.removeEventListener('drop', onDrop);
    };
  }, []);

  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      {withButton ? (
        <FileUploadButton {...props} className={btnClassName}>
          {children}
        </FileUploadButton>
      ) : (
        children
      )}
      {isDragging && (
        <div
          onDragOver={(event) => event.preventDefault()}
          onDrop={fileDrop}
          className={cx(styles.dropzone, dropZoneClassName)}
        >
          {dropLabel}
        </div>
      )}
    </>
  );
};
