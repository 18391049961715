import { useEffect, useRef } from 'react';

export function useDocumentEvent<K extends keyof DocumentEventMap>(
  name: K,
  handler: (event: DocumentEventMap[K]) => void
): void {
  const ref = useRef(handler);
  ref.current = handler;
  useEffect(() => {
    document.addEventListener(name, eventHandler);
    return () => {
      document.removeEventListener(name, eventHandler);
    };
    function eventHandler(e: DocumentEventMap[K]) {
      if (ref.current) ref.current(e);
    }
  }, [name]);
}

export function useDocumentClick(
  handler: (event: React.MouseEvent | MouseEvent) => void
): void {
  useDocumentEvent('click', handler);
}
