import React from 'react';
import Select, { ValueType } from 'react-select';
import { useSwitchProgram } from 'hooks/program';
import { useProgramIdState } from '../../contexts/program';
import { useUser } from '../../contexts/user';

export const ProgramPicker: React.FC = () => {
  const user = useUser();
  const { accessiblePrograms } = user;
  const [programId, setProgramId] = useProgramIdState();
  const { switchProg: switchProgram } = useSwitchProgram(programId);

  // Find all programs in the currently-selected brand to show in the dropdown.
  const brandId = accessiblePrograms?.find((p) => p.id === programId)?.brandId;
  const programsInBrand = accessiblePrograms.filter(
    (p) => p.brandId === brandId
  );

  type OptionType = { label: string; value: number };
  const options = programsInBrand.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const currentOption = options.find((opt) => opt.value === programId);
  const handleChange = (option: ValueType<OptionType>) => {
    switchProgram((option as OptionType).value as number);
    setProgramId((option as OptionType).value);
  };

  return (
    <Select
      classNamePrefix="white"
      menuPlacement="top"
      onChange={handleChange}
      options={options}
      value={currentOption}
    />
  );
};
