import React, { useCallback, useState } from 'react';
import { useOneOf } from 'hooks/useOneOf';
import { DateTime } from 'luxon';
import { Box } from 'DesignSystem/Components/Box';
import { Button } from 'DesignSystem/Form';
import { ChevronDown, ChevronUp } from 'shared/icons';
import { Caption } from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './time.module.css';

const TIME_LIMITS = {
  mm: [0, 59],
  hh: [1, 12],
} as { [key: string]: number[] };

export const Time: React.FC<{
  onChange: (time: DateTime) => void;
  date: DateTime;
}> = ({ onChange, date }) => {
  const { am, pm } = useOneOf({
    // (0-23) https://moment.github.io/luxon/api-docs/index.html#datetimehour
    names: ['am', 'pm'],
    initial: date.hour < 12 ? 'am' : 'pm',
  });

  const withLeadingZero = (value: string): string => {
    const num = Number(value);
    return num / 10 < 1 ? `0${num}` : num.toString();
  };

  const formatHour = (hour: number): number => {
    if ((hour === 0 && am.isActive) || (hour === 12 && pm.isActive)) {
      return 12;
    }

    return hour % 12;
  };

  const [localHour, setLocalHour] = useState(
    withLeadingZero(formatHour(date.hour).toString())
  );
  const [localMinute, setLocalMinute] = useState(
    withLeadingZero(date.minute.toString())
  );

  const activateAM = useCallback(() => {
    if (!am.isActive) {
      am.activate();
      onChange(date.minus({ hours: 12 }));
    }
  }, [am, onChange, date]);

  const activatePM = useCallback(() => {
    if (!pm.isActive) {
      pm.activate();
      onChange(date.plus({ hours: 12 }));
    }
  }, [pm, onChange, date]);

  const updateTime = useCallback(
    ({
      hh = date.hour.toString(),
      mm = date.minute.toString(),
    }: {
      hh?: string;
      mm?: string;
    }) => {
      onChange(
        date.set({
          hour: (parseInt(hh, 10) % 12) + (pm.isActive ? 12 : 0),
          minute: parseInt(mm, 10),
        })
      );
    },
    [pm.isActive, onChange, date]
  );

  const formatTimeValue = (key: string, value: string | number) => {
    const [min, max] = TIME_LIMITS[key];
    return Math.max(min, Math.min(max, Number(value)));
  };

  const onChevronClick = (
    key: 'mm' | 'hh',
    value: string,
    inc: 1 | -1,
    setLocalTime: (value: React.SetStateAction<string>) => void
  ) => {
    const time = formatTimeValue(key, Number(value) + inc);
    updateTime({ [key]: time });
    setLocalTime(withLeadingZero(time.toString()));
  };

  const timeInput = (
    label: string,
    timeKey: 'mm' | 'hh',
    localTimeValue: string,
    setLocalTime: (value: React.SetStateAction<string>) => void
  ) => {
    const inputId = `input-${timeKey}`;
    return (
      <Flex column>
        <ChevronUp
          onClick={() =>
            onChevronClick(timeKey, localTimeValue, 1, setLocalTime)
          }
          className={styles.Button}
        />
        <input
          id={inputId}
          type="number"
          className={styles.Input}
          value={localTimeValue}
          onBlur={() => {
            const val = formatTimeValue(timeKey, localTimeValue).toString();
            setLocalTime(withLeadingZero(val));
          }}
          onChange={({ target: { value } }) => {
            if (value.length <= 2) {
              updateTime({ [timeKey]: formatTimeValue(timeKey, value) });
              setLocalTime(value);
            }
          }}
        />
        <label className={styles.label} htmlFor={inputId}>
          <Caption>{label}</Caption>
        </label>
        <ChevronDown
          onClick={() =>
            onChevronClick(timeKey, localTimeValue, -1, setLocalTime)
          }
          className={styles.Button}
        />
      </Flex>
    );
  };

  return (
    <Flex className={styles.wrapper}>
      {timeInput('Hours', 'hh', localHour, setLocalHour)}
      <Flex column center className={styles.dots}>
        <strong>:</strong>
      </Flex>
      {timeInput('Minutes', 'mm', localMinute, setLocalMinute)}
      <Box margin={[0, 0, 15, 20]}>
        <Box margin={[5, 0]}>
          <Button label="AM" secondary={pm.isActive} onClick={activateAM} />
        </Box>
        <Box margin={[5, 0]}>
          <Button label="PM" secondary={!pm.isActive} onClick={activatePM} />
        </Box>
      </Box>
    </Flex>
  );
};
