import React from 'react';
import { SignInConfig } from 'models/signInConfig';
import { TextInput } from 'shared/TextInput';
import { Subsection } from 'DesignSystem/Form';
import styles from '../styles.module.css';

type PropsType = {
  config: SignInConfig;
  setConfig: (config: SignInConfig) => void;
};

export const LoginPageLabelInput: React.FC<PropsType> = ({
  config,
  setConfig,
}) => {
  const [charCount, setCharCount] = React.useState(
    config.labels.sso?.length || 0
  );
  return (
    <Subsection
      title="Login Page Label"
      description="Customize the Login Page Label that appears to users."
      divider={false}
    >
      <TextInput
        value={config.labels.sso}
        className={styles.TextField}
        placeholder="SSO"
        maximum={15}
        onChange={(value) => {
          setCharCount(value.length);
          setConfig({
            ...config,
            labels: { ...config.labels, sso: value },
          });
        }}
      />
      <div className={styles.CharCounter}>{charCount}/15 characters</div>
    </Subsection>
  );
};
