import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { WithPermission } from 'shared/WithPermission';
import { ExternalSource, validateExternalSource } from 'models/external-source';
import { useFlashMessage } from 'contexts/flasher';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { useActions } from './useActions';

export const NewExternalSource: React.FC<RouteComponentProps> = () => {
  const [externalSource, setExternalSource] = React.useState<
    Partial<ExternalSource>
  >({ type: 'rss' });
  const { create } = useActions();
  const { setFlashMessage } = useFlashMessage();

  function onChange(updatedData: Partial<ExternalSource>) {
    setExternalSource({
      ...externalSource,
      ...updatedData,
    });
  }

  const header = (
    <>
      <h1 className="page-header">Feed</h1>
    </>
  );

  const main = (
    <Form
      externalSource={externalSource as ExternalSource}
      onChange={onChange}
    />
  );

  function onSave() {
    const validationError = validateExternalSource(
      externalSource as ExternalSource
    );

    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });
      return;
    }
    create(externalSource);
  }

  return (
    <WithPermission permissions={['confContentAccess', 'feedAccess']}>
      <Helmet>
        <title>Create Feed</title>
      </Helmet>
      {externalSource && (
        <MainEditor
          header={header}
          main={main}
          sidebar={<></>}
          footer={<Footer externalSource={externalSource} action={onSave} />}
        />
      )}
    </WithPermission>
  );
};
