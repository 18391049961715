import { DateTime } from 'luxon';

// For TLP v2 we need date formatting that would be consistent with patron
const msInMinute = 60000;
const msInHour = 3600000;
const msInDay = 86400000;
const msInWeek = 604800000;

export function localizeDate(date: string): string {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
}
/**
 * Returns "minutes/hours/days" or a Date if the time period is longer than a week
 * Ported from https://github.com/socialchorus/patron/blob/68016b38140d4880722074da16e8ab6247b1d74b/src/lib/date-formatter.js#L80
 * @param {string} date
 * @returns {string} Localized time passed since `date`
 */
export function timeAgoOrDate(date: string): string {
  const timestamp = DateTime.fromISO(date).toMillis();

  const difference = Date.now() - timestamp;

  if (difference < msInHour) {
    const count = Math.floor(difference / msInMinute);
    return `${count} minute${count === 1 ? '' : 's'} ago`;
  }
  if (difference < msInDay) {
    const count = Math.floor(difference / msInHour);
    return `${count} hour${count === 1 ? '' : 's'} ago`;
  }
  if (difference < msInWeek) {
    const count = Math.floor(difference / msInDay);
    return `${count} day${count === 1 ? '' : 's'} ago`;
  }
  return localizeDate(date);
}
