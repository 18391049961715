import React from 'react';
import { useProgram } from 'contexts/program';
import {
  DEFAULT_MAX_FILE_SIZE,
  ExternalVideoStatus,
  Video as VideoObject,
  VideoEditorMode,
  VideoProcessingStatus,
  VideoProcessingStep,
} from 'models/video';
import { VideoFieldData } from 'models/donkey';
import { humanFileSize } from 'utility/human';
import { useVideoSourceMenu, ExternalSourceType } from './useVideoSourceMenu';
import { FieldFormProps } from '../../../useFieldForm';

export const useVideoFile: ({
  fieldData,
  video,
  upload,
  setUrl,
  uploadProcessingStatus,
  externalVideoStatus,
}: {
  fieldData: FieldFormProps<VideoFieldData>['data'];
  video?: VideoObject;
  upload: (file: File) => void;
  setUrl: (url: string) => void;
  uploadProcessingStatus: VideoProcessingStatus;
  externalVideoStatus: ExternalVideoStatus;
}) => {
  isUploading: boolean;
  isProcessing: boolean;
  maxVideoFileSize: string;
  showUploadVideo: boolean;
  showExternalVideo: boolean;
  errorMessage?: string;
  menu: ReturnType<typeof useVideoSourceMenu>['menu'];
  fileInput: ReturnType<typeof useVideoSourceMenu>['fileInput'];
  externalInput: ReturnType<typeof useVideoSourceMenu>['externalInput'];
} = ({
  fieldData,
  video,
  upload,
  setUrl,
  uploadProcessingStatus,
  externalVideoStatus,
}) => {
  // Default value to 500MB if not set
  const { maxVideoUploadSizeInBytes = DEFAULT_MAX_FILE_SIZE } = useProgram();
  const maxVideoFileSize = humanFileSize(maxVideoUploadSizeInBytes);

  const [editorMode, setEditorMode] = React.useState<
    VideoEditorMode | undefined
  >(fieldData.video_id ? undefined : VideoEditorMode.Upload);

  React.useEffect(() => {
    if (!video) {
      return;
    }

    setEditorMode(
      video.sourceType === 'admin_created'
        ? VideoEditorMode.Upload
        : VideoEditorMode.External
    );
  }, [video]);

  const { menu, fileInput, externalInput } = useVideoSourceMenu({
    onChange: (data: File | string) => {
      if (data instanceof File) {
        setEditorMode(VideoEditorMode.Upload);
        upload(data);
      } else {
        setEditorMode(VideoEditorMode.External);
        setUrl(data);
      }
    },
    inputFileAccept: 'video/mp4,video/x-m4v,video/*',
    menuSources: [VideoEditorMode.Upload, VideoEditorMode.External],
    externalSourceType: ExternalSourceType.Video,
  });

  const isUploading = React.useMemo(
    () =>
      (editorMode === VideoEditorMode.Upload &&
        uploadProcessingStatus.step === VideoProcessingStep.Uploading) ||
      (editorMode === VideoEditorMode.External &&
        externalVideoStatus.isLoading),
    [editorMode, externalVideoStatus.isLoading, uploadProcessingStatus.step]
  );

  const isProcessing = React.useMemo(
    () =>
      editorMode === VideoEditorMode.Upload &&
      uploadProcessingStatus.step === VideoProcessingStep.Transcoding,
    [editorMode, uploadProcessingStatus.step]
  );

  const errorMessage = React.useMemo<string | undefined>(() => {
    if (editorMode === VideoEditorMode.Upload) {
      return uploadProcessingStatus.errorMessage;
    }
    if (editorMode === VideoEditorMode.External) {
      return externalVideoStatus.errorMessage;
    }
    return undefined;
  }, [
    editorMode,
    externalVideoStatus.errorMessage,
    uploadProcessingStatus.errorMessage,
  ]);

  const showUploadVideo =
    editorMode === VideoEditorMode.Upload &&
    uploadProcessingStatus.step !== VideoProcessingStep.Idle &&
    !errorMessage;
  const showExternalVideo =
    editorMode === VideoEditorMode.External &&
    !errorMessage &&
    (!!video || externalVideoStatus.isLoading);

  return {
    isUploading,
    isProcessing,
    maxVideoFileSize,
    showUploadVideo,
    showExternalVideo,
    errorMessage,
    menu,
    fileInput,
    externalInput,
  };
};
