import * as React from 'react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Button } from 'DesignSystem/Form';
import cx from 'classnames';
import { MAIcon } from 'shared/MAIcon';
import styles from './styles.module.css';
import { Box } from './Box';
import { background } from '../Typography';

export const FixedPanel: React.FC<React.ComponentProps<typeof BoxPanel>> = ({
  children,
  height,
  width,
  side,
  close,
  onClick,
}) => (
  <ErrorBoundary
    ErrorComponent={() => (
      <ErrorPanel height={height} width={width} side={side} onClick={onClick} />
    )}
  >
    <BoxPanel close={close} height={height} side={side} onClick={onClick}>
      {children}
    </BoxPanel>
  </ErrorBoundary>
);

const ErrorPanel: React.FC<React.ComponentProps<typeof BoxPanel>> = ({
  height,
  width,
  side,
  onClick,
}) => (
  <BoxPanel height={height} width={width} side={side} onClick={onClick}>
    An unexpected error prevented the contents from being shown. Please close
    this panel and try again.
  </BoxPanel>
);

const BoxPanel: React.FC<{
  side: 'left' | 'right';
  height?: string;
  width?: string;
  onClick?: (event: React.MouseEvent) => void;
  close?: () => void;
}> = ({ children, onClick, side, close, height = 'calc(100vh - 112px)' }) => {
  const borderStyle = '1px solid var(--color-gray10)';
  return (
    <Box
      absolute
      className={styles.boxPanel}
      height={height}
      right={side === 'left' ? undefined : 0}
      left={side === 'left' ? 56 : undefined}
      top="48px"
      color={background.gray00}
      onClick={onClick}
      style={{
        overflow: 'auto',
        zIndex: 'var(--z-index-publisher-block-controls)',
        borderLeft: side === 'right' ? borderStyle : undefined,
        borderRight: side === 'left' ? borderStyle : undefined,
      }}
    >
      {close && (
        <Button
          clearText
          onClick={close}
          icon={<MAIcon name="close" />}
          className={cx([
            styles.closeButton,
            { [styles.right]: side === 'left' },
            { [styles.left]: side === 'right' },
          ])}
        />
      )}
      {children}
    </Box>
  );
};
