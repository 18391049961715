import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useCreateShortcut } from 'hooks/shortcuts';
import { useFlashServerErrors } from 'utility/errors';
import { audiencesToCriterion } from 'models/publisher/criterion';
import { Audience } from 'models/audience';
import { Topic } from 'models/topic';
import { Shortcut } from 'models/shortcut';
import { GeneralCard } from './GeneralCard';
import { LinksCard } from './LinksCard';
import styles from '../shortcuts.module.css';
import { TargetCard } from './TargetCard';
import { HeaderActions } from '../HeaderActions';
import { useLinkFields } from '../utils/hooks/use-link-fields';
import { ShortcutErrorsState } from '../utils/types';
import { useGeneralShortcutsFields } from '../utils/hooks/use-general-shortcuts-fields';

export const NewShortcut: React.FC<RouteComponentProps> = () => {
  const { mutateAsync: createShortcutFn } = useCreateShortcut();
  const errorToast = useFlashServerErrors();

  const [selectedTopics, setSelectedTopics] = React.useState<Array<Topic>>([]);
  const [audiences, setAudiences] = React.useState<Array<Audience>>([]);
  const {
    name,
    description,
    iconSrc,
    headerSrc,
    shortcutEnabled,
    handleNameChange,
    handleDescriptionChange,
    handleIconSrcChange,
    handleHeaderSrcChange,
    handleShortcutEnabledToggle,
  } = useGeneralShortcutsFields();

  const {
    links,
    errors: linkErrors,
    onAddLink,
    onLinkChange,
    onLinkRemove,
  } = useLinkFields();

  const errors: ShortcutErrorsState = {
    links: linkErrors,
  };

  const saveDisabled = Object.values(errors).every((value) => {
    if (Array.isArray(value)) {
      return value.filter(Boolean).length;
    }
    return Boolean(value);
  });

  const handleSelectedTopicsChange = (value: Array<Topic>) => {
    setSelectedTopics(value);
  };

  const handleSelectedAudiencesChange = (value: Array<Audience>) => {
    setAudiences(value);
  };

  const handleSave = async () => {
    const channels = [...selectedTopics.map((topic) => String(topic.id))];
    const criterion = audiencesToCriterion(audiences);
    const formData: Partial<Shortcut> = {
      type: 'link',
      name,
      description,
      criterion,
      imageUrl: iconSrc,
      bannerImageUrl: headerSrc,
      configuration: {
        links,
        channels,
        linkToAssistant: false,
      },
      status: shortcutEnabled ? 'active' : 'inactive',
    };

    const payload = { data: { ...formData } };
    try {
      await createShortcutFn(payload);
    } catch (e) {
      if (e instanceof Error) {
        errorToast(e, e.message);
      }
    }
  };

  const linksAreEmpty = links.length === 0;

  return (
    <WithPermission permissions={['configureExperienceSettingsAccess']}>
      <FormPage
        breadcrumbs={[
          { to: '../..', label: 'Configure' },
          { to: '..', label: 'Shortcuts' },
          { label: 'Create' },
        ]}
        actionsOverride={
          <HeaderActions
            handleSave={handleSave}
            isSavingDisabled={saveDisabled}
          />
        }
        title="Create"
      >
        <div className={styles.createShortcutContainer}>
          <GeneralCard
            name={name}
            onNameChange={handleNameChange}
            description={description}
            onDescriptionChange={handleDescriptionChange}
            setIconSrc={handleIconSrcChange}
            iconSrc={iconSrc}
            setHeaderSrc={handleHeaderSrcChange}
            headerSrc={headerSrc}
            isShortcutEnabled={shortcutEnabled}
            linksAreEmpty={linksAreEmpty}
            handleShortcutEnabledToggle={handleShortcutEnabledToggle}
          />
          <LinksCard
            links={links}
            addLink={onAddLink}
            onChange={onLinkChange}
            handleRemoveLinkItem={onLinkRemove}
            errors={errors}
          />
          <TargetCard
            handleSelectedTopicsChange={handleSelectedTopicsChange}
            selectedTopics={selectedTopics}
            handleSelectedAudiencesChange={handleSelectedAudiencesChange}
            selectedAudiences={audiences}
          />
        </div>
      </FormPage>
    </WithPermission>
  );
};
