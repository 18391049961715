import { BookmarkedReportJson } from 'models/insight/json/bookmarkedReportJson';
import {
  PlaceHolderReport,
  transformJsonToPlaceholderReport,
} from 'models/insight/Report';

export type BookmarkedReport = {
  id: number;
  createdAt: Date;
  bookmarkedUserName: string;
  filterState: never;
  programId: number;
  isPublic: boolean;
  title: string;
  creatorAvatarUrl: string;
  isBookmarked: boolean;
  report: PlaceHolderReport;
};

export const transformJsonToBookmarkedReport = (
  json: BookmarkedReportJson
): BookmarkedReport => {
  return {
    id: json.id,
    createdAt: new Date(json.created_at),
    bookmarkedUserName: json.creator,
    filterState: json.filter_state,
    programId: json.program_id,
    isPublic: json.public,
    title: json.title,
    creatorAvatarUrl: json.creator_avatar_url,
    isBookmarked: json.user_bookmarked,
    report: transformJsonToPlaceholderReport(json.report),
  };
};
