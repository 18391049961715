import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box } from 'DesignSystem/Components/Box';
import { Body, PageHeading } from 'DesignSystem/Typography';
import { Alert, AlertType } from 'DesignSystem/Components';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useDeliveryValidator } from 'hooks/useDeliveryValidator';
import { useSettingsChanges } from 'hooks/useSettingsChanges';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { useAudienceList } from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/useAudienceList';
import { MAIcon } from 'shared/MAIcon';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { usePublisher } from 'contexts/publisher';
import { DirectDeliverySection } from './DirectDelivery/DirectDeliverySection';
import { TopicSection } from './Topic/TopicSection';
import { EngagementSection } from './Engagement/EngagementSection';
import { DeliveryErrors } from './DeliveryErrors';

export type DeliverProps = RouteComponentProps & {
  footer: React.ReactElement;
  sidebar: React.ReactElement;
};

export const Deliver: React.FC<DeliverProps> = ({ footer, sidebar }) => {
  const {
    topicOrAudienceChanged,
    retargettingNotifOrRetriesChanged,
  } = useSettingsChanges();
  const { isValid, errors: deliveryErrors, validate } = useDeliveryValidator();
  const {
    contentPermissions: { isLoading: isEditableLoading, canEdit, errors },
    permissions: { audienceAccess },
    update,
  } = useSettings();
  const { id: postId } = usePublisher();

  const audiencesData = useAudienceList([], '', () => {}, []);

  const usingNewPubEditors = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  useEffect(() => {
    /**
     * If user have edit permission then only this block should be execute
     */
    if (canEdit && postId === 'new') {
      // We need to call /contents api to create post even if image is not present in the template.
      // It is not getting triggered automatically if image is not present.
      update({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEdit]);

  useEffect(() => {
    if (topicOrAudienceChanged()) {
      validate();
    }
  }, [validate, topicOrAudienceChanged]);

  useEffect(() => {
    if (retargettingNotifOrRetriesChanged()) {
      validate();
    }
  }, [validate, retargettingNotifOrRetriesChanged]);

  return (
    <MainEditor
      splitBars={usingNewPubEditors}
      header={<></>}
      footer={React.cloneElement(footer, {
        validateDelivery: validate,
        disableSchedule: !canEdit,
      })}
      main={
        <Box
          maxWidth={824}
          margin={[0, 'auto']}
          className="deliver-page-main-editor"
        >
          <PageHeading>Deliver</PageHeading>
          <Box margin={[12, 0, 30, 0]}>
            <Body>
              Determine your settings for campaign publish and delivery
            </Body>
          </Box>
          {!isEditableLoading && !canEdit && (
            <Box margin={[0, 'auto', 30]}>
              <Alert
                type={AlertType.error}
                title="Editing unavailable"
                message={errors.map((error) => (
                  <div>{error}</div>
                ))}
                bgColor="red"
                enableIcon
                icon={<MAIcon name="warning" />}
              />
            </Box>
          )}
          {!isValid && <DeliveryErrors errors={deliveryErrors} />}
          <TopicSection disabled={!canEdit} />
          <DirectDeliverySection disabled={!canEdit} />
          <EngagementSection disabled={!canEdit} />
        </Box>
      }
      sidebar={
        audienceAccess && audiencesData.data.length > 0 ? sidebar : undefined
      }
    />
  );
};
