import * as React from 'react';
import { Label } from 'shared/Label';
import { Section, Row, Block } from 'shared/SectionBlocks';
import cx from 'classnames';
import styles from './timezone.module.css';

type InfoType = {
  name: string;
  count?: number;
  measures?: Array<{ name: string; value: number }>;
  selected?: boolean;
};

const Info: React.FC<InfoType> = ({
  name,
  count = 0,
  measures,
  selected = false,
}) => (
  <Block className={cx(styles.block, selected ? styles.selected : null)}>
    <Label
      containerClassName={cx(styles.label, count > 0 ? styles.active : null)}
      name={name}
    >
      {count}
    </Label>
    <div className={styles.measures}>
      {measures?.map((measure) => (
        <Label
          key={measure.name}
          containerClassName={styles.measure}
          name={measure.name}
        >
          {measure.value}
        </Label>
      ))}
    </div>
  </Block>
);

export const Timezone: React.FC<{ timezones: Array<InfoType> }> = ({
  timezones,
}) => (
  <Section title="Timezone">
    <Row>
      {timezones.map((timezone) => (
        <Info
          key={timezone.name}
          name={timezone.name}
          count={timezone.count}
          measures={timezone.measures}
          selected={timezone.selected}
        />
      ))}
    </Row>
  </Section>
);
