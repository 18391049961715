import * as React from 'react';
import { Audience } from 'models/audience';
import { Button } from 'shared/Button';
import { AudienceInfo } from 'App/Program/Editors/AudienceBuilder/AudienceInfo';
import styles from './top-controls.module.css';

type PropsType = {
  audience: Audience;
  onAudienceChange: (newAudience: Audience) => void;
  isPreviewMode?: boolean;
  setPreviewMode: (value: boolean) => void;
  isSaveAsMode?: boolean;
  setSaveAsMode: (value: boolean) => void;
};

export const TopControls: React.FC<PropsType> = (props) => {
  const {
    audience,
    onAudienceChange,
    isPreviewMode,
    setPreviewMode,
    isSaveAsMode,
    setSaveAsMode,
  } = props;

  function handleSaveAs() {
    onAudienceChange({ ...audience, type: 'custom', id: undefined });
    setSaveAsMode(true);
  }

  return (
    <div className={styles.topControls}>
      {isSaveAsMode ? (
        <AudienceInfo
          audience={audience}
          setAudience={onAudienceChange}
          isSaveAsMode
          isSaved
        />
      ) : (
        <div data-test="title" className={styles.title}>
          {audience.title}
        </div>
      )}
      <div className={styles.buttons}>
        {!isSaveAsMode && (
          <Button
            data-test="saveAs"
            type="secondary"
            iconName="SaveSuccess"
            iconType="SVG"
            iconSize={18}
            compact
            onClick={handleSaveAs}
          >
            Save for later
          </Button>
        )}
        {isPreviewMode ? (
          <Button
            type="secondary"
            iconName="Eye"
            iconType="SVG"
            iconSize={18}
            compact
            data-test="preview"
            onClick={() => setPreviewMode(false)}
          >
            Edit
          </Button>
        ) : (
          <Button
            type="secondary"
            iconName="Eye"
            iconType="SVG"
            iconSize={18}
            data-test="preview"
            compact
            onClick={() => setPreviewMode(true)}
          >
            Preview
          </Button>
        )}
      </div>
    </div>
  );
};
