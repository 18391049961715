import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Body, Caption, color } from 'DesignSystem/Typography';
import { TextInput } from 'shared/TextInput';
import { localiseNumber, cleanNumericValue } from '../helpers';
import { CampaignSettingsCard } from '../CampaignSettingsCard';

const ThresholdInput: React.FC<{
  threshold: string;
  setThreshold: (value: string) => void;
  isValid: boolean;
}> = ({ threshold, setThreshold, isValid }) => {
  const onChange = (value: string) => {
    const cleanedValue = cleanNumericValue(value);
    if (cleanedValue.length === 0) {
      setThreshold(cleanedValue);
      return;
    }

    const numberValue = Number(cleanedValue);
    if (Number.isNaN(numberValue)) return;

    setThreshold(localiseNumber(cleanedValue));
  };

  const thresholdVal = Number(threshold);

  return (
    <Box margin={[32, 0, 0, 20]}>
      <Body bold block>
        Audience Size Threshold
      </Body>
      <Caption block>
        Audience size confirmation will only trigger when campaign delivery has
        been scheduled for the specified number of users or more
      </Caption>
      <TextInput
        style={{ margin: '0 8px 13px 0' }}
        value={threshold}
        onChange={onChange}
      />
      &nbsp;Users
      {!isValid && (
        <>
          {thresholdVal === 0 && (
            <Caption color={color.redFull} block>
              Threshold must be greater than 0
            </Caption>
          )}
          {thresholdVal !== 0 && (
            <Caption color={color.redFull} block>
              Max size is 8 digits
            </Caption>
          )}
        </>
      )}
    </Box>
  );
};

export const ConfirmationModalEdit: React.FC<{
  modalEnabled: boolean;
  setModalEnabled: (val: boolean) => void;
  threshold: string;
  setThreshold: (val: string) => void;
  isValid: boolean;
}> = ({ modalEnabled, setModalEnabled, threshold, setThreshold, isValid }) => {
  return (
    <CampaignSettingsCard
      title="Audience Size Confirmation"
      on={modalEnabled}
      onToggle={(value) => {
        const toggleValue = value || false;
        setModalEnabled(toggleValue);
      }}
    >
      {modalEnabled && (
        <ThresholdInput
          threshold={threshold}
          setThreshold={setThreshold}
          isValid={isValid}
        />
      )}
    </CampaignSettingsCard>
  );
};
