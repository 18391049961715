import React from 'react';
import { DateTime } from 'luxon';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { UsersFilter } from './UsersFilter';
import { DateRangeFilter } from './DateRangeFilter';

type PropsType = {
  onFilterChange: (filterName: string, values: string[]) => void;
  users?: string[];
  dateFilter: [string, string];
};

export const FiltersBar: React.FC<PropsType> = ({
  onFilterChange,
  users,
  dateFilter,
}) => {
  return (
    <div className={styles.filtersWrapper}>
      <UsersFilter values={users} onChange={onFilterChange} />
      <DateRangeFilter
        onChange={(value) => onFilterChange('date', value)}
        maxDate={DateTime.now()}
        value={dateFilter}
      />
    </div>
  );
};
