import { BaseListDropdown } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/components/BaseListDropdown';
import { ListDropdownContext } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/ListDropdownContext';
import { PaginatedListDropdownContext } from 'App/Program/Main/Insight/components/Filters/Dropdowns/ListDropdown/contexts/PaginatedListDropdownContext';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { SelectableListItem } from 'App/Program/Main/Insight/components/Filters/shared/body/SelectableListItem';
import {
  DEFAULT_DROPDOWN_WIDTH,
  FilterDropdownStructureContext,
} from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';
import React, { CSSProperties, useEffect } from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

export const PaginatedListDropdown: React.FC = () => {
  const { infiniteListBodyHeight } = React.useContext(
    FilterDropdownStructureContext
  );
  const { filter } = React.useContext(FilterDropdownContext);
  const {
    items,
    dispatchNewlySelectedItem,
    resetPlaceholderItems,
    itemIsSelected,
    getItemHeight,
  } = React.useContext(ListDropdownContext);

  const { loadMoreItems, isLoadingMoreItems, hasMoreToLoad } = React.useContext(
    PaginatedListDropdownContext
  );
  const [loaded, setLoaded] = React.useState(false);
  const { setShowReset } = React.useContext(ReportContext);

  useEffect(() => {
    if (!isLoadingMoreItems && !loaded) {
      setLoaded(true);
      resetPlaceholderItems();
    }
  }, [isLoadingMoreItems, loaded, setLoaded, resetPlaceholderItems]);

  const renderRowOrLoadingIndicator = (
    apparentIndex: number,
    style: CSSProperties
  ): React.ReactElement => {
    if (isLoadingMoreItems && apparentIndex === items.length) {
      return <p style={style}>Loading..</p>;
    }
    if (items.length === 0) return <p style={style}>No Data</p>;

    const item = items[apparentIndex];

    // this would only happen when the page is rerendering. return empty div
    if (item === undefined) return <div style={style} />;

    return (
      <SelectableListItem
        onClick={() => {
          setShowReset(true);
          dispatchNewlySelectedItem({
            action: 'toggle',
            item,
          });
        }}
        rowType={filter.allowMultiple ? 'checkbox' : 'radio'}
        isSelected={itemIsSelected(item)}
        label={item.label}
        style={style}
        innerListItemStyle={{ height: getItemHeight(apparentIndex) }}
      />
    );
  };

  const itemsCount = items.length + (hasMoreToLoad ? 1 : 0);

  return (
    <BaseListDropdown>
      {/* We add 1 to itemsCount for a "loading" row to render */}
      <InfiniteLoader
        isItemLoaded={(i) => i < items.length}
        loadMoreItems={loadMoreItems}
        itemCount={itemsCount}
      >
        {({ onItemsRendered, ref }) => (
          <VariableSizeList
            itemSize={getItemHeight}
            height={infiniteListBodyHeight}
            itemCount={itemsCount}
            width={DEFAULT_DROPDOWN_WIDTH}
            onItemsRendered={onItemsRendered}
            ref={ref}
          >
            {({ index: apparentIndex, style }) =>
              renderRowOrLoadingIndicator(apparentIndex, style)
            }
          </VariableSizeList>
        )}
      </InfiniteLoader>
    </BaseListDropdown>
  );
};
