import * as React from 'react';
import styles from './flash_messages.module.css';

export const ExperienceLinkMessage: React.FC<{
  permalinkUrl: string;
}> = ({ permalinkUrl }) => (
  <a
    href={permalinkUrl}
    rel="noreferrer"
    target="_blank"
    className={styles.experienceLink}
  >
    View In Experience
  </a>
);

export const CampaignProcessingMessage: React.FC<{ action: string }> = ({
  action,
}) => <div>Campaign will be {action} when processing is complete</div>;
