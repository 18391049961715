import * as React from 'react';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { canEditImageCroppingFromOptionsList } from 'components/publisher/pages/Design/ImageCropping/index';
import { canEditArrangement } from '../Arrangement';
import { canEditHiddenSections } from '../FormattingFlags';
import { useStyleData } from './useStyleData';
import { useStyleForm } from './useStyleForm';
import { canEditTextAlignment } from '../TextAlignment';

export function useStyleEditor({
  id,
  block,
}: Parameters<typeof useStyleData>[0]): ReturnType<typeof useStyleData> &
  ReturnType<typeof useStyleForm> & {
    updateFormatData: React.ContextType<
      typeof BlocksEditorContext
    >['updateFormatData'];
    hasFormattingOptions: boolean;
  } {
  const { updateFormatData } = React.useContext(BlocksEditorContext);
  const hasFormattingOptions = React.useMemo(() => {
    return (
      canEditArrangement(block) ||
      canEditHiddenSections(block) ||
      canEditTextAlignment(block) ||
      canEditImageCroppingFromOptionsList(block)
    );
  }, [block]);
  return {
    ...useStyleData({ id, block }),
    ...useStyleForm(block),
    updateFormatData,
    hasFormattingOptions,
  } as const;
}
