import React from 'react';
import { User } from '../../../models/user';
import styles from './user-pill.module.css';

export const UserPill: React.FC<User> = ({
  avatarUrl,
  firstName,
  lastName,
  displayName,
}: User) => {
  const userName = displayName || `${firstName} ${lastName}`;

  return (
    <div style={{ display: 'flex' }}>
      {avatarUrl && (
        <img className={styles.UserAvatar} alt={userName} src={avatarUrl} />
      )}
      <span className={styles.UserName}>{userName}</span>
    </div>
  );
};
