import { ThumbnailType } from 'shared/BannerListItem';
import { UserExport } from 'models/user-export';
import { MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { ItemType } from 'shared/IconMenu/IconMenuItem';

export const userExportToThumbnail = (
  userExport: Pick<UserExport, 'identifier'>
): ThumbnailType => {
  return {
    title: userExport.identifier,
    imageUrl: '',
    backgroundColor: '#5d6480',
    color: '#fff',
  };
};

export const useExportToMenuItems = (): MenuItemsType => {
  const items: ItemType[] = [
    {
      title: 'Download file',
      href: '#',
    },
  ];

  return items;
};
