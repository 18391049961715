import * as React from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import styles from '../styles.module.css';

export const StyleSwitch: React.FC<{
  enable: () => void;
  disable: () => void;
  isCustom: boolean;
  disabled?: boolean;
}> = ({ enable, disable, isCustom, disabled }) => (
  <div className={styles.customToggle}>
    <label htmlFor="use-custom-styling" className={styles.toggleLabelLayout}>
      <FlipSwitch
        htmlForId="use-custom-styling"
        on={isCustom}
        onChange={isCustom ? disable : enable}
        disabled={disabled}
      />
      <span style={{ marginLeft: '10px' }}>STYLE THIS BLOCK UNIQUELY</span>
    </label>
  </div>
);
