import React from 'react';
import {
  ImageFieldData,
  fieldToImage,
  imageToField,
} from 'models/publisher/block';
import { FieldFormProps } from '../../useFieldForm';
import { ImageList } from './components/ImageList';
import { useGalleryDataMutator } from './hooks/useGalleryDataMutator';
import { Image } from '../Image';

export const Gallery: React.FC<FieldFormProps<ImageFieldData[]>> = ({
  data,
  onChange,
}) => {
  const { current, collection } = useGalleryDataMutator(data, onChange);
  return current.item.data ? (
    <Image
      hideLinkOptions
      onChange={(imageField) => current.item.update(fieldToImage(imageField))}
      default_data={imageToField(current.item.data)}
      data={imageToField(current.item.data)}
    />
  ) : (
    <ImageList
      images={collection.images}
      onClearAll={collection.clear}
      onRemove={collection.remove}
      onSetCurrent={collection.setCurrent}
      onDrop={collection.append}
      onReorder={collection.reorder}
      onCreate={collection.create}
      replaceItems={collection.replaceItems}
    />
  );
};
