import React from 'react';
import { Button } from 'DesignSystem/Form';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';

export const ResetButton: React.FC = () => {
  const { resetFilters } = React.useContext(FiltersStateContext);
  const { setShowReset } = React.useContext(ReportContext);
  return (
    <div>
      <Button
        roundedPadded
        onClick={() => {
          setShowReset(false);
          resetFilters();
        }}
        label="Reset filters"
      />
    </div>
  );
};
