import * as React from 'react';
import cx from 'classnames';

import styles from './popover.module.css';
import { Box } from '../Box';

export const Popover: React.FC<{
  padding?: number | number[];
  centered?: boolean;
  className?: string;
}> = ({ children, padding, centered = false, className }) => (
  <Box
    className={cx(styles.Popover, className, {
      [styles.PopoverCenteredShadow]: centered,
    })}
    padding={padding ?? 16}
  >
    {children}
  </Box>
);
