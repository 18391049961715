import React, { RefObject } from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { Topic } from 'models/topic';
import { PaginationData } from 'services/common';
import { TopicBulkActionFilters } from 'hooks/common';
import { TopicBanner } from './TopicBanner';
import { WithBulkSelect } from './components/WithBulkSelect';

type PropsType = {
  topics: Array<Topic>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  errorMessage?: string;
  parentRef: RefObject<HTMLDivElement>;
  meta?: PaginationData;
  filterConfig: TopicBulkActionFilters;
  height: number;
};

export const TopicsList: React.FC<PropsType> = (props) => {
  const {
    isLoading,
    topics,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    parentRef,
    meta,
    filterConfig,
    height,
  } = props;

  const itemCount = topics.length || 0;

  return (
    <WithBulkSelect
      totalRecords={meta?.totalRecords || 0}
      filterConfig={filterConfig}
    >
      {() => (
        <InfiniteBannerList
          itemCount={itemCount}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          height={height}
          parentRef={parentRef}
          noItemsComponent={<div>No Topics match that criteria.</div>}
        >
          {(index: number) => <TopicBanner topic={topics[index]} />}
        </InfiniteBannerList>
      )}
    </WithBulkSelect>
  );
};
