import { ThumbnailType, MenuItemsType } from 'shared/BannerListItem';
import { LitmusRole } from 'models/role';

export const roleToThumbnail = (role: LitmusRole): ThumbnailType => {
  return {
    title: role.name,
    imageUrl: '',
    backgroundColor: '#5d6480',
    color: '#fff',
  };
};

export const roleToMenuItems = (
  programId: number,
  role: LitmusRole,
  duplicate: (role: LitmusRole) => void
): MenuItemsType => {
  return [
    {
      title: 'View',
      href: `/${programId}/configure/roles/${role.id}/details`,
    },
    {
      title: 'Duplicate',
      href: `#`,
      onClick: () => duplicate(role),
    },
  ];
};
