import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';
import { LitmusRole, validateRole } from 'models/role';
import { useNavigate } from '@reach/router';
import { useCreateRole, useUpdateRole } from 'hooks/roles';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { usePermissionFilters } from './usePermissionFilter';

const formatRoleNameAndTitle = (
  title: string,
  normalizeRoleNames: boolean
): { name?: string; title: string } => {
  let newTitle;
  const formatName = (rawName: string): string =>
    rawName
      .toLowerCase()
      .replace(/ /g, '_')
      // eslint-disable-next-line no-useless-escape
      .replace(/[\[\]]/gi, '');
  if (!title.includes('[DUPLICATE')) {
    newTitle = `[DUPLICATE] ${title}`;
    return normalizeRoleNames
      ? { title: newTitle }
      : { name: formatName(newTitle), title: newTitle };
  }

  const [dupString, ...rest] = title.split(']');
  // eslint-disable-next-line no-useless-escape
  const [, count] = dupString.replace(/[\[\]]/gi, '').split(' ');
  const dupCount = count ? parseInt(count, 10) + 1 : 2;

  newTitle = `[DUPLICATE ${dupCount}] ${rest.join(' ')}`;
  return normalizeRoleNames
    ? { title: newTitle }
    : {
        name: formatName(newTitle),
        title: newTitle,
      };
};

export const useActions = (): {
  create: (role: Partial<LitmusRole>) => void;
  duplicate: (role: LitmusRole) => void;
  update: (role: LitmusRole) => void;
} => {
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const normalizeRoleNames =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.NormalizeRoleNames')
      .data?.value as boolean) || false;

  const { filterAliases } = usePermissionFilters(programId);

  const { create } = useCreateRole({
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Role created successfully',
      });
    },
    onError: (error) => {
      flashServerErrors(error, error.message);
    },
  });

  const { update } = useUpdateRole({
    onSuccess: (role) => {
      setFlashMessage({
        severity: 'info',
        message: 'Role updated successfully',
      });
      navigate(`/${programId}/configure/roles/${role.id}/details`);
    },
    onError: (error) => {
      flashServerErrors(error, error.message);
    },
  });

  const duplicate = (role: LitmusRole) => {
    if (!role) return;

    // certain aliases should not be duplicated if the feature flag is not enabled
    const filteredRole: LitmusRole = {
      ...role,
      aliases: filterAliases(role.aliases),
    };

    const validationError = validateRole(filteredRole);
    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });

      return;
    }

    const role_to_create: Partial<LitmusRole> = {
      ...filteredRole,
      ...formatRoleNameAndTitle(filteredRole.title, normalizeRoleNames),
    };

    if (normalizeRoleNames) {
      delete role_to_create.name;
    }

    create(role_to_create);
  };

  return { create, duplicate, update };
};
