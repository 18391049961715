import * as React from 'react';
import cx from 'classnames';
import { TextInput } from 'shared/TextInput';
import { Button } from 'shared/Button';
import styles from '../../form.module.css';

export const External: React.FC<{
  onChange: (url: string) => void;
  className?: string;
}> = ({ onChange, className }) => {
  const [url, setUrl] = React.useState('');
  const apply = React.useCallback(() => {
    if (url) onChange(url);
    setUrl('');
  }, [url, setUrl, onChange]);
  return (
    <div
      data-test="external-image"
      className={cx(styles.externalInline, className)}
    >
      <TextInput
        className={cx('flex-fill', styles.squaredRight)}
        value={url}
        placeholder="Required"
        onChange={setUrl}
      />
      <Button onClick={apply}>Add Image</Button>
    </div>
  );
};
