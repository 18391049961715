import React from 'react';
import { AttachmentData } from 'models/attachment';
import { useBasicValidator, ValidatorsType } from './useBasicValidator';
import {
  useContentAttachmentUpload,
  useDesignAttachmentUpload,
} from './attachment';

const rules = {
  ALLOWED_EXTENSIONS: [
    'pdf',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'ics',
  ],
};

const ERRORS = {
  EXTENSION: `File has invalid extension. Use one of the following: ${rules.ALLOWED_EXTENSIONS}`,
  CANNOT_UPLOAD: 'Cannot upload file. Service is not available',
};

const validators: ValidatorsType<File> = {
  extension: (file) => {
    const parts = file.name.split('.');
    const extension = parts[parts.length - 1]?.toLowerCase();
    return !rules.ALLOWED_EXTENSIONS.includes(extension);
  },
};

type PropsType = {
  programId: number;
  onUpload: (attachment: AttachmentData) => void;
  isDesignAsset?: boolean;
};

export type Uploader = (
  props: PropsType
) => {
  allowedExtensions: string[];
  isUploading: boolean;
  update: (file: File) => Promise<void>;
  error: string | undefined;
  file: File | undefined;
};

export const useAttachmentUploader: Uploader = ({
  programId,
  onUpload,
  isDesignAsset,
}) => {
  const validator = useBasicValidator<File>(validators, ERRORS);
  const [error, setError] = React.useState<string>();
  const [file, setFile] = React.useState<File>();

  const onSuccess = (data: AttachmentData) => {
    onUpload(data);
    setError(undefined);
  };
  const contentAttachmentUpload = useContentAttachmentUpload({
    onSuccess,
  });
  const designAttachmentUpload = useDesignAttachmentUpload({
    onSuccess,
  });
  const { mutate, errorMessage, isSaving: isUploading } = isDesignAsset
    ? designAttachmentUpload
    : contentAttachmentUpload;

  React.useEffect(() => {
    if (errorMessage && error !== errorMessage) setError(errorMessage);
  }, [error, errorMessage]);

  const update = React.useCallback(
    async (upload: File) => {
      setFile(upload);
      const { isValid, errors: messages } = validator.validate(upload);

      if (!isValid) {
        setError(messages.join());
        return;
      }

      mutate({ file: upload, programId });
    },
    [mutate, programId, validator]
  );

  return {
    allowedExtensions: rules.ALLOWED_EXTENSIONS,
    isUploading,
    error,
    update,
    file,
  };
};
