import * as React from 'react';
import { Keys, useKeyboardEventHandler } from 'hooks/useKeyboardTrigger';
import styles from './click-dropdown.module.css';

export type DismissType = () => void;
export type DropdownRenderPropType =
  | React.ReactNode
  | ((dismiss: DismissType) => React.ReactNode);

// This component is meant to be "subclassed", so to speak.  There are concrete
// derived components that wrap this one and supply specialized dropdown contents.
// In all those cases, it needs to expose these props and pass them through to this
// parent so the common hover behavior can be provided.
export type PassThroughPropsType = {
  dropdownClassName?: string;
  id?: string;
  disabled?: boolean;
  isOpen?: boolean;
  onOpen?: (id?: string, toggle?: Element | null) => void;
  onClose?: (id?: string) => void;
  onDropdownClick?: () => void;
  portalRef?: React.RefObject<HTMLDivElement>;
  upward?: boolean;
  left?: React.CSSProperties['left'];
  cursorType?: 'pointer' | 'default';
};

type PropsType = {
  dropdownRenderProp: DropdownRenderPropType;
} & PassThroughPropsType;

export type ClickDropdownHandle = {
  correctDropdownOverflow: () => void;
};

export const ClickDropdown = React.forwardRef<
  ClickDropdownHandle,
  React.PropsWithChildren<PropsType>
>((props, ref) => {
  const {
    dropdownClassName,
    dropdownRenderProp,
    id,
    isOpen,
    disabled,
    onOpen,
    onClose,
    onDropdownClick,
    children,
    portalRef,
    upward = false,
    left,
    cursorType = 'pointer',
  } = props;

  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const toggleRef = React.useRef<HTMLDivElement>(null);

  const [currentDropdownOpen, setDropdownOpen] = React.useState(isOpen);
  const [previousIsOpenProp, setPreviousIsOpenProp] = React.useState(isOpen);

  // The dropdownStyle is applied as an inline-style and has a higher specificity
  // than css styling. If a dropdownClassName is provided without a left value,
  // this will not null-coalesce the value to 0.
  const [dropdownStyle, setDropdownStyle] = React.useState<React.CSSProperties>(
    {
      margin: 0,
      left: dropdownClassName ? left : left ?? 0,
    }
  );

  // The previous version had no callbacks or effects really.
  // It would call the setters during render, and the tests expected
  // an immediate rendering, and moving the snippet of code into
  // an effect caused the tests to fails. This keeps the current
  // behavior by somewhat duplicating the next state, but also
  // keeps the performance improvements with callbacks and effects.
  const isDropdownOpen = React.useMemo(() => {
    if (previousIsOpenProp === isOpen) return currentDropdownOpen;
    return isOpen;
  }, [isOpen, currentDropdownOpen, previousIsOpenProp]);

  React.useEffect(() => {
    if (isOpen !== previousIsOpenProp) {
      setPreviousIsOpenProp(isOpen);
      setDropdownOpen(isOpen);
    }
  }, [isOpen, previousIsOpenProp, isDropdownOpen]);

  React.useEffect(() => {
    if (toggleRef.current && upward) {
      setDropdownStyle({
        ...dropdownStyle,
        bottom: 0,
        marginBottom: toggleRef.current.clientHeight + 10,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDropdown = React.useCallback(() => {
    if (disabled) {
      return;
    }
    if (!isDropdownOpen && onOpen) {
      onOpen(id, toggleRef.current);
    }
    setDropdownOpen(true);
  }, [id, disabled, isDropdownOpen, onOpen]);

  const hideDropdown = React.useCallback(() => {
    if (isDropdownOpen && onClose) {
      onClose(id);
    }
    setDropdownOpen(false);
  }, [id, isDropdownOpen, onClose]);

  const catchOuterClick = React.useCallback(
    (event: MouseEvent) => {
      const { target } = (event as unknown) as { target: Node | null };
      if (portalRef) {
        if (
          isDropdownOpen &&
          portalRef.current &&
          !portalRef.current.contains(target)
        ) {
          hideDropdown();
        }
      } else if (
        isDropdownOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(target)
      ) {
        hideDropdown();
      }
    },
    [isDropdownOpen, hideDropdown, portalRef]
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', catchOuterClick);
    return () => {
      document.removeEventListener('mousedown', catchOuterClick);
    };
  }, [catchOuterClick]);

  const toggleDropdown = React.useCallback(() => {
    if (isDropdownOpen) {
      hideDropdown();
    } else {
      showDropdown();
    }
  }, [isDropdownOpen, hideDropdown, showDropdown]);

  const handleDropdownClick = React.useCallback(() => {
    if (onDropdownClick) {
      onDropdownClick();
    }
  }, [onDropdownClick]);

  const keyTriggeredShowDropdown = React.useCallback(() => {
    if (!isDropdownOpen) {
      showDropdown();
    }
  }, [isDropdownOpen, showDropdown]);

  const keyTriggeredHideDropdown = React.useCallback(() => {
    if (isDropdownOpen) {
      hideDropdown();
    }
  }, [isDropdownOpen, hideDropdown]);

  const { onKeyDown, onKeyUp } = useKeyboardEventHandler({
    onIncludedKey: keyTriggeredShowDropdown,
    keys: [Keys.Enter],
    onAnyOtherKey: keyTriggeredHideDropdown,
  });

  const correctDropdownOverflow = React.useCallback(() => {
    if (!dropdownRef.current || !wrapperRef.current) return;

    const padding = 32;
    const requestedBox = dropdownRef.current.getBoundingClientRect();
    const requestedRight = requestedBox.width + requestedBox.left;

    let current = wrapperRef.current.parentElement;
    while (current && current !== document.body) {
      if (getComputedStyle(current).overflow.indexOf('auto') >= 0) break;
      current = current.parentElement;
    }
    if (current) {
      const maxBox = current.getBoundingClientRect();
      const maxRight = maxBox.width + maxBox.left - padding;
      if (requestedRight > maxRight) {
        const currentMarginLeft = parseFloat(
          dropdownRef.current.style.marginLeft
        );
        dropdownRef.current.style.marginLeft = `${
          currentMarginLeft + maxRight - requestedRight
        }px`;
      }
    }
  }, []);

  React.useImperativeHandle(ref, () => ({ correctDropdownOverflow }));

  React.useEffect(() => {
    if (isDropdownOpen) setImmediate(() => correctDropdownOverflow());
  }, [correctDropdownOverflow, isDropdownOpen]);

  return (
    <div
      style={{
        position: 'relative',
        cursor: cursorType,
      }}
      ref={wrapperRef}
      id={id}
    >
      <div
        dir="auto"
        role="button"
        tabIndex={0}
        className="click-dropdown-target"
        ref={toggleRef}
        onClick={toggleDropdown}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      >
        {children}
      </div>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          onClick={handleDropdownClick}
          style={dropdownStyle}
          className={`${styles.dropdown} ${
            dropdownClassName ?? 'dropdown-align-left'
          }`}
        >
          {/* eslint-enable jsx-a11y/click-events-have-key-events */}
          {/* eslint-enable jsx-a11y/no-static-element-interactions */}
          {typeof dropdownRenderProp === 'function'
            ? dropdownRenderProp(hideDropdown)
            : dropdownRenderProp}
        </div>
      )}
    </div>
  );
});

/* eslint-disable react/jsx-props-no-spreading */
export const useClickDropdown = (): {
  isOpen: boolean;
  ClickDropdown: React.FC<PropsType>;
} => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdown: React.FC<PropsType> = React.useCallback(
    ({ children, ...props }) => (
      <ClickDropdown
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        {...props}
      >
        {children}
      </ClickDropdown>
    ),
    [isOpen, setIsOpen]
  );
  return {
    isOpen,
    ClickDropdown: dropdown,
  };
};
