import React from 'react';
import { NodeProps } from 'reactflow';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { useJourneyState } from 'contexts/journeys/journey';
import { useJourneyStartMetrics } from 'hooks/journeys/journey-metrics';

import { MetricsMode } from 'models/journeys/journey';
import { StartIcon } from '../icons';
import styles from './node.module.css';
import { BaseNode, NodeContent } from './BaseNode';
import { Metrics } from './Metrics';
import { Metric } from './Metrics/Metric';

export const StartNode = React.memo((props: NodeProps) => {
  const { id } = props;

  const { journey, currentGraph, metricsMode } = useJourneyState();

  return (
    <BaseNode
      key={id}
      activateOnClick
      hideMoveLeft
      hideMoveRight
      disableDelete
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <NodeContent>
        <Flex column start className={styles.nodeContainer}>
          <Flex start className={styles.bodyContainer}>
            <Box className={styles.icon}>
              <StartIcon />
            </Box>
            <Flex className={styles.messageBody} column start>
              <div>Start Journey</div>
              <div>Audience configuration and settings</div>
            </Flex>
          </Flex>
          {journey?.id && currentGraph?.id && currentGraph?.isLive && (
            <StartMetrics
              journeyId={journey.id}
              graphId={currentGraph.id}
              mode={metricsMode}
            />
          )}
        </Flex>
      </NodeContent>
    </BaseNode>
  );
});

const StartMetrics: React.FC<{
  journeyId: number;
  graphId: number;
  mode: MetricsMode;
}> = ({ journeyId, graphId, mode }) => {
  const { data, isLoading } = useJourneyStartMetrics(journeyId, graphId);
  return (
    <>
      {mode === MetricsMode.members && (
        <Metrics isLoading={isLoading}>
          {data && (
            <>
              <Metric label="Started" value={data.entered} />
              <Metric label="In Progress" value={data.current} />
            </>
          )}
        </Metrics>
      )}
    </>
  );
};
