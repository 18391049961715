import React, { useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { useReorderTopicShortcuts } from 'hooks/topics';
import { TopicShortcut } from 'models/topic';
import { DEFAULT_SHORTCUT_ICON } from '../../../common/constants';
import Sidebar from '../../../common/Sidebar';
import { useTopicFormCtx } from '../../../../context';
import { SidebarComponent } from '../type';
import {
  ItemAddButton,
  SortableItem,
  SortableList,
} from '../../components/SortableList';
import { ModalType } from '../../../../context/modals';

export const ShortcutsSidebar: SidebarComponent = ({ onClose }) => {
  const { state, topic, handleChange, toggleModal } = useTopicFormCtx();

  const { id: programId } = useProgram();
  const { reorder } = useReorderTopicShortcuts(programId, topic.id);

  const onSort = (shortcuts: TopicShortcut[]) => {
    handleChange('shortcuts', shortcuts);
    reorder(shortcuts);
  };

  const items = useMemo(() => {
    return state.fields.shortcuts.map((shortcut) => ({
      id: shortcut.id,
      imgSrc: shortcut.iconUrl || DEFAULT_SHORTCUT_ICON,
      name: shortcut.name,
      description: shortcut.description,
    }));
  }, [state.fields.shortcuts]);

  // a bit slow, especially when mapped over, but given that the number of shortcuts will easily be under 100, it's going to be fine
  const asShortcut = (item: SortableItem): TopicShortcut =>
    state.fields.shortcuts.find(
      (shortcut) => shortcut.id === item.id
    ) as TopicShortcut;

  return (
    <Sidebar
      title="Shortcuts"
      onClose={onClose}
      onBeforeClose={() => !state.modal.type}
      position="right"
      closeButton
    >
      <SortableList
        items={items}
        onEdit={(item) =>
          toggleModal({
            type: ModalType.EditShortcut,
            shortcut: asShortcut(item),
          })
        }
        onDelete={(item) =>
          toggleModal({
            type: ModalType.DeleteShortcut,
            shortcut: asShortcut(item),
          })
        }
        onSort={(newOrder) => onSort(newOrder.map(asShortcut))}
      />
      <ItemAddButton
        label="Shortcuts"
        onClick={() => toggleModal({ type: ModalType.EditShortcut })}
      />
    </Sidebar>
  );
};
