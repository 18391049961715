import * as React from 'react';
import cx from 'classnames';
import { useDropdownTopOffset } from './useDropdownTopOffset';

const DEFAULT_CLOSE_DELAY = 500;

export type DismissType = () => void;
export type DropdownRenderPropType = (dismiss: DismissType) => React.ReactNode;

// This component is meant to be "subclassed", so to speak.  There are concrete
// derived components that wrap this one and supply specialized dropdown contents.
// In all those cases, it needs to expose these props and pass them through to this
// parent so the common hover behavior can be provided.
export type PassThroughPropsType = {
  dropdownClassName?: string;
  openDelay?: number | 'click';
  closeDelay?: number;
  disabled?: boolean;
  id?: string;
  isOpen?: boolean;
  onOpen?: (id?: string, toggle?: Element | null) => void;
  onClose?: (id?: string) => void;
  onDropdownClick?: () => void;
  profileName?: string;
  ariaLabel?: string;
};

type PropTypes = {
  dropdownRenderProp: DropdownRenderPropType;
} & PassThroughPropsType;

export const HoverDropdown: React.FC<PropTypes> = (props) => {
  const {
    dropdownClassName = 'dropdown-align-left',
    openDelay = 0,
    closeDelay = DEFAULT_CLOSE_DELAY,
    disabled = false,
    dropdownRenderProp,
    id,
    isOpen,
    onOpen,
    onClose,
    onDropdownClick,
    children,
    ariaLabel,
  } = props;

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const toggleRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setDropdownOpen] = React.useState(isOpen);
  const [previousIsOpenProp, setPreviousIsOpenProp] = React.useState(isOpen);

  const dropdownTopOffset = useDropdownTopOffset({
    isDropdownOpen,
    toggleRef,
    dropdownRef,
  });

  const showTimeout = React.useRef<ReturnType<typeof window.setTimeout>>();
  const hidingTimeout = React.useRef<ReturnType<typeof window.setTimeout>>();

  const show = React.useCallback(() => {
    if (!isDropdownOpen && onOpen) {
      onOpen(id, toggleRef.current);
    }

    setDropdownOpen(true);
  }, [id, isDropdownOpen, onOpen, toggleRef]);

  const showDropdown = React.useCallback(() => {
    if (disabled) return;
    if (hidingTimeout.current) clearTimeout(hidingTimeout.current);

    showTimeout.current = setTimeout(
      () => {
        show();
      },
      openDelay === 'click' ? 0 : openDelay
    );
  }, [disabled, openDelay, show]);

  const dismiss = React.useCallback(() => {
    if (isDropdownOpen && onClose) {
      onClose(id);
    }

    setDropdownOpen(false);
  }, [id, isDropdownOpen, onClose]);

  const hideDropdown = React.useCallback(() => {
    if (showTimeout.current) clearTimeout(showTimeout.current);

    hidingTimeout.current = setTimeout(() => {
      dismiss();
    }, closeDelay);
  }, [closeDelay, dismiss]);

  const handleDropdownClick = React.useCallback(() => {
    if (onDropdownClick) onDropdownClick();
  }, [onDropdownClick]);

  const keepOpen = React.useCallback(() => {
    if (hidingTimeout.current) clearTimeout(hidingTimeout.current);
  }, []);

  React.useEffect(() => {
    if (isOpen !== previousIsOpenProp) {
      setPreviousIsOpenProp(isOpen);
      setDropdownOpen(isOpen);
    }
  }, [isOpen, previousIsOpenProp]);

  React.useEffect(() => {
    if (isDropdownOpen && dropdownRef.current && wrapperRef.current) {
      const padding = 32;
      const requestedBox = dropdownRef.current.getBoundingClientRect();
      const requestedRight = requestedBox.width + requestedBox.left;

      let current = wrapperRef.current.parentElement;
      while (current && current !== document.body) {
        if (getComputedStyle(current).overflow.indexOf('auto') >= 0) break;
        current = current.parentElement;
      }
      if (current) {
        const maxBox = current.getBoundingClientRect();
        const maxRight = maxBox.width + maxBox.left - padding;
        if (requestedRight > maxRight) {
          dropdownRef.current.style.marginLeft = `${
            maxRight - requestedRight
          }px`;
        }
      }
    }
  }, [isDropdownOpen, dropdownRef, wrapperRef]);

  const requireClickToOpen = openDelay === 'click';
  return (
    <div
      ref={wrapperRef}
      style={isDropdownOpen ? { position: 'relative' } : {}}
      id={id}
    >
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <div
        className={cx('hover-dropdown-target', { '-disabled': disabled })}
        onMouseEnter={requireClickToOpen ? keepOpen : showDropdown}
        onClick={requireClickToOpen ? showDropdown : undefined}
        onMouseLeave={hideDropdown}
        role="button"
        data-testid="hover-dropdown-target"
        aria-label={ariaLabel}
        tabIndex={0}
      >
        <div ref={toggleRef}>{children}</div>
        {isDropdownOpen && (
          /* TODO: another branch already addresses these issues
                   by removing the click handler. It is not needed */
          <div
            ref={dropdownRef}
            onClick={handleDropdownClick}
            style={{
              position: 'absolute',
              margin: 0,
              zIndex: 1000,
              marginTop: dropdownTopOffset,
            }}
            className={dropdownClassName}
          >
            {dropdownRenderProp(dismiss)}
          </div>
        )}
      </div>
    </div>
  );
};
