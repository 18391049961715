import * as React from 'react';
import { Dots } from 'shared/icons';

export const DragHandle: React.FC<{ size?: number }> = ({ size = 18 }) => (
  <div style={{ display: 'flex' }}>
    <StippleColumn size={size} />
    <StippleColumn size={size} />
  </div>
);

const StippleColumn: React.FC<{ size: number }> = ({ size }) => (
  <div
    style={{
      width: `${size / 3}px`,
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: `${size}px`,
    }}
  >
    <Dots style={{ marginLeft: `-${size / 3}px`, width: `${size}px` }} />
  </div>
);
