import { FieldLegend } from 'components/publisher/blocks/forms/fields/shared/FieldLegend';
import { Box } from 'DesignSystem/Components';
import React from 'react';
import cx from 'classnames';
import { FieldInput } from 'DesignSystem/Form';
import { Caption, Subheading } from 'DesignSystem/Typography';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { Post } from 'models/publisher/post';
import { AIControls } from 'components/publisher/pages/Card/AIControls';
import { useAIContentCover } from 'hooks/content-cover-generation';
import { SupportedCommands } from 'services/api-ai-chat-completion';
import { PersonalizationInput } from './PersonalizationInput';
import styles from '../DirectDelivery/DirectDeliverySection/DirectDeliverySection.module.css';
import { MAIcon } from '../../../../../../shared/MAIcon';

export const NotificationField: React.FC<{
  field: string;
  fieldName: string;
  placeholder?: string;
  inputPrefixText?: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  maxSize?: number;
  post?: Post;
  showAIGenerationButton?: boolean;
  aiCommandType?: SupportedCommands;
  context?: { [key: string]: string };
}> = ({
  field,
  onChange,
  fieldName,
  placeholder,
  inputPrefixText,
  disabled = false,
  maxSize = MAX_NOTIFICATION_LENGTH,
  post,
  showAIGenerationButton,
  aiCommandType = 'summarize',
  context = {},
}) => {
  const onGenerationCompleted = React.useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  const {
    status,
    generate,
    error: completionError,
    eligibleBlocks,
  } = useAIContentCover({
    onCompleted: onGenerationCompleted,
    command: aiCommandType,
    blocks: post?.blocks || [],
    context,
    post,
  });

  const [previousTitle, setPreviousTitle] = React.useState<string | null>(null);

  const backgroundHover = true;

  const generateText = React.useCallback(() => {
    if (field && field.trim() !== '') {
      setPreviousTitle(field);
    }

    generate();
  }, [generate, field]);

  const undo = React.useCallback(() => {
    if (previousTitle && previousTitle.trim() !== '') {
      onChange(previousTitle);
    }

    // Reset previous title
    setPreviousTitle(null);
  }, [onChange, previousTitle]);

  return (
    <>
      <FieldInput
        fieldSetClass={styles.FieldSet}
        legend={
          <FieldLegend block>
            <Subheading bold>{fieldName}</Subheading>
          </FieldLegend>
        }
      >
        <div
          style={{
            position: 'relative',
            marginTop: '5px',
            display: 'flex',
            marginBottom: '28px',
          }}
        >
          <PersonalizationInput
            inputPrefixText={inputPrefixText}
            maxSize={maxSize}
            value={field}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
          />
          {showAIGenerationButton && eligibleBlocks.length > 0 && (
            <Box absolute left={0} bottom={-35}>
              <Caption>
                <AIControls
                  status={status}
                  trigger={generateText}
                  cancel={undo}
                  canUndo={!!previousTitle}
                  sparkleFillColor="#F74857"
                  backgroundHover={backgroundHover}
                />
              </Caption>
            </Box>
          )}
          <Box absolute right={0} bottom={-25}>
            <Caption>
              {field.length} / {maxSize} characters
            </Caption>
          </Box>
          {completionError && (
            <Box absolute left={0} bottom={-40}>
              <div
                className={cx(styles.ctaErrorMessage, {
                  [styles.ctaErrorMessageDisabled]: disabled,
                })}
              >
                <MAIcon name="warning" className={styles.warningSign} />
                An unexpected error occurred. Please try again.
              </div>
            </Box>
          )}
        </div>
      </FieldInput>
    </>
  );
};
