import { CustomSlug } from 'models/custom-slug';
import React from 'react';
import {
  EmptyBanner,
  EmptyBannerDeleteItem,
  MenuItemsType,
} from 'shared/BannerListItem/EmptyBanner';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { useToggle } from 'hooks/useToggle';
import { ConfirmModal } from 'DesignSystem/Components';
import { useDeleteCustomSlug } from 'hooks/custom-slugs';
import { useUserQuery } from 'hooks/user';
import styles from './custom-slugs.module.css';
import { useActions } from './useActions';

type PropsType = {
  customSlug: CustomSlug;
};

export const CustomSlugsBanner: React.FC<PropsType> = ({ customSlug }) => {
  const { id: programId } = useProgram();
  const {
    toggle: toggleDeleteModal,
    value: showingDeleteModal,
    disable: closeDeleteModal,
  } = useToggle();
  const { duplicate } = useActions();

  const { mutate: destroy } = useDeleteCustomSlug({
    onSuccess: closeDeleteModal,
  });

  const customSlugId = customSlug.id;
  const { data: userData } = useUserQuery(
    programId,
    Number(customSlug.createdBy)
  );

  const menuItems: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${programId}/configure/custom-urls/${customSlugId}/edit`,
    },
    {
      title: 'Duplicate',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();

        duplicate(customSlugId);
      },
    },
    {
      title: 'Delete',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleDeleteModal();
      },
      danger: true,
    },
  ];

  const formatDate = (d: string | undefined) => {
    return d ? DateTime.fromJSDate(new Date(d)) : undefined;
  };

  const createdAt = formatDate(customSlug.createdAt)?.toFormat('MMM dd, yyyy');

  const deleteItem: EmptyBannerDeleteItem = {
    deleteAction: () => destroy(customSlug.id),
    resource: 'Custom URL',
    showingDeleteModal,
    toggleDeleteModal,
  };

  return (
    <EmptyBanner
      menuItems={menuItems}
      deleteItem={deleteItem}
      deleteItemModal={
        <DeleteCustomSlugModal
          deleteSlug={deleteItem.deleteAction}
          toggle={toggleDeleteModal}
        />
      }
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{customSlug.name}</div>
          <p className={styles.description}>{customSlug.slug}</p>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>Pointing to</span>
          <span className={styles.stats}>{customSlug.content?.title}</span>
        </div>
        <div className={styles.statCol}>
          <span className={styles.statHeader}>Created by</span>
          <span className={styles.stats}>{userData?.name}</span>
        </div>
        <div className={styles.lastCol}>
          <span className={styles.statHeader}>Date Created</span>
          <span className={styles.stats}>{createdAt}</span>
        </div>
      </div>
    </EmptyBanner>
  );
};

function DeleteCustomSlugModal({
  deleteSlug,
  toggle,
}: {
  deleteSlug: () => void;
  toggle: () => void;
}) {
  return (
    <ConfirmModal
      title="Delete Custom URL"
      description="Are you sure you want to delete this custom URL? This will remove any routing to a linked campaign."
      confirmLabel="Delete"
      onConfirm={deleteSlug}
      onCancel={() => {
        toggle();
      }}
    />
  );
}
