import * as React from 'react';
import {
  ImageFieldData,
  imageToField,
  fieldToImage,
} from 'models/publisher/block';
import { ImageData } from 'models/image';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { Form } from './Editor/Form';
import { FieldProps } from '../FieldProps';

export const SingleImage: React.FC<
  FieldProps<ImageFieldData> & { apply?: () => void }
> = ({ data, default_data, onChange, enableSave, disableSave }) => {
  const renderingVars = useFieldVariables();
  const images = React.useMemo(() => {
    const incoming = fieldToImage(data, renderingVars);
    if (incoming.url) return [incoming];
    return [fieldToImage(default_data)];
  }, [data, default_data, renderingVars]);

  const handleChange = React.useCallback(
    (updated?: ImageData) => {
      if (updated)
        onChange({ ...imageToField(updated), is_placeholder: false });
      else onChange(default_data);
    },
    [onChange, default_data]
  );

  return (
    <Form
      enableSave={enableSave}
      disableSave={disableSave}
      image={images[0]}
      onUpdate={handleChange}
    />
  );
};
