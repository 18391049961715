import { useDesign } from 'contexts/design';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useCaptionsJobsQuery, useCaptionsLanguagesQuery } from 'hooks/video';
import { VideoFieldData } from 'models/donkey';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import {
  TranscribeProps,
  transcribeVideo,
  TranscriptionJob,
  TranslateProps,
  translateVideo,
  TranslationJob,
} from 'services/api-captions';

type CaptionJobsContextProps = {
  isCaptionJobsLoading: boolean;
  isLanguagesLoading: boolean;
  setTranscriptionJob: (job: TranscriptionJob) => void;
  setTranslationJob: (job: TranslationJob) => void;
  transcriptionJob?: TranscriptionJob;
  translationJob?: TranslationJob;
  isLoadingFeatureFlags: boolean;
  isCaptionTranscriptionEnabled: boolean;
  isCaptionTranslationEnabled: boolean;
  availableLanguages: { [languageCode: string]: string };
};

const CaptionJobsContext = createContext<CaptionJobsContextProps>({
  isCaptionJobsLoading: false,
  isLanguagesLoading: false,
  setTranscriptionJob: () => {},
  setTranslationJob: () => {},
  transcriptionJob: undefined,
  translationJob: undefined,
  isCaptionTranscriptionEnabled: false,
  isCaptionTranslationEnabled: false,
  isLoadingFeatureFlags: false,
  availableLanguages: {},
});

export const CaptionJobsProvider: React.FC<{ fieldData: VideoFieldData }> = ({
  fieldData,
  children,
}) => {
  const { id: programId } = useProgram();
  const [transcriptionJob, setTranscriptionJob] = useState<
    TranscriptionJob | undefined
  >();

  const [translationJob, setTranslationJob] = useState<
    TranslationJob | undefined
  >();

  const {
    data: captionTranscriptionFeatureFlag,
    isLoading: isLoadingCaptionTranscriptionFeatureFlag,
  } = useFeatureFlagsQuery(programId, 'Studio.Publish.CaptionTranscription');

  const {
    data: captionTranslationFeatureFlag,
    isLoading: isLoadingCaptionTranslationFeatureFlag,
  } = useFeatureFlagsQuery(programId, 'Studio.Publish.CaptionTranslation');

  const isLoadingFeatureFlags =
    isLoadingCaptionTranscriptionFeatureFlag ||
    isLoadingCaptionTranslationFeatureFlag;

  const isCaptionTranscriptionEnabled = !!captionTranscriptionFeatureFlag?.value;
  const isCaptionTranslationEnabled = !!captionTranslationFeatureFlag?.value;

  const { active: isDesignAsset } = useDesign();

  const isIncomplete = (job?: TranslationJob | TranscriptionJob) => {
    if (!job) return false;
    return ['waiting', 'processing'].includes(job.status);
  };

  const {
    data: captionJobs,
    isLoading: isCaptionJobsLoading,
  } = useCaptionsJobsQuery({
    videoId: fieldData.video_id,
    programId,
    isDesignAsset,
    enabled: isCaptionTranscriptionEnabled,
    refetchInterval:
      isIncomplete(transcriptionJob) || isIncomplete(translationJob)
        ? 3000
        : undefined,
  });

  const {
    data: awsTranslationLanguages,
    isLoading: isLanguagesLoading,
  } = useCaptionsLanguagesQuery({
    programId,
    enabled: isCaptionTranslationEnabled,
  });

  const availableLanguages = useMemo(() => {
    return awsTranslationLanguages
      ? Object.fromEntries(
          awsTranslationLanguages.map((language) => [
            language.languageCode,
            language.languageName,
          ])
        )
      : {};
  }, [awsTranslationLanguages]);

  useEffect(() => {
    if (captionJobs?.transcription) {
      setTranscriptionJob(captionJobs.transcription);
    }
  }, [captionJobs?.transcription]);

  useEffect(() => {
    if (captionJobs?.translation) {
      setTranslationJob(captionJobs.translation);
    }
  }, [captionJobs?.translation]);

  return (
    <CaptionJobsContext.Provider
      value={{
        transcriptionJob,
        translationJob,
        isCaptionJobsLoading,
        isLanguagesLoading,
        setTranscriptionJob,
        setTranslationJob,
        isLoadingFeatureFlags,
        isCaptionTranscriptionEnabled,
        isCaptionTranslationEnabled,
        availableLanguages,
      }}
    >
      {children}
    </CaptionJobsContext.Provider>
  );
};

export const useCaptionsJobs: () => CaptionJobsContextProps = () => {
  const context = useContext(CaptionJobsContext);
  if (context === undefined) {
    throw new Error(
      'useCaptionsJobs must be used within a CaptionJobsProvider'
    );
  }
  return context;
};

type UseTranscribeVideoProps = {
  onSuccess: (data: TranscriptionJob) => void;
};

type UseTranslateVideoProps = {
  languages: string[];
  onSuccess: (data: TranslationJob) => void;
};

type UseTranscribeVideoReturn = {
  startTranscription: UseMutateFunction<
    TranscriptionJob,
    Error,
    TranscribeProps
  >;
  isTranscribing: boolean;
};

type UseTranslateVideoReturn = {
  startTranslation: UseMutateFunction<TranslationJob, Error, TranslateProps>;
  isTranslating: boolean;
};

export const useTranscribeVideo = ({
  onSuccess,
}: UseTranscribeVideoProps): UseTranscribeVideoReturn => {
  const { mutate: startTranscription, isLoading: isTranscribing } = useMutation<
    TranscriptionJob,
    Error,
    TranscribeProps
  >(transcribeVideo, {
    onSuccess,
  });
  return { startTranscription, isTranscribing };
};

export const useTranslateVideo = ({
  onSuccess,
}: UseTranslateVideoProps): UseTranslateVideoReturn => {
  const { mutate: startTranslation, isLoading: isTranslating } = useMutation<
    TranslationJob,
    Error,
    TranslateProps
  >(translateVideo, {
    onSuccess,
  });
  return { startTranslation, isTranslating };
};
