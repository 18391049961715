import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import * as Text from 'DesignSystem/Typography';
import { Section } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { TextInput } from 'shared/TextInput';
import { Program } from 'models/program';
import { useUpdateProgram } from 'hooks/program';
import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';
import { AppendedHashtagInput } from './AppendedHashtagInput';
import styles from './styles.module.css';
import { MAIcon } from '../../../../shared/MAIcon';

export const EditCommunityDetails: React.FC<RouteComponentProps> = () => {
  const program = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const [errors, setErrors] = React.useState<
    Record<string, string | undefined>
  >({});
  const { update } = useUpdateProgram(program.id, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Community details updated successfully',
      });
    },
    onError: (error) => {
      flashServerErrors(error, 'Could update Community details ');
    },
  });

  const [programState, setProgramState] = React.useState<Program>(program);
  function disableSave() {
    if (JSON.stringify(program) === JSON.stringify(programState)) {
      return true;
    }

    return Object.keys(errors).some((key) => errors[key]);
  }

  function validateRequired(key: string, value: string) {
    if (value.trim() === '') {
      setErrors({ ...errors, [key]: 'This field is required' });
      return;
    }

    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
  }

  return (
    <WithPermission permissions={['configureAdvocacyAccess']}>
      <FormPage
        title="Community Details"
        description="Manage community name and hashtags"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'Community Details', to: '..' },
          { label: 'edit' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <SVGIcon name="SaveSuccess" />,
            disabled: disableSave(),
            onClick: () => {
              update(programState);
            },
          },
        ]}
      >
        <Section title="Community Details">
          <div className={styles.Input}>
            <Text.Subheading block bold>
              Community Name
            </Text.Subheading>
            <TextInput
              value={programState.name}
              className={styles.TextField}
              placeholder="Enter a community name"
              onBlur={(value) => {
                validateRequired('name', value);
              }}
              onChange={(value) => {
                validateRequired('name', value);
                setProgramState({ ...programState, name: value });
              }}
            />
            {errors.name && (
              <div className={styles.Error}>
                <MAIcon name="warning" /> {errors.name}
              </div>
            )}
          </div>
          <div className={styles.Input}>
            <Text.Subheading block bold>
              Community URL Slug
            </Text.Subheading>
            <Text.Body block color={Text.color.gray60}>
              This will be added to your program&apos;s URL to uniquely identify
              it.
            </Text.Body>
            <TextInput
              value={programState.slug}
              className={styles.TextField}
              placeholder="Slug"
              onChange={(value) => {
                validateRequired('slug', value);
                setProgramState({ ...programState, slug: value });
              }}
              onBlur={(value) => {
                validateRequired('slug', value);
              }}
            />
            {errors.slug && (
              <div className={styles.Error}>
                <MAIcon name="warning" /> {errors.slug}
              </div>
            )}
          </div>

          <AppendedHashtagInput
            program={programState}
            setProgram={setProgramState}
            errors={errors}
            setErrors={setErrors}
          />
        </Section>
      </FormPage>
    </WithPermission>
  );
};
