import { request } from './api-shared';

type FetchCommentsAnalysisParams = {
  programId: number;
  contentId: number;
  usePercentage?: boolean;
};

export type CommentsAnalysis = {
  id: string;
  programId: number;
  contentId: number;
  polarity: {
    positive: number;
    negative: number;
    mixed: number;
    neutral: number;
    total: number;
  };
};

type JsonApiResponse<Type extends string, Attributes> = {
  id: string;
  data: {
    type: Type;
    attributes: Attributes;
  };
};

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export async function fetchCommentsAnalysis({
  programId,
  contentId,
  usePercentage,
}: FetchCommentsAnalysisParams): Promise<CommentsAnalysis> {
  const url = `${apiRoot}/v2/programs/${programId}/contents/${contentId}/comments/analysis`;

  const response = await request(url);
  if (response.status === 200) {
    const analysis = await response
      .json()
      .then(
        (
          json: JsonApiResponse<
            'aggregated_comments_analysis',
            CommentsAnalysis
          >
        ) => json.data.attributes
      );

    const { polarity } = analysis;

    if (usePercentage && polarity.total > 0) {
      analysis.polarity = {
        positive: (polarity.positive / polarity.total) * 100,
        negative: (polarity.negative / polarity.total) * 100,
        mixed: (polarity.mixed / polarity.total) * 100,
        neutral: (polarity.neutral / polarity.total) * 100,
        total: 100,
      };
    }

    return analysis;
  }
  throw new Error(`Error fetching comments analysis: ${response.status}`);
}
