import * as React from 'react';
import { useDrag, useDragDropManager } from 'react-dnd';
import * as Type from 'DesignSystem/Typography';
import { useSimpleBlocks } from 'hooks/publisher/useSimpleBlocks';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import styles from '../../publisher.module.css';

export const Blocks: React.FC = () => {
  const { id: programId } = useProgram();
  const boxIntegrationEnabled = useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement'
  ).data?.value;

  const { omitLibraryCategories } = React.useContext(BlocksEditorContext);

  const blocks = useSimpleBlocks({
    extended: true,
    omitNames: ['article'],
    includeNames: boxIntegrationEnabled ? ['box_integration'] : undefined,
    omitCategories: omitLibraryCategories,
  });
  const insert = useClickScrollInsert();
  useDragScrollFollow();
  return (
    <div className={styles.BlockPanel}>
      <div>
        <Type.Heading bold block>
          Blocks
        </Type.Heading>
      </div>
      <hr />
      <div>
        {blocks.map(({ block, Icon, title }) => (
          <DraggablePreview key={title} id={title}>
            <button type="button" onClick={() => insert(block.asset.blocks)}>
              <div style={{ transform: 'scale(1.5)' }}>
                <Icon />
              </div>
              <div style={{ marginTop: '8px' }}>{title}</div>
            </button>
          </DraggablePreview>
        ))}
      </div>
    </div>
  );
};

function useClickScrollInsert() {
  const canvas = React.useContext(BlocksEditorContext);
  const insert = React.useCallback(
    (data) => {
      const scroller = document.getElementById('canvas-scroll-container');
      const beforeHeight = scroller?.scrollTo(0, scroller?.scrollHeight) || 0;
      canvas.insert(data);
      scroller?.click(); // closes the panel with the right sequence

      const heightPoller = setInterval(() => {
        if (beforeHeight < (scroller?.scrollHeight || 0)) {
          clearInterval(heightPoller);
          scroller?.scrollTo(0, scroller?.scrollHeight);
        }
      }, 100);

      // killswitch, just in case.
      setTimeout(() => {
        clearInterval(heightPoller);
      }, 5000);
    },
    [canvas]
  );
  return insert;
}

function useDragScrollFollow() {
  const dragMonitor = useDragDropManager().getMonitor();
  React.useEffect(() => {
    const scroller = document.getElementById('canvas-scroll-container');
    return dragMonitor.subscribeToOffsetChange(() => {
      const offset = dragMonitor.getClientOffset();
      if (scroller && offset && offset.y) {
        if (offset.y < 200) scroller.scrollBy({ top: -20 });
        else if (document.body.clientHeight - offset.y < 200)
          scroller.scrollBy({ top: 20 });
      }
    });
  }, [dragMonitor]);
}

// integration with 3rd party library
/* eslint-disable react/jsx-props-no-spreading */
const DraggablePreview: React.FC<{ id: string }> = ({ children, id }) => {
  const [collected, draggableRef, draggingRef] = useDrag<
    { id: string },
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'add-block-from-panel-to-canvas',
    item: { id },
  }));
  return collected.isDragging ? (
    <div ref={draggingRef} />
  ) : (
    <div ref={draggableRef} {...collected}>
      {children}
    </div>
  );
};
/* eslint-enable react/jsx-props-no-spreading */
