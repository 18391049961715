import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { Pencil } from 'shared/icons';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { SendTestCampaign } from 'App/Program/Editors/Publisher/NewReview/ContentPreviewSection/SendTestCampaign';

import styles from './action-buttons.module.css';

export const ActionButtons: React.FC = () => {
  const {
    contentPermissions: { canEdit },
  } = useSettings();

  return (
    <Box margin={[32, 0, 0, 0]}>
      {canEdit && (
        <Button
          className={styles.Button}
          compact
          href="../compose/card"
          label={
            <>
              <Pencil /> <span className={styles.ButtonText}>Edit Cover</span>
            </>
          }
        />
      )}
      <SendTestCampaign />
    </Box>
  );
};
