import { Topic } from 'models/topic';

export const getGeneratedGroupTitles = (
  contentTopics: Array<Topic>
): Array<string> => {
  // NOTE group_table -> name and identifier rows removes special charecters(@!$%)
  // and added _1 in the end when topics duplicates.
  // Need to find a group by title. https://github.com/socialchorus/governor/blob/develop_fly/groups/app/generators/channel_subscriber_groups_generator.rb
  return contentTopics.map((c) => `Subscribed to: ${c.name}`);
};
