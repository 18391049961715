import React, { useMemo } from 'react';
import { Topic } from '../../../../../../../models/topic';
import { Button, Section } from '../../../../../../../DesignSystem/Form';
import { useFeedPreviewQuery } from '../../../../../../../hooks/feedPreview';
import { LoadingSpinner } from '../../../../../../../shared/LoadingSpinner';
import { PostsFeed } from '../../TopicForm/components/DesignView/tabs/Posts/Post';
import styles from './styles.module.css';
import { FeedQueryParams } from '../../../../../../../services/api-feed-preview';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { SearchInput } from '../../../../../../../shared/SearchInput';
import { pluralize } from '../../../../../../../utility/text';
import { usePermissions } from '../../../../../../../contexts/permissions';

interface Props {
  topic: Topic;
}

const postClassNames = {
  root: styles.post,
};
export function TopicPosts({ topic }: Props): JSX.Element | null {
  const { permissions } = usePermissions();
  const hasPostsTab = topic.landingPageTabs?.some(
    (tab) => tab.tabType === 'posts' && !tab.isHidden
  );
  const [search, setSearch] = React.useState('');
  const debouncedSearch = useDebounce(search.trim(), 500);
  const params = useMemo<FeedQueryParams>(
    () => ({ pageSize: 3, query: debouncedSearch }),
    [debouncedSearch]
  );
  const { isLoading, data } = useFeedPreviewQuery(topic.id, params);
  let postCount = null;
  if (!isLoading && data) {
    if (params.query) {
      postCount = data.meta?.totalResults || null;
    } else {
      postCount = topic.stats?.postsCount || null;
    }
  }
  if (!hasPostsTab) {
    return null;
  }
  let content;
  if (isLoading) {
    content = (
      <div className={styles.loadingPlaceholder}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <PostsFeed classNames={postClassNames} contents={data?.data || []} />
    );
  }
  return (
    <Section
      title="Latest Posts"
      className={styles.topicPosts}
      button={
        permissions.campaignsAccess && (
          <Button
            compact
            href={`/${topic.programId}/app/content/sent?topics=${topic.id}`}
            label="Go to Posts"
            secondary
          />
        )
      }
    >
      <div className={styles.searchWrapper}>
        <SearchInput
          panelClassName={styles.searchBar}
          placeholder="Search Posts"
          onChange={setSearch}
          value={search}
        />
        {postCount !== null && !isLoading && (
          <div className={styles.resultsCount}>
            {postCount} {pluralize(postCount, 'post')}
          </div>
        )}
      </div>
      {content}
    </Section>
  );
}
