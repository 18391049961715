import React, { useCallback, useEffect } from 'react';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import { Body, Caption, Subheading } from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form/InputElements';
import { ReactComponent as LightBulb } from 'shared/icons/LightBulb.svg';
import { ReactComponent as Check } from 'shared/icons/Check.svg';
import { notifications } from 'contexts/publisher/orchestrate/settings-fields';
import { usePrevious } from 'hooks/usePrevious';
import styles from './suggestion-section.module.css';
import {
  SuggestionsManager,
  SuggestionsState,
  useSuggestions,
} from './useSuggestions';
import { Suggestion } from './suggestions/Suggestion';

const SuggestionList: React.FC<{
  suggestionsManager: SuggestionsManager;
  dismiss: DismissType;
  disabled?: boolean;
}> = React.memo(
  ({ suggestionsManager: { suggestions, state }, dismiss, disabled }) => {
    const rowRenderer = useCallback(
      (index: number) => {
        const item: Suggestion = suggestions[index] as Suggestion;
        return item ? (
          <Box className={styles.Suggestion}>
            <div className={styles.border} />
            <Box margin={[0, 16, 5, 0]}>
              <Body bold>{item.title}</Body>
            </Box>
            <Box margin={[0, 16, 24, 0]}>
              <Caption>{item.description}</Caption>
            </Box>

            {item.apply && (
              <Box
                style={{ display: 'flex', gap: '10px' }}
                margin={[15, 16, 0, 0]}
              >
                <Button
                  compact
                  secondary
                  label={item.cta}
                  onClick={() => {
                    if (item.apply) item.apply();
                  }}
                  disabled={disabled}
                />
              </Box>
            )}
          </Box>
        ) : null;
      },
      [disabled, suggestions]
    );

    const previousState = usePrevious(state);
    // if the state changed to no suggestions, dismiss
    useEffect(() => {
      if (
        previousState !== undefined &&
        previousState !== state &&
        state === SuggestionsState.noSuggestions
      ) {
        dismiss();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dismiss, state, notifications]);

    return <div>{suggestions.map((value, index) => rowRenderer(index))}</div>;
  }
);

export const SuggestionsSelect: React.FC<{
  disabled?: boolean;
}> = ({ disabled = false }) => {
  const suggestionsManager = useSuggestions();
  const { loading, suggestions, state, badgeValue } = suggestionsManager;

  const suggestionsListDropdown = (dismiss: DismissType) => (
    <Box minWidth={400} margin={[-10, 0, 0, 0]}>
      <Popover padding={4}>
        <SuggestionList
          suggestionsManager={suggestionsManager}
          dismiss={dismiss}
          disabled={disabled}
        />
      </Popover>
    </Box>
  );

  const badge =
    badgeValue > 0 ? (
      <span className={styles.Badge}>{suggestions.length}</span>
    ) : null;

  let label: string;

  switch (state) {
    case SuggestionsState.noSuggestions:
      label = 'No suggestions';
      break;
    default:
      label = 'Suggestions';
  }

  return (
    <>
      <ClickDropdown
        dropdownRenderProp={suggestionsListDropdown}
        matchReferenceWidth
        disabled={loading || state === SuggestionsState.noSuggestions}
        onClose={() => {}}
      >
        <Button
          block
          icon={
            state === SuggestionsState.noSuggestions ? <Check /> : <LightBulb />
          }
          input
          disabled={state === SuggestionsState.noSuggestions}
          label={
            <Subheading>
              {label}&nbsp;{badge}
            </Subheading>
          }
          badge={<SVGIcon name="DownChevron" />}
          className={styles.SuggestionButton}
        />
      </ClickDropdown>
    </>
  );
};
