import * as React from 'react';
import { Button, FieldInput, Input } from 'DesignSystem/Form';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './url-input.module.css';
import { FieldLegend } from '../FieldLegend';

export const UrlInput: React.FC<{
  label?: string;
  value: string;
  onChange: (url: string) => void;
  onReset?: () => void;
  loading?: boolean;
}> = ({ label = 'URL', onChange, onReset, value, loading }) => (
  <FieldInput
    legend={
      <FieldLegend>
        <Flex start>
          {label}
          {loading && (
            <div className={styles.spinnerContainer}>
              <LoadingSpinner size="small" />
            </div>
          )}
        </Flex>
      </FieldLegend>
    }
    htmlFor="block-url-input"
  >
    <div style={{ position: 'relative' }}>
      <Input
        style={{ paddingRight: onReset ? '35px' : '8px' }}
        value={value}
        onChange={onChange}
        autoComplete="off"
        id="block-url-input"
        block
      />
      {onReset && (
        <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
          {value && (
            <Button
              minimal
              text
              onClick={onReset}
              label="&nbsp;&times;&nbsp;"
            />
          )}
        </div>
      )}
    </div>
  </FieldInput>
);
