import React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { LitmusRole } from 'models/role';
import { Stats } from './Stats';
import { EmptyStats } from './EmptyStats';
import styles from '../imports.module.css';

type Stats = {
  hasStats: boolean;
  experience: number;
  studio: { group: string; value: number | undefined }[];
};

const prepareError = (errors?: string[]) => {
  if (errors && errors.length) return errors[0];
  return 'No Errors';
};
export const Main: React.FC<{
  data: BulkUploadJob;
}> = ({ data }) => {
  const { errors, stats } = data.data;
  const errorMessage = prepareError(errors);
  const { id: programId } = useProgram();

  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;

  const { data: roles, isLoading: isRolesLoading } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });
  return (
    <div className={styles.userImportsPage}>
      <Section title="Error">
        <Row>
          <Block>
            <div>{errorMessage}</div>
          </Block>
        </Row>
      </Section>
      <Section title="Summary">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="File Name">{data.fileName || 'NA'}</Field>
          </div>
        </Fieldset>
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Audience">{data.invitationGroupName || 'NA'}</Field>
          </div>
        </Fieldset>
        {stats && !isRolesLoading && (
          <Stats
            stats={stats}
            roles={roles as LitmusRole[]}
            permissionsEnabled={permissionsEnabled}
          />
        )}
        {!stats && !isRolesLoading && (
          <EmptyStats
            roles={roles as LitmusRole[]}
            permissionsEnabled={permissionsEnabled}
          />
        )}
      </Section>
    </div>
  );
};
