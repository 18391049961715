import * as React from 'react';

// -- Generates a unique ID. --
// A few lines can replace a 3rd party library like UUID that will bring
// along many peer dependencies as well. When fails to suffice, install UUID.
//
// Some notes:
//  - This (almost?) exclusively used for creating an ID for an HTML element.
//  - HTML IDs are required to start with a letter.
//  - This implemention ensures the id always starts with a letter.

export function uniqueId(length = 8): string {
  if (length < 1) return '';
  const firstset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charset = `${firstset}0123456789`;
  let uuid = firstset.charAt(Math.floor(Math.random() * firstset.length));
  for (let i = 1; i < length; i += 1) {
    uuid += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return uuid;
}

export const useUniqueId = (length?: number): string => {
  return React.useMemo(() => uniqueId(length), [length]);
};

export const useUniqueIds = (count: number, length?: number): string[] => {
  const ids: string[] = [];
  for (let i = 0; i < count; i += 1) ids.push(uniqueId(length));
  const [uuids] = React.useState(ids);
  return uuids;
};
