import React from 'react';
import { FormSection, FormSubsection } from 'DesignSystem/Form';
import { useProgramIdState } from 'contexts/program';
import { AudienceSelect } from 'components/audience/AudienceSelect';
import { Audience } from 'models/audience';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { TopicSelect } from 'components/topics/TopicSelect';
import { Topic } from 'models/topic';

interface TargetCardProps {
  handleSelectedTopicsChange?: (topics: Array<Topic>) => void;
  selectedTopics?: Array<Topic>;
  handleSelectedAudiencesChange?: (audiences: Array<Audience>) => void;
  selectedAudiences?: Array<Audience>;
}
export const TargetCard: React.FC<TargetCardProps> = ({
  handleSelectedTopicsChange,
  selectedTopics,
  handleSelectedAudiencesChange,
  selectedAudiences,
}) => {
  const [programId] = useProgramIdState();
  const { data: topicEnabledFlag } = useFeatureFlagsQuery(
    programId,
    'Experimental.TargetedChannelLinks'
  );

  return (
    <FormSection title="Target">
      {topicEnabledFlag?.value ? (
        <FormSubsection title="Topics" divider={false}>
          {selectedTopics && handleSelectedTopicsChange && (
            <TopicSelect
              onChange={handleSelectedTopicsChange}
              value={selectedTopics}
              placeholder=""
            />
          )}
        </FormSubsection>
      ) : null}
      {selectedAudiences && handleSelectedAudiencesChange && (
        <FormSubsection title="Audience" divider={false}>
          <AudienceSelect
            selectedAudiences={selectedAudiences}
            onSelectedAudiencesChange={handleSelectedAudiencesChange}
          />
        </FormSubsection>
      )}
    </FormSection>
  );
};
