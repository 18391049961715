import { Topic } from 'models/topic';

/**
 * Returns the topic id to use for API calls
 * @param topic
 */
export function useTopicApiId(topic: Partial<Topic>): number {
  // If the topic is a draft with a parent, use the parent topic
  // instead as it will have the content attached to it
  return topic.draft && topic.parentId ? topic.parentId : Number(topic.id);
}
