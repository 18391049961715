import { bossanovaDomain, request } from './api-shared';

interface Attr {
  id: number;
  type: 'searchable-attributes';
  attributes: {
    'attribute-name': string;
    position: number;
    'updated-at': string;
    'created-at': string;
  };
}

interface SelectedAttrResponse {
  data: Array<Attr>;
}

export const fetchAvailableAttributes = async (
  programId: number
): Promise<Array<string>> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/searchable_attributes/available`;
  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    const { data } = await response.json();
    return data;
  }
  throw new Error(
    `Error fetching available search attributes: ${response.status}`
  );
};

export const fetchSelectedAttributes = async (
  programId: number
): Promise<Array<string>> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/searchable_attributes`;
  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    const data: SelectedAttrResponse = await response.json();
    return data.data.map((attr) => attr.attributes['attribute-name']);
  }
  throw new Error(
    `Error fetching selected search attributes: ${response.status}`
  );
};

export const updateSelectedAttributes = async (
  programId: number,
  attributes: Array<string>
): Promise<Array<string>> => {
  const url = `${bossanovaDomain}/v2/programs/${programId}/searchable_attributes`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
    body: JSON.stringify({
      data: {
        searchable_attributes: attributes,
      },
    }),
  });
  if (response.status === 200) {
    const data: SelectedAttrResponse = await response.json();
    return data.data.map((attr) => attr.attributes['attribute-name']);
  }
  throw new Error(`Error updating search attributes: ${response.status}`);
};
