export function resizePreview(
  element: HTMLDivElement,
  defaultWidth: number,
  desiredWidthPercentage: number
): { scaleValue: number } {
  let scaleValue = 1;
  if (element.parentElement) {
    const parentWidth = element.parentElement.getBoundingClientRect().width;
    const desiredWidth = parentWidth * desiredWidthPercentage;
    scaleValue = desiredWidth / defaultWidth;
  }
  return {
    scaleValue,
  };
}
