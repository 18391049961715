import camelcaseKeys from 'camelcase-keys';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
};

export type SubmitterCollectionData = Array<SubmitterData>;

export type SubmitterData = {
  userId: number;
  displayName: string;
  email: string;
};

export const fetchSubmitters = async (
  props: FetchProps
): Promise<SubmitterCollectionData> => {
  const { programId } = props;

  const url = `${apiRoot}/samba/programs/${programId}/contents/metadata/submitters`;

  const response = await request(url);
  if (response.status === 200) {
    return response
      .json()
      .then((json) => camelcaseKeys(json.data, { deep: true }));
  }
  throw new Error(`Error fetching submitters  : ${response.status}`);
};
