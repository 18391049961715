import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { PublisherFooter } from 'components/publisher/theme/PublisherFooter/PublisherFooter';
import { Sidebar } from './Sidebar';
import styles from './theme.module.css';

export const Theme: React.FC<RouteComponentProps> = ({ children }) => (
  <div className={styles.container}>
    <aside className={styles.aside}>
      <div className={styles.header}>Results</div>
      <Sidebar />
    </aside>
    <main className={styles.main}>{children}</main>
    <PublisherFooter />
  </div>
);
