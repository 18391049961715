import * as React from 'react';
import { useInputHighlighter } from 'hooks/inclusive-language/useInputHighlighter';
import { TextInput } from 'shared/TextInput';
import { useProgram } from 'contexts/program';
import cx from 'classnames';
import { CircleGauge } from 'shared/statistics/CircleGauge';
import { Flex } from 'DesignSystem/Layout/Flex';
import { usePublisher } from 'contexts/publisher';
import { useAIContentCover } from 'hooks/content-cover-generation';
import { InfoI } from 'shared/icons';
import styles from './sidebar.module.css';
import { AIControls } from './AIControls';
import { SidebarInputError } from './SidebarInputError';
import { detectLanguageDirection } from '../../blocks/LangDetect';

export const TitleInput: React.FC<{
  title: string;
  handleChange: (obj: { title: string }) => void;
  disabled: boolean;
  errorMessage?: string;
  showAIGenerationButton?: boolean;
}> = ({
  title,
  disabled,
  handleChange,
  errorMessage,
  showAIGenerationButton,
}) => {
  const { id: programId } = useProgram();
  const { post } = usePublisher();
  const [previousTitle, setPreviousTitle] = React.useState<string | null>(null);

  const backgroundHover = true;

  const onGenerationCompleted = React.useCallback(
    (value: string) => {
      handleChange({ title: value || '' });
    },
    [handleChange]
  );

  const {
    status,
    generate,
    error: completionError,
    eligibleBlocks,
  } = useAIContentCover({
    onCompleted: onGenerationCompleted,
    command: 'title',
    blocks: post.blocks,
    post,
  });

  const generateTitle = React.useCallback(() => {
    if (title && title.trim() !== '') {
      setPreviousTitle(title);
    }

    generate();
  }, [generate, title]);

  const undo = React.useCallback(() => {
    if (previousTitle && previousTitle.trim() !== '') {
      handleChange({ title: previousTitle });
    }

    // Reset previous title
    setPreviousTitle(null);
  }, [handleChange, previousTitle]);

  const changeTitle = React.useCallback(
    (value: string) => {
      handleChange({ title: value });
    },
    [handleChange]
  );

  const { inputRef, legendRef, onChange } = useInputHighlighter(
    programId,
    title,
    changeTitle
  );

  return (
    <fieldset className={styles.cardFieldset}>
      <legend ref={legendRef}>Title</legend>
      <TextInput
        disabled={disabled}
        className={cx('full-width text-input', styles.titleInput, {
          [styles.ctaInvalidInput]: errorMessage,
        })}
        value={title}
        maximum={150}
        onChange={onChange}
        inputRef={inputRef}
        dir={detectLanguageDirection(title)}
      />

      <Flex className={styles.count}>
        <CircleGauge radius={7} maximum={150} value={title.length ?? 0} />
        <div
          className={styles.number}
          style={{ width: `${String(title.length ?? 0).length}ch` }}
        >
          {title.length ?? 0}
        </div>
      </Flex>
      {showAIGenerationButton && (
        <AIControls
          status={status}
          trigger={generateTitle}
          cancel={undo}
          canUndo={!!previousTitle}
          sparkleFillColor="#F74857"
          backgroundHover={backgroundHover}
          disabled={eligibleBlocks.length === 0}
        />
      )}
      {(errorMessage || completionError) && (
        <SidebarInputError disabled={disabled} message={errorMessage} />
      )}
      {showAIGenerationButton && eligibleBlocks.length === 0 && (
        <div className={styles.ctaAiWarning}>
          <InfoI className={styles.infoSign} />
          Add a block with text content to enable AI generation
        </div>
      )}
    </fieldset>
  );
};
