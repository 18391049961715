import React from 'react';
import { FAIcon } from 'shared/Icon/FAIcon';
import { pluralize } from 'utility/text';
import { Icon } from 'shared/Icon';
import styles from './ConfirmModal.module.css';

export type ConfirmModalProps = {
  headerTitle?: string;
  actionTitle?: string;
  toStatusLabel?: string;
  itemName?: string;
  customTitle?: string;
  alert?: string;
  hideLabel?: boolean;
  confirmLabel?: string;
  count?: number;
  recordsToUpdate?: number;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  headerTitle = 'Bulk Change',
  actionTitle = '',
  itemName = 'item',
  confirmLabel = 'Confirm',
  count,
  toStatusLabel,
  onCancel,
  onConfirm,
  recordsToUpdate,
  hideLabel,
  customTitle = 'Make the following changes to',
  alert,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onConfirm();
  };

  const isActionable = recordsToUpdate !== 0;

  const getMessage = () => {
    const itemLabel = pluralize(Number(count), itemName);
    const toActionLabel = toStatusLabel || actionTitle;

    if (typeof count === 'number' && typeof recordsToUpdate === 'number') {
      if (recordsToUpdate === 0) {
        return `All of the selected ${itemLabel} are already ${toActionLabel}`;
      }
      if (recordsToUpdate < count) {
        const noAffectedCount = count - recordsToUpdate;
        return `Of the selected ${itemLabel}, ${noAffectedCount} ${
          noAffectedCount === 1 ? 'is' : 'are'
        } already ${toActionLabel}. ${customTitle} ${recordsToUpdate} of ${count} ${itemLabel}.`;
      }
      if (recordsToUpdate === count) {
        return `${customTitle} ${count} ${itemLabel}`;
      }
    }
    if (count) {
      return `${customTitle} ${count} ${itemLabel}`;
    }
    return customTitle;
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>{headerTitle}</h2>
          <button
            type="button"
            className={styles.modalHeaderClose}
            onClick={onCancel}
          >
            <FAIcon name="close" />
          </button>
        </div>

        <div className={styles.modalBody}>
          {alert && (
            <p className={styles.alert}>
              <Icon
                iconName="exclamation-triangle"
                className={styles.alertIcon}
              />{' '}
              {alert}
            </p>
          )}
          <div>
            <p className={styles.modalLabel}>{getMessage()}</p>
          </div>
          {!hideLabel && isActionable && (
            <div className={styles.actionName}>{actionTitle}</div>
          )}
        </div>

        <div className={styles.modalFooter}>
          <button type="button" className={styles.modalDeny} onClick={onCancel}>
            Cancel
          </button>
          {isActionable && (
            <button
              type="button"
              className={styles.modalAccept}
              onClick={handleClick}
            >
              {confirmLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
