import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './tooltip.module.css';

type TooltipType = {
  title?: string;
  description?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  width?: string;
  height?: string;
};

export const Tooltip: React.FC<TooltipType> = ({
  title,
  description,
  className,
  titleClassName,
  width,
  height,
}) => {
  const customStyles = useMemo(() => {
    let providedStyles = {};
    if (width) providedStyles = { ...providedStyles, width };
    if (height) providedStyles = { ...providedStyles, height };

    return providedStyles;
  }, [width, height]);

  return (
    <div className={cx(styles.tooltipBlock, className)} style={customStyles}>
      <div className={cx(styles.tooltipTitle, titleClassName)}>{title}</div>
      {description && (
        <div className={styles.tooltipDescription}>{description}</div>
      )}
    </div>
  );
};
