import { useQuery, useInfiniteQuery } from 'react-query';
import { BulkUploadJob } from 'models/bulk-upload-job';
import {
  fetchImportJob,
  fetchErrorReport,
  UserImportFetchParams,
  UserImportCollectionData,
  fetchUserImports,
  fetchJobReport,
} from 'services/api-imports';

import {
  InfiniteQueryResponse,
  MutationOptions,
  QueryResponse,
} from './common';

export const useUserImportQuery = (
  programId: number,
  jobId: number
): QueryResponse<BulkUploadJob> => {
  const { isLoading, error, data } = useQuery<BulkUploadJob, Error>(
    `job-${jobId}`,
    () => fetchImportJob(programId, jobId),
    { retry: false, cacheTime: 0 }
  );

  return {
    isLoading,
    data,
    errorMessage: error?.message,
  };
};

export function mapServerDataToUserImport(
  serverData: UserImportCollectionData
): Array<BulkUploadJob> {
  return serverData.data.map((entity: BulkUploadJob) => entity);
}

export const useUserImportInfiniteQuery = (
  props: Omit<UserImportFetchParams, 'page'>
): InfiniteQueryResponse<BulkUploadJob> => {
  const { order, programId, pageSize = 20, filter, search } = props;
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<UserImportCollectionData, Error>(
    ['user-imports-infinite', JSON.stringify(props)],
    async ({ pageParam = 1 }) =>
      fetchUserImports({
        order,
        programId,
        search,
        page: { number: pageParam, size: pageSize },
        filter,
      }),
    {
      getNextPageParam: (lastGroup) => {
        if (lastGroup && lastGroup.meta.pageNumber < lastGroup.meta.totalPages)
          return lastGroup.meta.pageNumber + 1;

        return undefined;
      },
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToUserImport(batch) : []))
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};

export const useGenerateJobReport = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): { download: (jobId: number) => void } => {
  const download = (jobId: number) => {
    fetchJobReport(programId, jobId)
      .then((response) => {
        if (onSuccess) onSuccess(response.csvRows);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { download };
};

export const useGenerateErrorReport = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): { download: (jobId: number) => void } => {
  const download = (jobId: number) => {
    fetchErrorReport(programId, jobId)
      .then((response) => {
        if (onSuccess) onSuccess(response.csvRows);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { download };
};
