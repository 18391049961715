import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { FieldInput, Input } from 'DesignSystem/Form';
import { FormModal } from 'DesignSystem/Components';
import { useDebounce } from 'hooks/useDebounce';
import { Box } from 'DesignSystem/Components/Box';
import { isUrlValid as validateUrl } from 'utility/text';

export const UrlInput: React.FC<{
  onChange: (url: string) => void;
  onCancel: () => void;
}> = ({ onChange, onCancel }) => {
  const [url, setUrl] = React.useState('');
  const [isUrlValid, setIsUrlValid] = React.useState(false);
  const [isUrlVerified, setIsUrlVerified] = React.useState(false);
  const [isUrlTouched, setIsUrlTouched] = React.useState(false);

  const debouncedUrl = useDebounce(url);

  React.useEffect(() => {
    if (!isUrlTouched) {
      return;
    }

    setIsUrlValid(validateUrl(debouncedUrl));
    setIsUrlVerified(true);
  }, [debouncedUrl, isUrlTouched]);

  const onInputChange = React.useCallback(
    (value) => {
      if (!isUrlTouched) setIsUrlTouched(true);
      setIsUrlVerified(false);
      setUrl(value);
    },
    [isUrlTouched]
  );

  const apply = React.useCallback(() => {
    if (debouncedUrl) onChange(debouncedUrl);
  }, [debouncedUrl, onChange]);

  const hasError = isUrlVerified && !isUrlValid;
  return (
    <FormModal
      entityText="url"
      actionText="add"
      onCancel={onCancel}
      onSubmit={apply}
      disabled={!isUrlVerified || hasError}
    >
      <FieldInput
        htmlFor="block-field-image-url"
        legend={<Text.Body bold>URL</Text.Body>}
      >
        <Input
          data-test="external-image"
          id="block-field-image-url"
          value={url}
          placeholder="Required"
          onChange={onInputChange}
          hasError={hasError}
          block
        />
        <Box minHeight={22}>
          <Text.Caption block color={Text.color.redFull}>
            {hasError ? 'URL is invalid.' : undefined}
          </Text.Caption>
        </Box>
      </FieldInput>
    </FormModal>
  );
};
