import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import React from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import DateRangeInput from 'shared/DateRangeInput';
import { DateRange, RelativeRange } from 'shared/DateRangeInput/DateRange';
import { DateFilter } from 'models/journeys/filter';
import styles from './filters.module.css';

export type DataRangeFilterValue = DateRange;

export const DateFilterComponent: React.FC<{
  filter: DateFilter;
  onChange: (filter: DateFilter) => void;
}> = ({ onChange, filter }) => {
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(() => {
    return (
      <div className={styles.filterDropdownContainer}>
        <DateRangeInput
          onStateChange={(dateRange) => {
            onChange({ ...filter, value: dateRange });
          }}
          initialValue={filter.value}
          onDone={() => onDismissRef.current()}
        />
      </div>
    );
  }, [filter, onChange]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div>{dropdown}</div>;
    },
    [dropdown]
  );

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp} cursorType="default">
      <TriggerButton
        name={filter?.label}
        values={[filter?.value?.label || '']}
        onClose={() =>
          onChange({
            ...filter,
            value: RelativeRange.build('past30days~'),
            active: false,
          })
        }
      />
    </ClickDropdown>
  );
};
