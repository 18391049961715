import { navigate } from '@reach/router';
import cx from 'classnames';
import React from 'react';

import { usePublisher } from 'contexts/publisher';
import { useProgramIdState } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { useStateMutation } from 'hooks/state-mutator';
import { DEFAULT_ERROR_MESSAGE } from 'models/flash-message';
import { Post } from 'models/publisher/post';
import { StateAction } from 'services/api-post';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from 'shared/NavigationFooter/menu.module.css';
import buttonStyles from 'shared/styles/buttons.module.css';

type PropsType = {
  action: StateAction;
  shouldUpsert?: boolean;
  label: string;
  message: string;
  showCondition?: (post: Post) => boolean;
  disabled?: boolean;
};

export const StateLink: React.FC<PropsType> = ({
  action,
  shouldUpsert = false,
  label,
  message,
  disabled,
  showCondition,
}) => {
  const { post } = usePublisher();
  const [programId] = useProgramIdState();
  const { setFlashMessage } = useFlashMessage();

  const onSuccess = React.useCallback(() => {
    navigate(`/${programId}/app/content`);
    setFlashMessage({
      severity: 'info',
      message,
    });
  }, [message, programId, setFlashMessage]);

  const onError = React.useCallback(
    (error) =>
      setFlashMessage({
        severity: 'error',
        message: DEFAULT_ERROR_MESSAGE,
        details: error.message,
      }),
    [setFlashMessage]
  );
  const { mutate, isSaving } = useStateMutation({
    action,
    onSuccess,
    onError,
    shouldUpsert,
  });

  const perform = React.useCallback(() => {
    mutate({ post, programId });
  }, [mutate, post, programId]);

  const showLink = React.useMemo(() => {
    if (showCondition) return showCondition(post);
    return true;
  }, [post, showCondition]);

  return showLink ? (
    <div className={styles.item}>
      <button
        type="button"
        onClick={perform}
        disabled={isSaving || disabled}
        className={cx(styles.stateLink, buttonStyles.buttonReset, {
          [styles.disabled]: isSaving || disabled,
        })}
      >
        {label}
      </button>
      {isSaving && <LoadingSpinner size="small" />}
    </div>
  ) : null;
};
