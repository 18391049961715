import React from 'react';
import cx from 'classnames';
import { ExternalSource } from 'models/external-source';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { TextInput } from 'shared/TextInput';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TopicInput } from './TopicInput';
import { ExpandArticlesInput } from './ExpandArticlesInput';
import { SharingInput } from './SharingInput';

import styles from './form.module.css';

type PropsType = {
  externalSource: Partial<ExternalSource>;
  onChange: (data: Partial<ExternalSource>) => void;
  update?: boolean;
};

export const Form: React.FC<PropsType> = ({
  externalSource,
  onChange,
  update = false,
}) => {
  function handleTypeChange(type: string) {
    if (update) return;
    onChange({ type });
  }
  function handleIdentifierChange(identifier: string) {
    if (update) return;
    onChange({ identifier });
  }
  return (
    <div className={styles.form}>
      <Section title="General">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Type" className={styles.typeField}>
              <div className={styles.typeSelector}>
                <button
                  type="button"
                  className={cx(styles.typeOption, {
                    [styles.selectedType]: externalSource.type === 'rss',
                  })}
                  onClick={() => {
                    handleTypeChange('rss');
                  }}
                >
                  <SVGIcon name="Rss" />
                </button>
                <button
                  type="button"
                  className={cx(styles.typeOption, {
                    [styles.selectedType]: externalSource.type === 'twitter',
                  })}
                  onClick={() => {
                    handleTypeChange('twitter');
                  }}
                >
                  <SVGIcon name="XSocial" />
                </button>
              </div>
            </Field>
          </div>
        </Fieldset>
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field
              label={externalSource.type === 'rss' ? 'URL' : '"X" username'}
            >
              <TextInput
                className={styles.textField}
                onChange={handleIdentifierChange}
                value={externalSource.identifier}
                disabled={update}
              />
            </Field>
          </div>
        </Fieldset>
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Publish to">
              <TopicInput externalSource={externalSource} onChange={onChange} />
            </Field>
          </div>
        </Fieldset>
      </Section>

      <Section title="Publishing rules">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Auto publish">
              <div className="kai-flex-row">
                <div className={styles.marginRight}>
                  <FlipSwitch
                    size="xl"
                    shape="rounded"
                    on={externalSource.autoPublish === true}
                    onChange={(value) =>
                      value
                        ? onChange({
                            autoPublish: true,
                          })
                        : onChange({
                            autoPublish: false,
                          })
                    }
                  />
                </div>
                Automatically publish content
              </div>
            </Field>
          </div>
        </Fieldset>
        {externalSource.type === 'rss' && (
          <ExpandArticlesInput
            externalSource={externalSource}
            onChange={onChange}
          />
        )}
        <SharingInput externalSource={externalSource} onChange={onChange} />
      </Section>
    </div>
  );
};
