import { useNavigate } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useFlashServerErrors } from 'utility/errors';
import { useQueryClient } from 'react-query';

import { ExternalSource } from 'models/external-source';
import {
  useUpdateExternalSource,
  useCreateExternalSource,
  useArchiveExternalSource,
  useUnarchiveExternalSource,
  useHarvestExternalSource,
  useBulkArchiveExternalSources,
  useBulkUnarchiveExternalSources,
} from 'hooks/external-source';
import { BulkSelection, ExternalSourceActionFilters } from 'hooks/common';

export const useActions = (
  setExternalSource?: (externalSource: ExternalSource) => void,
  updateList = false
): {
  update: (externalSource: ExternalSource) => void;
  create: (externalSource: Partial<ExternalSource>) => void;
  archive: (externalSourceId: number) => void;
  unarchive: (externalSourceId: number) => void;
  harvest: (externalSourceId: number) => void;
  bulkArchive: (
    bulkSelection: BulkSelection,
    filterConfig: ExternalSourceActionFilters
  ) => void;
  bulkUnarchive: (
    bulkSelection: BulkSelection,
    filterConfig: ExternalSourceActionFilters
  ) => void;
} => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const flashServerErrors = useFlashServerErrors();
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();
  const feedListUrl = `/${programId}/configure/feeds`;
  const infiniteQueryKey = 'external-sources-infinite';

  const { update } = useUpdateExternalSource(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Feed updated successfully',
      });
      navigate(feedListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update feed');
    },
  });

  const { create } = useCreateExternalSource(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Feed created successfully',
      });
      navigate(feedListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not create feed');
    },
  });

  const { archive } = useArchiveExternalSource(programId, {
    onSuccess: (data) => {
      setExternalSource?.(data);
      if (updateList) {
        queryClient.invalidateQueries(infiniteQueryKey);
      }
      setFlashMessage({
        severity: 'info',
        message: 'Feed archived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive feed',
      }),
  });

  const { unarchive } = useUnarchiveExternalSource(programId, {
    onSuccess: (data) => {
      setExternalSource?.(data);
      if (updateList) {
        queryClient.invalidateQueries(infiniteQueryKey);
      }
      setFlashMessage({
        severity: 'info',
        message: 'Feed unarchived successfully',
      });
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not unarchive feed');
    },
  });

  const { harvest } = useHarvestExternalSource(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Please check back in a few minutes for a status update.',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not refresh feed',
      }),
  });

  const { bulkArchive } = useBulkArchiveExternalSources(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Feeds archived successfully.',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not archive feeds',
      }),
  });

  const { bulkUnarchive } = useBulkUnarchiveExternalSources(programId, {
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Feeds unarchived successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not unarchive feeds',
      }),
  });

  return {
    update,
    archive,
    create,
    unarchive,
    harvest,
    bulkArchive,
    bulkUnarchive,
  };
};
