import * as React from 'react';
import styles from './banner-list-item.module.css';
import {
  EmptyBanner,
  BannerProps,
  ThumbnailType as ThumbnailPropsType,
  MenuItemsType as MenuItemsPropsType,
  DESIGN_SYSTEM_ROW_HEIGHT as EMPTY_ROW_HEIGHT,
} from './EmptyBanner';
import { Icon, IconType } from '../Icon';

const ICON_SIZE = 14;
const MAIN_ICON_SIZE = 16;

export const ROW_HEIGHT = EMPTY_ROW_HEIGHT;
export type ThumbnailType = ThumbnailPropsType;
export type MenuItemsType = MenuItemsPropsType;
export type IconStatType = {
  text: string;
} & IconType;

const IconStat: React.FC<IconStatType & { size: number; className: string }> = (
  props
) => {
  const { text, size, className, ...iconProps } = props;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={className}>
      <Icon size={size} useCurrentColor {...iconProps} />
      <span>{text}</span>
    </div>
  );
};

type PropsType = {
  headline: string;
  description?: string;
  rowId?: string;
  centerSectionStats?: Array<IconStatType>;
  rightSectionMainStat?: IconStatType;
  rightSectionStats?: Array<IconStatType>;
} & BannerProps;

export const BannerListItem: React.FC<PropsType> = (props) => {
  /* eslint-disable react/jsx-props-no-spreading */
  const {
    headline,
    description,
    rowId,
    centerSectionStats,
    rightSectionMainStat,
    rightSectionStats,
    ...bannerProps
  } = props;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <EmptyBanner {...bannerProps} rowId={rowId}>
      <section className={styles.col1}>
        <h2 className={styles.title} title={headline}>
          {headline}
        </h2>
        {description && (
          <p className={styles.description} title={description}>
            {description}
          </p>
        )}
      </section>
      <section className={styles.col2}>
        {centerSectionStats &&
          centerSectionStats.map((stat) => (
            <div key={stat.text} className={styles.stat}>
              {stat.text}
            </div>
          ))}
      </section>
      <section className={styles.col3}>
        {rightSectionMainStat && (
          <IconStat
            size={MAIN_ICON_SIZE}
            className={styles.stat}
            {...rightSectionMainStat}
          />
        )}
        {rightSectionStats &&
          rightSectionStats.map((stat) => (
            <IconStat
              key={stat.text}
              size={ICON_SIZE}
              className={styles.stat}
              {...stat}
            />
          ))}
      </section>
    </EmptyBanner>
  );
};
