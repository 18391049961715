import { useDeleteBoxFolderMapping } from 'hooks/box-knowledge-mangement';
import { useFlashMessage } from 'contexts/flasher';
import { useFlashServerErrors } from 'utility/errors';

export const useActions = (): {
  deleteBoxFolderMapping: {
    deleteAction: (id: number) => Promise<void>;
    isLoading: boolean;
  };
} => {
  const flashServerErrors = useFlashServerErrors();
  const { setFlashMessage } = useFlashMessage();
  const { mutateAsync, isLoading } = useDeleteBoxFolderMapping();

  const deleteBoxFolderMapping = (id: number): Promise<void> => {
    return mutateAsync(id, {
      onSuccess: () => {
        setFlashMessage({
          severity: 'info',
          message: 'Folder deleted successfully',
        });
      },
      onError: (error) => {
        flashServerErrors(error, 'Folder was not deleted');
      },
    });
  };

  return {
    deleteBoxFolderMapping: { deleteAction: deleteBoxFolderMapping, isLoading },
  };
};
