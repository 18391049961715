import React from 'react';
import qs from 'qs';
import { CollectionsContext } from 'App/Program/Main/Insight/contexts/CollectionsContext';
import { RouteComponentProps } from '@reach/router';
import {
  ReportsList,
  ReportsListViewType,
} from 'App/Program/Main/Insight/components/ReportsList/ReportsList';
import { ReportForCollectionViewProps } from 'App/Program/Main/Insight/components/ReportsList/ReportItemCard';
import styles from './Bookmarks.module.css';
import { Image } from '../../../../../shared/icons/index';

export const BookmarksPage: React.FC<RouteComponentProps> = () => {
  /*
   * This page renders all of the bookmarked reports
   * which are retrieved from the collections context
   * rendered within the CollectionsProvider
   * and the CollectionsSidebarTheme
   * the bookmarked reports are accessed via the CollectionsContext
   * */
  const { bookmarkedCollectionQuery } = React.useContext(CollectionsContext);

  // For now all reports render as the 'card' type.
  // This might change in the future if we build additional view types
  const reportsListViewType: ReportsListViewType = 'card';

  // we need to have a special transformation of reports when rendering bookmarks...
  const reports: ReportForCollectionViewProps[] =
    bookmarkedCollectionQuery?.data?.map((b) => {
      const reportWithCollectionViewProps: ReportForCollectionViewProps =
        b.report;

      // we need to override the report title with the bookmark title
      reportWithCollectionViewProps.title = b.title;

      // get the query string
      reportWithCollectionViewProps.queryString = qs.stringify(b.filterState);

      // we need to let the report card component know about the bookmark ID
      reportWithCollectionViewProps.bookmarkId = b.id;

      // private bookmarks will render the lock icon
      reportWithCollectionViewProps.shouldShowLockIcon = !b.isPublic;

      // the view for bookmarks require a header with the bookmarked user's info
      // for now we manually construct the props to pass
      // in the future we will pass an advocate user object
      reportWithCollectionViewProps.headerPanelProps = {
        title: b.bookmarkedUserName, // for now this comes back from server as first name + last name
        avatarUrl: b.creatorAvatarUrl, // avatar url of bookmark creator
        subtitle: b.createdAt.toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        thumbnailInitials: b.bookmarkedUserName // we manually extract initials from the full name string
          .split(' ')
          .map((s: string) => s[0])
          .join(''),
      };
      return reportWithCollectionViewProps;
    }) || [];
  return (
    <>
      {reports.length > 0 ? (
        <ReportsList reports={reports} viewType={reportsListViewType} />
      ) : (
        <div className={styles.noItemsFound}>
          <div>
            <Image className={styles.image} />
          </div>
          <span>You have no saved reports.</span>
        </div>
      )}
    </>
  );
};
