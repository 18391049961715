import * as React from 'react';
import { useProgram } from 'contexts/program';
import { useImportUsers } from 'hooks/user';
import { useState } from 'react';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { FAIcon } from 'shared/Icon/FAIcon';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { useQueryClient } from 'react-query';
import { useBulkUploadQuery } from 'hooks/bulk-upload';
import styles from './import-users.module.css';
import {
  EmptySidebar,
  Sidebar,
} from '../../Configuration/Content/Users/Import/View/Sidebar';
import { ImportUsersForm } from './ImportUsersForm';

const BulkUploadReloader: React.FC<{
  job: BulkUploadJob;
  onBulkUploadLoaded: (job: BulkUploadJob) => void;
}> = ({ job, onBulkUploadLoaded }) => {
  const queryClient = useQueryClient();
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries(`bulk-upload-${job.programId}-${job.id}`);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  });
  const { data } = useBulkUploadQuery(job.programId, job.id.toString());
  if (data) {
    onBulkUploadLoaded(data);
  }

  return null;
};

export const ImportUsersSlideIn: React.FC<{
  name: string;
  onClose: () => void;
}> = ({ onClose, name }) => {
  const { id: programId } = useProgram();
  const [job, setJob] = useState<BulkUploadJob>();
  const [error, setError] = useState('');
  const { importUsers } = useImportUsers(
    programId,
    (data) => {
      setJob(data);
    },
    (err) => setError(err)
  );
  const header = <h1 className="page-header">Upload audience</h1>;
  const form = (
    <ImportUsersForm job={job} error={error} onFileSelect={importUsers} />
  );

  const sidebar = job ? <Sidebar data={job} /> : <EmptySidebar />;

  return (
    <SlideIn name={name}>
      {(slideOut) => (
        <>
          <MainEditor
            header={header}
            main={form}
            sidebar={sidebar}
            footer={
              <div className={styles.footer}>
                <button
                  type="button"
                  className={styles.close}
                  onClick={() => slideOut(onClose)}
                >
                  <FAIcon name="arrow-left" />{' '}
                  {job && <span>{job.fileName}</span>}
                </button>
              </div>
            }
          />
          {job && !['completed', 'partial'].includes(`${job.status}`) && (
            <BulkUploadReloader onBulkUploadLoaded={setJob} job={job} />
          )}
        </>
      )}
    </SlideIn>
  );
};
