import * as React from 'react';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import cx from 'classnames';
import styles from './text-input.module.css';

export type PropsType = {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  className?: string;
  onFocus?: () => void;
  compact?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  maximum?: number;
  id?: string;
  number?: boolean;
  dataTest?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'>;

export const TextInput = React.forwardRef<HTMLInputElement, PropsType>(
  (
    {
      className,
      disabled,
      hasError,
      id,
      inputRef,
      onChange,
      onFocus,
      placeholder,
      maximum,
      value,
      onBlur,
      compact,
      number,
      errorMessage,
      dataTest,
      ...inputProps
    }: PropsType,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (number) {
        if (
          event.target.value.length === 0 ||
          /^\d+$/.test(event.target.value)
        ) {
          onChange?.(event.target.value);
        }
      } else {
        onChange?.(event.target.value);
      }
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
      onBlur?.(event.target.value);
    };

    return (
      <>
        <input
          id={id}
          type={inputProps.type || 'text'}
          ref={inputRef || ref}
          className={cx(styles.input, className, {
            [styles.compact]: compact,
            [styles.error]: hasError,
          })}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value || ''}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={onFocus}
          maxLength={maximum}
          data-test={dataTest}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...inputProps}
        />
        {hasError && errorMessage && (
          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: '#DD1D1D',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon name="WarningTriangle" size={16} />
            <div style={{ padding: '5px 10px' }}>{errorMessage}</div>
          </div>
        )}
      </>
    );
  }
);
