import * as React from 'react';
import { MetabaseReport } from 'models/insight/Report';
import { ReportsList } from '../components/ReportsList/ReportsList';
import styles from '../insight.module.css';

export const RelatedReports: React.FC<{
  relatedReports?: MetabaseReport[];
  onClick: () => void;
}> = ({ relatedReports, onClick }) =>
  !relatedReports?.length ? null : (
    <div className={styles.RelatedReports}>
      <h4 className={styles.subheader}>
        Related Reports ({relatedReports.length})
      </h4>
      <ReportsList reports={relatedReports} onClick={onClick} />
    </div>
  );
