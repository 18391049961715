import React, { createContext, useContext } from 'react';

export const DEFAULT_ERROR_MESSAGE = 'This journey could not be opened';

type ErrorHandlerContextData = {
  errorMessage?: string;
  setErrorMessage: (msg?: string) => void;
};

const ErrorHandlerContext = createContext({
  setErrorMessage: (() => {}) as (msg?: string) => void,
});

const useErrorHandlerContext = (): ErrorHandlerContextData => {
  const context = useContext(ErrorHandlerContext);
  if (context === undefined) {
    throw new Error(
      'Journey error handler context requires a containing ErrorHandlerProvider'
    );
  }
  return context;
};

export const useJourneyErrorHandler = (): ErrorHandlerContextData => {
  const { errorMessage, setErrorMessage } = useErrorHandlerContext();
  return { errorMessage, setErrorMessage };
};

export const ErrorHandlerProvider: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const contextValue = { errorMessage, setErrorMessage };
  return (
    <ErrorHandlerContext.Provider value={contextValue}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
