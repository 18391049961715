import React from 'react';
import { ExternalSource } from 'models/external-source';
import { Topic } from 'models/topic';
import { useTopicsQuery } from 'hooks/topics';
import { TopicSelect } from 'components/topics/TopicSelect';
import { useProgram } from 'contexts/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './form.module.css';

type PropsType = {
  externalSource: Partial<ExternalSource>;
  onChange: (data: Partial<ExternalSource>) => void;
};

export const TopicInput: React.FC<PropsType> = ({
  externalSource,
  onChange,
}) => {
  const { id: programId } = useProgram();
  const [topics, setTopics] = React.useState<Topic[]>([]);
  const { data, isLoading } = useTopicsQuery({
    programId,
    ids:
      Number(externalSource.contentChannelIds?.length) > 0
        ? externalSource.contentChannelIds
        : [0],
  });

  React.useEffect(() => {
    if (data) {
      setTopics(data);
    }
    // eslint-disable-next-line
  }, [isLoading, externalSource.contentChannelIds]);

  function handleChanges(values: Topic[]) {
    setTopics(values);
    const contentChannels = values.map((topic) => {
      return { id: Number(topic.id), name: topic.name };
    });
    onChange({ contentChannels });
  }

  return (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && <TopicSelect value={topics} onChange={handleChanges} />}
    </>
  );
};
