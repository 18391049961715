import { Audience, canEditAny } from 'models/audience';
import { ThumbnailType } from 'shared/BannerListItem/EmptyBanner';
import { MenuItemsType, IconStatType } from 'shared/BannerListItem';
import { ItemType } from 'shared/IconMenu/IconMenuItem';
import { Permissions } from 'models/permissions';
import { titleCase } from 'utility/text';

export function audienceToThumbnail(audience: Audience): ThumbnailType {
  return {
    title: audience.title.toUpperCase(),
    backgroundColor: 'var(--color-brandShadeDark)',
    color: 'var(--color-gray00)',
  };
}

export function audienceToMenuItems(
  programId: number,
  audience: Audience,
  permissions: Permissions,
  onDuplicate: () => void,
  onArchive: () => void,
  onUnarchive: () => void,
  onCreateSnapshot: () => void,
  onAddToFavorites: () => void,
  onRemoveFromFavorites: () => void,
  favoriteAudience?: boolean
): MenuItemsType {
  const {
    permissions: { manageAudienceAccess },
  } = permissions;
  const operation = canEditAny(audience) ? 'Edit' : 'View';
  const items: ItemType[] = [
    {
      title: operation,
      href: `/${programId}/app/people/audiences/${audience.id}`,
    },
    {
      title: `${favoriteAudience ? 'Remove from ' : 'Add to'} Favorites`,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation(); // prevent select/deselect the row
        if (favoriteAudience) onRemoveFromFavorites();
        else onAddToFavorites();
      },
      href: `#`,
    },
  ];

  if (manageAudienceAccess) {
    items.push({
      title: 'Duplicate',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation(); // prevent select/deselect the row
        onDuplicate();
      },
      href: `#`,
    });

    if (audience.type !== 'snapshot') {
      items.push({
        title: 'Create Snapshot',
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation(); // prevent select/deselect the row
          onCreateSnapshot();
        },
        href: `#`,
      });
    }

    if (audience.type !== 'standard') {
      const shouldDisableForScim =
        audience.state !== 'disabled' && audience.type === 'scim';
      items.push({
        title: audience.state === 'disabled' ? 'Unarchive' : 'Archive',
        disabled: shouldDisableForScim,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation(); // prevent select/deselect the row
          if (audience.state === 'disabled') onUnarchive();
          else onArchive();
        },
        href: `#`,
        danger: true,
        hoverTooltip: shouldDisableForScim
          ? 'ID Provider generated audiences cannot be archived'
          : undefined,
      });
    }
  }

  return items;
}

export function audienceToQueryTypeStat(audience: Audience): string {
  return audience.type === 'snapshot' ? 'Static' : 'Dynamic';
}

export function audienceToAudienceTypeStat(audience: Audience): string {
  return audience.type === 'scim' ? 'ID Provider' : titleCase(audience.type);
}

export function audienceToUsersCountStat(audience: Audience): string {
  return !audience.totalUsers && audience.totalUsers !== 0
    ? 'N/A'
    : audience.totalUsers.toString();
}

export function audienceToTagsStat(
  audience: Audience
): IconStatType | undefined {
  if (audience.tags && audience.tags.length > 0) {
    return {
      iconName: 'tag',
      iconType: 'FA',
      text:
        audience.tags.length > 1
          ? `${audience.tags[0]} +${audience.tags.length - 1}`
          : audience.tags[0],
    };
  }
  return undefined;
}
