import * as React from 'react';
import styles from './masonry.module.css';

export function Masonry<T>(props: {
  items: T[];
  render(item: T): React.ReactElement;
  columns: number;
}): React.ReactElement {
  const { items, render, columns } = props;

  // The layout is a "masonry" style grid, like pinterest.
  // There are two versions of this layout:
  //
  // +-----------+    +-----------+
  // | 1 | 4 | 7 |    | 1 | 2 | 3 |
  // |---|   |---|    |---|   |---|
  // | 2 |---| 8 | vs | 4 |---| 6 |
  // |   | 5 |---|    |   | 5 |---|
  // |---|---| 9 |    |---|---| 9 |
  // | 3 | 6 |   |    | 7 | 8 |   |
  // +-----------+    +-----------+
  //
  // The first is easy to achieve with plain CSS, but
  // ends up with a lot rearrangment of items as it changes,
  // whether via loading, filtering, etc.
  //
  // The second one is the preferrable outcome, but requires
  // some javascript. We can absolutely position each one,
  // but that requires monitoring dimensions of each item.
  //
  // We can use the simple CSS approach of the first one by
  // putting the items in N buckets before rendering. This
  // allows added items to appear at the bottom, and doesn't
  // require any window resizing hooks, or load events.
  //
  // If we want a responsive design, we can add some event watcher
  // in the client and change the column count in the props.

  const buckets = React.useMemo(() => {
    const sorted: Array<{ key: string; items: T[] }> = new Array(columns);
    items.forEach((item, index) => {
      const i = index % columns;
      if (!sorted[i]) sorted[i] = { key: `${index}`, items: [] };
      sorted[i].items.push(item);
    });
    return sorted;
  }, [items, columns]);

  return (
    <div className={`${styles.masonry} -of-${columns}`}>
      {buckets.map((column) => (
        <div key={`column-${column.key}`}>{column.items.map(render)}</div>
      ))}
    </div>
  );
}
