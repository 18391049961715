import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';
import { UserIdentity } from 'models/identity';

export type IdentitiesFetchParams = {
  search?: string;
  page?: number;
  pageSize?: number;
  programId: number;
};

export type IdentitiesCollectionData = {
  data: Array<UserIdentity>;
  meta?: {
    totalObjects: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  };
};

export const fetchIdentities = async (
  props: IdentitiesFetchParams
): Promise<IdentitiesCollectionData> => {
  const { programId, ...queryParams } = props;
  const query = qs.stringify(snakeCaseKeys({ ...queryParams }), {
    arrayFormat: 'brackets',
  });

  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/identities?${query}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching identities`);
};

export const fetchUserIdentity = async (
  userId: string,
  programId: number
): Promise<UserIdentity> => {
  const url = `${bossanovaDomain}/v2/tenants/program:${programId}/identities/user:${userId}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then((input) => deepCamelcaseKeys(input).data);
  }

  throw new Error(`Error fetching user identity`);
};

export const upsertUserIdentity = async (
  data: UserIdentity
): Promise<UserIdentity> => {
  const url = `${bossanovaDomain}/v2/tenants/${data.tenant}/identities/${data.name}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakeCaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return deepCamelcaseKeys(await response.json()).data;
  }

  throw new Error(`Error creating users: ${response.status}`);
};
