import * as React from 'react';
import { FieldData, FieldDefinition, FieldType } from 'models/publisher/block';
import { usePublisher } from 'contexts/publisher';
import { useFieldVariables as useDesignFieldVariables } from 'hooks/design/useFieldVariables';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { PublisherType } from 'models/library';
import { resolveVariables } from './variableResolver';

export const useVariableExpander: (args: {
  fields: FieldDefinition[];
  fieldData: FieldData;
  onChange: (fieldName: string, data: FieldType) => void;
}) => null = ({ fields, fieldData, onChange }) => {
  const { post } = usePublisher();
  const { publisherType } = React.useContext(BlocksEditorContext);
  const designVariables = useDesignFieldVariables();
  const postVariables = useFieldVariables(post);
  const vals =
    publisherType === PublisherType.journeys ? designVariables : postVariables;

  React.useEffect(() => {
    const newValues = resolveVariables({ vals, fields, fieldData });

    Object.keys(newValues).forEach((k) => {
      takeFinalCall(k, () => {
        onChange(k, newValues[k]);
      });
    });
  }, [fields, fieldData, onChange, vals]);
  return null;
};

// During the discovery PUB-2659, a nasty cycle of calls was found here in the
// variable explander. It might have something to do with using the publisher
// hook mixed with functionally-oriented props. The publisher hook was introduced
// to be able to resolve the latest values for the vars.
//
// Someday someone might solve this problem.
//
// Until then, all the stream of change requests can be debounced, by field name,
// and wait until calls have stopped coming in before applying the change.
//
// This seems to give the code enough time to escape out of a render loop.
//
// If you find yourself working on this code, and want to be sure you haven't broken
// anything, add all the "author" blocks you can find at the same time. The UI
// should not hang.
const poolQueue: Record<string, ReturnType<typeof setTimeout>> = {};
function takeFinalCall(name: string, fn: () => void): void {
  if (poolQueue[name]) clearTimeout(poolQueue[name]);
  poolQueue[name] = setTimeout(fn, 100);
}
