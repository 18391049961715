import {
  AddableStepTypes,
  CommunicationStep,
  DecisionEdge,
  DeliveryChannel,
  Journey,
  Step,
} from 'models/journeys/journey';
import { useReducer } from 'react';
import { Position } from 'reactflow';
import { exhaustiveCheck } from 'utility/errors';
import {
  handleCommunicationChannelUpdated,
  handleCommunicationChannelToggled,
} from './step-communication';
import {
  handleStepDecisionOptionDeleted,
  handleStepDeleted,
} from './step-deleted';
import { handleStepInserted } from './step-inserted';
import { handleStepMoved } from './step-moved';
import { handleStepUpdated } from './step-updated';

type JourneyDispatchMiddleware = (
  next: React.Dispatch<JourneyReducerAction>
) => React.Dispatch<JourneyReducerAction>;

export function useJourneyReducer(
  initialJourney: Journey,
  ...middleware: JourneyDispatchMiddleware[]
): readonly [Journey, React.Dispatch<JourneyReducerAction>] {
  const [journey, d] = useReducer(journeyReducer, initialJourney);

  return [journey, wrapDispatch(d, ...middleware)] as const;
}

export type JourneyActionFor<T extends JourneyReducerAction['type']> = Extract<
  JourneyReducerAction,
  { type: T }
>;

/**
 * Defines the actions for updating journey state, modeled as discrete events.
 *
 * Components should avoid directly modifying journey state or applying complex logic.
 * If you're updating the state with multiple `dispatch` calls or embedding business logic within an event handler,
 * consider creating a new action to represent that transactional event more explicitly.
 */
export type JourneyReducerAction =
  | {
      /**
       * Updates a specific step in the journey. For updating a communication step's channels
       * consider using `step/communication/channels/updated`
       */
      type: 'step/updated';
      stepId: Step['id'];
      step: Pick<Step, 'type'> &
        (
          | Partial<Omit<CommunicationStep, 'channels' | 'id'>>
          | Exclude<Step, { type: 'communication' }>
        );
    }
  | {
      /**
       * Deletes a step from the journey. Handles both decision and non-decision steps.
       */
      type: 'step/deleted';
      stepId: Step['id'];
    }
  | {
      /**
       * Moves a step left or right within the journey.
       */
      type: 'step/moved';
      stepId: Step['id'];
      direction: Position.Left | Position.Right;
    }
  | {
      /**
       *  Insert a default step at a targetId
       */
      type: 'step/inserted';
      stepType: AddableStepTypes;
      /*
       * The targetId is the step that the new step will be inserted before.
       * This targetId will be the next step of the inserted step.
       */
      targetId: Step['id'];
      /* The sourceId is the edge that the new step is inserted on */
      sourceId: Step['id'];
    }
  | {
      /**
       * Deletes a specific decision option branch within a decision step.
       */
      type: 'step/decision/deleted';
      stepId: Step['id'];
      edge: DecisionEdge;
    }
  | {
      /**
       * Updates the entire journey.
       */
      type: 'journey/updated';
      journey: Journey;
    }
  | {
      /**
       * Toggles selection of a communication channel, e.g. email, push, notification center
       */
      type: 'step/communication/channel/toggled';
      stepId: Step['id'];
      channel: DeliveryChannel['name'];
    }
  | {
      /**
       * Updates a specific channel for a communication step.
       * If the channel does not exist, the state will not change.
       */
      type: 'step/communication/channel/updated';
      stepId: Step['id'];
      channel: Required<Pick<DeliveryChannel, 'name'>> &
        Partial<DeliveryChannel>;
    };

export function journeyReducer(
  state: Journey,
  action: JourneyReducerAction
): Journey {
  switch (action.type) {
    case 'step/updated':
      return handleStepUpdated(state, action);

    case 'step/inserted': {
      return handleStepInserted(state, action);
    }

    case 'step/deleted': {
      return handleStepDeleted(state, action);
    }

    case 'step/communication/channel/updated': {
      return handleCommunicationChannelUpdated(state, action);
    }

    case 'step/communication/channel/toggled': {
      return handleCommunicationChannelToggled(state, action);
    }

    case 'step/decision/deleted': {
      return handleStepDecisionOptionDeleted(state, action);
    }

    case 'step/moved': {
      return handleStepMoved(state, action);
    }

    case 'journey/updated':
      return action.journey;

    default:
      return exhaustiveCheck(action, 'Unknown Journey Reducer Action');
  }
}

/**
 * Wraps a dispatch function with middleware to apply additional logic
 * before and after the dispatch.
 */
function wrapDispatch(
  disp: React.Dispatch<JourneyReducerAction>,
  ...ware: JourneyDispatchMiddleware[]
) {
  let d = disp;
  for (const middleware of ware.reverse()) {
    d = middleware(d);
  }

  return d;
}
