import React from 'react';
import { FEATURED_LABELS } from 'models/publisher/settings';
import { Label } from 'models/label';
import { LabelDropdown } from './LabelDropdown';

interface TopicDropdownProps {
  disabled?: boolean;
  defaultSelectedLabel?: Label;
  onChange: (label?: Label) => void;
}

export const FeaturedLabelsDropdown: React.FC<TopicDropdownProps> = ({
  disabled,
  defaultSelectedLabel,
  onChange,
}) => {
  return (
    <>
      <LabelDropdown
        placeholder="Select Featured Label"
        defaultSelectedLabel={defaultSelectedLabel}
        disabled={disabled}
        onChange={onChange}
        labels={FEATURED_LABELS}
      />
    </>
  );
};
