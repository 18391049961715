import React from 'react';
import {
  DashboardFilter,
  useDashboardFilters,
} from '../hooks/useDashboardFilters';
import { DateRangePickerContainer } from './DashboardParameter';

export const DashboardFilterBar: React.FC<{
  dashboardId: string;
  disabled: boolean;
  onParameterChanged: (
    name: string,
    value: string | number | boolean | Date
  ) => void;
  onFilterChange: (name: string, values: string[]) => void;
}> = ({ dashboardId, disabled, onParameterChanged, onFilterChange }) => {
  const dashboardFilters = useDashboardFilters(dashboardId);
  return (
    <div className="kai-flex-row">
      {dashboardFilters.parameters.length ? (
        dashboardFilters.parameters.map((x) => (
          <DateRangePickerContainer
            key={x}
            onChange={onParameterChanged}
            disabled={disabled}
          />
        ))
      ) : (
        <></>
      )}
      {dashboardFilters.filters.length ? (
        dashboardFilters.filters.map((x) => (
          <DashboardFilter key={x} filter={x} onChange={onFilterChange} />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};
