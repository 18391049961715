import * as React from 'react';
import { ButtonLinkFieldData } from 'models/publisher/block';
import { Fieldset, Field } from 'shared/Fieldset';
import { TextInput } from 'shared/TextInput';
import { Toggle } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { FieldProps } from '../FieldProps';
import styles from './button-link.module.css';

export const ButtonLinkField: React.FC<FieldProps<ButtonLinkFieldData>> = ({
  data,
  field,
  onChange,
}) => {
  const placeholders = field.placeholder as ButtonLinkFieldData;
  const showOpenInNewTab = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.ShowOpenLinksInNewTab'
  ).data?.value;

  return (
    <Fieldset>
      <Field label="Title">
        <TextInput
          className={styles.textInput}
          value={data.title}
          placeholder={placeholders.title}
          maximum={100}
          onChange={(e) => {
            if (e === data.title) {
              return;
            }
            onChange({
              ...data,
              title: e,
            });
          }}
          autoComplete="off"
        />
      </Field>
      <Field label="URL">
        <TextInput
          className={styles.textInput}
          value={data.url}
          placeholder={placeholders.url}
          onChange={(e) => {
            if (e === data.url) {
              return;
            }
            onChange({
              ...data,
              url: e,
            });
          }}
          autoComplete="off"
        />
      </Field>
      {showOpenInNewTab && (
        <Field label="Open in new tab?">
          <Toggle
            on={data.openInNewTab}
            onChange={(e) => onChange({ ...data, openInNewTab: e ?? false })}
            size="xl"
          />
        </Field>
      )}
    </Fieldset>
  );
};
