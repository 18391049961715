import { RouteComponentProps } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { Topic, updateTopic, validateTopic } from 'models/topic';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { TopicContext } from '../TopicContext';
import styles from '../topics.module.css';
import { useActions } from '../useActions';
import { Footer } from './Footer';
import { Form } from './Form';
import { Sidebar } from './Sidebar';

export const EditTopicMainPage: React.FC<RouteComponentProps> = () => {
  const { topic, setTopic, isLoading, errorMessage } = useContext(TopicContext);
  const { setFlashMessage } = useFlashMessage();
  const { update, archive, unarchive, isLoading: isWorking } = useActions(
    setTopic
  );

  const onChange = React.useCallback(
    (updatedData: Partial<Topic>) => {
      setTopic((prev) => updateTopic(prev as Topic, updatedData));
    },
    [setTopic]
  );

  const header = (
    <>
      <h1 className="page-header">Topic</h1>
    </>
  );

  const main = (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && topic && (
        <Form topic={topic} onChange={onChange} />
      )}
    </>
  );

  function onSave() {
    const validationError = validateTopic(topic);
    if (validationError) {
      setFlashMessage({
        severity: 'error',
        message: validationError,
      });
      return;
    }

    update(topic as Topic);
  }

  return (
    <WithPermission permissions={['confContentAccess', 'topicsAccess']}>
      <Helmet>
        <title>Edit Topic</title>
      </Helmet>
      {topic && (
        <MainEditor
          header={header}
          main={main}
          sidebar={
            <Sidebar topic={topic} archive={archive} unarchive={unarchive} />
          }
          footer={
            <Footer topic={topic} action={onSave} isWorking={isWorking} />
          }
        />
      )}
    </WithPermission>
  );
};
