import React, { useCallback } from 'react';
import { useToggle } from 'hooks/useToggle';
import { MAIcon } from 'shared/MAIcon';
import { copyToClipboard } from 'utility/copy-to-clipboard';
import { Button } from 'DesignSystem/Form';
import { useFlashMessage } from 'contexts/flasher';
import styles from './dev-mode.module.css';

const DEV_MODE_STORAGE_KEY = 'dev-mode-enabled' as const;

const DevMode = {
  enable() {
    localStorage.setItem(DEV_MODE_STORAGE_KEY, 'yes');
  },
  disable() {
    localStorage.removeItem(DEV_MODE_STORAGE_KEY);
  },
  isEnabled() {
    return localStorage.getItem(DEV_MODE_STORAGE_KEY) === 'yes';
  },
  toggle() {
    if (DevMode.isEnabled()) DevMode.disable();
    else DevMode.enable();
  },
};

export const DevModeContext = React.createContext({
  enabled: false,
});

export const DevModeProvider: React.FC<{ code: string }> = ({
  children,
  code,
}) => {
  const [enabled, setEnabled] = React.useState(DevMode.isEnabled());
  const [codePosition, setCodePosition] = React.useState(0);

  /**
   * Listen for key enters, keeping track of how far along the code we've gotten.
   * If we get an unexpected key, we restart.
   *
   * We also update the state on window focus, in case someone enabled it on
   * another window.
   */
  React.useEffect(() => {
    const listenForCode = (e: KeyboardEvent) => {
      setCodePosition((pos) => (e.key === code[codePosition] ? pos + 1 : 0));
    };
    const updateEnabled = () => {
      setEnabled(DevMode.isEnabled());
    };
    window.addEventListener('keydown', listenForCode);
    window.addEventListener('focus', updateEnabled);
    return () => {
      window.removeEventListener('keydown', listenForCode);
      window.removeEventListener('focus', updateEnabled);
    };
  }, [code, codePosition]);

  /**
   * If the code is successfully entered, we toggle dev mode
   */
  React.useEffect(() => {
    if (codePosition === code.length) {
      DevMode.toggle();
      setEnabled(DevMode.isEnabled);
      setCodePosition(0);
    }
  }, [code.length, codePosition]);

  return (
    <DevModeContext.Provider value={{ enabled }}>
      {children}
    </DevModeContext.Provider>
  );
};

/**
 * Helper components to show dev mode UI
 * Move to separate files as these become more numerous
 */

export const DevReveal: React.FC<{ view: React.ReactNode }> = ({
  children,
  view,
}) => {
  const { enabled } = React.useContext(DevModeContext);
  const { toggle, value } = useToggle();
  if (!enabled) return <>{children}</>;
  return (
    <>
      <Floating outside>
        <Button
          onClick={toggle}
          layoutOnly
          icon={
            value ? (
              <MAIcon name="visibility_off" />
            ) : (
              <MAIcon name="visibility" />
            )
          }
          className={styles.devModeButton}
        />
      </Floating>
      {value ? view : children}
    </>
  );
};

const Floating: React.FC<{ outside?: boolean }> = ({
  children,
  outside = false,
}) => {
  return (
    <div style={{ position: 'relative', zIndex: 100000 }}>
      <div
        style={{
          position: 'absolute',
          right: outside ? '-90px' : '10px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const CopyToClipboard: React.FC<{ name: string; value?: string }> = ({
  name,
  value,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const copy = React.useCallback(() => {
    if (!value) return;
    copyToClipboard(value);
    setFlashMessage({
      severity: 'info',
      message: `Devtools copied ${name} to your clipboard`,
      timeout: 3000,
    });
  }, [name, setFlashMessage, value]);

  const { enabled } = React.useContext(DevModeContext);
  if (!enabled) return null;
  if (!value) return null;

  return (
    <Floating>
      <Button
        title={`Copy ${name} to your clipboard`}
        layoutOnly
        onClick={copy}
        className={styles.devModeButton}
        icon={<MAIcon name="content_copy" />}
      />
    </Floating>
  );
};

export const PasteFromClipboard: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPaste: (v: any) => void;
}> = ({ onPaste }) => {
  const { setFlashMessage } = useFlashMessage();

  const paste = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    try {
      onPaste(JSON.parse(text));
    } catch (e) {
      setFlashMessage({
        severity: 'error',
        message: `Error parsing json ${text.slice(0, 10)}`,
        timeout: 3000,
      });
    }
  }, [onPaste, setFlashMessage]);
  const { enabled } = React.useContext(DevModeContext);
  if (!enabled) return null;

  return (
    <Floating>
      <Button
        title="Paste from clipboard"
        layoutOnly
        onClick={paste}
        className={styles.devModeButton}
        icon={<MAIcon name="content_paste" />}
      />
    </Floating>
  );
};

export const PrettyJson: React.FC<{ value: unknown }> = ({ value }) => {
  const string = React.useMemo(() => JSON.stringify(value, null, 2), [value]);
  return (
    <>
      <CopyToClipboard name="JSON" value={string} />
      <pre style={{ overflowX: 'scroll', backgroundColor: 'white', margin: 0 }}>
        <code>{JSON.stringify(value, null, 2)}</code>
      </pre>
    </>
  );
};
