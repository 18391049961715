import React from 'react';
import {
  linksToFields,
  fieldsToLinks,
  SocialFieldData,
  SocialData,
} from 'models/publisher/block';
import { FieldFormProps } from '../../../useFieldForm';

export const useSocialDataMutator: (
  props: FieldFormProps<SocialFieldData[]>
) => {
  items: SocialData[];
  changeItems(data: SocialData[]): void;
} = ({ data, onChange }) => {
  const [initial] = React.useState(fieldsToLinks(data || []) as SocialData[]);
  const [items, setItems] = React.useState(initial);
  const changeItems = React.useCallback(
    (updated: SocialData[]) => {
      setItems(updated);
      onChange(linksToFields(updated) as SocialFieldData[]);
    },
    [onChange]
  );
  return { items, changeItems };
};

export type SocialDataMutator = ReturnType<typeof useSocialDataMutator>;
