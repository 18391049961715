import React from 'react';
import { ImageData } from 'models/image';
import { Icon } from 'shared/Icon';
import styles from './select-image.module.css';

type PropsType = {
  current: ImageData;
  setImage: (image: ImageData) => void;
  images: Array<ImageData>;
};

export const SelectImage: React.FC<PropsType> = ({
  current,
  setImage,
  images,
}) => {
  const [index, setIndex] = React.useState<number>(0);

  // this is causing PUB-3333
  React.useEffect(() => {
    if (current.url) {
      setIndex(images.findIndex((image) => image.url === current.url));
    }
  }, [current, images]);

  const hasNext = index < images.length - 1;
  const next = React.useCallback(() => {
    if (hasNext) {
      setIndex(index + 1);
      setImage(images[index + 1]);
    }
  }, [hasNext, images, index, setImage]);

  const hasPrev = index >= 1;
  const prev = React.useCallback(() => {
    if (hasPrev) {
      setIndex(index - 1);
      setImage(images[index - 1]);
    }
  }, [hasPrev, images, index, setImage]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.arrows}>
        <button disabled={!hasPrev} type="button" onClick={prev}>
          <Icon iconType="SVG" iconName="ArrowPrev" />
        </button>
        <button disabled={!hasNext} type="button" onClick={next}>
          <Icon iconType="SVG" iconName="ArrowNext" />
        </button>
      </div>
      <div className={styles.pager}>
        {index + 1} of {images.length}
      </div>
    </div>
  );
};
