import React, { useCallback } from 'react';
import { Content } from 'models/content';
import { isPoll, Poll } from 'models/poll';
import { DirectionalTooltip } from 'shared/DirectionalTooltip/DirectionalTooltip';
import { truncate } from 'utility/text';
import { Workflow } from 'models/workflows';
import { useContentReportUrls } from 'hooks/content/useContentReportUrls';
import styles from './content-metrics-tooltip.module.css';
import { useWorkflowReportUrls } from './useWorkflowReportUrls';

export const ContentReportLinks: React.FC<{
  content: Content | Poll;
}> = ({ content }) => {
  const { getArticleUrl, getPollUrl, getVideoUrl } = useContentReportUrls({
    content,
  });

  const polls = isPoll(content) ? [content] : content?.meta?.polls;
  const status = isPoll(content) ? content.status : content.publicationState;

  const pollReportLink = useCallback(
    (poll: { id: number; name: string }) => {
      if (poll.name.length <= 25)
        return (
          <div key={poll.id}>
            <a
              target="_blank"
              rel="noreferrer"
              href={getPollUrl(poll.id)}
              className={styles.reportUrl}
            >
              {isPoll(content) ? 'Poll Summary' : `Poll Summary: ${poll.name}`}
            </a>
          </div>
        );

      return (
        <DirectionalTooltip
          title={poll.name}
          direction="top"
          className={styles.dropdown}
          tooltipClass={styles.tooltip}
          tooltipTitleClass={styles.tooltipTitle}
          openDelay={1000}
          closeDelay={100}
        >
          <div key={poll.id}>
            <a
              target="_blank"
              rel="noreferrer"
              href={getPollUrl(poll.id)}
              className={styles.reportUrl}
            >
              {isPoll(content)
                ? 'Poll Summary'
                : `Poll Summary: ${truncate(poll.name, { size: 25 })}`}
            </a>
          </div>
        </DirectionalTooltip>
      );
    },
    [content, getPollUrl]
  );

  return (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={getArticleUrl()}
        className={styles.reportUrl}
      >
        View full report
      </a>
      <div>
        {status === 'published' &&
          (polls as Array<{ id: number; name: string }>)?.map(pollReportLink)}
      </div>
      <div>
        {!isPoll(content) &&
          status === 'published' &&
          content.meta?.videos?.map(({ id }, i) => (
            <div key={id}>
              <a
                target="_blank"
                rel="noreferrer"
                href={getVideoUrl(id)}
                className={styles.reportUrl}
              >
                Video Performance: Video {i + 1}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export const DeliveryReportLink: React.FC<{
  content: Content | Poll;
}> = ({ content }) => {
  const { getDeliveryDetailsUrl } = useContentReportUrls({ content });

  return (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={getDeliveryDetailsUrl()}
        className={styles.reportUrl}
      >
        View delivery details
      </a>
    </div>
  );
};

export const WorkFlowReportLinks: React.FC<{
  workflow: Workflow;
}> = ({ workflow }) => {
  const { performanceReportUrl } = useWorkflowReportUrls(workflow);

  return (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={performanceReportUrl}
        className={styles.reportUrl}
      >
        View full report
      </a>
    </div>
  );
};
