import * as React from 'react';
import { IMAGE_ACCEPT_STRING, ImageData } from 'models/image';
import { UrlInput } from '../components/UrlInput';
import { Library } from '../components/Library';
import * as SourceMenu from '../../shared/SourceMenu';

const { OPENED, CLOSED } = SourceMenu;
export { OPENED, CLOSED };

export const UPLOAD = 'upload' as const;
export const LIBRARY = 'library' as const;
export const EXTERNAL = 'external' as const;
export type Source = typeof UPLOAD | typeof LIBRARY | typeof EXTERNAL;

export function useImageSourceMenu(
  visibility: boolean,
  onCreate: (data: ImageData[] | File[] | DataTransferItemList) => void
): SourceMenu.MenuIface<Source> & {
  fileInput: React.ReactNode;
  libraryInput: React.ReactNode;
  externalInput: React.ReactNode;
  onExternalChange: (url: string) => void;
  onUploadClick: () => void;
} {
  const maxSelections = 1;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onUploadClick = React.useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);
  const menu = [
    {
      name: 'Upload file',
      source: UPLOAD,
      icon: <SourceMenu.UploadIcon />,
      onClick: onUploadClick,
    },
    {
      name: 'Select from Library',
      source: LIBRARY,
      icon: <SourceMenu.LibraryIcon />,
    },
    {
      name: 'Add from link',
      source: EXTERNAL,
      icon: <SourceMenu.LinkIcon />,
    },
  ];

  const source = SourceMenu.useSourceMenu(menu, {
    source: UPLOAD,
    visibility,
  });

  const fileInput = React.useMemo(
    () => (
      <span
        style={{
          display: 'block',
          position: 'absolute',
          height: '1px',
          width: '1px',
          overflow: 'hidden',
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept={IMAGE_ACCEPT_STRING}
          data-test="upload-image"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file =
              event.currentTarget.files && event.currentTarget.files[0];
            if (file) {
              onCreate([file]);
              // Value has been passed to the callback so is safe to clear.
              // This is necessary to allow the same file to be selected again and give any visual feedback.
              if (inputRef.current) inputRef.current.value = '';
            }
          }}
        />
      </span>
    ),
    [inputRef, onCreate]
  );

  const onExternalChange = React.useCallback(
    (url: string) => {
      onCreate([{ url, altText: '', processed: false }]);
      source.close();
    },
    [onCreate, source]
  );

  const libraryInput = React.useMemo(
    () =>
      source.visibility !== OPENED || source.source !== LIBRARY ? null : (
        <Library
          maxSelections={maxSelections}
          onClose={source.close}
          onChange={onCreate}
        />
      ),
    [maxSelections, source.source, source.visibility, source.close, onCreate]
  );

  const externalInput = React.useMemo(
    () =>
      source.visibility !== OPENED || source.source !== EXTERNAL ? null : (
        <UrlInput onChange={onExternalChange} onCancel={source.close} />
      ),
    [source.source, source.visibility, source.close, onExternalChange]
  );

  return {
    ...source,
    fileInput,
    libraryInput,
    externalInput,
    onExternalChange,
    onUploadClick,
  };
}
