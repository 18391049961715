import React from 'react';
import { Block, Row } from 'shared/SectionBlocks';
import { MAX_CONTENT_SIZE_IN_MB } from 'utility/constants';
import styles from './errorlist.module.css';
import { ErrorRow } from './ErrorRow';

export interface ErrorsMap {
  [key: string]: {
    link: string | null;
    text: string;
    isWarning?: boolean;
    title?: string;
  };
}

export const errorsMap = {
  usedLiquidSyntax: {
    link: null,
    title: 'Campaign contains unsupported syntax',
    text:
      'It looks like content within this campaign contains unsupported syntax. This will be rendered as plain text when the campaign is published.',
    isWarning: true,
  },
  feature_enabled: {
    link: null,
    text: 'Publishing is not enabled yet',
  },
  no_topic_or_audience: {
    link: '../../orchestrate/setup',
    text: 'Audience or topic is missing',
  },
  archive_date_in_past: {
    link: '../../deliver/rules',
    text: 'Archive date must be in the future',
  },
  no_title_or_description: {
    link: '../../compose/card',
    text: 'Title or description is missing',
  },
  no_blocks: {
    link: '../../compose/design',
    text: 'Add a block',
  },
  notification_datetime_in_past: {
    link: '../../deliver/rules',
    text:
      'One or more Notifications are configured for a time in the past. Please adjust accordingly',
  },
  notification_datetimes_are_missing: {
    link: '../../deliver/rules',
    text:
      'One or more Notifications are missing DateTimes. You must set DateTimes for all configured Notifications',
  },
  notification_has_empty_text: {
    link: '../../deliver/rules',
    text:
      'One or more Notifications are missing text. All Notifications must have configured text.',
  },
  notification_datetimes_same_date: {
    link: '../../deliver/rules',
    text:
      'One or more Notifications are scheduled for the same day. You must set DateTime for different days.',
  },
  channel_override_no_audience: {
    link: '../details',
    text: 'Channel overrides and notifications requires an audience',
  },
  no_delivery_for_audience: {
    link: '../../deliver/rules',
    text:
      'This campaign cannot publish anywhere. Enable For You or at least one channel to publish.',
  },
  message_notification_length: {
    link: '../../deliver/rules',
    text: 'Messages preview text is too long',
  },
  campaign_limit_exceeded: {
    link: '../../compose/design',
    text: `The campaign content size limit has exceeded ${MAX_CONTENT_SIZE_IN_MB} MB.`,
  },
} as ErrorsMap;

export const ErrorList: React.FC<{
  errors: Array<string>;
}> = ({ errors }) => {
  const errorRow = (err: string) => {
    const { link, text } = errorsMap[err];
    return <ErrorRow link={link} text={text} />;
  };

  return (
    <div className={styles.errorBlock}>
      <Row>
        <Block className={styles.mainErrorBlock}>
          <Block className={styles.errorHead}>
            <h1 className={styles.errorTitle}>
              Errors need to be corrected before publishing
            </h1>
          </Block>
          {errors.map((err) => errorRow(err))}
        </Block>
      </Row>
    </div>
  );
};
