import * as React from 'react';
import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ProgressAnimation } from 'shared/ProgressAnimation';

export const LoadingPlaceholder: React.FC<{ prompt: string }> = (props) => {
  const { prompt } = props;
  return (
    <Flex spread column>
      <Box height={10}>
        <ProgressAnimation />
      </Box>
      <div>{prompt}</div>
    </Flex>
  );
};
