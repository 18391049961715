import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components/Box';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { Button } from 'DesignSystem/Form';
import { Popover } from 'DesignSystem/Components/Popover';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { DateTime } from 'luxon';
import { DismissType } from 'shared/ClickDropdown';
import { Body } from 'DesignSystem/Typography';
import { MAIcon } from 'shared/MAIcon';
import { ScheduleContentEdit } from './ScheduleContentEdit';
import style from './schedule-content.module.css';

// The `light` option is used from the Publisher New Editors
// It can go away if/when the orchestrate and publisher footers merge.
export const ScheduleContent: React.FC<{
  saveAsDraft?: () => void;
  disabled?: boolean;
  light?: boolean;
}> = ({ saveAsDraft, disabled = false, light = false }) => {
  const { settings, update } = useSettings();

  const onDatesChange = React.useCallback(
    (publishedAt: DateTime | undefined, archiveAt: DateTime | undefined) => {
      return update({ publishedAt, archiveAt });
    },
    [update]
  );
  const { publishedAt, archiveAt, publicationState } = settings;

  const dropdownRenderProp = React.useCallback(
    (dismiss: DismissType) => (
      <Box width={512} style={{ cursor: 'auto', position: 'relative' }}>
        <Popover centered padding={0} className={style.SchedulePopup}>
          <Body>
            <ScheduleContentEdit
              publicationState={publicationState}
              saveAsDraft={
                publicationState === 'scheduled' ? saveAsDraft : undefined
              }
              publishedAt={publishedAt}
              archiveAt={archiveAt}
              onDatesChange={onDatesChange}
              dismiss={dismiss}
            />
          </Body>
        </Popover>
      </Box>
    ),
    [archiveAt, onDatesChange, publishedAt, saveAsDraft, publicationState]
  );

  const scheduleLabel = React.useMemo(() => {
    if (publishedAt) {
      const formattedPublishedAt = publishedAt.toFormat('ff');
      return publishedAt <= DateTime.now() && publicationState === 'published'
        ? `Published: ${formattedPublishedAt}`
        : `Scheduled: ${formattedPublishedAt}`;
    }

    return 'Schedule For Later';
  }, [publishedAt, publicationState]);

  return (
    <Box margin={[0, 24]}>
      <ClickDropdown
        disabled={disabled}
        dropdownRenderProp={dropdownRenderProp}
        placement="top-end"
      >
        <Button
          disabled={disabled}
          text={light}
          layoutOnly={!light}
          className={light ? '' : style.Button}
          label={
            <>
              <Flex>
                <Box className={style.Icon}>
                  <MAIcon name="calendar_today" />
                </Box>
              </Flex>
              <Flex>
                <Box className={style.Label}>
                  {scheduleLabel}
                  {light && <MAIcon name="expand_less" />}
                </Box>
              </Flex>
            </>
          }
        />
      </ClickDropdown>
    </Box>
  );
};
