import * as React from 'react';
import { LinkFieldData } from 'models/publisher/block';
import { FieldFormProps } from '../../useFieldForm';
import { Panel } from '../shared/Panel';
import { Current } from './components/Current';
import { List } from './components/List';
import { useLinksSourceMenu } from './hooks/useSourceMenu';
import { useLinks } from './hooks/useLinks';

export const Links: React.FC<FieldFormProps<LinkFieldData[]>> = (props) => {
  const source = useLinksSourceMenu();
  const links = useLinks(props, source);

  return (
    <Panel
      title="Links Collection"
      description="Display custom links to your program"
      helperHref="https://support.firstup.io/hc/en-us/articles/4414499292311-Create-a-Link-Block"
    >
      <List
        source={source}
        links={links.links}
        onEdit={links.openEditorByIndex}
        onUpdate={links.listUpdate}
        onClearAll={links.clearList}
      />
      {links.current ? (
        <Current
          initial={links.current}
          onSave={links.applyEdits}
          onCancel={links.closeEditor}
          source={source}
        />
      ) : null}
    </Panel>
  );
};
