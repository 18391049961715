import { useQuery } from 'react-query';
import { fetchBulkUploadById } from 'services/api-bulk-upload';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { QueryResponse } from './common';

export const useBulkUploadQuery = (
  programId: number,
  id: string
): QueryResponse<BulkUploadJob> => {
  const { isLoading, error, data } = useQuery<BulkUploadJob, Error>({
    queryKey: `bulk-upload-${programId}-${id}`,
    queryFn: () => fetchBulkUploadById(programId, id),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};
