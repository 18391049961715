import { Tooltip } from 'DesignSystem/Components';
import React from 'react';
import styles from './overlay.module.css';

type OverlayProps = {
  show: boolean;
  hoverTooltipContent?: string;
};

const OverlayPanel: React.FC<OverlayProps> = ({
  show,
  hoverTooltipContent,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.overlayPanel}>
      <Tooltip content={hoverTooltipContent} placement="top">
        <div className={styles.overlayInner} />
      </Tooltip>
    </div>
  );
};

type ContainerProps = {
  show: boolean;
  hoverTooltipContent?: string;
};

/**
 * `Overlay` is a functional React component that renders a semi-transparent overlay panel on top of the wrapped content. The primary use case is to block off content for the purpose of disabling it.
 * For accesibility reasons, you will still need to disable any interactive elements within the wrapped content.
 *
 * @example
 * // To render the Overlay component, import it and then use it like this:
 * <Overlay show={true}>
 *   // Add your content here
 * </Overlay>
 *
 * @param {boolean} props.show - A boolean flag to indicate whether the overlay panel should be displayed or not.
 * @param {string} props.hoverTooltipContent - A tooltip message to be displayed when the user hovers over the overlay panel.
 * @param {React.ReactNode} props.children - The child elements or components to be displayed within the container, typically the content to be disabled.
 * @returns {React.ReactElement} Returns a React element representing the container with an overlay panel.
 */
export const Overlay: React.FC<ContainerProps> = ({
  show,
  hoverTooltipContent,
  children,
}) => {
  return (
    <div className={styles.overlayContainer}>
      <OverlayPanel show={show} hoverTooltipContent={hoverTooltipContent} />
      {children}
    </div>
  );
};
