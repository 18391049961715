import * as React from 'react';
import { Poll } from 'models/poll';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import styles from './content-banner.module.css';

type PollBannerProps = { poll: Poll };
export const PollBanner: React.FC<PollBannerProps> = ({ poll }) => {
  const thumbnail = {
    title: poll.questionText,
    backgroundColor: '#b8a0b6',
  };

  return (
    <EmptyBanner thumbnail={thumbnail} menuItems={[]} rowId={`poll-${poll.id}`}>
      <div className={styles.header}>
        <h2 className={styles.title} title={poll.questionText}>
          {poll.questionText}
        </h2>
        <p className={styles.summary}>{poll.descriptionText}</p>
      </div>

      <div className={styles.author}>
        <div>
          <div className={styles.label}>Creator</div>
          <div className={styles.authorName}>
            {poll.contentAuthor.displayName}
          </div>
        </div>
      </div>
    </EmptyBanner>
  );
};
