import React from 'react';
import { Field, Fieldset } from 'shared/Fieldset';
import { AuthorAliaseSelect } from 'components/author-alias/AuthorAliasSelect';
import { useProgram } from 'contexts/program';
import { User } from 'models/user';
import { IdentityScope } from 'models/identity';
import { AliasData } from 'services/api-author-alias';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import {
  useAuthorAliasByIdsQuery,
  useCreateAuthorAlias,
} from 'hooks/author-alias';
import { useHasNoRestrictions } from './hooks/roles';

type PropsType = {
  selectedIds: string[];
  onChange: (value: IdentityScope[]) => void;
  role: User['role'];
  currentUser?: User;
};

export const AuthorSelector: React.FC<PropsType> = ({
  selectedIds,
  onChange,
  role,
  currentUser,
}) => {
  const { id: programId } = useProgram();
  const hasNoRestrictions = useHasNoRestrictions(`${role}`);
  const [selectedAliases, setSelectedAliases] = React.useState<AliasData[]>([]);

  const { data: aliases, isLoading } = useAuthorAliasByIdsQuery(
    programId,
    selectedIds.map((id) => Number(id))
  );

  React.useEffect(() => {
    if (isLoading || !aliases) return;
    setSelectedAliases(aliases);
    // eslint-disable-next-line
  }, [isLoading, selectedIds]);

  function onSelectionChange(selections: AliasData[]) {
    const newSelections: AliasData[] = [];
    const allSelections = [];

    selections.forEach((nAlias) => {
      if (nAlias.id.toString().includes(':')) {
        newSelections.push(nAlias);
      }

      allSelections.push(nAlias);
    });
    if (newSelections.length) {
      newSelections.forEach((alias) => {
        const nAlias = alias;
        nAlias.createdBy = currentUser?.id;
        createAuthorAlias(nAlias);
      });
    } else {
      const scopes: IdentityScope[] = selections.map((als) => {
        return { type: 'author_alias', name: `author_alias:${als.id}` };
      });
      setSelectedAliases(selections);
      onChange(scopes);
    }
  }

  function handleSuccessfulAliasCreation(alias: AliasData) {
    const selections = [...selectedAliases, alias];
    setSelectedAliases(selections);
    const scopes: IdentityScope[] = selections.map((als) => {
      return { type: 'author_alias', name: `author_alias:${als.id}` };
    });
    onChange(scopes);
  }

  const { create: createAuthorAlias } = useCreateAuthorAlias(programId, {
    onSuccess: (alias) => {
      handleSuccessfulAliasCreation(alias);
    },
  });

  return (
    <Fieldset>
      <div className={fieldsetStyles.fieldGroupContainer}>
        <Field label="Author Aliases">
          {hasNoRestrictions ? (
            <div>Selected role has access to all author aliases</div>
          ) : (
            <AuthorAliaseSelect
              selectedAliases={selectedAliases as AliasData[]}
              onSelectionChange={onSelectionChange}
              placeholder="Search author aliases"
            />
          )}
        </Field>
      </div>
    </Fieldset>
  );
};
