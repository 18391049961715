import React from 'react';
import cx from 'classnames';
import { User } from 'models/user';
import { Topic } from 'models/topic';
import avatar from 'assets/images/Avatars.png';
import { Icon } from 'shared/Icon';
import { TextInput } from 'shared/TextInput';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from 'hooks/feature-flags';
import { usePermissions } from 'contexts/permissions';
import { NamedValue } from 'models/feature-flag';
import { RoleSelect } from '../../../Shared/ValueSelect/Role';
import { ContributorTopicSelect } from '../../../Shared/ValueSelect/ContributorTopic';
import { roles } from '../../../constants';
import styles from '../../../user-profile.module.css';

type UserStatusType = {
  label: string;
  code: number;
  key: string;
};

const UserStatus: React.FC<{
  status: UserStatusType;
  userStatus?: UserStatusType;
}> = ({ status, userStatus }) => {
  const isChecked = userStatus ? status.code <= userStatus.code : false;
  const iconName = isChecked ? 'CheckCircleFill' : 'CloseCircleOutline';
  const iconClassName = isChecked && styles.activeIcon;

  return (
    <div className={styles.userStatusGroup}>
      <span className={cx(styles.userStatusIcon, iconClassName)}>
        <Icon iconName={iconName} iconType="SVG" />
      </span>
      <span className={styles.userStatusLabel}>{status.label}</span>
    </div>
  );
};

export const GeneralInfo: React.FC<{
  data: User;
  programId: number;
  onDataChange: (field: string, value: string) => void;
  onTopicChange: (value: Topic[], topicsById: { [key: string]: Topic }) => void;
}> = ({ data, programId, onDataChange, onTopicChange }) => {
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );

  const { data: featureFlags } = useProgramCustomizationsQuery(programId);
  const userNameDisabled = (featureFlags?.profilePrivacyByField
    ?.value as NamedValue[]).find((x) => x.name === 'username')?.hidden;
  const statuses = [
    { label: 'Created', key: 'created', code: 0 },
    { label: 'Invited', key: 'invited', code: 1 },
    { label: 'Registering', key: 'registering', code: 2 },
    { label: 'Registered', key: 'active', code: 3 },
  ] as UserStatusType[];

  const isContributor = data.role === 'channel_contributor';
  const userStatus = statuses.filter(
    (stat: UserStatusType) => data?.status === stat.key
  );

  const { canPromoteTo } = usePermissions();

  const roleOptions = roles.filter((role) => canPromoteTo.includes(role.value));

  return (
    <Section title="General Info" className={styles.userPage}>
      <Fieldset className={styles.slimFitFieldGroupContainer}>
        <div
          className={cx(
            fieldsetStyles.fieldGroupContainer,
            styles.slimFitFieldGroupContainer
          )}
        >
          <Field label="Profile Picture">
            <img
              className={styles.profilePicture}
              alt="user avatar"
              src={data.avatarUrl || avatar}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Status" className={cx('p-0', styles.statusFieldGroup)}>
            <div className={styles.statusWrapper}>
              {statuses.map((status) => (
                <UserStatus
                  key={status.code}
                  status={status}
                  userStatus={userStatus[0]}
                />
              ))}
            </div>
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="First name">
            <TextInput
              className={styles.textField}
              onChange={(value) => onDataChange('firstName', value)}
              value={data.firstName || ''}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Last name">
            <TextInput
              className={styles.textField}
              onChange={(value) => onDataChange('lastName', value)}
              value={data.lastName || ''}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Preferred name">
            <TextInput
              className={styles.textField}
              onChange={(value) => onDataChange('preferredName', value)}
              value={data.preferredName || ''}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Email">
            <TextInput
              className={styles.textField}
              onChange={(value) => onDataChange('email', value)}
              value={data.email || ''}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Username">
            <TextInput
              className={styles.textField}
              onChange={() => {}}
              value={data.username || ''}
              disabled={userNameDisabled}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Universal Identifier">
            <TextInput
              className={styles.textField}
              onChange={(value) => onDataChange('federatedIdentifier', value)}
              value={data.federatedIdentifier || ''}
            />
          </Field>
        </div>
      </Fieldset>
      {!showPermissionsUI?.value && (
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Role">
              <RoleSelect
                value={data.admin_permission || data.role}
                options={roleOptions}
                onChange={onDataChange}
              />
            </Field>
          </div>
        </Fieldset>
      )}
      {isContributor && (
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            {/* show this for only channel contibutors */}
            <Field label="Topic">
              <ContributorTopicSelect
                userTopics={data.contributorChannels || []}
                onChange={onTopicChange}
              />
            </Field>
          </div>
        </Fieldset>
      )}
    </Section>
  );
};
