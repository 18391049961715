import { useLocation } from '@reach/router';
import { useProgram } from 'contexts/program';
import {
  Filter,
  ParameterizedFilters,
  parameterizeFilters,
} from 'models/journeys/filter';
import { JourneyState } from 'models/journeys/journey';
import qs from 'qs';
import { useState } from 'react';
import { QueryParameters } from 'services/api-journey';

export const useFilters = (
  initialFilters: Filter[],
  initialSearchTerm = ''
): {
  filters: Filter[];
  searchTerm: string;
  setFilters: (filters: Filter[]) => void;
  setSearchTerm: (searchTerm: string) => void;
  queryParameters: QueryParameters;
  parameterizedFilters: ParameterizedFilters;
  path: string;
  tabState?: string;
} => {
  const { pathname } = useLocation();
  const { id: programId } = useProgram();
  const [filters, setFilters] = useState<Filter[]>([...initialFilters]);
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);

  const parameterizedFilters = parameterizeFilters(
    filters.filter((f) => f.active)
  );

  const pathParams = {
    ...parameterizedFilters,
    ...(searchTerm !== '' ? { search: searchTerm } : {}),
  };
  const tabState = pathname.split('/').pop();
  const query = qs.stringify(pathParams);
  const queryString = query ? `?${query}` : '';
  const newPath = `${pathname}${queryString}`;

  const queryParameters: QueryParameters = {
    programId,
    search: searchTerm,
    pageSize: 10,
    sortBy: 'created_at',
    sortDirection: 'desc',
  };

  if (tabState === 'archive') {
    parameterizedFilters.editing_state = ['archived'] as JourneyState[];
  } else if (tabState === 'current') {
    parameterizedFilters.editing_state =
      parameterizedFilters.editing_state ??
      ([
        'active',
        'initial',
        'paused',
        'stopped',
        'processing',
      ] as JourneyState[]);
  }

  return {
    filters,
    searchTerm,
    setFilters,
    setSearchTerm,
    queryParameters,
    path: newPath,
    parameterizedFilters: {
      ...parameterizedFilters,
      ...{ archive_state: tabState },
    },
    tabState,
  };
};
