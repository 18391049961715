import React from 'react';
import { Topic } from '../../../../../../../models/topic';
import { Section } from '../../TopicForm/components/SettingsView/common';
import { Option } from './Option';
import { useAudiencesQuery } from '../../../../../../../hooks/audience';
import { WhitePillClass } from '../../../../../../../DesignSystem/Components/Pill';
import { Pills } from '../../../../../../../DesignSystem/Components/Pills';

interface Props {
  topic: Topic;
}

export function Audience({ topic }: Props): JSX.Element {
  const { data: audience } = useAudiencesQuery(
    {
      name: topic.groupIds.length ? topic.groupIds : [''],
      programId: topic.programId || 0,
    },
    { enabled: !!topic.groupIds.length }
  );
  return (
    <Section title="Audience">
      <Option
        label="Audience"
        value={topic.targeted ? 'Targeted' : 'All Users'}
      />
      {topic.targeted && (
        <Pills
          values={audience || []}
          render={(value) => (
            <>
              {value.title || value.name}&nbsp;·&nbsp;{value.totalUsers}
            </>
          )}
          pillClassName={WhitePillClass}
        />
      )}
    </Section>
  );
}
