import * as React from 'react';
import { PersonalizedFieldsFile } from 'models/personalized-fields';
import styles from '../styles.module.css';

export const Tag: React.FC<{
  state: PersonalizedFieldsFile['state'];
  message: string;
}> = ({ state, message }) => {
  switch (state) {
    case 'complete':
      return <span className={styles.OKTag}>{message}</span>;
    case 'failed':
      return <span className={styles.ErrorTag}>{message}</span>;
    default:
      return <span>{message}</span>;
  }
};
