export interface IDashboardJson {
  id: string;
  contentUrl: string;
  name: string;
  description: string;
  previewImage: string;
  tags: Tableau.TableauTag;
}

export const transformJsonToTableauDashboard = (
  json: IDashboardJson
): Tableau.Dashboard => ({
  id: json.id,
  contentUrl: json.contentUrl,
  name: json.name,
  description: json.description,
  previewImage: json.previewImage,
  tags: json.tags,
});
