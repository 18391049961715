import cx from 'classnames';
import { DateTime, Interval } from 'luxon';
import { Option } from 'models/insight/json/filterJson';
import React, { useCallback, useMemo, useState } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { FilterDropdown } from 'shared/FilterDropdown';
import { capitalize } from 'utility/strings';
import styles from './dashboard-parameter.module.css';

const generateYearlyValues = (): Option[] => {
  const start = DateTime.now().startOf('month');
  const lastTwoYears = Interval.before(start, { years: 2 }).splitBy({
    years: 1,
  });
  return lastTwoYears.map((interval) => {
    const d = interval.start;
    return {
      value: d?.toLocaleString(DateTime.DATE_SHORT) ?? '',
      label: d?.year?.toString() ?? '',
    };
  });
};

export const generateMonthlyValues = (): Option[] => {
  const start = DateTime.now().startOf('month');
  const lastTwoYears = Interval.before(start, { years: 2 }).splitBy({
    months: 1,
  });
  return lastTwoYears.map((interval) => {
    const d = interval.start;
    return {
      value: d?.toLocaleString(DateTime.DATE_SHORT) ?? '',
      label: d?.toLocaleString({ month: 'long', year: 'numeric' }) ?? '',
    };
  });
};

const generateQuarterlyValues = (): Option[] => {
  const start = DateTime.now().startOf('month');
  const lastTwoYears = Interval.before(start, { years: 2 }).splitBy({
    months: 3,
  });
  return lastTwoYears.map((interval) => {
    const d = interval.start;
    return {
      value: d?.toLocaleString(DateTime.DATE_SHORT) ?? '',
      label: d ? `Q${d.quarter} ${d.year}` : '',
    };
  });
};

type DashboardParameterProps = {
  label: string;
  name: string;
  values: Option[];
  onChange: (name: string, value: string | number | boolean | Date) => void;
  selected?: string | number | boolean | Date;
};

const DashboardParameter: React.FC<DashboardParameterProps> = ({
  label,
  name,
  values,
  onChange,
  selected,
}) => {
  const [selectedValue, setSelectedValues] = useState<
    string | number | boolean | Date | undefined
  >(selected);
  const onParameterChanged = useCallback(
    (value: string[]) => {
      if (value.length > 1) {
        setSelectedValues(value[value.length - 1]);
        onChange(name, value[value.length - 1]);
      } else {
        setSelectedValues(value[0]);
        onChange(name, value[0]);
      }
    },
    [name, onChange]
  );

  return (
    <FilterDropdown
      onChange={onParameterChanged}
      options={values}
      label={label}
      multiSelect={false}
      selectedOptions={values.filter(
        (opt: Option) => selectedValue === opt.value
      )}
    />
  );
};

const dateOptions = {
  quarter: generateQuarterlyValues(),
  month: generateMonthlyValues(),
  year: generateYearlyValues(),
};

export const DateRangePickerContainer: React.FC<{
  onChange: (name: string, value: string | number | boolean | Date) => void;
  disabled: boolean;
}> = ({ onChange, disabled }) => {
  const [selectedGranularity, setSelectedGranularity] = useState<
    string | number | boolean | Date
  >('month');
  const lastMonth = DateTime.now().startOf('month').minus({ months: 1 });
  const [selectedStartDate, setSelectedStartDate] = useState<{
    name: string;
    value: string | number | boolean | Date;
  }>({
    name: 'time_range_start_date',
    value: lastMonth.minus({ months: 3 }).toLocaleString(DateTime.DATE_SHORT),
  });
  const [selectedEndDate, setSelectedEndDate] = useState<{
    name: string;
    value: string | number | boolean | Date;
  }>({
    name: 'time_range_end_date',
    value: lastMonth.toLocaleString(DateTime.DATE_SHORT),
  });
  const [selectedDateOptions, setSelectedDateOptions] = useState<Option[]>(
    dateOptions.month
  );

  const onParameterChanged = useCallback(
    (name: string, value: string | number | boolean | Date) => {
      if (name === selectedStartDate.name) {
        setSelectedStartDate({ name, value });
      } else if (name === selectedEndDate.name) {
        setSelectedEndDate({ name, value });
      }
      onChange(name, value);
    },
    [onChange, selectedStartDate.name, selectedEndDate.name]
  );

  const onGranularityChanged = useCallback(
    (name: string, value: string | number | boolean | Date) => {
      setSelectedGranularity(value);
      setSelectedDateOptions(dateOptions[value as keyof typeof dateOptions]);
      onChange(name, value);
    },
    [onChange]
  );

  const label = useMemo(() => {
    if (selectedStartDate.value && selectedEndDate.value) {
      const startLbl = selectedDateOptions.find(
        (o) => o.value === selectedStartDate.value
      )?.label;
      const endLbl = selectedDateOptions.find(
        (o) => o.value === selectedEndDate.value
      )?.label;
      if (startLbl && endLbl) {
        return `${startLbl} ${' vs.  '} ${endLbl}`;
      }
    }

    return '';
  }, [selectedStartDate.value, selectedEndDate.value, selectedDateOptions]);

  const reset = useCallback(() => {
    setSelectedStartDate({ name: 'time_range_start_date', value: '' });
    setSelectedEndDate({ name: 'time_range_end_date', value: '' });
  }, []);

  const dropdown = React.useMemo(() => {
    const granularityOptions = [
      { label: 'Quarterly', value: 'quarter' },
      { label: 'Yearly', value: 'year' },
      { label: 'Monthly', value: 'month' },
    ];
    return (
      <div className={styles.dropdown}>
        <div className={styles.dropdownRow}>
          <DashboardParameter
            label={capitalize(selectedGranularity as string)}
            name="time_range_granularity_param"
            values={granularityOptions}
            onChange={onGranularityChanged}
          />
        </div>
        <div className={styles.dropdownRow}>
          <DashboardParameter
            label="Start Date"
            name={selectedStartDate.name}
            values={selectedDateOptions}
            selected={selectedStartDate.value}
            onChange={onParameterChanged}
          />
          <span className={styles.delimiter}>{' vs.  '}</span>
          <DashboardParameter
            label="End Date"
            name={selectedEndDate.name}
            values={selectedDateOptions}
            selected={selectedEndDate.value}
            onChange={onParameterChanged}
          />
        </div>
        <div className={styles.dropdownRow}>
          <button className={styles.reset} onClick={reset} type="button">
            Reset
          </button>
          <button className={styles.done} type="button">
            Done
          </button>
        </div>
      </div>
    );
  }, [
    selectedGranularity,
    onGranularityChanged,
    selectedDateOptions,
    onParameterChanged,
    selectedStartDate,
    selectedEndDate,
    reset,
  ]);
  return (
    <ClickDropdown dropdownRenderProp={dropdown} disabled={disabled}>
      <div
        className={cx(styles.container, 'kai-flex-row', {
          [styles.active]: label.length,
        })}
      >
        <span className={styles.label}>{`Time Range: ${label}`}</span>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.append}
        >
          <path
            d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </ClickDropdown>
  );
};
