import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';
import { WithPermission } from 'shared/WithPermission';

export const Branding: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['configureExperienceSettingsAccess']}>
    <BossViewPage
      title="Branding"
      src="#user-experience"
      app="programs"
      returnUrls={['/configure/branding']}
    />
  </WithPermission>
);
