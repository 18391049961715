import * as React from 'react';
import cx from 'classnames';
import styles from './relative-range-panel.module.css';
import { RelativeRange } from './DateRange';

const getRelativeRanges = (
  allowFutureSection: boolean,
  headingFilter: (heading: string) => boolean
): { [key: string]: RelativeRange[] } => {
  const relativeRanges = {
    Days: [
      RelativeRange.build('today', allowFutureSection),
      RelativeRange.build('thisweek', allowFutureSection),
      RelativeRange.build('lastweek', allowFutureSection),
    ],
    Months: [
      RelativeRange.build('thismonth', allowFutureSection),
      RelativeRange.build('lastmonth', allowFutureSection),
      RelativeRange.build('past30days~', allowFutureSection),
      RelativeRange.build('past90days~', allowFutureSection),
      RelativeRange.build('thisquarter', allowFutureSection),
      RelativeRange.build('lastquarter', allowFutureSection),
    ],
    Years: [
      RelativeRange.build('thisyear', allowFutureSection),
      RelativeRange.build('lastyear', allowFutureSection),
      RelativeRange.build('past2years', allowFutureSection),
    ],
  };
  return Object.fromEntries(
    Object.entries(relativeRanges).filter((entry) => headingFilter(entry[0]))
  );
};

type RangeLinkPropsType = {
  selected: boolean;
  range: RelativeRange;
  onChange: (range: RelativeRange) => void;
};

const RangeLink = (props: RangeLinkPropsType) => {
  const { onChange, range, selected } = props;

  return (
    <div className={cx({ [styles.selected]: selected })}>
      <button
        className={cx({ [styles.selected]: selected })}
        onClick={() => onChange(range)}
        type="button"
      >
        {range.label}
      </button>
    </div>
  );
};

type PropsType = {
  className?: string;
  onChange: (range: RelativeRange) => void;
  allowFutureSelection: boolean;
  selectedPresetKey?: string;
  forceIgnoreYear?: boolean;
};

export const RelativeRangePanel: React.FC<PropsType> = (props) => {
  const {
    className,
    onChange,
    selectedPresetKey,
    allowFutureSelection,
    forceIgnoreYear,
  } = props;
  const relativeRangeFilter = (heading: string) => {
    if (forceIgnoreYear) {
      return heading !== 'Years';
    }
    return true;
  };

  const relativeRanges = getRelativeRanges(
    allowFutureSelection,
    relativeRangeFilter
  );

  return (
    <div
      className={cx(styles.panel, className, {
        [styles.inactive]: !RelativeRange.isValidKey(selectedPresetKey || ''),
      })}
    >
      {Object.keys(relativeRanges).map((group: string) => (
        <div key={group}>
          <strong>{group}</strong>
          {relativeRanges[group]?.map((range: RelativeRange) => (
            <RangeLink
              key={range.label}
              range={range}
              selected={selectedPresetKey === range.key}
              onChange={onChange}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
