import React from 'react';

import { Audience } from 'models/audience';
import { useAudienceUsersQuery } from 'hooks/audience';
import { useStickyResponse } from 'hooks/common';
import { useAudienceFacetsQuery } from 'hooks/facet';
import { useProgram } from 'contexts/program';
import { FacetSidebarDisplay } from './FacetSidebarDisplay';
import { facetNames } from './constants';

type PropsType = {
  audience: Audience;
};

export const FacetsSidebar: React.FC<PropsType> = (props) => {
  const { audience } = props;
  const { query = '' } = audience;
  const { id: programId } = useProgram();

  const { isLoading: usersLoading, data: users } = useStickyResponse(
    useAudienceUsersQuery(programId, query)
  );

  const { isLoading: facetsLoading, data: facets } = useStickyResponse(
    useAudienceFacetsQuery(programId, query, facetNames)
  );

  const { data: emptyExpression } = useStickyResponse({
    isLoading: usersLoading || facetsLoading,
    data: query === '' || query === '*',
  });

  return (
    <FacetSidebarDisplay
      emptyExpression={emptyExpression}
      displayCount={users?.totalObjects || 0}
      displayFacets={facets || []}
    />
  );
};
