import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { StyleEditorContext } from 'contexts/publisher/compose/style';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { CanvasBlockPanelContextProvider } from 'contexts/publisher/compose/canvas-block-panel';
import { CanvasStylePanelContextProvider } from 'contexts/publisher/compose/canvas-style-panel';
import { NavigationBlockerContextProvider } from 'contexts/publisher/compose/navigation-blocker';
import { CanvasGlobalPanelContextProvider } from 'contexts/publisher/compose/canvas-global-panel';
import {
  DesignPermission,
  DesignPermissionContext,
} from 'components/publisher/theme/Compose/DesignPermission';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { DragDropContext } from '../../blocks/dnd/DragDropContext';
import { DesignEditor } from './useDesignHooks';
import { menuItems, Sidebar } from '../Compose/Sidebar';

export type ComposeComponentProps = RouteComponentProps & {
  navigationComponent: React.ReactNode;
  designEditor: DesignEditor;
  designPermission: DesignPermission;
  omitMenuItems?: menuItems[];
};

export const DesignComponent: React.FC<ComposeComponentProps> = ({
  children,
  navigationComponent,
  designEditor,
  designPermission,
  omitMenuItems = ['card'],
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <PreviewContext.Provider value={designEditor.preview}>
        <StyleEditorContext.Provider value={designEditor.styleEditor}>
          <BlocksEditorContext.Provider value={designEditor.blocksEditor}>
            <DesignPermissionContext.Provider value={designPermission}>
              <DragDropContext>
                <NavigationBlockerContextProvider
                  value={designEditor.navigationBlocker}
                >
                  <CanvasBlockPanelContextProvider>
                    <CanvasStylePanelContextProvider>
                      <CanvasGlobalPanelContextProvider>
                        <Sidebar
                          omitMenuItems={omitMenuItems}
                          blocksEditor={designEditor.blocksEditor}
                          enablePreview={designEditor.enablePreview}
                        >
                          {children}
                        </Sidebar>
                        {navigationComponent}
                      </CanvasGlobalPanelContextProvider>
                    </CanvasStylePanelContextProvider>
                  </CanvasBlockPanelContextProvider>
                </NavigationBlockerContextProvider>
              </DragDropContext>
            </DesignPermissionContext.Provider>
          </BlocksEditorContext.Provider>
        </StyleEditorContext.Provider>
      </PreviewContext.Provider>
    </DndProvider>
  );
};
