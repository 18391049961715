import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useFlashMessage } from 'contexts/flasher';
import { Section } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import {
  GreenTintDarkPillClass,
  Gray10PillClass,
} from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useProgram } from 'contexts/program';
import { useCampaignVariableConfigurationsQuery } from 'hooks/campaign-variables';
import {
  CampaignVariable,
  populateDisplayNames,
} from 'models/campaign-variable';
import { CustomLabel } from './CustomLabel';
import styles from './styles.module.css';

export const AttributeManagement: React.FC<RouteComponentProps> = () => {
  const program = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const loadListError = React.useCallback(() => {
    setFlashMessage({
      message: 'Unable to fetch attributes list',
      severity: 'error',
    });
  }, [setFlashMessage]);

  const { data: campaignVariables } = useCampaignVariableConfigurationsQuery({
    onError: loadListError,
  });
  const [campaignVariablesState, setCampaignVariablesState] = React.useState<
    CampaignVariable[]
  >([]);

  React.useEffect(() => {
    if (campaignVariables) {
      // sort it by name
      populateDisplayNames(campaignVariables);

      const sortedCampaignVariables = campaignVariables.sort(
        (a: CampaignVariable, b: CampaignVariable) => {
          const aname = a.display_name ? a.display_name : a.name;
          const bname = b.display_name ? b.display_name : a.name;
          return aname > bname ? 1 : -1;
        }
      );

      setCampaignVariablesState(sortedCampaignVariables as CampaignVariable[]);
    }
  }, [campaignVariables]);

  const defaultText = (
    fallbackValue: CampaignVariable['fallback_value']
  ): string => {
    return fallbackValue
      ? `Default Value: ${fallbackValue}`
      : 'No default value set';
  };

  return (
    <FormPage
      title="Field Management"
      description="Manage user attributes and their default values for use within content."
      breadcrumbs={[
        { label: 'Configure', to: '../' },
        { label: 'Field Management' },
      ]}
    >
      <Section
        href={`/${program.id}/configure/attribute-management/edit`}
        title="Personalized Fields"
      >
        <table className={styles.ViewTable}>
          {campaignVariablesState.map((variable) => (
            <tr>
              <td key={variable.name}>
                <div className={styles.AttributeValue}>
                  <Text.Subheading semibold>
                    {variable.display_name}
                  </Text.Subheading>
                  <CustomLabel variable={variable} />
                </div>
                <div>
                  <Pills
                    values={[{ id: 1 }]}
                    render={() => (
                      <Text.Body>
                        {variable.active ? 'Included' : 'Excluded'}
                      </Text.Body>
                    )}
                    pillIcon={() =>
                      variable.active ? (
                        <Icon name="Check" />
                      ) : (
                        <Icon name="Hidden" />
                      )
                    }
                    pillClassName={
                      variable.active ? GreenTintDarkPillClass : Gray10PillClass
                    }
                    small
                    padding={[0, 8]}
                  />
                </div>
              </td>
              <td>
                <div className={styles.DefaultCell}>
                  <Text.Subheading>
                    {defaultText(variable.fallback_value)}
                  </Text.Subheading>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </Section>
    </FormPage>
  );
};
