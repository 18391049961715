import { useCallback } from 'react';
import { PollFieldData } from 'models/publisher/block';
import { usePollDataMutator } from './usePollDataMutator';

export function usePollQuestion({
  poll: { question },
  updatePoll,
}: ReturnType<typeof usePollDataMutator>): Pick<PollFieldData, 'question'> & {
  changeQuestion(to: string): void;
} {
  const changeQuestion = useCallback(
    (text = '') => updatePoll({ question: text }),
    [updatePoll]
  );
  return { question, changeQuestion };
}
