import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { InclusiveRule } from 'models/inclusive-language';
import {
  createInclusiveRule,
  fetchById,
  fetchInclusiveLanguageRules,
  FetchProps,
  InclusiveRulesCollectionData,
  updateInclusiveRule,
} from 'services/api-inclusive-language';
import {
  InfiniteQueryResponse,
  MutationOptions,
  nextPageToFetch,
  QueryResponse,
} from '../common';

// The use case for Language Guides has "dozens" of language rules.
// We are several orders of magnitude away from needing to
// deal with infinite streams of data, and this hook is provided
// only for compatibility with features that expect it.
//
// Unless you need pagination or querying support, you can
// reasonably fetch all languages rules in one request.
//
// To do that without the inifinite overhead of this function:
// @see useInclusiveLanguageQuery(programId)
export const useInclusiveLanguageInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<InclusiveRule> => {
  const { programId, query, pageSize = 100 } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<InclusiveRulesCollectionData, Error>(
    ['inclusive-rules-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchInclusiveLanguageRules({
        programId,
        query,
        page: pageParam as number,
        pageSize,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup?.meta && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? batch.data.map((d) => d.attributes) : []))
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

// Assumes a finite and manageable collection of rules.
export const useInclusiveLanguageQuery = (
  programId: FetchProps['programId']
): QueryResponse<InclusiveRule[]> => {
  const { data, isLoading, errorMessage } = useInclusiveLanguageInfiniteQuery({
    programId,
    pageSize: 20000,
  });

  return {
    isLoading,
    errorMessage,
    data,
  };
};

export const useInclusiveRuleQuery = (
  programId: number,
  id: number
): QueryResponse<InclusiveRule> => {
  const { isLoading, error, data } = useQuery<InclusiveRule, Error>({
    queryFn: () => fetchById(programId, id),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useUpdateInclusiveRule = (
  programId: number,
  { onSuccess, onError }: MutationOptions<InclusiveRule, Error> = {}
): {
  update: (inclusiveRule: InclusiveRule) => void;
} => {
  const update = (inclusiveRule: InclusiveRule) => {
    updateInclusiveRule(programId, inclusiveRule)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};

export const useCreateInclusiveRule = (
  programId: number,
  { onSuccess, onError }: MutationOptions<InclusiveRule, Error> = {}
): {
  create: (inclusiveRule: Partial<InclusiveRule>) => void;
} => {
  const create = (inclusiveRule: Partial<InclusiveRule>) => {
    createInclusiveRule(programId, inclusiveRule)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { create };
};

export const useArchiveInclusiveRule = (
  programId: number,
  { onSuccess, onError }: MutationOptions<InclusiveRule> = {}
): {
  archive: (inclusiveRule: InclusiveRule) => void;
} => {
  const queryClient = useQueryClient();
  const archive = (inclusiveRule: InclusiveRule) => {
    updateInclusiveRule(programId, { ...inclusiveRule, isArchived: true })
      .then((data) => {
        queryClient.invalidateQueries(['inclusive-rules-infinite']);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { archive };
};
