import { useState, useEffect } from 'react';

// A debouncer for Image urls.
//
// Pass it a `src`. It will pass it back.
// When that `src` changes, it will continue to return the original
// until the next image has loaded.
//
// This can be used to prevent flickers.
export function useChangingImgSrc(src: string): string {
  const [prior, setSrc] = useState(src);
  useEffect(() => {
    if (src !== prior) {
      const img = new Image();
      img.src = src;
      img.onload = () => setSrc(src);
    }
  }, [src, prior]);
  return prior;
}
