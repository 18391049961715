import { Edge as ReactFlowEdge } from 'reactflow';
import { BaseEdge, DecisionStep, Step } from 'models/journeys/journey';

const edgesFromDecisionStep = (step: DecisionStep): ReactFlowEdge[] =>
  step.next
    .sort((a, b) => a.order - b.order)
    .map((edge) => ({
      type: 'decisionEdge',
      label: edge.label,
      labelBgPadding: [8, 8],
      labelBgBorderRadius: 0,
      labelBgStyle: { stroke: '#ccc' },
      data: { label: edge.label },

      ...getEdgeData(step, edge),
    }));

export const edgesFromStep = (step: Step): ReactFlowEdge[] => {
  if (step.type === 'decision') {
    return edgesFromDecisionStep(step);
  }

  return step.next.map((edge) => ({
    type: 'baseEdge',
    ...getEdgeData(step, edge),
  }));
};

const getEdgeId = (step: Step, edge: BaseEdge) => {
  return `${step.id}-${edge.targetId}`;
};

const getEdgeData = (step: Step, edge: BaseEdge) => {
  return {
    id: getEdgeId(step, edge),
    source: step.id,
    target: edge.targetId,
  };
};
