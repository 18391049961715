import React from 'react';
import { Modal, Box } from 'DesignSystem/Components';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { useBulkSelect } from 'contexts/bulk-select';
import { UserBulkActionFilters } from 'hooks/common';
import { useUniqueId } from 'hooks/useUniqueId';
import * as Text from 'DesignSystem/Typography';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { useFlashMessage } from 'contexts/flasher';
import { useBulkUpdatePermissions } from 'hooks/roles';
import { IdentityScope } from 'models/identity';
import { Topic } from 'models/topic';
import { Audience } from 'models/audience';
import { Template } from 'models/library';
import { EmailAlias } from 'models/email-alias';
import { AliasData } from 'services/api-author-alias';
import { ActionSelector } from './ActionSelector';
import { PermissionsSelector } from './PermisssionsSelector';
import { TopicSelector } from './TopicSelector';
import { AudienceSelector } from './AudienceSelector';
import { TemplateSelector } from './TemplateSelector';
import { EmailAliasSelector } from './EmailAliasSelector';
import { AuthorAliasSelector } from './AuthorAliasSelector';
import { EditPermissionsConfirmModal } from './EditPermissionsConfirmModal';
import { useJobProgressFlashMessage } from '../Shared/progress-flasher';

import styles from './styles.module.css';

type PropsType = {
  filter: UserBulkActionFilters;
  close: () => void;
};
export const EditPermissionsModal: React.FC<PropsType> = ({
  filter,
  close,
}) => {
  const bulkSelectData = useBulkSelect();
  const setJobProgressFlashMessage = useJobProgressFlashMessage();
  const { setFlashMessage } = useFlashMessage();
  const [action, setAction] = React.useState<string>();
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [openConfirmModel, setOpenConfirmModel] = React.useState(false);
  const [topics, setTopics] = React.useState<Topic[]>([]);
  const [audiences, setAudiences] = React.useState<Audience[]>([]);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [emailAliases, setEmailAliases] = React.useState<EmailAlias[]>([]);
  const [authorAliases, setAuthorAliases] = React.useState<AliasData[]>([]);
  const { updateBulk } = useBulkUpdatePermissions({
    onSuccess: ({ id }) => {
      setJobProgressFlashMessage(id, bulkSelectData);
      bulkSelectData.clearBulkSelect();
      close();
    },
    onError: ({ message }) => {
      setFlashMessage({ severity: 'error', message });
    },
  });

  const onConfirm = () => {
    const scopes: IdentityScope[] = [];
    topics.forEach((topic) => {
      scopes.push({ type: 'topic', name: `topic:${topic.id}` });
    });

    audiences.forEach((audience) => {
      scopes.push({ type: 'audience', name: `audience:${audience.id}` });
    });

    templates.forEach((template) => {
      scopes.push({
        type: 'publisher_template',
        name: `publisher_template:${template.id}`,
      });
    });

    emailAliases.forEach((alias) => {
      scopes.push({
        type: 'sender_account',
        name: `sender_account:${alias.id}`,
      });
    });

    authorAliases.forEach((alias) => {
      scopes.push({
        type: 'author_alias',
        name: `author_alias:${alias.id}`,
      });
    });

    updateBulk({
      filter: {
        ...filter,
        query: filter?.search,
        ids: bulkSelectData.selectedIds,
        excludedIds: bulkSelectData.excludedIds,
      },
      scopes,
      bulkAction: action as 'add' | 'replace',
    });
  };

  const cancelButton = React.useRef(false);
  const containerId = useUniqueId();

  const disabled = (): boolean => {
    if (permissions.includes('topics') && topics.length === 0) return true;
    if (permissions.includes('audiences') && audiences.length === 0)
      return true;
    if (permissions.includes('templates') && templates.length === 0)
      return true;
    if (permissions.includes('email-aliases') && emailAliases.length === 0)
      return true;
    if (permissions.includes('author-aliases') && authorAliases.length === 0)
      return true;

    return permissions.length === 0 || !action;
  };

  return (
    <>
      {!openConfirmModel && (
        <Modal
          name="edit-permisssions-modal"
          width={960}
          innerPadding={0}
          onClick={(e) => {
            const contained = document
              .getElementById(containerId)
              ?.contains(e.target as HTMLElement);
            if (!contained || cancelButton.current) {
              bulkSelectData.cancel();
              close();
            }
          }}
        >
          <div id={containerId} className={styles.EditPermissionsModal}>
            <Box
              padding={24}
              style={{ borderBottom: '1px solid var(--color-gray10)' }}
            >
              <Flex spread>
                <Text.Subheading bold block>
                  Bulk Update Permissions
                </Text.Subheading>
                <Button
                  onClick={() => {
                    cancelButton.current = true;
                  }}
                  clearText
                  label={<Text.Heading>×</Text.Heading>}
                />
              </Flex>

              <Text.Body color={Text.color.gray60}>
                Update permissions for {bulkSelectData.selectedCount} users
              </Text.Body>
            </Box>
            <Box
              padding={[48, 116, 48, 116]}
              style={{ borderBottom: '1px solid var(--color-gray10)' }}
            >
              <Box margin={[0, 0, 32, 0]}>
                <ActionSelector onChange={setAction} />
              </Box>

              <Box margin={[0, 0, 32, 0]}>
                <PermissionsSelector
                  onChange={setPermissions}
                  value={permissions}
                />
              </Box>
              {permissions.includes('topics') && (
                <Box margin={[0, 0, 32, 0]}>
                  <TopicSelector value={topics} onChange={setTopics} />
                </Box>
              )}
              {permissions.includes('audiences') && (
                <Box margin={[0, 0, 32, 0]}>
                  <AudienceSelector value={audiences} onChange={setAudiences} />
                </Box>
              )}
              {permissions.includes('templates') && (
                <Box margin={[0, 0, 32, 0]}>
                  <TemplateSelector value={templates} onChange={setTemplates} />
                </Box>
              )}
              {permissions.includes('email-aliases') && (
                <Box margin={[0, 0, 32, 0]}>
                  <EmailAliasSelector
                    value={emailAliases}
                    onChange={setEmailAliases}
                  />
                </Box>
              )}
              {permissions.includes('author-aliases') && (
                <Box margin={[0, 0, 32, 0]}>
                  <AuthorAliasSelector
                    value={authorAliases}
                    onChange={setAuthorAliases}
                  />
                </Box>
              )}
            </Box>

            <Box margin={[16, 24]}>
              <Flex end>
                <Button
                  secondary
                  label="Cancel"
                  className="cancel-button"
                  onClick={() => {
                    cancelButton.current = true;
                  }}
                />
                <Space row size={16} />
                <Button
                  label="Confirm"
                  onClick={() => {
                    setOpenConfirmModel(true);
                  }}
                  icon={<SVGIcon name="Check" size={16} />}
                  disabled={disabled()}
                />
              </Flex>
            </Box>
          </div>
        </Modal>
      )}
      {openConfirmModel && (
        <EditPermissionsConfirmModal
          action={action as string}
          onConfirm={onConfirm}
          onCancel={() => {
            bulkSelectData.cancel();
            close();
          }}
          permissions={permissions}
          topics={topics}
          audiences={audiences}
          templates={templates}
          emailAliases={emailAliases}
          authorAliases={authorAliases}
          userCount={bulkSelectData.selectedCount}
        />
      )}
    </>
  );
};
