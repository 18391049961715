import { DateRange, RelativeRange } from 'shared/DateRangeInput/DateRange';

export type SelectOption = {
  value: string;
  label: string;
};

type BaseFilter<T extends keyof Filters> = {
  name: string;
  type: T;
  field: string;
  label: string;
  active: boolean;
};

export type SelectFilter = BaseFilter<'select'> & {
  options?: SelectOption[];
  value: string[];
};

export type DateFilter = BaseFilter<'date'> & {
  queryFields: string[];
  value?: DateRange;
};

type Filters = {
  select: SelectFilter;
  date: DateFilter;
};

export type Filter = Filters[keyof Filters];

export type ParameterizedFilters = { [key: string]: string | string[] };

type FilterProcessors = {
  [Key in keyof Filters]: (filter: Filters[Key]) => ParameterizedFilters;
};

export const parameterizeFilters = (filters: Filter[]): ParameterizedFilters =>
  filters.reduce(
    (res, filter) => ({
      ...res,
      ...parameterizeFilter(filter.type, filter),
    }),
    {}
  );

const parameterizeFilter = <T extends keyof Filters>(
  type: T,
  filter: Filters[T]
): ParameterizedFilters => {
  const processor = FILTER_PROCESSORS[type];
  return processor(filter);
};

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const FILTER_PROCESSORS: FilterProcessors = {
  select: (filter) =>
    filter.value.length
      ? {
          [filter.field]: filter.value,
        }
      : {},
  date: (filter) => {
    const query: ParameterizedFilters = {};
    if (!filter.active || !filter.value) {
      return query;
    }

    const range = filter.value.toAbsolute();
    const startValue = range.start.toFormat(DATE_FORMAT);
    const endValue = range.end.toFormat(DATE_FORMAT);

    const [startField, endField] = filter.queryFields;
    query[startField] = startValue;
    query[endField] = endValue;

    return query;
  },
};

export const defaultFilters: Filter[] = [
  {
    name: 'creator',
    type: 'select',
    field: 'creatorIds',
    label: 'Creator',
    value: [],
    active: true,
  },
  {
    name: 'controlType',
    type: 'select',
    field: 'controlTypes',
    label: 'Control Type',
    options: [
      { value: 'remove', label: 'Remove' },
      { value: 'replace', label: 'Replace' },
      { value: 'do_not_translate', label: 'Do not translate' },
    ],
    value: [],
    active: false,
  },
  {
    name: 'creationDate',
    type: 'date',
    field: 'createdAt',
    label: 'Creation Date',
    queryFields: ['createdAtStart', 'createdAtEnd'],
    value: RelativeRange.build('past30days~'),
    active: false,
  },
];
