import React from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Body, color, Heading } from 'DesignSystem/Typography';
import cx from 'classnames';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './section-container.module.css';

interface SectionContainerProps {
  caption?: string;
  title?: string;
  icon?: string;
  smallHeader?: boolean;
  headerBottomBorder?: boolean;
  isLoading?: boolean;
}

export const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  caption,
  title,
  icon,
  smallHeader = false,
  headerBottomBorder = false,
  isLoading = false,
}) => {
  return (
    <Box
      className={styles.SectionContainer}
      style={!smallHeader && icon ? { backgroundImage: `url(${icon})` } : {}}
    >
      {isLoading && (
        <Flex center className={styles.overlay}>
          <LoadingSpinner />
        </Flex>
      )}
      <Box
        className={cx({
          [styles.SmallHeader]: smallHeader,
          [styles.BigHeader]: !smallHeader,
          [styles.HeaderBottomBorder]: headerBottomBorder,
        })}
      >
        <Box margin={[0, 0, !caption ? 32 : 0, 0]}>
          <Heading bold>{title}</Heading>
        </Box>
        {caption && (
          <Box className={styles.caption}>
            <Body color={color.gray60}>{caption}</Body>
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
};
