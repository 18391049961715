import React from 'react';
import { DateTime } from 'luxon';

import { ImmediateTrigger, ScheduledTrigger } from 'models/journeys/journey';
import {
  ComplexExpression,
  defaultComplexExpression,
  expressionToText,
} from 'models/expression';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Caption, Text } from 'DesignSystem/Typography';
import { useAudienceUsersQuery } from 'hooks/audience';
import { useProgram } from 'contexts/program';
import { Box } from 'DesignSystem/Components';
import { WarningTriangle } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { pluralize } from 'utility/text';
import { isEveryone as everyone, parseCriterion } from './utils';
import { editableTextToQuery } from '../../../JourneyDrawer/shared/AudienceSelect';
import styles from '../../journey-canvas-header.module.css';

export const OneTimeBody: React.FC<{
  trigger: ImmediateTrigger | ScheduledTrigger;
  criterion?: ComplexExpression;
}> = ({ trigger, criterion }) => {
  const { id: programId } = useProgram();
  const query = editableTextToQuery(
    expressionToText(
      criterion ?? defaultComplexExpression(defaultComplexExpression())
    )
  );
  const isEveryone = !criterion || everyone(criterion);
  const { isLoading, data: audienceUsers } = useAudienceUsersQuery(
    programId,
    query,
    {
      enabled: isEveryone,
    }
  );

  const startDate =
    (trigger.type === 'scheduled' &&
      trigger?.date?.toLocaleString(DateTime.DATE_MED)) ||
    (trigger.type === 'immediate' &&
      DateTime.now().toLocaleString(DateTime.DATE_MED));
  const parsedCriterion = !isEveryone && criterion && parseCriterion(criterion);

  if (isLoading)
    return (
      <Flex>
        <LoadingSpinner />
      </Flex>
    );

  return (
    <>
      <Flex column alignStart>
        <Text
          className={{ Semibold: true }}
          style={{ textTransform: 'capitalize' }}
        >
          Start Type: One-Time, {trigger.type}
        </Text>
        {startDate && (
          <Text className={{ Semibold: true }}>Start Date: {startDate}</Text>
        )}
        {isEveryone ? (
          <Text className={{ Semibold: true }}>Members: Everyone</Text>
        ) : (
          parsedCriterion && (
            <Text className={{ Semibold: true }}>
              Filter Members: {parsedCriterion.count}{' '}
              {pluralize(parsedCriterion.count, 'filter')} applied
            </Text>
          )
        )}
      </Flex>
      {isEveryone && audienceUsers && (
        <Box margin={[8, 0, 0, 0]} className={styles.modalPublishWarning}>
          <WarningTriangle />
          <Caption>
            This journey will include all {audienceUsers.totalObjects} users in
            your organization
          </Caption>
        </Box>
      )}
    </>
  );
};
