import React from 'react';
import { usePortal } from 'hooks/usePortal';
import { createPortal } from 'react-dom';
import { TemplateSelector } from 'shared/TemplateSelector';
import { Sidebar } from 'shared/TemplateSelector/Sidebar';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { NavigationFooter } from 'shared/NavigationFooter';
import { SplitFooter } from 'App/Program/Editors/Publisher/Footer/SplitFooter';
import { Footer } from 'App/Program/Editors/Publisher/Footer';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { titleCase } from 'utility/text';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import { Template } from 'models/library';
import styles from './templates-modal.module.css';

export const TemplatesModal: React.FC<{
  hide: () => void;
  onSelect?: (template: Template) => void;
}> = ({ hide, onSelect }) => {
  const portal = usePortal('campaign-templates');

  const { id: programId } = useProgram();
  const { PAGES } = useProductVocabulary();

  const { data: showStudioPublishNewEditors } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  );

  let FooterElement = NavigationFooter;
  if (showStudioPublishNewEditors?.value) {
    FooterElement = SplitFooter;
  } else {
    FooterElement = Footer;
  }

  const footer = (
    <FooterElement
      leftMenuItems={{}}
      links={[
        {
          disabled: true,
          href: '#compose',
          name: titleCase(PAGES.PUBLISHER.COMPOSE),
        },
        {
          disabled: true,
          href: '#deliver',
          name: titleCase(PAGES.PUBLISHER.DELIVER),
        },
        {
          disabled: true,
          href: '#review',
          name: titleCase(PAGES.PUBLISHER.REVIEW),
        },
      ]}
      canPerformAction
      exitHandler={hide}
      hideMenu
      title="Untitled"
      action={() => {}}
      actionName="Choose Template"
      actionClassName={styles.chooseButton}
      // eslint-disable-next-line react/jsx-props-no-spreading
    />
  );

  const mainWrapperRef = React.useRef<HTMLDivElement>(null);

  return createPortal(
    <div className={styles.modal}>
      <MainEditor
        splitBars={!!showStudioPublishNewEditors?.value}
        header={
          showStudioPublishNewEditors?.value ? (
            <></>
          ) : (
            <h1 className="page-header">Create...</h1>
          )
        }
        main={
          <TemplateSelector onSelect={onSelect} parentRef={mainWrapperRef} />
        }
        sidebar={<Sidebar />}
        footer={footer}
        mainWrapperRef={mainWrapperRef}
      />
    </div>,
    portal.target
  );
};
