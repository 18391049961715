import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { useProgram } from 'contexts/program';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { Icon } from 'shared/Icon';
import { WithPermission } from 'shared/WithPermission';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { RolesList } from './RolesList';

export const RolesListPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const parentRef = React.useRef<HTMLDivElement>(null);

  const showStandardRoles = !!useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowStandardRoles'
  ).data?.value;
  const showCustomRoles = !!useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowCustomRoles'
  ).data?.value;

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    errorMessage,
  } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'primary',
  });

  let filteredRoles = data;
  if (data) {
    filteredRoles = data.filter((role) =>
      role.isSystemRole ? showStandardRoles : showCustomRoles
    );
  }

  const roleActions = [];
  if (showCustomRoles) {
    roleActions.push({
      label: 'Role',
      to: './new',
      icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
    });
  }
  return (
    <WithPermission permissions={['configureRoleAccess']}>
      <ListPage
        title="Roles"
        breadcrumbs={[{ to: '..', label: 'Configure' }, { label: 'Roles' }]}
        actions={roleActions}
      >
        {errorMessage && <>{errorMessage}</>}
        {filteredRoles && !errorMessage && (
          <InfiniteContainer parentRef={parentRef}>
            {(height: number) => (
              <RolesList
                parentRef={parentRef}
                errorMessage={errorMessage}
                isLoading={isLoading}
                roles={filteredRoles}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                height={height}
              />
            )}
          </InfiniteContainer>
        )}
      </ListPage>
    </WithPermission>
  );
};
