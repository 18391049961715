export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const RIGHT_KEY = 39;
export const DOWN_KEY = 40;
export const BACKSPACE_KEY = 8;
export const DELETE_KEY = 46;
export const ESCAPE_KEY = 27;
export const ENTER_KEY = 13;
export const SPACE_KEY = 32;

export type KeyEvent = KeyboardEvent | React.KeyboardEvent;

export function leftKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === LEFT_KEY;
}

export function rightKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === RIGHT_KEY;
}

export function upKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === UP_KEY;
}

export function downKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === DOWN_KEY;
}

export function backspaceKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === BACKSPACE_KEY;
}

export function deleteKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === DELETE_KEY;
}

export function escapeKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === ESCAPE_KEY;
}

export function enterKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === ENTER_KEY;
}

export function spaceKey(event: KeyEvent): boolean {
  return (event.keyCode || event.which) === SPACE_KEY;
}

export function whichKey(event: KeyEvent): number {
  return event.keyCode || event.which;
}
