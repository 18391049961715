import { Button, Icon } from '@socialchorus/shared-ui-components';
import React, { useCallback } from 'react';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { useImageUploader } from 'hooks/useImageUploader';
import { ImageData } from 'models/image';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { FileUploadButton } from '../FileUploadButton';
import styles from './styles.module.css';

type ImageInputProps = {
  aspectRatio?: number;
  disabled?: boolean;
  imageSrc: string | null | undefined;
  onChange: (val: { url: string } | null) => void;
};

export function ImageInput({
  aspectRatio,
  disabled,
  imageSrc,
  onChange,
}: ImageInputProps): React.ReactElement {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const uploader = useImageUploader({
    onUpload: (data: ImageData) => data.url && onChange({ url: data.url }),
    programId,
    onError: (message) => setFlashMessage({ message, severity: 'error' }),
  });

  const handleFileChange = useCallback<(file: File) => void>(
    (file) => {
      uploader.update(file);
    },
    [uploader]
  );

  if (uploader.isUploading) {
    return <LoadingSpinner />;
  }

  if (!imageSrc) {
    return (
      <FileUploadButton
        aspectRatio={aspectRatio}
        accept="image/*"
        type="button"
        onFileSelect={handleFileChange}
        disabled={disabled}
        leftIcon={<Icon>upload</Icon>}
        label="Upload Image"
      />
    );
  }

  return (
    <div className={styles.PreviewContainer}>
      <img src={imageSrc || ''} alt="uploaded" className={styles.Preview} />
      {imageSrc && (
        <Button
          variant="text"
          label="Remove"
          onClick={() => onChange(null)}
          disabled={disabled}
        />
      )}
    </div>
  );
}

ImageInput.defaultProps = {
  aspectRatio: undefined,
  disabled: false,
};
