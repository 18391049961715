import React from 'react';
import { Subsection } from 'DesignSystem/Form';
import { DefaultAvatar } from 'shared/icons';
import { AliasData } from 'services/api-author-alias';
import { ImageUploadInput } from './ImageUploadInput';
import styles from '../author-alias.module.css';

export const TeamAvatarSection: React.FC<{
  aliasData: Partial<AliasData>;
  onChange: (avatarUrl: string) => void;
}> = ({ aliasData: { avatarUrl, displayName }, onChange }) => {
  const base = { marginTop: '0' };
  return (
    <Subsection
      title="Display Avatar"
      description="(Optional) Will appear next to the display name in the member experience"
      divider={false}
    >
      <div
        className={styles.TeamAvatar}
        style={
          avatarUrl ? { ...base, backgroundImage: `url(${avatarUrl})` } : base
        }
        title={`${
          avatarUrl ? 'The ' : 'No'
        } avatar for the ${displayName} alias`}
      >
        {!avatarUrl && <DefaultAvatar />}
      </div>
      <ImageUploadInput
        buttonLabel={avatarUrl ? 'Change image' : 'Upload image'}
        onChange={onChange}
      />
    </Subsection>
  );
};
