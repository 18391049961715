import React, { useMemo } from 'react';
import { Thumbnail } from 'shared/Thumbnail';
import { ContentTopicsAndAudiences } from 'components/content/ContentBanner/ContentTopicsAndAudiences';
import { titleCase } from 'utility/text';
import { DateTime } from 'luxon';
import { Content } from 'models/content';
import contentBannerStyles from 'components/content/ContentBanner/content-banner.module.css';
import cx from 'classnames';
import { priorityLabel } from 'models/priority';
import styles from './content-banner.module.css';
import { UseCollection } from './useCollection';

export type ThumbnailType = React.ComponentProps<typeof Thumbnail>;
// This is defined in the CSS.  The height of the thumbnail is 100.  The padding is 15 both above
// and below.  The margin below is 10.
export const BANNER_HEIGHT = 158;

export type BannerProps = {
  thumbnail: ThumbnailType;
  rowId?: string;
  select: UseCollection['select'];
  selection: UseCollection['selection'];
  content: Content;
};

type PropsType = {
  children?: React.ReactNode;
  menuFooter?: React.ReactNode;
} & BannerProps;
/* eslint-disable react/jsx-props-no-spreading */

export const EmptyBanner: React.FC<PropsType> = ({
  thumbnail,
  children,
  content,
  select,
  selection,
}) => {
  const selected = useMemo(
    () => selection.some(({ id }) => id === content.id),
    [content, selection]
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cx(styles.empty, styles.selectable, {
        [styles.selectedItem]: selected,
      })}
      onClick={() => select(content, selected ? 'remove' : 'add')}
      role="button"
      tabIndex={0}
      data-test="banner-item"
    >
      <div aria-hidden="true" className={styles.checkmark}>
        {selected ? (
          <i className="far fa-check-square" />
        ) : (
          <i className="far fa-square" />
        )}
      </div>
      <div className={styles.icon}>
        <Thumbnail {...thumbnail} size="large" />
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  );
};

type ContentBannerProps = {
  content: Content;
  select: UseCollection['select'];
  selection: UseCollection['selection'];
};

export const ContentBanner: React.FC<ContentBannerProps> = ({
  content,
  select,
  selection,
}) => {
  const thumbnail = {
    title: content.title,
    backgroundColor: '#b8a0b6',
    imageUrl: content.imageUrl || undefined,
  };

  const topicsAndAudiences = content.contentTopics?.map((x) => {
    return {
      id: `channel-${x.id}`,
      name: x.name,
      count: x.followerCount,
    };
  });

  content.contentCommunication?.audiences.forEach((x) => {
    topicsAndAudiences?.push({
      id: `audience-${x.id}`,
      name: x.title,
      count: x.totalUsers,
    });
  });

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      rowId={content.id.toString()}
      content={content}
      select={select}
      selection={selection}
    >
      <div className={contentBannerStyles.header}>
        <h2 className={contentBannerStyles.title} title={content.title}>
          {content.title || content.summary}
        </h2>
        <div className={contentBannerStyles.publishedAt}>
          <span className={contentBannerStyles.strong}>
            {content.publishedAt
              ? DateTime.fromISO(content.publishedAt).toFormat('MMM d, hh:mm a')
              : 'Unscheduled draft '}
          </span>
          by&nbsp;
          <span className={contentBannerStyles.strong}>
            {content.contentAuthor.displayName}
          </span>
        </div>
        <div className={contentBannerStyles.priority}>
          {priorityLabel(content?.contentCommunication?.priority) || ''}
        </div>
      </div>
      <div className={contentBannerStyles.reachWrapper}>
        <div className={contentBannerStyles.state}>
          {titleCase(content.publicationState)}
        </div>
      </div>

      <div className={contentBannerStyles.details}>
        <ContentTopicsAndAudiences
          topicsAndAudiences={topicsAndAudiences || []}
        />
      </div>
    </EmptyBanner>
  );
};
