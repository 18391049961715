import * as React from 'react';
import { InfiniteSelect, InfiniteLoadProps } from 'shared/InfiniteSelect';
import { WorkflowTemplate } from 'models/workflow-templates';
import styles from './templates-select.module.css';

export type PropsType = {
  values: WorkflowTemplate[];
  selectedIds: string[];
  onSelectedIdsChange: (ids: string[]) => void;
  maxHeight?: number;
} & InfiniteLoadProps;

export const Dropdown: React.FC<PropsType> = (props) => {
  const {
    values,
    selectedIds,
    onSelectedIdsChange,
    maxHeight = 400,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
  } = props;

  const rowIds: string[] = values.map(({ id }) => String(id) || '');

  const rowRenderProp = (id: string) => {
    const template = values.find((kek) => String(kek.id) === id);
    if (template) {
      const { name, description } = template;
      return (
        <div key={id} className={styles.row}>
          <div className={styles.title}>{name}</div>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.dropdown}>
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={rowRenderProp}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        itemHeight={60}
        maxHeight={maxHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        threshold={threshold}
        overscan={overscan}
      />
    </div>
  );
};
