import * as React from 'react';
import { fontAuthor, FontStylesheet, isGlobalItem } from 'models/library';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { useLibraryDisable, useLibraryEnable } from 'hooks/useLibrary';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFlashMessage } from 'contexts/flasher';
import { ItemType as MenuItemType } from 'shared/hover-dropdown/HoverIconMenu';
import { MenuItemsType, EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { LoadingSpinner } from 'shared/LoadingSpinner';

import { Flex } from 'DesignSystem/Layout/Flex';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { usePermissions } from 'contexts/permissions';
import styles from './font-banner.module.css';
import { FontPreview } from './FontPreview';

type PropsType = {
  font: FontStylesheet;
  invalidateQuery?: () => void;
  customFontsEnabled?: boolean;
};

export const FontBanner: React.FC<PropsType> = ({
  font,
  invalidateQuery,
  customFontsEnabled,
}) => {
  const { id: programId } = useProgram();
  const { data: permissionsService, isLoading } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Service'
  );

  const { permissions } = usePermissions();
  const { setFlashMessage } = useFlashMessage();

  const onSuccess = React.useCallback(
    (t) => {
      if (invalidateQuery) invalidateQuery();
      setFlashMessage({
        severity: 'info',
        message: `Font ${
          t.is_enabled_for_program === true ? 'unarchived' : 'archived'
        }`,
      });
    },
    [invalidateQuery, setFlashMessage]
  );

  const { mutate: enable, isWorking: isEnabling } = useLibraryEnable(onSuccess);

  const { mutate: disable, isWorking: isDisabling } = useLibraryDisable(
    onSuccess
  );

  const changeFontStatus = React.useCallback(() => {
    if (font.is_enabled_for_program) {
      disable(font);
    } else {
      enable(font);
    }
  }, [enable, disable, font]);

  const createdBy = fontAuthor(font);

  const isEditable = React.useMemo(() => {
    const editableByPermission =
      (!isLoading && !permissionsService?.value) ||
      permissions.libraryFontsAccess;
    const editableByType = !isGlobalItem(font);

    return editableByPermission && editableByType;
  }, [
    font,
    isLoading,
    permissions.libraryFontsAccess,
    permissionsService?.value,
  ]);

  const menuItems: MenuItemsType = React.useMemo(() => {
    const items = [] as Array<MenuItemType>;

    if (customFontsEnabled) {
      items.push({
        title: 'Edit',
        href: isEditable ? `/${programId}/edit/font/${font.id}` : undefined,
        onClick: isEditable ? undefined : () => {},
      });

      if (font.status === 'published') {
        items.push({
          title: `${
            font.is_enabled_for_program ? 'Archive' : 'Unarchive'
          } for my community`,
          onClick: changeFontStatus,
        });
      }
    }

    return items;
  }, [
    customFontsEnabled,
    isEditable,
    programId,
    font.id,
    font.status,
    font.is_enabled_for_program,
    changeFontStatus,
  ]);

  const availableStyles = React.useMemo(() => {
    let values: Array<string> = [];
    if (isGlobalItem(font)) {
      values = ['Normal', 'Bold', 'Italic', 'Bold Italic'];
    } else {
      const { fonts } = font.asset;
      if (fonts.find((f) => f.style === 'normal' && f.weight === 400)) {
        values.push('Normal');
      }
      if (fonts.find((f) => f.style === 'italic' && f.weight === 400)) {
        values.push('Italic');
      }
      if (fonts.find((f) => f.style === 'normal' && f.weight === 700)) {
        values.push('Bold');
      }
      if (fonts.find((f) => f.style === 'italic' && f.weight === 700)) {
        values.push('Bold Italic');
      }
    }

    return values.join(', ');
  }, [font]);

  const dropdownRenderProp = React.useCallback(() => {
    return <FontPreview font={font} />;
  }, [font]);

  return (
    <EmptyBanner hideFirstMenuItem={!isEditable} menuItems={menuItems}>
      <div className={styles.wrapper}>
        <div className={styles.col}>
          <HoverDropdown
            closeDelay={10}
            dropdownRenderProp={dropdownRenderProp}
          >
            <Flex
              style={{ fontFamily: font.asset.value }}
              className={styles.icon}
            >
              {font.title.substring(0, 2)}
            </Flex>
          </HoverDropdown>
        </div>
        <div className={styles.fontData}>
          <div className={cx(styles.col, styles.title)}>
            {(isEnabling || isDisabling) && <LoadingSpinner size="small" />}
            {font.title}
          </div>
          <div className={cx(styles.col, styles.styles)}>
            <div className={styles.colHeader}>Styles</div>
            <div className={styles.colData}>{availableStyles}</div>
          </div>
          <div className={cx(styles.col, styles.creator)}>
            <div className={styles.colHeader}>Creator</div>
            <div className={styles.colData}>{createdBy}</div>
          </div>
          <div className={cx(styles.col, styles.status)}>
            <div className={styles.colHeader}>Status</div>
            <div className={styles.colData}>
              {font.is_enabled_for_program ? 'published' : 'archived'}
            </div>
          </div>
        </div>
      </div>
    </EmptyBanner>
  );
};
