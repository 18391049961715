type SlugFormValidationAttrs = {
  errorMessage: string | undefined;
  isValid: boolean;
};

const errorTypes = {
  isEmpty: (value: string, message: string): string | undefined => {
    return value.trim().length === 0 ? message : undefined;
  },
  invalidFormat: (value: string): string | undefined => {
    const isValid = /^([a-zA-Z0-9-\s]+)$/.test(value);

    return !isValid
      ? 'Ensure that your input contains a combination of letters and numbers only'
      : undefined;
  },
  invalidLength: (value: string): string | undefined => {
    const isValid = value.length <= 210;

    return !isValid
      ? 'The field cannot exceed 210 characters. Please shorten your input'
      : undefined;
  },
  onlyNumbers: (value: string): string | undefined => {
    const invalid = /^([\d\s]+)$/.test(value);

    return invalid ? 'Field cannot be only numbers' : undefined;
  },
};

const validateSlug = (slug: string): SlugFormValidationAttrs => {
  const errorCatched =
    errorTypes.isEmpty(slug, 'Enter some custom link text') ||
    errorTypes.invalidFormat(slug) ||
    errorTypes.onlyNumbers(slug) ||
    errorTypes.invalidLength(slug);

  return {
    errorMessage: errorCatched,
    isValid: errorCatched === undefined,
  };
};

const validateName = (name: string): SlugFormValidationAttrs => {
  const errorCatched =
    errorTypes.isEmpty(name, 'Custom link must have a description') ||
    errorTypes.invalidLength(name);
  return {
    errorMessage: errorCatched,
    isValid: errorCatched === undefined,
  };
};

export { validateSlug, validateName };
