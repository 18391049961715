import React, { useState } from 'react';
import {
  useFloating,
  flip,
  shift,
  Placement,
  useHover,
  offset as offsetMiddleware,
  safePolygon,
  useInteractions,
  FloatingPortal,
  useFocus,
} from '@floating-ui/react';
import cx from 'classnames';
import styles from './tooltip.module.css';

type PropsType = {
  id?: string;
  content: React.ReactNode;
  showDelay?: number;
  hideDelay?: number;
  className?: string;
  placement?: Placement;
  children: React.ReactNode;
  offset?: number;
  border?: 'rounded' | 'square';
  size?: 'small' | 'large';
  type?: 'dark' | 'light' | 'blank';
  maxWidth?: number;
  disabled?: boolean;
};

/**
 * @description Tooltip Component
 * @param content {React.ReactNode} The content of the tooltip
 * @param children {React.ReactNode} The hover triggered component
 * @param showDelay {number} delay in ms to display the tooltip after hovering it. Default: 500ms
 * @param hideDelay {number} delay in ms to hide the tooltip after leaving it. Default: 500ms
 * @param className {string} class to apply to content (optional)
 * @param offset {number} offset from the reference component to the floating component
 * @param border {string} type of border to apply. Default: rounded
 * @param disabled {boolean} disable tooltip
 * @param size {string} size of the tooltip. Default: small.
 * @param type {string} style (dark, light, blank) of the content. Blank applies no style.
 * @param maxWidth {number} optional max width
 */
export const Tooltip: React.FC<PropsType> = ({
  id,
  children,
  content,
  showDelay,
  hideDelay,
  placement,
  offset,
  className,
  border,
  size,
  type,
  maxWidth,
  disabled = false,
}: PropsType) => {
  const [isVisible, setIsVisible] = useState(false);

  const {
    x,
    y,
    reference: referenceElement,
    floating: popperElement,
    strategy,
    context,
  } = useFloating<HTMLElement>({
    open: isVisible,
    onOpenChange: setIsVisible,
    placement,
    middleware: [flip(), offsetMiddleware(offset), shift({ padding: 10 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: { open: showDelay, close: hideDelay },
      handleClose: safePolygon({ buffer: 10 }),
    }),
    useFocus(context),
  ]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...getReferenceProps({ ref: referenceElement }),
      });
    }
    return child;
  });

  if (disabled) return <>{children}</>;

  return (
    <>
      {childrenWithProps}
      <FloatingPortal>
        {isVisible && (
          <div
            id={id}
            className={cx(styles.Tooltip, className, {
              ...(type !== 'blank'
                ? {
                    [styles[`border-${border}`]]: true,
                    [styles[`size-${size}`]]: true,
                    [styles[`type-${type}`]]: true,
                  }
                : {}),
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFloatingProps({
              ref: popperElement,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
                ...(maxWidth ? { maxWidth } : {}),
              },
            })}
          >
            {content}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

Tooltip.defaultProps = {
  id: undefined,
  showDelay: 300,
  hideDelay: 0,
  placement: 'bottom',
  offset: 10,
  className: undefined,
  border: 'rounded',
  size: 'small',
  type: 'dark',
  maxWidth: undefined,
  disabled: false,
};
