import * as React from 'react';
import { Button, FieldInput, Input } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';

export const External: React.FC<{
  onChange(url: string): void;
  onCancel(): void;
}> = ({ onCancel, onChange }) => {
  const [url, setUrl] = React.useState('');
  const apply = React.useCallback(() => {
    if (url) onChange(url);
    setUrl('');
  }, [url, setUrl, onChange]);
  return (
    <Box padding={[16, 0, 0]}>
      <FieldInput
        htmlFor="publisher-block-gallery-image-url"
        legend="Image URL"
        data-test="external-image"
      >
        <Input
          id="publisher-block-gallery-image-url"
          block
          value={url}
          placeholder="URL Required"
          onChange={setUrl}
        />
        <Box margin={[10, 0, 0]}>
          <Flex spread>
            <Button text onClick={onCancel} label="Cancel" />
            <Button disabled={!url} onClick={apply} label="Add Image" />
          </Flex>
        </Box>
      </FieldInput>
    </Box>
  );
};
