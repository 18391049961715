import { useNavigate } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import {
  useUpdateInitiative,
  useCreateInitiative,
  useArchiveInitiative,
  useUnarchiveInitiative,
  useDestroyInitiative,
} from 'hooks/initiatives';
import { Initiative } from 'models/initiative';
import { useFlashServerErrors } from 'utility/errors';

export const useActions = (
  setInitiative?: (initiative: Initiative) => void
): {
  archive: (initiative: Initiative) => void;
  unarchive: (initiative: Initiative) => void;
  destroy: (initiative: Initiative) => void;
  update: (initiative: Initiative) => void;
  create: (initiative: Partial<Initiative>) => void;
} => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const initiativeListUrl = `/${programId}/configure/initiatives`;

  const { update } = useUpdateInitiative(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Initiative updated successfully',
      });
      navigate(initiativeListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not update initiative');
    },
  });

  const { create } = useCreateInitiative(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Initiative created successfully',
      });
      navigate(initiativeListUrl);
    },
    onError: (error) => {
      flashServerErrors(error, 'Could not create initiative');
    },
  });

  const { archive } = useArchiveInitiative(programId, {
    onSuccess: (data) => {
      setInitiative?.(data);
      setFlashMessage({
        severity: 'info',
        message: 'Initiative archive successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update initiative',
      }),
  });

  const { unarchive } = useUnarchiveInitiative(programId, {
    onSuccess: (data) => {
      setInitiative?.(data);
      setFlashMessage({
        severity: 'info',
        message: 'Initiative unarchive successfully',
      });
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update initiative',
      }),
  });

  const { destroy } = useDestroyInitiative(programId, {
    onSuccess: () => {
      setFlashMessage({
        severity: 'info',
        message: 'Initiative deleted successfully',
      });
      navigate(initiativeListUrl);
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not delete initiative',
      }),
  });

  return { archive, unarchive, update, create, destroy };
};
