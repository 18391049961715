import * as React from 'react';
import { DateTime } from 'luxon';
import {
  DateFieldData,
  dateToField,
  fieldToDate,
} from 'models/publisher/block';
import { Fieldset, Field } from 'shared/Fieldset';
import { DateTimeInput } from 'shared/DateTimeInput';
import { ClickDropdown } from 'shared/ClickDropdown';
import styles from './date-time.module.css';
import { FieldProps } from '../FieldProps';

export const DateTimeField: React.FC<FieldProps<DateFieldData>> = ({
  field,
  data,
  default_data,
  onChange,
}) => {
  const datetime = React.useMemo(() => fieldToDate(data || default_data), [
    data,
    default_data,
  ]);

  const dropdownRenderProp = () => (
    <DateTimeInput
      value={datetime}
      onChange={(updated: DateTime) => {
        onChange(dateToField(updated));
      }}
    />
  );

  return (
    <Fieldset>
      <Field label={field.display_name}>
        <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
          <div className={styles.trigger}>
            {datetime.toFormat('dd LLL, hh:mma')}
          </div>
        </ClickDropdown>
      </Field>
    </Fieldset>
  );
};
