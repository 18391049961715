import React from 'react';
import DOMPurify from 'dompurify';
import { Section, Button } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import cx from 'classnames';
import { Modal } from 'shared/Modal';

import styles from './legal.module.css';

export const PolicySection: React.FC = () => {
  const program = useProgram();
  const ref = React.useRef<HTMLDivElement>(null);
  const [showMoreButton, setShowMoreButton] = React.useState(false);
  const [showModel, setShowModel] = React.useState(false);
  const html = DOMPurify.sanitize(program.privacyPolicy || '', {
    USE_PROFILES: { html: true },
    ADD_ATTR: ['target'],
  });
  React.useEffect(() => {
    if (ref.current) {
      if (ref.current.scrollHeight > ref.current.clientHeight) {
        setShowMoreButton(true);
      }
    }
  }, [ref]);

  return (
    <Section
      title="Privacy Policy"
      href={`/${program.id}/configure/legal/policy`}
    >
      {!program.privacyPolicyLink && !program.privacyPolicy && (
        <div>No Privacy Policy</div>
      )}
      {program.privacyPolicyFormat === 'link' && program.privacyPolicyLink && (
        <a className={styles.Link} href={program.privacyPolicyLink}>
          {program.privacyPolicyLink}
          <SVGIcon name="ExternalLink" />
        </a>
      )}
      {program.privacyPolicyFormat === 'text' && html && (
        <>
          <div
            ref={ref}
            className={cx(styles.RichTextBlock, styles.LimitedHeightBlock)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {showMoreButton && (
            <div className={styles.ShowAllBtn}>
              <Button
                onClick={() => {
                  setShowModel(true);
                }}
                label="Show All"
                block
                secondary
              />
              <Modal
                title="Privacy Policy"
                showTitle
                showModal={showModel}
                modalBodyStyle={{ maxHeight: '99%', overflow: 'auto' }}
                onClose={() => {
                  setShowModel(false);
                }}
              >
                <div
                  className={styles.RichTextBlock}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </Modal>
            </div>
          )}
        </>
      )}
    </Section>
  );
};
