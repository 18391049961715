import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Section } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useProgramQuery } from 'hooks/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { parseSessionTimeout } from '../helper';
import styles from '../session.module.css';

export const ViewSessionOptions: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { data: program, isLoading } = useProgramQuery(programId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { displayString: eeTimeout } = parseSessionTimeout(
    program?.eeSessionTimeout,
    true
  );
  const { displayString: studioTimeout } = parseSessionTimeout(
    program?.sessionTimeoutMinutes
  );

  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        breadcrumbs={[
          { to: '..', label: 'Configure' },
          { label: 'User Session Timeout' },
        ]}
        title="User Session Timeout"
      >
        <Section
          title="Member Experience"
          href={`/${program?.id}/configure/session-options/edit/Experience`}
          className={styles.section}
        >
          <div>
            <h3 className={styles.itemTitle}>Session Timeout</h3>
            <div className={styles.itemDesc}>
              <span>Automatically sign users out after a period of time</span>
              <span className={styles.itemValue}>{eeTimeout}</span>
            </div>
          </div>
        </Section>
        <Section
          title="Studio"
          href={`/${program?.id}/configure/session-options/edit/Studio`}
          className={styles.section}
        >
          <div>
            <h3 className={styles.itemTitle}>Session Timeout</h3>
            <div className={styles.itemDesc}>
              <span>Automatically sign users out after a period of time</span>
              <span className={styles.itemValue}>{studioTimeout}</span>
            </div>
          </div>
        </Section>
      </FormPage>
    </WithPermission>
  );
};
