/* eslint-disable react/jsx-no-undef */
import React from 'react';
import cx from 'classnames';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { Tooltip } from 'shared/Tooltip';
import styles from './directional-tooltip.module.css';

export const DirectionalTooltip: React.FC<{
  title: string;
  direction: 'top' | 'right' | 'bottom' | 'left';
  width?: string;
  height?: string;
  className?: string;
  tooltipClass?: string;
  tooltipTitleClass?: string;
  openDelay?: number;
  closeDelay?: number;
}> = ({
  title,
  direction,
  width,
  height,
  children,
  openDelay,
  closeDelay,
  className,
  tooltipClass,
  tooltipTitleClass,
}) => (
  <HoverDropdown
    closeDelay={closeDelay ?? 200}
    openDelay={openDelay ?? 500}
    dropdownClassName={className}
    dropdownRenderProp={() => (
      <div
        className={cx(styles.tooltip, {
          [styles.column]: direction === 'bottom',
          [styles.reverseColumn]: direction === 'top',
          [styles.row]: direction === 'right',
          [styles.reverseRow]: direction === 'left',
        })}
      >
        <Tooltip
          className={cx(styles.hoverTooltip, tooltipClass)}
          titleClassName={cx(styles.tooltipTitle, tooltipTitleClass)}
          title={title}
          width={width}
          height={height}
        />
        <div
          className={cx(styles.arrow, {
            [styles.topArrow]: direction === 'top',
            [styles.rightArrow]: direction === 'right',
            [styles.bottomArrow]: direction === 'bottom',
            [styles.leftArrow]: direction === 'left',
          })}
        />
      </div>
    )}
  >
    {children}
  </HoverDropdown>
);
