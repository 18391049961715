import React, { CSSProperties } from 'react';
import { FilterDropdownStructureContext } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import cx from 'classnames';
import styles from '../../Filters.module.css';

type FilterDropdownBodyPropsType = {
  className?: string;
  isScrollable?: boolean;
};
export const FilterDropdownBody: React.FC<FilterDropdownBodyPropsType> = ({
  children,
  className,
  isScrollable = false,
}) => {
  const { setElemBody, infiniteListBodyHeight } = React.useContext(
    FilterDropdownStructureContext
  );
  const style: CSSProperties = isScrollable
    ? { height: infiniteListBodyHeight }
    : {};
  return (
    <div
      ref={setElemBody}
      className={cx(styles.filterDropdownBody, className)}
      style={style}
    >
      {children}
    </div>
  );
};
