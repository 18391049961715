import React, { useCallback, useMemo } from 'react';
import { Sortable } from 'DesignSystem/Components';
import { SidebarComponent } from '../type';
import Sidebar from '../../../common/Sidebar';
import { AvailableTabs, TabName, useTopicFormCtx } from '../../../../context';
import styles from './styles.module.css';
import EnabledItem from './EnabledItem';
import HiddenItem from './HiddenItem';

// Existing Sortable component works with arrays of objects, but we need to sort an array of strings
interface TabItem {
  id: TabName;
  label: TabName;
}

const NavigationSidebar: SidebarComponent = ({ onClose }) => {
  const { state, handleChange, selectTab } = useTopicFormCtx();
  const { fields, activeTab } = state;

  const enabledItems = useMemo<TabItem[]>(() => {
    return fields.enabledTabs.map((tab) => ({
      id: tab,
      label: tab,
    }));
  }, [fields.enabledTabs]);

  const hiddenTabs = useMemo(() => {
    return AvailableTabs.filter((tab) => !fields.enabledTabs.includes(tab));
  }, [fields.enabledTabs]);

  const handleEnable = useCallback(
    (name: TabName) => {
      selectTab(name);
      handleChange('enabledTabs', [...fields.enabledTabs, name]);
    },
    [fields.enabledTabs, handleChange, selectTab]
  );

  const handleDisable = useCallback(
    (name: TabName) => {
      handleChange(
        'enabledTabs',
        fields.enabledTabs.filter((tab) => tab !== name)
      );
    },
    [fields.enabledTabs, handleChange]
  );

  const handleSort = useCallback(
    (items: TabItem[]) => {
      handleChange(
        'enabledTabs',
        items.map((item) => item.id)
      );
    },
    [handleChange]
  );

  // We need to memoize dynamic component to prevent unnecessary re-renders
  const SortableItem = useCallback(
    ({ item, index }) => (
      <EnabledItem
        value={item.id}
        active={item.id === activeTab}
        home={index === 0}
        persistent={fields.enabledTabs.length === 1}
        onClick={selectTab}
        onDisable={handleDisable}
      />
    ),
    [activeTab, handleDisable, selectTab, fields.enabledTabs.length]
  );

  let hiddenSection;
  if (hiddenTabs.length > 0) {
    hiddenSection = hiddenTabs.map((tab) => (
      <HiddenItem key={tab} value={tab} onEnable={handleEnable} />
    ));
  } else {
    hiddenSection = <div className={styles.Placeholder}>No hidden pages</div>;
  }
  return (
    <Sidebar title="Navigation" onClose={onClose} closeButton>
      <div className={styles.SectionHeading}>Visible Pages</div>
      <Sortable
        items={enabledItems}
        idAttr="id"
        className={styles.ItemList}
        handleClass={`.${styles.DragIcon}`}
        Item={SortableItem}
        onSort={handleSort}
      />
      <div className={styles.SectionHeading}>Hidden Pages</div>
      {hiddenSection}
    </Sidebar>
  );
};

export default NavigationSidebar;
