import {
  ContentBannerHeader,
  getTopicsAndAudiences,
} from 'components/content/ContentBanner/ContentBanner';
import { ContentTopicsAndAudiences } from 'components/content/ContentBanner/ContentTopicsAndAudiences';
import { FiltersStateType } from 'contexts/content/filters';
import { useContentsInfiniteQuery } from 'hooks/content';
import { Content } from 'models/content';
import React, { RefObject } from 'react';
import { FetchProps } from 'services/api-content';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { useCustomSlugContext } from '../useCustomSlugContext';
import styles from './campaign-list.module.css';

type PropsType = {
  height: number;
  parentRef: RefObject<HTMLDivElement>;
  fetchProps: FetchProps;
  filters: FiltersStateType;
};
const FETCH_INTERVAL = undefined; // disabled
export function CampaignList({
  height,
  parentRef,
  fetchProps,
  filters,
}: PropsType): JSX.Element {
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data,
  } = useContentsInfiniteQuery(fetchProps, filters, FETCH_INTERVAL);

  const content = data
    .flatMap((page) => page.data)
    // We only want content planner content (no polls)
    .filter((c): c is Content => c.type === 'content_planner');

  return (
    <InfiniteBannerList
      isLoading={isLoading}
      itemCount={content.length}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      height={height}
      parentRef={parentRef}
    >
      {(index: number) => (
        <ContentBanner key={content[index].id} content={content[index]} />
      )}
    </InfiniteBannerList>
  );
}

function ContentBanner({ content }: { content: Content }): JSX.Element {
  const thumbnail = {
    title: content.title,
    backgroundColor: '#b8a0b6',
    imageUrl: content.imageUrl || undefined,
  };

  const {
    setSelectedCampaign,
    toggleSelectedCampaignModal,
  } = useCustomSlugContext();

  const topicsAndAudiences = getTopicsAndAudiences(content);
  return (
    <EmptyBanner
      thumbnail={thumbnail}
      rowId={`content-${content.id}`}
      hideMenuDropdown
      menuItems={[
        {
          title: 'Assign to Custom URL',
          onClick: () => {
            setSelectedCampaign(content);
            toggleSelectedCampaignModal();
          },
        },
      ]}
    >
      <div className={styles.bannerContainer}>
        <div className={styles.headerContainer}>
          <ContentBannerHeader
            title={content.title || content.summary}
            publishedAt={content.publishedAt}
            displayName={content.contentAuthor.displayName}
            width="100%"
          />
        </div>
        <div className={styles.topicsAndAudiences}>
          <ContentTopicsAndAudiences topicsAndAudiences={topicsAndAudiences} />
        </div>
      </div>
    </EmptyBanner>
  );
}
