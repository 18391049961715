import { FormModal } from 'DesignSystem/Components';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { TextArea, Button } from '@socialchorus/shared-ui-components';

import { useUpdateTopicAboutPage } from 'hooks/topics';
import { useProgram } from 'contexts/program';
import { Form } from '../../../common/Form';
import { AboutDesignContext } from '../../contexts/about';
import { useTopicFormCtx } from '../../../../context';

interface Props {
  onClose: () => void;
}

export const EditAboutPreviewModal: FC<Props> = ({ onClose }) => {
  const { topic } = useTopicFormCtx();
  const { id: programId } = useProgram();
  const { update, isLoading } = useUpdateTopicAboutPage(programId, topic.id);
  const [formState, setFormState] = useState('');
  const { aboutPage } = useContext(AboutDesignContext);

  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = async () => {
    const valid = formRef.current?.reportValidity();

    if (!valid) return;

    update({ previewContent: formState });
    onClose();
  };
  useEffect(() => {
    setFormState(aboutPage?.previewContent || '');
  }, [aboutPage?.previewContent, setFormState]);

  const actionLabel = aboutPage?.previewContent ? 'Edit' : 'Add';

  return (
    <FormModal
      actionText={actionLabel}
      entityText="Preview"
      description="Add up to 120 characters to appear as your About Page preview text"
      disableCloseButton={isLoading}
      onCancel={onClose}
      secondaryButton={
        <Button
          variant="outline"
          onClick={onClose}
          label="Cancel"
          isLoading={isLoading}
        />
      }
      submitButton={
        <Button
          variant="primary"
          onClick={onSubmit}
          label="Save Preview"
          isLoading={isLoading}
        />
      }
      width="680px"
    >
      <Form ref={formRef}>
        <div>
          <TextArea
            label="Preview Text"
            id="about-preview-input"
            placeholder="Enter text"
            value={formState}
            disabled={isLoading}
            onChange={(newValue) => setFormState(newValue)}
            maxLength={120}
          />
        </div>
      </Form>
    </FormModal>
  );
};
