import {
  createHistory,
  createMemorySource,
  LocationProvider,
} from '@reach/router';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ContentFiltersProvider,
  defaultState as defaultFiltersState,
} from 'contexts/content/filters';
import { Sortable } from 'DesignSystem/Components';
import { Button, Checkbox } from 'DesignSystem/Form';
import {
  LandingPageTab,
  LandingPageTabType,
  PinnableContent,
  Topic,
} from 'models/topic';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { MAIcon } from 'shared/MAIcon';
import { Thumbnail } from 'shared/Thumbnail';
import postStyles from './posts.module.css';
import { PostsFinderModal } from './PostsFinder/PostsFinderModal';
import { PagesControlContext } from '../PagesControlContext';
import { PageControl } from '../PageControl';

type PostsControlProps = {
  topic: Partial<Topic>;
  tab: LandingPageTab & { tabType: LandingPageTabType.Posts };
  onChange: (data: LandingPageTab) => void;
  disabled?: boolean;
};

PostsControl.defaultProps = {
  disabled: false,
};

export function PostsControl({
  topic,
  tab,
  onChange,
  disabled,
}: PostsControlProps): ReactElement {
  const { pinnedPostsIncluded, setPinnedPostsIncluded } = useContext(
    PagesControlContext
  );
  const [addPostsModalOpen, setAddPostsModalOpen] = useState(false);

  const filtersState = useMemo(
    () =>
      defaultFiltersState({
        standard: {
          topics: {
            values: [topic],
            isVisible: false,
          },
        },
      }),
    [topic]
  );

  const onPinnedPostsChange = useCallback(
    (pinnedPosts: PinnableContent[]) => {
      onChange({
        ...tab,
        pinnedContents: pinnedPosts,
      });
    },
    [onChange, tab]
  );

  const pinnedPosts = tab.pinnedContents;

  const AddPostsButton = (
    <Button
      compact
      icon={<MAIcon name="add" />}
      label="Add Posts"
      onClick={() => setAddPostsModalOpen(true)}
      disabled={disabled}
    />
  );

  // Prevent browser URL rewriting from ContentFiltersProvider
  const modalHistory = createHistory(createMemorySource('/posts-finder'));

  return (
    <PageControl title="Posts" tab={tab} onChange={onChange} hideToggle>
      <Checkbox
        checked={pinnedPostsIncluded}
        label="Include pinned posts"
        disabled={disabled}
        onChange={(shouldEnable) => {
          setPinnedPostsIncluded(shouldEnable);

          if (!shouldEnable) {
            onPinnedPostsChange([]);
          }
        }}
      />
      {pinnedPostsIncluded && (
        <div className={postStyles.pinnedPostsContainer}>
          {pinnedPosts.length > 0 && (
            <Sortable
              className={postStyles.sortablePostsContainer}
              items={pinnedPosts}
              idAttr="id"
              Item={({ item, index }) => (
                <div className={postStyles.sortablePost}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '8px',
                    }}
                  >
                    <SVGIcon name="DragPan" />
                    <Thumbnail
                      title={item.title || item.summary || ''}
                      imageUrl={item.imageUrl}
                      backgroundColor="#b8a0b6" // default used by `ContentBanner`
                      size="small"
                    />
                  </div>
                  <span className={postStyles.sortablePostTitle}>
                    {'title' in item
                      ? item.title || item.summary
                      : item.descriptionText}
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '16px',
                    }}
                  >
                    <Button
                      layoutOnly
                      minimal
                      compact
                      icon={<MAIcon name="delete" />}
                      disabled={disabled}
                      onClick={() =>
                        onPinnedPostsChange([
                          ...pinnedPosts.slice(0, index),
                          ...pinnedPosts.slice(index + 1),
                        ])
                      }
                    />
                  </div>
                </div>
              )}
              onSort={onPinnedPostsChange}
            />
          )}
          {AddPostsButton}
        </div>
      )}

      <LocationProvider history={modalHistory}>
        <ContentFiltersProvider defaultFiltersState={filtersState}>
          {addPostsModalOpen && (
            <PostsFinderModal
              selected={pinnedPosts}
              onSubmit={(data) => {
                onPinnedPostsChange(data);
                setAddPostsModalOpen(false);
              }}
              onCancel={() => setAddPostsModalOpen(false)}
            />
          )}
        </ContentFiltersProvider>
      </LocationProvider>
    </PageControl>
  );
}
