import React, { useState } from 'react';
import { FAIcon } from 'shared/Icon/FAIcon';
import styles from './SaveAsModal.module.css';
import { Audience } from '../../../../models/audience';

export const SaveAsModal: React.FC<{
  audience: Audience;
  onCancel: () => void;
  onSaveAs: (title: string) => void;
}> = ({ audience, onCancel, onSaveAs }) => {
  const [title, setTitle] = useState(`Copy of ${audience.title}`);

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSaveAs(title);
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>Save as...</h2>
          <button
            type="button"
            className={styles.modalHeaderClose}
            onClick={onCancel}
          >
            <FAIcon name="close" />
          </button>
        </div>

        <div className={styles.modalBody}>
          <div>
            <p className={styles.modalLabel}>Name</p>
          </div>
          <div>
            <input
              type="text"
              className={styles.modalInput}
              value={title}
              onChange={handleChangeTitle}
            />
          </div>
        </div>

        <div className={styles.modalFooter}>
          <button type="button" className={styles.modalDeny} onClick={onCancel}>
            Cancel
          </button>

          <button
            type="button"
            className={styles.modalAccept}
            onClick={handleClick}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
