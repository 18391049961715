import React from 'react';
import {
  VoteType,
  Feedback,
  Vote,
  FeedbackModal,
  FeedbackModalBody,
  FeedbackFactory,
  FeedbackStyle,
} from '@socialchorus/critique-ui';
import { postFeedback } from 'services/api-critique';
import { useProgram } from 'contexts/program';
import { Box, Modal } from 'DesignSystem/Components';
import { styles } from 'DesignSystem/Form';
import { useFeedback } from './FeedbackProvider';

export interface CollectFeedbackProps {
  type: FeedbackStyle;
}

export const CollectFeedback: React.FC<CollectFeedbackProps> = ({ type }) => {
  const { id: programId } = useProgram();
  const { page, feature, task_id, resetFeedback } = useFeedback();
  const [showFeedbackUI, setShowFeedbackUI] = React.useState(false); // add this state for controlling UI visibility

  const handleVote = async (vote: VoteType): Promise<boolean> => {
    try {
      await postFeedback({
        tenant: `program:${programId}`,
        vote,
        feature,
        reference_type: 'athena_task',
        reference: task_id,
      });
      setShowFeedbackUI(true); // hide feedback UI
      resetFeedback();
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleComment = async (comment_text: string): Promise<boolean> => {
    try {
      await postFeedback({
        tenant: `program:${programId}`,
        comment_text,
        feature,
        reference_type: 'athena_task',
        reference: task_id,
      });
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleClose = () => {
    resetFeedback();
  };

  // only show feedback if we have a page defined
  const domainAndPath = window.location.origin + window.location.pathname;
  const show =
    (page !== '' && domainAndPath.startsWith(page)) || showFeedbackUI;
  if (!show) return <></>;
  // Get the correct feedback style based on the type
  const Format = FeedbackFactory(type);

  return (
    <Feedback>
      <Format
        title="Help us Improve our Search"
        subtitle="This page was generated using AI natural language processing, your feedback helps us improve our search."
        onClose={handleClose}
      >
        <Vote
          request="This is Helpful"
          response="Thank you for your vote!"
          link="Leave Feedback"
          finished="Thanks, your feedback has been recorded"
          onVote={handleVote}
        />
      </Format>
      <FeedbackModal>
        <Modal name="edit-role-modal" width={960} innerPadding={0}>
          <div className={styles.EditRoleModal}>
            <Box padding={20}>
              <FeedbackModalBody
                title="Firstup AI Feedback"
                message="Please leave any comments or suggestions abour your experience with our AI search tool. Your feedback is important to allow us to improve our features. Thank you."
                placeholder="Comment"
                onComment={handleComment}
              />
            </Box>
          </div>
        </Modal>
      </FeedbackModal>
    </Feedback>
  );
};
