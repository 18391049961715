import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from 'hooks/usePortal';
import { Button } from 'shared/Button';
import { useFlashMessage } from 'contexts/flasher';
import { usePublisher } from 'contexts/publisher';
import { Block } from 'shared/SectionBlocks';
import { SendPreviewEmailModal } from 'components/publisher/previews/Email/SendPreviewEmailModal';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { ReviewPreview } from './previews/Review';
import { ReactComponent as Email } from './Email.svg';
import styles from './test.module.css';

type PropsType = {
  isValid?: boolean;
};

export const Sidebar: React.FC<PropsType> = ({ isValid }) => {
  const [showSendPreviewEmailModal, setShowSendPreviewEmailModal] = useState(
    false
  );
  const { setFlashMessage } = useFlashMessage();
  const publisher = usePublisher();
  const { contentPermissions } = useSettings();
  const { restrictedFields } = contentPermissions;

  const trySendTestEmail = useCallback(() => {
    if (publisher.id === 'new') {
      setFlashMessage({
        severity: 'error',
        message: 'Error: post is not persisted',
      });
    } else {
      setShowSendPreviewEmailModal(true);
    }
  }, [publisher.id, setFlashMessage]);

  const portal = usePortal('review-sidebar-send-test-email-modal');

  const SendPreviewEmailModalPortal = useCallback(
    () =>
      createPortal(
        <SendPreviewEmailModal
          post={publisher.post}
          onClose={() => setShowSendPreviewEmailModal(false)}
        />,
        portal.target
      ),
    [portal.target, publisher.post]
  );

  return (
    <div>
      <Block>
        <ReviewPreview />
      </Block>
      <div className={styles.sidebarText}>
        <div className={styles.blockSpacing}>
          <Button
            onClick={trySendTestEmail}
            type="tertiary"
            data-testid="sidebar-send-test-email-button"
            disabled={
              restrictedFields.includes(RestrictedFields.SEND_EMAIL) || !isValid
            }
          >
            <Email width="30" height="37" className={styles.emailIcon} />
            &nbsp;&nbsp;Send test email
          </Button>
        </div>
      </div>
      {showSendPreviewEmailModal &&
        !restrictedFields.includes(RestrictedFields.SEND_EMAIL) && (
          <SendPreviewEmailModalPortal />
        )}
    </div>
  );
};
