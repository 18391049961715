import { AttachmentData } from 'models/attachment';
import { MutationOptions, useMutation, useQuery } from 'react-query';
import {
  showContentAttachment,
  ShowAttachmentProps,
  uploadContentAttachment,
  UploadAttachmentProps,
  uploadDesignAttachment,
  showDesignAttachment,
} from 'services/api-assets';
import { MutationResponse, QueryError, QueryResponse } from './common';

export const useContentAttachmentUpload = ({
  onSuccess,
}: MutationOptions<
  AttachmentData,
  QueryError,
  UploadAttachmentProps
> = {}): MutationResponse<UploadAttachmentProps> => {
  const { mutate, isLoading, error } = useMutation<
    AttachmentData,
    QueryError,
    UploadAttachmentProps
  >(uploadContentAttachment, { onSuccess });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export const useDesignAttachmentUpload = ({
  onSuccess,
}: MutationOptions<
  AttachmentData,
  QueryError,
  UploadAttachmentProps
> = {}): MutationResponse<UploadAttachmentProps> => {
  const { mutate, isLoading, error } = useMutation<
    AttachmentData,
    QueryError,
    UploadAttachmentProps
  >(uploadDesignAttachment, { onSuccess });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

type AttachmentQueryProps = ShowAttachmentProps & {
  enabled?: boolean;
};

export const useContentAttachmentQuery = (
  props: AttachmentQueryProps
): QueryResponse<AttachmentData> & {
  refetch: ReturnType<typeof useQuery>['refetch'];
} => {
  const { isLoading, data, error, refetch } = useQuery(
    ['content_attachment', props],
    () => showContentAttachment(props),
    { enabled: props.enabled }
  );

  return {
    refetch,
    isLoading,
    data,
    errorMessage: (error as QueryError)?.message,
  };
};

export const useDesignAttachmentQuery = (
  props: AttachmentQueryProps
): QueryResponse<AttachmentData> & {
  refetch: ReturnType<typeof useQuery>['refetch'];
} => {
  const { isLoading, data, error, refetch } = useQuery(
    ['design_attachment', props],
    () => showDesignAttachment(props),
    { enabled: props.enabled }
  );

  return {
    refetch,
    isLoading,
    data,
    errorMessage: (error as QueryError)?.message,
  };
};
