import React, { FC, useContext, useEffect } from 'react';
import { Avatar, Container } from '@socialchorus/shared-ui-components';
import { useProgram } from 'contexts/program';
import { useInfiniteApiQuery } from 'hooks/common';
import useScrollEnd from 'hooks/useScrollEnd';
import { Topic } from 'models/topic';
import { fetchMembers } from 'services/api-topics';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { BaseTab } from '../BaseTab';
import styles from './styles.module.css';
import { DEFAULT_AVATAR } from '../../../common/constants';
import { MainContainerCtx } from '../../../common/MainContainer';
import NonEditablePlaceholder from '../../components/NonEditablePlaceholder';
import { useTopicApiId } from '../../../../../useTopicApiId';

interface Props {
  topic?: Topic;
}

function NewTopicPlaceholder() {
  return (
    <NonEditablePlaceholder
      title="No Members Yet"
      description="There are no members following this topic yet"
    />
  );
}

function MembersList({ topic }: { topic: Topic }) {
  const { id: programId } = useProgram();

  const topicId = useTopicApiId(topic);

  const {
    data,
    isLoading,
    isFetchingNextPage,
    meta,
    hasNextPage,
    fetchNextPage,
    errorMessage,
  } = useInfiniteApiQuery(
    `members-${topicId}`,
    ({ page, pageSize }) => fetchMembers(programId, topicId, page, pageSize),
    {
      pageSize: 40,
      page: 1,
    }
  );
  const mainContainerRef = useContext(MainContainerCtx);
  const isAtBottom = useScrollEnd(mainContainerRef, 400);
  useEffect(() => {
    if (isAtBottom && hasNextPage && !isLoading) {
      fetchNextPage();
    }
  }, [isAtBottom, hasNextPage, fetchNextPage, isLoading]);

  if (errorMessage) {
    return (
      <div className={styles.placeholder}>
        <h2>Something went wrong!</h2>
        <p>{errorMessage}</p>
      </div>
    );
  }

  // Absence of meta means we haven't loaded first page yet
  if (!meta && isLoading) {
    return (
      <div className={styles.placeholder}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!data?.length) {
    return <NewTopicPlaceholder />;
  }

  return (
    <Container className={styles.container} shadow="extra-light" fullWidth>
      <div className={styles.memberCount}>
        {meta?.totalRecords} Member{(meta?.totalRecords || 0) > 1 ? 's' : ''}
      </div>
      <div className={styles.memberList}>
        {data?.map((member) => (
          <Container
            className={styles.member}
            key={member.id}
            variant="border"
            fullWidth
          >
            <Avatar
              size="large"
              shape="circle"
              alt={member.name}
              imgSrc={member.avatarUrl || DEFAULT_AVATAR}
            />
            <span className={styles.name}>{member.name}</span>
          </Container>
        ))}
      </div>
      {isFetchingNextPage && (
        <div className={styles.placeholder}>
          <LoadingSpinner />
        </div>
      )}
    </Container>
  );
}

const Members: FC<Props> = ({ topic }) => {
  return (
    <BaseTab currentTab="members">
      {topic ? <MembersList topic={topic} /> : <NewTopicPlaceholder />}
    </BaseTab>
  );
};

export default Members;
