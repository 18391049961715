import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { User } from 'models/user';
import { Topic } from 'models/topic';
import { IdentityScope } from 'models/identity';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { UserInfoForm } from './Main';
import { UserFormFooter } from './footer';
import { Sidebar } from './Sidebar';

export const UserCreation: React.FC<RouteComponentProps> = () => {
  const [userData, setUserData] = useState<User>({} as User);

  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
  const [identityScopes, setIdentityScopes] = useState<IdentityScope[]>([]);

  const convertTopicToContributorTopicType = (topic: Topic) => {
    return {
      id: topic.id.toString(),
      name: topic.name,
      default: topic.default,
      hidden: topic.archived,
      contributor_ids: topic.contributorIds,
      description: topic.description,
      criterion_v2: topic.criterionV2,
    };
  };

  const handleDataChange = (field: string, value: string) => {
    setUserData(() => ({
      ...userData,
      [field]: value,
    }));
  };

  const handleTopicChange = (selections: Topic[]) => {
    setSelectedTopics(() => selections);
    setUserData(() => ({
      ...userData,
      contributorChannels: selections.map(convertTopicToContributorTopicType),
    }));
  };

  const header = (
    <>
      <h1 className="page-header">User</h1>
    </>
  );

  const main = (
    <UserInfoForm
      data={userData}
      identityScopes={identityScopes}
      onDataChange={handleDataChange}
      onTopicChange={handleTopicChange}
      onIdentityScopesChange={setIdentityScopes}
    />
  );

  const sidebar = <Sidebar data={userData} />;
  const footer = (
    <UserFormFooter
      data={userData}
      identityScopes={identityScopes}
      selectedTopics={selectedTopics}
    />
  );

  return (
    <MainEditor header={header} main={main} sidebar={sidebar} footer={footer} />
  );
};
