import * as React from 'react';
import cx from 'classnames';
import { Field } from './Field';
import styles from './fieldset.module.css';

export { Field };

type PropsType = {
  className?: string;
  dataTest?: string;
};

export const Fieldset: React.FC<PropsType> = (props) => {
  const { className, children, dataTest } = props;

  return (
    <fieldset className={cx(styles.fieldset, className)} data-test={dataTest}>
      {children}
    </fieldset>
  );
};
