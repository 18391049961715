import React from 'react';
import styles from '../../editor.module.css';

export const ToolbarDivider: React.FC<{ width: string }> = ({ width }) => (
  <span
    className={styles.toolbarDivider}
    style={{
      borderWidth: width ?? '1px',
    }}
  />
);
