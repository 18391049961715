import React from 'react';
import styles from 'components/content/ContentFilterBar/filters.module.css';
import { SearchBar } from 'shared/SearchBar';
import { Filters } from './Filters';

type PropsType = {
  onFilterChange: (filterName: string, values: string[]) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  status?: string[];
};

export const FiltersBar: React.FC<PropsType> = ({
  onFilterChange,
  searchTerm,
  setSearchTerm,
  status,
}) => {
  return (
    <div className={styles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search email aliases"
        panelClassName={styles.searchBarWrapper}
      />
      <Filters onChange={onFilterChange} status={status} />
    </div>
  );
};
