import { ThumbnailType } from 'shared/BannerListItem';
import { Activity } from 'models/activity';
import avatar from 'assets/images/activity-avatar.png';

export const activityToThumbnail = (activity: Activity): ThumbnailType => {
  return {
    title: String(activity.user?.firstName),
    imageUrl: activity.user?.avatarUrl || avatar,
  };
};
