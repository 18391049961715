import { useCallback, useRef } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import {
  FlashMessageType,
  FlashProgressUpdateType,
} from 'models/flash-message';

type ProgressingFlashType = {
  message: string;
  percentage: number;
  keyPrefix?: string;
  interval?: number;
  children?: JSX.Element;
};

export const useProgressingFlash = (): {
  progressingFlashDismiss: (keyPrefix?: string) => void;
  setProgressingFlash: (data: ProgressingFlashType) => void;
} => {
  const currentProgressValue = useRef(0);
  const isProgressingFlashInitialized = useRef(false);
  const { setFlashMessage, dismiss, update } = useFlashMessage();

  const updateFlasherPercentage = useCallback(
    (updateFlasher: FlashProgressUpdateType) => {
      updateFlasher(currentProgressValue.current);
    },
    [currentProgressValue]
  );

  const setProgressingFlash = useCallback(
    (data: {
      message: string;
      percentage: number;
      keyPrefix?: string;
      interval?: number;
      children?: JSX.Element;
    }) => {
      currentProgressValue.current = data.percentage;
      const messageKey = `${data.keyPrefix}-progress`;
      const flashMessage: FlashMessageType = {
        severity: 'progress',
        message: data.message,
        timeout: false,
        progress: {
          max: 100,
          interval: data.interval ?? 1000,
          defaultProgress: currentProgressValue.current,
          update: updateFlasherPercentage,
        },
        children: data.children,
        inlineChildren: true,
      };

      if (!isProgressingFlashInitialized.current) {
        setFlashMessage({
          ...flashMessage,
          messageKey,
        });
        isProgressingFlashInitialized.current = true;
      } else {
        update({ messageKey, message: '' }, flashMessage);
      }
    },
    [currentProgressValue, update, updateFlasherPercentage, setFlashMessage]
  );

  const progressingFlashDismiss = useCallback(
    (keyPrefix?: string) => {
      dismiss({
        message: '',
        messageKey: `${keyPrefix}-progress`,
      });
      isProgressingFlashInitialized.current = false;
    },
    [dismiss]
  );

  return {
    progressingFlashDismiss,
    setProgressingFlash,
  };
};
