import React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';

import { useProgram } from 'contexts/program';
import { Body, Caption } from 'DesignSystem/Typography';
import {
  useDeleteLanguageControlMutation,
  useDuplicateLanguageControlMutation,
} from 'hooks/language-controls/useMutations';
import {
  isReplaceLanguageControl,
  LanguageControl,
} from 'models/language-controls/language-control';
import { User } from 'models/user';
import { EmptyBanner, MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { capitalize } from 'utility/strings';
import { convertSnakeCaseToSentenceCase } from 'utility/text';
import { LanguageControlsDeleteModal } from './LanguageControlsDeleteModal';
import styles from './language-control-list-item.module.css';

const DATE_FORMAT = 'MMM d, y';

type PropsType = {
  languageControl: LanguageControl;
  creator?: User;
  refetchList?: () => void;
};

export const LanguageControlBanner: React.FC<PropsType> = ({
  languageControl,
  creator,
  refetchList,
}) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const { id: programId } = useProgram();

  const {
    mutate: duplicateLanguageControl,
    isLoading: isDuplicating,
  } = useDuplicateLanguageControlMutation({
    onSuccess: () => {
      refetchList?.();
    },
  });

  const {
    mutate: deleteLanguageControl,
    isLoading: isDeleting,
  } = useDeleteLanguageControlMutation({
    onSuccess: () => {
      setShowDeleteModal(false);
      refetchList?.();
    },
  });

  const menuItems: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${programId}/configure/language-controls/${languageControl.id}`,
    },
    {
      title: 'Duplicate',
      onClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        duplicateLanguageControl({
          programId,
          id: languageControl.id,
        });
      },
      disabled: isDuplicating,
    },
    {
      title: 'Delete',
      onClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        setShowDeleteModal(true);
      },
      disabled: isDeleting,
    },
  ];

  const handleDelete = () => {
    deleteLanguageControl({
      programId,
      id: languageControl.id,
    });
  };

  const name = `${capitalize(
    convertSnakeCaseToSentenceCase(languageControl.controlType)
  )} "${languageControl.target}"${
    isReplaceLanguageControl(languageControl)
      ? ` with "${languageControl.replacement}"`
      : ''
  }`;

  const createdAt = DateTime.fromISO(languageControl.createdAt).toFormat(
    DATE_FORMAT
  );

  const createdBy = creator
    ? creator.displayName || `${creator.firstName} ${creator.lastName}`
    : 'User';

  return (
    <>
      <EmptyBanner
        isSelectable
        rowId={`${languageControl.id}`}
        menuItems={menuItems}
      >
        <BannerColumn primary header="name" value={name} />
        <BannerColumn header="date created" value={createdAt} />
        <BannerColumn header="created by" value={createdBy} />
      </EmptyBanner>
      {showDeleteModal && (
        <LanguageControlsDeleteModal
          onCancel={() => setShowDeleteModal(false)}
          onDelete={handleDelete}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

type BannerColumnProps = {
  primary?: boolean;
  header: string;
  value: string;
};

const BannerColumn: React.FC<BannerColumnProps> = ({
  primary,
  header,
  value,
}) => (
  <div
    className={cx(styles.bannerColumn, {
      [styles.primaryBannerColumn]: primary,
      [styles.auxiliaryBannerColumn]: !primary,
    })}
  >
    <span className={styles.bannerColumnHeader}>{header}</span>
    <span className={styles.bannerColumnBody}>
      {primary ? (
        <Body semibold>{value}</Body>
      ) : (
        <Caption semibold>{value}</Caption>
      )}
    </span>
  </div>
);
