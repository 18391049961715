import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { WithPermission } from 'shared/WithPermission';
import { EmailAliasFormFields, Form } from './components/Form/EmailAliasForm';
import { Footer } from './components/Footer';
import { useActions } from './useActions';

const emptyEmailAliasForm: EmailAliasFormFields = {
  name: '',
  description: '',
  senderName: '',
  senderEmail: '',
  replyToEmail: '',
  onBehalfOfSenderEnabled: false,
  onBehalfName: '',
  onBehalfEmail: '',
  customPhysicalAddressEnabled: false,
  companyName: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
};

type TouchedEmailAliasFields = Record<keyof EmailAliasFormFields, boolean>;

const formTouchedInit: TouchedEmailAliasFields = {
  name: false,
  description: false,
  senderName: false,
  senderEmail: false,
  replyToEmail: false,
  onBehalfOfSenderEnabled: false,
  onBehalfName: false,
  onBehalfEmail: false,
  customPhysicalAddressEnabled: false,
  companyName: false,
  addressOne: false,
  addressTwo: false,
  city: false,
  state: false,
  zip: false,
};

function required(value: string) {
  return value && value.trim() ? undefined : 'Required';
}

function getErrors(emailAlias: EmailAliasFormFields) {
  const result = {} as Record<keyof EmailAliasFormFields, string | undefined>; // `as` so we don't have to initialize all fields

  result.name = required(emailAlias.name);
  result.senderName = required(emailAlias.senderName);
  result.senderEmail = required(emailAlias.senderEmail);
  result.replyToEmail = required(emailAlias.replyToEmail);

  return result;
}

export function useEmailAliasForm(): {
  emailAlias: EmailAliasFormFields;
  touched: TouchedEmailAliasFields;
  errors: ReturnType<typeof getErrors>;
  handleEmailAliasChange: (values: Partial<EmailAliasFormFields>) => void;
  handleBlur: (values: keyof EmailAliasFormFields) => void;
  hasErrors: boolean;
} {
  const [touched, setTouched] = useState(formTouchedInit);
  const [emailAlias, setEmailAlias] = useState(emptyEmailAliasForm);

  const errors = getErrors(emailAlias);
  const hasErrors = Object.values(errors).some((error) => !!error);

  const handleEmailAliasChange = useCallback(
    (values: Partial<EmailAliasFormFields>) => {
      setEmailAlias((prev) => ({
        ...prev,
        ...values,
      }));
    },
    []
  );

  function handleBlur(field: keyof EmailAliasFormFields) {
    setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
  }

  return {
    touched,
    emailAlias,
    errors,
    handleEmailAliasChange,
    handleBlur,
    hasErrors,
  };
}

export const NewEmailAlias: React.FC<RouteComponentProps> = () => {
  const {
    emailAlias,
    handleEmailAliasChange,
    errors,
    handleBlur,
    touched,
    hasErrors,
  } = useEmailAliasForm();
  const { create } = useActions();

  const header = (
    <>
      <h1 className="page-header">Email Alias</h1>
    </>
  );

  const main = (
    <Form
      emailAlias={emailAlias}
      onChange={handleEmailAliasChange}
      errors={errors}
      onFieldBlur={handleBlur}
      touched={touched}
    />
  );

  function onSave() {
    create(emailAlias);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <Helmet>
        <title>New Email Alias</title>
      </Helmet>
      {emailAlias && (
        <MainEditor
          header={header}
          main={main}
          sidebar={<></>}
          footer={
            <Footer
              emailAlias={emailAlias}
              action={onSave}
              allowSaving={!hasErrors}
            />
          }
        />
      )}
    </WithPermission>
  );
};
