import * as React from 'react';
import { PaginationState } from 'hooks/common';
import { throttle } from 'utility/throttle';

// When the users gets this many pixels close to the bottom
// of the page, and there are more results, a request will be sent
const NEXT_PAGE_SENSITIVITY = 750;

export function useAutoPaging(
  paging: React.MutableRefObject<PaginationState>,
  sensitivity = NEXT_PAGE_SENSITIVITY
): React.RefObject<HTMLDivElement> {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { current: scrollElement } = scrollRef;
  React.useEffect(() => {
    let scrollTop = 0;

    const onScroll = throttle(() => {
      if (scrollElement) {
        const scrollingDownward = scrollElement.scrollTop >= scrollTop;
        const { hasNextPage, isFetchingNextPage } = paging.current;
        const readyToRequestPage = hasNextPage && !isFetchingNextPage;
        const inactiveRegion =
          scrollElement.scrollHeight -
          scrollElement.getBoundingClientRect().height -
          sensitivity;
        const inScrollRegion = scrollTop > inactiveRegion;
        if (scrollingDownward && readyToRequestPage && inScrollRegion) {
          paging.current.fetchNextPage();
        }
        scrollTop = scrollElement.scrollTop;
      }
    });
    if (scrollElement) scrollElement.addEventListener('scroll', onScroll);

    return () => {
      if (scrollElement) scrollElement.removeEventListener('scroll', onScroll);
    };
  }, [scrollElement, paging, sensitivity]);
  return scrollRef;
}
