/* TODO: Fix accessibility in MAGIC-5749 */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  ComplexExpression,
  Expression,
  defaultComplexExpression,
  emptyExpression,
  updateComplexExpression,
} from 'models/expression';
import { editableTextToQueryType } from 'models/audience';
import { Icon } from 'shared/Icon';
import { AIToggle, AIAssistant } from './AIAssistant';
import { ExpressionCount } from './ExpressionCount';
import { ExpressionOperator } from './ExpressionOperator';
import { ExpressionBuilder } from './index'; // eslint-disable-line import/no-cycle
import styles from '../AudienceBuilder.module.css';

export const ComplexBuilder: React.FC<{
  depth: number;
  onChange: (value: ComplexExpression) => void;
  onDelete: () => void;
  isAlone: boolean;
  value: ComplexExpression;
  canEdit: boolean;
  editableTextToQuery: editableTextToQueryType;
  audienceName?: string;
  updateSummaryQueryStatus?: (status: boolean) => void;
}> = ({
  depth,
  isAlone,
  onChange,
  onDelete,
  value,
  canEdit,
  editableTextToQuery,
  audienceName,
  updateSummaryQueryStatus,
}) => {
  const { id: programId } = useProgram();

  const {
    addExpression,
    removeExpression,
    setExpression,
    setOperator,
  } = updateComplexExpression(value, onChange);

  const { data: flag } = useFeatureFlagsQuery(
    programId,
    'Studio.AI.AudienceBuilder'
  );
  const showAIAssistantToggle = !!flag?.value;
  const [showAIAssistant, setShowAIAssistant] = React.useState(false);

  const isSingleExpression = value.terms.length === 1;
  const isChildAlone = isAlone && isSingleExpression;
  const isOutermost = depth === 0;
  const newExpression = isOutermost
    ? defaultComplexExpression()
    : emptyExpression();
  const operatorTheme = isSingleExpression ? 'inactive' : 'default';

  return (
    <div className={isOutermost ? '' : styles.criteriaTerm}>
      <div className={styles.expressionHeader}>
        <div className={styles.rowMember}>
          <div className={styles.count}>
            <ExpressionCount
              isCompact={isOutermost}
              expression={value}
              editableTextToQuery={editableTextToQuery}
              updateSummaryQueryStatus={updateSummaryQueryStatus}
            />
          </div>
        </div>
        {isOutermost && showAIAssistantToggle && (
          <AIToggle
            value={showAIAssistant}
            onChange={(v?: true) => setShowAIAssistant(v !== undefined)}
          />
        )}
      </div>

      {isOutermost && showAIAssistantToggle && showAIAssistant && (
        <AIAssistant value={value} onChange={onChange} />
      )}

      <div className={styles.operator}>
        {!isSingleExpression && canEdit && (
          <ExpressionOperator
            onChange={setOperator}
            theme={operatorTheme}
            value={value.operator}
          />
        )}
      </div>

      {value.terms.map((term, exprIndex) => {
        const onChangeExpression = (v: Expression): void =>
          setExpression(exprIndex, v);

        const isLastTerm = exprIndex === value.terms.length - 1;
        const shouldDeleteContainer = !isAlone && isSingleExpression;

        return (
          <div key={term.key}>
            <ExpressionBuilder
              isAlone={isChildAlone}
              depth={depth + 1}
              value={term.expression}
              canEdit={canEdit}
              onChange={onChangeExpression}
              onDelete={() => {
                removeExpression(exprIndex);
                if (shouldDeleteContainer) {
                  onDelete();
                }
              }}
              editableTextToQuery={editableTextToQuery}
              audienceName={audienceName}
            />
            {!isLastTerm && (
              <div className={styles.operatorLabel}>{value.operator}</div>
            )}
          </div>
        );
      })}
      {canEdit ? (
        <div
          className={isOutermost ? styles.addComplex : styles.addSimple}
          onClick={() => addExpression(newExpression)}
        >
          <span className={styles.addExpressionIcon}>
            <Icon iconName="Plus" iconType="SVG" size={14} />
          </span>
          Add {isOutermost ? 'Grouping' : 'Criteria'}
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
    </div>
  );
};
