import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useCampaignActivityDataInfiniteQuery } from '../../../../../../hooks/campaign-user-activity';
import styles from './MetricsCards.module.css';
import { DataTable } from '../../../../../../shared/Charts/DataTable';
import { DefaultLoading } from '../../../../../../shared/InfiniteList/DefaultLoading';
import { SearchInput } from '../../../../../../shared/SearchInput';

export type IColumn = {
  key: string;
  header: string;
  width: string;
  formatCell?: () => string;
};

const PAGE_SIZE = 10;
const DEFAULT_SORT_BY = 'updated_at';

export const UserActivityStreamCard: React.FC<{
  campaignId: number;
  columns: IColumn[];
  channel?: 'Email';
  hasSearch?: boolean;
}> = ({ campaignId, columns, channel, hasSearch }) => {
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState('');
  // const searchText = useDebounce(search);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useCampaignActivityDataInfiniteQuery({
    campaignId,
    pageSize: PAGE_SIZE,
    sortBy,
    sortDirection,
    search,
    channel,
  });

  const tableRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);

  const handleSort = (field: string) => {
    if (field === sortBy) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  const handleSearch = React.useCallback(
    (searchText) => {
      setSearch(searchText);
    },
    [setSearch]
  );

  useEffect(() => {
    const options = {
      root: tableRef.current,
      rootMargin: '1px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, options);

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      const { current } = loadingRef;
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <div className={cx(styles.metricCard, styles.metricCardFull)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>User Activity Stream</h1>
        {hasSearch && (
          <SearchInput
            placeholder="Search users"
            value={search}
            onChange={handleSearch}
            searchIconSize={14}
            width="250px"
            hasClearButton
            closeIconSize={14}
          />
        )}
      </div>
      <div className={cx(styles.metricCardBody)}>
        <div ref={tableRef} className={cx(styles.infiniteScrollWrapper)}>
          <DataTable
            columns={columns}
            data={data}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={handleSort}
            isFetching={isFetchingNextPage}
            isLoading={isLoading}
          />
          <div ref={loadingRef}>
            {(isLoading || isFetchingNextPage) && <DefaultLoading />}
          </div>
        </div>
      </div>
    </div>
  );
};
