import React from 'react';
import { EmailAlias } from 'models/email-alias';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { Topic } from 'models/topic';
import { useTopicsQuery } from 'hooks/topics';
import { TopicSelect } from 'components/topics/TopicSelect';
import { useProgram } from 'contexts/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { FlipSwitch } from 'shared/FlipSwitch';

import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from './form.module.css';

type PropsType = {
  emailAlias: Partial<EmailAlias>;
  onChange: (data: Partial<EmailAlias>) => void;
};

export const TopicsSection: React.FC<PropsType> = ({
  emailAlias,
  onChange,
}) => {
  const { id: programId } = useProgram();
  const [topics, setTopics] = React.useState<Topic[]>([]);
  const [displayTopicSelector, setDisplayTopicsSelector] = React.useState(
    Number(emailAlias.contentChannels?.length) > 0
  );

  const { data, isLoading } = useTopicsQuery({
    programId,
    ids:
      Number(emailAlias.contentChannels?.length) > 0
        ? emailAlias.contentChannels?.map((c) => c.id)
        : [0],
  });

  React.useEffect(() => {
    if (data) {
      setTopics(data);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  function handleChange(values: Topic[]) {
    setTopics(values);
    const contentChannelIds = values.map((topic) => {
      return Number(topic.id);
    });
    onChange({ contentChannelIds });
  }

  function disable() {
    setDisplayTopicsSelector(false);
    onChange({ contentChannelIds: [] });
  }

  return (
    <Section title="Enable for contributors">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Enable for contributors">
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <FlipSwitch
                  size="xl"
                  shape="rounded"
                  on={displayTopicSelector}
                  onChange={(value) =>
                    value ? setDisplayTopicsSelector(true) : disable()
                  }
                />
              </div>
              Topic contributors can use this email address
            </div>
            {displayTopicSelector && (
              <div className={styles.topicsSelector}>
                {isLoading && (
                  <div className={styles.loading}>
                    <LoadingSpinner />
                  </div>
                )}
                {!isLoading && (
                  <TopicSelect
                    value={topics}
                    onChange={handleChange}
                    maxHeight={270}
                    upward
                  />
                )}
              </div>
            )}
          </Field>
        </div>
      </Fieldset>
    </Section>
  );
};
