import React, { useEffect, useMemo, useState } from 'react';
import {
  ClickDropdown,
  DismissType,
} from 'DesignSystem/Components/ClickDropdown';
import { Box } from 'DesignSystem/Components/Box';
import { Pills } from 'DesignSystem/Components/Pills';
import { Input } from 'DesignSystem/Form';
import { Subheading } from 'DesignSystem/Typography';
import { useProgram } from 'contexts/program';
import { useCustomSlugInfiniteQuery } from 'hooks/custom-slugs';
import { CustomSlug } from 'models/custom-slug';
import { truncate } from 'utility/text';
import { InfoTooltip } from '../../Shared/InfoTooltip';
import { SaveModalButtons } from '../../Shared/SaveModalButtons';
import { SelectSlugDropdown } from './SelectSlugDropdown';

export const renderSlugPill = (slug: CustomSlug): React.ReactNode => {
  return <>{truncate(slug.name, { size: 33, fallback: 'Unassigned' })}</>;
};

interface SelectCustomSlugsProps {
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  setDismissable?: (dismissable: boolean) => void;
  linkedCustomSlugs: CustomSlug[];
  setLinkedCustomSlugs: (slugs: CustomSlug[]) => void;
}

export const SelectCustomSlugs: React.FC<SelectCustomSlugsProps> = ({
  disabled,
  onCancel,
  onSave,
  setDismissable,
  linkedCustomSlugs,
  setLinkedCustomSlugs,
}) => {
  const { id: programId } = useProgram();
  const [searchText, setSearchText] = React.useState('');
  const slugsQuery = useCustomSlugInfiniteQuery({
    programId,
    filters: {
      search: searchText,
      createdBy: [],
      excludeIds: linkedCustomSlugs.map((slug) => Number(slug.id)),
    },
  });

  const [saveEnabled, setSaveEnabled] = useState(false);
  // disable dismiss if the save button has been enabled
  useEffect(() => {
    if (setDismissable) setDismissable(!saveEnabled);
  }, [setDismissable, saveEnabled]);

  const filteredSlugs = useMemo(() => {
    return slugsQuery.data.filter(
      (slug) =>
        linkedCustomSlugs.find((selected) => selected.id === slug.id) ===
        undefined
    );
  }, [slugsQuery.data, linkedCustomSlugs]);

  return (
    <>
      <Box padding={[0, 16, 16, 0]}>
        <Subheading bold>
          Custom URL
          <InfoTooltip message="A URL alias will show up in the employee experience" />
        </Subheading>
      </Box>
      <Box padding={[0, 16, 8, 0]}>
        <ClickDropdown
          dropdownRenderProp={(dismiss: DismissType) => (
            <SelectSlugDropdown
              dismiss={dismiss}
              filteredSlugs={filteredSlugs}
              slugsQuery={slugsQuery}
              linkedCustomSlugs={linkedCustomSlugs}
              setLinkedCustomSlugs={setLinkedCustomSlugs}
              setSaveEnabled={setSaveEnabled}
            />
          )}
          disabled={disabled}
          placement="top"
          autoUpdate
          referencePress={false}
          ignoreKeys
          onClose={() => {
            setSearchText('');
          }}
        >
          <Input
            placeholder="Search custom URLs"
            block
            value={searchText}
            onChange={setSearchText}
            maxCharacters={30}
            showCharacterCount
          />
        </ClickDropdown>
      </Box>
      <Box maxWidth={512} padding={[0]}>
        <Pills
          values={linkedCustomSlugs}
          render={renderSlugPill}
          onClose={(slug: CustomSlug) => {
            const slugs = linkedCustomSlugs.filter(
              (selectedSlug: CustomSlug) => selectedSlug.id !== slug.id
            );
            setLinkedCustomSlugs(slugs);
            setSaveEnabled(true);
          }}
        />
      </Box>
      <SaveModalButtons
        onCancel={onCancel}
        onSave={() => {
          if (saveEnabled && onSave) onSave();
        }}
        disabled={!saveEnabled}
      />
    </>
  );
};
