import { ImageData } from 'models/image';
import * as React from 'react';
import * as Dnd from 'react-beautiful-dnd';

type PropsType = {
  images: Array<ImageData>;
  onDrop: (image: Array<ImageData>) => void;
};

export const DnDContext: React.FC<PropsType> = ({
  children,
  images,
  onDrop,
}) => {
  const move = React.useCallback(
    (result: Dnd.DropResult) => {
      if (!result.destination) return;
      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      const others = [
        ...images.slice(0, fromIndex),
        ...images.slice(fromIndex + 1),
      ];
      onDrop([
        ...others.slice(0, toIndex),
        images[fromIndex],
        ...others.slice(toIndex),
      ]);
    },
    [images, onDrop]
  );

  const dragEnd = React.useCallback((result: Dnd.DropResult) => move(result), [
    move,
  ]);

  return (
    <Dnd.DragDropContext onDragEnd={dragEnd}>{children}</Dnd.DragDropContext>
  );
};
