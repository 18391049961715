import React from 'react';
import { Button } from 'DesignSystem/Form';
import { Alert, AlertType } from 'DesignSystem/Components';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { ExclamationTriangleOutlined } from 'shared/icons';
import styles from '../video.module.css';

type CaptionsValidationErrorsComponentProps = {
  validationErrors: ParseError[] | undefined;
};

const scrollToError = (lineNumber: number) => {
  const line = document.getElementById(`codeline-id-${lineNumber}`);
  if (line) {
    line.scrollIntoView();
  }
};

export const CaptionValidationErrors: React.FC<CaptionsValidationErrorsComponentProps> = ({
  validationErrors,
}) => {
  if (!validationErrors || validationErrors.length === 0) return null;
  return (
    <Alert
      addon={
        <Button
          onClick={() => scrollToError(validationErrors[0].lineNumber)}
          compact
          className={styles.errorButton}
          layoutOnly
          label="Click here to see errors"
        />
      }
      type={AlertType.error}
      message={`${validationErrors.length} error${
        validationErrors.length > 1 ? 's' : ''
      } detected`}
      bgColor="red"
      title="Validation errors"
      icon={
        <div className={styles.alertIconWrapper}>
          <ExclamationTriangleOutlined />
        </div>
      }
      enableIcon
      compact
    />
  );
};
