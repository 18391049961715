import React from 'react';
import { BoxIntegrationData } from 'models/box-integration';
import {
  BoxIntegrationFieldData,
  boxIntegrationFieldToData,
  boxIntegrationToField,
} from 'models/publisher/block';
import { ImageData } from 'models/image';
import { useProgramIdState } from 'contexts/program';
import { FieldFormProps } from '../../../useFieldForm';

export const useBoxIntegrationDataMutator: (
  props: FieldFormProps<BoxIntegrationFieldData>
) => {
  data: BoxIntegrationData | undefined;
  updateBoxResource: (resource: BoxIntegrationData) => void;
  updateResourceName: (name: string) => void;
  updateDescription: (description: string) => void;
  updateCallToAction: (callToAction: string) => void;
  setImage: (image: ImageData | File) => void;
  setImageAltText: (altText: string) => void;
} = ({ data, onChange }) => {
  const [programId] = useProgramIdState();

  const [boxData, setBoxData] = React.useState<BoxIntegrationData | undefined>(
    boxIntegrationFieldToData(programId, data)
  );
  const [defaultResourceName, setDefaultResourceName] = React.useState<string>(
    boxData?.name ?? ''
  );

  const updateBoxResource = React.useCallback(
    (resource) => {
      if (resource && boxData) {
        const newBoxData = {
          ...boxData,
          file_url: resource.file_url,
          name: resource.name,
          extension: resource.extension,
          resource_id: resource.resource_id,
          resource_type: resource.resource_type,
        };
        setBoxData(newBoxData);
        setDefaultResourceName(newBoxData.name);
        onChange(boxIntegrationToField(newBoxData));
      }
    },
    [onChange, boxData]
  );

  const updateResourceName = React.useCallback(
    (name) => {
      if (boxData) {
        const newResourceName = !name ? defaultResourceName : name;
        if (newResourceName !== boxData.name) {
          const newBoxData = { ...boxData, name: newResourceName };
          setBoxData(newBoxData);
          onChange(boxIntegrationToField(newBoxData));
        }
      }
    },
    [boxData, defaultResourceName, onChange]
  );

  const updateDescription = React.useCallback(
    (newDescription) => {
      if (boxData) {
        if (newDescription !== boxData.description) {
          const newBoxData = { ...boxData, description: newDescription };
          setBoxData(newBoxData);
          onChange(boxIntegrationToField(newBoxData));
        }
      }
    },
    [boxData, onChange]
  );

  const updateCallToAction = React.useCallback(
    (newCallToActionText) => {
      if (boxData) {
        if (newCallToActionText !== boxData.call_to_action) {
          const newBoxData = {
            ...boxData,
            call_to_action: newCallToActionText,
          };
          setBoxData(newBoxData);
          onChange(boxIntegrationToField(newBoxData));
        }
      }
    },
    [boxData, onChange]
  );

  const setImage = React.useCallback(
    (image) => {
      if (boxData) {
        const newBoxData = { ...boxData, image };
        setBoxData(newBoxData);
        onChange(boxIntegrationToField(newBoxData));
      }
    },
    [boxData, onChange]
  );

  const setImageAltText = React.useCallback(
    (altText) => {
      if (boxData) {
        const newBoxData = { ...boxData, image: { ...boxData.image, altText } };
        setBoxData(newBoxData);
        onChange(boxIntegrationToField(newBoxData));
      }
    },
    [boxData, onChange]
  );

  return {
    data: boxData,
    updateBoxResource,
    updateResourceName,
    updateDescription,
    updateCallToAction,
    setImage,
    setImageAltText,
  };
};
