import { useCallback, useState } from 'react';
import {
  AttachmentFieldData,
  attachmentFieldToData,
  attachmentToField,
} from 'models/publisher/block';
import { useDesign } from 'contexts/design';
import { useProgramIdState } from 'contexts/program';
import { useAttachmentUploader } from 'hooks/useAttachmentUploader';
import { AttachmentData } from 'models/attachment';
import { FieldFormProps } from '../../../useFieldForm';
import { useKeepOpen } from '../../shared/KeepOpen';
import { ExitBlocker } from '../components/ExitBlocker';

export type UploaderStatus = {
  isUploading: boolean;
  allowedExtensions: string[];
  error?: string;
};

export const useAttachmentDataMutator: (
  props: FieldFormProps<AttachmentFieldData>
) => {
  data: AttachmentData | undefined;
  uploaderStatus: UploaderStatus;
  updateAttachmentFile: (fileToUpload: File) => void;
  updateFileName: (fileName: string) => void;
} = ({ data, onChange }) => {
  const [programId] = useProgramIdState();
  const { active: isDesignAsset } = useDesign();

  const [attachmentData, setAttachmentData] = useState<
    AttachmentData | undefined
  >(attachmentFieldToData(programId, data));
  const [defaultFileName, setDefaultFileName] = useState<string>(
    attachmentData?.filename ?? ''
  );

  const onUpload = useCallback(
    (newData: AttachmentData) => {
      setAttachmentData(newData);
      setDefaultFileName(newData.filename);
      const attachmentField = attachmentToField(newData);
      onChange(attachmentField);
    },
    [onChange]
  );

  const uploader = useAttachmentUploader({
    onUpload,
    programId,
    isDesignAsset,
  });

  const updateAttachmentFile = useCallback(
    (fileToUpload: File) => {
      uploader.update(fileToUpload);
    },
    [uploader]
  );

  const updateFileName = useCallback(
    (fileName: string) => {
      if (!attachmentData) {
        return;
      }

      let updatedFileName = defaultFileName;
      if (fileName && fileName !== '') {
        updatedFileName = `${fileName}.${attachmentData.filetype}`;
      }
      const newAttachmentData = {
        ...attachmentData,
        filename: updatedFileName,
      };
      setAttachmentData(newAttachmentData);
      onChange(attachmentToField(newAttachmentData));
    },
    [attachmentData, defaultFileName, onChange]
  );

  useKeepOpen(ExitBlocker, uploader.isUploading);

  return {
    data: attachmentData,
    uploaderStatus: {
      isUploading: uploader.isUploading,
      allowedExtensions: uploader.allowedExtensions,
      error: uploader.error,
    },
    updateAttachmentFile,
    updateFileName,
  };
};
