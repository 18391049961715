import * as React from 'react';
import { usePickerScreen } from './usePickerScreen';
import { FullScreenPicker } from './FullScreenPicker';
import { ModalPicker } from './ModalPicker';

export const PickerScreen: React.FC<
  { useModal?: boolean } & Parameters<typeof usePickerScreen>[0]
> = ({ useModal, ...props }) => {
  const picker = usePickerScreen(props);
  const Component = useModal ? ModalPicker : FullScreenPicker;
  return (
    <Component
      addToAppendedItems={picker.addToAppendedItems}
      allItems={picker.allItems}
      allowFiltering={picker.allowFiltering}
      canModifyCollection={picker.canModifyCollection}
      canUploadImages={picker.canUploadImages}
      categories={picker.categories}
      collection={picker.collection}
      defaultCategoryLabel={picker.defaultCategoryLabel}
      handleDeleteItem={picker.handleDeleteItem}
      hasCustomCategories={picker.hasCustomCategories}
      isFilteringAllowed={picker.isFilteringAllowed}
      label={picker.label}
      libraryType={picker.libraryType}
      maxSelections={picker.maxSelections}
      onCancel={picker.onCancel}
      onChange={picker.onChange}
      refs={picker.refs}
      select={picker.select}
      setSpinnersCount={picker.setSpinnersCount}
      showImageUploader={picker.showImageUploader}
      spinnersCount={picker.spinnersCount}
    />
  );
};
