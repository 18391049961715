export function throttle<T extends () => void = () => void>(
  func: T,
  limit = 100
): T {
  let timer: ReturnType<typeof setTimeout>;
  let lastRun = 0;
  return ((...args) => {
    if (!lastRun) {
      func(...args);
      lastRun = Date.now();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (Date.now() - lastRun >= limit) {
          func(...args);
          lastRun = Date.now();
        }
      }, limit - (Date.now() - lastRun));
    }
  }) as T;
}
