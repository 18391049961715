import React from 'react';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { Button } from 'DesignSystem/Form';

interface HeaderActionsProps {
  handleSave: () => void;
  isSavingDisabled?: boolean;
}
export const HeaderActions = ({
  isSavingDisabled = false,
  handleSave,
}: HeaderActionsProps): JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Button
        icon={<Icon name="SaveSuccess" />}
        title="Save"
        label="Save"
        onClick={handleSave}
        disabled={isSavingDisabled}
      />
    </div>
  );
};

HeaderActions.defaultProps = {
  isSavingDisabled: false,
};
