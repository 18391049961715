import { Image } from 'shared/Image';
import { FileDropZone } from 'shared/FileDropZone';
import * as React from 'react';
import { useImageUploader } from 'hooks/useImageUploader';
import { useProgram } from 'contexts/program';
import { ImageData } from 'models/image';
import { Video } from 'models/video';
import styles from './Uploader/video.module.css';

export const PreviewUploader: React.FC<{
  setImage: (image: ImageData) => void;
  shouldClear?: boolean;
  video?: Video;
}> = ({ setImage, shouldClear, video }) => {
  const { id: programId } = useProgram();
  const uploader = useImageUploader({ onUpload: setImage, programId });

  return (
    <FileDropZone
      accept="image/*"
      onFileSelect={uploader.update}
      shouldClear={shouldClear}
    >
      <Image
        isUploading={uploader.isUploading}
        url={video && (video.customPreviewImageUrl || video.previewImageUrl)}
        loadingClassName={styles.loading}
        imageClassName={styles.image}
        placeholderClassName={styles.placeholder}
        placeholderIconName="ImagePlaceholder"
      />
    </FileDropZone>
  );
};
