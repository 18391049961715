import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { Initiative } from 'models/initiative';
import { BulkSelection, InitiativeBulkActionFilters } from 'hooks/common';
import { EmptyablePage, Page } from './common';
import { request, deepCamelcaseKeys } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  query?: string;
  page?: number;
  pageSize?: number;
  statuses?: string[];
};

export type InitiativesCollectionData = Page<InitiativeData>;
export type InitiativesEmptyableCollectionData = EmptyablePage<InitiativeData>;

export type InitiativeData = {
  id: string;
  type: string;
  attributes: Initiative;
};

export const fetchInitiatives = async (
  props: FetchProps
): Promise<InitiativesCollectionData> => {
  const { programId, query, page, pageSize, statuses } = props;

  const q = qs.stringify(snakecaseKeys({ query, page, pageSize, statuses }), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags?${q}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching initiatives: ${response.status}`);
};

const getFiltersQueryParams = (
  filterConfig?: InitiativeBulkActionFilters
): string => {
  if (!filterConfig) return '';

  const { search, statuses } = filterConfig;
  return qs.stringify(
    snakecaseKeys({
      query: search,
      statuses,
    }),
    { arrayFormat: 'brackets' }
  );
};

export const archiveInitiatives = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig?: InitiativeBulkActionFilters
): Promise<Initiative> => {
  const query = getFiltersQueryParams(filterConfig);
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags/archive/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error archiving initiatives: ${response.status}`);
};

export const unarchiveInitiatives = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: InitiativeBulkActionFilters
): Promise<Initiative> => {
  const query = getFiltersQueryParams(filterConfig);
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags/unarchive/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error unarchiving initiatives: ${response.status}`);
};

export const activateInitiatives = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig?: InitiativeBulkActionFilters
): Promise<Initiative> => {
  const query = getFiltersQueryParams(filterConfig);
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags/active/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error active initiatives: ${response.status}`);
};

export const deleteInitiatives = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig?: InitiativeBulkActionFilters
): Promise<Initiative> => {
  const query = getFiltersQueryParams(filterConfig);
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags/delete/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error delete initiatives: ${response.status}`);
};

export const fetchById = async (
  programId: number,
  initiativeId: number
): Promise<Initiative> => {
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags/${initiativeId}`;
  const response = await request(url);
  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }
  throw new Error(`Error fetching initiative: ${response.status}`);
};

export const updateInitiative = async (
  programId: number,
  initiative: Initiative
): Promise<Initiative> => {
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags/${initiative.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify({
      id: initiative.id,
      data: { attributes: snakecaseKeys(initiative) },
    }),
  });

  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }

  throw new Error(`Error updating initiative: ${response.status}`);
};

export const createInitiative = async (
  programId: number,
  initiative: Partial<Initiative>
): Promise<Initiative> => {
  const url = `${apiRoot}/samba/programs/${programId}/initiative_tags`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      data: { attributes: snakecaseKeys(initiative) },
    }),
  });

  if (response.status === 200) {
    const output = await response.json();
    return deepCamelcaseKeys(output.data.attributes);
  }

  throw new Error(`Error creating initiative: ${response.status}`);
};
