import React, { FC, useMemo } from 'react';
import { useFeedPreviewQuery } from 'hooks/feedPreview';
import { Topic } from 'models/topic';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useTopicApiId } from '../../../../../useTopicApiId';
import { useTopicFormCtx } from '../../../../context';
import { BaseTab } from '../BaseTab';
import { PostsFeed } from './Post';
import { PinnedPostsDisplay } from './PinnedPostsDisplay';
import usePinnedFeedPreview from './usePinnedFeedPreview';
import styles from './styles.module.css';

interface PostsTabProps {
  topic: Partial<Topic>;
}

const Posts: FC<PostsTabProps> = ({ topic }) => {
  const {
    state: { fields },
  } = useTopicFormCtx();

  const topicId = useTopicApiId(topic);

  const { isLoading, data } = useFeedPreviewQuery(topicId);
  const posts = data?.data;
  const {
    isLoading: isPinnedLoading,
    data: pinnedPosts,
  } = usePinnedFeedPreview(topicId, fields.pinnedPosts);

  const unpinnedPreviews = useMemo(() => {
    const pinnedItems = (pinnedPosts || []).reduce((acc, curr) => {
      acc[curr.id] = true;
      return acc;
    }, {} as Record<number, boolean>);

    return (posts ?? []).filter((p) => !pinnedItems[p.id]);
  }, [posts, pinnedPosts]);

  return (
    <BaseTab currentTab="posts">
      {isLoading ? (
        <div className={styles.Loading}>
          <LoadingSpinner size="medium" />
        </div>
      ) : (
        <>
          {isPinnedLoading && !pinnedPosts.length ? null : (
            <PinnedPostsDisplay contents={pinnedPosts} />
          )}
          {(unpinnedPreviews.length > 0 ||
            (!isPinnedLoading && pinnedPosts.length === 0)) && (
            <PostsFeed contents={unpinnedPreviews} />
          )}
        </>
      )}
    </BaseTab>
  );
};

export default Posts;
