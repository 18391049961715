import * as React from 'react';
import { CollectionsContext } from '../contexts/CollectionsContext';
import { useCollectionLinks } from './useCollectionLinks';

export function useCollectionTabs(): ReturnType<typeof useCollectionLinks> {
  const {
    customCollectionsQuery,
    bookmarkedCollectionQuery,
  } = React.useContext(CollectionsContext);

  const collections = React.useMemo(() => customCollectionsQuery.data || [], [
    customCollectionsQuery,
  ]);

  const featuredCollections = React.useMemo(
    () =>
      collections.filter(
        ({ tags }) =>
          !tags.some((tag) => tag.indexOf('legacy') === 0) &&
          tags.filter((tag) => tag.indexOf('featured') === 0).length > 0
      ),
    [collections]
  );

  return useCollectionLinks({
    customCollections: featuredCollections,
    bookmarkedCollection: bookmarkedCollectionQuery.data,
  });
}
