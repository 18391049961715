import { useQuery, useInfiniteQuery } from 'react-query';
import {
  BulkUpdatePermissionsJobCollectionData,
  fetchBulkUpdateJobs,
  FetchProps,
  exportErrorReportCsv,
  fetchBulkUpdatePermissionsJobById,
} from 'services/api-bulk-update-permissions';
import { BulkUpdatePermissionsJob } from 'models/bulk-update-permissions-job';
import {
  QueryResponse,
  InfiniteQueryResponse,
  MutationOptions,
} from './common';

export function mapServerDataToBulkUpdate(
  serverData: BulkUpdatePermissionsJobCollectionData
): Array<BulkUpdatePermissionsJob> {
  return serverData.data.map((entity: BulkUpdatePermissionsJob) => entity);
}

export const useBulkUpdateJobsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<BulkUpdatePermissionsJob> => {
  const { programId, states, pageSize = 10 } = props;
  const key = 'bulk-updates-infinite';
  const {
    error,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<BulkUpdatePermissionsJobCollectionData, Error>(
    [key, JSON.stringify(props)],
    async ({ pageParam = 1 }) =>
      fetchBulkUpdateJobs({
        programId,
        states,
        page: { number: pageParam, size: pageSize },
      }),
    {
      getNextPageParam: (lastGroup) => {
        if (lastGroup && lastGroup.meta.pageNumber < lastGroup.meta.totalPages)
          return lastGroup.meta.pageNumber + 1;

        return undefined;
      },
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToBulkUpdate(batch) : []))
      .flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
  };
};

export const useBulkUpdatePermissionsJobQuery = (
  programId: number,
  id: number
): QueryResponse<BulkUpdatePermissionsJob> => {
  const { isLoading, error, data } = useQuery<BulkUpdatePermissionsJob, Error>({
    queryKey: `bulk-update-permissions-job-${programId}-${id}`,
    queryFn: () => fetchBulkUpdatePermissionsJobById(programId, id),
    cacheTime: 0,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useGenerateErrorReport = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Blob> = {}
): { download: (jobId: number) => void } => {
  const download = (jobId: number) => {
    exportErrorReportCsv(programId, jobId)
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { download };
};
