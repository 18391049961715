/* eslint-disable max-classes-per-file */
import { DateTime } from 'luxon';
import { LocalStore } from './local-store';

// state types
const VERSION = 1;
export type Schema = {
  version: typeof VERSION;
  records: Record[];
};
export type Record = {
  programId: number;
  userId: number;
  resourceId: string;
  kind: Resource;
  usageCount: number;
  createdAt: string;
  accessedAt: string;
};
export enum Resource {
  audience = 'audience',
  topic = 'topic',
}
type CompareFn = (a: Record, b: Record) => number;

// Sorts if a function is provided, and slices if a positive number is provided.
// Just avoiding repeating these couple lines of code in each function as this grows.
function sortedSlice(records: Record[], limit: number, compareFn?: CompareFn) {
  if (compareFn) records.sort(compareFn);
  return limit > 0 ? records.slice(0, limit) : records;
}

//-----------
// the smarts
class UsageImplementation {
  constructor(
    public schema: Schema,
    private readonly programId: number,
    private readonly userId: number
  ) {}

  // sorts by the usageCount number
  most(kind: Resource, limit: number) {
    const records = this.filter(kind);
    const compare: CompareFn = (a, b) => b.usageCount - a.usageCount;
    return sortedSlice(records, limit, compare);
  }

  recent(kind: Resource, limit: number) {
    const recordsByUsage = this.most(kind, 0);
    const since = DateTime.now()
      .minus({ days: 30 })
      .toFormat(UsageImplementation.DATE_FORMAT);
    // Because our date format is compare-friendly, we don't need to
    // create actual date objects, which should speed this up for large sets.
    const recent = recordsByUsage.filter(
      (r) => r.accessedAt.localeCompare(since) >= 0
    );
    const compareAccessTimes: CompareFn = (a, b) =>
      b.accessedAt.localeCompare(a.accessedAt);
    return sortedSlice(recent, limit, compareAccessTimes);
  }

  updateAccess(kind: Resource, id: string) {
    const item = this.find(kind, id);
    item.usageCount += 1;
    item.accessedAt = DateTime.now().toFormat(UsageImplementation.DATE_FORMAT);
    return this.schema;
  }

  // find by kind and id, creating if necessary
  private find(kind: Resource, id: string) {
    const found = this.filter(kind).find((r) => r.resourceId === id);
    if (found) return found;
    const record = {
      programId: this.programId,
      userId: this.userId,
      kind,
      resourceId: id,
      usageCount: 0,
      createdAt: DateTime.now().toFormat(UsageImplementation.DATE_FORMAT),
      accessedAt: '',
    };
    this.schema.records.push(record);
    return record;
  }

  // filter by kind
  private filter(kind: Resource) {
    return this.schema.records.filter(
      (r) =>
        r.programId === this.programId &&
        r.userId === this.userId &&
        r.kind === kind
    );
  }

  private static DATE_FORMAT = 'yyyy-MM-dd';
}

//------------------------
// client module interface
export class Usage {
  constructor(
    private readonly programId: number,
    private readonly userId: number
  ) {}

  private store = new LocalStore<Schema>('usage-records');

  static Kind = Resource;

  // methods expected to be called by the client
  recentlyUsed(kind: Resource, limit = 5): string[] {
    return this.lookupUsage('recent', kind, limit);
  }

  mostUsed(kind: Resource, limit = 5): string[] {
    return this.lookupUsage('most', kind, limit);
  }

  recordUse(kind: Resource, id: string): void {
    this.updateUsage('updateAccess', kind, id);
  }

  // low level functions for dealing with the schema manipulation
  private lookupUsage(
    method: 'recent' | 'most',
    kind: Resource,
    limit: number
  ) {
    const usage = new UsageImplementation(
      this.read(),
      this.programId,
      this.userId
    );
    const records = usage[method](kind, limit);
    return records.map((r) => r.resourceId);
  }

  private updateUsage(method: 'updateAccess', kind: Resource, id: string) {
    const usage = new UsageImplementation(
      this.read(),
      this.programId,
      this.userId
    );
    usage[method](kind, id);
    this.write(usage.schema);
  }

  // low level functions for dealing with the schema storage
  private read(): Schema {
    const data = this.store.read();
    // In the future we may need to update or migrate this data as needs change.
    // All of the initial reads pass through this function, so we can invent
    // whatever migration magic is needed, and hook it up here.
    if (data && data.version === VERSION) return data;
    return { version: VERSION, records: [] };
  }

  private write(schema: Schema) {
    if (schema && schema.version === VERSION) {
      // last-ditch effort to prevent saving something unexpected
      this.store.write(schema);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Skipping write(): unexpected schema version',
        schema?.version
      );
    }
  }
}
