import type { Editor } from '@tiptap/react';
import React from 'react';
import { ToolbarButton } from './shared/ToolbarButton';

export const EditorActionStrike: React.FC<{ editor: Editor }> = ({
  editor,
}) => (
  <ToolbarButton
    onClick={() => editor.chain().focus().toggleStrike().run()}
    disabled={!editor.can().chain().focus().toggleStrike().run()}
    isActive={editor.isActive('strike')}
    icon="format_strikethrough"
    aria-label="Strike"
  />
);
