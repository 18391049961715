import React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { PageTemplate } from 'DesignSystem/Layout/Pages';
import { CollectionsProvider } from 'App/Program/Main/Insight/contexts/CollectionsContext';
import { DownloadsPage } from 'App/Program/Main/Insight/pages/Downloads';
import { BookmarksPage } from 'App/Program/Main/Insight/pages/Bookmarks';
import { ReportPage } from 'App/Program/Main/Insight/pages/Report';
import { ReportProvider } from 'App/Program/Main/Insight/contexts/ReportContext';
import { WithPermission } from 'shared/WithPermission';
import { OverviewPage } from './pages/Overview';
import { CollectionPage } from './pages/Collection';
import { CollectionsSidebarTheme } from './pages/CollectionsSidebarTheme';

/*
 * Make note that the Reach Router navigates in a directory fashion
 * be especially careful when redirecting. Make sure to test thoroughly
 * Also be aware that the shared <SubNavLinks> component navigates up a directory
 * see src/App/Program/Main/theme/SubNavLinks.tsx#L14
 */
export const Insight: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['insightsAccess']}>
    <PageTemplate title="Insights - Overview">
      {/* primary={false} ensures that the page scrolls to the top between page navigations */}
      {/* https://github.com/reach/router/issues/242 */}
      <Router primary={false}>
        {/*
            The provider intercepts the collectionId path param
            And renders the CollectionPage with the proper reference to the collections state
          */}
        <CollectionsProvider path="collections">
          <CollectionsSidebarTheme path="overview">
            <OverviewPage path="/" />
          </CollectionsSidebarTheme>

          <CollectionsSidebarTheme path="saved">
            <BookmarksPage path="/" />
          </CollectionsSidebarTheme>

          <CollectionsSidebarTheme path=":collectionId">
            <CollectionPage path="/" />
          </CollectionsSidebarTheme>
        </CollectionsProvider>

        <CollectionsProvider path="/">
          <DownloadsPage path="downloads" />
          <DownloadsPage path="downloads/:folder" />
        </CollectionsProvider>

        <ReportProvider path="report/:reportId">
          <ReportPage path="/" />
        </ReportProvider>

        <Redirect from="/report" to=".." noThrow />
        <Redirect from="/" to="collections/overview" noThrow />
        <Redirect from="*" to="../" default noThrow />
      </Router>
    </PageTemplate>
  </WithPermission>
);
