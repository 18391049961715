import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as React from 'react';
import { Modal } from 'shared/Modal';
import styles from '../styles.module.css';

export const RevertToGlobalStyle: React.FC<{
  revert: () => void;
}> = ({ revert }) => {
  const [isShowingModal, setIsShowingModal] = React.useState(false);
  const openModal = React.useCallback(() => {
    setIsShowingModal(true);
  }, []);
  const closeModal = React.useCallback(() => {
    setIsShowingModal(false);
  }, []);
  const revertAndClose = React.useCallback(() => {
    revert();
    closeModal();
  }, [closeModal, revert]);

  return (
    <div className={styles.revertToGlobal}>
      {isShowingModal && (
        <Modal
          title="Revert block styles"
          showTitle
          showModal={isShowingModal}
          modalBodyStyle={{ maxHeight: '99%', overflow: 'auto' }}
          onClose={closeModal}
        >
          <Flex column>
            <div>
              Are you sure you want to update this block to use the global
              styles?
            </div>
            <Flex spread className={styles.modalButtonRow}>
              <Button secondary label="Cancel" onClick={closeModal} />
              <Button label="Revert" onClick={revertAndClose} />
            </Flex>
          </Flex>
        </Modal>
      )}
      <Button
        className={styles.revertToGlobalButton}
        compact
        text
        label="Revert block styles"
        onClick={openModal}
      />
    </div>
  );
};
