import {
  MessagesType,
  useBasicValidator,
  ValidateFunc,
  ValidatorsType,
} from './useBasicValidator';
import { useProgram } from '../contexts/program';
import { humanFileSize } from '../utility/human';
import { ALLOWED_MIME_TYPES, DEFAULT_MAX_FILE_SIZE } from '../models/video';

type ValidatorType = {
  file: File;
};

const validators: ValidatorsType<ValidatorType> = {
  allowed_extension_type: ({ file }) => {
    return !ALLOWED_MIME_TYPES.includes(file.type);
  },
};

const errorMessages: MessagesType = {
  ALLOWED_EXTENSION_TYPE: 'Video format is not supported.',
};

export const useVideoValidator: () => {
  validate: ValidateFunc<ValidatorType>;
} = () => {
  const { maxVideoUploadSizeInBytes = DEFAULT_MAX_FILE_SIZE } = useProgram();

  const mergedValidators: ValidatorsType<ValidatorType> = {
    ...validators,
    max_size: ({ file }) => file.size > maxVideoUploadSizeInBytes,
  };
  const mergedErrorMessages: MessagesType = {
    ...errorMessages,
    MAX_SIZE: `Video must be smaller than ${humanFileSize(
      maxVideoUploadSizeInBytes
    )}. Please upload a smaller video.`,
  };

  return useBasicValidator<ValidatorType>(
    mergedValidators,
    mergedErrorMessages
  );
};
