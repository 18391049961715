import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Sortable } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { SocialData } from 'models/publisher/block';
import { LinkPreview } from '../../shared/LinkPreview';

export const List: React.FC<{
  onSelect: (index: number) => void;
  onUpdate: (links: SocialData[]) => void;
  links: SocialData[];
}> = ({ links, onSelect, onUpdate }) => {
  const remove = React.useCallback(
    (index: number) => {
      links.splice(index, 1);
      onUpdate([...links]);
    },
    [links, onUpdate]
  );

  const handleOnCreate = React.useCallback(() => {
    onSelect(links.length);
  }, [links, onSelect]);

  return (
    <>
      <Sortable
        items={links}
        idAttr="url"
        onSort={onUpdate}
        Item={({ item, index }) => (
          <LinkPreview
            key={item.url}
            link={item}
            onEdit={() => onSelect(index)}
            onRemove={() => remove(index)}
          />
        )}
      />
      <br />
      <Button
        onClick={handleOnCreate}
        block
        icon={<Text.PageHeading color={Text.color.gray00}>+</Text.PageHeading>}
        label="Add Social Links"
      />
    </>
  );
};
