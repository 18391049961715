import React from 'react';

type Status = Record<string, boolean>;
type Return = {
  update: (blockId: string, valid: boolean) => void;
  get: (blockId: string) => boolean;
  remove: (blockId: string) => void;
  isValid: boolean;
};

export const useRenderStatus = (): Return => {
  const [status, setStatus] = React.useState<Status>({});

  const update = React.useCallback(
    (blockId: string, valid: boolean) =>
      setStatus((old) => ({ ...old, [blockId]: valid })),
    []
  );

  const remove = React.useCallback(
    (blockId: string) =>
      setStatus((old) => {
        const newState = { ...old };
        delete newState[blockId];
        return newState;
      }),
    []
  );

  const get = React.useCallback((blockId: string) => status[blockId], [status]);

  const isValid = React.useMemo(
    () => Object.entries(status).every(([, validity]) => validity),
    [status]
  );

  return { update, get, remove, isValid };
};

export const RenderStatusContext = React.createContext(
  {} as ReturnType<typeof useRenderStatus>
);

RenderStatusContext.displayName = 'RenderStatusContext';
