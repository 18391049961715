import { DateTime } from 'luxon';
import React, { CSSProperties, useState } from 'react';

import { MetricsTooltip } from 'App/Program/Main/ContentMetricsTooltip/MetricsTooltip';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import * as Text from 'DesignSystem/Typography';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Content, isActiveContent, isProcessing } from 'models/content';
import { priorityLabel } from 'models/priority';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import buttonStyles from 'shared/styles/buttons.module.css';
import { convertSnakeCaseToSentenceCase, titleCase } from 'utility/text';

import { useContentActions } from './useContentActions';
import styles from './content-banner.module.css';
import {
  ContentTopicsAndAudiences,
  ContentTopicsAndAudiencesPropsType,
} from './ContentTopicsAndAudiences';
import { CommentsModal } from './CommentsModal';
import { FlaggedCampaignModal } from './FlaggedCampaignModal/FlaggedCampaignModal';
import { FlaggedContentButton } from './FlaggedContentButton';

type ContentBannerProps = {
  content: Content;
  reach?: number;
  disableCommentsLinks?: boolean;
  disableContentActions?: boolean;
  isSelectable?: boolean;
  processingState?: string;
};

export function getTopicsAndAudiences(
  content: Content
): ContentTopicsAndAudiencesPropsType['topicsAndAudiences'] {
  const topics = (content.contentTopics || []).map((topic) => {
    return {
      id: `channel-${topic.id}`,
      name: topic.name,
      count: topic.followerCount,
    };
  });

  const audiences = (content.contentCommunication?.audiences || []).map(
    (audience) => {
      return {
        id: `audience-${audience.id}`,
        name: audience.title,
        count: audience.totalUsers,
      };
    }
  );

  return topics.concat(audiences);
}

export const ContentBanner: React.FC<ContentBannerProps> = ({
  content,
  reach,
  disableCommentsLinks,
  disableContentActions,
  isSelectable = true,
  processingState,
}) => {
  const { id: programId } = useProgram();

  const flagPostEnabled = !!useFeatureFlagsQuery(
    programId,
    'EE.FlagPost.Enabled'
  ).data?.value;

  const thumbnail = {
    title: content.title,
    backgroundColor: '#b8a0b6',
    imageUrl: content.imageUrl || undefined,
  };
  const {
    permissions: { moderateCommentAccess, moderateContentAccess },
  } = usePermissions();
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showFlaggedCampaignModal, setShowFlaggedCampaignModal] = useState(
    false
  );
  const { menuItems, deleteItem } = useContentActions(content);

  const topicsAndAudiences = getTopicsAndAudiences(content);
  const hasFlaggedComments =
    (content.contentCommentThread?.commentReportCount ?? 0) > 0;
  const flaggedContentCount =
    content.contentReporters?.contentReportersCount ?? 0;
  const hasComments =
    content.contentCommentThread?.commentCount &&
    content.contentCommentThread?.commentCount > 0;

  const showCommentsLink =
    !disableCommentsLinks && hasComments && moderateCommentAccess;
  const showFlagsLink = flagPostEnabled && moderateContentAccess;
  const state = React.useMemo(() => {
    return isProcessing(content)
      ? titleCase(content.processingState)
      : convertSnakeCaseToSentenceCase(
          content.publicationState.replace('for_review', 'needs_review')
        );
  }, [content]);

  const { data: reportVersion } = useFeatureFlagsQuery(
    programId,
    'Planner.Metrics.ContentReportVersion'
  );

  const displayViewers = (reportVersion?.value as string) === 'non_migrated';

  return (
    <>
      <EmptyBanner
        thumbnail={thumbnail}
        menuItems={disableContentActions ? [] : menuItems}
        rowId={`content-${content.id}`}
        disabledClick={content.publicationState === 'erased'}
        deleteItem={disableContentActions ? undefined : deleteItem}
        isSelectable={isSelectable}
      >
        <ContentBannerHeader
          priority={
            priorityLabel(content?.contentCommunication?.priority) || ''
          }
          title={content.title || content.summary}
          publishedAt={content.publishedAt}
          displayName={content.contentAuthor.displayName}
        />

        <div className={styles.reachWrapper}>
          {isActiveContent(content) && !processingState ? (
            <>
              <MetricsTooltip
                data={content}
                startDate={DateTime.fromISO(content.createdAt)}
                offset="-160px"
                closeDelay={100}
                openDelay={250}
                disableDropdown={content.version === 1}
              >
                <Text.Body nobr>
                  {(reach || 0).toLocaleString()}{' '}
                  {displayViewers ? 'Viewers' : 'Reached Users'}
                </Text.Body>
              </MetricsTooltip>

              {showCommentsLink ? (
                <div
                  aria-hidden="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!moderateCommentAccess) return;
                    setShowCommentsModal(true);
                  }}
                >
                  <div
                    className={
                      hasFlaggedComments ? buttonStyles.flaggedWithIcon : ''
                    }
                  >
                    <Text.Body underline nobr>
                      {(
                        content.contentCommentThread?.commentCount || 0
                      ).toLocaleString()}{' '}
                      comments
                    </Text.Body>
                  </div>
                </div>
              ) : null}
              {showFlagsLink && (
                <FlaggedContentButton
                  flagCount={flaggedContentCount}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFlaggedCampaignModal(true);
                  }}
                />
              )}
            </>
          ) : (
            <div className={styles.state}>{processingState || state}</div>
          )}
        </div>
        <div className={styles.details}>
          <ContentTopicsAndAudiences
            topicsAndAudiences={topicsAndAudiences || []}
          />
        </div>
      </EmptyBanner>
      {showCommentsModal && (
        <CommentsModal
          postId={content.id}
          programId={programId}
          onClose={() => setShowCommentsModal(false)}
        />
      )}
      {showFlaggedCampaignModal && (
        <FlaggedCampaignModal
          content={content}
          programId={programId}
          onClose={() => setShowFlaggedCampaignModal(false)}
        />
      )}
    </>
  );
};

export function ContentBannerHeader({
  title,
  publishedAt,
  displayName,
  priority,
  width,
}: {
  title: Content['title'] | Content['summary'];
  publishedAt: Content['publishedAt'];
  displayName: Content['contentAuthor']['displayName'];
  priority?: ReturnType<typeof priorityLabel>;
  width?: CSSProperties['width'];
}): JSX.Element {
  return (
    <div style={{ width }} className={styles.header}>
      <div className={styles.title} title={title}>
        {title}
      </div>
      <div className={styles.publishedAt}>
        <span className={styles.strong}>
          {publishedAt
            ? DateTime.fromISO(publishedAt).toFormat('MMM d, hh:mm a')
            : 'Unscheduled draft '}
        </span>
        by&nbsp;
        <span className={styles.strong}>{displayName}</span>
      </div>
      {priority && (
        <div className={styles.priority}>{priorityLabel(priority) || ''}</div>
      )}
    </div>
  );
}

ContentBannerHeader.defaultProps = {
  priority: undefined,
  width: undefined,
};
