import React, { FC, useCallback } from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import { MAIcon } from 'shared/MAIcon';
import { TabName } from '../../../../context';
import styles from './styles.module.css';

interface Props {
  value: TabName;
  active: boolean;
  home?: boolean;
  persistent?: boolean;
  onClick: (tab: TabName) => void;
  onDisable: (tab: TabName) => void;
}
const EnabledItem: FC<Props> = ({
  value,
  active,
  home,
  persistent,
  onClick,
  onDisable,
}) => {
  const handleChange = useCallback(() => {
    onDisable(value);
  }, [onDisable, value]);

  const handleSwitchClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick(value);
      }
    },
    [onClick, value]
  );

  return (
    <div
      tabIndex={0}
      role="button"
      className={active ? styles.ActiveItem : styles.EnabledItem}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      <MAIcon className={styles.DragIcon} name="drag_indicator" />
      <div className={styles.ItemTitle}>{value}</div>
      {home && <MAIcon className={styles.HomeIcon} name="home" />}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className={styles.ItemToggle} onClick={handleSwitchClick}>
        {!persistent && <FlipSwitch on value={value} onChange={handleChange} />}
      </div>
    </div>
  );
};

export default EnabledItem;
