import React, { CSSProperties } from 'react';
import { Design } from 'models/design';
import { EmptyBanner, MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import styles from 'components/content/ContentBanner/content-banner.module.css';
import { DateTime } from 'luxon';

type DesignBannerProps = {
  design: Design;
  disableContentActions?: boolean;
  programId: number;
};

type DesignBannerHeaderProps = {
  name?: Design['name'];
  updatedAt?: Design['updatedAt'];
  width?: CSSProperties['width'];
};

export const DesignBannerHeader: React.FC<DesignBannerHeaderProps> = ({
  name,
  updatedAt,
  width,
}) => {
  return (
    <div style={{ width }} className={styles.header}>
      <div className={styles.title} title={name}>
        {name}
      </div>
      {updatedAt && (
        <div className={styles.publishedAt}>
          <span className={styles.strong}>
            {DateTime.fromISO(updatedAt).toFormat('MMM d, hh:mm a')}
          </span>
        </div>
      )}
    </div>
  );
};

export const DesignBanner: React.FC<DesignBannerProps> = ({
  design,
  disableContentActions,
  programId,
}) => {
  const menuItems: MenuItemsType = React.useMemo(() => {
    return [
      {
        title: 'View Design',
        href: `/${programId}/app/designs/edit/${design.id}`,
      },
    ]; // TODO: Add content actions as defined
  }, [design, programId]);

  return (
    <EmptyBanner
      menuItems={disableContentActions ? [] : menuItems}
      rowId={`content-${design.id}`}
    >
      <DesignBannerHeader
        name={design.name || `unnamed design ${design.name}`}
        updatedAt={design.updatedAt} // TODO: Fix model translation from request
      />
    </EmptyBanner>
  );
};
