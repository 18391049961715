import * as React from 'react';
import { Button } from 'DesignSystem/Form';
import styles from './layout.module.css';

export type ActionData = {
  icon?: React.ReactElement;
  label: string;
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
  layoutOnly?: boolean;
  isLoading?: boolean;
};

export const Actions: React.FC<{
  actions: ActionData[];
}> = ({ actions }) => {
  const [first, ...rest] = actions;
  return (
    <div className={styles.Actions}>
      <Button
        icon={first.icon}
        title={first.label}
        label={first.label}
        onClick={first.onClick}
        href={first.to}
        disabled={first.disabled}
        layoutOnly={first.layoutOnly}
        isLoading={first.isLoading}
      />
      {rest.map((action) => (
        <Button
          key={action.label}
          icon={action.icon}
          title={action.label}
          label={action.label}
          onClick={action.onClick}
          href={action.to}
          disabled={action.disabled}
          isLoading={action.isLoading}
          secondary
        />
      ))}
    </div>
  );
};
