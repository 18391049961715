import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { PublisherProvider } from 'contexts/publisher';
import { useProgram } from 'contexts/program';
import { ContentResultsPage } from './ContentResultsPage';
import { useFeatureFlagsQuery } from '../../../../hooks/feature-flags';
import { Loading } from '../../../../components/publisher/blocks/instances/Loading';
import { ContentResultsPageV2 } from './ContentResultsPageV2';

export const ContentResults: React.FC<RouteComponentProps<{
  id?: string;
}>> = ({ id }) => {
  const program = useProgram();
  const { data: flag, isLoading } = useFeatureFlagsQuery(
    program.id,
    'Studio.Intelligence.CampaignSummaryV2.Enabled'
  );
  const metricsV2Enabled = flag?.value;

  return isLoading ? (
    <Loading />
  ) : (
    <PublisherProvider programId={program.id} id={Number(id)}>
      {metricsV2Enabled ? <ContentResultsPageV2 /> : <ContentResultsPage />}
    </PublisherProvider>
  );
};
