import React from 'react';
import { DismissType } from 'DesignSystem/Components/ClickDropdown';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import { Body, Caption } from 'DesignSystem/Typography';
import { InfiniteQueryResponse } from 'hooks/common';
import { CustomSlug } from 'models/custom-slug';
import { InfiniteList } from 'shared/InfiniteList';

interface SlugDropdownProps {
  dismiss: DismissType;
  filteredSlugs: CustomSlug[];
  slugsQuery: InfiniteQueryResponse<CustomSlug>;
  linkedCustomSlugs: CustomSlug[];
  setLinkedCustomSlugs: (slugs: CustomSlug[]) => void;
  setSaveEnabled: (saveEnabled: boolean) => void;
}

export const SelectSlugDropdown: React.FC<SlugDropdownProps> = ({
  dismiss,
  filteredSlugs,
  slugsQuery,
  linkedCustomSlugs,
  setLinkedCustomSlugs,
  setSaveEnabled,
}) => {
  return (
    <Box width={480}>
      <Popover centered>
        <InfiniteList
          itemCount={filteredSlugs.length}
          itemHeight={40}
          height={200}
          highlightable
          isLoading={slugsQuery.isLoading}
          hasNextPage={slugsQuery.hasNextPage}
          fetchNextPage={slugsQuery.fetchNextPage}
          isFetchingNextPage={slugsQuery.isFetchingNextPage}
          overscan={10}
        >
          {(index: number) => {
            const slug = filteredSlugs[index];
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                padding={[0, 8]}
                height={40}
                onClick={() => {
                  setLinkedCustomSlugs([...linkedCustomSlugs, slug]);
                  setSaveEnabled(true);
                  dismiss();
                }}
              >
                <Box
                  margin={[0, 16, 0, 0]}
                  maxWidth="50%"
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Body>{slug.name}</Body>
                </Box>
                <Box
                  maxWidth="50%"
                  style={{
                    marginLeft: 'auto',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <Caption>
                    {slug.content
                      ? `Pointing to: ${slug.content.title}`
                      : 'Unassigned'}
                  </Caption>
                </Box>
              </Box>
            );
          }}
        </InfiniteList>
      </Popover>
    </Box>
  );
};
