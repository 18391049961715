import { ComponentProps } from 'react';
import { PageHeader } from 'DesignSystem/Layout/Pages';
import { PermissionCapabilities } from 'models/permissions';

export const getBoxConfigurationPageTabs: (
  permissions: PermissionCapabilities
) => ComponentProps<typeof PageHeader>['tabs'] = (permissions) => {
  const tabs = [
    { to: 'box-manage-folders', label: 'Manage Folders' },
    { to: 'box-manage-content', label: 'Manage Box Content' },
  ];

  if (permissions.boxKnowledgeManagementConfigureAppAccess) {
    tabs.push({ to: 'box-configure-app', label: 'Configure Box App' });
  }

  return tabs;
};
