import * as React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import styles from './component-layout.module.css';
import { Weeks } from '../DateRangeInput/Weeks';
import { DateRange, Timezone } from '../DateRangeInput/DateRange';
import { ArrowLeft, ArrowRight } from '../icons';
import { FlipSwitch } from '../FlipSwitch';

type PropsType = {
  className?: string;
  calendarMonth: DateTime;
  dateRange: DateRange;
  hideWeekend?: boolean;
  toggleWeekend: () => void;
  previousCalendarYear: () => void;
  nextCalendarYear: () => void;
  setTimezone: (zone: Timezone) => void;
  setDateRange: (dr: DateRange) => void;
  setCurrentRangeDate: (dr: DateTime) => void;
  setVisibleMonth: (m: DateTime) => void;
};

export const ComponentLayout: React.FC<PropsType> = (props) => {
  const {
    setCurrentRangeDate,
    calendarMonth,
    className,
    dateRange,
    hideWeekend,
    toggleWeekend,
    previousCalendarYear,
    nextCalendarYear,
    setVisibleMonth,
  } = props;
  const monthName = React.useMemo(() => calendarMonth.toFormat('LLLL'), [
    calendarMonth,
  ]);
  const allMonthNames = React.useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    []
  );

  const monthNameComponent = React.useCallback(
    (name) => {
      const diff =
        allMonthNames.indexOf(monthName) - allMonthNames.indexOf(name);
      return (
        <button
          type="button"
          key={`month-name-button-${name}`}
          onClick={() => setVisibleMonth(calendarMonth.minus({ months: diff }))}
          className={cx(styles.monthName, {
            [styles.isActiveMonth]: name === monthName,
          })}
        >
          {name}
        </button>
      );
    },
    [allMonthNames, monthName, setVisibleMonth, calendarMonth]
  );

  const selectCurrentWeek = React.useCallback(() => {
    setCurrentRangeDate(DateTime.now());
  }, [setCurrentRangeDate]);

  return (
    <div className={cx(styles.layout, className)}>
      <div className={styles.main}>
        <div className={styles.body}>
          <div className={styles.year}>
            <h3>{calendarMonth.toFormat('yyyy')}</h3>
            <div className={styles.controls}>
              <button onClick={previousCalendarYear} type="button">
                <ArrowLeft />
              </button>
              <button onClick={nextCalendarYear} type="button">
                <ArrowRight />
              </button>
            </div>
          </div>
          <div className={styles.month}>
            {allMonthNames.map((n) => monthNameComponent(n))}
          </div>
          <div>
            <Weeks
              onDateClick={setCurrentRangeDate}
              month={calendarMonth}
              dateRange={dateRange}
              hideWeekend={hideWeekend}
            />
            <button
              type="button"
              onClick={selectCurrentWeek}
              className={styles.thisWeek}
            >
              This week
            </button>
          </div>
        </div>
        <div className={styles.footer}>
          <FlipSwitch onChange={toggleWeekend} on={!hideWeekend} />
          <span>Show weekends</span>
        </div>
      </div>
    </div>
  );
};
