import FroalaEditor, { FroalaEvents } from 'froala-editor';

enum ListNodeNames {
  ul = 'ul',
  ol = 'ol',
  li = 'li',
}

const CMD_INDENT = 'indent';
const CMD_OUTDENT = 'outdent';

export const froalaIndentationOptions: {
  events: Pick<FroalaEvents, 'commands.before' | 'buttons.refresh'>;
} = {
  events: {
    'commands.before': commandsBefore,
    'buttons.refresh': buttonsRefresh,
  },
};

/**
 * Only perform indentation override on list items
 *
 * @param selection {Selection}
 * @returns {boolean}
 */
const shouldPerform = (selection: Selection): boolean => {
  if (selection.isCollapsed) {
    const { anchorNode } = selection;
    switch (anchorNode?.nodeType) {
      case Node.TEXT_NODE:
        return !!anchorNode.parentElement?.closest('li');
      case Node.ELEMENT_NODE:
        return !!(anchorNode as HTMLElement).closest('li');
      default:
        return false;
    }
  }

  return isNodeOneOf(
    selection.getRangeAt(0).commonAncestorContainer,
    ListNodeNames.ul,
    ListNodeNames.ol,
    ListNodeNames.li
  );
};

/**
 * Performs indentation on the selected text
 *
 * @param   selection {Selection}
 */
const performIndent = (selection: Selection): void => {
  getListItemsForSelection(selection).some((listItem: HTMLLIElement) => {
    const {
      parentElement: parentList,
      previousElementSibling: previousListItem,
    } = listItem;
    const rootList = getRootList(listItem);
    const previousList = isNodeOneOf(
      previousListItem,
      ListNodeNames.ul,
      ListNodeNames.ol
    )
      ? previousListItem
      : previousListItem &&
        [...previousListItem.children].find((node) =>
          isNodeOneOf(node, ListNodeNames.ul, ListNodeNames.ol)
        );
    const childList = [...listItem.children].find((element) =>
      isNodeOneOf(element, ListNodeNames.ul, ListNodeNames.ol)
    );
    const newList = document.createElement(
      parentList?.nodeName?.toLowerCase() || 'ul'
    );

    // If the list item is the first list item in the root list,
    // everything gets indented.
    if (rootList.querySelector('li') === listItem) {
      const newRootList = document.createElement(
        rootList.nodeName.toLowerCase()
      );
      rootList.after(newRootList);
      newRootList.append(rootList);

      // The entire list has been indented, including all children of
      // the root list. Returning true to stop the iteration of remaining
      // list items.
      return true;
    }

    // If the list item has a child list, the list item becomes the first
    // child in the list.
    if (childList) {
      listItem.removeChild(childList);
      childList.prepend(listItem);

      if (previousListItem) {
        // If the list item has a previous sibling and the sibling has a list,
        // the children of the child list will be appended to the sibling list.
        // If the sibling does not have its own list, the child list will be
        // appended to the sibling.
        if (previousList) {
          previousList.append(...childList.children);
        } else {
          previousListItem.append(childList);
        }
      } else {
        // If there is no previous sibling, the child list will be prepended to the parent.
        parentList?.prepend(childList);
      }
      return false;
    }

    if (previousListItem) {
      // If the list item has a previous sibling and the sibling has a list,
      // the list item will be appended to the sibling list.
      // If the sibling doe snot have its own list, the list item will be
      // appended into a new list and that new list will be appended to the sibling.
      if (previousList) {
        previousList.append(listItem);
      } else {
        newList.append(listItem);
        previousListItem.append(newList);
      }
    } else {
      // If there is no previous sibling, the list item will be appended into a new
      // list and the new list will be prepended to the parent.
      newList.append(listItem);
      parentList?.prepend(newList);
    }
    return false;
  });
};

/**
 * Performs un-indentation on the selected text
 *
 * @param   selection {Selection}
 */
const performOutdent = (selection: Selection): void => {
  getListItemsForSelection(selection).forEach((listItem: HTMLLIElement) => {
    const { parentElement: parentList } = listItem;

    if (!isNodeOneOf(parentList, ListNodeNames.ul, ListNodeNames.ol)) return;

    const listItems = [...parentList.children];
    const previousListItems = listItems.slice(0, listItems.indexOf(listItem));
    const nextListItems = listItems.slice(listItems.indexOf(listItem) + 1);
    const childList = [...listItem.children].find((element) =>
      isNodeOneOf(element, ListNodeNames.ul, ListNodeNames.ol)
    );
    const nextList = childList
      ? document.createElement(childList.nodeName.toLowerCase())
      : document.createElement(parentList.nodeName.toLowerCase());

    // The previous list items can remain in their list.
    parentList.replaceChildren(...previousListItems);

    // If the list item has a child list, append it to the new list.
    if (childList) {
      listItem.removeChild(childList);
      nextList.append(childList);
    }

    // If next list items exist, append them to the new list.
    if (nextListItems.length) {
      nextList.append(...nextListItems);
    }

    if (
      !isNodeOneOf(
        parentList.parentElement,
        ListNodeNames.ul,
        ListNodeNames.ol,
        ListNodeNames.li
      )
    ) {
      // The parent list is at the root of the list hierarchy.

      // The outdented list item's text node will be wrapped in a paragraph element
      // and be the next sibling to the parent list.
      const p = document.createElement('p');
      p.append(...listItem.childNodes);
      parentList.after(p);

      // The next list items will be in their own list and be the next sibling
      // to the outdented list item.
      if (nextList.hasChildNodes()) {
        p.after(nextList);
      }
    } else {
      if (
        isNodeOneOf(
          parentList.parentElement,
          ListNodeNames.ul,
          ListNodeNames.ol
        )
      ) {
        // The parent list is a child of another list.

        // The outdented list item will be the next sibling to the parent list.
        parentList.after(listItem);
      } else {
        // The parent list is a child of a list item.

        // The outdented list item will be the next sibling to the parent
        // of the parent list.
        (parentList.parentElement as HTMLLIElement).after(listItem);
      }

      // The next list items will be in their own list and appended to the list item.
      if (nextList.hasChildNodes()) {
        listItem.append(nextList);
      }
    }

    // If the parent list is empty, remove it from the dom. This needs to be
    // the last step so the outdented list item has a reference point.
    if (!parentList.hasChildNodes()) {
      parentList.remove();
    }
  });
};

/**
 * @returns {boolean} Cancels the froala command if false is returned
 */
function commandsBefore(this: FroalaEditor, cmd: string): boolean {
  const selection = this.selection.get() as Selection;

  if (cmd === CMD_INDENT && shouldPerform(selection)) {
    this.undo.saveStep();
    this.selection.save();
    performIndent(selection);
    this.selection.restore();
    this.opts.events.contentChanged?.apply(this);
    this.undo.saveStep();
    return false;
  }

  if (cmd === CMD_OUTDENT && shouldPerform(selection)) {
    this.undo.saveStep();
    this.selection.save();
    performOutdent(selection);
    this.selection.restore();
    this.opts.events.contentChanged?.apply(this);
    this.undo.saveStep();
    return false;
  }

  return true;
}

/**
 * @returns {boolean} Cancels the froala button refresh if false is returned
 */
function buttonsRefresh(this: FroalaEditor): boolean {
  const selection = this.selection.get() as Selection;

  if (shouldPerform(selection)) {
    const selector = [CMD_INDENT, CMD_OUTDENT]
      .map((cmd) => `.fr-command[data-cmd="${cmd}"]`)
      .join(',');
    this.$tb
      .find(selector)
      .removeClass('fr-disabled')
      .attr('aria-disabled', false);
    return false;
  }

  return true;
}

const getRootList = (listItem: HTMLLIElement): HTMLElement => {
  let parentElement;
  parentElement = listItem.parentElement as HTMLElement;

  while (
    isNodeOneOf(
      parentElement.parentElement,
      ListNodeNames.ul,
      ListNodeNames.ol,
      ListNodeNames.li
    )
  ) {
    parentElement = parentElement.parentElement;
  }

  return parentElement;
};

const isNodeOneOf = (
  node: Node | null | undefined,
  ...nodeNames: string[]
): node is HTMLElement =>
  node?.nodeType === Node.ELEMENT_NODE &&
  nodeNames.includes(node.nodeName.toLowerCase());

const getListItemsForSelection = (
  selection: Selection
): ReturnType<typeof traverseNode> => {
  if (selection.isCollapsed) {
    const listItem = isNodeOneOf(selection.anchorNode, ListNodeNames.li)
      ? (selection.anchorNode as HTMLLIElement)
      : selection.anchorNode?.parentElement?.closest('li');
    return listItem ? [listItem] : [];
  }

  const range = selection.getRangeAt(0);

  const { commonAncestorContainer, startContainer, endContainer } = range;

  const listItems = traverseNode(commonAncestorContainer);

  const findClosestListItem = (node: Node): HTMLLIElement | undefined => {
    if (isNodeOneOf(node, ListNodeNames.li)) {
      return node as HTMLLIElement;
    }

    return node.parentElement?.closest('li') ?? undefined;
  };

  const startListItem = findClosestListItem(startContainer);
  const startIndex =
    startListItem && listItems.includes(startListItem)
      ? listItems.indexOf(startListItem)
      : 0;

  const endListItem = findClosestListItem(endContainer);
  const endIndex =
    endListItem && listItems.includes(endListItem)
      ? listItems.indexOf(endListItem) + 1
      : listItems.length;

  return listItems.slice(startIndex, endIndex);
};

const traverseNode = (node: Node, listItems: HTMLLIElement[] = []) => {
  const isListItem = isNodeOneOf(node, ListNodeNames.li);
  const isList = isNodeOneOf(node, ListNodeNames.ul, ListNodeNames.ol);

  if (isListItem) {
    listItems.push(node as HTMLLIElement);
  }

  if (isListItem || isList) {
    node.childNodes.forEach((childNode) => traverseNode(childNode, listItems));
  }

  return listItems;
};
