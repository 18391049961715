import { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { Audience } from 'models/audience';
import { useUser } from 'contexts/user';
import { Resource } from 'utility/usage-records';
import { useAudienceByIdQueries } from 'hooks/audience';
import { getRecent, updateRecent } from './useRecentTopics';

export type RecentReturn = {
  data: Audience[];
  isLoading: boolean;
  updateCache: (resourceId: string) => void;
  dataByRowId: { [key: string]: Audience };
  dataRowIds: string[];
};

export const audienceKey = (audience: Audience): string =>
  `${audience.type}.${audience.name}`;

export const useRecentAudiences = (limit = 5): RecentReturn => {
  const { id: programId } = useProgram();
  const { id: userId } = useUser();
  const recent = getRecent(programId, userId, Resource.audience, limit);

  // Filter out targeted channel audiences for now due to incompatibility with query.
  // These audiences do not have ids
  const recentAudiences = recent.filter(
    (audience: string) => !audience.includes('dynamic.channel_audience')
  );

  const audiences = useAudienceByIdQueries(programId, recentAudiences);

  const audiencesLoading = useMemo(
    () => audiences.some(({ isLoading }) => isLoading),
    [audiences]
  );

  const dataByRowId = useMemo(() => {
    const map: { [key: string]: Audience } = {};
    if (audiencesLoading) return map;
    audiences.forEach(({ data }) => {
      if (!data) return;
      map[audienceKey(data)] = data;
    });
    return map;
  }, [audiences, audiencesLoading]);

  const dataRowIds = useMemo(() => {
    if (audiencesLoading) return [];
    return audiences
      .filter(({ data }) => !!data)
      .map(({ data }) => audienceKey(data as Audience));
  }, [audiences, audiencesLoading]);

  const updateCache = useCallback(
    (resourceId: string) =>
      updateRecent(resourceId, programId, userId, Resource.audience),
    [programId, userId]
  );

  const data = useMemo(() => {
    if (audiencesLoading) return [];
    return audiences
      .filter(({ data: audienceData }) => audienceData !== undefined)
      .map(({ data: audienceData }) => audienceData as Audience);
  }, [audiences, audiencesLoading]);

  return {
    data,
    isLoading: audiencesLoading,
    updateCache,
    dataByRowId,
    dataRowIds,
  };
};
