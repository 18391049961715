import { useDuration } from 'App/Program/Editors/Publisher/Deliver/DirectDelivery/DirectDeliverySection/EngagementeBoostSection/useDuration';
import {
  SettingsEditor,
  useSettings,
} from 'contexts/publisher/orchestrate/use-settings';
import { DURATIONS_BY_UNIT } from 'models/duration';
import { hasAudience } from 'models/publisher/settings';
import { useEffect, useState } from 'react';
import { Suggestion } from './Suggestion';

export const useDurationSuggestion: () => Suggestion | undefined = () => {
  const suggestedDuration = useDuration();
  const { settings } = useSettings();
  const { deliveryType } = settings;
  const [suggestion, setSuggestion] = useState<Suggestion | undefined>(
    undefined
  );
  const withAudience = hasAudience(settings);

  useEffect(() => {
    if (suggestedDuration === undefined) {
      setSuggestion(undefined);
      return;
    }

    const currentDuration = settings.duration?.value || 0;
    const shouldDisplayWarning =
      withAudience && currentDuration < suggestedDuration;
    setSuggestion(
      shouldDisplayWarning
        ? {
            id: 'extend_duration',
            value: suggestedDuration,
            title: 'Extend Duration',
            description: `We suggest ${suggestedDuration} days as your minimum duration, based on your campaign settings. Note: We are not guaranteeing all notifications will be delivered for all users within this suggested timeframe.`,
            cta: `Extend Duration by ${
              suggestedDuration - currentDuration
            } Days`,
          }
        : undefined
    );
  }, [withAudience, deliveryType, settings.duration?.value, suggestedDuration]);

  return suggestion;
};

export const apply: (settings: SettingsEditor, value?: number) => () => void = (
  settings: SettingsEditor,
  value?: number
) => {
  return () => {
    const {
      fields: { duration: setDuration },
    } = settings;
    if (value) {
      setDuration.set({
        label: `${value} days`,
        unit: DURATIONS_BY_UNIT.day,
        value,
      });
    }
  };
};
