export type LiquidVariable = {
  key: string;
  scope: string;
  name: string;
  description: string;
  behavior: string;
  kind: string;
  legacy: boolean;
  value: string;
  defaultValue: string;
  fileName?: string;
};

export const personalizationVariableRegExp = /{{([^|{}]+?)((?:\s|&nbsp;)\|(?:\s|&nbsp;)default:(?:\s|&nbsp;)[\w" \S]+?){0,1}}}/;

export const personalizationVariableRegExpOpen = /{{([^|{}]+?)((?:\s|&nbsp;)\|(?:\s|&nbsp;)default:(?:\s|&nbsp;)[\w" \S]+?){0,1}([}]{0,2})/;

export const blockHasExLiquidVariables = /(?<!<span>[^<]*?)({{\s*[^}]+\s*}})(?![^<]*?<\/span>)/;

export const blockHasSystemliquidVariablesRegExp = /<span[^>]*?>[^<]*?{{\s*[^}]+?\s*}}[^<]*?<\/span>/;
