import * as React from 'react';
import * as donkey from 'models/donkey';
import * as Type from 'DesignSystem/Typography';
import { FieldInput, Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ProgressAnimation } from 'shared/ProgressAnimation';
import { Placeholder } from 'DesignSystem/Components/Placeholder';
import { FieldLegend } from '../../shared/FieldLegend';
import { SourceMenu } from '../../shared/SourceMenu';
import { useImageDataMutator } from '../hooks/useImageDataMutator';
import { useImageSourceMenu } from '../hooks/useImageSourceMenu';
import styles from './styles.module.css';

export const ImageSourceMenu: React.FC<
  {
    isUploading: boolean;
    source: ReturnType<typeof useImageSourceMenu>;
    name?: string;
  } & ReturnType<typeof useImageDataMutator>
> = ({ isUploading, source, image, name }) => {
  const hasImage = image?.url && image.url !== donkey.hostedUrls.blankImage;
  return (
    <FieldInput legend={<FieldLegend>{name || 'Image File'}</FieldLegend>}>
      {isUploading ? (
        <Placeholder>
          <ProgressAnimation />
          <div>Uploading image...</div>
        </Placeholder>
      ) : (
        <Box className={styles.imageFile} relative minHeight={170}>
          <div>
            {source.fileInput}
            {source.libraryInput}
            {source.externalInput}
          </div>
          <SourceMenu top={hasImage ? undefined : -167} menu={source.menu}>
            {hasImage ? (
              <div>
                <img
                  style={{
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                  }}
                  src={image.url}
                  alt={image.altText}
                  title={image.altText}
                />
                <Flex spread color={Type.background.gray05}>
                  <Button label="Replace" layoutOnly />
                </Flex>
              </div>
            ) : (
              <Placeholder>
                <Button
                  layoutOnly
                  icon={
                    <Type.PageHeading bold color={Type.color.gray90}>
                      +
                    </Type.PageHeading>
                  }
                />
                <Type.Subheading block bold>
                  Add an Image
                  <br />
                  <Type.Body color={Type.color.gray50}>10 MB max</Type.Body>
                </Type.Subheading>
              </Placeholder>
            )}
          </SourceMenu>
        </Box>
      )}
    </FieldInput>
  );
};
