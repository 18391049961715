import React, { useState } from 'react';
import { useResizeObserver } from './useResizeObserver';

export function useScrollbarWidth(
  scrollingRef: React.MutableRefObject<HTMLDivElement | null>
): number {
  const [scrollbarWidth, setScrollBarWidth] = useState(0);

  useResizeObserver({
    ref: scrollingRef,
    onResize: () => {
      if (scrollingRef.current) {
        setScrollBarWidth(
          scrollingRef.current.offsetWidth - scrollingRef.current.clientWidth
        );
      } else {
        setScrollBarWidth(0);
      }
    },
  });

  return scrollbarWidth;
}
