import { Content, isPublishProtectedContent } from 'models/content';
import { Settings } from 'models/publisher/settings';
import { ContentPermissions } from 'models/content-permission';
import { Permissions } from 'models/permissions';
import {
  FieldPermissions,
  RESTRICTED_FIELDS_MAP,
} from 'hooks/publisher/settings/restrictedFields';

import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from './feature-flags';
import { useEditable } from './content';

const evaluateEditPermissions = (
  editableByAudience: boolean,
  editableByTopic: boolean,
  handleFeaturedPermission: boolean,
  permissions?: Permissions['permissions'],
  settings?: Settings
): string | undefined => {
  if (!permissions) {
    return 'Unable to fetch permissions. If this problem persists please speak to a Studio administrator.';
  }

  if (!settings) {
    return 'Unable to evaluate permissions. If this problem persists please speak to a Studio administrator.';
  }

  const {
    sendPushAccess,
    sendEmailAccess,
    sendAssistantAccess,
    setAsCriticalAccess,
    publishCampaign,
    setFeatured,
  } = permissions;
  const {
    deliveryChannels: { email, push, assistant },
    priority,
    publicationState,
  } = settings;

  const missingEditPermissions = [
    email && !sendEmailAccess && 'the email channel',
    push && !sendPushAccess && 'the push channel',
    assistant && !sendAssistantAccess && 'the Assistant channel',
    priority?.value === 'CRITICAL' &&
      !setAsCriticalAccess &&
      'the critical priority',
    !publishCampaign &&
      isPublishProtectedContent(publicationState) &&
      'publish campaigns',
    !editableByAudience &&
      !editableByTopic &&
      'all topics and audiences assigned to this campaign',
    !editableByAudience &&
      editableByTopic &&
      'all audiences assigned to this campaign',
    editableByAudience &&
      !editableByTopic &&
      'all topics assigned to this campaign',
    handleFeaturedPermission &&
      !setFeatured &&
      settings?.isFeatured &&
      'featured campaigns',
  ].filter((x): x is string => typeof x === 'string');

  if (missingEditPermissions.length > 0) {
    const missingEditPermissionString = formatErrorMessage(
      missingEditPermissions
    );

    return `You must have access to ${missingEditPermissionString} to edit this campaign.`;
  }

  return undefined;
};

const evaluatePublishPermissions = (
  permissions?: Permissions['permissions']
): string | undefined => {
  if (!permissions) {
    return 'Unable to fetch permissions. If this problem persists please speak to a Studio administrator.';
  }

  const { publishCampaign } = permissions;

  const missingPublishPermissions = [
    !publishCampaign && 'publish campaigns',
  ].filter((x): x is string => typeof x === 'string');

  if (missingPublishPermissions.length > 0) {
    const missingPublishPermissionString = formatErrorMessage(
      missingPublishPermissions
    );

    return `You must have access to ${missingPublishPermissionString} to publish this campaign.`;
  }
  return undefined;
};

const formatErrorMessage = (errors: string[]): string => {
  return errors.length > 1
    ? `${errors.slice(0, -1).join(', ')} and ${errors.pop()}`
    : errors.join(' and ');
};

// TODO: Remove Restricted Fields. We're already
// getting the data. No need to transform it into an enum.
const getRestrictedFields = (permissions: Permissions['permissions']) => {
  const {
    sendEmailAccess,
    sendPushAccess,
    sendAssistantAccess,
    setAsCriticalAccess,
    setFeatured,
  } = permissions;

  return Object.entries({
    sendEmailAccess,
    sendPushAccess,
    sendAssistantAccess,
    setAsCriticalAccess,
    setFeatured,
  })
    .filter(([_, v]) => !v)
    .map(([k, _]) => RESTRICTED_FIELDS_MAP[k as FieldPermissions])
    .flat();
};

export const useContentEditable = (
  settings: Settings,
  permissions: Permissions['permissions']
): ContentPermissions => {
  const { id: programId } = useProgram();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );
  // TODO: Should not have to cast here
  const handleFeaturedPermission =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Featured').data
      ?.value as boolean) || false;

  // TODO: Fix dangerous casting. If the Editable endpoint doesn't serve
  // our purposes, then change the endpoint or add a new one that accepts
  // less than a full Content object
  const { isLoading, errorMessage, data } = useEditable(programId, ({
    programId,
    contentChannels: settings.contentTopics,
    contentCommunication: {
      audiences: settings.audiences,
    },
  } as unknown) as Content);

  if (!isLoading && data) {
    const restrictedFields = showPermissionsUI
      ? getRestrictedFields(permissions)
      : [];
    const missingEditPermissions = showPermissionsUI
      ? evaluateEditPermissions(
          data.attributes.audiences,
          data.attributes.topics,
          handleFeaturedPermission,
          permissions,
          settings
        )
      : undefined;

    const missingPublishPermissions = showPermissionsUI
      ? evaluatePublishPermissions(permissions)
      : undefined;

    const canPublish = !missingPublishPermissions;
    const canEdit = !missingEditPermissions;

    return {
      isLoading,
      canEdit,
      canPublish,
      errors:
        (missingEditPermissions && [missingEditPermissions]) ||
        (missingPublishPermissions && [missingPublishPermissions]) ||
        [],
      restrictedFields,
    };
  }

  if (errorMessage) {
    return {
      isLoading,
      canEdit: false,
      canPublish: false,
      errors: [errorMessage],
      restrictedFields: [],
    };
  }

  return {
    isLoading,
    canEdit: false,
    canPublish: false,
    errors: ['Something went wrong'],
    restrictedFields: [],
  };
};
