import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import {
  bossanovaDomain,
  deepCamelcaseKeys,
  request,
} from 'services/api-shared';
import { UserExport } from 'models/user-export';

export type UserExportFetchParams = {
  search?: string;
  status?: string[];
  page?: { size: number; number: number };
  pageSize?: number;
  programId: number;
  order: string;
};

export type UserExportCollectionData = {
  data: Array<UserExport>;
  meta: {
    totalObjects: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  };
};

export const fetchUserExports = async (
  props: UserExportFetchParams
): Promise<UserExportCollectionData> => {
  const { programId, ...queryParams } = props;
  const query = qs.stringify(snakeCaseKeys(queryParams), {
    arrayFormat: 'brackets',
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/user_exports?${query}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching imports`);
};

export const fetchExport = async (
  programId: number,
  jobId: number
): Promise<UserExport> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs/${jobId}`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }

  throw new Error(`Error fetching imports`);
};
