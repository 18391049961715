import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { titleCase } from 'utility/text';
import { useDesign } from 'contexts/design';
import { TextInput } from 'shared/TextInput';

export const TitleBar: React.FC<{
  canEdit: boolean;
  entityText?: string;
  showTitle: boolean;
}> = ({ canEdit, entityText, showTitle }) => {
  const { design, update } = useDesign();
  const updateTitle = React.useCallback(
    (name: string) => {
      update({ ...design, name });
    },
    [design, update]
  );
  return (
    <Flex>
      <Type.Subheading color={Type.color.gray40}>
        {titleCase(
          `${design.id === 'new' ? 'create' : 'edit'} ${entityText || 'design'}`
        )}{' '}
      </Type.Subheading>
      <Box color={Type.color.gray40} padding={[0, 8]}>
        /
      </Box>
      {showTitle && (
        <Box padding={[0, 8]}>
          <PlainTitle
            updateTitle={updateTitle}
            title={design.name ?? ''}
            canEdit={canEdit}
          />
        </Box>
      )}
    </Flex>
  );
};

const PlainTitle: React.FC<{
  canEdit: boolean;
  title: string;
  updateTitle: (title: string) => void;
}> = ({ canEdit, title, updateTitle }) => {
  return !canEdit ? (
    <Type.Heading bold color={Type.color.gray90}>
      {title}
    </Type.Heading>
  ) : (
    <TextInput value={title} onChange={updateTitle} />
  );
};
