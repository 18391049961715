import React from 'react';
import { createPortal } from 'react-dom';
import { useTemplate } from 'contexts/template';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Button } from 'DesignSystem/Form';
import {
  useLibraryDisable,
  useLibraryEnable,
  useLibraryTemplates,
} from 'hooks/useLibrary';
import { useProgram } from 'contexts/program';
import {
  Close,
  DefaultAvatar,
  Edit,
  FullScreen,
  Lock,
  Unlock,
} from 'shared/icons';
import { useFlashMessage } from 'contexts/flasher';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import cx from 'classnames';
import { usePreview as useBlocksPreview } from 'hooks/content-blocks';
import { resizePreview } from 'utility/resize-preview';
import { COMPANY_NAME } from 'utility/constants';
import { usePortal } from 'hooks/usePortal';
import { useStickyNavBarContext } from 'contexts/sticky-navbar';
import { useUserQuery } from 'hooks/user';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { usePermissions } from 'contexts/permissions';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { isGlobalItem, Template } from 'models/library';
import { Targets } from 'models/publisher/block';
import styles from './preview.module.css';
import { MoreLikeThisPreview } from './MoreLikeThisPreview';

export const Preview: React.FC<RouteComponentProps> = () => {
  const { template } = useTemplate();
  const { id: programId } = useProgram();
  const [templateState, setTemplateState] = React.useState<Template>(template);
  const [showingNavBar, setIsShowingNavBar] = React.useState(true);
  const [fullScreenPreview, setFullScreenPreview] = React.useState(false);
  const previewRef = React.useRef<HTMLDivElement>(null);
  const [hasResizedPreview, setHasResizedPreview] = React.useState(false);
  const { setFlashMessage } = useFlashMessage();
  const renderedWidth = 800;
  const portal = usePortal('template-preview');
  const { setIsShowStickyNavBar } = useStickyNavBarContext();
  const { html, isLoading } = useBlocksPreview(
    template.asset.template,
    Targets.web
  );

  const { data: userData, isLoading: isLoadingUser } = useUserQuery(
    programId,
    Number(template.asset.template.createdBy?.id)
  );

  const {
    data: permissionsService,
    isLoading: permissionsServiceLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Permissions.Service');
  const {
    permissions: { manageTemplateAccess },
  } = usePermissions();
  // gets overriden by setIsShowStickyNavBar(true) in theme
  // if called directly in openPreview/closePreview callback
  setIsShowStickyNavBar(showingNavBar);

  const onSuccess = React.useCallback(
    (t) => {
      setTemplateState(t);
      setFlashMessage({
        severity: 'info',
        message: `Template ${
          template.status === 'published' ? 'disabled' : 'enabled'
        }`,
      });
    },
    [setFlashMessage, template.status]
  );

  const { mutate: enable, isWorking: isEnabling } = useLibraryEnable(onSuccess);
  const { mutate: disable, isWorking: isDisabling } = useLibraryDisable(
    onSuccess
  );

  const templates = useLibraryTemplates({
    filter: {
      type: 'search',
      search: '',
      status: ['published', 'archived'],
    },
  });

  const openPreview = React.useCallback(() => {
    setFullScreenPreview(true);
    setIsShowingNavBar(false);
  }, [setIsShowingNavBar]);

  const closePreview = React.useCallback(() => {
    setFullScreenPreview(false);
    setIsShowingNavBar(true);
  }, [setIsShowingNavBar]);

  const createdBy = React.useMemo(() => {
    if (userData && !isLoadingUser) {
      return `${userData.firstName} ${userData.lastName}`;
    }
    return COMPANY_NAME;
  }, [userData, isLoadingUser]);

  const templatePermissions = React.useMemo(() => {
    if (template.asset.template.permissions) {
      return template.asset.template.permissions.join(', ');
    }
    return 'All Studio Users';
  }, [template]);

  const changeTemplateStatus = React.useCallback(() => {
    if (template.status === 'published') {
      disable(template);
    }
    if (template.status === 'archived') {
      enable(template);
    }
  }, [template, disable, enable]);

  const isWorking = React.useMemo(() => {
    return isEnabling || isDisabling;
  }, [isDisabling, isEnabling]);

  const previewComponent = createPortal(
    <div className={styles.fullScreenPreviewOverlay}>
      <div className={styles.fullScreenPreview}>
        <button
          type="button"
          className={styles.closeFullscreenPreview}
          onClick={closePreview}
        >
          <Close />
          <span>Close</span>
        </button>
        <div
          className={styles.fullPreview}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>,
    portal.target
  );

  React.useEffect(() => {
    setTimeout(() => {
      if (previewRef.current && !isLoading && !hasResizedPreview) {
        if (previewRef.current.parentElement) {
          // scale the element - we can cut off the height
          // and the width of a rendered feed element is usually 800px
          // if it's not the case then do document.createElement('div'),
          // append html and useGetBoundingClientRect() to figure it out
          const previewValues = resizePreview(
            previewRef.current,
            renderedWidth,
            0.4
          );
          previewRef.current.setAttribute(
            'style',
            `transform: scale(${previewValues.scaleValue}); transform-origin: top center;`
          );
          setHasResizedPreview(true);
        }
      }
    }, 500);
  }, [hasResizedPreview, isLoading]);

  React.useEffect(() => {
    setHasResizedPreview(false);
  }, [template]);

  const moreLikeThisData = React.useMemo(() => {
    return templates.data.filter((t) => t.id !== template.id);
  }, [template.id, templates.data]);

  const editableByPermission =
    (!permissionsServiceLoading && !permissionsService?.value) ||
    manageTemplateAccess;
  const editableByType = !isGlobalItem(templateState);

  const action = React.useMemo(
    () => (
      <div className={styles.ControlsWrapper}>
        {editableByPermission && editableByType && (
          <>
            <Button
              href={`/${programId}/edit/template/${template.id}`}
              icon={<Edit />}
              label="Edit"
            />
            <Button
              text
              onClick={() => {
                if (!isWorking) changeTemplateStatus();
              }}
              disabled={isWorking}
              label={
                isWorking ? (
                  <div style={{ transform: 'scale(0.5)' }}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    {templateState.is_enabled_for_program ? (
                      <Unlock />
                    ) : (
                      <Lock />
                    )}
                  </>
                )
              }
            />
          </>
        )}
      </div>
    ),
    [
      editableByPermission,
      editableByType,
      programId,
      template.id,
      isWorking,
      templateState.is_enabled_for_program,
      changeTemplateStatus,
    ]
  );

  const Wrapper = React.useMemo<
    React.FC<{ title: string; description?: string }>
  >(
    () => ({ children, title, description }) => (
      <ListPage
        breadcrumbs={[{ label: 'Library', to: '..' }, { label: title }]}
        title={title}
        description={description}
        actionsOverride={action}
      >
        {children}
      </ListPage>
    ),
    [action]
  );

  return fullScreenPreview ? (
    <Wrapper title={template.title} description={template.description}>
      {previewComponent}
    </Wrapper>
  ) : (
    <Wrapper title={template.title} description={template.description}>
      <div className={styles.wrapper}>
        <div className={styles.previewWrapper}>
          <button
            className={styles.openFullscreenPreview}
            type="button"
            onClick={openPreview}
          >
            <FullScreen />
            <span>Fullsize</span>
          </button>
          {!isLoading && (
            <div
              ref={previewRef}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: html }}
              className={cx(styles.preview, {
                [styles.previewHidden]: !hasResizedPreview,
              })}
            />
          )}
          {isLoading && <LoadingSpinner />}
        </div>
        <div className={styles.body}>
          <div className={styles.moreLikeThisWrapper}>
            <div className={styles.sectionTitle}>More like this</div>
            <div className={styles.moreLikeThisThumbnails}>
              {moreLikeThisData.map((t) => (
                <MoreLikeThisPreview key={t.id} template={t} />
              ))}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionHeader}>Creator</div>
              {isLoadingUser && <LoadingSpinner size="medium" />}
              {!isLoadingUser && (
                <div className={styles.avatarWrapper}>
                  {userData && userData.avatarUrl && (
                    <div
                      className={styles.avatar}
                      style={{
                        backgroundImage: `url(${userData.avatarUrl})`,
                      }}
                    />
                  )}
                  {userData && !userData.avatarUrl && (
                    <div className={styles.avatar}>
                      <DefaultAvatar />
                    </div>
                  )}
                  {!userData && (
                    <div className={styles.avatar}>
                      <SVGIcon name="Logo" />
                    </div>
                  )}
                  <span>{createdBy}</span>
                </div>
              )}
            </div>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionHeader}>Access</div>
              <div>{templatePermissions}</div>
            </div>
            {template.created_at && (
              <div className={styles.infoSection}>
                <div className={styles.infoSectionHeader}>Created</div>
                <div>
                  {DateTime.fromISO(template.created_at).toFormat(
                    'LLLL dd, yyyy'
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
