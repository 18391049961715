import React from 'react';

import * as Text from 'DesignSystem/Typography';
import buttonStyles from 'shared/styles/buttons.module.css';
import styles from './FlaggedContentButton.module.css';

type FlaggedProps = {
  flagCount: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function FlaggedContentButton({
  flagCount,
  onClick,
}: FlaggedProps): React.ReactElement | null {
  if (flagCount === 0) {
    return null;
  }

  return (
    <button
      aria-hidden="true"
      type="button"
      onClick={onClick}
      className={styles.flaggedContentButton}
    >
      <div className={buttonStyles.flaggedWithIcon}>
        <Text.Body underline nobr>
          {flagCount} {flagCount > 1 ? 'flags' : 'flag'}
        </Text.Body>
      </div>
    </button>
  );
}
