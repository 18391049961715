import React, { FC, useCallback, useMemo } from 'react';
import { uniqueId } from 'hooks/useUniqueId';
import { Checkbox } from 'DesignSystem/Form';
import { Control, Field, FieldSet, Label } from './common';

interface OptionProps<T = unknown> {
  name: string;
  label: string;
  value: T;
  checked: boolean;
  onChange: (value: T) => void;
}
const Option: FC<OptionProps> = ({ name, label, value, onChange, checked }) => {
  const id = useMemo(() => `checkbox-${uniqueId()}`, []);
  const handleChange = useCallback(() => {
    onChange(value);
  }, [value, onChange]);
  return (
    <Field>
      <Control>
        <Checkbox
          type="radio"
          checked={checked}
          onChange={handleChange}
          id={id}
          name={name}
        />
      </Control>
      <Label htmlFor={id}>{label}</Label>
    </Field>
  );
};

interface Props<V = unknown> {
  options: { label: string; value: V }[];
  name: string;
  value: V;
  onChange: (name: string, value: V) => void;
}

const RadioGroupField = ({
  name,
  options,
  value,
  onChange,
}: Props): JSX.Element => {
  const handleChange = useCallback(
    (val) => {
      onChange(name, val);
    },
    [name, onChange]
  );
  return (
    <FieldSet>
      {options.map((o) => (
        <Option
          key={o.label}
          name={name}
          label={o.label}
          value={o.value}
          checked={o.value === value}
          onChange={handleChange}
        />
      ))}
    </FieldSet>
  );
};

export default RadioGroupField;
