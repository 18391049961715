import React from 'react';
import { Section } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { Subheading, Body, color } from 'DesignSystem/Typography';
import styles from './legal.module.css';

export const GdprSection: React.FC = () => {
  const program = useProgram();

  return (
    <Section
      title="Privacy Contacts"
      href={`/${program.id}/configure/legal/contacts`}
    >
      <div className={styles.GdprSection}>
        <div className={styles.GdprDescription}>
          <div className={styles.GdprHeading}>
            <Subheading bold>Contact Email(s)</Subheading>
          </div>
          <Body color={color.gray60}>
            Community contact email(s) when users request to export or erase
            their personal data. Use comma to separate different email
            addresses.
          </Body>
        </div>
        <div>
          {program.gdprComplianceContacts && (
            <>
              {program.gdprComplianceContacts.split(',').map((l) => (
                <div>{l.trim()}</div>
              ))}
            </>
          )}
          {!program.gdprComplianceContacts && 'No Contact Email'}
        </div>
      </div>
    </Section>
  );
};
