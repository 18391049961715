import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDebounce } from 'hooks/useDebounce';
import { useExternalSourcesInfiniteQuery } from 'hooks/external-source';
import { useProgram } from 'contexts/program';
import { useStickyNavBarContext } from 'contexts/sticky-navbar';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { WithPermission } from 'shared/WithPermission';
import { SearchBar } from 'shared/SearchBar';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Body, Subheading } from 'DesignSystem/Typography';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import filterStyles from 'components/content/ContentFilterBar/filters.module.css';
import styles from './external-sources.module.css';
import { FeedsList } from './FeedsList';
import { Filters } from './Filters';

export const ExternalSources: React.FC<RouteComponentProps> = () => {
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();

  const breadcrumbs = [{ to: '..', label: 'Configure' }, { label: 'Feeds' }];

  const actions = [
    {
      icon: <Icon name="Plus" fill="currentColor" />,
      label: 'Feed',
      to: 'new',
    },
  ];
  const [statuses, setStatuses] = useFilterDropdownQueryString('statuses', [
    'active',
  ]);

  const [autoPublish, setAutoPublish] = useFilterDropdownQueryString(
    'autoPublish'
  );
  const [shareable, setShareable] = useFilterDropdownQueryString('shareable');
  const [publishing, setPublishing] = React.useState<string[]>(() => {
    const values: string[] = [];
    if (shareable.length > 0) values.push('shareable');
    if (autoPublish.length > 0) values.push('autoPublish');
    return values;
  });

  const { id: programId } = useProgram();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const { handlePageScroll } = useStickyNavBarContext();

  function updatePublishingFilter(values: string[]) {
    setPublishing(values);
    if (values.includes('shareable') && shareable.length === 0) {
      setShareable(['true']);
    }
    if (values.includes('autoPublish') && autoPublish.length === 0) {
      setAutoPublish(['true']);
    }
    if (!values.includes('shareable') && shareable.length !== 0) {
      setShareable([]);
    }
    if (!values.includes('autoPublish') && autoPublish.length !== 0) {
      setAutoPublish([]);
    }
  }

  const filterChangeCallbacks: { [key: string]: (values: string[]) => void } = {
    status: setStatuses,
    publishing: updatePublishingFilter,
  };

  function onFilterChange(filterName: string, values: string[]) {
    filterChangeCallbacks[filterName](values);
  }

  React.useEffect(() => {
    handlePageScroll(parentRef);
    // eslint-disable-next-line
  }, [parentRef]);

  const emptyList = <Subheading block>No feeds match the criteria.</Subheading>;

  const filterbar = (
    <div className={filterStyles.filtersWrapper}>
      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search feeds"
        panelClassName={styles.searchBar}
      />
      <Filters
        statuses={statuses}
        publishing={publishing}
        onChange={onFilterChange}
      />
    </div>
  );

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    meta,
  } = useExternalSourcesInfiniteQuery({
    programId,
    search: useDebounce(searchTerm),
    statuses: useDebounce(statuses),
    shareable: useDebounce(shareable),
    autoPublish: useDebounce(autoPublish),
  });

  const loading = <Body block> Loading... </Body>;

  return (
    <WithPermission permissions={['confContentAccess', 'feedAccess']}>
      <ListPage
        title="Feeds"
        actions={actions}
        breadcrumbs={breadcrumbs}
        emptyList={emptyList}
        filterbar={filterbar}
        loading={loading}
      >
        <FeedsList
          parentRef={parentRef}
          isLoading={isLoading}
          externalSources={data}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          meta={meta}
          filterConfig={{
            search: searchTerm,
            statuses,
            shareable,
            autoPublish,
          }}
        />
      </ListPage>
    </WithPermission>
  );
};
