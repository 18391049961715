import * as React from 'react';
import { Button, FieldInput, Input, Textarea } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { IframeFieldData } from 'models/donkey';
import { IframeMode } from 'models/iframe';
import { extractIframeFromHtml } from 'models/publisher/block';
import { FieldFormProps } from '../../useFieldForm';
import { EditorModeSelect } from '../shared/EditorModeSelect';
import { FieldLegend } from '../shared/FieldLegend';
import { useIFrameDataMutator } from './hooks/useIframeDataMutator';
import { Image } from '../Image';

export const Iframe: React.FC<FieldFormProps<IframeFieldData>> = (props) => {
  const { data, update } = useIFrameDataMutator(props);

  React.useEffect(() => {
    update(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = React.useCallback(
    (img) => {
      update({ image: img });
    },
    [update]
  );

  const canMakeIframeResponsive = React.useMemo(() => {
    const iframe = extractIframeFromHtml(data.rawHtml);
    if (!iframe) return false;

    const { height, width } = iframe;
    return (
      height && width && height.slice(-1) !== '%' && width.slice(-1) !== '%'
    );
  }, [data.rawHtml]);

  const makeIframeResponsive = React.useCallback(() => {
    if (data.mode !== IframeMode.rawHtml) return;

    const iframe = extractIframeFromHtml(data.rawHtml);
    if (!iframe) return;

    const { height, width } = iframe;

    iframe.style.aspectRatio = `${parseInt(width, 10)} / ${parseInt(
      height,
      10
    )}`;
    iframe.style.width = `${parseInt(width, 10)}px`;
    iframe.style.height = '100%';
    iframe.style.maxWidth = '100%';

    update({
      rawHtml: iframe.outerHTML,
    });
  }, [data.mode, data.rawHtml, update]);

  return (
    <>
      <EditorModeSelect
        prompt="How would you like to add?"
        onChange={(value) => update({ mode: value as IframeMode })}
        options={[
          { label: 'Code', value: IframeMode.rawHtml },
          { label: 'Website URL', value: IframeMode.url },
        ]}
        value={data.mode}
      />
      {data.mode === IframeMode.url && (
        <FieldInput
          legend={<FieldLegend>Website URL</FieldLegend>}
          htmlFor="url-input"
        >
          <Input
            id="url-input"
            style={{ width: '100%' }}
            value={data.url}
            onChange={(e) => update({ url: e })}
          />
        </FieldInput>
      )}
      {data.mode === IframeMode.rawHtml && (
        <FieldInput
          legend={<FieldLegend>Code</FieldLegend>}
          htmlFor="raw-html-input"
        >
          <Textarea
            id="raw-html-input"
            style={{ fontFamily: 'monospace' }}
            value={data.rawHtml}
            onChange={(e) => update({ rawHtml: e })}
          />
          <Flex end>
            <Button
              label="Make Responsive"
              disabled={!canMakeIframeResponsive}
              onClick={makeIframeResponsive}
            />
          </Flex>
        </FieldInput>
      )}
      <Image
        imageName="Fallback image"
        tooltipText="The fallback image will display in scenarios where the iframe cannot be shown, such as in email clients."
        hideLinkOptions
        onChange={handleImageChange}
        default_data={data.image}
        data={data.image}
      />
    </>
  );
};
