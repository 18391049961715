import React from 'react';
import styles from './data-table.module.css';
import { UserActivityStreamData } from '../../services/api-insights';
import { NoDataChart } from './NoDataChart';

interface Column {
  key: string;
  header: string;
  width?: string;
  formatCell?: (value?: string | Date) => string;
}

interface DataTableProps {
  columns: Column[];
  data: UserActivityStreamData[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onSort: (field: string) => void;
  isFetching: boolean;
  isLoading: boolean;
}

function getCellDisplayValue(
  row: UserActivityStreamData,
  attributeKey: string,
  formatFunction?: (value: string | Date) => string
) {
  const value = row[attributeKey as keyof UserActivityStreamData] as
    | Date
    | string;

  if (formatFunction !== undefined) {
    return formatFunction(value);
  }
  return value;
}

export const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  sortBy,
  sortDirection,
  onSort,
  isFetching,
  isLoading,
}) => {
  if (!isFetching && !isLoading && data.length === 0)
    return (
      <NoDataChart
        chartDimensions={{
          width: 1118,
          height: 575,
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        }}
      />
    );
  return (
    <table className={styles.dataTable}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              className={styles.headerCell}
              onClick={() => onSort(column.key)}
              style={column.width ? { width: column.width } : {}}
            >
              {column.header}{' '}
              {sortBy === column.key && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            {columns.map((column) => (
              <td
                key={column.key}
                className={`${styles.tableCell} ${
                  styles[
                    `col${
                      column.key.charAt(0).toUpperCase() + column.key.slice(1)
                    }`
                  ]
                }`}
              >
                {getCellDisplayValue(row, column.key, column.formatCell)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
