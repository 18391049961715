import * as React from 'react';
import cx from 'classnames';
import { MenuItem } from 'hooks/useFooter';
import styles from './menu.module.css';
import { LoadingSpinner } from '../LoadingSpinner';

export const Menu: React.FC<{
  menuItems: { [sectionName: string]: Array<MenuItem> };
}> = ({ menuItems }) => {
  return (
    <div className={styles.dropdown}>
      {Object.keys(menuItems).map((section) => (
        <div key={section} className={styles.section}>
          {menuItems[section].map(({ visible = true, ...item }, idx) => {
            return (
              visible && (
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  type="button"
                  onClick={item.disabled ? undefined : item.onClick}
                  className={cx(styles.item, {
                    [styles.disabled]: item.disabled,
                  })}
                >
                  {item.name && <span>{item.name}</span>}
                  {item.element}
                  {item.isWorking && <LoadingSpinner size="small" />}
                </button>
              )
            );
          })}
        </div>
      ))}
    </div>
  );
};
