import React from 'react';
import cx from 'classnames';
import { CampaignResultsData } from 'services/api-insights';
import styles from '../MetricsCards.module.css';
import { NumberChart } from '../../../../../../../shared/Charts/NumberChart';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IUsersDeliveredCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const chartDimensions = {
  height: 48,
  width: 233,
  padding: {
    top: 24,
    bottom: 24,
    right: 10,
    left: 10,
  },
};

export const UsersDeliveredCard: React.FC<IUsersDeliveredCard> = ({
  data,
  isLoading,
}) => {
  // const targeted = data?.campaign.stats.delivered || 0;
  const delivered = data?.campaign.stats.delivered || 0;
  // const ratio = targeted / delivered;

  return (
    <div className={cx(styles.metricCard, styles.metricCardQuarter)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>Users Delivered</h1>
      </div>
      {isLoading ? (
        <>
          <div className={cx(styles.metricCardBody, styles.center)}>
            <LoadingChart chartDimensions={chartDimensions} />
            <p className={cx(styles.centeredText, styles.metricCardSubtext)} />
          </div>
          <div style={{ height: '40px' }} />
        </>
      ) : (
        <>
          <div className={cx(styles.metricCardBody, styles.center)}>
            <NumberChart data={delivered} chartDimensions={chartDimensions} />
          </div>
          <div style={{ height: '40px' }} />
        </>
      )}
    </div>
  );
};
