import {
  Button,
  Icon,
  IconButton,
  TextInput,
} from '@socialchorus/shared-ui-components';
import React, { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import { useProgram } from 'contexts/program';
import { FormModal } from 'DesignSystem/Components';
import { useCreateTopicShortcut, useUpdateTopicShortcut } from 'hooks/topics';
import { TopicShortcut } from 'models/topic';
import { useFlashMessage } from 'contexts/flasher';
import { useTopicFormCtx } from '../../../../context';
import { Form } from '../../../common/Form';
import { ImageInput } from '../../../common/Form/ImageInput';
import styles from './styles.module.css';

const DEFAULT_LINK_OBJECT = { name: '', url: '', updatedAt: '' };

const customValidations: ComponentProps<typeof TextInput>['validations'] = {
  custom: {
    noWhiteSpace: (val) =>
      val.length > 0 && val.trim().length === 0 ? 'Required' : '',
  },
  valueMissing: 'Required',
  typeMismatch: 'URL format required',
};

interface Props {
  /* Not passing shortcut, means we are creating new one */
  shortcut?: TopicShortcut;
  onClose: () => void;
}

export const EditShortcutModal: FC<Props> = ({ shortcut, onClose }) => {
  const { id: programId } = useProgram();
  const { topic, state, selectTab } = useTopicFormCtx();
  const { setFlashMessage } = useFlashMessage();

  const customShortcutNameValidations: ComponentProps<
    typeof TextInput
  >['validations'] = {
    ...customValidations,
    custom: {
      ...customValidations.custom,
      uniqueName: (val) => {
        const isUnique = !state.fields.shortcuts.some(
          (s) => s.id !== shortcut?.id && s.name.trim() === val.trim()
        );
        return isUnique ? '' : 'Name already exists on this topic';
      },
    },
  };

  const { create, isLoading: isCreating } = useCreateTopicShortcut(
    programId,
    topic.id,
    {
      onError: () => {
        setFlashMessage({
          message: 'Unable to create shortcut',
          severity: 'error',
        });
      },
    }
  );
  const { update, isLoading: isUpdating } = useUpdateTopicShortcut(
    programId,
    topic.id,
    {
      onError: () => {
        setFlashMessage({
          message: 'Unable to update shortcut',
          severity: 'error',
        });
      },
    }
  );

  const formRef = useRef<HTMLFormElement>(null);
  const [formState, setFormState] = useState<Partial<TopicShortcut>>();

  const onSubmit = async () => {
    const valid = formRef.current?.reportValidity();

    if (!valid) return;

    const hasDuplicateLinkNames = formStateLinks.some(
      (item, _index, self) =>
        self.filter((other) => other.name === item.name).length > 1
    );

    if (hasDuplicateLinkNames) return;

    try {
      if (!shortcut) {
        await create(formState as TopicShortcut);
      } else {
        await update(formState as TopicShortcut);
      }
    } catch (err) {
      // Just need to avoid closing the modal.
      // The rest will be handled by the onError handlers.
      return;
    }

    // If modal was opened from preview card, switch to shortcuts tab
    if (state.activeTab !== 'shortcuts') {
      selectTab('shortcuts');
    }
    onClose();
  };

  const handleChange = (change: Partial<TopicShortcut>) => {
    setFormState((prev) => ({ ...prev, ...change }));
  };

  const handleIconUrlChange = (icon: { url: string } | null) => {
    handleChange({ iconUrl: icon?.url });
  };

  const handleLinkChange = (
    change: Partial<TopicShortcut['links'][number]>,
    index: number
  ) => {
    const updatedAt = new Date().toISOString();
    setFormState((prev) => ({
      ...prev,
      links: (prev?.links ?? [DEFAULT_LINK_OBJECT]).map((link, i) =>
        i === index ? { ...link, ...change, updatedAt } : link
      ),
    }));
  };

  const handleLinkAdd = () => {
    setFormState((prev) => ({
      ...prev,
      links: [...(prev?.links || [DEFAULT_LINK_OBJECT]), DEFAULT_LINK_OBJECT],
    }));
  };

  const handleLinkRemove = (index: number) => {
    setFormState((prev) => ({
      ...prev,
      links: prev?.links?.filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    setFormState(shortcut);
  }, [shortcut]);

  const submitLabel = shortcut ? 'Update Shortcut' : 'Add Shortcut';
  const isSubmittingLabel = shortcut ? 'Updating Shortcut' : 'Adding Shortcut';
  const isSendingData = isCreating || isUpdating;

  const formStateLinks = formState?.links || [DEFAULT_LINK_OBJECT];

  return (
    <FormModal
      actionText={shortcut ? 'Edit' : 'Add'}
      entityText="Shortcut"
      onCancel={onClose}
      disableCloseButton={isSendingData}
      submitButton={
        <Button
          variant="primary"
          disabled={formState === undefined}
          isLoading={isSendingData}
          onClick={isSendingData ? undefined : onSubmit}
          label={isSendingData ? isSubmittingLabel : submitLabel}
        />
      }
      width="680px"
    >
      <Form ref={formRef}>
        <div>
          <label htmlFor="shortcut-name-input">Name</label>
          <TextInput
            id="shortcut-name-input"
            fullWidth
            placeholder="Enter name"
            onChange={(name) => handleChange({ name })}
            onBlur={(event) =>
              handleChange({ name: event.target.value.trim() })
            }
            value={formState?.name || ''}
            disabled={isSendingData}
            required
            validations={customShortcutNameValidations}
          />
        </div>

        <div>
          <label htmlFor="shortcut-desc-input">Description</label>
          <TextInput
            id="shortcut-desc-input"
            fullWidth
            placeholder="Enter description"
            onChange={(description) => handleChange({ description })}
            value={formState?.description || ''}
            disabled={isSendingData}
          />
        </div>

        <div>
          <span className={styles.Label}>Icon</span>
          <ImageInput
            aspectRatio={1}
            disabled={isSendingData}
            imageSrc={formState?.iconUrl}
            onChange={handleIconUrlChange}
          />
        </div>

        <ul className={styles.LinkList}>
          {formStateLinks.map((link, index) => {
            const isDuplicateName = formStateLinks.some((l, otherIndex) => {
              if (otherIndex !== index && l.name.trim() === link.name.trim()) {
                return new Date(link.updatedAt) > new Date(l.updatedAt);
              }
              return false;
            });
            const customErrorMessage = isDuplicateName
              ? 'Name already exists on this shortcut'
              : undefined;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li className={styles.LinkItem} key={index}>
                <div className={styles.LinkItem__Content}>
                  <div>
                    <label htmlFor={`shortcut-link-${index}-name`}>
                      Link Name
                    </label>
                    <TextInput
                      id={`shortcut-link-${index}-name`}
                      fullWidth
                      placeholder="Enter link name"
                      onChange={(name) => handleLinkChange({ name }, index)}
                      onBlur={(event) =>
                        handleLinkChange(
                          { name: event.target.value.trim() },
                          index
                        )
                      }
                      value={link.name}
                      disabled={isSendingData}
                      required
                      customErrorMessage={customErrorMessage}
                    />
                  </div>

                  <div>
                    <label htmlFor={`shortcut-link-${index}-url`}>
                      Link URL
                    </label>
                    <TextInput
                      id={`shortcut-link-${index}-url`}
                      fullWidth
                      placeholder="Enter link URL"
                      type="url"
                      onChange={(url) => handleLinkChange({ url }, index)}
                      value={link.url}
                      disabled={isSendingData}
                      validations={customValidations}
                      required
                    />
                  </div>
                </div>

                {formStateLinks.length > 1 && (
                  <IconButton
                    type="button"
                    className={styles.RemoveLinkButton}
                    disabled={isSendingData}
                    iconName="delete"
                    onClick={() => handleLinkRemove(index)}
                  />
                )}
              </li>
            );
          })}
        </ul>

        <Button
          type="button"
          className={styles.AddLinkButton}
          variant="outline"
          label="Add Link"
          leftIcon={<Icon>add</Icon>}
          isLoading={isSendingData}
          onClick={handleLinkAdd}
        />
      </Form>
    </FormModal>
  );
};
