import { FontStylesheet } from '../models/library';

export function appendStylesheets(items: FontStylesheet[]): void {
  const dataAttr = 'data-custom-stylesheet';
  if (items) {
    items.forEach((item) => {
      if (!item.asset?.css?.url) return;
      if (document.querySelector(`link[href="${item.asset.css.url}"]`)) return;

      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', item.asset.css.url);
      link.setAttribute('type', 'text/css');
      link.setAttribute('data-source', dataAttr);
      link.setAttribute('data-id', `font-${item.id}`);
      document.head.appendChild(link);
    });
  }
}
