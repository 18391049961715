/** @jsxRuntime classic */
// IE11 Step 1: The line above must be first, to turn off auto jsx-runtime for this file only.
// https://github.com/facebook/create-react-app/issues/9906

// IE11 Step 2: The following must appear before any other JSX imports.
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// IE11 Step 3: The following are required since auto jsx-runtime is turned off in this file.
// https://github.com/facebook/create-react-app/issues/9906
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BoxI18nProvider } from '@socialchorus/box-components';
import { TooltipProvider } from '@socialchorus/shared-ui-components';
import { init as initDatadog } from 'hooks/datadog';
import * as serviceWorker from './serviceWorker';

import './main.css';
import { App } from './App';
import { ApiProvider } from './contexts/api';
import { ErrorBoundary } from './components/ErrorBoundary';

initDatadog();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <TooltipProvider>
        <ApiProvider>
          <BoxI18nProvider>
            <App />
            {process.env.REACT_APP_REACT_QUERY_DEVTOOLS && (
              <ReactQueryDevtools initialIsOpen={false} position="top-right" />
            )}
          </BoxI18nProvider>
        </ApiProvider>
      </TooltipProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
