import React from 'react';
import { DateTime } from 'luxon';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { useProgram } from 'contexts/program';
import { ExternalSource } from 'models/external-source';
import { useActions } from './useActions';
import styles from './external-sources.module.css';
import {
  externalSourceToThumbnail,
  externalSourceTitle,
  externalSourceToMenuItems,
} from './mappings';

type PropsType = {
  externalSource: ExternalSource;
};

export const ExternalSourceBanner: React.FC<PropsType> = ({
  externalSource,
}) => {
  const { id: programId } = useProgram();
  const actions = useActions(undefined, true);
  const thumbnail = externalSourceToThumbnail(externalSource);
  const headline = externalSourceTitle(externalSource);
  const menuItems = externalSourceToMenuItems(
    programId,
    externalSource,
    actions
  );

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={`${externalSource.id}`}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{headline}</div>
        </div>

        <div className={styles.statCol}>
          <span className={styles.statHeader}>PUBLISHING RULES</span>
          {externalSource.autoPublish && (
            <span className={styles.stat}>Auto-publish</span>
          )}
          {externalSource.isShareable && (
            <span className={styles.stat}>Shareable</span>
          )}
          {!externalSource.autoPublish && !externalSource.isShareable && (
            <span className={styles.stat}>None</span>
          )}
        </div>

        <div className={styles.statCol}>
          <span className={styles.statHeader}>Topics</span>
          <span className={styles.stat}>
            <span className={styles.topicName}>
              {externalSource.contentChannels[0]?.name}
            </span>
            {externalSource.contentChannels.length > 1 &&
              `,+${externalSource.contentChannels.length - 1}`}
          </span>
        </div>

        <div className={styles.statCol}>
          <span className={styles.statHeader}>LAST UPDATED</span>
          <span className={styles.stat}>
            {externalSource.lastHarvestedAt
              ? DateTime.fromISO(externalSource.lastHarvestedAt).toRelative()
              : 'N/A'}
          </span>
        </div>
      </div>
    </EmptyBanner>
  );
};
