import { useCallback, useEffect } from 'react';
import {
  useContentImageUploader,
  useProcessedContentImage,
} from 'hooks/useContentImage';
import { createFlashError } from 'models/flash-message';
import { useFlashMessage } from 'contexts/flasher';
import { ImageData, isImageDataArray } from 'models/image';
import { OnChange } from './useImageDataMutator';
import { useKeepOpen } from '../../shared/KeepOpen';
import { ExitBlocker } from '../components/ExitBlocker';

export function useImageUploader(
  onChange: OnChange
): ReturnType<typeof useContentImageUploader> & {
  updateFirst: (data: ImageData[] | File[] | DataTransferItemList) => void;
} {
  const { errors, ...uploader } = useContentImageUploader({
    onUpload: onChange,
  });
  useKeepOpen(ExitBlocker, uploader.isUploading);

  useProcessedContentImage({
    image: uploader.image,
    onProcessed: (data) => {
      onChange(data, { shouldValidate: false });
    },
    onError: (error) => {
      setFlashMessage(createFlashError(error));
      onChange();
    },
  });

  const { setFlashMessage } = useFlashMessage();
  useEffect(() => {
    errors.forEach((error) => {
      setFlashMessage(createFlashError(error));
    });
  }, [errors, setFlashMessage]);

  const updateFirst = useCallback(
    (data: ImageData[] | File[] | DataTransferItemList) => {
      if (isImageDataArray(data)) {
        uploader.uploadUrl(data[0].url);
      } else if (data instanceof DataTransferItemList) {
        const file = data[0].getAsFile();
        if (file) uploader.uploadFile(file);
      } else {
        uploader.uploadFile(data[0]);
      }
    },
    [uploader]
  );
  return { ...uploader, errors, updateFirst };
}
