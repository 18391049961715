import React from 'react';
import cx from 'classnames';
import styles from './progress-bar.module.css';

type ProgressBarProps = {
  color: string;
  max: number;
  value: number;
  orientation: 'horizontal' | 'vertical';
  size: number;
  className?: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  color,
  value,
  max,
  orientation,
  size,
  className,
}) => {
  // 2px minimum size - we want the start of the bar to be always visible
  const barSize = Math.max(2, (value * 100) / max);

  const bar = React.useMemo(() => {
    const styleProps = {
      backgroundColor: color,
      height: 'auto',
      width: 'auto',
    };
    if (orientation === 'horizontal') {
      styleProps.height = `${size}px`;
      styleProps.width = `${barSize}%`;
    }
    if (orientation === 'vertical') {
      styleProps.height = `${barSize}%`;
      styleProps.width = `${size}px`;
    }
    return <div style={styleProps} />;
  }, [barSize, size, color, orientation]);

  const dots = React.useMemo(() => {
    return (
      <div
        className={
          orientation === 'vertical' ? styles.dotsTall : styles.dotsWide
        }
        style={{ borderColor: color }}
      />
    );
  }, [orientation, color]);

  return (
    <div
      className={cx(
        className,
        orientation === 'vertical'
          ? styles.progressBarTall
          : styles.progressBarWide
      )}
    >
      {orientation === 'vertical' && dots}
      {bar}
      {orientation === 'horizontal' && dots}
    </div>
  );
};
