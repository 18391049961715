import * as React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { CreatePage } from './CreatePage';
import { EditPage } from './EditPage';
import { ListPage } from './ListPage';

export const Microapps: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['configureMicroappsAccess']}>
    <Router>
      <ListPage path="/" />
      <CreatePage path="new" />
      <EditPage path="edit/:id" />
    </Router>
  </WithPermission>
);
