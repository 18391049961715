import React, { CSSProperties } from 'react';
import { Icon } from 'shared/Icon';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import cx from 'classnames';
import styles from './image.module.css';

type PropsType = {
  url: string | undefined;
  isUploading?: boolean;
  loadingClassName?: string;
  imageClassName?: string;
  placeholderClassName?: string;
  placeholderIconName?: string;
  height?: string;
  width?: string;
};

function Loading(props: { className: string | undefined }) {
  const { className } = props;

  return (
    <div className={cx(styles.loading, className)}>
      <LoadingSpinner />
    </div>
  );
}

export const Image: React.FC<PropsType> = ({
  url,
  isUploading,
  loadingClassName,
  imageClassName,
  placeholderClassName,
  placeholderIconName = 'Image',
  width,
  height,
}) => {
  const style: CSSProperties = {
    backgroundImage: `url(${url})`,
  };
  if (width) style.width = width;
  if (height) style.height = height;
  return url || isUploading ? (
    <div className={cx(styles.image, imageClassName)} style={style}>
      {isUploading ? <Loading className={loadingClassName} /> : null}
    </div>
  ) : (
    <div className={cx(placeholderClassName, styles.placeholder)}>
      <Icon iconType="SVG" iconName={placeholderIconName} />
    </div>
  );
};
