import { useProgramIdState } from 'contexts/program';
import { Video } from 'models/video';
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query';
import { fetchVideoForFeed } from 'services/api-assets';

export const useFetchVideoFromFeed = (
  videoId: number | undefined,
  isDesignAsset?: boolean
): {
  videoFromFeed: Video | undefined;
  refetchVideo: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<Video | undefined>>;
  isLoading: boolean;
} => {
  const [programId] = useProgramIdState();
  const { data: videoFromFeed, refetch: refetchVideo, isLoading } = useQuery(
    ['video_feed', programId, videoId, isDesignAsset],
    () => fetchVideoForFeed({ programId, isDesignAsset, videoId }),
    { enabled: !!videoId, refetchOnWindowFocus: false }
  );

  return {
    videoFromFeed,
    refetchVideo,
    isLoading,
  };
};
