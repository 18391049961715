import React, { useMemo } from 'react';
import { usePermissions } from 'contexts/permissions';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { useProgram } from 'contexts/program';
import { inactivityTimeout } from 'utility/inactivity-timeout';
import { NotFoundScreen } from 'shared/NotFoundScreen';
import { Designs } from 'publish/designs';
import { People } from './People';
import { Calendar } from './Calendar';
import { Content, ContentContextEscape } from './Content';
import { Library } from './Library';
import { Insight } from './Insight';
import { Previews } from '../Previews';
import { TableauLanding } from './Tableau';
import { ContentResults } from './ContentResults';
import { Journey } from './Journey';

const InactivityTimeout: React.FC = () => {
  const { sessionTimeoutMinutes } = useProgram();

  React.useEffect(() => {
    if (sessionTimeoutMinutes) {
      inactivityTimeout(() => {
        window.location.href = '/auth/logout';
      }, sessionTimeoutMinutes);
    }
  }, [sessionTimeoutMinutes]);

  return <div />;
};

export const Main: React.FC<RouteComponentProps> = () => {
  const { permissions } = usePermissions();
  const landingPage = useMemo(() => {
    if (permissions.campaignsAccess) return 'content';
    if (permissions.calendarAccess) return 'calendar';
    if (permissions.insightsAccess) return 'insights';
    if (permissions.peopleAccess) return 'people';
    if (permissions.libraryAccess) return 'library';
    if (permissions.configureAccess) return '../configure';
    return 'not-found';
  }, [permissions]);

  return (
    <>
      <Router>
        <Calendar path="calendar/*" />
        <Content path="content/*" />
        <Journey path="journeys/*" />
        <ContentResults path="content/results/:id/*" />
        <ContentContextEscape path="start-content/*" />
        <People path="people/*" />
        <Library path="library/*" />
        <Designs path="designs/*" />
        <Insight path="insights/*" />
        <TableauLanding path="tableau/*" />
        <Previews path="preview/*" />
        <Redirect noThrow from="/" to={landingPage} />
        <NotFoundScreen default />
      </Router>
      <InactivityTimeout />
    </>
  );
};
