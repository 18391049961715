import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ImageFieldData } from 'models/publisher/block';
import { FieldFormProps } from '../../useFieldForm';
import { useImageDataMutator } from './hooks/useImageDataMutator';
import { useImageUploader } from './hooks/useImageUploader';
import { useImageSourceMenu } from './hooks/useImageSourceMenu';
import { AltText } from './components/AltText';
import { LinkOptions } from './components/LinkOptions';
import { ImageSourceMenu } from './components/ImageSourceMenu';
import { useImageFields } from './hooks/useImageFields';

export const Image: React.FC<
  FieldFormProps<ImageFieldData> & {
    hideLinkOptions?: boolean;
    altTextName?: string;
    imageName?: string;
    tooltipText?: string;
  }
> = (props) => {
  const { hideLinkOptions, altTextName, imageName, tooltipText } = props;
  const mutator = useImageDataMutator(props);
  const image = useImageFields(mutator);
  const { isUploading, updateFirst } = useImageUploader(mutator.changeImage);
  const source = useImageSourceMenu(!!mutator.image?.url, updateFirst);
  return (
    <Flex column>
      <ImageSourceMenu
        name={imageName}
        isUploading={isUploading}
        source={source}
        image={mutator.image}
        changeImage={mutator.changeImage}
      />
      {!hideLinkOptions && (
        <LinkOptions
          href={image.href}
          openInNewTab={image.openInNewTab}
          onChangeHref={image.changeHref}
          onChangeOpenInNewTab={image.changeOpenInNewTab}
        />
      )}
      <AltText
        name={altTextName}
        value={image.isPlaceholder ? '' : image.altText}
        onChange={image.changeAltText}
        tooltipText={tooltipText}
      />
    </Flex>
  );
};
