import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { UpperBar } from 'shared/NavigationBars/FixedBars';
import { IconButton } from '@socialchorus/shared-ui-components';
import { useNavigate } from '@reach/router';
import { TopicStatusPill } from 'App/Program/Configuration/Topics/TopicStatusPill';
import { ConfirmModal } from 'DesignSystem/Components';
import { useBeforeUnload } from 'hooks/useBeforeUnload';
import styles from './styles.module.css';
import { useTopicFormCtx } from '../../context';
import { BlockNavigationContext } from './contexts/blockNavigation';
import { TopicErrorsPill } from './ErrorPill/TopicErrorsPill';

const UNSAVED_CHANGES_MESSAGE =
  'This topic has unsaved changes. If you choose to continue, these changes will be lost.';

const Header: FC = (): JSX.Element => {
  const {
    topic,
    baseUrl,
    handleSave,
    hasUnsavedChanges,
    isTopicUpdating,
    topicUpdateError,
    topicUpdatedAt,
  } = useTopicFormCtx();
  const { shouldBlockNavigation } = useContext(BlockNavigationContext);
  const navigate = useNavigate();

  const returnUrl = useMemo(() => baseUrl.split('/').slice(0, -2).join('/'), [
    baseUrl,
  ]);

  const [navigationUrl, setNavigationUrl] = useState<string | null>(null);

  useBeforeUnload(hasUnsavedChanges, UNSAVED_CHANGES_MESSAGE);

  const handleNavigate = useCallback(
    (url) => {
      if (!hasUnsavedChanges) {
        return true;
      }
      setNavigationUrl(url);
      return false;
    },
    [hasUnsavedChanges]
  );

  const handleClose = useCallback(() => {
    if (!shouldBlockNavigation() && handleNavigate(returnUrl)) {
      navigate(returnUrl);
    }
  }, [navigate, returnUrl, handleNavigate, shouldBlockNavigation]);

  let StatusElement: React.ReactElement | null = null;
  if (isTopicUpdating) {
    StatusElement = <div className={styles.StatusSaving}>Saving</div>;
  } else if (topicUpdateError) {
    StatusElement = (
      <div className={styles.StatusUnsaved}>Update error occurred</div>
    );
  } else if (hasUnsavedChanges) {
    StatusElement = <div className={styles.StatusUnsaved}>Unsaved changes</div>;
  } else if (topicUpdatedAt) {
    StatusElement = <div className={styles.StatusSaved}>Saved</div>;
  }

  return (
    <UpperBar ariaLabel="Topic Form Upper">
      <div className={styles.Heading}>
        Edit Page <span className={styles.HeadingSeparator}>/</span>
        <strong>{topic.name}</strong>
        <span className={styles.HeadingStatus}>
          <TopicStatusPill topic={topic} showDraftOnly />
        </span>
      </div>
      <div className={styles.HeaderActions}>
        <TopicErrorsPill />
        {StatusElement}
        <IconButton
          iconName="save"
          className={styles.ActionButton}
          disabled={isTopicUpdating}
          onClick={handleSave}
        />
        <IconButton
          iconName="close"
          className={styles.ActionButton}
          onClick={handleClose}
        />
      </div>
      {navigationUrl && (
        <ConfirmModal
          title="Unsaved changes"
          confirmLabel="Continue"
          onConfirm={() => {
            navigate(navigationUrl);
          }}
          onCancel={() => setNavigationUrl(null)}
        >
          {UNSAVED_CHANGES_MESSAGE}
        </ConfirmModal>
      )}
    </UpperBar>
  );
};

export default Header;
