import React, { RefObject } from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { CustomSlugsActionFilters } from 'hooks/common';
import { CustomSlug } from 'models/custom-slug';
import { useUserByIdsQuery } from 'hooks/user';
import { useProgram } from 'contexts/program';
import { CustomSlugsBanner } from './CustomSlugsBanner';

type PropsType = {
  filterConfig?: CustomSlugsActionFilters;
  parentRef: RefObject<HTMLDivElement>;
  height: number;
  customSlugs: Array<CustomSlug>;
  isLoading?: boolean;
};

export const CustomSlugsList: React.FC<PropsType> = (props) => {
  const { customSlugs, parentRef, height, isLoading = true } = props;

  const itemCount = customSlugs.length || 0;
  const { id: programId } = useProgram();

  const userIds = customSlugs.map((slug) => Number(slug.createdBy));
  const uniqueUserIds = Array.from(new Set(userIds));

  const { isLoading: isLoadingUserData } = useUserByIdsQuery(
    programId,
    uniqueUserIds
  );

  return (
    <InfiniteBannerList
      itemCount={itemCount}
      height={height}
      parentRef={parentRef}
      noItemsComponent={<div>No items match that criteria.</div>}
      isLoading={isLoading || isLoadingUserData}
      isFetchingNextPage={false}
      hasNextPage={false}
      fetchNextPage={() => {}}
    >
      {(index: number) => <CustomSlugsBanner customSlug={customSlugs[index]} />}
    </InfiniteBannerList>
  );
};
