import { useMemo } from 'react';
import {
  QueryClient,
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMatch, useParams } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useFlashServerErrors } from 'utility/errors';
import { DEFAULT_ERROR_MESSAGE } from 'models/flash-message';
import { getGeneratedGroupTitles } from 'utility/groups';
import { Topic } from 'models/topic';
import {
  AudienceBulkActionFilters,
  BulkSelection,
  EditResponse,
  InfiniteQueryResponse,
  MutationOptions,
  nextPageToFetch,
  OptionType,
  QueryResponse,
} from './common';
import {
  Audience,
  audienceIntersectionQuery,
  setTitle,
} from '../models/audience';
import { Expression } from '../models/expression';
import {
  addAudienceToFavorites,
  archiveAudience,
  archiveAudiences,
  AudienceReadData,
  AudiencesCollectionData,
  AudienceSourceData,
  AudienceTag,
  AudienceTagsResponse,
  AudienceWriteData,
  CampaignAudienceQuery,
  createAudience,
  createSnapshot,
  CriterionCollectionData,
  exportAudienceUsers,
  fetchAudience,
  fetchAudienceByParams,
  fetchAudienceCriteria,
  fetchAudiences,
  FetchAudiencesProps,
  fetchAudienceTags,
  fetchCampaignUsersCount,
  fetchFavoriteAudiences,
  fetchSuggestedQuery,
  fetchUserExport,
  fetchUsersCount,
  FetchUsersCountResponse,
  fetchValueSuggestions,
  removeAudienceFromFavorites,
  unarchiveAudience,
  unArchiveAudiences,
  updateAudience,
  UserExportData,
} from '../services/api-audiences';
import { useEdit } from './useEdit';

const jsonToExpression = (expression?: string): Expression | undefined => {
  try {
    return expression && JSON.parse(expression);
  } catch {
    return undefined;
  }
};

export const mapDataToAudience = (data: AudienceReadData): Audience => ({
  ...data,
  totalUsers: data.totalUsers ?? 0,
  id: data.numericId,
  expression: jsonToExpression(data.expression),
});

const mapSourceArrayToAudiences = (
  data: Array<AudienceSourceData>
): Array<Audience> => {
  return data
    .map((audience: AudienceSourceData) => audience.attributes)
    .map(mapDataToAudience);
};

const mapAudienceToData = (data: Audience): AudienceWriteData => ({
  ...data,
  tags: (data.tags || []).join(', '),
  expression: data.expression && JSON.stringify(data.expression),
});

const audienceKey = ({ programId, id }: { programId: number; id?: string }) => [
  'audience',
  programId,
  id,
];

export type AudienceQuery = {
  data?: Audience;
  isLoading: boolean;
};

interface UseAudiencesQueryOptions {
  enabled?: boolean;
}

export const useAudiencesQuery = (
  props: FetchAudiencesProps,
  options: UseAudiencesQueryOptions = {}
): QueryResponse<Array<Audience>> => {
  const {
    programId,
    page,
    pageSize,
    search,
    tags,
    statuses,
    types,
    name,
  } = props;

  const { isLoading, error, data } = useQuery<AudiencesCollectionData, Error>(
    ['audiences', JSON.stringify(props)], // Cache key, must be distinct for different query params
    () =>
      fetchAudiences({
        programId,
        page,
        pageSize,
        search,
        tags,
        statuses,
        types,
        name,
      }),
    { retry: false, ...options }
  );
  // Only map data if it was changed to avoid unnecessary re-renders
  const audiences = useMemo(() => mapSourceArrayToAudiences(data?.data || []), [
    data,
  ]);
  return {
    isLoading,
    errorMessage: error?.message,
    data: audiences,
  };
};

export const useAudiencesInfiniteQuery = (
  props: Omit<FetchAudiencesProps, 'page'>
): InfiniteQueryResponse<Audience> => {
  const {
    programId,
    pageSize = 20,
    search,
    statuses,
    tags,
    types,
    favorites,
    name,
    hideUserCount = false,
    isFeatureFlagLoading = false,
  } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AudiencesCollectionData, Error>(
    ['audiences-infinite', props],
    async ({ pageParam = 1 }) =>
      fetchAudiences({
        programId,
        page: pageParam,
        pageSize,
        search,
        statuses,
        tags,
        types,
        visibility: 'visible',
        favorites,
        name,
        hideUserCount,
      }),
    {
      enabled: !isFeatureFlagLoading,
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );
  const flatData = data
    ? data.pages
        .map((batch) => (batch?.data || []).map((item) => item.attributes))
        .flat(1)
    : [];

  // Preload the fetched audience data items into the query cache for the
  // corresponding individual audience queries. Do this before mapping the
  // items to Audience objects, so they'll go into the cache as raw data.
  const queryClient = useQueryClient();
  flatData.forEach((audienceData) => {
    const { id } = audienceData;
    const key = audienceKey({ programId, id });
    // If this isn't guarded it can lead to an infinite loop
    if (!queryClient.getQueryData(key)) {
      queryClient.setQueryData(key, audienceData);
    }
  });

  const audiences = flatData.map(mapDataToAudience);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: audiences,
    meta: data?.pages[0].meta,
  };
};

const AudienceDataByQueries = (
  queries: UseQueryOptions[]
): Array<AudienceQuery> => {
  const audiences = useQueries(queries) as Array<
    UseQueryResult<AudienceReadData>
  >;
  const result: Array<AudienceQuery> = [];

  audiences.forEach((audienceData) => {
    const { data, isLoading } = audienceData;
    let audience: Audience | undefined;

    if (data) audience = mapDataToAudience(data);

    result.push({ data: audience, isLoading });
  });

  return result;
};

export const useAudienceByIdQueries = (
  programId: number,
  ids: Array<string>
): Array<AudienceQuery> => {
  const queries: UseQueryOptions[] =
    ids.map((id) => {
      return {
        queryKey: audienceKey({ programId, id }),
        queryFn: () => fetchAudience(programId, id),
        options: { retry: false },
      };
    }) || [];

  return AudienceDataByQueries(queries);
};

export const useAudienceByTitlesQueries = (
  programId: number,
  titles: Array<string>
): Array<AudienceQuery> => {
  const queries: UseQueryOptions[] =
    titles.map((title) => {
      return {
        queryKey: ['audience', programId, title],
        queryFn: () =>
          fetchAudienceByParams(programId, { exact_title: title }).catch(() => {
            return null;
          }),
        options: { retry: false },
      };
    }) || [];
  return AudienceDataByQueries(queries);
};

export const useTopicSubscribersAudience = (
  programId: number,
  contentTopics: Topic[]
): Audience[] => {
  const generatedGroupTitles = getGeneratedGroupTitles(contentTopics);

  const topicAudienceQueries = useAudienceByTitlesQueries(
    programId,
    generatedGroupTitles
  );
  const topicAudiences = useMemo(() => {
    const memo: Array<Audience> = [];

    topicAudienceQueries.forEach((query) => {
      if (query.data) memo.push(query.data);
    });

    return memo;
  }, [topicAudienceQueries]);

  return topicAudiences;
};

export const useTopicSubscribersAudienceWithLoading = (
  programId: number,
  contentTopics: Topic[]
): { data: Audience[]; isLoading: boolean } => {
  const generatedGroupTitles = getGeneratedGroupTitles(contentTopics);

  const topicAudienceQueries = useAudienceByTitlesQueries(
    programId,
    generatedGroupTitles
  );

  const { topicAudiences, isLoading } = useMemo(() => {
    const audiences: Array<Audience> = [];
    let stillLoading = false;

    topicAudienceQueries.forEach((query) => {
      if (query.data) audiences.push(query.data);
      if (query.isLoading) stillLoading = true;
    });

    return {
      topicAudiences: audiences,
      isLoading: stillLoading,
    };
  }, [topicAudienceQueries]);

  return {
    data: topicAudiences,
    isLoading,
  };
};

export const useTopicsSubscribersInAudienceQuery = (
  programId: number,
  contentTopics: Topic[],
  audiences: Audience[],
  { enabled = true }: { enabled?: boolean } = {}
): QueryResponse<FetchUsersCountResponse> => {
  const topicAudiences = useTopicSubscribersAudience(programId, contentTopics);
  const query = audienceIntersectionQuery(topicAudiences, audiences);
  const audienceUsers = useAudienceUsersQuery(programId, query || '', {
    enabled: enabled && !!query,
  });
  return audienceUsers;
};

export const useAudienceQuery = (
  programId: number,
  id: string
): QueryResponse<Audience> => {
  const { isLoading, error, data } = useQuery<AudienceReadData, Error>(
    audienceKey({ programId, id }),
    () => fetchAudience(programId, id),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToAudience(data),
  };
};

export const useAudienceTagsQuery = (
  programId: number
): QueryResponse<AudienceTag[]> => {
  const { isLoading, error, data } = useQuery<
    AudienceTagsResponse | undefined,
    Error
  >(
    ['audienceTags', programId], // Cache key, must be distinct for different query params
    () => fetchAudienceTags(programId),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export const useFavoriteAudiencesQuery = (
  programId: number
): QueryResponse<number[]> => {
  const { isLoading, error, data } = useQuery<number[] | undefined, Error>(
    ['favoriteAudiences', programId],
    () => fetchFavoriteAudiences(programId),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

const emptyAudienceReturn = {
  isLoading: false,
  errorMessage: undefined,
  data: { totalObjects: 0 },
};

export const useAudienceUsersQuery = (
  programId: number,
  q: string,
  {
    retry = false,
    enabled = true,
  }: { retry?: boolean | number; enabled?: boolean } = {}
): QueryResponse<FetchUsersCountResponse> => {
  const params = useParams();

  const isPublisherPage = useMatch('/:programId/edit/publisher/:id/*');
  const contentId =
    isPublisherPage && !Number.isNaN(Number(params?.id))
      ? Number(params.id)
      : undefined;

  const { isLoading, error, data } = useQuery<FetchUsersCountResponse, Error>(
    ['audienceUsers', programId, q], // Cache key, must be distinct for different query params
    () => fetchUsersCount(q, programId, params?.groupId, contentId),
    { retry, enabled }
  );
  return enabled
    ? { isLoading, errorMessage: error?.message, data }
    : emptyAudienceReturn;
};

export const useAudienceArchive = (
  audience: Audience,
  { onSuccess }: MutationOptions<Audience> = {}
): { archive: () => void } => {
  const queryClient = useQueryClient();
  const archive = () => {
    const request = archiveAudience(mapAudienceToData(audience));

    request.then((data) => {
      queryClient.invalidateQueries(['audience']);
      if (onSuccess) onSuccess(mapDataToAudience(data));
    });
  };
  return {
    archive,
  };
};

export const useAudienceBulkArchive = (
  programId: number,
  { onSuccess }: MutationOptions<string> = {}
): {
  archive: (
    bulkSelection: BulkSelection,
    filterConfig: AudienceBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const archive = (
    bulkSelection: BulkSelection,
    filterConfig: AudienceBulkActionFilters
  ) => {
    const request = archiveAudiences(programId, bulkSelection, filterConfig);

    request.then(() => {
      queryClient.invalidateQueries(['audiences-infinite']);
      if (onSuccess) onSuccess('');
    });
  };
  return {
    archive,
  };
};

export const useAudienceBulkUnArchive = (
  programId: number,
  { onSuccess }: MutationOptions<string> = {}
): {
  unArchive: (
    bulkSelection: BulkSelection,
    filterConfig: AudienceBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const unArchive = (
    bulkSelection: BulkSelection,
    filterConfig: AudienceBulkActionFilters
  ) => {
    const request = unArchiveAudiences(programId, bulkSelection, filterConfig);

    request.then(() => {
      queryClient.invalidateQueries(['audiences-infinite']);
      if (onSuccess) onSuccess('');
    });
  };
  return {
    unArchive,
  };
};

export const useExportAudience = (
  audience?: Audience,
  { onSuccess }: MutationOptions<UserExportData> = {}
): { exportAudience: () => void } => {
  const { id: programId } = useProgram();
  const exportAudience = () => {
    if (audience) {
      const request = exportAudienceUsers(
        programId,
        audience.type,
        audience.name,
        audience.title,
        audience.query || '*',
        audience.createdAt ? 'group' : 'query'
      );
      request.then((data) => {
        if (onSuccess) onSuccess(data);
      });
    }
  };
  return {
    exportAudience,
  };
};

export const useUserExportQuery = (
  id: number,
  programId: number
): QueryResponse<UserExportData> => {
  const { isLoading, error, data } = useQuery<UserExportData, Error>(
    ['userExport', id], // Cache key, must be distinct for different query params
    () => fetchUserExport(programId, id),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useAddAudienceToFavorites = (
  audience: Audience,
  { onSuccess }: MutationOptions<string> = {}
): { addToFavorites: () => void } => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();
  const addToFavorites = () => {
    const request = addAudienceToFavorites(programId, Number(audience.id));

    request.then((data) => {
      queryClient.invalidateQueries(['audience']);
      if (onSuccess) onSuccess(data);
    });
  };
  return {
    addToFavorites,
  };
};

export const useRemoveAudienceFromFavorites = (
  audience: Audience,
  { onSuccess }: MutationOptions<string> = {}
): { removeFromFavorites: () => void } => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();

  const removeFromFavorites = () => {
    const request = removeAudienceFromFavorites(programId, Number(audience.id));

    request.then((data) => {
      queryClient.invalidateQueries(['audience']);
      if (onSuccess) onSuccess(data);
    });
  };
  return {
    removeFromFavorites,
  };
};

export const useAudienceUnarchive = (
  audience: Audience,
  { onSuccess }: MutationOptions<Audience> = {}
): { unarchive: () => void } => {
  const queryClient = useQueryClient();
  const unarchive = () => {
    const request = unarchiveAudience(mapAudienceToData(audience));
    request.then((data) => {
      queryClient.invalidateQueries(['audience']);
      if (onSuccess) onSuccess(mapDataToAudience(data));
    });
  };
  return {
    unarchive,
  };
};

export const useAudienceDuplicate = (
  audience: Audience,
  { onSuccess }: MutationOptions<Audience> = {}
): { duplicate: () => void } => {
  const copyNew = (result: AudienceReadData) => {
    const fullAudiencePayload = mapDataToAudience(result);
    const newAudience = setTitle(fullAudiencePayload, `${audience.title} Copy`);
    newAudience.name = '';
    return newAudience;
  };
  const duplicate = () => {
    // do a fresh get because we're missing fields.
    // TODO we should have a dedicated copy endpoint to reduce the payload going back and forth from the browser.
    // just pass the id
    const { id, programId } = audience;
    if (id) {
      fetchAudience(programId, id).then((result) => {
        const request = createAudience(mapAudienceToData(copyNew(result)));
        request.then((data) => {
          if (onSuccess) onSuccess(mapDataToAudience(data));
        });
      });
    } else {
      const request = createAudience(
        mapAudienceToData(copyNew(audience as AudienceReadData))
      );
      request.then((data) => {
        if (onSuccess) onSuccess(mapDataToAudience(data));
      });
    }
  };
  return {
    duplicate,
  };
};

export const useAudienceSaveAs = (
  audience: Audience,
  { onSuccess }: MutationOptions<Audience> = {}
): { saveAs: (title: string) => void } => {
  const saveAs = (title: string) => {
    const newAudience = setTitle(audience, title);
    newAudience.name = '';
    const request = createAudience(mapAudienceToData(newAudience));
    request.then((data) => {
      if (onSuccess) onSuccess(mapDataToAudience(data));
    });
  };
  return {
    saveAs,
  };
};

export const useAudienceCreateSnapshot = (
  audience: Audience,
  { onSuccess }: MutationOptions<string> = {}
): { createSnapshot: () => void } => {
  const snapshot = () => {
    const { programId, id } = audience;
    if (id) {
      fetchAudience(programId, id).then((result) => {
        const request = createSnapshot(
          mapAudienceToData(mapDataToAudience(result))
        );
        request.then((data) => {
          if (onSuccess) onSuccess(data);
        });
      });
    } else {
      const request = createSnapshot(mapAudienceToData(audience));
      request.then((data) => {
        if (onSuccess) onSuccess(data);
      });
    }
  };
  return {
    createSnapshot: snapshot,
  };
};

const persistAudience = (
  audience: Audience,
  queryClient: QueryClient,
  onSuccess?: (newAudience: Audience) => void,
  onError?: (error: Error) => void
) => {
  const request = audience.id
    ? updateAudience(mapAudienceToData(audience))
    : createAudience(mapAudienceToData(audience));

  return request
    .then((response) => {
      const newAudience = mapDataToAudience(response);
      queryClient.invalidateQueries('audiences');
      queryClient.invalidateQueries([
        'audience',
        response.programId,
        response.numericId.toString(),
      ]);
      if (onSuccess) {
        onSuccess(newAudience);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const useAudienceEdit = (
  audience: Audience,
  { onSuccess }: MutationOptions<Audience> = {}
): EditResponse<Audience> => {
  const queryClient = useQueryClient();
  const flashServerErrors = useFlashServerErrors();
  const onError = (error: Error) => {
    flashServerErrors(error, DEFAULT_ERROR_MESSAGE);
  };

  const { setData, data, isSaving, errorMessage, save } = useEdit(
    audience,
    (obj) => persistAudience(obj, queryClient, onSuccess, onError),
    true
  );

  const isSaved = useMemo<boolean>(() => {
    return (
      data.title === audience.title &&
      data.description === audience.description &&
      JSON.stringify(data.tags) === JSON.stringify(audience.tags) &&
      data.query === audience.query
    );
  }, [
    audience.description,
    audience.query,
    audience.tags,
    audience.title,
    data.description,
    data.query,
    data.tags,
    data.title,
  ]);

  return {
    data,
    isSaved,
    isSaving,
    errorMessage,
    revert: () => setData({ ...audience }),
    save,
    setData,
  };
};

export const useValueSuggestionsQuery = (
  programId: number,
  criterion: string,
  term: string,
  scope: string,
  audienceName?: string,
  regex = false
): QueryResponse<Array<OptionType>> => {
  const { isLoading, error, data } = useQuery<OptionType[] | undefined, Error>(
    ['suggestions', criterion, term, scope],
    () => fetchValueSuggestions(programId, criterion, term, scope, regex),
    { retry: false }
  );

  let responseData = data;
  if (['role', 'classic_role'].includes(criterion)) {
    responseData = data?.map((option) => {
      if (option.label.includes('channel_contributor')) {
        return {
          ...option,
          label: option.label.replace('channel_contributor', 'topic_manager'),
        };
      }

      if (option.label.includes('program_admin')) {
        return {
          ...option,
          label: option.label.replace('program_admin', 'community_manager'),
        };
      }

      if (option.label.includes('program_manager')) {
        return {
          ...option,
          label: option.label.replace('program_manager', 'community_manager'),
        };
      }

      return option;
    });
  }

  const filteredOptionsWithoutAudienceName = () => {
    if (
      audienceName === undefined ||
      criterion.toLocaleLowerCase() !== 'group'
    ) {
      return responseData;
    }

    return responseData?.filter((option) => {
      return !(option.label === audienceName);
    });
  };

  return {
    isLoading,
    errorMessage: error?.message,
    data: filteredOptionsWithoutAudienceName() || [],
  };
};

export const useAudienceCriteriaQuery = (
  programId: number,
  journeysEnabled: boolean
): QueryResponse<Array<{ id: string; type: string }>> => {
  const excludeUnpopulatedProfileFields = !journeysEnabled;
  const { isLoading, error, data } = useQuery<
    CriterionCollectionData | undefined,
    Error
  >(
    ['audienceCriteria', programId, excludeUnpopulatedProfileFields], // Cache key, must be distinct for different query params
    () => fetchAudienceCriteria(programId, excludeUnpopulatedProfileFields),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export const useCampaignUsersCountQuery = (
  programId: number,
  campaignAudience: CampaignAudienceQuery
): QueryResponse<{ advocateCount: number }> => {
  const { isLoading, error, data } = useQuery<{ advocateCount: number }, Error>(
    ['audienceCount', programId],
    () => fetchCampaignUsersCount(programId, campaignAudience),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export interface SuggestQueryResponse {
  output: string;
  task_id: string;
}

export const useSuggestQuery = (
  programId: number,
  criteria: Array<OptionType>,
  { onError, onSuccess }: MutationOptions<SuggestQueryResponse> = {}
): {
  generate: (description: string, initialQuery: string) => void;
} => {
  const generate = (description: string, initialQuery: string) => {
    fetchSuggestedQuery(programId, criteria, description, initialQuery)
      .then((response) => {
        if (onSuccess) {
          onSuccess({
            output: response?.outputs?.join('\n'),
            task_id: response?.task_ids[0],
          });
        }
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { generate };
};
