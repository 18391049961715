import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';

export const AssistantMascot: React.FC<RouteComponentProps> = () => (
  <BossViewPage
    title="Assistant Mascot"
    src="#user-experience"
    app="programs"
    returnUrls={['/configure/assistant-mascot']}
  />
);
