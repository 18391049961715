import * as React from 'react';
import { Icon } from 'shared/Icon';
import { Video } from 'models/video';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { ProgressBar } from 'shared/ProgressBar';
import { VideoPlayer } from '../Player';
import styles from './video.module.css';

type PropsType = {
  video?: Video;
  uploadedBytes?: number;
  totalBytes?: number;
  isUploading: boolean;
  isTranscoding: boolean;
  isCompleted: boolean;
  transcodeJobPercentComplete?: number;
};

export const VideoPreview: React.FC<PropsType> = ({
  video,
  uploadedBytes,
  totalBytes,
  isUploading,
  isTranscoding,
  isCompleted,
  transcodeJobPercentComplete,
}) => {
  let percentUploaded = 0;
  // Protect against divide by zero (or undefined)
  if (totalBytes) {
    percentUploaded = Math.floor(((uploadedBytes || 0) * 100.0) / totalBytes);
  }

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <>
      {video &&
      isCompleted &&
      (video.customPreviewImageUrl || video.previewImageUrl) ? (
        <VideoPlayer video={video} />
      ) : (
        <div className={styles.placeholder}>
          {!(isUploading || isTranscoding) && (
            <Icon iconType="SVG" iconName="Video" size={50} />
          )}
          {isUploading && (
            <div>
              <span>{percentUploaded}% uploaded</span>
              <ProgressBar
                color="var(--color-brandTintDark)"
                max={100}
                value={percentUploaded}
                orientation="horizontal"
                size={5}
              />
            </div>
          )}

          {isTranscoding && (
            <>
              <LoadingSpinner />
              {transcodeJobPercentComplete && (
                <div className={styles.videoTranscodeJobPercentComplete}>
                  <span>{transcodeJobPercentComplete}% processed</span>
                  <ProgressBar
                    color="var(--color-brandTintDark)"
                    max={100}
                    value={transcodeJobPercentComplete}
                    orientation="horizontal"
                    size={5}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
