import React from 'react';
import {
  AcknowledgementConfig,
  acknowledgementLabelUtils,
} from 'shared/AcknowledgementConfig';
import { Label } from 'models/label';
import { CommunicationStepErrors } from 'models/journeys/journey-errors';
import { Flex } from 'DesignSystem/Layout/Flex';
import { CheckboxGroup } from 'shared/CheckboxGroup';
import styles from './content-settings.module.css';
import communicationStyles from '../communication-config.module.css';
import { ConfigurationSection } from '../layout';

export const ContentSettings: React.FC<{
  acknowledgementLabel?: Label;
  onAcknowledgementLabelChange: (label?: Label) => void;
  errors?: CommunicationStepErrors;
}> = ({ acknowledgementLabel, onAcknowledgementLabelChange, errors }) => {
  const {
    defaultLabel: defaultAcknowledgementLabel,
  } = acknowledgementLabelUtils();
  const acknowledgementLabelError = errors?.acknowledgement?.join(', ');
  return (
    <div className={styles.contentSettingsContainer}>
      <ConfigurationSection title="Content Settings">
        <CheckboxGroup
          label="Require Acknowledgement"
          isChecked={acknowledgementLabel != null}
          toggleChecked={() => {
            onAcknowledgementLabelChange(
              acknowledgementLabel ? undefined : defaultAcknowledgementLabel
            );
          }}
        >
          <AcknowledgementConfig
            label={acknowledgementLabel}
            onChange={onAcknowledgementLabelChange}
          />
          <Flex className={communicationStyles.errorsContainer} spread>
            <Flex>
              {acknowledgementLabelError && (
                <div className={communicationStyles.error}>
                  {acknowledgementLabelError}
                </div>
              )}
            </Flex>
          </Flex>
        </CheckboxGroup>
      </ConfigurationSection>
    </div>
  );
};
