import { useProgram } from 'contexts/program';
import { useRoleQuery } from 'hooks/roles';

export const useHasNoRestrictions = (role: string): boolean => {
  const { id: programId } = useProgram();
  const { data: roleData } = useRoleQuery(programId, `${role}`);

  return (
    (role !== 'member' &&
      roleData?.aliases.some(({ name }) => name === 'no_restrictions')) ||
    false
  );
};
