import React from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Button } from 'DesignSystem/Form';

export const SaveModalButtons: React.FC<{
  onCancel?: () => void;
  onSave: () => void;
  disabled?: boolean;
}> = ({ onCancel, onSave, disabled }) => (
  <Box
    style={{
      display: 'flex',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      justifyContent: 'right',
    }}
  >
    <Box padding={[16, 0, 0, 0]}>
      <Button secondary action label="Cancel" onClick={onCancel} />
      <Button
        action
        disabled={disabled}
        label="Save"
        onClick={() => {
          onSave();
        }}
      />
    </Box>
  </Box>
);
