import * as React from 'react';
import { ImagePicker } from 'shared/Library/ImagePicker';

export const LibraryPicker: React.FC<React.ComponentProps<
  typeof ImagePicker
>> = ({ maxSelections, onCancel, onChange }) => (
  <ImagePicker
    useModal
    maxSelections={maxSelections}
    onCancel={onCancel}
    onChange={onChange}
  />
);
