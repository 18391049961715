import React from 'react';
import { Router, Redirect, RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { ClassicContent } from './ClassicContent';
import { Publisher } from './Publisher';
import { Template } from './Template';
import { FontEditor } from '../Main/Library/fonts/Editor';

export const Editors: React.FC<RouteComponentProps> = () => {
  return (
    <WithPermission permissions={['publishAccess']}>
      <Router>
        <ClassicContent path="content/:id" />
        <Publisher path="publisher/*" />
        <Template path="template/*" />
        <FontEditor path="font/:id" />
        <Redirect from="/" to=".." noThrow />
        <Redirect from="*" to="/" noThrow />
      </Router>
    </WithPermission>
  );
};
