import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from './feature-flags';

export function useNotificationCenterEnabled(): boolean {
  const programId = useProgram().id;
  const enabled = Boolean(
    useFeatureFlagsQuery(programId, 'EE.NotificationCenter').data?.value
  );
  return enabled;
}

export function useNotificationCenterLabel():
  | 'Notification Center'
  | 'Assistant' {
  const enabled = useNotificationCenterEnabled();
  return enabled ? 'Notification Center' : 'Assistant';
}
