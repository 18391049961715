import React from 'react';
import { VideoFieldData } from 'models/donkey';
import { useVideo } from '../../hooks/useVideo';
import { UploadedVideoPlayer } from '../VideoFile/UploadedVideoPlayer';

type CaptionsVideoPreviewProps = {
  fieldData: VideoFieldData;
};

const MAX_VIDEO_PLAYER_HEIGHT = 360;

export const CaptionsVideoPreview: React.FC<CaptionsVideoPreviewProps> = ({
  fieldData,
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const onResize = React.useCallback(() => setIsVisible(true), []);

  const { video } = useVideo({ fieldData, onChange: () => {} });

  // The videoJS player dimensions are controlled by its width.  The following callback
  // observes the modal body to determine what the video's width should be to fit in
  // half of the modal body's height.
  const videoPlayerContainerRef = React.useCallback(
    (node) => {
      if (!node) return;

      const resizeObserver = new ResizeObserver(([entry]) => {
        if (!video?.height || !video?.width) return;

        const parentHeight = Math.min(
          entry.contentRect.height / 2,
          MAX_VIDEO_PLAYER_HEIGHT
        );
        const videoScale = parentHeight / video.height;
        const playerContainerWidth = video.width * videoScale;

        node.style.setProperty('width', `${playerContainerWidth}px`);
      });
      resizeObserver.observe(node.parentElement.parentElement);
    },
    [video?.height, video?.width]
  );

  if (!video) return null;

  return (
    <div ref={videoPlayerContainerRef} style={{ opacity: isVisible ? 1 : 0 }}>
      <UploadedVideoPlayer video={video} onResize={onResize} />
    </div>
  );
};
