import React from 'react';
import { LinkData, SocialData } from 'models/publisher/block';
import { Droppable } from 'react-beautiful-dnd';
import { useUniqueId } from 'hooks/useUniqueId';
import { useFlashMessage } from 'contexts/flasher';
import cx from 'classnames';
import { Eraser, Plus } from 'shared/icons';
import { SourceMenu } from '../../SourceMenu';
import { Source, useLinksSourceMenu, MENU } from '../useLinksSourceMenu';
import { DnDContext } from './DnDContext';
import { DraggablePreview } from './DraggablePreview';
import styles from './list.module.css';
import { useMenu } from '../../SourceMenu/useSourceMenu';

type PropsType = {
  type: string;
  onEdit: (index: number) => void;
  onUpdate: (links: (LinkData | SocialData)[]) => void;
  onClearAll: () => void;
  links: (LinkData | SocialData)[];
  source: ReturnType<typeof useLinksSourceMenu>;
};

export const List: React.FC<PropsType> = ({
  links,
  onEdit,
  onClearAll,
  onUpdate,
  source,
  type,
}) => {
  const uniqueId = useUniqueId();
  const { setFlashMessage } = useFlashMessage();
  const isSocialLink = React.useMemo(() => type === 'social[]', [type]);

  const remove = React.useCallback(
    (index: number) => {
      if (links.length === 1) {
        setFlashMessage({
          message: 'At least one link is required',
          severity: 'warning',
        });

        return;
      }

      links.splice(index, 1);
      onUpdate([...links]);
    },
    [links, onUpdate, setFlashMessage]
  );

  const handleOnCreate = React.useCallback(
    (initial?: Source) => {
      onEdit(links.length);
      if (!initial) return;
      source.open(initial);
    },
    [links, onEdit, source]
  );
  const createSocialLink = React.useCallback(() => {
    onEdit(links.length);
  }, [links.length, onEdit]);

  const menu = useMenu(MENU, handleOnCreate);

  const smallPlusButton = React.useMemo(
    () => (
      <button
        className={cx(styles.actionButton, styles.addButton)}
        type="button"
        onClick={isSocialLink ? createSocialLink : () => {}}
      >
        <Plus />
        <span>New {isSocialLink ? 'social ' : ''}link</span>
      </button>
    ),
    [createSocialLink, isSocialLink]
  );

  const bigPlusButton = React.useMemo(
    () => (
      <button
        className={styles.addButton}
        data-test="add-link"
        type="button"
        onClick={isSocialLink ? createSocialLink : () => {}}
      >
        <Plus />
      </button>
    ),
    [createSocialLink, isSocialLink]
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!isSocialLink && (
          <SourceMenu menu={menu}>{smallPlusButton}</SourceMenu>
        )}
        {isSocialLink && smallPlusButton}
        <button
          className={cx(styles.actionButton, styles.clearButton, {
            [styles.inactive]: links.length === 0,
          })}
          type="button"
          onClick={onClearAll}
        >
          <Eraser />
          <span>Clear all</span>
        </button>
      </div>
      <DnDContext links={links} onDrop={onUpdate}>
        <Droppable droppableId={uniqueId}>
          {(dropProvided) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}
              className={styles.row}
            >
              {links.map((link, index) => (
                <DraggablePreview
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  link={link}
                  onClick={() => onEdit(index)}
                  onRemove={() => remove(index)}
                />
              ))}
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DnDContext>
      <div className={styles.footer}>
        {!isSocialLink && <SourceMenu menu={menu}>{bigPlusButton}</SourceMenu>}
        {isSocialLink && bigPlusButton}
      </div>
    </div>
  );
};
