import React, { RefObject, useRef } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import { useDebounce } from 'hooks/useDebounce';
import { useInView } from 'hooks/use-in-view';
import { Flex } from 'DesignSystem/Layout/Flex';
import { DefaultLoading } from './DefaultLoading';
import { useKeyboardNavigation } from './useKeyboardNavigation';
import { useAutofocusAtTop } from './useAutofocusAtTop';
import {
  moveUpHighlight,
  moveDownHighlight,
  firstHighlightableIndex,
} from './utils';
import styles from './infinite-list.module.css';

const DEFAULT_THRESHOLD = 5;

/**
 * Props Usage:
 *   Required for list rendering:
 *     - itemCount:          The count of all items currently in memory.  For infinite loading it should
 *                           be just how many have been fetched so far.
 *     - itemHeight:         Either a number or a function.  If a scalar number, it is the height of
 *                           every item in the list.  If a function, it maps the item index to its
 *                           height.
 *     - height:             The height of the entire list.
 *     - children:           Must be a render prop mapping an index number to a component.
 *   Item highlighting:
 *     - highlightable:      Flag to turn on highlighting for the entire list.
 *     - itemHighlightable:  Function saying if an item is highlightable.  Useful for making
 *                           category headers or disabled items in a multi-select.  Defaults to
 *                           always true if highlightable is turned on.
 *     - onHighlight:        Callback notified when the highlight changed to a new index.
 *     - onSelect:           Callback notified when enter is pressed on a highlighted item.
 *   Infinite loading (all optional, do not provide for finite lists):
 *     - isLoading:          The boolean corresponding to `isLoading` when using react-query's
 *                           `useQuery` or `isFetching` when using `useInfiniteQuery`.
 *     - hasNextPage:       The boolean corresponding to `hasNextPage` from `useInfiniteQuery`.
 *     - fetchNextPage:          The promise corresponding to 'fetchNextPage` from `useInfiniteQuery`.
 *     - isFetchingNextPage:     The value corresponding to `isFetchingNextPage` (converted to boolean) from
 *                           `useInfiniteQuery`.
 *     - threshold:          The threshold of how many items away from scrolling to the end should
 *                           trigger a `fetchNextPage`.  Defaults to 5.
 *     - noItemsComponent:   JSX to render if there are no items (if `itemCount` is zero and no fetching
 *                           is occurring).
 *     - loadingComponent:   JSX to render for the last item of the list if it scrolls into view while
 *                           a `fetchNextPage` is happening.
 */

export type HighlightProps = {
  highlightable?: boolean;
  itemHighlightable?: (index: number) => boolean;
  onHighlight?: (index: number) => void;
  onSelect?: (index: number) => void;
  autofocus?: boolean;
};

export type InfiniteLoadProps = {
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  threshold?: number;
  overscan?: number;
};

type PropsType = {
  itemCount: number;
  itemHeight: ((index: number) => number) | number;
  noItemsComponent?: React.ReactNode;
  loadingComponent?: React.ReactNode;
  children: (index: number) => React.ReactNode;
  hoverFocus?: boolean;
  height: number;
  parentRef?: RefObject<HTMLDivElement>;
  columnCount?: number;
  columnWidth?: number;
} & HighlightProps &
  InfiniteLoadProps;

export const InfiniteList: React.FC<PropsType> = ({
  itemCount,
  itemHeight,
  height,
  highlightable,
  itemHighlightable = () => !!highlightable,
  onHighlight,
  onSelect,
  autofocus,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  threshold = DEFAULT_THRESHOLD,
  overscan,
  noItemsComponent,
  loadingComponent = <DefaultLoading />,
  children,
  hoverFocus,
  parentRef,
  columnCount = 0,
  columnWidth = 0,
}) => {
  const [highlight, setHighlight] = React.useState<number | undefined>();

  const handleSetHighlight = React.useCallback(
    (index: number) => {
      if (onHighlight && index !== highlight) {
        onHighlight(index);
      }
      setHighlight(index);
    },
    [highlight, onHighlight]
  );

  const defaultRef = React.useRef<HTMLDivElement>(null);
  const ref = parentRef || defaultRef;
  const fixedHeight = ref === defaultRef;
  // added a defaultRef for components that do not need the parentRef
  // to be passed down. these components also require the height of
  // the list to be set

  const estimateSize = React.useMemo(() => {
    if (typeof itemHeight === 'function') {
      return itemHeight;
    }
    return () => itemHeight;
  }, [itemHeight]);

  const rowVirtualizer = useVirtual({
    size: Math.max(isLoading ? 1 : 0, hasNextPage ? itemCount + 1 : itemCount),
    parentRef: ref,
    estimateSize: React.useCallback(estimateSize, [estimateSize]),
    overscan,
  });

  const columnVirtualizer = useVirtual({
    horizontal: true,
    size: columnCount,
    parentRef: ref,
    estimateSize: React.useCallback(() => columnWidth, [columnWidth]),
  });

  const onMouseEnterItem = React.useCallback(
    (index: number) => {
      if (itemHighlightable(index)) {
        handleSetHighlight(index);
      }
    },
    [handleSetHighlight, itemHighlightable]
  );

  const rowRenderer = React.useCallback(
    (virtualRow: VirtualItem) => {
      const { index, measureRef } = virtualRow;

      // Not using `virtualRow.size` for the height to enable dynamic resizing of the list items
      // https://github.com/TanStack/virtual/issues/23
      return (
        <div
          key={index}
          data-index={index}
          ref={measureRef}
          style={{
            width: '100%',
          }}
          className={index === highlight ? styles.highlighted : undefined}
          onMouseMove={() => onMouseEnterItem(index)}
        >
          <>{itemCount > index ? children(index) : loadingComponent}</>
        </div>
      );
    },
    [children, highlight, itemCount, loadingComponent, onMouseEnterItem]
  );

  const gridRenderer = React.useCallback(
    (virtualRow) => (
      <React.Fragment key={virtualRow.index}>
        {columnVirtualizer.virtualItems.map((virtualColumn) => {
          const itemIndex =
            virtualRow.index * columnCount + virtualColumn.index;
          return (
            <div
              key={virtualColumn.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${virtualColumn.size}px`,
                height: `${virtualRow.size}px`,
                transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
              }}
              className={itemIndex === highlight ? styles.highlighted : ''}
              onMouseMove={() => onMouseEnterItem(itemIndex)}
            >
              {children(itemIndex)}
            </div>
          );
        })}
      </React.Fragment>
    ),
    [
      children,
      columnCount,
      columnVirtualizer.virtualItems,
      highlight,
      onMouseEnterItem,
    ]
  );

  /**
   * why debounce?  i have little idea. the size of the lastItem changes often with re-renders, but
   * the last item index does NOT change.  so :shrug:?
   */
  const lastItem = useDebounce(
    React.useMemo(() => {
      const [item] = [...rowVirtualizer.virtualItems].reverse();
      return item;
    }, [rowVirtualizer.virtualItems]),
    10
  );

  React.useEffect(() => {
    if (!fetchNextPage) {
      return;
    }

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index > itemCount - threshold &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    itemCount,
    isFetchingNextPage,
    lastItem,
    threshold,
  ]);

  const scrollIntoView = React.useCallback(
    (newHighlight: number) => {
      const item = rowVirtualizer.virtualItems.find(
        (rowItem) => rowItem.index === newHighlight
      );
      if (
        ref.current &&
        (!item ||
          ref.current.scrollTop > item.start ||
          ref.current.scrollTop + height < item.end)
      ) {
        rowVirtualizer.scrollToIndex(newHighlight);
      }
    },
    [rowVirtualizer, ref, height]
  );

  const moveUp = React.useCallback(
    (existingHighlist: number) => {
      const newHighlight = moveUpHighlight({
        index: existingHighlist,
        itemHighlightable,
      });
      handleSetHighlight(newHighlight);
      scrollIntoView(newHighlight);
    },
    [handleSetHighlight, itemHighlightable, scrollIntoView]
  );

  const moveDown = React.useCallback(
    (existingHighlist: number) => {
      const newHighlight = moveDownHighlight({
        index: existingHighlist,
        itemHighlightable,
        itemCount,
      });
      handleSetHighlight(newHighlight);
      scrollIntoView(newHighlight);
    },
    [itemCount, handleSetHighlight, itemHighlightable, scrollIntoView]
  );

  const enableFocus = React.useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  // A touch non-DRY.  This repeats some of what's in the useAutofocusAtTop hook, but this is
  // a slightly different use/trigger.  It's small enough (2 lines effectively) that I don't
  // think it is worth extracting into a function.
  function focusAtTop() {
    if (!highlight || highlight >= itemCount) {
      const newHighlight = firstHighlightableIndex({
        itemCount,
        itemHighlightable,
      });
      if (newHighlight >= 0) {
        setHighlight(newHighlight);
      }
    }
  }

  useAutofocusAtTop({
    autofocus,
    highlightable,
    highlight,
    itemHighlightable,
    itemCount,
    setHighlight: handleSetHighlight,
    enableFocus,
  });

  useKeyboardNavigation({
    ref,
    index: highlight,
    moveUp,
    moveDown,
    onSelect,
  });

  if (isLoading && itemCount === 0) {
    return <>{loadingComponent}</>;
  }

  if (!hasNextPage && !isLoading && itemCount === 0) {
    if (noItemsComponent) {
      return <>{noItemsComponent}</>;
    }
    return <div className={styles.noItemsFound}>No items found.</div>;
  }

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    <div
      ref={defaultRef}
      style={{
        height: fixedHeight ? height + 20 : '100%',
        width: '100%',
        overflowY: fixedHeight ? 'auto' : 'visible',
      }}
      onMouseOver={hoverFocus ? enableFocus : undefined}
      onFocus={focusAtTop}
      tabIndex={0}
    >
      <div
        style={{
          height: `${rowVirtualizer.totalSize}px`,
        }}
      >
        {rowVirtualizer.virtualItems.length > 0 && (
          <>
            <div
              style={{ height: `${rowVirtualizer.virtualItems[0].start}px` }}
            />
            {rowVirtualizer.virtualItems.map((virtualRow) =>
              columnCount ? gridRenderer(virtualRow) : rowRenderer(virtualRow)
            )}
          </>
        )}
      </div>
    </div>
  );
};

export function InfiniteScrollList({
  isLoadingMore,
  isReachingEnd,
  onEnd,
  errorFetching,
  itemCount,
  children,
}: {
  isLoadingMore: boolean;
  isReachingEnd: boolean;
  itemCount: number;
  onEnd: () => void;
  errorFetching?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      {children}
      {errorFetching && 'Something went wrong. Please try again.'}
      <Flex center>
        {(() => {
          if (isLoadingMore) {
            return <DefaultLoading />;
          }

          if (itemCount === 0) {
            return 'No results found.';
          }

          if (isReachingEnd) {
            return 'No more items to load.';
          }

          return <ViewTrigger onInView={onEnd} />;
        })()}
      </Flex>
    </>
  );
}

function ViewTrigger({ onInView }: { onInView: () => void }) {
  const ref = useRef<HTMLInputElement>(null);

  const isInview = useInView(ref, {
    once: true,
  });

  if (isInview) {
    onInView();
  }

  return (
    <div
      style={{
        marginBottom: '1px',
        height: '1px',
      }}
      ref={ref}
    />
  );
}
