import React, { RefObject } from 'react';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { EmailAlias } from 'models/email-alias';
import { PaginationData } from 'services/common';
import { EmailAliasBulkActionFilters } from 'hooks/common';
import { EmailAliasBanner } from './EmailAliasBanner';
import { WithBulkSelect } from './components/WithBulkSelect';

type PropsType = {
  emailAliases: Array<EmailAlias>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  errorMessage?: string;
  parentRef: RefObject<HTMLDivElement>;
  meta?: PaginationData;
  filterConfig: EmailAliasBulkActionFilters;
  height: number;
};

export const EmailAliasList: React.FC<PropsType> = (props) => {
  const {
    isLoading,
    emailAliases,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    parentRef,
    meta,
    filterConfig,
    height,
  } = props;

  const itemCount = emailAliases.length || 0;

  return (
    <WithBulkSelect
      totalRecords={meta?.totalRecords || 0}
      filterConfig={filterConfig}
    >
      {() => (
        <>
          <InfiniteBannerList
            itemCount={itemCount}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            height={height}
            parentRef={parentRef}
            noItemsComponent={<div>No email aliases match that criteria.</div>}
          >
            {(index: number) => (
              <EmailAliasBanner
                index={index}
                emailAlias={emailAliases[index]}
              />
            )}
          </InfiniteBannerList>
        </>
      )}
    </WithBulkSelect>
  );
};
