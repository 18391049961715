import * as React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';

import { FAIcon } from 'shared/Icon/FAIcon';
import { Month } from '../DateRangeInput/Month';
import styles from './component-layout.module.css';
import { TimeInput } from './TimeInput';

type PropsType = {
  onChange: (date: DateTime) => void;
  value: DateTime;
  hideTime?: boolean;
  ignoreYearFlag?: boolean;
  ignoreYear?: boolean;
  toggleIgnoreYear?: () => void;
  upperLimit?: DateTime;
  lowerLimit?: DateTime;
  frameless?: boolean;
};

export const DateTimeInput: React.FC<PropsType> = ({
  onChange,
  value,
  hideTime,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
  upperLimit,
  lowerLimit,
  frameless,
}) => {
  const [month, setMonth] = React.useState<DateTime>(value);

  const previousMonth = React.useCallback(() => {
    setMonth(month.minus({ months: 1 }));
  }, [month, setMonth]);

  const nextMonth = React.useCallback(() => {
    setMonth(month.plus({ months: 1 }));
  }, [month, setMonth]);

  const dateChange = React.useCallback(
    (date: DateTime) => {
      if (value) {
        onChange(
          value.set({ day: date.day, month: date.month, year: date.year })
        );
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [value, onChange]
  );

  const timeChange = React.useCallback(
    (newTime: DateTime) => {
      if (value && (!lowerLimit || newTime >= lowerLimit)) {
        const { hour, minute, second } = newTime;
        onChange(value.set({ hour, minute, second }));
      }
    },
    [value, onChange, lowerLimit]
  );

  return (
    <div
      className={cx(styles.wrapper, { [styles.frameless]: frameless })}
      data-test="DateTimeInput"
    >
      <div>
        <div className={styles.controls}>
          <button
            className={styles.button}
            onClick={previousMonth}
            type="button"
          >
            <FAIcon name="chevron-left" />
          </button>

          <div className={styles.monthName}>{month.toFormat('MMMM')}</div>

          <button
            className={styles.button}
            onClick={nextMonth}
            type="button"
            data-test="next-month"
          >
            <FAIcon name="chevron-right" />
          </button>
        </div>

        <Month
          className={styles.month}
          onDateClick={dateChange}
          month={month}
          singleDate={value}
          allowFutureSelection
          upperLimit={upperLimit}
          lowerLimit={lowerLimit}
        />

        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}

        {ignoreYearFlag && (
          <div
            className={styles.description}
            style={{
              textAlign: 'center',
              paddingBottom: '0.5em',
            }}
          >
            <label htmlFor="ignoreYear" onClick={toggleIgnoreYear}>
              <input
                type="checkbox"
                id="ignoreYear"
                defaultChecked={ignoreYear}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleIgnoreYear();
                }}
              />
              Ignore Year
            </label>
          </div>
        )}
      </div>

      {!hideTime && (
        <TimeInput
          onTimeChange={timeChange}
          lowerLimit={lowerLimit}
          value={value}
        />
      )}
    </div>
  );
};
