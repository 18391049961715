import { Topic } from './topic';
import { Author, defaultAuthor } from './author';
import { Audience } from './audience';
import { Notification } from './notification';
import { Settings } from './publisher/settings';
import { ProgramAuthor } from './programAuthor';
import { DisplaySettings } from './display-settings';
import { EmailAlias } from './email-alias';

export type ContainedVideo = {
  filename: string;
  sourceType: string;
  status: string;
  transcodeUrl: string;
  isPrivate: boolean;
};

export const defaultContainedVideo: Pick<
  ContainedVideo,
  'sourceType' | 'status' | 'isPrivate'
> = {
  sourceType: 'admin_created',
  status: 'uploading',
  isPrivate: true,
};

export type PublicationState =
  | 'published'
  | 'orphaned'
  | 'draft'
  | 'scheduled'
  | 'archived'
  | 'for_review'
  | 'erased';

export type ProcessingState = 'completed' | 'processing' | 'errored';

export type ContentCommunication = {
  audiences: Audience[];
  audiencesCopy?: Audience[];
  limitVisibilityToAudience: boolean;
  channels: ('feed' | 'email' | 'assistant' | 'push')[];
  criterion: string;
  deliveryType: 'hybrid' | 'override' | 'optimize';
  duration: number;
  notifications: Notification[];
  notifDeliveryTimesEnabled: boolean;
  priority?: string;
  programContactAddressId: number;
  retries: number;
  includeInForYou: boolean;
  communicationId?: number;
};

export type Meta = {
  meta: {
    programId: number;
    polls?: Array<{ id: number; name: string }>;
    videos?: Array<{ id: number }>;
    editable?: boolean;
  };
};

export type Content = {
  publishedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  publicationState: PublicationState;
  processingState: ProcessingState;
  id: number;
  title: string;
  summary?: string;
  type: 'content_planner';
  contentAuthor: Author | ProgramAuthor;
  contentTopics: Pick<Topic, 'id' | 'name' | 'followerCount'>[];
  contentType?: string;
  contentNote?: string;
  permalinkUrl?: string;
  imageUrl?: string;
  videos?: Array<ContainedVideo>;
  attachments?: Array<number>;
  contentCommunication?: ContentCommunication;
  libraryTemplateId?: string;
  version?: number;
  contentChannels?: Topic[];
  contentCommentThread?: {
    commentCount: number;
    commentReportCount: number;
  };
  contentReporters?: {
    contentReportersCount: number;
  };
  displaySettings?: DisplaySettings;
  isHidden?: boolean;
  createdBy?: number;
  readTimeInSeconds?: number;
  wordCount?: number;
  emailSenderAlias?: EmailAlias;
  hasPfFile?: boolean;
} & Meta;

export const defaultContent: Content = {
  id: 0,
  title: 'Title',
  type: 'content_planner',
  publicationState: 'draft',
  processingState: 'completed',
  contentAuthor: defaultAuthor,
  contentTopics: [],
  meta: { programId: 0 },
  displaySettings: {
    displayInternalContent: true,
    messageCardColor: '',
  },
};

export function canViewResults(content: Content): boolean {
  return (
    content.version === 2 &&
    (hasExecution(content) ||
      isScheduled(content) ||
      isPublished(content) ||
      isArchived(content))
  );
}

export function hasAudience(content: Content): boolean {
  return Number(content.contentCommunication?.audiences?.length) > 0;
}

export function hasExecution(content: Content): boolean {
  return !!content.contentCommunication?.communicationId;
}

export function isProcessing(content: Content): boolean {
  return content.processingState === 'processing';
}

export function isScheduled(content: Content): boolean {
  return content.publicationState === 'scheduled';
}

export function isArchived(content: Content): boolean {
  return content.publicationState === 'archived';
}

export function isPublished(content: Content): boolean {
  return content.publicationState === 'published';
}

export function isTopicOnly(content: Content): boolean {
  return content.contentTopics.length > 0 && !hasAudience(content);
}

export function isDraft(content: Content): boolean {
  return content.publicationState === 'draft';
}

export function isActiveContent(
  content: Content
): content is Content & { createdAt: string } {
  return !!(
    content.publicationState === 'published' &&
    !isProcessing(content) &&
    content.createdAt
  );
}

export function isContent(value: Content | unknown): value is Content {
  return (value as Content).type === 'content_planner';
}

export function isPublishProtectedContent(
  status: (Content | Settings)['publicationState'] = ''
): boolean {
  return ['published', 'orphaned', 'scheduled', 'erased'].includes(status);
}

export enum BulkProcessingState {
  INITIAL = 'inital',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export enum BulkActionType {
  PUBLISHING = 'Publishing',
  ARCHIVING = 'Archiving',
}
