import * as React from 'react';
import styles from './upload.module.css';

export const AutoUpload: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <button
    type="button"
    className={styles.uploadSourceMenuItem}
    onClick={onClick}
  >
    Upload
  </button>
);
