import React from 'react';
import { Topic } from 'models/topic';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { FlipSwitch } from 'shared/FlipSwitch';
import { Checkbox } from 'shared/Checkbox';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from './form.module.css';

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>) => void;
};

export const UserSubmissionsSection: React.FC<PropsType> = ({
  topic,
  onChange,
}) => {
  return (
    <Section title="Member submissions">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Options">
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <FlipSwitch
                  size="xl"
                  shape="rounded"
                  on={topic.isUserSubmittable === true}
                  onChange={(value) =>
                    value
                      ? onChange({ isUserSubmittable: true })
                      : onChange({ isUserSubmittable: false })
                  }
                  disabled={topic?.default}
                />
              </div>
              Allow members to submit content
            </div>
            <div className={`kai-flex-row ${styles.autoPublishInput}`}>
              <div className={styles.marginRight}>
                <Checkbox
                  checked={Boolean(topic.autoPublish)}
                  onChange={(value) => onChange({ autoPublish: value })}
                  disabled={topic?.default}
                />
              </div>
              Auto-publish content submitted by members
            </div>
          </Field>
        </div>
      </Fieldset>
    </Section>
  );
};
