import { BookmarkedReport } from 'models/insight/bookmarkedReport';
import { Collection } from 'models/insight/Collection';
import { useDownloadsFeature } from './useDownloadsFeature';

export function useCollectionLinks({
  customCollections,
  bookmarkedCollection,
}: {
  customCollections?: Collection[];
  bookmarkedCollection?: BookmarkedReport[];
}): MenuLink[] {
  // we should conditionally check for overview report existence prior to defining
  const links: MenuLink[] = [{ to: 'overview', label: 'Overview' }];
  // internally Saved reports are called bookmarked.

  if (bookmarkedCollection) {
    links.push({ to: 'saved', label: 'Saved' });
  }

  if (customCollections) {
    customCollections.forEach((collection) => {
      links.push({
        to: `${collection.id}`,
        label: collection.title,
        disablePartial: true,
      });
    });
  }
  if (useDownloadsFeature().enabled)
    links.push({ to: '../downloads', label: 'Downloads' });
  return links;
}

type MenuLink = { to: string; label: string; disablePartial?: true };
