import React from 'react';
import { MenuItem } from 'hooks/useFooter';
import { usePublisher } from 'contexts/publisher';
import { useNavigate } from '@reach/router';
import { templateFromPost } from 'models/template';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { PostPersistanceStatus } from './PostPersistanceStatus';
import { StateLink } from '../StateLink';

type ReturnType = {
  menuItems: { [sectionName: string]: Array<MenuItem> };
};

export const useRightMenuItems = (): ReturnType => {
  const navigate = useNavigate();
  const { role, permissions } = usePermissions();
  const { post, status, isProcessing } = usePublisher();
  const { id: programId } = useProgram();
  const { contentPermissions } = useSettings();
  const { canEdit } = contentPermissions;

  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;

  const saveAsTemplateVisible = permissionsEnabled
    ? permissions.manageTemplateAccess
    : ['program_admin', 'administrator', 'super_admin'].includes(role);

  const saveAsTemplate = React.useCallback(() => {
    navigate(`/${programId}/edit/template/new`, {
      state: { initialTemplate: templateFromPost(post) },
    });
  }, [post, programId, navigate]);

  const menuItems = React.useMemo(() => {
    return {
      section1: [
        {
          name: 'Save as template',
          onClick: saveAsTemplate,
          visible: saveAsTemplateVisible,
          isWorking: status.isSaving,
          disabled: status.isSaving || isProcessing,
        },
        {
          element: (
            <StateLink
              action="draft"
              message="Draft saved."
              label="Save as draft"
              disabled={!canEdit}
              shouldUpsert={post.content.id === 0}
            />
          ),
          isWorking: status.isSaving,
          disabled: status.isSaving,
        },
      ],
      section2: [
        {
          element: <PostPersistanceStatus />,
        },
      ],
    };
  }, [
    canEdit,
    isProcessing,
    post.content.id,
    saveAsTemplate,
    saveAsTemplateVisible,
    status.isSaving,
  ]);

  return { menuItems };
};
