export const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
];

export const statusOptionsWithDrafts = [
  { value: 'active_without_draft', label: 'Active' },
  { value: 'active_with_draft', label: 'Active With Draft' },
  { value: 'archived_without_draft', label: 'Archived' },
  { value: 'archived_draft', label: 'Archived Draft' },
  { value: 'archived_with_draft', label: 'Archived With Draft' },
  { value: 'active_draft', label: 'Draft' },
];

export const visibilityOptions = [
  { value: 'visible', label: 'Shown in Experience' },
  { value: 'hidden', label: 'Hidden in Experience' },
];

export const autoFollowOptions = [
  { value: 'true', label: 'Allowed' },
  { value: 'false', label: 'Disallowed' },
];

export const autoPublishOptions = [
  { value: 'true', label: 'Allowed' },
  { value: 'false', label: 'Disallowed' },
];

export const userSubmittableOptions = [
  { value: 'true', label: 'Allowed' },
  { value: 'false', label: 'Disallowed' },
];
