import * as React from 'react';
import styles from './thumbnail.module.css';

export const Container: React.FC<{
  children?: React.ReactNode;
  backgroundColor?: string;
  color?: string;
  imageUrl?: string;
  size?: 'large' | 'medium' | 'small';
}> = ({ children, imageUrl, color, backgroundColor, size = 'large' }) => {
  const style: React.CSSProperties = { color, backgroundColor };
  if (imageUrl) {
    style.backgroundImage = `url(${imageUrl})`;
    style.backgroundSize = 'cover';
    style.backgroundPosition = '50% 50%';
  }

  return (
    <div style={style} className={styles[size]}>
      {children}
    </div>
  );
};
