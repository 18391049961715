import {
  isActiveDeliveryThreshold,
  useProgramDeliveryThreshold,
} from 'hooks/campaign-settings';
import { useEffect, useState } from 'react';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { cleanNumericValue, localiseNumber } from '../helpers';

export const useConfirmationModal = (
  onSaveSuccess?: () => void,
  onSaveError?: () => void
): {
  enabled: boolean;
  modalEnabled: boolean;
  setModalEnabled: (val: boolean) => void;
  threshold: string;
  initialThreshold: string;
  setThreshold: (val: string) => void;
  onSave: () => void;
  isValid: boolean;
} => {
  const { id: programId } = useProgram();
  const {
    deliveryThreshold,
    deliveryThresholdEnabled,
    updateDeliveryThreshold,
    defaultNewDeliveryThreshold,
  } = useProgramDeliveryThreshold(onSaveSuccess, onSaveError);

  const deliveryThresholdEnabledFlag = !!useFeatureFlagsQuery(
    programId,
    'Studio.DeliveryThresholdEnabled'
  ).data?.value;

  const [modalEnabled, setModalEnabled] = useState(deliveryThresholdEnabled);
  const initialThreshold = localiseNumber(
    isActiveDeliveryThreshold(deliveryThreshold)
      ? deliveryThreshold
      : defaultNewDeliveryThreshold
  );
  const [threshold, setThreshold] = useState(initialThreshold);

  useEffect(() => {
    setModalEnabled(deliveryThresholdEnabled);
  }, [deliveryThresholdEnabled]);

  const onSave = () => {
    updateDeliveryThreshold(cleanNumericValue(threshold), modalEnabled);
  };

  const strippedThresholdVal = threshold.replace(/,/g, '');

  const isValid =
    (Number(strippedThresholdVal) > 0 && strippedThresholdVal.length <= 8) ||
    !modalEnabled;

  return {
    enabled: deliveryThresholdEnabledFlag,
    modalEnabled,
    setModalEnabled,
    threshold: modalEnabled ? threshold : '-1',
    initialThreshold,
    setThreshold,
    onSave,
    isValid,
  };
};
