import * as React from 'react';
import cx from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import { useUniqueId } from 'hooks/useUniqueId';
import { ImageData } from 'models/image';
import { DraggableImage } from './DraggableImage';
import styles from './collection.module.css';

type PropsType = {
  startIndex: number;
  images: Array<ImageData>;
  onEdit: (index: number) => void;
  onRemove: (index: number) => void;
  onProcessed: (data: ImageData) => void;
};

export const Row: React.FC<PropsType> = ({
  startIndex,
  images,
  onEdit,
  onRemove,
  onProcessed,
}) => {
  const uniqueId = useUniqueId();

  return (
    <Droppable droppableId={uniqueId} direction="horizontal">
      {(dropProvided) => (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          className={cx(styles.row, styles[`-of-size-${images.length}`])}
        >
          {images.map((image, index) => (
            <DraggableImage
              key={image.imageId}
              image={image}
              index={startIndex + index}
              onRemove={() => onRemove(startIndex + index)}
              onClick={() => onEdit(startIndex + index)}
              onProcessed={onProcessed}
            />
          ))}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
