import React from 'react';
import { Expression } from 'models/expression';
import { editableTextToQueryType } from 'models/audience';
import { ComplexBuilder } from './ComplexBuilder'; // eslint-disable-line import/no-cycle
import { LiteralBuilder } from './LiteralBuilder';
import { SimpleBuilder } from './SimpleBuilder';

// TODO: Rename `isAlone` to something more descriptive. `showDelete`
export const ExpressionBuilder: React.FC<{
  depth: number;
  onChange: (value: Expression) => void;
  onDelete: () => void;
  isAlone: boolean;
  value: Expression;
  canEdit?: boolean;
  editableTextToQuery: editableTextToQueryType;
  audienceName?: string;
  updateSummaryQueryStatus?: (status: boolean) => void;
}> = ({
  depth,
  isAlone,
  onChange,
  onDelete,
  value,
  canEdit = true,
  editableTextToQuery,
  audienceName,
  updateSummaryQueryStatus,
}) => {
  switch (value.type) {
    case 'simple':
      return (
        <SimpleBuilder
          isAlone={isAlone}
          canEdit={canEdit}
          value={value}
          onChange={onChange}
          onDelete={onDelete}
          editableTextToQuery={editableTextToQuery}
          audienceName={audienceName}
        />
      );

    case 'complex':
      return (
        <ComplexBuilder
          depth={depth}
          isAlone={isAlone}
          canEdit={canEdit}
          value={value}
          onChange={onChange}
          onDelete={onDelete}
          editableTextToQuery={editableTextToQuery}
          audienceName={audienceName}
          updateSummaryQueryStatus={updateSummaryQueryStatus}
        />
      );

    case 'literal':
      return <LiteralBuilder />;

    default:
      throw new Error('unexpected expression type');
  }
};
