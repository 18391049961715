import React from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useResultsSummaryQuery } from 'hooks/content-insights';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Button } from 'DesignSystem/Form';
import { ConfirmModal } from 'shared/BulkSelect/Bar/ConfirmModal';
import { Duplicate, Edit, Trash, View } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { CampaignStatus } from './CampaignStatus';
import { EngagementSection } from './EngagementSection';
import { DeliverySection } from './DeliverySection';
import { useActions } from './useActions';
import styles from './results-page.module.css';
import { Tooltip } from '../../../../../DesignSystem/Components';

type LocationState = {
  from: string;
};

export const ContentResultsPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { post, status } = usePublisher();
  const {
    contentPermissions: { canEdit, isLoading: isLoadingPermissions },
  } = useSettings();
  const { state } = useLocation();
  const locationState = state as LocationState;

  const {
    canArchive,
    isDuplicating,
    showingArchiveModal,
    showArchiveModal,
    closeArchiveModal,
    handleDuplicate,
    handleConfirmArchive,
  } = useActions(programId, post);

  // TODO: Should be called conditionally based on hasExecution
  const {
    data: summary,
    isLoading: isLoadingSummary,
  } = useResultsSummaryQuery({ contentId: post.content.id });

  const showDeliverySection = !!summary?.executionState;
  const contentCanOnlyPreview = !canEdit;
  const action = React.useMemo(
    () => (
      <div data-pendo="results-page-actions" className={styles.controlsWrapper}>
        {isLoadingPermissions ? (
          <LoadingSpinner size="small" />
        ) : (
          <Button
            dataPendo="edit-campaign"
            href={`/${programId}/edit/publisher/${post.content.id}`}
            icon={contentCanOnlyPreview ? <View /> : <Edit />}
            label={`${contentCanOnlyPreview ? 'Open' : 'Edit'} Campaign`}
          />
        )}
        {!contentCanOnlyPreview && (
          <Tooltip content="Duplicate your campaign.">
            <Button
              dataPendo="duplicate-campaign"
              text
              onClick={handleDuplicate}
              label={
                isDuplicating ? <LoadingSpinner size="small" /> : <Duplicate />
              }
            />
          </Tooltip>
        )}
        {canArchive && !contentCanOnlyPreview && (
          <Tooltip content="Archive your campaign.">
            <Button
              dataPendo="archive-campaign"
              text
              onClick={showArchiveModal}
              label={
                status.isSaving ? <LoadingSpinner size="small" /> : <Trash />
              }
            />
          </Tooltip>
        )}
      </div>
    ),
    [
      contentCanOnlyPreview,
      isLoadingPermissions,
      programId,
      post.content.id,
      handleDuplicate,
      isDuplicating,
      canArchive,
      showArchiveModal,
      status.isSaving,
    ]
  );

  const Page = React.useMemo<React.FC<{ title: string; description?: string }>>(
    () => ({ children, title, description }) => (
      <ListPage
        noNavigation
        breadcrumbs={[
          {
            label: 'Campaigns',
            to: locationState?.from ?? '../../..',
          },
          { label: title },
        ]}
        title={title}
        tabs={undefined}
        description={description}
        actionsOverride={action}
      >
        {children}
      </ListPage>
    ),
    [action, locationState?.from]
  );

  const title = React.useMemo(() => {
    if (post.callToAction.title) {
      if (post.callToAction.title.length >= 55) {
        return `${post.callToAction.title.substring(0, 55)}...`;
      }
      return post.callToAction.title;
    }
    return 'Untitled';
  }, [post]);

  return (
    <Page title={title}>
      {showingArchiveModal && (
        <ConfirmModal
          headerTitle="Archive Campaign"
          customTitle="Are you sure you want to archive this campaign?"
          hideLabel
          onCancel={closeArchiveModal}
          onConfirm={handleConfirmArchive}
        />
      )}
      <div className={styles.pageWrapper}>
        <CampaignStatus
          post={post}
          isLoadingSummary={isLoadingSummary}
          summary={summary}
        />
        <EngagementSection post={post} />
        {showDeliverySection && (
          <DeliverySection
            content={post.content}
            isLoadingSummary={isLoadingSummary}
            summary={summary}
          />
        )}
      </div>
    </Page>
  );
};
