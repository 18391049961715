import * as React from 'react';
import cx from 'classnames';
import { useUniqueId } from 'hooks/useUniqueId';
import { PreviewablePost } from 'hooks/content-blocks';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Raw } from './Raw';
import styles from './post-previews.module.css';

export const Fancy: React.FC<{
  post: PreviewablePost;
  width: number;
  height: number;
  title?: string;
  zIndex?: number | number;
}> = ({ post, height, width, title, zIndex = 1 }) => {
  const id = useUniqueId();
  const raise = React.useCallback(() => {
    const el = document.getElementById(id);
    if (el) el.style.zIndex = `calc(${zIndex} + 1)`;
  }, [id, zIndex]);
  const lower = React.useCallback(() => {
    const el = document.getElementById(id);
    if (el) el.style.zIndex = `${zIndex}`;
  }, [id, zIndex]);
  return (
    <div
      id={id}
      className={styles.preview}
      style={{
        height: `${height}px`,
        zIndex,
      }}
      onFocus={raise}
      onMouseMove={raise}
      onMouseOut={lower}
      onBlur={lower}
    >
      {title && (
        <strong title={title} className={cx(styles.title, 'ellipsis-overflow')}>
          {title}
        </strong>
      )}
      <div className={styles.thumbnail}>
        <div className={styles.effects}>
          <div
            className={styles.shadow}
            style={{ width: `${width * 0.66}px` }}
          />
          <div
            className={styles.loading}
            style={{ width: `${width * 0.66}px` }}
          >
            <LoadingSpinner />
          </div>
        </div>
        <div className={styles.raw}>
          <Raw width={width * 0.66} height={height} post={post} />
        </div>
      </div>
    </div>
  );
};
