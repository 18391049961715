import React from 'react';
import { safeCopyMap } from 'utility/ie11Utils';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';
import { FiltersStateContext } from 'App/Program/Main/Insight/contexts/FiltersStateContext';
import { FilterValue } from 'services/api-insights';
import { FilterDropdownStructureContext } from 'App/Program/Main/Insight/components/Filters/shared/DropdownStructureContext';
import { usePrevious } from 'hooks/usePrevious';
import { isAttributeFilter } from 'models/insight/Filter';

/*
 * This context is meant to serve list dropdowns
 * - tracks list of all selected items
 * - able to sort items by selected after dropdown open event
 * - able to filter by search string
 * - able to pre-select default values (even if defaults are provided before `allItems`)
 *
 * the provider must be invoked with a list of `allItems` (can be empty initially)
 * `allItems` must be constructed outside of this provider with all relevant properties defined
 *
 * if using with paginated data, the accumulated list of `allItems` must be provided
 *    - see PaginatedListDropdownContext for implementation
 *
 * `newlySelectedItems` is a map used to track all selected items in the dropdown
 * `oldSelectedItems` tracks all of the items from the last time the dropdown was opened
 * when the dropdown reopens, we overwrite newlySelectedItems -> oldSelectedItems
 *
 * Dev note:
 * This context operates with a series of useEffects() that listen on the `newlySelectedItems` state
 * for debugging, ensure that all intended effects are fired as expected
 * */

export type ListDropdownItem = {
  label: string;
  value: string;
  description?: string;

  // default values that are not found in `allItems` are rendered in the dropdown as placeholders
  // such items are flagged with this boolean for ease of identification
  isPlaceholderItem?: boolean;

  // the stringified identifier for an item within the `newlySelectedItems` map.
  // must be defined and provided along with `allItems`
  uuid: string;
};

export type ListDropdownContextType = {
  items: ListDropdownItem[];
  dispatchNewlySelectedItem: (action: SelectedItemsReducerAction) => void;
  itemIsSelected: (item: ListDropdownItem) => boolean;
  itemsAreSelected: boolean;
  getItemHeight: (apparentIndex: number) => number;
  resetPlaceholderItems: () => void;
};
export const ListDropdownContext = React.createContext<ListDropdownContextType>(
  {
    items: [],
    dispatchNewlySelectedItem: () => {},
    itemIsSelected: () => false,
    itemsAreSelected: false,
    getItemHeight: () => 0,
    resetPlaceholderItems: () => {},
  }
);

// Item selection reducer
type SelectedItemsMap = Map<string, ListDropdownItem>;
type SelectedItemsReducerAction =
  | { action: 'clearAll' }
  | {
      action: 'toggle';
      item: ListDropdownItem;
    }
  | { action: 'setAll'; allItems: SelectedItemsMap }
  | { action: 'appendNewItemsToExistingList'; newItems: SelectedItemsMap };

type InfiniteDropdownProviderPropsType = {
  allItems: ListDropdownItem[];
  defaultValues?: Set<string>;
};

export const ListDropdownProvider: React.FC<InfiniteDropdownProviderPropsType> = ({
  children,
  allItems,
  defaultValues,
}) => {
  const {
    getInfiniteListItemVariableHeight,
    setInfiniteListActualBodyHeight,
  } = React.useContext(FilterDropdownStructureContext);
  const {
    setPillButtonDescriptionWithDefault,
    filter,
    didOpen,
    searchString,
    setSearchString,
  } = React.useContext(FilterDropdownContext);
  const { filtersStateAction } = React.useContext(FiltersStateContext);
  const didSetDefaultValues = React.useRef(false);
  const [
    shouldUpdatePlaceholderLabels,
    setShouldUpdatePlaceholderLabels,
  ] = React.useState(true);
  const prevDefaultValues = usePrevious(defaultValues);

  // map of the PREVIOUSLY selected items. Used for sorting list of items
  const [oldSelectedItems, setOldSelectedItems] = React.useState<
    SelectedItemsMap
  >(new Map());

  // list of newly selected items. overwrites `oldSelectedItems` when dropdown opens
  // NOTE! both old/newSelectedItems map are keyed by `item.uuid`
  // reducer function needs to be a memoized to prevent duplicated invocations
  const newlySelectedItemsReducer = React.useCallback(
    (state: SelectedItemsMap, action: SelectedItemsReducerAction) => {
      switch (action.action) {
        case 'toggle': {
          const { item } = action;
          const newMap = filter.allowMultiple ? safeCopyMap(state) : new Map();

          if (newMap.has(item.uuid)) {
            newMap.delete(item.uuid);
          } else {
            newMap.set(item.uuid, item);
          }
          return newMap;
        }
        case 'setAll': {
          return safeCopyMap(action.allItems);
        }
        case 'appendNewItemsToExistingList': {
          const newMap = filter.allowMultiple ? safeCopyMap(state) : new Map();
          safeCopyMap(action.newItems).forEach((value, key) => {
            newMap.set(key, value);
          });
          return newMap;
        }
        case 'clearAll':
          return new Map();
        default: {
          return safeCopyMap(state);
        }
      }
    },
    [filter.allowMultiple]
  );
  const [newlySelectedItems, dispatchNewlySelectedItem] = React.useReducer(
    newlySelectedItemsReducer,
    new Map()
  );

  // helper function that determines if an item was selected
  const itemIsSelected = (item: ListDropdownItem) => {
    return newlySelectedItems.has(item.uuid);
  };

  // when we are about to show the dropdown...
  React.useEffect(() => {
    if (didOpen) {
      // reset the oldSelectedItems
      setOldSelectedItems(newlySelectedItems);

      // clear out any search terms
      setSearchString('');
    }
    // we intentionally exclude `setOldSelectedItems` from the dep array
    // eslint-disable-next-line
  }, [didOpen]);

  // update the pill button label text when new items are de/selected
  // prefer displaying items with real labels before placeholder items
  React.useEffect(() => {
    let newlySelectedItemsText = '';
    const selectedItemsLabels: string[] = [];
    const placeholderItemsLabels: string[] = [];
    newlySelectedItems.forEach((item) => {
      if (item.isPlaceholderItem) {
        placeholderItemsLabels.push(item.label);
      } else {
        selectedItemsLabels.push(item.label);
      }
    });
    const totalItemsCount =
      selectedItemsLabels.length + placeholderItemsLabels.length;
    if (totalItemsCount > 0) {
      newlySelectedItemsText =
        selectedItemsLabels[0] || placeholderItemsLabels[0];
      if (totalItemsCount > 1) {
        newlySelectedItemsText += `, +${totalItemsCount - 1}`;
      }
    }
    setPillButtonDescriptionWithDefault(newlySelectedItemsText);
  }, [filter, newlySelectedItems, setPillButtonDescriptionWithDefault]);

  // update the global filter state as items are selected
  // we use `setAllFilterValues` action to directly set all values in the filter state object
  React.useEffect(() => {
    // the initialization of the reducer will trigger this effect
    // if a default exists, then this will cause a subtle bug where the state will temporarily
    // "flicker" between the default having been set and then unset and reset again
    // checking for the `didSetDefaultValues` ref will prevent that flicker,
    // and if a filter has a default, then that will apply, otherwise it will have no value
    if (!didSetDefaultValues.current) return;
    const selectedValues = new Set<FilterValue>();
    newlySelectedItems.forEach((item) =>
      selectedValues.add(item.value as FilterValue)
    );

    filtersStateAction({
      action: 'setAllFilterValues',
      filter,
      valuesSet: selectedValues,
    });
  }, [newlySelectedItems, filter, filtersStateAction]);

  // this is used to toggle the didSetDefaultValues ref so that defaults can be reset
  // NOTE! this only checks against the size of the defaultValues set, not its contents
  // the thinking is that default values should either be.. provided at the onset
  // or they are initially empty, but since the filter is required, then a subsequent
  // data load returns prepolutated data, of which the first is selected
  // so size will be the only thing that changes.
  // ie, we don't expect existing default values to change to a different value
  // if this somehow changes in the future, then a deep comparison of sets will be necessary
  React.useEffect(() => {
    if (defaultValues?.size !== prevDefaultValues?.size) {
      didSetDefaultValues.current = false;
    }
  }, [defaultValues, prevDefaultValues]);

  // create placeholder default items. later will be replaced as `allItems` updates
  // we treat all default values as being "pre-selected" and add them to the items map
  React.useEffect(() => {
    if (defaultValues === undefined || didSetDefaultValues.current) return;

    // only set the default values into the selected items map once
    didSetDefaultValues.current = true;
    const placeholderDefaultItems: SelectedItemsMap = new Map();

    defaultValues.forEach((value) =>
      placeholderDefaultItems.set(value, {
        uuid: value,
        value,
        label: value,
        isPlaceholderItem: true,
      })
    );

    dispatchNewlySelectedItem({
      action: 'setAll',
      allItems: placeholderDefaultItems,
    });
  }, [filter, defaultValues, dispatchNewlySelectedItem]);

  // match up allItems with any placeholder default items
  React.useEffect(() => {
    if (
      allItems.length === 0 ||
      newlySelectedItems.size === 0 ||
      !shouldUpdatePlaceholderLabels
    ) {
      return;
    }
    setShouldUpdatePlaceholderLabels(false);

    // copy items map and replace any placeholder items with "real" items
    const updatedSelectedItems = safeCopyMap(newlySelectedItems);
    allItems.forEach((item) => {
      // IMPORTANT! deeplink query params are set to item VALUES, not uuid
      // as such, any placeholder items will be keyed by a "real" item's value (see next line)
      const matchedItem = updatedSelectedItems.get(item.value);
      if (matchedItem && matchedItem.isPlaceholderItem) {
        // replace the placeholder item with the real item
        updatedSelectedItems.delete(matchedItem.uuid);
        // and set the real item with the items uuid
        updatedSelectedItems.set(item.uuid, item);
      }
    });

    dispatchNewlySelectedItem({
      action: 'setAll',
      allItems: updatedSelectedItems,
    });
  }, [
    allItems,
    newlySelectedItems,
    dispatchNewlySelectedItem,
    shouldUpdatePlaceholderLabels,
  ]);

  /**
   * Everything below here is related to sorting and filtering the dropdown items,
   * Each step is broken up into a separate memoized function
   * */

  // return list of items matching the search string, if any
  const allOrFilteredItems = React.useMemo<ListDropdownItem[]>(() => {
    // we skip this part in case searchString is empty or if the filter is an AttributeFilter, because it is paginated and
    // doesn't requires search filtering, since the results for paginated request are already filtered w.r.t searchString.
    if (!searchString || isAttributeFilter(filter)) return allItems;
    return allItems.filter(
      (item) =>
        item.label.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    );
  }, [allItems, searchString, filter]);

  // sort the searchedItems based on the `oldSelectedItems` map
  const finalSortedItems = React.useMemo<ListDropdownItem[]>(() => {
    if (oldSelectedItems.size === 0) return allOrFilteredItems;
    const selectedItems: ListDropdownItem[] = [];
    const selectedPlaceholderItems: ListDropdownItem[] = [];
    const unSelectedItems: ListDropdownItem[] = [];

    oldSelectedItems.forEach((item) => {
      if (item.isPlaceholderItem) {
        selectedPlaceholderItems.push(item);
      } else {
        selectedItems.push(item);
      }
    });
    allOrFilteredItems.forEach((item) => {
      if (!oldSelectedItems.has(item.uuid)) unSelectedItems.push(item);
    });

    // sort items by: real selected, placeholder selected, unselected
    return selectedItems
      .concat(selectedPlaceholderItems)
      .concat(unSelectedItems);
  }, [oldSelectedItems, allOrFilteredItems]);

  // helper function that returns the size of a list item based on its text content
  const getItemHeight = (apparentIndex: number): number => {
    const item = finalSortedItems[apparentIndex];
    return item === undefined
      ? 0
      : getInfiniteListItemVariableHeight(item.label.toString());
  };

  // set the total height of the list dropdown on the structure context
  // this will help in determining the final height of the dropdown
  React.useLayoutEffect(() => {
    let totalHeight = 0;
    finalSortedItems.forEach((item) => {
      totalHeight += getInfiniteListItemVariableHeight(item.label.toString());
    });
    setInfiniteListActualBodyHeight(totalHeight);
  }, [
    finalSortedItems,
    setInfiniteListActualBodyHeight,
    getInfiniteListItemVariableHeight,
  ]);

  const resetPlaceholderItems = React.useCallback(
    () => setShouldUpdatePlaceholderLabels(true),
    [setShouldUpdatePlaceholderLabels]
  );

  return (
    <ListDropdownContext.Provider
      value={{
        items: finalSortedItems,
        dispatchNewlySelectedItem,
        itemIsSelected,
        itemsAreSelected: newlySelectedItems.size > 0,
        getItemHeight,
        resetPlaceholderItems,
      }}
    >
      {children}
    </ListDropdownContext.Provider>
  );
};
