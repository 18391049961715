import React from 'react';
import { RadioGroup, RadioItem } from '@socialchorus/shared-ui-components';
import { includes } from 'utility/objectUtils';
import { Text } from 'DesignSystem/Typography';
import { ConfigurationSection } from '../layout';

const OPTIMIZE = 'optimize' as const;
const ALL = 'all' as const;
const channelDeliveryValues = [OPTIMIZE, ALL] as const;
export type ChannelDelivery = typeof channelDeliveryValues[number];

type ChannelDeliveryProps = {
  value: ChannelDelivery | undefined;
  onSelectionChange: (value: ChannelDelivery) => void;
  configurable: boolean;
};
const channelDeliveryLabel =
  'If more than one channel is selected, choose how to deliver the Communication';

export function ChannelDelivery({
  value = OPTIMIZE,
  onSelectionChange,
  configurable,
}: ChannelDeliveryProps): JSX.Element {
  return (
    <ConfigurationSection title="Channel Delivery">
      {configurable ? (
        <RadioGroup
          label={channelDeliveryLabel}
          defaultValue={OPTIMIZE}
          value={value}
          onValueChange={(channelOption) => {
            if (!includes(channelDeliveryValues, channelOption)) {
              throw new Error(
                `Invalid channel delivery value: ${channelOption}`
              );
            }
            onSelectionChange(channelOption);
          }}
        >
          <RadioItem
            label="Optimize which enabled channels to leverage"
            value={OPTIMIZE}
          />
          <RadioItem label="Send to ALL selected channels" value={ALL} />
        </RadioGroup>
      ) : (
        <Text
          className={{
            Body: true,
          }}
          as="p"
        >
          {channelDeliveryLabel}
        </Text>
      )}
    </ConfigurationSection>
  );
}
