import React, { useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { OptionType } from 'hooks/common';
import { selectComponents, selectStyles, selectTheme } from '../Select';

const options = [
  { value: 'on', label: 'on' },
  { value: 'between', label: 'between' },
  { value: 'since', label: 'since' },
  { value: 'last', label: 'last' },
  { value: 'next', label: 'next' },
  { value: 'is', label: 'relative' }, // functions the same as the "is" operator in string select
];

const newDateOperators = [
  { value: 'on', label: 'On' },
  { value: 'between', label: 'Between' },
  { value: 'before', label: 'Before' },
  { value: 'since', label: 'After' },
  { value: 'last', label: 'Last' },
  { value: 'next', label: 'Next' },
  { value: 'is', label: 'Relative' }, // functions the same as the "is" operator in string select
];

const journeyOptions = [
  { value: 'on', label: 'On' },
  { value: 'between', label: 'Between' },
  { value: 'before', label: 'Before' },
  { value: 'since', label: 'After' },
  { value: 'last', label: 'In Past' },
  { value: 'in_past_before', label: 'In Past Before' },
  { value: 'next', label: 'In Future' },
  { value: 'in_future_after', label: 'In Future After' },
  { value: 'is', label: 'In Time Frame' }, // functions the same as the "is" operator in string select
];

export const DateOperatorSelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
  newDateOperatorsEnabled: boolean;
  journeysEnabled: boolean;
  isDisabled?: boolean;
}> = ({
  onChange,
  value,
  isDisabled,
  newDateOperatorsEnabled,
  journeysEnabled,
}) => {
  let configuredOptions: { value: string; label: string }[];
  if (newDateOperatorsEnabled && journeysEnabled) {
    configuredOptions = journeyOptions;
  } else if (newDateOperatorsEnabled) {
    configuredOptions = newDateOperators;
  } else {
    configuredOptions = options;
  }

  const optionValue =
    configuredOptions.find((option) => option.value === value) ||
    configuredOptions[0];
  const onOptionChange = (option: ValueType<OptionType>) => {
    const newOp = option as OptionType;
    onChange(newOp.value);
  };

  useEffect(() => {
    if (!value) {
      onChange(configuredOptions[0].value);
    }
  }, [value, onChange, configuredOptions]);

  return (
    <Select
      value={optionValue}
      options={configuredOptions}
      onChange={onOptionChange}
      components={selectComponents}
      styles={selectStyles}
      theme={selectTheme}
      menuPlacement="auto"
      isDisabled={isDisabled}
    />
  );
};
