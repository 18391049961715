import * as React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { AbsoluteRange } from './DateRange';
import { ManualDateInput } from './ManualDateInput';
import { DateRangeInputActionsType, DateRangeInputStateType } from './types';
import { FAIcon } from '../Icon/FAIcon';
import styles from './absolute-control.module.css';

type PropsType = {
  actions: DateRangeInputActionsType;
  dateRange: AbsoluteRange;
  selectedDate: DateRangeInputStateType['selectedDate'];
};

export const AbsoluteControl: React.FC<PropsType> = (props) => {
  const { actions, dateRange } = props;

  const [showingStartInput, setShowingStartInput] = React.useState(false);
  const [showingEndInput, setShowingEndInput] = React.useState(false);

  const hideManualStartDateInput = React.useCallback(
    () => setShowingStartInput(false),
    []
  );
  const hideManualEndDateInput = React.useCallback(
    () => setShowingEndInput(false),
    []
  );

  const displayStartDateInput = () => {
    actions.setSelectedDate('start');
    if (!showingStartInput) setShowingStartInput(true);
  };

  const displayEndDateInput = () => {
    actions.setSelectedDate('end');
    if (!showingEndInput) setShowingEndInput(true);
  };

  const manualChange = (e: React.ChangeEvent) => {
    actions.setCurrentRangeDate(
      DateTime.fromISO((e.target as HTMLInputElement).value)
    );
  };

  return (
    <div className={styles.control}>
      <div className={styles.selected}>
        <div
          className={cx({ [styles.manual]: showingStartInput })}
          onClick={displayStartDateInput}
          onKeyPress={displayStartDateInput}
          role="button"
          tabIndex={0}
        >
          <span>{dateRange.start.toFormat('d')}</span>
          {showingStartInput ? (
            <ManualDateInput
              value={dateRange.start}
              onChange={manualChange}
              onBlur={hideManualStartDateInput}
            />
          ) : (
            <button className={styles.label} type="button">
              {dateRange.start.toFormat('EEE, MMM dd yyyy')}
            </button>
          )}
        </div>
        {!showingStartInput && (
          <div className={styles.caretDirections}>
            <button onClick={actions.shiftStartDateLeft} type="button">
              <FAIcon name="caret-up" className={styles.arrow} />
            </button>
            <button onClick={actions.shiftStartDateRight} type="button">
              <FAIcon name="caret-down" className={styles.arrow} />
            </button>
          </div>
        )}
      </div>
      <div className={styles.selected}>
        <div
          className={cx({ [styles.manual]: showingEndInput })}
          onClick={displayEndDateInput}
          onKeyPress={displayEndDateInput}
          role="button"
          tabIndex={0}
        >
          <div className={styles.ring}>
            <span>{dateRange.end.toFormat('d')}</span>
          </div>
          {showingEndInput ? (
            <ManualDateInput
              value={dateRange.end}
              onChange={manualChange}
              onBlur={hideManualEndDateInput}
            />
          ) : (
            <button className={styles.label} type="button">
              {dateRange.end.toFormat('EEE, MMM dd yyyy')}
            </button>
          )}
        </div>
        {!showingEndInput && (
          <div className={styles.caretDirections}>
            <button onClick={actions.shiftEndDateLeft} type="button">
              <FAIcon name="caret-up" className={styles.arrow} />
            </button>
            <button onClick={actions.shiftEndDateRight} type="button">
              <FAIcon name="caret-down" className={styles.arrow} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
