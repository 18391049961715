import { IDashboardJson, transformJsonToTableauDashboard } from './Dashboard';

export interface IWorkbookJson {
  project: {
    id: string;
    name: string;
  };
  location: {
    id: string;
    type: string;
    name: string;
  };
  owner: {
    id: string;
    name: string;
  };
  tags: Tableau.TableauTag;
  dataAccelerationConfig: {
    accelerationEnabled: boolean;
  };
  id: string;
  name: string;
  description: string;
  contentUrl: string;
  webpageUrl: string;
  showTabs: string;
  size: string;
  createdAt: string;
  updatedAt: string;
  encryptExtracts: string;
  defaultViewId: string;
  views?: {
    view: IDashboardJson[];
  };
}

export type IWorkbookCategoryJson = {
  id: string;
  createdAt: string;
  updatedAt: string;

  name: string;
  description: string;
  tag: string;
};

export const transformJsonToTableauWorkbook = (
  json: IWorkbookJson
): Tableau.Workbook => ({
  id: json.id,
  name: json.name,
  description: json.description,
  tags: json.tags,
  previewImage: undefined,
  defaultViewId: json.defaultViewId,
  views: json?.views?.view
    ? { view: json.views.view.map(transformJsonToTableauDashboard) }
    : { view: [] },
});

export const transformJsonToWorkbookCategory = (
  json: IWorkbookCategoryJson
): Tableau.WorkbookCategory => ({
  id: Number.parseInt(json.id, 10),
  createdAt: json.createdAt,
  updatedAt: json.updatedAt,

  name: json.name,
  description: json.description,
  tag: json.tag,
});
