import React from 'react';
import { QueryResponse } from 'hooks/common';
import { useQuery } from 'react-query';
import { Author } from 'models/author';
import {
  AuthorsFetchParams,
  fetchAndTransformPaginatedAuthorAliases,
  fetchAndTransformPaginatedPublishers,
} from 'services/api-authors';
import {
  AttributeFilterFetchProps,
  CampaignFilterFetchProps,
  ContentFilterFetchProps,
  fetchAndTransformAttributeFilters,
  fetchAndTransformCampaignFilters,
  fetchAndTransformContentFilters,
  fetchAndTransformPollFilters,
  PollFilterFetchProps,
} from 'services/api-insights';
import { Option } from 'models/insight/json/filterJson';
import { usePrevious } from 'hooks/usePrevious';
import { shallowObjectCompare } from 'utility/shallowObjectCompare';

/**
 * This is a simple hook for making requests to paginated APIs
 * Particularly, this is for API's that accept a `page` param but do not return any pagination metadata
 * Such APIs make `useInfiniteQuery()` hook unusable
 * Instead, what we do here is simple track the page param internally via a `page` state variable
 * and increment it each time new data is requested
 * requests are made via the `useQuery()` hook and can repeatedly be made
 * until no data is returned. after which the `fetchNext()` will do nothing
 *
 * Restrictions:
 * We can only fetch for data in one direction, from page 1
 * ie, we cannot "fetchPrevious()". Don't use this if that's a requirement
 *
 * Note:
 * if the endpoint you are using does return pagination metadata, then by all means
 * go ahead and utilize `useInfiniteQuery()` or our internal `useInfiniteApiQuery()` hooks
 * */

// we extend the `useQuery()` hook response with a few additional attributes
type SemiPaginatedQueryResponse<TModel> = QueryResponse<TModel[]> & {
  fetchNext: () => void;
  currentPage: number;
  hasMoreToLoad: boolean;
  propsDidChange: boolean; // if true, typically indicates that the list of paginated items should clear
  // also the page number will reset to 1
  aggregatedData: TModel[];
  isFetching: boolean;
};

type BaseSemiPaginatedProps = {
  page: number;
  query?: string;
};

// TProps passed into the hook should have everything the fetchFN needs EXCEPT `page`
// because that is tracked internally via a state variable
// any `page` prop passed into the hook will be overridden by the internal state
export const useSemiPaginatedQuery = <TProps, TModel>(
  props: TProps,
  cacheKey: string,
  fetchFn: (props: TProps & BaseSemiPaginatedProps) => Promise<TModel[]>,
  shouldDisableFetch = false // optional flag to disable the fetch
): SemiPaginatedQueryResponse<TModel> => {
  const [page, setPage] = React.useState<number>(1);
  const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
  const [aggregatedData, setAggregatedData] = React.useState<TModel[]>([]);
  const [propsDidChange, setPropsDidChange] = React.useState(false);
  const prevProps = usePrevious(props);
  const paginatedProps = React.useMemo(
    () => Object.assign({} as BaseSemiPaginatedProps & TProps, props, { page }),
    [props, page]
  );

  // note that the `page` in the key is the primary trigger for new requests in `fetchNext()`
  // it is also the primary guard against duplicate requests for the same page
  const paginatedCacheKey = `${cacheKey}:${JSON.stringify(paginatedProps)}`;

  // in the future we might want to have `retry` as a param
  const { isLoading, data, error, isFetching } = useQuery<TModel[], Error>(
    paginatedCacheKey,
    () => fetchFn(paginatedProps),
    { retry: false, enabled: !shouldDisableFetch }
  );

  React.useEffect(() => {
    setHasMoreToLoad((data?.length ?? 0) > 0);
  }, [data, setHasMoreToLoad]);

  // changing the page state will generate a new key and force a new request
  const fetchNext = () => {
    if (!hasMoreToLoad || isLoading) return;
    setPage((p: number) => p + 1);
  };

  // reset the page to 1 if any of the props have changed
  React.useEffect(() => {
    if (
      prevProps !== undefined &&
      props !== undefined &&
      !shallowObjectCompare(prevProps, props)
    ) {
      setPropsDidChange(true);
      setPage(1);
    } else {
      setPropsDidChange(false);
    }
  }, [prevProps, props]);

  // aggregate the data as new values come in
  // if props did change, that means we need to reset the data
  React.useEffect(() => {
    if (propsDidChange) setAggregatedData([]);
    else setAggregatedData(aggregatedData.concat(data || []));
    // eslint-disable-next-line
  }, [data, setAggregatedData, propsDidChange]);

  return {
    isLoading,
    errorMessage: error?.message,
    data,
    fetchNext,
    currentPage: page,
    hasMoreToLoad,
    propsDidChange,
    aggregatedData,
    isFetching,
  };
};

export const useSemiPaginatedPublishers = (
  props: Omit<AuthorsFetchParams, 'page'>
): SemiPaginatedQueryResponse<Author> => {
  return useSemiPaginatedQuery(
    props,
    `insights:report:filter:publisher`,
    fetchAndTransformPaginatedPublishers
  );
};

export const useSemiPaginatedAuthorAliases = (
  props: Omit<AuthorsFetchParams, 'page'>
): SemiPaginatedQueryResponse<Author> => {
  return useSemiPaginatedQuery(
    props,
    `insights:report:filter:author_alias`,
    fetchAndTransformPaginatedAuthorAliases
  );
};

export const useSemiPaginatedAttributes = (
  props: Omit<AttributeFilterFetchProps, 'page'>
): SemiPaginatedQueryResponse<Option> => {
  return useSemiPaginatedQuery(
    props,
    `insights:report:filter:attributes`,
    fetchAndTransformAttributeFilters,
    props.dateRange === undefined
  );
};

// typically this endpoint should return the original resource (eg Author or Campaign)
// but the dojo endpoint transforms the campaign resource to these option objects
// therefore we return a list of options, where value and label pertain to the campaign id and names
export const useSemiPaginatedCampaigns = (
  props: Omit<CampaignFilterFetchProps, 'page'>
): SemiPaginatedQueryResponse<Option> => {
  return useSemiPaginatedQuery(
    props,
    `insights:report:filter:campaign`,
    fetchAndTransformCampaignFilters,
    props.dateRange === undefined
  );
};

export const useSemiPaginatedPoll = (
  props: Omit<PollFilterFetchProps, 'page'>
): SemiPaginatedQueryResponse<Option> => {
  return useSemiPaginatedQuery(
    props,
    `insights:report:filter:poll`,
    fetchAndTransformPollFilters,
    props.dateRange === undefined
  );
};

export const useSemiPaginatedContents = (
  props: Omit<ContentFilterFetchProps, 'page'>
): SemiPaginatedQueryResponse<Option> => {
  return useSemiPaginatedQuery(
    props,
    `insights:report:filter:content`,
    fetchAndTransformContentFilters,
    props.dateRange === undefined
  );
};
