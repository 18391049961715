import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { ListPage as LayoutListPage } from 'DesignSystem/Layout/Pages';
import * as Text from 'DesignSystem/Typography';
import { Workflow } from 'models/workflows';
import { useWorkflowsInfiniteQuery } from 'hooks/workflows/useQueries';
import { WorkflowFiltersContext } from 'contexts/workflow/filters';
import { useDebounce } from 'hooks/useDebounce';
import { FetchProps } from 'services/api-workflows';
import { Banner } from './Banner';
import { FiltersBar } from './WorkflowFiltersBar/FiltersBar';
import { defaultListPages } from './default-list-pages';

export const ListPage: React.FC<
  RouteComponentProps & { defaultFetchProps: FetchProps }
> = ({ defaultFetchProps }) => {
  const { filters, setValue } = React.useContext(WorkflowFiltersContext);

  const [filterFetchProps, setFilterFetchProps] = React.useState<{
    search?: string;
  }>({});

  const fetchProps = { ...defaultFetchProps, ...useDebounce(filterFetchProps) };

  const filterProps = Object.keys(filters.standard).reduce((map, f) => {
    const filter = filters.standard[f];
    if (filter.values.length > 0) {
      return {
        ...map,
        [filter.field]: filter.values,
      };
    }
    return map;
  }, {});

  const query = useWorkflowsInfiniteQuery({ ...fetchProps, ...filterProps });

  return (
    <LayoutListPage
      title="Workflows"
      description="Create and manage automated workflows to increase adoption and engagement"
      breadcrumbs={[
        { label: 'Configure', to: '../..' },
        { label: 'Workflows' },
      ]}
      tabs={defaultListPages.map(({ key, label }) => ({
        to: key,
        label,
      }))}
      actions={[
        {
          icon: <Icon name="Plus" fill="currentColor" />,
          label: 'Create',
          to: '../new',
        },
      ]}
      filterbar={
        <FiltersBar
          fetchProps={filterFetchProps}
          onChange={setFilterFetchProps}
          filters={filters}
          setValue={setValue}
        />
      }
      infiniteQuery={query}
      emptyList={
        <Text.Subheading block bold>
          No workflows match that criteria.
        </Text.Subheading>
      }
      loading={<></>}
      renderRow={(workflow: Workflow, index: number) => (
        <Banner key={workflow.id} index={index} workflow={workflow} />
      )}
    />
  );
};
