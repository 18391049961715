import React, { useRef } from 'react';
import { ImageData, isImageDataArray } from 'models/image';
import {
  fieldsToImages,
  ImageFieldData,
  imagesToFields,
} from 'models/publisher/block';
import { Source } from '../../shared/SourceMenu';

import { UpdateOpts } from '../../../useFieldForm';

export type Action = {
  type: 'insert' | 'replace' | 'replace-items';
  index: number;
  data: ImageData;
  source?: Source;
  opts?: UpdateOpts;
};

type BulkAction = {
  type: 'insert' | 'replace' | 'bulk-set' | 'replace-items';
  index: number;
  data: ImageData[];
  opts?: UpdateOpts;
};

type UseCollection = {
  images: ImageData[];
  clear: () => void;
  remove: (index: number) => void;
  setCurrent: (index: number) => void;
  reorder: (images: ImageData[]) => void;
  replaceItems: (images: ImageData[]) => void;
  append: (images: ImageData[]) => void;
  create: (source?: Source) => void;
};

type UseCurrent = {
  action?: Action['type'];
  done: () => void;
  cancel: () => void;
  item: {
    source?: Source;
    data?: ImageData;
    update: (data: ImageData) => void;
    remove: () => void;
  };
};

export function useGalleryDataMutator(
  initialData: ImageFieldData[],
  onChange: (data: ImageFieldData[], opts?: UpdateOpts) => void
): {
  current: UseCurrent;
  collection: UseCollection;
} {
  const [images, set_images] = React.useState(fieldsToImages(initialData));
  const [current_action, set_current_action] = React.useState<Action>();
  const refImages = useRef(fieldsToImages(initialData));

  const applyAction = React.useCallback(
    (action?: Action | BulkAction) => {
      if (!action) return;
      if (action.type === 'bulk-set') {
        set_images(action.data);
        refImages.current = action.data;
        onChange(imagesToFields(action.data), action?.opts);
      } else if (action.type === 'replace-items') {
        const replacedImages = refImages.current;
        const { data } = action;
        if (isImageDataArray(data)) {
          data.forEach((incomingImage) => {
            replacedImages.forEach((existingImage, i) => {
              if (
                existingImage.imageId === incomingImage.imageId &&
                !existingImage.processed
              ) {
                replacedImages[i] = incomingImage;
              }
            });
          });
        } else {
          replacedImages.forEach((existingImage, i) => {
            if (
              existingImage.imageId === data.imageId &&
              !existingImage.processed
            ) {
              replacedImages[i] = data;
            }
          });
        }
        refImages.current = replacedImages;
        onChange(
          imagesToFields(replacedImages.filter((el) => el.processed)),
          action?.opts
        );
      } else {
        const frontEnd = action.index;
        const backStart = action.index + (action.type === 'replace' ? 1 : 0);
        const front = refImages.current.slice(0, frontEnd);
        const back = refImages.current.slice(backStart);
        const middle = (Array.isArray(action.data)
          ? action.data
          : [action.data]
        ).filter((img) => img && !!img.url);
        const updates = [...front, ...middle, ...back];
        set_images(updates);
        refImages.current = updates;

        // Don't send block update request, the image could be in processing state
        // replace-items actions is responsilbe for sending requests after image is processed
        // FE-4432: Image can be already processed and not have imageId, which means it
        // won't trigger processing state changes, thus it won't trigger block update
        const insertingProcessedImages =
          action.type === 'insert' && middle.every((i) => i.processed);
        if (action.type !== 'insert' || insertingProcessedImages) {
          onChange(imagesToFields(updates), action?.opts);
        }
      }
    },
    [onChange]
  );

  const currentDone = React.useCallback(() => {
    applyAction(current_action);
    set_current_action(undefined);
  }, [applyAction, current_action]);

  const currentCancel = React.useCallback(() => {
    set_current_action(undefined);
  }, []);

  const currentItemUpdate = React.useCallback(
    (data: ImageData) => {
      if (!current_action) return;
      set_current_action({
        ...current_action,
        data: { ...current_action.data, ...data },
      });

      const updatedCollection = images;
      updatedCollection[current_action.index] = data;
      set_images(updatedCollection);
      refImages.current = updatedCollection;
      onChange(imagesToFields(updatedCollection));
    },
    [current_action, images, onChange]
  );
  const currentItemRemove = React.useCallback(() => {
    if (!current_action?.index) return;
    applyAction({ type: 'replace', index: current_action.index, data: [] });
    set_current_action(undefined);
  }, [current_action, applyAction]);

  return {
    current: {
      action: current_action?.type,
      done: currentDone,
      cancel: currentCancel,
      item: {
        data: current_action?.data,
        source: current_action?.source,
        update: currentItemUpdate,
        remove: currentItemRemove,
      },
    },
    collection: {
      images,
      clear: React.useCallback(() => {
        applyAction({ type: 'bulk-set', index: 0, data: [] });
      }, [applyAction]),
      remove: React.useCallback(
        (index: number) => {
          applyAction({ type: 'replace', index, data: [] });
        },
        [applyAction]
      ),
      replaceItems: React.useCallback(
        (data: ImageData[], opts?: UpdateOpts) => {
          applyAction({
            type: 'replace-items',
            index: refImages.current.length,
            data,
            opts,
          });
        },
        [applyAction]
      ),
      setCurrent: React.useCallback(
        (index: number) => {
          set_current_action({ type: 'replace', index, data: images[index] });
        },
        [images]
      ),
      append: React.useCallback(
        (data: ImageData[]) => {
          applyAction({
            type: 'insert',
            index: refImages.current.length,
            data,
          });
        },
        [applyAction]
      ),
      reorder: React.useCallback(
        (data: ImageData[]) => {
          applyAction({ type: 'bulk-set', index: 0, data });
        },
        [applyAction]
      ),
      create: React.useCallback(
        (source?: Source) => {
          set_current_action({
            type: 'insert',
            source,
            index: images.length,
            data: { url: '', altText: '', processed: true },
          });
        },
        [images.length]
      ),
    },
  };
}
