import React, { useMemo, useState } from 'react';
import cx from 'classnames';

import { scaleOrdinal } from 'd3';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import {
  CustomDrawingFunction,
  HorizontalBarChart,
} from '../../../../../../../shared/Charts/HorizontalBarChart';
import styles from '../MetricsCards.module.css';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';
import { capitalize } from '../../../../../../../utility/strings';
import { Button } from '../../../../../../../shared/Button';

interface IContentEngagementActionCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const dimensions = {
  height: 285,
  width: 528,
  padding: {
    top: 0,
    bottom: 4,
    left: 120,
    right: 100,
  },
  bars: {
    padding: {
      inner: 0.5,
      outer: 0,
    },
  },
};

export const ContentEngagementActionCard: React.FC<IContentEngagementActionCard> = ({
  data,
  isLoading,
}) => {
  const { engagement } = data?.campaign ?? {};
  const pageLength = 5;
  const [page, setPage] = useState(0);
  const graphData = useMemo(() => {
    const filtered = Object.keys(engagement ?? {})
      .filter((key) => {
        return (
          !key.startsWith('open') &&
          engagement?.[key] !== undefined &&
          engagement?.[key] !== 0
        );
      })
      .map((key) => {
        return {
          label: capitalize(key.replace(/[_-]+/g, ' ')),
          value: engagement?.[key],
        };
      });
    const remaining = filtered.length % pageLength;
    if (remaining !== 0) {
      for (let i = 0; i < pageLength - remaining; i += 1) {
        filtered.push({ label: `placeholder${i}`, value: undefined });
      }
    }
    return filtered;
  }, [engagement]);
  const domainOverride: [number, number] = useMemo(() => {
    const max: number = graphData.reduce((s: number, { value }) => {
      return Math.max(value ?? 0, s);
    }, 0);
    return [0, max];
  }, [graphData]);
  const numPages = useMemo(() => {
    return Math.floor(graphData.length / pageLength);
  }, [graphData.length]);

  const color = scaleOrdinal()
    .domain(graphData.map((d) => d.label))
    .range([
      // 'rgba(255, 208, 130, 0.93)'
      'url(#orangeBarGradient)',
    ]);

  const drawLabels: CustomDrawingFunction = ({ bars, scaleX, scaleY }) => {
    if (bars === undefined) {
      return <></>;
    }

    return bars.map(({ label }) => {
      if (
        scaleX === undefined ||
        scaleX.bandwidth === undefined ||
        scaleY === undefined
      ) {
        return <></>;
      }
      const xPos = 8;
      const yPos = scaleX(label as never) ?? 0;

      let formattedLabel = label?.split(' ') ?? [];
      const approximateWidth = (label?.length ?? 0) * 8;

      if (approximateWidth > dimensions.padding.left - xPos) {
        // Split or truncate the label
        const strings = formattedLabel;
        let tmpString: Array<string> = [];
        const tmpLabel: Array<string> = [];
        let index = 0;
        while (index < strings.length) {
          tmpString.push(strings[index]);
          const tmpWidth = tmpString.join(' ').length * 8;

          if (tmpWidth > dimensions.padding.left - xPos) {
            const next = tmpString.pop();
            tmpLabel.push(tmpString.join(' '));
            tmpString = next === undefined ? [] : [next];
          }

          index += 1;
        }
        tmpLabel.push(tmpString.join(' '));

        formattedLabel = tmpLabel;
      }
      const textY =
        yPos + (scaleX.bandwidth() ?? 1) / 2 - (formattedLabel.length - 1) * 7;

      return (
        <text
          key={`bar-${label}`}
          x={xPos}
          y={textY}
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={15}
          visibility={label?.startsWith('placeholder') ? 'hidden' : 'visible'}
        >
          {formattedLabel.map((s, ii) => (
            <tspan key={s} x={xPos} dy={`${ii === 0 ? '0' : '1.2'}em`}>
              {s}
            </tspan>
          ))}
        </text>
      );
    });
  };

  const drawCustom: CustomDrawingFunction = ({ bars, scaleX, scaleY }) => {
    return (
      <>
        {drawLabels({ bars, scaleX, scaleY })}

        <defs>
          <linearGradient
            id="orangeBarGradient"
            x1="0%"
            x2="100%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(245, 158, 11, 1)" />
            <stop offset="100%" stopColor="rgba(255, 208, 130, 0.93)" />
          </linearGradient>
        </defs>
      </>
    );
  };

  return (
    <div className={cx(styles.metricCard, styles.metricCardHalf)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>
          What happened after Users opened your content in Experience?
        </h1>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <>
          <div className={styles.metricCardBody}>
            <HorizontalBarChart
              data={graphData.slice(page * pageLength, (page + 1) * pageLength)}
              domainOverride={domainOverride}
              customColors={color}
              chartDimensions={dimensions}
              customDrawing={drawCustom}
              hideXAxis
              hideYAxis
              units="User"
            />
          </div>
          <div className={styles.metricCardBody}>
            {numPages > 1 && (
              <span className={styles.metricCardButtonGroup}>
                <Button
                  type="blank"
                  onClick={() => setPage((p) => p - 1)}
                  disabled={page === 0}
                  iconName="chevron-left"
                  compact
                />
                <Button
                  type="blank"
                  onClick={() => setPage((p) => p + 1)}
                  disabled={page === numPages - 1}
                  iconName="chevron-right"
                  compact
                />
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
