import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormSection, FormSubsection } from 'DesignSystem/Form';
import { Program } from 'models/program';
import { TextInput } from 'shared/TextInput';
import styles from './community.module.css';

type Props = {
  program: Program;
  onChange: (value: Program) => void;
} & RouteComponentProps;

export const CommunitySettings: React.FC<Props> = (props) => {
  const { program, onChange } = props;

  const handleUpdate = (value: string, field: string) => {
    onChange({
      ...program,
      [field]: value,
    } as Program);
  };

  return (
    <FormSection title="Community Settings">
      <FormSubsection title="Community name">
        <TextInput
          value={program.name}
          onChange={(value) => handleUpdate(value, 'name')}
          className={styles.textInput}
        />
      </FormSubsection>
      <FormSubsection
        title="Community slug"
        description="This will be added to your community's URL to uniquely identify it."
      >
        <TextInput
          value={program.slug}
          onChange={(value) => handleUpdate(value, 'slug')}
          className={styles.textInput}
        />
      </FormSubsection>
    </FormSection>
  );
};
