import { useNavigate } from '@reach/router';
import { useProgramIdState } from 'contexts/program';
import { Shortcut } from 'models/shortcut';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  createShortcut,
  fetchShortcut,
  fetchShortcuts,
  ShortcutData,
  ShortcutFetchParams,
  ShortcutParams,
  ShortcutsData,
  orderShortcuts,
  ShortcutOrderParams,
  updateShortcut,
  deleteShortcut,
} from 'services/api-shortcut';
import { useFlashMessage } from 'contexts/flasher';
import { QueryResponse, MutationOptions } from './common';
import { useFeatureFlagsQuery } from './feature-flags';

export const useShortcutsQuery = (
  props: ShortcutFetchParams
): QueryResponse<Array<Shortcut>> => {
  const { isLoading, error, data } = useQuery<ShortcutsData, Error>({
    queryKey: `${props.programId}-shortcuts`,
    queryFn: () => fetchShortcuts(props),
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export const useShortcutQuery = (
  props: ShortcutParams
): QueryResponse<Shortcut> => {
  const { isLoading, error, data } = useQuery<ShortcutData, Error>({
    queryKey: `${props.programId}-shortcuts-${props.shortcutId}`,
    queryFn: () => fetchShortcut(props),
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

type ShortcutCreateParams = {
  data: Partial<Shortcut>;
};
export const useCreateShortcut = (
  options: UseMutationOptions<Shortcut, Error, ShortcutCreateParams> = {}
): UseMutationResult<Shortcut, Error, ShortcutCreateParams> => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [programId] = useProgramIdState();
  const { setFlashMessage } = useFlashMessage();

  const mutationFn = (payload: ShortcutCreateParams) =>
    createShortcut({ programId, payload });

  return useMutation(mutationFn, {
    mutationKey: `${programId}-create-new-shortcut`,
    onSuccess: () => {
      client.invalidateQueries(`${programId}-shortcuts`);
      setFlashMessage({
        message: 'Shortcut created successfully',
        severity: 'info',
      });
      navigate(`/${programId}/configure/shortcuts`);
    },
    ...options,
  });
};

export const useUpdateShortcut = (
  options: UseMutationOptions<Shortcut, Error, ShortcutCreateParams> = {}
): UseMutationResult<Shortcut, Error, ShortcutCreateParams> => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [programId] = useProgramIdState();
  const { setFlashMessage } = useFlashMessage();

  const mutationFn = (payload: ShortcutCreateParams) =>
    updateShortcut({ programId, payload });

  return useMutation(mutationFn, {
    mutationKey: `${programId}-update-shortcut`,
    onSuccess: (shortcut) => {
      client.invalidateQueries(`${programId}-shortcuts`);
      navigate(`/${programId}/configure/shortcuts/${shortcut.id}`);
      setFlashMessage({
        message: 'Shortcut updated successfully',
        severity: 'info',
      });
    },
    ...options,
  });
};

export const useOrderShortcut = (
  options: UseMutationOptions<null, Error, ShortcutOrderParams> = {}
): UseMutationResult<null, Error, ShortcutOrderParams> => {
  const client = useQueryClient();
  const [programId] = useProgramIdState();

  const mutationFn = (payload: ShortcutOrderParams) =>
    orderShortcuts(programId, payload);

  return useMutation(mutationFn, {
    mutationKey: `${programId}-order-shortcuts`,
    onSuccess: () => {
      client.invalidateQueries(`${programId}-shortcuts`);
    },
    onError: () => {
      client.invalidateQueries(`${programId}-shortcuts`);
    },
    ...options,
  });
};

export const useDeleteShortcut = (
  options: UseMutationOptions<null, Error, Shortcut['id']> = {}
): UseMutationResult<null, Error, Shortcut['id']> => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [programId] = useProgramIdState();
  const { setFlashMessage } = useFlashMessage();

  const mutationFn = (id: Shortcut['id']) => deleteShortcut({ programId, id });

  return useMutation(mutationFn, {
    mutationKey: [`${programId}-delete-shortcuts`],
    onSuccess: () => {
      client.invalidateQueries(`${programId}-shortcuts`);
      setFlashMessage({ message: 'Shortcut deleted', severity: 'info' });
      navigate(`/${programId}/configure/shortcuts`);
    },
    ...options,
  });
};

export const useShortcutsLimit = (): ReturnType<
  typeof useFeatureFlagsQuery
> => {
  const [programId] = useProgramIdState();
  return useFeatureFlagsQuery(programId, 'Studio.Shortcuts.Limit');
};

export const useEnableShortcut = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Shortcut> = {}
): {
  enable: (shortcut: Shortcut) => void;
} => {
  const queryClient = useQueryClient();
  const enable = (shortcut: Shortcut) => {
    const activeShortcut = {
      ...shortcut,
      status: 'active',
    } as const;
    const payload = { data: activeShortcut };
    updateShortcut({ programId, payload })
      .then((data) => {
        queryClient.invalidateQueries(`${programId}-shortcuts`);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { enable };
};

export const useDisableShortcut = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Shortcut> = {}
): {
  disable: (shortcut: Shortcut) => void;
} => {
  const queryClient = useQueryClient();
  const disable = (shortcut: Shortcut) => {
    const inactiveShortcut = {
      ...shortcut,
      status: 'inactive',
    } as const;
    const payload = { data: inactiveShortcut };
    updateShortcut({ programId, payload })
      .then((data) => {
        queryClient.invalidateQueries(`${programId}-shortcuts`);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { disable };
};
