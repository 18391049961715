/* eslint-disable no-underscore-dangle */
import FroalaEditor from 'froala-editor';
import {
  Firefox,
  MaybeNode,
  Safari,
  isfmt,
  issep,
  isvar,
  sepSelector,
  varAttr,
  varSelector,
} from './common';
import { setSeparators } from './fixCursedCursor';

interface FUFroalaEditor extends FroalaEditor {
  _fuSelectedVars?: Array<Element>;
  _fuAddingStyle?: boolean;
}
type MaybeElement = Element | null;

const valueToTag = {
  bold: 'strong',
  italic: 'em',
  underline: 'u',
  'line-through': 's',
  sub: 'sub',
  top: 'sup',
} as Record<string, string>;

function formatVariables(
  editor: FUFroalaEditor,
  tag: string,
  prop: string,
  value: string
) {
  const { _fuAddingStyle: adding, _fuSelectedVars: liquid_vars } = editor;
  for (const liquid of liquid_vars || []) {
    // 1. filter the property out of the style attribute
    // 2. if we are adding style, add it back in with the given value
    const styleAttr = liquid.getAttribute('style');
    let style = styleAttr?.split(';') || [];
    // text-decoration is used for both underline and strikethrough so we have
    // to handle multiple values
    if (prop === 'text-decoration' && styleAttr?.includes(prop)) {
      style = style
        .map((rule) => {
          if (!rule.startsWith('text-decoration:')) return rule;
          let values = rule.split(':')[1]?.split(' ') || [];
          values = values.filter((f) => f !== value);
          if (adding && !liquid.closest(tag)) values.push(value);
          return `${prop}:${values.join(' ')}`;
        })
        .filter((rule) => !!rule.split(':')[1]);
    } else {
      style = style.filter((rule) => !rule.startsWith(`${prop}:`));
      if (adding && !liquid.closest(tag)) style.push(`${prop}:${value}`);
    }
    liquid.setAttribute('style', style.join(';'));
  }
}

function colorVariable(liquid: Element, prop: string, color: string) {
  // 1. filter the property out of the style attribute
  // 2. if we aren't removing the color, add it back in with the given value
  const style = liquid.getAttribute('style')?.split(';') || [];
  const rules = style.filter((rule) => !rule.startsWith(`${prop}:`));
  if (color !== 'REMOVE') rules.push(`${prop}:${color}`);
  liquid.setAttribute('style', rules.join(';'));
  const child = liquid.firstElementChild;
  const sibling = liquid.previousElementSibling;
  if (child) {
    // froala sets the color on the child element which is not where it goes
    child.removeAttribute('style');
  } else if (
    sibling?.getAttribute('data-liquid-unique-id') ===
    liquid.getAttribute('data-liquid-unique-id')
  ) {
    // froala makes a shallow copy of the variable element if it has a color.
    // delete it if we find one.
    liquid.remove();
  }
}

function colorVariables(editor: FUFroalaEditor, prop: string, color: string) {
  const { _fuSelectedVars: liquid_vars } = editor;
  for (const liquid of liquid_vars || []) colorVariable(liquid, prop, color);
}

// make sure that all liquid variables are preceded by the right format elements
function ensureFormatTags(editor: FUFroalaEditor) {
  for (const liquid of editor.el.querySelectorAll(varSelector)) {
    // remove any format elements that precede the variable
    let base: MaybeElement = liquid;
    let node = liquid.previousSibling as MaybeNode;
    while (node && isfmt(node)) {
      const el = node as Element;
      if (el.className === 'fu-fr-marker') {
        base = el; // skip selection markers
      } else {
        // if an element contains a selection marker, move it outside
        const marker = el.querySelector('.fu-fr-marker');
        if (marker) el.before(marker);
        el.remove();
      }
      node = base?.previousSibling as MaybeNode;
    }
    // add new format tags based on the variable's style
    let target = null as MaybeElement;
    (liquid.getAttribute('style')?.split(';') || [])
      .flatMap((rule: string) => {
        const [prop, value] = rule.split(':');
        return prop === 'text-decoration' ? value.split(' ') : value;
      })
      .filter((value: string) => value in valueToTag)
      .forEach((value: string) => {
        const tag = valueToTag[value];
        const el = document.createElement(tag);
        if (!target) liquid.before(el);
        else target.prepend(el);
        target = el;
      });
    // froala needs this to recognize the formatting
    if (target) target.prepend('\u200b');
  }
}

function getSelectedVars(editor: FUFroalaEditor) {
  const selection = window.getSelection() as Selection;
  return editor.selection.blocks().flatMap((el: Element) => {
    return Array.from(
      el.querySelectorAll('span[data-liquid]')
    ).filter((liquid: Element) => selection?.containsNode(liquid));
  });
}

// walk down the first children of a node to find the deepest element that
// either matches the tag, is a variable or a format element, or is empty
function dive(node: Node, offset: number, tag: string): Node {
  while (
    node.nodeName !== tag &&
    node.firstChild &&
    !isfmt(node) &&
    !isvar(node)
  ) {
    const child = node.childNodes[offset];
    if (!child) break;
    node = child as Node; // eslint-disable-line no-param-reassign
    offset = 0; // eslint-disable-line no-param-reassign
  }
  return node;
}

// is a node contained by or is itself the specified element type, or
// is it the root of a stack of nested format elements that contains that type
function hasFormat(node: Node | null, tag: string) {
  if (!node) return false;
  const el = (node.nodeType === Node.ELEMENT_NODE
    ? node
    : node.parentElement) as Element;
  return (
    el.nodeName === tag ||
    (isfmt(el) && el.querySelector(tag)) ||
    el.closest(tag)
  );
}

// check to see if the current selection is styled by the given element type--
// that is to say, whether froala considers the selection to be styled that way
function selectionHasFormat(tag?: string) {
  if (!tag) return false;
  const {
    anchorNode,
    anchorOffset,
    focusNode,
    focusOffset,
  } = window.getSelection() as Selection;
  if (!anchorNode || !focusNode) return false;
  // anchor is where the user started selecting and focus is where they
  // stopped. we have to determine which comes first in the document because
  // that's what froala cares about.
  // we use dive() to find the best element to test for the format
  let type = anchorNode.nodeType;
  let node = anchorNode;
  let offset = anchorOffset;
  if (node === focusNode) {
    // selection all in one node? test the offset
    if (offset > focusOffset) {
      type = focusNode.nodeType;
      node = focusNode;
      offset = focusOffset;
    }
    node = dive(node, offset, tag);
  } else {
    // we have to dive() first for selection across multiple nodes
    const fnode = dive(focusNode, focusOffset, tag);
    node = dive(anchorNode, anchorOffset, tag);
    if (
      // eslint-disable-next-line no-bitwise
      node.compareDocumentPosition(fnode) & Node.DOCUMENT_POSITION_PRECEDING
    ) {
      type = focusNode.nodeType;
      node = fnode;
      offset = focusOffset;
    }
  }
  // is the selection start at the edge of a text node next to a formatted node
  if (type === Node.TEXT_NODE) {
    if (offset === 0 && hasFormat(node.previousSibling, tag)) return true;
    if (offset === node.textContent?.length && hasFormat(node.nextSibling, tag))
      return true;
  }
  // is the node itself formatted
  return hasFormat(node, tag);
}

// froala loses the text selection when it formats variables, so we have to do
// a few things before it starts its work:
// 1. get a list of the liquid variables contained in the selection
// 2. figure out whether we are adding style or not
// 3. stash the selection in a way that froala will ignore
function saveSelection(editor: FUFroalaEditor, tag?: string) {
  // eslint-disable-next-line no-param-reassign
  editor._fuSelectedVars = getSelectedVars(editor);
  if (editor._fuSelectedVars.length) {
    // eslint-disable-next-line no-param-reassign
    editor._fuAddingStyle = !selectionHasFormat(tag);
    editor.selection.save(); // have froala add selection marker elements
    if (Firefox || Safari)
      // remove all separators. sometimes froala puts the  markers inside
      // separators so we have to move them out
      for (const sep of editor.el.querySelectorAll(sepSelector)) {
        const marker = sep.querySelector('.fr-marker');
        if (marker?.dataset.type === true) sep.before(marker);
        else if (marker) sep.after(marker);
        sep.remove();
      }
    // change the  class so that froala doesn't delete them
    for (const marker of editor.el.querySelectorAll('.fr-marker')) {
      marker.className = 'fu-fr-marker';
      marker.setAttribute(
        'style',
        `${marker.getAttribute('style')} color: auto;`
      );
    }
  }
}

// if the user inserts a variable somewhere that already has a text or
// background color, we have to copy the color to the variable itself
export const setColor = (editor: FUFroalaEditor, id: string): void => {
  const liquid = editor.el.querySelector(`span[${varAttr}="${id}"]`);
  const bg = liquid?.closest('span[style*="background-color:"]');
  const fg = liquid?.closest('span[style*="color:"]');
  if (bg) colorVariable(liquid, 'background-color', bg.style.backgroundColor);
  if (fg?.style.color) colorVariable(liquid, 'color', fg.style.color);
};

export const fixFormatting = (editor: FUFroalaEditor): void => {
  editor.events.on('commands.before', (command: string) => {
    // capture the current selection and the selected liquid variables before
    // froala does its work
    switch (command) {
      case 'backgroundColor':
        saveSelection(editor);
        break;
      case 'bold':
        saveSelection(editor, 'STRONG');
        break;
      case 'italic':
        saveSelection(editor, 'EM');
        break;
      case 'strikeThrough':
        saveSelection(editor, 'S');
        break;
      case 'subscript':
        saveSelection(editor, 'SUB');
        break;
      case 'superscript':
        saveSelection(editor, 'SUP');
        break;
      case 'textColor':
        saveSelection(editor);
        break;
      case 'underline':
        saveSelection(editor, 'U');
        break;
      default:
    }
  });
  editor.events.on('commands.after', (command: string, color: string) => {
    if (!editor._fuSelectedVars?.length) return;
    switch (command) {
      case 'applybackgroundColor':
        colorVariables(editor, 'background-color', color);
        break;
      case 'applytextColor':
        colorVariables(editor, 'color', color);
        break;
      case 'bold':
        formatVariables(editor, 'strong', 'font-weight', 'bold');
        break;
      case 'italic':
        formatVariables(editor, 'em', 'font-style', 'italic');
        break;
      case 'strikeThrough':
        formatVariables(editor, 's', 'text-decoration', 'line-through');
        break;
      case 'subscript':
        formatVariables(editor, 'sub', 'font-size', 'small');
        formatVariables(editor, 'sub', 'vertical-align', 'sub');
        break;
      case 'superscript':
        formatVariables(editor, 'sup', 'font-size', 'small');
        formatVariables(editor, 'sup', 'vertical-align', 'top');
        break;
      case 'underline':
        formatVariables(editor, 'u', 'text-decoration', 'underline');
        break;
      default:
        return; // don't need to do any post-format work for other commands
    }
    setImmediate(() => {
      ensureFormatTags(editor);
      const markers = editor.el.querySelectorAll('.fu-fr-marker');
      if (markers) {
        // if there's a selection, restore it
        if (Firefox || Safari) {
          // if a marker precedes a styled variable at the start of a paragraph,
          // stash it inside the format element so that separators work right
          for (const marker of markers) {
            if (
              isvar(marker.nextSibling) &&
              isfmt(marker.previousSibling) &&
              !marker.previousSibling?.previousSibling
            )
              marker.previousSibling.append(marker);
          }
          setSeparators(editor, true);
          // move any stashed markers back out of the format elements
          for (const marker of markers) {
            const parent = marker.parentNode;
            if (isfmt(parent) && issep(parent?.nextSibling))
              parent.after(marker);
          }
        }
        // reset the class name to froala's version and restore the selection
        for (const marker of markers) marker.className = 'fr-marker';
        editor.selection.restore();
      } else if (Firefox || Safari) {
        // otherwise just restore the separators
        setSeparators(editor);
      }
    });
  });
};
