import React from 'react';
import { Link } from '@reach/router';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { fetchBulkUpdatePermissionsJobById } from 'services/api-bulk-update-permissions';
import { FlashProgressUpdateType } from 'models/flash-message';

export const useJobProgressFlashMessage = (): ((
  id: string,
  { selectedCount }: { selectedCount: number }
) => void) => {
  const { id: program } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  return (
    id: string,
    { selectedCount: total }: { selectedCount: number }
  ): void => {
    const link = (
      <Link
        to={`/${program}/configure/bulk-updates/${id}/details`}
        style={{ color: 'var(--color-gray00)' }}
      >
        View Status Report
      </Link>
    );
    const updateProgressCallback = getUpdateCallback(program, id, total, link);
    const interval = Math.max(500, Math.min(5000, Math.floor(total * 50)));
    setFlashMessage({
      severity: 'progress',
      message: 'Your bulk action is being processed.',
      messageKey: `${program}.${id}`,
      timeout: false,
      children: link,
      inlineChildren: true,
      progress: {
        interval,
        max: total,
        update: updateProgressCallback,
      },
    });
  };
};

const getUpdateCallback = (
  program: number,
  id: string,
  total: number,
  link: JSX.Element
) => (updateFlasher: FlashProgressUpdateType): void => {
  fetchBulkUpdatePermissionsJobById(program, +id).then(
    ({ state, processedUsers, failedUsers, errors }) => {
      if (state === 'created' || state === 'processing') {
        updateFlasher(processedUsers + failedUsers);
      } else if (state === 'completed') {
        updateFlasher(total, {
          severity: 'info',
          message: 'Your bulk action was successful.',
          children: link,
          inlineChildren: true,
        });
      } else {
        const count = errors?.count ?? '';
        updateFlasher(total, {
          severity: 'error',
          message: `There ${
            count === 1 ? 'was an error' : `were ${count} errors`
          } with your bulk action.`,
          timeout: false,
          children: link,
          inlineChildren: true,
        });
      }
    }
  );
};
