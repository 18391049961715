import { ContentReporter } from '../models/content-reporter';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';

export const fetchContentReporters = async (
  programId: number,
  contentId: number
): Promise<ContentReporter[]> => {
  const response = await request(
    `${bossanovaDomain}/v2/tenants/program:${programId}/contents/${contentId}/content_reporters`
  );

  if (response.status === 200) {
    const output = await response.json();
    return output.data?.map((value: { attributes: Record<string, unknown> }) =>
      deepCamelcaseKeys(value.attributes)
    );
  }
  throw new Error(`Error fetching content reporters: ${response.status}`);
};

export const clearContentReporters = async (
  programId: number,
  contentId: number
): Promise<void> => {
  const response = await request(
    `${bossanovaDomain}/v2/tenants/program:${programId}/contents/${contentId}/content_reporters/clear_all`,
    { method: 'POST' }
  );

  if (response.status % 200 >= 100) {
    throw new Error(`Error clearing content reporters: ${response.status}`);
  }
};
