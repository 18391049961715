import { TextArea, TextInput } from '@socialchorus/shared-ui-components';
import debounce from 'debounce-promise';
import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import { FieldError } from 'shared/Fieldset/FieldError';
import { duplicateName } from 'App/Program/Configuration/Topics/TopicV2/topicErrorDefinitions';
import { useIsErrorApplicable } from 'App/Program/Configuration/Topics/TopicV2/topicErrorHooks';
import { ConflictError } from 'services/Errors/ConflictError';
import { useTopicFormCtx } from '../../../../context';
import { BlockNavigationContext } from '../../../common/contexts/blockNavigation';
import { Fieldset, Form } from '../../../common/Form';
import { ImageDropzoneInput } from '../../../common/Form/ImageDropzoneInput';
import Sidebar from '../../../common/Sidebar';
import { SidebarComponent } from '../type';
import { ModalType } from '../../../../context/modals';

const customValidations: ComponentProps<typeof TextInput>['validations'] = {
  valueMissing: 'Required',
  custom: {
    noWhiteSpace: (val) =>
      val.length > 0 && val.trim().length === 0 ? 'Required' : '',
  },
};

const EditHeaderSidebar: SidebarComponent = ({ onClose }) => {
  const {
    state,
    handleChange,
    setValidationState,
    toggleModal,
    topicPublishError,
  } = useTopicFormCtx();
  const { setShouldBlockNavigation } = useContext(BlockNavigationContext);

  const formRef = useRef<HTMLFormElement>(null);
  const isDuplicateNameApplicable = useIsErrorApplicable('Duplicate Name');

  const validateForm = useCallback(() => {
    if (!formRef.current) return true;

    const isValid = formRef.current.reportValidity();

    setValidationState({
      isValidating: false,
      isValid,
    });

    return isValid;
  }, [setValidationState]);

  const onAfterChange = useMemo(() => debounce(validateForm, 100), [
    validateForm,
  ]);

  const onChange: typeof handleChange = useCallback(
    (key, value, opts) => {
      setValidationState((prevState) => ({
        ...prevState,
        isValidating: true,
      }));

      handleChange(key, value, opts);
      onAfterChange();
    },
    [handleChange, onAfterChange, setValidationState]
  );

  const onNavigateOut = useCallback(() => {
    const isValid = validateForm();

    if (!isValid) {
      toggleModal({
        type: ModalType.ErrorModal,
        content:
          'Some fields have errors. Please review your entries before leaving this section',
      });
    }

    return isValid;
  }, [toggleModal, validateForm]);

  useEffect(() => {
    setShouldBlockNavigation(() => !onNavigateOut());

    return () => {
      setShouldBlockNavigation(false);
    };
  }, [onNavigateOut, setShouldBlockNavigation]);

  const onBeforeClose = useCallback(() => {
    return onNavigateOut();
  }, [onNavigateOut]);

  return (
    <Sidebar
      title="Header"
      onBeforeClose={onBeforeClose}
      onClose={onClose}
      position="right"
      closeButton
    >
      <Form ref={formRef}>
        <div>
          <label htmlFor="topic-name-input">Name</label>
          <TextInput
            id="topic-name-input"
            onChange={(name) =>
              onChange('name', name, {
                resetPublishError: topicPublishError instanceof ConflictError,
              })
            }
            value={state.fields.name}
            fullWidth
            required
            validations={customValidations}
            maxLength={30}
          />
          {isDuplicateNameApplicable && (
            <FieldError
              error="The name of this topic is already in use"
              ariaLabel={duplicateName.name}
            />
          )}
        </div>

        <div>
          <label htmlFor="topic-desc-input">Description</label>
          <TextArea
            id="topic-desc-input"
            onChange={(desc) => onChange('description', desc)}
            value={state.fields.description}
            fullWidth
            required
            validations={customValidations}
            maxLength={150}
          />
        </div>

        <Fieldset legend="Topic Image">
          <ImageDropzoneInput
            imageSrc={state.fields.image?.url}
            onChange={(img) => onChange('image', img)}
          />
        </Fieldset>

        <Fieldset
          legend="Cover Image"
          toggleComponent={
            <FlipSwitch
              on={state.fields.coverEnabled}
              onChange={(val) => onChange('coverEnabled', Boolean(val))}
            />
          }
        >
          {state.fields.coverEnabled && (
            <ImageDropzoneInput
              imageSrc={state.fields.cover?.url}
              onChange={(img) => onChange('cover', img)}
            />
          )}
        </Fieldset>
      </Form>
    </Sidebar>
  );
};

export default EditHeaderSidebar;
