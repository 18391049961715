import React from 'react';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { WarningTriangle } from 'shared/icons';
import * as Text from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { TranscriptionJob, TranslationJob } from 'services/api-captions';
import { languageDisplayName } from 'utility/language';

type TranscriptionJobStatusProps = {
  captionJob: TranscriptionJob;
  waitingLabel?: never;
  processingLabel: string;
  failureLabel: string;
};

type TranslationJobStatusProps = {
  captionJob: TranslationJob;
  waitingLabel: string;
  processingLabel: string;
  failureLabel: string;
};

type CaptionJobStatusProps =
  | TranscriptionJobStatusProps
  | TranslationJobStatusProps;

export const CaptionJobStatus: React.FC<CaptionJobStatusProps> = ({
  captionJob,
  waitingLabel,
  processingLabel,
  failureLabel,
}) => {
  return (
    <>
      {captionJob &&
        captionJob.status === 'waiting' &&
        captionJob.locales.map((languageCode) => (
          <Box
            radius={4}
            margin={[8, 0, 0, 0]}
            padding={[12, 16]}
            color={Text.background.gray05}
            key={languageCode}
          >
            <Flex start>
              <FlexItem widen start>
                <Text.Body bold>{languageDisplayName(languageCode)}</Text.Body>
              </FlexItem>
              <LoadingSpinner size="xsmall" />
              <Box padding={[0, 0, 0, 8]}>
                <Text.Caption color={Text.color.gray90}>
                  {waitingLabel}
                </Text.Caption>
              </Box>
            </Flex>
          </Box>
        ))}
      {captionJob && captionJob.status === 'processing' && (
        <Box
          radius={4}
          margin={[8, 0, 0, 0]}
          padding={[12, 16]}
          color={Text.background.gray05}
        >
          <Flex start>
            <LoadingSpinner size="xsmall" />
            <Box padding={[0, 0, 0, 8]}>
              <Text.Caption color={Text.color.gray90}>
                {processingLabel}
              </Text.Caption>
            </Box>
          </Flex>
        </Box>
      )}
      {captionJob && captionJob.status === 'failed' && (
        <Box
          radius={4}
          margin={[8, 0, 0, 0]}
          padding={[12, 16]}
          color={Text.background.gray05}
        >
          <Flex start>
            <WarningTriangle />
            <Box padding={[0, 0, 0, 8]}>
              <Text.Caption color={Text.color.redFull}>
                {failureLabel}
              </Text.Caption>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};
