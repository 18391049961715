import { CustomSlug } from 'models/custom-slug';
import React from 'react';
import { useToggle } from 'hooks/useToggle';
import { Content } from 'models/content';

type CustomSlugContext = Partial<CustomSlug> & {
  setSelectedCampaign: (content: Content) => void;
  selectedCampaign: Content | undefined;
  showSelectedCampaignModal: ReturnType<typeof useToggle>['value'];
  toggleSelectedCampaignModal: ReturnType<typeof useToggle>['toggle'];
  handleClearCampaign: () => void;
};

export const CustomSlugContext = React.createContext<CustomSlugContext | null>(
  null
);

export function useCustomSlugContext(): CustomSlugContext {
  const context = React.useContext(CustomSlugContext);
  if (!context) {
    throw new Error(
      'useCustomSlugContext must be used within a CustomSlugContextProvider'
    );
  }
  return context;
}
