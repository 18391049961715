import React from 'react';
import { createHash } from 'crypto';

type Algorithm = 'sha1' | 'sha256' | 'sha384' | 'sha512';

export const useHash = (
  message?: string,
  algo: Algorithm = 'sha256'
): string => {
  const [hash, setHash] = React.useState<string>('');

  React.useEffect(() => {
    if (!message) {
      setHash('');
      return;
    }

    setHash(createHash(algo).update(message).digest('hex'));
  }, [algo, message]);

  return hash;
};
