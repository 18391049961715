import React from 'react';
import { FilterDropdownContext } from 'App/Program/Main/Insight/components/Filters/FilterDropdownContext';

export const DEFAULT_DROPDOWN_HEIGHT = 200;
export const DEFAULT_DROPDOWN_WIDTH = 220;
export const MIN_DROPDOWN_BOTTOM_GAP = 15;
const DEFAULT_DROPDOWN_BODY_HEIGHT = 300;

type HtmlDivElement = HTMLDivElement | null; // this matches the type of react's ref setter handler
export type FilterDropdownStructureContextType = {
  elemDropdown: HtmlDivElement;
  elemHeader: HtmlDivElement;
  elemBody: HtmlDivElement;
  elemFooter: HtmlDivElement;
  setElemDropdown: (element: HtmlDivElement) => void;
  setElemHeader: (element: HtmlDivElement) => void;
  setElemBody: (element: HtmlDivElement) => void;
  setElemFooter: (element: HtmlDivElement) => void;
  setPillButtonContainer: (element: HtmlDivElement) => void;
  setInfiniteListActualBodyHeight: (height: number) => void;
  getInfiniteListItemVariableHeight: (text: string) => number;
  infiniteListBodyHeight: number;
  leftPositionForOverflowingDropdowns?: number;
};

export const FilterDropdownStructureContext = React.createContext<
  FilterDropdownStructureContextType
>({
  infiniteListBodyHeight: DEFAULT_DROPDOWN_BODY_HEIGHT,
  elemDropdown: null,
  elemHeader: null,
  elemBody: null,
  elemFooter: null,
  setElemDropdown: () => {},
  setElemHeader: () => {},
  setElemBody: () => {},
  setElemFooter: () => {},
  setPillButtonContainer: () => {},
  setInfiniteListActualBodyHeight: () => {},
  getInfiniteListItemVariableHeight: () => 0,
});

export const FilterDropdownStructureProvider: React.FC = ({ children }) => {
  const [elemDropdown, setElemDropdown] = React.useState<HtmlDivElement>(null);
  const [elemHeader, setElemHeader] = React.useState<HtmlDivElement>(null);
  const [elemBody, setElemBody] = React.useState<HtmlDivElement>(null);
  const [elemFooter, setElemFooter] = React.useState<HtmlDivElement>(null);
  const [pillButtonContainer, setPillButtonContainer] = React.useState<
    HtmlDivElement
  >(null);
  const [
    leftPositionForOverflowingDropdowns,
    setLeftPositionForOverflowingDropdowns,
  ] = React.useState<number | undefined>();

  // the preferred height for list dropdowns, dynamically calculated based on various factors
  const [
    listDropdownBodyIdealHeight,
    setListDropdownBodyIdealHeight,
  ] = React.useState(DEFAULT_DROPDOWN_BODY_HEIGHT);

  // optional params. set the full height of the dropdown manually
  // value will be compared when determining `listDropdownBodyIdealHeight`
  // (this is only relevant to list dropdowns with windowed views)
  const [
    infiniteListActualBodyHeight,
    setInfiniteListActualBodyHeight,
  ] = React.useState<number>();
  const { didOpen, isVisible } = React.useContext(FilterDropdownContext);

  // calculate the body dropdown height based on the dropdown window position
  // this callback sets the `infiniteListBodyHeight` state var
  // this can optionally be used by other dropdowns
  // optionally, child dropdowns that render lists can call setInfiniteListActualBodyHeight()
  React.useLayoutEffect(() => {
    const defaultEmptyRect = { top: 0, height: 0 };
    const windowHeight = window.innerHeight;

    // get overall dropdown dimensions
    const { top: containerTop } =
      elemDropdown?.getBoundingClientRect() || defaultEmptyRect;

    // get header/body/footer dimensions
    // child components are identified with a `scrollableDropdown*` class
    // header and/or footer may be undefined for some dropdowns
    const { height: headerHeight } =
      elemHeader?.getBoundingClientRect() || defaultEmptyRect;
    const { height: footerHeight } =
      elemFooter?.getBoundingClientRect() || defaultEmptyRect;

    // calculate the available heights
    const availableHeight = windowHeight - containerTop;
    const bodyHeightToMaintainMinimumBottomGap =
      availableHeight - headerHeight - footerHeight - MIN_DROPDOWN_BOTTOM_GAP;

    // choose the largest body height value
    const maxBodyHeight = Math.max(
      DEFAULT_DROPDOWN_HEIGHT,
      bodyHeightToMaintainMinimumBottomGap
    );

    // the final body height will be the lesser of these two
    setListDropdownBodyIdealHeight(
      infiniteListActualBodyHeight === undefined
        ? maxBodyHeight
        : Math.min(infiniteListActualBodyHeight, maxBodyHeight)
    );
  }, [
    elemDropdown,
    elemHeader,
    elemFooter,
    elemBody,
    infiniteListActualBodyHeight,
    didOpen,
  ]);

  // approximate the number of pixels in height of a list item element
  // given only the text of the item content
  // this allows us to have variable-height list items in the react-window
  // the final value will depend on the css styles as defined in...
  // src/App/Program/Main/Insight/components/Filters/Filters.module.css.filterDropdownBodyListItem
  // ^ if these values change, then it is necessary to update the values in this function
  const getInfiniteListItemVariableHeight = (text: string): number => {
    const lineHeight = 22;
    const charactersPerLine = 22;
    const paddingHeight = 12;
    const lineHeightOverflowTruncation = 2;
    const words = text.split(' ');
    let numLines = 1;
    let remaining = charactersPerLine;

    words.forEach((word) => {
      if (word.length < remaining) {
        remaining = remaining - word.length - 1;
      } else {
        remaining = charactersPerLine - word.length - 1;
        numLines += 1;
      }
    });
    return (
      (lineHeight - lineHeightOverflowTruncation) * numLines + paddingHeight
    );
  };

  // determine if the dropdown is overflowing to the right
  React.useLayoutEffect(() => {
    if (!isVisible || !didOpen) return;

    const dropdownRect = elemDropdown?.getBoundingClientRect();
    const pillButton = pillButtonContainer?.getBoundingClientRect();
    if (!dropdownRect || !pillButton) return;

    const pillButtonRightInnerMarginToWindow =
      window.innerWidth - pillButton.right;

    // dropdown width exceeds window bounds
    if (dropdownRect.width > pillButtonRightInnerMarginToWindow) {
      // calculate the `left` position for the dropdown to be within the window
      // note: this should be set at the `left` property and should be negative
      // 30px accounts for scroll bar and extra margin
      const pillButtonLeftToWindow = window.innerWidth - pillButton.left;
      const newLeftPosition = pillButtonLeftToWindow - dropdownRect.width - 30;
      setLeftPositionForOverflowingDropdowns(newLeftPosition);
    } else {
      // undefined means do not change the `left` position
      setLeftPositionForOverflowingDropdowns(undefined);
    }
  }, [
    elemDropdown,
    pillButtonContainer,
    setLeftPositionForOverflowingDropdowns,
    isVisible,
    didOpen,
  ]);

  return (
    <FilterDropdownStructureContext.Provider
      value={{
        elemDropdown,
        elemHeader,
        elemBody,
        elemFooter,
        setElemDropdown,
        setElemHeader,
        setElemBody,
        setElemFooter,
        setPillButtonContainer,
        infiniteListBodyHeight: listDropdownBodyIdealHeight,
        getInfiniteListItemVariableHeight,
        setInfiniteListActualBodyHeight,
        leftPositionForOverflowingDropdowns,
      }}
    >
      {children}
    </FilterDropdownStructureContext.Provider>
  );
};
