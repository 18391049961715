import * as React from 'react';
import { Content } from 'models/content';
import { Poll } from 'models/poll';
import { ReachByContentId } from 'hooks/reach';
import { PollBanner } from './PollBanner';
import { ContentBanner } from './ContentBanner';

export const ContentOrPollBanner: React.FC<{
  item: Content | Poll;
  reachByContent: ReachByContentId;
  disableCommentsLinks?: boolean;
  disableContentActions?: boolean;
  isSelectable?: boolean;
  processingState?: string;
}> = ({
  item,
  reachByContent,
  disableCommentsLinks,
  disableContentActions,
  isSelectable = true,
  processingState,
}): React.ReactElement => {
  if (item.type === 'content_planner') {
    return (
      <ContentBanner
        key={item.id}
        content={item}
        reach={reachByContent[item.id]}
        disableCommentsLinks={disableCommentsLinks}
        disableContentActions={disableContentActions}
        isSelectable={isSelectable}
        processingState={processingState}
      />
    );
  }
  if (item.type === 'poll') {
    return <PollBanner key={item.id} poll={item} />;
  }
  throw new Error(`Cannot render Content List item ${JSON.stringify(item)}`);
};
