import React from 'react';

import { useProgram } from 'contexts/program';
import { ClickDropdown } from 'DesignSystem/Components';
import * as Text from 'DesignSystem/Typography';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useCaptionsLanguagesQuery } from 'hooks/video';
import { LanguageControl } from 'models/language-controls/language-control';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { SearchInput } from 'shared/SearchInput';
import styles from './language-control-edit-page.module.css';

type LanguageSelectProps = {
  value?: string;
  onChange: (data: Partial<LanguageControl>) => void;
};

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  value,
  onChange,
}) => {
  const [search, setSearch] = React.useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = React.useState<
    string | undefined
  >(value);

  const { id: programId } = useProgram();
  const { data: captionTranslationFeatureFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CaptionTranslation'
  );
  const { data: awsTranslationLanguages } = useCaptionsLanguagesQuery({
    programId,
    enabled: !!captionTranslationFeatureFlag?.value,
  });

  const availableLanguages = React.useMemo<Record<string, string>>(
    () =>
      awsTranslationLanguages?.reduce(
        (memo, { languageCode, languageName }) => ({
          ...memo,
          [languageCode]: languageName,
        }),
        {}
      ) ?? {},
    [awsTranslationLanguages]
  );

  React.useEffect(() => {
    if (
      typeof selectedLanguage === 'undefined' ||
      !availableLanguages[selectedLanguage]
    ) {
      return;
    }

    setSearch(availableLanguages[selectedLanguage]);
  }, [availableLanguages, selectedLanguage]);

  const rowIds =
    awsTranslationLanguages
      ?.filter(({ languageCode, languageName }) => {
        const lowerCaseSearch = search.toLowerCase();
        return (
          languageCode.includes(lowerCaseSearch) ||
          languageName.toLowerCase().includes(lowerCaseSearch)
        );
      })
      .map(({ languageCode }) => languageCode) ?? [];

  const renderRow = (rowId: string) => (
    <Text.Body>{availableLanguages[rowId]}</Text.Body>
  );

  const dropdownRenderProp = (dismiss: () => void) => (
    <InfiniteSelect
      rowIds={rowIds}
      rowRenderProp={renderRow}
      maxHeight={240}
      itemHeight={40}
      selectedIds={selectedLanguage ? [selectedLanguage] : []}
      onSelectedIdsChange={() => {}}
      onHandleChange={(id) => {
        setSelectedLanguage(id);
        onChange({ locale: id });
        dismiss();
      }}
      multiSelect={false}
      itemClassName={styles.dropdownItem}
      clearDisabled
    />
  );

  return (
    <ClickDropdown
      dropdownRenderProp={dropdownRenderProp}
      autoUpdate
      referencePress={false}
      ignoreKeys
    >
      <div>
        <SearchInput
          placeholder="Select a source language"
          value={search}
          onChange={setSearch}
          inputClassName={styles.searchInput}
        />
      </div>
    </ClickDropdown>
  );
};
