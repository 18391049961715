import { Workflow } from 'models/workflows';
import { MutationOptions } from 'hooks/common';
import {
  archiveWorkflow,
  copyWorkflow,
  startDraftWorkflow,
} from 'services/api-workflows';

export const useArchiveWorkflow = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Workflow> = {}
): {
  archive: (workflowId: number) => void;
} => {
  const archive = (workflowId: number) => {
    archiveWorkflow(programId, workflowId)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { archive };
};

export const useCopyWorkflow = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Workflow> = {}
): {
  copy: (workflowId: number) => void;
} => {
  const copy = (workflowId: number) => {
    copyWorkflow(programId, workflowId)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { copy };
};

export const useStartDraftWorkflow = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Workflow> = {}
): {
  startDraft: (workflowId: number) => void;
} => {
  const startDraft = (workflowId: number) => {
    startDraftWorkflow(programId, workflowId)
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { startDraft };
};
