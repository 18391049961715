import { useCallback } from 'react';
import { useImageDataMutator } from './useImageDataMutator';

export function useImageFields({
  changeImage,
  image,
}: ReturnType<typeof useImageDataMutator>): typeof image & {
  changeAltText(altText: string): void;
  changeHref(href: string): void;
  changeOpenInNewTab(openInNewTab: boolean): void;
} {
  const changeAltText = useCallback(
    (altText: string) => changeImage({ altText }),
    [changeImage]
  );
  const changeHref = useCallback((href: string) => changeImage({ href }), [
    changeImage,
  ]);
  const changeOpenInNewTab = useCallback(
    (openInNewTab: boolean) => changeImage({ openInNewTab }),
    [changeImage]
  );
  return { ...image, changeAltText, changeHref, changeOpenInNewTab };
}
