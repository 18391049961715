import * as React from 'react';
import { uniqueId } from '../useUniqueId';
import { attemptInterval } from '../attempts';
import { useHighlightPopup } from './useHighlightPopup';
import { InclusiveLanguageEvents } from './types';
import { useTextAnalyzer, Analyzer } from './useTextAnalyzer';

export class Highlighter {
  static DATA_ATTR = 'data-inclusive-language-marker';

  static JS_ATTR = 'inclusiveLanguageMarker';

  constructor(
    readonly analyzer: Analyzer,
    readonly rootSelector: string,
    private annotated = false
  ) {}

  clear(el: Element): void {
    // Unlike `show`, don't return early.
    // Then this function can be used to clean up anything that somehow snuck
    // in during an autosave or something.
    this.annotated = false;

    el.querySelectorAll<HTMLSpanElement>(`[${Highlighter.DATA_ATTR}]`).forEach(
      (span) => {
        span.replaceWith(span.textContent || '');
      }
    );
    el.normalize(); // merge text nodes
  }

  show(el: Element): void {
    if (this.annotated) return;
    this.annotated = true;

    const textNodes = document.createNodeIterator(el, NodeFilter.SHOW_TEXT);
    // eslint-disable-next-line
    while (true) {
      const textNode = textNodes.nextNode();
      if (!textNode || textNode.parentElement?.dataset[Highlighter.JS_ATTR]) {
        break;
      }
      attemptInterval(
        () => {
          if (!this.analyzer.ready()) return false;
          this.analyzer
            .suggestions(textNode.textContent)
            .reverse() // handle from back to front so text offsets remain correct
            .forEach((match) => {
              const span = document.createElement('span');
              const markerId = uniqueId();
              span.dataset.inclusiveLanguageMarker = markerId;
              const selector = `[${Highlighter.DATA_ATTR}=${markerId}]`;
              // on mousedown so plugins like froala, et. al. can prevent focus
              span.addEventListener('mousedown', (event) => {
                InclusiveLanguageEvents.show({
                  event,
                  edit: {
                    term: match.term,
                    suggestion: match.suggestion,
                    explanation: match.explanation,
                  },
                  marker: {
                    type: 'content',
                    selector,
                    root: this.rootSelector,
                  },
                });
              });
              const range = document.createRange();
              range.setStart(textNode, match.offset);
              range.setEnd(textNode, match.offset + match.term.length);
              range.surroundContents(span);
            });
          return true;
        },
        {
          maxAttempts: 100,
          intervalWait: 500,
        }
      );
    }
  }
}

export function useContentEditableHighlighter(
  programId: number,
  rootSelector: string
): Highlighter {
  useHighlightPopup(); // adds some global event handlers for handling the suggestions
  const analyzer = useTextAnalyzer(programId);
  const [highlighter] = React.useState(new Highlighter(analyzer, rootSelector));
  return highlighter;
}
