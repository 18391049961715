import React from 'react';
import cx from 'classnames';
import layout from 'DesignSystem/Layout/layout.module.css';
import { JourneyListItem } from './useJourneyList';
import styles from './journey-content-list-drawer.module.css';

export type Tab = {
  label: string;
  id: JourneyListItem['type'];
};

type ContentListTabsProps = {
  tabs: Tab[];
  activeId: string;
  onSelectTab: (tabId: Tab['id']) => void;
  className?: string;
};

export const ContentListTabs: React.FC<ContentListTabsProps> = ({
  tabs,
  activeId,
  onSelectTab,
  className,
}) => (
  <ul className={cx([layout.Tabs, className])}>
    {tabs?.map((tab) => (
      <li key={tab.id}>
        <button
          type="button"
          className={cx(layout.TabButton, styles.contentListTabButton, {
            [layout.TabButtonActive]: tab.id === activeId,
          })}
          onClick={() => onSelectTab(tab.id)}
        >
          {tab.label}
        </button>
      </li>
    ))}
  </ul>
);
