import { useQuery } from 'react-query';
import {
  CommentsAnalysis,
  fetchCommentsAnalysis,
} from '../services/api-comments-analysis';
import { QueryResponse } from './common';
import { useProgram } from '../contexts/program';

type UseCommentsAnalysisQueryProps = {
  contentId: number;
  usePercentage?: boolean;
  enabled?: boolean;
};

export function useCommentsAnalysisQuery({
  contentId,
  usePercentage,
  enabled,
}: UseCommentsAnalysisQueryProps): QueryResponse<CommentsAnalysis> {
  const { id: programId } = useProgram();

  return useQuery(
    ['comments-analysis', { contentId, programId, usePercentage }],
    () => fetchCommentsAnalysis({ contentId, programId, usePercentage }),
    { enabled }
  );
}
