import { useDesign } from 'contexts/design';
import { CloudStatus } from '../JourneyCanvasHeader/useJourneyPersistenceStatus';

export const useDesignPersistenceStatus = (): {
  cloudStatus: CloudStatus;
} => {
  const designContext = useDesign();
  const {
    status: { isModified },
  } = designContext;

  let status = CloudStatus.NotSaved;

  if (isModified) {
    status = CloudStatus.OutOfDate;
  } else {
    status = CloudStatus.UpToDate;
  }
  return { cloudStatus: status };
};
