import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { ContentFiltersProvider } from 'contexts/content/filters';
import { WithPermission } from 'shared/WithPermission';
import { CalendarPage } from './CalendarPage';
import { AdminPreferencesProvider } from '../../../../contexts/admin-preferences';

export const Calendar: React.FC<RouteComponentProps> = () => {
  return (
    <ContentFiltersProvider>
      <AdminPreferencesProvider>
        <WithPermission permissions={['calendarAccess']}>
          <CalendarPage />
        </WithPermission>
      </AdminPreferencesProvider>
    </ContentFiltersProvider>
  );
};
