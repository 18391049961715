import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';
import { WithPermission } from 'shared/WithPermission';

export const Advocacy: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['configureAdvocacyAccess']}>
    <BossViewPage
      title="Advocacy"
      src="#basics"
      app="programs"
      returnUrls={['/configure/advocacy']}
    />
  </WithPermission>
);
