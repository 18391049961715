import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { TopicTag } from 'models/topic-tag';
import { EmptyablePage, Page } from './common';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type FetchProps = {
  programId: number;
  query?: string;
  page?: number;
  pageSize?: number;
  statuses?: string[];
};

export type TopicTagsCollectionData = Page<TopicTagData>;
export type TopicTagsEmptyableCollectionData = EmptyablePage<TopicTagData>;

export type TopicTagData = {
  id: string;
  type: string;
  attributes: TopicTag;
};

export const fetchTopicTags = async (
  props: FetchProps
): Promise<TopicTagsCollectionData> => {
  const { programId, query, page, pageSize, statuses } = props;

  const q = qs.stringify(snakecaseKeys({ query, page, pageSize, statuses }), {
    arrayFormat: 'brackets',
  });
  const url = `${apiRoot}/samba/programs/${programId}/channel_tags?${q}`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching topic tags: ${response.status}`);
};
