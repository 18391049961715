import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Icon } from 'shared/Icon';
import { useProgram } from 'contexts/program';
import { DefaultLoading } from 'shared/InfiniteList/DefaultLoading';
import { useFlashMessage } from 'contexts/flasher';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { PermissionDeniedScreen } from 'shared/PermissionDeniedScreen';
import { useDesignsQuery } from 'hooks/design';
import { DesignBanner } from './DesignBanner';

const Component: React.FC = () => {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();
  const handleError = React.useCallback(() => {
    setFlashMessage({
      message: 'Unable to fetch Designs',
      severity: 'error',
    });
  }, [setFlashMessage]);
  const { isLoading, isSuccess, data: designs } = useDesignsQuery({
    programId,
    onError: handleError,
  });

  return (
    <ListPage
      title="Designs"
      breadcrumbs={[{ label: 'Designs' }]}
      actions={[
        {
          label: 'Design',
          to: 'edit/new/design',
          icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
        },
      ]}
    >
      {isLoading ? (
        <DefaultLoading />
      ) : (
        <div>
          <ul>
            {isSuccess &&
              designs?.map((design) => (
                <DesignBanner design={design} programId={programId} />
              ))}
          </ul>
        </div>
      )}
    </ListPage>
  );
};

export const DesignList: React.FC<RouteComponentProps> = () => {
  const {
    permissions: { designsAccess },
  } = usePermissions();
  const { id: programId } = useProgram();

  const {
    isLoading: isDesignFlagLoading,
    data: designsEnabled,
  } = useFeatureFlagsQuery(programId, 'Studio.Publish.Designs');

  if (!designsAccess || (!isDesignFlagLoading && !designsEnabled?.value))
    return <PermissionDeniedScreen />;

  return <Component />;
};
