import React from 'react';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { ImageData } from 'models/image';
import { Source } from 'models/source';
import { Action } from 'components/publisher/blocks/forms/fields/Gallery/hooks/useGalleryDataMutator';
import { Form } from '../Editor/Form';
import styles from '../../../../editing.module.css';
import { UpdateOpts } from '../../../useEditor';

type CurrentData = {
  image?: ImageData;
  source?: Source;
  action?: Action['type'];
};

type CurrentEvents = {
  onUpdate: (image: ImageData, opts?: UpdateOpts) => void;
  onRemove: () => void;
  onApply: () => void;
  onReset: () => void;
};

export const Current: React.FC<CurrentData & CurrentEvents> = ({
  image,
  source,
  onUpdate,
  onRemove,
  onApply,
  onReset,
  action,
}) => {
  const handleOnUpdate = React.useCallback(
    (data?: ImageData, opts?: UpdateOpts) => {
      if (data) {
        onUpdate(data, opts);
      }
    },
    [onUpdate]
  );

  return !image ? null : (
    <SlideIn name="publisher-block-images-image-slide-in">
      {(slideOut: (fn: () => void) => void) => (
        <div className={styles.layout}>
          <aside>
            <h2>{action === 'insert' ? 'Add' : 'Edit'} Image</h2>
            <button
              type="button"
              className={styles.applyButton}
              onClick={() => {
                slideOut(onApply);
              }}
            >
              Done
            </button>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={() => {
                slideOut(onReset);
              }}
            >
              Cancel
            </button>
          </aside>
          <div className={styles.main}>
            <Form
              initialSource={source}
              image={image}
              onRemove={onRemove}
              onUpdate={handleOnUpdate}
            />
          </div>
        </div>
      )}
    </SlideIn>
  );
};
