import { createContext } from 'react';
import { Audience } from 'models/audience';
import { BoxIntegrationData } from 'models/box-integration';

type BoxFolderFormContextType = {
  name: string;
  description: string;
  audiences: Audience[];
  boxFolder?: BoxIntegrationData;
  savedBoxFolderName?: string;
  onNameChange: (name: string) => void;
  onDescriptionChange: (description: string) => void;
  onAudiencesChange: (audiences: Audience[]) => void;
  onBoxFolderChange: (folder?: BoxIntegrationData) => void;
  isCheckingBoxFolderConflict?: boolean;
  boxFolderErrorMessage?: string;
};

export const BoxFolderFormContext = createContext<BoxFolderFormContextType>({
  name: '',
  description: '',
  audiences: [],
  boxFolder: undefined,
  savedBoxFolderName: undefined,
  onNameChange: () => {},
  onDescriptionChange: () => {},
  onAudiencesChange: () => {},
  onBoxFolderChange: () => {},
  isCheckingBoxFolderConflict: undefined,
  boxFolderErrorMessage: undefined,
});
