import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import {
  useActivitiesInfiniteQuery,
  useActivityDownload,
} from 'hooks/activities';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { download } from 'utility/download';
import { WithPermission } from 'shared/WithPermission';
import { Activity } from 'models/activity';
import { Icon } from 'shared/Icon';
import { ConfirmationContext } from 'contexts/confirmation';
import { useFlashMessage } from 'contexts/flasher';
import {
  useFilterDropdownQueryString,
  useGenericFilterDropdownQueryString,
} from 'hooks/useFilterDropdownQueryString';
import {
  defaultDateRangeInputFormat,
  formatDateRangetoDB,
} from 'shared/DateRange/date-range-db-format';
import { ListHeaderBar } from './ListHeaderBar';
import { FiltersBar } from './FilterBar';
import { ActivityBanner } from './ActivityBanner';
import styles from './activities.module.css';

export const ActivitiesListPage: React.FC<RouteComponentProps> = () => {
  const [dateRange, setDateRange] = useGenericFilterDropdownQueryString<
    [string, string]
  >({
    key: 'date',
    defaultValue: [
      DateTime.now().minus({ month: 1 }).toFormat(defaultDateRangeInputFormat),
      DateTime.now().toFormat(defaultDateRangeInputFormat),
    ],
  });
  const [users, setUsers] = useFilterDropdownQueryString('users');
  const { setFlashMessage } = useFlashMessage();
  const [sort, setSort] = React.useState({});
  const { showConfirmation } = React.useContext(ConfirmationContext);
  const { mutate, isLoading } = useActivityDownload({
    onSuccess: (data: Blob) => {
      download(data, 'activity.csv');
    },
    onError: () => {
      setFlashMessage({
        message: 'There was an error downloading the file. Try again',
        severity: 'error',
      });
    },
  });

  const activitiesFilters = React.useMemo(() => {
    const [start, end] = formatDateRangetoDB(dateRange);
    return { identity: users.map((id) => `user:${id}`), start, end };
  }, [users, dateRange]);
  const activitiesInfiniteQuery = useActivitiesInfiniteQuery(activitiesFilters);
  const onSort = (name: string, dir: 'asc' | 'desc') => {
    setSort({ name, dir });
  };

  const filterChangeCallbacks: { [key: string]: (values: string[]) => void } = {
    users: setUsers,
    date: setDateRange as () => void,
  };

  function onFilterChange(filterName: string, values: string[]) {
    filterChangeCallbacks[filterName](values);
  }

  const handleDownload = React.useCallback(async () => {
    showConfirmation({
      onConfirm: () => {
        mutate(activitiesFilters);
      },
      headerTitle: 'Download CSV',
      customTitle: 'A maximum of 1000 activities will be exported.',
      hideLabel: true,
    });
  }, [showConfirmation, mutate, activitiesFilters]);

  return (
    <WithPermission
      permissions={['confContentAccess', 'configureActivityFeedAccess']}
    >
      <div className={styles.List}>
        <ListPage
          title="Activity Feed"
          breadcrumbs={[
            { to: '..', label: 'Configure' },
            { label: 'Activity Feed' },
          ]}
          actions={[
            {
              icon: <Icon iconName="Download" iconType="SVG" useCurrentColor />,
              onClick: handleDownload,
              label: 'Download CSV',
              disabled: isLoading,
            },
          ]}
          loading={<></>}
          listHeaderBar={<ListHeaderBar sort={sort} onSort={onSort} />}
          infiniteQuery={activitiesInfiniteQuery}
          renderRow={(item: Activity, index) => (
            <ActivityBanner activity={item} index={index} />
          )}
          emptyList={<div>No Activities match that criteria.</div>}
          filterbar={
            <FiltersBar
              onFilterChange={onFilterChange}
              dateFilter={dateRange}
              users={users}
            />
          }
        />
      </div>
    </WithPermission>
  );
};
