import { useQuery } from 'react-query';
import { CampaignVariable } from 'models/campaign-variable';
import {
  fetchAllCampaignVariableConfigurations,
  updateCampaignVariableConfigurations,
} from 'services/api-campaign-variables';
import { useProgram } from 'contexts/program';
import { QueryResponse, MutationOptions } from './common';

export type FetchProps = {
  onError?: (error: Error) => void;
};

export const useCampaignVariableConfigurationsQuery = (
  props: FetchProps = {}
): QueryResponse<Array<CampaignVariable>> => {
  const { id: programId } = useProgram();
  const { isLoading, error, data } = useQuery<Array<CampaignVariable>, Error>({
    queryKey: `campaign-variables-${programId}`,
    queryFn: () => fetchAllCampaignVariableConfigurations(programId),
    cacheTime: 60000,
    onError: props.onError,
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useUpdateCampaignVariableConfigurations = ({
  onSuccess,
  onError,
}: MutationOptions<string, Error> = {}): {
  update: (variables: CampaignVariable[]) => void;
} => {
  const { id: programId } = useProgram();
  const update = (variables: CampaignVariable[]) => {
    updateCampaignVariableConfigurations(programId, variables)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { update };
};
