import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFlashMessage } from '../contexts/flasher';

type UseFileUploadProps = {
  shouldClear?: boolean | number;
  aspectRatio?: number;
  onFileSelect: (file: File[]) => void;
};

type UseFileUploadResult = {
  setFileUploadRef: (ref: HTMLInputElement) => void;
  handleButtonClick: () => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function useFileUpload({
  shouldClear,
  aspectRatio,
  onFileSelect,
}: UseFileUploadProps): UseFileUploadResult {
  const [fileUploadRef, setFileUploadRef] = useState<HTMLInputElement>();

  const { setFlashMessage } = useFlashMessage();

  const handleButtonClick = useCallback(() => {
    fileUploadRef?.click();
  }, [fileUploadRef]);

  useEffect(() => {
    if (shouldClear && fileUploadRef?.value) {
      fileUploadRef.value = '';
    }
  }, [fileUploadRef, shouldClear]);

  const showWarnings = useCallback(
    (file: File) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const imageAspectRatio = img.width / img.height;
        // give the user some wiggle room
        if (
          aspectRatio &&
          (imageAspectRatio > aspectRatio + 0.1 ||
            imageAspectRatio < aspectRatio - 0.1)
        ) {
          setFlashMessage({
            severity: 'warning',
            message: `Please use ${aspectRatio}:1 aspect ratio image for the best result`,
          });
        }
      };
    },
    [aspectRatio, setFlashMessage]
  );

  const handleFileChange = useCallback<
    (event: ChangeEvent<HTMLInputElement>) => void
  >(
    (event) => {
      if (event.currentTarget.files?.length) {
        const files = event.currentTarget.files
          ? Array.from(event.currentTarget.files)
          : [];
        files.forEach(showWarnings);
        onFileSelect(files);
      }
    },
    [onFileSelect, showWarnings]
  );

  return {
    setFileUploadRef,
    handleButtonClick,
    handleFileChange,
  };
}
