import * as React from 'react';
import cx from 'classnames';

type PropsType = {
  name: string;
  className?: string;
  title?: string;
};

export const FAIcon: React.FC<PropsType> = (props) => {
  const { name, className = '', title = '' } = props;

  const iconClass = name === 'x-twitter' ? 'fa-brands' : 'fa';

  return <i className={cx(iconClass, `fa-${name}`, className)} title={title} />;
};
