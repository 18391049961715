import React from 'react';
import { Topic } from '../../../../../../../models/topic';
import { Section } from '../../TopicForm/components/SettingsView/common';
import { Option } from './Option';

interface Props {
  topic: Topic;
}

export function MemberSubmissions({ topic }: Props): JSX.Element {
  return (
    <Section title="Member Submissions">
      <Option
        label="Allow members to submit content"
        value={topic.isUserSubmittable ? 'ON' : 'OFF'}
      />
      {topic.isUserSubmittable && (
        <Option
          label="Auto-publish content from members"
          value={topic.autoPublish ? 'ON' : 'OFF'}
        />
      )}
    </Section>
  );
}
