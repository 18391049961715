import React, { ReactElement } from 'react';
import { Button } from 'DesignSystem/Form';
import { LandingPageTab, LandingPageTabType } from 'models/topic';
import { FieldError } from 'shared/Fieldset/FieldError';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MAIcon } from 'shared/MAIcon';
import { PageControl } from '../PageControl';

type AboutControlProps = {
  tab: LandingPageTab & { tabType: LandingPageTabType.About };
  onChange: (data: LandingPageTab) => void;
  isLoading: boolean;
  isAboutPageEmpty: boolean;
  disabled?: boolean;
  error?: string;
};

AboutControl.defaultProps = {
  disabled: false,
  error: undefined,
};

export function AboutControl({
  tab,
  onChange,
  isLoading,
  isAboutPageEmpty,
  disabled,
  error,
}: AboutControlProps): ReactElement {
  const buttonLabel = isAboutPageEmpty ? 'Create Content' : 'Edit Content';

  return (
    <PageControl
      title="About"
      subtitle="A custom page that can include images, videos, and links "
      tab={tab}
      onChange={onChange}
    >
      <Button
        compact
        icon={
          !isLoading ? (
            <MAIcon
              name="edit_square"
              style={{
                fontSize: 'inherit',
              }}
            />
          ) : undefined
        }
        label={isLoading ? <LoadingSpinner size="small" /> : buttonLabel}
        href="about"
        disabled={disabled}
      />
      {error && <FieldError error={error} />}
    </PageControl>
  );
}
