type BaseLanguageControl<T extends LanguageControlTypes> = {
  id: number;
  programId: number;
  controlType: T;
  locale: string;
  target: string;
  creatorId: number;
  createdAt: string;
};

export const CONTROL_TYPE_DO_NOT_TRANSLATE = 'do_not_translate';
export const CONTROL_TYPE_REMOVE = 'remove';
export const CONTROL_TYPE_REPLACE = 'replace';

type RemoveLanguageControl = BaseLanguageControl<typeof CONTROL_TYPE_REMOVE>;

type ReplaceLanguageControl = BaseLanguageControl<
  typeof CONTROL_TYPE_REPLACE
> & {
  replacement: string;
};

type DoNotTranslateLanguageControl = BaseLanguageControl<
  typeof CONTROL_TYPE_DO_NOT_TRANSLATE
>;

type LanguageControls = {
  [CONTROL_TYPE_REMOVE]: RemoveLanguageControl;
  [CONTROL_TYPE_REPLACE]: ReplaceLanguageControl;
  [CONTROL_TYPE_DO_NOT_TRANSLATE]: DoNotTranslateLanguageControl;
};

export type LanguageControlTypes = keyof LanguageControls;

export type LanguageControl = LanguageControls[LanguageControlTypes];

export type LanguageControlCreator = {
  id: number;
  displayName: string;
};

export const isRemoveLanguageControl = (
  languageControl: RemoveLanguageControl | unknown
): languageControl is RemoveLanguageControl =>
  (languageControl as RemoveLanguageControl).controlType ===
  CONTROL_TYPE_REMOVE;

export const isReplaceLanguageControl = (
  languageControl: ReplaceLanguageControl | unknown
): languageControl is ReplaceLanguageControl =>
  (languageControl as ReplaceLanguageControl).controlType ===
  CONTROL_TYPE_REPLACE;

export const isDoNotTranslateLanguageControl = (
  languageControl: DoNotTranslateLanguageControl | unknown
): languageControl is DoNotTranslateLanguageControl =>
  (languageControl as DoNotTranslateLanguageControl).controlType ===
  CONTROL_TYPE_DO_NOT_TRANSLATE;
