import * as React from 'react';
import cx from 'classnames';
import { Link } from '@reach/router';
import { Checkbox } from 'shared/Checkbox';
import { FlipSwitch } from 'shared/FlipSwitch';
import { TextInput } from 'shared/TextInput';
import { ComponentProps, useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { usePrevious } from 'hooks/usePrevious';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './forms.module.css';

export type BaseButtonPropTypes = {
  className?: string;

  // Visual variations
  compact?: boolean; // reduces padding
  minimal?: boolean; // reduces padding even more
  roundedPadded?: boolean; // Pill Button
  secondary?: boolean; // outlined
  text?: boolean; // textual
  input?: boolean; // input style
  clearText?: boolean; // textual, but with no initial background
  warning?: boolean; // orange
  layoutOnly?: boolean; // effectively transparent, let's the parent style it
  disabled?: boolean;
  isLoading?: boolean;
  focused?: boolean;
  block?: boolean; // make it block
  action?: boolean; // buttons are line-ordered actions
  rounded?: boolean; // fully rounded corners
  round?: boolean; // circular button
  circle?: boolean; // fully circular button
  borderless?: boolean; // with no borders
  href?: string; // link

  // Content
  label?: string | React.ReactNode;
  icon?: string | React.ReactNode;
  badge?: string | React.ReactNode;
  title?: string;
  alignContent?: 'left' | 'right';

  onClick?: (e: React.MouseEvent) => void;

  // DOM Integration
  id?: string;
  openInNewTab?: boolean;

  // Data attributes for pendo and testing
  dataTest?: string;
  dataPendo?: string;
};

type ButtonPropTypes = ComponentProps<'button'> & BaseButtonPropTypes;

const Button = React.forwardRef<HTMLButtonElement, ButtonPropTypes>(
  (
    {
      className,
      id,
      dataTest,
      dataPendo,
      compact,
      minimal,
      roundedPadded,
      secondary,
      text,
      input,
      clearText,
      layoutOnly,
      warning,
      disabled,
      focused,
      block,
      action,
      round,
      rounded,
      circle,
      borderless,
      onClick,
      label,
      title,
      href,
      openInNewTab,
      icon,
      isLoading = false,
      badge,
      alignContent,
      ...props
    }: ButtonPropTypes,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const isDisabled = !!disabled || !!props['aria-disabled'] || isLoading;
    const buttonClasses = cx(styles.Button, {
      [styles.ButtonCompact]: !!compact,
      [styles.ButtonMinimal]: !!minimal,
      [styles.ButtonRoundedPadded]: !!roundedPadded,
      [styles.ButtonDisabled]: isDisabled,
      [styles.ButtonFocused]: !!focused,
      [styles.ButtonSecondary]: !!secondary,
      [styles.ButtonText]: !!text,
      [styles.ButtonInput]: !!input,
      [styles.ButtonLayoutOnly]: !!layoutOnly,
      [styles.ButtonWarning]: !!warning,
      [styles.ButtonLink]: !!href,
      [styles.ButtonBlock]: !!block,
      [styles.ButtonClearText]: !!clearText,
      [styles.ButtonActions]: !!action,
      [styles.ButtonRounded]: !!rounded,
      [styles.ButtonRound]: !!round,
      [styles.ButtonCircle]: !!circle,
      [styles.ButtonBorderLess]: !!borderless,
      [styles.ButtonAlignLeft]: alignContent === 'left',
      [styles.ButtonAlignRight]: alignContent === 'right',
      [`${className}`]: !!className,
    });
    const content = React.useMemo(
      () => (
        <span className={styles.ButtonContent}>
          {icon && (
            <span className={cx('icon', styles.ButtonIcon)}>{icon}</span>
          )}
          {label && (
            <span className={cx(styles.ButtonLabel)}>
              {isLoading ? (
                <>
                  <span className={styles.ButtonSpinner}>
                    <LoadingSpinner size="small" />
                  </span>
                  <span className={cx(styles.LabelHidden)}>{label}</span>
                </>
              ) : (
                label
              )}
            </span>
          )}
          {badge && <span className={styles.ButtonBadge}>{badge}</span>}
        </span>
      ),
      [icon, label, badge, isLoading]
    );
    const ButtonLink = React.useCallback(() => {
      // NOTE: The Link component provided by @reach/router doesn't support opening links
      // on a new tab: https://github.com/reach/router/issues/310#issuecomment-539285299
      // That's why we are using an anchor tag here.
      return openInNewTab ? (
        <a
          id={id}
          className={buttonClasses}
          data-test={dataTest}
          data-pendo={dataPendo}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </a>
      ) : (
        <Link
          id={id}
          className={buttonClasses}
          data-test={dataTest}
          data-pendo={dataPendo}
          title={title}
          to={`${href}`}
        >
          {content}
        </Link>
      );
    }, [
      id,
      dataTest,
      dataPendo,
      href,
      buttonClasses,
      content,
      title,
      openInNewTab,
    ]);

    /* eslint-disable react/jsx-props-no-spreading */
    return href ? (
      <ButtonLink />
    ) : (
      <button
        id={id}
        data-test={dataTest}
        data-pendo={dataPendo}
        title={title}
        type="button"
        onClick={!isDisabled ? onClick : undefined}
        className={buttonClasses}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {content}
      </button>
      /* eslint-enable react/jsx-props-no-spreading */
    );
  }
);
export { Button };

Button.defaultProps = {
  className: undefined,
  id: undefined,
  compact: false,
  roundedPadded: false,
  secondary: false,
  minimal: false,
  text: undefined,
  input: false,
  clearText: false,
  warning: false,
  layoutOnly: false,
  disabled: false,
  focused: false,
  block: false,
  action: false,
  round: false,
  rounded: false,
  circle: false,
  borderless: false,
  label: undefined,
  icon: undefined,
  badge: undefined,
  title: undefined,
  alignContent: undefined,
  openInNewTab: false,
  href: undefined,
  onClick: undefined,
  dataTest: undefined,
  dataPendo: undefined,
};

export function ComboButton<T extends string[]>({
  options,
  value,
  onChange,
}: {
  options: Readonly<T>;
  value: T[number];
  onChange: (newValue: T[number]) => void;
}): React.ReactElement {
  return (
    <div className={styles.ComboButton}>
      {options.map((newValue) => (
        <span key={newValue} data-active={value === newValue}>
          <Button
            compact
            layoutOnly
            label={newValue}
            onClick={() => onChange(newValue)}
          />
        </span>
      ))}
    </div>
  );
}

export type InputType = React.ComponentProps<typeof TextInput> & {
  type?: 'text' | 'number';
  block?: boolean;
  className?: string;
  maxCharacters?: number;
  showCharacterCount?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputType>(
  (
    {
      type: _type,
      block,
      value,
      maxCharacters,
      showCharacterCount = false,
      className = '',
      onChange,
      ...textInputProps
    }: InputType,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [localValue, setLocalValue] = useState<string | undefined>(value);
    const prevValue = usePrevious(value);

    // when a new value is passed through props
    React.useEffect(() => {
      if (value !== prevValue) {
        setLocalValue(value);
      }
    }, [prevValue, value]);

    return (
      <>
        <TextInput
          type={_type}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textInputProps}
          className={cx(className, { 'flex-fill': block })}
          ref={ref}
          value={localValue}
          onChange={(newValue) => {
            if (
              !maxCharacters ||
              (maxCharacters && maxCharacters >= newValue?.length)
            ) {
              if (onChange) onChange(newValue);
              setLocalValue(newValue);
            }
          }}
        />
        {showCharacterCount && (
          <Box className={styles.LetterCount}>
            {`${localValue?.length || 0} / ${maxCharacters} characters`}
          </Box>
        )}
      </>
    );
  }
);

export { Textarea } from 'shared/forms/Textarea';

const Radio = Checkbox;
const Toggle = FlipSwitch;
export { Checkbox, Toggle, Radio };

export const FieldInput: React.FC<{
  legend?: React.ReactNode;
  htmlFor?: string;
  fieldSetClass?: string;
}> = ({ htmlFor, legend, children, fieldSetClass }) => (
  <fieldset className={cx(styles.Fieldset, fieldSetClass)}>
    {htmlFor ? (
      <label htmlFor={htmlFor}>
        {legend && <legend className={styles.Legend}>{legend}</legend>}
        {children}
      </label>
    ) : (
      <>
        {legend && <legend className={styles.Legend}>{legend}</legend>}
        {children}
      </>
    )}
  </fieldset>
);
