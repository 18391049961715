import React, { createContext, useContext } from 'react';
import { AdminPreferences, DefaultAdminPreferences } from '../models/user';
import { useUser } from './user';
import { useUpdateAdminPreferences } from '../hooks/user';

type AdminPreferencesContextData = {
  adminPreferences: AdminPreferences;
  setAdminPreferences: (p: AdminPreferences) => void;
  setHideWeekend: (hw: boolean) => void;
  setUseAccessibilityColors: (hw: boolean) => void;
};

const DefaultAdminPreferencesContext: AdminPreferencesContextData = {
  adminPreferences: DefaultAdminPreferences,
  setAdminPreferences: () => {},
  setHideWeekend: () => {},
  setUseAccessibilityColors: () => {},
};

const AdminPreferencesContext = createContext(DefaultAdminPreferencesContext);

export const useAdminPreferences = (): AdminPreferencesContextData => {
  const context = useContext(AdminPreferencesContext);
  if (context === undefined) {
    throw new Error(
      'Admin preferences context context hooks require a containing AdminPreferencesProvider'
    );
  }
  return context;
};

export const AdminPreferencesProvider: React.FC = ({ children }) => {
  const user = useUser();
  const [adminPreferences, setAdminPreferences] = React.useState<
    AdminPreferences
  >(user.adminPreferences || DefaultAdminPreferences);

  const onUpdateSuccess = React.useCallback((d: AdminPreferences) => {
    setAdminPreferences(d);
  }, []);

  const { update: updateAdminPreferences } = useUpdateAdminPreferences({
    onSuccess: onUpdateSuccess,
  });

  const setHideWeekend = React.useCallback(
    (v: boolean) => {
      const preferences = { ...adminPreferences };
      preferences.hideWeekend = v;
      updateAdminPreferences(preferences);
    },
    [adminPreferences, updateAdminPreferences]
  );

  const setUseAccessibilityColors = React.useCallback(
    (v: boolean) => {
      const preferences = { ...adminPreferences };
      preferences.accessibilityColors = v;
      setAdminPreferences(preferences);
    },
    [adminPreferences]
  );

  const value = {
    adminPreferences,
    setAdminPreferences,
    setHideWeekend,
    setUseAccessibilityColors,
  };
  return (
    <AdminPreferencesContext.Provider value={value}>
      {children}
    </AdminPreferencesContext.Provider>
  );
};
