export type NavigateButtonType = {
  text: string;
  path: string;
};

export type FlashProgressUpdateType = (
  value: number,
  newMessage?: Omit<FlashMessageType, 'messageKey'>
) => void;

export type FlashMessageType = {
  severity: 'none' | 'info' | 'warning' | 'error' | 'progress';
  message: string;
  messageKey?: string;
  details?: string;
  children?: JSX.Element;
  inlineChildren?: boolean;
  timeout?: number | false;
  navigateButton?: NavigateButtonType;
  progress?: {
    interval?: number;
    max?: number;
    defaultProgress?: number;
    update: (callback: FlashProgressUpdateType) => void;
  };
};

export type FlashMessageKeyType = Partial<
  Pick<FlashMessageType, 'messageKey'>
> &
  Pick<FlashMessageType, 'message'>;

export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong. We're taking a look.";

export const EDIT_PERMISSION_WARNING: FlashMessageType = {
  severity: 'warning',
  message: 'Editing Unavailable',
  details:
    'In order to edit this post you must have access to all of the topics and audiences assigned to it',
  timeout: false,
};

export const EDIT_SESSION_WARNING: FlashMessageType = {
  severity: 'warning',
  message:
    'It looks like your session is expired, any future work may not be saved',
  details: 'Try refreshing the page or signing back in to resume',
  timeout: false,
};

export function createFlashMessage(
  message: FlashMessageType['message'],
  details: FlashMessageType['details'] = '',
  timeout: FlashMessageType['timeout'] = 5000,
  severity: FlashMessageType['severity'] = 'none'
): FlashMessageType {
  return { details, message, severity, timeout };
}

export const createFlashWarning: HelperFn = (title, description, timeout) =>
  createFlashMessage(title, description, timeout, 'warning');

export const createFlashInfo: HelperFn = (title, description, timeout) =>
  createFlashMessage(title, description, timeout, 'info');

export const createFlashError: HelperFn = (title, description, timeout) =>
  createFlashMessage(title, description, timeout, 'error');

type HelperFn = (
  title: FlashMessageType['message'],
  description?: FlashMessageType['details'],
  timeout?: FlashMessageType['timeout']
) => FlashMessageType;
