import React, { useState } from 'react';
import { useUniqueId } from 'hooks/useUniqueId';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  Audience,
  canEditInfo,
  setDescription,
  setTagString,
  setTitle,
  tagString,
  getTempTitle,
} from 'models/audience';
import styles from './AudienceInfo.module.css';

export const AudienceInfo: React.FC<{
  audience: Audience;
  setAudience: (audience: Audience) => void;
  isSaved: boolean;
  isSaveAsMode?: boolean;
}> = ({ audience, setAudience, isSaved, isSaveAsMode }) => {
  const { id: programId } = useProgram();

  const { data: permissionsService } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Service'
  );

  const [userTagString, setUserTagString] = useState(tagString(audience));

  const handleChangeTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTagString(e.target.value);
    setAudience(setTagString(audience, e.target.value));
  };
  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAudience(setTitle(audience, e.target.value));
  };
  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAudience(setDescription(audience, e.target.value));
  };
  const uniqueId = useUniqueId(6);
  const handleFocusTitle = (e: React.FocusEvent<HTMLInputElement>) => {
    setAudience(setTitle(audience, getTempTitle(e.target.value, '')));
  };
  const handleUnFocusTitle = (e: React.FocusEvent<HTMLInputElement>) => {
    if (isSaveAsMode)
      setAudience(
        setTitle(
          audience,
          getTempTitle(e.target.value, `Temporary-audience-${uniqueId}`)
        )
      );
  };
  const canEdit = canEditInfo(audience);

  return (
    <div className={styles.container}>
      <div className={styles.inputRow}>
        {canEdit ? (
          <>
            <input
              className={styles.title}
              type="text"
              placeholder="Untitled Audience"
              value={audience.title || ''}
              onFocus={handleFocusTitle}
              onBlur={handleUnFocusTitle}
              onChange={handleChangeTitle}
            />
          </>
        ) : (
          <div className={styles.title}>{audience.title}</div>
        )}
        {isSaved || <div className={styles.unsaved}>Unsaved</div>}
      </div>
      <div className={styles.inputRow}>
        {canEdit ? (
          <input
            className={styles.description}
            type="text"
            placeholder="Add description"
            value={audience.description || ''}
            onChange={handleChangeDescription}
          />
        ) : (
          <div className={styles.description}>{audience.description}</div>
        )}
      </div>
      <div className={styles.inputRow}>
        {canEdit ? (
          <input
            className={styles.tags}
            type="text"
            placeholder="Add tags"
            value={userTagString}
            onChange={handleChangeTags}
          />
        ) : (
          <div className={styles.tags}>{userTagString}</div>
        )}
      </div>
      {permissionsService?.value && (
        <div className={styles.PermissionsNote}>
          The result will be limited to your permissions
        </div>
      )}
    </div>
  );
};
