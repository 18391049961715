import React from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Body } from 'DesignSystem/Typography';
import { Author, hasAvatar } from 'models/author';
import { ProgramAuthor } from 'models/programAuthor';
import { DefaultAvatar } from 'shared/icons';
import styles from './AuthorName.module.css';

interface AuthorNameProps {
  author: Author | ProgramAuthor;
}

const AvatarIcon: React.FC<{ author: Author | ProgramAuthor }> = ({
  author,
}) => (
  <>
    {hasAvatar(author) ? (
      <div
        className={styles.Avatar}
        style={{
          backgroundImage: `url(${author.avatarUrl})`,
        }}
      />
    ) : (
      <div className={styles.Avatar}>
        <DefaultAvatar />
      </div>
    )}
  </>
);

export const AuthorName: React.FC<AuthorNameProps> = ({ author }) => {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <AvatarIcon author={author} />
      <Box margin={[0, 0, 0, 8]}>
        <Body>{author.displayName}</Body>
      </Box>
    </Box>
  );
};
