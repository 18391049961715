import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Button, FieldInput } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import { VideoFieldData } from 'models/donkey';
import { FieldFormProps } from 'components/publisher/blocks/forms/useFieldForm';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { List } from './List';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';
import { useVideoCaption } from '../../hooks/useVideoCaption';
import { CaptionJobs } from './CaptionJobs';
import { CaptionDefaultToggle } from './CaptionDefaultToggle';
import { CaptionStyles } from './CaptionStyles';

export const VideoCaptions: React.FC<{
  fieldData: VideoFieldData;
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  onCaptionUpdated: () => void;
  filename?: string;
  locale?: string;
}> = ({ fieldData, onChange, onCaptionUpdated, filename, locale }) => {
  const {
    fetchedCaptions,
    onListItemEdit,
    onListItemRemove,
    openSettings,
    CaptionSettings,
    isDisabled,
    captionsError,
    defaultShowCaptions,
    toggleDefaultShowCaptions,
    changeCaptionStyles,
    captionsColor,
    captionsColorBackground,
    captionsFontSize,
    captionsPosition,
    isCaptionsUpdating,
  } = useVideoCaption({
    fieldData,
    onChange,
    onCaptionUpdated,
  });

  const {
    isLoadingFeatureFlags,
    isCaptionTranscriptionEnabled,
  } = useCaptionsJobs();

  return (
    <>
      <FieldInput>
        <Box margin={[0, 0, 8, 0]}>
          <Text.FieldLabel>Captions</Text.FieldLabel>
        </Box>
        {isCaptionTranscriptionEnabled && (
          <>
            <CaptionDefaultToggle
              enabled={defaultShowCaptions}
              onToggle={toggleDefaultShowCaptions}
              disabled={!fetchedCaptions.length}
            />
            <CaptionJobs />
          </>
        )}
        <List
          locale={locale}
          captions={fetchedCaptions}
          onEdit={onListItemEdit}
          onRemove={onListItemRemove}
          filename={filename || ''}
          videoId={fieldData.video_id}
          isCaptionsUpdating={isCaptionsUpdating}
        />
        <br />
        <Button
          block
          icon={
            isLoadingFeatureFlags ? (
              <LoadingSpinner size="small" />
            ) : (
              <Text.PageHeading color={Text.color.gray00}>+</Text.PageHeading>
            )
          }
          label="Add Captions"
          onClick={openSettings}
          disabled={isDisabled || isLoadingFeatureFlags}
        />
        {captionsError && (
          <Text.Body color={Text.color.redFull}>{captionsError}</Text.Body>
        )}

        {isCaptionTranscriptionEnabled && (
          <CaptionStyles
            captionsColor={captionsColor}
            captionsColorBackground={captionsColorBackground}
            captionsFontSize={captionsFontSize}
            captionsPosition={captionsPosition}
            disabled={!fetchedCaptions.length}
            onChange={changeCaptionStyles}
          />
        )}

        {CaptionSettings}
      </FieldInput>
    </>
  );
};
