import React from 'react';
import { usePublisher } from 'contexts/publisher';
import { Box } from 'DesignSystem/Components';
import { background, Caption, Body } from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useOrchestrationInsights } from 'hooks/orchestration-insights';
import { ReactComponent as Bolt } from 'shared/icons/Bolt.svg';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { DailyStats } from './DailyStats';
import { SectionCard } from '../SectionCard';
import styles from './projected-delivery.module.css';

const useEngagementCopy = () => {
  const { settings } = useSettings();
  const {
    post: { content },
  } = usePublisher();
  const { maxRetries } = useOrchestrationInsights(
    settings,
    content?.id || undefined
  );

  const { optimizedDeliveryEnabled, retries, deliveryChannels } = settings;

  const chEnabledCount = Object.entries(deliveryChannels)
    .filter(([k, _]) => k !== 'feed')
    .reduce((count, [_, v]) => (v ? count + 1 : count), 0);

  if (optimizedDeliveryEnabled && retries !== 0 && chEnabledCount > 1) {
    return (
      <Body>
        Based on your selections, we will optimize{' '}
        <Body bold>delivery time</Body> and <Body bold>channels</Body> used for
        this campaign, and send a maximum of{' '}
        <Body bold>{maxRetries} retargeting</Body> attempts.
      </Body>
    );
  }

  if (optimizedDeliveryEnabled && retries !== 0 && chEnabledCount === 1) {
    return (
      <Body>
        Based on your selections, we will optimize{' '}
        <Body bold>delivery time</Body> for this campaign, and send a maximum of{' '}
        <Body bold>{maxRetries} retargeting</Body> attempts.
      </Body>
    );
  }

  if (!optimizedDeliveryEnabled && retries !== 0 && chEnabledCount > 1) {
    return (
      <Body>
        Based on your selections, we will optimize <Body bold>channels</Body>{' '}
        used for this campaign, and send a maximum of{' '}
        <Body bold>{maxRetries} retargeting</Body> attempts.
      </Body>
    );
  }

  if (optimizedDeliveryEnabled && retries === 0 && chEnabledCount > 1) {
    return (
      <Body>
        Based on your selections, we will optimize{' '}
        <Body bold>delivery time</Body> and <Body bold>channels</Body> used for
        this campaign. No retargeting attempts will be made.
      </Body>
    );
  }

  if (optimizedDeliveryEnabled && retries === 0 && chEnabledCount === 1) {
    return (
      <Body>
        Based on your selections, we will optimize{' '}
        <Body bold>delivery time</Body> for this campaign. No retargeting
        attempts will be made.
      </Body>
    );
  }

  if (!optimizedDeliveryEnabled && retries !== 0 && chEnabledCount === 1) {
    return (
      <Body>
        Based on your selections, a maximum of{' '}
        <Body bold>{maxRetries} retargeting</Body> attempts will be made.
      </Body>
    );
  }

  if (!optimizedDeliveryEnabled && retries === 0 && chEnabledCount > 1) {
    return (
      <Body>
        Based on your selections, we will optimize <Body bold>channels</Body>{' '}
        used for this campaign. No retargeting attempts will be made.
      </Body>
    );
  }

  return undefined;
};

export const ProjectedDeliverySection: React.FC = () => {
  const {
    post: { content, settings },
  } = usePublisher();
  const { insights } = useOrchestrationInsights(settings, content.id);

  const engagementCopy = useEngagementCopy();

  const willHaveInsights: boolean =
    !!settings.priority && settings.deliveryChannels.feed;

  if (insights.length === 0 && !willHaveInsights) return <></>;

  return (
    <SectionCard marginTop={20} padding={[32, 32]}>
      <>
        <Box style={{ display: 'flex' }}>
          <span className={styles.Title}>Projected Delivery</span>
          {/* We may resurrect it later */}
          {/* <Box margin={[0, 0, 0, 'auto']}>
            <Button
              className={styles.Button}
              secondary
              compact
              label={
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  <span style={{ marginRight: '10px', marginTop: '2.5px' }}>
                    <OpenInNew width={14.17} height={14.17} />
                  </span>
                  How is this calculated?
                </div>
              }
            />
          </Box> */}
        </Box>

        <Box margin={[15, 0, 0, 0]} className={styles.Caption}>
          This section is just an illustration of the initial delivery for your
          campaign. Actual delivery plans may vary.
        </Box>
      </>
      {engagementCopy && (
        <Box
          relative
          margin={[-32, -32]}
          background={background.gray05}
          padding={[16, 24, 1]}
        >
          <Box absolute className={styles.Bolt}>
            <Bolt />
          </Box>
          <Box margin={[0, 0, 25, 35]}>
            <Box margin={[0, 0, 0, 0]}>
              <Box margin={[0, 0, 4, 0]}>
                <Body bold>Engagement Boost</Body>
              </Box>
              <Caption>{engagementCopy}</Caption>
            </Box>
          </Box>
        </Box>
      )}
      {insights.length === 0 ? (
        <Flex>
          <LoadingSpinner />
        </Flex>
      ) : (
        <DailyStats insights={insights} publishedAt={content.publishedAt} />
      )}
    </SectionCard>
  );
};
