import { Avatar } from '@socialchorus/shared-ui-components';
import React, { FC, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useProgram } from 'contexts/program';
import { FieldLabel } from 'DesignSystem/Typography';
import { Topic } from 'models/topic';
import { fetchFollowersCount, fetchMembers } from 'services/api-topics';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';
import { useTopicApiId } from '../../../../../../../useTopicApiId';
import { useTopicFormCtx } from '../../../../../../context';
import { DEFAULT_AVATAR } from '../../../../../common/constants';
import styles from './styles.module.css';

const AVATARS_TO_RENDER = 6;

function NoMembersPartial() {
  return (
    <CardPlaceholder>
      There are no members following this topic yet
    </CardPlaceholder>
  );
}

function Preview({ topic }: { topic: Topic }) {
  const { id: programId } = useProgram();

  const topicId = useTopicApiId(topic);

  const { isLoading: isLoadingFollowers, data } = useQuery(
    `members-${topicId}`,
    () => fetchMembers(programId, topicId)
  );
  const {
    isLoading: isLoadingCount,
    data: followersCount,
  } = useQuery(`members-count-${topicId}`, () =>
    fetchFollowersCount(programId, topicId)
  );

  if (isLoadingFollowers || isLoadingCount) {
    return <LoadingSpinner />;
  }

  if (!data || followersCount === undefined) {
    return null;
  }

  const { data: members } = data;

  if (!members?.length) {
    return <NoMembersPartial />;
  }

  const hasMore = followersCount > AVATARS_TO_RENDER;
  const ViewMoreLink = (
    <div>
      <FieldLabel semibold>
        + {followersCount - AVATARS_TO_RENDER} more
      </FieldLabel>
    </div>
  );

  return (
    <div className={styles.avatarListWrapper}>
      <div className={styles.avatarList}>
        {members.slice(0, AVATARS_TO_RENDER).map((member) => (
          <div key={member.id} className={styles.avatarImg}>
            <Avatar
              alt={member.name}
              size="medium"
              shape="circle"
              imgSrc={member.avatarUrl || DEFAULT_AVATAR}
            />
          </div>
        ))}
      </div>
      {hasMore && ViewMoreLink}
    </div>
  );
}

const MembersPreview: FC = () => {
  const { topic, selectTab } = useTopicFormCtx();
  let content;
  if (!topic) {
    content = <NoMembersPartial />;
  } else {
    content = <Preview topic={topic} />;
  }

  const handleViewAll = useCallback(() => {
    selectTab('members');
  }, [selectTab]);
  const ViewAllLink = <CardLink onClick={handleViewAll}>View all</CardLink>;

  return (
    <PreviewCard title="Members" link={ViewAllLink}>
      {content}
    </PreviewCard>
  );
};

export default MembersPreview;
