import { UnitValue } from '../duration';

export type TimeUnit = 'd' | 'w' | 'M' | 'y' | 'q';
export type AdvanceQueryBuilderUnit = Exclude<TimeUnit, 'q'>;

export type RangeBoundaries = {
  before_beginning: string;
  after_ending: string;
  since_ending: string;
};

export function getRangeBoundaries(forceIgnoreYear: boolean): RangeBoundaries {
  if (forceIgnoreYear) {
    return {
      before_beginning: '01-01',
      after_ending: '12-31',
      since_ending: '12-31',
    };
  }
  return {
    before_beginning: '*',
    after_ending: '*',
    since_ending: '"now/d"',
  };
}

export function isDate(value: string | undefined): boolean {
  return !!(value || '').match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
}

export function isIgnoreYearDate(value: string | undefined): boolean {
  return !!(value || '').match(/^[0-9]{2}-[0-9]{2}$/);
}

export const units: Record<TimeUnit, UnitValue> = {
  d: 'day',
  w: 'week',
  M: 'month',
  y: 'year',
  q: 'quarter',
};

export function decodeRelativeDate(
  value: string | undefined,
  future: boolean
): string | undefined {
  const match = (value || '').match(/^((now[-+])([0-9]+)([dwMy])\/d|[*])$/);
  const [, , sign = '', quantity = '', unit = ''] = match || [];
  if (!match) {
    return undefined;
  }
  if (
    sign &&
    (!units[unit as AdvanceQueryBuilderUnit] ||
      (future && sign === '-') ||
      (!future && sign === '+'))
  ) {
    return undefined;
  }
  return `${quantity || '*'} ${units[unit as AdvanceQueryBuilderUnit] || ''}`;
}

export function decodeIgnoreYearDate(
  value: string | undefined
): string | undefined {
  //  const match = (value || '').match(/^[0-9]{2}-[0-9]{2}$/);
  return isIgnoreYearDate(value) ? `${value}:ignore_year` : value;
}

export function isRelativeDate(
  value: string | undefined,
  future: boolean
): boolean {
  return decodeRelativeDate(value, future) !== undefined;
}

export function isToday(value: string | undefined): boolean {
  return value === 'now/d';
}
