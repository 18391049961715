// Obviously this is a subset of the attributes above that really do come from the server.
// This is sufficient for POC for now as it's all we're displaying in the UI so far.
export type ExternalSource = {
  active: boolean;
  alias?: string;
  autoPublish: boolean;
  id: number;
  identifier: string;
  isShareable: boolean;
  type: string;
  appendedHashtagEnabled: boolean;
  appendedHashtag?: string;
  lastHarvestedAt?: string;
  contentChannels: { id: number; name: string }[];
  contentChannelIds: number[];
  contentTemplateId: number | null;
  displayInternalContent: boolean;
  programId?: number;
};

export const validateExternalSource = (
  externalSource: Partial<ExternalSource>
): string | undefined => {
  const { identifier } = externalSource;
  if (
    externalSource.type === 'rss' &&
    (!identifier || identifier.trim() === '')
  ) {
    return 'URL is required';
  }

  if (!identifier || identifier.trim() === '') {
    return '"X" username is required';
  }

  if (
    !externalSource.contentChannels ||
    externalSource.contentChannels.length < 1
  ) {
    return 'Channel is required';
  }

  return undefined;
};
