import React from 'react';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { RouteComponentProps } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteList } from 'DesignSystem/Layout/ListContainers';
import { useProgram } from 'contexts/program';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useGenericFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { useAuthorAliasesInfiniteQuery } from 'hooks/author-alias';
import { useDebounce } from 'hooks/useDebounce';
import { AuthorAliasBanner } from './AuthorAliasBanner';
import { FiltersBar } from './FilterBar';
import { WithBulkSelect } from './WithBulkSelect';

export const AuthorAliasesListPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();

  const [
    selectedStatuses,
    setSelectedStatuses,
  ] = useGenericFilterDropdownQueryString<string[] | undefined>({
    key: 'statuses',
    defaultValue: ['active'],
  });

  const [selectedTypes, setSelectedTypes] = useGenericFilterDropdownQueryString<
    string[] | undefined
  >({
    key: 'types',
  });

  const [selectedUsers, setSelectedUsers] = useGenericFilterDropdownQueryString<
    string[] | undefined
  >({
    key: 'user_ids',
  });

  const infiniteQuery = useAuthorAliasesInfiniteQuery({
    pageSize: 20,
    programId,
    search: useDebounce(searchTerm),
    types: selectedTypes,
    userIds: selectedUsers,
    statuses: selectedStatuses,
  });

  return (
    <ListPage
      title="Aliases"
      description="Create and manage email and author aliases, for use in campaigns"
      tabs={[
        { to: 'email-alias', label: 'Email Aliases' },
        { to: 'author-aliases', label: 'Author Aliases' },
      ]}
      breadcrumbs={[{ to: '..', label: 'Configure' }, { label: 'Aliases' }]}
      actions={[
        {
          icon: <Icon name="Plus" fill="currentColor" />,
          label: 'Author Alias',
          to: './new',
        },
      ]}
      filterbar={
        <FiltersBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          statuses={selectedStatuses}
          types={selectedTypes}
          users={selectedUsers}
          onFilterChange={(filterName, values) => {
            if (filterName === 'statuses') {
              setSelectedStatuses(values);
            }

            if (filterName === 'types') {
              setSelectedTypes(values);
            }

            if (filterName === 'users') {
              setSelectedUsers(values);
            }
          }}
        />
      }
    >
      <WithBulkSelect
        filterConfig={{
          search: searchTerm,
          userIds: selectedUsers,
          types: selectedTypes,
          statuses: selectedStatuses,
        }}
        totalRecords={infiniteQuery.meta?.totalArchivableRecords || 0}
      >
        <InfiniteList
          infiniteQuery={infiniteQuery}
          renderRow={(item, index) => (
            <AuthorAliasBanner authorAlias={item} index={index} />
          )}
          loading={<></>}
          emptyList={<div>No aliases match that criteria.</div>}
        />
      </WithBulkSelect>
    </ListPage>
  );
};
