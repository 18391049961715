import * as React from 'react';
import styles from './web.module.css';

export const Web: React.FC = ({ children }) => {
  return (
    <div className={styles.web}>
      <div className={styles.contentWrapper}>
        <div className={styles.address}>https://....</div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
