export const WORKFLOW_CHANNELS = [
  'email',
  'push',
  'feed',
  'assistant',
] as const;

export type Channel = typeof WORKFLOW_CHANNELS[number];

export const VisibilityTypes = ['visible', 'hidden'];

type Stooge = {
  id: number;
  program_id: number;
  is_hidden?: boolean;
};

type Gallery = {
  galleryId: number;
  galleryName: string;
  galleryDescription: string;
};

export const EditingStates = [
  'active',
  'paused',
  'completed',
  'draft',
  'archived',
];
export type EditingState = typeof EditingStates[number];
export type Workflow = {
  firstActivationDate: string;
} & Stooge &
  Gallery & {
    name: string;
    channels: Channel[];
    editingState: EditingState;
  };

export type WorkflowSummary = {
  targeted: number;
  steps: number;
  aggregated: Array<{ name: string; count: number }>;
  email?: Record<
    | 'INITIAL'
    | 'SENT'
    | 'DELIVERED'
    | 'DELIVERY_ERROR'
    | 'DROPPED'
    | 'OPEN'
    | 'OPEN_EMAIL_LINK'
    | 'OPEN_BY_MACHINE'
    | 'CLICK',
    number
  >;
  assistant?: Record<'INITIAL' | 'DELIVERED', number>;
  push?: Record<
    | 'INITIAL'
    | 'SENT'
    | 'SENT_ERROR'
    | 'DELIVERED'
    | 'DELIVERY_ERROR'
    | 'OPEN_LINK'
    | 'OPEN_BY_MACHINE',
    number
  >;
};
