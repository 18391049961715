import * as React from 'react';
import cx from 'classnames';
import { previewImage, ViewType } from 'models/publisher/post';
import { isImageBlock, isImagesBlock } from 'models/publisher/block';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useCardEditor } from 'contexts/publisher/compose/card';
import { usePublisher } from 'contexts/publisher';

type PropsType = {
  styles: { readonly [key: string]: string };
  viewType: ViewType;
};

export const CardPreview: React.FC<PropsType> = (props) => {
  const { styles, viewType } = props;
  const { post } = usePublisher();
  const { cta } = useCardEditor();
  const { instances } = React.useContext(BlocksEditorContext);

  const firstBlock = instances[0]?.block;
  const nonStandardCover = cta.customEnabled;
  const largePreviewImage =
    nonStandardCover && (isImagesBlock(firstBlock) || isImageBlock(firstBlock));
  const showDots = nonStandardCover && isImagesBlock(firstBlock);
  const dotsCount =
    Array.isArray(firstBlock?.field_data.images) &&
    firstBlock?.field_data.images.length;

  return (
    <>
      <div
        className={cx(styles.image, largePreviewImage ? styles.large : '')}
        style={{
          backgroundImage: `url(${
            previewImage(post, viewType, cta, instances)?.url
          })`,
        }}
      >
        {showDots && dotsCount && (
          <div className={styles.dotsContainer}>
            <div className={styles.dots}>
              <div className={cx(styles.ellipse, styles.black)} />
              {Array.from(Array(dotsCount - 1)).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={styles.ellipse} />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
