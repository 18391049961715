import React from 'react';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { BulkSelectActionType } from 'shared/BulkSelect/Actions';
import { AuthorAliasBulkActionFilters } from 'hooks/common';
import { useQueryClient } from 'react-query';
import {
  useAuthorAliasBulkAffected,
  useAuthorAliasBulkArchive,
  useAuthorAliasBulkUnarchive,
} from 'hooks/author-alias';
import { useFlashMessage } from 'contexts/flasher';

type PropsType = {
  children: React.ReactNode;
  totalRecords: number;
  filterConfig: AuthorAliasBulkActionFilters;
};

export const WithBulkSelect: React.FC<PropsType> = ({
  children,
  totalRecords,
  filterConfig,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();
  const infiniteQueryKey = 'author-aliases-infinite';

  const {
    data,
    fetchToBeUpdated,
    isLoadingArchive,
    isLoadingUnarchive,
  } = useAuthorAliasBulkAffected();

  const { archive } = useAuthorAliasBulkArchive({
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Aliases successfully archived.',
      });
    },
    onError: () => {
      setFlashMessage({
        severity: 'error',
        message: 'Aliases could not be unarchived.',
      });
    },
  });
  const { unArchive } = useAuthorAliasBulkUnarchive({
    onSuccess: () => {
      queryClient.invalidateQueries(infiniteQueryKey);
      setFlashMessage({
        severity: 'info',
        message: 'Aliases successfully unarchived.',
      });
    },
    onError: () => {
      setFlashMessage({
        severity: 'error',
        message: 'Aliases could not be unarchived.',
      });
    },
  });

  const actions: BulkSelectActionType[] = [
    {
      type: 'button',
      actions: [
        {
          label: 'Archive',
          iconName: 'archive',
          onClick: (bulkSelection) => {
            archive(bulkSelection, filterConfig);
            return true;
          },
        },
      ],
      beforeAction: async (bulkSelection) => {
        await fetchToBeUpdated({
          performedAction: 'archive',
          bulkSelection,
          filterConfig,
        });
      },
      isLoading: isLoadingArchive,
      toStatusLabel: 'archived',
    },
    {
      type: 'button',
      actions: [
        {
          label: 'Unarchive',
          iconName: 'Unarchive',
          onClick: (bulkSelection) => {
            unArchive(bulkSelection, filterConfig);
            return true;
          },
        },
      ],
      beforeAction: async (bulkSelection) => {
        await fetchToBeUpdated({
          performedAction: 'unarchive',
          bulkSelection,
          filterConfig,
        });
      },
      isLoading: isLoadingUnarchive,
      toStatusLabel: 'restored',
    },
  ];
  return (
    <BulkSelectWrapper
      totalRecords={totalRecords}
      recordsToUpdate={data?.affectedCount}
      actions={actions}
      itemName="alias"
    >
      {children}
    </BulkSelectWrapper>
  );
};
