export const LINK_COUNT_LIMIT = 75;

export const INTERNAL_SHORTCUT_TYPES = [
  'getting_started',
  'whats_new',
  'community',
  'for_you',
] as const;

export const EDITABLE_SHORTCUT_TYPES = [
  'link',
  ...INTERNAL_SHORTCUT_TYPES,
] as const;
