import { Box } from 'DesignSystem/Components/Box';
import { border } from 'DesignSystem/Typography';
import React, { Children } from 'react';
import styles from './section.module.css';

/**
 * SectionCard works as a wrapper for Review page. It consumes 1 required and 1 optional children.
 *
 * @param padding {Array<number>} padding for Card sections
 * @param marginTop optional {number} card top margin
 * @param backgroundColor optional {gray | undefined} card top margin
 * @param borderless optional {boolean} wrap container with border or not
 */

const BgColorsMap = {
  gray: 'var(--color-gray02)',
};

export const SectionCard: React.FC<{
  children: React.ReactNode;
  padding: number[] | number;
  marginTop?: number;
  bgColor?: 'gray' | undefined;
  borderless?: boolean;
}> = ({ children, padding, marginTop = 0, borderless = false, bgColor }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <Box
      border={
        borderless
          ? []
          : [border.solid, border.width1, border.radius8, border.gray10]
      }
      margin={[marginTop, 0, 0, 0]}
      relative
      style={{ backgroundColor: bgColor ? BgColorsMap[bgColor] : 'inherit' }}
    >
      <Box padding={padding}>{arrayChildren[0]}</Box>
      {arrayChildren.map((child, index) => {
        if (index !== 0) {
          return (
            <>
              <Box className={styles.Delimiter} />
              <Box padding={padding}>{child}</Box>
            </>
          );
        }
        return <></>;
      })}
    </Box>
  );
};
