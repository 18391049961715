import React from 'react';
import * as FlexLayout from '../../../../../../DesignSystem/Layout/Flex';
import { Box } from '../../../../../../DesignSystem/Components';
import styles from './readonly-communication-config.module.css';
import { FieldLabel } from '../../../../../../DesignSystem/Typography';
import { NotificationCard } from '../../../../Editors/Publisher/Deliver/Notifications/NotificationCard';
import {
  CommunicationStep,
  isEmailChannel,
} from '../../../../../../models/journeys/journey';
import { useAcknowledgementsEnabled } from '../../../../../../hooks/journeys/journeys';

export const EmailReadOnlyCommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const emailChannel = step.channels.find(isEmailChannel);
  const acknowledgementsEnabled = useAcknowledgementsEnabled();
  return (
    <FlexLayout.Flex data-testid="email-readonly-communication-config" spread>
      <Box width="50%">
        {emailChannel && (
          <Box>
            <Box>
              <FieldLabel>Email Alias</FieldLabel>
              <Box margin={[8, 0, 0, 0]}>
                {emailChannel.emailSenderAlias?.senderEmail}
              </Box>
            </Box>
            <Box margin={[24, 0, 0, 0]}>
              <FieldLabel>Subject and Preview Text</FieldLabel>
              <NotificationCard
                notification={{
                  text: emailChannel.subject,
                  previewText: emailChannel.previewText,
                  order: 0,
                }}
                disablePush
              />
            </Box>
          </Box>
        )}
        {acknowledgementsEnabled && step.acknowledgement?.customLabel && (
          <div className={styles.configSection}>
            <Box margin={[24, 0, 0, 0]}>
              <FieldLabel>Acknowledgement Label</FieldLabel>
              <Box margin={[8, 0, 0, 0]}>
                {step.acknowledgement?.customLabel}
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </FlexLayout.Flex>
  );
};
