import React from 'react';
import { Link } from '@reach/router';
import { Block, Row } from 'shared/SectionBlocks';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import styles from './errorlist.module.css';

export const ErrorRow: React.FC<{
  link: string | null;
  text: string;
}> = ({ link, text }) => {
  const arrowAndText = (
    <>
      <ArrowRight height="12" width="12" className={styles.arrowIcon} />
      <h3 className={styles.errorText}>{text}</h3>
    </>
  );

  return (
    <Row>
      <Block className={styles.errorListSpacing}>
        {link ? (
          <Link to={link} className={styles.link}>
            {arrowAndText}
          </Link>
        ) : (
          arrowAndText
        )}
      </Block>
    </Row>
  );
};
