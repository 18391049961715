/* eslint-disable no-console */
export class LocalStore<Schema extends Record<string, unknown>> {
  constructor(readonly store_name: string) {}

  read(): Schema | undefined {
    try {
      const json_string = localStorage.getItem(this.store_name);
      if (json_string) {
        const json_data: Schema | undefined = JSON.parse(json_string);
        if (json_data) {
          return json_data;
        }
        console.info(`unexepcted data in <${this.store_name}>`);
      }
    } catch (e) {
      console.log(`failed to read from <${this.store_name}>`);
      console.error(e);
    }
    return undefined;
  }

  write(data: Schema): void {
    try {
      localStorage.setItem(this.store_name, JSON.stringify(data));
    } catch (e) {
      console.log(`failed to write to <${this.store_name}>`);
      console.error(e);
    }
  }
}
