import { Text } from 'DesignSystem/Typography';
import React from 'react';
import styles from './communication-config.module.css';

export function ConfigurationSection({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <section>
      <div className={styles.configSectionContainer}>
        <Text
          className={{
            Subheading: true,
            Semibold: true,
          }}
        >
          {title}
        </Text>
      </div>
      {children}
    </section>
  );
}
