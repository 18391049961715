import { useQuery } from 'react-query';
import {
  IDashboardJson,
  transformJsonToTableauDashboard,
} from '../../models/insightsPlus/Dashboard';
import {
  IWorkbookCategoryJson,
  IWorkbookJson,
  transformJsonToTableauWorkbook,
  transformJsonToWorkbookCategory,
} from '../../models/insightsPlus/Workbook';
import {
  fetchTableauDashboards,
  fetchTableauWorkbook,
  fetchTableauWorkbookCategories,
  fetchTableauWorkbookDashboards,
  fetchTableauWorkbooks,
} from '../../services/api-insights';

type TableauQueryType = {
  isLoading: boolean;
  error: unknown;
};

type WorkbooksQueryType = {
  workbooks: Tableau.Workbook[];
} & TableauQueryType;

type WorkbookQueryType = {
  workbook: Tableau.Workbook | undefined;
} & TableauQueryType;

type CategoriesQueryType = {
  categories: Tableau.WorkbookCategory[];
} & TableauQueryType;

type DashboardsQueryType = {
  dashboards: Tableau.Dashboard[];
} & TableauQueryType;

export const useWorkbooksQuery = (programId: number): WorkbooksQueryType => {
  const { isLoading, data, error } = useQuery<
    unknown,
    unknown,
    IWorkbookJson[]
  >(['tableau-workbooks', programId], async () =>
    fetchTableauWorkbooks(programId)
  );

  return {
    isLoading,
    workbooks: data?.map(transformJsonToTableauWorkbook) || [],
    error,
  };
};

export const useWorkbookQuery = (
  programId: number,
  workbookId: string
): WorkbookQueryType => {
  const { isLoading, data, error } = useQuery<unknown, unknown, IWorkbookJson>(
    ['tableau-workbook', programId, workbookId],
    async () => fetchTableauWorkbook(programId, workbookId)
  );

  const workbook = data ? transformJsonToTableauWorkbook(data) : undefined;

  return {
    isLoading,
    workbook,
    error,
  };
};

export const useCategoriesQuery = (programId: number): CategoriesQueryType => {
  const { isLoading, data, error } = useQuery<
    unknown,
    unknown,
    IWorkbookCategoryJson[]
  >(['tableau-categories', programId], async () =>
    fetchTableauWorkbookCategories(programId)
  );

  return {
    isLoading,
    categories: data?.map(transformJsonToWorkbookCategory) || [],
    error,
  };
};

export const useDashboardsQuery = (
  programId: number,
  workbookId?: string
): DashboardsQueryType => {
  const fetchQuery = workbookId
    ? () => fetchTableauWorkbookDashboards(programId, workbookId)
    : () => fetchTableauDashboards(programId);

  const { isLoading, data, error } = useQuery<
    unknown,
    unknown,
    IDashboardJson[]
  >(['tableau-dashboards', programId, workbookId], async () => fetchQuery());

  return {
    isLoading,
    dashboards: data?.map(transformJsonToTableauDashboard) || [],
    error,
  };
};
