export type Role =
  | 'member'
  | 'analyst'
  | 'channel_contributor'
  | 'publisher'
  | 'program_admin'
  | 'administrator'
  | 'super_admin';

export type LitmusRole = {
  id?: number;
  aliases: Array<{ name: string }>;
  description: string;
  isSystemRole?: boolean;
  name: string;
  parent: string | null;
  sequence: number;
  tenant: string;
  title: string;
  type: 'primary' | 'secondary';
  hidden?: boolean;

  // The following fields are currently not available.
  admins?: number;
  createdAt?: string;
};

export type LitmusRoleByType = {
  primary: LitmusRole[];
  secondary: LitmusRole[];
};

type RoleAliasT = {
  name: string;
};

function roleTitleAndDescriptionValid(
  role: Partial<LitmusRole> | LitmusRole
): boolean {
  if (!role?.title || !role?.description) return false;
  return role?.title?.trim() !== '' && role?.description?.trim() !== '';
}

// TODO: we should not assume which toggles are nested under "configure" based on their names.
// We need to update this logic to use the "parent" relationships of the toggles instead.
export function validateRole(role: Partial<LitmusRole>): string | undefined {
  const hasValidTitleAndDescription = roleTitleAndDescriptionValid(role);

  const hasViewToggle = role.aliases?.some((alias: RoleAliasT) =>
    [
      'configure',
      'insights',
      'campaigns',
      'people',
      'library',
      'insights_plus_access',
    ].includes(alias.name)
  );

  const configToggleCount = role.aliases?.filter((alias: RoleAliasT) =>
    alias.name.includes('configure')
  ).length;
  const libraryToggleCount = role.aliases?.filter((alias: RoleAliasT) =>
    [
      'library',
      'create_templates',
      'library_images',
      'library_fonts',
      'library_custom_blocks',
    ].includes(alias.name)
  ).length;
  const hasExperienceToggle = role.aliases?.filter((alias: RoleAliasT) =>
    alias.name.includes('configure_experience')
  ).length;
  const hasExperienceSubtoggle = role.aliases?.filter(
    (alias: RoleAliasT) =>
      alias.name.includes('configure_experience_settings') ||
      alias.name.includes('configure_microapps')
  ).length;

  if (configToggleCount === 1) {
    return 'Please enable at least one permission under Configure or disable the Configure permission.';
  }
  if (hasExperienceToggle && hasExperienceSubtoggle === 0) {
    return 'Please enable at least one subtoggle under Configure - Experience';
  }
  if (libraryToggleCount === 1) {
    return 'Please enable at least one permission under Library or disable the Library permission.';
  }
  if (!hasViewToggle) {
    return 'Please enable at least one permission.';
  }
  if (!hasValidTitleAndDescription) {
    return 'Please enter valid role name and description.';
  }

  if (role.title && role.title.length > 200) {
    return 'Title is too long, the maximum length is 200 characters';
  }

  if (role.title?.toLowerCase() === 'member') {
    return 'This role name is not allowed.';
  }

  return undefined;
}
