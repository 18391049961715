import React, { useMemo } from 'react';
import { PinnableContent } from 'models/topic';
import { useTopicFormCtx } from '../../../../context';
import Sidebar from '../../../common/Sidebar';
import { SidebarComponent } from '../type';
import styles from './styles.module.css';
import {
  ItemAddButton,
  SortableItem,
  SortableList,
} from '../../components/SortableList';
import { ModalType } from '../../../../context/modals';

const EditPinnedPostsSidebar: SidebarComponent = ({ onClose }) => {
  const {
    state: {
      fields: { pinnedPosts },
      modal,
    },
    toggleModal,
    handleChange,
  } = useTopicFormCtx();

  const items = useMemo(() => {
    return pinnedPosts.map((post) => ({
      id: `${post.id}`,
      imgSrc: post.imageUrl,
      name: post.title || post.summary || 'No Description',
      description: post.title && post.summary,
    }));
  }, [pinnedPosts]);

  // a bit slow, especially when mapped over, but given that the number of pinned posts will easily be under 100, it's going to be fine
  const asContent = (item: SortableItem): PinnableContent =>
    pinnedPosts.find((post) => `${post.id}` === item.id) as PinnableContent;

  const onPinnedPostsChange = (newPinnedPosts: SortableItem[]) => {
    handleChange('pinnedPosts', newPinnedPosts.map(asContent));
  };

  const openPostsModal = () => {
    toggleModal({ type: ModalType.AddPinnedPosts });
  };

  const deletePinnedPost = (item: SortableItem) => {
    const index = pinnedPosts.findIndex((post) => `${post.id}` === item.id);
    handleChange('pinnedPosts', [
      ...pinnedPosts.slice(0, index),
      ...pinnedPosts.slice(index + 1),
    ]);
  };
  return (
    <Sidebar
      title="Pinned Posts"
      onClose={onClose}
      onBeforeClose={() => !modal.type}
      position="right"
      closeButton
    >
      <p className={styles.sidebarDescription}>
        Add up to 5 pinned posts. These will appear at the top of the feed.
        Select and drag posts in the order that you would like.
      </p>
      {pinnedPosts.length > 0 && (
        <SortableList
          items={items}
          onSort={onPinnedPostsChange}
          onDelete={deletePinnedPost}
        />
      )}
      <ItemAddButton label="Pinned Posts" onClick={openPostsModal} />
    </Sidebar>
  );
};

export default EditPinnedPostsSidebar;
