import React from 'react';
import { Topic } from 'models/topic';
import { User } from 'models/user';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { useProgram } from 'contexts/program';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { UsersSelect } from 'components/users/UsersSelect';
import { useUserByIdsQuery } from 'hooks/user';
import { LoadingSpinner } from 'shared/LoadingSpinner';

import styles from './form.module.css';

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>) => void;
};

export const ContributorSection: React.FC<PropsType> = ({
  topic,
  onChange,
}) => {
  const currentContributorIds = React.useMemo<number[]>(() => {
    return topic.contributorIds ?? [];
    // eslint-disable-next-line
  }, []);

  const { id: programId } = useProgram();
  const { data, isLoading } = useUserByIdsQuery(
    programId,
    currentContributorIds
  );

  const [users, setUsers] = React.useState<User[]>([]);

  React.useEffect(() => {
    if ((topic.contributorIds?.length ?? 0) > 0 && data) setUsers(data);
    // using data as deps leads to infinite loop
    // eslint-disable-next-line
  }, [isLoading]);

  function handleUsersChange(values: User[]) {
    setUsers(values);
    onChange({ contributorIds: values.map((value) => value.id) });
  }

  return (
    <Section title="Contributor">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="User(s)">
            {isLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && (
              <UsersSelect
                onSelectedUsersChange={handleUsersChange}
                selectedUsers={users}
                disabled={topic?.default}
              />
            )}
          </Field>
        </div>
      </Fieldset>
    </Section>
  );
};
