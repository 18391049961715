import qs from 'qs';
/**
 * Find the value of a key in the querystring.
 * It will find the value of a key if it is found in the querstring
 */
export const findInQueryParam = (
  queryString: string,
  field: string
): {
  hasValue: boolean;
  fieldValue: string;
} => {
  const params = qs.parse(queryString, { ignoreQueryPrefix: true });
  return {
    hasValue: Object.prototype.hasOwnProperty.call(params, field),
    fieldValue: params[field] as string,
  };
};
