import React from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { ReviewPreview } from 'components/publisher/previews/Review';

export const Content: React.FC = () => (
  <Section title="Content">
    <Row>
      <Block>
        <ReviewPreview />
      </Block>
    </Row>
  </Section>
);
