import React from 'react';
import { MenuItemsType } from 'shared/BannerListItem/EmptyBanner';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import {
  useEnableShortcut,
  useDeleteShortcut,
  useDisableShortcut,
} from 'hooks/shortcuts';
import { Shortcut } from 'models/shortcut';

type PropsType = {
  shortcut: Shortcut;
};

type Return = {
  menuItems: MenuItemsType;
  deleteItem?: {
    deleteAction: () => void;
    showingDeleteModal: boolean;
    toggleDeleteModal: () => void;
    resource: string;
  };
};

export const useShortcutActions = ({ shortcut }: PropsType): Return => {
  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const { setFlashMessage } = useFlashMessage();

  const toggleDeleteModal = () => {
    setShowingDeleteModal(false);
  };

  const onMutationError = () => {
    setFlashMessage({
      message: 'Error deleting shortcut',
      severity: 'error',
    });
  };
  const { id: programId } = useProgram();
  const { mutate } = useDeleteShortcut();

  const deleteShortcut = (t: Shortcut) =>
    mutate(t.id, {
      onError: onMutationError,
    });

  const { enable } = useEnableShortcut(programId, {
    onSuccess: () =>
      setFlashMessage({
        severity: 'info',
        message: `Shortcut enabled successfully`,
      }),
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Error occured while enabling the shortcut',
      }),
  });

  const { disable } = useDisableShortcut(programId, {
    onSuccess: () =>
      setFlashMessage({
        severity: 'info',
        message: `Shortcut disabled successfully`,
      }),
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Error occured while disabling the shortcut',
      }),
  });

  const menuItems: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${programId}/configure/shortcuts/${shortcut.id}`,
    },
    {
      title: shortcut.status === 'active' ? 'Disable' : 'Enable',
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (shortcut.status === 'active') disable(shortcut);
        else enable(shortcut);
      },
    },
    {
      title: 'Delete',
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setShowingDeleteModal(true);
      },
      disabled: false,
      danger: true,
    },
  ];

  return {
    menuItems,
    deleteItem: {
      deleteAction: () => deleteShortcut(shortcut),
      resource: 'shortcut',
      showingDeleteModal,
      toggleDeleteModal,
    },
  };
};
