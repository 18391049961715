import React from 'react';
import { Expression, expressionToText } from 'models/expression';
import { editableTextToQueryType } from 'models/audience';
import { Icon } from 'shared/Icon';
import { useAudienceUsersQuery } from 'hooks/audience';
import { useStickyResponse } from 'hooks/common';
import { useProgram } from 'contexts/program';
import styles from '../AudienceBuilder.module.css';

export const ExpressionCount: React.FC<{
  isCompact: boolean;
  expression: Expression;
  editableTextToQuery: editableTextToQueryType;
  updateSummaryQueryStatus?: (errorMessage: boolean) => void;
  memberLabel?: string;
}> = ({
  isCompact,
  expression,
  editableTextToQuery,
  updateSummaryQueryStatus,
  memberLabel = 'total user',
}) => {
  const { id: programId } = useProgram();
  const query = `${editableTextToQuery(expressionToText(expression))}`;
  const { isLoading, data, errorMessage } = useStickyResponse(
    useAudienceUsersQuery(programId, query)
  );
  const { data: isEmpty } = useStickyResponse({
    isLoading,
    data: query === '',
  });
  React.useEffect(() => {
    if (!isLoading && updateSummaryQueryStatus && isCompact) {
      updateSummaryQueryStatus(!errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, errorMessage, isCompact]);
  const count = (!isEmpty && data?.totalObjects) || 0;
  return (
    <>
      {isCompact || (
        <span className={styles.audienceCountIcon}>
          <Icon iconName="People" iconType="SVG" size={18} />
        </span>
      )}
      {count}
      {isCompact && ` ${memberLabel}${count === 1 ? '' : 's'}`}
    </>
  );
};
