import * as React from 'react';
import cx from 'classnames';
import styles from './timezone-panel.module.css';
import { Timezone } from './DateRange';

const getTimezones = (
  allowFutureSection: boolean
): { [key: string]: Timezone[] } => {
  return {
    Timezones: [
      Timezone.build('GMT', allowFutureSection),
      // Timezone.build('UTC', allowFutureSection),
      Timezone.build('ECT', allowFutureSection),
      // Timezone.build('EET', allowFutureSection),
      Timezone.build('ART', allowFutureSection),
      Timezone.build('EAT', allowFutureSection),
      // Timezone.build('MET', allowFutureSection),
      Timezone.build('NET', allowFutureSection),
      Timezone.build('PLT', allowFutureSection),
      Timezone.build('IST', allowFutureSection),
      Timezone.build('BST', allowFutureSection),
      Timezone.build('VST', allowFutureSection),
      Timezone.build('CTT', allowFutureSection),
      Timezone.build('JST', allowFutureSection),
      Timezone.build('ACT', allowFutureSection),
      Timezone.build('AET', allowFutureSection),
      Timezone.build('SST', allowFutureSection),
      Timezone.build('NST', allowFutureSection),
      Timezone.build('MIT', allowFutureSection),
      // Timezone.build('HST', allowFutureSection),
      Timezone.build('AST', allowFutureSection),
      Timezone.build('PST', allowFutureSection),
      Timezone.build('PNT', allowFutureSection),
      // Timezone.build('MST', allowFutureSection),
      Timezone.build('CST', allowFutureSection),
      // Timezone.build('EST', allowFutureSection),
      Timezone.build('IET', allowFutureSection),
      Timezone.build('PRT', allowFutureSection),
      Timezone.build('CNT', allowFutureSection),
      Timezone.build('AGT', allowFutureSection),
      Timezone.build('BET', allowFutureSection),
      Timezone.build('CAT', allowFutureSection),
      Timezone.build('WET', allowFutureSection),
    ],
  };
};

type TimezoneLinkPropsType = {
  selected: boolean;
  timezone: Timezone;
  onChange: (timezone: Timezone) => void;
};

const TimezoneLink = (props: TimezoneLinkPropsType) => {
  const { onChange, timezone, selected } = props;

  return (
    <div className={cx({ [styles.selected]: selected })}>
      <button
        className={cx({ [styles.selected]: selected })}
        onClick={() => onChange(timezone)}
        type="button"
      >
        {timezone.label} {timezone.offset}
      </button>
    </div>
  );
};

type PropsType = {
  className?: string;
  onChange: (timezone: Timezone) => void;
  allowFutureSelection: boolean;
  selectedPresetKey?: string;
};

export const TimezonePanel: React.FC<PropsType> = (props) => {
  const {
    className,
    onChange,
    selectedPresetKey,
    allowFutureSelection,
  } = props;
  const Timezones = getTimezones(allowFutureSelection);

  return (
    <div
      className={cx(styles.panel, className, {
        [styles.inactive]: !Timezone.isValidKey(selectedPresetKey || ''),
      })}
    >
      {['Timezones'].map((group: string) => (
        <div key={group}>
          <strong>{group}</strong>
          {Timezones[group].map((timezone: Timezone) => (
            <TimezoneLink
              key={timezone.label}
              timezone={timezone}
              selected={selectedPresetKey === timezone.key}
              onChange={onChange}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
