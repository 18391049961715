import React from 'react';
import { LinkFieldData, SocialFieldData } from 'models/publisher/block';
import { FieldProps } from '../FieldProps';
import { SingleLink } from './SingleLink';
import { List } from './List';
import { useLinksSourceMenu } from './useLinksSourceMenu';
import { useMultiLinks } from './useMultiLinks';

export const MultiLink: React.FC<FieldProps<
  (LinkFieldData | SocialFieldData)[]
>> = (props) => {
  const source_menu = useLinksSourceMenu();
  const multi_links = useMultiLinks(props, source_menu);
  const {
    field: { type },
  } = props;

  return multi_links.current ? (
    <SingleLink
      initial={multi_links.current}
      onSave={multi_links.applyEdits}
      onCancel={multi_links.closeEditor}
      source={source_menu}
    />
  ) : (
    <List
      type={type}
      source={source_menu}
      links={multi_links.links}
      onEdit={multi_links.openEditorByIndex}
      onUpdate={multi_links.listUpdate}
      onClearAll={multi_links.clearList}
    />
  );
};
