import React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Preview } from 'components/templates/Preview/';
import { Screen } from 'components/templates/Screen';

export const Template: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Screen path="/:id">
        <Preview path="/" />
        <Redirect from="/" to=".." noThrow />
        <Redirect from="*" to="/" noThrow />
      </Screen>
    </Router>
  );
};
