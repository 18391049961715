import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { KeepOpen } from '../../shared/KeepOpen';

export const ExitBlocker: React.FC = () => (
  <KeepOpen keepOpenLabel="Wait for Upload" confirmCloseLabel="Close Anyway">
    <Type.Heading block>Video upload in progress</Type.Heading>
    <Type.Body block>Please wait while the video upload finishes....</Type.Body>
  </KeepOpen>
);
