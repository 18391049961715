import React from 'react';
import { Pills } from 'DesignSystem/Components/Pills';
import { Subsection } from 'DesignSystem/Form';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { Gray10PillClass } from 'DesignSystem/Components/Pill';
import { EngagementBoostOptions } from './useEngagementBoostDefaults';

export const EngagementBoostDefaultsShow: React.FC<{
  engBoostDefaultsValues: Array<EngagementBoostOptions>;
}> = ({ engBoostDefaultsValues }) => (
  <Subsection
    title="Default Engagement Boost Settings"
    description="Engagement Boost settings pre-selected when an audience is added on the Deliver page"
  >
    <Pills
      values={engBoostDefaultsValues}
      render={({ label, checked }) => (
        <>
          {label}:&nbsp;
          <strong>{(checked && 'ON') || 'OFF'}</strong>
        </>
      )}
      pillIcon={({ checked }) =>
        checked ? (
          <Icon name="CheckCircleOutline" />
        ) : (
          <Icon name="CloseCircleOutline" />
        )
      }
      pillClassName={Gray10PillClass}
      small
      padding={[0, 8]}
    />
  </Subsection>
);
