import { InclusiveRule } from 'models/inclusive-language';
import {
  useBasicValidator,
  ValidateFunc,
  ValidatorsType,
} from './useBasicValidator';

type ValidatorType = {
  rule?: InclusiveRule;
};

const validators: ValidatorsType<ValidatorType> = {
  missing_fields: ({ rule }) =>
    !rule ||
    rule.terms.filter((t) => t.length > 0).length === 0 ||
    rule.suggestions.length === 0 ||
    rule.explanation.length === 0,
};

export const useInclusiveRuleValidator: () => {
  validate: ValidateFunc<ValidatorType>;
} = () => {
  return useBasicValidator<ValidatorType>(validators);
};
