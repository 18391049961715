import React from 'react';
import styles from 'components/publisher/pages/Preview/Mobile/mobile.module.css';

export const Mobile: React.FC = ({ children }) => {
  return (
    <div className={styles.mobile}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
