import {
  BigIconButton,
  Button,
  Icon,
} from '@socialchorus/shared-ui-components';
import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { pluralize } from 'utility/text';
import { FieldError } from 'shared/Fieldset/FieldError';
import { missingShortcuts } from 'App/Program/Configuration/Topics/TopicV2/topicErrorDefinitions';
import { useIsErrorApplicable } from 'App/Program/Configuration/Topics/TopicV2/topicErrorHooks';
import { useTopicFormCtx } from '../../../../context';
import { DEFAULT_SHORTCUT_ICON } from '../../../common/constants';
import { HighlightableContainer } from '../../components/HighlightableContainer';
import { BaseTab } from '../BaseTab';
import styles from './styles.module.css';
import { ModalType } from '../../../../context/modals';

export const ShortcutsTab = (): JSX.Element => {
  const { state, toggleSidebar, toggleModal } = useTopicFormCtx();

  const handleEditShortcuts = () => {
    toggleSidebar('shortcuts');
  };

  const handleCreateShortcut = () => {
    toggleModal({ type: ModalType.EditShortcut });
  };

  const shortcutsCount = state.fields.shortcuts.length;
  const isShortcutsMissingApplicable = useIsErrorApplicable(
    'Missing Shortcuts'
  );

  return (
    <BaseTab currentTab="shortcuts">
      <HighlightableContainer
        className={shortcutsCount ? styles.Container : styles.Container__Empty}
        hoverLabel="Shortcuts"
        active={state.activeSidebar === 'shortcuts'}
        editIcon={!!shortcutsCount}
      >
        {shortcutsCount ? (
          <>
            <button
              type="button"
              className={styles.OpenShortcutsSidebarButton}
              onClick={handleEditShortcuts}
            >
              <span>
                {shortcutsCount} {pluralize(shortcutsCount, 'Shortcut')}
              </span>
            </button>

            <div className={styles.ShortcutsGrid}>
              {state.fields.shortcuts.map((shortcut) => (
                <BigIconButton
                  key={shortcut.id}
                  imgSrc={shortcut?.iconUrl ?? DEFAULT_SHORTCUT_ICON}
                  label={shortcut.name}
                />
              ))}
            </div>
          </>
        ) : (
          <div className={styles.NoShortcuts}>
            <div className={styles.NoShortcuts__Texts}>
              <Text.Heading semibold>Shortcuts</Text.Heading>
              <Text.Body>
                <span className={styles.NoShortcuts__Texts__Description}>
                  There are no Shortcuts in this topic yet
                </span>
              </Text.Body>
            </div>

            <Button
              variant="primary"
              className={styles.AddShortcutButton}
              leftIcon={<Icon>add</Icon>}
              label="Shortcuts"
              onClick={handleCreateShortcut}
            />
            {isShortcutsMissingApplicable && (
              <FieldError
                error={missingShortcuts.text}
                ariaLabel={missingShortcuts.name}
              />
            )}
          </div>
        )}
      </HighlightableContainer>
    </BaseTab>
  );
};
