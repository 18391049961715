import { Editor } from '@tiptap/react';
import React from 'react';

import toolbarStyle from './editor.module.css';
import { EditorActionInsertImage } from './EditorActionInsertImage';
import { EditorActionAddLink } from './EditorActions/EditorActionAddLink';
import { EditorActionBold } from './EditorActions/EditorActionBold';
import { EditorActionFontColor } from './EditorActions/EditorActionFontColor';
import { EditorActionFontFamily } from './EditorActions/EditorActionFontFamily';
import { EditorActionFontSize } from './EditorActions/EditorActionFontSize';
import { EditorActionHighlight } from './EditorActions/EditorActionHighlight';
import { EditorActionItalicize } from './EditorActions/EditorActionItalicize';
import { EditorActionListBulleted } from './EditorActions/EditorActionListBulleted';
import { EditorActionListNumbered } from './EditorActions/EditorActionListNumbered';
import { EditorActionSelectHeading } from './EditorActions/EditorActionSelectHeading';
import { EditorActionStrike } from './EditorActions/EditorActionStrike';
import { EditorActionTextAlignCenter } from './EditorActions/EditorActionTextAlignCenter';
import { EditorActionTextAlignJustify } from './EditorActions/EditorActionTextAlignJustify';
import { EditorActionTextAlignLeft } from './EditorActions/EditorActionTextAlignLeft';
import { EditorActionTextAlignRight } from './EditorActions/EditorActionTextAlignRight';
import { EditorActionUnderline } from './EditorActions/EditorActionUnderline';
import { ToolbarDivider } from './EditorActions/shared/ToolbarDivider';

// todo: styling of everything here
// todo: factor this out into many separate components

export const EditorToolbar: React.FC<{
  editor: Editor | null;
  addFontId: (param: number) => void;
  // todo: replace prop drilling with context https://firstup-io.atlassian.net/browse/EE-18405
}> = ({ editor, addFontId }) => {
  // const { toggle: toggleColorPicker, value: colorPickerEnabled } = useToggle();

  if (!editor) return null;

  return (
    <div className={toolbarStyle.tiptapToolbar}>
      <EditorActionSelectHeading editor={editor} />
      <ToolbarDivider width="1px" />
      <EditorActionFontFamily editor={editor} addFontId={addFontId} />
      <ToolbarDivider width="1px" />
      <EditorActionFontSize editor={editor} />
      <ToolbarDivider width="1px" />
      <EditorActionBold editor={editor} />
      <EditorActionItalicize editor={editor} />
      <EditorActionUnderline editor={editor} />
      <EditorActionStrike editor={editor} />
      {/* <EditorActionCode editor={editor} /> */}
      <EditorActionFontColor editor={editor} />
      <EditorActionHighlight editor={editor} />
      <ToolbarDivider width="1px" />
      <EditorActionAddLink editor={editor} />
      {/* image upload */}
      <EditorActionInsertImage editor={editor} />
      {/* video upload */}
      <ToolbarDivider width="1px" />
      {/* Inside a dropdown */}
      <EditorActionTextAlignLeft editor={editor} />
      <EditorActionTextAlignCenter editor={editor} />
      <EditorActionTextAlignRight editor={editor} />
      <EditorActionTextAlignJustify editor={editor} />
      {/* Close dropdown */}
      <EditorActionListBulleted editor={editor} />
      <EditorActionListNumbered editor={editor} />
      {/* <EditorActionFormatClear editor={editor} /> */}
      {/* <EditorActionUndo editor={editor} /> */}
      {/* <EditorActionRedo editor={editor} /> */}
    </div>
  );
};
