import * as React from 'react';
import { PreviewablePost, usePreview } from 'hooks/content-blocks';
import { useUniqueId } from 'hooks/useUniqueId';
import { Targets } from 'models/publisher/block';
import styles from './post-previews.module.css';

export const Raw: React.FC<{
  post: PreviewablePost;
  width: number;
  height: number;
}> = ({ post, width, height }) => {
  const uuid = useUniqueId();
  const { html } = usePreview(post, Targets.web);
  const wrapper = React.useRef<HTMLDivElement>(null);
  const iframe = React.useRef<HTMLIFrameElement>(null);
  const [written, setWritten] = React.useState(false);
  const ratio = height / width;
  const scale = width / 800;
  const ready = iframe.current;

  React.useEffect(() => {
    if (html.length && ready && iframe.current?.contentDocument && !written) {
      iframe.current.style.transform = `scale(${scale})`;
      iframe.current.style.transformOrigin = `top left`;
      iframe.current.contentDocument.write(html);
      iframe.current.contentDocument.body.style.overflow = 'hidden';
      iframe.current.style.visibility = `visible`;
      setWritten(true);
    }
  }, [scale, html, written, ready]);

  return (
    <div
      ref={wrapper}
      className={styles.iframe}
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      <iframe
        title={uuid}
        id={uuid}
        ref={iframe}
        width={800}
        height={800 * ratio}
      />
    </div>
  );
};
