import {
  ContentFilterBar,
  ContentFilterFetchProps,
} from 'components/content/ContentFilterBar/ContentFilterBar';
import { FiltersStateType } from 'contexts/content/filters';
import { Author } from 'models/author';
import { Topic } from 'models/topic';
import React from 'react';
import styles from 'shared/Library/PickerScreen/header.module.css';

type HeaderProps = {
  filterFetchProps: ContentFilterFetchProps;
  setFilterFetchProps: (value: ContentFilterFetchProps) => void;
  filters: FiltersStateType;
  setValue: (name: string, values: (string | Author | Topic)[]) => void;
  setBooleanValue: (name: string, value: boolean) => void;
};

export const ContentHeader: React.FC<HeaderProps> = ({
  filterFetchProps,
  setFilterFetchProps,
  filters,
  setValue,
  setBooleanValue,
}) => {
  return (
    <div data-test="library-picker-header" className={styles.header}>
      <ContentFilterBar
        fetchProps={filterFetchProps}
        onChange={setFilterFetchProps}
        filters={filters}
        setValue={setValue}
        setBooleanValue={setBooleanValue}
      />
    </div>
  );
};
