import React from 'react';
import { Topic } from '../../../../../../../models/topic';
import { Section } from '../../TopicForm/components/SettingsView/common';
import { Option } from './Option';

interface Props {
  topic: Topic;
}

export function Visibility({ topic }: Props): JSX.Element {
  return (
    <Section title="Visibility">
      <Option
        label="Show in Experience"
        value={topic.published ? 'ON' : 'OFF'}
      />
      {topic.published && (
        <Option
          label="Suggest as Recommended Topic"
          value={topic.isPromoted ? 'ON' : 'OFF'}
        />
      )}
    </Section>
  );
}
