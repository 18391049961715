import { useQuery } from 'react-query';
import { ContentTemplate } from 'models/content-template';
import {
  FetchParams,
  fetchContentTemplates,
  ContentTemplatesCollectionData,
  ContentTemplateData,
} from '../services/api-content-templates';
import { QueryResponse } from './common';

function mapServerDataToContentTemplate(
  data: ContentTemplateData
): ContentTemplate {
  return {
    id: data.id,
    name: data.attributes.name,
    status: data.attributes.status,
  };
}

function mapCollectionSeverDataToContentTemplates(
  data: ContentTemplateData[]
): ContentTemplate[] {
  return data.map(mapServerDataToContentTemplate);
}

export const useContentTemplatesQuery = (
  props: FetchParams
): QueryResponse<Array<ContentTemplate>> => {
  const { status = 'published', pageSize = 200, page = 1 } = props;
  const { isLoading, error, data } = useQuery<
    ContentTemplatesCollectionData,
    Error
  >(
    ['content-templates', { ...props }],
    () => fetchContentTemplates({ ...props, status, pageSize, page }),
    {
      retry: false,
    }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data ? mapCollectionSeverDataToContentTemplates(data.data) : [],
  };
};
