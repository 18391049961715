import * as React from 'react';
import { InfiniteSelect, InfiniteLoadProps } from 'shared/InfiniteSelect';
import { TopicTag } from 'models/topic-tag';
import styles from './topic-tag-select.module.css';

export type PropsType = {
  values: Array<TopicTag>;
  selectedIds: Array<string>;
  onSelectedIdsChange: (ids: Array<string>) => void;
  maxHeight?: number;
} & InfiniteLoadProps;

export const Dropdown: React.FC<PropsType> = (props) => {
  const {
    values,
    selectedIds,
    onSelectedIdsChange,
    maxHeight = 400,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
  } = props;

  const itemHeight = 95;
  const rowIds: Array<string> = values.map((item) => item.id || '');

  const rowRenderProp = (id: string) => {
    const topicTag = values.find((item) => item.id?.toString() === id);
    if (!topicTag) return null;

    const { name, description } = topicTag;
    return (
      <div key={id} className={styles.row}>
        <div className={styles.title}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
    );
  };

  return (
    <div className={styles.dropdown}>
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={rowRenderProp}
        selectedIds={selectedIds.map((id) => id.toString())}
        onSelectedIdsChange={onSelectedIdsChange}
        itemHeight={itemHeight}
        maxHeight={maxHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        threshold={threshold}
        overscan={overscan}
      />
    </div>
  );
};
