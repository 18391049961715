import * as React from 'react';
import cx from 'classnames';
import { StandardColors, isWhite, DARKEST, LIGHTEST } from 'models/colors';
import { useColorPreview } from './useColorPreview';
import styles from './color-preview.module.css';

const ColorButton: React.FC<{
  color: string;
  selection?: string;
  handleChange: (color: string) => void;
  testType?: 'standard' | 'all';
}> = ({ color, selection, handleChange, testType }) => (
  /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
  <button
    type="button"
    data-test={`${testType}-color-picker-value`}
    style={{ backgroundColor: color }}
    className={cx(styles.swatch, {
      [styles.selection]: color === selection,
      [styles.hard2see]: isWhite(color),
    })}
    title={color.toLocaleUpperCase()}
    onClick={() => handleChange(color)}
  />
);

const NoColorButton: React.FC<{
  handleChange: (color: string) => void;
  testType?: 'standard' | 'all';
}> = ({ handleChange, testType }) => (
  /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
  <button
    type="button"
    data-test={`${testType}-color-picker-value`}
    className={cx(styles.swatch, styles.noColor, styles.hard2see)}
    onClick={() => handleChange(PICKER_NO_COLOR)}
  >
    No color
  </button>
);

export const PICKER_NO_COLOR = 'none';

export const Picker: React.FC<{
  onChange: (value: string) => void;
  colors?: string[];
  value?: string;
  includeNone?: boolean;
  hideBrandColors?: boolean;
}> = ({ onChange, colors, value, includeNone, hideBrandColors }) => {
  const picker = useColorPreview({
    onChange,
    value,
  });

  return (
    <div className={styles.pickerWrapper}>
      <div
        data-test="color-picker-preview"
        data-test-color={value}
        style={{ backgroundColor: picker.isValid ? value : LIGHTEST }}
        className={cx(styles.previewColor, {
          [styles.hard2see]: isWhite(value),
        })}
      >
        <div className={styles.valueWrapper}>
          {value !== PICKER_NO_COLOR && picker.error && (
            <div className={styles.error}>{picker.error}</div>
          )}
          <input
            type="text"
            value={value}
            className={styles.input}
            onChange={(e) => picker.onChange(e.target.value)}
            style={{ color: picker.contrast || DARKEST }}
          />
        </div>
      </div>
      <div className={styles.optionsWrapper}>
        {includeNone && (
          <NoColorButton
            key={PICKER_NO_COLOR}
            handleChange={picker.onChange}
            testType="all"
          />
        )}
        {picker.programColors.length > 0 && !hideBrandColors && (
          <div className={styles.allColors}>
            <div className={styles.sectionTitle}>Brand colors</div>
            <div className={styles.colorsWrapper}>
              {picker.programColors.map((hex) => (
                <ColorButton
                  key={hex}
                  color={hex}
                  selection={value}
                  handleChange={picker.onChange}
                  testType="standard"
                />
              ))}
            </div>
          </div>
        )}
        <div className={styles.brandColors}>
          <div className={styles.sectionTitle}>All the colors</div>
          <div className={styles.colorsWrapper}>
            {(colors ?? StandardColors).map((hex) => (
              <ColorButton
                key={hex}
                color={hex}
                selection={value}
                handleChange={picker.onChange}
                testType="all"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
