import React from 'react';
import { Thumbnail } from 'shared/Thumbnail';
import style from './ReportsListStyle.module.css';

export type ReportCardHeaderPanelPropsType = {
  title: string;
  subtitle: string;
  thumbnailInitials: string;
  avatarUrl: string;
};
export const ReportItemCardHeader: React.FC<ReportCardHeaderPanelPropsType> = ({
  title,
  subtitle,
  thumbnailInitials,
  avatarUrl,
}) => {
  /*
   * eventually we want to pass in a user object into this component
   *
   * sample implementation:
   * const tp = userToThumbnail(user);
   * return (
    <div className={style.reportCardHeaderPanel}>
      <Thumbnail
        size="small"
        title={tp.title}
        backgroundColor={tp.backgroundColor}
        color={tp.color}
      />
      <div className={style.reportCardHeaderPanelInfo}>
        <h6 className={style.headerTitle}>{userToName(user)}</h6>
        <p className={style.headerSubtitle}>Dec 12, 2020</p>
      </div>
    </div>
   *
   * But for now we will just pass in simple values
   * */

  return (
    <div className={style.reportCardHeaderPanel}>
      <Thumbnail
        size="small"
        title={thumbnailInitials}
        backgroundColor="#5d6480"
        color="var(--color-gray00)"
        imageUrl={avatarUrl}
      />
      <div className={style.reportCardHeaderPanelInfo}>
        <h6 className={style.headerTitle}>{title}</h6>
        <p className={style.headerSubtitle}>{subtitle}</p>
      </div>
    </div>
  );
};
