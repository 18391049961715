import * as React from 'react';
import { useUser } from 'contexts/user';
import { Audience, defaultAudience, DYNAMIC_TYPE } from 'models/audience';
import { useAudienceEdit, useAudienceUsersQuery } from 'hooks/audience';
import { useProgram } from 'contexts/program';
import { useUniqueId } from 'hooks/useUniqueId';
import { FacetsSidebar } from 'components/audience/FacetsSidebar';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { LeftControls } from './LeftControls';
import { Editor } from './Editor';
import styles from './builder.module.css';

type PropsType = {
  onCloseBuilder: () => void;
  onUseBuiltAudience: (audience: Audience) => void;
  existingAudience?: Audience;
};

export const Builder: React.FC<PropsType> = (props) => {
  const { onCloseBuilder, onUseBuiltAudience, existingAudience } = props;

  const {
    id: userId,
    firstName: userFirstName,
    lastName: userLastName,
  } = useUser();
  const [isPreviewMode, setPreviewMode] = React.useState(false);
  const [isSaveAsMode, setSaveAsMode] = React.useState(false);

  const { id: programId } = useProgram();
  const uniqueId = useUniqueId(6);

  const newDateOperatorsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Audience.NewDateOperators').data
      ?.value as boolean) || false;

  function initialAudience(): Audience {
    const title = `Temporary-audience-${uniqueId}`;
    const type = DYNAMIC_TYPE;
    const id = `${type}.${uniqueId}`;
    return {
      ...defaultAudience({ programId }),
      id,
      title,
      type,
      creatorId: userId,
      creator: `${userFirstName} ${userLastName}`,
    };
  }

  function onSuccessfulSave(savedAudience: Audience) {
    onUseBuiltAudience(savedAudience);
    onCloseBuilder();
  }

  const {
    data: audience,
    setData: setAudience,
    isSaving,
    save,
  } = useAudienceEdit(existingAudience || initialAudience(), {
    onSuccess: onSuccessfulSave,
  });

  const { query = '' } = audience;
  const { data: countData } = useAudienceUsersQuery(programId, query);
  audience.totalUsers = countData ? countData.totalObjects : 0;

  function handleDone() {
    if (isSaveAsMode) {
      save();
    } else {
      onUseBuiltAudience(audience);
      onCloseBuilder();
    }
  }

  return (
    <div className={styles.fullPage}>
      <div className={styles.main}>
        <LeftControls
          audience={audience}
          onCloseBuilder={onCloseBuilder}
          onDone={handleDone}
          isSaving={isSaving}
        />
        <Editor
          audience={audience}
          onAudienceChange={setAudience}
          isPreviewMode={isPreviewMode}
          setPreviewMode={setPreviewMode}
          isSaveAsMode={isSaveAsMode}
          setSaveAsMode={setSaveAsMode}
          newDateOperatorsEnabled={newDateOperatorsEnabled}
        />
      </div>
      <div className={styles.sidebar}>
        <FacetsSidebar audience={audience} />
      </div>
    </div>
  );
};
