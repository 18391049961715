import React, { FC, useCallback, useMemo } from 'react';
import { FlipSwitch } from 'shared/FlipSwitch';
import { uniqueId } from 'hooks/useUniqueId';
import { Control, Label } from './common';
import styles from './styles.module.css';

interface Props {
  name: string;
  checked: boolean;
  onChange: (name: string, on: boolean) => void;
}

const ToggleField: FC<Props> = ({ children, name, checked, onChange }) => {
  const id = useMemo(() => `toggle-${uniqueId()}`, []);
  const handleChange = useCallback(
    (val) => {
      onChange(name, !!val);
    },
    [name, onChange]
  );
  return (
    <div className={styles.ToggleField}>
      <Control>
        <FlipSwitch
          htmlForId={id}
          onChange={handleChange}
          on={checked}
          value
          size="lg"
          shape="rounded"
        />
      </Control>

      <Label htmlFor={id}>{children}</Label>
    </div>
  );
};

export default ToggleField;
