import * as React from 'react';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Workflow } from 'models/workflows';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { ConfirmModal } from 'shared/BulkSelect/Bar/ConfirmModal';
import { FAIcon } from 'shared/Icon/FAIcon';
import { MetricsTooltip } from 'App/Program/Main/ContentMetricsTooltip/MetricsTooltip';
import { useActions } from './useActions';
import { workflowToMenuItems, workflowToThumbnail } from './mappings';

export const Banner: React.FC<{
  workflow: Workflow;
  index: number;
}> = ({ workflow, index }) => {
  const actions = useActions();
  const thumbnail = workflowToThumbnail(workflow, index);
  const menuItems = workflowToMenuItems(workflow, actions);
  const { confirmArchive, archive } = actions;

  const handleConfirmArchive = React.useCallback(() => {
    archive(workflow.id);
    confirmArchive?.closeModal();
  }, [workflow.id, archive, confirmArchive]);

  return (
    <>
      <EmptyBanner
        rowId={`${workflow.id}`}
        thumbnail={thumbnail}
        menuItems={menuItems}
      >
        <FlexLayout.FlexItem widen start>
          <FlexLayout.Flex column>
            <div style={{ textAlign: 'left', padding: '0 20px' }}>
              <Text.Subheading block bold>
                {workflow.name}
              </Text.Subheading>
              <Text.Caption>{workflow.galleryDescription}</Text.Caption>
            </div>
          </FlexLayout.Flex>
        </FlexLayout.FlexItem>
        <FlexLayout.FlexItem start widen>
          <div style={{ paddingLeft: '120px' }}>
            <MetricsTooltip
              data={workflow}
              offset="-80px"
              closeDelay={100}
              openDelay={250}
            >
              <FAIcon name="bar-chart" />
            </MetricsTooltip>
          </div>
        </FlexLayout.FlexItem>
        {Array.isArray(workflow.channels) && (
          <div style={{ width: '150px', textAlign: 'right' }}>
            <Text.Caption>{workflow.channels.join(', ')}</Text.Caption>
          </div>
        )}
        <FlexLayout.Space />
      </EmptyBanner>
      {confirmArchive?.showingModal && (
        <ConfirmModal
          headerTitle="Archive Workflow"
          customTitle="Are you sure you want to archive this workflow?"
          hideLabel
          onCancel={confirmArchive.closeModal}
          onConfirm={handleConfirmArchive}
        />
      )}
    </>
  );
};
