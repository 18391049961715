import * as React from 'react';
import { Link, useLocation } from '@reach/router';
import cx from 'classnames';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import { Icon, IconType } from '../Icon';
import styles from './icon-menu.module.css';

// It's a little unfortunate that this is duplicated from the css, but the SVG icon needs its width
// set explicitly.
const ICON_WIDTH = 15;

export type ItemType = {
  title: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  href?: string;
  newTab?: boolean;
  disabled?: boolean;
  danger?: boolean;
  hoverTooltip?: string;
  iconClassname?: string;
} & IconType;

type PropsType = {
  dismiss?: () => void;
  className?: string;
  layout?: 'classic' | 'journeys';
} & ItemType;

export const IconMenuItem: React.FC<PropsType> = (props) => {
  const {
    title,
    onClick,
    href = '#',
    iconName,
    iconType,
    iconClassname,
    newTab,
    disabled,
    dismiss,
    layout,
    className = '',
    hoverTooltip,
  } = props;

  const location = useLocation();

  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        return;
      }
      if (dismiss) {
        dismiss();
      }
      if (onClick) {
        onClick(e);
      }
    },
    [disabled, dismiss, onClick]
  );

  const extraProps = newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  const item = (
    <div className={styles.MenuItems}>
      <span className={styles.icon}>
        <Icon
          iconName={iconName}
          iconType={iconType}
          className={iconClassname}
          size={ICON_WIDTH}
          useCurrentColor
        />
      </span>
      <span className="spec-title">{title}</span>
    </div>
  );

  const fromUrl = `${location.pathname}${
    location.search ? `${location.search}` : ''
  }`;

  let link = (
    <Link
      to={href}
      onClick={onLinkClick}
      state={{ from: fromUrl }} // eslint-disable-next-line react/jsx-props-no-spreading
      {...extraProps}
    >
      {item}
    </Link>
  );

  // Reach-Router <Link> doesn't handle external urls
  if (/^https?:\/\//.test(href))
    link = ( // eslint-disable-next-line react/jsx-props-no-spreading
      <a href={href} onClick={onLinkClick} {...extraProps}>
        {item}
      </a>
    );

  if (disabled) link = item;

  return (
    <div
      className={cx(className, disabled ? styles.disabled : styles.active, {
        [styles.journeysLayout]: layout === 'journeys',
      })}
    >
      {hoverTooltip ? (
        <HoverTooltip
          align="left"
          content={<Tooltip description={hoverTooltip} />}
          icon={link}
        />
      ) : (
        link
      )}
    </div>
  );
};
