import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import React, { useCallback, useMemo } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { SelectFilter } from 'models/journeys/filter';
import styles from './filters.module.css';

export const SelectFilterComponent: React.FC<{
  filter: SelectFilter;
  onChange: (filter: SelectFilter) => void;
}> = ({ onChange, filter }) => {
  const optionsLabelMap: { [key: string]: string } = useMemo(() => {
    return (
      filter.options?.reduce((acc, option) => {
        return { ...acc, [option.value]: option.label };
      }, {}) || {}
    );
  }, [filter.options]);

  const renderRow = useMemo(
    () => (row: string) => (
      <div key={row}>
        <span className={styles.filterTitle}>
          {optionsLabelMap[row] ?? row}
        </span>
      </div>
    ),
    [optionsLabelMap]
  );

  const onSelectedIdsChange = useCallback(
    (ids: string[]) => {
      onChange({ ...filter, value: ids });
    },
    [filter, onChange]
  );

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const rowIds = (filter.options ?? []).map((o) => o.value);
  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={400}
        itemHeight={32}
        selectedIds={filter.value}
        onSelectedIdsChange={onSelectedIdsChange}
        className="no-search"
        itemClassName="filter-item"
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    );
  }, [rowIds, filter.value, renderRow, onSelectedIdsChange]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  const selectedLabels = filter.value.map((value) => optionsLabelMap[value]);

  return (
    <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
      <TriggerButton name={filter.label} values={selectedLabels} />
    </ClickDropdown>
  );
};
