import * as React from 'react';
import { StyleEditorContext } from 'contexts/publisher/compose/style';
import { StyleData, Styling } from 'models/publisher/block';
import {
  BASE_STYLING,
  ColorField,
  FontField,
  FontOption,
  mergeStyles,
  SizeField,
} from 'models/publisher/style';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from './feature-flags';

type PublisherStyleEditorProps = {
  styleFor: 'global' | string; // global or blockId
  initialStyle?: Styling; // initial global styling - only used for resolving displayValue in the pickers
  initialStyleData: StyleData; // partial styling, merged with defaults
  onChange?: (style: Styling) => void; // emits complete style data
  onChangeBlockStyle?: (style: StyleData) => void; // emits partial style data
  fontOptions: FontOption[];
};

export type PublisherStyleEditor = React.ContextType<typeof StyleEditorContext>;

export function usePublisherStyleEditor(
  props: PublisherStyleEditorProps
): PublisherStyleEditor {
  const { styleFor, onChange, onChangeBlockStyle } = {
    ...props,
  };

  const initialStyleData = props.initialStyleData || {};
  const initialStyle = props.initialStyle || BASE_STYLING;

  const [style, setStyle] = React.useState(
    mergeStyles(BASE_STYLING, initialStyleData)
  );

  const newEditors = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const [blockStyle, setBlockStyle] = React.useState<StyleData>(
    initialStyleData
  );
  const [id, setId] = React.useState(styleFor);

  React.useEffect(() => {
    if (newEditors) {
      if (styleFor !== id) {
        setBlockStyle(initialStyleData);
        setId(styleFor);
      }
    } else if (styleFor !== id) {
      setStyle(mergeStyles(initialStyle, initialStyleData));
      setId(styleFor);
    }

    // Developer Note: -----
    // If you are working in this area, be sure to uncomment
    // out the following eslint-ignore and verify that there
    // is only 1 missing dependency:
    //
    //  - initialStyleData
    //
    // As changes are pushed up, they come back in to this hook.
    // If we react to that normallyl, we'll be in a loop.
    //
    // See: PUB-1011
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleFor, id]);

  const changeStyle = React.useCallback(
    (changed: Styling) => {
      const value = {
        ...changed,
      };
      setStyle(value);
      if (onChange) onChange(value);
    },
    [onChange]
  );

  // there is some code duplication here and in mergeStyleData
  // to make it easier to remove the old editor when the time comes
  // without breaking the new one
  const changeBlockStyle = React.useCallback(
    (changed: Styling | StyleData) => {
      const value = {
        ...changed,
      };
      setBlockStyle(value);
      if (newEditors && onChangeBlockStyle) {
        onChangeBlockStyle(value as StyleData);
      }
    },
    [newEditors, onChangeBlockStyle]
  );

  const mergeStyle = React.useCallback(
    (
      option: Exclude<keyof Styling, 'isCustomStyled'>,
      field: string,
      value: string | number
    ) => {
      return {
        ...style,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [option]: { ...style[option], [field]: value },
      };
    },
    [style]
  );

  const mergeStyleData = React.useCallback(
    (
      option: Exclude<keyof StyleData, 'isCustomStyled'>,
      field: string,
      value: string | number
    ) => {
      return {
        ...blockStyle,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [option]: { ...blockStyle[option], [field]: value },
      };
    },
    [blockStyle]
  );

  const handleMerge = React.useCallback(
    (...args: Parameters<typeof mergeStyle>) => {
      if (newEditors && styleFor !== 'global') {
        changeBlockStyle(mergeStyleData(...args));
      } else {
        changeStyle(mergeStyle(...args));
      }
    },
    [
      newEditors,
      styleFor,
      changeBlockStyle,
      mergeStyleData,
      changeStyle,
      mergeStyle,
    ]
  );

  const colorChanger = React.useCallback(
    (field: ColorField) => (color: string) => {
      handleMerge('colors', field, color);
    },
    [handleMerge]
  );

  const sizeChanger = React.useCallback(
    (field: SizeField) => (size: number) => {
      handleMerge('sizes', field, size);
    },
    [handleMerge]
  );

  const fontChanger = React.useCallback(
    (field: FontField) => (font: string) => {
      handleMerge('fonts', field, font);
    },
    [handleMerge]
  );

  const revertToGlobalStyle = React.useCallback(() => {
    // do not revert styles that can not be changed on global level
    const preservedStyles = JSON.parse(
      // remove undefined
      JSON.stringify({
        sizes: {
          imageWidth: blockStyle.sizes?.imageWidth,
        },
        autoCropping: blockStyle.autoCropping,
      })
    );
    changeBlockStyle(preservedStyles);
  }, [blockStyle, changeBlockStyle]);

  const displayValue = React.useCallback(
    (key: keyof Styling, field: ColorField | SizeField | FontField) => {
      // make ts happy
      const styleData = style[key] as never;
      const blockStyleData = blockStyle[key] as never;
      if (newEditors) {
        if (styleFor === 'global') {
          return styleData[field];
        }
        if (blockStyleData && blockStyleData[field]) {
          return blockStyleData[field];
        }
        return (props.initialStyle as never)[key][field];
      }

      return styleData[field];
    },
    [style, blockStyle, newEditors, styleFor, props.initialStyle]
  );

  return {
    style,
    blockStyle,
    colorChanger,
    sizeChanger,
    fontChanger,
    changeStyle,
    changeBlockStyle,
    revertToGlobalStyle,
    displayValue,
    fontOptions: props.fontOptions,
  };
}
