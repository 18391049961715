import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { SocialData, LinkData } from 'models/publisher/block';
import { Box } from 'DesignSystem/Components';
import { ConfirmDeleteV2 as ConfirmDelete } from 'shared/ConfirmDelete';
import { SettingsCogGear, Trash } from 'shared/icons';
import { DragHandle } from 'shared/DragHandle';
import styles from './source_menu.module.css';

export const LinkPreview: React.FC<{
  isDragging?: boolean;
  onEdit: () => void;
  onRemove?: () => void;
  link: SocialData | LinkData;
}> = ({ link, onEdit, onRemove }) => {
  const { title, image } = link;
  const [isDeleting, setDeleting] = React.useState(false);

  const showDialog = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleting(true);
  }, []);

  const remove = React.useCallback(
    (event?: React.MouseEvent) => {
      setDeleting(false);
      if (onRemove) onRemove();
      if (event) event.stopPropagation();
    },
    [onRemove]
  );

  return (
    <Box
      radius={4}
      relative
      data-test="block-link-editor-preview"
      color={Text.background.gray05}
    >
      {isDeleting && (
        <ConfirmDelete
          entity="Link"
          onRemove={remove}
          cancelRemove={() => setDeleting(false)}
        />
      )}
      <Flex center className={styles.linkPreviewSideBar}>
        <DragHandle />
        <div
          style={{
            backgroundColor: 'transparent',
            borderRadius: '4px',
            height: '40px',
            width: '40px',
            margin: '0 16px',
            backgroundImage: `url(${image?.url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            flexShrink: '0',
          }}
        />
        <FlexItem widen start className={styles.linkPreviewTitle}>
          <div>
            <Text.Body bold>{title.value}</Text.Body>
          </div>
        </FlexItem>
        <Button
          title="Edit Link"
          minimal
          clearText
          icon={<SettingsCogGear style={{ margin: '0 4px', width: '20px' }} />}
          onClick={onEdit}
        />
        {onRemove && (
          <Button
            title="Remove Link"
            minimal
            clearText
            icon={<Trash style={{ width: '18px' }} />}
            onClick={showDialog}
          />
        )}
      </Flex>
    </Box>
  );
};
