import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import {
  background,
  Body,
  border,
  FieldLabel,
  Subheading,
} from 'DesignSystem/Typography';
import { Plus } from 'shared/icons';
import { ReactComponent as PhonePushNotif } from 'shared/icons/IPhone.svg';
import { ReactComponent as Mail } from 'shared/icons/Mail2.svg';
import { ReactComponent as DeleteTrash } from 'shared/icons/Delete.svg';

import {
  defaultFirstNotification,
  Notification,
  useNotificationsValidator,
} from 'models/notification';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { selectedChannels } from 'models/channel';
import {
  PREVIEW_TEXT_PLACEHOLDER,
  TEXT_PLACEHOLDER,
  useNotifications,
} from '../../../Notifications/useNotifications';
import { ContentTitle } from '../../../SettingsCard';
import { SaveModalButtons } from '../../../Shared/SaveModalButtons';
import { NotificationField } from '../../../Notifications/NotificationField';
import styles from './edit-notifications.module.css';
import { InfoTooltip } from '../../../Shared/InfoTooltip';

export const EditNotifications: React.FC<{
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  setDismissable?: (dismissable: boolean) => void;
}> = ({ disabled = false, onCancel, onSave }) => {
  const {
    defaultNotificationPreviewText,
    defaultNotificationText,
    firstNotification,
    onNotificationsChange,
    reTargetingNotifications,
  } = useNotifications();

  const {
    contentPermissions: { restrictedFields },
    settings: { deliveryChannels },
  } = useSettings();

  const emailChannel = deliveryChannels?.email;
  const pushChannel = deliveryChannels?.push;

  const emailNotPermitted = restrictedFields.includes(
    RestrictedFields.SEND_EMAIL
  );
  const pushNotPermitted = restrictedFields.includes(
    RestrictedFields.SEND_PUSH
  );
  const notPermitted = emailNotPermitted && pushNotPermitted;

  const [localReTargetingNotifs, setLocalReTargetingNotifs] = useState<
    Notification[]
  >([...reTargetingNotifications]);

  const [formDirty, setFormDirty] = useState(false);
  const notifsAreValid = useNotificationsValidator(
    localReTargetingNotifs,
    selectedChannels(deliveryChannels).filter(
      (channel) => channel !== 'assistant'
    )
  );

  const disableAdd = disabled || !notifsAreValid || notPermitted;
  const disableSave = disableAdd || !formDirty;

  // Once we create/update notification from delivery page, we do not sync content from cover page.
  const createNotification = () => {
    const newNotification: Notification = {
      ...defaultFirstNotification,
      text: defaultNotificationText,
      previewText: defaultNotificationPreviewText,
      pushText: defaultNotificationText,
      order: localReTargetingNotifs.length + 1,
    };
    setLocalReTargetingNotifs([...localReTargetingNotifs, newNotification]);
    setFormDirty(true);
  };

  const updateNotification = (data: Partial<Notification>, index: number) => {
    const updatedNotifs = [...localReTargetingNotifs];
    updatedNotifs[index] = {
      ...updatedNotifs[index],
      ...data,
    };
    setLocalReTargetingNotifs(updatedNotifs);
    setFormDirty(true);
  };

  const deleteNotification = (index: number) => {
    const updatedNotifs = [...localReTargetingNotifs];
    updatedNotifs.splice(index, 1);
    setLocalReTargetingNotifs(updatedNotifs);
    setFormDirty(true);
  };

  const onNotificationsSave = useCallback(() => {
    onNotificationsChange([firstNotification, ...localReTargetingNotifs]);
    if (onSave) onSave();
  }, [
    firstNotification,
    localReTargetingNotifs,
    onNotificationsChange,
    onSave,
  ]);

  useEffect(() => {
    if (localReTargetingNotifs.length === 0 && formDirty) {
      onNotificationsSave();
    }
  }, [formDirty, localReTargetingNotifs.length, onNotificationsSave]);

  // when this section first renders, create a notifications if there's none, saving the user a click.
  useEffect(() => {
    if (localReTargetingNotifs.length === 0) createNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailNotificationFields = (
    notification: Notification,
    index: number
  ) => (
    <>
      <Box margin={[12, 0, 0, 12]} style={{ display: 'flex' }}>
        <Box className={styles.Icon}>
          <Mail />
        </Box>
        <Box padding={[0, 12]}>
          <Body>Email</Body>
        </Box>
      </Box>
      <Box style={{ width: '100%' }} padding={[0, 30]} margin={[20, 0]}>
        <Box dataTest="subject-line" margin={[16, 0, 0, 0]}>
          <FieldLabel>Subject Line</FieldLabel>
          <NotificationField
            field={notification.text}
            onChange={(text) => {
              updateNotification({ ...notification, text }, index);
            }}
            fieldName=""
            placeholder={TEXT_PLACEHOLDER}
            disabled={disabled || emailNotPermitted}
          />
        </Box>
        <Box dataTest="preview" margin={[-12, 0, 0, 0]}>
          <FieldLabel>Preview</FieldLabel>
          <NotificationField
            field={notification.previewText || ''}
            onChange={(previewText) => {
              updateNotification({ ...notification, previewText }, index);
            }}
            fieldName=""
            placeholder={PREVIEW_TEXT_PLACEHOLDER}
            disabled={disabled || emailNotPermitted}
          />
        </Box>
      </Box>
    </>
  );

  const pushNotificationFields = (
    notification: Notification,
    index: number
  ) => (
    <>
      <Box margin={[12, 0, 0, 12]} style={{ display: 'flex' }}>
        <Box className={styles.Icon}>
          <PhonePushNotif />
        </Box>
        <Box padding={[0, 12]}>
          <Body>Push Notification</Body>
        </Box>
      </Box>
      <Box
        dataTest="push-notification"
        style={{ width: '100%' }}
        padding={[0, 30]}
      >
        <Box margin={[16, 0, 0, 0]}>
          <FieldLabel>Message</FieldLabel>
          <NotificationField
            field={notification.pushText || ''}
            onChange={(pushText) => {
              updateNotification({ ...notification, pushText }, index);
            }}
            fieldName=""
            placeholder={TEXT_PLACEHOLDER}
            disabled={disabled || pushNotPermitted}
          />
        </Box>
      </Box>
    </>
  );

  const withNotifications = localReTargetingNotifs.length > 0;

  return (
    <Box>
      <Box>
        <Box>
          <ContentTitle>
            Retargeting Notification
            <InfoTooltip
              message={
                <>
                  <Box>
                    Retargeting notification text will be used in the order
                    configured. If the orchestration engine decides to make
                    additional deliveries of the campaign, the last configured
                    text will be used for those retargeting attempts.
                  </Box>
                  <Box margin={['1em', 0, 0, 0]}>
                    Note: If no text configuration is made here, the engine will
                    leverage the initial subject line/push text for future
                    deliveries.
                  </Box>
                </>
              }
            />
          </ContentTitle>
          <Box margin={[-15, 0, -8, 0]}>
            <Body>
              Add retargeting notifications to send different messages per
              attempt to users who have not engaged.
            </Body>
          </Box>
        </Box>

        {localReTargetingNotifs.map((notification, index) => {
          return (
            <Box
              padding={24}
              margin={[24, 0, 0, 0]}
              border={[
                border.solid,
                border.width1,
                border.radius8,
                border.gray10,
              ]}
              background={background.gray00}
            >
              <Box margin={[0, 0, 20, 0]} style={{ display: 'flex' }}>
                <Subheading>
                  {`Retargeting Notification #${index + 1}`}
                </Subheading>
                <Box margin={['auto', 0, 'auto', 'auto']}>
                  <Button
                    compact
                    borderless
                    onClick={() => deleteNotification(index)}
                    label={<DeleteTrash width={13} height={16} />}
                    secondary
                    disabled={disabled || notPermitted}
                  />
                </Box>
              </Box>
              {emailChannel && emailNotificationFields(notification, index)}
              {pushChannel && pushNotificationFields(notification, index)}
            </Box>
          );
        })}

        <Box
          padding={[16, 0, 0, 0]}
          margin={withNotifications ? 0 : [20, 0, 0, 0]}
        >
          <Button
            disabled={disableAdd}
            compact
            onClick={createNotification}
            label={
              <>
                <Plus />
                <Box padding={[0, 12]}>
                  {withNotifications ? 'Add Another' : 'Add Notification'}
                </Box>
              </>
            }
            secondary
          />
        </Box>

        <SaveModalButtons
          onCancel={onCancel}
          onSave={onNotificationsSave}
          disabled={disableSave}
        />
      </Box>
    </Box>
  );
};
