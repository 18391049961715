import type { Editor } from '@tiptap/react';
import React from 'react';
import { useClickDropdown } from 'shared/ClickDropdown';
import { Picker } from 'shared/ColorPreview';
import { MAIcon } from 'shared/MAIcon';
import editorStyles from '../editor.module.css';
import { ToolbarButton } from './shared/ToolbarButton';

const DEFAULT_FONT_CODE = '#000000';
export const EditorActionFontColor: React.FC<{ editor: Editor }> = ({
  editor,
}) => {
  const { ClickDropdown } = useClickDropdown();
  const editorFontColor =
    editor.getAttributes('textStyle').color ?? DEFAULT_FONT_CODE;

  return (
    <ClickDropdown
      dropdownRenderProp={() => (
        <Picker
          onChange={(value) => {
            editor.chain().focus().setColor(value).run();
          }}
          value={editorFontColor}
        />
      )}
    >
      <ToolbarButton
        minimal
        layoutOnly
        className={[
          editorStyles.tiptapButton,
          editorStyles.fontColorSelectButton,
        ]
          .filter(Boolean)
          .join(' ')}
        aria-label="Font Color"
        disabled={!editor.can().setColor('')}
        icon={
          <>
            <MAIcon
              name="text_format"
              className={editorStyles.fontColorSelectForegroundIcon}
            />
            <MAIcon
              name="text_format"
              className={editorStyles.fontColorSelectBackgroundIcon}
              style={{ color: editorFontColor }}
            />
          </>
        }
      />
    </ClickDropdown>
  );
};
