/**
 * Why this file, too?
 *
 * Quick overview:
 * - models/feature-flags.ts
 *   Defines the resource fetched by Studio
 * - hooks/feature-flags.ts
 *   Provides low-level access to the Studio API
 * - contexts/feature-flags.ts
 *   Provides a hook that can be used to get the data
 *
 * What is missing?
 * - The "logical" feature flags that aren't a part of
 *   our schema, but make the code more readable.
 * - A easier to use API with boilerplate `flags.name?.value`
 *
 * Usage:
 * - Do you want a table indexed by name?
 *   const { myFlag, flagTwo } = useFeatureFlags();
 * - Or ko you want only one flag?
 *   const myFlag = useFeatureFlag('myFlag');
 *
 * In both cases, the value of the flag is _not_ `FeatureFlag`
 * it is the value type: `FeatureFlag['value']`.
 */
import { useFeatureFlags as useFeatureFlagsContext } from 'contexts/feature-flags';
import { FeatureFlag, FeatureFlags } from 'models/feature-flag';

type ExtendedFlags = FeatureFlags & {
  emailOnly: FeatureFlag;
  hiddenPollBlocks: FeatureFlag;
};

type FlagName = keyof ExtendedFlags;

function mapDerivedFeatureFlags(flags: FeatureFlags): ExtendedFlags {
  return {
    ...flags,
    emailOnly: {
      value: !!flags.emailTokenAuthEnabled?.value,
      programId: flags.emailTokenAuthEnabled?.programId ?? 0,
      type: 'boolean',
      label: 'Program uses email only.',
    },
    hiddenPollBlocks: {
      // don't need to architect away this present coincidence..
      value: !!flags.emailTokenAuthEnabled?.value,
      programId: flags.emailTokenAuthEnabled?.programId ?? 0,
      type: 'boolean',
      label: 'Can use poll blocks when creating v2 content.',
    },
  };
}

type Value = FeatureFlag['value'] | undefined;

export function useFeatureFlags(): Record<keyof ExtendedFlags, Value> {
  const flags = mapDerivedFeatureFlags(useFeatureFlagsContext());
  const names = Object.keys(flags) as Array<keyof ExtendedFlags>;
  return names.reduce((map, name: keyof ExtendedFlags) => {
    // eslint-disable-next-line no-param-reassign
    map[name] = flags[name]?.value;
    return map;
  }, {} as Record<keyof ExtendedFlags, Value>);
}

export function useFeatureFlag(name: FlagName): Value {
  // TODO: This gets called a lot when going to create page
  const flags = useFeatureFlags();
  return flags[name];
}
