import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useActions } from './useActions';
import { SlugEditForm } from './components/SlugEditForm';

export const CustomSlugsNew: React.FC<RouteComponentProps> = () => {
  const { create } = useActions();

  return <SlugEditForm action={create} />;
};
