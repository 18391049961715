import React from 'react';
import { Initiative } from 'models/initiative';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Trash } from 'shared/icons';
import styles from './sidebar.module.css';

export const Sidebar: React.FC<{
  initiative: Initiative;
  archive: (initiative: Initiative) => void;
  unarchive: (initiative: Initiative) => void;
  destroy: (initiative: Initiative) => void;
}> = ({ initiative, archive, unarchive, destroy }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <button
          type="button"
          onClick={() => {
            if (initiative.isActive) {
              archive(initiative);
            } else {
              unarchive(initiative);
            }
          }}
          className={styles.action}
        >
          <span>
            <SVGIcon name="Folder" />
          </span>
          <span>{initiative.isActive ? 'Archive' : 'Unarchive'}</span>
        </button>

        <button
          type="button"
          onClick={() => {
            destroy(initiative);
          }}
          className={styles.action}
        >
          <span>
            <Trash />
          </span>
          <span>Delete</span>
        </button>
      </div>
    </div>
  );
};
