import * as React from 'react';
import cx from 'classnames';
import { Icon } from 'shared/Icon';
import styles from './Button.module.css';
import { ButtonType } from '../Button';

export const Button: React.FC<
  ButtonType & {
    tabIndex?: number;
    className?: string;
    iconLocation?: 'left' | 'right';
    id?: string;
  }
> = (props) => {
  const {
    onClick,
    disabled,
    tabIndex,
    className,
    dataTest,
    type = 'primary',
    iconLocation = 'left',
    iconSize,
    useCurrentColor,
    compact,
    children,
    // IconType
    iconType,
    iconName,
    id,
  } = props;
  const clickHandler = React.useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [onClick, disabled]);

  const icon = !iconName ? undefined : (
    <Icon
      iconName={iconName}
      iconType={iconType}
      size={iconSize || (compact ? 13 : 14)}
      useCurrentColor={useCurrentColor}
    />
  );

  return (
    <button
      id={id}
      type="button"
      className={cx(styles.button, className, styles[type], {
        [styles.compact]: compact,
      })}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={clickHandler}
      data-test={dataTest}
    >
      {icon && iconLocation === 'left' && (
        <div className={styles.left}>{icon}</div>
      )}
      {children}
      {icon && iconLocation === 'right' && (
        <div className={styles.right}>{icon}</div>
      )}
    </button>
  );
};
