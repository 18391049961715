export const StandardColors = [
  '#000000',
  '#E22727',
  '#E28C27',
  '#E2D027',
  '#27E25B',
  '#27E2E2',
  '#275BE2',
  '#C827E2',
  '#E22781',
  '#4D4D4D',
  '#9E0909',
  '#995B11',
  '#A49107',
  '#0EA337',
  '#0C9595',
  '#103187',
  '#79098B',
  '#870B47',
  '#FFFFFF',
  '#FB8484',
  '#F7D5AE',
  '#FDF6B9',
  '#8BF7A9',
  '#81F7F7',
  '#92B0FB',
  '#F0A3FD',
  '#FDA9D1',
  '#D3D3D3',
  '#E94865',
  '#F5CB5D',
  '#D8DA6B',
  '#1CDB96',
  '#5BAFCA',
  '#4D4AFA',
  '#C26FDF',
  '#F52872',
];

export const VideoJsColors = [
  '#000',
  '#00F',
  '#0FF',
  '#0F0',
  '#F0F',
  '#F00',
  '#FFF',
  '#FF0',
];

export const COLOR_REGEX = /^#(?:[0-9a-f]{3}){1,2}$/i;

export const INVALID_HEX_MSG = 'Please use a valid hex color (ex: #01AB77)';

export function isValidHex(hex?: string): hex is string {
  if (hex) {
    return !!hex.match(COLOR_REGEX);
  }
  return false;
}

// no news is good news.
export function validateHex(hex = ''): string {
  return isValidHex(hex) ? '' : INVALID_HEX_MSG;
}

export function isWhite(hex = ''): boolean {
  return ['WHITE', '#FFF', '#FFFFFF'].includes(hex);
}

export const DARKEST = '#000000';
export const LIGHTEST = '#FFFFFF';
