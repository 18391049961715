import React from 'react';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { SearchInput } from 'shared/SearchInput';
import { Pills } from 'DesignSystem/Components/Pills';
import { Box } from 'DesignSystem/Components';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import * as Text from 'DesignSystem/Typography';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';

type CaptionsTranslationProps = {
  disabled?: boolean;
  selectedLanguages: string[];
  setSelectedLanguages: (languages: string[]) => void;
};

export const CaptionsTranslation: React.FC<CaptionsTranslationProps> = ({
  disabled,
  selectedLanguages,
  setSelectedLanguages,
}) => {
  const { availableLanguages } = useCaptionsJobs();

  const [search, setSearch] = React.useState('');
  const rowIds = Object.keys(availableLanguages).filter((key) => {
    const value = availableLanguages[key];
    const lowercaseSearch = search.toLowerCase();
    return (
      key.includes(lowercaseSearch) ||
      value.toLowerCase().includes(lowercaseSearch)
    );
  });
  const renderRow = (rowId: string) => {
    const item = availableLanguages[rowId];
    return item ? <Text.Body>{item}</Text.Body> : null;
  };

  const sortLanguages = (a: string, b: string) =>
    availableLanguages[a].localeCompare(availableLanguages[b]);

  const dropdown = (
    <InfiniteSelect
      rowIds={rowIds}
      rowRenderProp={renderRow}
      maxHeight={300}
      itemHeight={40}
      selectedIds={selectedLanguages}
      onSelectedIdsChange={setSelectedLanguages}
      hasNextPage={false}
      isFetchingNextPage={false}
      isLoading={false}
      allOptionId="all"
    />
  );

  return (
    <Box padding={[0, 0, 20, 0]}>
      <Box padding={[8, 0, 8, 0]}>
        <Text.Body>Select languages for auto-translation:</Text.Body>
      </Box>
      <ClickDropdown
        disabled={disabled}
        dropdownRenderProp={dropdown}
        matchReferenceWidth
        autoUpdate
        referencePress={false}
        onClose={() => {
          setSearch('');
        }}
        ignoreKeys
      >
        <div>
          <SearchInput
            placeholder="Search languages"
            value={search || ''}
            onChange={setSearch}
          />
        </div>
      </ClickDropdown>
      {selectedLanguages.length > 0 && (
        <Pills
          key="translation-languages"
          values={selectedLanguages
            .sort(sortLanguages)
            .map((language) => ({ id: language }))}
          render={(value) => <>{availableLanguages[value.id]}</>}
          onClose={(t) => {
            setSelectedLanguages(selectedLanguages.filter((l) => l !== t.id));
          }}
          padding={[0, 12, 0, 8]}
        />
      )}
    </Box>
  );
};
