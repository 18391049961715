import React from 'react';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { usePermissions } from 'contexts/permissions';
import { MetricsButton } from './MetricsButton';
import { MetricsItem, Stat } from './MetricsItem';
import styles from './results-page.module.css';

export type Metric = {
  name: string;
  highlight?: boolean;
  stat: Stat;
  tooltip?: string | React.ReactElement;
  isLoading?: boolean;
  style?: React.CSSProperties;
};

export type MetricsSubsection = {
  id: string;
  style?: React.CSSProperties;
  subtitle?: React.ReactElement;
  metrics: Array<Metric>;
  addon?: React.ReactElement;
};

export const MetricsSection: React.FC<{
  title: string;
  description: string;
  reportLink?: {
    label: string;
    href: string;
    dataPendo?: string;
  };
  subsections?: Array<MetricsSubsection>;
}> = React.memo(({ title, description, reportLink, subsections }) => {
  const {
    permissions: { insightsAccess },
  } = usePermissions();

  return (
    <div className={cx(styles.block, styles.metrics)}>
      <header>
        <Flex spread>
          <Text.Heading bold>{title}</Text.Heading>
          {reportLink && insightsAccess && (
            <MetricsButton
              dataPendo={reportLink.dataPendo}
              href={reportLink.href}
              label={reportLink.label}
            />
          )}
        </Flex>
        <Text.Body color={Text.color.gray50}>{description}</Text.Body>
      </header>
      {subsections && (
        <Flex column style={{ width: '100%' }}>
          {subsections?.map(
            ({ id, style: subsectionStyle, subtitle, metrics, addon }, idx) => (
              <React.Fragment key={id}>
                <section id={id}>
                  {subtitle}
                  <div className={styles.subsection} style={subsectionStyle}>
                    {metrics.map((metric) => (
                      <MetricsItem
                        key={metric.name}
                        name={metric.name}
                        stat={metric.stat}
                        highlight={metric.highlight}
                        tooltip={metric.tooltip}
                        isLoading={metric.isLoading}
                        style={metric.style}
                      />
                    ))}
                  </div>
                  {addon}
                </section>
                {idx < subsections.length - 1 && <hr />}
              </React.Fragment>
            )
          )}
        </Flex>
      )}
    </div>
  );
});
