/**
 * Type helper to allow for literal autocompletion in IDE but also allow for any string value
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type LooseAutocomplete<T extends string> = T | (string & {});

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeWords(
  str: string,
  exceptions?: Record<string, string>
): string {
  return str
    .split(' ')
    .map((word) => exceptions?.[word.toLowerCase()] ?? capitalize(word))
    .join(' ');
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
