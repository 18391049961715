import { Button } from 'DesignSystem/Form';
import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Plus } from 'shared/icons';
import styles from './journey-list-page.module.css';

export const BlankJourneyButton: React.FC = () => {
  return (
    <Button
      layoutOnly
      href="../new/edit"
      className={styles.templateButton}
      label={
        <Flex start className={styles.blankCanvasJourneyLink}>
          <div className={styles.plusSvgContainer}>
            <Plus />
          </div>
          <div>Blank Canvas</div>
        </Flex>
      }
    />
  );
};
