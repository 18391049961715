/**
 * Asserts an invariant at the current point in the code. An invariant is a condition that must always be true
 * for the program to continue running correctly. If this assumption is false, it indicates a critical flaw in
 * the application logic, and we must not continue. In such cases, this function throws an error with a provided
 * message to clearly indicate what went wrong.
 *
 * @throws {Error} Throws when the condition is false.
 */
export function asserts(
  condition: unknown,
  message: string
): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
