import { Item } from 'models/library';
import React from 'react';
import { Icon } from 'shared/Icon';
import { TextInput } from 'shared/TextInput';
import { pluralize } from 'utility/text';
import { UseCollection } from './useCollection';
import styles from './header.module.css';

type PropsType = {
  collection: UseCollection<Item>;
  label: string;
  maxSelections: number;
  onSearchTextChange?: (text: string) => void;
};

export const Header: React.FC<PropsType> = ({
  collection,
  label,
  maxSelections,
  onSearchTextChange,
}) => {
  // The search text in the collection shares a consistent state
  // with the rest of the collection. We want to defer any actual
  // searching until the user has typed a couple characters.
  // So this component keeps its own search text, and sends upstream
  // updates only when it makes sense for the UX. Right now, 2 chars.
  const [searchText, setSearchText] = React.useState(
    collection.filter.type === 'search' ? collection.filter.search : ''
  );
  const changeSearchText = React.useCallback(
    (text: string) => {
      setSearchText(text);
      if (onSearchTextChange) {
        onSearchTextChange(text);
      }
      if (text.length >= 2) collection.filterBySearch(text);
      else if (text.length === 0 && collection.filter.type === 'search')
        collection.clearFilter();
    },
    [collection, onSearchTextChange]
  );
  // Since we are keeping our own copy, we need to watch for the collection
  // filter type to change away from search, and clear our copy out.
  const [wasType, setWasType] = React.useState(collection.filter.type);
  React.useEffect(() => {
    if (collection.filter.type !== wasType) {
      setWasType(collection.filter.type);
      if (collection.filter.type !== 'search') {
        setSearchText('');
        if (onSearchTextChange) {
          onSearchTextChange('');
        }
      }
    }
  }, [collection, wasType, onSearchTextChange]);

  const selectedClick = React.useCallback(() => {
    if (collection.filter.type === 'selected') {
      collection.clearFilter();
    } else if (collection.selection.length > 0) {
      collection.filterBySelected();
    }
  }, [collection]);

  // If the user is viewing the selected blocks, (the UI doesn't not make it
  // obvious that this is even possible right now), and they unselect their
  // items one by one, eventually they are left with an empty screen. This
  // puts them back to the default screen for convenience.
  React.useEffect(() => {
    if (
      collection.selection.length === 0 &&
      collection.filter.type === 'selected'
    )
      collection.clearFilter();
  }, [collection]);

  return (
    <div data-test="library-picker-header" className={styles.header}>
      <div className={styles.search}>
        <div className={styles.searchIcon}>
          <Icon iconName="search" iconType="SVG" />
        </div>
        <TextInput
          className={styles.searchInput}
          onChange={changeSearchText}
          value={searchText}
          placeholder={`Search ${pluralize(label)}`}
        />
        {/*
        <div className={styles.searchAppliedFilters}>Status is Published</div>
        <div className={styles.searchAllFilters}>
          <Icon iconName="SearchFilter" iconType="SVG" />
        </div>
        */}
      </div>
      <div className={styles.selection}>
        <button
          className={styles.selected}
          type="button"
          onClick={selectedClick}
          disabled={!collection.selection.length}
        >
          Selected {pluralize(collection.selection.length, label)} (
          {collection.selection.length}/
          <span data-test="max-selections">{maxSelections}</span>)
        </button>
        {/*
        <div className={styles.quickFilters}>
          <button disabled type="button" className={styles.active}>
            Recent
          </button>
          <button disabled type="button">
            Popular
          </button>
          <button disabled type="button">
            Favorited
          </button>
        </div>
        */}
      </div>
    </div>
  );
};
