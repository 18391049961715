import React from 'react';
import { FormSection, FormSubsection } from 'DesignSystem/Form';
import { CustomSlug } from 'models/custom-slug';
import { TextInput } from 'shared/TextInput';
import styles from '../custom-slugs.module.css';
import { validateName, validateSlug } from '../validations';

type FormProps = {
  slugFormFields: Partial<CustomSlug>;
  setSlugFormFields: React.Dispatch<React.SetStateAction<Partial<CustomSlug>>>;
  formFieldErrors: SlugFieldsError;
  setFormFieldErrors: React.Dispatch<React.SetStateAction<SlugFieldsError>>;
};

export type SlugFieldsError = {
  slugErrorMessage: string | undefined;
  nameErrorMessage: string | undefined;
};

const formatSlug = (value: string) => {
  return value
    .normalize('NFKD')
    .trim()
    .replace(/\s+/g, '-') // Replace all consecutive spaces with a single dash
    .replace(/_+/g, '-') // Replace consecutive underscores with a single dash
    .replace(/-+/g, '-') // Replace consecutive dashes with a single dash
    .toLowerCase();
};

export const SlugsForm: React.FC<FormProps> = ({
  slugFormFields,
  setSlugFormFields,
  formFieldErrors,
  setFormFieldErrors,
}) => {
  const { slug, name } = slugFormFields;

  const [slugText, setSlugText] = React.useState(slug || '');

  const onChangeUrlField = (value: string) => {
    const { errorMessage } = validateSlug(value);
    setFormFieldErrors((prevState) => ({
      ...prevState,
      slugErrorMessage: errorMessage,
    }));
    setSlugText(value);
    setSlugFormFields((prevState) => ({
      ...prevState,
      slug: formatSlug(value),
    }));
  };

  const onChangeNameField = (value: string) => {
    const { errorMessage } = validateName(value);
    setFormFieldErrors((prevState) => ({
      ...prevState,
      nameErrorMessage: errorMessage,
    }));

    setSlugFormFields((prevState) => ({
      ...prevState,
      name: value,
    }));
  };

  return (
    <FormSection title="General" className={styles.customUrlForm}>
      <FormSubsection title="Custom Link Text" divider={false}>
        <span className={styles.formFieldInfo}>
          Will appear at the end of the URL
        </span>
        <TextInput
          className={
            formFieldErrors.slugErrorMessage
              ? styles.textInputError
              : styles.textInput
          }
          placeholder="Type link text"
          // The slug text will show on real time the current format, slug will show
          // as fallback for edition and fi it is empty will be for a new creation record
          value={slugText || slug}
          onChange={onChangeUrlField}
        />
        <span className={styles.formFieldExample}>
          {`domain.com/contents/${slug || 'custom-link-text'}`}
        </span>
        {formFieldErrors.slugErrorMessage && (
          <span className={styles.formFieldErrorMessage}>
            {formFieldErrors.slugErrorMessage}
          </span>
        )}
      </FormSubsection>
      <FormSubsection title="Description">
        <span className={styles.formFieldInfo}>
          Description/nickname for the URL Alias that will only appear in Studio
        </span>
        <TextInput
          className={
            formFieldErrors.nameErrorMessage
              ? styles.textInputError
              : styles.textInput
          }
          placeholder="Type a description"
          value={name}
          onChange={onChangeNameField}
        />
        {formFieldErrors.nameErrorMessage && (
          <span className={styles.formFieldErrorMessage}>
            {formFieldErrors.nameErrorMessage}
          </span>
        )}
      </FormSubsection>
    </FormSection>
  );
};
