import React from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useResultsSummaryQuery } from 'hooks/content-insights';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { Button } from 'DesignSystem/Form';
import { ConfirmModal } from 'shared/BulkSelect/Bar/ConfirmModal';
import { Edit, View } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { DateTime, Duration, Interval } from 'luxon';
import { useActions } from '../ContentResultsPage/useActions';
import { MetricsV2Section } from './MetricsV2Section';
import { useFeatureFlagsQuery } from '../../../../../hooks/feature-flags';
import styles from '../ContentResultsPage/results-page.module.css';
import { useCampaignStatus } from '../../../../../hooks/content/useCampaignStatus';
import { HoverIconMenu } from '../../../../../shared/hover-dropdown/HoverIconMenu';
import { ItemType as IconMenuItemType } from '../../../../../shared/IconMenu';
import { useContentReportUrls } from '../../../../../hooks/content/useContentReportUrls';
import { useCampaignResults } from '../../../../../hooks/campaign-results';
import { truncate } from '../../../../../utility/text';
import { Tooltip } from '../../../../../DesignSystem/Components';

type LocationState = {
  from: string;
};

export const ContentResultsPageV2: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { post, status } = usePublisher();
  const {
    contentPermissions: { canEdit, isLoading: isLoadingPermissions },
  } = useSettings();
  const { state, pathname } = useLocation();
  const locationState = state as LocationState;

  const {
    canArchive,
    isDuplicating,
    showingArchiveModal,
    showArchiveModal,
    closeArchiveModal,
    handleDuplicate,
    handleConfirmArchive,
  } = useActions(programId, post);

  // TODO: Should be called conditionally based on hasExecution
  const {
    data: summary,
    // isLoading: isLoadingSummary,
  } = useResultsSummaryQuery({ contentId: post.content.id });

  const showDeliverySection =
    summary?.executionState && Object.keys(summary.email).length > 0;
  const isShowingEmail = pathname.endsWith('email');
  const { results, isLoading: campaignResultsLoading } = useCampaignResults(
    post.content,
    post.settings
  );

  const metricsV2Enabled = useFeatureFlagsQuery(
    programId,
    'Studio.Intelligence.CampaignSummaryV2.Enabled'
  ).data?.value;

  const contentCanOnlyPreview = !canEdit;

  const { timeLeft, startDate, endDate } = useCampaignStatus(post, summary);
  const {
    getArticleUrl,
    getDeliveryDetailsUrl,
    getPollUrl,
    getVideoUrl,
  } = useContentReportUrls({
    content: post.content,
    startedAt: startDate,
    version: 2,
  });

  const action = React.useMemo(() => {
    const hoverMenuItems: Array<IconMenuItemType> = [];
    if (!contentCanOnlyPreview) {
      hoverMenuItems.push({
        title: 'Duplicate Campaign',
        onClick: handleDuplicate,
        iconName: 'copy',
        iconClassname: 'fa-regular',
        disabled: isDuplicating,
      });
      if (canArchive) {
        hoverMenuItems.push({
          title: 'Archive Campaign',
          onClick: showArchiveModal,
          iconName: 'trash',
          iconClassname: 'fa-regular',
          disabled: status.isSaving,
          danger: true,
        });
      }
    }

    hoverMenuItems.push({
      title: 'View Engagement Insights',
      href: getArticleUrl(),
      iconName: 'arrow-up-right-from-square',
      // iconClassname: 'fa-regular',
    });

    if (showDeliverySection) {
      hoverMenuItems.push({
        title: 'View Delivery Insights',
        href: getDeliveryDetailsUrl(),
        iconName: 'arrow-up-right-from-square',
        // iconClassname: 'fa-regular',
      });
    }

    const { polls, videos } = post.content.meta;
    polls?.forEach((poll) => {
      hoverMenuItems.push({
        title: `Poll Summary: ${truncate(poll.name, { size: 64 })}`,
        href: getPollUrl(poll.id),
        iconName: 'arrow-up-right-from-square',
        // iconClassname: 'fa-regular',
      });
    });
    videos?.forEach((video, index) => {
      hoverMenuItems.push({
        title: `Video Performance: Video ${index + 1}`,
        href: getVideoUrl(video.id),
        iconName: 'arrow-up-right-from-square',
        // iconClassname: 'fa-regular',
      });
    });

    return (
      <div data-pendo="results-page-actions" className={styles.controlsWrapper}>
        {isLoadingPermissions ? (
          <LoadingSpinner size="small" />
        ) : (
          <Button
            dataPendo="edit-campaign"
            href={`/${programId}/edit/publisher/${post.content.id}`}
            icon={contentCanOnlyPreview ? <View /> : <Edit />}
            label={`${contentCanOnlyPreview ? 'Open' : 'Edit'} Campaign`}
          />
        )}
        <HoverIconMenu menuItems={hoverMenuItems} openDelay="click">
          <button type="button" className={styles.button}>
            <span>•</span>
            <span>•</span>
            <span>•</span>
          </button>
        </HoverIconMenu>
      </div>
    );
  }, [
    contentCanOnlyPreview,
    getArticleUrl,
    showDeliverySection,
    post.content.meta,
    post.content.id,
    isLoadingPermissions,
    programId,
    handleDuplicate,
    isDuplicating,
    canArchive,
    showArchiveModal,
    status.isSaving,
    getDeliveryDetailsUrl,
    getPollUrl,
    getVideoUrl,
  ]);

  const Page = React.useMemo<
    React.FC<{ title: string; description?: string }>
  >(() => {
    const metricsV2Tabs: Array<{
      to: string;
      label: string;
      disablePartial?: boolean;
    }> = [
      {
        to: `${post.content.id}/`,
        label: 'Omni-Channel Performance',
        disablePartial: true,
      },
    ];
    if (showDeliverySection) {
      metricsV2Tabs.push({
        to: `${post.content.id}/email`,
        label: 'Email Performance',
      });
    }

    let updatedAt;
    if (results?.calculated_at) {
      const calculatedTime = DateTime.fromFormat(
        results.calculated_at,
        'yyyy-mm-dd HH:mm:ss'
      );
      if (calculatedTime.isValid) {
        updatedAt = Interval.fromDateTimes(
          DateTime.fromFormat(results.calculated_at, 'yyyy-MM-dd HH:mm:ss', {
            zone: 'utc',
          }),
          DateTime.now().toUTC()
        ).toDuration(['days', 'hours', 'minutes', 'seconds']);
      }
    }

    const displayLargestTimeInterval = (duration: Duration) => {
      if (!duration.isValid) {
        return '';
      }

      if (duration.days !== 0) {
        return `${duration.days} day${duration.days === 1 ? '' : 's'} ago`;
      }
      if (duration.hours !== 0) {
        return `${duration.hours} hour${duration.hours === 1 ? '' : 's'} ago`;
      }
      if (duration.minutes !== 0) {
        return `${duration.minutes} minute${
          duration.minutes === 1 ? '' : 's'
        } ago`;
      }
      return 'now';
    };

    const metricsV2Description = (
      <div>
        {timeLeft && (
          <p className={styles.metricsV2SubtitleBold}>
            {timeLeft.days} Days Left
          </p>
        )}
        <p className={styles.metricsV2Subtitle}>
          {startDate && (
            <>Publish Date: {startDate.toLocaleString(DateTime.DATE_MED)}</>
          )}{' '}
          {endDate && (
            <>
              - Estimated End Date: {endDate.toLocaleString(DateTime.DATE_MED)}
            </>
          )}
        </p>
        {!campaignResultsLoading &&
          updatedAt !== undefined &&
          updatedAt.isValid && (
            <p className={styles.metricsV2Caption}>
              Results Last Updated: {displayLargestTimeInterval(updatedAt)}{' '}
              <Tooltip
                content={
                  "The metrics displayed on this page provide a snapshot of the most recent engagement data available and may not be real-time. Please also note that the comprehensive metrics included in the 'Full Report' may differ slightly from the metrics presented here and are subject to a 2-hour delay."
                }
              >
                <i
                  className="material-symbols-rounded"
                  style={{ cursor: 'help', verticalAlign: 'middle' }}
                >
                  info
                </i>
              </Tooltip>
            </p>
          )}
      </div>
    );

    return ({ children, title, description }) => (
      <ListPage
        noNavigation
        breadcrumbs={[
          {
            label: 'Campaigns',
            to: locationState?.from ?? '../../..',
          },
          { label: title },
        ]}
        title={title}
        tabs={metricsV2Enabled ? metricsV2Tabs : undefined}
        description={metricsV2Enabled ? metricsV2Description : description}
        actionsOverride={action}
      >
        {children}
      </ListPage>
    );
  }, [
    action,
    campaignResultsLoading,
    endDate,
    locationState?.from,
    metricsV2Enabled,
    post.content.id,
    results?.calculated_at,
    showDeliverySection,
    startDate,
    timeLeft,
  ]);

  const title = React.useMemo(() => {
    if (post.callToAction.title) {
      if (post.callToAction.title.length >= 55) {
        return `${post.callToAction.title.substring(0, 55)}...`;
      }
      return post.callToAction.title;
    }
    return 'Untitled';
  }, [post]);

  return (
    <Page title={title}>
      {showingArchiveModal && (
        <ConfirmModal
          headerTitle="Archive Campaign"
          customTitle="Are you sure you want to archive this campaign?"
          hideLabel
          onCancel={closeArchiveModal}
          onConfirm={handleConfirmArchive}
        />
      )}
      <div className={styles.pageWrapper}>
        <MetricsV2Section
          post={post}
          isEmailOnly={isShowingEmail}
          results={results}
          isLoading={campaignResultsLoading}
        />
      </div>
    </Page>
  );
};
