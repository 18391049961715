import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { ClickDropdown, ClickDropdownHandle } from 'shared/ClickDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { useInfiniteApiQuery } from 'hooks/common';
import { fetchAuthorPage } from 'services/api-authors';
import { useProgram } from 'contexts/program';
import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import { Author } from 'models/author';
import styles from '../administration-select.module.css';

export const AuthorFilter: React.FC<{
  value: Array<Author>;
  onChange: (change: Array<Author>) => void;
  isRadio?: boolean;
  useAuthorAliases?: boolean;
  searchPlaceholder?:
    | 'Search authors'
    | 'Search author aliases'
    | 'Search creators';
  triggerName?: string;
  allowForgotten?: boolean;
  align?: 'left' | 'right' | 'center';
}> = ({
  value,
  onChange,
  useAuthorAliases,
  searchPlaceholder,
  triggerName,
  allowForgotten,
  align = 'left',
}) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState<string>();
  const clearSearch = React.useCallback(() => {
    setSearch('');
  }, []);

  const params = {
    programId,
    query: search,
    useAuthorAliases: !!useAuthorAliases,
  };

  const {
    data: authors,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery(
    'authors',
    fetchAuthorPage,
    params.useAuthorAliases ? { ...params, forgotten: allowForgotten } : params
  );

  const authorsByRowId: { [rowId: string]: Author } = useMemo(() => {
    const map: { [key: string]: Author } = {};
    if (useAuthorAliases) {
      authors.forEach((author) => {
        if (author.authorAliasId) {
          map[author.authorAliasId] = author;
        }
      });
      // retain the currently selected authors
      value.forEach((author) => {
        if (author.authorAliasId) {
          if (!map[author.authorAliasId]) map[author.authorAliasId] = author;
        }
      });
    } else {
      authors.forEach((author) => {
        map[author.userId] = author;
      });
      // retain the currently selected authors
      value.forEach((author) => {
        if (!map[author.userId]) map[author.userId] = author;
      });
    }

    return map;
  }, [useAuthorAliases, authors, value]);

  const rowIds = Object.keys(authorsByRowId).sort((a, b) => {
    const nameA =
      authorsByRowId[a].displayName?.toLowerCase() ||
      authorsByRowId[a].defaultDisplayName?.toLowerCase();
    const nameB =
      authorsByRowId[b].displayName?.toLowerCase() ||
      authorsByRowId[b].defaultDisplayName?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const selectedIds = React.useMemo(() => {
    return useAuthorAliases
      ? value.filter((v) => v).map(({ authorAliasId }) => `${authorAliasId}`)
      : value.map(({ userId }) => `${userId}`);
  }, [useAuthorAliases, value]);

  const onSelectedIdsChange = useCallback(
    (ids: Array<string>) => {
      onChange(ids.map((id) => authorsByRowId[id]));
    },
    [onChange, authorsByRowId]
  );

  const renderSelectRow = useCallback(
    (id: string) => {
      const a = authorsByRowId[id];
      if (!a) return null;

      return (
        <div className={styles.dropdownFilterItem}>
          <span>{a.displayName || a.defaultDisplayName}</span>
        </div>
      );
    },
    [authorsByRowId]
  );

  const selectedValues = React.useMemo(() => {
    return selectedIds.map(
      (id) =>
        authorsByRowId[id].displayName || authorsByRowId[id].defaultDisplayName
    );
  }, [authorsByRowId, selectedIds]);

  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const dropdown = React.useMemo(
    () => (
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={renderSelectRow}
        maxHeight={300}
        itemHeight={32}
        searchEnabled
        searchTerm={search}
        searchPlaceholder={
          searchPlaceholder ||
          (useAuthorAliases ? 'Search aliases' : 'Search publishers')
        }
        onSearchTermChange={setSearch}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        itemClassName="filter-item"
        className={cx('filter', styles.authorFilter)}
        hasClearSearchButton
        dismissButton="Done"
        onDismissRef={onDismissRef}
      />
    ),
    [
      rowIds,
      renderSelectRow,
      search,
      searchPlaceholder,
      useAuthorAliases,
      selectedIds,
      onSelectedIdsChange,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
    ]
  );

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  const clickDropdownRef = React.useRef<ClickDropdownHandle>(null);

  // When a new dataset is fetched, the width of the dropdown may change causing it to
  // possibly overflow off of the screen. The use of setImmediate is necessary to wait
  // for the InfiniteSelect component to finish rendering before having the dropdown check
  // if its positioning needs to be corrected.
  React.useEffect(() => {
    setImmediate(() => clickDropdownRef.current?.correctDropdownOverflow());
  }, [authors]);

  return (
    <ClickDropdown
      onClose={clearSearch}
      dropdownRenderProp={dropdownRenderProp}
      dropdownClassName={`dropdown-align-${align}`}
      ref={clickDropdownRef}
    >
      <TriggerButton name={triggerName || 'Author'} values={selectedValues} />
    </ClickDropdown>
  );
};
