import * as React from 'react';
import { throttle } from 'utility/throttle';

type PropsType = {
  children: (height: number) => React.ReactNode;
};

export const AutoHeight: React.FC<PropsType> = (props) => {
  const { children } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const handleResize = throttle(() => {
      if (!ref || !ref.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const marginTop = Math.max(windowHeight - rect.top, 0);

      setHeight(marginTop);
    });

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [ref]);

  return (
    <div ref={ref} style={{ maxHeight: '100%' }}>
      {children(height)}
    </div>
  );
};
