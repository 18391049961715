import * as React from 'react';
import { ImageData } from 'models/image';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { ImagePicker, itemToImage } from './ImagePicker';

// The name is given to the slide-in modal.
// That component uses portals and by providing unique names,
// they will be "stacked" correctly, hopefully without having
// to deal with z-indexes.
export const ImageSlideIn: React.FC<{
  name: string;
  onChange: (images: ImageData[]) => void;
  onClose: () => void;
  maxSelections: number;
  useModal?: boolean;
}> = ({ onChange, onClose, name, maxSelections, useModal = false }) => {
  if (useModal)
    return (
      <ImagePicker
        useModal
        maxSelections={maxSelections}
        onChange={(images) => {
          onChange(images.map(itemToImage));
          onClose();
        }}
        onCancel={onClose}
      />
    );
  // After all programs use the new editors, this branch of the code
  // will be deleted. Maybe even this component entirely.
  return (
    <SlideIn name={name}>
      {(slideOut) => (
        <ImagePicker
          maxSelections={maxSelections}
          onChange={(images) => {
            onChange(images.map(itemToImage));
            slideOut(onClose);
          }}
          onCancel={() => slideOut(onClose)}
        />
      )}
    </SlideIn>
  );
};
