import React from 'react';
import cx from 'classnames';
import { VariableInput } from './VariableInput';
import EditableInput from './EditableInput';
import {
  parseTextWithVariables,
  normalText,
  encodeText,
  decodeText,
} from './lib';
import styles from './vti.module.css';

type Props = {
  channel?: string;
  invalid: boolean;
  moveTo: (to: { focusIndex?: number; caretIndex?: number }) => void;
  setText: (text: string) => void;
  showVariables: (
    matchingText: string,
    onSelectVariable: (key: string) => void
  ) => void;
  text: string;
  variables: { key: string }[];
  placeholder: string;
  autoFillText: string;
  disabled: boolean;
  focusRef?: React.RefObject<HTMLElement>;
  classNames?: string;
  variableTextClassName?: string;
  clearPadding?: boolean;
  onCaretMoved?: (value: number) => void;
};

type State = {
  text: string;
  inputs: {
    type: 'input' | 'variable';
    value: string;
    display_value?: string;
  }[];
  channel?: string;
  invalid: boolean;
  variables: { key: string }[];
  clickedPlaceholder: boolean;
  showPlaceholder: boolean;
};

export class InputContainer extends React.Component<Props, State> {
  static getDerivedStateFromProps(
    props: Props,
    prevState: Partial<State>
  ): State {
    const text = encodeText(props.text);
    const inputs = parseTextWithVariables(text);
    return {
      text,
      inputs,
      channel: props.channel,
      invalid: props.invalid,
      variables: props.variables,
      clickedPlaceholder: !!prevState.clickedPlaceholder,
      showPlaceholder: !prevState.clickedPlaceholder && !text,
    };
  }

  private editables: React.RefObject<HTMLDivElement>;

  private expectNextPropsTextToBe: string;

  private renderedInputsCount = 0;

  constructor(props: Props) {
    super(props);
    this.state = InputContainer.getDerivedStateFromProps(props, {});
    const { text } = this.state;
    this.setText = this.setText.bind(this);
    this.expectNextPropsTextToBe = normalText(text);
    this.editables = React.createRef();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    const { channel, invalid, variables, showPlaceholder } = this.state;
    const { disabled } = this.props;
    const render =
      disabled !== nextProps.disabled ||
      channel !== nextState.channel ||
      invalid !== nextState.invalid ||
      variables.length !== nextState.variables.length ||
      this.renderedInputsCount !== nextState.inputs.length ||
      this.expectNextPropsTextToBe !== normalText(nextState.text) ||
      showPlaceholder !== nextState.showPlaceholder;
    return render;
  }

  componentDidUpdate(): void {
    const { text: stateText } = this.state;
    this.expectNextPropsTextToBe = normalText(stateText);
  }

  clickPlaceholder: () => void = () => {
    this.setState({ clickedPlaceholder: true });
    const { moveTo, setText, autoFillText } = this.props;
    if (autoFillText) {
      setText(autoFillText);
      setTimeout(() => {
        const ref = this.editables;
        if (!ref || !ref.current) return;
        const span = ref.current.querySelector('.-vti-clickable:first-child');
        if (!span) return;
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(span);
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 100);
    }
    moveTo({ focusIndex: 0 });
  };

  setText = (text: string) => {
    this.expectNextPropsTextToBe = normalText(text);
    const { setText } = this.props;
    setText(normalText(text));
  };

  getInputs: () => { type: 'input' | 'variable'; value: string }[] = () => {
    const { inputs } = this.state;
    return inputs;
  };

  /* eslint-disable react/no-array-index-key */
  render(): JSX.Element | null {
    const { inputs, invalid, channel, showPlaceholder } = this.state;
    const {
      moveTo,
      focusRef,
      placeholder,
      disabled,
      showVariables,
      classNames,
      clearPadding,
      variableTextClassName,
      onCaretMoved,
    } = this.props;
    this.renderedInputsCount = inputs.length;
    return (
      <div
        className={cx(styles.input, '-vti-input', 'text-input', classNames, {
          [styles.disabled]: disabled,
          [styles.nochannel]: !channel && !clearPadding,
          [styles.invalid]: invalid,
        })}
        onBlur={() => this.setState({ clickedPlaceholder: false })}
        dir="auto"
      >
        {channel && (
          <i
            role="button"
            aria-label="select text"
            tabIndex={0}
            onKeyPress={() => {}}
            onClick={() => {
              moveTo({ focusIndex: 0, caretIndex: 0 });
            }}
            className={`-vti-channel channel icon-${channel}`}
          />
        )}
        <div ref={this.editables}>
          {!showPlaceholder ? null : (
            <span
              role="textbox"
              className={styles.placeholder}
              onClick={this.clickPlaceholder}
              onFocus={this.clickPlaceholder}
              tabIndex={0}
              onKeyPress={() => {}}
            >
              <span ref={focusRef} contentEditable="true" />
              {placeholder}
            </span>
          )}
          {showPlaceholder
            ? null
            : inputs.map((part, i) =>
                part.type === 'input' ? (
                  <EditableInput
                    key={i}
                    index={i}
                    disabled={disabled}
                    html={part.value}
                    onCaretMoved={(caretPosition) => {
                      let offset = 0;
                      const parts = inputs;
                      for (let index = 0; index < i; index += 1) {
                        offset += decodeText(parts[index].value).length;
                      }
                      offset += caretPosition;
                      if (onCaretMoved) onCaretMoved(offset);
                    }}
                    onChange={({ text, newVariable }) => {
                      const value = this.getInputs()
                        .map((_part, _i) => (i === _i ? text : _part.value))
                        .join('');
                      this.setText(value);
                      if (newVariable) {
                        moveTo({ focusIndex: i + 2, caretIndex: 0 });
                      }
                    }}
                    onSuggest={showVariables}
                    onDeletePreviousVariable={() => {
                      if (i === 0) return;
                      const textWithoutVariable = this.getInputs()
                        .map((input, _i) => (i - 1 === _i ? '' : input.value))
                        .join('');
                      this.setText(textWithoutVariable);
                      moveTo({
                        focusIndex: i - 2,
                        caretIndex: decodeText(this.getInputs()[i - 2].value)
                          .length,
                      });
                    }}
                    onPreviousInput={() => {
                      moveTo({ focusIndex: i - 1 });
                    }}
                    onNextInput={() => {
                      moveTo({ focusIndex: i + 1 });
                    }}
                  />
                ) : (
                  <VariableInput
                    key={i}
                    disabled={disabled}
                    className={variableTextClassName}
                    onDeleteVariable={() => {
                      const textWithoutVariable = this.getInputs()
                        .map((input, _i) => (i === _i ? '' : input.value))
                        .join('');
                      this.setText(textWithoutVariable);
                      moveTo({
                        focusIndex: i - 1,
                        caretIndex: decodeText(this.getInputs()[i - 1].value)
                          .length,
                      });
                    }}
                    onPreviousInput={() => {
                      moveTo({ focusIndex: i - 1, caretIndex: -1 });
                    }}
                    onNextInput={() => {
                      moveTo({ focusIndex: i + 1, caretIndex: 0 });
                    }}
                  >
                    {part.display_value
                      ?.replace(/^cstm_/, '')
                      ?.replace(/_/g, ' ')}
                  </VariableInput>
                )
              )}
        </div>
      </div>
    );
  }
  /* eslint-enable react/no-array-index-key */
}
