import * as React from 'react';

export type DesignPermission = {
  canEdit: boolean;
  isLoading?: boolean;
  errors: string[];
};
export const DesignPermissionContext = React.createContext<DesignPermission>({
  canEdit: false,
  isLoading: true,
  errors: [],
});
