import React from 'react';
import cx from 'classnames';
import { styles as FormStyles, Button } from 'DesignSystem/Form';

export const DownloadDashboardButton: React.FC<{
  title: string;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
}> = ({ title, onClick }) => {
  return (
    <Button
      text
      label={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.59 9L13 12.59V4H11V12.59L7.41 9L6 10.41L11.29 15.71C11.68 16.1 12.32 16.1 12.71 15.7L18 10.41L16.59 9ZM20 17.59V15.59H22V17.59C22 19.25 20.66 20.59 19 20.59H5C3.34 20.59 2 19.25 2 17.59V15.59H4V17.59C4 18.14 4.45 18.59 5 18.59H19C19.55 18.59 20 18.14 20 17.59Z"
            fill="var(--color-gray90)"
          />
        </svg>
      }
      className={cx(FormStyles.Button, FormStyles.ButtonText)}
      onClick={onClick}
      title={title}
    />
  );
};
