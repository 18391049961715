import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useTopicQuery } from 'hooks/topics';
import { Topic } from 'models/topic';
import React from 'react';
import { TopicContext } from './TopicContext';
import { WithPagesControl } from './WithPagesControl';

export const EditTopic: React.FC<RouteComponentProps<{
  id: string | number;
}>> = ({ id, children }) => {
  const [topic, setTopic] = React.useState<Topic>();
  const { id: programId } = useProgram();
  const { data, isLoading, errorMessage } = useTopicQuery(
    programId,
    id as string
  );

  React.useEffect(() => {
    if (data) setTopic(data);
  }, [data]);

  return (
    <TopicContext.Provider value={{ topic, setTopic, isLoading, errorMessage }}>
      <WithPagesControl topicId={topic?.id ?? -1}>{children}</WithPagesControl>
    </TopicContext.Provider>
  );
};
