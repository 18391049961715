import * as React from 'react';
import { Button } from 'DesignSystem/Form';
import { Box, Popover } from 'DesignSystem/Components';
import { ClickDropdown } from 'shared/ClickDropdown';
import { DashboardCustomize, Upload, LinkIcon as LinkSvg } from 'shared/icons';
import styles from './source_menu.module.css';
import { UPLOAD, EXTERNAL, LIBRARY } from './SourceMenuConst';

export const SourceMenu: React.FC<{
  menu: Menu;
  top?: number;
  left?: number;
}> = ({ menu, children, top, left }) => {
  const renderTrigger = React.useCallback(
    () => (
      <React.Fragment key="source-menu-trigger">
        {React.Children.count(children) > 0 ? (
          children
        ) : (
          <Button
            key="button"
            dataTest="change-source-button"
            onClick={() => {}}
            label="Change"
          />
        )}
      </React.Fragment>
    ),
    [children]
  );
  const renderMenu = React.useCallback(
    (dismiss: () => void) => (
      <Box
        relative
        top={top ? `${top}px` : '-105px'}
        left={left ? `${left}px` : '32px'}
      >
        <Popover padding={[8, 16]} className={styles.sourceMenuPopover}>
          {menu.map((item) => (
            <Button
              className={styles.sourceMenuButton}
              icon={item.icon}
              key={item.name}
              block
              text
              data-test={`add-${item.source}`}
              onClick={() => {
                item.onClick();
                dismiss();
              }}
              label={item.component || <span>{item.name}</span>}
            />
          ))}
        </Popover>
      </Box>
    ),
    [menu, left, top]
  );
  return (
    <ClickDropdown dropdownRenderProp={renderMenu}>
      {renderTrigger()}
    </ClickDropdown>
  );
};

export const OPENED = true as const;
export const CLOSED = false as const;

export type Visibility = typeof OPENED | typeof CLOSED;
export type State<T> = { source: T; visibility: Visibility };
export type Config<T> = Array<
  Pick<Item<T>, 'name'> & {
    source: T;
    icon?: string | React.ReactNode;
    onClick?: () => void;
  }
>;
export type Item<T> = {
  name: string;
  onClick: () => void;
  source: T;
  icon?: string | React.ReactNode;
  component?: React.ReactNode;
};
export type Menu<T = string> = Item<T>[];
export type MenuIface<T> = {
  menu: Menu<T>;
  source: T;
  visibility: Visibility;
  open: (source?: T) => void;
  close: () => void;
};

export function useMenu<T>(
  items: Config<T>,
  fn: (source?: T) => void
): Menu<T> {
  return React.useMemo(
    () =>
      items.map(({ name, source, icon, onClick }) => ({
        name,
        source,
        icon,
        onClick: onClick || (() => fn(source)),
      })),
    [items, fn]
  );
}

export function useSourceMenu<T>(
  items: Config<T>,
  initial: State<T>
): MenuIface<T> {
  const [{ source, visibility }, setState] = React.useState(initial);
  const open = React.useCallback(
    (newValue?: T) =>
      setState({ source: newValue || source, visibility: OPENED }),
    [source]
  );
  const close = React.useCallback(() => {
    setState((state) => ({ ...state, visibility: CLOSED }));
  }, []);
  const menu = useMenu(items, open);
  return { source, visibility, open, close, menu };
}

export const UploadIcon: React.FC = () => (
  <span className={styles.uploadIcon}>
    <Upload />
  </span>
);

export const LibraryIcon: React.FC = () => (
  <span className={styles.libraryIcon}>
    <DashboardCustomize />
  </span>
);

export const LinkIcon: React.FC = () => (
  <span className={styles.linkIcon}>
    <LinkSvg />
  </span>
);

export type Source = typeof UPLOAD | typeof EXTERNAL | typeof LIBRARY;

export const DEFAULT_MENU_OPTIONS = [
  {
    name: 'Upload File',
    source: UPLOAD,
    icon: <UploadIcon />,
  },
  {
    name: 'Select from Library',
    source: LIBRARY,
    icon: <LibraryIcon />,
  },
  {
    name: 'Add from Link',
    source: EXTERNAL,
    icon: <LinkIcon />,
  },
];
