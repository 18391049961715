import React, { useMemo, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { useOneOf } from 'hooks/useOneOf';
import { DateTime } from 'luxon';
import styles from './component-layout.module.css';

type PropsType = {
  onTimeChange: (time: DateTime) => void;
  value: DateTime;
  lowerLimit?: DateTime;
};

const getTimeOptions = (
  currSelection: DateTime,
  lowerLimit?: DateTime,
  isAm?: boolean
) =>
  ([] as {
    hh: string;
    mm: string;
    formatted: string;
    disabled: boolean;
  }[]).concat(
    ...[
      '12',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
    ].map((hh) =>
      ['00', '15', '30', '45'].map((mm) => {
        let disabled = false;
        if (lowerLimit && currSelection.ordinal === lowerLimit.ordinal) {
          const floorHour = (lowerLimit && lowerLimit.get('hour')) || 0;
          const floorMin = (lowerLimit && lowerLimit.get('minute')) || 0;

          const h = parseInt(hh, 10);

          // Convert hour to military time
          const optHour = h + (isAm || (!isAm && h === 12) ? 0 : 12);
          const optMin = parseInt(mm, 10);

          disabled =
            floorHour > optHour || (floorHour === optHour && floorMin > optMin);
        }

        return {
          hh,
          mm,
          formatted: `${hh}:${mm}`,
          disabled,
        };
      })
    )
  );

/* Same as for month calendar - ignoring some accessibility concerns here in order to make a better experience.
   Making every time option a button would make tab navigation a nightmare. */
/* eslint-disable  jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-static-element-interactions */
export const TimeInput: React.FC<PropsType> = ({
  onTimeChange,
  value,
  lowerLimit,
}) => {
  const aligned: DateTime = useMemo(() => {
    const miss = value.minute % 15;
    if (miss <= 8) return value.minus({ minutes: miss });
    return value.plus({ minutes: 15 - miss });
  }, [value]);
  const selectedTime = aligned.toFormat('hh:mm');

  const { am, pm } = useOneOf({
    // (0-23) https://moment.github.io/luxon/api-docs/index.html#datetimehour
    names: ['am', 'pm'],
    initial: value.hour < 12 ? 'am' : 'pm',
  });

  const timeOptions = useMemo(
    () => getTimeOptions(value, lowerLimit, am.isActive),
    [lowerLimit, am, value]
  );

  const activateAM = useCallback(() => {
    const newDateTime = value.minus({ hours: 12 });

    if ((!lowerLimit || newDateTime >= lowerLimit) && !am.isActive) {
      am.activate();
      onTimeChange(newDateTime);
    }
  }, [am, onTimeChange, lowerLimit, value]);
  const activatePM = useCallback(() => {
    if (!pm.isActive) {
      pm.activate();
      onTimeChange(value.plus({ hours: 12 }));
    }
  }, [pm, onTimeChange, value]);

  const changeTime = useCallback(
    ({ hh, mm }: { hh: string; mm: string }) => {
      onTimeChange(
        value.set({
          hour: (parseInt(hh, 10) % 12) + (pm.isActive ? 12 : 0),
          minute: parseInt(mm, 10),
        })
      );
    },
    [pm.isActive, onTimeChange, value]
  );

  useEffect(() => {
    const selector = `.${styles.timeOption}[data-clock-time="${selectedTime}"]`;
    document.querySelector(selector)?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.timeWrapper}>
      <div className={styles.amPmSwitchWrapper}>
        <button
          type="button"
          onClick={activateAM}
          className={cx({ [styles.active]: am.isActive })}
        >
          AM
        </button>
        <button
          type="button"
          onClick={activatePM}
          className={cx({ [styles.active]: pm.isActive })}
        >
          PM
        </button>
      </div>
      <div className={styles.optionsWrapper}>
        {timeOptions.map(({ hh, mm, formatted, disabled }) => (
          <div
            key={formatted}
            onClick={() => (disabled ? null : changeTime({ hh, mm }))}
            data-clock-time={formatted}
            className={cx(styles.timeOption, {
              [styles.selected]: formatted === selectedTime,
              [styles.disabled]: disabled,
            })}
          >
            {formatted}
          </div>
        ))}
      </div>
    </div>
  );
};
