import React from 'react';
import { Filter } from 'models/insight/Filter';
import { DataSource, WidgetType } from 'models/insight/json/filterJson';
import { usePrevious } from 'hooks/usePrevious';
import { ReportContext } from 'App/Program/Main/Insight/contexts/ReportContext';
import { FilterState } from 'App/Program/Main/Insight/contexts/filtersStateReducer';
import { titleCase } from 'utility/text';
import { useDefaultValuesOrDeepLinkedParams } from 'App/Program/Main/Insight/contexts/useDefaultValuesOrDeepLinkedParams';
import { useDebounce } from 'hooks/useDebounce';

const DEFAULT_PILL_BUTTON_DESCRIPTION = 'Any';

/**
 * This context holds data relevant to each individual filter dropdown,
 * including the filter state, any default values, and the visibility of the dropdown
 * */
export type FilterDropdownContextType = {
  filter: Filter;
  filterState: FilterState;
  defaultValue?: string; // a filter will either have the single or multiple default values
  defaultValues?: Set<string>;
  isVisible: boolean;
  didOpen: boolean;
  didClose: boolean;
  setVisible: (value: boolean) => void;
  toggleVisible: () => void;
  pillButtonDescription: string | undefined | null;
  setPillButtonDescriptionWithDefault: (
    text: string | undefined | null
  ) => void;
  searchString: string; // search string used to filter list dropdowns
  debouncedSearchString: string;
  setSearchString: (text: string) => void;
  customHeaderComponent?: React.ReactNode;
  setCustomHeaderComponent: (component: React.ReactNode) => void;
};

const defaultFilter = {
  dataType: DataSource.Analyze,
  label: '',
  slug: '',
  widgetType: WidgetType.Text,
  internalId: '',
};
export const FilterDropdownContext = React.createContext<
  FilterDropdownContextType
>({
  filter: defaultFilter,
  filterState: { filter: defaultFilter, isLoading: false },
  isVisible: false,
  didOpen: false,
  didClose: false,
  setVisible: () => {},
  toggleVisible: () => {},
  pillButtonDescription: DEFAULT_PILL_BUTTON_DESCRIPTION,
  setPillButtonDescriptionWithDefault: () => {},
  searchString: '',
  debouncedSearchString: '',
  setSearchString: () => {},
  setCustomHeaderComponent: () => {},
});

export type FilterDropdownProviderPropsType = {
  filter: Filter;
  filterState: FilterState;
};
export const FilterDropdownProvider: React.FC<FilterDropdownProviderPropsType> = ({
  filter,
  filterState,
  children,
}) => {
  const { deepLinkedParams } = React.useContext(ReportContext);
  const [isVisible, setVisible] = React.useState(false);
  const [didOpen, setDidOpen] = React.useState(false);
  const [didClose, setDidClose] = React.useState(false);
  const [searchString, setSearchString] = React.useState('');
  const [customHeaderComponent, setCustomHeaderComponent] = React.useState<
    React.ReactNode
  >();
  const debouncedSearchString = useDebounce(searchString, 500);
  const prevIsVisible = usePrevious(isVisible);

  // The pill button will display the text for the selected items from a dropdown
  // eg 1: "Content is _____" or "Attribute State is _____"
  // all dropdowns need to set the text in the underline
  // if a falsy value is set then it will default to `Any` as the description
  const [pillButtonDescription, setPillButtonDescription] = React.useState<
    string | undefined | null
  >();

  const toggleVisible = () => setVisible(!isVisible);

  React.useEffect(() => {
    setDidOpen(isVisible && !prevIsVisible);
    setDidClose(!isVisible && prevIsVisible);
  }, [isVisible, prevIsVisible, setDidClose, setDidOpen]);

  // if the description is ever set to empty string or something falsy,
  // we want to automatically display the default description string
  const setPillButtonDescriptionWithDefault = React.useCallback(
    (text: string | { label: string } | undefined | null) => {
      const safeText: string | undefined | null =
        typeof text === 'object' ? text?.label : text;
      const sanitizedText = safeText === 'NaN' ? '...' : safeText;
      setPillButtonDescription(
        sanitizedText
          ? titleCase(sanitizedText)
          : DEFAULT_PILL_BUTTON_DESCRIPTION
      );
    },
    [setPillButtonDescription]
  );

  // default values are returned as both sets and as a single value
  // the consuming component for a filter should know which value to use
  const { defaultValues, defaultValue } = useDefaultValuesOrDeepLinkedParams(
    deepLinkedParams,
    filter
  );

  return (
    <FilterDropdownContext.Provider
      value={{
        filter,
        filterState,
        defaultValue,
        defaultValues,
        isVisible,
        setVisible,
        didOpen,
        didClose,
        toggleVisible,
        pillButtonDescription,
        setPillButtonDescriptionWithDefault,
        searchString,
        debouncedSearchString,
        setSearchString,
        customHeaderComponent,
        setCustomHeaderComponent,
      }}
    >
      {children}
    </FilterDropdownContext.Provider>
  );
};
