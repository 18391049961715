import { Topic } from 'models/topic';
import { Settings } from 'models/publisher/settings';
import { DateTime } from 'luxon';
import { Criterion } from 'models/publisher/criterion';
import { Channel, WORKFLOW_CHANNELS } from 'models/workflows';
import { PriorityName } from 'models/priority';
import { addDuration, DURATION_DAYS_BY_VALUE } from 'models/duration';

export type GaugeData = {
  value?: number;
  label: string;
  signal: 'success' | 'error' | 'neutral';
};

export type Performance = {
  gauges: GaugeData[];
  suggestion: string;
};

export type ScheduleInsightItem = {
  channelDescriptor: string;
  percentage: number;
};
export type ScheduleInsightByChannel = {
  label: string;
  items: Array<ScheduleInsightItem>;
};

export type CompetitionInsight = {
  name: PriorityName;
  count: number;
};

export type BaseChannelInsight = {
  name: string;
  value: number;
};

export type ChannelInsight = BaseChannelInsight & {
  count: number;
};

export type MaxRetries = number;

export type ScheduleInsight = {
  day: number;
  value: number;
  count: number;
};

export type HigherPriority = { percent: number; count: number };

export type Insights = {
  performance: Performance;
  insights: ScheduleInsightByChannel[];
  competitions: Array<CompetitionInsight>;
  higherPriority: HigherPriority;
  channelsInsights: Array<ChannelInsight>;
  scheduleInsights: Array<ScheduleInsight>;
  maxRetries: MaxRetries;
  isLoading?: boolean | undefined;
};

export function performanceSuggestion(
  resonance: number,
  noise: number
): string {
  if (noise <= 50 && resonance <= 50)
    return 'Consider extending the duration, if appropriate for this post, to achieve a potentially higher Performance Score.';
  if (noise <= 50 && resonance > 50)
    return 'Your Fatigue and Performance scores seem to be in balance at this time. No action is recommended.';
  if (resonance <= 50)
    return 'Consider lowering the priority, if appropriate, in order to reduce your Fatigue Score. Consider extending duration, if possible, in order to achieve a potentially higher Performance Score.';
  return 'Consider lowering the priority, if appropriate, or extending the duration, if possible, in order to reduce your Fatigue Score.';
}

export const DEFAULT_INSIGHTS: Insights = {
  performance: {
    gauges: [
      { label: 'Performance', signal: 'neutral' },
      { label: 'Fatigue', signal: 'neutral' },
    ],
    suggestion: 'Set a priority to see insights',
  },
  insights: [],
  competitions: [],
  higherPriority: { percent: 0, count: 0 },
  channelsInsights: [],
  scheduleInsights: [],
  maxRetries: 0,
};

export const INSIGHTS_CHANNELS = WORKFLOW_CHANNELS;

export type { Channel };

export type InsightsParams = {
  programId: number;
  contentId?: number;
  criterion: unknown;
  retries: number;
  epoch_start: number;
  epoch_end: number;
  topic_ids: Topic['id'][];
  channels: Channel[];
  priority: string;
  duration: number;
  deliveryType?: string;
  optimizedDeliveryEnabled?: boolean;
};

// Move to models/channel
function extractChannels(settings: Settings): InsightsParams['channels'] {
  return INSIGHTS_CHANNELS.filter(
    (channel) => settings.deliveryChannels[channel]
  );
}

export function buildParams(
  programId: number,
  settings: Settings,
  startDate: DateTime,
  criterion: Criterion,
  contentId?: number,
  newDeliveryPageEnabled?: boolean
): InsightsParams | undefined {
  if (!settings.priority) return undefined;

  // TODO: Need to fix this. Only doing this to satisfy unnecessary requirement
  //  from Hermes for a date range. Override shouldn't have a range. It's one and done.
  const duration = settings.duration
    ? settings.duration
    : DURATION_DAYS_BY_VALUE[1];

  const endDate = startDate ? addDuration(startDate, duration) : startDate;
  const deliveryParams = newDeliveryPageEnabled
    ? { optimizedDeliveryEnabled: settings.optimizedDeliveryEnabled }
    : { deliveryType: settings.deliveryType };

  return {
    programId,
    contentId,
    criterion,
    retries: settings.retries,
    epoch_start: Math.ceil(startDate.toSeconds()),
    epoch_end: Math.ceil(endDate.toSeconds()),
    topic_ids: settings.contentTopics.map((topic) => topic.id),
    channels: extractChannels(settings),
    priority: settings.priority?.value,
    duration: duration.value,
    ...deliveryParams,
  };
}
