import { navigate } from '@reach/router';
import { useCallback } from 'react';
import { LinkNavigation } from '@socialchorus/shared-ui-components/dist/components/Link';

/**
 * Returns a function that can be used to create a `LinkNavigation` object used by the shared UI components.
 */
export const useLinkNavigation = (): ((pathname: string) => LinkNavigation) => {
  return useCallback(
    (pathname: string) => ({
      baseUrl: new URL(window.location.origin).href,
      pathname,
      navigate,
    }),
    []
  );
};
