import React from 'react';
import {
  linksToFields,
  fieldsToLinks,
  LinkFieldData,
  fieldToLink,
  LINK,
  toLinkFieldData,
  LinkData,
} from 'models/publisher/block';
import { useLinksSourceMenu } from './useSourceMenu';
import { FieldFormProps } from '../../../useFieldForm';

export const useLinks: (
  props: FieldFormProps<LinkFieldData[]>,
  source: ReturnType<typeof useLinksSourceMenu>
) => {
  current?: LinkData;
  applyEdits: (data: LinkData | LinkData[]) => void;
  closeEditor: () => void;
  links: LinkData[];
  openEditorByIndex: (index: number) => void;
  listUpdate: (data: LinkData[]) => void;
  clearList: () => void;
} = ({ data, default_data, onChange }, source) => {
  const [links, setLinks] = React.useState(
    fieldsToLinks(data || default_data) as LinkData[]
  );

  const listUpdate = React.useCallback(
    (updated: Array<LinkData>) => {
      setLinks(updated);
      onChange(linksToFields(updated) as LinkFieldData[]);
    },
    [onChange]
  );

  const [editingIndex, setEditingIndex] = React.useState<number | undefined>();
  const applyEdits = React.useCallback(
    (updated: LinkData | LinkData[]) => {
      if (editingIndex === undefined)
        throw new Error(`failed to edit link[undefined]`);
      const collection = Array.isArray(updated) ? updated : [updated];
      const prior = links.slice(0, editingIndex);
      const after = links.slice(editingIndex + 1);
      const newLinks = [...prior, ...collection, ...after];
      listUpdate(newLinks);
      source.close();
      setEditingIndex(undefined);
    },
    [links, listUpdate, editingIndex, source]
  );

  const clearList = React.useCallback(() => listUpdate([]), [listUpdate]);

  const openEditorByIndex = React.useCallback(
    (index: number) => {
      source.close();
      setEditingIndex(index);
    },
    [source]
  );

  const closeEditor = React.useCallback(() => {
    source.close();
    setEditingIndex(undefined);
  }, [source]);

  const current = React.useMemo(() => {
    if (editingIndex === undefined) return undefined;
    if (links[editingIndex]) return links[editingIndex];
    const newLink = { ...LINK };
    newLink.callToAction.value = 'Read More';
    return fieldToLink(toLinkFieldData(newLink));
  }, [editingIndex, links]);

  return {
    current,
    applyEdits,
    closeEditor,
    links,
    openEditorByIndex,
    listUpdate,
    clearList,
  };
};
