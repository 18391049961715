import React from 'react';
import { Editor } from '@tiptap/react';
import { Button } from 'DesignSystem/Form';
import { MAIcon } from 'shared/MAIcon';
import { Picker } from 'shared/ColorPreview';
import { useToggle } from 'hooks/useToggle';

export const TipTapMenuBar: React.FC<{ editor: Editor | null }> = ({
  editor,
}) => {
  const { toggle: toggleColorPicker, value: colorPickerEnabled } = useToggle();

  if (!editor) return null;

  return (
    <>
      <div>
        <Button
          compact
          layoutOnly
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
          icon={<MAIcon name="format_bold" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
          icon={<MAIcon name="format_italic" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          icon={<MAIcon name="format_clear" />}
        />
        <Button
          compact
          layoutOnly
          icon={<MAIcon name="format_color_text" />}
          onClick={toggleColorPicker}
        />
        {colorPickerEnabled && (
          <Picker
            value={editor.getAttributes('textStyle').color}
            onChange={(value) => {
              editor.chain().focus().setColor(value).run();
              toggleColorPicker();
            }}
          />
        )}
      </div>
      <div>
        <Button compact layoutOnly icon={<MAIcon name="format_size" />} />
        <Button
          compact
          layoutOnly
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
          icon={<MAIcon name="format_paragraph" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
          }
          icon={<MAIcon name="format_h1" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
          }
          icon={<MAIcon name="format_h2" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
          }
          icon={<MAIcon name="format_h3" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive('heading', { level: 4 }) ? 'is-active' : ''
          }
          icon={<MAIcon name="format_h4" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={
            editor.isActive('heading', { level: 5 }) ? 'is-active' : ''
          }
          icon={<MAIcon name="format_h5" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={
            editor.isActive('heading', { level: 6 }) ? 'is-active' : ''
          }
          icon={<MAIcon name="format_h6" />}
        />
      </div>
      <div>
        <Button
          compact
          layoutOnly
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
          icon={<MAIcon name="format_list_bulleted" />}
        />
        <Button
          compact
          layoutOnly
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
          icon={<MAIcon name="format_list_numbered" />}
        />
      </div>
      <Button
        compact
        layoutOnly
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
        icon={<MAIcon name="format_quote" />}
      />
      <Button
        compact
        layoutOnly
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        icon={<MAIcon name="horizontal_rule" />}
      />
      <Button
        compact
        layoutOnly
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        icon={<MAIcon name="undo" />}
      />
      <Button
        compact
        layoutOnly
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        icon={<MAIcon name="redo" />}
      />
    </>
  );
};
