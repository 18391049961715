import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { FieldInput, Radio } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import { FieldLegend } from './FieldLegend';

export type EditorModeSelectOption = {
  label: string;
  value: string;
};

export type EditorModeSelectProps = {
  prompt: string;
  onChange: (value: string) => void;
  options: EditorModeSelectOption[];
  value: string;
};

export const EditorModeSelect: React.FC<EditorModeSelectProps> = (props) => {
  const { prompt, onChange, options } = props;
  return (
    <FieldInput
      legend={<FieldLegend>{prompt}</FieldLegend>}
      htmlFor="mode-select-container"
    >
      <div id="mode-select-container">
        {options.map((option) => (
          <Flex start style={{ margin: '8px' }} key={option.value}>
            <Radio
              type="radio"
              name="mode"
              style={{ marginRight: '5px' }}
              checked={option.value === props.value}
              onChange={() => onChange(option.value)}
            />
            <Text.Body>{option.label}</Text.Body>
          </Flex>
        ))}
      </div>
    </FieldInput>
  );
};
