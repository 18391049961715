import { RefObject, useCallback, useEffect, useState } from 'react';
import { throttle } from '../utility/throttle';

/**
 * Hook to detect if the user has scrolled to the bottom of the element.
 * @param ref - React ref to element to watch for scroll events.
 * @param threshold - Number of pixels from the bottom of the element to change the state.
 */
function useScrollEnd(ref: RefObject<HTMLElement>, threshold = 750): boolean {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = ref.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < threshold;
    setIsBottom(isAtBottom);
  }, [ref, threshold]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const element = ref.current;
    const handleScrollThrottled = throttle(handleScroll);

    element.addEventListener('scroll', handleScrollThrottled);
    window.addEventListener('resize', handleScrollThrottled);
    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener('scroll', handleScrollThrottled);
      window.removeEventListener('resize', handleScrollThrottled);
    };
  }, [ref, handleScroll]);

  return isBottom;
}

export default useScrollEnd;
