import React from 'react';
import { Topic, TopicErrors } from 'models/topic';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { useProgram } from 'contexts/program';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { useAudiencesQuery } from 'hooks/audience';
import { Audience } from 'models/audience';
import { AudienceSelect } from 'components/audience/AudienceSelect';
import { FlipSwitch } from 'shared/FlipSwitch';
import { Checkbox } from 'shared/Checkbox';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './form.module.css';

type PropsType = {
  topic: Partial<Topic>;
  onChange: (data: Partial<Topic>) => void;
  errors?: TopicErrors;
};

export const TargetSection: React.FC<PropsType> = ({
  topic,
  onChange,
  errors,
}) => {
  const { id: programId } = useProgram();

  const { data: groupsData, isLoading: groupsIsLoading } = useAudiencesQuery({
    name: (topic.groupIds?.length ?? 0) > 0 ? topic.groupIds : [''],
    pageSize: topic.groupIds?.length,
    programId,
  });

  const {
    data: autoFollowGroupsData,
    isLoading: autoFollowGroupsIsLoading,
  } = useAudiencesQuery({
    name:
      (topic.autoFollowGroupIds?.length ?? 0) > 0
        ? topic.autoFollowGroupIds
        : [''],
    programId,
  });

  const [groups, setGroups] = React.useState<Audience[]>([]);
  const [autoFollowGroups, setAutoFollowGroups] = React.useState<Audience[]>(
    []
  );

  React.useEffect(() => {
    if ((topic.groupIds?.length ?? 0) > 0 && groupsData) setGroups(groupsData);
    // using groupsData as deps leads to infinite loop
    // eslint-disable-next-line
  }, [groupsIsLoading]);

  React.useEffect(() => {
    if ((topic.autoFollowGroupIds?.length ?? 0) > 0 && autoFollowGroupsData)
      setAutoFollowGroups(autoFollowGroupsData);
    // using autoFollowGroupsData as deps leads to infinite loop
    // eslint-disable-next-line
  }, [autoFollowGroupsIsLoading]);

  function updateGroups(values: Audience[]) {
    setGroups(values);
    onChange({ groupIds: values.map((audience) => audience.name) });
  }

  function updateAutoFollowGroups(values: Audience[]) {
    setAutoFollowGroups(values);
    onChange({ autoFollowGroupIds: values.map((audience) => audience.name) });
  }

  return (
    <Section title="Target">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Audience">
            {groupsIsLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}
            {!groupsIsLoading && (
              <AudienceSelect
                selectedAudiences={groups}
                onSelectedAudiencesChange={updateGroups}
                disabled={topic?.default}
              />
            )}
          </Field>
        </div>
      </Fieldset>

      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Auto-follow" error={errors?.autoFollowGroupIds}>
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <FlipSwitch
                  size="xl"
                  shape="rounded"
                  on={topic.autoFollow === true}
                  onChange={(value) =>
                    value
                      ? onChange({ autoFollow: true })
                      : onChange({ autoFollow: false })
                  }
                  disabled={topic?.default}
                />
              </div>
              Auto-follow the audience to this topic
            </div>
            {topic.autoFollow && (
              <div className={styles.autoFollowSelector}>
                {autoFollowGroupsIsLoading && (
                  <div className={styles.loading}>
                    <LoadingSpinner />
                  </div>
                )}
                {!autoFollowGroupsIsLoading && (
                  <AudienceSelect
                    selectedAudiences={autoFollowGroups}
                    onSelectedAudiencesChange={updateAutoFollowGroups}
                    name={groups.map((g) => g.name)}
                    disabled={topic?.default}
                  />
                )}
              </div>
            )}
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Recommend follow">
            <div className="kai-flex-row">
              <div className={styles.marginRight}>
                <Checkbox
                  checked={Boolean(topic.isPromoted)}
                  onChange={(value) => onChange({ isPromoted: value })}
                  disabled={topic?.default}
                />
              </div>
              Suggest this topic as a Recommended Topic
            </div>
          </Field>
        </div>
      </Fieldset>
    </Section>
  );
};
