import * as React from 'react';
import cx from 'classnames';
import { supportsLinkStyling } from 'models/publisher/block';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  canEditImageCroppingFromOptionsList,
  ImageCropping,
} from 'components/publisher/pages/Design/ImageCropping/index';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { ColorPicker, SizeSlider } from '../Pickers';
import { StyleSwitch } from './StyleSwitch';
import { Arrangement, canEditArrangement } from '../Arrangement';
import { canEditTextAlignment, TextAlignment } from '../TextAlignment';
import { canEditHiddenSections, HiddenSections } from '../FormattingFlags';
import { FontSection } from './FontSection';
import { useStyleEditor } from './useStyleEditor';
import { canEditLinkType, LinkType } from '../LinkType';
import styles from '../styles.module.css';
import { AutoCropping } from '../AutoCropping';
import {
  canEditVerticalAlignment,
  VerticalAlignment,
} from '../VerticalAlignment';
import { RevertToGlobalStyle } from './RevertToGlobalStyle';

export const SelectedTab: React.FC<Parameters<typeof useStyleEditor>[0]> = ({
  id,
  block,
}) => {
  const { canEdit } = React.useContext(DesignPermissionContext);
  const customStyle = useStyleEditor({ id, block });
  const supportsLinkStyles = supportsLinkStyling(block);
  const newEditor = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const fieldTypes = block.fields.map((f) => f.type);
  const hasImages =
    fieldTypes.includes('image') || fieldTypes.includes('image[]');

  const isSocialBlock = fieldTypes.includes('social[]');
  const minImageWidth = isSocialBlock ? 15 : 1;
  const maxImageWidth = isSocialBlock ? 250 : 100;
  const hasImageCropOptions = canEditImageCroppingFromOptionsList(block);
  const isGlobalStyled = React.useMemo(() => {
    const data = customStyle.editor.blockStyle;

    // imageWidth and top/bottom spacing can only be changed on block level
    if (Object.keys(data).length === 0) {
      return true;
    }

    if (
      Object.keys(data.colors || {}).length +
        Object.keys(data.fonts || {}).length ===
      0
    ) {
      const sizes = Object.keys(data.sizes || {}).filter(
        (f) => !['imageWidth'].includes(f)
      );
      if (sizes.length === 0) {
        return true;
      }
    }

    return false;
  }, [customStyle.editor.blockStyle]);
  return (
    <div className={cx(styles.styles, { [styles.newEditor]: newEditor })}>
      {customStyle.hasFormattingOptions && (
        <div className={styles.sidebarPadding}>
          <fieldset
            className={newEditor ? styles.smallFieldsetPadding : undefined}
          >
            {canEdit && (
              <legend className={styles.fieldsetLegend}>Format</legend>
            )}
            {canEditArrangement(block) && canEdit && (
              <Arrangement
                onChange={customStyle.updateFormatData}
                block={block}
                id={id}
              />
            )}
            {hasImages && !hasImageCropOptions && (
              <AutoCropping
                enableAutoCropping={customStyle.enableAutoCropping}
                disableAutoCropping={customStyle.disableAutoCropping}
                autoCropping={customStyle.autoCropping}
                canEdit={canEdit}
              />
            )}
            {canEditTextAlignment(block) && canEdit && (
              <TextAlignment
                onChange={customStyle.updateFormatData}
                block={block}
                id={id}
              />
            )}
            {canEditVerticalAlignment(block) && canEdit && (
              <VerticalAlignment
                onChange={customStyle.updateFormatData}
                block={block}
                id={id}
              />
            )}
            <div style={{ marginBottom: '6px' }} />
            {hasImageCropOptions && (
              <ImageCropping
                block={block}
                onChange={customStyle.updateFormatData}
                id={id}
              />
            )}
            {canEditHiddenSections(block) && canEdit && (
              <HiddenSections
                onChange={customStyle.updateFormatData}
                block={block}
                id={id}
              />
            )}
            {canEditLinkType(block) && canEdit && (
              <LinkType
                onChange={customStyle.updateFormatData}
                block={block}
                id={id}
              />
            )}
          </fieldset>
        </div>
      )}

      <div className={styles.divider} />

      {!newEditor && (
        <div className={styles.sidebarPadding}>
          <StyleSwitch
            enable={customStyle.enableCustomStyle}
            disable={customStyle.disableCustomStyle}
            isCustom={customStyle.isCustomStyled}
            disabled={!canEdit}
          />
        </div>
      )}

      {customStyle.availableSections.block && (
        <div className={styles.sidebarPadding}>
          <fieldset
            className={newEditor ? styles.smallFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Block</legend>
            {customStyle.canEditColor('page') && (
              <ColorPicker
                field="page"
                name="Background"
                editor={customStyle.editor}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditColor('divider') && (
              <ColorPicker
                field="divider"
                name="Divider"
                editor={customStyle.editor}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditSize('margins') && (
              <SizeSlider
                field="margins"
                name="Background"
                editor={customStyle.editor}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditSize('topSpacing') && (
              <SizeSlider
                field="topSpacing"
                name="Top spacing"
                editor={customStyle.editor}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditSize('bottomSpacing') && (
              <SizeSlider
                field="bottomSpacing"
                name="Bottom spacing"
                editor={customStyle.editor}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditSize('dividerVerticalSpacing') && (
              <SizeSlider
                field="dividerVerticalSpacing"
                name="Vertical spacing"
                editor={customStyle.editor}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditSize('height') && (
              <SizeSlider
                field="height"
                name="Height"
                editor={customStyle.editor}
                max={200}
                min={10}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditSize('dividerHeight') && (
              <SizeSlider
                field="dividerHeight"
                name="Divider height"
                editor={customStyle.editor}
                max={200}
                min={1}
                disabled={!canEdit}
              />
            )}
          </fieldset>
        </div>
      )}

      {customStyle.availableSections.buttons && (
        <div className={styles.sidebarPadding}>
          <fieldset
            className={newEditor ? styles.smallFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Buttons</legend>
            <SizeSlider
              field="buttonRadiuses"
              name="Button rounding"
              editor={customStyle.editor}
              max={100}
              min={0}
              disabled={!canEdit}
            />
            <ColorPicker
              editor={customStyle.editor}
              availableStyles={block.style_options}
              field="buttonBg"
              name="Button background"
              disabled={!canEdit}
            />
            <ColorPicker
              editor={customStyle.editor}
              availableStyles={block.style_options}
              field="buttonFg"
              name="Button text"
              disabled={!canEdit}
            />
          </fieldset>
        </div>
      )}

      {customStyle.availableSections.image && (
        <div className={styles.sidebarPadding}>
          <fieldset
            className={newEditor ? styles.smallFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Image</legend>
            {customStyle.canEditSize('radiuses') && (
              <SizeSlider
                field="radiuses"
                name="Round image corners"
                editor={customStyle.editor}
                max={300}
                min={0}
                disabled={!canEdit}
              />
            )}
            {customStyle.canEditImageWidth() && (
              <SizeSlider
                field="imageWidth"
                name={isSocialBlock ? 'Max image width' : 'Image width'}
                editor={customStyle.editor}
                max={maxImageWidth}
                min={minImageWidth}
                disabled={!canEdit}
                helperInfo={
                  isSocialBlock
                    ? {
                        description: (
                          <p>
                            This value is the <i>maximum</i> width (in pixels)
                            for each image in the block. Images will be
                            automatically scaled down to a smaller width if they
                            cannot fit on a single row at the specified width.
                          </p>
                        ),
                      }
                    : undefined
                }
              />
            )}
          </fieldset>
        </div>
      )}

      {(block.style_options?.fonts || []).map(
        (setting) =>
          customStyle.canEditFont(setting) && (
            <div className={styles.sidebarPadding} key={setting}>
              <FontSection
                editor={customStyle.editor}
                setting={setting}
                color={setting !== 'button'}
                font={setting === 'link' ? supportsLinkStyles : true}
                size={setting === 'link' ? supportsLinkStyles : true}
                disabled={!canEdit}
              />
            </div>
          )
      )}

      {!isGlobalStyled && newEditor && (
        <RevertToGlobalStyle revert={customStyle.revertToGlobalStyle} />
      )}
    </div>
  );
};
