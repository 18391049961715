import React from 'react';
import { Topic } from 'models/topic';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import cx from 'classnames';
import styles from './orchestrate.module.css';

export const Topics: React.FC<{ topics: Array<Topic> }> = ({ topics }) => {
  function backgroundImageUrl(topic: Topic) {
    return topic.uploadedImage?.url || topic.backgroundImageUrl;
  }
  return (
    <div className={styles.topicsWrapper}>
      {topics.map((t) => (
        <div
          key={t.id}
          className={cx(styles.topic, 'kai-flex-row', 'flex-top', 'kai-p-24')}
        >
          <div
            className={cx(styles.topicBackground, 'flex-shrink-0')}
            style={{
              backgroundImage: backgroundImageUrl(t)
                ? `url(${backgroundImageUrl(t)})`
                : undefined,
              backgroundColor: t.backgroundColor,
            }}
          />
          <div className="flex-grow kai-pl-20 ellipsis-overflow">
            <div className="kai-flex-row flex-spread">
              <h3 className="section-header ellipsis-overflow">{t.name}</h3>
              <div className="flex-end flex-shrink-0 nowrap">
                <SVGIcon name="Audience" size={20} />
                &nbsp;
                {t.followerCount.toLocaleString()}
              </div>
            </div>
            <div className="ellipsis-overflow kai-mt-4">{t.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
