import React from 'react';
import cx from 'classnames';
import styles from './orchestration-insights.module.css';

export const Hints: React.FC = () => {
  return (
    <div className={styles.insights}>
      <div className={cx(styles.row, styles.hintRow)} key="warning">
        <div className={styles.label}>Warning</div>

        <div className={styles.hintText}>
          The Scores and Plan are predictive and subject to change
        </div>
      </div>
      <div className={cx(styles.row, styles.hintRow)} key="hint">
        <div className={styles.label}>Hint</div>

        <div className={styles.hintText}>
          For a higher Performance Score extend the duration
        </div>
      </div>
    </div>
  );
};
