import * as React from 'react';
import { DefinitionBlock, Targets } from 'models/publisher/block';
import { useRenderer } from 'hooks/content-blocks';
import { useStyleEditor } from 'contexts/publisher/compose/style';
import { Loading } from './Loading';

export const Static: React.FC<{
  block: DefinitionBlock;
}> = ({ block }) => {
  const { style } = useStyleEditor();
  const render = useRenderer(block, style, Targets.web);
  const [cache, setCache] = React.useState(render.html || '');
  React.useEffect(() => {
    setCache(render.html || cache);
  }, [render.html, cache, setCache]);
  return (
    <div>
      {cache === '' ? (
        <Loading />
      ) : (
        /* eslint-disable-next-line react/no-danger */
        <div dangerouslySetInnerHTML={{ __html: cache }} />
      )}
    </div>
  );
};
