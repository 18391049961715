import { useQuery } from 'react-query';
import {
  DirectDataDownloadFolders,
  fetchDirectDataDownloads,
} from '../../../../../services/api-insights';

export const useDataDownloads = (
  programId: number
): {
  data: DirectDataDownloadFolders;
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, isError, isLoading } = useQuery(
    [programId, 'directDataDownload'],
    () => fetchDirectDataDownloads(programId)
  );

  return {
    data: data ?? {},
    isError,
    isLoading,
  };
};
