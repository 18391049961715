import * as React from 'react';
import { AliasData } from 'services/api-author-alias';
import { InfiniteSelect, InfiniteLoadProps } from 'shared/InfiniteSelect';
import styles from './author-alias.module.css';

export type PropsType = {
  values: Array<AliasData>;
  selectedIds: Array<string>;
  onSelectedIdsChange: (ids: Array<string>) => void;
  maxHeight?: number;
} & InfiniteLoadProps;

export const Dropdown: React.FC<PropsType> = (props) => {
  const {
    values,
    selectedIds,
    onSelectedIdsChange,
    maxHeight = 400,
    // Infinite load props
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
  } = props;

  const itemHeight = 56;

  const rowIds: Array<string> = values.map((item: AliasData) =>
    item.id.toString()
  );

  const rowRenderProp = (id: string) => {
    const alias: AliasData | undefined = values.find(
      (item: AliasData) => item.id.toString() === id
    );
    if (alias) {
      return (
        <div key={id} className={styles.row}>
          <div>{alias.displayName}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.dropdown}>
      <InfiniteSelect
        rowIds={rowIds}
        rowRenderProp={rowRenderProp}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        itemHeight={itemHeight}
        maxHeight={maxHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        threshold={threshold}
        overscan={overscan}
      />
    </div>
  );
};
