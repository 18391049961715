import React, { useState } from 'react';
import {
  PreviewablePost,
  usePreview as useBlocksPreview,
} from 'hooks/content-blocks';
import { Mobile as MobileFrame } from 'components/publisher/pages/Preview/Mobile';
import { Email } from 'components/publisher/pages/Preview/Email';
import { Web } from 'components/publisher/pages/Preview/Web';
import { MicroApp } from 'components/publisher/pages/Preview/MicroApp';
import {
  Android as AndroidIcon,
  Desktop as WebIcon,
  IOS as IOSIcon,
  Mail as EmailIcon,
  MicroApp as MicroAppIcon,
  Mobile as MobileIcon,
} from 'shared/icons';
import { Post } from 'models/publisher/post';
import { PreviewAs } from 'models/variable-previews';
import { RenderError, Targets } from 'models/publisher/block';
import { useUser } from 'contexts/user';
import { usePersonalizedFieldsFilesQuery } from 'hooks/personalized-fields';
import { usePublisher } from 'contexts/publisher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';

export type PreviewTab = {
  name: PreviewType;
  delivery: Targets;
  frame: React.FC<{ post?: Post }>;
  icon: React.FC;
};

export type Preview = {
  tabs: PreviewTab[];
  tab: PreviewTab;
  setTab: (tab: PreviewTab) => void;
  html?: string;
  isLoading: boolean;
  errors: RenderError[];
  webFontsEnabled: boolean;
  toggleWebFonts: () => void;
  previewAs: PreviewAs;
  setPreviewAs: (t: PreviewAs) => void;
};

export enum PreviewType {
  android = 'android',
  ios = 'ios',
  mobile = 'mobile',
  web = 'web',
  email = 'email',
  microapp = 'microapp',
}

export const PREVIEW_TABS: Record<PreviewType, PreviewTab> = {
  android: {
    name: PreviewType.android,
    delivery: Targets.android,
    frame: MobileFrame,
    icon: AndroidIcon,
  },
  ios: {
    name: PreviewType.ios,
    delivery: Targets.ios,
    frame: MobileFrame,
    icon: IOSIcon,
  },
  mobile: {
    name: PreviewType.mobile,
    delivery: Targets.ios,
    frame: MobileFrame,
    icon: MobileIcon,
  },
  web: {
    name: PreviewType.web,
    delivery: Targets.web,
    frame: Web,
    icon: WebIcon,
  },
  email: {
    name: PreviewType.email,
    delivery: Targets.email,
    frame: Email,
    icon: EmailIcon,
  },
  microapp: {
    name: PreviewType.microapp,
    delivery: Targets.microapp,
    frame: MicroApp,
    icon: MicroAppIcon,
  },
};

export function usePreview(
  post: PreviewablePost,
  excludedPreviewTypes?: PreviewType[]
): Preview {
  const [webFontsEnabled, setWebFontsEnabled] = useState(true);
  const [previewAs, setPreviewAs] = useState<PreviewAs>('anonymous');
  const { id: program_id } = useProgram();
  const publisher = usePublisher();
  const { id: userId } = useUser();

  const { data: personalizedFieldsFlag } = useFeatureFlagsQuery(
    program_id,
    'Studio.Publish.PersonalizedFields'
  );

  const { data: pfData } = usePersonalizedFieldsFilesQuery(
    publisher.id === 'new' ? -1 : publisher.id,
    (personalizedFieldsFlag?.value as boolean) || false
  );

  // Split the mobile preview icon into separate iOS and Android icons if
  // any blocks have different targeting among iOS and Android targets.
  const hasGranularMobilePreview = React.useMemo(
    () =>
      post.blocks.some(
        (block) =>
          block.target?.excluded?.includes(Targets.android) !==
          block.target?.excluded?.includes(Targets.ios)
      ),
    [post.blocks]
  );

  // Show the Microapp preview icon if any blocks have different targeting
  // among Microapp and Web targets.
  const hasMicroappPreview = React.useMemo(
    () =>
      post.blocks.some(
        (block) =>
          block.target?.excluded?.includes(Targets.microapp) !==
          block.target?.excluded?.includes(Targets.web)
      ),
    [post.blocks]
  );

  const previewTabs = React.useMemo<PreviewTab[]>(() => {
    const availablePreviewTabs = [
      ...(hasGranularMobilePreview
        ? [PREVIEW_TABS.ios, PREVIEW_TABS.android]
        : [PREVIEW_TABS.mobile]),
      PREVIEW_TABS.web,
      PREVIEW_TABS.email,
      ...(hasMicroappPreview ? [PREVIEW_TABS.microapp] : []),
    ];

    return excludedPreviewTypes
      ? availablePreviewTabs.filter(
          ({ name }) => !excludedPreviewTypes.includes(name)
        )
      : availablePreviewTabs;
  }, [excludedPreviewTypes, hasGranularMobilePreview, hasMicroappPreview]);

  const [tab, setTab] = useState(previewTabs[0]);
  React.useEffect(() => {
    const isSelectedTabInList = previewTabs.some(
      ({ name }) => name === tab.name
    );
    if (!isSelectedTabInList) {
      // Reset selected tab to first tab in list
      setTab(previewTabs[0]);
    }
  }, [previewTabs, tab.name]);

  const { html, isLoading, errors } = useBlocksPreview(
    post,
    tab.delivery,
    webFontsEnabled,
    previewAs,
    // eslint-disable-next-line
    pfData
      ?.filter((file) => file.creatorId === userId)
      .map((file) => file.name) ?? []
  );
  const toggleWebFonts = () => setWebFontsEnabled(!webFontsEnabled);

  return {
    tab,
    tabs: previewTabs,
    setTab,
    html,
    isLoading,
    errors,
    webFontsEnabled,
    toggleWebFonts,
    previewAs,
    setPreviewAs,
  };
}

export const PreviewContext = React.createContext({
  tabs: {},
} as ReturnType<typeof usePreview>);
