import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import styles from './not-found-screen.module.css';

export const NotFoundScreen: React.FC<
  RouteComponentProps & { message?: string }
> = ({ message = 'Page not found' }) => (
  <div className={styles.wrapper}>
    <div className={styles.logoWrapper}>
      <SVGIcon size={120} name="Logo" />
    </div>
    <h3>{message}</h3>
    <Link to="/">back to home</Link>
  </div>
);
