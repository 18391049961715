import * as React from 'react';
import { uniqueId } from 'hooks/useUniqueId';
import { InclusiveLanguageEvents } from './types';
import { useHighlightPopup } from './useHighlightPopup';
import { useTextAnalyzer } from './useTextAnalyzer';

export function useInputHighlighter<
  E extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>(
  programId: number,
  value: string,
  onChange: (value: string) => void
): {
  inputRef: React.RefObject<E>;
  legendRef: React.RefObject<HTMLLegendElement>;
  onChange: (value: string) => void;
} {
  useHighlightPopup();
  const inputRef = React.useRef<E>(null);
  const legendRef = React.useRef<HTMLLegendElement>(null);
  const analyzer = useTextAnalyzer(programId);
  const analyzerReady = analyzer.ready();
  const getText = React.useCallback(() => {
    if (!inputRef.current) return '';
    return inputRef.current.value || '';
  }, []);

  const suggestIdx: React.MutableRefObject<number> = React.useRef(0);
  const showNextSuggestion = React.useCallback(
    (event: MouseEvent) => {
      const markers = analyzer.suggestions(getText());
      const marker = markers[Math.min(suggestIdx.current, markers.length - 1)];
      if (marker && inputRef.current) {
        const { suggestion, explanation, term, offset } = marker;
        const id = uniqueId();
        inputRef.current.dataset.inclusiveLanguageElement = id;
        inputRef.current.focus(); // auto-scroll, brings it into view
        inputRef.current.setSelectionRange(offset, offset + term.length);
        const { top, left, height } = inputRef.current.getBoundingClientRect();
        InclusiveLanguageEvents.show({
          event,
          position: { top: top + height + 5, left },
          edit: {
            term,
            suggestion,
            explanation,
          },
          marker: {
            type: 'input',
            offset,
            selector: `[data-inclusive-language-element=${id}]`,
          },
        });
      }
      // finally..
      suggestIdx.current += 1;
      suggestIdx.current %= markers.length;
    },
    [analyzer, getText]
  );

  const triggerChange = React.useCallback(
    (e: Event) => {
      if (!e.target) return;
      const target = (e.target as unknown) as HTMLInputElement;
      onChange(target.value || '');
    },
    [onChange]
  );

  React.useEffect(() => {
    const cleanup = inputRef.current;
    if (inputRef.current) {
      inputRef.current.addEventListener(
        InclusiveLanguageEvents.names.applied,
        triggerChange
      );
    }
    return () => {
      if (cleanup)
        cleanup.removeEventListener(
          InclusiveLanguageEvents.names.applied,
          triggerChange
        );
    };
  }, [triggerChange]);

  React.useEffect(() => {
    const cleanup = legendRef.current;
    if (inputRef.current && analyzerReady) {
      if (analyzer.suggestions(getText()).length > 0) {
        if (legendRef.current) {
          legendRef.current.dataset.inclusiveLanguageEnabled = 'true';
          legendRef.current.style.boxShadow =
            '0 3px 0 -1px var(--color-yellowFull)';
          legendRef.current.style.cursor = 'pointer';
          legendRef.current.addEventListener('click', showNextSuggestion);
        }
      } else if (legendRef.current) {
        legendRef.current.dataset.inclusiveLanguageEnabled = 'false';
        legendRef.current.removeEventListener('click', showNextSuggestion);
        legendRef.current.style.boxShadow = 'none';
        legendRef.current.style.cursor = 'text';
      }
    }
    return () => {
      if (cleanup) cleanup.removeEventListener('click', showNextSuggestion);
    };
  }, [value, analyzer, getText, analyzerReady, showNextSuggestion]);
  return { inputRef, legendRef, onChange };
}
