import React from 'react';
import { MaxRetries } from 'models/publisher/orchestration-insights';
import styles from './orchestration-insights.module.css';

export const ReTargeting: React.FC<{ maxRetries: MaxRetries }> = ({
  maxRetries,
}) => (
  <div className={styles.insights}>
    <div className={styles.targetRow}>
      <div className={styles.day}>Max possible retargeting attempts:</div>
      <div className={styles.value}>{maxRetries}</div>
    </div>

    <div className={styles.note}>
      <strong>Note:</strong> Retargeting attempts are planned for individual
      users, accounting for all other campaigns targeted to them. This count is
      a maximum, not a guarantee of delivery.
    </div>
  </div>
);
