import { TextInput } from '@socialchorus/shared-ui-components';
import React from 'react';
import { useAudiencesInfiniteQuery } from 'hooks/audience';
import { Audience } from 'models/audience';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { FocusDropdown } from 'shared/FocusDropdown';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { ClickDropdown } from 'DesignSystem/Components';
import { WhitePillClass } from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import styles from './audience-select.module.css';
import { Dropdown } from './Dropdown';

export type PropsType = {
  icon?: React.ReactElement;
  selectedAudiences: Array<Audience>;
  onSelectedAudiencesChange: (audiences: Array<Audience>) => void;
  maxDropdownHeight?: number;
  placeholder?: string;
  // Always display selected audiences in dropdown, defaults to true
  alwaysDisplaySelected?: boolean;
  /* Only search within audiences with these names */
  name?: string | string[];
  upward?: boolean;
  /*
   * `default` variant will render input with pills inside it.
   * `pills` variant will render search input with pills under it.
   */
  variant?: 'default' | 'pills';
  /*
   * See `Dropdown` component for more details.
   */
  dropdownVariant?: 'default' | 'minimal';
  disabled?: boolean;
};

export const AudienceSelect: React.FC<PropsType> = (props) => {
  const {
    icon,
    selectedAudiences,
    onSelectedAudiencesChange,
    maxDropdownHeight = 400,
    placeholder,
    alwaysDisplaySelected = true,
    name,
    upward = false,
    variant = 'default',
    dropdownVariant = 'default',
    disabled,
  } = props;

  const [searchText, setSearchText] = React.useState('');

  const { id: programId } = useProgram();
  const {
    isLoading,
    data: values,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAudiencesInfiniteQuery({
    programId,
    search: useDebounce(searchText),
    statuses: 'enabled',
    name,
  });

  const selectedIds = selectedAudiences.map((audience) => audience.id || '');
  const selectedValues = selectedAudiences.map(
    (audience) => `${audience?.title} (${audience?.totalUsers})`
  );

  const audiences = [...values];
  const audienceIds: Array<string> = values.map((item) => item.id || '');
  const uniqueIds = new Set([...audienceIds]);

  selectedAudiences.forEach((value) => {
    if (value.id && !uniqueIds.has(value.id)) {
      audiences.push(value);
    }
  });

  function handleRemove(index: number) {
    const newSelectedAudiences = [...selectedAudiences];
    newSelectedAudiences.splice(index, 1);
    onSelectedAudiencesChange(newSelectedAudiences);
  }

  function handleSelectedIdsChange(ids: Array<string>) {
    const newSelectedAudiences = ids
      .map((id) =>
        audiences.find((audience) => audience?.id?.toString() === id.toString())
      )
      .filter((item) => !!item);
    onSelectedAudiencesChange(newSelectedAudiences as Array<Audience>);
  }

  const clearSearch = React.useCallback(() => setSearchText(''), []);

  function dropdownRenderProp() {
    return (
      <Dropdown
        values={alwaysDisplaySelected ? audiences : values}
        selectedIds={selectedIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        maxHeight={maxDropdownHeight}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        variant={dropdownVariant}
      />
    );
  }

  if (variant === 'pills') {
    return (
      <div className={styles.selector}>
        <ClickDropdown
          dropdownRenderProp={dropdownRenderProp}
          matchReferenceWidth
          referencePress={false}
          ignoreKeys
          autoUpdate
          onClose={clearSearch}
        >
          <TextInput
            leftWidget={icon}
            fullWidth
            placeholder={placeholder}
            value={searchText}
            onChange={setSearchText}
          />
        </ClickDropdown>
        <Pills
          values={selectedAudiences}
          render={(value) => (
            <>
              {value.title || value.name}&nbsp;·&nbsp;{value.totalUsers}
            </>
          )}
          pillClassName={WhitePillClass}
          onClose={(audience) => {
            const newValue = selectedAudiences.filter(
              (selectedAudience: Audience) =>
                selectedAudience.id !== audience.id
            );
            onSelectedAudiencesChange(newValue);
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.selector}>
      <FocusDropdown
        dropdownRenderProp={dropdownRenderProp}
        dropdownClassName="dropdown-align-left full-width"
        keyPressActivated
        onClose={clearSearch}
        upward={upward}
        disabled={disabled}
      >
        {(onFocus, ref) => (
          <MultiValueTextInput
            icon={icon}
            textValue={searchText}
            onTextValueChange={setSearchText}
            selectedValues={selectedValues}
            onRemoveSelectedValueAt={handleRemove}
            placeholder={placeholder}
            onFocus={onFocus}
            inputRef={ref}
          />
        )}
      </FocusDropdown>
    </div>
  );
};
