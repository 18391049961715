import { Notification } from 'models/notification';

export function notificationTextPreview(
  notification: Notification
): {
  emailSubject: string;
  emailPreview: string;
  pushText: string;
  assistantText: string;
} {
  const emailPreview = notification.previewText || '';
  const emailSubject = notification.text;
  const pushText = notification.pushText || notification.text;
  const assistantText =
    notification.notificationCenterText || notification.text;

  return { emailSubject, emailPreview, pushText, assistantText };
}
