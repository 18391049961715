import { expressionToText } from 'models/expression';
import { Audience } from 'models/audience';
import lucene from 'lucene';
import { quoteTerm, readAST, ReadNode } from 'models/publisher/lucene';

export type CriterionStatement = {
  key: string;
  value: string;
  operator: '=' | '!=';
};

export type Criterion =
  | { or: Criterion[] }
  | { and: Criterion[] }
  | { not: Criterion[] }
  | CriterionStatement
  | null;

function explodeOr(c: Criterion): Criterion[] {
  return c !== null && 'or' in c ? c.or : [c];
}

function explodeAnd(c: Criterion): Criterion[] {
  return c !== null && 'and' in c ? c.and : [c];
}

export function or(cs: Criterion[]): Criterion {
  return cs.length === 1 ? cs[0] : { or: cs.flatMap(explodeOr) };
}

export function and(cs: Criterion[]): Criterion {
  return cs.length === 1 ? cs[0] : { and: cs.flatMap(explodeAnd) };
}

export function not(c: Criterion): Criterion {
  return c !== null && 'not' in c ? c : { not: [c] };
}

function readCriterion(node: ReadNode<Criterion>): Criterion {
  if (node === null) return null;

  if ('op' in node)
    switch (node.op) {
      case '<implicit>':
        return readCriterion(node.value);
      case 'NOT':
        return not(readCriterion(node.value));
      case 'OR':
        return or([readCriterion(node.left), readCriterion(node.right)]);
      case 'AND':
        return and([readCriterion(node.left), readCriterion(node.right)]);
      case 'AND NOT':
        return and([readCriterion(node.left), not(readCriterion(node.right))]);
      case 'OR NOT':
        return or([readCriterion(node.left), not(readCriterion(node.right))]);
      default:
        throw new Error(
          `Unexpected operator on ${JSON.stringify(
            node
          )} when reading criterion`
        );
    }

  if ('term' in node)
    return {
      key: node.field,
      operator: '=',
      value: node.term,
    };

  if ('inclusive' in node)
    return {
      key: node.field,
      operator: '=',
      value: lucene.toString({
        inclusive: node.inclusive,
        term_min: quoteTerm(node.term_min),
        term_max: quoteTerm(node.term_max),
        // The types lie. They think this function needs an AST, but a Node
        // works just fine.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any),
    };

  return node;
}

export function textToCriterion(query: string): Criterion {
  const ast = lucene.parse(query);

  if (!ast.left) return null;

  return readAST(ast, readCriterion);
}

function audienceToCriterion(audience: Audience): Criterion {
  if (audience.name)
    return { key: 'group', operator: '=', value: audience.name };
  if (audience.query) return textToCriterion(audience.query);
  if (audience.expression)
    return textToCriterion(expressionToText(audience.expression));
  return textToCriterion('');
}

export function audiencesToCriterion(audiences: Audience[]): Criterion {
  return audiences.length ? or(audiences.map(audienceToCriterion)) : null;
}
